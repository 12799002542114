import { IPermissionSetMetadata } from '@ready/security.core';
import { Action } from '../../../redux/types';
import SecurityRolesService from '../../services/securityRolesService';
import { pageErrorState } from '../../../redux/actions/uiActions/responseStateActions';
import { SECURITY_ROLES_METADATA } from './types';

export const loadPermissionsSet = (companyId: string) => async (dispatch: any) => {
  try {
    dispatch(loadPermissionsSetBegin());
    const metadata = await SecurityRolesService.getSecurityMetadata(companyId);
    dispatch(loadPermissionsSetSuccess(metadata));
  } catch (err) {
    dispatch(loadPermissionsSetError());
    dispatch(pageErrorState(err.status, err.message));
  }
};

export const loadPermissionsSetBegin = (): Action => ({
  type: SECURITY_ROLES_METADATA.LOAD_PERMISSIONS_SET_BEGIN,
});

export const loadPermissionsSetSuccess = (payload: IPermissionSetMetadata): Action => ({
  type: SECURITY_ROLES_METADATA.LOAD_PERMISSIONS_SET_SUCCESS,
  payload,
});

export const loadPermissionsSetError = (): Action => ({
  type: SECURITY_ROLES_METADATA.LOAD_PERMISSIONS_SET_ERROR,
});
