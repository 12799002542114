import React from 'react';
import { FormPanelCollapsible } from '../../../../components/Form';
import PayAtTablePanel from './PayAtTable';
import { IPayAtTable } from '@ready/dashboardv2api.contracts';
import { IUpdateLocationParam } from '../../LocationsEditPage';

export interface LocationPanelPayAtTableProps {
  isProcessing: boolean;
  updateLocationParam: IUpdateLocationParam;
  payAtTable: IPayAtTable;
  handlePanelToggle: (toggle: boolean) => void;
  panelToggle: boolean;
}

export const LocationPanelPayAtTable = (props: LocationPanelPayAtTableProps) => {
  const { isProcessing, payAtTable, panelToggle, handlePanelToggle, updateLocationParam } = props;
  return (
    <FormPanelCollapsible
      title='Pay at Table'
      initiallyExpanded={panelToggle}
      isEnabled={panelToggle}
      withToggle={true}
      handlePanelToggle={(toggled: boolean) => {
        handlePanelToggle(toggled);
      }}
      loading={isProcessing}
    >
      <PayAtTablePanel
        isProcessing={isProcessing}
        updateLocationParam={updateLocationParam}
        payAtTable={payAtTable}
      ></PayAtTablePanel>
    </FormPanelCollapsible>
  );
};

export default LocationPanelPayAtTable;
