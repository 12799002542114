import React, { FC, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import styles from './UpdatePickupDateTimeDialog.module.scss';
import { AppState } from '../../../redux/initialStates/AppState';
import {
  updatePickupTime,
  setEditPickupTimeDialogOpen,
  setEditPickupTimeDialogMessage,
  setNewPickupTime,
  validateNewPickupDateTime,
  resetPickupDateTimeValidations,
} from '../../../redux/actions/orders/orderDetailActions';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '../../../components/Modal';
import { useParams } from 'react-router-dom';
import { ContextParams } from '../../../types/ContextParams.interface';
import DialogSMSInput from './DialogSMSInput';
import { format, isAfter } from 'date-fns';
import DialogPickupTimeInput from './DialogPickupTimeInput';
import getIdentitySmsNumber from '../../../utils/orderUtils/getIdentityInfoSmsNumber';
import replaceCustomStringVariables from '../../../utils/stringUtils/customStringVariableParser';
import { VariableTypes } from '../../../enums/variables.enum';
import { formatDateTime } from '../../../utils/formatUtils';
import DialogPickupDateInput from './DialogPickupDateInput';
import { isBeginningOfTime } from '../../../utils/dateUtils';
import { ISmsConfig, OrderSmsMessageSource } from '@ready/dashboardv2api.contracts';
import {
  OrderResourceActions,
  PrincipalPermissions,
  ResourceType,
  SecurityScope,
  Verifier,
} from '@ready/security.core';

const UpdatePickupDateTimeDialog = ({
  invoiceId,
  orderSmsConfigs,
  messageLoading,
  identityInfo,
  open,
  processing,
  pickupTime,
  newPickupTime,
  validation,
  updatePickupTime,
  setEditPickupTimeDialogOpen,
  setEditPickupTimeDialogMessage,
  setNewPickupTime,
  validateNewPickupDateTime,
  locationDetails,
  orderSmsUpdatePickupTimeMessage,
  orderId,
  resetPickupDateTimeValidations,
  permissionsList,
}: ReduxProps): JSX.Element | null => {
  const { contextId, locationId } = useParams<ContextParams>();
  const selectedOrderSmsConfig: ISmsConfig =
    orderSmsConfigs.messageSource === OrderSmsMessageSource.Location
      ? orderSmsConfigs.locationConfig
      : orderSmsConfigs.companyConfig;

  const handleSetShowModal = React.useCallback(
    (open: boolean) => {
      setEditPickupTimeDialogOpen(open);
    },
    [setEditPickupTimeDialogOpen]
  );

  const formatMessage = React.useCallback(
    (value: string) => {
      return replaceCustomStringVariables(value, VariableTypes.Time, newPickupTime);
    },
    [newPickupTime]
  );

  const handlePickupTimeValidation = React.useCallback(
    (value: Date) => {
      validateNewPickupDateTime(value, new Date());
    },
    [validateNewPickupDateTime]
  );
  const newPickupTimeDate = useMemo(() => new Date(newPickupTime), [newPickupTime]);
  const handleUpdatePickupTime = React.useCallback(async () => {
    // used to display error message on UI
    handlePickupTimeValidation(newPickupTimeDate);

    // only if newPickupTime is after current datetime is that it allows to save
    if (isAfter(newPickupTimeDate, new Date())) {
      const success = await updatePickupTime(
        contextId,
        locationId,
        orderId,
        newPickupTimeDate,
        invoiceId,
        orderSmsUpdatePickupTimeMessage ? formatMessage(orderSmsUpdatePickupTimeMessage) : undefined
      );

      if (success) {
        setEditPickupTimeDialogOpen(false);
        // reset pickup time to initial order sms configured message
        setEditPickupTimeDialogMessage(selectedOrderSmsConfig.pickUpTimeUpdated?.value ?? '');
      }
    }
  }, [
    handlePickupTimeValidation,
    newPickupTimeDate,
    updatePickupTime,
    contextId,
    locationId,
    orderId,
    invoiceId,
    orderSmsUpdatePickupTimeMessage,
    formatMessage,
    setEditPickupTimeDialogOpen,
    setEditPickupTimeDialogMessage,
    selectedOrderSmsConfig.pickUpTimeUpdated?.value,
  ]);

  const pickupTimeDate = new Date(pickupTime);

  const handleCancel = () => {
    setEditPickupTimeDialogOpen(false);
    setNewPickupTime(pickupTimeDate);
    setEditPickupTimeDialogMessage(selectedOrderSmsConfig.pickUpTimeUpdated?.value ?? '');
    resetPickupDateTimeValidations();
  };

  const handleMessageChange = React.useCallback(
    (value: string) => {
      setEditPickupTimeDialogMessage(value);
    },
    [setEditPickupTimeDialogMessage]
  );

  const orderSmsNumber = identityInfo ? getIdentitySmsNumber(identityInfo) : null;
  const hasOrdersAllPermission = Verifier.check(
    new PrincipalPermissions(permissionsList),
    SecurityScope.location,
    ResourceType.order,
    OrderResourceActions.all,
    locationId
  );
  const hasViewOrderSmsMessagesPermission = Verifier.check(
    new PrincipalPermissions(permissionsList),
    SecurityScope.location,
    ResourceType.order,
    OrderResourceActions.allExceptEditingOutgoingSmsMessage,
    locationId
  );

  const hasPermissionsToSeePickupTimeMessage =
    (hasOrdersAllPermission || hasViewOrderSmsMessagesPermission) &&
    locationDetails &&
    locationDetails?.settings?.orderSmsEnabled &&
    selectedOrderSmsConfig.pickUpTimeUpdated?.isActive;

  return open ? (
    <Modal setShowModal={handleSetShowModal} additionalStyles={styles.overflowY}>
      <ModalHeader headerLabel='Edit Pick Up Time?' setShowModal={handleSetShowModal} />
      <ModalBody additionalStyles={styles.overflowY}>
        {hasPermissionsToSeePickupTimeMessage && orderSmsNumber ? (
          <>
            <DialogMessageWithSms smsNumber={orderSmsNumber} pickupTime={pickupTimeDate} />
            <DialogPickupDateInput
              value={newPickupTimeDate}
              onChange={setNewPickupTime}
              hasError={validation.newPickupDate.hasError}
              errorMessage={validation.newPickupDate.errorMessage}
            />
            <DialogPickupTimeInput
              value={newPickupTimeDate}
              onChange={setNewPickupTime}
              hasError={validation.newPickupTime.hasError}
              errorMessage={validation.newPickupTime.errorMessage}
            />
            <DialogSMSInput
              value={orderSmsUpdatePickupTimeMessage}
              loading={messageLoading}
              processing={processing}
              onChange={handleMessageChange}
              formatter={formatMessage}
              withPencilIcon={hasOrdersAllPermission}
            />
          </>
        ) : (
          <>
            <DialogMessage pickupTime={pickupTimeDate} />
            <DialogPickupDateInput
              value={newPickupTimeDate}
              onChange={setNewPickupTime}
              hasError={validation.newPickupDate.hasError}
              errorMessage={validation.newPickupDate.errorMessage}
            />
            <DialogPickupTimeInput
              value={newPickupTimeDate}
              onChange={setNewPickupTime}
              hasError={validation.newPickupTime.hasError}
              errorMessage={validation.newPickupTime.errorMessage}
            />
          </>
        )}
      </ModalBody>
      <ModalFooter
        primaryLabel='Save Changes'
        primaryActionHandler={handleUpdatePickupTime}
        loading={processing}
        secondaryLabel='Cancel'
        secondaryActionHandler={handleCancel}
      />
    </Modal>
  ) : null;
};

const mapStateToProps = (state: AppState) => {
  return {
    invoiceId: state.orders.orderDetail.invoiceId,
    orderSmsUpdatePickupTimeMessage: state.orders.orderDetail.editPickupTimeDialogMessage,
    messageLoading: state.orders.orderDetail.editPickupTimeDialogMessageLoading,
    identityInfo: state.orders.orderDetail.identityInfo,
    open: state.orders.orderDetail.editPickupTimeDialogOpen,
    processing: state.orders.orderDetail.editPickupTimeProcessing,
    pickupTime: state.orders.orderDetail.pickupTime,
    newPickupTime: state.orders.orderDetail.newPickupTime || state.orders.orderDetail.pickupTime, // use pickupTime as an initial value
    validation: state.orders.orderDetail.validation,
    locationDetails: state.orders.orderList.locationDetails,
    orderSmsConfigs: state.orders.orderSmsConfigs.orderSmsConfigData,
    orderId: state.orders.orderDetail.orderId,
    permissionsList: state.session.permissions.permissionsList,
  };
};

const actionCreators = {
  updatePickupTime,
  setEditPickupTimeDialogOpen,
  setEditPickupTimeDialogMessage,
  setNewPickupTime,
  validateNewPickupDateTime,
  resetPickupDateTimeValidations,
};

const connector = connect(mapStateToProps, actionCreators);
type ReduxProps = ConnectedProps<typeof connector>;

const getPickupTimeFormatted = (pickupTime: Date) => {
  if (isBeginningOfTime(pickupTime)) {
    return format(pickupTime, 'h:mm aa');
  }

  return formatDateTime(pickupTime);
};

interface DialogMessageProps {
  pickupTime: Date;
}

const DialogMessage: FC<DialogMessageProps> = ({ pickupTime }) => (
  <div className={styles.modalMessage}>
    This order is currently scheduled for pick up at <b>{getPickupTimeFormatted(pickupTime)}</b>.
  </div>
);

interface DialogMessageWithSmsProps extends DialogMessageProps {
  smsNumber: string | null;
}

const DialogMessageWithSms: FC<DialogMessageWithSmsProps> = ({ pickupTime, smsNumber }) => (
  <div className={styles.modalMessage}>
    This order is currently scheduled for pick up at <b>{getPickupTimeFormatted(pickupTime)}</b>. The guest will be
    notified via <b>text</b> to <b>{smsNumber}</b>.
  </div>
);

export default connector(UpdatePickupDateTimeDialog);
