import { Action } from '../../redux/types';
import { ISchedule, IScheduleSlot } from '@ready/menu.core';
import { pageErrorState, set200Toast, toastErrorState } from '../../redux/actions/uiActions/responseStateActions';
import { ScheduleValidationErrors } from './ScheduleState';
import validateForm from '../utils/validateSchedule';
import ScheduleService from '../services/ScheduleService';
import { setFormIsDirty } from '../../redux/actions/uiActions/formStateActions';

export const actionTypes = {
  SCHEDULE_INIT: 'SCHEDULE_INIT',
  SCHEDULE_SET: 'SCHEDULE_SET',
  SCHEDULE_SET_CACHE: 'SCHEDULE_SET_CACHE',
  SCHEDULE_SET_LOADING: 'SCHEDULE_SET_LOADING',
  SCHEDULE_RESET: 'SCHEDULE_RESET',
  SCHEDULE_PROCESSING: 'SCHEDULE_PROCESSING',
  SCHEDULE_ADD_NEW_SLOT: 'SCHEDULE_ADD_NEW_SLOT',
  SCHEDULE_REMOVE_SLOT: 'SCHEDULE_REMOVE_SLOT',
  SCHEDULE_SLOT_SET: 'SCHEDULE_SLOT_SET',
  SCHEDULE_NAME_SET: 'SCHEDULE_NAME_SET',
  SCHEDULE_VALIDATION_SET: 'SCHEDULE_VALIDATION_SET',
  SCHEDULE_DELETE_DIALOG_SHOW: 'SCHEDULE_DELETE_DIALOG_SHOW',
  SCHEDULE_CANCEL_CREATE: 'SCHEDULE_CANCEL_CREATE',
};

export const fetchSchedule = (companyId: string, scheduleId: string) => async (dispatch: any) => {
  try {
    dispatch(setScheduleLoading(true));

    const schedule = await ScheduleService.getSchedule(companyId, scheduleId);

    dispatch(setSchedule(schedule));
    dispatch(setScheduleCache(schedule));
  } catch (err) {
    dispatch(pageErrorState(err.status, err.message));

  } finally {
    dispatch(setScheduleLoading(false));
  }
};

export const submitSchedule =
  (companyId: string, schedule: ISchedule) =>
  async (dispatch: any): Promise<string> => {
    let updatedSchedule: ISchedule = {
      _id: '',
      name: '',
      slots: [],
    };

    try {
      dispatch(setScheduleProcessing(true));

      // If there is no ID, this is a create
      if (!schedule._id || schedule._id === '') {
        updatedSchedule = await ScheduleService.createSchedule(companyId, schedule);

        // Otherwise this is an update
      } else {
        updatedSchedule = await ScheduleService.updateSchedule(companyId, schedule);
      }

      dispatch(setFormIsDirty(false));
      dispatch(setScheduleCache(updatedSchedule));
      dispatch(set200Toast());
    } catch (err) {
      dispatch(toastErrorState(err.status, err.message));
    } finally {
      dispatch(setScheduleProcessing(false));
    }

    return updatedSchedule._id;
  };

export const deleteSchedule =
  (companyId: string, scheduleId: string) =>
  async (dispatch: any): Promise<boolean> => {
    let success = false;

    try {
      dispatch(setScheduleProcessing(true));

      await ScheduleService.deleteSchedule(companyId, scheduleId);

      dispatch(set200Toast('Schedule deleted.'));
      success = true;
    } catch (err) {
      dispatch(toastErrorState(err.status, err.message));
    } finally {
      dispatch(setScheduleProcessing(false));
    }

    return success;
  };

export const validateSchedule =
  (schedule: ISchedule) =>
  (dispatch: any): boolean => {
    const errors = validateForm(schedule);
    dispatch(setValidationErrors(errors));

    return errors.emptyNameError || errors.slotErrors.length > 0;
  };

export const setScheduleSlot = (scheduleSlot: IScheduleSlot, index: number) => (dispatch: any) => {
  dispatch(setScheduleSlotData(scheduleSlot, index));
  dispatch(setFormIsDirty(true));
};

export const setScheduleName = (name: string) => (dispatch: any) => {
  dispatch(setScheduleNameData(name));
};

const setValidationErrors = (errors: ScheduleValidationErrors): Action => ({
  type: actionTypes.SCHEDULE_VALIDATION_SET,
  payload: errors,
});

export const initSchedule = (): Action => ({
  type: actionTypes.SCHEDULE_INIT,
});

const setScheduleSlotData = (scheduleSlot: IScheduleSlot, index: number): Action => ({
  type: actionTypes.SCHEDULE_SLOT_SET,
  payload: { scheduleSlot, index },
});

const setScheduleNameData = (name: string): Action => ({
  type: actionTypes.SCHEDULE_NAME_SET,
  payload: name,
});

const setScheduleLoading = (loading: boolean): Action => ({
  type: actionTypes.SCHEDULE_SET_LOADING,
  payload: loading,
});

const setSchedule = (schedule: ISchedule): Action => ({
  type: actionTypes.SCHEDULE_SET,
  payload: schedule,
});

const setScheduleCache = (schedule: ISchedule): Action => ({
  type: actionTypes.SCHEDULE_SET_CACHE,
  payload: schedule,
});

export const resetForm = () => (dispatch: any) => {
  dispatch(setFormIsDirty(false));
  dispatch({
    type: actionTypes.SCHEDULE_RESET,
  });
};

export const addNewSlot = () => ({
  type: actionTypes.SCHEDULE_ADD_NEW_SLOT,
});

export const removeSlot = (index: number) => ({
  type: actionTypes.SCHEDULE_REMOVE_SLOT,
  payload: index,
});

export const setScheduleProcessing = (processing: boolean): Action => ({
  type: actionTypes.SCHEDULE_PROCESSING,
  payload: processing,
});

export const showDeleteDialog = (show: boolean, schedule?: ISchedule): Action => ({
  type: actionTypes.SCHEDULE_DELETE_DIALOG_SHOW,
  payload: { show, schedule },
});

export const cancelCreateSchedule = () => (dispatch: any) => {
  dispatch(setFormIsDirty(false));
  dispatch({
    type: actionTypes.SCHEDULE_CANCEL_CREATE,
  });
};
