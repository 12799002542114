import React, { useState } from 'react';
import Checkbox from 'components/Checkbox/Checkbox';
import TextInput from 'components/TextInput/TextInput';
import { Form, FormActionButtons, FormControl, FormControlStacked } from 'components/Form';
import { PunchhSettingsProps } from '../PunchhSettings';
import { Controller, useForm } from 'react-hook-form';
import TextInputNestedLabel from 'components/TextInputNestedLabel/TextInputNestedLabel';

interface PunchSettingsEditPanelProps {
  punchSettings?: PunchhSettingsProps;
  onCancel: () => void;
  onSave: (updated: PunchhSettingsProps) => Promise<void>;
}

export const PunchhSettingsEditPanel = ({ punchSettings, onSave, onCancel }: PunchSettingsEditPanelProps) => {
  const [saving, setSaving] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<PunchhSettingsProps>({
    values: {
      firstNameRequired: false,
      lastNameRequired: false,
      phoneNumberRequired: false,
      birthdayRequired: false,
      postalCodeRequired: false,
      termsRequired: false,
      ...punchSettings,
    },
  });

  const termsRequired = watch('termsRequired');

  return (
    <Form
      hasGroups={true}
      onSubmit={handleSubmit(async (updatedPunchSettings: PunchhSettingsProps) => {
        setSaving(true);
        await onSave(updatedPunchSettings);
      })}
      confirmCancelChanges={true}
    >
      <FormControl label='Client ID *' errorMessage={errors.clientId?.message} withError={!!errors.clientId}>
        <TextInput
          {...register('clientId', { required: 'Required' })}
          placeholder={'Enter the Punchh client ID'}
          withError={!!errors.clientId}
          loading={saving}
        />
      </FormControl>
      <FormControl
        label='Client Secret *'
        errorMessage={errors.clientSecret?.message}
        withError={!!errors.clientSecret}
      >
        <TextInput
          {...register('clientSecret', { required: 'Required' })}
          placeholder={'Enter the Punchh client secret'}
          withError={!!errors.clientSecret}
          loading={saving}
        />
      </FormControl>
      <FormControl label='Server URL *' errorMessage={errors.serverUrl?.message} withError={!!errors.serverUrl}>
        <TextInput
          {...register('serverUrl', { required: 'Required' })}
          placeholder='Enter the Server URL'
          withError={!!errors.serverUrl}
          loading={saving}
        />
      </FormControl>

      <FormControl label='Fields Required for Guest Sign Up'>
        <FormControlStacked>
          {/* uses Controller here instead of ...register because Checkbox is a bit different */}
          <Controller
            control={control}
            name='firstNameRequired'
            render={({ field }) => <Checkbox label='First Name' checked={field.value} {...field} loading={saving} />}
          />
        </FormControlStacked>
        <FormControlStacked>
          <Controller
            control={control}
            name='lastNameRequired'
            render={({ field }) => <Checkbox label='Last Name' checked={field.value} {...field} loading={saving} />}
          />
        </FormControlStacked>
        <FormControlStacked>
          <Controller
            control={control}
            name='phoneNumberRequired'
            render={({ field }) => <Checkbox label='Phone Number' checked={field.value} {...field} loading={saving} />}
          />
        </FormControlStacked>
        <FormControlStacked>
          <Controller
            control={control}
            name='birthdayRequired'
            render={({ field }) => <Checkbox label='Birthday' checked={field.value} {...field} loading={saving} />}
          />
        </FormControlStacked>
        <FormControlStacked>
          <Controller
            control={control}
            name='postalCodeRequired'
            render={({ field }) => (
              <Checkbox label='Zip / Postal Code' checked={field.value} {...field} loading={saving} />
            )}
          />
        </FormControlStacked>
        <FormControlStacked>
          <Controller
            control={control}
            name='termsRequired'
            render={({ field }) => (
              <Checkbox label='Terms & Conditions' checked={field.value} {...field} loading={saving} />
            )}
          />
        </FormControlStacked>
        {termsRequired && (
          <FormControlStacked indented>
            <TextInputNestedLabel
              {...register('termsUrl', { required: 'Required' })}
              withRequiredLabel
              withError={!!errors.termsUrl}
              errorMessage={errors.termsUrl?.message}
              label='URL Link to Terms & Conditions *'
            />
          </FormControlStacked>
        )}
      </FormControl>
      <FormActionButtons handleCancel={() => onCancel()} loading={saving} />
    </Form>
  );
};
