import React from 'react';
import { useDispatch } from 'react-redux';
import { ICompanyIntegrations } from '@ready/dashboardv2api.contracts';
// components
import { FormControl } from '../../../components/Form';
import { Icon } from '../../../components/Icon/TextIcon';
import Notification from '../../../components/Notification/Notification';
import SelectFilter, { Option } from '../../../components/SelectFilter/SelectFilter';
import { loadSquarePOSLocations, LOCATION_SETTINGS_ACTIONS } from '../../redux/LocationSettingsActions';
import { IPosSettingsPanel } from '../../redux/PanelPosSettingsState';
import { IUpdateLocationParam } from '../LocationsEditPage';

interface Props {
  isProcessing: boolean;
  updateLocationParam: IUpdateLocationParam;
  posSettingsPanel: IPosSettingsPanel;
  locationId: string;
  companyIntegrations?: ICompanyIntegrations;
}

const SquareSettingsPOS = (props: Props) => {
  const { updateLocationParam, posSettingsPanel, locationId, companyIntegrations } = props;

  const dispatch = useDispatch();
  const activated = !!companyIntegrations?.square?.auth?.tokenInfo?.accessToken;
  React.useEffect(() => {
    dispatch(loadSquarePOSLocations(locationId));
  }, [dispatch, locationId]);

  const mapSelectLocationOptions = (optionValue: any): Option | null => {
    if (posSettingsPanel.locations && posSettingsPanel.locations.length > 0) {
      return posSettingsPanel.locations.find((optionObj: Option) => optionObj.value === optionValue) as Option;
    }
    return null;
  };
  return (
    <>
      {!activated && (
        <Notification type={'warning'}>
          <p>
            <i className={Icon.InfoCircle} />
            <span>
              The client must authorize and connect their Square account on the Company Settings page before set up can
              be completed.
            </span>
          </p>
        </Notification>
      )}
      <FormControl label='Square Location'>
        <SelectFilter
          options={posSettingsPanel.locations}
          value={mapSelectLocationOptions(posSettingsPanel.optionsSquare.squareLocationId)}
          placeholder='Select a location'
          onChange={(event) => {
            updateLocationParam(event?.value, LOCATION_SETTINGS_ACTIONS.UPDATE_POS_SQUARE_LOCATION_ID);
          }}
          loading={posSettingsPanel.loadingLocations}
          disabled={!activated}
          isSearchable
          isClearable
        />
      </FormControl>
    </>
  );
};

export default SquareSettingsPOS;
