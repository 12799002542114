import { format } from 'date-fns';
import { VariableTypes } from '../../enums/variables.enum';
import { isBeginningOfTime } from '../dateUtils';
import { formatDateTime } from '../formatUtils';

const replaceCustomStringVariables = (
  customString: string,
  varToReplace: VariableTypes,
  replacementValue: any
): string => {
  const getTimeFormatted = (date: Date): string => {
    if (isBeginningOfTime(date)) {
      return format(date, 'h:mm aa');
    }
    return formatDateTime(date);
  };

  switch (varToReplace) {
    // todo: add more types as we move along
    case VariableTypes.Time:
      const value = !(replacementValue instanceof Date) ? new Date(replacementValue) : replacementValue;
      return customString.replace(/%TIME%/gim, getTimeFormatted(value));
  }
};

export default replaceCustomStringVariables;
