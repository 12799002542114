import React, { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import styles from './CancelOrderDialog.module.scss';
import { AppState } from '../../../redux/initialStates/AppState';
import {
  setCancelOrderDialogOpen,
  setCancelOrderDialogMessage,
  cancelOrder,
} from '../../../redux/actions/orders/orderDetailActions';

import { Modal, ModalHeader, ModalBody, ModalFooter } from '../../../components/Modal';
import { useParams } from 'react-router-dom';
import { ContextParams } from '../../../types/ContextParams.interface';
import DialogSMSInput from './DialogSMSInput';
import getIdentitySmsNumber from '../../../utils/orderUtils/getIdentityInfoSmsNumber';
import {
  OrderFulfillmentStatus,
  IOrderGiftCardTotals,
  OrderSmsMessageSource,
  ISmsConfig,
} from '@ready/dashboardv2api.contracts';
import { calculateRefundedAmount } from '../../../utils/orderUtils/getOrderRefund';
import {
  PrincipalPermissions,
  OrderResourceActions,
  ResourceType,
  SecurityScope,
  Verifier,
} from '@ready/security.core';

export type CancelOrderDialogProps = ReduxProps & {
  currentStatus: OrderFulfillmentStatus;
  giftCardPaymentInfo?: IOrderGiftCardTotals;
};

const CancelOrderDialog = (props: CancelOrderDialogProps) => {
  const {
    amountPaid,
    refundInfo,
    currentStatus,
    giftCardPaymentInfo,
    invoiceId,
    orderSmsCancelMessage,
    messageLoading,
    identityInfo,
    open,
    processing,
    setCancelOrderDialogOpen,
    setCancelOrderDialogMessage,
    cancelOrder,
    locationDetails,
    orderSmsConfigs,
    orderId,
    permissionsList,
  } = props;

  const { contextId, locationId } = useParams<ContextParams>();

  const orderSmsNumber = identityInfo ? getIdentitySmsNumber(identityInfo) : null;

  const hasOrdersAllPermission = Verifier.check(
    new PrincipalPermissions(permissionsList),
    SecurityScope.location,
    ResourceType.order,
    OrderResourceActions.all,
    locationId
  );

  const hasViewOrderSmsMessagesPermission = Verifier.check(
    new PrincipalPermissions(permissionsList),
    SecurityScope.location,
    ResourceType.order,
    OrderResourceActions.allExceptEditingOutgoingSmsMessage,
    locationId
  );

  const handleSetShowModal = React.useCallback(
    (open: boolean) => {
      setCancelOrderDialogOpen(open);
    },
    [setCancelOrderDialogOpen]
  );

  const handleCancelOrder = React.useCallback(async () => {
    // First, assume the amount to be refunded is the total
    // amount paid.
    let toRefundAmount = amountPaid;
    const amountRefundedIncludingGC = calculateRefundedAmount(refundInfo);

    // If refund info exists while displaying the cancel order
    // dialog, it means it is partially refunded. Therefore only attempt
    // to refund remaining amount left on the transaction.
    // Keep in mind we need to check if amount paid exists (it might not).
    // If it does, calculate refund amount by subtracting the already
    // refunded amount.
    if (amountRefundedIncludingGC && amountPaid) {
      toRefundAmount = amountPaid - amountRefundedIncludingGC;
    }

    await cancelOrder(
      contextId,
      locationId,
      orderId,
      currentStatus,
      OrderFulfillmentStatus.Cancelled,
      invoiceId,
      orderSmsCancelMessage,
      toRefundAmount,
      giftCardPaymentInfo
    );
  }, [
    amountPaid,
    refundInfo,
    cancelOrder,
    contextId,
    locationId,
    orderId,
    currentStatus,
    invoiceId,
    orderSmsCancelMessage,
    giftCardPaymentInfo,
  ]);

  const selectedOrderSmsConfig: ISmsConfig =
    orderSmsConfigs.messageSource === OrderSmsMessageSource.Location
      ? orderSmsConfigs.locationConfig
      : orderSmsConfigs.companyConfig;

  const handleBackOut = () => {
    setCancelOrderDialogOpen(false);
    setCancelOrderDialogMessage(selectedOrderSmsConfig.orderCancelled?.value ?? '');
  };

  const handleMessageChange = React.useCallback(
    (value: string) => {
      setCancelOrderDialogMessage(value);
    },
    [setCancelOrderDialogMessage]
  );
  const hasPermissionsToSeeCancelledOrderMessage =
    (hasOrdersAllPermission || hasViewOrderSmsMessagesPermission) &&
    locationDetails &&
    locationDetails?.settings?.orderSmsEnabled &&
    selectedOrderSmsConfig.orderCancelled?.isActive;

  return open ? (
    <Modal setShowModal={handleSetShowModal}>
      <ModalHeader headerLabel='Cancel Order?' setShowModal={handleSetShowModal} />
      <ModalBody>
        {hasPermissionsToSeeCancelledOrderMessage && orderSmsNumber ? (
          <>
            <DialogMessageWithSms amountPaid={amountPaid} smsNumber={orderSmsNumber} />
            <DialogSMSInput
              value={orderSmsCancelMessage}
              loading={messageLoading}
              processing={processing}
              onChange={handleMessageChange}
              withPencilIcon={hasOrdersAllPermission}
            />
          </>
        ) : (
          <DialogMessage amountPaid={amountPaid} />
        )}
      </ModalBody>
      <ModalFooter
        primaryLabel='Cancel Order'
        primaryActionHandler={handleCancelOrder}
        loading={processing}
        secondaryLabel="Don't Cancel"
        secondaryActionHandler={handleBackOut}
      />
    </Modal>
  ) : null;
};

const mapStateToProps = (state: AppState) => {
  return {
    amountPaid: state.orders.orderDetail.amountPaid,
    refundInfo: state.orders.orderDetail.refundInfo,
    invoiceId: state.orders.orderDetail.invoiceId,
    messageLoading: state.orders.orderDetail.cancelDialogMessageLoading,
    identityInfo: state.orders.orderDetail.identityInfo,
    open: state.orders.orderDetail.cancelDialogOpen,
    processing: state.orders.orderDetail.cancelProcessing,
    orderSmsCancelMessage: state.orders.orderDetail.cancelDialogMessage,
    orderId: state.orders.orderDetail.orderId,
    locationDetails: state.orders.orderList.locationDetails,
    orderSmsConfigs: state.orders.orderSmsConfigs.orderSmsConfigData,
    permissionsList: state.session.permissions.permissionsList,
  };
};

const actionCreators = {
  setCancelOrderDialogOpen,
  setCancelOrderDialogMessage,
  cancelOrder,
};

const connector = connect(mapStateToProps, actionCreators);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(CancelOrderDialog);

interface DialogMessageWithSmsProps {
  smsNumber: string;
  amountPaid?: number;
}

interface DialogMessageProps {
  amountPaid?: number;
}

const DialogMessageWithSms: FC<DialogMessageWithSmsProps> = ({ smsNumber, amountPaid }) => (
  <div className={styles.modalMessage}>
    {amountPaid === 0
      ? 'Are you sure you want to cancel this order? This action cannot be undone. '
      : "Cancelling this order will refund the guest's payment in full. Are you sure you want to cancel this order? "}
    The guest will be notified via <b>text</b> to <b>{smsNumber}</b>.
  </div>
);

const DialogMessage: FC<DialogMessageProps> = ({ amountPaid }) => (
  <div className={styles.modalMessage}>
    {amountPaid === 0
      ? 'Are you sure you want to cancel this order? This action cannot be undone.'
      : "Cancelling this order will refund the guest's payment in full. Are you sure you want to cancel this order?"}
  </div>
);
