import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useSession } from '../../hooks/useSession';
import LayoutContent from '../../components/AppLayout/LayoutContent';
import EmptyResult from '../../components/EmptyResult/EmptyResult';
import { ISession, ISidebarOption } from '../../redux/initialStates/session/session';
import { isValidSidebarOption } from '../../utils/sidebar.utils';

export interface HomeProps {
  session: ISession;
}

const Home = (props: HomeProps) => {
  const { session } = props;
  const { push } = useHistory();
  // session
  useSession(session);

  // redirect logic
  useRedirect(session, push);

  // get userName
  const userName = session?.userSession?.userName;
  const welcomeTitle = userName ? `Welcome, ${userName}` : 'Welcome';

  return (
    <LayoutContent title={welcomeTitle} containerType='within'>
      <div className='page-with-empty-result'>
        <EmptyResult
          title='Your Invitation Awaits'
          paragraph='It looks like you haven’t joined a company yet. Before you can access any content, please contact your company’s admin and have them invite you to join their company.'
        />
      </div>
    </LayoutContent>
  );
};

const useRedirect = (session: ISession, push: (pathname: string) => void) => {
  // if permissionsSet and permissions > 0, redirect to first one
  React.useEffect(() => {
    if (session.sidebar.optionsSet && !!session.contextSession.type) {
      const sidebarOptions = session.sidebar.options;
      const contextType = session.contextSession.type;
      const isValidOption = (option: ISidebarOption) => {
        return isValidSidebarOption(contextType, option) && option.enabled && option.hasPermission;
      };
      const firstSidebarOption = sidebarOptions.find(isValidOption) as ISidebarOption;
      const firstOptionUrl = firstSidebarOption ? firstSidebarOption.url : '';
      const redirectPrefix = contextType === 'company' ? `/companies/${session.contextSession.id}` : '';
      const redirectUrl = `${redirectPrefix}${firstOptionUrl}`;
      push(redirectUrl);
    }
  }, [session.sidebar, session.contextSession.type, session.contextSession.id, push]);
};

const mapStateToProps = (state: any) => ({
  session: state.session,
});

export default connect(mapStateToProps)(Home);
