import { IBulkEditMenuItemRequest, IMenuItem, IMenuItemRequest } from '@ready/menu.core';
import executeApiAsync from 'services/utils/executeApiAsync';
import config from 'config';
import { undefinedWithNullReplacer } from 'utils/objectUtils/replaceUndefinedWithNull';

export const editItemLocationSettings = async (
  companyId: string,
  locationId: string,
  request: IMenuItemRequest
): Promise<IMenuItemRequest> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/menus/items`;
  return await executeApiAsync<IMenuItemRequest>(url, {
    method: 'PUT',
    body: JSON.stringify(request),
  });
};

export const bulkEditLocationItemSettings = async (
  companyId: string,
  itemId: string,
  request: IBulkEditMenuItemRequest
): Promise<IBulkEditMenuItemRequest> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/items/${itemId}/locationItems`;

  return await executeApiAsync<IBulkEditMenuItemRequest>(url, {
    method: 'PUT',
    body: JSON.stringify(request, undefinedWithNullReplacer),
  });
};

export const getMenuItemAtLocation = async (
  companyId: string,
  locationId: string,
  menuItemId: string
): Promise<IMenuItem> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/menus/items/${menuItemId}`;
  return await executeApiAsync<IMenuItem>(url);
};
