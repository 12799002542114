import React from 'react';

import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import TextIcon, { Icon } from '../Icon/TextIcon';

export interface DragHandleProps {
  providedDragHandleProps?: DraggableProvidedDragHandleProps;
  className?: string;
}

const DragHandle = ({ providedDragHandleProps, className }: DragHandleProps): JSX.Element => (
  <div {...providedDragHandleProps} className={className}>
    <TextIcon icon={Icon.Drag} />
  </div>
);

export default DragHandle;
