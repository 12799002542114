import produce from 'immer';
import { Action } from '../../redux/types';
import { LoyaltyProvider } from '@ready/dashboardv2api.contracts';
import { PANEL_LOYALTY_ACTIONS } from './PanelLoyaltyActions';
import { LOCATION_SETTINGS_ACTIONS } from './LocationSettingsActions';
import {
  newByPassValidations,
  newPunchhValidations,
  newSpendgoValidations,
  newSquareValidations,
  posLoyaltyPanelInitialState,
} from './PanelLoyaltyState';

export const posLoyaltyPanelReducer = (state = posLoyaltyPanelInitialState, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case PANEL_LOYALTY_ACTIONS.SET_LOYALTY_TAB:
        draft = { ...draft, ...action.payload };
        return draft;

      case PANEL_LOYALTY_ACTIONS.UPDATE_SPENDGO_ACCOUNT_ID:
        draft.spendgoOptions!.brandId = action.payload;
        return draft;

      case PANEL_LOYALTY_ACTIONS.UPDATE_SPENDGO_STORE_CODE:
        draft.spendgoOptions!.locationId = action.payload;
        return draft;

      case PANEL_LOYALTY_ACTIONS.UPDATE_SPENDGO_API_KEY:
        draft.spendgoOptions!.clientId = action.payload;
        return draft;

      case PANEL_LOYALTY_ACTIONS.UPDATE_SPENDGO_ACCOUNT_DETAILS_URL:
        draft.spendgoOptions!.accountDetailsUrl = action.payload;
        return draft;

      case PANEL_LOYALTY_ACTIONS.UPDATE_SPENDGO_CLIENT_SECRET:
        draft.spendgoOptions!.clientSecret = action.payload;
        return draft;

      case PANEL_LOYALTY_ACTIONS.UPDATE_SPENDGO_LOYALTY_DISCOUNT:
        draft.spendgoOptions!.loyaltyDiscount = action.payload;
        return draft;

      case PANEL_LOYALTY_ACTIONS.UPDATE_PUNCHH_LOYALTY_DISCOUNT:
        draft.punchhOptions!.loyaltyDiscount = action.payload;
        return draft;

      case PANEL_LOYALTY_ACTIONS.LOAD_AVAILABLE_DISCOUNTS_BEGIN:
        draft.loadingDiscounts = true;
        return draft;

      case PANEL_LOYALTY_ACTIONS.LOAD_AVAILABLE_DISCOUNTS_SUCCESS:
        draft.discounts = action.payload;
        draft.loadingDiscounts = false;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.LOAD_SQUARE_LOCATIONS_BEGIN:
        draft.locations = [];
        draft.loadingLocations = true;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.LOAD_SQUARE_LOCATIONS_SUCCESS:
        draft.locations = action.payload;
        draft.loadingLocations = false;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.LOAD_SQUARE_LOCATIONS_ERROR:
        draft.loadingLocations = false;
        return draft;

      case PANEL_LOYALTY_ACTIONS.SET_LOYALTY_PANEL:
        draft.provider = action.payload.provider;
        draft.spendgoOptions = action.payload.spendgoOptions;
        draft.byPassOptions = action.payload.byPassOptions;
        draft.squareOptions = action.payload.squareOptions;
        draft.punchhOptions = action.payload.punchhOptions;
        draft.validations.spendgo = newSpendgoValidations();
        draft.validations.byPass = newByPassValidations();
        draft.validations.square = newSquareValidations();
        draft.validations.punchh = newPunchhValidations();
        return draft;

      case PANEL_LOYALTY_ACTIONS.SET_SELECTED_PROVIDER:
        draft.provider = action.payload === 'none' ? null : action.payload;
        draft.validations.spendgo = newSpendgoValidations();
        draft.validations.byPass = newByPassValidations();
        draft.validations.square = newSquareValidations();
        draft.validations.punchh = newPunchhValidations();
        return draft;

      case PANEL_LOYALTY_ACTIONS.SAVE_ITEM_VALIDATION_ERROR:
        const { provider, fields } = action.payload;
        if (provider === LoyaltyProvider.ByPass) {
          draft.validations.byPass = fields;
        }
        if (provider === LoyaltyProvider.Spendgo) {
          draft.validations.spendgo = fields;
        }
        if (provider === LoyaltyProvider.Square) {
          draft.validations.square = fields;
        }
        if (provider === LoyaltyProvider.Punchh) {
          draft.validations.punchh = fields;
        }
        return draft;

      case PANEL_LOYALTY_ACTIONS.UPDATE_BYPASS_LOCATION_ID:
        draft.byPassOptions!.locationId = action.payload;
        return draft;

      case PANEL_LOYALTY_ACTIONS.UPDATE_PUNCHH_STORE_ID:
        draft.punchhOptions!.storeId = action.payload;
        return draft;

      case PANEL_LOYALTY_ACTIONS.UPDATE_SQUARE_LOCATION_ID:
        draft.squareOptions!.locationId = action.payload;
        return draft;

      case PANEL_LOYALTY_ACTIONS.UPDATE_SQUARE_PROGRAM_ID:
        draft.squareOptions!.programId = action.payload;
        return draft;

      case PANEL_LOYALTY_ACTIONS.UPDATE_SQUARE_API_KEY:
        draft.squareOptions!.apiKey = action.payload;
        return draft;

      case PANEL_LOYALTY_ACTIONS.UPDATE_SQUARE_LOYALTY_DISCOUNT:
        draft.squareOptions!.loyaltyDiscount = action.payload;
        return draft;

      case PANEL_LOYALTY_ACTIONS.UPDATE_SQUARE_SKIP_PHONE_VERIFICATION:
        draft.squareOptions!.skipPhoneVerification = !action.payload;
        return draft;

      default:
        return draft;
    }
  });
};
