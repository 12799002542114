import { ICountry, IState, IAddressListOptions } from './types/address.type';
import { Option } from '../components/SelectFilter/SelectFilter';
import * as countries from '../data/countries.json';

const getCountryListOptions = (countries: ICountry[]): Option[] => {
  const countryOptions = countries.map((country: ICountry) => {
    return {
      value: country.Code,
      label: country.Name,
    };
  });

  return countryOptions as Option[];
};

const getStateListOptions = (countries: ICountry[]): Option[] => {
  const states = countries
    .map((country: ICountry) => {
      return country.States as IState[];
    })
    .flat();

  const stateOptions = states.map((state: IState) => {
    return {
      value: state.Code,
      label: state.Name,
    };
  });

  return stateOptions as Option[];
};

export const getAddressListOptions = async (): Promise<IAddressListOptions> => {
  try {
    const allCountriesData = countries as any; // imported json file comes with an initial external property of default
    const allCountries: ICountry[] = allCountriesData.default;

    const relevantCountries = allCountries.filter((country: ICountry) => {
      return country.Name === 'Canada' || country.Name === 'United States';
    });

    return {
      countryList: getCountryListOptions(relevantCountries),
      stateList: getStateListOptions(relevantCountries),
    };
  } catch (e) {
    console.error(`Cannot get country list`);
    throw e;
  }
};
