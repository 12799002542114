import { ICompanyIntegrations } from '@ready/dashboardv2api.contracts';
import config from '../../config';
import executeApiAsync from '../../services/utils/executeApiAsync';

export class CompanyIntegrations {
  static BASE_URL = `${config.readyApiLegacyEndpoint}`;

  public static async getIntegrations(companyId: string): Promise<ICompanyIntegrations> {
    const url = `${CompanyIntegrations.BASE_URL}/companies/${companyId}/integrations`;
    return await executeApiAsync<ICompanyIntegrations>(url);
  }
}
