import { DayOfWeek, IPromoCode } from '@ready/dashboardv2api.contracts';
import { DayOfWeek as MenuDayOfWeek } from '@ready/menu.core';
import PromoCodeService from '../services/PromoCodeService';
import { set200Toast, toastErrorState } from '../../redux/actions/uiActions/responseStateActions';

export const PROMO_CODES_ACTIONS = {
  LOAD_PROMO_CODES: 'LOAD_PROMO_CODES',

  LOAD_PROMO_CODE_POS_DISCOUNTS: 'LOAD_PROMO_CODE_POS_DISCOUNTS',

  LOAD_PROMO_CODE_BEGIN: 'LOAD_PROMO_CODE_BEGIN',
  LOAD_PROMO_CODE_SUCCESS: 'LOAD_PROMO_CODE_SUCCESS',
  LOAD_PROMO_CODE_ERROR: 'LOAD_PROMO_CODE_ERROR',

  UPDATE_PROMO_CODE_STATUS: 'UPDATE_PROMO_CODE_STATUS',
  UPDATE_PROMO_CODE_CODE: 'UPDATE_PROMO_CODE_NAME',
  UPDATE_PROMO_CODE_DESCRIPTION: 'UPDATE_PROMO_CODE_DESCRIPTION',
  UPDATE_PROMO_CODE_POS_DISCOUNT: 'UPDATE_PROMO_CODE_POS_DISCOUNT',
  UPDATE_PROMO_CODE_VALIDATION: 'UPDATE_PROMO_CODE_VALIDATION',
  UPDATE_PROMO_CODE_AUTO_ATTACH: 'UPDATE_PROMO_CODE_AUTO_ATTACH',
  UPDATE_PROMO_CODE_TIME_SLOT: 'UPDATE_PROMO_CODE_TIME_SLOT',
  UPDATE_PROMO_CODE_ADD_TIME_SLOT: 'UPDATE_PROMO_CODE_ADD_TIME_SLOT',
  UPDATE_PROMO_CODE_REMOVE_TIME_SLOT: 'UPDATE_PROMO_CODE_REMOVE_TIME_SLOT',

  VALIDATE_PROMO_CODE: 'VALIDATE_PROMO_CODE',
  SAVE_PROMO_CODE_BEGIN: 'SAVE_PROMO_CODE_BEGIN',
  SAVE_PROMO_CODE_SUCCESS: 'SAVE_PROMO_CODE_SUCCESS',
  SAVE_PROMO_CODE_ERROR: 'SAVE_PROMO_CODE_ERROR',

  PREPARE_DELETE_PROMO_CODE_MODAL: 'PREPARE_DELETE_PROMO_CODE_MODAL',
  DELETE_PROMO_CODE_BEGIN: 'DELETE_PROMO_CODE_BEGIN',
  DELETE_PROMO_CODE_SUCCESS: 'DELETE_PROMO_CODE_SUCCESS',
  DELETE_PROMO_CODE_ERROR: 'DELETE_PROMO_CODE_ERROR',

  RESET_PROMO_CODE_FORM: 'RESET_PROMO_CODE_FORM',
};

export const loadPromoCodes = (locationId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: PROMO_CODES_ACTIONS.LOAD_PROMO_CODES,
      payload: await PromoCodeService.loadPromoCodes(locationId),
    });
  } catch (err) {
    dispatch(toastErrorState(err.status, err.message));
  }
};

export const loadPromoCode = (locationId: string, id: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: PROMO_CODES_ACTIONS.LOAD_PROMO_CODE_BEGIN,
    });
    const promoCode = await PromoCodeService.loadPromoCode(locationId, id);
    dispatch({
      type: PROMO_CODES_ACTIONS.LOAD_PROMO_CODE_SUCCESS,
      payload: promoCode,
    });
  } catch (err) {
    dispatch({
      type: PROMO_CODES_ACTIONS.LOAD_PROMO_CODE_ERROR,
    });
    dispatch(toastErrorState(err.status, err.message));
  }
};

export const resetPromoCodeForm = () => ({
  type: PROMO_CODES_ACTIONS.RESET_PROMO_CODE_FORM,
});

export const loadPromoCodePosDiscounts = (locationId: string, companyId: string) => async (dispatch: any) => {
  dispatch({
    type: PROMO_CODES_ACTIONS.LOAD_PROMO_CODE_POS_DISCOUNTS,
    payload: await PromoCodeService.loadPosDiscounts(locationId, companyId),
  });
};

export const updatePromoCodeStatus = (enabled: boolean) => ({
  type: PROMO_CODES_ACTIONS.UPDATE_PROMO_CODE_STATUS,
  payload: enabled,
});

export const updatePromoCodeName = (name: string) => ({
  type: PROMO_CODES_ACTIONS.UPDATE_PROMO_CODE_CODE,
  payload: name,
});

export const updatePromoCodeDescription = (description: string) => ({
  type: PROMO_CODES_ACTIONS.UPDATE_PROMO_CODE_DESCRIPTION,
  payload: description,
});

export const updatePromoCodePosDiscount = (posDiscount: string) => ({
  type: PROMO_CODES_ACTIONS.UPDATE_PROMO_CODE_POS_DISCOUNT,
  payload: posDiscount,
});

export const updatePromoCodeValidation = (validation: string) => ({
  type: PROMO_CODES_ACTIONS.UPDATE_PROMO_CODE_VALIDATION,
  payload: validation,
});

export const updatePromoCodeAutoAttach = (autoAttach: boolean) => ({
  type: PROMO_CODES_ACTIONS.UPDATE_PROMO_CODE_AUTO_ATTACH,
  payload: autoAttach,
});

export const updatePromoCodeTimeSlot = (
  index: number,
  daysOfWeek: number[],
  startHour?: number,
  startMinute?: number,
  endHour?: number,
  endMinute?: number
) => ({
  type: PROMO_CODES_ACTIONS.UPDATE_PROMO_CODE_TIME_SLOT,
  payload: {
    index,
    timeSlot: {
      daysAvailable: [
        // Front-end and back-end use slightly different methods of tracking the selected days:
        // front-end uses 1-based day numbers starting on Monday, while back-end uses 0-based day
        // numbers starting on Sunday; front-end uses an array that only contains the selected days,
        // while back-end uses an array that contains all days and a boolean indicating whether or
        // not that day is selected. The difference is because we re-use front-end components that
        // do similar time slot selection for other modules, but the back-ends were developed
        // differently.
        { day: DayOfWeek.Sunday, available: daysOfWeek.includes(MenuDayOfWeek.sunday) },
        { day: DayOfWeek.Monday, available: daysOfWeek.includes(MenuDayOfWeek.monday) },
        { day: DayOfWeek.Tuesday, available: daysOfWeek.includes(MenuDayOfWeek.tuesday) },
        { day: DayOfWeek.Wednesday, available: daysOfWeek.includes(MenuDayOfWeek.wednesday) },
        { day: DayOfWeek.Thursday, available: daysOfWeek.includes(MenuDayOfWeek.thursday) },
        { day: DayOfWeek.Friday, available: daysOfWeek.includes(MenuDayOfWeek.friday) },
        { day: DayOfWeek.Saturday, available: daysOfWeek.includes(MenuDayOfWeek.saturday) },
      ],
      startHour,
      startMinute,
      endHour,
      endMinute,
    },
  },
});

export const updatePromoCodeAddTimeSlot = () => ({
  type: PROMO_CODES_ACTIONS.UPDATE_PROMO_CODE_ADD_TIME_SLOT,
  payload: {
    daysAvailable: [
      { day: DayOfWeek.Sunday, available: false },
      { day: DayOfWeek.Monday, available: false },
      { day: DayOfWeek.Tuesday, available: false },
      { day: DayOfWeek.Wednesday, available: false },
      { day: DayOfWeek.Thursday, available: false },
      { day: DayOfWeek.Friday, available: false },
      { day: DayOfWeek.Saturday, available: false },
    ],
  },
});

export const updatePromoCodeRemoveTimeSlot = (index: number) => ({
  type: PROMO_CODES_ACTIONS.UPDATE_PROMO_CODE_REMOVE_TIME_SLOT,
  payload: index,
});

export const validatePromoCode = (promoCode: IPromoCode) => ({
  type: PROMO_CODES_ACTIONS.VALIDATE_PROMO_CODE,
  payload: PromoCodeService.validatePromoCode(promoCode),
});

export const savePromoCode = (locationId: string, promoCode: IPromoCode) => async (dispatch: any) => {
  dispatch({
    type: PROMO_CODES_ACTIONS.SAVE_PROMO_CODE_BEGIN,
  });
  try {
    dispatch({
      type: PROMO_CODES_ACTIONS.SAVE_PROMO_CODE_SUCCESS,
      payload: await PromoCodeService.savePromoCode(locationId, promoCode),
    });
  } catch (err) {
    dispatch({
      type: PROMO_CODES_ACTIONS.SAVE_PROMO_CODE_ERROR,
    });
    dispatch(toastErrorState(err.status, err.message));
  }
};

export const prepareDeletePromoCodeModal = (show: boolean) => ({
  type: PROMO_CODES_ACTIONS.PREPARE_DELETE_PROMO_CODE_MODAL,
  payload: show,
});

export const deletePromoCode = (locationId: string, promoCodeId: string) => async (dispatch: any) => {
  dispatch({
    type: PROMO_CODES_ACTIONS.DELETE_PROMO_CODE_BEGIN,
  });
  try {
    await PromoCodeService.deletePromoCode(locationId, promoCodeId);
    dispatch(set200Toast('Promo code deleted.'));
    dispatch({
      type: PROMO_CODES_ACTIONS.DELETE_PROMO_CODE_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: PROMO_CODES_ACTIONS.DELETE_PROMO_CODE_ERROR,
    });
    dispatch(toastErrorState(err.status, err.message));
  }
};
