import { PaginationResponse } from '@ready/dashboardv2api.contracts';
import { ISchedule } from '@ready/menu.core';
import executeApiAsync from '../../services/utils/executeApiAsync';
import config from '../../config';
import toPaginationResponse from '../../services/utils/toPaginationResponse';
import { IPagedResponse } from '../../services/types/IPagedResponse.type';

type IScheduleListResponse = PaginationResponse<ISchedule>;

export default class ScheduleService {
  static async getScheduleList(
    companyId: string,
    locationId?: string,
    query: string = '',
    page: number = 1,
    limit: number = 50
  ): Promise<IScheduleListResponse> {
    const url = !locationId
      ? `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/schedules?searchTerm=${query}&pageSize=${limit}&pageNumber=${page}`
      : `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/menus/schedules?searchTerm=${query}&pageSize=${limit}&pageNumber=${page}`;

    return toPaginationResponse(await executeApiAsync<IPagedResponse<ISchedule>>(url), page, limit);
  }

  static async getSchedule(companyId: string, scheduleId: string): Promise<ISchedule> {
    const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/schedules/${scheduleId}`;
    return await executeApiAsync<ISchedule>(url);
  }

  static async createSchedule(companyId: string, schedule: ISchedule): Promise<ISchedule> {
    const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/schedules`;

    return await executeApiAsync<ISchedule>(url, {
      method: 'POST',
      body: JSON.stringify(schedule),
    });
  }

  static async updateSchedule(companyId: string, schedule: ISchedule): Promise<ISchedule> {
    const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/schedules`;

    return await executeApiAsync<ISchedule>(url, {
      method: 'PUT',
      body: JSON.stringify(schedule),
    });
  }

  static async deleteSchedule(companyId: string, scheduleId: string): Promise<void> {
    const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/schedules/${scheduleId}`;
    await executeApiAsync<ISchedule>(url, { method: 'DELETE' });
  }
}
