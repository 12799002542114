import produce from 'immer';
import { Action } from '../../redux/types';
import { PANEL_LOYALTY_ACTIONS } from './PanelLoyaltyActions';
import { cachedLoyalty } from './CachedLoyaltyState';

export const cachedLoyaltyReducer = (state = cachedLoyalty, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case PANEL_LOYALTY_ACTIONS.SET_LOYALTY_CACHE:
        draft = { ...action.payload };
        return draft;

      default:
        return draft;
    }
  });
};
