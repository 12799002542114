import LayoutContent from 'components/AppLayout/LayoutContent';
import React, { ReactNode } from 'react';
import { SharedMenuItemsTabs, SharedMenuItemsTabId } from './SharedMenuItemsTabs';
import { useParams } from 'react-router-dom';
import { pageErrorState } from 'redux/actions/uiActions/responseStateActions';
import { selectPermissions } from 'redux/selectors/sessionSelectors/sessionSelectors';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { ContextParams } from 'types/ContextParams.interface';
import {
  PrincipalPermissions,
  ResourceType,
  SecurityScope,
  TemplateMenuResourceActions,
  Verifier,
} from '@ready/security.core';

interface Props {
  tab?: SharedMenuItemsTabId;
  children?: ReactNode;
}

const SharedMenuItemsLayout = (props: Props): JSX.Element | null => {
  const { children = null, tab } = props;
  const { permissionsList } = useAppSelector(selectPermissions);
  const { contextId: companyId } = useParams<ContextParams>();
  const dispatch = useAppDispatch();
  const responseStatus = useAppSelector((state) => state.ui.responseState.responseStatus);

  const hasPermissionAll = Verifier.check(
    new PrincipalPermissions(permissionsList),
    SecurityScope.company,
    ResourceType.templateMenu,
    TemplateMenuResourceActions.all,
    companyId
  );

  // OR operator covers if the errors state has been reset
  if (!hasPermissionAll || (!responseStatus && !hasPermissionAll)) {
    dispatch(pageErrorState(401));
    return null;
  }

  return (
    <LayoutContent title='Menu Builder' containerType='within'>
      {!!tab && <SharedMenuItemsTabs tab={tab} />}
      {children}
    </LayoutContent>
  );
};

export { SharedMenuItemsLayout };
