import { combineReducers } from 'redux';
import { transactionsListReducer } from './transactionsListReducer';
import { transactionDetailReducer } from './transactionDetailReducer';
import { permittedLocationsReducer } from './permittedLocationsReducer';
import { transactionDetailReducerNew } from './transactionDetailReducerNew';

export const transactionReducer = combineReducers({
  transactionsList: transactionsListReducer,
  transactionDetail: transactionDetailReducer,
  permittedLocations: permittedLocationsReducer,
  transactionDetailNew: transactionDetailReducerNew,
});
