import { DigitalMenuMode } from '@ready/dashboardv2api.contracts';
import { Option } from '../../../components/SelectFilter/SelectFilter';
export const DIGITAL_MENU_OPTIONS: Option[] = [
  { label: 'None', value: "" },
  {
    label: 'Link to Online Menu',
    value: DigitalMenuMode.Iframe,
  },
  {
    label: 'Static Image',
    value: DigitalMenuMode.Image,
  },
];
