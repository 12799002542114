import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { AppState } from '../../../redux/initialStates/AppState';
import ItemsAndGroupsView from '../../types/ItemsAndGroupsView.enum';
import { setItemsAndGroupsModalQuery, setItemsAndGroupsModalView } from '../../redux/MenusActions';

import styles from './AddItemsAndGroupsSearch.module.scss';

import SearchInput from '../../../components/SearchInput/SearchInput';
import ToggleButtons, { ToggleButton } from '../../../components/ToggleButtons/ToggleButtons';
import Button from '../../../components/Button/Button';

export type AddMenuItemsSearchProps = ConnectedProps<typeof connector> & {
  selectionType: ItemsAndGroupsView;
  onNewGroupClick: () => void;
};

const AddMenuItemsSearch = (props: AddMenuItemsSearchProps) => {
  const {
    view,
    query,
    selectionType,
    setItemsAndGroupsModalView,
    setItemsAndGroupsModalQuery,
    onNewGroupClick,
  } = props;

  const filterButtons: ToggleButton[] = [
    {
      label: 'Menu Items',
      selected: view === ItemsAndGroupsView.ITEMS,
      onClick: () => setItemsAndGroupsModalView(ItemsAndGroupsView.ITEMS),
    },
    {
      label: 'Item Groups',
      selected: view === ItemsAndGroupsView.GROUPS,
      onClick: () => setItemsAndGroupsModalView(ItemsAndGroupsView.GROUPS),
    },
  ];

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setItemsAndGroupsModalQuery(event.target.value);
  };

  return (
    <>
      <div className={styles.toggles}>
        <ToggleButtons buttons={filterButtons} />
      </div>
      {selectionType === ItemsAndGroupsView.ITEMS ? (
        <SearchInput
          value={query}
          placeholder='Search menu items'
          fullWidth
          onChange={handleQueryChange}
          autoFocus={true}
        />
      ) : (
        <div className={styles.container}>
          <div className={styles.input}>
            <SearchInput
              value={query}
              placeholder='Search item groups'
              fullWidth
              onChange={handleQueryChange}
              autoFocus={true}
            />
          </div>
          <div className={styles.button}>
            <Button label='+ New Group' size='large' variant='secondary' onClick={onNewGroupClick} />
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  view: state.menuBuilder.menus.itemsAndGroupsSelection.view,
  query: state.menuBuilder.menus.itemsAndGroupsSelection.query,
});

const actionCreators = {
  setItemsAndGroupsModalView,
  setItemsAndGroupsModalQuery,
};

const connector = connect(mapStateToProps, actionCreators);

export default connector(AddMenuItemsSearch);
