import { IPagination } from '@ready/dashboardv2api.contracts';
import { ILocation } from '../../../services/types/ILocation.type';

export interface ILocationList {
  loading: boolean;
  items: ILocation[];
  error: boolean;
  query: string;
  page: number;
  pagination: IPagination;
}

const pagination: IPagination = {
  start: 0,
  length: 0,
  limit: 0,
  total: 0,
};

export const locationsList = {
  loading: false,
  items: [],
  error: false,
  query: '',
  page: 0,
  pagination,
} as ILocationList;
