import { ICompanyLoyaltyConfig } from '@ready/dashboardv2api.contracts';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { saveCompanyLoyaltyConfig } from '../../service';
import { set200Toast, toastErrorState } from 'redux/actions/uiActions/responseStateActions';

interface Args extends ICompanyLoyaltyConfig {
  companyId: string;
}

export const saveCompanyLoyaltyConfigThunk = createAsyncThunk<ICompanyLoyaltyConfig, Args>(
  'companies/loyalty/configs/save',
  (args: Args, { dispatch }) => {
    try {
      const { companyId, ...config } = args;
      const result = saveCompanyLoyaltyConfig(companyId, config);
      dispatch(set200Toast('Success! Changes saved.'));
      return result;
    } catch (e) {
      dispatch(toastErrorState(e.status, e.message));
      throw e;
    }
  }
);
