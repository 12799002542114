import React from 'react';

import styles from './ModifierOptionsValidationNotification.module.scss';

import Notification from '../../../components/Notification/Notification';
import { Icon } from '../../../components/Icon/TextIcon';

export interface OptionsValidationNotificationProps {
  showMessage: boolean;
  message: string;
}

const ModifierOptionsValidationNotification = (props: OptionsValidationNotificationProps): JSX.Element => {
  const { showMessage, message } = props;

  return (
    <>
      {showMessage ? (
        <div className={styles.bannerWrapper}>
          <Notification>
            <p>
              <i className={Icon.Alert} />
              <span>{message}</span>
            </p>
          </Notification>
        </div>
      ) : null}
    </>
  );
};

export default ModifierOptionsValidationNotification;
