import { setYear, getYear, setMonth, getMonth, setDate, getDate } from 'date-fns';

const setDatePart = (date: Date, fromDate: Date) => {
  let newDate = new Date(date);

  newDate = setYear(newDate, getYear(fromDate));
  newDate = setMonth(newDate, getMonth(fromDate));
  newDate = setDate(newDate, getDate(fromDate));

  return newDate;
};

export default setDatePart;
