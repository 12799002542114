import { IRole } from '@ready/security.core';
import { FieldValidation } from '../../../types/FieldValidation.interface';

export type CreateableRole = Omit<IRole, '_id'>;

export interface RoleForm extends IRole {
  loading: boolean;
  processing: boolean;
  validation: RoleFormValidation;
  deleteRequested: boolean;
  isDeleted: boolean;
}

export interface RoleFormValidation {
  isValid: boolean;
  name: FieldValidation;
}

export const roleFormInitialState: RoleForm = {
  _id: '',
  companyId: '',
  name: '',
  description: '',
  companyPermissions: [],
  locationPermissions: [],
  loading: false,
  processing: false,
  deleteRequested: false,
  isDeleted: false,
  validation: {
    isValid: true,
    name: {
      error: '',
      hasError: false,
    },
  },
};
