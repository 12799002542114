import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import LayoutContent from '../../components/AppLayout/LayoutContent';
import SquareIntegration from '../components/SquareIntegration';
import TaxList from '../components/TaxList';
import { ContextParams } from '../../types/ContextParams.interface';
import { selectCompanySettingsState } from '../redux/CompanySettingsSelectors';
import { getCompanyIntegrations, getSmbLocations, getTaxes } from '../redux/CompanySettingsActions';
import {
  CompanyResourceActions,
  PrincipalPermissions,
  ResourceType,
  SecurityScope,
  Verifier,
} from '@ready/security.core';
import { selectPermissions } from '../../redux/selectors/sessionSelectors/sessionSelectors';
import { pageErrorState } from '../../redux/actions/uiActions/responseStateActions';

const Integrations = () => {
  const { contextId, locationId } = useParams<ContextParams>();

  const dispatch = useDispatch();

  const {
    companyIntegrations: { loading, integrations },
    smbTaxes: { loading: taxesLoading, taxes, smbConfiguredLocations },
  } = useSelector(selectCompanySettingsState);

  React.useEffect(() => {
    dispatch(getSmbLocations(contextId));
    dispatch(getCompanyIntegrations(contextId));
  }, [contextId, dispatch]);

  React.useEffect(() => {
    if (smbConfiguredLocations.length > 0) {
      dispatch(getTaxes(contextId));
    }
  }, [contextId, dispatch, smbConfiguredLocations]);

  const { permissionsList } = useSelector(selectPermissions);
  const hasPermissionAll = Verifier.check(
    new PrincipalPermissions(permissionsList),
    SecurityScope.company,
    ResourceType.company,
    CompanyResourceActions.all,
    locationId
  );
  const hasPermissionView = Verifier.check(
    new PrincipalPermissions(permissionsList),
    SecurityScope.company,
    ResourceType.company,
    CompanyResourceActions.view,
    locationId
  );

  if (!hasPermissionAll && !hasPermissionView) {
    dispatch(pageErrorState(401));
  }

  return (
    <LayoutContent title='Company Settings' containerType='within' loadingContent={loading}>
      <SquareIntegration companyId={contextId} integrations={integrations} hasPermissionAll={hasPermissionAll} />
      {smbConfiguredLocations.length > 0 && <TaxList taxes={taxes} loading={taxesLoading} />}
    </LayoutContent>
  );
};

export default Integrations;
