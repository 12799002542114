import React from 'react';
import { useParams } from 'react-router-dom';
import { IMenuItem } from '@ready/menu.core';
import { ContextParams } from '../../../types/ContextParams.interface';
import { viewItemGroup } from '../../redux/ItemGroupsActions';
import AddMenuItemsDialog from '../ItemsAndMods/AddMenuItemsDialog';
import { ItemGroupPanels } from './ItemGroupPanels';
import { selectLocationsState } from 'menus/redux/LocationsSelectors';
import { LocationsThunks } from 'menus/redux/LocationsThunks';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { IMenuItemSelection } from 'menus/redux/ItemsAndModsState';
import { selectMenuItemSelectionState } from 'menus/redux/ItemsAndModsSelectors';
import { ILocationsState } from 'menus/redux/LocationsState';
import { addItemToGroupItems, removeItemFromGroupItems } from 'menus/redux/MenuBuilderActions';

export const ItemGroupDetailPage = () => {
  const { contextId: companyId, locationId, id } = useParams<ContextParams>();
  const { location } = useAppSelector<ILocationsState>(selectLocationsState);
  const { modalVisible } = useAppSelector<IMenuItemSelection>(selectMenuItemSelectionState);
  const dispatch = useAppDispatch();

  const fetchLocation = React.useCallback(
    (companyId: string, locationId: string) => {
      if (!location || location.id !== locationId) {
        dispatch(LocationsThunks.loadSelectedLocation({ companyId, locationId }));
      }
    },
    [location, dispatch]
  );

  React.useEffect(() => {
    fetchLocation(companyId, locationId);
  }, [fetchLocation, companyId, locationId]);

  const fetchData = React.useCallback(
    (companyId: string, locationId: string, id: string) => {
      dispatch(viewItemGroup(companyId, locationId, id));
    },
    [dispatch]
  );

  React.useEffect(() => {
    fetchData(companyId, locationId, id);
  }, [fetchData, companyId, locationId, id]);

  const onAddItemToGroup = (item: IMenuItem) => {
    dispatch(addItemToGroupItems(item));
  };

  const onRemoveItemFromGroup = (itemId: string) => {
    dispatch(removeItemFromGroupItems(itemId));
  };

  return (
    <>
      <AddMenuItemsDialog
        visible={modalVisible}
        companyId={companyId}
        locationId={locationId}
        title='Add Menu Items'
        addMenuItemToSelection={onAddItemToGroup}
        removeMenuItemFromSelection={onRemoveItemFromGroup}
      />

      <ItemGroupPanels />
    </>
  );
};

export default ItemGroupDetailPage;
