import { PaginationResponse } from '@ready/dashboardv2api.contracts';
import {
  ILocationAssignments,
  ITemplateLocation,
  ITemplateMenuGroup,
  ITemplateMenuGroupRequest,
  WithAssignedLocationsCount,
} from '@ready/menu.core';
import { IPagedResponse } from '@ready/table.core';
import config from 'config';
import executeApiAsync from 'services/utils/executeApiAsync';
import toPaginationResponse from 'services/utils/toPaginationResponse';
import { objectToQueryParamsString } from 'utils/urlUtils/objectToQueryParamsString';
import { NewTemplateMenuGroupRequest } from './menuConfigs.mapper';

export const getTemplateMenuGroups = async (
  companyId: string,
  query: string = '',
  pageNumber: number = 1,
  pageSize: number = 50
): Promise<PaginationResponse<WithAssignedLocationsCount<ITemplateMenuGroup>>> => {
  const queryString = objectToQueryParamsString({
    pageNumber: pageNumber.toString(),
    pageSize: pageSize.toString(),
    searchTerm: query,
  });

  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menuGroups`;
  const result = await executeApiAsync<IPagedResponse<WithAssignedLocationsCount<ITemplateMenuGroup>>>(
    `${url}?${queryString}`,
    {
      method: 'GET',
    }
  );

  return toPaginationResponse({ results: result.results, totalCount: result.totalCount }, pageNumber, pageSize);
};

export const updateTemplateMenuGroup = async (
  companyId: string,
  templateMenuGroupRequest: NewTemplateMenuGroupRequest | ITemplateMenuGroupRequest,
  isNew: boolean
): Promise<ITemplateMenuGroup> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menuGroups`;
  return await executeApiAsync<ITemplateMenuGroup>(url, {
    method: isNew ? 'POST' : 'PUT',
    body: JSON.stringify(templateMenuGroupRequest),
  });
};

export const deleteTemplateMenuGroup = async (companyId: string, id: string): Promise<void> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menuGroups/${id}`;
  return await executeApiAsync<void>(url, {
    method: 'DELETE',
  });
};

export const getLocationAssignmentsForMenuGroup = async (
  companyId: string,
  menuGroupId: string,
  locationSearchTerm?: string,
  pageNumber: number = 1,
  pageSize: number = 50
): Promise<PaginationResponse<ITemplateLocation>> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menuGroups/${menuGroupId}/locations`;
  const queryString = objectToQueryParamsString({
    pageNumber: pageNumber.toString(),
    pageSize: pageSize.toString(),
    searchTerm: locationSearchTerm,
  });

  const result = await executeApiAsync<IPagedResponse<ITemplateLocation>>(url + '?' + queryString, { method: 'GET' });
  return toPaginationResponse({ results: result.results, totalCount: result.totalCount }, pageNumber, pageSize);
};

export const updateLocationAssignmentsForMenuGroup = async (
  companyId: string,
  itemId: string,
  locationAssignments: ILocationAssignments
): Promise<void> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menuGroups/${itemId}/locations`;

  await executeApiAsync<IPagedResponse<ITemplateLocation>>(url, {
    body: JSON.stringify(locationAssignments),
    method: 'POST',
  });
};
