import { ISchedule } from '@ready/menu.core';
import React, { FC } from 'react';
import { DeleteConfirmationModal } from '../../../components/Modal';

interface DeleteScheduleDialogProps {
  onDelete: (event: React.MouseEvent<HTMLButtonElement>, scheduleId: string) => void;
  schedule: ISchedule;
  isOpen: boolean;
  onClose: () => void;
  processing: boolean;
}

const DeleteScheduleDialog: FC<DeleteScheduleDialogProps> = ({ onDelete, onClose, isOpen, schedule, processing }) => {
  return isOpen ? (
    <DeleteConfirmationModal
      item='schedule'
      handleDelete={(event) => onDelete(event, schedule._id)}
      itemName={schedule.name}
      setShowModal={(modal: boolean) => {
        !modal && onClose();
      }}
      loading={processing}
    />
  ) : null;
};

export default DeleteScheduleDialog;
