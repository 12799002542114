import {
  IFeedbackReportGuestSatisfactionData,
  IFeedbackReportStarRatingData,
  IFeedbackReportState,
} from './initialState';
import { IFeedbackReportItem, PaginationResponse } from '@ready/dashboardv2api.contracts';
import _ from 'lodash';
import { IPagedResponse } from '@ready/table.core';
import { ILocation } from '../../../services/types/ILocation.type';

export const fetchFeedbacksListReducers = {
  pending: (state: IFeedbackReportState) => {
    state.loading.feedbackList = true;
  },
  fulfilled: (state: IFeedbackReportState, action: { payload: PaginationResponse<IFeedbackReportItem> }) => {
    const { items, total, limit, start, length } = action.payload;
    state.loading.feedbackList = false;
    state.items = items;
    state.pagination = { total, limit, start, length };
  },
  rejected: (state: IFeedbackReportState) => {
    state.loading.feedbackList = false;
    state.errors.feedbackList = 'Unable to retrieve feedback list.';
  },
};

export const exportFeedbacksListReducers = {
  pending: (state: IFeedbackReportState) => {
    state.loading.export = true;
  },
  fulfilled: (state: IFeedbackReportState) => {
    state.loading.export = false;
  },
  rejected: (state: IFeedbackReportState) => {
    state.loading.export = false;
  },
}

export const fetchStarRatingReducers = {
  pending: (state: IFeedbackReportState) => {
    state.loading.starRating = true;
  },
  fulfilled: (state: IFeedbackReportState, action: { payload: IFeedbackReportStarRatingData }) => {
    state.loading.starRating = false;
    state.starRating = action.payload;
  },
  rejected: (state: IFeedbackReportState) => {
    state.loading.starRating = false;
    state.errors.starRating = 'Unable to retrieve star rating data.';
  },
};

export const fetchGuestSatisfactionReducers = {
  pending: (state: IFeedbackReportState) => {
    state.loading.guestSatisfaction = true;
  },
  fulfilled: (state: IFeedbackReportState, action: { payload: IFeedbackReportGuestSatisfactionData }) => {
    state.loading.guestSatisfaction = false;
    const maxFeedbacksNumber =
      _.max(
        action.payload.counts.reduce<Array<number>>((acc, { positive, negative }) => [...acc, positive, negative], [0])
      ) ?? 0;

    const counts = action.payload.counts.map(({ positive, negative, ...rest }) => {
      // percents of max value
      const positivePercent = (positive / maxFeedbacksNumber) * 100;
      const negativePercent = (negative / maxFeedbacksNumber) * 100;

      return { ...rest, positive, negative, positivePercent, negativePercent };
    });

    state.guestSatisfaction = { ...action.payload, counts };
  },
  rejected: (state: IFeedbackReportState) => {
    state.loading.guestSatisfaction = false;
    state.errors.guestSatisfaction = 'Unable to retrieve guest satisfaction data.';
  },
};

export const fetchPermittedLocationsReducers = {
  pending: (state: IFeedbackReportState) => {
    state.loading.permittedLocations = true;
  },
  fulfilled: (state: IFeedbackReportState, action: { payload: IPagedResponse<ILocation> }) => {
    state.loading.permittedLocations = false;
    state.permittedLocations = action.payload;
  },
  rejected: (state: IFeedbackReportState) => {
    state.loading.permittedLocations = false;
  },
};
