import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  ITransactionDetailCheck,
  ITransactionDetailCreditCardDetails,
  ITransactionDetailGiftCardDetails,
  ITransactionDetailOnAccountPaymentDetails,
} from '@ready/dashboardv2api.contracts';
// components
import LayoutContent from '../../components/AppLayout/LayoutContent';
import ActionHeader from '../../components/ActionHeader/ActionHeader';
import { Panel, PanelLayout } from '../../components/PanelLayout';
import HeaderControls, { ControlOption } from '../../components/AppLayout/HeaderControls';
import TransactionCheck from './components/TransactionCheck';
import ManuallyEnterPayment from './components/ManuallyEnterPayment';
import RefundTransactionDialog from './components/RefundTransactionDialog';
import RefundGiftCardDialog from './components/RefundGiftCardDialog';
import { TotalsSummarySection } from './components/TransactionDetails/TotalsSummarySection';
import { CardPaymentDetailsSection } from './components/TransactionDetails/CardPaymentDetailsSection';
import { OnAccountPaymentDetailsSection } from './components/TransactionDetails/OnAccountPaymentDetailsSection';
import { GiftCardDetailsSection } from './components/TransactionDetails/GiftCardDetailsSection';
import { GuestInformationSection } from './components/TransactionDetails/GuestInformationSection';
import { PaymentHistorySection } from './components/TransactionDetails/PaymentHistorySection';
import { PaymentStatusSection } from './components/TransactionDetails/PaymentStatusSection';
// redux
import { AppState } from '../../redux/initialStates/AppState';
import { connect, ConnectedProps } from 'react-redux';
import {
  checkTransactionRefundAccess,
  validateRefund,
  refundTransaction,
  resetRefundState,
} from '../../redux/actions/transactionActions/transactionDetailActions';
import {
  getTransactionDetail,
  showManualEnterPaymentModal,
  hideManualEnterPaymentModal,
  manualEnterPayment,
  manualRetryPayment,
  refundGiftCard,
  resetGiftCardRefundState,
} from '../../redux/actions/transactionActions/transactionDetailActionsNew';
import { ContextParams } from '../../types/ContextParams.interface';
import {
  PrincipalPermissions,
  ResourceType,
  SecurityScope,
  TransactionResourceActions,
  Verifier,
} from '@ready/security.core';
import { usePageTitle } from '../../hooks';
import { useSearchParams } from '../../hooks/useSearchParams';
import TransactionItemsList from './components/TransactionItemList';

export type TransactionDetailPageProps = ReduxProps & {};

class TransactionRefunds {
  public static getCreditCardTotalRefundAmountAvailable(
    creditCardDetails?: ITransactionDetailCreditCardDetails
  ): number {
    return creditCardDetails?.amountAvailableForRefund ?? 0;
  }

  public static getGiftCardTotalRefundAmountAvailable(giftCardDetails?: ITransactionDetailGiftCardDetails): number {
    return giftCardDetails?.amountAvailableForRefund ?? 0;
  }

  public static getOnAccountPaymentOptionTotalRefundAmountAvailable(
    onAccountPaymentDetails?: ITransactionDetailOnAccountPaymentDetails
  ): number {
    return onAccountPaymentDetails?.amountAvailableForRefund ?? 0;
  }
}

function formatTipPercentages(tipPreTaxPercentage: number, tipPostTaxPercentage: number): string {
  if (tipPreTaxPercentage && tipPostTaxPercentage) {
    return `${tipPreTaxPercentage}% / ${tipPostTaxPercentage}%`;
  }
  return '';
}

const creditCardRefundModalMessage = 'Are you sure you want to refund this payment? This action cannot be undone.';
const onAccountPaymentRefundModalMessage =
  'This is an unintegrated payment and was not processed via your payment processor. Refunding here will mark this transaction as refunded but will not reverse any fees collected. Please ensure you follow your internal business processes for returning funds collected for this payment option to your guest.';

const TransactionDetailPage = (props: TransactionDetailPageProps) => {
  usePageTitle('Transaction Details');
  const {
    contextId,
    permissions,
    transactionDetail,
    transactionsListUrlQuery,
    getTransactionDetail,
    hasRefundTransactionAccess,
    refunding,
    refundAmount,
    refundValidationError,
    refundCompleted,
    refundError,
    checkTransactionRefundAccess,
    validateRefund,
    refundTransaction,
    resetRefundState,
    showManualEnterPaymentModal,
    hideManualEnterPaymentModal,
    manualEnterPayment,
    manualRetryPayment,
    refundGiftCard,
    resetGiftCardRefundState,
    giftCardRefunding,
    refundGiftCardCompleted,
    refundGiftCardError,
  } = props;
  const { locationId, id } = useParams<ContextParams>();

  const { backUrl } = useSearchParams();

  const hasGuestPiiPermission = Verifier.check(
    new PrincipalPermissions(permissions),
    SecurityScope.location,
    ResourceType.transaction,
    TransactionResourceActions.viewPii,
    locationId
  );

  React.useEffect(() => {
    if (contextId && locationId && id) {
      getTransactionDetail(contextId, locationId, id);
    }
  }, [getTransactionDetail, contextId, locationId, id]);

  const {
    loading,
    transactionDetail: transactionDetailData,
    manuallyEnterPaymentPOS,
    manuallyRetryingPayment,
  } = transactionDetail;

  const tipPercentages = formatTipPercentages(
    transactionDetailData.totalsSummary.tipPreTaxPercentage,
    transactionDetailData.totalsSummary.tipPostTaxPercentage
  );
  const paymentHistory = transactionDetailData.paymentHistory ?? [];
  const transactionChecksData = transactionDetailData.transactionChecks ?? [];
  
  const { queryString, page } = transactionsListUrlQuery;
  const backLinkTo = useMemo(() => {
    return backUrl
      ? decodeURIComponent(backUrl)
      : {
          baseUrl: `/companies/${contextId}/transactions`,
          searchParams: {
            query: queryString,
            page,
          },
        };
  }, [backUrl, contextId, queryString, page]);

  const { loading: posPaymentLoading, showModal: showPosPaymentModal, paymentPOSDetail } = manuallyEnterPaymentPOS;

  const [amountAvailableForRefund, setAmountAvailableForRefund] = React.useState<number>(0);
  const [gcAmountAvailableForRefund, setGCAmountAvailableForRefund] = React.useState<number>(0);
  const [refundRequested, setRefundRequested] = React.useState(false);
  const [giftCardRefundRequested, setGiftCardRefundRequested] = React.useState(false);

  const headerControlOptions = [
    {
      buttonLabel: 'Refund Payment',
      dropdownLabel: 'Refund Payment',
      onClick: () => {
        setRefundRequested(true);
      },
      primary: false,
      loading: loading,
      disabled: amountAvailableForRefund === 0,
    } as ControlOption,
  ];

  const setShowModal = (showModal: boolean): void => {
    resetRefundState();
    setRefundRequested(showModal);
  };
  const handleRefund = async (refundAmount: string, amountAvailableForRefund: number): Promise<void> => {
    validateRefund(refundAmount, amountAvailableForRefund);
  };
  // memoized functions to be used in effects
  const checkAccess = React.useCallback(
    (locationId, permissions) => {
      checkTransactionRefundAccess(locationId, permissions);
    },
    [checkTransactionRefundAccess]
  );
  const submitRefund = React.useCallback(
    (contextId, locationId, id, amount) => {
      refundTransaction(contextId, locationId, id, amount);
    },
    [refundTransaction]
  );

  const setShowGiftCardRefundModal = (showModal: boolean): void => {
    resetGiftCardRefundState();
    setGiftCardRefundRequested(showModal);
  };

  const handleGiftCardRefund = () => {
    refundGiftCard(contextId, locationId, id, gcAmountAvailableForRefund);
  };

  // Verifies that this is the transaction id we currently want.
  // React holds state in memory, so until we dispatch an action
  // to update the state, we can't be sure that we are getting values
  // from the proper transaction. Uses the URI param to verify against
  // the current transaction ID in memory.
  const isCurrentTransaction = useCallback((transactionId: string): boolean => id.slice(-6) === transactionId, [id]);

  React.useEffect(() => {
    if (locationId) {
      checkAccess(locationId, permissions);
    }
  }, [locationId, permissions, checkAccess]);

  React.useEffect(() => {
    const completeRefund = async () => {
      if (refundAmount > 0 && !refundValidationError) {
        submitRefund(contextId, locationId, id, refundAmount);
      }
    };
    completeRefund();
  }, [contextId, locationId, id, refundAmount, refundValidationError, submitRefund]);

  const showHeaderControls = hasRefundTransactionAccess && !loading;

  React.useEffect(() => {
    if (isCurrentTransaction(transactionDetailData.transactionId)) {
      const creditCardAmountAvailableForRefund = TransactionRefunds.getCreditCardTotalRefundAmountAvailable(
        transactionDetailData.creditCardDetails
      );
      const giftCardAmountAvailableForRefund = TransactionRefunds.getGiftCardTotalRefundAmountAvailable(
        transactionDetailData.giftCardDetails
      );
      const onAccountPaymentTotalRefundAmountAvailable =
        TransactionRefunds.getOnAccountPaymentOptionTotalRefundAmountAvailable(
          transactionDetailData.onAccountPaymentDetails
        );

      setAmountAvailableForRefund(creditCardAmountAvailableForRefund || onAccountPaymentTotalRefundAmountAvailable);
      setGCAmountAvailableForRefund(giftCardAmountAvailableForRefund);
    }
  }, [isCurrentTransaction, transactionDetailData]);

  return (
    <LayoutContent
      title='Transaction Details'
      loadingContent={loading}
      headerControls={showHeaderControls ? <HeaderControls controlOptions={headerControlOptions} /> : null}
      containerType='within'
    >
      <>
        <ActionHeader text={`#${transactionDetailData.transactionId}`} backLinkTo={backLinkTo} />

        <PanelLayout columns={2}>
          <Panel column={0} withDividers key='paymentPanel'>
            <PaymentStatusSection transactionDetailData={transactionDetailData} refundCompleted={refundCompleted} />

            <TotalsSummarySection
              totalsSummaryData={transactionDetailData.totalsSummary}
              tipPercentages={tipPercentages}
            />
            {transactionDetailData.creditCardDetails && transactionDetailData.creditCardDetails.amountPaid > 0 ? (
              <CardPaymentDetailsSection
                cardPaymentDetails={transactionDetailData.creditCardDetails}
                timezone={transactionDetailData.timezone}
              />
            ) : null}

            {transactionDetailData.giftCardDetails && transactionDetailData.giftCardDetails.amountPaid > 0 ? (
              <GiftCardDetailsSection
                setShowGiftCardRefundModal={setShowGiftCardRefundModal}
                gcAmountAvailableForRefund={gcAmountAvailableForRefund}
                giftCardDetails={transactionDetailData.giftCardDetails}
                timezone={transactionDetailData.timezone}
              />
            ) : null}

            {transactionDetailData.onAccountPaymentDetails && transactionDetailData.onAccountPaymentDetails ? (
              <OnAccountPaymentDetailsSection
                onAccountPaymentOptionDetails={transactionDetailData?.onAccountPaymentDetails}
                timezone={transactionDetailData.timezone}
              />
            ) : null}

            <GuestInformationSection
              hasGuestPiiPermission={hasGuestPiiPermission}
              guestInformation={transactionDetailData.guestInformation}
            />

            <PaymentHistorySection paymentHistory={paymentHistory} timezone={transactionDetailData.timezone} />
          </Panel>

          <>
          { (!transactionChecksData || transactionChecksData.length === 0) && transactionDetailData.transactionItems ? (
              <Panel column={1} key={`transactionItems`}>
                <TransactionItemsList items={transactionDetailData.transactionItems}></TransactionItemsList>
              </Panel>
            ) : transactionChecksData.map((checkData: ITransactionDetailCheck, index: number) => {
              
              return TransactionCheck({
                key: `check${index}`,
                setShowPaymentDialog: showManualEnterPaymentModal,
                creditCardBrand: transactionDetailData.creditCardDetails?.cardBrand,
                transactionId: id,
                manuallyRetryingPayment,
                contextId,
                locationId,
                manualRetryPayment,
                ...checkData,
                onAccountPaymentOptionName: transactionDetailData?.onAccountPaymentDetails?.paymentType,
              });
            })
          }
            
          </>
        </PanelLayout>

        {showPosPaymentModal && (
          <ManuallyEnterPayment
            setShowModal={hideManualEnterPaymentModal}
            manualEnterPayment={manualEnterPayment}
            contextId={contextId}
            locationId={locationId}
            posPaymentLoading={posPaymentLoading}
            {...paymentPOSDetail}
          />
        )}
        {refundRequested && amountAvailableForRefund && (
          <RefundTransactionDialog
            loading={refunding}
            amountAvailableForRefund={amountAvailableForRefund}
            validationMessage={refundValidationError}
            errorMessage={refundError}
            completed={refundCompleted}
            setShowModal={setShowModal}
            handleRefund={handleRefund}
            modalMessage={
              transactionDetail.transactionDetail.creditCardDetails?.amountAvailableForRefund
                ? creditCardRefundModalMessage
                : onAccountPaymentRefundModalMessage
            }
          />
        )}

        {giftCardRefundRequested && (
          <RefundGiftCardDialog
            loading={giftCardRefunding}
            errorMessage={refundGiftCardError}
            completed={refundGiftCardCompleted}
            setShowModal={setShowGiftCardRefundModal}
            handleRefund={handleGiftCardRefund}
          />
        )}
      </>
    </LayoutContent>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    contextId: state.session.contextSession.id,
    permissions: state.session.permissions.permissionsList,
    transactionDetail: state.transactions.transactionDetailNew,
    transactionsListUrlQuery: state.transactions.transactionsList.urlQuery,
    hasRefundTransactionAccess: state.transactions.transactionDetail.hasRefundTransactionAccess,
    refunding: state.transactions.transactionDetail.refunding,
    refundAmount: state.transactions.transactionDetail.refundAmount,
    refundValidationError: state.transactions.transactionDetail.refundValidationError,
    refundCompleted: state.transactions.transactionDetail.refundCompleted,
    refundError: state.transactions.transactionDetail.refundError,
    giftCardRefunding: state.transactions.transactionDetailNew.refundingGiftCard,
    refundGiftCardCompleted: state.transactions.transactionDetailNew.refundGiftCardCompleted,
    refundGiftCardError: state.transactions.transactionDetailNew.refundGiftCardError,
  };
};

const actionCreators = {
  getTransactionDetail,
  checkTransactionRefundAccess,
  validateRefund,
  refundTransaction,
  resetRefundState,
  showManualEnterPaymentModal,
  hideManualEnterPaymentModal,
  manualEnterPayment,
  manualRetryPayment,
  refundGiftCard,
  resetGiftCardRefundState,
};

const connector = connect(mapStateToProps, actionCreators);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(TransactionDetailPage);
