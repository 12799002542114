import React from 'react';
import InfoCardColumn from './InfoCardColumn';
import Skeleton from '../Skeleton/Skeleton';
import InfoCard from './InfoCard';
import InfoCardDataRow from './InfoCardDataRow';

export interface InfoCardSkeletonProps {
  instances?: number;
  rowsWithinInstance?: number;
  columns?: number;
  infoCardColumnWidths?: number[];
  dataRows?: number;
}

const renderInfoCardDataRows = (dataRows: number): JSX.Element[] => {
  const result = [];
  for (let i = 0, length = dataRows; i < length; i++) {
    result.push(
      <InfoCardDataRow key={i} skeleton>
        <Skeleton />
      </InfoCardDataRow>
    );
  }
  return result;
};

const renderInfoCardColumns = (infoCardColumnWidths: number[], rowsWithinInstance: number): JSX.Element[] => {
  const dataRows = renderInfoCardDataRows(rowsWithinInstance);
  return infoCardColumnWidths.map((width, idx) => {
    return (
      <InfoCardColumn width={width} key={idx} skeleton>
        {dataRows}
      </InfoCardColumn>
    );
  });
};

const renderInfoCardSkeletons = (
  instances: number,
  infoCardColumnWidths: number[],
  rowsWithinInstance: number
): JSX.Element[] => {
  const infoCardSkeletons = [];
  const infoCardColumns = renderInfoCardColumns(infoCardColumnWidths, rowsWithinInstance);
  for (let i = 0, length = instances; i < length; i++) {
    infoCardSkeletons.push(<InfoCard key={i}>{infoCardColumns}</InfoCard>);
  }
  return infoCardSkeletons;
};

const InfoCardsSkeleton = (props: InfoCardSkeletonProps): JSX.Element => {
  const { instances = 1, infoCardColumnWidths = [100], rowsWithinInstance = 1 } = props;
  return <>{renderInfoCardSkeletons(instances, infoCardColumnWidths, rowsWithinInstance)}</>;
};

export default InfoCardsSkeleton;
