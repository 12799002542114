import { ITransactionDetailTotalsSummary } from '@ready/dashboardv2api.contracts';
import React from 'react';
import { DetailLayout, DetailElement } from '../../../../components/DetailLayout';
import { DollarValue, TextValue } from '../../../../components/Value';

interface TotalsSummarySectionProps {
  totalsSummaryData: ITransactionDetailTotalsSummary;
  tipPercentages: string;
}
export const TotalsSummarySection = (props: TotalsSummarySectionProps) => {
  const {
    tipPercentages,
    totalsSummaryData: {
      subtotal,
      tax,
      serviceCharge,
      otherCharges,
      discounts,
      checkTotal,
      tip,
      totalPaid,
      totalRefunded,
    },
  } = props;
  return (
    <DetailLayout title='Totals Summary'>
      <DetailElement condensed label='Subtotal'>
        <DollarValue value={subtotal} />
      </DetailElement>
      <DetailElement condensed label='Tax'>
        <DollarValue value={tax} />
      </DetailElement>
      <DetailElement condensed label='Service Charge'>
        <DollarValue value={serviceCharge} />
      </DetailElement>
      <DetailElement condensed label='Other Charges'>
        <DollarValue value={otherCharges} />
      </DetailElement>
      <DetailElement condensed label='Discounts'>
        <DollarValue value={-Math.abs(discounts)} />
      </DetailElement>
      <DetailElement condensed label='Check Total'>
        <DollarValue value={checkTotal} />
      </DetailElement>
      <DetailElement condensed label='Tip'>
        <DollarValue value={tip} />
      </DetailElement>
      <DetailElement condensed label='Total Paid'>
        <DollarValue value={totalPaid} />
      </DetailElement>
      <DetailElement condensed label='Total Refunded'>
        <DollarValue value={totalRefunded} />
      </DetailElement>
      <DetailElement condensed label='Tip % (Pre-Tax/Post Tax)'>
        <TextValue value={tipPercentages} />
      </DetailElement>
    </DetailLayout>
  );
};
