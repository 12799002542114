import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import TableRow from './TableRow';
import TableCell from './TableCell';
import { useSearchParams } from '../../hooks';
import { IPagination } from '@ready/dashboardv2api.contracts';
import { objectToQueryParamsString } from '../../utils/urlUtils/objectToQueryParamsString';

const TablePagination = (props: IPagination) => {
  const start = Number(props.start ?? 0);
  const length = Number(props.length ?? 0);
  const total = Number(props.total ?? 0);

  const { page = '1', ...restSearchParameters } = useSearchParams();
  const { pathname } = useLocation();

  const emptyResult = length === 0;

  const pageLimit = !emptyResult ? start + length : 0;
  const isFirstPage = start === 0;
  const isLastPage = pageLimit === total || total < length + start;
  const startShow = emptyResult ? 0 : start + 1;
  const totalShow = total;

  // set previous and next page links
  const initialPath = `${pathname}?${
    Object.entries(restSearchParameters).length ? `${objectToQueryParamsString(restSearchParameters)}&` : ''
  }`;
  const prevLink = `${initialPath}page=${parseInt(page) - 1}`;
  const nextLink = `${initialPath}page=${parseInt(page) + 1}`;

  return (
    <TableRow>
      <TableCell>{`${startShow}-${pageLimit} of ${totalShow}`}</TableCell>
      <TableCell>
        {isFirstPage ? (
          <span className='table__row__cell__navigation__link--disabled'>Prev</span>
        ) : (
          <Link to={prevLink}>Prev</Link>
        )}

        <span className='table__row__cell__navigation__separator'>|</span>

        {isLastPage || emptyResult ? (
          <span className='table__row__cell__navigation__link--disabled'>Next</span>
        ) : (
          <Link to={nextLink}>Next</Link>
        )}
      </TableCell>
    </TableRow>
  );
};

export default TablePagination;
