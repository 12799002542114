import { ITransactionDetailCreditCardDetails } from '@ready/dashboardv2api.contracts';
import React from 'react';
import { isValidLast4CardNumber } from 'pages/Transactions/utils/isValidLast4CardNumber';
import { DetailLayout, DetailElement } from '../../../../components/DetailLayout';
import { DateValue, DollarValue, TextValue } from '../../../../components/Value';
interface CardPaymentDetailsSectionProps {
  cardPaymentDetails: ITransactionDetailCreditCardDetails;
  timezone?: string;
}

export const CardPaymentDetailsSection = (props: CardPaymentDetailsSectionProps) => {
  const {
    cardPaymentDetails: {
      amountPaid,
      amountRefunded,
      date,
      currency,
      transactionType,
      authCode,
      paymentId,
      last4,
      expiryMonth,
      expiryYear,
      cardBrand,
      source,
      zipPostal,
    },
    timezone,
  } = props;

  const creditCardNumber = isValidLast4CardNumber(last4) ? `****${last4}` : '';
  const expires = `${expiryMonth < 10 ? '0' : ''}${expiryMonth}/${expiryYear}`;

  return (
    <DetailLayout title='Card Payment Details'>
      <DetailElement condensed label='Amount Paid'>
        <DollarValue value={amountPaid} />
      </DetailElement>
      <DetailElement condensed label='Amount Refunded'>
        <DollarValue value={amountRefunded} />
      </DetailElement>
      <DetailElement condensed label='Date'>
        <DateValue value={date?.toString() ?? ''} timeZone={timezone} />
      </DetailElement>
      <DetailElement condensed label='Currency'>
        <TextValue value={currency} />
      </DetailElement>
      <DetailElement condensed label='Transaction Type'>
        <TextValue value={transactionType} />
      </DetailElement>
      <DetailElement condensed label='Auth Code'>
        <TextValue value={authCode} />
      </DetailElement>
      <DetailElement condensed label='Payment ID'>
        <TextValue value={paymentId} />
      </DetailElement>
      <DetailElement condensed label='Card Number'>
        <TextValue value={creditCardNumber} />
      </DetailElement>
      <DetailElement condensed label='Expires'>
        <TextValue value={expires} />
      </DetailElement>
      <DetailElement condensed label='Card Brand'>
        <TextValue value={cardBrand} />
      </DetailElement>
      <DetailElement condensed label='Source'>
        <TextValue value={source} />
      </DetailElement>
      <DetailElement condensed label='Zip/Postal'>
        <TextValue value={zipPostal} />
      </DetailElement>
    </DetailLayout>
  );
};
