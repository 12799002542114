import { IPagination } from '@ready/dashboardv2api.contracts';
import { ILocationAssignments } from '@ready/menu.core';
import TableLayoutContent from 'companyLocations/components/TableLayoutContent';
import Button from 'components/Button/Button';
import OverflowButton from 'components/OverflowButton/OverflowButton';
import { PaginationContainer } from 'components/PaginationContainer/PaginationContainer';

import { Body, BodySkeleton, TableBodyProps } from 'components/TableV2/Body/Body';
import { TableHeader, TableHeaderProps } from 'components/TableV2/TableHeader/TableHeader';
import { TableV2 } from 'components/TableV2/TableV2';
import { useResponsiveBreakpoint } from 'hooks';
import React, { ReactNode, useState } from 'react';
import {
  LoadAssignedLocations,
  ManageAssignedLocationsModal,
} from '../ManageAssignedLocationsModal/ManageAssignedLocationsModal';
import styles from './AssignedLocationsTable.module.scss';

interface Props {
  title?: string;
  titleTooltip?: string;
  pagination?: IPagination;
  tableHeaderProps: TableHeaderProps;
  bodyProps: TableBodyProps;
  items: Record<string, any>[];
  loading: boolean;

  toolbarContent?: ReactNode;

  noAssignedLocationsMessage?: string;
  loadLocationAssignments?: (params: LoadAssignedLocations) => void;
  onSaveLocationAssignments?: (assignments: ILocationAssignments) => Promise<void>;
  totalLocationsCount?: number;
  query?: string;
}

export const AssignedLocationsTable = ({
  title,
  titleTooltip,
  pagination = { length: 0, start: 0, total: 0, limit: 0 },
  tableHeaderProps,
  bodyProps,
  toolbarContent,
  items = [],
  loading = true,
  loadLocationAssignments,
  noAssignedLocationsMessage,
  onSaveLocationAssignments,
  totalLocationsCount,
  query,
}: Props) => {
  const [showEditAssignedLocations, setShowEditAssignedLocationsModal] = useState<boolean>(false);
  const { isMobile } = useResponsiveBreakpoint();

  if (isMobile && !!bodyProps.columns.indexOf('mobileToolbarHeader')) {
    bodyProps.columns.unshift('mobileToolbarHeader');
  }

  const showEditControls = onSaveLocationAssignments && loadLocationAssignments;

  return (
    <>
      <TableLayoutContent
        className={styles.tableLayout}
        title={title || 'Assigned Locations'}
        titleTooltip={titleTooltip}
        headerControls={
          showEditControls ? (
            isMobile ? (
              <OverflowButton
                options={[
                  {
                    label: 'Edit Assigned Locations ',
                    onClick: () => setShowEditAssignedLocationsModal(true),
                    primary: true,
                  },
                ]}
              />
            ) : (
              <Button
                label={'Edit Assigned Locations'}
                variant='secondary'
                onClick={() => setShowEditAssignedLocationsModal(true)}
              />
            )
          ) : undefined
        }
        toolbarContent={toolbarContent}
      >
        <PaginationContainer pagination={pagination}>
          {items.length === 0 && !loading ? (
            <div className={styles.noAssignedLocations}>
              {query !== undefined ? 'No Results Found.' : noAssignedLocationsMessage || 'No assigned locations.'}
            </div>
          ) : (
            <TableV2>
              <TableHeader {...tableHeaderProps} />
              {!loading ? (
                <Body {...bodyProps} data={items} />
              ) : (
                <BodySkeleton rowsNumber={50} columns={bodyProps.columns} />
              )}
            </TableV2>
          )}
        </PaginationContainer>
      </TableLayoutContent>

      {loadLocationAssignments && onSaveLocationAssignments && showEditAssignedLocations && (
        <ManageAssignedLocationsModal
          currentAssignedCount={pagination?.total || 0}
          totalLocationsCount={totalLocationsCount || 0}
          headerLabel='Edit Assigned Locations'
          setShowModal={setShowEditAssignedLocationsModal}
          loadLocationAssignments={loadLocationAssignments}
          saveAssignedLocations={onSaveLocationAssignments}
        />
      )}
    </>
  );
};
