import React from 'react';
import LayoutContent from 'components/AppLayout/LayoutContent';
import EmptyResult from 'components/EmptyResult/EmptyResult';

interface ServerAssignmentUnsupportedPOSProps {
  titleControls?: JSX.Element;
}

const ServerAssignmentUnsupportedPOSPage = ({ titleControls }: ServerAssignmentUnsupportedPOSProps) => {
  const title = 'Server Assignment Unavailable';
  const paragraph = 'This feature is not currently supported by the POS system configured at this location.';

  return (
    <LayoutContent title='Server Assignment' containerType='within' titleControls={titleControls}>
      <div className='page-with-empty-result'>
        <EmptyResult title={title} paragraph={paragraph} />
      </div>
    </LayoutContent>
  );
};

export default ServerAssignmentUnsupportedPOSPage;
