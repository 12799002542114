import SearchSelectFilter, { Option } from 'components/SearchSelectFilter/SearchSelectFilter';
import React from 'react';
import styles from './SearchSelectCheckboxFitler.module.scss';
import Checkbox from 'components/Checkbox/Checkbox';
import { OptionProps } from 'react-select';

export const SearchSelectCheckboxFilter = (props: { options: Option[]; name: string }) => {
  return (
    <SearchSelectFilter
      hideSelectedOptions={false}
      defaultValue=''
      name={props.name}
      options={props.options}
      isMulti={true}
      components={{ Option: CheckboxOptionComponent, ValueContainer: CheckboxValueContainer }}
    />
  );
};

const CheckboxOptionComponent = ({ innerProps, data, isSelected }: OptionProps<Option, boolean>) => (
  <span {...innerProps} className={styles.checkboxOptionComponent}>
    <Checkbox onChange={() => {}} label={data.label} checked={isSelected} />
  </span>
);

const CheckboxValueContainer = (props: any) => {
  const options = props.getValue();
  const input = props.children[1];

  let label: string | React.ReactElement = '';
  const singleOption = 1;
  const noOptions = 0;
  const maxOptions = props.options.length;
  const allSelectedLabel = <span className={styles.checkboxValueContainerAll}>All</span>;

  switch (options.length) {
    case singleOption:
      label = options[0].label;
      break;
    case maxOptions:
      label = allSelectedLabel;
      break;
    case noOptions:
      label = allSelectedLabel;
      break;
    default:
      label = `${options.length} selected`;
  }

  return (
    <div className={styles.checkboxValueContainer}>
      {label}
      {input}
    </div>
  );
};
