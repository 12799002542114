import React from 'react';

export interface InfoCardDataRowProps {
  width?: number;
  children?: JSX.Element | JSX.Element[] | string;
  skeleton?: boolean;
  inFooter?: boolean;
  additionalClassName?: string;
}

const InfoCardDataRow = (props: InfoCardDataRowProps) => {
  const { children, skeleton = false, inFooter = false, additionalClassName = '' } = props;
  const skeletonClass = skeleton ? 'info__card__data__row--skeleton' : '';
  const inFooterClass = inFooter ? 'info__card__data_row--inFooter' : '';

  return (
    <div className={`info__card__data__row ${skeletonClass} ${inFooterClass} ${additionalClassName}`}>{children}</div>
  );
};

export default InfoCardDataRow;
