import React, { RefObject } from 'react';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useResponsiveBreakpoint from '../../../hooks/useResponsiveBreakpoint';
import { Routes } from '../../MenuBuilderRouter';
import { MenuResourceActions, PrincipalPermissions, ResourceType, SecurityScope, Verifier } from '@ready/security.core';

import { AppState } from '../../../redux/initialStates/AppState';
import { IMenuItem, IMenuSection, IMenuSectionItemGroup, Status, Visibility } from '@ready/menu.core';
import { LocationsThunks } from '../../redux/LocationsThunks';
import {
  changeMenuSectionStatus,
  deleteMenuSection,
  deselectItemGroupInItemsAndGroupsModal,
  deselectMenuItemInItemsAndGroupsModal,
  loadMenu,
  moveMenuItemInGroup,
  moveMenuItemInSection,
  moveMenuSection,
  prepareItemsAndGroupsModal,
  prepareReorderMenuSectionsModal,
  removeMenuItemFromItemGroupInMenuSection,
  removeMenuItemFromMenuSection,
  resetForms,
  saveMenuSection,
  saveReorderedMenuSections,
  selectItemGroupInItemsAndGroupsModal,
  selectMenuItemInItemsAndGroupsModal,
  setMenuEditMode,
  setMenuSectionCreateMode,
  setMenuSectionEditMode,
  showDeleteMenuSectionModal,
  updateItemGroupMenuItemVisibilityAndSave,
  updateMenuDescription,
  updateMenuDisplayName,
  updateMenuItemDisplayNameInSection,
  updateMenuItemVisibilityAndSave,
  updateMenuItemVisibilityInSection,
  updateMenuItemVisibilityInSectionItemGroup,
  updateMenuScheduler,
  updateMenuSectionDescription,
  updateMenuSectionDisplayName,
  updateMenuSectionScheduler,
  updateMenuSectionStatus,
  updateMenuSectionDefaultAppearance,
  updateMenuSectionViewState,
  updateMenuStatus,
  startEditingInnerItemGallery,
  menuGalleryUpdateGalleryName,
  menuGalleryUpdateDescription,
  selectItemForMenuGallery,
  selectItemGroupForMenuGallery,
  deselectItemForMenuGallery,
  deselectItemGroupForMenuGallery,
  updateMenuGallerySubitemName,
  updateItemGalleryItemVisibility,
  updateItemGalleryItemGroupItemVisibility,
  removeMenuGallerySubitem,
  updateItemGalleryItemsSortOrder,
  updateItemGalleryReorderItems,
  menuGalleryUpdateStatus,
  saveInnerItemGallery,
  updateItemGalleryItemGroupItemsSortOrder,
  updateItemGalleryMenuItemVisibilityAndSave,
  updateItemGalleryItemGroupMenuItemVisibilityAndSave,
} from '../../redux/MenusActions';
import { prepareMenuItemsModal } from '../../redux/ItemsAndModsActions';
import {
  prepareNewItemGroupModal,
  saveNewItemGroupInSection,
  saveNewItemGroupInItemGallery,
  updateItemGroupDescription,
  updateItemGroupDisplayName,
} from '../../redux/ItemGroupsActions';
import {
  deselectItemGroupFromMenuSection,
  deselectMenuItemFromMenuSection,
  deselectMenuItemFromMenuSectionItemGroup,
  selectItemGroupForMenuSection,
  selectMenuItemForMenuSection,
  selectMenuItemForMenuSectionItemGroup,
  selectMenuItemForItemGalleryItemGroup,
  deselectMenuItemFromItemGalleryItemGroup,
} from '../../redux/MenuBuilderActions';
import { selectLocationsState } from '../../redux/LocationsSelectors';
import styles from './MenuPage.module.scss';
import MenuBuilderLayoutContent from '../MenuBuilderLayoutContent';
import MenuBuilderConfig from '../../types/MenuBuilderConfig';
import MenuBuilderTab from '../../types/MenuBuilderTab.enum';
import MenuItemsView from '../../types/MenuItemsView.enum';
import ItemsAndGroupsView from '../../types/ItemsAndGroupsView.enum';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import EmptyResult from '../../../components/EmptyResult/EmptyResult';
import { DeleteConfirmationModal } from '../../../components/Modal';
import EditDetailsPanel from './EditDetailsPanel';
import SectionHeader from './SectionHeader';
import EditSectionPanel from './EditSectionPanel';
import SectionPanel from './SectionPanel';
import ReorderDialog from './ReorderDialog';
import AddItemsAndGroupsDialog from './AddItemsAndGroupsDialog';
import ItemGroupFormModal from '../ItemGroups/ItemGroupFormModal';
import { getScheduleList, initScheduleList } from '../../redux/ScheduleListActions';
import AddMenuItemsDialog from '../ItemsAndMods/AddMenuItemsDialog';
import MenuItemAndItemGroupList from './MenuItemAndItemGroupList';
import { MenuActionHeader } from './MenuActionHeader/MenuActionHeader';
import { saveMenu } from 'menus/redux/MenuActions/SaveMenu';
import { ItemGalleryCollapsiblePanel } from './ItemGalleryCollapsiblePanel';
import { EditItemGalleryPanel } from 'menus/components/Menus/EditItemGalleryPanel';
import { isSharedMenuAssignedToCurrentLocation } from 'sharedMenuItems/sharedMenuItems.utils';
import { MenuContextParams } from 'menus/types/MenuContextParams.interface';

const mapStateToProps = (state: AppState) => {
  return {
    permissions: state.session.permissions.permissionsList,
    expandedSections: state.menuBuilder.menus.previousMenuState.expandedSections,
    menuForm: state.menuBuilder.menus.menuForm,
    menuGalleryForm: state.menuBuilder.menus.menuGalleryForm,
    itemGroupForm: state.menuBuilder.itemGroups.itemGroupForm,
    itemsAndGroupsSelection: state.menuBuilder.menus.itemsAndGroupsSelection,
    isSelectingMenuItems: state.menuBuilder.itemsAndMods.menuItemSelection.modalVisible,
    schedules: state.menuBuilder.scheduleList.schedules,
    areSchedulesLoading: state.menuBuilder.scheduleList.loading,
  };
};

const actionCreators = {
  loadMenu,
  setMenuEditMode,
  setMenuSectionCreateMode,
  setMenuSectionEditMode,
  updateMenuDisplayName,
  updateMenuDescription,
  updateMenuScheduler,
  updateMenuSectionScheduler,
  updateMenuStatus,
  updateMenuSectionDisplayName,
  updateMenuSectionDescription,
  showDeleteMenuSectionModal,
  deleteMenuSection,
  changeMenuSectionStatus,
  updateMenuSectionDefaultAppearance,
  prepareReorderMenuSectionsModal,
  moveMenuSection,
  saveReorderedMenuSections,
  saveMenuSection,
  updateMenuItemDisplayNameInSection,
  saveMenu,
  updateMenuItemVisibilityAndSave,
  updateItemGroupMenuItemVisibilityAndSave,
  resetForms,
  prepareItemsAndGroupsModal,
  selectMenuItemInItemsAndGroupsModal,
  deselectMenuItemInItemsAndGroupsModal,
  selectItemGroupInItemsAndGroupsModal,
  deselectItemGroupInItemsAndGroupsModal,
  prepareNewItemGroupModal,
  prepareMenuItemsModal,
  updateItemGroupDisplayName,
  updateItemGroupDescription,
  updateMenuSectionViewState,
  updateMenuSectionStatus,
  updateMenuItemVisibilityInSection,
  updateMenuItemVisibilityInSectionItemGroup,
  moveMenuItemInSection,
  removeMenuItemFromMenuSection,
  removeMenuItemFromItemGroupInMenuSection,
  moveMenuItemInGroup,
  selectMenuItemForMenuSection,
  deselectMenuItemFromMenuSection,
  selectItemGroupForMenuSection,
  deselectItemGroupFromMenuSection,
  selectMenuItemForMenuSectionItemGroup,
  deselectMenuItemFromMenuSectionItemGroup,
  saveNewItemGroupInSection,
  saveNewItemGroupInItemGallery,
  getScheduleList,
  initScheduleList,
  startEditingInnerItemGallery,
  menuGalleryUpdateGalleryName,
  menuGalleryUpdateDescription,
  selectItemForMenuGallery,
  selectItemGroupForMenuGallery,
  deselectItemForMenuGallery,
  deselectItemGroupForMenuGallery,
  updateMenuGallerySubitemName,
  updateItemGalleryItemVisibility,
  updateItemGalleryItemGroupItemVisibility,
  removeMenuGallerySubitem,
  updateItemGalleryItemsSortOrder,
  updateItemGalleryReorderItems,
  menuGalleryUpdateStatus,
  saveInnerItemGallery,
  selectMenuItemForItemGalleryItemGroup,
  deselectMenuItemFromItemGalleryItemGroup,
  updateItemGalleryItemGroupItemsSortOrder,
  updateItemGalleryMenuItemVisibilityAndSave,
  updateItemGalleryItemGroupMenuItemVisibilityAndSave,
};

const connector = connect(mapStateToProps, actionCreators);

export type MenuPageProps = ConnectedProps<typeof connector>;

const MenuPage = (props: MenuPageProps): JSX.Element => {
  const {
    permissions,
    expandedSections,
    menuForm,
    menuForm: { loading, editing, cache, menu, validation, sectionForm },
    menuGalleryForm: itemGalleryForm,
    menuGalleryForm: {
      menu: { itemGallery: formItemGallery },
      editing: isEditingItemGallery,
      processing: itemGalleryProcessing,
      sectionItemChanges: galleryItemChanges,
    },
    itemGroupForm,
    itemGroupForm: { itemGroup },
    itemsAndGroupsSelection: { modalVisible: isSelectingItemsAndGroups, view: itemsAndGroupsView },
    isSelectingMenuItems,
    schedules,
    getScheduleList,
    initScheduleList,
    loadMenu,
    setMenuEditMode,
    setMenuSectionCreateMode,
    setMenuSectionEditMode,
    updateMenuDisplayName,
    updateMenuScheduler,
    updateMenuSectionScheduler,
    updateMenuStatus,
    updateMenuSectionViewState,
    updateMenuSectionDisplayName,
    updateMenuSectionDescription,
    updateMenuDescription,
    showDeleteMenuSectionModal,
    deleteMenuSection,
    changeMenuSectionStatus,
    prepareReorderMenuSectionsModal,
    moveMenuSection,
    saveReorderedMenuSections,
    saveMenuSection,
    updateMenuItemDisplayNameInSection,
    saveMenu,
    updateMenuItemVisibilityAndSave,
    updateItemGroupMenuItemVisibilityAndSave,
    resetForms,
    prepareItemsAndGroupsModal,
    prepareNewItemGroupModal,
    prepareMenuItemsModal,
    updateItemGroupDisplayName,
    updateItemGroupDescription,
    updateMenuSectionStatus,
    updateMenuSectionDefaultAppearance,
    updateMenuItemVisibilityInSection,
    updateMenuItemVisibilityInSectionItemGroup,
    moveMenuItemInSection,
    removeMenuItemFromMenuSection,
    removeMenuItemFromItemGroupInMenuSection,
    moveMenuItemInGroup,
    selectMenuItemForMenuSection,
    deselectMenuItemFromMenuSection,
    selectItemGroupForMenuSection,
    deselectItemGroupFromMenuSection,
    selectMenuItemForMenuSectionItemGroup,
    deselectMenuItemFromMenuSectionItemGroup,
    saveNewItemGroupInSection,
    saveNewItemGroupInItemGallery,
    startEditingInnerItemGallery,
    menuGalleryUpdateGalleryName,
    menuGalleryUpdateDescription,
    selectItemForMenuGallery,
    selectItemGroupForMenuGallery,
    deselectItemForMenuGallery,
    deselectItemGroupForMenuGallery,
    updateMenuGallerySubitemName,
    updateItemGalleryItemVisibility,
    updateItemGalleryItemGroupItemVisibility,
    removeMenuGallerySubitem,
    updateItemGalleryItemsSortOrder,
    updateItemGalleryReorderItems,
    menuGalleryUpdateStatus,
    saveInnerItemGallery,
    selectMenuItemForItemGalleryItemGroup,
    deselectMenuItemFromItemGalleryItemGroup,
    updateItemGalleryItemGroupItemsSortOrder,
    updateItemGalleryMenuItemVisibilityAndSave,
    updateItemGalleryItemGroupMenuItemVisibilityAndSave,
    areSchedulesLoading,
  } = props;
  const { location } = useSelector(selectLocationsState);
  const dispatch = useDispatch();
  const { contextId: companyId, locationId, id, menuConfigId } = useParams<MenuContextParams>();

  const menuItemGalleryDisabled = !menu.itemGallery || menu.itemGallery.status === Status.disabled;
  const isEditingMenuItemGallery = isEditingItemGallery && formItemGallery!._id === menu.itemGallery!._id;

  const { isMobile } = useResponsiveBreakpoint();
  const [groupIdForAddingItems, setGroupIdForAddingItems] = React.useState<string>('');
  const [addingToItemGallery, setAddingToItemGallery] = React.useState<boolean>(false);

  const hasManagePermission = Verifier.check(
    new PrincipalPermissions(permissions),
    SecurityScope.location,
    ResourceType.menu,
    MenuResourceActions.all,
    locationId
  );

  const isLoading = loading || !menu._id;

  const menuInputRef: RefObject<HTMLInputElement> = React.useRef<HTMLInputElement>(null);
  const sectionInputRef: RefObject<HTMLInputElement> = React.useRef<HTMLInputElement>(null);
 
  const backLinkTo = Routes.getMenusPage(companyId, locationId, menuConfigId);

  const hasMenuSections = menu.sections && !!menu.sections.length;
  const isEditingNewSection = sectionForm.editing && !sectionForm.section.uid;
  const isEditingThisSection = (section: IMenuSection): boolean => {
    return sectionForm.editing && section.uid === sectionForm.section.uid;
  };

  const onEditDetailsClick = (): void => {
    setMenuEditMode(true, menu);
  };

  const onCancelEditDetailsClick = (): void => {
    setMenuEditMode(false, cache);
  };

  const onSaveDetailsClick = (): void => {
    saveMenu({ companyId, locationId, menu });
  };

  const onAddItemsToItemGalleryClick = (): void => {
    setGroupIdForAddingItems('');
    setAddingToItemGallery(true);
    prepareItemsAndGroupsModal(true, formItemGallery!.items.map((item) => item.itemId) || [], ItemsAndGroupsView.ITEMS);
  };

  const onAddItemsToItemGalleryGroupClick = (id: string): void => {
    setGroupIdForAddingItems(id);
    setAddingToItemGallery(true);
    const itemGroup = formItemGallery!.items.find((item) => item.itemId === id) as IMenuSectionItemGroup;
    if (itemGroup) {
      prepareMenuItemsModal(
        true,
        itemGroup.items.map((item) => item.itemId),
        MenuItemsView.ITEMS
      );
    }
  };

  const onItemGalleryDragItem = (oldIndex: number, newIndex: number) => {
    updateItemGalleryReorderItems(oldIndex, newIndex);
    updateItemGalleryItemsSortOrder();
  };

  const onSaveItemGalleryClick = (): void => {
    // configuration from the location
    const readOnlyStock = !location?.settings.allowManualStockUpdates;
    saveInnerItemGallery(companyId, locationId, menu, formItemGallery!, galleryItemChanges, readOnlyStock);
  };

  const onCreateSectionClick = (): void => {
    setMenuSectionCreateMode(true);
  };

  const onEditSectionClick = (section: IMenuSection): void => {
    setMenuSectionEditMode(true, section);
    startEditingInnerItemGallery(section.itemGallery!);
  };

  const onAddItemGroupsToSectionClick = (): void => {
    setAddingToItemGallery(false);
    prepareItemsAndGroupsModal(
      true,
      sectionForm.section.items.map((item) => item.itemId),
      ItemsAndGroupsView.GROUPS
    );
  };

  const onSaveItemGroupClick = (): void => {
    if (addingToItemGallery) {
      saveNewItemGroupInItemGallery(companyId, locationId, itemGroup);
    } else {
      saveNewItemGroupInSection(companyId, locationId, itemGroup);
    }
  };

  const onAddItemsToSectionClick = (): void => {
    setGroupIdForAddingItems('');
    setAddingToItemGallery(false);
    prepareItemsAndGroupsModal(
      true,
      sectionForm.section.items.map((item) => item.itemId),
      ItemsAndGroupsView.ITEMS
    );
  };

  const onAddItemsToGroupClick = (id: string): void => {
    setGroupIdForAddingItems(id);
    setAddingToItemGallery(false);
    const itemGroup = sectionForm.section.items.find((item) => item.itemId === id) as IMenuSectionItemGroup;
    if (itemGroup) {
      prepareMenuItemsModal(
        true,
        itemGroup.items.map((item) => item.itemId),
        MenuItemsView.ITEMS
      );
    }
  };

  const onAddMenuItemToItemGroup = React.useCallback(
    (menuItem: IMenuItem): void => {
      // Add it in section and item gallery, as it's possible we're editing a section where the item group exists
      // in both the section and the section's item gallery, and these changes need to be kept synchronized. If
      // it's not in both places, or we're only editing the menu's item gallery, the other action will do nothing,
      // so there's no harm in calling it anyway.
      selectMenuItemForItemGalleryItemGroup(groupIdForAddingItems, menuItem);
      selectMenuItemForMenuSectionItemGroup(groupIdForAddingItems, menuItem);
    },
    [selectMenuItemForMenuSectionItemGroup, selectMenuItemForItemGalleryItemGroup, groupIdForAddingItems]
  );

  const onRemoveMenuItemFromItemGroup = React.useCallback(
    (menuItemId: string): void => {
      // Remove it in section and item gallery, as it's possible we're editing a section where the item group exists
      // in both the section and the section's item gallery, and these changes need to be kept synchronized. If
      // it's not in both places, or we're only editing the menu's item gallery, the other action will do nothing,
      // so there's no harm in calling it anyway.
      deselectMenuItemFromItemGalleryItemGroup(groupIdForAddingItems, menuItemId);
      deselectMenuItemFromMenuSectionItemGroup(groupIdForAddingItems, menuItemId);
    },
    [deselectMenuItemFromMenuSectionItemGroup, deselectMenuItemFromItemGalleryItemGroup, groupIdForAddingItems]
  );

  const onCancelEditSectionClick = (): void => {
    setMenuSectionEditMode(false);
  };

  const onSaveSectionClick = (): void => {
    // configuration from the location
    const readOnlyStock = !location?.settings.allowManualStockUpdates;
    saveMenuSection(
      companyId,
      locationId,
      menu,
      sectionForm.section,
      sectionForm.sectionItemChanges,
      formItemGallery!,
      galleryItemChanges,
      readOnlyStock
    );
  };

  const onDeleteSectionClick = (section: IMenuSection) => {
    showDeleteMenuSectionModal(true, section);
  };

  const onConfirmDeleteSection = () => {
    if (sectionForm.section) {
      deleteMenuSection(companyId, menu, sectionForm.section, locationId);
    }
  };

  const onChangeSectionStatusClick = (section: IMenuSection, enabled: boolean) => {
    changeMenuSectionStatus(companyId, locationId, menu, section, enabled);
  };

  const onReorderSectionsClick = () => {
    prepareReorderMenuSectionsModal(true);
  };

  const onSaveReorderedSections = (sections: IMenuSection[]) => {
    saveReorderedMenuSections(companyId, menu, sections, locationId);
  };

  const onUpdateItemVisibilityAndSave = React.useCallback(
    (sectionId: string, itemId: string, visible: boolean): void => {
      updateMenuItemVisibilityAndSave(companyId, locationId, menu, sectionId, itemId, visible);
    },
    [updateMenuItemVisibilityAndSave, companyId, locationId, menu]
  );

  const onUpdateGroupItemVisibilityAndSave = React.useCallback(
    (sectionId: string, groupId: string, itemId: string, visible: boolean): void => {
      updateItemGroupMenuItemVisibilityAndSave(companyId, locationId, menu, sectionId, groupId, itemId, visible);
    },
    [updateItemGroupMenuItemVisibilityAndSave, companyId, locationId, menu]
  );

  const onUpdateGalleryItemVisibilityAndSave = React.useCallback(
    (itemGalleryId: string, itemId: string, visible: boolean): void => {
      updateItemGalleryMenuItemVisibilityAndSave(companyId, locationId, menu, itemGalleryId, itemId, visible);
    },
    [updateItemGalleryMenuItemVisibilityAndSave, companyId, locationId, menu]
  );

  const onUpdateGalleryGroupItemVisibilityAndSave = React.useCallback(
    (itemGalleryId: string, groupId: string, itemId: string, visible: boolean): void => {
      updateItemGalleryItemGroupMenuItemVisibilityAndSave(
        companyId,
        locationId,
        menu,
        itemGalleryId,
        groupId,
        itemId,
        visible
      );
    },
    [updateItemGalleryItemGroupMenuItemVisibilityAndSave, companyId, locationId, menu]
  );

  const fetchLocation = React.useCallback(
    (companyId: string, locationId: string) => {
      if (!location || location.id !== locationId) {
        dispatch(LocationsThunks.loadSelectedLocation({ companyId, locationId }));
      }
    },
    [location, dispatch]
  );

  const fetchData = React.useCallback(
    (companyId: string, locationId: string, id: string) => {
      loadMenu(companyId, locationId, id);
    },
    [loadMenu]
  );

  const cleanUp = React.useCallback(() => {
    resetForms();
  }, [resetForms]);

  React.useEffect(() => {
    fetchLocation(companyId, locationId);
  }, [fetchLocation, companyId, locationId]);

  React.useEffect(() => {
    fetchData(companyId, locationId, id);
  }, [fetchData, companyId, locationId, id]);

  React.useEffect(() => {
    if (menuInputRef.current && editing) {
      menuInputRef.current.focus();
    }
    if (sectionInputRef.current && sectionForm.editing) {
      sectionInputRef.current.focus();
    }
  }, [
    menuInputRef,
    sectionInputRef,
    editing,
    sectionForm.editing,
    validation, // Include validation as a dependency so focus gets set on failed validation
    sectionForm.validation,
  ]);

  React.useEffect(() => {
    return () => {
      cleanUp();
    };
  }, [cleanUp]);

  React.useEffect(() => {
    getScheduleList(companyId, locationId);
    return () => {
      initScheduleList();
    };
  }, [getScheduleList, initScheduleList, companyId, locationId]);

  React.useEffect(() => {
    if (itemGroupForm.saved) {
      prepareItemsAndGroupsModal(false);
    }
  }, [itemGroupForm.saved, prepareItemsAndGroupsModal]);

  return (
    <>
      {sectionForm.deleteRequested && sectionForm.section && (
        <DeleteConfirmationModal
          setShowModal={showDeleteMenuSectionModal}
          item='Section'
          itemName={sectionForm.section.displayName}
          loading={sectionForm.processing}
          handleDelete={onConfirmDeleteSection}
        />
      )}

      <ReorderDialog
        visible={sectionForm.reordering}
        processing={sectionForm.processing}
        title='Reorder Sections'
        items={sectionForm.itemsForReordering}
        setVisible={prepareReorderMenuSectionsModal}
        keyExtractor={(section: IMenuSection) => section.uid}
        labelExtractor={(section: IMenuSection) => section.displayName}
        onMove={moveMenuSection}
        onSave={onSaveReorderedSections}
      />

      {itemGroupForm.editing && (
        <ItemGroupFormModal
          setShowModal={prepareNewItemGroupModal}
          onChangeDisplayName={updateItemGroupDisplayName}
          onChangeDescription={updateItemGroupDescription}
          onSave={onSaveItemGroupClick}
          itemGroupForm={itemGroupForm}
        />
      )}

      <AddItemsAndGroupsDialog
        visible={isSelectingItemsAndGroups && !addingToItemGallery}
        companyId={companyId}
        locationId={locationId}
        selectionType={itemsAndGroupsView}
        onMenuItemAdded={selectMenuItemForMenuSection}
        onItemGroupAdded={selectItemGroupForMenuSection}
        onMenuItemRemoved={deselectMenuItemFromMenuSection}
        onItemGroupRemoved={deselectItemGroupFromMenuSection}
      />

      <AddItemsAndGroupsDialog
        visible={isSelectingItemsAndGroups && addingToItemGallery}
        companyId={companyId}
        locationId={locationId}
        selectionType={itemsAndGroupsView}
        selectedMax={MenuBuilderConfig.itemGalleryLimit}
        onMenuItemAdded={selectItemForMenuGallery}
        onItemGroupAdded={selectItemGroupForMenuGallery}
        onMenuItemRemoved={deselectItemForMenuGallery}
        onItemGroupRemoved={deselectItemGroupForMenuGallery}
      />

      <AddMenuItemsDialog
        visible={isSelectingMenuItems}
        companyId={companyId}
        locationId={locationId}
        title='Add Menu Items'
        addMenuItemToSelection={onAddMenuItemToItemGroup}
        removeMenuItemFromSelection={onRemoveMenuItemFromItemGroup}
      />

      <MenuBuilderLayoutContent
        companyId={companyId}
        locationId={locationId}
        locationName={location && location.id === locationId ? location.name : null}
        stockStatusOnly={!hasManagePermission}
        tab={MenuBuilderTab.MENUS}
      >
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            {editing ? (
              <EditDetailsPanel
                menu={menuForm.menu}
                processing={menuForm.processing}
                validation={menuForm.validation}
                inputRef={menuInputRef}
                onChangeName={updateMenuDisplayName}
                onChangeDescription={updateMenuDescription}
                onChangeStatus={updateMenuStatus}
                onChangeScheduler={updateMenuScheduler}
                onCancel={onCancelEditDetailsClick}
                onSave={onSaveDetailsClick}
                schedules={schedules}
                areSchedulesLoading={areSchedulesLoading}
                isSharedMenuAssignedToCurrentLocation={isSharedMenuAssignedToCurrentLocation(menu.parentTemplateId)}
              />
            ) : (
              <div className={styles.details}>
                <MenuActionHeader
                  menu={menu}
                  backLinkTo={backLinkTo}
                  onEditDetailsClick={onEditDetailsClick}
                  editUnavailable={
                    isEditingMenuItemGallery || sectionForm.editing || menuForm.editing || menuForm.processing
                  }
                  editItemLabel='Edit Menu Details'
                />
              </div>
            )}

            <SectionHeader
              disabled={
                editing ||
                sectionForm.editing ||
                menuForm.processing ||
                itemGalleryProcessing ||
                isEditingMenuItemGallery
              }
              onReorderClick={onReorderSectionsClick}
              onCreateClick={onCreateSectionClick}
              isSharedMenuAssignedToCurrentLocation={isSharedMenuAssignedToCurrentLocation(menu.parentTemplateId)}
            />

            <div className={styles.sections}>
              <>
                {isEditingMenuItemGallery ? (
                  <EditItemGalleryPanel
                    onItemGalleryDragItem={onItemGalleryDragItem}
                    onAddItems={onAddItemsToItemGalleryClick}
                    onSubmit={onSaveItemGalleryClick}
                    onAddItemsToGroupClick={onAddItemsToItemGalleryGroupClick}
                    isSharedMenuAssignedToCurrentLocation={isSharedMenuAssignedToCurrentLocation(menu.parentTemplateId)}
                  />
                ) : (
                  <ItemGalleryCollapsiblePanel
                    menuItemsList={
                      <>
                        {menu.itemGallery && !!menu.itemGallery.items.length && (
                          <div className={styles.itemGalleryItems}>
                            <MenuItemAndItemGroupList
                              disabled={editing || sectionForm.editing || menuForm.processing}
                              parentDisabled={menuItemGalleryDisabled}
                              items={menu.itemGallery!.items}
                              getMenuItemLink={(menuItemId: string) =>
                                Routes.getMenuSectionItemPage(companyId, locationId, menu._id, menuItemId, menuConfigId)
                              }
                              getItemGroupLink={(itemGroupId: string) =>
                                Routes.getMenuSectionItemGroupPage(companyId, locationId, menu._id, itemGroupId, menuConfigId)
                              }
                              onItemVisibilityClick={(itemId: string, visible: boolean) =>
                                onUpdateGalleryItemVisibilityAndSave(menu.itemGallery!._id, itemId, visible)
                              }
                              onGroupItemVisibilityClick={(groupId: string, itemId: string, visible: boolean) =>
                                onUpdateGalleryGroupItemVisibilityAndSave(
                                  menu.itemGallery!._id,
                                  groupId,
                                  itemId,
                                  visible
                                )
                              }
                            />
                          </div>
                        )}
                      </>
                    }
                  />
                )}
              </>

              {isEditingNewSection && (
                <EditSectionPanel
                  sectionForm={sectionForm}
                  itemGalleryForm={itemGalleryForm}
                  itemGalleryLimit={MenuBuilderConfig.itemGalleryLimit}
                  inputRef={sectionInputRef}
                  onChangeName={updateMenuSectionDisplayName}
                  onChangeDescription={updateMenuSectionDescription}
                  onChangeStatus={updateMenuSectionStatus}
                  onChangeDefaultAppearance={updateMenuSectionDefaultAppearance}
                  onAddGroup={onAddItemGroupsToSectionClick}
                  onAddItems={onAddItemsToSectionClick}
                  onRemoveItem={removeMenuItemFromMenuSection}
                  onRemoveGroupItem={removeMenuItemFromItemGroupInMenuSection}
                  onChangeItemName={updateMenuItemDisplayNameInSection}
                  onChangeItemVisibility={updateMenuItemVisibilityInSection}
                  onChangeGroupItemVisibility={updateMenuItemVisibilityInSectionItemGroup}
                  onMoveItem={moveMenuItemInSection}
                  onMoveItemInGroup={moveMenuItemInGroup}
                  onAddItemsToGroupClick={onAddItemsToGroupClick}
                  onChangeScheduler={updateMenuSectionScheduler}
                  schedules={schedules}
                  onChangeGalleryName={menuGalleryUpdateGalleryName}
                  onChangeGalleryDescription={menuGalleryUpdateDescription}
                  onChangeGalleryStatus={(enabled) =>
                    menuGalleryUpdateStatus(enabled ? Status.enabled : Status.disabled)
                  }
                  onAddGalleryItems={onAddItemsToItemGalleryClick}
                  onRemoveGalleryItem={removeMenuGallerySubitem}
                  onRemoveGalleryGroupItem={deselectMenuItemFromItemGalleryItemGroup}
                  onChangeGalleryItemName={updateMenuGallerySubitemName}
                  onChangeGalleryItemVisibility={(itemId, visible) =>
                    updateItemGalleryItemVisibility(itemId, visible ? Visibility.visible : Visibility.hidden)
                  }
                  onChangeGalleryGroupItemVisibility={(groupId: string, itemId, visible) =>
                    updateItemGalleryItemGroupItemVisibility(
                      groupId,
                      itemId,
                      visible ? Visibility.visible : Visibility.hidden
                    )
                  }
                  onMoveGalleryItem={onItemGalleryDragItem}
                  onMoveGalleryItemInGroup={updateItemGalleryItemGroupItemsSortOrder}
                  onAddGalleryItemsToGroupClick={onAddItemsToItemGalleryGroupClick}
                  onCancel={onCancelEditSectionClick}
                  onSave={onSaveSectionClick}
                  areSchedulesLoading={areSchedulesLoading}
                />
              )}
              {hasMenuSections
                ? menu.sections.map((section) =>
                    isEditingThisSection(section) ? (
                      <EditSectionPanel
                        key={section.uid}
                        sectionForm={sectionForm}
                        itemGalleryForm={itemGalleryForm}
                        itemGalleryLimit={MenuBuilderConfig.itemGalleryLimit}
                        inputRef={sectionInputRef}
                        onChangeName={updateMenuSectionDisplayName}
                        onChangeDescription={updateMenuSectionDescription}
                        onChangeStatus={updateMenuSectionStatus}
                        onChangeDefaultAppearance={updateMenuSectionDefaultAppearance}
                        onAddGroup={onAddItemGroupsToSectionClick}
                        onAddItems={onAddItemsToSectionClick}
                        onRemoveItem={removeMenuItemFromMenuSection}
                        onRemoveGroupItem={removeMenuItemFromItemGroupInMenuSection}
                        onChangeItemName={updateMenuItemDisplayNameInSection}
                        onChangeItemVisibility={updateMenuItemVisibilityInSection}
                        onChangeGroupItemVisibility={updateMenuItemVisibilityInSectionItemGroup}
                        onMoveItem={moveMenuItemInSection}
                        onMoveItemInGroup={moveMenuItemInGroup}
                        onAddItemsToGroupClick={onAddItemsToGroupClick}
                        onChangeScheduler={updateMenuSectionScheduler}
                        schedules={schedules}
                        onChangeGalleryName={menuGalleryUpdateGalleryName}
                        onChangeGalleryDescription={menuGalleryUpdateDescription}
                        onChangeGalleryStatus={(enabled) =>
                          menuGalleryUpdateStatus(enabled ? Status.enabled : Status.disabled)
                        }
                        onAddGalleryItems={onAddItemsToItemGalleryClick}
                        onRemoveGalleryItem={removeMenuGallerySubitem}
                        onRemoveGalleryGroupItem={deselectMenuItemFromItemGalleryItemGroup}
                        onChangeGalleryItemName={updateMenuGallerySubitemName}
                        onChangeGalleryItemVisibility={(itemId, visible) =>
                          updateItemGalleryItemVisibility(itemId, visible ? Visibility.visible : Visibility.hidden)
                        }
                        onChangeGalleryGroupItemVisibility={(groupId: string, itemId, visible) =>
                          updateItemGalleryItemGroupItemVisibility(
                            groupId,
                            itemId,
                            visible ? Visibility.visible : Visibility.hidden
                          )
                        }
                        onMoveGalleryItem={onItemGalleryDragItem}
                        onMoveGalleryItemInGroup={updateItemGalleryItemGroupItemsSortOrder}
                        onAddGalleryItemsToGroupClick={onAddItemsToItemGalleryGroupClick}
                        onCancel={onCancelEditSectionClick}
                        onSave={onSaveSectionClick}
                        areSchedulesLoading={areSchedulesLoading}
                        isSharedMenuAssignedToCurrentLocation={isSharedMenuAssignedToCurrentLocation(
                          menu.parentTemplateId
                        )}
                      />
                    ) : (
                      <SectionPanel
                        key={section.uid}
                        companyId={companyId}
                        locationId={locationId}
                        menuId={menu._id}
                        isSharedMenuAssignedToCurrentLocation={isSharedMenuAssignedToCurrentLocation(
                          menu.parentTemplateId
                        )}
                        section={section}
                        expanded={expandedSections.includes(section.uid)}
                        disabled={editing || sectionForm.editing || menuForm.processing || isEditingMenuItemGallery}
                        isMobile={isMobile}
                        onSectionStateChanged={(isExpanded: boolean) =>
                          updateMenuSectionViewState(section.uid, isExpanded)
                        }
                        onEditClick={() => onEditSectionClick(section)}
                        onDeleteClick={() => onDeleteSectionClick(section)}
                        onChangeStatusClick={(enabled: boolean) => onChangeSectionStatusClick(section, enabled)}
                        onItemVisibilityClick={onUpdateItemVisibilityAndSave}
                        onGroupItemVisibilityClick={onUpdateGroupItemVisibilityAndSave}
                        onGalleryItemVisibilityClick={(itemId: string, visible: boolean) =>
                          onUpdateGalleryItemVisibilityAndSave(section.itemGallery!._id, itemId, visible)
                        }
                        onGalleryGroupItemVisibilityClick={(groupId: string, itemId: string, visible: boolean) =>
                          onUpdateGalleryGroupItemVisibilityAndSave(section.itemGallery!._id, groupId, itemId, visible)
                        }
                        getMenuItemLink={(menuItemId: string) =>
                          Routes.getMenuSectionItemPage(companyId, locationId, menu._id, menuItemId, menuConfigId)
                        }
                        getItemGroupLink={(itemGroupId: string) =>
                          Routes.getMenuSectionItemGroupPage(companyId, locationId, menu._id, itemGroupId, menuConfigId)
                        }
                      />
                    )
                  )
                : !isEditingNewSection && (
                    <EmptyResult
                      title='Add Menu Sections'
                      paragraph='You can create multiple sections within a menu to group similar items together, such as appetizers, mains or sides.'
                    />
                  )}
            </div>
          </>
        )}
      </MenuBuilderLayoutContent>
    </>
  );
};

export default connector(MenuPage);
