const segmentsToSkipRedirection = ['square/auth-redirect'];

const shouldRedirectFromState = (): boolean => {
  let check = true;
  segmentsToSkipRedirection.forEach((segment) => {
    if (window.location.pathname.includes(segment)) {
      check = false;
    }
  });
  return check;
};

export default shouldRedirectFromState;
