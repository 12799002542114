import React from 'react';

export interface TableBodyProps {
  children: JSX.Element[] | JSX.Element;
  emptyResult?: boolean;
}

const TableBodyEmpty = () => {
  return <div className='table__body__empty'>No Results Found</div>;
};

const TableBody = (props: TableBodyProps) => {
  const { children, emptyResult = false } = props;

  return <div className='table__body'>{emptyResult ? <TableBodyEmpty /> : children}</div>;
};

export default TableBody;
