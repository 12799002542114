import { PaginationResponse } from '@ready/dashboardv2api.contracts/dist/src/contracts/api/paginationResponse';
import { IMenuGroupAssignments } from '@ready/menu.core';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMenuGroupAssignments } from '../locations.service';

interface Args {
  companyId: string;
  locationSearchTerm?: string;
  page?: number;
}

export const getMenuGroupAssignmentsThunk = createAsyncThunk<PaginationResponse<IMenuGroupAssignments>, Args>(
  'sharedMenus/menuConfigs/assignments',
  async ({ companyId, locationSearchTerm, page }: Args) => {
    return getMenuGroupAssignments(companyId, locationSearchTerm, page);
  }
);
