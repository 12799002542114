import React from 'react';
import { IMenuItem, IMenuSectionItemGroup, Status, Visibility } from '@ready/menu.core';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../../redux/initialStates/AppState';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import TextInputNestedLabel from 'components/TextInputNestedLabel/TextInputNestedLabel';
import TextAreaNestedLabel from 'components/TextAreaNestedLabel/TextAreaNestedLabel';
import Button from '../../../components/Button/Button';
import AddItemsAndGroupsDialog from './AddItemsAndGroupsDialog';
import MenuBuilderConfig from '../../types/MenuBuilderConfig';
import ItemsAndGroupsView from '../../types/ItemsAndGroupsView.enum';
import {
  deselectItemForMenuGallery,
  menuGalleryCancelCreateMode,
  menuGalleryUpdateDescription,
  menuGalleryUpdateGalleryName,
  menuGalleryUpdateStatus,
  prepareItemsAndGroupsModal,
  removeMenuGallerySubitem,
  saveMenuItemGallery,
  selectItemForMenuGallery,
  updateItemGalleryItemsSortOrder,
  updateItemGalleryItemGroupItemsSortOrder,
  updateItemGalleryItemVisibility,
  updateItemGalleryItemGroupItemVisibility,
  updateItemGalleryReorderItems,
  updateMenuGallerySubitemName,
  selectItemGroupForMenuGallery,
  deselectItemGroupForMenuGallery,
} from '../../redux/MenusActions';
import {
  prepareNewItemGroupModal,
  updateItemGroupDescription,
  updateItemGroupDisplayName,
  saveNewItemGroupInItemGallery,
} from '../../redux/ItemGroupsActions';
import { prepareMenuItemsModal } from '../../redux/ItemsAndModsActions';
import {
  selectMenuItemForItemGalleryItemGroup,
  deselectMenuItemFromItemGalleryItemGroup,
} from '../../redux/MenuBuilderActions';
import InputError from '../../../components/InputError/InputError';

import styles from './MenuItemGalleryEntryForm.module.scss';
import SectionItems from './SectionItems';
import MenuItemsView from '../../types/MenuItemsView.enum';
import ItemGroupFormModal from '../ItemGroups/ItemGroupFormModal';
import AddMenuItemsDialog from '../ItemsAndMods/AddMenuItemsDialog';
import { Form } from '../../../components/Form';
import EditPanelFooter from './EditPanelFooter';
import LinkButton from 'components/LinkButton/LinkButton';
import { updateSharedItemGalleryThunk } from 'sharedMenuItems/pages/itemGallery/redux/thunk';
import { isSharedMenu } from 'sharedMenuItems/sharedMenuItems.utils';
const mapStateToProps = (state: AppState) => ({
  menuGalleryForm: state.menuBuilder.menus.menuGalleryForm,
  itemsAndGroupsSelection: state.menuBuilder.menus.itemsAndGroupsSelection,
  itemGroupForm: state.menuBuilder.itemGroups.itemGroupForm,
  isSelectingMenuItems: state.menuBuilder.itemsAndMods.menuItemSelection.modalVisible,
});

const actionCreators = {
  menuGalleryCancelCreateMode,
  menuGalleryUpdateGalleryName,
  menuGalleryUpdateDescription,
  menuGalleryUpdateStatus,
  prepareItemsAndGroupsModal,
  selectItemForMenuGallery,
  deselectItemForMenuGallery,
  updateMenuGallerySubitemName,
  updateItemGalleryItemVisibility,
  updateItemGalleryItemGroupItemVisibility,
  removeMenuGallerySubitem,
  updateItemGalleryItemsSortOrder,
  updateItemGalleryItemGroupItemsSortOrder,
  updateItemGalleryReorderItems,
  saveMenuItemGallery,
  selectItemGroupForMenuGallery,
  deselectItemGroupForMenuGallery,
  selectMenuItemForItemGalleryItemGroup,
  deselectMenuItemFromItemGalleryItemGroup,
  prepareNewItemGroupModal,
  updateItemGroupDescription,
  updateItemGroupDisplayName,
  saveNewItemGroupInItemGallery,
  prepareMenuItemsModal,
  updateSharedItemGalleryThunk,
};

const connector = connect(mapStateToProps, actionCreators);

export interface MenuItemGalleryEntryFormProps extends ConnectedProps<typeof connector> {
  companyId: string;
  locationId?: string;
}

const MenuItemGalleryEntryForm = (props: MenuItemGalleryEntryFormProps) => {
  const {
    menuGalleryForm,
    menuGalleryCancelCreateMode,
    menuGalleryUpdateGalleryName,
    menuGalleryUpdateDescription,
    menuGalleryUpdateStatus,
    companyId,
    locationId = '',
    itemsAndGroupsSelection: { modalVisible: isSelectingItemsAndGroups, view: itemsAndGroupsView },
    itemGroupForm,
    isSelectingMenuItems,
    prepareItemsAndGroupsModal,
    selectItemForMenuGallery,
    deselectItemForMenuGallery,
    updateMenuGallerySubitemName,
    updateItemGalleryItemVisibility,
    updateItemGalleryItemGroupItemVisibility,
    removeMenuGallerySubitem,
    updateItemGalleryReorderItems,
    updateItemGalleryItemsSortOrder,
    updateItemGalleryItemGroupItemsSortOrder,
    saveMenuItemGallery,
    selectItemGroupForMenuGallery,
    deselectItemGroupForMenuGallery,
    selectMenuItemForItemGalleryItemGroup,
    deselectMenuItemFromItemGalleryItemGroup,
    prepareNewItemGroupModal,
    updateItemGroupDescription,
    updateItemGroupDisplayName,
    saveNewItemGroupInItemGallery,
    prepareMenuItemsModal,
    updateSharedItemGalleryThunk,
  } = props;

  const [groupIdForAddingItems, setGroupIdForAddingItems] = React.useState<string>('');

  const item = menuGalleryForm.menu;

  const openAddItems = (): void => {
    setGroupIdForAddingItems('');
    prepareItemsAndGroupsModal(
      true,
      menuGalleryForm.menu.itemGallery?.items.map((item) => item.itemId) || [],
      ItemsAndGroupsView.ITEMS
    );
  };

  const handleReorderItems = (oldIndex: number, newIndex: number) => {
    updateItemGalleryReorderItems(oldIndex, newIndex);
    updateItemGalleryItemsSortOrder();
  };
  const handleSaveMenuGallery = () => {
    if (isSharedMenu(companyId, locationId) && menuGalleryForm.menu.itemGallery) {
      updateSharedItemGalleryThunk({
        companyId,
        itemGallery: {
          ...menuGalleryForm.menu.itemGallery,
          displayName: menuGalleryForm.menu.itemGallery.displayName ?? '',
          sortOrder: menuGalleryForm.menu.sortOrder,
        },
        sectionItemChanges: menuGalleryForm.sectionItemChanges,
      });
    } else {
      saveMenuItemGallery(companyId, locationId, menuGalleryForm.menu, menuGalleryForm.sectionItemChanges);
    }
  };

  const formIsLoading = menuGalleryForm.loading;
  const formIsProcessing = menuGalleryForm.processing;

  const onAddMenuItemToItemGroup = React.useCallback(
    (menuItem: IMenuItem): void => {
      selectMenuItemForItemGalleryItemGroup(groupIdForAddingItems, menuItem);
    },
    [selectMenuItemForItemGalleryItemGroup, groupIdForAddingItems]
  );

  const onRemoveMenuItemFromItemGroup = React.useCallback(
    (menuItemId: string): void => {
      deselectMenuItemFromItemGalleryItemGroup(groupIdForAddingItems, menuItemId);
    },
    [deselectMenuItemFromItemGalleryItemGroup, groupIdForAddingItems]
  );

  React.useEffect(() => {
    if (itemGroupForm.saved) {
      prepareItemsAndGroupsModal(false);
    }
  }, [itemGroupForm.saved, prepareItemsAndGroupsModal]);

  if (formIsLoading) {
    return (
      <div className={styles.itemGalleryForm}>
        <div className={styles.itemGalleryFormLoading}>
          <LoadingSpinner additionalStyles={styles.itemGalleryFormLoadingSpinner} />
        </div>
      </div>
    );
  }

  const saveButtonLabel = item._id ? 'Save Changes' : 'Create Gallery';

  const itemLimitExceeded =
    menuGalleryForm.menu.itemGallery &&
    menuGalleryForm.menu.itemGallery.items.length > MenuBuilderConfig.itemGalleryLimit;
  const showFooterWarningMessage = itemLimitExceeded
    ? `Item Gallery limit of ${MenuBuilderConfig.itemGalleryLimit} exceeded. Please remove some items before saving.`
    : '';
  const onChangeStatus = (checked: boolean) => {
    menuGalleryUpdateStatus(checked ? Status.enabled : Status.disabled);
  };
  const onAddItemsToItemGalleryGroupClick = (id: string) => {
    setGroupIdForAddingItems(id);
    const itemGroup = menuGalleryForm.menu.itemGallery!.items.find(
      (item) => item.itemId === id
    ) as IMenuSectionItemGroup;
    if (itemGroup) {
      prepareMenuItemsModal(
        true,
        itemGroup.items.map((item) => item.itemId),
        MenuItemsView.ITEMS
      );
    }
  };

  return (
    <>
      <Form hasGroups onSubmit={handleSaveMenuGallery}>
        <AddItemsAndGroupsDialog
          visible={isSelectingItemsAndGroups}
          companyId={companyId}
          locationId={locationId}
          selectionType={itemsAndGroupsView}
          selectedMax={MenuBuilderConfig.itemGalleryLimit}
          onMenuItemAdded={selectItemForMenuGallery}
          onItemGroupAdded={selectItemGroupForMenuGallery}
          onMenuItemRemoved={deselectItemForMenuGallery}
          onItemGroupRemoved={deselectItemGroupForMenuGallery}
        />

        {itemGroupForm.editing && (
          <ItemGroupFormModal
            setShowModal={prepareNewItemGroupModal}
            onChangeDisplayName={updateItemGroupDisplayName}
            onChangeDescription={updateItemGroupDescription}
            onSave={() => saveNewItemGroupInItemGallery(companyId, locationId, itemGroupForm.itemGroup)}
            itemGroupForm={itemGroupForm}
          />
        )}

        <AddMenuItemsDialog
          visible={isSelectingMenuItems}
          companyId={companyId}
          locationId={locationId}
          title='Add Menu Items'
          addMenuItemToSelection={onAddMenuItemToItemGroup}
          removeMenuItemFromSelection={onRemoveMenuItemFromItemGroup}
        />

        <div className={styles.itemGalleryForm}>
          <div className={styles.top}>
            <div className={styles.topControls}>
              <TextInputNestedLabel
                value={item.itemGallery?.displayName ?? ''}
                placeholder='Item Gallery Name'
                onChange={(e) => menuGalleryUpdateGalleryName(e.target.value)}
                withError={menuGalleryForm.validation.displayName.hasError}
                maxLength={60}
                label={'Item Gallery Name *'}
                disabled={formIsProcessing}
              />
              {menuGalleryForm.validation.displayName.hasError && (
                <InputError message={menuGalleryForm.validation.displayName.errorMessage} />
              )}
              <TextAreaNestedLabel
                value={item.itemGallery?.description || ''}
                label={'description'}
                onChange={(e) => menuGalleryUpdateDescription(e.target.value)}
                placeholder='Enter a description which will be displayed to your guests.'
                disabled={formIsProcessing}
                maxLength={100}
              />
            </div>
            <div className={styles.topButton}>
              <Button variant='secondary' label='+ Add Items' onClick={openAddItems} unavailable={formIsProcessing} />
            </div>
          </div>

          <div className={styles.middle}>
            {!!menuGalleryForm.menu.itemGallery && menuGalleryForm.menu.itemGallery.items.length > 0 ? (
              <SectionItems
                items={menuGalleryForm.menu.itemGallery.items}
                itemChanges={menuGalleryForm.sectionItemChanges}
                processing={formIsProcessing}
                onRemoveItem={removeMenuGallerySubitem}
                onRemoveGroupItem={deselectMenuItemFromItemGalleryItemGroup}
                onChangeItemName={updateMenuGallerySubitemName}
                onChangeItemVisibility={(itemId, visible) =>
                  updateItemGalleryItemVisibility(itemId, visible ? Visibility.visible : Visibility.hidden)
                }
                onChangeGroupItemVisibility={(groupId: string, itemId, visible) =>
                  updateItemGalleryItemGroupItemVisibility(
                    groupId,
                    itemId,
                    visible ? Visibility.visible : Visibility.hidden
                  )
                }
                onAddItemsToGroupClick={onAddItemsToItemGalleryGroupClick}
                onMoveItemInGroup={updateItemGalleryItemGroupItemsSortOrder}
                onMoveItem={handleReorderItems}
                isSharedMenu={isSharedMenu(companyId, locationId)}
              />
            ) : (
              <div className={styles.noSections}>
                <LinkButton onClick={openAddItems} disabled={formIsProcessing}>
                  + Add Items
                </LinkButton>
                <p className={styles.noSectionsText}>
                  &nbsp;to this gallery to promote them to your guests{' '}
                  <span className={styles.noSectionsTextItems}>(max {MenuBuilderConfig.itemGalleryLimit} items)</span>.
                </p>
              </div>
            )}
          </div>

          <EditPanelFooter
            onCancel={menuGalleryCancelCreateMode}
            onChangeStatus={onChangeStatus}
            saveLabel={saveButtonLabel}
            status={item.itemGallery?.status || Status.disabled}
            statusLabel='Status:'
            processing={formIsProcessing}
            warningMessage={showFooterWarningMessage}
            isSharedMenu={isSharedMenu(companyId, locationId)}
          />
        </div>
      </Form>
    </>
  );
};

export default connector(MenuItemGalleryEntryForm);
