import { IMenuItemGroup, IEmbeddedMenuItem } from '@ready/menu.core';
import NamedEntityValidation, { newNamedEntityValidation } from '../types/NamedEntityValidation.interface';
import { PaginationResponse } from '@ready/dashboardv2api.contracts';
import InfiniteScrollingListState from '../../types/InfiniteScrollingListState.interface';
import InfiniteScrollingListStateHelper from '../../utils/InfiniteScrollingList/InfiniteScrollingListStateHelper';

export interface IItemGroupSelection extends InfiniteScrollingListState<IMenuItemGroup> {}

export interface IItemGroupForm {
  editing: boolean;
  processing: boolean;
  saved: boolean;
  itemGroup: IMenuItemGroup;
  validation: NamedEntityValidation;
}

export interface IItemGroupsCreatePageValidation {
  validated: boolean;
  displayName: {
    hasError: boolean;
    errorMessage: string;
  };
}

export interface IItemGroupsCreatePage {
  editing: boolean;
  processing: boolean;
  itemGroupSaved: string | null;
  fields: IMenuItemGroup;
  validation: IItemGroupsCreatePageValidation;
}
export interface IItemGroupslistPage {
  loading: boolean;
  error: boolean;
  itemGroups: PaginationResponse<IMenuItemGroup>;
  query: string;
  page: string;
}

export interface IItemGroupDetailsFields extends Omit<IMenuItemGroup, 'items'> {
  validation: {
    validated: boolean;
    displayName: {
      hasError: boolean;
      errorMessage: string;
    };
  };
}

export interface IItemGroupDetailsPanel {
  processing: boolean;
  itemGroupFields: IItemGroupDetailsFields;
  itemGroupSaved: string | null;
}

export interface IItemGroupGroupItemsPanel {
  processing: boolean;
  visibilityUpdated: boolean;
  items: IEmbeddedMenuItem[];
}

export interface IItemGroupImages {
  imageId?: string;
  thumbnailImageId?: string;
}

export interface IItemGroupImagesPanel extends IItemGroupImages {
  processingImage: boolean;
  errorImage: boolean;
  imageWasChanged: boolean;
  processingThumbnailImage: boolean;
  errorThumbnailImage: boolean;
  thumbnailImageWasChanged: boolean;
}

export interface IItemGroupViewEditPage {
  loading: boolean;
  error: boolean;
  deleteLoading: boolean;
  deleteError: boolean;
  itemDeleted: boolean;
  detailsPanel: IItemGroupDetailsPanel;
  groupItemsPanel: IItemGroupGroupItemsPanel;
  imagesPanel: IItemGroupImagesPanel;
  itemGroupCache: IMenuItemGroup;
}

export interface IItemGroupsState {
  itemGroupForm: IItemGroupForm;
  itemGroupSelection: IItemGroupSelection;
  itemGroupsListPage: IItemGroupslistPage;
  itemGroupViewEditPage: IItemGroupViewEditPage;
}

export const newItemGroup = (): IMenuItemGroup => ({
  _id: '',
  displayName: '',
  items: [],
});

export const createItemGroupBlank = (): IMenuItemGroup => ({
  _id: '',
  displayName: '',
  description: '',
  items: [],
});

export const viewEditItemGroupBlank = (): IItemGroupViewEditPage => ({
  loading: true,
  error: false,
  deleteLoading: false,
  deleteError: false,
  itemDeleted: false,
  detailsPanel: {
    itemGroupSaved: null,
    processing: false,
    itemGroupFields: {
      _id: '',
      displayName: '',
      description: '',
      validation: {
        validated: false,
        displayName: {
          hasError: false,
          errorMessage: '',
        },
      },
    },
  },
  groupItemsPanel: {
    processing: false,
    visibilityUpdated: false,
    items: [],
  },
  imagesPanel: {
    imageId: '',
    processingImage: false,
    errorImage: false,
    imageWasChanged: false,
    thumbnailImageId: '',
    processingThumbnailImage: false,
    errorThumbnailImage: false,
    thumbnailImageWasChanged: false,
  },
  itemGroupCache: newItemGroup(),
});

export const itemGroupsState: IItemGroupsState = {
  itemGroupForm: {
    editing: false,
    processing: false,
    saved: false,
    itemGroup: newItemGroup(),
    validation: newNamedEntityValidation(),
  },
  itemGroupSelection: InfiniteScrollingListStateHelper.initialState(),
  itemGroupsListPage: {
    loading: true,
    error: false,
    query: '',
    page: '',
    itemGroups: {
      items: [],
      limit: 0,
      length: 0,
      start: 0,
      total: 0,
    },
  },
  itemGroupViewEditPage: viewEditItemGroupBlank(),
};
