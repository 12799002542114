import React from 'react';
import classNames from 'classnames';
import styles from './UnassignedPanelSection.module.scss';
import { UnassignedPanelNoResults } from './index';

interface UnassignedPanelSectionProps {
  section: 'unassigned' | 'assigned';
  showEmpty?: boolean;
  showNoResults?: boolean;
  children?: JSX.Element | JSX.Element[];
  additionalContentStyles?: string;
}

const UnassignedPanelSection = ({
  section,
  showEmpty = false,
  showNoResults = false,
  children,
  additionalContentStyles,
}: UnassignedPanelSectionProps) => {
  return (
    <div>
      <div className={classNames(styles.textContainer, additionalContentStyles)}>
        <span className={styles.text}>{section.toUpperCase()}</span>
      </div>
      {showEmpty ? (
        <div className={additionalContentStyles}>
          <UnassignedPanelNoResults messageType='empty' />
        </div>
      ) : showNoResults ? (
        <div className={additionalContentStyles}>
          <UnassignedPanelNoResults messageType='noSearchResults' />
        </div>
      ) : (
        children
      )}
    </div>
  );
};

export default UnassignedPanelSection;
