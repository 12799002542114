export default class ServiceError extends Error {
  status: number;

  constructor(status: number, message: string) {
    super(message);

    this.name = 'ServiceError';
    this.status = status;
  }
}
