import config from '../../config';
import {
  IDiscount,
  ILocationEditModelResponse,
  ILocationLoyaltyOptions,
  LoyaltyProvider,
} from '@ready/dashboardv2api.contracts';
import executeApiAsync from '../../services/utils/executeApiAsync';
import {
  newByPassValidations,
  newSpendgoValidations,
  ISpendgoValidations,
  IByPassValidations,
  ILoyaltyValidationsResult,
  ISquareValidations,
  newSquareValidations,
  newPunchhValidations,
} from '../redux/PanelLoyaltyState';

export default class LoyaltyPOSService {
  static BASE_URL = config.readyDashboardApiEndpoint;

  static getLocationDiscounts = async (locationId: string, companyId: string, query: string): Promise<IDiscount[]> => {
    const url = `${LoyaltyPOSService.BASE_URL}/companies/${companyId}/locations/${locationId}/discounts?q=${query}`;
    return await executeApiAsync<IDiscount[]>(url, undefined);
  };

  static validate = (loyaltyData: ILocationLoyaltyOptions): ILoyaltyValidationsResult => {
    const provider = loyaltyData.provider;
    let result: ILoyaltyValidationsResult = {
      errorsFound: false,
      fields: {} as ISpendgoValidations | ISquareValidations | IByPassValidations,
    };
    if (provider === LoyaltyProvider.Square) {
      result.fields = newSquareValidations();
      if (!loyaltyData.squareOptions?.locationId) {
        result.errorsFound = true;
        result.fields.location.hasError = true;
        result.fields.location.errorMessage = 'Location ID is required';
      }
      if (!loyaltyData.squareOptions?.programId) {
        result.errorsFound = true;
        result.fields.programId.hasError = true;
        result.fields.programId.errorMessage = 'Program ID is required';
      }
      if (!loyaltyData.squareOptions?.apiKey) {
        result.errorsFound = true;
        result.fields.apiKey.hasError = true;
        result.fields.apiKey.errorMessage = 'API Key is required';
      }
      if (!loyaltyData.squareOptions?.loyaltyDiscount) {
        result.errorsFound = true;
        result.fields.loyaltyDiscount.hasError = true;
        result.fields.loyaltyDiscount.errorMessage = 'Loyalty Discount is required';
      }
    }
    if (provider === LoyaltyProvider.Spendgo) {
      result.fields = newSpendgoValidations();
      if (!loyaltyData.spendgoOptions?.locationId) {
        result.errorsFound = true;
        result.fields.storeCode.hasError = true;
        result.fields.storeCode.errorMessage = 'Store Code is required';
      }
      if (!loyaltyData.spendgoOptions?.brandId) {
        result.errorsFound = true;
        result.fields.accountId.hasError = true;
        result.fields.accountId.errorMessage = 'Account ID is required';
      }
      if (!loyaltyData.spendgoOptions?.loyaltyDiscount) {
        result.errorsFound = true;
        result.fields.loyaltyDiscount.hasError = true;
        result.fields.loyaltyDiscount.errorMessage = 'Loyalty Discount is required';
      }
      if (!loyaltyData.spendgoOptions?.accountDetailsUrl) {
        result.errorsFound = true;
        result.fields.accountDetailsUrl.hasError = true;
        result.fields.accountDetailsUrl.errorMessage = 'Account Details URL is required';
      }
      if (!loyaltyData.spendgoOptions?.clientId) {
        result.errorsFound = true;
        result.fields.apiKey.hasError = true;
        result.fields.apiKey.errorMessage = 'API Key is required';
      }
      if (!loyaltyData.spendgoOptions?.clientSecret) {
        result.errorsFound = true;
        result.fields.clientSecret.hasError = true;
        result.fields.clientSecret.errorMessage = 'Client Secret is required';
      }
    }
    if (provider === LoyaltyProvider.ByPass) {
      result.fields = newByPassValidations();
      if (!loyaltyData.byPassOptions?.locationId) {
        result.errorsFound = true;
        result.fields.locationId.hasError = true;
        result.fields.locationId.errorMessage = 'Location ID is required';
      }
    }

    if (provider === LoyaltyProvider.Punchh) {
      result.fields = newPunchhValidations();
      if (!loyaltyData.punchhOptions?.storeId) {
        result.errorsFound = true;
        result.fields.storeId.hasError = true;
        result.fields.storeId.errorMessage = 'Store ID is required';
      }
      if (!loyaltyData.punchhOptions?.loyaltyDiscount) {
        result.errorsFound = true;
        result.fields.loyaltyDiscount.hasError = true;
        result.fields.loyaltyDiscount.errorMessage = 'Loyalty Discount is required';
      }
    }
    return result;
  };

  public static updateLoyaltyConfig = async (
    locationId: string,
    params: ILocationLoyaltyOptions
  ): Promise<ILocationEditModelResponse> => {
    const url = `${LoyaltyPOSService.BASE_URL}/admins/locations/${locationId}/loyalty`;
    return await executeApiAsync<ILocationEditModelResponse>(url, {
      method: 'PUT',
      body: JSON.stringify(params),
    });
  };
}
