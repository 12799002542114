import { Form, FormControl } from 'components/Form';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'components/Modal';
import TextArea from 'components/TextArea/TextArea';
import TextInput from 'components/TextInput/TextInput';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

export interface ISaveNameAndDescriptionModalFields {
  displayName: string;
  description?: string;
}

interface Props {
  onSave: (modalFields: ISaveNameAndDescriptionModalFields) => void;
  setShowModal: (show: boolean) => void;
  handleCancel: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  fieldsToEdit?: ISaveNameAndDescriptionModalFields;
  labelName?: string;
}

/** To be used in cases where we are editing a name and description. ie. menus and item galleries */
export const NameAndDescriptionModal = ({
  onSave,
  setShowModal,
  handleCancel,
  fieldsToEdit,
  labelName = '',
}: Props) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ISaveNameAndDescriptionModalFields>({
    defaultValues: fieldsToEdit ?? { displayName: '', description: '' },
  });

  const [saving, setSaving] = useState<boolean>(false);
  const descriptionValue = watch('description');

  return (
    <Modal setShowModal={setShowModal}>
      <ModalHeader
        headerLabel={`${fieldsToEdit ? 'Edit' : 'New'} ${labelName}`}
        setShowModal={setShowModal}
      ></ModalHeader>
      <ModalBody>
        <Form isModalForm useMobileStyling>
          <FormControl
            label={`${labelName} Name *`}
            errorMessage={errors.displayName?.message}
            withError={errors.displayName !== undefined}
          >
            <TextInput
              {...register('displayName', { required: 'Required field.' })}
              autoFocus
              withError={errors.displayName !== undefined}
              disabled={saving}
            />
          </FormControl>

          <FormControl label={`${labelName} Description`}>
            <TextArea
              {...register('description')}
              disabled={saving}
              placeholder='E.g. Available daily'
              maxLength={150}
              value={descriptionValue}
            />
          </FormControl>
        </Form>
      </ModalBody>
      <ModalFooter
        primaryLabel={fieldsToEdit ? 'Save Changes' : `Create ${labelName}`}
        primaryActionHandler={handleSubmit(async (modalFields) => {
          setSaving(true);
          await onSave(modalFields);
        })}
        loading={saving}
        secondaryActionHandler={handleCancel}
        secondaryLabel='Cancel'
      />
    </Modal>
  );
};
