import React, { PropsWithChildren } from 'react';
import { components } from 'react-select';
import { getSelectedOption } from '../../../utils/selectListUtils/listUtil';
import { IUnsavedMappedTableConfiguration } from '../../redux/initialStates/tablesDetails';
import styles from './EditableSelectCell.module.scss';
import SelectFilter, { Option } from '../../../components/SelectFilter/SelectFilter';
import NestedLabel from '../../../components/NestedLabel/NestedLabel';

export interface EditableSelectCellProps {
  label: string;
  options: Option[];
  tableId: string;
  value: Option;
  unsavedMappedPosOrderTypes: IUnsavedMappedTableConfiguration;
  isMobile: boolean;
  handleSave: (tableId: string, orderExperienceTypeValue: string) => void;
}

interface ContainerProps {
  isMobile: boolean;
  label: string;
  value?: string;
}

const Container = ({ isMobile, label, value, children }: PropsWithChildren<ContainerProps>): JSX.Element =>
  isMobile ? (
    <NestedLabel label={label} value={value}>
      {children}
    </NestedLabel>
  ) : (
    <>{children}</>
  );

const EditableSelectCell = (props: EditableSelectCellProps) => {
  const { label, options, tableId, value, handleSave, isMobile, unsavedMappedPosOrderTypes } = props;

  const unsavedValue = unsavedMappedPosOrderTypes[tableId]
    ? getSelectedOption(options, unsavedMappedPosOrderTypes[tableId], '')
    : null;

  const handleChange = ({ value }: any) => {
    handleSave(tableId, value);
  };

  const Option = (props: any) => (
    <components.Option {...props}>
      {props.data.value === 'default' ? <span className={styles.default}>{props.data.label}</span> : props.data.label}
    </components.Option>
  );

  const SingleValue = (props: any) => (
    <components.SingleValue {...props}>
      {props.data.value === 'default' ? <span className={styles.default}>{props.data.label}</span> : props.data.label}
    </components.SingleValue>
  );

  return (
    <Container isMobile={isMobile} label={label} value={`${value.label}`}>
      <SelectFilter
        customComponents={{ Option, SingleValue }}
        options={options}
        onChange={handleChange}
        value={unsavedValue ?? value}
        additionalSelectStyles={styles.select}
        additionalStyles={styles.container}
      />
    </Container>
  );
};

export default EditableSelectCell;
