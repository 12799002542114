import React, { useEffect } from 'react';
import { Panel } from 'components/PanelLayout';
import { useState } from 'react';
import Button from 'components/Button/Button';

import { AddPaymentTerminalModal, IAddTerminalFormFields } from './components/AddPaymentTerminalModal';
import { PaymentTerminalPanelListItem } from './components/PaymentTerminalPanelListItem';
import { NoResultsPanelListItem, PanelListItem } from 'components/PanelLayout/PanelListItem';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { selectPaymentTerminalsPanelState } from './redux/selectors';
import { useParams } from 'react-router-dom';
import { selectLocationCacheState } from 'redux/selectors/cacheSelectors/cacheSelectors';

import Skeleton from 'components/Skeleton/Skeleton';
import styles from './PaymentTerminalsPanel.module.scss';
import { createTerminalThunk, deleteTerminalThunk, getTerminalsThunk } from './redux/thunks';
import { TerminalDto } from '@ready/dashboardv2api.contracts';

export const PaymentTerminalsPanel = () => {
  const dispatch = useAppDispatch();
  const location = useAppSelector(selectLocationCacheState);
  const { companyId, id: locationId } = useParams<{ companyId: string; id: string }>();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const headerControls = (
    <div>
      <Button label='+ Add Terminal' onClick={() => setShowEditModal(true)} />
    </div>
  );

  const { terminals, loading } = useAppSelector(selectPaymentTerminalsPanelState);

  useEffect(() => {
    dispatch(getTerminalsThunk({ companyId, locationId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddTerminal = async (fields: IAddTerminalFormFields) => {
    await dispatch(
      createTerminalThunk({
        companyId,
        locationId,
        terminal: {
          currency: location.details.currency as 'CAD' | 'USD', // todo: need to do something about MXN?
          modelName: 'S1F2',
          serialNumber: fields.serialNumber,
        },
      })
    );
    dispatch(getTerminalsThunk({ companyId, locationId }));
  };

  const onDeleteTerminal = async (terminalId: string) => {
    await dispatch(deleteTerminalThunk({ companyId, locationId, terminalId }));
    dispatch(getTerminalsThunk({ companyId, locationId }));
  };

  return (
    <>
      <Panel title='Payment Terminals' headerControls={headerControls} fullContentArea>
        {!loading ? (
          <>
            {terminals.length > 0 ? (
              terminals.map((terminal: TerminalDto) => (
                <PaymentTerminalPanelListItem
                  key={terminal.id}
                  terminalId={terminal.id}
                  serialNumber={terminal.serialNumber}
                  onDelete={onDeleteTerminal}
                />
              ))
            ) : (
              <NoResultsPanelListItem message='To add your first terminal, click the ‘+ Add Terminal’ button and enter the terminal’s serial number.' />
            )}
          </>
        ) : (
          <Skeletons />
        )}
      </Panel>

      {showEditModal && <AddPaymentTerminalModal setShowModal={setShowEditModal} onAddTerminal={onAddTerminal} />}
    </>
  );
};

const Skeletons = () => {
  return (
    <>
      <PanelListItem>
        <div className={styles.skels}>
          <Skeleton />
        </div>
      </PanelListItem>
      <PanelListItem>
        <div className={styles.skels}>
          <Skeleton />
        </div>
      </PanelListItem>
      <PanelListItem>
        <div className={styles.skels}>
          <Skeleton />
        </div>
      </PanelListItem>
    </>
  );
};
