import React, { PropsWithChildren } from 'react';
import styles from './NestedLabel.module.scss';
import { compositeStyles } from '../../utils/cssUtils';

interface NestedLabelProps<T> {
  label: string;
  value?: T;
  loading?: boolean;
  withError?: boolean;
  className?: string;
}

const NestedLabel = <T,>({
  label,
  value,
  loading,
  withError,
  className,
  children,
}: PropsWithChildren<NestedLabelProps<T>>): JSX.Element => (
  <div className={compositeStyles([styles.container, className], styles)}>
    <div
      className={compositeStyles(
        [styles.label, { hidden: !value }, { loading: loading }, { error: withError }],
        styles
      )}
    >
      {label}
    </div>
    {children}
  </div>
);

export default NestedLabel;
