import React from 'react';

import styles from './LocationCard.module.scss';

import { ILocation } from '../../services/types/ILocation.type';

import { Link } from 'react-router-dom';
import InfoCard from '../InfoCard/InfoCard';
import InfoCardColumn from '../InfoCard/InfoCardColumn';

export interface ILocationCardProps {
  location: ILocation;
  path: string;
}

const LocationCard = (props: ILocationCardProps): JSX.Element => (
  <Link to={`${props.path.replace(':id', props.location._id)}`}>
    <InfoCard>
      <InfoCardColumn>
        <div className={styles.name}>{props.location.name}</div>
        <div className={styles.address}>{props.location.fullAddress || <i>No address</i>}</div>
      </InfoCardColumn>
    </InfoCard>
  </Link>
);

export default LocationCard;
