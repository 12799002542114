export const ORDERS_ACTIONS = {
  // Order locations
  LOAD_ORDERS_LOCATIONS_BEGIN: 'LOAD_ORDERS_LOCATIONS_BEGIN',
  LOAD_ORDERS_LOCATIONS_SUCCESS: 'LOAD_ORDERS_LOCATIONS_SUCCESS',
  LOAD_ORDERS_LOCATIONS_ERROR: 'LOAD_ORDERS_LOCATIONS_ERROR',

  // Order location details
  SET_LOADING_LOCATION_DETAILS: 'SET_LOADING_LOCATION_DETAILS',
  SET_CLIENT_LOCATION_DETAILS: 'SET_CLIENT_LOCATION_DETAILS',
  SET_LOCATION_DETAILS: 'SET_LOCATION_DETAILS',
  SET_LOCATION_ORDER_TOGGLE_PROCESSING: 'SET_LOCATION_ORDER_TOGGLE_PROCESSING',
  SET_LOCATION_ALCOHOL_ORDER_TOGGLE_PROCESSING: 'SET_LOCATION_ALCOHOL_ORDER_TOGGLE_PROCESSING',
  UPDATE_LOCATION_ORDER_TOGGLE: 'UPDATE_LOCATION_ORDER_TOGGLE',
  UPDATE_LOCATION_ALCOHOL_ORDER_TOGGLE: 'UPDATE_LOCATION_ALCOHOL_ORDER_TOGGLE',

  // Order List
  SET_LOADING_ORDERS: 'SET_LOADING_ORDERS',
  SET_ORDER_LIST: 'SET_ORDER_LIST',
  SET_OVERDUE_ORDERS: 'SET_OVERDUE_ORDERS',
  SET_OFFSET_ORDERS: 'SET_OFFSET_ORDERS',
  SET_UP_NEXT_ORDERS: 'SET_UP_NEXT_ORDERS',
  SET_COMPLETED_ORDERS: 'SET_COMPLETED_ORDERS',
  SET_CANCELLED_ORDERS: 'SET_CANCELLED_ORDERS',
  INIT_ORDERS: 'INIT_ORDERS',
  INIT_LOCATIONS: 'INIT_LOCATIONS',
  INIT_ORDER_SMS_CONFIGS_DATA: 'INIT_ORDER_SMS_CONFIGS_DATA',
  SET_ORDER_LIST_URL_QUERY: 'SET_ORDER_LIST_URL_QUERY',

  // Order settings
  INIT_ORDER_SETTINGS_DIALOG_DATA: 'INIT_ORDER_SETTINGS_DIALOG_DATA',
  SET_ORDER_SETTINGS_DIALOG_DATA: 'SET_ORDER_SETTINGS_DIALOG_DATA',
  SET_ORDER_SETTINGS_DIALOG_OPEN: 'SET_ORDER_SETTINGS_DIALOG_OPEN',
  SET_ORDER_SETTINGS_DIALOG_LOADING: 'SET_ORDER_SETTINGS_DIALOG_LOADING',
  TOGGLE_ORDER_SETTINGS_ORDER_THROTTLING_ENABLED: 'TOGGLE_ORDER_SETTINGS_ORDER_THROTTLING_ENABLED',
  UPDATE_ORDER_SETTINGS_MAX_NUMBER_OF_ORDERS_PER_MINUTE_AMOUNT:
    'UPDATE_ORDER_SETTINGS_MAX_NUMBER_OF_ORDERS_PER_MINUTE_AMOUNT',
  UPDATE_ORDER_SETTINGS_MAX_NUMBER_OF_ORDERS_PER_MINUTE_DURATION_MINUTES:
    'UPDATE_ORDER_SETTINGS_MAX_NUMBER_OF_ORDERS_PER_MINUTE_DURATION_MINUTES',
  UPDATE_ORDER_SETTINGS_DISRUPTION_MESSAGE: 'UPDATE_ORDER_SETTINGS_DISRUPTION_MESSAGE',
  UPDATE_ORDER_SETTINGS_ALCOHOL_POLICY_MESSAGE: 'UPDATE_ORDER_SETTINGS_ALCOHOL_POLICY_MESSAGE',
  UPDATE_ORDER_SETTINGS_MAX_NUMBER_OF_DRINKS_PER_ORDER: 'UPDATE_ORDER_SETTINGS_MAX_NUMBER_OF_DRINKS_PER_ORDER',
  VALIDATE_ORDER_SETTINGS: 'VALIDATE_ORDER_SETTINGS',

  // Order statuses
  UPDATE_ORDER_STATUS: 'UPDATE_ORDER_STATUS',

  // Individual Order Item in list processing
  SET_PROCESSING_ORDER: 'SET_PROCESSING_ORDER',

  // Order sorting
  SORT_ORDERS_IN_PLACE: 'SORT_ORDERS_IN_PLACE',

  // Order details
  SET_PROCESSING_ORDER_DETAIL: 'SET_PROCESSING_ORDER_DETAIL',
  SET_LOADING_ORDER_DETAIL: 'SET_LOADING_ORDER_DETAIL',
  SET_ORDER_DETAIL: 'SET_ORDER_DETAIL',
  SET_ORDER_DETAIL_STATUS: 'SET_ORDER_DETAIL_STATUS',
  SET_ORDER_SMS_CONFIGS_DATA: 'SET_ORDER_SMS_CONFIGS_DATA',
  INIT_ORDER_DETAIL: 'INIT_ORDER_DETAIL',

  // Order details - cancel order
  SET_CANCEL_ORDER_DIALOG_OPEN: 'SET_CANCEL_ORDER_DIALOG_OPEN',
  INIT_CANCEL_ORDER_DIALOG: 'INIT_CANCEL_ORDER_DIALOG',
  SET_CANCEL_ORDER_DIALOG_MESSAGE: 'SET_CANCEL_ORDER_DIALOG_MESSAGE',
  RESET_CANCEL_ORDER_DIALOG_MESSAGE: 'RESET_CANCEL_ORDER_DIALOG_MESSAGE',
  SET_CANCEL_ORDER_DIALOG_MESSAGE_LOADING: 'SET_CANCEL_ORDER_DIALOG_MESSAGE_LOADING',
  SET_CANCEL_ORDER_PROCESSING: 'SET_CANCEL_ORDER_PROCESSING',

  // Order details - edit pickup time
  SET_EDIT_PICKUP_TIME_DIALOG_OPEN: 'SET_EDIT_PICKUP_TIME_DIALOG_OPEN',
  SET_EDIT_PICKUP_TIME_DIALOG_MESSAGE: 'SET_EDIT_PICKUP_TIME_DIALOG_MESSAGE',
  RESET_EDIT_PICKUP_TIME_DIALOG_MESSAGE: 'RESET_EDIT_PICKUP_TIME_DIALOG_MESSAGE',
  SET_EDIT_PICKUP_TIME_DIALOG_MESSAGE_LOADING: 'SET_EDIT_PICKUP_TIME_DIALOG_MESSAGE_LOADING',
  SET_EDIT_PICKUP_TIME_PROCESSING: 'SET_EDIT_PICKUP_TIME_PROCESSING',
  INIT_EDIT_PICKUP_TIME_DIALOG: 'INIT_EDIT_PICKUP_TIME_DIALOG',
  SET_EDIT_PICKUP_TIME_NEW_PICKUP_TIME: 'SET_EDIT_PICKUP_TIME_NEW_PICKUP_TIME',
  VALIDATE_NEW_PICKUP_DATE_TIME: 'VALIDATE_NEW_PICKUP_DATE_TIME',
  RESET_PICKUP_TIME_VALIDATIONS: 'RESET_PICKUP_TIME_VALIDATIONS',
};
