import React from 'react';

import { useClassNames } from '../../../utils/cssUtils';
import styles from './AddModifierGroupListItem.module.scss';

export type AddModifierGroupListItemProps = {
  unavailable: boolean;
  controls: JSX.Element;
  name: string;
  optionsLength: number;
  displayName?: string;
  constraintsMin?: number;
  constraintsMax?: number;
};

const AddModifierGroupListItem = (props: AddModifierGroupListItemProps): JSX.Element => {
  const { unavailable, controls, constraintsMin, constraintsMax, displayName, name, optionsLength } = props;

  const minMax = `Min: ${constraintsMin ? constraintsMin : 'None'} | Max: ${
    constraintsMax ? constraintsMax : 'Unlimited'
  }`;

  return (
    <div className={styles.container}>
      <div className={useClassNames([styles.content, { unavailable }], styles)}>
        <div className={styles.description}>
          <div className={styles.name}>{name}</div>
          <div className={styles.displayName}>{displayName}</div>
        </div>

        <div className={styles.attributes}>
          <div className={styles.options}>{optionsLength} options</div>
          <div className={styles.minMax}>{minMax}</div>
        </div>
      </div>

      <div className={styles.controls}>
        <div className={styles.button}>{controls}</div>
      </div>
    </div>
  );
};

export default AddModifierGroupListItem;
