import TextIcon, { Icon } from 'components/Icon/TextIcon';
import OverflowButton from 'components/OverflowButton/OverflowButton';
import React, { useState } from 'react';
import { IMenuSummaryUI } from '../../redux/types';
import styles from './MenuConfigPanelRow.module.scss';
import { DeleteConfirmationModal } from 'components/Modal';
import {
  ISaveNameAndDescriptionModalFields,
  NameAndDescriptionModal,
} from 'components/nameAndDescriptionModal/NameAndDescriptionModal';

interface Props {
  menuSummary: IMenuSummaryUI;
  onClick: (menuSummaryId: string, isGallery: boolean) => void;
  onDeleteMenuOrGallery: (menuOrGalleryId: string, isGallery: boolean) => void;
  onUpdateMenuOrItemGallerySummary: (
    id: string,
    menuFields: ISaveNameAndDescriptionModalFields,
    isGallery?: boolean
  ) => void;
}

export const MenuConfigPanelRow = ({
  menuSummary: { id, name, description, isGallery = false },
  onClick,
  onDeleteMenuOrGallery,
  onUpdateMenuOrItemGallerySummary,
}: Props) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [isDeletingMenu, setIsDeletingMenu] = useState<boolean>(false);

  return (
    <>
      <div className={styles.container} onClick={() => onClick(id, isGallery)}>
        {isGallery && <TextIcon icon={Icon.Star} additionalStyles={styles.galleryIcon} />}
        <span>{name}</span>
        <OverflowButton
          className={styles.overflowButton}
          options={[
            {
              label: 'Quick Edit',
              onClick: (e) => {
                setShowEditModal(true);
                e.stopPropagation();
              },
            },
            {
              primary: true,
              label: `Delete ${isGallery ? 'Gallery' : 'Menu'}`,
              onClick: (e) => {
                setShowDeleteModal(true);
                e.stopPropagation();
              },
            },
          ]}
        />
      </div>

      {showEditModal && (
        <NameAndDescriptionModal
          onSave={(modalFields) => onUpdateMenuOrItemGallerySummary(id, modalFields, isGallery)}
          handleCancel={() => setShowEditModal(false)}
          setShowModal={setShowEditModal}
          fieldsToEdit={{ displayName: name, description }}
          labelName={isGallery ? 'Item Gallery' : 'Menu'}
        />
      )}

      {showDeleteModal && (
        <DeleteConfirmationModal
          item={isGallery ? 'Item Gallery' : 'Menu'}
          itemName={name}
          handleDelete={async () => {
            setIsDeletingMenu(true);
            await onDeleteMenuOrGallery(id, isGallery);
            setIsDeletingMenu(false);
          }}
          setShowModal={setShowDeleteModal}
          handleCancel={() => setShowDeleteModal(false)}
          loading={isDeletingMenu}
        />
      )}
    </>
  );
};
