import React from 'react';
import TextBubble from '../../components/TextBubble/TextBubble';
import StandardLoadingSpinner from '../LoadingSpinner/StandardLoadingSpinner';
import styles from './TextSMSInput.module.scss';

interface TextSMSInputProps {
  value: string;
  onChange?: (value: string, event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  formatter?: (value: string) => string;
  editMode?: boolean;
  maxLength?: number;
  rows?: number;
  columns?: number;
  placeholder?: string;
  name?: string;
  disabled?: boolean;
  loading?: boolean;
}

const TextSMSInput = ({
  value,
  onChange = () => {},
  formatter,
  editMode = false,
  maxLength,
  rows,
  columns,
  placeholder,
  name,
  disabled,
  loading = false,
}: TextSMSInputProps): JSX.Element => {
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;

    onChange(value, event);
  };

  const characterLimitText = `${value.length}/${maxLength || 0}`;

  return (
    <div className={styles.container}>
      {editMode ? (
        <div className={styles.textAreaContainer}>
          <div className={styles.loadingControl}>
            <StandardLoadingSpinner
              additionalStyles={styles.loadingSpinner}
              loading={loading}
              color='gray'
              size='medium'
            />
            <textarea
              className={styles.textArea}
              value={value}
              onChange={handleChange}
              maxLength={maxLength}
              rows={rows}
              cols={columns}
              placeholder={placeholder}
              name={name}
              disabled={disabled || loading}
            />
          </div>
          {maxLength && maxLength > 0 ? <span className={styles.characterLimit}>{characterLimitText}</span> : null}
        </div>
      ) : (
        <TextBubble arrowLocation='bottom' arrowAlignment='right'>
          <StandardLoadingSpinner
            additionalStyles={styles.loadingSpinner}
            loading={loading}
            color='gray'
            size='medium'
          />
          <p className={styles.text}>{(formatter ? formatter(value) : value) || ''}</p>
        </TextBubble>
      )}
    </div>
  );
};

export default TextSMSInput;
