import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { AppState } from '../../../redux/initialStates/AppState';
import { IMenuItem } from '@ready/menu.core';
import MenuItemsView from '../../types/MenuItemsView.enum';

import styles from './AddMenuItemsList.module.scss';

import InfiniteScrollingList from '../../../components/InfiniteScrollingList/InfiniteScrollingList';
import AddMenuItemsListItem from './AddMenuItemsListItem';

const mapStateToProps = (state: AppState) => {
  const {
    loading,
    error,
    query,
    view,
    page,
    paginationLoading,
    paginationAvailable,
    filtered,
    items,
    selectedIds,
  } = state.menuBuilder.itemsAndMods.menuItemSelection;
  return {
    loading,
    error,
    query,
    view,
    page,
    paginationLoading,
    paginationAvailable,
    filtered,
    menuItems: items,
    selectedMenuItemIds: selectedIds,
  };
};

const connector = connect(mapStateToProps);

export type AddMenuItemsListProps = ConnectedProps<typeof connector> & {
  loadMenuItems: (query: string | undefined, view: MenuItemsView) => void;
  loadMoreMenuItems: (query: string | undefined, view: MenuItemsView, page: number) => void;
  addMenuItemToSelection: (menuItem: IMenuItem) => void;
  removeMenuItemFromSelection: (menuItem: string) => void;

  selectedItemIds?: string[];
};

const buildItemComponent = (menuItem: IMenuItem, controls: JSX.Element | null): JSX.Element => (
  <AddMenuItemsListItem menuItem={menuItem} controls={controls!} />
);

const AddMenuItemsList = (props: AddMenuItemsListProps): JSX.Element => {
  const {
    loading,
    error,
    query,
    view,
    page,
    paginationLoading,
    paginationAvailable,
    filtered,
    menuItems,
    selectedMenuItemIds,
    loadMenuItems,
    loadMoreMenuItems,
    addMenuItemToSelection,
    removeMenuItemFromSelection,
    selectedItemIds,
  } = props;

  const noItemsTitle = 'No Results Found';
  const noItemsMessage =
    'Make sure the items you are looking for have been created in your point of sale, or try refining your search.';

  const loadItems = React.useCallback((): void => {
    loadMenuItems(query, view);
  }, [loadMenuItems, query, view]);

  const loadMoreItems = React.useCallback((): void => {
    loadMoreMenuItems(query, view, page);
  }, [loadMoreMenuItems, query, view, page]);

  return (
    <InfiniteScrollingList
      loading={loading}
      error={error}
      paginationLoading={paginationLoading}
      paginationAvailable={paginationAvailable}
      filtered={filtered}
      items={menuItems}
      noItemsTitle={noItemsTitle}
      noItemsMessage={noItemsMessage}
      selectedItemKeys={selectedItemIds || selectedMenuItemIds}
      loadItems={loadItems}
      loadMoreItems={loadMoreItems}
      getItemKey={(menuItem: IMenuItem) => menuItem._id}
      buildItemComponent={buildItemComponent}
      addItem={addMenuItemToSelection}
      removeItem={removeMenuItemFromSelection}
      listAdditionalClassName={styles.list}
      isInModal
    />
  );
};

export default connector(AddMenuItemsList);
