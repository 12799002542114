import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import debounce from 'lodash/debounce';

import {
  prepareMenuItemsModal,
  loadMenuItemsForInfiniteScroll,
  loadMoreMenuItemsForInfiniteScroll,
} from '../../redux/ItemsAndModsActions';
import { IMenuItem } from '@ready/menu.core';

import ListModal from '../../../components/Modal/ListModal';

import AddMenuItemsSearch from './AddMenuItemsSearch';
import AddMenuItemsList from './AddMenuItemsList';
import MenuItemsView from '../../types/MenuItemsView.enum';

export type AddMenuItemsDialogProps = ConnectedProps<typeof connector> & {
  visible: boolean;
  companyId: string;
  locationId?: string;
  title: string;
  typeSelection?: boolean;
  addMenuItemToSelection: (menuItem: IMenuItem) => void;
  removeMenuItemFromSelection: (menuItem: string) => void;
  prepareModalDispatch?: (show: boolean) => void;
  /** IDs for items that you want to be selected in the dialog.
   * Normally, Redux state is used for this, but use this prop if
   * you want to pass them in instead.
   * todo: consider making a "dumb" version of this component.
   */
  selectedItemIds?: string[];
};

const AddMenuItemsDialog = (props: AddMenuItemsDialogProps): JSX.Element => {
  const {
    visible,
    companyId,
    locationId = '',
    title,
    typeSelection = false,
    prepareMenuItemsModal,
    loadMenuItemsForInfiniteScroll,
    loadMoreMenuItemsForInfiniteScroll,
    addMenuItemToSelection,
    removeMenuItemFromSelection,
    prepareModalDispatch,
    selectedItemIds,
  } = props;

  const debounceTime = 300;
  const loadMenuItemsDebounced = debounce(loadMenuItemsForInfiniteScroll, debounceTime);

  const handleLoadMenuItems = React.useCallback(
    (query: string | undefined, view: MenuItemsView): void => {
      loadMenuItemsDebounced(companyId, locationId, view, query);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyId, locationId]
  );

  const handleLoadMoreMenuItems = (query: string | undefined, view: MenuItemsView, page: number): void => {
    loadMoreMenuItemsForInfiniteScroll(companyId, locationId, view, query, page);
  };

  return (
    <>
      {visible && (
        <ListModal
          setShowModal={prepareModalDispatch ?? prepareMenuItemsModal}
          headerLabel={title}
          searchControl={<AddMenuItemsSearch typeSelection={typeSelection} />}
          showDismissButton
          showSeparator={typeSelection}
        >
          <AddMenuItemsList
            loadMenuItems={handleLoadMenuItems}
            loadMoreMenuItems={handleLoadMoreMenuItems}
            addMenuItemToSelection={addMenuItemToSelection}
            removeMenuItemFromSelection={removeMenuItemFromSelection}
            selectedItemIds={selectedItemIds}
          />
        </ListModal>
      )}
    </>
  );
};

const actionCreators = {
  prepareMenuItemsModal,
  loadMenuItemsForInfiniteScroll,
  loadMoreMenuItemsForInfiniteScroll,
};

const connector = connect(null, actionCreators);

export default connector(AddMenuItemsDialog);
