import React, { useRef } from 'react';
import TablePagination from '../Table/TablePagination';
import { IPagination } from '@ready/dashboardv2api.contracts';

interface PaginationContainerProps {
  pagination: IPagination;
}

/*
  The component helps to use pagination.
  Also, the component provide functionality that scroll page
  to top of the container for every change of the page.
 */
export const PaginationContainer: React.FC<PaginationContainerProps> = ({ pagination, children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const scrollTo = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLElement;
    if (ref && ref.current && target?.tagName === 'A') {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  };

  return (
    <div>
      <span ref={ref} />
      {children}
      <div className='table' onClick={scrollTo}>
        <div className='table__footer'>
          <TablePagination
            length={pagination.length}
            start={pagination.start}
            total={pagination.total}
            limit={pagination.limit}
          />
        </div>
      </div>
    </div>
  );
};
