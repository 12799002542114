import React, { ReactNode } from 'react';
import IconLink from '../IconLink/IconLink';
import Button, { ButtonProps } from '../Button/Button';
import styles from './ActionHeader.module.scss';
import OverflowButton from '../OverflowButton/OverflowButton';
import { sortBy } from 'lodash';
import { useClassNames } from '../../utils/cssUtils';
import { BackLink } from '../../types/BackLink.interface';
import getBackLinkUrl from '../../utils/urlUtils/getBackLinkUrl';

type ExtendedTextStyle = 'hint' | 'regular';
type ExtendedTextPosition = 'inline' | 'below';

interface ExtendedTextProps {
  text: string | undefined;
  textStyle: ExtendedTextStyle;
  textPosition: ExtendedTextPosition;
}

const ExtendedText = ({ text, textStyle, textPosition }: ExtendedTextProps) => {
  const className = useClassNames(
    [
      styles.extendedText,
      {
        hint: textStyle === 'hint',
        inline: textPosition === 'inline',
      },
    ],
    styles
  );

  return text ? <p className={className}>{text}</p> : null;
};

interface SubTextProps {
  text: string | undefined;
}

const SubText = ({ text }: SubTextProps) => (text ? <p className={styles.subText}>{text}</p> : null);

export interface Action extends ButtonProps {
  hidden?: boolean;
  overflowOrder?: number;
}

interface ActionProps {
  actions: Array<Action> | undefined;
}

const sortedActions = (actions: Array<Action>) => sortBy(actions, (action) => action.overflowOrder);

const doNothingCallback = () => {};

const Actions = ({ actions }: ActionProps) =>
  actions && actions.filter((action) => !action.hidden).length > 0 ? (
    <div className={styles.actions}>
      <div className={styles.actionMenu}>
        <OverflowButton
          options={sortedActions(actions).map((action) => ({
            label: action.label,
            onClick: action.onClick || doNothingCallback,
            hidden: action.hidden,
          }))}
        />
      </div>
      <div className={styles.actionButtons}>
        {actions
          .filter((action) => !action.hidden)
          .map((action) => (
            <div key={`back-link-button-container__${action.label}`} className={styles.actionButton}>
              <Button key={`back-link-button__${action.label}`} {...action} />
            </div>
          ))}
      </div>
    </div>
  ) : null;

export interface ActionHeaderProps {
  text?: string;
  backLinkTo?: BackLink | string;
  extendedText?: string;
  extendedTextStyle?: ExtendedTextStyle;
  extendedTextPosition?: ExtendedTextPosition;
  subText?: string;
  subComponent?: JSX.Element;
  disabled?: boolean;
  fullWidth?: boolean;
  actionButtons?: Array<Action>;
  additionalTextStyles?: string;
  textOverflowStyle?: 'none' | 'wrap-all' | 'wrap-word' | 'ellipsis';

  afterTextSlot?: ReactNode;
  beforeTextSlot?: ReactNode;
}

const ActionHeader = (props: ActionHeaderProps) => {
  const {
    text,
    backLinkTo,
    extendedText,
    subText,
    subComponent,
    extendedTextStyle = 'hint',
    extendedTextPosition = 'inline',
    disabled,
    fullWidth,
    actionButtons,
    additionalTextStyles,
    textOverflowStyle = 'none',
    afterTextSlot,
    beforeTextSlot,
  } = props;

  let backLinkUrl: string | undefined = undefined;
  if (backLinkTo) {
    if (typeof backLinkTo === 'string') {
      backLinkUrl = backLinkTo;
    } else {
      backLinkUrl = getBackLinkUrl(backLinkTo);
    }
  }

  const textClass = useClassNames(
    [
      {
        wrapAll: textOverflowStyle === 'wrap-all',
        wrapWord: textOverflowStyle === 'wrap-word',
        ellipsis: textOverflowStyle === 'ellipsis',
        disabled: disabled,
      },
      styles.text,
      additionalTextStyles,
    ],
    styles
  );

  const textSectionClass = useClassNames([styles.textSection, { inline: extendedTextPosition === 'inline' }], styles);

  const textWrapperClass = useClassNames([styles.textWrapper, { textWrapperWithBackLink: !!backLinkUrl }], styles);

  return (
    <div className={fullWidth ? styles.containerFullWidth : styles.container}>
      <div className={styles.backLinkSection}>
        {backLinkUrl && (
          <div className={styles.backLink}>
            <IconLink to={backLinkUrl}>
              <i className={`${styles.leftArrowIcon} icon-dashboard-icons_arrow-left`}></i>
            </IconLink>
          </div>
        )}
        <div className={textWrapperClass}>
          <div className={textSectionClass}>
            {beforeTextSlot}
            <h2 className={textClass}>{text}</h2>
            {afterTextSlot}
            <ExtendedText text={extendedText} textStyle={extendedTextStyle} textPosition={extendedTextPosition} />
          </div>
          {subComponent}
          <SubText text={subText} />
        </div>
      </div>
      <Actions actions={actionButtons} />
    </div>
  );
};

export default ActionHeader;
