import React from 'react';
import LayoutContent from '../../components/AppLayout/LayoutContent';
import EmptyResult from '../../components/EmptyResult/EmptyResult';

const InternalError = () => {
  const notFoundTitle = 'Whoops! Something went wrong.';
  const notFoundParagraph =
    'It’s not you, it’s us. We’re experiencing an internal server error. Please try loading the page again, or contact Ready Support if the problem persists.';

  return (
    <LayoutContent title='Internal Error' containerType='within'>
      <div className='page-with-empty-result'>
        <EmptyResult title={notFoundTitle} paragraph={notFoundParagraph} />
      </div>
    </LayoutContent>
  );
};

export default InternalError;
