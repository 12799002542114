import React from 'react';
import styles from './AssignTablesDialogHeader.module.scss';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../redux/initialStates/AppState';
import { syncThenFetchServers } from '../redux/ServerAssignmentActions';
import Button from '../../components/Button/Button';
import TextIcon, { Icon } from '../../components/Icon/TextIcon';
import { AssignTablesDialogMode } from './index';
import { useParams } from 'react-router-dom';
import { ContextParams } from '../../types/ContextParams.interface';

interface AssignTablesDialogHeaderProps extends ReduxProps {
  mode: AssignTablesDialogMode;
}

const AssignTablesDialogHeader = ({
  mode,
  loading,
  assignProcessing,
  assignModalQuery,
  syncThenFetchServers,
}: AssignTablesDialogHeaderProps): JSX.Element => {
  const { contextId, locationId } = useParams<ContextParams>();

  const handleSyncClick = () => {
    syncThenFetchServers(contextId, locationId, assignModalQuery);
  };

  return (
    <div className={styles.container}>
      <h3 className={styles.headerText}>{`${mode === 'select' ? 'Select a' : 'Edit'} Server`}</h3>

      {/* TODO: The existence (or enableness) of this button will need to be conditional
                based on whether or not the customer's POS supports this sync functionality. */}
      <Button
        label=''
        variant='secondary'
        onClick={handleSyncClick}
        additionalButtonStyles={styles.syncButton}
        disabled={loading || assignProcessing}
      >
        <TextIcon icon={Icon.Refresh} additionalStyles={styles.syncButtonIcon} />
        <p className={styles.syncButtonText}>Sync</p>
      </Button>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    loading: state.tableServerAssignment.serverAssignments.servers.loading,
    assignProcessing: state.tableServerAssignment.serverAssignments.assignModalProcessing,
    assignModalQuery: state.tableServerAssignment.serverAssignments.assignModalQuery,
  };
};

const actionCreators = {
  syncThenFetchServers,
};

const connector = connect(mapStateToProps, actionCreators);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AssignTablesDialogHeader);
