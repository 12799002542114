import { ILocationOrderSettings } from '@ready/dashboardv2api.contracts';
import produce from 'immer';
import { Action } from '../../../redux/types';
import { ORDERS_ACTIONS } from '../../actions/orders/types';
import { locationOrderSettingsInitialState } from '../../initialStates/orders/locationOrderSettingsInitialState';

const locationOrderSettingsReducer = (state = locationOrderSettingsInitialState, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ORDERS_ACTIONS.INIT_ORDER_SETTINGS_DIALOG_DATA:
        draft = { ...locationOrderSettingsInitialState };
        return draft;

      case ORDERS_ACTIONS.SET_ORDER_SETTINGS_DIALOG_DATA:
        draft.orderSettingsData = action.payload;
        return draft;

      case ORDERS_ACTIONS.SET_ORDER_SETTINGS_DIALOG_OPEN:
        draft.orderSettingsDialogOpen = action.payload;
        return draft;

      case ORDERS_ACTIONS.SET_ORDER_SETTINGS_DIALOG_LOADING:
        draft.loading = action.payload;
        return draft;

      case ORDERS_ACTIONS.UPDATE_ORDER_SETTINGS_MAX_NUMBER_OF_ORDERS_PER_MINUTE_AMOUNT:
        draft.orderSettingsData.orderThrottling!.amount = action.payload;
        return draft;

      case ORDERS_ACTIONS.UPDATE_ORDER_SETTINGS_MAX_NUMBER_OF_ORDERS_PER_MINUTE_DURATION_MINUTES:
        draft.orderSettingsData.orderThrottling!.durationMinutes = action.payload;
        return draft;

      case ORDERS_ACTIONS.UPDATE_ORDER_SETTINGS_DISRUPTION_MESSAGE:
        draft.orderSettingsData.disruptionMessage = action.payload;
        return draft;

      case ORDERS_ACTIONS.UPDATE_ORDER_SETTINGS_ALCOHOL_POLICY_MESSAGE:
        draft.orderSettingsData.alcoholPolicy = action.payload;
        return draft;

      case ORDERS_ACTIONS.UPDATE_ORDER_SETTINGS_MAX_NUMBER_OF_DRINKS_PER_ORDER:
        draft.orderSettingsData.alcoholPurchasingLimit = action.payload;
        return draft;

      case ORDERS_ACTIONS.TOGGLE_ORDER_SETTINGS_ORDER_THROTTLING_ENABLED:
        draft.orderSettingsData.orderThrottling!.enabled = action.payload;
        return draft;

      case ORDERS_ACTIONS.VALIDATE_ORDER_SETTINGS:
        const orderSettings: ILocationOrderSettings = action.payload.orderSettings;
        const orderThrottling = orderSettings.orderThrottling;

        draft.validation.orderThrottling = {
          amount: {
            error: '',
            hasError: false,
          },
        };

        if (orderThrottling && orderThrottling.enabled && !orderThrottling.amount) {
          draft.validation.orderThrottling = {
            amount: {
              error: 'Min value of 1  is required',
              hasError: true,
            },
          };
        }

        return draft;
    }
  });
};

export default locationOrderSettingsReducer;
