import { ILocationPicker } from '../companyLocations/companyLocations';

export interface IOrderLocationsState {
  locationPicker: ILocationPicker;
}

export const orderLocationsState: IOrderLocationsState = {
  locationPicker: {
    loading: false,
    error: false,
    errorMessage: '',
    pagination: {
      start: 0,
      length: 0,
      limit: 0,
      total: 0,
    },
    locations: [],
  },
} as IOrderLocationsState;
