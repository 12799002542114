import { ILocationOrderMessagingConfig } from '@ready/dashboardv2api.contracts';
import { createAsyncThunk } from '@reduxjs/toolkit';

import dashConfig from 'config';

import { interceptErrorWithToastMessage } from 'redux/helpers/interceptErrorWithToastMessage';
import executeApiAsync from 'services/utils/executeApiAsync';

const getClientOrderMessagingConfig = ({
  companyId,
  locationId,
}: {
  companyId: string;
  locationId: string;
}): Promise<ILocationOrderMessagingConfig> => {
  const url = `${dashConfig.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/orderMessaging`;
  return executeApiAsync<ILocationOrderMessagingConfig>(url);
};

const updateClientOrderMessagingConfig = ({
  companyId,
  locationId,
  config,
}: {
  companyId: string;
  locationId: string;
  config: ILocationOrderMessagingConfig;
}): Promise<ILocationOrderMessagingConfig> => {
  const url = `${dashConfig.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/orderMessaging`;
  return executeApiAsync<ILocationOrderMessagingConfig>(url, { method: 'PUT', body: JSON.stringify(config) });
};

export const getClientOrderMessagingConfigThunk = createAsyncThunk(
  'orderMessagingConfig/get',
  interceptErrorWithToastMessage(getClientOrderMessagingConfig)
);

export const updateClientOrderMessagingConfigThunk = createAsyncThunk(
  'orderMessagingConfig/update',
  interceptErrorWithToastMessage(updateClientOrderMessagingConfig, 'Success! Custom Messaging is updated.')
);
