import React, { ChangeEvent } from 'react';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';
import Checkbox from '../../../components/Checkbox/Checkbox';
import styles from './MenuItemIsDefaultBlock.module.scss';

export interface MenuItemDefaultBlockProps {
  isDefault: boolean;
  itemId: string;
  editing?: boolean;
  hasError?: boolean;
  className?: string;
  onDefaultChanged?: (itemId: string, checked: boolean) => void;
}

const MenuItemIsDefaultBlock = ({
  isDefault,
  hasError = false,
  itemId,
  editing = false,
  className,
  onDefaultChanged,
}: MenuItemDefaultBlockProps): JSX.Element => {
  return (
    <span className={className}>
      {editing ? (
        <Checkbox
          label='Default'
          checked={isDefault}
          onChange={({ target: { checked } }: ChangeEvent<HTMLInputElement>) =>
            onDefaultChanged && onDefaultChanged(itemId, checked)
          }
          withError={hasError}
        />
      ) : isDefault ? (
        <span className={styles.isDefault}>
          <TextIcon icon={Icon.Checkmark} additionalStyles={styles.icon} />
          <span>Default</span>
        </span>
      ) : undefined}
    </span>
  );
};

export default MenuItemIsDefaultBlock;
