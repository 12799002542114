import React from 'react';
import styles from './Widgets.module.scss';
import { Card, CardGeneralText, CardTitle } from '../../../../widgets/Card/Card';
import { DistributionDescription, DistributionLine } from '../../../../widgets/Distribution/Distribution';
import DollarValue from '../../../../../components/Value/DollarValue';
import LoadingSpinner from '../../../../../components/LoadingSpinner/LoadingSpinner';
import { IChecksReportWidgetsData } from '@ready/dashboardv2api.contracts';

interface IDescription {
  text?: string;
  percents?: number;
  value?: number | null;
  disabled?: boolean;
}

type AverageCheckSizeDescriptionData = Pick<
  IChecksReportWidgetsData,
  'amountAllAvg' | 'amountReadyAvg' | 'amountNonReadyAvg'
>;

const AverageCheckSizeDescriptionItem = ({ text, value, disabled }: IDescription) => (
  <>
    {disabled || !text ? 'n/a' : <DollarValue additionalStyles={styles.MoneyDescription} value={value || 0} />} {text}
  </>
);

const AverageCheckSizeDescription = ({
  data,
  disabled,
}: {
  data: AverageCheckSizeDescriptionData;
  disabled: boolean;
}) => {
  const distribution = [
    {
      backgroundColorClass: styles.BackgroundMagenta,
      description: <AverageCheckSizeDescriptionItem disabled={disabled} value={data.amountReadyAvg} text='Ready' />,
    },
    {
      backgroundColorClass: styles.BackgroundGrey,
      description: (
        <AverageCheckSizeDescriptionItem disabled={disabled} value={data.amountNonReadyAvg} text='Non-Ready' />
      ),
    },
  ];

  return (
    <Card classes={styles.Widget}>
      <CardTitle>average check size</CardTitle>
      <CardGeneralText>{disabled ? 'n/a' : <DollarValue value={data.amountAllAvg || 0} />}</CardGeneralText>
      <div className={styles.AverageCheckLine} />
      <DistributionDescription items={distribution} />
    </Card>
  );
};

const TotalTipsValueDescriptionItem = ({ text, value, percents, disabled }: IDescription) => (
  <>
    <span>{disabled ? 'n/a' : <DollarValue additionalStyles={styles.MoneyDescription} value={value || 0} />} </span>
    {text} (avg {percents}%)
  </>
);

type TotalTipsValueDescriptionData = Pick<
  IChecksReportWidgetsData,
  'tipAllSum' | 'tipReadySum' | 'tipReadyAvgPct' | 'tipNonReadySum' | 'tipNonReadyAvgPct'
>;

const TotalTipsValueDescription = ({ data, disabled }: { data: TotalTipsValueDescriptionData; disabled: boolean }) => {
  const sumTips = data.tipReadySum + data.tipNonReadySum;
  const readyTipsPct = (data.tipReadySum / sumTips) * 100;
  const nonReadyTipsPct = (data.tipNonReadySum / sumTips) * 100;

  const distribution = [
    {
      percents: readyTipsPct,
      backgroundColorClass: styles.BackgroundMagenta,
      description: (
        <TotalTipsValueDescriptionItem
          disabled={disabled}
          value={data.tipReadySum}
          percents={data.tipReadyAvgPct || 0}
          text='Ready'
        />
      ),
    },
    {
      percents: nonReadyTipsPct,
      backgroundColorClass: styles.BackgroundGrey,
      description: (
        <TotalTipsValueDescriptionItem
          disabled={disabled}
          value={data.tipNonReadySum}
          percents={data.tipNonReadyAvgPct || 0}
          text='Non-Ready'
        />
      ),
    },
  ];

  return (
    <Card classes={styles.Widget}>
      <CardTitle>total tips</CardTitle>
      <CardGeneralText>{disabled ? 'n/a' : <DollarValue value={data.tipAllSum} />}</CardGeneralText>
      <DistributionLine items={distribution} />
      <DistributionDescription items={distribution} />
    </Card>
  );
};

const TotalCheckValueDescriptionItem = ({ text, value, percents, disabled }: IDescription) => (
  <>
    <span>{disabled ? 'n/a' : <DollarValue additionalStyles={styles.MoneyDescription} value={value || 0} />} </span>
    {text} ({percents}%)
  </>
);

type TotalCheckValueDescriptionData = Pick<
  IChecksReportWidgetsData,
  'amountAllSum' | 'amountReadySum' | 'amountReadyPct' | 'amountNonReadySum' | 'amountNonReadyPct'
>;

const TotalCheckValueDescription = ({
  data,
  disabled,
}: {
  data: TotalCheckValueDescriptionData;
  disabled: boolean;
}) => {
  const distribution = [
    {
      percents: data.amountReadyPct || 0,
      backgroundColorClass: styles.BackgroundMagenta,
      description: (
        <TotalCheckValueDescriptionItem
          disabled={disabled}
          value={data.amountReadySum}
          percents={data.amountReadyPct || 0}
          text='Ready'
        />
      ),
    },
    {
      percents: data.amountNonReadyPct || 0,
      backgroundColorClass: styles.BackgroundGrey,
      description: (
        <TotalCheckValueDescriptionItem
          disabled={disabled}
          value={data.amountNonReadySum}
          percents={data.amountNonReadyPct || 0}
          text='Non-Ready'
        />
      ),
    },
  ];

  return (
    <Card classes={styles.Widget}>
      <CardTitle>total check value</CardTitle>
      <CardGeneralText>{disabled ? 'n/a' : <DollarValue value={data.amountAllSum} />}</CardGeneralText>
      <DistributionLine items={distribution} />
      <DistributionDescription disabled={disabled} items={distribution} />
    </Card>
  );
};

const TotalCheckDescriptionItem = ({ text, value, percents, disabled }: IDescription) => (
  <>
    {disabled ? 'n/a' : <span className={styles.MoneyDescription}>{value}</span>} {text} ({percents}%)
  </>
);

type TotalCheckDistributionData = Pick<
  IChecksReportWidgetsData,
  'countAll' | 'countReady' | 'countReadyPct' | 'countNonReady' | 'countNonReadyPct'
>;

const TotalCheckDistribution = ({ data, disabled }: { data: TotalCheckDistributionData; disabled: boolean }) => {
  const distribution = [
    {
      percents: data.countReadyPct || 0,
      backgroundColorClass: styles.BackgroundMagenta,
      description: (
        <TotalCheckDescriptionItem
          disabled={disabled}
          value={data.countReady}
          percents={data.countReadyPct || 0}
          text='Ready'
        />
      ),
    },
    {
      percents: data.countNonReadyPct || 0,
      backgroundColorClass: styles.BackgroundGrey,
      description: (
        <TotalCheckDescriptionItem
          disabled={disabled}
          value={data.countNonReady}
          percents={data.countNonReadyPct || 0}
          text='Non-Ready'
        />
      ),
    },
  ];

  return (
    <Card classes={styles.Widget}>
      <CardTitle>total check</CardTitle>
      <CardGeneralText>{disabled ? 'n/a' : data.countAll}</CardGeneralText>
      <DistributionLine items={distribution} />
      <DistributionDescription items={distribution} />
    </Card>
  );
};

export const Widgets = ({
  widgets,
  disabled,
  loading,
}: {
  widgets: IChecksReportWidgetsData;
  disabled: boolean;
  loading?: boolean;
}) => {
  return (
    <div className={styles.WidgetsContainer}>
      {loading ? <LoadingSpinner additionalStyles={styles.WidgetsLoader} /> : null}
      <TotalCheckDistribution data={widgets} disabled={disabled} />
      <TotalCheckValueDescription data={widgets} disabled={disabled} />
      <TotalTipsValueDescription data={widgets} disabled={disabled} />
      <AverageCheckSizeDescription data={widgets} disabled={disabled} />
    </div>
  );
};
