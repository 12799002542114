import produce from 'immer';
import { Action } from '../../../redux/types';
import { SIDEBAR_STATE_TYPES } from '../../actions/uiActions/sidebarStateActionTypes';
import { sidebarState } from '../../initialStates/ui/sidebarState';

export const sidebarStateReducer = (state = sidebarState, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SIDEBAR_STATE_TYPES.UPDATE_MOBILE_SIDEBAR_VISIBLE:
        draft.mobileSidebarVisible = action.payload;
        return draft;

      default:
        return draft;
    }
  });
};
