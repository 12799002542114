import produce from 'immer';
import { Action } from '../../../redux/types';
import { TABLE_CONFIGURATION_ACTIONS } from '../../redux/actions/types';
import { tableConfigurationList } from '../initialStates/tablesDetails';

export const tablesListReducer = (state = tableConfigurationList, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case TABLE_CONFIGURATION_ACTIONS.DELETE_TABLE_CONFIGURATION_BEGIN:
      case TABLE_CONFIGURATION_ACTIONS.UPDATE_TABLE_CONFIGURATION_BEGIN:
      case TABLE_CONFIGURATION_ACTIONS.GET_TABLE_CONFIGURATION_TARGET_LIST_BEGIN:
        draft.tableConfigurations.loading = true;
        draft.tableConfigurations.disabled = true;
        draft.error = false;
        return draft;

      case TABLE_CONFIGURATION_ACTIONS.GET_TABLE_CONFIGURATION_TARGET_LIST_SUCCESS:
        draft.tableConfigurations.loading = false;
        draft.tableConfigurations.results = action.payload.items;
        draft.tableConfigurations.pagination.length = action.payload.length;
        draft.tableConfigurations.pagination.limit = action.payload.limit;
        draft.tableConfigurations.pagination.start = action.payload.start;
        draft.tableConfigurations.pagination.total = action.payload.total;
        draft.tableConfigurations.disabled = false;
        draft.tableConfigurations.paginationAvailable = draft.tableConfigurations.results.length < action.payload.total;
        return draft;

      case TABLE_CONFIGURATION_ACTIONS.UPDATE_TABLE_CONFIGURATION_SUCCESS:
        draft.tableConfigurations.loading = false;
        let tempUpdateIndex = draft.tableConfigurations.results.findIndex(
          (table) => table.id === action.payload.tableConfiguration.id
        );
        let tempUpdateTableConfig = [...draft.tableConfigurations.results];
        tempUpdateTableConfig.splice(tempUpdateIndex, 1, action.payload.tableConfiguration);
        draft.tableConfigurations.results = tempUpdateTableConfig;
        draft.tableConfigurations.disabled = false;
        return draft;

      case TABLE_CONFIGURATION_ACTIONS.CREATE_TABLE_CONFIGURATION_SUCCESS:
        draft.tableConfigurations.loading = false;
        let tempCreateTableConfig = [...draft.tableConfigurations.results];
        tempCreateTableConfig.push(action.payload.tableConfiguration);
        draft.tableConfigurations.results = tempCreateTableConfig;
        if (draft.tableConfigurations.pagination.length === draft.tableConfigurations.pagination.limit) {
          draft.tableConfigurations.results.pop();
        } else {
          draft.tableConfigurations.pagination.length = draft.tableConfigurations.pagination.length + 1;
        }
        draft.tableConfigurations.pagination.total = draft.tableConfigurations.pagination.total + 1;
        draft.tableConfigurations.paginationAvailable =
          draft.tableConfigurations.results.length < draft.tableConfigurations.pagination.total;
        draft.tableConfigurations.disabled = false;
        return draft;

      case TABLE_CONFIGURATION_ACTIONS.DELETE_TABLE_CONFIGURATION_SUCCESS:
        draft.tableConfigurations.loading = false;
        let tempDeleteIndex = draft.tableConfigurations.results.findIndex((r) => r.id === action.payload.tableId);
        let tempDeleteTableConfig = [...draft.tableConfigurations.results];
        tempDeleteTableConfig.splice(tempDeleteIndex, 1);
        draft.tableConfigurations.results = tempDeleteTableConfig;
        draft.tableConfigurations.disabled = false;
        draft.tableConfigurations.pagination.length = draft.tableConfigurations.pagination.length - 1;
        draft.tableConfigurations.pagination.total = draft.tableConfigurations.pagination.total - 1;
        draft.tableConfigurations.paginationAvailable =
          draft.tableConfigurations.results.length < draft.tableConfigurations.pagination.total &&
          draft.tableConfigurations.pagination.total < draft.tableConfigurations.pagination.limit;
        return draft;

      case TABLE_CONFIGURATION_ACTIONS.DELETE_TABLE_CONFIGURATION_ERROR:
      case TABLE_CONFIGURATION_ACTIONS.UPDATE_TABLE_CONFIGURATION_ERROR:
      case TABLE_CONFIGURATION_ACTIONS.GET_TABLE_CONFIGURATION_TARGET_LIST_ERROR:
        draft.tableConfigurations.loading = false;
        draft.error = true;
        draft.tableConfigurations.disabled = false;
        return draft;

      case TABLE_CONFIGURATION_ACTIONS.UPDATE_TABLE_CONFIGURATION_TARGET_LIST_PARAMETERS:
        draft.query = action.payload.query;
        draft.page = action.payload.page;
        return draft;

      case TABLE_CONFIGURATION_ACTIONS.UPDATE_TABLE_CONFIGURATION_TARGET_CODE:
        draft.unsavedMappedTargetCodes[action.payload.tableId] = action.payload.code;
        draft.changed = true;
        return draft;

      case TABLE_CONFIGURATION_ACTIONS.UPDATE_TABLE_CONFIGURATION_POS_ORDER_TYPE:
        draft.unsavedMappedPosOrderTypes[action.payload.tableId] = action.payload.posOrderTypeValue;
        draft.changed = true;
        return draft;

      case TABLE_CONFIGURATION_ACTIONS.UPDATE_TABLE_CONFIGURATION_ORDER_EXPERIENCE_TYPE:
        draft.unsavedMappedOrderExperienceTypes[action.payload.tableId] = action.payload.orderExperienceTypeValue;
        draft.changed = true;
        return draft;

      case TABLE_CONFIGURATION_ACTIONS.RESET_UNSAVED_CHANGES:
        draft.unsavedMappedTargetCodes = {};
        draft.unsavedMappedPosOrderTypes = {};
        draft.unsavedMappedOrderExperienceTypes = {};
        draft.changed = false;
        return draft;

      case TABLE_CONFIGURATION_ACTIONS.SAVE_TABLE_CONFIGURATION_BEGIN:
        draft.tableConfigurations.pagination.length = 0;
        draft.tableConfigurations.disabled = true;
        return draft;

      case TABLE_CONFIGURATION_ACTIONS.SAVE_TABLE_CONFIGURATION_SUCCESS:
        draft.unsavedMappedTargetCodes = {};
        draft.unsavedMappedPosOrderTypes = {};
        draft.unsavedMappedOrderExperienceTypes = {};
        draft.changed = false;
        draft.tableConfigurations.loading = false;
        draft.tableConfigurations.pagination.length = draft.tableConfigurations.results.length;
        draft.tableConfigurations.disabled = false;
        return draft;

      case TABLE_CONFIGURATION_ACTIONS.SAVE_TABLE_CONFIGURATION_ERROR:
        draft.tableConfigurations.loading = false;
        draft.error = true;
        draft.changed = true;
        draft.tableConfigurations.disabled = false;
        return draft;

      case TABLE_CONFIGURATION_ACTIONS.SYNC_TABLE_CONFIGURATIONS_BEGIN:
        draft.tableConfigurations.loading = true;
        draft.tableConfigurations.syncLoading = true;
        draft.tableConfigurations.syncComplete = false;
        draft.changed = false;
        draft.error = false;
        return draft;

      case TABLE_CONFIGURATION_ACTIONS.SYNC_TABLE_CONFIGURATIONS_SUCCESS:
        draft.unsavedMappedTargetCodes = {};
        draft.unsavedMappedPosOrderTypes = {};
        draft.unsavedMappedOrderExperienceTypes = {};
        draft.tableConfigurations.loading = false;
        draft.error = false;
        draft.tableConfigurations.syncLoading = false;
        draft.tableConfigurations.syncComplete = true;
        draft.changed = false;
        return draft;

      case TABLE_CONFIGURATION_ACTIONS.SYNC_TABLE_CONFIGURATIONS_ERROR:
        draft.tableConfigurations.loading = false;
        draft.tableConfigurations.syncLoading = false;
        draft.tableConfigurations.syncComplete = false;
        draft.error = true;
        return draft;

      case TABLE_CONFIGURATION_ACTIONS.LOAD_SELECTED_LOCATION:
        draft.location = action.payload;
        return draft;

      case TABLE_CONFIGURATION_ACTIONS.VALIDATION_ERROR:
        draft.error = true;
        return draft;

      case TABLE_CONFIGURATION_ACTIONS.CLEAR_VALIDATION_ERROR:
        draft.error = false;
        return draft;

      default:
        return draft;
    }
  });
};
