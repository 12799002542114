import { combineReducers } from 'redux';
import { selectModifierGroupModalSlice } from './components/SelectModifierGroupModal/redux/slice';
import { modifierGroupDetailsSlice } from './details/redux/slice';
import { modifierGroupListSlice } from './list/redux/slice';

export const sharedModiferGroupReducer = combineReducers({
  list: modifierGroupListSlice.reducer,
  details: modifierGroupDetailsSlice.reducer,

  selectModal: selectModifierGroupModalSlice.reducer,
});
