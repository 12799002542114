import { IInnerItemGallery, IMenu, IMenuSection, ITemplateMenu } from '@ready/menu.core';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleCoordinatedExecutionResult, MENUS_ACTIONS } from 'menus/redux/MenusActions';
import MenuBuilderService from 'menus/services/MenuBuilderService';
import { SectionItemChange } from 'menus/types/SectionItemChange.interface';
import { CoordinatedExecutionResult } from 'utils/asyncUtils/coordinateExecutions';
import { mapITemplateMenuToIMenu } from '../menu.mapper';
import { getTemplateMenu, deleteTemplateMenu, deleteTemplateItemGallery } from '../menu.service';
import { set200Toast, toastErrorState } from 'redux/actions/uiActions/responseStateActions';
export interface GetSharedMenuOrGalleryArgs {
  companyId: string;
  menuOrItemGalleryId: string;
}
export const getSharedMenuThunk = createAsyncThunk<void, GetSharedMenuOrGalleryArgs>(
  'sharedMenus/menu/fetch',
  async ({ companyId, menuOrItemGalleryId }: GetSharedMenuOrGalleryArgs, thunkAPI) => {
    thunkAPI.dispatch({ type: MENUS_ACTIONS.LOAD_MENU_BUILDER_MENU_BEGIN });
    try {
      const result = await getTemplateMenu(companyId, menuOrItemGalleryId);
      const mappedMenu = mapITemplateMenuToIMenu(result);

      thunkAPI.dispatch({
        type: MENUS_ACTIONS.LOAD_MENU_BUILDER_MENU_SUCCESS,
        payload: mappedMenu,
      });
    } catch (error) {
      thunkAPI.dispatch({ type: MENUS_ACTIONS.LOAD_MENU_BUILDER_MENU_ERROR });
      thunkAPI.dispatch(toastErrorState(error.status, error.message));
    }
  }
);

interface DeleteSharedMenuThunkArgs {
  companyId: string;
  id: string;
}

export const deleteSharedMenuThunk = createAsyncThunk<void, DeleteSharedMenuThunkArgs>(
  'sharedMenus/menu/delete',
  async (args: DeleteSharedMenuThunkArgs, thunkAPI) => {
    try {
      await deleteTemplateMenu(args.companyId, args.id);
      thunkAPI.dispatch(set200Toast('Menu deleted.'));
    } catch (e) {
      thunkAPI.dispatch(toastErrorState(e.status, e.message));
    }
  }
);

interface DeleteSharedItemGalleryThunk {
  companyId: string;
  id: string;
}

export const deleteSharedItemGalleryThunk = createAsyncThunk<void, DeleteSharedItemGalleryThunk>(
  'sharedMenus/itemGallery/delete',
  async (args: DeleteSharedItemGalleryThunk, thunkAPI) => {
    try {
      await deleteTemplateItemGallery(args.companyId, args.id);
      thunkAPI.dispatch(set200Toast('Item gallery deleted.'));
    } catch (e) {
      thunkAPI.dispatch(toastErrorState(e.status, e.message));
    }
  }
);

interface SaveSharedMenuSectionArgs {
  companyId: string;
  menu: IMenu;
  section: IMenuSection;
  itemGallery: IInnerItemGallery;
  sectionItemChanges: SectionItemChange[];
  itemGalleryChanges?: SectionItemChange[];
  sectionId?: string;
}

export const saveSharedMenuSectionThunk = createAsyncThunk<void, SaveSharedMenuSectionArgs>(
  'sharedMenus/menu/saveSharedMenuSection',
  async (args: SaveSharedMenuSectionArgs, thunkAPI) => {
    const validationResult = MenuBuilderService.validateMenuSection(
      args.section,
      args.sectionItemChanges,
      args.itemGalleryChanges ?? []
    );

    if (validationResult.errorsFound) {
      thunkAPI.dispatch({
        type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_SECTION_VALIDATED,
        payload: validationResult,
      });
      return;
    }

    thunkAPI.dispatch({ type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_SECTION_BEGIN });

    const result = (await MenuBuilderService.updateMenuSection(
      args.companyId,
      '',
      args.menu,
      args.section,
      args.sectionItemChanges,
      args.itemGallery,
      args.itemGalleryChanges ?? []
    )) as CoordinatedExecutionResult<ITemplateMenu>;
    const mappedMenu = mapITemplateMenuToIMenu(result.returnValue);
    const successMessage = `Success! Section ${args.sectionId ? 'updated' : 'created'}.`;

    handleCoordinatedExecutionResult(
      thunkAPI.dispatch,
      {
        ...result,
        returnValue: mappedMenu,
      },
      MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_SECTION_SUCCESS,
      MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_SECTION_ERROR,
      successMessage
    );
  }
);

interface SaveSharedMenuSectionItemGalleryArgs {
  companyId: string;
  menu: IMenu;
  section: IMenuSection;
  itemGallery: IInnerItemGallery;
  itemGalleryChanges: SectionItemChange[];
}

export const saveSharedMenuSectionItemGalleryThunk = createAsyncThunk<void, SaveSharedMenuSectionItemGalleryArgs>(
  'sharedMenus/menu/saveSharedMenuSectionItemGallery',
  async (args: SaveSharedMenuSectionItemGalleryArgs, thunkAPI) => {
    const validationResult = MenuBuilderService.validateSectionItemChanges(args.itemGalleryChanges);
    if (validationResult.errorsFound) {
      thunkAPI.dispatch({
        type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_ITEM_GALLERY_VALIDATED,
        payload: validationResult.items,
      });
      return;
    }

    thunkAPI.dispatch({ type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_ITEM_GALLERY_BEGIN });
    const result = (await MenuBuilderService.updateMenuInnerItemGallery(
      args.companyId,
      '',
      args.menu,
      args.itemGallery,
      args.itemGalleryChanges
    )) as CoordinatedExecutionResult<ITemplateMenu>;

    const mappedMenu = mapITemplateMenuToIMenu(result.returnValue);
    const successMessage = 'Success! Changes saved.';
    handleCoordinatedExecutionResult(
      thunkAPI.dispatch,
      {
        ...result,
        returnValue: mappedMenu,
      },
      MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_ITEM_GALLERY_SUCCESS,
      MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_ITEM_GALLERY_ERROR,
      successMessage
    );
  }
);
