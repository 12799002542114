import { ILocationBranding } from '@ready/dashboardv2api.contracts';
export interface IBrandingPanel extends ILocationBranding {
  isDirty: boolean;
  validation: {
    primaryColour: {
      hasError: boolean;
      error: string;
    };
    secondaryColour: {
      hasError: boolean;
      error: string;
    };
  };
}

export const getBrandingValidation = () => {
  return {
    primaryColour: {
      hasError: false,
      error: '',
    },
    secondaryColour: {
      hasError: false,
      error: '',
    },
  };
};

export const brandingPanel = {
  isDirty: false,
  primaryColour: '',
  secondaryColour: '',
  headerFont: '',
  bodyFont: '',
  validation: getBrandingValidation(),
  imageKey: '',
} as IBrandingPanel;
