import React from 'react';
import styles from './LocationSelectorLink.module.scss';
import { Link } from 'react-router-dom';
import TextIcon, { Icon } from '../Icon/TextIcon';
import useResponsiveBreakpoint from '../../hooks/useResponsiveBreakpoint';
import IconLink from '../IconLink/IconLink';
// TODO: implement later, see comment below
// import useLastLocationId from '../../hooks/useLastLocationId';

export interface LocationSelectorLinkProps {
  to: string;
  text: string;
}

const LocationSelectorLink = ({ to, text }: LocationSelectorLinkProps): JSX.Element => {
  // TODO: This will be implemented later when there is an opportunity; it
  // is currently only partially working, and the scope is too large to
  // complete for this sprint.

  // const { clear, update } = useLastLocationId();
  // const { locationId } = useParams();
  // Updates the location ID wen navigating to this page or when the ID changes
  // useEffect(() => {
  //   if (locationId) {
  //     update(locationId);
  //   }
  // }, [locationId, update]);

  const { isMobile } = useResponsiveBreakpoint();

  return isMobile ? (
    <div className={styles.container}>
      <div className={styles.locationText}>{text}</div>
      <IconLink
        to={to}
        additionalClassName={styles.iconLink}
        // onClick={clear} // TODO: implement later, see comment above
      >
        <TextIcon icon={Icon.Location} additionalStyles={styles.icon} />
      </IconLink>
    </div>
  ) : (
    <div className={styles.container}>
      <Link
        to={to}
        className={styles.link}
        // onClick={clear} // TODO: implement later, see comment above
      >
        <div className={styles.iconContainer}>
          <TextIcon icon={Icon.Location} additionalStyles={styles.icon} />
        </div>
        <div className={styles.locationText}>{text}</div>
        <div className={styles.changeText}> (change)</div>
      </Link>
    </div>
  );
};

export default LocationSelectorLink;
