import { createAsyncThunk } from '@reduxjs/toolkit';
import { IGetPayoutHistoryDetailsReportArgs, getPayoutHistoryDetailsReport, getPermittedLocations } from '../service';
import { interceptErrorWithToastMessage } from '../../../redux/helpers/interceptErrorWithToastMessage';
import { IPayoutHistoryDetailItem, PaginationResponse } from '@ready/dashboardv2api.contracts';

export const fetchPayoutHistoryDetailsReportThunk = createAsyncThunk(
  'payoutHistoryDetailsReport/fetch',
  interceptErrorWithToastMessage<IGetPayoutHistoryDetailsReportArgs, PaginationResponse<IPayoutHistoryDetailItem>>(
    getPayoutHistoryDetailsReport
  )
);

export const fetchPermittedLocationsThunk = createAsyncThunk(
  'payoutHistoryDetailsReport/permittedLocations',
  interceptErrorWithToastMessage(getPermittedLocations)
);
