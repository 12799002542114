import React from 'react';
// components
import styles from './AdyenPP.module.scss';
import { FormControl, FormControlStacked } from '../../../components/Form';
import Checkbox from '../../../components/Checkbox/Checkbox';
import FormattedInput from '../../../components/FormattedInput/FormattedInput';
import { IPaymentProcessingPanel } from '../../redux/PanelPaymentProcessingState';
import { LOCATION_SETTINGS_ACTIONS } from '../../redux/LocationSettingsActions';
// utils
import { toNumber } from '../../../utils/numberUtils/toNumber';
import { IUpdateLocationParam } from '../LocationsEditPage';
import { TextValue } from '../../../components/Value';
import { useClassNames } from '../../../utils/cssUtils';
import Toggle from 'components/Toggle/Toggle';
import TextInput from 'components/TextInput/TextInput';
import SelectFilter, { Option } from 'components/SelectFilter/SelectFilter';
import { LIST_OPTIONS } from 'utils/selectListUtils/listOptions';

interface Props {
  isProcessing: boolean;
  updateLocationParam: IUpdateLocationParam;
  paymentProcessingPanel: IPaymentProcessingPanel;
}

const mapSelectOptions = (allOptions: Option[], optionValue: any): Option => {
  return allOptions.find((optionObj: Option) => optionObj.value === optionValue) as Option;
};

const {adyenPriceModel} = LIST_OPTIONS;

export const AdyenPP = (props: Props) => {
  const { isProcessing, paymentProcessingPanel, updateLocationParam } = props;

  const handlePriceModelChange = (data: Option) => {
    updateLocationParam(data.value, LOCATION_SETTINGS_ACTIONS.UPDATE_ADYEN_PP_PRICING_MODEL);
  };

  return (
    <>
      <FormControl label='Balance Account'>
        <TextValue value={paymentProcessingPanel.adyen.balanceAccount} mode='missing' />
      </FormControl>

      <FormControl label='Payment Methods'>
        <div className={styles.paymentMethodCheckboxContainer}>
          <div className={useClassNames([styles.paymentMethodCheckbox, 'control--stacked'], styles)}>
            <Checkbox
              label='Credit Card'
              checked={paymentProcessingPanel.adyen.paymentMethods.creditCard}
              loading={isProcessing}
              readonly
              onChange={() => {}}
            />
          </div>
          <div className={useClassNames([styles.paymentMethodCheckbox, 'control--stacked'], styles)}>
            <Checkbox
              label='Apple Pay'
              checked={paymentProcessingPanel.adyen.paymentMethods.applePay}
              loading={isProcessing}
              readonly
              onChange={() => {}}
            />
          </div>
          <div className='control--stacked'>
            <Checkbox
              label='Google Pay'
              checked={paymentProcessingPanel.adyen.paymentMethods.googlePay}
              loading={isProcessing}
              readonly
              onChange={() => {}}
            />
          </div>
        </div>
      </FormControl>

      <FormControl label='Rate Limiting'>
        <FormControlStacked>
          <Toggle
            checked={paymentProcessingPanel.adyen.rateLimits.enabled}
            onChange={(checked) => {
              updateLocationParam(
                checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_ADYEN_PP_RATE_LIMITING_TOGGLE
              );
            }}
            loading={isProcessing}
          />
        </FormControlStacked>
        <FormControlStacked>
          <Checkbox
            disabled={true}
            checked={true}
            label='Enforce rate limits on manual card payments'
            loading={isProcessing}
          />
        </FormControlStacked>
        <FormControlStacked>
          <Checkbox
            disabled={!paymentProcessingPanel.adyen.rateLimits.enabled}
            checked={paymentProcessingPanel.adyen.rateLimits.enforceRateLimitsForGooglePay}
            label='Enforce rate limits on Google Pay transactions'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_ADYEN_PP_RATE_LIMITING_FOR_GOOGLE_PAY
              );
            }}
            loading={isProcessing}
          />
        </FormControlStacked>
        <FormControlStacked>
          <FormattedInput
            format='money'
            disabled={!paymentProcessingPanel.adyen.rateLimits.enabled}
            value={paymentProcessingPanel.adyen.rateLimits.amount}
            onChange={(event) => {
              updateLocationParam(
                toNumber(event.target.value),
                LOCATION_SETTINGS_ACTIONS.UPDATE_ADYEN_PP_RATE_LIMITING_AMOUNT
              );
            }}
            loading={isProcessing}
          />
          <span className='form__control__separator'>Per</span>
          <FormattedInput
            format='min'
            disabled={!paymentProcessingPanel.adyen.rateLimits.enabled}
            value={paymentProcessingPanel.adyen.rateLimits.duration / 60}
            onChange={(event) => {
              updateLocationParam(
                toNumber(event.target.value) * 60,
                LOCATION_SETTINGS_ACTIONS.UPDATE_ADYEN_PP_RATE_LIMITING_TIME_INTERVAL
              );
            }}
            loading={isProcessing}
          />
        </FormControlStacked>
      </FormControl>

      <FormControl label='Additional Settings'>
        <Checkbox
          checked={paymentProcessingPanel.adyen.allowSmallTransactions}
          label='Allow small transactions'
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            updateLocationParam(
              event.target.checked,
              LOCATION_SETTINGS_ACTIONS.UPDATE_ADYEN_PP_ALLOW_SMALL_TRANSACTIONS
            );
          }}
          loading={isProcessing}
        />
      </FormControl>

      <FormControl label='Per Transaction Fee'>
        <div className='control--stacked'>
          <div className={styles.transactionFeeInput}>
            <FormattedInput
              format='percentage'
              onChange={(event) =>
                updateLocationParam(
                  toNumber(event.target.value),
                  LOCATION_SETTINGS_ACTIONS.UPDATE_ADYEN_PP_TRANSACTION_FEE_PERCENTAGE
                )
              }
              value={paymentProcessingPanel.adyen.perTransactionFees.percentage}
            />
          </div>
        </div>
        <div className='control--stacked'>
          <div className={styles.transactionFeeInput}>
            <FormattedInput
              format='money'
              onChange={(event) =>
                updateLocationParam(
                  toNumber(event.target.value) * 100,
                  LOCATION_SETTINGS_ACTIONS.UPDATE_ADYEN_PP_TRANSACTION_FEE_AMOUNT
                )
              }
              value={paymentProcessingPanel.adyen.perTransactionFees.fixedAmount / 100}
            />
          </div>
        </div>
      </FormControl>

      <FormControl label='Daily Deposit Email Recipients'>
        <TextInput
          placeholder='Recipient email addresses (comma separated)'
          onChange={(event) => {
            const trimmedValues = event.target.value.split(',').map(v => v.trim())
            updateLocationParam(trimmedValues, LOCATION_SETTINGS_ACTIONS.UPDATE_ADYEN_PP_PAYFAC_REPORT_EMAILS);
          }}
          value={paymentProcessingPanel.adyen.payfac.payoutRecipientEmails.join(', ')}
          loading={isProcessing}
        />
      </FormControl>

      <FormControl label='Pricing Model'>
        <SelectFilter
          placeholder='Select Pricing Model'
          options={adyenPriceModel}
          onChange={handlePriceModelChange}
          value={mapSelectOptions(adyenPriceModel, paymentProcessingPanel.adyen.pricingModel)}
          loading={isProcessing}
        />
      </FormControl>
    </>
  );
};
