import config from '../../config';
import executeApiAsync from '../../services/utils/executeApiAsync';

export class SquareIntegration {
  static BASE_URL = `${config.readyApiLegacyEndpoint}`;

  public static async authorize(companyId: string, code: string): Promise<boolean> {
    const url = `${SquareIntegration.BASE_URL}/companies/${companyId}/integrations/square/auth`;
    const data = {
      code,
    };
    return await executeApiAsync<boolean>(url, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }
}
