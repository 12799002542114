import { IOrderType } from '@ready/table.core/dist/src/models/IOrderType';
import { POS_ORDER_TYPES_ACTIONS } from './types';
import { toastErrorState } from '../../../redux/actions/uiActions/responseStateActions';
import { TablesService } from '../../services/tablesService';

export const getPosOrderTypes =
  (companyId: string = '', locationId: string = '') =>
  async (dispatch: any) => {
    dispatch(getPosOrderTypesBegin());
    try {
      const posOrderTypes = await TablesService.listPosOrderTypes(companyId, locationId);
      dispatch(getPosOrderTypesSuccess(posOrderTypes));
    } catch (error) {
      dispatch(getPosOrderTypesError());
      dispatch(toastErrorState(error.status, error.message));
    }
  };

const getPosOrderTypesBegin = () => ({
  type: POS_ORDER_TYPES_ACTIONS.GET_POS_ORDER_TYPES_BEGIN,
});

const getPosOrderTypesSuccess = (results: IOrderType[]) => ({
  type: POS_ORDER_TYPES_ACTIONS.GET_POS_ORDER_TYPES_SUCCESS,
  payload: results,
});

const getPosOrderTypesError = () => ({
  type: POS_ORDER_TYPES_ACTIONS.GET_POS_ORDER_TYPES_ERROR,
});
