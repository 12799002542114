import produce from 'immer';
import { Action } from '../../redux/types';
import { ADMINS_LIST_ACTION_TYPES } from './AdminsListActions';
import { adminsList } from './AdminsListState';

export const adminsListReducer = (state = adminsList, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ADMINS_LIST_ACTION_TYPES.ADMINS_LIST_BEGIN:
        draft.loading = true;
        return draft;

      case ADMINS_LIST_ACTION_TYPES.ADMINS_LIST_SUCCESS:
        draft.admins = action.payload;
        draft.loading = false;
        draft.error = false;
        return draft;

      case ADMINS_LIST_ACTION_TYPES.ADMINS_LIST_ERROR:
        draft.error = true;
        draft.loading = false;
        return draft;

      default:
        return draft;
    }
  });
};
