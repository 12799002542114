import config from '../../config';
import executeApiAsync from '../../services/utils/executeApiAsync';
import { IBadge, ITag } from '@ready/menu.core';

export default class TagsBadgesService {
  static BASE_URL = `${config.readyDashboardApiEndpoint}`;

  static getTagsList = async (companyId: string, locationId?: string): Promise<ITag[]> => {
    const url = !locationId
      ? `${TagsBadgesService.BASE_URL}/companies/${companyId}/menus/tags`
      : `${TagsBadgesService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/tags`;
    const response = await executeApiAsync<ITag[]>(url);
    return response;
  };

  static createTag = async (companyId: string, tag: ITag): Promise<ITag> => {
    const url = `${TagsBadgesService.BASE_URL}/companies/${companyId}/menus/tags`;
    return await executeApiAsync<ITag>(url, {
      method: 'POST',
      body: JSON.stringify(tag),
    });
  };

  static updateTag = async (companyId: string, tag: ITag): Promise<ITag> => {
    const url = `${TagsBadgesService.BASE_URL}/companies/${companyId}/menus/tags`;
    return await executeApiAsync<ITag>(url, {
      method: 'PUT',
      body: JSON.stringify(tag),
    });
  };

  static deleteTagById = async (companyId: string, id: string): Promise<void> => {
    const url = `${TagsBadgesService.BASE_URL}/companies/${companyId}/menus/tags/${id}`;
    await executeApiAsync(url, { method: 'DELETE' });
  };

  static getBadgesList = async (companyId: string, locationId?: string): Promise<IBadge[]> => {
    const url = !locationId
      ? `${TagsBadgesService.BASE_URL}/companies/${companyId}/menus/badges`
      : `${TagsBadgesService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/badges`;

    const response = await executeApiAsync<IBadge[]>(url);
    return response;
  };

  static createBadge = async (companyId: string, badge: IBadge): Promise<IBadge> => {
    const url = `${TagsBadgesService.BASE_URL}/companies/${companyId}/menus/badges`;
    return await executeApiAsync<IBadge>(url, {
      method: 'POST',
      body: JSON.stringify(badge),
    });
  };

  static updateBadge = async (companyId: string, badge: IBadge): Promise<IBadge> => {
    const url = `${TagsBadgesService.BASE_URL}/companies/${companyId}/menus/badges`;
    return await executeApiAsync<IBadge>(url, {
      method: 'PUT',
      body: JSON.stringify(badge),
    });
  };

  static deleteBadgeById = async (companyId: string, id: string): Promise<void> => {
    const url = `${TagsBadgesService.BASE_URL}/companies/${companyId}/menus/badges/${id}`;
    await executeApiAsync(url, { method: 'DELETE' });
  };
}
