import debounce from 'lodash/debounce';

const defaultDebounceTime = 500;

export interface Params {
  [key: string]: string;
}

export const updatePath = debounce((query: string, pathname: string, pushInHistory: any, additionalQuery?: string) => {
  const path = query ? `${pathname}?query=${query}${additionalQuery ? `&${additionalQuery}` : ''}` : pathname;
  pushInHistory(path);
}, defaultDebounceTime);

/** Updates the current query parameters.
 * If empty string is used as a value, it will delete it from the query params.
 * */
export const updateQueryParams = (path: string, params: Params, pushInHistory: any) => {
  var searchParams = new URLSearchParams(window.location.search);

  for (const [key, value] of Object.entries(params)) {
    if (value === '') {
      searchParams.delete(key);
    } else {
      searchParams.set(key, value);
    }
  }

  const targetPath = `${path}?${searchParams.toString()}`;
  pushInHistory(targetPath);
};

export const updateQueryParamsDebounced = debounce(updateQueryParams, defaultDebounceTime);
