import React from 'react';
import { endOfDay, formatISO, setMilliseconds, setSeconds, startOfDay, startOfMonth } from 'date-fns';
import { systemTimeToZonedEquivalent } from 'utils/dateUtils/systemTimeToZonedEquivalent';
import { zonedTimeToSystemEquivalent } from 'utils/dateUtils/zonedTimeToSystemEquivalent';
import { IEffectiveDates, EffectiveDates as ItemEffectiveDates } from '@ready/menu.core';
import styles from './EffectiveDates.module.scss';
import { DatePickerValue } from 'components/DatePicker/DatePicker';
import { FormControl } from 'components/Form';
import DateTimeInput from 'components/DateTimeInput/DateTimeInput';
import { EffectiveDateValue } from 'components/Value/EffectiveDateValue';

interface Props {
  effectiveDates?: IEffectiveDates;
  timeZone?: string;
  withError?: boolean;
  errorMessage?: string;
  onChangeStartDate: (start?: string) => void;
  onChangeEndDate: (end?: string) => void;
  isInModal?: boolean;
  disabled?: boolean;
  disabledLabel?: boolean;
  readonly?: boolean;
}

export const EffectiveDates = ({
  effectiveDates,
  timeZone,
  withError,
  errorMessage,
  onChangeStartDate,
  onChangeEndDate,
  isInModal = false,
  disabled = false,
  disabledLabel = false,
  readonly = false,
}: Props): JSX.Element => {
  const today = new Date();
  const menuEffectiveDates = ItemEffectiveDates.from(effectiveDates);
  const startDate = menuEffectiveDates?.start;
  const endDate = menuEffectiveDates?.end;
  const rezonedStartDate = startDate && timeZone ? zonedTimeToSystemEquivalent(startDate, timeZone) : startDate;
  const rezonedEndDate = endDate && timeZone ? zonedTimeToSystemEquivalent(endDate, timeZone) : endDate;
  const effectiveStartDate: DatePickerValue = {
    startMonth: startOfMonth(rezonedStartDate ?? today),
    endMonth: startOfMonth(rezonedStartDate ?? today),
    startDate: rezonedStartDate,
    endDate: rezonedStartDate,
  };
  const effectiveEndDate: DatePickerValue = {
    startMonth: startOfMonth(rezonedEndDate ?? today),
    endMonth: startOfMonth(rezonedEndDate ?? today),
    startDate: rezonedEndDate,
    endDate: rezonedEndDate,
  };
  const startOfToday = startOfDay(today);
  const endOfToday = setSeconds(setMilliseconds(endOfDay(today), 0), 0);
  const initialEffectiveStartDateValue: DatePickerValue = {
    startMonth: startOfMonth(today),
    endMonth: startOfMonth(today),
    startDate: startOfToday,
    endDate: startOfToday,
  };
  const initialEffectiveEndDateValue: DatePickerValue = {
    startMonth: startOfMonth(today),
    endMonth: startOfMonth(today),
    startDate: endOfToday,
    endDate: endOfToday,
  };

  const handleChangeEffectiveStartDate = (value: DatePickerValue) => {
    onChangeStartDate(
      value.startDate && timeZone
        ? formatISO(systemTimeToZonedEquivalent(value.startDate, timeZone))
        : value.startDate
        ? formatISO(value.startDate)
        : undefined
    );
  };

  const handleChangeEffectiveEndDate = (value: DatePickerValue) => {
    // NOTE: The value that gets returned when doing a single date selection contains the
    // selected date in its "startDate" field. Here we are doing a single selection for a field
    // that is called "effectiveEndDate", so we set the "effectiveEndDate" to the "startDate"
    // value. A bit confusing, but that's just how it works.
    onChangeEndDate(
      value.startDate && timeZone
        ? formatISO(systemTimeToZonedEquivalent(value.startDate, timeZone))
        : value.startDate
        ? formatISO(value.startDate)
        : undefined
    );
  };

  return (
    <FormControl
      label='Effective Date'
      labelTooltip='Enter a start and/or end date to restrict when this item will be visible on your menu. Leave blank for no restrictions.'
      withError={withError}
      errorMessage={errorMessage}
      disabled={disabledLabel}
    >
      {readonly ? (
        <EffectiveDateValue effectiveDates={effectiveDates} />
      ) : (
        <div className={styles.effectiveDates}>
          <DateTimeInput
            placeholder='Select Start Date'
            value={effectiveStartDate}
            initialValue={initialEffectiveStartDateValue}
            type='datetime'
            mode='single'
            clearable
            withError={withError}
            onChange={handleChangeEffectiveStartDate}
            timePlaceholder='00:00:00'
            isInModal={isInModal}
            disabled={disabled}
          />
          <DateTimeInput
            placeholder='Select End Date'
            value={effectiveEndDate}
            initialValue={initialEffectiveEndDateValue}
            type='datetime'
            mode='single'
            clearable
            withError={withError}
            onChange={handleChangeEffectiveEndDate}
            timePlaceholder='23:59:59'
            isInModal={isInModal}
            disabled={disabled}
          />
        </div>
      )}
    </FormControl>
  );
};
