import {
  ILocationOrderSettings,
  IOrderDetails,
  IOrderGiftCardTotals,
  OrderFulfillmentStatus,
  PaginationResponse,
  ISmsNotificationsConfig,
} from '@ready/dashboardv2api.contracts';
import { endOfDay, formatISO, isValid, parseISO, startOfDay } from 'date-fns';
import config from '../config';
import { OrderLocationPagination } from '../types/OrderLocationPagination.interface';
import { OrderLocationResponse } from '../types/OrderLocationResponse.interface';
import { mapOrderLocationToViewModel } from '../utils/orderUtils';
import { IOrderLocationData } from './types/orders.type';
import executeApiAsync from './utils/executeApiAsync';

export class OrdersService {
  public static async getOrdersLocationData(
    companyId: string,
    query: string = '',
    paginationData: OrderLocationPagination,
  ): Promise<IOrderLocationData> {
    const { number } = paginationData;
    const showHiddenLocations = false;

    // TODO: Temporarily force fetch size to 50 as per RD-461
    const API_URL = `${
      config.readyDashboardApiEndpoint
    }/companies/${companyId}/locations/orderLocations?query=${query}&page=${number}&size=${50}&isHidden=${showHiddenLocations}`;
    const result = await executeApiAsync<PaginationResponse<OrderLocationResponse>>(API_URL);
    const items = mapOrderLocationToViewModel(result.items);
    return {
      ...result,
      items,
    };
  }

  public static async getOrderList(companyId: string, locationId: string, day: string = ''): Promise<IOrderDetails[]> {
    const today = startOfDay(new Date());
    const parsedDay = parseISO(day);
    const dayValue = isValid(parsedDay) ? parsedDay : today;

    const startDate = startOfDay(dayValue);
    const endDate = endOfDay(dayValue);

    const startDateString = encodeURIComponent(formatISO(startDate));
    const endDateString = encodeURIComponent(formatISO(endDate));

    const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/orders?startDate=${startDateString}&endDate=${endDateString}`;

    const preParsedResults: any[] = await executeApiAsync(url);

    // Properly parse any dates
    const results: IOrderDetails[] = preParsedResults.map((orderDetail) => {
      const orderDetails: IOrderDetails = {
        ...orderDetail,
        pickupTime: parseISO(orderDetail.pickupTime),
        created: parseISO(orderDetail.created),
      };

      return orderDetails;
    });

    return [...results];
  }

  public static async getOrder(companyId: string, locationId: string, orderId: string): Promise<IOrderDetails> {
    const orderDetails = await executeApiAsync<IOrderDetails>(
      `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/orders/${orderId}/orderDetails`
    );

    return orderDetails;
  }

  public static async getOrderSmsConfigs(companyId: string, locationId: string): Promise<ISmsNotificationsConfig> {
    return executeApiAsync<ISmsNotificationsConfig>(
      `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/orderSms`
    );
  }

  public static async toggleAlcoholOrdering(companyId: string, locationId: string, isOn: boolean): Promise<void> {
    await executeApiAsync<void>(
      `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/alcoholOrdering`,
      {
        method: 'PUT',
        body: JSON.stringify({ alcoholOrderingEnabled: isOn }),
      }
    );
  }

  public static async toggleOrdering(companyId: string, locationId: string, isOn: boolean): Promise<void> {
    await executeApiAsync<void>(`${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/ordering`, {
      method: 'PUT',
      body: JSON.stringify({ orderingEnabled: isOn }),
    });
  }

  public static async updateOrderStatus(
    companyId: string,
    locationId: string,
    orderId: string,
    orderStatus: OrderFulfillmentStatus,
    invoiceId?: string,
    orderCancelledMessage?: string,
    refundAmount?: number,
    giftCardPaymentInfo?: IOrderGiftCardTotals
  ): Promise<IOrderDetails> {
    const url = `${config.readyApiLegacyEndpoint}/companies/${companyId}/locations/${locationId}/orders/${orderId}/status`;
    let body: {
      orderStatus: OrderFulfillmentStatus;
      invoiceId?: string;
      orderCancelledMessage?: string;
      refundAmount?: number;
      giftCardRefundAmount?: number;
    } = {
      orderStatus,
    };

    if (!!invoiceId) {
      body.invoiceId = invoiceId;
    }

    if (orderStatus === OrderFulfillmentStatus.Cancelled) {
      if (!!orderCancelledMessage) {
        body.orderCancelledMessage = orderCancelledMessage;
      }
      if (typeof refundAmount === 'number') {
        body.refundAmount = refundAmount;
      }

      // Refund the giftcard, should it exist.
      if (
        invoiceId &&
        giftCardPaymentInfo &&
        giftCardPaymentInfo.amountPaid - giftCardPaymentInfo.amountRefunded !== 0
      ) {
        body.giftCardRefundAmount = giftCardPaymentInfo.amountPaid;
      }
    }

    return executeApiAsync(url, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  public static async updatePickupTime(
    companyId: string,
    locationId: string,
    orderId: string,
    orderAheadTime: Date,
    invoiceId?: string,
    message?: string
  ): Promise<void> {
    const isoDate = orderAheadTime.toISOString();
    const payload = {
      invoiceId,
      orderAheadTime: isoDate,
      message,
    };
    const url = `${config.readyApiLegacyEndpoint}/companies/${companyId}/locations/${locationId}/orders/${orderId}/orderAheadTime`;
    return executeApiAsync(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
    });
  }

  public static async getLocationOrderSettingsData(
    companyId: string,
    locationId: string
  ): Promise<ILocationOrderSettings> {
    const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/orderSettings`;
    return executeApiAsync(url);
  }

  public static async saveLocationOrderSettingsData(
    companyId: string,
    locationId: string,
    payload: ILocationOrderSettings
  ): Promise<ILocationOrderSettings> {
    const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/orderSettings`;
    return executeApiAsync(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
    });
  }
}
