import produce from 'immer';
import { Action } from '../../redux/types';

import { LOCATION_SETTINGS_ACTIONS } from './LocationSettingsActions';
import { newsletterPanel } from './PanelNewsletterState';

export const newsletterPanelReducer = (state = newsletterPanel, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case LOCATION_SETTINGS_ACTIONS.SET_NEWSLETTER_PANEL:
        draft = { ...action.payload };
        return draft;
      case LOCATION_SETTINGS_ACTIONS.TOGGLE_SHOW_NEWSLETTER_SUB_CHECKBOX:
        draft.showNewsletterSubCheckbox = action.payload;
        draft.isDirty = true;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_NEWSLETTER_PROMPT_TEXT:
        draft.newsletterPromptText = action.payload;
        draft.isDirty = true;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_NEWSLETTER_SUCCESS_TEXT:
        draft.newsletterSuccessText = action.payload;
        draft.isDirty = true;
        return draft;
      default:
        return draft;
    }
  });
};
