import React from 'react';
import { Auth0Provider } from './context/Auth0';
import history from './utils/history';
import AppRouter from './routes/AppRouter';
import './styles/index.scss';
import config from './config';
import useFeatureToggles from './hooks/useFeatureToggles';

// redux
import store from './redux/store';
import { Provider } from 'react-redux';

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState: any) => {
  history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
};

const App = () => {
  useFeatureToggles();

  return (
    <div className='app'>
      <Auth0Provider
        domain={config.auth0Domain}
        client_id={config.auth0ClientId}
        audience={config.auth0Audience}
        redirect_uri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
      >
        <Provider store={store}>
          <AppRouter/>
        </Provider>
      </Auth0Provider>
    </div>
  );
};

export default App;
