import { ITransaction } from '@ready/dashboardv2api.contracts';
import config from '../config';
import executeApiAsync from './utils/executeApiAsync';

const BASE_URL = `${config.readyApiLegacyEndpoint}/companies`;

export const getTransaction = async (contextId: string, locationId: string, id: string): Promise<ITransaction> => {
  const url = `${BASE_URL}/${contextId}/locations/${locationId}/transactions/${id}`;
  return await executeApiAsync<ITransaction>(url);
};

export const refundTransactionByIdAndAmount = async (
  contextId: string,
  locationId: string,
  id: string,
  amount: number
): Promise<ITransaction> => {
  const url = `${BASE_URL}/${contextId}/locations/${locationId}/transactions/${id}/refund`;
  const method = 'POST';
  const body = JSON.stringify({ amount });
  return await executeApiAsync<ITransaction>(url, {
    method,
    body,
  });
};

export const retryPaymentSync = async (locationId: string, invoiceId: string): Promise<void> => {
  await executeApiAsync(`${config.readyDashboardApiEndpoint}/locations/${locationId}/invoices/${invoiceId}/sync`, {
    method: 'POST',
  });
};
