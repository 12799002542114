export enum TruncateNumberDisplayType {
  Thousands = 'K',
  Millions = 'M',
}

export enum TruncateNumberDecimalPlaces {
  Zero,
  One,
  Two,
  Three,
}

export enum TruncateNumberOperation {
  Floor,
  Ceiling,
  Round,
}

const calculate = (_number: number, operation: TruncateNumberOperation): number => {
  switch (operation) {
    case TruncateNumberOperation.Floor:
      return Math.floor(_number);
    case TruncateNumberOperation.Ceiling:
      return Math.ceil(_number);
    case TruncateNumberOperation.Round:
      return Math.round(_number);
  }
};

const truncateNumber = (
  _number: number,
  displayType: TruncateNumberDisplayType = TruncateNumberDisplayType.Thousands,
  decimalPlaces: TruncateNumberDecimalPlaces = TruncateNumberDecimalPlaces.Zero,
  operation: TruncateNumberOperation = TruncateNumberOperation.Floor,
  pad: boolean = false
) => {
  let preFormattedNumber = _number;

  if (displayType === TruncateNumberDisplayType.Millions) {
    preFormattedNumber = preFormattedNumber / 1000;
  }

  switch (decimalPlaces) {
    case TruncateNumberDecimalPlaces.Zero:
      preFormattedNumber = calculate(preFormattedNumber / 1000, operation);
      break;
    case TruncateNumberDecimalPlaces.One:
      preFormattedNumber = calculate(preFormattedNumber / 100, operation) / 10;
      break;
    case TruncateNumberDecimalPlaces.Two:
      preFormattedNumber = calculate(preFormattedNumber / 10, operation) / 100;
      break;
    case TruncateNumberDecimalPlaces.Three:
      preFormattedNumber = calculate(preFormattedNumber, operation) / 1000;
      break;
  }

  return `${pad ? preFormattedNumber.toFixed(decimalPlaces) : preFormattedNumber}${displayType}`;
};

export default truncateNumber;
