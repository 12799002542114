import React from 'react';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { CreateEditItemPanels } from 'sharedMenuItems/pages/createEditItem/components/createEditItemPanels/CreateEditItemPanels';
import { getTemplateMenuItemThunk } from 'sharedMenuItems/redux/createEditItem/createEditItemThunkActions';
import { selectCreateEditItemState } from 'sharedMenuItems/redux/selectors';
import { SharedModifierDetails } from 'sharedMenuItems/pages/createEditItem/components/sharedModifierDetails/SharedModifierDetails';
import { resetSharedMenuItemState } from 'sharedMenuItems/redux/createEditItem/createEditItemSlice';
import { SharedMenuConextParams } from 'sharedMenuItems/types/sharedMenuContextParams.interface';

export const ItemAndModDetailsPageRedirect = () => {
  const dispatch = useAppDispatch();
  const { loading, item } = useAppSelector(selectCreateEditItemState);
  const { contextId: companyId, id: sharedMenuItemId, nestedForcedModifierId } = useParams<SharedMenuConextParams>();

  React.useEffect(() => {
    if (nestedForcedModifierId || sharedMenuItemId) {
      dispatch(getTemplateMenuItemThunk({ companyId, itemId: nestedForcedModifierId ?? sharedMenuItemId }));
    }
    return () => {
      dispatch(resetSharedMenuItemState());
    };
  }, [companyId, dispatch, sharedMenuItemId, nestedForcedModifierId]);

  return (
    <>
      {loading ? <LoadingSpinner /> : item?.itemType === 'item' ? <CreateEditItemPanels /> : <SharedModifierDetails />}
    </>
  );
};
