import React from 'react';
import { IEmbeddedMenuItem } from '@ready/menu.core';
import { IItemGroupViewEditPage } from '../../redux/ItemGroupsState';
import DragAndDrop from '../../../components/DragAndDrop';
import DraggableGroupItemCard from './DraggableGroupItemCard';
import styles from './ItemGroupItems.module.scss';
import { setFormIsDirty } from 'redux/actions/uiActions/formStateActions';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { selectItemGroupsViewEditState } from 'menus/redux/ItemGroupSelector';
import {
  removeItemGroupItem,
  updateItemGroupItemsSortOrder,
  updateItemGroupItemVisibility,
  updateItemGroupReorderItems,
} from 'menus/redux/ItemGroupsActions';
import useS3ImagesMap, { IImageS3Request } from 'hooks/useS3Images';

const ItemGroupItems = () => {
  const dispatch = useAppDispatch();
  const itemGroupViewEditPage = useAppSelector<IItemGroupViewEditPage>(selectItemGroupsViewEditState);
  const { groupItemsPanel, detailsPanel, imagesPanel } = itemGroupViewEditPage;
  const { items: groupItems } = groupItemsPanel;
  const isSharedItemGroup = !!detailsPanel.itemGroupFields?.parentTemplateId;

  const handleReorderItems = (oldIndex: number, newIndex: number) => {
    dispatch(setFormIsDirty(true));
    dispatch(updateItemGroupReorderItems(oldIndex, newIndex));
    dispatch(updateItemGroupItemsSortOrder());
  };

  const isPageProcessing =
    detailsPanel.processing ||
    imagesPanel.processingImage ||
    imagesPanel.processingThumbnailImage ||
    groupItemsPanel.processing;

  const [itemImgUrls, setItemImgUrls] = React.useState<{
    [id: string]: string;
  }>({});

  // get items thumbnail images
  const getS3ImagesMap = useS3ImagesMap();
  React.useEffect(() => {
    async function getImages(items: IEmbeddedMenuItem[]) {
      const imagesRequest = items
        .filter((i) => !!i.thumbnailImageId)
        .map(
          (i) =>
            ({
              id: i.itemId,
              fileKey: i.thumbnailImageId,
            } as IImageS3Request)
        );
      const images = await getS3ImagesMap(imagesRequest);
      setItemImgUrls(images);
    }
    if (groupItems.length > 0) {
      getImages(groupItems);
    }
  }, [groupItems, getS3ImagesMap]);

  return (
    <div className={styles.container}>
      {groupItems.length > 0 ? (
        <DragAndDrop
          dropAreaClassName={styles.dropArea}
          dropAreaDraggingClassName={styles.dropAreaDragging}
          dragItemClassName={styles.dragItem}
          dragItemDraggingClassName={styles.dragItemDragging}
          dragHandleClassName={styles.dragHandle}
          dragHandleProvided
          dragItems={groupItems}
          dragItemKeyExtractor={(item: IEmbeddedMenuItem) => item.itemId}
          dragItemComponentBuilder={(item: IEmbeddedMenuItem, dragHandle?: JSX.Element) => (
            <DraggableGroupItemCard
              item={item}
              itemImgUrl={itemImgUrls[item.itemId]}
              dragHandle={dragHandle}
              isPageProcessing={isPageProcessing}
              updateItemGroupItemVisibility={(
                itemId: string,
                visibility: number,
                immediateSync?: boolean | undefined
              ) => dispatch(updateItemGroupItemVisibility(itemId, visibility, immediateSync))}
              removeItemGroupItem={(itemId: string) => dispatch(removeItemGroupItem(itemId))}
              updateItemGroupItemsSortOrder={() => dispatch(updateItemGroupItemsSortOrder())}
              setFormIsDirty={(isDirty: boolean) => dispatch(setFormIsDirty(isDirty))}
              isSharedItemGroup={isSharedItemGroup}
            />
          )}
          onDragItemDropped={handleReorderItems}
        />
      ) : (
        <div className={styles.itemsPanelFooter}>
          <p className={styles.noItemsMessage}>Click the +Add Items button to start adding items to this group.</p>
        </div>
      )}
    </div>
  );
};

export default ItemGroupItems;
