import { IBulkEditMenuItemGroup, IScheduleLink, IScheduleReference } from '@ready/menu.core';
import { FormControl } from 'components/Form';
import ScheduleFormControl from 'menus/components/shared/ScheduleFormControl';
import { ILink } from 'menus/redux/MenusState';
import { selectScheduleListState } from 'menus/redux/SchedulesSelectors';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppSelector } from 'redux/store';

export type IBulkEditItemSettingsFormState = Pick<IBulkEditMenuItemGroup, 'schedule'>;

const BulkEditItemGroupSettingsForm = () => {
  const { schedules, loading: schedulesLoading } = useAppSelector(selectScheduleListState);
  const { watch, setValue } = useFormContext<IBulkEditItemSettingsFormState>();

  const onScheduleFormControlChange = (schedule: IScheduleLink<ILink> | null) => {
    if (schedule) {
      const updatedLinks: IScheduleReference[] = schedule.links.map((link) => ({ id: link.id, name: link.name ?? '' }));
      setValue('schedule', { ...schedule, links: updatedLinks });
    } else {
      setValue('schedule', undefined);
    }
  };

  return (
    <FormControl label='Schedule'>
      <ScheduleFormControl
        onChange={onScheduleFormControlChange}
        validation={{ displayName: { hasError: false, errorMessage: '' } }}
        opened={true}
        scheduleLink={watch('schedule')}
        schedules={schedules}
        areSchedulesLoading={schedulesLoading}
        isInModal
        fullWidth
      />
    </FormControl>
  );
};

export default BulkEditItemGroupSettingsForm;
