import { ILocationOrderMessagingConfig } from '@ready/dashboardv2api.contracts';
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import {
  orderMessagingConfigInitialState,
  OrderMessagingConfigState,
} from 'companyLocations/redux/initialStates/orderMessagingConfig';
import { getClientOrderMessagingConfigThunk, updateClientOrderMessagingConfigThunk } from './thunk';

export const orderMessagingConfigSlice = createSlice({
  name: 'orderMessagingConfig',
  initialState: orderMessagingConfigInitialState,

  reducers: {},

  extraReducers: (builder: ActionReducerMapBuilder<OrderMessagingConfigState>) => {
    builder.addCase(getClientOrderMessagingConfigThunk.pending, (state: OrderMessagingConfigState) => {
      state.loading = true;
    });
    builder.addCase(
      getClientOrderMessagingConfigThunk.fulfilled,
      (state: OrderMessagingConfigState, action: { payload: ILocationOrderMessagingConfig }) => {
        state.loading = false;
        state.orderMessagingEdit = state.orderMessagingCache = action.payload;
      }
    );
    builder.addCase(getClientOrderMessagingConfigThunk.rejected, (state: OrderMessagingConfigState) => {
      state.loading = false;
    });
    builder.addCase(
      updateClientOrderMessagingConfigThunk.fulfilled,
      (state: OrderMessagingConfigState, action: { payload: ILocationOrderMessagingConfig }) => {
        state.orderMessagingEdit = state.orderMessagingCache = action.payload;
      }
    );
  },
});
