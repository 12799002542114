import { SelectableTable } from '../redux/ServerAssignmentState';

// Falsy and empty string should just return the unfiltered list
const searchTables = (query: string, tables: SelectableTable[]): SelectableTable[] =>
  !query || query.trim() === ''
    ? tables
    : tables.filter((table) => {
        const searchQuery = decodeURIComponent(query).trim().toLowerCase();
        const tableNumber = table.tableNumber?.toString() || '';
        const revenueCenter = table.revenueCenter.toLowerCase();

        return tableNumber.includes(searchQuery) || revenueCenter.includes(searchQuery);
      });

export default searchTables;
