import React, { useRef, RefObject } from 'react';
import styles from './CompanyTablePage.module.scss';
import TextInput from '../../../components/TextInput/TextInput';
import { IUnsavedMappedTableConfiguration } from '../../redux/initialStates/tablesDetails';

export interface EditableCellProps {
  tableId: string;
  children?: string;
  disabledLoading?: boolean;
  width?: number;
  placeholder?: string;
  unsavedMappedTargetCodes: IUnsavedMappedTableConfiguration;
  handleSave: (code: string, tableId: string) => void;
}

const EditableCell = (props: EditableCellProps) => {
  const { tableId, children, disabledLoading, placeholder, handleSave, unsavedMappedTargetCodes } = props;

  const inputRef: RefObject<HTMLInputElement> = useRef(null);
  const unsavedValue = unsavedMappedTargetCodes[tableId];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSave(e.target.value, tableId);
  };

  return (
    <TextInput
      value={unsavedValue ?? children}
      inputRef={inputRef}
      placeholder={placeholder}
      onChange={handleChange}
      disabledLoading={disabledLoading}
      additionalContainerStyles={`${styles.rowCell} ${styles.editableCell}`}
    />
  );
};

export default EditableCell;
