import {
  Appearance,
  IInnerItemGallery,
  IItemGallery,
  IMenu,
  IMenuItem,
  IMenuItemGroup,
  IMenuSection,
  IMenuSectionItem,
  IMenuSectionItemGroup,
  Status,
} from '@ready/menu.core';
import NamedEntityValidation, { newNamedEntityValidation } from '../types/NamedEntityValidation.interface';
import ItemsAndGroupsView from '../types/ItemsAndGroupsView.enum';
import InfiniteScrollingListState from '../../types/InfiniteScrollingListState.interface';
import InfiniteScrollingListStateHelper from '../../utils/InfiniteScrollingList/InfiniteScrollingListStateHelper';
import { SectionItemChange } from '../types/SectionItemChange.interface';

export interface IMenuForm {
  editing: boolean;
  processing: boolean;
  saved: boolean;
  menu: IMenu;
  validation: NamedEntityValidation;
}

export interface IMenuItemGalleryForm {
  editing: boolean;
}

export interface IMenuSectionForm {
  editing: boolean;
  processing: boolean;
  saved: boolean;
  reordering: boolean;
  itemsForReordering: IMenuSection[];
  deleteRequested: boolean;
  section: IMenuSection;
  sectionItemChanges: SectionItemChange[];
  validation: NamedEntityValidation;
}

export interface IMenuForm {
  loading: boolean;
  editing: boolean;
  changed: boolean;
  processing: boolean;
  saved: boolean;
  cache: IMenu;
  menu: IMenu;
  sectionForm: IMenuSectionForm;
}

export interface IMenuGalleryForm {
  loading: boolean;
  editing: boolean;
  viewing: boolean;
  changed: boolean;
  processing: boolean;
  saved: boolean;
  cache: IMenu;
  menu: IMenu;
  sectionItemChanges: SectionItemChange[];
  validation: NamedEntityValidation;
}

export interface IPreviousMenuState {
  menuId: string;
  expandedSections: string[];
}

export interface IMenuAdvancedSettings {
  isDialogVisible: boolean;
  loading: boolean;
  error: boolean;
  sync: boolean;
  settings: {
    menuSectionsSecondaryNavigation: boolean;
    readOnlyMenus: boolean;
  };
  cachedSettings: {
    menuSectionsSecondaryNavigation: boolean;
    readOnlyMenus: boolean;
  };
}

export interface IItemsAndGroupsSelection extends InfiniteScrollingListState<IMenuItem | IMenuItemGroup> {
  view: ItemsAndGroupsView;
  token: string | undefined;
}

export type SummaryType = 'menu' | 'itemGallery';

export interface IMenuWithType extends IMenu {
  type?: SummaryType;
}

export interface IMenuGalleryView {
  loading: boolean;
  error: boolean;
  visible: boolean;
  menuGalleryDetails: IItemGallery;
}

export interface IMenuGalleryViewMap {
  [key: string]: IMenuGalleryView;
}

export interface IMenusState {
  loading: boolean;
  reordering: boolean;
  processing: boolean;
  items: IMenuWithType[];
  itemsForReordering: IMenuWithType[];
  deleteRequested: boolean;
  deleteProcessing: boolean;
  deleteError: boolean;
  menuToDelete?: IMenu;
  deletedMenu?: IMenu;
  menuForm: IMenuForm;
  menuGalleryForm: IMenuGalleryForm;
  menuGalleryView: IMenuGalleryViewMap;
  previousMenuState: IPreviousMenuState;
  advancedSettings: IMenuAdvancedSettings;
  itemsAndGroupsSelection: IItemsAndGroupsSelection;
  menuGalleryDeleteRequested: boolean;
  menuGalleryDeleted: boolean;
  menuGalleryDeleteProcessing: boolean;
  menuGalleryToDelete: {
    id: string;
    name: string;
  };
}

export const newMenuForm = (editing?: boolean, menu?: IMenu): IMenuForm => ({
  loading: false,
  editing: !!editing,
  changed: false,
  processing: false,
  saved: false,
  cache: menu || newMenu(),
  menu: menu || newMenu(),
  validation: newNamedEntityValidation(),
  sectionForm: newMenuSectionForm(),
});

export const newMenuGalleryForm = (
  editing?: boolean,
  viewing?: boolean,
  menu?: IMenu,
  itemGallery?: IItemGallery | IInnerItemGallery
): IMenuGalleryForm => ({
  loading: false,
  editing: !!editing,
  viewing: !!viewing,
  changed: false,
  processing: false,
  saved: false,
  cache: menu || newMenu(undefined, true),
  menu: menu || newMenu(undefined, true),
  sectionItemChanges: sectionItemChangesForSection(itemGallery),
  validation: newNamedEntityValidation(),
});

export interface ILink {
  id: string;
  name?: string;
}

export const newMenu = (sortOrder?: number, isGallery?: boolean): IMenu => ({
  _id: '',
  displayName: '',
  status: Status.enabled,
  sortOrder: sortOrder || 0,
  sections: [],
  itemGallery: isGallery
    ? {
        _id: '',
        items: [],
        status: Status.enabled,
        description: '',
        displayName: '',
      }
    : undefined,
});

export const sectionItemChange = (item: IMenuSectionItem | IMenuSectionItemGroup): SectionItemChange => ({
  itemId: item.itemId,
  itemType: item.sectionItemType,
  displayName: item.displayName,
  originalDisplayName: item.displayName,
  modified: false,
  validation: {
    displayName: {
      hasError: false,
      errorMessage: '',
    },
  },
});

export const sectionItemChangesForSection = (
  section?: IMenuSection | IItemGallery | IInnerItemGallery
): SectionItemChange[] => {
  const sectionItemChanges: SectionItemChange[] = [];
  if (!section) {
    return sectionItemChanges;
  }
  section.items.forEach((item) => {
    sectionItemChanges.push(sectionItemChange(item));
    if (item.sectionItemType === 'group') {
      (item as IMenuSectionItemGroup).items.forEach((groupItem) =>
        sectionItemChanges.push(sectionItemChange(groupItem))
      );
    }
  });
  return sectionItemChanges;
};

export const newMenuSectionForm = (editing?: boolean, section?: IMenuSection): IMenuSectionForm => ({
  editing: !!editing,
  processing: false,
  reordering: false,
  itemsForReordering: [],
  saved: false,
  deleteRequested: false,
  section: section || newMenuSection(),
  sectionItemChanges: sectionItemChangesForSection(section),
  validation: newNamedEntityValidation(),
});

export const newMenuSection = (): IMenuSection => ({
  uid: '',
  displayName: '',
  status: Status.enabled,
  defaultAppearance: Appearance.expanded,
  sortOrder: 0,
  items: [],
});

export const newItemGallery = (): IInnerItemGallery => ({
  _id: '',
  displayName: '',
  description: '',
  status: Status.disabled,
  items: [],
});

export const newMenuGalleryView = (): IMenuGalleryView => ({
  error: false,
  loading: false,
  visible: false,
  menuGalleryDetails: {
    _id: '',
    displayName: '',
    status: Status.disabled,
    items: [],
    description: '',
    sortOrder: 0,
  },
});

export const menusState: IMenusState = {
  loading: false,
  reordering: false,
  processing: false,
  items: [],
  itemsForReordering: [],
  deleteRequested: false,
  deleteProcessing: false,
  deleteError: false,
  menuForm: newMenuForm(),
  menuGalleryForm: newMenuGalleryForm(),
  menuGalleryView: {},
  previousMenuState: {
    menuId: '',
    expandedSections: [],
  },
  advancedSettings: {
    isDialogVisible: false,
    loading: false,
    error: false,
    sync: false,
    settings: {
      menuSectionsSecondaryNavigation: false,
      readOnlyMenus: false,
    },
    cachedSettings: {
      menuSectionsSecondaryNavigation: false,
      readOnlyMenus: false,
    },
  },
  itemsAndGroupsSelection: {
    ...InfiniteScrollingListStateHelper.initialState<IMenuItem | IMenuItemGroup>(),
    view: ItemsAndGroupsView.ITEMS,
    token: '',
  },
  menuGalleryDeleteRequested: false,
  menuGalleryDeleted: false,
  menuGalleryDeleteProcessing: false,
  menuGalleryToDelete: {
    id: '',
    name: '',
  },
};
