import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { AppState } from '../../../redux/initialStates/AppState';
import { IModifierGroup, ITemplateModifierGroup } from '@ready/menu.core';

import InfiniteScrollingList from '../../../components/InfiniteScrollingList/InfiniteScrollingList';
import AddModifierGroupListItem from './AddModifierGroupListItem';

export type AddModifierGroupListProps = ConnectedProps<typeof connector> & {
  parentModifierGroupId?: string;
  loadModifierGroups: (query?: string) => void;
  loadMoreModifierGroups: (query: string | undefined, page: number) => void;
  addModifierGroup: (modifierGroup: IModifierGroup) => void;
  removeModifierGroup: (modifierGroupId: string) => void;
};

export const buildItemComponent = (
  { name, displayName, options, _id, constraints }: IModifierGroup | ITemplateModifierGroup,
  controls: JSX.Element | null,
  unavailable: boolean = false
): JSX.Element => (
  <AddModifierGroupListItem
    key={_id}
    unavailable={unavailable}
    controls={controls!}
    name={name}
    displayName={displayName}
    optionsLength={options.length}
    constraintsMax={constraints.max}
    constraintsMin={constraints.min}
  />
);

const AddModifierGroupList = (props: AddModifierGroupListProps): JSX.Element => {
  const {
    loading,
    error,
    query,
    page,
    paginationLoading,
    paginationAvailable,
    filtered,
    modifierGroups,
    selectedIds,
    parentModifierGroupId,
    loadModifierGroups,
    loadMoreModifierGroups,
    addModifierGroup,
    removeModifierGroup,
  } = props;

  const noItemsTitle = 'Create Your First Modifier Group';
  const noItemsMessage = `You haven't created any modifier groups yet. You can create and manage
                          your modifier groups on the "Modifier Groups" page.`;

  const selectedModifierGroupIds = selectedIds;

  const loadItems = React.useCallback((): void => {
    loadModifierGroups(query);
  }, [loadModifierGroups, query]);

  const loadMoreItems = React.useCallback((): void => {
    loadMoreModifierGroups(query, page);
  }, [loadMoreModifierGroups, query, page]);

  return (
    <InfiniteScrollingList
      loading={loading}
      error={error}
      paginationLoading={paginationLoading}
      paginationAvailable={paginationAvailable}
      items={modifierGroups}
      filtered={filtered}
      noItemsTitle={noItemsTitle}
      noItemsMessage={noItemsMessage}
      selectedItemKeys={selectedModifierGroupIds}
      unavailableItemKeys={!!parentModifierGroupId ? [parentModifierGroupId] : undefined}
      loadItems={loadItems}
      loadMoreItems={loadMoreItems}
      getItemKey={(modifierGroup: IModifierGroup) => modifierGroup._id}
      buildItemComponent={buildItemComponent}
      addItem={addModifierGroup}
      removeItem={removeModifierGroup}
      isInModal
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: state.menuBuilder.modifierGroups.modifierGroupSelection.loading,
  error: state.menuBuilder.modifierGroups.modifierGroupSelection.error,
  query: state.menuBuilder.modifierGroups.modifierGroupSelection.query,
  page: state.menuBuilder.modifierGroups.modifierGroupSelection.page,
  paginationLoading: state.menuBuilder.modifierGroups.modifierGroupSelection.paginationLoading,
  paginationAvailable: state.menuBuilder.modifierGroups.modifierGroupSelection.paginationAvailable,
  filtered: state.menuBuilder.modifierGroups.modifierGroupSelection.filtered,
  modifierGroups: state.menuBuilder.modifierGroups.modifierGroupSelection.items,
  selectedIds: state.menuBuilder.modifierGroups.modifierGroupSelection.selectedIds,
});

const connector = connect(mapStateToProps);

export default connector(AddModifierGroupList);
