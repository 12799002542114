import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaginationResponse } from '@ready/dashboardv2api.contracts';
import { IMenuCopyState, MenuCopyStatus } from '@ready/menu.core';
import { ILocation } from '../../services/types/ILocation.type';
import { AppDispatch } from '../../redux/store';
import MenuBuilderService from '../services/MenuBuilderService';
import { toastErrorState } from '../../redux/actions/uiActions/responseStateActions';

export interface LaunchMenuCopyModalResult {
  menuCopyState: IMenuCopyState;
  selectedLocation?: ILocation;
}

export interface LaunchMenuCopyResult {
  launched: boolean;
  copyInProgress: boolean;
}

export class CopyMenuThunks {
  public static loadMenuCopyState = createAsyncThunk(
    'copyMenu/loadMenuCopyState',
    async (
      args: { companyId: string; locationId: string },
      { dispatch }: { dispatch: AppDispatch }
    ): Promise<IMenuCopyState> => {
      try {
        return await MenuBuilderService.getMenuCopyState(args.companyId, args.locationId);
      } catch (error) {
        dispatch(toastErrorState(error.status, error.message));
        throw error;
      }
    }
  );

  public static acknowledgeFailedMenuCopy = createAsyncThunk(
    'copyMenu/acknowledgeFailedMenuCopy',
    async (args: { companyId: string; locationId: string }, { dispatch }: { dispatch: AppDispatch }): Promise<void> => {
      try {
        return await MenuBuilderService.acknowledgeFailedMenuCopy(args.companyId, args.locationId);
      } catch (error) {
        dispatch(toastErrorState(error.status, error.message));
        throw error;
      }
    }
  );

  public static launchMenuCopyModal = createAsyncThunk(
    'copyMenu/launchMenuCopyModal',
    async (
      args: { companyId: string; locationId: string; selectedLocation?: ILocation },
      { dispatch }: { dispatch: AppDispatch }
    ): Promise<LaunchMenuCopyModalResult> => {
      try {
        return {
          menuCopyState: await MenuBuilderService.getMenuCopyState(args.companyId, args.locationId),
          selectedLocation: args.selectedLocation,
        };
      } catch (error) {
        dispatch(toastErrorState(error.status, error.message));
        throw error;
      }
    }
  );

  public static loadLocations = createAsyncThunk(
    'copyMenu/loadLocations',
    async (
      args: { companyId: string; filter: string },
      { dispatch }: { dispatch: AppDispatch }
    ): Promise<PaginationResponse<ILocation>> => {
      try {
        return await MenuBuilderService.getMenuBuilderLocations(args.companyId, args.filter);
      } catch (error) {
        dispatch(toastErrorState(error.status, error.message));
        throw error;
      }
    }
  );

  public static launchMenuCopy = createAsyncThunk(
    'copyMenu/launchMenuCopy',
    async (
      args: { companyId: string; sourceLocationId: string; targetLocationId: string },
      { dispatch }: { dispatch: AppDispatch }
    ): Promise<LaunchMenuCopyResult> => {
      try {
        await MenuBuilderService.launchMenuCopy(args.companyId, args.sourceLocationId, args.targetLocationId);
        return {
          launched: true,
          copyInProgress: false,
        };
      } catch (error) {
        if (error.status === 400) {
          // Something went wrong here, which may be that another user already started a copy, but could also be
          // something else. We need to know if it was that a copy is already in progress, so we retrieve the current
          // state to find out. If it's not that, we just fall through to the default error handling below.
          const updatedState = await MenuBuilderService.getMenuCopyState(args.companyId, args.targetLocationId);
          if (updatedState.status === MenuCopyStatus.inProgress) {
            return {
              launched: false,
              copyInProgress: true,
            };
          }
        }
        dispatch(toastErrorState(error.status, error.message));
        throw error;
      }
    }
  );
}
