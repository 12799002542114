import React from 'react';

import Button from '../Button/Button';
import OverflowButton from '../OverflowButton/OverflowButton';
import styles from './app-layout.module.scss';

export interface ControlOption {
  buttonLabel: string;
  dropdownLabel?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  primary?: boolean;
  dropdownPrimary?: boolean;
  loading?: boolean;
  disabled?: boolean;
}

export interface HeaderControlsProps {
  controlOptions: ControlOption[];
}

const HeaderControls = (props: HeaderControlsProps) => {
  const { controlOptions } = props;
  const menuOptions = controlOptions.map((controlOption) => ({
    label: controlOption.dropdownLabel || controlOption.buttonLabel,
    onClick: controlOption.onClick,
    primary: controlOption.dropdownPrimary,
    loading: controlOption.loading,
    disabled: controlOption.disabled,
  }));
  // switch to a dropdown on tablet if there are multiple entries
  const useDropdownForTablet = controlOptions.length > 1;

  const buttonClassName = useDropdownForTablet ? styles.headerControlsDesktop : styles.headerControlsDesktopTablet;
  const overflowClassName = useDropdownForTablet ? styles.headerControlsTabletMobile : styles.headerControlsMobile;

  if (controlOptions.length === 0) {
    return null;
  }

  return (
    <>
      <div className={buttonClassName}>
        {controlOptions.map((controlOption, index) => (
          <Button
            key={index}
            variant={controlOption.primary ? 'primary' : 'secondary'}
            label={controlOption.buttonLabel}
            loading={controlOption.loading}
            disabled={controlOption.disabled}
            onClick={controlOption.onClick}
          />
        ))}
      </div>
      <div className={overflowClassName}>
        <OverflowButton options={menuOptions} />
      </div>
    </>
  );
};

export default HeaderControls;
