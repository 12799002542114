import React from 'react';
import LayoutContent from 'components/AppLayout/LayoutContent';
import InfoCard from 'components/InfoCard/InfoCard';
import InfoCardColumn from 'components/InfoCard/InfoCardColumn';
import InfoCardList from 'components/InfoCard/InfoCardList';
import styles from './CompanySelectionPage.module.scss';
import { useAppSelector } from 'redux/store';
import { selectContextSwitcherState } from 'redux/selectors/contextSwitcher/contextSwitcherSelector';
import { IContextSwitcher } from 'redux/initialStates/contextSwitcher/contextSwitcher';
import { IContext } from '@ready/dashboardv2api.contracts';

export const CompanySelectionPage = () => {
  const { items: companiesList } = useAppSelector<IContextSwitcher>(selectContextSwitcherState);

  return (
    <LayoutContent title='Select a Company' containerType='within'>
      <div className={styles.container}>
        <div>
          Select the company you would like to manage from the list below. To switch between companies, click on <br />
          the company name under your username in the top left corner of your screen.
        </div>
        <div className={styles.content}>
          <InfoCardList>
            {companiesList.map((company: IContext) => (
              <a href={`/companies/${(company.id)}`}
                key={company.id}
              >
                <InfoCard>
                  <InfoCardColumn>
                    <div>{company.name}</div>
                  </InfoCardColumn>
                </InfoCard>
              </a>
            ))}
          </InfoCardList>
        </div>
      </div>
    </LayoutContent>
  );
};
