import React from 'react';
import { Route } from 'react-router-dom';
import { useAuth0 } from '../context/Auth0';

// TODO: I wanted to type the dispatch props going into LocationEditPageNew, which I feel is more important
// than typing here... since this is only written once.
// interface Props {
//   component: (props: Pick<AppState, 'cache' | 'ui'>) => JSX.Element | any;
//   path: string;
//   [propName: string]: any;
// }

const PrivateRoute = (props: any) => {
  const { component: Component, path, location, ...rest } = props;
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

  React.useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: location.pathname },
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, location]);

  const render = (props: any) => (isAuthenticated === true ? <Component {...props} /> : null);

  return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;
