import produce from 'immer';
import { TAGS_BADGES_LIST_ACTIONS } from './TagsBadgesListActions';
import { Action } from '../../redux/types';
import { initialTagsBadgesListState, newBadgeForm, newTagForm, TagsBadgesListState } from './TagsBadgesListState';

export const tagsBadgesListReducer = (state: TagsBadgesListState = initialTagsBadgesListState, action: Action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case TAGS_BADGES_LIST_ACTIONS.TAGS_BADGES_LIST_INIT:
        draft = initialTagsBadgesListState;
        return draft;

      case TAGS_BADGES_LIST_ACTIONS.TAGS_BADGES_LIST_LOADING:
        draft.loading = action.payload;
        draft.tagForm = newTagForm(false);
        draft.badgeForm = newBadgeForm(false);
        return draft;

      case TAGS_BADGES_LIST_ACTIONS.TAGS_BADGES_LIST_SET:
        draft.tags = action.payload.tags;
        draft.badges = action.payload.badges;
        return draft;

      case TAGS_BADGES_LIST_ACTIONS.TAGS_MODAL_EDIT:
        draft.tagForm = action.payload;
        return draft;

      case TAGS_BADGES_LIST_ACTIONS.UPDATE_TAG_SHORT_NAME:
        draft.tagForm.tag.shortCode = action.payload;
        draft.tagForm.validation.tagShortCode = {
          hasError: false,
          notUnique: false,
          errorMessage: '',
        };
        return draft;

      case TAGS_BADGES_LIST_ACTIONS.UPDATE_TAG_NAME:
        draft.tagForm.tag.name = action.payload;
        draft.tagForm.validation.tagName = {
          hasError: false,
          notUnique: false,
          errorMessage: '',
        };
        return draft;

      case TAGS_BADGES_LIST_ACTIONS.UPDATE_UNIQUE_TAG_NAME:
        draft.tagForm.validation.tagName = action.payload;
        return draft;

      case TAGS_BADGES_LIST_ACTIONS.UPDATE_UNIQUE_SHORT_CODE:
        draft.tagForm.validation.tagShortCode = action.payload;
        return draft;

      case TAGS_BADGES_LIST_ACTIONS.SAVE_TAG_NAME_VALIDATED:
        draft.tagForm.validation.tagName = action.payload;
        return draft;

      case TAGS_BADGES_LIST_ACTIONS.SAVE_TAG_SHORT_NAME_VALIDATED:
        draft.tagForm.validation.tagShortCode = action.payload;
        return draft;

      case TAGS_BADGES_LIST_ACTIONS.SAVE_TAG_BEGIN:
        draft.tagForm.processing = true;
        draft.tagForm.saved = false;
        return draft;

      case TAGS_BADGES_LIST_ACTIONS.SAVE_TAG_SUCCESS:
        draft.tagForm.tag = action.payload;
        draft.tagForm.editing = false;
        draft.tagForm.processing = false;
        draft.tagForm.saved = true;
        return draft;

      case TAGS_BADGES_LIST_ACTIONS.SAVE_BADGE_BEGIN:
        draft.badgeForm.processing = true;
        draft.badgeForm.saved = false;
        return draft;

      case TAGS_BADGES_LIST_ACTIONS.SAVE_BADGE_SUCCESS:
        draft.badgeForm.badge = action.payload;
        draft.badgeForm.editing = false;
        draft.badgeForm.processing = false;
        draft.badgeForm.saved = true;
        return draft;

      case TAGS_BADGES_LIST_ACTIONS.SAVE_TAG_ERROR:
        draft.tagForm.processing = false;
        return draft;

      case TAGS_BADGES_LIST_ACTIONS.SAVE_BADGE_ERROR:
        draft.badgeForm.processing = false;
        return draft;

      case TAGS_BADGES_LIST_ACTIONS.PREPARE_TAG_EDIT_MODE:
        draft.tagForm = action.payload;
        return draft;

      case TAGS_BADGES_LIST_ACTIONS.DELETE_TAG_ITEM_REQUESTE:
        draft.deleteRequested = action.payload.show;
        draft.tagToDelete = action.payload.tag;
        return draft;

      case TAGS_BADGES_LIST_ACTIONS.DELETE_TAG_BEGIN:
        draft.deleteProcessing = true;
        draft.deleteError = false;
        return draft;

      case TAGS_BADGES_LIST_ACTIONS.DELETE_TAG_SUCCESS:
        draft.deleteRequested = false;
        draft.deleteProcessing = false;
        draft.deletedTag = draft.tagToDelete;
        draft.tagToDelete = undefined;
        return draft;

      case TAGS_BADGES_LIST_ACTIONS.DELETE_TAG_ERROR:
        draft.deleteProcessing = false;
        draft.deleteError = true;
        return draft;

      case TAGS_BADGES_LIST_ACTIONS.DELETE_BADGE_ITEM_REQUESTE:
        draft.deleteRequested = action.payload.show;
        draft.badgeToDelete = action.payload.badge;
        return draft;

      case TAGS_BADGES_LIST_ACTIONS.DELETE_BADGE_BEGIN:
        draft.deleteProcessing = true;
        draft.deleteError = false;
        return draft;

      case TAGS_BADGES_LIST_ACTIONS.DELETE_BADGE_SUCCESS:
        draft.deleteRequested = false;
        draft.deleteProcessing = false;
        draft.deletedBadge = draft.badgeToDelete;
        draft.badgeToDelete = undefined;
        return draft;

      case TAGS_BADGES_LIST_ACTIONS.DELETE_BADGE_ERROR:
        draft.deleteProcessing = false;
        draft.deleteError = true;
        return draft;

      case TAGS_BADGES_LIST_ACTIONS.BADGE_MODAL_EDIT:
        draft.badgeForm = action.payload;
        return draft;

      case TAGS_BADGES_LIST_ACTIONS.UPDATE_BADGE_NAME:
        draft.badgeForm.badge.name = action.payload;
        draft.badgeForm.validation.badgeName = {
          hasError: false,
          errorMessage: '',
        };
        return draft;

      case TAGS_BADGES_LIST_ACTIONS.UPDATE_BADGE_TEXT:
        draft.badgeForm.badge.text = action.payload;
        draft.badgeForm.validation.badgeText = {
          hasError: false,
          errorMessage: '',
        };
        return draft;

      case TAGS_BADGES_LIST_ACTIONS.UPDATE_BADGE_TEXT_COLOR:
        draft.badgeForm.badge.textColor = action.payload;
        return draft;

      case TAGS_BADGES_LIST_ACTIONS.UPDATE_BADGE_BACKGROUND_COLOR:
        draft.badgeForm.badge.backgroundColor = action.payload;
        return draft;

      case TAGS_BADGES_LIST_ACTIONS.BADGE_NAME_VALIDATION_ERROR:
        draft.badgeForm.validation.badgeName = action.payload;
        return draft;

      case TAGS_BADGES_LIST_ACTIONS.BADGE_TEXT_VALIDATION_ERROR:
        draft.badgeForm.validation.badgeText = action.payload;
        return draft;

      case TAGS_BADGES_LIST_ACTIONS.REQUEST_DELETE_BADGE:
        draft.deleteBadgeRequested = true;
        draft.badgeToDelete = action.payload;
        return draft;

      case TAGS_BADGES_LIST_ACTIONS.CANCEL_REQUEST_DELETE_BADGE:
        draft.deleteBadgeRequested = false;
        draft.badgeToDelete = undefined;
        return draft;

      case TAGS_BADGES_LIST_ACTIONS.CANCEL_REQUEST_DELETE_TAG:
        draft.deleteRequested = false;
        draft.tagToDelete = undefined;
        return draft;

      default:
        return state;
    }
  });
