import React from 'react';
import { useAuth0 } from '../context/Auth0';
import { Router, Switch, Route } from 'react-router-dom';
import history from '../utils/history';
import ProtectedRoutes from './ProtectedRoutes';
import PrivateRoute from './PrivateRoute';
import SquareRedirectPage from '../companySettings/pages/SquareRedirectPage';

// pages
import LoadingPage from '../pages/LoadingPage';
import ResetPassword from '../pages/ResetPassword/ResetPassword';

const AppRouter = () => {
  const { loading: auth0Loading } = useAuth0();

  if (auth0Loading) {
    return <LoadingPage />;
  }

  return (
    <Router history={history}>
      <Switch>
        {/* public routes */}
        <Route exact path='/reset-password' component={ResetPassword} />
        <Route path='/square/auth-redirect' component={SquareRedirectPage} />

        {/* protected routes */}
        <PrivateRoute path='*' component={ProtectedRoutes} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
