import React from 'react';
import classnames, { Argument } from 'classnames';
import styles from './Cell.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import _ from 'lodash';

export type TCellData = any;

export const getCellClasses = (...classes: Array<Argument>) => classnames(...classes, styles.tableV2__cell);

export type AnyRowData = Record<string, any>;
export type CellExtraProps = Record<string, any>;

export interface BaseCallProps<TRowData extends AnyRowData = AnyRowData> {
  rowData: TRowData;
  columnName: keyof TRowData;
  classes?: Argument;
  align?: 'right' | 'left' | 'center';
}

export type CellProps<
  TRowData extends AnyRowData = AnyRowData,
  TExtraProps extends CellExtraProps = CellExtraProps
> = TExtraProps & {
  rowData: TRowData;
  columnName: keyof TRowData;
  classes?: Argument;
  align?: 'right' | 'left' | 'center';
};

export type TCell = (props: CellProps<any>) => JSX.Element;

export type TSelector = (state: RootState) => Record<string, string>;

/**
 * @deprecated use createCellWithMobileLayout instead
 */
export const getWrappedCellWithMobileLayout =
  (
    cell: TCell,
    headerColumnsProp: TSelector | Record<string, string>,
    wrapperClasses?: Argument,
    cellsClasses?: Argument
  ) =>
  ({ classes, columnName, rowData, ...restCellProps }: CellProps) => {
    const Cell = cell;
    let headerColumns: Record<string, string> = {};
    if (typeof headerColumnsProp === 'function') {
      headerColumns = useSelector(headerColumnsProp);
    } else {
      headerColumns = headerColumnsProp;
    }

    return (
      <div className={classnames(styles.tableV2__cellWrapper, classes, wrapperClasses)}>
        <span
          className={classnames(
            styles.tableV2__cellHeaderWrapped,
            styles.tableV2__cellHeader,
            cellsClasses,
            styles['tableV2__cellHeader--mobile'],
            styles['hidden--desktop'],
            styles['hidden--tablet']
          )}
        >
          {headerColumns[columnName]}
        </span>
        <Cell rowData={rowData} columnName={columnName} {...restCellProps} />
      </div>
    );
  };

/**
 * @deprecated use createCellsWithMobileLayout instead
 */
export const getWrappedCellsWithMobileLayout = (
  cells: Record<any, any>,
  headerColumnsProp: TSelector | Record<string, string>,
  wrapperClasses?: Argument,
  cellsClasses?: Argument
) => {
  return Object.entries(cells).reduce((accm, [key, cell]) => {
    return {
      ...accm,
      [key]: getWrappedCellWithMobileLayout(cell, headerColumnsProp, wrapperClasses, cellsClasses),
    };
  }, {});
};

export const createCellWithMobileLayout =
  (
    cell: TCell,
    selector: TSelector | Record<string, string>,
    {
      mobileWrapperClasses,
      titleClasses,
      cellClasses,
    }: { mobileWrapperClasses?: Argument; titleClasses?: Argument; cellClasses?: Argument }
  ) =>
  ({ classes, columnName, rowData, handler }: CellProps) => {
    const Cell = cell;
    let headerColumns: Record<string, string> = {};
    if (typeof selector === 'function') {
      headerColumns = useSelector(selector);
    } else {
      headerColumns = selector;
    }

    return (
      <>
        <div
          className={classnames(
            styles.tableV2__cellWrapper,
            mobileWrapperClasses,
            styles['hidden--desktop'],
            styles['hidden--tablet']
          )}
        >
          <span
            className={classnames(
              styles.tableV2__cellHeaderWrapped,
              styles.tableV2__cellHeader,
              titleClasses,
              styles['tableV2__cellHeader--mobile']
            )}
          >
            {headerColumns[columnName]}
          </span>
          <Cell rowData={rowData} columnName={columnName} classes={classnames(classes, cellClasses)} />
        </div>
        <Cell
          rowData={rowData}
          columnName={columnName}
          classes={classnames(classes, styles['hidden--mobile'], styles.tableV2__cellWrapper, cellClasses)}
          handler={handler}
        />
      </>
    );
  };

export const createCellsWithMobileLayout = (
  cells: Record<any, any>,
  selector: TSelector | Record<string, string>,
  classes: { mobileWrapperClasses?: Argument; titleClasses?: Argument; cellClasses?: Argument }
) => {
  return Object.entries(cells).reduce((accm, [key, cell]) => {
    return { ...accm, [key]: createCellWithMobileLayout(cell, selector, classes) };
  }, {});
};

export const passPropsToCell = (cell: TCell, cellProps?: Partial<CellProps>) => {
  const Cell = cell;
  return (props: CellProps) => <Cell {..._.merge(cellProps, props)} />;
};
