import React from 'react';
import Modal, { ModalProps } from './Modal';
import ModalHeader from './ModalHeader';
import ModalBody from './ModalBody';

export interface ListModalProps extends Pick<ModalProps, 'setShowModal'> {
  headerLabel?: string;
  headerWarning?: string;
  subHeader?: React.ReactNode;
  subLabel?: string;
  showDismissIcon?: boolean;
  showDismissButton?: boolean;
  showSeparator?: boolean;
  children: React.ReactNode;
  searchControl?: (JSX.Element | null)[] | JSX.Element | null;
  headerControl?: (JSX.Element | null)[] | JSX.Element | null;
  footerControl?: JSX.Element | null;
  additionalBodyStyles?: string;
}

const ListModal = (props: ListModalProps) => {
  const {
    setShowModal,
    headerLabel,
    headerWarning,
    subHeader,
    subLabel,
    showDismissIcon = false,
    showDismissButton = false,
    showSeparator = false,
    children,
    searchControl,
    headerControl,
    footerControl = null,
    additionalBodyStyles,
  } = props;

  return (
    <Modal setShowModal={setShowModal}>
      {headerLabel ? (
        <ModalHeader
          headerLabel={headerLabel}
          headerWarning={headerWarning}
          setShowModal={setShowModal}
          showDismissIcon={showDismissIcon}
          showDismissButton={showDismissButton}
          subLabel={subLabel}
          subHeader={subHeader}
        />
      ) : null}
      {headerControl ? <div className='modal__header'>{headerControl}</div> : null}
      {searchControl ? <div className='modal__search-panel'>{searchControl}</div> : null}
      <ModalBody withSeparator={showSeparator} additionalStyles={additionalBodyStyles}>
        {children}
      </ModalBody>
      {footerControl}
    </Modal>
  );
};

export default ListModal;
