import { OrderSmsMessageSource, ISmsNotificationsConfig, ISmsMessage } from '@ready/dashboardv2api.contracts';

export interface IOrderSmsMessagesValidation {
  orderPlaced: {
    hasError: boolean;
    errorMessage: string;
  };
  orderInProgress: {
    hasError: boolean;
    errorMessage: string;
  };
  orderReady: {
    hasError: boolean;
    errorMessage: string;
  };
  orderCancelled: {
    hasError: boolean;
    errorMessage: string;
  };
  pickUpTimeUpdated: {
    hasError: boolean;
    errorMessage: string;
  };
}
export interface IOrderSmsMessages {
  isModalOpen: boolean;
  isEditMode: boolean;
  formProcessing: boolean;
  loading: boolean;
  validation: IOrderSmsMessagesValidation;
  validated: boolean;
  orderSmsMessages: ISmsNotificationsConfig;
  cachedOrderSmsMessages: ISmsNotificationsConfig;
}

export interface IOrderSmsMessagesValidationResult {
  errorsFound: boolean;
  config: IOrderSmsMessagesValidation;
}

export const newConfigValidation = (): IOrderSmsMessagesValidation => ({
  orderPlaced: {
    hasError: false,
    errorMessage: '',
  },
  orderInProgress: {
    hasError: false,
    errorMessage: '',
  },
  orderReady: {
    hasError: false,
    errorMessage: '',
  },
  orderCancelled: {
    hasError: false,
    errorMessage: '',
  },
  pickUpTimeUpdated: {
    hasError: false,
    errorMessage: '',
  },
});

export const orderSmsDefaultConfig: ISmsMessage = {
  isActive: false,
  value: '',
};

export const orderSmsMessagesConfigDefaultValues: ISmsNotificationsConfig = {
  isEnabled: false,
  messageSource: OrderSmsMessageSource.Company,
  companyConfig: {},
  locationConfig: {},
};
export const orderSmsMessagesInitialState: IOrderSmsMessages = {
  isModalOpen: false,
  isEditMode: false,
  formProcessing: false,
  loading: false,
  validation: newConfigValidation(),
  validated: false,
  orderSmsMessages: orderSmsMessagesConfigDefaultValues,
  cachedOrderSmsMessages: orderSmsMessagesConfigDefaultValues,
};
