import produce from 'immer';
import { Action } from '../../../redux/types';
import { companyLocations } from '../../initialStates/companyLocations/companyLocations';
import { COMPANY_LOCATIONS_ACTIONS } from '../../actions/companyLocations/types';
import InfiniteScrollingListStateHelper from '../../../utils/InfiniteScrollingList/InfiniteScrollingListStateHelper';

export const companyLocationsReducer = (state = companyLocations, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case COMPANY_LOCATIONS_ACTIONS.PREPARE_COMPANY_LOCATIONS_MODAL:
        InfiniteScrollingListStateHelper.prepareState(draft, action.payload.visible);
        if (action.payload.permissionIndex !== undefined) {
          draft.permissionIndex = action.payload.permissionIndex;
        }
        return draft;

      case COMPANY_LOCATIONS_ACTIONS.SET_COMPANY_LOCATIONS_QUERY:
        InfiniteScrollingListStateHelper.filterChangedState(draft, action.payload);
        return draft;

      case COMPANY_LOCATIONS_ACTIONS.LOAD_COMPANY_LOCATIONS_BEGIN:
        InfiniteScrollingListStateHelper.loadingState(draft);
        return draft;

      case COMPANY_LOCATIONS_ACTIONS.LOAD_COMPANY_LOCATIONS_SUCCESS:
        InfiniteScrollingListStateHelper.loadedState(draft, action.payload);
        return draft;

      case COMPANY_LOCATIONS_ACTIONS.LOAD_COMPANY_LOCATIONS_ERROR:
        InfiniteScrollingListStateHelper.loadFailedState(draft);
        return draft;

      case COMPANY_LOCATIONS_ACTIONS.LOAD_MORE_COMPANY_LOCATIONS_BEGIN:
        InfiniteScrollingListStateHelper.loadingMoreState(draft);
        return draft;

      case COMPANY_LOCATIONS_ACTIONS.LOAD_MORE_COMPANY_LOCATIONS_SUCCESS:
        InfiniteScrollingListStateHelper.loadedMoreState(draft, action.payload);
        return draft;

      case COMPANY_LOCATIONS_ACTIONS.LOAD_MORE_COMPANY_LOCATIONS_NO_RESULTS:
        InfiniteScrollingListStateHelper.loadMoreNoItemsState(draft);
        return draft;

      case COMPANY_LOCATIONS_ACTIONS.LOAD_MORE_COMPANY_LOCATIONS_ERROR:
        InfiniteScrollingListStateHelper.loadMoreFailedState(draft);
        return draft;

      default:
        return draft;
    }
  });
};
