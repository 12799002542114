import React from 'react';
import LayoutContent from '../../components/AppLayout/LayoutContent';
import InfoCardList from '../../components/InfoCard/InfoCardList';
import { Link, useParams } from 'react-router-dom';
import InfoCard from '../../components/InfoCard/InfoCard';
import { AppState } from '../../redux/initialStates/AppState';
import { connect, ConnectedProps } from 'react-redux';
import EmptyResult from '../../components/EmptyResult/EmptyResult';
import {
  ToolbarContent,
  ContactColumn,
  StackableTimeAndStatusColumns,
  StackableItemsAndTotalColumns,
  ToolbarContentTab,
  EmptySearchResults,
  OrdersOrderToggle,
  OrderSettingsDialog,
} from './components';
import useResponsiveBreakpoint, { ResponsiveBreakpoint } from '../../hooks/useResponsiveBreakpoint';
import styles from './CompletedOrdersListPage.module.scss';
import { setLocationListUrlQuery, setOrderStatus } from '../../redux/actions/orders/orderListActions';
import { useSearchParams } from '../../hooks/useSearchParams';
import filterOrders from '../../utils/orderUtils/filterOrders';
import LoadingPage from '../LoadingPage';
import { RouteStatus } from './components/ToolbarContent';
import LocationSelectorLink from '../../components/AppLayout/LocationSelectorLink';
import { ContextParams } from '../../types/ContextParams.interface';
import { OrderFulfillmentStatus } from '@ready/dashboardv2api.contracts';
import { OrderSection } from '../../enums/orders.enum';
import IconButton from '../../components/IconButton/IconButton';
import TextIcon, { Icon } from '../../components/Icon/TextIcon';
import { setOrderSettingsDialogOpen } from '../../redux/actions/orders/locationOrderSettingsActions';
import RoundIcon from '../../components/RoundIcon/RoundIcon';
import { getOrderExperienceType } from '../../utils/orderUtils';
import { getOrderHasRefund } from '../../utils/orderUtils/getOrderRefund';

interface widths {
  contactColumnWidth: number;
  itemsColumnWidth: number;
  totalColumnWidth: number;
  timeColumnWidth: number;
  statusColumnWidth?: number;
}

const getWidths = (breakpoint: ResponsiveBreakpoint): widths => {
  switch (breakpoint) {
    case ResponsiveBreakpoint.Mobile:
      return {
        contactColumnWidth: 100,
        itemsColumnWidth: 0,
        totalColumnWidth: 0,
        timeColumnWidth: 0,
      };

    case ResponsiveBreakpoint.Tablet:
      return {
        contactColumnWidth: 35,
        itemsColumnWidth: 15,
        totalColumnWidth: 15,
        timeColumnWidth: 0,
      };

    case ResponsiveBreakpoint.Desktop:
      return {
        contactColumnWidth: 30,
        itemsColumnWidth: 12,
        totalColumnWidth: 12,
        timeColumnWidth: 12,
        statusColumnWidth: 24,
      };
  }
};

interface PageParams extends ContextParams {
  day: string;
}

const CompletedOrdersListPage = (props: ReduxProps) => {
  const {
    completedOrders,
    loadingOrders,
    completedOrdersCount,
    upNextOrdersCount,
    offsetOrdersCount,
    overdueOrdersCount,
    cancelledOrdersCount,
    dineInOrdersCount,
    locationDetails,
    setLocationListUrlQuery,
    setOrderStatus,
    setOrderSettingsDialogOpen,
  } = props;

  const { contextId, locationId, day } = useParams<PageParams>();
  const { breakpoint, isMobile, isTablet } = useResponsiveBreakpoint();
  const { query } = useSearchParams();

  const searchTerm = decodeURIComponent(query || '');
  const filteredCompletedOrders = filterOrders(completedOrders, searchTerm);

  React.useEffect(() => {
    setLocationListUrlQuery({ day, status: RouteStatus.Complete, query: query || '' });
  }, [setLocationListUrlQuery, day, query]);

  const locationName = locationDetails && locationDetails.name ? locationDetails.name : '';
  const upcomingOrdersCount = upNextOrdersCount + offsetOrdersCount + overdueOrdersCount + dineInOrdersCount;
  const showEmptyResult = completedOrdersCount === 0;
  const widths = getWidths(breakpoint);

  const handleOnClickOrderSettings = React.useCallback(() => {
    setOrderSettingsDialogOpen(true);
  }, [setOrderSettingsDialogOpen]);

  return (
    <LayoutContent
      title='Orders'
      containerType='within'
      showSubHeaderDivider={false}
      darkBackground
      titleControls={<LocationSelectorLink text={locationName} to={`/companies/${contextId}/orders`} />}
      subHeader={<OrdersOrderToggle />}
      additionalSubHeaderControls={
        <IconButton onClick={handleOnClickOrderSettings}>
          <TextIcon icon={Icon.Cog} additionalStyles={styles.settingsIcon} />
        </IconButton>
      }
    >
      <OrderSettingsDialog />
      {loadingOrders ? (
        <LoadingPage />
      ) : (
        <div className={styles.listContainer}>
          <div className={styles.toolbarContainer}>
            <ToolbarContent
              upcomingOrders={upcomingOrdersCount}
              completeOrders={completedOrdersCount}
              cancelledOrders={cancelledOrdersCount}
              companyId={contextId}
              day={day}
              locationId={locationId}
              selectedTab={ToolbarContentTab.Complete}
            />
          </div>
          {filteredCompletedOrders && (
            <InfoCardList>
              {filteredCompletedOrders.length === 0 && completedOrdersCount > 0 ? (
                <EmptySearchResults />
              ) : (
                filteredCompletedOrders.map((order) => {
                  const handleOverdueOrderStatusChange = (newStatus: OrderFulfillmentStatus) => {
                    setOrderStatus(
                      contextId,
                      locationId,
                      newStatus,
                      OrderSection.COMPLETE,
                      order.orderId,
                      order.invoiceId
                    );
                  };
                  const orderExperienceType = getOrderExperienceType(order.tableInfo?.orderExperienceType);
                  const orderTip = order.tip ? order.tip : 0;

                  return (
                    <Link
                      to={`/companies/${contextId}/locations/${locationId}/orders/${order.orderId}`}
                      key={order.orderId}
                    >
                      <InfoCard>
                        <RoundIcon icon={orderExperienceType.icon} additionalStyles={styles.icon} />
                        <ContactColumn
                          customerDetails={order.identityInfo}
                          tableInfo={order.tableInfo}
                          width={widths.contactColumnWidth}
                          additionalStyles={styles.column}
                        />
                        <StackableItemsAndTotalColumns
                          items={order.itemCount}
                          itemsColumnWidth={widths.itemsColumnWidth}
                          total={order.priceExcludingTip + orderTip}
                          subtotal={order.priceExcludingTip}
                          tip={orderTip}
                          hasRefund={getOrderHasRefund(order.refundInfo)}
                          totalColumnWidth={widths.totalColumnWidth}
                          stacked={isTablet}
                          hidden={isMobile}
                          additionalStyles={styles.column}
                          orderExperienceType={order.tableInfo?.orderExperienceType}
                          hasInvoice={!!order.invoiceId}
                        />
                        <StackableTimeAndStatusColumns
                          time={new Date(order.pickupTime)}
                          timeColumnWidth={widths.timeColumnWidth}
                          stacked={isMobile || isTablet}
                          additionalStyles={styles.column}
                          handleOrderStatusChange={handleOverdueOrderStatusChange}
                          orderStatusColumnWidth={widths.statusColumnWidth}
                          isProcessing={order.processing}
                          orderStatus={order.status}
                        />
                      </InfoCard>
                    </Link>
                  );
                })
              )}
            </InfoCardList>
          )}

          {showEmptyResult && <EmptyResult title='No Completed Orders' />}
        </div>
      )}
    </LayoutContent>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    completedOrders: state.orders.orderList.completedOrders,
    completedOrdersCount: state.orders.orderList.completedOrdersCount,
    upNextOrdersCount: state.orders.orderList.upNextOrdersCount,
    offsetOrdersCount: state.orders.orderList.offsetOrdersCount,
    overdueOrdersCount: state.orders.orderList.overdueOrdersCount,
    cancelledOrdersCount: state.orders.orderList.cancelledOrdersCount,
    dineInOrdersCount: state.orders.orderList.dineInOrdersCount,
    loadingOrders: state.orders.orderList.loadingOrders,
    locationDetails: state.orders.orderList.locationDetails,
  };
};

const actionCreators = {
  setLocationListUrlQuery,
  setOrderStatus,
  setOrderSettingsDialogOpen,
};

const connector = connect(mapStateToProps, actionCreators);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(CompletedOrdersListPage);
