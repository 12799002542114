import React from 'react';
import { LOCATION_SETTINGS_ACTIONS } from '../../redux/LocationSettingsActions';
// components
import { IPaymentProcessingPanel } from '../../redux/PanelPaymentProcessingState';
import { LIST_OPTIONS } from '../../../utils/selectListUtils/listOptions';
import { FormControl, FormControlStacked } from '../../../components/Form';
import TextInput from '../../../components/TextInput/TextInput';
import SelectFilter from '../../../components/SelectFilter/SelectFilter';
import mapSelectOption from '../../../utils/selectListUtils/mapSelectOption';
import Checkbox from '../../../components/Checkbox/Checkbox';
import TextInputNestedLabel from '../../../components/TextInputNestedLabel/TextInputNestedLabel';
import FormattedInput from '../../../components/FormattedInput/FormattedInput';
import { toNumber } from '../../../utils/numberUtils/toNumber';
import { IUpdateLocationParam } from '../LocationsEditPage';
import Toggle from 'components/Toggle/Toggle';

interface Props {
  isProcessing: boolean;
  updateLocationParam: IUpdateLocationParam;
  paymentProcessingPanel: IPaymentProcessingPanel;
}

const RealexPP = (props: Props) => {
  const { isProcessing, updateLocationParam, paymentProcessingPanel } = props;

  return (
    <>
      <FormControl label='Merchant ID'>
        <FormControlStacked>
          <TextInput
            placeholder='Enter Merchant ID'
            loading={isProcessing}
            value={paymentProcessingPanel.realex.merchantId}
            onChange={(event) =>
              updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_MERCHANT_ID)
            }
          />
        </FormControlStacked>
      </FormControl>
      <FormControl label='Account ID'>
        <FormControlStacked>
          <TextInput
            placeholder='Enter Account ID'
            loading={isProcessing}
            value={paymentProcessingPanel.realex.accountId}
            onChange={(event) =>
              updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_ACCOUNT_ID)
            }
          />
        </FormControlStacked>
      </FormControl>
      <FormControl label='Shared Secret'>
        <FormControlStacked>
          <TextInput
            placeholder='Enter Shared Secret'
            loading={isProcessing}
            value={paymentProcessingPanel.realex.sharedSecret}
            onChange={(event) =>
              updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_SHARED_SECRET)
            }
          />
        </FormControlStacked>
      </FormControl>
      <FormControl label='Rebate Password'>
        <FormControlStacked>
          <TextInput
            placeholder='Enter Rebate Password'
            loading={isProcessing}
            value={paymentProcessingPanel.realex.rebatePassword}
            onChange={(event) =>
              updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_REBATE_PASSWORD)
            }
          />
        </FormControlStacked>
      </FormControl>
      <FormControl label='Refund Password'>
        <FormControlStacked>
          <TextInput
            placeholder='Enter Refund Password'
            loading={isProcessing}
            value={paymentProcessingPanel.realex.refundPassword}
            onChange={(event) =>
              updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_REFUND_PASSWORD)
            }
          />
        </FormControlStacked>
      </FormControl>
      <FormControl label='Payment Methods'>
        <FormControlStacked>
          <Checkbox
            label='Credit Card'
            loading={isProcessing}
            checked={paymentProcessingPanel.realex.paymentMethod.creditCard}
            onChange={(event) =>
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_PAYMENT_METHOD_CREDIT_CARD
              )
            }
          />
        </FormControlStacked>
        {paymentProcessingPanel.realex.paymentMethod.creditCard && (
          <FormControlStacked indented>
            <Checkbox
              label='Suppress 3DS address collection fields'
              labelTooltip='3DS must be enabled for this setting to apply. You can enable 3DS through your payment processor dashboard.'
              loading={isProcessing}
              checked={paymentProcessingPanel.realex.suppressCreditCard3DSAddress}
              onChange={(event) =>
                updateLocationParam(
                  event.target.checked,
                  LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_PAYMENT_METHOD_CREDIT_CARD_SUPPRESS_3DS
                )
              }
            />
          </FormControlStacked>
        )}
        <FormControlStacked>
          <Checkbox
            label='Apple Pay'
            loading={isProcessing}
            checked={paymentProcessingPanel.realex.paymentMethod.applePay}
            onChange={(event) =>
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_PAYMENT_METHOD_APPLE_PAY
              )
            }
          />
        </FormControlStacked>
        <FormControlStacked>
          <Checkbox
            label='Google Pay'
            loading={isProcessing}
            checked={paymentProcessingPanel.realex.paymentMethod.googlePay}
            onChange={(event) =>
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_PAYMENT_METHOD_GOOGLE_PAY
              )
            }
          />
        </FormControlStacked>
      </FormControl>
      <FormControl label='Apple Pay Settings'>
        <FormControlStacked>
          <TextInputNestedLabel
            label='Merchant ID'
            loading={isProcessing}
            value={paymentProcessingPanel.realex.applePay.merchantId}
            onChange={(event) =>
              updateLocationParam(
                event.target.value,
                LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_APPLE_SETTINGS_MERCHANT_ID
              )
            }
          />
        </FormControlStacked>
        <FormControlStacked>
          <Checkbox
            label='Supports 3DS'
            loading={isProcessing}
            checked={paymentProcessingPanel.realex.applePay.supports3DS}
            onChange={(event) =>
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_APPLE_SETTINGS_SUPPORTS_3DS
              )
            }
          />
        </FormControlStacked>
        <FormControlStacked>
          <Checkbox
            label='Supports Debit'
            loading={isProcessing}
            checked={paymentProcessingPanel.realex.applePay.supportsDebit}
            onChange={(event) =>
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_APPLE_SETTINGS_SUPPORTS_DEBIT
              )
            }
          />
        </FormControlStacked>
        <FormControlStacked>
          <Checkbox
            label='Supports Credit'
            loading={isProcessing}
            checked={paymentProcessingPanel.realex.applePay.supportsCredit}
            onChange={(event) =>
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_APPLE_SETTINGS_SUPPORTS_CREDIT
              )
            }
          />
        </FormControlStacked>
      </FormControl>
      <FormControl label='Google Pay Settings'>
        <FormControlStacked>
          <TextInputNestedLabel
            label='Gateway Merchant ID'
            loading={isProcessing}
            value={paymentProcessingPanel.realex.googlePay.gatewayMerchantId}
            onChange={(event) =>
              updateLocationParam(
                event.target.value,
                LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_GOOGLE_SETTINGS_GATEWAY_MERCHANT_ID
              )
            }
          />
        </FormControlStacked>
      </FormControl>
      <FormControl>
        <FormControlStacked>
          <TextInputNestedLabel
            label='Gateway ID'
            loading={isProcessing}
            value={paymentProcessingPanel.realex.googlePay.gatewayId}
            onChange={(event) =>
              updateLocationParam(
                event.target.value,
                LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_GOOGLE_SETTINGS_GATEWAY_ID
              )
            }
          />
        </FormControlStacked>
      </FormControl>
      <FormControl>
        <FormControlStacked>
          <TextInputNestedLabel
            label='Merchant ID'
            loading={isProcessing}
            value={paymentProcessingPanel.realex.googlePay.merchantId}
            onChange={(event) =>
              updateLocationParam(
                event.target.value,
                LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_GOOGLE_SETTINGS_MERCHANT_ID
              )
            }
          />
        </FormControlStacked>
      </FormControl>
      <FormControl>
        <FormControlStacked>
          <TextInputNestedLabel
            label='Merchant Name'
            loading={isProcessing}
            value={paymentProcessingPanel.realex.googlePay.merchantName}
            onChange={(event) =>
              updateLocationParam(
                event.target.value,
                LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_GOOGLE_SETTINGS_MERCHANT_NAME
              )
            }
          />
        </FormControlStacked>
      </FormControl>
      <FormControl>
        <FormControlStacked>
          <SelectFilter
            placeholder='Select an Environment'
            options={LIST_OPTIONS.paymentEnvironment}
            loading={isProcessing}
            value={mapSelectOption(
              LIST_OPTIONS.paymentEnvironment,
              paymentProcessingPanel.realex.googlePay.environment
            )}
            onChange={(option) =>
              updateLocationParam(option.value, LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_GOOGLE_SETTINGS_ENVIRONMENT)
            }
            isSearchable={true}
          />
        </FormControlStacked>
      </FormControl>
      <FormControl>
        <FormControlStacked>
          <Checkbox
            label='Cryptogram3DS (supports 3DS)'
            loading={isProcessing}
            checked={paymentProcessingPanel.realex.googlePay.supports3DS}
            onChange={(event) =>
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_GOOGLE_SETTINGS_SUPPORTS_3DS
              )
            }
          />
        </FormControlStacked>
        <FormControlStacked>
          <Checkbox
            label='PAN Only (supports credit)'
            loading={isProcessing}
            checked={paymentProcessingPanel.realex.googlePay.supportsCredit}
            onChange={(event) =>
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_GOOGLE_SETTINGS_SUPPORTS_CREDIT
              )
            }
          />
        </FormControlStacked>
      </FormControl>
      <FormControl label='Apple Pay / Google Pay Networks'>
        <FormControlStacked>
          <Checkbox
            label='Mastercard'
            loading={isProcessing}
            checked={paymentProcessingPanel.realex.appleGoogleNetwork.mastercard}
            onChange={(event) =>
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_APPLE_GOOGLE_NETWORK_MASTERCARD
              )
            }
          />
        </FormControlStacked>
        <FormControlStacked>
          <Checkbox
            label='Visa'
            loading={isProcessing}
            checked={paymentProcessingPanel.realex.appleGoogleNetwork.visa}
            onChange={(event) =>
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_APPLE_GOOGLE_NETWORK_VISA
              )
            }
          />
        </FormControlStacked>
        <FormControlStacked>
          <Checkbox
            label='Amex'
            loading={isProcessing}
            checked={paymentProcessingPanel.realex.appleGoogleNetwork.amex}
            onChange={(event) =>
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_APPLE_GOOGLE_NETWORK_AMEX
              )
            }
          />
        </FormControlStacked>
        <FormControlStacked>
          <Checkbox
            label='Discover'
            loading={isProcessing}
            checked={paymentProcessingPanel.realex.appleGoogleNetwork.discover}
            onChange={(event) =>
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_APPLE_GOOGLE_NETWORK_DISCOVER
              )
            }
          />
        </FormControlStacked>
        <FormControlStacked>
          <Checkbox
            label='JCB'
            loading={isProcessing}
            checked={paymentProcessingPanel.realex.appleGoogleNetwork.jcb}
            onChange={(event) =>
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_APPLE_GOOGLE_NETWORK_JCB
              )
            }
          />
        </FormControlStacked>
      </FormControl>
      <FormControl label='Rate Limiting'>
        <FormControlStacked>
          <Toggle
            checked={paymentProcessingPanel.realex.rateLimits.enabled}
            onChange={(checked) => {
              updateLocationParam(
                checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_RATE_LIMITING_TOGGLE
              );
            }}
            loading={isProcessing}
          />
        </FormControlStacked>
        <FormControlStacked>
          <Checkbox
            disabled={true}
            checked={true}
            label='Enforce rate limits on manual card payments'
            loading={isProcessing}
          />
        </FormControlStacked>
        <FormControlStacked>
          <Checkbox
            disabled={!paymentProcessingPanel.realex.rateLimits.enabled}
            checked={paymentProcessingPanel.realex.rateLimits.enforceRateLimitsForGooglePay}
            label='Enforce rate limits on Google Pay transactions'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_RATE_LIMITING_FOR_GOOGLE_PAY
              );
            }}
            loading={isProcessing}
          />
        </FormControlStacked>
        <FormControlStacked>
          <FormattedInput
            format='money'
            disabled={!paymentProcessingPanel.realex.rateLimits.enabled}
            value={paymentProcessingPanel.realex.rateLimits.amount}
            onChange={(event) => {
              updateLocationParam(
                toNumber(event.target.value),
                LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_RATE_LIMITING_AMOUNT
              );
            }}
            loading={isProcessing}
          />
          <span className='form__control__separator'>Per</span>
          <FormattedInput
            format='min'
            disabled={!paymentProcessingPanel.realex.rateLimits.enabled}
            value={paymentProcessingPanel.realex.rateLimits.duration / 60}
            onChange={(event) => {
              updateLocationParam(
                toNumber(event.target.value) * 60,
                LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_RATE_LIMITING_TIME_INTERVAL
              );
            }}
            loading={isProcessing}
          />
        </FormControlStacked>
      </FormControl>
      <FormControl label='Additional Settings'>
        <FormControlStacked>
          <Checkbox
            label='Allow small transactions'
            loading={isProcessing}
            checked={paymentProcessingPanel.realex.allowSmallTransactions}
            onChange={(event) =>
              updateLocationParam(event.target.checked, LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_SMALL_TRANSACTIONS)
            }
          />
        </FormControlStacked>
      </FormControl>
    </>
  );
};

export default RealexPP;
