import { connect } from 'react-redux';
import { AppState } from '../../../redux/initialStates/AppState';
import { OrderToggle, OrderToggleStateProps, OrderToggleActionProps } from '../../../components/Orders';
import { toggleOrdering, toggleAlcoholOrdering } from '../../../redux/actions/orders/orderListActions';

const mapStateToProps = (state: AppState): OrderToggleStateProps => {
  const orderList = state.orders.orderList;

  return {
    orderingEnabled: !!orderList.locationDetails?.settings.orderingEnabled || false,
    orderingEnabledProcessing: orderList.orderingEnabledProcessing,
    alcoholOrderingEnabled: !!orderList.locationDetails?.settings.alcoholOrderingEnabled || false,
    alcoholOrderingEnabledProcessing: orderList.alcoholOrderingEnabledProcessing,
    loading: orderList.locationDetailsLoading,
  };
};

const actionCreators: OrderToggleActionProps = {
  onOrderingEnabledChange: toggleOrdering,
  onAlcoholOrderingEnabledChange: toggleAlcoholOrdering,
};

export default connect(mapStateToProps, actionCreators)(OrderToggle);
