import { DropDownButtonOption } from 'components/DropDownButton/DropDownButton';
import OverflowButton from 'components/OverflowButton/OverflowButton';
import React, { ChangeEvent, useState } from 'react';
import { PageControls } from 'sharedMenuItems/components/PageControls';
import styles from './ItemGroupListPageControls.module.scss';
import Button from 'components/Button/Button';
import { useHistory, useParams } from 'react-router-dom';
import { SharedMenuItemsRoutes } from 'sharedMenuItems/Router';
import { ContextParams } from 'types/ContextParams.interface';
import { LocationFilter } from 'sharedMenuItems/components/filters/locationFilter/LocationFilter';
import { ILocationFilterParams, useLocationFilter } from 'hooks/useLocationFilter';
import SearchInput from 'components/SearchInput/SearchInput';
import { updateQueryParamsDebounced } from 'utils/updatePath';

interface Props {
  initialSearchFilter?: string;
  initialLocationFilter?: ILocationFilterParams;
}

const ItemGroupListPageControls = ({ initialSearchFilter, initialLocationFilter }: Props) => {
  const { push } = useHistory();
  const { contextId: companyId } = useParams<ContextParams>();

  const [searchInputFilter, setSearchInputFilter] = useState<string>(initialSearchFilter ?? '');
  const [locationFilter, setLocationFilter] = useState<ILocationFilterParams | undefined>(
    initialLocationFilter ?? undefined
  );

  const route = SharedMenuItemsRoutes.getItemGroupListRoute(companyId);
  const overflowButtonOptions: DropDownButtonOption[] = [
    {
      label: '+ New Item Group',
      disabled: false,
      onClick: () => {
        push(SharedMenuItemsRoutes.getItemGroupDetailsRoute(companyId));
      },
    },
  ];

  useLocationFilter(route, locationFilter);

  const onSearchTermChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchInputFilter(event.currentTarget.value);
    updateQueryParamsDebounced(route, { searchTerm: event.currentTarget.value, page: '' }, push);
  };

  return (
    <PageControls>
      <div className={styles.searchFilter}>
        <SearchInput
          placeholder='Search item groups'
          additionalClassName='white search-input-full-width'
          value={searchInputFilter}
          onChange={onSearchTermChange}
        />
        <OverflowButton className={styles.mobileOverflowButton} options={overflowButtonOptions}></OverflowButton>
      </div>

      <LocationFilter
        onLocationSet={(locationFilter?: ILocationFilterParams) => setLocationFilter(locationFilter)}
        initial={locationFilter}
      />
      <div className={styles.buttons}>
        <Button
          label='+ New Item Group'
          onClick={() => {
            push(SharedMenuItemsRoutes.getItemGroupDetailsRoute(companyId));
          }}
        />
      </div>
      <OverflowButton options={overflowButtonOptions} className={styles.tabletOverflowButton}></OverflowButton>
    </PageControls>
  );
};

export { ItemGroupListPageControls };
