import React, { useEffect, useState } from 'react';
import ListModal from 'components/Modal/ListModal';
import InfiniteScrollingList from 'components/InfiniteScrollingList/InfiniteScrollingList';
import styles from './AddMenuItemsDialog.module.scss';
import SearchInput from 'components/SearchInput/SearchInput';
import { RootState, useAppDispatch, useAppSelector } from 'redux/store';
import { ITemplateMenuItem, WithAssignedLocationsCount } from '@ready/menu.core';

import { useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { ContextParams } from 'types/ContextParams.interface';
import { getSharedMenuItemsForDialogThunk } from './redux/thunks';
import ToggleButtons from 'components/ToggleButtons/ToggleButtons';
import { select, deselect, prepare } from './redux/slice';
import { ItemWithAssignedLocations } from 'sharedMenuItems/pages/createEditItem/components/itemWithAssignedLocations/ItemWithAssignedLocations';

export type ModalTypeFilters = 'items' | 'options';
interface Props {
  setShowModal: (isVisible: boolean) => void;
  selectedIds: string[];
  onRemove: (itemId: string) => void;
  onAdd: (item: WithAssignedLocationsCount<ITemplateMenuItem>) => void;
  headerLabel?: string;
  initialTypeFilter?: ModalTypeFilters;
  isSingleFilterModal?: boolean;
}

export const AddMenuItemsDialog = ({
  headerLabel,
  setShowModal,
  selectedIds,
  onRemove,
  onAdd,
  initialTypeFilter,
  isSingleFilterModal = false,
}: Props): JSX.Element => {
  const { contextId: companyId } = useParams<ContextParams>();
  const [typeFilter, setTypeFilter] = useState<ModalTypeFilters>(initialTypeFilter ?? 'options');
  const [query, setQuery] = useState<string>('');

  const dispatch = useAppDispatch();
  const {
    page,
    loading,
    paginationAvailable,
    paginationLoading,
    filtered,
    error,
    items,
    selectedIds: stateSelectedIds,
  } = useAppSelector((state: RootState) => state.sharedMenuItems.itemsAndModsDialog);

  const getItemsDebounced = React.useMemo(
    () =>
      debounce((searchTerm) => {
        dispatch(
          getSharedMenuItemsForDialogThunk({
            isLoadMore: false,
            companyId,
            searchTerm: searchTerm,
            type: initialTypeFilter ?? typeFilter,
          })
        );
      }, 500),
    [companyId, dispatch, initialTypeFilter, typeFilter]
  );

  const loadItems = React.useCallback(() => {
    dispatch(getSharedMenuItemsForDialogThunk({ isLoadMore: false, companyId, type: initialTypeFilter ?? typeFilter }));
  }, [companyId, dispatch, initialTypeFilter, typeFilter]);

  const loadMore = React.useCallback(() => {
    dispatch(
      getSharedMenuItemsForDialogThunk({
        isLoadMore: true,
        companyId,
        searchTerm: query,
        page: page + 1,
        type: initialTypeFilter ?? typeFilter,
      })
    );
  }, [companyId, dispatch, initialTypeFilter, page, query, typeFilter]);

  const searchPlaceholder = (initialTypeFilter ?? typeFilter) === 'options' ? 'modifier' : 'menu items';

  useEffect(() => {
    dispatch(prepare(selectedIds));
    // only load up the the selectedIds when rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ListModal
      additionalBodyStyles={items.length > 0 ? styles.modalBody : undefined}
      setShowModal={setShowModal}
      headerLabel={headerLabel}
      showDismissButton
      showSeparator={!isSingleFilterModal}
      searchControl={
        <>
          <SearchInput
            value={query}
            placeholder={`Search ${searchPlaceholder}`}
            fullWidth
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setQuery(e.target.value);
              getItemsDebounced(e.target.value);
            }}
            autoFocus={true}
          />
          {isSingleFilterModal ? null : (
            <div className={styles.toggles}>
              <ToggleButtons
                buttons={[
                  {
                    label: 'Modifiers',
                    selected: (initialTypeFilter ?? typeFilter) === 'options',
                    onClick: () => setTypeFilter('options'),
                  },
                  {
                    label: 'Menu Items',
                    selected: (initialTypeFilter ?? typeFilter) === 'items',
                    onClick: () => setTypeFilter('items'),
                  },
                ]}
              />
            </div>
          )}
        </>
      }
      children={
        <>
          <InfiniteScrollingList
            loading={loading}
            error={error}
            paginationLoading={paginationLoading}
            paginationAvailable={paginationAvailable}
            items={items}
            filtered={filtered}
            noItemsTitle={'No Results Found'}
            noItemsMessage={'Make sure the items you are looking for have been created, or try refining your search.'}
            addButtonLabel={'Add'}
            removeButtonLabel={'Remove'}
            selectedItemKeys={stateSelectedIds}
            addItem={(item) => {
              dispatch(select(item._id));
              onAdd(item);
            }}
            removeItem={(id) => {
              dispatch(deselect(id));
              onRemove(id);
            }}
            loadItems={loadItems}
            loadMoreItems={loadMore}
            getItemKey={(item: WithAssignedLocationsCount<ITemplateMenuItem>) => item._id}
            buildItemComponent={ItemWithAssignedLocations}
            isInModal
          />
        </>
      }
    />
  );
};
