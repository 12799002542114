import { Auth0Client, User } from '@auth0/auth0-spa-js';
import React, { useContext, useState, useEffect } from 'react';
import Auth0Service from 'services/Auth0Service';
import shouldRedirectFromState from 'utils/auth0Utils/shouldRedirectFromState';

interface Auth0ClientOptions {
  domain: string;
  client_id: string;
}

interface Auth0ProviderProps {
  children: JSX.Element[] | JSX.Element;
  onRedirectCallback: (appState?: any) => void;
  [propName: string]: any;
}

export interface Auth0Response {
  isAuthenticated: boolean;
  user: User;
  loading: boolean;
  popupOpen: boolean;
  loginWithPopup: () => void;
  handleRedirectCallback: () => void;
  getIdTokenClaims: () => void;
  loginWithRedirect: (p: any) => void;
  getTokenSilently: () => any;
  getTokenWithPopup: () => void;
  logout: () => void;
}

const initialUser: User = {
  name: '',
  email: '',
  sub: '',
};

const initialState: Auth0Response = {
  isAuthenticated: false,
  user: initialUser,
  loading: false,
  popupOpen: false,
  loginWithPopup: () => {},
  handleRedirectCallback: () => {},
  getIdTokenClaims: () => {},
  loginWithRedirect: (p: any) => {},
  getTokenSilently: () => {},
  getTokenWithPopup: () => {},
  logout: () => {},
};

const ORIGIN_DOMAIN = window.location.origin;
const logoutReturnTo = {
  returnTo: ORIGIN_DOMAIN,
};
const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext(initialState);
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = (props: Auth0ProviderProps) => {
  const { children, onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, ...initOptions } = props;

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User>(initialUser);
  const [auth0Client, setAuth0] = useState<Auth0Client>();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await Auth0Service.initializeClient(initOptions as Auth0ClientOptions);
      setAuth0(auth0FromHook);

      if (
        window.location.search.includes('code=') &&
        window.location.search.includes('state=') &&
        shouldRedirectFromState()
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();
      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();

        if (user) {
          setUser(user);
        }
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    if (!auth0Client) return;
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();

    setUser({ email: user?.email });
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    if (!auth0Client) return;
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    if (user) {
      setUser(user);
    }
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client!.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client!.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client!.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client!.getTokenWithPopup(...p),
        logout: (...p) => auth0Client!.logout(logoutReturnTo, ...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
