import React, { FC } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { usePageTitle } from '../hooks';
import Integrations from './pages/Integrations';

const CompanyLocations: FC<{}> = () => {
  const { path } = useRouteMatch();
  usePageTitle('Company Settings');

  return (
    <Switch>
      <Route path={`${path}/`} component={Integrations} />
    </Switch>
  );
};

export default CompanyLocations;
