import React, { RefObject } from 'react';
import { IScheduleLink, ISchedule } from '@ready/menu.core';
import { ILink, IMenuForm } from '../../redux/MenusState';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '../../../components/Modal';
import StatusRadioGroup from './StatusRadioGroup';
import DisplayNameInput from './DisplayNameInput';
import DescriptionInput from './DescriptionInput';
import ScheduleFormControl from '../shared/ScheduleFormControl';
import styles from './MenuEntryPanel.module.scss';
import NoneValue from 'components/Value/NoneValue';

export interface MenuFormModalProps {
  setShowModal: (visible: boolean) => void;
  onChangeDisplayName: (displayName: string) => void;
  onChangeDescription: (description: string) => void;
  onChangeStatus: (status: boolean) => void;
  onSave: () => void;
  menuForm: IMenuForm;
  header: string;
  saveLabel: string;
  onChangeSchedule: (scheduler: IScheduleLink<ILink> | null) => void;
  schedules: ISchedule[];
  areSchedulesLoading: boolean;
  isSharedMenu?: boolean;
}

const MenuFormModal = (props: MenuFormModalProps): JSX.Element | null => {
  const {
    setShowModal,
    onChangeDisplayName,
    onChangeDescription,
    onChangeStatus,
    onSave,
    menuForm,
    menuForm: { processing },
    header,
    saveLabel,
    onChangeSchedule,
    schedules,
    areSchedulesLoading,
    isSharedMenu = false,
  } = props;

  const inputRef: RefObject<HTMLInputElement> = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [
    inputRef,
    menuForm.validation, // Include validation as a dependency so focus gets set on failed validation
  ]);

  return (
    <Modal loading={processing} setShowModal={setShowModal}>
      <ModalHeader headerLabel={header} setShowModal={setShowModal} />
      <ModalBody>
        <StatusRadioGroup
          value={menuForm.menu.status}
          processing={menuForm.processing}
          boldLabel
          onChange={onChangeStatus}
        />

        {isSharedMenu ? (
          <ReadonlyValue label='Menu name' value={menuForm.menu.displayName} />
        ) : (
          <DisplayNameInput
            value={menuForm.menu.displayName}
            validation={menuForm.validation}
            processing={menuForm.processing}
            label='Menu Name'
            inputRef={inputRef}
            fullWidth={true}
            onChange={onChangeDisplayName}
          />
        )}

        {isSharedMenu ? (
          <ReadonlyValue label='Description' value={menuForm.menu.description || ''} />
        ) : (
          <DescriptionInput
            value={menuForm.menu.description || ''}
            processing={processing}
            fullWidth={true}
            onChange={onChangeDescription}
          />
        )}

        <ScheduleFormControl
          schedules={schedules}
          scheduleLink={menuForm.menu.schedule}
          onChange={onChangeSchedule}
          validation={menuForm.validation}
          isInModal
          areSchedulesLoading={areSchedulesLoading}
        />
      </ModalBody>

      <ModalFooter
        loading={processing}
        primaryLabel={saveLabel}
        primaryActionHandler={onSave}
        secondaryLabel='Cancel'
        secondaryActionHandler={() => setShowModal(false)}
      />
    </Modal>
  );
};

interface Props {
  label: string;
  value: string;
}
const ReadonlyValue = ({ label, value }: Props) => {
  return (
    <div className={styles.readonlyValue}>
      <b>{label}</b>

      <div>{value !== '' ? value : <NoneValue />}</div>
    </div>
  );
};

export default MenuFormModal;
