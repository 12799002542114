import React from 'react';
import styles from './SelectPriceLevel.module.scss';
import SelectFilter, { Option } from '../../../components/SelectFilter/SelectFilter';
import { components, OptionProps, SingleValueProps } from 'react-select';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';
import { IPriceLevel } from '@ready/menu.core';

interface PriceLevelOptionProps {
  option: Option;
}

const PriceLevelOption = (props: PriceLevelOptionProps): JSX.Element => {
  const { option } = props;
  const value = option.value === 'default' ? 'Default' : option.value;
  const label = option.label;
  const subLabel = option.subLabel;

  return (
    <>
      {label === 'deleted' ? (
        <>
          <span className={styles.warning}>
            <TextIcon icon={Icon.Alert} />
          </span>
          <span className={styles.priceDeleted}>Price Deleted</span>
        </>
      ) : (
        <>
          <span className={styles.priceLevelPrice}>{label}</span>
          <span className={styles.priceLevelId}>({value})</span>
          {!!subLabel && <span className={styles.priceLevelName}>{subLabel}</span>}
        </>
      )}
    </>
  );
};

const PriceLevel = (props: OptionProps<Option, boolean>): JSX.Element => (
  <components.Option {...props}>
    <PriceLevelOption option={props.data} />
  </components.Option>
);

const SelectedPriceLevel = (props: SingleValueProps<Option>): JSX.Element => (
  <components.SingleValue {...props}>
    <PriceLevelOption option={props.data} />
  </components.SingleValue>
);

interface SelectPriceLevelProps {
  placeholder: string;
  activePriceLevel: string;
  priceLevels: IPriceLevel[];
  price?: number;
  processing?: boolean;
  disabled?: boolean;
  withError?: boolean;
  isInModal?: boolean;
  onChange?: (option: Option) => void;
}

const SelectPriceLevel = (props: SelectPriceLevelProps): JSX.Element => {
  const {
    placeholder,
    activePriceLevel,
    priceLevels,
    price,
    processing = false,
    disabled = false,
    withError = false,
    isInModal = false,
    onChange,
  } = props;

  const priceLevelOptions: Option[] = priceLevels.map((priceLevel) => ({
    value: priceLevel.id,
    label: `$${((priceLevel.pricePerUnit || 0) / 100).toFixed(2)}`,
    subLabel: priceLevel.name,
  }));

  if (price !== undefined) {
    priceLevelOptions.splice(0, 0, {
      value: 'default',
      label: `$${(price / 100).toFixed(2)}`,
    });
  }

  if (withError) {
    priceLevelOptions.splice(0, 0, {
      value: activePriceLevel,
      label: 'deleted',
    });
  }

  const selectedPriceLevelOption: Option | undefined = priceLevelOptions.find(
    (priceLevelOption) => priceLevelOption.value === activePriceLevel
  );

  return (
    <SelectFilter
      placeholder={placeholder}
      value={selectedPriceLevelOption}
      options={priceLevelOptions}
      loading={processing}
      disabled={disabled}
      withError={withError}
      isInModal={isInModal}
      customComponents={{ Option: PriceLevel, SingleValue: SelectedPriceLevel }}
      onChange={onChange}
    />
  );
};

export default SelectPriceLevel;
