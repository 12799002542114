import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { IClientLocation, PaginationResponse } from '@ready/dashboardv2api.contracts';
import { ILocation } from '../../services/types/ILocation.type';
import { ILocationsState } from './LocationsState';
import { LocationsThunks } from './LocationsThunks';

export const reducers = {
  loadLocationsInit: (state: ILocationsState): void => {
    state.loading = true;
    state.locations = [];
    state.location = null;
  },
  loadLocationsSuccess: (state: ILocationsState, action: PayloadAction<PaginationResponse<ILocation>>): void => {
    state.loading = false;
    state.pagination.start = action.payload.start;
    state.pagination.length = action.payload.length;
    state.pagination.limit = action.payload.limit;
    state.pagination.total = action.payload.total;
    state.locations = action.payload.items;
  },
  loadLocationsFailure: (state: ILocationsState): void => {
    state.loading = false;
  },

  loadSelectedLocationSuccess: (state: ILocationsState, action: PayloadAction<IClientLocation>): void => {
    state.location = action.payload;
  },
};

export const extraReducers = (builder: ActionReducerMapBuilder<ILocationsState>) => {
  builder.addCase(LocationsThunks.loadLocations.pending, reducers.loadLocationsInit);
  builder.addCase(LocationsThunks.loadLocations.fulfilled, reducers.loadLocationsSuccess);
  builder.addCase(LocationsThunks.loadLocations.rejected, reducers.loadLocationsFailure);

  builder.addCase(LocationsThunks.loadSelectedLocation.fulfilled, reducers.loadSelectedLocationSuccess);
};
