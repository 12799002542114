import { useServerTables } from './index';

/**
 * Returns a list of selected table IDs for a specific server from the
 * tableAssignments Redux store
 */
const useServerTableIds = (employeeId: string): string[] => {
  const serverTable = useServerTables(employeeId);

  return serverTable ? serverTable.tables.map((table) => table.id) : [];
};

export default useServerTableIds;
