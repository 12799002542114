import React from 'react';
import { IOrderItemModifier } from '@ready/dashboardv2api.contracts';
import styles from './ItemsPanelContents.module.scss';
import { OrderDetailForm } from '../../../redux/types';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';
import { DollarValue } from '../../../components/Value';
import { IOrderItem } from '@ready/dashboardv2api.contracts';

interface ItemsPanelContentsProps {
  orderDetail: OrderDetailForm;
}

const ItemsPanelContents = ({ orderDetail }: ItemsPanelContentsProps) => {
  let itemSections: JSX.Element[] = [];

  orderDetail.items.forEach((item, index) => {
    const itemModifierKey = `itemModifier_${index}`;

    itemSections.push(<ItemInfo key={`itemInfo_${index}`} item={item} />);

    if (item.modifiers && item.modifiers.length > 0) {
      itemSections.push(<ItemModifiers key={itemModifierKey} itemModifierKey={itemModifierKey} item={item} />);
    }
  });

  return <>{itemSections}</>;
};

export default ItemsPanelContents;

interface ItemProps {
  item: IOrderItem;
}

const ItemInfo = ({ item }: ItemProps) => (
  <div className={styles.itemLine}>
    <div className={styles.itemDescription}>
      <div className={styles.itemDescriptionRow}>
        <div>
          <div className={styles.itemQuantityContainer}>
            <span className={styles.itemQuantity}>{item.quantity}</span>
          </div>
        </div>
        <span className={styles.itemName}>{item.name}</span>
        <div className={styles.priceContainer}>
          <DollarValue value={item.price} negativeFormat='symbol' additionalStyles={styles.itemAmount} />
        </div>
      </div>
      <Comment text={item.comment} />
    </div>
  </div>
);

interface CommentProps {
  text?: string;
  additionalStyles?: string;
}

const Comment = ({ text }: CommentProps) =>
  text ? (
    <div className={styles.commentContainer}>
      <div className={styles.comment}>
        <TextIcon icon={Icon.SpeechBubble} additionalStyles={styles.commentIcon} />
        <span className={styles.commentText}>{text}</span>
      </div>
    </div>
  ) : null;
interface ItemModifiersProps {
  item: IOrderItem;
  itemModifierKey: string | number;
}

const ItemModifiers = ({ item, itemModifierKey }: ItemModifiersProps) => {
  if (!(item.modifiers && item.modifiers.length > 0)) {
    return null;
  }

  return (
    <div className={styles.itemModifiers}>
      {item.modifiers.map((modifier, index) => (
        <div key={`${itemModifierKey}_modifier_${index}`} className={styles.modifierRowContainer}>
          <div className={styles.modifierRow}>
            <div className={styles.modifierQuantityContainer}>
              <span className={styles.modifierQuantity}>{modifier.quantity}</span>
            </div>
            <span className={styles.modifierName}>{modifier.name}</span>
            {modifier.pricePerUnit !== 0 ? (
              <span className={styles.modifierAmountContainer}>
                {'('}
                <DollarValue
                  value={modifier.pricePerUnit * modifier.quantity}
                  negativeFormat='symbol'
                  showPositiveSymbol={true}
                  additionalStyles={styles.modifierAmount}
                />
                {')'}
              </span>
            ) : null}
          </div>

          <NestedModifiers modifiers={modifier.modifiers} />

          <Comment text={modifier.comment} />
        </div>
      ))}
    </div>
  );
};

interface NestedModifiersProps {
  modifiers?: IOrderItemModifier[];
}

const NestedModifiers = (props: NestedModifiersProps) => {
  const { modifiers } = props;

  const renderNestedModifier = (nestedMod?: IOrderItemModifier[], level = 0) => {
    if (!nestedMod || nestedMod.length === 0) {
      return null;
    }
    const indicatorClassname = level % 2 === 0 ? styles.nestedIndicatorSolid : styles.nestedIndicator;
    const additionalMargin = 12 * level;

    return (
      <>
        {nestedMod.map((nestedModItem: IOrderItemModifier, index: number) => (
          <div className={styles.nestedModifier} key={index}>
            <div className={styles.nestedModifierDetails}>
              <span className={styles.modifierQuantity}>{nestedModItem.quantity}</span>
              <div className={styles.nestedIndentation} style={{ marginLeft: `${additionalMargin}px` }}>
                <div className={indicatorClassname}></div>
                <span>{nestedModItem.name}</span>
                {!!nestedModItem.pricePerUnit && !!nestedModItem.quantity ? (
                  <span className={styles.modifierAmountContainer}>
                    {'('}
                    <DollarValue
                      value={nestedModItem.pricePerUnit * nestedModItem.quantity}
                      negativeFormat='symbol'
                      showPositiveSymbol={true}
                      additionalStyles={styles.modifierAmount}
                    />
                    {')'}
                  </span>
                ) : null}
              </div>
            </div>
            {renderNestedModifier(nestedModItem.modifiers, level + 1)}
          </div>
        ))}
      </>
    );
  };

  return renderNestedModifier(modifiers);
};
