import React from 'react';
import { Status } from '@ready/menu.core';
import styles from './EditPanelFooter.module.scss';
import StatusRadioGroup from './StatusRadioGroup';
import { FormActionButtons } from '../../../components/Form';
import Notification from '../../../components/Notification/Notification';
import { Icon } from '../../../components/Icon/TextIcon';
import { useClassNames } from 'utils/cssUtils';

export interface EditPanelFooterProps {
  status: Status;
  statusLabel: string;
  processing: boolean;
  onChangeStatus?: (enabled: boolean) => void;
  onCancel: () => void;
  isSharedMenu?: boolean;
  saveLabel?: string;
  warningMessage?: string;
  showFooterSeparator?: boolean;
}

const EditPanelFooter = ({
  status,
  statusLabel,
  saveLabel,
  processing,
  isSharedMenu = false,
  onChangeStatus,
  onCancel,
  warningMessage,
  showFooterSeparator = false,
}: EditPanelFooterProps): JSX.Element => (
  <div className={useClassNames([styles.editPanelFooter, { footerSeparator: showFooterSeparator }], styles)}>
    <div className={useClassNames([styles.mainContent, { noStatusChange: isSharedMenu || !onChangeStatus }], styles)}>
      {!isSharedMenu && onChangeStatus && (
        <StatusRadioGroup label={statusLabel} value={status} processing={processing} onChange={onChangeStatus} />
      )}
      <FormActionButtons
        handleCancel={onCancel}
        loading={processing}
        saveButtonLabel={saveLabel}
        simpleLayout
        disabled={!!warningMessage}
        additionalClassName={styles.buttons}
      />
    </div>

    {!!warningMessage && (
      <div className={styles.subContent}>
        <div className={styles.warningMessage}>
          <Notification>
            <p>
              <i className={Icon.Alert} />
              <span>{warningMessage}</span>
            </p>
          </Notification>
        </div>
      </div>
    )}
  </div>
);

export default EditPanelFooter;
