import { CellProps, getCellClasses } from 'components/TableV2/Cell/Cell';
import React from 'react';
import styles from './SpecialCells.module.scss';
import PaymentMethodIcon from 'pages/Transactions/components/PaymentMethodIcon';

export const PaymentMethodCell = ({ rowData, columnName, classes }: CellProps) => {
  return (
    <div className={getCellClasses(classes, styles.paymentMethodCell)}>
      <PaymentMethodIcon code={rowData[columnName]} />
    </div>
  );
};
