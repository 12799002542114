import { IClientLocation } from '@ready/dashboardv2api.contracts';
import { ILocationPicker } from '../../redux/initialStates/companyLocations/companyLocations';
export interface ILocationsState extends ILocationPicker {
  location: IClientLocation | null;
}

export const locationsState: ILocationsState = {
  loading: false,
  pagination: {
    start: 0,
    length: 0,
    limit: 0,
    total: 0,
  },
  locations: [],
  location: null,
};
