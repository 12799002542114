import { CompanyPaymentProviderType, ICompanyPaymentConfig, IJetpayOptions } from '@ready/dashboardv2api.contracts';
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { FieldValidation } from 'types/FieldValidation.interface';
import {
  getAllJetpayCompanyConfigsListThunk,
  createJetpayCompanyConfigThunk,
  updateJetpayCompanyConfigThunk,
  deleteJetpayCompanyConfigThunk,
} from './thunk';

interface JetpayValidationField extends Omit<FieldValidation, 'error'> {}

export interface IJetpayOptionsValidation {
  hasErrors: boolean;
  hasPassedValidation: boolean;
  configName: JetpayValidationField;
  companyNumber: JetpayValidationField;
  clientPrivateKey: JetpayValidationField;
  serverPublicKey: JetpayValidationField;
}

const newJetpayOptions: IJetpayOptions = {
  name: '',
  provider: CompanyPaymentProviderType.Jetpay,
  companyNumber: 0,
  clientPublicKey: '',
  serverPublicKey: '',
  clientXmlPublicKey:'',
};

const newValidation: JetpayValidationField = {
  hasError: false,
};

export const jetpayOptionsDefaultValidation: IJetpayOptionsValidation = {
  hasErrors: false,
  hasPassedValidation: false,
  configName: {
    ...newValidation,
  },
  companyNumber: {
    ...newValidation,
  },
  clientPrivateKey: {
    ...newValidation,
  },
  serverPublicKey: {
    ...newValidation,
  },
};

const jetpayCompanyPaymentConfigDefault: ICompanyPaymentConfig = {
  id: '',
  options: newJetpayOptions,
};

interface State {
  companyPaymentConfigsList: ICompanyPaymentConfig[];
  companyPaymentConfig: ICompanyPaymentConfig;
  validation: IJetpayOptionsValidation;
  isSaving: boolean;
  isLoading: boolean;
}

const initialState: State = {
  companyPaymentConfig: {
    ...jetpayCompanyPaymentConfigDefault,
  },
  companyPaymentConfigsList: [],
  validation: jetpayOptionsDefaultValidation,
  isSaving: false,
  isLoading: false,
};

const reducers = {
  updateJetpayConfigOptions: (state: State, action: { payload: Partial<IJetpayOptions> }) => {
    state.companyPaymentConfig.options = {
      ...(state.companyPaymentConfig.options ?? jetpayCompanyPaymentConfigDefault),
      ...action.payload,
    };
  },

  resetJetPayConfigOptions: (state: State) => {
    state.companyPaymentConfig = {
      id: '',
      options: newJetpayOptions,
    };
  },

  validateJetPayConfigOptions: (state: State) => {
    const { name, companyNumber } = state.companyPaymentConfig.options;
    const { validation } = state;

    if (!name) {
      validation.configName.hasError = validation.hasErrors = true;
    }

    if (!companyNumber) {
      validation.companyNumber.hasError = validation.hasErrors = true;
    }

    if (!validation.hasErrors) {
      validation.hasPassedValidation = true;
    }
  },

  updateHasPassedValidationFlag: (state: State, action: { payload: boolean }) => {
    state.validation.hasPassedValidation = action.payload;
  },

  resetJetPayConfigOptionsValidation: (state: State) => {
    state.validation = jetpayOptionsDefaultValidation;
  },

  selectJetpayConfig: (state: State, action: { payload: string }) => {
    const selectedConfig = state.companyPaymentConfigsList.find(
      (config: ICompanyPaymentConfig) => config.id === action.payload
    );
    if (selectedConfig) {
      state.companyPaymentConfig = selectedConfig;
    }
  },
  resetJetpayState: () => initialState,
};

const jetpayPaymentProcessingSlice = createSlice({
  name: 'companies/details/paymentProcessing/jetpay',
  initialState: initialState,
  reducers,
  extraReducers: (builder: ActionReducerMapBuilder<State>) => {
    builder.addCase(getAllJetpayCompanyConfigsListThunk.pending, (state: State) => {
      state.isLoading = true;
    });
    builder.addCase(getAllJetpayCompanyConfigsListThunk.fulfilled, (state: State, action) => {
      state.isLoading = false;
      state.companyPaymentConfigsList = action.payload;
    });
    builder.addCase(getAllJetpayCompanyConfigsListThunk.rejected, (state: State) => {
      state.isLoading = false;
    });

    builder.addCase(createJetpayCompanyConfigThunk.pending, (state: State) => {
      state.isSaving = true;
    });
    builder.addCase(createJetpayCompanyConfigThunk.fulfilled, (state: State, action) => {
      state.isSaving = false;
      if (action.payload) {
        state.companyPaymentConfigsList.push(action.payload);
        state.validation.hasPassedValidation = false;
      }
    });
    builder.addCase(createJetpayCompanyConfigThunk.rejected, (state: State) => {
      state.isSaving = false;
    });

    builder.addCase(updateJetpayCompanyConfigThunk.pending, (state: State) => {
      state.isSaving = true;
    });
    builder.addCase(updateJetpayCompanyConfigThunk.fulfilled, (state: State, action) => {
      state.isSaving = false;
      if (action.payload) {
        const configIndex = state.companyPaymentConfigsList.findIndex(
          (config: ICompanyPaymentConfig) => config.id === action.payload?.id
        );
        state.companyPaymentConfigsList.splice(configIndex, 1, action.payload);
        state.validation.hasPassedValidation = false;
      }
    });
    builder.addCase(updateJetpayCompanyConfigThunk.rejected, (state: State) => {
      state.isSaving = false;
    });

    builder.addCase(deleteJetpayCompanyConfigThunk.pending, (state: State) => {
      state.isSaving = true;
    });
    builder.addCase(deleteJetpayCompanyConfigThunk.fulfilled, (state: State, action) => {
      state.isSaving = false;
      if (action.payload) {
        const filteredConfigsList = state.companyPaymentConfigsList.filter(
          (config: ICompanyPaymentConfig) => config.id !== action.payload
        );
        state.companyPaymentConfigsList = filteredConfigsList;
      }
    });
    builder.addCase(deleteJetpayCompanyConfigThunk.rejected, (state: State) => {
      state.isSaving = false;
    });
  },
});

export const {
  selectJetpayConfig,
  updateJetpayConfigOptions,
  resetJetPayConfigOptions,
  validateJetPayConfigOptions,
  resetJetPayConfigOptionsValidation,

  resetJetpayState,
} = jetpayPaymentProcessingSlice.actions;

export { jetpayPaymentProcessingSlice };
