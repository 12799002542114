import { CreateableRole, RoleFormValidation } from '../initialStates/securityRoleForm';
import validateSecurityRoleForm from '../../../redux/helpers/validateSecurityRoleForm';
import { Action } from '../../../redux/types';
import { SECURITY_ROLE_FORM_TYPES } from './types';
import SecurityRolesService from '../../services/securityRolesService';

import { IRole, ResourceType } from '@ready/security.core';
import { pageErrorState, set200Toast, toastErrorState } from '../../../redux/actions/uiActions/responseStateActions';

export const initForm = (): Action => ({
  type: SECURITY_ROLE_FORM_TYPES.INIT_SECURITY_ROLE_FORM,
});

export const setName = (name: string): Action => ({
  type: SECURITY_ROLE_FORM_TYPES.SET_SECURITY_ROLE_NAME,
  payload: name,
});

export const setDescription = (description: string): Action => ({
  type: SECURITY_ROLE_FORM_TYPES.SET_SECURITY_ROLE_DESCRIPTION,
  payload: description,
});

export const setPermission = (
  permissionType: 'company' | 'location',
  resourceType: ResourceType,
  actionName: string,
  operation: 'add' | 'remove'
): Action => ({
  type: SECURITY_ROLE_FORM_TYPES.SET_SECURITY_ROLE_PERMISSION,
  payload: { permissionType, resourceType, actionName, operation },
});

const setValidation = (validation: RoleFormValidation): Action => ({
  type: SECURITY_ROLE_FORM_TYPES.VALIDATE_SECURITY_ROLE_FORM,
  payload: validation,
});

const setFormLoading = (isLoading: boolean): Action => ({
  type: SECURITY_ROLE_FORM_TYPES.SET_SECURITY_ROLE_FORM_LOADING,
  payload: isLoading,
});

const setFormProcessing = (isProcessing: boolean): Action => ({
  type: SECURITY_ROLE_FORM_TYPES.SET_SECURITY_ROLE_FORM_PROCESSING,
  payload: isProcessing,
});

const setFormRoleData = (role: IRole): Action => ({
  type: SECURITY_ROLE_FORM_TYPES.SET_SECURITY_ROLE_FORM_DATA,
  payload: role,
});

export const validateForm =
  (name: string) =>
  (dispatch: any): boolean => {
    const validation = validateSecurityRoleForm(name);

    dispatch(setValidation(validation));

    return validation.isValid;
  };

export const submitCreateForm =
  (companyId: string, role: CreateableRole) =>
  async (dispatch: any): Promise<string | null> => {
    let responseRoleId = null;

    dispatch(setFormProcessing(true));

    try {
      const roleCreated = await SecurityRolesService.createSecurityRole(companyId, role);
      responseRoleId = roleCreated._id;
      dispatch(set200Toast());
    } catch (err) {
      dispatch(toastErrorState(err.status, err.message));
      responseRoleId = null;
    } finally {
      dispatch(setFormProcessing(false));
    }

    return responseRoleId;
  };

export const submitUpdateForm =
  (roleId: string, companyId: string, role: CreateableRole) =>
  async (dispatch: any): Promise<string | null> => {
    let responseRoleId = null;

    dispatch(setFormProcessing(true));

    try {
      const roleUpdated = await SecurityRolesService.updateSecurityRole(roleId, companyId, role);
      responseRoleId = roleUpdated._id;
      dispatch(set200Toast());
    } catch (err) {
      dispatch(toastErrorState(err.status, err.message));
      responseRoleId = null;
    } finally {
      dispatch(setFormProcessing(false));
    }

    return responseRoleId;
  };

export const fetchFormData =
  (companyId: string, roleId: string) =>
  async (dispatch: any): Promise<void> => {
    dispatch(setFormLoading(true));

    try {
      const roleData = await SecurityRolesService.fetchSecurityRole(companyId, roleId);
      dispatch(setFormRoleData(roleData));
    } catch (err) {
      dispatch(pageErrorState(err));
    } finally {
      dispatch(setFormLoading(false));
    }
  };

export const deleteSecurityRole = (companyId: string, roleId: string) => async (dispatch: any) => {
  dispatch({ type: SECURITY_ROLE_FORM_TYPES.DELETE_SECURITY_ROLE_BEGIN });
  try {
    await SecurityRolesService.deleteSecurityRoleById(companyId, roleId);
    dispatch(set200Toast('Security role deleted.'));
    dispatch({ type: SECURITY_ROLE_FORM_TYPES.DELETE_SECURITY_ROLE_SUCCESS });
  } catch (error) {
    dispatch(toastErrorState(error.status, error.message));
    dispatch({ type: SECURITY_ROLE_FORM_TYPES.DELETE_SECURITY_ROLE_ERROR });
  }
};

export const showDeleteSecurityRoleModal = (show: boolean, roleId?: string) => ({
  type: SECURITY_ROLE_FORM_TYPES.DELETE_SECURITY_ROLE_REQUESTED,
  payload: { show, roleId },
});
