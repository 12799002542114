import { ITemplateMenuItem, WithAssignedLocationsCount } from '@ready/menu.core';
import TextIcon, { Icon } from 'components/Icon/TextIcon';
import InfoCard from 'components/InfoCard/InfoCard';
import InfoCardColumn from 'components/InfoCard/InfoCardColumn';
import { useResponsiveBreakpoint } from 'hooks';
import { useAssetUrl } from 'hooks/useAssetUrl';
import MenuItemImage from 'menus/components/ItemsAndMods/MenuItemImage';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import {  SharedMenuItemsRoutes } from 'sharedMenuItems/Router';
import { ContextParams } from 'types/ContextParams.interface';
import { compositeStyles } from 'utils/cssUtils';
import styles from './ItemInfoCard.module.scss';

interface Props {
  item: WithAssignedLocationsCount<ITemplateMenuItem>;
}

const ItemInfoCard = ({ item }: Props) => {
  const { contextId: companyId } = useParams<ContextParams>();
  const { displayName, description, alcohol, itemType, thumbnailImageId, assignedLocationsCount, _id } = item;

  const imageUrl = useAssetUrl(thumbnailImageId);
  const { isMobile } = useResponsiveBreakpoint();

  return (
    <Link to={SharedMenuItemsRoutes.getItemAndModsItem(companyId, _id)}>
      <InfoCard additionalStyles={styles.infoCard}>
        {isMobile && (
          <InfoCardColumn>
            <MenuItemImage type={itemType} name={displayName} url={imageUrl}></MenuItemImage>
          </InfoCardColumn>
        )}
        <InfoCardColumn width={100} columnClassName={styles.container}>
          <div className={styles.left}>
            {!isMobile && <MenuItemImage type={itemType} name={displayName} url={imageUrl}></MenuItemImage>}
            <div className={styles.details}>
              <span className={styles.name}>{displayName}</span>
              <span className={compositeStyles([styles.description, { missing: !description }], styles)}>
                {description ?? 'No description'}
              </span>
            </div>
          </div>
          <div className={styles.attributes}>
            <div className={styles.assignedLocations}>
              <TextIcon icon={Icon.Location} additionalStyles={styles.icon} />
              <span className={styles.amount}>{assignedLocationsCount}</span>
            </div>

            <div className={styles.icons}>
              {alcohol && alcohol.containsAlcohol && (
                <div className={styles.alcohol}>
                  <TextIcon icon={Icon.Martini} additionalStyles={styles.icon} />
                  <span className={styles.amount}>{alcohol.quantity?.value}</span>
                </div>
              )}
            </div>
          </div>
        </InfoCardColumn>
      </InfoCard>
    </Link>
  );
};

export { ItemInfoCard };
