import { IPagination } from '@ready/dashboardv2api.contracts';
import {
  ForcedModifierStatus,
  ICachedPosMenuItem,
  ICachedPosModifier,
  IMenuItem,
  MenuItemInactiveStates,
  Status,
} from '@ready/menu.core';
import InfiniteScrollingListState from '../../types/InfiniteScrollingListState.interface';
import InfiniteScrollingListStateHelper from '../../utils/InfiniteScrollingList/InfiniteScrollingListStateHelper';
import { LinkPosItemType } from '../types/LinkPosItemType.type';
import MenuItemsView from '../types/MenuItemsView.enum';
import ItemsAndGroupsStockStatus from '../types/ItemsAndGroupsStockStatus.enum';
import { ISmbTax } from '../../companySettings/types/SmbTaxTypes.type';
import { ISmbMenuItem } from '../types/SmbMenuTypes.type';

export interface IMenuItemFormValidation {
  displayName: {
    hasError: boolean;
    errorMessage: string;
  };
  effectiveStartDate: {
    hasError: boolean;
    errorMessage: string;
  };
  price?: {
    hasError: boolean;
    errorMessage: string;
  };
}

export interface IForcedModifierSelection {
  loading: boolean;
  items: ICachedPosModifier[];
}

export interface IMenuItemForm {
  loading: boolean;
  processing: boolean;
  duplicating: boolean;
  cancelled: boolean;
  newId: string;
  menuItem: IMenuItem;
  taxes?: ISmbTax[];
  validation: IMenuItemFormValidation;
  forcedModifierSelection: IForcedModifierSelection;
}

export interface IMenuItemSelection extends InfiniteScrollingListState<IMenuItem> {
  view: MenuItemsView;
}

export interface IPosItemSelection extends InfiniteScrollingListState<ICachedPosMenuItem | ICachedPosModifier> {
  type: LinkPosItemType;
  selectedItemId: string;
}

export interface IItemsAndModsState {
  loading: boolean;
  view?: MenuItemsView;
  stockStatus?: ItemsAndGroupsStockStatus;
  query?: string;
  page?: number;
  pagination: IPagination;
  items: IMenuItem[];
  syncing: boolean;
  deleting: boolean;
  deleted: boolean;
  menuItemForm: IMenuItemForm;
  menuItemSelection: IMenuItemSelection;
  posItemSelection: IPosItemSelection;
  forcedModSelection: IMenuItemSelection;
}

export const newMenuItem = (): IMenuItem => ({
  _id: '',
  itemType: 'item',
  status: Status.disabled,
  displayName: '',
  posItemName: '',
  posItemCategories: [],
  inStock: true,
  inactiveStates: [MenuItemInactiveStates.draft],
  modifierGroups: [],
  recommendedItems: [],
  forcedModifiers: [],
  forcedModifierStatus: ForcedModifierStatus.valid,
  tags: undefined,
  badge: undefined,
});

export const newSmbMenuItem = (): ISmbMenuItem => ({
  ...newMenuItem(),
  status: Status.enabled,
  taxes: [],
});

export const newModifier = (): IMenuItem => ({
  _id: '',
  itemType: 'option',
  status: Status.disabled,
  displayName: '',
  posItemName: '',
  posItemCategories: [],
  inStock: false,
  inactiveStates: [MenuItemInactiveStates.draft],
  forcedModifierStatus: ForcedModifierStatus.valid,
  modifierGroups: [],
  recommendedItems: [],
  forcedModifiers: [],
});

export const newSmbModifier = (): ISmbMenuItem => ({
  ...newModifier(),
  status: Status.enabled,
  inStock: true,
});

export const newMenuItemValidation = (): IMenuItemFormValidation => ({
  displayName: {
    hasError: false,
    errorMessage: '',
  },
  effectiveStartDate: {
    hasError: false,
    errorMessage: '',
  },
});

export const itemsAndModsState: IItemsAndModsState = {
  loading: false,
  pagination: {
    start: 0,
    length: 0,
    limit: 0,
    total: 0,
  },
  items: [],
  syncing: false,
  deleting: false,
  deleted: false,
  menuItemForm: {
    loading: false,
    processing: false,
    duplicating: false,
    cancelled: false,
    newId: '',
    menuItem: newMenuItem(),
    validation: newMenuItemValidation(),
    forcedModifierSelection: {
      loading: false,
      items: [],
    },
  },
  menuItemSelection: {
    ...InfiniteScrollingListStateHelper.initialState(),
    view: MenuItemsView.MODS,
  },
  posItemSelection: {
    ...InfiniteScrollingListStateHelper.initialState(),
    type: 'item',
    selectedItemId: '',
  },
  forcedModSelection: {
    ...InfiniteScrollingListStateHelper.initialState(),
    view: MenuItemsView.MODS,
  },
};
