import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { format, utcToZonedTime } from 'date-fns-tz';
import { getTime, parseISO } from 'date-fns';
import { ICheckDetailsPayment, CheckDetailsPaymentStatus, PospaymentStatus } from '@ready/dashboardv2api.contracts';
import styles from '../Page.module.scss';
import Badge, { BadgeVariant } from '../../../../components/Badge/Badge';
import DollarValue from '../../../../components/Value/DollarValue';
import TextIcon, { Icon } from '../../../../components/Icon/TextIcon';

enum CheckDetailsPaymentStatuses {
  Processed = 'Processed',
  Authorized = 'Authorized',
  PartialRefund = 'Partial Refund',
  Refunded = 'Refunded',
  Cancelled = 'Cancelled',
}

const defaultGetStatusBadge = (status: CheckDetailsPaymentStatus) => {
  switch (status) {
    case CheckDetailsPaymentStatuses.Processed:
      return <Badge variant={BadgeVariant.GreenSolid} value={'Processed'} />;
    case CheckDetailsPaymentStatuses.Authorized:
      return <Badge variant={BadgeVariant.YellowSolid} value={'Authorized'} />;
    case CheckDetailsPaymentStatuses.PartialRefund:
      return <Badge variant={BadgeVariant.Green} value={'Partial Refund'} />;
    case CheckDetailsPaymentStatuses.Refunded:
      return <Badge variant={BadgeVariant.Green} value={'Refunded'} />;
    case CheckDetailsPaymentStatuses.Cancelled:
      return <Badge variant={BadgeVariant.DisabledGraySolid} value={'Cancelled'} />;
    default:
      return null;
  }
};

const Payment = ({
  timezone,
  payment,
  payment: { tip, total, amountNoTip, cardBrand, last4, status, tokenizationMethod, created },
  getStatusBadge = defaultGetStatusBadge,
}: {
  timezone: string;
  payment: ICheckDetailsPayment;
  getStatusBadge?: (payment: CheckDetailsPaymentStatus) => ReactNode;
}) => {
  const dateFormated = timezone ? utcToZonedTime(parseISO(created), timezone) : parseISO(created);

  return (
    <div className={classnames(styles.Payment, styles.LineHeight_24_mobile)}>
      <div className={styles.PaymentMoney}>
        <span>
          <DollarValue value={total} additionalStyles={classnames(styles.TextBold, styles.PaymentMoneyValue)} />
          {payment.pospaymentLastInfo?.status === PospaymentStatus.failed ? (
            <TextIcon icon={Icon.Alert} additionalStyles={styles.PaymentAlertIcon} />
          ) : null}
        </span>
        {tip ? (
          <span className={styles.SecondaryText}>
            <DollarValue value={amountNoTip} />
            <span className={styles.PaymentTip}>
              {' '}
              + (<DollarValue value={tip} />)
            </span>
          </span>
        ) : null}
      </div>
      <div className={styles.PaymentCart}>
        <span>
          {cardBrand && (
            <span className={classnames(styles.TextBold, styles.PaymentCartBrand, styles.Capitalized)}>
              {cardBrand}
            </span>
          )}{' '}
          {last4 && <span>(****{last4})</span>}
        </span>
        {/* TODO check this with server */}
        {tokenizationMethod && <span className={styles.SecondaryText}>{tokenizationMethod}</span>}
        <span className={styles.PaymentStatusBadgeContainer}>{status ? getStatusBadge(status) : null}</span>
      </div>
      <div className={styles.PaymentDate}>
        <span>{format(getTime(dateFormated), 'MMM d, yyyy')}</span>
        <span className={styles.SecondaryText}>
          {format(getTime(dateFormated), 'h:mm a (zzz)', { timeZone: timezone })}
        </span>
      </div>
    </div>
  );
};

export const Payments = ({ payments, timezone }: { timezone: string; payments: Array<ICheckDetailsPayment> }) => (
  <div className={styles.Payments}>
    {payments.map((payment) => (
      <Payment timezone={timezone} payment={payment} key={payment.preauthId} />
    ))}
  </div>
);
