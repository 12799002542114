import { PaginationResponse } from '@ready/dashboardv2api.contracts';
import { ITemplateMenuItem, WithAssignedLocationsCount } from '@ready/menu.core';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSharedMenuItemList, ItemTypeFilter } from '../service';

export interface IFetchSharedMenuItemListArgs {
  companyId: string;
  page?: number;
  type?: ItemTypeFilter;
  searchTerm?: string;
  locationId?: string;
  isLoadMoreThunk?: boolean;
}

const getSharedMenuItemListThunk = createAsyncThunk<
  PaginationResponse<WithAssignedLocationsCount<ITemplateMenuItem>>,
  IFetchSharedMenuItemListArgs
>(
  'sharedMenuItems/itemsAndMods/list/fetch',
  ({ companyId, page, type, searchTerm, locationId }: IFetchSharedMenuItemListArgs) =>
    getSharedMenuItemList(companyId, searchTerm, type, locationId, page)
);

export { getSharedMenuItemListThunk };
