import React, { useCallback, useEffect } from 'react';
import {
  PrincipalPermissions,
  ResourceType,
  MenuScheduleResourceActions,
  SecurityScope,
  Verifier,
} from '@ready/security.core';
import styles from './SchedulesListPage.module.scss';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../../redux/initialStates/AppState';
import LayoutContent from '../../../components/AppLayout/LayoutContent';
import InfoCardList from '../../../components/InfoCard/InfoCardList';
import InfoCardsSkeleton from '../../../components/InfoCard/InfoCardColumnSkeleton';
import { useResponsiveBreakpoint, useSearchParams } from '../../../hooks';
import EmptyResult from '../../../components/EmptyResult/EmptyResult';
import { getScheduleList, initScheduleList } from '../../redux/ScheduleListActions';
import { showDeleteDialog, deleteSchedule } from '../../redux/ScheduleActions';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ContextParams } from '../../../types/ContextParams.interface';
import { ISchedule } from '@ready/menu.core';
import InfoCard from '../../../components/InfoCard/InfoCard';
import InfoCardColumn from '../../../components/InfoCard/InfoCardColumn';
import { TextValue } from '../../../components/Value';
import SearchParamInput from '../../../components/SearchInput/SearchParamInput';
import OverflowButton from '../../../components/OverflowButton/OverflowButton';
import { DropDownMenuOptionProps } from '../../../components/DropDownMenu/types';
import Button from '../../../components/Button/Button';
import { Routes } from '../../SchedulesRouter';
import DeleteScheduleDialog from './DeleteScheduleDialog';

const SchedulesListPage = ({
  getScheduleList,
  initScheduleList,
  showDeleteDialog,
  deleteSchedule,
  schedules,
  pagination,
  loading,
  processing,
  deleteDialogOpen,
  deleteDialogSchedule,
  permissions,
}: ReduxProps): JSX.Element => {
  const { contextId } = useParams<ContextParams>();
  const { query, page } = useSearchParams();
  const { push } = useHistory();
  const { isMobile } = useResponsiveBreakpoint();

  // permission check
  const canManageAllSchedules = Verifier.check(
    new PrincipalPermissions(permissions),
    SecurityScope.company,
    ResourceType.menuSchedule,
    MenuScheduleResourceActions.all
  );

  // Fetch/init the list of schedules
  useEffect(() => {
    getScheduleList(contextId, undefined, query, page, 50, true);

    return () => {
      initScheduleList();
    };
  }, [getScheduleList, initScheduleList, contextId, query, page]);

  const handleDeleteMenuClick = (_: React.MouseEvent<HTMLButtonElement>, schedule: ISchedule) => {
    showDeleteDialog(true, schedule);
  };

  const handleDeleteDialogClose = () => {
    showDeleteDialog(false);
  };

  const handleDeleteClick = async (_: React.MouseEvent<HTMLButtonElement>, scheduleId: string) => {
    const success = await deleteSchedule(contextId, scheduleId);

    // Close the dialog and re-fetch list on success
    if (success) {
      showDeleteDialog(false);

      getScheduleList(contextId, query);
    }
  };

  const handleNewScheduleClick = useCallback(
    (_) => {
      push(Routes.getCreateSchedulesPageRoute(contextId));
    },
    [push, contextId]
  );

  const createNewScheduleControl = () => {
    if (!canManageAllSchedules) {
      return null;
    }

    return isMobile ? (
      <OverflowButton
        options={[
          {
            label: 'New Schedule',
            onClick: handleNewScheduleClick,
            primary: true,
          },
        ]}
      />
    ) : (
      <Button size='large' label='+ New Schedule' onClick={handleNewScheduleClick} />
    );
  };

  return (
    <LayoutContent title='Schedules' containerType='within'>
      <DeleteScheduleDialog
        isOpen={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        onDelete={handleDeleteClick}
        schedule={deleteDialogSchedule}
        processing={processing}
      />
      <div className={styles.contentContainer}>
        <div className={styles.controlContainer}>
          <div className={styles.searchContainer}>
            <SearchParamInput placeholder='Search Schedules' additionalClassName={styles.searchInput} fullWidth />
          </div>
          {createNewScheduleControl()}
        </div>
        {!loading && pagination.total === 0 ? (
          <EmptyResult title='Create Your First Schedule'>
            <p className={styles.emptyResultMessage}>
              Use schedules to control when certain limited time menus or items should be displayed to your guests{' '}
              <em>(ex. "Happy Hour" or "Weekend Brunch")</em>. Schedules can be applied to entire menus or to individual
              sections and items.
            </p>
          </EmptyResult>
        ) : (
          <InfoCardList paginationProps={pagination}>
            {loading ? (
              <InfoCardsSkeleton instances={10} rowsWithinInstance={1} columns={1} />
            ) : (
              schedules.map((schedule: ISchedule) => {
                const menuOptions: DropDownMenuOptionProps[] = [
                  {
                    label: 'Delete Schedule',
                    onClick: (event: React.MouseEvent<HTMLButtonElement>) => handleDeleteMenuClick(event, schedule),
                    primary: true,
                  },
                ];

                return (
                  <InfoCard key={schedule._id} additionalStyles={styles.item}>
                    <InfoCardColumn width={50}>
                      <Link to={Routes.getEditSchedulesPageRoute(contextId, schedule._id)}>
                        <div className={styles.textLinkContainer}>
                          <TextValue value={schedule.name} />
                        </div>
                      </Link>
                    </InfoCardColumn>
                    {canManageAllSchedules && (
                      <InfoCardColumn width={50}>
                        <OverflowButton options={menuOptions} />
                      </InfoCardColumn>
                    )}
                  </InfoCard>
                );
              })
            )}
          </InfoCardList>
        )}
      </div>
    </LayoutContent>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    schedules: state.menuBuilder.scheduleList.schedules,
    pagination: state.menuBuilder.scheduleList.pagination,
    loading: state.menuBuilder.scheduleList.loading,
    processing: state.menuBuilder.schedule.processing,
    deleteDialogOpen: state.menuBuilder.schedule.deleteModalOpen,
    deleteDialogSchedule: state.menuBuilder.schedule.deleteModalSchedule,
    permissions: state.session.permissions.permissionsList,
  };
};

const actionCreators = {
  getScheduleList,
  initScheduleList,
  showDeleteDialog,
  deleteSchedule,
};

const connector = connect(mapStateToProps, actionCreators);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(SchedulesListPage);
