import {
  IAdminUser,
  IAdminUserAuth0Create,
  PaginationResponse,
  UserStatusFilter,
} from '@ready/dashboardv2api.contracts';
import config from '../../config';
import executeApiAsync from '../../services/utils/executeApiAsync';
import { objectToQueryParamsString } from 'utils/urlUtils/objectToQueryParamsString';
import { IUIAdmin } from 'admins/redux/AdminsListState';
import { getPaginationQueryStringProperties } from 'utils/urlUtils/pagination.utils';

export default class AdminsService {
  static getAdmins = async (
    query: string = '',
    page: number = 1,
    status: UserStatusFilter = UserStatusFilter.All
  ): Promise<PaginationResponse<IAdminUser>> => {
    const queryString = objectToQueryParamsString({
      searchTerm: query,
      status: status === UserStatusFilter.All ? null : status,
      ...getPaginationQueryStringProperties(page),
    });
    const url = `${config.readyDashboardApiEndpoint}/admins?${queryString}`;
    return await executeApiAsync<PaginationResponse<IAdminUser>>(url);
  };

  static getAdmin = async (id: string): Promise<IAdminUser> => {
    const url = `${config.readyDashboardApiEndpoint}/admins/${id}`;
    return await executeApiAsync<IAdminUser>(url);
  };

  static createAdmin = async (data: IUIAdmin): Promise<IAdminUser> => {
    const url = `${config.readyDashboardApiEndpoint}/admins`;

    const newAdmin: IAdminUserAuth0Create = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
    };

    return await executeApiAsync<IAdminUser>(url, {
      method: 'POST',
      body: JSON.stringify(newAdmin),
    });
  };

  static updateAdmin = async (id: string, data: IUIAdmin): Promise<IAdminUser> => {
    const url = `${config.readyDashboardApiEndpoint}/admins`;

    return await executeApiAsync<IAdminUser>(url, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  };

  public static updateAdminAccess = async (id: string, enabled: boolean): Promise<void> => {
    const url = `${config.readyDashboardApiEndpoint}/admins/${id}/enabled`;
    return executeApiAsync(url, {
      method: 'PUT',
      body: JSON.stringify({ enabled }),
    });
  };
}
