import { ContextParams } from '../../types/ContextParams.interface';
import { fetchUsersListThunk, initializeUsersList } from '../../redux/actions/usersActions/usersListActions';
import { UIClientUser } from '../../services/types/companyUsers.type';
import { IPagination, UserStatusFilter } from '@ready/dashboardv2api.contracts';
import { PrincipalPermissions, ResourceType, UserResourceActions, SecurityScope, Verifier } from '@ready/security.core';

import { Table, TableHeader, TableBody, TableRow, TableCell, TableRowsSkeleton } from '../../components/Table';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import HeaderControls from '../../components/AppLayout/HeaderControls';
import LayoutContent from '../../components/AppLayout/LayoutContent';
import React, { useEffect, useState } from 'react';
import SearchParamInput from '../../components/SearchInput/SearchParamInput';
import SelectFilter from '../../components/SelectFilter/SelectFilter';
import StatusIndicator from '../../components/StatusIndicator/StatusIndicator';
import styles from './Users.module.scss';
import { useSearchParams } from '../../hooks/useSearchParams';
import { selectContextSession, selectPermissions } from '../../redux/selectors/sessionSelectors/sessionSelectors';
import { selectUsersList } from '../../redux/selectors/usersSelectors';

const filterOptions = [
  { label: 'All Users', value: UserStatusFilter.All },
  { label: 'Active Users', value: UserStatusFilter.Active },
  { label: 'Inactive Users', value: UserStatusFilter.Inactive },
];

const UsersListPage = () => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState<UserStatusFilter>(UserStatusFilter.Active);

  const permissionList = useSelector(selectPermissions).permissionsList;
  const hasAddUserAccess = Verifier.check(
    new PrincipalPermissions(permissionList),
    SecurityScope.company,
    ResourceType.user,
    UserResourceActions.all
  );

  const history = useHistory();
  const contextId = useSelector(selectContextSession).id;
  const redirectToNewCompanyUser = (): void => history.push(`/companies/${contextId}/users/new`);
  const headerControlOptions = hasAddUserAccess
    ? [
        {
          buttonLabel: '+ New User',
          onClick: redirectToNewCompanyUser,
          primary: true,
        },
      ]
    : [];

  const { start, length, limit, total, loading, items: users } = useSelector(selectUsersList);
  const paginationProps: IPagination = { start, length, limit, total };
  const emptyResult = !loading && !users.length;
  const urlParams = useParams<ContextParams>();
  const { query, page } = useSearchParams();

  const colsWidth: number[] = [40, 50, 10];
  const [nameColWidth, emailColWidth, statusColWidth] = colsWidth;

  useEffect(() => {
    dispatch(fetchUsersListThunk(urlParams.contextId, query, page, status));

    return () => {
      dispatch(initializeUsersList(query, page));
    };
  }, [urlParams.contextId, query, page, status, dispatch]);

  return (
    <>
      <LayoutContent
        title={'Users'}
        containerType='full'
        headerControls={<HeaderControls controlOptions={headerControlOptions} />}
        toolbarContent={
          <div className={styles.listToolbar}>
            <SearchParamInput placeholder='Filter by Name or Email' additionalClassName={styles.listSearchFilter} />

            <SelectFilter
              options={filterOptions}
              additionalStyles={styles.listSelectFilter}
              defaultValue={filterOptions[1]} // defaults to active users
              onChange={(data) => setStatus(data.value as UserStatusFilter)}
            />
          </div>
        }
      >
        <Table paginationProps={paginationProps}>
          <TableHeader>
            <TableRow>
              <TableCell width={nameColWidth}>Name</TableCell>
              <TableCell width={emailColWidth}>Email</TableCell>
              <TableCell width={statusColWidth}>Status</TableCell>
            </TableRow>
          </TableHeader>

          <TableBody emptyResult={emptyResult}>
            {users && users.length > 0 ? (
              users.map((data: UIClientUser, index: number) => {
                return (
                  <TableRow key={index} linkTo={`/companies/${contextId}/users/${data.id}`}>
                    <TableCell width={nameColWidth}>{`${data.firstName} ${data.lastName}`}</TableCell>
                    <TableCell width={emailColWidth}>{data.email}</TableCell>

                    <TableCell width={statusColWidth}>
                      <StatusIndicator active={data.enabled} activeLabel={'Active'} inactiveLabel={'Inactive'} />
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRowsSkeleton rows={50} colsWidth={colsWidth} />
            )}
          </TableBody>
        </Table>
      </LayoutContent>
    </>
  );
};

export default UsersListPage;
