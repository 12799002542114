import React, { useContext, useEffect, useRef } from 'react';
import classnames from 'classnames';
import styles from './Cell.module.scss';
import _ from 'lodash';
import { CellProps } from './Cell';
import { TableV2Context } from '../TableV2';
import { useSearchParams } from '../../../hooks/useSearchParams';
import { useHistory, useLocation } from 'react-router-dom';
import { objectToQueryParamsString } from '../../../utils/urlUtils/objectToQueryParamsString';

export interface HeaderCellProps extends CellProps {
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  maxWidth: number;
  minWidth: number;
}

export enum HeaderCellConstants {
  defaultCellMaxWidth = 500,
  defaultCellMinWidth = 50,
  sortDirectionPadding = 18,
}

export const HeaderCell = ({ columnName, rowData, classes, onClick, align, maxWidth, minWidth }: HeaderCellProps) => {
  const { dispatch, state } = useContext(TableV2Context);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref?.current && !state.columnSizesUpdated[columnName]) {
      const currentWidth = Math.ceil(ref.current.getBoundingClientRect().width);
      dispatch({
        type: 'SET_COLUMN_SIZE',
        payload: { columnName, width: currentWidth < minWidth ? minWidth : currentWidth },
      });
    }
  }, [dispatch, ref, state, columnName, minWidth]);

  const size = state.columnSizesUpdated[columnName]
    ? (state.columnSizes[columnName] || 0) < maxWidth
      ? state.columnSizes[columnName]
      : maxWidth
    : undefined;

  return (
    <div
      ref={ref}
      style={{
        minWidth: size || minWidth,
        width: size || minWidth,
        maxWidth: size ? size + HeaderCellConstants.sortDirectionPadding : 'unset',
      }}
      onClick={onClick}
      className={classnames(classes, styles.tableV2__cellHeader, styles.tableV2__cell, {
        [styles[`tableV2__cellTextAlign--${align}`]]: align,
      })}
    >
      {rowData[columnName]}
    </div>
  );
};

export const createSortableHeaderCell =
  (sortKey: string, headerCellProps: Partial<HeaderCellProps> = {}) =>
  (props: HeaderCellProps) => {
    const {
      rowData,
      columnName,
      classes,
      align,
      maxWidth = HeaderCellConstants.defaultCellMaxWidth,
      minWidth = HeaderCellConstants.defaultCellMinWidth,
    } = {
      ..._.merge({}, headerCellProps, props),
      classes: classnames(headerCellProps.classes, props.classes),
    };
    const { sortDirection, sortColumn: urlSortColumn, page, ...restSearchParameters } = useSearchParams();
    const { push } = useHistory();
    const { pathname } = useLocation();
    const sortedByThisColumn = sortDirection !== undefined && urlSortColumn === sortKey;
    const cellContent = (
      <span
        className={classnames(styles.cellHeader__content, {
          [styles[`cellHeader__content--sortDirection-${sortDirection}`]]: sortedByThisColumn,
        })}
      >
        {rowData[columnName]}
      </span>
    );
    const onClick = () => {
      const newSortDirection = !(sortedByThisColumn && sortDirection === 'asc') ? 'asc' : 'desc';
      const queryParameters = {
        ...restSearchParameters,
        sortDirection: newSortDirection,
        sortColumn: sortKey,
      };
      if (page) {
        // any change of filters will reset pagination
        Object.assign(queryParameters, { page: '1' });
      }

      const queryParamsString = objectToQueryParamsString(queryParameters);

      push(`${pathname}?${queryParamsString}`);
    };

    return (
      <HeaderCell
        maxWidth={maxWidth}
        minWidth={minWidth}
        onClick={onClick}
        classes={classes}
        columnName={columnName}
        align={align}
        rowData={{ ...rowData, [columnName]: cellContent }}
      />
    );
  };

/**
 * @deprecated function is renamed, use createSortableHeaderCell() instead
 */
export const getSortableHeaderCell = createSortableHeaderCell;
