import {
  IClientLocation,
  IOrderDetails,
  OrderFulfillmentStatus,
} from '@ready/dashboardv2api.contracts';
import { OrderSection } from '../../../enums/orders.enum';
import LocationsService from '../../../locations/services/LocationsService';
import { OrdersService } from '../../../services/ordersService';
import { ISinglePlacedOrder } from '../../../services/types/orders.type';
import mapOrdersToPlacedOrders from '../../../utils/orderUtils/mapOrdersToPlacedOrders';
import { IOrderListUrlQuery } from '../../initialStates/orders/orderList';
import { Action } from '../../types';
import { pageErrorState, toastErrorState } from '../uiActions/responseStateActions';
import { ORDERS_ACTIONS } from './types';

export const fetchLocationDetails = (companyId: string, locationId: string) => async (dispatch: any) => {
  dispatch(setLoadingLocationDetails(true));

  try {
    const locationDetails = await LocationsService.getClientLocationById(companyId, locationId);

    dispatch(setClientLocationDetails(locationDetails));
  } catch (error) {
    dispatch(pageErrorState(error.status, error.message));
  } finally {
    dispatch(setLoadingLocationDetails(false));
  }
};

export const updateOrderList = (contextId: string, locationId: string, day?: string) => async (dispatch: any) => {
  try {
    const orderList: IOrderDetails[] = await OrdersService.getOrderList(contextId, locationId, day);

    dispatch(setOrderList(mapOrdersToPlacedOrders(orderList)));
    dispatch(sortOrdersInPlace());
  } catch (err) {
    dispatch(toastErrorState(err.status, err.message));
  }
};

export const fetchOrderList = (contextId: string, locationId: string, day?: string) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOrders(true));
    // will retrieve one location from array for now, in the future we may have to retrieve multiple locations as per requirements
    // the offset time for the day will have to be taken into consideration as well, but as per Vlad's ask, I am just hardcoding
    // a value for offset time until we can get clearer requirements with upcoming multiple locations
    const orderList: IOrderDetails[] = await OrdersService.getOrderList(contextId, locationId, day);

    dispatch(setOrderList(mapOrdersToPlacedOrders(orderList)));
    dispatch(sortOrdersInPlace());
  } catch (err) {
    dispatch(pageErrorState(err.status, err.message));
  } finally {
    dispatch(setLoadingOrders(false));
  }
};

export const sortOrdersInPlace = () => ({
  type: ORDERS_ACTIONS.SORT_ORDERS_IN_PLACE,
});

export const setOrderStatus =
  (
    contextId: string,
    locationId: string,
    updatedStatus: OrderFulfillmentStatus,
    currentSection: OrderSection,
    orderId: string,
    invoiceId?: string
  ) =>
  async (dispatch: any) => {
    try {
      dispatch({
        type: ORDERS_ACTIONS.SET_PROCESSING_ORDER,
        payload: { currentSection, orderId, val: true },
      });

      await OrdersService.updateOrderStatus(contextId, locationId, orderId, updatedStatus, invoiceId);

      dispatch({
        type: ORDERS_ACTIONS.UPDATE_ORDER_STATUS,
        payload: {
          updatedStatus,
          currentSection,
          orderId,
        },
      });
    } catch (err) {
      dispatch(toastErrorState(err.status, err.message));
      throw err;
    } finally {
      // if this order was set to complete, that means that the order is being moved out of its
      // current bucket and into the completed orders bucket. when this finally block runs, the
      // original bucket that the order was in no longer houses it, and thus we need to specify
      // that its updated section is the completed section so that the reducer knows where to look.
      if (updatedStatus === OrderFulfillmentStatus.Complete) {
        dispatch({
          type: ORDERS_ACTIONS.SET_PROCESSING_ORDER,
          payload: { currentSection: OrderSection.COMPLETE, orderId, val: false },
        });
      } else {
        let newCurrentSection: OrderSection = currentSection;

        if (currentSection === OrderSection.COMPLETE) {
          newCurrentSection = OrderSection.OFFSET;
        }

        dispatch({
          type: ORDERS_ACTIONS.SET_PROCESSING_ORDER,
          payload: { currentSection: newCurrentSection, orderId, val: false },
        });

        dispatch({ type: ORDERS_ACTIONS.SORT_ORDERS_IN_PLACE });
      }
    }
  };

export const toggleOrdering = (contextId: string, locationId: string, isOn: boolean) => async (dispatch: any) => {
  dispatch(setProcessingOrderToggle(true));

  try {
    await OrdersService.toggleOrdering(contextId, locationId, isOn);

    // If no error, then update was successful
    dispatch(setLocationOrderToggle(isOn));
  } catch (err) {
    dispatch(toastErrorState(err.status, err.message));
  } finally {
    dispatch(setProcessingOrderToggle(false));
  }
};

export const toggleAlcoholOrdering =
  (contextId: string, locationId: string, isOn: boolean) => async (dispatch: any) => {
    dispatch(setProcessingAlcoholOrderToggle(true));

    try {
      await OrdersService.toggleAlcoholOrdering(contextId, locationId, isOn);

      // If no error, then update was successful
      dispatch(setLocationAlcoholOrderToggle(isOn));
    } catch (err) {
      dispatch(toastErrorState(err.status, err.message));
    } finally {
      dispatch(setProcessingAlcoholOrderToggle(false));
    }
  };

export const initOrdersList = () => ({
  type: ORDERS_ACTIONS.INIT_ORDERS,
});

export const setLocationListUrlQuery = (urlQuery: IOrderListUrlQuery) => ({
  type: ORDERS_ACTIONS.SET_ORDER_LIST_URL_QUERY,
  payload: urlQuery,
});

export const initLocationDetails = () => ({
  type: ORDERS_ACTIONS.INIT_LOCATIONS,
});

const setOrderList = (orders: ISinglePlacedOrder[]): Action => ({
  type: ORDERS_ACTIONS.SET_ORDER_LIST,
  payload: orders,
});

const setLoadingOrders = (isLoading: boolean) => ({
  type: ORDERS_ACTIONS.SET_LOADING_ORDERS,
  payload: isLoading,
});

const setLoadingLocationDetails = (isLoading: boolean) => ({
  type: ORDERS_ACTIONS.SET_LOADING_LOCATION_DETAILS,
  payload: isLoading,
});

const setClientLocationDetails = (details: IClientLocation) => ({
  type: ORDERS_ACTIONS.SET_CLIENT_LOCATION_DETAILS,
  payload: details,
});

const setProcessingOrderToggle = (processing: boolean) => ({
  type: ORDERS_ACTIONS.SET_LOCATION_ORDER_TOGGLE_PROCESSING,
  payload: processing,
});

const setProcessingAlcoholOrderToggle = (processing: boolean) => ({
  type: ORDERS_ACTIONS.SET_LOCATION_ALCOHOL_ORDER_TOGGLE_PROCESSING,
  payload: processing,
});

const setLocationOrderToggle = (isOn: boolean) => ({
  type: ORDERS_ACTIONS.UPDATE_LOCATION_ORDER_TOGGLE,
  payload: isOn,
});

const setLocationAlcoholOrderToggle = (isOn: boolean) => ({
  type: ORDERS_ACTIONS.UPDATE_LOCATION_ALCOHOL_ORDER_TOGGLE,
  payload: isOn,
});
