import React from 'react';
import Container from '../Container/Container';

export interface InputErrorProps {
  message: string;
}

const InputError = (props: InputErrorProps) => {
  const { message } = props;
  return (
    <Container additionalClassName='input-error-container'>
      <p className='input-error'>{message}</p>
    </Container>
  );
};

export default InputError;
