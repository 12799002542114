import React from 'react';
import Button from '../Button/Button';
import styles from './image-uploader.module.scss';
import readyProgressSpinner from '../../styles/assets/ready-progress-spinner-gray.svg';
import { IAsset } from '@ready/dashboardv2api.contracts';
import { AssetService } from '../../services/assetService';
// redux
import { connect, ConnectedProps } from 'react-redux';
import { toastErrorState } from '../../redux/actions/uiActions/responseStateActions';
import { setFormIsDirty } from 'redux/actions/uiActions/formStateActions';
import { useAppDispatch } from 'redux/store';
import { compositeStyles } from 'utils/cssUtils';

export type ImageUploaderProps = ReduxProps & {
  aspectRatio: string;
  onUpload: (asset: Partial<IAsset>, image?: HTMLImageElement) => any;
  imageKey?: string;
  maxWidth?: number;
  formattedGuideText?: JSX.Element;
  additionalHelpText?: string;
  isProcessing?: boolean;
  maxFileSizeKb?: number;
  isSyncing?: boolean;
  onImageChange?: any;
  disabled?: boolean;

  readonly?: boolean;
};

// aspectRatio format: heightRatio : widthRatio
// returns a number [0, 100]
export const getAspectRatio = (aspectRatio: string): number => {
  if (aspectRatio) {
    const [heightRatio, widthRatio] = aspectRatio.split(':');
    if (!!widthRatio) {
      const quotient = parseInt(heightRatio) / parseInt(widthRatio);
      return quotient * 100;
    }
  }
  return 100; // fallback case: return just 100
};

const invalidFileSize = (fileSize: number, maxFileSizeKb: number): boolean => {
  if (!!maxFileSizeKb) {
    const maxFileSizeBytes = maxFileSizeKb * 1024;
    return fileSize > maxFileSizeBytes;
  }
  return false;
};

const ImageUploader = (props: ImageUploaderProps) => {
  const {
    aspectRatio,
    onUpload,
    imageKey = '',
    maxWidth,
    formattedGuideText,
    additionalHelpText,
    isProcessing = false,
    toastErrorState,
    maxFileSizeKb = 0,
    isSyncing = false,
    onImageChange,
    disabled = false,
    readonly = false,
  } = props;
  const [uploading, setUploading] = React.useState(false);
  const [imageData, setImageData] = React.useState<FormData | null>();
  const [imageElement, setImageElement] = React.useState<HTMLImageElement | null>();
  const [imageUrl, setImageUrl] = React.useState('');
  const [validationErrors, setValidationErrors] = React.useState<string[]>([]);
  const labelRef = React.useRef<HTMLLabelElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  // aspect ratio
  const aspectRatioPercentage = getAspectRatio(aspectRatio);
  const aspectRatioStyles = {
    paddingTop: `${aspectRatioPercentage}%`,
  };

  const handleAddImageButtonClick = () => {
    if (readonly || !labelRef || !labelRef.current) return;
    labelRef.current.click();
  };

  const handleRemoveImageButtonClick = () => {
    dispatch(setFormIsDirty(true));
    if (inputRef && inputRef.current) {
      inputRef.current.value = '';
      setImageData(null);
      setImageElement(null);
      const removedAsset: Partial<IAsset> = {
        _id: '',
        fileKey: '',
        fileName: '',
      };
      onUpload(removedAsset);
    }
  };

  const invalidImageFile = (file: File) => {
    const errors: string[] = [];
    if (!file.type.startsWith('image/')) {
      errors.push('Only image files are supported');
    }
    if (invalidFileSize(file.size, maxFileSizeKb)) {
      const maxFileSizeMb = maxFileSizeKb / 1024;
      errors.push(`The image size should not be greater than ${maxFileSizeKb} KB (${maxFileSizeMb.toFixed(2)} MB)`);
    }
    setValidationErrors(errors);
    return !!errors.length;
  };

  // image processing -----
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      const file = files[0];
      if (invalidImageFile(file)) return;
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        // form data
        const imageData = new FormData();
        imageData.append('file', file);

        // get image dimensions
        const img = new Image();
        img.onload = () => {
          setImageData(imageData);
          setImageElement(img);
        };
        img.src = URL.createObjectURL(file);
      });
      reader.readAsDataURL(file);
    }
  };

  // upload image to API service
  React.useEffect(() => {
    if (imageData && imageElement) {
      setUploading(true);
      if (typeof onImageChange === 'function') {
        onImageChange();
      }
      const saveImage = async () => {
        try {
          const uploadedAsset = (await AssetService.uploadImage(imageData)) as IAsset;
          if (uploadedAsset._id) {
            const file = {
              _id: uploadedAsset._id,
              fileName: uploadedAsset.fileName,
              fileKey: uploadedAsset.fileKey,
            };
            onUpload(file, imageElement);
          }
          setUploading(false);
        } catch (err) {
          toastErrorState(err.status, err.message);
          setUploading(false);
        }
      };
      saveImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageData, imageElement]);

  // get the image url from fileKey
  React.useEffect(() => {
    const getImageUrl = async (imageKey: string) => {
      let imageUrl = '';
      setUploading(true);
      if (imageKey) {
        const file = {
          fileKey: imageKey,
        };
        imageUrl = await AssetService.getAssetUrl(file);
      }

      setImageUrl(imageUrl);
      setUploading(false);
    };

    getImageUrl(imageKey);
  }, [imageKey]);

  // image uploader loading state
  // it consists of the disjunction between the uploading (internal) state
  // and an optional isSyncing (external) state
  const loading = uploading || isSyncing;

  const placeholderMaxWidth = maxWidth ? `${maxWidth}px` : '100%';
  const backgroundImage = loading ? '' : imageUrl;
  const placeholderStyles = {
    backgroundImage: `url('${backgroundImage}')`,
    maxWidth: placeholderMaxWidth,
  };

  return (
    <>
      <label className={compositeStyles([styles.imageUploaderLabel, { readonly: readonly }], styles)} ref={labelRef}>
        <div className={styles.imageUploaderPlaceholder} style={placeholderStyles}>
          {!imageUrl && !loading && <i className={`icon-dashboard-icons_camera ${styles.cameraIcon}`}></i>}
          {loading && <img src={readyProgressSpinner} alt='loading' className={styles.loadingSpinner} />}
          <div style={aspectRatioStyles}></div>
        </div>
        <input
          type='file'
          accept='image/jpeg, image/png'
          onChange={handleFileChange}
          ref={inputRef}
          className={styles.imageUPloaderInput}
          disabled={loading || isProcessing || disabled || readonly}
        />
      </label>
      <div className={styles.imageUploaderHelpText}>
        {formattedGuideText && <div className={styles.formattedGuideText}>{formattedGuideText}</div>}
        {additionalHelpText && <p className={styles.additionalHelpText}>{additionalHelpText}</p>}
      </div>

      {!!validationErrors.length && (
        <div className={styles.imageUploaderErrors}>
          {validationErrors.map((error: string, index: number) => (
            <span className={styles.imageUploaderErrorText} key={index}>
              {error}
            </span>
          ))}
        </div>
      )}
      {!readonly && (
        <div className={styles.imageUploaderControls}>
          {imageUrl ? (
            <>
              <Button
                variant='primary'
                label='Change Image'
                onClick={handleAddImageButtonClick}
                disabled={loading || isProcessing || disabled}
              />
              <Button
                variant='secondary'
                label='Remove'
                onClick={handleRemoveImageButtonClick}
                disabled={loading || isProcessing || disabled}
              />
            </>
          ) : (
            <Button
              variant='primary'
              label='Add Image'
              onClick={handleAddImageButtonClick}
              disabled={loading || isProcessing || disabled}
            />
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({});
const actionCreators = {
  toastErrorState,
};
const connector = connect(mapStateToProps, actionCreators);
type ReduxProps = ConnectedProps<typeof connector>;
export default connector(ImageUploader);
