import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { getCompanyInfoById } from './thunk';

interface State {
  companyName: string;
  isLoading: boolean;
}

const initialState: State = {
  companyName: '',
  isLoading: false,
};

const companyInfoSlice = createSlice({
  name: 'companies/details/info',
  initialState: initialState,
  reducers: {
    resetPaymentProcessingState: () => initialState,
  },
  extraReducers: (builder: ActionReducerMapBuilder<State>) => {
    builder.addCase(getCompanyInfoById.pending, (state: State) => {
      state.isLoading = true;
    });
    builder.addCase(getCompanyInfoById.fulfilled, (state: State, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.companyName = action.payload.name;
      }
    });
    builder.addCase(getCompanyInfoById.rejected, (state: State) => {
      state.isLoading = false;
    });
  },
});
export const { resetPaymentProcessingState } = companyInfoSlice.actions;

export { companyInfoSlice };
