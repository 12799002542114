import { ILocation } from '../../../services/types/ILocation.type';
import { ILocationToShow, IPagination } from '@ready/dashboardv2api.contracts';
import InfiniteScrollingListState from '../../../types/InfiniteScrollingListState.interface';
import InfiniteScrollingListStateHelper from '../../../utils/InfiniteScrollingList/InfiniteScrollingListStateHelper';

export interface ILocationPicker {
  loading: boolean;
  pagination: IPagination;
  locations: ILocation[];
}

export interface ICompanyLocations extends InfiniteScrollingListState<ILocationToShow> {
  permissionIndex: number;
}

export const companyLocations = {
  ...InfiniteScrollingListStateHelper.initialState(),
  permissionIndex: -1,
} as ICompanyLocations;
