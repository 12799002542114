import { IBadge } from '@ready/menu.core';

export interface BadgesListState {
  loading: boolean;
  badges: IBadge[];
}

export const badgesListState: BadgesListState = {
  loading: false,
  badges: [],
};
