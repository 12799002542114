import { IItemGroupDetailsFields, IItemGroupViewEditPage } from './ItemGroupsState';
import { pageErrorState, toastErrorState, set200Toast } from '../../redux/actions/uiActions/responseStateActions';
import { setFormIsDirty } from '../../redux/actions/uiActions/formStateActions';
import MenuBuilderService from '../services/MenuBuilderService';
import { IMenuItemGroup, IMenuItemGroupRequest, IEmbeddedMenuItem, IScheduleLink } from '@ready/menu.core';
import {
  addItemGroupToMenuSection,
  addItemGroupToMenuGallerySection,
  prepareItemsAndGroupsModal,
} from './MenusActions';
import { selectItemInMenuItemsModal } from './ItemsAndModsActions';
import { ILink } from './MenusState';
import { IAsset } from '@ready/dashboardv2api.contracts';
import { AppDispatch } from 'redux/store';
import { isSharedMenu } from 'sharedMenuItems/sharedMenuItems.utils';
import { createItemGroup } from 'sharedMenuItems/pages/itemGroups/itemGroupService';
import { mapToCreateEmptyItemGroup } from 'sharedMenuItems/pages/menu/menu.mapper';

export const ITEM_GROUPS_ACTIONS = {
  ADD_ITEM_GROUP_TO_ITEM_GROUP_SELECTION: 'ADD_ITEM_GROUP_TO_ITEM_GROUP_SELECTION',
  REMOVE_ITEM_GROUP_FROM_ITEM_GROUP_SELECTION: 'REMOVE_ITEM_GROUP_FROM_ITEM_GROUP_SELECTION',

  PREPARE_MENU_BUILDER_NEW_ITEM_GROUP_MODAL: 'PREPARE_MENU_BUILDER_NEW_ITEM_GROUP_MODAL',
  UPDATE_MENU_BUILDER_ITEM_GROUP_DISPLAY_NAME: 'UPDATE_MENU_BUILDER_ITEM_GROUP_DISPLAY_NAME',
  UPDATE_MENU_BUILDER_ITEM_GROUP_DESCRIPTION: 'UPDATE_MENU_BUILDER_ITEM_GROUP_DESCRIPTION',

  SAVE_MENU_BUILDER_ITEM_GROUP_BEGIN: 'SAVE_MENU_BUILDER_ITEM_GROUP_BEGIN',
  SAVE_MENU_BUILDER_ITEM_GROUP_VALIDATED: 'SAVE_MENU_BUILDER_ITEM_GROUP_VALIDATED',
  SAVE_MENU_BUILDER_ITEM_GROUP_SUCCESS: 'SAVE_MENU_BUILDER_ITEM_GROUP_SUCCESS',
  SAVE_MENU_BUILDER_ITEM_GROUP_ERROR: 'SAVE_MENU_BUILDER_ITEM_GROUP_ERROR',

  // SAVE_ITEM_GROUP (tab page related actions)
  SAVE_ITEM_GROUP_BEGIN: 'SAVE_ITEM_GROUP_BEGIN',
  SAVE_ITEM_GROUP_SUCCESS: 'SAVE_ITEM_GROUP_SUCCESS',
  SAVE_ITEM_GROUP_ERROR: 'SAVE_ITEM_GROUP_ERROR',
  SAVE_ITEM_GROUP_UPDATE_DISPLAY_NAME: 'SAVE_ITEM_GROUP_UPDATE_DISPLAY_NAME',
  SAVE_ITEM_GROUP_UPDATE_DESCRIPTION: 'SAVE_ITEM_GROUP_UPDATE_DESCRIPTION',
  SAVE_ITEM_GROUP_UPDATE_SCHEDULE: 'SAVE_ITEM_GROUP_UPDATE_SCHEDULE',
  SAVE_ITEM_GROUP_UPDATE_DISPLAY_NAME_VALIDATION: 'SAVE_ITEM_GROUP_UPDATE_DISPLAY_NAME_VALIDATION',
  SAVE_ITEM_GROUP_UPDATE_VALIDATION: 'SAVE_ITEM_GROUP_UPDATE_VALIDATION',

  RESET_ITEM_GROUP: 'RESET_ITEM_GROUP',

  // LIST_ITEM_GROUPS (tab page related actions)
  LIST_ITEM_GROUPS_BEGIN: 'LIST_ITEM_GROUPS_BEGIN',
  LIST_ITEM_GROUPS_SUCCESS: 'LIST_ITEM_GROUPS_SUCCESS',
  LIST_ITEM_GROUPS_ERROR: 'LIST_ITEM_GROUPS_ERROR',

  // VIEW_ITEM_GROUP (tab page related actions)
  VIEW_ITEM_GROUP_BEGIN: 'VIEW_ITEM_GROUP_BEGIN',
  VIEW_ITEM_GROUP_SUCCESS: 'VIEW_ITEM_GROUP_SUCCESS',
  VIEW_ITEM_GROUP_ERROR: 'VIEW_ITEM_GROUP_ERROR',

  // UPDATE_ITEM_GROUP (tab page related actions)
  UPDATE_ITEM_GROUP_SUCCESS: 'UPDATE_ITEM_GROUP_SUCCESS',
  UPDATE_DETAILS_PANEL_EDIT_STATE: 'UPDATE_DETAILS_PANEL_EDIT_STATE',
  UPDATE_DETAILS_PANEL_PROCESSING_STATE: 'UPDATE_DETAILS_PANEL_PROCESSING_STATE',
  UPDATE_ITEMS_PANEL_EDIT_STATE: 'UPDATE_ITEMS_PANEL_EDIT_STATE',
  UPDATE_ITEMS_PANEL_PROCESSING_STATE: 'UPDATE_ITEMS_PANEL_PROCESSING_STATE',
  UPDATE_IMAGE_PROCESSING_STATE: 'UPDATE_IMAGE_PROCESSING_STATE',
  UPDATE_THUMBNAIL_IMAGE_PROCESSING_STATE: 'UPDATE_THUMBNAIL_IMAGE_PROCESSING_STATE',
  RESET_DETAILS_PANEL_FORM: 'RESET_DETAILS_PANEL_FORM',
  UPDATE_ITEM_GROUP_UPDATE_DISPLAY_NAME: 'UPDATE_ITEM_GROUP_UPDATE_DISPLAY_NAME',
  UPDATE_ITEM_GROUP_UPDATE_DESCRIPTION: 'UPDATE_ITEM_GROUP_UPDATE_DESCRIPTION',
  UPDATE_ITEM_GROUP_UPDATE_SCHEDULE: 'UPDATE_ITEM_GROUP_UPDATE_SCHEDULE',
  UPDATE_ITEM_GROUP_UPDATE_DISPLAY_NAME_VALIDATION: 'UPDATE_ITEM_GROUP_UPDATE_DISPLAY_NAME_VALIDATION',
  UPDATE_ITEM_GROUP_UPDATE_SCHEDULE_VALIDATION: 'UPDATE_ITEM_GROUP_UPDATE_SCHEDULE_VALIDATION',
  SET_ITEM_GROUP_UPDATE_VALIDATED: 'SET_ITEM_GROUP_UPDATE_VALIDATED',
  UPDATE_ITEM_GROUP_IMAGE: 'UPDATE_ITEM_GROUP_IMAGE',
  UPDATE_ITEM_GROUP_THUMBNAIL_IMAGE: 'UPDATE_ITEM_GROUP_THUMBNAIL_IMAGE',
  UPDATE_ITEM_GROUP_ITEM_VISIBILITY: 'UPDATE_ITEM_GROUP_ITEM_VISIBILITY',
  REMOVE_ITEM_GROUP_ITEM: 'REMOVE_ITEM_GROUP_ITEM',
  UPDATE_ITEM_GROUP_REORDER_ITEMS: 'UPDATE_ITEM_GROUP_REORDER_ITEMS',
  UPDATE_ITEM_GROUP_ITEMS_SORT_ORDER: 'UPDATE_ITEM_GROUP_ITEMS_SORT_ORDER',
  UPDATE_ITEM_GROUP_ADD_ITEM: 'UPDATE_ITEM_GROUP_ADD_ITEM',
  UPDATE_ITEM_GROUP_THUMBNAIL_IMAGE_ID: 'UPDATE_ITEM_GROUP_THUMBNAIL_IMAGE_ID',
  UPDATE_ITEM_GROUP_BANNER_IMAGE_ID: 'UPDATE_ITEM_GROUP_BANNER_IMAGE_ID',
  REVERT_DETAIL_PAGE_CHANGES: 'REVERT_DETAIL_PAGE_CHANGES',

  // DELETE_ITEM_GROUP (tab page related actions)
  DELETE_ITEM_GROUP_BEGIN: 'DELETE_ITEM_GROUP_BEGIN',
  DELETE_ITEM_GROUP_SUCCESS: 'DELETE_ITEM_GROUP_SUCCESS',
  DELETE_ITEM_GROUP_ERROR: 'DELETE_ITEM_GROUP_ERROR',

  BLANK_VIEW_EDIT_ITEM_GROUP_PAGE: 'BLANK_VIEW_EDIT_ITEM_GROUP_PAGE',
};

export const prepareNewItemGroupModal = (visible: boolean) => ({
  type: ITEM_GROUPS_ACTIONS.PREPARE_MENU_BUILDER_NEW_ITEM_GROUP_MODAL,
  payload: visible,
});

export const updateItemGroupDisplayName = (displayName: string) => ({
  type: ITEM_GROUPS_ACTIONS.UPDATE_MENU_BUILDER_ITEM_GROUP_DISPLAY_NAME,
  payload: displayName,
});

export const updateItemGroupDescription = (description: string) => ({
  type: ITEM_GROUPS_ACTIONS.UPDATE_MENU_BUILDER_ITEM_GROUP_DESCRIPTION,
  payload: description,
});

export const saveNewItemGroupInSection =
  (companyId: string, locationId: string, itemGroup: IMenuItemGroup) => async (dispatch: AppDispatch) => {
    const validationResult = MenuBuilderService.validateItemGroup(itemGroup);
    if (validationResult.errorsFound) {
      dispatch({
        type: ITEM_GROUPS_ACTIONS.SAVE_MENU_BUILDER_ITEM_GROUP_VALIDATED,
        payload: validationResult.itemGroup,
      });
      return;
    }

    dispatch({ type: ITEM_GROUPS_ACTIONS.SAVE_MENU_BUILDER_ITEM_GROUP_BEGIN });
    try {
      let createdItemGroup: IMenuItemGroup;
      if (isSharedMenu(companyId, locationId)) {
        const newSharedMenuItemGroup = await createItemGroup(companyId, mapToCreateEmptyItemGroup(itemGroup));
        createdItemGroup = mapToCreateEmptyItemGroup(newSharedMenuItemGroup);
      } else {
        createdItemGroup = await MenuBuilderService.createItemGroup(companyId, locationId, itemGroup);
      }
      dispatch({ type: ITEM_GROUPS_ACTIONS.SAVE_MENU_BUILDER_ITEM_GROUP_SUCCESS });
      dispatch(addItemGroupToMenuSection(createdItemGroup));
      dispatch(setFormIsDirty(true));
      dispatch(prepareItemsAndGroupsModal(false));
    } catch (error) {
      dispatch({ type: ITEM_GROUPS_ACTIONS.SAVE_MENU_BUILDER_ITEM_GROUP_ERROR });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const saveNewItemGroupInItemGallery =
  (companyId: string, locationId: string, itemGroup: IMenuItemGroup) => async (dispatch: AppDispatch) => {
    const validationResult = MenuBuilderService.validateItemGroup(itemGroup);
    if (validationResult.errorsFound) {
      dispatch({
        type: ITEM_GROUPS_ACTIONS.SAVE_MENU_BUILDER_ITEM_GROUP_VALIDATED,
        payload: validationResult.itemGroup,
      });
      return;
    }

    dispatch({ type: ITEM_GROUPS_ACTIONS.SAVE_MENU_BUILDER_ITEM_GROUP_BEGIN });
    try {
      let createdItemGroup: IMenuItemGroup;

      if (isSharedMenu(companyId, locationId)) {
        const newSharedMenuItemGroup = await createItemGroup(companyId, mapToCreateEmptyItemGroup(itemGroup));

        createdItemGroup = mapToCreateEmptyItemGroup(newSharedMenuItemGroup);
      } else {
        createdItemGroup = await MenuBuilderService.createItemGroup(companyId, locationId, itemGroup);
      }
      dispatch({ type: ITEM_GROUPS_ACTIONS.SAVE_MENU_BUILDER_ITEM_GROUP_SUCCESS });
      dispatch(addItemGroupToMenuGallerySection(createdItemGroup));
      dispatch(setFormIsDirty(true));
      dispatch(prepareItemsAndGroupsModal(false));
    } catch (error) {
      dispatch({ type: ITEM_GROUPS_ACTIONS.SAVE_MENU_BUILDER_ITEM_GROUP_ERROR });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const saveItemUpdateDisplayName = (displayName: string) => ({
  type: ITEM_GROUPS_ACTIONS.SAVE_ITEM_GROUP_UPDATE_DISPLAY_NAME,
  payload: displayName,
});

export const saveItemUpdateDescription = (description: string) => ({
  type: ITEM_GROUPS_ACTIONS.SAVE_ITEM_GROUP_UPDATE_DESCRIPTION,
  payload: description,
});

export const saveItemUpdateSchedule = (schedule: IScheduleLink<ILink> | null) => ({
  type: ITEM_GROUPS_ACTIONS.SAVE_ITEM_GROUP_UPDATE_SCHEDULE,
  payload: schedule,
});

export const updateItemGroupBannerImageId =
  (image: Partial<IAsset>, imageEl?: HTMLImageElement) => async (dispatch: AppDispatch) => {
    dispatch(setFormIsDirty(true));
    dispatch({
      type: ITEM_GROUPS_ACTIONS.UPDATE_ITEM_GROUP_BANNER_IMAGE_ID,
      payload: {
        fileKey: image.fileKey,
        width: imageEl?.naturalWidth ?? 0,
        height: imageEl?.naturalHeight ?? 0,
      },
    });
  };

export const updateItemGroupThumbnailImageId =
  (image: Partial<IAsset>, imageEl?: HTMLImageElement) => async (dispatch: AppDispatch) => {
    dispatch(setFormIsDirty(true));

    dispatch({
      type: ITEM_GROUPS_ACTIONS.UPDATE_ITEM_GROUP_THUMBNAIL_IMAGE_ID,
      payload: {
        fileKey: image.fileKey,
        width: imageEl?.naturalWidth ?? 0,
        height: imageEl?.naturalHeight ?? 0,
      },
    });
  };

// tab page related actions
export const listItemGroups =
  (companyId: string, locationId: string, query: string = '', page: number) =>
  async (dispatch: AppDispatch) => {
    dispatch({
      type: ITEM_GROUPS_ACTIONS.LIST_ITEM_GROUPS_BEGIN,
    });
    try {
      const pageSize = 50;
      const results = await MenuBuilderService.getItemGroups(companyId, locationId, query, page, pageSize);
      dispatch({
        type: ITEM_GROUPS_ACTIONS.LIST_ITEM_GROUPS_SUCCESS,
        payload: { results, query, page },
      });
    } catch (error) {
      dispatch({
        type: ITEM_GROUPS_ACTIONS.LIST_ITEM_GROUPS_ERROR,
      });
      dispatch(pageErrorState(error.status, error.message));
    }
  };

export const selectItemGroupInItemGroupsModal = (id: string) => ({
  type: ITEM_GROUPS_ACTIONS.ADD_ITEM_GROUP_TO_ITEM_GROUP_SELECTION,
  payload: id,
});

export const saveItemUpdateDisplayNameValidation = (hasError: boolean, errorMessage: string) => ({
  type: ITEM_GROUPS_ACTIONS.SAVE_ITEM_GROUP_UPDATE_DISPLAY_NAME_VALIDATION,
  payload: { hasError, errorMessage },
});

export const validateItemGroupsCreatePage = (fields: IMenuItemGroup) => async (dispatch: AppDispatch) => {
  if (fields.displayName === '') {
    dispatch(saveItemUpdateDisplayNameValidation(true, 'Display name is required.'));
    dispatch(setItemGroupUpdateValidated(false));
  } else {
    dispatch(saveItemUpdateDisplayNameValidation(false, ''));
    dispatch(setItemGroupUpdateValidated(true));
  }
};

export const saveItemGroupBegin = () => ({
  type: ITEM_GROUPS_ACTIONS.SAVE_ITEM_GROUP_BEGIN,
});

export const saveItemGroupSuccess = (itemGroupSaved: string) => ({
  type: ITEM_GROUPS_ACTIONS.SAVE_ITEM_GROUP_SUCCESS,
  payload: itemGroupSaved,
});

export const saveItemGroupError = () => ({
  type: ITEM_GROUPS_ACTIONS.SAVE_ITEM_GROUP_ERROR,
});

export const saveItemGroup =
  (companyId: string, locationId: string, detailsPanel: IItemGroupDetailsFields, groupItems: IEmbeddedMenuItem[]) =>
  async (dispatch: AppDispatch) => {
    dispatch(saveItemGroupBegin());
    const { validation, ...restObj } = detailsPanel;
    const newItemGroup = {
      ...restObj,
      items: groupItems,
    };
    try {
      const result = await MenuBuilderService.createItemGroup(companyId, locationId, newItemGroup);
      dispatch(setFormIsDirty(false));
      const { _id } = result;
      dispatch(saveItemGroupSuccess(_id));
      dispatch(set200Toast());
    } catch (error) {
      dispatch(saveItemGroupError());
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const resetItemGroupForm = () => ({
  type: ITEM_GROUPS_ACTIONS.RESET_ITEM_GROUP,
});

export const deselectItemGroupFromItemGroupsModal = (id: string) => ({
  type: ITEM_GROUPS_ACTIONS.REMOVE_ITEM_GROUP_FROM_ITEM_GROUP_SELECTION,
  payload: id,
});

// view item group (tab page related actions)
export const viewItemGroup = (companyId: string, locationId: string, id: string) => async (dispatch: AppDispatch) => {
  dispatch(viewItemGroupBegin());
  try {
    const result = await MenuBuilderService.getItemGroup(companyId, locationId, id);
    dispatch(viewItemGroupSuccess(result));
  } catch (error) {
    dispatch(pageErrorState(error.status, error.message));
    dispatch(viewItemGroupError());
  }
};

export const viewItemGroupBegin = () => ({
  type: ITEM_GROUPS_ACTIONS.VIEW_ITEM_GROUP_BEGIN,
});

export const viewItemGroupSuccess = (payload: IMenuItemGroup) => ({
  type: ITEM_GROUPS_ACTIONS.VIEW_ITEM_GROUP_SUCCESS,
  payload,
});

export const revertItemGroupsDetailPageChanges = () => ({
  type: ITEM_GROUPS_ACTIONS.REVERT_DETAIL_PAGE_CHANGES,
});

export const viewItemGroupError = () => ({
  type: ITEM_GROUPS_ACTIONS.VIEW_ITEM_GROUP_ERROR,
});

// UPDATE_ITEM_GROUP (tab page related actions)

export const updateItemGroupUpdateDisplayName = (value: string) => ({
  type: ITEM_GROUPS_ACTIONS.UPDATE_ITEM_GROUP_UPDATE_DISPLAY_NAME,
  payload: value,
});

export const updateItemGroupUpdateDescription = (value: string) => ({
  type: ITEM_GROUPS_ACTIONS.UPDATE_ITEM_GROUP_UPDATE_DESCRIPTION,
  payload: value,
});

export const updateItemGroupUpdateSchedule = (schedule: IScheduleLink<ILink> | null) => ({
  type: ITEM_GROUPS_ACTIONS.UPDATE_ITEM_GROUP_UPDATE_SCHEDULE,
  payload: schedule,
});

export const updateItemUpdateDisplayNameValidation = (hasError: boolean, errorMessage: string) => ({
  type: ITEM_GROUPS_ACTIONS.UPDATE_ITEM_GROUP_UPDATE_DISPLAY_NAME_VALIDATION,
  payload: { hasError, errorMessage },
});

export const setItemGroupUpdateValidated = (validated: boolean) => ({
  type: ITEM_GROUPS_ACTIONS.SET_ITEM_GROUP_UPDATE_VALIDATED,
  payload: validated,
});

export const validateUpdateItemGroupsDetails = (displayName: string) => async (dispatch: AppDispatch) => {
  if (displayName === '') {
    dispatch(updateItemUpdateDisplayNameValidation(true, 'Display name is required.'));
    dispatch(setItemGroupUpdateValidated(false));
  } else {
    dispatch(updateItemUpdateDisplayNameValidation(false, ''));
    dispatch(setItemGroupUpdateValidated(true));
  }
};

export const updateItemGroup =
  (companyId: string, locationId: string, id: string, itemGroupViewEditPage: IItemGroupViewEditPage) =>
  async (dispatch: AppDispatch) => {
    dispatch(updateDetailsPanelProcessingState(true));
    dispatch(updateItemsPanelProcessingState(true));
    dispatch(updateImageProcessingState(true));
    dispatch(updateThumbnailImageProcessingState(true));
    dispatch(setItemGroupUpdateValidated(false));

    const updateRequest: IMenuItemGroupRequest = {
      _id: id,
      displayName: itemGroupViewEditPage.detailsPanel.itemGroupFields.displayName,
      description: itemGroupViewEditPage.detailsPanel.itemGroupFields.description,
      schedule: itemGroupViewEditPage.detailsPanel.itemGroupFields.schedule,
      imageId: itemGroupViewEditPage.detailsPanel.itemGroupFields.imageId,
      thumbnailImageId: itemGroupViewEditPage.detailsPanel.itemGroupFields.thumbnailImageId,
      items: itemGroupViewEditPage.groupItemsPanel.items,
      thumbnailImageHeight: itemGroupViewEditPage.detailsPanel.itemGroupFields.thumbnailImageHeight,
      thumbnailImageWidth: itemGroupViewEditPage.detailsPanel.itemGroupFields.thumbnailImageWidth,
      imageHeight: itemGroupViewEditPage.detailsPanel.itemGroupFields.imageHeight,
      imageWidth: itemGroupViewEditPage.detailsPanel.itemGroupFields.imageWidth,
      parentTemplateId: itemGroupViewEditPage.detailsPanel.itemGroupFields.parentTemplateId,
    };

    try {
      const result = await MenuBuilderService.updateOneItemGroup(companyId, locationId, updateRequest);
      dispatch(updateItemGroupSuccess(result));
      dispatch(set200Toast());
    } catch (err) {
      dispatch(toastErrorState(err.status, err.message));
    } finally {
      dispatch(setFormIsDirty(false));
      dispatch(updateDetailsPanelProcessingState(false));
      dispatch(updateItemsPanelProcessingState(false));
      dispatch(updateImageProcessingState(false));
      dispatch(updateThumbnailImageProcessingState(false));
    }
  };

export const updateItemGroupSuccess = (result: IMenuItemGroup) => ({
  type: ITEM_GROUPS_ACTIONS.UPDATE_ITEM_GROUP_SUCCESS,
  payload: result,
});

export const updateDetailsPanelProcessingState = (state: boolean) => ({
  type: ITEM_GROUPS_ACTIONS.UPDATE_DETAILS_PANEL_PROCESSING_STATE,
  payload: state,
});

export const updateItemsPanelProcessingState = (state: boolean) => ({
  type: ITEM_GROUPS_ACTIONS.UPDATE_ITEMS_PANEL_PROCESSING_STATE,
  payload: state,
});

export const updateImageProcessingState = (state: boolean) => ({
  type: ITEM_GROUPS_ACTIONS.UPDATE_IMAGE_PROCESSING_STATE,
  payload: state,
});

export const updateThumbnailImageProcessingState = (state: boolean) => ({
  type: ITEM_GROUPS_ACTIONS.UPDATE_THUMBNAIL_IMAGE_PROCESSING_STATE,
  payload: state,
});

export const updateItemGroupImage = (imageKey?: string) => ({
  type: ITEM_GROUPS_ACTIONS.UPDATE_ITEM_GROUP_IMAGE,
  payload: imageKey || null,
});

export const updateItemGroupThumbnailImage = (imageKey?: string) => ({
  type: ITEM_GROUPS_ACTIONS.UPDATE_ITEM_GROUP_THUMBNAIL_IMAGE,
  payload: imageKey || null,
});

export const blankViewEditItemGroupPage = () => ({
  type: ITEM_GROUPS_ACTIONS.BLANK_VIEW_EDIT_ITEM_GROUP_PAGE,
});

export const updateItemGroupItemVisibility = (itemId: string, visibility: number, immediateSync = true) => ({
  type: ITEM_GROUPS_ACTIONS.UPDATE_ITEM_GROUP_ITEM_VISIBILITY,
  payload: { itemId, visibility, immediateSync },
});

export const removeItemGroupItem = (itemId: string) => ({
  type: ITEM_GROUPS_ACTIONS.REMOVE_ITEM_GROUP_ITEM,
  payload: itemId,
});

export const updateItemGroupReorderItems = (sourceIndex: number, destinationIndex: number) => ({
  type: ITEM_GROUPS_ACTIONS.UPDATE_ITEM_GROUP_REORDER_ITEMS,
  payload: {
    sourceIndex,
    destinationIndex,
  },
});

export const updateItemGroupItemsSortOrder = () => ({
  type: ITEM_GROUPS_ACTIONS.UPDATE_ITEM_GROUP_ITEMS_SORT_ORDER,
});

export const updateItemGroupAddItemInPanel = (item: IEmbeddedMenuItem) => ({
  type: ITEM_GROUPS_ACTIONS.UPDATE_ITEM_GROUP_ADD_ITEM,
  payload: item,
});

export const updateItemGroupAddItem = (item: IEmbeddedMenuItem) => async (dispatch: AppDispatch) => {
  dispatch(updateItemGroupAddItemInPanel(item));
  dispatch(updateItemGroupItemsSortOrder());
  dispatch(selectItemInMenuItemsModal(item.itemId));
};

export const deleteItemGroupBegin = () => ({
  type: ITEM_GROUPS_ACTIONS.DELETE_ITEM_GROUP_BEGIN,
});

export const deleteItemGroupSuccess = () => ({
  type: ITEM_GROUPS_ACTIONS.DELETE_ITEM_GROUP_SUCCESS,
});

export const deleteItemGroupError = () => ({
  type: ITEM_GROUPS_ACTIONS.DELETE_ITEM_GROUP_ERROR,
});

export const deleteItemGroup = (companyId: string, locationId: string, id: string) => async (dispatch: AppDispatch) => {
  dispatch(deleteItemGroupBegin());
  try {
    await MenuBuilderService.deleteItemGroup(companyId, locationId, id);
    dispatch(set200Toast('Item Group deleted.'));
    dispatch(deleteItemGroupSuccess());
  } catch (error) {
    dispatch(toastErrorState(error.status, error.message));
    dispatch(deleteItemGroupError());
  }
};
