import React from 'react';
import styles from './AssignTablesDialog.module.scss';
import { ConnectedProps, connect } from 'react-redux';
import { AppState } from '../../redux/initialStates/AppState';
import {
  closeAssignModal,
  loadServerList,
  loadNextServerList,
  initAssignModal,
} from '../redux/ServerAssignmentActions';
import {
  AssignTablesDialogSearch,
  AssignTablesDialogHeader,
  AssignTablesDialogActions,
  AssignTablesDialogServerList,
} from './index';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import ListModal from '../../components/Modal/ListModal';
import { ContextParams } from '../../types/ContextParams.interface';

export type AssignTablesDialogMode = 'select' | 'edit';

const AssignTablesDialog = ({
  mode,
  closeAssignModal,
  loadServerList,
  loadNextServerList,
  initAssignModal,
  page,
  isOpen,
}: ReduxProps): JSX.Element | null => {
  const { contextId, locationId } = useParams<ContextParams>();

  // Re-initializes the list of servers when the modal is closed
  React.useEffect(() => {
    if (!isOpen) {
      initAssignModal();
    }
  }, [isOpen, initAssignModal]);

  const loadServerListDebounced = React.useRef(debounce(loadServerList, 300));

  // Re-initializes the list of servers when the modal is closed
  React.useEffect(() => {
    if (!isOpen) {
      initAssignModal();
    }
  }, [initAssignModal, isOpen]);

  const handleSetShowModal = React.useCallback((isOpen: boolean) => !isOpen && closeAssignModal(), [closeAssignModal]);

  const handleLoadItems = React.useCallback(
    (_query?: string) => {
      loadServerListDebounced.current(contextId, locationId, _query || '');
    },
    [contextId, locationId, loadServerListDebounced]
  );

  const handleLoadMoreItems = React.useCallback(
    (_query?: string) => {
      loadNextServerList(contextId, locationId, _query || '', page);
    },
    [contextId, locationId, loadNextServerList, page]
  );

  return isOpen ? (
    <ListModal
      setShowModal={handleSetShowModal}
      headerControl={<AssignTablesDialogHeader mode={mode} />}
      footerControl={<AssignTablesDialogActions mode={mode} />}
      searchControl={<AssignTablesDialogSearch />}
      additionalBodyStyles={styles.modalBody}
    >
      <AssignTablesDialogServerList onLoadItems={handleLoadItems} onLoadMoreItems={handleLoadMoreItems} />
    </ListModal>
  ) : null;
};

const mapStateToProps = (state: AppState) => {
  return {
    page: state.tableServerAssignment.serverAssignments.servers.page,
    mode: state.tableServerAssignment.serverAssignments.assignModalMode,
    isOpen: state.tableServerAssignment.serverAssignments.isAssignModalOpen,
  };
};

const actionCreators = {
  closeAssignModal,
  loadServerList,
  loadNextServerList,
  initAssignModal,
};

const connector = connect(mapStateToProps, actionCreators);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AssignTablesDialog);
