import React from 'react';
import styles from './Distribution.module.scss';
import classnames from 'classnames';

export interface IDistributionItem {
  percents?: number;
  backgroundColorClass: string;
  value?: number | any;
  description: string | any;
}

interface IDistributionLineProps {
  items: Array<Pick<IDistributionItem, 'percents' | 'backgroundColorClass'>>;
}

interface IDistributionDescriptionProps {
  items: Array<IDistributionItem>;
  disabled?: boolean;
}

export const DistributionLine = ({ items }: IDistributionLineProps) => (
  <div
    className={classnames(styles.DistributionLine, {
      [styles.DistributionLine__empty]:
        items.reduce((accm, { percents }) => (percents ? accm + percents : accm), 0) === 0,
    })}
  >
    {items.map(({ percents, backgroundColorClass }, i) => (
      <div
        key={i}
        style={{ width: `${percents ? `${Math.ceil(percents)}%` : ''}` }}
        className={classnames(styles.DistributionLineItem, backgroundColorClass)}
      />
    ))}
  </div>
);

export const DistributionDescription = ({ items, disabled }: IDistributionDescriptionProps) => (
  <div className={styles.DistributionDescription}>
    {items.map(({ description, backgroundColorClass }, i) => (
      <div className={styles.DistributionDescriptionItem} key={i}>
        {disabled ? null : <span className={classnames(backgroundColorClass, styles.DistributionDescriptionPoint)} />}
        <span>{description}</span>
      </div>
    ))}
  </div>
);
