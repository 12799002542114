import React from 'react';
import EmptyResult from '../../../components/EmptyResult/EmptyResult';
import styles from './Section.module.scss';

export interface SectionProps {
  title: string;
  children?: JSX.Element[] | JSX.Element;
  sectionName?: string;
  sectionHidden?: boolean;
  sectionEmpty?: boolean;
}

const Section = (props: SectionProps) => {
  const { title, children, sectionName, sectionHidden = false, sectionEmpty = false } = props;

  return (
    <div className={`${styles.container} ${sectionName} ${sectionHidden ? styles.hidden : ''}`}>
      <div className={styles.separator}>
        <div className={styles.title}>{title}</div>
        <hr className={styles.line} />
      </div>
      {children && !sectionEmpty ? children : <EmptyResult title='' paragraph='No results found' italicize={true} />}
    </div>
  );
};

export default Section;
