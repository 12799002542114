export const USERS_LIST_ACTIONS = {
  // initialize
  INITIALIZE_USERS_LIST: 'INITIALIZE_USERS_LIST',

  // set
  SET_USERS_LIST: 'SET_USERS_LIST',
  SET_USERS_LIST_LOADING: 'SET_USERS_LIST_LOADING',
};

export const USERS_FORM_ACTIONS = {
  // initialize
  INITIALIZE_USERS_FORM: 'INITIALIZE_USERS_FORM',

  //set
  SET_USERS_FORM: 'SET_USERS_FORM',
  SET_USERS_FORM_PROCESSING: 'SET_USERS_FORM_PROCESSING',
  SET_USERS_FORM_LOADING: 'SET_USERS_FORM_LOADING',
  SET_USERS_FORM_CACHE: 'SET_USERS_FORM_CACHE',

  // updates
  UPDATE_FIRST_NAME: 'UPDATE_FIRST_NAME',
  UPDATE_LAST_NAME: 'UPDATE_LAST_NAME',
  UPDATE_EMAIL_ADDRESS: 'UPDATE_EMAIL_ADDRESS',
  ADD_USER_ROLE: 'ADD_USER_ROLE',
  REMOVE_USER_ROLE: 'REMOVE_USER_ROLE',
  UPDATE_ROLE: 'UPDATE_ROLE',
  ADD_USER_ROLE_LOCATION: 'ADD_USER_ROLE_LOCATION',
  REMOVE_USER_ROLE_LOCATION: 'REMOVE_USER_ROLE_LOCATION',
  UPDATE_ENABLED: 'UPDATE_ENABLED',

  // creates
  CREATE_USER_PERMISSION_FIELD: 'CREATE_USER_PERMISSION_FIELD',
  REMOVE_USER_PERMISSION_FIELD: 'REMOVE_USER_PERMISSION_FIELD',

  LOAD_AVAILABLE_ROLES_BEGIN: 'LOAD_AVAILABLE_ROLES_BEGIN',
  LOAD_AVAILABLE_ROLES_SUCCESS: 'LOAD_AVAILABLE_ROLES_SUCCESS',

  ADD_LOCATION_TO_USER_PERMISSION: 'ADD_LOCATION_TO_USER_PERMISSION',
  REMOVE_LOCATION_FROM_USER_PERMISSION: 'REMOVE_LOCATION_FROM_USER_PERMISSION',

  // RESET
  RESET_USERS_FORM: 'RESET_USERS_FORM',

  VALIDATE_USER_FORM: 'VALIDATE_USER_FORM',
};
