import React from 'react';
import CenteredPanel from '../../components/CenteredPanel/CenteredPanel';
import BackLink from '../../components/BackLink/BackLink';
import RequestEmail from './RequestEmail';
import TransactionResult from './TransactionResult';
import { isValidEmail } from '../../utils/emailValidation';
import { ClientUserService } from '../../services/usersService';

const ResetPassword = () => {
  const [email, setEmail] = React.useState('');
  const [emptyError, setEmptyError] = React.useState(false);
  const [syntaxError, setSyntaxError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [transactionSuccess, setTransactionSuccess] = React.useState<boolean | null>(null);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEmail(value);
    setSyntaxError(false);
    if (value !== '') {
      setEmptyError(false);
    }
  };

  const handleResetPassword = async () => {
    // perform validation
    if (email === '') {
      setEmptyError(true);
      return;
    }
    if (!isValidEmail(email)) {
      setSyntaxError(true);
      return;
    }
    // call resest password service
    setLoading(true);
    await ClientUserService.resetUserPassword(email);
    setLoading(false);
    setTransactionSuccess(true);
  };

  // component to render based on transactionSuccess or transactionError
  let component = (
    <RequestEmail
      email={email}
      handleEmailChange={handleEmailChange}
      handleResetPassword={handleResetPassword}
      emptyError={emptyError}
      syntaxError={syntaxError}
      loading={loading}
    />
  );
  if (transactionSuccess !== null) {
    component = <TransactionResult transactionSuccess={transactionSuccess} email={email} />;
  }

  return (
    <CenteredPanel>
      <BackLink to='/' label='Back to Login' />
      {component}
    </CenteredPanel>
  );
};

export default ResetPassword;
