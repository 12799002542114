import { IResourcePermission, ResourceType } from '@ready/security.core';

// The purpose of this helper is to add or remove permissions from the
// permissionsSet living in the Redux store.
// If the operation is to add a permission, it will look first for the resource type
// and add the intended action. If the operation is to remove a permission, it will
// look for the resource type and remove the action (if no actions left it will
// remove the entire resource type).

const setSecurityRolePermission = (
  resourceType: ResourceType,
  actionName: string,
  operation: 'add' | 'remove',
  permissionsSet?: IResourcePermission[]
): IResourcePermission[] | undefined => {
  if (!!permissionsSet) {
    const permissionsUpdated = permissionsSet;
    const resourceIndex = !!permissionsUpdated
      ? permissionsUpdated?.findIndex((permission) => permission.resourceType === resourceType)
      : -1;
    if (resourceIndex >= 0 && !!permissionsUpdated && !!permissionsUpdated[resourceIndex]) {
      if (operation === 'add') {
        permissionsUpdated[resourceIndex].actions.push(actionName);
      }
      if (operation === 'remove') {
        permissionsUpdated[resourceIndex].actions = permissionsUpdated[resourceIndex].actions.filter(
          (a) => a !== actionName
        );
        // if the resourceType has no action, remove the resource type
        if (!permissionsUpdated[resourceIndex].actions.length) {
          permissionsUpdated.splice(resourceIndex, 1);
        }
      }
    } else {
      if (operation === 'add') {
        permissionsUpdated?.push({
          resourceType,
          actions: [actionName],
        });
      }
    }
    return permissionsUpdated;
  }
};

export default setSecurityRolePermission;
