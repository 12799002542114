import { IEmailMarketingListItem, PaginationResponse } from '@ready/dashboardv2api.contracts';
import config from '../../../config';
import executeApiAsync, { ResponseType } from '../../../services/utils/executeApiAsync';
import { IPagedResponse } from '@ready/table.core';
import { ILocation } from '../../../services/types/ILocation.type';
import { downloadFileFromBlob } from '../../../utils/downloadFileFromBlob';

const BASE_URL = `${config.readyDashboardApiEndpoint}/companies`;

export const getEmailMarketingList = ({
  contextId,
  query = '',
  page = 1,
  sortColumn = 'lastSubTime',
  sortDirection = 'asc',
}: {
  contextId: string;
  query: string;
  page: number;
  sortColumn: string;
  sortDirection: string;
}): Promise<PaginationResponse<IEmailMarketingListItem>> => {
  const url =
    `${BASE_URL}/${contextId}/reports/email-marketing-list/?` +
    decodeURIComponent(query) +
    `&page=${page}&sortColumn=${sortColumn}&sortDirection=${sortDirection}`;

  return executeApiAsync<PaginationResponse<IEmailMarketingListItem>>(url);
};

export const exportEmailMarketingList = async ({
  contextId,
  query = '',
  sortColumn = 'lastSubTime',
  sortDirection = 'asc',
}: {
  contextId: string;
  query: string;
  sortColumn: string;
  sortDirection: string;
}) => {
  const url =
    `${BASE_URL}/${contextId}/reports/email-marketing-list/export?` +
    decodeURIComponent(query) +
    `&sortColumn=${sortColumn}&sortDirection=${sortDirection}`;

  const blob = await executeApiAsync<Blob>(url, { responseType: ResponseType.Blob });
  downloadFileFromBlob(blob, `EmailMarketingList.csv`);
};

export const getPermittedEmailMarketingListLocations = (contextId: string): Promise<IPagedResponse<ILocation>> => {
  // security params
  const resourceType = 'reports';
  const action = 'viewEmailMarketingList';
  const securityParams = `permissions=${resourceType}.${action}`;
  const url = `${BASE_URL}/${contextId}/permittedlocations?${securityParams}`;
  return executeApiAsync<IPagedResponse<ILocation>>(url);
};
