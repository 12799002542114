import { ISidebarOption } from '../redux/initialStates/session/session';

export type contextType = 'company' | 'system';

export const getFirstAvailableSidebarOption = (options: ISidebarOption[], contextType: contextType): ISidebarOption | undefined => {
  for (let i = 0; i < options.length; i ++) {
    const option = options[i];
    if (option.children && option.children.length > 0) {
      return getFirstAvailableSidebarOption(option.children, contextType);
    }
    if (isValidSidebarOption(contextType, option) && option.enabled && option.hasPermission) {
      return option;
    }
  }
};

export const isValidSidebarOption = (contextType: contextType, option: ISidebarOption): boolean => {
  return (contextType === 'system' && option.adminOption) || (contextType === 'company' && !option.adminOption);
};
