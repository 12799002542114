import { ITemplateMenuItemGroup, WithAssignedLocationsCount } from '@ready/menu.core';
import TextIcon, { Icon } from 'components/Icon/TextIcon';
import InfoCard from 'components/InfoCard/InfoCard';
import InfoCardColumn from 'components/InfoCard/InfoCardColumn';
import { useResponsiveBreakpoint } from 'hooks';
import { useAssetUrl } from 'hooks/useAssetUrl';
import MenuItemImage from 'menus/components/ItemsAndMods/MenuItemImage';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { SharedMenuItemsRoutes } from 'sharedMenuItems/Router';
import { ContextParams } from 'types/ContextParams.interface';
import { compositeStyles } from 'utils/cssUtils';
import styles from './ItemGroupInfoCard.module.scss';

interface Props {
  itemGroup: WithAssignedLocationsCount<ITemplateMenuItemGroup>;
}

const ItemGroupInfoCard = ({ itemGroup }: Props) => {
  const { contextId: companyId } = useParams<ContextParams>();
  const { displayName, description, thumbnailImageId, items, assignedLocationsCount } = itemGroup;

  const imageUrl = useAssetUrl(thumbnailImageId);
  const { isMobile } = useResponsiveBreakpoint();

  return (
    <Link to={SharedMenuItemsRoutes.getItemGroupDetailsRoute(companyId, itemGroup._id)}>
      <InfoCard additionalStyles={styles.infoCard}>
        {isMobile && (
          <InfoCardColumn>
            <MenuItemImage type={'item'} name={displayName} url={imageUrl}></MenuItemImage>
          </InfoCardColumn>
        )}
        <InfoCardColumn width={100} columnClassName={styles.container}>
          <div className={styles.left}>
            {!isMobile && <MenuItemImage type={'item'} name={displayName} url={imageUrl}></MenuItemImage>}
            <div className={styles.details}>
              <span className={styles.name}>{displayName}</span>
              <span className={compositeStyles([styles.description, { missing: !description }], styles)}>
                {description ?? 'No description'}
              </span>
            </div>
          </div>
          <div className={styles.attributes}>
            <div className={styles.assignedLocations}>
              <TextIcon icon={Icon.Location} additionalStyles={styles.icon} />
              <span className={styles.amount}>{assignedLocationsCount}</span>
            </div>

            <div className={styles.assignedLocations}>
              <span className={styles.amount}>
                {items.length} item{items.length === 1 ? '' : 's'}
              </span>
            </div>
          </div>
        </InfoCardColumn>
      </InfoCard>
    </Link>
  );
};

export { ItemGroupInfoCard };
