import { createSlice } from '@reduxjs/toolkit';

import { TerminalDto } from '@ready/dashboardv2api.contracts';
import { getTerminalsThunk, createTerminalThunk, deleteTerminalThunk } from './thunks';

interface IPaymentTerminalsPanelState {
  terminals: Array<TerminalDto>;
  loading: boolean;
  saving: boolean;
}

const initialState: IPaymentTerminalsPanelState = {
  terminals: [],
  loading: false,
  saving: false,
};

const slice = createSlice({
  name: 'admin/locations/paymentProcessing/terminals',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTerminalsThunk.pending, getTerminalsReducer.pending);
    builder.addCase(getTerminalsThunk.fulfilled, getTerminalsReducer.fulfilled);
    builder.addCase(getTerminalsThunk.rejected, getTerminalsReducer.rejected);

    builder.addCase(createTerminalThunk.pending, createTerminalReducer.pending);
    builder.addCase(createTerminalThunk.fulfilled, createTerminalReducer.fulfilled);
    builder.addCase(createTerminalThunk.rejected, createTerminalReducer.rejected);

    builder.addCase(deleteTerminalThunk.pending, deleteTerminalReducer.pending);
    builder.addCase(deleteTerminalThunk.fulfilled, deleteTerminalReducer.fulfilled);
    builder.addCase(deleteTerminalThunk.rejected, deleteTerminalReducer.rejected);
  },
});

const getTerminalsReducer = {
  pending: (state: IPaymentTerminalsPanelState) => {
    state.loading = true;
  },
  fulfilled: (state: IPaymentTerminalsPanelState, action: { payload: TerminalDto[] }) => {
    state.terminals = action.payload;
    state.loading = false;
  },
  rejected: (state: IPaymentTerminalsPanelState) => {
    state.loading = false;
  },
};

const createTerminalReducer = {
  pending: (state: IPaymentTerminalsPanelState) => {
    state.saving = true;
  },
  fulfilled: (state: IPaymentTerminalsPanelState, action: { payload: TerminalDto }) => {
    state.saving = false;
  },
  rejected: (state: IPaymentTerminalsPanelState) => {
    state.saving = false;
  },
};

const deleteTerminalReducer = {
  pending: (state: IPaymentTerminalsPanelState) => {
    state.saving = true;
  },
  fulfilled: (state: IPaymentTerminalsPanelState) => {
    state.saving = false;
  },
  rejected: (state: IPaymentTerminalsPanelState) => {
    state.saving = false;
  },
};

export const { actions, reducer } = slice;
