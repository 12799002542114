import React from 'react';
import { ResourceType } from '@ready/security.core';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../../redux/initialStates/AppState';
import {
  initForm,
  setDescription,
  setName,
  validateForm,
  submitUpdateForm,
  setPermission,
  fetchFormData,
} from '../../redux/actions/securityRoleFormActions';
import { loadPermissionsSet } from '../../redux/actions/securityMetadataActions';
import LayoutContent from '../../../components/AppLayout/LayoutContent';
import { Form, FormActionButtons, FormControl } from '../../../components/Form';
import TextInput from '../../../components/TextInput/TextInput';
import TextArea from '../../../components/TextArea/TextArea';
import PermissionSection from '../../components/PermissionSection';
import { useHistory, useParams } from 'react-router-dom';
import { ContextParams } from '../../../types/ContextParams.interface';

interface PageParams extends ContextParams {
  roleId: string;
}

const SecurityRoleEditPage: React.FC<ReduxProps> = ({
  initForm,
  setDescription,
  setName,
  validateForm,
  submitUpdateForm,
  roleForm,
  loadPermissionsSet,
  securityMetadata,
  setPermission,
  fetchFormData,
}: ReduxProps) => {
  const { contextId, roleId } = useParams<PageParams>();
  const { push } = useHistory();

  // Re-initialize form data on unmount
  React.useEffect(
    () => () => {
      initForm();
    },
    [initForm]
  );

  // Load security metadata (permissions set)
  React.useEffect(() => {
    if (!!contextId) {
      loadPermissionsSet(contextId);
    }
  }, [loadPermissionsSet, contextId]);

  // Load the Role data
  React.useEffect(() => {
    fetchFormData(contextId, roleId);
  }, [contextId, roleId, fetchFormData]);

  const listPageUrl = `/companies/${contextId}/securityRoles`;
  const roleEditedUrl = `/companies/${contextId}/securityRoles/${roleId}`;

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
  };

  const handlePermissionChange = (
    permissionType: 'company' | 'location',
    resourceType: ResourceType,
    actionName: string,
    operation: 'add' | 'remove'
  ) => {
    setPermission(permissionType, resourceType, actionName, operation);
  };

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    // Validate the user input
    const isValid = validateForm(roleForm.name);

    // Submit the form if it's valid
    if (isValid) {
      const responseRoleId = await submitUpdateForm(roleId, contextId, {
        companyId: contextId,
        name: roleForm.name,
        description: roleForm.description,
        companyPermissions: roleForm.companyPermissions,
        locationPermissions: roleForm.locationPermissions,
      });

      // Return to the security role view page if update was successful
      if (!!responseRoleId) {
        push(`${listPageUrl}/${responseRoleId}`);
      }
    }
  };

  const handleFormCancel = () => {
    push(roleEditedUrl);
  };

  return (
    <LayoutContent
      title='Edit Security Role'
      loadingContent={securityMetadata.permissions.loading}
      containerType='within'
    >
      <Form onSubmit={handleFormSubmit}>
        <FormControl
          label='Security Role Name *'
          withError={roleForm.validation.name.hasError}
          errorMessage={roleForm.validation.name.error}
        >
          <TextInput
            placeholder='e.g. Floor Manager'
            name='securityRoleName'
            value={roleForm.name}
            onChange={handleNameChange}
            loading={roleForm.processing}
          />
        </FormControl>

        <FormControl label='Description'>
          <TextArea
            placeholder='e.g. Use for full time floor managers at all locations.'
            name='securityRoleDescription'
            value={roleForm.description || ''}
            onChange={handleDescriptionChange}
            loading={roleForm.processing}
          />
        </FormControl>

        <PermissionSection
          permissionType='company'
          permissions={securityMetadata.permissions.permissionSet.companyPermissions}
          handlePermissionChange={handlePermissionChange}
          permissionsForm={roleForm.companyPermissions}
        />

        <PermissionSection
          permissionType='location'
          permissions={securityMetadata.permissions.permissionSet.locationPermissions}
          handlePermissionChange={handlePermissionChange}
          permissionsForm={roleForm.locationPermissions}
        />

        <FormActionButtons handleCancel={handleFormCancel} loading={roleForm.processing} />
      </Form>
    </LayoutContent>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    roleForm: state.securityRoles.securityRoleForm,
    securityMetadata: state.securityRoles.securityMetadata,
  };
};

const actionCreators = {
  initForm,
  setDescription,
  setName,
  validateForm,
  submitUpdateForm,
  loadPermissionsSet,
  setPermission,
  fetchFormData,
};

const connector = connect(mapStateToProps, actionCreators);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(SecurityRoleEditPage);
