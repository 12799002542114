import { ILocationAssignments } from '@ready/menu.core';
import React from 'react';

import styles from './QuickAssignControls.module.scss';
import Checkbox from 'components/Checkbox/Checkbox';

interface Props {
  value: ILocationAssignments['allLocations'];
  totalLocations: number;
  onAssignAll: (shouldAssign: boolean) => void;
}

export const QuickAssignControls = ({ value, totalLocations, onAssignAll }: Props): JSX.Element => {
  const [checked, setChecked] = React.useState<boolean>(false);

  return (
    <div className={styles.controls}>
      <b>Bulk Actions: </b>
      <Checkbox
        label={`Assign to all ${totalLocations.toLocaleString()} location${totalLocations > 1 ? 's' : ''}`}
        checked={checked}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setChecked(event.target.checked);
          onAssignAll(event.target.checked);
        }}
      />
    </div>
  );
};
