import React from 'react';
import styles from './DisabledBadge.module.scss';
import classNames from 'classnames';

interface DisabledBadgeProps {
  withMargin?: boolean;
  className?: string;
}

const DisabledBadge = ({ withMargin = false, className }: DisabledBadgeProps): JSX.Element => (
  <div className={classNames(withMargin ? styles.containerWithMargin : styles.container, className)}>DISABLED</div>
);

export default DisabledBadge;
