import produce from 'immer';
import { TRANSACTION_DETAIL_ACTIONS } from '../../actions/transactionActions/transactionDetailActionTypesNew';
import { paymentPOSDetailState, transactionDetailState } from '../../initialStates/transactions/transactionDetail';
import Action from '../../types/Action';

export const transactionDetailReducerNew = (state = transactionDetailState, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case TRANSACTION_DETAIL_ACTIONS.TRANSACTION_DETAIL_BEGIN:
        draft.loading = true;
        draft.error = false;
        return draft;

      case TRANSACTION_DETAIL_ACTIONS.TRANSACTION_DETAIL_SUCCESS:
        draft.transactionDetail = action.payload;
        draft.loading = false;
        return draft;

      case TRANSACTION_DETAIL_ACTIONS.TRANSACTION_DETAIL_ERROR:
        draft.loading = false;
        draft.error = true;
        return draft;

      case TRANSACTION_DETAIL_ACTIONS.SHOW_MANUAL_ENTER_PAYMENT_MODAL:
        draft.manuallyEnterPaymentPOS.showModal = true;
        draft.manuallyEnterPaymentPOS.paymentPOSDetail = action.payload;
        return draft;

      case TRANSACTION_DETAIL_ACTIONS.HIDE_MANUAL_ENTER_PAYMENT_MODAL:
        draft.manuallyEnterPaymentPOS.showModal = false;
        draft.manuallyEnterPaymentPOS.paymentPOSDetail = paymentPOSDetailState;
        return draft;

      case TRANSACTION_DETAIL_ACTIONS.MANUAL_ENTER_PAYMENT_BEGIN:
        draft.manuallyEnterPaymentPOS.loading = true;
        return draft;

      case TRANSACTION_DETAIL_ACTIONS.MANUAL_ENTER_PAYMENT_SUCCESS:
        draft.manuallyEnterPaymentPOS.loading = false;
        draft.manuallyEnterPaymentPOS.error = false;
        draft.manuallyEnterPaymentPOS.showModal = false;
        return draft;

      case TRANSACTION_DETAIL_ACTIONS.MANUAL_ENTER_PAYMENT_ERROR:
        draft.manuallyEnterPaymentPOS.loading = false;
        draft.manuallyEnterPaymentPOS.error = true;
        return draft;

      case TRANSACTION_DETAIL_ACTIONS.MANUAL_RETRY_PAYMENT_BEGIN:
        draft.manuallyRetryingPayment = true;
        return draft;

      case TRANSACTION_DETAIL_ACTIONS.MANUAL_RETRY_PAYMENT_SUCCESS:
        draft.manuallyRetryingPayment = false;
        return draft;

      case TRANSACTION_DETAIL_ACTIONS.MANUAL_RETRY_PAYMENT_ERROR:
        draft.manuallyRetryingPayment = false;
        return draft;

      case TRANSACTION_DETAIL_ACTIONS.REFUND_TRANSACTION_SUCCESS:
        // On refund success, make sure to update full transaction detail object,
        // as we must ensure the numbers are accurate. We receive the full object
        // back from the API, so we should update the entire transaction detail
        // redux state. There are too many complications updating bits of the
        // transaction detail state here, especially with the updated transaction
        // details API.
        draft.transactionDetail = action.payload;
        return draft;

      case TRANSACTION_DETAIL_ACTIONS.REFUND_GIFT_CARD_BEGIN:
        draft.refundingGiftCard = true;
        draft.refundGiftCardCompleted = false;
        return draft;

      case TRANSACTION_DETAIL_ACTIONS.REFUND_GIFT_CARD_SUCCESS:
        draft.refundingGiftCard = false;
        draft.refundGiftCardCompleted = true;
        draft.transactionDetail = action.payload;
        return draft;

      case TRANSACTION_DETAIL_ACTIONS.REFUND_GIFT_CARD_ERROR:
        draft.refundingGiftCard = false;
        draft.refundGiftCardCompleted = false;
        draft.refundGiftCardError = action.payload;
        return draft;

      case TRANSACTION_DETAIL_ACTIONS.RESET_REFUND_GIFT_CARD_STATE:
        draft.refundingGiftCard = false;
        draft.refundGiftCardCompleted = false;
        draft.refundGiftCardError = '';
        return draft;

      default:
        return draft;
    }
  });
};
