import React from 'react';
import { DetailElement, DetailLayout } from 'components/DetailLayout';
import { PunchhSettingsProps } from '../PunchhSettings';
import { BooleanValue } from 'components/Value/BooleanValue';
import { TextValue } from 'components/Value';
import styles from '../PunchhSettings.module.scss';
import Skeleton from 'components/Skeleton/Skeleton';

export const PunchhSettingsViewPanel = (props: PunchhSettingsProps & { loading: boolean }) => {
  return (
    <DetailLayout>
      <DetailElement label='Client ID'>
        {!props.loading ? (
          <TextValue value={props.clientId || ''} mode='none' />
        ) : (
          <Skeleton additionalStyles={styles.skel} />
        )}
      </DetailElement>
      <DetailElement label='Client Secret'>
        {!props.loading ? (
          <TextValue value={props.clientSecret || ''} mode='none' />
        ) : (
          <Skeleton additionalStyles={styles.skel} />
        )}
      </DetailElement>
      <DetailElement label='Server URL'>
        {!props.loading ? (
          <TextValue value={props.serverUrl || ''} mode='none' />
        ) : (
          <Skeleton additionalStyles={styles.skel} />
        )}
      </DetailElement>
      <DetailElement label='Fields Required for Guest Sign Up'>
        {!props.loading ? (
          <>
            <BooleanValue className={styles.booleanValue} label='First Name' booleanValue={!!props.firstNameRequired} />
            <BooleanValue className={styles.booleanValue} label='Last Name' booleanValue={!!props.lastNameRequired} />
            <BooleanValue
              className={styles.booleanValue}
              label='Phone Number'
              booleanValue={!!props.phoneNumberRequired}
            />
            <BooleanValue className={styles.booleanValue} label='Birthday' booleanValue={!!props.birthdayRequired} />
            <BooleanValue
              className={styles.booleanValue}
              label='Zip / Postal Code'
              booleanValue={!!props.postalCodeRequired}
            />
            <BooleanValue label={`Terms & Conditions`} booleanValue={!!props.termsRequired} />
            {props.termsRequired && <span className={styles.termsUrl}>{props.termsUrl}</span>}
          </>
        ) : (
          <Skeleton additionalStyles={styles.skel} />
        )}
      </DetailElement>
    </DetailLayout>
  );
};
