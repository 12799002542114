
import { updateURLSearchParams } from '../updateURLSearchParams';
import { endOfDay, startOfDay, sub, formatISO } from 'date-fns';

const currentDate = new Date();
// this URL doesn't carry any previous query, therefore previousQuery = ''
const previousQuery = '';
// startDateValue: seven days ago at 00:00:00 (or 12:00:00 AM)
const startDateValue = formatISO(sub(startOfDay(currentDate), { weeks: 1 }));
// endDateValue: today at 23:59:59 (or 11:59:59 PM)
const endDateValue = formatISO(endOfDay(currentDate));

const queryParams = updateURLSearchParams(previousQuery, [
  { name: 'startDate', value: startDateValue },
  { name: 'endDate', value: endDateValue },
]);

const sanitizedValue = encodeURIComponent(queryParams.toString());

/** Get initial date query range of a week ago to today's date. */
export const initDateFilterUrlQuery = `query=${sanitizedValue}`;

/** Get initial date query for start of day on startDate and end of day on endDate */
export const getInitialPaymentReportUrlQueries = (startDate: Date, endDate: Date) => {
  const queryParams = updateURLSearchParams('', [
    { name: 'startDate', value: formatISO(startOfDay(startDate)) },
    { name: 'endDate', value: formatISO(endOfDay(endDate)) },
    { name: 'country', value: 'CA' }
  ]);

  const sanitizedValue = encodeURIComponent(queryParams.toString());
  return `${sanitizedValue}`;
};
