import React from 'react';
// components
import { FormControl, FormControlStacked } from '../../../components/Form';
import TextInput from '../../../components/TextInput/TextInput';
import Checkbox from '../../../components/Checkbox/Checkbox';
import FormattedInput from '../../../components/FormattedInput/FormattedInput';
import { IPaymentProcessingPanel } from '../../redux/PanelPaymentProcessingState';
import { LOCATION_SETTINGS_ACTIONS } from '../../redux/LocationSettingsActions';
// utils
import { toNumber } from '../../../utils/numberUtils/toNumber';
import { IUpdateLocationParam } from '../LocationsEditPage';
import Toggle from 'components/Toggle/Toggle';

interface Props {
  isProcessing: boolean;
  updateLocationParam: IUpdateLocationParam;
  paymentProcessingPanel: IPaymentProcessingPanel;
}

const FreedomPayPP = (props: Props) => {
  const { isProcessing, updateLocationParam, paymentProcessingPanel } = props;
  return (
    <>
      <FormControl label='Store ID'>
        <TextInput
          placeholder='Store ID'
          loading={isProcessing}
          value={paymentProcessingPanel.freedompay.storeId}
          onChange={(event) => {
            updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_FREEDOMPAY_STORE_ID);
          }}
        />
      </FormControl>
      <FormControl label='Terminal ID'>
        <TextInput
          placeholder='Terminal ID'
          loading={isProcessing}
          value={paymentProcessingPanel.freedompay.terminalId}
          onChange={(event) => {
            updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_FREEDOMPAY_TERMINAL_ID);
          }}
        />
      </FormControl>
      <FormControl label='ES Key'>
        <TextInput
          placeholder='ES Key'
          loading={isProcessing}
          value={paymentProcessingPanel.freedompay.esKey}
          onChange={(event) => {
            updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_FREEDOMPAY_ESKEY);
          }}
        />
      </FormControl>
      <FormControl label='Payment Methods'>
        <div className='control--stacked'>
          <Checkbox
            label='Credit Card'
            checked={paymentProcessingPanel.freedompay.paymentMethod.creditCard}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_FREEDOMPAY_PAYMENT_METHOD_CREDIT_CARD
              );
            }}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <Checkbox
            label='Apple Pay'
            checked={paymentProcessingPanel.freedompay.paymentMethod.applePay}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_FREEDOMPAY_PAYMENT_METHOD_APPLE_PAY
              );
            }}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <Checkbox
            label='Google Pay'
            checked={paymentProcessingPanel.freedompay.paymentMethod.googlePay}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_FREEDOMPAY_PAYMENT_METHOD_GOOGLE_PAY
              );
            }}
            loading={isProcessing}
          />
        </div>
      </FormControl>
      <FormControl label='Rate Limiting'>
        <FormControlStacked>
          <Toggle
            checked={paymentProcessingPanel.freedompay.rateLimits.enabled}
            onChange={(checked) => {
              updateLocationParam(
                checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_FREEDOMPAY_RATE_LIMITING_TOGGLE
              );
            }}
            loading={isProcessing}
          />
        </FormControlStacked>
        <FormControlStacked>
          <Checkbox
            disabled={true}
            checked={true}
            label='Enforce rate limits on manual card payments'
            loading={isProcessing}
          />
        </FormControlStacked>
        <FormControlStacked>
          <Checkbox
            disabled={!paymentProcessingPanel.freedompay.rateLimits.enabled}
            checked={paymentProcessingPanel.freedompay.rateLimits.enforceRateLimitsForGooglePay}
            label='Enforce rate limits on Google Pay transactions'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_FREEDOMPAY_RATE_LIMITING_FOR_GOOGLE_PAY
              );
            }}
            loading={isProcessing}
          />
        </FormControlStacked>
        <FormControlStacked>
          <FormattedInput
            format='money'
            disabled={!paymentProcessingPanel.freedompay.rateLimits.enabled}
            value={paymentProcessingPanel.freedompay.rateLimits.amount}
            onChange={(event) => {
              updateLocationParam(
                toNumber(event.target.value),
                LOCATION_SETTINGS_ACTIONS.UPDATE_FREEDOMPAY_RATE_LIMITING_AMOUNT
              );
            }}
            loading={isProcessing}
          />
          <span className='form__control__separator'>Per</span>
          <FormattedInput
            format='min'
            disabled={!paymentProcessingPanel.freedompay.rateLimits.enabled}
            value={paymentProcessingPanel.freedompay.rateLimits.duration / 60}
            onChange={(event) => {
              updateLocationParam(
                toNumber(event.target.value) * 60,
                LOCATION_SETTINGS_ACTIONS.UPDATE_FREEDOMPAY_RATE_LIMITING_TIME_INTERVAL
              );
            }}
            loading={isProcessing}
          />
        </FormControlStacked>
      </FormControl>
      <FormControl label='Additional Settings'>
        <div className='control--stacked'>
          <Checkbox
            label='Allow small transactions'
            checked={paymentProcessingPanel.freedompay.allowSmallTransactions}
            onChange={(event) => {
              updateLocationParam(event.target.checked, LOCATION_SETTINGS_ACTIONS.UPDATE_FREEDOMPAY_SMALL_TRANSACTIONS);
            }}
            loading={isProcessing}
          />
        </div>
      </FormControl>
    </>
  );
};

export default FreedomPayPP;
