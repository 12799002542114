import { combineReducers } from 'redux';
import { dropDownReducers } from './dropDownReducers';
import { sidebarStateReducer } from './sidebarStateReducer';
import { responseStateReducer } from './responseStateReducer';
import { formStateReducer } from './formStateReducer';
import { overflowButtonReducer } from './overflowButtonReducer';
import { searchParamsSlice } from 'redux/slices/searchParamsSlice';
import { infiniteScrollingListModalSlice } from 'redux/slices/infiniteScrollingListModalSlice';
import { checkboxStatesSlice } from 'redux/slices/checkboxeStatesSlice';



export const uiReducer = combineReducers({
  dropDown: dropDownReducers,
  sidebarState: sidebarStateReducer,
  responseState: responseStateReducer,
  formState: formStateReducer,
  overflowButtonState: overflowButtonReducer,
  searchParamsState: searchParamsSlice.reducer,
  infiniteScrollingListModalState: infiniteScrollingListModalSlice.reducer,
  checkboxStates: checkboxStatesSlice.reducer,
});
