import styles from './ManageAssignedLocationsModal.module.scss';
import SearchInput from 'components/SearchInput/SearchInput';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import ListModal from 'components/Modal/ListModal';
import InfiniteScrollingList from 'components/InfiniteScrollingList/InfiniteScrollingList';
import { RootState, useAppDispatch, useAppSelector } from 'redux/store';
import { QuickAssignControls } from './QuickAssignControls';
import { prepare, selectItem, unselectItem } from 'redux/slices/infiniteScrollingListModalSlice';
import { ModalFooter } from 'components/Modal';
import { ILocationAssignments, ITemplateLocation, ITemplateModifierGroup } from '@ready/menu.core';
import { debounce } from 'lodash';
import { AssignedLocationsListItem } from 'sharedMenuItems/pages/itemAssignedLocations/components/AssignedLocationsListItem';

export interface LoadAssignedLocations {
  page?: number;
  isLoadMore?: boolean;
  searchTerm?: string;
}

interface Props {
  selectedIds?: string[];
  unavailableItemKeys?: string[];
  headerLabel?: string;
  headerMessage?: ReactNode;
  setShowModal: (isVisible: boolean) => void;
  showQuickAssignControls?: boolean;

  /** Header label for Assigned to count.".
   * @default "Assigned to"
   */
  assignedToLabel?: string;
  currentAssignedCount: number;
  totalLocationsCount: number;

  loadLocationAssignments: (loadAssignedLocationsParams: LoadAssignedLocations) => void;
  saveAssignedLocations: (assignments: ILocationAssignments) => Promise<void>;
}

export const ManageAssignedLocationsModal = ({
  headerLabel,
  headerMessage,
  assignedToLabel = 'Assigned to',
  showQuickAssignControls = true,
  setShowModal,
  selectedIds,
  unavailableItemKeys,
  loadLocationAssignments,
  saveAssignedLocations,
  currentAssignedCount: currentAssignedCountProp,
  totalLocationsCount,
}: Props): JSX.Element => {
  const [query, setQuery] = useState<string>('');

  const dispatch = useAppDispatch();

  const {
    page,
    loading,
    paginationAvailable,
    paginationLoading,
    filtered,
    error,
    items,
    selectedIds: stateSelectedIds,
  } = useAppSelector((state: RootState) => state.ui.infiniteScrollingListModalState);

  const getItemsDebounced = useMemo(
    () =>
      debounce((searchTerm) => {
        loadLocationAssignments({ searchTerm: searchTerm });
      }, 500),
    [loadLocationAssignments]
  );

  const loadItems = useCallback(() => {
    loadLocationAssignments({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMore = useCallback(() => {
    loadLocationAssignments({ page: page + 1, isLoadMore: true, searchTerm: query });
  }, [loadLocationAssignments, page, query]);

  useEffect(() => {
    dispatch(prepare({ isVisible: true, selectedIds: selectedIds }));
    // only load up the the selectedIds when rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [newAssign, setNewAssign] = useState<string[]>([]);
  const [newUnassign, setNewUnassign] = useState<string[]>([]);

  const [saving, setSaving] = useState<boolean>(false);

  const [allLocationAssignment, setAllLocationAssignment] = useState<ILocationAssignments['allLocations']>(undefined);
  const [currentAssignedCount, setCurrentAssignedCount] = useState<number>(currentAssignedCountProp ?? 0);
  const assignAllLocations = allLocationAssignment === 'assign';

  return (
    <ListModal
      additionalBodyStyles={items.length > 0 ? styles.modalBody : undefined}
      setShowModal={setShowModal}
      headerLabel={headerLabel}
      subHeader={
        <>
          {!loading && (
            <div className={styles.subHeader}>{`${assignedToLabel} ${getCurrentDisplayedCount(
              allLocationAssignment,
              currentAssignedCount,
              items.length
            )} / ${totalLocationsCount} locations`}</div>
          )}
        </>
      }
      searchControl={
        <div className={styles.searchControl}>
          <SearchInput
            value={query}
            placeholder={`Search locations`}
            fullWidth
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setQuery(e.target.value);
              getItemsDebounced(e.target.value);
            }}
            autoFocus={true}
          />
          {headerMessage}
          {showQuickAssignControls && !loading && (
            <QuickAssignControls
              value={allLocationAssignment}
              totalLocations={totalLocationsCount}
              onAssignAll={(shouldAssign) => setAllLocationAssignment(shouldAssign ? 'assign' : undefined)}
            />
          )}
        </div>
      }
      children={
        <InfiniteScrollingList
          loading={loading}
          error={error}
          paginationLoading={paginationLoading}
          paginationAvailable={paginationAvailable}
          items={items}
          filtered={filtered}
          noItemsTitle='No Results Found'
          noItemsMessage='No assignable locations.'
          addButtonLabel='Assign'
          removeButtonLabel='Remove'
          selectedItemKeys={stateSelectedIds}
          unavailableItemKeys={unavailableItemKeys ? unavailableItemKeys : undefined}
          addItem={(item) => {
            dispatch(selectItem(item._id));
            setCurrentAssignedCount((prev) => prev + 1);

            const newUnassignIndex = newUnassign.findIndex((x) => x === item._id);

            if (newUnassignIndex !== -1) {
              setNewUnassign(newUnassign.filter((_, i) => i !== newUnassignIndex));
            } else {
              setNewAssign([...newAssign, item._id]);
            }
          }}
          removeItem={(id) => {
            dispatch(unselectItem(id));
            setCurrentAssignedCount((prev) => prev - 1);

            const newAssignIndex = newAssign.findIndex((x) => x === id);
            if (newAssignIndex !== -1) {
              setNewAssign(newAssign.filter((_, i) => i !== newAssignIndex));
            } else {
              setNewUnassign([...newUnassign, id]);
            }
          }}
          loadItems={loadItems}
          loadMoreItems={loadMore}
          getItemKey={(item: ITemplateModifierGroup) => item._id}
          buildItemComponent={(item: ITemplateLocation, buttons) => (
            <AssignedLocationsListItem location={item} buttons={buttons} allLocations={allLocationAssignment} />
          )}
          disabledButtons={assignAllLocations}
          isInModal
        />
      }
      footerControl={
        <ModalFooter
          primaryLabel={'Save Changes'}
          loading={saving}
          primaryActionHandler={async () => {
            if (newAssign.length > 0 || newUnassign.length > 0 || assignAllLocations) {
              setSaving(true);
              await saveAssignedLocations(
                assignAllLocations
                  ? { allLocations: 'assign' }
                  : { assignToLocations: newAssign, unassignFromLocations: newUnassign }
              );
            }
            setShowModal(false);
          }}
          secondaryActionHandler={() => setShowModal(false)}
          secondaryLabel='Cancel'
        />
      }
    />
  );
};

const getCurrentDisplayedCount = (
  allLocationAssignment: ILocationAssignments['allLocations'],
  current: number,
  allLocationsCount: number
) => {
  switch (allLocationAssignment) {
    case 'assign':
      return allLocationsCount;
    case 'unassign':
      return 0;
    case undefined:
      return current;
  }
};
