import produce from 'immer';
import { LOCATION_SETTINGS_ACTIONS } from './LocationSettingsActions';
import { companyIntegrationsState } from './CompanyIntegrationsState';

export interface IAction {
  type: string;
  payload: any;
}

export const companyIntegrationsReducer = (state = companyIntegrationsState, action: IAction) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case LOCATION_SETTINGS_ACTIONS.SET_COMPANY_INTEGRATIONS_PANEL:
        draft = action.payload;
        return draft;

      default:
        return draft;
    }
  });
};
