import {
  IBaseItemInModifierGroup,
  IBaseNestedModifierGroup,
  ITemplateEmbeddedModifierItem,
  ITemplateMenuItem,
  ITemplateModifierGroup,
  ITemplateModifierGroupRequest,
  TemplateNestedModifierInEmbeddedItem,
  Visibility,
} from '@ready/menu.core';

export const mapModifierGroupToRequest = (modifierGroup: ITemplateModifierGroup): ITemplateModifierGroupRequest => {
  return {
    ...modifierGroup,
    constraints: {
      min: modifierGroup.constraints.min ? Number(modifierGroup.constraints.min) : undefined,
      max: modifierGroup.constraints.max ? Number(modifierGroup.constraints.max) : undefined,
    },
    options: modifierGroup.options.map(mapOptionToRequest),
  };
};

export const mapOptionToRequest = (option: ITemplateEmbeddedModifierItem): IBaseItemInModifierGroup<string> => {
  return {
    itemId: option.itemId,
    sortOrder: option.sortOrder,
    visibility: option.visibility,
    constraints: option.constraints,
    isDefault: option.isDefault,
    nestedGroups: option.nestedGroups?.map(mapNestedGroupToRequest),
  };
};

export const mapNestedGroupToRequest = (
  nestedGroups: TemplateNestedModifierInEmbeddedItem
): IBaseNestedModifierGroup<string> => {
  return {
    _id: nestedGroups._id,
    sortOrder: nestedGroups.sortOrder,
  };
};

export const mapTemplateItemToEmbeddedModifierItem = (item: ITemplateMenuItem): ITemplateEmbeddedModifierItem => {
  return {
    ...item,
    itemId: item._id,
    displayName: item.displayName,
    itemType: item.itemType,
    modifierGroups: item.modifierGroups,
    sortOrder: 0,
    visibility: Visibility.visible,
  };
};
