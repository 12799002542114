import React from 'react';
import { PanelCollapsible } from 'components/PanelLayout';
import TextIcon, { Icon } from 'components/Icon/TextIcon';
import { useAppDispatch, useAppSelector } from 'redux/store';
import styles from './ItemGalleryCollapsiblePanel.module.scss';
import Button from 'components/Button/Button';
import { useResponsiveBreakpoint } from 'hooks';
import {
  changeMenuItemGalleryStatus,
  startEditingInnerItemGallery,
  updateMenuSectionViewState,
} from 'menus/redux/MenusActions';
import DisabledBadge from '../DisabledBadge';
import OverflowButton from 'components/OverflowButton/OverflowButton';
import { Status } from '@ready/menu.core';
import { useParams } from 'react-router-dom';
import { ContextParams } from 'types/ContextParams.interface';
import { LocationSettingsButton } from 'sharedMenuItems/pages/menu/components/LocationSettingsButton';
import { isSharedMenu } from 'sharedMenuItems/sharedMenuItems.utils';

interface Props {
  menuItemsList?: React.ReactNode;
  onLocationSettingsClick?: () => void;
}
export const ItemGalleryCollapsiblePanel = ({ menuItemsList, onLocationSettingsClick }: Props) => {
  const {
    menuForm: { sectionForm, menu },
    menuForm,
    previousMenuState: { expandedSections },
  } = useAppSelector((state) => state.menuBuilder.menus);
  const { isMobile } = useResponsiveBreakpoint();
  const dispatch = useAppDispatch();
  const { contextId: companyId, locationId } = useParams<ContextParams>();

  const menuItemGalleryDisabled = !menu.itemGallery || menu.itemGallery.status === Status.disabled;

  const overflowOptions = [
    {
      label: 'Edit Gallery',
      primary: false,
      disabled: menuForm.editing || menuForm.processing || sectionForm.editing,
      hidden: !isMobile,
      onClick: () => dispatch(startEditingInnerItemGallery(menu.itemGallery!)),
    },
    menuItemGalleryDisabled
      ? {
          label: 'Enable Gallery',
          primary: false,
          disabled: menuForm.editing || sectionForm.editing || menuForm.processing,
          onClick: () => dispatch(changeMenuItemGalleryStatus(companyId, locationId, menu, true)),
        }
      : {
          label: 'Disable Gallery',
          primary: false,
          disabled: menuForm.editing || sectionForm.editing || menuForm.processing,
          onClick: () => dispatch(changeMenuItemGalleryStatus(companyId, locationId, menu, false)),
        },
  ];

  return (
    <PanelCollapsible
      fullContentArea
      titleComponent={
        <>
          <div className={styles.itemGalleryDescription}>
            <TextIcon icon={Icon.Star} additionalStyles={styles.itemGalleryStarIcon} />
            <span
              className={
                !isSharedMenu(companyId, locationId) && menuItemGalleryDisabled
                  ? styles.itemGalleryDescriptionNameDisabled
                  : styles.itemGalleryDescriptionName
              }
            >
              Item Gallery
            </span>
            {!isSharedMenu(companyId, locationId) && menuItemGalleryDisabled && <DisabledBadge />}
          </div>

          {isSharedMenu(companyId, locationId) && onLocationSettingsClick && (
            <div>
              <LocationSettingsButton onClick={onLocationSettingsClick} />
            </div>
          )}
        </>
      }
      subTitleComponent={
        <>
          {menu.itemGallery?.displayName && (
            <div className={styles.itemGalleryNameText}>{menu.itemGallery.displayName}</div>
          )}
          {menu.itemGallery?.description && (
            <div className={styles.itemGalleryDescriptionText}>{menu.itemGallery.description}</div>
          )}
        </>
      }
      headerControls={
        <div className={styles.itemGalleryControls}>
          {!isMobile && (
            <Button
              label='Edit'
              variant='secondary'
              unavailable={menuForm.editing || sectionForm.editing || menuForm.processing}
              additionalStyles={styles.itemGalleryEditButton}
              onClick={() => dispatch(startEditingInnerItemGallery(menu.itemGallery!))}
            />
          )}

          {!isSharedMenu(companyId, locationId) && <OverflowButton options={overflowOptions} />}
        </div>
      }
      initiallyExpanded={menu.itemGallery ? expandedSections.includes(menu.itemGallery._id) : undefined}
      onPanelStateChanged={(isExpanded: boolean) => {
        if (menu.itemGallery) {
          dispatch(updateMenuSectionViewState(menu.itemGallery._id, isExpanded));
        }
      }}
    >
      {menuItemsList}

      {menu.itemGallery && !menu.itemGallery.items.length && (
        <p className={styles.itemGalleryNoItemsMessage}>
          Add featured items to this item gallery to promote them to your guests.
        </p>
      )}
    </PanelCollapsible>
  );
};
