import React from 'react';
import { Location, Action } from 'history';
import { useHistory } from 'react-router-dom';
import { UnregisterCallback } from 'history';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '../Modal';
import styles from './NavigationConfirmationDialog.module.scss';
import { setFormIsDirty } from 'redux/actions/uiActions/formStateActions';
import { useAppDispatch } from 'redux/store';

interface NavigationConfirmationDialogProps {
  shouldBlockNav: boolean;
  title?: string;
  message?: string;
  continueNavigationButtonText?: string;
  cancelNavigationButtonText?: string;
  onConfirmNav?: () => void;
  onCancelNav?: () => void;
  isModalForm?: boolean;
}

interface NavData {
  location: Location;
  action: Action;
}

const noOpUnregisterCallback: UnregisterCallback = () => {};

const NavigationConfirmationDialog = ({
  shouldBlockNav,
  title,
  message,
  continueNavigationButtonText,
  cancelNavigationButtonText,
  onConfirmNav,
  onCancelNav,
  isModalForm = false,
}: NavigationConfirmationDialogProps): JSX.Element | null => {
  const history = useHistory();
  const dispatch = useAppDispatch()

  const [dialogVisible, setDialogVisible] = React.useState<boolean>(false);
  const [navData, setNavData] = React.useState<NavData | null>(null);
  const [unBlock, setUnBlock] = React.useState<UnregisterCallback>(() => noOpUnregisterCallback);
  const [shouldRedoNav, setShouldRedoNav] = React.useState<boolean>(false);

  // When the 'shouldBlockNav' prop is changed to true, block attempted
  // navigation transitions, and save the un-block callback to state
  // Do not block navigation if this is a modal window, since the user
  // has no other way of exiting the modal other than cancelling or saving.
  React.useEffect(() => {
    if (shouldBlockNav && !isModalForm) {
      setUnBlock(() =>
        history.block((location: any, action: any) => {
          setDialogVisible(true);
          setNavData({ location, action });
          return false;
        })
      );
    } else {
      setUnBlock(() => noOpUnregisterCallback);
    }
  }, [shouldBlockNav, isModalForm, history]);

  // The shouldBlockNav can change from true to false, so make sure navigation
  // is unblocked
  React.useEffect(() => {
    if (!shouldBlockNav) {
      unBlock();
    }
  }, [shouldBlockNav, unBlock]);

  const redoNav = React.useCallback(() => {
    if (navData) {
      switch (navData.action) {
        case 'PUSH':
          history.push(navData.location.pathname);
          break;

        case 'POP':
          history.goBack();
          break;

        case 'REPLACE':
          history.replace(navData.location.pathname);
      }
    }
  }, [history, navData]);

  // Redo the navigation transition when the flag is set to true
  React.useEffect(() => {
    if (shouldRedoNav) {
      setShouldRedoNav(false);
      redoNav();
    }
  }, [shouldRedoNav, redoNav]);

  const handleSetShowModal = (showModal: boolean) => {
    setDialogVisible(showModal);
  };

  const handleStayClick = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onCancelNav && onCancelNav();
    setDialogVisible(false);
  };

  const handleLeaveClick = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    dispatch(setFormIsDirty(false))
    setDialogVisible(false);
    unBlock();
    onConfirmNav && onConfirmNav();
    setShouldRedoNav(true);
  };

  return dialogVisible ? (
    <Modal setShowModal={handleSetShowModal}>
      <ModalHeader headerLabel={title ?? 'Are you sure you want to leave?'} setShowModal={handleSetShowModal} />
      <ModalBody>
        <p className={styles.modalBody}>
          {message ?? 'You have unsaved changes. If you leave now, your changes will not be saved.'}
        </p>
      </ModalBody>
      <ModalFooter
        primaryLabel={cancelNavigationButtonText ?? 'Stay on Page'}
        primaryActionHandler={handleStayClick}
        secondaryLabel={continueNavigationButtonText ?? "Leave, Don't Save"}
        secondaryActionHandler={handleLeaveClick}
      />
    </Modal>
  ) : null;
};

export default NavigationConfirmationDialog;
