import React from 'react';
import styles from './NestedModifierGroup.module.scss';
import { compositeStyles } from '../../../utils/cssUtils';
import {
  IEmbeddedModifierItem,
  IModifierGroup,
  ITemplateEmbeddedModifierItem,
  ITemplateModifierGroup,
} from '@ready/menu.core';
import ModifierGroupConstraints from './ModifierGroupConstraints';
import ModifierGroupOption from './ModifierGroupOption';
import { Routes } from '../../MenuBuilderRouter';
import OptionalLink from '../../../components/OptionalLink/OptionalLink';
import { SharedMenuItemsRoutes } from 'sharedMenuItems/Router';

interface NestedModifierGroupProps {
  companyId: string;
  locationId?: string;
  rootModifierGroupId: string;
  nestedGroup: IModifierGroup | ITemplateModifierGroup;
  nestingLevel: number;
  processing: boolean;
  editing: boolean;
  suppressLinking: boolean;
  compactView: boolean;
}

const NestedModifierGroup = ({
  companyId,
  locationId,
  rootModifierGroupId,
  nestedGroup,
  nestingLevel,
  processing,
  editing,
  suppressLinking,
  compactView,
}: NestedModifierGroupProps): JSX.Element => {
  const options: (ITemplateEmbeddedModifierItem | IEmbeddedModifierItem)[] = nestedGroup.options;

  return (
    <div className={styles.container}>
      <div
        className={compositeStyles(
          [
            styles.modGroup,
            { nestingLevel1: nestingLevel === 1 },
            { nestingLevel2: nestingLevel === 2 },
            { nestingLevel3: nestingLevel === 3 },
            { nestingLevel4: nestingLevel === 4 },
            { compactView },
          ],
          styles
        )}
      >
        <OptionalLink
          to={
            !editing && !suppressLinking
              ? locationId
                ? Routes.getModifierGroupPage(companyId, locationId, nestedGroup._id)
                : SharedMenuItemsRoutes.getModifierGroupDetailsRoute(companyId, nestedGroup._id)
              : undefined
          }
          className={styles.modGroupNameLink}
          alternateClassName={styles.modGroupName}
        >
          {nestedGroup.name}
        </OptionalLink>
        <ModifierGroupConstraints
          min={nestedGroup.constraints.min}
          max={nestedGroup.constraints.max}
          small={compactView}
        />
      </div>

      <div className={styles.options}>
        {options.map((option: IEmbeddedModifierItem | ITemplateEmbeddedModifierItem) => (
          <ModifierGroupOption
            key={`${nestedGroup._id}-${option.itemId}`}
          locationId={locationId}
            companyId={companyId}
            rootModifierGroupId={rootModifierGroupId}
            option={option}
            editing={editing}
            suppressLinking={suppressLinking}
            compactView={compactView}
            nestingLevel={nestingLevel}
            allowExpansion={
              !!nestedGroup.options.find((option) => !!option.nestedGroups && !!option.nestedGroups.length)
            }
            allowMultiplesOfEachOption={!!nestedGroup.allowMultiplesOfEachOption}
            defaultMaxValue={nestedGroup.constraints.max || null}
            processing={processing}
          />
        ))}
      </div>
    </div>
  );
};

export default NestedModifierGroup;
