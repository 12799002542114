import { Tabs, Tab } from 'components/Tabs';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ContextParams } from 'types/ContextParams.interface';
import { SharedMenuItemsRoutes } from './Router';

export enum SharedMenuItemsTabId {
  Menus = 'menus',
  ItemAndMods = 'items-and-mods',
  ItemGroups = 'item-groups',
  ModifierGroups = 'modifier-groups',
  Locations = 'locations',
}

interface Props {
  tab?: SharedMenuItemsTabId;
}

export const SharedMenuItemsTabs = ({ tab }: Props): JSX.Element => {
  const { contextId: companyId } = useParams<ContextParams>();

  const menusTab = {
    id: SharedMenuItemsTabId.Menus,
    route: SharedMenuItemsRoutes.getMenuConfigsRoute(companyId),
    label: 'Menus',
  };

  const itemAndModsTab = {
    id: SharedMenuItemsTabId.ItemAndMods,
    route: SharedMenuItemsRoutes.getItemAndModsRoute(companyId),
    label: 'Items & Mods',
  };

  const itemGroupsTab = {
    id: SharedMenuItemsTabId.ItemGroups,
    route: SharedMenuItemsRoutes.getItemGroupListRoute(companyId),
    label: 'Item Groups',
  };

  const modifierGroupsTab = {
    id: SharedMenuItemsTabId.ModifierGroups,
    route: SharedMenuItemsRoutes.getModifierGroupListRoute(companyId),
    label: 'Modifier Groups',
  };

  const locationsTab = {
    id: SharedMenuItemsTabId.Locations,
    route: SharedMenuItemsRoutes.getLocationsRoute(companyId),
    label: 'Locations',
  };

  const tabs = [menusTab, itemAndModsTab, itemGroupsTab, modifierGroupsTab, locationsTab];
  const requestedTab = tab ? tabs.find((item) => item.id === tab) : undefined;
  const selectedTab = requestedTab ? requestedTab : itemAndModsTab;

  return (
    <Tabs color='white' selectedTab={selectedTab.id}>
      <Tab {...menusTab} />
      <Tab {...itemAndModsTab} />
      <Tab {...itemGroupsTab} />
      <Tab {...modifierGroupsTab} />
      <Tab {...locationsTab} />
    </Tabs>
  );
};
