import React from 'react';
import { updateCurrentlyOpenDropdownOverflowButtonId } from 'redux/actions/uiActions/overflowButtonStateActions';
import { IOverflowButtonState } from 'redux/initialStates/ui/overflowButtonState';
import { selectOverflowButtonState } from 'redux/selectors/uiSelectors/uiSelectors';
import { useAppDispatch, useAppSelector } from 'redux/store';

const useHideDropdownMenu = () => {
  const dispatch = useAppDispatch();
  const { currentlyOpenOverflowButtonId } = useAppSelector<IOverflowButtonState>(selectOverflowButtonState);

  React.useEffect(() => {
    const exemptElements = [
      'icon-button icon-button-overflow',
      'icon-dashboard-icons_ellipsis',
      'drop-down-menu__option',
      'icon-dashboard-icons_angle-down',
      'drop-down-button',
      'icon-button icon-button-frame icon-button-overflow',
    ];

    const hideDropdown = (event: any) => {
      const { target } = event;
      if (!target) return;

      const isExemptedElement = exemptElements.some((element: string) => {
        if (target.className && typeof target.className === 'string') {
          return target.className.startsWith(element) || target.className.endsWith(element);
        } else {
          return false;
        }
      });
      const shouldHideDropdown = isExemptedElement === false;

      if (shouldHideDropdown && currentlyOpenOverflowButtonId) {
        dispatch(updateCurrentlyOpenDropdownOverflowButtonId(undefined));
      }
    };
    document.addEventListener('click', hideDropdown);
    // cleanup
    return () => {
      document.removeEventListener('click', hideDropdown);
    };
  }, [dispatch, currentlyOpenOverflowButtonId]);
};

export default useHideDropdownMenu;
