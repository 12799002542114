import { IEffectiveDates } from '@ready/menu.core';
import { format } from 'date-fns';
import React from 'react';
import NoneValue from './NoneValue';

const defaultDateFormat = 'MMM d yyyy, h:mm a';

interface Props {
  effectiveDates?: IEffectiveDates;
  dateFormat?: string;
}

export const EffectiveDateValue = ({ effectiveDates, dateFormat = defaultDateFormat }: Props) => {
  if (!effectiveDates) {
    return <NoneValue />;
  }

  const { start, end } = effectiveDates;

  const startDateString = start ? format(new Date(start), dateFormat) : 'Now';
  const endDateString = end ? format(new Date(end), dateFormat) : 'Forever';
  const effectiveDatesString = `${startDateString} — ${endDateString}`;

  return <>{effectiveDatesString}</>;
};
