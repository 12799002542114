import { Feature, features } from './features';

export const checkFeatureToggle = (feature: Feature): boolean => {
  const localStorageFeature = window.localStorage.getItem(feature);
  if (localStorageFeature) {
    return localStorageFeature === 'true';
  }
  return false;
};

interface IFeatureGroup {
  [featureKey: string]: boolean;
}

class FeatureToggles {
  static list() {
    const { localStorage } = window;
    const sidebar: IFeatureGroup = {};
    const app: IFeatureGroup = {};

    for (const [featureKey, status] of Object.entries(localStorage)) {
      if (featureKey.startsWith('sidebar_')) {
        sidebar[featureKey] = JSON.parse(status);
      } else if (featureKey.startsWith('app_')) {
        app[featureKey] = JSON.parse(status);
      }
    }

    // go through all available feature toggles, if they don't exist in localStorage, they're considered false.
    const allFeatureToggles = features.reduce((acc, curr) => ({ ...acc, [curr]: false }), {});

    return { ...allFeatureToggles, ...sidebar, ...app };
  }

  static enable(...featureKeys: Feature[]) {
    this.updateFeatures(featureKeys, true);
  }

  static disable(...featureKeys: Feature[]) {
    this.updateFeatures(featureKeys, false);
  }

  static toggle(...featureKeys: Feature[]) {
    featureKeys.forEach((feature: Feature) => {
      const currentValue = window.localStorage.getItem(feature);

      if (!currentValue) {
        this.toggleFeature(feature, true);
      } else {
        this.toggleFeature(feature, false);
      }
    });
  }

  private static updateFeatures(featureKeys: Feature[], status: boolean) {
    featureKeys.forEach((feature: Feature) => {
      this.toggleFeature(feature, status);
    });
  }

  private static toggleFeature(feature: Feature, targetStatus: boolean) {
    if (!features.includes(feature)) {
      console.error(`Feature toggle '${feature}' doesn't exist `);
      return;
    }

    if (targetStatus) {
      window.localStorage.setItem(feature, 'true');
      console.log(`Enabled feature toggle: "${feature}".`);
    } else {
      window.localStorage.removeItem(feature);
      console.log(`Disabled feature toggle: "${feature}".`);
    }
  }
}

export default FeatureToggles;
