import React from 'react';

import { IconValue } from '../../../components/Value';

const mapPaymentTypeCodeToIconFilename = (type: string): string => {
  switch (type) {
    case 'americanexpress':
    case 'american express':
      return 'americanexpress';
    case 'discover':
    case 'novus':
      return 'discover';
    case 'gc':
      return 'giftcard';
    case 'jcb':
      return 'jcb';
    case 'mastercard':
      return 'mastercard';
    case 'visa':
    case 'vs':
      return 'visa';
    case 'interac':
      return 'interac';
    default:
      return '';
  }
};

interface PaymentTypeIconProps {
  code: string;
}

const PaymentTypeIcon = ({ code }: PaymentTypeIconProps): JSX.Element => (
  <IconValue value={mapPaymentTypeCodeToIconFilename(code)} />
);

export default PaymentTypeIcon;
