import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { ChecksReadyPayment, ICheckDetailsCheckItem, ICheckDetailsTotals } from '@ready/dashboardv2api.contracts';
import { DollarValue } from '../../../../components/Value';
import styles from '../Page.module.scss';
import DateValue from '../../../../components/Value/DateValue';
import { Tooltip } from '../../../../components/Tooltip/Tooltip';
import TextIcon, { Icon } from '../../../../components/Icon/TextIcon';

interface PaymentDescriptionItemProps {
  title: string;
  classes?: string;
}

const PaymentDescriptionItem: React.FC<PaymentDescriptionItemProps> = ({ title, children, classes }) => (
  <div className={classnames(styles.PaymentDescriptionItem, classes)}>
    <span className={styles.TextBold}>{title}</span>
    {children}
  </div>
);

const isNumberGreaterThenZero = (number: any) => _.isNumber(number) && number > 0;

const PaymentDescriptionItemDollarValue: React.FC<PaymentDescriptionItemProps & { value: number }> = ({
  title,
  classes,
  value,
}) => (
  <PaymentDescriptionItem title={title} classes={classes}>
    <DollarValue value={value} />
  </PaymentDescriptionItem>
);

export const PaymentDetails = ({ details }: { details: ICheckDetailsTotals }) => {
  return (
    <div className={styles.PaymentDescription}>
      <PaymentDescriptionItemDollarValue value={details.subtotal} title='Subtotal' />
      <PaymentDescriptionItemDollarValue value={details.tax} title='Tax' />
      <PaymentDescriptionItemDollarValue value={details.serviceCharge} title='Service Charge' />
      <PaymentDescriptionItemDollarValue value={details.otherCharges} title='Other Charges' />
      <PaymentDescriptionItemDollarValue value={details.discounts} title='Discounts' />

      {/* // TODO fill once we have loyaltyDiscounts in the response
       * <PaymentDescriptionItemDollarValue value={details.loyaltyDiscounts} title='Loyalty Discounts' />
       */}

      <PaymentDescriptionItemDollarValue value={details.checkTotal} title='Check Total' />

      {isNumberGreaterThenZero(details.tip) && <PaymentDescriptionItemDollarValue value={details.tip} title='Tip' />}
      {isNumberGreaterThenZero(details.totalPaid) && (
        <PaymentDescriptionItemDollarValue value={details.totalPaid} title='Total Paid' />
      )}

      <PaymentDescriptionItemDollarValue value={details.totalDue} title='Total Due' />

      {isNumberGreaterThenZero(details.totalRefunded) && (
        <PaymentDescriptionItemDollarValue
          value={details.totalRefunded}
          title='Total Refunded'
          classes={styles.PaymentDescriptionItemMT}
        />
      )}
      {isNumberGreaterThenZero(details.tipPreTaxPercentage) && isNumberGreaterThenZero(details.tipPreTaxPercentage) ? (
        <PaymentDescriptionItem title='Tip % (Pre-Tax/Post Tax)'>
          <span>{`${details.tipPreTaxPercentage}% / ${details.tipPreTaxPercentage}%`}</span>
        </PaymentDescriptionItem>
      ) : null}
    </div>
  );
};

export const CheckItems = ({ items }: { items: Array<ICheckDetailsCheckItem> }) => {
  return (
    <div className={styles.CheckItems}>
      {items.map(({ quantity, modifiers, priceBeforeDiscount, priceAfterDiscount, name, comment }, i) => (
        <div className={styles.CheckItemContainer} key={i}>
          <div className={styles.CheckItem}>
            {/* TODO fill once we have paidByReady in the response
              {paidByReady ? (
                // <span className={styles.CheckItemReadyMark}>
                <TextIcon additionalStyles={styles.CheckItemReadyMark} icon={Icon.UserCheckmark} />
              ) : // </span>
              null}
            */}
            <span className={styles.CheckItemCount}>{quantity}</span>
            <div className={styles.CheckItemDescription}>
              <span>{name}</span>
              {comment ? (
                <span className={styles.CheckItemCustomerComment}>
                  <TextIcon additionalStyles={styles.CheckItemCustomerCommentIcon} icon={Icon.SpeechBubble} />{' '}
                  <span>{comment}</span>
                </span>
              ) : null}
            </div>
            <div className={styles.CheckItemCost}>
              {priceAfterDiscount !== priceBeforeDiscount ? <DollarValue value={priceAfterDiscount} /> : null}
              <span className={priceBeforeDiscount !== priceAfterDiscount ? styles.CheckItemDisabledCost : ''}>
                <DollarValue value={priceBeforeDiscount} />
              </span>
            </div>
          </div>
          {modifiers?.map(({ name, quantity }, index) => (
            <div className={styles.CheckItemAdditionalInfo} key={index}>
              {quantity && <span className={styles.CheckItemCount}>{quantity}</span>}
              <div className={styles.CheckItemDescription}>
                <span>{name}</span>
              </div>
            </div>
          )) || null}
        </div>
      ))}
    </div>
  );
};

const tooltipText =
  'This check contains a mix of Ready and POS payments. The' +
  ' guest did not specify which items they were paying for at time of checkout.';

const getTextCheckPaymentDistribution = (readyPayment: ChecksReadyPayment) => {
  switch (readyPayment) {
    case ChecksReadyPayment.Full:
      return 'FULLY PAID BY READY';
    case ChecksReadyPayment.Partial:
      return 'PARTIALLY PAID BY READY';
    case ChecksReadyPayment.Unpaid:
      return 'NO PAYMENTS APPLIED';
    case ChecksReadyPayment.None:
      return 'NO READY PAYMENTS APPLIED';
    default:
      return '';
  }
};

export const CheckPaymentDistribution = ({ readyPayment }: { readyPayment: ChecksReadyPayment }) => (
  <div className={styles.CheckPaymentDistribution}>
    {getTextCheckPaymentDistribution(readyPayment)}{' '}
    {readyPayment === ChecksReadyPayment.Partial ? (
      <Tooltip text={tooltipText}>
        <TextIcon icon={Icon.InfoCircle} />
      </Tooltip>
    ) : null}
  </div>
);

export const GeneralInfo = ({
  locationName,
  serverName,
  lastUpdated,
  checkDuration,
  timezone,
}: {
  locationName: string;
  serverName: string;
  timezone: string;
  lastUpdated: string | null;
  checkDuration: string | null;
}) => {
  return (
    <div className={styles.GeneralInfo}>
      <span>{locationName}</span>
      <span>
        <span className={styles.TextBold}>Server:</span> {serverName}
      </span>
      {lastUpdated && (
        <span>
          <span className={styles.TextBold}>Last Updated:</span> <DateValue value={lastUpdated} timeZone={timezone} />
        </span>
      )}
      {checkDuration && (
        <span>
          <span className={styles.TextBold}>Check Duration:</span> {checkDuration}
        </span>
      )}
    </div>
  );
};
