import { IAsset, IClientLocation } from '@ready/dashboardv2api.contracts';
import {
  IBadge,
  ICachedPosMenuItem,
  ICachedPosModifier,
  IForcedModifier,
  IMenuItem,
  IModifierGroup,
  IScheduleLink,
  IStockUpdateRequest,
  ITag,
  ItemType,
  Status,
} from '@ready/menu.core';
import { setFormIsDirty } from '../../redux/actions/uiActions/formStateActions';
import { pageErrorState, set200Toast, toastErrorState } from '../../redux/actions/uiActions/responseStateActions';
import MenuBuilderService from '../services/MenuBuilderService';
import { LinkPosItemType } from '../types/LinkPosItemType.type';
import MenuItemsView from '../types/MenuItemsView.enum';
import { newMenuItem } from './ItemsAndModsState';
import { ILink } from './MenusState';
import ItemsAndGroupsStockStatus from '../types/ItemsAndGroupsStockStatus.enum';
import { AppDispatch } from '../../redux/store';
import { ISmbTax } from '../../companySettings/types/SmbTaxTypes.type';
import { ISmbMenuItem } from '../types/SmbMenuTypes.type';
import { isSharedMenu } from 'sharedMenuItems/sharedMenuItems.utils';
import { mapITemplateMenuItemToIMenuItem } from 'sharedMenuItems/pages/menu/menu.mapper';
import { getSharedMenuItemList } from 'sharedMenuItems/pages/itemsAndMods/service';

export const ITEMS_AND_MODS_ACTIONS = {
  LOAD_MENU_BUILDER_MENU_ITEMS_BEGIN: 'LOAD_MENU_BUILDER_MENU_ITEMS_BEGIN',
  LOAD_MENU_BUILDER_MENU_ITEMS_SUCCESS: 'LOAD_MENU_BUILDER_MENU_ITEMS_SUCCESS',
  LOAD_MENU_BUILDER_MENU_ITEMS_ERROR: 'LOAD_MENU_BUILDER_MENU_ITEMS_ERROR',

  STORE_MENU_BUILDER_MENU_ITEMS_FILTERS: 'STORE_MENU_BUILDER_MENU_ITEMS_FILTERS',

  CREATE_MENU_BUILDER_NEW_MENU_ITEM: 'CREATE_MENU_BUILDER_NEW_MENU_ITEM',
  CREATE_MENU_BUILDER_NEW_MODIFIER: 'CREATE_MENU_BUILDER_NEW_MODIFIER',
  CREATE_MENU_BUILDER_NEW_SMB_MENU_ITEM: 'CREATE_MENU_BUILDER_NEW_SMB_MENU_ITEM',
  CREATE_MENU_BUILDER_NEW_SMB_MODIFIER: 'CREATE_MENU_BUILDER_NEW_SMB_MODIFIER',
  CANCEL_CREATE_MENU_BUILDER_NEW_ITEM: 'CANCEL_CREATE_MENU_BUILDER_NEW_ITEM',
  RESET_CREATE_MENU_BUILDER_NEW_ITEM: 'RESET_CREATE_MENU_BUILDER_NEW_ITEM',

  LOAD_MENU_BUILDER_MENU_ITEM_BEGIN: 'LOAD_MENU_BUILDER_MENU_ITEM_BEGIN',
  LOAD_MENU_BUILDER_MENU_ITEM_SUCCESS: 'LOAD_MENU_BUILDER_MENU_ITEM_SUCCESS',
  LOAD_MENU_BUILDER_MENU_ITEM_ERROR: 'LOAD_MENU_BUILDER_MENU_ITEM_ERROR',

  SET_MENU_ITEM_CACHE: 'SET_MENU_ITEM_CACHE',

  UPDATE_MENU_ITEM_STATUS: 'UPDATE_MENU_ITEM_STATUS',
  UPDATE_MENU_ITEM_EFFECTIVE_START_DATE: 'UPDATE_MENU_ITEM_EFFECTIVE_START_DATE',
  UPDATE_MENU_ITEM_EFFECTIVE_END_DATE: 'UPDATE_MENU_ITEM_EFFECTIVE_END_DATE',
  UPDATE_MENU_ITEM_IN_STOCK: 'UPDATE_MENU_ITEM_IN_STOCK',
  UPDATE_MENU_ITEM_DISPLAY_NAME: 'UPDATE_MENU_ITEM_DISPLAY_NAME',
  UPDATE_MENU_ITEM_DESCRIPTION: 'UPDATE_MENU_ITEM_DESCRIPTION',
  UPDATE_MENU_ITEM_ADD_TAG: 'UPDATE_MENU_ITEM_ADD_TAG',
  UPDATE_MENU_ITEM_REMOVE_TAG: 'UPDATE_MENU_ITEM_REMOVE_TAG',
  UPDATE_MENU_ITEM_BADGE: 'UPDATE_MENU_ITEM_BADGE',
  UPDATE_MENU_ITEM_ACTIVE_PRICE_LEVEL: 'UPDATE_MENU_ITEM_ACTIVE_PRICE_LEVEL',
  UPDATE_MENU_BUILDER_MENU_SCHEDULER: 'UPDATE_MENU_BUILDER_MENU_SCHEDULER',
  UPDATE_MENU_ITEM_IS_POPULAR: 'UPDATE_MENU_ITEM_IS_POPULAR',
  UPDATE_MENU_ITEM_CONTAINS_ALCOHOL: 'UPDATE_MENU_ITEM_CONTAINS_ALCOHOL',
  UPDATE_MENU_ITEM_ALCOHOL_AMOUNT: 'UPDATE_MENU_ITEM_ALCOHOL_AMOUNT',
  UPDATE_MENU_ITEM_IMAGE: 'UPDATE_MENU_ITEM_IMAGE',
  UPDATE_MENU_ITEM_THUMBNAIL_IMAGE: 'UPDATE_MENU_ITEM_THUMBNAIL_IMAGE',
  UPDATE_MENU_ITEM_PRICE: 'UPDATE_MENU_ITEM_PRICE',
  UPDATE_MENU_ITEM_TAXES: 'UPDATE_MENU_ITEM_TAXES',

  SAVE_MENU_BUILDER_MENU_ITEM_BEGIN: 'SAVE_MENU_BUILDER_MENU_ITEM_BEGIN',
  SAVE_MENU_BUILDER_MENU_ITEM_SUCCESS: 'SAVE_MENU_BUILDER_MENU_ITEM_SUCCESS',
  SAVE_MENU_BUILDER_MENU_ITEM_ERROR: 'SAVE_MENU_BUILDER_MENU_ITEM_ERROR',
  SAVE_MENU_BUILDER_MENU_ITEM_VALIDATION_ERROR: 'SAVE_MENU_BUILDER_MENU_ITEM_VALIDATION_ERROR',

  RESET_MENU_ITEM: 'RESET_MENU_ITEM',

  ADD_MODIFIER_GROUP_TO_MENU_ITEM: 'ADD_MODIFIER_GROUP_TO_MENU_ITEM',
  REMOVE_MODIFIER_GROUP_FROM_MENU_ITEM: 'REMOVE_MODIFIER_GROUP_FROM_MENU_ITEM',
  MOVE_MODIFIER_GROUP_IN_MENU_ITEM: 'MOVE_MODIFIER_GROUP_IN_MENU_ITEM',

  PREPARE_MENU_BUILDER_ITEMS_AND_MODS_MODAL: 'PREPARE_MENU_BUILDER_ITEMS_AND_MODS_MODAL',
  SET_MENU_BUILDER_ITEMS_AND_MODS_MODAL_QUERY: 'SET_MENU_BUILDER_ITEMS_AND_MODS_MODAL_QUERY',
  SET_MENU_BUILDER_ITEMS_AND_MODS_MODAL_VIEW: 'SET_MENU_BUILDER_ITEMS_AND_MODS_MODAL_VIEW',
  ADD_ITEMS_AND_MODS_TO_ITEMS_AND_MODS_SELECTION: 'ADD_ITEMS_AND_MODS_TO_ITEMS_AND_MODS_SELECTION',
  REMOVE_ITEMS_AND_MODS_FROM_ITEMS_AND_MODS_SELECTION: 'REMOVE_ITEMS_AND_MODS_FROM_ITEMS_AND_MODS_SELECTION',

  LOAD_MENU_BUILDER_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_BEGIN:
    'LOAD_MENU_BUILDER_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_BEGIN',
  LOAD_MENU_BUILDER_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_SUCCESS:
    'LOAD_MENU_BUILDER_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_SUCCESS',
  LOAD_MENU_BUILDER_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_ERROR:
    'LOAD_MENU_BUILDER_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_ERROR',
  LOAD_MORE_MENU_BUILDER_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_BEGIN:
    'LOAD_MORE_MENU_BUILDER_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_BEGIN',
  LOAD_MORE_MENU_BUILDER_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_SUCCESS:
    'LOAD_MORE_MENU_BUILDER_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_SUCCESS',
  LOAD_MORE_MENU_BUILDER_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_NO_RESULTS:
    'LOAD_MORE_MENU_BUILDER_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_NO_RESULTS',
  LOAD_MORE_MENU_BUILDER_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_ERROR:
    'LOAD_MORE_MENU_BUILDER_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_ERROR',

  PREPARE_MENU_BUILDER_POS_ITEMS_AND_MODS_MODAL: 'PREPARE_MENU_BUILDER_POS_ITEMS_AND_MODS_MODAL',
  SET_MENU_BUILDER_POS_ITEMS_AND_MODS_MODAL_QUERY: 'SET_MENU_BUILDER_POS_ITEMS_AND_MODS_MODAL_QUERY',
  SELECT_POS_ITEM_IN_POS_ITEMS_AND_MODS_MODAL: 'SELECT_POS_ITEM_IN_POS_ITEMS_AND_MODS_MODAL',

  LOAD_MENU_BUILDER_POS_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_BEGIN:
    'LOAD_MENU_BUILDER_POS_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_BEGIN',
  LOAD_MENU_BUILDER_POS_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_SUCCESS:
    'LOAD_MENU_BUILDER_POS_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_SUCCESS',
  LOAD_MENU_BUILDER_POS_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_ERROR:
    'LOAD_MENU_BUILDER_POS_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_ERROR',
  LOAD_MORE_MENU_BUILDER_POS_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_BEGIN:
    'LOAD_MORE_MENU_BUILDER_POS_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_BEGIN',
  LOAD_MORE_MENU_BUILDER_POS_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_SUCCESS:
    'LOAD_MORE_MENU_BUILDER_POS_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_SUCCESS',
  LOAD_MORE_MENU_BUILDER_POS_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_NO_RESULTS:
    'LOAD_MORE_MENU_BUILDER_POS_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_NO_RESULTS',
  LOAD_MORE_MENU_BUILDER_POS_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_ERROR:
    'LOAD_MORE_MENU_BUILDER_POS_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_ERROR',

  LOAD_MENU_BUILDER_POS_MODIFIERS_BEGIN: 'LOAD_MENU_BUILDER_POS_MODIFIERS_BEGIN',
  LOAD_MENU_BUILDER_POS_MODIFIERS_SUCCESS: 'LOAD_MENU_BUILDER_POS_MODIFIERS_SUCCESS',
  LOAD_MENU_BUILDER_POS_MODIFIERS_ERROR: 'LOAD_MENU_BUILDER_POS_MODIFIERS_ERROR',

  ADD_FORCED_MODIFIER_TO_MENU_ITEM: 'ADD_FORCED_MODIFIER_TO_MENU_ITEM',
  REMOVE_FORCED_MODIFIER_FROM_MENU_ITEM: 'REMOVE_FORCED_MODIFIER_FROM_MENU_ITEM',
  UPDATE_FORCED_MODIFIER_PRICE_LEVEL: 'UPDATE_FORCED_MODIFIER_PRICE_LEVEL',

  ADD_MENU_ITEM_TO_MENU_ITEM_PAIRING: 'ADD_MENU_ITEM_TO_MENU_ITEM_PAIRING',
  REMOVE_MENU_ITEM_FROM_MENU_ITEM_PAIRING: 'REMOVE_MENU_ITEM_FROM_MENU_ITEM_PAIRING',

  QUICK_UPDATE_MENU_ITEM_IN_STOCK_BEGIN: 'QUICK_UPDATE_MENU_ITEM_IN_STOCK_BEGIN',
  QUICK_UPDATE_MENU_ITEM_IN_STOCK_SUCCESS: 'QUICK_UPDATE_MENU_ITEM_IN_STOCK_SUCCESS',
  QUICK_UPDATE_MENU_ITEM_IN_STOCK_ERROR: 'QUICK_UPDATE_MENU_ITEM_IN_STOCK_ERROR',

  SYNC_MENU_BUILDER_MENU_ITEMS_BEGIN: 'SYNC_MENU_BUILDER_MENU_ITEMS_BEGIN',
  SYNC_MENU_BUILDER_MENU_ITEMS_SUCCESS: 'SYNC_MENU_BUILDER_MENU_ITEMS_SUCCESS',
  SYNC_MENU_BUILDER_MENU_ITEMS_ERROR: 'SYNC_MENU_BUILDER_MENU_ITEMS_ERROR',

  SHOW_DUPLICATE_MENU_BUILDER_MENU_ITEM_MODAL: 'SHOW_DUPLICATE_MENU_BUILDER_MENU_ITEM_MODAL',
  SET_DUPLICATE_MENU_BUILDER_MENU_ITEM_DISPLAY_NAME: 'SET_DUPLICATE_MENU_BUILDER_MENU_ITEM_DISPLAY_NAME',
  DUPLICATE_MENU_BUILDER_MENU_ITEM_VALIDATION_ERROR: 'DUPLICATE_MENU_BUILDER_MENU_ITEM_VALIDATION_ERROR',

  DELETE_MENU_BUILDER_MENU_ITEM_BEGIN: 'DELETE_MENU_BUILDER_MENU_ITEM_BEGIN',
  DELETE_MENU_BUILDER_MENU_ITEM_SUCCESS: 'DELETE_MENU_BUILDER_MENU_ITEM_SUCCESS',
  DELETE_MENU_BUILDER_MENU_ITEM_ERROR: 'DELETE_MENU_BUILDER_MENU_ITEM_ERROR',

  // ready forced mod stuff
  PREPARE_MENU_BUILDER_FORCED_MOD_MODAL: 'PREPARE_MENU_BUILDER_FORCED_MOD_MODAL',
  SET_MENU_BUILDER_FORCED_MOD_MODAL_QUERY: 'SET_MENU_BUILDER_FORCED_MOD_MODAL_QUERY',
  SELECT_ITEMS_IN_FORCED_MOD_MODAL: 'SELECT_ITEMS_IN_FORCED_MOD_MODAL',
  ADD_READY_FORCED_MODIFIER_TO_MENU_ITEM: 'ADD_READY_FORCED_MODIFIER_TO_MENU_ITEM',
  REMOVE_READY_FORCED_MODIFIER_TO_MENU_ITEM: 'REMOVE_READY_FORCED_MODIFIER_TO_MENU_ITEM',
};

export const loadMenuItems =
  (
    companyId: string,
    locationId: string,
    view: MenuItemsView,
    inStockFilter: ItemsAndGroupsStockStatus,
    query: string = '',
    page: number = 1
  ) =>
  async (dispatch: any) => {
    dispatch({ type: ITEMS_AND_MODS_ACTIONS.LOAD_MENU_BUILDER_MENU_ITEMS_BEGIN });
    try {
      const results = await MenuBuilderService.getMenuItems(companyId, locationId, view, inStockFilter, query, page);
      dispatch({
        type: ITEMS_AND_MODS_ACTIONS.LOAD_MENU_BUILDER_MENU_ITEMS_SUCCESS,
        payload: results,
      });
    } catch (error) {
      dispatch({
        type: ITEMS_AND_MODS_ACTIONS.LOAD_MENU_BUILDER_MENU_ITEMS_ERROR,
      });
      dispatch(pageErrorState(error.status, error.message));
    }
  };

export const storeMenuItemsFilters = (
  view: MenuItemsView = MenuItemsView.ALL,
  stockStatus: ItemsAndGroupsStockStatus = ItemsAndGroupsStockStatus.All,
  query: string = '',
  page: number = 1
) => ({
  type: ITEMS_AND_MODS_ACTIONS.STORE_MENU_BUILDER_MENU_ITEMS_FILTERS,
  payload: { view, stockStatus, query, page },
});

export const loadNewMenuItem = (isSmbLocation: boolean) => async (dispatch: any) => {
  if (isSmbLocation) {
    dispatch({
      type: ITEMS_AND_MODS_ACTIONS.CREATE_MENU_BUILDER_NEW_SMB_MENU_ITEM,
    });
  } else {
    dispatch({
      type: ITEMS_AND_MODS_ACTIONS.CREATE_MENU_BUILDER_NEW_MENU_ITEM,
    });
  }
  dispatch(setFormIsDirty(true));
};

export const loadNewModifier = (isSmbLocation: boolean) => async (dispatch: any) => {
  if (isSmbLocation) {
    dispatch({
      type: ITEMS_AND_MODS_ACTIONS.CREATE_MENU_BUILDER_NEW_SMB_MODIFIER,
    });
  } else {
    dispatch({
      type: ITEMS_AND_MODS_ACTIONS.CREATE_MENU_BUILDER_NEW_MODIFIER,
    });
  }
  dispatch(setFormIsDirty(true));
};

export const cancelNewItem = () => async (dispatch: any) => {
  dispatch({
    type: ITEMS_AND_MODS_ACTIONS.CANCEL_CREATE_MENU_BUILDER_NEW_ITEM,
  });
  dispatch(setFormIsDirty(false));
};

export const loadMenuItem =
  (companyId: string, locationId: string, menuItemId: string, abortController?: AbortController) =>
  async (dispatch: any) => {
    dispatch({ type: ITEMS_AND_MODS_ACTIONS.LOAD_MENU_BUILDER_MENU_ITEM_BEGIN });
    try {
      const menuItem = await MenuBuilderService.getMenuItem(companyId, locationId, menuItemId, abortController);

      dispatch({
        type: ITEMS_AND_MODS_ACTIONS.LOAD_MENU_BUILDER_MENU_ITEM_SUCCESS,
        payload: menuItem,
      });
      dispatch({
        type: ITEMS_AND_MODS_ACTIONS.SET_MENU_ITEM_CACHE,
        payload: menuItem,
      });
    } catch (error) {
      dispatch({
        type: ITEMS_AND_MODS_ACTIONS.LOAD_MENU_BUILDER_MENU_ITEM_ERROR,
      });
      dispatch(pageErrorState(error.status, error.message));
    }
  };

export const loadSmbMenuItem =
  (companyId: string, locationId: string, menuItemId: string, abortController?: AbortController) =>
  async (dispatch: any) => {
    dispatch({ type: ITEMS_AND_MODS_ACTIONS.LOAD_MENU_BUILDER_MENU_ITEM_BEGIN });
    try {
      const menuItem = await MenuBuilderService.getSmbMenuItem(companyId, locationId, menuItemId, abortController);

      dispatch({
        type: ITEMS_AND_MODS_ACTIONS.LOAD_MENU_BUILDER_MENU_ITEM_SUCCESS,
        payload: menuItem,
      });
      dispatch({
        type: ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_TAXES,
        payload: menuItem.taxes,
      });
      dispatch({
        type: ITEMS_AND_MODS_ACTIONS.SET_MENU_ITEM_CACHE,
        payload: menuItem,
      });
    } catch (error) {
      dispatch({
        type: ITEMS_AND_MODS_ACTIONS.LOAD_MENU_BUILDER_MENU_ITEM_ERROR,
      });
      dispatch(pageErrorState(error.status, error.message));
    }
  };

export const updateMenuItemStatus = (enabled: boolean) => async (dispatch: any) => {
  dispatch({
    type: ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_STATUS,
    payload: enabled ? Status.enabled : Status.disabled,
  });
  dispatch(setFormIsDirty(true));
};

export const updateMenuItemEffectiveStartDate = (effectiveStartDate?: string) => async (dispatch: AppDispatch) => {
  dispatch({
    type: ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_EFFECTIVE_START_DATE,
    payload: effectiveStartDate,
  });
  dispatch(setFormIsDirty(true));
};

export const updateMenuItemEffectiveEndDate = (effectiveEndDate?: string) => async (dispatch: AppDispatch) => {
  dispatch({
    type: ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_EFFECTIVE_END_DATE,
    payload: effectiveEndDate,
  });
  dispatch(setFormIsDirty(true));
};

export const updateMenuItemInStock = (inStock: boolean) => ({
  type: ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_IN_STOCK,
  payload: inStock,
});

export const updateMenuItemDisplayName = (displayName: string) => async (dispatch: any) => {
  dispatch({
    type: ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_DISPLAY_NAME,
    payload: displayName,
  });
  dispatch(setFormIsDirty(true));
};

export const updateMenuItemDescription = (description: string) => ({
  type: ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_DESCRIPTION,
  payload: description,
});

export const updateMenuItemActivePriceLevel = (activePriceLevel: string) => async (dispatch: any) => {
  dispatch({
    type: ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_ACTIVE_PRICE_LEVEL,
    payload: activePriceLevel,
  });
  dispatch(setFormIsDirty(true));
};

export const updateMenuItemScheduler = (schedule: IScheduleLink<ILink> | null) => async (dispatch: any) => {
  dispatch({
    type: ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_BUILDER_MENU_SCHEDULER,
    payload: schedule,
  });
  dispatch(setFormIsDirty(true));
};

export const updateMenuItemIsPopular = (isPopular: boolean) => ({
  type: ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_IS_POPULAR,
  payload: isPopular,
});

export const updateMenuItemContainsAlcohol = (containsAlcohol: boolean) => ({
  type: ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_CONTAINS_ALCOHOL,
  payload: containsAlcohol,
});

export const updateMenuItemAlcoholAmount = (alcoholAmount: string) => ({
  type: ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_ALCOHOL_AMOUNT,
  payload: alcoholAmount,
});

export const updateMenuItemImage = (image: Partial<IAsset>, imageEl?: HTMLImageElement) => async (dispatch: any) => {
  dispatch({
    type: ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_IMAGE,
    payload: {
      fileKey: image.fileKey,
      width: imageEl?.naturalWidth ?? 0,
      height: imageEl?.naturalHeight ?? 0,
    },
  });
  dispatch(setFormIsDirty(true));
};

export const updateMenuItemThumbnailImage =
  (thumbnailImage: Partial<IAsset>, imageEl?: HTMLImageElement) => async (dispatch: any) => {
    dispatch({
      type: ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_THUMBNAIL_IMAGE,
      payload: {
        fileKey: thumbnailImage.fileKey,
        width: imageEl?.naturalWidth ?? 0,
        height: imageEl?.naturalHeight ?? 0,
      },
    });
    dispatch(setFormIsDirty(true));
  };

export const resetMenuItem = (menuItem: IMenuItem) => async (dispatch: any) => {
  dispatch({
    type: ITEMS_AND_MODS_ACTIONS.RESET_MENU_ITEM,
    payload: menuItem,
  });
  dispatch(setFormIsDirty(false));
};

export const clearMenuItem = () => ({
  type: ITEMS_AND_MODS_ACTIONS.RESET_MENU_ITEM,
  payload: newMenuItem(),
});

export const saveMenuItem =
  (companyId: string, location: IClientLocation, menuItem: IMenuItem, readOnlyStock?: boolean, duplicating?: boolean) =>
  async (dispatch: any) => {
    dispatch({ type: ITEMS_AND_MODS_ACTIONS.SAVE_MENU_BUILDER_MENU_ITEM_BEGIN });
    const validationResult = MenuBuilderService.validateMenuItem(menuItem);
    if (validationResult.errorsFound) {
      if (duplicating) {
        dispatch({
          type: ITEMS_AND_MODS_ACTIONS.DUPLICATE_MENU_BUILDER_MENU_ITEM_VALIDATION_ERROR,
        });
      } else {
        dispatch({
          type: ITEMS_AND_MODS_ACTIONS.SAVE_MENU_BUILDER_MENU_ITEM_VALIDATION_ERROR,
          payload: validationResult.menuItem,
        });
      }
      return;
    }
    try {
      const isNewItem = !menuItem._id;
      const storedMenuItem = await MenuBuilderService.storeMenuItem(companyId, location, menuItem, readOnlyStock);
      dispatch(
        set200Toast(
          isNewItem ? `Success! New ${menuItem.itemType === 'item' ? 'item' : 'modifier'} created!` : undefined
        )
      );
      dispatch(setFormIsDirty(false));
      dispatch({
        type: ITEMS_AND_MODS_ACTIONS.SAVE_MENU_BUILDER_MENU_ITEM_SUCCESS,
        payload: {
          storedMenuItem,
          id: isNewItem ? storedMenuItem._id : '',
        },
      });
      dispatch({
        type: ITEMS_AND_MODS_ACTIONS.SET_MENU_ITEM_CACHE,
        payload: storedMenuItem,
      });
    } catch (error) {
      dispatch({
        type: ITEMS_AND_MODS_ACTIONS.SAVE_MENU_BUILDER_MENU_ITEM_ERROR,
      });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const saveSmbMenuItem =
  (
    companyId: string,
    location: IClientLocation,
    menuItem: ISmbMenuItem,
    readOnlyStock?: boolean,
    duplicating?: boolean
  ) =>
  async (dispatch: any) => {
    dispatch({ type: ITEMS_AND_MODS_ACTIONS.SAVE_MENU_BUILDER_MENU_ITEM_BEGIN });
    const validationResult = MenuBuilderService.validateSmbMenuItem(menuItem);
    if (validationResult.errorsFound) {
      if (duplicating) {
        dispatch({
          type: ITEMS_AND_MODS_ACTIONS.DUPLICATE_MENU_BUILDER_MENU_ITEM_VALIDATION_ERROR,
        });
      } else {
        dispatch({
          type: ITEMS_AND_MODS_ACTIONS.SAVE_MENU_BUILDER_MENU_ITEM_VALIDATION_ERROR,
          payload: validationResult.menuItem,
        });
      }
      return;
    }
    try {
      const isNewItem = !menuItem._id;
      const storedMenuItem = await MenuBuilderService.storeMenuItem(
        companyId,
        location,
        menuItem,
        readOnlyStock,
        menuItem.taxes
      );
      dispatch(
        set200Toast(
          isNewItem ? `Success! New ${menuItem.itemType === 'item' ? 'item' : 'modifier'} created!` : undefined
        )
      );
      dispatch(setFormIsDirty(false));
      dispatch({
        type: ITEMS_AND_MODS_ACTIONS.SAVE_MENU_BUILDER_MENU_ITEM_SUCCESS,
        payload: {
          storedMenuItem,
          id: isNewItem ? storedMenuItem._id : '',
        },
      });
      dispatch({
        type: ITEMS_AND_MODS_ACTIONS.SET_MENU_ITEM_CACHE,
        payload: storedMenuItem,
      });
    } catch (error) {
      dispatch({
        type: ITEMS_AND_MODS_ACTIONS.SAVE_MENU_BUILDER_MENU_ITEM_ERROR,
      });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const addModifierGroupToMenuItem = (modifierGroup: IModifierGroup) => async (dispatch: any) => {
  dispatch({
    type: ITEMS_AND_MODS_ACTIONS.ADD_MODIFIER_GROUP_TO_MENU_ITEM,
    payload: modifierGroup,
  });
  dispatch(setFormIsDirty(true));
};

export const removeModifierGroupFromMenuItem = (modifierGroupId: string) => async (dispatch: any) => {
  dispatch({
    type: ITEMS_AND_MODS_ACTIONS.REMOVE_MODIFIER_GROUP_FROM_MENU_ITEM,
    payload: modifierGroupId,
  });
  dispatch(setFormIsDirty(true));
};

export const moveModifierGroupInMenuItem = (sourceIndex: number, destinationIndex: number) => async (dispatch: any) => {
  dispatch({
    type: ITEMS_AND_MODS_ACTIONS.MOVE_MODIFIER_GROUP_IN_MENU_ITEM,
    payload: {
      sourceIndex,
      destinationIndex,
    },
  });
  dispatch(setFormIsDirty(true));
};

export const prepareMenuItemsModal = (visible: boolean, selectedMenuItemIds?: string[], view?: MenuItemsView) => ({
  type: ITEMS_AND_MODS_ACTIONS.PREPARE_MENU_BUILDER_ITEMS_AND_MODS_MODAL,
  payload: {
    visible,
    selectedMenuItemIds: selectedMenuItemIds || [],
    view: view || MenuItemsView.MODS,
  },
});

export const prepareForcedModiferModal = (visible: boolean, selectedModIds?: string[]) => ({
  type: ITEMS_AND_MODS_ACTIONS.PREPARE_MENU_BUILDER_FORCED_MOD_MODAL,
  payload: { visible, selectedModIds: selectedModIds },
});

export const setMenuItemsModalQuery = (query: string) => ({
  type: ITEMS_AND_MODS_ACTIONS.SET_MENU_BUILDER_ITEMS_AND_MODS_MODAL_QUERY,
  payload: query,
});

export const setMenuItemsModalView = (view: MenuItemsView) => ({
  type: ITEMS_AND_MODS_ACTIONS.SET_MENU_BUILDER_ITEMS_AND_MODS_MODAL_VIEW,
  payload: view,
});

export const selectItemInMenuItemsModal = (id: string) => ({
  type: ITEMS_AND_MODS_ACTIONS.ADD_ITEMS_AND_MODS_TO_ITEMS_AND_MODS_SELECTION,
  payload: id,
});

export const deselectItemInMenuItemsModal = (id: string) => ({
  type: ITEMS_AND_MODS_ACTIONS.REMOVE_ITEMS_AND_MODS_FROM_ITEMS_AND_MODS_SELECTION,
  payload: id,
});

export const loadMenuItemsForInfiniteScroll =
  (companyId: string, locationId: string, view: MenuItemsView, query: string = '') =>
  async (dispatch: any) => {
    dispatch({
      type: ITEMS_AND_MODS_ACTIONS.LOAD_MENU_BUILDER_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_BEGIN,
    });

    try {
      let searchMenuResults: IMenuItem[] = [];

      if (isSharedMenu(companyId, locationId)) {
        const sharedMenuItemsPagination = await getSharedMenuItemList(companyId, query, 'items');
        searchMenuResults = mapITemplateMenuItemToIMenuItem(sharedMenuItemsPagination.items);
      } else {
        const menuBuilderItemsPagination = await MenuBuilderService.getMenuItems(
          companyId,
          locationId,
          view,
          ItemsAndGroupsStockStatus.All,
          query
        );
        searchMenuResults = menuBuilderItemsPagination.items;
      }

      dispatch({
        type: ITEMS_AND_MODS_ACTIONS.LOAD_MENU_BUILDER_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_SUCCESS,
        payload: searchMenuResults,
      });
    } catch (error) {
      dispatch({
        type: ITEMS_AND_MODS_ACTIONS.LOAD_MENU_BUILDER_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_ERROR,
      });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const loadMoreMenuItemsForInfiniteScroll =
  (companyId: string, locationId: string, view: MenuItemsView, query: string = '', page: number) =>
  async (dispatch: any) => {
    dispatch({
      type: ITEMS_AND_MODS_ACTIONS.LOAD_MORE_MENU_BUILDER_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_BEGIN,
    });
    try {
      const nextPage = page + 1;
      let searchMenuResults: IMenuItem[] = [];

      if (isSharedMenu(companyId, locationId)) {
        const sharedMenuItemsPagination = await getSharedMenuItemList(companyId, query, 'items', undefined, nextPage);
        searchMenuResults = mapITemplateMenuItemToIMenuItem(sharedMenuItemsPagination.items);
      } else {
        const menuBuilderItemsPagination = await MenuBuilderService.getMenuItems(
          companyId,
          locationId,
          view,
          ItemsAndGroupsStockStatus.All,
          query,
          nextPage
        );
        searchMenuResults = menuBuilderItemsPagination.items;
      }

      if (searchMenuResults.length > 0) {
        dispatch({
          type: ITEMS_AND_MODS_ACTIONS.LOAD_MORE_MENU_BUILDER_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_SUCCESS,
          payload: searchMenuResults,
        });
      } else {
        dispatch({
          type: ITEMS_AND_MODS_ACTIONS.LOAD_MORE_MENU_BUILDER_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_NO_RESULTS,
        });
      }
    } catch (error) {
      dispatch({
        type: ITEMS_AND_MODS_ACTIONS.LOAD_MORE_MENU_BUILDER_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_ERROR,
      });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const preparePosItemsModal = (visible: boolean, type?: LinkPosItemType, selectedItemId?: string) => ({
  type: ITEMS_AND_MODS_ACTIONS.PREPARE_MENU_BUILDER_POS_ITEMS_AND_MODS_MODAL,
  payload: {
    visible,
    type,
    selectedItemId,
  },
});

export const setPosItemsModalQuery = (query: string) => ({
  type: ITEMS_AND_MODS_ACTIONS.SET_MENU_BUILDER_POS_ITEMS_AND_MODS_MODAL_QUERY,
  payload: query,
});

export const selectPosItemInPosItemsModal =
  (item: ICachedPosMenuItem | ICachedPosModifier) => async (dispatch: any) => {
    dispatch({
      type: ITEMS_AND_MODS_ACTIONS.SELECT_POS_ITEM_IN_POS_ITEMS_AND_MODS_MODAL,
      payload: item,
    });
    dispatch(setFormIsDirty(true));
  };

export const loadPosItemsForInfiniteScroll =
  (companyId: string, locationId: string, type: LinkPosItemType, query: string = '') =>
  async (dispatch: any) => {
    dispatch({
      type: ITEMS_AND_MODS_ACTIONS.LOAD_MENU_BUILDER_POS_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_BEGIN,
    });
    try {
      const results =
        type === 'item'
          ? await MenuBuilderService.getPosItems(companyId, locationId, query)
          : await MenuBuilderService.getPosModifiers(companyId, locationId, query);
      dispatch({
        type: ITEMS_AND_MODS_ACTIONS.LOAD_MENU_BUILDER_POS_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_SUCCESS,
        payload: results.items,
      });
    } catch (error) {
      dispatch({
        type: ITEMS_AND_MODS_ACTIONS.LOAD_MENU_BUILDER_POS_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_ERROR,
      });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const loadMorePosItemsForInfiniteScroll =
  (companyId: string, locationId: string, type: LinkPosItemType, query: string = '', page: number) =>
  async (dispatch: any) => {
    dispatch({
      type: ITEMS_AND_MODS_ACTIONS.LOAD_MORE_MENU_BUILDER_POS_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_BEGIN,
    });
    try {
      const nextPage = page + 1;
      const results =
        type === 'item'
          ? await MenuBuilderService.getPosItems(companyId, locationId, query, nextPage)
          : await MenuBuilderService.getPosModifiers(companyId, locationId, query, nextPage);
      if (results.length > 0) {
        dispatch({
          type: ITEMS_AND_MODS_ACTIONS.LOAD_MORE_MENU_BUILDER_POS_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_SUCCESS,
          payload: results.items,
        });
      } else {
        dispatch({
          type: ITEMS_AND_MODS_ACTIONS.LOAD_MORE_MENU_BUILDER_POS_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_NO_RESULTS,
        });
      }
    } catch (error) {
      dispatch({
        type: ITEMS_AND_MODS_ACTIONS.LOAD_MORE_MENU_BUILDER_POS_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_ERROR,
      });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const addMenuItemToMenuItemPairing = (menuItem: IMenuItem) => ({
  type: ITEMS_AND_MODS_ACTIONS.ADD_MENU_ITEM_TO_MENU_ITEM_PAIRING,
  payload: MenuBuilderService.convertIMenuItemToIEmbeddedMenuItem(menuItem),
});

export const removeMenuItemFromMenuItemPairing = (menuItemId: string) => async (dispatch: any) => {
  dispatch({
    type: ITEMS_AND_MODS_ACTIONS.REMOVE_MENU_ITEM_FROM_MENU_ITEM_PAIRING,
    payload: menuItemId,
  });
  dispatch(setFormIsDirty(true));
};

export const quickUpdateMenuItemInStockBegin = () => ({
  type: ITEMS_AND_MODS_ACTIONS.QUICK_UPDATE_MENU_ITEM_IN_STOCK_BEGIN,
});

export const quickUpdateMenuItemInStockSuccess = (id: string, newStockStatus: boolean) => ({
  type: ITEMS_AND_MODS_ACTIONS.QUICK_UPDATE_MENU_ITEM_IN_STOCK_SUCCESS,
  payload: { id, newStockStatus },
});

export const quickUpdateMenuItemInStockError = () => ({
  type: ITEMS_AND_MODS_ACTIONS.QUICK_UPDATE_MENU_ITEM_IN_STOCK_ERROR,
});

export const quickUpdateMenuItemInStock =
  (companyId: string, locationId: string, id: string, newStockStatus: boolean) => async (dispatch: any) => {
    dispatch(quickUpdateMenuItemInStockBegin());
    try {
      const itemToUpdate: IStockUpdateRequest[] = [
        {
          _id: id,
          inStock: newStockStatus,
        },
      ];
      await MenuBuilderService.bulkUpdateItemAndModStock(companyId, locationId, itemToUpdate);
      dispatch(quickUpdateMenuItemInStockSuccess(id, newStockStatus));
      dispatch(set200Toast('Stock status updated!'));
    } catch (error) {
      dispatch(quickUpdateMenuItemInStockError());
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const syncMenuItems =
  (
    companyId: string,
    locationId: string,
    view: MenuItemsView,
    stockStatus: ItemsAndGroupsStockStatus,
    query: string = '',
    page: number = 1
  ) =>
  async (dispatch: any) => {
    dispatch({
      type: ITEMS_AND_MODS_ACTIONS.SYNC_MENU_BUILDER_MENU_ITEMS_BEGIN,
    });
    try {
      await MenuBuilderService.syncMenuItems(companyId, locationId);
      const updatedMenuItems = await MenuBuilderService.getMenuItems(
        companyId,
        locationId,
        view,
        stockStatus,
        query,
        page
      );
      dispatch({
        type: ITEMS_AND_MODS_ACTIONS.SYNC_MENU_BUILDER_MENU_ITEMS_SUCCESS,
        payload: updatedMenuItems,
      });
      dispatch(set200Toast('Success! Sync complete.'));
    } catch (error) {
      const previousMenuItems = await MenuBuilderService.getMenuItems(
        companyId,
        locationId,
        view,
        stockStatus,
        query,
        page
      );
      dispatch({
        type: ITEMS_AND_MODS_ACTIONS.SYNC_MENU_BUILDER_MENU_ITEMS_ERROR,
        payload: previousMenuItems,
      });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const showDuplicateItemModal = (visible: boolean) => ({
  type: ITEMS_AND_MODS_ACTIONS.SHOW_DUPLICATE_MENU_BUILDER_MENU_ITEM_MODAL,
  payload: visible,
});

export const duplicateMenuItem =
  (companyId: string, location: IClientLocation, menuItem: IMenuItem, displayName: string) => async (dispatch: any) => {
    const duplicatedMenuItem: IMenuItem = {
      ...menuItem,
      _id: '',
      displayName,
    };
    dispatch(saveMenuItem(companyId, location, duplicatedMenuItem, true, true));
  };

export const deleteMenuItem =
  (companyId: string, locationId: string, menuItemId: string, itemType: ItemType) => async (dispatch: any) => {
    dispatch(setFormIsDirty(false));
    dispatch({
      type: ITEMS_AND_MODS_ACTIONS.DELETE_MENU_BUILDER_MENU_ITEM_BEGIN,
    });
    try {
      await MenuBuilderService.deleteMenuItem(companyId, locationId, menuItemId);
      dispatch({
        type: ITEMS_AND_MODS_ACTIONS.DELETE_MENU_BUILDER_MENU_ITEM_SUCCESS,
      });
      dispatch(set200Toast(`${itemType === 'item' ? 'Item' : 'Modifier'} deleted.`));
    } catch (error) {
      dispatch({
        type: ITEMS_AND_MODS_ACTIONS.DELETE_MENU_BUILDER_MENU_ITEM_ERROR,
      });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const loadPosModifiers =
  (companyId: string, locationId: string, query: string = '', page: number = 1, pageSize: number = 10) =>
  async (dispatch: any) => {
    dispatch({
      type: ITEMS_AND_MODS_ACTIONS.LOAD_MENU_BUILDER_POS_MODIFIERS_BEGIN,
    });
    try {
      const results = await MenuBuilderService.getPosModifiers(companyId, locationId, query, page, pageSize);
      dispatch({
        type: ITEMS_AND_MODS_ACTIONS.LOAD_MENU_BUILDER_POS_MODIFIERS_SUCCESS,
        payload: results.items,
      });
    } catch (error) {
      dispatch({
        type: ITEMS_AND_MODS_ACTIONS.LOAD_MENU_BUILDER_POS_MODIFIERS_ERROR,
      });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const addReadyForcedModifierToMenuItem = (modifierItem: IForcedModifier) => async (dispatch: any) => {
  dispatch({
    type: ITEMS_AND_MODS_ACTIONS.ADD_READY_FORCED_MODIFIER_TO_MENU_ITEM,
    payload: modifierItem,
  });
  dispatch(setFormIsDirty(true));
};

export const removeReadyForcedModifierToMenuItem = (modifierItemId: string) => async (dispatch: any) => {
  dispatch({
    type: ITEMS_AND_MODS_ACTIONS.REMOVE_READY_FORCED_MODIFIER_TO_MENU_ITEM,
    payload: modifierItemId,
  });
  dispatch(setFormIsDirty(true));
};

export const reenableMenuItem =
  (companyId: string, location: IClientLocation, menuItem: IMenuItem) => async (dispatch: any) => {
    const reenabledMenuItem = {
      ...menuItem,
      status: Status.enabled,
    };
    dispatch({ type: ITEMS_AND_MODS_ACTIONS.SAVE_MENU_BUILDER_MENU_ITEM_BEGIN });
    try {
      const updatedMenuItem = await MenuBuilderService.storeMenuItem(companyId, location, reenabledMenuItem);
      dispatch(set200Toast());
      dispatch({
        type: ITEMS_AND_MODS_ACTIONS.SAVE_MENU_BUILDER_MENU_ITEM_SUCCESS,
      });
      dispatch(loadMenuItem(companyId, location.id, updatedMenuItem._id));
    } catch (error) {
      dispatch({
        type: ITEMS_AND_MODS_ACTIONS.SAVE_MENU_BUILDER_MENU_ITEM_ERROR,
      });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const updateMenuItemAddTag = (tag: ITag) => (dispatch: any) => {
  dispatch({
    type: ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_ADD_TAG,
    payload: tag,
  });
};

export const updateMenuItemRemoveTag = (tagId: string) => (dispatch: any) => {
  dispatch({
    type: ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_REMOVE_TAG,
    payload: tagId,
  });
};

export const updateMenuItemBadge = (badge?: IBadge) => (dispatch: any) => {
  dispatch({
    type: ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_BADGE,
    payload: badge,
  });
  dispatch(setFormIsDirty(true));
};

export const updateMenuItemPrice = (price?: number) => (dispatch: any) => {
  dispatch({
    type: ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_PRICE,
    payload: price,
  });
  dispatch(setFormIsDirty(true));
};

export const selectTaxes = (taxes?: ISmbTax[]) => (dispatch: any) => {
  dispatch({
    type: ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_TAXES,
    payload: taxes,
  });
  dispatch(setFormIsDirty(true));
};
