import { createAsyncThunk } from '@reduxjs/toolkit';
import { toastErrorState } from 'redux/actions/uiActions/responseStateActions';
import { UserContextService } from 'services/userContextsService';

const getCompanyInfoById = createAsyncThunk(
  'companies/details/info/fetch',
  async (args: { companyId: string }, thunkAPI) => {
    try {
      return await UserContextService.getContextById(args.companyId);
    } catch (error) {
      thunkAPI.dispatch(toastErrorState(error.status, error.message));
    }
  }
);

export { getCompanyInfoById };
