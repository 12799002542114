import React from 'react';

export interface TabProps {
  children?: JSX.Element[] | JSX.Element;
  id: string;
  route: string;
  label: string;
}

const Tab = (props: TabProps) => {
  const { children } = props;

  return <div>{children}</div>;
};

export default Tab;
