import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../../redux/initialStates/AppState';
import { setModifierGroupsModalQuery } from '../../redux/ModifierGroupsActions';
import SearchInput from '../../../components/SearchInput/SearchInput';

export type AddModifierGroupSearchProps = ConnectedProps<typeof connector>;

const AddModifierGroupSearch = (props: AddModifierGroupSearchProps) => {
  const { query, setModifierGroupsModalQuery } = props;

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModifierGroupsModalQuery(event.target.value);
  };

  return (
    <SearchInput
      value={query}
      placeholder='Search Modifier Groups'
      fullWidth
      onChange={handleQueryChange}
      autoFocus={true}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  query: state.menuBuilder.modifierGroups.modifierGroupSelection.query,
});

const actionCreators = {
  setModifierGroupsModalQuery,
};

const connector = connect(mapStateToProps, actionCreators);

export default connector(AddModifierGroupSearch);
