import React, { useEffect, useCallback, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import styles from './CreateSchedulePage.module.scss';
import ActionHeader from '../../../components/ActionHeader/ActionHeader';
import LayoutContent from '../../../components/AppLayout/LayoutContent';
import { Form, FormControl } from '../../../components/Form';
import FormActionBar from '../../../components/FormActionBar/FormActionBar';
import TextInput from '../../../components/TextInput/TextInput';
import { AppState } from '../../../redux/initialStates/AppState';
import TimeSlotSelector from '../../../components/TimeSlotSelector/TimeSlotSelector';
import { IScheduleSlot } from '@ready/menu.core';
import Button from '../../../components/Button/Button';
import { Routes } from '../../SchedulesRouter';
import { useHistory, useParams } from 'react-router-dom';
import { ContextParams } from '../../../types/ContextParams.interface';
import {
  initSchedule,
  setScheduleSlot,
  setScheduleName,
  addNewSlot,
  removeSlot,
  setScheduleProcessing,
  validateSchedule,
  submitSchedule,
  cancelCreateSchedule,
} from '../../redux/ScheduleActions';
import { ScheduleValidationErrors } from '../../redux/ScheduleState';

const CreateSchedulePage = ({
  schedule,
  processing,
  cancelled,
  errors,
  initSchedule,
  setScheduleSlot,
  setScheduleName,
  addNewSlot,
  removeSlot,
  setScheduleProcessing,
  validateSchedule,
  submitSchedule,
  cancelCreateSchedule,
}: ReduxProps): JSX.Element => {
  const { push } = useHistory();
  const { contextId } = useParams<ContextParams>();
  const [newScheduleId, setNewScheduleId] = useState('');

  // Adds an empty slot on load, and clears all form data on un-load
  useEffect(() => {
    addNewSlot();

    return () => {
      initSchedule();
    };
  }, [addNewSlot, initSchedule]);

  const handleFormSubmit = useCallback(async () => {
    const hasErrors = validateSchedule(schedule);

    if (!hasErrors) {
      setNewScheduleId(await submitSchedule(contextId, schedule));
    }

    // Just return success status to the FormActionBar since error handling is
    // already taken care of.
    return { status: 200 };
  }, [contextId, schedule, submitSchedule, validateSchedule]);

  // Navigate to edit page after successful submit
  useEffect(() => {
    if (newScheduleId !== '') {
      push(Routes.getEditSchedulesPageRoute(contextId, newScheduleId));
    }
  }, [push, contextId, newScheduleId]);

  useEffect(() => {
    if (cancelled) {
      // Go back to list page (no form reset, since this is a create page)
      push(Routes.getSchedulesListPageRoute(contextId));
    }
  }, [cancelled, push, contextId]);

  const handleResetForm = () => {
    cancelCreateSchedule();
  };

  const handleAddTimeSlotClick = () => {
    addNewSlot();
  };

  const handleScheduleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setScheduleName(event.target.value);
  };

  return (
    <>
      <FormActionBar
        actionBarVisible={true}
        handleFormSubmit={handleFormSubmit}
        resetForm={handleResetForm}
        setIsFormProcessing={setScheduleProcessing}
        isFormProcessing={processing}
        saveLabel='Create Schedule'
      />
      <LayoutContent title='Schedule Details' containerType='within'>
        <ActionHeader text='New Schedule' />
        <Form additionalClassName={styles.form}>
          <FormControl
            label='Schedule Name *'
            errorMessage={'Schedule Name is required'}
            withError={errors.emptyNameError}
          >
            <div className={styles.formControlContainer}>
              <TextInput value={schedule.name} onChange={handleScheduleNameChange} disabled={processing} />
            </div>
          </FormControl>
          <FormControl label='Availability *'>
            <div className={styles.formControlContainer}>
              {schedule.slots.map((slot, slotIndex) => {
                const handleClearClick = () => {
                  removeSlot(slotIndex);
                };

                const handleSlotChange = (scheduleSlot: IScheduleSlot) => {
                  setScheduleSlot(scheduleSlot, slotIndex);
                };

                const { daysOfWeekErrorMessage, startTimeErrorMessage, endTimeErrorMessage } = getErrorMessages(
                  errors,
                  slotIndex
                );

                return (
                  <TimeSlotSelector
                    // 'slots' is an arbitrary array, so there are no unique
                    // identifiers except for the index
                    key={slotIndex}
                    value={slot}
                    onChange={handleSlotChange}
                    onClearClick={handleClearClick}
                    processing={processing}
                    daysOfWeekErrorMessage={daysOfWeekErrorMessage}
                    startTimeErrorMessage={startTimeErrorMessage}
                    endTimeErrorMessage={endTimeErrorMessage}
                    // Disable the clear button if it's the only slot
                    disableClearClick={schedule.slots.length <= 1}
                  />
                );
              })}
              <Button label='+ Add Time Slot' size='large' variant='secondary' onClick={handleAddTimeSlotClick} />
            </div>
          </FormControl>
        </Form>
      </LayoutContent>
    </>
  );
};

const getErrorMessages = (errors: ScheduleValidationErrors, slotIndex: number) => {
  const slotErrors = errors.slotErrors.find((error) => error.slotIndex === slotIndex);

  return {
    daysOfWeekErrorMessage: slotErrors?.emptyDaysError ? 'Select at least 1 day' : '',
    startTimeErrorMessage: slotErrors?.emptyStartTimeError
      ? 'Enter a valid Start Time'
      : slotErrors?.timeRangeOrderError
      ? 'Start Time must be before End Time'
      : '',
    endTimeErrorMessage: slotErrors?.emptyEndTimeError ? 'Enter a valid End Time' : '',
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    schedule: state.menuBuilder.schedule.schedule,
    processing: state.menuBuilder.schedule.processing,
    cancelled: state.menuBuilder.schedule.cancelled,
    errors: state.menuBuilder.schedule.errors,
  };
};

const actionCreators = {
  initSchedule,
  setScheduleSlot,
  setScheduleName,
  addNewSlot,
  removeSlot,
  setScheduleProcessing,
  validateSchedule,
  submitSchedule,
  cancelCreateSchedule,
};

const connector = connect(mapStateToProps, actionCreators);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(CreateSchedulePage);
