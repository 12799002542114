import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { updatePath } from '../../utils/updatePath';
import { useSearchParams } from '../../hooks';
import getSearchParam from '../../utils/urlUtils/getSearchParam';
import { updateURLSearchParams } from '../../utils/updateURLSearchParams';
import TextInput from '../TextInput/TextInput';

export interface SearchInputFilterProps {
  name: string;
  placeholder?: string;
  additionalClassName?: string;
  additionalContainerClassName?: string;
  disabled?: boolean;
  additionalParams?: string;
}

const SearchInputFilter = ({
  name,
  placeholder = '',
  additionalClassName = '',
  additionalContainerClassName,
  disabled = false,
  additionalParams,
}: SearchInputFilterProps) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { query } = useSearchParams();

  const [value, setValue] = React.useState('');

  // Handles change via query param value
  React.useEffect(() => {
    let initialValue = '';

    if (query) {
      const encodedValueFromUrl = getSearchParam(decodeURIComponent(query), name);
      if (encodedValueFromUrl) {
        initialValue = decodeURIComponent(encodedValueFromUrl);
      }
    }
    setValue(initialValue);
  }, [query, name]);

  // Handles change via user input
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setValue(value);

    const sanitizedValue = updateURLSearchParams(query, [{ name, value }]).toEncodedString();
    updatePath(sanitizedValue, pathname, push, additionalParams);
  };

  return (
    <TextInput
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      additionalStyles={additionalClassName}
      additionalContainerStyles={additionalContainerClassName}
      onChange={handleChange}
    />
  );
};

export default SearchInputFilter;
