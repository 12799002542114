import {
  ITemplateItemGallery,
  ITemplateItemGalleryRequest,
  ITemplateItemGallerySummary,
  ITemplateMenu,
  ITemplateMenuRequest,
  ITemplateMenuSummary,
  TemplateItemGallerySummaryRequest,
  TemplateMenuSummaryRequest,
} from '@ready/menu.core';
import config from 'config';
import executeApiAsync from 'services/utils/executeApiAsync';

export type INewTemplateMenuRequest = Omit<ITemplateMenuRequest, '_id'>;

export const createTemplateMenu = async (
  companyId: string,
  groupId: string,
  templateMenuRequest: INewTemplateMenuRequest
): Promise<ITemplateMenu> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menuGroups/${groupId}/menus`;
  return await executeApiAsync<ITemplateMenu>(url, {
    method: 'POST',
    body: JSON.stringify(templateMenuRequest),
  });
};

export const updateTemplateMenu = async (
  companyId: string,
  templateMenuRequest: ITemplateMenuRequest
): Promise<ITemplateMenu> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus`;
  return await executeApiAsync<ITemplateMenu>(url, {
    method: 'PUT',
    body: JSON.stringify(templateMenuRequest),
  });
};

export const getTemplateMenu = async (companyId: string, menuId: string): Promise<ITemplateMenu> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/${menuId}`;
  return await executeApiAsync<ITemplateMenu>(`${url}`, {
    method: 'GET',
  });
};

export const deleteTemplateMenu = async (companyId: string, menuId: string): Promise<void> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/${menuId}`;
  await executeApiAsync(`${url}`, {
    method: 'DELETE',
  });
};

export const deleteTemplateItemGallery = async (companyId: string, itemGalleryId: string): Promise<void> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/itemGalleries/${itemGalleryId}`;
  await executeApiAsync(`${url}`, {
    method: 'DELETE',
  });
};

export const updateTemplateMenuSummary = async (
  companyId: string,
  summaryRequest: TemplateMenuSummaryRequest
): Promise<ITemplateMenuSummary> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/summaries`;
  return await executeApiAsync<ITemplateMenuSummary>(url, {
    method: 'PUT',
    body: JSON.stringify(summaryRequest),
  });
};

export const updateTemplateItemGallerySummary = async (
  companyId: string,
  summaryRequest: TemplateItemGallerySummaryRequest
): Promise<ITemplateItemGallerySummary> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/itemgalleries/summaries`;
  return await executeApiAsync<ITemplateMenuSummary>(url, {
    method: 'PUT',
    body: JSON.stringify(summaryRequest),
  });
};

export const createTemplateMenuGroupItemGallery = async (
  companyId: string,
  groupId: string,
  itemGalleryRequest: Omit<ITemplateItemGalleryRequest, '_id'>
): Promise<ITemplateItemGallery> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menuGroups/${groupId}/menus/itemgalleries`;
  return await executeApiAsync<ITemplateItemGallery>(url, {
    method: 'POST',
    body: JSON.stringify(itemGalleryRequest),
  });
};

export const getTemplateMenuGroupItemGallery = async (
  companyId: string,
  itemGalleryId: string
): Promise<ITemplateItemGallery> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/itemgalleries/${itemGalleryId}`;
  return await executeApiAsync<ITemplateItemGallery>(`${url}`, {
    method: 'GET',
  });
};
