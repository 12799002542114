import React from 'react';
import InfiniteScrollingList from 'components/InfiniteScrollingList/InfiniteScrollingList';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  selectItemInModal,
  unselectRecommendedItem,
  updateSharedMenuItemState,
} from 'sharedMenuItems/redux/createEditItem/createEditItemSlice';
import { selectCreateEditItemState } from 'sharedMenuItems/redux/selectors';
import { ItemWithAssignedLocations } from '../itemWithAssignedLocations/ItemWithAssignedLocations';
import { useParams } from 'react-router-dom';
import { ContextParams } from 'types/ContextParams.interface';
import { debounce } from 'lodash';
import {
  getMoreRecommendedItemsThunk,
  getRecommendedItemsThunk,
} from 'sharedMenuItems/redux/createEditItem/createEditItemThunkActions';
import { setFormIsDirty } from 'redux/actions/uiActions/formStateActions';
import { ITemplateEmbeddedMenuItem, ITemplateMenuItem, WithAssignedLocationsCount } from '@ready/menu.core';
import { mapITemplateMenuItemToITemplateEmbeddedMenuItem } from 'sharedMenuItems/mappers/mappers';
import styles from './RecommendedItemsModalList.module.scss';

const debounceTime = 300;

export const RecommendedItemsModalList = () => {
  const dispatch = useAppDispatch();
  const { contextId: companyId } = useParams<ContextParams>();
  const {
    modals: {
      recommendedItemsModal: {
        paginationAvailable,
        paginationLoading,
        error,
        filtered,
        selectedIds,
        items,
        query,
        page,
        loading,
      },
    },
    item,
  } = useAppSelector(selectCreateEditItemState);
  const noItemsTitle = 'No Results Found';
  const noItemsMessage =
    'Make sure the items you are looking for have been created in your point of sale, or try refining your search.';

  const selectModalItem = (recommendedItem: WithAssignedLocationsCount<ITemplateMenuItem>) => {
    const embeddedMenuItem: ITemplateEmbeddedMenuItem = mapITemplateMenuItemToITemplateEmbeddedMenuItem(
      recommendedItem
    );

    dispatch(setFormIsDirty(true));
    dispatch(
      selectItemInModal({
        stateKey: 'recommendedItemsModal',
        itemId: embeddedMenuItem.itemId,
      })
    );

    dispatch(
      updateSharedMenuItemState({
        recommendedItems: [...(item?.recommendedItems ?? []), embeddedMenuItem],
      })
    );
  };

  const unselectModalItem = (id: string) => {
    dispatch(setFormIsDirty(true));
    dispatch(unselectRecommendedItem(id));
  };

  const loadRecommendedItemsDebounced = React.useMemo(() => {
    return debounce((companyId: string, query?: string) => {
      dispatch(getRecommendedItemsThunk({ companyId, page: 1, query }));
    }, debounceTime);
  }, [dispatch]);

  const loadRecommendedItems = React.useCallback(() => {
    loadRecommendedItemsDebounced(companyId, query);
  }, [loadRecommendedItemsDebounced, companyId, query]);

  const nextPage = page + 1;

  const loadMoreRecommendedItemsDebounced = React.useMemo(() => {
    return debounce((companyId: string, nextPage: number, query?: string) => {
      dispatch(getMoreRecommendedItemsThunk({ companyId, query, page: nextPage }));
    }, debounceTime);
  }, [dispatch]);

  const loadMoreRecommendedItems = () => {
    loadMoreRecommendedItemsDebounced(companyId, nextPage, query);
  };

  return (
    <InfiniteScrollingList
      loading={loading}
      error={error}
      paginationLoading={paginationLoading}
      paginationAvailable={paginationAvailable}
      items={items}
      filtered={filtered}
      noItemsTitle={noItemsTitle}
      noItemsMessage={noItemsMessage}
      selectedItemKeys={selectedIds}
      loadItems={loadRecommendedItems}
      loadMoreItems={loadMoreRecommendedItems}
      getItemKey={(item: WithAssignedLocationsCount<ITemplateMenuItem>) => item._id}
      buildItemComponent={ItemWithAssignedLocations}
      addItem={selectModalItem}
      removeItem={unselectModalItem}
      isInModal
      listAdditionalClassName={styles.list}
    />
  );
};
