import { IValidationField } from '@ready/dashboardv2api.contracts';
import React from 'react';
import { useAppSelector } from '../../../redux/store';
import { DetailElement, DetailLayout } from '../../../components/DetailLayout';
import StatusIndicator from '../../../components/StatusIndicator/StatusIndicator';
import TextInputNestedLabel from '../../../components/TextInputNestedLabel/TextInputNestedLabel';
import { useClassNames } from '../../../utils/cssUtils';
import PaymentOptionShortCodePreview from '../../components/OnAccountPaymentOptions/PaymentOptionShortCodePreview';
import { selectOnAccountPaymentOptions } from '../../redux/selectors/onAccountPaymentOptionsSelector';
import { NoneComponent } from '../digitalMenu/DigitalMenuViewPanel';
import styles from './OnAccountPaymentOptionDetailsViewPanel.module.scss';
import { IOnAccountPaymentOptionsState } from '../../redux/initialStates/onAccountPaymentOptions';
import { ITenderType } from '../../redux/initialStates/paymentProcessing';

export const OnAccountPaymentOptionDetailsViewPanel = () => {
  const {
    view: {
      config: { enabled, name, description, code, tenderType: tenderTypeId, validationFields },
      tenderTypesOptions: { tenderTypes },
    },
  } = useAppSelector<IOnAccountPaymentOptionsState>(selectOnAccountPaymentOptions);

  const nestedTextInputDetailElementClassName = useClassNames(
    [styles.textInputLabel, styles.detailElementLabel],
    styles
  );

  const hasValidationFields = validationFields && validationFields.length > 0;
  const posTenderType = tenderTypes?.find((tenderType: ITenderType) => tenderType.id === tenderTypeId);

  return (
    <DetailLayout>
      <DetailElement labelAdditionalStyles={styles.detailElementLabel} label='Status'>
        <StatusIndicator
          additionalStyles={styles.statusIndicator}
          active={enabled}
          activeLabel='Enabled'
          inactiveLabel='Disabled'
        />
      </DetailElement>
      <DetailElement labelAdditionalStyles={styles.detailElementLabel} label='Payment Option Name *'>
        {name}
      </DetailElement>
      <DetailElement
        label='Description'
        labelTooltip='This description will be visible to guests during checkout when they are shown a list of payment options.'
        labelAdditionalStyles={styles.detailElementLabel}
      >
        {description || <NoneComponent />}
      </DetailElement>
      <DetailElement
        labelAdditionalStyles={styles.detailElementLabel}
        label='Short Code *'
        labelTooltip='This will be displayed as an icon in your payment reports.'
      >
        <div className={styles.shortCodeContainer}>
          <PaymentOptionShortCodePreview textPreview={code} disabled={false}></PaymentOptionShortCodePreview>
        </div>
      </DetailElement>
      <DetailElement labelAdditionalStyles={styles.detailElementLabel} label='POS Tender Type *'>
        {posTenderType?.name}
      </DetailElement>

      <DetailElement
        label='Payment Validation Fields'
        labelTooltip='Guests will only be asked to fill out these fields if they choose this payment option during checkout.'
        labelAdditionalStyles={hasValidationFields ? nestedTextInputDetailElementClassName : ''}
      >
        {hasValidationFields ? (
          <>
            {validationFields.map((field: IValidationField, i) => (
              <div key={i + field.menuItemId} className={styles.textInputsContainer}>
                <TextInputNestedLabel
                  additionalStyles={styles.readOnlyInputField}
                  label={field.label}
                  value={field.placeholder}
                  onChange={() => {}}
                  withRequiredLabel={field.required}
                />
              </div>
            ))}
          </>
        ) : (
          <NoneComponent />
        )}
      </DetailElement>
    </DetailLayout>
  );
};
