import React from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import {
  IRole,
  PrincipalPermissions,
  ResourceType,
  SecurityRoleResourceActions,
  SecurityScope,
  Verifier,
} from '@ready/security.core';
// redux
import { connect, ConnectedProps } from 'react-redux';
import {
  initializeSecurityRoleslist,
  loadSecurityRolesList,
} from '../../../redux/actions/securityRolesActions/securityRolesListActions';
// hooks
import { useSearchParams } from '../../../hooks/useSearchParams';
import useResponsiveBreakpont from '../../../hooks/useResponsiveBreakpoint';
// components
import LayoutContent from '../../../components/AppLayout/LayoutContent';
import SearchParamInput from '../../../components/SearchInput/SearchParamInput';
import InfoCardList from '../../../components/InfoCard/InfoCardList';
import InfoCardColumnSkeleton from '../../../components/InfoCard/InfoCardColumnSkeleton';
import InfoCard from '../../../components/InfoCard/InfoCard';
import InfoCardColumn from '../../../components/InfoCard/InfoCardColumn';
import styles from './SecurityRoleListPage.module.scss';
import NoResults from '../../../components/NoResults/NoResults';
import HeaderControls from '../../../components/AppLayout/HeaderControls';

const DESKTOP_WIDTHS = [50, 50];
const TABLET_WIDTHS = [60, 40];
const MOBILE_WIDTHS = [70, 30];

const COLUMN_WIDTHS = { DESKTOP_WIDTHS, TABLET_WIDTHS, MOBILE_WIDTHS };

const SecurityRolesListPage = (props: ReduxProps) => {
  const { securityRoles, paginationProps, initializeSecurityRoleslist, loadSecurityRolesList, loading, permissions } =
    props;

  /* ****** Hooks ****** */

  const history = useHistory();
  const { contextId: companyId } = useParams() as any;
  const { query, page } = useSearchParams();
  const { isDesktop, isTablet, isMobile } = useResponsiveBreakpont();

  /* ****** State ****** */

  const [columnWidths, setColumnWidths] = React.useState(COLUMN_WIDTHS.DESKTOP_WIDTHS);

  /* ****** Permissions ****** */

  const hasAddRoleAccess = Verifier.check(
    new PrincipalPermissions(permissions),
    SecurityScope.company,
    ResourceType.securityRole,
    SecurityRoleResourceActions.all
  );

  /* ****** Effects ****** */

  // Fetches data when companyId, query, or page changes
  React.useEffect(() => {
    loadSecurityRolesList(companyId, query, page);
  }, [loadSecurityRolesList, companyId, query, page]);

  // Updates flex column widths depending on current breakpoint
  React.useEffect(() => {
    if (isDesktop) {
      setColumnWidths(COLUMN_WIDTHS.DESKTOP_WIDTHS);
    } else if (isTablet) {
      setColumnWidths(COLUMN_WIDTHS.TABLET_WIDTHS);
    } else if (isMobile) {
      setColumnWidths(COLUMN_WIDTHS.MOBILE_WIDTHS);
    }
  }, [isDesktop, isTablet, isMobile]);

  const noResults = !loading && securityRoles && !securityRoles.length && !query;
  const noSearchResults = !loading && securityRoles && !securityRoles.length && query;

  /* ****** Rendering ****** */

  const redirectToNewRole = (): void => history.push(`/companies/${companyId}/securityRoles/new`);
  const getUrlToRoleDetail = (roleId: string): string => `/companies/${companyId}/securityRoles/${roleId}`;

  // Cleans up data when component unmounts
  React.useEffect(() => {
    return () => {
      initializeSecurityRoleslist();
    };
  }, [initializeSecurityRoleslist]);

  const headerControlOptions = hasAddRoleAccess
    ? [
        {
          buttonLabel: '+ New Role',
          onClick: redirectToNewRole,
          primary: true,
        },
      ]
    : [];

  return (
    <LayoutContent
      title={'Security Roles'}
      containerType='full'
      darkBackground
      headerControls={<HeaderControls controlOptions={headerControlOptions} />}
    >
      <div className={styles.container}>
        <div className={styles.controls}>
          <SearchParamInput placeholder='Search Security Roles' additionalClassName='white' />
        </div>
        <div className={styles.content}>
          {!noResults ? (
            <InfoCardList paginationProps={paginationProps} noSearchResults={noSearchResults}>
              {!loading && securityRoles ? (
                securityRoles.map((data: IRole, index: number) => {
                  return (
                    <Link to={getUrlToRoleDetail(data._id)} key={index}>
                      <InfoCard>
                        <InfoCardColumn width={columnWidths[0]} columnClassName={styles.roleDataColumn}>
                          <div className={styles.roleName}>{data.name}</div>
                          {data.description ? (
                            <div className={styles.roleDescription}>{data.description}</div>
                          ) : (
                            <div className={styles.placeholderRoleDescription}>{'No Description'}</div>
                          )}
                        </InfoCardColumn>
                      </InfoCard>
                    </Link>
                  );
                })
              ) : (
                <InfoCardColumnSkeleton instances={10} rowsWithinInstance={2} />
              )}
            </InfoCardList>
          ) : (
            <NoResults additionalStyles={styles.noResultsBoxBorders} />
          )}
        </div>
      </div>
    </LayoutContent>
  );
};

const mapStateToProps = (state: any) => {
  const securityRolesList = state.securityRoles.securityRolesList;
  const { start, length, limit, total, loading } = securityRolesList;
  const permissions = state.session.permissions.permissionsList;

  return {
    securityRoles: securityRolesList.items,
    paginationProps: {
      start,
      length,
      limit,
      total,
    },
    loading,
    permissions,
  };
};

const actionCreators = {
  initializeSecurityRoleslist,
  loadSecurityRolesList,
};

const connector = connect(mapStateToProps, actionCreators);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(SecurityRolesListPage);
