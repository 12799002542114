import { IUIAdmin } from './AdminsListState';
export interface IAdminDetailsState {
  admin?: IUIAdmin;
  loading: boolean;
  error: boolean;
  accessChange: boolean;
}

export const initialAdminDetailsState: IAdminDetailsState = {
  admin: undefined,
  loading: false,
  error: false,
  accessChange: false,
};
