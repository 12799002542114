import { CustomTipType, ILocationGratuity } from '@ready/dashboardv2api.contracts';

export interface IGratuityPanel extends ILocationGratuity {
  isDirty: boolean;
  validation: any;
}

export const gratuityPanel = {
  preDiscountTip: false,
  preItemDiscountTip: false,
  preTaxTip: false,
  preNonReadyPaymentsTip: false,
  bottomText: '',
  showBottomText: false,
  allowTips: false,
  calculateTipsOn: '',
  defaultTips: [],
  isDirty: false,
  validation: {},
  defaultTipsForServiceCharges: [],
  defaultCustomTipType: CustomTipType.Percentage,
} as IGratuityPanel;
