import { IPagination, IPromoCodeReportListItem } from '@ready/dashboardv2api.contracts';
import { IPagedResponse } from '@ready/table.core';
import { ILocation } from '../../../services/types/ILocation.type';

export interface ILoading {
  list?: boolean;
  filters?: boolean;
  permittedLocations?: boolean;
  export?: boolean;
}

export interface IErrors {
  list?: string;
  filters?: string;
  permittedLocations?: string;
}

export interface IPromoCodeSummary {
  rowKey: string;
  columns: Array<string>;
  headerColumns: Record<string, string>;
  items: Array<IPromoCodeReportListItem>;
  pagination: IPagination;
  loading: ILoading;
  errors: IErrors;
  permittedLocations: IPagedResponse<ILocation>;
}

export const initialPromoCodeSummaryState: IPromoCodeSummary = {
  rowKey: '_id',
  items: [],
  permittedLocations: {
    totalCount: 0,
    results: [],
  },
  columns: ['code', 'description', 'locationName', 'usageCount', 'totalDiscounted', 'linkedPosDiscount', 'status'],
  headerColumns: {
    code: 'Promo Code Name',
    description: 'Description',
    locationName: 'Location',
    usageCount: '# Times Used',
    totalDiscounted: 'Total $ Discount',
    linkedPosDiscount: 'Linked POS Discount',
    status: 'Status',
  },
  pagination: {
    start: 0,
    length: 0,
    limit: 0,
    total: 0,
  },
  loading: {},
  errors: {},
};
