import { ILocationNewsletter } from '@ready/dashboardv2api.contracts';

export interface INewsletterPanel extends ILocationNewsletter {
  isDirty: boolean;
  validation: INewsletterPanelValidation;
}

export interface INewsletterPanelValidation {
  newsletter: {
    newsletterPromptText: {
      hasError: boolean;
      error: string;
    };
    newsletterSuccessText: {
      hasError: boolean;
      error: string;
    };
  };
}

export const getNewsletterPanelValidation = (): INewsletterPanelValidation => {
  return {
    newsletter: {
      newsletterPromptText: {
        hasError: false,
        error: '',
      },
      newsletterSuccessText: {
        hasError: false,
        error: '',
      },
    },
  };
};

export const newsletterPanel = {
  showNewsletterSubCheckbox: false,
  newsletterPromptText: '',
  newsletterSuccessText: '',
  isDirty: false,
  validation: getNewsletterPanelValidation(),
} as INewsletterPanel;
