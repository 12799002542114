import { ISmbTax } from '../types/SmbTaxTypes.type';
import config from '../../config';
import executeApiAsync from '../../services/utils/executeApiAsync';
import { ISmbLocation } from '../types/SmbLocationTypes.type';

export class TaxService {
  static BASE_URL = config.readyDashboardApiEndpoint;

  public static async getTaxesForCompany(companyId: string): Promise<ISmbTax[]> {
    const url = `${this.BASE_URL}/companies/${companyId}/taxes`;
    return executeApiAsync(url, {
      method: 'GET',
    });
  }

  public static async createTax(companyId: string, tax: Omit<ISmbTax, '_id'>): Promise<ISmbTax> {
    const url = `${this.BASE_URL}/companies/${companyId}/taxes`;
    return executeApiAsync(url, {
      method: 'POST',
      body: JSON.stringify(tax),
    });
  }

  public static async updateTax(companyId: string, tax: ISmbTax): Promise<ISmbTax> {
    const url = `${this.BASE_URL}/companies/${companyId}/taxes/${tax._id}`;
    return executeApiAsync(url, {
      method: 'PUT',
      body: JSON.stringify(tax),
    });
  }

  public static async deleteTax(companyId: string, tax: ISmbTax): Promise<void> {
    const url = `${this.BASE_URL}/companies/${companyId}/taxes/${tax._id}`;
    return executeApiAsync(url, {
      method: 'DELETE',
    });
  }

  public static async getSmbLocations(companyId: string): Promise<ISmbLocation[]> {
    const url = `${this.BASE_URL}/companies/${companyId}/smblocations`;
    return executeApiAsync(url, {
      method: 'GET',
    });
  }
}
