import React from 'react';
import IconButton from '../IconButton/IconButton';
import { format, subMonths, addMonths, isBefore, isAfter, minTime, maxTime, startOfMonth, endOfMonth } from 'date-fns';
import styles from './MonthScroller.module.scss';

export interface MonthScrollerProps {
  value: Date;
  onChange: (value: MonthScrollerOnChangeProps) => void;
  minValue?: Date;
  maxValue?: Date;
  showArrows?: boolean;
}

export interface MonthScrollerOnChangeProps {
  value: Date;
}

const scrollerFormat = 'MMM yyyy';

const getDateDisplayText = (value: Date) => format(value, scrollerFormat);

const MonthScroller = (props: MonthScrollerProps) => {
  const minValue = props.minValue || minTime;
  const maxValue = props.maxValue || maxTime;

  const changeMonth = (direction: 'previous' | 'next') => {
    const newValue = direction === 'previous' ? subMonths(props.value, 1) : addMonths(props.value, 1);

    // New value must be within the min/max range
    if (direction === 'previous' && isBefore(newValue, startOfMonth(minValue))) {
      return;
    }

    if (direction === 'next' && isAfter(newValue, endOfMonth(maxValue))) {
      return;
    }

    props.onChange({ value: newValue });
  };

  /* ****** Callbacks ****** */

  const scrollLeft = () => changeMonth('previous');

  const scrollRight = () => changeMonth('next');

  /* ****** Rendering ****** */

  return (
    <div className={styles.container}>
      {props.showArrows && (
        <IconButton additionalClassName={styles.button} onClick={scrollLeft}>
          <i className={`icon-dashboard-icons_angle-left ${styles.button}`} />
        </IconButton>
      )}
      <div className={styles.displayText}>{getDateDisplayText(props.value)}</div>
      {props.showArrows && (
        <IconButton additionalClassName={styles.button} onClick={scrollRight}>
          <i className='icon-dashboard-icons_angle-right' />
        </IconButton>
      )}
    </div>
  );
};

export default MonthScroller;
