import { IResponseState } from '../../initialStates/ui/responseState';
import { RESPONSE_STATE_ACTION_TYPES } from './responseStateActionTypes';

// When implementing more error codes, be sure to add them to the HttpErrorCode
// enum in src\services\ServiceError.ts as well.

export const toastErrorState = (statusCode?: number, message?: string) => async (dispatch: any) => {
  const errorCode = statusCode !== undefined ? `[Error ${statusCode.toString()}]` : '[Error]';
  const errorMessage = message !== '' ? `${errorCode} ${message}` : errorCode;
  const response = {
    responseStatus: statusCode || null,
    responseType: 'toast',
    message: errorMessage,
  } as IResponseState;
  dispatch(updateResponseState(response));
};

export const pageErrorState = (statusCode?: number, message?: string) => async (dispatch: any) => {
  const errorCode = statusCode !== undefined ? `[Error ${statusCode.toString()}]` : '[Error]';
  const errorMessage = message !== '' ? `${errorCode} ${message}` : errorCode;
  const response = {
    responseStatus: statusCode || null,
    responseType: 'page',
    message: errorMessage,
  } as IResponseState;
  dispatch(updateResponseState(response));
};

export const set200Toast = (message?: string) => async (dispatch: any) => {
  const response = {
    responseStatus: 200,
    responseType: 'toast',
    message,
  } as IResponseState;
  dispatch(updateResponseState(response));
};

export const setGenericErrorToast = (header: string, message: string, listItems: string[]) => async (dispatch: any) => {
  const response = {
    responseStatus: null,
    responseType: 'toast',
    header,
    message,
    listItems,
  } as IResponseState;
  dispatch(updateResponseState(response));
};

export const updateResponseState = (payload: IResponseState) => ({
  type: RESPONSE_STATE_ACTION_TYPES.UPDATE_RESPONSE_STATE,
  payload,
});

export const resetResponseState = () => ({
  type: RESPONSE_STATE_ACTION_TYPES.RESET_RESPONSE_STATE,
});
