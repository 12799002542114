import { debounce } from 'lodash';
import { ICachedPosMenuItem } from '@ready/menu.core';
import { Option } from '../../../../components/SelectFilter/SelectFilter';
import { OptionProps, components, SingleValueProps } from 'react-select';
import MenuBuilderService from '../../../../menus/services/MenuBuilderService';
import React, { useCallback, useEffect, useState } from 'react';
import SelectFilterAsync from '../../../../components/SelectFilter/SelectFilterAsync';
import styles from './PosItemSelect.module.scss';
import mapSelectOption from '../../../../utils/selectListUtils/mapSelectOption';

const PosItemOption = (props: OptionProps<Option, boolean>) => (
  <components.Option {...props}>
    {props.data.label}
    <div className={styles.posDetails}>{props.data.subLabel}</div>
  </components.Option>
);

const PosItemSingleValue = (props: SingleValueProps<Option>) => {
  const subLabel = props.data.subLabel ? props.data.subLabel.substring(0, props.data.subLabel.indexOf(' | ')) : '';
  return (
    <components.SingleValue {...props}>
      {props.data.label}
      {subLabel && (
        <>
          {' '}
          <span className={styles.posId}>{`(${subLabel})`}</span>
        </>
      )}
    </components.SingleValue>
  );
};

interface Props {
  companyId: string;
  locationId: string;
  onChange: (option: Option) => void;
  selectedPosItemId?: string;
  posItemFilter?: (item: ICachedPosMenuItem) => void;
}

const PosItemSelect = (props: Props) => {
  const { companyId, locationId, selectedPosItemId, onChange, posItemFilter } = props;
  const [posItems, setPosItems] = useState<Option[]>([]);
  const [loadingPosItems, setLoadingPosItems] = useState<boolean>(true);

  const selectedPosItem = selectedPosItemId ? mapSelectOption(posItems, selectedPosItemId) : null;

  const fetchPosItems = useCallback(
    async (companyId, locationId, query) => {
      const paginatedPosItems = await MenuBuilderService.getPosItems(companyId, locationId, query, 1, 50);
      const filteredItems = posItemFilter ? paginatedPosItems.items.filter(posItemFilter) : paginatedPosItems.items;

      setPosItems(
        filteredItems.map((posItem) => ({
          value: posItem.externalId,
          label: `${posItem.name}`,
          subLabel: `${posItem.externalId} | ${(posItem.categories ?? []).join(', ')}`,
        }))
      );
      setLoadingPosItems(false);
    },
    [posItemFilter]
  );

  const fetchInitialPosItems = useCallback(async (): Promise<void> => {
    await fetchPosItems(companyId, locationId, '');
  }, [companyId, fetchPosItems, locationId]);

  const fetchPosItemsWithFilter = debounce(async (filter: string): Promise<void> => {
    setLoadingPosItems(true);
    await fetchPosItems(companyId, locationId, filter);
    setLoadingPosItems(false);
  }, 300);

  useEffect(() => {
    fetchInitialPosItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SelectFilterAsync
      placeholder={'Search to select a POS Item'}
      options={posItems}
      value={selectedPosItem}
      loading={loadingPosItems}
      limit={50}
      overflowMessage={`Top 50 results shown. Type to refine your search.`}
      isClearable
      hideIndicator
      handleFetch={fetchPosItemsWithFilter}
      handleInitialFetch={fetchInitialPosItems}
      onChange={onChange}
      customComponents={{
        Option: PosItemOption,
        SingleValue: PosItemSingleValue,
      }}
    />
  );
};

export { PosItemSelect };
