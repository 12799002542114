import { IFeedbackReportItem, IPagination } from '@ready/dashboardv2api.contracts';
import { IPagedResponse } from '@ready/table.core';
import { ILocation } from '../../../services/types/ILocation.type';

export interface IGuestSatisfactionCount {
  text: string;
  positive: number;
  negative: number;
}
export interface IFeedbackReportGuestSatisfactionData {
  totalSatisfaction?: number;
  counts: Array<IGuestSatisfactionCount>;
}

export interface IFeedbackReportGuestSatisfactionClientData {
  totalSatisfaction?: number;
  counts: Array<
    IGuestSatisfactionCount & {
      positivePercent: number;
      negativePercent: number;
    }
  >;
}

export interface IFeedbackReportStarRatingData {
  average?: number;
  counts: {
    '1': number;
    '2': number;
    '3': number;
    '4': number;
    '5': number;
  };
}

export interface IFeedbackLoading {
  guestSatisfaction?: boolean;
  starRating?: boolean;
  feedbackList?: boolean;
  filters?: boolean;
  permittedLocations?: boolean;
  export?: boolean;
}

export interface IFeedbackErrors {
  guestSatisfaction?: string;
  starRating?: string;
  feedbackList?: string;
  filters?: string;
  permittedLocations?: string;
}

export interface IFeedbackReportState {
  rowKey: string;
  columns: Array<string>;
  headerColumns: Record<string, string>;
  items: Array<IFeedbackReportItem>;
  starRating: IFeedbackReportStarRatingData;
  guestSatisfaction: IFeedbackReportGuestSatisfactionClientData;
  pagination: IPagination;
  loading: IFeedbackLoading;
  errors: IFeedbackErrors;
  permittedLocations: IPagedResponse<ILocation>;
}

export const initialFeedbackState: IFeedbackReportState = {
  rowKey: '_id',
  items: [],
  permittedLocations: {
    totalCount: 0,
    results: [],
  },
  columns: ['locationName', 'serverName', 'tableName', 'rating', 'feedback', 'amountPaid', 'date'],
  headerColumns: {
    locationName: 'Location',
    serverName: 'Server',
    date: 'Date',
    feedback: 'Comments',
    amountPaid: 'Amount Paid (Tip %)',
    rating: 'Rating',
    tableName: 'Table',
  },
  starRating: {
    average: 0,
    counts: { '1': 0, '2': 0, '3': 0, '4': 0, '5': 0 },
  },
  guestSatisfaction: {
    totalSatisfaction: 0,
    counts: [
      { text: 'Wait Time' },
      { text: 'Atmosphere' },
      { text: 'Value' },
      { text: 'Mobile Payment' },
      { text: 'Service' },
      { text: 'Food' },
    ].map((item) => ({
      ...item,
      positive: 0,
      negative: 0,
      positivePercent: 0,
      negativePercent: 0,
    })),
  },
  pagination: {
    start: 0,
    length: 0,
    limit: 0,
    total: 0,
  },
  loading: {},
  errors: {},
};
