import { createAsyncThunk } from '@reduxjs/toolkit';
import { set200Toast, toastErrorState } from 'redux/actions/uiActions/responseStateActions';
import { PaginationResponse } from '@ready/dashboardv2api.contracts';
import { IBulkEditItemGallery, IBulkEditItemGalleryRequest } from '@ready/menu.core';
import { getLocationItemGalleries, saveLocationItemGalleries } from '../service';

interface IGetItemGalleryLocationSettingsArgs {
  companyId: string;
  itemGalleryId: string;
  page?: number;
  pageSize?: number;
  searchTerm?: string;
}
export const getLocationItemGalleriesThunk = createAsyncThunk<
  PaginationResponse<IBulkEditItemGallery>,
  IGetItemGalleryLocationSettingsArgs
>(
  'sharedMenu/locationSettings/itemGallery/fetch',
  async ({ companyId, itemGalleryId, page, pageSize, searchTerm }: IGetItemGalleryLocationSettingsArgs) => {
    return await getLocationItemGalleries(companyId, itemGalleryId, page, pageSize, searchTerm);
  }
);

interface ISaveItemGalleryLocationSettingsThunkArgs {
  companyId: string;
  menuId: string;
  request: Omit<IBulkEditItemGalleryRequest, 'templateItemGalleryId'>;
}
export const saveLocationItemGalleriesThunk = createAsyncThunk(
  'sharedMenu/locationSettings/itemGallery/save',
  async (args: ISaveItemGalleryLocationSettingsThunkArgs, thunkAPI) => {
    try {
      const result = await saveLocationItemGalleries(args.companyId, args.menuId, args.request);
      thunkAPI.dispatch(set200Toast('Success! Changes saved.'));
      return result;
    } catch (error) {
      thunkAPI.dispatch(toastErrorState(error));
    }
  }
);
