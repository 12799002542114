import { OrderLocationResponse } from '../../types/OrderLocationResponse.interface';
import { OrderLocation } from '../../types/OrderLocation.interface';

export const mapOrderLocationToViewModel = (locations: OrderLocationResponse[]) => {
  const mapped = [...locations] as OrderLocation[];
  mapped.forEach((location: OrderLocation, idx: number) => {
    location.id = locations[idx]._id;
    location.address = locations[idx].fullAddress;
  });
  return mapped;
};
