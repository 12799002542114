import React, { useCallback, useEffect } from 'react';
// components
import { FormControl } from '../../../components/Form';
import TextInput from '../../../components/TextInput/TextInput';
import { IPosLoyaltyPanel } from '../../redux/PanelLoyaltyState';
import { Option } from '../../../components/SelectFilter/SelectFilter';
import { loadDiscounts, PANEL_LOYALTY_ACTIONS } from '../../redux/PanelLoyaltyActions';
import { debounce } from 'lodash';
import { connect, ConnectedProps } from 'react-redux';
import { IUpdateLocationParam } from '../LocationsEditPage';
import SelectFilterAsync from '../../../components/SelectFilter/SelectFilterAsync';

type SpendGoPOSProps = ConnectedProps<typeof connector> & {
  isProcessing: boolean;
  updateLocationParam: IUpdateLocationParam;
  posLoyaltyPanelInitialState: IPosLoyaltyPanel;
  locationId: string;
  companyId: string;
};

const SpendGoPOS = (props: SpendGoPOSProps) => {
  const {
    isProcessing,
    updateLocationParam,
    locationId,
    companyId,
    posLoyaltyPanelInitialState,
    loadDiscounts,
  } = props;

  useEffect(() => {
    loadDiscounts(locationId, companyId, '');
  }, [companyId, loadDiscounts, locationId]);

  const handleFetch = useCallback(
    (query: string): void => {
      const loadPosDiscountsDebounced = debounce(loadDiscounts, 300);
      loadPosDiscountsDebounced(locationId, companyId, query);
    },
    [companyId, loadDiscounts, locationId]
  );

  const mapSelectOptions = (optionValue: any): Option | null => {
    if (posLoyaltyPanelInitialState.discounts && posLoyaltyPanelInitialState.discounts.length > 0) {
      return posLoyaltyPanelInitialState.discounts.find(
        (optionObj: Option) => optionObj.value === optionValue
      ) as Option;
    }
    return null;
  };

  return (
    <>
      <FormControl
        label='Store Code *'
        errorMessage={posLoyaltyPanelInitialState.validations.spendgo.storeCode.errorMessage}
        withError={posLoyaltyPanelInitialState.validations.spendgo.storeCode.hasError}
      >
        <TextInput
          placeholder='Enter the Spendgo store code'
          value={posLoyaltyPanelInitialState.spendgoOptions?.locationId}
          onChange={(event) => {
            updateLocationParam(event.target.value, PANEL_LOYALTY_ACTIONS.UPDATE_SPENDGO_STORE_CODE);
          }}
          withError={posLoyaltyPanelInitialState.validations.spendgo.storeCode.hasError}
          loading={isProcessing}
        />
      </FormControl>
      <FormControl
        label='Account ID *'
        errorMessage={posLoyaltyPanelInitialState.validations.spendgo.accountId.errorMessage}
        withError={posLoyaltyPanelInitialState.validations.spendgo.accountId.hasError}
      >
        <TextInput
          placeholder='Enter the Spendgo account ID'
          value={posLoyaltyPanelInitialState.spendgoOptions?.brandId}
          onChange={(event) => {
            updateLocationParam(event.target.value, PANEL_LOYALTY_ACTIONS.UPDATE_SPENDGO_ACCOUNT_ID);
          }}
          loading={isProcessing}
        />
      </FormControl>

      <FormControl
        label='Loyalty Discount *'
        errorMessage={posLoyaltyPanelInitialState.validations.spendgo.loyaltyDiscount.errorMessage}
        withError={posLoyaltyPanelInitialState.validations.spendgo.loyaltyDiscount.hasError}
      >
        <SelectFilterAsync
          options={posLoyaltyPanelInitialState.discounts}
          value={mapSelectOptions(posLoyaltyPanelInitialState.spendgoOptions?.loyaltyDiscount)}
          placeholder='Select discount from POS'
          onChange={(event) => {
            updateLocationParam(event?.value, PANEL_LOYALTY_ACTIONS.UPDATE_SPENDGO_LOYALTY_DISCOUNT);
          }}
          handleFetch={handleFetch}
          loading={posLoyaltyPanelInitialState.loadingDiscounts}
          limit={50}
        />
      </FormControl>

      <FormControl
        label='API Key *'
        errorMessage={posLoyaltyPanelInitialState.validations.spendgo.apiKey.errorMessage}
        withError={posLoyaltyPanelInitialState.validations.spendgo.apiKey.hasError}
      >
        <TextInput
          placeholder='Enter the Spendgo API Key'
          value={posLoyaltyPanelInitialState.spendgoOptions?.clientId}
          onChange={(event) => {
            updateLocationParam(event.target.value, PANEL_LOYALTY_ACTIONS.UPDATE_SPENDGO_API_KEY);
          }}
          loading={isProcessing}
        />
      </FormControl>
      <FormControl
        label='Account Details URL *'
        errorMessage={posLoyaltyPanelInitialState.validations.spendgo.accountDetailsUrl.errorMessage}
        withError={posLoyaltyPanelInitialState.validations.spendgo.accountDetailsUrl.hasError}
      >
        <TextInput
          placeholder='Enter the Spendgo login page URL'
          value={posLoyaltyPanelInitialState.spendgoOptions?.accountDetailsUrl}
          onChange={(event) => {
            updateLocationParam(event.target.value, PANEL_LOYALTY_ACTIONS.UPDATE_SPENDGO_ACCOUNT_DETAILS_URL);
          }}
          loading={isProcessing}
        />
      </FormControl>
      <FormControl
        label='Client Secret *'
        errorMessage={posLoyaltyPanelInitialState.validations.spendgo.clientSecret.errorMessage}
        withError={posLoyaltyPanelInitialState.validations.spendgo.clientSecret.hasError}
      >
        <TextInput
          placeholder='Enter the Spendgo shared client secret'
          value={posLoyaltyPanelInitialState.spendgoOptions?.clientSecret}
          onChange={(event) => {
            updateLocationParam(event.target.value, PANEL_LOYALTY_ACTIONS.UPDATE_SPENDGO_CLIENT_SECRET);
          }}
          loading={isProcessing}
        />
      </FormControl>
    </>
  );
};

const actionCreators = {
  loadDiscounts,
};

const connector = connect(null, actionCreators);

export default connector(SpendGoPOS);
