import { RootState } from '../../redux/store';
import { IItemGroupsState, IItemGroupViewEditPage } from './ItemGroupsState';

export const selectItemGroupsState = (state: RootState): IItemGroupsState => state.menuBuilder.itemGroups;

export const selectItemGroupsViewEditState = (state: RootState): IItemGroupViewEditPage =>
  state.menuBuilder.itemGroups.itemGroupViewEditPage;

export const selectItemGroupsListPageQuery = (state: RootState): string =>
  state.menuBuilder.itemGroups.itemGroupsListPage.query;

export const selectItemGroupsListPageNumber = (state: RootState): string =>
  state.menuBuilder.itemGroups.itemGroupsListPage.page;
