import produce from 'immer';
import { COMPANY_SETTINGS_ACTIONS } from './CompanySettingsActions';
import { CompanySettingsState, companySettingsState } from './CompanySettingsState';
import { Action } from '../../redux/types';

export const companySettingsReducer = (state: CompanySettingsState = companySettingsState, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case COMPANY_SETTINGS_ACTIONS.GET_COMPANY_INTEGRATIONS_BEGIN:
        draft.companyIntegrations.loading = true;
        return draft;

      case COMPANY_SETTINGS_ACTIONS.GET_COMPANY_INTEGRATIONS_SUCCESS:
        draft.companyIntegrations.loading = false;
        draft.companyIntegrations.integrations = action.payload;
        return draft;

      case COMPANY_SETTINGS_ACTIONS.GET_COMPANY_INTEGRATIONS_ERROR:
        draft.companyIntegrations.loading = false;
        return draft;

      case COMPANY_SETTINGS_ACTIONS.GET_TAXES_BEGIN:
        draft.smbTaxes.loading = true;
        return draft;

      case COMPANY_SETTINGS_ACTIONS.GET_TAXES_SUCCESS:
        draft.smbTaxes.loading = false;
        draft.smbTaxes.taxes = action.payload;
        return draft;

      case COMPANY_SETTINGS_ACTIONS.GET_TAXES_ERROR:
        draft.smbTaxes.loading = false;
        return draft;

      case COMPANY_SETTINGS_ACTIONS.CREATE_TAX_SUCCESS:
        if (action.payload) {
          draft.smbTaxes.taxes.push(action.payload);
        }
        return draft;

      case COMPANY_SETTINGS_ACTIONS.UPDATE_TAX_SUCCESS:
        if (action.payload) {
          const updatedTaxIndex = draft.smbTaxes.taxes.findIndex((t) => t._id === action.payload._id);
          draft.smbTaxes.taxes[updatedTaxIndex] = action.payload;
        }
        return draft;

      case COMPANY_SETTINGS_ACTIONS.SELECT_TAX_TO_MODIFY:
        draft.smbTaxes.selectedTax = action.payload;
        return draft;

      case COMPANY_SETTINGS_ACTIONS.DELETE_TAX_SUCCESS:
        if (action.payload) {
          draft.smbTaxes.taxes = draft.smbTaxes.taxes.filter((t) => t._id !== action.payload);
        }
        return draft;

      case COMPANY_SETTINGS_ACTIONS.SET_TAXES_LOADING:
        draft.smbTaxes.loading = action.payload;
        return draft;

      case COMPANY_SETTINGS_ACTIONS.GET_SMB_LOCATIONS_SUCCESS:
        draft.smbTaxes.smbConfiguredLocations = action.payload ?? [];
        return draft;

      default:
        return draft;
    }
  });
};
