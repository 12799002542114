import { IOrderRefundInfo } from '@ready/dashboardv2api.contracts';
export const getOrderHasRefund = (refundInfo: IOrderRefundInfo[] | undefined): boolean | undefined => {
  return refundInfo?.some((order: IOrderRefundInfo) => order?.refunded);
};

export const calculateRefundedAmount = (refundInfoList: IOrderRefundInfo[] | undefined): number | undefined => {
  if (!refundInfoList || refundInfoList.length === 0) {
    return;
  }

  const mappedRefundInfoList = refundInfoList.map((order: IOrderRefundInfo) => order.refundedAmount);
  const refundedAmount = mappedRefundInfoList.reduce((prev: number, curr: number) => prev + curr, 0);
  return refundedAmount;
};
