import React from 'react';
import Container from '../../components/Container/Container';

export interface TextFieldProps {
  value: string | number;
  additionalValue?: string;
  additionalClassName?: string;
}

const TextField = (props: TextFieldProps) => {
  const { value, additionalValue, additionalClassName } = props;

  return (
    <Container additionalClassName='text-field-container'>
      <>
        <p className={`text-field ${additionalClassName}`}>{value}</p>
        {additionalValue && <p className='text-field-additional'>{additionalValue}</p>}
      </>
    </Container>
  );
};

export default TextField;
