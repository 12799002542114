import { ISchedule } from '@ready/menu.core';
import { ScheduleValidationErrors, ScheduleSlotValidationError } from '../redux/ScheduleState';

const validateSchedule = (schedule: ISchedule): ScheduleValidationErrors => {
  const slotErrors: Array<ScheduleSlotValidationError> = [];
  const errors: ScheduleValidationErrors = { slotErrors };

  // Schedule must have a name
  if (!schedule.name) {
    errors.emptyNameError = true;
  }

  // Evaluate each slot for errors
  schedule.slots.forEach((slot, slotIndex) => {
    const slotError: ScheduleSlotValidationError = { slotIndex };

    // At least one day should be selected
    if (slot.daysOfWeek.length === 0) {
      slotError.emptyDaysError = true;
    }

    // Start hour and start minute should be populated
    if (!(slot.startHour === 0 || slot.startHour) || !(slot.startMinute === 0 || slot.startMinute)) {
      slotError.emptyStartTimeError = true;
    }

    // End hour and end minute should be populated
    if (!(slot.endHour === 0 || slot.endHour) || !(slot.endMinute === 0 || slot.endMinute)) {
      slotError.emptyEndTimeError = true;
    }

    const startTimeNumber = +`${slot.startHour}${slot.startMinute?.toString().padStart(2, '0')}`;
    const endTimeNumber = +`${slot.endHour}${slot.endMinute?.toString().padStart(2, '0')}`;

    // Time range should be in correct order (if both times are selected).
    if (startTimeNumber && endTimeNumber && startTimeNumber >= endTimeNumber) {
      slotError.timeRangeOrderError = true;
    }

    // If an error exists, add it to the list
    if (
      slotError.emptyDaysError ||
      slotError.emptyStartTimeError ||
      slotError.emptyEndTimeError ||
      slotError.timeRangeOrderError
    ) {
      slotErrors.push(slotError);
    }
  });

  return errors;
};

export default validateSchedule;
