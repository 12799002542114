import React from 'react';
import { ViewOrderSmsMessages } from '../viewOrderSMSMessages/ViewOrderSMSMessages';
import { EditOrderSmsMessages } from '../editOrderSMSMessages/EditOrderSMSMessages';
import { PanelLayout, Panel } from '../../../../components/PanelLayout';
import Button from '../../../../components/Button/Button';
import styles from './OrderSMSMessagesContent.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetOrderSmsMessagesConfig,
  toggleOrderSmsEditMode,
} from '../../../redux/reducers/orderSMSMessages/orderSMSMessagesSlice';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { orderSmsMessagesSelector } from '../../../redux/selectors/orderSmsMessagesSelector';
import { paymentProcessingSelector } from 'companyLocations/redux/selectors/PaymentProcessingSelector';
import { OrderSmsMessagesThunk } from 'companyLocations/redux/reducers/orderSMSMessages/orderSMSMessagesThunk';
import { useParams } from 'react-router-dom';
import { ContextParams } from 'types/ContextParams.interface';

export const OrderSmsMessagesContent = () => {
  const dispatch = useDispatch();
  const { isEditMode, loading } = useSelector(orderSmsMessagesSelector);
  const { location } = useSelector(paymentProcessingSelector);
  const { contextId: companyId, locationId } = useParams<ContextParams>();
  const userLocationSettings = location?.settings;
  const featureIsEnabled = userLocationSettings?.orderSmsEnabled === true;

  const toggleEditMode = () => {
    dispatch(toggleOrderSmsEditMode(true));
  };

  React.useEffect(() => {
    if (featureIsEnabled) {
      dispatch(
        OrderSmsMessagesThunk.fetchOrderSmsMessagesConfig({
          companyId,
          locationId,
        })
      );
    }
    return () => {
      dispatch(resetOrderSmsMessagesConfig());
    };
  }, [dispatch, companyId, locationId, featureIsEnabled]);

  return (
    <>
      {featureIsEnabled && (
        <>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div className={styles.panelContainer}>
              <PanelLayout>
                <Panel
                  title='SMS Notifications'
                  headerControls={
                    isEditMode ? undefined : (
                      <Button
                        variant='secondary'
                        label='Edit'
                        size='large'
                        loading={loading}
                        onClick={toggleEditMode}
                      />
                    )
                  }
                >
                  {isEditMode ? <EditOrderSmsMessages /> : <ViewOrderSmsMessages />}
                </Panel>
              </PanelLayout>
            </div>
          )}
        </>
      )}
    </>
  );
};
