import React, { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import MenuBuilderLocationPage from './components/MenuBuilderLocationPage';

const Router: FC<{}> = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={path} component={MenuBuilderLocationPage} />
    </Switch>
  );
};

export class Routes {
  public static getTemplate(): string {
    return '/menu-builder';
  }

  public static getLocationsPage(companyId: string): string {
    return `/companies/${companyId}/menu-builder`;
  }
}

export default Router;
