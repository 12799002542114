import React, { FC } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import LocationsListPage from './pages/LocationsListPage';
import TablesDetailsPage from './pages/TablesDetails/TablesDetailsPage';
import PaymentProcessingPage from './pages/paymentProcessing/PaymentProcessingPage';
import NewOnAccountPaymentOption from './pages/paymentProcessing/NewOnAccountPaymentOption';
import { OnAccountPaymentOptionDetails } from './pages/paymentProcessing/OnAccountPaymentOptionDetails';
import NewPromoCode from './pages/promoCodes/NewPromoCode';
import EditPromoCode from './pages/promoCodes/EditPromoCode';
import PromoCodesPage from './pages/promoCodes/PromoCodesPage';
import ViewPromoCode from './pages/promoCodes/ViewPromoCode';
import { DigitalMenuPage } from './pages/digitalMenu/DigitalMenuPage';
import { usePageTitle } from '../hooks';
import { OrderSettingsPage } from './pages/orderSettings/OrderSettingsPage';
import { ServerFlowPage } from './pages/serverFlow/ServerFlowPage';


const CompanyLocationsRoutes: FC<{}> = () => {
  const { path } = useRouteMatch();
  usePageTitle('Location Settings');

  return (
    <Switch>
      <Route path={`${path}/:locationId/tables/`} component={TablesDetailsPage} />
      <Route path={`${path}/:locationId/paymentProcessing/paymentOptions/new`} component={NewOnAccountPaymentOption} />
      <Route
        path={`${path}/:locationId/paymentProcessing/paymentOptions/:paymentOptionId`}
        component={OnAccountPaymentOptionDetails}
      />
      <Route path={`${path}/:locationId/paymentProcessing/`} component={PaymentProcessingPage} />
      <Route path={`${path}/:locationId/promo-codes/:promoCodeId/edit`} component={EditPromoCode} />
      <Route path={`${path}/:locationId/promo-codes/new`} component={NewPromoCode} />
      <Route path={`${path}/:locationId/promo-codes/:promoCodeId`} component={ViewPromoCode} />
      <Route path={`${path}/:locationId/promo-codes/`} component={PromoCodesPage} />
      <Route path={`${path}/:locationId/digital-menu/`} component={DigitalMenuPage} />
      <Route path={`${path}/:locationId/order-settings/`} component={OrderSettingsPage} />
      <Route path={`${path}/:locationId/server-flow/`} component={ServerFlowPage} />
      <Route path={`${path}`} component={LocationsListPage} />
    </Switch>
  );
};

export default CompanyLocationsRoutes;
