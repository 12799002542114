import React, { forwardRef } from 'react';
import Container from '../Container/Container';
import FormattedInput, { FormattedInputProps } from '../FormattedInput/FormattedInput';
import InputError from '../InputError/InputError';

export interface FormattedInputNestedLabelProps extends FormattedInputProps {
  label: string;
  errorMessage?: string;
}

const FormattedInputNestedLabel = forwardRef((props: FormattedInputNestedLabelProps, ref) => {
  const { label, placeholder, errorMessage = '', value, withError = false, loading = false } = props;

  const errorClassName = withError ? 'formatted-input-nested-label--state-error' : '';
  const loadingClassName = loading ? 'formatted-input-nested-label--state-loading' : '';
  const labelVisibilityClassName = value !== '' || placeholder ? 'formatted-input-nested-label__label--visible' : '';

  return (
    <Container additionalClassName='formatted-input-nested-label-container'>
      <label className={`formatted-input-nested-label ${errorClassName} ${loadingClassName}`}>
        <span className={`formatted-input-nested-label__label ${labelVisibilityClassName}`}>{label}</span>
        <FormattedInput {...props} placeholder={placeholder || label} />
      </label>
      <>{withError && <InputError message={errorMessage} />}</>
    </Container>
  );
});

export default FormattedInputNestedLabel;
