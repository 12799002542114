import React, { useCallback } from 'react';
import styles from './DayOfWeekSelector.module.scss';
import { DayOfWeek } from '@ready/menu.core';
import classNames from 'classnames';
import Button from '../Button/Button';

interface DayOfWeekSelectorProps {
  value: Array<DayOfWeek>;
  onChange: (daysOfWeek: Array<DayOfWeek>) => void;
  errorMessage?: string;
  additionalStyles?: string;
  disabled?: boolean;
  processingDay?: DayOfWeek;
}

const DayOfWeekSelector = ({
  value,
  onChange,
  errorMessage = '',
  additionalStyles,
  disabled = false,
  processingDay,
}: DayOfWeekSelectorProps): JSX.Element => {
  const handleMondayClick = useCallback(() => {
    onChange(toggleValue(value, DayOfWeek.monday));
  }, [onChange, value]);

  const handleTuesdayClick = useCallback(() => {
    onChange(toggleValue(value, DayOfWeek.tuesday));
  }, [onChange, value]);

  const handleWednesdayClick = useCallback(() => {
    onChange(toggleValue(value, DayOfWeek.wednesday));
  }, [onChange, value]);

  const handleThursdayClick = useCallback(() => {
    onChange(toggleValue(value, DayOfWeek.thursday));
  }, [onChange, value]);

  const handleFridayClick = useCallback(() => {
    onChange(toggleValue(value, DayOfWeek.friday));
  }, [onChange, value]);

  const handleSaturdayClick = useCallback(() => {
    onChange(toggleValue(value, DayOfWeek.saturday));
  }, [onChange, value]);

  const handleSundayClick = useCallback(() => {
    onChange(toggleValue(value, DayOfWeek.sunday));
  }, [onChange, value]);

  return (
    <div className={classNames(styles.container, additionalStyles)}>
      <div className={styles.buttonContainer}>
        <Button
          additionalStyles={styles.button}
          label='Mon'
          onClick={handleMondayClick}
          variant={value.includes(DayOfWeek.monday) ? 'toggle-colour-selected' : 'toggle-colour-unselected'}
          disabled={disabled}
          loading={processingDay === DayOfWeek.monday}
        />
        <Button
          additionalStyles={styles.button}
          label='Tue'
          onClick={handleTuesdayClick}
          variant={value.includes(DayOfWeek.tuesday) ? 'toggle-colour-selected' : 'toggle-colour-unselected'}
          disabled={disabled}
          loading={processingDay === DayOfWeek.tuesday}
        />
        <Button
          additionalStyles={styles.button}
          label='Wed'
          onClick={handleWednesdayClick}
          variant={value.includes(DayOfWeek.wednesday) ? 'toggle-colour-selected' : 'toggle-colour-unselected'}
          disabled={disabled}
          loading={processingDay === DayOfWeek.wednesday}
        />
        <Button
          additionalStyles={styles.button}
          label='Thu'
          onClick={handleThursdayClick}
          variant={value.includes(DayOfWeek.thursday) ? 'toggle-colour-selected' : 'toggle-colour-unselected'}
          disabled={disabled}
          loading={processingDay === DayOfWeek.thursday}
        />
        <Button
          additionalStyles={styles.button}
          label='Fri'
          onClick={handleFridayClick}
          variant={value.includes(DayOfWeek.friday) ? 'toggle-colour-selected' : 'toggle-colour-unselected'}
          disabled={disabled}
          loading={processingDay === DayOfWeek.friday}
        />
        <Button
          additionalStyles={styles.button}
          label='Sat'
          onClick={handleSaturdayClick}
          variant={value.includes(DayOfWeek.saturday) ? 'toggle-colour-selected' : 'toggle-colour-unselected'}
          disabled={disabled}
          loading={processingDay === DayOfWeek.saturday}
        />
        <Button
          additionalStyles={styles.button}
          label='Sun'
          onClick={handleSundayClick}
          variant={value.includes(DayOfWeek.sunday) ? 'toggle-colour-selected' : 'toggle-colour-unselected'}
          disabled={disabled}
          loading={processingDay === DayOfWeek.sunday}
        />
      </div>
      <span className={styles.errorMessage}>{errorMessage}</span>
    </div>
  );
};

const toggleValue = (value: Array<DayOfWeek>, toggleValue: DayOfWeek): Array<DayOfWeek> => {
  // Clone the original array and find the value, if it exists
  const newValue = [...value];
  const existingValueIndex = newValue.findIndex((_value) => _value === toggleValue);

  // Either remove or add the value, depending on whether or not it already
  // exists
  if (existingValueIndex > -1) {
    newValue.splice(existingValueIndex, 1);
  } else {
    newValue.push(toggleValue);
  }

  return newValue;
};

export default DayOfWeekSelector;
