import { ISchedule } from '@ready/menu.core';

export interface ScheduleSlotValidationError {
  slotIndex: number;
  emptyDaysError?: boolean;
  emptyStartTimeError?: boolean;
  emptyEndTimeError?: boolean;
  timeRangeOrderError?: boolean;
}

export interface ScheduleValidationErrors {
  slotErrors: Array<ScheduleSlotValidationError>;
  emptyNameError?: boolean;
}

export interface ScheduleState {
  schedule: ISchedule;
  scheduleCache: ISchedule;
  processing: boolean;
  loading: boolean;
  cancelled: boolean;
  errors: ScheduleValidationErrors;
  deleteModalOpen: boolean;
  deleteModalSchedule: ISchedule;
}

export const initialScheduleState: ScheduleState = {
  schedule: {
    _id: '',
    name: '',
    slots: [],
  },
  scheduleCache: {
    _id: '',
    name: '',
    slots: [],
  },
  processing: false,
  loading: false,
  cancelled: false,
  errors: {
    slotErrors: [],
  },
  deleteModalOpen: false,
  deleteModalSchedule: {
    _id: '',
    name: '',
    slots: [],
  },
};
