import { ICompanyIntegrations } from '@ready/dashboardv2api.contracts';
import React from 'react';
import { DetailLayout, DetailElement } from '../../components/DetailLayout';
import SquareButton from '../components/SquareButton';
import styles from './SquareIntegration.module.scss';
import StatusIndicator from '../../components/StatusIndicator/StatusIndicator';
import { Panel } from '../../components/PanelLayout';

export interface SquareIntegrationProps {
  companyId: string;
  integrations: ICompanyIntegrations;
  hasPermissionAll: boolean;
}

const SquareIntegration = (props: SquareIntegrationProps) => {
  const { companyId, integrations, hasPermissionAll } = props;

  const isSquareConnected = !!integrations?.square?.auth?.tokenInfo;
  return (
    <div className={styles.container}>
      <Panel>
        <DetailLayout title='Integrations'>
          <DetailElement label='Square POS'>
            <StatusIndicator active={isSquareConnected} activeLabel={'Connected'} inactiveLabel={'Not Connected'} />
            {hasPermissionAll && (
              <div className={styles.reauthenticateButtonWrapper}>
                <SquareButton companyId={companyId} isSquareConnected={isSquareConnected} />
              </div>
            )}
          </DetailElement>
        </DetailLayout>
      </Panel>
    </div>
  );
};

export default SquareIntegration;
