import React from 'react';
import styles from './MenuItemPriceBlock.module.scss';
import { DollarValue } from '../../../components/Value';
import ErrorValue from '../../../components/ErrorValue/ErrorValue';

export interface MenuItemPriceBlockProps {
  price: number | undefined;
  deleted: boolean;
  parentDisabled?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  className?: string;
}

const MenuItemPriceBlock = ({
  price,
  deleted,
  parentDisabled = false,
  disabled = false,
  hidden = false,
  className,
}: MenuItemPriceBlockProps): JSX.Element => {
  return (
    <span className={className}>
      {deleted ? (
        <ErrorValue text='Price Deleted' />
      ) : (
        price !== undefined && (
          <DollarValue
            value={price}
            variant={parentDisabled || disabled || hidden ? 'secondary' : 'default'}
            additionalStyles={disabled ? styles.disabled : undefined}
          />
        )
      )}
    </span>
  );
};

export default MenuItemPriceBlock;
