import React, { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DigitalMenuMode, IAsset } from '@ready/dashboardv2api.contracts';

import { FormActionButtons, FormControl } from '../../../components/Form';
import { Form } from '../../../components/Form';
import ImageUploader from '../../../components/ImageUploader/ImageUploader';
import SelectFilter, { Option } from '../../../components/SelectFilter/SelectFilter';
import TextInput from '../../../components/TextInput/TextInput';

import {
  toggleClientDigitalMenuEditForm,
  updateClientDigitalMenuMode,
  updateClientDigitalMenuImage,
  saveClientDigitalMenuForm,
  updateClientDigitalMenuIframeUrl,
} from '../../redux/actions/digitalMenuActions';
import { DIGITAL_MENU_OPTIONS } from '../../../redux/initialStates/digitalMenu/digitalMenuOptions';

import { ImageSize } from '../../../enums/ImageSize.enum';
import { ContextParams } from '../../../types/ContextParams.interface';
import { selectClientDigitalMenu } from '../../redux/selectors/LocationSelector';
import { setFormIsDirty } from 'redux/actions/uiActions/formStateActions';

export const DigitalMenuEditPanel = () => {
  const {
    edit: editMode,
    loading,
    processing,
    validations: { errorMessage, hasError },
  } = useSelector(selectClientDigitalMenu);
  const { menuMode, imageKey, iframeUrl } = editMode;
  const showImageUploader = menuMode === DigitalMenuMode.Image;
  const showTextInput = menuMode === DigitalMenuMode.Iframe;

  const loadingPage = loading || processing;

  const dispatch = useDispatch();
  const { locationId, contextId: companyId } = useParams<ContextParams>();

  const noneOption = DIGITAL_MENU_OPTIONS[0];
  const selectedOption = (options: Option[], optionValue: string | undefined): Option | undefined => {
    return options.find((option: Option) => option.value === optionValue) ?? noneOption;
  };

  const handleChangeMenuType = (option: Option) => {
    dispatch(updateClientDigitalMenuMode(option?.value.toString()));
    dispatch(setFormIsDirty(true));
  };

  const handleImageUpload = (asset: Partial<IAsset>) => {
    dispatch(updateClientDigitalMenuImage(asset));
  };

  const handleSubmission = () => {
    dispatch(saveClientDigitalMenuForm(companyId, locationId, editMode));
  };

  const handleCancelButton = () => {
    dispatch(toggleClientDigitalMenuEditForm(false));
  };

  const handleTextInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(updateClientDigitalMenuIframeUrl(e.target.value));
  };

  return (
    <Form hasGroups onSubmit={handleSubmission}>
      <FormControl label='Menu Type'>
        <SelectFilter
          placeholder='Select a Menu Type'
          options={DIGITAL_MENU_OPTIONS}
          onChange={handleChangeMenuType}
          value={selectedOption(DIGITAL_MENU_OPTIONS, menuMode)}
          loading={loadingPage}
        />
      </FormControl>

      {showTextInput && (
        <FormControl withError={hasError} errorMessage={errorMessage}>
          <TextInput
            withError={hasError}
            placeholder='Enter URL'
            onChange={handleTextInputChange}
            value={iframeUrl}
            loading={loadingPage}
          />
        </FormControl>
      )}

      {showImageUploader && (
        <FormControl withError={hasError} errorMessage={errorMessage}>
          <ImageUploader
            maxWidth={375}
            aspectRatio='1:3'
            onUpload={handleImageUpload}
            imageKey={imageKey}
            isProcessing={loadingPage}
            maxFileSizeKb={ImageSize.MaxSize}
            formattedGuideText={
              <>
                <strong>Max File Size: </strong>2 MB, <strong>Format: </strong>
                JPG, PNG, <strong>Min-Width: </strong>375px
                <br />
                <i>
                  When possible we recommend exporting PNGs at 2X(750px) or 3X(1125px) width for display on HD screens.{' '}
                </i>
                <br />
                <i>Use the largest option possible while ensuring the final file size does not exceed 2MB.</i>
              </>
            }
          />
        </FormControl>
      )}
      <FormActionButtons handleCancel={handleCancelButton} loading={loadingPage} />
    </Form>
  );
};
