import SearchInput from 'components/SearchInput/SearchInput';
import React from 'react';

interface ModalSearchInputProps {
  query: string;
  handleQueryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
}

export const ModalSearchInput = ({ handleQueryChange, query = '', placeholder = '' }: ModalSearchInputProps) => {
  return (
    <SearchInput value={query} placeholder={placeholder} fullWidth onChange={handleQueryChange} autoFocus={true} />
  );
};
