import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  exportLocationPerformanceList,
  getLocationPerformance,
  getPermittedLocations,
} from '../locationPerformanceServices';
import { interceptErrorWithToastMessage } from '../../../redux/helpers/interceptErrorWithToastMessage';
import { showToastMessageOnFulfill } from '../../../redux/helpers/showToastMessageOnFulfill';

export const fetchLocationPerformanceThunk = createAsyncThunk(
  'locationPerformance/fetch',
  interceptErrorWithToastMessage(getLocationPerformance)
);

export const exportLocationPerformanceThunk = createAsyncThunk(
  'locationPerformance/export',
  interceptErrorWithToastMessage(showToastMessageOnFulfill(exportLocationPerformanceList, 'Success! Export complete.'))
);

export const fetchPermittedLocationsThunk = createAsyncThunk(
  'locationPerformance/permittedLocations',
  interceptErrorWithToastMessage(getPermittedLocations)
);
