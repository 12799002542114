import React, { PropsWithChildren } from 'react';
import { Alignment } from '../../types/Alignment.type';

export type NotificationProps = PropsWithChildren<{
  type?: 'alert' | 'warning' | 'info';
  alignment?: Alignment;
  className?: string;
}>;

const Notification: React.FC<NotificationProps> = (props: NotificationProps) => {
  const { type = 'alert', alignment = 'horizontal', className, children } = props;

  const notificationTypeClassName = `notification--${type}`;

  return (
    <div className={`notification ${notificationTypeClassName} ${className}`}>
      <div className='notification__container'>
        <div className={`notification__text ${alignment === 'vertical' ? 'notification__text--vertical' : ''}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Notification;
