import classnames from 'classnames';
import React, { ReactNode } from 'react';
import styles from './PageControls.module.scss';

interface Props {
  children?: ReactNode;
  className?: string;
}

const PageControls = ({ children, className }: Props) => {
  return <div className={classnames(styles.controls, className)}>{children}</div>;
};

export { PageControls };
