import React from 'react';
import { Link } from 'react-router-dom';

export interface TabLabelProps {
  route: string;
  label: string;
  selected: boolean;
}

const TabLabel = (props: TabLabelProps) => {
  const { route, label, selected } = props;

  return (
    <div className={selected ? 'tabs__list__item tabs__list__item--selected' : 'tabs__list__item'}>
      {selected ? (
        <span>{label}</span>
      ) : (
        <Link to={route} replace={true}>
          {label}
        </Link>
      )}
    </div>
  );
};

export default TabLabel;
