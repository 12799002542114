import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './CopyMenuState';
import { extraReducers, reducers } from './CopyMenuReducers';

export const copyMenuSlice = createSlice({
  name: 'copyMenu',
  initialState,
  reducers,
  extraReducers,
});
