/*
    locationsList: state.table.locationsList,
    revenueCenters: state.table.revenueCenters,
*/

import { AppState } from '../../../redux/initialStates/AppState';
import { ITableConfigurationState } from '../initialStates/tablesDetails';

export const selectTableConfigurationListState = (state: AppState): ITableConfigurationState =>
  state.table.tableConfigurationList;
