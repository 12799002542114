import React, { RefObject } from 'react';

import { IItemGroupForm } from '../../redux/ItemGroupsState';

import styles from './ItemGroupFormModal.module.scss';

import { Modal, ModalBody, ModalFooter, ModalHeader } from '../../../components/Modal';
import { FormControl } from '../../../components/Form';
import TextInput from '../../../components/TextInput/TextInput';
import TextArea from '../../../components/TextArea/TextArea';

export interface ItemGroupFormModalProps {
  setShowModal: (visible: boolean) => void;
  onChangeDisplayName: (displayName: string) => void;
  onChangeDescription: (description: string) => void;
  onSave: () => void;
  itemGroupForm: IItemGroupForm;
}

const ItemGroupFormModal = (props: ItemGroupFormModalProps): JSX.Element | null => {
  const {
    setShowModal,
    onChangeDisplayName,
    onChangeDescription,
    onSave,
    itemGroupForm: {
      processing,
      itemGroup: { displayName, description },
      validation,
    },
  } = props;

  const inputRef: RefObject<HTMLInputElement> = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [
    inputRef,
    validation, // Include validation as a dependency so focus gets set on failed validation
  ]);

  return (
    <Modal loading={processing} setShowModal={setShowModal}>
      <ModalHeader headerLabel='New Group' setShowModal={setShowModal} />

      <ModalBody>
        <span className={styles.label}>Item Group Name *</span>
        <FormControl withError={validation.displayName.hasError} errorMessage={validation.displayName.errorMessage}>
          <TextInput
            value={displayName}
            loading={processing}
            withError={validation.displayName.hasError}
            inputRef={inputRef}
            onChange={(event) => onChangeDisplayName(event.target.value)}
          />
        </FormControl>

        <span className={styles.label}>Description</span>
        <span className={styles.subLabel}>(optional)</span>
        <FormControl>
          <TextArea
            value={description || ''}
            loading={processing}
            onChange={(event) => onChangeDescription(event.target.value)}
          />
        </FormControl>
      </ModalBody>

      <ModalFooter
        loading={processing}
        primaryLabel='Create Group'
        primaryActionHandler={onSave}
        secondaryLabel='Cancel'
        secondaryActionHandler={() => setShowModal(false)}
      />
    </Modal>
  );
};

export default ItemGroupFormModal;
