import { combineReducers } from 'redux';
import { locationsSlice } from './LocationsSlice';
import { menusReducer } from './MenusReducer';
import { copyMenuSlice } from './CopyMenuSlice';
import { itemsAndModsReducer } from './ItemsAndModsReducer';
import { itemGroupsReducer } from './ItemGroupsReducer';
import { modifierGroupsReducer } from './ModifierGroupsReducer';
import { scheduleListReducer } from './ScheduleListReducers';
import { scheduleReducer } from './ScheduleReducers';
import { tagsListReducer } from './TagsListReducer';
import { badgesListReducer } from './BadgesListReducer';
import { menuConfigSlice } from 'menus/menuConfigs/redux/slice';

export const menuBuilderReducer = combineReducers({
  locationPicker: locationsSlice.reducer,
  menuConfigs: menuConfigSlice.reducer,
  menus: menusReducer,
  copyMenu: copyMenuSlice.reducer,
  itemsAndMods: itemsAndModsReducer,
  itemGroups: itemGroupsReducer,
  modifierGroups: modifierGroupsReducer,
  scheduleList: scheduleListReducer,
  schedule: scheduleReducer,
  tagsList: tagsListReducer,
  badgesList: badgesListReducer,
});
