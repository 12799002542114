import React from 'react';
import classnames from 'classnames';
import mainStyles from '../TableV2.module.scss';
import styles from './TableRow.module.scss';
import { TableBodyProps } from '../Body/Body';

export interface RowProps extends Pick<TableBodyProps, 'columns' | 'cells' | 'cellProps'> {
  rowData: Record<string, any>;
  classes?: string;
  hiddenColumns: Record<string, Record<string, boolean>>;
}

export interface RowClickableProps extends RowProps {
  onClick: (rowData: Record<string, any>, e: React.MouseEvent<HTMLDivElement>) => void;
}

const getCellClasses = (hidden: Record<string, Record<string, boolean>>, column: string) =>
  classnames(column, {
    [mainStyles['hidden--mobile']]: hidden[column]?.mobile,
    [mainStyles['hidden--table']]: hidden[column]?.tablet,
    [mainStyles['hidden--desktop']]: hidden[column]?.desktop,
  });

export const TableRow = ({ rowData, columns, cells, classes, hiddenColumns, cellProps }: RowProps) => {
  return (
    <div className={classnames(classes, styles.tableV2__row)}>
      {columns.map((column) => {
        const TableCell = cells[column];
        if (!TableCell) return null;

        return (
          <TableCell
            key={`col-${column}`}
            rowData={rowData}
            columnName={column}
            classes={getCellClasses(hiddenColumns, column)}
            {...(cellProps && cellProps[column] ? cellProps[column] : {})}
          />
        );
      })}
    </div>
  );
};

export const TableRowClickable = ({
  rowData,
  columns,
  cells,
  classes,
  hiddenColumns,
  onClick,
  cellProps,
}: RowClickableProps) => {
  // use this instead of onClick to allow user select text
  let mouseDownSelection: string | undefined;
  const clickHandler = onClick
    ? {
        onMouseDown: () => {
          mouseDownSelection = window.getSelection()?.toString();
        },
        onMouseUp: (e: React.MouseEvent<HTMLDivElement>) => {
          // @ts-ignore
          if (e.target?.nodeName === 'A') {
            return;
          }
          const selection = window.getSelection()?.toString();
          // prevent click if user selected some text during clicking
          if (!selection || selection === mouseDownSelection) {
            onClick(rowData, e);
          }
        },
      }
    : {};

  return (
    <div className={classnames(classes, styles.tableV2__row, styles.tableV2__rowClickable)} {...clickHandler}>
      {columns.map((column) => {
        const TableCell = cells[column];
        if (!TableCell) return null;

        return (
          <TableCell
            key={`col-${column}`}
            rowData={rowData}
            columnName={column}
            classes={getCellClasses(hiddenColumns, column)}
            {...(cellProps && cellProps[column] ? cellProps[column] : {})}
          />
        );
      })}
    </div>
  );
};
