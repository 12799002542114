import React from 'react';
import { ModalProps } from '../../components/Modal/Modal';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '../../components/Modal';

import { IUIAdmin } from 'admins/redux/AdminsListState';

export interface AdminEditStatusModalProps extends Pick<ModalProps, 'setShowModal'> {
  admin: IUIAdmin;
  loading?: boolean;
  handlePrimary: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleCancel?: () => void;
}

const AdminEditStatusModal = (props: AdminEditStatusModalProps) => {
  const { setShowModal, admin, loading = false, handlePrimary, handleCancel = () => setShowModal(false) } = props;

  return (
    <Modal setShowModal={setShowModal} loading={loading}>
      <ModalHeader
        headerLabel={admin.enabled ? 'Deactivate this Ready Admin?' : 'Activate this Ready Admin?'}
        setShowModal={setShowModal}
      />
      <ModalBody>
        {admin.enabled ? (
          <p>
            Are you sure you want to deactivate the Ready Admin "
            <strong>{admin.firstName + ' ' + admin.lastName}</strong>"? Their access will be revoked. You can
            re-activate their account later if needed.
          </p>
        ) : (
          <p>
            Are you sure you want to activate the Ready Admin "<strong>{admin.firstName + ' ' + admin.lastName}</strong>
            "?
          </p>
        )}
      </ModalBody>
      <ModalFooter
        primaryLabel={admin.enabled ? 'Deactivate Ready Admin' : 'Activate Ready Admin'}
        primaryActionHandler={handlePrimary}
        secondaryLabel='Cancel'
        secondaryActionHandler={handleCancel}
        loading={loading}
      />
    </Modal>
  );
};

export default AdminEditStatusModal;
