import { CompanyLoyaltyProviderType } from '@ready/dashboardv2api.contracts';
import { PunchhSettingsProps } from './PunchhSettings';
import { IPunchhOptions } from '@ready/dashboardv2api.contracts/dist/src/contracts/api/companyLoyaltyConfig/punchh/IPunchhOptions';

export function mapToUi(options: IPunchhOptions | undefined): PunchhSettingsProps {
  return {
    firstNameRequired: options?.firstNameRequired,
    lastNameRequired: options?.lastNameRequired,
    serverUrl: options?.url,
    birthdayRequired: options?.birthdayRequired,
    clientId: options?.clientId,
    clientSecret: options?.clientSecret,
    phoneNumberRequired: options?.phoneRequired,
    postalCodeRequired: options?.zipCodeRequired,
    termsRequired: options?.termsAndConditionsRequired,
    termsUrl: options?.termsAndConditionsUrl,
  };
}

export function mapToApiContract(settings: PunchhSettingsProps): IPunchhOptions {
  return {
    firstNameRequired: settings.firstNameRequired ?? false,
    lastNameRequired: settings.lastNameRequired ?? false,
    url: settings.serverUrl ?? '',
    birthdayRequired: settings.birthdayRequired ?? false,
    clientId: settings.clientId ?? '',
    clientSecret: settings.clientSecret ?? '',
    phoneRequired: settings.phoneNumberRequired ?? false,
    zipCodeRequired: settings.postalCodeRequired ?? false,
    termsAndConditionsRequired: settings.termsRequired ?? false,
    termsAndConditionsUrl: settings.termsUrl ?? '',
    provider: CompanyLoyaltyProviderType.Punchh,
  };
}
