import React from 'react';
// components
import { FormGroup } from '../../../components/Form';
import LocationPanelMO from './MobileOrdering/LocationPanelMO';
import LocationPanelPayAtTable from './PayAtTable/LocationPanelPayAtTable';
import LocationPanelDM from './DigitalMenu/LocationPanelDM';
import { LocationPanelServerFlow } from './serverFlow/LocationPanelServerFlow';
// actions
import { LOCATION_SETTINGS_ACTIONS } from '../../redux/LocationSettingsActions';
// types
import { IFeaturesPanel } from '../../redux/PanelFeaturesState';
import { IUIDropDown } from '../../../redux/initialStates/ui/dropDownState';
import { IUpdateLocationParam } from '../LocationsEditPage';

export interface Props {
  isProcessing: boolean;
  updateLocationParam: IUpdateLocationParam;
  featuresPanel: IFeaturesPanel;
  dropDown: IUIDropDown;
  ignoreInStockFromPosItem: boolean;
}

const LocationPanelFeatures = (props: Props) => {
  const { isProcessing, updateLocationParam, featuresPanel, dropDown, ignoreInStockFromPosItem } = props;

  const handleMobilePanelToggle = (toggled: boolean) => {
    updateLocationParam(toggled, LOCATION_SETTINGS_ACTIONS.TOGGLE_MOBILE_ORDERING_PANEL);
  };

  const handlePayAtTablePanelToggle = (toggled: boolean) => {
    updateLocationParam(toggled, LOCATION_SETTINGS_ACTIONS.TOGGLE_PAY_AT_TABLE_PANEL);
  };

  const { mobileOrdering, orderAhead, payAtTable, validation } = featuresPanel;

  return (
    <>
      <FormGroup groupName='Ready Features'>
        <LocationPanelPayAtTable
          isProcessing={isProcessing}
          updateLocationParam={updateLocationParam}
          payAtTable={payAtTable}
          panelToggle={payAtTable.payAtTableEnabled}
          handlePanelToggle={handlePayAtTablePanelToggle}
        ></LocationPanelPayAtTable>
      </FormGroup>

      <FormGroup groupName=''>
        <LocationPanelDM
          isProcessing={false}
          digitalMenuPanel={featuresPanel.digitalMenu}
          updateLocationParam={updateLocationParam}
        ></LocationPanelDM>
      </FormGroup>

      <FormGroup groupName=''>
        <LocationPanelMO
          isProcessing={isProcessing}
          updateLocationParam={updateLocationParam}
          mobileOrdering={mobileOrdering}
          orderAhead={orderAhead}
          handlePanelToggle={handleMobilePanelToggle}
          panelToggle={mobileOrdering.orderPickupEnabled}
          dropDown={dropDown}
          validation={validation}
          ignoreInStockFromPosItem={ignoreInStockFromPosItem}
        ></LocationPanelMO>
      </FormGroup>

      <FormGroup groupName=''>
        <LocationPanelServerFlow
          isProcessing={isProcessing}
          updateLocationParam={updateLocationParam}
          serverFlowPanel={featuresPanel.serverFlow}
        />
      </FormGroup>
    </>
  );
};

export default LocationPanelFeatures;
