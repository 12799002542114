import React from 'react';
import { IPagination } from '@ready/dashboardv2api.contracts';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { selectTableState } from 'sharedMenuItems/redux/selectors';
import styles from './BulkEditToolbarContent.module.scss';
import LinkButton from 'components/LinkButton/LinkButton';
import Button from 'components/Button/Button';
import { selectAllMenuItems, toggleAllOnPage } from 'sharedMenuItems/redux/table/tableSlice';

interface BulkEditToolbarContentProps {
  setShowBulkEditModal?: (showModal: boolean) => void;
  setShowBulkUnassignModal?: (showModal: boolean) => void;
  pagination?: IPagination;
  showBulkUnassignButton?: boolean;
  disabled?: boolean;
}

export const BulkEditToolbarContent = ({
  pagination,
  showBulkUnassignButton = true,
  setShowBulkEditModal = () => {},
  setShowBulkUnassignModal = () => {},
  disabled = false,
}: BulkEditToolbarContentProps) => {
  const { checkCells, allMenuItemsSelected } = useAppSelector(selectTableState);
  const dispatch = useAppDispatch();
  const numOfCells = Object.keys(checkCells).length;
  const numOfCheckedCells = Object.values(checkCells).filter((value) => value).length;
  const moreThanOnePage = pagination && pagination?.total > 50;

  return (
    <div className={styles.bulkEditToolbar}>
      {moreThanOnePage && numOfCells === numOfCheckedCells && (
        <div className={styles.selectAllRow}>
          {allMenuItemsSelected ? (
            <>
              <>All Results selected. </>
              <LinkButton onClick={() => dispatch(toggleAllOnPage({ check: false }))} disabled={disabled}>
                (Clear Selection)
              </LinkButton>
            </>
          ) : (
            <>
              <>All {numOfCells} items on page selected. </>
              <LinkButton onClick={() => dispatch(selectAllMenuItems())} disabled={disabled}>
                Click to select all results.
              </LinkButton>
            </>
          )}
        </div>
      )}
      <div className={styles.subHeader}>
        {allMenuItemsSelected ? <>All Results </> : <>{numOfCheckedCells} selected </>}
        <LinkButton onClick={() => dispatch(toggleAllOnPage({ check: false }))} disabled={disabled}>(Deselect)</LinkButton>
        <span className={styles.divider}> | </span>
        <Button
          label='Edit Selected'
          variant='secondary'
          onClick={() => setShowBulkEditModal(true)}
          additionalStyles={styles.editSelectedButton}
          disabled={disabled}
        />
        {showBulkUnassignButton && (
          <Button
            label='Unassign Selected'
            variant='secondary'
            onClick={() => setShowBulkUnassignModal(true)}
            additionalStyles={styles.editSelectedButton}
            disabled={disabled}
          />
        )}
      </div>
    </div>
  );
};
