import React from 'react';
import { IMenuSection, Status } from '@ready/menu.core';
import styles from './SectionPanel.module.scss';
import { PanelCollapsible, PanelTitle } from '../../../components/PanelLayout';
import SectionControls from './SectionControls';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';
import InfoSchedule from '../shared/InfoSchedule';
import InfoCardList from '../../../components/InfoCard/InfoCardList';
import DisabledBadge from '../DisabledBadge';
import MenuItemAndItemGroupList from './MenuItemAndItemGroupList';
import { LocationSettingsButton } from 'sharedMenuItems/pages/menu/components/LocationSettingsButton';

export interface SectionPanelProps {
  companyId: string;
  locationId?: string;
  menuId: string;
  section: IMenuSection;
  isMobile: boolean;
  expanded: boolean;
  disabled: boolean;
  /** shared menu in company settings */
  isSharedMenu?: boolean;
  isSharedMenuAssignedToCurrentLocation?: boolean;
  onSectionStateChanged: (isExpanded: boolean) => void;
  onEditClick: () => void;
  onDeleteClick?: () => void;
  onChangeStatusClick?: (enabled: boolean) => void;
  onItemVisibilityClick?: (sectionId: string, itemId: string, visible: boolean) => void;
  onGroupItemVisibilityClick?: (sectionId: string, groupId: string, itemId: string, visible: boolean) => void;
  onGalleryItemVisibilityClick?: (itemId: string, visible: boolean) => void;
  onGalleryGroupItemVisibilityClick?: (groupId: string, itemId: string, visible: boolean) => void;
  getMenuItemLink: (menuItemId: string) => string;
  getItemGroupLink: (itemGroupId: string) => string;
  onLocationSettingsButtonClick?: () => void;
}

const SectionPanel = ({
  companyId,
  locationId = '',
  menuId,
  section,
  isMobile,
  expanded,
  disabled,
  isSharedMenu = false,
  isSharedMenuAssignedToCurrentLocation = false,
  onSectionStateChanged,
  onEditClick,
  onDeleteClick = () => {},
  onChangeStatusClick = () => {},
  onItemVisibilityClick = () => {},
  onGroupItemVisibilityClick = () => {},
  onGalleryItemVisibilityClick = () => {},
  onGalleryGroupItemVisibilityClick = () => {},
  getMenuItemLink,
  getItemGroupLink,
  onLocationSettingsButtonClick,
}: SectionPanelProps): JSX.Element => {
  const itemGalleryEnabled = section.itemGallery?.status === Status.enabled;
  const itemGalleryName = section.itemGallery?.displayName || '';
  const itemGalleryDescription = section.itemGallery?.description || '';
  const showItemGalleryHeader = !!itemGalleryName || !!itemGalleryDescription;
  const itemGalleryItems = section.itemGallery?.items || [];

  return (
    <PanelCollapsible
      titleComponent={
        <PanelTitle
          title={section.displayName}
          disabled={section.status === Status.disabled}
          additionalComponent={section.status === Status.disabled ? <DisabledBadge withMargin /> : undefined}
        />
      }
      subTitleComponent={
        <>
          {!!section.description && (
            <div className={!!section.schedule ? styles.descriptionWithSchedule : styles.description}>
              {section.description}
            </div>
          )}
          {!!section.schedule && <InfoSchedule variant='primary' scheduleLink={section.schedule} magenta />}

          {onLocationSettingsButtonClick ? (
            <div className={styles.locationSettingsButtonContainer}>
              <LocationSettingsButton onClick={onLocationSettingsButtonClick} />
            </div>
          ) : null}
        </>
      }
      headerControls={
        <SectionControls
          editInOverflow={isMobile}
          defaultAppearance={section.defaultAppearance}
          disabled={disabled}
          changeStatusLabel={section.status === Status.enabled ? 'Disable Section' : 'Enable Section'}
          onEditClick={onEditClick}
          onDeleteClick={onDeleteClick}
          onChangeStatusClick={() => onChangeStatusClick(section.status !== Status.enabled)}
          isSharedMenu={isSharedMenu}
          isSharedMenuAssignedToCurrentLocation={isSharedMenuAssignedToCurrentLocation}
        />
      }
      initiallyExpanded={expanded}
      onPanelStateChanged={onSectionStateChanged}
      fullContentArea
    >
      {(showItemGalleryHeader || itemGalleryItems.length > 0) && (
        <>
          <div className={itemGalleryEnabled ? styles.itemGalleryBand : styles.itemGalleryBandDisabled}>
            <TextIcon icon={Icon.Star} additionalStyles={styles.itemGalleryIcon} />
            ITEM GALLERY
            {!itemGalleryEnabled && <DisabledBadge withMargin />}
          </div>

          {showItemGalleryHeader && (
            <div className={styles.itemGallery}>
              {!!itemGalleryName && (
                <div className={itemGalleryEnabled ? styles.itemGalleryName : styles.itemGalleryNameDisabled}>
                  {itemGalleryName}
                </div>
              )}
              {!!itemGalleryDescription && (
                <div
                  className={itemGalleryEnabled ? styles.itemGalleryDescription : styles.itemGalleryDescriptionDisabled}
                >
                  {itemGalleryDescription}
                </div>
              )}
            </div>
          )}

          {!!itemGalleryItems.length && (
            <div className={styles.itemGalleryItems}>
              <MenuItemAndItemGroupList
                disabled={disabled}
                parentDisabled={section.status === Status.disabled || !itemGalleryEnabled}
                items={itemGalleryItems}
                getMenuItemLink={(menuItemId: string) => getMenuItemLink(menuItemId)}
                getItemGroupLink={(itemGroupId: string) => getItemGroupLink(itemGroupId)}
                onItemVisibilityClick={(itemId: string, visible: boolean) =>
                  onGalleryItemVisibilityClick(itemId, visible)
                }
                onGroupItemVisibilityClick={(groupId: string, itemId: string, visible: boolean) =>
                  onGalleryGroupItemVisibilityClick(groupId, itemId, visible)
                }
                isSharedMenu={isSharedMenu}
              />
            </div>
          )}

          <div className={styles.itemListBand}>ITEM LIST</div>
        </>
      )}
      {section.items.length ? (
        <InfoCardList additionalClassName={styles.items}>
          <MenuItemAndItemGroupList
            disabled={disabled}
            isSharedMenu={isSharedMenu}
            parentDisabled={section.status === Status.disabled}
            items={section.items}
            getMenuItemLink={(menuItemId: string) => getMenuItemLink(menuItemId)}
            getItemGroupLink={(itemGroupId: string) => getItemGroupLink(itemGroupId)}
            onItemVisibilityClick={(itemId: string, visible: boolean) =>
              onItemVisibilityClick(section.uid, itemId, visible)
            }
            onGroupItemVisibilityClick={(groupId: string, itemId: string, visible: boolean) =>
              onGroupItemVisibilityClick(section.uid, groupId, itemId, visible)
            }
          />
        </InfoCardList>
      ) : (
        <div className={styles.noItems}>
          There are no items in this section. Edit this section to start adding items.
        </div>
      )}
    </PanelCollapsible>
  );
};

export default SectionPanel;
