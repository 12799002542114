import React from 'react';
import readyProgressSpinner from '../../styles/assets/ready-progress-spinner-gray.svg';
import styles from './LoadingSpinnerInline.module.scss';

const LoadingSpinnerInline = () => {
  return (
    <div className={styles.loadingSpinnerInline}>
      <img src={readyProgressSpinner} alt='loading' className={styles.loadingSpinnerElement} />
    </div>
  );
};

export default LoadingSpinnerInline;
