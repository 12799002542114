import produce from 'immer';
import { Action } from '../../redux/types';
import { LOCATION_SETTINGS_ACTIONS } from './LocationSettingsActions';
import { lastEdit } from './LastEditState';

export const lastEditReducer = (state = lastEdit, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case LOCATION_SETTINGS_ACTIONS.LOAD_LAST_EDIT:
        draft = { ...action.payload };
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_LAST_EDIT:
        draft.lastSaveBy = action.payload.lastSaveBy;
        draft.lastSaveAt = action.payload.lastSaveAt;
        return draft;
      default:
        return draft;
    }
  });
};
