// 200 - success
// 400 - bad request
// 401 - unauthorized
// 404 - not found
// 500 - server error
export type IResponseStatus = 200 | 400 | 401 | 404 | 500 | null;

export type IResponseType = 'toast' | 'page' | null;

export interface IResponseState {
  responseStatus: IResponseStatus;
  responseType: IResponseType;
  header?: string;
  message?: string;
  listItems?: string[];
}

export const responseState = {
  responseStatus: null,
  responseType: null,
  message: '',
} as IResponseState;
