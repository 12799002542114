import React from 'react';
import { connect } from 'react-redux';
import styles from './app-layout.module.scss';
import readyIcon from '../../styles/assets/ready-colour-icon.png';
import { IUserSession, IContextSession } from '../../redux/initialStates/session/session';
import IconButton from '../IconButton/IconButton';
import { updateModalVisible } from '../../redux/actions/contextSwitcherActions/contextSwitcherActions';
import { Action } from '../../redux/types';
import { useLocation } from 'react-router-dom';

export interface SidebarUserProps {
  userSession: IUserSession;
  contextSession: IContextSession;
  enableContextSwitcher: boolean;
  updateModalVisible: (modal: boolean) => Action | void;
  toggleSidebar: (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => void;
}

const SidebarUser = (props: SidebarUserProps) => {
  const { userSession, contextSession, enableContextSwitcher, toggleSidebar, updateModalVisible } = props;
  const { pathname } = useLocation();

  return (
    <div className={styles.sidebarUser}>
      <div className={styles.readyIcon}>
        <img className={styles.readyIconImg} src={readyIcon} alt='Ready' />
      </div>
      <div className={styles.sidebarUserInfo}>
        <p className={styles.sidebarUserInfoName}>{userSession.userName}</p>

        {pathname === '/companySelection' ? (
          <p className={styles.showCompanySelectionPageParagraph}>No Company Selected</p>
        ) : enableContextSwitcher ? (
          <button onClick={() => updateModalVisible(true)} className={styles.sidebarContextSwitcherButton}>
            <p className={styles.sidebarContextSwitcherParagraph}>
              <span className={styles.sidebarContextSwitcherContextLabel}>
                {contextSession.name || 'Switch Context'}
              </span>
              <i className='icon-dashboard-icons_arrow-right'></i>
            </p>
          </button>
        ) : (
          <p className={styles.sidebarContextSwitcherNoButton}>{contextSession.name}</p>
        )}
      </div>
      <IconButton onClick={toggleSidebar} id='sidebar-toggler' additionalClassName={styles.toogleSidebarButton}>
        <i className='icon-dashboard-icons_close'></i>
      </IconButton>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  userSession: state.session.userSession,
  contextSession: state.session.contextSession,
  enableContextSwitcher: state.session.enableContextSwitcher,
});

export default connect(mapStateToProps, {
  updateModalVisible,
})(SidebarUser);
