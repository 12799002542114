import { PaginationResponse } from '@ready/dashboardv2api.contracts';
import { ITemplateMenuItemGroup, WithAssignedLocationsCount } from '@ready/menu.core';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSharedItemGroupList } from '../../itemGroupService';

interface IGetTemplateItemGroupThunkArgs {
  companyId: string;
  query: string;
  page?: number;
  locationId?: string;
}

const getSharedItemGroupsThunk = createAsyncThunk<
  PaginationResponse<WithAssignedLocationsCount<ITemplateMenuItemGroup>>,
  IGetTemplateItemGroupThunkArgs
>('sharedMenuItems/itemGroups/list/fetch', async (args: IGetTemplateItemGroupThunkArgs, thunkAPI) => {
  return getSharedItemGroupList(args.companyId, args.query, args.locationId, args.page);
});

export { getSharedItemGroupsThunk };
