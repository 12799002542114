import React from 'react';
import classNames from 'classnames';
import styles from './StandardLoadingSpinner.module.scss';
import magentaSpinner from '../../styles/assets/ready-progress-spinner.svg';
import whiteSpinner from '../../styles/assets/ready-progress-spinner-white.svg';
import graySpinner from '../../styles/assets/ready-progress-spinner-gray-fullsize.svg';

export interface StandardLoadingSpinnerProps {
  loading: boolean;
  color?: 'magenta' | 'white' | 'gray';
  size?: 'extraLarge' | 'large' | 'medium' | 'small';
  additionalStyles?: string;
}

const StandardLoadingSpinner = ({
  loading,
  color = 'magenta',
  size = 'extraLarge',
  additionalStyles,
}: StandardLoadingSpinnerProps): JSX.Element | null => {
  if (!loading) {
    return null;
  }

  let spinnerSource: string;

  // Set the colour
  switch (color) {
    case 'magenta':
      spinnerSource = magentaSpinner;
      break;

    case 'white':
      spinnerSource = whiteSpinner;
      break;

    case 'gray':
    default:
      spinnerSource = graySpinner;
      break;
  }

  // Set the size
  const sizeClass = styles[size];

  const spinnerClassName = classNames(sizeClass, additionalStyles);

  return <img src={spinnerSource} alt='loading' className={spinnerClassName} />;
};

export default StandardLoadingSpinner;
