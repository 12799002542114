import { PaymentDataType, TokenizationMethodType } from '@ready/dashboardv2api.contracts';

export const paymentTypeOptions: { value: keyof typeof PaymentDataType; label: string }[] = [
  { value: 'Payment', label: 'Payment' },
  { value: 'Refund', label: 'Refund' },
  { value: 'Fee', label: 'Fee' },
  { value: 'BankTransfer', label: 'Bank Transfer' },
];

export const paymentMethodOptions: { value: keyof typeof TokenizationMethodType; label: string }[] = [
  { value: 'GooglePay', label: 'Google Pay' },
  { value: 'ApplePay', label: 'Apple Pay' },
  { value: 'CreditCard', label: 'Card Entry' },
];

type CardBrandOption = 'Visa' | 'Amex' | 'MC' | 'Discover' | 'JCB' | 'Interac';

export const cardBrandOptions: { value: CardBrandOption; label: string }[] = [
  { value: 'Visa', label: 'Visa' },
  { value: 'Amex', label: 'American Express' },
  { value: 'MC', label: 'Mastercard' },
  { value: 'Discover', label: 'Discover' },
  { value: 'JCB', label: 'JCB' },
  { value: 'Interac', label: 'Interac' },
];
