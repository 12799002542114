import React from 'react';
import { Status, Visibility } from '@ready/menu.core';
import { Form } from 'components/Form';
import EditPanelFooter from './EditPanelFooter';
import MenuItemAndItemGroupList from './MenuItemAndItemGroupList';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { useParams } from 'react-router-dom';
import { ContextParams } from 'types/ContextParams.interface';
import { Routes } from 'menus/MenuBuilderRouter';
import {
  menuGalleryUpdateStatus,
  stopEditingInnerItemGallery,
  updateItemGalleryItemGroupItemVisibility,
  updateItemGalleryItemVisibility,
} from 'menus/redux/MenusActions';
import styles from './SharedMenuSectionItemGallery.module.scss';
import { PanelTitle } from 'components/PanelLayout';
import TextIcon, { Icon } from 'components/Icon/TextIcon';
import { isSharedMenu } from 'sharedMenuItems/sharedMenuItems.utils';

export const SharedMenuSectionItemGallery = ({ onSave }: { onSave: () => void }) => {
  const {
    menuGalleryForm: {
      processing: itemGalleryProcessing,
      menu: { itemGallery },
    },
    menuForm: {
      menu: { _id: menuId },
    },
  } = useAppSelector((state) => state.menuBuilder.menus);
  const { contextId: companyId, locationId } = useParams<ContextParams>();
  const dispatch = useAppDispatch();

  return (
    <Form hasGroups onSubmit={onSave}>
      <div className={styles.details}>
        <div className={styles.content}>
          <div className={styles.name}>
            {itemGallery?.displayName ? (
              <PanelTitle title={itemGallery?.displayName ?? ''} disabled={itemGallery?.status === Status.disabled} />
            ) : (
              <div className={styles.itemGalleryDescription}>
                <TextIcon icon={Icon.Star} additionalStyles={styles.itemGalleryStarIcon} />
                <span
                  className={
                    !isSharedMenu(companyId, locationId) && itemGallery?.status === Status.disabled
                      ? styles.itemGalleryDescriptionNameDisabled
                      : styles.itemGalleryDescriptionName
                  }
                >
                  Item Gallery
                </span>
              </div>
            )}
          </div>
          <div className={styles.description}>{itemGallery?.description}</div>
        </div>
      </div>

      <div>
        {!!itemGallery && !!itemGallery.items.length && (
          <div>
            <MenuItemAndItemGroupList
              disabled={false}
              parentDisabled={false}
              items={itemGallery.items}
              getMenuItemLink={(menuItemId: string) =>
                Routes.getMenuSectionItemPage(companyId, locationId, menuId, menuItemId)
              }
              getItemGroupLink={(itemGroupId: string) =>
                Routes.getMenuSectionItemGroupPage(companyId, locationId, menuId, itemGroupId)
              }
              onItemVisibilityClick={(itemId: string, visible: boolean) => {
                dispatch(updateItemGalleryItemVisibility(itemId, visible ? Visibility.visible : Visibility.hidden));
              }}
              onGroupItemVisibilityClick={(groupId: string, itemId: string, visible: boolean) => {
                dispatch(
                  updateItemGalleryItemGroupItemVisibility(
                    groupId,
                    itemId,
                    visible ? Visibility.visible : Visibility.hidden
                  )
                );
              }}
            />
          </div>
        )}
      </div>

      <EditPanelFooter
        onCancel={() => dispatch(stopEditingInnerItemGallery())}
        onChangeStatus={(enabled) => dispatch(menuGalleryUpdateStatus(enabled ? Status.enabled : Status.disabled))}
        saveLabel='Save Changes'
        status={itemGallery!.status}
        statusLabel='Status:'
        processing={itemGalleryProcessing}
      />
    </Form>
  );
};
