const SIDEBAR_FEATURES = [] as const;

const APP_FEATURES = [
  'app_info',
  'app_companyBillingCheck',
  'app_chooseReportDataSource',
  'app_promoCodeUsageReport',
  'app_checksReport',
  'app_payPalPanel',
  'app_checkPage',
  'app_checksReportMetricsCards',
  'app_locationPerformanceReport',
  'app_companyLoyalty',
  'app_locationPunchhLoyalty',
  'app_readyPaySelfOnboarding',
] as const;

export type AppFeature = typeof APP_FEATURES[number]; // provides union type of APP_FEATURES
export type SidebarFeature = typeof SIDEBAR_FEATURES[number]; // provides union type of SIDEBAR_FEATURES
export type Feature = AppFeature | SidebarFeature;

export const features: string[] = [...SIDEBAR_FEATURES, ...APP_FEATURES];
