import { ITemplateModifierGroup } from '@ready/menu.core';
import { DropDownMenuOptionProps } from 'components/DropDownMenu/types';
import InfoCard from 'components/InfoCard/InfoCard';
import InfoCardColumn from 'components/InfoCard/InfoCardColumn';
import OverflowButton from 'components/OverflowButton/OverflowButton';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { SharedMenuItemsRoutes } from 'sharedMenuItems/Router';
import { ContextParams } from 'types/ContextParams.interface';
import { compositeStyles } from 'utils/cssUtils';
import { DeleteSharedModifierGroupModal } from '../../components/DeleteSharedModifierGroupModal';
import styles from './ModifierGroupInfoCard.module.scss';

interface Props {
  modifierGroup: ITemplateModifierGroup;
  onDelete: () => void;
}

const ModifierGroupInfoCard = ({ modifierGroup, onDelete }: Props) => {
  const { contextId: companyId } = useParams<ContextParams>();
  const {
    displayName,
    name,
    options,
    guestInstructions,
    constraints: { min, max },
  } = modifierGroup;

  const overflowOptions: DropDownMenuOptionProps[] = [
    {
      label: 'Delete',
      primary: true,
      onClick: () => {
        setShowDeleteModal(true);
      },
    },
  ];

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  return (
    <>
      <InfoCard>
        <Link
          className={styles.link}
          to={SharedMenuItemsRoutes.getModifierGroupDetailsRoute(companyId, modifierGroup._id)}
        >
          <InfoCardColumn width={100} columnClassName={styles.columns}>
            <div className={styles.column}>
              <div className={styles.name}>{name}</div>
            </div>

            <div className={styles.column}>
              <div className={displayName ? styles.displayNameStyle : styles.noDisplayName}>
                {displayName || (
                  <>
                    <span className={styles.noDisplayNameMessage}>No Display Name</span>
                    <span className={styles.noDisplayNameIndicator}>&mdash;</span>
                  </>
                )}
              </div>

              <div
                className={compositeStyles(
                  [styles.guestInstructionsStyle, { noGuestInstructions: !guestInstructions }],
                  styles
                )}
              >
                {guestInstructions || 'No Instructions'}
              </div>
            </div>

            <div className={styles.column}>
              <div className={styles.options}>{`${options.length} options`}</div>

              <div className={styles.constraints}>{`MIN: ${min || 'None'} | MAX: ${max || 'Unlimited'}`}</div>
            </div>
          </InfoCardColumn>
        </Link>
        <InfoCardColumn columnClassName={styles.actions}>
          <OverflowButton options={overflowOptions} />
        </InfoCardColumn>
      </InfoCard>

      {showDeleteModal && (
        <DeleteSharedModifierGroupModal
          companyId={companyId}
          modifierGroup={modifierGroup}
          setShowModal={(show: boolean) => setShowDeleteModal(show)}
          onDelete={() => {
            setShowDeleteModal(false);
            onDelete();
          }}
        />
      )}
    </>
  );
};

export { ModifierGroupInfoCard };
