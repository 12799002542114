import produce from 'immer';
import { Action } from '../../redux/types';
import {
  itemsAndModsState,
  newMenuItem,
  newMenuItemValidation,
  newModifier,
  newSmbMenuItem,
  newSmbModifier,
} from './ItemsAndModsState';
import { ITEMS_AND_MODS_ACTIONS } from './ItemsAndModsActions';
import { IForcedModifier, IPosItemPriceLevel, MenuItemInactiveStates, Status } from '@ready/menu.core';
import InfiniteScrollingListStateHelper from '../../utils/InfiniteScrollingList/InfiniteScrollingListStateHelper';

export const itemsAndModsReducer = (state = itemsAndModsState, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ITEMS_AND_MODS_ACTIONS.LOAD_MENU_BUILDER_MENU_ITEMS_BEGIN:
        draft.loading = true;
        draft.pagination.start = 0;
        draft.pagination.length = 0;
        draft.pagination.limit = 0;
        draft.pagination.total = 0;
        draft.items = [];
        return draft;

      case ITEMS_AND_MODS_ACTIONS.LOAD_MENU_BUILDER_MENU_ITEMS_SUCCESS:
        draft.loading = false;
        draft.pagination.start = action.payload.start;
        draft.pagination.length = action.payload.length;
        draft.pagination.limit = action.payload.limit;
        draft.pagination.total = action.payload.total;
        draft.items = action.payload.items;
        return draft;

      case ITEMS_AND_MODS_ACTIONS.LOAD_MENU_BUILDER_MENU_ITEMS_ERROR:
        draft.loading = false;
        return draft;

      case ITEMS_AND_MODS_ACTIONS.STORE_MENU_BUILDER_MENU_ITEMS_FILTERS:
        draft.view = action.payload.view;
        draft.stockStatus = action.payload.stockStatus;
        draft.query = action.payload.query;
        draft.page = action.payload.page;
        return draft;

      case ITEMS_AND_MODS_ACTIONS.CREATE_MENU_BUILDER_NEW_MENU_ITEM:
        draft.menuItemForm.cancelled = false;
        draft.menuItemForm.menuItem = newMenuItem();
        draft.menuItemForm.taxes = undefined;
        draft.menuItemForm.validation = newMenuItemValidation();
        return draft;

      case ITEMS_AND_MODS_ACTIONS.CREATE_MENU_BUILDER_NEW_SMB_MENU_ITEM:
        const newSmbItem = newSmbMenuItem();
        draft.menuItemForm.cancelled = false;
        draft.menuItemForm.menuItem = newSmbItem;
        draft.menuItemForm.taxes = newSmbItem.taxes;
        draft.menuItemForm.validation = newMenuItemValidation();
        return draft;

      case ITEMS_AND_MODS_ACTIONS.CREATE_MENU_BUILDER_NEW_MODIFIER:
        draft.menuItemForm.cancelled = false;
        draft.menuItemForm.menuItem = newModifier();
        draft.menuItemForm.taxes = undefined;
        draft.menuItemForm.validation = newMenuItemValidation();
        return draft;

      case ITEMS_AND_MODS_ACTIONS.CREATE_MENU_BUILDER_NEW_SMB_MODIFIER:
        draft.menuItemForm.cancelled = false;
        draft.menuItemForm.menuItem = newSmbModifier();
        draft.menuItemForm.taxes = undefined;
        draft.menuItemForm.validation = newMenuItemValidation();
        return draft;

      case ITEMS_AND_MODS_ACTIONS.CANCEL_CREATE_MENU_BUILDER_NEW_ITEM:
        draft.menuItemForm.cancelled = true;
        return draft;

      case ITEMS_AND_MODS_ACTIONS.LOAD_MENU_BUILDER_MENU_ITEM_BEGIN:
        draft.menuItemForm.loading = true;
        draft.menuItemForm.menuItem = newMenuItem();
        return draft;

      case ITEMS_AND_MODS_ACTIONS.LOAD_MENU_BUILDER_MENU_ITEM_SUCCESS:
        draft.menuItemForm.loading = false;
        draft.menuItemForm.newId = '';
        draft.menuItemForm.menuItem = action.payload;

        return draft;

      case ITEMS_AND_MODS_ACTIONS.LOAD_MENU_BUILDER_MENU_ITEM_ERROR:
        draft.menuItemForm.loading = false;
        return draft;

      case ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_STATUS:
        draft.menuItemForm.menuItem.status = action.payload;
        return draft;

      case ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_EFFECTIVE_START_DATE:
        if (action.payload) {
          if (!draft.menuItemForm.menuItem.effectiveDates) {
            // we're setting a start date, but there's no container object yet, so add it
            draft.menuItemForm.menuItem.effectiveDates = {};
          }
          // now set the start date from the payload
          draft.menuItemForm.menuItem.effectiveDates.start = action.payload;
        } else {
          if (draft.menuItemForm.menuItem.effectiveDates?.end) {
            // we're deleting the start date, and there's an existing end date, so just remove the start date
            draft.menuItemForm.menuItem.effectiveDates.start = undefined;
          } else {
            // we're deleting the start date, and there is no end date, so remove the whole container object
            draft.menuItemForm.menuItem.effectiveDates = undefined;
          }
        }
        draft.menuItemForm.validation = newMenuItemValidation();
        return draft;

      case ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_EFFECTIVE_END_DATE:
        if (action.payload) {
          if (!draft.menuItemForm.menuItem.effectiveDates) {
            // we're setting an end date, but there's no container object yet, so add it
            draft.menuItemForm.menuItem.effectiveDates = {};
          }
          // now set the end date from the payload
          draft.menuItemForm.menuItem.effectiveDates.end = action.payload;
        } else {
          if (draft.menuItemForm.menuItem.effectiveDates?.start) {
            // we're deleting the end date, and there's an existing start date, so just remove the end date
            draft.menuItemForm.menuItem.effectiveDates.end = undefined;
          } else {
            // we're deleting the end date, and there is no start date, so remove the whole container object
            draft.menuItemForm.menuItem.effectiveDates = undefined;
          }
        }
        draft.menuItemForm.validation = newMenuItemValidation();
        return draft;

      case ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_IN_STOCK:
        draft.menuItemForm.menuItem.inStock = action.payload;
        return draft;

      case ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_DISPLAY_NAME:
        draft.menuItemForm.menuItem.displayName = action.payload;
        draft.menuItemForm.validation = newMenuItemValidation();
        return draft;

      case ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_DESCRIPTION:
        draft.menuItemForm.menuItem.description = action.payload;
        return draft;

      case ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_ACTIVE_PRICE_LEVEL:
        draft.menuItemForm.menuItem.activePriceLevel = action.payload;
        draft.menuItemForm.menuItem.inactiveStates = draft.menuItemForm.menuItem.inactiveStates.filter(
          (state) => state !== MenuItemInactiveStates.priceDeletedOnPOS
        );
        return draft;

      case ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_BUILDER_MENU_SCHEDULER:
        draft.menuItemForm.menuItem.schedule = action.payload;
        return draft;

      case ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_IS_POPULAR:
        draft.menuItemForm.menuItem.isPopular = action.payload;
        return draft;

      case ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_CONTAINS_ALCOHOL:
        const menuItemForAlcoholUpdate = draft.menuItemForm.menuItem;
        if (!menuItemForAlcoholUpdate.alcohol) {
          menuItemForAlcoholUpdate.alcohol = {
            containsAlcohol: action.payload,
          };
        } else {
          menuItemForAlcoholUpdate.alcohol.containsAlcohol = action.payload;
        }
        return draft;

      case ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_ALCOHOL_AMOUNT:
        const menuItemForAlcoholAmountUpdate = draft.menuItemForm.menuItem;
        if (!menuItemForAlcoholAmountUpdate.alcohol) {
          menuItemForAlcoholAmountUpdate.alcohol = {
            containsAlcohol: true,
            quantity: {
              measure: 'standardDrink',
              value: action.payload,
            },
          };
        } else if (!menuItemForAlcoholAmountUpdate.alcohol.quantity) {
          menuItemForAlcoholAmountUpdate.alcohol.quantity = {
            measure: 'standardDrink',
            value: action.payload,
          };
        } else {
          menuItemForAlcoholAmountUpdate.alcohol.quantity.value = action.payload;
        }
        return draft;

      case ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_ADD_TAG:
        if (draft.menuItemForm.menuItem.tags) {
          draft.menuItemForm.menuItem.tags.push(action.payload);
        } else {
          draft.menuItemForm.menuItem.tags = [action.payload];
        }
        return draft;

      case ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_REMOVE_TAG:
        draft.menuItemForm.menuItem.tags = draft.menuItemForm.menuItem.tags?.filter((t) => t._id !== action.payload);
        return draft;

      case ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_BADGE:
        draft.menuItemForm.menuItem.badge = action.payload;
        return draft;

      case ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_IMAGE:
        draft.menuItemForm.menuItem.imageId = action.payload.fileKey;
        draft.menuItemForm.menuItem.imageWidth = action.payload.width;
        draft.menuItemForm.menuItem.imageHeight = action.payload.height;
        return draft;

      case ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_THUMBNAIL_IMAGE:
        draft.menuItemForm.menuItem.thumbnailImageId = action.payload.fileKey;
        draft.menuItemForm.menuItem.thumbnailImageWidth = action.payload.width;
        draft.menuItemForm.menuItem.thumbnailImageHeight = action.payload.height;
        return draft;

      case ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_PRICE:
        draft.menuItemForm.menuItem.price = action.payload;
        draft.menuItemForm.validation = newMenuItemValidation();
        return draft;

      case ITEMS_AND_MODS_ACTIONS.UPDATE_MENU_ITEM_TAXES:
        draft.menuItemForm.taxes = action.payload;
        draft.menuItemForm.validation = newMenuItemValidation();
        return draft;

      case ITEMS_AND_MODS_ACTIONS.RESET_MENU_ITEM:
        draft.deleted = false;
        draft.menuItemForm.cancelled = false;
        draft.menuItemForm.menuItem = action.payload;
        draft.menuItemForm.validation = newMenuItemValidation();
        return draft;

      case ITEMS_AND_MODS_ACTIONS.SAVE_MENU_BUILDER_MENU_ITEM_BEGIN:
        draft.menuItemForm.processing = true;
        return draft;

      case ITEMS_AND_MODS_ACTIONS.SAVE_MENU_BUILDER_MENU_ITEM_SUCCESS:
        draft.menuItemForm.processing = false;
        draft.menuItemForm.duplicating = false;
        draft.menuItemForm.validation = newMenuItemValidation();
        if (action.payload) {
          draft.menuItemForm.newId = action.payload.id;
          draft.menuItemForm.menuItem = action.payload.storedMenuItem;
        }

        return draft;

      case ITEMS_AND_MODS_ACTIONS.SAVE_MENU_BUILDER_MENU_ITEM_ERROR:
        draft.menuItemForm.processing = false;
        return draft;

      case ITEMS_AND_MODS_ACTIONS.SAVE_MENU_BUILDER_MENU_ITEM_VALIDATION_ERROR:
        draft.menuItemForm.processing = false;
        draft.menuItemForm.validation = action.payload;
        return draft;

      case ITEMS_AND_MODS_ACTIONS.SHOW_DUPLICATE_MENU_BUILDER_MENU_ITEM_MODAL:
        draft.menuItemForm.duplicating = action.payload;
        return draft;

      case ITEMS_AND_MODS_ACTIONS.DUPLICATE_MENU_BUILDER_MENU_ITEM_VALIDATION_ERROR:
        draft.menuItemForm.processing = false;
        return draft;

      case ITEMS_AND_MODS_ACTIONS.ADD_MODIFIER_GROUP_TO_MENU_ITEM:
        draft.menuItemForm.menuItem.modifierGroups.push({
          ...action.payload,
          sortOrder: draft.menuItemForm.menuItem.modifierGroups.length,
        });
        return draft;

      case ITEMS_AND_MODS_ACTIONS.REMOVE_MODIFIER_GROUP_FROM_MENU_ITEM:
        const modifierGroupsForRemove = draft.menuItemForm.menuItem.modifierGroups;
        const modifierGroupIndex = modifierGroupsForRemove.findIndex(
          (modifierGroup) => modifierGroup._id === action.payload
        );
        if (modifierGroupIndex > -1) {
          modifierGroupsForRemove.splice(modifierGroupIndex, 1);
        }
        modifierGroupsForRemove.forEach((modGroup, index) => (modGroup.sortOrder = index));
        return draft;

      case ITEMS_AND_MODS_ACTIONS.MOVE_MODIFIER_GROUP_IN_MENU_ITEM:
        const modifierGroupsForMove = draft.menuItemForm.menuItem.modifierGroups;
        const movedModifierGroup = modifierGroupsForMove.splice(action.payload.sourceIndex, 1)[0];
        modifierGroupsForMove.splice(action.payload.destinationIndex, 0, movedModifierGroup);
        modifierGroupsForMove.forEach((modGroup, index) => (modGroup.sortOrder = index));
        return draft;

      case ITEMS_AND_MODS_ACTIONS.PREPARE_MENU_BUILDER_ITEMS_AND_MODS_MODAL:
        InfiniteScrollingListStateHelper.prepareState(
          draft.menuItemSelection,
          action.payload.visible,
          action.payload.selectedMenuItemIds
        );
        draft.menuItemSelection.view = action.payload.view;
        return draft;

      case ITEMS_AND_MODS_ACTIONS.SET_MENU_BUILDER_ITEMS_AND_MODS_MODAL_QUERY:
        InfiniteScrollingListStateHelper.filterChangedState(draft.menuItemSelection, action.payload);
        return draft;

      case ITEMS_AND_MODS_ACTIONS.SET_MENU_BUILDER_ITEMS_AND_MODS_MODAL_VIEW:
        draft.menuItemSelection.view = action.payload;
        return draft;

      case ITEMS_AND_MODS_ACTIONS.ADD_ITEMS_AND_MODS_TO_ITEMS_AND_MODS_SELECTION:
        InfiniteScrollingListStateHelper.selectItemState(draft.menuItemSelection, action.payload);
        return draft;

      case ITEMS_AND_MODS_ACTIONS.REMOVE_ITEMS_AND_MODS_FROM_ITEMS_AND_MODS_SELECTION:
        InfiniteScrollingListStateHelper.unselectItemState(draft.menuItemSelection, action.payload);
        return draft;

      case ITEMS_AND_MODS_ACTIONS.LOAD_MENU_BUILDER_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_BEGIN:
        InfiniteScrollingListStateHelper.loadingState(draft.menuItemSelection);
        return draft;

      case ITEMS_AND_MODS_ACTIONS.LOAD_MENU_BUILDER_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_SUCCESS:
        InfiniteScrollingListStateHelper.loadedState(draft.menuItemSelection, action.payload);
        return draft;

      case ITEMS_AND_MODS_ACTIONS.LOAD_MENU_BUILDER_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_ERROR:
        InfiniteScrollingListStateHelper.loadFailedState(draft.menuItemSelection);
        return draft;

      case ITEMS_AND_MODS_ACTIONS.LOAD_MORE_MENU_BUILDER_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_BEGIN:
        InfiniteScrollingListStateHelper.loadingMoreState(draft.menuItemSelection);
        return draft;

      case ITEMS_AND_MODS_ACTIONS.LOAD_MORE_MENU_BUILDER_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_SUCCESS:
        InfiniteScrollingListStateHelper.loadedMoreState(draft.menuItemSelection, action.payload);
        return draft;

      case ITEMS_AND_MODS_ACTIONS.LOAD_MORE_MENU_BUILDER_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_NO_RESULTS:
        InfiniteScrollingListStateHelper.loadMoreNoItemsState(draft.menuItemSelection);
        return draft;

      case ITEMS_AND_MODS_ACTIONS.LOAD_MORE_MENU_BUILDER_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_ERROR:
        InfiniteScrollingListStateHelper.loadMoreFailedState(draft.menuItemSelection);
        return draft;

      case ITEMS_AND_MODS_ACTIONS.PREPARE_MENU_BUILDER_POS_ITEMS_AND_MODS_MODAL:
        InfiniteScrollingListStateHelper.prepareState(draft.posItemSelection, action.payload.visible);
        draft.posItemSelection.type = action.payload.type;
        draft.posItemSelection.selectedItemId = action.payload.selectedItemId;
        return draft;

      case ITEMS_AND_MODS_ACTIONS.SET_MENU_BUILDER_POS_ITEMS_AND_MODS_MODAL_QUERY:
        InfiniteScrollingListStateHelper.filterChangedState(draft.posItemSelection, action.payload);
        return draft;

      case ITEMS_AND_MODS_ACTIONS.SELECT_POS_ITEM_IN_POS_ITEMS_AND_MODS_MODAL:
        draft.posItemSelection.selectedItemId = action.payload.externalId;
        draft.menuItemForm.menuItem.posItemId = action.payload.externalId;
        draft.menuItemForm.menuItem.posItemName = action.payload.name;
        draft.menuItemForm.menuItem.posItemCategories = action.payload.categories;
        draft.menuItemForm.menuItem.status = Status.enabled;

        // We don't always get inStock as true from the POS,
        // if the item is in stock. We need to assume in this
        // case that the item is in stock when initially linking
        // an item.
        draft.menuItemForm.menuItem.inStock = action.payload.inStock || action.payload.inStock === null;

        draft.menuItemForm.menuItem.price = action.payload.price;
        draft.menuItemForm.menuItem.priceLevels = action.payload.price_levels.map((priceLevel: IPosItemPriceLevel) => ({
          id: priceLevel.id,
          name: priceLevel.name,
          pricePerUnit: priceLevel.price_per_unit,
        }));
        draft.menuItemForm.menuItem.activePriceLevel = 'default';
        draft.menuItemForm.menuItem.inactiveStates = draft.menuItemForm.menuItem.inactiveStates
          .filter((state) => state !== MenuItemInactiveStates.draft)
          .filter((state) => state !== MenuItemInactiveStates.itemDeletedOnPOS);
        return draft;

      case ITEMS_AND_MODS_ACTIONS.LOAD_MENU_BUILDER_POS_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_BEGIN:
        InfiniteScrollingListStateHelper.loadingState(draft.posItemSelection);
        return draft;

      case ITEMS_AND_MODS_ACTIONS.LOAD_MENU_BUILDER_POS_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_SUCCESS:
        InfiniteScrollingListStateHelper.loadedState(draft.posItemSelection, action.payload);
        return draft;

      case ITEMS_AND_MODS_ACTIONS.LOAD_MENU_BUILDER_POS_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_ERROR:
        InfiniteScrollingListStateHelper.loadFailedState(draft.posItemSelection);
        return draft;

      case ITEMS_AND_MODS_ACTIONS.LOAD_MORE_MENU_BUILDER_POS_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_BEGIN:
        InfiniteScrollingListStateHelper.loadingMoreState(draft.posItemSelection);
        return draft;

      case ITEMS_AND_MODS_ACTIONS.LOAD_MORE_MENU_BUILDER_POS_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_SUCCESS:
        InfiniteScrollingListStateHelper.loadedMoreState(draft.posItemSelection, action.payload);
        return draft;

      case ITEMS_AND_MODS_ACTIONS.LOAD_MORE_MENU_BUILDER_POS_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_NO_RESULTS:
        InfiniteScrollingListStateHelper.loadMoreNoItemsState(draft.posItemSelection);
        return draft;

      case ITEMS_AND_MODS_ACTIONS.LOAD_MORE_MENU_BUILDER_POS_ITEMS_AND_MODS_FOR_INFINITE_SCROLL_ERROR:
        InfiniteScrollingListStateHelper.loadMoreFailedState(draft.posItemSelection);
        return draft;

      case ITEMS_AND_MODS_ACTIONS.LOAD_MENU_BUILDER_POS_MODIFIERS_BEGIN:
        draft.menuItemForm.forcedModifierSelection.loading = true;
        draft.menuItemForm.forcedModifierSelection.items = [];
        return draft;

      case ITEMS_AND_MODS_ACTIONS.LOAD_MENU_BUILDER_POS_MODIFIERS_SUCCESS:
        draft.menuItemForm.forcedModifierSelection.loading = false;
        draft.menuItemForm.forcedModifierSelection.items = action.payload;
        return draft;

      case ITEMS_AND_MODS_ACTIONS.LOAD_MENU_BUILDER_POS_MODIFIERS_ERROR:
        draft.menuItemForm.forcedModifierSelection.loading = false;
        return draft;

      case ITEMS_AND_MODS_ACTIONS.PREPARE_MENU_BUILDER_FORCED_MOD_MODAL: {
        InfiniteScrollingListStateHelper.prepareState(
          draft.forcedModSelection,
          action.payload.visible,
          action.payload.selectedModIds
        );
        return draft;
      }

      case ITEMS_AND_MODS_ACTIONS.ADD_READY_FORCED_MODIFIER_TO_MENU_ITEM:
        if (!draft.menuItemForm.menuItem.forcedModifiers) {
          draft.menuItemForm.menuItem.forcedModifiers = [];
        }
        draft.menuItemForm.menuItem.forcedModifiers.push(action.payload);
        return draft;

      case ITEMS_AND_MODS_ACTIONS.REMOVE_READY_FORCED_MODIFIER_TO_MENU_ITEM:
        if (!draft.menuItemForm.menuItem.forcedModifiers) {
          draft.menuItemForm.menuItem.forcedModifiers = [];
        }
        draft.menuItemForm.menuItem.forcedModifiers.splice(
          draft.menuItemForm.menuItem.forcedModifiers.findIndex((m: IForcedModifier) => m.itemId === action.payload),
          1
        );
        return draft;

      case ITEMS_AND_MODS_ACTIONS.ADD_MENU_ITEM_TO_MENU_ITEM_PAIRING:
        draft.menuItemForm.menuItem.recommendedItems.push(action.payload);
        return draft;

      case ITEMS_AND_MODS_ACTIONS.REMOVE_MENU_ITEM_FROM_MENU_ITEM_PAIRING:
        draft.menuItemForm.menuItem.recommendedItems = draft.menuItemForm.menuItem.recommendedItems.filter(
          (item) => item.itemId !== action.payload
        );
        return draft;

      case ITEMS_AND_MODS_ACTIONS.QUICK_UPDATE_MENU_ITEM_IN_STOCK_BEGIN:
        draft.loading = true;
        return draft;

      case ITEMS_AND_MODS_ACTIONS.QUICK_UPDATE_MENU_ITEM_IN_STOCK_SUCCESS:
        const itemStockPayload = action.payload as {
          id: string;
          newStockStatus: boolean;
        };
        const itemIndex = draft.items.findIndex((i) => i._id === itemStockPayload.id);
        if (itemIndex !== -1) {
          draft.items[itemIndex].inStock = itemStockPayload.newStockStatus;
        }
        draft.loading = false;
        return draft;

      case ITEMS_AND_MODS_ACTIONS.QUICK_UPDATE_MENU_ITEM_IN_STOCK_ERROR:
        draft.loading = false;
        return draft;

      case ITEMS_AND_MODS_ACTIONS.SYNC_MENU_BUILDER_MENU_ITEMS_BEGIN:
        draft.syncing = true;
        draft.pagination.start = 0;
        draft.pagination.length = 0;
        draft.pagination.limit = 0;
        draft.pagination.total = 0;
        draft.items = [];
        return draft;

      case ITEMS_AND_MODS_ACTIONS.SYNC_MENU_BUILDER_MENU_ITEMS_SUCCESS:
        draft.syncing = false;
        draft.pagination.start = action.payload.start;
        draft.pagination.length = action.payload.length;
        draft.pagination.limit = action.payload.limit;
        draft.pagination.total = action.payload.total;
        draft.items = action.payload.items;
        return draft;

      case ITEMS_AND_MODS_ACTIONS.SYNC_MENU_BUILDER_MENU_ITEMS_ERROR:
        draft.syncing = false;
        draft.pagination.start = action.payload.start;
        draft.pagination.length = action.payload.length;
        draft.pagination.limit = action.payload.limit;
        draft.pagination.total = action.payload.total;
        draft.items = action.payload.items;
        return draft;

      case ITEMS_AND_MODS_ACTIONS.DELETE_MENU_BUILDER_MENU_ITEM_BEGIN:
        draft.deleting = true;
        return draft;

      case ITEMS_AND_MODS_ACTIONS.DELETE_MENU_BUILDER_MENU_ITEM_SUCCESS:
        draft.deleting = false;
        draft.deleted = true;
        return draft;

      case ITEMS_AND_MODS_ACTIONS.DELETE_MENU_BUILDER_MENU_ITEM_ERROR:
        draft.deleting = false;
        return draft;

      default:
        return draft;
    }
  });
};
