import React from 'react';
import styles from './MenuItemCard.module.scss';
import {
  ForcedModifierStatus,
  IEmbeddedMenuItem,
  IMenuItem,
  IMenuSectionItem,
  MenuItemInactiveStates,
  Status,
} from '@ready/menu.core';
import MenuItemImage from './MenuItemImage';
import InfoCard from '../../../components/InfoCard/InfoCard';
import InfoCardColumn from '../../../components/InfoCard/InfoCardColumn';
import MenuItemPosBlock from './MenuItemPosBlock';
import MenuItemPriceBlock from './MenuItemPriceBlock';
import MenuItemNameBlock from './MenuItemNameBlock';
import MenuItemIconsBlock from './MenuItemIconsBlock';
import { InfoCardColumnsWrapper } from 'components/InfoCardColumnsWrapper/InfoCardColumnsWrapper';

export interface MenuItemCardProps {
  menuItem: IMenuItem | IMenuSectionItem | IEmbeddedMenuItem;
  linkTo?: string;
  imageUrl?: string;
  secondLevel?: boolean;
  parentDisabled?: boolean;
  hidden?: boolean;
  isSharedMenu?: boolean;
  controls?: React.ReactNode;
  additionalClassName?: string;
}

const MenuItemCard = (props: MenuItemCardProps): JSX.Element => {
  const {
    menuItem: {
      itemType,
      displayName,
      description,
      posItemId,
      posItemName,
      posItemCategories,
      inStock,
      price,
      alcohol,
      status,
      inactiveStates,
      schedule,
      isPopular,
      forcedModifierStatus,
      parentTemplateId,
    },
    linkTo,
    imageUrl,
    secondLevel = false,
    parentDisabled = false,
    hidden = false,
    controls,
    additionalClassName,
    isSharedMenu = false,
  } = props;
  const disabled = status === Status.disabled;

  // only show out of stock on menu image when posItemId exists
  const outOfStock =
    posItemId === undefined
      ? undefined
      : !inactiveStates.includes(MenuItemInactiveStates.draft) &&
        !inactiveStates.includes(MenuItemInactiveStates.itemDeletedOnPOS) &&
        !inStock;

  const containsAlcohol = alcohol && alcohol.containsAlcohol;
  const alcoholAmount = alcohol && containsAlcohol && alcohol.quantity ? alcohol.quantity.value : undefined;

  const hasSchedule = schedule && schedule.links.length > 0;

  return (
    <InfoCard additionalStyles={additionalClassName}>
      <InfoCardColumnsWrapper linkTo={linkTo}>
        <InfoCardColumn columnClassName={styles.imageMobile}>
          {secondLevel && (
            <div className={styles.dotContainer}>
              <div className={styles.dot} />
            </div>
          )}
          <MenuItemImage type={itemType} name={displayName} outOfStock={outOfStock} url={imageUrl} />
        </InfoCardColumn>

        <InfoCardColumn width={100} columnClassName={styles.details}>
          <div className={styles.imageAndDetails}>
            <div className={styles.imageDesktop}>
              {secondLevel && (
                <div className={styles.dotContainer}>
                  <div className={styles.dot} />
                </div>
              )}
              <MenuItemImage type={itemType} name={displayName} outOfStock={outOfStock} url={imageUrl} />
            </div>

            <MenuItemNameBlock
              displayName={displayName}
              description={description}
              parentDisabled={parentDisabled}
              disabled={disabled}
              hidden={hidden}
              warning={forcedModifierStatus === ForcedModifierStatus.invalid}
              className={styles.name}
            />
          </div>

          <MenuItemPosBlock
            id={posItemId}
            name={posItemName}
            categories={posItemCategories ?? []}
            draft={inactiveStates.includes(MenuItemInactiveStates.draft)}
            deleted={inactiveStates.includes(MenuItemInactiveStates.itemDeletedOnPOS)}
            parentDisabled={parentDisabled}
            disabled={disabled}
            hidden={hidden}
            className={styles.pos}
          />

          <div className={styles.attributes}>
            <MenuItemPriceBlock
              price={price}
              deleted={inactiveStates.includes(MenuItemInactiveStates.priceDeletedOnPOS)}
              parentDisabled={parentDisabled}
              disabled={disabled}
              hidden={hidden}
              className={styles.pos}
            />
          </div>

          <div className={styles.attributes}>
            <MenuItemIconsBlock
              isPopular={isPopular}
              hasSchedule={hasSchedule}
              containsAlcohol={containsAlcohol}
              alcoholAmount={alcoholAmount}
              isShared={parentTemplateId !== undefined}
              className={styles.icons}
            />
          </div>
        </InfoCardColumn>
      </InfoCardColumnsWrapper>

      {!!controls && !isSharedMenu ? controls : null}
    </InfoCard>
  );
};

export default MenuItemCard;
