import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { getTerminalsThunk } from './thunk';

interface State {
  serialNumbers: string[];
  loading: boolean;
}

export const initialState: State = {
  serialNumbers: [],
  loading: false,
};

export const readyPaymentsSlice = createSlice({
  name: 'serverFlow/readyPayments',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<State>) => {
    builder.addCase(getTerminalsThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTerminalsThunk.fulfilled, (state, action) => {
      if (action.payload) {
        state.loading = false;
        state.serialNumbers = action.payload.map((terminal) => terminal.serialNumber);
      }
    });
    builder.addCase(getTerminalsThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});
