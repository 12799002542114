import produce from 'immer';
import { Action } from '../../../redux/types';
import { contextSwitcher } from '../../initialStates/contextSwitcher/contextSwitcher';
import { CONTEXT_SWITCHER_ACTIONS } from '../../actions/contextSwitcherActions/types';
import InfiniteScrollingListStateHelper from '../../../utils/InfiniteScrollingList/InfiniteScrollingListStateHelper';

export const contextSwitcherReducer = (state = contextSwitcher, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case CONTEXT_SWITCHER_ACTIONS.UPDATE_MODAL_VISIBLE:
        InfiniteScrollingListStateHelper.prepareState(draft, action.payload);
        return draft;

      case CONTEXT_SWITCHER_ACTIONS.UPDATE_QUERY:
        InfiniteScrollingListStateHelper.filterChangedState(draft, action.payload);
        return draft;

      case CONTEXT_SWITCHER_ACTIONS.GET_CONTEXTS_BEGIN:
        InfiniteScrollingListStateHelper.loadingState(draft);
        return draft;

      case CONTEXT_SWITCHER_ACTIONS.GET_CONTEXTS_SUCCESS:
        InfiniteScrollingListStateHelper.loadedState(draft, action.payload);
        return draft;

      case CONTEXT_SWITCHER_ACTIONS.GET_CONTEXTS_ERROR:
        InfiniteScrollingListStateHelper.loadFailedState(draft);
        return draft;

      case CONTEXT_SWITCHER_ACTIONS.LOAD_MORE_CONTEXTS_BEGIN:
        InfiniteScrollingListStateHelper.loadingMoreState(draft);
        return draft;

      case CONTEXT_SWITCHER_ACTIONS.LOAD_MORE_CONTEXTS_SUCCESS:
        InfiniteScrollingListStateHelper.loadedMoreState(draft, action.payload);
        return draft;

      case CONTEXT_SWITCHER_ACTIONS.LOAD_MORE_CONTEXTS_ERROR:
        InfiniteScrollingListStateHelper.loadMoreFailedState(draft);
        return draft;

      case CONTEXT_SWITCHER_ACTIONS.LOAD_MORE_CONTEXTS_NO_RESULTS:
        InfiniteScrollingListStateHelper.loadMoreNoItemsState(draft);
        return draft;

      default:
        return draft;
    }
  });
};
