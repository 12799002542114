import React from 'react';
import { startOfMonth, subMonths } from 'date-fns';
import { TextCell, DollarCell, createNullableCell } from '../../components/TableV2/Cell/SpecialCells';
import { CellProps, createCellsWithMobileLayout } from '../../components/TableV2/Cell/Cell';
import cellStyles from '../../components/TableV2/Cell/Cell.module.scss';
import { createSortableHeaderCell } from '../../components/TableV2/Cell/HeaderCell';
import StatusIndicator from '../../components/StatusIndicator/StatusIndicator';
import styles from './PropmoCodeSummary.module.scss';
import classnames from 'classnames';
import { promoCodeHeaderColumnsSelector } from './redux/selectors';


const Status = ({ rowData, columnName, classes }: CellProps) => (
  <div className={classnames(cellStyles.tableV2__cell, styles.CellNowrap, classes)}>
    <StatusIndicator inactiveLabel='Disabled' activeLabel='Active' active={rowData[columnName] === 'Active'} />
  </div>
);

const CustomTextCell = ({ classes, ...rest }: CellProps) => {
  return <TextCell classes={classnames(classes, styles.CellNowrap)} {...rest} />;
};

const DescriptionCell = ({ classes, ...rest }: CellProps) => {
  return <TextCell classes={classnames(classes, styles.CellPrewrap)} {...rest} />;
};

const AlignedCustomCell = ({ classes, ...rest }: CellProps) => {
  return (
    <TextCell classes={classnames(classes, styles.CellNowrap, cellStyles['tableV2__cellTextAlign--right'])} {...rest} />
  );
};

const PosDiscountNull = ({ classes }: CellProps) => (
  <span className={classnames(styles.PosDiscountNull, styles.CellNowrap, cellStyles.tableV2__cell, classes)}>
    <i className='icon-dashboard-icons_alert' />
    POS Discount Deleted
  </span>
);

const NoDescription = ({ classes }: CellProps) => (
  <span className={classnames(styles.NoDescription, styles.CellNowrap, cellStyles.tableV2__cell, classes)}>
    No description
  </span>
);

const cells = createCellsWithMobileLayout(
  {
    code: (props: CellProps) => (
      <CustomTextCell {...props} classes={classnames(props.classes, styles.paddingLeftDesktop50)} />
    ),
    description: createNullableCell(DescriptionCell, NoDescription),
    locationName: CustomTextCell,
    usageCount: AlignedCustomCell,
    totalDiscounted: DollarCell,
    linkedPosDiscount: createNullableCell(CustomTextCell, PosDiscountNull),
    status: Status,
  },
  promoCodeHeaderColumnsSelector,
  { titleClasses: styles.MobileHeaderCell }
);

const headerCells = {
  code: createSortableHeaderCell('code', { classes: styles.HeaderCellShorted50 }),
  description: createSortableHeaderCell('description', { classes: styles.HeaderCellShorted500 }),
  locationName: createSortableHeaderCell('locationName', { classes: styles.HeaderCellShorted50 }),
  usageCount: createSortableHeaderCell('usageCount', { align: 'right', classes: styles.HeaderCellShorted50 }),
  totalDiscounted: createSortableHeaderCell('totalDiscounted', {
    classes: classnames(styles.HeaderCells, styles.HeaderCellShorted50),
    align: 'right',
  }),
  linkedPosDiscount: createSortableHeaderCell('linkedPosDiscount', {
    classes: classnames(styles.HeaderCells, styles.HeaderCellShorted50),
  }),
  status: createSortableHeaderCell('status', { classes: classnames(styles.HeaderCells, styles.HeaderCellShorted50) }),
};

const endMonth = startOfMonth(new Date());
const startMonth = subMonths(endMonth, 1);
const initDateFilter = { endMonth, startMonth };

const maxDaysToChoose = 366;

export { headerCells, cells, initDateFilter, maxDaysToChoose };
