import { createAsyncThunk } from '@reduxjs/toolkit';

import config from 'config';
import executeApiAsync from 'services/utils/executeApiAsync';

import { interceptErrorWithToastMessage } from 'redux/helpers/interceptErrorWithToastMessage';
import { ILocationServerFlow } from '@ready/dashboardv2api.contracts';

const getClientServerFlowSettings = ({
  companyId,
  locationId,
}: {
  companyId: string;
  locationId: string;
}): Promise<ILocationServerFlow> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/serverFlowConfig`;
  return executeApiAsync<ILocationServerFlow>(url);
};


const saveClientServerFlowSettings = ({
  companyId,
  locationId,
  settings,
}: {
  companyId: string;
  locationId: string;
  settings: ILocationServerFlow;
}): Promise<ILocationServerFlow> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/serverFlowConfig`;
  return executeApiAsync<ILocationServerFlow>(url, { method: 'PUT', body: JSON.stringify(settings) });
};

export const getClientServerFlowSettingsThunk = createAsyncThunk(
  'serverFlow/settings/get',
  interceptErrorWithToastMessage(getClientServerFlowSettings)
);

export const saveClientServerFlowSettingsThunk = createAsyncThunk(
  'serverFlow/settings/save',
  interceptErrorWithToastMessage(saveClientServerFlowSettings, 'Success! ServerFlow settings updated.')
);
