import React, { ChangeEvent, useCallback } from 'react';
// components
import { FormControl } from '../../../../../components/Form';
import FormattedInputNestedLabel from '../../../../../components/FormattedInputNestedLabel/FormattedInputNestedLabel';
import { LOCATION_SETTINGS_ACTIONS } from '../../../../redux/LocationSettingsActions';
// types
import { IPickupTimes } from '@ready/dashboardv2api.contracts';
import { IPickUpErrors, IPickUpHourError, IOrderAheadValidation } from '../../../../redux/PanelFeaturesState';
import { IUpdateLocationParam } from '../../../LocationsEditPage';

interface Props {
  updateLocationParam: IUpdateLocationParam;
  pickUpTimes: IPickupTimes;
  label: string;
  day: string;
  validation: IOrderAheadValidation;
  isProcessing?: boolean;
}

type PickupTimesValues = IPickupTimes[keyof IPickupTimes];

const PickupHours = (props: Props) => {
  const { updateLocationParam, pickUpTimes, label, day, validation, isProcessing = false } = props;

  const handleInputChange = useCallback(
    (value: PickupTimesValues, inputType: keyof IPickupTimes, day: string, setFormAsDirty = true) => {
      let newValues = {} as any;
      newValues.day = day;
      switch (inputType) {
        case 'start':
          newValues.start = value as string;
          updateLocationParam(newValues, LOCATION_SETTINGS_ACTIONS.SET_PICK_UP_HOURS_START, setFormAsDirty);
          break;
        case 'end':
          newValues.end = value as string;
          updateLocationParam(newValues, LOCATION_SETTINGS_ACTIONS.SET_PICK_UP_HOURS_END, setFormAsDirty);
          break;
        case 'offset':
          newValues.offset = value as string;
          updateLocationParam(newValues, LOCATION_SETTINGS_ACTIONS.SET_PICK_UP_HOURS_OFFSET, setFormAsDirty);
          break;

        default:
          console.error(`Cannot change pickup times`);
          break;
      }
    },
    [updateLocationParam]
  );

  const pickUpValidation = validation.orderAhead.pickUpHours;
  const pickUpHourError = pickUpValidation.errors[day as keyof IPickUpErrors] as IPickUpHourError;

  const startError = pickUpValidation.hasError && pickUpHourError.start;
  const endError = pickUpValidation.hasError && pickUpHourError.end;
  const offSetError = pickUpValidation.hasError && pickUpHourError.offset;

  const isClosedDay = pickUpTimes.start === pickUpTimes.end;

  const pickupTimesString = JSON.stringify(pickUpTimes);
  React.useEffect(() => {
    if (isClosedDay) {
      handleInputChange('0', 'offset', day, false);
    }
  }, [isClosedDay, day, pickupTimesString, handleInputChange]);

  return (
    <FormControl label={label} subLabeled>
      <FormattedInputNestedLabel
        format='time'
        label='Start'
        value={pickUpTimes.start}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleInputChange(e.target.value, 'start', day);
        }}
        withError={startError}
        errorMessage={'Start time is required'}
        loading={isProcessing}
      />
      <FormattedInputNestedLabel
        format='time'
        label='End'
        value={pickUpTimes.end}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleInputChange(e.target.value, 'end', day);
        }}
        withError={endError}
        errorMessage={'End time is required'}
        loading={isProcessing}
      />
      <FormattedInputNestedLabel
        format='min'
        label='Offset'
        value={pickUpTimes.offset}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleInputChange(e.target.value, 'offset', day);
        }}
        withError={offSetError}
        errorMessage={'Offset is required'}
        loading={isProcessing}
        disabled={isClosedDay}
      />
    </FormControl>
  );
};

export default PickupHours;
