import { ICompanyIntegrations, LoyaltyProvider } from '@ready/dashboardv2api.contracts';
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Option } from '../../../components/SelectFilter/SelectFilter';
import { DetailLayout } from '../../../components/DetailLayout';
import { FormControl } from '../../../components/Form';
import SelectFilter from '../../../components/SelectFilter/SelectFilter';
import { changePosProviderOption } from '../../../redux/actions/uiActions/uiActions';
import { IUIDropDown } from '../../../redux/initialStates/ui/dropDownState';
import { LIST_OPTIONS } from '../../../utils/selectListUtils/listOptions';
import { getSelectedOption } from '../../../utils/selectListUtils/listUtil';
import { PANEL_LOYALTY_ACTIONS } from '../../redux/PanelLoyaltyActions';
import { IPosLoyaltyPanel } from '../../redux/PanelLoyaltyState';
import SpendGoPOS from './SpendGoPOS';
import ByPass from './ByPass';
import SquarePOS from './SquarePOS';
import { IUpdateLocationParam } from '../LocationsEditPage';
import PunchhForm from './PunchhForm';

export type LoyaltyPOSProps = {
  isProcessing: boolean;
  updateLocationParam: IUpdateLocationParam;
  posLoyaltyPanelInitialState: IPosLoyaltyPanel;
  dropDown: IUIDropDown;
  companyIntegrations?: ICompanyIntegrations;
};

const LoyaltyTabContent = (props: LoyaltyPOSProps) => {
  const { isProcessing, updateLocationParam, posLoyaltyPanelInitialState, dropDown, companyIntegrations } = props;
  const dispatch = useDispatch();
  const { posProvider } = dropDown;
  const { id, companyId } = useParams<{ id: string; companyId: string }>();

  const handleSelectOptionChange = useCallback(
    (option: Option, key: string) => {
      switch (key) {
        case 'posProvider':
          const newSelection = getSelectedOption(posProvider.options, option.label.toString(), key);
          dispatch(changePosProviderOption(newSelection));
          break;
      }
    },
    [dispatch, posProvider.options]
  );

  React.useEffect(() => {
    let data = LIST_OPTIONS.loyaltyProviderOptions.find((o) => o.value === posLoyaltyPanelInitialState.provider);
    if (!data) {
      data = LIST_OPTIONS.loyaltyProviderOptions[0];
    }
    handleSelectOptionChange(data, 'posProvider');
  }, [handleSelectOptionChange, posLoyaltyPanelInitialState.provider]);

  const handlePosProviderChange = (data: Option) => {
    updateLocationParam(data.value, PANEL_LOYALTY_ACTIONS.SET_SELECTED_PROVIDER);
    handleSelectOptionChange(data, 'posProvider');
  };

  return (
    <DetailLayout title='Loyalty'>
      <FormControl label='Loyalty Provider'>
        <SelectFilter
          options={LIST_OPTIONS.loyaltyProviderOptions}
          onChange={handlePosProviderChange}
          value={posProvider.selectedOption}
          loading={isProcessing}
        />
      </FormControl>
      <>
        {posLoyaltyPanelInitialState.provider === LoyaltyProvider.Spendgo && (
          <SpendGoPOS
            isProcessing={isProcessing}
            posLoyaltyPanelInitialState={posLoyaltyPanelInitialState}
            updateLocationParam={updateLocationParam}
            locationId={id}
            companyId={companyId}
          />
        )}

        {posLoyaltyPanelInitialState.provider === LoyaltyProvider.ByPass && (
          <ByPass
            isProcessing={isProcessing}
            posLoyaltyPanelInitialState={posLoyaltyPanelInitialState}
            updateLocationParam={updateLocationParam}
          />
        )}

        {posLoyaltyPanelInitialState.provider === LoyaltyProvider.Square && (
          <SquarePOS
            isProcessing={isProcessing}
            posLoyaltyPanelInitialState={posLoyaltyPanelInitialState}
            updateLocationParam={updateLocationParam}
            locationId={id}
            companyId={companyId}
            companyIntegrations={companyIntegrations}
          />
        )}

        {posLoyaltyPanelInitialState.provider === LoyaltyProvider.Punchh && (
          <PunchhForm
            isProcessing={isProcessing}
            posLoyaltyPanelInitialState={posLoyaltyPanelInitialState}
            updateLocationParam={updateLocationParam}
          />
        )}
      </>
    </DetailLayout>
  );
};

export default LoyaltyTabContent;
