import TextInput from 'components/TextInput/TextInput';
import React, { useState } from 'react';
import styles from './ToolbarContent.module.scss';

interface ToolbarContentProps {
  initialSearch?: string;

  onSearchTermChanged: (searchTerm: string) => void;
}

export const ToolbarContent = ({ initialSearch = '', onSearchTermChanged }: ToolbarContentProps) => {
  const [searchTerm, setSearchTerm] = useState<string>(initialSearch);

  return (
    <div className={styles.toolbar}>
      <div className={styles.locationFilter}>
        <TextInput
          placeholder='Search locations'
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            onSearchTermChanged(e.target.value);
          }}
        />
      </div>
    </div>
  );
};
