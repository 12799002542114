import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
  loadPromoCode,
  prepareDeletePromoCodeModal,
  deletePromoCode,
  resetPromoCodeForm,
} from '../../redux/PromoCodesActions';
import { AppState } from '../../../redux/initialStates/AppState';
import { IDayAvailability, ValidationType } from '@ready/dashboardv2api.contracts';
import { Routes } from '../../EditRouter';

import styles from './PromoCodePage.module.scss';
import ActionHeader from '../../../components/ActionHeader/ActionHeader';
import LayoutContent from '../../../components/AppLayout/LayoutContent';
import { DeleteConfirmationModal } from '../../../components/Modal';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import { Panel, PanelLayout } from '../../../components/PanelLayout';
import { DetailElement, DetailLayout } from '../../../components/DetailLayout';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';
import ErrorValue from '../../../components/ErrorValue/ErrorValue';
import StatusIndicator from '../../../components/StatusIndicator/StatusIndicator';

const mapStateToProps = (state: AppState) => ({
  loading: state.location.promoCodes.form.loading,
  promoCode: state.location.promoCodes.form.promoCode,
  processing: state.location.promoCodes.form.processing,
  deleting: state.location.promoCodes.form.deleting,
  deleted: state.location.promoCodes.form.deleted,
});

const actionCreators = {
  loadPromoCode,
  prepareDeletePromoCodeModal,
  deletePromoCode,
  resetPromoCodeForm,
};

const connector = connect(mapStateToProps, actionCreators);

type PromoCodePageProps = ConnectedProps<typeof connector>;

const DAYS_OF_WEEK = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];

const formatTime = (hour: number, minute: number): string => {
  return `${hour > 12 ? hour - 12 : hour}:${minute < 10 ? '0' : ''}${minute} ${hour > 12 ? 'P' : 'A'}M`;
};

interface AvailabilityTimeSlotProps {
  daysAvailable: IDayAvailability[];
  startHour: number;
  startMinute: number;
  endHour: number;
  endMinute: number;
}

const AvailabilityTimeSlot = ({
  daysAvailable,
  startHour,
  startMinute,
  endHour,
  endMinute,
}: AvailabilityTimeSlotProps): JSX.Element => (
  <div className={styles.timeSlot}>
    <div className={styles.days}>
      {daysAvailable
        .filter((dayAvailability) => dayAvailability.available)
        .map((dayAvailability) => DAYS_OF_WEEK[dayAvailability.day])
        .join(', ')}
    </div>
    <div>
      {formatTime(startHour, startMinute)} - {formatTime(endHour, endMinute)}
    </div>
  </div>
);

const PromoCodePage = (props: PromoCodePageProps): JSX.Element => {
  const {
    loading,
    promoCode,
    processing,
    deleting,
    deleted,
    loadPromoCode,
    prepareDeletePromoCodeModal,
    deletePromoCode,
    resetPromoCodeForm,
  } = props;
  const {
    companyId,
    id: locationId,
    promoCodeId: id,
  } = useParams<{
    companyId: string;
    id: string;
    promoCodeId: string;
  }>();
  const history = useHistory();

  const backLink = Routes.getPromoCodeTab(companyId, locationId);

  const onDeleteButtonClick = (): void => {
    prepareDeletePromoCodeModal(true);
  };

  const onDeleteConfirmButtonClick = (): void => {
    deletePromoCode(locationId, id);
  };

  const onEditButtonClick = (): void => {
    history.push(Routes.getEditPromoCodePage(companyId, locationId, id));
  };

  React.useEffect(() => {
    loadPromoCode(locationId, id);
    return () => {
      resetPromoCodeForm();
    };
  }, [loadPromoCode, locationId, id, resetPromoCodeForm]);

  React.useEffect(() => {
    if (deleted) {
      history.push(backLink);
    }
  }, [deleted, history, backLink]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <LayoutContent title='Promo Code Details' containerType='within'>
      {deleting && (
        <DeleteConfirmationModal
          setShowModal={prepareDeletePromoCodeModal}
          item='Promo Code'
          itemName={promoCode.code}
          loading={processing}
          handleDelete={onDeleteConfirmButtonClick}
        />
      )}

      <ActionHeader
        text={promoCode.code}
        backLinkTo={backLink}
        actionButtons={[
          {
            label: 'Delete',
            variant: 'secondary-gray-bg',
            onClick: onDeleteButtonClick,
          },
          {
            label: 'Edit',
            onClick: onEditButtonClick,
          },
        ]}
      />
      <PanelLayout>
        <Panel>
          <DetailLayout>
            <DetailElement label='Status'>
              <StatusIndicator active={promoCode.enabled} activeLabel='Active' inactiveLabel='Disabled' />
            </DetailElement>
            <DetailElement label='Promo Code *'>{promoCode.code}</DetailElement>
            <DetailElement label='Description'>
              {promoCode.description || <span className={styles.missing}>No description</span>}
            </DetailElement>
            <DetailElement label='Link to POS Discount *'>
              {promoCode.posDiscountName || <ErrorValue text='POS Discount Deleted' />}
            </DetailElement>
            <DetailElement label='Validate Based On'>
              {promoCode.validationType === ValidationType.OrderTime
                ? 'Time the order was placed'
                : 'Scheduled pick up time'}
            </DetailElement>
            <DetailElement label='Auto Attach'>
              {promoCode.autoAttach ? (
                <TextIcon icon={Icon.Checkmark} additionalStyles={styles.autoAttach} />
              ) : (
                <TextIcon icon={Icon.Remove} additionalStyles={styles.noAutoAttach} />
              )}
              Automatically attach this promo code to every order
            </DetailElement>
            <DetailElement
              label='Availability'
              labelTooltip='You can restrict a promo code’s availability so that it can only be used on certain days and time.'
            >
              {promoCode.timeSlots.length === 0 && 'Always Available'}
              {promoCode.timeSlots.map((timeSlot, index) => (
                <AvailabilityTimeSlot
                  key={index}
                  daysAvailable={timeSlot.daysAvailable}
                  startHour={timeSlot.startHour!}
                  startMinute={timeSlot.startMinute!}
                  endHour={timeSlot.endHour!}
                  endMinute={timeSlot.endMinute!}
                />
              ))}
            </DetailElement>
          </DetailLayout>
        </Panel>
      </PanelLayout>
    </LayoutContent>
  );
};

export default connector(PromoCodePage);
