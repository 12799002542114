import React from 'react';
import TableCell from './TableCell';

export interface TableCellsProps {
  children: JSX.Element[] | JSX.Element;
  visibleOn?: 'all' | 'desktop' | 'mobile';
}

const TableCells = (props: TableCellsProps) => {
  const { children, visibleOn = 'all' } = props;

  return (
    <>
      {(Array.isArray(children) ? children : [children]).map((child, index) => (
        <TableCell key={index} {...child.props} visibleOn={visibleOn} firstCell={index === 0} />
      ))}
    </>
  );
};

export default TableCells;
