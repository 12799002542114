import React from 'react';
import styles from './CompanyTablePage.module.scss';

export interface CompanyTableFooterProps {
  children: JSX.Element;
}

const CompanyTableFooter = (props: CompanyTableFooterProps) => {
  const { children } = props;

  return <div className={styles.footer}>{children}</div>;
};

export default CompanyTableFooter;
