import { IEmailMarketingListItem, IPagination } from '@ready/dashboardv2api.contracts';
import { IPagedResponse } from '@ready/table.core';
import { ILocation } from '../../../services/types/ILocation.type';

export interface IEmailMarketingList {
  emailMarketingList?: boolean;
  filters?: boolean;
  permittedLocations?: boolean;
}

export interface IEmailMarketingListErrors {
  emailMarketingList?: string;
  filters?: string;
  permittedLocations?: string;
}

export interface IEmailMarketingListState {
  rowKey: string;
  columns: Array<string>;
  headerColumns: Record<string, string>;
  items: Array<IEmailMarketingListItem>;
  pagination: IPagination;
  loading: IEmailMarketingList;
  errors: IEmailMarketingListErrors;
  permittedLocations: IPagedResponse<ILocation>;
}

export const initialEmailMarketingListState: IEmailMarketingListState = {
  rowKey: '_id',
  items: [],
  permittedLocations: {
    totalCount: 0,
    results: [],
  },
  columns: ['email', 'locationName', 'date'],
  headerColumns: {
    locationName: 'Location',
    date: 'Guest opt in date',
    email: 'Email',
  },
  pagination: {
    start: 0,
    length: 0,
    limit: 0,
    total: 0,
  },
  loading: {},
  errors: {},
};
