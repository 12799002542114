import { PaginationResponse } from '@ready/dashboardv2api.contracts';
import {
  ITemplateMenuGroup,
  ITemplateMenuRequest,
  WithAssignedLocationsCount,
  TemplateMenuSummaryRequest,
  ITemplateItemGalleryRequest,
  ILocationAssignments,
  TemplateItemGallerySummaryRequest,
} from '@ready/menu.core';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { set200Toast, toastErrorState } from 'redux/actions/uiActions/responseStateActions';
import {
  createTemplateMenu,
  createTemplateMenuGroupItemGallery,
  INewTemplateMenuRequest,
  updateTemplateItemGallerySummary,
  updateTemplateMenuSummary,
} from 'sharedMenuItems/pages/menu/menu.service';
import { mapTemplateMenuGroupContractToUI, mapUIMenuGroupToRequest } from '../../menuConfigs.mapper';
import {
  deleteTemplateMenuGroup,
  getLocationAssignmentsForMenuGroup,
  getTemplateMenuGroups,
  updateLocationAssignmentsForMenuGroup,
  updateTemplateMenuGroup,
} from '../../menuConfigs.service';
import { IMenuGroupUI } from './types';
import {
  loadMoreItemsPending,
  loadItemsPending,
  loadMoreItemsFulfilled,
  loadItemsFulfilled,
  selectItems,
  loadMoreItemsFailure,
  loadItemsFailure,
} from 'redux/slices/infiniteScrollingListModalSlice';
import { menuConfigsListSlice } from './slice';

const getSharedMenuConfigsThunk = createAsyncThunk<
  PaginationResponse<WithAssignedLocationsCount<ITemplateMenuGroup>>,
  string
>('sharedMenus/menuConfigs/list/fetch', async (companyId: string, thunkAPI) => {
  return getTemplateMenuGroups(companyId);
});

interface SaveSharedMenuConfigArgs {
  companyId: string;
  templateMenuGroup: IMenuGroupUI;
}

const saveSharedMenuConfigThunk = createAsyncThunk<IMenuGroupUI | undefined, SaveSharedMenuConfigArgs>(
  'sharedMenus/menuConfigs/save',
  async (args: SaveSharedMenuConfigArgs, thunkAPI) => {
    try {
      const isNew = !args.templateMenuGroup._id;
      const request = mapUIMenuGroupToRequest(args.templateMenuGroup);
      const result = await updateTemplateMenuGroup(args.companyId, request, isNew);
      thunkAPI.dispatch(set200Toast(`Success! ${isNew ? 'New configuration created' : 'Changes saved.'} `));

      return mapTemplateMenuGroupContractToUI(result);
    } catch (e) {
      thunkAPI.dispatch(toastErrorState(e.status, e.message));
    }
  }
);

interface CreateSharedMenuOrItemGalleryArgs {
  companyId: string;
  groupId: string;
  formFields: Pick<ITemplateMenuRequest, 'displayName' | 'description'>;
}

const defaultNewMenuValues: INewTemplateMenuRequest = {
  sections: [],
  displayName: '',
};

const createSharedMenuThunk = createAsyncThunk<void, CreateSharedMenuOrItemGalleryArgs>(
  'sharedMenus/menuConfigs/createMenu',
  async (args: CreateSharedMenuOrItemGalleryArgs, thunkAPI) => {
    const createMenuPayload: INewTemplateMenuRequest = {
      ...defaultNewMenuValues,
      displayName: args.formFields.displayName,
      description: args.formFields.description,
    };

    try {
      await createTemplateMenu(args.companyId, args.groupId, createMenuPayload);
      thunkAPI.dispatch(set200Toast('Success! Menu created.'));
    } catch (e) {
      thunkAPI.dispatch(toastErrorState(e.status, e.message));
    }
  }
);

interface IUpdateMenuSummaryArgs {
  companyId: string;
  menuOrGalleryId: string;
  menuFormFields: Pick<ITemplateMenuRequest, 'displayName' | 'description'>;
}

const updateSharedMenuSummaryThunk = createAsyncThunk<void, IUpdateMenuSummaryArgs>(
  'sharedMenus/menuConfigs/updateMenuSummary',
  async (args: IUpdateMenuSummaryArgs, thunkAPI) => {
    const menuSummaryRequest: TemplateMenuSummaryRequest = {
      displayName: args.menuFormFields.displayName,
      description: args.menuFormFields.description,
      _id: args.menuOrGalleryId,
    };

    try {
      await updateTemplateMenuSummary(args.companyId, menuSummaryRequest);
      thunkAPI.dispatch(set200Toast('Success! Changes saved.'));
    } catch (e) {
      thunkAPI.dispatch(toastErrorState(e.status, e.message));
    }
  }
);

const updateSharedItemGallerySummaryThunk = createAsyncThunk<void, IUpdateMenuSummaryArgs>(
  'sharedMenus/menuConfigs/updateItemGallerySummary',
  async (args: IUpdateMenuSummaryArgs, thunkAPI) => {
    const itemGallerySummaryRequest: TemplateItemGallerySummaryRequest = {
      displayName: args.menuFormFields.displayName,
      description: args.menuFormFields.description,
      _id: args.menuOrGalleryId,
    };

    try {
      await updateTemplateItemGallerySummary(args.companyId, itemGallerySummaryRequest);
      thunkAPI.dispatch(set200Toast('Success! Changes saved.'));
    } catch (e) {
      thunkAPI.dispatch(toastErrorState(e.status, e.message));
    }
  }
);

interface DeleteSharedMenuConfigArgs {
  companyId: string;
  id: string;
}

const deleteSharedMenuConfigThunk = createAsyncThunk<void, DeleteSharedMenuConfigArgs>(
  'sharedMenus/menuConfigs/delete',
  async (args: DeleteSharedMenuConfigArgs, thunkAPI) => {
    try {
      await deleteTemplateMenuGroup(args.companyId, args.id);
      thunkAPI.dispatch(set200Toast(`Menu configuration deleted.`));
    } catch (e) {
      thunkAPI.dispatch(toastErrorState(e.status, e.message));
    }
  }
);

interface GetAssignedLocationsForMenuConfigThunk {
  companyId: string;
  menuConfigId: string;
  locationSearchTerm?: string;
  page?: number;

  isLoadMore?: boolean;
}

export const getAssignedLocationsForMenuConfigThunk = createAsyncThunk<void, GetAssignedLocationsForMenuConfigThunk>(
  'sharedMenus/menuConfigs/locations/fetch',
  async (
    { companyId, menuConfigId, locationSearchTerm, page, isLoadMore }: GetAssignedLocationsForMenuConfigThunk,
    thunkApi
  ) => {
    if (isLoadMore) {
      thunkApi.dispatch(loadMoreItemsPending());
    } else {
      thunkApi.dispatch(loadItemsPending());
    }
    try {
      const result = await getLocationAssignmentsForMenuGroup(companyId, menuConfigId, locationSearchTerm, page);

      if (isLoadMore) {
        thunkApi.dispatch(loadMoreItemsFulfilled(result.items));
      } else {
        thunkApi.dispatch(loadItemsFulfilled(result.items));

        // store all locations
        if (!locationSearchTerm) thunkApi.dispatch(menuConfigsListSlice.actions.storeTotalLocationsCount(result.total));
      }

      // if any of these are flagged as assigned, add them to selectedIds state.
      const selectedIds = result.items.filter((x) => x.hasTemplateAssigned).map((x) => x._id);
      if (selectedIds.length > 0) {
        thunkApi.dispatch(selectItems(selectedIds));
      }
    } catch (error) {
      if (isLoadMore) {
        thunkApi.dispatch(loadMoreItemsFailure());
      } else {
        thunkApi.dispatch(loadItemsFailure());
      }
      thunkApi.dispatch(toastErrorState(error));
    }
  }
);

interface UpdateAssignedLocationsForMenuConfig {
  companyId: string;
  menuConfigId: string;

  /** Menu Configs do not allow bulk assignment. */
  assignments: Omit<ILocationAssignments, 'allLocations'>;
}

export const updateAssignedLocationsForMenuGroupThunk = createAsyncThunk<void, UpdateAssignedLocationsForMenuConfig>(
  'sharedMenus/menuGroups/assignedLocations/locations/save',
  async ({ companyId, menuConfigId, assignments }: UpdateAssignedLocationsForMenuConfig, thunkApi) => {
    try {
      await updateLocationAssignmentsForMenuGroup(companyId, menuConfigId, assignments);

      thunkApi.dispatch(set200Toast('Success! Changes saved.'));
    } catch (e) {
      thunkApi.dispatch(toastErrorState(e.status, e.message));
    }
  }
);

const createSharedMenuConfigItemGalleryThunk = createAsyncThunk<void, CreateSharedMenuOrItemGalleryArgs>(
  'sharedMenus/menuConfigs/createMenuGroupItemGallery',
  async (args: CreateSharedMenuOrItemGalleryArgs, thunkAPI) => {
    const createItemGalleryPayload: Omit<ITemplateItemGalleryRequest, '_id'> = {
      displayName: args.formFields.displayName,
      description: args.formFields.description,
      items: [],
      sortOrder: 1,
    };

    try {
      await createTemplateMenuGroupItemGallery(args.companyId, args.groupId, createItemGalleryPayload);
      thunkAPI.dispatch(set200Toast('Success! New item gallery created.'));
    } catch (e) {
      thunkAPI.dispatch(toastErrorState(e.status, e.message));
    }
  }
);

export {
  getSharedMenuConfigsThunk,
  saveSharedMenuConfigThunk,
  deleteSharedMenuConfigThunk,
  createSharedMenuConfigItemGalleryThunk,
  updateSharedMenuSummaryThunk,
  createSharedMenuThunk,
  updateSharedItemGallerySummaryThunk,
};
