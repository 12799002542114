import { debounce } from 'lodash';
import { ICachedPosMenuItem, ICachedPosModifier } from '@ready/menu.core';
import { OptionProps, components, SingleValueProps } from 'react-select';
import React, { useCallback, useEffect, useState } from 'react';
import styles from './PosMenuItemSelect.module.scss';
import { Option } from 'components/SelectFilter/SelectFilter';
import MenuBuilderService from 'menus/services/MenuBuilderService';
import SelectFilterAsync from 'components/SelectFilter/SelectFilterAsync';
import { PaginationResponse } from '@ready/dashboardv2api.contracts';

export interface IPosMenuItemOption extends ICachedPosMenuItem {
  value: string;
  label: string;
  subLabel: string;
}

const PosMenuItemOption = (props: OptionProps<Option, boolean>) => (
  <components.Option {...props}>
    {props.data.label}
    <div className={styles.posDetails}>{props.data.subLabel}</div>
  </components.Option>
);

const PosMenuItemSingleValue = (props: SingleValueProps<Option>) => {
  const subLabel = props.data.subLabel ? props.data.subLabel.substring(0, props.data.subLabel.indexOf(' | ')) : '';
  return (
    <components.SingleValue {...props}>
      {props.data.label}
      {subLabel && (
        <>
          {' '}
          <span className={styles.posId}>{`(${subLabel})`}</span>
        </>
      )}
    </components.SingleValue>
  );
};

interface Props {
  companyId: string;
  locationId: string;
  isModifier: boolean;
  onChange: (option?: IPosMenuItemOption) => void;
  selectedPosMenuItemId?: string;
  isInModal?: boolean;
  disabled?: boolean;
  initialOption?: Option;
}

const PosMenuItemSelect = (props: Props) => {
  const { companyId, locationId, selectedPosMenuItemId, onChange, isInModal, isModifier, disabled = false } = props;
  const [posMenuItems, setPosMenuItems] = useState<IPosMenuItemOption[]>([]);
  const [loadingPosMenuItems, setLoadingPosMenuItems] = useState<boolean>(true);
  const [selectedPosMenuItem, setSelectedPosMenuItem] = useState<Option | undefined>(props.initialOption);

  const fetchPosMenuItems = useCallback(
    async (companyId, locationId, query) => {
      const itemResponse: PaginationResponse<ICachedPosMenuItem | ICachedPosModifier> = isModifier
        ? await MenuBuilderService.getPosModifiers(companyId, locationId, query, 1, 50)
        : await MenuBuilderService.getPosItems(companyId, locationId, query, 1, 50);

      setPosMenuItems(
        itemResponse.items.map((posItem: ICachedPosMenuItem | ICachedPosModifier) => ({
          value: posItem.externalId,
          label: `${posItem.name}`,
          subLabel: `${posItem.externalId} | ${(posItem.categories ?? []).join(', ')}`,
          ...posItem,
        }))
      );
      setLoadingPosMenuItems(false);
    },
    [isModifier]
  );

  const fetchInitialPosMenuItems = useCallback(async (): Promise<void> => {
    await fetchPosMenuItems(companyId, locationId, '');
  }, [companyId, fetchPosMenuItems, locationId]);

  const fetchPosItemsWithFilter = debounce(async (filter: string): Promise<void> => {
    setLoadingPosMenuItems(true);
    await fetchPosMenuItems(companyId, locationId, filter);
    setLoadingPosMenuItems(false);
  }, 300);

  useEffect(() => {
    fetchInitialPosMenuItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loadingPosMenuItems && selectedPosMenuItemId) {
      setSelectedPosMenuItem(posMenuItems.find((item) => item.externalId === selectedPosMenuItemId));
    } else if (!selectedPosMenuItemId) {
      setSelectedPosMenuItem(undefined);
    }
  }, [loadingPosMenuItems, selectedPosMenuItemId, posMenuItems]);

  const handleChange = (option: Option) => {
    option ? onChange(posMenuItems.find((item) => item.value === option.value)) : onChange(option);
  };

  return (
    <SelectFilterAsync
      placeholder={'Search to select a POS Item'}
      options={posMenuItems}
      value={selectedPosMenuItem}
      loading={loadingPosMenuItems}
      limit={50}
      overflowMessage={`Top 50 results shown. Type to refine your search.`}
      isClearable
      hideIndicator
      handleFetch={fetchPosItemsWithFilter}
      handleInitialFetch={fetchInitialPosMenuItems}
      onChange={handleChange}
      customComponents={{
        Option: PosMenuItemOption,
        SingleValue: PosMenuItemSingleValue,
      }}
      isInModal={isInModal}
      disabled={disabled}
    />
  );
};

export { PosMenuItemSelect };
