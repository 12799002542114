export interface Param {
  name: string;
  value: string;
}

export class URLSearchParamsExtended extends URLSearchParams {
  /**
   * Returns string which is encodeURIComponent-encoded and can be used as value of URL paremeter.
   *
   * Additionally, fix case with escaping space with '+'. The URLSearchParams do such escaping,
   * but it can't be parsed back with decodeURIComponent.
   */
  toEncodedString() {
    return encodeURIComponent(this.toString().replace(/\+/g, " "));
  }
}

export const updateURLSearchParams = (query: string, params: Array<Param>): URLSearchParamsExtended => {
  let searchParams = query
    ? new URLSearchParamsExtended(decodeURIComponent(query))
    : new URLSearchParamsExtended();

  params.forEach((param) => {
    if (param.value === '') {
      searchParams.delete(param.name);
    } else {
      searchParams.set(param.name, param.value);
    }
  });

  return searchParams;
};
