import { IPagination } from '@ready/dashboardv2api.contracts';
import { ITemplateMenuItemGroup, WithAssignedLocationsCount } from '@ready/menu.core';
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import { getSharedItemGroupsThunk } from './thunks';

interface State {
  itemGroups: WithAssignedLocationsCount<ITemplateMenuItemGroup>[];
  pagination?: IPagination;
  loading: boolean;
}

const initialState: State = {
  loading: true,
  itemGroups: [],
};

const itemGroupListSlice = createSlice({
  name: 'itemGroups/list',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<State>) => {
    builder.addCase(getSharedItemGroupsThunk.pending, (state: State) => {
      state.loading = true;
    });
    builder.addCase(getSharedItemGroupsThunk.fulfilled, (state: State, action) => {
      state.loading = false;
      const { items, ...pagination } = action.payload;
      state.itemGroups = items;
      state.pagination = pagination;
    });

    builder.addCase(getSharedItemGroupsThunk.rejected, (state: State) => {
      state.loading = false;
    });
  },
});

export { itemGroupListSlice };
