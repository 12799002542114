import React from 'react';

enum ResponsiveBreakpointQueries {
  Tablet = '(max-width: 1100px)',
  Mobile = '(max-width: 600px)',
}

export enum ResponsiveBreakpoint {
  Desktop,
  Tablet,
  Mobile,
}

const useResponsiveBreakpoint = () => {
  const mobileMatch = React.useRef(window.matchMedia(ResponsiveBreakpointQueries.Mobile));
  const [isMobileMatch, setIsMobileMatch] = React.useState(mobileMatch.current.matches);

  const tabletMatch = React.useRef(window.matchMedia(ResponsiveBreakpointQueries.Tablet));
  const [isTabletMatch, setIsTabletMatch] = React.useState(tabletMatch.current.matches);

  const handleMobileMediaQueryListEvent = React.useCallback((event: MediaQueryListEvent) => {
    setIsMobileMatch(event.matches);
  }, []);

  const handleTabletMediaQueryListEvent = React.useCallback((event: MediaQueryListEvent) => {
    setIsTabletMatch(event.matches);
  }, []);

  React.useEffect(() => {
    const currentMobileMatch = mobileMatch.current;
    const currentTabletMatch = tabletMatch.current;

    currentMobileMatch.addListener(handleMobileMediaQueryListEvent);
    currentTabletMatch.addListener(handleTabletMediaQueryListEvent);

    return () => {
      currentMobileMatch.removeListener(handleMobileMediaQueryListEvent);
      currentTabletMatch.removeListener(handleTabletMediaQueryListEvent);
    };
  }, [handleMobileMediaQueryListEvent, handleTabletMediaQueryListEvent, mobileMatch, tabletMatch]);

  let breakpoint = ResponsiveBreakpoint.Desktop;

  if (isTabletMatch) breakpoint = ResponsiveBreakpoint.Tablet;

  if (isMobileMatch) breakpoint = ResponsiveBreakpoint.Mobile;

  return {
    breakpoint,
    isMobile: breakpoint === ResponsiveBreakpoint.Mobile,
    isTablet: breakpoint === ResponsiveBreakpoint.Tablet,
    isDesktop: breakpoint === ResponsiveBreakpoint.Desktop,
  };
};

export default useResponsiveBreakpoint;
