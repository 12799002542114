import produce from 'immer';
import { Action } from '../../redux/types';
import {
  modifierGroupsState,
  newModifierGroup,
  newModifierGroupRemoteValidation,
  newModifierGroupValidation,
} from './ModifierGroupsState';
import { MODIFIER_GROUPS_ACTIONS } from './ModifierGroupsActions';
import InfiniteScrollingListStateHelper from '../../utils/InfiniteScrollingList/InfiniteScrollingListStateHelper';

export const modifierGroupsReducer = (state = modifierGroupsState, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case MODIFIER_GROUPS_ACTIONS.LOAD_MENU_BUILDER_MODIFIER_GROUPS_BEGIN:
        draft.loading = true;
        draft.items = [];
        return draft;

      case MODIFIER_GROUPS_ACTIONS.LOAD_MENU_BUILDER_MODIFIER_GROUPS_SUCCESS:
        draft.loading = false;
        draft.pagination.start = action.payload.start;
        draft.pagination.length = action.payload.length;
        draft.pagination.limit = action.payload.limit;
        draft.pagination.total = action.payload.total;
        draft.items = action.payload.items;
        return draft;

      case MODIFIER_GROUPS_ACTIONS.LOAD_MENU_BUILDER_MODIFIER_GROUPS_ERROR:
        draft.loading = false;
        return draft;

      case MODIFIER_GROUPS_ACTIONS.STORE_MENU_BUILDER_MODIFIER_GROUPS_FILTERS:
        draft.query = action.payload.query;
        draft.page = action.payload.page;
        return draft;

      case MODIFIER_GROUPS_ACTIONS.LOAD_MENU_BUILDER_MODIFIER_GROUP_BEGIN:
        draft.modifierGroupForm.loading = true;
        draft.modifierGroupForm.editing = false;
        draft.modifierGroupForm.processing = false;
        draft.modifierGroupForm.modifierGroup = newModifierGroup();
        draft.modifierGroupForm.validation = newModifierGroupValidation();
        return draft;

      case MODIFIER_GROUPS_ACTIONS.LOAD_MENU_BUILDER_MODIFIER_GROUP_SUCCESS:
        draft.modifierGroupForm.loading = false;
        draft.modifierGroupForm.cache = action.payload;
        draft.modifierGroupForm.modifierGroup = action.payload;
        return draft;

      case MODIFIER_GROUPS_ACTIONS.LOAD_MENU_BUILDER_MODIFIER_GROUP_ERROR:
        draft.modifierGroupForm.loading = false;
        return draft;

      case MODIFIER_GROUPS_ACTIONS.DELETE_MENU_BUILDER_MODIFIER_GROUP_REQUESTED:
        draft.deleteRequested = action.payload.show;
        draft.modifierGroupToDelete = action.payload.modifierGroup;
        return draft;

      case MODIFIER_GROUPS_ACTIONS.DELETE_MENU_BUILDER_MODIFIER_GROUP_BEGIN:
        draft.deleteProcessing = true;
        draft.deleteError = false;
        return draft;

      case MODIFIER_GROUPS_ACTIONS.DELETE_MENU_BUILDER_MODIFIER_GROUP_SUCCESS:
        draft.deleteRequested = false;
        draft.deleteProcessing = false;
        draft.deletedModifierGroup = draft.modifierGroupToDelete;
        draft.modifierGroupToDelete = undefined;
        return draft;

      case MODIFIER_GROUPS_ACTIONS.DELETE_MENU_BUILDER_MODIFIER_GROUP_ERROR:
        draft.deleteProcessing = false;
        draft.deleteError = true;
        return draft;

      case MODIFIER_GROUPS_ACTIONS.PREPARE_MENU_BUILDER_NEW_MODIFIER_GROUP:
        draft.modifierGroupForm.loading = false;
        draft.modifierGroupForm.editing = true;
        draft.modifierGroupForm.processing = false;
        draft.modifierGroupForm.saved = false;
        draft.modifierGroupForm.cancelled = false;
        draft.modifierGroupForm.cache = action.payload;
        draft.modifierGroupForm.modifierGroup = action.payload;
        draft.modifierGroupForm.validation = newModifierGroupValidation();
        return draft;

      case MODIFIER_GROUPS_ACTIONS.CANCEL_MENU_BUILDER_NEW_MODIFIER_GROUP:
        draft.modifierGroupForm.cancelled = true;
        return draft;

      case MODIFIER_GROUPS_ACTIONS.RESET_MENU_BUILDER_MODIFIER_GROUP_FORM:
        draft.modifierGroupForm.editing = false;
        draft.modifierGroupForm.processing = false;
        draft.modifierGroupForm.saved = false;
        draft.modifierGroupForm.cancelled = false;
        draft.modifierGroupForm.cache = action.payload;
        draft.modifierGroupForm.modifierGroup = action.payload;
        draft.modifierGroupForm.validation = newModifierGroupValidation();
        draft.modifierGroupForm.remoteValidation = newModifierGroupRemoteValidation();
        return draft;

      case MODIFIER_GROUPS_ACTIONS.UPDATE_MENU_BUILDER_MODIFIER_GROUP_NAME:
        draft.modifierGroupForm.modifierGroup.name = action.payload;
        draft.modifierGroupForm.validation.validated = false;
        draft.modifierGroupForm.validation.name = { hasError: false, errorMessage: '' };
        return draft;

      case MODIFIER_GROUPS_ACTIONS.UPDATE_MENU_BUILDER_MODIFIER_GROUP_DISPLAY_NAME:
        draft.modifierGroupForm.modifierGroup.displayName = action.payload;
        return draft;

      case MODIFIER_GROUPS_ACTIONS.UPDATE_MENU_BUILDER_MODIFIER_GROUP_GUEST_INSTRUCTIONS:
        draft.modifierGroupForm.modifierGroup.guestInstructions = action.payload;
        return draft;

      case MODIFIER_GROUPS_ACTIONS.UPDATE_MENU_BUILDER_MODIFIER_GROUP_MINIMUM:
        draft.modifierGroupForm.modifierGroup.constraints.min = action.payload;
        return draft;

      case MODIFIER_GROUPS_ACTIONS.UPDATE_MENU_BUILDER_MODIFIER_GROUP_MAXIMUM:
        draft.modifierGroupForm.modifierGroup.constraints.max = action.payload;
        return draft;

      case MODIFIER_GROUPS_ACTIONS.VALIDATE_MENU_BUILDER_MODIFIER_GROUP:
        draft.modifierGroupForm.validation = action.payload;
        return draft;

      case MODIFIER_GROUPS_ACTIONS.VALIDATE_MENU_BUILDER_MODIFIER_OPTION:
        draft.modifierGroupForm.optionsValidation = action.payload;
        return draft;

      case MODIFIER_GROUPS_ACTIONS.SAVE_MENU_BUILDER_MODIFIER_GROUP_BEGIN:
        draft.modifierGroupForm.processing = true;
        draft.modifierGroupForm.remoteValidation = newModifierGroupRemoteValidation();
        return draft;

      case MODIFIER_GROUPS_ACTIONS.SAVE_MENU_BUILDER_MODIFIER_GROUP_SUCCESS:
        draft.modifierGroupForm.editing = false;
        draft.modifierGroupForm.processing = false;
        draft.modifierGroupForm.saved = true;
        draft.modifierGroupForm.modifierGroup = action.payload;
        draft.modifierGroupForm.validation = newModifierGroupValidation();
        return draft;

      case MODIFIER_GROUPS_ACTIONS.SAVE_MENU_BUILDER_MODIFIER_GROUP_ERROR:
        draft.modifierGroupForm.processing = false;
        draft.modifierGroupForm.validation = newModifierGroupValidation();
        draft.modifierGroupForm.remoteValidation = action.payload;
        return draft;

      case MODIFIER_GROUPS_ACTIONS.EDIT_MENU_BUILDER_MODIFIER_GROUP:
        draft.modifierGroupForm.editing = true;
        return draft;

      case MODIFIER_GROUPS_ACTIONS.ADD_OPTION_TO_MODIFIER_GROUP:
        draft.modifierGroupForm.selectedMenuItemIds.push(action.payload.itemId);
        draft.modifierGroupForm.modifierGroup.options.push({
          constraints: {
            max: state.modifierGroupForm.modifierGroup.constraints.max,
          },
          ...action.payload,
          sortOrder: draft.modifierGroupForm.modifierGroup.options.length,
        });
        return draft;

      case MODIFIER_GROUPS_ACTIONS.REMOVE_OPTION_FROM_MODIFIER_GROUP:
        draft.modifierGroupForm.selectedMenuItemIds = draft.modifierGroupForm.selectedMenuItemIds.filter(
          (id) => id !== action.payload
        );
        const modifierOptionsForRemove = draft.modifierGroupForm.modifierGroup.options;
        const modifierOptionIndex = modifierOptionsForRemove.findIndex(
          (modifierOption) => modifierOption.itemId === action.payload
        );
        if (modifierOptionIndex > -1) {
          modifierOptionsForRemove.splice(modifierOptionIndex, 1);
        }
        return draft;

      case MODIFIER_GROUPS_ACTIONS.MOVE_MODIFIER_OPTION_IN_MODIFIER_GROUP:
        const modifierOptionsForMove = draft.modifierGroupForm.modifierGroup.options;
        const movedModifierOption = modifierOptionsForMove.splice(action.payload.sourceIndex, 1)[0];
        modifierOptionsForMove.splice(action.payload.destinationIndex, 0, movedModifierOption);
        modifierOptionsForMove.forEach((option, index) => (option.sortOrder = index));
        return draft;

      case MODIFIER_GROUPS_ACTIONS.UPDATE_MODIFIER_OPTION_VISIBILITY_IN_MODIFIER_GROUP:
        const modifierOptionForUpdate = draft.modifierGroupForm.modifierGroup.options.find(
          (modifierOption) => modifierOption.itemId === action.payload.id
        );
        if (modifierOptionForUpdate) {
          modifierOptionForUpdate.visibility = action.payload.visibility;
        }
        return draft;

      case MODIFIER_GROUPS_ACTIONS.UPDATE_MODIFIER_OPTION_IS_DEFAULT_IN_MODIFIER_GROUP:
        const modifierOptionDefaultsForUpdate = draft.modifierGroupForm.modifierGroup.options.find(
          (modifierOption) => modifierOption.itemId === action.payload.id
        );
        if (modifierOptionDefaultsForUpdate) {
          modifierOptionDefaultsForUpdate.isDefault = action.payload.isDefault;
        }
        return draft;

      case MODIFIER_GROUPS_ACTIONS.UPDATE_MODIFIER_OPTION_MAX_VALUE_IN_MODIFIER_GROUP:
        const modifierOptionMaxValueForUpdate = draft.modifierGroupForm.modifierGroup.options.find(
          (modifierOption) => modifierOption.itemId === action.payload.id
        );
        if (modifierOptionMaxValueForUpdate) {
          modifierOptionMaxValueForUpdate.constraints = { max: action.payload.max };
        }
        return draft;

      case MODIFIER_GROUPS_ACTIONS.UPDATE_ALLOW_MULTIPLES_MODIFIER_OPTION_IN_MODIFIER_GROUP:
        draft.modifierGroupForm.modifierGroup.allowMultiplesOfEachOption = action.payload.allowMultiplesOfEachOption;
        return draft;

      case MODIFIER_GROUPS_ACTIONS.UPDATE_MENU_BUILDER_MODIFIER_GROUP_SUCCESS:
        draft.modifierGroupForm.editing = false;
        draft.modifierGroupForm.processing = false;
        draft.modifierGroupForm.cache = action.payload;
        draft.modifierGroupForm.modifierGroup = action.payload;
        draft.modifierGroupForm.validation = newModifierGroupValidation();
        return draft;

      case MODIFIER_GROUPS_ACTIONS.PREPARE_MENU_BUILDER_MODIFIER_GROUPS_MODAL:
        InfiniteScrollingListStateHelper.prepareState(
          draft.modifierGroupSelection,
          action.payload.visible,
          action.payload.selectedIds
        );
        draft.modifierGroupSelection.parentModifierOptionId = action.payload.parentModifierOptionId;
        return draft;

      case MODIFIER_GROUPS_ACTIONS.SELECT_MODIFIER_GROUP_IN_MODIFIER_GROUPS_MODAL:
        InfiniteScrollingListStateHelper.selectItemState(draft.modifierGroupSelection, action.payload);
        return draft;

      case MODIFIER_GROUPS_ACTIONS.DESELECT_MODIFIER_GROUP_IN_MODIFIER_GROUPS_MODAL:
        InfiniteScrollingListStateHelper.unselectItemState(draft.modifierGroupSelection, action.payload);
        return draft;

      case MODIFIER_GROUPS_ACTIONS.SET_MENU_BUILDER_MODIFIER_GROUPS_MODAL_QUERY:
        InfiniteScrollingListStateHelper.filterChangedState(draft.modifierGroupSelection, action.payload);
        return draft;

      case MODIFIER_GROUPS_ACTIONS.LOAD_MENU_BUILDER_MODIFIER_GROUPS_FOR_INFINITE_SCROLL_BEGIN:
        InfiniteScrollingListStateHelper.loadingState(draft.modifierGroupSelection);
        return draft;

      case MODIFIER_GROUPS_ACTIONS.LOAD_MENU_BUILDER_MODIFIER_GROUPS_FOR_INFINITE_SCROLL_SUCCESS:
        InfiniteScrollingListStateHelper.loadedState(draft.modifierGroupSelection, action.payload);
        return draft;

      case MODIFIER_GROUPS_ACTIONS.LOAD_MENU_BUILDER_MODIFIER_GROUPS_FOR_INFINITE_SCROLL_ERROR:
        InfiniteScrollingListStateHelper.loadFailedState(draft.modifierGroupSelection);
        return draft;

      case MODIFIER_GROUPS_ACTIONS.LOAD_MORE_MENU_BUILDER_MODIFIER_GROUPS_FOR_INFINITE_SCROLL_BEGIN:
        InfiniteScrollingListStateHelper.loadingMoreState(draft.modifierGroupSelection);
        return draft;

      case MODIFIER_GROUPS_ACTIONS.LOAD_MORE_MENU_BUILDER_MODIFIER_GROUPS_FOR_INFINITE_SCROLL_SUCCESS:
        InfiniteScrollingListStateHelper.loadedMoreState(draft.modifierGroupSelection, action.payload);
        return draft;

      case MODIFIER_GROUPS_ACTIONS.LOAD_MORE_MENU_BUILDER_MODIFIER_GROUPS_FOR_INFINITE_SCROLL_NO_RESULTS:
        InfiniteScrollingListStateHelper.loadMoreNoItemsState(draft.modifierGroupSelection);
        return draft;

      case MODIFIER_GROUPS_ACTIONS.LOAD_MORE_MENU_BUILDER_MODIFIER_GROUPS_FOR_INFINITE_SCROLL_ERROR:
        InfiniteScrollingListStateHelper.loadMoreFailedState(draft.modifierGroupSelection);
        return draft;

      case MODIFIER_GROUPS_ACTIONS.ADD_MENU_BUILDER_NESTED_MODIFIER_GROUP_TO_MODIFIER_OPTION:
        InfiniteScrollingListStateHelper.selectItemState(draft.modifierGroupSelection, action.payload._id);
        const parentModifierOptionToAddTo = draft.modifierGroupForm.modifierGroup.options.find(
          (option) => option.itemId === draft.modifierGroupSelection.parentModifierOptionId
        );
        if (parentModifierOptionToAddTo) {
          if (!parentModifierOptionToAddTo.nestedGroups) {
            parentModifierOptionToAddTo.nestedGroups = [];
          }
          parentModifierOptionToAddTo.nestedGroups.push({
            ...action.payload,
            sortOrder: parentModifierOptionToAddTo.nestedGroups.length,
          });
        }
        return draft;

      case MODIFIER_GROUPS_ACTIONS.REMOVE_MENU_BUILDER_NESTED_MODIFIER_GROUP_FROM_MODIFIER_OPTION:
        InfiniteScrollingListStateHelper.unselectItemState(
          draft.modifierGroupSelection,
          action.payload.nestedModifierGroupId
        );
        const parentModifierOptionToRemoveFrom = draft.modifierGroupForm.modifierGroup.options.find(
          (option) => option.itemId === action.payload.parentModifierOptionId
        );
        if (parentModifierOptionToRemoveFrom) {
          if (!parentModifierOptionToRemoveFrom.nestedGroups) {
            parentModifierOptionToRemoveFrom.nestedGroups = [];
          }
          parentModifierOptionToRemoveFrom.nestedGroups = parentModifierOptionToRemoveFrom.nestedGroups.filter(
            (nestedGroup) => nestedGroup._id !== action.payload.nestedModifierGroupId
          );
        }
        return draft;

      case MODIFIER_GROUPS_ACTIONS.MOVE_MODIFIER_GROUP_IN_MODIFIER_OPTION:
        const optionToReorder = draft.modifierGroupForm.modifierGroup.options.find(
          (option) => option.itemId === action.payload.optionId
        );
        if (optionToReorder && optionToReorder.nestedGroups) {
          const movedModifierOption = optionToReorder.nestedGroups.splice(action.payload.sourceIndex, 1)[0];
          optionToReorder.nestedGroups.splice(action.payload.destinationIndex, 0, movedModifierOption);
          optionToReorder.nestedGroups.forEach((option, index) => (option.sortOrder = index));
        }
        return draft;

      default:
        return draft;
    }
  });
};
