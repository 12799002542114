import config from '../../config';
import { ILocationBilling } from '@ready/dashboardv2api.contracts';
import executeApiAsync from '../../services/utils/executeApiAsync';

export default class LocationBillingService {
  static BASE_URL = `${config.readyDashboardApiEndpoint}/admins`;

  public static getLocationBillingByLocationId = async (locationId: string): Promise<ILocationBilling> => {
    const url = `${LocationBillingService.BASE_URL}/locations/${locationId}/billing`;
    return await executeApiAsync<ILocationBilling>(url);
  };

  public static updateLocationBilling = async (
    locationId: string,
    params: ILocationBilling
  ): Promise<ILocationBilling> => {
    const url = `${LocationBillingService.BASE_URL}/locations/${locationId}/billing`;
    return await executeApiAsync<ILocationBilling>(url, {
      method: 'PUT',
      body: JSON.stringify(params),
    });
  };
}
