import { ILocationPromoCodesConfig } from '@ready/dashboardv2api.contracts';

export interface ILocationPromoCodes {
  config: ILocationPromoCodesConfig;
}

export const locationPromoCodes = {
  config: {
    promoCodesEnabled: false,
  },
} as ILocationPromoCodes;
