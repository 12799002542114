import React from 'react';
import { Switch, Route, useParams, useRouteMatch } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import {
  fetchOrderList,
  fetchLocationDetails,
  initOrdersList,
  initLocationDetails,
} from '../../redux/actions/orders/orderListActions';
import UpcomingOrdersListPage from './UpcomingOrdersListPage';
import CompletedOrdersListPage from './CompletedOrdersListPage';
import CancelledOrdersListPage from './CancelledOrdersListPage';
import { ContextParams } from '../../types/ContextParams.interface';
import { usePageTitle } from '../../hooks';

interface PageParams extends ContextParams {
  day: string | undefined;
}

const OrdersListPageRoutes = (props: ReduxProps) => {
  usePageTitle('Orders');
  const { fetchOrderList, fetchLocationDetails, initOrdersList, initLocationDetails } = props;
  const { contextId, locationId, day } = useParams<PageParams>();
  const { path } = useRouteMatch();

  React.useEffect(() => {
    (async () => {
      await fetchOrderList(contextId, locationId, day);
    })();
    return () => {
      initOrdersList();
    };
  }, [fetchOrderList, initOrdersList, locationId, contextId, day]);

  React.useEffect(() => {
    (async () => {
      await fetchLocationDetails(contextId, locationId);
    })();
    return () => {
      initLocationDetails();
    };
  }, [contextId, fetchLocationDetails, initLocationDetails, locationId]);

  return (
    <Switch>
      <Route path={`${path}/upcoming`} component={UpcomingOrdersListPage} />
      <Route path={`${path}/complete`} component={CompletedOrdersListPage} />
      <Route path={`${path}/cancelled`} component={CancelledOrdersListPage} />
    </Switch>
  );
};

const actionCreators = {
  fetchOrderList,
  fetchLocationDetails,
  initOrdersList,
  initLocationDetails,
};

const connector = connect(null, actionCreators);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(OrdersListPageRoutes);
