import React from 'react';

interface TransactionResultProps {
  transactionSuccess: boolean;
  email?: string;
}

const TransactionResult = (props: TransactionResultProps) => {
  const { transactionSuccess, email } = props;

  return (
    <div className='reset-password__transaction-result'>
      <h3>{transactionSuccess ? 'Request Sent!' : 'Error'}</h3>
      {transactionSuccess ? (
        <p className='reset-password__transaction-result__paragraph'>
          A password reset has been sent to <b>{email}</b>. Please check your email for further instructions. If you do
          not receive an email, please contact <a href='https://readytopay.com/faq'>Ready Support</a>.{' '}
        </p>
      ) : (
        <p className='reset-password__transaction-result__paragraph'>This is an error message</p>
      )}
    </div>
  );
};

export default TransactionResult;
