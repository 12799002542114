import React from 'react';

import { Modal, ModalBody, ModalFooter, ModalHeader } from '../../../components/Modal';

export interface RefundGiftCardDialogProps {
  loading: boolean;
  errorMessage?: string;
  completed?: boolean;
  setShowModal: (showModal: boolean) => void;
  handleRefund: () => void;
}

const RefundGiftCardDialog = (props: RefundGiftCardDialogProps): JSX.Element => {
  const { loading, errorMessage = '', completed = false, setShowModal, handleRefund } = props;

  React.useEffect(() => {
    if (completed || errorMessage) {
      setShowModal(false);
    }
  }, [completed, errorMessage, setShowModal]);

  return (
    <Modal loading={loading} setShowModal={setShowModal}>
      <ModalHeader headerLabel='Refund Gift Card' setShowModal={setShowModal} />
      <ModalBody>
        <p>Are you sure you want to refund this payment? This action cannot be undone.</p>
      </ModalBody>
      <ModalFooter
        loading={loading}
        primaryLabel='Issue Refund'
        primaryActionHandler={() => handleRefund()}
        secondaryLabel='Cancel'
        secondaryActionHandler={() => setShowModal(false)}
      />
    </Modal>
  );
};

export default RefundGiftCardDialog;
