import React from 'react';
// components
import { FormControl } from '../../../components/Form';
import SelectFilter, { Option } from '../../../components/SelectFilter/SelectFilter';
import TextInput from '../../../components/TextInput/TextInput';
import Checkbox from '../../../components/Checkbox/Checkbox';
import { IPosSettingsPanel } from '../../redux/PanelPosSettingsState';
import { LOCATION_SETTINGS_ACTIONS } from '../../redux/LocationSettingsActions';
import { LIST_OPTIONS } from '../../../utils/selectListUtils/listOptions';
import { IUpdateLocationParam } from '../LocationsEditPage';
import mapSelectOption from 'utils/selectListUtils/mapSelectOption';

interface Props {
  isProcessing: boolean;
  updateLocationParam: IUpdateLocationParam;
  posSettingsPanel: IPosSettingsPanel;
  handlePosEmployeeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handlePosOrderTypeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handlePosRevenueCenterChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handlePosTenderTypeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const posTypeOptions: Option[] = LIST_OPTIONS.posType;

const OmnivorePOS = (props: Props) => {
  const { isProcessing, updateLocationParam, posSettingsPanel } = props;
  const handlePosTypeChange = (data: Option) => {
    updateLocationParam(data.value, LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_TYPE);
  };

  return (
    <>
      <FormControl label='POS Type'>
        <SelectFilter
          placeholder='Select a POS Type'
          options={posTypeOptions}
          loading={isProcessing}
          onChange={handlePosTypeChange}
          value={mapSelectOption(posTypeOptions, posSettingsPanel.optionsOmnivore.omnivorePosType)}
        />
      </FormControl>
      <FormControl label='Omnivore Location ID'>
        <TextInput
          placeholder='Enter the Location ID from Omnivore'
          onChange={(event) => {
            updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_LOCATION_ID);
          }}
          value={posSettingsPanel.optionsOmnivore.omnivoreLocationId}
          loading={isProcessing}
        />
      </FormControl>
      <FormControl label='POS Specific Settings'>
        <div className='control--stacked'>
          <Checkbox
            label='This POS supports tables'
            checked={posSettingsPanel.optionsOmnivore.omnivorePosSettingSupportTable}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_SETTINGS_SUPPORT_TABLE
              );
            }}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <Checkbox
            label='Requires Covers'
            checked={posSettingsPanel.optionsOmnivore.omnivorePosSettingsRequiresCovers}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_SETTINGS_REQUIRES_COVERS
              );
            }}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <Checkbox
            label='Requires modifier group price checks'
            checked={posSettingsPanel.optionsOmnivore.omnivorePosSettingsModifierGroup}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_SETTINGS_MODIFIER_GROUP
              );
            }}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <Checkbox
            label='Disable user info on Orders'
            checked={posSettingsPanel.optionsOmnivore.omnivorePosSettingsDisableUserInfo}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_SETTINGS_DISABLE_USER_INFO
              );
            }}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <Checkbox
            label='Send item & comment separately'
            checked={posSettingsPanel.optionsOmnivore.omnivorePosSettingsSendAndComment}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_SETTINGS_SEND_AND_COMMENT
              );
            }}
            loading={isProcessing}
            additionalHelp='Required for Squirrel. This is a global setting that will apply to all cases where fake POS items are used (ex. Guest Info, Order Time)'
          />
        </div>
      </FormControl>
      <FormControl label='Employee'>
        <TextInput
          placeholder='Employee'
          onChange={(event) => {
            updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_EMPLOYEE);
          }}
          value={posSettingsPanel.optionsOmnivore.posEmployee}
          loading={isProcessing}
        />
      </FormControl>
      <FormControl label='Order Type'>
        <TextInput
          placeholder='Order Type'
          onChange={(event) => {
            updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_ORDER_TYPE);
          }}
          value={posSettingsPanel.optionsOmnivore.posOrderType}
          loading={isProcessing}
        />
      </FormControl>
      <FormControl label='Default Revenue Center'>
        <TextInput
          placeholder='Default Revenue Center'
          onChange={(event) => {
            updateLocationParam(
              event.target.value,
              LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_DEFAULT_REVENUE_CENTER
            );
          }}
          value={posSettingsPanel.optionsOmnivore.posRevenueCenter}
          loading={isProcessing}
        />
      </FormControl>

      <FormControl label='Price Check Table'>
        <TextInput
          placeholder='Price Check Table'
          onChange={(event) => {
            updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_PRICE_CHECK_TABLE);
          }}
          value={posSettingsPanel.optionsOmnivore.posPriceCheckTable}
          loading={isProcessing}
        />
      </FormControl>

      <FormControl label='Price Check Revenue Center'>
        <TextInput
          placeholder='Price Check Revenue Center'
          onChange={(event) => {
            updateLocationParam(
              event.target.value,
              LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_PRICE_CHECK_REVENUE_CENTER
            );
          }}
          value={posSettingsPanel.optionsOmnivore.posPriceCheckRevenueCenter}
          loading={isProcessing}
        />
      </FormControl>

      <FormControl label='Tender Type'>
        <TextInput
          placeholder='Tender Type'
          onChange={(event) => {
            updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_TENDER_TYPE);
          }}
          value={posSettingsPanel.optionsOmnivore.posTenderType}
          loading={isProcessing}
        />
      </FormControl>
      <FormControl label='Minimum Ready Time Buffer'>
        <TextInput
          placeholder='30'
          onChange={(event) => {
            updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_MINIMUM_READY_TIME);
          }}
          value={posSettingsPanel.optionsOmnivore.minimumReadyTime}
          loading={isProcessing}
        />
      </FormControl>
      <FormControl label='Card Specific Tender Types'>
        <div className='control--stacked'>
          <span className='form__control__sublabel'>Mastercard</span>
          <TextInput
            placeholder='Mastercard'
            onChange={(event) => {
              updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_MASTERCARD);
            }}
            value={posSettingsPanel.optionsOmnivore.posMastercard}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <span className='form__control__sublabel'>Visa</span>
          <TextInput
            placeholder='Visa'
            onChange={(event) => {
              updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_VISA);
            }}
            value={posSettingsPanel.optionsOmnivore.posVisa}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <span className='form__control__sublabel'>Amex</span>
          <TextInput
            placeholder='Amex'
            onChange={(event) => {
              updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_AMEX);
            }}
            value={posSettingsPanel.optionsOmnivore.posAmex}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <span className='form__control__sublabel'>Discover</span>
          <TextInput
            placeholder='Discover'
            onChange={(event) => {
              updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_DISCOVER);
            }}
            value={posSettingsPanel.optionsOmnivore.posDiscover}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <span className='form__control__sublabel'>JCB</span>
          <TextInput
            placeholder='JCB'
            onChange={(event) => {
              updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_JCB);
            }}
            value={posSettingsPanel.optionsOmnivore.posJcb}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <span className='form__control__sublabel'>Interac</span>
          <TextInput
            placeholder='Interac'
            onChange={(event) => {
              updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_INTERAC);
            }}
            value={posSettingsPanel.optionsOmnivore.posInterac}
            loading={isProcessing}
          />
        </div>
      </FormControl>
    </>
  );
};

export default OmnivorePOS;
