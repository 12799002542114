import React from 'react';
import styles from './DetailElement.module.scss';
import { useClassNames } from '../../utils/cssUtils';
import { Tooltip } from '../Tooltip/Tooltip';
import TextIcon, { Icon } from '../Icon/TextIcon';

export interface DetailElementProps {
  label: string;
  labelNoteComponent?: React.ReactNode;
  isLabelNoteColumn?: boolean;
  labelTooltip?: string;
  condensed?: boolean;
  children?: React.ReactNode;
  labelAdditionalStyles?: string;
}

const DetailElement = (props: DetailElementProps) => {
  const {
    label,
    labelTooltip,
    condensed = false,
    isLabelNoteColumn = false,
    children,
    labelAdditionalStyles = '',
    labelNoteComponent,
  } = props;

  return (
    <div className={styles.container}>
      <div
        className={useClassNames(
          [styles.label, labelAdditionalStyles, { condensed: condensed }, { isLabelNoteColumn: isLabelNoteColumn }],
          styles
        )}
      >
        {label}
        {labelNoteComponent}
        {labelTooltip && (
          <div className={styles.tooltip}>
            <Tooltip text={labelTooltip}>
              <TextIcon icon={Icon.InfoCircle} />
            </Tooltip>
          </div>
        )}
      </div>
      <div className={useClassNames([styles.content, { condensed: condensed }], styles)}>{children}</div>
    </div>
  );
};

export default DetailElement;
