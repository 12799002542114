import React from 'react';
import { useAuth0 } from '../context/Auth0';
import { useDispatch } from 'react-redux';
import { ISession } from '../redux/initialStates/session/session';
import {
  initializeSessionSuccess,
  initializeUserSession,
  initializeContextSession,
  initializePermissions,
  initializeSidebarOptions,
} from '../redux/actions/session/sessionActions';
import { updateModalVisible } from '../redux/actions/contextSwitcherActions/contextSwitcherActions';
import { useHistory } from 'react-router-dom';
/**
 useSession uses flags from redux states to go through a sequence of if statements IN ORDER. These flags rely on the aforementioned if statements to execute if it’s truthy. Otherwise, it would skip that if statement to the next one. E.g.

- if there is no userInformation, getUserInformation.
- if there is userInformation && !userCompanies, getUserCompanies.
- if there is userId && userCompanies && !userPermissions, getUserPermissions.
- if there isuserId && userCompanies && userPermissions && !userSideBarOptionsSet, getUserSideBarOptions.
 **/
const useSession = (session: ISession, contextId: string = '') => {
  // get auth0 resources
  const { user } = useAuth0();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const showCompanySelectionPage = session.showCompanySelectionPage && !contextId;

  React.useEffect(() => {
    if (!user || !user.sub || !session) return;

    // 1) initialize user session
    if (!session.userSession.userId) {
      dispatch(initializeUserSession(user));
    }

    // 2) initialize or update context session
    if (shouldInitOrUpdateContextSession(session)) {
      dispatch(initializeContextSession(contextId));
    }

    //3) only route if user has multi companies, and user
    //  is not an admin
    if (showCompanySelectionPage) {
      push('/companySelection');
    }

    // 4) get user permissions
    if (shouldInitPermissions(session)) {
      dispatch(initializePermissions(session.contextSession.id as string));
    }

    // 5) update sidebar available options
    if (shouldInitSidebarOptions(session)) {
      dispatch(initializeSidebarOptions(session.permissions.allPermissionsMap, session.contextSession));
    }

    // 6) finalize process
    if (shouldFinalizeProcess(session)) {
      dispatch(initializeSessionSuccess());
      dispatch(updateModalVisible(false));
    }
  }, [
    user,
    session.userSession,
    session.contextSession,
    session.permissions,
    session.sidebar,
    session,
    dispatch,
    contextId,
    push,
    showCompanySelectionPage,
  ]);
};

const shouldInitOrUpdateContextSession = (session: ISession): boolean => {
  return !session.userSession.userId && !session.contextSession.id && !session.contextSession.invalidContext;
};

const shouldInitPermissions = (session: ISession): boolean => {
  return (
    !!session.contextSession.id && !session.permissions.apiPermissionsRequested && !session.showCompanySelectionPage
  );
};

const shouldInitSidebarOptions = (session: ISession): boolean => {
  return !!session.contextSession.type && session.permissions.apiPermissionsRequested && !session.sidebar.optionsSet;
};

const shouldFinalizeProcess = (session: ISession): boolean => {
  return (
    (!!session.contextSession.id && session.permissions.apiPermissionsRequested && session.sidebar.optionsSet) ||
    session.contextSession.invalidContext
  );
};

export { useSession };
