import React from 'react';
import readyProgressSpinner from '../../styles/assets/ready-progress-spinner-gray.svg';
import { DropDownMenuOptionProps, DropDownMenuToggleProps } from './types';
import DropDownMenuToggle from './DropDownMenuToggle';
import useOutsideInteraction from '../../hooks/useOutsideInteraction';

const DropDownMenuOption = (props: DropDownMenuOptionProps) => {
  const { label, onClick, primary = false, active = false, disabled = false, loading = false } = props;

  const primaryClassName = primary ? 'drop-down-menu__option--primary' : '';
  const activeClassName = active ? 'drop-down-menu__option--active' : '';
  const disabledClassName = disabled ? 'drop-down-menu__option--disabled' : '';
  const loadingClassName = loading ? 'drop-down-menu__option--loading' : '';

  return (
    <button
      onClick={onClick}
      type='button'
      className={`drop-down-menu__option
        ${primaryClassName}
        ${activeClassName}
        ${disabledClassName}
        ${loadingClassName}`}
      disabled={disabled || loading}
    >
      {loading && <img src={readyProgressSpinner} alt='loading' />}
      {label}
    </button>
  );
};

// Type guard to check if the passed in options props is for toggles
const isToggleOptions = (
  options: DropDownMenuOptionProps[] | DropDownMenuToggleProps[]
): options is DropDownMenuToggleProps[] => {
  if (options.length < 1) return false;

  const firstToggleOption = options[0] as DropDownMenuToggleProps;

  return (
    firstToggleOption.label !== undefined &&
    firstToggleOption.onChange !== undefined &&
    firstToggleOption.checked !== undefined
  );
};

export interface DropDownMenuProps {
  options: DropDownMenuOptionProps[] | DropDownMenuToggleProps[];
  isVisible?: boolean;
  snapTo?: 'right' | 'left';
  onOutsideInteraction?: () => void;
  onOptionClicked?: () => void;
}

const DropDownMenu = (props: DropDownMenuProps) => {
  const { options, isVisible = false, snapTo = 'right', onOutsideInteraction = () => {}, onOptionClicked } = props;

  const visibleClassName = isVisible ? 'drop-down-menu__options--visible' : '';
  const snapToClassName = `drop-down-menu__options-snap-${snapTo}`;

  const dropdownRef = React.useRef<HTMLDivElement>(null);

  useOutsideInteraction(dropdownRef, onOutsideInteraction);

  return (
    <div className='drop-down-menu'>
      <div className={`drop-down-menu__options ${visibleClassName} ${snapToClassName}`} ref={dropdownRef}>
        {isToggleOptions(options)
          ? options.map((option) => <DropDownMenuToggle key={option?.label} {...option} />)
          : options
              .filter((option) => !option.hidden)
              .map((option: DropDownMenuOptionProps) => {
                return (
                  <DropDownMenuOption
                    key={option?.label}
                    {...option}
                    onClick={(event) => {
                      !!onOptionClicked && onOptionClicked();
                      option.onClick(event);
                    }}
                  />
                );
              })}
      </div>
    </div>
  );
};

export default DropDownMenu;
export * from './types';
