import React from 'react';
import styles from './ItemGalleryForm.module.scss';
import MenuItemGalleryEntryForm from 'menus/components/Menus/MenuItemGalleryEntryForm';

export type ItemGalleryFormProps = {
  companyId: string
  itemGalleryId: string
}

export function ItemGalleryForm(props: ItemGalleryFormProps) {
  const { companyId } = props

  return (
    <div className={styles.panel}>
      <MenuItemGalleryEntryForm companyId={companyId} />
    </div>
  )
}
