import React from 'react';

import { IMenuItem, MenuItemInactiveStates } from '@ready/menu.core';

import styles from './AddMenuItemsListItem.module.scss';
import MenuItemPriceBlock from './MenuItemPriceBlock';
import ErrorValue from '../../../components/ErrorValue/ErrorValue';
import { ContextParams } from 'types/ContextParams.interface';
import { useParams } from 'react-router-dom';
import { isSharedMenu } from 'sharedMenuItems/sharedMenuItems.utils';

export interface AddMenuItemsListItemProps {
  menuItem: IMenuItem;
  controls: JSX.Element;
}

const AddMenuItemsListItem = (props: AddMenuItemsListItemProps): JSX.Element => {
  const { menuItem, controls } = props;
  const { contextId: companyId, locationId } = useParams<ContextParams>();

  const draft = menuItem.inactiveStates.includes(MenuItemInactiveStates.draft);
  const deleted = menuItem.inactiveStates.includes(MenuItemInactiveStates.itemDeletedOnPOS);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.description}>
          <div className={styles.displayName}>{menuItem.displayName}</div>
          {!isSharedMenu(companyId, locationId) && (
            <div className={styles.pos}>
              {draft || deleted || menuItem.posItemId === undefined ? (
                <>
                  {deleted ? (
                    <ErrorValue text='POS Item Deleted' />
                  ) : (
                    <div className={styles.missing}>No Item Linked</div>
                  )}
                </>
              ) : (
                <>
                  <span className={styles.posItemId}>{menuItem.posItemId}</span>
                  <span className={styles.separator}>|</span>
                  <span className={styles.posItemCategories}>{(menuItem.posItemCategories ?? []).join(', ')}</span>
                  <span className={styles.separator}>{'>'}</span>
                  <span className={styles.posItemName}>{menuItem.posItemName}</span>
                </>
              )}
            </div>
          )}
        </div>

        {!isSharedMenu(companyId, locationId) && (
          <MenuItemPriceBlock
            price={menuItem.price}
            deleted={menuItem.inactiveStates.includes(MenuItemInactiveStates.priceDeletedOnPOS)}
            className={styles.price}
          />
        )}
      </div>

      <div className={styles.controls}>
        <div className={styles.button}>{controls}</div>
      </div>
    </div>
  );
};

export default AddMenuItemsListItem;
