import { IMenuGroup } from '@ready/menu.core';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

export const selectMenuConfigs = (state: RootState) => state.menuBuilder.menuConfigs.menuConfigs;

export const selectActiveMenuConfig = createSelector(selectMenuConfigs, (menuConfigs: IMenuGroup[]) =>
  menuConfigs.find((mg: IMenuGroup) => mg.isActive)
);

export const selectUserSelectedMenuConfigId = (state: RootState) =>
  state.menuBuilder.menuConfigs.userSelectedMenuConfigId;

export const selectUserSelectedMenuConfig = createSelector(
  selectMenuConfigs,
  selectUserSelectedMenuConfigId,
  (menuConfigs, userSelectedMenuConfigId) => menuConfigs.find((x) => x._id === userSelectedMenuConfigId)
);
