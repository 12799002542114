import React from 'react';
import InfoCardColumn from '../../../components/InfoCard/InfoCardColumn';
import OrderStatusButton from './OrderStatusButton';
import { OrderFulfillmentStatus } from '@ready/dashboardv2api.contracts';

interface StatusColumnProps {
  readOnly?: boolean;
  handleOrderStatusChange?: (orderStatus: OrderFulfillmentStatus) => void;
  additionalStyles?: string;
  width?: number | undefined;
  isProcessing: boolean;
  orderStatus: OrderFulfillmentStatus;
}

const StatusColumn = ({
  readOnly = false,
  handleOrderStatusChange = () => {},
  additionalStyles = '',
  width,
  isProcessing,
  orderStatus,
}: StatusColumnProps) => {
  return (
    <InfoCardColumn width={width} columnClassName={additionalStyles}>
      <OrderStatusButton
        orderStatus={orderStatus}
        readOnly={readOnly}
        isProcessing={isProcessing}
        onChange={(_orderStatus) => handleOrderStatusChange(_orderStatus)}
      />
    </InfoCardColumn>
  );
};

export default StatusColumn;
