import produce from 'immer';
import { Action } from '../../redux/types';
import { promoCodes, newPromoCodeForm, newPromoCodeValidation, newTimeSlotValidation } from './PromoCodesState';
import { PROMO_CODES_ACTIONS } from './PromoCodesActions';

export const promoCodesReducer = (state = promoCodes, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case PROMO_CODES_ACTIONS.LOAD_PROMO_CODES:
        draft.codes = action.payload;
        return draft;

      case PROMO_CODES_ACTIONS.LOAD_PROMO_CODE_BEGIN:
        draft.form.loading = true;
        return draft;

      case PROMO_CODES_ACTIONS.LOAD_PROMO_CODE_SUCCESS:
        draft.form.loading = false;
        draft.form.promoCode = action.payload;
        draft.form.validation = newPromoCodeValidation();
        action.payload.timeSlots.forEach((_: any) => draft.form.validation.timeSlots.push(newTimeSlotValidation()));
        return draft;

      case PROMO_CODES_ACTIONS.LOAD_PROMO_CODE_ERROR:
        draft.form.loading = false;
        return draft;

      case PROMO_CODES_ACTIONS.LOAD_PROMO_CODE_POS_DISCOUNTS:
        draft.form.discounts = action.payload;
        return draft;

      case PROMO_CODES_ACTIONS.UPDATE_PROMO_CODE_STATUS:
        draft.form.promoCode.enabled = action.payload;
        return draft;

      case PROMO_CODES_ACTIONS.UPDATE_PROMO_CODE_CODE:
        draft.form.promoCode.code = action.payload;
        return draft;

      case PROMO_CODES_ACTIONS.UPDATE_PROMO_CODE_DESCRIPTION:
        draft.form.promoCode.description = action.payload;
        return draft;

      case PROMO_CODES_ACTIONS.UPDATE_PROMO_CODE_POS_DISCOUNT:
        draft.form.promoCode.posDiscountId = action.payload;
        return draft;

      case PROMO_CODES_ACTIONS.UPDATE_PROMO_CODE_VALIDATION:
        draft.form.promoCode.validationType = action.payload;
        return draft;

      case PROMO_CODES_ACTIONS.UPDATE_PROMO_CODE_AUTO_ATTACH:
        draft.form.promoCode.autoAttach = action.payload;
        return draft;

      case PROMO_CODES_ACTIONS.UPDATE_PROMO_CODE_TIME_SLOT:
        draft.form.promoCode.timeSlots[action.payload.index] = action.payload.timeSlot;
        return draft;

      case PROMO_CODES_ACTIONS.UPDATE_PROMO_CODE_ADD_TIME_SLOT:
        draft.form.promoCode.timeSlots.push(action.payload);
        draft.form.validation.timeSlots.push(newTimeSlotValidation());
        return draft;

      case PROMO_CODES_ACTIONS.UPDATE_PROMO_CODE_REMOVE_TIME_SLOT:
        draft.form.promoCode.timeSlots.splice(action.payload, 1);
        draft.form.validation.timeSlots.splice(action.payload, 1);
        return draft;

      case PROMO_CODES_ACTIONS.VALIDATE_PROMO_CODE:
        draft.form.validated = true;
        draft.form.validation = action.payload;
        return draft;

      case PROMO_CODES_ACTIONS.SAVE_PROMO_CODE_BEGIN:
        draft.form.processing = true;
        draft.form.validated = false;
        draft.form.saved = false;
        return draft;

      case PROMO_CODES_ACTIONS.SAVE_PROMO_CODE_SUCCESS:
        draft.form.processing = false;
        draft.form.saved = true;
        draft.form.promoCode = action.payload;
        return draft;

      case PROMO_CODES_ACTIONS.SAVE_PROMO_CODE_ERROR:
        draft.form.processing = false;
        return draft;

      case PROMO_CODES_ACTIONS.PREPARE_DELETE_PROMO_CODE_MODAL:
        draft.form.deleting = action.payload;
        draft.form.deleted = false;
        return draft;

      case PROMO_CODES_ACTIONS.DELETE_PROMO_CODE_BEGIN:
        draft.form.processing = true;
        return draft;

      case PROMO_CODES_ACTIONS.DELETE_PROMO_CODE_SUCCESS:
        draft.form.processing = false;
        draft.form.deleted = true;
        return draft;

      case PROMO_CODES_ACTIONS.DELETE_PROMO_CODE_ERROR:
        draft.form.processing = false;
        return draft;

      case PROMO_CODES_ACTIONS.RESET_PROMO_CODE_FORM:
        draft.form = newPromoCodeForm();
        return draft;

      default:
        return draft;
    }
  });
};
