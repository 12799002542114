import { ITable, ITableAssignment } from '@ready/table.core';
import { compareTables } from './index';
import { SelectableTable } from '../redux/ServerAssignmentState';

interface PartitionTablesReturns {
  assignedTables: SelectableTable[];
  unassignedTables: SelectableTable[];
}

const partitionTables = (
  tables: (ITable | SelectableTable)[],
  tableAssignments: ITableAssignment[]
): PartitionTablesReturns => {
  const assignedTableIds: string[] = tableAssignments.reduce(
    (tableIds: string[], tableAssignment) => tableIds.concat(tableAssignment.tables.map((table) => table.id)),
    []
  );

  // If the tables is an array of ITable, they will need the 'selected' property
  // inserted to be a SelectableTable type
  const selectableTables: SelectableTable[] = tables.map(tableToSelectableTable);

  return {
    assignedTables: selectableTables.filter((table) => assignedTableIds.includes(table.id)).sort(compareTables),

    unassignedTables: selectableTables.filter((table) => !assignedTableIds.includes(table.id)).sort(compareTables),
  };
};

// Either converts ITable to SelectableTable, or passes through the SelectableTable
const tableToSelectableTable = (table: ITable | SelectableTable): SelectableTable =>
  isSelectableTableType(table) ? table : { ...table, selected: false };

// Checks that all of the tables are of type SelectableTable
const isSelectableTableType = (table: ITable | SelectableTable): table is SelectableTable =>
  (table as SelectableTable).selected !== undefined;

export default partitionTables;
