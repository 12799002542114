import { createAsyncThunk } from '@reduxjs/toolkit';
import { exportPromoCodesList, getPromoCodeSummary, getPermittedLocations } from '../promoCodeSummaryServices';
import { interceptErrorWithToastMessage } from '../../../redux/helpers/interceptErrorWithToastMessage';
import { showToastMessageOnFulfill } from '../../../redux/helpers/showToastMessageOnFulfill';

export const fetchPromoCodeSummaryThunk = createAsyncThunk(
  'promoCodeSummary/fetch',
  interceptErrorWithToastMessage(getPromoCodeSummary)
);

export const exportPromoCodeSummaryThunk = createAsyncThunk(
  'promoCodeSummary/export',
  interceptErrorWithToastMessage(showToastMessageOnFulfill(exportPromoCodesList, 'Success! Export complete.'))
);

export const fetchPermittedLocationsThunk = createAsyncThunk(
  'promoCodeSummary/permittedLocations',
  interceptErrorWithToastMessage(getPermittedLocations)
);
