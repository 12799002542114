import produce from 'immer';
import { Action } from '../../redux/types';
import { LOCATION_SETTINGS_ACTIONS } from './LocationSettingsActions';
import { featuresPanel } from './PanelFeaturesState';
import {
  IPickupHours,
  GuestInformationField,
  OrderThrottling,
  OrderIntegrationType,
} from '@ready/dashboardv2api.contracts';

export const featuresPanelReducer = (state = featuresPanel, action: Action) => {
  return produce(state, (draft) => {
    let day: keyof IPickupHours;
    let index;
    switch (action.type) {
      case LOCATION_SETTINGS_ACTIONS.SET_MOBILE_ORDERING_PANEL: {
        const posModifierSelection = draft.mobileOrdering.posModifierSelection;
        draft.mobileOrdering = { ...action.payload, posModifierSelection };
        return draft;
      }
      case LOCATION_SETTINGS_ACTIONS.TOGGLE_MOBILE_ORDERING_PANEL:
        draft.mobileOrdering.orderPickupEnabled = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.TOGGLE_MOBILE_ORDERING:
        draft.mobileOrdering.orderingEnabled = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.TOGGLE_ALCOHOL_ORDERING:
        draft.mobileOrdering.alcoholOrderingEnabled = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_ALCOHOL_POLICY:
        draft.mobileOrdering.alcoholPolicy = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_DISRUPTION_MESSAGE:
        draft.mobileOrdering.disruptionMessage = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_MENU_ANNOUNCEMENT:
        draft.mobileOrdering.menuAnnouncementsMessage = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_DELIVERY_DETAILS:
        draft.mobileOrdering.pickupInstructions = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_DELIVERY_LOCATION:
        draft.mobileOrdering.pickupLocationName = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_CHECKOUT_ANNOUNCEMENT:
        draft.mobileOrdering.checkoutAnnouncementMessage = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_NAVIGATION_TEXT:
        draft.mobileOrdering.orderConfirmedMessage = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_ORDER_WIDGET:
        draft.mobileOrdering.enableOrderLocationWidget = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_DEFERRED_PAYMENT:
        if (!action.payload) {
          draft.mobileOrdering.requireServerCreatedCheckBeforeGuestOrder = false;
          draft.mobileOrdering.orderDoesNotRequireImmediatePayment = action.payload;
        } else {
          draft.mobileOrdering.requireServerCreatedCheckBeforeGuestOrder = true;
          draft.mobileOrdering.orderDoesNotRequireImmediatePayment = action.payload;
        }
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_SMS_NOTIFICATIONS_ENABLED:
        draft.mobileOrdering.orderSmsEnabled = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_START_CHECK_BEFORE_GUESTS_ORDER:
        draft.mobileOrdering.requireServerCreatedCheckBeforeGuestOrder = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_GUEST_VALIDATION_METHOD:
        draft.mobileOrdering.openTabGuestPresenceValidationMethods = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_GUEST_PRESENCE_DURATION_MINUTES:
        if (action.payload || typeof action.payload === 'number') {
          draft.mobileOrdering.openTabGuestPresenceTargetScanDurationMinutes = Number(action.payload);
        } else {
          delete draft.mobileOrdering.openTabGuestPresenceTargetScanDurationMinutes;
        }
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_GUEST_PRESENCE_EXPIRE_MESSAGE:
        if (action.payload) {
          draft.mobileOrdering.openTabGuestPresenceTargetScanExpiryMessage = action.payload;
        } else {
          draft.mobileOrdering.openTabGuestPresenceTargetScanExpiryMessage = '';
        }
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_SINGLE_CHECK_POS:
        if (!action.payload) {
          draft.mobileOrdering.requireServerCreatedCheckBeforeGuestOrder = false;
        } else {
          draft.mobileOrdering.requireServerCreatedCheckBeforeGuestOrder = true;
        }
        draft.mobileOrdering.alwaysAppendOrdersToExistingCheck = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_ALLOW_ITEM_COMMENTS:
        draft.mobileOrdering.itemComments.enableItemComments = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_ITEM_COMMENTS_LENGTH:
        draft.mobileOrdering.itemComments.maxItemCommentLength = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_POS_MODIFIER:
        draft.mobileOrdering.posModifierSelection.selectedItem = action.payload;
        draft.mobileOrdering.itemComments.posModifierId = action.payload?.externalId;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_COMMENT_SUBMISSION:
        draft.mobileOrdering.itemComments.commentSubmissionMethod = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_ORDER_BY_EMAIL:
        draft.mobileOrdering.orderIntegration.emailSubmissionEnabled = action.payload;

        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_ORDER_EMAIL_ADDRESS:
        draft.mobileOrdering.orderIntegration.emailSubmissionAddress = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.GET_INIT_POS_MODIFIER_START:
        draft.mobileOrdering.posModifierSelection.loading = true;
        draft.mobileOrdering.posModifierSelection.error = false;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.GET_INIT_POS_MODIFIER_SUCCESS:
        draft.mobileOrdering.posModifierSelection.loading = false;
        draft.mobileOrdering.posModifierSelection.items = action.payload.modifiers;
        draft.mobileOrdering.posModifierSelection.selectedItem = action.payload.selectedModifier;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.GET_INIT_POS_MODIFIER_ERROR:
        draft.mobileOrdering.posModifierSelection.loading = false;
        draft.mobileOrdering.posModifierSelection.error = true;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.GET_POS_MODIFIERS_START:
        draft.mobileOrdering.posModifierSelection.loading = true;
        draft.mobileOrdering.posModifierSelection.error = false;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.GET_POS_MODIFIERS_SUCCESS:
        draft.mobileOrdering.posModifierSelection.loading = false;
        draft.mobileOrdering.posModifierSelection.items = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.GET_POS_MODIFIERS_ERROR:
        draft.mobileOrdering.posModifierSelection.loading = false;
        draft.mobileOrdering.posModifierSelection.error = true;
        return draft;
      // pay at table
      case LOCATION_SETTINGS_ACTIONS.SET_PAY_AT_TABLE_PANEL:
        draft.payAtTable = { ...action.payload };
        return draft;
      case LOCATION_SETTINGS_ACTIONS.TOGGLE_PAY_AT_TABLE_PANEL:
        draft.payAtTable.payAtTableEnabled = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_PAY_AT_TABLE_CONFIRMATION_MESSAGE:
        draft.payAtTable.paymentConfirmationMessage = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.TOGGLE_PAY_AT_TABLE_MULTI_CHECK_CONFIGURATION_OPTION:
        draft.payAtTable.allowMultiSelect = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_PAY_BY_ITEM:
        draft.payAtTable.payByItem = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_PAY_BY_SEAT:
        draft.payAtTable.payBySeat = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.TOGGLE_VERIFY_TICKET:
        draft.payAtTable.verifyTicket = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.TOGGLE_PAY_AT_TABLE_REQUIRE_EMAIL_ADDRESS_ON_CHECKOUT:
        draft.payAtTable.requireEmailAddressOnCheckout = action.payload;
        return draft;
      // digital menu
      case LOCATION_SETTINGS_ACTIONS.SET_DIGITAL_MENU_PANEL:
        draft.digitalMenu = { ...action.payload };
        return draft;
      case LOCATION_SETTINGS_ACTIONS.TOGGLE_DIGITAL_MENU_PANEL:
        if (action.payload === false) {
          draft.digitalMenu.enabled = false;
          draft.digitalMenu.menuMode = undefined;
        }
        draft.digitalMenu.digitalMenuFeatureEnabled = action.payload;
        draft.digitalMenu.isDirty = true;
        return draft;
      // Order ahead
      case LOCATION_SETTINGS_ACTIONS.SET_ORDER_AHEAD_PANEL:
        draft.orderAhead = { ...action.payload.orderAhead };
        draft.validation = { ...action.payload.validation };
        return draft;
      case LOCATION_SETTINGS_ACTIONS.TOGGLE_ORDER_AHEAD_STATUS:
        draft.orderAhead.enableOrderAhead = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_ORDER_AHEAD_ACCEPT_FOR:
        draft.orderAhead.acceptOrdersFor = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_ORDER_AHEAD_FUTURE_DAYS_LIMIT:
        draft.orderAhead.futureDaysLimit = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_ORDER_TIME_VIA:
        draft.orderAhead.submitOrderTimeVia!.type = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_ORDER_TIME_POS_ITEM_ID:
        draft.orderAhead.submitOrderTimeVia!.posItemId = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.SET_PICK_UP_HOURS:
        draft.orderAhead.pickUpHours = { ...action.payload };
        return draft;
      case LOCATION_SETTINGS_ACTIONS.SET_PICK_UP_HOURS_START:
        day = action.payload.day;
        draft.orderAhead.pickUpHours[day].start = action.payload.start;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.SET_PICK_UP_HOURS_END:
        day = action.payload.day;
        draft.orderAhead.pickUpHours[day].end = action.payload.end;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.SET_PICK_UP_HOURS_OFFSET:
        day = action.payload.day;
        draft.orderAhead.pickUpHours[day].offset = action.payload.offset;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.ADD_GUEST_INFO:
        const { newGuestField, newGuestFieldError } = action.payload;
        draft.orderAhead.guestInfoFields.push(newGuestField);
        draft.validation.orderAhead.guestInfoFields.errors.push(newGuestFieldError);
        return draft;
      case LOCATION_SETTINGS_ACTIONS.REMOVE_GUEST_INFO:
        const removeIndex = action.payload;
        const guestInfoFields = draft.orderAhead.guestInfoFields;
        const shouldUpdatePrimary =
          guestInfoFields.length > 1 && guestInfoFields[removeIndex].isPrimaryOnPaymentConfirmationScreen;
        draft.orderAhead.guestInfoFields.splice(removeIndex, 1);
        // update primary field if we are removing the current primary field
        if (shouldUpdatePrimary) {
          draft.orderAhead.guestInfoFields[0].isPrimaryOnPaymentConfirmationScreen = true;
        }
        draft.validation.orderAhead.guestInfoFields.errors.splice(removeIndex, 1);
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_GUEST_INFO_PRIMARY_FIELD:
        const primaryIndex = action.payload;
        const guestFields = [...draft.orderAhead.guestInfoFields];
        const updatedGuestFields = guestFields.map((guestInfoField: GuestInformationField, index: number) => {
          guestInfoField.isPrimaryOnPaymentConfirmationScreen = index === primaryIndex;
          return guestInfoField;
        });
        draft.orderAhead.guestInfoFields = updatedGuestFields;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_GUEST_INFO_FIELD_TYPE:
        index = action.payload.index;
        draft.orderAhead.guestInfoFields[index].fieldType = action.payload.value;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_GUEST_INFO_SENDING_METHOD:
        index = action.payload.index;
        draft.orderAhead.guestInfoFields[index].methodForSending = action.payload.value;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_GUEST_INFO_FIELD_NAME:
        index = action.payload.index;
        draft.orderAhead.guestInfoFields[index].fieldName = action.payload.value;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_GUEST_INFO_PLACEHOLDER:
        index = action.payload.index;
        draft.orderAhead.guestInfoFields[index].placeholder = action.payload.value;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_GUEST_INFO_MAX_CHAR:
        index = action.payload.index;
        draft.orderAhead.guestInfoFields[index].charLimit = action.payload.value;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_GUEST_INFO_REQUIRED_INPUT:
        index = action.payload.index;
        draft.orderAhead.guestInfoFields[index].inputIsRequired = action.payload.value;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_GUEST_INFO_POS_ITEM_ID:
        draft.orderAhead.guestInfoPosItemId = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.TOGGLE_ORDER_THROTTLING:
        if (!draft.orderAhead.orderThrottling) {
          draft.orderAhead.orderThrottling = {} as OrderThrottling;
          // default values
          draft.orderAhead.orderThrottling.amount = 5;
          draft.orderAhead.orderThrottling.durationMinutes = 5;
        }
        draft.orderAhead.orderThrottling.enabled = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_ORDER_THROTTLING_AMOUNT:
        if (!draft.orderAhead.orderThrottling) {
          draft.orderAhead.orderThrottling = {} as OrderThrottling;
          draft.orderAhead.orderThrottling.durationMinutes = 5;
        }
        draft.orderAhead.orderThrottling!.amount = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_ORDER_THROTTLING_TIME:
        if (!draft.orderAhead.orderThrottling) {
          draft.orderAhead.orderThrottling = {} as OrderThrottling;
          draft.orderAhead.orderThrottling.amount = 5;
        }
        draft.orderAhead.orderThrottling!.durationMinutes = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.SET_FAKE_MENU_ITEM_ID_ERROR:
        draft.validation.orderAhead.submitOrderTimeVia = { ...action.payload };
        return draft;
      case LOCATION_SETTINGS_ACTIONS.SET_PICK_UP_ERROR:
        draft.validation.orderAhead.pickUpHours = { ...action.payload };
        return draft;
      case LOCATION_SETTINGS_ACTIONS.SET_GUEST_INFO_FIELD_ERROR:
        draft.validation.orderAhead.guestInfoFields = { ...action.payload };
        return draft;
      case LOCATION_SETTINGS_ACTIONS.SET_MENU_ITEM_GUEST_INFO_ERROR:
        draft.validation.orderAhead.guestInfoPosItemId = { ...action.payload };
        return draft;
      case LOCATION_SETTINGS_ACTIONS.SET_ORDER_THROTTLE_MAX_ORDERS_ERROR:
        draft.validation.orderAhead.orderThrottling.amount = {
          ...action.payload,
        };
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_SUBMIT_ORDERS_TO_POS: {
        draft.mobileOrdering.orderIntegration.orderIntegrationType = action.payload;
        if (action.payload !== OrderIntegrationType.Unintegrated) {
          draft.mobileOrdering.orderIntegration.emailSubmissionEnabled = false;
        }
        return draft;
      }
      case LOCATION_SETTINGS_ACTIONS.SET_SERVER_FLOW_PANEL:
        draft.serverFlow = { ...action.payload };
        return draft;
      case LOCATION_SETTINGS_ACTIONS.TOGGLE_SERVER_FLOW_PANEL:
        draft.serverFlow.serverFlowFeatureEnabled = action.payload;
        // trigger dirty flag here is important to make sure the serverFlow is sent to the server
        draft.serverFlow.isDirty = true;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_SERVER_FLOW_PANEL_CHAT_ASSISTANT_TOGGLE:
        draft.serverFlow.chatAssistantFeatureEnabled = action.payload;
        draft.serverFlow.isDirty = true;
        return draft;
      default:
        return draft;
    }
  });
};
