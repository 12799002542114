import { IEmailMarketingListState } from './initialState';
import { IEmailMarketingListItem, PaginationResponse } from '@ready/dashboardv2api.contracts';
import { IPagedResponse } from '@ready/table.core';
import { ILocation } from '../../../services/types/ILocation.type';

export const fetchEmailMarketingListReducers = {
  pending: (state: IEmailMarketingListState) => {
    state.loading.emailMarketingList = true;
  },
  fulfilled: (state: IEmailMarketingListState, action: { payload: PaginationResponse<IEmailMarketingListItem> }) => {
    const { items, total, limit, start, length } = action.payload;
    state.loading.emailMarketingList = false;
    state.items = items || [];
    state.pagination = { total, limit, start, length };
  },
  rejected: (state: IEmailMarketingListState) => {
    state.loading.emailMarketingList = false;
    state.errors.emailMarketingList = 'Unable to retrieve email marketing list.';
  },
};

export const fetchPermittedLocationsReducers = {
  pending: (state: IEmailMarketingListState) => {
    state.loading.permittedLocations = true;
  },
  fulfilled: (state: IEmailMarketingListState, action: { payload: IPagedResponse<ILocation> }) => {
    state.loading.permittedLocations = false;
    state.permittedLocations = action.payload;
  },
  rejected: (state: IEmailMarketingListState) => {
    state.loading.permittedLocations = false;
  },
};
