import React, { useEffect, useState } from 'react';
import { FormControl } from 'components/Form';
import { Icon } from 'components/Icon/TextIcon';
import SelectFilter from 'components/SelectFilter/SelectFilter';
import TextInput from 'components/TextInput/TextInput';
import { useDispatch } from 'react-redux';
import Notification from '../../../../components/Notification/Notification';
import styles from './SimphonyPOS.module.scss';
import {
  getPosTenderTypes,
  resetSimphonyOptions,
  updateSimphonyPosSettings,
} from 'locations/redux/LocationSettingsActions';
import { useAppSelector } from 'redux/store';
import { useParams } from 'react-router-dom';
import mapSelectOption from 'utils/selectListUtils/mapSelectOption';
import { selectFormState } from 'redux/selectors/uiSelectors/uiSelectors';
import { ToggleEditWrapper } from 'components/ToggleEditWrapper/ToggleEditWrapper';

export const SimphonyPOS = ({ isProcessing }: { isProcessing: boolean }) => {
  const { id: locationId } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const {
    apiUrl,
    apiPassword,
    apiUsername,
    clientId,
    host,
    posAmex,
    posDiscover,
    posJcb,
    posMastercard,
    posVisa,
    posInterac,
    posTenderType,
    posEmployee,
    organization,
    location,
  } = useAppSelector((state) => state.location.posSettingsPanel.optionsSimphony2);
  const { tenderTypes } = useAppSelector((state) => state.location.posSettingsPanel.posOptions);
  const { isDirty } = useAppSelector(selectFormState);

  // We can assume that if a 'clientId' exists and 'apiPassword' is undefined, a config exists, and therefor a password exists.
  const passwordExists = !!clientId && apiPassword === undefined;
  const [isPasswordEditToggled, setIsPasswordEditToggled] = useState<boolean>(passwordExists);

  const dependentFieldsUnavailable = !apiUsername || !clientId || !host || !apiUrl;

  useEffect(() => {
    if (dependentFieldsUnavailable) {
      dispatch(resetSimphonyOptions());
    }
  }, [dependentFieldsUnavailable, dispatch]);

  useEffect(() => {
    dispatch(getPosTenderTypes(locationId));
  }, [dispatch, locationId]);

  // reset API Password on cancel changes button
  useEffect(() => {
    if (!isDirty) {
      setIsPasswordEditToggled(true);
    }
  }, [isDirty]);

  return (
    <>
      <FormControl label='Client ID *'>
        <TextInput
          placeholder='Enter Client ID'
          onChange={(event) => {
            dispatch(updateSimphonyPosSettings({ clientId: event.target.value.toString() }));
          }}
          value={clientId}
          loading={isProcessing}
        />
      </FormControl>
      <FormControl label='Host *'>
        <TextInput
          placeholder='Enter Host'
          onChange={(event) => {
            dispatch(updateSimphonyPosSettings({ host: event.target.value.toString() }));
          }}
          value={host}
          loading={isProcessing}
        />
      </FormControl>
      <FormControl label='API URL *'>
        <TextInput
          placeholder='Enter API URL'
          onChange={(event) => {
            dispatch(updateSimphonyPosSettings({ apiUrl: event.target.value.toString() }));
          }}
          value={apiUrl}
          loading={isProcessing}
        />
      </FormControl>
      <FormControl label='API Username *'>
        <TextInput
          placeholder='Enter API Username'
          onChange={(event) => {
            dispatch(updateSimphonyPosSettings({ apiUsername: event.target.value.toString() }));
          }}
          value={apiUsername}
          loading={isProcessing}
        />
      </FormControl>

      <FormControl label='API Password *'>
        {isPasswordEditToggled ? (
          <ToggleEditWrapper
            readonlyValue='*******'
            editButtonText='Edit Password'
            children={<></>}
            onEdit={() => setIsPasswordEditToggled(false)}
          />
        ) : (
          <TextInput
            placeholder='Enter API Password'
            onChange={(event) => {
              if (event.target.value) {
                dispatch(updateSimphonyPosSettings({ apiPassword: event.target.value.toString() }));
              } else {
                dispatch(updateSimphonyPosSettings({ apiPassword: undefined }));
              }
            }}
            value={apiPassword ?? ''}
            loading={isProcessing}
          />
        )}
      </FormControl>

      {dependentFieldsUnavailable && (
        <Notification type='warning' className={styles.notification}>
          <p>
            <i className={Icon.InfoCircle} />
            <span>The fields above must be completed before the additional settings below can be configured.</span>
          </p>
        </Notification>
      )}
      <FormControl label='Organization ID *'>
        <TextInput
          placeholder='Enter Organization ID *'
          onChange={(event) => {
            dispatch(updateSimphonyPosSettings({ organization: event.target.value }));
          }}
          value={organization}
          loading={isProcessing}
          disabled={dependentFieldsUnavailable}
        />
      </FormControl>

      <FormControl label='Location ID *'>
        <TextInput
          placeholder='Enter Location ID *'
          onChange={(event) => {
            dispatch(updateSimphonyPosSettings({ location: event.target.value }));
          }}
          value={location}
          loading={isProcessing}
          disabled={dependentFieldsUnavailable}
        />
      </FormControl>

      <FormControl label='Default Employee ID *'>
        <TextInput
          placeholder='Enter employee ID'
          onChange={(event) => {
            dispatch(updateSimphonyPosSettings({ posEmployee: event.target.value.toString() }));
          }}
          value={posEmployee}
          loading={isProcessing}
          disabled={dependentFieldsUnavailable}
        />
      </FormControl>

      <FormControl label='Default Tender Types *'>
        <SelectFilter
          placeholder='Select a tender type '
          populating={tenderTypes.loading}
          disabled={dependentFieldsUnavailable}
          loading={isProcessing}
          options={tenderTypes.results}
          value={mapSelectOption(tenderTypes.results, posTenderType) || ''}
          isSearchable
          isClearable
          onChange={(data) => {
            dispatch(updateSimphonyPosSettings({ posTenderType: data ? data.value.toString() : '' }));
          }}
        />
      </FormControl>

      <FormControl label='Card Specific Tender Types'>
        <div className='control--stacked'>
          <span className='form__control__sublabel'>Mastercard</span>
          <SelectFilter
            placeholder='Type to search tender types'
            populating={tenderTypes.loading}
            disabled={dependentFieldsUnavailable}
            loading={isProcessing}
            options={tenderTypes.results}
            value={mapSelectOption(tenderTypes.results, posMastercard) || ''}
            hideIndicator
            isSearchable
            isClearable
            onChange={(data) => {
              dispatch(updateSimphonyPosSettings({ posMastercard: data ? data.value.toString() : '' }));
            }}
          />
        </div>
        <div className='control--stacked'>
          <span className='form__control__sublabel'>Visa</span>
          <SelectFilter
            placeholder='Type to search tender types'
            populating={tenderTypes.loading}
            disabled={dependentFieldsUnavailable}
            loading={isProcessing}
            options={tenderTypes.results}
            value={mapSelectOption(tenderTypes.results, posVisa) || ''}
            hideIndicator
            isSearchable
            isClearable
            onChange={(data) => {
              dispatch(updateSimphonyPosSettings({ posVisa: data ? data.value.toString() : '' }));
            }}
          />
        </div>
        <div className='control--stacked'>
          <span className='form__control__sublabel'>Amex</span>
          <SelectFilter
            placeholder='Type to search tender types'
            populating={tenderTypes.loading}
            disabled={dependentFieldsUnavailable}
            loading={isProcessing}
            options={tenderTypes.results}
            value={mapSelectOption(tenderTypes.results, posAmex) || ''}
            hideIndicator
            isSearchable
            isClearable
            onChange={(data) => {
              dispatch(updateSimphonyPosSettings({ posAmex: data ? data.value.toString() : '' }));
            }}
          />
        </div>
        <div className='control--stacked'>
          <span className='form__control__sublabel'>Discover</span>
          <SelectFilter
            placeholder='Type to search tender types'
            populating={tenderTypes.loading}
            disabled={dependentFieldsUnavailable}
            loading={isProcessing}
            options={tenderTypes.results}
            value={mapSelectOption(tenderTypes.results, posDiscover) || ''}
            hideIndicator
            isSearchable
            isClearable
            onChange={(data) => {
              dispatch(updateSimphonyPosSettings({ posDiscover: data ? data.value.toString() : '' }));
            }}
          />
        </div>
        <div className='control--stacked'>
          <span className='form__control__sublabel'>JCB</span>
          <SelectFilter
            placeholder='Type to search tender types'
            populating={tenderTypes.loading}
            disabled={dependentFieldsUnavailable}
            loading={isProcessing}
            options={tenderTypes.results}
            value={mapSelectOption(tenderTypes.results, posJcb) || ''}
            hideIndicator
            isSearchable
            isClearable
            onChange={(data) => {
              dispatch(updateSimphonyPosSettings({ posJcb: data ? data.value.toString() : '' }));
            }}
          />
        </div>
        <div className='control--stacked'>
          <span className='form__control__sublabel'>Interac</span>
          <SelectFilter
            placeholder='Type to search tender types'
            populating={tenderTypes.loading}
            disabled={dependentFieldsUnavailable}
            loading={isProcessing}
            options={tenderTypes.results}
            value={mapSelectOption(tenderTypes.results, posInterac) || ''}
            hideIndicator
            isSearchable
            isClearable
            onChange={(data) => {
              dispatch(updateSimphonyPosSettings({ posInterac: data ? data.value.toString() : '' }));
            }}
          />
        </div>
      </FormControl>
    </>
  );
};
