import { IContext } from '@ready/dashboardv2api.contracts';
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { getCompanyListForAdminThunk } from './thunk';

interface State {
  companies?: IContext[];
  loading: boolean;
}

const initialState: State = {
  loading: false,
} as const;

const companiesListSlice = createSlice({
  name: 'admin/companies/list',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<State>) => {
    builder.addCase(getCompanyListForAdminThunk.pending, (state: State) => {
      state.loading = true;
    });

    builder.addCase(getCompanyListForAdminThunk.fulfilled, (state: State, action) => {
      state.loading = false;
      state.companies = action.payload;
    });

    builder.addCase(getCompanyListForAdminThunk.rejected, (state: State) => {
      state.loading = false;
    });
  },
});

export { companiesListSlice };
