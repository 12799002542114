import produce from 'immer';
import { Action } from '../../redux/types';
import { IMenuItemGroup, IEmbeddedMenuItem } from '@ready/menu.core';
import { IItemGroupsState, itemGroupsState, newItemGroup, viewEditItemGroupBlank } from './ItemGroupsState';
import { ITEM_GROUPS_ACTIONS } from './ItemGroupsActions';
import InfiniteScrollingListStateHelper from '../../utils/InfiniteScrollingList/InfiniteScrollingListStateHelper';

const fillPanels = (itemGroup: IMenuItemGroup, draft: IItemGroupsState) => {
  const { items, ...itemGroupDetailFields } = itemGroup;
  const itemGroupViewEditPageState = draft.itemGroupViewEditPage;

  draft.itemGroupViewEditPage.detailsPanel.itemGroupFields = {
    ...draft.itemGroupViewEditPage.detailsPanel.itemGroupFields,
    ...itemGroupDetailFields,
  };

  itemGroupViewEditPageState.groupItemsPanel.items = items;
  itemGroupViewEditPageState.imagesPanel.imageId = itemGroupDetailFields.imageId;
  itemGroupViewEditPageState.imagesPanel.thumbnailImageId = itemGroupDetailFields.thumbnailImageId;
};

export const itemGroupsReducer = (state = itemGroupsState, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ITEM_GROUPS_ACTIONS.PREPARE_MENU_BUILDER_NEW_ITEM_GROUP_MODAL:
        draft.itemGroupForm.editing = action.payload;
        if (action.payload) {
          InfiniteScrollingListStateHelper.closeState(draft.itemGroupSelection);
          draft.itemGroupForm.itemGroup = newItemGroup();
          draft.itemGroupForm.validation.displayName.errorMessage = '';
          draft.itemGroupForm.validation.displayName.hasError = false;
        }
        return draft;

      case ITEM_GROUPS_ACTIONS.ADD_ITEM_GROUP_TO_ITEM_GROUP_SELECTION:
        InfiniteScrollingListStateHelper.selectItemState(draft.itemGroupSelection, action.payload);
        return draft;

      case ITEM_GROUPS_ACTIONS.REMOVE_ITEM_GROUP_FROM_ITEM_GROUP_SELECTION:
        InfiniteScrollingListStateHelper.unselectItemState(draft.itemGroupSelection, action.payload);
        return draft;

      case ITEM_GROUPS_ACTIONS.UPDATE_MENU_BUILDER_ITEM_GROUP_DISPLAY_NAME:
        draft.itemGroupForm.itemGroup.displayName = action.payload;
        draft.itemGroupForm.validation.displayName.hasError = false;
        draft.itemGroupForm.validation.displayName.errorMessage = '';
        return draft;

      case ITEM_GROUPS_ACTIONS.UPDATE_MENU_BUILDER_ITEM_GROUP_DESCRIPTION:
        draft.itemGroupForm.itemGroup.description = action.payload;
        return draft;

      case ITEM_GROUPS_ACTIONS.SAVE_MENU_BUILDER_ITEM_GROUP_BEGIN:
        draft.itemGroupForm.processing = true;
        draft.itemGroupForm.saved = false;
        return draft;

      case ITEM_GROUPS_ACTIONS.SAVE_MENU_BUILDER_ITEM_GROUP_VALIDATED:
        draft.itemGroupForm.validation = action.payload;
        return draft;

      case ITEM_GROUPS_ACTIONS.SAVE_MENU_BUILDER_ITEM_GROUP_SUCCESS:
        draft.itemGroupForm.editing = false;
        draft.itemGroupForm.processing = false;
        draft.itemGroupForm.saved = true;
        return draft;

      case ITEM_GROUPS_ACTIONS.SAVE_MENU_BUILDER_ITEM_GROUP_ERROR:
        draft.itemGroupForm.processing = false;
        return draft;

      case ITEM_GROUPS_ACTIONS.SAVE_ITEM_GROUP_BEGIN:
        draft.itemGroupViewEditPage.detailsPanel.processing = true;
        draft.itemGroupViewEditPage.detailsPanel.itemGroupSaved = null;
        draft.itemGroupViewEditPage.detailsPanel.itemGroupFields.validation.validated = false;
        return draft;

      case ITEM_GROUPS_ACTIONS.SAVE_ITEM_GROUP_SUCCESS:
        draft.itemGroupViewEditPage.detailsPanel.itemGroupSaved = action.payload;
        draft.itemGroupViewEditPage.detailsPanel.processing = false;
        return draft;

      case ITEM_GROUPS_ACTIONS.SAVE_ITEM_GROUP_ERROR:
        draft.itemGroupViewEditPage.detailsPanel.itemGroupSaved = null;
        draft.itemGroupViewEditPage.detailsPanel.processing = false;
        return draft;

      case ITEM_GROUPS_ACTIONS.LIST_ITEM_GROUPS_BEGIN:
        draft.itemGroupsListPage.loading = true;
        draft.itemGroupsListPage.error = false;
        return draft;

      case ITEM_GROUPS_ACTIONS.LIST_ITEM_GROUPS_SUCCESS:
        draft.itemGroupsListPage.itemGroups = action.payload.results;
        draft.itemGroupsListPage.query = action.payload.query;
        draft.itemGroupsListPage.page = action.payload.page;
        draft.itemGroupsListPage.loading = false;
        draft.itemGroupsListPage.error = false;
        return draft;

      case ITEM_GROUPS_ACTIONS.LIST_ITEM_GROUPS_ERROR:
        draft.itemGroupsListPage.loading = false;
        draft.itemGroupsListPage.error = true;
        return draft;

      case ITEM_GROUPS_ACTIONS.VIEW_ITEM_GROUP_BEGIN:
        draft.itemGroupViewEditPage = viewEditItemGroupBlank();
        return draft;

      case ITEM_GROUPS_ACTIONS.VIEW_ITEM_GROUP_SUCCESS:
        fillPanels(action.payload, draft);
        draft.itemGroupViewEditPage.itemGroupCache = action.payload;
        draft.itemGroupViewEditPage.loading = false;
        draft.itemGroupViewEditPage.error = false;
        return draft;

      case ITEM_GROUPS_ACTIONS.REVERT_DETAIL_PAGE_CHANGES:
        fillPanels(draft.itemGroupViewEditPage.itemGroupCache, draft);
        return draft;

      case ITEM_GROUPS_ACTIONS.VIEW_ITEM_GROUP_ERROR:
        draft.itemGroupViewEditPage.error = true;
        draft.itemGroupViewEditPage.loading = false;
        return draft;

      case ITEM_GROUPS_ACTIONS.UPDATE_ITEM_GROUP_UPDATE_DISPLAY_NAME:
        draft.itemGroupViewEditPage.detailsPanel.itemGroupFields.displayName = action.payload;
        draft.itemGroupViewEditPage.detailsPanel.itemGroupFields.validation.displayName.hasError = false;
        draft.itemGroupViewEditPage.detailsPanel.itemGroupFields.validation.displayName.errorMessage = '';
        return draft;

      case ITEM_GROUPS_ACTIONS.UPDATE_ITEM_GROUP_UPDATE_DESCRIPTION:
        draft.itemGroupViewEditPage.detailsPanel.itemGroupFields.description = action.payload;
        return draft;

      case ITEM_GROUPS_ACTIONS.UPDATE_ITEM_GROUP_UPDATE_SCHEDULE:
        draft.itemGroupViewEditPage.detailsPanel.itemGroupFields.schedule = action.payload;
        return draft;

      case ITEM_GROUPS_ACTIONS.UPDATE_ITEM_GROUP_UPDATE_DISPLAY_NAME_VALIDATION:
        draft.itemGroupViewEditPage.detailsPanel.itemGroupFields.validation.displayName = action.payload;
        return draft;

      case ITEM_GROUPS_ACTIONS.SET_ITEM_GROUP_UPDATE_VALIDATED:
        draft.itemGroupViewEditPage.detailsPanel.itemGroupFields.validation.validated = action.payload;
        return draft;

      case ITEM_GROUPS_ACTIONS.UPDATE_ITEM_GROUP_SUCCESS:
        fillPanels(action.payload, draft);
        draft.itemGroupViewEditPage.itemGroupCache = action.payload;
        draft.itemGroupViewEditPage.imagesPanel.thumbnailImageWasChanged = false;
        draft.itemGroupViewEditPage.imagesPanel.imageWasChanged = false;
        draft.itemGroupViewEditPage.groupItemsPanel.visibilityUpdated = false;
        return draft;

      case ITEM_GROUPS_ACTIONS.UPDATE_ITEM_GROUP_BANNER_IMAGE_ID:
        draft.itemGroupViewEditPage.detailsPanel.itemGroupFields.imageId = action.payload.fileKey;
        draft.itemGroupViewEditPage.detailsPanel.itemGroupFields.imageHeight = action.payload.height;
        draft.itemGroupViewEditPage.detailsPanel.itemGroupFields.imageWidth = action.payload.width;
        return draft;

      case ITEM_GROUPS_ACTIONS.UPDATE_ITEM_GROUP_THUMBNAIL_IMAGE_ID:
        draft.itemGroupViewEditPage.detailsPanel.itemGroupFields.thumbnailImageId = action.payload.fileKey;
        draft.itemGroupViewEditPage.detailsPanel.itemGroupFields.thumbnailImageHeight = action.payload.height;
        draft.itemGroupViewEditPage.detailsPanel.itemGroupFields.thumbnailImageWidth = action.payload.width;
        return draft;

      case ITEM_GROUPS_ACTIONS.UPDATE_DETAILS_PANEL_PROCESSING_STATE:
        draft.itemGroupViewEditPage.detailsPanel.processing = action.payload;
        return draft;

      case ITEM_GROUPS_ACTIONS.UPDATE_ITEMS_PANEL_PROCESSING_STATE:
        draft.itemGroupViewEditPage.groupItemsPanel.processing = action.payload;
        return draft;

      case ITEM_GROUPS_ACTIONS.UPDATE_IMAGE_PROCESSING_STATE:
        draft.itemGroupViewEditPage.imagesPanel.processingImage = action.payload;
        return draft;

      case ITEM_GROUPS_ACTIONS.UPDATE_THUMBNAIL_IMAGE_PROCESSING_STATE:
        draft.itemGroupViewEditPage.imagesPanel.processingThumbnailImage = action.payload;
        return draft;

      case ITEM_GROUPS_ACTIONS.UPDATE_ITEM_GROUP_IMAGE:
        draft.itemGroupViewEditPage.imagesPanel.processingImage = true;
        draft.itemGroupViewEditPage.imagesPanel.imageId = action.payload;
        draft.itemGroupViewEditPage.imagesPanel.imageWasChanged = true;
        return draft;

      case ITEM_GROUPS_ACTIONS.UPDATE_ITEM_GROUP_THUMBNAIL_IMAGE:
        draft.itemGroupViewEditPage.imagesPanel.processingThumbnailImage = true;
        draft.itemGroupViewEditPage.imagesPanel.thumbnailImageId = action.payload;
        draft.itemGroupViewEditPage.imagesPanel.thumbnailImageWasChanged = true;
        return draft;

      case ITEM_GROUPS_ACTIONS.BLANK_VIEW_EDIT_ITEM_GROUP_PAGE:
        draft.itemGroupViewEditPage = viewEditItemGroupBlank();
        draft.itemGroupViewEditPage.itemGroupCache = newItemGroup();
        return draft;

      case ITEM_GROUPS_ACTIONS.UPDATE_ITEM_GROUP_ITEM_VISIBILITY:
        const { itemId, visibility, immediateSync } = action.payload;
        const index = draft.itemGroupViewEditPage.groupItemsPanel.items.findIndex((i) => i.itemId === itemId);
        if (index >= 0) {
          draft.itemGroupViewEditPage.groupItemsPanel.items[index].visibility = visibility;
          if (immediateSync) {
            draft.itemGroupViewEditPage.groupItemsPanel.visibilityUpdated = true;
          }
        }
        return draft;

      case ITEM_GROUPS_ACTIONS.REMOVE_ITEM_GROUP_ITEM:
        const itemToRemove = action.payload;
        const itemsFiltered = draft.itemGroupViewEditPage.groupItemsPanel.items.filter(
          (i) => i.itemId !== itemToRemove
        );
        draft.itemGroupViewEditPage.groupItemsPanel.items = itemsFiltered;
        return draft;

      case ITEM_GROUPS_ACTIONS.UPDATE_ITEM_GROUP_REORDER_ITEMS:
        const itemsForMove = draft.itemGroupViewEditPage.groupItemsPanel.items;
        const movedItem = itemsForMove.splice(action.payload.sourceIndex, 1)[0];
        itemsForMove.splice(action.payload.destinationIndex, 0, movedItem);
        return draft;

      case ITEM_GROUPS_ACTIONS.UPDATE_ITEM_GROUP_ITEMS_SORT_ORDER:
        const reorderedItems = draft.itemGroupViewEditPage.groupItemsPanel.items.map((item, index) => ({
          ...item,
          sortOrder: index,
        }));
        draft.itemGroupViewEditPage.groupItemsPanel.items = reorderedItems;
        return draft;

      case ITEM_GROUPS_ACTIONS.UPDATE_ITEM_GROUP_ADD_ITEM:
        const item = action.payload as IEmbeddedMenuItem;
        draft.itemGroupViewEditPage.groupItemsPanel.items.push(item);
        return draft;

      case ITEM_GROUPS_ACTIONS.DELETE_ITEM_GROUP_BEGIN:
        draft.itemGroupViewEditPage.deleteLoading = true;
        draft.itemGroupViewEditPage.itemDeleted = false;
        return draft;

      case ITEM_GROUPS_ACTIONS.DELETE_ITEM_GROUP_SUCCESS:
        draft.itemGroupViewEditPage.deleteLoading = false;
        draft.itemGroupViewEditPage.itemDeleted = true;
        return draft;

      case ITEM_GROUPS_ACTIONS.DELETE_ITEM_GROUP_ERROR:
        draft.itemGroupViewEditPage.deleteLoading = false;
        draft.itemGroupViewEditPage.itemDeleted = false;
        draft.itemGroupViewEditPage.deleteError = true;
        return draft;

      default:
        return draft;
    }
  });
};
