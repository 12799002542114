import { PaginationResponse } from '@ready/dashboardv2api.contracts';
import { ITemplateModifierGroup } from '@ready/menu.core';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSharedModifierGroups } from 'sharedMenuItems/pages/modifierGroups/modifierGroupService';

interface IGetSharedModifierGroupsForSelectModalThunkArgs {
  isLoadMore: boolean;
  companyId: string;
  searchTerm?: string;
  page?: number;
}

export const getModifierGroupsForSelectModalThunk = createAsyncThunk<
  PaginationResponse<ITemplateModifierGroup>,
  IGetSharedModifierGroupsForSelectModalThunkArgs
>(
  'sharedMenuItems/modifierGroups/modal/fetch',
  ({ companyId, searchTerm, page }: IGetSharedModifierGroupsForSelectModalThunkArgs) =>
    getSharedModifierGroups(companyId, searchTerm, page)
);
