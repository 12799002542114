import { ISmsNotificationsConfig } from '@ready/dashboardv2api.contracts';
import { OrdersService } from '../../../services/ordersService';
import { Action } from '../../types';
import { pageErrorState } from '../uiActions/responseStateActions';
import { ORDERS_ACTIONS } from './types';

export const fetchOrderSmsConfigs = (companyId: string, locationId: string) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOrderSmsConfigs(true));
    const orderSmsConfigs = await OrdersService.getOrderSmsConfigs(companyId, locationId);
    dispatch(setOrderSmsConfigsData(orderSmsConfigs));
  } catch (err) {
    dispatch(pageErrorState(err.status, err.message));
  } finally {
    dispatch(setLoadingOrderSmsConfigs(false));
  }
};

export const initOrderSmsConfigs = () => ({
  type: ORDERS_ACTIONS.INIT_ORDER_SMS_CONFIGS_DATA,
});

const setLoadingOrderSmsConfigs = (loading: boolean): Action => ({
  type: ORDERS_ACTIONS.SET_LOADING_ORDER_DETAIL,
  payload: loading,
});

const setOrderSmsConfigsData = (orderSmsConfigs: ISmsNotificationsConfig): Action => ({
  type: ORDERS_ACTIONS.SET_ORDER_SMS_CONFIGS_DATA,
  payload: orderSmsConfigs,
});
