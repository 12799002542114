import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import debounce from 'lodash/debounce';

import {
  preparePosItemsModal,
  loadPosItemsForInfiniteScroll,
  loadMorePosItemsForInfiniteScroll,
} from '../../redux/ItemsAndModsActions';
import { ICachedPosMenuItem, ICachedPosModifier } from '@ready/menu.core';
import { LinkPosItemType } from '../../types/LinkPosItemType.type';

import ListModal from '../../../components/Modal/ListModal';
import LinkPosItemSearch from './LinkPosItemSearch';
import LinkPosItemList from './LinkPosItemList';

export type LinkPosItemDialogProps = ConnectedProps<typeof connector> & {
  visible: boolean;
  companyId: string;
  locationId: string;
  selectPosItem: (item: ICachedPosMenuItem | ICachedPosModifier) => void;
  title?: string;
};

const LinkPosItemDialog = (props: LinkPosItemDialogProps): JSX.Element | null => {
  const {
    visible,
    companyId,
    locationId,
    preparePosItemsModal,
    loadPosItemsForInfiniteScroll,
    loadMorePosItemsForInfiniteScroll,
    selectPosItem,
    title = 'Link POS Item',
  } = props;

  if (!visible) {
    return null;
  }

  const debounceTime = 300;
  const loadPosItemsDebounced = debounce(loadPosItemsForInfiniteScroll, debounceTime);

  const handleLoadPosItems = (type: LinkPosItemType, query: string | undefined): void => {
    loadPosItemsDebounced(companyId, locationId, type, query);
  };

  const handleLoadMorePosItems = (type: LinkPosItemType, query: string | undefined, page: number): void => {
    loadMorePosItemsForInfiniteScroll(companyId, locationId, type, query, page);
  };

  return (
    <ListModal
      setShowModal={preparePosItemsModal}
      headerLabel={title}
      searchControl={<LinkPosItemSearch />}
      showDismissButton
    >
      <LinkPosItemList
        loadPosItems={handleLoadPosItems}
        loadMorePosItems={handleLoadMorePosItems}
        selectPosItem={selectPosItem}
      />
    </ListModal>
  );
};

const actionCreators = {
  preparePosItemsModal,
  loadPosItemsForInfiniteScroll,
  loadMorePosItemsForInfiniteScroll,
};

const connector = connect(null, actionCreators);

export default connector(LinkPosItemDialog);
