import React, { ChangeEvent } from 'react';
import Checkbox from 'components/Checkbox/Checkbox';
import styles from './StockStatus.module.scss';

interface Props {
  inStock: boolean;
  isNotApplicable: boolean;
  readOnlyStock: boolean;
  processing: boolean;
  updateMenuItemInStock: (inStock: boolean) => void;
}

const StockStatus = ({
  inStock,
  isNotApplicable,
  readOnlyStock,
  processing,
  updateMenuItemInStock,
}: Props): JSX.Element => {
  // first check if in draft state, show dash.
  if (isNotApplicable) {
    return <span className={styles.unavailable}>Stock status unavailable</span>;
  }

  if (readOnlyStock) {
    // if not in draft state and this is read only,
    // show appropriate read only in stock status.
    if (inStock) {
      return (
        <>
          <span className={styles.inStockIndicator}>
            <i className='icon-dashboard-icons_checkmark-circle' />
          </span>
          In Stock
        </>
      );
    } else {
      return (
        <>
          <span className={styles.outOfStockIndicator}>
            <i className='icon-dashboard-icons_remove-circle' />
          </span>
          Out Of Stock
        </>
      );
    }
  }

  // if this is manual stock sync, show checkbox.
  return (
    <Checkbox
      label='In Stock'
      checked={inStock}
      loading={processing}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        updateMenuItemInStock(e.target.checked);
      }}
    />
  );
};

export { StockStatus };
