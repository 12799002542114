import React from 'react';
import { useHideDropdownMenu } from '../../hooks';

import styles from './DropDownButton.module.scss';

import Button from '../Button/Button';
import TextIcon, { Icon } from '../Icon/TextIcon';
import DropDownMenu, { DropDownMenuOptionProps } from '../DropDownMenu/DropDownMenu';

export interface DropDownButtonOption extends DropDownMenuOptionProps {}
export interface DropDownButtonProps {
  label: string;
  options: DropDownButtonOption[];
  disabled?: boolean;
}

const DropDownButton = (props: DropDownButtonProps): JSX.Element => {
  const { label, options, disabled = false } = props;
  const [isVisible, setIsVisible] = React.useState(false);
  useHideDropdownMenu();
  const toggleState = () => setIsVisible(!isVisible);

  return (
    <div className={styles.container}>
      <Button
        label={label}
        disabled={disabled}
        onClick={toggleState}
        additionalButtonStyles='drop-down-button'
        additionalLabelStyles='drop-down-button'
      >
        <TextIcon icon={Icon.AngleDown} additionalStyles={styles.dropdown} />
      </Button>

      <div className={styles.options}>
        <DropDownMenu options={options} isVisible={isVisible} />
      </div>
    </div>
  );
};

export default DropDownButton;
