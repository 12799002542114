import React from 'react';
import styles from '../Page.module.scss';
import Button from '../../../../components/Button/Button';
import { DollarValue } from '../../../../components/Value';
import Modal from '../../../../components/Modal/Modal';
import { CheckDetailsFailedPayment } from './PaymentNotAppliedAlert';

interface IModalProps {
  setModalOpen: (val: boolean) => void;
  onEntryComplete: () => void;
  failedPayments: Array<CheckDetailsFailedPayment>;
}

export const ModalCompleteEntry = ({ setModalOpen, failedPayments, onEntryComplete }: IModalProps) => (
  <Modal setShowModal={setModalOpen} closeOnOverlayClick={true}>
    <div className={styles.Panel}>
      <h4 className={styles.ModalTitle}>Manually Enter Payment in POS</h4>
      <p className={styles.ModalText}>
        Enter the following payment totals into your POS system. Once the payment is applied, confirm that the Manual
        Entry is complete.
      </p>
      <div className={styles.ModalDescriptionContainer}>
        {failedPayments.map(({ cardBrand, last4, pospaymentLastInfo: { _id, subtotal, tip, total } }) => (
          <div key={_id} className={styles.ModalDescriptionItem}>
            <div>
              <div className={styles.ModalDescriptionItem__CardInfo}>
                <div className={styles.TextBold}>{cardBrand}</div>
                <div>(****{last4})</div>
              </div>
              <div className={styles.ModalDescriptionItem__PaymentDescription}>
                <div>
                  <span className={styles.TextBold}>Pre-Tip Total:</span> <DollarValue value={subtotal} />
                </div>
                <div>
                  <span className={styles.TextBold}>Tip:</span>{' '}
                  <span className={styles.ColorGreen}>
                    <DollarValue value={tip} />
                  </span>
                </div>
                <div>
                  <span className={styles.TextBold}>Total Paid:</span> <DollarValue value={total} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.ModalButtons}>
        <Button
          additionalStyles={styles.ModalButton}
          label='Cancel'
          onClick={() => setModalOpen(false)}
          variant='secondary'
        />
        <Button
          additionalStyles={styles.ModalButton}
          label='Manual Entry Complete'
          onClick={onEntryComplete}
          variant='primary'
        />
      </div>
    </div>
  </Modal>
);
