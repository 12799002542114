import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Notification from '../../../../components/Notification/Notification';
import { Icon } from '../../../../components/Icon/TextIcon';
import Button from '../../../../components/Button/Button';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { fetchManualEntryPaymentCompletedThunk, fetchRetryPOSPaymentThunk } from '../redux/thunks';
import { ModalCompleteEntry } from './ModalCompleteEntry';
import styles from '../Page.module.scss';
import { ICheckDetailsPayment, PospaymentStatus } from '@ready/dashboardv2api.contracts';
import { useParams } from 'react-router-dom';

export type CheckDetailsFailedPayment = Omit<ICheckDetailsPayment, 'pospaymentLastInfo'> & {
  pospaymentLastInfo: {
    _id: string;
    status: PospaymentStatus;
    subtotal: number;
    tip: number;
    total: number;
  };
};

interface PaymentNotAppliedAlertProps {
  failedPayments: Array<CheckDetailsFailedPayment>;
  retryPOSPaymentLoading?: boolean;
  locationId: string;
}

export const PaymentNotAppliedAlert = ({
  failedPayments,
  retryPOSPaymentLoading,
  locationId,
}: PaymentNotAppliedAlertProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { checkId, contextId } = useParams();

  return (
    <>
      {modalOpen ? (
        <ModalCompleteEntry
          setModalOpen={setModalOpen}
          failedPayments={failedPayments}
          onEntryComplete={() => {
            setModalOpen(false);
            dispatch(fetchManualEntryPaymentCompletedThunk({ contextId, locationId, failedPayments, checkId }));
          }}
        />
      ) : null}

      <Notification className={styles.Alert} type='alert'>
        <p>
          <i className={Icon.Alert} />
          Ready has collected payment on this check, but could not send a record of the amount to your POS. You can{' '}
          <b>Retry</b> sending the amounts again, or <b>manually enter</b> them in your POS.
        </p>
        <div className={styles.AlertBtns}>
          <Button
            additionalStyles={styles.AlertBtn}
            label='Enter Manually'
            type='button'
            variant='alert-secondary'
            onClick={() => setModalOpen(true)}
          />
          <Button
            additionalStyles={styles.AlertBtn}
            label='Retry'
            type='button'
            variant='alert'
            onClick={() => {
              dispatch(fetchRetryPOSPaymentThunk({ checkId, locationId, contextId, failedPayments }));
            }}
          />
        </div>
        {retryPOSPaymentLoading ? <LoadingSpinner additionalStyles={styles.POSPaymentRetryLoading} /> : null}
      </Notification>
    </>
  );
};
