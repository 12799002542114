const getPaginationQueryStringProperties = (pageNumber: number, pageSize?: number) => {
  const pageNumberString = pageNumber.toString();
  const pageSizeString = pageSize?.toString();

  return {
    pageNumber: pageNumberString,
    pageSize: pageSizeString,

    /**
     * Deprecated in favour of pageNumber. To remove once we stop accepting these in the API.
     */
    page: pageNumberString,
    /**
     * Deprecated in favour of pageSize. To remove once we stop accepting these in the API.
     */
    size: pageSizeString,
  };
};

export { getPaginationQueryStringProperties };
