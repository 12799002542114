import React from 'react';
import { compositeStyles } from '../../../utils/cssUtils';
import styles from './MenuItemNameBlock.module.scss';
import DisabledBadge from '../DisabledBadge';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';

interface MenuItemNameBlockProps {
  displayName: string;
  description?: string;
  parentDisabled?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  warning?: boolean;
  indented?: boolean;
  compactView?: boolean;
  className?: string;
  showDescription?: boolean;
}

const MenuItemNameBlock = ({
  displayName,
  description,
  parentDisabled = false,
  disabled = false,
  hidden = false,
  warning = false,
  indented = false,
  compactView = false,
  showDescription = true,
  className,
}: MenuItemNameBlockProps): JSX.Element => (
  <div className={compositeStyles([styles.container, { indented: indented }, { compactView }, className], styles)}>
    <div className={styles.nameContainer}>
      <div
        className={compositeStyles(
          [styles.name, { parentDisabled: parentDisabled }, { disabled: disabled }, { hidden: hidden }],
          styles
        )}
      >
        {warning && <TextIcon icon={Icon.Alert} additionalStyles={styles.warning} />}
        {displayName}
      </div>
      {disabled ? <DisabledBadge withMargin /> : null}
    </div>
    {!compactView && showDescription && (
      <div
        className={compositeStyles(
          [
            styles.description,
            { parentDisabled: parentDisabled },
            { disabled: disabled },
            { hidden: hidden },
            { missing: !description },
          ],
          styles
        )}
      >
        {description ?? 'No Description'}
      </div>
    )}
  </div>
);

export default MenuItemNameBlock;
