import { ITableAssignment, ITable, IRevenueCenter } from '@ready/table.core';
import executeApiAsync from './utils/executeApiAsync';
import config from '../config';
import { IPagedResponse } from './types/IPagedResponse.type';
import { IEmployee } from '@ready/employee.core';

export class TableAssignmentService {
  public static async getTables(companyId: string, locationId: string): Promise<ITable[]> {
    return executeApiAsync(`${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/tables`);
  }

  public static async getTableAssignments(companyId: string, locationId: string): Promise<ITableAssignment[]> {
    return executeApiAsync(
      `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/tableAssignments`
    );
  }

  public static async getServers(
    companyId: string,
    locationId: string,
    query: string,
    page: number = 1
  ): Promise<IPagedResponse<IEmployee>> {
    return executeApiAsync<IPagedResponse<IEmployee>>(
      `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/employees?q=${query}&page=${page}&size=40`
    );
  }

  public static async syncPOSServers(companyId: string, locationId: string): Promise<void> {
    return executeApiAsync(
      `${config.readyApiLegacyEndpoint}/companies/${companyId}/locations/${locationId}/employees/sync`,
      { method: 'POST' }
    );
  }

  public static async assignTablesToServer(
    companyId: string,
    locationId: string,
    employeeId: string,
    tableIds: string[]
  ): Promise<ITableAssignment> {
    return executeApiAsync(
      `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/tableAssignments`,
      {
        method: 'PUT',
        body: JSON.stringify({ employeeId, tableIds }),
      }
    );
  }

  public static async listRevenueCenters(companyId: string, locationId: string): Promise<IRevenueCenter[]> {
    return executeApiAsync(
      `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/tables/revenueCenters`
    );
  }
}
