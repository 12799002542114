import { useAppDispatch, useAppSelector } from 'redux/store';
import { selectMenuConfigs, selectActiveMenuConfig, selectUserSelectedMenuConfig } from '../redux/selectors';
import { setUserSelectedMenuConfigId } from '../redux/slice';

export const useMenuConfigs = (initialUserSelectedMenuConfig?: string) => {
  const menuConfigs = useAppSelector(selectMenuConfigs);
  const activeMenuConfig = useAppSelector(selectActiveMenuConfig);
  const userSelectedMenuConfig = useAppSelector(selectUserSelectedMenuConfig);

  const dispatch = useAppDispatch();

  if (initialUserSelectedMenuConfig) {
    dispatch(setUserSelectedMenuConfigId(initialUserSelectedMenuConfig));
  }

  return [menuConfigs, activeMenuConfig, userSelectedMenuConfig] as const;
};
