import React from 'react';
import SelectFilter, { Option } from '../../../components/SelectFilter/SelectFilter';
import { getSelectedOption } from '../../../utils/selectListUtils/listUtil';
import { components } from 'react-select';
import TextIcon from '../../../components/Icon/TextIcon';
import styles from './EditableSelectIconCell.module.scss';
import { IUnsavedMappedTableConfiguration } from '../../redux/initialStates/tablesDetails';

export interface EditableSelectIconCellProps {
  options: Option[];
  tableId: string;
  value: Option;
  unsavedMappedOrderExperienceTypes: IUnsavedMappedTableConfiguration;
  handleSave: (tableId: string, orderExperienceTypeValue: string) => void;
}

const EditableSelectIconCell = (props: EditableSelectIconCellProps) => {
  const { options, tableId, value, handleSave, unsavedMappedOrderExperienceTypes } = props;

  const unsavedValue = unsavedMappedOrderExperienceTypes[tableId]
    ? getSelectedOption(options, unsavedMappedOrderExperienceTypes[tableId], '')
    : null;

  const handleChange = ({ value }: any) => {
    handleSave(tableId, value);
  };

  const Option = (props: any) => (
    <components.Option {...props}>
      {props.data.icon && <TextIcon icon={props.data.icon} />}
      {` ${props.data.label}`}
    </components.Option>
  );

  const SingleValue = (props: any) => (
    <components.SingleValue {...props}>
      {props.data.icon && <TextIcon icon={props.data.icon} additionalStyles={styles.selectedIcon} />}
    </components.SingleValue>
  );

  return (
    <SelectFilter
      customComponents={{ Option, SingleValue }}
      options={options}
      onChange={handleChange}
      value={unsavedValue ?? value}
      additionalSelectStyles={styles.select}
      additionalStyles={styles.container}
    />
  );
};

export default EditableSelectIconCell;
