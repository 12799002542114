import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ISinglePlacedOrder } from '../../../services/types/orders.type';
import InfoCard from '../../../components/InfoCard/InfoCard';
import { useParams, Link } from 'react-router-dom';
import { OrderSection } from '../../../enums/orders.enum';
import RoundIcon from '../../../components/RoundIcon/RoundIcon';
import useResponsiveBreakpoint, { ResponsiveBreakpoint } from '../../../hooks/useResponsiveBreakpoint';
import { ContextParams } from '../../../types/ContextParams.interface';
import { ContactColumn, StackableItemsAndTotalColumns, StackableTimeAndStatusColumns } from '.';
import { OrderFulfillmentStatus } from '@ready/dashboardv2api.contracts';
import { setOrderStatus } from '../../../redux/actions/orders/orderListActions';
import styles from './DineInOrders.module.scss';
import { getOrderExperienceType, groupOrdersByTableName } from '../../../utils/orderUtils';

interface widths {
  contactColumnWidth: number;
  itemsColumnWidth: number;
  totalColumnWidth: number;
  timeColumnWidth: number;
  statusColumnWidth?: number;
}

const getWidths = (breakpoint: ResponsiveBreakpoint): widths => {
  switch (breakpoint) {
    case ResponsiveBreakpoint.Mobile:
      return {
        contactColumnWidth: 100,
        itemsColumnWidth: 0,
        totalColumnWidth: 0,
        timeColumnWidth: 0,
      };

    case ResponsiveBreakpoint.Tablet:
      return {
        contactColumnWidth: 35,
        itemsColumnWidth: 15,
        totalColumnWidth: 15,
        timeColumnWidth: 0,
      };

    case ResponsiveBreakpoint.Desktop:
      return {
        contactColumnWidth: 30,
        itemsColumnWidth: 12,
        totalColumnWidth: 12,
        timeColumnWidth: 12,
        statusColumnWidth: 24,
      };
  }
};

interface DineInTablesProps extends ConnectedProps<typeof connector> {
  filteredDineInOrders: ISinglePlacedOrder[];
}

const DineInTables = ({ filteredDineInOrders, setOrderStatus }: DineInTablesProps): JSX.Element => {
  const { breakpoint, isMobile, isTablet } = useResponsiveBreakpoint();
  const widths = getWidths(breakpoint);
  const { contextId, locationId } = useParams<ContextParams>();

  const groupedDineInOrders = groupOrdersByTableName(filteredDineInOrders);

  return (
    <>
      {groupedDineInOrders.map((orders, index) => {
        return (
          <div key={index} className={styles.dineInTable}>
            {orders.map((order) => {
              const handleDineInOrderStatusChange = (newStatus: OrderFulfillmentStatus) => {
                setOrderStatus(contextId, locationId, newStatus, OrderSection.DINE_IN, order.orderId, order.invoiceId);
              };
              const orderExperienceType = getOrderExperienceType(order.tableInfo?.orderExperienceType);
              const orderTip = order.tip ? order.tip : 0;

              return (
                <Link
                  to={`/companies/${contextId}/locations/${locationId}/orders/${order.orderId}`}
                  key={order.orderId}
                >
                  <InfoCard>
                    <RoundIcon icon={orderExperienceType.icon} additionalStyles={styles.icon} />
                    <ContactColumn
                      customerDetails={order.identityInfo}
                      tableInfo={order.tableInfo}
                      width={widths.contactColumnWidth}
                      newOrder={order.newOrder}
                      additionalStyles={styles.column}
                      onAccountPayment={order.onAccountPayment}
                    />
                    <StackableItemsAndTotalColumns
                      items={order.itemCount}
                      itemsColumnWidth={widths.itemsColumnWidth}
                      total={order.priceExcludingTip + orderTip}
                      subtotal={order.priceExcludingTip}
                      tip={orderTip}
                      totalColumnWidth={widths.totalColumnWidth}
                      stacked={isTablet}
                      hidden={isMobile}
                      additionalStyles={styles.column}
                      orderExperienceType={order.tableInfo?.orderExperienceType}
                      hasInvoice={!!order.invoiceId}
                    />
                    <StackableTimeAndStatusColumns
                      time={new Date(order.pickupTime)}
                      timeColumnWidth={widths.timeColumnWidth}
                      stacked={isTablet || isMobile}
                      additionalStyles={styles.column}
                      handleOrderStatusChange={handleDineInOrderStatusChange}
                      orderStatusColumnWidth={widths.statusColumnWidth}
                      isProcessing={order.processing}
                      orderStatus={order.status}
                    />
                  </InfoCard>
                </Link>
              );
            })}
          </div>
        );
      })}
    </>
  );
};

const actionCreators = {
  setOrderStatus,
};

const connector = connect(null, actionCreators);

export default connector(DineInTables);
