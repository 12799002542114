import { OrderFulfillmentStatus } from '@ready/dashboardv2api.contracts';
import { beginningOfTime } from 'utils/dateUtils';
import { OrderDetailForm } from '../../types';

const orderDetailInitialState: OrderDetailForm = {
  created: beginningOfTime.toISOString(),
  pickupTime: beginningOfTime.toISOString(),
  invoiceId: '',
  orderId: '',
  itemCount: 0,
  priceExcludingTip: 0,
  tip: 0,
  amountPaid: 0,
  refundInfo: [],
  status: OrderFulfillmentStatus.Pending,
  items: [],
  totals: {
    discounts: 0,
    due: 0,
    otherCharges: 0,
    serviceCharge: 0,
    subtotal: 0,
    tax: 0,
    total: 0,
  },
  appliedDiscounts: [],
  loading: false,
  processing: false,
  cancelProcessing: false,
  cancelDialogMessage: '',
  cancelDialogInitialMessage: '',
  cancelDialogMessageLoading: false,
  cancelDialogOpen: false,
  editPickupTimeProcessing: false,
  editPickupTimeDialogMessage: '',
  editPickupTimeDialogInitialMessage: '',
  editPickupTimeDialogMessageLoading: false,
  editPickupTimeDialogPickupTime: null,
  editPickupTimeDialogOpen: false,
  newPickupTime: null,
  validation: {
    newPickupDate: {
      errorMessage: '',
      hasError: false,
    },
    newPickupTime: {
      errorMessage: '',
      hasError: false,
    },
  },
};

export default orderDetailInitialState;
