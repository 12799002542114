import React from 'react';
import classNames from 'classnames';
import { ReactNode } from 'react';

export interface ModalContainerProps {
  children: ReactNode;
  additionalStyles?: string;
}

const ModalContainer = (props: ModalContainerProps) => {
  const { children, additionalStyles } = props;

  const className = classNames('modal__container', additionalStyles);

  return <div className={className}>{children}</div>;
};

export default ModalContainer;
