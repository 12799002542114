export default interface NamedEntityValidation {
  displayName: {
    hasError: boolean;
    errorMessage: string;
  };
}

export const newNamedEntityValidation = (): NamedEntityValidation => ({
  displayName: {
    hasError: false,
    errorMessage: '',
  },
});
