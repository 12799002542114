import React from 'react';
// state
import { IPaymentProcessingPanel } from '../../redux/PanelPaymentProcessingState';
import { LOCATION_SETTINGS_ACTIONS } from '../../redux/LocationSettingsActions';
// utils
import { toNumber } from '../../../utils/numberUtils/toNumber';
// components
import { FormControl, FormControlStacked } from '../../../components/Form';
import TextInput from '../../../components/TextInput/TextInput';
import TextInputNestedLabel from '../../../components/TextInputNestedLabel/TextInputNestedLabel';
import FormattedInput from '../../../components/FormattedInput/FormattedInput';
import Checkbox from '../../../components/Checkbox/Checkbox';
import { IUpdateLocationParam } from '../LocationsEditPage';
import Toggle from 'components/Toggle/Toggle';

interface Props {
  isProcessing: boolean;
  updateLocationParam: IUpdateLocationParam;
  paymentProcessingPanel: IPaymentProcessingPanel;
}

const BamboraPP = ({ isProcessing, updateLocationParam, paymentProcessingPanel }: Props) => {
  return (
    <>
      <FormControl label='API Key'>
        <TextInput
          type='text'
          placeholder='Enter API Key'
          loading={isProcessing}
          value={paymentProcessingPanel.bambora.apiKey}
          onChange={(event) => {
            updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_API_KEY);
          }}
        />
      </FormControl>
      <FormControl label='Merchant ID'>
        <TextInput
          type='text'
          placeholder='Enter Merchant ID'
          loading={isProcessing}
          value={paymentProcessingPanel.bambora.merchantId}
          onChange={(event) => {
            updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_MERCHANT_ID);
          }}
        />
      </FormControl>
      <FormControl label='Payment Methods'>
        <div className='control--stacked'>
          <Checkbox
            label='Credit Card'
            checked={paymentProcessingPanel.bambora.paymentMethod.creditCard}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_PAYMENT_METHOD_CREDIT_CARD
              );
            }}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <Checkbox
            label='Apple Pay'
            checked={paymentProcessingPanel.bambora.paymentMethod.applePay}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_PAYMENT_METHOD_APPLE_PAY
              );
            }}
            loading={isProcessing}
          />
        </div>
      </FormControl>
      <FormControl label='Apple Pay / Google Pay Networks'>
        <div className='control--stacked'>
          <Checkbox
            label='Mastercard'
            checked={paymentProcessingPanel.bambora.appleGoogleNetwork.mastercard}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_APPLE_GOOGLE_NETWORK_MASTERCARD
              );
            }}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <Checkbox
            label='Visa'
            checked={paymentProcessingPanel.bambora.appleGoogleNetwork.visa}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_APPLE_GOOGLE_NETWORK_VISA
              );
            }}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <Checkbox
            label='Amex'
            checked={paymentProcessingPanel.bambora.appleGoogleNetwork.amex}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_APPLE_GOOGLE_NETWORK_AMEX
              );
            }}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <Checkbox
            label='Discover'
            checked={paymentProcessingPanel.bambora.appleGoogleNetwork.discover}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_APPLE_GOOGLE_NETWORK_DISCOVER
              );
            }}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <Checkbox
            label='JCB'
            checked={paymentProcessingPanel.bambora.appleGoogleNetwork.jcb}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_APPLE_GOOGLE_NETWORK_JCB
              );
            }}
            loading={isProcessing}
          />
        </div>
      </FormControl>
      <FormControl label='Apple Pay Settings'>
        <div className='control--stacked'>
          <TextInputNestedLabel
            label='Merchant ID'
            value={paymentProcessingPanel.bambora.applePay.merchantId}
            onChange={(event) => {
              updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_APPLE_MERCHANT_ID);
            }}
          />
        </div>
        <div className='control--stacked'>
          <Checkbox
            label='Supports 3DS'
            checked={paymentProcessingPanel.bambora.applePay.supports3DS}
            onChange={(event) => {
              updateLocationParam(event.target.checked, LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_APPLE_SUPPORTS_3DS);
            }}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <Checkbox
            label='Supports Debit'
            checked={paymentProcessingPanel.bambora.applePay.supportsDebit}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_APPLE_SUPPORTS_DEBIT
              );
            }}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <Checkbox
            label='Supports Credit'
            checked={paymentProcessingPanel.bambora.applePay.supportsCredit}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_APPLE_SUPPORTS_CREDIT
              );
            }}
            loading={isProcessing}
          />
        </div>
      </FormControl>
      <FormControl label='Rate Limiting'>
        <FormControlStacked>
          <Toggle
            checked={paymentProcessingPanel.bambora.rateLimits.enabled}
            onChange={(checked) => {
              updateLocationParam(
                checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_RATE_LIMITING_TOGGLE
              );
            }}
            loading={isProcessing}
          />
        </FormControlStacked>
        <FormControlStacked>
          <Checkbox
            disabled={true}
            checked={true}
            label='Enforce rate limits on manual card payments'
            loading={isProcessing}
          />
        </FormControlStacked>
        <FormControlStacked>
          <Checkbox
            disabled={!paymentProcessingPanel.bambora.rateLimits.enabled}
            checked={paymentProcessingPanel.bambora.rateLimits.enforceRateLimitsForGooglePay}
            label='Enforce rate limits on Google Pay transactions'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_RATE_LIMITING_FOR_GOOGLE_PAY
              );
            }}
            loading={isProcessing}
          />
        </FormControlStacked>
        <FormControlStacked>
          <FormattedInput
            format='money'
            disabled={!paymentProcessingPanel.bambora.rateLimits.enabled}
            value={paymentProcessingPanel.bambora.rateLimits.amount}
            onChange={(event) => {
              updateLocationParam(
                toNumber(event.target.value),
                LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_RATE_LIMITING_AMOUNT
              );
            }}
            loading={isProcessing}
          />
          <span className='form__control__separator'>Per</span>
          <FormattedInput
            format='min'
            disabled={!paymentProcessingPanel.bambora.rateLimits.enabled}
            value={paymentProcessingPanel.bambora.rateLimits.duration / 60}
            onChange={(event) => {
              updateLocationParam(
                toNumber(event.target.value) * 60,
                LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_RATE_LIMITING_TIME_INTERVAL
              );
            }}
            loading={isProcessing}
          />
        </FormControlStacked>
      </FormControl>
      <FormControl label='Additional Settings'>
        <Checkbox
          checked={paymentProcessingPanel.bambora.allowSmallTransactions}
          label='Allow small transactions'
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            updateLocationParam(
              event.target.checked,
              LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_ALLOW_SMALL_TRANSACTIONS
            );
          }}
          loading={isProcessing}
        />
      </FormControl>
    </>
  );
};

export default BamboraPP;
