import { useCallback } from 'react';
import { AssetService } from '../services/assetService';

export interface IS3ImagesMap {
  [key: string]: string;
}

export interface IImageS3Request {
  id: string;
  fileKey: string;
}

const useS3ImagesMap = (): ((imagesRequest: IImageS3Request[]) => Promise<IS3ImagesMap>) => {
  const getImageUrl = useCallback(async (id: string, fileKey: string): Promise<{
    id: string;
    imageUrl: string;
  }> => {
    const file = { fileKey };
    const imageUrl = await AssetService.getAssetUrl(file);
    return { id, imageUrl };
  }, []);

  const resolvePromises = useCallback(async (promises: Array<Promise<{ id: string; imageUrl: string }>>): Promise<
    IS3ImagesMap
  > => {
    const images: IS3ImagesMap = {};
    const imagesResult = (await Promise.all(promises)) || [];
    imagesResult.forEach((result) => {
      const { id, imageUrl } = result;
      images[id] = imageUrl;
    });
    return images;
  }, []);

  return useCallback(
    async (imagesRequest: IImageS3Request[]): Promise<IS3ImagesMap> => {
      const promises: Array<Promise<{ id: string; imageUrl: string }>> = [];
      let images: IS3ImagesMap = {};
      imagesRequest.forEach((i) => {
        const { id, fileKey } = i;
        promises.push(getImageUrl(id, fileKey));
      });
      if (!!promises && promises.length > 0) {
        images = await resolvePromises(promises);
      }
      return images;
    },
    [getImageUrl, resolvePromises]
  );
};

export default useS3ImagesMap;
