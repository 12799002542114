import React from 'react';
import styles from './NoOptions.module.scss';

const NoOptions = () => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.content}>Create a new payment option to start accepting On Account payments.</p>
    </div>
  )
};

export default NoOptions;
