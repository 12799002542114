import { AppState } from '../../redux/initialStates/AppState';
import { connect, ConnectedProps } from 'react-redux';
import { ContextParams } from '../../types/ContextParams.interface';
import { formatISO } from 'date-fns/esm';
import { loadLocations } from '../../redux/actions/orders/orderLocationsActions';
import { OrderLocationPagination } from '../../types/OrderLocationPagination.interface';
import { useParams } from 'react-router-dom';
import LayoutContent from '../../components/AppLayout/LayoutContent';
import LocationPicker from '../../components/LocationPicker/LocationPicker';
import now from '../../utils/dateUtils/now';
import React from 'react';

const mapStateToProps = (state: AppState) => {
  return {
    loading: state.orders.orderLocations.locationPicker.loading,
    pagination: state.orders.orderLocations.locationPicker.pagination,
    locations: state.orders.orderLocations.locationPicker.locations,
  };
};

const actionCreators = {
  loadLocations,
};

const connector = connect(mapStateToProps, actionCreators);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type OrdersLocationsPickerPageProps = PropsFromRedux & {};

const OrdersLocationsPickerPage = (props: OrdersLocationsPickerPageProps): JSX.Element => {
  const { loading, pagination, locations, loadLocations } = props;

  const { contextId } = useParams<ContextParams>();
  const today = formatISO(now(), { representation: 'date' });
  const path = `locations/:id/orders/day/${today}/upcoming`;

  const handleLoadLocations = React.useCallback(async (query?: string, page?: number, limit?: number) => {
    const orderLocationPagination: OrderLocationPagination = {
      number: page ? page : 1,
      size: limit ? limit : 15,
    };
    await loadLocations(contextId, query, orderLocationPagination);
  }, [contextId, loadLocations]);

  return (
    <LayoutContent title='Orders' containerType='within'>
      <LocationPicker
        loading={loading}
        pagination={pagination}
        locations={locations}
        path={path}
        handleLoadLocations={handleLoadLocations}
      />
    </LayoutContent>
  );
};

export default connector(OrdersLocationsPickerPage);
