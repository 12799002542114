import produce from 'immer';
import { Action } from '../../../redux/types';
import { session } from '../../initialStates/session/session';
import { SESSION_ACTIONS } from '../../actions/session/types';

export const sessionReducer = (state = session, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SESSION_ACTIONS.INITIALIZE_SESSION_BEGIN:
        draft.initializeSessionLoading = true;
        draft.contextSession.invalidContext = false;
        return draft;

      case SESSION_ACTIONS.INITIALIZE_SESSION_SUCCESS:
        draft.initializeSessionLoading = false;
        return draft;

      case SESSION_ACTIONS.INITIALIZE_SESSION_ERROR:
        draft.initializeSessionLoading = false;
        draft.initializeSessionError = true;
        return draft;

      case SESSION_ACTIONS.UPDATE_USER_SESSION_BEGIN:
        draft.userSession.updateUserLoading = true;
        return draft;

      case SESSION_ACTIONS.UPDATE_USER_SESSION_SUCCESS:
        draft.userSession.isAdmin = action.payload.isAdmin;
        draft.userSession.userId = action.payload.userId;
        draft.userSession.userName = action.payload.userName;
        draft.userSession.updateUserLoading = false;
        return draft;

      case SESSION_ACTIONS.UPDATE_USER_SESSION_ERROR:
        draft.userSession.updateUserLoading = false;
        draft.userSession.updateUserError = true;
        return draft;

      case SESSION_ACTIONS.UPDATE_CONTEXT_BEGIN:
        draft.contextSession.updateContextLoading = true;
        return draft;

      case SESSION_ACTIONS.UPDATE_CONTEXT_SUCCESS:
        draft.contextSession.id = action.payload.id;
        draft.contextSession.name = action.payload.name;
        draft.contextSession.type = action.payload.type;
        draft.contextSession.usesAdyen = action.payload.usesAdyen;

        draft.contextSession.updateContextLoading = false;
        draft.permissions.apiPermissionsRequested = false;
        draft.sidebar.options = [];
        draft.sidebar.optionsSet = false;
        return draft;

      case SESSION_ACTIONS.UPDATE_CONTEXT_ERROR:
        draft.contextSession.updateContextLoading = false;
        draft.contextSession.updateContextError = true;
        return draft;

      case SESSION_ACTIONS.UPDATE_INVALID_CONTEXT:
        draft.contextSession.invalidContext = action.payload;
        return draft;

      case SESSION_ACTIONS.UPDATE_ENABLE_CONTEXT_SWITCHER:
        draft.enableContextSwitcher = action.payload;
        return draft;

      case SESSION_ACTIONS.UPDATE_SHOW_COMPANY_SELECTION_PAGE:
        draft.showCompanySelectionPage = action.payload;
        return draft;

      case SESSION_ACTIONS.UPDATE_SIDEBAR_OPTIONS:
        draft.sidebar.options = action.payload;
        draft.sidebar.optionsSet = true;
        return draft;

      case SESSION_ACTIONS.UPDATE_SIDEBAR_OPTIONS_SET:
        draft.sidebar.optionsSet = action.payload;
        return draft;

      case SESSION_ACTIONS.UPDATE_PERMISSIONS_LIST:
        draft.permissions.permissionsList = action.payload.permissions;
        draft.permissions.allPermissionsMap = action.payload.allPermissionsMap;
        draft.permissions.apiPermissionsRequested = true;
        draft.sidebar.optionsSet = false;
        return draft;

      case SESSION_ACTIONS.UPDATE_API_PERMISSIONS_REQUESTED:
        draft.permissions.apiPermissionsRequested = action.payload;
        return draft;

      case SESSION_ACTIONS.UPDATE_LAST_LOCATION:
        draft.lastLocation = action.payload;
        return draft;

      case SESSION_ACTIONS.CLEAR_LAST_LOCATION:
        draft.lastLocation = {
          locationId: '',
        };
        return draft;

      default:
        return draft;
    }
  });
};
