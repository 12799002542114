import { TerminalDto, CreateTerminalRequest } from '@ready/dashboardv2api.contracts';
import { createAsyncThunk } from '@reduxjs/toolkit';
import config from 'config';

import { interceptErrorWithToastMessage } from 'redux/helpers/interceptErrorWithToastMessage';
import executeApiAsync from 'services/utils/executeApiAsync';

const getTerminals = ({ companyId, locationId }: { companyId: string; locationId: string }): Promise<TerminalDto[]> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/terminals`;
  return executeApiAsync<TerminalDto[]>(url);
};

const createTerminal = ({
  companyId,
  locationId,
  terminal,
}: {
  companyId: string;
  locationId: string;
  terminal: CreateTerminalRequest;
}): Promise<TerminalDto> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/terminals`;
  return executeApiAsync<TerminalDto>(url, { method: 'POST', body: JSON.stringify(terminal) });
};

const deleteTerminal = ({
  companyId,
  locationId,
  terminalId,
}: {
  companyId: string;
  locationId: string;
  terminalId: string;
}): Promise<void> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/terminals/${terminalId}`;
  return executeApiAsync<void>(url, { method: 'DELETE' });
};

export const getTerminalsThunk = createAsyncThunk(
  'admin/locations/paymentProcessing/terminals/get',
  interceptErrorWithToastMessage(getTerminals)
);

export const createTerminalThunk = createAsyncThunk(
  'admin/locations/paymentProcessing/terminals/create',
  interceptErrorWithToastMessage(createTerminal, 'Success! Terminal created.')
);

export const deleteTerminalThunk = createAsyncThunk(
  'admin/locations/paymentProcessing/terminals/delete',
  interceptErrorWithToastMessage(deleteTerminal, 'Terminal deleted.')
);
