import React from 'react';
import styles from './DescriptionInput.module.scss';
import { FormControl } from '../../../components/Form';
import TextAreaNestedLabel from '../../../components/TextAreaNestedLabel/TextAreaNestedLabel';

export interface DescriptionInputProps {
  value: string;
  processing: boolean;
  fullWidth?: boolean;
  className?: string;
  onChange: (displayName: string) => void;
}

const DescriptionInput = (props: DescriptionInputProps): JSX.Element => {
  const { value, processing, fullWidth = false, className = '', onChange } = props;

  const onDisplayNameChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value);
  };

  const containerClassName = `${styles.input} ${fullWidth ? styles.fullWidth : ''} ${className}`;
  return (
    <FormControl hideFormLabel additionalStyles={containerClassName}>
      <TextAreaNestedLabel
        label='Description'
        value={value}
        placeholder='Enter a description which will be displayed to your guests.'
        loading={processing}
        onChange={onDisplayNameChange}
        maxLength={150}
      />
    </FormControl>
  );
};

export default DescriptionInput;
