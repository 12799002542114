import React from 'react';
import styles from './SectionItems.module.scss';
import DragAndDrop from '../../../components/DragAndDrop';
import { IMenuSectionItem, IMenuSectionItemGroup } from '@ready/menu.core';
import SectionItemCard from './SectionItemCard';
import SectionItemGroupCard from './SectionItemGroupCard';
import { SectionItemChange } from '../../types/SectionItemChange.interface';

interface SectionItemsProps {
  items: (IMenuSectionItem | IMenuSectionItemGroup)[];
  itemChanges: SectionItemChange[];
  processing: boolean;
  isSharedMenu?: boolean;
  onRemoveItem: (id: string) => void;
  onRemoveGroupItem: (groupId: string, itemId: string) => void;
  onChangeItemName: (id: string, displayName: string) => void;
  onChangeItemVisibility: (id: string, visible: boolean) => void;
  onChangeGroupItemVisibility: (groupId: string, itemId: string, visible: boolean) => void;
  onAddItemsToGroupClick: (id: string) => void;
  onMoveItemInGroup: (itemId: string, oldIndex: number, newIndex: number) => void;
  onMoveItem: (oldIndex: number, newIndex: number) => void;
}

const SectionItems = (props: SectionItemsProps): JSX.Element => {
  const {
    items,
    itemChanges,
    processing,
    isSharedMenu,
    onRemoveItem,
    onRemoveGroupItem,
    onChangeItemName,
    onChangeItemVisibility,
    onChangeGroupItemVisibility,
    onAddItemsToGroupClick,
    onMoveItemInGroup,
    onMoveItem,
  } = props;

  return (
    <DragAndDrop
      dropAreaClassName={styles.items}
      dropAreaDraggingClassName={styles.itemsDragging}
      dragItemClassName={styles.item}
      dragItemDraggingClassName={styles.itemDragging}
      dragHandleClassName={styles.dragHandle}
      dragHandleProvided
      dragItems={items}
      dragItemKeyExtractor={(item: IMenuSectionItem | IMenuSectionItemGroup) => item.itemId}
      dragItemComponentBuilder={(item: IMenuSectionItem | IMenuSectionItemGroup, dragHandle?: JSX.Element) =>
        item.sectionItemType === 'item' ? (
          <SectionItemCard
            item={item}
            sectionItemChanges={itemChanges}
            processing={processing}
            dragHandle={dragHandle}
            onRemoveItem={onRemoveItem}
            onChangeItemName={onChangeItemName}
            onChangeItemVisibility={onChangeItemVisibility}
            isSharedMenu={isSharedMenu}
          />
        ) : (
          <SectionItemGroupCard
            item={item}
            sectionItemChanges={itemChanges}
            processing={processing}
            dragHandle={dragHandle}
            onRemoveGroup={onRemoveItem}
            onRemoveGroupItem={onRemoveGroupItem}
            onChangeGroupName={onChangeItemName}
            onChangeGroupVisibility={onChangeItemVisibility}
            onChangeGroupItemVisibility={onChangeGroupItemVisibility}
            onMoveItem={(oldIndex: number, newIndex: number) => onMoveItemInGroup(item.itemId, oldIndex, newIndex)}
            onAddItemsClick={onAddItemsToGroupClick}
            isSharedMenu={isSharedMenu}
          />
        )
      }
      onDragItemDropped={onMoveItem}
    />
  );
};

export default SectionItems;
