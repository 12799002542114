import React from 'react';

import styles from './DollarValue.module.scss';
import NumberFormat from 'react-number-format';
import MissingValue from './MissingValue';
import { useClassNames } from '../../utils/cssUtils';

interface DollarValueProps {
  value: number;
  allowZero?: boolean;
  negativeFormat?: 'symbol' | 'parentheses';
  showPositiveSymbol?: boolean;
  variant?: 'default' | 'primary' | 'secondary' | 'special';
  overridden?: boolean;
  additionalStyles?: string;
}

const DollarValue = ({
  value,
  allowZero = true,
  negativeFormat = 'parentheses',
  showPositiveSymbol = false,
  variant = 'default',
  overridden = false,
  additionalStyles,
}: DollarValueProps): JSX.Element => {
  const isNegative = value < 0;

  let prefix: string = '';
  let suffix: string = '';

  if (isNegative) {
    if (negativeFormat === 'parentheses') {
      prefix = '(';
      suffix = ')';
    } else {
      prefix = '- ';
    }
  } else if (negativeFormat === 'symbol' && showPositiveSymbol) {
    prefix = '+ ';
  }

  prefix += '$';

  const displayValue = Math.abs(value) / 100;

  const className = useClassNames([{ overridden }, styles[variant], additionalStyles], styles);

  if (!value && !(allowZero && value === 0)) {
    return <MissingValue />;
  }

  return (
    <NumberFormat
      value={displayValue}
      displayType={'text'}
      thousandSeparator={true}
      prefix={prefix}
      suffix={suffix}
      decimalScale={2}
      fixedDecimalScale={true}
      className={className}
    />
  );
};

export default DollarValue;
