export const TRANSACTION_ACTIONS = {
  GET_TRANSACTIONS_LIST_BEGIN: 'GET_TRANSACTIONS_LIST_BEGIN',
  GET_TRANSACTIONS_LIST_SUCCESS: 'GET_TRANSACTIONS_LIST_SUCCESS',
  GET_TRANSACTIONS_LIST_ERROR: 'GET_TRANSACTIONS_LIST_ERROR',
  EXPORT_TRANSACTIONS_BEGIN: 'EXPORT_TRANSACTIONS_BEGIN',
  EXPORT_TRANSACTIONS_SUCCESS: 'EXPORT_TRANSACTIONS_SUCCESS',
  EXPORT_TRANSACTIONS_ERROR: 'EXPORT_TRANSACTIONS_ERROR',
  GET_TRANSACTIONS_REPORT_BEGIN: 'GET_TRANSACTIONS_REPORT_BEGIN',
  GET_TRANSACTIONS_REPORT_SUCCESS: 'GET_TRANSACTIONS_REPORT_SUCCESS',
  GET_TRANSACTIONS_REPORT_ERROR: 'GET_TRANSACTIONS_REPORT_ERROR',
  UPDATE_TRANSACTIONS_LIST_URL_QUERY: 'UPDATE_TRANSACTIONS_LIST_URL_QUERY',
};
