import React from 'react';
import styles from './AssignTablesDialogServerList.module.scss';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../redux/initialStates/AppState';
import { setSelectedServer } from '../redux/ServerAssignmentActions';
import { Radio } from '../../components/RadioGroup/RadioGroup';
import { IEmployee } from '@ready/employee.core';
import InfiniteScrollingList from '../../components/InfiniteScrollingList/InfiniteScrollingList';
import { AssignTablesDialogNoResults } from './index';

interface AssignTablesDialogServerListProps extends ReduxProps {
  onLoadItems: (query?: string) => void;
  onLoadMoreItems: (query: string | undefined, page: number) => void;
}

const handleGetItemKey = (employee: IEmployee) => employee.id;

const AssignTablesDialogServerList = ({
  onLoadItems,
  onLoadMoreItems,
  setSelectedServer,
  items,
  page,
  selectedServer,
  loading,
  paginationLoading,
  query,
  paginationAvailable,
  filtered,
  assignedServerId,
}: AssignTablesDialogServerListProps): JSX.Element => {
  const handleBuildItemComponent = React.useCallback(
    (employee: IEmployee) => {
      const employeeFullName =
        employee.firstName && employee.lastName ? `${employee.firstName} ${employee.lastName}` : '';
      const employeeName = employee.name ? employee.name.trim() : '';
      const label = employeeName || employeeFullName || `Employee ${employee.externalId}`;
      return (
        <div className={styles.radioContainer}>
          <Radio
            option={{
              label,
              value: employee.id,
            }}
            groupName='server-list'
            selectedValue={selectedServer}
            onChange={(event) => {
              setSelectedServer(event.target.value);
            }}
          />
        </div>
      );
    },
    [selectedServer, setSelectedServer]
  );

  React.useEffect(() => {
    if (assignedServerId) {
      setSelectedServer(assignedServerId);
    }
  }, [assignedServerId, setSelectedServer]);

  const handleLoadItems = React.useCallback((): void => {
    onLoadItems(query);
  }, [onLoadItems, query]);

  const handleLoadMoreItems = React.useCallback((): void => {
    onLoadMoreItems(query, page);
  }, [onLoadMoreItems, query, page]);

  return (
    <div className={'radio-group'}>
      {/* The non-modular className above is required to properly render
          the list of radio buttons */}
      <InfiniteScrollingList
        loading={loading}
        paginationLoading={paginationLoading}
        error={false}
        paginationAvailable={paginationAvailable}
        filtered={filtered}
        items={items}
        loadItems={handleLoadItems}
        loadMoreItems={handleLoadMoreItems}
        getItemKey={handleGetItemKey}
        buildItemComponent={handleBuildItemComponent}
        additionalListItemStyles={styles.listItem}
        noItemsComponent={<AssignTablesDialogNoResults messageType='noServers' />}
        noResultsComponent={<AssignTablesDialogNoResults messageType='noResults' />}
        isInModal
      />
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    items: state.tableServerAssignment.serverAssignments.servers.items,
    page: state.tableServerAssignment.serverAssignments.servers.page,
    selectedServer: state.tableServerAssignment.serverAssignments.selectedServer,
    loading: state.tableServerAssignment.serverAssignments.servers.loading,
    paginationLoading: state.tableServerAssignment.serverAssignments.servers.paginationLoading,
    query: state.tableServerAssignment.serverAssignments.assignModalQuery,
    paginationAvailable: state.tableServerAssignment.serverAssignments.servers.paginationAvailable,
    filtered: state.tableServerAssignment.serverAssignments.servers.filtered,
    assignedServerId: state.tableServerAssignment.serverAssignments.assignModalServerEdit,
  };
};

const actionCreators = {
  setSelectedServer,
};

const connector = connect(mapStateToProps, actionCreators);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AssignTablesDialogServerList);
