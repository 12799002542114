import React, { RefObject } from 'react';
import { addMonths, isEqual, startOfMonth } from 'date-fns';
import { DateTimeInputUtil } from './DateTimeInputUtil';
import { compositeStyles } from '../../utils/cssUtils';
import styles from './DateTimeInput.module.scss';
import DatePicker, { DatePickerValue } from '../DatePicker/DatePicker';
import TextInput from '../TextInput/TextInput';
import IconButton from '../IconButton/IconButton';
import Button from '../Button/Button';
import classNames from 'classnames';

interface Props {
  placeholder?: string;
  value?: DatePickerValue;
  initialValue?: DatePickerValue;
  type?: 'datetime' | 'date';
  mode?: 'single' | 'range';
  timePrecision?: 'minutes' | 'seconds' | 'hours';
  clearable?: boolean;
  withError?: boolean;
  onChange?: (value: DatePickerValue) => void;
  onSetDate?: (value: DatePickerValue) => void;
  errorMessage?: string;
  timePlaceholder?: string;
  isInModal?: boolean;
  disabled?: boolean;
  maxValue?: Date;
}

const DateTimeInput = ({
  placeholder,
  value = DateTimeInputUtil.initialDateTimeInputValue,
  initialValue = DateTimeInputUtil.initialDateTimeInputValue,
  type = 'datetime',
  mode = 'single',
  timePrecision = 'minutes',
  clearable = false,
  withError = false,
  onChange,
  onSetDate,
  errorMessage,
  timePlaceholder,
  isInModal = false,
  disabled = false,
  maxValue,
}: Props): JSX.Element => {
  let displayFormat;
  if (type === 'date') {
    displayFormat = DateTimeInputUtil.dateDisplayFormat;
  } else {
    switch (timePrecision) {
      case 'hours':
        displayFormat = DateTimeInputUtil.dateTimeHoursDisplayFormat;
        break;
      case 'minutes':
        displayFormat = DateTimeInputUtil.dateTimeDisplayFormat;
        break;
      case 'seconds':
        displayFormat = DateTimeInputUtil.dateTimeWithSecondsDisplayFormat;
    }
  }

  const [isPickerOpen, setIsPickerOpen] = React.useState<boolean>(false);
  const [pickerDateValue, setPickerDateValue] = React.useState<DatePickerValue>(value);

  const datePickerRef: RefObject<HTMLDivElement> = React.useRef(null);

  const textInputClassName = compositeStyles([styles.input, { withError }], styles);
  const showClearIconButton = (value.startDate || value.endDate) && clearable;
  // The button controls are unnecessary for single selection of only a date. Simply clicking a date will commit the
  // selection. If time is included, or there is a range, then that means that more than one thing is being selected,
  // therefore it's necessary to leave the dialog open until a button is clicked to commit or cancel the selections.
  const showSetDateAndClearButtons = !(mode === 'single' && type === 'date');
  const inputValue =
    value?.startDate && value?.endDate
      ? DateTimeInputUtil.formatSelectionForInput(mode, value.startDate, value.endDate, displayFormat)
      : '';

  const handleInputClick = () => {
    const startMonth = value.startDate ?? initialValue?.startDate ?? value.startMonth ?? startOfMonth(new Date());
    let endMonth: Date;

    if (value.startDate && value.endDate && isEqual(startOfMonth(value.startDate), startOfMonth(value.endDate))) {
      endMonth = addMonths(startOfMonth(value.endDate), 1);
    } else if (value.endDate) {
      endMonth = startOfMonth(value.endDate);
    } else {
      endMonth = value.endMonth ?? initialValue?.endMonth ?? addMonths(startOfMonth(new Date()), 1);
    }

    setPickerDateValue({
      startMonth,
      endMonth,
      startDate: pickerDateValue.startDate ?? initialValue?.startDate,
      endDate: pickerDateValue.endDate ?? initialValue?.endDate,
    });

    !isPickerOpen && setIsPickerOpen(true);
  };

  const handleCancelClick = () => {
    setIsPickerOpen(false);
    setPickerDateValue(value);
  };

  const handleClearClick = () => {
    onChange && onChange(DateTimeInputUtil.initialDateTimeInputValue);
    setIsPickerOpen(false);
  };

  const handleSetDate = (value: DatePickerValue) => {
    onChange && onChange(value ?? DateTimeInputUtil.initialDateTimeInputValue);

    if (value.startMonth && !value.monthArrowButtonIsClicked) {
      setIsPickerOpen(false);
    }
  };

  const handleSetDateClick = () => {
    handleSetDate(pickerDateValue);
  };

  const handleDatePickerChange = (value: DatePickerValue) => {
    if (onSetDate) {
      onSetDate(value);
    }

    setPickerDateValue(value);
    if (!showSetDateAndClearButtons) {
      handleSetDate(value);
    }
  };

  React.useEffect(() => {
    const handleOutsidePickerClick = (event: MouseEvent) =>
      DateTimeInputUtil.handleOutsideClick(event, datePickerRef, () => {
        setIsPickerOpen(false);
        setPickerDateValue(value);
      });

    document.addEventListener('mousedown', handleOutsidePickerClick);

    return () => document.removeEventListener('mousedown', handleOutsidePickerClick);
  }, [value]);

  const isSetDateDisabled =
    !(pickerDateValue?.startDate && (pickerDateValue?.endDate || mode === 'single')) || Boolean(errorMessage);
  const datePickerClassName = classNames(styles.datePicker, isInModal && styles.isInModal);

  return (
    <div className={styles.container}>
      <div className={textInputClassName}>
        <TextInput
          onClick={handleInputClick}
          value={inputValue}
          readOnly={true}
          placeholder={placeholder}
          disabled={disabled}
        />
        {showClearIconButton ? (
          <IconButton
            onClick={handleClearClick}
            transparent
            additionalClassName={styles.clearButton}
            disabled={disabled}
          >
            <i className={`icon-dashboard-icons_remove-circle ${styles.inputIcon}`} />
          </IconButton>
        ) : (
          !disabled && <span className={styles.clearButton} />
        )}
      </div>
      {isPickerOpen && (
        <div className={datePickerClassName} ref={datePickerRef}>
          <DatePicker
            isRange={mode === 'range'}
            withTime={type === 'datetime'}
            showSeconds={timePrecision === 'seconds'}
            showMinutes={timePrecision === 'seconds' || timePrecision === 'minutes'}
            value={pickerDateValue}
            onChange={handleDatePickerChange}
            timePlaceholder={timePlaceholder}
            maxValue={maxValue}
          />
          <div className={styles.footer}>
            {errorMessage && <div className={styles.tipMessage}>{errorMessage}</div>}
            {showSetDateAndClearButtons && (
              <div className={styles.footerButtons}>
                <div className={styles.button}>
                  <Button label='Cancel' variant='secondary' onClick={handleCancelClick} />
                </div>
                <div className={styles.button}>
                  <Button
                    label='Set Date'
                    variant='primary'
                    disabled={isSetDateDisabled}
                    onClick={handleSetDateClick}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DateTimeInput;
