import React from 'react';
import { ChangeEvent } from 'react';
import { toggleSingleCheckbox, toggleAllCheckboxes } from '../../../redux/slices/checkboxeStatesSlice';
import { useAppDispatch, useAppSelector, RootState } from 'redux/store';
import Checkbox from '../Checkbox';

type Props = {
  rowId?: string;
  rowIds?: string[];
  type?: 'selectAll' | 'single';
  className?: string;
};
export const CheckboxCell = ({ rowId = '', type = 'single', rowIds = [], className }: Props) => {
  const { checkboxes, selectAllIsChecked } = useAppSelector((state: RootState) => state.ui.checkboxStates);
  const dispatch = useAppDispatch();

  const checked = selectAllIsChecked ? selectAllIsChecked : checkboxes[rowId];

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    e.stopPropagation();
    if (type === 'selectAll' && rowIds.length > 0) {
      dispatch(toggleAllCheckboxes({ ids: rowIds, value: checked }));
    } else {
      dispatch(toggleSingleCheckbox({ id: rowId, value: checked }));
    }
  };

  return (
    <div className={className} onClick={(e) => e.stopPropagation()}>
      <Checkbox onChange={handleCheckboxChange} checked={checked} label='' />
    </div>
  );
};
