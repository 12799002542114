import { createSlice } from '@reduxjs/toolkit';

export type CheckboxStates = {
  checkboxes: { [key: string]: boolean };
  selectAllIsChecked: boolean;
}

export const initialState: CheckboxStates = {
  checkboxes: {},
  selectAllIsChecked: false,
};

const checkboxStatesSlice = createSlice({
  name: 'checkboxStates',
  initialState,
  reducers: {
    toggleSingleCheckbox: (state: CheckboxStates, action: { payload: { id: string; value: boolean } }): void => {
      state.selectAllIsChecked = false;
      state.checkboxes[action.payload.id] = action.payload.value;
    },

    toggleAllCheckboxes: (state: CheckboxStates, action: { payload: { ids: string[]; value: boolean } }): void => {
      state.selectAllIsChecked = action.payload.value;
      action.payload.ids.forEach((id) => {
        state.checkboxes[id] = action.payload.value;
      });
    },
  },
});

export const { toggleSingleCheckbox, toggleAllCheckboxes } = checkboxStatesSlice.actions;

export { checkboxStatesSlice };
