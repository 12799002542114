import React from 'react';
import { AppState } from '../../redux/initialStates/AppState';
import { connect, ConnectedProps } from 'react-redux';
import { ILocationState } from '../../locations/redux/LocationState';
import { Form, FormControl, FormActionButtons } from '../../components/Form';
import TextInput from '../../components/TextInput/TextInput';
import {
  updateFirstName,
  updateLastName,
  updateEmail,
  addUserRole,
  removeUserRole,
  updateRole,
  addUserRoleLocation,
  removeUserRoleLocation,
  loadUserFormData,
  validateUserForm,
  initializeUserFormData,
  submitUserForm,
} from '../../redux/actions/usersActions/usersFormActions';
import UserPermissionsGroup from '../../components/UserPermissions/UserPermissionsGroup';
import { useParams, useHistory } from 'react-router-dom';
import LayoutContent from '../../components/AppLayout/LayoutContent';
import { IUpdateLocationParam } from '../../locations/components/LocationsEditPage';

type Props = ReduxProps & {
  location: ILocationState;
  updateLocationParam: IUpdateLocationParam;
};

const UsersEditPage = (props: Props) => {
  /* ****** Props/State ****** */

  const {
    usersForm,
    loadUserFormData,
    updateFirstName,
    updateLastName,
    updateEmail,
    addUserRole,
    removeUserRole,
    updateRole,
    addUserRoleLocation,
    removeUserRoleLocation,
    initializeUserFormData,
    validateUserForm,
    submitUserForm,
  } = props;

  /* ****** Hooks ****** */

  const { contextId: companyId, userId } = useParams<{ contextId: string; userId: string }>();
  const history = useHistory();

  /* ****** Effects ****** */

  // Fetch user data
  React.useEffect(() => {
    loadUserFormData(companyId, userId);
    return () => {
      initializeUserFormData();
    };
  }, [companyId, userId, loadUserFormData, initializeUserFormData]);

  /* ****** Callbacks ****** */

  const handleUserFormValidation = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const isValid = validateUserForm(usersForm);

    if (isValid) {
      const success = await submitUserForm(usersForm, companyId, userId);

      if (success) {
        history.push(`/companies/${companyId}/users/${usersForm.id}`);
      }
    }
  };

  const handleUserFormCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    history.push(`/companies/${companyId}/users/${usersForm.id}`);
  };

  /* ****** Rendering ****** */

  return (
    <LayoutContent title='Edit User' loadingContent={usersForm.loading} pageId='users-edit-page' containerType='within'>
      <Form onSubmit={handleUserFormValidation}>
        <FormControl
          label='First Name'
          withError={usersForm.validation.usersForm.firstName.hasError}
          errorMessage={usersForm.validation.usersForm.firstName.error}
        >
          <TextInput
            placeholder='e.g. John'
            name='given_name'
            onChange={(event) => {
              updateFirstName(event.target.value);
            }}
            value={usersForm.firstName}
            loading={usersForm.processing}
          />
        </FormControl>
        <FormControl
          label='Last Name'
          withError={usersForm.validation.usersForm.lastName.hasError}
          errorMessage={usersForm.validation.usersForm.lastName.error}
        >
          <TextInput
            placeholder='e.g. Doe'
            name='family_name'
            onChange={(event) => {
              updateLastName(event.target.value);
            }}
            value={usersForm.lastName}
            loading={usersForm.processing}
          />
        </FormControl>
        <FormControl
          label='Email Address'
          withError={usersForm.validation.usersForm.email.hasError}
          errorMessage={usersForm.validation.usersForm.email.error}
        >
          <TextInput
            placeholder='e.g. name@domain.com'
            name='email'
            onChange={(event) => {
              updateEmail(event.target.value);
            }}
            value={usersForm.email}
            loading={usersForm.processing}
          />
        </FormControl>
        <FormControl label='Security Role(s)'>
          <UserPermissionsGroup
            companyId={companyId}
            onAddUserRole={addUserRole}
            onRemoveUserRole={removeUserRole}
            onUpdateRole={updateRole}
            onAddLocation={addUserRoleLocation}
            onRemoveLocation={removeUserRoleLocation}
            value={usersForm.roles}
            validation={usersForm.validation}
            loading={usersForm.processing}
          />
        </FormControl>
        <FormActionButtons handleCancel={handleUserFormCancel} loading={usersForm.processing} />
      </Form>
    </LayoutContent>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    usersForm: state.users.usersForm,
    usersFormCache: state.cache.usersFormCache,
  };
};

const actionCreators = {
  loadUserFormData,
  updateFirstName,
  updateLastName,
  updateEmail,
  addUserRole,
  removeUserRole,
  updateRole,
  addUserRoleLocation,
  removeUserRoleLocation,
  validateUserForm,
  initializeUserFormData,
  submitUserForm,
};

const connector = connect(mapStateToProps, actionCreators);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(UsersEditPage);
