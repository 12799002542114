import config from '../config';
import { IContext } from '@ready/dashboardv2api.contracts';
import executeApiAsync from './utils/executeApiAsync';

const BASE_URL = `${config.readyDashboardApiEndpoint}/me/contexts`;

export class UserContextService {
  public static async getUserContexts(query: string = '', page: number = 1): Promise<IContext[]> {
    const pageSize = 50;
    const url = `${BASE_URL}?searchTerm=${query}&pageSize=${pageSize}&pageNumber=${page}`;
    return await executeApiAsync<IContext[]>(url);
  }

  public static getContextById(contextId: string): Promise<IContext> {
    return executeApiAsync<IContext>(`${BASE_URL}/${contextId}`);
  }
}
