import produce from 'immer';
import { Action } from '../../redux/types';
import { locationPromoCodes } from './LocationPromoCodesState';
import { LOCATION_PROMO_CODES_ACTIONS } from './LocationPromoCodesActions';

export const locationPromoCodesReducer = (state = locationPromoCodes, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case LOCATION_PROMO_CODES_ACTIONS.SET_PROMO_CODES:
        draft.config = action.payload;
        return draft;

      case LOCATION_PROMO_CODES_ACTIONS.UPDATE_PROMO_CODES_ENABLED:
        draft.config.promoCodesEnabled = action.payload;
        return draft;

      default:
        return draft;
    }
  });
};
