import React from 'react';
import { TColumns } from '../Body/Body';
import { TCellData } from '../Cell/Cell';
import { TableRow } from '../TableRow/TableRow';
import styles from './TableHeader.module.scss';

export interface TableHeaderProps {
  columns: TColumns;
  cells: Record<string, any>;
  data: Record<string, TCellData>;
}

export const TableHeader = ({ data, columns, cells }: TableHeaderProps) => {
  return (
    <TableRow hiddenColumns={{}} rowData={data} classes={styles.tableV2__headerRow} columns={columns} cells={cells} />
  );
};
