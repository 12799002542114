import { ILocation } from '../../../services/types/ILocation.type';
import { IPagedResponse } from '../../../services/types/IPagedResponse.type';
import { PERMITTED_LOCATIONS_TYPES } from './permittedLocationsTypes';
import { getPermittedTransactionLocations } from '../../../services/transactionsService';

export const getLocations = (contextId: string) => async (dispatch: any) => {
  try {
    dispatch(getLocationsBegin());
    const locations = await getPermittedTransactionLocations(contextId);
    dispatch(getLocationsSuccess(locations));
  } catch (err) {
    dispatch(getLocationsError());
  }
};

export const getLocationsBegin = () => ({
  type: PERMITTED_LOCATIONS_TYPES.GET_LOCATIONS_BEGIN,
});

export const getLocationsSuccess = (payload: IPagedResponse<ILocation>) => ({
  type: PERMITTED_LOCATIONS_TYPES.GET_LOCATIONS_SUCCESS,
  payload,
});

export const getLocationsError = () => ({
  type: PERMITTED_LOCATIONS_TYPES.GET_LOCATIONS_ERROR,
});
