import React from 'react';
import { format, getTime } from 'date-fns';
import InfoCardColumn from '../../../components/InfoCard/InfoCardColumn';
import styles from './TimeColumn.module.scss';

export enum TimeColumnStatus {
  Normal,
  StartSoon,
  Overdue,
}

interface TimeColumnProps {
  time: Date;
  isOverdue?: boolean;
  isStartSoon?: boolean;
  width: number;
  additionalStyles?: string;
}

const TimeColumn = ({
  time,
  isOverdue = false,
  isStartSoon = false,
  width,
  additionalStyles = '',
}: TimeColumnProps) => {
  const formattedTime = format(getTime(time), 'h:mm a');

  const urgent: boolean = isOverdue || isStartSoon;

  return (
    <InfoCardColumn width={width} columnClassName={`time ${urgent ? styles.urgent : ''} ${additionalStyles}`}>
      <div className={styles.time}>{formattedTime}</div>
      <SubText isOverdue={isOverdue} isStartSoon={isStartSoon} />
    </InfoCardColumn>
  );
};

interface SubTextProps {
  isOverdue?: boolean;
  isStartSoon?: boolean;
}

const SubText = ({ isOverdue, isStartSoon }: SubTextProps) => {
  if (isOverdue) {
    return <div className={styles.subText}>Overdue</div>;
  } else if (isStartSoon) {
    return <div className={styles.subText}>Start Soon</div>;
  } else {
    return null;
  }
};

export default TimeColumn;
