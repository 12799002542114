import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/Modal';
import React from 'react';
import { useSelector } from 'react-redux';
import { UIClientUser } from 'services/types/companyUsers.type';
import { setUserEnabledThunk } from '../../../redux/actions/usersActions/usersFormActions';
import { RootState, useAppDispatch } from '../../../redux/store';

interface Props {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  user: UIClientUser;
  fullName: string;
  isUserEnabled: boolean;
}

const UserToggleActivateModal = ({ setShow, user, fullName, isUserEnabled }: Props) => {
  const { processing } = useSelector((state: RootState) => state.users.usersForm);
  const dispatch = useAppDispatch();

  const confirm = async () => {
    await dispatch(setUserEnabledThunk(user, !isUserEnabled));
    setShow(false);
  };

  return (
    <Modal setShowModal={setShow} loading={processing}>
      <ModalHeader headerLabel={`${isUserEnabled ? 'Deactivate' : 'Activate'} this User?`} setShowModal={setShow} />
      <ModalBody>
        {isUserEnabled
          ? 'Are you sure you want to deactivate the user '
          : 'Are you sure you want to activate the user '}
        "<strong>{fullName}</strong>"?
        {isUserEnabled && ' Their access will be revoked. You can re-activate their account later if needed.'}
      </ModalBody>
      <ModalFooter
        primaryLabel={`${isUserEnabled ? 'Deactivate' : 'Activate'} User`}
        primaryActionHandler={confirm}
        secondaryLabel='Cancel'
        secondaryActionHandler={() => {
          setShow(false);
        }}
        loading={processing}
      />
    </Modal>
  );
};

export { UserToggleActivateModal };
