import React from 'react';
import { ITemplateEmbeddedMenuItem } from '@ready/menu.core';
import { setFormIsDirty } from 'redux/actions/uiActions/formStateActions';
import { useAppDispatch, useAppSelector } from 'redux/store';

import useS3ImagesMap, { IImageS3Request } from 'hooks/useS3Images';
import styles from './GroupItemsCards.module.scss';
import DragAndDrop from 'components/DragAndDrop';
import { GroupItemsCard } from '../groupItemsCard/GroupItemsCard';
import { moveItemGroupItem } from '../../redux/slice';

export const GroupItemsCards = () => {
  const dispatch = useAppDispatch();
  const { itemGroup, loading, saving } = useAppSelector((state) => state.sharedMenuItems.itemGroups.details);

  const handleReorderItems = (oldIndex: number, newIndex: number) => {
    dispatch(setFormIsDirty(true));
    dispatch(moveItemGroupItem({ oldIndex, newIndex }));
  };

  const isPageProcessing = loading || saving;

  const [itemImgUrls, setItemImgUrls] = React.useState<{
    [id: string]: string;
  }>({});

  // get items thumbnail images
  const getS3ImagesMap = useS3ImagesMap();
  React.useEffect(() => {
    async function getImages(items: ITemplateEmbeddedMenuItem[]) {
      const imagesRequest = items
        .filter((i) => !!i.thumbnailImageId)
        .map(
          (i) =>
            ({
              id: i.itemId,
              fileKey: i.thumbnailImageId,
            } as IImageS3Request)
        );
      const images = await getS3ImagesMap(imagesRequest);
      setItemImgUrls(images);
    }
    if (itemGroup && itemGroup.items.length > 0) {
      getImages(itemGroup.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemGroup?.items, getS3ImagesMap, !!itemGroup]);

  return (
    <div className={styles.container}>
      <DragAndDrop
        dropAreaClassName={styles.dropArea}
        dropAreaDraggingClassName={styles.dropAreaDragging}
        dragItemClassName={styles.dragItem}
        dragItemDraggingClassName={styles.dragItemDragging}
        dragHandleClassName={styles.dragHandle}
        dragHandleProvided
        dragItems={itemGroup?.items ?? []}
        emptyComponent={
          <div className={styles.itemsPanelFooter}>
            <p className={styles.noItemsMessage}>Click the +Add Items button to start adding items to this group.</p>
          </div>
        }
        dragItemKeyExtractor={(item: ITemplateEmbeddedMenuItem) => item.itemId}
        dragItemComponentBuilder={(item: ITemplateEmbeddedMenuItem, dragHandle?: JSX.Element) => (
          <GroupItemsCard
            dragHandle={dragHandle}
            item={item}
            isPageProcessing={isPageProcessing}
            itemImgUrl={itemImgUrls[item.itemId]}
          />
        )}
        onDragItemDropped={handleReorderItems}
      />
    </div>
  );
};
