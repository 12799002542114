import React, { ReactNode } from 'react';
import styles from './DetailLayout.module.scss';
import Button from '../Button/Button';

interface CtaDetailsButton {
  label: string;
  actionHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  loading?: boolean;
}

export interface DetailLayoutProps {
  children?: ReactNode;
  title?: string;
  titleExtra?: JSX.Element | null;
  ctaDetailsButton?: CtaDetailsButton;
}

const DetailLayout = (props: DetailLayoutProps) => {
  const { children, title, titleExtra, ctaDetailsButton } = props;

  return (
    <div className={styles.container}>
      {title && (
        <div className={styles.title}>
          <h3>{title}</h3>
          {titleExtra && <div className={styles.extra}>{titleExtra}</div>}
          {ctaDetailsButton && (
            <Button
              variant='secondary'
              size='large'
              label={ctaDetailsButton.label}
              onClick={ctaDetailsButton.actionHandler}
              disabled={ctaDetailsButton.disabled}
              loading={ctaDetailsButton.loading}
            />
          )}
        </div>
      )}
      {children}
    </div>
  );
};

export default DetailLayout;
