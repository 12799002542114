import { startOfMonth, subMonths } from 'date-fns';
import { DateCell, DollarCell, TextCell } from '../../components/TableV2/Cell/SpecialCells';
import { CellProps, createCellsWithMobileLayout } from '../../components/TableV2/Cell/Cell';
import { createSortableHeaderCell } from '../../components/TableV2/Cell/HeaderCell';
import styles from './PayoutHistoryReportPage.module.scss';
import React from 'react';
import classnames from 'classnames';
import { CheckboxCell } from 'components/Checkbox/CheckboxCell/CheckboxCell';
import { compositeStyles } from 'utils/cssUtils';

const rowKey = 'id';

/** Defines header columns and displayed text */
const headerColumns = {
  export: 'Export',
  transferId: 'Transfer ID',
  locationName: 'Location',
  creationDate: 'Date',
  instructedAmount: 'Amount',
  currencyCode: 'Currency',
};

const columns = ['export', 'transferId', 'locationName', 'creationDate', 'instructedAmount', 'currencyCode'];

export const getCells = () => {
  let cells = {
    export: (props: CellProps) => (
      <CheckboxCell
        className={compositeStyles(
          [styles.CheckboxCell, styles.paddingLeftDesktop50, styles.selectable, props.classes],
          styles
        )}
        rowId={props.rowData.id as string}
      />
    ),
    transferId: (props: CellProps) => (
      <TextCell {...props} classes={classnames(props.classes, styles.paddingLeftDesktop50, styles.selectable)} />
    ),
    locationName: TextCell,
    creationDate: (props: CellProps<any>) => (
      <DateCell
        {...props}
        dateGetter={(rowData: any) => rowData.creationDate}
        timezoneGetter={(_: any) => ''}
        classes={classnames(props.classes)}
      />
    ),
    instructedAmount: DollarCell,
    currencyCode: TextCell,
  };

  return createCellsWithMobileLayout(cells, headerColumns, { titleClasses: styles.MobileHeaderCell });
};

export const getHeaderCells = (rowIds: string[]) => {
  return {
    export: (props: CellProps) => (
      <CheckboxCell
        className={classnames(props.classes, styles.paddingLeftDesktop50, styles.selectable)}
        rowIds={rowIds}
        type='selectAll'
      />
    ),
    transferId: createSortableHeaderCell('transferId', { classes: styles.HeaderCellShorted50, align: 'center' }),
    locationName: createSortableHeaderCell('locationName', { classes: styles.HeaderCellShorted50 }),
    creationDate: createSortableHeaderCell('creationDate', { classes: styles.HeaderCellShorted50 }),
    instructedAmount: createSortableHeaderCell('instructedAmount', {
      classes: styles.HeaderCellShorted50,
      align: 'center',
    }),
    currencyCode: createSortableHeaderCell('currencyCode', { classes: styles.HeaderCellShorted50 }),
  };
};

const endMonth = startOfMonth(new Date());
const startMonth = subMonths(endMonth, 1);
const initDateFilter = { endMonth, startMonth };

const maxDaysToChoose = 366;

export { rowKey, columns, headerColumns, initDateFilter, maxDaysToChoose };
