export const inputColorMask = (value: string): string => {
  let result = value;
  if (value.length < 2) result = '#';
  else if (value[0] !== '#') result = `#${value}`;
  return result;
};

/**
 * Checks if it is a valid hex value. Pattern:
 * [0-9a-fA-F] - hex digit
 * {1,2} - repeated once or twice
 * {1,2} - repeated once or twice
 * @param value
 */
export function isValidHex(value: string): boolean {
  return value.match(/^#(?:[0-9a-fA-F]{3}){1,2}$/) !== null;
}
