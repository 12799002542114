import { ResourceType, TableAssignmentResourceActions } from '@ready/security.core';
import { SERVER_ASSIGNMENT_ACTIONS } from './types';
import { toastErrorState } from '../../redux/actions/uiActions/responseStateActions';
import ServerAssignmentLocationPagination from '../types/ServerAssignmentLocationPagination.interface';
import { IServerAssignmentLocationsState } from './ServerAssignmentLocationsState';
import { Action } from '../../redux/types';
import { CompanyLocationService } from '../../services/companyLocationsService';

export const loadLocations =
  (companyId: string, query: string = '', page: ServerAssignmentLocationPagination) =>
  async (dispatch: any) => {
    dispatch(setTableAssignmentLocationsLoading(true));

    try {
      const pageNumber = page.number;
      const { items, start, length, limit, total } = await CompanyLocationService.getPermittedLocations(
        companyId,
        [{ resourceType: ResourceType.tableAssignment, action: TableAssignmentResourceActions.view }],
        query,
        pageNumber
      );

      const locationsPayload: IServerAssignmentLocationsState = {
        locationPicker: {
          locations: items,
          pagination: {
            start,
            length,
            limit,
            total,
          },
          loading: true,
        },
      };

      dispatch(setTableAssignmentLocations(locationsPayload));
    } catch (error) {
      // @ts-ignore
      dispatch(toastErrorState(error.status, error.message));
    } finally {
      dispatch(setTableAssignmentLocationsLoading(false));
    }
  };

const setTableAssignmentLocationsLoading = (loading: boolean): Action => ({
  type: SERVER_ASSIGNMENT_ACTIONS.SET_SERVER_ASSIGNMENT_LOCATIONS_LOADING,
  payload: loading,
});

const setTableAssignmentLocations = (locationsData: IServerAssignmentLocationsState): Action => ({
  type: SERVER_ASSIGNMENT_ACTIONS.SET_SERVER_ASSIGNMENT_LOCATIONS,
  payload: locationsData,
});
