import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { getAdminsList } from '../redux/AdminsListActions';
import { IAdminList } from '../redux/AdminsListState';
import { AppState } from '../../redux/initialStates/AppState';
import { useSearchParams } from '../../hooks/useSearchParams';
import { UserStatusFilter } from '@ready/dashboardv2api.contracts';
import LayoutContent from '../../components/AppLayout/LayoutContent';
import SearchParamInput from '../../components/SearchInput/SearchParamInput';
import { Table, TableHeader, TableBody, TableRow, TableCell, TableRowsSkeleton } from '../../components/Table';
import HeaderControls from '../../components/AppLayout/HeaderControls';
import StatusIndicator from '../../components/StatusIndicator/StatusIndicator';
import SelectFilter from '../../components/SelectFilter/SelectFilter';
import styles from './AdminsListPage.module.scss';

export type AdminsListPageProps = ReduxProps & {
  adminsList: IAdminList;
};

const AdminsListPage = (props: AdminsListPageProps) => {
  const { adminsList, getAdminsList } = props;
  const { query, page } = useSearchParams();

  const history = useHistory();

  const { admins, loading } = adminsList;
  const { items, length, limit, start, total } = admins;
  const paginationProps = { length, limit, start, total };
  const emptyResult = !loading && !items?.length;
  const [status, setStatus] = React.useState<UserStatusFilter>(UserStatusFilter.Active);
  const filterOptions = [
    { label: 'All Users', value: UserStatusFilter.All },
    { label: 'Active Users', value: UserStatusFilter.Active },
    { label: 'Inactive Users', value: UserStatusFilter.Inactive },
  ];

  // fetch admins list
  React.useEffect(() => {
    async function listAdminsPage() {
      getAdminsList(query, page, status);
    }

    if (!query || query.length > 2) {
      listAdminsPage();
    }
  }, [query, page, status, getAdminsList]);

  // table cols width
  const colsWidth: number[] = [30, 40, 30, 10];
  const redirectToAdminsCreate = (): void => history.push('/ready/admins/create');

  const headerControlOptions = [
    {
      buttonLabel: '+ New Admin',
      onClick: redirectToAdminsCreate,
      primary: true,
    },
  ];

  return (
    <LayoutContent
      title='Admins'
      headerControls={<HeaderControls controlOptions={headerControlOptions} />}
      toolbarContent={
        <div className={styles.adminToolbar}>
          <SearchParamInput additionalClassName={styles.adminSearchFilter} placeholder='Filter by Name or Email' />
          <SelectFilter
            options={filterOptions}
            defaultValue={filterOptions[1]} // defaults to active users
            onChange={(data) => setStatus(data.value as UserStatusFilter)}
          />
        </div>
      }
      containerType='full'
    >
      <Table paginationProps={paginationProps}>
        <TableHeader>
          <TableRow>
            <TableCell width={colsWidth[0]}>Name</TableCell>
            <TableCell width={colsWidth[1]}>Email</TableCell>
            <TableCell width={colsWidth[2]}>Last login</TableCell>
            <TableCell width={colsWidth[3]}>Status</TableCell>
          </TableRow>
        </TableHeader>

        <TableBody emptyResult={emptyResult}>
          {!loading ? (
            items.map((data, index) => {
              return (
                <TableRow key={index} linkTo={`/ready/admins/${data.id}`}>
                  <TableCell width={colsWidth[0]}>{`${data.firstName} ${data.lastName}`}</TableCell>
                  <TableCell width={colsWidth[1]}>{data.email}</TableCell>
                  <TableCell width={colsWidth[2]} disabled={true}>
                    <span>N/A</span>
                  </TableCell>
                  <TableCell width={colsWidth[3]}>
                    <StatusIndicator additionalStyles={styles.adminStatusIndicator} active={data.enabled} activeLabel={'Active'} inactiveLabel={'Inactive'} />
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRowsSkeleton rows={50} colsWidth={colsWidth} />
          )}
        </TableBody>
      </Table>
    </LayoutContent>
  );
};

const mapStateToProps = (state: AppState) => ({
  adminsList: state.admins.adminsList,
});

const actionCreators = {
  getAdminsList,
};

const connector = connect(mapStateToProps, actionCreators);
type ReduxProps = ConnectedProps<typeof connector>;
export default connector(AdminsListPage);
