import { IPagination } from '@ready/dashboardv2api.contracts';
import React, { ReactNode } from 'react';
import NoResults from '../NoResults/NoResults';
import InfoCardFooter from './InfoCardFooter';
import InfoCardPagination from './InfoCardPagination';

export interface InfoCardListProps {
  children: ReactNode;
  paginationProps?: IPagination;
  additionalClassName?: string;
  noSearchResults?: boolean;
}

const InfoCardList = (props: InfoCardListProps) => {
  const { children, paginationProps, additionalClassName = '', noSearchResults } = props;

  return (
    <div className={`info-card-list ${additionalClassName}`}>
      {children}
      {noSearchResults && <NoResults />}
      {paginationProps && (
        <InfoCardFooter>
          <InfoCardPagination {...paginationProps}></InfoCardPagination>
        </InfoCardFooter>
      )}
    </div>
  );
};

export default InfoCardList;
