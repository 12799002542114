import {
  ILocationEditModelResponse,
  ILocationDetails,
  ILocationMobileOrdering,
  ILocationOrderAhead,
  ILocationPosSettings,
  IPayAtTable,
  ILocationPaymentProcessor,
  ILocationBranding,
  ILocationGratuity,
  ILocationNewsletter,
  ILocationEmailReceipts,
  IAuth0User,
  ImageStyling,
  CommentSubmissionMethod,
  PaymentProcessorProvider,
  CustomTipType,
} from '@ready/dashboardv2api.contracts';

const getInitialLocationDetails = (): ILocationDetails => ({
  name: '',
  countryCode: '',
  streetAddress: '',
  city: '',
  stateCode: '',
  zip: '',
  timezone: '',
  currency: '',
  publicName: '',
  phoneNumber: '',
  enabled: false,
  locale: '',
});

const getInitialMobileOrdering = (): ILocationMobileOrdering => ({
  orderPickupEnabled: false,
  orderingEnabled: false,
  alcoholOrderingEnabled: false,
  alcoholPolicy: '',
  disruptionMessage: '',
  menuAnnouncementsMessage: '',
  checkoutAnnouncementMessage: '',
  pickupInstructions: '',
  pickupLocationName: '',
  orderConfirmedMessage: '',
  itemComments: {
    enableItemComments: false,
    commentSubmissionMethod: CommentSubmissionMethod.AttachCommentModifier,
  },
  enableOrderLocationWidget: false,
  orderIntegration: {
    emailSubmissionAddress: '',
    emailSubmissionEnabled: false,
    orderIntegrationType: undefined,
  },
  orderDoesNotRequireImmediatePayment: false,
  orderSmsEnabled: false,
});

const getInitialOrderAhead = (): ILocationOrderAhead => ({
  enableOrderAhead: false,
  pickUpHours: {
    monday: {
      start: '',
      end: '',
      offset: '',
    },
    tuesday: {
      start: '',
      end: '',
      offset: '',
    },
    wednesday: {
      start: '',
      end: '',
      offset: '',
    },
    thursday: {
      start: '',
      end: '',
      offset: '',
    },
    friday: {
      start: '',
      end: '',
      offset: '',
    },
    saturday: {
      start: '',
      end: '',
      offset: '',
    },
    sunday: {
      start: '',
      end: '',
      offset: '',
    },
  },
  guestInfoFields: [],
});

const getInitialPosSettings = (): ILocationPosSettings => ({
  posIntegration: '',
});

const getInitialPayAtTable = (): IPayAtTable => ({
  payAtTableEnabled: false,
  allowMultiSelect: false,
  paymentConfirmationMessage: '',
  payByItem: false,
  payBySeat: false,
  verifyTicket: false,
  requireEmailAddressOnCheckout: false,
});

const getInitialPaymentProcessor = (): ILocationPaymentProcessor => ({
  paymentQueues: false,
  providerName: PaymentProcessorProvider.Adyen,
});

const getInitialBranding = (): ILocationBranding => ({
  primaryColour: '',
  secondaryColour: '',
  menuBackgroundColour: '',
  menuTextColour: '',
  accentBackgroundColour: '',
  accentTextColour: '',
  headerFont: '',
  bodyFont: '',
  imageStyling: ImageStyling.RoundedCorners,
});

const getInitialGratuity = (): ILocationGratuity => ({
  allowTips: false,
  bottomText: '',
  preDiscountTip: false,
  preItemDiscountTip: false,
  preNonReadyPaymentsTip: false,
  preTaxTip: false,
  showBottomText: false,
  defaultTips: [],
  defaultTipsForServiceCharges: [],
  defaultCustomTipType: CustomTipType.Percentage,
});

const getInitialNewsletter = (): ILocationNewsletter => ({
  showNewsletterSubCheckbox: false,
  newsletterPromptText: '',
  newsletterSuccessText: '',
});

const getInitialEmailReceipts = (): ILocationEmailReceipts => ({
  receiptLogoAsset: {
    _id: '',
    fileName: '',
    fileKey: '',
  },
  receiptTitleText: '',
  receiptHeaderText: '',
});

const getInitialLastSaveBy = (): IAuth0User => ({
  name: '',
});

export const locationTab = {
  _id: '',
  details: getInitialLocationDetails(),
  mobileOrdering: getInitialMobileOrdering(),
  orderAhead: getInitialOrderAhead(),
  posSettings: getInitialPosSettings(),
  payAtTable: getInitialPayAtTable(),
  paymentProcessor: getInitialPaymentProcessor(),
  branding: getInitialBranding(),
  gratuity: getInitialGratuity(),
  newsletter: getInitialNewsletter(),
  emailReceipts: getInitialEmailReceipts(),
  lastSaveBy: getInitialLastSaveBy(),
  lastSaveAt: '',
  iqEntityId: 0,
  companyName: '',
  companyId: '',
  iqParentEntityId: 0,
} as ILocationEditModelResponse;
