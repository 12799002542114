import { UI_ACTIONS } from '../../actions/types';
import { dropDownState } from '../../initialStates/ui/dropDownState';
import { Action } from '../../../redux/types';
import produce from 'immer';

export const dropDownReducers = (state = dropDownState, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case UI_ACTIONS.SETUP_DETAILS_PANEL_DROPDOWN:
        draft.country = action.payload.country;
        draft.state = action.payload.state;
        draft.timezone = action.payload.timezone;
        draft.currency = action.payload.currency;
        draft.addressListOptions = action.payload.addressListOptions;
        draft.locale = action.payload.locale;
        return draft;
      case UI_ACTIONS.SET_SELECTED_OPTION_COUNTRY:
        draft.country.selectedOption = action.payload;
        return draft;
      case UI_ACTIONS.SET_SELECTED_OPTION_STATE:
        draft.state.selectedOption = action.payload;
        return draft;
      case UI_ACTIONS.SET_SELECTED_OPTION_TIMEZONE:
        draft.timezone.selectedOption = action.payload;
        return draft;
      case UI_ACTIONS.SET_SELECTED_OPTION_CURRENCY:
        draft.currency.selectedOption = action.payload;
        return draft;
      case UI_ACTIONS.SET_SELECTED_OPTION_LOCALE:
        draft.locale.selectedOption = action.payload;
        return draft;
      // order ahead
      case UI_ACTIONS.SET_ORDER_AHEAD_PANEL_DROPDOWN:
        draft.orderTime = { ...action.payload.orderTime };
        draft.acceptOrders = { ...action.payload.acceptOrders };
        draft.throttleDuration = { ...action.payload.throttleDuration };
        return draft;
      case UI_ACTIONS.SET_SELECTED_OPTION_ORDER_TIME:
        draft.orderTime.selectedOption = action.payload;
        return draft;
      case UI_ACTIONS.SET_SELECTED_OPTION_THROTTLE_DURATION:
        draft.throttleDuration.selectedOption = action.payload;
        return draft;
      case UI_ACTIONS.SET_SELECTED_OPTION_ACCEPT_FOR:
        draft.acceptOrders.selectedOption = action.payload;
        return draft;
      // pos settings
      case UI_ACTIONS.SET_POS_SETTINGS_DROPDOWN:
        draft.posIntegration = {
          ...action.payload.posIntegration,
        };
        return draft;
      case UI_ACTIONS.SET_SELECTED_OPTION_POS_INTEGRATION:
        draft.posIntegration.selectedOption = action.payload;
        return draft;
      // branding
      case UI_ACTIONS.SET_BRANDING_PANEL_DROPDOWNS:
        draft.headerFont = { ...action.payload.headerFont };
        draft.bodyFont = { ...action.payload.bodyFont };
        return draft;
      case UI_ACTIONS.SET_SELECTED_OPTION_HEADER_FONT:
        draft.headerFont.selectedOption = action.payload;
        return draft;
      case UI_ACTIONS.SET_SELECTED_OPTION_BODY_FONT:
        draft.bodyFont.selectedOption = action.payload;
        return draft;
      // gratuity
      case UI_ACTIONS.SETUP_GRATUITY_PANEL_DROPDOWN:
        draft.tipCalculation = action.payload;
        return draft;
      case UI_ACTIONS.SET_SELECTED_OPTION_TIP_CALCULATION:
        draft.tipCalculation.selectedOption = action.payload;
        return draft;
      // Loyalty
      case UI_ACTIONS.SET_SELECTED_OPTION_POS_PROVIDER:
        draft.posProvider.selectedOption = action.payload;
        return draft;

      default:
        return draft;
    }
  });
};
