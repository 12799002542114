import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import getSearchParams from '../utils/urlUtils/getSearchParams';
import { useMemo } from 'react';
import { useAppDispatch } from 'redux/store';
import { storeSearchParams } from 'redux/slices/searchParamsSlice';

const useSearchParams = (storeInRedux: boolean = false) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const searchParams = useMemo(() => getSearchParams(location.search), [location.search]);

  useEffect(() => {
    if (storeInRedux) {
      dispatch(storeSearchParams(searchParams));
    }
  }, [dispatch, searchParams, storeInRedux]);

  return searchParams;
};

export { useSearchParams };
