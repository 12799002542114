import React from 'react';
import styles from './OnAccountPaymentBadge.module.scss';

interface Props {
  /** Two character code for on account payment option. Will use the first two characters if longer than 2 characters.*/
  shortCode: string;
}

const OnAccountPaymentBadge = ({ shortCode }: Props) => {
  const truncatedShortCode = shortCode.length > 2 ? shortCode.substring(0, 2) : shortCode;

  return <span className={styles.onAccountPaymentBadge}>{truncatedShortCode}</span>;
};

export { OnAccountPaymentBadge };
