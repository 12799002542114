import TextIcon, { Icon } from 'components/Icon/TextIcon';
import IconButton from 'components/IconButton/IconButton';
import { DeleteConfirmationModal } from 'components/Modal';
import { PanelListItem } from 'components/PanelLayout/PanelListItem';
import React, { useState } from 'react';

import styles from '../PaymentTerminalsPanel.module.scss';

interface Props {
  terminalId: string;
  serialNumber: string;
  onDelete: (terminalId: string) => Promise<void>;
}

export const PaymentTerminalPanelListItem = ({ terminalId, serialNumber, onDelete }: Props) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const onDeleteIconClick = async () => {
    setShowDeleteModal(true);
  };

  const onDeleteConfirmed = async () => {
    setDeleting(true);
    await onDelete(terminalId);
  };

  return (
    <>
      <PanelListItem>
        <div className={styles.listItem}>
          <span className={styles.serial}>{serialNumber}</span>
          <IconButton onClick={onDeleteIconClick}>
            <TextIcon icon={Icon.TrashCan} />
          </IconButton>
        </div>
      </PanelListItem>

      {showDeleteModal && (
        <DeleteConfirmationModal
          item='Payment Terminal'
          itemName={serialNumber}
          handleDelete={onDeleteConfirmed}
          setShowModal={setShowDeleteModal}
          loading={deleting}
        />
      )}
    </>
  );
};
