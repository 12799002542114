import { createSlice } from '@reduxjs/toolkit';
import {
  IInfiniteScrollingListModalState,
  infiniteScrollingListModalState,
} from 'redux/initialStates/ui/infiniteScrollingListModalState';
import InfiniteScrollingListStateHelper from 'utils/InfiniteScrollingList/InfiniteScrollingListStateHelper';

export const reducers = {
  prepare: (
    state: IInfiniteScrollingListModalState,
    action: {
      payload: {
        isVisible: boolean;
        selectedIds?: string[];
      };
    }
  ) => {
    InfiniteScrollingListStateHelper.prepareState(state, action.payload.isVisible, action.payload.selectedIds);
  },

  loadMoreItemsNoResults: (state: IInfiniteScrollingListModalState) => {
    InfiniteScrollingListStateHelper.loadMoreNoItemsState(state);
  },

  loadItemsPending: (state: IInfiniteScrollingListModalState) => {
    InfiniteScrollingListStateHelper.loadingState(state);
  },

  loadItemsFulfilled: (
    state: IInfiniteScrollingListModalState,
    action: {
      payload: any[];
    }
  ): void => {
    if (action.payload) {
      InfiniteScrollingListStateHelper.loadedState(state, action.payload);
    }
  },

  loadMoreItemsFulfilled: (
    state: IInfiniteScrollingListModalState,
    action: {
      payload: any[];
    }
  ): void => {
    if (action.payload) {
      if (action.payload.length === 0) {
        reducers.loadMoreItemsNoResults(state);
      }
      InfiniteScrollingListStateHelper.loadedMoreState(state, action.payload);
    }
  },

  loadItemsFailure: (state: IInfiniteScrollingListModalState) => {
    InfiniteScrollingListStateHelper.loadFailedState(state);
  },

  loadMoreItemsPending: (state: IInfiniteScrollingListModalState) => {
    InfiniteScrollingListStateHelper.loadingMoreState(state);
  },

  loadMoreItemsFailure: (state: IInfiniteScrollingListModalState) => {
    InfiniteScrollingListStateHelper.loadMoreFailedState(state);
  },

  updateSearchQuery: (state: IInfiniteScrollingListModalState, action: { payload: string }) => {
    InfiniteScrollingListStateHelper.filterChangedState(state, action.payload);
  },

  selectItem: (state: IInfiniteScrollingListModalState, action: { payload: string }) => {
    InfiniteScrollingListStateHelper.selectItemState(state, action.payload);
  },

  unselectItem: (state: IInfiniteScrollingListModalState, action: { payload: string }) => {
    InfiniteScrollingListStateHelper.unselectItemState(state, action.payload);
  },

  selectItems: (state: IInfiniteScrollingListModalState, action: { payload: string[] }) => {
    state.selectedIds = [...state.selectedIds, ...action.payload];
  },

  resetInfiniteScrollingListModalState: () => InfiniteScrollingListStateHelper.initialState(),
};

const infiniteScrollingListModalSlice = createSlice({
  name: 'infiniteScrollingListModal',
  initialState: infiniteScrollingListModalState,
  reducers,
});

export const {
  prepare,
  loadItemsFulfilled,
  loadItemsPending,
  loadMoreItemsFailure,
  loadItemsFailure,
  loadMoreItemsFulfilled,
  loadMoreItemsNoResults,
  loadMoreItemsPending,
  selectItem,
  unselectItem,
  updateSearchQuery,
  resetInfiniteScrollingListModalState,
  selectItems,
} = infiniteScrollingListModalSlice.actions;

export { infiniteScrollingListModalSlice };
