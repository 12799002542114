import TextIcon, { Icon } from 'components/Icon/TextIcon';
import LinkButton from 'components/LinkButton/LinkButton';
import NoneValue from 'components/Value/NoneValue';
import React from 'react';
import styles from './LinkedPosItem.module.scss';

interface Props {
  isInDraftState: boolean;
  isInPosDeletedState: boolean;
  handleLinkPosItemClick: () => void;
  posItemName: string;
  posItemId?: string;
  posItemCategories: string[];
  readonly?: boolean;
}

const LinkedPosItem = ({
  isInDraftState,
  isInPosDeletedState,
  handleLinkPosItemClick,
  posItemId,
  posItemName,
  posItemCategories = [],
  readonly = false,
}: Props): JSX.Element => {
  return (
    <div className={styles.container}>
      {isInDraftState || !posItemId ? (
        <>
          {!readonly ? (
            <LinkButton onClick={handleLinkPosItemClick}>
              <TextIcon icon={Icon.Link} />
              <span className={styles.posItemLinkText}>Select linked POS Item</span>
            </LinkButton>
          ) : (
            <NoneValue />
          )}
        </>
      ) : (
        <>
          {isInPosDeletedState ? (
            <span className={styles.warning}>
              <TextIcon icon={Icon.Alert} />
              <span className={styles.warningText}>POS Item Deleted</span>
            </span>
          ) : (
            <div className={styles.posInfo}>
              <div className={styles.posItemName}>{posItemName}</div>
              <div className={styles.posItemId}>
                {posItemId} {(posItemCategories ?? []).length > 0 ? ` | ${(posItemCategories ?? []).join(', ')}` : ''}
              </div>
            </div>
          )}
          {!readonly && (
            <div className={styles.btnContainer}>
              <LinkButton onClick={handleLinkPosItemClick}>
                <TextIcon additionalStyles={styles.editButton} icon={Icon.Pencil} />
              </LinkButton>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export { LinkedPosItem };
