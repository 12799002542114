import React from 'react';
import classNames from 'classnames';

interface SkeletonProps {
  additionalStyles?: string;
}

const Skeleton = ({ additionalStyles }: SkeletonProps) => {
  return <div className={classNames('skeleton', additionalStyles)}></div>;
};

export default Skeleton;
