import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/store';
import { IPaymentsReportState } from './types';

export const selectPaymentsReport = createSelector(
  (state: RootState) => state,
  ({ session, reports: { payments } }: RootState): IPaymentsReportState & { contextId: string } => {
    return {
      contextId: session.contextSession.id,
      ...payments,
    };
  }
);
