import React from 'react';

import NumberFormat from 'react-number-format';
import MissingValue from './MissingValue';

interface NumberValueProps {
  value: number | string;
  allowZero?: boolean;
  negativeFormat?: 'symbol' | 'parentheses';
  unit?: string;
}

const NumberValue = ({
  value,
  allowZero = true,
  negativeFormat = 'symbol',
  unit = '',
}: NumberValueProps): JSX.Element => {
  if (!value && !(allowZero && value === 0)) {
    return <MissingValue />;
  }
  const isNegative = value < 0;
  const prefix = isNegative && negativeFormat === 'parentheses' ? '(' : '';
  const suffix = isNegative && negativeFormat === 'parentheses' ? `)${unit}` : unit;
  const displayValue =
    negativeFormat === 'parentheses'
      ? Object.prototype.toString.call(value) === '[object String]'
        ? value
        : Math.abs(value as number)
      : value;

  return (
    <NumberFormat value={displayValue} displayType={'text'} thousandSeparator={true} prefix={prefix} suffix={suffix} />
  );
};

export default NumberValue;
