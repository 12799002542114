import React from 'react';
import { Routes } from '../MenuBuilderRouter';

import MenuBuilderTab from '../types/MenuBuilderTab.enum';

import { Tabs, Tab } from '../../components/Tabs';

export interface MenuBuilderTabsProps {
  companyId: string;
  locationId: string;
  stockStatusOnly: boolean;
  tab: MenuBuilderTab;
}

const MenuBuilderTabs = (props: MenuBuilderTabsProps): JSX.Element => {
  const { companyId, locationId, stockStatusOnly, tab } = props;
  const MENUS = {
    id: MenuBuilderTab.MENUS,
    route: Routes.getMenusPage(companyId, locationId),
    label: 'Menus',
  };
  const ITEMS_AND_MODS = {
    id: MenuBuilderTab.ITEMS_AND_MODS,
    route: Routes.getItemsAndModsPage(companyId, locationId),
    label: 'Items & Mods',
  };
  const ITEM_GROUPS = {
    id: MenuBuilderTab.ITEM_GROUPS,
    route: Routes.getItemGroupsPage(companyId, locationId),
    label: 'Item Groups',
  };
  const MODIFIER_GROUPS = {
    id: MenuBuilderTab.MOD_GROUPS,
    route: Routes.getModifierGroupsPage(companyId, locationId),
    label: 'Modifier Groups',
  };
  const tabs = stockStatusOnly ? [ITEMS_AND_MODS] : [MENUS, ITEMS_AND_MODS, ITEM_GROUPS, MODIFIER_GROUPS];
  const requestedTab = tab ? tabs.find((item) => item.id === tab) : undefined;
  const selectedTab = requestedTab ? requestedTab : stockStatusOnly ? ITEMS_AND_MODS : MENUS;

  return stockStatusOnly ? (
    <Tabs color='white' selectedTab={selectedTab.id}>
      <Tab {...ITEMS_AND_MODS} />
    </Tabs>
  ) : (
    <Tabs color='white' selectedTab={selectedTab.id}>
      <Tab {...MENUS} />
      <Tab {...ITEMS_AND_MODS} />
      <Tab {...ITEM_GROUPS} />
      <Tab {...MODIFIER_GROUPS} />
    </Tabs>
  );
};

export default MenuBuilderTabs;
