import { IOnAccountPaymentOption, IOnAccountPaymentOptionSummary } from '@ready/dashboardv2api.contracts';
import config from '../../config';
import executeApiAsync from '../../services/utils/executeApiAsync';

export class OnAccountPaymentOptionService {
  public static list = (companyId: string, locationId: string): Promise<IOnAccountPaymentOptionSummary[]> => {
    const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/onAccountPaymentOptions`;
    return executeApiAsync<IOnAccountPaymentOptionSummary[]>(url);
  };

  public static save = (
    companyId: string,
    locationId: string,
    onAccountPaymentOption: IOnAccountPaymentOption
  ): Promise<IOnAccountPaymentOption> => {
    const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/onAccountPaymentOptions`;
    return executeApiAsync<IOnAccountPaymentOption>(url, {
      method: 'POST',
      body: JSON.stringify(onAccountPaymentOption),
    });
  };
  public static getById = (
    companyId: string,
    locationId: string,
    onAccountPaymentOptionId: string
  ): Promise<IOnAccountPaymentOption> => {
    const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/onAccountPaymentOptions/${onAccountPaymentOptionId}`;
    return executeApiAsync<IOnAccountPaymentOption>(url);
  };

  public static delete = async (
    companyId: string,
    locationId: string,
    onAccountPaymentOptionId: string
  ): Promise<void> => {
    const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/onAccountPaymentOptions/${onAccountPaymentOptionId}`;
    await executeApiAsync<IOnAccountPaymentOption>(url, {
      method: 'DELETE',
    });
  };

  public static update = (
    companyId: string,
    locationId: string,
    onAccountPaymentOptionId: string,
    onAccountPaymentOption: IOnAccountPaymentOption
  ): Promise<IOnAccountPaymentOption> => {
    const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/onAccountPaymentOptions/${onAccountPaymentOptionId}`;
    return executeApiAsync<IOnAccountPaymentOption>(url, {
      method: 'PUT',
      body: JSON.stringify(onAccountPaymentOption),
    });
  };
}
