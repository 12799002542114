import { ITransactionDetailPaymentHistoryItem } from '@ready/dashboardv2api.contracts';
import React from 'react';
import { DetailLayout } from '../../../../components/DetailLayout';
import { DateValue, DollarValue } from '../../../../components/Value';
import styles from './PaymentHistorySection.module.scss';

interface PaymentHistorySectionProps {
  paymentHistory: ITransactionDetailPaymentHistoryItem[];
  timezone?: string;
}

export const PaymentHistorySection = (props: PaymentHistorySectionProps) => {
  const { paymentHistory, timezone } = props;
  return (
    <DetailLayout title='Payment History'>
      {paymentHistory.map((historyData: ITransactionDetailPaymentHistoryItem, index: number) => {
        return (
          <div className={styles.paymentHistoryItem} key={`phistory${index}`}>
            <div className={styles.historyDate}>
              <DateValue value={historyData.date?.toString() ?? ''} timeZone={timezone} />
            </div>
            <div className={styles.historyAmount}>
              <DollarValue value={historyData.amount ?? 0} variant='primary' allowZero={false} />
            </div>
            <div className={styles.historyDescription}>{historyData.description}</div>
          </div>
        );
      })}
    </DetailLayout>
  );
};
