import React from 'react';
import { Link } from 'react-router-dom';
import styles from './CompanyTablePage.module.scss';
import { useClassNames } from '../../../utils/cssUtils';

export interface CompanyTableRowProps {
  children: React.ReactNode;
  withOrderType: boolean;
  skeleton?: boolean;
  linkTo?: string;
  squarePOS?: boolean;
}

const CompanyTableRowContent = (props: Pick<CompanyTableRowProps, 'children' | 'linkTo'>) => {
  const { children, linkTo } = props;

  return linkTo ? (
    <Link className={styles.rowLink} to={linkTo}>
      {children}
    </Link>
  ) : (
    children
  );
};

const CompanyTableRow = (props: CompanyTableRowProps) => {
  const { children, withOrderType, skeleton = false, linkTo, squarePOS = false } = props;

  const className = useClassNames(
    [
      { rowSkeleton: skeleton },
      { hasLink: !!linkTo },
      { rowWithOrderType: withOrderType },
      { rowNoOrderType: !withOrderType },
      { rowSquarePOS: squarePOS },
    ],
    styles
  );

  return <div className={className}>{CompanyTableRowContent({ children, linkTo })}</div>;
};

export default CompanyTableRow;
