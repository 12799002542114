import React from 'react';
import LayoutContent from '../../components/AppLayout/LayoutContent';
import EmptyResult from '../../components/EmptyResult/EmptyResult';

const NotFound = () => {
  return (
    <LayoutContent title='Page Not Found' containerType='within'>
      <div className='page-with-empty-result'>
        <EmptyResult
          title="It's a 404!"
          paragraph='We can’t seem to find the page you’re looking for. It may have moved, or you may have mistyped the address. You can try going back to the previous screen to get back on track.'
        />
      </div>
    </LayoutContent>
  );
};

export default NotFound;
