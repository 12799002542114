import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import LayoutContent from '../../../components/AppLayout/LayoutContent';
import { selectOnAccountPaymentOptions } from '../../redux/selectors/onAccountPaymentOptionsSelector';
import { OnAccountPaymentOptionsActions } from '../../redux/actions/onAccountPaymentOptionsActions';
import { ContextParams } from '../../../types/ContextParams.interface';
import { OnAccountPaymentOptionsEditForm } from '../../components/OnAccountPaymentOptions/OnAccountPaymentOptionsEditForm';

const getPaymentProcessingUrl = (pathname: string): string => {
  return pathname.split('/').slice(0, -2).join('/');
};

const NewOnAccountPaymentOption = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { contextId: companyId, locationId } = useParams<ContextParams>();
  const dispatch = useDispatch();
  const { form } = useSelector(selectOnAccountPaymentOptions);

  const paymentProcessingUrl = getPaymentProcessingUrl(pathname);
  const onAccountPaymentOptionDetailsUrl = `/companies/${companyId}/companyLocations/${locationId}/paymentProcessing/paymentOptions/${form.paymentOptionId}`;

  const isReadyToStartSaving = form.isValidated && !form.isProcessing;
  React.useEffect(() => {
    if (isReadyToStartSaving) {
      dispatch(OnAccountPaymentOptionsActions.save(companyId, locationId, form));
    }
  }, [isReadyToStartSaving, dispatch, companyId, locationId, form]);

  React.useEffect(() => {
    if (form.paymentOptionId) {
      push(onAccountPaymentOptionDetailsUrl);
    }
  }, [form.paymentOptionId, push, onAccountPaymentOptionDetailsUrl]);

  const handleCancel = () => {
    push(paymentProcessingUrl);
  };

  return (
    <LayoutContent title='New Payment Option'>
      <OnAccountPaymentOptionsEditForm saveButtonLabel='Create Payment Option' handleCancel={handleCancel} />
    </LayoutContent>
  );
};

export default NewOnAccountPaymentOption;
