import React from 'react';

import { IconValue } from '../../../components/Value';

export type PaymentMethodCode =
  | 'apple_pay'
  | 'credit_card'
  | 'credit-card'
  | 'gift_card'
  | 'gift-card'
  | 'credit'
  | 'android_pay'
  | 'google_pay'
  | 'PayPal'
  | 'accountpayment';

const mapPaymentMethodCodeToIconFilename = (method: PaymentMethodCode): string => {
  switch (method) {
    case 'apple_pay':
      return 'apple_pay';
    case 'credit_card':
    case 'credit-card':
    case 'gift_card':
    case 'gift-card':
    case 'credit':
      return 'credit_card';
    case 'android_pay':
    case 'google_pay':
      return 'google_pay';
    case 'PayPal':
      return 'paypal_icon';
    case 'accountpayment':
      return 'accountpayment_icon';
    default:
      return '';
  }
};

interface PaymentMethodIconProps {
  code: PaymentMethodCode;
}

const PaymentMethodIcon = ({ code }: PaymentMethodIconProps): JSX.Element => (
  <IconValue value={mapPaymentMethodCodeToIconFilename(code)} />
);

export default PaymentMethodIcon;
