import React, { useEffect } from 'react';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { AppState } from '../../../redux/initialStates/AppState';
import { ContextParams } from '../../../types/ContextParams.interface';
import { MenuResourceActions, PrincipalPermissions, ResourceType, SecurityScope, Verifier } from '@ready/security.core';
import MenuBuilderTab from '../../types/MenuBuilderTab.enum';
import { IMenu, MenuCopyStatus } from '@ready/menu.core';
import { RootState, useAppDispatch } from '../../../redux/store';
import { ILocationsState } from '../../redux/LocationsState';
import { CopyMenuState } from '../../redux/CopyMenuState';
import { selectLocationsState } from '../../redux/LocationsSelectors';
import { selectCopyMenuState } from '../../redux/CopyMenuSelectors';
import { LocationsThunks } from '../../redux/LocationsThunks';
import { CopyMenuThunks } from '../../redux/CopyMenuThunks';
import { resetCopyMenuState } from '../../redux/CopyMenuActions';
import {
  advancedSettingsDialogVisible,
  deleteMenu,
  deleteMenuGallery,
  initializeAdvancedSettings,
  loadMenus,
  menuGalleryCancelCreateMode,
  menuGallerySetCreateMode,
  MENUS_ACTIONS,
  moveMenu,
  prepareReorderMenusModal,
  requestDeleteMenuGallery,
  resetForms,
  saveMenuOrder,
  setMenuCreateMode,
  setMenuEditMode,
  showDeleteMenuModal,
  updateMenuDescription,
  updateMenuDisplayName,
  updateMenuScheduler,
  updateMenuStatus,
} from '../../redux/MenusActions';
import styles from './MenusPage.module.scss';
import MenuBuilderLayoutContent from '../MenuBuilderLayoutContent';
import MenuFormModal from './MenuFormModal';
import Button from '../../../components/Button/Button';
import OverflowButton from '../../../components/OverflowButton/OverflowButton';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import MenuEntryPanel from './MenuEntryPanel';
import MenuItemGalleryEntryPanel from './MenuItemGalleryEntryPanel';
import { DeleteConfirmationModal } from '../../../components/Modal';
import ReorderDialog from './ReorderDialog';
import { Routes } from '../../MenuBuilderRouter';
import { getScheduleList, initScheduleList } from '../../redux/ScheduleListActions';
import { IMenuWithType } from '../../redux/MenusState';
import AdvancedSettingsDialog from './AdvancedSettingsDialog';
import { scrollTo } from '../../../utils/scrollTo';
import EmptyResult, { EmptyResultSize } from '../../../components/EmptyResult/EmptyResult';
import Notification from '../../../components/Notification/Notification';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';
import CopyMenuDialog from './CopyMenuDialog';
import { saveMenu } from 'menus/redux/MenuActions/SaveMenu';
import { MenuConfigPicker } from 'menus/menuConfigs/components/MenuConfigPicker/MenuConfigPicker';
import { setUserSelectedMenuConfigId } from 'menus/menuConfigs/redux/slice';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { useMenuConfigs } from 'menus/menuConfigs/hooks/useMenuConfigs';

const mapStateToProps = (state: AppState) => ({
  permissions: state.session.permissions.permissionsList,
  loading: state.menuBuilder.menus.loading,
  reordering: state.menuBuilder.menus.reordering,
  processing: state.menuBuilder.menus.processing,
  items: state.menuBuilder.menus.items,
  itemsForReordering: state.menuBuilder.menus.itemsForReordering,
  deleteRequested: state.menuBuilder.menus.deleteRequested,
  deleteProcessing: state.menuBuilder.menus.deleteProcessing,
  menuToDelete: state.menuBuilder.menus.menuToDelete,
  deletedMenu: state.menuBuilder.menus.deletedMenu,
  menuForm: state.menuBuilder.menus.menuForm,
  menuGalleryForm: state.menuBuilder.menus.menuGalleryForm,
  schedules: state.menuBuilder.scheduleList.schedules,
  advancedSettingsProps: state.menuBuilder.menus.advancedSettings,
  itemsAndGroupsSelection: state.menuBuilder.menus.itemsAndGroupsSelection,
  menuGalleryDeleteRequested: state.menuBuilder.menus.menuGalleryDeleteRequested,
  menuGalleryDeleted: state.menuBuilder.menus.menuGalleryDeleted,
  menuGalleryToDelete: state.menuBuilder.menus.menuGalleryToDelete,
  menuGalleryDeleteProcessing: state.menuBuilder.menus.menuGalleryDeleteProcessing,
  areSchedulesLoading: state.menuBuilder.scheduleList.loading,
});

const actionCreators = {
  loadMenus,
  setMenuCreateMode,
  setMenuEditMode,
  updateMenuDisplayName,
  updateMenuDescription,
  updateMenuStatus,
  saveMenu,
  showDeleteMenuModal,
  deleteMenu,
  prepareReorderMenusModal,
  moveMenu,
  saveMenuOrder,
  updateMenuScheduler,
  resetForms,
  getScheduleList,
  initScheduleList,
  advancedSettingsDialogVisible,
  menuGallerySetCreateMode,
  menuGalleryCancelCreateMode,
  requestDeleteMenuGallery,
  deleteMenuGallery,
  initializeAdvancedSettings,
};

const connector = connect(mapStateToProps, actionCreators);

export type MenusPageProps = ConnectedProps<typeof connector>;

const MenusPage = (props: MenusPageProps): JSX.Element => {
  const {
    permissions,
    loading,
    reordering,
    processing,
    items,
    menuForm,
    menuGalleryForm,
    itemsForReordering,
    deleteRequested,
    deleteProcessing,
    menuToDelete,
    deletedMenu,
    schedules,
    loadMenus,
    setMenuCreateMode,
    setMenuEditMode,
    updateMenuDisplayName,
    updateMenuDescription,
    updateMenuStatus,
    saveMenu,
    showDeleteMenuModal,
    deleteMenu,
    prepareReorderMenusModal,
    moveMenu,
    saveMenuOrder,
    updateMenuScheduler,
    resetForms,
    getScheduleList,
    initScheduleList,
    advancedSettingsProps,
    advancedSettingsDialogVisible,
    menuGallerySetCreateMode,
    menuGalleryCancelCreateMode,
    menuGalleryDeleteRequested,
    menuGalleryDeleted,
    menuGalleryToDelete,
    requestDeleteMenuGallery,
    menuGalleryDeleteProcessing,
    deleteMenuGallery,
    initializeAdvancedSettings,
    areSchedulesLoading,
  } = props;
  const { location } = useSelector<RootState, ILocationsState>(selectLocationsState);
  const { menuCopyStateLoading, menuCopyState, menuCopyStateError, acknowledgementProcessing } = useSelector<
    RootState,
    CopyMenuState
  >(selectCopyMenuState);
  const [menuCopyRequested, setMenuCopyRequested] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { contextId: companyId, locationId, menuConfigId } = useParams<ContextParams & { menuConfigId?: string }>();

  const principalPermissions = new PrincipalPermissions(permissions);
  const hasManagePermission = Verifier.check(
    principalPermissions,
    SecurityScope.location,
    ResourceType.menu,
    MenuResourceActions.all,
    locationId
  );
  const hasSourceLocationCopyPermission =
    menuCopyState?.fromLocation &&
    Verifier.check(
      principalPermissions,
      SecurityScope.location,
      ResourceType.menu,
      MenuResourceActions.all,
      menuCopyState.fromLocation._id
    );

  const onSaveMenu = (): void => {
    saveMenu({ companyId, locationId, menu: menuForm.menu, summaryOnly: true });
  };

  const menuGalleryEditMode = menuGalleryForm.editing;
  const createNewMenuGallery = menuGalleryEditMode && !menuGalleryForm.menu._id;
  const menuGalleryProcessing = menuGalleryForm.processing;

  const addNewMenuHandler = () => {
    menuGallerySetCreateMode(Math.max(...items.map((item) => item.sortOrder || 0)) + 1);
    scrollTo('newMenuGalleryEntry');
  };

  const newItemGalleryButton = {
    label: '+ New Item Gallery',
    onClick: addNewMenuHandler,
  };
  const reorderMenusButton = {
    label: 'Reorder Menus',
    primary: false,
    onClick: (): void => {
      prepareReorderMenusModal(true);
    },
  };
  const addMenuButton = {
    label: '+ New Menu',
    onClick: () => setMenuCreateMode(true, Math.max(...items.map((item) => item.sortOrder)) + 1),
  };
  const advancedSettings = {
    label: 'Advanced Settings',
    onClick: () => advancedSettingsDialogVisible(true),
  };
  const createMenuFromCopy = {
    label: 'Create Menu from Copy',
    disabled: !hasManagePermission,
    onClick: () => {
      setMenuCopyRequested(true);
      dispatch(CopyMenuThunks.launchMenuCopyModal({ companyId, locationId }));
    },
  };
  const controlOptions = [
    newItemGalleryButton,
    addMenuButton,
    reorderMenusButton,
    advancedSettings,
    createMenuFromCopy,
  ];

  const setShowCopyMenuModal = (): void => {
    setMenuCopyRequested(false);
    dispatch(resetCopyMenuState());
  };

  const onEditMenuClick = (menu: IMenu) => {
    setMenuEditMode(true, menu);
  };

  const onDeleteMenuClick = (menu: IMenu) => {
    showDeleteMenuModal(true, menu);
  };

  const onConfirmDeleteMenu = () => {
    if (menuToDelete) {
      deleteMenu(companyId, locationId, menuToDelete);
    }
  };

  const onConfirmDeleteMenuGallery = () => {
    if (menuGalleryToDelete.id) {
      deleteMenuGallery(companyId, locationId, menuGalleryToDelete.id);
    }
  };

  const onSaveMenus = (menus: IMenuWithType[]): void => {
    saveMenuOrder(companyId, locationId, menus);
  };

  const fetchLocation = React.useCallback(
    (companyId: string, locationId: string, refetch: boolean) => {
      if (!location || location.id !== locationId || refetch) {
        dispatch(LocationsThunks.loadSelectedLocation({ companyId, locationId }));
      }
    },
    [location, dispatch]
  );

  const fetchData = React.useCallback(
    async (companyId: string, locationId: string) => {
      await loadMenus(companyId, locationId);
    },
    [loadMenus]
  );

  const suppressSectionsInNavigation = !!location?.settings.suppressSectionsInNavigation;
  const menuReadOnly = !!location?.settings.menuReadOnly;
  const initSettings = React.useCallback(() => {
    initializeAdvancedSettings(!suppressSectionsInNavigation, menuReadOnly);
  }, [initializeAdvancedSettings, suppressSectionsInNavigation, menuReadOnly]);

  const cleanUp = React.useCallback(() => {
    resetForms();
    menuGalleryCancelCreateMode();
    dispatch(resetCopyMenuState());
    dispatch(setUserSelectedMenuConfigId(undefined));
  }, [resetForms, menuGalleryCancelCreateMode, dispatch]);

  React.useEffect(() => {
    fetchLocation(companyId, locationId, advancedSettingsProps.sync);
    initSettings();
  }, [fetchLocation, companyId, locationId, advancedSettingsProps.sync, initSettings]);

  React.useEffect(() => {
    if (!(menuCopyState || menuCopyStateLoading || menuCopyStateError)) {
      dispatch(CopyMenuThunks.loadMenuCopyState({ companyId, locationId }));
    }
  }, [companyId, locationId, menuCopyStateLoading, menuCopyStateError, menuCopyState, dispatch]);

  React.useEffect(() => {
    return () => {
      cleanUp();
    };
  }, [cleanUp]);

  React.useEffect(() => {
    getScheduleList(companyId, locationId);
    return () => {
      initScheduleList();
    };
  }, [getScheduleList, initScheduleList, companyId, locationId]);

  const [menuConfigs, activeMenuConfig, userSelectedMenuConfig] = useMenuConfigs(menuConfigId);

  const isSharedConfig = userSelectedMenuConfig
    ? userSelectedMenuConfig.parentTemplateId !== undefined
    : activeMenuConfig?.parentTemplateId !== undefined;

  useEffect(() => {
    if (userSelectedMenuConfig)
      dispatch({
        type: MENUS_ACTIONS.LOAD_MENU_BUILDER_MENUS_SUCCESS,
        payload: userSelectedMenuConfig?.menusAndGalleries,
      });
  }, [dispatch, userSelectedMenuConfig]);

  const { push } = useHistory();
  const onChangeSelectedMenuConfig = (menuConfigId: string) => {
    push(Routes.getMenusPage(companyId, locationId, menuConfigId));
  };

  React.useEffect(() => {
    fetchData(companyId, locationId);
  }, [
    fetchData,
    companyId,
    locationId,
    menuForm.saved,
    menuGalleryForm.saved,
    deletedMenu, // ensures we reload the list after deleting a menu
    menuGalleryDeleted,
  ]);

  // We only want to hide the menu config picker if the only one available and active is the location custom menus
  const hideMenuConfigPicker =
    menuConfigs.length === 1 && menuConfigs[0].parentTemplateId === undefined && menuConfigs[0].isActive === true;

  return (
    <>
      {menuForm.editing && (
        <MenuFormModal
          setShowModal={setMenuCreateMode}
          onChangeDisplayName={updateMenuDisplayName}
          onChangeDescription={updateMenuDescription}
          onChangeStatus={updateMenuStatus}
          onSave={onSaveMenu}
          menuForm={menuForm}
          header={menuForm.menu._id ? 'Edit Menu' : 'New Menu'}
          saveLabel={menuForm.menu._id ? 'Save Changes' : 'Create Menu'}
          onChangeSchedule={updateMenuScheduler}
          schedules={schedules}
          areSchedulesLoading={areSchedulesLoading}
          isSharedMenu={isSharedConfig}
        />
      )}

      {advancedSettingsProps.isDialogVisible && (
        <AdvancedSettingsDialog companyId={companyId} locationId={locationId} />
      )}

      {menuCopyRequested && <CopyMenuDialog setShowModal={setShowCopyMenuModal} />}

      <ReorderDialog
        visible={reordering}
        processing={processing}
        title='Reorder Menus'
        items={itemsForReordering}
        setVisible={prepareReorderMenusModal}
        keyExtractor={(menu: IMenu) => menu._id}
        labelExtractor={(menu: IMenu) => menu.displayName}
        onMove={moveMenu}
        onSave={onSaveMenus}
      />

      {deleteRequested && menuToDelete && (
        <DeleteConfirmationModal
          setShowModal={showDeleteMenuModal}
          item='Menu'
          itemName={menuToDelete.displayName}
          loading={deleteProcessing}
          handleDelete={onConfirmDeleteMenu}
        />
      )}

      {menuGalleryDeleteRequested && (
        <DeleteConfirmationModal
          setShowModal={requestDeleteMenuGallery}
          item='Item Gallery'
          itemName={menuGalleryToDelete.name}
          loading={menuGalleryDeleteProcessing}
          handleDelete={onConfirmDeleteMenuGallery}
        />
      )}

      <MenuBuilderLayoutContent
        companyId={companyId}
        locationId={locationId}
        locationName={location && location.id === locationId ? location.name : null}
        stockStatusOnly={!hasManagePermission}
        tab={MenuBuilderTab.MENUS}
      >
        <div className={styles.container}>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              {menuCopyState?.status === MenuCopyStatus.error && !menuCopyState?.acknowledged && (
                <Notification className={styles.menuCopyError}>
                  <p>
                    <i className={Icon.Alert} />
                    <span>
                      Copying menu data from{' '}
                      <span className={styles.menuCopyErrorHighlight}>{menuCopyState.fromLocation!.name}</span> to this
                      location failed to complete. Only a portion of the data may have copied over. You can hit{' '}
                      <span className={styles.menuCopyErrorHighlight}>Retry Now</span> and attempt to run the copy
                      process again, or <span className={styles.menuCopyErrorHighlight}>Dismiss</span> this message
                      which will leave the menu as is.
                    </span>
                  </p>
                  <div className={styles.menuCopyErrorControls}>
                    <Button
                      label='Dismiss'
                      variant='alert-secondary'
                      loading={acknowledgementProcessing}
                      disabled={!hasManagePermission}
                      onClick={() => dispatch(CopyMenuThunks.acknowledgeFailedMenuCopy({ companyId, locationId }))}
                    />
                    <Button
                      label='Retry Now'
                      variant='alert'
                      disabled={!hasManagePermission || !hasSourceLocationCopyPermission}
                      additionalStyles={styles.menuCopyErrorButton}
                      onClick={() => {
                        setMenuCopyRequested(true);
                        dispatch(
                          CopyMenuThunks.launchMenuCopyModal({
                            companyId,
                            locationId,
                            selectedLocation: menuCopyState?.fromLocation
                              ? {
                                  _id: menuCopyState.fromLocation._id,
                                  name: `${menuCopyState.fromLocation.name}`,
                                  publicName: '',
                                  fullAddress: '',
                                }
                              : undefined,
                          })
                        );
                      }}
                    />
                  </div>
                </Notification>
              )}

              <div className={styles.header}>
                {hideMenuConfigPicker ? (
                  <div className={styles.title}>Menus</div>
                ) : (
                  <div className={styles.menuConfigHeader}>
                    <div className={styles.label}>
                      Menu Configurations&nbsp;
                      <Tooltip
                        text={
                          'Select a configuration to preview it. Only the active configuration will be displayed to guests. To change which configuration is set to active, navigate to the Shared Menus manager.'
                        }
                      >
                        <TextIcon icon={Icon.InfoCircle} />
                      </Tooltip>
                    </div>
                    <MenuConfigPicker
                      className={styles.menuConfigPicker}
                      menuConfigs={menuConfigs}
                      activeMenuConfig={activeMenuConfig}
                      userSelectedMenuConfig={userSelectedMenuConfig}
                      onChange={onChangeSelectedMenuConfig}
                    />
                  </div>
                )}

                {!isSharedConfig && !!activeMenuConfig && (
                  <div className={styles.controls}>
                    <div className={styles.buttons}>
                      <div className={styles.button}>
                        <Button
                          label={newItemGalleryButton.label}
                          variant='primary'
                          onClick={newItemGalleryButton.onClick}
                          unavailable={createNewMenuGallery || menuGalleryEditMode || menuGalleryProcessing}
                        />
                      </div>
                      <div className={styles.button}>
                        <Button
                          label={addMenuButton.label}
                          variant='primary'
                          onClick={addMenuButton.onClick}
                          unavailable={createNewMenuGallery || menuGalleryEditMode || menuGalleryProcessing}
                        />
                      </div>
                      <div className={styles.buttonsOverflowDesktop}>
                        <OverflowButton
                          options={controlOptions.slice(2)}
                          disabled={createNewMenuGallery || menuGalleryEditMode || menuGalleryProcessing}
                        />
                      </div>
                    </div>
                    <div className={styles.buttonsOverflow}>
                      <OverflowButton
                        options={controlOptions}
                        disabled={createNewMenuGallery || menuGalleryEditMode || menuGalleryProcessing}
                      />
                    </div>
                  </div>
                )}
              </div>

              {items.length === 0 &&
                (!!activeMenuConfig && !isSharedConfig ? (
                  <EmptyResult
                    title={'Create Your First Menu & Item Gallery'}
                    paragraph={`
                      Menus are broad categories of items such as “Food” or “Drinks”.
                      Each menu can contain multiple sections that further group your items,
                      such as “Appies” or “Entrees”.
                      Item Galleries are used to highlight up to 5 featured products
                      to promote to your guests. `}
                    size={EmptyResultSize.Regular}
                  />
                ) : (
                  <EmptyResult
                    title='No Menu Selected'
                    paragraph='To change which configuration is set to active, navigate to the Locations tab in the Menu Builder.'
                    size={EmptyResultSize.Regular}
                  />
                ))}
              {items.map((entry: IMenuWithType) => {
                const editMenuGallery = menuGalleryForm.editing && menuGalleryForm.menu._id === entry._id;
                return entry.type === 'itemGallery' ? (
                  <MenuItemGalleryEntryPanel
                    key={entry._id}
                    isEditable={editMenuGallery}
                    disabled={createNewMenuGallery || menuGalleryEditMode || menuGalleryProcessing}
                    item={entry}
                    companyId={companyId}
                    locationId={locationId}
                    menuConfigId={menuConfigId}
                    isSharedConfig={isSharedConfig}
                  />
                ) : (
                  <MenuEntryPanel
                    key={entry._id}
                    menu={entry}
                    linkTo={Routes.getMenuPage(companyId, locationId, entry._id, menuConfigId)}
                    onEditClick={onEditMenuClick}
                    onDeleteClick={onDeleteMenuClick}
                    scheduleLink={entry.schedule}
                    disabled={createNewMenuGallery || menuGalleryEditMode || menuGalleryProcessing}
                    isSharedMenu={isSharedConfig}
                  />
                );
              })}

              <div id='newMenuGalleryEntry'>
                {createNewMenuGallery && (
                  <MenuItemGalleryEntryPanel
                    isEditable={true}
                    disabled={false}
                    item={menuGalleryForm.menu}
                    companyId={companyId}
                    locationId={locationId}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </MenuBuilderLayoutContent>
    </>
  );
};

export default connector(MenusPage);
