import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { AppState } from '../../../redux/initialStates/AppState';
import MenuItemsView from '../../types/MenuItemsView.enum';
import { setMenuItemsModalQuery, setMenuItemsModalView } from '../../redux/ItemsAndModsActions';

import styles from './AddMenuItemsSearch.module.scss';

import SearchInput from '../../../components/SearchInput/SearchInput';
import ToggleButtons, { ToggleButton } from '../../../components/ToggleButtons/ToggleButtons';

export type AddMenuItemsSearchProps = ConnectedProps<typeof connector> & {
  typeSelection: boolean;
};

const AddMenuItemsSearch = (props: AddMenuItemsSearchProps) => {
  const { query, view, typeSelection, setMenuItemsModalQuery, setMenuItemsModalView } = props;

  const filterButtons: ToggleButton[] = [
    {
      label: 'Modifiers',
      selected: view === MenuItemsView.MODS,
      onClick: () => setMenuItemsModalView(MenuItemsView.MODS),
    },
    {
      label: 'Menu Items',
      selected: view === MenuItemsView.ITEMS,
      onClick: () => setMenuItemsModalView(MenuItemsView.ITEMS),
    },
  ];

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMenuItemsModalQuery(event.target.value);
  };

  return (
    <>
      <SearchInput
        value={query}
        placeholder='Search menu items'
        fullWidth
        onChange={handleQueryChange}
        autoFocus={true}
      />
      {typeSelection && (
        <div className={styles.toggles}>
          <ToggleButtons buttons={filterButtons} />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  query: state.menuBuilder.itemsAndMods.menuItemSelection.query,
  view: state.menuBuilder.itemsAndMods.menuItemSelection.view,
});

const actionCreators = {
  setMenuItemsModalQuery,
  setMenuItemsModalView,
};

const connector = connect(mapStateToProps, actionCreators);

export default connector(AddMenuItemsSearch);
