import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GuestSatisfaction } from './components/GuestSatisfaction/GuestSatisfaction';
import { TableHeader } from '../../components/TableV2/TableHeader/TableHeader';
import { TableV2 } from '../../components/TableV2/TableV2';
import { Body, BodySkeleton } from '../../components/TableV2/Body/Body';
import LayoutContent from '../../components/AppLayout/LayoutContent';
import HeaderControls, { ControlOption } from '../../components/AppLayout/HeaderControls';
import { ToolBar } from './components/ToolBar/ToolBar';
import { cells, headerCells, initDateFilter } from './feedbackReportSettings';
import { usePageTitle, useSearchParams } from '../../hooks';
import { StarRating } from './components/StarRating/StarRating';
import { PaginationContainer } from '../../components/PaginationContainer/PaginationContainer';
import styles from './FeedbackReport.module.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { initDateFilterUrlQuery } from '../../utils/urlUtils/initDateFilterUrlQuery';
import {
  exportFeedbacksListThunk,
  fetchFeedbacksListThunk,
  fetchGuestSatisfactionThunk,
  fetchPermittedLocationsThunk,
  fetchStarRatingThunk,
} from './redux/thunks';
import { feedbackReportSelector } from './redux/selectors';

export function FeedbackReportPage() {
  usePageTitle('Feedback', 'Reports');
  const { query, page, sortColumn, sortDirection } = useSearchParams();
  const { pathname } = useLocation();
  const { push } = useHistory();

  const {
    contextId,
    permittedLocations,
    starRating,
    loading,
    guestSatisfaction,
    headerColumns,
    columns,
    rowKey,
    items,
    pagination,
  } = useSelector(feedbackReportSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!query) {
      const redirectUrl = `${pathname}?${initDateFilterUrlQuery}`;
      push(redirectUrl);
    }
  }, [pathname, push, query, dispatch]);

  useEffect(() => {
    dispatch(fetchPermittedLocationsThunk(contextId));
  }, [contextId, dispatch]);

  useEffect(() => {
    if (query) {
      dispatch(fetchGuestSatisfactionThunk({ contextId, query }));
      dispatch(fetchStarRatingThunk({ contextId, query }));
    }
  }, [contextId, query, dispatch]);

  useEffect(() => {
    if (query) {
      dispatch(fetchFeedbacksListThunk({ contextId, query, page, sortColumn, sortDirection }));
    }
  }, [contextId, query, page, sortColumn, sortDirection, dispatch]);

  const headerControlOptions: Array<ControlOption> = [
    {
      buttonLabel: 'Export',
      loading: loading.export,
      onClick: async () => dispatch(exportFeedbacksListThunk({ contextId, query, sortColumn, sortDirection })),
    },
  ];

  return (
    <LayoutContent
      title='Feedback'
      headerControls={<HeaderControls controlOptions={headerControlOptions} />}
      toolbarContent={<ToolBar initDateFilter={initDateFilter} locations={permittedLocations.results} />}
      containerType='within'
      scrollToolbarForMobile
    >
      <div className={styles.feedback}>
        <div className={styles.feedback__widgets}>
          <div className={styles.feedback__widgetsContainer}>
            <StarRating data={starRating} loading={loading.starRating} />
            <GuestSatisfaction data={guestSatisfaction} loading={loading.guestSatisfaction} />
          </div>
        </div>
        <PaginationContainer pagination={pagination}>
          <TableV2 loading={loading.feedbackList}>
            <>
              <TableHeader cells={headerCells} data={headerColumns} columns={columns} />
              {loading.feedbackList ? (
                <BodySkeleton columns={columns} rowsNumber={50} />
              ) : (
                <Body cells={cells} hiddenColumns={{}} rowKey={rowKey} data={items} columns={columns} />
              )}
            </>
          </TableV2>
        </PaginationContainer>
      </div>
    </LayoutContent>
  );
}
