import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { updatePath } from '../../utils/updatePath';
import { useSearchParams } from '../../hooks/useSearchParams';
import SearchInput from './SearchInput';

export interface SearchParamInputProps {
  placeholder?: string;
  additionalClassName?: string;
  additionalContainerClassName?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  additionalParams?: string;
  autoFocus?: boolean;
}

const SearchParamInput = (props: SearchParamInputProps) => {
  const {
    placeholder = '',
    additionalClassName = '',
    additionalContainerClassName,
    fullWidth = false,
    disabled = false,
    autoFocus = false,
    additionalParams,
  } = props;

  const { push } = useHistory();
  const { pathname } = useLocation();
  const { query: encodedQuery } = useSearchParams();
  const query = decodeURIComponent(encodedQuery || '');

  const [value, setValue] = React.useState(query);

  // Handles change via query param value
  React.useEffect(() => {
    setValue(query);
  }, [query]);

  // Handles change via user input
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    // update search input value
    setValue(value);

    // sanitize data before it gets added to the query URL string,
    const sanitizedValue = encodeURIComponent(value);

    // update url path based on this value, debounced (500ms) function
    updatePath(sanitizedValue, pathname, push, additionalParams);
  };

  return (
    <SearchInput
      value={value}
      placeholder={placeholder}
      fullWidth={fullWidth}
      disabled={disabled}
      additionalClassName={additionalClassName}
      additionalContainerClassName={additionalContainerClassName}
      onChange={handleChange}
      autoFocus={autoFocus}
    />
  );
};

export default SearchParamInput;
