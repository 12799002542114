import React from 'react';
import Button from '../Button/Button';
import IconButton from '../IconButton/IconButton';
import { ModalProps } from './Modal';
import classNames from 'classnames';
import TextIcon, { Icon } from '../Icon/TextIcon';
import readyProgressSpinner from '../../styles/assets/ready-progress-spinner-gray.svg';

export interface ModalHeaderProps extends Pick<ModalProps, 'setShowModal'> {
  headerLabel: string;
  subHeader?: React.ReactNode;
  withSpinner?: boolean;
  prefix?: React.ReactNode;
  headerWarning?: string;
  subLabel?: string;
  showDismissButton?: boolean;
  showDismissIcon?: boolean;
  displayInline?: boolean;
  additionalStyles?: string;
  additionalSubLabelStyles?: string;
}

const ModalHeader = (props: ModalHeaderProps) => {
  const {
    setShowModal,
    headerLabel,
    subHeader,
    withSpinner = false,
    prefix,
    headerWarning,
    subLabel,
    showDismissButton = false,
    showDismissIcon = false,
    displayInline = false,
    additionalStyles = '',
    additionalSubLabelStyles = '',
  } = props;

  const className = classNames('modal__header', displayInline ? 'displayInline' : '', additionalStyles);

  return (
    <div className={className}>
      <h3 className={withSpinner ? 'with-spinner' : ''}>
        {withSpinner && <img src={readyProgressSpinner} alt='loading' className='modal__header--spinner' />}
        {!!prefix && <span className='modal__header--prefix'>{prefix}</span>}
        {headerLabel}
        {!!headerWarning && (
          <span className='modal__header--warning'>
            {headerWarning}
            <TextIcon icon={Icon.Alert} additionalStyles='modal__header--warning-icon' />
          </span>
        )}
      </h3>
      <div className={additionalSubLabelStyles}>{subLabel}</div>
      {showDismissButton && <Button variant='primary' label='Done' onClick={() => setShowModal(false)} />}
      {showDismissIcon && (
        <IconButton onClick={() => setShowModal(false)}>
          <i className='icon-dashboard-icons_close' />
        </IconButton>
      )}
      {subHeader && <span className='modal__header--subheader'>{subHeader}</span>}
    </div>
  );
};

export default ModalHeader;
