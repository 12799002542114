import React from 'react';
import { useDispatch } from 'react-redux';
import { ToastElementMessage, ToastBody } from '../components/Toast/Toast';
import { toast } from 'react-toastify';
import { resetResponseState } from '../redux/actions/uiActions/responseStateActions';

export const useToastify = () => {
  const [toastMessages, setToastMessages] = React.useState([]);
  const dispatch = useDispatch();

  // show a single message
  const showToastifyMessage = (message: ToastElementMessage) => {
    let autoClose: number | boolean = 3000;
    if (message.message) {
      autoClose = false;
    }
    if (toastMessages) {
      clearToastifyMessages();
    }
    toast(<ToastBody {...message} />, {
      autoClose,
      onClose: onCloseCallback,
    });
  };

  // show multiple messages
  const showToastifyMessages = (messages: ToastElementMessage[]) => {
    messages.forEach((message: ToastElementMessage) => {
      let autoClose: number | boolean = 3000;
      if (message.message) {
        autoClose = false;
      }
      if (toastMessages) {
        clearToastifyMessages();
      }
      toast(<ToastBody {...message} />, {
        autoClose,
        onClose: onCloseCallback,
      });
    });
  };

  const clearToastifyMessages = () => {
    setToastMessages([]);
  };

  const onCloseCallback = () => {
    dispatch(resetResponseState());
  };

  return { showToastifyMessage, showToastifyMessages };
};
