import { IClientLocation } from '@ready/dashboardv2api.contracts';
import { IEmployee } from '@ready/employee.core';
import { ITable, ITableAssignment } from '@ready/table.core';
import LocationsService from '../../locations/services/LocationsService';
import { AssignTablesDialogMode } from '../components';
import { TableAssignmentService } from '../../services/tableAssignmentService';
import { IPagedResponse } from '../../services/types/IPagedResponse.type';
import { Action } from '../../redux/types';
import { toastErrorState, pageErrorState } from '../../redux/actions/uiActions/responseStateActions';
import { SERVER_ASSIGNMENT_ACTIONS } from './types';

export const fetchLocationDetails = (companyId: string, locationId: string) => async (dispatch: any) => {
  dispatch(setLoadingLocationDetails(true));

  try {
    const locationDetails = await LocationsService.getClientLocationById(companyId, locationId);
    dispatch(setLocationDetails(locationDetails));
  } catch (error) {
    dispatch(pageErrorState(error.status, error.message));
  } finally {
    dispatch(setLoadingLocationDetails(false));
  }
};

export const initLocationDetails = (): Action => ({
  type: SERVER_ASSIGNMENT_ACTIONS.INIT_SERVER_ASSIGNMENT_LOCATION_DETAILS,
});

const setLoadingLocationDetails = (isLoading: boolean): Action => ({
  type: SERVER_ASSIGNMENT_ACTIONS.SET_SERVER_ASSIGNMENT_LOCATION_DETAILS_LOADING,
  payload: isLoading,
});

const setLocationDetails = (locationDetails: IClientLocation) => ({
  type: SERVER_ASSIGNMENT_ACTIONS.SET_SERVER_ASSIGNMENT_LOCATION_DETAILS,
  payload: locationDetails,
});

/* Table assignment list actions */

export const loadTableLists = (companyId: string, locationId: string) => async (dispatch: any) => {
  dispatch(setTableListLoading(true));

  try {
    // First retrieve the list of table assignments, this will be needed to
    // determine which tables are assigned and which are unassigned.
    const tableAssignments = await TableAssignmentService.getTableAssignments(companyId, locationId);

    dispatch(setTableAssignmentList(tableAssignments));
    dispatch(setTableList(tableAssignments, await TableAssignmentService.getTables(companyId, locationId)));
  } catch (error) {
    dispatch(toastErrorState(error.status, error.message));
  } finally {
    dispatch(setTableListLoading(false));
  }
};

export const initTableList = (): Action => ({
  type: SERVER_ASSIGNMENT_ACTIONS.INIT_TABLE_LIST,
});

const setTableList = (tableAssignments: ITableAssignment[], tables?: ITable[]): Action => ({
  type: SERVER_ASSIGNMENT_ACTIONS.SET_TABLE_LIST,
  payload: { tables, tableAssignments },
});

const setTableAssignmentList = (tableAssignments: ITableAssignment[]): Action => ({
  type: SERVER_ASSIGNMENT_ACTIONS.SET_TABLE_ASSIGNMENT_LIST,
  payload: tableAssignments,
});

const setTableListLoading = (loading: boolean): Action => ({
  type: SERVER_ASSIGNMENT_ACTIONS.SET_TABLE_LIST_LOADING,
  payload: loading,
});

export const selectTable = (tableId: string): Action => ({
  type: SERVER_ASSIGNMENT_ACTIONS.TABLE_LIST_SELECT_TABLE,
  payload: tableId,
});

export const deselectTable = (tableId: string): Action => ({
  type: SERVER_ASSIGNMENT_ACTIONS.TABLE_LIST_DESELECT_TABLE,
  payload: tableId,
});

const deselectAllTables = (): Action => ({
  type: SERVER_ASSIGNMENT_ACTIONS.TABLE_LIST_DESELECT_ALL_TABLES,
});

const setPageProcessing = (isProcessing: boolean): Action => ({
  type: SERVER_ASSIGNMENT_ACTIONS.SET_SERVER_ASSIGNMENT_PAGE_PROCESSING,
  payload: isProcessing,
});

/* Table assignment modal actions */

export const assignTables =
  (companyId: string, locationId: string, employeeId: string, tableIds: string[]) => async (dispatch: any) => {
    dispatch(setAssignmentProcessing(true));

    try {
      await TableAssignmentService.assignTablesToServer(companyId, locationId, employeeId, tableIds);

      dispatch(closeAssignModal());
      dispatch(deselectAllTables());

      dispatch(refreshTableAssignments(companyId, locationId));
    } catch (error) {
      dispatch(toastErrorState(error.status, error.message));
    } finally {
      dispatch(setAssignmentProcessing(false));
    }
  };

const refreshTableAssignments = (companyId: string, locationId: string) => async (dispatch: any) => {
  dispatch(setPageProcessing(true));

  try {
    const tableAssignments = await TableAssignmentService.getTableAssignments(companyId, locationId);

    dispatch(setTableAssignmentList(tableAssignments));
    dispatch(setTableList(tableAssignments));
  } catch (error) {
    dispatch(toastErrorState(error.status, error.message));
  } finally {
    dispatch(setPageProcessing(false));
  }
};

export const removeTableFromAssignment =
  (companyId: string, locationId: string, tableAssignment: ITableAssignment, tableId: string) =>
  async (dispatch: any) => {
    dispatch(setPageProcessing(true));

    try {
      // Removes the table ID from the assignment
      const tableIds: string[] = tableAssignment.tables
        .filter((table) => table.id !== tableId)
        .map((table) => table.id);

      await TableAssignmentService.assignTablesToServer(companyId, locationId, tableAssignment.employee.id, tableIds);

      dispatch(refreshTableAssignments(companyId, locationId));
    } catch (error) {
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const removeAssignment =
  (companyId: string, locationId: string, employeeId: string) =>
  async (dispatch: any): Promise<boolean> => {
    dispatch(setPageProcessing(true));

    try {
      await TableAssignmentService.assignTablesToServer(companyId, locationId, employeeId, []);

      dispatch(refreshTableAssignments(companyId, locationId));
    } catch (error) {
      dispatch(toastErrorState(error.status, error.message));
      return false;
    }

    return true;
  };

export const initAssignModal = (): Action => ({
  type: SERVER_ASSIGNMENT_ACTIONS.INIT_SERVER_ASSIGNMENT_ASSIGN_MODAL,
});

export const openAssignModal = (mode: AssignTablesDialogMode, employeeId: string = ''): Action => ({
  type: SERVER_ASSIGNMENT_ACTIONS.SERVER_ASSIGNMENT_ASSIGN_MODAL_OPEN,
  payload: { mode, employeeId },
});

export const closeAssignModal = (): Action => ({
  type: SERVER_ASSIGNMENT_ACTIONS.SERVER_ASSIGNMENT_ASSIGN_MODAL_CLOSE,
});

export const setAssignModalQuery = (query: string): Action => ({
  type: SERVER_ASSIGNMENT_ACTIONS.SET_SERVER_ASSIGNMENT_ASSIGN_MODAL_QUERY,
  payload: query,
});

export const setSelectedServer = (serverId: string): Action => ({
  type: SERVER_ASSIGNMENT_ACTIONS.SET_SERVER_ASSIGNMENT_ASSIGN_MODAL_SELECTED_SERVER,
  payload: serverId,
});

export const loadServerList = (companyId: string, locationId: string, query: string) => async (dispatch: any) => {
  dispatch(setServerListLoading(true));

  // Always page 1 on first fetch
  const page = 1;

  try {
    dispatch(setServerList(await TableAssignmentService.getServers(companyId, locationId, query, page), page));
  } catch (error) {
    dispatch(toastErrorState(error.status, error.message));
  } finally {
    dispatch(setServerListLoading(false));
  }
};

export const syncThenFetchServers = (companyId: string, locationId: string, query: string) => async (dispatch: any) => {
  dispatch(setServerListLoading(true));

  // Consider this the same as the initial fetch, but the API is syncing with
  // the POS system first
  const page = 1;

  try {
    await TableAssignmentService.syncPOSServers(companyId, locationId);

    // After the sync was successful, fetch the updated list
    dispatch(setServerList(await TableAssignmentService.getServers(companyId, locationId, query, page), page));
  } catch (error) {
    dispatch(toastErrorState(error.status, error.message));
  } finally {
    dispatch(setServerListLoading(false));
  }
};

export const loadNextServerList =
  (companyId: string, locationId: string, query: string, currentPage: number) => async (dispatch: any) => {
    dispatch(setServerListPaginationLoading(true));

    const page = currentPage + 1;

    try {
      dispatch(appendServerList(await TableAssignmentService.getServers(companyId, locationId, query, page), page));
    } catch (error) {
      dispatch(toastErrorState(error.status, error.message));
    } finally {
      dispatch(setServerListPaginationLoading(false));
    }
  };

const setServerList = (servers: IPagedResponse<IEmployee>, page: number): Action => ({
  type: SERVER_ASSIGNMENT_ACTIONS.SET_SERVER_LIST,
  payload: { servers, page },
});

const appendServerList = (servers: IPagedResponse<IEmployee>, page: number): Action => ({
  type: SERVER_ASSIGNMENT_ACTIONS.APPEND_SERVER_LIST,
  payload: { servers, page },
});

const setServerListLoading = (isLoading: boolean): Action => ({
  type: SERVER_ASSIGNMENT_ACTIONS.SET_SERVER_LIST_LOADING,
  payload: isLoading,
});

const setServerListPaginationLoading = (isLoading: boolean): Action => ({
  type: SERVER_ASSIGNMENT_ACTIONS.SET_SERVER_LIST_PAGINATION_LOADING,
  payload: isLoading,
});

const setAssignmentProcessing = (isProcessing: boolean): Action => ({
  type: SERVER_ASSIGNMENT_ACTIONS.SET_SERVER_ASSIGNMENT_MODAL_PROCESSING,
  payload: isProcessing,
});

export const setRemoveTableAssignmentModalOpen = (isOpen: boolean, employeeId: string = ''): Action => ({
  type: SERVER_ASSIGNMENT_ACTIONS.SET_REMOVE_SERVER_ASSIGNMENT_MODAL_OPEN,
  payload: { isOpen, employeeId },
});
