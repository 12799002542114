import React from 'react';
import { compositeStyles } from '../../utils/cssUtils';
import styles from './LinkButton.module.scss';

interface LinkButtonProps {
  onClick: () => void;
  disabled?: boolean;
  children?: React.ReactNode;
  fontWeight?: 'bold' | 'normal';
}

const LinkButton = ({ onClick, disabled, children, fontWeight = 'bold' }: LinkButtonProps): JSX.Element => (
  <button
    type='button'
    disabled={disabled}
    onClick={onClick}
    className={compositeStyles([styles.link, { disabled, normalFontWeight: fontWeight === 'normal' }], styles)}
  >
    {children}
  </button>
);

export default LinkButton;
