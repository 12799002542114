import { IPickupHours, ISpecialOrderHours } from '@ready/dashboardv2api.contracts';
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { orderHoursInitialState, OrderHoursState } from 'companyLocations/redux/initialStates/orderHours';
import moment from 'moment';
import {
  getClientOrderHoursThunk,
  updateClientOrderHoursThunk,
  getClientSpecialOrderHoursThunk,
  saveClientSpecialOrderHoursThunk,
} from './thunk';

export const orderHoursSlice = createSlice({
  name: 'orderHours',
  initialState: orderHoursInitialState,

  reducers: {},

  extraReducers: (builder: ActionReducerMapBuilder<OrderHoursState>) => {
    builder.addCase(getClientOrderHoursThunk.pending, (state: OrderHoursState) => {
      state.loading = true;
    });
    builder.addCase(getClientOrderHoursThunk.fulfilled, (state: OrderHoursState, action: { payload: IPickupHours }) => {
      // Checking Friday to determine the existsance of disabled flag.
      // This can be achieved picking any other day
      const disabledFlagIsNotSet =
        action.payload.friday &&
        (action.payload.friday.disabled === null || action.payload.friday.disabled === undefined);

      if (disabledFlagIsNotSet) {
        const format = 'HH:mm';
        Object.keys(action?.payload).forEach((key: string) => {
          const payloadProps = action.payload[key as keyof IPickupHours];
          const startTime = moment(payloadProps.start, format);
          const endTime = moment(payloadProps.end, format);
          payloadProps.disabled = endTime.isSameOrBefore(startTime);
        });
      }

      state.loading = false;
      state.orderHoursEdit = state.orderHoursCache = action.payload;
    });
    builder.addCase(getClientOrderHoursThunk.rejected, (state: OrderHoursState) => {
      state.loading = false;
    });
    builder.addCase(
      updateClientOrderHoursThunk.fulfilled,
      (state: OrderHoursState, action: { payload: IPickupHours }) => {
        state.orderHoursEdit = state.orderHoursCache = action.payload;
      }
    );

    builder.addCase(getClientSpecialOrderHoursThunk.pending, (state: OrderHoursState) => {
      state.loading = true;
    });
    builder.addCase(
      getClientSpecialOrderHoursThunk.fulfilled,
      (state: OrderHoursState, action: { payload: ISpecialOrderHours[] }) => {
        state.loading = false;
        state.specialHoursEdit = state.specialHoursCache = action.payload;
      }
    );
    builder.addCase(getClientSpecialOrderHoursThunk.rejected, (state: OrderHoursState) => {
      state.loading = false;
    });

    builder.addCase(
      saveClientSpecialOrderHoursThunk.fulfilled,
      (state: OrderHoursState, action: { payload: ISpecialOrderHours[] }) => {
        state.specialHoursEdit = state.specialHoursCache = action.payload;
      }
    );
  },
});
