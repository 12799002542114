import { createAsyncThunk } from '@reduxjs/toolkit';
import { getEmailMarketingList, getPermittedEmailMarketingListLocations } from '../services/emailMarketingListService';
import { interceptErrorWithToastMessage } from '../../../redux/helpers/interceptErrorWithToastMessage';

export const fetchEmailMarketingListThunk = createAsyncThunk(
  'emailMarketingList/fetchList',
  interceptErrorWithToastMessage(getEmailMarketingList)
);

export const fetchPermittedLocationsThunk = createAsyncThunk(
  'emailMarketingList/permittedLocations',
  interceptErrorWithToastMessage(getPermittedEmailMarketingListLocations)
);
