import { format, parse } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

/**
 * This function takes a Date object that represents a given moment in time, and a time zone, and returns a new Date
 * object that represents the system time zone's equivalent of the given Date object's time in the given time zone. In
 * other words, it allows for a date and time within a given time zone to be represented as if the local system time
 * zone matches the given time zone.
 *
 * For example, for a Date object that represents 2000-06-15 12:00 pm in the America/Vancouver time zone, on a system
 * that has America/Winnipeg as its local time zone, this function will return a Date object that represents
 * 2000-06-15 12:00 pm in the America/Winnipeg time zone.
 *
 * Note that this is not the same as simply converting to a different time zone. A time of 12:00 pm in America/Vancouver
 * is 2:00 pm in America/Winnipeg, but this function returns 12:00 pm in America/Winnipeg. The returned Date is the
 * same displayed time, but not the same moment in time.
 *
 * @param date The date to convert
 * @param timeZone The time zone to use for the displayed date and time
 */
export const zonedTimeToSystemEquivalent = (date: Date, timeZone: string): Date => {
  const dateFormattedWithoutTimeZone = formatInTimeZone(date, timeZone, 'yyyy-MM-dd HH:mm:ss.SSSS');
  const systemTimeZone = format(date, 'X');
  const equivalentTimeInSystemTimeZone = `${dateFormattedWithoutTimeZone} ${systemTimeZone}`;
  return parse(equivalentTimeInSystemTimeZone, 'yyyy-MM-dd HH:mm:ss.SSSS X', new Date());
};
