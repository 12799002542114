import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
// components
import LayoutContent from '../../components/AppLayout/LayoutContent';
import { Form, FormControl, FormActionButtons } from '../../components/Form';
import TextInput from '../../components/TextInput/TextInput';
import TextField from '../../components/TextField/TextField';
import InputError from '../../components/InputError/InputError';
// redux
import { connect, ConnectedProps } from 'react-redux';
import {
  resetAdminForm,
  initializeAdminForm,
  updateFormGivenName,
  updateFormFamilyName,
  updateFormFamilyNameError,
  updateFormGivenNameError,
  putAdmin,
} from '../redux/AdminFormActions';
import { getAdminDetails } from '../redux/AdminDetailsActions';
import { IAdminDetailsState } from '../redux/AdminDetailsState';
import { IAdminForm } from '../redux/AdminFormState';
import { set200Toast, toastErrorState } from '../../redux/actions/uiActions/responseStateActions';
import { AppState } from '../../redux/initialStates/AppState';
import { IAdminUser } from '@ready/dashboardv2api.contracts';

export type AdminsEditPageProps = ReduxProps & {
  adminForm: IAdminForm;
  adminDetails: IAdminDetailsState;
};

const AdminsEditPage = (props: AdminsEditPageProps) => {
  const {
    resetAdminForm,
    adminForm,
    adminDetails,
    getAdminDetails,
    set200Toast,
    initializeAdminForm,
    updateFormGivenName,
    updateFormFamilyName,
    updateFormFamilyNameError,
    updateFormGivenNameError,
    putAdmin,
  } = props;
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { admin, loading } = adminDetails;
  const { adminFormData, loading: loadingForm } = adminForm;
  const { givenNameError, familyNameError } = adminForm.formValidation;

  // reset admin form on page load
  React.useEffect(() => {
    resetAdminForm();
  }, [resetAdminForm]);

  // get admin details
  React.useEffect(() => {
    async function getAdminDetailsPage() {
      getAdminDetails(id);
    }
    getAdminDetailsPage();
  }, [getAdminDetails, id]);

  // initialize admin form
  React.useEffect(() => {
    if (admin && admin.id) {
      const adminFormData: IAdminUser = { ...admin };
      initializeAdminForm(adminFormData);
    }
  }, [admin, initializeAdminForm]);

  const onSubmit = (event: React.FocusEvent) => {
    event.preventDefault();
    if (!validateForm(adminForm.adminFormData)) return;
    async function putAdminPage() {
      if (admin) {
        putAdmin(id, admin.uid, adminForm.adminFormData);
      }
    }
    putAdminPage();
  };

  const validateForm = (admin: IAdminUser): boolean => {
    let validForm = true;
    if (admin.firstName === '') {
      updateFormGivenNameError(true);
      validForm = false;
    }
    if (admin.lastName === '') {
      updateFormFamilyNameError(true);
      validForm = false;
    }
    return validForm;
  };

  const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    history.push('/ready/admins');
  };

  // handle admin updated
  React.useEffect(() => {
    if (!!adminForm.adminIdTransaction) {
      set200Toast();
      history.push(`/ready/admins/${adminForm.adminIdTransaction}`);
    }
  }, [adminForm.adminIdTransaction, history, set200Toast]);

  return (
    <LayoutContent title='Edit Admin' loadingContent={loading} containerType='within'>
      {adminFormData && (
        <Form formTitle='Profile' onSubmit={onSubmit}>
          <FormControl label='First Name'>
            <TextInput
              placeholder='e.g. Gordon'
              loading={loadingForm}
              value={adminFormData.firstName}
              onChange={(e) => updateFormGivenName(e.target.value)}
            />
            <>{givenNameError && <InputError message='First Name is required' />}</>
          </FormControl>
          <FormControl label='Last Name'>
            <TextInput
              placeholder='e.g. Ramsay'
              loading={loadingForm}
              value={adminFormData.lastName}
              onChange={(e) => updateFormFamilyName(e.target.value)}
            />
            <>{familyNameError && <InputError message='Last Name is required' />}</>
          </FormControl>
          <FormControl label='Email Address'>
            <TextField value={adminFormData.email} />
          </FormControl>

          <FormActionButtons handleCancel={handleCancel} loading={loadingForm} />
        </Form>
      )}
    </LayoutContent>
  );
};

const mapStateToProps = (state: AppState) => ({
  adminDetails: state.admins.adminDetails,
  adminForm: state.admins.adminForm,
});

const actionCreators = {
  resetAdminForm,
  getAdminDetails,
  set200Toast,
  toastErrorState,
  initializeAdminForm,
  updateFormGivenName,
  updateFormFamilyName,
  updateFormFamilyNameError,
  updateFormGivenNameError,
  putAdmin,
};

const connector = connect(mapStateToProps, actionCreators);
type ReduxProps = ConnectedProps<typeof connector>;
export default connector(AdminsEditPage);
