import { IDailyTotalsSummaryReportState } from './types';
import { IPaymentSdrReportItem, PaginationResponse } from '@ready/dashboardv2api.contracts';
import { IPagedResponse } from '@ready/table.core';
import { ILocation } from '../../../services/types/ILocation.type';

export const fetchDailyTotalsReportReducers = {
  pending: (state: IDailyTotalsSummaryReportState) => {
    state.loading.list = true;
  },
  fulfilled: (
    state: IDailyTotalsSummaryReportState,
    action: { payload: PaginationResponse<IPaymentSdrReportItem> }
  ) => {
    const { items, total, limit, start, length } = action.payload;
    state.loading.list = false;

    // need to convert money to cents for display purposes.
    state.items = items.map((x) => {
      return {
        ...x,
        amount: x.amount * 100,
        net: x.net * 100,
        processorFees: x.processorFees * 100,
        totalFees: x.totalFees && x.totalFees * 100,
        industryFees: x.industryFees && x.industryFees * 100,
        payout: x.payout && x.payout * 100,
      };
    });
    state.pagination = { total, limit, start, length };
  },
  rejected: (state: IDailyTotalsSummaryReportState) => {
    state.loading.list = false;
    state.errors.list = 'Unable to retrieve Promo Code Summary list.';
    state.items = [];
  },
};

export const fetchPermittedLocationsReducers = {
  pending: (state: IDailyTotalsSummaryReportState) => {
    state.loading.permittedLocations = true;
  },
  fulfilled: (state: IDailyTotalsSummaryReportState, action: { payload: IPagedResponse<ILocation> }) => {
    state.loading.permittedLocations = false;
    state.permittedLocations = action.payload;
  },
  rejected: (state: IDailyTotalsSummaryReportState) => {
    state.loading.permittedLocations = false;
  },
};
