import React from 'react';
import { ICompanyIntegrations, IServiceChargeSettings, PosSystemType } from '@ready/dashboardv2api.contracts';
import { FormGroup, FormControl } from '../../../components/Form';
import SelectFilter, { Option } from '../../../components/SelectFilter/SelectFilter';
import OmnivorePOS from './OmnivorePOS';
import BrinkPOS from './BrinkPOS';
import Bypass2POS from './Bypass2POS';
import { IPosSettingsPanel, IPosSettingsValidation } from '../../redux/PanelPosSettingsState';
import { LOCATION_SETTINGS_ACTIONS } from '../../redux/LocationSettingsActions';
import { UI_ACTIONS } from '../../../redux/actions/types';
import { LIST_OPTIONS } from '../../../utils/selectListUtils/listOptions';
import { IUIDropDown } from '../../../redux/initialStates/ui/dropDownState';
import { changePosIntegrationOption } from '../../../redux/actions/uiActions/uiActions';
import { getSelectedOption } from '../../../utils/selectListUtils/listUtil';
import { useDispatch } from 'react-redux';
import SquareSettingsPOS from './SquareSettingsPOS';
import { IUpdateLocationParam } from '../LocationsEditPage';
import ForcedServiceCharges, { ServiceChargePosIntegration } from './ForcedServiceCharges';
import { SimphonyPOS } from './simphony/SimphonyPOS';

interface Props {
  companyId: string;
  locationId: string;
  isProcessing: boolean;
  updateLocationParam: IUpdateLocationParam;
  posSettingsPanel: IPosSettingsPanel;
  dropDown: IUIDropDown;
  companyIntegrations?: ICompanyIntegrations;
}

const LocationPanelPOS = (props: Props) => {
  const { isProcessing, updateLocationParam, posSettingsPanel, dropDown } = props;
  const { posIntegration } = dropDown;

  const dispatch = useDispatch();

  const handleSelectOptionChange = (option: Option, key: string) => {
    let newSelection;
    switch (key) {
      case 'posIntegration':
        newSelection = getSelectedOption(posIntegration.options, option.value.toString(), key);
        dispatch(changePosIntegrationOption(newSelection));

        dispatch({
          type: 'UPDATE_POS_INTEGRATION',
          payload: newSelection.value,
        });
        break;
    }
  };

  const handlePosIntegrationChange = (data: Option) => {
    updateLocationParam(data.value, UI_ACTIONS.SET_SELECTED_OPTION_POS_INTEGRATION);
    handleSelectOptionChange(data, 'posIntegration');
  };
  const handlePosEmployeeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_POS_EMPLOYEE);
  };
  const handlePosOrderTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_POS_ORDER_TYPE);
  };
  const handlePosRevenueCenterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_POS_REVENUE_CENTER);
  };
  const handlePosTenderTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_POS_TENDER_TYPE);
  };

  const validation: IPosSettingsValidation = posSettingsPanel.validation;

  let serviceChargeSettings: IServiceChargeSettings | undefined;
  let serviceChargeIntegrationType: ServiceChargePosIntegration | undefined;

  const isOmnivorePosSupportServiceCharges = (posType: string): boolean => {
    return posType === 'micros3700' || posType === 'parbrink' || posType === 'microssimphony';
  };

  switch (posSettingsPanel.posIntegration) {
    case 'omnivore':
      if (isOmnivorePosSupportServiceCharges(posSettingsPanel.optionsOmnivore.omnivorePosType)) {
        serviceChargeSettings = posSettingsPanel.optionsOmnivore;
        serviceChargeIntegrationType = PosSystemType.omnivore;
      }
      break;
    case 'bypass2':
      serviceChargeSettings = posSettingsPanel.optionsBypass2;
      serviceChargeIntegrationType = PosSystemType.bypass2;
      break;
    case 'simphony2':
      serviceChargeSettings = posSettingsPanel.optionsSimphony2;
      serviceChargeIntegrationType = PosSystemType.simphony2;
      break;
  }

  return (
    <FormGroup groupName='POS Settings'>
      <FormControl
        label='POS Integration'
        withError={validation.posSettings.posIntegration.hasError}
        errorMessage={validation.posSettings.posIntegration.error}
      >
        <SelectFilter
          placeholder='Select a POS integration'
          options={LIST_OPTIONS.posIntegrationOptions}
          onChange={handlePosIntegrationChange}
          value={posIntegration.selectedOption}
          loading={isProcessing}
        />
      </FormControl>
      <>
        {posSettingsPanel.posIntegration === 'omnivore' && (
          <OmnivorePOS
            isProcessing={isProcessing}
            posSettingsPanel={posSettingsPanel}
            updateLocationParam={updateLocationParam}
            handlePosEmployeeChange={handlePosEmployeeChange}
            handlePosOrderTypeChange={handlePosOrderTypeChange}
            handlePosRevenueCenterChange={handlePosRevenueCenterChange}
            handlePosTenderTypeChange={handlePosTenderTypeChange}
          />
        )}
        {posSettingsPanel.posIntegration === 'parbrink' && <BrinkPOS {...props} />}
        {posSettingsPanel.posIntegration === 'bypass2' && <Bypass2POS {...props} />}
        {posSettingsPanel.posIntegration === 'square' && <SquareSettingsPOS {...props} />}
        {posSettingsPanel.posIntegration === 'simphony2' && <SimphonyPOS isProcessing={props.isProcessing} />}
      </>

      {serviceChargeSettings && serviceChargeIntegrationType && (
        <ForcedServiceCharges
          posIntegration={serviceChargeIntegrationType}
          settings={serviceChargeSettings}
          updateLocationParam={updateLocationParam}
        />
      )}
    </FormGroup>
  );
};

export default LocationPanelPOS;
