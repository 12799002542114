import { IPagedResponse, ITableConfiguration } from '@ready/table.core';
import { isEmpty } from 'lodash';
import LocationsService from '../../../locations/services/LocationsService';
import { pageErrorState, set200Toast, toastErrorState } from '../../../redux/actions/uiActions/responseStateActions';
import { TablesService } from '../../services/tablesService';
import { TABLE_CONFIGURATION_ACTIONS } from './types';

export const resetUnsavedChanges = () => async (dispatch: any) => {
  dispatch({ type: TABLE_CONFIGURATION_ACTIONS.RESET_UNSAVED_CHANGES });
};

export const getTableConfigurationList =
  (companyId: string = '', locationId: string = '', query: string = '', page: number) =>
  async (dispatch: any) => {
    dispatch(getTableConfigurationListBegin());
    try {
      const tableConfiguration = await TablesService.getTableConfigurations(companyId, locationId, query, page);
      dispatch(getTableConfigurationListSuccess(tableConfiguration));
    } catch (err) {
      dispatch(getTableConfigurationListError());
      dispatch(pageErrorState(err.status, err.message));
    }
  };

export const saveTableConfigurations =
  (
    companyId: string,
    locationId: string,
    unsavedMappedTargetCodes: {},
    unsavedMappedPosOrderTypes: {},
    unsavedMappedOrderExperienceTypes: {},
    query: string = '',
    page: number
  ) =>
  async (dispatch: any) => {
    dispatch({ type: TABLE_CONFIGURATION_ACTIONS.SAVE_TABLE_CONFIGURATION_BEGIN });
    try {
      let targetCodes, posOrderTypes, orderExperienceTypes;

      if (!isEmpty(unsavedMappedTargetCodes)) {
        targetCodes = await TablesService.updateTableConfigurationTargetCodes(
          companyId,
          locationId,
          unsavedMappedTargetCodes
        );
      }
      if (!isEmpty(unsavedMappedPosOrderTypes)) {
        posOrderTypes = await TablesService.updateTableConfigurationPosOrderTypes(
          companyId,
          locationId,
          unsavedMappedPosOrderTypes
        );
      }
      if (!isEmpty(unsavedMappedOrderExperienceTypes)) {
        orderExperienceTypes = await TablesService.updateTableConfigurationOrderExperienceTypes(
          companyId,
          locationId,
          unsavedMappedOrderExperienceTypes
        );
      }

      const errorMessage =
        targetCodes?.errorMessage || posOrderTypes?.errorMessage || orderExperienceTypes?.errorMessage || '';
      const updatedTableConfiguration = orderExperienceTypes || posOrderTypes || targetCodes;

      if (!errorMessage) {
        dispatch(updateTableConfigurationListSuccess(updatedTableConfiguration));
        dispatch(set200Toast());
        dispatch(getTableConfigurationList(companyId, locationId, query, page));
      } else {
        dispatch({ type: TABLE_CONFIGURATION_ACTIONS.SAVE_TABLE_CONFIGURATION_ERROR });
        dispatch(toastErrorState(500, errorMessage));
      }
    } catch (error) {
      dispatch({ type: TABLE_CONFIGURATION_ACTIONS.SAVE_TABLE_CONFIGURATION_ERROR });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const syncTableConfigurations = (companyId: string, locationId: string) => async (dispatch: any) => {
  dispatch({ type: TABLE_CONFIGURATION_ACTIONS.SYNC_TABLE_CONFIGURATIONS_BEGIN });
  try {
    const response = (await TablesService.syncTableWithPOS(companyId, locationId)) as any;

    if (!!response) {
      dispatch({ type: TABLE_CONFIGURATION_ACTIONS.SYNC_TABLE_CONFIGURATIONS_SUCCESS });
      dispatch(set200Toast(`Success! Sync completed.`));
    } else {
      dispatch({ type: TABLE_CONFIGURATION_ACTIONS.SYNC_TABLE_CONFIGURATIONS_ERROR });
      dispatch(toastErrorState(response?.status || 500, `We were unable to sync your POS tables. Please try again.`));
    }
  } catch (err) {
    dispatch({ type: TABLE_CONFIGURATION_ACTIONS.SYNC_TABLE_CONFIGURATIONS_ERROR });
    dispatch(toastErrorState(err.status, err.message));
  }
};

export const loadSelectedLocation = (companyId: string, locationId: string) => async (dispatch: any) => {
  try {
    const location = await LocationsService.getClientLocationById(companyId, locationId);

    dispatch({
      type: TABLE_CONFIGURATION_ACTIONS.LOAD_SELECTED_LOCATION,
      payload: location,
    });
  } catch (error) {
    dispatch(pageErrorState(error.status, error.message));
  }
};

export const updateTableConfiguration =
  (companyId: string = '', locationId: string = '', table: ITableConfiguration) =>
  async (dispatch: any) => {
    if (table.tableName) {
      dispatch({ type: TABLE_CONFIGURATION_ACTIONS.UPDATE_TABLE_CONFIGURATION_BEGIN });
      try {
        const tableConfiguration = await TablesService.updateTableConfiguration(companyId, locationId, table);

        if (table.id) {
          dispatch({
            type: TABLE_CONFIGURATION_ACTIONS.UPDATE_TABLE_CONFIGURATION_SUCCESS,
            payload: { tableConfiguration },
          });
          dispatch(set200Toast(`Success! Changes saved.`));
        } else {
          dispatch({
            type: TABLE_CONFIGURATION_ACTIONS.CREATE_TABLE_CONFIGURATION_SUCCESS,
            payload: { tableConfiguration },
          });
          dispatch(set200Toast(`Success! Table created.`));
        }
      } catch (err) {
        dispatch({ type: TABLE_CONFIGURATION_ACTIONS.UPDATE_TABLE_CONFIGURATION_ERROR });
        // @ts-ignore
        dispatch(toastErrorState(err.status, err.message));
      }
    } else {
      dispatch({
        type: TABLE_CONFIGURATION_ACTIONS.VALIDATION_ERROR,
      });
    }
  };

export const deleteTableConfiguration =
  (companyId: string = '', locationId: string = '', tableId: string) =>
  async (dispatch: any) => {
    dispatch({ type: TABLE_CONFIGURATION_ACTIONS.DELETE_TABLE_CONFIGURATION_BEGIN });
    try {
      await TablesService.deleteTableConfiguration(companyId, locationId, tableId);
      dispatch({
        type: TABLE_CONFIGURATION_ACTIONS.DELETE_TABLE_CONFIGURATION_SUCCESS,
        payload: { tableId },
      });
      dispatch(set200Toast(`Table deleted.`));
    } catch (err) {
      dispatch({ type: TABLE_CONFIGURATION_ACTIONS.DELETE_TABLE_CONFIGURATION_ERROR });
      dispatch(toastErrorState(err.status, err.message));
    }
  };

export const getTableConfigurationListBegin = () => ({
  type: TABLE_CONFIGURATION_ACTIONS.GET_TABLE_CONFIGURATION_TARGET_LIST_BEGIN,
});

export const getTableConfigurationListSuccess = (items: IPagedResponse<ITableConfiguration>) => ({
  type: TABLE_CONFIGURATION_ACTIONS.GET_TABLE_CONFIGURATION_TARGET_LIST_SUCCESS,
  payload: items,
});

export const getTableConfigurationListError = () => ({
  type: TABLE_CONFIGURATION_ACTIONS.GET_TABLE_CONFIGURATION_TARGET_LIST_ERROR,
});

export const updateTableConfigurationListParameters = (query: string, page: string) => ({
  type: TABLE_CONFIGURATION_ACTIONS.UPDATE_TABLE_CONFIGURATION_TARGET_LIST_PARAMETERS,
  payload: {
    query,
    page,
  },
});

export const updateTableConfigurationTargetCode = (code: string, tableId: string) => ({
  type: TABLE_CONFIGURATION_ACTIONS.UPDATE_TABLE_CONFIGURATION_TARGET_CODE,
  payload: {
    code,
    tableId,
  },
});

export const updateTableConfigurationPosOrderType = (tableId: string, posOrderType: string) => ({
  type: TABLE_CONFIGURATION_ACTIONS.UPDATE_TABLE_CONFIGURATION_POS_ORDER_TYPE,
  payload: {
    tableId,
    posOrderTypeValue: posOrderType,
  },
});

export const updateTableConfigurationOrderExperienceType = (tableId: string, orderExperienceTypeValue: string) => ({
  type: TABLE_CONFIGURATION_ACTIONS.UPDATE_TABLE_CONFIGURATION_ORDER_EXPERIENCE_TYPE,
  payload: {
    tableId,
    orderExperienceTypeValue,
  },
});

export const updateTableConfigurationListSuccess = (tableConfigurations: any) => ({
  type: TABLE_CONFIGURATION_ACTIONS.SAVE_TABLE_CONFIGURATION_SUCCESS,
  payload: {
    tableConfigurations,
  },
});

export const clearValidationError = () => ({
  type: TABLE_CONFIGURATION_ACTIONS.CLEAR_VALIDATION_ERROR,
});
