import React from 'react';
import styles from './AssignTablesDialogActions.module.scss';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../redux/initialStates/AppState';
import { AssignTablesDialogMode } from './index';
import { closeAssignModal, assignTables } from '../redux/ServerAssignmentActions';
import Button from '../../components/Button/Button';
import { useParams } from 'react-router-dom';
import { ContextParams } from '../../types/ContextParams.interface';
import { useSelectedTableIds, useServerTableIds } from '../hooks';

interface AssignTablesDialogActionsProps extends ReduxProps {
  mode: AssignTablesDialogMode;
}

const AssignTablesDialogActions = ({
  mode,
  closeAssignModal,
  assignTables,
  loading,
  processing,
  selectedServer,
  editingServer,
}: AssignTablesDialogActionsProps): JSX.Element => {
  const { contextId, locationId } = useParams<ContextParams>();
  const selectedTableIds = useSelectedTableIds();
  const editingServerTableIds = useServerTableIds(editingServer);
  const selectedServerTableIds = useServerTableIds(selectedServer);

  const handleAssignButtonClick = () => {
    assignTables(
      contextId,
      locationId,
      selectedServer,
      mode === 'select'
        ? [...selectedTableIds, ...selectedServerTableIds]
        : [...editingServerTableIds, ...selectedServerTableIds]
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.cancelButton}>
        <Button
          label='Cancel'
          onClick={closeAssignModal}
          variant='secondary'
          disabled={loading || processing}
          size='large'
        />
      </div>
      <div className={styles.submitButton}>
        <Button
          label={mode === 'select' ? 'Assign to Selected' : 'Save Changes'}
          onClick={handleAssignButtonClick}
          disabled={loading || selectedServer === ''}
          loading={processing}
          size='large'
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    loading: state.tableServerAssignment.serverAssignments.servers.loading,
    processing: state.tableServerAssignment.serverAssignments.assignModalProcessing,
    selectedServer: state.tableServerAssignment.serverAssignments.selectedServer,
    editingServer: state.tableServerAssignment.serverAssignments.assignModalServerEdit,
  };
};

const actionCreators = {
  closeAssignModal,
  assignTables,
};

const connector = connect(mapStateToProps, actionCreators);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AssignTablesDialogActions);
