import { IDigitalMenuConfig, IAsset } from '@ready/dashboardv2api.contracts';
import LocationsService from '../../../locations/services/LocationsService';
import { pageErrorState, set200Toast, toastErrorState } from '../../../redux/actions/uiActions/responseStateActions';
import { AssetService } from '../../../services/assetService';
import { DigitalMenuService } from '../../services/digitalMenuService';

export const DIGITAL_MENU_ACTIONS = {
  UPDATE_CLIENT_DIGITAL_MENU_MODE: 'UPDATE_CLIENT_DIGITAL_MENU_MODE',
  UPDATE_CLIENT_DIGITAL_MENU_IFRAME_URL: 'UPDATE_CLIENT_DIGITAL_MENU_IFRAME_URL',
  UPDATE_CLIENT_DIGITAL_MENU_IMAGE_URL: 'UPDATE_CLIENT_DIGITAL_MENU_IMAGE_URL',
  UPDATE_CLIENT_DIGITAL_MENU_IMAGE_KEY: 'UPDATE_CLIENT_DIGITAL_MENU_IMAGE_KEY',
  TOGGLE_CLIENT_DIGITAL_MENU_EDIT_FORM: 'TOGGLE_CLIENT_DIGITAL_MENU_EDIT_FORM',
  LOAD_CLIENT_DIGITAL_MENU_BEGIN: 'LOAD_CLIENT_DIGITAL_MENU_BEGIN',
  LOAD_CLIENT_DIGITAL_MENU_SUCCESS: 'LOAD_CLIENT_DIGITAL_MENU_SUCCESS',
  LOAD_CLIENT_DIGITAL_MENU_ERROR: 'LOAD_CLIENT_DIGITAL_MENU_ERROR',
  SUBMIT_CLIENT_DIGITAL_MENU_FORM_PROCESSING: 'SUBMIT_CLIENT_DIGITAL_MENU_FORM_PROCESSING',
  SUBMIT_CLIENT_DIGITAL_MENU_FORM_SUCCESS: 'SUBMIT_CLIENT_DIGITAL_MENU_FORM_SUCCESS',
  SUBMIT_CLIENT_DIGITAL_MENU_FORM_ERROR: 'SUBMIT_CLIENT_DIGITAL_MENU_FORM_ERROR',
  SET_CLIENT_DIGITAL_MENU_LOCATION: 'SET_CLIENT_DIGITAL_MENU_LOCATION',
  RESET_CLIENT_DIGITAL_MENU_CONFIG: 'RESET_CLIENT_DIGITAL_MENU_CONFIG',
  SUBMIT_CLIENT_DIGITAL_MENU_FORM_VALIDATION_ERROR: 'SUBMIT_CLIENT_DIGITAL_MENU_FORM_VALIDATION_ERROR',
};

export const updateClientDigitalMenuMode = (mode: string) => ({
  type: DIGITAL_MENU_ACTIONS.UPDATE_CLIENT_DIGITAL_MENU_MODE,
  payload: mode,
});

export const updateClientDigitalMenuImage = (asset: Partial<IAsset>) => async (dispatch: any) => {
  dispatch({
    type: DIGITAL_MENU_ACTIONS.UPDATE_CLIENT_DIGITAL_MENU_IMAGE_KEY,
    payload: asset,
  });

  const file = {
    fileKey: asset.fileKey,
  };
  if (file.fileKey) {
    const imageUrl = await AssetService.getAssetUrl(file);
    dispatch({
      type: DIGITAL_MENU_ACTIONS.UPDATE_CLIENT_DIGITAL_MENU_IMAGE_URL,
      payload: imageUrl,
    });
  }
};

export const updateClientDigitalMenuIframeUrl = (iframeUrl: string) => ({
  type: DIGITAL_MENU_ACTIONS.UPDATE_CLIENT_DIGITAL_MENU_IFRAME_URL,
  payload: iframeUrl,
});

export const toggleClientDigitalMenuEditForm = (editable: boolean) => ({
  type: DIGITAL_MENU_ACTIONS.TOGGLE_CLIENT_DIGITAL_MENU_EDIT_FORM,
  payload: editable,
});

export const resetClientDigitalMenuConfig = () => ({
  type: DIGITAL_MENU_ACTIONS.RESET_CLIENT_DIGITAL_MENU_CONFIG,
});

export const getClientDigitalMenuConfig = (companyId: string, locationId: string) => async (dispatch: any) => {
  dispatch({
    type: DIGITAL_MENU_ACTIONS.LOAD_CLIENT_DIGITAL_MENU_BEGIN,
  });
  try {
    const config = await DigitalMenuService.getClientDigitalMenuConfig(companyId, locationId);
    const imageUrlPayload = {
      fileKey: config?.imageKey === '' ? undefined : config?.imageKey,
    };
    const imageUrl = await AssetService.getAssetUrl(imageUrlPayload);

    dispatch({
      type: DIGITAL_MENU_ACTIONS.LOAD_CLIENT_DIGITAL_MENU_SUCCESS,
      payload: { ...config, imageUrl: config?.imageKey ? imageUrl : '' },
    });
  } catch (error) {
    dispatch(pageErrorState(error.status, error.message));
    dispatch({
      type: DIGITAL_MENU_ACTIONS.LOAD_CLIENT_DIGITAL_MENU_ERROR,
    });
  }
};

export const saveClientDigitalMenuForm = (
  companyId: string,
  locationId: string,
  digitalMenuConfig: IDigitalMenuConfig
) => async (dispatch: any) => {
  const validationResult = DigitalMenuService.validateDigitalMenu(digitalMenuConfig);

  if (validationResult.hasError) {
    dispatch({
      type: DIGITAL_MENU_ACTIONS.SUBMIT_CLIENT_DIGITAL_MENU_FORM_VALIDATION_ERROR,
      payload: validationResult,
    });
    return;
  }
  dispatch({
    type: DIGITAL_MENU_ACTIONS.SUBMIT_CLIENT_DIGITAL_MENU_FORM_PROCESSING,
  });
  try {
    const updatedConfig = await DigitalMenuService.updateClientDigitalMenuConfig(
      companyId,
      locationId,
      digitalMenuConfig
    );

    dispatch({
      type: DIGITAL_MENU_ACTIONS.SUBMIT_CLIENT_DIGITAL_MENU_FORM_SUCCESS,
      payload: updatedConfig,
    });
    dispatch(set200Toast());
  } catch (err) {
    dispatch(toastErrorState(err.status, err.message));
    dispatch({
      type: DIGITAL_MENU_ACTIONS.SUBMIT_CLIENT_DIGITAL_MENU_FORM_ERROR,
    });
  }
};

export const fetchUserLocation = (companyId: string, locationId: string) => async (dispatch: any) => {
  try {
    const location = await LocationsService.getClientLocationById(companyId, locationId);
    dispatch({
      type: DIGITAL_MENU_ACTIONS.SET_CLIENT_DIGITAL_MENU_LOCATION,
      payload: location,
    });
  } catch (error) {
    dispatch(toastErrorState(error.status, error.message));
  }
};
