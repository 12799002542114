import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { ISession } from '../redux/initialStates/session/session';
import { pageErrorState } from '../redux/actions/uiActions/responseStateActions';

import NotFound from '../pages/NotFound/NotFound';
import AdminsRouter from '../admins/Router';
import LocationsEditRouter from '../locations/EditRouter';
import LocationsListRouter from '../locations/ListRouter';
import { CompaniesSettingsRouter } from 'admin/companies/Router';

export interface ReadyRoutesProps {
  session: ISession;
}

const ReadyRoutes = (props: ReadyRoutesProps) => {
  const { session } = props;
  const { path } = useRouteMatch();
  // redirect logic
  useRedirect(session);

  return (
    <Switch>
      <Route path={`${path}/admins`} component={AdminsRouter} />
      <Route path={`${path}/companies/:companyId/locations`} component={LocationsEditRouter} />
      <Route path={`${path}/companiesSettings`} component={CompaniesSettingsRouter} />
      <Route exact path={`${path}/locations`} component={LocationsListRouter} />
      <Route path='*' component={NotFound} />
    </Switch>
  );
};

const useRedirect = (session: ISession) => {
  // security check
  // if user is not admin, then show 401 unauthorized
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (!!session.userSession.userId && !session.userSession.isAdmin) {
      dispatch(pageErrorState(401));
    }
  }, [
    dispatch,
    session.contextSession.type,
    session.initializeSessionLoading,
    session.userSession.isAdmin,
    session.userSession.userId,
  ]);
};

const mapStateToProps = (state: any) => ({
  session: state.session,
});

export default connect(mapStateToProps)(ReadyRoutes);
