import { useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/store';
import { selectSearchParamsState } from 'redux/selectors/uiSelectors/uiSelectors';
import { ContextParams } from 'types/ContextParams.interface';
import getBackLinkUrl from 'utils/urlUtils/getBackLinkUrl';
import { SharedMenuItemsRoutes } from 'sharedMenuItems/Router';

export const useTemplateItemBackLink = (baseUrl: string) => {
  const {
    contextId: companyId,
    modGroupId,
    id: templateMenuItemId,
    menuConfigId,
    menuId,
    itemGroupId,
    itemGalleryId,
  } = useParams<
    ContextParams & {
      modGroupId?: string;
      menuId?: string;
      menuConfigId?: string;
      itemGroupId?: string;
      itemGalleryId?: string;
    }
  >();
  const { type, searchTerm, page, locationName, locationId } = useAppSelector(selectSearchParamsState);
  const isComingFromTemplateMenuDetailsPage = !!menuConfigId && !!menuId && (!!itemGroupId || templateMenuItemId);
  const isComingFromTemplateItemGroupDetailsPage = !!itemGroupId;
  const isComingFromTemplateModGroupDetailsPage = !!modGroupId;
  const isComingFromTemplateItemGalleryDetailsPage = !!itemGalleryId;

  if (isComingFromTemplateMenuDetailsPage) {
    return SharedMenuItemsRoutes.getSharedMenusMenuRoute(companyId, menuConfigId ?? '', menuId ?? '');
  }

  if (isComingFromTemplateItemGroupDetailsPage) {
    return SharedMenuItemsRoutes.getItemGroupDetailsRoute(companyId, itemGroupId);
  }

  if (isComingFromTemplateModGroupDetailsPage) {
    return SharedMenuItemsRoutes.getModifierGroupDetailsRoute(companyId, modGroupId);
  }

  if (isComingFromTemplateItemGalleryDetailsPage) {
    return SharedMenuItemsRoutes.getSharedMenusItemGalleryRoute(companyId, menuConfigId ?? '', itemGalleryId ?? '');
  }
  return getBackLinkUrl({
    baseUrl: baseUrl,
    searchParams: { searchTerm, type, page, locationId, locationName },
  });
};
