import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { loadLocation, resetLocation, saveLocation } from '../redux/LocationsActions';
import { loadBilling, resetBilling, saveBilling, validateBillingTab } from '../redux/LocationBillingActions';
import {
  validateBrandingPanel,
  validateDetailsPanel,
  validateNewsletterPanel,
  validatePosSettingsPanel,
} from '../redux/LocationSettingsActions';
import { validateOrderAheadPanel } from '../redux/locationOrderAhead/locationOrderAheadActions';
import {
  loadLocationPromoCodesConfig,
  resetLocationPromoCodesConfig,
  saveLocationPromoCodesConfig,
} from '../redux/LocationPromoCodesActions';
import { loadPromoCodes } from '../redux/PromoCodesActions';
import { setFormIsDirty } from '../../redux/actions/uiActions/formStateActions';
import styles from './LocationsEditPage.module.scss';
import { Form } from '../../components/Form';
// components
import FormActionBar from '../../components/FormActionBar/FormActionBar';
import LayoutContent from '../../components/AppLayout/LayoutContent';
import { useAuth0 } from '../../context/Auth0';
import { AppState } from '../../redux/initialStates/AppState';
import { ILocationState } from '../redux/LocationState';
import { IUIDropDown } from '../../redux/initialStates/ui/dropDownState';
import ActionHeader from '../../components/ActionHeader/ActionHeader';
import Tabs from '../../components/Tabs/Tabs';
import Tab from '../../components/Tabs/Tab';
import LocationPanelDetails from './LocationPanelDetails';
import LocationPanelFeatures from './LocationPanelFeatures/LocationPanelFeatures';
import LocationPanelPOS from './LocationPanelPOS/LocationPanelPOS';
import LocationPanelPaymentProcessing from './LocationPanelPaymentProcessing/LocationPanelPaymentProcessing';
import LocationPanelBranding from './LocationPanelBranding';
import LocationPanelGratuity from './GratuityPanel/LocationPanelGratuity';
import LocationPanelEmailReceipts from './LocationPanelEmailReceipts';
// types
import {
  ILocationBilling,
  ILocationEditModelResponse,
  ILocationLoyaltyOptions,
  ILocationOrderAhead,
  ISmsNotificationsConfig,
  PaymentProcessorProvider,
} from '@ready/dashboardv2api.contracts';
import { LocationStateMapper } from '../../redux/initialStates/converters/locationStateMapper';
import { IDetailsPanelState } from '../redux/PanelDetailsState';
import { IBrandingPanel } from '../redux/PanelBrandingState';
import { IPosSettingsPanel } from '../redux/PanelPosSettingsState';
import LocationBillingTab from './LocationBillingTab';
import LoyaltyTabContent from './LoyaltyPanelPOS/LoyaltyTabContent';
import { resetLoyalty, saveLoyaltyConfig } from '../redux/PanelLoyaltyActions';
import { ILocationPromoCodes } from '../redux/LocationPromoCodesState';
import LocationPanelNewsletter from './LocationPanelNewsletter';
import { PaymentProcessingTabContent } from './PaymentProcessingTab/PaymentProcessingTabContent';

export interface LocationPageProps {
  location: ILocationState;
  dropDown: IUIDropDown;
  actionBarVisible: boolean;
  locationCache: ILocationEditModelResponse;
  billingCache: ILocationBilling;
  loyaltyCache: ILocationLoyaltyOptions;
  promoCodesCache: ILocationPromoCodes;
  smsNotificationsCache: ISmsNotificationsConfig;
  resetLoyalty: typeof resetLoyalty;
  loadBilling: typeof loadBilling;
  loadLocation: typeof loadLocation;
  resetBilling: typeof resetBilling;
  resetLocation: typeof resetLocation;
  saveBilling: typeof saveBilling;
  saveLocation: typeof saveLocation;
  saveLoyaltyConfig: typeof saveLoyaltyConfig;
  loadLocationPromoCodesConfig: typeof loadLocationPromoCodesConfig;
  resetLocationPromoCodesConfig: typeof resetLocationPromoCodesConfig;
  saveLocationPromoCodesConfig: typeof saveLocationPromoCodesConfig;
  loadPromoCodes: typeof loadPromoCodes;
  validateDetailsPanel: any;
  validateOrderAheadPanel: any; // adding type messes up the dispatch function (look into this when we have time)
  validateBrandingPanel: any;
  validatePosSettingsPanel: any;
  validateBillingTab: any;
  validateNewsletterPanel: any;
  setFormIsDirty: any;
}

export type IUpdateLocationParam = (newVal: any, actionType: string, setFormAsDirty?: boolean) => void;

const LocationsEditPage = (props: LocationPageProps) => {
  const { user } = useAuth0();
  const { companyId, id, tab } = useParams<{ companyId: string; id: string; tab: string }>();
  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = useState(false);
  const [loading, setLoading] = useState(true);

  const validateLocation = (): boolean => {
    const detailsPanelValid = validateDetailsPanel(props.location.detailsPanel);
    const orderAheadPanelValid = validateOrderAheadPanel(props.location.featuresPanel.orderAhead);
    const posSettingsPanelValid = validatePosSettingsPanel(props.location.posSettingsPanel);
    const brandingPanelValid = validateBrandingPanel(props.location.brandingPanel);

    return detailsPanelValid && orderAheadPanelValid && brandingPanelValid && posSettingsPanelValid;
  };

  const handleLocationFormSubmit = () => {
    const isValid = validateLocation();

    if (!isValid) {
      setLoading(false);
      setIsProcessing(false);
      return;
    }

    props.saveLocation(
      id as string,
      LocationStateMapper.mapToEditApiModel(props.location, user!.name ?? '', new Date())
    );
  };

  const handleBillingFormSubmit = async () => {
    await props.saveBilling(id!, props.location.billingPanel);
  };

  const handleLoyaltyFormSubmit = async () => {
    const loyaltyData: ILocationLoyaltyOptions = {
      provider: props.location.posLoyaltyPanelInitialState.provider,
      spendgoOptions: props.location.posLoyaltyPanelInitialState.spendgoOptions,
      byPassOptions: props.location.posLoyaltyPanelInitialState.byPassOptions,
      squareOptions: props.location.posLoyaltyPanelInitialState.squareOptions,
      punchhOptions: props.location.posLoyaltyPanelInitialState.punchhOptions,
    };
    await props.saveLoyaltyConfig(id!, loyaltyData);
  };

  const resetLocationForm = () => {
    props.resetLocation(props.locationCache, props.dropDown.addressListOptions);
  };

  const resetBillingForm = () => {
    props.resetBilling(props.billingCache);
  };

  const resetLoyaltyForm = () => {
    props.resetLoyalty(props.loyaltyCache);
  };

  // currently the payment processing tab only contains Adyen tooling.
  // if there are any general PP settings, we should remove this.
  const shouldShowPaymentProcessingTab =
    props.locationCache?.paymentProcessor?.providerName === PaymentProcessorProvider.Adyen;

  const requestedTab = tab ? tabs.find((item) => item.id === tab) : undefined;
  const selectedTab = requestedTab ? requestedTab : SettingsTab;

  let handleSubmit = () => {};
  let resetForm = () => {};

  switch (selectedTab.id) {
    case SettingsTab.id:
      handleSubmit = handleLocationFormSubmit;
      resetForm = resetLocationForm;
      break;
    case BillingTab.id:
      handleSubmit = handleBillingFormSubmit;
      resetForm = resetBillingForm;
      break;
    case LoyaltyTab.id:
      handleSubmit = handleLoyaltyFormSubmit;
      resetForm = resetLoyaltyForm;
      break;
    case PaymentTerminalsTab.id:
    default:
      break;
  }

  const fetchData = async () => {
    const loadFunctions = [props.loadLocation(id!), props.loadBilling(id!)];

    loadFunctions.push(props.loadLocationPromoCodesConfig(id!));
    loadFunctions.push(props.loadPromoCodes(id!));

    await Promise.all(loadFunctions);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateDetailsPanel = (detailsPanel: IDetailsPanelState): boolean => {
    // This validation code was added a few years ago but has not been used since.
    // For now, we're returning true to avoid blocking any code paths, but we'll need to update
    // the validation code in the future to properly validate the input
    // return props.validateDetailsPanel(detailsPanel);
    return true;
  };

  const validateOrderAheadPanel = (orderAhead: ILocationOrderAhead): boolean => {
    return props.validateOrderAheadPanel(orderAhead);
  };

  const validatePosSettingsPanel = (posSettings: IPosSettingsPanel): boolean => {
    // This validation code was added a few years ago but has not been used since.
    // For now, we're returning true to avoid blocking any code paths, but we'll need to update
    // the validation code in the future to properly validate the input
    // return props.validatePosSettingsPanel(posSettings);
    return true;
  };

  const validateBrandingPanel = (branding: IBrandingPanel): boolean => {
    // This validation code was added a few years ago but has not been used since.
    // For now, we're returning true to avoid blocking any code paths, but we'll need to update
    // the validation code in the future to properly validate the input
    // return props.validateBrandingPanel(branding);
    return true;
  };

  const updateLocationParam: IUpdateLocationParam = (newVal: any, actionType: string, setFormAsDirty = true) => {
    if (setFormAsDirty) {
      dispatch(props.setFormIsDirty(true));
    }
    dispatch({
      type: actionType,
      payload: newVal,
    });
  };

  const getFormattedLastSavedDetails = (lastEdit: any) => {
    const lastSaveAt = lastEdit.lastSaveAt ? format(new Date(lastEdit.lastSaveAt), 'dd/MM/yyyy H:mm a') : 'unknown';
    const lastSaveBy = lastEdit.lastSaveBy || 'unknown';
    return {
      lastSaveAt,
      lastSaveBy,
    };
  };

  const {
    location: { lastEdit },
  } = props;
  const lastSavedDetails = getFormattedLastSavedDetails(lastEdit);
  const backLinkSubText = `Last saved on ${lastSavedDetails.lastSaveAt} by ${lastSavedDetails.lastSaveBy}`;

  const { query, page } = props.location.locationsList;
  const backLinkTo = {
    baseUrl: '/ready/locations',
    searchParams: { query, page },
  };

  return (
    <>
      <FormActionBar
        actionBarVisible={props.actionBarVisible}
        handleFormSubmit={handleSubmit}
        resetForm={resetForm}
        setIsFormProcessing={(processing) => setIsProcessing(processing)}
        isFormProcessing={isProcessing}
      />
      <LayoutContent title='Location Details' loadingContent={loading} containerType='within'>
        <ActionHeader text={lastEdit.locationName || ''} subText={backLinkSubText} backLinkTo={backLinkTo} />
        <Tabs selectedTab={selectedTab.id}>
          <Tab {...SettingsTab}>
            <div className={styles.container}>
              <Form hasGroups onSubmit={() => {}} confirmCancelChanges={false}>
                <LocationPanelDetails
                  isProcessing={isProcessing}
                  updateLocationParam={updateLocationParam}
                  detailsPanel={props.location.detailsPanel}
                  dropDown={props.dropDown}
                />
                <LocationPanelFeatures
                  isProcessing={isProcessing}
                  updateLocationParam={updateLocationParam}
                  featuresPanel={props.location.featuresPanel}
                  dropDown={props.dropDown}
                  ignoreInStockFromPosItem={props.location.posSettingsPanel.ignoreInStockFromPosItem || false}
                />
                <LocationPanelPOS
                  companyId={companyId}
                  locationId={id}
                  isProcessing={isProcessing}
                  updateLocationParam={updateLocationParam}
                  posSettingsPanel={props.location.posSettingsPanel}
                  dropDown={props.dropDown}
                  companyIntegrations={props.location.companyIntegrations}
                />
                <LocationPanelPaymentProcessing
                  isProcessing={isProcessing}
                  updateLocationParam={updateLocationParam}
                  paymentProcessingPanel={props.location.paymentProcessingPanel}
                  companyIntegrations={props.location.companyIntegrations}
                />
                <LocationPanelBranding
                  updateLocationParam={updateLocationParam}
                  isProcessing={isProcessing}
                  brandingPanel={props.location.brandingPanel}
                  dropDown={props.dropDown}
                />
                <LocationPanelGratuity
                  updateLocationParam={updateLocationParam}
                  isProcessing={isProcessing}
                  dropDown={props.dropDown}
                />
                <LocationPanelNewsletter
                  updateLocationParam={updateLocationParam}
                  newsletter={props.location.newsletterPanel}
                  isProcessing={isProcessing}
                />
                <LocationPanelEmailReceipts
                  updateLocationParam={updateLocationParam}
                  emailReceiptsPanel={props.location.emailReceiptsPanel}
                  isProcessing={isProcessing}
                />
              </Form>
            </div>
          </Tab>

          <Tab {...LoyaltyTab}>
            <Form onSubmit={() => {}}>
              <LoyaltyTabContent
                isProcessing={isProcessing}
                updateLocationParam={updateLocationParam}
                posLoyaltyPanelInitialState={props.location.posLoyaltyPanelInitialState}
                dropDown={props.dropDown}
                companyIntegrations={props.location.companyIntegrations}
              />
            </Form>
          </Tab>

          {shouldShowPaymentProcessingTab ? (
            <Tab {...PaymentTerminalsTab}>
              <PaymentProcessingTabContent />
            </Tab>
          ) : null}

          <Tab {...BillingTab}>
            <Form onSubmit={() => {}}>
              <LocationBillingTab
                isProcessing={isProcessing}
                billingTab={props.location.billingPanel}
                updateLocationParam={updateLocationParam}
              />
            </Form>
          </Tab>
        </Tabs>
      </LayoutContent>
    </>
  );
};

const SettingsTab = {
  id: 'settings',
  route: 'settings',
  label: 'Settings',
};

const LoyaltyTab = {
  id: 'loyalty',
  route: 'loyalty',
  label: 'Loyalty',
};
const BillingTab = {
  id: 'billing',
  route: 'billing',
  label: 'Billing',
};

// name will change to PaymentProcessingTab once we do more than just terminals in here.
const PaymentTerminalsTab = {
  id: 'payment-terminals',
  route: 'payment-terminals',
  label: 'Payment Terminals',
};

const tabs = [SettingsTab, LoyaltyTab, BillingTab, PaymentTerminalsTab];

const mapStateToProps = (state: AppState) => ({
  location: state.location,
  dropDown: state.ui.dropDown,
  actionBarVisible: state.ui.formState.isDirty,
  locationCache: state.cache.locationCache,
  billingCache: state.cache.billingCache,
  loyaltyCache: state.cache.loyaltyCache,
  promoCodesCache: state.cache.promoCodesCache,
  smsNotificationsCache: state.cache.smsNotificationsCache,
});

const actionCreators = {
  loadBilling,
  loadLocation,
  resetBilling,
  resetLoyalty,
  resetLocation,
  saveBilling,
  saveLocation,
  saveLoyaltyConfig,
  loadLocationPromoCodesConfig,
  resetLocationPromoCodesConfig,
  saveLocationPromoCodesConfig,
  loadPromoCodes,
  validateDetailsPanel,
  validateOrderAheadPanel,
  validateBrandingPanel,
  validateNewsletterPanel,
  validatePosSettingsPanel,
  validateBillingTab,
  setFormIsDirty,
};

export default connect(mapStateToProps, actionCreators as any)(LocationsEditPage);
