import { IPermission, IPrincipalPermissionSet } from '@ready/security.core';
import { IContext } from '@ready/dashboardv2api.contracts';

export interface IUserSessionApiResponse {
  isAdmin: boolean;
  userName: string;
  userId: string;
}

export interface IUserSession extends IUserSessionApiResponse {
  updateUserLoading: boolean;
  updateUserError: boolean;
}

export interface IContextSession extends IContext {
  updateContextLoading: boolean;
  updateContextError: boolean;
  invalidContext: boolean;
}

export interface ISidebarOption {
  label: string;
  url: string;
  adminOption: boolean;
  enabled: boolean;
  children?: ISidebarOption[];
  /** Permissions required to show in side bar. */
  permissions: (string | IPermission)[];
  /** If provided, these much match the properties that are retrieved in the /me/context call. */
  requiredContextSessionProps?: IContextPropsForSidebar;
  hasPermission?: boolean;
}

export interface IContextPropsForSidebar extends Pick<IContext, 'usesAdyen'> {}

export interface ISidebar {
  options: ISidebarOption[];
  optionsSet: boolean;
}

export interface IPermissions {
  permissionsList: IPrincipalPermissionSet;
  allPermissionsMap: AllPermissionsMap;
  apiPermissionsRequested: boolean;
}

export type AllPermissionsMap = { [resourceType: string]: { [action: string]: boolean } };

export interface ILastLocation {
  locationId: string;
}

export interface ISession {
  userSession: IUserSession;
  contextSession: IContextSession;
  permissions: IPermissions;
  sidebar: ISidebar;
  initializeSessionLoading: boolean;
  initializeSessionError: boolean;
  enableContextSwitcher: boolean;
  showCompanySelectionPage: boolean;
  lastLocation: ILastLocation;
}

export const session = {
  userSession: {
    isAdmin: false,
    userName: '',
    userId: '',
    updateUserLoading: false,
    updateUserError: false,
  },
  contextSession: {
    id: '',
    name: '',
    type: 'system',
    usesAdyen: false,
    updateContextLoading: false,
    updateContextError: false,
    invalidContext: false,
  },
  permissions: {
    permissionsList: {
      locations: [],
      companyPermissions: {},
      locationPermissions: {},
    },
    allPermissionsMap: {},
    apiPermissionsRequested: false,
  },
  sidebar: {
    options: [],
    optionsSet: false,
  },
  initializeSessionLoading: true,
  initializeSessionError: false,
  enableContextSwitcher: false,
  showCompanySelectionPage: false,
  lastLocation: {
    locationId: '',
  },
} as ISession;
