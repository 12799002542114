import React from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { ContextParams } from '../../../types/ContextParams.interface';
import { TablesTab } from '../../../enums/tables.enum';
import TablesLayoutContent from '../TablesDetails/TablesLayoutContent';
import PaymentProcessingContent from './PaymentProcessingContent';
import { paymentProcessingSelector } from '../../redux/selectors/PaymentProcessingSelector';
import { selectOnAccountPaymentOptions } from '../../redux/selectors/onAccountPaymentOptionsSelector';
import { fetchLocation } from '../../redux/actions/paymentProcessingActions';
import {
  PaymentProcessingActions,
  PrincipalPermissions,
  ResourceType,
  SecurityScope,
  Verifier,
} from '@ready/security.core';
import { selectPermissions } from '../../../redux/selectors/sessionSelectors/sessionSelectors';
import { pageErrorState } from '../../../redux/actions/uiActions/responseStateActions';
import { OnAccountPaymentOptionsActions } from '../../redux/actions/onAccountPaymentOptionsActions';
import { IOnAccountPaymentOptionsState } from '../../redux/initialStates/onAccountPaymentOptions';
import { IPaymentProcessingState } from '../../redux/initialStates/paymentProcessing';
import { IPermissions } from '../../../redux/initialStates/session/session';
import { getOnAccountPaymentSettingsThunk } from 'companyLocations/redux/reducers/onAccountPaymentSettings/thunk';

const PaymentProcessingPage = () => {
  const {
    loading,
    location,
    providers: { paypal, giftCard },
  } = useAppSelector<IPaymentProcessingState>(paymentProcessingSelector);
  const { list } = useAppSelector<IOnAccountPaymentOptionsState>(selectOnAccountPaymentOptions);

  const dispatch = useAppDispatch();
  const { contextId, locationId } = useParams<ContextParams>();

  const isPageLoading =
    loading || !paypal.initialized || !giftCard.initialized || !list.initialized;

  const { permissionsList } = useAppSelector<IPermissions>(selectPermissions);
  const hasPermission = Verifier.check(
    new PrincipalPermissions(permissionsList),
    SecurityScope.location,
    ResourceType.paymentProcessing,
    PaymentProcessingActions.all,
    locationId
  );

  const accessDenied = () => {
    dispatch(pageErrorState(401));
    return null;
  };

  React.useEffect(() => {
    if (contextId && locationId && (!location || location.id !== locationId)) {
      dispatch(fetchLocation(contextId, locationId));
    }
  }, [contextId, locationId, location, dispatch]);

  React.useEffect(() => {
    dispatch(OnAccountPaymentOptionsActions.loadOnAccountPaymentOptions(contextId, locationId));
  }, [dispatch, contextId, locationId]);

  React.useEffect(() => {
    dispatch(getOnAccountPaymentSettingsThunk({ companyId: contextId, locationId }));
  }, [dispatch, contextId, locationId]);

  return hasPermission ? (
    <TablesLayoutContent
      companyId={contextId}
      locationId={locationId}
      locationName={location && location?.id === locationId ? location.name : null}
      tab={TablesTab.PaymentProcessing}
    >
      <PaymentProcessingContent
        paypal={paypal}
        giftCard={giftCard}
        onAccountPaymentOptions={list.onAccountPaymentOptions}
        giftCardEnabled={!!location?.settings.giftCardsEnabled}
        posSystemType={location?.posSystemType}
        underlyingPosSystemType={location?.underlyingPosSystemType}
        paymentProcessor={location?.paymentProcessor}
        companyId={contextId}
        locationId={locationId}
        loading={isPageLoading}
      />
    </TablesLayoutContent>
  ) : (
    accessDenied()
  );
};

export default PaymentProcessingPage;
