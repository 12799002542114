import React from 'react';

import MissingValue from './MissingValue';
import NoneValue from './NoneValue';

interface TextValueProps {
  value: string;
  mode?: 'missing' | 'none';
}

const TextValue = ({ value, mode = 'missing' }: TextValueProps): JSX.Element => {
  return !!value ? <>{value}</> : getNoValueComponent(mode);
};

const getNoValueComponent = (mode: 'missing' | 'none') => {
  switch (mode) {
    case 'missing':
      return <MissingValue />;
    case 'none':
      return <NoneValue />;
  }
};

export default TextValue;
