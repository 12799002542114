import produce from 'immer';
import { actionTypes } from './BadgesListActions';
import { Action } from '../../redux/types';
import { BadgesListState, badgesListState } from './BadgesListState';

export const badgesListReducer = (state: BadgesListState = badgesListState, action: Action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.GET_BADGES_LIST_BEGIN:
        draft.loading = true;
        return draft;

      case actionTypes.GET_BADGES_LIST_SUCCESS:
        draft.loading = false;
        draft.badges = action.payload;
        return draft;

      case actionTypes.GET_BADGES_LIST_ERROR:
        draft.loading = false;
        return draft;

      default:
        return state;
    }
  });
