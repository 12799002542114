import React from 'react';

import styles from './DateValue.module.scss';
import { getTime, parseISO, isToday, isYesterday } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import MissingValue from './MissingValue';

const formatDate = (value: string, timeZone: string, formatCode: 'ymd' | 'mdy'): JSX.Element => {
  const date = timeZone ? utcToZonedTime(parseISO(value), timeZone) : parseISO(value);
  let datePart: string;
  if (isToday(date)) {
    datePart = `Today ${format(getTime(date), 'h:mm a')}`;
  } else if (isYesterday(date)) {
    datePart = `Yesterday ${format(getTime(date), 'h:mm a')}`;
  } else {
    datePart = format(getTime(date), formatCode === 'ymd' ? 'yyyy/MM/dd h:mm a' : 'MM/dd/yyyy h:mm a');
  }
  return (
    <>
      <span>{datePart}</span>{' '}
      {timeZone && <span className={styles.timeZone}>({format(date, 'z', { timeZone: timeZone })})</span>}
    </>
  );
};

interface DateValueProps {
  value: string;
  timeZone?: string;
  format?: 'ymd' | 'mdy';
}

const DateValue = ({ value, timeZone = '', format = 'ymd' }: DateValueProps): JSX.Element =>
  !!value ? formatDate(value, timeZone, format) : <MissingValue />;

export default DateValue;
