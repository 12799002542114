import { IRevenueCenter } from '@ready/table.core';

export interface IRevenueCenters {
  loading: boolean;
  error: boolean;
  revenueCenterList: IRevenueCenter[];
}

export const revenueCenters = {
  loading: false,
  error: false,
  revenueCenterList: [],
} as IRevenueCenters;
