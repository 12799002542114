import React from 'react';
import { AnyRowData, CellProps, getCellClasses } from './Cell';
import Skeleton from '../../Skeleton/Skeleton';
import styles from './Cell.module.scss';
import { DateValue, DollarValue } from '../../Value';

interface DateFieldsGetters<TRowData extends AnyRowData> {
  dateGetter: (row: TRowData) => string;
  timezoneGetter: (row: TRowData) => string;
}

export const createNullableCell = (DataCell: React.ElementType<CellProps>, NullCell: React.ElementType<CellProps>) => {
  return (props: CellProps) => {
    const val = props.rowData[props.columnName];
    return val === 0 || val ? <DataCell {...props} /> : <NullCell {...props} />;
  };
};

export const createDateCell = <TRowData extends AnyRowData>(dateFieldsGetters: DateFieldsGetters<TRowData>) => {
  return (props: CellProps<TRowData>) => <DateCell {...props} {...dateFieldsGetters} />;
};

export const DateCell = <TRowData extends AnyRowData>({
  rowData,
  classes,
  dateGetter,
  timezoneGetter,
}: CellProps<TRowData> & DateFieldsGetters<TRowData>) => {
  return (
    <span className={getCellClasses(styles.tableV2__cellDate, classes)}>
      <DateValue value={dateGetter(rowData)} timeZone={timezoneGetter(rowData)} />
    </span>
  );
};

export const SkeletonCell = ({ classes }: { classes?: string }) => (
  <span className={getCellClasses(styles.tableV2__cellSkeleton, styles.tableV2__cellWrapper, classes)}>
    <Skeleton additionalStyles={styles.skeleton} />
  </span>
);

export const TextCell = ({ rowData, columnName, classes, align }: CellProps) => {
  let value = rowData[columnName];
  const isEmpty = !value && value !== 0;

  return (
    <span
      className={getCellClasses(
        {
          [styles['tableV2__cell--textEmpty']]: isEmpty,
          [styles[`tableV2__cellTextAlign--${align}`]]: align,
        },
        classes
      )}
    >
      {isEmpty ? 'None' : value}
    </span>
  );
};

export const TextCellShortable = ({ rowData, columnName, maxLength, classes }: CellProps & { maxLength: number }) => {
  const initContent = rowData[columnName];
  const content =
    initContent.length > maxLength
      ? initContent.slice(0, maxLength - 10) + '...' + initContent.slice(initContent.length - 10, initContent.length)
      : initContent;

  return <TextCell classes={classes} rowData={{ ...rowData, [columnName]: content }} columnName={columnName} />;
};

export const DollarCell = ({ rowData, columnName, classes, align }: CellProps) => {
  return (
    <DollarValue
      value={rowData[columnName]}
      additionalStyles={getCellClasses(classes, styles[`tableV2__cellTextAlign--${align ?? 'right'}`])}
    />
  );
};
