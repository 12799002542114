import React from 'react';
import { startOfMonth, subMonths } from 'date-fns';
import { TextCellShortable, TextCell, createDateCell } from '../../components/TableV2/Cell/SpecialCells';
import { CellProps, getWrappedCellsWithMobileLayout } from '../../components/TableV2/Cell/Cell';
import { getSortableHeaderCell } from '../../components/TableV2/Cell/HeaderCell';
import { AmountPaidCell, CellRating } from './components/Cells/Cells';
import styles from './FeedbackReport.module.scss';
import { getIsMobile } from '../../utils/checkMediaQuery';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../redux/store';
import { IFeedbackReportItem } from '@ready/dashboardv2api.contracts';

const isMobile = getIsMobile();
const maxLength = isMobile ? 50 : 1000;

const headerColumnsSelector = createSelector(
  (state: RootState) => state,
  (state) => state.reports.feedback.headerColumns
);

const cells = getWrappedCellsWithMobileLayout(
  {
    date: createDateCell<IFeedbackReportItem>({
      dateGetter: (rowData) => rowData.created,
      timezoneGetter: (rowData) => rowData.timezone,
    }),
    rating: CellRating,
    amountPaid: AmountPaidCell,
    locationName: TextCell,
    serverName: TextCell,
    tableName: TextCell,
    feedback: (props: CellProps) => <TextCellShortable classes={styles.cellComment} maxLength={maxLength} {...props} />,
  },
  headerColumnsSelector
);

const headerCells = {
  locationName: getSortableHeaderCell('locationName', { classes: styles['headerCell--location'] }),
  serverName: getSortableHeaderCell('serverName', { classes: styles['headerCell--server'] }),
  tableName: getSortableHeaderCell('tableName', { classes: styles['headerCell--table'] }),
  rating: getSortableHeaderCell('rating', { classes: styles['headerCell--rating'] }),
  feedback: getSortableHeaderCell('feedback', { classes: styles['headerCell--comment'] }),
  amountPaid: getSortableHeaderCell('amountPaid', { classes: styles['headerCell--amountPaid'] }),
  date: getSortableHeaderCell('created', { classes: styles['headerCell--date'] }),
};

const endMonth = startOfMonth(new Date());
const startMonth = subMonths(endMonth, 1);
const initDateFilter = { endMonth, startMonth };

const maxDaysToChoose = 366;

export { headerCells, cells, initDateFilter, maxDaysToChoose };
