import React from 'react';
import readyProgressSpinnerWhite from '../../../styles/assets/ready-progress-spinner-white.svg';
import styles from './OrderStatusButton.module.scss';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';
import { OrderFulfillmentStatus } from '@ready/dashboardv2api.contracts';
import { useClassNames } from '../../../utils/cssUtils';
import { OrderStatusButtonMenuOption } from '.';
import { OrderStatusLabel } from '../../../enums/orders.enum';

export interface OrderStatusButtonProps {
  orderStatus: OrderFulfillmentStatus;
  readOnly?: boolean;
  isProcessing: boolean;
  onChange: (orderStatus: OrderFulfillmentStatus) => void;
}

interface OrderStatusAttribute {
  label: string;
  buttonStyle: string;
}

const getButtonAttributes = (orderStatus: OrderFulfillmentStatus): OrderStatusAttribute => {
  switch (orderStatus) {
    case OrderFulfillmentStatus.Pending:
      return { label: OrderStatusLabel.PENDING, buttonStyle: styles.pendingButton };

    case OrderFulfillmentStatus.InProgress:
      return { label: OrderStatusLabel.IN_PROGRESS, buttonStyle: styles.inProgressButton };

    case OrderFulfillmentStatus.Ready:
      return { label: OrderStatusLabel.READY, buttonStyle: styles.readyButton };

    case OrderFulfillmentStatus.Complete:
      return { label: OrderStatusLabel.COMPLETED, buttonStyle: styles.completedButton };

    case OrderFulfillmentStatus.Cancelled:
      return { label: OrderStatusLabel.CANCELLED, buttonStyle: styles.cancelledButton };
  }
};

const OrderStatusButton = (props: OrderStatusButtonProps) => {
  const { readOnly = false, isProcessing = false, orderStatus, onChange } = props;

  const canChange = orderStatus !== OrderFulfillmentStatus.Cancelled;

  const [isVisible, setIsVisible] = React.useState(false);

  const toggleVisible = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
    // prevent bubbling with React's synthetic events
    event.nativeEvent.stopImmediatePropagation();
    event.preventDefault();
    setIsVisible(!isVisible);
  };

  const doNothing = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
    // prevent bubbling with React's synthetic events
    event.nativeEvent.stopImmediatePropagation();
    event.preventDefault();
  };

  const captureClickOutsideDD = () => {
    setIsVisible(false);
  };

  React.useEffect(() => {
    document.addEventListener('click', captureClickOutsideDD, false);
    return () => {
      document.removeEventListener('click', captureClickOutsideDD, false);
    };
  }, []);

  const { label, buttonStyle } = getButtonAttributes(orderStatus);

  const handleMenuOptionClick = (event: React.MouseEvent<HTMLButtonElement>, orderStatus: OrderFulfillmentStatus) => {
    event.nativeEvent.stopImmediatePropagation();
    event.preventDefault();
    setIsVisible(false);
    onChange(orderStatus);
  };

  const statusButtonClassName = useClassNames(
    [{ statusButtonDisabled: readOnly || isProcessing }, styles.button, buttonStyle],
    styles
  );

  return (
    <div className={styles.container}>
      <button
        className={statusButtonClassName}
        onClick={readOnly || isProcessing || !canChange ? doNothing : toggleVisible}
      >
        <div className={styles.buttonLabel}>
          {label}
          {canChange ? <TextIcon icon={Icon.AngleDown} additionalStyles={styles.downArrowIcon} /> : null}
          {isProcessing && <img className={styles.spinner} src={readyProgressSpinnerWhite} alt='loading' />}
        </div>
      </button>
      {isVisible ? (
        <div className={styles.menuContainer}>
          <div className={styles.menu}>
            <OrderStatusButtonMenuOption
              orderStatus={OrderFulfillmentStatus.Pending}
              activeOrderStatus={orderStatus}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                handleMenuOptionClick(event, OrderFulfillmentStatus.Pending)
              }
            />
            <OrderStatusButtonMenuOption
              orderStatus={OrderFulfillmentStatus.InProgress}
              activeOrderStatus={orderStatus}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                handleMenuOptionClick(event, OrderFulfillmentStatus.InProgress)
              }
            />
            <OrderStatusButtonMenuOption
              orderStatus={OrderFulfillmentStatus.Ready}
              activeOrderStatus={orderStatus}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                handleMenuOptionClick(event, OrderFulfillmentStatus.Ready)
              }
            />
            <OrderStatusButtonMenuOption
              orderStatus={OrderFulfillmentStatus.Complete}
              activeOrderStatus={orderStatus}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                handleMenuOptionClick(event, OrderFulfillmentStatus.Complete)
              }
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default OrderStatusButton;
