import { combineReducers } from 'redux';
import { securityMetadataReducer } from '../../../securityRoles/redux/reducers/securityMetadataReducer';
import { securityRoleFormReducer } from '../../../securityRoles/redux/reducers/securityRoleFormReducer';
import { securityRolesListReducer } from './securityRolesListReducer';

export const securityRolesReducer = combineReducers({
  securityRoleForm: securityRoleFormReducer,
  securityMetadata: securityMetadataReducer,
  securityRolesList: securityRolesListReducer,
});
