import React from 'react';
import classNames from 'classnames';

import Container from '../Container/Container';

export interface SearchInputProps {
  value: string;
  placeholder?: string;
  fullWidth?: boolean;
  additionalContainerClassName?: string;
  additionalClassName?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  autoFocus?: boolean;
}

const SearchInput = (props: SearchInputProps): JSX.Element => {
  const {
    value,
    placeholder = '',
    fullWidth = false,
    additionalContainerClassName = '',
    additionalClassName = '',
    onChange,
    disabled = false,
    autoFocus = false,
  } = props;

  const containerClassName = classNames(
    'search-input-container',
    { 'search-input-container-disabled': disabled },
    additionalContainerClassName
  );

  const inputClassName = classNames(
    'search-input',
    {
      'search-input-full-width': fullWidth,
      'search-input-disabled': disabled,
    },
    additionalClassName
  );

  return (
    <Container additionalClassName={containerClassName}>
      <i className='icon-dashboard-icons_search'></i>
      <input
        type='text'
        placeholder={placeholder}
        value={value}
        onChange={disabled ? undefined : onChange}
        className={inputClassName}
        autoFocus={autoFocus}
      />
    </Container>
  );
};

export default SearchInput;
