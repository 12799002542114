import React from 'react';
// components
import { FormControl, FormControlStacked } from '../../../components/Form';
import TextInput from '../../../components/TextInput/TextInput';
import Checkbox from '../../../components/Checkbox/Checkbox';
import FormattedInput from '../../../components/FormattedInput/FormattedInput';
import { IPaymentProcessingPanel } from '../../redux/PanelPaymentProcessingState';
import { LOCATION_SETTINGS_ACTIONS } from '../../redux/LocationSettingsActions';
// utils
import { toNumber } from '../../../utils/numberUtils/toNumber';
import { IUpdateLocationParam } from '../LocationsEditPage';
import Toggle from 'components/Toggle/Toggle';

interface Props {
  isProcessing: boolean;
  updateLocationParam: IUpdateLocationParam;
  paymentProcessingPanel: IPaymentProcessingPanel;
}

const MonerisPP = (props: Props) => {
  const { isProcessing, updateLocationParam, paymentProcessingPanel } = props;
  return (
    <>
      <FormControl label='API Token'>
        <TextInput
          placeholder='API Token'
          loading={isProcessing}
          value={paymentProcessingPanel.moneris.apiToken}
          onChange={(event) => {
            updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_API_TOKEN);
          }}
        />
      </FormControl>
      <FormControl label='Store ID'>
        <TextInput
          placeholder='Store ID'
          loading={isProcessing}
          value={paymentProcessingPanel.moneris.storeId}
          onChange={(event) => {
            updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_STORE_ID);
          }}
        />
      </FormControl>
      <FormControl label='HPP Token'>
        <TextInput
          placeholder='HPP Token'
          loading={isProcessing}
          value={paymentProcessingPanel.moneris.hppToken}
          onChange={(event) => {
            updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_HPP_TOKEN);
          }}
        />
      </FormControl>
      <FormControl label='Apple Pay Merchant ID'>
        <TextInput
          placeholder='Apple Pay Merchant ID'
          loading={isProcessing}
          value={paymentProcessingPanel.moneris.applePayMerchantId}
          onChange={(event) => {
            updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_APPLE_PAY_MERCHANT_ID);
          }}
        />
      </FormControl>
      <FormControl label='Google Pay Merchant ID'>
        <TextInput
          placeholder='Google Pay Merchant ID'
          loading={isProcessing}
          value={paymentProcessingPanel.moneris.googlePayMerchantId}
          onChange={(event) => {
            updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_GOOGLE_PAY_MERCHANT_ID);
          }}
        />
      </FormControl>
      <FormControl label='Payment Methods'>
        <div className='control--stacked'>
          <Checkbox
            label='Credit Card'
            checked={paymentProcessingPanel.moneris.paymentMethod.creditCard}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_PAYMENT_METHOD_CREDIT_CARD
              );
            }}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <Checkbox
            label='Apple Pay'
            checked={paymentProcessingPanel.moneris.paymentMethod.applePay}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_PAYMENT_METHOD_APPLE_PAY
              );
            }}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <Checkbox
            label='Google Pay'
            checked={paymentProcessingPanel.moneris.paymentMethod.googlePay}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_PAYMENT_METHOD_GOOGLE_PAY
              );
            }}
            loading={isProcessing}
          />
        </div>
      </FormControl>
      <FormControl label='Apple Pay / Google Pay Networks'>
        <div className='control--stacked'>
          <Checkbox
            label='Mastercard'
            checked={paymentProcessingPanel.moneris.appleGoogleNetwork.mastercard}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_APPLE_GOOGLE_NETWORK_MASTERCARD
              );
            }}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <Checkbox
            label='Visa'
            checked={paymentProcessingPanel.moneris.appleGoogleNetwork.visa}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_APPLE_GOOGLE_NETWORK_VISA
              );
            }}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <Checkbox
            label='Amex'
            checked={paymentProcessingPanel.moneris.appleGoogleNetwork.amex}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_APPLE_GOOGLE_NETWORK_AMEX
              );
            }}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <Checkbox
            label='Discover'
            checked={paymentProcessingPanel.moneris.appleGoogleNetwork.discover}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_APPLE_GOOGLE_NETWORK_DISCOVER
              );
            }}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <Checkbox
            label='Interac'
            checked={paymentProcessingPanel.moneris.appleGoogleNetwork.interac}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_APPLE_GOOGLE_NETWORK_INTERAC
              );
            }}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <Checkbox
            label='JCB'
            checked={paymentProcessingPanel.moneris.appleGoogleNetwork.jcb}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_APPLE_GOOGLE_NETWORK_JCB
              );
            }}
            loading={isProcessing}
          />
        </div>
      </FormControl>
      <FormControl label='Rate Limiting'>
        <FormControlStacked>
          <Toggle
            checked={paymentProcessingPanel.moneris.rateLimits.enabled}
            onChange={(checked) => {
              updateLocationParam(
                checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_RATE_LIMITING_TOGGLE
              );
            }}
            loading={isProcessing}
          />
        </FormControlStacked>
        <FormControlStacked>
          <Checkbox
            disabled={true}
            checked={true}
            label='Enforce rate limits on manual card payments'
            loading={isProcessing}
          />
        </FormControlStacked>
        <FormControlStacked>
          <Checkbox
            disabled={!paymentProcessingPanel.moneris.rateLimits.enabled}
            checked={paymentProcessingPanel.moneris.rateLimits.enforceRateLimitsForGooglePay}
            label='Enforce rate limits on Google Pay transactions'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_RATE_LIMITING_FOR_GOOGLE_PAY
              );
            }}
            loading={isProcessing}
          />
        </FormControlStacked>
        <FormControlStacked>
          <FormattedInput
            format='money'
            disabled={!paymentProcessingPanel.moneris.rateLimits.enabled}
            value={paymentProcessingPanel.moneris.rateLimits.amount}
            onChange={(event) => {
              updateLocationParam(
                toNumber(event.target.value),
                LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_RATE_LIMITING_AMOUNT
              );
            }}
            loading={isProcessing}
          />
          <span className='form__control__separator'>Per</span>
          <FormattedInput
            format='min'
            disabled={!paymentProcessingPanel.moneris.rateLimits.enabled}
            value={paymentProcessingPanel.moneris.rateLimits.duration / 60}
            onChange={(event) => {
              updateLocationParam(
                toNumber(event.target.value) * 60,
                LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_RATE_LIMITING_TIME_INTERVAL
              );
            }}
            loading={isProcessing}
          />
        </FormControlStacked>
      </FormControl>
      <FormControl label='Additional Settings'>
        <div className='control--stacked'>
          <Checkbox
            label='Allow small transactions'
            checked={paymentProcessingPanel.moneris.allowSmallTransactions}
            onChange={(event) => {
              updateLocationParam(event.target.checked, LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_SMALL_TRANSACTIONS);
            }}
            loading={isProcessing}
          />
        </div>
      </FormControl>
    </>
  );
};

export default MonerisPP;
