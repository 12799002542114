import { IAdminUser } from '@ready/dashboardv2api.contracts';
import { toastErrorState } from '../../redux/actions/uiActions/responseStateActions';
import AdminsService from '../services/AdminsService';
import { IUIAdmin } from './AdminsListState';

export const ADMIN_FORM_ACTION_TYPES = {
  UPDATE_FORM_EMAIL: 'UPDATE_FORM_EMAIL',
  UPDATE_FORM_FAMILY_NAME: 'UPDATE_FORM_FAMILY_NAME',
  UPDATE_FORM_GIVEN_NAME: 'UPDATE_FORM_GIVEN_NAME',

  SUBMIT_FORM_BEGIN: 'SUBMIT_FORM_BEGIN',
  SUBMIT_FORM_SUCCESS: 'SUBMIT_FORM_SUCCESS',
  SUBMIT_FORM_ERROR: 'SUBMIT_FORM_ERROR',
  RESET_ADMIN_FORM: 'RESET_ADMIN_FORM',
  INITIALIZE_ADMIN_FORM: 'INITIALIZE_ADMIN_FORM',

  UPDATE_FORM_EMAIL_ERROR: 'UPDATE_FORM_EMAIL_ERROR',
  UPDATE_FORM_GIVEN_NAME_ERROR: 'UPDATE_FORM_GIVEN_NAME_ERROR',
  UPDATE_FORM_FAMILY_NAME_ERROR: 'UPDATE_FORM_FAMILY_NAME_ERROR',
  RESET_ADMIN_FORM_ERRORS: 'RESET_ADMIN_FORM_ERRORS',

  RESET_ADMIN_ID_TRANSACTION: 'RESET_ADMIN_ID_TRANSACTION',
};

export const postAdmin = (adminFormData: IAdminUser) => async (dispatch: any) => {
  try {
    dispatch(submitFormBegin());
    const admin = await AdminsService.createAdmin(adminFormData);
    dispatch(submitFormSuccess(admin));
  } catch (err) {
    dispatch(submitFormError());
    // @ts-ignore
    dispatch(toastErrorState(err.status, err.message));
  }
};
export const putAdmin = (id: string, uid: string, adminFormData: IAdminUser) => async (dispatch: any) => {
  try {
    dispatch(submitFormBegin());
    const admin = await AdminsService.updateAdmin(id, adminFormData);
    dispatch(submitFormSuccess(admin));
  } catch (err) {
    dispatch(submitFormError());
    // @ts-ignore
    dispatch(toastErrorState(err.status, err.message));
  }
};

export const updateFormEmail = (payload: string) => async (dispatch: any) => {
  if (payload !== '') {
    dispatch(updateFormEmailError(false));
  }
  dispatch({
    type: ADMIN_FORM_ACTION_TYPES.UPDATE_FORM_EMAIL,
    payload,
  });
};

export const updateFormFamilyName = (payload: string) => (dispatch: any) => {
  if (payload !== '') {
    dispatch(updateFormFamilyNameError(false));
  }
  dispatch({
    type: ADMIN_FORM_ACTION_TYPES.UPDATE_FORM_FAMILY_NAME,
    payload,
  });
};

export const updateFormGivenName = (payload: string) => (dispatch: any) => {
  if (payload !== '') {
    dispatch(updateFormGivenNameError(false));
  }
  dispatch({
    type: ADMIN_FORM_ACTION_TYPES.UPDATE_FORM_GIVEN_NAME,
    payload,
  });
};

export const submitFormBegin = () => ({
  type: ADMIN_FORM_ACTION_TYPES.SUBMIT_FORM_BEGIN,
});

export const submitFormSuccess = (payload: IUIAdmin) => ({
  type: ADMIN_FORM_ACTION_TYPES.SUBMIT_FORM_SUCCESS,
  payload,
});

export const submitFormError = () => ({
  type: ADMIN_FORM_ACTION_TYPES.SUBMIT_FORM_ERROR,
});

export const resetAdminForm = () => ({
  type: ADMIN_FORM_ACTION_TYPES.RESET_ADMIN_FORM,
});

export const updateFormEmailError = (payload: boolean) => ({
  type: ADMIN_FORM_ACTION_TYPES.UPDATE_FORM_EMAIL_ERROR,
  payload,
});

export const updateFormGivenNameError = (payload: boolean) => ({
  type: ADMIN_FORM_ACTION_TYPES.UPDATE_FORM_GIVEN_NAME_ERROR,
  payload,
});

export const updateFormFamilyNameError = (payload: boolean) => ({
  type: ADMIN_FORM_ACTION_TYPES.UPDATE_FORM_FAMILY_NAME_ERROR,
  payload,
});

export const resetAdminFormErrors = () => ({
  type: ADMIN_FORM_ACTION_TYPES.RESET_ADMIN_FORM_ERRORS,
});

export const initializeAdminForm = (payload: IAdminUser) => ({
  type: ADMIN_FORM_ACTION_TYPES.INITIALIZE_ADMIN_FORM,
  payload,
});

export const resetAdminIdTransaction = () => ({
  type: ADMIN_FORM_ACTION_TYPES.RESET_ADMIN_ID_TRANSACTION,
});
