import React from 'react';
import { Widget } from '../Widget/Widget';
import styles from './GuestSatisfaction.module.scss';
import classnames from 'classnames';
import { IFeedbackReportGuestSatisfactionClientData } from '../../redux';
import { SatisfactionFactor } from './StatisfactionFactor';
import TextIcon, { Icon } from '../../../../components/Icon/TextIcon';

interface GuestSatisfactionProps {
  data: IFeedbackReportGuestSatisfactionClientData;
  loading?: boolean;
}

export function GuestSatisfaction({ data, loading }: GuestSatisfactionProps) {
  const { negative, positive } = data.counts.reduce(
    (acc, item) => ({
      negative: acc.negative + item.negative,
      positive: acc.positive + item.positive,
    }),
    { negative: 0, positive: 0 }
  );

  return (
    <Widget title='Guest Satisfaction' classes={styles.guestSatisfaction} loading={loading}>
      <div
        className={classnames(
          styles.guestSatisfaction__percentage,
          styles[`guestSatisfaction__percentage--${data.totalSatisfaction ?? 0}`]
        )}
      >
        <div className={styles.guestSatisfaction__percentageNum}>
          <span>{data.totalSatisfaction ?? 0}%</span>
        </div>
      </div>
      <div className={styles.guestSatisfaction__factorsBody}>
        <span className={styles.guestSatisfaction__factorsLine} />
        <span className={styles.guestSatisfaction__satisfactionCounter}>
          <span className={styles.guestSatisfaction__satisfactionCounterContainer}>
            <span>
              <TextIcon icon={Icon.FrownFace} additionalStyles={styles.guestSatisfaction__dissatisfiedIcon} />
              <span>{negative}</span>
            </span>
            <span>
              <span>{positive}</span>
              <TextIcon icon={Icon.SmileFace} additionalStyles={styles.guestSatisfaction__satisfiedIcon} />
            </span>
          </span>
        </span>
        <div className={styles.guestSatisfaction__factors}>
          {data.counts.map(({ text, positivePercent, negativePercent }, index) => (
            <SatisfactionFactor
              negativePercent={negativePercent}
              positivePercent={positivePercent}
              text={text}
              key={text}
              renderLine={index === 0}
            />
          ))}
        </div>
      </div>
    </Widget>
  );
}
