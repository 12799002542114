import React from 'react';
import styles from './OrderToggle.module.scss';
import TextIcon, { Icon } from '../Icon/TextIcon';
import { useClassNames } from '../../utils/cssUtils';
import DropDownMenu, { DropDownMenuToggleProps } from '../DropDownMenu/DropDownMenu';
import { ContextParams } from '../../types/ContextParams.interface';
import { useParams } from 'react-router-dom';

type OnChange = (companyId: string, locationId: string, isOn: boolean) => void;

export interface OrderToggleStateProps {
  orderingEnabled: boolean;
  orderingEnabledProcessing: boolean;
  alcoholOrderingEnabled: boolean;
  alcoholOrderingEnabledProcessing: boolean;
  loading: boolean;
}

export interface OrderToggleActionProps {
  onOrderingEnabledChange: OnChange;
  onAlcoholOrderingEnabledChange: OnChange;
}

const getOrderToggleText = (
  orderingEnabled: boolean,
  alcoholOrderingEnabled: boolean
): { text: string; subText?: string } =>
  !orderingEnabled
    ? { text: 'Ordering is Off' }
    : alcoholOrderingEnabled
    ? { text: 'Ordering is On' }
    : { text: 'Ordering is On', subText: '(No Alcohol)' };

const OrderToggle = ({
  orderingEnabled,
  alcoholOrderingEnabled,
  loading,
  orderingEnabledProcessing,
  alcoholOrderingEnabledProcessing,
  onOrderingEnabledChange,
  onAlcoholOrderingEnabledChange,
}: OrderToggleStateProps & OrderToggleActionProps): JSX.Element => {
  const disableInteraction = loading || orderingEnabledProcessing || alcoholOrderingEnabledProcessing;

  const [isOpen, setIsOpen] = React.useState(false);
  const { text, subText } = getOrderToggleText(orderingEnabled, alcoholOrderingEnabled);

  const { contextId, locationId } = useParams<ContextParams>();

  const handleOrderingEnabledChange = (checked: boolean) => {
    onOrderingEnabledChange(contextId, locationId, checked);
  };

  const handleAlcoholOrderingEnabledChange = (checked: boolean) => {
    onAlcoholOrderingEnabledChange(contextId, locationId, checked);
  };

  const handleOrderingButtonClick = (_: React.MouseEvent<HTMLButtonElement>) => {
    !isOpen && setIsOpen(true);
  };

  const handleOutsideDropDownClick = () => {
    !disableInteraction && isOpen && setIsOpen(false);
  };

  const dropDownOptions: DropDownMenuToggleProps[] = [
    {
      label: 'Ordering',
      checked: orderingEnabled,
      onChange: handleOrderingEnabledChange,
      loading: orderingEnabledProcessing,
    },
    {
      label: 'Alcohol Sales',
      checked: alcoholOrderingEnabled,
      onChange: handleAlcoholOrderingEnabledChange,
      loading: alcoholOrderingEnabledProcessing,
    },
  ];

  const orderingButtonClassNames = useClassNames([{ isOpen }, styles.orderingButton], styles);

  const statusTextClassName = useClassNames(
    [
      {
        orderOnText: orderingEnabled,
        orderOffText: !orderingEnabled,
        disabledText: disableInteraction,
      },
    ],
    styles
  );

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <button className={orderingButtonClassNames} onClick={handleOrderingButtonClick} disabled={disableInteraction}>
          <div className={statusTextClassName}>
            <TextIcon
              icon={orderingEnabled ? Icon.CheckmarkCircle : Icon.Alert}
              additionalStyles={styles.statusIndicator}
            />
            <span>{text}</span>
            {subText && <span className={styles.orderSubText}>{subText}</span>}
          </div>
          <TextIcon icon={Icon.AngleDown} additionalStyles={styles.openOptionsIcon} />
        </button>
        <DropDownMenu
          options={dropDownOptions}
          isVisible={isOpen}
          onOutsideInteraction={handleOutsideDropDownClick}
          snapTo='left'
        />
      </div>
    </div>
  );
};

export default OrderToggle;
