import React from 'react';
import classNames from 'classnames';

enum Icon {
  Alert = 'icon-dashboard-icons_alert',
  AngleDown = 'icon-dashboard-icons_angle-down',
  AngleLeft = 'icon-dashboard-icons_angle-left',
  AngleRight = 'icon-dashboard-icons_angle-right',
  AngleUp = 'icon-dashboard-icons_angle-up',
  ArrowLeft = 'icon-dashboard-icons_arrow-left',
  ArrowRight = 'icon-dashboard-icons_arrow-right',
  Calendar = 'icon-dashboard-icons_calendar',
  Camera = 'icon-dashboard-icons_camera',
  Checkmark = 'icon-dashboard-icons_checkmark',
  CheckmarkCircle = 'icon-dashboard-icons_checkmark-circle',
  Clock = 'icon-dashboard-icons_clock',
  Close = 'icon-dashboard-icons_close',
  Drag = 'icon-dashboard-icons_drag',
  Ellipsis = 'icon-dashboard-icons_ellipsis',
  EyeClosed = 'icon-dashboard-icons_eye-closed',
  EyeOpen = 'icon-dashboard-icons_eye-open',
  HeartOutline = 'icon-dashboard-icons_heart-outline',
  InfoCircle = 'icon-dashboard-icons_info-circle',
  Link = 'icon-dashboard-icons_link',
  Location = 'icon-dashboard-icons_location',
  Martini = 'icon-dashboard-icons_martini',
  Menu = 'icon-dashboard-icons_menu',
  Pencil = 'icon-dashboard-icons_pencil_pencil',
  QuestionmarkCircle = 'icon-dashboard-icons_questionmark-circle',
  Refresh = 'icon-dashboard-icons_refresh',
  Remove = 'icon-dashboard-icons_remove',
  RemoveCircle = 'icon-dashboard-icons_remove-circle',
  ReverseArrow = 'icon-dashboard-icons_reverse-arrow',
  Search = 'icon-dashboard-icons_search',
  SpeechBubble = 'icon-dashboard-icons_speech-bubble',
  Star = 'icon-dashboard-icons_star',
  StarCircle = 'icon-dashboard-icons_star-circle',
  TriDot = 'icon-dashboard-icons_tri-dot',
  Cog = 'icon-dashboard-icons_cog',
  Cutlery = 'icon-dashboard-icons_cutlery',
  TakeoutBag = 'icon-dashboard-icons_takeout-bag',
  Car = 'icon-dashboard-icons_car',
  CaretDownTiny = 'icon-dashboard-icons_caret-down-tiny',
  CaretDown = 'icon-dashboard-icons_caret-down',
  CaretRight = 'icon-dashboard-icons_caret-right',
  CollapsedSection = 'icon-dashboard-icons_collapsed-section',
  ExpandedSection = 'icon-dashboard-icons_expanded-section',
  PartialCollapsedSection = 'icon-dashboard-icons_partialcollapsed-section',
  TrashCan = 'icon-dashboard-icons_trash-can',
  SmileFace = 'icon-dashboard-icons_smile-face',
  FrownFace = 'icon-dashboard-icons_frown-face',
  ExternalLink = 'icon-dashboard-icons_external-link',
  UserCheckmark = 'icon-dashboard-icons_user-checkmark',
  Share = 'icon-dashboard-icons_share',
}

interface TextIconProps {
  icon: string | Icon;
  additionalStyles?: string;
}

const TextIcon = ({ icon, additionalStyles = '' }: TextIconProps) => (
  <span className={classNames(icon, additionalStyles)} />
);

export default TextIcon;
export { Icon };
