import React, { useEffect } from 'react';
import LayoutContent from 'components/AppLayout/LayoutContent';
import { CompanySettingsPageTabs } from './CompanySettingsPageTabs';
import ActionHeader from 'components/ActionHeader/ActionHeader';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { getCompanyInfoById } from './redux/thunk';
import { resetPaymentProcessingState } from './redux/slice';
import { CompaniesSettingsRoutes } from '../Router';

export const CompanySettingsPage = () => {
  const dispatch = useAppDispatch();
  const { contextId: companyId, tabId } = useParams<any>();

  const { companyName, isLoading } = useAppSelector((state) => state.companies.companySettings.companyInfo);

  useEffect(() => {
    dispatch(getCompanyInfoById({ companyId }));
    return () => {
      dispatch(resetPaymentProcessingState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <LayoutContent title='Company Details' containerType='within'>
      <ActionHeader text={companyName} backLinkTo={CompaniesSettingsRoutes.getCompaniesListPage()} />
      <CompanySettingsPageTabs openingTab={tabId} />
    </LayoutContent>
  );
};
