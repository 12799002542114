import React from 'react';
import FormattedInputNestedLabel from '../../../components/FormattedInputNestedLabel/FormattedInputNestedLabel';
import styles from './ModifierMaxValue.module.scss';

export interface ModifierMaxValueProps {
  itemId: string;
  value?: number;
  editing?: boolean;
  processing?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  className?: string;
  onChange?: (id: string, constraints: number) => void;
}

const ModifierMaxValue = (props: ModifierMaxValueProps): JSX.Element => {
  const {
    value,
    processing = false,
    itemId,
    editing = false,
    className,
    hasError = false,
    errorMessage = '',
    onChange,
  } = props;

  return (
    <div className={className}>
      {editing ? (
        <FormattedInputNestedLabel
          value={value ?? ''}
          format='number'
          label='Max'
          loading={processing}
          placeholder='e.g. 1'
          onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
            onChange && onChange(itemId, parseInt(event.target.value));
          }}
          withError={hasError}
          errorMessage={errorMessage}
        />
      ) : (
        <div>
          <span className={styles.maxLabel}>Max: </span>
          {value ? value : 'None'}
        </div>
      )}
    </div>
  );
};

export default ModifierMaxValue;
