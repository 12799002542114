import React from 'react';
import { ICompanyIntegrations } from '@ready/dashboardv2api.contracts';
// components
import { FormControl } from '../../../components/Form';
import TextInput from '../../../components/TextInput/TextInput';
import SelectFilter, { Option } from '../../../components/SelectFilter/SelectFilter';
import { loadDiscounts, PANEL_LOYALTY_ACTIONS } from '../../redux/PanelLoyaltyActions';
import { loadSquarePOSLocations } from '../../redux/LocationSettingsActions';
import { useDispatch } from 'react-redux';
import Toggle from '../../../components/Toggle/Toggle';
import debounce from 'lodash/debounce';
import { IPosLoyaltyPanel } from '../../redux/PanelLoyaltyState';
import Notification from '../../../components/Notification/Notification';
import { Icon } from '../../../components/Icon/TextIcon';
import { IUpdateLocationParam } from '../LocationsEditPage';
import SelectFilterAsync from '../../../components/SelectFilter/SelectFilterAsync';

export type SquarePOSProps = {
  isProcessing: boolean;
  updateLocationParam: IUpdateLocationParam;
  posLoyaltyPanelInitialState: IPosLoyaltyPanel;
  locationId: string;
  companyId: string;
  companyIntegrations?: ICompanyIntegrations;
};

const SquarePOS = (props: SquarePOSProps) => {
  const {
    isProcessing,
    updateLocationParam,
    locationId,
    posLoyaltyPanelInitialState,
    companyIntegrations,
    companyId,
  } = props;

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(loadSquarePOSLocations(locationId));
    dispatch(loadDiscounts(locationId, companyId, ''));
  }, [companyId, dispatch, locationId]);

  const handleFetch = (query: string) => {
    dispatch(loadDiscounts(locationId, companyId, query));
  };

  const handleFetchWithFilter = debounce(handleFetch, 1000, {
    maxWait: 2000,
  });

  const mapSelectDiscountOptions = (optionValue: any): Option | null => {
    if (posLoyaltyPanelInitialState.discounts && posLoyaltyPanelInitialState.discounts.length > 0) {
      return posLoyaltyPanelInitialState.discounts.find(
        (optionObj: Option) => optionObj.value === optionValue
      ) as Option;
    }
    return null;
  };

  const mapSelectLocationOptions = (optionValue: any): Option | null => {
    if (posLoyaltyPanelInitialState.locations && posLoyaltyPanelInitialState.locations.length > 0) {
      return posLoyaltyPanelInitialState.locations.find(
        (optionObj: Option) => optionObj.value === optionValue
      ) as Option;
    }
    return null;
  };

  const handleProgramId = (programId: string | undefined) => {
    if (!programId) {
      updateLocationParam('main', PANEL_LOYALTY_ACTIONS.UPDATE_SQUARE_PROGRAM_ID);
    }
    return posLoyaltyPanelInitialState.squareOptions?.programId;
  };

  const activated = !!companyIntegrations?.square?.auth?.tokenInfo?.accessToken;
  return (
    <>
      {!activated && (
        <Notification type={'warning'}>
          <p>
            <i className={Icon.InfoCircle} />
            <span>
              The client must authorize and connect their Square account on the Company Settings page before set up can
              be completed.
            </span>
          </p>
        </Notification>
      )}
      <FormControl
        label='Location ID *'
        errorMessage={posLoyaltyPanelInitialState.validations.square.location.errorMessage}
        withError={posLoyaltyPanelInitialState.validations.square.location.hasError}
      >
        <SelectFilter
          options={posLoyaltyPanelInitialState.locations}
          value={mapSelectLocationOptions(posLoyaltyPanelInitialState.squareOptions?.locationId)}
          placeholder='Select a location'
          onChange={(event) => {
            updateLocationParam(event?.value, PANEL_LOYALTY_ACTIONS.UPDATE_SQUARE_LOCATION_ID);
          }}
          withError={posLoyaltyPanelInitialState.validations.square.location.hasError}
          loading={posLoyaltyPanelInitialState.loadingLocations}
          disabled={!activated}
          isSearchable
          isClearable
        />
      </FormControl>

      <FormControl
        label='Program ID *'
        errorMessage={posLoyaltyPanelInitialState.validations.square.programId.errorMessage}
        withError={posLoyaltyPanelInitialState.validations.square.programId.hasError}
      >
        <TextInput
          placeholder='Enter the Square Program ID'
          value={handleProgramId(posLoyaltyPanelInitialState.squareOptions?.programId)}
          withError={posLoyaltyPanelInitialState.validations.square.programId.hasError}
          loading={isProcessing}
          readOnly
          disabled
        />
      </FormControl>

      <FormControl
        label='Loyalty Discount *'
        errorMessage={posLoyaltyPanelInitialState.validations.square.loyaltyDiscount.errorMessage}
        withError={posLoyaltyPanelInitialState.validations.square.loyaltyDiscount.hasError}
      >
        <SelectFilterAsync
          options={posLoyaltyPanelInitialState.discounts}
          value={mapSelectDiscountOptions(posLoyaltyPanelInitialState.squareOptions?.loyaltyDiscount)}
          placeholder='Select discount from POS'
          onChange={(event) => {
            updateLocationParam(event?.value, PANEL_LOYALTY_ACTIONS.UPDATE_SQUARE_LOYALTY_DISCOUNT);
          }}
          handleFetch={handleFetchWithFilter}
          handleInitialFetch={() => handleFetch('')}
          loading={posLoyaltyPanelInitialState.loadingDiscounts}
          limit={50}
          withError={posLoyaltyPanelInitialState.validations.square.loyaltyDiscount.hasError}
          disabled={!activated}
        />
      </FormControl>

      <FormControl label='Require Phone Number Verification'>
        <Toggle
          checked={!posLoyaltyPanelInitialState.squareOptions?.skipPhoneVerification}
          onChange={(checked: boolean) => {
            updateLocationParam(checked, PANEL_LOYALTY_ACTIONS.UPDATE_SQUARE_SKIP_PHONE_VERIFICATION);
          }}
          loading={isProcessing}
          disabled={!activated}
        />
      </FormControl>
    </>
  );
};

export default SquarePOS;
