import { IPayAtTable } from '@ready/dashboardv2api.contracts';
import React, { ChangeEvent } from 'react';
import styles from './PayAtTable.module.scss';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import { FormControl } from '../../../../components/Form';
import TextArea from '../../../../components/TextArea/TextArea';
import { LOCATION_SETTINGS_ACTIONS } from '../../../redux/LocationSettingsActions';
import { IUpdateLocationParam } from '../../LocationsEditPage';

export interface PayAtTablePanelProps {
  isProcessing: boolean;
  updateLocationParam: IUpdateLocationParam;
  payAtTable: IPayAtTable;
}

const paymentConfirmedMessagePlaceholder =
  'This message will appear on the payment confirmation screen after a guest has completed payment.';

const PayAtTable = (props: PayAtTablePanelProps) => {
  const { isProcessing, updateLocationParam, payAtTable } = props;

  return (
    <div>
      <FormControl label='Configuration Options' additionalStyles={styles.toggle}>
        <div className='control--stacked'>
          <Checkbox
            label='Allow multi-select on multi check view'
            checked={payAtTable.allowMultiSelect}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              updateLocationParam(
                !payAtTable.allowMultiSelect,
                LOCATION_SETTINGS_ACTIONS.TOGGLE_PAY_AT_TABLE_MULTI_CHECK_CONFIGURATION_OPTION
              );
            }}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <Checkbox
            label='Allow Pay by Item'
            checked={payAtTable.payByItem}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              updateLocationParam(e.target.checked, LOCATION_SETTINGS_ACTIONS.UPDATE_PAY_BY_ITEM);
            }}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <Checkbox
            label='Allow Pay by Seat'
            checked={payAtTable.payBySeat}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              updateLocationParam(e.target.checked, LOCATION_SETTINGS_ACTIONS.UPDATE_PAY_BY_SEAT);
            }}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <Checkbox
            label='Verify ticket before payment'
            checked={payAtTable.verifyTicket}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              updateLocationParam(e.target.checked, LOCATION_SETTINGS_ACTIONS.TOGGLE_VERIFY_TICKET);
            }}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <Checkbox
            label='Require email address on checkout'
            additionalHelp='Stripe only'
            checked={payAtTable.requireEmailAddressOnCheckout ?? false}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              updateLocationParam(
                e.target.checked,
                LOCATION_SETTINGS_ACTIONS.TOGGLE_PAY_AT_TABLE_REQUIRE_EMAIL_ADDRESS_ON_CHECKOUT
              );
            }}
            loading={isProcessing}
          />
        </div>
      </FormControl>
      <FormControl label='Payment Confirmed Message' additionalStyles={styles.toggle}>
        <TextArea
          value={payAtTable.paymentConfirmationMessage || ''}
          placeholder={paymentConfirmedMessagePlaceholder}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            updateLocationParam(e.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_PAY_AT_TABLE_CONFIRMATION_MESSAGE);
          }}
          loading={isProcessing}
        ></TextArea>
      </FormControl>
    </div>
  );
};

export default PayAtTable;
