import React from 'react';
import { ICompanyIntegrations } from '@ready/dashboardv2api.contracts';
// components
import { FormGroup, FormControl } from '../../../components/Form';
import SelectFilter, { Option } from '../../../components/SelectFilter/SelectFilter';
import Toggle from '../../../components/Toggle/Toggle';
import { LIST_OPTIONS } from '../../../utils/selectListUtils/listOptions';
import { IPaymentProcessingPanel } from '../../redux/PanelPaymentProcessingState';
import { LOCATION_SETTINGS_ACTIONS } from '../../redux/LocationSettingsActions';
import StripePP from './StripePP';
import MonerisPP from './MonerisPP';
import IqPayPP from './IqPayPP';
import FreedomPayPP from './FreedomPayPP';
import RealexPP from './RealexPP';
import JetPayPP from './JetPayPP';
import SquarePP from './SquarePP';
import BamboraPP from './BamboraPP';
import { AdyenPP } from './AdyenPP';
import { IUpdateLocationParam } from '../LocationsEditPage';

interface Props {
  isProcessing: boolean;
  updateLocationParam: IUpdateLocationParam;
  paymentProcessingPanel: IPaymentProcessingPanel;
  companyIntegrations?: ICompanyIntegrations;
}

const mapSelectOptions = (allOptions: Option[], optionValue: any): Option => {
  return allOptions.find((optionObj: Option) => optionObj.value === optionValue) as Option;
};

const { paymentProvider } = LIST_OPTIONS;

const LocationPanelPaymentProcessing = (props: Props) => {
  const { isProcessing, updateLocationParam, paymentProcessingPanel, companyIntegrations } = props;

  const handlePaymentProviderChange = (data: Option) => {
    updateLocationParam(data.value, LOCATION_SETTINGS_ACTIONS.UPDATE_PAYMENT_PROVIDER);
  };

  return (
    <FormGroup groupName='Payment Processing'>
      <FormControl label='Payment Queues'>
        <Toggle
          checked={paymentProcessingPanel.paymentQueues}
          onChange={(status) => {
            updateLocationParam(status, LOCATION_SETTINGS_ACTIONS.UPDATE_PAYMENT_QUEUES);
          }}
          loading={isProcessing}
        />
      </FormControl>
      <FormControl label='Payment Provider'>
        <SelectFilter
          placeholder='Select a Payment Processor'
          options={paymentProvider}
          onChange={handlePaymentProviderChange}
          value={mapSelectOptions(paymentProvider, paymentProcessingPanel.providerName)}
          loading={isProcessing}
        />
      </FormControl>
      <>
        {paymentProcessingPanel.providerName === 'stripe' && <StripePP {...props} />}

        {paymentProcessingPanel.providerName === 'moneris' && <MonerisPP {...props} />}

        {paymentProcessingPanel.providerName === 'iqpay' && <IqPayPP {...props} />}

        {paymentProcessingPanel.providerName === 'freedompay' && <FreedomPayPP {...props} />}

        {paymentProcessingPanel.providerName === 'realex' && <RealexPP {...props} />}

        {paymentProcessingPanel.providerName === 'jetpay' && <JetPayPP {...props} />}

        {paymentProcessingPanel.providerName === 'square' && (
          <SquarePP {...props} companyIntegrations={companyIntegrations} />
        )}

        {paymentProcessingPanel.providerName === 'bambora' && <BamboraPP {...props} />}

        {paymentProcessingPanel.providerName === 'adyen' && <AdyenPP {...props} />}
      </>
    </FormGroup>
  );
};

export default LocationPanelPaymentProcessing;
