import TableLayoutContent from 'companyLocations/components/TableLayoutContent';
import Button from 'components/Button/Button';
import OverflowButton from 'components/OverflowButton/OverflowButton';
import { PaginationContainer } from 'components/PaginationContainer/PaginationContainer';
import SelectFilter, { Option } from 'components/SelectFilter/SelectFilter';
import { useResponsiveBreakpoint, useSearchParams } from 'hooks';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { prepareModal } from 'sharedMenuItems/redux/editAssignedLocations/assignLocationsSlice';
import { SharedMenuItemsLayout } from 'sharedMenuItems/SharedMenuItemsLayout';
import { SharedMenuItemsTabId } from 'sharedMenuItems/SharedMenuItemsTabs';
import styles from './ItemsAndModsAssignedLocationsPage.module.scss';
import { LinkStatusFilter } from './ItemsAndModsAssignedLocationsService';
import {
  deleteTemplateMenuItemThunk,
  getTemplateMenuItemThunk,
} from 'sharedMenuItems/redux/createEditItem/createEditItemThunkActions';
import { useHistory, useParams } from 'react-router-dom';
import { ContextParams } from 'types/ContextParams.interface';
import { DetailsTabs, DetailTabId } from '../createEditItem/components/tabs/DetailsTabs';
import ActionHeader from 'components/ActionHeader/ActionHeader';
import { SharedMenuItemsRoutes } from 'sharedMenuItems/Router';
import { updateQueryParamsDebounced } from 'utils/updatePath';
import TextInput from 'components/TextInput/TextInput';
import { selectTableState } from 'sharedMenuItems/redux/selectors';
import { decodeUriString } from 'utils/urlUtils/decodeUriString';
import {
  getAssignableLocationListThunk,
  getTemplateChildMenuItems,
  updateAssignedLocationsThunk,
} from './redux/thunks';
import { AssignedLocationsTable } from './components/table/AssignedLocationsTable';
import { BulkEditToolbarContent } from 'sharedMenuItems/components/bulkEditToolbarContent/BulkEditToolbarContent';
import {
  initCellCheckedState,
  setBulkLocationEditModalVisible,
  setBulkUnassignLocationModalVisible,
} from 'sharedMenuItems/redux/table/tableSlice';
import {
  LoadAssignedLocations,
  ManageAssignedLocationsModal,
} from 'sharedMenuItems/components/ManageAssignedLocationsModal/ManageAssignedLocationsModal';
import { ILocationAssignments } from '@ready/menu.core';
import { DuplicateSharedMenuItemModal } from '../createEditItem/components/duplicateSharedMenuItemModal/DuplicateSharedMenuItemModal';
import { DeleteConfirmationModal } from 'components/Modal';
import { setFormIsDirty } from 'redux/actions/uiActions/formStateActions';

const ItemsAndModsAssignedLocationsPage = () => {
  const dispatch = useAppDispatch();
  const { contextId: companyId, id: itemId } = useParams<ContextParams>();
  const { push } = useHistory();
  const { linkStatus, searchTerm, page } = useSearchParams();
  const { loading, items, pagination, totalLocationsCount } = useAppSelector(
    (state) => state.sharedMenuItems.itemAssignedLocationsList
  );
  const { itemCache, isItemDeleted, newId, item, processing } = useAppSelector((state) => state.sharedMenuItems.item);
  const { checkCells } = useAppSelector(selectTableState);
  const numOfCheckedCells = Object.values(checkCells).filter((value) => value).length;
  const decodedSearchTerm = decodeUriString(searchTerm || '');
  const [isDuplicateModalVisible, setIsDuplicateModalVisible] = React.useState<boolean>(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = React.useState<boolean>(false);
  const [linkedStatusFilter, setLinkedStatusFilter] = useState<LinkStatusFilter>(linkStatus || 'all');
  const [searchTermFilter, setSearchTermFilter] = useState<string>(decodedSearchTerm);

  useEffect(() => {
    dispatch(initCellCheckedState(items));
  }, [items, dispatch]);

  useEffect(() => {
    if (!itemCache) {
      dispatch(getTemplateMenuItemThunk({ companyId, itemId }));
    }
  }, [companyId, dispatch, itemId, itemCache]);

  useEffect(() => {
    dispatch(
      getTemplateChildMenuItems({
        companyId,
        itemId: itemId,
        linkStatusFilter: linkStatus,
        locationSearchTerm: decodedSearchTerm,
        page,
      })
    );
  }, [companyId, decodedSearchTerm, dispatch, itemId, linkStatus, page]);

  useEffect(() => {
    if (newId) {
      push(SharedMenuItemsRoutes.getItemAndModsItem(companyId, newId));
    } else if (isItemDeleted) {
      push(SharedMenuItemsRoutes.getItemAndModsRoute(companyId));
    }
  }, [companyId, isItemDeleted, newId, push]);

  useEffect(() => {
    updateQueryParamsDebounced(
      SharedMenuItemsRoutes.getAssignedLocationsRoute(companyId, itemId),
      { linkStatus: linkedStatusFilter, page: '', searchTerm: searchTermFilter },
      push
    );
  }, [companyId, itemId, linkedStatusFilter, push, searchTermFilter]);

  // if no results and was not filtered at all, this item was not assigned to any locations
  const isNotAssigned = items.length === 0 && !searchTerm && linkStatus === 'all' && !loading;

  const loadLocationAssignments = ({ page, isLoadMore, searchTerm }: LoadAssignedLocations) => {
    dispatch(getAssignableLocationListThunk({ companyId, itemId, page, isLoadMore, locationSearchTerm: searchTerm }));
  };

  const resetFilters = () => {
    setSearchTermFilter('');
    setLinkedStatusFilter('all');
    updateQueryParamsDebounced(
      SharedMenuItemsRoutes.getAssignedLocationsRoute(companyId, itemId),
      { searchTerm: '', page: '', linkedStatus: 'all' },
      push
    );
  };

  const hasPageDefaultFilters = decodedSearchTerm === '' && linkStatus === 'all' && page === undefined;
  const saveAssignedLocations = async ({
    allLocations,
    assignToLocations,
    unassignFromLocations,
  }: ILocationAssignments) => {
    await dispatch(
      updateAssignedLocationsThunk({
        companyId,
        itemId,
        allLocations,
        assignToLocations: assignToLocations ?? [],
        unassignFromLocations: unassignFromLocations ?? [],
      })
    );

    if (hasPageDefaultFilters) {
      dispatch(
        getTemplateChildMenuItems({
          companyId,
          itemId: itemId,
          linkStatusFilter: linkStatus,
          locationSearchTerm: decodedSearchTerm,
          page,
        })
      );
    } else {
      resetFilters();
    }
  };

  const onSearchTermChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTermFilter(event.currentTarget.value);
  };

  return (
    <SharedMenuItemsLayout tab={SharedMenuItemsTabId.ItemAndMods}>
      <ActionHeader
        text={itemCache?.displayName ?? ''}
        backLinkTo={SharedMenuItemsRoutes.getItemAndModsRoute(companyId)}
        actionButtons={[
          {
            label: 'Duplicate',
            variant: 'secondary-gray-bg',
            disabled: loading,
            onClick: () => {
              setIsDuplicateModalVisible(true);
            },
          },
          {
            label: 'Delete',
            variant: 'secondary-gray-bg',
            disabled: loading,
            onClick: () => {
              setIsDeleteModalVisible(true);
            },
          },
        ]}
      />
      <DetailsTabs selectedTabId={DetailTabId.AssignedLocations} />
      <div className={styles.container}>
        <TableLayoutContent
          className={styles.tableLayout}
          title='Assigned Locations'
          headerControls={
            <HeaderControls
              currentAssignedCount={pagination?.total}
              totalLocationsCount={totalLocationsCount}
              loadLocationAssignments={loadLocationAssignments}
              onSaveLocationAssignments={saveAssignedLocations}
            />
          }
          toolbarContent={
            numOfCheckedCells ? (
              <BulkEditToolbarContent
                pagination={pagination}
                setShowBulkEditModal={(showModal) => dispatch(setBulkLocationEditModalVisible(showModal))}
                setShowBulkUnassignModal={(showModal) => dispatch(setBulkUnassignLocationModalVisible(showModal))}
                disabled={loading}
              />
            ) : (
              <ToolbarContent
                searchTermFilter={searchTermFilter}
                linkStatusFilter={linkedStatusFilter}
                onSearchTermChange={onSearchTermChange}
                onLinkedStatusChange={(o) => setLinkedStatusFilter(o.value)}
              />
            )
          }
        >
          <div className={styles.mobileGap} />
          <PaginationContainer pagination={pagination || { length: 0, start: 0, total: 0, limit: 0 }}>
            {isNotAssigned ? (
              <div className={styles.notAssigned}>
                This {itemCache?.itemType === 'option' ? 'modifier ' : 'item '}
                has not been assigned to any locations yet.
              </div>
            ) : (
              <AssignedLocationsTable
                hasPageDefaultFilters={hasPageDefaultFilters}
                resetPageFilters={resetFilters}
                items={items}
                loading={loading}
              />
            )}
          </PaginationContainer>
        </TableLayoutContent>
      </div>

      {isDeleteModalVisible && (
        <DeleteConfirmationModal
          setShowModal={setIsDeleteModalVisible}
          item='Menu Item'
          itemName={item?.displayName ?? ''}
          loading={processing}
          handleDelete={() => {
            dispatch(
              deleteTemplateMenuItemThunk({
                companyId,
                itemId: itemId,
                itemType: item?.itemType,
              })
            );
            dispatch(setFormIsDirty(false));
          }}
        />
      )}

      {isDuplicateModalVisible && (
        <DuplicateSharedMenuItemModal companyId={companyId} setVisible={setIsDuplicateModalVisible} />
      )}
    </SharedMenuItemsLayout>
  );
};

interface HeaderControlsProps {
  currentAssignedCount?: number;
  totalLocationsCount?: number;
  loadLocationAssignments?: (params: LoadAssignedLocations) => void;
  onSaveLocationAssignments?: (assignments: ILocationAssignments) => Promise<void>;
}

const HeaderControls = ({
  currentAssignedCount = 0,
  totalLocationsCount = 0,
  loadLocationAssignments,
  onSaveLocationAssignments,
}: HeaderControlsProps) => {
  const dispatch = useAppDispatch();
  const [isModalVisible, setModalVisible] = React.useState<boolean>(false);
  const { loading } = useAppSelector((state) => state.sharedMenuItems.itemAssignedLocationsList);

  const openEditAssignedLocationModal = async (): Promise<void> => {
    setModalVisible(true);
    dispatch(prepareModal());
  };

  const { isMobile } = useResponsiveBreakpoint();

  return (
    <>
      {loadLocationAssignments && onSaveLocationAssignments && isModalVisible && (
        <ManageAssignedLocationsModal
          currentAssignedCount={currentAssignedCount}
          totalLocationsCount={totalLocationsCount}
          headerLabel='Edit Assigned Locations'
          setShowModal={(isVisible) => setModalVisible(isVisible)}
          loadLocationAssignments={loadLocationAssignments}
          saveAssignedLocations={onSaveLocationAssignments}
        />
      )}
      {isMobile ? (
        <OverflowButton
          options={[
            {
              label: 'Edit Assigned Locations ',
              onClick: openEditAssignedLocationModal,
              primary: true,
            },
          ]}
        />
      ) : (
        <Button
          label='Edit Assigned Locations'
          onClick={openEditAssignedLocationModal}
          variant='secondary'
          disabled={loading}
        />
      )}
    </>
  );
};

const options: Option<LinkStatusFilter>[] = [
  {
    label: 'Linked & Unlinked',
    value: 'all',
  },
  {
    label: 'Linked',
    value: 'linked',
  },
  {
    label: 'Unlinked',
    value: 'unlinked',
  },
];

interface Props {
  linkStatusFilter: LinkStatusFilter;
  searchTermFilter: string;
  onSearchTermChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onLinkedStatusChange: (data: Option<LinkStatusFilter>) => void;
}

const ToolbarContent = ({ linkStatusFilter, searchTermFilter, onSearchTermChange, onLinkedStatusChange }: Props) => {
  return (
    <div className={styles.toolbar}>
      <div className={styles.locationFilter}>
        <TextInput placeholder='Search locations' value={searchTermFilter} onChange={onSearchTermChange} />
      </div>
      <div className={styles.linkedStatusFilter}>
        <SelectFilter
          options={options}
          value={options.find((o) => o.value === linkStatusFilter)}
          onChange={(o) => onLinkedStatusChange(o)}
        />
      </div>
    </div>
  );
};

export { ItemsAndModsAssignedLocationsPage };
