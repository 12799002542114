import React from 'react';
import styles from './TableAssignmentPanelHeader.module.scss';
import Button from '../../components/Button/Button';
import IconButton from '../../components/IconButton/IconButton';
import TextIcon, { Icon } from '../../components/Icon/TextIcon';
import useResponsiveBreakpoint from '../../hooks/useResponsiveBreakpoint';
import OverflowButton from '../../components/OverflowButton/OverflowButton';
import { useClassNames } from '../../utils/cssUtils';
import { ITableAssignment } from '@ready/table.core';

interface TableAssignmentPanelHeaderProps {
  tableCount: number;
  employee: ITableAssignment['employee'];
  onEditClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onRemoveClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onExpandToggleClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isExpanded: boolean;
  disabled: boolean;
  hasAllPermission?: boolean;
}

const TableAssignmentPanelHeader = ({
  tableCount,
  employee,
  onEditClick,
  onRemoveClick,
  onExpandToggleClick,
  isExpanded,
  disabled,
  hasAllPermission,
}: TableAssignmentPanelHeaderProps): JSX.Element => {
  const { isMobile } = useResponsiveBreakpoint();

  const containerClassName = useClassNames([{ expanded: isExpanded }, styles.container], styles);

  const { name, firstName, lastName, externalId } = employee;
  const serverName = name || (firstName && lastName) ? `${firstName} ${lastName}` : `Employee ${externalId};'`;

  return (
    <div className={containerClassName}>
      <div className={styles.titleContainer}>
        <h3 className={styles.serverName}>{serverName}</h3>
        <p className={styles.tableCount}>{`(${tableCount} tables)`}</p>
      </div>
      <div className={styles.controlContainer}>
        {hasAllPermission && (
          <>
            {isMobile ? (
              <MenuControls onEditClick={onEditClick} onRemoveClick={onRemoveClick} disabled={disabled} />
            ) : (
              <ButtonControls onEditClick={onEditClick} onRemoveClick={onRemoveClick} disabled={disabled} />
            )}
          </>
        )}
        <div className={styles.buttonContainer}>
          <IconButton onClick={onExpandToggleClick}>
            <TextIcon icon={isExpanded ? Icon.AngleUp : Icon.AngleDown} additionalStyles={styles.expandButton} />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default TableAssignmentPanelHeader;

interface ControlProps {
  onEditClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onRemoveClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled: boolean;
}

const ButtonControls = ({ onEditClick, onRemoveClick, disabled }: ControlProps) => (
  <>
    <div className={styles.buttonContainer}>
      <Button label='Edit' onClick={onEditClick} variant='secondary' disabled={disabled} />
    </div>
    <div className={styles.buttonContainer}>
      <Button label='Remove' onClick={onRemoveClick} variant='secondary' disabled={disabled} />
    </div>
  </>
);

const MenuControls = ({ onEditClick, onRemoveClick, disabled }: ControlProps) => (
  <>
    <div className={styles.buttonContainer}>
      <OverflowButton
        options={[
          { label: 'Edit', onClick: onEditClick, primary: true, disabled },
          { label: 'Remove', onClick: onRemoveClick, primary: true, disabled },
        ]}
        isVisible={true}
      />
    </div>
  </>
);
