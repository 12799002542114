import { ModalBody, ModalFooter, ModalHeader } from 'components/Modal';
import Modal, { ModalProps } from 'components/Modal/Modal';
import React from 'react';

interface UnassignLocationModalProps extends Pick<ModalProps, 'setShowModal' | 'loading'> {
  itemType: 'Modifier' | 'Item' | 'Item Group';
  itemName?: string;
  locationName?: string;
  onUnassign: () => void;
}

export const UnassignLocationModal = ({
  itemType,
  onUnassign,
  setShowModal,
  itemName = '',
  locationName = '',
  loading = false,
}: UnassignLocationModalProps) => {
  return (
    <Modal setShowModal={setShowModal}>
      <ModalHeader
        headerLabel={`Unassign Shared Menu ${itemType}?`}
        setShowModal={(visible) => setShowModal(visible)}
      />
      <ModalBody>
        Are you sure you want to unassign the shared {itemType.toLocaleLowerCase()} "<b>{itemName}</b>" from "
        <b>{locationName}</b>"? It will be removed from all menus at this location.
      </ModalBody>
      <ModalFooter
        primaryLabel={`Unassign Shared Menu ${itemType}`}
        primaryActionHandler={onUnassign}
        secondaryLabel='Cancel'
        secondaryActionHandler={() => setShowModal(false)}
        loading={loading}
      />
    </Modal>
  );
};
