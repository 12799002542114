import classNames from 'classnames/bind';
import { Argument } from 'classnames';

// Todo: remove either useClassNames or compositeStyles functions,
// since it's a duplicate code
export const useClassNames = (classes: Argument[] | Argument, styles: { [key: string]: string }) => {
  const cx = classNames.bind(styles);
  return cx(classes);
};

export const compositeStyles = (classes: Argument[] | Argument, styles: { [key: string]: string }) => {
  const cx = classNames.bind(styles);
  return cx(classes);
};
