import { DropDownButtonOption } from 'components/DropDownButton/DropDownButton';
import OverflowButton from 'components/OverflowButton/OverflowButton';
import SearchParamInput from 'components/SearchInput/SearchParamInput';
import React from 'react';
import { PageControls } from 'sharedMenuItems/components/PageControls';
import styles from './ModifierGroupListPageControls.module.scss';
import Button from 'components/Button/Button';
import { useHistory, useParams } from 'react-router-dom';
import { SharedMenuItemsRoutes } from 'sharedMenuItems/Router';
import { ContextParams } from 'types/ContextParams.interface';

const ModifierGroupListPageControls = () => {
  const { push } = useHistory();
  const { contextId: companyId } = useParams<ContextParams>();

  const overflowButtonOptions: DropDownButtonOption[] = [
    {
      label: '+ New Modifier Group',
      disabled: false,
      onClick: () => {
        push(SharedMenuItemsRoutes.getModifierGroupDetailsRoute(companyId));
      },
    },
  ];

  return (
    <PageControls>
      <div className={styles.searchFilter}>
        <SearchParamInput placeholder='Search modifier groups' additionalClassName='white search-input-full-width' />
        <OverflowButton className={styles.mobileOverflowButton} options={overflowButtonOptions}></OverflowButton>
      </div>

      <div className={styles.buttons}>
        <Button
          label='+ New Modifier Group'
          onClick={() => {
            push(SharedMenuItemsRoutes.getModifierGroupDetailsRoute(companyId));
          }}
        />
      </div>
      <OverflowButton options={overflowButtonOptions} className={styles.tabletOverflowButton}></OverflowButton>
    </PageControls>
  );
};

export { ModifierGroupListPageControls };
