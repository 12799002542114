import { createSelector } from '@reduxjs/toolkit';
import { startOfMonth, subMonths } from 'date-fns';
import { createDateCell, TextCell } from '../../components/TableV2/Cell/SpecialCells';
import { getWrappedCellsWithMobileLayout } from '../../components/TableV2/Cell/Cell';
import { getSortableHeaderCell } from '../../components/TableV2/Cell/HeaderCell';
import styles from './EmailMarketingList.module.scss';
import { RootState } from '../../redux/store';
import { IEmailMarketingListItem } from '@ready/dashboardv2api.contracts';

const headerColumnsSelector = createSelector(
  (state: RootState) => state,
  (state) => state.reports.emailMarketingList.headerColumns
);

const cells = getWrappedCellsWithMobileLayout(
  {
    date: createDateCell<IEmailMarketingListItem>({
      dateGetter: (rowData) => rowData.lastSubTime,
      timezoneGetter: (rowData) => rowData.timezone,
    }),
    locationName: TextCell,
    email: TextCell,
  },
  headerColumnsSelector,
  styles.cellMobileLayoutWrapper,
  styles.cellMobileLayoutWrapped
);

const headerCells = {
  date: getSortableHeaderCell('lastSubTime', { classes: styles['headerCell--date'] }),
  locationName: getSortableHeaderCell('locationName', { classes: styles['headerCell--location'] }),
  email: getSortableHeaderCell('email', { classes: styles['headerCell--email'] }),
};

const endMonth = startOfMonth(new Date());
const startMonth = subMonths(endMonth, 1);
const initDateFilter = { endMonth, startMonth };

const maxDaysToChoose = 366;

export { headerCells, cells, initDateFilter, maxDaysToChoose };
