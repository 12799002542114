import { ITag } from '@ready/menu.core';
import React, { RefObject } from 'react';
import { FormControl } from '../../components/Form';
import TextIcon, { Icon } from '../../components/Icon/TextIcon';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '../../components/Modal';
import { ModalProps } from '../../components/Modal/Modal';
import TextInput from '../../components/TextInput/TextInput';
import { ITagForm } from '../redux/TagsBadgesListState';
import Tag from './Tag';
import styles from './TagFormModal.module.scss';

export interface TagFormModalProps extends Pick<ModalProps, 'setShowModal'> {
  setShowModal: (visible: boolean) => void;
  onChangeShortName: (shortName: string, isUnique: boolean) => void;
  onChangeUniqueTagName: (tagName: string, isUnique: boolean) => void;
  onSave: () => void;
  tagForm: ITagForm;
  header: string;
  saveLabel: string;
  tagList: ITag[];
}

const TagFormModal = (props: TagFormModalProps): JSX.Element | null => {
  const {
    setShowModal,
    onSave,
    tagForm,
    tagForm: { processing },
    header,
    saveLabel,
    onChangeShortName,
    onChangeUniqueTagName,
    tagList,
  } = props;

  const handleDisplayShortName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const valueDuplicated = tagList.filter(
      (element) =>
        element.shortCode.toUpperCase() === event.target.value.toUpperCase() && element._id !== tagForm.tag._id
    );
    onChangeShortName(event.target.value, valueDuplicated.length === 0);
  };

  const handleDisplayUniqueName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const valueDuplicated = tagList.filter(
      (element) => element.name.toUpperCase() === event.target.value.toUpperCase() && element._id !== tagForm.tag._id
    );
    onChangeUniqueTagName(event.target.value, valueDuplicated.length === 0);
  };

  const inputRef: RefObject<HTMLInputElement> = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return (
    <Modal loading={processing} setShowModal={setShowModal}>
      <ModalHeader headerLabel={header} setShowModal={setShowModal} />
      <ModalBody>
        <FormControl
          label='Tag Name *'
          bold
          withError={tagForm.validation.tagName.hasError}
          errorMessage={tagForm.validation.tagName.errorMessage}
        >
          <TextInput
            placeholder='e.g. Gluten Free'
            loading={false}
            value={tagForm.tag.name}
            onChange={handleDisplayUniqueName}
            inputRef={inputRef}
            withError={tagForm.validation.tagName.hasError}
          />
        </FormControl>
        <div className={styles.container}>
          <div>
            <FormControl
              label='Short Code (Max 2 char) *'
              bold
              withError={tagForm.validation.tagShortCode.hasError}
              errorMessage={
                tagForm.validation.tagShortCode.notUnique ? '' : tagForm.validation.tagShortCode.errorMessage
              }
            >
              <TextInput
                placeholder='e.g. GF'
                loading={false}
                value={tagForm.tag.shortCode}
                inputRef={inputRef}
                onChange={handleDisplayShortName}
                maxLength={2}
                withError={tagForm.validation.tagShortCode.hasError}
              />
            </FormControl>
          </div>
          <div className={styles.tagIconCenter}>
            <Tag item={tagForm.tag} />
          </div>
        </div>
        {tagForm.validation.tagShortCode.notUnique ? (
          <div className={styles.warning}>
            <TextIcon icon={Icon.Alert} /> {tagForm.validation.tagShortCode.errorMessage}
          </div>
        ) : null}
      </ModalBody>

      <ModalFooter
        loading={processing}
        primaryLabel={saveLabel}
        primaryActionHandler={onSave}
        secondaryLabel='Cancel'
        secondaryActionHandler={() => setShowModal(false)}
      />
    </Modal>
  );
};

export default TagFormModal;
