import React, { ChangeEvent, RefObject } from 'react';

import { IModifierGroupForm } from '../../redux/ModifierGroupsState';

import styles from './ModifierGroupDetailsForm.module.scss';

import { Form, FormControl } from '../../../components/Form';
import TextInput from '../../../components/TextInput/TextInput';
import TextArea from '../../../components/TextArea/TextArea';
import FormattedInputNestedLabel from '../../../components/FormattedInputNestedLabel/FormattedInputNestedLabel';

export interface ModifierGroupDetailsFormProps {
  inPanel?: boolean;
  modifierGroupForm: IModifierGroupForm;
  onNameChange: (name: string) => void;
  onDisplayNameChange: (name: string) => void;
  onGuestInstructionsChange: (guestInstructions: string) => void;
  onMinimumChange: (minimum: number) => void;
  onMaximumChange: (maximum: number) => void;
}

const ModifierGroupDetailsForm = (props: ModifierGroupDetailsFormProps): JSX.Element => {
  const {
    inPanel = false,
    modifierGroupForm: { processing, modifierGroup, validation },
    onNameChange,
    onDisplayNameChange,
    onGuestInstructionsChange,
    onMinimumChange,
    onMaximumChange,
  } = props;

  const inputRef: RefObject<HTMLInputElement> = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return (
    <Form hasGroups={!inPanel} onSubmit={() => {}}>
      <FormControl
        label='Group Name *'
        withError={validation.name.hasError}
        errorMessage={validation.name.errorMessage}
      >
        <TextInput
          value={modifierGroup.name}
          placeholder='e.g. Sides for burgers'
          loading={processing}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            onNameChange(e.target.value);
          }}
          withError={validation.name.hasError}
          inputRef={inputRef}
        />
      </FormControl>

      <FormControl label='Display Name' labelNoteComponent={<span className={styles.labelNote}>(optional)</span>}>
        <TextInput
          value={modifierGroup.displayName}
          placeholder='e.g. Select sides'
          loading={processing}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            onDisplayNameChange(e.target.value);
          }}
        />
      </FormControl>

      <FormControl label='Guest Instructions'>
        <TextArea
          value={modifierGroup.guestInstructions || ''}
          placeholder='e.g. Includes small fries, or you can upgrade for a fee.'
          loading={processing}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>): void => {
            onGuestInstructionsChange(e.target.value);
          }}
        />
      </FormControl>

      <FormControl label='Required Selection' additionalStyles={styles.constraints}>
        <FormattedInputNestedLabel
          value={modifierGroup.constraints.min || ''}
          format='number'
          label='Minimum'
          placeholder='e.g. 1'
          loading={processing}
          withError={validation.constraints.hasError || validation.min.hasError}
          errorMessage={validation.constraints.errorMessage || validation.min.errorMessage}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            onMinimumChange(parseInt(e.target.value));
          }}
        />
        <FormattedInputNestedLabel
          value={modifierGroup.constraints.max || ''}
          format='number'
          label='Maximum'
          placeholder='e.g. 1'
          loading={processing}
          withError={validation.constraints.hasError || validation.max.hasError}
          errorMessage={validation.max.errorMessage}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            onMaximumChange(parseInt(e.target.value));
          }}
        />
      </FormControl>
    </Form>
  );
};

export default ModifierGroupDetailsForm;
