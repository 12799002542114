import React, { useEffect, useState, useMemo, useCallback } from 'react';
import ListModal from 'components/Modal/ListModal';
import InfiniteScrollingList from 'components/InfiniteScrollingList/InfiniteScrollingList';
import styles from './SelectModifierGroupModal.module.scss';
import SearchInput from 'components/SearchInput/SearchInput';
import { RootState, useAppDispatch, useAppSelector } from 'redux/store';
import { ITemplateModifierGroup } from '@ready/menu.core';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { ContextParams } from 'types/ContextParams.interface';
import { getModifierGroupsForSelectModalThunk } from './redux/thunks';
import { select, deselect, prepare } from './redux/slice';
import Notification from 'components/Notification/Notification';
import { Icon } from 'components/Icon/TextIcon';
import { buildItemComponent } from 'menus/components/ModifierGroups/AddModifierGroupList';

interface Props {
  selectedIds: string[];
  parentModifierGroupId?: string;
  headerLabel?: string;
  setShowModal: (isVisible: boolean) => void;
  onAdd: (modifierGroup: ITemplateModifierGroup) => void;
  onRemove: (modifierGroupId: string) => void;
}

export const SelectModifierGroupModal = ({
  headerLabel,
  setShowModal,
  selectedIds,
  onRemove,
  onAdd,
  parentModifierGroupId,
}: Props): JSX.Element => {
  const { contextId: companyId } = useParams<ContextParams>();
  const [query, setQuery] = useState<string>('');

  const dispatch = useAppDispatch();
  const {
    page,
    loading,
    paginationAvailable,
    paginationLoading,
    filtered,
    error,
    items,
    selectedIds: stateSelectedIds,
  } = useAppSelector((state: RootState) => state.sharedMenuItems.modifierGroups.selectModal);

  const getItemsDebounced = useMemo(
    () =>
      debounce((searchTerm) => {
        dispatch(getModifierGroupsForSelectModalThunk({ isLoadMore: false, companyId, searchTerm: searchTerm }));
      }, 500),
    [companyId, dispatch]
  );

  const loadItems = useCallback(() => {
    dispatch(getModifierGroupsForSelectModalThunk({ isLoadMore: false, companyId }));
  }, [companyId, dispatch]);

  const loadMore = useCallback(() => {
    dispatch(
      getModifierGroupsForSelectModalThunk({
        isLoadMore: true,
        companyId,
        searchTerm: query,
        page: page + 1,
      })
    );
  }, [companyId, dispatch, page, query]);

  useEffect(() => {
    dispatch(prepare(selectedIds));
    // only load up the the selectedIds when rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ListModal
      additionalBodyStyles={items.length > 0 ? styles.modalBody : undefined}
      setShowModal={setShowModal}
      headerLabel={headerLabel}
      showDismissButton
      searchControl={
        <>
          <SearchInput
            value={query}
            placeholder={`Search modifier groups`}
            fullWidth
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setQuery(e.target.value);
              getItemsDebounced(e.target.value);
            }}
            autoFocus={true}
          />
          {parentModifierGroupId && (
            <Notification type='info' className={styles.notification}>
              <p>
                <i className={Icon.InfoCircle} />
                <span>
                  Nested modifiers can only be used at a location if its configured POS system supports them. If they
                  are not supported, any nested modifiers within a mod group will not be visible.
                </span>
              </p>
            </Notification>
          )}
        </>
      }
      children={
        <InfiniteScrollingList
          loading={loading}
          error={error}
          paginationLoading={paginationLoading}
          paginationAvailable={paginationAvailable}
          items={items}
          filtered={filtered}
          noItemsTitle='No Results Found'
          noItemsMessage='Make sure the modifier groups you are looking for have been created, or try refining your search.'
          addButtonLabel='Add'
          removeButtonLabel='Remove'
          selectedItemKeys={stateSelectedIds}
          unavailableItemKeys={parentModifierGroupId ? [parentModifierGroupId] : undefined}
          addItem={(item) => {
            dispatch(select(item._id));
            onAdd(item);
          }}
          removeItem={(id) => {
            dispatch(deselect(id));
            onRemove(id);
          }}
          loadItems={loadItems}
          loadMoreItems={loadMore}
          getItemKey={(item: ITemplateModifierGroup) => item._id}
          buildItemComponent={buildItemComponent}
          isInModal
        />
      }
    />
  );
};
