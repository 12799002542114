import produce from 'immer';
import { actionTypes } from './ScheduleListActions';
import { Action } from '../../redux/types';
import { ScheduleListState, initialScheduleListState } from './ScheduleListState';

export const scheduleListReducer = (state: ScheduleListState = initialScheduleListState, action: Action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.SCHEDULE_LIST_INIT:
        draft = initialScheduleListState;
        return draft;

      case actionTypes.SCHEDULE_LIST_LOADING:
        draft.loading = action.payload;
        return draft;

      case actionTypes.SCHEDULE_LIST_SET:
        const { schedules, pagination } = action.payload;
        draft.schedules = schedules;
        draft.pagination = pagination;
        return draft;

      default:
        return state;
    }
  });
