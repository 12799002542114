import React from 'react';
import styles from './OrderDetailPanelContents.module.scss';
import { OrderDetailForm } from '../../../redux/types';
import { ContactInfo, OrderCostSummary } from '.';
import OrderStatusToggle from './OrderStatusToggle';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';
import { DollarValue } from '../../../components/Value';
import { OrderFulfillmentStatus, IClientLocation } from '@ready/dashboardv2api.contracts';
import { format } from 'date-fns';
import { OrderStatusChanging } from '../../../types/OrderStatusChanging.interface';
import { calculateRefundedAmount } from '../../../utils/orderUtils/getOrderRefund';
import { OnAccountPaymentOptions } from './OnAccountPaymentOptions';

interface OrderDetailPanelContentsProps {
  onOrderStatusChange: (orderStatus: OrderFulfillmentStatus) => void;
  orderStatusChanging: OrderStatusChanging;
  orderDetail: OrderDetailForm;
  locationDetails: IClientLocation | null;
}

const OrderDetailPanelContents = (props: OrderDetailPanelContentsProps) => {
  const { onOrderStatusChange, orderStatusChanging, orderDetail, locationDetails } = props;
  const { refundInfo } = orderDetail;

  const amountRefunded = calculateRefundedAmount(refundInfo);

  const orderDetailTip = orderDetail.tip ? orderDetail.tip : 0;

  const hasOnAccountPaymentValidationResponses =
    orderDetail.onAccountPayment && orderDetail.onAccountPayment.validationResponses.length > 0;

  return (
    <>
      <div className={styles.containerSection}>
        <span className={styles.locationName}>
          <TextIcon icon={Icon.Location} additionalStyles={styles.locationIcon} />
          {locationDetails?.name}
        </span>
        <div className={styles.orderInfo}>
          <div>
            <ContactInfo orderIdentityInfo={orderDetail.identityInfo} tableInfo={orderDetail.tableInfo} />
            {hasOnAccountPaymentValidationResponses && (
              <OnAccountPaymentOptions options={orderDetail.onAccountPayment?.validationResponses} />
            )}
          </div>
          <OrderStatusToggle
            value={orderDetail.status}
            onChange={onOrderStatusChange}
            orderStatusChanging={orderStatusChanging}
            additionalStyles={styles.statusInfo}
          />
        </div>
      </div>
      <OrderCostSummary
        totals={orderDetail.totals}
        tip={orderDetailTip}
        appliedDiscounts={orderDetail.appliedDiscounts}
        additionalStyles={styles.orderSummary}
        orderExperienceType={orderDetail.tableInfo?.orderExperienceType}
        hasInvoice={!!orderDetail.invoiceId}
      />
      {orderDetail.invoiceId && amountRefunded ? <RefundRow amount={amountRefunded} /> : null}
      <div className={styles.containerSection}>
        <span className={styles.orderPlaced}>
          {/* the new Date() here is a workaround, since the service
            response.json() deserialization doesn't actually deserialize a string
            that looks like a date into a date object. */}
          {`Submitted ${format(new Date(orderDetail.created), 'dd/MM/yyyy, h:mm a')}`}
        </span>
      </div>
    </>
  );
};

export default OrderDetailPanelContents;

interface RefundRowProps {
  amount: number | undefined;
}

const RefundRow = ({ amount }: RefundRowProps) => {
  if (!amount) {
    return null;
  }

  return (
    <div className={styles.refundRow}>
      <div>
        <TextIcon icon={Icon.ReverseArrow} additionalStyles={styles.refundIcon} />
        <span>Refunded</span>
      </div>
      <DollarValue value={amount} negativeFormat='parentheses' />
    </div>
  );
};
