import React, { forwardRef } from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import Container from '../Container/Container';
import { timeFormat } from '../../utils/formatUtils';

export interface FormattedInputProps {
  value?: string | number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onValueChange?: (values: NumberFormatValues) => void;
  format: 'time' | 'percentage' | 'money' | 'min' | 'max' | 'number';
  showMask?: boolean;
  placeholder?: string;
  decimalScale?: number;
  customPrefix?: string;
  customSuffix?: string;
  withError?: boolean;
  inputRef?: any;
  disabled?: boolean;
  loading?: boolean;
  name?: string;
  is24HourTime?: boolean;
  showTimeSeconds?: boolean;
  wideTimeSpacing?: boolean;
  alignment?: 'left' | 'center' | 'right';
}

export interface FormattedInputConfig {
  format?: (value: any) => string;
  className: string;
  placeholder: string;
  [propName: string]: any;
}

const FormattedInput = forwardRef((props: FormattedInputProps, ref) => {
  const {
    value,
    onChange,
    onValueChange,
    format,
    showMask = false,
    placeholder = '',
    decimalScale = 2,
    customPrefix = '',
    customSuffix = '',
    withError = false,
    inputRef,
    disabled = false,
    loading = false,
    name,
    is24HourTime = true,
    showTimeSeconds = false,
    alignment = 'left',
    wideTimeSpacing = false,
  } = props;

  const mask = showMask ? '_' : ' ';
  const baseClassName = 'formatted-input';
  const errorClassName = withError ? `${baseClassName}--state-error` : '';
  const loadingClassName = loading ? `${baseClassName}--state-loading` : '';
  const disabledClassName = disabled ? `${baseClassName}--disabled` : '';
  const formattedInputClassName = `formatted-input ${errorClassName} ${loadingClassName} ${disabledClassName}`;
  const baseClassNameInput = 'formatted-input__input';

  let formatMethod: (value: any) => string;
  let formatConfig: FormattedInputConfig;
  let prefix = '';
  let suffix = '';

  const labelRef = React.useRef<HTMLLabelElement>(null);
  const handleFocus = () => {
    if (labelRef && labelRef.current) labelRef.current.className = `${baseClassName} ${baseClassName}--focus`;
  };
  const handleBlur = () => {
    if (labelRef && labelRef.current) labelRef.current.className = formattedInputClassName;
  };

  if (format === 'time') {
    formatMethod = (_value) => timeFormat(_value, mask, is24HourTime, showTimeSeconds, wideTimeSpacing);

    const alignmentClass = alignment === 'center' ? ` ${baseClassNameInput}--centered` : '';

    formatConfig = {
      format: formatMethod,
      placeholder:
        placeholder ||
        (showTimeSeconds
          ? `hh${wideTimeSpacing ? ' ' : ''}:${wideTimeSpacing ? ' ' : ''}mm${wideTimeSpacing ? ' ' : ''}:${
              wideTimeSpacing ? ' ' : ''
            }ss`
          : `hh${wideTimeSpacing ? ' ' : ''}:${wideTimeSpacing ? ' ' : ''}mm`),
      className: `${baseClassNameInput} ${baseClassNameInput}--time${alignmentClass}`,
    };
  }
  if (format === 'percentage') {
    suffix = '%';
    formatConfig = {
      placeholder: placeholder ? placeholder : '0',
      className: `${baseClassNameInput} ${baseClassNameInput}--percentage`,
      decimalScale,
    };
  }
  if (format === 'money') {
    prefix = '$';
    formatConfig = {
      placeholder: placeholder ? placeholder : '0.00',
      className: `${baseClassNameInput} ${baseClassNameInput}--money`,
      decimalScale,
      fixedDecimalScale: true,
    };
  }
  if (format === 'min' || format === 'max') {
    suffix = format;
    formatConfig = {
      placeholder: placeholder ? placeholder : '0',
      className: `${baseClassNameInput} ${baseClassNameInput}--min-max`,
      decimalScale,
    };
  }
  if (format === 'number') {
    prefix = customPrefix;
    suffix = customSuffix;
    const prefixClassName = prefix ? `${baseClassNameInput}--number--prefix` : '';
    const suffixClassName = suffix ? `${baseClassNameInput}--number--suffix` : '';
    formatConfig = {
      placeholder: placeholder ? placeholder : '0',
      className: `${baseClassNameInput} ${baseClassNameInput}--number ${prefixClassName} ${suffixClassName}`,
      decimalScale,
    };
  }

  const onChangeValueUpdated = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = typeof value === 'number' || typeof value === 'string' ? value : '';
    const currentValueFormatted = Number.isInteger(currentValue)
      ? currentValue
      : parseFloat(currentValue.toString()).toFixed(2);
    const valueWasUpdated = currentValueFormatted.toString() !== event.target.value;

    if (!!onChange && valueWasUpdated) {
      onChange(event);
    }
  };

  return (
    <Container additionalClassName='formatted-input-container'>
      <label ref={labelRef} className={formattedInputClassName}>
        {prefix !== '' && <span className='formatted-input__prefix'>{prefix}</span>}
        <NumberFormat
          {...formatConfig!}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={value ?? ''}
          onChange={onChangeValueUpdated}
          onValueChange={onValueChange}
          disabled={loading || disabled}
          name={name}
          getInputRef={inputRef || ref}
        />
        {suffix !== '' && <span className='formatted-input__suffix'>{suffix}</span>}
      </label>
    </Container>
  );
});

export default FormattedInput;
