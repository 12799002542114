import { PAYMENT_PROCESSING_ACTIONS } from './paymentProcessingActionTypes';
import {
  GiftCardProvider,
  IClientLocation,
  IGiftCardConfig,
  IPayPalOptions,
  PayPalStatus,
} from '@ready/dashboardv2api.contracts';
import {
  getGiftCardInitConfigStates,
  getGiftCardInitValidationStates,
  noneOption,
} from '../initialStates/paymentProcessing';
import LocationsService from '../../../locations/services/LocationsService';
import { set200Toast, toastErrorState } from '../../../redux/actions/uiActions/responseStateActions';
import { PaymentProcessingService } from '../../../services/paymentProcessingService';
import { updateOptions } from 'companyLocations/pages/paymentProcessing/giftcards/givex/redux/slice';

export const fetchLocation = (companyId: string, locationId: string) => async (dispatch: any) => {
  try {
    const location = await LocationsService.getClientLocationById(companyId, locationId);
    dispatch(updateLocation(location));
  } catch (error) {
    // @ts-ignore
    dispatch(toastErrorState(error.status, error.message));
  }
};

// PayPal
export const initPayPalInfo = (companyId: string, locationId: string) => async (dispatch: any) => {
  dispatch({
    type: PAYMENT_PROCESSING_ACTIONS.INIT_PAYPAL_INFO_BEGIN,
  });

  try {
    let paypalLink;
    const { options } = await PaymentProcessingService.getPayPalConfig(companyId, locationId);

    if (options.status === PayPalStatus.Disconnected) {
      const redirectUrl = window.location.href;
      const { href } = await PaymentProcessingService.getPayPalLink(companyId, locationId, redirectUrl);
      paypalLink = href;
    }

    dispatch({
      type: PAYMENT_PROCESSING_ACTIONS.INIT_PAYPAL_INFO_SUCCESS,
      payload: {
        options,
        link: paypalLink,
      },
    });
  } catch (error) {
    // @ts-ignore
    dispatch(toastErrorState(error.status, error.message));
    dispatch({
      type: PAYMENT_PROCESSING_ACTIONS.INIT_PAYPAL_INFO_ERROR,
    });
  }
};

export const initPayPalInfoAfterPayPalSignUp =
  (companyId: string, locationId: string, paypalConfig: IPayPalOptions) => async (dispatch: any) => {
    dispatch({
      type: PAYMENT_PROCESSING_ACTIONS.INIT_PAYPAL_INFO_BEGIN,
    });

    try {
      const { options } = await PaymentProcessingService.updatePayPalConfig(companyId, locationId, paypalConfig);

      dispatch({
        type: PAYMENT_PROCESSING_ACTIONS.INIT_PAYPAL_INFO_SUCCESS,
        payload: {
          options,
        },
      });
    } catch (error) {
      // @ts-ignore
      dispatch(toastErrorState(error.status, error.message));
      dispatch({
        type: PAYMENT_PROCESSING_ACTIONS.INIT_PAYPAL_INFO_ERROR,
      });
    }
  };
export const resetPayPalConfig = () => ({
  type: PAYMENT_PROCESSING_ACTIONS.RESET_PAYPAL_CONFIG,
});

export const updateLocation = (location: IClientLocation) => ({
  type: PAYMENT_PROCESSING_ACTIONS.UPDATE_LOCATION,
  payload: location,
});

export const unlinkPayPalModal = (modalVisible: boolean) => ({
  type: PAYMENT_PROCESSING_ACTIONS.UNLINK_PAYPAL_MODAL,
  payload: modalVisible,
});

export const unlinkPayPal = (companyId: string, locationId: string) => async (dispatch: any) => {
  dispatch({
    type: PAYMENT_PROCESSING_ACTIONS.UNLINK_PAYPAL_BEGIN,
  });

  const redirectUrl = window.location.href;
  const { options } = await PaymentProcessingService.unlinkPayPalAccount(companyId, locationId);
  const { href } = await PaymentProcessingService.getPayPalLink(companyId, locationId, redirectUrl);

  try {
    dispatch({
      type: PAYMENT_PROCESSING_ACTIONS.UNLINK_PAYPAL_SUCCESS,
      payload: { paypalOptions: options, link: href },
    });
  } catch (error) {
    // @ts-ignore
    dispatch(toastErrorState(error.status, error.message));
  }
};

export const updatePayPalConfig =
  (companyId: string, locationId: string, config: IPayPalOptions) => async (dispatch: any) => {
    dispatch({
      type: PAYMENT_PROCESSING_ACTIONS.UPDATE_PAYPAL_CONFIG_BEGIN,
    });

    try {
      const updatedLocationPaymentConfig = await PaymentProcessingService.updatePayPalConfig(
        companyId,
        locationId,
        config
      );

      dispatch({
        type: PAYMENT_PROCESSING_ACTIONS.UPDATE_PAYPAL_CONFIG_SUCCESS,
        payload: updatedLocationPaymentConfig.options,
      });
    } catch (error) {
      // @ts-ignore
      dispatch(toastErrorState(error.status, error.message));
      dispatch({
        type: PAYMENT_PROCESSING_ACTIONS.UPDATE_PAYPAL_CONFIG_ERROR,
      });
    }
  };

// Gift Cards

export const getGiftCardTenderTypes = (companyId: string, locationId: string) => async (dispatch: any) => {
  dispatch({
    type: PAYMENT_PROCESSING_ACTIONS.GET_GIFT_CARD_TENDER_TYPES_BEGIN,
  });
  try {
    const tenderTypes = await PaymentProcessingService.getTenderTypes(companyId, locationId);

    dispatch({
      type: PAYMENT_PROCESSING_ACTIONS.GET_GIFT_CARD_TENDER_TYPES_SUCCESS,
      payload: tenderTypes,
    });
  } catch (error) {
    // @ts-ignore
    dispatch(toastErrorState(error.status, error.message));
    dispatch({
      type: PAYMENT_PROCESSING_ACTIONS.GET_GIFT_CARD_TENDER_TYPES_ERROR,
    });
  }
};

export const initGiftCardConfig =
  (companyId: string, locationId: string, provider?: GiftCardProvider) => async (dispatch: any) => {
    dispatch({
      type: PAYMENT_PROCESSING_ACTIONS.INIT_GIFT_CARD_CONFIG_BEGIN,
    });

    try {
      const giftCardConfig = await PaymentProcessingService.getGiftCardConfig(companyId, locationId, provider);

      if (giftCardConfig) {
        dispatch({
          type: PAYMENT_PROCESSING_ACTIONS.INIT_GIFT_CARD_CONFIG_SUCCESS,
          payload: {
            giftCardConfig,
            validation: getGiftCardInitValidationStates(giftCardConfig.provider),
          },
        });

        if (giftCardConfig.provider === 'givex') {
          dispatch(updateOptions(giftCardConfig));
        }
      }
    } catch (error) {
      // @ts-ignore
      dispatch(toastErrorState(error.status, error.message));
      dispatch({
        type: PAYMENT_PROCESSING_ACTIONS.INIT_GIFT_CARD_CONFIG_ERROR,
      });
    }
  };

export const toggleGiftCardConfig =
  (companyId: string, locationId: string, enabled: boolean) => async (dispatch: any) => {
    dispatch({
      type: PAYMENT_PROCESSING_ACTIONS.TOGGLE_GIFT_CARD_CONFIG_BEGIN,
    });
    try {
      const location = await LocationsService.toggleGiftCardsEnabled(companyId, locationId, enabled);
      dispatch({
        type: PAYMENT_PROCESSING_ACTIONS.TOGGLE_GIFT_CARD_CONFIG_SUCCESS,
        payload: location,
      });
    } catch (error) {
      // @ts-ignore
      dispatch(toastErrorState(error.status, error.message));
      dispatch({
        type: PAYMENT_PROCESSING_ACTIONS.TOGGLE_GIFT_CARD_CONFIG_ERROR,
      });
    }
  };

export const saveGiftCardConfig =
  (
    companyId: string,
    locationId: string,
    username: string,
    provider: GiftCardProvider,
    updatedConfig: IGiftCardConfig
  ) =>
  async (dispatch: any) => {
    dispatch({
      type: PAYMENT_PROCESSING_ACTIONS.SAVE_GIFT_CARD_CONFIG_BEGIN,
    });
    try {
      const setGiftCardConfigresult = await PaymentProcessingService.setGiftCardConfig(
        companyId,
        locationId,
        updatedConfig
      );

      const location = await LocationsService.toggleGiftCardsEnabled(companyId, locationId, true);

      dispatch({
        type: PAYMENT_PROCESSING_ACTIONS.SAVE_GIFT_CARD_CONFIG_SUCCESS,
        payload: {
          view: setGiftCardConfigresult,
          location: location,
        },
      });
      dispatch(updateGiftCardFormValidation(false));
      dispatch(set200Toast());
    } catch (error) {
      // @ts-ignore
      dispatch(toastErrorState(error.status, error.message));
      dispatch({
        type: PAYMENT_PROCESSING_ACTIONS.SAVE_GIFT_CARD_CONFIG_ERROR,
      });
    }
  };

export const changeGiftCardProvider =
  (companyId: string, locationId: string, provider: GiftCardProvider) => async (dispatch: any) => {
    dispatch({
      type: PAYMENT_PROCESSING_ACTIONS.CHANGE_GIFT_CARD_CONFIG_BEGIN,
    });

    try {
      const giftCardConfig =
        provider !== noneOption.value
          ? await PaymentProcessingService.getGiftCardConfig(companyId, locationId, provider)
          : undefined;

      dispatch({
        type: PAYMENT_PROCESSING_ACTIONS.CHANGE_GIFT_CARD_CONFIG_SUCCESS,
        payload: {
          giftCardConfig: giftCardConfig?.provider ? giftCardConfig : getGiftCardInitConfigStates(provider),
          validation: getGiftCardInitValidationStates(provider),
        },
      });

      if (giftCardConfig && giftCardConfig.provider === GiftCardProvider.Givex) {
        dispatch(updateOptions(giftCardConfig));
      }
    } catch (error) {
      // @ts-ignore
      dispatch(toastErrorState(error.status, error.message));
      dispatch({
        type: PAYMENT_PROCESSING_ACTIONS.CHANGE_GIFT_CARD_CONFIG_ERROR,
      });
    }
  };

export const updatePosIntegration = (posIntegration: string) => ({
  type: PAYMENT_PROCESSING_ACTIONS.SET_POS_INTEGRATION,
  payload: posIntegration,
});

export const toggleGiftCardEditForm = (editable: boolean) => ({
  type: PAYMENT_PROCESSING_ACTIONS.TOGGLE_GIFT_CARD_EDIT_FORM,
  payload: editable,
});

export const setGiftCardPanelEditingMode = (editable: boolean) => ({
  type: PAYMENT_PROCESSING_ACTIONS.UPDATE_GIFT_CARD_PANEL_EDIT_STATE,
  payload: editable,
});

export const resetGiftCardConfig = () => ({
  type: PAYMENT_PROCESSING_ACTIONS.RESET_GIFT_CARD_CONFIG,
});

export const updateGiftCardFormValidation = (validated: boolean) => ({
  type: PAYMENT_PROCESSING_ACTIONS.UPDATE_GIFT_CARD_FORM_VALIDATION,
  payload: validated,
});

export const updateGiftCardProvider = (value: GiftCardProvider) => ({
  type: PAYMENT_PROCESSING_ACTIONS.UPDATE_GIFT_CARD_PROVIDER,
  payload: value,
});
