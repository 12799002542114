import { ITemplateEmbeddedMenuItem } from '@ready/menu.core';
import TextIcon, { Icon } from 'components/Icon/TextIcon';
import IconButton from 'components/IconButton/IconButton';
import InfoCard from 'components/InfoCard/InfoCard';
import InfoCardColumn from 'components/InfoCard/InfoCardColumn';
import { InfoCardColumnsWrapper } from 'components/InfoCardColumnsWrapper/InfoCardColumnsWrapper';
import { useAssetUrl } from 'hooks/useAssetUrl';
import MenuItemIconsBlock from 'menus/components/ItemsAndMods/MenuItemIconsBlock';
import MenuItemImage from 'menus/components/ItemsAndMods/MenuItemImage';
import MenuItemNameBlock from 'menus/components/ItemsAndMods/MenuItemNameBlock';
import React from 'react';
import styles from './RecommendedItemCard.module.scss';

interface RecommendedItemCardProps {
  item: ITemplateEmbeddedMenuItem;
  removeItem: () => void;
  linkTo?: string;
  imageUrl?: string;
  additionalClassName?: string;
}

export const RecommendedItemCard = (props: RecommendedItemCardProps) => {
  const {
    item: { itemType, displayName, description, alcohol, imageId },
    linkTo,
    additionalClassName,
    removeItem,
  } = props;

  const imageUrl = useAssetUrl(imageId);
  const containsAlcohol = alcohol && alcohol.containsAlcohol;
  const alcoholAmount = alcohol && containsAlcohol && alcohol.quantity ? alcohol.quantity.value : undefined;

  return (
    <InfoCard additionalStyles={additionalClassName}>
      <InfoCardColumnsWrapper linkTo={linkTo}>
        <InfoCardColumn width={100} columnClassName={styles.details}>
          <div className={styles.imageAndDetails}>
            <div className={styles.image}>
              <MenuItemImage type={itemType} name={displayName} url={imageUrl} />
            </div>

            <MenuItemNameBlock displayName={displayName} description={description} className={styles.name} />
          </div>
        </InfoCardColumn>
        <div className={styles.attributes}>
          {/* Todo: uncomment once assigned locations is added to the response */}
          {/* <AssignedLocationsContent numberOfLocations={100} additionalStyles={styles.assignedLocations} /> */}
          <MenuItemIconsBlock
            containsAlcohol={containsAlcohol}
            alcoholAmount={alcoholAmount}
            className={styles.icons}
          />
        </div>
      </InfoCardColumnsWrapper>

      <IconButton onClick={removeItem}>
        <TextIcon icon={Icon.RemoveCircle} additionalStyles={styles.button} />
      </IconButton>
    </InfoCard>
  );
};
