import { initialPromoCodeSummaryState } from './initialState';
import { createSlice } from '@reduxjs/toolkit';
import { exportPromoCodeSummaryThunk, fetchPromoCodeSummaryThunk, fetchPermittedLocationsThunk } from './thunks';
import {
  fetchPromoCodeSummaryReducers,
  fetchPermittedLocationsReducers,
  exportPromoCodeSummaryReducers,
} from './reducers';

const promoCodeSummaryReducerSlice = createSlice({
  name: 'promoCodeSummary',
  initialState: initialPromoCodeSummaryState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPromoCodeSummaryThunk.pending, fetchPromoCodeSummaryReducers.pending);
    builder.addCase(fetchPromoCodeSummaryThunk.fulfilled, fetchPromoCodeSummaryReducers.fulfilled);
    builder.addCase(fetchPromoCodeSummaryThunk.rejected, fetchPromoCodeSummaryReducers.rejected);

    builder.addCase(exportPromoCodeSummaryThunk.pending, exportPromoCodeSummaryReducers.pending);
    builder.addCase(exportPromoCodeSummaryThunk.fulfilled, exportPromoCodeSummaryReducers.fulfilled);
    builder.addCase(exportPromoCodeSummaryThunk.rejected, exportPromoCodeSummaryReducers.rejected);

    builder.addCase(fetchPermittedLocationsThunk.pending, fetchPermittedLocationsReducers.pending);
    builder.addCase(fetchPermittedLocationsThunk.fulfilled, fetchPermittedLocationsReducers.fulfilled);
    builder.addCase(fetchPermittedLocationsThunk.rejected, fetchPermittedLocationsReducers.rejected);
  },
});

const { actions: promoCodeSummaryActions, reducer: promoCodeSummaryReducer } = promoCodeSummaryReducerSlice;
export { promoCodeSummaryActions, promoCodeSummaryReducer };
