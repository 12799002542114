import React from 'react';
import TextIcon, { Icon } from 'components/Icon/TextIcon';
import IconButton from 'components/IconButton/IconButton';
import InfoCard from 'components/InfoCard/InfoCard';
import styles from './ConfigurationCard.module.scss';
import { useResponsiveBreakpoint } from 'hooks';
import OverflowButton from 'components/OverflowButton/OverflowButton';
import NoneValue from 'components/Value/NoneValue';

interface Props {
  configId: string;
  name: string;
  companyNumber: number;
  serverPublicKey?: string;
  clientPublicKey?: string;
  onEdit: (configId: string) => void;
  onDelete: (configId: string) => void;
}

export const ConfigurationCard = ({
  configId,
  name,
  companyNumber,
  serverPublicKey,
  clientPublicKey,
  onEdit,
  onDelete,
}: Props) => {
  const { isMobile } = useResponsiveBreakpoint();

  const overflowButtonOptions = [
    {
      label: 'Edit',
      onClick: () => onEdit(configId),
    },
    {
      label: 'Delete',
      onClick: () => onDelete(configId),
    },
  ];

  return (
    <div className={styles.infoCardContainer}>
      <InfoCard additionalStyles={styles.infoCard}>
        <div className={styles.settingsContainer}>
          <div className={styles.configName}>{name}</div>
          <div className={styles.configDetails}>
            <div>
              <span className={styles.configHeader}>Company Number:</span>
              <span className={styles.configData}> {companyNumber}</span>
            </div>
            <div>
              <span className={styles.configHeader}>Client Public Key:</span>
              <span className={styles.configData}> {clientPublicKey || <NoneValue />}</span>
            </div>
            <div>
              <span className={styles.configHeader}>Server Public Key:</span>
              <span className={styles.configData}> {serverPublicKey || <NoneValue />}</span>
            </div>
          </div>
        </div>
        {isMobile ? (
          <OverflowButton options={overflowButtonOptions} />
        ) : (
          <div className={styles.iconsContainer}>
            <IconButton
              onClick={() => {
                onEdit(configId);
              }}
            >
              <TextIcon icon={Icon.Pencil} />
            </IconButton>
            <IconButton
              onClick={() => {
                onDelete(configId);
              }}
            >
              <TextIcon icon={Icon.TrashCan} />
            </IconButton>
          </div>
        )}
      </InfoCard>
    </div>
  );
};
