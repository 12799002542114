import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { updateQueryParams } from 'utils/updatePath';

export interface ILocationFilterParams {
  id: string;
  name: string;
}

export const useLocationFilter = (basePath: string, locationFilter?: ILocationFilterParams) => {
  const { push } = useHistory();

  useEffect(() => {
    updateQueryParams(
      basePath,
      { locationName: locationFilter?.name ?? '', locationId: locationFilter?.id ?? '', page: '' },
      push
    );
  }, [basePath, locationFilter, push]);
};
