import { IPagination } from '@ready/dashboardv2api.contracts';
import React from 'react';
import CompanyTableFooter from './CompanyTableFooter';
import styles from './CompanyTablePage.module.scss';
import CompanyTablePagination from './CompanyTablePagination';

export interface CompanyTableProps {
  children: JSX.Element[] | JSX.Element;
  withOrderType: boolean;
  paginationProps?: IPagination;
}

const CompanyTable = (props: CompanyTableProps) => {
  const { children, withOrderType, paginationProps } = props;

  return (
    <div className={styles.table}>
      {children}

      {paginationProps && (
        <CompanyTableFooter>
          <CompanyTablePagination withOrderType={withOrderType} {...paginationProps} />
        </CompanyTableFooter>
      )}
    </div>
  );
};

export default CompanyTable;
