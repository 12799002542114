import TextIcon, { Icon } from 'components/Icon/TextIcon';
import StatusIndicator from 'components/StatusIndicator/StatusIndicator';
import React from 'react';
import { useResponsiveBreakpoint } from 'hooks';
import { StockStatus } from 'menus/components/ItemsAndMods/MenuItemDetails/components/StockStatus';
import { ScheduleEffect, Status } from '@ready/menu.core';
import { IBulkEditMenuItemTemp } from './AssignedLocationsTable';
import { format } from 'date-fns';
import { DollarValue } from 'components/Value';
import { CellProps, getCellClasses } from 'components/TableV2/Cell/Cell';

import styles from './Cells.module.scss';
import OverflowButton from 'components/OverflowButton/OverflowButton';
import {
  setEditLocationSettingsModalVisible,
  setRowData,
  setUnassignLocationModalVisible,
  toggleAllOnPage,
  toggleCellCheck,
} from 'sharedMenuItems/redux/table/tableSlice';
import { useAppDispatch, useAppSelector } from 'redux/store';
import IconButton from 'components/IconButton/IconButton';
import Checkbox from 'components/Checkbox/Checkbox';
import { selectTableState } from 'sharedMenuItems/redux/selectors';

export interface MobileToolbarHeaderProps {
  onEdit: () => void;
  onUnassign: () => void;
}

export const MobileToolbarHeader = ({
  rowData,
  classes,
  onEdit,
  onUnassign,
}: CellProps<IBulkEditMenuItemTemp, MobileToolbarHeaderProps>) => {
  const { isMobile } = useResponsiveBreakpoint();
  const dispatch = useAppDispatch();
  return (
    <>
      {isMobile && (
        <div className={getCellClasses(classes, styles.mobileToolbarHeader)}>
          <IconButton
            additionalClassName={styles.editButton}
            onClick={() => {
              dispatch(setRowData(rowData));
              onEdit ? onEdit() : dispatch(setEditLocationSettingsModalVisible(true));
            }}
          >
            <TextIcon icon={Icon.Pencil} additionalStyles={styles.edit} />
          </IconButton>
          <OverflowButton
            className={styles.overflowButton}
            options={[
              {
                label: 'Unassign from this Location',
                onClick: () => {
                  dispatch(setRowData(rowData));
                  onUnassign ? onUnassign() : dispatch(setUnassignLocationModalVisible(true));
                },
                primary: false,
              },
            ]}
          />
        </div>
      )}
    </>
  );
};

export const CheckboxCell = ({ rowData, classes }: CellProps<IBulkEditMenuItemTemp>) => {
  const { locationId } = rowData;
  const { checkCells } = useAppSelector(selectTableState);
  const dispatch = useAppDispatch();
  const { isMobile } = useResponsiveBreakpoint();
  //if a check has a location, then display the location state (or false if it is not in the list).
  //if the check is from the header row (does not have a locationId), and the table has entries
  //then set to checked if all checks are checked
  const isChecked = locationId
    ? checkCells[locationId] || false
    : Object.keys(checkCells).length > 0
    ? Object.values(checkCells).every((cell) => cell)
    : false;
  return (
    <>
      {!isMobile && (
        <div className={getCellClasses(classes, styles.checkboxCell)}>
          <Checkbox
            label=''
            checked={isChecked}
            disabled={Object.keys(checkCells).length < 1}
            onChange={() =>
              dispatch(
                locationId
                  ? toggleCellCheck({ locationId, checked: !checkCells[locationId] })
                  : toggleAllOnPage({ check: Object.values(checkCells).includes(false) || false })
              )
            }
          />
        </div>
      )}
    </>
  );
};

export interface LocationNameCellProps {
  onEdit: () => void;
}

export const LocationNameCell = ({
  rowData,
  classes,
  onEdit,
}: CellProps<IBulkEditMenuItemTemp, LocationNameCellProps>) => {
  const { locationName } = rowData;
  const { isMobile } = useResponsiveBreakpoint();
  const dispatch = useAppDispatch();

  return (
    <div className={getCellClasses(classes, styles.locationNameCell)}>
      <span>{locationName}</span>
      {!isMobile && (
        <IconButton
          onClick={() => {
            dispatch(setRowData(rowData));
            // TODO: Remove 'setEditLocationSettingsModalVisible' once the table slice is no longer needed
            onEdit ? onEdit() : dispatch(setEditLocationSettingsModalVisible(true));
          }}
        >
          <TextIcon icon={Icon.Pencil} additionalStyles={styles.edit} />
        </IconButton>
      )}
    </div>
  );
};

export const ScheduleLinkCell = ({ rowData, classes }: CellProps<IBulkEditMenuItemTemp>) => {
  const data = rowData.schedule;

  if (!data) {
    return (
      <div className={getCellClasses(classes)}>
        <NoneCell />
      </div>
    );
  }

  const effectString = data.effect === ScheduleEffect.show ? 'Show' : 'Hide';

  return (
    <div className={getCellClasses(classes, styles.scheduleLinkCell)}>
      <span className={styles.effect}>{effectString} During</span> {data.links.map((x) => x.name).join(', ')}
    </div>
  );
};

export const POSItemCell = ({ rowData, classes }: CellProps<IBulkEditMenuItemTemp>) => {
  const { posItemName, posItemId } = rowData;

  if (!posItemId || !posItemName) {
    return <NoneCell />;
  }

  return (
    <div className={getCellClasses(classes, styles.priceLevelCell)}>
      <span>{posItemName}</span>
      <span className={styles.priceLevel}>&nbsp;({posItemId})</span>
    </div>
  );
};

export const PriceLevelCell = ({ rowData, classes }: CellProps<IBulkEditMenuItemTemp>) => {
  const { price, activePriceLevel } = rowData;

  if (!price) {
    return (
      <div className={getCellClasses(classes)}>
        <span className={styles.none}>—</span>
      </div>
    );
  }

  return (
    <div className={getCellClasses(classes, styles.priceLevelCell)}>
      <DollarValue value={price} />
      <span hidden={!activePriceLevel} className={styles.priceLevel}>
        &nbsp;({activePriceLevel})
      </span>
    </div>
  );
};

export const EnabledStatusCell = ({ rowData, classes }: CellProps<{ status: Status }>) => {
  // todo: styling - line up better?
  const status = rowData.status;

  return (
    <div className={getCellClasses(classes, styles.statusCell)}>
      <StatusIndicator
        active={status === Status.enabled}
        activeLabel='Enabled'
        inactiveLabel='Disabled'
      ></StatusIndicator>
    </div>
  );
};

const dateFormat = 'MMM d, yyyy h:mm a';
export const EffectiveDatesCell = ({ rowData, classes }: CellProps<IBulkEditMenuItemTemp>) => {
  const { effectiveDates } = rowData;

  if (!effectiveDates) {
    return <NoneCell text='Now' />;
  }

  const { start, end } = effectiveDates;
  const startDateString = start ? format(new Date(start), dateFormat) : 'Now';
  const endDateString = end ? format(new Date(end), dateFormat) : 'Forever';
  const effectiveDatesString = `${startDateString} — ${endDateString}`;

  return <div className={getCellClasses(classes, styles.effectiveDatesCell)}>{effectiveDatesString}</div>;
};

export const InStockCell = ({ rowData, classes }: CellProps<IBulkEditMenuItemTemp>) => {
  // todo: possibly clean up the stock status component, add optional props etc.
  const { inStock } = rowData;

  return (
    <div className={getCellClasses(classes)}>
      <StockStatus
        inStock={inStock}
        isNotApplicable={false}
        readOnlyStock={true}
        processing={false}
        updateMenuItemInStock={function (inStock: boolean): void {
          throw new Error('Function not implemented.');
        }}
      />
    </div>
  );
};

export const PopularCell = ({ rowData, classes }: CellProps<IBulkEditMenuItemTemp>) => {
  const { isPopular } = rowData;

  return <div className={getCellClasses(classes)}>{isPopular ? 'Yes' : 'No'}</div>;
};

export const NoneCell = ({ classes, text = 'None' }: { classes?: string; text?: string }) => (
  <div className={getCellClasses(classes, styles.priceLevelCell)}>
    <span className={styles.none}>{text}</span>
  </div>
);

export interface UnassignCellProps {
  onUnassign: () => void;
}

export const UnassignCell = ({ rowData, onUnassign }: CellProps<IBulkEditMenuItemTemp, UnassignCellProps>) => {
  const dispatch = useAppDispatch();
  const { isMobile } = useResponsiveBreakpoint();
  return (
    <>
      {!isMobile && (
        <OverflowButton
          options={[
            {
              label: 'Unassign from this Location',
              onClick: () => {
                dispatch(setRowData(rowData));
                // TODO: Remove 'setUnassignLocationModalVisible' once the table slice is no longer needed
                onUnassign ? onUnassign() : dispatch(setUnassignLocationModalVisible(true));
              },
              primary: false,
            },
          ]}
        />
      )}
    </>
  );
};
