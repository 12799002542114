import React from 'react';

export interface UnderLayerProps {
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const UnderLayer = (props: UnderLayerProps) => {
  const { onClick } = props;

  return <div className='underlayer' onClick={onClick}></div>;
};

export default UnderLayer;
