import { ITemplateMenuItemGroup, ITemplateMenuItemGroupRequest } from '@ready/menu.core';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { set200Toast, toastErrorState } from 'redux/actions/uiActions/responseStateActions';
import { mapITemplateMenuItemGroupToITemplateMenuItemRequest } from 'sharedMenuItems/mappers/mappers';
import { createItemGroup, deleteItemGroup, getItemGroup, updateItemGroup } from '../../itemGroupService';

interface ISharedItemGroupArgs {
  companyId: string;
  id: string;
  itemGroup: ITemplateMenuItemGroup;
  displayName?: string;
}
const getSharedItemGroupThunk = createAsyncThunk(
  'sharedMenuItems/itemGroups/details/fetch',
  async (args: Omit<ISharedItemGroupArgs, 'itemGroup'>, thunkAPI) => {
    return getItemGroup(args.companyId, args.id);
  }
);

const createSharedItemGroupThunk = createAsyncThunk(
  'sharedMenuItems/itemGroups/details/create',
  async (args: Omit<ISharedItemGroupArgs, 'id'>, thunkAPI) => {
    try {
      const result = await createItemGroup(args.companyId, args.itemGroup);
      thunkAPI.dispatch(set200Toast('Success! Item group created.'));
      return result;
    } catch (error) {
      thunkAPI.dispatch(toastErrorState(error));
    }
  }
);

const updateSharedItemGroupThunk = createAsyncThunk(
  'sharedMenuItems/itemGroups/details/update',
  async (args: Omit<ISharedItemGroupArgs, 'id'>, thunkAPI) => {
    try {
      const itemToUpdateRequest: ITemplateMenuItemGroupRequest = mapITemplateMenuItemGroupToITemplateMenuItemRequest(
        args.itemGroup
      );

      const result = await updateItemGroup(args.companyId, itemToUpdateRequest);
      thunkAPI.dispatch(set200Toast('Success! Changes saved.'));
      return result;
    } catch (error) {
      thunkAPI.dispatch(toastErrorState(error));
    }
  }
);

const duplicateSharedItemGroupThunk = createAsyncThunk(
  'sharedMenuItems/itemGroups/details/duplicate',
  async (args: Omit<ISharedItemGroupArgs, 'id'>, thunkAPI) => {
    const itemGroupToDuplicate: ITemplateMenuItemGroup = {
      ...args.itemGroup,
      displayName: args.displayName ?? '',
    };
    try {
      const result = await createItemGroup(args.companyId, itemGroupToDuplicate);
      thunkAPI.dispatch(set200Toast('Success! Item group created.'));
      return result;
    } catch (error) {
      thunkAPI.dispatch(toastErrorState(error));
    }
  }
);

const deleteSharedItemGroupThunk = createAsyncThunk(
  'sharedMenuItems/itemGroups/details/delete',
  async (args: Omit<ISharedItemGroupArgs, 'itemGroup'>, thunkAPI) => {
    try {
      await deleteItemGroup(args.companyId, args.id);
      thunkAPI.dispatch(set200Toast(`Item group deleted.`));
    } catch (error) {
      thunkAPI.dispatch(toastErrorState(error.status, error.message));
    }
  }
);
export {
  getSharedItemGroupThunk,
  createSharedItemGroupThunk,
  deleteSharedItemGroupThunk,
  duplicateSharedItemGroupThunk,
  updateSharedItemGroupThunk,
};
