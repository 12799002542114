import React from 'react';
import styles from './RoundIcon.module.scss';
import TextIcon, { Icon } from '../Icon/TextIcon';
import classNames from 'classnames';

interface RoundIconProps {
  icon: string | Icon;
  additionalStyles?: string;
}

const RoundIcon = ({ icon, additionalStyles = '' }: RoundIconProps): JSX.Element => (
  <TextIcon icon={icon} additionalStyles={classNames(styles.icon, additionalStyles)} />
);

export default RoundIcon;
