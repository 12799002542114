import { IChecksReportListItem, IChecksReportWidgetsData, IPagination } from '@ready/dashboardv2api.contracts';
import { IPagedResponse } from '@ready/table.core';
import { ILocation } from '../../../../services/types/ILocation.type';

export interface IChecksReportState {
  rowKey: string;
  columns: Array<string>;
  headerColumns: Record<string, string>;
  items: Array<IChecksReportListItem>;
  pagination: IPagination;
  widgets: IChecksReportWidgetsData;
  loading: {
    list?: boolean;
    filters?: boolean;
    permittedLocations?: boolean;
    export?: boolean;
    widgets?: boolean;
  };
  errors: {
    list?: string;
    filters?: string;
    permittedLocations?: string;
    widgets?: string;
  };
  permittedLocations: IPagedResponse<ILocation>;
}

export const initialChecksReportState: IChecksReportState = {
  rowKey: '_id',
  items: [],
  permittedLocations: {
    totalCount: 0,
    results: [],
  },
  columns: [
    'posTicketNumber',
    'tableName',
    'serverName',
    'checkTotal',
    'tip',
    'totalPaid',
    'due',
    'status',
    'readyPayment',
    'checkDuration',
    'locationName',
    'openDate',
  ],
  headerColumns: {
    posTicketNumber: 'Check #',
    tableName: 'Table',
    serverName: 'Server',
    checkTotal: 'Check Total',
    tip: 'Tip',
    totalPaid: 'Total Paid',
    due: 'Due',
    status: 'Status',
    readyPayment: 'Ready Payment',
    checkDuration: 'Check Duration',
    locationName: 'Location',
    openDate: 'Created Date',
  },
  widgets: {
    countAll: 0,
    countReady: 0,
    countReadyPct: null,
    countNonReady: 0,
    countNonReadyPct: null,

    amountAllSum: 0,
    amountReadySum: 0,
    amountReadyPct: null,
    amountNonReadySum: 0,
    amountNonReadyPct: null,

    tipAllSum: 0,
    tipReadySum: 0,
    tipReadyAvgPct: null,
    tipNonReadySum: 0,
    tipNonReadyAvgPct: null,

    amountAllAvg: null,
    amountReadyAvg: null,
    amountNonReadyAvg: null,
  },
  pagination: {
    start: 0,
    length: 0,
    limit: 0,
    total: 0,
  },
  loading: {},
  errors: {},
};
