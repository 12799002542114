import ActionHeader from 'components/ActionHeader/ActionHeader';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { TableBodyProps } from 'components/TableV2/Body/Body';
import { CellProps, getWrappedCellsWithMobileLayout } from 'components/TableV2/Cell/Cell';
import { HeaderCell, HeaderCellConstants } from 'components/TableV2/Cell/HeaderCell';
import { TableHeaderProps } from 'components/TableV2/TableHeader/TableHeader';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector, RootState, AppDispatch } from 'redux/store';
import { AssignedLocationsTable } from 'sharedMenuItems/components/assignedLocationsTable/AssignedLocationsTable';
import {
  CheckboxCell,
  EnabledStatusCell,
  ScheduleLinkCell,
} from 'sharedMenuItems/pages/itemAssignedLocations/components/table/Cells';
import { SharedMenuItemsRoutes } from 'sharedMenuItems/Router';
import { SharedMenuItemsLayout } from 'sharedMenuItems/SharedMenuItemsLayout';
import { SharedMenuItemsTabId } from 'sharedMenuItems/SharedMenuItemsTabs';
import { ContextParams } from 'types/ContextParams.interface';
import { getSharedMenuThunk } from '../../menu/redux/thunks';
import { EditLocationSettingsModal, IEditLocationSettingsModalFields } from '../components/EditLocationSettingsModal';
import { LocationEditCell } from '../components/LocationNameCell';
import styles from '../locationSettings.module.scss';
import { ToolbarContent } from 'sharedMenuItems/pages/itemGroups/assignedLocations/components/ToolbarContent';
import { useSearchParams } from 'hooks/useSearchParams';
import { decodeUriString } from 'utils/urlUtils/decodeUriString';
import { updateQueryParams } from 'utils/updatePath';
import { getLocationMenuSectionsThunk, saveLocationMenuSectionsThunk } from './redux/thunks';

import { IBulkEditMenuSection } from '@ready/menu.core';
import { selectTableState } from 'sharedMenuItems/redux/selectors';
import { initCellCheckedState } from 'sharedMenuItems/redux/table/tableSlice';
import { BulkEditToolbarContent } from 'sharedMenuItems/components/bulkEditToolbarContent/BulkEditToolbarContent';

export const SectionLocationSettingsPage = () => {
  const {
    contextId: companyId,
    menuConfigId,
    menuId,
    sectionId: id,
  } = useParams<ContextParams & { menuConfigId: string; menuId: string; sectionId: string }>();

  const dispatch: AppDispatch = useAppDispatch();
  const { push } = useHistory();
  const { searchTerm, page } = useSearchParams();
  const decodedSearchTerm = decodeUriString(searchTerm ?? '');
  const { menu, loading: menuLoading } = useAppSelector((state: RootState) => state.menuBuilder.menus.menuForm);
  const { items, loading, pagination } = useAppSelector(
    (state: RootState) => state.sharedMenuItems.locationSettings.section
  );
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [currentLocationSettings, setCurrentLocationSettings] = useState<IBulkEditMenuSection>();

  const onSearchTermChanged = (searchTerm: string) => {
    updateQueryParams(
      SharedMenuItemsRoutes.getMenuSectionLocationSettingsRoute(companyId, menuConfigId, menuId, id),
      { searchTerm: searchTerm, page: '' },
      push
    );
  };

  const saveLocationSettings = async (updates: IEditLocationSettingsModalFields, isBulkEdit: boolean = false) => {
    let menuSections: { _id: string; locationId: string }[] = [];
    if (isBulkEdit) {
      menuSections = checkedLocationIds.reduce<{ _id: string; locationId: string }[]>((accumulator, locationId) => {
        const menu = items.find((menu) => menu.locationId === locationId);

        if (menu) {
          accumulator.push({ _id: menu.menuId, locationId });
        }

        return accumulator;
      }, []);
    } else {
      if (currentLocationSettings) {
        menuSections = [{ _id: currentLocationSettings.menuId, locationId: currentLocationSettings.locationId }];
      }
    }

    const request = {
      menus: menuSections,
      sectionUpdates: updates,
    };

    await dispatch(saveLocationMenuSectionsThunk({ companyId, menuId, sectionId: id, request }));
    dispatch(getLocationMenuSectionsThunk({ companyId, menuId, sectionId: id, page, searchTerm }));
  };

  useEffect(() => {
    if (menu?._id !== menuId) {
      dispatch(getSharedMenuThunk({ companyId, menuOrItemGalleryId: menuId }));
    }
  }, [companyId, dispatch, menu?._id, menuConfigId, menuId]);

  useEffect(() => {
    dispatch(getLocationMenuSectionsThunk({ companyId, menuId, sectionId: id, searchTerm: decodedSearchTerm, page }));
  }, [companyId, decodedSearchTerm, dispatch, id, menuId, page]);

  const bodyProps: TableBodyProps = getTableBodyProps(setCurrentLocationSettings, setShowEditModal);

  const [showBulkEditModal, setShowBulkEditModal] = useState<boolean>(false);
  const { checkCells } = useAppSelector(selectTableState);
  const checkedLocationIds = Object.keys(checkCells).filter((locationId) => checkCells[locationId]);

  const sectionName = menu.sections.find((x) => x.uid === id)?.displayName;

  useEffect(() => {
    dispatch(initCellCheckedState(items));
  }, [dispatch, items]);

  return (
    <SharedMenuItemsLayout tab={SharedMenuItemsTabId.Menus}>
      {menu && !menuLoading ? (
        <>
          <div className={styles.container}>
            <ActionHeader
              text={sectionName}
              additionalTextStyles={styles.displayName}
              fullWidth
              backLinkTo={SharedMenuItemsRoutes.getSharedMenusMenuRoute(companyId, menuConfigId, menuId)}
              afterTextSlot={<h2 className={styles.title}>Location Settings</h2>}
            />
          </div>

          <AssignedLocationsTable
            title='Locations'
            titleTooltip={
              'This list of locations is based on where the parent menu configuration is assigned. It will update when the configuration is assigned or removed from locations.'
            }
            tableHeaderProps={tableHeaderProps}
            toolbarContent={
              checkedLocationIds.length > 0 ? (
                <BulkEditToolbarContent
                  pagination={pagination}
                  setShowBulkEditModal={setShowBulkEditModal}
                  showBulkUnassignButton={false}
                />
              ) : (
                <ToolbarContent initialSearch={decodedSearchTerm} onSearchTermChanged={onSearchTermChanged} />
              )
            }
            bodyProps={bodyProps}
            items={items}
            loading={loading}
            pagination={pagination}
            noAssignedLocationsMessage={
              'This menu section cannot be configured at any locations yet. Before you can configure this menu section at a location, \nits parent menu configuration to that location.'
            }
            query={searchTerm}
          />
          {showEditModal && currentLocationSettings && (
            <EditLocationSettingsModal
              title='Edit Menu Section Settings'
              setShowModal={setShowEditModal}
              locationName={currentLocationSettings.locationName}
              locationSettings={currentLocationSettings}
              onSave={saveLocationSettings}
            />
          )}

          {showBulkEditModal && (
            <EditLocationSettingsModal
              bulkEditCount={checkedLocationIds.length}
              title='Edit Menu Section Settings'
              setShowModal={setShowBulkEditModal}
              onSave={(updates) => saveLocationSettings(updates, true)}
            />
          )}
        </>
      ) : (
        <LoadingSpinner />
      )}
    </SharedMenuItemsLayout>
  );
};

const tableHeaderProps: TableHeaderProps = {
  cells: {
    checkboxCell: CheckboxCell,
    locationName: (props: any) => <HeaderCell {...props} maxWidth={HeaderCellConstants.defaultCellMaxWidth} />,
    status: (props: any) => <HeaderCell {...props} classes={styles.leftBorder} />,
    schedule: HeaderCell,
  },
  data: {
    checkboxCell: '',
    locationName: 'Location Name',
    status: 'Status',
    schedule: 'Schedule',
  },
  columns: ['checkboxCell', 'locationName', 'status', 'schedule'],
};

export const getTableBodyProps = (
  setCurrentLocation: React.Dispatch<React.SetStateAction<IBulkEditMenuSection | undefined>>,
  setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const bodyCells = getWrappedCellsWithMobileLayout(
    {
      checkboxCell: CheckboxCell,
      locationName: (props: CellProps) => (
        <LocationEditCell
          locationNameGetter={(rowData) => rowData.locationName}
          onEditClick={(rowData: IBulkEditMenuSection) => {
            setCurrentLocation(rowData);
            setShowEditModal(true);
          }}
          {...props}
        />
      ),
      status: EnabledStatusCell,
      schedule: ScheduleLinkCell,
    },
    tableHeaderProps.data,
    styles.bodyCell
  );

  const bodyProps: TableBodyProps = {
    cells: bodyCells,
    columns: tableHeaderProps.columns,
    data: [],
    rowKey: 'locationId',
    hiddenColumns: {},
  };
  return bodyProps;
};
