import {
  CompanyPaymentProviderType,
  ICompanyPaymentConfig,
  ICompanyPaymentConfigCreate,
  ICompanyPaymentConfigUpdate,
  IJetpayOptionsEdit,
} from '@ready/dashboardv2api.contracts';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toastErrorState, set200Toast } from 'redux/actions/uiActions/responseStateActions';
import { getAllCompanyConfigsList, createCompanyConfig, updateCompanyConfig, deleteCompanyConfig } from '../../service';

const getAllJetpayCompanyConfigsListThunk = createAsyncThunk(
  'companies/details/paymentProcessing/jetpay/fetchConfigs',
  async (args: { companyId: string }, thunkAPI) => {
    try {
      const companyConfigs = await getAllCompanyConfigsList(args.companyId);
      const jetpayConfigs = companyConfigs.filter(
        (config) => config.options.provider === CompanyPaymentProviderType.Jetpay
      );
      return jetpayConfigs;
    } catch (error) {
      thunkAPI.dispatch(toastErrorState(error.status, error.message));
      return [];
    }
  }
);

const createJetpayCompanyConfigThunk = createAsyncThunk(
  'companies/details/paymentProcessing/jetpay/createConfig',
  async (args: { companyId: string; config: ICompanyPaymentConfig }, thunkAPI) => {
    const createdOptions: IJetpayOptionsEdit = {
      name: args.config.options.name,
      provider: args.config.options.provider,
      companyNumber: args.config.options.companyNumber,
      serverPublicKey: args.config.options.serverPublicKey,
    };

    const createRequestPayload: ICompanyPaymentConfigCreate = {
      options: createdOptions,
    };

    try {
      const createdItem = await createCompanyConfig(args.companyId, createRequestPayload);
      thunkAPI.dispatch(set200Toast('Success! Configuration created.'));
      return createdItem;
    } catch (error) {
      thunkAPI.dispatch(toastErrorState(error.status, error.message));
    }
  }
);

const updateJetpayCompanyConfigThunk = createAsyncThunk(
  'companies/details/paymentProcessing/jetpay/updateConfig',
  async (args: { companyId: string; config: ICompanyPaymentConfig }, thunkAPI) => {
    const updatedOptions: IJetpayOptionsEdit = {
      name: args.config.options.name,
      provider: args.config.options.provider,
      companyNumber: args.config.options.companyNumber,
      serverPublicKey: args.config.options.serverPublicKey,
    };

    const updateRequestPayload: ICompanyPaymentConfigUpdate = {
      options: updatedOptions,
      id: args.config.id,
    };

    try {
      const updatedItem = await updateCompanyConfig(args.companyId, updateRequestPayload);
      thunkAPI.dispatch(set200Toast('Success! Configuration updated.'));
      return updatedItem;
    } catch (error) {
      thunkAPI.dispatch(toastErrorState(error.status, error.message));
    }
  }
);

const deleteJetpayCompanyConfigThunk = createAsyncThunk(
  'companies/details/paymentProcessing/jetpay/deleteConfig',
  async (args: { companyId: string; configId: string }, thunkAPI) => {
    try {
      await deleteCompanyConfig(args.companyId, args.configId);
      thunkAPI.dispatch(set200Toast('Configuration deleted.'));
      return args.configId;
    } catch (error) {
      thunkAPI.dispatch(toastErrorState(error.status, error.message));
    }
  }
);

export {
  getAllJetpayCompanyConfigsListThunk,
  createJetpayCompanyConfigThunk,
  updateJetpayCompanyConfigThunk,
  deleteJetpayCompanyConfigThunk,
};
