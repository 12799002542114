import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Button from '../../components/Button/Button';
import { setFormIsDirty } from '../../redux/actions/uiActions/formStateActions';

interface FormCancelEvent {
  shouldCancel: boolean;
  event?: React.MouseEvent<HTMLButtonElement>;
}

export interface FormActionButtonsProps {
  handleCancel: (event: React.MouseEvent<HTMLButtonElement>) => void;
  loading?: boolean;
  saveButtonLabel?: string;
  simpleLayout?: boolean;
  disabled?: boolean;
  additionalClassName?: string;
}

const FormActionButtons = (props: FormActionButtonsProps & ReduxProps) => {
  const {
    handleCancel,
    loading = false,
    saveButtonLabel = 'Save Changes',
    simpleLayout = false,
    disabled = false,
    additionalClassName = '',
    setFormIsDirty,
  } = props;

  const [formCancelEvent, setFormCancelEvent] = React.useState<FormCancelEvent>({ shouldCancel: false });

  const handleCancelClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.persist();

    setFormIsDirty(false);
    setFormCancelEvent({ event, shouldCancel: true });
  };

  React.useEffect(() => {
    if (formCancelEvent.shouldCancel) {
      handleCancel(formCancelEvent.event!);
    }
  }, [formCancelEvent, handleCancel]);

  const containerClassName = 'form__action-buttons';
  const containerLayoutClassName = simpleLayout ? '' : ' form__action-buttons--container-layout';
  const className = `${containerClassName} ${containerLayoutClassName} ${additionalClassName}`;

  return (
    <div className={className}>
      <Button label='Cancel' onClick={handleCancelClick} variant='secondary' size='large' unavailable={loading} />
      <Button
        label={saveButtonLabel}
        type='submit'
        variant='primary'
        size='large'
        disabled={disabled}
        loading={loading}
      />
    </div>
  );
};

const actionCreators = {
  setFormIsDirty,
};

const connector = connect(null, actionCreators);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(FormActionButtons);
