import React, { forwardRef } from 'react';
import Container from '../Container/Container';
import TextInput from '../TextInput/TextInput';
import InputError from '../InputError/InputError';

export interface TextInputNestedLabelProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  placeholder?: string;
  value?: string | number;
  type?: 'text' | 'email' | 'password' | 'number';
  withError?: boolean;
  errorMessage?: string;
  loading?: boolean;
  additionalStyles?: string;
  disabled?: boolean;
  maxLength?: number;
  withRequiredLabel?: boolean;
  containerStyles?: string;
}

const TextInputNestedLabel = forwardRef<HTMLInputElement, TextInputNestedLabelProps>(
  (props: TextInputNestedLabelProps, ref) => {
    const {
      label,
      placeholder,
      value,
      withError = false,
      errorMessage = '',
      loading = false,
      withRequiredLabel = false,
      additionalStyles = '',
      containerStyles = '',
    } = props;

    const errorClassName = withError ? 'text-input-nested-label--state-error' : '';
    const loadingClassName = loading ? 'text-input-nested-label--state-loading' : '';
    const labelVisibilityClassName = value !== '' ? 'text-input-nested-label__label--visible' : '';
    const containerStyle = `text-input-nested-label-container ${containerStyles}`;

    return (
      <Container additionalClassName={containerStyle}>
        <label className={`text-input-nested-label ${errorClassName} ${loadingClassName}`}>
          <span className={`text-input-nested-label__label ${labelVisibilityClassName}`}>{label}</span>
          {withRequiredLabel && <span className='text-input-nested-label__required-label'>*Required</span>}
          <TextInput additionalStyles={additionalStyles} {...props} placeholder={placeholder || label} ref={ref} />
        </label>
        <>{withError && errorMessage && <InputError message={errorMessage} />}</>
      </Container>
    );
  }
);

export default TextInputNestedLabel;
