import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../../redux/initialStates/AppState';
import {
  initForm,
  fetchFormData,
  deleteSecurityRole,
  showDeleteSecurityRoleModal,
} from '../../redux/actions/securityRoleFormActions';
import { loadPermissionsSet } from '../../redux/actions/securityMetadataActions';
import LayoutContent from '../../../components/AppLayout/LayoutContent';
import ActionHeader, { Action } from '../../../components/ActionHeader/ActionHeader';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { ContextParams } from '../../../types/ContextParams.interface';
import { Form } from '../../../components/Form';
import PermissionSectionView from '../../components/PermissionSectionView';
import styles from './SecurityRoleViewPage.module.scss';
import { DeleteConfirmationModal } from '../../../components/Modal';
import {
  PrincipalPermissions,
  ResourceType,
  SecurityScope,
  Verifier,
  SecurityRoleResourceActions,
} from '@ready/security.core';

interface PageParams extends ContextParams {
  roleId: string;
}

const SecurityRoleViewPage = ({
  initForm,
  fetchFormData,
  roleForm,
  securityMetadata,
  deleteSecurityRole,
  loadPermissionsSet,
  showDeleteSecurityRoleModal,
  isDeleted,
  permissions,
}: ReduxProps): JSX.Element => {
  const { contextId, roleId } = useParams<PageParams>();
  const listPageUrl = `/companies/${contextId}/securityRoles/`;
  const { push } = useHistory();
  const { pathname } = useLocation();

  // Fetch form data on mount
  React.useEffect(() => {
    fetchFormData(contextId, roleId);
  }, [fetchFormData, contextId, roleId]);

  React.useEffect(() => {
    if (!!contextId) {
      loadPermissionsSet(contextId);
    }
  }, [loadPermissionsSet, contextId]);

  // Re-initialize form data on unmount
  React.useEffect(
    () => () => {
      initForm();
    },
    [initForm]
  );

  React.useEffect(() => {
    if (isDeleted) push(listPageUrl);
  }, [isDeleted, push, listPageUrl]);

  const handleDeleteSecurityRoleClick = () => {
    showDeleteSecurityRoleModal(true);
  };

  const handleEditClick = () => {
    push(`${pathname}/edit`);
  };

  const onConfirmDelete = () => {
    if (roleForm) {
      deleteSecurityRole(contextId, roleId);
    }
  };

  const hasSecurityRolesAllPermission = Verifier.check(
    new PrincipalPermissions(permissions),
    SecurityScope.company,
    ResourceType.securityRole,
    SecurityRoleResourceActions.all
  );

  const editRoleButton = {
    label: 'Edit Role',
    primary: true,
    onClick: handleEditClick,
    hidden: !hasSecurityRolesAllPermission,
  };
  const deleteRoleButton = {
    label: 'Delete Role',
    primary: false,
    variant: 'secondary-gray-bg',
    onClick: handleDeleteSecurityRoleClick,
    hidden: !hasSecurityRolesAllPermission,
  };
  const actionButtons: Action[] = [editRoleButton, deleteRoleButton];

  return (
    <LayoutContent title='Security Role Details' loadingContent={roleForm.loading} containerType='within'>
      <ActionHeader
        text={roleForm.name}
        extendedText={roleForm.description}
        extendedTextPosition='below'
        extendedTextStyle='regular'
        backLinkTo={listPageUrl}
        actionButtons={actionButtons}
      />
      <>
        {roleForm && roleForm.deleteRequested ? (
          <DeleteConfirmationModal
            setShowModal={showDeleteSecurityRoleModal}
            item='Security Role'
            itemName={roleForm.name}
            loading={roleForm.loading}
            handleDelete={onConfirmDelete}
          />
        ) : null}
      </>
      <div className={styles.formWithOutTopMargin}>
        <Form>
          <PermissionSectionView
            permissionType='company'
            showDivider={false}
            permissions={securityMetadata.permissions.permissionSet.companyPermissions}
            permissionsForm={roleForm.companyPermissions}
          />
          <PermissionSectionView
            permissionType='location'
            permissions={securityMetadata.permissions.permissionSet.locationPermissions}
            permissionsForm={roleForm.locationPermissions}
          />
        </Form>
      </div>
    </LayoutContent>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    roleForm: state.securityRoles.securityRoleForm,
    securityMetadata: state.securityRoles.securityMetadata,
    deleteRequested: state.securityRoles.securityRoleForm.deleteRequested,
    isDeleted: state.securityRoles.securityRoleForm.isDeleted,
    permissions: state.session.permissions.permissionsList,
  };
};

const actionCreators = {
  initForm,
  fetchFormData,
  loadPermissionsSet,
  deleteSecurityRole,
  showDeleteSecurityRoleModal,
};

const connector = connect(mapStateToProps, actionCreators);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(SecurityRoleViewPage);
