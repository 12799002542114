import React from 'react';
import { useAppSelector } from '../../../redux/store';
import { Panel, PanelLayout } from '../../../components/PanelLayout';
import { selectOnAccountPaymentOptions } from '../../redux/selectors/onAccountPaymentOptionsSelector';
import styles from './OnAccountPaymentOptionDetailsContent.module.scss';
import { OnAccountPaymentOptionDetailsEditPanel } from './OnAccountPaymentOptionDetailsEditPanel';
import { OnAccountPaymentOptionDetailsViewPanel } from './OnAccountPaymentOptionDetailsViewPanel';
import { IOnAccountPaymentOptionsState } from '../../redux/initialStates/onAccountPaymentOptions';

export const OnAccountPaymentOptionDetailsContent = () => {
  const {
    view: { isEditMode },
  } = useAppSelector<IOnAccountPaymentOptionsState>(selectOnAccountPaymentOptions);

  return (
    <div className={styles.contentPanelContainer}>
      <PanelLayout>
        <Panel>
          {isEditMode ? <OnAccountPaymentOptionDetailsEditPanel /> : <OnAccountPaymentOptionDetailsViewPanel />}
        </Panel>
      </PanelLayout>
    </div>
  );
};
