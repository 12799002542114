import React from 'react';

import { Modal, ModalBody, ModalFooter, ModalHeader } from '../../../components/Modal';
import DollarValue from '../../../components/Value/DollarValue';
import FormattedInput from '../../../components/FormattedInput/FormattedInput';
import styles from './RefundTransactionDialog.module.scss';

export interface RefundTransactionDialogProps {
  loading: boolean;
  amountAvailableForRefund: number;
  validationMessage?: string;
  errorMessage?: string;
  completed?: boolean;
  modalMessage?: string;
  setShowModal: (showModal: boolean) => void;
  handleRefund: (refundAmount: string, amountAvailableForRefund: number) => void;
}

const RefundTransactionDialog = (props: RefundTransactionDialogProps): JSX.Element => {
  const {
    loading,
    amountAvailableForRefund,
    validationMessage,
    errorMessage = '',
    modalMessage = '',
    completed = false,
    setShowModal,
    handleRefund,
  } = props;
  const inputRef = React.useRef<HTMLInputElement>();
  const defaultValue = inputRef.current?.value || (amountAvailableForRefund / 100).toFixed(2);

  const closeModal = React.useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  React.useEffect(() => {
    if (completed && !errorMessage) {
      closeModal();
    }
  }, [completed, errorMessage, closeModal]);

  return (
    <Modal loading={loading} setShowModal={setShowModal}>
      <ModalHeader headerLabel='Refund Payment' setShowModal={setShowModal} />

      <ModalBody>
        {modalMessage && <p>{modalMessage}</p>}

        <div className={styles.container}>
          <div className={styles.field}>
            <div className={styles.fieldTitle}>Available for Refund</div>
            <div className={styles.fieldText}>
              <DollarValue value={amountAvailableForRefund} />
            </div>
          </div>

          <div className={styles.field}>
            <div className={styles.fieldTitle}>Refund Amount</div>
            <div className={styles.fieldInput}>
              <FormattedInput
                value={defaultValue}
                format='money'
                placeholder='0.00'
                loading={loading}
                inputRef={inputRef}
                withError={!!validationMessage}
              />
            </div>
            <div className={styles.fieldMessage}>{validationMessage}</div>
          </div>
        </div>
      </ModalBody>

      <ModalFooter
        loading={loading}
        primaryLabel='Issue Refund'
        primaryActionHandler={() => handleRefund(inputRef.current!.value, amountAvailableForRefund)}
        secondaryLabel='Cancel'
        secondaryActionHandler={() => setShowModal(false)}
      />
    </Modal>
  );
};

export default RefundTransactionDialog;
