import { ITemplateMenuItem, ITemplateMenuItemRequest, ItemType } from '@ready/menu.core';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { setFormIsDirty } from 'redux/actions/uiActions/formStateActions';
import { set200Toast, toastErrorState } from 'redux/actions/uiActions/responseStateActions';
import { mapITemplateMenuItemToITemplateMenuItemUpdateRequest } from 'sharedMenuItems/mappers/mappers';
import {
  createTemplateMenuItem,
  deleteTemplateMenuItem,
  getTemplateMenuItem,
  updateTemplateMenuItem,
} from 'sharedMenuItems/pages/createEditItem/service';
import { getSharedMenuItemList } from 'sharedMenuItems/pages/itemsAndMods/service';
import { getSharedModifierGroups } from 'sharedMenuItems/pages/modifierGroups/modifierGroupService';

const getTemplateMenuItemThunk = createAsyncThunk(
  'sharedMenuItems/createEditItem/getItem',
  async (args: { companyId: string; itemId: string }, thunkAPI) => {
    try {
      return await getTemplateMenuItem(args.companyId, args.itemId);
    } catch (error) {
      thunkAPI.dispatch(toastErrorState(error.status, error.message));
    }
  }
);

const getModifierGroupsThunk = createAsyncThunk(
  'sharedMenuItems/createEditItem/getModifierGroupsThunk',
  async (args: { companyId: string; query?: string; page?: number }, thunkAPI) => {
    try {
      return await getSharedModifierGroups(args.companyId, args.query, args.page);
    } catch (error) {
      thunkAPI.dispatch(toastErrorState(error.status, error.message));
    }
  }
);

const getMoreModifierGroupsThunk = createAsyncThunk(
  'sharedMenuItems/createEditItem/getMoreModifierGroupsThunk',
  async (args: { companyId: string; query?: string; page?: number }, thunkAPI) => {
    try {
      return await getSharedModifierGroups(args.companyId, args.query, args.page);
    } catch (error) {
      thunkAPI.dispatch(toastErrorState(error.status, error.message));
    }
  }
);

const getForcedModifiersThunk = createAsyncThunk(
  'sharedMenuItems/createEditItem/getForcedModifiers',
  async (args: { companyId: string; page: number; query?: string }, thunkAPI) => {
    try {
      return await getSharedMenuItemList(args.companyId, args.query, 'options', undefined, args.page);
    } catch (error) {
      thunkAPI.dispatch(toastErrorState(error.status, error.message));
    }
  }
);

export const getMoreForcedModifiersThunk = createAsyncThunk(
  'sharedMenuItems/createEditItem/getMoreForcedModifiers',
  async (args: { companyId: string; page: number; query?: string }, thunkAPI) => {
    try {
      return await getSharedMenuItemList(args.companyId, args.query, 'options', undefined, args.page);
    } catch (error) {
      thunkAPI.dispatch(toastErrorState(error.status, error.message));
    }
  }
);

const getRecommendedItemsThunk = createAsyncThunk(
  'sharedMenuItems/createEditItem/getRecommendedItems',
  async (args: { companyId: string; page: number; query?: string }, thunkAPI) => {
    try {
      return await getSharedMenuItemList(args.companyId, args.query, 'items', undefined, args.page);
    } catch (error) {
      thunkAPI.dispatch(toastErrorState(error.status, error.message));
    }
  }
);

const getMoreRecommendedItemsThunk = createAsyncThunk(
  'sharedMenuItems/createEditItem/getMoreRecommendedItems',
  async (args: { companyId: string; page: number; query?: string }, thunkAPI) => {
    try {
      return await getSharedMenuItemList(args.companyId, args.query, 'items', undefined, args.page);
    } catch (error) {
      thunkAPI.dispatch(toastErrorState(error.status, error.message));
    }
  }
);

const createTemplateMenuItemThunk = createAsyncThunk(
  'sharedMenuItems/createEditItem/createItem',
  async (args: { companyId: string; item: ITemplateMenuItem }, thunkAPI) => {
    try {
      const createdItem = await createTemplateMenuItem(args.companyId, args.item);
      const isModifier = createdItem.itemType === 'option';
      thunkAPI.dispatch(set200Toast(`Success! ${isModifier ? 'Modifier' : 'Item'} created.`));
      thunkAPI.dispatch(setFormIsDirty(false));
      return createdItem;
    } catch (error) {
      thunkAPI.dispatch(toastErrorState(error.status, error.message));
    }
  }
);

const updateTemplateMenuItemThunk = createAsyncThunk(
  'sharedMenuItems/createEditItem/updateItem',
  async (args: { companyId: string; item: ITemplateMenuItem }, thunkAPI) => {
    try {
      const itemToUpdate: ITemplateMenuItemRequest = mapITemplateMenuItemToITemplateMenuItemUpdateRequest(args.item);
      const updatedItem = await updateTemplateMenuItem(args.companyId, itemToUpdate);
      const isModifier = updatedItem.itemType === 'option';
      thunkAPI.dispatch(set200Toast(`Success! ${isModifier ? 'Modifier' : 'Item'} updated.`));
      thunkAPI.dispatch(setFormIsDirty(false));
      return updatedItem;
    } catch (error) {
      thunkAPI.dispatch(toastErrorState(error.status, error.message));
    }
  }
);

const duplicateTemplateMenuItemThunk = createAsyncThunk(
  'sharedMenuItems/createEditItem/duplicateItem',
  async (args: { companyId: string; item: ITemplateMenuItem; displayName: string }, thunkAPI) => {
    try {
      const duplicatedItem = await createTemplateMenuItem(args.companyId, {
        ...args.item,
        _id: '',
        displayName: args.displayName,
      });
      const isModifier = duplicatedItem.itemType === 'option';
      thunkAPI.dispatch(set200Toast(`Success! ${isModifier ? 'Modifier' : 'Item'} created.`));
      thunkAPI.dispatch(setFormIsDirty(false));
      return duplicatedItem;
    } catch (error) {
      thunkAPI.dispatch(toastErrorState(error.status, error.message));
    }
  }
);

const deleteTemplateMenuItemThunk = createAsyncThunk(
  'sharedMenuItems/createEditItem/deleteItem',
  async (args: { companyId: string; itemId: string; itemType?: ItemType }, thunkAPI) => {
    try {
      await deleteTemplateMenuItem(args.companyId, args.itemId);
      const isModifier = args.itemType === 'option';
      thunkAPI.dispatch(set200Toast(`Success! ${isModifier ? 'Shared modifier' : 'Item'} deleted.`));
      thunkAPI.dispatch(setFormIsDirty(false));
    } catch (error) {
      thunkAPI.dispatch(toastErrorState(error.status, error.message));
    }
  }
);
export {
  getModifierGroupsThunk,
  getMoreModifierGroupsThunk,
  createTemplateMenuItemThunk,
  getForcedModifiersThunk,
  getRecommendedItemsThunk,
  getMoreRecommendedItemsThunk,
  duplicateTemplateMenuItemThunk,
  deleteTemplateMenuItemThunk,
  updateTemplateMenuItemThunk,
  getTemplateMenuItemThunk,
};
