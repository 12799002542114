import React, { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

// TODO: when ready for release, the NewMenusPage import will be removed
import MenusPage from './components/Menus/MenusPage';

import MenuPage from './components/Menus/MenuPage';
import MenuItemsPage from './components/ItemsAndMods/MenuItemsPage';
import MenuItemDetailPage from './components/ItemsAndMods/MenuItemDetailPage';
import ModifierGroupsPage from './components/ModifierGroups/ModifierGroupsPage';
import ViewModifierGroupPage from './components/ModifierGroups/ViewModifierGroupPage';
import CreateItemGroupPage from './components/ItemGroups/CreateItemGroupPage';
import ItemGroupsPage from './components/ItemGroups/ItemGroupsPage';
import ItemGroupDetailPage from './components/ItemGroups/ItemGroupDetailPage';
import { usePageTitle } from '../hooks';
import EditSchedulePage from './components/Schedules/EditSchedulePage';

const MenuBuilderRouter: FC<{}> = () => {
  const { path } = useRouteMatch();
  usePageTitle('Location Menus');

  return (
    <Switch>
      <Route path={`${path}/menuConfigs/:menuConfigId/menus/:menuId/items/:id`} component={MenuItemDetailPage} />
      <Route
        path={`${path}/menuConfigs/:menuConfigId/menus/:menuId/item-groups/:itemGroupId/items/:id`}
        component={MenuItemDetailPage}
      />
      <Route path={`${path}/menuConfigs/:menuConfigId/menus/:menuId/item-groups/:id`} component={ItemGroupDetailPage} />
      <Route path={`${path}/menuConfigs/:menuConfigId/menus/:id`} component={MenuPage} />
      <Route path={`${path}/menuConfigs/:menuConfigId/menus`} component={MenusPage} />

      <Route path={`${path}/menus/:menuId/items/:id`} component={MenuItemDetailPage} />
      <Route path={`${path}/menus/:menuId/item-groups/:itemGroupId/items/:id`} component={MenuItemDetailPage} />
      <Route path={`${path}/menus/:menuId/item-groups/:id`} component={ItemGroupDetailPage} />
      <Route path={`${path}/menus/:id`} component={MenuPage} />
      <Route path={`${path}/menus`} component={MenusPage} />

      <Route path={`${path}/items-and-mods/:id/forced-mods/:forcedModId`} component={MenuItemDetailPage} />
      <Route path={`${path}/items-and-mods/:menuItemId/schedules/:id`} component={EditSchedulePage} />
      <Route path={`${path}/items-and-mods/:id`} component={MenuItemDetailPage} />
      <Route path={`${path}/items-and-mods`} component={MenuItemsPage} />
      <Route path={`${path}/items-and-mods-:view`} component={MenuItemsPage} />
      <Route exact path={`${path}/item-groups/new`} component={CreateItemGroupPage} />
      <Route path={`${path}/item-groups/:itemGroupId/items/:id`} component={MenuItemDetailPage} />
      <Route path={`${path}/item-groups/:id`} component={ItemGroupDetailPage} />
      <Route path={`${path}/item-groups`} component={ItemGroupsPage} />
      <Route path={`${path}/mod-groups/:modGroupId/items/:id`} component={MenuItemDetailPage} />
      <Route path={`${path}/mod-groups/:id`} component={ViewModifierGroupPage} />
      <Route path={`${path}/mod-groups`} component={ModifierGroupsPage} />
    </Switch>
  );
};

export class Routes {
  public static getTemplate(): string {
    return '/locations/:locationId/menu-builder';
  }

  public static getMenusPage(companyId: string, locationId: string, menuConfigId?: string): string {
    if (menuConfigId) {
      return `/companies/${companyId}/locations/${locationId}/menu-builder/menuConfigs/${menuConfigId}/menus`;
    }
    return `/companies/${companyId}/locations/${locationId}/menu-builder/menus`;
  }

  public static getMenuPage(companyId: string, locationId: string, menuId: string, menuConfigId?: string): string {
    if (menuConfigId) {
      return `/companies/${companyId}/locations/${locationId}/menu-builder/menuConfigs/${menuConfigId}/menus/${menuId}`;
    }
    return `/companies/${companyId}/locations/${locationId}/menu-builder/menus/${menuId}`;
  }

  public static getItemGalleryItemPage(companyId: string, locationId: string, itemId: string, menuConfigId?: string): string {
    if (menuConfigId) {
      return `/companies/${companyId}/locations/${locationId}/menu-builder/menuConfigs/${menuConfigId}/menus/item-gallery/items/${itemId}`;
    }

    return `/companies/${companyId}/locations/${locationId}/menu-builder/menus/item-gallery/items/${itemId}`;
  }

  public static getLocationItemGalleryItemGroupPage(
    companyId: string,
    locationId: string,
    itemGroupId: string,
    menuConfigId?:string
  ): string {
    if (menuConfigId) {
      return `/companies/${companyId}/locations/${locationId}/menu-builder/menuConfigs/${menuConfigId}/menus/item-gallery/item-groups/${itemGroupId}`;
    }

    return `/companies/${companyId}/locations/${locationId}/menu-builder/menus/item-gallery/item-groups/${itemGroupId}`;
  }

  public static getLocationItemGalleryItemGroupItemPage(
    companyId: string,
    locationId: string,
    itemGroupId: string,
    itemId: string
  ): string {
    return `/companies/${companyId}/locations/${locationId}/menu-builder/menus/item-gallery/item-groups/${itemGroupId}/items/${itemId}`;
  }

  public static getMenuSectionItemPage(
    companyId: string,
    locationId: string,
    menuId: string,
    itemId: string,
    menuConfigId?: string
  ): string {
    if (menuConfigId) {
      return `/companies/${companyId}/locations/${locationId}/menu-builder/menuConfigs/${menuConfigId}/menus/${menuId}/items/${itemId}`;
    }

    return `/companies/${companyId}/locations/${locationId}/menu-builder/menus/${menuId}/items/${itemId}`;
  }

  public static getMenuSectionItemGroupPage(
    companyId: string,
    locationId: string,
    menuId: string,
    itemGroupId: string,
    menuConfigId?: string
  ): string {
    if (menuConfigId) {
      return `/companies/${companyId}/locations/${locationId}/menu-builder/menuConfigs/${menuConfigId}/menus/${menuId}/item-groups/${itemGroupId}`;
    }

    return `/companies/${companyId}/locations/${locationId}/menu-builder/menus/${menuId}/item-groups/${itemGroupId}`;
  }

  public static getMenuSectionItemGroupItemPage(
    companyId: string,
    locationId: string,
    menuId: string,
    itemGroupId: string,
    itemId: string
  ): string {
    return `/companies/${companyId}/locations/${locationId}/menu-builder/menus/${menuId}/item-groups/${itemGroupId}/items/${itemId}`;
  }

  public static getItemsAndModsPage(companyId: string, locationId: string, view?: string): string {
    return !view || view === 'all'
      ? `/companies/${companyId}/locations/${locationId}/menu-builder/items-and-mods`
      : `/companies/${companyId}/locations/${locationId}/menu-builder/items-and-mods-${view}`;
  }

  public static getItemsAndModsPageModsView(companyId: string, locationId: string): string {
    return `/companies/${companyId}/locations/${locationId}/menu-builder/items-and-mods-mods`;
  }

  public static getMenuItemPage(companyId: string, locationId: string, menuItemId: string): string {
    return `/companies/${companyId}/locations/${locationId}/menu-builder/items-and-mods/${menuItemId}`;
  }

  public static getForcedModItemPage(
    companyId: string,
    locationId: string,
    itemId: string,
    forcedModId: string
  ): string {
    return `/companies/${companyId}/locations/${locationId}/menu-builder/items-and-mods/${itemId}/forced-mods/${forcedModId}`;
  }

  public static getScheduleRouteFromMenuItem(
    companyId: string,
    locationId: string,
    itemId: string,
    scheduleId: string
  ): string {
    return `/companies/${companyId}/locations/${locationId}/menu-builder/items-and-mods/${itemId}/schedules/${scheduleId}`;
  }

  public static getNewMenuItemPage(companyId: string, locationId: string): string {
    return `/companies/${companyId}/locations/${locationId}/menu-builder/items-and-mods/new-item`;
  }

  public static getNewModifierPage(companyId: string, locationId: string): string {
    return `/companies/${companyId}/locations/${locationId}/menu-builder/items-and-mods/new-mod`;
  }

  public static getItemGroupsPage(companyId: string, locationId: string): string {
    return `/companies/${companyId}/locations/${locationId}/menu-builder/item-groups`;
  }

  public static getNewItemGroupPage(companyId: string, locationId: string): string {
    return `/companies/${companyId}/locations/${locationId}/menu-builder/item-groups/new`;
  }

  public static getItemGroupPage(companyId: string, locationId: string, itemGroupId: string): string {
    return `/companies/${companyId}/locations/${locationId}/menu-builder/item-groups/${itemGroupId}`;
  }

  public static getItemGroupItemPage(
    companyId: string,
    locationId: string,
    itemGroupId: string,
    itemId: string
  ): string {
    return `/companies/${companyId}/locations/${locationId}/menu-builder/item-groups/${itemGroupId}/items/${itemId}`;
  }

  public static getModifierGroupsPage(companyId: string, locationId: string): string {
    return `/companies/${companyId}/locations/${locationId}/menu-builder/mod-groups`;
  }

  public static getNewModifierGroupPage(companyId: string, locationId: string): string {
    return `/companies/${companyId}/locations/${locationId}/menu-builder/mod-groups/new`;
  }

  static getModifierGroupPage(companyId: string, locationId: string, modGroupId: string): string {
    return `/companies/${companyId}/locations/${locationId}/menu-builder/mod-groups/${modGroupId}`;
  }

  static getModifierGroupItemPage(companyId: string, locationId: string, modGroupId: string, itemId: string): string {
    return `/companies/${companyId}/locations/${locationId}/menu-builder/mod-groups/${modGroupId}/items/${itemId}`;
  }
}

export default MenuBuilderRouter;
