import {
  OrderSmsMessageSource,
  ISmsNotificationsConfig,
  ISmsConfig,
  ILocationSmsConfigEdit,
  ISmsMessage,
} from '@ready/dashboardv2api.contracts';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { OrderSmsMessagesService } from '../../../services/orderSMSMessagesService';
import {
  orderSmsMessagesInitialState,
  IOrderSmsMessages,
  newConfigValidation,
  IOrderSmsMessagesValidationResult,
  orderSmsDefaultConfig,
} from '../../initialStates/orderSMSMessagesInitialState';
import { OrderSmsMessagesThunk } from './orderSMSMessagesThunk';

const updateOrderSmsConfig = (
  state: IOrderSmsMessages,
  configKey: keyof ISmsConfig,
  smsKey: keyof ISmsMessage,
  payload: string | boolean
): void => {
  const configToChange =
    state.orderSmsMessages.messageSource === OrderSmsMessageSource.Company
      ? state.orderSmsMessages.companyConfig
      : state.orderSmsMessages.locationConfig;

  configToChange[configKey] = {
    ...(configToChange[configKey] ?? orderSmsDefaultConfig),
    [smsKey]: payload,
  };
};

export const reducers = {
  updateOrderSmsMessageSource: (
    state: IOrderSmsMessages = orderSmsMessagesInitialState,
    action: { payload: string }
  ): void => {
    const convertPayloadToEnum =
      action.payload === OrderSmsMessageSource.Location
        ? OrderSmsMessageSource.Location
        : OrderSmsMessageSource.Company;
    state.orderSmsMessages.messageSource = convertPayloadToEnum;
    state.validation = newConfigValidation();
    state.validated = false;
  },

  updateCompanyOrderPlacedMessageStatus: (state: IOrderSmsMessages, action: { payload: boolean }): void => {
    updateOrderSmsConfig(state, 'orderPlaced', 'isActive', action.payload);
  },

  updateCompanyOrderPlacedMessage: (state: IOrderSmsMessages, action: { payload: string }): void => {
    updateOrderSmsConfig(state, 'orderPlaced', 'value', action.payload);
  },

  updateLocationOrderPlacedMessageStatus: (state: IOrderSmsMessages, action: { payload: boolean }): void => {
    updateOrderSmsConfig(state, 'orderPlaced', 'isActive', action.payload);
  },

  updateLocationOrderPlacedMessage: (state: IOrderSmsMessages, action: { payload: string }): void => {
    updateOrderSmsConfig(state, 'orderPlaced', 'value', action.payload);
  },
  updateCompanyOrderInProgressMessageStatus: (state: IOrderSmsMessages, action: { payload: boolean }): void => {
    updateOrderSmsConfig(state, 'orderInProgress', 'isActive', action.payload);
  },
  updateLocationOrderInProgressMessageStatus: (state: IOrderSmsMessages, action: { payload: boolean }): void => {
    updateOrderSmsConfig(state, 'orderInProgress', 'isActive', action.payload);
  },

  updateCompanyOrderInProgressMessage: (state: IOrderSmsMessages, action: { payload: string }): void => {
    updateOrderSmsConfig(state, 'orderInProgress', 'value', action.payload);
  },
  updateLocationOrderInProgressMessage: (state: IOrderSmsMessages, action: { payload: string }): void => {
    updateOrderSmsConfig(state, 'orderInProgress', 'value', action.payload);
  },

  updateCompanyOrderIsReadyMessageStatus: (state: IOrderSmsMessages, action: { payload: boolean }): void => {
    updateOrderSmsConfig(state, 'orderReady', 'isActive', action.payload);
  },
  updateLocationOrderIsReadyMessageStatus: (state: IOrderSmsMessages, action: { payload: boolean }): void => {
    updateOrderSmsConfig(state, 'orderReady', 'isActive', action.payload);
  },

  updateCompanyOrderIsReadyMessage: (state: IOrderSmsMessages, action: { payload: string }): void => {
    updateOrderSmsConfig(state, 'orderReady', 'value', action.payload);
  },
  updateLocationOrderIsReadyMessage: (state: IOrderSmsMessages, action: { payload: string }): void => {
    updateOrderSmsConfig(state, 'orderReady', 'value', action.payload);
  },
  updateCompanyOrderCancelledMessageStatus: (state: IOrderSmsMessages, action: { payload: boolean }): void => {
    updateOrderSmsConfig(state, 'orderCancelled', 'isActive', action.payload);
  },
  updateLocationOrderCancelledMessageStatus: (state: IOrderSmsMessages, action: { payload: boolean }): void => {
    updateOrderSmsConfig(state, 'orderCancelled', 'isActive', action.payload);
  },

  updateCompanyOrderCancelledMessage: (state: IOrderSmsMessages, action: { payload: string }): void => {
    updateOrderSmsConfig(state, 'orderCancelled', 'value', action.payload);
  },
  updateLocationOrderCancelledMessage: (state: IOrderSmsMessages, action: { payload: string }): void => {
    updateOrderSmsConfig(state, 'orderCancelled', 'value', action.payload);
  },
  updateCompanyOrderPickUpTimeUpdatedMessageStatus: (state: IOrderSmsMessages, action: { payload: boolean }): void => {
    updateOrderSmsConfig(state, 'pickUpTimeUpdated', 'isActive', action.payload);
  },
  updateLocationOrderPickUpTimeUpdatedMessageStatus: (state: IOrderSmsMessages, action: { payload: boolean }): void => {
    updateOrderSmsConfig(state, 'pickUpTimeUpdated', 'isActive', action.payload);
  },

  updateCompanyOrderPickUpTimeUpdatedMessage: (state: IOrderSmsMessages, action: { payload: string }): void => {
    updateOrderSmsConfig(state, 'pickUpTimeUpdated', 'value', action.payload);
  },
  updateLocationOrderPickUpTimeUpdatedMessage: (state: IOrderSmsMessages, action: { payload: string }): void => {
    updateOrderSmsConfig(state, 'pickUpTimeUpdated', 'value', action.payload);
  },
  toggleOrderSmsEditCompanyModal: (state: IOrderSmsMessages, action: { payload: boolean }): void => {
    state.isModalOpen = action.payload;
  },
  toggleOrderSmsEditMode: (state: IOrderSmsMessages, action: { payload: boolean }): void => {
    state.isEditMode = action.payload;
  },
  resetOrderSmsEditCompanyModal: (state: IOrderSmsMessages): void => {
    state.orderSmsMessages.companyConfig = state.cachedOrderSmsMessages.companyConfig;
    state.validation = newConfigValidation();
    state.validated = false;
  },

  resetOrderSmsEditLocationForm: (state: IOrderSmsMessages): void => {
    state.orderSmsMessages = state.cachedOrderSmsMessages;
    state.validation = newConfigValidation();
    state.isEditMode = state.formProcessing = state.validated = false;
  },
  orderSmsNotificationLoadingBegin: (state: IOrderSmsMessages): void => {
    state.loading = true;
  },
  orderSmsNotificationLoadingFinish: (state: IOrderSmsMessages): void => {
    state.loading = false;
  },

  loadOrderSmsMessagesConfigSuccess: (state: IOrderSmsMessages, action: { payload: ISmsNotificationsConfig }): void => {
    state.loading = state.validated = false;
    state.validation = newConfigValidation();
    state.orderSmsMessages = state.cachedOrderSmsMessages = action.payload;
  },

  saveOrderSmsMessagesBegin: (state: IOrderSmsMessages): void => {
    state.formProcessing = true;
  },
  saveOrderSmsMessagesFinish: (state: IOrderSmsMessages): void => {
    state.formProcessing = false;
  },

  saveOrderSmsMessagesCompanySuccess: (state: IOrderSmsMessages, action: { payload: ISmsConfig }): void => {
    state.validation = newConfigValidation();
    state.formProcessing = state.isModalOpen = state.validated = false;
    state.orderSmsMessages.companyConfig = state.cachedOrderSmsMessages.companyConfig = action.payload;
  },

  saveOrderSmsMessagesLocationSuccess: (
    state: IOrderSmsMessages,
    action: { payload: ILocationSmsConfigEdit }
  ): void => {
    state.validation = newConfigValidation();
    state.validated = false;
    state.formProcessing = state.isModalOpen = state.isEditMode = false;
    state.orderSmsMessages.isEnabled = state.cachedOrderSmsMessages.isEnabled;
    state.orderSmsMessages.companyConfig = state.cachedOrderSmsMessages.companyConfig;
    const { locationConfig, messageSource } = action.payload;
    state.orderSmsMessages.locationConfig = state.cachedOrderSmsMessages.locationConfig = locationConfig;
    state.orderSmsMessages.messageSource = state.cachedOrderSmsMessages.messageSource = messageSource;
  },

  validateOrderSmsMessages: (state: IOrderSmsMessages, action: { payload: ISmsConfig }): void => {
    const validationResult: IOrderSmsMessagesValidationResult = OrderSmsMessagesService.validateOrderSmsMessages(
      action.payload
    );
    state.validation = validationResult.config;
    state.validated = !validationResult.errorsFound;
    state.formProcessing = false;
  },

  resetOrderSmsMessagesConfig: (state: IOrderSmsMessages): void => {
    const {
      orderSmsMessages,
      cachedOrderSmsMessages,
      validated,
      validation,
      isEditMode,
      isModalOpen,
      formProcessing,
      loading,
    } = orderSmsMessagesInitialState;
    state.orderSmsMessages = orderSmsMessages;
    state.cachedOrderSmsMessages = cachedOrderSmsMessages;
    state.validated = validated;
    state.validation = validation;
    state.formProcessing = formProcessing;
    state.isModalOpen = isModalOpen;
    state.loading = loading;
    state.isEditMode = isEditMode;
  },
};

export const extraReducers = (builder: ActionReducerMapBuilder<IOrderSmsMessages>) => {
  builder.addCase(OrderSmsMessagesThunk.fetchOrderSmsMessagesConfig.pending, reducers.orderSmsNotificationLoadingBegin);
  builder.addCase(
    OrderSmsMessagesThunk.fetchOrderSmsMessagesConfig.fulfilled,
    reducers.loadOrderSmsMessagesConfigSuccess
  );

  builder.addCase(
    OrderSmsMessagesThunk.fetchOrderSmsMessagesConfig.rejected,
    reducers.orderSmsNotificationLoadingFinish
  );

  builder.addCase(
    OrderSmsMessagesThunk.updateOrderSmsMessagesCompanyConfig.pending,
    reducers.saveOrderSmsMessagesBegin
  );

  builder.addCase(
    OrderSmsMessagesThunk.updateOrderSmsMessagesCompanyConfig.fulfilled,
    reducers.saveOrderSmsMessagesCompanySuccess
  );

  builder.addCase(
    OrderSmsMessagesThunk.updateOrderSmsMessagesCompanyConfig.rejected,
    reducers.saveOrderSmsMessagesFinish
  );

  builder.addCase(
    OrderSmsMessagesThunk.updateOrderSmsMessagesLocationConfig.pending,
    reducers.saveOrderSmsMessagesBegin
  );

  builder.addCase(
    OrderSmsMessagesThunk.updateOrderSmsMessagesLocationConfig.fulfilled,
    reducers.saveOrderSmsMessagesLocationSuccess
  );

  builder.addCase(
    OrderSmsMessagesThunk.updateOrderSmsMessagesLocationConfig.rejected,
    reducers.saveOrderSmsMessagesFinish
  );
};
