import React from 'react';
import styles from './PickupTime.module.scss';
import { connect, ConnectedProps } from 'react-redux';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';
import { useClassNames } from '../../../utils/cssUtils';
import { AppState } from '../../../redux/initialStates/AppState';
import { setEditPickupTimeDialogOpen } from '../../../redux/actions/orders/orderDetailActions';
import { UpdatePickupDateTimeDialog } from '.';
import { OrderFulfillmentStatus } from '@ready/dashboardv2api.contracts';
import { formatDateTime } from '../../../utils/formatUtils';
import { beginningOfTime } from 'utils/dateUtils';

interface PickupTimeProps extends ReduxProps {
  readOnly?: boolean;
}

const PickupTime = ({
  orderStatus,
  pickupTime,
  readOnly = false,
  setEditPickupTimeDialogOpen,
}: PickupTimeProps): JSX.Element => {
  // TODO in READY-576: convert from toggled feature once ready for prod
  const isCancelledOrCompleted =
    orderStatus === OrderFulfillmentStatus.Cancelled || orderStatus === OrderFulfillmentStatus.Complete;
  const editable = !readOnly && orderStatus && !isCancelledOrCompleted;

  const containerClassName = useClassNames([{ editable }, styles.container], styles);

  const handleClick = () => {
    if (editable) {
      setEditPickupTimeDialogOpen(true);
    }
  };

  const getPickupTimeFormatted = React.useCallback(() => {
    return pickupTime ? formatDateTime(new Date(pickupTime)) : formatDateTime(beginningOfTime);
  }, [pickupTime]);

  return (
    <>
      <UpdatePickupDateTimeDialog />
      <button className={containerClassName} onClick={handleClick}>
        <span>
          {getPickupTimeFormatted()}
          {editable && <TextIcon icon={Icon.Pencil} />}
        </span>
      </button>
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    orderStatus: state.orders.orderDetail.status,
    pickupTime: state.orders.orderDetail.pickupTime,
  };
};

const actionCreators = {
  setEditPickupTimeDialogOpen,
};

const connector = connect(mapStateToProps, actionCreators);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(PickupTime);
