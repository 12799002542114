import React from 'react';
import { connect } from 'react-redux';
import IconButton from '../IconButton/IconButton';
import styles from './app-layout.module.scss';
import { updateMobileSidebarVisible } from '../../redux/actions/uiActions/sidebarStateActions';
import { Action } from '../../redux/types';
import Divider from '../Divider/Divider';

export interface HeaderProps {
  title: string;
  controls?: React.ReactNode;
  titleControls?: JSX.Element | JSX.Element[] | null;
  subHeader?: JSX.Element | (JSX.Element | null)[] | null;
  additionalSubHeaderControls?: React.ReactNode;
  showSubHeaderDivider?: boolean;
  mobileSidebarVisible: boolean;
  updateMobileSidebarVisible: (visible: boolean) => Action;
}

const Header = (props: HeaderProps) => {
  const {
    title,
    controls,
    titleControls = null,
    subHeader,
    additionalSubHeaderControls,
    showSubHeaderDivider = true,
    mobileSidebarVisible,
    updateMobileSidebarVisible,
  } = props;

  const toggleSidebar = () => updateMobileSidebarVisible(!mobileSidebarVisible);

  return (
    <>
      <div className={styles.header}>
        <div className={styles.headerTitleContainer}>
          <div className={styles.headerTitle}>
            <div className={styles.headerTitleWithControls}>
              <IconButton
                onClick={toggleSidebar}
                additionalClassName={styles.headerSidebarToggler}
                id='header-sidebar-toggler'
              >
                <i className='icon-dashboard-icons_menu'></i>
              </IconButton>
              <h1 className={styles.headerTitleText}>{title}</h1>
              {titleControls && (
                <div className={styles.headerTitleControlsWrapper}>
                  <span className={styles.headerTitleControls}>{titleControls}</span>
                </div>
              )}
            </div>
            {controls && <div className={styles.headerControls}>{controls}</div>}
          </div>
          {subHeader && showSubHeaderDivider ? <Divider color='lightGray' size='small' /> : null}
        </div>
      </div>
      {subHeader ? (
        <div className={styles.subHeader}>
          {subHeader}
          {additionalSubHeaderControls ? <div> {additionalSubHeaderControls} </div> : null}
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  mobileSidebarVisible: state.ui.sidebarState.mobileSidebarVisible,
});

export default connect(mapStateToProps, {
  updateMobileSidebarVisible,
})(Header);
