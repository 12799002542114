import React from 'react';
import { Appearance, IMenuItem, IMenuItemGroup, IMenuSection, IMenuSectionItemGroup, Status } from '@ready/menu.core';
import SectionPanel from 'menus/components/Menus/SectionPanel';
import EditSectionPanel from 'menus/components/Menus/EditSectionPanel';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  updateMenuSectionDescription,
  updateMenuSectionDisplayName,
  updateMenuSectionDefaultAppearance,
  setMenuSectionEditMode,
  prepareItemsAndGroupsModal,
  selectItemGroupForMenuSection,
  removeMenuItemFromMenuSection,
  removeMenuItemFromItemGroupInMenuSection,
  moveMenuItemInSection,
  moveMenuItemInGroup,
  updateItemGalleryReorderItems,
  updateItemGalleryItemsSortOrder,
  selectItemForMenuGallery,
  selectItemGroupForMenuGallery,
  deselectItemForMenuGallery,
  deselectItemGroupForMenuGallery,
  updateMenuItemDisplayNameInSection,
  updateMenuGallerySubitemName,
  updateMenuSectionViewState,
  menuGalleryUpdateGalleryName,
  menuGalleryUpdateDescription,
  removeMenuGallerySubitem,
  updateItemGalleryItemGroupItemsSortOrder,
  startEditingInnerItemGallery,
  menuGalleryUpdateStatus,
  prepareReorderMenuSectionsModal,
  moveMenuSection,
  saveReorderedMenuSections,
  showDeleteMenuSectionModal,
  deleteMenuSection,
} from '../../../../menus/redux/MenusActions';
import { saveSharedMenuSectionItemGalleryThunk, saveSharedMenuSectionThunk } from '../redux/thunks';
import { useHistory, useParams } from 'react-router-dom';
import { ContextParams } from 'types/ContextParams.interface';
import EmptyResult from 'components/EmptyResult/EmptyResult';
import { prepareMenuItemsModal } from 'menus/redux/ItemsAndModsActions';
import MenuItemsView from 'menus/types/MenuItemsView.enum';
import ItemsAndGroupsView from 'menus/types/ItemsAndGroupsView.enum';
import AddItemsAndGroupsDialog from 'menus/components/Menus/AddItemsAndGroupsDialog';
import {
  deselectItemGroupFromMenuSection,
  deselectMenuItemFromItemGalleryItemGroup,
  deselectMenuItemFromMenuSection,
  deselectMenuItemFromMenuSectionItemGroup,
  selectMenuItemForItemGalleryItemGroup,
  selectMenuItemForMenuSection,
  selectMenuItemForMenuSectionItemGroup,
} from 'menus/redux/MenuBuilderActions';
import AddMenuItemsDialog from 'menus/components/ItemsAndMods/AddMenuItemsDialog';
import { useResponsiveBreakpoint } from 'hooks';
import { SharedMenuItemsRoutes } from 'sharedMenuItems/Router';
import { ItemGalleryCollapsiblePanel } from 'menus/components/Menus/ItemGalleryCollapsiblePanel';
import { EditItemGalleryPanel } from 'menus/components/Menus/EditItemGalleryPanel';
import MenuBuilderConfig from 'menus/types/MenuBuilderConfig';
import MenuItemAndItemGroupList from 'menus/components/Menus/MenuItemAndItemGroupList';
import ItemGroupFormModal from 'menus/components/ItemGroups/ItemGroupFormModal';
import {
  prepareNewItemGroupModal,
  saveNewItemGroupInItemGallery,
  saveNewItemGroupInSection,
  updateItemGroupDescription,
  updateItemGroupDisplayName,
} from 'menus/redux/ItemGroupsActions';
import ReorderDialog from 'menus/components/Menus/ReorderDialog';
import { DeleteConfirmationModal } from 'components/Modal';

export const ViewEditSectionPanel = () => {
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const {
    menuForm: {
      sectionForm: { section, editing: isEditingSection },
      sectionForm,
      menu,
      editing: isEditingMenu,
      processing,
    },
    itemsAndGroupsSelection: { modalVisible: isItemListModalVisible, view: itemListView },
    menuGalleryForm,
    previousMenuState: { expandedSections },
  } = useAppSelector((state) => state.menuBuilder.menus);
  const { itemGroupForm } = useAppSelector((state) => state.menuBuilder.itemGroups);
  const { modalVisible: isSharedMenuItemsModalVisible } = useAppSelector(
    (state) => state.menuBuilder.itemsAndMods.menuItemSelection
  );
  const { isMobile } = useResponsiveBreakpoint();

  const { contextId: companyId, id: menuId, menuConfigId } = useParams<ContextParams & { menuConfigId: string }>();

  const [groupIdForAddingItems, setGroupIdForAddingItems] = React.useState<string>('');
  const [isAddingToItemGallery, setIsAddingToItemGallery] = React.useState<boolean>(false);

  const isEditingMenuItemGallery =
    menuGalleryForm.editing && menuGalleryForm.menu.itemGallery?._id === menu.itemGallery?._id;

  const isEditingNewSection = isEditingSection && !section.uid;
  const hasMenuSections = menu.sections.length > 0;
  const isSectionDisabled = isEditingMenu || isEditingSection || processing || isEditingMenuItemGallery;
  const isEditingThisSection = (menuSection: IMenuSection): boolean => {
    return isEditingSection && menuSection.uid === section.uid;
  };

  const onEditSectionClick = (section: IMenuSection) => {
    dispatch(setMenuSectionEditMode(true, section));
    dispatch(startEditingInnerItemGallery(section.itemGallery!));
  };

  const onAddItemsToSection = (): void => {
    setGroupIdForAddingItems('');
    setIsAddingToItemGallery(false);
    dispatch(
      prepareItemsAndGroupsModal(
        true,
        sectionForm.section.items.map((item) => item.itemId),
        ItemsAndGroupsView.ITEMS
      )
    );
  };

  const onAddItemGroupsToSection = () => {
    setIsAddingToItemGallery(false);
    dispatch(
      prepareItemsAndGroupsModal(
        true,
        sectionForm.section.items.map((item) => item.itemId),
        ItemsAndGroupsView.GROUPS
      )
    );
  };

  const onAddItemsToGroup = (id: string): void => {
    setGroupIdForAddingItems(id);
    setIsAddingToItemGallery(false);
    const itemGroup = sectionForm.section.items.find((item) => item.itemId === id) as IMenuSectionItemGroup;
    if (itemGroup) {
      prepareMenuItemsModal(
        true,
        itemGroup.items.map((item) => item.itemId),
        MenuItemsView.ITEMS
      );
    }
  };

  const onAddMenuItemToItemGroup = (menuItem: IMenuItem) => {
    dispatch(selectMenuItemForMenuSectionItemGroup(groupIdForAddingItems, menuItem));
    dispatch(selectMenuItemForItemGalleryItemGroup(groupIdForAddingItems, menuItem));
  };

  const onRemoveMenuItemFromItemGroup = (menuItemId: string) => {
    dispatch(deselectMenuItemFromMenuSectionItemGroup(groupIdForAddingItems, menuItemId));
    dispatch(deselectMenuItemFromItemGalleryItemGroup(groupIdForAddingItems, menuItemId));
  };

  const onItemGalleryDragItem = (oldIndex: number, newIndex: number) => {
    dispatch(updateItemGalleryReorderItems(oldIndex, newIndex));
    dispatch(updateItemGalleryItemsSortOrder());
  };

  const onAddItemsToItemGalleryClick = (): void => {
    setGroupIdForAddingItems('');
    setIsAddingToItemGallery(true);
    dispatch(
      prepareItemsAndGroupsModal(
        true,
        menuGalleryForm.menu.itemGallery?.items.map((item) => item.itemId) || [],
        ItemsAndGroupsView.ITEMS
      )
    );
  };

  const onSubmitItemGallery = () => {
    dispatch(
      saveSharedMenuSectionItemGalleryThunk({
        companyId,
        section,
        menu,
        itemGallery: menuGalleryForm.menu.itemGallery!,
        itemGalleryChanges: menuGalleryForm.sectionItemChanges,
      })
    );
  };

  const onSaveItemGroup = () => {
    if (isAddingToItemGallery) {
      dispatch(saveNewItemGroupInItemGallery(companyId, '', itemGroupForm.itemGroup));
    } else {
      dispatch(saveNewItemGroupInSection(companyId, '', itemGroupForm.itemGroup));
    }
  };

  const onAddItemsToItemGalleryGroupClick = (id: string): void => {
    setGroupIdForAddingItems(id);
    setIsAddingToItemGallery(true);
    const itemGroup = menuGalleryForm!.menu.itemGallery?.items.find(
      (item) => item.itemId === id
    ) as IMenuSectionItemGroup;
    if (itemGroup) {
      dispatch(
        prepareMenuItemsModal(
          true,
          itemGroup.items.map((item) => item.itemId),
          MenuItemsView.ITEMS
        )
      );
    }
  };

  return (
    <>
      <AddItemsAndGroupsDialog
        visible={isItemListModalVisible && !isAddingToItemGallery}
        companyId={companyId}
        selectionType={itemListView}
        onMenuItemAdded={(menuItem: IMenuItem) => dispatch(selectMenuItemForMenuSection(menuItem))}
        onItemGroupAdded={(itemGroup: IMenuItemGroup) => dispatch(selectItemGroupForMenuSection(itemGroup))}
        onMenuItemRemoved={(menuItemId: string) => dispatch(deselectMenuItemFromMenuSection(menuItemId))}
        onItemGroupRemoved={(itemGroupId: string) => dispatch(deselectItemGroupFromMenuSection(itemGroupId))}
      />

      <ReorderDialog
        visible={sectionForm.reordering}
        processing={sectionForm.processing}
        title='Reorder Sections'
        items={sectionForm.itemsForReordering}
        setVisible={(visible: boolean) => {
          dispatch(prepareReorderMenuSectionsModal(visible));
        }}
        keyExtractor={(section: IMenuSection) => section.uid}
        labelExtractor={(section: IMenuSection) => section.displayName}
        onMove={(oldIndex: number, newIndex: number) => {
          dispatch(moveMenuSection(oldIndex, newIndex));
        }}
        onSave={(sections: IMenuSection[]) => dispatch(saveReorderedMenuSections(companyId, menu, sections))}
      />

      {sectionForm.deleteRequested && sectionForm.section && (
        <DeleteConfirmationModal
          setShowModal={(show: boolean) => dispatch(showDeleteMenuSectionModal(show, section))}
          item='Section'
          itemName={sectionForm.section.displayName}
          loading={sectionForm.processing}
          handleDelete={() => {
            dispatch(deleteMenuSection(companyId, menu, sectionForm.section));
          }}
        />
      )}

      <AddMenuItemsDialog
        visible={isSharedMenuItemsModalVisible}
        companyId={companyId}
        title='Add Menu Items'
        addMenuItemToSelection={onAddMenuItemToItemGroup}
        removeMenuItemFromSelection={onRemoveMenuItemFromItemGroup}
      />

      {itemGroupForm.editing && (
        <ItemGroupFormModal
          setShowModal={(visible: boolean) => dispatch(prepareNewItemGroupModal(visible))}
          onChangeDisplayName={(displayName: string) => dispatch(updateItemGroupDisplayName(displayName.toString()))}
          onChangeDescription={(description: string) => dispatch(updateItemGroupDescription(description.toString()))}
          onSave={onSaveItemGroup}
          itemGroupForm={itemGroupForm}
        />
      )}

      <AddItemsAndGroupsDialog
        visible={isItemListModalVisible && isAddingToItemGallery}
        companyId={companyId}
        selectionType={itemListView}
        selectedMax={MenuBuilderConfig.itemGalleryLimit}
        onMenuItemAdded={(menuItem: IMenuItem) => dispatch(selectItemForMenuGallery(menuItem))}
        onItemGroupAdded={(itemGroup: IMenuItemGroup) => dispatch(selectItemGroupForMenuGallery(itemGroup))}
        onMenuItemRemoved={(menuItemId: string) => dispatch(deselectItemForMenuGallery(menuItemId))}
        onItemGroupRemoved={(itemGroupId: string) => dispatch(deselectItemGroupForMenuGallery(itemGroupId))}
      />

      {isEditingMenuItemGallery ? (
        <EditItemGalleryPanel
          onItemGalleryDragItem={onItemGalleryDragItem}
          onAddItems={onAddItemsToItemGalleryClick}
          onSubmit={onSubmitItemGallery}
          onAddItemsToGroupClick={onAddItemsToItemGalleryGroupClick}
        />
      ) : (
        <ItemGalleryCollapsiblePanel
          onLocationSettingsClick={() => {
            if (menu.itemGallery) {
              push(
                SharedMenuItemsRoutes.getItemGalleryLocationSettingsRoute(
                  companyId,
                  menuConfigId,
                  menu.itemGallery?._id,
                  menu._id
                )
              );
            }
          }}
          menuItemsList={
            <>
              {menu.itemGallery && menu.itemGallery.items.length > 0 && (
                <div>
                  <MenuItemAndItemGroupList
                    disabled={isEditingMenu || sectionForm.editing || processing}
                    parentDisabled={false}
                    items={menu.itemGallery!.items}
                    getMenuItemLink={(menuItemId: string) =>
                      SharedMenuItemsRoutes.getItemAndModsItem(companyId, menuItemId, menuConfigId, menuId)
                    }
                    getItemGroupLink={(itemGroupId: string) =>
                      SharedMenuItemsRoutes.getItemGroupDetailsRoute(companyId, itemGroupId, menuConfigId, menuId)
                    }
                    isSharedMenu={true}
                  />
                </div>
              )}
            </>
          }
        />
      )}

      {isEditingNewSection && (
        <EditSectionPanel
          isSharedMenu={true}
          sectionForm={sectionForm}
          itemGalleryForm={menuGalleryForm}
          itemGalleryLimit={MenuBuilderConfig.itemGalleryLimit}
          onChangeName={(name: string) => dispatch(updateMenuSectionDisplayName(name.toString()))}
          onChangeDescription={(description: string) => dispatch(updateMenuSectionDescription(description.toString()))}
          onChangeDefaultAppearance={(defaultAppearance: Appearance) =>
            dispatch(updateMenuSectionDefaultAppearance(defaultAppearance))
          }
          onCancel={() => dispatch(setMenuSectionEditMode(false))}
          onSave={() =>
            dispatch(
              saveSharedMenuSectionThunk({
                companyId,
                section,
                menu,
                sectionItemChanges: sectionForm.sectionItemChanges,
                itemGallery: menuGalleryForm.menu.itemGallery!,
                itemGalleryChanges: menuGalleryForm.sectionItemChanges,
              })
            )
          }
          onAddGroup={onAddItemGroupsToSection}
          onAddItems={onAddItemsToSection}
          onRemoveItem={(id: string) => dispatch(removeMenuItemFromMenuSection(id))}
          onRemoveGroupItem={(groupId: string, itemId: string) =>
            dispatch(removeMenuItemFromItemGroupInMenuSection(groupId, itemId))
          }
          onMoveItem={(oldIndex: number, newIndex: number) => dispatch(moveMenuItemInSection(oldIndex, newIndex))}
          onMoveItemInGroup={(groupId: string, oldIndex: number, newIndex: number) =>
            dispatch(moveMenuItemInGroup(groupId, oldIndex, newIndex))
          }
          onAddItemsToGroupClick={onAddItemsToGroup}
          onChangeItemName={(menuItemId: string, displayName: string) =>
            dispatch(updateMenuItemDisplayNameInSection(menuItemId, displayName))
          }
          onChangeGalleryName={(galleryName: string) => dispatch(menuGalleryUpdateGalleryName(galleryName.toString()))}
          onChangeGalleryDescription={(description: string) =>
            dispatch(menuGalleryUpdateDescription(description.toString()))
          }
          onChangeGalleryStatus={(enabled: boolean) =>
            dispatch(menuGalleryUpdateStatus(enabled ? Status.enabled : Status.disabled))
          }
          onAddGalleryItems={onAddItemsToItemGalleryClick}
          onRemoveGalleryItem={(id: string) => dispatch(removeMenuGallerySubitem(id.toString()))}
          onRemoveGalleryGroupItem={(groupId: string, itemId: string) =>
            dispatch(deselectMenuItemFromItemGalleryItemGroup(groupId, itemId))
          }
          onChangeGalleryItemName={(itemId: string, displayName: string) =>
            dispatch(updateMenuGallerySubitemName(itemId, displayName))
          }
          onMoveGalleryItem={onItemGalleryDragItem}
          onMoveGalleryItemInGroup={(groupId: string, sourceIndex: number, destinationIndex: number) =>
            dispatch(updateItemGalleryItemGroupItemsSortOrder(groupId, sourceIndex, destinationIndex))
          }
          onAddGalleryItemsToGroupClick={onAddItemsToItemGalleryGroupClick}
        />
      )}

      {hasMenuSections
        ? menu.sections.map((menuSection) =>
            isEditingThisSection(menuSection) ? (
              <EditSectionPanel
                key={menuSection.uid}
                isSharedMenu={true}
                sectionForm={sectionForm}
                itemGalleryForm={menuGalleryForm}
                itemGalleryLimit={MenuBuilderConfig.itemGalleryLimit}
                onChangeName={(name: string) => dispatch(updateMenuSectionDisplayName(name.toString()))}
                onChangeDescription={(description: string) =>
                  dispatch(updateMenuSectionDescription(description.toString()))
                }
                onChangeDefaultAppearance={(defaultAppearance: Appearance) =>
                  dispatch(updateMenuSectionDefaultAppearance(defaultAppearance))
                }
                onCancel={() => dispatch(setMenuSectionEditMode(false))}
                onAddGroup={onAddItemGroupsToSection}
                onAddItems={onAddItemsToSection}
                onRemoveItem={(id: string) => dispatch(removeMenuItemFromMenuSection(id))}
                onRemoveGroupItem={(itemGroupId: string, menuItemId: string) =>
                  dispatch(removeMenuItemFromItemGroupInMenuSection(itemGroupId, menuItemId))
                }
                onMoveItem={(oldIndex: number, newIndex: number) => dispatch(moveMenuItemInSection(oldIndex, newIndex))}
                onMoveItemInGroup={(groupId: string, oldIndex: number, newIndex: number) =>
                  dispatch(moveMenuItemInGroup(groupId, oldIndex, newIndex))
                }
                onSave={() =>
                  dispatch(
                    saveSharedMenuSectionThunk({
                      companyId,
                      section,
                      menu,
                      sectionItemChanges: sectionForm.sectionItemChanges,
                      itemGallery: menuGalleryForm.menu.itemGallery!,
                      itemGalleryChanges: menuGalleryForm.sectionItemChanges,
                      sectionId: menuSection.uid,
                    })
                  )
                }
                onAddItemsToGroupClick={onAddItemsToGroup}
                onChangeGalleryName={(galleryName: string) =>
                  dispatch(menuGalleryUpdateGalleryName(galleryName.toString()))
                }
                onChangeGalleryDescription={(description: string) =>
                  dispatch(menuGalleryUpdateDescription(description.toString()))
                }
                onChangeItemName={(id: string, name: string) => dispatch(updateMenuItemDisplayNameInSection(id, name))}
                onChangeGalleryStatus={(enabled: boolean) =>
                  dispatch(menuGalleryUpdateStatus(enabled ? Status.enabled : Status.disabled))
                }
                onAddGalleryItems={onAddItemsToItemGalleryClick}
                onRemoveGalleryItem={(id: string) => dispatch(removeMenuGallerySubitem(id.toString()))}
                onRemoveGalleryGroupItem={(groupId: string, itemId: string) =>
                  dispatch(deselectMenuItemFromItemGalleryItemGroup(groupId, itemId))
                }
                onChangeGalleryItemName={(itemId: string, displayName: string) =>
                  dispatch(updateMenuGallerySubitemName(itemId, displayName))
                }
                onMoveGalleryItem={onItemGalleryDragItem}
                onMoveGalleryItemInGroup={(groupId: string, sourceIndex: number, destinationIndex: number) =>
                  dispatch(updateItemGalleryItemGroupItemsSortOrder(groupId, sourceIndex, destinationIndex))
                }
                onAddGalleryItemsToGroupClick={onAddItemsToItemGalleryGroupClick}
              />
            ) : (
              <SectionPanel
                key={menuSection.uid}
                companyId={companyId}
                menuId={menuId}
                section={menuSection}
                isMobile={isMobile}
                expanded={expandedSections.includes(menuSection.uid)}
                disabled={isSectionDisabled}
                onEditClick={() => onEditSectionClick(menuSection)}
                isSharedMenu={true}
                getMenuItemLink={(menuItemId: string) =>
                  SharedMenuItemsRoutes.getItemAndModsItem(companyId, menuItemId, menuConfigId, menuId)
                }
                getItemGroupLink={(itemGroupId: string) =>
                  SharedMenuItemsRoutes.getItemGroupDetailsRoute(companyId, itemGroupId, menuConfigId, menuId)
                }
                onSectionStateChanged={(isExpanded: boolean) =>
                  dispatch(updateMenuSectionViewState(menuSection.uid, isExpanded))
                }
                onLocationSettingsButtonClick={() => {
                  push(
                    SharedMenuItemsRoutes.getMenuSectionLocationSettingsRoute(
                      companyId,
                      menuConfigId,
                      menuId,
                      menuSection.uid
                    )
                  );
                }}
                onDeleteClick={() => dispatch(showDeleteMenuSectionModal(true, menuSection))}
              />
            )
          )
        : !isEditingNewSection && (
            <EmptyResult
              title='Add Menu Sections'
              paragraph='You can create multiple sections within a menu to group similar items together, such as appetizers, mains or sides.'
            />
          )}
    </>
  );
};
