import { ICompanyIntegrations } from '@ready/dashboardv2api.contracts';

export const companyIntegrationsState: ICompanyIntegrations = {
  square: {
    auth: {
      tokenInfo: {
        accessToken: '',
        expiresAt: '',
        refreshToken: '',
      },
    },
  },
};
