import { IClientLocation, IPagination } from '@ready/dashboardv2api.contracts';
import { IPagedResponse, ITableConfiguration } from '@ready/table.core';

export interface IUnsavedMappedTableConfiguration {
  [key: string]: string;
}

export interface TableConfigurationList extends IPagedResponse<ITableConfiguration> {
  paginationAvailable: boolean;
  loading: boolean;
  syncLoading: boolean;
  syncComplete: boolean;
  initialLoading: boolean;
  pagination: IPagination;
  disabled: boolean;
}

export interface ITableConfigurationState {
  changed: boolean;
  error: boolean;
  query: string;
  page: string;
  tableConfigurations: TableConfigurationList;
  unsavedMappedTargetCodes: IUnsavedMappedTableConfiguration;
  unsavedMappedPosOrderTypes: IUnsavedMappedTableConfiguration;
  unsavedMappedOrderExperienceTypes: IUnsavedMappedTableConfiguration;
  location: IClientLocation | null;
}

export const tableConfigurationList = {
  changed: false,
  error: false,
  query: '',
  page: '',
  tableConfigurations: {
    results: [],
    totalCount: 0,
    pagination: {
      start: 0,
      length: 0,
      limit: 0,
      total: 0,
    },
    paginationAvailable: false,
    loading: false,
    syncLoading: false,
    syncComplete: false,
    initialLoading: true,
    disabled: false,
  },
  unsavedMappedTargetCodes: {},
  unsavedMappedPosOrderTypes: {},
  unsavedMappedOrderExperienceTypes: {},
  location: null,
} as ITableConfigurationState;
