import { RootState } from 'redux/store';
import { ICreateEditSharedMenuItemState } from './createEditItem/createEditItemSlice';
import { IEditAssignedLocationsState } from './editAssignedLocations/assignLocationsSlice';
import { ITableState } from './table/tableSlice';

export const selectCreateEditItemState = (state: RootState): ICreateEditSharedMenuItemState =>
  state.sharedMenuItems.item;

export const selectEditAssignedLocationsState = (state: RootState): IEditAssignedLocationsState =>
  state.sharedMenuItems.locations;

export const selectTableState = (state: RootState): ITableState => state.sharedMenuItems.table;
