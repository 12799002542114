import { OrdersService } from '../../../services/ordersService';
import { OrderLocationPagination } from '../../../types/OrderLocationPagination.interface';
import { toastErrorState } from '../uiActions/responseStateActions';
import { ORDERS_ACTIONS } from './types';

export const loadLocations =
  (companyId: string, query: string = '', page: OrderLocationPagination) =>
  async (dispatch: any) => {
    dispatch({ type: ORDERS_ACTIONS.LOAD_ORDERS_LOCATIONS_BEGIN });
    try {
      const result = await OrdersService.getOrdersLocationData(companyId, query, page);
      const { items, start, length, limit, total } = result;
      const locationsPayload = {
        locations: items,
        start,
        length,
        limit,
        total,
      };
      dispatch({
        type: ORDERS_ACTIONS.LOAD_ORDERS_LOCATIONS_SUCCESS,
        payload: locationsPayload,
      });
    } catch (error) {
      dispatch({ type: ORDERS_ACTIONS.LOAD_ORDERS_LOCATIONS_ERROR });
      dispatch(toastErrorState(error.status, error.message));
    }
  };
