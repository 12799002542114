import React, { ChangeEvent } from 'react';
import { UseFieldArrayReturn, Controller, useFormContext, FieldErrors } from 'react-hook-form';
import { zonedTimeToSystemEquivalent } from 'utils/dateUtils/zonedTimeToSystemEquivalent';
import { DatePickerValue } from 'components/DatePicker/DatePicker';
import DateTimeInput from 'components/DateTimeInput/DateTimeInput';
import { FormControl, FormControlStacked } from 'components/Form';
import Checkbox from 'components/Checkbox/Checkbox';
import TimePicker from 'components/TimePicker/TimePicker';
import IconButton from 'components/IconButton/IconButton';
import TextIcon, { Icon } from 'components/Icon/TextIcon';
import LinkButton from 'components/LinkButton/LinkButton';
import styles from './SpecialHours.module.scss';
import { OrderHoursFormState } from '../../EditPanel';
import { ISpecialOrderHours } from '@ready/dashboardv2api.contracts';
import moment from 'moment';
import InputError from 'components/InputError/InputError';
import { validateEndTime, validateStartTime, formatTimeValue, formatTimeInput } from '../../../utils';

interface SpecialHoursProps {
  fieldArray: UseFieldArrayReturn<OrderHoursFormState, 'specialHours'>;
  formErrors: FieldErrors<OrderHoursFormState>;
  isSaving: boolean;
  locationTimezone?: string;
}

interface SpecialHoursFields extends ISpecialOrderHours {
  id?: string;
}

const isDuplicateDate = (date: string, index: number, allDates: string[]): boolean => {
  const formattedDate = moment(date).format('YYYY-MM-DD');
  return allDates.some(
    (currentDate, currentIndex) => moment(currentDate).format('YYYY-MM-DD') === formattedDate && currentIndex !== index
  );
};

const isClosedWithoutSettingTimes = (closed?: boolean, startTime?: string, endTime?: string) =>
  closed && !startTime && !endTime;

const defaultStartTime = '00:00'; // 12 a.m
const defaultEndTime = '23:59';

// This component reads from the same form state in two different ways

// 1- 'fields' coming from react-hook-form useFieldArray
// 'fields' does not reflect right away. Also, fields adds an id for each
// array item that can be used as a key and has built in way to add, remove array items

// 2- 'watchedSpecialHours' to reflect changes right away

export const SpecialHours: React.FC<SpecialHoursProps> = ({ fieldArray, locationTimezone, formErrors, isSaving }) => {
  const { control, watch, setValue } = useFormContext<OrderHoursFormState>(); // useFormContext to access form methods
  const { fields, append, remove } = fieldArray;
  const formFields = fields as unknown as SpecialHoursFields[];
  const watchedSpecialHours = watch('specialHours') as SpecialHoursFields[];
  const hasSpecialHours = !!watchedSpecialHours && watchedSpecialHours.length > 0;

  const validateDate = (newDate: string, index: number) => {
    const allDates = watchedSpecialHours.map((hour) => hour.date);
    return (
      !isDuplicateDate(newDate, index, allDates) ||
      'This date has already been selected. Please choose a different date.'
    );
  };

  return (
    <div className={styles.specialHoursContainer}>
      <div className={styles.specialHoursTitleRow}>
        <span>
          <b>Special Hours </b>
        </span>
      </div>
      {hasSpecialHours && (
        <>
          {formFields &&
            formFields?.map((field, index) => {
              const checkboxName = `specialHours[${index}].closed`;
              const startTimeName = `specialHours[${index}].start`;
              const endTimeName = `specialHours[${index}].end`;
              const dateName = `specialHours[${index}].date`;

              if (
                isClosedWithoutSettingTimes(
                  watchedSpecialHours[index]?.closed,
                  watchedSpecialHours[index]?.start,
                  watchedSpecialHours[index]?.end
                )
              ) {
                setValue(startTimeName, defaultStartTime);
                setValue(endTimeName, defaultEndTime);
              }
              const rezonedStartDate =
                watchedSpecialHours[index]?.date && locationTimezone
                  ? zonedTimeToSystemEquivalent(new Date(watchedSpecialHours[index]?.date), locationTimezone)
                  : new Date(watchedSpecialHours[index]?.date);
              const dateValue: DatePickerValue = {
                startMonth: rezonedStartDate,
                endMonth: rezonedStartDate,
                startDate: rezonedStartDate,
                endDate: rezonedStartDate,
              };

              return (
                <div className={styles.dateTimeRow} key={field?.id || index}>
                  <div className={styles.dateCheckboxColumn}>
                    <div className={styles.dateWrapper}>
                      <Controller
                        control={control}
                        name={dateName}
                        rules={{
                          validate: (value: string) => validateDate(value, index),
                        }}
                        render={({ field }) => {
                          return (
                            <DateTimeInput
                              {...field}
                              placeholder='DD/MM/YYYY'
                              value={dateValue}
                              type='date'
                              onChange={(value) => field.onChange(value?.startDate?.toISOString())}
                              withError={formErrors?.specialHours && !!formErrors.specialHours[index]?.date}
                              disabled={isSaving}
                            />
                          );
                        }}
                      />
                    </div>

                    {formErrors?.specialHours && formErrors?.specialHours[index]?.date?.message && (
                      <InputError message={formErrors?.specialHours[index]?.date?.message || ''} />
                    )}

                    <div>
                      <Controller
                        control={control}
                        name={checkboxName}
                        render={({ field }) => (
                          <FormControl hideFormLabel>
                            <FormControlStacked>
                              <Checkbox
                                checked={watchedSpecialHours[index]?.closed}
                                boldLabel={'Closed'}
                                label=''
                                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                  field.onChange(event.target.checked)
                                }
                                loading={isSaving}
                              />
                            </FormControlStacked>
                          </FormControl>
                        )}
                      />
                    </div>
                  </div>

                  <div className={styles.timeSlotColumn}>
                    {watchedSpecialHours[index]?.closed ? (
                      <div className={styles.isClosedContainer}>
                        <span className={styles.isClosed}>Closed</span>
                      </div>
                    ) : (
                      <>
                        <div className={styles.timePicker}>
                          <div>
                            <Controller
                              control={control}
                              name={startTimeName}
                              rules={{
                                validate: () =>
                                  validateStartTime(
                                    watchedSpecialHours[index].start || '',
                                    watchedSpecialHours[index].end || ''
                                  ),
                              }}
                              render={({ field }) => (
                                <FormControl hideFormLabel>
                                  <TimePicker
                                    showSeconds={false}
                                    onChange={(value: string) => field.onChange(formatTimeInput(value))}
                                    darkBorder
                                    containerPadding='none'
                                    placeholder='12:00:00'
                                    value={formatTimeValue(field.value)}
                                    hasError={!!formErrors?.specialHours && !!formErrors?.specialHours[index]?.start}
                                    disabled={isSaving}
                                  />
                                </FormControl>
                              )}
                            />
                            <div className={styles.timeError}>
                              {formErrors?.specialHours && formErrors?.specialHours[index]?.start?.message && (
                                <InputError message={formErrors?.specialHours[index]?.start?.message || ''} />
                              )}
                            </div>
                          </div>

                          <span className={styles.timeDivider}>-</span>
                          <div>
                            <Controller
                              control={control}
                              name={endTimeName}
                              rules={{
                                validate: () => validateEndTime(watchedSpecialHours[index].end || ''),
                              }}
                              render={({ field }) => (
                                <FormControl hideFormLabel>
                                  <TimePicker
                                    value={formatTimeValue(field?.value)}
                                    onChange={(value: string) => field.onChange(formatTimeInput(value))}
                                    showSeconds={false}
                                    darkBorder
                                    containerPadding='none'
                                    placeholder='12:00:00'
                                    hasError={!!formErrors?.specialHours && !!formErrors?.specialHours[index]?.end}
                                    disabled={isSaving}
                                  />
                                </FormControl>
                              )}
                            />
                            <div className={styles.timeError}>
                              {formErrors?.specialHours && formErrors?.specialHours[index]?.end?.message && (
                                <InputError message={formErrors?.specialHours[index]?.end?.message || ''} />
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <IconButton
                      disabled={false}
                      onClick={() => remove(index)}
                      additionalClassName={styles.clearButton}
                      loading={isSaving}
                    >
                      <TextIcon icon={Icon.RemoveCircle} additionalStyles={styles.clearIcon} />
                    </IconButton>
                  </div>
                </div>
              );
            })}
        </>
      )}
      <div className={styles.addDayRow}>
        <LinkButton
          onClick={() => append({ date: new Date()?.toISOString(), start: '', end: '', closed: false, offset: '' })}
          disabled={isSaving}
        >
          + Add Days
        </LinkButton>
      </div>
    </div>
  );
};
