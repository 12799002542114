import React from 'react';
import classNames from 'classnames';
import { Alignment } from '../../types/Alignment.type';
import styles from './Divider.module.scss';

/**
 * size/color can be overridden by setting it to 'custom' and passing the
 * desired styles through the 'additionalStyles' prop
 */
interface DividerProps {
  size?: 'custom' | 'tiny' | 'small' | 'medium' | 'large' | 'huge';
  color?: 'custom' | 'lightGray' | 'gray';
  direction?: Alignment;
  additionalContainerStyles?: string;
  additionalStyles?: string;
}

const Divider = ({
  size = 'medium',
  color = 'gray',
  direction = 'horizontal',
  additionalContainerStyles,
  additionalStyles,
}: DividerProps): JSX.Element => {
  const containerClassName = classNames(
    styles.container,
    size !== 'custom' && styles[size],
    styles[direction],
    additionalContainerStyles
  );

  const dividerClassName = classNames(
    styles.divider,
    color !== 'custom' && styles[color],
    styles[direction],
    additionalStyles
  );

  return (
    <div className={containerClassName}>
      <hr className={dividerClassName} />
    </div>
  );
};

export default Divider;
