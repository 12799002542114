import { RoleFormValidation } from '../../securityRoles/redux/initialStates/securityRoleForm';
import { FieldValidation } from '../../types/FieldValidation.interface';

const validateSecurityRoleForm = (name: string): RoleFormValidation => {
  let isValid = true;

  let nameValidation: FieldValidation = {
    error: '',
    hasError: false,
  };

  if (!name) {
    nameValidation = {
      error: 'Security Role Name is required.',
      hasError: true,
    };

    isValid = false;
  }

  return {
    name: nameValidation,
    isValid,
  };
};

export default validateSecurityRoleForm;
