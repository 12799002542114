import { combineReducers } from 'redux';
import { menuLocationSettingsSlice } from './menu/redux/slice';
import { itemGalleryLocationSettingsSlice } from './itemGallery/redux/slice';
import { sectionLocationSettingsSlice } from './section/redux/slice';

export const locationSettingsReducer = combineReducers({
  menu: menuLocationSettingsSlice.reducer,
  itemGallery: itemGalleryLocationSettingsSlice.reducer,
  section: sectionLocationSettingsSlice.reducer,
});
