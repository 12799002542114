import { IBulkEditMenuItemRequest, IMenuItemRequest } from '@ready/menu.core';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { setFormIsDirty } from 'redux/actions/uiActions/formStateActions';
import { set200Toast, toastErrorState } from 'redux/actions/uiActions/responseStateActions';
import { getTemplateChildMenuItems } from 'sharedMenuItems/pages/itemAssignedLocations/redux/thunks';
import {
  bulkEditLocationItemSettings,
  editItemLocationSettings,
  getMenuItemAtLocation,
} from 'sharedMenuItems/service/assignedLocationService';

const editSharedMenuItemLocationSettings = createAsyncThunk(
  'sharedMenuItems/locations/editItemSettings',
  async (args: { companyId: string; locationId: string; request: IMenuItemRequest }, thunkAPI) => {
    try {
      await editItemLocationSettings(args.companyId, args.locationId, args.request);
      thunkAPI.dispatch(set200Toast('Success! Changes saved.'));
      thunkAPI.dispatch(
        getTemplateChildMenuItems({ companyId: args.companyId, itemId: args.request.parentTemplateId || '' })
      );
    } catch (error) {
      thunkAPI.dispatch(toastErrorState(error.status, error.message));
    }
  }
);

const bulkEditLocationsThunk = createAsyncThunk(
  'sharedMenuItems/locations/bulkEditSettings',
  async (args: { companyId: string; templateItemId: string; request: IBulkEditMenuItemRequest }, thunkAPI) => {
    try {
      await bulkEditLocationItemSettings(args.companyId, args.templateItemId, args.request);
      thunkAPI.dispatch(set200Toast('Success! Changes saved.'));
      thunkAPI.dispatch(setFormIsDirty(false));
      thunkAPI.dispatch(getTemplateChildMenuItems({ companyId: args.companyId, itemId: args.templateItemId }));
      return;
    } catch (error) {
      thunkAPI.dispatch(toastErrorState(error.status, error.message));
    }
  }
);

const getCurrentItemAtLocation = createAsyncThunk(
  'sharedMenuItems/locations/getCurrentItem',
  async (args: { companyId: string; locationId: string; menuItemId: string }, thunkAPI) => {
    try {
      return await getMenuItemAtLocation(args.companyId, args.locationId, args.menuItemId);
    } catch (error) {
      thunkAPI.dispatch(toastErrorState(error.status, error.message));
    }
  }
);

export { editSharedMenuItemLocationSettings, bulkEditLocationsThunk, getCurrentItemAtLocation };
