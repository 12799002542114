import { ISmbTax } from 'companySettings/types/SmbTaxTypes.type';
import { pageErrorState, set200Toast } from '../../redux/actions/uiActions/responseStateActions';
import { CompanyIntegrations } from '../services/CompanyIntegrations';
import { TaxService } from '../services/TaxService';

export const COMPANY_SETTINGS_ACTIONS = {
  GET_COMPANY_INTEGRATIONS_BEGIN: 'GET_COMPANY_INTEGRATIONS_BEGIN',
  GET_COMPANY_INTEGRATIONS_SUCCESS: 'GET_COMPANY_INTEGRATIONS_SUCCESS',
  GET_COMPANY_INTEGRATIONS_ERROR: 'GET_COMPANY_INTEGRATIONS_ERROR',

  GET_TAXES_BEGIN: 'GET_TAXES_BEGIN',
  GET_TAXES_SUCCESS: 'GET_TAXES_SUCCESS',
  GET_TAXES_ERROR: 'GET_TAXES_ERROR',

  CREATE_TAX_SUCCESS: 'CREATE_TAX_SUCCESS',
  UPDATE_TAX_SUCCESS: 'UPDATE_TAX_SUCCESS',
  SELECT_TAX_TO_MODIFY: 'SELECT_TAX_TO_MODIFY',
  DELETE_TAX_SUCCESS: 'DELETE_TAX_SUCCESS',
  SET_TAXES_LOADING: 'SET_TAXES_LOADING',

  GET_SMB_LOCATIONS_SUCCESS: 'GET_SMB_LOCATIONS_SUCCESS',
};

export const getCompanyIntegrations = (companyId: string) => async (dispatch: any) => {
  dispatch({
    type: COMPANY_SETTINGS_ACTIONS.GET_COMPANY_INTEGRATIONS_BEGIN,
  });
  try {
    const integrations = await CompanyIntegrations.getIntegrations(companyId);
    dispatch({
      type: COMPANY_SETTINGS_ACTIONS.GET_COMPANY_INTEGRATIONS_SUCCESS,
      payload: integrations,
    });
  } catch (error) {
    dispatch(pageErrorState(error.status, error.message));
  }
};

export const getTaxes = (companyId: string) => async (dispatch: any) => {
  dispatch({
    type: COMPANY_SETTINGS_ACTIONS.GET_TAXES_BEGIN,
  });

  try {
    const taxes = await TaxService.getTaxesForCompany(companyId);
    dispatch({
      type: COMPANY_SETTINGS_ACTIONS.GET_TAXES_SUCCESS,
      payload: taxes,
    });
  } catch (error) {
    dispatch(pageErrorState(error.status, error.message));
    dispatch({
      type: COMPANY_SETTINGS_ACTIONS.GET_TAXES_ERROR,
    });
  }
};

export const createTax = (companyId: string, tax: Omit<ISmbTax, '_id'>) => async (dispatch: any) => {
  dispatch(setTaxesLoading(true));
  try {
    const newTax = await TaxService.createTax(companyId, tax);

    dispatch({
      type: COMPANY_SETTINGS_ACTIONS.CREATE_TAX_SUCCESS,
      payload: newTax,
    });
    dispatch(set200Toast('Success! New tax created.'));
  } catch (error) {
    dispatch(pageErrorState(error.status, error.message));
  } finally {
    dispatch(setTaxesLoading(false));
  }
};

export const updateTax = (companyId: string, tax: ISmbTax) => async (dispatch: any) => {
  dispatch(setTaxesLoading(true));
  try {
    const updatedTax = await TaxService.updateTax(companyId, tax);

    dispatch({
      type: COMPANY_SETTINGS_ACTIONS.UPDATE_TAX_SUCCESS,
      payload: updatedTax,
    });
    dispatch(set200Toast(`Success! Changes saved.`));
  } catch (error) {
    dispatch(pageErrorState(error.status, error.message));
  } finally {
    dispatch(setTaxesLoading(false));
  }
};

export const selectTax = (tax?: ISmbTax) => (dispatch: any) => {
  dispatch({
    type: COMPANY_SETTINGS_ACTIONS.SELECT_TAX_TO_MODIFY,
    payload: tax,
  });
};

export const deleteTax = (companyId: string, tax: ISmbTax) => async (dispatch: any) => {
  dispatch(setTaxesLoading(true));
  try {
    await TaxService.deleteTax(companyId, tax);

    dispatch({
      type: COMPANY_SETTINGS_ACTIONS.DELETE_TAX_SUCCESS,
      payload: tax._id,
    });
    dispatch(set200Toast('Tax deleted.'));
  } catch (error) {
    dispatch(pageErrorState(error.status, error.message));
  } finally {
    dispatch(setTaxesLoading(false));
  }
};

export const setTaxesLoading = (loading: boolean) => (dispatch: any) => {
  dispatch({
    type: COMPANY_SETTINGS_ACTIONS.SET_TAXES_LOADING,
    payload: loading,
  });
};

export const getSmbLocations = (companyId: string) => async (dispatch: any) => {
  try {
    const locations = await TaxService.getSmbLocations(companyId);
    dispatch({
      type: COMPANY_SETTINGS_ACTIONS.GET_SMB_LOCATIONS_SUCCESS,
      payload: locations,
    });
  } catch (error) {
    dispatch(pageErrorState(error.status, error.message));
  }
};
