import { IEmployee } from '@ready/employee.core';
import config from '../../config';
import executeApiAsync from '../../services/utils/executeApiAsync';
import { Option } from '../../components/SelectFilter/SelectFilter';
import { convertEmployeeToOption, convertPosItemToOption, PosItem } from 'utils/mappers/toOption';

export default class BrinkService {
  static getEmployee = async (companyId: string, locationId: string, externalId: string): Promise<Option> => {
    const employee = await executeApiAsync<IEmployee>(
      `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/employees/external/${externalId}`
    );
    return convertEmployeeToOption(employee);
  };

  static getRevenueCenters = async (
    locationId: string,
    brinkLocationId: string,
    brinkApiUrl: string
  ): Promise<Option[]> => {
    const queryString = BrinkService.buildQueryStringForBrinkApi(brinkLocationId, brinkApiUrl);
    const revenueCenters = await executeApiAsync<PosItem[]>(
      `${config.readyApiLegacyEndpoint}/admins/locations/${locationId}/revenueCenters?${queryString}`
    );
    return revenueCenters.map(convertPosItemToOption);
  };

  private static buildQueryStringForBrinkApi = (brinkLocationId: string, brinkApiUrl: string): string => {
    return `posLocationId=${encodeURIComponent(brinkLocationId)}&apiUrl=${encodeURIComponent(brinkApiUrl)}`;
  };
}
