import { initialChecksReportState } from './initialState';
import { createSlice } from '@reduxjs/toolkit';
import {
  exportChecksListThunk,
  fetchChecksListThunk,
  fetchCheckWidgetsThunk,
  fetchPermittedLocationsThunk,
} from './thunks';
import {
  exportChecksListReducers,
  fetchChecksListReducers,
  fetchCheckWidgetsReducers,
  fetchPermittedLocationsReducers,
} from './reducers';

const checksReportSlice = createSlice({
  name: 'checksReport',
  initialState: initialChecksReportState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchChecksListThunk.pending, fetchChecksListReducers.pending);
    builder.addCase(fetchChecksListThunk.fulfilled, fetchChecksListReducers.fulfilled);
    builder.addCase(fetchChecksListThunk.rejected, fetchChecksListReducers.rejected);

    builder.addCase(exportChecksListThunk.pending, exportChecksListReducers.pending);
    builder.addCase(exportChecksListThunk.fulfilled, exportChecksListReducers.fulfilled);
    builder.addCase(exportChecksListThunk.rejected, exportChecksListReducers.rejected);

    builder.addCase(fetchPermittedLocationsThunk.pending, fetchPermittedLocationsReducers.pending);
    builder.addCase(fetchPermittedLocationsThunk.fulfilled, fetchPermittedLocationsReducers.fulfilled);
    builder.addCase(fetchPermittedLocationsThunk.rejected, fetchPermittedLocationsReducers.rejected);

    builder.addCase(fetchCheckWidgetsThunk.pending, fetchCheckWidgetsReducers.pending);
    builder.addCase(fetchCheckWidgetsThunk.fulfilled, fetchCheckWidgetsReducers.fulfilled);
    builder.addCase(fetchCheckWidgetsThunk.rejected, fetchCheckWidgetsReducers.rejected);
  },
});

const { actions: checksReportActions, reducer: reportReducer } = checksReportSlice;
export { checksReportActions, reportReducer };
