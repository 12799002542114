import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IPagination } from '@ready/dashboardv2api.contracts';
import InfoCardColumn from '../../components/InfoCard/InfoCardColumn';
import InfoCardDataRow from '../../components/InfoCard/InfoCardDataRow';
import { useSearchParams } from '../../hooks/useSearchParams';
import excludeParamFromSearch from '../../utils/urlUtils/excludeParamFromSearch';

const InfoCardPagination = (props: IPagination) => {
  const { start = 0, length = 0, total = 0 } = props;

  const emptyResult = length === 0;
  const pageLimit = !emptyResult ? start + length : 0;
  const isFirstPage = start === 0;
  const isLastPage = pageLimit === total;
  const startShow = emptyResult ? 0 : start + 1;
  const totalShow = emptyResult ? 0 : total;

  const searchParams = useSearchParams();
  const { page = 1 } = searchParams;
  const location = useLocation();
  const { pathname } = location;

  // get all url params but the page
  const params = excludeParamFromSearch(searchParams, 'page');
  const initialPath = `${pathname}?${params}`;
  const prevLink = `${initialPath}page=${parseInt(page) - 1}`;
  const nextLink = `${initialPath}page=${parseInt(page) + 1}`;

  return (
    <div className='info-card-pagination'>
      <InfoCardColumn width={50}>
        <InfoCardDataRow inFooter>
          <div className='info__card__data__row__result__count'>
            {startShow}-{pageLimit} of <span className='info__card__data__row__result__count__total'>{totalShow}</span>
          </div>
        </InfoCardDataRow>
      </InfoCardColumn>

      <InfoCardColumn width={50}>
        <InfoCardDataRow inFooter>
          <div className='info__card__data__row__result__count'>
            {isFirstPage || emptyResult ? (
              <span className='info__card__navigation__link--disabled'>Prev</span>
            ) : (
              <Link className='info__card__navigation__link' to={prevLink}>
                Prev
              </Link>
            )}

            <span className='info__card__pagination__separator'>|</span>

            {isLastPage || emptyResult ? (
              <span className='info__card__navigation__link--disabled'>Next</span>
            ) : (
              <Link className='info__card__navigation__link' to={nextLink}>
                Next
              </Link>
            )}
          </div>
        </InfoCardDataRow>
      </InfoCardColumn>
    </div>
  );
};

export default InfoCardPagination;
