import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
// hooks
import useModal from '../../hooks/useModal';
// actions
import { handleAdminAccess, adminDetailsCleanUp } from '../redux/AdminDetailsActions';
// components
import LayoutContent from '../../components/AppLayout/LayoutContent';
import { DetailLayout, DetailElement } from '../../components/DetailLayout';
import AdminEditStatusModal from './AdminEditStatusModal';
// redux
import { connect, ConnectedProps } from 'react-redux';
import { getAdminDetails } from '../redux/AdminDetailsActions';
import { resetAdminIdTransaction } from '../redux/AdminFormActions';
import { IAdminDetailsState } from '../redux/AdminDetailsState';
import { AppState } from '../../redux/initialStates/AppState';
import ActionHeader from '../../components/ActionHeader/ActionHeader';
import { Panel } from '../../components/PanelLayout';
import styles from './AdminsViewPage.module.scss';
import StatusIndicator from '../../components/StatusIndicator/StatusIndicator';
import { set200Toast } from '../../redux/actions/uiActions/responseStateActions';

export type AdminsViewPageProps = ReduxProps & {
  adminDetails: IAdminDetailsState;
};

const AdminsViewPage = (props: AdminsViewPageProps) => {
  const {
    adminDetails,
    getAdminDetails,
    resetAdminIdTransaction,
    handleAdminAccess,
    adminDetailsCleanUp,
    set200Toast,
  } = props;
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { admin, loading } = adminDetails;
  const { showModal, setShowModal, setLoadingModal } = useModal();

  // get admin details
  React.useEffect(() => {
    async function getAdminDetailsPage() {
      getAdminDetails(id);
    }
    getAdminDetailsPage();
    resetAdminIdTransaction();
  }, [getAdminDetails, id, resetAdminIdTransaction]);

  // const titleExtra = admin && admin.last_login
  //   ? <p><b>Last login: </b>{moment(admin.last_login).format('MMM DD, YYYY, h:mm a')}</p>
  //   : null;
  // TODO: keep track of last login date in new collection
  const titleExtra = null;

  const adminEditAction = () => history.push(`/ready/admins/${id}/edit`);
  const adminStatusAction = () => setShowModal(true);

  const handleAdminAccessAction = async (enabled: boolean) => {
    if (admin) {
      handleAdminAccess(admin.id, enabled);
      setLoadingModal(true);
    }
  };

  React.useEffect(() => {
    return () => {
      adminDetailsCleanUp();
    };
  }, [adminDetailsCleanUp]);

  React.useEffect(() => {
    if (adminDetails.accessChange) {
      set200Toast();
      history.push('/ready/admins');
    }
  }, [adminDetails.accessChange, history, set200Toast]);

  React.useEffect(() => {
    if (adminDetails.error) {
      setLoadingModal(false);
      setShowModal(false);
    }
  }, [adminDetails.error, setLoadingModal, setShowModal]);

  return (
    <LayoutContent title='Admin Details' containerType='within' pageId='admins-view-page'>
      {admin && (
        <>
          <ActionHeader
            text={admin.firstName + ' ' + admin.lastName}
            backLinkTo={'/ready/admins'}
            actionButtons={[
              {
                label: admin.enabled ? 'Deactivate' : 'Activate',
                onClick: adminStatusAction,
                variant: 'secondary-gray-bg',
              },
              {
                label: 'Edit Admin',
                onClick: adminEditAction,
                variant: 'primary',
              },
            ]}
          />
          <Panel additionalStyles={styles.adminContainer}>
            <DetailLayout title='Profile' titleExtra={titleExtra}>
              <DetailElement label='Status'>
                <StatusIndicator active={admin.enabled} activeLabel={'Active'} inactiveLabel={'Inactive'} />
              </DetailElement>
              <DetailElement label='First Name'>{admin.firstName}</DetailElement>
              <DetailElement label='Last Name'>{admin.lastName}</DetailElement>
              <DetailElement label='Email Address'>{admin.email}</DetailElement>
            </DetailLayout>
          </Panel>
          {showModal && (
            <AdminEditStatusModal
              admin={admin}
              loading={loading}
              handlePrimary={() => handleAdminAccessAction(!admin.enabled)}
              setShowModal={setShowModal}
            />
          )}
        </>
      )}
    </LayoutContent>
  );
};

const mapStateToProps = (state: AppState) => ({
  adminDetails: state.admins.adminDetails,
});

const actionCreators = {
  getAdminDetails,
  resetAdminIdTransaction,
  handleAdminAccess,
  adminDetailsCleanUp,
  set200Toast,
};

const connector = connect(mapStateToProps, actionCreators);
type ReduxProps = ConnectedProps<typeof connector>;
export default connector(AdminsViewPage);
