export const COMPANY_LOCATIONS_ACTIONS = {
  PREPARE_COMPANY_LOCATIONS_MODAL: 'PREPARE_COMPANY_LOCATIONS_MODAL',

  SET_COMPANY_LOCATIONS_QUERY: 'SET_COMPANY_LOCATIONS_QUERY',

  LOAD_COMPANY_LOCATIONS_BEGIN: 'LOAD_COMPANY_LOCATIONS_BEGIN',
  LOAD_COMPANY_LOCATIONS_SUCCESS: 'LOAD_COMPANY_LOCATIONS_SUCCESS',
  LOAD_COMPANY_LOCATIONS_ERROR: 'LOAD_COMPANY_LOCATIONS_ERROR',

  LOAD_MORE_COMPANY_LOCATIONS_BEGIN: 'LOAD_MORE_COMPANY_LOCATIONS_BEGIN',
  LOAD_MORE_COMPANY_LOCATIONS_SUCCESS: 'LOAD_MORE_COMPANY_LOCATIONS_SUCCESS',
  LOAD_MORE_COMPANY_LOCATIONS_NO_RESULTS: 'LOAD_MORE_COMPANY_LOCATIONS_NO_RESULTS',
  LOAD_MORE_COMPANY_LOCATIONS_ERROR: 'LOAD_MORE_COMPANY_LOCATIONS_ERROR',
};
