import React from 'react';
import { IMenu, IScheduleLink, Status } from '@ready/menu.core';
import { Link } from 'react-router-dom';
import OverflowButton from '../../../components/OverflowButton/OverflowButton';
import { ILink } from '../../redux/MenusState';
import InfoSchedule from '../shared/InfoSchedule';
import DisabledBadge from '../../components/DisabledBadge';
import styles from './MenuEntryPanel.module.scss';
import { DropDownButtonOption } from 'components/DropDownButton/DropDownButton';

export interface MenuEntryPanelProps {
  menu: IMenu;
  linkTo: string;
  onEditClick: (menu: IMenu) => void;
  onDeleteClick: (menu: IMenu) => void;
  scheduleLink?: IScheduleLink<ILink>;
  disabled: boolean;
  isSharedMenu?: boolean;
}

export interface MenuEntryOptionWrapperProps {
  children: React.ReactNode;
  linkTo: string;
  disabled: boolean;
}

const MenuEntryOptionWrapper = (props: MenuEntryOptionWrapperProps) => {
  const { children, linkTo, disabled } = props;

  return disabled ? (
    <div className={styles.menuEntryLink}>{children}</div>
  ) : (
    <Link to={linkTo} className={styles.menuEntryLink}>
      {children}
    </Link>
  );
};

const MenuEntryPanel = (props: MenuEntryPanelProps) => {
  const {
    menu,
    menu: { displayName, status },
    linkTo,
    scheduleLink,
    onEditClick,
    onDeleteClick,
    disabled,
    isSharedMenu = false,
  } = props;

  const displayNameStyle = status ? styles.menuEntryDisplayName : styles.menuEntryDisplayNameDisabled;

  const overflowOptions: DropDownButtonOption[] = [
    {
      label: 'Quick Edit',
      primary: false,
      onClick: () => onEditClick(menu),
    },
    {
      label: 'Delete Menu',
      primary: true,
      hidden: isSharedMenu,
      onClick: () => onDeleteClick(menu),
    },
  ];

  return (
    <div className={styles.menuEntry}>
      <div className={styles.menuEntryDetails}>
        <MenuEntryOptionWrapper linkTo={linkTo} disabled={disabled}>
          <div className={styles.menuEntryDisplayNameWrapper}>
            <p className={displayNameStyle}>{displayName}</p>
            {status === Status.disabled && <DisabledBadge />}
          </div>
          <InfoSchedule variant='secondary' scheduleLink={scheduleLink} />
        </MenuEntryOptionWrapper>
      </div>
      <div>
        <OverflowButton options={overflowOptions} disabled={disabled} />
      </div>
    </div>
  );
};

export default MenuEntryPanel;
