import React from 'react';
import { connect } from 'react-redux';
import { updateQuery } from '../../redux/actions/contextSwitcherActions/contextSwitcherActions';
import { Action } from '../../redux/types';
import SearchInput from '../SearchInput/SearchInput';

export interface ContextSwitcherSearchProps {
  query: string;
  updateQuery: (query: string) => Action;
}

const ContextSwitcherSearch = (props: ContextSwitcherSearchProps) => {
  const { query, updateQuery } = props;

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateQuery(event.target.value);
  };

  return (
    <SearchInput value={query} placeholder='Search Companies' fullWidth onChange={handleQueryChange} autoFocus={true} />
  );
};

const mapStateToProps = (state: any) => ({
  query: state.contextSwitcher.query,
});

export default connect(mapStateToProps, {
  updateQuery,
})(ContextSwitcherSearch);
