import React from 'react';

import styles from './PanelCollapsible.module.scss';

import Panel from './Panel';
import IconButton from '../IconButton/IconButton';
import TextIcon, { Icon } from '../Icon/TextIcon';

export interface PanelCollapsibleProps {
  column?: number;
  withDividers?: boolean;
  title?: string;
  titleComponent?: JSX.Element | null;
  subTitleComponent?: JSX.Element | null;
  headerControls?: JSX.Element | null;
  fullContentArea?: boolean;
  initiallyExpanded?: boolean;
  shaded?: boolean;
  transparent?: boolean;
  onPanelStateChanged?: (isExpanded: boolean) => void;
  children?: React.ReactNode;
  additionalStyles?: string;
  hideContentTopSeparation?: boolean;
  toggleDisabled?: boolean;
}

const PanelCollapsible = ({
  column,
  withDividers = false,
  title,
  titleComponent,
  subTitleComponent,
  headerControls,
  fullContentArea = false,
  initiallyExpanded = false,
  shaded = false,
  transparent = false,
  onPanelStateChanged,
  children,
  additionalStyles,
  hideContentTopSeparation = false,
  toggleDisabled = false,
}: PanelCollapsibleProps): JSX.Element => {
  const [isExpanded, setIsExpanded] = React.useState(initiallyExpanded);

  const onExpanderClicked = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    const newExpandedState = !isExpanded;
    setIsExpanded(newExpandedState);
    if (onPanelStateChanged) {
      onPanelStateChanged(newExpandedState);
    }
  };

  const contentClassname = hideContentTopSeparation ? '' : styles.content;

  return (
    <Panel
      column={column}
      withDividers={withDividers}
      title={title}
      titleComponent={titleComponent}
      subTitleComponent={subTitleComponent}
      headerControls={
        <div className={styles.controls}>
          {headerControls}
          <IconButton onClick={onExpanderClicked} disabled={toggleDisabled}>
            {<TextIcon icon={isExpanded ? Icon.AngleUp : Icon.AngleDown} additionalStyles={styles.expander} />}
          </IconButton>
        </div>
      }
      fullContentArea={fullContentArea}
      shaded={shaded}
      transparent={transparent}
      additionalStyles={additionalStyles}
    >
      {isExpanded ? <div className={contentClassname}>{children}</div> : undefined}
    </Panel>
  );
};

export default PanelCollapsible;
