import React from 'react';
import { IContext } from '@ready/dashboardv2api.contracts';
import ContextItem from './ContextItem';
// redux
import { connect } from 'react-redux';
// context
import InfiniteScrollingList from '../InfiniteScrollingList/InfiniteScrollingList';

export interface ContextListProps {
  query: string;
  page: number;
  loading: boolean;
  error: boolean;
  paginationLoading: boolean;
  filtered: boolean;
  contexts: IContext[];
  getContexts: (query?: string | undefined) => void;
  loadMoreContexts: (query: string | undefined, page: number) => void;
  paginationAvailable: boolean;
}

const buildItemComponent = (context: IContext, controls: JSX.Element | null): JSX.Element => (
  <ContextItem {...context} />
);

const ContextSwitcherList = (props: ContextListProps) => {
  const {
    query,
    page,
    loading,
    error,
    paginationLoading,
    filtered,
    contexts,
    getContexts,
    loadMoreContexts,
    paginationAvailable,
  } = props;

  const loadItems = React.useCallback((): void => {
    getContexts(query);
  }, [getContexts, query]);

  const loadMoreItems = React.useCallback((): void => {
    loadMoreContexts(query, page);
  }, [loadMoreContexts, query, page]);

  return (
    <InfiniteScrollingList
      loading={loading}
      error={error}
      paginationLoading={paginationLoading}
      paginationAvailable={paginationAvailable}
      filtered={filtered}
      items={contexts}
      loadItems={loadItems}
      loadMoreItems={loadMoreItems}
      getItemKey={(context: IContext) => context.id}
      buildItemComponent={buildItemComponent}
      isInModal
    />
  );
};

const mapStateToProps = (state: any) => ({
  query: state.contextSwitcher.query,
  page: state.contextSwitcher.page,
  loading: state.contextSwitcher.loading,
  error: state.contextSwitcher.error,
  contexts: state.contextSwitcher.items,
  paginationLoading: state.contextSwitcher.paginationLoading,
  paginationAvailable: state.contextSwitcher.paginationAvailable,
  filtered: state.contextSwitcher.filtered,
});

export default connect(mapStateToProps)(ContextSwitcherList);
