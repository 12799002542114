import { createAsyncThunk } from '@reduxjs/toolkit';
import { IGetDailyTotalsReportArgs, getDailyTotalsSummaryReport, getPermittedLocations } from '../service';
import { interceptErrorWithToastMessage } from '../../../redux/helpers/interceptErrorWithToastMessage';
import { IPaymentSdrReportItem, PaginationResponse } from '@ready/dashboardv2api.contracts';

export const fetchDailyTotalsSummaryReportThunk = createAsyncThunk(
  'dailyTotalsSummaryReport/fetch',
  interceptErrorWithToastMessage<IGetDailyTotalsReportArgs, PaginationResponse<IPaymentSdrReportItem>>(
    getDailyTotalsSummaryReport
  )
);

export const fetchPermittedLocationsThunk = createAsyncThunk(
  'dailyTotalsSummaryReport/permittedLocations',
  interceptErrorWithToastMessage(getPermittedLocations)
);
