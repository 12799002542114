import { Tabs, Tab } from 'components/Tabs';
import React from 'react';
import { useParams } from 'react-router-dom';
import { SharedMenuItemsRoutes } from 'sharedMenuItems/Router';
import { ContextParams } from 'types/ContextParams.interface';

export enum DetailTabId {
  Details = 'details',
  AssignedLocations = 'assigned-locations',
}

interface Props {
  selectedTabId: DetailTabId;
  detailsRoute?: string;
  assignedLocationsRoute?: string;
}

export const DetailsTabs = (props: Props) => {
  const { contextId: companyId, id: menuItemId } = useParams<ContextParams>();
  const {
    selectedTabId,
    detailsRoute = SharedMenuItemsRoutes.getItemAndModsItem(companyId, menuItemId),
    assignedLocationsRoute = SharedMenuItemsRoutes.getAssignedLocationsRoute(companyId, menuItemId),
  } = props;

  return (
    <div style={{ paddingBottom: 24 }}>
      <Tabs selectedTab={selectedTabId}>
        <Tab id={DetailTabId.Details} route={detailsRoute} label={'Details'}></Tab>

        <Tab id={DetailTabId.AssignedLocations} route={assignedLocationsRoute} label={'Assigned Locations'}></Tab>
      </Tabs>
    </div>
  );
};
