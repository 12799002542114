import { ICompanyUsersList } from '../../../services/types/companyUsers.type';

export const usersListInitialState: ICompanyUsersList = {
  start: 0,
  length: 0,
  limit: 0,
  total: 0,
  items: [],
  loading: false,
  query: '',
  page: '',
};
