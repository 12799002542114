import React from 'react';
import { DateFilterRestrictable } from '../../../../components/DateFilter/DateFilter';
import SearchSelectFilter, { Option } from '../../../../components/SearchSelectFilter/SearchSelectFilter';
import { DatePickerValue } from '../../../../components/DatePicker/DatePicker';
import styles from './ToolBar.module.scss';
import { maxDaysToChoose } from '../../locationPerformanceSettings';

interface ToolBarProps {
  locations: Array<{ name: string; _id: string }>;
  initDateFilter: DatePickerValue;
}

export const ToolBar = ({ locations, initDateFilter }: ToolBarProps) => {
  const locationOptions: Option[] = locations?.map(({ name, _id }) => ({ value: _id, label: name })) || [];

  return (
    <div className={styles.toolBar}>
      <div>
        <h4>Date Range</h4>
        <DateFilterRestrictable initialValue={initDateFilter} maxDays={maxDaysToChoose} />
      </div>
      <div>
        <h4>Location</h4>
        <SearchSelectFilter
          placeholder='Select a location'
          options={locationOptions}
          isMulti
          name='locations'
          isSearchable
        />
      </div>
    </div>
  );
};
