import { ModalBody, ModalFooter, ModalHeader } from 'components/Modal';
import Modal, { ModalProps } from 'components/Modal/Modal';
import React from 'react';

interface BulkUnassignLocationModalProps extends Pick<ModalProps, 'setShowModal' | 'loading'> {
  itemType: 'Modifier' | 'Item' | 'Item Group';
  itemName?: string;
  totalCheckedLocations?: number;
  onBulkUnassign: () => void;
}

const BulkUnassignLocationModal = ({
  itemType,
  itemName = '',
  totalCheckedLocations = 0,
  onBulkUnassign,
  setShowModal,
  loading = false,
}: BulkUnassignLocationModalProps) => {
  return (
    <Modal setShowModal={setShowModal}>
      <ModalHeader headerLabel={`Unassign Shared Menu ${itemType}`} setShowModal={(visible) => setShowModal(visible)} />
      <ModalBody>
        Are you sure you want to unassign the shared {itemType.toLowerCase()} "<b>{itemName}</b>" from "
        <b>{totalCheckedLocations} Locations</b>
        "? It will be removed from all menus at these locations.
      </ModalBody>
      <ModalFooter
        primaryLabel={`Unassign Shared Menu ${itemType}`}
        primaryActionHandler={onBulkUnassign}
        secondaryLabel='Cancel'
        loading={loading}
        secondaryActionHandler={() => setShowModal(false)}
      />
    </Modal>
  );
};

export default BulkUnassignLocationModal;
