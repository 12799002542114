import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import IconButton from '../../../components/IconButton/IconButton';
import SelectFilter from '../../../components/SelectFilter/SelectFilter';
import SelectFilterAsync from '../../../components/SelectFilter/SelectFilterAsync';
import styles from './ScheduleFormControl.module.scss';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';
import { ISchedule, IScheduleLink, ScheduleEffect } from '@ready/menu.core';
import { toNumber } from '../../../utils/numberUtils/toNumber';
import { ILink } from '../../redux/MenusState';
import NamedEntityValidation from '../../types/NamedEntityValidation.interface';
import { FormControl } from '../../../components/Form';
import { Option, statusEffects } from './filtersData';
import { getScheduleList } from '../../redux/ScheduleListActions';
import { useParams } from 'react-router-dom';
import { ContextParams } from 'types/ContextParams.interface';
import classNames from 'classnames';

export interface ScheduleFormControlProps {
  onChange: (scheduler: IScheduleLink<ILink> | null) => void;
  schedules: ISchedule[];
  opened?: boolean;
  disabled?: boolean;
  loading?: boolean;
  isInModal?: boolean;
  scheduleLink?: IScheduleLink<ILink>;
  validation: NamedEntityValidation;
  // for fetching schedules on click and on search
  areSchedulesLoading?: boolean;
  fullWidth?: boolean;
}

const ScheduleFormControl = (props: ScheduleFormControlProps): JSX.Element => {
  const { contextId: companyId, locationId } = useParams<ContextParams>();

  const {
    schedules,
    onChange,
    opened,
    disabled = false,
    loading = false,
    isInModal = false,
    areSchedulesLoading = false,
    fullWidth = false,
  } = props;
  let { scheduleLink } = props;

  const dispatch = useDispatch();
  const [displayForm, setDisplayForm] = useState(scheduleLink && scheduleLink.links.length > 0);
  const emptyOption: Option[] = [];
  const [menuSchedules, setMenuSchedules] = React.useState<Option[]>(emptyOption);
  let scheduleLinkValues: IScheduleLink<ILink> = {
    effect: ScheduleEffect.show,
    links: [],
  };

  if (scheduleLink) {
    scheduleLinkValues.effect = scheduleLink.effect;
    scheduleLinkValues.links = scheduleLink.links;
  }

  const toggleEdit = () => {
    setDisplayForm(!displayForm);
    onChange(null);
  };

  React.useEffect(() => {
    if (schedules.length) {
      const mappedSchedule: Option[] = [];
      for (const item of schedules) {
        if (!mappedSchedule.some((schedule) => schedule.label === item.name && schedule.value === item._id)) {
          mappedSchedule.push({
            label: item.name,
            value: item._id,
          });
        }
      }
      setMenuSchedules(mappedSchedule);
    } else {
      // To ensure async selector are set to empty array
      // when searching and no results found

      setMenuSchedules([]);
    }
  }, [schedules]);

  const schedulesLimit = 50;
  const schedulesOverflowMessage = `Top ${schedulesLimit} results shown. Type to refine your search.`;

  const handleFetch = (filter: string): void => {
    if (!companyId) {
      return;
    }
    dispatch(getScheduleList(companyId, locationId, filter));
  };

  const scheduleEffectChange = (name: string, data: Option) => {
    if (name && data) {
      scheduleLinkValues.effect =
        toNumber(data.value) === ScheduleEffect.hide ? ScheduleEffect.hide : ScheduleEffect.show;
      onChange(scheduleLinkValues);
    }
  };

  const statusAvailabilityOptionsChange = (name: string, data: Option[]) => {
    if (name && data) {
      scheduleLinkValues.links = data.map((elem) => ({
        id: elem.value,
        name: elem.label,
      }));
    } else if (name && !data) {
      scheduleLinkValues.links = [];
    }
    if (scheduleLinkValues.links.length) {
      onChange(scheduleLinkValues);
    } else {
      onChange(null);
    }
  };

  const effectOption = statusEffects.find(
    (item) => item.value === (!!scheduleLinkValues ? scheduleLinkValues!!.effect : ScheduleEffect.show)
  );

  const linksOption: Option[] = [];
  for (const item of scheduleLinkValues.links) {
    if (!linksOption.some((schedule) => schedule.value === item.id)) {
      linksOption.push({
        label: item.name!!,
        value: item.id,
      });
    }
  }

  const containerClassNames = classNames(styles.container, fullWidth && styles.fullWidth);

  return !displayForm && !opened ? (
    <IconButton onClick={toggleEdit} additionalClassName={styles.scheduler}>
      <div className={styles.btnTextLink}>
        <i className='icon-dashboard-icons_clock' /> Set Schedule
      </div>
    </IconButton>
  ) : (
    <div className={!opened ? containerClassNames : styles.openedContainer}>
      {opened ? null : (
        <div className={styles.btnTextForm}>
          <i className='icon-dashboard-icons_clock' />
        </div>
      )}
      <div className={!opened ? styles.filters : styles.openedSchedule}>
        <div className={styles.selectorStatusContainer}>
          <SelectFilter
            options={statusEffects}
            value={effectOption}
            placeholder='Select a Status'
            name='statuseffects'
            setValue={scheduleEffectChange}
            disabled={disabled}
            loading={loading}
            isInModal={isInModal}
          />
        </div>

        <div className={styles.selectorAvailabilityContainer}>
          <FormControl hideFormLabel>
            <SelectFilterAsync
              isMulti
              options={menuSchedules}
              value={linksOption}
              placeholder='Select a Schedule'
              setValue={statusAvailabilityOptionsChange}
              handleFetch={handleFetch}
              loading={areSchedulesLoading}
              processing={loading}
              limit={schedulesLimit}
              overflowMessage={schedulesOverflowMessage}
              name={'scheduleListOptions'}
              isInModal={isInModal}
              disabled={disabled}
            />
          </FormControl>
        </div>
      </div>
      {opened ? null : (
        <IconButton onClick={() => toggleEdit()} additionalClassName={styles.remove}>
          <TextIcon icon={Icon.RemoveCircle} />
        </IconButton>
      )}
    </div>
  );
};

export default ScheduleFormControl;
