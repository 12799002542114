import React from 'react';

import styles from './ToggleLinks.module.scss';
import { Link } from 'react-router-dom';

export interface IToggleLinkState {
  label: string;
  selected: boolean;
  route: string;
  search?: string;
}

export interface ToggleLinkProps {
  data: IToggleLinkState[];
  disabled?: boolean;
}

const ToggleLinks = (props: ToggleLinkProps) => {
  const { data, disabled = false } = props;
  const shouldShow = data && data.length > 1;
  return (
    <div className={styles.container}>
      {shouldShow &&
        data.map((linkData: IToggleLinkState, i: number) =>
          disabled ? (
            <div className={`${styles.button} ${linkData.selected ? styles.selected : ''} ${styles.disabled}`} key={i}>
              {linkData.label}
            </div>
          ) : (
            <Link
              className={`${styles.button} ${linkData.selected ? styles.selected : ''}`}
              to={{
                pathname: linkData.route,
                state: {
                  currentLink: { ...linkData, selected: true },
                  data: data.map((d: IToggleLinkState) => {
                    return { ...d, selected: d.label === linkData.label };
                  }),
                },
                search: linkData.search,
              }}
              key={i}
            >
              {linkData.label}
            </Link>
          )
        )}
    </div>
  );
};

export default ToggleLinks;
