import produce from 'immer';
import { Action } from '../../../redux/types';
import { orderLocationsState } from '../../initialStates/orders/orderLocations';
import { ORDERS_ACTIONS } from '../../actions/orders/types';

export const orderLocationsReducer = (state = orderLocationsState, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ORDERS_ACTIONS.LOAD_ORDERS_LOCATIONS_BEGIN:
        draft.locationPicker.loading = true;
        return draft;

      case ORDERS_ACTIONS.LOAD_ORDERS_LOCATIONS_SUCCESS:
        draft.locationPicker.loading = false;
        draft.locationPicker.pagination.start = action.payload.start;
        draft.locationPicker.pagination.length = action.payload.length;
        draft.locationPicker.pagination.limit = action.payload.limit;
        draft.locationPicker.pagination.total = action.payload.total;
        draft.locationPicker.locations = action.payload.locations;
        return draft;

      case ORDERS_ACTIONS.LOAD_ORDERS_LOCATIONS_ERROR:
        draft.locationPicker.loading = false;
        return draft;

      default:
        return draft;
    }
  });
};
