import { IOrderIdentityInfo, IOrderTableInfo, OrderExperienceType } from '@ready/dashboardv2api.contracts';

interface ReturnProps {
  primaryInfo: string;
  secondaryInfo: string[];
}

export const getOrderContactInformation = (
  orderIdentityInfo: IOrderIdentityInfo | undefined,
  tableInfo?: IOrderTableInfo
): ReturnProps => {
  if (!orderIdentityInfo) {
    return {
      primaryInfo: '-',
      secondaryInfo: [],
    };
  }

  let primaryInfo = orderIdentityInfo.mappedCustomerResponses.find((x) => x.primary === true)?.value || '-';
  const secondaryInfo = orderIdentityInfo.mappedCustomerResponses
    .filter((x) => x.primary === false)
    .map((x) => x.value);

  if (tableInfo?.orderExperienceType === OrderExperienceType.DineIn) {
    secondaryInfo.unshift(primaryInfo);
    primaryInfo = tableInfo?.tableName ? `Table ${tableInfo?.tableName}` : 'No Table Assigned';
  }

  return {
    primaryInfo,
    secondaryInfo,
  };
};
