import React from 'react';
import classnames from 'classnames';
import styles from './Card.module.scss';

export const CardTitle: React.FC = ({ children }) => <span className={styles.CardTitle}>{children}</span>;

export const CardGeneralText: React.FC = ({ children }) => <span className={styles.CardGeneralText}>{children}</span>;

export const Card: React.FC<{ classes?: string }> = ({ classes, children }) => (
  <div className={classnames(classes, styles.Card)}>{children}</div>
);
