import React from 'react';

import { DragDropContext, Droppable, DropResult, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';

export interface DropAreaProps {
  draggingDisabled?: boolean;
  className?: string;
  draggingClassName?: string;
  onDragEnd: (result: DropResult) => void;
  children: JSX.Element | JSX.Element[] | undefined;
}

const DropArea = ({
  draggingDisabled,
  className,
  draggingClassName,
  onDragEnd,
  children,
}: DropAreaProps): JSX.Element => {
  if (draggingDisabled) {
    return <div className={className}>{children}</div>;
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
          <div
            {...provided.droppableProps}
            className={snapshot.isDraggingOver ? draggingClassName : className}
            ref={provided.innerRef}
          >
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DropArea;
