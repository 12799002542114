import produce from 'immer';
import { POS_ORDER_TYPES_ACTIONS } from '../actions/types';
import { posOrderTypes } from '../initialStates/posOrderTypes';
import { Action } from '../../../redux/types';

export const posOrderTypesReducer = (state = posOrderTypes, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case POS_ORDER_TYPES_ACTIONS.GET_POS_ORDER_TYPES_BEGIN:
        draft.loading = true;
        draft.error = false;
        return draft;

      case POS_ORDER_TYPES_ACTIONS.GET_POS_ORDER_TYPES_SUCCESS:
        draft.options = action.payload;
        draft.loading = false;
        draft.error = false;
        return draft;

      case POS_ORDER_TYPES_ACTIONS.GET_POS_ORDER_TYPES_ERROR:
        draft.loading = false;
        draft.error = true;
        return draft;

      default:
        return draft;
    }
  });
};
