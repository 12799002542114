import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../redux/store';
import { IChecksReportState } from './initialState';

export const checksReportSelector = createSelector(
  (state: RootState) => state,
  ({
    session,
    reports: {
      checks: { reportReducer },
    },
  }: RootState): IChecksReportState & { contextId: string } => {
    return {
      contextId: session.contextSession.id,
      ...reportReducer,
    };
  }
);

export const checksHeaderColumnsSelector = createSelector(
  (state: RootState) => state,
  (state) => state.reports.checks.reportReducer.headerColumns
);
