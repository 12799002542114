import React, { useEffect } from 'react';
import { useAppDispatch } from 'redux/store';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ChecksCheckStatus, PospaymentStatus } from '@ready/dashboardv2api.contracts';
import LayoutContent from '../../../components/AppLayout/LayoutContent';
import { DollarValue } from '../../../components/Value';
import { HistoryItems } from './components/HistoryItems';
import { Payments } from './components/Payments';
import { CheckItems, CheckPaymentDistribution, GeneralInfo, PaymentDetails } from './components/GeneralInfo';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import { PaymentNotAppliedAlert, CheckDetailsFailedPayment } from './components/PaymentNotAppliedAlert';
import { checksPageDataSelector } from './redux/selectors';
import Badge, { BadgeVariant } from '../../../components/Badge/Badge';
import { fetchCheckDetailsThunk } from './redux';
import styles from './Page.module.scss';

export const Panel: React.FC<{ classes?: string }> = ({ children, classes }) => (
  <div className={classes}>
    <div className={styles.Panel}>{children}</div>
  </div>
);
export const PanelHeader: React.FC = ({ children }) => <div className={styles.PanelHeader}>{children}</div>;
export const MarkOpen = ({ status }: { status: ChecksCheckStatus }) => {
  return (
    <span className={styles.MarkOpen}>
      <Badge
        variant={status === ChecksCheckStatus.Open ? BadgeVariant.GreenSolid : BadgeVariant.DisabledGraySolid}
        value={status}
      />
    </span>
  );
};

export const CheckPage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { checkId, locationId } = useParams<{ checkId: string; locationId: string }>();

  const { retryPOSPaymentLoading, contextId, loading, data } = useSelector(checksPageDataSelector);

  const failedPayments = data?.payments.filter(
    ({ pospaymentLastInfo }) => pospaymentLastInfo?.status === PospaymentStatus.failed
  ) as Array<CheckDetailsFailedPayment>;

  useEffect(() => {
    dispatch(fetchCheckDetailsThunk({ contextId, checkId, locationId }));
  }, [contextId, locationId, checkId, dispatch]);

  return (
    <LayoutContent title='Check Detail'>
      {loading ? (
        <div className={styles.CheckPageLoading}>
          <LoadingSpinner additionalStyles={styles.CheckPageLoadingSpinner} />
        </div>
      ) : null}
      {data && checkId === data._id ? (
        <div className={styles.CheckPage}>
          <div className={styles.CheckPageHeader}>
            <i className={`${styles.LeftArrowIcon} icon-dashboard-icons_arrow-left`} onClick={history.goBack} />
            <h2 className={styles.CheckPageTitle}>Check #{data.posTicketNumber}</h2>
          </div>
          {failedPayments.length ? (
            <PaymentNotAppliedAlert
              locationId={data.locationId}
              retryPOSPaymentLoading={retryPOSPaymentLoading}
              failedPayments={failedPayments}
            />
          ) : null}
          <div className={styles.CheckPageBody}>
            <Panel>
              <PanelHeader>
                <DollarValue value={data.checkTotal} additionalStyles={styles.PanelTitle} />
                <span className={styles.CurrencyIndicator}>{data.currency}</span>
                <MarkOpen status={data.status} />
              </PanelHeader>
              <GeneralInfo
                locationName={data.locationName}
                serverName={data.serverName}
                timezone={data.timezone}
                lastUpdated={data.lastUpdated}
                checkDuration={data.checkDuration}
              />
              <CheckPaymentDistribution readyPayment={data.readyPayment} />
              <CheckItems items={data.items} />
              <PaymentDetails details={data.totals} />
            </Panel>
            {data.payments.length ? (
              <Panel classes={styles.CheckPagePayments}>
                <PanelHeader>
                  <span className={styles.PanelTitle}>Payments</span>
                </PanelHeader>
                <Payments payments={data.payments} timezone={data.timezone} />
              </Panel>
            ) : null}
            {/* data is not ready */}
            {data?.history?.length ? (
              <Panel>
                <PanelHeader>
                  <span className={styles.PanelTitle}>History</span>
                </PanelHeader>
                <HistoryItems historyItems={data.history} />
              </Panel>
            ) : null}
          </div>
        </div>
      ) : null}
    </LayoutContent>
  );
};
