import React, { ChangeEvent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Routes } from '../../MenuBuilderRouter';
import { useAppSelector } from '../../../redux/store';
import { Form, FormControl } from '../../../components/Form';
import TextInput from '../../../components/TextInput/TextInput';
import TextArea from '../../../components/TextArea/TextArea';
import styles from './ItemGroupPanels.module.scss';
import {
  saveItemGroup,
  updateItemGroupThumbnailImageId,
  updateItemGroupBannerImageId,
  updateItemGroupUpdateDisplayName,
  validateUpdateItemGroupsDetails,
  updateItemGroupUpdateDescription,
  updateItemGroupUpdateSchedule,
  blankViewEditItemGroupPage,
  updateItemGroup,
  deleteItemGroup,
  revertItemGroupsDetailPageChanges,
} from '../../redux/ItemGroupsActions';
import { getScheduleList, initScheduleList } from '../../redux/ScheduleListActions';
import ScheduleFormControl from '../shared/ScheduleFormControl';
import { IScheduleLink } from '@ready/menu.core';
import { ILink } from '../../redux/MenusState';
import MenuBuilderLayoutContent from '../MenuBuilderLayoutContent';
import MenuBuilderTab from 'menus/types/MenuBuilderTab.enum';
import FormActionBar from 'components/FormActionBar/FormActionBar';
import { BannerAndThumbnailImagesFormControls } from '../ItemsAndMods/BannerAndThumbnailImage/BannerAndThumbnailImagesFormControls';
import { useAppDispatch } from 'redux/store';
import { IAsset } from '@ready/dashboardv2api.contracts';
import {
  selectItemGroupsListPageNumber,
  selectItemGroupsListPageQuery,
  selectItemGroupsViewEditState,
} from 'menus/redux/ItemGroupSelector';
import { selectPermissions } from '../../../redux/selectors/sessionSelectors/sessionSelectors';
import { IItemGroupViewEditPage } from 'menus/redux/ItemGroupsState';
import { selectScheduleListState } from 'menus/redux/SchedulesSelectors';
import { ScheduleListState } from 'menus/redux/ScheduleListState';
import { IFormState } from 'redux/initialStates/ui/formState';
import { selectFormState } from 'redux/selectors/uiSelectors/uiSelectors';
import { Panel, PanelLayout } from 'components/PanelLayout';
import ActionHeader from 'components/ActionHeader/ActionHeader';
import Button from 'components/Button/Button';
import { MenuContextParams } from 'menus/types/MenuContextParams.interface';
import ItemGroupItems from './ItemGroupItems';
import { prepareMenuItemsModal } from 'menus/redux/ItemsAndModsActions';
import MenuItemsView from 'menus/types/MenuItemsView.enum';
import { DeleteConfirmationModal } from 'components/Modal';
import getBackLinkUrl from 'utils/urlUtils/getBackLinkUrl';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { MenuResourceActions, PrincipalPermissions, ResourceType, SecurityScope, Verifier } from '@ready/security.core';
import { IPermissions } from 'redux/initialStates/session/session';
import { setFormIsDirty } from 'redux/actions/uiActions/formStateActions';
import { ReadonlyValueWrapper } from 'components/Value/ReadonlyValueWrapper';
import { TextValue } from 'components/Value';
import TextIcon, { Icon } from 'components/Icon/TextIcon';

export const ItemGroupPanels = () => {
  const dispatch = useAppDispatch();
  const { contextId: companyId, locationId, menuId, id } = useParams<MenuContextParams>();
  const { loading: areSchedulesLoading, schedules } = useAppSelector<ScheduleListState>(selectScheduleListState);
  const itemGroupViewEditPageState = useAppSelector<IItemGroupViewEditPage>(selectItemGroupsViewEditState);
  const {
    detailsPanel: {
      processing,
      itemGroupSaved,
      itemGroupFields,
      itemGroupFields: {
        validation,
        validation: { validated },
        displayName,
        description,
        schedule,
        imageId: bannerImageId,
        thumbnailImageId,
        parentTemplateId,
      },
    },
    detailsPanel,
    imagesPanel,
    groupItemsPanel,
    itemDeleted,
    deleteLoading,
    loading,
  } = itemGroupViewEditPageState;

  const { isDirty } = useAppSelector<IFormState>(selectFormState);
  const backLinkQuery = useAppSelector<string>(selectItemGroupsListPageQuery);
  const backLinkPage = useAppSelector<string>(selectItemGroupsListPageNumber);
  const { permissionsList: permissions } = useAppSelector<IPermissions>(selectPermissions);
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [cancelled, setCancelled] = React.useState<boolean>(false);
  const isNewItemGroup = !id;
  const isSharedItemGroup = !!parentTemplateId;

  const performUpdateItemGroup = React.useCallback(() => {
    dispatch(updateItemGroup(companyId, locationId, id, itemGroupViewEditPageState));
  }, [dispatch, companyId, locationId, id, itemGroupViewEditPageState]);

  const backLinkTo = menuId
    ? menuId === 'item-gallery'
      ? Routes.getMenusPage(companyId, locationId)
      : Routes.getMenuPage(companyId, locationId, menuId)
    : getBackLinkUrl({
        baseUrl: Routes.getItemGroupsPage(companyId, locationId),
        searchParams: { query: backLinkQuery, page: backLinkPage },
      });

  React.useEffect(() => {
    // Save Form
    if (validated) {
      if (isNewItemGroup) {
        dispatch(saveItemGroup(companyId, locationId, itemGroupFields, groupItemsPanel.items));
      } else {
        performUpdateItemGroup();
      }
    }
  }, [
    validated,
    isNewItemGroup,
    dispatch,
    companyId,
    locationId,
    itemGroupFields,
    groupItemsPanel.items,
    performUpdateItemGroup,
    itemGroupSaved,
  ]);

  React.useEffect(() => {
    if (itemGroupSaved) {
      history.push(Routes.getItemGroupPage(companyId, locationId, itemGroupSaved));
    }
  }, [itemGroupSaved, history, companyId, locationId]);

  React.useEffect(() => {
    if (!isNewItemGroup) {
      dispatch(getScheduleList(companyId, locationId));
    }
    return () => {
      dispatch(initScheduleList());
    };
  }, [dispatch, companyId, isNewItemGroup, locationId]);

  // on page unmount blank the entire form
  React.useEffect(() => {
    return () => {
      dispatch(blankViewEditItemGroupPage());
    };
  }, [dispatch]);

  React.useEffect(() => {
    if (itemDeleted) {
      history.push(backLinkTo);
    }
  }, [backLinkTo, history, itemDeleted]);

  React.useEffect(() => {
    if (cancelled && isNewItemGroup) {
      history.push(Routes.getItemGroupsPage(companyId, locationId));
    }
  }, [dispatch, cancelled, companyId, history, isNewItemGroup, locationId]);

  const onSubmit = () => {
    dispatch(validateUpdateItemGroupsDetails(displayName));
  };

  const handleCancel = () => {
    setCancelled(true);
    dispatch(revertItemGroupsDetailPageChanges());
    dispatch(setFormIsDirty(false));
  };

  const uploadBannerImage = (asset: Partial<IAsset>, imageEl?: HTMLImageElement) => {
    dispatch(updateItemGroupBannerImageId(asset, imageEl));
  };
  const uploadThumbnailImage = (asset: Partial<IAsset>, imageEl?: HTMLImageElement) => {
    dispatch(updateItemGroupThumbnailImageId(asset, imageEl));
  };

  const handleDeleteItemGroup = () => {
    dispatch(deleteItemGroup(companyId, locationId, id));
  };

  const saveLabel = isNewItemGroup ? 'Create Item Group' : 'Save Changes';

  const onDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const isPageProcessing =
    detailsPanel.processing ||
    imagesPanel.processingImage ||
    imagesPanel.processingThumbnailImage ||
    groupItemsPanel.processing;

  const onAddItemsToGroupClick = React.useCallback((): void => {
    dispatch(
      prepareMenuItemsModal(
        true,
        groupItemsPanel.items.map((item) => item.itemId),
        MenuItemsView.ITEMS
      )
    );
  }, [dispatch, groupItemsPanel]);

  const hasManagePermission = Verifier.check(
    new PrincipalPermissions(permissions),
    SecurityScope.location,
    ResourceType.menu,
    MenuResourceActions.all,
    locationId
  );

  return (
    <>
      <FormActionBar
        actionBarVisible={isDirty}
        saveLabel={saveLabel}
        handleFormSubmit={onSubmit}
        resetForm={handleCancel}
      />

      {showDeleteModal && (
        <DeleteConfirmationModal
          handleDelete={handleDeleteItemGroup}
          item='Item Group'
          itemName={detailsPanel.itemGroupFields.displayName}
          loading={deleteLoading}
          setShowModal={setShowDeleteModal}
        />
      )}
      <MenuBuilderLayoutContent
        companyId={companyId}
        locationId={locationId}
        locationName=''
        stockStatusOnly={isNewItemGroup ? false : !hasManagePermission}
        tab={MenuBuilderTab.ITEM_GROUPS}
      >
        {loading && !isNewItemGroup ? (
          <LoadingSpinner />
        ) : (
          <>
            {isNewItemGroup ? (
              <ActionHeader text='New Item Group' />
            ) : (
              <ActionHeader
                text={detailsPanel.itemGroupFields.displayName}
                afterTextSlot={isSharedItemGroup && <TextIcon icon={Icon.Share} additionalStyles={styles.sharedIcon} />}
                backLinkTo={backLinkTo}
                actionButtons={
                  isSharedItemGroup
                    ? undefined
                    : [
                        {
                          label: 'Delete',
                          variant: 'secondary-gray-bg',
                          unavailable: false,
                          disabled: isPageProcessing,
                          onClick: onDeleteClick,
                        },
                      ]
                }
              />
            )}

            <Form hasGroups>
              <PanelLayout stackOnTablet additionalColumnStyles={styles.PanelLayout_container}>
                <Panel>
                  <FormControl
                    label='Display Name *'
                    withError={validation.displayName.hasError}
                    errorMessage={validation.displayName.errorMessage}
                  >
                    <ReadonlyValueWrapper value={displayName} readonly={isSharedItemGroup}>
                      <TextInput
                        value={displayName}
                        placeholder='e.g. Best ever lager'
                        loading={processing}
                        withError={validation.displayName.hasError}
                        onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                          dispatch(updateItemGroupUpdateDisplayName(e.target.value));
                        }}
                      />
                    </ReadonlyValueWrapper>
                  </FormControl>

                  <FormControl label='Description'>
                    <ReadonlyValueWrapper
                      value={<TextValue value={description ?? ''} mode='none' />}
                      readonly={isSharedItemGroup}
                    >
                      <TextArea
                        value={description ?? ''}
                        loading={processing}
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>): void => {
                          dispatch(updateItemGroupUpdateDescription(e.target.value));
                        }}
                      />
                    </ReadonlyValueWrapper>
                  </FormControl>

                  <FormControl label='Schedule'>
                    <ScheduleFormControl
                      opened={true}
                      schedules={schedules}
                      scheduleLink={schedule}
                      validation={validation}
                      loading={processing}
                      onChange={(scheduler: IScheduleLink<ILink> | null) => {
                        dispatch(updateItemGroupUpdateSchedule(scheduler));
                        dispatch(setFormIsDirty(true));
                      }}
                      areSchedulesLoading={areSchedulesLoading}
                    />
                  </FormControl>

                  <BannerAndThumbnailImagesFormControls
                    bannerImageId={bannerImageId}
                    bannerImageLoading={processing}
                    uploadBannerImage={uploadBannerImage}
                    thumbnailImageId={thumbnailImageId}
                    thumbnailImageLoading={processing}
                    uploadThumbnailImage={uploadThumbnailImage}
                    readonly={isSharedItemGroup}
                  />
                </Panel>
                <Panel
                  title='Group Items'
                  headerControls={
                    isSharedItemGroup ? undefined : (
                      <Button
                        label='+ Add Items'
                        variant='primary'
                        onClick={onAddItemsToGroupClick}
                        unavailable={isPageProcessing}
                      />
                    )
                  }
                  fullContentArea
                >
                  <ItemGroupItems />
                </Panel>
              </PanelLayout>
            </Form>
          </>
        )}
      </MenuBuilderLayoutContent>
    </>
  );
};
