import { IPagination } from '@ready/dashboardv2api.contracts';
import { WithAssignedLocationsCount } from '@ready/menu.core';
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { mapListTemplateMenuGroupContractToUI } from '../../menuConfigs.mapper';
import { IMenuGroupUI } from './types';
import { getSharedMenuConfigsThunk, saveSharedMenuConfigThunk } from './thunks';

interface State {
  menuConfigs: WithAssignedLocationsCount<IMenuGroupUI>[];
  pagination?: IPagination;
  loading: boolean;
  saving?: boolean;
  selectedConfigId?: string;

  totalAssignableLocations?: number;
}

const initialState: State = {
  loading: true,
  menuConfigs: [],

  saving: false,
};

const menuConfigsListSlice = createSlice({
  name: 'menuConfigs/list',
  initialState: initialState,
  reducers: {
    updateSelectedConfigId: (state: State, action: { payload: string }) => {
      state.selectedConfigId = action.payload;
    },
    storeTotalLocationsCount: (state, action: { payload: number }) => {
      state.totalAssignableLocations = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<State>) => {
    builder.addCase(getSharedMenuConfigsThunk.pending, (state: State) => {
      state.loading = true;
    });

    builder.addCase(getSharedMenuConfigsThunk.fulfilled, (state: State, action) => {
      state.loading = false;
      const { items, ...pagination } = action.payload;
      state.menuConfigs = items.map(mapListTemplateMenuGroupContractToUI);
      state.pagination = pagination;
    });

    builder.addCase(getSharedMenuConfigsThunk.rejected, (state: State) => {
      state.loading = false;
    });

    builder.addCase(saveSharedMenuConfigThunk.pending, (state: State) => {
      state.saving = true;
    });

    builder.addCase(saveSharedMenuConfigThunk.fulfilled, (state: State) => {
      state.saving = false;
    });

    builder.addCase(saveSharedMenuConfigThunk.rejected, (state: State) => {
      state.saving = false;
    });
  },
});
export const { updateSelectedConfigId } = menuConfigsListSlice.actions;
export { menuConfigsListSlice };
