import { ILocationOrderAhead } from '@ready/dashboardv2api.contracts';
import React from 'react';
import { FormPanelCollapsible } from '../../../../components/Form';
import MobileOrdering from './MobileOrdering';
import OrderAhead from './OrderAhead/OrderAhead';
import { IUIDropDown } from '../../../../redux/initialStates/ui/dropDownState';
import { IMobileOrderingPanel, IOrderAheadValidation } from '../../../redux/PanelFeaturesState';
import { IUpdateLocationParam } from '../../LocationsEditPage';

interface Props {
  isProcessing: boolean;
  updateLocationParam: IUpdateLocationParam;
  mobileOrdering: IMobileOrderingPanel;
  orderAhead: ILocationOrderAhead;
  handlePanelToggle: (toggle: boolean) => void;
  panelToggle: boolean;
  dropDown: IUIDropDown;
  validation: IOrderAheadValidation;
  ignoreInStockFromPosItem: boolean;
}

const LocationPanelMO = (props: Props) => {
  const {
    isProcessing,
    updateLocationParam,
    mobileOrdering,
    orderAhead,
    handlePanelToggle,
    panelToggle,
    dropDown,
    validation,
    ignoreInStockFromPosItem,
  } = props;

  return (
    <FormPanelCollapsible
      title='Mobile Ordering'
      initiallyExpanded={panelToggle}
      isEnabled={panelToggle}
      withToggle={true}
      handlePanelToggle={(toggled: boolean) => {
        handlePanelToggle(toggled);
      }}
      loading={isProcessing}
    >
      <MobileOrdering
        isProcessing={isProcessing}
        updateLocationParam={updateLocationParam}
        mobileOrdering={mobileOrdering}
        ignoreInStockFromPosItem={ignoreInStockFromPosItem}
      ></MobileOrdering>
      <div className='form-panel-separator'></div>
      <OrderAhead
        isProcessing={isProcessing}
        updateLocationParam={updateLocationParam}
        orderAhead={orderAhead}
        dropDown={dropDown}
        validation={validation}
      ></OrderAhead>
    </FormPanelCollapsible>
  );
};

export default LocationPanelMO;
