import React from 'react';
import Button from '../Button/Button';

export interface FormActionBarProps {
  actionBarVisible: boolean;
  saveLabel?: string;
  isFormProcessing?: boolean;
  handleFormSubmit: () => any;
  resetForm: () => void;
  hasValidationErrors?: () => boolean;
  setIsFormProcessing?: (processing: boolean) => void;
}

const FormActionBar = (props: FormActionBarProps) => {
  const {
    actionBarVisible,
    saveLabel = 'Save Changes',
    isFormProcessing = false,
    handleFormSubmit,
    resetForm,
    setIsFormProcessing,
  } = props;

  const handleSave = async () => {
    if (setIsFormProcessing) {
      setIsFormProcessing(true);
    }

    await handleFormSubmit();

    if (setIsFormProcessing) {
      setIsFormProcessing(false);
    }
  };

  const handleCancel = () => {
    resetForm();
  };

  return (
    <div
      className='formActionBar'
      style={{
        top: actionBarVisible ? '0' : '-82px',
        zIndex: 2,
      }}
    >
      <div className='formActionBar__buttons'>
        <Button
          label='Cancel'
          onClick={handleCancel}
          variant='secondary'
          loading={isFormProcessing}
          disabled={isFormProcessing}
        />
        <Button label={saveLabel} onClick={handleSave} loading={isFormProcessing} />
      </div>
    </div>
  );
};

export default FormActionBar;
