import { IPagination } from '@ready/dashboardv2api.contracts';
import React from 'react';
import TableFooter from './TableFooter';
import TablePagination from './TablePagination';

export interface TableProps {
  children: JSX.Element[] | JSX.Element;
  paginationProps?: IPagination;
}

const Table = (props: TableProps) => {
  const { children, paginationProps } = props;

  return (
    <div className='table'>
      {children}

      {paginationProps && (
        <TableFooter>
          <TablePagination {...paginationProps} />
        </TableFooter>
      )}
    </div>
  );
};

export default Table;
