import produce from 'immer';
import { actionTypes } from './ScheduleActions';
import { Action } from '../../redux/types';
import { ScheduleState, initialScheduleState } from './ScheduleState';

export const scheduleReducer = (state: ScheduleState = initialScheduleState, action: Action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.SCHEDULE_INIT:
        draft = initialScheduleState;
        return draft;

      case actionTypes.SCHEDULE_PROCESSING:
        draft.processing = action.payload;
        return draft;

      case actionTypes.SCHEDULE_ADD_NEW_SLOT:
        draft.schedule.slots.push({ daysOfWeek: [] });
        return draft;

      case actionTypes.SCHEDULE_REMOVE_SLOT:
        const slotIndex = action.payload;
        draft.schedule.slots.splice(slotIndex, 1);

        // Removes any validation data associated with the slot
        const slotErrorIndex = draft.errors.slotErrors.findIndex((slotError) => slotError.slotIndex === slotIndex);

        // Update error indexes
        draft.errors.slotErrors.forEach((slotError) => {
          if (slotError.slotIndex > slotIndex) {
            slotError.slotIndex -= 1;
          }
        });

        if (slotErrorIndex >= 0) {
          draft.errors.slotErrors.splice(slotErrorIndex, 1);
        }

        return draft;

      case actionTypes.SCHEDULE_SLOT_SET:
        const { scheduleSlot, index } = action.payload;
        draft.schedule.slots[index] = scheduleSlot;
        const slotErrors = draft.errors.slotErrors.find((error) => error.slotIndex === index);
        if (slotErrors) {
          if (scheduleSlot.daysOfWeek.length > 0) {
            slotErrors.emptyDaysError = false;
          }
          if (scheduleSlot.startHour !== undefined && scheduleSlot.startMinute !== undefined) {
            slotErrors.emptyStartTimeError = false;
            slotErrors.timeRangeOrderError = false;
          }
          if (scheduleSlot.endHour !== undefined && scheduleSlot.endMinute !== undefined) {
            slotErrors.emptyEndTimeError = false;
            slotErrors.timeRangeOrderError = false;
          }
        }
        return draft;

      case actionTypes.SCHEDULE_NAME_SET:
        draft.schedule.name = action.payload;
        draft.errors.emptyNameError = false;
        return draft;

      case actionTypes.SCHEDULE_VALIDATION_SET:
        draft.errors = action.payload;
        return draft;

      case actionTypes.SCHEDULE_DELETE_DIALOG_SHOW:
        draft.deleteModalOpen = action.payload.show;
        draft.deleteModalSchedule = action.payload.schedule || { _id: '', name: '', slots: [] };
        return draft;

      case actionTypes.SCHEDULE_SET_LOADING:
        draft.loading = action.payload;
        return draft;

      case actionTypes.SCHEDULE_SET:
        draft.schedule = action.payload;
        return draft;

      case actionTypes.SCHEDULE_SET_CACHE:
        draft.scheduleCache = action.payload;
        return draft;

      case actionTypes.SCHEDULE_RESET:
        draft.schedule = draft.scheduleCache;
        draft.errors.slotErrors = [];
        draft.errors.emptyNameError = false;
        return draft;

      case actionTypes.SCHEDULE_CANCEL_CREATE:
        draft.cancelled = true;
        return draft;

      default:
        return state;
    }
  });
