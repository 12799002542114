import { OrderSmsMessageSource } from '@ready/dashboardv2api.contracts';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl } from '../../../../../components/Form';
import LinkButton from '../../../../../components/LinkButton/LinkButton';
import SelectFilter, { Option } from '../../../../../components/SelectFilter/SelectFilter';
import { OrderSmsMessagesFormControls } from '../../components/OrderSMSMessagesFormControls';
import { LIST_OPTIONS } from '../../../../../utils/selectListUtils/listOptions';
import mapSelectOption from '../../../../../utils/selectListUtils/mapSelectOption';
import {
  toggleOrderSmsEditCompanyModal,
  updateOrderSmsMessageSource,
} from '../../../../redux/reducers/orderSMSMessages/orderSMSMessagesSlice';
import styles from './OrderSMSMessagesLocation.module.scss';
import { orderSmsMessagesSelector } from '../../../../redux/selectors/orderSmsMessagesSelector';
import { selectPermissions } from '../../../../../redux/selectors/sessionSelectors/sessionSelectors';
import {
  PrincipalPermissions,
  OrderSettingsResourceActions,
  ResourceType,
  SecurityScope,
  Verifier,
} from '@ready/security.core';
import { useParams } from 'react-router-dom';
import { ContextParams } from '../../../../../types/ContextParams.interface';
import { setFormIsDirty } from 'redux/actions/uiActions/formStateActions';

export interface EditOrderSmsMessagesLinkProps {
  toggleCompanyModal: () => void;
}

const EditOrderSmsMessagesLink = (props: EditOrderSmsMessagesLinkProps) => {
  return (
    <div className={styles.editLocations}>
      Company Default Settings
      <span className={styles.magenta}>
        <LinkButton onClick={props.toggleCompanyModal}>(Edit)</LinkButton>
      </span>
    </div>
  );
};

export const OrderSmsMessagesLocation = () => {
  const dispatch = useDispatch();
  const { orderSmsMessages, formProcessing } = useSelector(orderSmsMessagesSelector);
  const { messageSource } = orderSmsMessages;
  const { locationId } = useParams<ContextParams>();
  const handleMessageSourceChange = (option: Option) => {
    dispatch(updateOrderSmsMessageSource(option.value.toString()));
    dispatch(setFormIsDirty(true));
  };
  const toggleCompanySettingsModal = () => {
    dispatch(toggleOrderSmsEditCompanyModal(true));
  };
  const isLocation = messageSource === OrderSmsMessageSource.Location;
  const { permissionsList } = useSelector(selectPermissions);
  const hasOrderSmsMessagesCompanyLevelPermission = Verifier.check(
    new PrincipalPermissions(permissionsList),
    SecurityScope.company,
    ResourceType.orderSettings,
    OrderSettingsResourceActions.all,
    locationId
  );

  return (
    <>
      <FormControl label='Message Source'>
        <SelectFilter
          options={LIST_OPTIONS.smsMessageSource}
          onChange={handleMessageSourceChange}
          value={mapSelectOption(LIST_OPTIONS.smsMessageSource, messageSource)}
          loading={formProcessing}
        />
      </FormControl>
      <FormControl
        label='Message Settings'
        labelNoteComponent={
          <span className={styles.labelNote}>
            Pick up times can be dynamically inserted into messages by entering the following variable:
            <b> %TIME%</b>
          </span>
        }
      >
        {hasOrderSmsMessagesCompanyLevelPermission && !isLocation ? (
          <EditOrderSmsMessagesLink toggleCompanyModal={toggleCompanySettingsModal} />
        ) : null}
        <OrderSmsMessagesFormControls />
      </FormControl>
    </>
  );
};
