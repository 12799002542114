import { combineReducers } from 'redux';
import { adminsListReducer } from './AdminsListReducer';
import { adminDetailsReducer } from './AdminDetailsReducer';
import { adminFormReducer } from './AdminFormReducer';

export const adminsReducer = combineReducers({
  adminsList: adminsListReducer,
  adminDetails: adminDetailsReducer,
  adminForm: adminFormReducer,
});
