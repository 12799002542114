import { isBefore } from 'date-fns';

/**
 * READY-1137
 * This function is meant to ONLY COMPARE DATES and IGNORE TIME.
 *
 * @param date {Date}
 * @param dateToCompare {Date}
 */
export const isBeforeDate = (date: Date, dateToCompare: Date): boolean => {
  return isBefore(
    new Date(date.getFullYear(), date.getMonth(), date.getDate()),
    new Date(dateToCompare.getFullYear(), dateToCompare.getMonth(), dateToCompare.getDate())
  );
};
