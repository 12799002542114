import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { IMenuGalleryView } from 'menus/redux/MenusState';
import React, { useEffect } from 'react';
import { RootState, useAppDispatch, useAppSelector } from 'redux/store';
import { SharedMenuItemsLayout } from 'sharedMenuItems/SharedMenuItemsLayout';
import { SharedMenuItemsTabId } from 'sharedMenuItems/SharedMenuItemsTabs';
import { ItemGalleryView } from './components/ItemGalleryView/ItemGalleryView';
import { ItemGalleryForm } from './components/ItemGalleryForm/ItemGalleryForm';
import { useCompanyContextParams } from 'hooks';
import { menuGalleryCancelCreateMode } from 'menus/redux/MenusActions';
import { getSharedItemGalleryThunk } from './redux/thunk';

export const ItemGalleryPage = () => {
  const dispatch = useAppDispatch();

  const { companyId, itemGalleryId, menuConfigId } = useCompanyContextParams<{
    itemGalleryId: string;
    menuConfigId: string;
  }>();

  useEffect(() => {
    dispatch(getSharedItemGalleryThunk({ companyId, menuOrItemGalleryId: itemGalleryId }));
    return () => {
      dispatch(menuGalleryCancelCreateMode());
    };
  }, [companyId, itemGalleryId, dispatch]);

  const {
    menuGalleryView,
    menuGalleryForm: { editing },
  } = useAppSelector((state: RootState) => state.menuBuilder.menus);

  const itemGallery: IMenuGalleryView | undefined = menuGalleryView[itemGalleryId];

  return (
    <SharedMenuItemsLayout tab={SharedMenuItemsTabId.Menus}>
      {itemGallery && !itemGallery?.loading ? (
        editing ? (
          <ItemGalleryForm companyId={companyId} itemGalleryId={itemGalleryId} />
        ) : (
          <ItemGalleryView menuConfigId={menuConfigId} companyId={companyId} itemGalleryId={itemGalleryId} />
        )
      ) : (
        <LoadingSpinner />
      )}
    </SharedMenuItemsLayout>
  );
};
