import LinkButton from 'components/LinkButton/LinkButton';
import React, { ReactNode, useState } from 'react';

interface ToggleEditWrapperProps {
  readonlyValue: string | ReactNode;
  editButtonText?: string;
  children: ReactNode;

  /** If you immediately want to show the children component. Defaults to to false.*/
  showControl?: boolean;
  /** A method that runs when clicking the edit button. The edit button will by default simply show the hidden child control. 
   * Use this for side effects that will occur alongside that.
  */
  onEdit?: () => void;
}

export const ToggleEditWrapper = ({
  readonlyValue,
  editButtonText,
  children,
  showControl = false,
  onEdit,
}: ToggleEditWrapperProps) => {
  const [controlHidden, setControlHidden] = useState<boolean>(!showControl);

  return (
    <>
      {controlHidden ? (
        <>
          <span>{readonlyValue}</span>
          &nbsp;
          <LinkButton
            onClick={() => {
              setControlHidden((prev) => !prev);
              if (onEdit) onEdit();
            }}
          >
            ({editButtonText})
          </LinkButton>
        </>
      ) : (
        <>{children}</>
      )}
    </>
  );
};
