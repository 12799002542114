import React from 'react';
import classNames from 'classnames';
import TextIcon, { Icon } from '../Icon/TextIcon';
import styles from './badge.module.scss';

export enum BadgeVariant {
  Gray = 'gray',
  GraySolid = 'graySolid',
  Green = 'green',
  GreenSolid = 'greenSolid',
  Magenta = 'magenta',
  MagentaSolid = 'magentaSolid',
  Orange = 'orange',
  OrangeSolid = 'orangeSolid',
  YellowSolid = 'yellowSolid',
  DisabledGraySolid = 'disabledGraySolid',
}

export interface BadgeProps {
  value: string;
  icon?: string | Icon;
  variant?: BadgeVariant;
}

const Badge = (props: BadgeProps) => {
  const { value, icon, variant = BadgeVariant.Gray } = props;
  const variantClassname = styles[variant];

  return (
    <span className={classNames(styles.badge, variantClassname)}>
      {icon && <TextIcon icon={icon} additionalStyles={styles.badgeIcon} />}
      {value}
    </span>
  );
};

export default Badge;
