import React from 'react';
import { IScheduleLink, ScheduleEffect } from '@ready/menu.core';
import styles from './InfoSchedule.module.scss';
import { ILink } from '../../redux/MenusState';
import { statusEffects } from '../shared/filtersData';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';
import { useClassNames } from '../../../utils/cssUtils';

export interface InfoScheduleProps {
  variant: 'primary' | 'secondary';
  scheduleLink?: IScheduleLink<ILink>;
  magenta?: boolean;
  hideIcon?: boolean;
}

export const InfoSchedule = (props: InfoScheduleProps): JSX.Element => {
  const { variant, scheduleLink, magenta, hideIcon = false } = props;

  const getEffect = (scheduleLink: IScheduleLink<ILink>) => {
    let item: { value: ScheduleEffect; label: string } | undefined;
    if (scheduleLink) {
      item = statusEffects.find((item) => item.value === scheduleLink!!.effect);
    }
    return item?.label;
  };

  const getOptions = (scheduleLink: IScheduleLink<ILink>) => {
    let result = scheduleLink.links.map((a) => a.name).join(', ');
    return result;
  };

  const containerClassName = useClassNames(
    [
      { scheduleWithEffect: scheduleLink },
      { scheduleNoDescription: !scheduleLink },
      { secondary: variant === 'secondary' },
    ],
    styles
  );

  return (
    <>
      {scheduleLink && scheduleLink.effect !== undefined && scheduleLink.links.length > 0 ? (
        <div className={containerClassName}>
          <TextIcon icon={Icon.Clock} />
          {getEffect(scheduleLink)}
          <span className={magenta ? styles.magenta : styles.bold}>{getOptions(scheduleLink)}</span>
        </div>
      ) : (
        <div className={containerClassName}>{!hideIcon && <TextIcon icon={Icon.Clock} />} No Schedule</div>
      )}
    </>
  );
};

export default InfoSchedule;
