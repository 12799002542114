import { PaginationResponse } from '@ready/dashboardv2api.contracts';
import { ITemplateMenuItem, WithAssignedLocationsCount } from '@ready/menu.core';
import { IPagedResponse } from '@ready/table.core';
import config from 'config';
import executeApiAsync from 'services/utils/executeApiAsync';
import toPaginationResponse from 'services/utils/toPaginationResponse';
import { objectToQueryParamsString } from 'utils/urlUtils/objectToQueryParamsString';

export type ItemTypeFilter = 'all' | 'items' | 'options';

export const getSharedMenuItemList = async (
  companyId: string,
  query?: string,
  type: ItemTypeFilter = 'all',
  locationId?: string,
  pageNumber: number = 1,
  pageSize: number = 50
): Promise<PaginationResponse<WithAssignedLocationsCount<ITemplateMenuItem>>> => {
  const queryString = objectToQueryParamsString({
    pageNumber: pageNumber.toString(),
    pageSize: pageSize.toString(),
    locationId,
    searchTerm: query,
    itemType: type,
  });

  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/items`;
  const result = await executeApiAsync<IPagedResponse<WithAssignedLocationsCount<ITemplateMenuItem>>>(
    `${url}?${queryString}`,
    {
      method: 'GET',
    }
  );

  return toPaginationResponse({ results: result.results, totalCount: result.totalCount }, pageNumber, pageSize);
};
