import { OrderExperienceType, OrderFulfillmentStatus } from '@ready/dashboardv2api.contracts';
import { add, getTime, isAfter, isBefore, isWithinInterval, sub } from 'date-fns';
import { sortOrderPickupTimeAsc, sortOrderPickupTimeDesc } from '.';
import { IOrderList } from '../../redux/initialStates/orders/orderList';
import { ISinglePlacedOrder } from '../../services/types/orders.type';

export const sortOrdersIntoSections = (
  orders: ISinglePlacedOrder[]
): Pick<
  IOrderList,
  | 'overdueOrders'
  | 'overdueOrdersCount'
  | 'upNextOrders'
  | 'upNextOrdersCount'
  | 'offsetOrders'
  | 'offsetOrdersCount'
  | 'completedOrders'
  | 'completedOrdersCount'
  | 'cancelledOrders'
  | 'cancelledOrdersCount'
  | 'dineInOrders'
  | 'dineInOrdersCount'
> => {
  const upNext: ISinglePlacedOrder[] = [];
  const offset: ISinglePlacedOrder[] = [];
  const overdue: ISinglePlacedOrder[] = [];
  const completed: ISinglePlacedOrder[] = [];
  const cancelled: ISinglePlacedOrder[] = [];
  const dineIn: ISinglePlacedOrder[] = [];

  // frame of reference
  const currTime = getTime(new Date());

  const isNewOrder = (order: ISinglePlacedOrder): boolean => {
    // some legacy orders do not have this field
    if (order.created) {
      const orderCreatedTime = new Date(order.created);
      // mark order as new if within the last minute or so
      // the exact time depends on latency between saving the order
      // and the UI receiving the signal, taking into account
      // the timeout between signal received and fetching
      // the new order list
      const newOrderThresholdStartTime = getTime(sub(currTime, { seconds: 60 }));
      return isWithinInterval(orderCreatedTime, {
        start: getTime(newOrderThresholdStartTime),
        end: getTime(currTime),
      });
    }

    return false;
  };

  for (const order of orders) {
    if (order.status === OrderFulfillmentStatus.Complete) {
      // no need to sort completed orders into buckets
      completed.push(order);
    } else if (order.status === OrderFulfillmentStatus.Cancelled) {
      // no need to sort cancelled orders into buckets
      cancelled.push(order);
    } else if (order.tableInfo?.orderExperienceType === OrderExperienceType.DineIn) {
      order.newOrder = isNewOrder(order);
      dineIn.push(order);
    } else {
      // the order pickup time
      const orderTime = new Date(order.pickupTime);

      order.newOrder = isNewOrder(order);

      // todo: use the order offset time from the API once it's configured, for now just assume 30 mins
      //const offsetTime = getTime(add(currTime, { minutes: order.offset }));
      const offsetTime = getTime(add(currTime, { minutes: 30 }));

      if (isAfter(orderTime, offsetTime)) {
        offset.push(order);
      } else if (isBefore(currTime, orderTime) && isBefore(currTime, offsetTime)) {
        upNext.push(order);
      } else if (isBefore(orderTime, currTime)) {
        overdue.push(order);
      }
    }
  }

  return {
    overdueOrders: overdue.sort(sortOrderPickupTimeAsc),
    overdueOrdersCount: overdue.length,
    upNextOrders: upNext.sort(sortOrderPickupTimeAsc),
    upNextOrdersCount: upNext.length,
    offsetOrders: offset.sort(sortOrderPickupTimeAsc),
    offsetOrdersCount: offset.length,
    // sort from newest to oldest
    completedOrders: completed.sort(sortOrderPickupTimeDesc),
    completedOrdersCount: completed.length,
    // sort from newest to oldest
    cancelledOrders: cancelled.sort(sortOrderPickupTimeDesc),
    cancelledOrdersCount: cancelled.length,
    dineInOrders: dineIn.sort(sortOrderPickupTimeAsc),
    dineInOrdersCount: dineIn.length,
  };
};
