import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import debounce from 'lodash/debounce';

import { AppState } from '../../redux/initialStates/AppState';
import {
  prepareCompanyLocationsModal,
  loadLocations,
  loadMoreLocations,
} from '../../redux/actions/companyLocations/companyLocationsActions';
import { ILocationToShow } from '@ready/dashboardv2api.contracts';

import ListModal from '../../components/Modal/ListModal';

import UsersAddLocationsDialogSearch from './UsersAddLocationsDialogSearch';
import UsersAddLocationsDialogList from './UsersAddLocationsDialogList';

export type UsersAddLocationsDialogProps = ConnectedProps<typeof connector> & {
  visible: boolean;
  companyId: string;
  addLocationToUserPermission: (location: ILocationToShow, permissionIndex: number) => void;
  removeLocationFromUserPermission: (locationId: string, permissionIndex: number) => void;
};

const UsersAddLocationsDialog = (props: UsersAddLocationsDialogProps): JSX.Element | null => {
  const {
    visible,
    companyId,
    prepareCompanyLocationsModal,
    loadLocations,
    loadMoreLocations,
    addLocationToUserPermission,
    removeLocationFromUserPermission,
  } = props;

  if (!visible) {
    return null;
  }

  const debounceTime = 300;
  const loadLocationsDebounced = debounce(loadLocations, debounceTime);

  const handleLoadLocations = (query?: string): void => {
    loadLocationsDebounced(companyId, query);
  };

  const handleLoadMoreLocations = (query: string | undefined, page: number): void => {
    loadMoreLocations(companyId, query, page);
  };

  return (
    <ListModal
      setShowModal={prepareCompanyLocationsModal}
      headerLabel='Select Locations'
      searchControl={<UsersAddLocationsDialogSearch />}
      showDismissButton
    >
      <UsersAddLocationsDialogList
        loadLocations={handleLoadLocations}
        loadMoreLocations={handleLoadMoreLocations}
        addLocationToUserPermission={addLocationToUserPermission}
        removeLocationFromUserPermission={removeLocationFromUserPermission}
      />
    </ListModal>
  );
};

const mapStateToProps = (state: AppState) => ({});

const actionCreators = {
  prepareCompanyLocationsModal,
  loadLocations,
  loadMoreLocations,
};

const connector = connect(mapStateToProps, actionCreators);

export default connector(UsersAddLocationsDialog);
