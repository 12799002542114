import React from 'react';
import InputError from '../../components/InputError/InputError';
import TextIcon, { Icon } from '../Icon/TextIcon';
import { Tooltip } from '../Tooltip/Tooltip';

export interface FormControlProps {
  label?: string;
  labelNoteComponent?: React.ReactNode;
  labelTooltip?: string;
  labelColumnSpace?: number;
  icon?: string;
  description?: string;
  children: React.ReactNode;
  bold?: boolean;
  grayBackground?: boolean;
  withError?: boolean;
  errorMessage?: string;
  additionalStyles?: string;
  subLabeled?: boolean;
  separated?: boolean;
  hideFormLabel?: boolean;
  showHelpInstructions?: boolean;
  helpInstructions?: string;
  disabled?: boolean;
}

const FormControl = (props: FormControlProps) => {
  const {
    label,
    labelTooltip,
    labelColumnSpace,
    icon,
    description,
    children,
    bold = false,
    grayBackground = false,
    withError = false,
    errorMessage = '',
    additionalStyles = '',
    subLabeled = false,
    separated = false,
    hideFormLabel = false,
    showHelpInstructions = false,
    helpInstructions,
    labelNoteComponent,
    disabled = false,
  } = props;

  const grayBackgroundClassName = grayBackground ? 'form__control__inputs--gray-background' : '';
  const withErrorClassName = withError ? 'form__control__error--show' : '';
  const subLabeledClassName = subLabeled ? 'form__control__label--sublabeled' : '';
  const disabledClassName = disabled ? 'form__control__label--disabled' : '';

  const helpInstructionsClassName = showHelpInstructions
    ? 'form__control__help_instructions'
    : 'form__control__hide__help_instructions';

  const labelBoldClassName = bold ? 'form__control__label__bold' : '';

  let inputsClassName = 'form__control__inputs__elements';
  if (additionalStyles) {
    inputsClassName += ' ' + additionalStyles;
  }

  return (
    <div
      className={`form__control${separated ? ' form__control--separated' : ''}`}
      style={{
        ['--formControlLabelColumnSpace' as any]: labelColumnSpace,
      }}
    >
      <div className={'form__control__wrapper'}>
        <div
          className={`form__control__label__wrapper ${
            hideFormLabel
              ? 'form__control__label__wrapper__hidden'
              : !label
              ? 'form__control__label__wrapper__empty'
              : ''
          }`}
        >
          <div>
            {icon && <TextIcon icon={icon} additionalStyles='form__control__label form__control__label--icon' />}
            {labelTooltip ? (
              <div
                className={`form__control__label form__control__label--with-tooltip ${labelBoldClassName} ${subLabeledClassName} ${disabledClassName}`}
              >
                {label}
                {labelNoteComponent}
                <Tooltip text={labelTooltip}>
                  <TextIcon icon={Icon.InfoCircle} />
                </Tooltip>
              </div>
            ) : (
              <>
                <span
                  className={`form__control__label bold ${labelBoldClassName} ${subLabeledClassName} ${disabledClassName}`}
                >
                  {label}
                </span>
                {labelNoteComponent}
              </>
            )}
          </div>
          <p className={`${helpInstructionsClassName}`}>{helpInstructions}</p>
          {description && <p className='form__control__description'>{description}</p>}
        </div>
        <div className={`form__control__inputs ${grayBackgroundClassName}`}>
          <div className={inputsClassName}>{children}</div>
          <div className={`form__control__error ${withErrorClassName}`}>
            <InputError message={errorMessage} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormControl;
