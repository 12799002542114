import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ICompanyIntegrations } from '@ready/dashboardv2api.contracts';
// state
import { IPaymentProcessingPanel } from '../../redux/PanelPaymentProcessingState';
import { LOCATION_SETTINGS_ACTIONS } from '../../redux/LocationSettingsActions';
// utils
import { toNumber } from '../../../utils/numberUtils/toNumber';
// components
import { FormControl, FormControlStacked } from '../../../components/Form';
import FormattedInput from '../../../components/FormattedInput/FormattedInput';
import Checkbox from '../../../components/Checkbox/Checkbox';
import SelectFilter, { Option } from '../../../components/SelectFilter/SelectFilter';
import Notification from '../../../components/Notification/Notification';
import { AppState } from '../../../redux/initialStates/AppState';
import { loadSquarePOSLocations } from '../../redux/LocationSettingsActions';
import { useParams } from 'react-router-dom';
import { Icon } from '../../../components/Icon/TextIcon';
import { IUpdateLocationParam } from '../LocationsEditPage';
import Toggle from 'components/Toggle/Toggle';

export interface SquarePPProps {
  isProcessing: boolean;
  updateLocationParam: IUpdateLocationParam;
  paymentProcessingPanel: IPaymentProcessingPanel;
  companyIntegrations?: ICompanyIntegrations;
}

const mapSelectOptions = (allOptions: Option[], optionValue: any): Option => {
  return allOptions?.find((optionObj: Option) => optionObj.value === optionValue) as Option;
};

const SquarePP = (props: SquarePPProps & ReduxProps) => {
  const {
    isProcessing,
    updateLocationParam,
    paymentProcessingPanel,
    loadSquarePOSLocations,
    squarePOS,
    companyIntegrations,
  } = props;

  const { locations, squareOptions } = squarePOS;
  const { id: locationId } = useParams<{ id: string }>();

  const handleFetchLocations = React.useCallback(() => {
    loadSquarePOSLocations(locationId);
  }, [loadSquarePOSLocations, locationId]);
  React.useEffect(() => {
    handleFetchLocations();
  }, [handleFetchLocations]);

  const notAuthorized = !companyIntegrations?.square?.auth?.tokenInfo?.accessToken;

  return (
    <>
      {notAuthorized && (
        <Notification type='warning'>
          <p>
            <i className={Icon.InfoCircle} />
            <span>
              The client must authorize and connect their Square account on the Company Settings page before set up can
              be completed.
            </span>
          </p>
        </Notification>
      )}

      <FormControl label='Square Location'>
        <SelectFilter
          onChange={(data: Option | null) => {
            updateLocationParam(data?.value, LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_STORE_ID);
          }}
          placeholder='Square Location'
          options={locations}
          loading={isProcessing}
          value={mapSelectOptions(locations, squareOptions?.locationId)}
          disabled={notAuthorized}
        />
      </FormControl>

      <FormControl label='Payment Methods'>
        <FormControlStacked>
          <Checkbox
            checked={paymentProcessingPanel.square.paymentMethod.creditCard}
            label='Credit Card'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              updateLocationParam(event.target.checked, LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_CREDIT_CARD);
            }}
            loading={isProcessing}
            disabled={notAuthorized}
          />
        </FormControlStacked>
        <FormControlStacked>
          <Checkbox
            checked={paymentProcessingPanel.square.paymentMethod.applePay}
            label='Apple Pay'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              updateLocationParam(event.target.checked, LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_APPLE_PAY);
            }}
            loading={isProcessing}
            disabled={notAuthorized}
          />
        </FormControlStacked>
        <FormControlStacked>
          <Checkbox
            checked={paymentProcessingPanel.square.paymentMethod.googlePay}
            label='Google Pay'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              updateLocationParam(event.target.checked, LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_GOOGLE_PAY);
            }}
            loading={isProcessing}
            disabled={notAuthorized}
          />
        </FormControlStacked>
      </FormControl>
      <FormControl label='Rate Limiting'>
        <FormControlStacked>
          <Toggle
            checked={paymentProcessingPanel.square.rateLimits.enabled}
            onChange={(checked) => {
              updateLocationParam(
                checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_RATE_LIMITING_TOGGLE
              );
            }}
            loading={isProcessing}
          />
        </FormControlStacked>
        <FormControlStacked>
          <Checkbox
            disabled={true}
            checked={true}
            label='Enforce rate limits on manual card payments'
            loading={isProcessing}
          />
        </FormControlStacked>
        <FormControlStacked>
          <Checkbox
            disabled={!paymentProcessingPanel.square.rateLimits.enabled}
            checked={paymentProcessingPanel.square.rateLimits.enforceRateLimitsForGooglePay}
            label='Enforce rate limits on Google Pay transactions'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_RATE_LIMITING_FOR_GOOGLE_PAY
              );
            }}
            loading={isProcessing}
          />
        </FormControlStacked>
        <FormControlStacked>
          <FormattedInput
            format='money'
            disabled={!paymentProcessingPanel.square.rateLimits.enabled}
            value={paymentProcessingPanel.square.rateLimits.amount}
            onChange={(event) => {
              updateLocationParam(
                toNumber(event.target.value),
                LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_RATE_LIMITING_AMOUNT
              );
            }}
            loading={isProcessing}
          />
          <span className='form__control__separator'>Per</span>
          <FormattedInput
            format='min'
            disabled={!paymentProcessingPanel.square.rateLimits.enabled}
            value={paymentProcessingPanel.square.rateLimits.duration / 60}
            onChange={(event) => {
              updateLocationParam(
                toNumber(event.target.value) * 60,
                LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_RATE_LIMITING_TIME_INTERVAL
              );
            }}
            loading={isProcessing}
          />
        </FormControlStacked>
      </FormControl>
      <FormControl label='Additional Settings'>
        <Checkbox
          checked={paymentProcessingPanel.square.allowSmallTransactions}
          label='Allow small transactions'
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            updateLocationParam(
              event.target.checked,
              LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_ALLOW_SMALL_TRANSACTIONS
            );
          }}
          loading={isProcessing}
          disabled={notAuthorized}
        />
      </FormControl>
    </>
  );
};
const mapStateToProps = (state: AppState) => ({
  squarePOS: state.location.posLoyaltyPanelInitialState,
});

const actionCreators = {
  loadSquarePOSLocations,
};

const connector = connect(mapStateToProps, actionCreators);
type ReduxProps = ConnectedProps<typeof connector>;
export default connector(SquarePP);
