import { ILocationEditModelResponse } from '@ready/dashboardv2api.contracts';
import { getAddressListOptions } from '../../../services/addressListService';
import { IAddressListOptions } from '../../../services/types/address.type';
import { UI_ACTIONS } from '../types';
import { LIST_OPTIONS } from '../../../utils/selectListUtils/listOptions';
import { createDropDownListPayload } from '../../../utils/selectListUtils/listUtil';
import { Option } from '../../../components/SelectFilter/SelectFilter';
import { DropDownListKeyEnum, IDropDownList } from '../../../utils/selectListUtils/listOptions.interface';
import { Action } from '../../types';

export const getDropDownItems = (location: ILocationEditModelResponse) => async (dispatch: any) => {
  try {
    const addressListOptions = (await getAddressListOptions()) as IAddressListOptions;
    dispatch(setDropDownItems(location, addressListOptions));
  } catch (e) {
    console.error(`Cannot fetch address list`);
    throw e;
  }
};

export const setDropDownItems =
  (location: ILocationEditModelResponse, addressListOptions: IAddressListOptions) => async (dispatch: any) => {
    const countryDropDownList = createDropDownListPayload(
      addressListOptions.countryList,
      location.details.countryCode,
      DropDownListKeyEnum.Country
    ) as IDropDownList;

    const stateDropDownList = createDropDownListPayload(
      addressListOptions.stateList,
      location.details.stateCode,
      DropDownListKeyEnum.State
    ) as IDropDownList;

    const timeZoneDropDownList = createDropDownListPayload(
      LIST_OPTIONS.timezone,
      location.details.timezone,
      DropDownListKeyEnum.TimeZone
    ) as IDropDownList;

    const currencyDropDownList = createDropDownListPayload(
      LIST_OPTIONS.currency,
      location.details.currency,
      DropDownListKeyEnum.Currency
    ) as IDropDownList;

    const localeDropdownList = createDropDownListPayload(
      LIST_OPTIONS.locale,
      location.details.locale,
      DropDownListKeyEnum.Locale
    ) as IDropDownList;

    dispatch({
      type: UI_ACTIONS.SETUP_DETAILS_PANEL_DROPDOWN,
      payload: {
        addressListOptions: addressListOptions,
        country: countryDropDownList,
        state: stateDropDownList,
        timezone: timeZoneDropDownList,
        currency: currencyDropDownList,
        locale: localeDropdownList,
      },
    });
  };

export const changeCountryOption = (newOption: Option) => async (dispatch: any) => {
  dispatch({
    type: UI_ACTIONS.SET_SELECTED_OPTION_COUNTRY,
    payload: newOption,
  });
};

export const changeStateOption = (newOption: Option) => async (dispatch: any) => {
  dispatch({
    type: UI_ACTIONS.SET_SELECTED_OPTION_STATE,
    payload: newOption,
  });
};

export const changeTimezoneOption = (newOption: Option) => async (dispatch: any) => {
  dispatch({
    type: UI_ACTIONS.SET_SELECTED_OPTION_TIMEZONE,
    payload: newOption,
  });
};

export const changeCurrencyOption = (newOption: Option) => async (dispatch: any) => {
  dispatch({
    type: UI_ACTIONS.SET_SELECTED_OPTION_CURRENCY,
    payload: newOption,
  });
};

export const changeLocaleOption = (newOption: Option) => async (dispatch: any) => {
  dispatch({
    type: UI_ACTIONS.SET_SELECTED_OPTION_LOCALE,
    payload: newOption,
  });
};

export const changeOrderTimeOption = (newOption: Option) => async (dispatch: any) => {
  dispatch({
    type: UI_ACTIONS.SET_SELECTED_OPTION_ORDER_TIME,
    payload: newOption,
  });
};

export const changeThrottleDurationOption = (newOption: Option) => async (dispatch: any) => {
  dispatch({
    type: UI_ACTIONS.SET_SELECTED_OPTION_THROTTLE_DURATION,
    payload: newOption,
  });
};

export const changeAcceptOrderAheadForOption = (newOption: Option) => async (dispatch: any) => {
  dispatch({
    type: UI_ACTIONS.SET_SELECTED_OPTION_ACCEPT_FOR,
    payload: newOption,
  });
};

export const changePosIntegrationOption = (newOption: Option) => async (dispatch: any) => {
  dispatch({
    type: UI_ACTIONS.SET_SELECTED_OPTION_POS_INTEGRATION,
    payload: newOption,
  });
};

export const changeHeaderFontOption = (newOption: Option) => (dispatch: any) => {
  dispatch({
    type: UI_ACTIONS.SET_SELECTED_OPTION_HEADER_FONT,
    payload: newOption,
  });
};

export const changeBodyFontOption = (newOption: Option) => (dispatch: any) => {
  dispatch({
    type: UI_ACTIONS.SET_SELECTED_OPTION_BODY_FONT,
    payload: newOption,
  });
};

export const changeCalculateTipsOption = (newOption: Option) => (dispatch: any) => {
  dispatch({
    type: UI_ACTIONS.SET_SELECTED_OPTION_TIP_CALCULATION,
    payload: newOption,
  });
};

export const changePosProviderOption = (newOption: Option): Action => ({
  type: UI_ACTIONS.SET_SELECTED_OPTION_POS_PROVIDER,
  payload: newOption,
});
