import React from 'react';
import { DetailElement, DetailLayout } from '../../../components/DetailLayout';
import { IPayPalInfo } from '../../redux/initialStates/paymentProcessing';
import ConnectWithPayPalButton from '../../components/ConnectWithPayPalButton/ConnectWithPayPalButton';

import styles from './PayPalPanel.module.scss';
import Toggle from '../../../components/Toggle/Toggle';
import Button from '../../../components/Button/Button';
import PayPalUnlink from './PayPalUnlink';
import { PayPalStatus } from '@ready/dashboardv2api.contracts';

interface PayPalPanelProps {
  paypal: IPayPalInfo;
  handleUnlinkPayPalModal: (modalVisible: boolean) => void;
  unlinkPayPalAccount: () => void;
  handlePayPalLocationStatusChange: (status: boolean) => void;
}

const PayPalPanel = (props: PayPalPanelProps) => {
  const { paypal, handleUnlinkPayPalModal, unlinkPayPalAccount, handlePayPalLocationStatusChange } = props;

  const addPayPalScripts = () => {
    const script = document.createElement('script');
    script.id = 'paypal-init';
    script.textContent =
      '(function(d, s, id) {var js, ref = d.getElementsByTagName(s)[0];if (!d.getElementById(id)) {js = d.createElement(s);js.id = id;js.async = true;js.src = "https://www.paypal.com/webapps/merchantboarding/js/lib/lightbox/partner.js";ref.parentNode.insertBefore(js, ref);}}(document, "script", "paypal-js"));';
    script.async = true;
    document.body.appendChild(script);
  };

  const removePayPalScripts = React.useCallback(() => {
    const init = document.getElementById('paypal-init');
    const script = document.getElementById('paypal-js');

    if (init) {
      document.body.removeChild(init);
    }

    if (script) {
      document.head.removeChild(script);
    }
  }, []);

  React.useEffect(() => {
    if (paypal.link && paypal.options.status === PayPalStatus.Disconnected) {
      addPayPalScripts();

      return () => {
        if (paypal.options.status === PayPalStatus.Disconnected) {
          removePayPalScripts();
        }
      };
    }
  }, [paypal.link, paypal.options.status, removePayPalScripts]);

  return (
    <>
      <DetailLayout>
        {!paypal.options || !paypal.options.paypalMerchantId || paypal.options.status === PayPalStatus.Disconnected ? (
          <DetailElement
            label='Status'
            isLabelNoteColumn
            labelNoteComponent={
              <span className={styles.labelNote}>
                Your browser must be configured to accept third-party cookies before connecting.
              </span>
            }
          >
            <ConnectWithPayPalButton link={paypal.link} />
          </DetailElement>
        ) : (
          <>
            <DetailElement label='Status'>
              {paypal.options.status === PayPalStatus.Pending ? (
                <p>Pending</p>
              ) : (
                <Toggle
                  checked={paypal.options.status === PayPalStatus.Connected}
                  onChange={handlePayPalLocationStatusChange}
                  additionalStyles={styles.statusToggle}
                  loading={paypal.loading}
                />
              )}
            </DetailElement>
            <DetailElement label='Merchant ID'>
              <p>{paypal.options.paypalMerchantId}</p>
            </DetailElement>
            <DetailElement label=''>
              <Button
                variant='secondary'
                label='Unlink PayPal Account'
                size='large'
                additionalStyles={styles.unlinkButton}
                onClick={() => handleUnlinkPayPalModal(true)}
                disabled={paypal.loading}
              />
            </DetailElement>
          </>
        )}
      </DetailLayout>

      {paypal.unlink.requested && (
        <PayPalUnlink
          processing={paypal.unlink.loading}
          setVisible={handleUnlinkPayPalModal}
          unlinkPayPalAccount={unlinkPayPalAccount}
        />
      )}
    </>
  );
};

export default PayPalPanel;
