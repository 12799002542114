import { DetailLayout, DetailElement } from '../../components/DetailLayout';
import { loadUserFormData, initializeUserFormData } from '../../redux/actions/usersActions/usersFormActions';
import { Panel, PanelLayout } from '../../components/PanelLayout';
import { PrincipalPermissions, ResourceType, SecurityScope, Verifier, UserResourceActions } from '@ready/security.core';
import { RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { UserToggleActivateModal } from './components/UserToggleActivateModal';
import ActionHeader from '../../components/ActionHeader/ActionHeader';
import LayoutContent from '../../components/AppLayout/LayoutContent';
import React, { useState } from 'react';
import UserPermissionsGroup from '../../components/UserPermissions/UserPermissionsGroup';
import StatusIndicator from '../../components/StatusIndicator/StatusIndicator';

const getFullName = (firstName: string | undefined, lastName: string | undefined) =>
  `${firstName || ''} ${lastName || ''}`;

const UsersViewPage = () => {
  const dispatch = useDispatch();

  const permissions = useSelector((state: RootState) => state.session.permissions.permissionsList);
  const hasUserAllPermission = Verifier.check(
    new PrincipalPermissions(permissions),
    SecurityScope.company,
    ResourceType.user,
    UserResourceActions.all
  );

  const { query, page } = useSelector((state: RootState) => state.users.usersList);
  const { contextId: companyId, userId } = useParams<{
    contextId: string;
    userId: string;
  }>();

  const backLinkTo = {
    baseUrl: `/companies/${companyId}/users`,
    searchParams: { query, page },
  };

  React.useEffect(() => {
    dispatch(loadUserFormData(companyId, userId));
    return () => {
      dispatch(initializeUserFormData());
    };
  }, [companyId, userId, dispatch]);

  const history = useHistory();
  const handleEditUser = () => {
    history.push(`/companies/${companyId}/users/${userId}/edit`);
  };

  const usersForm = useSelector((state: RootState) => state.users.usersForm);
  const name = getFullName(usersForm.firstName, usersForm.lastName);

  const [showActivateModal, setShowActivateModal] = useState<boolean>(false);

  return (
    <>
      <LayoutContent
        title='User Details'
        loadingContent={usersForm.loading}
        pageId='users-view-page'
        containerType='within'
      >
        <ActionHeader
          text={name}
          actionButtons={[
            {
              variant: 'secondary-gray-bg',
              label: usersForm.enabled ? 'Deactivate' : 'Activate',
              onClick: () => setShowActivateModal(true),
              overflowOrder: 2,
              hidden: !hasUserAllPermission,
            },
            {
              variant: 'primary',
              label: 'Edit User',
              onClick: handleEditUser,
              overflowOrder: 1,
              hidden: !hasUserAllPermission,
            },
          ]}
          backLinkTo={backLinkTo}
        />
        <PanelLayout columns={1}>
          <Panel column={0}>
            <DetailLayout>
              <DetailElement label='Status'>
                <StatusIndicator active={usersForm.enabled} activeLabel={'Active'} inactiveLabel={'Inactive'} />
              </DetailElement>

              <DetailElement label='First Name'>{usersForm.firstName}</DetailElement>
              <DetailElement label='Last Name'>{usersForm.lastName}</DetailElement>
              <DetailElement label='Email Address'>{usersForm.email}</DetailElement>
              <DetailElement label='Security Role(s)'>
                {usersForm.roles && (
                  <UserPermissionsGroup
                    companyId={companyId}
                    onAddUserRole={() => {}}
                    onRemoveUserRole={() => {}}
                    onUpdateRole={() => {}}
                    onAddLocation={() => {}}
                    onRemoveLocation={() => {}}
                    value={usersForm.roles}
                    readOnly={true}
                  />
                )}
              </DetailElement>
            </DetailLayout>
          </Panel>
        </PanelLayout>
      </LayoutContent>
      {showActivateModal && (
        <UserToggleActivateModal
          setShow={setShowActivateModal}
          user={usersForm}
          fullName={name}
          isUserEnabled={usersForm.enabled}
        />
      )}
    </>
  );
};

export default UsersViewPage;
