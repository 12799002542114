import React from 'react';
import classNames from 'classnames';

import styles from './Panel.module.scss';
import PanelTitle from './PanelTitle';
import { useClassNames } from '../../utils/cssUtils/useClassNames';

export interface IPanelProps {
  column?: number;
  withDividers?: boolean;
  title?: string;
  titleComponent?: JSX.Element | null;
  subTitleComponent?: JSX.Element | null;
  headerControls?: JSX.Element | null;
  fullContentArea?: boolean;
  shaded?: boolean;
  children?: React.ReactNode;
  transparent?: boolean;
  closeVerticalSpacing?: boolean;
  additionalStyles?: string;
  additionalSectionStyles?: string;
  additionalContentStyles?: string;
  emptyResult?: boolean;
  emptyResultComponent?: JSX.Element;
}

const Panel = ({
  withDividers = false,
  title,
  titleComponent,
  subTitleComponent,
  headerControls,
  fullContentArea = false,
  shaded = false,
  children,
  transparent = false,
  closeVerticalSpacing = false,
  additionalStyles,
  additionalSectionStyles = '',
  additionalContentStyles = '',
  emptyResult = false,
  emptyResultComponent,
}: IPanelProps) => {
  const childElements = !children ? [] : Array.isArray(children) ? children : [children];

  const containerClassName = useClassNames(
    [
      {
        containerTransparent: transparent,
        containerShaded: !transparent && shaded,
        container: !transparent && !shaded,
        containerVerticalSpacing: !closeVerticalSpacing,
      },
    ],
    styles
  );

  const contentClassName = useClassNames(
    [
      {
        content: true,
        contentFull: fullContentArea,
        contentWithTitle: title || titleComponent,
        contentNoChildren: !children,
        contentTransparent: transparent,
      },
      additionalContentStyles,
    ],
    styles
  );

  return (
    <>
      {emptyResult && emptyResultComponent ? (
        emptyResultComponent
      ) : (
        <div className={classNames(containerClassName, additionalStyles)}>
          <>
            {(title || titleComponent) && (
              <div className={styles.header}>
                <div className={styles.headerMain}>
                  <div className={styles.title}>{title ? <PanelTitle title={title} /> : titleComponent}</div>
                  {headerControls && <div className={styles.controls}>{headerControls}</div>}
                </div>
                {subTitleComponent}
              </div>
            )}
          </>

          <div className={contentClassName}>
            {withDividers &&
              childElements
                .filter((child) => !!child)
                .map((child, index) => (
                  <div className={classNames(styles.section, additionalSectionStyles)} key={`section-${index}`}>
                    {child}
                  </div>
                ))}
            {!withDividers && childElements}
          </div>
        </div>
      )}
    </>
  );
};

export default Panel;
