import React from 'react';
import { IPickupHours } from '@ready/dashboardv2api.contracts';
import { orderDaysDefault } from 'companyLocations/redux/initialStates/orderHours';
import { DetailElement, DetailLayout } from 'components/DetailLayout';
import { pickUpDayLabels } from 'locations/components/LocationPanelFeatures/MobileOrdering/OrderAhead/OrderAhead';
import styles from './ViewPanel.module.scss';
import { useAppSelector } from 'redux/store';
import { offsetTimeForAllDaysValue } from '../utils';
import moment from 'moment';

type TimeSlotProps = {
  startHour: number;
  startMinute: number;
  endHour: number;
  endMinute: number;
};
export const orderHoursLabelNoteMessage =
  'Determines a guest’s earliest selectable pick up time. (E.g. If a guest orders at 12:00 PM and the prep time is set to 15 minutes, their earliest pick up time will be 12:15 PM.)';

const formatTime = (hour: number, minute: number): string => {
  return `${hour > 12 ? hour - 12 : hour === 0 ? 12 : hour}:${minute < 10 ? '0' : ''}${minute} ${
    hour >= 12 ? 'P' : 'A'
  }M`;
};

const TimSlot = ({ startHour, startMinute, endHour, endMinute }: TimeSlotProps) => {
  return (
    <div>
      {formatTime(startHour, startMinute)} - {formatTime(endHour, endMinute)}
    </div>
  );
};
export const ViewPanel = () => {
  const { orderHoursCache, specialHoursCache: specialHours } = useAppSelector((state) => state.location.orderHours);
  const orderHours =
    orderHoursCache === undefined || Object.keys(orderHoursCache).length === 0 ? orderDaysDefault : orderHoursCache;

  const hasSpecialHours = specialHours.length > 0;

  return (
    <DetailLayout>
      {pickUpDayLabels.map((label) => {
        const day = label.toLowerCase() as keyof IPickupHours;
        const [startHour, startMinute] = orderHours[day].start.split(':').map(Number);
        const [endHour, endMinute] = orderHours[day].end.split(':').map(Number);

        return (
          <DetailElement key={label} label={label}>
            {orderHours[day].disabled ? (
              <span className={styles.greyText}>Closed</span>
            ) : (
              <TimSlot startHour={startHour} startMinute={startMinute} endHour={endHour} endMinute={endMinute} />
            )}
          </DetailElement>
        );
      })}

      <div className={styles.specialHours}>
        <DetailElement label='Special Hours'>
          {!hasSpecialHours && <span className={styles.greyText}>None</span>}
        </DetailElement>
        {hasSpecialHours && (
          <>
            {specialHours?.map((hour) => {
              const { start, end, date, closed } = hour;
              const [startHour, startMinute] = start.split(':').map(Number);
              const [endHour, endMinute] = end.split(':').map(Number);
              const formattedDate = moment(new Date(date)).format('DD/MM/YYYY');
              return (
                <React.Fragment key={date}>
                  <DetailElement labelAdditionalStyles={styles.dateLabel} label={formattedDate}>
                    {closed ? (
                      <span className={styles.greyText}>Closed</span>
                    ) : (
                      <TimSlot
                        startHour={startHour}
                        startMinute={startMinute}
                        endHour={endHour}
                        endMinute={endMinute}
                      />
                    )}
                  </DetailElement>
                </React.Fragment>
              );
            })}
          </>
        )}
      </div>

      <DetailElement label='Minimum Order Prep Time' labelTooltip={orderHoursLabelNoteMessage}>
        {offsetTimeForAllDaysValue(orderHours) ? (
          <div>{offsetTimeForAllDaysValue(orderHours)} minutes</div>
        ) : (
          <>
            {pickUpDayLabels.map((label) => {
              const day = label.toLowerCase() as keyof IPickupHours;
              return (
                <DetailElement key={label} label={label}>
                  <div className={styles.offsetItem}>{orderHours[day].offset} minutes</div>
                </DetailElement>
              );
            })}
          </>
        )}
      </DetailElement>
    </DetailLayout>
  );
};
