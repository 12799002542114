import { ReportsResourceActions, ResourceType } from '@ready/security.core';
import { initDateFilterUrlQuery } from '../../../utils/urlUtils/initDateFilterUrlQuery';
import { ISidebarOption } from './session';
import { checkFeatureToggle } from '../../../utils/featureToggle/featureToggle';

const reportsSidebarChildren: ISidebarOption[] = [
  {
    label: 'Transactions',
    url: `/transactions?${initDateFilterUrlQuery}`,
    adminOption: false,
    enabled: true,
    permissions: [ResourceType.transaction],
  },
  {
    label: 'Payments',
    url: `/payments?sortDirection=desc&sortColumn=date&${initDateFilterUrlQuery}`,
    adminOption: false,
    enabled: true,
    permissions: [{ resourceType: ResourceType.reports, action: ReportsResourceActions.viewPaymentDataReport }],
    requiredContextSessionProps: {
      usesAdyen: true,
    },
  },
  /// hide the daily totals summary report for now, as the payout history report is similar */
  // {
  //   label: 'Daily Totals Summary',
  //   url: `/dailyTotalsSummary?sortDirection=desc&sortColumn=dateCreated`,
  //   adminOption: false,
  //   enabled: true,
  //   permissions: [{ resourceType: ResourceType.reports, action: ReportsResourceActions.viewPaymentDataReport }],
  //   requiredContextSessionProps: {
  //     usesAdyen: true,
  //   },
  // },
  {
    label: 'Payout History',
    url: `/payoutHistory?sortDirection=desc&sortColumn=dateCreated`,
    adminOption: false,
    enabled: true,
    permissions: [{ resourceType: ResourceType.reports, action: ReportsResourceActions.viewPaymentDataReport }],
    requiredContextSessionProps: {
      usesAdyen: true,
    },
  },
  {
    label: 'Checks',
    url: `/checks?sortDirection=desc&sortColumn=openDate&${initDateFilterUrlQuery}`,
    adminOption: false,
    enabled: checkFeatureToggle('app_checksReport'),
    permissions: [{ resourceType: ResourceType.reports, action: ReportsResourceActions.viewChecksReport }],
  },
  {
    label: 'Feedback',
    url: `/feedback?sortDirection=desc&sortColumn=created&${initDateFilterUrlQuery}`,
    adminOption: false,
    enabled: true,
    permissions: [{ resourceType: ResourceType.reports, action: ReportsResourceActions.viewFeedbackReport }],
  },
  {
    label: 'Email Marketing List',
    url: `/email_marketing_list?${initDateFilterUrlQuery}`,
    adminOption: false,
    enabled: true,
    permissions: [{ resourceType: ResourceType.reports, action: ReportsResourceActions.viewEmailMarketingList }],
  },
  {
    label: 'Promo Code Usage',
    url: `/promo_code_usage?sortDirection=desc&sortColumn=code&${initDateFilterUrlQuery}`,
    adminOption: false,
    enabled: checkFeatureToggle('app_promoCodeUsageReport'),
    permissions: [{ resourceType: ResourceType.reports, action: ReportsResourceActions.viewPromoCodeReport }],
  },
  {
    label: 'Location Performance',
    url: `/location_performance?sortDirection=desc&sortColumn=locationName&${initDateFilterUrlQuery}`,
    adminOption: false,
    enabled: checkFeatureToggle('app_locationPerformanceReport'),
    permissions: [{ resourceType: ResourceType.reports, action: ReportsResourceActions.viewLocationPerformanceReport }],
  },
];
export default reportsSidebarChildren;
