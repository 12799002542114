import React from 'react';
import styles from './DialogSMSInput.module.scss';
import TextSMSInput from '../../../components/TextSMSInput/TextSMSInput';
import IconButton from '../../../components/IconButton/IconButton';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';

export interface DialogSMSInputStateProps {
  value: string;
  loading: boolean;
  processing: boolean;
  withPencilIcon?: boolean;
}

export interface DialogSMSInputActionProps {
  onChange: (value: string, event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  formatter?: (value: string) => string;
}

const DialogSMSInput = ({
  value,
  loading,
  processing,
  onChange,
  formatter,
  withPencilIcon = false,
}: DialogSMSInputStateProps & DialogSMSInputActionProps): JSX.Element => {
  const [editMode, setEditMode] = React.useState(false);

  const handleEditClick = () => {
    setEditMode(true);
  };

  return (
    <div className={styles.container}>
      <h5 className={styles.header}>Guest Message</h5>
      <div className={styles.input}>
        <TextSMSInput
          value={value}
          onChange={onChange}
          formatter={formatter}
          editMode={editMode && !processing}
          maxLength={160}
          rows={3}
          loading={loading}
        />
        {!editMode && withPencilIcon ? (
          <IconButton
            onClick={handleEditClick}
            additionalClassName={styles.editButton}
            disabled={loading || processing}
          >
            <TextIcon additionalStyles={styles.editIcon} icon={Icon.Pencil} />
          </IconButton>
        ) : null}
      </div>
    </div>
  );
};

export default DialogSMSInput;
