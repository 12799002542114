import { OrderExperienceType } from '@ready/dashboardv2api.contracts';
import { IOrderType } from '@ready/table.core/dist/src/models/IOrderType';
import { IPagedResponse, ITableConfiguration, IUpdateTableTargetResponse } from '@ready/table.core';
import config from '../../config';
import executeApiAsync from '../../services/utils/executeApiAsync';
import { IUnsavedMappedTableConfiguration } from '../redux/initialStates/tablesDetails';

export class TablesService {
  public static getTableConfigurations = async (
    companyId: string = '',
    locationId: string = '',
    query: string = '',
    page: number = 1
  ): Promise<IPagedResponse<ITableConfiguration>> => {
    const params = `${decodeURIComponent(query)}&page=${page}&size=${50}&pageNumber=${page}&pageSize=${50}`;

    const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/tables/configurations?${params}`;
    return executeApiAsync<IPagedResponse<ITableConfiguration>>(url);
  };

  public static updateTableConfigurationTargetCodes = async (
    companyId: string,
    locationId: string,
    unsavedMappedTargetCodes: IUnsavedMappedTableConfiguration
  ): Promise<IUpdateTableTargetResponse> => {
    const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/tables/updateTargets`;
    const body = {
      tableTargets: unsavedMappedTargetCodes,
    };
    return executeApiAsync<IUpdateTableTargetResponse>(url, {
      method: 'PUT',
      body: JSON.stringify(body),
    });
  };

  public static updateTableConfigurationPosOrderTypes = async (
    companyId: string,
    locationId: string,
    unsavedMappedPosOrderTypes: IUnsavedMappedTableConfiguration
  ): Promise<IUpdateTableTargetResponse> => {
    const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/tables/orderTypes`;
    const posOrderTypes = { ...unsavedMappedPosOrderTypes };
    for (const key in posOrderTypes) {
      if (posOrderTypes[key] === 'default') {
        posOrderTypes[key] = '';
      }
    }
    return executeApiAsync<IUpdateTableTargetResponse>(url, {
      method: 'PUT',
      body: JSON.stringify(posOrderTypes),
    });
  };

  // FIXME: READY-1111 return type needs to be renamed in order to make sense to
  // updateTableConfigurationTargetCodes, updateTableConfigurationOrderExperienceTypes,
  // and updateTableConfigurationPosOrderTypes functions
  public static updateTableConfigurationOrderExperienceTypes = async (
    companyId: string,
    locationId: string,
    unsavedMappedOrderExperienceTypes: IUnsavedMappedTableConfiguration
  ): Promise<IUpdateTableTargetResponse> => {
    const orderExperienceTypes: [string, OrderExperienceType][] = [];
    for (const key in unsavedMappedOrderExperienceTypes) {
      orderExperienceTypes.push([key, unsavedMappedOrderExperienceTypes[key] as OrderExperienceType]);
    }

    const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/tables/orderExperienceTypes`;

    return executeApiAsync<IUpdateTableTargetResponse>(url, {
      method: 'PUT',
      body: JSON.stringify(orderExperienceTypes),
    });
  };

  public static listPosOrderTypes = async (companyId: string, locationId: string): Promise<IOrderType[]> => {
    return executeApiAsync<IOrderType[]>(
      `${config.readyApiLegacyEndpoint}/companies/${companyId}/locations/${locationId}/orderTypes`
    );
  };

  public static syncTableWithPOS = async (companyId: string, locationId: string): Promise<Response> => {
    return executeApiAsync<Response>(
      `${config.readyApiLegacyEndpoint}/companies/${companyId}/locations/${locationId}/tables/sync`,
      {
        method: 'POST',
      }
    );
  };

  public static updateTableConfiguration = async (
    companyId: string,
    locationId: string,
    table: ITableConfiguration
  ): Promise<ITableConfiguration> => {
    return executeApiAsync<ITableConfiguration>(
      `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/tables/configuration`,
      {
        method: table.id ? 'PUT' : 'POST',
        body: JSON.stringify(table),
      }
    );
  };

  public static deleteTableConfiguration = async (
    companyId: string,
    locationId: string,
    tableId: string
  ): Promise<void> => {
    return executeApiAsync<void>(
      `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/tables/configuration/${tableId}`,
      { method: 'DELETE' }
    );
  };
}
