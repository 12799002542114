import React from 'react';

export interface InfoCardColumnProps {
  children?: React.ReactNode;
  width?: number;
  skeleton?: boolean;
  columnClassName?: string;
}

const InfoCardColumn = (props: InfoCardColumnProps) => {
  const { width, skeleton, children, columnClassName = '' } = props;

  let inlineFlex: any;
  if (width) {
    inlineFlex = {
      flex: `${width}%`,
    };
  }

  const skeletonClassName = skeleton ? 'info__card__column--skeleton' : '';

  return (
    <div className={`info__card__column ${skeletonClassName} ${columnClassName}`} style={inlineFlex}>
      {children}
    </div>
  );
};

export default InfoCardColumn;
