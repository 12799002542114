import { createAsyncThunk } from '@reduxjs/toolkit';
import { set200Toast, toastErrorState } from 'redux/actions/uiActions/responseStateActions';
import { PaginationResponse } from '@ready/dashboardv2api.contracts';
import { IBulkEditMenu, IBulkEditMenuRequest } from '@ready/menu.core';
import { getLocationMenus, saveLocationMenus } from '../service';

interface IGetMenuLocationSettingsArgs {
  companyId: string;
  menuId: string;
  page?: number;
  pageSize?: number;
  searchTerm?: string;
}
export const getLocationMenusThunk = createAsyncThunk<PaginationResponse<IBulkEditMenu>, IGetMenuLocationSettingsArgs>(
  'sharedMenu/locationSettings/menu/fetch',
  async ({ companyId, menuId, page, pageSize, searchTerm }: IGetMenuLocationSettingsArgs) => {
    return await getLocationMenus(companyId, menuId, page, pageSize, searchTerm);
  }
);

interface ISaveMenuLocationSettingsThunkArgs {
  companyId: string;
  menuId: string;
  editMenuRequest: Omit<IBulkEditMenuRequest, 'templateMenuId'>;
}
export const saveLocationMenusThunk = createAsyncThunk(
  'sharedMenu/locationSettings/menu/save',
  async (args: ISaveMenuLocationSettingsThunkArgs, thunkAPI) => {
    try {
      const result = await saveLocationMenus(args.companyId, args.menuId, args.editMenuRequest);
      thunkAPI.dispatch(set200Toast('Success! Changes saved.'));
      return result;
    } catch (error) {
      thunkAPI.dispatch(toastErrorState(error));
    }
  }
);
