import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { AppState } from '../../../redux/initialStates/AppState';
import { ICachedPosMenuItem, ICachedPosModifier } from '@ready/menu.core';

import styles from './LinkPosItemList.module.scss';

import InfiniteScrollingList from '../../../components/InfiniteScrollingList/InfiniteScrollingList';
import LinkPosItemListItem from './LinkPosItemListItem';
import { LinkPosItemType } from '../../types/LinkPosItemType.type';

const mapStateToProps = (state: AppState) => {
  const { loading, type, error, query, page, paginationLoading, paginationAvailable, filtered, items, selectedItemId } =
    state.menuBuilder.itemsAndMods.posItemSelection;
  return {
    loading,
    type,
    error,
    query,
    page,
    paginationLoading,
    paginationAvailable,
    filtered,
    items,
    selectedItemId,
  };
};

const connector = connect(mapStateToProps);

export type LinkPosItemListProps = ConnectedProps<typeof connector> & {
  loadPosItems: (type: LinkPosItemType, query: string | undefined) => void;
  loadMorePosItems: (type: LinkPosItemType, query: string | undefined, page: number) => void;
  selectPosItem: (item: ICachedPosMenuItem | ICachedPosModifier) => void;
};

const buildItemComponent = (
  item: ICachedPosMenuItem | ICachedPosModifier,
  controls: JSX.Element | null
): JSX.Element => <LinkPosItemListItem item={item} controls={controls!} />;

const LinkPosItemList = (props: LinkPosItemListProps): JSX.Element => {
  const {
    loading,
    type,
    error,
    query,
    page,
    paginationLoading,
    paginationAvailable,
    filtered,
    items,
    selectedItemId,
    loadPosItems,
    loadMorePosItems,
    selectPosItem,
  } = props;

  const noItemsTitle = 'No Results Found';
  const noItemsMessage =
    'Make sure the items you are looking for have been created in your point of sale, or try refining your search.';

  const loadItems = React.useCallback((): void => {
    loadPosItems(type, query);
  }, [loadPosItems, type, query]);

  const loadMoreItems = React.useCallback((): void => {
    loadMorePosItems(type, query, page);
  }, [loadMorePosItems, type, query, page]);

  return (
    <InfiniteScrollingList
      loading={loading}
      error={error}
      paginationLoading={paginationLoading}
      paginationAvailable={paginationAvailable}
      items={items}
      filtered={filtered}
      noItemsTitle={noItemsTitle}
      noItemsMessage={noItemsMessage}
      selectedItemKeys={[selectedItemId]}
      loadItems={loadItems}
      loadMoreItems={loadMoreItems}
      getItemKey={(item: ICachedPosMenuItem | ICachedPosModifier) => item.externalId}
      buildItemComponent={buildItemComponent}
      addItem={selectPosItem}
      listAdditionalClassName={styles.list}
      isInModal
    />
  );
};

export default connector(LinkPosItemList);
