import React from 'react';
import SearchParamInput from '../../components/SearchInput/SearchParamInput';
import { UnassignedPanelNoResults } from './index';
import styles from './UnassignedPanelData.module.scss';
import { UnassignedPanelSection, UnassignedTableRow } from './index';
import { useSearchParams } from '../../hooks/useSearchParams';
import { searchTables } from '../utils';
import classNames from 'classnames';
import { SelectableTable } from '../redux/ServerAssignmentState';
import StandardLoadingSpinner from '../../components/LoadingSpinner/StandardLoadingSpinner';

interface UnassignedPanelDataProps {
  unassignedTables: SelectableTable[];
  assignedTables: SelectableTable[];
  additionalContentStyles?: string;
  onChange: (tableId: string, checked: boolean) => void;
  loading: boolean;
  disabled: boolean;
  hasAllPermission?: boolean;
}

const UnassignedPanelData = ({
  unassignedTables,
  assignedTables,
  additionalContentStyles,
  onChange,
  loading,
  disabled,
  hasAllPermission,
}: UnassignedPanelDataProps) => {
  const { query } = useSearchParams();

  const [filteredAssignedTables, setFilteredAssignedTables] = React.useState(assignedTables);
  const [filteredUnassignedTables, setFilteredUnassignedTables] = React.useState(unassignedTables);

  // Filters the assigned table lists
  React.useEffect(() => {
    setFilteredAssignedTables(searchTables(query, assignedTables));
  }, [query, assignedTables]);

  // Filters the unassigned table lists
  React.useEffect(() => {
    setFilteredUnassignedTables(searchTables(query, unassignedTables));
  }, [query, unassignedTables]);

  const unassignedTableCount = unassignedTables.length;
  const assignedTableCount = assignedTables.length;
  const filteredUnassignedTableCount = filteredUnassignedTables.length;
  const filteredAssignedTableCount = filteredAssignedTables.length;

  const totalTableCount = unassignedTableCount + assignedTableCount;

  return (
    <>
      <div className={additionalContentStyles}>
        <SearchParamInput
          fullWidth
          placeholder='Search Tables'
          additionalContainerClassName={styles.search}
          additionalClassName={'white'}
        />
      </div>

      {totalTableCount === 0 ? (
        <UnassignedPanelNoResults
          messageType='noTables'
          additionalStyles={classNames(styles.noResult, additionalContentStyles)}
        />
      ) : loading ? (
        <div className={styles.spinnerContainer}>
          <StandardLoadingSpinner size='large' loading={loading} color='gray' />
        </div>
      ) : (
        <>
          <UnassignedPanelSection
            section='unassigned'
            showEmpty={unassignedTableCount === 0}
            showNoResults={filteredUnassignedTableCount === 0}
            additionalContentStyles={additionalContentStyles}
          >
            {filteredUnassignedTables.map((table) => (
              <UnassignedTableRow
                key={`unassigned-table_${table.id}`}
                tableId={table.id}
                tableName={table.tableName || table.tableNumber || 'Not Provided'}
                revenueCenterName={table.revenueCenter}
                isSelected={table.selected}
                onChange={onChange}
                additionalStyles={additionalContentStyles}
                disabled={disabled}
                hasAllPermission={hasAllPermission}
              />
            ))}
          </UnassignedPanelSection>
          <UnassignedPanelSection
            section='assigned'
            showEmpty={assignedTableCount === 0}
            showNoResults={filteredAssignedTableCount === 0}
            additionalContentStyles={additionalContentStyles}
          >
            {filteredAssignedTables.map((table) => (
              <UnassignedTableRow
                key={`assigned-table_${table.id}`}
                tableId={table.id}
                tableName={table.tableName || table.tableNumber || 'Not Provided'}
                revenueCenterName={table.revenueCenter}
                isSelected={table.selected}
                onChange={onChange}
                isSecondaryInfo
                additionalStyles={additionalContentStyles}
                disabled={disabled}
              />
            ))}
          </UnassignedPanelSection>
        </>
      )}
    </>
  );
};

export default UnassignedPanelData;
