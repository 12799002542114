import React from 'react';
import styles from './OrderDetailPage.module.scss';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../redux/initialStates/AppState';
import { format } from 'date-fns';
import LayoutContent from '../../components/AppLayout/LayoutContent';
import ActionHeader, { Action } from '../../components/ActionHeader/ActionHeader';
import {
  initOrderDetail,
  fetchOrderDetail,
  updateStatus,
  setCancelOrderDialogOpen,
  initEditPickupTimeDialog,
  setCancelOrderDialogMessage,
  setCancelOrderDialogMessageLoading,
  setEditPickupTimeDialogMessage,
  setEditPickupTimeDialogMessageLoading,
} from '../../redux/actions/orders/orderDetailActions';
import { useParams } from 'react-router-dom';
import { PanelLayout, Panel } from '../../components/PanelLayout';
import { ItemsPanelContents, OrderDetailPanelContents, CancelOrderDialog } from './components';
import { IOrderItem, ISmsConfig, OrderFulfillmentStatus, OrderSmsMessageSource } from '@ready/dashboardv2api.contracts';
import { ContextParams } from '../../types/ContextParams.interface';
import { fetchOrderSmsConfigs, initOrderSmsConfigs } from '../../redux/actions/orders/orderSmsConfigsActions';
import { fetchLocationDetails, initLocationDetails } from '../../redux/actions/orders/orderListActions';
import { usePageTitle } from '../../hooks';

interface OrderParams extends ContextParams {
  orderId: string;
}

const OrderDetailPage = ({
  orderDetail,
  fetchOrderDetail,
  initOrderDetail,
  updateStatus,
  urlQuery,
  orderSmsConfigs,
  setCancelOrderDialogOpen,
  setCancelOrderDialogMessage,
  setCancelOrderDialogMessageLoading,
  setEditPickupTimeDialogMessage,
  setEditPickupTimeDialogMessageLoading,
  fetchOrderSmsConfigs,
  initOrderSmsConfigs,
  fetchLocationDetails,
  initLocationDetails,
  locationDetails,
}: ReduxProps) => {
  usePageTitle('Order Details');

  const { locationId, contextId, orderId } = useParams<OrderParams>();
  const [newOrderStatus, setNewOrderStatus] = React.useState(OrderFulfillmentStatus.Pending);
  // Fetch the order details on mount, and re-initialize on un-mount
  React.useEffect(() => {
    fetchOrderDetail(contextId, locationId, orderId);
    return () => {
      initOrderDetail();
    };
  }, [fetchOrderDetail, contextId, locationId, orderId, initOrderDetail]);

  const handleOrderStatusChange = (orderStatus: OrderFulfillmentStatus) => {
    setNewOrderStatus(orderStatus);
    updateStatus(contextId, locationId, orderId, orderDetail.status, orderStatus, orderDetail.invoiceId);
  };

  React.useEffect(() => {
    (async () => {
      await fetchLocationDetails(contextId, locationId);
    })();
    return () => {
      initLocationDetails();
    };
  }, [initLocationDetails, fetchLocationDetails, contextId, locationId]);

  React.useEffect(() => {
    (async () => {
      await fetchOrderSmsConfigs(contextId, locationId);
    })();
    return () => {
      initOrderSmsConfigs();
    };
  }, [initOrderSmsConfigs, fetchOrderSmsConfigs, contextId, locationId]);
  const selectedOrderSmsConfig: ISmsConfig =
    orderSmsConfigs.messageSource === OrderSmsMessageSource.Location
      ? orderSmsConfigs.locationConfig
      : orderSmsConfigs.companyConfig;

  React.useEffect(() => {
    if (orderSmsConfigs) {
      setCancelOrderDialogMessageLoading(true);
      setEditPickupTimeDialogMessageLoading(true);
      const orderSmsCancelOrderMessage = selectedOrderSmsConfig.orderCancelled?.value ?? '';
      const orderSmsUpdatePickupTimeMessage = selectedOrderSmsConfig.pickUpTimeUpdated?.value ?? '';

      setEditPickupTimeDialogMessage(orderSmsUpdatePickupTimeMessage);
      setCancelOrderDialogMessage(orderSmsCancelOrderMessage);
      setCancelOrderDialogMessageLoading(false);
      setEditPickupTimeDialogMessageLoading(false);
    }
  }, [
    orderSmsConfigs,
    setCancelOrderDialogMessageLoading,
    setEditPickupTimeDialogMessageLoading,
    setEditPickupTimeDialogMessage,
    setCancelOrderDialogMessage,
    selectedOrderSmsConfig,
  ]);

  const { query, status } = urlQuery;
  const urlDay = format(new Date(orderDetail.pickupTime), 'yyyy-MM-dd');

  const baseUrl = `/companies/${contextId}/locations/${locationId}/orders/day/${urlDay}/${status}`;

  const handleCancelOrder = () => {
    setCancelOrderDialogOpen(true);
  };

  // We cannot cancel completed, or already cancelled orders.
  const isCancelledOrCompleted =
    orderDetail.status === OrderFulfillmentStatus.Cancelled || orderDetail.status === OrderFulfillmentStatus.Complete;

  const headerActionButtons: Action[] | undefined = !isCancelledOrCompleted
    ? [
        {
          label: 'Cancel Order',
          variant: 'secondary-gray-bg',
          onClick: handleCancelOrder,
          disabled: orderDetail.processing,
        },
      ]
    : undefined;

  return (
    <LayoutContent title='Order Details' loadingContent={orderDetail.loading} pageId='order-view-page'>
      <CancelOrderDialog currentStatus={orderDetail.status} giftCardPaymentInfo={orderDetail.giftCardPaymentInfo} />
      <ActionHeader
        text={`# ${orderId}`}
        backLinkTo={{
          baseUrl,
          searchParams: { query },
        }}
        textOverflowStyle='wrap-all'
        actionButtons={headerActionButtons}
      />
      <PanelLayout columns={2} flexWidths={[40, 60]} additionalColumnStyles={styles.panelLayoutColumn}>
        <Panel key='orderInfo' fullContentArea additionalContentStyles={styles.panelContent}>
          <OrderDetailPanelContents
            orderDetail={orderDetail}
            locationDetails={locationDetails}
            orderStatusChanging={{
              newOrderStatus,
              processing: orderDetail.processing,
            }}
            onOrderStatusChange={handleOrderStatusChange}
          />
        </Panel>
        <Panel
          key='itemInfo'
          fullContentArea
          titleComponent={<ItemsTitle items={orderDetail.items} />}
          additionalContentStyles={styles.panelContent}
        >
          <ItemsPanelContents orderDetail={orderDetail} />
        </Panel>
      </PanelLayout>
    </LayoutContent>
  );
};

interface ItemsTitleProps {
  items: IOrderItem[];
}

const ItemsTitle = ({ items }: ItemsTitleProps) => {
  const itemCount = items.reduce((acc, { quantity }) => acc + quantity, 0);

  return (
    <div className={styles.itemHeader}>
      <span className={styles.itemHeaderText}>Items</span>
      <span className={styles.itemHeaderCount}>{`(${itemCount})`}</span>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    orderDetail: state.orders.orderDetail,
    locationDetails: state.orders.orderList.locationDetails,
    urlQuery: state.orders.orderList.urlQuery,
    orderSmsConfigs: state.orders.orderSmsConfigs.orderSmsConfigData,
  };
};

const actionCreators = {
  initOrderDetail,
  fetchOrderDetail,
  updateStatus,
  setCancelOrderDialogOpen,
  initEditPickupTimeDialog,
  fetchOrderSmsConfigs,
  initOrderSmsConfigs,
  fetchLocationDetails,
  initLocationDetails,
  setCancelOrderDialogMessage,
  setCancelOrderDialogMessageLoading,
  setEditPickupTimeDialogMessage,
  setEditPickupTimeDialogMessageLoading,
};

const connector = connect(mapStateToProps, actionCreators);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(OrderDetailPage);
