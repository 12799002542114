import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Panel, PanelLayout } from '../../../components/PanelLayout';
import Button from '../../../components/Button/Button';
import { DigitalMenuEditPanel } from './DigitalMenuEditPanel';
import { DigitalMenuViewPanel } from './DigitalMenuViewPanel';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import { toggleClientDigitalMenuEditForm } from '../../redux/actions/digitalMenuActions';
import { selectClientDigitalMenu } from '../../redux/selectors/LocationSelector';
import styles from './DigitalMenuLayoutContent.module.scss';

export const DigitalMenuLayoutContent = () => {
  const dispatch = useDispatch();
  const clientDigitalMenuState = useSelector(selectClientDigitalMenu);
  const { isEditMode = false, loading } = clientDigitalMenuState;

  const toggleView = () => {
    dispatch(toggleClientDigitalMenuEditForm(true));
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className={styles.digitalMenuContainer}>
          <PanelLayout>
            <Panel
              title='Digital Menu'
              headerControls={
                isEditMode ? undefined : (
                  <Button variant='secondary' label='Edit' size='large' loading={loading} onClick={toggleView} />
                )
              }
            >
              {isEditMode ? <DigitalMenuEditPanel /> : <DigitalMenuViewPanel />}
            </Panel>
          </PanelLayout>
        </div>
      )}
    </>
  );
};
