import produce from 'immer';
import { actionTypes } from './TagsListActions';
import { Action } from '../../redux/types';
import { TagsListState, tagsListState } from './TagsListState';

export const tagsListReducer = (state: TagsListState = tagsListState, action: Action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.SET_TAGS_LIST_DIALOG_VISIBLE:
        draft.modalVisible = action.payload;
        return draft;

      case actionTypes.GET_TAGS_LIST_BEGIN:
        draft.loading = true;
        return draft;

      case actionTypes.GET_TAGS_LIST_SUCCESS:
        draft.loading = false;
        draft.tags = action.payload;
        return draft;

      case actionTypes.GET_TAGS_LIST_ERROR:
        draft.loading = false;
        return draft;

      case actionTypes.UPDATE_TAGS_LIST_QUERY:
        draft.query = action.payload;
        return draft;

      default:
        return state;
    }
  });
