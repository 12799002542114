import { PaginationResponse } from '@ready/dashboardv2api.contracts';
import { IRole } from '@ready/security.core';

export interface IRolesList {
  loading: boolean;
  securityRoles: PaginationResponse<IRole>;
}

export const securityRolesList: IRolesList = {
  loading: false,
  securityRoles: {
    items: [],
    length: 0,
    limit: 0,
    start: 0,
    total: 0,
  },
};
