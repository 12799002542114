import { IBulkEditMenuItem, IBulkEditMenuItemGroup, IBulkEditMenuSection, IMenuItem } from '@ready/menu.core';
import { ActionReducerMapBuilder, createSlice, Dictionary } from '@reduxjs/toolkit';
import { getCurrentItemAtLocation } from './tableThunkActions';

export interface ITableState {
  isUnassignLocationModalVisible: boolean;
  isBulkUnassignLocationModalVisible: boolean;
  isEditLocationSettingsModalVisible: boolean;
  isBulkLocationEditModalVisible: boolean;
  rowData?: IBulkEditMenuItem | IBulkEditMenuItemGroup;
  allMenuItemsSelected: boolean;
  checkCells: Dictionary<boolean>;
  currentMenuItemAtLocation?: IMenuItem;
  modalLoading: boolean;
}

const initialState: ITableState = {
  isUnassignLocationModalVisible: false,
  isBulkUnassignLocationModalVisible: false,
  isEditLocationSettingsModalVisible: false,
  isBulkLocationEditModalVisible: false,
  allMenuItemsSelected: false,
  checkCells: {},
  modalLoading: false,
};

const reducers = {
  setUnassignLocationModalVisible: (state: ITableState, action: { payload: boolean }): void => {
    state.isUnassignLocationModalVisible = action.payload;
  },

  setBulkUnassignLocationModalVisible: (state: ITableState, action: { payload: boolean }): void => {
    state.isBulkUnassignLocationModalVisible = action.payload;
  },

  setEditLocationSettingsModalVisible: (state: ITableState, action: { payload: boolean }): void => {
    state.isEditLocationSettingsModalVisible = action.payload;
  },

  setBulkLocationEditModalVisible: (state: ITableState, action: { payload: boolean }): void => {
    state.isBulkLocationEditModalVisible = action.payload;
  },

  setRowData: (state: ITableState, action: { payload: IBulkEditMenuItem | IBulkEditMenuItemGroup }): void => {
    state.rowData = action.payload;
  },

  initCellCheckedState: (
    state: ITableState,
    action: { payload: Array<IBulkEditMenuItem | IBulkEditMenuItemGroup | IBulkEditMenuSection> }
  ): void => {
    state.checkCells = {};
    action.payload.map((item) => (state.checkCells[item.locationId] = false));
  },

  toggleCellCheck: (state: ITableState, action: { payload: { locationId: string; checked: boolean } }): void => {
    state.checkCells[action.payload.locationId] = action.payload.checked;
    if (!action.payload.checked) state.allMenuItemsSelected = false;
  },

  toggleAllOnPage: (state: ITableState, action: { payload: { check: boolean } }): void => {
    Object.keys(state.checkCells).map((locationId) => (state.checkCells[locationId] = action.payload.check));
    if (!action.payload.check) state.allMenuItemsSelected = false;
  },

  selectAllMenuItems: (state: ITableState): void => {
    state.allMenuItemsSelected = true;
  },

  clearCurrentMenuItem: (state: ITableState): void => {
    state.currentMenuItemAtLocation = undefined;
  },

  resetTableState: (): ITableState => initialState,
};

const tableSlice = createSlice({
  name: 'sharedMenuItems/assignTable',
  initialState: initialState,
  reducers,
  extraReducers: (builder: ActionReducerMapBuilder<ITableState>) => {
    builder.addCase(getCurrentItemAtLocation.pending, (state: ITableState) => {
      state.modalLoading = true;
    });

    builder.addCase(getCurrentItemAtLocation.fulfilled, (state: ITableState, action) => {
      state.modalLoading = false;
      state.currentMenuItemAtLocation = action.payload;
    });
  },
});

export const {
  setUnassignLocationModalVisible,
  setBulkUnassignLocationModalVisible,
  setEditLocationSettingsModalVisible,
  setBulkLocationEditModalVisible,
  setRowData,
  initCellCheckedState,
  toggleCellCheck,
  toggleAllOnPage,
  selectAllMenuItems,
  clearCurrentMenuItem,
  resetTableState,
} = tableSlice.actions;

export { tableSlice };
