import produce from 'immer';
import { Action } from '../../redux/types';
import { LOCATIONS_ACTIONS } from './LocationsActions';
import { locationsList } from './LocationsState';

export const locationsReducer = (state = locationsList, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case LOCATIONS_ACTIONS.GET_LOCATIONS_LIST_BEGIN:
        draft.loading = true;
        draft.error = false;
        return draft;

      case LOCATIONS_ACTIONS.GET_LOCATIONS_LIST_SUCCESS:
        draft.loading = false;
        draft.locations = action.payload.locations;
        draft.pagination = action.payload.pagination;
        return draft;

      case LOCATIONS_ACTIONS.GET_LOCATIONS_LIST_ERROR:
        draft.loading = false;
        draft.error = true;
        return draft;

      case LOCATIONS_ACTIONS.UPDATE_LOCATIONS_LIST_PARAMETERS:
        draft.query = action.payload.query;
        draft.page = action.payload.page;
        return draft;

      case LOCATIONS_ACTIONS.EXPORT_LOCATIONS_LIST_BEGIN:
        draft.exporting = true;
        draft.loading = false;
        draft.error = false;
        return draft;

      case LOCATIONS_ACTIONS.EXPORT_LOCATIONS_LIST_SUCCESS:
        draft.exporting = false;
        draft.loading = false;
        draft.error = false;
        return draft;

      case LOCATIONS_ACTIONS.EXPORT_LOCATIONS_LIST_ERROR:
        draft.exporting = false;
        draft.loading = false;
        draft.error = true;
        return draft;

      default:
        return draft;
    }
  });
};
