import React from 'react';
import TablesSearchParamInput from '../../components/TablesSearchInput/TablesSearchParamInput';
import TablesSearchSelectFilter from '../../components/TablesSearchSelectFilter/TablesSearchSelectFilter';
import { Option } from '../../../components/SelectFilter/SelectFilter';
import styles from './TablesDetailsPage.module.scss';
import TablesSelectFilter from '../../components/TablesSelectFilter/TablesSelectFilter';
import { LIST_OPTIONS } from '../../../utils/selectListUtils/listOptions';
import { IRevenueCenters } from '../../redux/initialStates/revenueCenters';
import { IPosOrderTypes } from '../../redux/initialStates/posOrderTypes';

export interface ToolbarComponentProps {
  revenueCenters: IRevenueCenters;
  posOrderTypes: IPosOrderTypes;
  showPosOrderType: boolean;
  disabledLoading: boolean;
  hideRevenueFilter?: boolean;
}

export const TablesToolbarComponent = (props: ToolbarComponentProps) => {
  const {
    revenueCenters: { revenueCenterList },
    posOrderTypes: { options: posOrderTypes },
    showPosOrderType,
    disabledLoading,
    hideRevenueFilter = false,
  } = props;
  const revenueCentersOptions: Option[] = revenueCenterList.map((rc) => ({
    value: rc._id,
    label: rc.revenueCenter,
  }));
  const posOrderTypesOptions: Option[] = [
    {
      value: '',
      label: 'All POS Order Types',
    },
    {
      value: 'default',
      label: 'Location Default',
    },
    ...posOrderTypes.map((posOrderType) => ({
      value: posOrderType.id,
      label: posOrderType.name || posOrderType.id,
    })),
  ];

  return (
    <div className={styles.filtersContainer}>
      <div className={styles.filterName}>
        <TablesSearchParamInput
          name='q'
          placeholder='Search by Table Name and Target Code'
          disabledLoading={disabledLoading}
        />
      </div>
      {!hideRevenueFilter && (
        <div className={styles.filterRevenueCenter}>
          <TablesSearchSelectFilter
            placeholder='Filter by Revenue Center'
            options={revenueCentersOptions}
            isMulti
            name='revenueCenter'
            disabledLoading={disabledLoading}
            isSearchable
          />
        </div>
      )}
      {showPosOrderType && (
        <div className={styles.filterPosOrderType}>
          <TablesSelectFilter name='orderType' options={posOrderTypesOptions} disabledLoading={disabledLoading} />
        </div>
      )}
      <div className={styles.filterTableType}>
        <TablesSelectFilter
          name='orderExperienceType'
          options={LIST_OPTIONS.orderExperienceTypesFilter}
          disabledLoading={disabledLoading}
        />
      </div>
    </div>
  );
};
