import React from 'react';
import IconButton from '../IconButton/IconButton';
import Toggle from '../Toggle/Toggle';

export interface FormPanelCollapsibleProps {
  title: string;
  children?: React.ReactNode;
  initiallyExpanded?: boolean;
  isEnabled?: boolean;
  withToggle?: boolean;
  handlePanelToggle?: (b: boolean) => void;
  loading?: boolean;
  withoutIcon?: boolean;
  containerAdditionalStyles?: string;
}

const FormPanelCollapsible = (props: FormPanelCollapsibleProps) => {
  const {
    title,
    children,
    initiallyExpanded = false,
    isEnabled = false,
    withToggle = false,
    handlePanelToggle = () => {},
    loading = false,
    withoutIcon = false,
    containerAdditionalStyles = '',
  } = props;

  const [isCollapsed, setIsCollapsed] = React.useState(!initiallyExpanded);
  const toggleCollapsed = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsCollapsed(!isCollapsed);
  };

  const collapsedClassName = isCollapsed ? 'form__panel--collapsable-collapsed' : '';
  const enabledClassName = isEnabled ? 'form__panel--collapsable-enabled' : '';

  return (
    <div
      className={`form__panel--collapsable ${collapsedClassName} ${enabledClassName}`}
      id={containerAdditionalStyles}
    >
      <div className='form__panel--collapsable__header'>
        <h3>
          {title}
          <span>(Disabled)</span>
        </h3>
        <div className='form__panel--collapsable__controls'>
          {withToggle && (
            <Toggle
              checked={isEnabled}
              onChange={(checked: boolean) => {
                handlePanelToggle(checked);
              }}
              loading={loading}
            ></Toggle>
          )}
          {!withoutIcon && (
            <IconButton onClick={toggleCollapsed} disabled={loading}>
              {isCollapsed ? (
                <i className='icon-dashboard-icons_angle-down'></i>
              ) : (
                <i className='icon-dashboard-icons_angle-up'></i>
              )}
            </IconButton>
          )}
        </div>
      </div>
      {children && <div className='form__panel--collapsable__content'>{children}</div>}
    </div>
  );
};

export default FormPanelCollapsible;
