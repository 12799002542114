import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/store';
import { IPayoutHistoryDetailsReportState } from './types';

export const selectPayoutHistoryDetailsReport = createSelector(
  (state: RootState) => state,
  ({ session, reports: { payoutHistoryDetails } }: RootState): IPayoutHistoryDetailsReportState & { contextId: string } => {
    return {
      contextId: session.contextSession.id,
      ...payoutHistoryDetails,
    };
  }
);
