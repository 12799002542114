import { ITag } from '@ready/menu.core';

export interface TagsListState {
  query: string;
  modalVisible: boolean;
  loading: boolean;
  tags: ITag[];
}

export const tagsListState: TagsListState = {
  query: '',
  modalVisible: false,
  loading: false,
  tags: [],
};
