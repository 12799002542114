import {
  ILocationMobileOrdering,
  ILocationOrderAhead,
  IPayAtTable,
  GuestInformationField,
  IPickupHours,
  ILocationDigitalMenuConfig,
  CommentSubmissionMethod,
  OrderIntegrationType,
  ILocationServerFlow,
} from '@ready/dashboardv2api.contracts';
import { ICachedPosModifier } from '@ready/menu.core';

export interface IGuestInfoFieldError {
  fieldName: boolean;
  placeholder: boolean;
  methodForSending: boolean;
  charLimit: boolean;
}
export interface IPickUpHourError {
  start: boolean;
  end: boolean;
  offset: boolean;
}

export interface IPickUpErrors {
  monday: IPickUpHourError;
  tuesday: IPickUpHourError;
  wednesday: IPickUpHourError;
  thursday: IPickUpHourError;
  friday: IPickUpHourError;
  saturday: IPickUpHourError;
  sunday: IPickUpHourError;
}

export interface IOrderAheadValidation {
  orderAhead: {
    submitOrderTimeVia: {
      hasError: boolean;
      error: '';
    };
    acceptOrdersFor: {
      hasError: boolean;
      error: '';
    };
    futureDaysLimit: {
      hasError: boolean;
      error: '';
    };
    pickUpHours: {
      hasError: boolean;
      errors: IPickUpErrors;
    };
    guestInfoFields: {
      hasError: boolean;
      errors: IGuestInfoFieldError[];
    };
    guestInfoPosItemId: {
      hasError: boolean;
      error: string;
    };
    orderThrottling: {
      amount: {
        hasError: boolean;
        error: string;
      };
    };
  };
}

export interface IDigitalMenuPanel extends ILocationDigitalMenuConfig {
  isDirty: boolean;
}

export interface IPOSModifierSelection {
  items: ICachedPosModifier[];
  selectedItem?: ICachedPosModifier;
  loading: boolean;
  error: boolean;
}
export interface IMobileOrderingPanel extends ILocationMobileOrdering {
  posModifierSelection: IPOSModifierSelection;
}

export interface IServerFlowPanel extends ILocationServerFlow {
  isDirty: boolean;
}

export interface IFeaturesPanel {
  payAtTable: IPayAtTable;
  mobileOrdering: IMobileOrderingPanel;
  orderAhead: ILocationOrderAhead;
  digitalMenu: IDigitalMenuPanel;
  serverFlow: IServerFlowPanel;
  validation: IOrderAheadValidation;
}

export const getInitialMobileOrdering = (): IMobileOrderingPanel => {
  return {
    orderPickupEnabled: false,
    alcoholOrderingEnabled: false,
    alcoholPolicy: '',
    disruptionMessage: '',
    menuAnnouncementsMessage: '',
    checkoutAnnouncementMessage: '',
    itemComments: {
      commentSubmissionMethod: CommentSubmissionMethod.AttachCommentModifier,
      enableItemComments: false,
      maxItemCommentLength: 120,
    },
    posModifierSelection: {
      items: [],
      loading: false,
      error: false,
    },
    orderIntegration: {
      emailSubmissionAddress: '',
      emailSubmissionEnabled: false,
      orderIntegrationType: OrderIntegrationType.Full,
    },
    enableOrderLocationWidget: false,
    orderConfirmedMessage: '',
    orderingEnabled: false,
    pickupInstructions: '',
    pickupLocationName: '',
    orderDoesNotRequireImmediatePayment: false,
    alwaysAppendOrdersToExistingCheck: false,
    orderSmsEnabled: false,
  };
};

export const getInitialPickUpHours = (): IPickupHours => {
  return {
    monday: {
      start: '00:00',
      end: '23:59',
      offset: '30',
    },
    tuesday: {
      start: '00:00',
      end: '23:59',
      offset: '30',
    },
    wednesday: {
      start: '00:00',
      end: '23:59',
      offset: '30',
    },
    thursday: {
      start: '00:00',
      end: '23:59',
      offset: '30',
    },
    friday: {
      start: '00:00',
      end: '23:59',
      offset: '30',
    },
    saturday: {
      start: '00:00',
      end: '23:59',
      offset: '30',
    },
    sunday: {
      start: '00:00',
      end: '23:59',
      offset: '30',
    },
  };
};

export const getOrderAheadValidation = () => {
  return {
    orderAhead: {
      submitOrderTimeVia: {
        hasError: false,
        error: '',
      },
      acceptOrdersFor: {
        hasError: false,
        error: '',
      },
      futureDaysLimit: {
        hasError: false,
        error: '',
      },
      pickUpHours: {
        hasError: false,
        errors: getPickUpHourErrors(),
      },
      guestInfoFields: {
        hasError: false,
        errors: [],
      },
      guestInfoPosItemId: {
        hasError: false,
        error: '',
      },
      orderThrottling: {
        amount: {
          hasError: false,
          error: '',
        },
      },
    },
  };
};

const getPickUpHourErrors = () => {
  return {
    monday: {
      start: false,
      end: false,
      offset: false,
    },
    tuesday: {
      start: false,
      end: false,
      offset: false,
    },
    wednesday: {
      start: false,
      end: false,
      offset: false,
    },
    thursday: {
      start: false,
      end: false,
      offset: false,
    },
    friday: {
      start: false,
      end: false,
      offset: false,
    },
    saturday: {
      start: false,
      end: false,
      offset: false,
    },
    sunday: {
      start: false,
      end: false,
      offset: false,
    },
  };
};

export const getInitialOrderAhead = (): ILocationOrderAhead => {
  return {
    enableOrderAhead: false,
    submitOrderTimeVia: undefined,
    pickUpHours: getInitialPickUpHours(),
    guestInfoFields: [] as GuestInformationField[],
    guestInfoPosItemId: '',
  };
};

const getInitialPayAtTable = (): IPayAtTable => {
  return {
    allowMultiSelect: false,
    payAtTableEnabled: false,
    paymentConfirmationMessage: '',
    payByItem: false,
    payBySeat: false,
    verifyTicket: false,
    requireEmailAddressOnCheckout: false,
  };
};

const getInitialDigitalMenu = (): IDigitalMenuPanel => {
  return {
    digitalMenuFeatureEnabled: false,
    enabled: undefined,
    menuMode: undefined,
    isDirty: false,
  };
};

const getInitialServerFlow = (): IServerFlowPanel => {
  return {
    serverFlowFeatureEnabled: false,
    chatAssistantFeatureEnabled: false,
    isDirty: false,
  };
};

export const featuresPanel = {
  payAtTable: getInitialPayAtTable(),
  mobileOrdering: getInitialMobileOrdering(),
  orderAhead: getInitialOrderAhead(),
  digitalMenu: getInitialDigitalMenu(),
  serverFlow: getInitialServerFlow(),
} as IFeaturesPanel;
