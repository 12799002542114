import { ISmsNotificationsConfig, OrderSmsMessageSource } from '@ready/dashboardv2api.contracts';

export interface IOrderSmsConfigsState {
  loading: boolean;
  orderSmsConfigData: ISmsNotificationsConfig;
}

export const orderSmsConfigsInitialState: IOrderSmsConfigsState = {
  loading: false,
  orderSmsConfigData: {
    isEnabled: false,
    messageSource: OrderSmsMessageSource.Company,
    companyConfig: {
      orderPlaced: {
        isActive: false,
        value: '',
      },
      orderInProgress: {
        isActive: false,
        value: '',
      },
      orderReady: {
        isActive: false,
        value: '',
      },
      orderCancelled: {
        isActive: false,
        value: '',
      },
      pickUpTimeUpdated: {
        isActive: false,
        value: '',
      },
    },
    locationConfig: {
      orderPlaced: {
        isActive: false,
        value: '',
      },
      orderInProgress: {
        isActive: false,
        value: '',
      },
      orderReady: {
        isActive: false,
        value: '',
      },
      orderCancelled: {
        isActive: false,
        value: '',
      },
      pickUpTimeUpdated: {
        isActive: false,
        value: '',
      },
    },
  },
};
