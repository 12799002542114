import React from 'react';
import { OrderFulfillmentStatus } from '@ready/dashboardv2api.contracts';
import styles from './OrderStatusButtonMenuOption.module.scss';
import classNames from 'classnames';
import { OrderStatusLabel } from '../../../enums/orders.enum';

interface ButtonMenuOptionProps {
  orderStatus: OrderFulfillmentStatus;
  activeOrderStatus: OrderFulfillmentStatus;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

interface MenuItemAttribute {
  label: string;
  activeMenuItemStyle: string;
}

const getMenuItemAttributes = (
  orderStatus: OrderFulfillmentStatus,
  activeOrderStatus: OrderFulfillmentStatus
): MenuItemAttribute => {
  switch (orderStatus) {
    case OrderFulfillmentStatus.Pending:
      return {
        label: OrderStatusLabel.PENDING,
        activeMenuItemStyle: activeOrderStatus === 'pending' ? styles.pendingMenuItem : '',
      };

    case OrderFulfillmentStatus.InProgress:
      return {
        label: OrderStatusLabel.IN_PROGRESS,
        activeMenuItemStyle: activeOrderStatus === 'processed' ? styles.inProgressMenuItem : '',
      };

    case OrderFulfillmentStatus.Ready:
      return {
        label: OrderStatusLabel.READY,
        activeMenuItemStyle: activeOrderStatus === 'ready' ? styles.readyMenuItem : '',
      };

    case OrderFulfillmentStatus.Complete:
      return {
        label: OrderStatusLabel.COMPLETED,
        activeMenuItemStyle: activeOrderStatus === 'complete' ? styles.completedMenuItem : '',
      };

    case OrderFulfillmentStatus.Cancelled:
      return {
        label: OrderStatusLabel.CANCELLED,
        activeMenuItemStyle: activeOrderStatus === 'cancelled' ? styles.cancelledMenuItem : '',
      };
  }
};

const OrderStatusButtonMenuOption = ({ orderStatus, activeOrderStatus, onClick }: ButtonMenuOptionProps) => {
  const { label, activeMenuItemStyle: menuItemStyle } = getMenuItemAttributes(orderStatus, activeOrderStatus);

  return (
    <button className={classNames(styles.menuItem, menuItemStyle)} onClick={onClick}>
      {label}
    </button>
  );
};

export default OrderStatusButtonMenuOption;
