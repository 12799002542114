import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/browser';
import config from 'config';

export class SentryService {
  public static initialize(): void {
    if (config.environment !== 'local') {
      Sentry.init({
        environment: config.environment,
        integrations: [new BrowserTracing()],
        dsn: 'https://9e88b2b88c174190a477d85d0eee24a5@o1410452.ingest.sentry.io/6753734',
        tracesSampleRate: 0.1,
      });
    }
  }
}
