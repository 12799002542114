import React from 'react';
import { cloneDeep } from 'lodash';

import styles from './TimeSlotSelector.module.scss';
import DayOfWeekSelector from '../DayOfWeekSelector/DayOfWeekSelector';
import TimePicker from '../TimePicker/TimePicker';
import IconButton from '../IconButton/IconButton';
import TextIcon, { Icon } from '../Icon/TextIcon';

export interface TimeSlot {
  daysOfWeek: number[];
  startHour?: number;
  startMinute?: number;
  endHour?: number;
  endMinute?: number;
}

interface TimeSlotSelectorProps {
  value: TimeSlot;
  onChange: (timeSlot: TimeSlot) => void;
  onClearClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  daysOfWeekErrorMessage?: string;
  startTimeErrorMessage?: string;
  endTimeErrorMessage?: string;
  processing?: boolean;
  disableClearClick?: boolean;
  canManageAllSchedules?: boolean;
}

const TimeSlotSelector = ({
  value,
  onChange,
  onClearClick,
  daysOfWeekErrorMessage = '',
  startTimeErrorMessage = '',
  endTimeErrorMessage = '',
  processing = false,
  disableClearClick = false,
  canManageAllSchedules = true,
}: TimeSlotSelectorProps): JSX.Element => {
  const handleDayOfWeekChange = (daysOfWeek: Array<number>) => {
    onChange(getUpdatedValue(value, { daysOfWeek }));
  };

  const handleStartTimeChange = (startTime: string) => {
    const { hour: startHour, minute: startMinute } = getTimeParts(startTime);
    onChange(getUpdatedValue(value, { startHour, startMinute }));
  };

  const handleEndTimeChange = (endTime: string) => {
    const { hour: endHour, minute: endMinute } = getTimeParts(endTime);
    onChange(getUpdatedValue(value, { endHour, endMinute }));
  };

  return (
    <div className={styles.panel}>
      <div className={styles.controlContainer}>
        <div className={styles.dayOfWeekContainer}>
          <DayOfWeekSelector
            value={value.daysOfWeek}
            onChange={handleDayOfWeekChange}
            errorMessage={daysOfWeekErrorMessage}
            disabled={processing || !canManageAllSchedules}
          />
          <div className={styles.clearButtonContainer}>
            {canManageAllSchedules && (
              <IconButton
                disabled={processing || disableClearClick}
                onClick={onClearClick}
                additionalClassName={styles.clearButton}
              >
                <TextIcon icon={Icon.RemoveCircle} additionalStyles={styles.clearIcon} />
              </IconButton>
            )}
          </div>
        </div>
        <div className={styles.timeRangeContainer}>
          <div className={styles.timeInputContainer}>
            <span className={styles.timeInputLabel}>Start Time</span>
            <TimePicker
              showSeconds={false}
              onChange={handleStartTimeChange}
              additionalInputStyles={styles.timeInput}
              darkBorder
              containerPadding='small'
              placeholder='12:00:00'
              disabled={processing || !canManageAllSchedules}
              value={
                (value.startHour || value.startHour === 0) && (value.startMinute || value.startMinute === 0)
                  ? getTime(value.startHour, value.startMinute)
                  : undefined
              }
            />
            <span className={styles.errorMessage}>{startTimeErrorMessage}</span>
          </div>
          <div className={styles.timeInputContainer}>
            <span className={styles.timeInputLabel}>End Time</span>
            <TimePicker
              showSeconds={false}
              onChange={handleEndTimeChange}
              additionalInputStyles={styles.timeInput}
              darkBorder
              containerPadding='small'
              placeholder='12:00:00'
              disabled={processing || !canManageAllSchedules}
              value={
                (value.endHour || value.endHour === 0) && (value.endMinute === 0 || value.endMinute)
                  ? getTime(value.endHour, value.endMinute)
                  : undefined
              }
            />
            <span className={styles.errorMessage}>{endTimeErrorMessage}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

interface TimeParts {
  hour: number;
  minute: number;
}

/**
 * Gets the hours and minutes numbers from a 24h time string
 * @param value Time string of the 24h format HH:mm
 * @returns An object containing the hours and minutes representation as numbers
 */
const getTimeParts = (value: string): TimeParts => ({
  hour: +value.substr(0, 2),
  minute: +value.substr(3, 2),
});

/**
 * Accepts a 24h hour and minute numbers, and returns a 24h formatted time
 * string
 * @param hour 24h numerical representation of hours
 * @param minute Numerical representation of minutes
 * @returns Formatted 24h string
 */
const getTime = (hour: number, minute: number): string =>
  `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:00`;

/**
 * Creates an updated copy of an TimeSlot value. Does not mutate the
 * original value
 *
 * @param value The original TimeSlot
 * @param valuePropertiesToUpdate A partial TimeSlot, containing only the
 * properties that should be updated
 * @returns An updated copy of the passed in value
 */
const getUpdatedValue = (value: TimeSlot, valuePropertiesToUpdate: Partial<TimeSlot>): TimeSlot => {
  // Deep copy to ensure deeply nested values were copied, not their references
  const newValue = cloneDeep(value);

  return {
    ...newValue,
    ...valuePropertiesToUpdate,
  };
};

export default TimeSlotSelector;
