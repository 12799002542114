import { useEffect } from 'react';

export const usePageTitle = (...titles: string[]) => {
  const baseTitle = 'Ready Dashboard';

  useEffect(() => {
    titles.push(baseTitle);
    document.title = titles.join(' | ');
  }, [titles]);

  useEffect(() => {
    return () => {
      document.title = baseTitle;
    };
  }, []);
};
