import { CellProps, getCellClasses } from 'components/TableV2/Cell/Cell';
import React from 'react';
import { IPaymentDataReportItem, PaymentDirection } from '@ready/dashboardv2api.contracts';
import { DollarValue } from 'components/Value';

import cellStyles from 'components/TableV2/Cell/Cell.module.scss';

export const TotalDollarCell = (props: CellProps<IPaymentDataReportItem>) => {
  const amount = props.rowData.amount ?? 0;
  const direction = props.rowData.direction;

  return (
    <DollarValue
      additionalStyles={getCellClasses(props.classes, cellStyles[`tableV2__cellTextAlign--${props.align ?? 'right'}`])}
      value={direction === PaymentDirection.Incoming ? amount : -amount}
    />
  );
};
