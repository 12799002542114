import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../redux/initialStates/AppState';
import LayoutContent from '../components/AppLayout/LayoutContent';
import LocationPicker from '../components/LocationPicker/LocationPicker';
import { loadLocations } from './redux/ServerAssignmentLocationActions';
import { useParams } from 'react-router-dom';
import { ContextParams } from '../types/ContextParams.interface';
import { pageErrorState } from '../redux/actions/uiActions/responseStateActions';

const ServerAssignmentLocationPickerPage = ({
  loading,
  pagination,
  locations,
  loadLocations,
}: ReduxProps): JSX.Element | null => {
  const path = `locations/:id/serverAssignment`;
  const { contextId } = useParams<ContextParams>();

  const handleLoadLocations = React.useCallback(
    async (query?: string, page?: number, limit?: number) => {
      await loadLocations(contextId, query, {
        number: page ? page : 1,
        size: limit ? limit : 15,
      });
    },
    [contextId, loadLocations]
  );

  return (
    <LayoutContent title='Server Assignment' containerType='within'>
      <LocationPicker
        loading={loading}
        pagination={pagination}
        locations={locations}
        path={path}
        handleLoadLocations={handleLoadLocations}
      />
    </LayoutContent>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    loading: state.tableServerAssignment.serverAssignmentLocations.locationPicker.loading,
    pagination: state.tableServerAssignment.serverAssignmentLocations.locationPicker.pagination,
    locations: state.tableServerAssignment.serverAssignmentLocations.locationPicker.locations,
    permissions: state.session.permissions.permissionsList,
  };
};

const actionCreators = {
  loadLocations,
  pageErrorState,
};

const connector = connect(mapStateToProps, actionCreators);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ServerAssignmentLocationPickerPage);
