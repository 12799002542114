import { ICheckDetails } from '@ready/dashboardv2api.contracts';

export enum EventTypes {
  tabOrder = 'tabOrder',
  payment = 'payment',
  checkCreated = 'checkCreated',
  closed = 'closed',
  order = 'order',
}

export type ICheckPageState = {
  data?: ICheckDetails;
  loading?: boolean;
  errors?: Array<string>;
  retryPOSPaymentLoading?: boolean;
};

export const defaultCheckDetails: ICheckPageState = {
  loading: true,
  errors: undefined,
  retryPOSPaymentLoading: false,
};
