import React from 'react';

import MenuBuilderTab from '../types/MenuBuilderTab.enum';
import MenuBuilderTabs from './MenuBuilderTabs';

import LayoutContent from '../../components/AppLayout/LayoutContent';
import LocationSelectorLink from '../../components/AppLayout/LocationSelectorLink';
import { Routes } from '../LocationsRouter';

export interface MenuBuilderLayoutContentProps {
  companyId: string;
  locationId: string;
  locationName: string | null;
  stockStatusOnly: boolean;
  tab: MenuBuilderTab;
  children?: React.ReactNode;
}

const MenuBuilderLayoutContent = (props: MenuBuilderLayoutContentProps): JSX.Element => {
  const { companyId, locationId, locationName, stockStatusOnly, tab, children = null } = props;

  const to = Routes.getLocationsPage(companyId);

  return (
    <LayoutContent
      title='Location Menus'
      titleControls={locationName ? <LocationSelectorLink text={locationName} to={to} /> : null}
      containerType='within'
    >
      <MenuBuilderTabs companyId={companyId} locationId={locationId} stockStatusOnly={stockStatusOnly} tab={tab} />
      {children}
    </LayoutContent>
  );
};

export default MenuBuilderLayoutContent;
