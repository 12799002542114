import { GiftCardProvider, IGiftCardConfig, IPayPalOptions } from '@ready/dashboardv2api.contracts';
import { ITenderType } from '../companyLocations/redux/initialStates/paymentProcessing';
import config from '../config';
import executeApiAsync from './utils/executeApiAsync';

export enum PaymentProviderType {
  PayPal = 'paypal',
}

export interface ILocationPaymentConfig {
  locationId: string;
  provider: PaymentProviderType;
  options: IPayPalOptions;
}

export const getPayPalSignupLink = async (companyId: string, locationId: string): Promise<string> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/paymentProcessing/paypal/signupLink`;
  const result = await executeApiAsync<{ signUpLink: string }>(url);
  return result.signUpLink;
};

export class PaymentProcessingService {
  private static KEYBASE = 'ReadyIntegrationoRequestConfirmation';
  private static SUCCESSVALUE = 'true';

  public static checkConfirmationKey = (type: string, locationId: string): boolean => {
    return (
      localStorage.getItem(`${PaymentProcessingService.KEYBASE}-${type}-${locationId}`) ===
      PaymentProcessingService.SUCCESSVALUE
    );
  };

  public static setConfirmationKey = (type: string, locationId: string): void => {
    localStorage.setItem(
      `${PaymentProcessingService.KEYBASE}-${type}-${locationId}`,
      PaymentProcessingService.SUCCESSVALUE
    );
  };

  public static removeConfirmationKey = (type: string, locationId: string): void => {
    localStorage.removeItem(`${PaymentProcessingService.KEYBASE}-${type}-${locationId}`);
  };

  public static getPayPalConfig = (companyId: string, locationId: string): Promise<ILocationPaymentConfig> => {
    const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/integrations/paypal`;

    return executeApiAsync<ILocationPaymentConfig>(url);
  };

  public static getPayPalLink = (
    companyId: string,
    locationId: string,
    redirectUrl: string
  ): Promise<{ href: string }> => {
    const url = `${config.readyApiLegacyEndpoint}/companies/${companyId}/locations/${locationId}/integrations/paypal/connection`;
    return executeApiAsync<{ href: string }>(url, {
      method: 'POST',
      body: JSON.stringify({ redirectUrl }),
    });
  };

  public static updatePayPalConfig = (
    companyId: string,
    locationId: string,
    options: IPayPalOptions
  ): Promise<ILocationPaymentConfig> => {
    const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/integrations/paypal`;

    return executeApiAsync<ILocationPaymentConfig>(url, {
      method: 'PUT',
      body: JSON.stringify(options),
    });
  };

  public static unlinkPayPalAccount = async (
    companyId: string,
    locationId: string
  ): Promise<ILocationPaymentConfig> => {
    const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/integrations/paypal/unlink`;

    return executeApiAsync<ILocationPaymentConfig>(url, {
      method: 'PUT',
    });
  };

  public static getTenderTypes = (companyId: string, locationId: string): Promise<ITenderType[]> => {
    const url = `${config.readyApiLegacyEndpoint}/companies/${companyId}/locations/${locationId}/tenderTypes`;
    return executeApiAsync<ITenderType[]>(url);
  };

  public static getGiftCardConfig = (
    companyId: string,
    locationId: string,
    provider?: GiftCardProvider
  ): Promise<IGiftCardConfig> => {
    const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/giftcardconfig${
      provider ? `?provider=${provider}` : ''
    }`;

    return executeApiAsync<IGiftCardConfig>(url);
  };

  public static setGiftCardConfig = (companyId: string, locationId: string, giftCardConfig: IGiftCardConfig) => {
    const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/giftcardconfig`;
    return executeApiAsync<IGiftCardConfig>(url, {
      method: 'PUT',
      body: JSON.stringify(giftCardConfig),
    });
  };
}
