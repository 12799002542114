import { Option } from 'components/SelectFilter/SelectFilter';
import React from 'react';
import { components, IndicatorProps } from 'react-select';
import TextIcon, { Icon } from '../Icon/TextIcon';
import styles from './DropdownIndicator.module.scss';

const DropdownIndicator = (props: IndicatorProps<Option, boolean>) => (
  <components.DropdownIndicator {...props}>
    <TextIcon icon={Icon.CaretDownTiny} additionalStyles={styles.dropdownIcon} />
  </components.DropdownIndicator>
);

export default DropdownIndicator;
