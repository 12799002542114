import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { AppState } from '../../redux/initialStates/AppState';
import { IRoleLocation } from '../../services/types/companyUsers.type';

import InfiniteScrollingList from '../../components/InfiniteScrollingList/InfiniteScrollingList';
import UsersAddLocationsDialogListItem from './UsersAddLocationsDialogListItem';
import { ILocationToShow } from '@ready/dashboardv2api.contracts';

export type UsersAddLocationsDialogListProps = ConnectedProps<typeof connector> & {
  loadLocations: (query?: string) => void;
  loadMoreLocations: (query: string | undefined, page: number) => void;
  addLocationToUserPermission: (location: ILocationToShow, permissionIndex: number) => void;
  removeLocationFromUserPermission: (locationId: string, permissionIndex: number) => void;
};

const buildItemComponent = (location: ILocationToShow, controls: JSX.Element | null): JSX.Element => (
  <UsersAddLocationsDialogListItem key={location._id} location={location} controls={controls!} />
);

const UsersAddLocationsDialogList = (props: UsersAddLocationsDialogListProps): JSX.Element => {
  const {
    loading,
    error,
    query,
    page,
    paginationLoading,
    paginationAvailable,
    filtered,
    locations,
    permissionIndex,
    selectedLocations = [],
    loadLocations,
    loadMoreLocations,
    addLocationToUserPermission,
    removeLocationFromUserPermission,
  } = props;

  const loadItems = React.useCallback((): void => {
    loadLocations(query);
  }, [loadLocations, query]);

  const loadMoreItems = React.useCallback((): void => {
    loadMoreLocations(query, page);
  }, [loadMoreLocations, query, page]);

  const addItem = (location: ILocationToShow): void => {
    addLocationToUserPermission(location, permissionIndex);
  };

  const removeItem = (locationId: string): void => {
    removeLocationFromUserPermission(locationId, permissionIndex);
  };

  const selectedLocationIds = selectedLocations.map((location: IRoleLocation): string => location.id);

  return (
    <InfiniteScrollingList
      loading={loading}
      error={error}
      paginationLoading={paginationLoading}
      paginationAvailable={paginationAvailable}
      filtered={filtered}
      items={locations}
      selectedItemKeys={selectedLocationIds}
      addButtonLabel='Assign'
      loadItems={loadItems}
      loadMoreItems={loadMoreItems}
      getItemKey={(location: ILocationToShow) => location._id}
      buildItemComponent={buildItemComponent}
      addItem={addItem}
      removeItem={removeItem}
      isInModal
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: state.companyLocations.loading,
  error: state.companyLocations.error,
  query: state.companyLocations.query,
  page: state.companyLocations.page,
  paginationLoading: state.companyLocations.paginationLoading,
  paginationAvailable: state.companyLocations.paginationAvailable,
  filtered: state.companyLocations.filtered,
  locations: state.companyLocations.items,
  permissionIndex: state.companyLocations.permissionIndex,
  selectedLocations: state.users.usersForm.roles
    ? state.users.usersForm.roles![state.companyLocations.permissionIndex].locations
    : undefined,
});

const actionCreators = {};

const connector = connect(mapStateToProps, actionCreators);

export default connector(UsersAddLocationsDialogList);
