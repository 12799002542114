import { IPickupHours, ISpecialOrderHours } from '@ready/dashboardv2api.contracts';
import { createAsyncThunk } from '@reduxjs/toolkit';

import config from 'config';

import { interceptErrorWithToastMessage } from 'redux/helpers/interceptErrorWithToastMessage';
import executeApiAsync from 'services/utils/executeApiAsync';

const getClientOrderHours = ({
  companyId,
  locationId,
}: {
  companyId: string;
  locationId: string;
}): Promise<IPickupHours> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/orderHours`;
  return executeApiAsync<IPickupHours>(url);
};

const updateClientOrderHours = ({
  companyId,
  locationId,
  settings,
}: {
  companyId: string;
  locationId: string;
  settings: IPickupHours;
}): Promise<IPickupHours> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/orderHours`;
  return executeApiAsync<IPickupHours>(url, { method: 'PUT', body: JSON.stringify(settings) });
};

const getClientSpecialOrderHours = ({
  companyId,
  locationId,
}: {
  companyId: string;
  locationId: string;
}): Promise<ISpecialOrderHours[]> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/specialOrderHours`;
  return executeApiAsync<ISpecialOrderHours[]>(url);
};

const saveClientSpecialOrderHours = ({
  companyId,
  locationId,
  hours,
}: {
  companyId: string;
  locationId: string;
  hours: ISpecialOrderHours[];
}): Promise<ISpecialOrderHours[]> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/specialOrderHours`;
  return executeApiAsync<ISpecialOrderHours[]>(url, { method: 'PUT', body: JSON.stringify(hours) });
};

export const getClientSpecialOrderHoursThunk = createAsyncThunk(
  'specialOrderHours/get',
  interceptErrorWithToastMessage(getClientSpecialOrderHours)
);

export const saveClientSpecialOrderHoursThunk = createAsyncThunk(
  'specialOrderHours/save',
  interceptErrorWithToastMessage(saveClientSpecialOrderHours)
);

export const getClientOrderHoursThunk = createAsyncThunk(
  'orderHours/get',
  interceptErrorWithToastMessage(getClientOrderHours)
);

export const updateClientOrderHoursThunk = createAsyncThunk(
  'orderHours/update',
  interceptErrorWithToastMessage(updateClientOrderHours, 'Success! Order hours updated.')
);
