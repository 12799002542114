import { SectionItemChange } from '../types/SectionItemChange.interface';

const findSectionItem = (sectionItemChanges: SectionItemChange[], id: string): SectionItemChange =>
  sectionItemChanges.find((sectionItemChange) => sectionItemChange.itemId === id) || {
    itemId: '',
    itemType: 'item',
    displayName: '',
    originalDisplayName: '',
    modified: false,
    validation: {
      displayName: {
        hasError: false,
        errorMessage: '',
      },
    },
  };

export default findSectionItem;
