import _ from 'lodash';
import { ILocationEditModel } from '@ready/dashboardv2api.contracts';
import { ILocationState } from '../../../locations/redux/LocationState';
export class LocationStateMapper {
  public static mapToEditApiModel(location: ILocationState, lastSaveBy: string, lastSaveAt: Date): ILocationEditModel {
    const apiPayload: ILocationEditModel = {
      details: location.detailsPanel.details,
      mobileOrdering: location.featuresPanel.mobileOrdering,
      orderAhead: location.featuresPanel.orderAhead,
      payAtTable: location.featuresPanel.payAtTable,
      posSettings: location.posSettingsPanel,
      paymentProcessor: location.paymentProcessingPanel,
      lastSaveBy: {
        name: lastSaveBy,
      },
      lastSaveAt: lastSaveAt,
    };

    // send to api only if dirty
    if (location.brandingPanel.isDirty) {
      apiPayload.branding = _.omit(location.brandingPanel, ['isDirty', 'validation']);
    }

    if (location.gratuityPanel.isDirty) {
      apiPayload.gratuity = _.omit(location.gratuityPanel, ['isDirty', 'validation']);
    }

    if (location.newsletterPanel.isDirty) {
      apiPayload.newsletter = _.omit(location.newsletterPanel, ['isDirty', 'validation']);
    }

    if (location.emailReceiptsPanel.isDirty) {
      apiPayload.emailReceipts = _.omit(location.emailReceiptsPanel, ['isDirty', 'validation']);
    }

    if (location.featuresPanel.digitalMenu.isDirty) {
      apiPayload.digitalMenu = _.omit(location.featuresPanel.digitalMenu, ['isDirty']);
    }

    if (location.featuresPanel.mobileOrdering.posModifierSelection) {
      apiPayload.mobileOrdering = _.omit(location.featuresPanel.mobileOrdering, ['posModifierSelection']);
    }

    if (location.featuresPanel.serverFlow.isDirty) {
      apiPayload.serverFlow = _.omit(location.featuresPanel.serverFlow, ['isDirty']);
    }
    return apiPayload;
  }
}
