import React from 'react';
import styles from './PermissionItemView.module.scss';

export interface PermissionItemViewProps {
  label: string;
  description: string;
  isActive: boolean;
}

const PermissionItemView: React.FC<PermissionItemViewProps> = (props: PermissionItemViewProps) => {
  const { label, description, isActive } = props;

  return (
    <div className={styles.permissionItemView}>
      {isActive ? (
        <span className={styles.active}>
          <i className='icon-dashboard-icons_checkmark-circle'></i>
        </span>
      ) : (
        <span className={styles.notActive}>
          <i className='icon-dashboard-icons_remove-circle'></i>
        </span>
      )}
      <div className={styles.permissionItemDescription}>
        <h5 className={styles.permissionViewTitle}>{label}</h5>
        <p className={styles.permissionViewDescription}>{description}</p>
      </div>
    </div>
  );
};

export default PermissionItemView;
