import { PaginationResponse } from '@ready/dashboardv2api.contracts';
import {
  IBulkEditMenuItemGroup,
  ITemplateMenuItemGroup,
  ITemplateMenuItemGroupRequest,
  WithAssignedLocationsCount,
} from '@ready/menu.core';
import { IPagedResponse } from '@ready/table.core';
import config from 'config';
import executeApiAsync from 'services/utils/executeApiAsync';
import toPaginationResponse from 'services/utils/toPaginationResponse';
import { mapITemplateMenuItemGroupToITemplateMenuItemRequest } from 'sharedMenuItems/mappers/mappers';
import { objectToQueryParamsString } from 'utils/urlUtils/objectToQueryParamsString';

export const getTemplateChildMenuItemGroups = async (
  companyId: string,
  itemGroupId: string,
  locationSearchTerm?: string,
  pageNumber: number = 1,
  pageSize: number = 50
): Promise<PaginationResponse<IBulkEditMenuItemGroup>> => {
  const queryString = objectToQueryParamsString({
    pageNumber: pageNumber.toString(),
    pageSize: pageSize.toString(),
    lq: locationSearchTerm,
  });

  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/itemGroups/${itemGroupId}/locationItemGroups`;
  const result = await executeApiAsync<IPagedResponse<IBulkEditMenuItemGroup>>(`${url}?${queryString}`, {
    method: 'GET',
  });
  return toPaginationResponse({ results: result.results, totalCount: result.totalCount }, pageNumber, pageSize);
};

export const getSharedItemGroupList = async (
  companyId: string,
  query: string = '',
  locationId?: string,
  pageNumber: number = 1,
  pageSize: number = 50
): Promise<PaginationResponse<WithAssignedLocationsCount<ITemplateMenuItemGroup>>> => {
  const queryString = objectToQueryParamsString({
    pageNumber: pageNumber.toString(),
    pageSize: pageSize.toString(),
    locationId,
    searchTerm: query,
  });

  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/itemGroups`;
  const result = await executeApiAsync<IPagedResponse<WithAssignedLocationsCount<ITemplateMenuItemGroup>>>(
    `${url}?${queryString}`,
    {
      method: 'GET',
    }
  );

  return toPaginationResponse({ results: result.results, totalCount: result.totalCount }, pageNumber, pageSize);
};

export const getItemGroup = async (companyId: string, itemGroupId: string = ''): Promise<ITemplateMenuItemGroup> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/itemGroups/${itemGroupId}`;
  return await executeApiAsync<ITemplateMenuItemGroup>(url, {
    method: 'GET',
  });
};

export const createItemGroup = async (
  companyId: string,
  itemGroup: ITemplateMenuItemGroup
): Promise<ITemplateMenuItemGroup> => {
  const itemToCreate = {
    ...mapITemplateMenuItemGroupToITemplateMenuItemRequest(itemGroup),
    _id: undefined,
  } as Omit<ITemplateMenuItemGroupRequest, '_id'>;

  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/itemGroups`;
  return await executeApiAsync<ITemplateMenuItemGroup>(url, {
    method: 'POST',
    body: JSON.stringify(itemToCreate),
  });
};

export const updateItemGroup = async (
  companyId: string,
  itemGroup: ITemplateMenuItemGroupRequest
): Promise<ITemplateMenuItemGroup> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/itemGroups`;
  return await executeApiAsync<ITemplateMenuItemGroup>(url, {
    method: 'PUT',
    body: JSON.stringify(itemGroup),
  });
};

export const deleteItemGroup = async (companyId: string, itemGroupId: string): Promise<void> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/itemGroups/${itemGroupId}`;
  return await executeApiAsync<void>(url, {
    method: 'DELETE',
  });
};
