import styles from './GuestSatisfaction.module.scss';
import classnames from 'classnames';
import React from 'react';

interface FactorProps {
  text: string;
  positivePercent: number;
  negativePercent: number;
  numberPoints?: number;
  numberSameColor?: number;
  colorShift?: number;
  renderLine?: boolean;
}

interface PointsProps {
  classMods: Array<string>;
  maxColorfulPoints: number;
  numberPoints: number;
  numberSameColor: number;
  colorShift: number;
  reverse?: boolean;
}

const Points = ({ classMods, maxColorfulPoints, reverse, numberPoints, colorShift, numberSameColor }: PointsProps) => (
  <div
    className={classnames(styles.satisfactionFactor__pointsContainer, {
      [styles['satisfactionFactor__pointsContainer--reverse']]: reverse,
    })}
  >
    {new Array(numberPoints).fill(null).map((ignored, index) => {
      const classIndex = Math.ceil((index + 1 + colorShift) / numberSameColor) - 1;
      const className =
        index < maxColorfulPoints
          ? styles[`satisfactionFactor__point--${classMods[classIndex]}`]
          : styles['satisfactionFactor__point--0'];

      return <span key={index} className={classnames(styles.satisfactionFactor__point, className)} />;
    })}
  </div>
);

export const SatisfactionFactor = ({
  negativePercent,
  positivePercent,
  text,
  numberPoints = 10,
  numberSameColor = 4,
  colorShift = 2,
}: FactorProps) => {
  return (
    <div className={styles.satisfactionFactor}>
      <span className={styles.satisfactionFactor__title}>{text}</span>
      <div className={styles.satisfactionFactor__points}>
        <Points
          numberPoints={numberPoints}
          colorShift={colorShift}
          numberSameColor={numberSameColor}
          maxColorfulPoints={negativePercent / numberPoints}
          classMods={['3', '2', '1']}
          reverse
        />
        <Points
          numberPoints={numberPoints}
          colorShift={colorShift}
          numberSameColor={numberSameColor}
          maxColorfulPoints={positivePercent / numberPoints}
          classMods={['3', '4', '5']}
        />
      </div>
    </div>
  );
};
