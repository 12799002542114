import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableHeader } from '../../components/TableV2/TableHeader/TableHeader';
import { TableV2 } from '../../components/TableV2/TableV2';
import { Body, BodySkeleton } from '../../components/TableV2/Body/Body';
import LayoutContent from '../../components/AppLayout/LayoutContent';
import HeaderControls, { ControlOption } from '../../components/AppLayout/HeaderControls';
import { ToolBar } from './components/ToolBar/ToolBar';
import { cells, headerCells, initDateFilter } from './locationPerformanceSettings';
import { usePageTitle, useSearchParams } from '../../hooks';
import { PaginationContainer } from '../../components/PaginationContainer/PaginationContainer';
import { useHistory, useLocation } from 'react-router-dom';
import { initDateFilterUrlQuery } from '../../utils/urlUtils/initDateFilterUrlQuery';
import {
  exportLocationPerformanceThunk,
  fetchLocationPerformanceThunk,
  fetchPermittedLocationsThunk,
} from './redux/thunks';
import { locationPerformanceSelector } from './redux/selectors';

export const LocationPerformancePage = () => {
  usePageTitle('Location Performance', 'Reports');
  const { query, page, sortColumn, sortDirection } = useSearchParams();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const { contextId, permittedLocations, loading, headerColumns, columns, rowKey, items, pagination } = useSelector(
    locationPerformanceSelector
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!query) {
      const redirectUrl = `${pathname}?${initDateFilterUrlQuery}`;
      push(redirectUrl);
    }
  }, [pathname, push, query, dispatch]);

  useEffect(() => {
    dispatch(fetchPermittedLocationsThunk(contextId));
  }, [contextId, dispatch]);

  useEffect(() => {
    if (query) {
      dispatch(fetchLocationPerformanceThunk({ contextId, query, page, sortColumn, sortDirection }));
    }
  }, [contextId, query, page, sortColumn, sortDirection, dispatch]);

  const headerControlOptions: Array<ControlOption> = [
    {
      buttonLabel: 'Export',
      loading: loading.export,
      onClick: async () => dispatch(exportLocationPerformanceThunk({ contextId, query, sortColumn, sortDirection })),
    },
  ];

  return (
    <LayoutContent
      title='Location Performance'
      headerControls={<HeaderControls controlOptions={headerControlOptions} />}
      toolbarContent={<ToolBar initDateFilter={initDateFilter} locations={permittedLocations.results} />}
      containerType='within'
      scrollToolbarForMobile
    >
      <PaginationContainer pagination={pagination}>
        <TableV2 loading={loading.list}>
          <>
            <TableHeader cells={headerCells} data={headerColumns} columns={columns} />
            {loading.list ? null : (
              <Body cells={cells} hiddenColumns={{}} rowKey={rowKey} data={items} columns={columns} />
            )}
            {loading.list ? <BodySkeleton columns={columns} rowsNumber={50} /> : null}
          </>
        </TableV2>
      </PaginationContainer>
    </LayoutContent>
  );
};
