import { ITemplateModifierGroup } from '@ready/menu.core';
import { DeleteConfirmationModal } from 'components/Modal';
import React, { useState } from 'react';
import { useAppDispatch } from 'redux/store';
import { deleteSharedModifierGroupThunk } from '../details/redux/thunks';

interface Props {
  companyId: string;
  modifierGroup: ITemplateModifierGroup;
  setShowModal: (show: boolean) => void;
  onDelete?: () => void;
}

export const DeleteSharedModifierGroupModal = ({ companyId, modifierGroup, setShowModal, onDelete }: Props) => {
  const dispatch = useAppDispatch();
  const [deleting, setDeleting] = useState<boolean>(false);

  const handleDelete = async () => {
    setDeleting(true);
    await dispatch(deleteSharedModifierGroupThunk({ companyId, id: modifierGroup._id }));
    if (onDelete) {
      onDelete();
    }
  };

  return (
    <DeleteConfirmationModal
      item={'Shared Modifier Group'}
      handleDelete={handleDelete}
      loading={deleting}
      setShowModal={setShowModal}
      itemName={modifierGroup.name}
    />
  );
};
