import React from 'react';
import InfiniteScrollingList from 'components/InfiniteScrollingList/InfiniteScrollingList';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  selectItemInModal,
  unselectForcedModifier,
  updateSharedMenuItemState,
} from 'sharedMenuItems/redux/createEditItem/createEditItemSlice';
import { selectCreateEditItemState } from 'sharedMenuItems/redux/selectors';
import { ItemWithAssignedLocations } from '../itemWithAssignedLocations/ItemWithAssignedLocations';
import { debounce } from 'lodash';
import {
  getForcedModifiersThunk,
  getMoreForcedModifiersThunk,
} from 'sharedMenuItems/redux/createEditItem/createEditItemThunkActions';
import { useParams } from 'react-router-dom';
import { ContextParams } from 'types/ContextParams.interface';
import { setFormIsDirty } from 'redux/actions/uiActions/formStateActions';
import { ITemplateForcedModifier, ITemplateMenuItem, WithAssignedLocationsCount } from '@ready/menu.core';
import { mapITemplateMenuItemToITemplateForcedModifier } from 'sharedMenuItems/mappers/mappers';
import styles from './ForcedModifiersModalList.module.scss';

const debounceTime = 300;

export const ForcedModifiersModalList = () => {
  const dispatch = useAppDispatch();
  const { contextId: companyId } = useParams<ContextParams>();
  const {
    modals: {
      forcedModifiersModal: {
        paginationAvailable,
        paginationLoading,
        error,
        filtered,
        selectedIds,
        items,
        query,
        page,
        loading,
      },
    },
    item,
  } = useAppSelector(selectCreateEditItemState);
  const noItemsTitle = 'No Results Found';
  const noItemsMessage =
    'Make sure the items you are looking for have been created in your point of sale, or try refining your search.';

  const selectModalItem = (forcedModifier: WithAssignedLocationsCount<ITemplateMenuItem>) => {
    const embededMenuModifier: ITemplateForcedModifier = mapITemplateMenuItemToITemplateForcedModifier(forcedModifier);

    dispatch(setFormIsDirty(true));
    dispatch(
      selectItemInModal({
        stateKey: 'forcedModifiersModal',
        itemId: embededMenuModifier.itemId,
      })
    );
    dispatch(
      updateSharedMenuItemState({
        forcedModifiers: [...(item?.forcedModifiers ?? []), embededMenuModifier],
      })
    );
  };

  const unselectModalItem = (id: string) => {
    dispatch(setFormIsDirty(true));
    dispatch(unselectForcedModifier(id));
  };

  const loadForcedModifiersDebounced = React.useMemo(() => {
    return debounce((companyId: string, query?: string) => {
      dispatch(getForcedModifiersThunk({ companyId, page: 1, query }));
    }, debounceTime);
  }, [dispatch]);

  const loadForcedModifiers = React.useCallback(() => {
    loadForcedModifiersDebounced(companyId, query);
  }, [loadForcedModifiersDebounced, companyId, query]);

  const nextPage = page + 1;

  const loadMoreForcedModifiersDebounced = React.useMemo(() => {
    return debounce((companyId: string, nextPage: number, query?: string) => {
      dispatch(getMoreForcedModifiersThunk({ companyId, page: nextPage, query }));
    }, debounceTime);
  }, [dispatch]);

  const loadMoreForcedModifiers = React.useCallback(() => {
    loadMoreForcedModifiersDebounced(companyId, nextPage, query);
  }, [loadMoreForcedModifiersDebounced, companyId, query, nextPage]);

  return (
    <InfiniteScrollingList
      loading={loading}
      error={error}
      paginationLoading={paginationLoading}
      paginationAvailable={paginationAvailable}
      items={items}
      filtered={filtered}
      noItemsTitle={noItemsTitle}
      noItemsMessage={noItemsMessage}
      selectedItemKeys={selectedIds}
      loadItems={loadForcedModifiers}
      loadMoreItems={loadMoreForcedModifiers}
      getItemKey={(forcedModifier: WithAssignedLocationsCount<ITemplateMenuItem>) => forcedModifier._id}
      buildItemComponent={ItemWithAssignedLocations}
      addItem={selectModalItem}
      removeItem={unselectModalItem}
      isInModal
      listAdditionalClassName={styles.list}
    />
  );
};
