import React from 'react';
import styles from './ModifierGroupConstraints.module.scss';
import { compositeStyles } from '../../../utils/cssUtils';

interface ModifierGroupConstraintsProps {
  min?: number;
  max?: number;
  small?: boolean;
}

const ModifierGroupConstraints = ({ min, max, small = false }: ModifierGroupConstraintsProps): JSX.Element => (
  <div className={compositeStyles([styles.constraints, { small: small }, { large: !small }], styles)}>
    MIN: {min || 'None'} | MAX: {max || 'Unlimited'}
  </div>
);

export default ModifierGroupConstraints;
