import React from 'react';
import styles from './AssignedLocationsListItem.module.scss';
import { ITemplateLocation } from '@ready/menu.core';
import { AllLocations } from 'sharedMenuItems/redux/editAssignedLocations/assignLocationsSlice';
import { useClassNames } from 'utils/cssUtils';

export interface AssignedLocationsListItemProps {
  location: ITemplateLocation;
  buttons: JSX.Element | null;
  allLocations?: AllLocations;
}

export const AssignedLocationsListItem = ({
  location,
  buttons,
  allLocations,
}: AssignedLocationsListItemProps): JSX.Element => {
  return (
    <div className={useClassNames([styles.cardContent, { disabled: allLocations === 'assign' }], styles)}>
      <div className={styles.cardColumn}>
        <div className={styles.name}>{location.name}</div>
        <div className={styles.locationAddress}>{location.fullAddress}</div>
      </div>
      <div className={styles.cardColumn}>{buttons}</div>
    </div>
  );
};
