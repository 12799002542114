import React from 'react';
import { IContext } from '@ready/dashboardv2api.contracts';

import styles from './ContextItem.module.scss';

export interface ContextItemProps extends IContext {}

const ContextItem = ({ id, name, type }: ContextItemProps) => {
  const url = type === 'company' ? `/companies/${id}` : '/';

  return (
    <a href={url} className={styles.item}>
      {name}
    </a>
  );
};

export default ContextItem;
