import React from 'react';
import { SimplePanelLayout } from 'components/PanelLayout/SimplePanelLayout';
import { PaymentTerminalsPanel } from './PaymentTerminalsPanel/PaymentTerminalsPanel';
import { useAppSelector } from 'redux/store';
import { selectLocationCacheState } from 'redux/selectors/cacheSelectors/cacheSelectors';
import { PaymentProcessorProvider } from '@ready/dashboardv2api.contracts';

export const PaymentProcessingTabContent = () => {
  // location cache is the raw location data.
  const location = useAppSelector(selectLocationCacheState);

  const isPaymentProviderAdyen = location.paymentProcessor.providerName === PaymentProcessorProvider.Adyen;
  return <SimplePanelLayout>{isPaymentProviderAdyen && <PaymentTerminalsPanel />}</SimplePanelLayout>;
};
