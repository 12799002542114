export const UI_ACTIONS = {
  SETUP_DETAILS_PANEL_DROPDOWN: 'SETUP_DETAILS_PANEL_DROPDOWN',
  SET_SELECTED_OPTION_COUNTRY: 'SET_SELECTED_OPTION_COUNTRY',
  SET_SELECTED_OPTION_STATE: 'SET_SELECTED_OPTION_STATE',
  SET_SELECTED_OPTION_TIMEZONE: 'SET_SELECTED_OPTION_TIMEZONE',
  SET_SELECTED_OPTION_CURRENCY: 'SET_SELECTED_OPTION_CURRENCY',
  SET_SELECTED_OPTION_LOCALE: 'SET_SELECTED_OPTION_LOCALE',
  // order-ahead
  SET_ORDER_AHEAD_PANEL_DROPDOWN: 'SET_ORDER_AHEAD_PANEL_DROPDOWN',
  SET_SELECTED_OPTION_ORDER_TIME: 'SET_SELECTED_OPTION_ORDER_TIME',
  SET_SELECTED_OPTION_THROTTLE_DURATION: 'SET_SELECTED_OPTION_THROTTLE_DURATION',
  SET_SELECTED_OPTION_ACCEPT_FOR: 'SET_SELECTED_OPTION_ACCEPT_FOR',
  SET_POS_SETTINGS_DROPDOWN: 'SET_POS_SETTINGS_DROPDOWN',
  SET_SELECTED_OPTION_POS_INTEGRATION: 'SET_SELECTED_OPTION_POS_INTEGRATION',
  //digital menu
  SETUP_DIGITAL_MENU_DROP_DOWN: 'SETUP_DIGITAL_MENU_DROP_DOWN',
  SET_SELECTED_OPTION_DIGITAL_MENU_MODE: 'SET_SELECTED_OPTION_DIGITAL_MENU_MODE',
  // branding
  SET_BRANDING_PANEL_DROPDOWNS: 'SET_BRANDING_PANEL_DROPDOWNS',
  SET_SELECTED_OPTION_HEADER_FONT: 'SET_SELECTED_OPTION_HEADER_FONT',
  SET_SELECTED_OPTION_BODY_FONT: 'SET_SELECTED_OPTION_BODY_FONT',

  // gratuity
  SETUP_GRATUITY_PANEL_DROPDOWN: 'SETUP_GRATUITY_PANEL_DROPDOWN',
  SET_SELECTED_OPTION_TIP_CALCULATION: 'SET_SELECTED_OPTION_TIP_CALCULATION',

  // action bar
  SET_ACTION_BAR_VISIBLE: 'SET_ACTION_BAR_VISIBLE',

  // users
  SET_SELECTED_USER_ROLE: 'SET_SELECTED_USER_ROLE',

  //loyalty
  SET_SELECTED_OPTION_POS_PROVIDER: 'SET_SELECTED_OPTION_POS_PROVIDER',

  // overflowButton state
  UPDATE_CURRENTLY_OPEN_DROPDOWN_OVERFLOW_BUTTON_ID: 'UPDATE_CURRENTLY_OPEN_DROPDOWN_OVERFLOW_BUTTON_ID',
};
