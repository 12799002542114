import { createAsyncThunk } from '@reduxjs/toolkit';
import { set200Toast, toastErrorState } from 'redux/actions/uiActions/responseStateActions';
import { PaginationResponse } from '@ready/dashboardv2api.contracts';
import { IBulkEditMenuSection, IBulkEditMenuSectionRequest } from '@ready/menu.core';
import { getLocationMenuSections, saveLocationMenuSections } from '../service';

interface IGetMenuSectionLocationSettingsArgs {
  companyId: string;
  menuId: string;
  sectionId: string;
  page?: number;
  pageSize?: number;
  searchTerm?: string;
}
export const getLocationMenuSectionsThunk = createAsyncThunk<
  PaginationResponse<IBulkEditMenuSection>,
  IGetMenuSectionLocationSettingsArgs
>(
  'sharedMenu/locationSettings/menu/fetch',
  async ({ companyId, menuId, sectionId, page, pageSize, searchTerm }: IGetMenuSectionLocationSettingsArgs) => {
    return await getLocationMenuSections(companyId, menuId, sectionId, page, pageSize, searchTerm);
  }
);

interface ISaveMenuSectionLocationSettingsThunkArgs {
  companyId: string;
  menuId: string;
  sectionId: string;
  request: Omit<IBulkEditMenuSectionRequest, 'templateMenuId' | 'sectionUid'>;
}
export const saveLocationMenuSectionsThunk = createAsyncThunk(
  'sharedMenu/locationSettings/menu/save',
  async (args: ISaveMenuSectionLocationSettingsThunkArgs, thunkAPI) => {
    try {
      const result = await saveLocationMenuSections(args.companyId, args.menuId, args.sectionId, args.request);
      thunkAPI.dispatch(set200Toast('Success! Changes saved.'));
      return result;
    } catch (error) {
      thunkAPI.dispatch(toastErrorState(error));
    }
  }
);
