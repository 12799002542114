import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

export interface TableRowProps {
  children: ReactNode;
  skeleton?: boolean;
  linkTo?: string;
}

const TableRowContent = (props: Pick<TableRowProps, 'children' | 'linkTo'>) => {
  const { children, linkTo } = props;

  return linkTo ? (
    <Link className='table__row__link' to={linkTo}>
      {children}
    </Link>
  ) : (
    children
  );
};

const TableRow = (props: TableRowProps) => {
  const { children, skeleton = false, linkTo } = props;

  const skeletonClassName = skeleton ? 'table__row--skeleton' : '';
  const linkClassName = linkTo ? 'table__row--has-link' : '';

  return (
    <div className={`table__row ${skeletonClassName} ${linkClassName}`}>{TableRowContent({ children, linkTo })}</div>
  );
};

export default TableRow;
