import React from 'react';
import classNames from 'classnames';
import Container from '../../../components/Container/Container';
import styles from './TablesSearchInput.module.scss';

export interface TablesSearchInputProps {
  value: string;
  placeholder?: string;
  additionalContainerClassName?: string;
  additionalClassName?: string;
  disabledLoading?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const TablesSearchInput = (props: TablesSearchInputProps): JSX.Element => {
  const {
    value,
    placeholder = '',
    additionalContainerClassName = '',
    additionalClassName = '',
    disabledLoading = false,
    onChange,
  } = props;

  const containerClassName = classNames(styles.targetSearchInputContainer, additionalContainerClassName);
  const inputClassName = classNames(
    styles.targetSearchInput,
    styles.targetSearchInputFullWidth,
    disabledLoading ? styles.textInputDisabledLoading : null,
    additionalClassName
  );

  return (
    <Container additionalClassName={containerClassName}>
      <span className={styles.iconDashboardIcons_search}>
        <i className='icon-dashboard-icons_search'></i>
      </span>
      <input type='text' placeholder={placeholder} value={value} onChange={onChange} className={inputClassName} />
    </Container>
  );
};

export default TablesSearchInput;
