import { RootState } from '../../redux/store';
import {
  IForcedModifierSelection,
  IItemsAndModsState,
  IMenuItemForm,
  IMenuItemSelection,
  IPosItemSelection,
} from './ItemsAndModsState';

export const selectItemsAndModsState = (state: RootState): IItemsAndModsState => state.menuBuilder.itemsAndMods;

export const selectPosItemSelectionState = (state: RootState): IPosItemSelection =>
  state.menuBuilder.itemsAndMods.posItemSelection;

export const selectMenuItemSelectionState = (state: RootState): IMenuItemSelection =>
  state.menuBuilder.itemsAndMods.menuItemSelection;

export const selectMenuItemFormState = (state: RootState): IMenuItemForm => state.menuBuilder.itemsAndMods.menuItemForm;

export const selectForcedModifierSelectionState = (state: RootState): IForcedModifierSelection =>
  state.menuBuilder.itemsAndMods.menuItemForm.forcedModifierSelection;

export const selectForcedModSelectionState = (state: RootState): IMenuItemSelection =>
  state.menuBuilder.itemsAndMods.forcedModSelection;
