import { combineReducers } from 'redux';
import { locationsListReducer } from './locationsListReducer';
import { tablesListReducer } from './tablesListReducer';
import { revenueCentersReducer } from './revenueCentersReducer';
import { posOrderTypesReducer } from './posOrderTypesReducer';

export const tablesReducer = combineReducers({
  locationsList: locationsListReducer,
  revenueCenters: revenueCentersReducer,
  posOrderTypes: posOrderTypesReducer,
  tableConfigurationList: tablesListReducer,
});
