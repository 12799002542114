import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { TableHeader } from '../../components/TableV2/TableHeader/TableHeader';
import { TableV2 } from '../../components/TableV2/TableV2';
import { Body } from '../../components/TableV2/Body/Body';
import LayoutContent from '../../components/AppLayout/LayoutContent';
import HeaderControls, { ControlOption } from '../../components/AppLayout/HeaderControls';
import { ToolBar } from './components/ToolBar/ToolBar';
import { fetchPermittedLocationsThunk, fetchEmailMarketingListThunk, IEmailMarketingListState } from './redux';
import { useSearchParams } from '../../hooks';
import { cells, headerCells, initDateFilter } from './emailMarketingListReportSettings';
import { BodySkeleton } from '../../components/TableV2/Body/Body';
import { exportEmailMarketingList } from './services/emailMarketingListService';
import styles from './EmailMarketingList.module.scss';
import { PaginationContainer } from '../../components/PaginationContainer/PaginationContainer';

interface IKnownState {
  session: { contextSession: { id: string } };
  reports: {
    emailMarketingList: IEmailMarketingListState;
  };
}

const emailMarketingListSelector = createSelector(
  (state: IKnownState) => state,
  ({ session, reports: { emailMarketingList } }: IKnownState): IEmailMarketingListState & { contextId: string } => {
    return {
      contextId: session.contextSession.id,
      ...emailMarketingList,
    };
  }
);

export function EmailMarketingListPage() {
  const { query, page, sortColumn, sortDirection } = useSearchParams();
  const { loading, pagination, contextId, permittedLocations, items, columns, headerColumns, rowKey } = useSelector(
    emailMarketingListSelector
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPermittedLocationsThunk(contextId));
  }, [contextId, dispatch]);

  useEffect(() => {
    dispatch(fetchEmailMarketingListThunk({ contextId, query, page, sortColumn, sortDirection }));
  }, [contextId, query, page, sortColumn, sortDirection, dispatch]);

  const headerControlOptions: Array<ControlOption> = [
    {
      buttonLabel: 'Export',
      onClick: async () => exportEmailMarketingList({ contextId, query, sortColumn, sortDirection }),
    },
  ];

  return (
    <LayoutContent
      title='Email Marketing List'
      headerControls={<HeaderControls controlOptions={headerControlOptions} />}
      toolbarContent={<ToolBar initDateFilter={initDateFilter} locations={permittedLocations.results} />}
      containerType='within'
    >
      <div className={styles.emailMarketingList}>
        <PaginationContainer pagination={pagination}>
          <TableV2 loading={loading.emailMarketingList}>
            <>
              <TableHeader cells={headerCells} data={headerColumns} columns={columns} />
              {loading.emailMarketingList ? (
                <BodySkeleton columns={columns} rowsNumber={50} />
              ) : (
                <Body cells={cells} hiddenColumns={{}} rowKey={rowKey} data={items} columns={columns} />
              )}
            </>
          </TableV2>
        </PaginationContainer>
      </div>
    </LayoutContent>
  );
}
