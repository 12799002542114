import rg4js from 'raygun4js';
import config from '../../config';

export class RaygunService {
  public static initRaygun(): void {
    if (config.environment === 'prod') {
      rg4js('apiKey', config.raygunApiKey);
      rg4js('enableCrashReporting', true); //automatically catch and send unhandled errors
    }
  }
}
