import { locationReducer } from '../../locations/redux';
import { uiReducer } from './uiReducers';
import { cacheReducer } from './cacheReducers';
import { contextSwitcherReducer } from './contextSwitcher/contextSwitcherReducer';
import { menuBuilderReducer } from '../../menus/redux';
import { transactionReducer } from './transactionReducers';
import { sessionReducer } from './sessionReducer/sessionReducer';
import { usersReducer } from './userReducers';
import { companyLocationsReducer } from './companyLocations/companyLocationsReducer';
import { adminsReducer } from '../../admins/redux';
import { orderReducer } from './orderReducers';
import { tableServerAssignmentReducer } from '../../serverAssignment/redux';
import { securityRolesReducer } from './securityRolesReducers';
import { tablesReducer } from '../../companyLocations/redux/reducers';
import { combinedPaymentProcessorReducer } from '../../companyLocations/redux/reducers/paymentProcessingReducer';
import { tagsBadgesReducer } from '../../tagsBadges/redux';
import { companySettingsReducer } from '../../companySettings/redux/CompanySettingsReducer';
import { promoCodesReducer } from '../../companyLocations/redux/reducers/promoCodesReducer';
import { clientDigitalMenuReducer } from '../../companyLocations/redux/reducers/digitalMenuReducer';
import { reportsReducer } from './reportsReducer';
import { orderSmsMessagesSlice } from '../../companyLocations/redux/reducers/orderSMSMessages/orderSMSMessagesSlice';
import { onAccountPaymentOptionsReducer } from '../../companyLocations/redux/reducers/onAccountPaymentOptionsReducer';

const reducers = {
  location: locationReducer,
  ui: uiReducer,
  cache: cacheReducer,
  contextSwitcher: contextSwitcherReducer,
  menuBuilder: menuBuilderReducer,
  transactions: transactionReducer,
  reports: reportsReducer,
  session: sessionReducer,
  users: usersReducer,
  companyLocations: companyLocationsReducer,
  admins: adminsReducer,
  orders: orderReducer,
  tableServerAssignment: tableServerAssignmentReducer,
  securityRoles: securityRolesReducer,
  table: tablesReducer,
  paymentProcessing: combinedPaymentProcessorReducer,
  tagsBadges: tagsBadgesReducer,
  companySettings: companySettingsReducer,
  locationPromoCodes: promoCodesReducer,
  digitalMenu: clientDigitalMenuReducer,
  orderSmsMessages: orderSmsMessagesSlice.reducer,
  onAccountPaymentOptions: onAccountPaymentOptionsReducer,
};

export default reducers;
