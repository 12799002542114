import React from 'react';
import { ILink, IMenuGalleryForm, IMenuSectionForm } from '../../redux/MenusState';
import { ISchedule, IScheduleLink, Status } from '@ready/menu.core';
import styles from './SharedMenuLocationSection.module.scss';
import { PanelCollapsible, PanelTitle } from '../../../components/PanelLayout';
import EditPanelFooter from './EditPanelFooter';
import { Form } from '../../../components/Form';
import ScheduleFormControl from '../shared/ScheduleFormControl';
import DefaultAppearanceSelector from './DefaultAppearanceSelector';
import { Routes } from 'menus/MenuBuilderRouter';
import MenuItemAndItemGroupList from './MenuItemAndItemGroupList';
import { useParams } from 'react-router-dom';
import { ContextParams } from 'types/ContextParams.interface';
import { itemGalleryCountIndicator } from './EditSectionPanel';
import DisabledBadge from '../DisabledBadge';
import { useClassNames } from 'utils/cssUtils';

export interface SharedMenuLocationSectionProps {
  sectionForm: IMenuSectionForm;
  itemGalleryForm: IMenuGalleryForm;
  itemGalleryLimit: number;
  schedules: ISchedule[];
  areSchedulesLoading: boolean;
  isSharedMenuAssignedToCurrentLocation?: boolean;
  onCancel: () => void;
  onSave: () => void;
  onChangeStatus: (enabled: boolean) => void;
  onChangeScheduler: (scheduler: IScheduleLink<ILink> | null) => void;
  onChangeItemVisibility: (id: string, visible: boolean) => void;
  onChangeGroupItemVisibility: (groupId: string, itemId: string, visible: boolean) => void;
  onChangeGalleryItemVisibility: (id: string, visible: boolean) => void;
  onChangeGalleryGroupItemVisibility: (groupId: string, itemId: string, visible: boolean) => void;
}

export const SharedMenuLocationSection = ({
  sectionForm: { section, processing, validation },
  itemGalleryForm,
  itemGalleryLimit,
  areSchedulesLoading,
  onCancel,
  onSave,
  onChangeStatus,
  schedules,
  onChangeScheduler,
  onChangeItemVisibility,
  onChangeGroupItemVisibility,
  onChangeGalleryItemVisibility,
  onChangeGalleryGroupItemVisibility,
}: SharedMenuLocationSectionProps): JSX.Element => {
  const { contextId: companyId, locationId, id: menuId } = useParams<ContextParams>();

  const itemGalleryEnabled = section.itemGallery?.status === Status.enabled;
  const itemGalleryName = section.itemGallery?.displayName || '';
  const itemGalleryDescription = section.itemGallery?.description || '';
  const showItemGalleryHeader = !!itemGalleryName || !!itemGalleryDescription;

  return (
    <Form hasGroups onSubmit={onSave}>
      <div className={styles.details}>
        <div className={styles.content}>
          <div className={styles.name}>
            <PanelTitle title={section.displayName} disabled={section.status === Status.disabled} />
          </div>
          <div className={styles.description}>{section.description}</div>
          <div className={styles.defaultAppearanceContent}>
            <div className={styles.label}>Default Appearance</div>
            <DefaultAppearanceSelector
              defaultAppearance={section.defaultAppearance}
              hideIndicator={true}
              withoutBorder={true}
              menuIsOpen={false}
            />
          </div>

          <div className={styles.schedules}>
            <ScheduleFormControl
              schedules={schedules}
              scheduleLink={section.schedule}
              validation={validation}
              onChange={onChangeScheduler}
              areSchedulesLoading={areSchedulesLoading}
            />
          </div>
        </div>

        <div className={styles.controls}>
          <div className={styles.defaultAppearanceControl}>
            <div className={styles.label}>Default Appearance</div>
            <DefaultAppearanceSelector
              defaultAppearance={section.defaultAppearance}
              hideIndicator={true}
              withoutBorder={true}
              menuIsOpen={false}
            />
          </div>
        </div>
      </div>

      <PanelCollapsible
        transparent
        initiallyExpanded={itemGalleryEnabled}
        fullContentArea
        titleComponent={
          <>
            <div className={styles.itemGalleryTitleContainer}>
              <span className={useClassNames([styles.itemGalleryTitle, { isDisabled: !itemGalleryEnabled }], styles)}>
                Item Gallery
                {itemGalleryCountIndicator(itemGalleryForm.menu.itemGallery!.items.length, itemGalleryLimit)}{' '}
                {!itemGalleryEnabled ? <DisabledBadge className={styles.itemGalleryDisabledBadge} /> : null}
              </span>
            </div>
          </>
        }
        subTitleComponent={
          <>
            {showItemGalleryHeader && (
              <div className={styles.itemGalleryNameAndDescriptionContainer}>
                {!!itemGalleryName && (
                  <div className={itemGalleryEnabled ? styles.itemGalleryName : styles.itemGalleryNameDisabled}>
                    {itemGalleryName}
                  </div>
                )}
                {!!itemGalleryDescription && (
                  <div
                    className={
                      itemGalleryEnabled ? styles.itemGalleryDescription : styles.itemGalleryDescriptionDisabled
                    }
                  >
                    {itemGalleryDescription}
                  </div>
                )}
              </div>
            )}
          </>
        }
        additionalStyles={styles.itemGallery}
      >
        {!!itemGalleryForm.menu.itemGallery && itemGalleryForm.menu.itemGallery.items.length > 0 && (
          <MenuItemAndItemGroupList
            disabled={false}
            parentDisabled={false}
            items={itemGalleryForm.menu.itemGallery.items}
            getMenuItemLink={(menuItemId: string) =>
              Routes.getMenuSectionItemPage(companyId, locationId, menuId, menuItemId)
            }
            getItemGroupLink={(itemGroupId: string) =>
              Routes.getMenuSectionItemGroupPage(companyId, locationId, menuId, itemGroupId)
            }
            onItemVisibilityClick={onChangeGalleryItemVisibility}
            onGroupItemVisibilityClick={onChangeGalleryGroupItemVisibility}
          />
        )}
      </PanelCollapsible>

      <PanelCollapsible
        transparent
        initiallyExpanded
        fullContentArea
        titleComponent={<div className={styles.itemListTitle}>Item List</div>}
        additionalStyles={styles.itemList}
      >
        {!!section.items && !!section.items.length ? (
          <MenuItemAndItemGroupList
            disabled={false}
            parentDisabled={false}
            items={section.items}
            getMenuItemLink={(menuItemId: string) =>
              Routes.getMenuSectionItemPage(companyId, locationId, menuId, menuItemId)
            }
            getItemGroupLink={(itemGroupId: string) =>
              Routes.getMenuSectionItemGroupPage(companyId, locationId, menuId, itemGroupId)
            }
            onItemVisibilityClick={onChangeItemVisibility}
            onGroupItemVisibilityClick={onChangeGroupItemVisibility}
          />
        ) : (
          <div className={styles.noItems}>
            There are no items in this section. Edit this section to start adding items.
          </div>
        )}
      </PanelCollapsible>

      <div className={styles.footer}>
        <EditPanelFooter
          status={section.status}
          statusLabel='Section Status:'
          saveLabel={'Save Changes'}
          processing={processing}
          onChangeStatus={onChangeStatus}
          onCancel={onCancel}
        />
      </div>
    </Form>
  );
};
