export const TRANSACTION_DETAIL_ACTIONS = {
  TRANSACTION_DETAIL_BEGIN: 'TRANSACTION_DETAIL_BEGIN',
  TRANSACTION_DETAIL_SUCCESS: 'TRANSACTION_DETAIL_SUCCESS',
  TRANSACTION_DETAIL_ERROR: 'TRANSACTION_DETAIL_ERROR',

  SHOW_MANUAL_ENTER_PAYMENT_MODAL: 'SHOW_MANUAL_ENTER_PAYMENT_MODAL',
  HIDE_MANUAL_ENTER_PAYMENT_MODAL: 'HIDE_MANUAL_ENTER_PAYMENT_MODAL',
  MANUAL_ENTER_PAYMENT_BEGIN: 'MANUAL_ENTER_PAYMENT_BEGIN',
  MANUAL_ENTER_PAYMENT_SUCCESS: 'MANUAL_ENTER_PAYMENT_SUCCESS',
  MANUAL_ENTER_PAYMENT_ERROR: 'MANUAL_ENTER_PAYMENT_ERROR',

  MANUAL_RETRY_PAYMENT_BEGIN: 'MANUAL_RETRY_PAYMENT_BEGIN',
  MANUAL_RETRY_PAYMENT_SUCCESS: 'MANUAL_RETRY_PAYMENT_SUCCESS',
  MANUAL_RETRY_PAYMENT_ERROR: 'MANUAL_RETRY_PAYMENT_ERROR',

  REFUND_TRANSACTION_SUCCESS: 'REFUND_TRANSACTION_SUCCESS',

  REFUND_GIFT_CARD_BEGIN: 'REFUND_GIFT_CARD_BEGIN',
  REFUND_GIFT_CARD_SUCCESS: 'REFUND_GIFT_CARD_SUCCESS',
  REFUND_GIFT_CARD_ERROR: 'REFUND_GIFT_CARD_ERROR',
  RESET_REFUND_GIFT_CARD_STATE: 'RESET_REFUND_GIFT_CARD_STATE',
};
