import React from 'react';

export interface MenuConfigDeleteConfirmationModalProps {
  configName: string;
}

export const MenuConfigDeleteConfirmationModalContent = ({ configName }: MenuConfigDeleteConfirmationModalProps) => (
  <p>
    Are you sure you want to delete the menu configuration “<strong>{configName}</strong>” and all its menus and item
    galleries? It will be deleted from all locations where it is assigned. This action cannot be undone.
  </p>
);
