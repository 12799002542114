import { IOnAccountPaymentSettings } from '@ready/dashboardv2api.contracts';
import config from 'config';
import executeApiAsync from 'services/utils/executeApiAsync';

export const getOnAccountPaymentSettings = async (
  companyId: string,
  locationId: string
): Promise<IOnAccountPaymentSettings> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/onAccountPaymentSettings`;
  return executeApiAsync<IOnAccountPaymentSettings>(url, { method: 'GET' });
};

export const updateOnAccountPaymentSettings = async (
  companyId: string,
  locationId: string,
  settings: IOnAccountPaymentSettings
): Promise<IOnAccountPaymentSettings> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/onAccountPaymentSettings`;
  return executeApiAsync<IOnAccountPaymentSettings>(url, {
    method: 'PUT',
    body: JSON.stringify(settings),
  });
};
