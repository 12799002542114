import {
  IMenu,
  IMenuRequest,
  IMenuSection,
  IMenuSectionRequest,
  IInnerItemGallery,
  IInnerItemGalleryRequest,
  IMenuSectionItem,
  IMenuSectionItemGroup,
  IMenuSectionItemRequest,
} from '@ready/menu.core';

export const mapMenuToMenuRequest = (menu: IMenu): IMenuRequest => {
  return {
    ...menu,
    itemGallery: mapInnerItemGalleryIntoInnerItemGalleryRequest(menu.itemGallery),
    sections: menu.sections.map((x) => mapSectionToSectionRequest(x)),
  };
};

export const mapSectionToSectionRequest = (section: IMenuSection): IMenuSectionRequest => {
  return {
    ...section,
    itemGallery: mapInnerItemGalleryIntoInnerItemGalleryRequest(section.itemGallery),
    items: section.items.map((x) => mapMenuSectionItemToMenuSectionItemRequest(x)),
  };
};

export const mapInnerItemGalleryIntoInnerItemGalleryRequest = (
  innerItemGallery?: IInnerItemGallery
): IInnerItemGalleryRequest | undefined => {
  return !innerItemGallery
    ? undefined
    : {
        ...innerItemGallery,
        items: innerItemGallery.items.map((x) => mapMenuSectionItemToMenuSectionItemRequest(x)),
      };
};

export const mapMenuSectionItemToMenuSectionItemRequest = (
  item: IMenuSectionItem | IMenuSectionItemGroup
): IMenuSectionItemRequest => {
  return {
    itemId: item.itemId,
    sectionItemType: item.sectionItemType,
    sortOrder: item.sortOrder,
    visibility: item.visibility,
  };
};
