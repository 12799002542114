import React from 'react';
import { Link } from 'react-router-dom';
import Container from '../Container/Container';

export interface BackLinkProps {
  to: string;
  label: string;
}

const BackLink = (props: BackLinkProps) => {
  const { to, label } = props;

  return (
    <Container additionalClassName='back-link-container'>
      <Link to={to} className='back-link'>
        <i className='icon-dashboard-icons_angle-left'></i>
        <span>{label}</span>
      </Link>
    </Container>
  );
};

export default BackLink;
