import {
  IBulkEditMenuItemGroup,
  IBulkEditMenuItemGroupRequest,
  ILocationAssignments,
  ITemplateLocation,
} from '@ready/menu.core';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { set200Toast, toastErrorState } from 'redux/actions/uiActions/responseStateActions';

import { getTemplateChildMenuItemGroups } from '../../itemGroupService';

import {
  loadMoreItemsPending,
  loadItemsPending,
  loadMoreItemsFulfilled,
  loadItemsFulfilled,
  loadMoreItemsFailure,
  loadItemsFailure,
  selectItems,
} from 'redux/slices/infiniteScrollingListModalSlice';
import { PaginationResponse } from '@ready/dashboardv2api.contracts';
import { RootState } from 'redux/store';
import {
  bulkUpdateLocationItemGroups,
  getLocationAssignmentsForItemGroup,
  updateLocationAssignmentsForItemGroup,
} from './service';
import { itemGroupAssignedLocationsSlice } from './slice';

interface Args {
  companyId: string;
  itemId: string;
  page?: number;
  locationSearchTerm?: string;
}

export const getTemplateChildMenuItemGroupsThunk = createAsyncThunk<PaginationResponse<IBulkEditMenuItemGroup>, Args>(
  'sharedMenuItems/itemGroups/assignedLocations/fetch',
  ({ companyId, page, itemId, locationSearchTerm }: Args) =>
    getTemplateChildMenuItemGroups(companyId, itemId, locationSearchTerm, page)
);

interface LocationsForItemGroupArgs {
  companyId: string;
  itemId: string;
  locationSearchTerm?: string;
  page?: number;

  isLoadMore?: boolean;
}

export const getAssignedLocationsForItemGroupThunk = createAsyncThunk<
  PaginationResponse<ITemplateLocation> | undefined,
  LocationsForItemGroupArgs
>(
  'sharedMenuItems/itemGroups/assignedLocations/locations',
  async ({ companyId, itemId, locationSearchTerm, page, isLoadMore }: LocationsForItemGroupArgs, thunkApi) => {
    if (isLoadMore) {
      thunkApi.dispatch(loadMoreItemsPending());
    } else {
      thunkApi.dispatch(loadItemsPending());
    }
    try {
      const result = await getLocationAssignmentsForItemGroup(companyId, itemId, locationSearchTerm, page);

      if (isLoadMore) {
        thunkApi.dispatch(loadMoreItemsFulfilled(result.items));
      } else {
        thunkApi.dispatch(loadItemsFulfilled(result.items));

        // store all locations count
        if (!locationSearchTerm)
          thunkApi.dispatch(itemGroupAssignedLocationsSlice.actions.storeTotalLocationsCount(result.total));
      }

      // if any of these are flagged as assigned, add them to selectedIds state.
      const selected = result.items.filter((x) => x.hasTemplateAssigned).map((x) => x._id);
      if (selected.length > 0) {
        thunkApi.dispatch(selectItems(selected));
      }

      return result;
    } catch (error) {
      if (isLoadMore) {
        thunkApi.dispatch(loadMoreItemsFailure());
      } else {
        thunkApi.dispatch(loadItemsFailure());
      }
      thunkApi.dispatch(toastErrorState(error));
    }
  }
);

interface IUpdateAssignedLocationsForItemGroupArgs {
  companyId: string;
  itemId: string;

  assignments: ILocationAssignments;
  toastMessage?: string;
}

export const updateAssignedLocationsforItemGroupThunk = createAsyncThunk<
  void,
  IUpdateAssignedLocationsForItemGroupArgs,
  { state: RootState }
>(
  'sharedMenuItems/itemGroups/assignedLocations/locations/update',
  async (
    {
      companyId,
      itemId,
      assignments: { allLocations, assignToLocations, unassignFromLocations },
      toastMessage,
    }: IUpdateAssignedLocationsForItemGroupArgs,
    thunkApi
  ) => {
    let locationAssignments: ILocationAssignments = {};

    if (allLocations !== undefined) {
      locationAssignments.allLocations = allLocations === 'assign' ? 'assign' : 'unassign';
    } else {
      locationAssignments = { assignToLocations, unassignFromLocations };
    }

    try {
      await updateLocationAssignmentsForItemGroup(companyId, itemId, locationAssignments);

      thunkApi.dispatch(set200Toast(toastMessage));
    } catch (e) {
      thunkApi.dispatch(toastErrorState(e.status, e.message));
    }
  }
);

interface IBulkUpdateAssignedLocationItemGroupsArgs {
  companyId: string;
  itemGroupId: string;
  bulkEditMenuItemGroupRequest: IBulkEditMenuItemGroupRequest;
}

export const bulkUpdateAssignedLocationItemGroupsThunk = createAsyncThunk<
  void,
  IBulkUpdateAssignedLocationItemGroupsArgs
>(
  'sharedMenuItems/itemGroups/assignedLocations/locations/bulkUpdate',
  async (
    { companyId, itemGroupId, bulkEditMenuItemGroupRequest }: IBulkUpdateAssignedLocationItemGroupsArgs,
    thunkApi
  ) => {
    try {
      await bulkUpdateLocationItemGroups(companyId, itemGroupId, bulkEditMenuItemGroupRequest);
      thunkApi.dispatch(set200Toast('Success! Changes saved.'));
    } catch (e) {
      thunkApi.dispatch(toastErrorState(e.status, e.message));
    }
  }
);
