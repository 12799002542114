import { BackLink } from '../../types/BackLink.interface';

const getBackLinkUrl = ({ baseUrl, searchParams }: BackLink): string => {
  let backLinkUrl = baseUrl;

  if (searchParams) {
    const urlSearchParams = new URLSearchParams();
    Object.keys(searchParams).forEach((key) => {
      if (searchParams[key]) {
        urlSearchParams.set(key, decodeURIComponent(searchParams[key]));
      }
    });
    // replace '+' sign for multiple word query
    const queryString = urlSearchParams.toString().replace(/\+/g, '%20');
    if (queryString) {
      backLinkUrl += `?${queryString}`;
    }
  }

  return backLinkUrl;
};

export default getBackLinkUrl;
