import { IPagedResponse } from '@ready/table.core';
import { ILocation } from '../../services/types/ILocation.type';
import executeApiAsync, { ResponseType } from '../../services/utils/executeApiAsync';
import config from '../../config';
import { IPromoCodeReportListItem, PaginationResponse } from '@ready/dashboardv2api.contracts';
import { downloadFileFromBlob } from '../../utils/downloadFileFromBlob';
import { ReportsResourceActions, ResourceType } from '@ready/security.core';
const BASE_URL = `${config.readyDashboardApiEndpoint}/companies`;

export const getPermittedLocations = async (contextId: string): Promise<IPagedResponse<ILocation>> => {
  const securityParams = `permissions=${ResourceType.reports}.${ReportsResourceActions.viewPromoCodeReport}`;
  const showHiddenLocations = false;
  const url = `${BASE_URL}/${contextId}/permittedlocations?${securityParams}&isHidden=${showHiddenLocations}`;
  return await executeApiAsync<IPagedResponse<ILocation>>(url);
};

export const exportPromoCodesList = async ({
  contextId,
  query = '',
  sortColumn = 'usageCount',
  sortDirection = 'desc',
}: {
  contextId: string;
  query: string;
  sortColumn: string;
  sortDirection: string;
}) => {
  const url =
    `${BASE_URL}/${contextId}/reports/promo-code/list/export?` +
    decodeURIComponent(query) +
    `&sortColumn=${sortColumn}&sortDirection=${sortDirection}`;

  const blob = await executeApiAsync<Blob>(url, { responseType: ResponseType.Blob });
  downloadFileFromBlob(blob, `PromoCodeUsageReport.csv`);
};

export const getPromoCodeSummary = ({
  contextId,
  query = '',
  page = 1,
  sortColumn = 'usageCount',
  sortDirection = 'desc',
}: {
  contextId: string;
  query: string;
  page: number;
  sortColumn: string;
  sortDirection: string;
}): Promise<PaginationResponse<IPromoCodeReportListItem>> => {
  const url =
    `${BASE_URL}/${contextId}/reports/promo-code/list?` +
    decodeURIComponent(query) +
    `&page=${page}&sortColumn=${sortColumn}&sortDirection=${sortDirection}`;

  return executeApiAsync<PaginationResponse<IPromoCodeReportListItem>>(url);
};
