import React from 'react';
import classNames from 'classnames';
import styles from './OrderCostSummary.module.scss';
import { DollarValue } from '../../../components/Value';
import { IOrderAppliedDiscount, IOrderTotals, OrderExperienceType } from '@ready/dashboardv2api.contracts';

interface OrderCostSummaryProps {
  totals: IOrderTotals | number;
  tip: number;
  discounts?: number;
  appliedDiscounts: IOrderAppliedDiscount[];
  additionalStyles?: string;
  orderExperienceType?: OrderExperienceType;
  hasInvoice?: boolean;
}

const OrderCostSummary = ({
  totals,
  tip,
  appliedDiscounts,
  additionalStyles = '',
  orderExperienceType = OrderExperienceType.DineIn,
  hasInvoice = false,
}: OrderCostSummaryProps) => {
  const totalsAsIOrderTotals: IOrderTotals = totals as IOrderTotals;
  const isTotalsJustNumber = typeof totals === 'number';

  return (
    <div className={classNames(styles.container, additionalStyles)}>
      <span className={styles.header}>Order Summary</span>
      <SummaryRow name='Subtotal' value={isTotalsJustNumber ? (totals as number) : totalsAsIOrderTotals.subtotal} />
      {(orderExperienceType === OrderExperienceType.Pickup || hasInvoice) && (
        <>
          {totalsAsIOrderTotals.discounts > 0 && <SummaryRow name='Discounts' value={totalsAsIOrderTotals.discounts} />}
          {appliedDiscounts && appliedDiscounts.length > 0
            ? appliedDiscounts.map((appliedDiscount, index) => (
                <SummaryRow
                  name={appliedDiscount.information + ` (${appliedDiscount.posName})`}
                  value={-appliedDiscount.amount}
                  key={index}
                  id={index}
                />
              ))
            : null}
          {totalsAsIOrderTotals.serviceCharge > 0 && (
            <SummaryRow name='Service Charge' value={totalsAsIOrderTotals.serviceCharge} />
          )}
          <SummaryRow name='Tax' value={totalsAsIOrderTotals.tax} />
          <SummaryRow name='Tip' value={tip} additionalStyles={styles.tip} />
          <SummaryRow name='Total' value={totalsAsIOrderTotals.total} additionalStyles={styles.total} />
        </>
      )}
    </div>
  );
};

interface SummaryRowProps {
  name: string;
  value?: number;
  id?: number;
  additionalStyles?: string;
}

const SummaryRow = ({ name, value = 0, id, additionalStyles }: SummaryRowProps) => (
  <div className={classNames(styles.summaryRow, additionalStyles)} key={id}>
    <span>{name}</span>
    <DollarValue value={value} negativeFormat='parentheses' />
  </div>
);

export default OrderCostSummary;
