import {
  IAcceptOrdersFor,
  MethodType,
  OrderExperienceType,
  PaymentType,
  TransactionStatus,
  LoyaltyProvider,
  GooglePayEnvironment,
  ImageStyling,
  CommentSubmissionMethod,
  OrderSmsMessageSource,
  AdyenPricingModel,
} from '@ready/dashboardv2api.contracts';
import * as moment from 'moment-timezone';
import { Icon } from '../../components/Icon/TextIcon';
import { Option } from '../../components/SelectFilter/SelectFilter';
import MenuItemsView from '../../menus/types/MenuItemsView.enum';
import { IOptionList } from './listOptions.interface';
import { checkFeatureToggle } from 'utils/featureToggle/featureToggle';

const getAmericanTimeZonesList = (): Option[] => {
  const allTimeZones = moment.tz.names();
  const northAmericanTimeZones = allTimeZones.filter((tz: string) => {
    return tz.includes(`America`);
  });
  return northAmericanTimeZones.map((tz: string) => {
    return {
      value: tz,
      label: tz,
    };
  });
};

const TIMEZONE_LIST_OPTIONS = getAmericanTimeZonesList();

const CURRENCY_LIST_OPTIONS: Option[] = [
  {
    value: 'CAD',
    label: 'CAD',
  },
  {
    value: 'USD',
    label: 'USD',
  },
  {
    value: 'MXN',
    label: 'MXN',
  },
];

const POS_INTEGRATION_OPTIONS: Option[] = [
  {
    label: 'Unintegrated',
    value: 'basicpos',
  },
  {
    label: 'Ready POS',
    value: 'readypos',
  },
  {
    label: 'Omnivore',
    value: 'omnivore',
  },
  {
    label: 'Brink',
    value: 'parbrink',
  },
  {
    label: 'Bypass',
    value: 'bypass2',
  },
  {
    label: 'Square',
    value: 'square',
  },

  {
    label: 'Micros Simphony',
    value: 'simphony2',
  },
];

const LOYALTY_PROVIDER_OPTIONS: Option[] = [
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'Spendgo',
    value: LoyaltyProvider.Spendgo,
  },
  {
    label: 'ByPass',
    value: LoyaltyProvider.ByPass,
  },
  {
    label: 'Square',
    value: LoyaltyProvider.Square,
  },
  ...(checkFeatureToggle('app_locationPunchhLoyalty')
    ? [
        {
          label: 'Punchh',
          value: LoyaltyProvider.Punchh,
        },
      ]
    : []),
];

const POS_TYPE_OPTIONS: Option[] = [
  {
    label: 'Micros 3700',
    value: 'micros3700',
  },
  {
    label: 'POSitouch',
    value: 'positouch',
  },
  {
    label: 'Par Brink',
    value: 'parbrink',
  },
  {
    label: 'NCR Aloha',
    value: 'ncraloha',
  },
  {
    label: 'Dinerware',
    value: 'dinerware',
  },
  {
    label: 'Micros Simphony FE',
    value: 'microssimphonyfe',
  },
  {
    label: 'Micros Simphony',
    value: 'microssimphony',
  },
  {
    label: 'Squirrel',
    value: 'squirrel',
  },
];

const SUBMIT_ORDER_TIME_OPTIONS: Option[] = [
  {
    label: 'Ready Order Time Field',
    value: 'Ready Order Time Field',
  },
  {
    label: 'POS Item',
    value: 'Pos Item',
  },
];

const PAYMENT_PROVIDER: Option[] = [
  {
    label: 'Ready Payments',
    value: 'adyen',
  },
  {
    label: 'Stripe',
    value: 'stripe',
  },
  {
    label: 'Moneris',
    value: 'moneris',
  },
  {
    label: 'iQpay',
    value: 'iqpay',
  },
  {
    label: 'Freedom Pay',
    value: 'freedompay',
  },
  {
    label: 'Realex',
    value: 'realex',
  },
  {
    label: 'JetPay',
    value: 'jetpay',
  },
  {
    label: 'Square Payments',
    value: 'square',
  },
  {
    label: 'Bambora',
    value: 'bambora',
  },
];

const ADYEN_PRICE_MODEL: Option[] = [
  { label: 'Interchange Plus', value: AdyenPricingModel.InterchangePlus },
  { label: 'Blended Rate', value: AdyenPricingModel.BlendedRate },
  { label: 'None', value: AdyenPricingModel.None },
];

const FIELD_TYPE_OPTIONS: Option[] = [
  {
    label: 'Text',
    value: 'text',
  },
  {
    label: 'Phone',
    value: 'tel',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Number',
    value: 'number',
  },
];

const SENDING_METHOD_OPTIONS: Option[] = [
  {
    label: 'Ticket Name',
    value: 'ticketName',
  },
  {
    label: 'POS Item',
    value: 'fakeItem',
  },
];

const ORDER_THROTTLE_DURATION_OPTIONS: Option[] = [
  {
    label: '5 min',
    value: '5',
  },
  {
    label: '10 min',
    value: '10',
  },
  {
    label: '15 min',
    value: '15',
  },
  {
    label: '20 min',
    value: '20',
  },
  {
    label: '30 min',
    value: '30',
  },
];

const HEADER_FONT_OPTIONS: Option[] = [
  {
    label: 'Lato',
    value: 'Lato',
  },
  {
    label: 'Raleway',
    value: 'Raleway',
  },
  {
    label: 'Roboto',
    value: 'Roboto',
  },
  {
    label: 'Montserrat',
    value: 'Montserrat',
  },
  {
    label: 'Oswald',
    value: 'Oswald',
  },
  {
    label: 'Merriweather',
    value: 'Merriweather',
  },
  {
    label: 'Nunito',
    value: 'Nunito',
  },
];

const BODY_FONT_OPTIONS: Option[] = [
  {
    label: 'Lato',
    value: 'Lato',
  },
  {
    label: 'Raleway',
    value: 'Raleway',
  },
  {
    label: 'Roboto',
    value: 'Roboto',
  },
  {
    label: 'Montserrat',
    value: 'Montserrat',
  },
  {
    label: 'Oswald',
    value: 'Oswald',
  },
  {
    label: 'Merriweather',
    value: 'Merriweather',
  },
  {
    label: 'Nunito',
    value: 'Nunito',
  },
];

const TIP_CALCULATION: Option[] = [
  {
    label: 'Pre-tax Value',
    value: 'Pre-tax Value',
  },
  {
    label: 'Tax Inclusive Value',
    value: 'Tax Inclusive Value',
  },
];

export const paymentMethod: Option[] = [
  {
    label: 'applePay',
    value: MethodType.ApplePay,
  },
  {
    label: 'cardEntry',
    value: MethodType.CardEntry,
  },
  {
    label: 'googlePay',
    value: MethodType.GooglePay,
  },
];

export const paymentType: Option[] = [
  {
    label: 'visa',
    value: PaymentType.Visa,
  },
  {
    label: 'amex',
    value: PaymentType.Amex,
  },
  {
    label: 'masterCard',
    value: PaymentType.MC,
  },
  {
    label: 'discover',
    value: PaymentType.Discover,
  },
  {
    label: 'jcb',
    value: PaymentType.JCB,
  },
  {
    label: 'interac',
    value: PaymentType.Interac,
  },
];

export const transactionStatus: Option[] = [
  {
    label: 'AUTHORIZED',
    value: TransactionStatus.AUTHORIZED,
  },
  {
    label: 'CANCELLED',
    value: TransactionStatus.CANCELLED,
  },
  {
    label: 'PAID',
    value: TransactionStatus.PAID,
  },
  {
    label: 'REFUNDED',
    value: TransactionStatus.REFUNDED,
  },
];

export const PAYMENT_ENVIRONMENT_OPTIONS: Option[] = [
  {
    label: 'Production',
    value: GooglePayEnvironment.Production,
  },
  {
    label: 'Test',
    value: GooglePayEnvironment.Test,
  },
];

export const ACCEPT_ORDERS_OPTIONS: Option[] = [
  {
    label: 'Same day only',
    value: IAcceptOrdersFor.SameDayOnly,
  },
  {
    label: 'Future days',
    value: IAcceptOrdersFor.FutureDays,
  },
];

export const OUT_OF_STOCK_MANAGEMENT: Option[] = [
  {
    label: 'Automatically sync stock status',
    value: 0,
  },
  {
    label: 'Manually set stock status',
    value: 1,
  },
];

export const SMS_MESSAGE_SOURCE: Option[] = [
  {
    label: 'Use company defaults',
    value: OrderSmsMessageSource.Company,
  },
  {
    label: 'Use custom messaging for this location',
    value: OrderSmsMessageSource.Location,
  },
];

const ORDER_EXPERIENCE_TYPES_OPTIONS: Option[] = [
  {
    label: 'Delivery',
    value: OrderExperienceType.Delivery,
    icon: Icon.Car,
  },
  {
    label: 'Dine In',
    value: OrderExperienceType.DineIn,
    icon: Icon.Cutlery,
  },
  {
    label: 'Pick Up',
    value: OrderExperienceType.Pickup,
    icon: Icon.TakeoutBag,
  },
];

const ORDER_EXPERIENCE_TYPES_FILTER: Option[] = [
  {
    label: 'All Table Types',
    value: '',
  },
  ...ORDER_EXPERIENCE_TYPES_OPTIONS,
];

const MENU_ITEM_LIST_OPTIONS: Option[] = [
  {
    label: 'All Items & Mods',
    value: MenuItemsView.ALL,
  },
  {
    label: 'Items',
    value: MenuItemsView.ITEMS,
  },
  {
    label: 'Modifiers',
    value: MenuItemsView.MODS,
  },
];

export const GUEST_VALIDATION_METHOD_OPTIONS: Option[] = [
  {
    label: 'Confirm guest presence with NFC/QR scan',
    value: 'targetScan',
  },
  {
    label: 'Confirm guest presence with SMS phone verification',
    value: 'phone',
  },
];

export const SINGLE_CHECK_POS: Option[] = [
  {
    label: 'Create new check per guest',
    value: 0,
  },
  {
    label: 'Append all orders to single check',
    value: 1,
  },
];

export const SQUARE_GOOGLE_PAY_ENV: Option[] = [
  {
    label: 'Production',
    value: GooglePayEnvironment.Production,
  },
  {
    label: 'Test',
    value: GooglePayEnvironment.Test,
  },
];

export const BRANDING_IMAGE_STYLING: Option[] = [
  {
    label: 'Rounded Corners',
    value: ImageStyling.RoundedCorners,
  },
  {
    label: 'Square Corners',
    value: ImageStyling.SquareCorners,
  },
];

export const ITEM_COMMENTS_SUBMISSION_OPTIONS: Option[] = [
  {
    label: 'Use POS item comments field',
    value: CommentSubmissionMethod.UsePOSItemCommentField,
  },
  {
    label: 'Submit comments as a modifier',
    value: CommentSubmissionMethod.AttachCommentModifier,
  },
];

export const LOCALE_OPTIONS: Option[] = [
  {
    label: 'en-US',
    value: 'en-US',
  },
  {
    label: 'es-MX',
    value: 'es-MX',
  },
];

export const LIST_OPTIONS: IOptionList = {
  timezone: TIMEZONE_LIST_OPTIONS,
  currency: CURRENCY_LIST_OPTIONS,
  posIntegrationOptions: POS_INTEGRATION_OPTIONS,
  loyaltyProviderOptions: LOYALTY_PROVIDER_OPTIONS,
  posType: POS_TYPE_OPTIONS,
  orderTime: SUBMIT_ORDER_TIME_OPTIONS,
  acceptOrders: ACCEPT_ORDERS_OPTIONS,
  paymentProvider: PAYMENT_PROVIDER,
  fieldType: FIELD_TYPE_OPTIONS,
  sendingMethod: SENDING_METHOD_OPTIONS,
  throttleDuration: ORDER_THROTTLE_DURATION_OPTIONS,
  headerFont: HEADER_FONT_OPTIONS,
  bodyFont: BODY_FONT_OPTIONS,
  tipCalculation: TIP_CALCULATION,
  paymentEnvironment: PAYMENT_ENVIRONMENT_OPTIONS,
  outOfStockManagement: OUT_OF_STOCK_MANAGEMENT,
  smsMessageSource: SMS_MESSAGE_SOURCE,
  guestValidationMethod: GUEST_VALIDATION_METHOD_OPTIONS,
  singleCheckPos: SINGLE_CHECK_POS,
  orderExperienceTypes: ORDER_EXPERIENCE_TYPES_OPTIONS,
  orderExperienceTypesFilter: ORDER_EXPERIENCE_TYPES_FILTER,
  menuItemType: MENU_ITEM_LIST_OPTIONS,
  squareGooglePayEnv: SQUARE_GOOGLE_PAY_ENV,
  brandingImageStyling: BRANDING_IMAGE_STYLING,
  itemCommentsSubmission: ITEM_COMMENTS_SUBMISSION_OPTIONS,
  locale: LOCALE_OPTIONS,
  adyenPriceModel: ADYEN_PRICE_MODEL,
};
