import { IAsset } from '@ready/dashboardv2api.contracts';
import ActionHeader from 'components/ActionHeader/ActionHeader';
import { Form, FormControl } from 'components/Form';
import FormActionBar from 'components/FormActionBar/FormActionBar';
import { PanelLayout, Panel } from 'components/PanelLayout';
import TextArea from 'components/TextArea/TextArea';
import TextInput from 'components/TextInput/TextInput';
import { BannerAndThumbnailImagesFormControls } from 'menus/components/ItemsAndMods/BannerAndThumbnailImage/BannerAndThumbnailImagesFormControls';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { setFormIsDirty } from 'redux/actions/uiActions/formStateActions';
import { IFormState } from 'redux/initialStates/ui/formState';
import { selectFormState } from 'redux/selectors/uiSelectors/uiSelectors';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { SharedMenuItemsRoutes } from 'sharedMenuItems/Router';
import { SharedMenuItemsLayout } from 'sharedMenuItems/SharedMenuItemsLayout';
import { SharedMenuItemsTabId } from 'sharedMenuItems/SharedMenuItemsTabs';
import { ContextParams } from 'types/ContextParams.interface';
import {
  createSharedItemGroupThunk,
  deleteSharedItemGroupThunk,
  duplicateSharedItemGroupThunk,
  getSharedItemGroupThunk,
  updateSharedItemGroupThunk,
} from './redux/thunks';
import { resetItemGroup, resetState, updateItemGroup } from './redux/slice';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import Button from 'components/Button/Button';
import OverflowButton from 'components/OverflowButton/OverflowButton';
import styles from './ItemGroupDetailsPage.module.scss';
import { GroupItemsCards } from './components/groupItemsCards/GroupItemsCards';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { AddMenuItemsDialog } from 'sharedMenuItems/pages/itemsAndMods/components/AddMenuItemsDialog/AddMenuItemsDialog';
import { ITemplateMenuItem, WithAssignedLocationsCount } from '@ready/menu.core';
import { mapITemplateMenuItemToITemplateEmbeddedMenuItem } from 'sharedMenuItems/mappers/mappers';
import DuplicateMenuItemModal from 'menus/components/ItemsAndMods/DuplicateMenuItemModal';
import { DeleteConfirmationModal } from 'components/Modal';
import { DetailsTabs, DetailTabId } from 'sharedMenuItems/pages/createEditItem/components/tabs/DetailsTabs';
import { useTemplateItemBackLink } from 'hooks/useTemplateItemBackLink';

export const ItemGroupDetailsPage = () => {
  const { itemGroup, loading, itemGroupCache, saving, newId, isItemGroupDeleted } = useAppSelector(
    (state) => state.sharedMenuItems.itemGroups.details
  );
  const { isDirty: actionBarVisible } = useAppSelector<IFormState>(selectFormState);
  const { contextId: companyId, id } = useParams<ContextParams>();
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const displayNameValidation = useForm<{ displayName: string }>({
    mode: 'all',
    values: { displayName: itemGroup?.displayName ?? '' },
  });

  const {
    formState: { errors },
    control,
  } = displayNameValidation;
  const [showGroupItemsModal, setShowGroupItemsModal] = useState<boolean>(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const backLink = useTemplateItemBackLink(SharedMenuItemsRoutes.getItemGroupListRoute(companyId));

  const isNew = id === undefined;

  useEffect(() => {
    if (!isNew) {
      dispatch(getSharedItemGroupThunk({ companyId, id }));
    }
  }, [dispatch, companyId, id, isNew]);

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (newId) {
      push(SharedMenuItemsRoutes.getItemGroupDetailsRoute(companyId, newId));
    } else if (isItemGroupDeleted) {
      push(SharedMenuItemsRoutes.getItemGroupListRoute(companyId));
    }
  }, [companyId, isItemGroupDeleted, newId, push]);

  const handleFormSubmit = async () => {
    const formValidated = await displayNameValidation.trigger();

    if (formValidated && itemGroup) {
      if (isNew) {
        dispatch(createSharedItemGroupThunk({ companyId, itemGroup }));
      } else {
        dispatch(updateSharedItemGroupThunk({ companyId, itemGroup }));
      }
      dispatch(setFormIsDirty(false));
    }
  };

  const handleCancelChanges = async () => {
    dispatch(resetItemGroup());
    await displayNameValidation.reset();
    dispatch(setFormIsDirty(false));

    if (isNew) {
      push(SharedMenuItemsRoutes.getItemGroupListRoute(companyId));
    }
  };

  const removeModalItem = (itemId: string) => {
    if (itemGroup?.items.length) {
      const filteredItems = itemGroup?.items.filter((i) => i.itemId !== itemId);
      dispatch(
        updateItemGroup({
          items: filteredItems,
        })
      );
      dispatch(setFormIsDirty(true));
    }
  };

  const addModalItem = (item: WithAssignedLocationsCount<ITemplateMenuItem>): void => {
    dispatch(
      updateItemGroup({
        items: [...(itemGroup?.items ?? []), mapITemplateMenuItemToITemplateEmbeddedMenuItem(item)],
      })
    );
    dispatch(setFormIsDirty(true));
  };

  const resetDuplicateModalChanges = (visible: boolean) => {
    setShowDuplicateModal(visible);
  };

  return (
    <>
      <FormActionBar
        actionBarVisible={actionBarVisible || isNew}
        saveLabel={isNew ? 'Create Shared Item Group' : undefined}
        handleFormSubmit={handleFormSubmit}
        resetForm={handleCancelChanges}
      />

      <SharedMenuItemsLayout tab={SharedMenuItemsTabId.ItemGroups}>
        {loading && !isNew ? (
          <LoadingSpinner />
        ) : (
          <>
            <ActionHeader
              backLinkTo={!isNew ? backLink : undefined}
              text={itemGroupCache?.displayName ?? 'New Shared Item Group'}
              actionButtons={
                isNew
                  ? undefined
                  : [
                      {
                        label: 'Duplicate',
                        variant: 'secondary-gray-bg',
                        disabled: saving,
                        onClick: () => {
                          setShowDuplicateModal(true);
                        },
                      },
                      {
                        label: 'Delete',
                        variant: 'secondary-gray-bg',
                        disabled: saving,
                        onClick: () => {
                          setShowDeleteModal(true);
                        },
                      },
                    ]
              }
            />
            {!isNew && (
              <DetailsTabs
                detailsRoute={SharedMenuItemsRoutes.getItemGroupListRoute(companyId)}
                assignedLocationsRoute={SharedMenuItemsRoutes.getItemGroupAssignedLocationsRoute(companyId, id)}
                selectedTabId={DetailTabId.Details}
              />
            )}

            <Form hasGroups>
              <PanelLayout stackOnTablet>
                <Panel>
                  <FormProvider {...displayNameValidation}>
                    <FormControl
                      label='Display Name *'
                      withError={!!errors.displayName}
                      errorMessage={errors.displayName?.message}
                    >
                      <Controller
                        control={control}
                        name='displayName'
                        rules={{ required: 'Display Name is required.' }}
                        defaultValue={itemGroup?.displayName}
                        render={({ field }) => {
                          return (
                            <TextInput
                              withError={!!errors.displayName}
                              placeholder='Enter a display name'
                              maxLength={100}
                              {...field}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                field.onChange(e.target.value);
                                dispatch(updateItemGroup({ displayName: e.target.value }));
                              }}
                            />
                          );
                        }}
                      />
                    </FormControl>
                  </FormProvider>

                  <FormControl label='Description'>
                    <TextArea
                      placeholder='Enter a description'
                      value={itemGroup?.description ?? ''}
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        dispatch(updateItemGroup({ description: e.target.value }));
                      }}
                    />
                  </FormControl>

                  <BannerAndThumbnailImagesFormControls
                    bannerImageId={itemGroup?.imageId}
                    bannerImageLoading={false}
                    uploadBannerImage={(asset: Partial<IAsset>, imageEl?: HTMLImageElement | undefined): void => {
                      dispatch(
                        updateItemGroup({
                          imageId: asset.fileKey,
                          imageWidth: imageEl?.naturalWidth ?? 0,
                          imageHeight: imageEl?.naturalHeight ?? 0,
                        })
                      );
                    }}
                    thumbnailImageId={itemGroup?.thumbnailImageId}
                    thumbnailImageLoading={false}
                    uploadThumbnailImage={(asset: Partial<IAsset>, imageEl?: HTMLImageElement | undefined): void => {
                      dispatch(
                        updateItemGroup({
                          thumbnailImageId: asset.fileKey,
                          thumbnailImageWidth: imageEl?.naturalWidth ?? 0,
                          thumbnailImageHeight: imageEl?.naturalHeight ?? 0,
                        })
                      );
                    }}
                  />
                </Panel>
                <Panel
                  title='Group Items'
                  headerControls={
                    <>
                      <div className={styles.groupItemsHeaderButton}>
                        <Button
                          label='+ Add Items'
                          variant='primary'
                          onClick={() => setShowGroupItemsModal(true)}
                          unavailable={loading || saving}
                        />
                      </div>

                      <div className={styles.groupItemsHeaderOverflow}>
                        <OverflowButton
                          options={[
                            {
                              label: '+ Add Items',
                              onClick: () => setShowGroupItemsModal(true),
                              disabled: loading || saving,
                            },
                          ]}
                        />
                      </div>
                    </>
                  }
                  fullContentArea
                >
                  <GroupItemsCards />
                </Panel>
              </PanelLayout>
            </Form>

            {showGroupItemsModal && (
              <AddMenuItemsDialog
                headerLabel='Add Item'
                setShowModal={setShowGroupItemsModal}
                selectedIds={itemGroup?.items.map((item) => item.itemId) ?? []}
                onRemove={removeModalItem}
                onAdd={addModalItem}
                initialTypeFilter='items'
                isSingleFilterModal
              />
            )}

            {showDuplicateModal && (
              <DuplicateMenuItemModal
                processing={saving}
                setVisible={setShowDuplicateModal}
                duplicateMenuItem={async (displayName) => {
                  if (itemGroupCache && !isNew) {
                    await dispatch(
                      duplicateSharedItemGroupThunk({
                        companyId,
                        itemGroup: itemGroupCache,
                        displayName,
                      })
                    );
                    dispatch(setFormIsDirty(false));
                    setShowDuplicateModal(false);
                  }
                }}
                cancelChanges={resetDuplicateModalChanges}
                headerLabel='item group'
                placeholder='e.g. Extra Cheese'
              />
            )}

            {showDeleteModal && (
              <DeleteConfirmationModal
                setShowModal={setShowDeleteModal}
                item='Shared Item Group'
                itemName={itemGroupCache?.displayName ?? ''}
                loading={saving}
                handleDelete={() => {
                  if (!isNew) {
                    dispatch(
                      deleteSharedItemGroupThunk({
                        companyId,
                        id,
                      })
                    );
                    dispatch(setFormIsDirty(false));
                  }
                }}
              />
            )}
          </>
        )}
      </SharedMenuItemsLayout>
    </>
  );
};
