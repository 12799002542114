import { IClientLocation, ILocationMenuAdvancedSettings, PaginationResponse } from '@ready/dashboardv2api.contracts';
import {
  EffectiveDates,
  IBaseItemInMenu,
  ICachedPosMenuItem,
  ICachedPosModifier,
  IEmbeddedMenuItem,
  IForcedModifier,
  IInnerItemGallery,
  IItemGallery,
  IItemGalleryRequest,
  IMenu,
  IMenuCopyState,
  IMenuGroup,
  IMenuItem,
  IMenuItemGroup,
  IMenuItemGroupRequest,
  IMenuItemRequest,
  IMenuRequest,
  IMenuSection,
  IMenuSectionItem,
  IMenuSectionItemGroup,
  IModifierGroup,
  IModifierGroupRequest,
  ISortableMenuSummary,
  IStockUpdateRequest,
  ITemplateItemGallery,
  ITemplateMenu,
  ITemplateMenuItem,
  ITemplateMenuItemGroup,
  Status,
  Visibility,
} from '@ready/menu.core';
import { MenuResourceActions, ResourceType } from '@ready/security.core';
import config from '../../config';
import { CompanyLocationService } from '../../services/companyLocationsService';
import { ILocation } from '../../services/types/ILocation.type';
import { IPagedResponse } from '../../services/types/IPagedResponse.type';
import executeApiAsync from '../../services/utils/executeApiAsync';
import toPaginationResponse from '../../services/utils/toPaginationResponse';
import coordinateExecutions, {
  CoordinatedExecutionResult,
  Execution,
  ExecutionGroup,
} from '../../utils/asyncUtils/coordinateExecutions';
import MenuItemsView from '../types/MenuItemsView.enum';
import ItemsAndGroupsStockStatus from '../types/ItemsAndGroupsStockStatus.enum';
import NamedEntityValidation, { newNamedEntityValidation } from '../types/NamedEntityValidation.interface';
import { SectionItemChange } from '../types/SectionItemChange.interface';
import { isEqualOrBefore } from '../../utils/dateUtils';
import { IMenuItemFormValidation, newMenuItemValidation } from '../redux/ItemsAndModsState';
import { mapMenuToMenuRequest } from 'menus/mappers/menu.mappers';
import { ISmbMenuItem, ISmbMenuItemRequest } from 'menus/types/SmbMenuTypes.type';
import { isReadySmb } from '../../utils/smb.utils';
import { ISmbTax } from '../../companySettings/types/SmbTaxTypes.type';
import { updateItemGroup } from 'sharedMenuItems/pages/itemGroups/itemGroupService';
import {
  mapIItemGalleryToITemplateItemGallery,
  mapIMenuItemGroupRequestToITemplateMenuItemGroupRequest,
  mapIMenuItemToITemplateMenuItem,
  mapIMenuToITemplateMenu,
  mapITemplateItemGalleryToRequest,
  mapITemplateMenuResponseToUpdateRequest,
} from 'sharedMenuItems/pages/menu/menu.mapper';
import { updateTemplateMenu } from 'sharedMenuItems/pages/menu/menu.service';
import { updateTemplateMenuItem } from 'sharedMenuItems/pages/createEditItem/service';
import { isSharedMenu } from 'sharedMenuItems/sharedMenuItems.utils';
import { updateTemplateMenuGroupItemGallery } from 'sharedMenuItems/pages/itemGallery/service';
export interface MenuSectionValidationResult {
  errorsFound: boolean;
  section: NamedEntityValidation;
  items: SectionItemChange[];
}

export interface ItemGroupValidationResult {
  errorsFound: boolean;
  itemGroup: NamedEntityValidation;
}

export interface MenuItemValidationResult {
  errorsFound: boolean;
  menuItem: IMenuItemFormValidation;
}

export default class MenuBuilderService {
  static BASE_URL = `${config.readyDashboardApiEndpoint}`;

  public static getMenuBuilderLocations = async (
    companyId: string,
    query: string = '',
    page: number = 1
  ): Promise<PaginationResponse<ILocation>> => {
    return CompanyLocationService.getPermittedLocations(
      companyId,
      [{ resourceType: ResourceType.menu, action: MenuResourceActions.changeStockStatus }],
      query,
      page
    );
  };

  public static getMenus = async (companyId: string, locationId: string): Promise<IMenu[]> => {
    const url = `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/summaries`;
    const response = await executeApiAsync<IPagedResponse<IMenu>>(url);
    return response.results;
  };

  public static createMenu = async (companyId: string, locationId: string, menu: IMenu): Promise<IMenu> => {
    const url = `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus`;
    const menuToSave: IMenuRequest = { ...menu, sections: [] };
    return await executeApiAsync<IMenu>(url, {
      method: 'POST',
      body: JSON.stringify(menuToSave),
    });
  };

  public static updateMenu = async (companyId: string, locationId: string, menu: IMenu): Promise<IMenu> => {
    const url = `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus`;

    const menuToUpdate: IMenuRequest = mapMenuToMenuRequest(menu);
    return await executeApiAsync<IMenu>(url, {
      method: 'PUT',
      body: JSON.stringify(menuToUpdate),
    });
  };

  public static saveMenuGallery = async (
    companyId: string,
    locationId: string,
    itemGallery: IItemGallery,
    sectionItemChanges: SectionItemChange[]
  ): Promise<CoordinatedExecutionResult> => {
    const menuItemUpdates = MenuBuilderService.prepareMenuItemUpdatesExecutionGroup(
      companyId,
      locationId,
      itemGallery!.items,
      sectionItemChanges
    );

    const menuItemGroupUpdates = MenuBuilderService.prepareMenuItemGroupUpdatesExecutionGroup(
      companyId,
      locationId,
      itemGallery!.items,
      sectionItemChanges
    );

    const locationItemGalleryUpdate: ExecutionGroup = {
      isCritical: true,
      failureMessage:
        'We were unable to save some changes to this item gallery. Some updates to your items and item groups may have saved.',
      includeIndividualMessages: false,
      executions: [
        {
          process: (): Promise<ITemplateItemGallery | IItemGallery> => {
            if (isSharedMenu(companyId, locationId)) {
              const locationItemGalleryInterface = mapIItemGalleryToITemplateItemGallery(itemGallery);
              const mappedItemGallery = mapITemplateItemGalleryToRequest(locationItemGalleryInterface);
              return updateTemplateMenuGroupItemGallery(companyId, mappedItemGallery);
            } else {
              return MenuBuilderService.updateMenuGallery(companyId, locationId, itemGallery);
            }
          },
          storeReturnValue: true,
        },
      ],
    };

    // fire off all of the executions
    return await coordinateExecutions([menuItemUpdates, menuItemGroupUpdates, locationItemGalleryUpdate]);
  };

  public static updateMenuGallery = async (
    companyId: string,
    locationId: string,
    itemGallery: IItemGallery
  ): Promise<IItemGallery> => {
    const url = `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/itemgalleries`;
    const isNewItemGallery = !itemGallery._id;
    const menuGalleryToSave: IItemGalleryRequest = {
      displayName: itemGallery.displayName || '',
      items: itemGallery.items || [],
      status: itemGallery.status,
      description: itemGallery.description,
      sortOrder: itemGallery.sortOrder,
      parentTemplateId: itemGallery.parentTemplateId,
    };
    if (!isNewItemGallery) {
      menuGalleryToSave._id = itemGallery._id;
    }
    return executeApiAsync<IItemGallery>(url, {
      method: isNewItemGallery ? 'POST' : 'PUT',
      body: JSON.stringify(menuGalleryToSave),
    });
  };

  public static updateMenuSummary = async (companyId: string, locationId: string, menu: IMenu): Promise<IMenu> => {
    const url = `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/summaries`;
    return await executeApiAsync<IMenu>(url, {
      method: 'PUT',
      body: JSON.stringify(menu),
    });
  };

  public static updateMenuOrder = async (
    companyId: string,
    locationId: string,
    menus: ISortableMenuSummary[]
  ): Promise<IMenuGroup> => {
    const url = `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menuGroups/locationCustomMenus/sortOrder`;
    const response = await executeApiAsync<IMenuGroup>(url, {
      method: 'PUT',
      body: JSON.stringify({
        menusAndGalleries: menus,
      }),
    });
    return response;
  };

  public static deleteMenuById = async (companyId: string, locationId: string, id: string): Promise<void> => {
    const url = `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/${id}`;
    await executeApiAsync(url, { method: 'DELETE' });
  };

  public static getMenu = async (companyId: string, locationId: string, menuId: string): Promise<IMenu> => {
    const url = `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/${menuId}`;
    return await executeApiAsync<IMenu>(url);
  };

  public static getMenuGallery = async (
    companyId: string,
    locationId: string,
    menuGalleryId: string
  ): Promise<IItemGallery> => {
    const url = `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/itemgalleries/${menuGalleryId}`;
    return await executeApiAsync<IItemGallery>(url);
  };

  public static deleteMenuGalleryById = async (companyId: string, locationId: string, id: string): Promise<void> => {
    const url = `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/itemgalleries/${id}`;
    await executeApiAsync(url, { method: 'DELETE' });
  };

  public static updateMenuSectionStatus = async (
    companyId: string,
    locationId: string,
    menu: IMenu,
    section: IMenuSection,
    enabled: boolean
  ): Promise<IMenu> => {
    const sectionIndex = menu.sections.findIndex((candidateSection) => candidateSection.uid === section.uid);
    if (sectionIndex > -1) {
      const sectionToDisable = menu.sections[sectionIndex];
      const menuWithDisabledSection = {
        ...menu,
        sections: [
          ...menu.sections.slice(0, sectionIndex),
          {
            ...sectionToDisable,
            status: enabled ? Status.enabled : Status.disabled,
          },
          ...menu.sections.slice(sectionIndex + 1),
        ],
      };
      return await MenuBuilderService.updateMenu(companyId, locationId, menuWithDisabledSection);
    }
    return menu;
  };

  public static updateMenuItemGalleryStatus = async (
    companyId: string,
    locationId: string,
    menu: IMenu,
    enabled: boolean
  ): Promise<IMenu> => {
    if (!menu.itemGallery) {
      return menu;
    }
    const menuWithUpdatedItemGallery = {
      ...menu,
      itemGallery: {
        ...menu.itemGallery,
        status: enabled ? Status.enabled : Status.disabled,
      },
    };
    return await MenuBuilderService.updateMenu(companyId, locationId, menuWithUpdatedItemGallery);
  };

  public static updateMenuSection = async (
    companyId: string,
    locationId: string,
    menu: IMenu,
    section: IMenuSection,
    sectionItemChanges: SectionItemChange[],
    itemGallery: IInnerItemGallery,
    galleryItemChanges: SectionItemChange[],
    readOnlyStock?: boolean
  ): Promise<CoordinatedExecutionResult> => {
    const menuItemUpdates = MenuBuilderService.prepareMenuItemUpdatesExecutionGroup(
      companyId,
      locationId,
      [...section.items, ...itemGallery.items],
      [...sectionItemChanges, ...galleryItemChanges],
      readOnlyStock
    );

    const menuItemGroupUpdates = MenuBuilderService.prepareMenuItemGroupUpdatesExecutionGroup(
      companyId,
      locationId,
      [...section.items, ...itemGallery.items],
      [...sectionItemChanges, ...galleryItemChanges]
    );

    // only re-order the section's items if not a shared menu
    let updatedSection = section;
    if (!menu.parentTemplateId) {
      // set up the sort order on the items in the item gallery
      const sortedGalleryItems: (IMenuSectionItem | IMenuSectionItemGroup)[] = [];
      for (let index = 0; index < itemGallery.items.length; index++) {
        sortedGalleryItems.push({
          ...itemGallery.items[index],
          sortOrder: index,
        });
      }
      const sortedItemGallery: IInnerItemGallery = {
        ...itemGallery,
        items: sortedGalleryItems,
      };

      // set up the sort order on the items in the section, and attach the sorted item gallery
      const sortedItems: (IMenuSectionItem | IMenuSectionItemGroup)[] = [];
      for (let index = 0; index < section.items.length; index++) {
        sortedItems.push({
          ...section.items[index],
          sortOrder: index,
        });
      }

      updatedSection = {
        ...section,
        items: sortedItems,
        itemGallery: sortedItemGallery,
      };
    }

    // prepare the menu section update execution
    let menuWithSection: IMenu;
    const sectionIndex = menu.sections.findIndex((candidateSection) => candidateSection.uid === section.uid);
    if (sectionIndex < 0) {
      menuWithSection = {
        ...menu,
        sections: [...menu.sections, { ...updatedSection, sortOrder: menu.sections.length }],
      };
    } else {
      menuWithSection = {
        ...menu,
        sections: [...menu.sections.slice(0, sectionIndex), updatedSection, ...menu.sections.slice(sectionIndex + 1)],
      };
    }

    const menuSectionUpdate: ExecutionGroup = {
      isCritical: true,
      failureMessage:
        'We were unable to save some changes to this section. Some updates to your items and item groups may have saved.',
      includeIndividualMessages: false,
      executions: [
        {
          process: (): Promise<IMenu | ITemplateMenu> => {
            if (isSharedMenu(companyId, locationId)) {
              const templateMenu = mapIMenuToITemplateMenu(menuWithSection);
              const templateMenuRequest = mapITemplateMenuResponseToUpdateRequest(templateMenu);
              return updateTemplateMenu(companyId, templateMenuRequest);
            } else {
              return MenuBuilderService.updateMenu(companyId, locationId, menuWithSection);
            }
          },

          storeReturnValue: true,
        },
      ],
    };

    // fire off all of the executions
    return await coordinateExecutions([menuItemUpdates, menuItemGroupUpdates, menuSectionUpdate]);
  };

  public static updateMenuInnerItemGallery = async (
    companyId: string,
    locationId: string,
    menu: IMenu,
    itemGallery: IInnerItemGallery,
    sectionItemChanges: SectionItemChange[],
    readOnlyStock?: boolean
  ): Promise<CoordinatedExecutionResult> => {
    const menuItemUpdates = MenuBuilderService.prepareMenuItemUpdatesExecutionGroup(
      companyId,
      locationId,
      itemGallery.items,
      sectionItemChanges,
      readOnlyStock
    );

    const menuItemGroupUpdates = MenuBuilderService.prepareMenuItemGroupUpdatesExecutionGroup(
      companyId,
      locationId,
      itemGallery.items,
      sectionItemChanges
    );

    let updatedItemGallery = {
      ...itemGallery,
      _id: menu.itemGallery!._id,
    };

    // only re-order the item gallery's items if not a shared menu
    if (!itemGallery.parentTemplateId) {
      // set up the sort order on the items in the section
      const sortedItems: (IMenuSectionItem | IMenuSectionItemGroup)[] = [];
      for (let index = 0; index < itemGallery.items.length; index++) {
        sortedItems.push({
          ...itemGallery.items[index],
          sortOrder: index,
        });
      }

      updatedItemGallery = {
        ...updatedItemGallery,
        items: sortedItems,
      };
    }

    // prepare the menu item gallery update execution
    const menuWithItemGallery: IMenu = {
      ...menu,
      itemGallery: updatedItemGallery,
    };

    const menuItemGalleryUpdate: ExecutionGroup = {
      isCritical: true,
      failureMessage:
        'We were unable to save some changes to this item gallery. Some updates to your items and item groups may have saved.',
      includeIndividualMessages: false,
      executions: [
        {
          process: (): Promise<IMenu | ITemplateMenu> => {
            if (isSharedMenu(companyId, locationId)) {
              const templateMenu = mapIMenuToITemplateMenu(menuWithItemGallery);
              const templateMenuRequest = mapITemplateMenuResponseToUpdateRequest(templateMenu);

              return updateTemplateMenu(companyId, templateMenuRequest);
            } else {
              return MenuBuilderService.updateMenu(companyId, locationId, menuWithItemGallery);
            }
          },
          storeReturnValue: true,
        },
      ],
    };

    // fire off all of the executions
    return await coordinateExecutions([menuItemUpdates, menuItemGroupUpdates, menuItemGalleryUpdate]);
  };

  public static updateMenuSectionMenuItemVisibility = async (
    companyId: string,
    locationId: string,
    menu: IMenu,
    sectionId: string,
    menuItemId: string,
    visible: boolean
  ): Promise<IMenu> => {
    const modifiedSections = menu.sections.map((section) => {
      if (section.uid !== sectionId) {
        return section;
      }
      const modifiedItems = section.items.map((item) => {
        if (item.itemId !== menuItemId) {
          return item;
        }
        return {
          ...item,
          visibility: visible ? Visibility.visible : Visibility.hidden,
        };
      });
      return { ...section, items: modifiedItems };
    });
    const updatedMenu = { ...menu, sections: modifiedSections };
    return await MenuBuilderService.updateMenu(companyId, locationId, updatedMenu);
  };

  public static updateMenuSectionItemGroupMenuItemVisibility = async (
    companyId: string,
    locationId: string,
    menu: IMenu,
    sectionId: string,
    itemGroupId: string,
    menuItemId: string,
    visible: boolean
  ): Promise<IMenu> => {
    const section = menu.sections.find((section) => section.uid === sectionId);
    if (!section) {
      return menu;
    }
    const itemGroup = section.items
      .filter((itemOrGroup) => itemOrGroup.sectionItemType === 'group')
      .find((group) => group.itemId === itemGroupId) as IMenuSectionItemGroup;
    const modifiedItems = itemGroup.items.map((item) => {
      if (item.itemId !== menuItemId) {
        return item;
      }
      return {
        ...item,
        visibility: visible ? Visibility.visible : Visibility.hidden,
      };
    });
    const modifiedItemGroup = {
      ...itemGroup,
      items: modifiedItems,
    };
    await MenuBuilderService.updateItemGroup(
      companyId,
      locationId,
      MenuBuilderService.convertIMenuSectionItemGroupToIMenuItemGroupRequest(modifiedItemGroup)
    );
    return await MenuBuilderService.getMenu(companyId, locationId, menu._id);
  };

  public static updateLocationLevelItemGalleryMenuItemVisibility = async (
    companyId: string,
    locationId: string,
    itemGallery: IItemGallery,
    menuItemId: string,
    visible: boolean
  ): Promise<IItemGallery> => {
    const updatedItemGallery: IItemGallery = {
      ...itemGallery,
      items: itemGallery.items.map((item) => {
        if (item.itemId !== menuItemId) {
          return item;
        }
        return {
          ...item,
          visibility: visible ? Visibility.visible : Visibility.hidden,
        };
      }),
    };
    return MenuBuilderService.updateMenuGallery(companyId, locationId, updatedItemGallery);
  };

  public static updateInnerItemGalleryMenuItemVisibility = async (
    companyId: string,
    locationId: string,
    menu: IMenu,
    itemGalleryId: string,
    menuItemId: string,
    visible: boolean
  ): Promise<IMenu> => {
    let updatedMenu: IMenu | undefined;
    if (menu.itemGallery?._id === itemGalleryId) {
      // Item gallery is at the menu level; update accordingly
      const modifiedItems = menu.itemGallery.items.map((item) => {
        if (item.itemId !== menuItemId) {
          return item;
        }
        return {
          ...item,
          visibility: visible ? Visibility.visible : Visibility.hidden,
        };
      });
      const updatedItemGallery = { ...menu.itemGallery, items: modifiedItems };
      updatedMenu = { ...menu, itemGallery: updatedItemGallery };
    } else {
      let index = 0;
      for (const section of menu.sections) {
        if (section.itemGallery?._id === itemGalleryId) {
          // Item gallery is at the section level in this section; update accordingly
          const modifiedItems = section.itemGallery.items.map((item) => {
            if (item.itemId !== menuItemId) {
              return item;
            }
            return {
              ...item,
              visibility: visible ? Visibility.visible : Visibility.hidden,
            };
          });
          const updatedItemGallery = {
            ...section.itemGallery,
            items: modifiedItems,
          };
          const updatedSection = {
            ...section,
            itemGallery: updatedItemGallery,
          };
          const updatedSections = [...menu.sections.slice(0, index), updatedSection, ...menu.sections.slice(index + 1)];
          updatedMenu = { ...menu, sections: updatedSections };
        }
        index++;
      }
    }

    if (!updatedMenu) {
      return menu;
    }

    return await MenuBuilderService.updateMenu(companyId, locationId, updatedMenu);
  };

  public static updateInnerItemGalleryItemGroupMenuItemVisibility = async (
    companyId: string,
    locationId: string,
    menu: IMenu,
    itemGalleryId: string,
    itemGroupId: string,
    menuItemId: string,
    visible: boolean
  ): Promise<IMenu> => {
    let itemGroup: IMenuSectionItemGroup | undefined;
    if (menu.itemGallery?._id === itemGalleryId) {
      // Item gallery is at the menu level; update accordingly
      itemGroup = menu.itemGallery.items
        .filter((itemOrGroup) => itemOrGroup.sectionItemType === 'group')
        .find((group) => group.itemId === itemGroupId) as IMenuSectionItemGroup;
    } else {
      for (const section of menu.sections) {
        if (section.itemGallery?._id === itemGalleryId) {
          // Item gallery is at the section level in this section; update accordingly
          itemGroup = section.itemGallery.items
            .filter((itemOrGroup) => itemOrGroup.sectionItemType === 'group')
            .find((group) => group.itemId === itemGroupId) as IMenuSectionItemGroup;
        }
      }
    }

    if (!itemGroup) {
      return menu;
    }

    const modifiedItems = itemGroup.items.map((item) => {
      if (item.itemId !== menuItemId) {
        return item;
      }
      return {
        ...item,
        visibility: visible ? Visibility.visible : Visibility.hidden,
      };
    });
    const modifiedItemGroup = {
      ...itemGroup,
      items: modifiedItems,
    };

    await MenuBuilderService.updateItemGroup(
      companyId,
      locationId,
      MenuBuilderService.convertIMenuSectionItemGroupToIMenuItemGroupRequest(modifiedItemGroup)
    );
    return await MenuBuilderService.getMenu(companyId, locationId, menu._id);
  };

  public static updateLocationItemGalleryItemGroupMenuItemVisibilityAndSave = async (
    companyId: string,
    locationId: string,
    itemGallery: IItemGallery,
    itemGroupId: string,
    menuItemId: string,
    visible: boolean
  ): Promise<IItemGallery> => {
    const itemGroup = itemGallery.items
      .filter((itemOrGroup) => itemOrGroup.sectionItemType === 'group')
      .find((group) => group.itemId === itemGroupId) as IMenuSectionItemGroup;

    if (!itemGroup) {
      return itemGallery;
    }

    const modifiedItems = itemGroup.items.map((item) => {
      if (item.itemId !== menuItemId) {
        return item;
      }
      return {
        ...item,
        visibility: visible ? Visibility.visible : Visibility.hidden,
      };
    });
    const modifiedItemGroup = {
      ...itemGroup,
      items: modifiedItems,
    };

    await MenuBuilderService.updateItemGroup(
      companyId,
      locationId,
      MenuBuilderService.convertIMenuSectionItemGroupToIMenuItemGroupRequest(modifiedItemGroup)
    );
    return await MenuBuilderService.getMenuGallery(companyId, locationId, itemGallery._id);
  };

  public static syncMenuItems = async (companyId: string, locationId: string): Promise<void> => {
    return await executeApiAsync<void>(
      `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/sync`,
      {
        method: 'POST',
      }
    );
  };

  public static getMenuItems = async (
    companyId: string,
    locationId: string,
    view: MenuItemsView,
    stockStatus: ItemsAndGroupsStockStatus = ItemsAndGroupsStockStatus.All,
    query: string = '',
    page: number = 1
  ): Promise<PaginationResponse<IMenuItem>> => {
    const pageSize = 50;
    const itemType = view === MenuItemsView.ITEMS ? 'items' : view === MenuItemsView.MODS ? 'options' : 'all';

    let inStockFilter: boolean | undefined = undefined;
    if (stockStatus === ItemsAndGroupsStockStatus.InStock) {
      inStockFilter = true;
    } else if (stockStatus === ItemsAndGroupsStockStatus.OutOfStock) {
      inStockFilter = false;
    }
    const url = `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/items?itemType=${itemType}&searchTerm=${query}&pageSize=${pageSize}&pageNumber=${page}&inStockFilter=${inStockFilter}`;
    const response = await executeApiAsync<IPagedResponse<IMenuItem>>(url);
    return toPaginationResponse(response, page, pageSize);
  };

  public static getMenuItem = async (
    companyId: string,
    locationId: string,
    menuItemId: string,
    abortController?: AbortController
  ): Promise<IMenuItem> => {
    const url = `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/items/${menuItemId}`;
    return await executeApiAsync<IMenuItem>(url, {}, true, abortController);
  };

  public static getSmbMenuItem = async (
    companyId: string,
    locationId: string,
    menuItemId: string,
    abortController?: AbortController
  ): Promise<ISmbMenuItem> => {
    const url = `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/smbitems/${menuItemId}`;
    return await executeApiAsync<ISmbMenuItem>(url, {}, true, abortController);
  };

  public static validateMenuItem = (menuItem: IMenuItem): MenuItemValidationResult => {
    const result = {
      errorsFound: false,
      menuItem: newMenuItemValidation(),
    };

    if (!menuItem.displayName) {
      result.errorsFound = true;
      result.menuItem.displayName.hasError = true;
      result.menuItem.displayName.errorMessage = 'Required field.';
    }

    const itemEffectiveDates = EffectiveDates.from(menuItem.effectiveDates);
    if (
      itemEffectiveDates?.start &&
      itemEffectiveDates?.end &&
      isEqualOrBefore(itemEffectiveDates.end, itemEffectiveDates.start)
    ) {
      result.errorsFound = true;
      result.menuItem.effectiveStartDate.hasError = true;
      result.menuItem.effectiveStartDate.errorMessage = 'End Date must be later than Start Date';
    }

    return result;
  };

  public static validateSmbMenuItem = (menuItem: ISmbMenuItem): MenuItemValidationResult => {
    const validationResult = MenuBuilderService.validateMenuItem(menuItem);

    if (menuItem.price === undefined || menuItem.price === null) {
      validationResult.errorsFound = true;
      validationResult.menuItem.price = { hasError: true, errorMessage: 'Required field.' };
    }

    return validationResult;
  };

  public static storeMenuItem = async (
    companyId: string,
    location: IClientLocation,
    menuItem: IMenuItem,
    readOnlyStock?: boolean,
    taxes?: ISmbTax[]
  ): Promise<IMenuItem> => {
    const suppressInStock = !location.settings.allowManualStockUpdates;
    if (isReadySmb(location.posSystemType)) {
      const menuItemRequest = MenuBuilderService.convertISmbMenuItemToISmbMenuItemRequest(
        menuItem,
        suppressInStock,
        taxes
      );
      if (menuItem._id) {
        return await MenuBuilderService.updateSmbMenuItem(companyId, location.id, menuItemRequest, readOnlyStock);
      } else {
        return await MenuBuilderService.createSmbMenuItem(companyId, location.id, menuItemRequest, readOnlyStock);
      }
    } else {
      const menuItemRequest = MenuBuilderService.convertIMenuItemToIMenuItemRequest(menuItem, suppressInStock);
      if (menuItem._id) {
        return await MenuBuilderService.updateMenuItem(companyId, location.id, menuItemRequest, readOnlyStock);
      } else {
        return await MenuBuilderService.createMenuItem(companyId, location.id, menuItemRequest, readOnlyStock);
      }
    }
  };

  public static createMenuItem = async (
    companyId: string,
    locationId: string,
    menuItem: IMenuItemRequest,
    readOnlyStock?: boolean
  ): Promise<IMenuItem> => {
    const url = `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/items`;
    const payload = { ...menuItem };

    if (readOnlyStock) {
      delete payload.inStock;
    }

    return await executeApiAsync<IMenuItem>(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
  };

  public static createSmbMenuItem = async (
    companyId: string,
    locationId: string,
    menuItem: ISmbMenuItemRequest,
    readOnlyStock?: boolean
  ): Promise<IMenuItem> => {
    const url = `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/smbitems`;
    const payload = { ...menuItem };

    if (readOnlyStock) {
      delete payload.inStock;
    }

    return await executeApiAsync<IMenuItem>(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
  };

  public static updateMenuItem = async (
    companyId: string,
    locationId: string,
    menuItem: IMenuItemRequest,
    readOnlyStock?: boolean
  ): Promise<IMenuItem> => {
    const url = `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/items`;
    const payload = { ...menuItem };

    if (readOnlyStock) {
      delete payload.inStock;
    }

    return await executeApiAsync<IMenuItem>(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
    });
  };

  public static updateSmbMenuItem = async (
    companyId: string,
    locationId: string,
    menuItem: ISmbMenuItemRequest,
    readOnlyStock?: boolean
  ): Promise<IMenuItem> => {
    const url = `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/smbitems`;
    const payload = { ...menuItem };

    if (readOnlyStock) {
      delete payload.inStock;
    }

    return await executeApiAsync<IMenuItem>(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
    });
  };

  public static deleteMenuItem = async (companyId: string, locationId: string, menuItemId: string): Promise<void> => {
    const url = `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/items/${menuItemId}`;
    await executeApiAsync<IMenuItem>(url, {
      method: 'DELETE',
    });
  };

  public static getPosItems = async (
    companyId: string,
    locationId: string,
    query: string = '',
    page: number = 1,
    pageSize: number = 50
  ): Promise<PaginationResponse<ICachedPosMenuItem>> => {
    const url = `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/positems?searchTerm=${query}&pageSize=${pageSize}&pageNumber=${page}`;
    const response = await executeApiAsync<IPagedResponse<ICachedPosMenuItem>>(url);
    return toPaginationResponse(response, page, pageSize);
  };

  public static getPosModifiers = async (
    companyId: string,
    locationId: string,
    query: string = '',
    page: number = 1,
    pageSize: number = 50
  ): Promise<PaginationResponse<ICachedPosModifier>> => {
    const url = `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/posmodifiers?searchTerm=${query}&pageSize=${pageSize}&pageNumber=${page}`;
    const response = await executeApiAsync<IPagedResponse<ICachedPosModifier>>(url);
    return toPaginationResponse(response, page, pageSize);
  };

  public static getItemGroups = async (
    companyId: string,
    locationId: string,
    query: string = '',
    page: number = 1,
    pageSize: number = 10
  ): Promise<PaginationResponse<IMenuItemGroup>> => {
    const url = `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/itemgroups?searchTerm=${query}&pageSize=${pageSize}&pageNumber=${page}`;
    const response = await executeApiAsync<IPagedResponse<IMenuItemGroup>>(url);
    return toPaginationResponse(response, page, pageSize);
  };

  public static updateItemGroup = async (
    companyId: string,
    locationId: string,
    menuItemGroup: IMenuItemGroupRequest
  ): Promise<void> => {
    const url = `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/itemgroups`;
    await executeApiAsync<IPagedResponse<IMenuItemGroup>>(url, {
      method: 'PUT',
      body: JSON.stringify(menuItemGroup),
    });
  };

  public static updateOneItemGroup = async (
    companyId: string,
    locationId: string,
    menuItemGroup: IMenuItemGroupRequest
  ): Promise<IMenuItemGroup> => {
    const url = `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/itemgroups`;
    return await executeApiAsync<IMenuItemGroup>(url, {
      method: 'PUT',
      body: JSON.stringify(menuItemGroup),
    });
  };

  public static getItemGroup = async (companyId: string, locationId: string, id: string): Promise<IMenuItemGroup> => {
    const url = `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/itemgroups/${id}`;
    return await executeApiAsync<IMenuItemGroup>(url);
  };

  public static createItemGroup = async (
    companyId: string,
    locationId: string,
    itemGroup: IMenuItemGroup
  ): Promise<IMenuItemGroup> => {
    const url = `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/itemgroups`;
    return await executeApiAsync<IMenuItemGroup>(url, {
      method: 'POST',
      body: JSON.stringify(itemGroup),
    });
  };

  public static deleteItemGroup = async (companyId: string, locationId: string, id: string): Promise<void> => {
    const url = `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/itemgroups/${id}`;
    await executeApiAsync<any>(url, { method: 'DELETE' });
  };

  public static getModifierGroups = async (
    companyId: string,
    locationId: string,
    query: string = '',
    page: number = 1
  ): Promise<PaginationResponse<IModifierGroup>> => {
    const pageSize = 50;
    const url = `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/modifiergroups?searchTerm=${query}&pageNumber=${page}&pageSize=${pageSize}`;
    const response = await executeApiAsync<IPagedResponse<IModifierGroup>>(url);
    return toPaginationResponse(response, page, pageSize);
  };

  public static deleteModifierGroupById = async (companyId: string, locationId: string, id: string): Promise<void> => {
    const url = `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/modifiergroups/${id}`;
    await executeApiAsync(url, { method: 'DELETE' });
  };

  public static getModifierGroup = async (
    companyId: string,
    locationId: string,
    modifierGroupId: string
  ): Promise<IModifierGroup> => {
    const url = `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/modifiergroups/${modifierGroupId}`;
    return await executeApiAsync<IModifierGroup>(url);
  };

  public static createModifierGroup = async (
    companyId: string,
    locationId: string,
    modifierGroup: IModifierGroup
  ): Promise<IModifierGroup> => {
    const url = `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/modifiergroups`;
    const modifierGroupToCreate: IModifierGroupRequest = modifierGroup;
    return await executeApiAsync<IModifierGroup>(url, {
      method: 'POST',
      body: JSON.stringify(modifierGroupToCreate),
    });
  };

  public static updateModifierGroup = async (
    companyId: string,
    locationId: string,
    modifierGroup: IModifierGroup
  ): Promise<IModifierGroup> => {
    const url = `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/modifiergroups`;
    const modifierGroupToUpdate: IModifierGroupRequest = modifierGroup;
    return await executeApiAsync<IModifierGroup>(url, {
      method: 'PUT',
      body: JSON.stringify(modifierGroupToUpdate),
    });
  };

  public static validateMenuSection = (
    section: IMenuSection,
    sectionItemChanges: SectionItemChange[],
    galleryItemChanges: SectionItemChange[]
  ): Omit<MenuSectionValidationResult, 'items'> => {
    const validationResult: Omit<MenuSectionValidationResult, 'items'> = {
      errorsFound: false,
      section: newNamedEntityValidation(),
    };

    // first validate the section
    if (!section.displayName) {
      validationResult.section.displayName.hasError = true;
      validationResult.section.displayName.errorMessage = 'Section Name is required';
      validationResult.errorsFound = true;
    }

    // then validate any menu items that have had their display name changed in the section
    const sectionItemChangesResult = MenuBuilderService.validateSectionItemChanges(sectionItemChanges);
    if (sectionItemChangesResult.errorsFound) {
      validationResult.errorsFound = true;
    }

    // and finally validate any menu items that have had their display name changed in the item gallery
    const galleryItemChangesResult = MenuBuilderService.validateSectionItemChanges(galleryItemChanges);
    if (galleryItemChangesResult.errorsFound) {
      validationResult.errorsFound = true;
    }

    return validationResult;
  };

  public static validateItemGroup = (itemGroup: IMenuItemGroup): ItemGroupValidationResult => {
    const validationResult: ItemGroupValidationResult = {
      errorsFound: false,
      itemGroup: newNamedEntityValidation(),
    };

    if (!itemGroup.displayName) {
      validationResult.itemGroup.displayName.hasError = true;
      validationResult.itemGroup.displayName.errorMessage = 'Item Group Name is required';
      validationResult.errorsFound = true;
    }

    return validationResult;
  };

  public static validateSectionItemChanges = (sectionItemChanges: SectionItemChange[]): MenuSectionValidationResult => {
    const validationResult: MenuSectionValidationResult = {
      errorsFound: false,
      section: newNamedEntityValidation(),
      items: [],
    };

    for (const sectionItemName of sectionItemChanges) {
      let sectionHasError = false;
      if (!sectionItemName.displayName) {
        sectionHasError = true;
        validationResult.errorsFound = true;
      }
      validationResult.items.push({
        itemId: sectionItemName.itemId,
        itemType: sectionItemName.itemType,
        displayName: sectionItemName.displayName,
        originalDisplayName: sectionItemName.originalDisplayName,
        modified: sectionItemName.modified,
        validation: {
          displayName: {
            hasError: sectionHasError,
            errorMessage: sectionHasError ? 'Display Name is required' : '',
          },
        },
      });
    }
    return validationResult;
  };

  public static convertIEmbeddedMenuItemToIBaseItemInMenu = (item: IEmbeddedMenuItem): IBaseItemInMenu<string> => ({
    sortOrder: item.sortOrder,
    itemId: item.itemId,
    visibility: item.visibility,
  });

  public static convertIMenuItemToIEmbeddedMenuItem = (menuItem: IMenuItem): IEmbeddedMenuItem => ({
    itemId: menuItem._id,
    sortOrder: 0,
    visibility: Visibility.visible,
    posItemId: menuItem.posItemId,
    status: menuItem.status,
    inactiveStates: menuItem.inactiveStates,
    imageId: menuItem.imageId,
    thumbnailImageId: menuItem.thumbnailImageId,
    itemType: menuItem.itemType,
    displayName: menuItem.displayName,
    description: menuItem.description,
    alcohol: menuItem.alcohol,
    posItemName: menuItem.posItemName,
    posItemCategories: menuItem.posItemCategories,
    inStock: menuItem.inStock,
    modifierGroups:
      (!!menuItem.modifierGroups &&
        menuItem.modifierGroups.map((modifierGroup) => ({
          _id: modifierGroup._id,
          sortOrder: modifierGroup.sortOrder,
        }))) ||
      [],
    activePriceLevel: menuItem.activePriceLevel,
    priceLevels: menuItem.priceLevels,
    price: menuItem.price,
    schedule: menuItem.schedule,
    effectiveDates: menuItem.effectiveDates,
    isPopular: menuItem.isPopular,
    forcedModifiers: menuItem.forcedModifiers,
    forcedModifierStatus: menuItem.forcedModifierStatus,
  });

  public static convertIMenuItemToIMenuItemRequest = (
    menuItem: IMenuItem,
    suppressInStock?: boolean
  ): IMenuItemRequest => ({
    _id: menuItem._id,
    posItemId: menuItem.posItemId,
    itemType: menuItem.itemType,
    status: menuItem.status,
    imageId: menuItem.imageId,
    imageWidth: menuItem.imageWidth,
    imageHeight: menuItem.imageHeight,
    thumbnailImageId: menuItem.thumbnailImageId,
    thumbnailImageWidth: menuItem.thumbnailImageWidth,
    thumbnailImageHeight: menuItem.thumbnailImageHeight,
    displayName: menuItem.displayName,
    description: menuItem.description,
    alcohol: menuItem.alcohol,
    modifierGroups: menuItem.modifierGroups.map((modifierGroup) => ({
      _id: modifierGroup._id,
      sortOrder: modifierGroup.sortOrder,
    })),
    activePriceLevel: menuItem.activePriceLevel,
    schedule: menuItem.schedule,
    effectiveDates: menuItem.effectiveDates,
    isPopular: menuItem.isPopular,
    recommendedItems: menuItem.recommendedItems.map(MenuBuilderService.convertIEmbeddedMenuItemToIBaseItemInMenu),

    forcedModifiers: menuItem.forcedModifiers.map((fm: IForcedModifier) => ({ itemId: fm.itemId })),
    inStock: !suppressInStock ? menuItem.inStock : undefined,
    tags: menuItem.tags?.map((t) => t._id),
    badge: menuItem.badge?._id,
    parentTemplateId: menuItem.parentTemplateId,
  });

  public static convertISmbMenuItemToISmbMenuItemRequest = (
    menuItem: IMenuItem,
    suppressInStock?: boolean,
    taxes?: ISmbTax[]
  ): ISmbMenuItemRequest => ({
    price: menuItem.price,
    taxes,
    ...MenuBuilderService.convertIMenuItemToIMenuItemRequest(menuItem, suppressInStock),
  });

  public static convertIMenuSectionItemToIEmbeddedMenuItem = (
    menuSectionItem: IMenuSectionItem
  ): IEmbeddedMenuItem => ({
    itemId: menuSectionItem.itemId,
    sortOrder: menuSectionItem.sortOrder,
    visibility: menuSectionItem.visibility,
    posItemId: menuSectionItem.posItemId,
    status: menuSectionItem.status,
    inactiveStates: menuSectionItem.inactiveStates,
    imageId: menuSectionItem.imageId,
    thumbnailImageId: menuSectionItem.thumbnailImageId,
    itemType: menuSectionItem.itemType,
    displayName: menuSectionItem.displayName,
    description: menuSectionItem.description,
    alcohol: menuSectionItem.alcohol,
    posItemName: menuSectionItem.posItemName,
    posItemCategories: menuSectionItem.posItemCategories,
    inStock: menuSectionItem.inStock,
    modifierGroups: [],
    activePriceLevel: menuSectionItem.activePriceLevel,
    priceLevels: menuSectionItem.priceLevels,
    price: menuSectionItem.price,
    schedule: menuSectionItem.schedule,
    effectiveDates: menuSectionItem.effectiveDates,
    isPopular: menuSectionItem.isPopular,
    forcedModifiers: menuSectionItem.forcedModifiers,
    forcedModifierStatus: menuSectionItem.forcedModifierStatus,
  });

  public static convertIMenuSectionItemToIMenuItemRequest = (menuSectionItem: IMenuSectionItem): IMenuItemRequest => ({
    _id: menuSectionItem.itemId,
    posItemId: menuSectionItem.posItemId,
    itemType: menuSectionItem.itemType,
    status: menuSectionItem.status,
    imageId: menuSectionItem.imageId,
    thumbnailImageId: menuSectionItem.thumbnailImageId,
    displayName: menuSectionItem.displayName,
    description: menuSectionItem.description,
    alcohol: menuSectionItem.alcohol,
    modifierGroups: menuSectionItem.modifierGroups,
    activePriceLevel: menuSectionItem.activePriceLevel,
    schedule: menuSectionItem.schedule,
    effectiveDates: menuSectionItem.effectiveDates,
    isPopular: menuSectionItem.isPopular,
    recommendedItems: menuSectionItem.recommendedItems,
    forcedModifiers: menuSectionItem.forcedModifiers.map((fm: IForcedModifier) => ({
      itemId: fm.itemId,
    })),
    tags: menuSectionItem?.tags?.map((tag) => tag?._id),
    badge: menuSectionItem.badge?._id,
    thumbnailImageHeight: menuSectionItem.thumbnailImageHeight,
    thumbnailImageWidth: menuSectionItem.thumbnailImageWidth,
    imageHeight: menuSectionItem.imageHeight,
    imageWidth: menuSectionItem.imageWidth,
    inStock: menuSectionItem.inStock,
  });

  public static convertIEmbeddedMenuItemToIMenuSectionItem = (
    embeddedMenuItem: IEmbeddedMenuItem
  ): IMenuSectionItem => ({
    itemId: embeddedMenuItem.itemId,
    sortOrder: embeddedMenuItem.sortOrder,
    visibility: embeddedMenuItem.visibility,
    posItemId: embeddedMenuItem.posItemId,
    itemType: embeddedMenuItem.itemType,
    status: embeddedMenuItem.status,
    imageId: embeddedMenuItem.imageId,
    thumbnailImageId: embeddedMenuItem.thumbnailImageId,
    displayName: embeddedMenuItem.displayName,
    description: embeddedMenuItem.description,
    alcohol: embeddedMenuItem.alcohol,
    modifierGroups: embeddedMenuItem.modifierGroups,
    activePriceLevel: embeddedMenuItem.activePriceLevel,
    sectionItemType: 'item',
    posItemName: embeddedMenuItem.posItemName,
    posItemCategories: embeddedMenuItem.posItemCategories,
    inStock: true,
    priceLevels: embeddedMenuItem.priceLevels,
    price: embeddedMenuItem.price,
    inactiveStates: [],
    schedule: embeddedMenuItem.schedule,
    effectiveDates: embeddedMenuItem.effectiveDates,
    isPopular: embeddedMenuItem.isPopular,
    recommendedItems: [],
    forcedModifiers: embeddedMenuItem.forcedModifiers,
    forcedModifierStatus: embeddedMenuItem.forcedModifierStatus,
  });

  public static convertIMenuItemToIMenuSectionItem = (menuItem: IMenuItem): IMenuSectionItem => ({
    itemId: menuItem._id,
    sortOrder: 0,
    visibility: Visibility.visible,
    posItemId: menuItem.posItemId,
    itemType: menuItem.itemType,
    status: menuItem.status,
    imageId: menuItem.imageId,
    thumbnailImageId: menuItem.thumbnailImageId,
    displayName: menuItem.displayName,
    description: menuItem.description,
    alcohol: menuItem.alcohol,
    modifierGroups: menuItem.modifierGroups.map((modifierGroup) => ({
      _id: modifierGroup._id,
      sortOrder: modifierGroup.sortOrder,
    })),
    activePriceLevel: menuItem.activePriceLevel,
    sectionItemType: 'item',
    posItemName: menuItem.posItemName,
    posItemCategories: menuItem.posItemCategories,
    inStock: true,
    priceLevels: menuItem.priceLevels,
    price: menuItem.price,
    inactiveStates: menuItem.inactiveStates,
    schedule: menuItem.schedule,
    effectiveDates: menuItem.effectiveDates,
    isPopular: menuItem.isPopular,
    recommendedItems: menuItem.recommendedItems,
    forcedModifiers: menuItem.forcedModifiers,
    forcedModifierStatus: menuItem.forcedModifierStatus,
  });

  public static convertIMenuItemGroupToIMenuSectionItemGroup = (
    menuItemGroup: IMenuItemGroup
  ): IMenuSectionItemGroup => ({
    itemId: menuItemGroup._id,
    sortOrder: 0,
    visibility: Visibility.visible,
    displayName: menuItemGroup.displayName,
    description: menuItemGroup.description,
    imageId: menuItemGroup.imageId,
    thumbnailImageId: menuItemGroup.thumbnailImageId,
    sectionItemType: 'group',
    items: menuItemGroup.items.map(MenuBuilderService.convertIEmbeddedMenuItemToIMenuSectionItem),
  });

  public static convertIMenuSectionItemGroupToIMenuItemGroup = (
    menuSectionItemGroup: IMenuSectionItemGroup
  ): IMenuItemGroup => ({
    _id: menuSectionItemGroup.itemId,
    displayName: menuSectionItemGroup.displayName,
    description: menuSectionItemGroup.description,
    imageId: menuSectionItemGroup.imageId,
    thumbnailImageId: menuSectionItemGroup.thumbnailImageId,
    items: menuSectionItemGroup.items.map(MenuBuilderService.convertIMenuSectionItemToIEmbeddedMenuItem),
  });

  public static convertIMenuSectionItemGroupToIMenuItemGroupRequest = (
    menuSectionItemGroup: IMenuSectionItemGroup
  ): IMenuItemGroupRequest => ({
    _id: menuSectionItemGroup.itemId,
    displayName: menuSectionItemGroup.displayName,
    description: menuSectionItemGroup.description,
    imageId: menuSectionItemGroup.imageId,
    thumbnailImageId: menuSectionItemGroup.thumbnailImageId,
    items: menuSectionItemGroup.items.map(MenuBuilderService.convertIEmbeddedMenuItemToIBaseItemInMenu),
    schedule: menuSectionItemGroup.schedule,
    thumbnailImageHeight: menuSectionItemGroup.thumbnailImageHeight,
    thumbnailImageWidth: menuSectionItemGroup.thumbnailImageWidth,
    imageHeight: menuSectionItemGroup.imageHeight,
    imageWidth: menuSectionItemGroup.imageWidth,
    parentTemplateId: menuSectionItemGroup.parentTemplateId,
  });

  public static bulkUpdateItemAndModStock = async (
    companyId: string,
    locationId: string,
    items: IStockUpdateRequest[]
  ): Promise<void> => {
    const url = `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/items/stock`;
    await executeApiAsync(url, {
      method: 'PUT',
      body: JSON.stringify(items),
    });
  };

  public static updateAdvancedSettings = async (
    companyId: string,
    locationId: string,
    suppressSectionsInNavigation: boolean,
    menuReadOnly: boolean
  ): Promise<ILocationMenuAdvancedSettings> => {
    const body = JSON.stringify({
      suppressSectionsInNavigation,
      menuReadOnly,
    });
    const url = `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/advancedSettings`;
    return executeApiAsync<ILocationMenuAdvancedSettings>(url, {
      method: 'PUT',
      body,
    });
  };

  public static getMenuCopyState = async (companyId: string, locationId: string): Promise<IMenuCopyState> => {
    return executeApiAsync<IMenuCopyState>(
      `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/copy`
    );
  };

  public static acknowledgeFailedMenuCopy = async (companyId: string, locationId: string): Promise<void> => {
    return executeApiAsync<void>(
      `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${locationId}/menus/copy/acknowledgement`,
      {
        method: 'POST',
      }
    );
  };

  public static launchMenuCopy = async (
    companyId: string,
    fromLocationId: string,
    toLocationId: string
  ): Promise<void> => {
    return executeApiAsync<void>(
      `${MenuBuilderService.BASE_URL}/companies/${companyId}/locations/${toLocationId}/menus/copy`,
      {
        method: 'POST',
        body: JSON.stringify({
          fromLocationId,
        }),
      }
    );
  };

  private static prepareMenuItemUpdatesExecutionGroup = (
    companyId: string,
    locationId: string,
    items: (IMenuSectionItem | IMenuSectionItemGroup)[],
    sectionItemChanges: SectionItemChange[],
    readOnlyStock?: boolean
  ): ExecutionGroup => {
    // prepare all of the menu item update executions
    const menuItemUpdateExecutions: Execution[] = sectionItemChanges
      .filter((sectionItemChange) => sectionItemChange.itemType === 'item')
      .filter((sectionItemChange) => sectionItemChange.originalDisplayName !== sectionItemChange.displayName)
      .map((sectionItemChange) => {
        let item: IMenuSectionItem;
        item = items
          .filter((item) => item.sectionItemType === 'item')
          .find((item) => item.itemId === sectionItemChange.itemId) as IMenuSectionItem;
        if (!item) {
          // didn't find the item in the section; it must be in an item group
          item = items
            .filter((item) => item.sectionItemType === 'group')
            .flatMap((item) => (item as IMenuSectionItemGroup).items)
            .find((item) => item.itemId === sectionItemChange.itemId) as IMenuSectionItem;
        }
        let itemRequest = MenuBuilderService.convertIMenuSectionItemToIMenuItemRequest(item);
        if (sectionItemChange.originalDisplayName !== sectionItemChange.displayName) {
          // update the name
          itemRequest.displayName = sectionItemChange.displayName;
        }

        return {
          process: (): Promise<IMenuItem | ITemplateMenuItem> =>
            isSharedMenu(companyId, locationId)
              ? updateTemplateMenuItem(companyId, mapIMenuItemToITemplateMenuItem(itemRequest))
              : MenuBuilderService.updateMenuItem(companyId, locationId, itemRequest, readOnlyStock),
          storeReturnValue: false,
          failureMessage: sectionItemChange.originalDisplayName,
        } as Execution;
      });
    return {
      isCritical: false,
      failureMessage: 'We were unable to save changes on the following menu items:',
      includeIndividualMessages: true,
      executions: menuItemUpdateExecutions,
    };
  };

  private static prepareMenuItemGroupUpdatesExecutionGroup = (
    companyId: string,
    locationId: string,
    items: (IMenuSectionItem | IMenuSectionItemGroup)[],
    sectionItemChanges: SectionItemChange[]
  ): ExecutionGroup => {
    // prepare all of the menu item group update executions
    const menuItemGroupUpdateExecutions: Execution[] = sectionItemChanges
      .filter((sectionItemChange) => sectionItemChange.itemType === 'group')
      .filter(
        (sectionItemChange) =>
          sectionItemChange.originalDisplayName !== sectionItemChange.displayName ||
          (sectionItemChange.modified && items.some((i) => i.itemId === sectionItemChange.itemId))
      )
      .map((sectionItemChange) => {
        const itemGroup = items.find((item) => item.itemId === sectionItemChange.itemId) as IMenuSectionItemGroup;
        let itemGroupRequest = MenuBuilderService.convertIMenuSectionItemGroupToIMenuItemGroupRequest(itemGroup);
        if (sectionItemChange.originalDisplayName !== sectionItemChange.displayName) {
          // update the name
          itemGroupRequest.displayName = sectionItemChange.displayName;
        }

        // updates sort order if not shared menu
        if (sectionItemChange.modified && !itemGroup.parentTemplateId) {
          const sortedGroupItems: IMenuSectionItem[] = [];
          for (let index = 0; index < itemGroupRequest.items.length; index++) {
            sortedGroupItems.push({
              ...itemGroup.items[index],
              sortOrder: index,
            });
          }
          itemGroupRequest.items = sortedGroupItems;
        }

        return {
          process: (): Promise<void | ITemplateMenuItemGroup> =>
            isSharedMenu(companyId, locationId)
              ? updateItemGroup(companyId, mapIMenuItemGroupRequestToITemplateMenuItemGroupRequest(itemGroupRequest))
              : MenuBuilderService.updateItemGroup(companyId, locationId, itemGroupRequest),
          storeReturnValue: false,
          failureMessage: sectionItemChange.originalDisplayName,
        } as Execution;
      });
    return {
      isCritical: false,
      failureMessage: 'We were unable to save changes on the following item groups:',
      includeIndividualMessages: true,
      executions: menuItemGroupUpdateExecutions,
    };
  };
}
