import { PaginationResponse } from '@ready/dashboardv2api.contracts';
import { IMenuGroupActivationRequest, IMenuGroupAssignments } from '@ready/menu.core';
import { IPagedResponse } from '@ready/table.core';
import config from 'config';
import executeApiAsync from 'services/utils/executeApiAsync';
import toPaginationResponse from 'services/utils/toPaginationResponse';
import { objectToQueryParamsString } from 'utils/urlUtils/objectToQueryParamsString';

export const getMenuGroupAssignments = async (
  companyId: string,
  locationSearchTerm?: string,
  pageNumber: number = 1,
  pageSize: number = 50
): Promise<PaginationResponse<IMenuGroupAssignments>> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menuGroups/locations`;
  const queryString = objectToQueryParamsString({
    pageNumber: pageNumber.toString(),
    pageSize: pageSize.toString(),
    lq: locationSearchTerm,
  });

  const result = await executeApiAsync<IPagedResponse<IMenuGroupAssignments>>(url + '?' + queryString, {
    method: 'GET',
  });

  return toPaginationResponse({ results: result.results, totalCount: result.totalCount }, pageNumber, pageSize);
};

export const saveActivations = async (
  companyId: string,
  guestActivations: IMenuGroupActivationRequest[],
  serverFlowActivations: IMenuGroupActivationRequest[]
): Promise<void> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menuGroups/activations`;

  await executeApiAsync<void>(url, {
    body: JSON.stringify({
      guestMenuGroups: guestActivations,
      serverFlowMenuGroups: serverFlowActivations,
    }),
    method: 'PUT',
  });
};
