import React from 'react';
import classNames from 'classnames';

export interface IconButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children: JSX.Element[] | JSX.Element;
  id?: string;
  disabled?: boolean;
  additionalClassName?: string;
  loading?: boolean;
  transparent?: boolean;
}

const IconButton = (props: IconButtonProps) => {
  const {
    onClick,
    children,
    id,
    disabled = false,
    additionalClassName = '',
    loading = false,
    transparent = false,
  } = props;

  const className = classNames(['icon-button', { 'icon-button-frame': !transparent }, additionalClassName]);

  return (
    <button type='button' className={className} onClick={onClick} disabled={disabled || loading} id={id}>
      {children}
    </button>
  );
};

export default IconButton;
