import React from 'react';
import { ITag } from '@ready/menu.core';
import InfoCard from '../../components/InfoCard/InfoCard';
import IconButton from '../../components/IconButton/IconButton';
import TextIcon, { Icon } from '../../components/Icon/TextIcon';
import Tag from './Tag';
import styles from './TagItemLine.module.scss';

interface TTagItemLineProps {
  item: ITag;
  onEditClick: (tag: ITag) => void;
  canEdit: boolean;
  onDeleteClick: (tag: ITag) => void;
}

const TagItemLine = (props: TTagItemLineProps): JSX.Element => {
  const { item, onEditClick, onDeleteClick, canEdit } = props;

  return (
    <InfoCard additionalStyles={'removeMinHight'}>
      <div className={styles.contentBox}>
        <Tag item={item} />
        <div className={styles.tagName}>{item.name}</div>
      </div>
      <div>
        {canEdit && (
          <>
            <IconButton onClick={() => onEditClick(item)}>
              <TextIcon icon={Icon.Pencil} />
            </IconButton>
            <IconButton onClick={() => onDeleteClick(item)}>
              <TextIcon icon={Icon.TrashCan} />
            </IconButton>
          </>
        )}
      </div>
    </InfoCard>
  );
};

export default TagItemLine;
