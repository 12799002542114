import { DateFilterRestrictable } from 'components/DateFilter/DateFilter';
import { DatePickerValue } from 'components/DatePicker/DatePicker';
import SearchSelectFilter, { Option } from 'components/SearchSelectFilter/SearchSelectFilter';
import React from 'react';
import { maxDaysToChoose } from 'reports/promoCodeSummary/promoCodeSummarySettings';
import styles from './ToolBar.module.scss';

interface ToolBarProps {
  locations: Array<{ name: string; _id: string }>;
  initDateFilter: DatePickerValue;
  initialCountry: 'CA' | 'US'
}

export const ToolBar = ({ locations, initDateFilter, initialCountry }: ToolBarProps) => {
  const locationOptions: Option[] = locations?.map(({ name, _id }) => ({ value: _id, label: name })) || [];

  return (
    <div className={styles.toolBar}>
      <div>
        <h4>Date Range *</h4>
        <DateFilterRestrictable
          initialValue={initDateFilter}
          maxDays={maxDaysToChoose}
        />
      </div>
      <div className={styles.filterWidthSmall}>
        <h4>Country *</h4>
        <SearchSelectFilter placeholder='Select a country' options={[{ label: "CA", value: "CA" }, { label: "US", value: "US" }]} defaultValue={initialCountry} name='country' />
      </div>
      <div>
        <h4>Location</h4>
        <SearchSelectFilter placeholder='Select a location' options={locationOptions} name='location' isSearchable isClearable />
      </div>
    </div>
  );
};
