import { IPagination, ILocationPerformanceReportListItem } from '@ready/dashboardv2api.contracts';
import { IPagedResponse } from '@ready/table.core';
import { ILocation } from '../../../services/types/ILocation.type';

export interface ILoading {
  list?: boolean;
  filters?: boolean;
  permittedLocations?: boolean;
  export?: boolean;
}

export interface IErrors {
  list?: string;
  filters?: string;
  permittedLocations?: string;
}

export interface ILocationPerformance {
  rowKey: string;
  columns: Array<string>;
  headerColumns: Record<string, string>;
  items: Array<ILocationPerformanceReportListItem>;
  pagination: IPagination;
  loading: ILoading;
  errors: IErrors;
  permittedLocations: IPagedResponse<ILocation>;
}

export const initialLocationPerformanceState: ILocationPerformance = {
  rowKey: 'locationId',
  items: [],
  permittedLocations: {
    totalCount: 0,
    results: [],
  },
  columns: [
    'locationName',
    'scansCount',
    'conversionRatePercent',
    'readyChecksCount',
    'readyChecksSum',
    'readyPaymentsSum',
    'readyCheckAvg',
    'readyTableTurnAvg',
    'preTaxTipAvgPercent',
    // 'postTaxTipAvgPercent',
    'ratingsCount',
    'ratingAvg',
    'surveyRatePercent',
  ],
  headerColumns: {
    locationName: 'location',
    scansCount: '# scans',
    conversionRatePercent: 'conversion rate ',
    readyChecksCount: '# ready checks',
    readyChecksSum: 'total $ ready check',
    readyPaymentsSum: 'total $ ready payments',
    readyCheckAvg: 'avg ready check $',
    readyTableTurnAvg: 'avg ready table turns',
    preTaxTipAvgPercent: 'avg ready tip %',
    ratingAvg: 'avg star rating',
    ratingsCount: '# ratings',
    surveyRatePercent: 'survey rate %',
  },
  pagination: {
    start: 0,
    length: 0,
    limit: 0,
    total: 0,
  },
  loading: {},
  errors: {},
};
