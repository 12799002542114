import { get, find } from 'lodash';

enum Key {
  ArrowLeft = 'ArrowLeft',
  ArrowUp = 'ArrowUp',
  ArrowRight = 'ArrowRight',
  ArrowDown = 'ArrowDown',
}

enum KeyCode {
  ArrowLeft = 37,
  ArrowUp = 38,
  ArrowRight = 39,
  ArrowDown = 40,
}

// Used to map a keyboardEvent.keyCode to a keyboardEvent.key
const arrowKeyCodes = [
  { key: KeyCode.ArrowLeft, keyValue: Key.ArrowLeft },
  { key: KeyCode.ArrowUp, keyValue: Key.ArrowUp },
  { key: KeyCode.ArrowRight, keyValue: Key.ArrowRight },
  { key: KeyCode.ArrowDown, keyValue: Key.ArrowDown },
];

const arrowKeys = [
  { key: Key.ArrowLeft, keyValue: Key.ArrowLeft },
  { key: Key.ArrowUp, keyValue: Key.ArrowUp },
  { key: Key.ArrowRight, keyValue: Key.ArrowRight },
  { key: Key.ArrowDown, keyValue: Key.ArrowDown },
];

interface OnArrowKeyCallback {
  (event: React.KeyboardEvent): void;
}

interface OnArrowKeyEvents {
  onArrowKeyLeft: OnArrowKeyCallback;
  onArrowKeyUp: OnArrowKeyCallback;
  onArrowKeyRight: OnArrowKeyCallback;
  onArrowKeyDown: OnArrowKeyCallback;
}

interface OnArrowKeyEvent {
  (key: Key, event: React.KeyboardEvent): void;
}

const isArrowKeyEvents = (onArrowKeyEvent: OnArrowKeyEvent | OnArrowKeyEvents): onArrowKeyEvent is OnArrowKeyEvents =>
  (onArrowKeyEvent as OnArrowKeyEvents).onArrowKeyDown !== undefined;

/**
 * Use in place of keyboard event handlers when you want to respond to arrow key events with callbacks.
 * @param event A React keyboard event
 * @param onArrowKeyEvent A callback that returns the arrow key pressed, or a set of four callbacks for each arrow key press
 * @param preventDefault Calls event.preventDefault() after executing onArrowKeyEvent callback(s)
 */
const handleArrowKeyEvent = (
  event: React.KeyboardEvent,
  onArrowKeyEvent: OnArrowKeyEvent | OnArrowKeyEvents,
  preventDefault: boolean = false
) => {
  const eventKey = event && event.key;
  const eventKeyCode = event && event.keyCode;

  let key: Key | undefined;

  // Check 'keyboardEvent.key' first. If not supported or detected, fall back
  // to 'keyboardEvent.keyCode' which is now deprecated, but was supported by
  // all browsers before deprecation.
  if (eventKey) {
    key = get(find(arrowKeys, { key: eventKey }), 'keyValue');
  } else if (eventKeyCode) {
    key = get(find(arrowKeyCodes, { key: eventKeyCode }), 'keyValue');
  }

  if (key) {
    if (isArrowKeyEvents(onArrowKeyEvent)) {
      if (key === Key.ArrowLeft) {
        onArrowKeyEvent.onArrowKeyLeft(event);
      } else if (key === Key.ArrowUp) {
        onArrowKeyEvent.onArrowKeyUp(event);
      } else if (key === Key.ArrowRight) {
        onArrowKeyEvent.onArrowKeyRight(event);
      } else if (key === Key.ArrowDown) {
        onArrowKeyEvent.onArrowKeyDown(event);
      }
    } else {
      onArrowKeyEvent(key, event);
    }

    preventDefault && event.preventDefault();
  }
};

export default handleArrowKeyEvent;
export { Key };
export type { OnArrowKeyEvent, OnArrowKeyEvents };
