import { useEffect, useState } from 'react';
import { AssetService } from 'services/assetService';

/**
 * Fetch a single image from the asset service.
 * @param fileKey ID of image to fetch
 * @returns Image URL string
 */
const useAssetUrl = (fileKey?: string) => {
  const [imageUrl, setImageUrl] = useState<string | undefined>();

  useEffect(() => {
    let isMounted = true;
    const fetchImage = async () => {
      const url = await AssetService.getAssetUrl({ fileKey });
      if (isMounted) setImageUrl(url);
    };

    if (fileKey) {
      fetchImage();
    }

    return () => {
      isMounted = false;
    };
  }, [fileKey]);

  return imageUrl;
};

export { useAssetUrl };
