import { ILocationBilling } from '@ready/dashboardv2api.contracts';
import { IBillingTab, billingTab } from './LocationBillingState';
import LocationBillingService from '../services/LocationBillingService';
import { toastErrorState, set200Toast, pageErrorState } from '../../redux/actions/uiActions/responseStateActions';
import { setFormIsDirty } from '../../redux/actions/uiActions/formStateActions';

export const LOCATION_BILLING_ACTIONS = {
  SET_BILLING_TAB: 'SET_BILLING_TAB',
  SET_BILLING_CACHE: 'SET_BILLING_CACHE',
  UPDATE_BILLING_ENABLED: 'UPDATE_BILLING_ENABLED',
  UPDATE_OPT_IN_COMPANY_BILLING: 'UPDATE_OPT_IN_COMPANY_BILLING',
  UPDATE_PER_TRANSACTION_FEES_ORDER_TRANSACTIONS: 'UPDATE_PER_TRANSACTION_FEES_ORDER_TRANSACTIONS',
  UPDATE_PER_TRANSACTION_FEES_ORDER_TRANSACTIONS_PRODUCT_KEY:
    'UPDATE_PER_TRANSACTION_FEES_ORDER_TRANSACTIONS_PRODUCT_KEY',
  UPDATE_PER_TRANSACTION_FEES_PAY_TRANSACTIONS: 'UPDATE_PER_TRANSACTION_FEES_PAY_TRANSACTIONS',
  UPDATE_PER_TRANSACTION_FEES_PAY_TRANSACTIONS_PRODUCT_KEY: 'UPDATE_PER_TRANSACTION_FEES_PAY_TRANSACTIONS_PRODUCT_KEY',
  UPDATE_BPS_FEES_ORDER_TRANSACTIONS: 'UPDATE_BPS_FEES_ORDER_TRANSACTIONS',
  UPDATE_BPS_FEES_ORDER_TRANSACTIONS_PRODUCT_KEY: 'UPDATE_BPS_FEES_ORDER_TRANSACTIONS_PRODUCT_KEY',
  UPDATE_BPS_FEES_PAY_TRANSACTIONS: 'UPDATE_BPS_FEES_PAY_TRANSACTIONS',
  UPDATE_BPS_FEES_PAY_TRANSACTIONS_PRODUCT_KEY: 'UPDATE_BPS_FEES_PAY_TRANSACTIONS_PRODUCT_KEY',
  UPDATE_MONTHLY_SAAS_FEES_RESTAURANT: 'UPDATE_MONTHLY_SAAS_FEES_RESTAURANT',
  UPDATE_MONTHLY_SAAS_FEES_RESTAURANT_PRODUCT_KEY: 'UPDATE_MONTHLY_SAAS_FEES_RESTAURANT_PRODUCT_KEY',
  UPDATE_MONTHLY_SAAS_FEES_SINGLE_MODULE: 'UPDATE_MONTHLY_SAAS_FEES_SINGLE_MODULE',
  UPDATE_MONTHLY_SAAS_FEES_SINGLE_MODULE_PRODUCT_KEY: 'UPDATE_MONTHLY_SAAS_FEES_SINGLE_MODULE_PRODUCT_KEY',
  UPDATE_MONTHLY_SAAS_FEES_MULTI_MODULE: 'UPDATE_MONTHLY_SAAS_FEES_MULTI_MODULE',
  UPDATE_MONTHLY_SAAS_FEES_MULTI_MODULE_PRODUCT_KEY: 'UPDATE_MONTHLY_SAAS_FEES_MULTI_MODULE_PRODUCT_KEY',
  UPDATE_MONTHLY_SAAS_FEES_VIRTUAL_KIOSK: 'UPDATE_MONTHLY_SAAS_FEES_VIRTUAL_KIOSK',
  UPDATE_MONTHLY_SAAS_FEES_VIRTUAL_KIOSK_PRODUCT_KEY: 'UPDATE_MONTHLY_SAAS_FEES_VIRTUAL_KIOSK_PRODUCT_KEY',
  UPDATE_MONTHLY_SAAS_FEES_VIRTUAL_DRIVE_THROUGH: 'UPDATE_MONTHLY_SAAS_FEES_VIRTUAL_DRIVE_THROUGH',
  UPDATE_MONTHLY_SAAS_FEES_VIRTUAL_DRIVE_THROUGH_PRODUCT_KEY:
    'UPDATE_MONTHLY_SAAS_FEES_VIRTUAL_DRIVE_THROUGH_PRODUCT_KEY',
  UPDATE_MONTHLY_SAAS_FEES_ORDER_AHEAD: 'UPDATE_MONTHLY_SAAS_FEES_VIRTUAL_ORDER_AHEAD',
  UPDATE_MONTHLY_SAAS_FEES_ORDER_AHEAD_PRODUCT_KEY: 'UPDATE_MONTHLY_SAAS_FEES_VIRTUAL_ORDER_AHEAD_PRODUCT_KEY',
  UPDATE_MONTHLY_SAAS_FEES_PAY_AT_TABLE: 'UPDATE_MONTHLY_SAAS_FEES_VIRTUAL_PAY_AT_TABLE',
  UPDATE_MONTHLY_SAAS_FEES_PAY_AT_TABLE_PRODUCT_KEY: 'UPDATE_MONTHLY_SAAS_FEES_VIRTUAL_PAY_AT_TABLE_PRODUCT_KEY',
  UPDATE_MONTHLY_SAAS_FEES_ORDER_TO_TABLE: 'UPDATE_MONTHLY_SAAS_FEES_VIRTUAL_ORDER_TO_TABLE',
  UPDATE_MONTHLY_SAAS_FEES_ORDER_TO_TABLE_PRODUCT_KEY: 'UPDATE_MONTHLY_SAAS_FEES_VIRTUAL_ORDER_TO_TABLE_PRODUCT_KEY',
  UPDATE_RECURRING_PRODUCTS_READY_WAITLIST: 'UPDATE_RECURRING_PRODUCTS_READY_WAITLIST',
  UPDATE_RECURRING_PRODUCTS_READY_DIGITAL_SIGNAGE: 'UPDATE_RECURRING_PRODUCTS_DIGITAL_SIGNAGE',
  UPDATE_RECURRING_PRODUCTS_READY_PILOT_PROGRAM: 'UPDATE_RECURRING_PRODUCTS_READY_PILOT_PROGRAM',
  SET_PER_TRANSACTION_FEES_ORDER_TRANSACTIONS_PRODUCT_KEY_ERROR:
    'SET_PER_TRANSACTION_FEES_ORDER_TRANSACTIONS_PRODUCT_KEY_ERROR',
  SET_PER_TRANSACTION_FEES_PAY_TRANSACTIONS_PRODUCT_KEY_ERROR:
    'SET_PER_TRANSACTION_FEES_PAY_TRANSACTIONS_PRODUCT_KEY_ERROR',
  SET_BPS_FEES_ORDER_TRANSACTIONS_PRODUCT_KEY_ERROR: 'SET_BPS_FEES_ORDER_TRANSACTIONS_PRODUCT_KEY_ERROR',
  SET_BPS_FEES_PAY_TRANSACTIONS_PRODUCT_KEY_ERROR: 'SET_BPS_FEES_PAY_TRANSACTIONS_PRODUCT_KEY_ERROR',
  SET_MONTHLY_SAAS_FEES_RESTAURANT_PRODUCT_KEY_ERROR: 'SET_MONTHLY_SAAS_FEES_RESTAURANT_PRODUCT_KEY_ERROR',
  SET_MONTHLY_SAAS_FEES_SINGLE_MODULE_PRODUCT_KEY_ERROR: 'SET_MONTHLY_SAAS_FEES_SINGLE_MODULE_PRODUCT_KEY_ERROR',
  SET_MONTHLY_SAAS_FEES_MULTI_MODULE_PRODUCT_KEY_ERROR: 'SET_MONTHLY_SAAS_FEES_MULTI_MODULE_PRODUCT_KEY_ERROR',
  SET_MONTHLY_SAAS_FEES_VIRTUAL_KIOSK_PRODUCT_KEY_ERROR: 'SET_MONTHLY_SAAS_FEES_VIRTUAL_KIOSK_PRODUCT_KEY_ERROR',
  SET_MONTHLY_SAAS_FEES_VIRTUAL_DRIVE_THROUGH_PRODUCT_KEY_ERROR:
    'SET_MONTHLY_SAAS_FEES_VIRTUAL_DRIVE_THROUGH_PRODUCT_KEY_ERROR',
  SET_MONTHLY_SAAS_FEES_ORDER_AHEAD_PRODUCT_KEY_ERROR: 'SET_MONTHLY_SAAS_FEES_ORDER_AHEAD_PRODUCT_KEY_ERROR',
  SET_MONTHLY_SAAS_FEES_PAY_AT_TABLE_PRODUCT_KEY_ERROR: 'SET_MONTHLY_SAAS_FEES_PAY_AT_TABLE_PRODUCT_KEY_ERROR',
  SET_MONTHLY_SAAS_FEES_ORDER_TO_TABLE_PRODUCT_KEY_ERROR: 'SET_MONTHLY_SAAS_FEES_ORDER_TO_TABLE_PRODUCT_KEY_ERROR',
};

export const setBillingTab = (billing: ILocationBilling) => async (dispatch: any) => {
  dispatch({
    type: LOCATION_BILLING_ACTIONS.SET_BILLING_TAB,
    payload: billing,
  });
};

export const setBillingCache = (billing: ILocationBilling) => async (dispatch: any) => {
  dispatch({
    type: LOCATION_BILLING_ACTIONS.SET_BILLING_CACHE,
    payload: billing,
  });
};

const setBilling = (billing: ILocationBilling) => async (dispatch: any) => {
  dispatch(setBillingTab(billing));
  dispatch(setBillingCache(billing));
};

export const saveBilling = (locationId: string, billing: ILocationBilling) => async (dispatch: any) => {
  try {
    const updatedBilling = await LocationBillingService.updateLocationBilling(locationId, billing);

    dispatch(setBilling(updatedBilling));
    dispatch(setFormIsDirty(false));
    dispatch(set200Toast());
  } catch (err) {
    dispatch(toastErrorState(err.status, err.message));
  }
};

export const loadBilling = (id: string) => async (dispatch: any) => {
  try {
    const billing = await LocationBillingService.getLocationBillingByLocationId(id);
    dispatch(setBilling(billing));
  } catch (err) {
    dispatch(setBilling(billingTab));
    dispatch(pageErrorState(err.status, err.message));
  }
};

export const resetBilling = (billing: ILocationBilling) => async (dispatch: any) => {
  dispatch(setBilling(billing));
  dispatch(setFormIsDirty(false));
};

const isValidBillingSetting = (setting: boolean, input: string): boolean => {
  return !setting || (!!input && input.trim().length > 0);
};

export const validateBillingTab =
  (billingTab: IBillingTab) =>
  (dispatch: any): boolean => {
    const missingProductKeyError = 'Product key is required.';

    const isPerTransactionFeeOrderTransactionsProductKeyEmpty = !isValidBillingSetting(
      billingTab.transactionFee.orderTransactionsEnabled,
      billingTab.transactionFee.orderTransactions
    );
    dispatch({
      type: LOCATION_BILLING_ACTIONS.SET_PER_TRANSACTION_FEES_ORDER_TRANSACTIONS_PRODUCT_KEY_ERROR,
      payload: {
        hasError: isPerTransactionFeeOrderTransactionsProductKeyEmpty,
        error: missingProductKeyError,
      },
    });

    const isPerTransactionFeePayTransactionsProductKeyEmpty = !isValidBillingSetting(
      billingTab.transactionFee.payTransactionsEnabled,
      billingTab.transactionFee.payTransactions
    );
    dispatch({
      type: LOCATION_BILLING_ACTIONS.SET_PER_TRANSACTION_FEES_PAY_TRANSACTIONS_PRODUCT_KEY_ERROR,
      payload: {
        hasError: isPerTransactionFeePayTransactionsProductKeyEmpty,
        error: missingProductKeyError,
      },
    });

    const isBpsFeeOrderTransactionsProductKeyEmpty = !isValidBillingSetting(
      billingTab.BPSFee.orderTransactionBPSFeesEnabled,
      billingTab.BPSFee.orderTransactionsBPSFees
    );
    dispatch({
      type: LOCATION_BILLING_ACTIONS.SET_BPS_FEES_ORDER_TRANSACTIONS_PRODUCT_KEY_ERROR,
      payload: {
        hasError: isBpsFeeOrderTransactionsProductKeyEmpty,
        error: missingProductKeyError,
      },
    });

    const isBpsFeePayTransactionsProductKeyEmpty = !isValidBillingSetting(
      billingTab.BPSFee.payTransactionBPSFeesEnabled,
      billingTab.BPSFee.payTransactionsBPSFees
    );
    dispatch({
      type: LOCATION_BILLING_ACTIONS.SET_BPS_FEES_PAY_TRANSACTIONS_PRODUCT_KEY_ERROR,
      payload: {
        hasError: isBpsFeePayTransactionsProductKeyEmpty,
        error: missingProductKeyError,
      },
    });

    const isMonthlySaasFeesRestaurantProductKeyEmpty = !isValidBillingSetting(
      billingTab.monthlyFees.restaurantMonthlyFeeEnabled,
      billingTab.monthlyFees.restaurantMonthlyFee
    );
    dispatch({
      type: LOCATION_BILLING_ACTIONS.SET_MONTHLY_SAAS_FEES_RESTAURANT_PRODUCT_KEY_ERROR,
      payload: {
        hasError: isMonthlySaasFeesRestaurantProductKeyEmpty,
        error: missingProductKeyError,
      },
    });

    const isMonthlySaasFeesSingleModuleProductKeyEmpty = !isValidBillingSetting(
      billingTab.monthlyFees.singleModuleFeeEnabled,
      billingTab.monthlyFees.singleModuleFee
    );
    dispatch({
      type: LOCATION_BILLING_ACTIONS.SET_MONTHLY_SAAS_FEES_SINGLE_MODULE_PRODUCT_KEY_ERROR,
      payload: {
        hasError: isMonthlySaasFeesSingleModuleProductKeyEmpty,
        error: missingProductKeyError,
      },
    });

    const isMonthlySaasFeesMultiModuleProductKeyEmpty = !isValidBillingSetting(
      billingTab.monthlyFees.multiModuleFeeEnabled,
      billingTab.monthlyFees.multiModuleFee
    );
    dispatch({
      type: LOCATION_BILLING_ACTIONS.SET_MONTHLY_SAAS_FEES_MULTI_MODULE_PRODUCT_KEY_ERROR,
      payload: {
        hasError: isMonthlySaasFeesMultiModuleProductKeyEmpty,
        error: missingProductKeyError,
      },
    });

    const isMonthlySaasFeesVirtualKioskProductKeyEmpty = !isValidBillingSetting(
      billingTab.monthlyFees.virutalKioskEnabled,
      billingTab.monthlyFees.virutalKiosk
    );
    dispatch({
      type: LOCATION_BILLING_ACTIONS.SET_MONTHLY_SAAS_FEES_VIRTUAL_KIOSK_PRODUCT_KEY_ERROR,
      payload: {
        hasError: isMonthlySaasFeesVirtualKioskProductKeyEmpty,
        error: missingProductKeyError,
      },
    });

    const isMonthlySaasFeesVirtualDriveThroughProductKeyEmpty = !isValidBillingSetting(
      billingTab.monthlyFees.virtualDriveThroughEnabled,
      billingTab.monthlyFees.virtualDriveThrough
    );
    dispatch({
      type: LOCATION_BILLING_ACTIONS.SET_MONTHLY_SAAS_FEES_VIRTUAL_DRIVE_THROUGH_PRODUCT_KEY_ERROR,
      payload: {
        hasError: isMonthlySaasFeesVirtualDriveThroughProductKeyEmpty,
        error: missingProductKeyError,
      },
    });

    const isMonthlySaasFeesOrderAheadProductKeyEmpty = !isValidBillingSetting(
      billingTab.monthlyFees.orderAheadEnabled,
      billingTab.monthlyFees.orderAhead
    );
    dispatch({
      type: LOCATION_BILLING_ACTIONS.SET_MONTHLY_SAAS_FEES_ORDER_AHEAD_PRODUCT_KEY_ERROR,
      payload: {
        hasError: isMonthlySaasFeesOrderAheadProductKeyEmpty,
        error: missingProductKeyError,
      },
    });

    const isMonthlySaasFeesPayAtTableProductKeyEmpty = !isValidBillingSetting(
      billingTab.monthlyFees.payAtTableEnabled,
      billingTab.monthlyFees.payAtTable
    );
    dispatch({
      type: LOCATION_BILLING_ACTIONS.SET_MONTHLY_SAAS_FEES_PAY_AT_TABLE_PRODUCT_KEY_ERROR,
      payload: {
        hasError: isMonthlySaasFeesPayAtTableProductKeyEmpty,
        error: missingProductKeyError,
      },
    });

    const isMonthlySaasFeesOrderToTableProductKeyEmpty = !isValidBillingSetting(
      billingTab.monthlyFees.orderToTableEnabled,
      billingTab.monthlyFees.orderToTable
    );
    dispatch({
      type: LOCATION_BILLING_ACTIONS.SET_MONTHLY_SAAS_FEES_ORDER_TO_TABLE_PRODUCT_KEY_ERROR,
      payload: {
        hasError: isMonthlySaasFeesOrderToTableProductKeyEmpty,
        error: missingProductKeyError,
      },
    });

    return (
      isPerTransactionFeeOrderTransactionsProductKeyEmpty ||
      isPerTransactionFeePayTransactionsProductKeyEmpty ||
      isBpsFeeOrderTransactionsProductKeyEmpty ||
      isBpsFeePayTransactionsProductKeyEmpty ||
      isMonthlySaasFeesRestaurantProductKeyEmpty ||
      isMonthlySaasFeesSingleModuleProductKeyEmpty ||
      isMonthlySaasFeesMultiModuleProductKeyEmpty ||
      isMonthlySaasFeesVirtualKioskProductKeyEmpty ||
      isMonthlySaasFeesVirtualDriveThroughProductKeyEmpty ||
      isMonthlySaasFeesOrderAheadProductKeyEmpty ||
      isMonthlySaasFeesPayAtTableProductKeyEmpty ||
      isMonthlySaasFeesOrderToTableProductKeyEmpty
    );
  };
