import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TablesTab } from '../../../enums/tables.enum';
import { useParams } from 'react-router-dom';
import {
  getTableConfigurationList,
  updateTableConfigurationListParameters,
  updateTableConfigurationTargetCode,
  saveTableConfigurations,
  resetUnsavedChanges,
  syncTableConfigurations,
  loadSelectedLocation,
  updateTableConfigurationPosOrderType,
  updateTableConfigurationOrderExperienceType,
  updateTableConfiguration,
  deleteTableConfiguration,
  clearValidationError,
} from '../../redux/actions/tablesActions';
import { getRevenueCenters } from '../../redux/actions/revenueCentersActions';
import { getPosOrderTypes } from '../../redux/actions/posOrderTypesActions';
import { selectTableConfigurationListState } from '../../redux/selectors/TablesListSelector';
import { selectRevenueCentersState } from '../../redux/selectors/RevenueCentersSelector';
import { selectPosOrderTypesState } from '../../redux/selectors/PosOrderTypesSelector';
// hooks
import { useSearchParams } from '../../../hooks/useSearchParams';
// components
import TablesLayoutContent from './TablesLayoutContent';
import {
  CompanyTable,
  CompanyTableHeader,
  CompanyTableBody,
  CompanyTableRow,
  CompanyTableCell,
  CompanyTableRowsSkeleton,
} from '../../components/Table';
import TableLayoutContent from '../../components/TableLayoutContent';
import TablesActionHeader from '../../components/TablesActionHeader/TablesActionHeader';
import EditableCell from '../../components/Table/EditableCell';
import FormActionBar from '../../../components/FormActionBar/FormActionBar';
import { TablesToolbarComponent } from './TablesToolbarComponent';
import { ContextParams } from '../../../types/ContextParams.interface';
import styles from './TablesDetailsPage.module.scss';
import { LIST_OPTIONS } from '../../../utils/selectListUtils/listOptions';
import { getSelectedOption } from '../../../utils/selectListUtils/listUtil';
import EditableSelectCell from '../../components/Table/EditableSelectCell';
import EditableSelectIconCell from '../../components/Table/EditableSelectIconCell';
import { useResponsiveBreakpoint } from '../../../hooks';
import { Option } from '../../../components/SelectFilter/SelectFilter';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';
import IconButton from '../../../components/IconButton/IconButton';
import { ITableConfiguration } from '@ready/table.core';
import { OrderExperienceType, PosSystemType } from '@ready/dashboardv2api.contracts';
import OverflowButton from '../../../components/OverflowButton/OverflowButton';
import EditTableModal from './EditTableModal';
import { DeleteConfirmationModal } from '../../../components/Modal';
import Button from '../../../components/Button/Button';
import { setFormIsDirty } from 'redux/actions/uiActions/formStateActions';

const TablesDetailsPage = () => {
  const {
    location,
    tableConfigurations: { pagination, loading, syncLoading, syncComplete, disabled, results },
    changed,
    unsavedMappedTargetCodes,
    unsavedMappedPosOrderTypes,
    unsavedMappedOrderExperienceTypes,
    error,
  } = useSelector(selectTableConfigurationListState);
  const revenueCenters = useSelector(selectRevenueCentersState);
  const posOrderTypes = useSelector(selectPosOrderTypesState);
  const dispatch = useDispatch();
  const { query, page } = useSearchParams();
  const { contextId, locationId } = useParams<ContextParams>();
  const { isDesktop, isTablet } = useResponsiveBreakpoint();
  const emptyResult = !loading && !results.length;
  const { isMobile } = useResponsiveBreakpoint();
  const emptyTableConfig = useMemo(() => {
    return {
      id: '',
      orderExperienceType: OrderExperienceType.DineIn,
      tableName: '',
      targetCode: '',
      revenueCenter: '',
      isCatchAll: false,
    };
  }, []);

  const squarePOS = location?.posSystemType === PosSystemType.square;
  const [tableModalVisible, showTableModal] = React.useState(false);
  const [confirmDeleteVisible, showDeleteModal] = React.useState(false);
  const [editTable, setEdit] = React.useState<ITableConfiguration>(emptyTableConfig);

  const hideTableAndRevenueIds = !isDesktop;
  const showPosOrderType = !!(posOrderTypes.options && posOrderTypes.options.length);
  // table cols width - only used for loading skeletons; actual table defined in CSS grid
  const colsWidth: number[] = isTablet
    ? showPosOrderType
      ? [20, 0, 25, 0, 20, 25, 10]
      : [25, 0, 25, 0, 25, 0, 25]
    : showPosOrderType
    ? [10, 10, 15, 10, 20, 25, 10]
    : [20, 20, 20, 10, 20, 0, 10];

  const posOrderTypesOptions: Option[] = [
    {
      value: 'default',
      label: 'Location Default',
    },
    ...posOrderTypes.options.map((posOrderType) => ({
      value: posOrderType.id,
      label: posOrderType.name ?? posOrderType.id,
    })),
  ];

  const loadTableConfigurationList = React.useCallback(() => {
    dispatch(getTableConfigurationList(contextId, locationId, query, page));
    dispatch(updateTableConfigurationListParameters(query ?? '', page ?? 1));
  }, [dispatch, contextId, locationId, query, page]);

  const fetchLocation = React.useCallback(
    (locationId: string) => {
      if (!location || location.id !== locationId) {
        dispatch(loadSelectedLocation(contextId, locationId));
      }
    },
    [dispatch, contextId, location]
  );

  const clearEdit = React.useCallback(() => {
    setEdit(emptyTableConfig);
    showTableModal(false);
    showDeleteModal(false);
  }, [setEdit, showTableModal, showDeleteModal, emptyTableConfig]);

  const handleSync = () => {
    dispatch(syncTableConfigurations(contextId, locationId));
  };

  const handleTargetCodeChange = (tableId: string, targetCode: string) => {
    dispatch(updateTableConfigurationTargetCode(tableId, targetCode));
    dispatch(setFormIsDirty(true));
  };

  const handlePosOrderTypeChange = (tableId: string, posOrderType: string) => {
    dispatch(updateTableConfigurationPosOrderType(tableId, posOrderType));
  };

  const handleOrderExperienceTypeChange = (tableId: string, orderExperienceType: string) => {
    dispatch(updateTableConfigurationOrderExperienceType(tableId, orderExperienceType));
    dispatch(setFormIsDirty(true));
  };

  const handleSave = () => {
    dispatch(
      saveTableConfigurations(
        contextId,
        locationId,
        unsavedMappedTargetCodes,
        unsavedMappedPosOrderTypes,
        unsavedMappedOrderExperienceTypes,
        query,
        page
      )
    );
    dispatch(setFormIsDirty(false));
  };

  const handleCancel = () => {
    dispatch(resetUnsavedChanges());
    dispatch(setFormIsDirty(false));
  };

  const handleEdit = (data: ITableConfiguration) => {
    setEdit(data);
    showTableModal(true);
  };

  const handleTableEdit = (data: Partial<ITableConfiguration>) => {
    setEdit({ ...editTable, ...data });
    if (error) {
      dispatch(clearValidationError());
    }
  };

  const handleDelete = () => {
    dispatch(deleteTableConfiguration(contextId, locationId, editTable.id));
    clearEdit();
  };

  const handleSubmit = () => {
    dispatch(updateTableConfiguration(contextId, locationId, editTable));
  };

  const confirmDelete = (data: ITableConfiguration) => {
    showDeleteModal(true);
    setEdit(data);
  };

  React.useEffect(() => {
    if (contextId && locationId) {
      dispatch(getRevenueCenters(contextId, locationId));
      dispatch(getPosOrderTypes(contextId, locationId));
    }
  }, [dispatch, contextId, locationId]);

  React.useEffect(() => {
    fetchLocation(locationId);
  }, [fetchLocation, locationId]);

  // TODO should be removed when the modal popup confirm box will be added
  React.useEffect(() => {
    dispatch(resetUnsavedChanges());
  }, [dispatch]);

  // get table target list items
  React.useEffect(() => {
    loadTableConfigurationList();
  }, [loadTableConfigurationList]);

  React.useEffect(() => {
    if (syncComplete) {
      // refreshes content after sync is done successfully
      loadTableConfigurationList();
    }
  }, [syncComplete, loadTableConfigurationList]);

  React.useEffect(() => {
    if (tableModalVisible && disabled) {
      console.log('cleared!');
      clearEdit();
    }
  }, [tableModalVisible, disabled, clearEdit]);

  // dismiss "unsaved changes" modal when component unmounts
  React.useEffect(() => {
    return () => {
      dispatch(setFormIsDirty(false));
    };
  }, [dispatch]);

  const syncBtn = (
    <TablesActionHeader
      text=''
      loading={syncLoading}
      actionButtons={[
        {
          variant: 'secondary',
          label: 'Sync',
          overflowOrder: 1,
          icon: 'icon-dashboard-icons_refresh',
          loading: syncLoading,
          unavailable: disabled,
          onClick: handleSync,
        },
      ]}
    />
  );

  const newTableBtn = (
    <>
      <div className={styles.newTableButton}>
        <Button label='+ New Table' variant='primary' onClick={() => showTableModal(true)} />
      </div>
      <OverflowButton
        options={[
          {
            label: 'Sync',
            loading: syncLoading,
            onClick: handleSync,
          },
        ]}
      />
    </>
  );

  return (
    <>
      {squarePOS && tableModalVisible && (
        <EditTableModal
          table={editTable}
          setShowModal={showTableModal}
          handleChange={(data) => handleTableEdit(data)}
          handleSubmit={handleSubmit}
          handleCancel={clearEdit}
          error={error}
        />
      )}
      {squarePOS && confirmDeleteVisible && (
        <DeleteConfirmationModal
          setShowModal={showDeleteModal}
          item='table'
          itemName={editTable.tableName ?? ''}
          handleDelete={handleDelete}
          handleCancel={clearEdit}
        />
      )}
      <FormActionBar
        actionBarVisible={changed}
        handleFormSubmit={handleSave}
        resetForm={handleCancel}
        isFormProcessing={disabled}
      />
      <TablesLayoutContent
        companyId={contextId}
        locationId={locationId}
        locationName={location && location.id === locationId ? location.name : null}
        tab={TablesTab.Tables}
      >
        <TableLayoutContent
          title='Tables'
          toolbarContent={
            <TablesToolbarComponent
              disabledLoading={disabled}
              revenueCenters={revenueCenters}
              posOrderTypes={posOrderTypes}
              showPosOrderType={showPosOrderType}
              hideRevenueFilter={squarePOS}
            />
          }
          headerControls={squarePOS ? newTableBtn : syncBtn}
        >
          <CompanyTable withOrderType={showPosOrderType} paginationProps={pagination}>
            <CompanyTableHeader>
              <CompanyTableRow withOrderType={showPosOrderType} squarePOS={squarePOS}>
                <CompanyTableCell width={colsWidth[0]}>Table Name</CompanyTableCell>
                {!squarePOS && (
                  <CompanyTableCell width={colsWidth[1]} hidden={hideTableAndRevenueIds}>
                    TABLE ID
                  </CompanyTableCell>
                )}
                {!squarePOS && (
                  <CompanyTableCell width={colsWidth[2]}>{`${isDesktop ? 'REV' : 'REVENUE'} CENTRE`}</CompanyTableCell>
                )}
                {!squarePOS && (
                  <CompanyTableCell width={colsWidth[3]} hidden={hideTableAndRevenueIds}>
                    REV CENTRE ID
                  </CompanyTableCell>
                )}
                <CompanyTableCell width={colsWidth[4]}>TARGET CODE</CompanyTableCell>
                {!squarePOS && (
                  <CompanyTableCell width={colsWidth[5]} hidden={!showPosOrderType}>
                    POS ORDER TYPE
                  </CompanyTableCell>
                )}
                <CompanyTableCell width={colsWidth[6]} additionalStyles={styles.orderExperienceType}>
                  TABLE TYPE
                </CompanyTableCell>
              </CompanyTableRow>
            </CompanyTableHeader>

            <CompanyTableBody emptyResult={emptyResult} hasNoQuery={!query}>
              {!loading ? (
                results.map((data, index) => {
                  return (
                    <CompanyTableRow key={`${data.id}-${index}`} withOrderType={showPosOrderType} squarePOS={squarePOS}>
                      <CompanyTableCell width={colsWidth[0]}>
                        <>
                          {data.tableName ?? 'No Table Name'}
                          {!squarePOS && <div>({data.tableNumber ?? 'Not Provided'})</div>}
                        </>
                      </CompanyTableCell>

                      {!squarePOS && (
                        <CompanyTableCell width={colsWidth[1]} hidden={hideTableAndRevenueIds}>
                          {data.tableNumber?.toString() ?? ' '}
                        </CompanyTableCell>
                      )}
                      {!squarePOS && (
                        <CompanyTableCell width={colsWidth[2]}>
                          <>
                            {data.revenueCenter ?? 'Not Provided'}
                            <div>({data.revenueCenterId ?? 'Not Provided'})</div>
                          </>
                        </CompanyTableCell>
                      )}
                      {!squarePOS && (
                        <CompanyTableCell width={colsWidth[3]} hidden={hideTableAndRevenueIds}>
                          {data.revenueCenterId ?? 'Not Provided'}
                        </CompanyTableCell>
                      )}
                      <EditableCell
                        tableId={data.id}
                        unsavedMappedTargetCodes={unsavedMappedTargetCodes}
                        disabledLoading={disabled}
                        placeholder='Target Code'
                        handleSave={handleTargetCodeChange}
                        width={colsWidth[4]}
                      >
                        {data.targetCode}
                      </EditableCell>
                      {showPosOrderType && !squarePOS && (
                        <EditableSelectCell
                          label='POS Order Type'
                          options={posOrderTypesOptions}
                          tableId={data.id}
                          unsavedMappedPosOrderTypes={unsavedMappedPosOrderTypes}
                          value={
                            posOrderTypesOptions.find((option) => option.value === data.orderType) ??
                            posOrderTypesOptions[0]
                          }
                          isMobile={isMobile}
                          handleSave={handlePosOrderTypeChange}
                        />
                      )}
                      <EditableSelectIconCell
                        options={LIST_OPTIONS.orderExperienceTypes}
                        tableId={data.id}
                        unsavedMappedOrderExperienceTypes={unsavedMappedOrderExperienceTypes}
                        value={getSelectedOption(LIST_OPTIONS.orderExperienceTypes, data.orderExperienceType!, '')}
                        handleSave={handleOrderExperienceTypeChange}
                      />
                      {squarePOS && (
                        <CompanyTableCell>
                          <IconButton onClick={() => handleEdit(data)} disabled={loading || disabled}>
                            <TextIcon icon={Icon.Pencil} />
                          </IconButton>
                          <IconButton onClick={() => confirmDelete(data)} disabled={loading || disabled}>
                            <TextIcon icon={Icon.TrashCan} />
                          </IconButton>
                        </CompanyTableCell>
                      )}
                      {squarePOS && (
                        <OverflowButton
                          options={[
                            {
                              label: 'Edit Table',
                              onClick: () => handleEdit(data),
                            },
                            {
                              label: 'Delete Table',
                              onClick: () => confirmDelete(data),
                              primary: true,
                            },
                          ]}
                        />
                      )}
                    </CompanyTableRow>
                  );
                })
              ) : (
                <CompanyTableRowsSkeleton rows={50} colsWidth={colsWidth} withOrderType={showPosOrderType} />
              )}
            </CompanyTableBody>
          </CompanyTable>
        </TableLayoutContent>
      </TablesLayoutContent>
    </>
  );
};

export default TablesDetailsPage;
