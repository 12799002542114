import React from 'react';
import styles from './TransactionCheck.module.scss';
import { DollarValue } from '../../../components/Value';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';
import { ITransactionDetailItem } from '@ready/dashboardv2api.contracts';

export interface TransactionItemListProps {
  items: ITransactionDetailItem[];
}

const TransactionItemsList = (props: TransactionItemListProps) => {
  const {
    items,
  } = props;

  return (
        <div>
          <p className={styles.itemListHeader}>
              Items Paid
          </p>
          { items.length > 0 ? items.map((itemInfo: TransactionItemProps, index: number) => (
            <TransactionItem key={index} {...itemInfo} />
          )):(
            <div>
              <p className={styles.itemsNotFound}>No check details found</p>
            </div>
          )}
        </div>
  );
};

export interface TransactionItemProps {
  name: string;
  quantity: number;
  priceBeforeDiscount: number;
  priceAfterDiscount: number;
  comment?: string;
  modifiers?: any[];
}

const TransactionItem = (props: TransactionItemProps) => {
  const { name, quantity, priceBeforeDiscount, priceAfterDiscount, comment, modifiers = [] } = props;

  return (
    <div className={styles.checkItem}>
      <div className={styles.checkInfo}>
        <div className={styles.checkItemNameWrapper}>
          <p>{name}</p>
          {comment && <CheckItemComment comment={comment} />}
        </div>
        <div className={styles.checkItemQuantityWrapper}>
          {quantity > 1 && <p className={styles.checkItemQuantity}>{quantity}</p>}
        </div>
        <div className={styles.checkItemTotalsWrapper}>
          <DollarValue value={priceAfterDiscount} variant='primary' additionalStyles={styles.checkItemTotal} />
          {priceBeforeDiscount > priceAfterDiscount && (
            <DollarValue
              value={priceBeforeDiscount}
              overridden
              variant='secondary'
              additionalStyles={styles.checkItemTotal}
            />
          )}
        </div>
      </div>
      {!!modifiers.length && (
        <div className={styles.checkItemModifiers}>
          {modifiers.map((modifier: CheckItemModifierProps, index: number) => (
            <CheckItemModifier name={modifier.name} value={modifier.value} key={index} />
          ))}
        </div>
      )}
    </div>
  );
};

export interface CheckItemCommentProps {
  comment: string;
}

const CheckItemComment = (props: CheckItemCommentProps) => {
  const { comment } = props;

  return (
    <p className={styles.checkItemComment}>
      <TextIcon icon={Icon.SpeechBubble} additionalStyles={styles.checkItemCommentIcon} />
      <span>{comment}</span>
    </p>
  );
};

export interface CheckItemModifierProps {
  name: string;
  value: number;
}

const CheckItemModifier = (props: CheckItemModifierProps) => {
  const { name, value } = props;

  return (
    <p className={styles.checkModifierDescription}>
      {name}
      { value ? (<span className={styles.checkModifierValue}>
        (
        <DollarValue value={value} negativeFormat='symbol' showPositiveSymbol />)
      </span>) : null }
    </p>
  );
};

export default TransactionItemsList;
