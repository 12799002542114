import { ILocationPromoCodesConfig } from '@ready/dashboardv2api.contracts';
import { locationPromoCodes } from './LocationPromoCodesState';
import { setFormIsDirty } from '../../redux/actions/uiActions/formStateActions';
import { set200Toast, toastErrorState } from '../../redux/actions/uiActions/responseStateActions';
import LocationPromoCodeService from '../services/LocationPromoCodeService';

export const LOCATION_PROMO_CODES_ACTIONS = {
  SET_PROMO_CODES: 'SET_PROMO_CODES',
  SET_PROMO_CODES_CACHE: 'SET_PROMO_CODES_CACHE',
  UPDATE_PROMO_CODES_ENABLED: 'UPDATE_PROMO_CODES_ENABLED',
};

export const loadLocationPromoCodesConfig = (locationId: string) => async (dispatch: any) => {
  try {
    const config = await LocationPromoCodeService.loadLocationPromoCodesConfig(locationId);
    dispatch(setLocationPromoCodesConfig(config));
  } catch (err) {
    dispatch(setLocationPromoCodesConfig(locationPromoCodes.config));
    dispatch(toastErrorState(err.status, err.message));
  }
};

export const setLocationPromoCodesConfig = (config: ILocationPromoCodesConfig) => async (dispatch: any) => {
  dispatch({
    type: LOCATION_PROMO_CODES_ACTIONS.SET_PROMO_CODES,
    payload: config,
  });
  dispatch({
    type: LOCATION_PROMO_CODES_ACTIONS.SET_PROMO_CODES_CACHE,
    payload: config,
  });
};

export const resetLocationPromoCodesConfig = (config: ILocationPromoCodesConfig) => async (dispatch: any) => {
  dispatch(setLocationPromoCodesConfig(config));
  dispatch(setFormIsDirty(false));
};

export const saveLocationPromoCodesConfig =
  (locationId: string, config: ILocationPromoCodesConfig) => async (dispatch: any) => {
    try {
      const updatedPromoCodes = await LocationPromoCodeService.saveLocationPromoCodesConfig(locationId, config);

      dispatch(setLocationPromoCodesConfig(updatedPromoCodes));
      dispatch(setFormIsDirty(false));
      dispatch(set200Toast());
    } catch (err) {
      dispatch(toastErrorState(err.status, err.message));
    }
  };
