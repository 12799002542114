import { IValidationFieldValue } from '@ready/dashboardv2api.contracts';
import React from 'react';
import styles from './OnAccountPaymentOptions.module.scss';

interface Props {
  options?: IValidationFieldValue[];
}

const OnAccountPaymentOptions = ({ options = [] }: Props) => {
  return (
    <div className={styles.container}>
      {options.map((option: IValidationFieldValue, index: number) => (
        <OnAccountPaymentOption
          key={`${index}-${option.label}-${option.value}`}
          label={option.label}
          value={option.value}
        />
      ))}
    </div>
  );
};

const OnAccountPaymentOption = ({ label, value }: IValidationFieldValue) => {
  return (
    <div className={styles.onAccountPaymentOption}>
      <span className={styles.label}>{label}</span>
      <span className={styles.value}>{value || '-'}</span>
    </div>
  );
};

export { OnAccountPaymentOptions };
