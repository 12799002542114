import React from 'react';
import styles from './CompanyTablePage.module.scss';
import { useClassNames } from '../../../utils/cssUtils';

export interface CompanyTableCellProps {
  children: JSX.Element[] | JSX.Element | string;
  width?: number;
  disabled?: boolean;
  firstCell?: boolean;
  additionalStyles?: string;
  hidden?: boolean;
}

const CompanyTableCell = (props: CompanyTableCellProps) => {
  const { children, width, disabled = false, firstCell = false, additionalStyles = '', hidden = false } = props;

  let inlineFlex: React.CSSProperties | undefined;
  if (width) {
    inlineFlex = {
      flex: `${width}%`,
    };
  }

  const firstCellClassName = firstCell ? 'table__row__cell--manual-layout-first-item' : '';

  const className = useClassNames(
    [
      { disabled }, // conditional styles are defined using object properties like this
      styles.rowCell,
      firstCellClassName,
      additionalStyles,
    ],
    styles
  );

  if (hidden) {
    return null;
  }

  return (
    <div className={className} style={inlineFlex}>
      <span>{children}</span>
    </div>
  );
};

export default CompanyTableCell;
