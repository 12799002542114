import React from 'react';
import TextIcon, { Icon } from '../Icon/TextIcon';
import styles from './ErrorValue.module.scss';

export interface ErrorValueProps {
  text: string;
}

const ErrorValue = ({ text }: ErrorValueProps): JSX.Element => (
  <div className={styles.error}>
    <TextIcon icon={Icon.Alert} additionalStyles={styles.icon} />
    {text}
  </div>
);

export default ErrorValue;
