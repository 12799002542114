import React from 'react';
import { Link } from 'react-router-dom';
import { ITag } from '@ready/menu.core';
import { useDispatch, useSelector } from 'react-redux';
import { selectTagsListState } from '../../redux/TagsListSelector';
import { getTagsList, updateTagsListQuery } from '../../redux/TagsListActions';

import ListModal from '../../../components/Modal/ListModal';
import SearchInput from '../../../components/SearchInput/SearchInput';
import Tag from '../../../tagsBadges/components/Tag';
import Button from '../../../components/Button/Button';
import EmptyResult from '../../../components/EmptyResult/EmptyResult';
import LoadingSpinnerInline from '../../../components/LoadingSpinnerInline/LoadingSpinnerInline';
import styles from './AddTagsDialog.module.scss';
import { setFormIsDirty } from 'redux/actions/uiActions/formStateActions';

export interface AddTagsDialogItemProps {
  item: ITag;
  currentTags?: ITag[];
  addTag: (tag: ITag) => void;
  removeTag: (tagId: string) => void;
}

const AddTagsDialogItem = (props: AddTagsDialogItemProps) => {
  const { item, currentTags, addTag, removeTag } = props;

  const dispatch = useDispatch();
  const handleAddTag = () => {
    addTag(item);
    dispatch(setFormIsDirty(true));
  };
  const handleRemoveTag = () => {
    removeTag(item._id);
    dispatch(setFormIsDirty(true));
  };

  const tagInUse = currentTags?.some((t) => t._id === item._id) || false;

  return (
    <div className={styles.tagItem}>
      <div className={styles.tagItemDetails}>
        <Tag item={item} />
        <p>{item.name}</p>
      </div>
      <div>
        {tagInUse ? (
          <Button variant='toggle-selected' label='Remove' onClick={handleRemoveTag} />
        ) : (
          <Button variant='toggle-unselected' label='Add' onClick={handleAddTag} />
        )}
      </div>
    </div>
  );
};

export interface AddTagsDialogProps {
  setShowModal: (modal: boolean) => void;
  companyId: string;
  locationId?: string;
  currentTags?: ITag[];
  addTag: (tag: ITag) => void;
  removeTag: (tagId: string) => void;
}

const AddTagsDialog = ({ setShowModal, companyId, locationId, currentTags, addTag, removeTag }: AddTagsDialogProps) => {
  const { loading, tags, query } = useSelector(selectTagsListState);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getTagsList(companyId, locationId));
  }, [companyId, dispatch, locationId]);

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateTagsListQuery(event.target.value));
  };

  const tagsToShow = !!query ? tags.filter((t) => t.name.toLowerCase().includes(query.toLowerCase())) : tags;

  return (
    <ListModal
      setShowModal={setShowModal}
      headerLabel='Add Tags'
      showDismissButton
      searchControl={<SearchInput value={query} onChange={handleQueryChange} fullWidth placeholder='Search Tags' />}
    >
      {loading ? (
        <LoadingSpinnerInline />
      ) : (
        <>
          {tagsToShow.length > 0 ? (
            <>
              {tagsToShow.map((i: ITag) => (
                <AddTagsDialogItem
                  key={i._id}
                  item={i}
                  currentTags={currentTags}
                  addTag={addTag}
                  removeTag={removeTag}
                />
              ))}
            </>
          ) : (
            <>
              {!query ? (
                <EmptyResult title='No Tags Created'>
                  <p>
                    Head over to the{' '}
                    <Link to={`/companies/${companyId}/tagsBadges`} className={styles.tagsBadgesLink}>
                      Tags & Badges
                    </Link>{' '}
                    page to create your first tags.
                  </p>
                </EmptyResult>
              ) : (
                <p className={styles.noSearchResults}>No Results Found</p>
              )}
            </>
          )}
        </>
      )}
    </ListModal>
  );
};

export default AddTagsDialog;
