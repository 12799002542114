import React from 'react';

import styles from './ToggleButtons.module.scss';

export interface ToggleButton {
  label: string;
  selected: boolean;
  onClick: () => void;
}

export interface ToggleButtonsProps {
  buttons: ToggleButton[];
}

const ToggleButtons = (props: ToggleButtonsProps): JSX.Element => {
  const { buttons } = props;

  return (
    <div className={styles.container}>
      {buttons.map((button: ToggleButton, index: number) => (
        <button
          className={button.selected ? styles.buttonSelected : styles.button}
          onClick={button.onClick}
          key={index}
        >
          {button.label}
        </button>
      ))}
    </div>
  );
};

export default ToggleButtons;
