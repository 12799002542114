import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IUserRole, IRole } from '../../services/types/companyUsers.type';
import { ILocationToShow } from '@ready/dashboardv2api.contracts';
import { IUsersFormValidation } from '../../redux/initialStates/users/usersForm';
import { Option } from '../SelectFilter/SelectFilter';
import UserPermissionField from './UserPermissionsField';
import { FormControl } from '../Form';
import Button from '../Button/Button';
import { AppState } from '../../redux/initialStates/AppState';

export type UserPermissionsGroupInitialProps = PropsFromRedux & {
  companyId: string;
  value: IUserRole[] | undefined;
  validation?: IUsersFormValidation;
  loading?: boolean;
  readOnly?: boolean;
  onAddUserRole: () => void;
  onRemoveUserRole: (index: number) => void;
  onUpdateRole: (index: number, role: IRole) => void;
  onAddLocation: (location: ILocationToShow, index: number) => void;
  onRemoveLocation: (locationId: string, index: number) => void;
};

export interface UserPermissionsGroupProps extends UserPermissionsGroupInitialProps {}

// The change handlers are optional, and are not expected to be invoked when in
// read-only mode. They may still be provided here as a convenience, for example
// if you have a page that flips between edit and read-only mode, and selectively
// providing event handlers in each case would be inconvenient and unnecessary.
export interface UserPermissionsGroupReadOnlyProps extends UserPermissionsGroupInitialProps {
  readOnly: true;
}

const UserPermissionsGroup = (props: UserPermissionsGroupProps | UserPermissionsGroupReadOnlyProps) => {
  const {
    companyId,
    value = [],
    validation,
    loading = false,
    readOnly = false,
    onAddUserRole,
    onRemoveUserRole,
    onUpdateRole,
    onAddLocation,
    onRemoveLocation,
  } = props;

  const handleSelectRoleChange = (role: Option, index: number) => {
    onUpdateRole(index, { id: `${role.value}`, name: role.label });
  };

  return (
    <>
      {value &&
        value.map((userRole: IUserRole, i: number) => {
          return (
            <UserPermissionField
              handleRemoveUserPermission={onRemoveUserRole}
              handleSelectRoleChange={handleSelectRoleChange}
              handleAddLocationToUserPermission={onAddLocation}
              handleRemoveLocationFromUserPermission={onRemoveLocation}
              companyRoles={userRole.selector}
              companyId={companyId}
              isProcessing={false}
              index={i}
              role={userRole}
              validation={validation}
              key={`${i}-${userRole.role}`}
              loading={loading}
              readOnly={readOnly}
            />
          );
        })}
      {!readOnly && (
        <FormControl label='' additionalStyles='full-width-button add-role-button' hideFormLabel>
          <Button variant='secondary' label='+ Add Role' onClick={onAddUserRole} disabled={loading} />
        </FormControl>
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({});

const actionCreators = {};

const connector = connect(mapStateToProps, actionCreators);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UserPermissionsGroup);
