import React from 'react';
import InfoCardColumn from '../../../components/InfoCard/InfoCardColumn';
import InfoCardDataRow from '../../../components/InfoCard/InfoCardDataRow';
import TextField from '../../../components/TextField/TextField';
import styles from './ContactColumn.module.scss';
import { useClassNames } from '../../../utils/cssUtils/useClassNames';
import { IOnAccountPayment, IOrderIdentityInfo, IOrderTableInfo } from '@ready/dashboardv2api.contracts';
import { getOrderContactInformation } from '../../../utils/orderUtils';

export interface ContactColumnProps {
  customerDetails?: IOrderIdentityInfo;
  tableInfo?: IOrderTableInfo;
  width: number;
  newOrder?: boolean;
  additionalStyles?: string;
  onAccountPayment?: IOnAccountPayment;
}

const ContactColumn = ({
  customerDetails = undefined,
  tableInfo = undefined,
  width,
  newOrder,
  additionalStyles = '',
  onAccountPayment,
}: ContactColumnProps) => {
  const columnClassName = useClassNames(
    [
      {
        cardColumn: true,
      },
      additionalStyles,
    ],
    styles
  );

  if (customerDetails || (tableInfo && customerDetails)) {
    const { primaryInfo, secondaryInfo } = getOrderContactInformation(customerDetails, tableInfo);

    if (onAccountPayment?.validationResponses && onAccountPayment.validationResponses.length > 0) {
      secondaryInfo.push(...onAccountPayment.validationResponses.map((x) => x.value));
    }

    return (
      <InfoCardColumn width={width} columnClassName={columnClassName}>
        <InfoCardDataRow>
          <span className={styles.primaryField}>
            {primaryInfo} {newOrder && <span className={styles.newBadge}>NEW</span>}
          </span>
        </InfoCardDataRow>
        {secondaryInfo ? (
          <InfoCardDataRow>
            {secondaryInfo.map((additionalField, index) => {
              return (
                <span key={index} className={styles.additionalField}>
                  {additionalField || '-'}
                </span>
              );
            })}
          </InfoCardDataRow>
        ) : (
          <InfoCardDataRow>
            <TextField value={''} />
          </InfoCardDataRow>
        )}
      </InfoCardColumn>
    );
  }

  return null;
};

export default ContactColumn;
