import { IEmployee } from "@ready/employee.core";
import { Option } from '../../components/SelectFilter/SelectFilter';

export interface PosItem {
  id: string;
  name: string;
}

export const convertEmployeeToOption = (employee: IEmployee): Option => ({
  value: employee.externalId!,
  label: employee.name || `${employee.firstName} ${employee.lastName}`.trim() || employee.id,
});

export const convertPosItemToOption = (posItem: PosItem): Option => ({
  value: posItem.id,
  label: posItem.name,
});
