import { IServiceChargeSettings } from '@ready/dashboardv2api.contracts';
import produce from 'immer';
import { Action } from '../../redux/types';
import { LOCATIONS_ACTIONS } from './LocationsActions';
import { LOCATION_SETTINGS_ACTIONS } from './LocationSettingsActions';
import { IPosSettingsPanel, posSettingsPanel } from './PanelPosSettingsState';

export const posSettingsPanelReducer = (state = posSettingsPanel, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case LOCATION_SETTINGS_ACTIONS.SET_POS_SETTINGS_PANEL:
        draft = { ...draft, ...action.payload }; // dev, testing validations
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_POS_INTEGRATION:
        draft.posIntegration = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_TYPE:
        draft.optionsOmnivore.omnivorePosType = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_MINIMUM_READY_TIME:
        draft.optionsOmnivore.minimumReadyTime = action.payload > 0 ? action.payload : undefined;
        break;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_LOCATION_ID:
        draft.optionsOmnivore.omnivoreLocationId = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_SETTINGS_SUPPORT_TABLE:
        draft.optionsOmnivore.omnivorePosSettingSupportTable = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_SETTINGS_REQUIRES_COVERS:
        draft.optionsOmnivore.omnivorePosSettingsRequiresCovers = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_SETTINGS_MODIFIER_GROUP:
        draft.optionsOmnivore.omnivorePosSettingsModifierGroup = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_SETTINGS_DISABLE_USER_INFO:
        draft.optionsOmnivore.omnivorePosSettingsDisableUserInfo = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_SETTINGS_SEND_AND_COMMENT:
        draft.optionsOmnivore.omnivorePosSettingsSendAndComment = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_EMPLOYEE:
        draft.optionsOmnivore.posEmployee = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_BRINK_POS_EMPLOYEE:
        draft.optionsBrink.posEmployee = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_ORDER_TYPE:
        draft.optionsOmnivore.posOrderType = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_DEFAULT_REVENUE_CENTER:
        draft.optionsOmnivore.posRevenueCenter = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_PRICE_CHECK_TABLE:
        draft.optionsOmnivore.posPriceCheckTable = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_PRICE_CHECK_REVENUE_CENTER:
        draft.optionsOmnivore.posPriceCheckRevenueCenter = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_TENDER_TYPE:
        draft.optionsOmnivore.posTenderType = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_MASTERCARD:
        draft.optionsOmnivore.posMastercard = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_VISA:
        draft.optionsOmnivore.posVisa = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_AMEX:
        draft.optionsOmnivore.posAmex = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_DISCOVER:
        draft.optionsOmnivore.posDiscover = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_JCB:
        draft.optionsOmnivore.posJcb = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_OMNIVORE_POS_INTERAC:
        draft.optionsOmnivore.posInterac = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_BRINK_POS_MASTERCARD:
        draft.optionsBrink.posMastercard = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_BRINK_POS_VISA:
        draft.optionsBrink.posVisa = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_BRINK_POS_AMEX:
        draft.optionsBrink.posAmex = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_BRINK_POS_DISCOVER:
        draft.optionsBrink.posDiscover = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_BRINK_POS_JCB:
        draft.optionsBrink.posJcb = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_BRINK_POS_INTERAC:
        draft.optionsBrink.posInterac = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.SET_BRINK_EMPLOYEE:
        draft.posOptions.employees.selection = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.GET_BRINK_REVENUE_CENTERS_START:
        draft.posOptions.revenueCenters.loading = true;
        draft.posOptions.revenueCenters.results = [];
        break;

      case LOCATION_SETTINGS_ACTIONS.GET_BRINK_REVENUE_CENTERS_SUCCESS:
        draft.posOptions.revenueCenters.loaded = true;
        draft.posOptions.revenueCenters.loading = false;
        draft.posOptions.revenueCenters.results = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.GET_BRINK_REVENUE_CENTERS_ERROR:
        draft.posOptions.revenueCenters.loading = false;
        break;

      case LOCATION_SETTINGS_ACTIONS.GET_POS_TENDER_TYPES_START:
        draft.posOptions.tenderTypes.loading = true;
        draft.posOptions.tenderTypes.results = [];
        return draft;

      case LOCATION_SETTINGS_ACTIONS.GET_POS_TENDER_TYPES_SUCCESS:
        draft.posOptions.tenderTypes.loaded = true;
        draft.posOptions.tenderTypes.loading = false;
        draft.posOptions.tenderTypes.results = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.GET_POS_TENDER_TYPES_ERROR:
        draft.posOptions.tenderTypes.loading = false;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.GET_POS_EMPLOYEES_START:
        draft.posOptions.employees.loading = true;
        break;

      case LOCATION_SETTINGS_ACTIONS.GET_POS_EMPLOYEES_SUCCESS:
        draft.posOptions.employees.loading = false;
        draft.posOptions.employees.results = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.GET_POS_EMPLOYEES_ERROR:
        draft.posOptions.employees.loading = false;
        break;

      case LOCATION_SETTINGS_ACTIONS.RESET_BRINK_OPTIONS:
        draft.optionsBrink.posEmployee = '';
        draft.optionsBrink.posOrderType = '';
        draft.optionsBrink.posRevenueCenter = '';
        draft.optionsBrink.posTenderType = '';
        draft.optionsBrink.posMastercard = '';
        draft.optionsBrink.posVisa = '';
        draft.optionsBrink.posAmex = '';
        draft.optionsBrink.posDiscover = '';
        draft.optionsBrink.posJcb = '';
        draft.optionsBrink.posInterac = '';
        draft.posOptions.employees.selection = undefined;
        draft.posOptions.revenueCenters.loaded = false;
        draft.posOptions.revenueCenters.results = [];
        draft.posOptions.tenderTypes.loaded = false;
        break;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_BRINK_LOCATION_ID:
        draft.optionsBrink.brinkLocationId = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_BRINK_API_URL:
        draft.optionsBrink.apiUrl = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_BRINK_POS_ORDER_TYPE:
        draft.optionsBrink.posOrderType = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_BRINK_POS_REVENUE_CENTER:
        draft.optionsBrink.posRevenueCenter = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_BRINK_POS_TENDER_TYPE:
        draft.optionsBrink.posTenderType = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.SET_POS_SETTINGS_PANEL_POS_INTEGRATION_ERROR:
        draft.validation.posSettings.posIntegration = { ...action.payload };
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_POS_BYPASS2_LOCATION_ID:
        draft.optionsBypass2.locationId = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_POS_SQUARE_LOCATION_ID:
        draft.optionsSquare.squareLocationId = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_POS_SQUARE_API_KEY:
        draft.optionsSquare.squareApiKey = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_POS_SQUARE_EMPLOYEE_ID:
        draft.optionsSquare.squareOrderEmployeeId = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_POS_SQUARE_REVENUE_CENTER:
        draft.optionsSquare.squareOrderRevenueCenter = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_POS_SQUARE_ORDER_TYPE:
        draft.optionsSquare.squareOrderType = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_POS_SQUARE_TENDER_TYPE:
        draft.optionsSquare.squareOrderTenderType = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_POS_SQUARE_TABLE:
        draft.optionsSquare.squarePriceCheckTableId = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_POS_SQUARE_PRICE_CHECK_REVENUE_CENTER:
        draft.optionsSquare.squarePriceCheckRevenueCenterId = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_POS_SQUARE_TABLE_SUPPORT:
        draft.optionsSquare.squareSupportsTables = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_POS_SQUARE_REQUIRES_COVERS:
        draft.optionsSquare.squareRequiresCovers = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_POS_SQUARE_MODIFIER_GROUP_PRICE_CHECKS:
        draft.optionsSquare.squareRequiresModifierGroupPriceChecks = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_POS_SQUARE_DISABLE_USER_INFO_ON_ORDERS:
        draft.optionsSquare.squareOrderDisableUserInfo = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_POS_SQUARE_SEND_ITEM_AND_COMMENT_SEPARATELY:
        draft.optionsSquare.squareSeparateItemAndComment = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.LOAD_SQUARE_LOCATIONS_BEGIN:
        draft.loadingLocations = true;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.LOAD_SQUARE_LOCATIONS_SUCCESS:
        draft.loadingLocations = false;
        draft.locations = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.LOAD_SQUARE_LOCATIONS_ERROR:
        draft.loadingLocations = false;
        return draft;

      case LOCATIONS_ACTIONS.GET_LOCATIONS_LIST_SUCCESS:
        draft.optionsSquare = posSettingsPanel.optionsSquare;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_POS_OUT_OF_STOCK_MANAGEMENT:
        draft.ignoreInStockFromPosItem = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_SIMPHONY_POS_SETTINGS:
        draft.optionsSimphony2 = {
          ...draft.optionsSimphony2,
          ...action.payload,
        };
        return draft;

      case LOCATION_SETTINGS_ACTIONS.RESET_SIMPHONY_POS_OPTIONS:
        draft.optionsSimphony2.location =
          draft.optionsSimphony2.organization =
          draft.optionsSimphony2.posEmployee =
          draft.optionsSimphony2.posTenderType =
          draft.optionsSimphony2.posMastercard =
          draft.optionsSimphony2.posVisa =
          draft.optionsSimphony2.posAmex =
          draft.optionsSimphony2.posDiscover =
          draft.optionsSimphony2.posJcb =
            '';
          draft.optionsSimphony2.posInterac = '';
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_FORCED_SERVICE_CHARGE_STATUS:
        setServiceChargeSettings(draft, 'serviceChargeEnabled', action.payload);
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_FORCED_SERVICE_CHARGE:
        setServiceChargeSettings(draft, 'serviceChargeId', action.payload);
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_FORCED_SERVICE_CHARGE_TYPE:
        setServiceChargeSettings(draft, 'serviceChargeType', action.payload);
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_FORCED_SERVICE_CHARGE_AMOUNT:
        setServiceChargeSettings(draft, 'serviceChargeAmount', action.payload);
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_FORCED_SERVICE_CHARGE_POS_ITEM:
        setServiceChargeSettings(draft, 'serviceChargeId', action.payload);
        return draft;

      default:
        return draft;
    }
  });
};

const setServiceChargeSettings = (
  draft: IPosSettingsPanel,
  settingsKey: keyof IServiceChargeSettings,
  payload: any
) => {
  switch (draft.posIntegration) {
    case 'bypass2':
      draft.optionsBypass2[settingsKey] = payload;
      break;
    case 'omnivore':
      draft.optionsOmnivore[settingsKey] = payload;
      break;
    case 'simphony2':
      draft.optionsSimphony2[settingsKey] = payload;
  }

  return draft;
};
