import { Action } from '../../redux/types';
import { ISchedule } from '@ready/menu.core';
import { IPagination } from '@ready/dashboardv2api.contracts';
import { pageErrorState, toastErrorState } from '../../redux/actions/uiActions/responseStateActions';
import ScheduleService from '../services/ScheduleService';

export const actionTypes = {
  SCHEDULE_LIST_INIT: 'SCHEDULE_LIST_INIT',
  SCHEDULE_LIST_SET: 'SCHEDULE_LIST_SET',
  SCHEDULE_LIST_LOADING: 'SCHEDULE_LIST_LOADING',
};

export const getScheduleList = (
  companyId: string,
  locationId?: string,
  query?: string,
  page?: number,
  limit?: number,
  withPageError?: boolean
) => async (dispatch: any) => {
  dispatch(setScheduleListLoading(true));
  try {
    const { items: schedules, length, limit: responseLimit, start, total } = await ScheduleService.getScheduleList(
      companyId,
      locationId,
      query,
      page,
      limit
    );

    const pagination = {
      length,
      limit: responseLimit,
      start,
      total,
    };

    dispatch(setScheduleList(schedules, pagination));
  } catch (err) {
    if (withPageError) {
      dispatch(pageErrorState(err.status, err.message));
    } else {
      dispatch(toastErrorState(err.status, err.message));
    }
  } finally {
    dispatch(setScheduleListLoading(false));
  }
};

export const initScheduleList = (): Action => ({
  type: actionTypes.SCHEDULE_LIST_INIT,
});

const setScheduleList = (schedules: Array<ISchedule>, pagination: IPagination): Action => ({
  type: actionTypes.SCHEDULE_LIST_SET,
  payload: { schedules, pagination },
});

const setScheduleListLoading = (loading: boolean): Action => ({
  type: actionTypes.SCHEDULE_LIST_LOADING,
  payload: loading,
});
