import React from 'react';

import { DropResult } from 'react-beautiful-dnd';
import { DragItem, DropArea } from '.';

export interface DragAndDropProps {
  dropAreaClassName: string;
  dropAreaDraggingClassName?: string;
  dragItemClassName: string;
  dragItemDraggingClassName?: string;
  dragHandleClassName?: string;
  dragItems: any[];
  emptyComponent?: JSX.Element;
  draggingDisabled?: boolean;
  dragHandleProvided?: boolean;
  dragItemKeyExtractor: (item: any) => string;
  dragItemComponentBuilder: (item: any, dragHandle?: JSX.Element, isDragging?: boolean) => JSX.Element;
  onDragItemDropped: (oldIndex: number, newIndex: number) => void;
}

const DragAndDrop = (props: DragAndDropProps): JSX.Element => {
  const {
    dropAreaClassName,
    dropAreaDraggingClassName = dropAreaClassName,
    dragItemClassName,
    dragItemDraggingClassName = dragItemClassName,
    dragHandleClassName,
    dragItems,
    emptyComponent,
    draggingDisabled = false,
    dragHandleProvided = false,
    dragItemKeyExtractor,
    dragItemComponentBuilder,
    onDragItemDropped,
  } = props;

  const onDragEnd = (result: DropResult) => {
    if (result.destination) {
      onDragItemDropped(result.source.index, result.destination.index);
    }
  };

  return (
    <DropArea
      draggingDisabled={draggingDisabled}
      className={dropAreaClassName}
      draggingClassName={dropAreaDraggingClassName}
      onDragEnd={onDragEnd}
    >
      {(dragItems && dragItems.length > 0) || !emptyComponent
        ? dragItems.map((item: any, index: number) => (
            <DragItem
              key={dragItemKeyExtractor(item)}
              item={item}
              itemId={dragItemKeyExtractor(item)}
              itemIndex={index}
              className={dragItemClassName}
              draggingClassName={dragItemDraggingClassName}
              dragHandleClassName={dragHandleClassName}
              draggingDisabled={draggingDisabled}
              dragHandleProvided={dragHandleProvided}
              dragItemComponentBuilder={dragItemComponentBuilder}
            />
          ))
        : emptyComponent}
    </DropArea>
  );
};

export default DragAndDrop;
