import { COMPANY_LOCATIONS_ACTIONS } from './types';
import { CompanyLocationService } from '../../../services/companyLocationsService';

export const prepareCompanyLocationsModal = (visible: boolean, permissionIndex?: number) => async (dispatch: any) => {
  dispatch({
    type: COMPANY_LOCATIONS_ACTIONS.PREPARE_COMPANY_LOCATIONS_MODAL,
    payload: { visible, permissionIndex },
  });
};

export const setLocationsQuery = (query: string) => async (dispatch: any) => {
  dispatch({
    type: COMPANY_LOCATIONS_ACTIONS.SET_COMPANY_LOCATIONS_QUERY,
    payload: query,
  });
};

export const loadLocations =
  (companyId: string, query: string = '') =>
  async (dispatch: any) => {
    dispatch({ type: COMPANY_LOCATIONS_ACTIONS.LOAD_COMPANY_LOCATIONS_BEGIN });
    try {
      const locations = await CompanyLocationService.getCompanyLocations(companyId, query);
      dispatch({
        type: COMPANY_LOCATIONS_ACTIONS.LOAD_COMPANY_LOCATIONS_SUCCESS,
        payload: locations,
      });
    } catch (err) {
      dispatch({ type: COMPANY_LOCATIONS_ACTIONS.LOAD_COMPANY_LOCATIONS_ERROR });
    }
  };

export const loadMoreLocations =
  (companyId: string, query: string = '', page: number) =>
  async (dispatch: any) => {
    dispatch({
      type: COMPANY_LOCATIONS_ACTIONS.LOAD_MORE_COMPANY_LOCATIONS_BEGIN,
    });
    try {
      const nextPage = page + 1;
      const locations = await CompanyLocationService.getCompanyLocations(companyId, query, nextPage);
      if (locations.length > 0) {
        dispatch({
          type: COMPANY_LOCATIONS_ACTIONS.LOAD_MORE_COMPANY_LOCATIONS_SUCCESS,
          payload: locations,
        });
      } else {
        dispatch({
          type: COMPANY_LOCATIONS_ACTIONS.LOAD_MORE_COMPANY_LOCATIONS_NO_RESULTS,
        });
      }
    } catch (err) {
      dispatch({
        type: COMPANY_LOCATIONS_ACTIONS.LOAD_MORE_COMPANY_LOCATIONS_ERROR,
      });
    }
  };
