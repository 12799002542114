import { IChecksReportListItem, IChecksReportWidgetsData, PaginationResponse } from '@ready/dashboardv2api.contracts';
import config from '../../../../config';
import executeApiAsync, { ResponseType } from '../../../../services/utils/executeApiAsync';
import { IPagedResponse } from '@ready/table.core';
import { ILocation } from '../../../../services/types/ILocation.type';
import { downloadFileFromBlob } from '../../../../utils/downloadFileFromBlob';
import { ReportsResourceActions, ResourceType } from '@ready/security.core';

const BASE_URL = `${config.readyDashboardApiEndpoint}/companies`;

export const getChecksList = ({
  contextId,
  query = '',
  page = 1,
  sortColumn = 'openDate',
  sortDirection = 'desc',
}: {
  contextId: string;
  query: string;
  page: number;
  sortColumn: string;
  sortDirection: string;
}): Promise<PaginationResponse<IChecksReportListItem>> => {
  const url =
    `${BASE_URL}/${contextId}/reports/checks/list?` +
    decodeURIComponent(query) +
    `&page=${page}&sortColumn=${sortColumn}&sortDirection=${sortDirection}`;

  return executeApiAsync<PaginationResponse<IChecksReportListItem>>(url);
};

export const exportChecksList = async ({
  contextId,
  query = '',
  sortColumn = 'openDate',
  sortDirection = 'desc',
}: {
  contextId: string;
  query: string;
  sortColumn: string;
  sortDirection: string;
}) => {
  const url =
    `${BASE_URL}/${contextId}/reports/checks/list/export?` +
    decodeURIComponent(query) +
    `&sortColumn=${sortColumn}&sortDirection=${sortDirection}`;

  const blob = await executeApiAsync<Blob>(url, { responseType: ResponseType.Blob });
  downloadFileFromBlob(blob, `ChecksReport.csv`);
};

export const getPermittedChecksReportLocations = (contextId: string): Promise<IPagedResponse<ILocation>> => {
  const securityParams = `permissions=${ResourceType.reports}.${ReportsResourceActions.viewChecksReport}`;
  const showHiddenLocations = false;
  const url = `${BASE_URL}/${contextId}/permittedlocations?${securityParams}&isHidden=${showHiddenLocations}`;
  return executeApiAsync<IPagedResponse<ILocation>>(url);
};

export const getCheckWidgets = ({
  contextId,
  query = '',
}: {
  contextId: string;
  query: string;
}): Promise<IChecksReportWidgetsData> => {
  const url = `${BASE_URL}/${contextId}/reports/checks/widgets-data?` + decodeURIComponent(query);

  return executeApiAsync<IChecksReportWidgetsData>(url);
};
