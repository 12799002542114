import produce from 'immer';
import { Action } from '../../../redux/types';
import { SECURITY_ROLES_LIST_TYPES } from '../../actions/securityRolesActions/types';
import { securityRolesList } from '../../initialStates/securityRoles/securityRolesList';

export const securityRolesListReducer = (state = securityRolesList, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SECURITY_ROLES_LIST_TYPES.INITIALIZE_SECURITY_ROLES_LIST:
        draft = {
          ...securityRolesList,
          loading: draft.loading,
        };
        return draft;

      case SECURITY_ROLES_LIST_TYPES.SET_SECURITY_ROLES_LIST_LOADING:
        draft.loading = action.payload;
        return draft;

      case SECURITY_ROLES_LIST_TYPES.SET_SECURITY_ROLES_LIST:
        draft = {
          ...action.payload,
          loading: draft.loading,
        };
        return draft;

      default:
        return draft;
    }
  });
};
