import React, { useState } from 'react';
import { DatePickerValue } from '../DatePicker/DatePicker';
import { parseISO, startOfMonth, formatISO, isValid, addMonths, isEqual } from 'date-fns';
import { updatePath } from '../../utils/updatePath';
import { useSearchParams } from '../../hooks/useSearchParams';
import { useHistory, useLocation } from 'react-router-dom';
import { updateURLSearchParams } from '../../utils/updateURLSearchParams';
import getSearchParams from '../../utils/urlUtils/getSearchParams';
import DateTimeInput from '../DateTimeInput/DateTimeInput';

export interface DateFilterProps {
  initialValue: DatePickerValue;
  maxValue?: Date;
}

export const extractValueFromQueryString = (query: string): DatePickerValue | undefined => {
  if (query) {
    const { startDate, endDate } = getSearchParams(decodeURIComponent(query));

    if (startDate && endDate) {
      const decodedStartDate = decodeURIComponent(startDate);
      const decodedEndDate = decodeURIComponent(endDate);

      const startDateValue = parseISO(decodedStartDate);
      const endDateValue = parseISO(decodedEndDate);

      if (isValid(startDateValue) && isValid(endDateValue)) {
        return {
          startDate: startDateValue,
          endDate: endDateValue,
          startMonth: startDateValue,
          endMonth: isEqual(startOfMonth(startDateValue), startOfMonth(endDateValue))
            ? addMonths(startDateValue, 1)
            : endDateValue,
        };
      }
    }
  }
};

const useDateFilter = (initialValue: DatePickerValue): [DatePickerValue, (value: DatePickerValue) => void] => {
  const { query } = useSearchParams();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const dateTimeInputValue = extractValueFromQueryString(query) ?? {
    startMonth: initialValue.startMonth,
    endMonth: initialValue.endMonth,
  };

  type UpdateURLPath = {
    startDate?: Date;
    endDate?: Date;
  };

  const updateURLPath = ({ startDate, endDate }: UpdateURLPath = {}) => {
    const startDateValue = startDate ? formatISO(startDate, { format: 'extended' }) : '';
    const endDateValue = endDate ? formatISO(endDate, { format: 'extended' }) : '';

    const queryParams = updateURLSearchParams(query, [
      { name: 'startDate', value: startDateValue },
      { name: 'endDate', value: endDateValue },
    ]);

    const sanitizedValue = encodeURIComponent(queryParams.toString());
    updatePath(sanitizedValue, pathname, push);
  };

  const onDateTimeInputChange = (value: DatePickerValue) => {
    updateURLPath({
      startDate: value.startDate,
      endDate: value.endDate,
    });
  };

  return [dateTimeInputValue, onDateTimeInputChange];
};

const DateFilter = (props: DateFilterProps) => {
  const { initialValue } = props;
  const [date, setDate] = useDateFilter(initialValue);

  return (
    <DateTimeInput
      placeholder='DD/MM/YYYY HH:MM:SS AM - DD/MM/YYYY HH:MM:SS AM'
      value={date}
      type='datetime'
      mode='range'
      timePrecision='seconds'
      onChange={setDate}
    />
  );
};

const daysToMilliseconds = (days: number) => days * 24 * 60 * 60 * 1000;

export const DateFilterRestrictable = ({
  initialValue,
  maxDays,
  timePrecision = 'seconds',
  maxValue,
}: DateFilterProps & { maxDays: number; timePrecision?: 'seconds' | 'minutes' | 'hours' }) => {
  const [date, setDate] = useDateFilter(initialValue);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const restrictedSetDate = (value: DatePickerValue) => {
    if (value.endDate && value.startDate) {
      const range = value.endDate?.getTime() - value.startDate?.getTime();
      if (range > daysToMilliseconds(maxDays)) {
        setErrorMessage(`Date range must be less than ${maxDays} days.`);
        return;
      }

      setErrorMessage(undefined);
      return;
    }

    setErrorMessage(undefined);
  };

  return (
    <DateTimeInput
      errorMessage={errorMessage}
      placeholder='DD/MM/YYYY HH:MM:SS AM - DD/MM/YYYY HH:MM:SS AM'
      value={date}
      type='datetime'
      mode='range'
      timePrecision={timePrecision}
      onSetDate={restrictedSetDate}
      onChange={setDate}
      maxValue={maxValue}
    />
  );
};

export default DateFilter;
