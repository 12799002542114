import React from 'react';
import styles from './ItemGalleryView.module.scss';
import { Panel } from 'components/PanelLayout';
import { MenuActionHeader, MenuForActionHeader } from 'menus/components/Menus/MenuActionHeader/MenuActionHeader';
import { IMenuGalleryView } from 'menus/redux/MenusState';
import { RootState, useAppDispatch, useAppSelector } from 'redux/store';
import { SharedMenuItemsRoutes } from 'sharedMenuItems/Router';
import { menuGallerySetEditMode } from 'menus/redux/MenusActions';
import TextIcon, { Icon } from 'components/Icon/TextIcon';
import MenuBuilderConfig from 'menus/types/MenuBuilderConfig';
import MenuItemAndItemGroupList from 'menus/components/Menus/MenuItemAndItemGroupList';
import { Status } from '@ready/menu.core';
import { useHistory } from 'react-router-dom';

const noItemsMessage = `Add items to this gallery to promote them to your guests (max ${MenuBuilderConfig.itemGalleryLimit} items).`;

export type ItemGalleryViewProps = {
  companyId: string;
  itemGalleryId: string;
  menuConfigId: string;
};

export const ItemGalleryView = (props: ItemGalleryViewProps) => {
  const { companyId, itemGalleryId, menuConfigId } = props;

  const dispatch = useAppDispatch();

  const {
    menuGalleryView,
    menuGalleryForm: { editing, processing },
  } = useAppSelector((state: RootState) => state.menuBuilder.menus);

  const handleEditDetailsClick = () => {
    dispatch(menuGallerySetEditMode(companyId, '', itemGalleryId));
  };

  const itemGallery: IMenuGalleryView | undefined = menuGalleryView[itemGalleryId];

  const isButtonDisabled = editing || processing;

  const actionHeaderMenu: MenuForActionHeader = {
    displayName: itemGallery?.menuGalleryDetails.displayName,
    description: itemGallery?.menuGalleryDetails.description,
  };

  const { push } = useHistory();
  const handleLocationSettingsClick = () => {
    push(SharedMenuItemsRoutes.getItemGalleryLocationSettingsRoute(companyId, menuConfigId, itemGalleryId));
  };

  return (
    <>
      <div className={styles.container}>
        <MenuActionHeader
          menu={actionHeaderMenu}
          backLinkTo={SharedMenuItemsRoutes.getMenuConfigsRoute(companyId)}
          editUnavailable={isButtonDisabled}
          onEditDetailsClick={handleEditDetailsClick}
          onLocationSettingsClick={handleLocationSettingsClick}
          editItemLabel='Edit Item Gallery'
          beforeTextSlot={<TextIcon icon={Icon.Star} additionalStyles={styles.itemGalleryStarIcon} />}
        />
      </div>

      <Panel title='Items' fullContentArea additionalStyles={styles.panel}>
        {itemGallery.menuGalleryDetails.items.length > 0 ? (
          <div className={styles.listContainer}>
            <MenuItemAndItemGroupList
              disabled={false}
              parentDisabled={itemGallery.menuGalleryDetails.status === Status.disabled}
              items={itemGallery.menuGalleryDetails.items}
              getMenuItemLink={(menuItemId: string) =>
                SharedMenuItemsRoutes.getItemAndModsItem(companyId, menuItemId, menuConfigId, undefined, itemGalleryId)
              }
              getItemGroupLink={(itemGroupId: string) =>
                SharedMenuItemsRoutes.getItemGroupDetailsRoute(
                  companyId,
                  itemGroupId,
                  menuConfigId,
                  undefined,
                  itemGalleryId
                )
              }
              isSharedMenu
            />
          </div>
        ) : (
          <p className={styles.noItemsMessage}>{noItemsMessage}</p>
        )}
      </Panel>
    </>
  );
};
