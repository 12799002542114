import { createAsyncThunk } from '@reduxjs/toolkit';
import { TerminalDto } from '@ready/dashboardv2api.contracts';
import config from 'config';
import executeApiAsync from 'services/utils/executeApiAsync';
import { interceptErrorWithToastMessage } from 'redux/helpers/interceptErrorWithToastMessage';

export const getTerminals = ({
  companyId,
  locationId,
}: {
  companyId: string;
  locationId: string;
}): Promise<TerminalDto[]> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/terminals`;
  return executeApiAsync<TerminalDto[]>(url);
};

export const getTerminalsThunk = createAsyncThunk(
  'serverFlow/readyPayments/getTerminals',
  interceptErrorWithToastMessage(getTerminals)
);
