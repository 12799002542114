import React from 'react';
import styles from './MenuActionHeader.module.scss';
import ActionHeader from '../../../../components/ActionHeader/ActionHeader';
import InfoSchedule from '../../shared/InfoSchedule';
import { IMenu, Status } from '@ready/menu.core';
import { LocationSettingsButton } from 'sharedMenuItems/pages/menu/components/LocationSettingsButton';

export type MenuForActionHeader = Partial<Pick<IMenu, 'displayName' | 'schedule' | 'description' | 'status'>>;

interface Props {
  menu: MenuForActionHeader;
  backLinkTo: string;
  editUnavailable: boolean;
  onEditDetailsClick: () => void;
  onLocationSettingsClick?: () => void;
  editItemLabel?: string;
  beforeTextSlot?: React.ReactNode;
}

export const MenuActionHeader = ({
  menu,
  backLinkTo,
  editUnavailable,
  onEditDetailsClick,
  onLocationSettingsClick,
  editItemLabel = '',
  beforeTextSlot,
}: Props) => {
  return (
    <ActionHeader
      beforeTextSlot={beforeTextSlot}
      text={menu.displayName}
      extendedText={menu.status === Status.disabled ? 'Disabled' : ''}
      subComponent={
        <div className={styles.subheader}>
          {menu.schedule && <InfoSchedule variant='primary' scheduleLink={menu.schedule} magenta />}
          {menu.description && <div className={styles.description}>{menu.description}</div>}
          {onLocationSettingsClick && <LocationSettingsButton onClick={onLocationSettingsClick} />}
        </div>
      }
      fullWidth
      disabled={menu.status === Status.disabled}
      backLinkTo={backLinkTo}
      actionButtons={[
        {
          label: `${editItemLabel}`,
          variant: 'secondary-gray-bg',
          unavailable: editUnavailable,
          onClick: onEditDetailsClick,
        },
      ]}
    />
  );
};
