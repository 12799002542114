import { PaginationResponse } from '@ready/dashboardv2api.contracts';
import {
  ITag,
  ITemplateEmbeddedMenuItem,
  ITemplateEmbeddedModifierGroup,
  ITemplateForcedModifier,
  ITemplateMenuItem,
  ITemplateModifierGroup,
  WithAssignedLocationsCount,
} from '@ready/menu.core';
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import InfiniteScrollingListState from 'types/InfiniteScrollingListState.interface';
import InfiniteScrollingListStateHelper from 'utils/InfiniteScrollingList/InfiniteScrollingListStateHelper';
import {
  createTemplateMenuItemThunk,
  deleteTemplateMenuItemThunk,
  duplicateTemplateMenuItemThunk,
  getForcedModifiersThunk,
  getModifierGroupsThunk,
  getMoreModifierGroupsThunk,
  getRecommendedItemsThunk,
  getMoreRecommendedItemsThunk,
  getTemplateMenuItemThunk,
  updateTemplateMenuItemThunk,
  getMoreForcedModifiersThunk,
} from './createEditItemThunkActions';

// Type here is "any" so it can accept all three modals
export interface ICreateEditSharedMenuItemModalState extends InfiniteScrollingListState<any> {}

export interface ICreateEditSharedMenuItemModals {
  modifierGroupsModal: ICreateEditSharedMenuItemModalState;
  recommendedItemsModal: ICreateEditSharedMenuItemModalState;
  forcedModifiersModal: ICreateEditSharedMenuItemModalState;
}

type SharedMenuItemModalKey = keyof ICreateEditSharedMenuItemModals;

export interface ICreateEditSharedMenuItemState {
  item?: ITemplateMenuItem;
  itemCache?: ITemplateMenuItem;
  loading: boolean;
  processing: boolean;
  isItemDeleted: boolean;
  newId?: string;
  modals: ICreateEditSharedMenuItemModals;
}

export const newSharedMenuItem: ITemplateMenuItem = {
  _id: '',
  itemType: 'item',
  displayName: '',
  modifierGroups: [],
  forcedModifiers: [],
  recommendedItems: [],
};

export const initialState: ICreateEditSharedMenuItemState = {
  modals: {
    modifierGroupsModal: { ...InfiniteScrollingListStateHelper.initialState() },
    forcedModifiersModal: { ...InfiniteScrollingListStateHelper.initialState() },
    recommendedItemsModal: { ...InfiniteScrollingListStateHelper.initialState() },
  },
  loading: false,
  processing: false,
  isItemDeleted: false,
};

const reducers = {
  updateSharedMenuItemState: (
    state: ICreateEditSharedMenuItemState,
    action: { payload: Partial<ITemplateMenuItem> }
  ) => {
    state.item = { ...(state.item ?? newSharedMenuItem), ...action.payload };
  },

  revertSharedMenuItemChanges: (state: ICreateEditSharedMenuItemState) => {
    state.item = state.itemCache;
  },

  resetSharedMenuItemState: () => initialState,

  addTag: (state: ICreateEditSharedMenuItemState, action: { payload: ITag }) => {
    reducers.updateSharedMenuItemState(state, { payload: { tags: [...(state.item?.tags ?? []), action.payload] } });
  },

  removeTag: (state: ICreateEditSharedMenuItemState, action: { payload: string }) => {
    if (state.item) {
      state.item.tags = state.item.tags?.filter((tag) => tag._id !== action.payload);
    }
  },

  saveItemPending: (state: ICreateEditSharedMenuItemState) => {
    state.processing = true;
  },

  fillItemAndCacheFulfilled: (
    state: ICreateEditSharedMenuItemState,
    action: { payload: ITemplateMenuItem | undefined }
  ) => {
    state.item = state.itemCache = action.payload;
  },

  saveItemRejected: (state: ICreateEditSharedMenuItemState) => {
    state.processing = false;
  },

  prepareModal: (
    state: ICreateEditSharedMenuItemState,
    action: {
      payload: {
        stateKey: SharedMenuItemModalKey;
        isVisible: boolean;
        selectedIds?: string[];
      };
    }
  ) => {
    InfiniteScrollingListStateHelper.prepareState(
      state.modals[action.payload.stateKey],
      action.payload.isVisible,
      action.payload.selectedIds
    );
  },

  loadModalItemsPending: (
    state: ICreateEditSharedMenuItemState,
    action: { payload: { stateKey: SharedMenuItemModalKey } }
  ) => {
    InfiniteScrollingListStateHelper.loadingState(state.modals[action.payload.stateKey]);
  },

  loadModalItemsFailure: (
    state: ICreateEditSharedMenuItemState,
    action: { payload: { stateKey: SharedMenuItemModalKey } }
  ) => {
    InfiniteScrollingListStateHelper.loadFailedState(state.modals[action.payload.stateKey]);
  },

  loadMoreModalItemsPending: (
    state: ICreateEditSharedMenuItemState,
    action: { payload: { stateKey: SharedMenuItemModalKey } }
  ) => {
    InfiniteScrollingListStateHelper.loadingMoreState(state.modals[action.payload.stateKey]);
  },

  loadMoreModalItemsFailure: (
    state: ICreateEditSharedMenuItemState,
    action: { payload: { stateKey: SharedMenuItemModalKey } }
  ) => {
    InfiniteScrollingListStateHelper.loadMoreFailedState(state.modals[action.payload.stateKey]);
  },

  loadMoreModalItemsNoResults: (
    state: ICreateEditSharedMenuItemState,
    action: { payload: { stateKey: SharedMenuItemModalKey } }
  ) => {
    InfiniteScrollingListStateHelper.loadMoreNoItemsState(state.modals[action.payload.stateKey]);
  },

  updateModalSearchQuery: (
    state: ICreateEditSharedMenuItemState,
    action: { payload: { stateKey: SharedMenuItemModalKey; searchQuery: string } }
  ) => {
    InfiniteScrollingListStateHelper.filterChangedState(
      state.modals[action.payload.stateKey],
      action.payload.searchQuery
    );
  },

  selectItemInModal: (
    state: ICreateEditSharedMenuItemState,
    action: { payload: { itemId: string; stateKey: SharedMenuItemModalKey } }
  ) => {
    InfiniteScrollingListStateHelper.selectItemState(state.modals[action.payload.stateKey], action.payload.itemId);
  },

  unselectRecommendedItem: (state: ICreateEditSharedMenuItemState, action: { payload: string }) => {
    InfiniteScrollingListStateHelper.unselectItemState(state.modals.recommendedItemsModal, action.payload);

    if (state.item?.recommendedItems.length) {
      const filteredItems = state.item.recommendedItems.filter(
        (recommendedItem: ITemplateEmbeddedMenuItem) => recommendedItem.itemId !== action.payload
      );

      reducers.updateSharedMenuItemState(state, { payload: { recommendedItems: filteredItems } });
    }
  },

  unselectForcedModifier: (state: ICreateEditSharedMenuItemState, action: { payload: string }) => {
    InfiniteScrollingListStateHelper.unselectItemState(state.modals.forcedModifiersModal, action.payload);

    if (state.item?.forcedModifiers.length) {
      const filteredForcedModifiers = state.item.forcedModifiers.filter(
        (forcedMod: ITemplateForcedModifier) => forcedMod.itemId !== action.payload
      );

      reducers.updateSharedMenuItemState(state, { payload: { forcedModifiers: filteredForcedModifiers } });
    }
  },

  unselectModifierGroup: (state: ICreateEditSharedMenuItemState, action: { payload: string }) => {
    InfiniteScrollingListStateHelper.unselectItemState(state.modals.modifierGroupsModal, action.payload);

    if (state.item?.modifierGroups.length) {
      const filteredModifierGroups = state.item.modifierGroups.filter(
        (modGroup: ITemplateEmbeddedModifierGroup) => modGroup._id !== action.payload
      );

      reducers.updateSharedMenuItemState(state, { payload: { modifierGroups: filteredModifierGroups } });
    }
  },

  dragAndDropModifierGroup: (
    state: ICreateEditSharedMenuItemState,
    action: { payload: { oldIndex: number; newIndex: number } }
  ) => {
    const modifierGroups = state.item?.modifierGroups;
    if (modifierGroups?.length) {
      const draggedItem = modifierGroups.splice(action.payload.oldIndex, 1)[0];
      modifierGroups.splice(action.payload.newIndex, 0, draggedItem);
      modifierGroups.forEach((modGroup, index) => (modGroup.sortOrder = index));
    }
  },


  getModifierGroupsModalFulfilled: (
    state: ICreateEditSharedMenuItemState,
    action: {
      payload: PaginationResponse<ITemplateModifierGroup> | undefined;
      isLoadMore?: boolean;
    }
  ): void => {
    if (action.payload?.items) {
      if (action.isLoadMore) {
        if (action.payload.items.length === 0) {
          reducers.loadMoreModalItemsNoResults(state, { payload: { stateKey: 'modifierGroupsModal' } });
        }
        InfiniteScrollingListStateHelper.loadedMoreState(state.modals.modifierGroupsModal, action.payload.items);
      } else {
        InfiniteScrollingListStateHelper.loadedState(state.modals.modifierGroupsModal, action.payload.items);
      }
    }
  },

  getForcedModifiersModalFulfilled: (
    state: ICreateEditSharedMenuItemState,
    action: {
      payload: PaginationResponse<WithAssignedLocationsCount<ITemplateMenuItem>> | undefined;
      isLoadMore?: boolean;
    }
  ): void => {
    if (action.payload?.items) {
      if (action.isLoadMore) {
        if (action.payload.items.length === 0) {
          reducers.loadMoreModalItemsNoResults(state, { payload: { stateKey: 'forcedModifiersModal' } });
        }
        InfiniteScrollingListStateHelper.loadedMoreState(state.modals.forcedModifiersModal, action.payload.items);
      } else {
        InfiniteScrollingListStateHelper.loadedState(state.modals.forcedModifiersModal, action.payload.items);
      }
    }
  },

  getRecommendedItemsModalFulfilled: (
    state: ICreateEditSharedMenuItemState,
    action: {
      payload: PaginationResponse<WithAssignedLocationsCount<ITemplateMenuItem>> | undefined;
      isLoadMore?: boolean;
    }
  ): void => {
    if (action.payload?.items) {
      if (action.isLoadMore) {
        if (action.payload.items.length === 0) {
          reducers.loadMoreModalItemsNoResults(state, { payload: { stateKey: 'recommendedItemsModal' } });
        }
        InfiniteScrollingListStateHelper.loadedMoreState(state.modals.recommendedItemsModal, action.payload.items);
      } else {
        InfiniteScrollingListStateHelper.loadedState(state.modals.recommendedItemsModal, action.payload.items);
      }
    }
  },
};

const createEditSharedMenuItemSlice = createSlice({
  name: 'item/createEditSharedMenuItem',
  initialState: initialState,
  reducers,

  extraReducers: (builder: ActionReducerMapBuilder<ICreateEditSharedMenuItemState>) => {
    builder.addCase(createTemplateMenuItemThunk.pending, reducers.saveItemPending);
    builder.addCase(createTemplateMenuItemThunk.fulfilled, (state, action) => {
      state.processing = false;
      reducers.fillItemAndCacheFulfilled(state, action);
      state.newId = action.payload?._id;
    });
    builder.addCase(createTemplateMenuItemThunk.rejected, reducers.saveItemRejected);

    builder.addCase(updateTemplateMenuItemThunk.pending, reducers.saveItemPending);
    builder.addCase(updateTemplateMenuItemThunk.fulfilled, (state, action) => {
      state.processing = false;
      reducers.fillItemAndCacheFulfilled(state, action);
    });
    builder.addCase(updateTemplateMenuItemThunk.rejected, reducers.saveItemRejected);

    builder.addCase(getTemplateMenuItemThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTemplateMenuItemThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.newId = undefined;
      reducers.fillItemAndCacheFulfilled(state, action);
    });
    builder.addCase(getTemplateMenuItemThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getModifierGroupsThunk.pending, (state) => {
      reducers.loadModalItemsPending(state, { payload: { stateKey: 'modifierGroupsModal' } });
    });
    builder.addCase(getModifierGroupsThunk.fulfilled, reducers.getModifierGroupsModalFulfilled);
    builder.addCase(getModifierGroupsThunk.rejected, (state) => {
      reducers.loadModalItemsFailure(state, { payload: { stateKey: 'modifierGroupsModal' } });
    });

    builder.addCase(getMoreModifierGroupsThunk.pending, (state) => {
      reducers.loadMoreModalItemsPending(state, { payload: { stateKey: 'modifierGroupsModal' } });
    });
    builder.addCase(getMoreModifierGroupsThunk.fulfilled, (state, action) => {
      reducers.getModifierGroupsModalFulfilled(state, { isLoadMore: true, payload: action.payload });
    });
    builder.addCase(getMoreModifierGroupsThunk.rejected, (state) => {
      reducers.loadMoreModalItemsFailure(state, { payload: { stateKey: 'modifierGroupsModal' } });
    });

    builder.addCase(getForcedModifiersThunk.pending, (state) => {
      reducers.loadModalItemsPending(state, { payload: { stateKey: 'forcedModifiersModal' } });
    });
    builder.addCase(getForcedModifiersThunk.fulfilled, reducers.getForcedModifiersModalFulfilled);
    builder.addCase(getForcedModifiersThunk.rejected, (state) => {
      reducers.loadModalItemsFailure(state, { payload: { stateKey: 'forcedModifiersModal' } });
    });

    builder.addCase(getMoreForcedModifiersThunk.pending, (state) => {
      reducers.loadMoreModalItemsPending(state, { payload: { stateKey: 'forcedModifiersModal' } });
    });
    builder.addCase(getMoreForcedModifiersThunk.fulfilled, (state, action) => {
      reducers.getForcedModifiersModalFulfilled(state, { isLoadMore: true, payload: action.payload });
    });
    builder.addCase(getMoreForcedModifiersThunk.rejected, (state) => {
      reducers.loadMoreModalItemsFailure(state, { payload: { stateKey: 'forcedModifiersModal' } });
    });

    builder.addCase(getRecommendedItemsThunk.pending, (state) => {
      reducers.loadModalItemsPending(state, { payload: { stateKey: 'recommendedItemsModal' } });
    });
    builder.addCase(getRecommendedItemsThunk.fulfilled, reducers.getRecommendedItemsModalFulfilled);
    builder.addCase(getRecommendedItemsThunk.rejected, (state) => {
      reducers.loadModalItemsFailure(state, { payload: { stateKey: 'recommendedItemsModal' } });
    });

    builder.addCase(getMoreRecommendedItemsThunk.pending, (state) => {
      reducers.loadMoreModalItemsPending(state, { payload: { stateKey: 'recommendedItemsModal' } });
    });
    builder.addCase(getMoreRecommendedItemsThunk.fulfilled, (state, action) => {
      reducers.getRecommendedItemsModalFulfilled(state, { isLoadMore: true, payload: action.payload });
    });
    builder.addCase(getMoreRecommendedItemsThunk.rejected, (state) => {
      reducers.loadMoreModalItemsFailure(state, { payload: { stateKey: 'recommendedItemsModal' } });
    });

    builder.addCase(duplicateTemplateMenuItemThunk.pending, (state) => {
      state.processing = true;
    });
    builder.addCase(duplicateTemplateMenuItemThunk.fulfilled, (state, action) => {
      state.processing = false;
      reducers.fillItemAndCacheFulfilled(state, action);
      state.newId = action.payload?._id;
    });
    builder.addCase(duplicateTemplateMenuItemThunk.rejected, (state) => {
      state.processing = false;
    });

    builder.addCase(deleteTemplateMenuItemThunk.pending, (state) => {
      state.processing = true;
      state.isItemDeleted = false;
    });
    builder.addCase(deleteTemplateMenuItemThunk.fulfilled, (state) => {
      state.processing = false;
      state.isItemDeleted = true;
    });
    builder.addCase(deleteTemplateMenuItemThunk.rejected, (state) => {
      state.processing = false;
      state.isItemDeleted = false;
    });
  },
});
export const {
  updateSharedMenuItemState,
  revertSharedMenuItemChanges,
  resetSharedMenuItemState,
  addTag,
  removeTag,
  prepareModal,
  selectItemInModal,
  updateModalSearchQuery,
  unselectModifierGroup,
  unselectForcedModifier,
  unselectRecommendedItem,
  dragAndDropModifierGroup,
} = createEditSharedMenuItemSlice.actions;

export { createEditSharedMenuItemSlice };
