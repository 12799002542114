import { IPromoCode, ValidationType } from '@ready/dashboardv2api.contracts';

export interface Discount {
  id: string;
  posId: string;
  name: string;
}

export interface IPromoCodeTimeSlotValidation {
  daysOfWeek: {
    hasError: boolean;
    errorMessage: string;
  };
  startTime: {
    hasError: boolean;
    errorMessage: string;
  };
  endTime: {
    hasError: boolean;
    errorMessage: string;
  };
}

export interface IPromoCodeValidation {
  hasErrors: boolean;
  code: {
    hasError: boolean;
    errorMessage: string;
  };
  posDiscountId: {
    hasError: boolean;
    errorMessage: string;
  };
  startDate: {
    hasError: boolean;
    errorMessage: string;
  };
  endDate: {
    hasError: boolean;
    errorMessage: string;
  };
  timeSlots: IPromoCodeTimeSlotValidation[];
}
export interface IPromoCodeForm {
  loading: boolean;
  promoCode: IPromoCode;
  discounts: Discount[];
  processing: boolean;
  validated: boolean;
  saved: boolean;
  deleting: boolean;
  deleted: boolean;
  validation: IPromoCodeValidation;
}

export interface IPromoCodes {
  codes: IPromoCode[];
  form: IPromoCodeForm;
}

export const newPromoCodeForm = (promoCode?: IPromoCode): IPromoCodeForm => ({
  loading: false,
  promoCode: promoCode || newPromoCode(),
  discounts: [],
  processing: false,
  validated: false,
  saved: false,
  deleting: false,
  deleted: false,
  validation: newPromoCodeValidation(),
});

export const newPromoCodeValidation = (): IPromoCodeValidation => ({
  hasErrors: false,
  code: {
    hasError: false,
    errorMessage: '',
  },
  posDiscountId: {
    hasError: false,
    errorMessage: '',
  },
  startDate: {
    hasError: false,
    errorMessage: '',
  },
  endDate: {
    hasError: false,
    errorMessage: '',
  },
  timeSlots: [],
});

export const newTimeSlotValidation = (): IPromoCodeTimeSlotValidation => ({
  daysOfWeek: {
    hasError: false,
    errorMessage: '',
  },
  startTime: {
    hasError: false,
    errorMessage: '',
  },
  endTime: {
    hasError: false,
    errorMessage: '',
  },
});

export const newPromoCode = (): IPromoCode => ({
  _id: '',
  code: '',
  description: '',
  posDiscountId: '',
  posDiscountName: '',
  validationType: ValidationType.OrderTime,
  autoAttach: false,
  enabled: true,
  timeSlots: [],
});

export const promoCodes = {
  codes: [],
  form: newPromoCodeForm(),
};
