import React, { FC } from 'react';
import { IBadge } from '@ready/menu.core';
import InfoCard from '../../components/InfoCard/InfoCard';
import IconButton from '../../components/IconButton/IconButton';
import TextIcon, { Icon } from '../../components/Icon/TextIcon';
import styles from './BadgeItem.module.scss';
import BadgePreview from './BadgePreview';

interface BadgeItemProps {
  item: IBadge;
  canEdit: boolean;
  onEditBadge: (badge: IBadge) => void;
  onDeleteBadge: (badge: IBadge) => void;
}

const BadgeItem: FC<BadgeItemProps> = (props) => {
  const { item, onDeleteBadge, onEditBadge, canEdit } = props;

  return (
    <InfoCard additionalStyles={'removeMinHight'}>
      <div className={styles.contentBox}>
        <BadgePreview backgroundColour={item.backgroundColor} textColour={item.textColor} badgeText={item.text} />
        {item.name}
      </div>
      {canEdit && (
        <>
          <IconButton onClick={() => onEditBadge(item)}>
            <TextIcon icon={Icon.Pencil} />
          </IconButton>
          <IconButton onClick={() => onDeleteBadge(item)}>
            <TextIcon icon={Icon.TrashCan} />
          </IconButton>
        </>
      )}
    </InfoCard>
  );
};

export default BadgeItem;
