import produce from 'immer';
import { Action } from '../../../redux/types';
import { transactionState } from '../../initialStates/transactions/transaction';
import { TRANSACTION_DETAIL_ACTIONS } from '../../actions/transactionActions/transactionDetailActionTypes';

export const transactionDetailReducer = (state = transactionState, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case TRANSACTION_DETAIL_ACTIONS.GET_TRANSACTION_BEGIN:
        draft.loading = true;
        draft.error = false;
        draft.errorMessage = '';
        return draft;

      case TRANSACTION_DETAIL_ACTIONS.GET_TRANSACTION_SUCCESS:
        draft.loading = false;
        draft.transaction = action.payload;
        return draft;

      case TRANSACTION_DETAIL_ACTIONS.GET_TRANSACTION_ERROR:
        draft.loading = false;
        draft.error = true;
        draft.errorMessage = action.payload;
        return draft;

      case TRANSACTION_DETAIL_ACTIONS.CHECK_TRANSACTION_REFUND_ACCESS_SUCCESS:
        draft.hasRefundTransactionAccess = action.payload;
        return draft;

      case TRANSACTION_DETAIL_ACTIONS.CHECK_TRANSACTION_REFUND_ACCESS_ERROR:
        draft.error = true;
        draft.errorMessage = action.payload;
        return draft;

      case TRANSACTION_DETAIL_ACTIONS.VALIDATE_REFUND_AMOUNT:
        draft.refundAmount = action.payload.refundAmount;
        draft.refundValidationError = action.payload.validationMessage;
        return draft;

      case TRANSACTION_DETAIL_ACTIONS.REFUND_TRANSACTION_BEGIN:
        draft.refunding = true;
        draft.refundCompleted = false;
        draft.refundError = '';
        return draft;

      case TRANSACTION_DETAIL_ACTIONS.REFUND_TRANSACTION_SUCCESS:
        draft.refunding = false;
        draft.refundCompleted = true;
        return draft;

      case TRANSACTION_DETAIL_ACTIONS.REFUND_TRANSACTION_ERROR:
        draft.refunding = false;
        draft.refundCompleted = true;
        draft.refundError = action.payload;
        return draft;

      case TRANSACTION_DETAIL_ACTIONS.RESET_REFUND_STATE:
        draft.refunding = false;
        draft.refundAmount = 0;
        draft.refundValidationError = '';
        draft.refundCompleted = false;
        draft.refundError = '';
        return draft;

      case TRANSACTION_DETAIL_ACTIONS.RETRY_PAYMENT_SYNC_PROCESSING:
        draft.processingRetry = action.payload;
        return draft;

      case TRANSACTION_DETAIL_ACTIONS.RETRY_PAYMENT_SYNC_SUCCESS:
        draft.transaction.requiresReentry = false;
        return draft;

      default:
        return draft;
    }
  });
};
