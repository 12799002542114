import { initialLocationPerformanceState } from './initialState';
import { createSlice } from '@reduxjs/toolkit';
import { exportLocationPerformanceThunk, fetchLocationPerformanceThunk, fetchPermittedLocationsThunk } from './thunks';
import {
  fetchLocationPerformanceReducers,
  fetchPermittedLocationsReducers,
  exportLocationPerformanceReducers,
} from './reducers';

const locationPerformanceReducerSlice = createSlice({
  name: 'locationPerformance',
  initialState: initialLocationPerformanceState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLocationPerformanceThunk.pending, fetchLocationPerformanceReducers.pending);
    builder.addCase(fetchLocationPerformanceThunk.fulfilled, fetchLocationPerformanceReducers.fulfilled);
    builder.addCase(fetchLocationPerformanceThunk.rejected, fetchLocationPerformanceReducers.rejected);

    builder.addCase(exportLocationPerformanceThunk.pending, exportLocationPerformanceReducers.pending);
    builder.addCase(exportLocationPerformanceThunk.fulfilled, exportLocationPerformanceReducers.fulfilled);
    builder.addCase(exportLocationPerformanceThunk.rejected, exportLocationPerformanceReducers.rejected);

    builder.addCase(fetchPermittedLocationsThunk.pending, fetchPermittedLocationsReducers.pending);
    builder.addCase(fetchPermittedLocationsThunk.fulfilled, fetchPermittedLocationsReducers.fulfilled);
    builder.addCase(fetchPermittedLocationsThunk.rejected, fetchPermittedLocationsReducers.rejected);
  },
});

const { actions: locationPerformanceActions, reducer: locationPerformanceReducer } = locationPerformanceReducerSlice;
export { locationPerformanceActions, locationPerformanceReducer };
