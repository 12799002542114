import React from 'react';
import { IMenu, Status } from '@ready/menu.core';
import { connect, ConnectedProps } from 'react-redux';
import { Routes } from '../../MenuBuilderRouter';
import { AppState } from '../../../redux/initialStates/AppState';
import MenuBuilderConfig from '../../types/MenuBuilderConfig';
import PanelCollapsible from '../../../components/PanelLayout/PanelCollapsible';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';
import DisabledBadge from '../../components/DisabledBadge';
import OverflowButton from '../../../components/OverflowButton/OverflowButton';
import Button from '../../../components/Button/Button';
import useResponsiveBreakpoint from '../../../hooks/useResponsiveBreakpoint';
import styles from './MenuItemGalleryEntryView.module.scss';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import {
  menuGallerySetEditMode,
  requestDeleteMenuGallery,
  updateMenuGalleryStatus,
  viewMenuGallery,
  updateLocationItemGalleryMenuItemVisibilityAndSave,
  updateLocationItemGalleryItemGroupMenuItemVisibilityAndSave,
} from '../../redux/MenusActions';
import MenuItemAndItemGroupList from './MenuItemAndItemGroupList';

import { DropDownButtonOption } from 'components/DropDownButton/DropDownButton';

const mapStateToProps = (state: AppState) => ({
  menuGalleryView: state.menuBuilder.menus.menuGalleryView,
});

const actionCreators = {
  menuGallerySetEditMode,
  updateMenuGalleryStatus,
  requestDeleteMenuGallery,
  viewMenuGallery,
  updateLocationItemGalleryMenuItemVisibilityAndSave,
  updateLocationItemGalleryItemGroupMenuItemVisibilityAndSave,
};

const connector = connect(mapStateToProps, actionCreators);

export interface MenuItemGalleryEntryViewProps extends ConnectedProps<typeof connector> {
  disabled: boolean;
  item: IMenu;
  companyId: string;
  locationId: string;
  menuConfigId? :string;
  isSharedConfig?: boolean;
}

const MenuItemGalleryEntryView = (props: MenuItemGalleryEntryViewProps) => {
  const {
    item,
    disabled,
    companyId,
    locationId,
    menuConfigId,
    menuGallerySetEditMode,
    updateMenuGalleryStatus,
    requestDeleteMenuGallery,
    viewMenuGallery,
    menuGalleryView,
    updateLocationItemGalleryMenuItemVisibilityAndSave,
    updateLocationItemGalleryItemGroupMenuItemVisibilityAndSave,
    isSharedConfig = false,
  } = props;
  const { isMobile } = useResponsiveBreakpoint();

  const { _id, displayName, status } = item;

  const handleEditGallery = () => {
    menuGallerySetEditMode(companyId, locationId, _id);
  };

  const handleDeleteGallery = () => {
    requestDeleteMenuGallery(true, _id, displayName);
  };

  const statusButton =
    status === Status.disabled
      ? {
          label: 'Enable Gallery',
          primary: false,
          onClick: () => updateMenuGalleryStatus(companyId, locationId, _id, 1),
        }
      : {
          label: 'Disable Gallery',
          primary: false,
          onClick: () => updateMenuGalleryStatus(companyId, locationId, _id, 0),
        };

  const overflowOptions: DropDownButtonOption[] = [
    statusButton,
    {
      label: 'Delete Gallery',
      primary: true,
      hidden: isSharedConfig,
      onClick: handleDeleteGallery,
    },
  ];

  if (isMobile && !isSharedConfig) {
    overflowOptions.unshift({
      label: 'Edit Gallery',
      primary: true,
      onClick: handleEditGallery,
    });
  }

  const displayNameClassName =
    status === Status.disabled ? styles.menuGalleryDescriptionNameDisabled : styles.menuGalleryDescriptionName;

  const loading = menuGalleryView[_id]?.loading || false;
  const itemGallery = menuGalleryView[_id]?.menuGalleryDetails;
  const items = itemGallery?.items || [];
  const description = itemGallery?.description || '';

  // get gallery details
  React.useEffect(() => {
    if (!!companyId && !!locationId && !!_id) {
      viewMenuGallery(companyId, locationId, _id);
    }
  }, [viewMenuGallery, companyId, locationId, _id]);

  return (
    <PanelCollapsible
      fullContentArea
      additionalStyles={styles.menuGallery}
      toggleDisabled={disabled}
      titleComponent={
        <div>
          <div className={styles.menuGalleryDescription}>
            <TextIcon icon={Icon.Star} additionalStyles={styles.menuGalleryStarIcon} />
            <span className={displayNameClassName}>{displayName}</span>
            {status === Status.disabled && <DisabledBadge />}
          </div>
        </div>
      }
      headerControls={
        <div className={styles.menuGalleryControls}>
          {!isMobile && !isSharedConfig && (
            <Button
              label='Edit'
              variant='secondary'
              additionalStyles={styles.menuGalleryEditButton}
              unavailable={disabled}
              onClick={handleEditGallery}
            />
          )}
          <OverflowButton disabled={disabled} options={overflowOptions} />
        </div>
      }
      hideContentTopSeparation
    >
      <div className={styles.viewContent}>
        {loading ? (
          <LoadingSpinner additionalStyles={styles.viewContentLoading} />
        ) : (
          <>
            <div className={styles.descriptionSummary}>
              {!!description && <p className={styles.descriptionSummaryContent}>{description}</p>}
            </div>
            <div className={styles.items}>
              {items.length > 0 ? (
                <div className={styles.gallerySubitems}>
                  <MenuItemAndItemGroupList
                    disabled={false}
                    parentDisabled={item.status === Status.disabled}
                    items={items}
                    getMenuItemLink={(menuItemId: string) =>
                      Routes.getItemGalleryItemPage(companyId, locationId, menuItemId, menuConfigId)
                    }
                    getItemGroupLink={(itemGroupId: string) =>
                      Routes.getLocationItemGalleryItemGroupPage(companyId, locationId, itemGroupId, menuConfigId)
                    }
                    onItemVisibilityClick={(itemId: string, visible: boolean) =>
                      updateLocationItemGalleryMenuItemVisibilityAndSave(
                        companyId,
                        locationId,
                        itemGallery,
                        itemId,
                        visible
                      )
                    }
                    onGroupItemVisibilityClick={(groupId: string, itemId: string, visible: boolean) =>
                      updateLocationItemGalleryItemGroupMenuItemVisibilityAndSave(
                        companyId,
                        locationId,
                        itemGallery,
                        groupId,
                        itemId,
                        visible
                      )
                    }
                  />
                </div>
              ) : (
                <p className={styles.noItemsMessage}>
                  Add items to this gallery to promote them to your guests{' '}
                  <span>(max {MenuBuilderConfig.itemGalleryLimit} items)</span>.
                </p>
              )}
            </div>
          </>
        )}
      </div>
    </PanelCollapsible>
  );
};

export default connector(MenuItemGalleryEntryView);
