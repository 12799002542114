import produce from 'immer';
import { Action } from '../../../redux/types';
import { usersFormInitialState, usersFormCompanyRoleSelectorInitialState } from '../../initialStates/users/usersForm';
import { USERS_FORM_ACTIONS } from '../../actions/usersActions/types';
import { RoleListValue } from '../../actions/usersActions/usersFormActions';
import { IUserRole } from '../../../services/types/companyUsers.type';

export const usersFormReducer = (state = usersFormInitialState, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case USERS_FORM_ACTIONS.INITIALIZE_USERS_FORM:
        draft = { ...usersFormInitialState };
        return draft;

      case USERS_FORM_ACTIONS.SET_USERS_FORM:
        draft = {
          loading: draft.loading,
          processing: draft.processing,
          selectedRoleIds: getSelectedRoleIds(action.payload.roles),
          ...action.payload,
        };
        return draft;

      case USERS_FORM_ACTIONS.UPDATE_FIRST_NAME:
        draft.firstName = action.payload;
        draft.validation.usersForm.firstName.hasError = false;
        return draft;

      case USERS_FORM_ACTIONS.UPDATE_LAST_NAME:
        draft.lastName = action.payload;
        draft.validation.usersForm.lastName.hasError = false;
        return draft;

      case USERS_FORM_ACTIONS.UPDATE_EMAIL_ADDRESS:
        draft.email = action.payload;
        draft.validation.usersForm.email.hasError = false;
        return draft;

      case USERS_FORM_ACTIONS.ADD_USER_ROLE:
        if (!draft.roles) {
          draft.roles = [];
        }
        draft.roles.push({ role: undefined, locations: [], selector: usersFormCompanyRoleSelectorInitialState });
        draft.validation.usersForm.roles.push({ hasError: false, error: '' });
        draft.selectedRoleIds = getSelectedRoleIds(draft.roles);
        return draft;

      case USERS_FORM_ACTIONS.REMOVE_USER_ROLE:
        draft.roles!.splice(action.payload, 1);
        draft.validation.usersForm.roles.splice(action.payload, 1);
        draft.selectedRoleIds = getSelectedRoleIds(draft.roles);
        return draft;

      case USERS_FORM_ACTIONS.UPDATE_ROLE:
        draft.roles![action.payload.index].role = action.payload.role;
        draft.validation.usersForm.roles[action.payload.index].hasError = false;
        draft.selectedRoleIds = getSelectedRoleIds(draft.roles);
        return draft;

      case USERS_FORM_ACTIONS.LOAD_AVAILABLE_ROLES_BEGIN:
        draft.roles![action.payload].selector.loading = true;
        return draft;

      case USERS_FORM_ACTIONS.LOAD_AVAILABLE_ROLES_SUCCESS:
        draft.roles![action.payload.instance].selector.loading = false;

        const filteredRoles = action.payload.roles.filter(
          (role: RoleListValue) => !draft.selectedRoleIds.includes(role.id)
        );

        draft.roles![action.payload.instance].selector.roles = filteredRoles;

        return draft;

      case USERS_FORM_ACTIONS.UPDATE_ENABLED:
        draft.enabled = action.payload;
        return draft;

      case USERS_FORM_ACTIONS.ADD_USER_ROLE_LOCATION:
        if (!draft.roles![action.payload.index].locations) {
          draft.roles![action.payload.index].locations = [];
        }
        draft.roles![action.payload.index].locations?.push(action.payload.location);
        return draft;

      case USERS_FORM_ACTIONS.REMOVE_USER_ROLE_LOCATION:
        const userRole = draft.roles![action.payload.index];
        const locationIndex = userRole.locations!.findIndex((location) => location.id === action.payload.locationId);
        if (locationIndex > -1) {
          userRole.locations!.splice(locationIndex, 1);
        }
        return draft;

      case USERS_FORM_ACTIONS.VALIDATE_USER_FORM:
        draft.roles = action.payload.roles;
        draft.validation = action.payload.validation;
        return draft;

      case USERS_FORM_ACTIONS.SET_USERS_FORM_PROCESSING:
        draft.processing = action.payload;
        return draft;

      case USERS_FORM_ACTIONS.SET_USERS_FORM_LOADING:
        draft.loading = action.payload;
        return draft;

      case USERS_FORM_ACTIONS.RESET_USERS_FORM:
        draft = {
          loading: draft.loading,
          processing: draft.processing,
          ...action.payload,
        };
        return draft;

      default:
        return draft;
    }
  });
};

const getSelectedRoleIds = (roles?: IUserRole[]) =>
  (roles?.map((role) => role.role?.id || '') || []).filter((id) => !!id);
