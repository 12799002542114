import { ITransactionDetailGiftCardDetails } from '@ready/dashboardv2api.contracts';
import React from 'react';
import { DetailLayout, DetailElement } from '../../../../components/DetailLayout';
import { DateValue, DollarValue, TextValue } from '../../../../components/Value';

interface GiftCardDetailsSectionProps {
  giftCardDetails: ITransactionDetailGiftCardDetails;
  gcAmountAvailableForRefund: number;
  setShowGiftCardRefundModal: (showModal: boolean) => void;
  timezone?: string;
}

export const GiftCardDetailsSection = (props: GiftCardDetailsSectionProps) => {
  const {
    setShowGiftCardRefundModal,
    gcAmountAvailableForRefund,
    giftCardDetails: { amountPaid, amountRefunded, date, currency, giftCardNumber, transactionId },
    timezone,
  } = props;
  return (
    <DetailLayout
      title='Gift Card Payment Details'
      ctaDetailsButton={{
        label: 'Refund Gift Card',
        actionHandler: () => setShowGiftCardRefundModal(true),
        disabled: gcAmountAvailableForRefund === 0,
      }}
    >
      <DetailElement condensed label='Amount Paid'>
        <DollarValue value={amountPaid} />
      </DetailElement>
      <DetailElement condensed label='Amount Refunded'>
        <DollarValue value={amountRefunded} />
      </DetailElement>
      <DetailElement condensed label='Date'>
        <DateValue value={date?.toString() ?? ''} timeZone={timezone} />
      </DetailElement>
      <DetailElement condensed label='Currency'>
        <TextValue value={currency} />
      </DetailElement>
      <DetailElement condensed label='Gift Card Number'>
        <TextValue value={giftCardNumber} />
      </DetailElement>
      <DetailElement condensed label='Transaction ID'>
        <TextValue value={transactionId} />
      </DetailElement>
    </DetailLayout>
  );
};
