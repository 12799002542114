import React from 'react';
import styles from './BadgePreview.module.scss';

export interface BadgePreviewProps {
  backgroundColour: string;
  textColour: string;
  badgeText: string;
}

const BadgePreview = (props: BadgePreviewProps) => {
  const { backgroundColour, textColour, badgeText } = props;

  return (
    <div
      className={styles.badgeHost}
      style={{
        backgroundColor: backgroundColour,
      }}
    >
      <div id={styles.topTriangle} style={{ backgroundColor: backgroundColour }} className={styles.triangle}></div>
      <div id={styles.bottomTriangle} style={{ backgroundColor: backgroundColour }} className={styles.triangle}></div>
      <span
        style={{
          color: textColour,
        }}
        className={styles.globalHeaderFont}
      >
        {badgeText}
      </span>
    </div>
  );
};

export default BadgePreview;
