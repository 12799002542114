import { IClientLocation, IDigitalMenuConfig } from '@ready/dashboardv2api.contracts';
export interface IClientDigitalMenuState {
  view: IDigitalMenuConfig;
  edit: IDigitalMenuConfig;
  isEditMode: boolean;
  loading: boolean;
  processing: boolean;
  error: boolean;
  location?: IClientLocation;
  validations: IDigitalMenuValidations;
}

export interface IDigitalMenuValidations {
  hasError: boolean;
  errorMessage: string;
}

const digitalMenuDefaultValues: IDigitalMenuConfig = {
  menuMode: undefined,
  imageUrl: undefined,
  iframeUrl: undefined,
  imageKey: undefined,
  enabled: undefined,
};

export const clientDigitalMenuDefaultState = {
  view: digitalMenuDefaultValues,
  edit: digitalMenuDefaultValues,
  isEditMode: false,
  loading: false,
  processing: false,
  error: false,
  validations: {
    hasError: false,
    errorMessage: '',
  },
} as IClientDigitalMenuState;
