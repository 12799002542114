import React from 'react';
import Modal, { ModalProps } from '../../../components/Modal/Modal';
import ModalHeader from '../../../components/Modal/ModalHeader';
import ModalBody from '../../../components/Modal/ModalBody';
import ModalFooter from '../../../components/Modal/ModalFooter';
import { ITableConfiguration } from '@ready/table.core';
import TextInput from '../../../components/TextInput/TextInput';
import { FormControl } from '../../../components/Form';
import { components } from 'react-select';
import { OrderExperienceType } from '@ready/dashboardv2api.contracts';
import SelectFilter from '../../../components/SelectFilter/SelectFilter';
import TextIcon from '../../../components/Icon/TextIcon';
import { LIST_OPTIONS } from '../../../utils/selectListUtils/listOptions';
import styles from './EditTableModal.module.scss';

export interface EditTableModalProps extends Pick<ModalProps, 'setShowModal'> {
  table: ITableConfiguration;
  handleChange: (table: { tableName?: string; targetCode?: string; orderExperienceType?: OrderExperienceType }) => void;
  handleSubmit: () => void;
  handleCancel: () => void;
  error: boolean;
}

const EditTableModal = (props: EditTableModalProps) => {
  const { table, setShowModal, handleChange, handleSubmit, handleCancel, error } = props;

  const Option = (props: any) => (
    <components.Option {...props}>
      {props.data.icon && <TextIcon icon={props.data.icon} />}
      {` ${props.data.label}`}
    </components.Option>
  );

  const SingleValue = (props: any) => (
    <components.SingleValue {...props}>
      {props.data.icon && <TextIcon icon={props.data.icon} additionalStyles={'selectedIcon'} />}
      {` ${props.data.label}`}
    </components.SingleValue>
  );

  return (
    <Modal setShowModal={() => setShowModal(false)}>
      <ModalHeader headerLabel={table.id ? 'Edit Table' : 'New Table'} setShowModal={() => setShowModal(false)} />
      <ModalBody>
        <FormControl label={'Table Name *'} errorMessage={error ? 'Table name required' : ''} bold>
          <TextInput
            type={'text'}
            placeholder={'e.g. 12'}
            value={table.tableName}
            maxLength={25}
            onChange={(e) => {
              handleChange({ tableName: e.target.value });
            }}
            withError={error}
          />
        </FormControl>
        <FormControl label={'Target Code'} bold>
          <TextInput
            type={'text'}
            placeholder={'e.g. ABC123'}
            additionalContainerStyles={styles.targetCode}
            value={table.targetCode}
            onChange={(e) => {
              handleChange({ targetCode: e.target.value });
            }}
          />
        </FormControl>
        <FormControl label={'Table Type'} bold>
          <SelectFilter
            customComponents={{ Option, SingleValue }}
            options={LIST_OPTIONS.orderExperienceTypes}
            value={LIST_OPTIONS.orderExperienceTypes.find((o) => o.value === table.orderExperienceType)}
            onChange={(option) => {
              handleChange({ orderExperienceType: option.value as OrderExperienceType });
            }}
            isInModal
          />
        </FormControl>
      </ModalBody>
      <ModalFooter
        primaryLabel={table.id ? 'Save Changes' : 'Create Table'}
        primaryActionHandler={handleSubmit}
        secondaryLabel='Cancel'
        secondaryActionHandler={handleCancel}
      />
    </Modal>
  );
};

export default EditTableModal;
