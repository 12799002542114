import React from 'react';
import styles from './CompanyTablePage.module.scss';

export interface CompanyTableHeaderProps {
  children: JSX.Element;
}

const CompanyTableHeader = (props: CompanyTableHeaderProps) => {
  const { children } = props;

  return <div className={styles.tableHeader}>{children}</div>;
};

export default CompanyTableHeader;
