import { createSelector } from '@reduxjs/toolkit';
import { ICheckPageState } from './initialState';
import { RootState } from '../../../../redux/store';

export const checksPageDataSelector = createSelector(
  (state: RootState) => state,
  ({
    session,
    reports: {
      checks: { detailsReducer },
    },
  }: RootState): ICheckPageState & { contextId: string } => {
    return {
      ...detailsReducer,
      contextId: session.contextSession.id,
    } as ICheckPageState & { contextId: string };
  }
);
