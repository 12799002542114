import React from 'react';
// state
import { IPaymentProcessingPanel } from '../../redux/PanelPaymentProcessingState';
import { LOCATION_SETTINGS_ACTIONS } from '../../redux/LocationSettingsActions';
// utils
import { toNumber } from '../../../utils/numberUtils/toNumber';
// components
import { FormControl, FormControlStacked } from '../../../components/Form';
import TextInput from '../../../components/TextInput/TextInput';
import FormattedInput from '../../../components/FormattedInput/FormattedInput';
import Checkbox from '../../../components/Checkbox/Checkbox';
import { IUpdateLocationParam } from '../LocationsEditPage';
import TextInputNestedLabel from '../../../components/TextInputNestedLabel/TextInputNestedLabel';
import SelectFilter, { Option } from 'components/SelectFilter/SelectFilter';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { useParams } from 'react-router-dom';
import MissingValue from 'components/Value/MissingValue';
import { getAllJetpayCompanyConfigsListThunk } from 'admin/companies/CompanySettingsPage/payment/jetpay/redux/thunk';
import {
  resetJetpayState,
  selectJetpayConfig,
} from 'admin/companies/CompanySettingsPage/payment/jetpay/redux/slice';
import mapSelectOption from 'utils/selectListUtils/mapSelectOption';
import Toggle from 'components/Toggle/Toggle';

interface Props {
  isProcessing: boolean;
  updateLocationParam: IUpdateLocationParam;
  paymentProcessingPanel: IPaymentProcessingPanel;
}

const JetPayPP = ({ isProcessing, updateLocationParam, paymentProcessingPanel }: Props) => {
  const dispatch = useAppDispatch();
  const { companyId } = useParams<{ companyId: string }>();
  const {
    companyPaymentConfigsList: configs,
    companyPaymentConfig: config,
    isLoading,
  } = useAppSelector((state) => state.companies.companySettings.paymentProcessing.jetpay);

  React.useEffect(() => {
    dispatch(getAllJetpayCompanyConfigsListThunk({ companyId }));
    return () => {
      dispatch(resetJetpayState());
    };
  }, [companyId, dispatch]);

  const hasConfigs = configs.length > 0;

  React.useEffect(() => {
    if (hasConfigs && paymentProcessingPanel.jetpay.companyPaymentConfigId) {
      dispatch(selectJetpayConfig(paymentProcessingPanel.jetpay.companyPaymentConfigId));
    }
  }, [dispatch, paymentProcessingPanel.jetpay.companyPaymentConfigId, hasConfigs]);

  const configsOptions: Option[] = configs.map((config) => ({
    value: config.id,
    label: `${config.options.name}`,
  }));

  return (
    <>
      <FormControl label='Payment Configuration'>
        <SelectFilter
          placeholder='Select a Configuration'
          value={mapSelectOption(configsOptions, config?.id)}
          options={configsOptions}
          loading={isLoading || isProcessing}
          onChange={(option: Option) => {
            updateLocationParam(
              option.value.toString(),
              LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_COMPANY_PAYMENT_CONFIG_ID
            );
          }}
        />
      </FormControl>

      <FormControl label='Company Number'>
        {config.options.companyNumber ? <span>{config.options.companyNumber}</span> : <MissingValue />}
      </FormControl>

      <FormControl label='Store Number'>
        <TextInput
          type='number'
          placeholder='Enter Store Number'
          loading={isProcessing}
          value={paymentProcessingPanel.jetpay.storeNumber}
          onChange={(event) => {
            updateLocationParam(toNumber(event.target.value), LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_STORE_NUMBER);
          }}
        />
      </FormControl>

      <FormControl label='Payment Methods'>
        <Checkbox
          label='Credit Card'
          checked={paymentProcessingPanel.jetpay.paymentMethod.creditCard}
          onChange={(event) => {
            updateLocationParam(
              event.target.checked,
              LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_PAYMENT_METHOD_CREDIT_CARD
            );
          }}
          loading={isProcessing}
        />
        <div className='control--stacked'>
          <Checkbox
            label='Apple Pay'
            checked={paymentProcessingPanel.jetpay.paymentMethod.applePay}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_PAYMENT_METHOD_APPLE_PAY
              );
            }}
            loading={isProcessing}
          />
        </div>
      </FormControl>
      <>
        <FormControl label='Apple Pay Settings'>
          <FormControlStacked>
            <TextInputNestedLabel
              label='Merchant ID'
              loading={isProcessing}
              value={paymentProcessingPanel.jetpay.applePay.merchantId}
              onChange={(event) =>
                updateLocationParam(
                  event.target.value,
                  LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_APPLE_PAY_SETTINGS_MERCHANT_ID
                )
              }
            />
          </FormControlStacked>
          <FormControlStacked>
            <Checkbox
              label='Supports 3DS'
              loading={isProcessing}
              checked={paymentProcessingPanel.jetpay.applePay.supports3DS}
              onChange={(event) =>
                updateLocationParam(
                  event.target.checked,
                  LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_APPLE_PAY_SETTINGS_SUPPORT_3DS
                )
              }
            />
          </FormControlStacked>
          <FormControlStacked>
            <Checkbox
              label='Supports Debit'
              loading={isProcessing}
              checked={paymentProcessingPanel.jetpay.applePay.supportsDebit}
              onChange={(event) =>
                updateLocationParam(
                  event.target.checked,
                  LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_APPLE_PAY_SETTINGS_SUPPORT_DEBIT
                )
              }
            />
          </FormControlStacked>
          <FormControlStacked>
            <Checkbox
              label='Supports Credit'
              loading={isProcessing}
              checked={paymentProcessingPanel.jetpay.applePay.supportsCredit}
              onChange={(event) =>
                updateLocationParam(
                  event.target.checked,
                  LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_APPLE_PAY_SETTINGS_SUPPORT_CREDIT
                )
              }
            />
          </FormControlStacked>
        </FormControl>

        <FormControl label='Apple Pay Network'>
          <div className='control--stacked'>
            <Checkbox
              label='Mastercard'
              checked={paymentProcessingPanel.jetpay.appleGoogleNetwork.mastercard}
              onChange={(event) => {
                updateLocationParam(
                  event.target.checked,
                  LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_APPLE_GOOGLE_NETWORK_MASTERCARD
                );
              }}
              loading={isProcessing}
            />
          </div>
          <div className='control--stacked'>
            <Checkbox
              label='Visa'
              checked={paymentProcessingPanel.jetpay.appleGoogleNetwork.visa}
              onChange={(event) => {
                updateLocationParam(
                  event.target.checked,
                  LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_APPLE_GOOGLE_NETWORK_VISA
                );
              }}
              loading={isProcessing}
            />
          </div>
          <div className='control--stacked'>
            <Checkbox
              label='Amex'
              checked={paymentProcessingPanel.jetpay.appleGoogleNetwork.amex}
              onChange={(event) => {
                updateLocationParam(
                  event.target.checked,
                  LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_APPLE_GOOGLE_NETWORK_AMEX
                );
              }}
              loading={isProcessing}
            />
          </div>
          <div className='control--stacked'>
            <Checkbox
              label='Discover'
              checked={paymentProcessingPanel.jetpay.appleGoogleNetwork.discover}
              onChange={(event) => {
                updateLocationParam(
                  event.target.checked,
                  LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_APPLE_GOOGLE_NETWORK_DISCOVER
                );
              }}
              loading={isProcessing}
            />
          </div>
          <div className='control--stacked'>
            <Checkbox
              label='JCB'
              checked={paymentProcessingPanel.jetpay.appleGoogleNetwork.jcb}
              onChange={(event) => {
                updateLocationParam(
                  event.target.checked,
                  LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_APPLE_GOOGLE_NETWORK_JCB
                );
              }}
              loading={isProcessing}
            />
          </div>
        </FormControl>
      </>
      <FormControl label='Rate Limiting'>
        <FormControlStacked>
          <Toggle
            checked={paymentProcessingPanel.jetpay.rateLimits.enabled}
            onChange={(checked) => {
              updateLocationParam(
                checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_RATE_LIMITING_TOGGLE
              );
            }}
            loading={isProcessing}
          />
        </FormControlStacked>
        <FormControlStacked>
          <Checkbox
            disabled={true}
            checked={true}
            label='Enforce rate limits on manual card payments'
            loading={isProcessing}
          />
        </FormControlStacked>
        <FormControlStacked>
          <Checkbox
            disabled={!paymentProcessingPanel.jetpay.rateLimits.enabled}
            checked={paymentProcessingPanel.jetpay.rateLimits.enforceRateLimitsForGooglePay}
            label='Enforce rate limits on Google Pay transactions'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_RATE_LIMITING_FOR_GOOGLE_PAY
              );
            }}
            loading={isProcessing}
          />
        </FormControlStacked>
        <FormControlStacked>
          <FormattedInput
            format='money'
            disabled={!paymentProcessingPanel.jetpay.rateLimits.enabled}
            value={paymentProcessingPanel.jetpay.rateLimits.amount}
            onChange={(event) => {
              updateLocationParam(
                toNumber(event.target.value),
                LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_RATE_LIMITING_AMOUNT
              );
            }}
            loading={isProcessing}
          />
          <span className='form__control__separator'>Per</span>
          <FormattedInput
            format='min'
            disabled={!paymentProcessingPanel.jetpay.rateLimits.enabled}
            value={paymentProcessingPanel.jetpay.rateLimits.duration / 60}
            onChange={(event) => {
              updateLocationParam(
                toNumber(event.target.value) * 60,
                LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_RATE_LIMITING_TIME_INTERVAL
              );
            }}
            loading={isProcessing}
          />
        </FormControlStacked>
      </FormControl>

      <FormControl label='Additional Settings'>
        <Checkbox
          label='Allow small transactions'
          checked={paymentProcessingPanel.jetpay.allowSmallTransactions}
          onChange={(event) => {
            updateLocationParam(event.target.checked, LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_SMALL_TRANSACTIONS);
          }}
          loading={isProcessing}
        />
      </FormControl>
    </>
  );
};

export default JetPayPP;
