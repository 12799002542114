import React from 'react';
import { DateFilterRestrictable } from '../../../../components/DateFilter/DateFilter';
import SearchSelectFilter from '../../../../components/SearchSelectFilter/SearchSelectFilter';
import { DatePickerValue } from '../../../../components/DatePicker/DatePicker';
import styles from './ToolBar.module.scss';
import { maxDaysToChoose } from '../../feedbackReportSettings';
import { checkFeatureToggle } from '../../../../utils/featureToggle/featureToggle';

interface ToolBarProps {
  locations: Array<{ name: string; _id: string }>;
  initDateFilter: DatePickerValue;
}

const dataSources = [
  { value: 'mongo', label: 'Default (Mongo)'},
  { value: 'pgRaw', label: 'Reporting DB (raw)'},
]
const showDataSource = checkFeatureToggle('app_chooseReportDataSource');

export const ToolBar = ({ locations, initDateFilter }: ToolBarProps) => {
  const options = locations?.map(({ name, _id }) => ({ value: _id, label: name })) || [];

  return (
    <div className={styles.toolBar}>
      <div>
        <h4>Date Range</h4>
        <DateFilterRestrictable initialValue={initDateFilter} maxDays={maxDaysToChoose} />
      </div>
      <div>
        <h4>Location</h4>
        <SearchSelectFilter placeholder='Select a location' options={options} isMulti name='locations' isSearchable />
      </div>

      {/* TEMPORARY data source selector, will be deleted after switching to reporting-DB */}
      {showDataSource ? (
        <div>
          <h4>Data Source</h4>
          <SearchSelectFilter options={dataSources} name='dataSource' defaultValue='mongo' />
        </div>
      ) : null}

    </div>
  );
};
