import { combineReducers } from 'redux';
import locationOrderSettingsReducer from './locationOrderSettingsReducer';
import orderDetailReducer from './orderDetailReducer';
import { orderListReducer } from './orderListReducer';
import { orderLocationsReducer } from './orderLocationsReducer';
import orderSmsConfigsReducer from './orderSmsConfigsReducer';

export const orderReducer = combineReducers({
  orderLocations: orderLocationsReducer,
  orderList: orderListReducer,
  orderDetail: orderDetailReducer,
  orderSmsConfigs: orderSmsConfigsReducer,
  orderSettings: locationOrderSettingsReducer,
});
