import React from 'react';
import Container from '../Container/Container';

export interface FormSubHeaderProps {
  label: string;
}

const FormSubHeader = (props: FormSubHeaderProps) => {
  const { label } = props;

  return (
    <Container additionalClassName='form-sub-header-container'>
      <h3>{label}</h3>
    </Container>
  );
};

export default FormSubHeader;
