export const SESSION_ACTIONS = {
  INITIALIZE_SESSION_BEGIN: 'INITIALIZE_SESSION_BEGIN',
  INITIALIZE_SESSION_SUCCESS: 'INITIALIZE_SESSION_SUCCESS',
  INITIALIZE_SESSION_ERROR: 'INITIALIZE_SESSION_ERROR',

  UPDATE_USER_SESSION: 'UPDATE_USER_SESSION',
  UPDATE_USER_SESSION_BEGIN: 'UPDATE_USER_SESSION_BEGIN',
  UPDATE_USER_SESSION_SUCCESS: 'UPDATE_USER_SESSION_SUCCESS',
  UPDATE_USER_SESSION_ERROR: 'UPDATE_USER_SESSION_ERROR',

  UPDATE_CONTEXT_BEGIN: 'UPDATE_CONTEXT_BEGIN',
  UPDATE_CONTEXT_SUCCESS: 'UPDATE_CONTEXT_SUCCESS',
  UPDATE_CONTEXT_ERROR: 'UPDATE_CONTEXT_ERROR',
  UPDATE_INVALID_CONTEXT: 'UPDATE_INVALID_CONTEXT',

  UPDATE_ENABLE_CONTEXT_SWITCHER: 'UPDATE_ENABLE_CONTEXT_SWITCHER',

  UPDATE_PERMISSIONS_LIST: 'UPDATE_PERMISSIONS_LIST',
  UPDATE_API_PERMISSIONS_REQUESTED: 'UPDATE_API_PERMISSIONS_REQUESTED',

  UPDATE_SIDEBAR_OPTIONS: 'UPDATE_SIDEBAR_OPTIONS',
  UPDATE_SIDEBAR_OPTIONS_SET: 'UPDATE_SIDEBAR_OPTIONS_SET',

  UPDATE_LAST_LOCATION: 'UPDATE_LAST_LOCATION',
  CLEAR_LAST_LOCATION: 'CLEAR_LAST_LOCATION',

  UPDATE_SHOW_COMPANY_SELECTION_PAGE: 'UPDATE_SHOW_COMPANY_SELECTION_PAGE',
};
