import {
  IMenuSection,
  ITemplateMenu,
  ITemplateMenuSection,
  Status,
  ITemplateMenuSectionRequest,
  ITemplateMenuRequest,
  ITemplateInnerItemGalleryRequest,
  ITemplateInnerItemGallery,
  IMenu,
  ITemplateMenuSectionItemRequest,
  ITemplateMenuSectionItem,
  ITemplateMenuSectionItemGroup,
  IMenuSectionItem,
  IMenuSectionItemGroup,
  ITemplateMenuItem,
  IMenuItem,
  IMenuItemGroup,
  ITemplateMenuItemGroup,
  Visibility,
  ForcedModifierStatus,
  IMenuItemGroupRequest,
  ITemplateMenuItemGroupRequest,
  ITemplateItemGallery,
  IItemGallery,
  IInnerItemGallery,
  IMenuSectionItemRequest,
  IMenuItemRequest,
  ITemplateMenuItemRequest,
  ITemplateItemGalleryRequest,
  ITemplateMenuSectionItemGallery,
  ITemplateMenuSectionItemGalleryRequest,
} from '@ready/menu.core';
import { INewTemplateMenuRequest } from './menu.service';

export const mapITemplateMenuResponseToCreateRequest = (menu: ITemplateMenu): INewTemplateMenuRequest => {
  return {
    displayName: menu.displayName,
    description: menu.description,
    sections: mapITemplateMenuSectionResponseToRequest(menu.sections),
    itemGallery: menu.itemGallery ? mapItemGalleryResponseToRequest(menu.itemGallery) : undefined,
  };
};

export const mapITemplateMenuResponseToUpdateRequest = (ui: ITemplateMenu): ITemplateMenuRequest => {
  return {
    ...mapITemplateMenuResponseToCreateRequest(ui),
    _id: ui._id,
  };
};

export const mapItemGalleryResponseToRequest = (
  itemGallery?: ITemplateInnerItemGallery
): ITemplateInnerItemGalleryRequest => {
  return {
    _id: itemGallery?._id ?? '',
    items: mapToMenuSectionItemGalleryItemsRequest(itemGallery?.items),
    displayName: itemGallery?.displayName ?? '',
    description: itemGallery?.description,
  };
};

export const mapSectionItemGalleryResponseToRequest = (
  itemGallery: ITemplateMenuSectionItemGallery
): ITemplateMenuSectionItemGalleryRequest => {
  return {
    _id: itemGallery?._id ?? '',
    items: mapToMenuSectionItemGalleryItemsRequest(itemGallery?.items),
    displayName: itemGallery?.displayName ?? '',
    status: itemGallery?.status,
    description: itemGallery?.description,
  };
};

export const mapITemplateMenuSectionResponseToRequest = (
  sections: ITemplateMenuSection[] = []
): ITemplateMenuSectionRequest[] => {
  if (sections.length === 0) return [];

  return sections.map((section: ITemplateMenuSection) => ({
    displayName: section.displayName,
    description: section.description,
    defaultAppearance: section.defaultAppearance,
    items: mapITemplateMenuSectionItemOrItemGroupResponseToRequest(section.items),
    sortOrder: section.sortOrder,
    uid: section.uid,
    itemGallery: section.itemGallery && mapSectionItemGalleryResponseToRequest(section.itemGallery),
  }));
};

export const mapITemplateMenuSectionToIMenuSection = (menuSections: ITemplateMenuSection[] = []): IMenuSection[] => {
  if (menuSections.length === 0) return [];

  return menuSections.map((section) => ({
    sortOrder: section.sortOrder,
    status: Status.enabled,
    displayName: section.displayName,
    description: section.description,
    defaultAppearance: section.defaultAppearance,
    uid: section.uid,
    itemGallery: section.itemGallery && mapITemplateMenuSectionGalleryToIMenuSectionItemGallery(section.itemGallery),
    items: mapITemplateMenuSectionItemOrGroupToIMenuSectionItemOrGroup(section.items),
  }));
};

export const mapITemplateMenuSectionItemToIMenuSectionItem = (item: ITemplateMenuSectionItem): IMenuSectionItem => {
  return {
    sectionItemType: 'item',
    itemId: item.itemId,
    sortOrder: item.sortOrder,
    visibility: item.visibility,
    displayName: item.displayName,
    alcohol: item.alcohol,
    imageId: item.imageId,
    imageHeight: item.imageHeight,
    imageWidth: item.imageWidth,
    thumbnailImageId: item.thumbnailImageId,
    thumbnailImageHeight: item.thumbnailImageHeight,
    thumbnailImageWidth: item.thumbnailImageWidth,
    description: item.description,
    modifierGroups: item.modifierGroups,
    recommendedItems: item.recommendedItems,

    forcedModifiers: item.forcedModifiers.map((fm) => ({
      itemId: fm.itemId,
      displayName: fm.displayName,
      status: Status.enabled,
      itemType: 'item',
      inStock: true,
      inactiveStates: [],
    })),
    tags: item.tags,
    badge: item.badge,
    posItemName: '',
    posItemCategories: [],
    inStock: true,
    inactiveStates: [],
    forcedModifierStatus: ForcedModifierStatus.valid,
    status: Status.enabled,
    itemType: 'item',
  };
};
export const mapITemplateMenuSectionItemGroupToIMenuSectionItemGroup = (
  item: ITemplateMenuSectionItemGroup
): IMenuSectionItemGroup => {
  return {
    sectionItemType: 'group',
    itemId: item.itemId,
    sortOrder: item.sortOrder,
    visibility: item.visibility,
    displayName: item.displayName,
    items: mapITemplateMenuSectionItemOrGroupToIMenuSectionItemOrGroup(item.items) as IMenuSectionItem[],
    description: item.description,
    thumbnailImageHeight: item.thumbnailImageHeight,
    thumbnailImageWidth: item.thumbnailImageWidth,
    thumbnailImageId: item.thumbnailImageId,
    imageHeight: item.imageHeight,
    imageWidth: item.imageWidth,
    imageId: item.imageId,
  };
};

export const mapITemplateMenuSectionItemOrGroupToIMenuSectionItemOrGroup = (
  items: (ITemplateMenuSectionItem | ITemplateMenuSectionItemGroup)[] = []
): (IMenuSectionItem | IMenuSectionItemGroup)[] => {
  if (items.length === 0) return [];

  let results: (IMenuSectionItem | IMenuSectionItemGroup)[] = [];

  items.forEach((item) => {
    if (item.sectionItemType === 'item') {
      results.push(mapITemplateMenuSectionItemToIMenuSectionItem(item));
    } else {
      results.push(mapITemplateMenuSectionItemGroupToIMenuSectionItemGroup(item));
    }
  });

  return results;
};

const mapIMenuSectionItemToITemplateMenuSectionItem = (
  item: IMenuSectionItem,
  sortOrder?: number
): ITemplateMenuSectionItem => ({
  itemId: item.itemId,
  displayName: item.displayName,
  itemType: item.itemType,
  sectionItemType: item.sectionItemType,
  sortOrder: sortOrder ?? item.sortOrder,
  visibility: item.visibility,
  thumbnailImageHeight: item.thumbnailImageHeight,
  thumbnailImageId: item.thumbnailImageId,
  thumbnailImageWidth: item.thumbnailImageWidth,
  imageHeight: item.imageHeight,
  imageId: item.imageId,
  imageWidth: item.imageWidth,
  tags: item.tags,
  badge: item.badge,
  description: item.description,
  alcohol: item.alcohol,
  modifierGroups: [],
  forcedModifiers: [],
  recommendedItems: [],
});

const mapIMenuSectionItemsToITemplateMenuSectionItems = (
  items: IMenuSectionItem[] = []
): ITemplateMenuSectionItem[] => {
  if (items.length === 0) return [];

  return items.map((item) => ({
    ...mapIMenuSectionItemToITemplateMenuSectionItem(item),
  }));
};

const mapIMenuSectionItemGroupToITemplateMenuSectionItemGroup = (
  item: IMenuSectionItemGroup,
  sortOrder?: number
): ITemplateMenuSectionItemGroup => {
  return {
    itemId: item.itemId,
    sortOrder: sortOrder ?? item.sortOrder,
    visibility: item.visibility,
    displayName: item.displayName,
    sectionItemType: 'group',
    items: mapIMenuSectionItemsToITemplateMenuSectionItems(item.items),
    thumbnailImageHeight: item.thumbnailImageHeight,
    thumbnailImageId: item.thumbnailImageId,
    thumbnailImageWidth: item.thumbnailImageWidth,
    imageHeight: item.imageHeight,
    imageId: item.imageId,
    imageWidth: item.imageWidth,
  };
};

const mapIMenuSectionItemOrGroupToITemplateMenuSectionItemOrGroup = (
  items: (IMenuSectionItem | IMenuSectionItemGroup)[] = []
): (ITemplateMenuSectionItem | ITemplateMenuSectionItemGroup)[] => {
  if (items.length === 0) return [];
  let results: (ITemplateMenuSectionItem | ITemplateMenuSectionItemGroup)[] = [];

  items.forEach((item, index) => {
    if (item.sectionItemType === 'item') {
      results.push(mapIMenuSectionItemToITemplateMenuSectionItem(item, index));
    } else {
      results.push(mapIMenuSectionItemGroupToITemplateMenuSectionItemGroup(item, index));
    }
  });
  return results;
};

const mapITemplateMenuSectionItemOrItemGroupToRequest = (
  item: ITemplateMenuSectionItem
): ITemplateMenuSectionItemRequest => ({
  sectionItemType: item.sectionItemType,
  itemId: item.itemId,
  sortOrder: item.sortOrder,
  visibility: item.visibility,
});

const mapITemplateMenuSectionItem = (items: ITemplateMenuSectionItem[] = []): ITemplateMenuSectionItem[] => {
  if (items.length === 0) return [];

  return items.map((item) => ({
    sectionItemType: item.sectionItemType,
    itemId: item.itemId,
    sortOrder: item.sortOrder,
    visibility: item.visibility,
    displayName: item.displayName,
    modifierGroups: item.modifierGroups,
    forcedModifiers: item.forcedModifiers,
    recommendedItems: item.recommendedItems,
    itemType: item.itemType,
  }));
};

const mapITemplateMenuSectionItemGroupResponseToRequest = (
  itemGroup: ITemplateMenuSectionItemGroup
): ITemplateMenuSectionItemGroup => ({
  itemId: itemGroup.itemId,
  sortOrder: itemGroup.sortOrder,
  displayName: itemGroup.displayName,
  items: mapITemplateMenuSectionItem(itemGroup.items),
  visibility: Visibility.visible,
  sectionItemType: 'group',
});

export const mapITemplateMenuSectionItemOrItemGroupResponseToRequest = (
  items: (ITemplateMenuSectionItem | ITemplateMenuSectionItemGroup)[] = []
): (ITemplateMenuSectionItemRequest | ITemplateMenuSectionItemGroup)[] => {
  if (items.length === 0) return [];

  let results: ITemplateMenuSectionItemRequest[] = [];

  items.forEach((item) => {
    if (item.sectionItemType === 'item') {
      results.push(mapITemplateMenuSectionItemOrItemGroupToRequest(item));
    } else {
      results.push(mapITemplateMenuSectionItemGroupResponseToRequest(item));
    }
  });
  return results;
};

export const mapITemplateMenuGalleryToIMenuItemGallery = (
  itemGallery: ITemplateInnerItemGallery
): IInnerItemGallery => {
  return {
    _id: itemGallery?._id,
    status: Status.enabled,
    items: mapITemplateMenuSectionItemOrGroupToIMenuSectionItemOrGroup(itemGallery.items),
    description: itemGallery?.description,
    displayName: itemGallery?.displayName,
  };
};

export const mapITemplateMenuSectionGalleryToIMenuSectionItemGallery = (
  itemGallery: ITemplateMenuSectionItemGallery
): IInnerItemGallery => {
  return {
    _id: itemGallery?._id,
    status: itemGallery.status,
    items: mapITemplateMenuSectionItemOrGroupToIMenuSectionItemOrGroup(itemGallery.items),
    description: itemGallery?.description,
    displayName: itemGallery?.displayName,
  };
};

export const mapITemplateMenuToIMenu = (menu: ITemplateMenu): IMenu => {
  return {
    _id: menu._id,
    displayName: menu.displayName,
    description: menu?.description,
    status: Status.enabled,
    sections: mapITemplateMenuSectionToIMenuSection(menu.sections),
    itemGallery: mapITemplateMenuGalleryToIMenuItemGallery(menu?.itemGallery!),
    sortOrder: 0,
  };
};

export const mapITemplateItemGalleryToIItemGallery = (itemGallery: ITemplateItemGallery): IItemGallery => {
  const items = mapITemplateMenuSectionItemOrGroupToIMenuSectionItemOrGroup(itemGallery.items);
  const { displayName, description, _id } = itemGallery;
  return {
    displayName,
    description,
    _id,
    status: Status.enabled,
    items,
    sortOrder: 0,
  };
};

export const mapIMenuSectionToITemplateMenuSection = (menuSection: IMenuSection): ITemplateMenuSection => ({
  defaultAppearance: menuSection.defaultAppearance,
  displayName: menuSection.displayName,
  description: menuSection.description,
  items: mapIMenuSectionItemOrGroupToITemplateMenuSectionItemOrGroup(menuSection.items),
  uid: menuSection.uid,
  sortOrder: menuSection.sortOrder,
  itemGallery: mapIInnerItemGalleryToITemplateInnerItemGallery(menuSection.itemGallery!),
});

export const mapIMenuSectionsToITemplateMenuSections = (menuSections: IMenuSection[] = []): ITemplateMenuSection[] => {
  if (menuSections.length === 0) return [];

  return menuSections.map((section) => ({
    ...mapIMenuSectionToITemplateMenuSection(section),
  }));
};

export const mapIMenuToITemplateMenu = (menu: IMenu): ITemplateMenu => {
  return {
    _id: menu._id,
    displayName: menu.displayName,
    description: menu?.description,
    sections: mapIMenuSectionsToITemplateMenuSections(menu.sections),
    itemGallery: mapIMenuItemGalleryToITemplateMenuGallery(menu.itemGallery),
  };
};

export const mapIMenuItemGalleryToITemplateMenuGallery = (
  itemGallery: IInnerItemGallery | undefined
): ITemplateInnerItemGallery => {
  return {
    _id: itemGallery?._id ?? '',
    items: mapIMenuSectionItemOrGroupToITemplateMenuSectionItemOrGroup(itemGallery?.items),
    description: itemGallery?.description,
    displayName: itemGallery?.displayName,
  };
};

export const mapITemplateMenuItemToIMenuItem = (items: ITemplateMenuItem[] = []): IMenuItem[] => {
  if (items.length === 0) return [];

  return items.map((item) => ({
    _id: item._id,
    displayName: item.displayName,
    description: item.description,
    alcohol: item.alcohol,
    posItemName: '',
    posItemCategories: [],
    inStock: false,
    modifierGroups: [],
    inactiveStates: [],
    forcedModifierStatus: ForcedModifierStatus.valid,
    recommendedItems: [],
    status: Status.enabled,
    itemType: 'item',
    forcedModifiers: [],
  }));
};

export const mapIMenuItemToITemplateMenuItem = (item: IMenuItemRequest): ITemplateMenuItemRequest => ({
  _id: item._id,
  displayName: item.displayName,
  description: item.description,
  modifierGroups: item.modifierGroups,
  forcedModifiers: item.forcedModifiers,
  recommendedItems: item.recommendedItems,
  itemType: item.itemType,
  thumbnailImageHeight: item.thumbnailImageHeight,
  thumbnailImageWidth: item.thumbnailImageWidth,
  thumbnailImageId: item.thumbnailImageId,
  imageHeight: item.imageHeight,
  imageWidth: item.imageWidth,
  imageId: item.imageId,
  tags: item.tags,
  alcohol: item.alcohol,
  badge: item.badge,
});

export const mapITemplateMenuItemGroupToIMenuItemGroup = (groups: ITemplateMenuItemGroup[] = []): IMenuItemGroup[] => {
  if (groups.length === 0) return [];

  return groups.map((group) => ({
    _id: group._id,
    displayName: group.displayName,
    description: group.description,
    items: group.items.map((item) => ({
      itemId: item.itemId,
      displayName: item.displayName,
      alcohol: item.alcohol,
      modifierGroups: [],
      forcedModifiers: [],
      posItemCategories: [],
      inactiveStates: [],
      forcedModifierStatus: ForcedModifierStatus.valid,
      visibility: Visibility.visible,
      sortOrder: 0,
      posItemName: '',
      itemType: 'item',
      status: Status.enabled,
      inStock: false,
    })),
  }));
};

export const mapIMenuItemGroupRequestToITemplateMenuItemGroupRequest = (
  itemGroup: IMenuItemGroupRequest
): ITemplateMenuItemGroupRequest => {
  return {
    _id: itemGroup._id,
    displayName: itemGroup.displayName,
    description: itemGroup.description,
    items: itemGroup.items,
    thumbnailImageHeight: itemGroup.thumbnailImageHeight,
    thumbnailImageWidth: itemGroup.thumbnailImageWidth,
    thumbnailImageId: itemGroup.thumbnailImageId,
    imageHeight: itemGroup.imageHeight,
    imageWidth: itemGroup.imageWidth,
    imageId: itemGroup.imageId,
  };
};

export const mapIInnerItemGalleryToITemplateInnerItemGallery = (
  sectionItemGallery: IInnerItemGallery
): ITemplateMenuSectionItemGallery => ({
  _id: sectionItemGallery._id,
  displayName: sectionItemGallery.displayName,
  description: sectionItemGallery.description,
  status: sectionItemGallery.status,
  items: mapIMenuSectionItemOrGroupToITemplateMenuSectionItemOrGroup(sectionItemGallery.items),
});

const mapToMenuSectionItemGalleryItemsRequest = (
  items: (ITemplateMenuSectionItem | ITemplateMenuSectionItemGroup)[] = []
): IMenuSectionItemRequest[] => {
  if (items.length === 0) return [];
  return items.map((item) => ({
    itemId: item.itemId,
    sectionItemType: item.sectionItemType,
    sortOrder: item.sortOrder,
    visibility: item.visibility,
  }));
};

export const mapToCreateEmptyItemGroup = (itemGroup: ITemplateMenuItemGroup | IMenuItemGroup): IMenuItemGroup => ({
  _id: itemGroup._id,
  displayName: itemGroup.displayName,
  description: itemGroup.description,
  items: [],
});
export const mapITemplateItemGalleryToRequest = (itemGallery: ITemplateItemGallery): ITemplateItemGalleryRequest => {
  const items = mapToMenuSectionItemGalleryItemsRequest(itemGallery.items);
  const { displayName, description, _id } = itemGallery;

  return {
    displayName,
    items,
    description,
    _id,
    sortOrder: 0,
  };
};

export const mapIItemGalleryToITemplateItemGallery = (itemGallery: IItemGallery): ITemplateItemGallery => {
  const items = mapIMenuSectionItemOrGroupToITemplateMenuSectionItemOrGroup(itemGallery.items);
  const { displayName, description, _id } = itemGallery;

  return {
    displayName,
    description,

    items,
    _id,
  };
};
