import React from 'react';
import classNames from 'classnames';

export interface ModalBodyProps {
  withSeparator?: boolean;
  children: React.ReactNode;
  additionalStyles?: string;
}

const ModalBody = (props: ModalBodyProps) => {
  const { withSeparator = false, children, additionalStyles } = props;

  const className = classNames('modal__body', { 'modal__body--with-separator': withSeparator }, additionalStyles);

  return <div className={className}>{children}</div>;
};

export default ModalBody;
