import React from 'react';
import TextArea, { TextAreaProps } from '../TextArea/TextArea';
import NestedLabel from '../NestedLabel/NestedLabel';

interface TextAreaNestedLabelProps extends TextAreaProps {
  label: string;
}

const TextAreaNestedLabel = (props: TextAreaNestedLabelProps): JSX.Element => {
  const {
    value,
    onChange,
    placeholder,
    withError,
    disabled,
    loading,
    additionalStyles,
    name,
    maxLength,
    errorMessage,
    label,
  } = props;

  return (
    <NestedLabel label={label} value={value} loading={loading} withError={withError}>
      <TextArea
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        withError={withError}
        disabled={disabled}
        loading={loading}
        additionalStyles={additionalStyles}
        name={name}
        maxLength={maxLength}
        errorMessage={errorMessage}
      />
    </NestedLabel>
  );
};

export default TextAreaNestedLabel;
