import React from 'react';
import Checkbox from '../../components/Checkbox/Checkbox';
import styles from './PermissionItem.module.scss';

export interface PermissionItemProps {
  label: string;
  description: string;
  isActive: boolean;
  onChange: (operation: 'add' | 'remove') => void;
}

const PermissionItem: React.FC<PermissionItemProps> = (props: PermissionItemProps) => {
  const { label, description, isActive, onChange } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      onChange('add');
    } else {
      onChange('remove');
    }
  };

  return (
    <div className={styles.permissionItem}>
      <div className={styles.permissionItemCheck}>
        <Checkbox label='' checked={isActive} onChange={handleChange} />
      </div>
      <div className={styles.permissionItemDescription}>
        <h5 className={styles.permissionTitle}>{label}</h5>
        <p className={styles.permissionDescription}>{description}</p>
      </div>
    </div>
  );
};

export default PermissionItem;
