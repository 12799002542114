import { IBadge, ITag } from '@ready/menu.core';

export const newEntityValidation = (): EntityValidation => ({
  tagName: {
    hasError: false,
    notUnique: false,
    errorMessage: '',
  },
  tagShortCode: {
    notUnique: false,
    hasError: false,
    errorMessage: '',
  },
});

export const newTagForm = (editing?: boolean, tag?: ITag): ITagForm => ({
  loading: false,
  editing: !!editing,
  processing: false,
  saved: false,
  tag: tag || newTag(),
  tagList: [],
  validation: newEntityValidation(),
});

export const newTag = (): ITag => ({
  _id: '',
  name: '',
  shortCode: '',
});

export interface ITagForm {
  loading: boolean;
  editing: boolean;
  processing: boolean;
  tag: ITag;
  tagList: ITag[];
  saved: boolean;
  validation: EntityValidation;
}

export const newBadge = (): IBadge => ({
  _id: '',
  name: '',
  text: '',
  textColor: '#FFFFFF',
  backgroundColor: '#000000',
});

export const newBadgeForm = (editing?: boolean, badge?: IBadge): IBadgeForm => ({
  loading: false,
  editing: !!editing,
  processing: false,
  saved: false,
  badge: badge || newBadge(),
  validation: newBadgeFormValidaton(),
});

export interface IBadgeForm {
  loading: boolean;
  editing: boolean;
  processing: boolean;
  saved: boolean;
  badge: IBadge;
  validation: BadgeFormValidation;
}

export interface BadgeFormValidation {
  badgeName: {
    hasError: boolean;
    errorMessage: string;
  };
  badgeText: {
    hasError: boolean;
    errorMessage: string;
  };
}

export const newBadgeFormValidaton = (): BadgeFormValidation => ({
  badgeName: {
    hasError: false,
    errorMessage: '',
  },
  badgeText: {
    hasError: false,
    errorMessage: '',
  },
});

export interface TagsBadgesListState {
  tags: Array<ITag>;
  badges: Array<IBadge>;
  tagForm: ITagForm;
  badgeForm: IBadgeForm;
  loading: boolean;
  deleteRequested: boolean;
  tagToDelete?: ITag;
  deletedTag?: ITag;
  deleteProcessing: boolean;
  deleteError: boolean;
  deleteBadgeRequested: boolean;
  badgeToDelete?: IBadge;
  deletedBadge?: IBadge;
  deleteBadgeProcessing: boolean;
  deleteBadgeError: boolean;
}

export const initialTagsBadgesListState: TagsBadgesListState = {
  tags: [],
  badges: [],
  loading: false,
  tagForm: newTagForm(),
  badgeForm: newBadgeForm(),
  deleteRequested: false,
  deleteProcessing: false,
  deleteError: false,
  deleteBadgeRequested: false,
  deleteBadgeProcessing: false,
  deleteBadgeError: false,
};

export default interface EntityValidation {
  tagName: {
    hasError: boolean;
    notUnique: boolean;
    errorMessage: string;
  };
  tagShortCode: {
    hasError: boolean;
    errorMessage: string;
    notUnique: boolean;
  };
}
