import { PaginationResponse } from '@ready/dashboardv2api.contracts';
import { IBulkEditItemGallery, IBulkEditItemGalleryRequest } from '@ready/menu.core';
import { IPagedResponse } from '@ready/table.core';
import config from 'config';
import executeApiAsync from 'services/utils/executeApiAsync';
import toPaginationResponse from 'services/utils/toPaginationResponse';
import { undefinedWithNullReplacer } from 'utils/objectUtils/replaceUndefinedWithNull';
import { objectToQueryParamsString } from 'utils/urlUtils/objectToQueryParamsString';

export const getLocationItemGalleries = async (
  companyId: string,
  itemGalleryId: string,
  page: number = 1,
  pageSize: number = 50,
  searchTerm?: string
): Promise<PaginationResponse<IBulkEditItemGallery>> => {
  const queryString = objectToQueryParamsString({
    pageNumber: page.toString(),
    pageSize: pageSize.toString(),
    lq: searchTerm,
  });
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/itemgalleries/${itemGalleryId}/locationItemGalleries?${queryString}`;
  const { results, totalCount } = await executeApiAsync<IPagedResponse<IBulkEditItemGallery>>(`${url}`, {
    method: 'GET',
  });

  return toPaginationResponse({ results, totalCount }, page, pageSize);
};

export const saveLocationItemGalleries = async (
  companyId: string,
  itemGalleryId: string,
  updates: Omit<IBulkEditItemGalleryRequest, 'templateItemGalleryId'>
): Promise<void> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/itemgalleries/${itemGalleryId}/locationItemGalleries`;

  return await executeApiAsync(url, {
    method: 'PUT',
    body: JSON.stringify(updates, undefinedWithNullReplacer),
  });
};
