import produce from 'immer';
import { Action } from '../../../redux/types';
import { LOCATION_SETTINGS_ACTIONS } from '../LocationSettingsActions';
import { gratuityPanel } from './state';

export const gratuityPanelReducer = (state = gratuityPanel, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case LOCATION_SETTINGS_ACTIONS.SET_GRATUITY_PANEL:
        draft = { ...action.payload };
        return draft;
      case LOCATION_SETTINGS_ACTIONS.TOGGLE_ALLOW_TIPS:
        draft.allowTips = action.payload;
        draft.isDirty = true;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_TIP_VALUE:
        const { tip_value, tip_index } = action.payload;
        draft.defaultTips[tip_index] = tip_value;
        draft.isDirty = true;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_SERVICE_CHARGE_TIP_VALUE:
        const { service_tip_value, service_tip_index } = action.payload;
        draft.defaultTipsForServiceCharges[service_tip_index] = service_tip_value;
        draft.isDirty = true;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_PRE_DISCOUNT_TIP:
        draft.preDiscountTip = action.payload;
        draft.isDirty = true;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_PRE_ITEM_DISCOUNT_TIP:
        draft.preItemDiscountTip = action.payload;
        draft.isDirty = true;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_PRE_TAX_TIP:
        draft.preTaxTip = action.payload;
        draft.isDirty = true;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_PRE_NON_READY_PAYMENTS_TIP:
        draft.preNonReadyPaymentsTip = action.payload;
        draft.isDirty = true;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.TOGGLE_SHOW_BOTTOM:
        draft.showBottomText = action.payload;
        draft.isDirty = true;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_BOTTOM_TEXT:
        draft.bottomText = action.payload;
        draft.isDirty = true;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_DEFAULT_CUSTOM_TIP:
        draft.defaultCustomTipType = action.payload;
        draft.isDirty = true;
        return draft;
      default:
        return draft;
    }
  });
};
