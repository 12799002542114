import ModalForm from 'components/ModalForm/ModalForm';
import React from 'react';
import Checkbox from 'components/Checkbox/Checkbox';
import { ModalProps } from 'components/Modal/Modal';
import TextInput from 'components/TextInput/TextInput';
import styles from './OnAccountPaymentOptionSettingsModal.module.scss';
import { Tooltip } from 'components/Tooltip/Tooltip';
import TextIcon, { Icon } from 'components/Icon/TextIcon';
import { useFormContext } from 'react-hook-form';
import { IOnAccountPaymentSettings, PosSystemType } from '@ready/dashboardv2api.contracts';
import { useAppDispatch, useAppSelector } from 'redux/store';
import InputError from 'components/InputError/InputError';
import { useParams } from 'react-router-dom';
import { ContextParams } from 'types/ContextParams.interface';
import {
  getOnAccountPaymentSettingsThunk,
  updateOnAccountPaymentSettingsThunk,
} from 'companyLocations/redux/reducers/onAccountPaymentSettings/thunk';

interface OnAccountPaymentOptionSettingsModalProps extends Pick<ModalProps, 'setShowModal'> {
  posSystemType?: PosSystemType;
  underlyingPosSystemType?: PosSystemType;
}

interface OnAccountPaymentOptionSettingsFormProps {
  showServiceChargeIdField: boolean;
}

interface OnAccountPaymentOptionSettingsFormState extends IOnAccountPaymentSettings {}

const OnAccountPaymentOptionSettingsForm = ({ showServiceChargeIdField }: OnAccountPaymentOptionSettingsFormProps) => {
  const {
    watch,
    setValue,
    register,
    formState: { errors },
  } = useFormContext<IOnAccountPaymentSettings>();
  const keepCheckOpenFormValue = watch('keepChecksOpen');

  return (
    <>
      <b>Check Closing Behaviour</b>
      <Checkbox
        {...register('keepChecksOpen')}
        label='Keep checks open in POS after submitting payment.'
        checked={keepCheckOpenFormValue ?? false}
        onChange={(event) => {
          setValue('keepChecksOpen', event.target.checked);
        }}
      />
      {keepCheckOpenFormValue && showServiceChargeIdField && (
        <div className={styles.indented}>
          <div className={styles.tooltipContainer}>
            <b className={styles.tooltipLabel}>Service Charge ID*</b>
            <Tooltip text='This service charge is used to send tips collected via Ready to your POS.  The service charge should be configured so that 100% of the charge goes to Tips Paid.'>
              <TextIcon icon={Icon.InfoCircle} />
            </Tooltip>
          </div>
          <TextInput
            {...register('tipServiceChargeId', {
              required: true,
            })}
            placeholder={'Enter Service Charge ID'}
            withError={Boolean(errors.tipServiceChargeId)}
          />
          {errors.tipServiceChargeId && <InputError message='Required field.' />}
        </div>
      )}
    </>
  );
};

export const OnAccountPaymentOptionSettingsModal = ({
  setShowModal,
  posSystemType,
  underlyingPosSystemType,
}: OnAccountPaymentOptionSettingsModalProps) => {
  const onAccountPaymentOptionsConfig = useAppSelector(
    (state) => state.paymentProcessing.onAccountPaymentSettings.settings
  );
  const { contextId: companyId, locationId } = useParams<ContextParams>();
  const dispatch = useAppDispatch();

  const isServiceChargeIdFieldVisible = (posSystemType?: PosSystemType, underlyingPosSystemType?: PosSystemType) => {
    const supportServiceChargeId: PosSystemType[] = [
      PosSystemType.simphony2,
      PosSystemType.micros3700,
      PosSystemType.microssimphony,
    ];

    const type = (posSystemType === PosSystemType.omnivore ? underlyingPosSystemType : posSystemType) as PosSystemType;
    return supportServiceChargeId.includes(type);
  };

  const showServiceChargeIdField = isServiceChargeIdFieldVisible(posSystemType, underlyingPosSystemType);

  const onSave = async (formState: OnAccountPaymentOptionSettingsFormState) => {
    const settings: IOnAccountPaymentSettings = { keepChecksOpen: formState.keepChecksOpen };

    if (formState.keepChecksOpen && showServiceChargeIdField) {
      settings.tipServiceChargeId = formState.tipServiceChargeId;
    }

    await dispatch(updateOnAccountPaymentSettingsThunk({ companyId, locationId, settings }));
    await dispatch(getOnAccountPaymentSettingsThunk({ companyId, locationId }));
    setShowModal(false);
  };

  return (
    <ModalForm
      headerLabel='On Account Payment Settings'
      onSave={onSave}
      setShowModal={setShowModal}
      defaultValues={onAccountPaymentOptionsConfig ?? { keepChecksOpen: false }}
      additionalBodyStyles={styles.modalBody}
    >
      <OnAccountPaymentOptionSettingsForm showServiceChargeIdField={showServiceChargeIdField} />
    </ModalForm>
  );
};
