import React from 'react';
import Button from '../../../components/Button/Button';
import styles from './DialogPickupTimeInput.module.scss';
import TimePicker from '../../../components/TimePicker/TimePicker';
import { parse, subMinutes, addMinutes, format } from 'date-fns';

interface DialogPickupTimeInputProps {
  value: Date;
  onChange: (value: Date) => void;
  hasError?: boolean;
  errorMessage?: string;
}

const DialogPickupTimeInput = ({
  value,
  onChange,
  hasError = false,
  errorMessage = '',
}: DialogPickupTimeInputProps): JSX.Element => {
  const handleSub5Min = () => {
    onChange(subMinutes(value, 5));
  };

  const handleAdd5Min = () => {
    onChange(addMinutes(value, 5));
  };

  const handleTimeChange = (newValue: string) => {
    onChange(timeStringToDate(newValue, value));
  };

  return (
    <div className={styles.container}>
      <h5 className={styles.header}>Pick Up Time</h5>
      <div className={styles.inputContainer}>
        <Button label='- 5 min' variant='secondary-gray-bg' onClick={handleSub5Min} />
        <div className={styles.timeInputContainer}>
          <TimePicker
            value={dateToTimeString(value)}
            onChange={handleTimeChange}
            showSeconds={false}
            darkBorder
            containerPadding='small'
            hasError={hasError}
          />
          {hasError && <p className={styles.errorMessage}>{errorMessage}</p>}
        </div>
        <Button label='+ 5 min' variant='secondary-gray-bg' onClick={handleAdd5Min} />
      </div>
    </div>
  );
};

const dateToTimeString = (value: Date) => format(value, 'HH:mm:ss');
const timeStringToDate = (value: string, referenceDate: Date) => parse(value, 'HH:mm:ss', referenceDate);

export default DialogPickupTimeInput;
