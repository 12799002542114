const timeFormat = (
  value: any,
  mask: ' ' | '_' = ' ',
  is24HourTime: boolean = false,
  showTimeSeconds: boolean = false,
  wideTimeSpacing: boolean = false
) => {
  let [hour_1 = mask, hour_2 = mask, minute_1 = mask, minute_2 = mask, second_1 = mask, second_2 = mask] = value;

  if (is24HourTime) {
    // eslint-disable-next-line
    if (hour_1 == 2 && hour_2 > 3) {
      minute_1 = hour_2;
      hour_2 = hour_1;
      hour_1 = 0;
    }

    if (hour_1 > 2) {
      hour_2 = hour_1;
      hour_1 = 0;
    }
  } else {
    // eslint-disable-next-line
    if (hour_1 == 1 && hour_2 > 2) {
      minute_1 = hour_2;
      hour_2 = hour_1;
      hour_1 = 0;
    }

    // eslint-disable-next-line
    if (hour_1 == 0 && hour_2 == 0) {
      hour_1 = 1;
      hour_2 = 2;
    }

    if (hour_1 > 1) {
      hour_2 = hour_1;
      hour_1 = 0;
    }
  }

  if (minute_1 > 5) {
    minute_1 = 0;
  }

  if (second_1 > 5) {
    second_2 = second_1;
    second_1 = 0;
  }

  return showTimeSeconds
    ? `${hour_1}${hour_2}${wideTimeSpacing ? ' ' : ''}:${wideTimeSpacing ? ' ' : ''}${minute_1}${minute_2}${
        wideTimeSpacing ? ' ' : ''
      }:${wideTimeSpacing ? ' ' : ''}${second_1}${second_2}`
    : `${hour_1}${hour_2}${wideTimeSpacing ? ' ' : ''}:${wideTimeSpacing ? ' ' : ''}${minute_1}${minute_2}`;
};

export default timeFormat;
