import React, { ReactNode } from 'react';
import Container from '../Container/Container';

export interface FormGroupProps {
  groupName: string;
  children: ReactNode;
}

const FormGroup = (props: FormGroupProps) => {
  const { groupName, children } = props;

  return (
    <div className='form__group'>
      <div className='form__group__name'>
        <Container additionalClassName='from__group__name__container'>
          <h4>{groupName}</h4>
        </Container>
      </div>
      <div className='form__panel'>{children}</div>
    </div>
  );
};

export default FormGroup;
