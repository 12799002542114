import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { RaygunService } from './services/monitoring/raygunService';
import { GoogleAnalyticsService } from './services/monitoring/googleAnalyticsService';
import { SentryService } from 'services/monitoring/sentry.service';

import './styles/normalize.scss';

RaygunService.initRaygun();
SentryService.initialize();
GoogleAnalyticsService.initialize();
ReactDOM.render(<App />, document.getElementById('root'));
