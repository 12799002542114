import React from 'react';
import { Appearance } from '@ready/menu.core';
import styles from './SectionControls.module.scss';
import Button from '../../../components/Button/Button';
import OverflowButton from '../../../components/OverflowButton/OverflowButton';
import DefaultAppearanceIcon from './DefaultAppearanceIcon';

export interface SectionControlsProps {
  editInOverflow: boolean;
  defaultAppearance: Appearance;
  changeStatusLabel: string;
  disabled: boolean;
  onEditClick: () => void;
  onDeleteClick: () => void;
  onChangeStatusClick: () => void;
  isSharedMenu?: boolean;
  isSharedMenuAssignedToCurrentLocation?: boolean;
}

const SectionControls = ({
  editInOverflow,
  changeStatusLabel,
  defaultAppearance,
  disabled,
  onEditClick,
  onDeleteClick,
  onChangeStatusClick,
  isSharedMenu = false,
  isSharedMenuAssignedToCurrentLocation = false,
}: SectionControlsProps): JSX.Element => (
  <div className={styles.sectionControls}>
    <DefaultAppearanceIcon defaultAppearance={defaultAppearance} />

    {!editInOverflow && (
      <div className={styles.button}>
        <Button label='Edit' variant='secondary' unavailable={disabled} onClick={onEditClick} />
      </div>
    )}
    <div className={styles.sectionOverflow}>
      <OverflowButton
        options={[
          {
            label: 'Edit',
            hidden: !editInOverflow,
            disabled: disabled,
            onClick: onEditClick,
          },
          {
            label: changeStatusLabel,
            disabled: disabled,
            onClick: onChangeStatusClick,
            hidden: !!isSharedMenu,
          },
          {
            label: 'Delete Section',
            primary: true,
            disabled: disabled,
            onClick: onDeleteClick,
            hidden: isSharedMenuAssignedToCurrentLocation,
          },
        ]}
      />
    </div>
  </div>
);

export default SectionControls;
