import { FormControl, FormControlStacked } from 'components/Form';
import { IPaymentProcessingPanel } from 'locations/redux/PanelPaymentProcessingState';
import { IUpdateLocationParam } from '../LocationsEditPage';
import { LIST_OPTIONS } from 'utils/selectListUtils/listOptions';
import { LOCATION_SETTINGS_ACTIONS } from 'locations/redux/LocationSettingsActions';
import { toNumber } from 'lodash';
import Checkbox from 'components/Checkbox/Checkbox';
import FormattedInput from 'components/FormattedInput/FormattedInput';
import mapSelectOption from 'utils/selectListUtils/mapSelectOption';
import React from 'react';
import SelectFilter from 'components/SelectFilter/SelectFilter';
import TextInput from 'components/TextInput/TextInput';
import TextInputNestedLabel from 'components/TextInputNestedLabel/TextInputNestedLabel';
import Toggle from 'components/Toggle/Toggle';

interface Props {
  isProcessing: boolean;
  updateLocationParam: IUpdateLocationParam;
  paymentProcessingPanel: IPaymentProcessingPanel;
}

const IqPayPP = (props: Props) => {
  const { isProcessing, updateLocationParam, paymentProcessingPanel } = props;
  const { paymentMethod, appleGoogleNetwork, applePay, googlePay, allowSmallTransactions, profileId } =
    paymentProcessingPanel.iqpay;

  return (
    <>
      <FormControl label='Profile ID'>
        <TextInput
          placeholder='Profile ID'
          loading={isProcessing}
          value={profileId}
          onChange={(event) => {
            updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_PROFILE_ID);
          }}
        />
      </FormControl>
      <FormControl label='Payment Methods'>
        <div className='control--stacked'>
          <Checkbox
            label='Credit Card'
            checked={paymentMethod.creditCard}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_PAYMENT_METHOD_CREDIT_CARD
              );
            }}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <Checkbox
            label='Apple Pay'
            checked={paymentMethod.applePay}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_PAYMENT_METHOD_APPLE_PAY
              );
            }}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <Checkbox
            label='Google Pay'
            checked={paymentMethod.googlePay}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_PAYMENT_METHOD_GOOGLE_PAY
              );
            }}
            loading={isProcessing}
          />
        </div>
      </FormControl>

      <FormControl label='Apple Pay Settings'>
        <FormControlStacked>
          <TextInputNestedLabel
            label='Merchant ID'
            loading={isProcessing}
            value={applePay.merchantId}
            onChange={(event) =>
              updateLocationParam(
                event.target.value,
                LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_APPLE_PAY_SETTINGS_MERCHANT_ID
              )
            }
          />
        </FormControlStacked>
        <FormControlStacked>
          <Checkbox
            label='Supports 3DS'
            loading={isProcessing}
            checked={applePay.supports3DS}
            onChange={(event) =>
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_APPLE_PAY_SETTINGS_SUPPORT_3DS
              )
            }
          />
        </FormControlStacked>
        <FormControlStacked>
          <Checkbox
            label='Supports Debit'
            loading={isProcessing}
            checked={applePay.supportsDebit}
            onChange={(event) =>
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_APPLE_PAY_SETTINGS_SUPPORT_DEBIT
              )
            }
          />
        </FormControlStacked>
        <FormControlStacked>
          <Checkbox
            label='Supports Credit'
            loading={isProcessing}
            checked={applePay.supportsCredit}
            onChange={(event) =>
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_APPLE_PAY_SETTINGS_SUPPORT_CREDIT
              )
            }
          />
        </FormControlStacked>
      </FormControl>
      <FormControl label='Google Pay Settings'>
        <FormControlStacked>
          <TextInputNestedLabel
            label='Gateway Merchant ID'
            loading={isProcessing}
            value={googlePay.gatewayMerchantId}
            onChange={(event) =>
              updateLocationParam(
                event.target.value,
                LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_GOOGLE_PAY_SETTINGS_GATEWAY_MERCHANT_ID
              )
            }
          />
        </FormControlStacked>
      </FormControl>
      <FormControl>
        <FormControlStacked>
          <TextInputNestedLabel
            label='Gateway ID'
            loading={isProcessing}
            value={googlePay.gatewayId}
            onChange={(event) =>
              updateLocationParam(
                event.target.value,
                LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_GOOGLE_PAY_SETTINGS_GATEWAY_ID
              )
            }
          />
        </FormControlStacked>
      </FormControl>
      <FormControl>
        <FormControlStacked>
          <TextInputNestedLabel
            label='Merchant ID'
            loading={isProcessing}
            value={googlePay.merchantId}
            onChange={(event) =>
              updateLocationParam(
                event.target.value,
                LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_GOOGLE_PAY_SETTINGS_MERCHANT_ID
              )
            }
          />
        </FormControlStacked>
      </FormControl>
      <FormControl>
        <FormControlStacked>
          <TextInputNestedLabel
            label='Merchant Name'
            loading={isProcessing}
            value={googlePay.merchantName}
            onChange={(event) =>
              updateLocationParam(
                event.target.value,
                LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_GOOGLE_PAY_SETTINGS_MERCHANT_NAME
              )
            }
          />
        </FormControlStacked>
      </FormControl>
      <FormControl>
        <FormControlStacked>
          <SelectFilter
            placeholder='Select an Environment'
            options={LIST_OPTIONS.paymentEnvironment}
            loading={isProcessing}
            value={mapSelectOption(LIST_OPTIONS.paymentEnvironment, googlePay.environment)}
            onChange={(option) =>
              updateLocationParam(
                option.value.toString(),
                LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_GOOGLE_PAY_SETTINGS_ENVIRONMENT
              )
            }
            isSearchable={true}
          />
        </FormControlStacked>
      </FormControl>
      <FormControl>
        <FormControlStacked>
          <Checkbox
            label='Supports 3DS'
            loading={isProcessing}
            checked={googlePay.supports3DS}
            onChange={(event) =>
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_GOOGLE_PAY_SETTINGS_SUPPORT_3DS
              )
            }
          />
        </FormControlStacked>
        <FormControlStacked>
          <Checkbox
            label='Supports Credit'
            loading={isProcessing}
            checked={googlePay.supportsCredit}
            onChange={(event) =>
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_GOOGLE_PAY_SETTINGS_SUPPORT_CREDIT
              )
            }
          />
        </FormControlStacked>
        <FormControlStacked>
          <Checkbox
            label='Supports Debit'
            loading={isProcessing}
            checked={googlePay.supportsDebit}
            onChange={(event) =>
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_GOOGLE_PAY_SETTINGS_SUPPORT_DEBIT
              )
            }
          />
        </FormControlStacked>
      </FormControl>

      <FormControl label='Apple Pay / Google Pay Networks'>
        <div className='control--stacked'>
          <Checkbox
            label='Mastercard'
            checked={appleGoogleNetwork.mastercard}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_APPLE_GOOGLE_NETWORK_MASTERCARD
              );
            }}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <Checkbox
            label='Visa'
            checked={appleGoogleNetwork.visa}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_APPLE_GOOGLE_NETWORK_VISA
              );
            }}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <Checkbox
            label='Amex'
            checked={appleGoogleNetwork.amex}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_APPLE_GOOGLE_NETWORK_AMEX
              );
            }}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <Checkbox
            label='Discover'
            checked={appleGoogleNetwork.discover}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_APPLE_GOOGLE_NETWORK_DISCOVER
              );
            }}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <Checkbox
            label='JCB'
            checked={appleGoogleNetwork.jcb}
            onChange={(event) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_APPLE_GOOGLE_NETWORK_JCB
              );
            }}
            loading={isProcessing}
          />
        </div>
      </FormControl>
      <FormControl label='Rate Limiting'>
        <FormControlStacked>
          <Toggle
            checked={paymentProcessingPanel.iqpay.rateLimits.enabled}
            onChange={(checked) => {
              updateLocationParam(
                checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_RATE_LIMITS_TOGGLE
              );
            }}
            loading={isProcessing}
          />
        </FormControlStacked>
        <FormControlStacked>
          <Checkbox
            disabled={true}
            checked={true}
            label='Enforce rate limits on manual card payments'
            loading={isProcessing}
          />
        </FormControlStacked>
        <FormControlStacked>
          <Checkbox
            disabled={!paymentProcessingPanel.iqpay.rateLimits.enabled}
            checked={paymentProcessingPanel.iqpay.rateLimits.enforceRateLimitsForGooglePay}
            label='Enforce rate limits on Google Pay transactions'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_RATE_LIMITS_FOR_GOOGLE_PAY
              );
            }}
            loading={isProcessing}
          />
        </FormControlStacked>
        <FormControlStacked>
          <FormattedInput
            format='money'
            disabled={!paymentProcessingPanel.iqpay.rateLimits.enabled}
            value={paymentProcessingPanel.iqpay.rateLimits.amount}
            onChange={(event) => {
              updateLocationParam(
                toNumber(event.target.value),
                LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_RATE_LIMITS_AMOUNT
              );
            }}
            loading={isProcessing}
          />
          <span className='form__control__separator'>Per</span>
          <FormattedInput
            format='min'
            disabled={!paymentProcessingPanel.iqpay.rateLimits.enabled}
            value={paymentProcessingPanel.iqpay.rateLimits.duration / 60}
            onChange={(event) => {
              updateLocationParam(
                toNumber(event.target.value) * 60,
                LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_RATE_LIMITS_DURATION
              );
            }}
            loading={isProcessing}
          />
        </FormControlStacked>
      </FormControl>
      <FormControl label='Additional Settings'>
        <div className='control--stacked'>
          <Checkbox
            label='Allow small transactions'
            checked={allowSmallTransactions}
            onChange={(event) => {
              updateLocationParam(event.target.checked, LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_SMALL_TRANSACTIONS);
            }}
            loading={isProcessing}
          />
        </div>
      </FormControl>
    </>
  );
};

export default IqPayPP;
