import { createSlice } from '@reduxjs/toolkit';
import { fetchPaymentsReportThunk, fetchPermittedLocationsThunk } from './thunks';
import { fetchPaymentsReportReducers, fetchPermittedLocationsReducers } from './reducers';
import { IPaymentsReportState } from './types';

const initialState: IPaymentsReportState = {
  items: [],
  permittedLocations: {
    totalCount: 0,
    results: [],
  },
  pagination: {
    start: 0,
    length: 0,
    limit: 0,
    total: 0,
  },
  loading: {},
  errors: {},
};

const paymentsReportSlice = createSlice({
  name: 'paymentsReport',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPaymentsReportThunk.pending, fetchPaymentsReportReducers.pending);
    builder.addCase(fetchPaymentsReportThunk.fulfilled, fetchPaymentsReportReducers.fulfilled);
    builder.addCase(fetchPaymentsReportThunk.rejected, fetchPaymentsReportReducers.rejected);

    builder.addCase(fetchPermittedLocationsThunk.pending, fetchPermittedLocationsReducers.pending);
    builder.addCase(fetchPermittedLocationsThunk.fulfilled, fetchPermittedLocationsReducers.fulfilled);
    builder.addCase(fetchPermittedLocationsThunk.rejected, fetchPermittedLocationsReducers.rejected);
  },
});

export const { actions, reducer } = paymentsReportSlice;
