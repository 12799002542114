import { IOrderGiftCardTotals, OrderFulfillmentStatus } from '@ready/dashboardv2api.contracts';
import { OrdersService } from '../../../services/ordersService';
import { Action } from '../../types';
import { pageErrorState, set200Toast, toastErrorState } from '../uiActions/responseStateActions';
import { ORDERS_ACTIONS } from './types';

export const initOrderDetail = (): Action => ({
  type: ORDERS_ACTIONS.INIT_ORDER_DETAIL,
});

export const fetchOrderDetail = (companyId: string, locationId: string, orderId: string) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOrderDetail(true));
    const orderDetail = await OrdersService.getOrder(companyId, locationId, orderId);
    dispatch(setOrderDetail(orderDetail));
  } catch (err) {
    dispatch(pageErrorState(err.status, err.message));
  } finally {
    dispatch(setLoadingOrderDetail(false));
  }
};

export const updateStatus = (
  companyId: string,
  locationId: string,
  orderId: string,
  originalOrderStatus: OrderFulfillmentStatus,
  newOrderStatus: OrderFulfillmentStatus,
  invoiceId?: string,
  orderCancelledMessage?: string,
  refundAmount?: number,
  giftCardPaymentInfo?: IOrderGiftCardTotals
) => async (dispatch: any) => {
  try {
    dispatch(setProcessingOrderDetail(true));
    dispatch(setOrderDetailStatus(newOrderStatus));

    const updatedOrder = await OrdersService.updateOrderStatus(
      companyId,
      locationId,
      orderId,
      newOrderStatus,
      invoiceId,
      orderCancelledMessage,
      refundAmount,
      giftCardPaymentInfo
    );
    dispatch(setOrderDetailStatus(updatedOrder.status));
    if (newOrderStatus === OrderFulfillmentStatus.Cancelled) {
      // show a 200 toast on cancelled specifically, since this refunds the invoice
      // and we want to let the client know this was successful
      dispatch(setCancelOrderDialogOpen(false));
      dispatch(fetchOrderDetail(companyId, locationId, orderId));
      dispatch(set200Toast('Order cancelled.'));
    }
  } catch (err) {
    dispatch(toastErrorState(err.status, err.message));
    dispatch(setOrderDetailStatus(originalOrderStatus));
  } finally {
    dispatch(setProcessingOrderDetail(false));
    // the reason we dispatch cancel order processing false here,
    // is because the finally block from the cancel order action
    // dispatcher runs FIRST, before this finally block, as the
    // try logic here is doing the actual API call. in order for
    // the cancel dialog modal processing to happen only after the
    // API call attempt was made, is to dispatch the processing false
    // action here, instead of from the cancelOrder dispatcher.
    if (newOrderStatus === OrderFulfillmentStatus.Cancelled) {
      dispatch(setProcessingCancelOrder(false));
    }
  }
};

export const cancelOrder = (
  companyId: string,
  locationId: string,
  orderId: string,
  originalOrderStatus: OrderFulfillmentStatus,
  newOrderStatus: OrderFulfillmentStatus,
  invoiceId?: string,
  orderCancelledMessage?: string,
  refundAmount?: number,
  giftCardPaymentInfo?: IOrderGiftCardTotals
) => async (dispatch: any): Promise<boolean> => {
  let success = true;
  try {
    dispatch(setProcessingCancelOrder(true));
    dispatch(
      updateStatus(
        companyId,
        locationId,
        orderId,
        originalOrderStatus,
        newOrderStatus,
        invoiceId,
        orderCancelledMessage,
        refundAmount,
        giftCardPaymentInfo
      )
    );
  } catch (err) {
    dispatch(toastErrorState(err.status, err.message));
    success = false;
  }
  return success;
};

export const updatePickupTime = (
  companyId: string,
  locationId: string,
  orderId: string,
  pickupTime: Date,
  invoiceId?: string,
  message?: string
) => async (dispatch: any): Promise<boolean> => {
  let success = true;
  try {
    dispatch(setProcessingEditPickupTime(true));
    await OrdersService.updatePickupTime(companyId, locationId, orderId, pickupTime, invoiceId, message);
    dispatch(fetchOrderDetail(companyId, locationId, orderId));
    dispatch(set200Toast());
  } catch (err) {
    dispatch(toastErrorState(err.status, err.message));
    success = false;
  } finally {
    dispatch(setProcessingEditPickupTime(false));
  }
  return success;
};

// TODO: removing this IOrderStatus contract since it cannot be found by the import
// const setOrderDetail = <T extends IOrderStatus>(orderDetail: T): Action => ({
const setOrderDetail = <T extends any>(orderDetail: T): Action => ({
  type: ORDERS_ACTIONS.SET_ORDER_DETAIL,
  payload: orderDetail,
});

const setOrderDetailStatus = (orderStatus: OrderFulfillmentStatus): Action => ({
  type: ORDERS_ACTIONS.SET_ORDER_DETAIL_STATUS,
  payload: orderStatus,
});

const setLoadingOrderDetail = (loading: boolean): Action => ({
  type: ORDERS_ACTIONS.SET_LOADING_ORDER_DETAIL,
  payload: loading,
});

const setProcessingOrderDetail = (processing: boolean): Action => ({
  type: ORDERS_ACTIONS.SET_PROCESSING_ORDER_DETAIL,
  payload: processing,
});

export const setCancelOrderDialogOpen = (isOpen: boolean): Action => ({
  type: ORDERS_ACTIONS.SET_CANCEL_ORDER_DIALOG_OPEN,
  payload: isOpen,
});

export const setCancelOrderDialogMessage = (message: string): Action => ({
  type: ORDERS_ACTIONS.SET_CANCEL_ORDER_DIALOG_MESSAGE,
  payload: message,
});

export const setCancelOrderDialogMessageLoading = (loading: boolean): Action => ({
  type: ORDERS_ACTIONS.SET_CANCEL_ORDER_DIALOG_MESSAGE_LOADING,
  payload: loading,
});

const setProcessingCancelOrder = (processing: boolean): Action => ({
  type: ORDERS_ACTIONS.SET_CANCEL_ORDER_PROCESSING,
  payload: processing,
});

export const setEditPickupTimeDialogOpen = (isOpen: boolean): Action => ({
  type: ORDERS_ACTIONS.SET_EDIT_PICKUP_TIME_DIALOG_OPEN,
  payload: isOpen,
});

export const initEditPickupTimeDialog = (): Action => ({
  type: ORDERS_ACTIONS.INIT_EDIT_PICKUP_TIME_DIALOG,
});

export const setEditPickupTimeDialogMessage = (message: string): Action => ({
  type: ORDERS_ACTIONS.SET_EDIT_PICKUP_TIME_DIALOG_MESSAGE,
  payload: message,
});

export const setEditPickupTimeDialogMessageLoading = (loading: boolean): Action => ({
  type: ORDERS_ACTIONS.SET_EDIT_PICKUP_TIME_DIALOG_MESSAGE_LOADING,
  payload: loading,
});

const setProcessingEditPickupTime = (processing: boolean): Action => ({
  type: ORDERS_ACTIONS.SET_EDIT_PICKUP_TIME_PROCESSING,
  payload: processing,
});

export const setNewPickupTime = (pickupTime: Date): Action => ({
  type: ORDERS_ACTIONS.SET_EDIT_PICKUP_TIME_NEW_PICKUP_TIME,
  payload: pickupTime.toISOString(),
});

export const validateNewPickupDateTime = (pickupDateTime: Date, minPickupDateTime: Date): Action => ({
  type: ORDERS_ACTIONS.VALIDATE_NEW_PICKUP_DATE_TIME,
  payload: { pickupDateTime: pickupDateTime.toISOString(), minPickupDateTime: minPickupDateTime.toISOString() },
});

export const resetPickupDateTimeValidations = () => ({
  type: ORDERS_ACTIONS.RESET_PICKUP_TIME_VALIDATIONS,
});
