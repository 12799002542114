import produce from 'immer';
import { Action } from '../../../redux/types';
import { usersListInitialState } from '../../initialStates/users/usersList';
import { USERS_LIST_ACTIONS } from '../../actions/usersActions/types';

export const usersListReducer = (state = usersListInitialState, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case USERS_LIST_ACTIONS.SET_USERS_LIST:
        draft = {
          loading: draft.loading,
          ...action.payload,
        };
        return draft;

      case USERS_LIST_ACTIONS.INITIALIZE_USERS_LIST:
        draft = { ...usersListInitialState, ...action.payload };
        return draft;

      case USERS_LIST_ACTIONS.SET_USERS_LIST_LOADING:
        draft.loading = action.payload;
        return draft;

      default:
        return draft;
    }
  });
};
