import React from 'react';
import styles from './PermissionDescription.module.scss';

interface PermissionDescriptionProps {
  permissionType: 'company' | 'location';
}

const companyPermissionDescription =
  'Users with these permissions will have access to edit company level ' +
  'resources that are shared across all locations.';

const locationPermissionDescription =
  'Users with these permissions will have access to perform the ' +
  'following actions for locations assigned in conjunction with their ' +
  'role.';

const PermissionDescription = ({ permissionType }: PermissionDescriptionProps): JSX.Element => {
  let permissionMessage: string;

  switch (permissionType) {
    case 'company':
      permissionMessage = companyPermissionDescription;
      break;

    case 'location':
      permissionMessage = locationPermissionDescription;
      break;
  }

  return <p className={styles.permissionDescription}>{permissionMessage}</p>;
};

export default PermissionDescription;
