import React from 'react';
import { DetailLayout, DetailElement } from '../../../../components/DetailLayout';
import { DateValue, DollarValue, TextValue } from '../../../../components/Value';
import { ITransactionDetailOnAccountPaymentDetails, IValidationFieldValue } from '@ready/dashboardv2api.contracts';

interface OnAccountPaymentDetailsSectionProps {
  onAccountPaymentOptionDetails: ITransactionDetailOnAccountPaymentDetails;
  timezone?: string;
}

export const OnAccountPaymentDetailsSection = (props: OnAccountPaymentDetailsSectionProps) => {
  const {
    onAccountPaymentOptionDetails: { amountPaid, amountRefunded, date, currency, paymentType, validationFields },
    timezone,
  } = props;

  return (
    <DetailLayout title='On Account Payment Details'>
      <DetailElement condensed label='Amount Paid'>
        <DollarValue value={amountPaid} />
      </DetailElement>
      <DetailElement condensed label='Amount Refunded'>
        <DollarValue value={amountRefunded} />
      </DetailElement>
      <DetailElement condensed label='Date'>
        <DateValue value={date?.toString() ?? ''} timeZone={timezone} />
      </DetailElement>
      <DetailElement condensed label='Currency'>
        <TextValue value={currency} />
      </DetailElement>
      <DetailElement condensed label='Payment Type'>
        <TextValue value={paymentType} />
      </DetailElement>
      {validationFields.length > 0 &&
        validationFields.map((field: IValidationFieldValue, index: number) => (
          <DetailElement key={`${index}-${field.label}-${field.value}`} condensed label={field.label}>
            <TextValue value={field.value} />
          </DetailElement>
        ))}
    </DetailLayout>
  );
};
