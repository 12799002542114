import { IFeedbackReportItem, PaginationResponse } from '@ready/dashboardv2api.contracts';
import config from '../../../config';
import executeApiAsync, { ResponseType } from '../../../services/utils/executeApiAsync';
import { IFeedbackReportGuestSatisfactionData, IFeedbackReportStarRatingData } from '../redux';
import { IPagedResponse } from '@ready/table.core';
import { ILocation } from '../../../services/types/ILocation.type';
import { downloadFileFromBlob } from '../../../utils/downloadFileFromBlob';

const BASE_URL = `${config.readyDashboardApiEndpoint}/companies`;

export const getFeedbackListing = async ({
  contextId,
  query = '',
  page = 1,
  sortColumn = 'created',
  sortDirection = 'asc',
}: {
  contextId: string;
  query: string;
  page: number;
  sortColumn: string;
  sortDirection: string;
}): Promise<PaginationResponse<IFeedbackReportItem>> => {
  const url =
    `${BASE_URL}/${contextId}/reports/feedback/listing?` +
    decodeURIComponent(query) +
    `&page=${page}&sortColumn=${sortColumn}&sortDirection=${sortDirection}`;

  return await executeApiAsync<PaginationResponse<IFeedbackReportItem>>(url);
};

export const exportFeedbackListing = async ({
  contextId,
  query = '',
  sortColumn = 'created',
  sortDirection = 'asc',
}: {
  contextId: string;
  query: string;
  sortColumn: string;
  sortDirection: string;
}): Promise<void> => {
  const url =
    `${BASE_URL}/${contextId}/reports/feedback/listing/export?` +
    decodeURIComponent(query) +
    `&sortColumn=${sortColumn}&sortDirection=${sortDirection}`;

  const blob = await executeApiAsync<Blob>(url, { responseType: ResponseType.Blob });
  downloadFileFromBlob(blob, `FeedbackReport.csv`);
};

export const getStarRating = async ({
  contextId,
  query,
}: {
  contextId: string;
  query: string;
}): Promise<IFeedbackReportStarRatingData> => {
  const url = `${BASE_URL}/${contextId}/reports/feedback/star-rating?${decodeURIComponent(query)}`;
  return await executeApiAsync<IFeedbackReportStarRatingData>(url);
};

export const getGuestSatisfaction = async ({
  contextId,
  query,
}: {
  contextId: string;
  query: string;
}): Promise<IFeedbackReportGuestSatisfactionData> => {
  const url = `${BASE_URL}/${contextId}/reports/feedback/guest-satisfaction?${decodeURIComponent(query)}`;
  return await executeApiAsync<IFeedbackReportGuestSatisfactionData>(url);
};

export const getPermittedFeedbackLocations = async (contextId: string): Promise<IPagedResponse<ILocation>> => {
  // security params
  const resourceType = 'reports';
  const action = 'viewFeedbackReport';
  const securityParams = `permissions=${resourceType}.${action}`;
  const showHiddenLocations = false;

  const url = `${BASE_URL}/${contextId}/permittedlocations?${securityParams}&isHidden=${showHiddenLocations}`;
  return await executeApiAsync<IPagedResponse<ILocation>>(url);
};
