import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import Container from '../Container/Container';
import { useHistory, useLocation } from 'react-router-dom';
import { updatePath } from '../../utils/updatePath';
import { updateURLSearchParams } from '../../utils/updateURLSearchParams';
import { useSearchParams } from '../../hooks/useSearchParams';
import getSearchParam from '../../utils/urlUtils/getSearchParam';
import { find } from 'lodash';
import { NoticeProps } from 'react-select/src/components/Menu';
import DropdownIndicator from '../DropdownIndicator/DropdownIndicator';
import classnames from 'classnames';

export interface Option {
  value: string | number;
  label: string;
}

export type Value = Option | Option[] | null;

type TSelectProps = React.ComponentProps<typeof Select>;

export interface SelectFilterProps extends Partial<Omit<TSelectProps, 'onChange'>> {
  name: string;
  options: Option[];
  onChange?: (data: Option | Option[]) => void;
  value?: Value;
  isSearchable?: boolean;
  isClearable?: boolean;
  placeholder?: string;
  isMulti?: boolean;
  disabled?: boolean;
  loading?: boolean;
  additionalStyles?: string;
  defaultValue?: string;
  setValue?: (name: string, data: any) => void;
}

const SearchSelectFilter = ({
  options,
  value,
  isSearchable = false,
  isClearable = false,
  placeholder = '',
  isMulti = false,
  disabled = false,
  loading = false,
  name,
  defaultValue,
  onChange,
  components: selectComponents = {},
  additionalStyles = '',
  ...restProps
}: SelectFilterProps) => {
  const { query } = useSearchParams();
  const { push } = useHistory();
  const { pathname } = useLocation();

  const [valueState, setValueState] = useState(value);

  // Updates the internal value if controlled from parent component
  useEffect(() => setValueState(value), [value]);

  // Populate input value with query URL value
  useEffect(() => {
    let initialValue: string | null = null;

    if (query) {
      const valueFromUrl = getSearchParam(decodeURIComponent(query), name);
      if (valueFromUrl) {
        initialValue = decodeURIComponent(valueFromUrl);
      }
    }

    if (initialValue === null && defaultValue) {
      initialValue = defaultValue;
    }

    // Option with '' value assumed to be default (if no explicit "defaultValue" passed)
    if (initialValue === null && options.some((option) => option.value === '')) {
      initialValue = '';
    }

    if (initialValue !== null) {
      if (isMulti) {
        const selectedOptionValues = initialValue.split(',');

        // Map the parameters to options in selection order
        const selectedOptions: Option[] = [];
        selectedOptionValues.forEach((value) => {
          const selectedOption = options.find((option) => value === option.value.toString());
          if (selectedOption) {
            selectedOptions.push(selectedOption);
          }
        });

        setValueState(selectedOptions);
      } else {
        const selectedOption = find(options, (option) => option.value === initialValue);
        setValueState(selectedOption);
      }
    }

    // No parameters selected
    else {
      setValueState(isMulti ? [] : null);
    }
  }, [isMulti, name, options, query, defaultValue]);

  const handleChange = (values: Option | Option[]) => {
    let valueString = '';

    if (values !== null) {
      // multi-select passes an array of values
      if (Array.isArray(values)) {
        valueString = values.map((e) => e.value).join(',');
      }
      // single-select passes one value
      else if (values.value) {
        valueString = String(values.value);
      }
    }

    const sanitizedValue = updateURLSearchParams(query, [{ name, value: valueString }]).toEncodedString();
    updatePath(sanitizedValue, pathname, push);
  };

  const isSearchableClassName = isSearchable ? 'search-select-filter--searchable' : '';
  const loadingClassName = loading ? 'search-select-filter--loading' : '';
  const disabledClassName = disabled ? 'search-select-filter--disabled' : '';

  const reactSelectProps = {
    ...restProps,
    className: classnames(
      'search-select-filter',
      isSearchableClassName,
      loadingClassName,
      disabledClassName,
      restProps?.className
    ),
    classNamePrefix: restProps?.classNamePrefix || 'search-select-filter',
  };

  let containerClass = additionalStyles
    ? 'search-select-filter-container ' + additionalStyles
    : 'search-select-filter-container';

  const NoOptionsMessage = (props: NoticeProps<Option, boolean>): JSX.Element => (
    <components.NoOptionsMessage {...props}>No results found</components.NoOptionsMessage>
  );

  return (
    <Container additionalClassName={containerClass}>
      <Select
        options={options}
        isSearchable={isSearchable}
        isClearable={isClearable}
        placeholder={placeholder}
        onChange={(data: any) => handleChange(data)}
        value={valueState}
        isMulti={isMulti}
        isDisabled={loading || disabled}
        components={{ NoOptionsMessage, DropdownIndicator, ...selectComponents }}
        {...reactSelectProps}
      />
    </Container>
  );
};

export default SearchSelectFilter;
