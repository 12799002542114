import { ITemplateMenuItemGroup } from '@ready/menu.core';
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import {
  createSharedItemGroupThunk,
  deleteSharedItemGroupThunk,
  duplicateSharedItemGroupThunk,
  getSharedItemGroupThunk,
  updateSharedItemGroupThunk,
} from './thunks';

interface State {
  itemGroup?: ITemplateMenuItemGroup;
  itemGroupCache?: ITemplateMenuItemGroup;
  newId?: string;
  isItemGroupDeleted: boolean;
  loading: boolean;
  saving: boolean;
}

const initialState: State = {
  loading: false,
  saving: false,
  isItemGroupDeleted: false,
};

const newItemGroup: ITemplateMenuItemGroup = {
  _id: '',
  displayName: '',
  items: [],
};

const itemGroupDetailsSlice = createSlice({
  name: 'itemGroups/details',
  initialState: initialState,
  reducers: {
    updateItemGroup: (state: State, action: { payload: Partial<ITemplateMenuItemGroup> }) => {
      state.itemGroup = { ...(state.itemGroup ?? newItemGroup), ...action.payload };
    },
    moveItemGroupItem: (state: State, action: { payload: { oldIndex: number; newIndex: number } }) => {
      const itemsForMove = state.itemGroup?.items;

      if (itemsForMove) {
        const movedItem = itemsForMove.splice(action.payload.oldIndex, 1)[0];
        itemsForMove?.splice(action.payload.newIndex, 0, movedItem);
        itemsForMove?.forEach((item, index) => (item.sortOrder = index));
      }
    },
    removeItemGroupItem: (state: State, action: { payload: string }) => {
      if (state.itemGroup?.items) {
        const filteredItems = state.itemGroup?.items.filter((item) => item.itemId !== action.payload);
        state.itemGroup.items = filteredItems;
      }
    },
    resetItemGroup: (state: State) => {
      state.itemGroup = { ...(state.itemGroupCache ?? newItemGroup) };
    },
    resetState: () => initialState,
  },
  extraReducers: (builder: ActionReducerMapBuilder<State>) => {
    builder.addCase(getSharedItemGroupThunk.pending, (state: State) => {
      state.loading = true;
    });

    builder.addCase(getSharedItemGroupThunk.fulfilled, (state: State, action) => {
      state.loading = false;
      state.itemGroupCache = state.itemGroup = action.payload;
      state.newId = undefined;
    });

    builder.addCase(getSharedItemGroupThunk.rejected, (state: State) => {
      state.loading = false;
    });

    builder.addCase(createSharedItemGroupThunk.pending, (state: State) => {
      state.saving = true;
    });

    builder.addCase(createSharedItemGroupThunk.fulfilled, (state: State, action) => {
      state.saving = false;
      state.itemGroupCache = state.itemGroup = action.payload;
      state.newId = action.payload?._id;
    });

    builder.addCase(createSharedItemGroupThunk.rejected, (state: State) => {
      state.saving = false;
    });

    builder.addCase(updateSharedItemGroupThunk.pending, (state: State) => {
      state.saving = true;
    });

    builder.addCase(updateSharedItemGroupThunk.fulfilled, (state: State, action) => {
      state.saving = false;
      state.itemGroupCache = state.itemGroup = action.payload;
    });

    builder.addCase(updateSharedItemGroupThunk.rejected, (state: State) => {
      state.saving = false;
    });

    builder.addCase(duplicateSharedItemGroupThunk.pending, (state: State) => {
      state.saving = true;
    });

    builder.addCase(duplicateSharedItemGroupThunk.fulfilled, (state: State, action) => {
      state.saving = false;
      state.itemGroupCache = state.itemGroup = action.payload;
      state.newId = action.payload?._id;
    });

    builder.addCase(duplicateSharedItemGroupThunk.rejected, (state: State) => {
      state.saving = false;
    });

    builder.addCase(deleteSharedItemGroupThunk.pending, (state: State) => {
      state.saving = true;
      state.isItemGroupDeleted = false;
    });

    builder.addCase(deleteSharedItemGroupThunk.fulfilled, (state: State, action) => {
      state.saving = false;
      state.isItemGroupDeleted = true;
    });

    builder.addCase(deleteSharedItemGroupThunk.rejected, (state: State) => {
      state.saving = false;
      state.isItemGroupDeleted = false;
    });
  },
});

export const { updateItemGroup, resetItemGroup, resetState, moveItemGroupItem, removeItemGroupItem } =
  itemGroupDetailsSlice.actions;

export { itemGroupDetailsSlice };
