import React from 'react';
import { IEmbeddedModifierItem, IModifierGroup, ITemplateEmbeddedModifierItem, ITemplateModifierGroup } from '@ready/menu.core';
import styles from './ModifierGroupOptionsList.module.scss';
import ModifierGroupOptionsListItem from './ModifierGroupOptionsListItem';
import { Form } from '../../../components/Form';
import DragAndDrop from '../../../components/DragAndDrop';
import { IModifierOptionsFormValidation } from '../../redux/ModifierGroupsState';

export interface ModifierGroupOptionsListProps {
  modifierGroup?: IModifierGroup | ITemplateModifierGroup;
  optionsValidation?: IModifierOptionsFormValidation;
  editing: boolean;
  processing: boolean;
  companyId: string;
  locationId?: string;
  allowNestedMods: boolean;
  onOptionMoved: (oldIndex: number, newIndex: number) => void;
  onOptionDeleted: (id: string) => void;
  onVisibilityChanged: (id: string, visible: boolean) => void;
  onAddNestedMod: (parentOption: IEmbeddedModifierItem | ITemplateEmbeddedModifierItem) => void;
  onRemoveNestedMod: (optionId: string, modGroupId: string) => void;
  onMoveNestedMod: (optionId: string, sourceIndex: number, destinationIndex: number) => void;
  onChangeMaxValue: (id: string, constraints: number) => void;
  onDefaultChanged: (id: string, isDefault: boolean) => void;
}

const EmptyModGroupList = () => (
  <div className={styles.option}>
    <div className={styles.noOptions}>There are no modifier options in this group yet.</div>
  </div>
);

const ModifierGroupOptionsList = (props: ModifierGroupOptionsListProps): JSX.Element | null => {
  const {
    modifierGroup,
    optionsValidation,
    editing,
    processing,
    companyId,
    locationId,
    allowNestedMods,
    onOptionMoved,
    onOptionDeleted,
    onVisibilityChanged,
    onAddNestedMod,
    onRemoveNestedMod,
    onMoveNestedMod,
    onChangeMaxValue,
    onDefaultChanged,
  } = props;

  if (!modifierGroup) {
    return <EmptyModGroupList />;
  }

  const hasOptionsWithNestedMods =
    modifierGroup.options.filter((option) => !!option.nestedGroups && !!option.nestedGroups.length).length > 0;

  return (
    <Form hasGroups onSubmit={() => {}}>
      <DragAndDrop
        dropAreaClassName={styles.options}
        dropAreaDraggingClassName={styles.optionsDropArea}
        dragItemClassName={styles.option}
        dragItemDraggingClassName={styles.optionDragging}
        dragHandleClassName={styles.dragHandle}
        dragItems={modifierGroup.options}
        emptyComponent={<EmptyModGroupList />}
        draggingDisabled={!editing}
        dragHandleProvided
        dragItemKeyExtractor={(option: IEmbeddedModifierItem) => option.itemId}
        dragItemComponentBuilder={(option: IEmbeddedModifierItem, dragHandle?: JSX.Element) => (
          <ModifierGroupOptionsListItem
            companyId={companyId}
            locationId={locationId}
            modifierGroupId={modifierGroup._id}
            modifierOption={option}
            defaultMaxValue={modifierGroup.constraints.max ? modifierGroup.constraints.max : null}
            allowMultiplesOfEachOption={modifierGroup.allowMultiplesOfEachOption || false}
            allowNestedMods={allowNestedMods}
            allowExpansion={hasOptionsWithNestedMods}
            modifierOptionsValidation={optionsValidation}
            editing={editing}
            dragHandle={dragHandle}
            processing={processing}
            onDelete={onOptionDeleted}
            onVisibilityChanged={onVisibilityChanged}
            onAddNestedModClick={() => onAddNestedMod(option)}
            onRemoveNestedModClick={(modGroupId: string) => onRemoveNestedMod(option.itemId, modGroupId)}
            onNestedModMoved={(sourceIndex: number, destinationIndex: number) =>
              onMoveNestedMod(option.itemId, sourceIndex, destinationIndex)
            }
            onChangeMaxValue={onChangeMaxValue}
            onDefaultChanged={onDefaultChanged}
          />
        )}
        onDragItemDropped={onOptionMoved}
      />
    </Form>
  );
};

export default ModifierGroupOptionsList;
