import { ILocationOrderMessagingConfig } from '@ready/dashboardv2api.contracts';

export type OrderMessagingConfigState = {
  orderMessagingEdit: ILocationOrderMessagingConfig;
  orderMessagingCache: ILocationOrderMessagingConfig;
  loading: boolean;
};

const orderMessagingConfigDefault: ILocationOrderMessagingConfig = {
  checkoutAnnouncementMessage: '',
  menuAnnouncementsMessage: '',
  pickupInstructions: '',
  pickupLocationName: '',
  disruptionMessage: '',
  alcoholPolicy: '',
  orderConfirmedMessage: '',
};

export const orderMessagingConfigInitialState: OrderMessagingConfigState = {
  loading: false,
  orderMessagingCache: orderMessagingConfigDefault,
  orderMessagingEdit: orderMessagingConfigDefault,
};
