import React, { ChangeEvent } from 'react';
import Checkbox from 'components/Checkbox/Checkbox';
import { Form, FormControl } from 'components/Form';
import FormActionBar from 'components/FormActionBar/FormActionBar';
import { Icon } from 'components/Icon/TextIcon';
import { Panel, PanelLayout } from 'components/PanelLayout';
import TextArea from 'components/TextArea/TextArea';
import TextInput from 'components/TextInput/TextInput';
import { SharedMenuItemsLayout } from 'sharedMenuItems/SharedMenuItemsLayout';
import ActionHeader from 'components/ActionHeader/ActionHeader';
import {
  defaultValidationValues,
  DuplicateSharedMenuItemModal,
  IDisplayNameValidation,
} from 'sharedMenuItems/pages/createEditItem/components/duplicateSharedMenuItemModal/DuplicateSharedMenuItemModal';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { selectCreateEditItemState } from 'sharedMenuItems/redux/selectors';
import FormattedInput from 'components/FormattedInput/FormattedInput';
import { IFormState } from 'redux/initialStates/ui/formState';
import { selectFormState, selectSearchParamsState } from 'redux/selectors/uiSelectors/uiSelectors';
import { useHistory, useParams } from 'react-router-dom';
import { SharedMenuItemsRoutes } from 'sharedMenuItems/Router';
import { setFormIsDirty } from 'redux/actions/uiActions/formStateActions';
import styles from './SharedModifierDetails.module.scss';
import { updateSharedMenuItemState } from 'sharedMenuItems/redux/createEditItem/createEditItemSlice';
import {
  createTemplateMenuItemThunk,
  deleteTemplateMenuItemThunk,
  updateTemplateMenuItemThunk,
} from 'sharedMenuItems/redux/createEditItem/createEditItemThunkActions';
import { SharedMenuItemsTabId } from 'sharedMenuItems/SharedMenuItemsTabs';
import DeleteConfirmationModal from 'components/Modal/DeleteConfirmationModal';
import { SharedMenuConextParams } from 'sharedMenuItems/types/sharedMenuContextParams.interface';
import { BackLink } from 'types/BackLink.interface';
import { DetailsTabs, DetailTabId } from '../tabs/DetailsTabs';

const getBackToBaseUrl = (companyId: string, modGroupId?: string) => {
  // if a modGroupIdExists, we've come from a mod group.
  if (modGroupId) {
    return SharedMenuItemsRoutes.getModifierGroupDetailsRoute(companyId, modGroupId);
  }

  return SharedMenuItemsRoutes.getItemAndModsRoute(companyId);
};

export const SharedModifierDetails = () => {
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const {
    item: modifier,
    itemCache: modifierCache,
    processing: saving,
    newId,
    isItemDeleted: isModifierDeleted,
  } = useAppSelector(selectCreateEditItemState);
  const { type, searchTerm, page, locationName, locationId } = useAppSelector(selectSearchParamsState);
  const { isDirty: actionBarVisible } = useAppSelector<IFormState>(selectFormState);
  const {
    contextId: companyId,
    modGroupId,
    id: forcedModifierId,
    nestedForcedModifierId,
  } = useParams<SharedMenuConextParams & { modGroupId?: string }>();
  const [displayNameValidation, setDisplayNameValidation] =
    React.useState<IDisplayNameValidation>(defaultValidationValues);
  const [isCancelChangesButtonClicked, setIsCancelChangesButtonClicked] = React.useState<boolean>(false);
  const [isDuplicateModalVisible, setIsDuplicateModalVisible] = React.useState<boolean>(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = React.useState<boolean>(false);

  const isNewModifier = forcedModifierId === undefined;
  const saveLabel = isNewModifier ? 'Create Modifier' : 'Save Changes';

  const getItemAndModsPageRoute = {
    baseUrl: getBackToBaseUrl(companyId, modGroupId),
    searchParams: {
      type: type || '',
      searchTerm: searchTerm || '',
      page: page || '',
      locationName: locationName || '',
      locationId: locationId || '',
    },
  };

  const backLinkTo: string | BackLink = nestedForcedModifierId
    ? SharedMenuItemsRoutes.getItemAndModsItem(companyId, forcedModifierId)
    : getItemAndModsPageRoute;

  React.useEffect(() => {
    return () => {
      setDisplayNameValidation(defaultValidationValues);
      setIsCancelChangesButtonClicked(false);
    };
  }, []);

  React.useEffect(() => {
    if ((isCancelChangesButtonClicked && isNewModifier) || isModifierDeleted) {
      push(SharedMenuItemsRoutes.getItemAndModsRoute(companyId));
    }
  }, [companyId, isCancelChangesButtonClicked, isModifierDeleted, isNewModifier, push]);

  React.useEffect(() => {
    if (newId) {
      push(SharedMenuItemsRoutes.getItemAndModsItem(companyId, newId));
    }
  }, [companyId, newId, push]);

  const hasPassedValidation = (): boolean => {
    if (modifier?.displayName) {
      setDisplayNameValidation(defaultValidationValues);
      return true;
    } else {
      setDisplayNameValidation({
        hasError: true,
        errorMessage: 'Required field. ',
      });
      return false;
    }
  };

  const handleFormSubmit = () => {
    if (hasPassedValidation()) {
      if (modifier) {
        if (isNewModifier) {
          dispatch(createTemplateMenuItemThunk({ companyId, item: modifier }));
        } else {
          dispatch(updateTemplateMenuItemThunk({ companyId, item: modifier }));
        }
      }
    }
  };

  const cancelChanges = () => {
    setIsCancelChangesButtonClicked(true);
    dispatch(setFormIsDirty(false));
  };

  return (
    <>
      <FormActionBar
        actionBarVisible={actionBarVisible || isNewModifier}
        saveLabel={saveLabel}
        handleFormSubmit={handleFormSubmit}
        resetForm={cancelChanges}
      />
      <SharedMenuItemsLayout tab={SharedMenuItemsTabId.ItemAndMods}>
        {isNewModifier ? (
          <ActionHeader text='New Shared Modifier' />
        ) : (
          <ActionHeader
            text={modifierCache?.displayName ?? ''}
            backLinkTo={backLinkTo}
            actionButtons={[
              {
                label: 'Duplicate',
                variant: 'secondary-gray-bg',
                disabled: saving,
                onClick: () => {
                  setIsDuplicateModalVisible(true);
                },
              },
              {
                label: 'Delete',
                variant: 'secondary-gray-bg',
                disabled: saving,
                onClick: () => {
                  setIsDeleteModalVisible(true);
                },
              },
            ]}
          />
        )}
        {!isNewModifier && (
          <div className={styles.sharedModifierTabsContainer}>
            <DetailsTabs selectedTabId={DetailTabId.Details} />
          </div>
        )}
        <Form hasGroups>
          <PanelLayout columns={2} flexWidths={[100, 34]} stackOnTablet>
            <Panel>
              <FormControl
                label='Display Name *'
                errorMessage={displayNameValidation.errorMessage}
                withError={displayNameValidation.hasError}
              >
                <TextInput
                  placeholder='Enter a display name'
                  value={modifier?.displayName || ''}
                  withError={displayNameValidation.hasError}
                  loading={saving}
                  maxLength={100}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    dispatch(updateSharedMenuItemState({ displayName: e.target.value.toString(), itemType: 'option' }));
                  }}
                />
              </FormControl>
              <FormControl label='Description'>
                <TextArea
                  placeholder='Enter a description'
                  value={modifier?.description ?? ''}
                  loading={saving}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                    dispatch(updateSharedMenuItemState({ description: e.target.value.toString() }));
                  }}
                />
              </FormControl>

              <FormControl icon={Icon.Martini} label='Alcohol Tracking'>
                <Checkbox
                  label='Contains Alcohol'
                  checked={modifier?.alcohol?.containsAlcohol ?? false}
                  loading={saving}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    dispatch(
                      updateSharedMenuItemState({
                        alcohol: {
                          ...modifier?.alcohol,
                          containsAlcohol: e.target.checked,
                        },
                      })
                    );
                  }}
                />
              </FormControl>

              {modifier?.alcohol && modifier?.alcohol.containsAlcohol ? (
                <FormControl>
                  <div className='control--stacked'>
                    <div>
                      <FormattedInput
                        format='number'
                        decimalScale={2}
                        value={modifier?.alcohol && modifier?.alcohol.quantity ? modifier?.alcohol.quantity.value : ''}
                        loading={saving}
                        placeholder='e.g. 1.5'
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          if (e.target.value) {
                            dispatch(
                              updateSharedMenuItemState({
                                alcohol: {
                                  containsAlcohol: modifier.alcohol?.containsAlcohol ?? false,
                                  quantity: {
                                    measure: 'standardDrink',
                                    value: Number(e.target.value),
                                  },
                                },
                              })
                            );
                          } else {
                            dispatch(
                              updateSharedMenuItemState({
                                alcohol: {
                                  containsAlcohol: modifier.alcohol?.containsAlcohol ?? false,
                                },
                              })
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className='control--stacked'>
                    <span className={styles.alcoholQuantityNote}>Measured in # of standard drinks</span>
                  </div>
                </FormControl>
              ) : null}
            </Panel>
          </PanelLayout>

          {isDeleteModalVisible && (
            <DeleteConfirmationModal
              setShowModal={setIsDeleteModalVisible}
              item='Shared Modifier'
              itemName={modifier?.displayName ?? ''}
              loading={saving}
              handleDelete={() => {
                if (!isNewModifier && modifier) {
                  dispatch(
                    deleteTemplateMenuItemThunk({
                      companyId,
                      itemId: modifier._id,
                      itemType: modifier.itemType,
                    })
                  );
                  dispatch(setFormIsDirty(false));
                }
              }}
            />
          )}
        </Form>
        {isDuplicateModalVisible && (
          <DuplicateSharedMenuItemModal companyId={companyId} setVisible={setIsDuplicateModalVisible} />
        )}
      </SharedMenuItemsLayout>
    </>
  );
};
