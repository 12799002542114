import React from 'react';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../redux/initialStates/AppState';
import { useHistory, useParams } from 'react-router-dom';
import { useSearchParams } from '../../../hooks/useSearchParams';
import { Routes } from '../../MenuBuilderRouter';
import { MenuResourceActions, PrincipalPermissions, ResourceType, SecurityScope, Verifier } from '@ready/security.core';

import { LocationsThunks } from '../../redux/LocationsThunks';
import {
  loadModifierGroups,
  storeModifierGroupsFilters,
  showDeleteModifierGroupModal,
  deleteModifierGroup,
} from '../../redux/ModifierGroupsActions';
import { IModifierGroup } from '@ready/menu.core';

import styles from './ModifierGroupsPage.module.scss';
import classNames from 'classnames';

import MenuBuilderLayoutContent from '../MenuBuilderLayoutContent';
import MenuBuilderTab from '../../types/MenuBuilderTab.enum';
import useResponsiveBreakpoint from '../../../hooks/useResponsiveBreakpoint';
import SearchParamInput from '../../../components/SearchInput/SearchParamInput';
import Button from '../../../components/Button/Button';
import OverflowButton from '../../../components/OverflowButton/OverflowButton';
import InfoCardList from '../../../components/InfoCard/InfoCardList';
import InfoCardsSkeleton from '../../../components/InfoCard/InfoCardColumnSkeleton';
import EmptyResult from '../../../components/EmptyResult/EmptyResult';
import ModifierGroupListCard from './ModifierGroupListCard';
import DeleteConfirmationModal from '../../../components/Modal/DeleteConfirmationModal';
import { ContextParams } from '../../../types/ContextParams.interface';
import InfoCard from '../../../components/InfoCard/InfoCard';
import { selectLocationsState } from '../../redux/LocationsSelectors';

const mapStateToProps = (state: AppState) => ({
  permissions: state.session.permissions.permissionsList,
  modifierGroups: state.menuBuilder.modifierGroups,
});

const actionCreators = {
  loadModifierGroups,
  storeModifierGroupsFilters,
  showDeleteModifierGroupModal,
  deleteModifierGroup,
};

const connector = connect(mapStateToProps, actionCreators);

export type ModifierGroupsPageProps = ConnectedProps<typeof connector>;

const ModifierGroupsPage = (props: ModifierGroupsPageProps): JSX.Element => {
  const {
    permissions,
    modifierGroups: {
      loading,
      pagination,
      items,
      deleteRequested,
      deleteProcessing,
      modifierGroupToDelete,
      deletedModifierGroup,
    },
    loadModifierGroups,
    storeModifierGroupsFilters,
    showDeleteModifierGroupModal,
    deleteModifierGroup,
  } = props;
  const { location } = useSelector(selectLocationsState);
  const dispatch = useDispatch();
  const { contextId: companyId, locationId } = useParams<ContextParams>();
  const { query, page } = useSearchParams();
  const history = useHistory();

  const hasManagePermission = Verifier.check(
    new PrincipalPermissions(permissions),
    SecurityScope.location,
    ResourceType.menu,
    MenuResourceActions.all,
    locationId
  );

  const searchInputPlaceholder = 'Search modifier groups';
  const searchClassName = classNames(`${styles.search}`, 'search-input-full-width', 'white');

  const addModifierGroupButton = {
    label: '+ New Modifier Group',
    primary: true,
    onClick: (): void => history.push(Routes.getNewModifierGroupPage(companyId, locationId)),
  };
  const controlOptions = [addModifierGroupButton];
  const { isMobile } = useResponsiveBreakpoint();
  const skeletonInstances = 10;
  const skeletonRowsWithinInstances = isMobile ? 4 : 2;
  const skeletonColumns = isMobile ? 2 : 4;
  const skeletonColumnWidths = isMobile ? [85, 15] : [33, 33, 34, 5];
  const emptyResultTitle = 'Create Your First Modifier Group';
  const emptyResultParagraph =
    'Click on the ‘+ New Modifier Group’ button to get started on building out your modifiers. These groups can then be applied to items.';

  const onDeleteModifierGroupClick = (modifierGroup: IModifierGroup) => {
    showDeleteModifierGroupModal(true, modifierGroup);
  };

  const onConfirmDeleteModifierGroup = () => {
    if (modifierGroupToDelete) {
      deleteModifierGroup(companyId, locationId, modifierGroupToDelete);
    }
  };

  const fetchLocation = React.useCallback(
    (companyId: string, locationId: string) => {
      if (!location || location.id !== locationId) {
        dispatch(LocationsThunks.loadSelectedLocation({ companyId, locationId }));
      }
    },
    [location, dispatch]
  );

  const fetchData = React.useCallback(
    async (companyId: string, locationId: string, query: string, page: number) => {
      await loadModifierGroups(companyId, locationId, query, page);
    },
    [loadModifierGroups]
  );

  React.useEffect(() => {
    fetchLocation(companyId, locationId);
  }, [fetchLocation, companyId, locationId]);

  React.useEffect(() => {
    fetchData(companyId, locationId, query, page);
    storeModifierGroupsFilters(query, page);
  }, [
    fetchData,
    storeModifierGroupsFilters,
    companyId,
    locationId,
    query,
    page,
    deletedModifierGroup, // ensures we reload the list after deleting a modifier group
  ]);

  return (
    <>
      {deleteRequested && modifierGroupToDelete && (
        <DeleteConfirmationModal
          setShowModal={showDeleteModifierGroupModal}
          item='Modifier Group'
          itemName={modifierGroupToDelete.name!}
          loading={deleteProcessing}
          handleDelete={onConfirmDeleteModifierGroup}
        />
      )}

      <MenuBuilderLayoutContent
        companyId={companyId}
        locationId={locationId}
        locationName={location && location.id === locationId ? location.name : null}
        stockStatusOnly={!hasManagePermission}
        tab={MenuBuilderTab.MOD_GROUPS}
      >
        <div className={styles.container}>
          <div className={styles.controls}>
            <div className={styles.searchContainer}>
              <div className={styles.search}>
                <SearchParamInput placeholder={searchInputPlaceholder} additionalClassName={searchClassName} />
                <div className={styles.buttonsOverflow}>
                  <OverflowButton options={controlOptions} />
                </div>
              </div>
            </div>
            <div className={styles.buttons}>
              <Button label={addModifierGroupButton.label} onClick={addModifierGroupButton.onClick} />
            </div>
          </div>

          {loading || (items && items.length) || query ? (
            <InfoCardList paginationProps={pagination}>
              {loading ? (
                <InfoCardsSkeleton
                  instances={skeletonInstances}
                  rowsWithinInstance={skeletonRowsWithinInstances}
                  columns={skeletonColumns}
                  infoCardColumnWidths={skeletonColumnWidths}
                />
              ) : items && !!items.length ? (
                items.map((modifierGroup) => (
                  <ModifierGroupListCard
                    modifierGroup={modifierGroup}
                    linkTo={Routes.getModifierGroupPage(companyId, locationId, modifierGroup._id)}
                    onDeleteClick={onDeleteModifierGroupClick}
                    key={modifierGroup._id}
                    additionalClassName={styles.item}
                  />
                ))
              ) : (
                <InfoCard>
                  <div className={styles.noResults}>No Results Found</div>
                </InfoCard>
              )}
            </InfoCardList>
          ) : (
            <EmptyResult title={emptyResultTitle} paragraph={emptyResultParagraph} />
          )}
        </div>
      </MenuBuilderLayoutContent>
    </>
  );
};

export default connector(ModifierGroupsPage);
