import React from 'react';
import { Link } from 'react-router-dom';
import InfoCard from '../../../components/InfoCard/InfoCard';
import InfoCardColumn from '../../../components/InfoCard/InfoCardColumn';
import ItemGroupImage from './ItemGroupImage';
import styles from './ItemGroupCard.module.scss';
import MenuItemNameBlock from '../ItemsAndMods/MenuItemNameBlock';
import TextIcon, { Icon } from 'components/Icon/TextIcon';

export interface ItemGroupCardProps {
  imageUrl?: string;
  linkTo: string;
  name: string;
  description?: string;
  quantity: number;
  parentDisabled?: boolean;
  hidden?: boolean;
  controls?: JSX.Element;
  isSharedItemGroup?: boolean;
  isSharedMenu?: boolean;
}

const ItemGroupCard = (props: ItemGroupCardProps) => {
  const {
    linkTo,
    imageUrl,
    name,
    description,
    quantity,
    parentDisabled = false,
    hidden = false,
    controls,
    isSharedItemGroup = false,
    isSharedMenu = false,
  } = props;

  const quantityText = `${quantity} Item${quantity !== 1 ? 's' : ''}`;

  return (
    <InfoCard additionalStyles={styles.container}>
      <Link to={linkTo} className={parentDisabled ? styles.linkDisabled : styles.link}>
        <InfoCardColumn columnClassName={styles.itemGroupImageMobile}>
          <ItemGroupImage itemGroupName={name} imageUrl={imageUrl} />
        </InfoCardColumn>
        <InfoCardColumn columnClassName={styles.itemGroupImageAndDetails}>
          <div className={styles.itemGroupImage}>
            <ItemGroupImage itemGroupName={name} imageUrl={imageUrl} />
          </div>

          <div className={styles.itemGroupDetails}>
            <MenuItemNameBlock
              displayName={name}
              description={description}
              parentDisabled={parentDisabled}
              hidden={hidden}
            />
            <p className={styles.itemGroupQtyMobile}>{quantityText}</p>
          </div>
        </InfoCardColumn>
        <InfoCardColumn columnClassName={styles.itemGroupQuantityAndSpacer}>
          <div className={hidden ? styles.itemGroupQuantityHidden : styles.itemGroupQuantity}>{quantityText}</div>
        </InfoCardColumn>

        <InfoCardColumn columnClassName={styles.sharedIconColumn} width={10}>
          <div className={styles.sharedIcon}>{isSharedItemGroup && <TextIcon icon={Icon.Share} />}</div>
        </InfoCardColumn>
      </Link>

      {!!controls && !isSharedMenu ? controls : null}
    </InfoCard>
  );
};

export default ItemGroupCard;
