import { ITransactionDetailGuestInformation } from '@ready/dashboardv2api.contracts';
import React from 'react';
import { DetailLayout, DetailElement } from '../../../../components/DetailLayout';
import { TextValue } from '../../../../components/Value';
import TransactionGuestPii from '../TransactionGuestPii';

interface GuestInformationSectionProps {
  guestInformation: ITransactionDetailGuestInformation;
  hasGuestPiiPermission: boolean;
}

export const GuestInformationSection = (props: GuestInformationSectionProps) => {
  const {
    hasGuestPiiPermission,
    guestInformation: { ipAddress, pii },
  } = props;
  return (
    <DetailLayout title='Guest Information'>
      <DetailElement condensed label='IP Address'>
        <TextValue value={ipAddress} />
      </DetailElement>
      <TransactionGuestPii hasGuestPiiPermission={hasGuestPiiPermission} pii={pii} />
    </DetailLayout>
  );
};
