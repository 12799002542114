import React from 'react';
import LayoutContent from '../../components/AppLayout/LayoutContent';
import EmptyResult from '../../components/EmptyResult/EmptyResult';
// redux
import { connect } from 'react-redux';
import { ISession } from '../../redux/initialStates/session/session';

export interface CompanyContextProps {
  session: ISession;
}

const CompanyContext = (props: CompanyContextProps) => {
  // get my permissions for this context
  // based on my permissions - show sidebard options
  // redirect to first available page. If nothing is available - don't redirect
  const { session } = props;

  const title = `Welcome, ${session.userSession.userName}`;

  const [contentTitle, setContentTitle] = React.useState('');
  const [contentParagraph, setContentParagraph] = React.useState('');
  React.useEffect(() => {
    if (session.sidebar.options.length === 0) {
      setContentTitle('Time for a Coffee Break!');
      setContentParagraph(
        'It looks like you haven’t been assigned any security roles at this company, so there isn’t much for you to do here yet. You’ll need to contact your company’s admin and ask them to assign you a role so that you can get started.'
      );
    } else {
      setContentTitle('Select an option in the sidebar');
      setContentParagraph('In order to get started you will need to select an option in the sidebar menu.');
    }
  }, [session.sidebar.optionsSet, session.sidebar.options.length]);

  return (
    <LayoutContent title={title} containerType='within'>
      <div className='page-with-empty-result'>
        <EmptyResult title={contentTitle} paragraph={contentParagraph} />
      </div>
    </LayoutContent>
  );
};

const mapStateToProps = (state: any) => ({
  session: state.session,
});

export default connect(mapStateToProps)(CompanyContext);
