import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IUserRole, ICompanyRoleSelector } from '../../services/types/companyUsers.type';
import { ILocationToShow } from '@ready/dashboardv2api.contracts';
import { IUsersFormValidation } from '../../redux/initialStates/users/usersForm';
import { AppState } from '../../redux/initialStates/AppState';
import { loadRoles } from '../../redux/actions/usersActions/usersFormActions';
import { prepareCompanyLocationsModal } from '../../redux/actions/companyLocations/companyLocationsActions';
import { Option } from '../SelectFilter/SelectFilter';
import SelectFilterAsync from '../SelectFilter/SelectFilterAsync';
import { FormControl } from '../Form';
import IconButton from '../IconButton/IconButton';
import Button from '../Button/Button';
import styles from './UserPermissionsField.module.scss';
import UsersAddLocationsDialog from '../../pages/Users/UsersAddLocationsDialog';
import TextIcon, { Icon } from '../Icon/TextIcon';
import { useClassNames } from '../../utils/cssUtils';

export type UserPermissionFieldProps = PropsFromRedux & {
  handleRemoveUserPermission?: (index: number) => void;
  handleSelectRoleChange?: (role: Option, index: number) => void;
  handleAddLocationToUserPermission?: (location: ILocationToShow, permissionIndex: number) => void;
  handleRemoveLocationFromUserPermission?: (locationId: string, permissionIndex: number) => void;
  companyRoles: ICompanyRoleSelector;
  companyId: string;
  isProcessing: boolean;
  index: number;
  role: IUserRole;
  validation?: IUsersFormValidation;
  loading: boolean;
  readOnly?: boolean;
};

type LocationProps = { name: string };

const Location = ({ name }: LocationProps) => (
  <div className={styles.locationLabel}>
    <div>
      <TextIcon additionalStyles={styles.locationIcon} icon={Icon.Location} />
      {name}
    </div>
  </div>
);

const doNothingCallback = () => {};

const UserPermissionsField = (props: UserPermissionFieldProps) => {
  const {
    loadRoles,
    prepareCompanyLocationsModal,
    handleRemoveUserPermission,
    handleSelectRoleChange,
    handleAddLocationToUserPermission,
    handleRemoveLocationFromUserPermission,
    companyId,
    companyRoles,
    isSelectingLocations,
    isProcessing,
    index,
    role,
    validation,
    loading,
    readOnly = false,
  } = props;

  const handleSetLocationsModalVisibility = (visible: boolean): void => {
    prepareCompanyLocationsModal(visible, index);
  };

  const rolesLoading = companyRoles.loading;
  const roleOptions: Option[] = companyRoles.roles.map((role) => ({ value: role.id, label: role.name }));
  const selectedRole = role.role ? { value: role.role.id, label: role.role.name } : undefined;
  const rolesLimit = 50;
  const rolesOverflowMessage = `Top ${rolesLimit} results shown. Type to refine your search.`;

  const handleFetch = (filter: string): void => {
    loadRoles(companyId, index, filter, rolesLimit);
  };
  const onChange = handleSelectRoleChange
    ? (option: Option) => handleSelectRoleChange(option, index)
    : doNothingCallback;

  const locations = role.locations ? role.locations : [];
  const includeLocationsDialog =
    !readOnly && !!handleAddLocationToUserPermission && !!handleRemoveLocationFromUserPermission;

  const containerClassNames = useClassNames([{ readOnly, firstContainer: index === 0 }, styles.container], styles);

  const listClassNames = useClassNames([{ readOnly }, styles.locationList], styles);

  const roleHasError =
    !!validation && validation.usersForm.roles.length > index && validation.usersForm.roles[index].hasError;
  const roleErrorMessage = roleHasError ? validation!.usersForm.roles[index].error : '';

  return (
    <div className={containerClassNames}>
      {includeLocationsDialog && (
        <UsersAddLocationsDialog
          visible={isSelectingLocations}
          companyId={companyId}
          addLocationToUserPermission={handleAddLocationToUserPermission!}
          removeLocationFromUserPermission={handleRemoveLocationFromUserPermission!}
        />
      )}

      <div className={listClassNames}>
        {readOnly ? (
          <div className={styles.roleName}>
            {role.role && (
              <>
                <span>{role.role.name}</span>
                {`${locations.length > 0 ? ' at' : ''}`}
              </>
            )}
          </div>
        ) : (
          <FormControl hideFormLabel withError={roleHasError} errorMessage={roleErrorMessage}>
            <SelectFilterAsync
              options={roleOptions}
              value={selectedRole}
              placeholder='Select a role'
              onChange={onChange}
              handleFetch={handleFetch}
              loading={rolesLoading}
              processing={loading}
              limit={rolesLimit}
              overflowMessage={rolesOverflowMessage}
              alwaysFetchOnOpen
            />
          </FormControl>
        )}

        <div className={styles.location}>
          {readOnly ? (
            <div className={styles.locationLabelList}>
              {locations.map((location) => (
                <Location key={location.id} name={location.name} />
              ))}
            </div>
          ) : (
            <>
              <span className={styles.atLabel}>at</span>
              <div className={styles.locations}>
                {locations.map((location) => (
                  <div key={location.id} className={styles.locationListItem}>
                    <Location name={location.name} />
                    <div className={styles.control}>
                      <IconButton
                        onClick={
                          handleRemoveLocationFromUserPermission
                            ? () => handleRemoveLocationFromUserPermission(location.id, index)
                            : doNothingCallback
                        }
                        loading={isProcessing}
                      >
                        <TextIcon icon={Icon.RemoveCircle} additionalStyles={styles.removeIcon} />
                      </IconButton>
                    </div>
                  </div>
                ))}
                <FormControl label='' additionalStyles={`full-width-button ${styles.addLocation}`} hideFormLabel>
                  <Button
                    onClick={() => handleSetLocationsModalVisibility(true)}
                    label='+ Add Locations'
                    variant='secondary-gray-bg'
                    disabled={loading}
                  />
                </FormControl>
              </div>
            </>
          )}
        </div>
      </div>
      {!readOnly && (
        <div className={styles.removePermission}>
          <IconButton
            onClick={handleRemoveUserPermission ? () => handleRemoveUserPermission(index) : doNothingCallback}
            loading={isProcessing}
          >
            <TextIcon icon={Icon.RemoveCircle} additionalStyles={styles.removeIcon} />
          </IconButton>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  isSelectingLocations: state.companyLocations.modalVisible,
});

const actionCreators = {
  loadRoles,
  prepareCompanyLocationsModal,
};

const connector = connect(mapStateToProps, actionCreators);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UserPermissionsField);
