import SelectFilter, { Option } from 'components/SelectFilter/SelectFilter';
import React from 'react';
import styles from './MenuConfigPicker.module.scss';
import TextIcon, { Icon } from 'components/Icon/TextIcon';
import StatusIndicator from 'components/StatusIndicator/StatusIndicator';
import { IMenuGroup } from '@ready/menu.core';
import { OptionProps, SingleValueProps, components } from 'react-select';
import { NoMenusAssignedPlaceholder } from '../../../../sharedMenuItems/pages/locations/components/NoMenusAssignedPlaceholder';

interface Props {
  className?: string;
  menuConfigs: IMenuGroup[];
  activeMenuConfig?: IMenuGroup;
  userSelectedMenuConfig?: IMenuGroup;
  onChange: (menuConfigId: string) => void;
}

export const MenuConfigPicker = ({
  className,
  menuConfigs,
  activeMenuConfig,
  userSelectedMenuConfig,
  onChange,
}: Props) => {
  const options = menuConfigs.map<Option<IMenuGroup>>((mc: IMenuGroup) => ({ label: mc.name, value: mc }));

  const onChangeSelected = ({ value }: Option<IMenuGroup>) => {
    onChange(value._id);
  };

  return (
    <div className={className}>
      <SelectFilter
        options={options}
        customComponents={{ Option: MenuConfigOption, SingleValue: SelectedMenuConfig }}
        onChange={onChangeSelected}
        value={
          userSelectedMenuConfig
            ? options.find((x) => x.value._id === userSelectedMenuConfig?._id)
            : options.find((x) => x.value._id === activeMenuConfig?._id)
        }
        placeholder={<NoMenusAssignedPlaceholder />}
      />
    </div>
  );
};

const SelectedMenuConfig = (props: SingleValueProps<Option<IMenuGroup>>) => {
  const menuConfig = props.data.value;
  const label = props.data.label;

  return (
    <components.SingleValue {...props}>
      <MenuConfigView menuConfig={menuConfig} label={label} />
    </components.SingleValue>
  );
};

const MenuConfigOption = (props: OptionProps<Option<IMenuGroup>, boolean>): JSX.Element => {
  const menuConfig = props.data.value;
  const label = props.data.label;

  return (
    <components.Option {...props}>
      <MenuConfigView menuConfig={menuConfig} label={label} />
    </components.Option>
  );
};

const MenuConfigView = ({ menuConfig, label }: { menuConfig: IMenuGroup; label: string }) => {
  return (
    <div className={styles.option}>
      <StatusIndicator active={menuConfig.isActive || menuConfig.isServerFlowActive} />
      {label}
      {menuConfig.parentTemplateId !== undefined && <TextIcon icon={Icon.Share} additionalStyles={styles.shareIcon} />}
      {menuConfig.isActive === false && menuConfig.isServerFlowActive === false && (
        <span className={styles.inactive}>(inactive)</span>
      )}
    </div>
  );
};
