import EmptyResult from 'components/EmptyResult/EmptyResult';
import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ContextParams } from 'types/ContextParams.interface';
import { useAppDispatch, useAppSelector } from 'redux/store';
import InfoCardList from 'components/InfoCard/InfoCardList';
import { SharedMenuItemsLayout } from 'sharedMenuItems/SharedMenuItemsLayout';
import { SharedMenuItemsTabId } from 'sharedMenuItems/SharedMenuItemsTabs';
import styles from './ModifierGroupListPage.module.scss';
import { ListLoadingSkeletons } from 'sharedMenuItems/components/ListLoadingSkeletons';
import { getSharedModifierGroupsThunk } from './redux/actions';
import { ModifierGroupListPageControls } from './components/ModifierGroupListPageControls';
import { ModifierGroupInfoCard } from './components/ModifierGroupInfoCard';
import { ITemplateModifierGroup } from '@ready/menu.core';
import { useSearchParams } from 'hooks';
import { decodeUriString } from 'utils/urlUtils/decodeUriString';
import InfoCard from 'components/InfoCard/InfoCard';

const ModifierGroupListPage = () => {
  const { contextId: companyId } = useParams<ContextParams>();
  const { query, page } = useSearchParams(true);
  const dispatch = useAppDispatch();
  const decodedQuery = decodeUriString(query || '');

  const { loading, modifierGroups, pagination } = useAppSelector((state) => state.sharedMenuItems.modifierGroups.list);
  const isFilterSet = decodedQuery;
  const showCreateFirstMessage = !loading && !isFilterSet && modifierGroups.length === 0;

  const getSharedModifierGroups = useCallback(() => {
    dispatch(getSharedModifierGroupsThunk({ companyId, query: decodedQuery, page }));
  }, [companyId, decodedQuery, dispatch, page]);

  useEffect(() => {
    getSharedModifierGroups();
  }, [getSharedModifierGroups]);

  return (
    <SharedMenuItemsLayout tab={SharedMenuItemsTabId.ModifierGroups}>
      <div className={styles.container}>
        <ModifierGroupListPageControls />
        {!showCreateFirstMessage ? (
          <InfoCardList paginationProps={pagination}>
            {loading ? (
              <ListLoadingSkeletons />
            ) : (
              modifierGroups.map((itemGroup: ITemplateModifierGroup) => (
                <ModifierGroupInfoCard
                  key={itemGroup._id}
                  modifierGroup={itemGroup}
                  onDelete={() => {
                    getSharedModifierGroups();
                  }}
                />
              ))
            )}
            {!loading && modifierGroups.length === 0 && (
              <InfoCard>
                <div className={styles.noResults}>No Results Found</div>
              </InfoCard>
            )}
          </InfoCardList>
        ) : (
          <EmptyResult
            title='Create Your First Modifier Group'
            paragraph='Click the ‘+ New Modifier Group’ button to start building your first shared modifier group. These groups can then be added to shared items.'
          />
        )}
      </div>
    </SharedMenuItemsLayout>
  );
};

export { ModifierGroupListPage };
