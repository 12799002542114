import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/store';
import { IPayoutHistoryReportState } from './types';

export const selectPayoutHistoryReport = createSelector(
  (state: RootState) => state,
  ({ session, reports: { payoutHistory } }: RootState): IPayoutHistoryReportState & { contextId: string } => {
    return {
      contextId: session.contextSession.id,
      ...payoutHistory,
    };
  }
);
