export const LOCATIONS_ACTIONS = {
  GET_LOCATIONS_LIST_BEGIN: 'GET_LOCATIONS_LIST_BEGIN',
  GET_LOCATIONS_LIST_SUCCESS: 'GET_LOCATIONS_LIST_SUCCESS',
  GET_LOCATIONS_LIST_ERROR: 'GET_LOCATIONS_LIST_ERROR',

  UPDATE_LOCATIONS_LIST_PARAMETERS: 'UPDATE_LOCATIONS_LIST_PARAMETERS',
};

export const TABLE_CONFIGURATION_ACTIONS = {
  GET_TABLE_CONFIGURATION_TARGET_LIST_BEGIN: 'GET_TABLE_CONFIGURATION_TARGET_LIST_BEGIN',
  GET_TABLE_CONFIGURATION_TARGET_LIST_SUCCESS: 'GET_TABLE_CONFIGURATION_TARGET_LIST_SUCCESS',
  GET_TABLE_CONFIGURATION_TARGET_LIST_ERROR: 'GET_TABLE_CONFIGURATION_TARGET_LIST_ERROR',

  UPDATE_TABLE_CONFIGURATION_TARGET_LIST_PARAMETERS: 'UPDATE_TABLE_CONFIGURATION_TARGET_LIST_PARAMETERS',
  UPDATE_TABLE_CONFIGURATION_TARGET_CODE: 'UPDATE_TABLE_CONFIGURATION_TARGET_CODE',
  UPDATE_TABLE_CONFIGURATION_POS_ORDER_TYPE: 'UPDATE_TABLE_CONFIGURATION_POS_ORDER_TYPE',
  UPDATE_TABLE_CONFIGURATION_ORDER_EXPERIENCE_TYPE: 'UPDATE_TABLE_CONFIGURATION_ORDER_EXPERIENCE_TYPE',
  UPDATE_TABLE_CONFIGURATION_BEGIN: 'UPDATE_TABLE_CONFIGURATION_BEGIN',
  UPDATE_TABLE_CONFIGURATION_SUCCESS: 'UPDATE_TABLE_CONFIGURATION_SUCCESS',
  CREATE_TABLE_CONFIGURATION_SUCCESS: 'CREATE_TABLE_CONFIGURATION_SUCCESS',
  UPDATE_TABLE_CONFIGURATION_ERROR: 'UPDATE_TABLE_CONFIGURATION_ERROR',

  RESET_UNSAVED_CHANGES: 'RESET_UNSAVED_CHANGES',

  SAVE_TABLE_CONFIGURATION_BEGIN: 'SAVE_TABLE_CONFIGURATION_BEGIN',
  SAVE_TABLE_CONFIGURATION_SUCCESS: 'SAVE_TABLE_CONFIGURATION_SUCCESS',
  SAVE_TABLE_CONFIGURATION_ERROR: 'SAVE_TABLE_CONFIGURATION_ERROR',

  SYNC_TABLE_CONFIGURATIONS_BEGIN: 'SYNC_TABLE_CONFIGURATIONS_BEGIN',
  SYNC_TABLE_CONFIGURATIONS_SUCCESS: 'SYNC_TABLE_CONFIGURATIONS_SUCCESS',
  SYNC_TABLE_CONFIGURATIONS_ERROR: 'SYNC_TABLE_CONFIGURATIONS_ERROR',

  LOAD_SELECTED_LOCATION: 'LOAD_SELECTED_LOCATION',

  DELETE_TABLE_CONFIGURATION_BEGIN: 'DELETE_TABLE_CONFIGURATION_BEGIN',
  DELETE_TABLE_CONFIGURATION_SUCCESS: 'DELETE_TABLE_CONFIGURATION_SUCCESS',
  DELETE_TABLE_CONFIGURATION_ERROR: 'DELETE_TABLE_CONFIGURATION_ERROR',

  VALIDATION_ERROR: 'VALIDATION_ERROR',
  CLEAR_VALIDATION_ERROR: 'CLEAR_VALIDATION_ERROR',
};

export const REVENUE_CENTERS_ACTIONS = {
  GET_REVENUE_CENTERS_BEGIN: 'GET_REVENUE_CENTERS_BEGIN',
  GET_REVENUE_CENTERS_SUCCESS: 'GET_REVENUE_CENTERS_SUCCESS',
  GET_REVENUE_CENTERS_ERROR: 'GET_REVENUE_CENTERS_ERROR',
};

export const POS_ORDER_TYPES_ACTIONS = {
  GET_POS_ORDER_TYPES_BEGIN: 'GET_POS_ORDER_TYPES_BEGIN',
  GET_POS_ORDER_TYPES_SUCCESS: 'GET_POS_ORDER_TYPES_SUCCESS',
  GET_POS_ORDER_TYPES_ERROR: 'GET_POS_ORDER_TYPES_ERROR',
};
