import React from 'react';
import { useClassNames } from '../../../utils/cssUtils';
import styles from './MenuItemPosBlock.module.scss';
import MissingValue from '../../../components/Value/MissingValue';
import ErrorValue from '../../../components/ErrorValue/ErrorValue';
import { useParams } from 'react-router-dom';
import { ContextParams } from 'types/ContextParams.interface';
import { isSharedMenu } from 'sharedMenuItems/sharedMenuItems.utils';

export interface MenuItemPosBlockProps {
  id?: string;
  name: string;
  categories: string[];
  draft: boolean;
  deleted: boolean;
  parentDisabled?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  compactView?: boolean;
  className?: string;
}

const MenuItemPosBlock = ({
  id,
  name,
  categories,
  draft,
  deleted,
  parentDisabled = false,
  disabled = false,
  hidden = false,
  compactView = false,
  className,
}: MenuItemPosBlockProps): JSX.Element => {
  const { contextId: companyId, locationId } = useParams<ContextParams>();

  const nameClassName = useClassNames(
    [
      styles.name,
      { parentDisabled: parentDisabled },
      { disabled: disabled },
      { hidden: hidden },
      { large: !compactView },
      { small: compactView },
    ],
    styles
  );

  const detailsClassName = useClassNames(
    [
      styles.details,
      { parentDisabled: parentDisabled },
      { disabled: disabled },
      { hidden: hidden },
      { large: !compactView },
      { small: compactView },
    ],
    styles
  );

  const missingClassName = useClassNames(
    [
      styles.missing,
      { parentDisabled: parentDisabled },
      { disabled: disabled },
      { hidden: hidden },
      { large: !compactView },
      { small: compactView },
    ],
    styles
  );

  return (
    <div className={className}>
      {!isSharedMenu(companyId, locationId) && (
        <>
          {draft || deleted || !id ? (
            compactView ? (
              <div className={compactView ? styles.compactContainer : undefined}>
                <div className={detailsClassName}>
                  <MissingValue />
                </div>
                {deleted ? (
                  <ErrorValue text='POS Item Deleted' />
                ) : (
                  <div className={missingClassName}>No Item Linked</div>
                )}
              </div>
            ) : (
              <>
                {deleted ? (
                  <ErrorValue text='POS Item Deleted' />
                ) : (
                  <div className={missingClassName}>No Item Linked</div>
                )}
                <div className={detailsClassName}>
                  <MissingValue />
                </div>
              </>
            )
          ) : compactView ? (
            <div className={styles.compactContainer}>
              <div className={detailsClassName}>
                <span className={styles.id}>{id}</span>
              </div>
              <div className={nameClassName}>{name}</div>
            </div>
          ) : (
            <>
              <div className={nameClassName}>{name}</div>
              <div className={detailsClassName}>
                <span className={styles.id}>{id}</span>
                <span className={styles.separator}>|</span>
                <span className={styles.categories}>{categories.join(', ')}</span>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MenuItemPosBlock;
