import React from 'react';
import TextIcon, { Icon } from 'components/Icon/TextIcon';
import styles from './ForcedModifiers.module.scss';
import IconButton from 'components/IconButton/IconButton';
import DisabledBadge from 'menus/components/DisabledBadge';
import { useClassNames } from 'utils/cssUtils';
import { useAppDispatch } from 'redux/store';
import { Routes } from 'menus/MenuBuilderRouter';
import { removeReadyForcedModifierToMenuItem } from 'menus/redux/ItemsAndModsActions';
import { Link, useParams } from 'react-router-dom';
import { IForcedModifier, MenuItemInactiveStates, Status } from '@ready/menu.core';
import { DollarValue } from 'components/Value';
import { ContextParams } from 'types/ContextParams.interface';

interface Props {
  forcedModifier: IForcedModifier;
  removable?: boolean;
}

export const ForcedModifier = ({ forcedModifier, removable = true }: Props) => {
  const dispatch = useAppDispatch();
  const { contextId: companyId, locationId, id } = useParams<ContextParams>();
  const menuItemRoute = Routes.getForcedModItemPage(companyId, locationId, id, forcedModifier.itemId);

  const onRemoveClick = () => {
    dispatch(removeReadyForcedModifierToMenuItem(forcedModifier.itemId));
  };

  const isPosItemDeleted = forcedModifier.inactiveStates.includes(MenuItemInactiveStates.itemDeletedOnPOS);

  return (
    <div className={styles.forcedMod}>
      <div className={styles.forcedModDetails}>
        <div>
          <Link className={styles.modifierLink} to={menuItemRoute}>
            {forcedModifier.displayName}
          </Link>
          {!isPosItemDeleted ? (
            <>
              {forcedModifier.posItemId && <span className={styles.forcedModId}>({forcedModifier.posItemId})</span>}
              <ForcedModBadge forcedMod={forcedModifier} />
            </>
          ) : (
            <PosItemDeletedAlert />
          )}
        </div>
      </div>
      <div className={styles.removeAndDollarContainer}>
        <ForcedModPrice forcedModifier={forcedModifier} isPosItemDeleted={isPosItemDeleted}></ForcedModPrice>
        {removable && (
          <IconButton onClick={onRemoveClick} id='header-sidebar-toggler'>
            <TextIcon icon={Icon.RemoveCircle}></TextIcon>
          </IconButton>
        )}
      </div>
      
    </div>
  );
};

/**
 * Retrieves the appropriate badge based on supplied forced modifier.
 */
const ForcedModBadge = ({ forcedMod }: { forcedMod: IForcedModifier }): JSX.Element | null => {
  if (forcedMod.status === Status.disabled) {
    return <DisabledBadge className={styles.disabledBadge} />;
  } else if (!forcedMod.inStock) {
    return <span className={styles.forcedModOutOfStock}>SOLD OUT</span>;
  }
  return null;
};

const ForcedModPrice = ({
  isPosItemDeleted,
  forcedModifier,
}: {
  isPosItemDeleted: boolean;
  forcedModifier: IForcedModifier;
}): JSX.Element => {
  const isPriceLevelDeleted = forcedModifier.inactiveStates.includes(MenuItemInactiveStates.priceDeletedOnPOS);

  const forcedModPriceClassNames = useClassNames(
    [styles.forcedModPrice, { disabled: forcedModifier.status === Status.disabled }],
    styles
  );

  if (isPosItemDeleted) {
    return <>—</>;
  } else if (isPriceLevelDeleted) {
    return <PriceDeletedAlert />;
  } else {
    return (
      <DollarValue
        value={
          forcedModifier.priceLevels?.find((priceLevel) => priceLevel.id === forcedModifier.activePriceLevel)
            ?.pricePerUnit ||
          forcedModifier.price ||
          0
        }
        additionalStyles={forcedModPriceClassNames}
      />
    );
  }
};

const PriceDeletedAlert = () => (
  <span className={styles.priceDeleted}>
    <TextIcon icon={Icon.Alert} />
    &nbsp;Price
    <br />
    Deleted
  </span>
);

const PosItemDeletedAlert = () => (
  <div className={styles.posItemDeleted}>
    &nbsp;(
    <span>
      <TextIcon icon={Icon.Alert} /> POS Item Deleted
    </span>
    )
  </div>
);
