import { PaginationResponse } from '@ready/dashboardv2api.contracts';
import { IBulkEditMenuItem, ILocationAssignments, ITemplateLocation } from '@ready/menu.core';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { set200Toast, toastErrorState } from 'redux/actions/uiActions/responseStateActions';
import { RootState } from 'redux/store';
import { AllLocations } from 'sharedMenuItems/redux/editAssignedLocations/assignLocationsSlice';
import {
  LinkStatusFilter,
  fetchTemplateChildMenuItems,
  listMenuItemLocations,
  updateMenuItemLocations,
} from '../ItemsAndModsAssignedLocationsService';
import {
  loadItemsFailure,
  loadItemsFulfilled,
  loadItemsPending,
  loadMoreItemsFailure,
  loadMoreItemsFulfilled,
  loadMoreItemsPending,
  selectItems,
} from 'redux/slices/infiniteScrollingListModalSlice';
import { itemAndModsAssignedLocationsSlice } from './slice';

interface FetchSharedMenuItemAssignedLocationListArgs {
  companyId: string;
  itemId: string;
  page?: number;
  linkStatusFilter?: LinkStatusFilter;
  locationSearchTerm?: string;
}

export const getTemplateChildMenuItems = createAsyncThunk<
  PaginationResponse<IBulkEditMenuItem>,
  FetchSharedMenuItemAssignedLocationListArgs
>(
  'sharedMenuItems/itemsAndMods/assignedLocations/fetch',
  ({ companyId, page, linkStatusFilter, itemId, locationSearchTerm }: FetchSharedMenuItemAssignedLocationListArgs) =>
    fetchTemplateChildMenuItems(companyId, itemId, locationSearchTerm, linkStatusFilter, page)
);

interface IGetAssignableLocationListThunkArgs {
  companyId: string;
  itemId: string;
  locationSearchTerm?: string;
  page?: number;
  isLoadMore?: boolean;
}

export const getAssignableLocationListThunk = createAsyncThunk<
  PaginationResponse<ITemplateLocation> | undefined,
  IGetAssignableLocationListThunkArgs
>(
  'sharedMenuItems/itemsAndMods/assignedLocations/locations',
  async (
    { companyId, itemId, locationSearchTerm, page, isLoadMore }: IGetAssignableLocationListThunkArgs,
    thunkApi
  ) => {
    if (isLoadMore) {
      thunkApi.dispatch(loadMoreItemsPending());
    } else {
      thunkApi.dispatch(loadItemsPending());
    }

    try {
      const result = await listMenuItemLocations(companyId, itemId, locationSearchTerm, page);

      if (isLoadMore) {
        thunkApi.dispatch(loadMoreItemsFulfilled(result.items));
      } else {
        thunkApi.dispatch(loadItemsFulfilled(result.items));

        if (!locationSearchTerm) {
          thunkApi.dispatch(itemAndModsAssignedLocationsSlice.actions.storeTotalLocationsCount(result.total));
        }
      }

      const selected = result.items.filter((item) => item.hasTemplateAssigned).map((item) => item._id);

      if (selected.length > 0) {
        thunkApi.dispatch(selectItems(selected));
      }

      return result;
    } catch (error) {
      if (isLoadMore) {
        thunkApi.dispatch(loadMoreItemsFailure());
      } else {
        thunkApi.dispatch(loadItemsFailure());
      }

      // @ts-ignore
      thunkApi.dispatch(toastErrorState(error));
    }
  }
);

interface IUpdateAssignedLocationsThunkArgs {
  companyId: string;
  itemId: string;

  allLocations?: AllLocations;
  assignToLocations: string[];
  unassignFromLocations: string[];
}

export const updateAssignedLocationsThunk = createAsyncThunk<
  void,
  IUpdateAssignedLocationsThunkArgs,
  { state: RootState }
>(
  'sharedMenuItems/itemsAndMods/item/locations/update',
  async (
    { companyId, itemId, allLocations, assignToLocations, unassignFromLocations }: IUpdateAssignedLocationsThunkArgs,
    thunkApi
  ) => {
    let locationAssignments: ILocationAssignments = {};

    if (allLocations !== undefined) {
      locationAssignments.allLocations = allLocations === 'assign' ? 'assign' : 'unassign';
    } else {
      locationAssignments = { assignToLocations, unassignFromLocations };
    }

    try {
      await updateMenuItemLocations(companyId, itemId, locationAssignments);
      thunkApi.dispatch(set200Toast('Success! Changes saved.'));
    } catch (e) {
      // @ts-ignore
      thunkApi.dispatch(toastErrorState(e.status, e.message));
    }
  }
);
