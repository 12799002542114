import { GiftCardProvider, IGivexConfig } from '@ready/dashboardv2api.contracts';
import { createSlice } from '@reduxjs/toolkit';

interface State {
  options: IGivexConfig;
  loading: boolean;
}

const initialState: State = {
  options: {
    provider: GiftCardProvider.Givex,
    addAccountDetailsCommentToPayment: false,
    giftcardTenderId: '',
    host: '',
    userId: '',
    password: '',
    requiresPin: true,
  },
  loading: true,
};

const givexSlice = createSlice({
  name: 'companyLocations/paymentProcessing/giftcards/givex',
  initialState: initialState,
  reducers: {
    updateOptions: (state: State, action: { payload: Partial<IGivexConfig> }) => {
      state.options = { ...state.options, ...action.payload };
    },
    resetState: () => initialState,
  },
});

export const { updateOptions, resetState } = givexSlice.actions;

export { givexSlice };
