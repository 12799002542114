import React, { ReactNode } from 'react';
import styles from './CompanyTablePage.module.scss';
import { Tooltip } from 'components/Tooltip/Tooltip';
import TextIcon, { Icon } from 'components/Icon/TextIcon';

export interface TableHeaderProps {
  title: string;
  titleTooltip?: string;
  description?: ReactNode;
  controls?: JSX.Element | JSX.Element[] | null;
}

const TableHeader = (props: TableHeaderProps) => {
  const { title, description, controls, titleTooltip } = props;

  return (
    <div className={styles.header}>
      <div className={styles.titleRow}>
        <div className={styles.headerTitle}>
          <h3 className={styles.headerTitleText}>{title}</h3>
          {titleTooltip && (
            <Tooltip text={titleTooltip}>
              <TextIcon icon={Icon.InfoCircle} />
            </Tooltip>
          )}
        </div>

        {controls && <div className={styles.headerControls}>{controls}</div>}
      </div>
      {description && getDescriptionComponent(description)}
    </div>
  );
};

const getDescriptionComponent = (description: string | ReactNode) =>
  typeof description === 'string' ? <p className={styles.headerDescription}>{description}</p> : description;

export default TableHeader;
