import React from 'react';
import { useAuth0 } from 'context/Auth0';
import styles from './app-layout.module.scss';
import NavigationButton from './NavigationButton';
import NavigationLink from './NavigationLink';
import { ISidebarOption, IContextSession } from 'redux/initialStates/session/session';
import { isValidSidebarOption } from 'utils/sidebar.utils';
import MultiLevelSidebar from './MultiLevelSidebar';
import { RootState, useAppSelector } from 'redux/store';
import { HelpAndSupportNavigationButton } from './Sidebar/components/HelpAndSupportNavigationButton';

export interface SidebarNavigationProps {
  contextSession: IContextSession;
  sidebarOptions: ISidebarOption[];
}

const SidebarNavigation = () => {
  const { contextSession, sidebar } = useAppSelector((state: RootState) => state.session);
  const { type: contextType, id: contextId } = contextSession;
  const { logout } = useAuth0();

  return (
    <div className={styles.sidebarNavigation}>
      <div className={styles.sidebarNavigationPages}>
        {sidebar.options.map((option: ISidebarOption, index: number) => {
          const redirectPrefix = contextType === 'company' ? `/companies/${contextId}` : '';
          if (
            isValidSidebarOption(contextType as 'system' | 'company', option)
            && option.enabled
            && option.hasPermission
          ) {
            if (option.children && option.children.length > 0) {
              return <MultiLevelSidebar key={index} option={option} redirectPrefix={redirectPrefix} />;
            } else if (option.children && option.children.length === 0) {
              return null;
            } else {
              const url = `${redirectPrefix}${option.url}`;
              return <NavigationLink key={index} to={url} label={option.label} />;
            }
          } else {
            return null;
          }
        })}
        <HelpAndSupportNavigationButton />
      </div>
      <NavigationButton onClick={logout} label='Log Out' />
    </div>
  );
};

export default SidebarNavigation;
