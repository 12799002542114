import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getSelectedTenderTypeOption } from '../GiftCardsEditPanel';
import { ITenderType } from 'companyLocations/redux/initialStates/paymentProcessing';
import Checkbox from 'components/Checkbox/Checkbox';
import { FormControl } from 'components/Form';
import SelectFilter from 'components/SelectFilter/SelectFilter';
import TextInput from 'components/TextInput/TextInput';
import { Option } from 'components/SelectFilter/SelectFilter';
import { Controller, useFormContext } from 'react-hook-form';
import { updateOptions } from './redux/slice';
import { ToggleEditWrapper } from 'components/ToggleEditWrapper/ToggleEditWrapper';
import { IGivexConfig } from '@ready/dashboardv2api.contracts';
import Toggle from 'components/Toggle/Toggle';

export interface GivexFormProps {
  loading: boolean;
  options: IGivexConfig;
  tenderTypes: ITenderType[];
}

const GivexForm = ({ loading, options, tenderTypes }: GivexFormProps) => {
  const dispatch = useDispatch();
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<IGivexConfig>();

  const watchGiftcardTenderId = watch('giftcardTenderId');

  useEffect(() => {
    const sub = watch((val: Partial<IGivexConfig>) => {
      dispatch(updateOptions(val));
    });

    return () => sub.unsubscribe();
  }, [dispatch, watch]);

  const tenderTypeSelectedOption = React.useMemo(
    () => getSelectedTenderTypeOption(tenderTypes, watchGiftcardTenderId || options.giftcardTenderId),
    [options.giftcardTenderId, tenderTypes, watchGiftcardTenderId]
  );

  const giftCardTenderTypes = React.useMemo(
    () =>
      tenderTypes.map<Option>((tenderType: ITenderType) => ({
        label: tenderType.name,
        value: tenderType.id,
      })),
    [tenderTypes]
  );

  // We can assume that if a userId exists, a config exists, and therefor a password exists.
  // If a password already exists, it is not required during edit.
  const passwordExists = options.userId;
  const [passwordRequired, setPasswordRequired] = useState<boolean>(!passwordExists);

  return (
    <>
      <FormControl label='Givex Host *' withError={!!errors.host} errorMessage={errors.host?.message}>
        <Controller
          control={control}
          defaultValue={options.host || ''}
          name='host'
          rules={{ required: 'Host is required.' }}
          render={({ field }) => (
            <TextInput
              {...field}
              type='text'
              placeholder='e.g. dc-xxx-givex.com'
              loading={loading}
              withError={!!errors.host}
            />
          )}
        />
      </FormControl>
      <FormControl label='User ID *' withError={!!errors.userId} errorMessage={errors.userId?.message}>
        <Controller
          defaultValue={options.userId || ''}
          control={control}
          name='userId'
          rules={{ required: 'User ID is required.' }}
          render={({ field }) => (
            <TextInput placeholder='Enter your Givex ID' loading={loading} withError={!!errors.userId} {...field} />
          )}
        />
      </FormControl>
      <FormControl label='Password *' withError={!!errors.password} errorMessage={errors.password?.message}>
        <Controller
          defaultValue={options.password || ''}
          control={control}
          name='password'
          rules={{ required: { value: passwordRequired, message: 'Password is required.' } }}
          render={({ field }) => (
            <ToggleEditWrapper
              readonlyValue='*******'
              editButtonText='Edit Password'
              showControl={!passwordExists}
              onEdit={() => setPasswordRequired(true)}
            >
              <TextInput
                type='password'
                placeholder='Enter your Givex password'
                loading={loading}
                withError={!!errors.password}
                {...field}
              />
            </ToggleEditWrapper>
          )}
        />
      </FormControl>
      <FormControl label='Requires PIN'>
        <Controller
          defaultValue={options.requiresPin || true}
          control={control}
          name='requiresPin'
          render={({ field }) => <Toggle checked={field.value} {...field} />}
        />
      </FormControl>
      <FormControl label='Guest Account Details'>
        <Controller
          defaultValue={options.addAccountDetailsCommentToPayment || false}
          control={control}
          name='addAccountDetailsCommentToPayment'
          render={({ field }) => (
            <Checkbox
              label='Submit guest’s account number to POS along with payment'
              checked={field.value}
              {...field}
            />
          )}
        />
      </FormControl>
      <FormControl
        label='Gift Card Tender Type *'
        withError={!!errors.giftcardTenderId}
        errorMessage={errors.giftcardTenderId?.message}
      >
        <Controller
          defaultValue={options.giftcardTenderId}
          control={control}
          name='giftcardTenderId'
          rules={{ required: 'Tender Type is required.' }}
          render={({ field }) => (
            <SelectFilter
              options={giftCardTenderTypes}
              loading={loading}
              placeholder='Select a tender type'
              withError={!!errors.giftcardTenderId}
              value={tenderTypeSelectedOption}
              onChange={(opt) => {
                field.onChange(opt.value as string);
              }}
            />
          )}
        />
      </FormControl>
    </>
  );
};

export default GivexForm;
