export const TRANSACTION_DETAIL_ACTIONS = {
  GET_TRANSACTION_BEGIN: 'GET_TRANSACTION_BEGIN',
  GET_TRANSACTION_SUCCESS: 'GET_TRANSACTION_SUCCESS',
  GET_TRANSACTION_ERROR: 'GET_TRANSACTION_ERROR',

  CHECK_TRANSACTION_REFUND_ACCESS_SUCCESS: 'CHECK_TRANSACTION_REFUND_ACCESS_SUCCESS',
  CHECK_TRANSACTION_REFUND_ACCESS_ERROR: 'CHECK_TRANSACTION_REFUND_ACCESS_ERROR',

  VALIDATE_REFUND_AMOUNT: 'VALIDATE_REFUND_AMOUNT',
  REFUND_TRANSACTION_BEGIN: 'REFUND_TRANSACTION_BEGIN',
  REFUND_TRANSACTION_SUCCESS: 'REFUND_TRANSACTION_SUCCESS',
  REFUND_TRANSACTION_ERROR: 'REFUND_TRANSACTION_ERROR',
  RESET_REFUND_STATE: 'RESET_REFUND_STATE',

  RETRY_PAYMENT_SYNC: 'RETRY_PAYMENT_SYNC',
  RETRY_PAYMENT_SYNC_PROCESSING: 'RETRY_PAYMENT_SYNC_PROCESSING',
  RETRY_PAYMENT_SYNC_SUCCESS: 'RETRY_PAYMENT_SYNC_SUCCESS',
};
