import React from 'react';
import styles from './PanelTitle.module.scss';
import { useClassNames } from '../../utils/cssUtils';
import { Link } from 'react-router-dom';

interface PanelTitleProps {
  title: string;
  linkTo?: string;
  disabled?: boolean;
  additionalComponent?: React.ReactNode;
}

const Container = ({ linkTo, children }: { linkTo?: string; children: React.ReactNode }): JSX.Element => (
  <>
    {linkTo ? (
      <Link to={linkTo} className={styles.container}>
        {children}
      </Link>
    ) : (
      <div className={styles.container}>{children}</div>
    )}
  </>
);

const PanelTitle = ({ title, linkTo, disabled = false, additionalComponent }: PanelTitleProps) => {
  const titleClassName = useClassNames(
    [
      {
        title: true,
        magenta: !!linkTo,
        gray: disabled,
      },
    ],
    styles
  );

  return (
    <Container linkTo={linkTo}>
      <h3 className={titleClassName}>{title}</h3>
      {additionalComponent}
    </Container>
  );
};

export default PanelTitle;
