import { selectCompanyPunchOptions } from 'admin/companies/CompanySettingsPage/loyalty/redux/selectors';
import { getCompanyLoyaltyConfigThunk } from 'admin/companies/CompanySettingsPage/loyalty/redux/thunks/getCompanyLoyaltyConfigThunk';
import { Icon } from 'components/Icon/TextIcon';
import LoadingSpinnerInline from 'components/LoadingSpinnerInline/LoadingSpinnerInline';
import Notification from 'components/Notification/Notification';
import SelectFilterAsync from 'components/SelectFilter/SelectFilterAsync';
import { loadDiscounts, PANEL_LOYALTY_ACTIONS } from '../../redux/PanelLoyaltyActions';
import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { FormControl } from '../../../components/Form';
import { Option } from '../../../components/SelectFilter/SelectFilter';
import TextInput from '../../../components/TextInput/TextInput';
import { IPosLoyaltyPanel } from '../../redux/PanelLoyaltyState';
import { IUpdateLocationParam } from '../LocationsEditPage';
import styles from './PunchhForm.module.scss';
import { debounce } from 'lodash';

export interface PunchhProps {
  isProcessing: boolean;
  updateLocationParam: IUpdateLocationParam;
  posLoyaltyPanelInitialState: IPosLoyaltyPanel;
};

const PunchhForm = ({
  isProcessing,
  updateLocationParam,
  posLoyaltyPanelInitialState,
}: PunchhProps) => {
  const { companyId, id } = useParams<{ companyId: string; id: string; tab: string }>();
  const dispatch = useAppDispatch();

  const companyPunchOptions = useAppSelector(selectCompanyPunchOptions);
  const companyLoyaltyConfigLoading = useAppSelector((state) => state.companies.companySettings.loyalty.isLoading);
  const companyPunchOptionsExist = companyPunchOptions !== undefined;

  useEffect(() => {
    dispatch(getCompanyLoyaltyConfigThunk({ companyId: companyId }));
    dispatch(loadDiscounts(id, companyId, ''));
  }, [companyId, id, dispatch]);

  const handleFetch = useCallback(
    (query: string): void => {
      const loadPosDiscountsDebounced = debounce(loadDiscounts, 300);
      loadPosDiscountsDebounced(id, companyId, query);
    },
    [companyId, id]
  );

  const mapSelectOptions = (optionValue: any): Option | null => {
    if (posLoyaltyPanelInitialState.discounts && posLoyaltyPanelInitialState.discounts.length > 0) {
      return posLoyaltyPanelInitialState.discounts.find(
        (optionObj: Option) => optionObj.value === optionValue
      ) as Option;
    }
    return null;
  };

  if (companyLoyaltyConfigLoading) {
    return (
      <div className={styles.loading}>
        <LoadingSpinnerInline />
      </div>
    );
  }

  return (
    <>
      {!companyPunchOptionsExist && <PunchhCompanySettingsWarning />}
      <FormControl
        label='Store ID *'
        withError={posLoyaltyPanelInitialState.validations.punchh.storeId.hasError}
        errorMessage={posLoyaltyPanelInitialState.validations.punchh.storeId.errorMessage}
      >
        <TextInput
          placeholder='Enter the Punchh Store ID'
          value={posLoyaltyPanelInitialState.punchhOptions?.storeId}
          disabled={!companyPunchOptionsExist}
          onChange={(event) => {
            updateLocationParam(event.target.value, PANEL_LOYALTY_ACTIONS.UPDATE_PUNCHH_STORE_ID);
          }}
          withError={posLoyaltyPanelInitialState.validations.punchh.storeId.hasError}
          loading={isProcessing}
        />
      </FormControl>

      <FormControl
        label='Loyalty Discount *'
        errorMessage={posLoyaltyPanelInitialState.validations.punchh.loyaltyDiscount.errorMessage}
        withError={posLoyaltyPanelInitialState.validations.punchh.loyaltyDiscount.hasError}
      >
        <SelectFilterAsync
          options={posLoyaltyPanelInitialState.discounts}
          value={mapSelectOptions(posLoyaltyPanelInitialState.punchhOptions?.loyaltyDiscount)}
          placeholder='Select discount from POS'
          onChange={(event) => {
            updateLocationParam(event?.value, PANEL_LOYALTY_ACTIONS.UPDATE_PUNCHH_LOYALTY_DISCOUNT);
          }}
          handleFetch={handleFetch}
          loading={posLoyaltyPanelInitialState.loadingDiscounts}
          limit={50}
        />
      </FormControl>
    </>
  );
};

const PunchhCompanySettingsWarning = () => {
  return (
    <Notification type='warning' className={styles.companySettingsWarning}>
      <i className={Icon.InfoCircle} />
      <p>Punchh must be configured on the Company Settings page before setting up at any locations.</p>
    </Notification>
  );
};

export default PunchhForm;
