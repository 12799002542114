import { IOnAccountPaymentSettings } from '@ready/dashboardv2api.contracts';
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { getOnAccountPaymentSettingsThunk } from './thunk';

export interface State {
  settings?: IOnAccountPaymentSettings;
  loading: boolean;
}

export const initialState: State = {
  loading: false,
};

export const onAccountPaymentSettingsSlice = createSlice({
  name: 'paymentProcessing/onAccountPaymentSettings',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<State>) => {
    builder.addCase(getOnAccountPaymentSettingsThunk.pending, (state: State) => {
      state.loading = true;
    });

    builder.addCase(getOnAccountPaymentSettingsThunk.fulfilled, (state: State, action) => {
      state.loading = false;
      state.settings = action.payload;
    });

    builder.addCase(getOnAccountPaymentSettingsThunk.rejected, (state: State) => {
      state.loading = false;
    });
  },
});
