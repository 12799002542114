import React from 'react';
import styles from './MenuItemIconsBlock.module.scss';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';
import { useClassNames } from '../../../utils/cssUtils';

interface MenuItemIconsBlockProps {
  isPopular?: boolean;
  hasSchedule?: boolean;
  containsAlcohol?: boolean;
  alcoholAmount?: number;
  isShared?: boolean;
  className?: string;
}

const MenuItemIconsBlock = ({
  isPopular = false,
  hasSchedule = false,
  containsAlcohol = false,
  isShared = false,
  alcoholAmount,
  className,
}: MenuItemIconsBlockProps): JSX.Element => (
  <div className={useClassNames([styles.container, className], styles)}>
    <div className={styles.shared}>{isShared && <TextIcon icon={Icon.Share} />}</div>
    <div className={styles.popular}>{isPopular && <TextIcon icon={Icon.HeartOutline} />}</div>

    <div className={styles.schedule}>{hasSchedule && <TextIcon icon={Icon.Clock} />}</div>

    <div className={styles.alcohol}>
      {containsAlcohol && (
        <>
          <TextIcon icon={Icon.Martini} />
          <span className={styles.alcoholAmount}>{alcoholAmount}</span>
        </>
      )}
    </div>
  </div>
);

export default MenuItemIconsBlock;
