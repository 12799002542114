import { ITableAssignment } from '@ready/table.core';

const compareServerAssignmentEmployeeNames = (
  tableAssignment: ITableAssignment,
  tableAssignmentToCompare: ITableAssignment
): number => {
  // The π symbol is to ensure that no-name employees are sorted last

  const name = tableAssignment.employee.name || `π${tableAssignment.employee.externalId}`;

  const nameToCompare = tableAssignmentToCompare.employee.name || `π${tableAssignmentToCompare.employee.externalId}`;

  return name.localeCompare(nameToCompare);
};

export default compareServerAssignmentEmployeeNames;
