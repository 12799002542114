import React, { RefObject } from 'react';
import { IMenu, ISchedule, IScheduleLink } from '@ready/menu.core';
import { ILink } from '../../redux/MenusState';
import NamedEntityValidation from '../../types/NamedEntityValidation.interface';
import styles from './EditDetailsPanel.module.scss';
import { Panel } from '../../../components/PanelLayout';
import { Form } from '../../../components/Form';
import DisplayNameInput from './DisplayNameInput';
import DescriptionInput from './DescriptionInput';
import ScheduleFormControl from '../shared/ScheduleFormControl';
import EditPanelFooter from './EditPanelFooter';
import { useClassNames } from 'utils/cssUtils';
import { ReadonlyValueWrapper } from 'components/Value/ReadonlyValueWrapper';

export interface EditDetailsPanelProps {
  menu: IMenu;
  processing: boolean;
  validation: NamedEntityValidation;
  inputRef?: RefObject<HTMLInputElement>;
  onChangeName: (name: string) => void;
  onChangeDescription: (description: string) => void;
  onChangeStatus?: (enabled: boolean) => void;
  onChangeScheduler?: (scheduler: IScheduleLink<ILink> | null) => void;
  schedules?: ISchedule[];
  areSchedulesLoading?: boolean;
  isSharedMenuAssignedToCurrentLocation?: boolean;
  onCancel: () => void;
  onSave: () => void;
}

const EditDetailsPanel = ({
  menu,
  processing,
  validation,
  inputRef,
  onChangeName,
  onChangeDescription,
  onChangeStatus,
  onChangeScheduler,
  onCancel,
  onSave,
  schedules,
  areSchedulesLoading,
  isSharedMenuAssignedToCurrentLocation = false,
}: EditDetailsPanelProps): JSX.Element => (
  <div className={styles.editDetails}>
    <Form hasGroups onSubmit={onSave}>
      <Panel closeVerticalSpacing fullContentArea>
        <div
          className={useClassNames(
            [
              styles.displayName,
              styles.withNoBottomPadding,
              { isSharedMenuAssignedToCurrentLocation: styles.readonlyNameAndDescription },
            ],
            styles
          )}
        >
          <ReadonlyValueWrapper
            readonly={isSharedMenuAssignedToCurrentLocation}
            value={<span className={styles.readonlyDisplayName}>{menu.displayName}</span>}
          >
            <DisplayNameInput
              value={menu.displayName}
              processing={processing}
              validation={validation}
              label='Menu Name *'
              onChange={onChangeName}
              inputRef={inputRef}
            />
          </ReadonlyValueWrapper>
          <ReadonlyValueWrapper
            readonly={isSharedMenuAssignedToCurrentLocation}
            value={<div className={styles.readonlyDescription}>{menu.description}</div>}
          >
            <DescriptionInput
              value={menu.description || ''}
              processing={processing}
              onChange={onChangeDescription}
              className={styles.description}
            />
          </ReadonlyValueWrapper>
        </div>

        {onChangeScheduler && schedules && areSchedulesLoading !== undefined ? (
          <div className={styles.schedulerContainer}>
            <ScheduleFormControl
              schedules={schedules}
              scheduleLink={menu.schedule}
              onChange={onChangeScheduler}
              validation={validation}
              loading={processing}
              areSchedulesLoading={areSchedulesLoading}
            />
          </div>
        ) : null}

        <EditPanelFooter
          status={menu.status}
          statusLabel='Menu Status:'
          processing={processing}
          onChangeStatus={onChangeStatus}
          onCancel={onCancel}
          showFooterSeparator
        />
      </Panel>
    </Form>
  </div>
);

export default EditDetailsPanel;
