import { ITemplateModifierGroup } from '@ready/menu.core';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { set200Toast, toastErrorState } from 'redux/actions/uiActions/responseStateActions';
import {
  deleteSharedModifierGroup,
  getSharedModifierGroup,
  updateSharedModifierGroup,
} from '../../modifierGroupService';

interface FetchSharedModifierGroupArgs {
  companyId: string;
  id: string;
}

const getSharedModifierGroupThunk = createAsyncThunk<ITemplateModifierGroup, FetchSharedModifierGroupArgs>(
  'sharedMenuItems/modifierGroups/details/fetch',
  async (args: FetchSharedModifierGroupArgs, thunkAPI) => {
    return getSharedModifierGroup(args.companyId, args.id);
  }
);

interface SaveSharedModifierGroupArgs {
  companyId: string;
  templateModifierGroup: ITemplateModifierGroup;
}

const saveSharedModifierGroupThunk = createAsyncThunk<ITemplateModifierGroup | undefined, SaveSharedModifierGroupArgs>(
  'sharedMenuItems/modifierGroups/details/save',
  async (args: SaveSharedModifierGroupArgs, thunkAPI) => {
    try {
      const isNew = !args.templateModifierGroup._id;
      const result = await updateSharedModifierGroup(args.companyId, args.templateModifierGroup, isNew);

      thunkAPI.dispatch(set200Toast(`Success! Shared Modifier Group ${isNew ? 'created' : 'updated'}!`));
      return result;
    } catch (e) {
      thunkAPI.dispatch(toastErrorState(e.status, e.message));
    }
  }
);

const deleteSharedModifierGroupThunk = createAsyncThunk(
  'sharedMenuItems/modifierGroups/details/delete',
  async (args: { companyId: string; id: string }, thunkAPI) => {
    try {
      await deleteSharedModifierGroup(args.companyId, args.id);
      thunkAPI.dispatch(set200Toast(`Modifier group deleted.`));
    } catch (error) {
      thunkAPI.dispatch(toastErrorState(error.status, error.message));
    }
  }
);

export { getSharedModifierGroupThunk, saveSharedModifierGroupThunk, deleteSharedModifierGroupThunk };
