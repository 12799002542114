import React from 'react';
import DuplicateMenuItemModal from 'menus/components/ItemsAndMods/DuplicateMenuItemModal';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { duplicateTemplateMenuItemThunk } from 'sharedMenuItems/redux/createEditItem/createEditItemThunkActions';
import { selectCreateEditItemState } from 'sharedMenuItems/redux/selectors';

export interface IDisplayNameValidation {
  hasError: boolean;
  errorMessage: string;
}

export const defaultValidationValues = {
  errorMessage: '',
  hasError: false,
};

export const DuplicateSharedMenuItemModal = ({
  companyId,
  setVisible,
}: {
  companyId: string;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const dispatch = useAppDispatch();
  const { itemCache, processing } = useAppSelector(selectCreateEditItemState);

  const resetDuplicateModalChanges = (visible: boolean) => {
    setVisible(visible);
  };

  const duplicateItem = async (displayName: string) => {
    if (itemCache) {
      await dispatch(
        duplicateTemplateMenuItemThunk({
          companyId,
          item: itemCache,
          displayName,
        })
      );
      resetDuplicateModalChanges(false);
    }
  };
  return (
    <DuplicateMenuItemModal
      processing={processing}
      setVisible={setVisible}
      duplicateMenuItem={duplicateItem}
      cancelChanges={() => resetDuplicateModalChanges(false)}
      headerLabel={itemCache?.itemType === 'item' ? 'Item' : 'Modifier'}
      placeholder={itemCache?.itemType === 'item' ? 'e.g. Club Sandwich' : 'e.g. Extra Cheese'}
    />
  );
};
