import React from 'react';

export interface TableHeaderProps {
  children: JSX.Element;
}

const TableHeader = (props: TableHeaderProps) => {
  const { children } = props;

  return <div className='table__header'>{children}</div>;
};

export default TableHeader;
