import {
  ILocationBilling,
  ILocationBillingPerTransactionFee,
  ILocationBillingBPSTransactionFees,
  ILocationBillingMonthlySaaSFees,
  IRecurringProducts,
} from '@ready/dashboardv2api.contracts';

export interface IBillingValidation {
  hasErrors: boolean;
  transactionFee: {
    orderTransactions: {
      hasError: boolean;
      error: string;
    };
    payTransactions: {
      hasError: boolean;
      error: string;
    };
  };
  BPSFee: {
    orderTransactionsBPSFees: {
      hasError: boolean;
      error: string;
    };
    payTransactionsBPSFees: {
      hasError: boolean;
      error: string;
    };
  };
  monthlyFees: {
    restaurantMonthlyFee: {
      hasError: boolean;
      error: string;
    };
    singleModuleFee: {
      hasError: boolean;
      error: string;
    };
    multiModuleFee: {
      hasError: boolean;
      error: string;
    };
    virutalKiosk: {
      hasError: boolean;
      error: string;
    };
    virtualDriveThrough: {
      hasError: boolean;
      error: string;
    };
    orderAhead: {
      hasError: boolean;
      error: string;
    };
    payAtTable: {
      hasError: boolean;
      error: string;
    };
    orderToTable: {
      hasError: boolean;
      error: string;
    };
  };
}

export interface IBillingTab extends ILocationBilling {
  validation: IBillingValidation;
}

export const getInitialBillingEnabled = (): boolean => false;

export const getInitialOptInCompanyBilling = (): boolean => false;

export const getInitialPerTransactionFees = (): ILocationBillingPerTransactionFee => {
  return {
    orderTransactionsEnabled: false,
    orderTransactions: '',
    payTransactionsEnabled: false,
    payTransactions: '',
  };
};

export const getInitialBpsFees = (): ILocationBillingBPSTransactionFees => {
  return {
    orderTransactionBPSFeesEnabled: false,
    orderTransactionsBPSFees: '',
    payTransactionBPSFeesEnabled: false,
    payTransactionsBPSFees: '',
  };
};

export const getInitialMonthlySaasFees = (): ILocationBillingMonthlySaaSFees => {
  return {
    restaurantMonthlyFeeEnabled: false,
    restaurantMonthlyFee: '',
    singleModuleFeeEnabled: false,
    singleModuleFee: '',
    multiModuleFeeEnabled: false,
    multiModuleFee: '',
    virutalKioskEnabled: false,
    virutalKiosk: '',
    virtualDriveThroughEnabled: false,
    virtualDriveThrough: '',
    orderAheadEnabled: false,
    orderAhead: '',
    payAtTableEnabled: false,
    payAtTable: '',
    orderToTableEnabled: false,
    orderToTable: '',
  };
};

export const getInitialRecurringProducts = (): IRecurringProducts => {
  return {
    readyWaitlistEnabled: false,
    readyDigitalSignageEnabled: false,
    readyPilotProgramEnabled: false,
  };
};

export const getInitialValidation = (): IBillingValidation => {
  return {
    hasErrors: false,
    transactionFee: {
      orderTransactions: {
        hasError: false,
        error: '',
      },
      payTransactions: {
        hasError: false,
        error: '',
      },
    },
    BPSFee: {
      orderTransactionsBPSFees: {
        hasError: false,
        error: '',
      },
      payTransactionsBPSFees: {
        hasError: false,
        error: '',
      },
    },
    monthlyFees: {
      restaurantMonthlyFee: {
        hasError: false,
        error: '',
      },
      singleModuleFee: {
        hasError: false,
        error: '',
      },
      multiModuleFee: {
        hasError: false,
        error: '',
      },
      virutalKiosk: {
        hasError: false,
        error: '',
      },
      virtualDriveThrough: {
        hasError: false,
        error: '',
      },
      orderAhead: {
        hasError: false,
        error: '',
      },
      payAtTable: {
        hasError: false,
        error: '',
      },
      orderToTable: {
        hasError: false,
        error: '',
      },
    },
  };
};

export const billingTab = {
  billingEnabled: getInitialBillingEnabled(),
  optInCompanyTiering: getInitialOptInCompanyBilling(),
  transactionFee: getInitialPerTransactionFees(),
  BPSFee: getInitialBpsFees(),
  monthlyFees: getInitialMonthlySaasFees(),
  recurringProducts: getInitialRecurringProducts(),
  validation: getInitialValidation(),
} as IBillingTab;
