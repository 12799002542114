import React from 'react';
import { IMenuSectionItem, MenuItemInactiveStates, Visibility } from '@ready/menu.core';

import styles from './SectionItemCard.module.scss';

import findSectionItem from '../../utils/findSectionItem';
import DisplayNameInput from './DisplayNameInput';
import IconButton from '../../../components/IconButton/IconButton';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';
import MenuItemPosBlock from '../ItemsAndMods/MenuItemPosBlock';
import MenuItemPriceBlock from '../ItemsAndMods/MenuItemPriceBlock';
import { SectionItemChange } from '../../types/SectionItemChange.interface';

export interface SectionItemCardProps {
  item: IMenuSectionItem;
  sectionItemChanges: SectionItemChange[];
  processing: boolean;
  isSharedMenu?: boolean;
  dragHandle?: JSX.Element;
  onRemoveItem: (id: string) => void;
  onChangeItemName: (id: string, name: string) => void;
  onChangeItemVisibility: (id: string, visible: boolean) => void;
}

const SectionItemCard = ({
  item,
  sectionItemChanges,
  processing,
  isSharedMenu = false,
  dragHandle,
  onRemoveItem,
  onChangeItemName,
  onChangeItemVisibility,
}: SectionItemCardProps): JSX.Element => (
  <div className={styles.container}>
    {dragHandle}
    <div className={styles.details}>
      <div className={styles.name}>
        <DisplayNameInput
          value={findSectionItem(sectionItemChanges, item.itemId).displayName}
          validation={findSectionItem(sectionItemChanges, item.itemId).validation}
          processing={processing}
          label='Display Name'
          maxLength={100}
          onChange={(displayName: string) => onChangeItemName(item.itemId, displayName)}
        />
      </div>

      <div className={styles.information}>
        <MenuItemPosBlock
          id={item.posItemId}
          name={item.posItemName}
          categories={item.posItemCategories}
          draft={item.inactiveStates.includes(MenuItemInactiveStates.draft)}
          deleted={item.inactiveStates.includes(MenuItemInactiveStates.itemDeletedOnPOS)}
          className={styles.pos}
        />

        <MenuItemPriceBlock
          price={item.price}
          deleted={item.inactiveStates.includes(MenuItemInactiveStates.priceDeletedOnPOS)}
          className={styles.price}
        />
      </div>
    </div>

    {!isSharedMenu && (
      <IconButton
        onClick={() => onChangeItemVisibility(item.itemId, item.visibility !== Visibility.visible)}
        loading={processing}
        additionalClassName={item.visibility === Visibility.visible ? styles.hide : styles.show}
      >
        {item.visibility === Visibility.visible ? <TextIcon icon={Icon.EyeOpen} /> : <TextIcon icon={Icon.EyeClosed} />}
      </IconButton>
    )}

    <IconButton onClick={() => onRemoveItem(item.itemId)} loading={processing} additionalClassName={styles.remove}>
      <TextIcon icon={Icon.RemoveCircle} />
    </IconButton>
  </div>
);

export default SectionItemCard;
