import React from 'react';
import readyProgressSpinner from '../../styles/assets/ready-progress-spinner-gray.svg';

export interface LoadingSpinnerProps {
  additionalStyles?: string;
}

const LoadingSpinner = (props: LoadingSpinnerProps) => {
  const { additionalStyles = '' } = props;

  return (
    <div className={`loading-spinner ${additionalStyles}`}>
      <img src={readyProgressSpinner} alt='loading' />
    </div>
  );
};

export default LoadingSpinner;
