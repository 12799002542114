import produce from 'immer';
import { Action } from '../../../redux/types';
import {
  orderSmsConfigsInitialState,
  IOrderSmsConfigsState,
} from '../../initialStates/orders/orderSmsConfigsInitialState';
import { ORDERS_ACTIONS } from '../../actions/orders/types';

export const orderSmsConfigsReducer = (state: IOrderSmsConfigsState = orderSmsConfigsInitialState, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ORDERS_ACTIONS.INIT_ORDER_SMS_CONFIGS_DATA:
        draft = { ...orderSmsConfigsInitialState };
        return draft;
      case ORDERS_ACTIONS.SET_ORDER_SMS_CONFIGS_DATA:
        draft.orderSmsConfigData = action.payload;
        return draft;
      case ORDERS_ACTIONS.SET_LOADING_ORDER_DETAIL:
        draft.loading = action.payload;
        return draft;
      default:
        return draft;
    }
  });
};

export default orderSmsConfigsReducer;
