import React from 'react';
import { CellProps, getCellClasses } from '../../../../../components/TableV2/Cell/Cell';
import { IChecksReportListItem } from '@ready/dashboardv2api.contracts';
import styles from './Cells.module.scss';
import TextIcon, { Icon } from '../../../../../components/Icon/TextIcon';

export const CheckStatusCell = ({ rowData, classes }: CellProps<IChecksReportListItem>) => {
  return (
    <span className={getCellClasses(classes)}>
      {rowData.status}
      {rowData.pospaymentLastStatus === 'failed' ? (
        <>
          &nbsp;
          <TextIcon icon={Icon.Alert} additionalStyles={styles.paymentStatusFailed} />
        </>
      ) : null}
    </span>
  );
};

export const CheckReadyPaymentCell = ({ rowData, classes }: CellProps<IChecksReportListItem>) => {
  const isUnpaid = rowData.readyPayment === 'Unpaid';
  return (
    <span className={getCellClasses({ [styles.readyPaymentUnpaid]: isUnpaid }, classes)}>{rowData.readyPayment}</span>
  );
};
