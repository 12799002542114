import React from 'react';

/**
 * Executes a callback when an interaction happens outside of the passed in ref
 * by listening to global focus and click events, and checking if those events
 * happened outside of the passed in ref's DOM node structure.
 *
 * This will not execute the callback if a child node of the passed in ref was
 * clicked or focused, so this hook is useful for closing drop-down menus and
 * modals, for example.
 *
 * @param ref React HTML element ref to watch for parent node interactions
 * @param onOutsideInteraction Callback to execute when any DOM node parent of
 * the ref is interacted with
 */
const useOutsideInteraction = (
  ref: React.RefObject<HTMLElement>,
  onOutsideInteraction: (event: FocusEvent | MouseEvent) => void
) => {
  const handleInteraction = React.useCallback(
    (event: FocusEvent | MouseEvent) => {
      const eventTarget = event.target instanceof Node ? (event.target as Node) : null;

      if (ref.current && !ref.current.contains(eventTarget)) {
        onOutsideInteraction && onOutsideInteraction(event);
      }
    },
    [ref, onOutsideInteraction]
  );

  React.useEffect(() => {
    document.addEventListener('focusin', handleInteraction);
    document.addEventListener('mousedown', handleInteraction);

    return () => {
      document.removeEventListener('focusin', handleInteraction);
      document.removeEventListener('mousedown', handleInteraction);
    };
  }, [handleInteraction]);
};

export default useOutsideInteraction;
