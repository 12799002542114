import React from 'react';
import clone from 'lodash/clone';
import { startOfDay, endOfDay, isEqual, format, parse, isBefore, isAfter } from 'date-fns';
import { isEqualOrBefore, isEqualOrAfter, setDatePart } from '../../utils/dateUtils';
import { useSetVh } from '../../hooks';
import styles from './DatePicker.module.scss';
import MonthScroller, { MonthScrollerOnChangeProps } from './MonthScroller';
import CalendarDays, { CalendarOnChangeCallback, CalendarValue } from './CalendarDays';
import TimePicker from '../TimePicker/TimePicker';

interface DatePickerProps {
  value: DatePickerValue;
  onChange: OnChangeFunction;
  minValue?: Date;
  maxValue?: Date;
  minDisplayMonth?: Date;
  maxDisplayMonth?: Date;
  canChangeMonth?: boolean;
  withTime?: boolean;
  showSeconds?: boolean;
  isRange?: boolean;
  showMinutes?: boolean;
  timePlaceholder?: string;
}

export interface DatePickerValue extends CalendarValue {
  endMonth: Date;
  monthArrowButtonIsClicked?: boolean;
}

interface OnChangeFunction {
  (value: DatePickerValue): void;
}

interface calendarArgs {
  isFirstInRange: boolean;
}

const timeFormat = 'HH:mm:ss';

const DatePicker = (props: DatePickerProps) => {
  const { showMinutes = true, showSeconds } = props;
  // Backup previous time selection
  const [startTimeBackup, setStartTimeBackup] = React.useState<number>(0);
  const [endTimeBackup, setEndTimeBackup] = React.useState<number>(0);

  // Default true
  const canChangeMonth = !!props.canChangeMonth || props.canChangeMonth === undefined;

  // Default false
  const withTime = !!props.withTime;

  // Default false
  const isRange = !!props.isRange;

  // Calculate actual viewable height and inject it into CSS for correct display on mobile Safari
  useSetVh();

  /* ****** Internal functions ****** */

  const updateValue = (newValue: DatePickerValue) => {
    props.onChange && props.onChange(newValue);
  };

  /* ****** Callbacks ****** */

  const handleMonthChange = ({ value }: MonthScrollerOnChangeProps, isFirstInRange: boolean) => {
    isFirstInRange
      ? updateValue({
          ...props.value,
          startMonth: value,
          monthArrowButtonIsClicked: true,
        })
      : updateValue({
          ...props.value,
          endMonth: value,
          monthArrowButtonIsClicked: true,
        });
  };

  const handleDateChange: CalendarOnChangeCallback = (value) => {
    let newValue = clone(props.value);

    // Lets the user select the end range in the first calendar, if the start
    // range has already been selected
    if (
      isRange &&
      props.value.startDate &&
      value.startDate &&
      !props.value.endDate &&
      !value.endDate &&
      isEqualOrAfter(value.startDate, props.value.startDate)
    ) {
      const newEndDate = endOfDay(value.startDate);
      if (endTimeBackup) {
        const backupDate = new Date(endTimeBackup);
        newEndDate.setHours(backupDate.getHours(), backupDate.getMinutes(), backupDate.getSeconds());
      }
      newValue.endDate = newEndDate;
    }

    // Lets the user select the start range in the last calendar, if the end
    // range has already been selected
    else if (
      isRange &&
      props.value.endDate &&
      value.endDate &&
      !props.value.startDate &&
      !value.startDate &&
      isEqualOrBefore(value.endDate, props.value.endDate)
    ) {
      const newStartDate = startOfDay(value.endDate);
      if (startTimeBackup) {
        const backupDate = new Date(startTimeBackup);
        newStartDate.setHours(backupDate.getHours(), backupDate.getMinutes(), backupDate.getSeconds());
      }
      newValue.startDate = newStartDate;
    }

    // The start date has already been selected, and another date is being selected
    // in the calendar. If it is before the already selected date, swap the
    // selections and set it as the range in the first calendar.
    else if (
      isRange &&
      value.startDate &&
      !value.endDate &&
      props.value.startDate &&
      !props.value.endDate &&
      isBefore(value.startDate, props.value.startDate)
    ) {
      const { startDate } = props.value;
      const newStartDateValue = startOfDay(value.startDate);
      newStartDateValue.setHours(startDate.getHours(), startDate.getMinutes(), startDate.getSeconds());

      const newEndDate = endOfDay(props.value.startDate);
      if (endTimeBackup) {
        const backupDate = new Date(endTimeBackup);
        newEndDate.setHours(backupDate.getHours(), backupDate.getMinutes(), backupDate.getSeconds());
      }

      newValue.startDate = newStartDateValue;
      newValue.endDate = newEndDate;
    }

    // The start date has already been selected, and another date is being selected
    // in the calendar. If it is before the already selected date, swap the
    // selections and set it as the range in the last calendar.
    else if (
      isRange &&
      value.endDate &&
      !value.startDate &&
      props.value.endDate &&
      !props.value.startDate &&
      isAfter(value.endDate, props.value.endDate)
    ) {
      const { endDate } = props.value;
      const newEndDateValue = endOfDay(value.endDate);
      newEndDateValue.setHours(endDate.getHours(), endDate.getMinutes(), endDate.getSeconds());

      const newStartDate = startOfDay(props.value.endDate);
      if (startTimeBackup) {
        const backupDate = new Date(startTimeBackup);
        newStartDate.setHours(backupDate.getHours(), backupDate.getMinutes(), backupDate.getSeconds());
      }

      newValue.endDate = newEndDateValue;
      newValue.startDate = newStartDate;
    }

    // Date range has already been selected and user is clicking a date in the
    // first calendar, so clear the selection
    else if (
      isRange &&
      value.startDate &&
      props.value.startDate &&
      props.value.endDate &&
      !isEqual(props.value.startDate, value.startDate)
    ) {
      const { startDate, endDate } = props.value;
      const newStartDateValue = startOfDay(value.startDate);
      newStartDateValue.setHours(startDate.getHours(), startDate.getMinutes(), startDate.getSeconds());
      setEndTimeBackup(endTimeBackup || endDate.getTime());

      newValue.startDate = newStartDateValue;
      newValue.endDate = undefined;
    }

    // Date range has already been selected and user is clicking a date in the
    // second calendar, so clear the selection
    else if (
      isRange &&
      value.endDate &&
      props.value.endDate &&
      props.value.startDate &&
      !isEqual(props.value.endDate, value.endDate)
    ) {
      const { startDate, endDate } = props.value;
      const newEndDateValue = endOfDay(value.endDate);
      newEndDateValue.setHours(endDate.getHours(), endDate.getMinutes(), endDate.getSeconds());
      setStartTimeBackup(startTimeBackup || startDate.getTime());

      newValue.endDate = newEndDateValue;
      newValue.startDate = undefined;
    }

    // Otherwise normal range selection
    else {
      if (newValue.startDate && value.startDate) {
        newValue.startDate = setDatePart(newValue.startDate, value.startDate);
      } else if (value.startDate) {
        const newStartDate = startOfDay(value.startDate);
        if (startTimeBackup) {
          const backupDate = new Date(startTimeBackup);
          newStartDate.setHours(backupDate.getHours(), backupDate.getMinutes(), backupDate.getSeconds());
        }

        newValue.startDate = newStartDate;
      }

      if (newValue.endDate && value.endDate) {
        newValue.endDate = setDatePart(newValue.endDate, value.endDate);
      } else if (value.endDate) {
        const newEndDate = endOfDay(value.endDate);
        if (endTimeBackup) {
          const backupDate = new Date(endTimeBackup);
          newEndDate.setHours(backupDate.getHours(), backupDate.getMinutes(), backupDate.getSeconds());
        }
        newValue.endDate = newEndDate;
      }
    }

    if (!(newValue.startDate && newValue.endDate && newValue.startDate > newValue.endDate)) {
      updateValue({ ...newValue, monthArrowButtonIsClicked: false });
    }
  };

  const handleTimeChange = (value: string, isFirstInRange: boolean) => {
    let newValue = clone(props.value);

    if (isFirstInRange && newValue.startDate && props.value.startDate) {
      newValue.startDate = parse(value, timeFormat, props.value.startDate);
    }

    if ((!isFirstInRange || !isRange) && newValue.endDate && props.value.endDate) {
      newValue.endDate = parse(value, timeFormat, props.value.endDate);
    }

    updateValue(newValue);
  };

  /* ****** Rendering ****** */

  const startRangeMaxValue = () => {
    if (props.maxValue) {
      if (props.value.endDate) {
        if (isEqualOrBefore(props.maxValue, endOfDay(props.value.endDate))) {
          return props.maxValue;
        }
      } else {
        return props.maxValue;
      }
    }
  };

  const endRangeMinValue = () => {
    if (props.minValue) {
      if (props.value.startDate) {
        if (isEqualOrBefore(props.minValue, startOfDay(props.value.startDate))) {
          return props.minValue;
        }
      } else {
        return props.minValue;
      }
    }
  };

  const persistedDate = new Date();
  const persistedStartTime =
    props.value.startDate || (startTimeBackup ? persistedDate.setTime(startTimeBackup) : startOfDay(persistedDate));
  const persistedEndTime =
    props.value.endDate || (endTimeBackup ? persistedDate.setTime(endTimeBackup) : endOfDay(persistedDate));

  const calendar = ({ isFirstInRange }: calendarArgs) => (
    <div>
      <MonthScroller
        value={isFirstInRange ? props.value.startMonth : props.value.endMonth}
        onChange={({ value }: MonthScrollerOnChangeProps) => handleMonthChange({ value }, isFirstInRange)}
        minValue={props.minDisplayMonth}
        maxValue={props.maxDisplayMonth}
        showArrows={canChangeMonth}
      />

      <CalendarDays
        value={{
          startDate: props.value.startDate,
          endDate: props.value.endDate,
          startMonth: isFirstInRange ? props.value.startMonth : props.value.endMonth,
        }}
        onChange={handleDateChange}
        isRange={isRange}
        updateValue={isFirstInRange ? 'startDate' : 'endDate'}
        minValue={isFirstInRange ? props.minValue : endRangeMinValue()}
        maxValue={isFirstInRange ? startRangeMaxValue() : props.maxValue}
      />
    </div>
  );

  return (
    <div className={styles.container}>
      {calendar({ isFirstInRange: true })}
      {withTime && (
        <div className={styles.timePicker}>
          <TimePicker
            value={format(persistedStartTime, timeFormat)}
            disabled={!props.value.startDate}
            fullWidth={!isRange}
            showSeconds={showSeconds}
            showMinutes={showMinutes}
            onChange={(value: string) => handleTimeChange(value, true)}
            placeholder={props.timePlaceholder}
          />
          {isRange && (
            <TimePicker
              value={format(persistedEndTime, timeFormat)}
              disabled={!props.value.endDate}
              showMinutes={showMinutes}
              showSeconds={showSeconds}
              onChange={(value: string) => handleTimeChange(value, false)}
              placeholder={props.timePlaceholder}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DatePicker;
