import React from 'react';
import styles from './SelectMenuConfigCell.module.scss';
import TextIcon, { Icon } from 'components/Icon/TextIcon';

export const NoMenusAssignedPlaceholder = () => (
  <>
    <TextIcon icon={Icon.Alert} additionalStyles={styles.noMenusPlaceholderIcon} />
    <span className={styles.noMenusPlaceholder}>No Menu Selected</span>
  </>
);
