import React from 'react';
import Modal from '../../../components/Modal/Modal';
import ModalHeader from '../../../components/Modal/ModalHeader';
import ModalBody from '../../../components/Modal/ModalBody';
import ModalFooter from '../../../components/Modal/ModalFooter';

export interface PayPalUnlinkProps {
  processing: boolean;
  setVisible: (visible: boolean) => void;
  unlinkPayPalAccount: () => void;
}

const PayPalUnlink = (props: PayPalUnlinkProps) => {
  const {
    processing,
    setVisible,
    unlinkPayPalAccount,
  } = props;

  return (
    <Modal setShowModal={setVisible} loading={processing}>
      <ModalHeader headerLabel="Unlink PayPal account?" setShowModal={setVisible} />
      <ModalBody>
        <p>Are you sure you want to unlink your PayPal account? You will no longer be able to accept payments via PayPal at this location.</p>
      </ModalBody>
      <ModalFooter
        primaryLabel="Unlink Account"
        primaryActionHandler={unlinkPayPalAccount}
        secondaryLabel='Cancel'
        secondaryActionHandler={() => setVisible(false)}
        loading={processing}
      />
    </Modal>
  )
};

export default PayPalUnlink;
