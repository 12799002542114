import { ICompanyPaymentConfig, ICompanyPaymentConfigCreate, ICompanyPaymentConfigUpdate } from '@ready/dashboardv2api.contracts';
import config from 'config';
import executeApiAsync from 'services/utils/executeApiAsync';

const baseUrl = `${config.readyDashboardApiEndpoint}/companies`;
export const getAllCompanyConfigsList = async (companyId: string): Promise<ICompanyPaymentConfig[]> => {
  return await executeApiAsync<ICompanyPaymentConfig[]>(`${baseUrl}/${companyId}/paymentConfigs`);
};

export const getOneCompanyConfig = async (companyId: string, configId: string): Promise<ICompanyPaymentConfig> => {
  return await executeApiAsync<ICompanyPaymentConfig>(`${baseUrl}/${companyId}/paymentConfigs/${configId}`);
};

export const createCompanyConfig = async (
  companyId: string,
  config: ICompanyPaymentConfigCreate
): Promise<ICompanyPaymentConfig> => {
  return await executeApiAsync<ICompanyPaymentConfig>(`${baseUrl}/${companyId}/paymentConfigs`, {
    method: 'POST',
    body: JSON.stringify(config),
  });
};

export const updateCompanyConfig = async (
  companyId: string,
  config: ICompanyPaymentConfigUpdate
): Promise<ICompanyPaymentConfig> => {
  return await executeApiAsync<ICompanyPaymentConfig>(`${baseUrl}/${companyId}/paymentConfigs`, {
    method: 'PUT',
    body: JSON.stringify(config),
  });
};

export const deleteCompanyConfig = async (companyId: string, configId: string): Promise<void> => {
  await executeApiAsync<void>(`${baseUrl}/${companyId}/paymentConfigs/${configId}`, { method: 'DELETE' });
};
