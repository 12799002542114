import { IPagination } from '@ready/dashboardv2api.contracts';
import { IBulkEditMenuItem } from '@ready/menu.core';
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { getTemplateChildMenuItems } from './thunks';

interface State {
  items: IBulkEditMenuItem[];
  pagination?: IPagination;
  loading: boolean;
  totalLocationsCount?: number;
}

const initialState: State = {
  loading: true,
  items: [],
};

const itemAndModsAssignedLocationsSlice = createSlice({
  name: 'sharedMenuItems/itemsAndMods/assignedLocations',
  initialState: initialState,
  reducers: {
    storeTotalLocationsCount: (state, action: { payload: number }) => {
      state.totalLocationsCount = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<State>) => {
    builder.addCase(getTemplateChildMenuItems.pending, (state: State) => {
      state.loading = true;
    });
    builder.addCase(getTemplateChildMenuItems.fulfilled, (state: State, action) => {
      state.loading = false;
      const { items, ...pagination } = action.payload;
      state.items = items;
      state.pagination = pagination;
    });
    builder.addCase(getTemplateChildMenuItems.rejected, (state: State) => {
      state.loading = false;
    });
  },
});

export { itemAndModsAssignedLocationsSlice };
