import { find } from 'lodash/fp';

import { Icon } from '../../components/Icon/TextIcon';
import { Option, DefaultOptionValue } from '../../components/SelectFilter/SelectFilter';
import { IDropDownList } from '../../utils/selectListUtils/listOptions.interface';

export const findOptionByValue = (options: Option[], value?: DefaultOptionValue): Option | undefined =>
  find({ value }, options);

export const getLabelForValue = (optionsList: Option[], val: string | undefined, listKey: string): string => {
  if (!val) {
    return `Select ${listKey}`;
  }

  const option = findOptionByValue(optionsList, val);

  return option ? option.label : val;
};

export const createDropDownListPayload = (
  options: Option[],
  value: string | undefined,
  listKey: string
): IDropDownList => {
  return {
    options: [...options],
    selectedOption: {
      value: value,
      label: getLabelForValue(options, value, listKey),
    },
  };
};

export const getSelectedOption = (options: Option[], value: string, listKey: string): Option => {
  return {
    value: value,
    label: getLabelForValue(options, value, listKey),
    icon: getIconForValue(options, value),
  };
};

export const getIconForValue = (options: Option[], value: string | number): Icon | undefined => {
  return findOptionByValue(options, value)?.icon;
};

export const mapArrayToSelectOptions = (arr: string[]): Option[] => {
  let result = [] as Option[];
  for (let i = 0, length = arr.length; i < length; i++) {
    result.push({
      label: arr[i],
      value: arr[i].toLowerCase(),
    });
  }
  return result;
};
