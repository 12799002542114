import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import debounce from 'lodash/debounce';

import {
  prepareModifierGroupsModal,
  loadModifierGroupsForInfiniteScroll,
  loadMoreModifierGroupsForInfiniteScroll,
} from '../../redux/ModifierGroupsActions';
import { IModifierGroup } from '@ready/menu.core';

import ListModal from '../../../components/Modal/ListModal';

import AddModifierGroupSearch from './AddModifierGroupSearch';
import AddModifierGroupList from './AddModifierGroupList';

export type AddModifierGroupDialogProps = ConnectedProps<typeof connector> & {
  visible: boolean;
  companyId: string;
  locationId: string;
  parentModifierGroupId?: string;
  addModifierGroup: (modifierGroup: IModifierGroup) => void;
  removeModifierGroup: (modifierGroupId: string) => void;
};

const AddModifierGroupDialog = (props: AddModifierGroupDialogProps): JSX.Element | null => {
  const {
    visible,
    companyId,
    locationId,
    parentModifierGroupId,
    prepareModifierGroupsModal,
    loadModifierGroups,
    loadMoreModifierGroups,
    addModifierGroup,
    removeModifierGroup,
  } = props;

  if (!visible) {
    return null;
  }

  const debounceTime = 300;
  const loadModifierGroupsDebounced = debounce(loadModifierGroups, debounceTime);

  const handleLoadModifierGroups = (query?: string): void => {
    loadModifierGroupsDebounced(companyId, locationId, query);
  };

  const handleLoadMoreModifierGroups = (query: string | undefined, page: number): void => {
    loadMoreModifierGroups(companyId, locationId, query, page);
  };

  return (
    <ListModal
      setShowModal={prepareModifierGroupsModal}
      headerLabel={!!parentModifierGroupId ? 'Add Nested Modifier Group' : 'Add Modifier Groups'}
      searchControl={<AddModifierGroupSearch />}
      showDismissButton
    >
      <AddModifierGroupList
        parentModifierGroupId={parentModifierGroupId}
        loadModifierGroups={handleLoadModifierGroups}
        loadMoreModifierGroups={handleLoadMoreModifierGroups}
        addModifierGroup={addModifierGroup}
        removeModifierGroup={removeModifierGroup}
      />
    </ListModal>
  );
};

const actionCreators = {
  prepareModifierGroupsModal,
  loadModifierGroups: loadModifierGroupsForInfiniteScroll,
  loadMoreModifierGroups: loadMoreModifierGroupsForInfiniteScroll,
};

const connector = connect(null, actionCreators);

export default connector(AddModifierGroupDialog);
