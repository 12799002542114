import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

interface OptionalLinkProps {
  to?: string;
  className?: string;
  alternateClassName?: string;
}

const OptionalLink: React.FC<OptionalLinkProps> = ({
  to,
  className,
  alternateClassName,
  children,
}: PropsWithChildren<OptionalLinkProps>) => {
  if (!!to) {
    return (
      <Link to={to} className={className}>
        {children}
      </Link>
    );
  }
  return <div className={alternateClassName || className}>{children}</div>;
};

export default OptionalLink;
