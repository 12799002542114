import React, { forwardRef } from 'react';
import Switch from 'react-switch';
import Container from '../Container/Container';
import StandardLoadingSpinner from '../LoadingSpinner/StandardLoadingSpinner';

type ToggleOnChangeEvent = React.SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent;

export interface ToggleProps {
  checked: boolean;
  onChange: (checked: boolean, event: ToggleOnChangeEvent, id: string) => void;
  disabled?: boolean;
  loading?: boolean;
  processing?: boolean;
  additionalStyles?: string;
}

const ToggleText = (props: { text: string }) => (
  <span className={`toggle__text toggle__text--${props.text}`}>{props.text}</span>
);

const Toggle = forwardRef((props: ToggleProps, ref) => {
  const {
    checked = false,
    onChange,
    disabled = false,
    loading = false,
    processing = false,
    additionalStyles = '',
  } = props;

  const loadingClassName = loading ? 'toggle--state-loading' : '';
  const disabledClassName = disabled ? 'toggle--disabled' : '';
  const additionalClassName = `toggle-container ${additionalStyles}`;

  return (
    <Container additionalClassName={additionalClassName}>
      <StandardLoadingSpinner loading={processing} color='magenta' size='small' additionalStyles='toggle__spinner' />
      <Switch
        checked={checked}
        onChange={onChange}
        checkedIcon={<ToggleText text='on' />}
        uncheckedIcon={<ToggleText text='off' />}
        onColor='#a70e5b'
        offColor='#404040'
        height={24}
        width={58}
        disabled={disabled || loading || processing}
        className={`toggle ${loadingClassName} ${disabledClassName}`}
        activeBoxShadow='0 0 0 1.5px #000000'
      />
    </Container>
  );
});

export default Toggle;
