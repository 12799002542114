import React from 'react';
import styles from './UnassignedTableRow.module.scss';
import Checkbox from '../../components/Checkbox/Checkbox';
import classNames from 'classnames';
import { useClassNames } from '../../utils/cssUtils';

interface UnassignedTableRowProps {
  tableId: string;
  tableName: string | number;
  revenueCenterName: string;
  isSecondaryInfo?: boolean;
  onChange?: (tableId: string, checked: boolean) => void;
  isSelected?: boolean;
  additionalStyles?: string;
  disabled: boolean;
  hasAllPermission?: boolean;
}

const UnassignedTableRow = ({
  tableId,
  tableName,
  revenueCenterName,
  onChange = () => {},
  isSelected = false,
  isSecondaryInfo = false,
  additionalStyles,
  disabled,
  hasAllPermission,
}: UnassignedTableRowProps) => {
  const tableNumberClassName = useClassNames([{ secondary: isSecondaryInfo }, styles.tableName], styles);
  const revenueCenterClassName = useClassNames([{ secondary: isSecondaryInfo }, styles.revenueCenter], styles);

  return (
    <div className={classNames(styles.container, additionalStyles)}>
      {hasAllPermission &&
        <Checkbox
          label=''
          checked={isSelected}
          onChange={(event) => onChange(tableId, event.target.checked)}
          disabled={disabled}
        />
      }
      <span className={tableNumberClassName}>{tableName}</span>
      <span className={revenueCenterClassName}>{revenueCenterName}</span>
    </div>
  );
};

export default UnassignedTableRow;
