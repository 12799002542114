import produce from 'immer';
import { Action } from '../../../redux/types';
import { cachedUsersForm } from '../../initialStates/users/cachedUsersForm';
import { USERS_FORM_ACTIONS } from '../../actions/usersActions/types';

export const cachedUserFormReducer = (state = cachedUsersForm, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case USERS_FORM_ACTIONS.SET_USERS_FORM_CACHE:
        draft = { ...action.payload };
        return draft;
      default:
        return draft;
    }
  });
};
