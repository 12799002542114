import { WithAssignedLocationsCount } from '@ready/menu.core';
import Button from 'components/Button/Button';
import { DeleteConfirmationModal } from 'components/Modal';
import OverflowButton from 'components/OverflowButton/OverflowButton';
import React, { useState } from 'react';
import { useAppDispatch } from 'redux/store';
import { updateSelectedConfigId } from '../../redux/slice';
import { IMenuGroupUI, IMenuSummaryUI } from '../../redux/types';
import { EditMenuConfigModal } from '../MenuConfigFormModal/MenuConfigFormModal';
import { MenuConfigDeleteConfirmationModalContent } from './MenuConfigDeleteConfirmationModalContent';
import styles from './MenuConfigPanel.module.scss';
import ReorderDialog from 'menus/components/Menus/ReorderDialog';
import {
  ISaveNameAndDescriptionModalFields,
  NameAndDescriptionModal,
} from 'components/nameAndDescriptionModal/NameAndDescriptionModal';

interface Props {
  menuConfig: WithAssignedLocationsCount<IMenuGroupUI>;
  onCreateMenu: (menu: ISaveNameAndDescriptionModalFields) => Promise<void>;
  onDeleteMenuConfig: (id: string) => Promise<void>;
  onEditNameSave: (menu: IMenuGroupUI) => Promise<void>;
  onReorderMenusSave: (items: IMenuSummaryUI[]) => Promise<void>;
  onCreateMenuConfigItemGallery: (itemGallery: ISaveNameAndDescriptionModalFields) => Promise<void>;
}
export const MenuConfigPanelHeaderControls = ({
  menuConfig,
  onCreateMenu,
  onDeleteMenuConfig: onDelete,
  onEditNameSave,
  onReorderMenusSave,
  onCreateMenuConfigItemGallery,
}: Props) => {
  const dispatch = useAppDispatch();
  const [showNewMenuModal, setShowNewMenuModal] = useState<boolean>(false);
  const [showNewItemGallery, setShowNewItemGallery] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showEditNameModal, setShowEditNameModal] = useState<boolean>(false);

  const [itemsToSort, setItemsToSort] = useState<IMenuSummaryUI[]>([]);
  const [showReorderModal, setShowReorderModal] = useState<boolean>(false);
  const [reorderSaving, setReorderSaving] = useState<boolean>(false);
  const [isDeletingMenuConfig, setIsDeletingMenuConfig] = useState<boolean>(false);

  const onEditSortOrderClick = () => {
    setItemsToSort(menuConfig.menusAndGalleries);
    setShowReorderModal(true);
  };

  return (
    <>
      <div className={styles.buttons}>
        <Button
          label='+ New Item Gallery'
          variant='secondary'
          onClick={() => {
            setShowNewItemGallery(true);
            dispatch(updateSelectedConfigId(menuConfig._id));
          }}
        />
        <Button
          label='+ New Menu'
          variant='secondary'
          onClick={() => {
            setShowNewMenuModal(true);
            dispatch(updateSelectedConfigId(menuConfig._id));
          }}
        />
        <OverflowButton
          options={[
            {
              label: 'Edit Config Name',
              onClick: () => setShowEditNameModal(true),
            },
            {
              label: 'Edit Sort Order',
              onClick: onEditSortOrderClick,
              hidden: menuConfig.menusAndGalleries.length <= 1,
            },
            {
              label: 'Delete Configuration',
              primary: true,
              onClick: () => setShowDeleteModal(true),
            },
          ]}
        />
      </div>
      <div className={styles.overflow}>
        <OverflowButton
          options={[
            {
              label: '+ New Menu',
              onClick: () => {
                setShowNewMenuModal(true);
                dispatch(updateSelectedConfigId(menuConfig._id));
              },
            },
            {
              label: '+ New Item Gallery',
              onClick: () => {
                setShowNewItemGallery(true);
                dispatch(updateSelectedConfigId(menuConfig._id));
              },
            },
            {
              label: 'Edit Config Name',
              onClick: () => setShowEditNameModal(true),
            },
            {
              label: 'Edit Sort Order',
              onClick: onEditSortOrderClick,
              hidden: menuConfig.menusAndGalleries.length <= 1,
            },
            {
              label: 'Delete Configuration',
              primary: true,
              onClick: () => setShowDeleteModal(true),
            },
          ]}
        />
      </div>

      {showNewMenuModal && (
        <NameAndDescriptionModal
          handleCancel={() => setShowNewMenuModal(false)}
          setShowModal={setShowNewMenuModal}
          onSave={onCreateMenu}
          labelName='Menu'
        />
      )}

      {showNewItemGallery && (
        <NameAndDescriptionModal
          handleCancel={() => setShowNewItemGallery(false)}
          setShowModal={setShowNewItemGallery}
          onSave={onCreateMenuConfigItemGallery}
          labelName='Item Gallery'
        />
      )}

      {showEditNameModal && (
        <EditMenuConfigModal
          setShowModal={setShowEditNameModal}
          onSave={onEditNameSave}
          menuConfigToEdit={menuConfig}
        />
      )}
      {showDeleteModal && (
        <DeleteConfirmationModal
          item={'Menu Configuration'}
          itemName={menuConfig.name}
          handleDelete={async () => {
            setIsDeletingMenuConfig(true);
            await onDelete(menuConfig._id);
            setIsDeletingMenuConfig(false);
          }}
          loading={isDeletingMenuConfig}
          setShowModal={setShowDeleteModal}
          modalBodyComponent={<MenuConfigDeleteConfirmationModalContent configName={menuConfig.name} />}
        />
      )}
      {showReorderModal && (
        <ReorderDialog
          visible={true}
          processing={reorderSaving}
          title={'Reorder Menus'}
          items={itemsToSort.map((x) => ({ ...x, type: x.isGallery ? 'itemGallery' : '' }))}
          setVisible={setShowReorderModal}
          keyExtractor={(item) => item.id}
          labelExtractor={(item) => item.name}
          onMove={(oldIndex, newIndex) => {
            const copy = [...itemsToSort];
            const movedItem = copy.splice(oldIndex, 1)[0];
            copy.splice(newIndex, 0, movedItem);
            setItemsToSort(copy);
          }}
          onSave={(items) => {
            setReorderSaving(true);
            onReorderMenusSave(items);
          }}
        />
      )}
    </>
  );
};
