import React from 'react';
import { FormPanelCollapsible } from '../../../../components/Form';
import { LOCATION_SETTINGS_ACTIONS } from '../../../redux/LocationSettingsActions';
import { ILocationDigitalMenuConfig } from '@ready/dashboardv2api.contracts';
import { IUpdateLocationParam } from '../../LocationsEditPage';
import styles from './LocationPanelDM.module.scss';

interface Props {
  isProcessing: boolean;
  digitalMenuPanel: ILocationDigitalMenuConfig;
  updateLocationParam: IUpdateLocationParam;
}

const LocationPanelDM = (props: Props) => {
  const { isProcessing, digitalMenuPanel, updateLocationParam } = props;

  return (
    <FormPanelCollapsible
      title='Digital Menu'
      initiallyExpanded={digitalMenuPanel.digitalMenuFeatureEnabled}
      isEnabled={digitalMenuPanel.digitalMenuFeatureEnabled}
      withToggle={true}
      handlePanelToggle={(toggled: boolean) => {
        updateLocationParam(toggled, LOCATION_SETTINGS_ACTIONS.TOGGLE_DIGITAL_MENU_PANEL);
      }}
      loading={isProcessing}
      withoutIcon
      containerAdditionalStyles={styles.digitalMenuPanelContainer}
    ></FormPanelCollapsible>
  );
};

export default LocationPanelDM;
