import React from 'react';

export interface ContainerProps {
  children?: React.ReactNode;
  additionalClassName?: string;
}

const Container = (props: ContainerProps) => {
  const { children, additionalClassName = '' } = props;

  return <div className={`container ${additionalClassName}`}>{children}</div>;
};

export default Container;
