import React, { FC } from 'react';
import styles from './EmptyResult.module.scss';
import { useClassNames } from '../../utils/cssUtils/useClassNames';

export enum EmptyResultSize {
  Small = 'small',
  Regular = 'regular',
}

export interface EmptyResultProps {
  title: string;
  paragraph?: string;
  italicize?: boolean;
  size?: EmptyResultSize;
  additionalStyles?: string;
}

const EmptyResult: FC<EmptyResultProps> = (props) => {
  const {
    title,
    paragraph = '',
    italicize = false,
    size = EmptyResultSize.Regular,
    children,
    additionalStyles,
  } = props;

  const containerClassName = useClassNames(
    [
      {
        italicize,
        small: size === EmptyResultSize.Small,
        regular: size === EmptyResultSize.Regular,
      },
      styles.container,
      additionalStyles,
    ],
    styles
  );

  return (
    <div className={containerClassName}>
      <p className={styles.title}>{title}</p>
      {paragraph !== '' ? <p className={styles.paragraph}>{paragraph}</p> : null}
      {children}
    </div>
  );
};

export default EmptyResult;
