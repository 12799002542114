import produce from 'immer';
import Action from '../../../redux/types/Action';
import { revenueCenters } from '../initialStates/revenueCenters';
import { REVENUE_CENTERS_ACTIONS } from '../actions/types';

export const revenueCentersReducer = (state = revenueCenters, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case REVENUE_CENTERS_ACTIONS.GET_REVENUE_CENTERS_BEGIN:
        draft.loading = true;
        draft.error = false;
        return draft;

      case REVENUE_CENTERS_ACTIONS.GET_REVENUE_CENTERS_SUCCESS:
        draft.revenueCenterList = action.payload;
        draft.loading = false;
        draft.error = false;
        return draft;

      case REVENUE_CENTERS_ACTIONS.GET_REVENUE_CENTERS_ERROR:
        draft.loading = false;
        draft.error = true;
        return draft;

      default:
        return draft;
    }
  });
};
