import {
  DigitalMenuResourceActions,
  PaymentProcessingActions,
  PromoCodesResourceActions,
  ResourceType,
  TableResourceActions,
  OrderSettingsResourceActions,
  ServerFlowResourceActions,
} from '@ready/security.core';
import { LOCATIONS_ACTIONS } from './types';
import { pageErrorState } from '../../../redux/actions/uiActions/responseStateActions';
import { CompanyLocationService } from '../../../services/companyLocationsService';

export const getLocationsList = (contextId: string, query: string = '', page: number) => async (dispatch: any) => {
  dispatch(getLocationsListBegin());
  try {
    const permissions = [
      { resourceType: ResourceType.table, action: TableResourceActions.all },
      { resourceType: ResourceType.paymentProcessing, action: PaymentProcessingActions.all },
      { resourceType: ResourceType.promoCodes, action: PromoCodesResourceActions.view },
      { resourceType: ResourceType.digitalMenu, action: DigitalMenuResourceActions.all },
      { resourceType: ResourceType.orderSettings, action: OrderSettingsResourceActions.all },
      { resourceType: ResourceType.serverFlow, action: ServerFlowResourceActions.all },
    ];
    const { items, start, length, limit, total } = await CompanyLocationService.getPermittedLocations(
      contextId,
      permissions,
      query,
      page
    );
    dispatch(getLocationsListSuccess(items, start, length, limit, total));
  } catch (err) {
    dispatch(getLocationsListError());
    //@ts-ignore
    dispatch(pageErrorState(err.status, err.message));
  }
};

export const getLocationsListBegin = () => ({
  type: LOCATIONS_ACTIONS.GET_LOCATIONS_LIST_BEGIN,
});

export const getLocationsListSuccess = (items: any, start: number, length: number, limit: number, total: number) => ({
  type: LOCATIONS_ACTIONS.GET_LOCATIONS_LIST_SUCCESS,
  payload: {
    locations: items,
    pagination: { start, length, limit, total },
  },
});

export const getLocationsListError = () => ({
  type: LOCATIONS_ACTIONS.GET_LOCATIONS_LIST_ERROR,
});

export const updateLocationsListParameters = (query: string, page: number) => ({
  type: LOCATIONS_ACTIONS.UPDATE_LOCATIONS_LIST_PARAMETERS,
  payload: {
    query,
    page,
  },
});
