import React, { useEffect, useState } from 'react';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { Panel, PanelLayout } from 'components/PanelLayout';
import Button from 'components/Button/Button';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { useParams } from 'react-router-dom';
import { ContextParams } from 'types/ContextParams.interface';
import { SimplePanelLayout } from 'components/PanelLayout/SimplePanelLayout';
import styles from './OrderMessagingPanel.module.scss';
import { ViewPanel } from './viewPanel/ViewPanel';
import { EditPanel } from './editPanel/EditPanel';
import { getClientOrderMessagingConfigThunk } from 'companyLocations/redux/reducers/orderMessagingConfig/thunk';

export const OrderMessagingPanel = () => {
  const dispatch = useAppDispatch();
  const { contextId: companyId, locationId } = useParams<ContextParams>();
  const [isEditModeActive, setIsEditModeActive] = useState(false);
  const { loading } = useAppSelector((state) => state.location.orderMessagingConfig);

  useEffect(() => {
    dispatch(getClientOrderMessagingConfigThunk({ companyId, locationId }));
  }, [companyId, dispatch, locationId]);

  const closeForm = () => {
    setIsEditModeActive(false);
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <SimplePanelLayout customStyle={styles.simplePanelLayout}>
          <PanelLayout>
            <Panel
              title='Custom Messaging'
              headerControls={
                isEditModeActive ? undefined : (
                  <Button
                    variant='secondary'
                    label='Edit'
                    size='large'
                    loading={loading}
                    onClick={() => setIsEditModeActive(true)}
                  />
                )
              }
            >
              {isEditModeActive ? <EditPanel closeForm={closeForm} /> : <ViewPanel />}
            </Panel>
          </PanelLayout>
        </SimplePanelLayout>
      )}
    </>
  );
};
