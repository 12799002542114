import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { ContextParams } from '../../../types/ContextParams.interface';
import { MenuCopyStatus } from '@ready/menu.core';
import { RootState } from '../../../redux/store';
import { CopyMenuState } from '../../redux/CopyMenuState';
import { selectCopyMenuState } from '../../redux/CopyMenuSelectors';
import { acknowledgeDataDeletion, selectLocation, validate } from '../../redux/CopyMenuActions';
import { CopyMenuThunks } from '../../redux/CopyMenuThunks';
import styles from './CopyMenuDialog.module.scss';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '../../../components/Modal';
import LoadingSpinnerInline from '../../../components/LoadingSpinnerInline/LoadingSpinnerInline';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';
import { Tooltip } from '../../../components/Tooltip/Tooltip';
import { FormControl } from '../../../components/Form';
import SelectFilterAsync from '../../../components/SelectFilter/SelectFilterAsync';
import Notification from '../../../components/Notification/Notification';

enum Step {
  CopyAlreadyInProgress,
  MenuDataExists,
  SelectSourceLocation,
  Validation,
  CopyInProgress,
}

const CopyInProgressDialogBody: React.FC = () => (
  <ModalBody>
    <div className={styles.textBlock}>
      Menu data is now being copied over. This may take a few minutes to complete depending on the size of your menu.
      Grab a coffee, or refresh the page in a few minutes to see the progress.
    </div>
  </ModalBody>
);

const SelectSourceLocationDialogBody: React.FC = () => {
  const { locationsLoading, showCopyInProgressError, locations, selectedLocation, showValidationError } = useSelector<
    RootState,
    CopyMenuState
  >(selectCopyMenuState);
  const dispatch = useDispatch();
  const { contextId: companyId, locationId } = useParams<ContextParams>();

  const fetchInitialLocations = (): void => {
    const filter = '';
    dispatch(CopyMenuThunks.loadLocations({ companyId, filter }));
  };

  const fetchLocations = debounce((filter: string): void => {
    dispatch(CopyMenuThunks.loadLocations({ companyId, filter }));
  }, 300);

  return (
    <ModalBody>
      <>
        {showCopyInProgressError && (
          <Notification className={styles.copyInProgressWarning}>
            <p>
              <i className={Icon.Alert} />
              <span>
                A menu copy is already in progress. This may take a few minutes to complete. You won’t be able to start
                another copy process until the current process is finished.
              </span>
            </p>
          </Notification>
        )}
        <div className={styles.textBlock}>
          All <span className={styles.emphasis}>menu data</span>{' '}
          <Tooltip text='Menu data includes menus, items, item groups, modifiers and modifier groups.'>
            <TextIcon icon={Icon.InfoCircle} />
          </Tooltip>{' '}
          will be copied from the location you select below.
        </div>
        <div className={styles.textBlock}>
          POS Items will be automatically linked to the items and mods copied if the POS IDs + Names match at both
          locations. If they don't match, the items will still be copied but will be unlinked.
        </div>
        <FormControl
          label='Select Location to copy from *'
          bold
          withError={showValidationError}
          errorMessage={showValidationError ? 'Location required.' : ''}
        >
          <SelectFilterAsync
            placeholder='Select an option'
            options={locations
              .filter((location) => location._id !== locationId)
              .map((location) => ({
                value: location._id,
                label: location.name,
              }))}
            value={selectedLocation ? { value: selectedLocation._id, label: selectedLocation.name } : undefined}
            loading={locationsLoading}
            isClearable
            isInModal
            hideIndicator={!!selectedLocation}
            withError={showValidationError}
            handleFetch={fetchLocations}
            handleInitialFetch={fetchInitialLocations}
            onChange={(data) => {
              dispatch(
                selectLocation(
                  data
                    ? {
                        _id: `${data.value}`,
                        name: data.label,
                        publicName: '',
                        fullAddress: '',
                      }
                    : undefined
                )
              );
            }}
          />
        </FormControl>
      </>
    </ModalBody>
  );
};

const MenuDataExistsDialogBody: React.FC = () => (
  <ModalBody>
    <>
      <div className={styles.textBlock}>
        This location already contains some menu data{' '}
        <span className={styles.highlight}>(either menus, galleries, items, item groups, mods, mod groups)</span>. This
        copy tool will <span className={styles.emphasis}>delete</span> this existing data before beginning the copy
        process.
      </div>
      <div className={styles.textBlock}>
        This action cannot be undone. Only proceed if you're sure you want to delete the data from this location, and
        replace it with a copy from another location.
      </div>
    </>
  </ModalBody>
);

const CopyAlreadyInProgressDialogBody: React.FC = () => (
  <ModalBody>
    <div className={styles.textBlock}>
      A menu copy is already in progress. This may take a few minutes to complete. Please wait for the current copy to
      finish before copying again.
    </div>
  </ModalBody>
);

const LoadingDialogBody: React.FC = () => (
  <ModalBody>
    <LoadingSpinnerInline />
  </ModalBody>
);

interface Props {
  setShowModal: () => void;
}

const CopyMenuDialog: React.FC<Props> = ({ setShowModal }: Props) => {
  const {
    menuCopyState,
    menuCopyProcessing,
    dataDeletionAcknowledged,
    selectedLocation,
    validated,
    launchingMenuCopy,
    launchedMenuCopy,
  } = useSelector(selectCopyMenuState);
  const dispatch = useDispatch();
  const { contextId: companyId, locationId } = useParams<ContextParams>();

  const loading = menuCopyProcessing || launchingMenuCopy;

  let step: Step;
  if (menuCopyState && menuCopyState.status === MenuCopyStatus.inProgress) {
    step = Step.CopyAlreadyInProgress;
  } else if (menuCopyState && menuCopyState.notEmpty && !dataDeletionAcknowledged) {
    step = Step.MenuDataExists;
  } else if (!launchedMenuCopy) {
    step = Step.SelectSourceLocation;
  } else {
    step = Step.CopyInProgress;
  }

  const sourceLocationId = selectedLocation?._id ?? '';
  const targetLocationId = locationId;
  React.useEffect(() => {
    if (step === Step.SelectSourceLocation && validated) {
      dispatch(
        CopyMenuThunks.launchMenuCopy({
          companyId,
          sourceLocationId,
          targetLocationId,
        })
      );
    }
  }, [step, validated, companyId, sourceLocationId, targetLocationId, dispatch]);

  return (
    <Modal setShowModal={setShowModal}>
      {loading ? (
        <>
          <ModalHeader headerLabel='' showDismissIcon setShowModal={setShowModal} />
          <LoadingDialogBody />
        </>
      ) : (
        <>
          {step === Step.CopyAlreadyInProgress && (
            <>
              <ModalHeader headerLabel='Menu Copy Already in Progress' setShowModal={setShowModal} />
              <CopyAlreadyInProgressDialogBody />
              <ModalFooter primaryLabel='Dismiss' primaryActionHandler={setShowModal} />
            </>
          )}
          {step === Step.MenuDataExists && (
            <>
              <ModalHeader
                headerLabel='Caution: Delete & Replace Data'
                prefix={<TextIcon icon={Icon.Alert} additionalStyles={styles.warningIcon} />}
                setShowModal={setShowModal}
              />
              <MenuDataExistsDialogBody />
              <ModalFooter
                primaryLabel='Got it! Proceed to Copy tool.'
                primaryActionHandler={() => dispatch(acknowledgeDataDeletion())}
                secondaryLabel='Cancel'
                secondaryActionHandler={setShowModal}
              />
            </>
          )}
          {step === Step.SelectSourceLocation && (
            <>
              <ModalHeader headerLabel='Create Menu From Copy' setShowModal={setShowModal} />
              <SelectSourceLocationDialogBody />
              <ModalFooter
                primaryLabel='Create Copy'
                primaryActionHandler={() => dispatch(validate(selectedLocation))}
                secondaryLabel='Cancel'
                secondaryActionHandler={setShowModal}
              />
            </>
          )}
          {step === Step.CopyInProgress && (
            <>
              <ModalHeader headerLabel='Menu Copy in Progress' withSpinner setShowModal={setShowModal} />
              <CopyInProgressDialogBody />
              <ModalFooter primaryLabel='Dismiss' primaryActionHandler={setShowModal} />
            </>
          )}
        </>
      )}
    </Modal>
  );
};

export default CopyMenuDialog;
