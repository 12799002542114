import { IMenuItem } from '@ready/menu.core';
import { selectMenuItemSelectionState } from 'menus/redux/ItemsAndModsSelectors';
import { IMenuItemSelection } from 'menus/redux/ItemsAndModsState';
import { addItemToGroupItems, removeItemFromGroupItems } from 'menus/redux/MenuBuilderActions';
import React from 'react';
import { useParams } from 'react-router-dom';
import { setFormIsDirty } from 'redux/actions/uiActions/formStateActions';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { ContextParams } from 'types/ContextParams.interface';
import AddMenuItemsDialog from '../ItemsAndMods/AddMenuItemsDialog';
import { ItemGroupPanels } from './ItemGroupPanels';

const CreateItemGroupPage = () => {
  const dispatch = useAppDispatch();
  const { modalVisible } = useAppSelector<IMenuItemSelection>(selectMenuItemSelectionState);
  const { contextId: companyId, locationId } = useParams<ContextParams>();

  const onAddItemToGroup = (item: IMenuItem) => {
    dispatch(addItemToGroupItems(item));
  };

  const onRemoveItemFromGroup = (itemId: string) => {
    dispatch(removeItemFromGroupItems(itemId));
  };

  React.useEffect(() => {
    dispatch(setFormIsDirty(true));
  }, [dispatch]);

  return (
    <>
      <AddMenuItemsDialog
        visible={modalVisible}
        companyId={companyId}
        locationId={locationId}
        title='Add Menu Items'
        addMenuItemToSelection={onAddItemToGroup}
        removeMenuItemFromSelection={onRemoveItemFromGroup}
      />
      <ItemGroupPanels />
    </>
  );
};

export default CreateItemGroupPage;
