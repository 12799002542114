import React from 'react';
import { useHistory } from 'react-router-dom';
// components
import LayoutContent from '../../components/AppLayout/LayoutContent';
import { Form, FormControl, FormActionButtons } from '../../components/Form';
import TextInput from '../../components/TextInput/TextInput';
import InputError from '../../components/InputError/InputError';
// redux
import { connect, ConnectedProps } from 'react-redux';
import {
  updateFormEmail,
  updateFormGivenName,
  updateFormFamilyName,
  updateFormEmailError,
  updateFormFamilyNameError,
  updateFormGivenNameError,
  resetAdminForm,
  postAdmin,
} from '../redux/AdminFormActions';
import { IAdminForm } from '../redux/AdminFormState';
import { set200Toast } from '../../redux/actions/uiActions/responseStateActions';
import { isAdminEmailDomain, isValidEmail } from '../../utils/emailValidation';
import { AppState } from '../../redux/initialStates/AppState';
import { IAdminUser } from '@ready/dashboardv2api.contracts';

export type AdminsCreatePageProps = ReduxProps & {
  adminForm: IAdminForm;
};

const AdminsCreatePage = (props: AdminsCreatePageProps) => {
  const {
    adminForm,
    updateFormEmail,
    updateFormGivenName,
    updateFormFamilyName,
    updateFormEmailError,
    updateFormFamilyNameError,
    updateFormGivenNameError,
    resetAdminForm,
    postAdmin,
    set200Toast,
  } = props;
  const { adminFormData, loading } = adminForm;
  const { emailError, givenNameError, familyNameError } = adminForm.formValidation;
  const history = useHistory();

  // reset admin form on page load
  React.useEffect(() => {
    resetAdminForm();
  }, [resetAdminForm]);

  // submit form
  const onSubmit = (event: React.FocusEvent) => {
    event.preventDefault();
    if (!validateForm(adminForm.adminFormData)) return;
    async function postAdminPage() {
      postAdmin(adminForm.adminFormData);
    }
    postAdminPage();
  };

  const validateForm = (adminFormData: IAdminUser): boolean => {
    let validForm = true;
    if (adminFormData.email === '' || !isValidEmail(adminFormData.email) || !isAdminEmailDomain(adminFormData.email)) {
      updateFormEmailError(true);
      validForm = false;
    }
    if (adminFormData.firstName === '') {
      updateFormGivenNameError(true);
      validForm = false;
    }
    if (adminFormData.lastName === '') {
      updateFormFamilyNameError(true);
      validForm = false;
    }
    return validForm;
  };

  const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    history.push('/ready/admins');
  };

  // handle admin created
  React.useEffect(() => {
    if (!!adminForm.adminIdTransaction) {
      set200Toast();
      history.push(`/ready/admins/${adminForm.adminIdTransaction}`);
    }
  }, [adminForm.adminIdTransaction, history, set200Toast]);

  return (
    <LayoutContent title='New Admin' containerType='within'>
      <Form formTitle='Profile' onSubmit={onSubmit}>
        <FormControl label='First Name'>
          <TextInput
            placeholder='e.g. Gordon'
            loading={loading}
            value={adminFormData.firstName}
            onChange={(e) => updateFormGivenName(e.target.value)}
          />
          <>{givenNameError && <InputError message='First Name is required' />}</>
        </FormControl>
        <FormControl label='Last Name'>
          <TextInput
            placeholder='e.g. Ramsay'
            loading={loading}
            value={adminFormData.lastName}
            onChange={(e) => updateFormFamilyName(e.target.value)}
          />
          <>{familyNameError && <InputError message='Last Name is required' />}</>
        </FormControl>
        <FormControl label='Email Address'>
          <TextInput
            placeholder='e.g. name@domain.com'
            loading={loading}
            value={adminFormData.email}
            onChange={(e) => updateFormEmail(e.target.value)}
          />
          <>{emailError && <InputError message='A valid @iqmetrix email is required' />}</>
        </FormControl>

        <FormActionButtons handleCancel={handleCancel} loading={loading} />
      </Form>
    </LayoutContent>
  );
};

const mapStateToProps = (state: AppState) => ({
  adminForm: state.admins.adminForm,
});

const actionCreators = {
  updateFormEmail,
  updateFormGivenName,
  updateFormFamilyName,
  updateFormEmailError,
  updateFormFamilyNameError,
  updateFormGivenNameError,
  resetAdminForm,
  postAdmin,
  set200Toast,
};

const connector = connect(mapStateToProps, actionCreators);
type ReduxProps = ConnectedProps<typeof connector>;
export default connector(AdminsCreatePage);
