import config from '../config';
import { PaginationResponse } from '@ready/dashboardv2api.contracts';
import { ILocation } from './types/ILocation.type';
import { IPagedResponse } from './types/IPagedResponse.type';
import toPaginationResponse from './utils/toPaginationResponse';
import { IPermission, ResourceType, UserResourceActions } from '@ready/security.core';
import executeApiAsync from './utils/executeApiAsync';

const BASE_URL = `${config.readyDashboardApiEndpoint}/companies`;

export class CompanyLocationService {
  public static async getCompanyLocations(
    companyId: string,
    query: string = '',
    page: number = 1
  ): Promise<ILocation[]> {
    const paginatedLocations = await CompanyLocationService.getPermittedLocations(
      companyId,
      [{ resourceType: ResourceType.user, action: UserResourceActions.view }],
      query,
      page
    );
    return paginatedLocations.items;
  }

  public static async getPermittedLocations(
    companyId: string,
    permissions: IPermission[],
    query: string = '',
    page: number = 1
  ): Promise<PaginationResponse<ILocation>> {
    const permissionsList = permissions.map((p) => `${p.resourceType}.${p.action}`).join(',');
    const pageSize = 50;
    const showHiddenLocations = false;
    const url = `${BASE_URL}/${companyId}/permittedlocations?permissions=${permissionsList}&searchTerm=${query}&pageNumber=${page}&pageSize=${pageSize}&isHidden=${showHiddenLocations}`;
    const response = await executeApiAsync<IPagedResponse<ILocation>>(url);
    return toPaginationResponse(response, page, pageSize);
  }
}
