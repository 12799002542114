import { createSlice } from '@reduxjs/toolkit';
import { fetchCheckDetailsThunk } from './thunks';
import { fetchCheckThunkReducers } from './reducers';
import { defaultCheckDetails } from './initialState';
import { fetchManualEntryPaymentCompletedThunk, fetchRetryPOSPaymentThunk } from './thunks';
import { manualEnterPOSPaymentReducer, retryPOSPaymentReducer } from './reducers';

const checkDetailsSlice = createSlice({
  name: 'checkDetails',
  initialState: defaultCheckDetails,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCheckDetailsThunk.pending, fetchCheckThunkReducers.pending);
    builder.addCase(fetchCheckDetailsThunk.fulfilled, fetchCheckThunkReducers.fulfilled);
    builder.addCase(fetchCheckDetailsThunk.rejected, fetchCheckThunkReducers.rejected);

    builder.addCase(fetchRetryPOSPaymentThunk.pending, retryPOSPaymentReducer.pending);
    builder.addCase(fetchRetryPOSPaymentThunk.fulfilled, retryPOSPaymentReducer.fulfilled);
    builder.addCase(fetchRetryPOSPaymentThunk.rejected, retryPOSPaymentReducer.rejected);

    builder.addCase(fetchManualEntryPaymentCompletedThunk.pending, manualEnterPOSPaymentReducer.pending);
    builder.addCase(fetchManualEntryPaymentCompletedThunk.fulfilled, manualEnterPOSPaymentReducer.fulfilled);
    builder.addCase(fetchManualEntryPaymentCompletedThunk.rejected, manualEnterPOSPaymentReducer.rejected);
  },
});

const { actions: checkDetailsActions, reducer: detailsReducer } = checkDetailsSlice;
export { checkDetailsActions, detailsReducer };
