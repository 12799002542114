import React from 'react';
import { ToastContainer, ToastContainerProps, ToastProps } from 'react-toastify';
import IconButton from '../IconButton/IconButton';
import 'react-toastify/dist/ReactToastify.css';
import styles from './toast.module.scss';

export interface ToastElementMessage {
  type: 'error' | 'success';
  title?: string;
  message?: string;
  listItems?: string[];
}

const toastPropsObj: ToastContainerProps = {
  autoClose: false,
  className: styles.toast,
  bodyClassName: styles.toastBodyWrapper,
  closeButton: false,
  draggable: false,
  hideProgressBar: true,
  closeOnClick: false,
};

export const Toast = () => {
  return <ToastContainer {...toastPropsObj} />;
};

export const ToastBody = (props: ToastElementMessage & Partial<ToastProps>) => {
  const { closeToast, type, title = '', message, listItems } = props;
  const [showMessage, setShowMessage] = React.useState(false);
  const handleToggle = () => setShowMessage(!showMessage);
  const toggleLabel = showMessage ? 'View Less' : 'View More';

  let iconClassName = 'icon-dashboard-icons_info-circle';
  let toastBodyIconClassName = '';
  let headerTitleClassName = '';
  let togglerClassName = '';
  if (type === 'error') {
    iconClassName = 'icon-dashboard-icons_alert';
    toastBodyIconClassName = styles.toastBodyIconError;
    headerTitleClassName = styles.toastBodyContentHeaderError;
    togglerClassName = styles.toastHeaderToggleError;
  }
  if (type === 'success') {
    iconClassName = 'icon-dashboard-icons_checkmark-circle';
    toastBodyIconClassName = styles.toastBodyIconSuccess;
    headerTitleClassName = styles.toastBodyContentHeaderSuccess;
    togglerClassName = styles.toastHeaderToggleSuccess;
  }

  return (
    <div className={styles.toastBody}>
      <div className={toastBodyIconClassName}>
        <i className={iconClassName}></i>
      </div>
      <div className={styles.toastBodyContent}>
        <div className={styles.toastBodyContentHeader}>
          <div className={styles.toastBodyContentHeaderContainer}>
            <h5 className={headerTitleClassName}>{title}</h5>
            {message && (
              <button className={togglerClassName} onClick={handleToggle}>
                {toggleLabel}
              </button>
            )}
          </div>
          <IconButton onClick={closeToast as any} additionalClassName={styles.toastCloseButton}>
            <i className='icon-dashboard-icons_close'></i>
          </IconButton>
        </div>
        {showMessage && message && (
          <div className={styles.toastMessage}>
            <p>{message}</p>
            {!!listItems && !!listItems.length && (
              <ul>
                {listItems.map((itemMessage) => (
                  <li>{itemMessage}</li>
                ))}
              </ul>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
