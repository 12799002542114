import {
  IClientLocation,
  ILocationEditModel,
  ILocationEditModelResponse,
  ILocationToShow,
  PaginationResponse,
} from '@ready/dashboardv2api.contracts';
import { objectToQueryParamsString } from 'utils/urlUtils/objectToQueryParamsString';
import { getPaginationQueryStringProperties } from 'utils/urlUtils/pagination.utils';
import config from '../../config';
import executeApiAsync, { ResponseType } from '../../services/utils/executeApiAsync';
import { downloadFileFromBlob } from 'utils/downloadFileFromBlob';
export default class LocationsService {
  public static CLIENT_BASE_URL = `${config.readyDashboardApiEndpoint}/locations`;
  private static ADMIN_BASE_URL = `${config.readyDashboardApiEndpoint}/admins/locations`;

  private static appendBrandingData = (location: any) => {
    return {
      ...location,
      brandingPanel: {
        primaryColour: '#ff9900',
        secondaryColour: '#ff9900',
        headerFontOptions: [
          {
            label: 'Arial',
            value: 'Arial',
          },
          {
            label: 'Georgia',
            value: 'Georgia',
          },
        ],
        bodyFontOptions: [
          {
            label: 'Arial',
            value: 'Arial',
          },
          {
            label: 'Georgia',
            value: 'Georgia',
          },
        ],
        selectedHeaderFont: 'Arial',
        selectedBodyFont: 'Georgia',
      },
    };
  };

  public static getClientLocationById = (companyId: string, locationId: string): Promise<IClientLocation> => {
    const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}`;
    return executeApiAsync<IClientLocation>(url);
  };

  public static getAdminLocationById = async (locationId: string): Promise<ILocationEditModelResponse> => {
    const url = LocationsService.ADMIN_BASE_URL + `/${locationId}`;
    const location = await executeApiAsync<ILocationEditModelResponse>(url);
    return LocationsService.appendBrandingData(location);
  };

  public static updateAdminLocation = (
    locationId: string,
    params: ILocationEditModel
  ): Promise<ILocationEditModelResponse> => {
    const url = LocationsService.ADMIN_BASE_URL + `/${locationId}`;
    return executeApiAsync<ILocationEditModelResponse>(url, {
      method: 'PUT',
      body: JSON.stringify(params),
    });
  };

  public static getLocations = (query: string = '', showHidden: boolean = false, page: number = 1): Promise<PaginationResponse<ILocationToShow>> => {
    const queryString = objectToQueryParamsString({
      q: query,
      showHidden: showHidden.toString(),
      ...getPaginationQueryStringProperties(page),
    });
    const url = `${LocationsService.ADMIN_BASE_URL}?${queryString}`;
    return executeApiAsync<PaginationResponse<ILocationToShow>>(url);
  };

  public static toggleGiftCardsEnabled = (
    companyId: string,
    locationId: string,
    giftCardsEnabled: boolean
  ): Promise<IClientLocation> => {
    const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/giftCardsEnabled`;
    return executeApiAsync<IClientLocation>(url, {
      method: 'PUT',
      body: JSON.stringify({ giftCardsEnabled }),
    });
  };

  public static getLocationsListExport = async () => {
    const url = `${LocationsService.ADMIN_BASE_URL}/csv`;
    const now = new Date();
    const filename = `Locations-${now.getFullYear()}-${now.getMonth()}-${now.getDate()}.csv`;
    const blob = await executeApiAsync<Blob>(url, {
      responseType: ResponseType.Blob,
    });

    downloadFileFromBlob(blob, filename);
  };
}
