import React from 'react';
import { TablesTab } from '../../../enums/tables.enum';
import { Tabs, Tab } from '../../../components/Tabs';
import styles from './TablesDetailsPage.module.scss';
import {
  PromoCodesResourceActions,
  PrincipalPermissions,
  ResourceType,
  SecurityScope,
  Verifier,
  TableResourceActions,
  PaymentProcessingActions,
  DigitalMenuResourceActions,
  OrderSettingsResourceActions,
  ServerFlowResourceActions,
} from '@ready/security.core';
import { selectPermissions } from '../../../redux/selectors/sessionSelectors/sessionSelectors';
import { useSelector, useDispatch } from 'react-redux';
import { selectClientDigitalMenu } from '../../redux/selectors/LocationSelector';
import { fetchUserLocation } from '../../redux/actions/digitalMenuActions';

export interface LocationDetailsTabsProps {
  companyId: string;
  locationId: string;
  tab: TablesTab;
}

const LocationDetailsTabs = (props: LocationDetailsTabsProps): JSX.Element => {
  const { companyId, locationId, tab } = props;
  const dispatch = useDispatch();

  const BASE_ROUTE = `/companies/${companyId}/companyLocations/${locationId}`;
  const permissionsList = new PrincipalPermissions(useSelector(selectPermissions).permissionsList);
  const hasPermissions = (type: ResourceType, action: string) => {
    return Verifier.check(permissionsList, SecurityScope.location, type, action, locationId);
  };
  const clientDigitalMenuState = useSelector(selectClientDigitalMenu);
  const { error, location } = clientDigitalMenuState;

  const hasPermissionsToDigitalMenuTab =
    hasPermissions(ResourceType.digitalMenu, DigitalMenuResourceActions.all) &&
    location?.settings?.digitalMenuFeatureEnabled;

  const TABLES = {
    id: TablesTab.Tables,
    route: `${BASE_ROUTE}/tables/`,
    label: 'Tables',
  };
  const PAYMENT_PROCESSING = {
    id: TablesTab.PaymentProcessing,
    route: `${BASE_ROUTE}/paymentProcessing`,
    label: 'Payment Processing',
  };
  const PROMO_CODES = {
    id: TablesTab.PromoCodes,
    route: `${BASE_ROUTE}/promo-codes`,
    label: 'Promo Codes',
  };
  const DIGITAL_MENU = {
    id: TablesTab.DigitalMenu,
    route: `${BASE_ROUTE}/digital-menu`,
    label: 'Digital Menu',
  };
  const ORDER_SETTINGS = {
    id: TablesTab.OrderSettings,
    route: `${BASE_ROUTE}/order-settings`,
    label: 'Order Settings',
  };

  const SERVER_FLOW = {
    id: TablesTab.ServerFlow,
    route: `${BASE_ROUTE}/server-flow`,
    label: 'ServerFlow',
  };
  const tabs = [TABLES, PAYMENT_PROCESSING, PROMO_CODES, DIGITAL_MENU, ORDER_SETTINGS, SERVER_FLOW];
  const requestedTab = tabs.find((item) => item.id === tab);
  const selectedTab = requestedTab ? requestedTab : TABLES;

  const showDigitalMenuTab = React.useCallback(() => {
    if (!error && companyId && locationId) {
      dispatch(fetchUserLocation(companyId, locationId));
    }
  }, [companyId, locationId, dispatch, error]);

  React.useEffect(() => {
    showDigitalMenuTab();
  }, [showDigitalMenuTab]);

  return (
    <div className={styles.tab}>
      <Tabs selectedTab={selectedTab.id}>
        {hasPermissions(ResourceType.table, TableResourceActions.all) ? <Tab {...TABLES} /> : null}
        {hasPermissions(ResourceType.orderSettings, OrderSettingsResourceActions.all) ? (
          <Tab {...ORDER_SETTINGS} />
        ) : null}
        {hasPermissions(ResourceType.paymentProcessing, PaymentProcessingActions.all) ? (
          <Tab {...PAYMENT_PROCESSING} />
        ) : null}
        {hasPermissions(ResourceType.promoCodes, PromoCodesResourceActions.view) ? <Tab {...PROMO_CODES} /> : null}
        {hasPermissionsToDigitalMenuTab ? <Tab {...DIGITAL_MENU} /> : null}
        {hasPermissions(ResourceType.serverFlow, ServerFlowResourceActions.all) ? <Tab {...SERVER_FLOW} /> : null}
      </Tabs>
    </div>
  );
};

export default LocationDetailsTabs;
