import { createSlice } from '@reduxjs/toolkit';
import { orderSmsMessagesInitialState as initialState } from '../../initialStates/orderSMSMessagesInitialState';
import { reducers, extraReducers } from './orderSMSMessagesReducer';

export const orderSmsMessagesSlice = createSlice({
  name: 'orderSmsMessages',
  initialState,
  reducers,
  extraReducers,
});

export const {
  toggleOrderSmsEditCompanyModal,
  resetOrderSmsEditCompanyModal,
  updateOrderSmsMessageSource,
  updateCompanyOrderPlacedMessageStatus,
  updateLocationOrderPlacedMessageStatus,
  updateLocationOrderPlacedMessage,
  updateCompanyOrderPlacedMessage,
  updateCompanyOrderCancelledMessageStatus,
  updateLocationOrderCancelledMessageStatus,
  updateCompanyOrderCancelledMessage,
  updateLocationOrderCancelledMessage,
  updateCompanyOrderInProgressMessageStatus,
  updateLocationOrderInProgressMessageStatus,
  updateCompanyOrderInProgressMessage,
  updateLocationOrderInProgressMessage,
  updateCompanyOrderIsReadyMessageStatus,
  updateLocationOrderIsReadyMessageStatus,
  updateCompanyOrderIsReadyMessage,
  updateLocationOrderIsReadyMessage,
  updateCompanyOrderPickUpTimeUpdatedMessageStatus,
  updateLocationOrderPickUpTimeUpdatedMessageStatus,
  updateCompanyOrderPickUpTimeUpdatedMessage,
  updateLocationOrderPickUpTimeUpdatedMessage,
  validateOrderSmsMessages,
  resetOrderSmsEditLocationForm,
  toggleOrderSmsEditMode,
  resetOrderSmsMessagesConfig,
} = orderSmsMessagesSlice.actions;
export default orderSmsMessagesSlice.reducer;
