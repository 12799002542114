import MenuBuilderService from '../services/MenuBuilderService';
import {
  pageErrorState,
  set200Toast,
  setGenericErrorToast,
  toastErrorState,
} from '../../redux/actions/uiActions/responseStateActions';
import {
  Appearance,
  IMenu,
  IMenuItem,
  IMenuItemGroup,
  IMenuSection,
  IScheduleLink,
  ISortableMenuSummary,
  IInnerItemGallery,
  IItemGallery,
} from '@ready/menu.core';
import {
  ILink,
  IMenuWithType,
  newItemGallery,
  newMenu,
  newMenuForm,
  newMenuGalleryForm,
  newMenuSectionForm,
  SummaryType,
} from './MenusState';
import ItemsAndGroupsView from '../types/ItemsAndGroupsView.enum';
import MenuItemsView from '../types/MenuItemsView.enum';
import { setFormIsDirty } from '../../redux/actions/uiActions/formStateActions';
import ItemsAndGroupsStockStatus from '../types/ItemsAndGroupsStockStatus.enum';
import { CoordinatedExecutionResult } from '../../utils/asyncUtils/coordinateExecutions';
import { SectionItemChange } from '../types/SectionItemChange.interface';
import { getSharedMenuItemList } from 'sharedMenuItems/pages/itemsAndMods/service';
import {
  mapIMenuToITemplateMenu,
  mapITemplateItemGalleryToIItemGallery,
  mapITemplateMenuItemGroupToIMenuItemGroup,
  mapITemplateMenuItemToIMenuItem,
  mapITemplateMenuResponseToUpdateRequest,
  mapITemplateMenuToIMenu,
} from 'sharedMenuItems/pages/menu/menu.mapper';
import { getSharedItemGroupList } from 'sharedMenuItems/pages/itemGroups/itemGroupService';
import { getTemplateMenuGroupItemGallery, updateTemplateMenu } from 'sharedMenuItems/pages/menu/menu.service';
import { isSharedMenu } from 'sharedMenuItems/sharedMenuItems.utils';
import { RootState } from 'redux/store';
import { getMenuConfigsForLocationThunk } from 'menus/menuConfigs/redux/thunks/getMenuConfigs';
import { selectActiveMenuConfig, selectUserSelectedMenuConfig } from 'menus/menuConfigs/redux/selectors';

export const MENUS_ACTIONS = {
  LOAD_MENU_BUILDER_MENUS_BEGIN: 'LOAD_MENU_BUILDER_MENUS_BEGIN',
  LOAD_MENU_BUILDER_MENUS_SUCCESS: 'LOAD_MENU_BUILDER_MENUS_SUCCESS',
  LOAD_MENU_BUILDER_MENUS_ERROR: 'LOAD_MENU_BUILDER_MENUS_ERROR',

  PREPARE_MENU_BUILDER_EDIT_MENU: 'PREPARE_MENU_BUILDER_EDIT_MENU',
  UPDATE_MENU_BUILDER_MENU_STATUS: 'UPDATE_MENU_BUILDER_MENU_STATUS',
  UPDATE_MENU_BUILDER_MENU_DISPLAY_NAME: 'UPDATE_MENU_BUILDER_MENU_DISPLAY_NAME',
  UPDATE_MENU_BUILDER_MENU_DESCRIPTION: 'UPDATE_MENU_BUILDER_MENU_DESCRIPTION',

  PREPARE_MENU_BUILDER_REORDER_MENUS_MODAL: 'PREPARE_MENU_BUILDER_REORDER_MENUS_MODAL',
  MOVE_MENU_IN_MENUS_LIST: 'MOVE_MENU_IN_MENUS_LIST',

  DELETE_MENU_BUILDER_MENU_REQUESTED: 'DELETE_MENU_BUILDER_MENU_REQUESTED',
  DELETE_MENU_BUILDER_MENU_BEGIN: 'DELETE_MENU_BUILDER_MENU_BEGIN',
  DELETE_MENU_BUILDER_MENU_SUCCESS: 'DELETE_MENU_BUILDER_MENU_SUCCESS',
  DELETE_MENU_BUILDER_MENU_ERROR: 'DELETE_MENU_BUILDER_MENU_ERROR',

  SAVE_MENU_BUILDER_MENUS_BEGIN: 'SAVE_MENU_BUILDER_MENUS_BEGIN',
  SAVE_MENU_BUILDER_MENUS_SUCCESS: 'SAVE_MENU_BUILDER_MENUS_SUCCESS',
  SAVE_MENU_BUILDER_MENUS_ERROR: 'SAVE_MENU_BUILDER_MENUS_ERROR',

  LOAD_MENU_BUILDER_MENU_BEGIN: 'LOAD_MENU_BUILDER_MENU_BEGIN',
  LOAD_MENU_BUILDER_MENU_SUCCESS: 'LOAD_MENU_BUILDER_MENU_SUCCESS',
  LOAD_MENU_BUILDER_MENU_ERROR: 'LOAD_MENU_BUILDER_MENU_ERROR',

  PREPARE_MENU_BUILDER_EDIT_MENU_SECTION: 'PREPARE_MENU_BUILDER_EDIT_MENU_SECTION',
  UPDATE_MENU_BUILDER_MENU_SECTION_STATUS: 'UPDATE_MENU_BUILDER_MENU_SECTION_STATUS',
  UPDATE_MENU_BUILDER_MENU_SECTION_DISPLAY_NAME: 'UPDATE_MENU_BUILDER_MENU_SECTION_DISPLAY_NAME',
  UPDATE_MENU_BUILDER_MENU_SECTION_DESCRIPTION: 'UPDATE_MENU_BUILDER_MENU_SECTION_DESCRIPTION',
  UPDATE_MENU_BUILDER_MENU_SECTION_DEFAULT_APPEARANCE: 'UPDATE_MENU_BUILDER_MENU_SECTION_DEFAULT_APPEARANCE',

  DELETE_MENU_BUILDER_MENU_SECTION_REQUESTED: 'DELETE_MENU_BUILDER_MENU_SECTION_REQUESTED',
  DELETE_MENU_BUILDER_MENU_SECTION_BEGIN: 'DELETE_MENU_BUILDER_MENU_SECTION_BEGIN',
  DELETE_MENU_BUILDER_MENU_SECTION_SUCCESS: 'DELETE_MENU_BUILDER_MENU_SECTION_SUCCESS',
  DELETE_MENU_BUILDER_MENU_SECTION_ERROR: 'DELETE_MENU_BUILDER_MENU_SECTION_ERROR',

  UPDATE_MENU_BUILDER_MENU_SECTION_STATUS_BEGIN: 'UPDATE_MENU_BUILDER_MENU_SECTION_STATUS_BEGIN',
  UPDATE_MENU_BUILDER_MENU_SECTION_STATUS_SUCCESS: 'UPDATE_MENU_BUILDER_MENU_SECTION_STATUS_SUCCESS',
  UPDATE_MENU_BUILDER_MENU_SECTION_STATUS_ERROR: 'UPDATE_MENU_BUILDER_MENU_SECTION_STATUS_ERROR',

  SAVE_MENU_BUILDER_MENU_SECTION_VALIDATED: 'SAVE_MENU_BUILDER_MENU_SECTION_VALIDATED',
  SAVE_MENU_BUILDER_MENU_SECTION_BEGIN: 'SAVE_MENU_BUILDER_MENU_SECTION_BEGIN',
  SAVE_MENU_BUILDER_MENU_SECTION_SUCCESS: 'SAVE_MENU_BUILDER_MENU_SECTION_SUCCESS',
  SAVE_MENU_BUILDER_MENU_SECTION_ERROR: 'SAVE_MENU_BUILDER_MENU_SECTION_ERROR',

  ADD_ITEM_GROUP_TO_MENU_SECTION: 'ADD_ITEM_GROUP_TO_MENU_SECTION',
  REMOVE_ITEM_GROUP_FROM_MENU_SECTION: 'REMOVE_ITEM_GROUP_FROM_MENU_SECTION',

  PREPARE_MENU_BUILDER_REORDER_MENU_SECTIONS_MODAL: 'PREPARE_MENU_BUILDER_REORDER_MENU_SECTIONS_MODAL',
  MOVE_MENU_SECTION_IN_MENU: 'MOVE_MENU_SECTION_IN_MENU',

  ADD_MENU_ITEM_TO_MENU_SECTION: 'ADD_MENU_ITEM_TO_MENU_SECTION',
  REMOVE_MENU_ITEM_FROM_MENU_SECTION: 'REMOVE_MENU_ITEM_FROM_MENU_SECTION',
  ADD_MENU_ITEM_TO_ITEM_GROUP_IN_MENU_SECTION: 'ADD_MENU_ITEM_TO_ITEM_GROUP_IN_MENU_SECTION',
  REMOVE_MENU_ITEM_FROM_ITEM_GROUP_IN_MENU_SECTION: 'REMOVE_MENU_ITEM_FROM_ITEM_GROUP_IN_MENU_SECTION',
  MOVE_MENU_ITEM_IN_MENU_SECTION: 'MOVE_MENU_ITEM_IN_MENU_SECTION',
  MOVE_MENU_ITEM_IN_MENU_SECTION_GROUP: 'MOVE_MENU_ITEM_IN_MENU_SECTION_GROUP',

  UPDATE_MENU_BUILDER_MENU_SECTION_VIEW_STATE: 'UPDATE_MENU_BUILDER_MENU_SECTION_VIEW_STATE',

  UPDATE_MENU_BUILDER_MENU_SECTION_ITEM_DISPLAY_NAME: 'UPDATE_MENU_BUILDER_MENU_SECTION_ITEM_DISPLAY_NAME',
  UPDATE_MENU_BUILDER_MENU_SECTION_ITEM_VISIBILITY: 'UPDATE_MENU_BUILDER_MENU_SECTION_ITEM_VISIBILITY',
  UPDATE_MENU_BUILDER_MENU_SECTION_ITEM_GROUP_ITEM_VISIBILITY:
    'UPDATE_MENU_BUILDER_MENU_SECTION_ITEM_GROUP_ITEM_VISIBILITY',

  SAVE_MENU_BUILDER_MENU_VALIDATED: 'SAVE_MENU_BUILDER_MENU_VALIDATED',
  SAVE_MENU_BUILDER_MENU_VALIDATED_SCHEDULES: 'SAVE_MENU_BUILDER_MENU_VALIDATED_SCHEDULES',
  SAVE_MENU_BUILDER_MENU_BEGIN: 'SAVE_MENU_BUILDER_MENU_BEGIN',
  SAVE_MENU_BUILDER_MENU_SUCCESS: 'SAVE_MENU_BUILDER_MENU_SUCCESS',
  SAVE_MENU_BUILDER_MENU_ERROR: 'SAVE_MENU_BUILDER_MENU_ERROR',

  UPDATE_MENU_BUILDER_MENU_SECTION_SCHEDULER: 'UPDATE_MENU_BUILDER_MENU_SECTION_SCHEDULER',
  UPDATE_MENU_BUILDER_MENU_SCHEDULER: 'MENUS_ACTIONS.UPDATE_MENU_BUILDER_MENU_SCHEDULER',
  PREPARE_MENU_BUILDER_MENU_SCHEDULER: 'PREPARE_MENU_BUILDER_MENU_SCHEDULER',

  RESET_MENU_BUILDER_MENU_FORMS: 'RESET_MENU_BUILDER_MENU_FORMS',

  ADVANCED_SETTINGS_DIALOG_VISIBLE: 'ADVANCED_SETTINGS_DIALOG_VISIBLE',
  ADVANCED_SETTINGS_MENU_SECONDARY_NAVIGATION: 'ADVANCED_SETTINGS_MENU_SECONDARY_NAVIGATION',
  ADVANCED_SETTINGS_READ_ONLY_MENUS: 'ADVANCED_SETTINGS_READ_ONLY_MENUS',
  ADVANCED_SETTINGS_RESET_SETTINGS: 'ADVANCED_SETTINGS_RESET_SETTINGS',
  ADVANCED_SETTINGS_SAVE_SETTINGS: 'ADVANCED_SETTINGS_SAVE_SETTINGS',
  SAVE_ADVANCED_SETTINGS_BEGIN: 'SAVE_ADVANCED_SETTINGS_BEGIN',
  SAVE_ADVANCED_SETTINGS_SUCCESS: 'SAVE_ADVANCED_SETTINGS_SUCCESS',
  SAVE_ADVANCED_SETTINGS_ERROR: 'SAVE_ADVANCED_SETTINGS_ERROR',
  INITIALIZE_ADVANCED_SETTINGS: 'INITIALIZE_ADVANCED_SETTINGS',

  PREPARE_MENU_BUILDER_ITEMS_AND_GROUPS_MODAL: 'PREPARE_MENU_BUILDER_ITEMS_AND_GROUPS_MODAL',
  SET_MENU_BUILDER_ITEMS_AND_GROUPS_MODAL_VIEW: 'SET_MENU_BUILDER_ITEMS_AND_GROUPS_MODAL_VIEW',
  SET_MENU_BUILDER_ITEMS_AND_GROUPS_MODAL_QUERY: 'SET_MENU_BUILDER_ITEMS_AND_GROUPS_MODAL_QUERY',
  ADD_MENU_ITEM_TO_ITEMS_AND_GROUPS_SELECTION: 'ADD_MENU_ITEM_TO_ITEMS_AND_GROUPS_SELECTION',
  REMOVE_MENU_ITEM_FROM_ITEMS_AND_GROUPS_SELECTION: 'REMOVE_MENU_ITEM_FROM_ITEMS_AND_GROUPS_SELECTION',
  ADD_ITEM_GROUP_TO_ITEMS_AND_GROUPS_SELECTION: 'ADD_ITEM_GROUP_TO_ITEMS_AND_GROUPS_SELECTION',
  REMOVE_ITEM_GROUP_FROM_ITEMS_AND_GROUPS_SELECTION: 'REMOVE_ITEM_GROUP_FROM_ITEMS_AND_GROUPS_SELECTION',
  LOAD_MENU_BUILDER_MENU_ITEMS_FOR_INFINITE_SCROLL_BEGIN: 'LOAD_MENU_BUILDER_MENU_ITEMS_FOR_INFINITE_SCROLL_BEGIN',
  LOAD_MENU_BUILDER_MENU_ITEMS_FOR_INFINITE_SCROLL_SUCCESS: 'LOAD_MENU_BUILDER_MENU_ITEMS_FOR_INFINITE_SCROLL_SUCCESS',
  LOAD_MENU_BUILDER_MENU_ITEMS_FOR_INFINITE_SCROLL_ERROR: 'LOAD_MENU_BUILDER_MENU_ITEMS_FOR_INFINITE_SCROLL_ERROR',
  LOAD_MORE_MENU_BUILDER_MENU_ITEMS_FOR_INFINITE_SCROLL_BEGIN:
    'LOAD_MORE_MENU_BUILDER_MENU_ITEMS_FOR_INFINITE_SCROLL_BEGIN',
  LOAD_MORE_MENU_BUILDER_MENU_ITEMS_FOR_INFINITE_SCROLL_SUCCESS:
    'LOAD_MORE_MENU_BUILDER_MENU_ITEMS_FOR_INFINITE_SCROLL_SUCCESS',
  LOAD_MORE_MENU_BUILDER_MENU_ITEMS_FOR_INFINITE_SCROLL_NO_RESULTS:
    'LOAD_MORE_MENU_BUILDER_MENU_ITEMS_FOR_INFINITE_SCROLL_NO_RESULTS',
  LOAD_MORE_MENU_BUILDER_MENU_ITEMS_FOR_INFINITE_SCROLL_ERROR:
    'LOAD_MORE_MENU_BUILDER_MENU_ITEMS_FOR_INFINITE_SCROLL_ERROR',
  LOAD_MENU_BUILDER_ITEM_GROUPS_FOR_INFINITE_SCROLL_BEGIN: 'LOAD_MENU_BUILDER_ITEM_GROUPS_FOR_INFINITE_SCROLL_BEGIN',
  LOAD_MENU_BUILDER_ITEM_GROUPS_FOR_INFINITE_SCROLL_SUCCESS:
    'LOAD_MENU_BUILDER_ITEM_GROUPS_FOR_INFINITE_SCROLL_SUCCESS',
  LOAD_MENU_BUILDER_ITEM_GROUPS_FOR_INFINITE_SCROLL_ERROR: 'LOAD_MENU_BUILDER_ITEM_GROUPS_FOR_INFINITE_SCROLL_ERROR',
  LOAD_MORE_MENU_BUILDER_ITEM_GROUPS_FOR_INFINITE_SCROLL_BEGIN:
    'LOAD_MORE_MENU_BUILDER_ITEM_GROUPS_FOR_INFINITE_SCROLL_BEGIN',
  LOAD_MORE_MENU_BUILDER_ITEM_GROUPS_FOR_INFINITE_SCROLL_SUCCESS:
    'LOAD_MORE_MENU_BUILDER_ITEM_GROUPS_FOR_INFINITE_SCROLL_SUCCESS',
  LOAD_MORE_MENU_BUILDER_ITEM_GROUPS_FOR_INFINITE_SCROLL_NO_RESULTS:
    'LOAD_MORE_MENU_BUILDER_ITEM_GROUPS_FOR_INFINITE_SCROLL_NO_RESULTS',
  LOAD_MORE_MENU_BUILDER_ITEM_GROUPS_FOR_INFINITE_SCROLL_ERROR:
    'LOAD_MORE_MENU_BUILDER_ITEM_GROUPS_FOR_INFINITE_SCROLL_ERROR',
  LOAD_MENU_BUILDER_MENU_ITEMS_FOR_INFINITE_SCROLL_UPDATE_TOKEN:
    'LOAD_MENU_BUILDER_MENU_ITEMS_FOR_INFINITE_SCROLL_UPDATE_TOKEN',

  MENU_GALLERY_SET_CREATE_MODE: 'MENU_GALLERY_SET_CREATE_MODE',
  MENU_GALLERY_CANCEL_CREATE_MODE: 'MENU_GALLERY_CANCEL_CREATE_MODE',
  MENU_GALLERY_UPDATE_GALLERY_NAME: 'MENU_GALLERY_UPDATE_GALLERY_NAME',
  MENU_GALLERY_UPDATE_DESCRIPTION: 'MENU_GALLERY_UPDATE_DESCRIPTION',
  MENU_GALLERY_UPDATE_STATUS: 'MENU_GALLERY_UPDATE_STATUS',
  MENU_GALLERY_ADD_MENU_ITEM: 'MENU_GALLERY_ADD_MENU_ITEM',
  MENU_GALLERY_REMOVE_MENU_ITEM: 'MENU_GALLERY_REMOVE_MENU_ITEM',
  REMOVE_MENU_ITEM_FROM_MENU_GALLERY: 'REMOVE_MENU_ITEM_FROM_MENU_GALLERY',
  ADD_ITEM_GROUP_TO_MENU_GALLERY: 'ADD_ITEM_GROUP_TO_MENU_GALLERY',
  ADD_ITEM_GROUP_TO_MENU_GALLERY_FORM: 'ADD_ITEM_GROUP_TO_MENU_GALLERY_FORM',
  REMOVE_ITEM_GROUP_FROM_MENU_GALLERY_FORM: 'REMOVE_ITEM_GROUP_FROM_MENU_GALLERY_FORM',
  ADD_MENU_ITEM_TO_ITEM_GROUP_IN_ITEM_GALLERY: 'ADD_MENU_ITEM_TO_ITEM_GROUP_IN_ITEM_GALLERY',
  REMOVE_MENU_ITEM_FROM_ITEM_GROUP_IN_ITEM_GALLERY: 'REMOVE_MENU_ITEM_FROM_ITEM_GROUP_IN_ITEM_GALLERY',
  UPDATE_MENU_GALLERY_SUB_ITEM_NAME: 'UPDATE_MENU_GALLERY_SUB_ITEM_NAME',
  UPDATE_MENU_GALLERY_SUB_ITEM_VISIBILITY: 'UPDATE_MENU_GALLERY_SUB_ITEM_VISIBILITY',
  UPDATE_MENU_GALLERY_ITEM_GROUP_SUB_ITEM_VISIBILITY: 'UPDATE_MENU_GALLERY_ITEM_GROUP_SUB_ITEM_VISIBILITY',
  REMOVE_MENU_GALLERY_SUB_ITEM: 'REMOVE_MENU_GALLERY_SUB_ITEM',
  UPDATE_MENU_GALLERY_SUB_ITEM_SORT_ORDER: 'UPDATE_MENU_GALLERY_SUB_ITEM_SORT_ORDER',
  UPDATE_MENU_GALLERY_REORDER_SUB_ITEMS: 'UPDATE_MENU_GALLERY_REORDER_SUB_ITEMS',
  UPDATE_MENU_GALLERY_ITEM_GROUP_ITEM_SORT_ORDER: 'UPDATE_MENU_GALLERY_ITEM_GROUP_ITEM_SORT_ORDER',
  SAVE_MENU_BUILDER_MENU_GALLERY_VALIDATED: 'SAVE_MENU_BUILDER_MENU_GALLERY_VALIDATED',
  SAVE_MENU_BUILDER_MENU_GALLERY_BEGIN: 'SAVE_MENU_BUILDER_MENU_GALLERY_BEGIN',
  SAVE_MENU_BUILDER_MENU_GALLERY_SUCCESS: 'SAVE_MENU_BUILDER_MENU_GALLERY_SUCCESS',
  SAVE_MENU_BUILDER_MENU_GALLERY_ERROR: 'SAVE_MENU_BUILDER_MENU_GALLERY_ERROR',
  MENU_GALLERY_SET_EDIT_MODE_BEGIN: 'MENU_GALLERY_SET_EDIT_MODE_BEGIN',
  MENU_GALLERY_SET_EDIT_MODE_SUCCESS: 'MENU_GALLERY_SET_EDIT_MODE_SUCCESS',
  MENU_GALLERY_SET_EDIT_MODE_ERROR: 'MENU_GALLERY_SET_EDIT_MODE_ERROR',
  MENU_GALLERY_CANCEL_EDIT_MODE: 'MENU_GALLERY_CANCEL_EDIT_MODE',
  MENU_GALLERY_UPDATE_STATUS_BEGIN: 'MENU_GALLERY_UPDATE_STATUS_BEGIN',
  MENU_GALLERY_UPDATE_STATUS_SUCCESS: 'MENU_GALLERY_UPDATE_STATUS_SUCCESS',
  MENU_GALLERY_UPDATE_STATUS_ERROR: 'MENU_GALLERY_UPDATE_STATUS_ERROR',
  REQUEST_DELETE_MENU_GALLERY: 'REQUEST_DELETE_MENU_GALLERY',
  DELETE_MENU_GALLERY_BEGIN: 'DELETE_MENU_GALLERY_BEGIN',
  DELETE_MENU_GALLERY_SUCCESS: 'DELETE_MENU_GALLERY_SUCCESS',
  DELETE_MENU_GALLERY_ERROR: 'DELETE_MENU_GALLERY_ERROR',
  VIEW_MENU_GALLERY_BEGIN: 'VIEW_MENU_GALLERY_BEGIN',
  VIEW_MENU_GALLERY_SUCCESS: 'VIEW_MENU_GALLERY_SUCCESS',
  VIEW_MENU_GALLERY_ERROR: 'VIEW_MENU_GALLERY_ERROR',
  SAVE_MENU_BUILDER_UPDATED_LOCATION_ITEM_GALLERY_BEGIN: 'SAVE_MENU_BUILDER_UPDATED_LOCATION_ITEM_GALLERY_BEGIN',
  SAVE_MENU_BUILDER_UPDATED_LOCATION_ITEM_GALLERY_SUCCESS: 'SAVE_MENU_BUILDER_UPDATED_LOCATION_ITEM_GALLERY_SUCCESS',
  SAVE_MENU_BUILDER_UPDATED_LOCATION_ITEM_GALLERY_ERROR: 'SAVE_MENU_BUILDER_UPDATED_LOCATION_ITEM_GALLERY_ERROR',

  INNER_ITEM_GALLERY_START_EDITING: 'INNER_ITEM_GALLERY_START_EDITING',
  INNER_ITEM_GALLERY_STOP_EDITING: 'INNER_ITEM_GALLERY_STOP_EDITING',
  SAVE_MENU_BUILDER_MENU_ITEM_GALLERY_VALIDATED: 'SAVE_MENU_BUILDER_MENU_ITEM_GALLERY_VALIDATED',
  SAVE_MENU_BUILDER_MENU_ITEM_GALLERY_BEGIN: 'SAVE_MENU_BUILDER_MENU_ITEM_GALLERY_BEGIN',
  SAVE_MENU_BUILDER_MENU_ITEM_GALLERY_SUCCESS: 'SAVE_MENU_BUILDER_MENU_ITEM_GALLERY_SUCCESS',
  SAVE_MENU_BUILDER_MENU_ITEM_GALLERY_ERROR: 'SAVE_MENU_BUILDER_MENU_ITEM_GALLERY_ERROR',
};

export const loadMenus =
  (companyId: string, locationId: string) => async (dispatch: any, getState: () => RootState) => {
    dispatch({ type: MENUS_ACTIONS.LOAD_MENU_BUILDER_MENUS_BEGIN });
    try {
      await dispatch(getMenuConfigsForLocationThunk({ companyId, locationId }));
      
      // use the user selected menu config. otherwise, use the company set active menu config. 
      const displayedConfig = selectUserSelectedMenuConfig(getState()) ?? selectActiveMenuConfig(getState());

      dispatch({
        type: MENUS_ACTIONS.LOAD_MENU_BUILDER_MENUS_SUCCESS,
        payload: displayedConfig?.menusAndGalleries || [],
      });
    } catch (error) {
      dispatch({ type: MENUS_ACTIONS.LOAD_MENU_BUILDER_MENUS_ERROR });
      dispatch(pageErrorState(error.status, error.message));
    }
  };

export const setMenuCreateMode = (visible: boolean, sortOrder?: number) => ({
  type: MENUS_ACTIONS.PREPARE_MENU_BUILDER_EDIT_MENU,
  payload: newMenuForm(visible, newMenu(sortOrder)),
});

export const setMenuEditMode = (editing: boolean, menu: IMenu) => ({
  type: MENUS_ACTIONS.PREPARE_MENU_BUILDER_EDIT_MENU,
  payload: newMenuForm(editing, menu),
});

export const setMenuSectionCreateMode = (editing: boolean) => async (dispatch: any) => {
  dispatch({
    type: MENUS_ACTIONS.PREPARE_MENU_BUILDER_EDIT_MENU_SECTION,
    payload: newMenuSectionForm(editing),
  });
  dispatch({
    type: MENUS_ACTIONS.INNER_ITEM_GALLERY_START_EDITING,
    payload: newItemGallery(),
  });
};

export const setMenuSectionEditMode = (editing: boolean, section?: IMenuSection) => ({
  type: MENUS_ACTIONS.PREPARE_MENU_BUILDER_EDIT_MENU_SECTION,
  payload: newMenuSectionForm(editing, section),
});

export const updateMenuDisplayName = (displayName: string) => ({
  type: MENUS_ACTIONS.UPDATE_MENU_BUILDER_MENU_DISPLAY_NAME,
  payload: displayName,
});

export const updateMenuDescription = (description: string) => ({
  type: MENUS_ACTIONS.UPDATE_MENU_BUILDER_MENU_DESCRIPTION,
  payload: description,
});

export const updateMenuScheduler = (schedule: IScheduleLink<ILink> | null) => ({
  type: MENUS_ACTIONS.UPDATE_MENU_BUILDER_MENU_SCHEDULER,
  payload: schedule,
});

export const updateMenuStatus = (enabled: boolean) => ({
  type: MENUS_ACTIONS.UPDATE_MENU_BUILDER_MENU_STATUS,
  payload: enabled,
});

export const updateMenuSectionViewState = (sectionId: string, isExpanded: boolean) => ({
  type: MENUS_ACTIONS.UPDATE_MENU_BUILDER_MENU_SECTION_VIEW_STATE,
  payload: {
    sectionId,
    isExpanded,
  },
});

export const updateMenuSectionDisplayName = (displayName: string) => ({
  type: MENUS_ACTIONS.UPDATE_MENU_BUILDER_MENU_SECTION_DISPLAY_NAME,
  payload: displayName,
});

export const updateMenuSectionDescription = (description: string) => ({
  type: MENUS_ACTIONS.UPDATE_MENU_BUILDER_MENU_SECTION_DESCRIPTION,
  payload: description,
});

export const updateMenuSectionStatus = (enabled: boolean) => async (dispatch: any) => {
  dispatch({
    type: MENUS_ACTIONS.UPDATE_MENU_BUILDER_MENU_SECTION_STATUS,
    payload: enabled,
  });
  dispatch(setFormIsDirty(true));
};

export const updateMenuSectionDefaultAppearance = (defaultAppearance: Appearance) => async (dispatch: any) => {
  dispatch({
    type: MENUS_ACTIONS.UPDATE_MENU_BUILDER_MENU_SECTION_DEFAULT_APPEARANCE,
    payload: defaultAppearance,
  });
  dispatch(setFormIsDirty(true));
};

export const updateMenuSectionScheduler = (schedule: IScheduleLink<ILink> | null) => ({
  type: MENUS_ACTIONS.UPDATE_MENU_BUILDER_MENU_SECTION_SCHEDULER,
  payload: schedule,
});

export const showDeleteMenuSectionModal = (show: boolean, section?: IMenuSection) => ({
  type: MENUS_ACTIONS.DELETE_MENU_BUILDER_MENU_SECTION_REQUESTED,
  payload: { show, section },
});

export const prepareReorderMenuSectionsModal = (visible: boolean) => ({
  type: MENUS_ACTIONS.PREPARE_MENU_BUILDER_REORDER_MENU_SECTIONS_MODAL,
  payload: visible,
});

export const moveMenuSection = (sourceIndex: number, destinationIndex: number) => ({
  type: MENUS_ACTIONS.MOVE_MENU_SECTION_IN_MENU,
  payload: {
    sourceIndex,
    destinationIndex,
  },
});

export const saveReorderedMenuSections =
  (companyId: string, menu: IMenu, sections: IMenuSection[], locationId?: string) => async (dispatch: any) => {
    const orderedSections = sections.map((section, index) => ({ ...section, sortOrder: index }));
    const menuWithReorderedSections = { ...menu, sections: orderedSections };
    dispatch({ type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_BEGIN });

    let updatedMenu: IMenu;
    try {
      if (isSharedMenu(companyId, locationId)) {
        const mapToTemplateMenu = mapIMenuToITemplateMenu(menuWithReorderedSections);
        const mapToRequest = mapITemplateMenuResponseToUpdateRequest(mapToTemplateMenu);
        const response = await updateTemplateMenu(companyId, mapToRequest);
        updatedMenu = mapITemplateMenuToIMenu(response);
      } else {
        updatedMenu = await MenuBuilderService.updateMenu(companyId, locationId ?? '', menuWithReorderedSections);
      }

      dispatch({
        type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_SUCCESS,
        payload: updatedMenu,
      });
      dispatch(set200Toast());
    } catch (error) {
      dispatch({ type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_ERROR });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const deleteMenuSection =
  (companyId: string, menu: IMenu, section: IMenuSection, locationId?: string) => async (dispatch: any) => {
    const sectionIndex = menu.sections.findIndex((candidateSection) => candidateSection.uid === section.uid);
    if (sectionIndex > -1) {
      const menuWithoutSection = {
        ...menu,
        sections: [...menu.sections.slice(0, sectionIndex), ...menu.sections.slice(sectionIndex + 1)],
      };
      dispatch({ type: MENUS_ACTIONS.DELETE_MENU_BUILDER_MENU_SECTION_BEGIN });
      let updatedMenu: IMenu;
      try {
        if (isSharedMenu(companyId, locationId)) {
          const mapToTemplateMenu = mapIMenuToITemplateMenu(menuWithoutSection);
          const mapToRequest = mapITemplateMenuResponseToUpdateRequest(mapToTemplateMenu);
          const response = await updateTemplateMenu(companyId, mapToRequest);
          updatedMenu = mapITemplateMenuToIMenu(response);
        } else {
          updatedMenu = await MenuBuilderService.updateMenu(companyId, locationId ?? '', menuWithoutSection);
        }
        dispatch({
          type: MENUS_ACTIONS.DELETE_MENU_BUILDER_MENU_SECTION_SUCCESS,
          payload: updatedMenu,
        });
        dispatch(set200Toast());
      } catch (error) {
        dispatch({ type: MENUS_ACTIONS.DELETE_MENU_BUILDER_MENU_SECTION_ERROR });
        dispatch(toastErrorState(error.status, error.message));
      }
    }
  };

export const changeMenuSectionStatus =
  (companyId: string, locationId: string, menu: IMenu, section: IMenuSection, enabled: boolean) =>
  async (dispatch: any) => {
    dispatch({
      type: MENUS_ACTIONS.UPDATE_MENU_BUILDER_MENU_SECTION_STATUS_BEGIN,
    });
    try {
      const updatedMenu = await MenuBuilderService.updateMenuSectionStatus(
        companyId,
        locationId,
        menu,
        section,
        enabled
      );
      dispatch({
        type: MENUS_ACTIONS.UPDATE_MENU_BUILDER_MENU_SECTION_STATUS_SUCCESS,
        payload: updatedMenu,
      });
      dispatch(set200Toast());
    } catch (error) {
      dispatch({
        type: MENUS_ACTIONS.UPDATE_MENU_BUILDER_MENU_SECTION_STATUS_ERROR,
      });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const changeMenuItemGalleryStatus =
  (companyId: string, locationId: string, menu: IMenu, enabled: boolean) => async (dispatch: any) => {
    dispatch({
      type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_BEGIN,
    });
    try {
      const updatedMenu = await MenuBuilderService.updateMenuItemGalleryStatus(companyId, locationId, menu, enabled);
      dispatch({
        type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_SUCCESS,
        payload: updatedMenu,
      });
      dispatch(set200Toast());
    } catch (error) {
      dispatch({
        type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_ERROR,
      });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const saveMenuSection =
  (
    companyId: string,
    locationId: string,
    menu: IMenu,
    section: IMenuSection,
    sectionItemChanges: SectionItemChange[],
    itemGallery: IInnerItemGallery,
    galleryItemChanges: SectionItemChange[],
    readOnlyStock?: boolean
  ) =>
  async (dispatch: any) => {
    const validationResult = MenuBuilderService.validateMenuSection(section, sectionItemChanges, galleryItemChanges);
    if (validationResult.errorsFound) {
      dispatch({
        type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_SECTION_VALIDATED,
        payload: validationResult,
      });
      return;
    }

    dispatch({ type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_SECTION_BEGIN });
    const result = await MenuBuilderService.updateMenuSection(
      companyId,
      locationId,
      menu,
      section,
      sectionItemChanges,
      itemGallery,
      galleryItemChanges,
      readOnlyStock
    );
    handleCoordinatedExecutionResult(
      dispatch,
      result,
      MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_SECTION_SUCCESS,
      MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_SECTION_ERROR
    );
  };

export const addItemGroupToMenuSection = (itemGroup: IMenuItemGroup) => ({
  type: MENUS_ACTIONS.ADD_ITEM_GROUP_TO_MENU_SECTION,
  payload: MenuBuilderService.convertIMenuItemGroupToIMenuSectionItemGroup(itemGroup),
});

export const removeItemGroupFromMenuSection = (itemGroupId: string) => ({
  type: MENUS_ACTIONS.REMOVE_ITEM_GROUP_FROM_MENU_SECTION,
  payload: itemGroupId,
});

export const addMenuItemToMenuSection = (menuItem: IMenuItem) => ({
  type: MENUS_ACTIONS.ADD_MENU_ITEM_TO_MENU_SECTION,
  payload: MenuBuilderService.convertIMenuItemToIMenuSectionItem(menuItem),
});

export const removeMenuItemFromMenuSection = (menuItemId: string) => async (dispatch: any) => {
  dispatch({
    type: MENUS_ACTIONS.REMOVE_MENU_ITEM_FROM_MENU_SECTION,
    payload: menuItemId,
  });
  dispatch(setFormIsDirty(true));
};

export const addMenuItemToItemGroupInMenuSection = (itemGroupId: string, menuItem: IMenuItem) => ({
  type: MENUS_ACTIONS.ADD_MENU_ITEM_TO_ITEM_GROUP_IN_MENU_SECTION,
  payload: {
    itemGroupId,
    menuSectionItem: MenuBuilderService.convertIMenuItemToIMenuSectionItem(menuItem),
  },
});

export const removeMenuItemFromItemGroupInMenuSection =
  (itemGroupId: string, menuItemId: string) => async (dispatch: any) => {
    dispatch({
      type: MENUS_ACTIONS.REMOVE_MENU_ITEM_FROM_ITEM_GROUP_IN_MENU_SECTION,
      payload: {
        itemGroupId,
        menuItemId,
      },
    });
  };

export const addMenuItemToItemGroupInItemGallery = (itemGroupId: string, menuItem: IMenuItem) => ({
  type: MENUS_ACTIONS.ADD_MENU_ITEM_TO_ITEM_GROUP_IN_ITEM_GALLERY,
  payload: {
    itemGroupId,
    menuItem: MenuBuilderService.convertIMenuItemToIMenuSectionItem(menuItem),
  },
});

export const removeMenuItemFromItemGroupInItemGallery =
  (itemGroupId: string, menuItemId: string) => async (dispatch: any) => {
    dispatch({
      type: MENUS_ACTIONS.REMOVE_MENU_ITEM_FROM_ITEM_GROUP_IN_ITEM_GALLERY,
      payload: {
        itemGroupId,
        menuItemId,
      },
    });
  };

export const updateMenuItemDisplayNameInSection = (menuItemId: string, displayName: string) => ({
  type: MENUS_ACTIONS.UPDATE_MENU_BUILDER_MENU_SECTION_ITEM_DISPLAY_NAME,
  payload: {
    menuItemId,
    displayName,
  },
});

export const updateMenuItemVisibilityInSection = (menuItemId: string, visible: boolean) => async (dispatch: any) => {
  dispatch({
    type: MENUS_ACTIONS.UPDATE_MENU_BUILDER_MENU_SECTION_ITEM_VISIBILITY,
    payload: {
      menuItemId,
      visible,
    },
  });
  dispatch(setFormIsDirty(true));
};

export const updateMenuItemVisibilityInSectionItemGroup =
  (itemGroupId: string, menuItemId: string, visible: boolean) => async (dispatch: any) => {
    dispatch({
      type: MENUS_ACTIONS.UPDATE_MENU_BUILDER_MENU_SECTION_ITEM_GROUP_ITEM_VISIBILITY,
      payload: {
        itemGroupId,
        menuItemId,
        visible,
      },
    });
    dispatch(setFormIsDirty(true));
  };

export const moveMenuItemInSection = (sourceIndex: number, destinationIndex: number) => async (dispatch: any) => {
  dispatch({
    type: MENUS_ACTIONS.MOVE_MENU_ITEM_IN_MENU_SECTION,
    payload: {
      sourceIndex,
      destinationIndex,
    },
  });
  dispatch(setFormIsDirty(true));
};

export const moveMenuItemInGroup =
  (groupId: string, sourceIndex: number, destinationIndex: number) => async (dispatch: any) => {
    dispatch({
      type: MENUS_ACTIONS.MOVE_MENU_ITEM_IN_MENU_SECTION_GROUP,
      payload: {
        groupId,
        sourceIndex,
        destinationIndex,
      },
    });
    dispatch(setFormIsDirty(true));
  };

export const prepareReorderMenusModal = (visible: boolean) => ({
  type: MENUS_ACTIONS.PREPARE_MENU_BUILDER_REORDER_MENUS_MODAL,
  payload: visible,
});

export const moveMenu = (sourceIndex: number, destinationIndex: number) => ({
  type: MENUS_ACTIONS.MOVE_MENU_IN_MENUS_LIST,
  payload: {
    sourceIndex,
    destinationIndex,
  },
});

export const saveMenuOrder =
  (companyId: string, locationId: string, menus: IMenuWithType[]) => async (dispatch: any) => {
    dispatch({ type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENUS_BEGIN });
    try {
      const sortables: ISortableMenuSummary[] = menus.map((menu: IMenuWithType, index: number) => ({
        _id: menu._id,
        sortOrder: index,
        type: menu.type as SummaryType,
      }));
      const updatedMenuGroup = await MenuBuilderService.updateMenuOrder(companyId, locationId, sortables);
      dispatch({
        type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENUS_SUCCESS,
        payload: updatedMenuGroup.menusAndGalleries,
      });
      dispatch(set200Toast());
    } catch (error) {
      dispatch({ type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENUS_ERROR });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const showDeleteMenuModal = (show: boolean, menu?: IMenu) => ({
  type: MENUS_ACTIONS.DELETE_MENU_BUILDER_MENU_REQUESTED,
  payload: { show, menu },
});

export const deleteMenu = (companyId: string, locationId: string, menu: IMenu) => async (dispatch: any) => {
  dispatch({ type: MENUS_ACTIONS.DELETE_MENU_BUILDER_MENU_BEGIN });
  try {
    await MenuBuilderService.deleteMenuById(companyId, locationId, menu._id);
    dispatch(set200Toast('Menu deleted.'));
    dispatch({ type: MENUS_ACTIONS.DELETE_MENU_BUILDER_MENU_SUCCESS });
  } catch (error) {
    dispatch(toastErrorState(error.status, error.message));
    dispatch({ type: MENUS_ACTIONS.DELETE_MENU_BUILDER_MENU_ERROR });
  }
};

export const loadMenu = (companyId: string, locationId: string, id: string) => async (dispatch: any) => {
  dispatch({ type: MENUS_ACTIONS.LOAD_MENU_BUILDER_MENU_BEGIN });
  try {
    const menu = await MenuBuilderService.getMenu(companyId, locationId, id);
    dispatch({
      type: MENUS_ACTIONS.LOAD_MENU_BUILDER_MENU_SUCCESS,
      payload: menu,
    });
  } catch (error) {
    dispatch({ type: MENUS_ACTIONS.LOAD_MENU_BUILDER_MENU_ERROR });
    dispatch(pageErrorState(error.status, error.message));
  }
};

export const updateMenuItemVisibilityAndSave =
  (companyId: string, locationId: string, menu: IMenu, sectionId: string, menuItemId: string, visible: boolean) =>
  async (dispatch: any) => {
    dispatch({ type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_BEGIN });
    const savedMenu = await MenuBuilderService.updateMenuSectionMenuItemVisibility(
      companyId,
      locationId,
      menu,
      sectionId,
      menuItemId,
      visible
    );
    try {
      dispatch({
        type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_SUCCESS,
        payload: savedMenu,
      });
    } catch (error) {
      dispatch({ type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_ERROR });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const updateItemGroupMenuItemVisibilityAndSave =
  (
    companyId: string,
    locationId: string,
    menu: IMenu,
    sectionId: string,
    itemGroupId: string,
    menuItemId: string,
    visible: boolean
  ) =>
  async (dispatch: any) => {
    dispatch({ type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_BEGIN });
    const updatedMenu = await MenuBuilderService.updateMenuSectionItemGroupMenuItemVisibility(
      companyId,
      locationId,
      menu,
      sectionId,
      itemGroupId,
      menuItemId,
      visible
    );
    try {
      dispatch({
        type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_SUCCESS,
        payload: updatedMenu,
      });
    } catch (error) {
      dispatch({ type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_ERROR });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const updateItemGalleryMenuItemVisibilityAndSave =
  (companyId: string, locationId: string, menu: IMenu, itemGalleryId: string, menuItemId: string, visible: boolean) =>
  async (dispatch: any) => {
    dispatch({ type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_BEGIN });
    const savedMenu = await MenuBuilderService.updateInnerItemGalleryMenuItemVisibility(
      companyId,
      locationId,
      menu,
      itemGalleryId,
      menuItemId,
      visible
    );
    try {
      dispatch({
        type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_SUCCESS,
        payload: savedMenu,
      });
    } catch (error) {
      dispatch({ type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_ERROR });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const updateLocationItemGalleryMenuItemVisibilityAndSave =
  (companyId: string, locationId: string, itemGallery: IItemGallery, menuItemId: string, visible: boolean) =>
  async (dispatch: any) => {
    dispatch({ type: MENUS_ACTIONS.SAVE_MENU_BUILDER_UPDATED_LOCATION_ITEM_GALLERY_BEGIN });
    const updatedItemGallery = await MenuBuilderService.updateLocationLevelItemGalleryMenuItemVisibility(
      companyId,
      locationId,
      itemGallery,
      menuItemId,
      visible
    );
    try {
      dispatch({
        type: MENUS_ACTIONS.SAVE_MENU_BUILDER_UPDATED_LOCATION_ITEM_GALLERY_SUCCESS,
        payload: updatedItemGallery,
      });
    } catch (error) {
      dispatch({ type: MENUS_ACTIONS.SAVE_MENU_BUILDER_UPDATED_LOCATION_ITEM_GALLERY_ERROR });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const updateItemGalleryItemGroupMenuItemVisibilityAndSave =
  (
    companyId: string,
    locationId: string,
    menu: IMenu,
    itemGalleryId: string,
    itemGroupId: string,
    menuItemId: string,
    visible: boolean
  ) =>
  async (dispatch: any) => {
    dispatch({ type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_BEGIN });
    const updatedMenu = await MenuBuilderService.updateInnerItemGalleryItemGroupMenuItemVisibility(
      companyId,
      locationId,
      menu,
      itemGalleryId,
      itemGroupId,
      menuItemId,
      visible
    );
    try {
      dispatch({
        type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_SUCCESS,
        payload: updatedMenu,
      });
    } catch (error) {
      dispatch({ type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_ERROR });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const updateLocationItemGalleryItemGroupMenuItemVisibilityAndSave =
  (
    companyId: string,
    locationId: string,
    itemGallery: IItemGallery,
    itemGroupId: string,
    menuItemId: string,
    visible: boolean
  ) =>
  async (dispatch: any) => {
    dispatch({ type: MENUS_ACTIONS.SAVE_MENU_BUILDER_UPDATED_LOCATION_ITEM_GALLERY_BEGIN });
    const updatedItemGallery = await MenuBuilderService.updateLocationItemGalleryItemGroupMenuItemVisibilityAndSave(
      companyId,
      locationId,
      itemGallery,
      itemGroupId,
      menuItemId,
      visible
    );
    try {
      dispatch({
        type: MENUS_ACTIONS.SAVE_MENU_BUILDER_UPDATED_LOCATION_ITEM_GALLERY_SUCCESS,
        payload: updatedItemGallery,
      });
    } catch (error) {
      dispatch({ type: MENUS_ACTIONS.SAVE_MENU_BUILDER_UPDATED_LOCATION_ITEM_GALLERY_ERROR });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const resetForms = () => ({
  type: MENUS_ACTIONS.RESET_MENU_BUILDER_MENU_FORMS,
});

export const advancedSettingsDialogVisible = (visibility: boolean) => ({
  type: MENUS_ACTIONS.ADVANCED_SETTINGS_DIALOG_VISIBLE,
  payload: visibility,
});

export const advancedSettingsMenuSecondaryNavigation = (value: boolean) => ({
  type: MENUS_ACTIONS.ADVANCED_SETTINGS_MENU_SECONDARY_NAVIGATION,
  payload: value,
});

export const advancedSettingsReadOnlyMenus = (value: boolean) => ({
  type: MENUS_ACTIONS.ADVANCED_SETTINGS_READ_ONLY_MENUS,
  payload: value,
});

export const advancedSettingsResetSettings = () => ({
  type: MENUS_ACTIONS.ADVANCED_SETTINGS_RESET_SETTINGS,
});

export const advancedSettingsSaveSettings = () => ({
  type: MENUS_ACTIONS.ADVANCED_SETTINGS_SAVE_SETTINGS,
});

export const prepareItemsAndGroupsModal = (
  modalVisible: boolean,
  selectedIds: string[] = [],
  view: ItemsAndGroupsView = ItemsAndGroupsView.ITEMS
) => ({
  type: MENUS_ACTIONS.PREPARE_MENU_BUILDER_ITEMS_AND_GROUPS_MODAL,
  payload: {
    modalVisible,
    selectedIds,
    view,
  },
});

export const setItemsAndGroupsModalView = (view: ItemsAndGroupsView) => ({
  type: MENUS_ACTIONS.SET_MENU_BUILDER_ITEMS_AND_GROUPS_MODAL_VIEW,
  payload: view,
});

export const setItemsAndGroupsModalQuery = (query: string) => ({
  type: MENUS_ACTIONS.SET_MENU_BUILDER_ITEMS_AND_GROUPS_MODAL_QUERY,
  payload: query,
});

export const selectMenuItemInItemsAndGroupsModal = (id: string) => ({
  type: MENUS_ACTIONS.ADD_MENU_ITEM_TO_ITEMS_AND_GROUPS_SELECTION,
  payload: id,
});

export const deselectMenuItemInItemsAndGroupsModal = (id: string) => ({
  type: MENUS_ACTIONS.REMOVE_MENU_ITEM_FROM_ITEMS_AND_GROUPS_SELECTION,
  payload: id,
});

export const selectItemGroupInItemsAndGroupsModal = (id: string) => ({
  type: MENUS_ACTIONS.ADD_ITEM_GROUP_TO_ITEMS_AND_GROUPS_SELECTION,
  payload: id,
});

export const deselectItemGroupInItemsAndGroupsModal = (id: string) => ({
  type: MENUS_ACTIONS.REMOVE_ITEM_GROUP_FROM_ITEMS_AND_GROUPS_SELECTION,
  payload: id,
});

export const updateToken = (token: string) => ({
  type: MENUS_ACTIONS.LOAD_MENU_BUILDER_MENU_ITEMS_FOR_INFINITE_SCROLL_UPDATE_TOKEN,
  payload: token,
});

export const loadMenuItemsForInfiniteScroll =
  (companyId: string, locationId: string, query: string = '') =>
  async (dispatch: any) => {
    dispatch({
      type: MENUS_ACTIONS.LOAD_MENU_BUILDER_MENU_ITEMS_FOR_INFINITE_SCROLL_BEGIN,
    });

    // We're generating a unique token each time we make a call to fetch menu items.
    // The call is created each time a user types a key into the infinite scroll search
    // each time we create a token we store that token in redux.
    // As each async call finishes, we check the token we generated for that call
    // against the token stored in redux. If the token matches the one stored in redux,
    // we update the menu item state as this is the list of items we want. If the tokens don't match,
    // we ignore the result of the api call, and we don't update the menu item list state.
    const token = Date.now().toString();
    dispatch(updateToken(token));

    try {
      let results: IMenuItem[] = [];

      if (isSharedMenu(companyId, locationId)) {
        const sharedMenuItemsPagination = await getSharedMenuItemList(companyId, query, 'items');
        results = mapITemplateMenuItemToIMenuItem(sharedMenuItemsPagination.items);
      } else {
        const menuBuilderItemsPagination = await MenuBuilderService.getMenuItems(
          companyId,
          locationId,
          MenuItemsView.ITEMS,
          ItemsAndGroupsStockStatus.All,
          query
        );
        results = menuBuilderItemsPagination.items;
      }

      dispatch({
        type: MENUS_ACTIONS.LOAD_MENU_BUILDER_MENU_ITEMS_FOR_INFINITE_SCROLL_SUCCESS,
        payload: {
          items: results,
          token: token,
        },
      });
    } catch (error) {
      dispatch({
        type: MENUS_ACTIONS.LOAD_MENU_BUILDER_MENU_ITEMS_FOR_INFINITE_SCROLL_ERROR,
      });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const loadMoreMenuItemsForInfiniteScroll =
  (companyId: string, locationId: string, query: string = '', page: number) =>
  async (dispatch: any) => {
    dispatch({
      type: MENUS_ACTIONS.LOAD_MORE_MENU_BUILDER_MENU_ITEMS_FOR_INFINITE_SCROLL_BEGIN,
    });
    try {
      let results: IMenuItem[] = [];
      const nextPage = page + 1;

      if (isSharedMenu(companyId, locationId)) {
        const sharedMenuItemsPagination = await getSharedMenuItemList(companyId, query, 'items', '', nextPage);
        results = mapITemplateMenuItemToIMenuItem(sharedMenuItemsPagination.items);
      } else {
        const menuBuilderItemsPagination = await MenuBuilderService.getMenuItems(
          companyId,
          locationId,
          MenuItemsView.ITEMS,
          ItemsAndGroupsStockStatus.All,
          query,
          nextPage
        );
        results = menuBuilderItemsPagination.items;
      }

      if (results.length > 0) {
        dispatch({
          type: MENUS_ACTIONS.LOAD_MORE_MENU_BUILDER_MENU_ITEMS_FOR_INFINITE_SCROLL_SUCCESS,
          payload: results,
        });
      } else {
        dispatch({
          type: MENUS_ACTIONS.LOAD_MORE_MENU_BUILDER_MENU_ITEMS_FOR_INFINITE_SCROLL_NO_RESULTS,
        });
      }
    } catch (error) {
      dispatch({
        type: MENUS_ACTIONS.LOAD_MORE_MENU_BUILDER_MENU_ITEMS_FOR_INFINITE_SCROLL_ERROR,
      });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const loadItemGroupsForInfiniteScroll =
  (companyId: string, locationId: string, query: string = '') =>
  async (dispatch: any) => {
    dispatch({ type: MENUS_ACTIONS.LOAD_MENU_BUILDER_ITEM_GROUPS_FOR_INFINITE_SCROLL_BEGIN });

    try {
      let results: IMenuItemGroup[] = [];

      if (isSharedMenu(companyId, locationId)) {
        const sharedMenuItemGroupsPagination = await getSharedItemGroupList(companyId, query);
        results = mapITemplateMenuItemGroupToIMenuItemGroup(sharedMenuItemGroupsPagination.items);
      } else {
        const menuBuilderItemGroupsPagination = await MenuBuilderService.getItemGroups(companyId, locationId, query);
        results = menuBuilderItemGroupsPagination.items;
      }
      dispatch({
        type: MENUS_ACTIONS.LOAD_MENU_BUILDER_ITEM_GROUPS_FOR_INFINITE_SCROLL_SUCCESS,
        payload: results,
      });
    } catch (error) {
      dispatch({ type: MENUS_ACTIONS.LOAD_MENU_BUILDER_ITEM_GROUPS_FOR_INFINITE_SCROLL_ERROR });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const loadMoreItemGroupsForInfiniteScroll =
  (companyId: string, locationId: string, query: string = '', page: number) =>
  async (dispatch: any) => {
    dispatch({ type: MENUS_ACTIONS.LOAD_MORE_MENU_BUILDER_ITEM_GROUPS_FOR_INFINITE_SCROLL_BEGIN });
    try {
      let results: IMenuItemGroup[] = [];
      const nextPage = page + 1;

      if (isSharedMenu(companyId, locationId)) {
        const sharedMenuItemGroupsPagination = await getSharedItemGroupList(companyId, query, '', nextPage);
        results = mapITemplateMenuItemGroupToIMenuItemGroup(sharedMenuItemGroupsPagination.items);
      } else {
        const menuBuilderItemGroupsPagination = await MenuBuilderService.getItemGroups(
          companyId,
          locationId,
          query,
          nextPage
        );
        results = menuBuilderItemGroupsPagination.items;
      }

      if (results.length > 0) {
        dispatch({
          type: MENUS_ACTIONS.LOAD_MORE_MENU_BUILDER_ITEM_GROUPS_FOR_INFINITE_SCROLL_SUCCESS,
          payload: results,
        });
      } else {
        dispatch({ type: MENUS_ACTIONS.LOAD_MORE_MENU_BUILDER_ITEM_GROUPS_FOR_INFINITE_SCROLL_NO_RESULTS });
      }
    } catch (error) {
      dispatch({ type: MENUS_ACTIONS.LOAD_MORE_MENU_BUILDER_ITEM_GROUPS_FOR_INFINITE_SCROLL_ERROR });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const menuGallerySetCreateMode = (sortOrder: number) => ({
  type: MENUS_ACTIONS.MENU_GALLERY_SET_CREATE_MODE,
  payload: sortOrder,
});

export const menuGalleryCancelCreateMode = () => ({
  type: MENUS_ACTIONS.MENU_GALLERY_CANCEL_CREATE_MODE,
  payload: newMenuGalleryForm(),
});

export const menuGalleryUpdateGalleryName = (galleryName: string) => ({
  type: MENUS_ACTIONS.MENU_GALLERY_UPDATE_GALLERY_NAME,
  payload: galleryName,
});

export const menuGalleryUpdateDescription = (description: string) => ({
  type: MENUS_ACTIONS.MENU_GALLERY_UPDATE_DESCRIPTION,
  payload: description,
});

export const menuGalleryUpdateStatus = (status: number) => ({
  type: MENUS_ACTIONS.MENU_GALLERY_UPDATE_STATUS,
  payload: status,
});

export const addMenuItemToMenuGallerySection = (menuItem: IMenuItem) => ({
  type: MENUS_ACTIONS.MENU_GALLERY_ADD_MENU_ITEM,
  payload: MenuBuilderService.convertIMenuItemToIMenuSectionItem(menuItem),
});

export const addItemGroupToMenuGallerySection = (itemGroup: IMenuItemGroup) => ({
  type: MENUS_ACTIONS.ADD_ITEM_GROUP_TO_MENU_GALLERY_FORM,
  payload: MenuBuilderService.convertIMenuItemGroupToIMenuSectionItemGroup(itemGroup),
});

export const removeItemGroupFromMenuGallerySection = (itemGroupId: string) => ({
  type: MENUS_ACTIONS.REMOVE_ITEM_GROUP_FROM_MENU_GALLERY_FORM,
  payload: itemGroupId,
});

export const selectItemForMenuGallery = (menuItem: IMenuItem) => async (dispatch: any) => {
  dispatch(selectMenuItemInItemsAndGroupsModal(menuItem._id));
  dispatch(addMenuItemToMenuGallerySection(menuItem));
  dispatch(setFormIsDirty(true));
};

export const selectItemGroupForMenuGallery = (itemGroup: IMenuItemGroup) => async (dispatch: any) => {
  dispatch(selectItemGroupInItemsAndGroupsModal(itemGroup._id));
  dispatch(addItemGroupToMenuGallerySection(itemGroup));
  dispatch(setFormIsDirty(true));
};

export const deselectMenuItemInMenuGallery = (id: string) => ({
  type: MENUS_ACTIONS.MENU_GALLERY_REMOVE_MENU_ITEM,
  payload: id,
});

export const removeMenuItemFromMenuGallery = (menuItemId: string) => async (dispatch: any) => {
  dispatch({
    type: MENUS_ACTIONS.REMOVE_MENU_ITEM_FROM_MENU_GALLERY,
    payload: menuItemId,
  });
  dispatch(setFormIsDirty(true));
};

export const deselectItemForMenuGallery = (menuItemId: string) => async (dispatch: any) => {
  dispatch(deselectMenuItemInMenuGallery(menuItemId));
  dispatch(removeMenuItemFromMenuGallery(menuItemId));
  dispatch(setFormIsDirty(true));
};

export const deselectItemGroupForMenuGallery = (itemGroupId: string) => async (dispatch: any) => {
  dispatch(deselectMenuItemInMenuGallery(itemGroupId));
  dispatch(removeItemGroupFromMenuGallerySection(itemGroupId));
  dispatch(setFormIsDirty(true));
};

export const selectItemGroupInMenuGalleryModal = (id: string) => ({
  type: MENUS_ACTIONS.ADD_ITEM_GROUP_TO_ITEMS_AND_GROUPS_SELECTION,
  payload: id,
});

export const addItemGroupToMenuGallery = (itemGroup: IMenuItemGroup) => ({
  type: MENUS_ACTIONS.ADD_ITEM_GROUP_TO_MENU_SECTION,
  payload: MenuBuilderService.convertIMenuItemGroupToIMenuSectionItemGroup(itemGroup),
});

export const selectItemGroupForMenuSection = (itemGroup: IMenuItemGroup) => async (dispatch: any) => {
  dispatch(selectItemGroupInMenuGalleryModal(itemGroup._id));
  dispatch(addItemGroupToMenuGallery(itemGroup));
  dispatch(setFormIsDirty(true));
};

export const updateMenuGallerySubitemName = (itemId: string, displayName: string) => ({
  type: MENUS_ACTIONS.UPDATE_MENU_GALLERY_SUB_ITEM_NAME,
  payload: { itemId, displayName },
});

export const updateItemGalleryItemVisibility = (itemId: string, visibility: number, immediateSync = true) => ({
  type: MENUS_ACTIONS.UPDATE_MENU_GALLERY_SUB_ITEM_VISIBILITY,
  payload: { itemId, visibility, immediateSync },
});

export const updateItemGalleryItemGroupItemVisibility = (
  groupId: string,
  itemId: string,
  visibility: number,
  immediateSync = true
) => ({
  type: MENUS_ACTIONS.UPDATE_MENU_GALLERY_ITEM_GROUP_SUB_ITEM_VISIBILITY,
  payload: { groupId, itemId, visibility, immediateSync },
});

export const removeMenuGallerySubitem = (itemId: string) => ({
  type: MENUS_ACTIONS.REMOVE_MENU_GALLERY_SUB_ITEM,
  payload: itemId,
});

export const updateItemGalleryItemsSortOrder = () => ({
  type: MENUS_ACTIONS.UPDATE_MENU_GALLERY_SUB_ITEM_SORT_ORDER,
});

export const updateItemGalleryItemGroupItemsSortOrder = (
  groupId: string,
  sourceIndex: number,
  destinationIndex: number
) => ({
  type: MENUS_ACTIONS.UPDATE_MENU_GALLERY_ITEM_GROUP_ITEM_SORT_ORDER,
  payload: {
    groupId,
    sourceIndex,
    destinationIndex,
  },
});

export const updateItemGalleryReorderItems = (sourceIndex: number, destinationIndex: number) => ({
  type: MENUS_ACTIONS.UPDATE_MENU_GALLERY_REORDER_SUB_ITEMS,
  payload: {
    sourceIndex,
    destinationIndex,
  },
});

export const saveMenuItemGallery =
  (companyId: string, locationId: string, menu: IMenu, sectionItemChanges: SectionItemChange[]) =>
  async (dispatch: any) => {
    if (!menu.itemGallery?.displayName) {
      dispatch({
        type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_GALLERY_VALIDATED,
        payload: {
          hasError: true,
          errorMessage: 'Item Gallery Name is required',
        },
      });
      return;
    }

    dispatch({ type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_GALLERY_BEGIN });
    try {
      const result = await MenuBuilderService.saveMenuGallery(
        companyId,
        locationId,
        {
          ...menu.itemGallery!,
          sortOrder: menu.sortOrder,
        } as IItemGallery,
        sectionItemChanges
      );
      dispatch({
        type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_GALLERY_SUCCESS,
      });
      handleCoordinatedExecutionResult(
        dispatch,
        result,
        MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_GALLERY_SUCCESS,
        MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_GALLERY_ERROR,
        !menu._id ? 'Success! New item gallery created.' : undefined
      );
    } catch (error) {
      dispatch({ type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_GALLERY_ERROR });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const menuGallerySetEditMode =
  (companyId: string, locationId: string, itemGalleryId: string) => async (dispatch: any) => {
    const getMenuGallery = async (
      companyId: string,
      itemGalleryId: string,
      locationId?: string
    ): Promise<IItemGallery> => {
      if (!!locationId) {
        return MenuBuilderService.getMenuGallery(companyId, locationId, itemGalleryId);
      } else {
        const result = await getTemplateMenuGroupItemGallery(companyId, itemGalleryId);
        return mapITemplateItemGalleryToIItemGallery(result);
      }
    };

    dispatch({
      type: MENUS_ACTIONS.MENU_GALLERY_SET_EDIT_MODE_BEGIN,
      payload: {
        itemGalleryId,
        editing: true,
      },
    });
    try {
      const menuItemGallery = await getMenuGallery(companyId, itemGalleryId, locationId);
      dispatch({
        type: MENUS_ACTIONS.MENU_GALLERY_SET_EDIT_MODE_SUCCESS,
        payload: menuItemGallery,
      });
    } catch (error) {
      dispatch({ type: MENUS_ACTIONS.MENU_GALLERY_SET_EDIT_MODE_ERROR });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const updateMenuGalleryStatus =
  (companyId: string, locationId: string, itemGalleryId: string, status: number) => async (dispatch: any) => {
    dispatch({ type: MENUS_ACTIONS.MENU_GALLERY_UPDATE_STATUS_BEGIN });
    try {
      const menuItemGallery = await MenuBuilderService.getMenuGallery(companyId, locationId, itemGalleryId);
      const locationItemGalleryToUpdate: IItemGallery = {
        ...menuItemGallery,
        status,
        sortOrder: menuItemGallery.sortOrder,
      };
      await MenuBuilderService.updateMenuGallery(companyId, locationId, locationItemGalleryToUpdate);
      dispatch({ type: MENUS_ACTIONS.MENU_GALLERY_UPDATE_STATUS_SUCCESS });
      dispatch(set200Toast('Status updated.'));
    } catch (error) {
      dispatch({ type: MENUS_ACTIONS.MENU_GALLERY_UPDATE_STATUS_ERROR });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const requestDeleteMenuGallery = (show: boolean, itemGalleryId?: string, itemGalleryName?: string) => ({
  type: MENUS_ACTIONS.REQUEST_DELETE_MENU_GALLERY,
  payload: {
    show,
    itemGalleryId,
    itemGalleryName,
  },
});

export const deleteMenuGallery =
  (companyId: string, locationId: string, itemGalleryId: string) => async (dispatch: any) => {
    dispatch({ type: MENUS_ACTIONS.DELETE_MENU_GALLERY_BEGIN });
    try {
      await MenuBuilderService.deleteMenuGalleryById(companyId, locationId, itemGalleryId);
      dispatch({ type: MENUS_ACTIONS.DELETE_MENU_GALLERY_SUCCESS });
      dispatch(set200Toast('Item gallery deleted.'));
    } catch (error) {
      dispatch({ type: MENUS_ACTIONS.DELETE_MENU_GALLERY_ERROR });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const viewMenuGallery =
  (companyId: string, locationId: string, itemGalleryId: string) => async (dispatch: any) => {
    dispatch({
      type: MENUS_ACTIONS.VIEW_MENU_GALLERY_BEGIN,
      payload: itemGalleryId,
    });
    try {
      const itemGallery = await MenuBuilderService.getMenuGallery(companyId, locationId, itemGalleryId);
      dispatch({
        type: MENUS_ACTIONS.VIEW_MENU_GALLERY_SUCCESS,
        payload: itemGallery,
      });
    } catch (error) {
      dispatch({
        type: MENUS_ACTIONS.VIEW_MENU_GALLERY_ERROR,
        payload: itemGalleryId,
      });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const startEditingInnerItemGallery = (itemGallery: IInnerItemGallery) => ({
  type: MENUS_ACTIONS.INNER_ITEM_GALLERY_START_EDITING,
  payload: itemGallery,
});

export const stopEditingInnerItemGallery = () => ({
  type: MENUS_ACTIONS.INNER_ITEM_GALLERY_STOP_EDITING,
});

export const saveInnerItemGallery =
  (
    companyId: string,
    locationId: string,
    menu: IMenu,
    itemGallery: IInnerItemGallery,
    sectionItemChanges: SectionItemChange[],
    readOnlyStock?: boolean
  ) =>
  async (dispatch: any) => {
    const validationResult = MenuBuilderService.validateSectionItemChanges(sectionItemChanges);
    if (validationResult.errorsFound) {
      dispatch({
        type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_ITEM_GALLERY_VALIDATED,
        payload: validationResult.items,
      });
      return;
    }

    dispatch({ type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_ITEM_GALLERY_BEGIN });
    const result = await MenuBuilderService.updateMenuInnerItemGallery(
      companyId,
      locationId,
      menu,
      itemGallery,
      sectionItemChanges,
      readOnlyStock
    );
    handleCoordinatedExecutionResult(
      dispatch,
      result,
      MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_ITEM_GALLERY_SUCCESS,
      MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_ITEM_GALLERY_ERROR,
      !menu._id ? 'Success! New menu created.' : undefined
    );
  };

export const handleCoordinatedExecutionResult = (
  dispatch: any,
  result: CoordinatedExecutionResult,
  successAction: string,
  errorAction: string,
  successMessage?: string
): void => {
  // regardless of overall success or failure, notify of any failures that occurred
  for (const failure of result.failures) {
    dispatch(setGenericErrorToast('Some items did not save.', failure.groupMessage, failure.individualMessages));
  }

  if (result.isSuccessful) {
    // now handle the overall result, even though there may have been non-critical failures
    dispatch({
      type: successAction,
      payload: result.returnValue,
    });
    if (!result.failures.length) {
      // only toast success if overall result is successful and no non-critical errors were shown
      if (!!successMessage) {
        dispatch(set200Toast(successMessage));
      } else {
        dispatch(set200Toast());
      }
    }
  } else {
    // by this point all necessary toast messaging has occurred
    dispatch({ type: errorAction });
  }
};

export const saveMenuAdvancedSettings =
  (companyId: string, locationId: string, menuSectionsSecondaryNavigation: boolean, readOnlyMenus: boolean) =>
  async (dispatch: any) => {
    dispatch({
      type: MENUS_ACTIONS.SAVE_ADVANCED_SETTINGS_BEGIN,
    });
    try {
      const result = await MenuBuilderService.updateAdvancedSettings(
        companyId,
        locationId,
        !menuSectionsSecondaryNavigation,
        readOnlyMenus
      );
      const payload = {
        menuSectionsSecondaryNavigation: !result.suppressSectionsInNavigation,
        readOnlyMenus: !!result.menuReadOnly,
      };
      dispatch({
        type: MENUS_ACTIONS.SAVE_ADVANCED_SETTINGS_SUCCESS,
        payload,
      });
      dispatch(set200Toast('Advanced Settings updated.'));
    } catch (error) {
      dispatch({ type: MENUS_ACTIONS.SAVE_ADVANCED_SETTINGS_ERROR });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const initializeAdvancedSettings = (menuSectionsSecondaryNavigation: boolean, readOnlyMenus: boolean) => ({
  type: MENUS_ACTIONS.INITIALIZE_ADVANCED_SETTINGS,
  payload: { menuSectionsSecondaryNavigation, readOnlyMenus },
});
