import styles from './MenuConfigPanel.module.scss';
import React, { useState } from 'react';
import EmptyResult from 'components/EmptyResult/EmptyResult';
import Notification from 'components/Notification/Notification';
import { ILocationAssignments, WithAssignedLocationsCount } from '@ready/menu.core';
import { Panel } from 'components/PanelLayout';
import { MenuConfigPanelHeaderControls as HeaderControls } from './MenuConfigPanelHeaderControls';
import { IMenuGroupUI, IMenuSummaryUI } from '../../redux/types';
import { MenuConfigPanelRow } from '../MenuConfigPanelRow/MenuConfigPanelRow';

import LinkButton from 'components/LinkButton/LinkButton';
import { LoadAssignedLocations } from 'sharedMenuItems/components/ManageAssignedLocationsModal/ManageAssignedLocationsModal';
import { ManageAssignedLocationsModal } from 'sharedMenuItems/components/ManageAssignedLocationsModal/ManageAssignedLocationsModal';

import { ISaveNameAndDescriptionModalFields } from 'components/nameAndDescriptionModal/NameAndDescriptionModal';
import TextIcon, { Icon } from 'components/Icon/TextIcon';

interface Props {
  menuConfig: WithAssignedLocationsCount<IMenuGroupUI>;
  onCreateMenu: (menu: ISaveNameAndDescriptionModalFields) => Promise<void>;
  onDeleteMenuConfig: (id: string) => Promise<void>;
  onRowClick: (id: string, isGallery: boolean) => void;
  onEditNameSave: (menu: IMenuGroupUI) => Promise<void>;
  onCreateMenuConfigItemGallery: (itemGallery: ISaveNameAndDescriptionModalFields) => Promise<void>;
  onDeleteMenuOrGallery: (menuOrGalleryId: string, isGallery: boolean) => Promise<void>;
  onReorderMenusSave: (items: IMenuSummaryUI[]) => Promise<void>;

  loadLocationAssignments: (args: LoadAssignedLocations) => void;
  onSaveAssignedLocations: (locationAssignments: ILocationAssignments) => Promise<void>;
  totalAssignableLocations?: number;
  onUpdateMenuOrItemGallerySummary: (
    id: string,
    menuFields: ISaveNameAndDescriptionModalFields,
    isGallery?: boolean
  ) => Promise<void>;
}

export const MenuConfigPanel = ({
  onCreateMenu,
  menuConfig,
  onDeleteMenuConfig,
  onRowClick,
  onEditNameSave,
  onDeleteMenuOrGallery,
  onReorderMenusSave,
  onUpdateMenuOrItemGallerySummary,
  onCreateMenuConfigItemGallery,
  loadLocationAssignments,
  onSaveAssignedLocations,
  totalAssignableLocations = 0,
}: Props) => {
  const { name, menusAndGalleries, assignedLocationsCount } = menuConfig;
  const [showAssignModal, setShowAssignModal] = useState<boolean>();

  return (
    <>
      <Panel
        title={name}
        subTitleComponent={
          <span className={styles.subtitle}>
            <span className={styles.assignedTo}>Assigned to: </span>

            <LinkButton fontWeight='normal' onClick={() => setShowAssignModal(true)}>
              {assignedLocationsCount || 0} {`Location${assignedLocationsCount === 1 ? '' : 's'}`}
            </LinkButton>
          </span>
        }
        headerControls={
          <HeaderControls
            menuConfig={menuConfig}
            onCreateMenu={onCreateMenu}
            onDeleteMenuConfig={onDeleteMenuConfig}
            onEditNameSave={onEditNameSave}
            onReorderMenusSave={onReorderMenusSave}
            onCreateMenuConfigItemGallery={onCreateMenuConfigItemGallery}
          />
        }
        additionalContentStyles={styles.content}
        closeVerticalSpacing
      >
        {menusAndGalleries.length > 0 ? (
          menusAndGalleries.map((menuSummary: IMenuSummaryUI) => (
            <MenuConfigPanelRow
              key={menuSummary.id}
              menuSummary={menuSummary}
              onClick={onRowClick}
              onDeleteMenuOrGallery={onDeleteMenuOrGallery}
              onUpdateMenuOrItemGallerySummary={onUpdateMenuOrItemGallerySummary}
            />
          ))
        ) : (
          <EmptyResult
            additionalStyles={styles.emptyResult}
            title='Create Your First Menu & Item Gallery'
            paragraph='Menus such as “Food” or “Drinks”, can contain multiple Sections such as “Appies” or “Entrees”. These menus and sections make up the bulk of your menu layout. Item Galleries allow you to highlight up to 5 featured items in a carousel format to promote to your guests.'
          />
        )}
      </Panel>

      {showAssignModal && (
        <ManageAssignedLocationsModal
          headerLabel='Edit Assigned Locations'
          assignedToLabel='Configuration assigned to'
          showQuickAssignControls={false}
          setShowModal={setShowAssignModal}
          currentAssignedCount={assignedLocationsCount}
          totalLocationsCount={totalAssignableLocations}
          loadLocationAssignments={loadLocationAssignments}
          saveAssignedLocations={onSaveAssignedLocations}
          headerMessage={
            <Notification alignment='horizontal' type='info'>
              <p>
                <TextIcon icon={Icon.InfoCircle} additionalStyles={styles.warningMessageIconSpacing} /> Caution:
                Removing a configuration from a location where it is set to active will result in no menus being
                displayed until a new active configuration is set.
              </p>
            </Notification>
          }
        />
      )}
    </>
  );
};
