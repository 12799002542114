import { IOnAccountPaymentSettings } from '@ready/dashboardv2api.contracts';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getOnAccountPaymentSettings,
  updateOnAccountPaymentSettings,
} from 'companyLocations/services/onAccountPaymentSettingsService';
import { set200Toast, toastErrorState } from 'redux/actions/uiActions/responseStateActions';

interface IGetOnAccountPaymentSettingsArgs {
  companyId: string;
  locationId: string;
}

export const getOnAccountPaymentSettingsThunk = createAsyncThunk<
  IOnAccountPaymentSettings,
  IGetOnAccountPaymentSettingsArgs
>(
  'paymentProcessing/onAccountPaymentSettings/fetch',
  async ({ companyId, locationId }: IGetOnAccountPaymentSettingsArgs) => {
    return getOnAccountPaymentSettings(companyId, locationId);
  }
);

interface IUpdateOnAccountPaymentSettingsArgs {
  companyId: string;
  locationId: string;
  settings: IOnAccountPaymentSettings;
}

export const updateOnAccountPaymentSettingsThunk = createAsyncThunk<
  IOnAccountPaymentSettings | undefined,
  IUpdateOnAccountPaymentSettingsArgs
>(
  'paymentProcessing/onAccountPaymentSettings/update',
  async ({ companyId, locationId, settings }: IUpdateOnAccountPaymentSettingsArgs, thunkAPI) => {
    try {
      const result = await updateOnAccountPaymentSettings(companyId, locationId, settings);
      thunkAPI.dispatch(set200Toast('Success! Changes saved.'));

      return result;
    } catch (e) {
      thunkAPI.dispatch(toastErrorState(e.status, e.message));
    }
  }
);
