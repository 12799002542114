import { ICompanyLoyaltyConfig } from '@ready/dashboardv2api.contracts';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCompanyLoyaltyConfig } from '../../service';
import { toastErrorState } from 'redux/actions/uiActions/responseStateActions';

interface Args {
  companyId: string;
}

export const getCompanyLoyaltyConfigThunk = createAsyncThunk<ICompanyLoyaltyConfig, Args>(
  'companies/loyalty/configs/get',
  ({ companyId }: Args, { dispatch }) => {
    try {
      return getCompanyLoyaltyConfig(companyId);
    } catch (e) {
      dispatch(toastErrorState(e.status, e.message));
      throw e;
    }
  }
);
