import { OrderSmsMessageSource } from '@ready/dashboardv2api.contracts';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import React, { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import TextArea from '../../../../components/TextArea/TextArea';

import {
  updateCompanyOrderPlacedMessageStatus,
  updateLocationOrderPlacedMessageStatus,
  updateLocationOrderPlacedMessage,
  updateCompanyOrderPlacedMessage,
  updateCompanyOrderCancelledMessageStatus,
  updateLocationOrderCancelledMessageStatus,
  updateCompanyOrderCancelledMessage,
  updateLocationOrderCancelledMessage,
  updateCompanyOrderInProgressMessageStatus,
  updateLocationOrderInProgressMessageStatus,
  updateCompanyOrderInProgressMessage,
  updateLocationOrderInProgressMessage,
  updateCompanyOrderIsReadyMessageStatus,
  updateLocationOrderIsReadyMessageStatus,
  updateCompanyOrderIsReadyMessage,
  updateLocationOrderIsReadyMessage,
  updateCompanyOrderPickUpTimeUpdatedMessageStatus,
  updateLocationOrderPickUpTimeUpdatedMessageStatus,
  updateCompanyOrderPickUpTimeUpdatedMessage,
  updateLocationOrderPickUpTimeUpdatedMessage,
} from '../../../redux/reducers/orderSMSMessages/orderSMSMessagesSlice';
import { orderSmsMessagesText } from '../viewOrderSMSMessages/ViewOrderSMSMessages';
import { orderSmsMessagesSelector } from '../../../redux/selectors/orderSmsMessagesSelector';

export interface OrderSmsMessagesFormControlProps {
  label: string;
  checked: boolean;
  messageValue: string;
  updateTextArea: (value: string) => void;
  updateCheckbox: (value: boolean) => void;
  placeholder: string;
  disabled: boolean;
  maxLength: number;
  withError?: boolean;
  errorMessage?: string;
  loading?: boolean;
  boldLabel?: string;
}

export const OrderSmsMessagesFormControl = (props: OrderSmsMessagesFormControlProps): JSX.Element => {
  const {
    label,
    checked,
    messageValue,
    updateTextArea,
    updateCheckbox,
    disabled,
    placeholder,
    maxLength,
    withError,
    errorMessage,
    loading = false,
    boldLabel = '',
  } = props;

  return (
    <>
      <Checkbox
        label={label}
        boldLabel={boldLabel}
        checked={checked}
        onChange={(event: ChangeEvent<HTMLInputElement>): void => {
          updateCheckbox(event.target.checked);
        }}
        disabled={disabled}
        loading={loading}
      />

      <TextArea
        onChange={(e: ChangeEvent<HTMLTextAreaElement>): void => {
          updateTextArea(e.target.value.toString());
        }}
        value={messageValue}
        disabled={disabled}
        placeholder={placeholder}
        maxLength={maxLength}
        withError={withError}
        errorMessage={errorMessage}
        loading={loading}
      />
    </>
  );
};

export const OrderSmsMessagesFormControls = () => {
  const { orderSmsMessages, formProcessing, isModalOpen, validation } = useSelector(orderSmsMessagesSelector);
  const { locationConfig, companyConfig, messageSource } = orderSmsMessages;
  const { orderCancelled, orderInProgress, orderPlaced, orderReady, pickUpTimeUpdated } = validation;
  const { orderPickupTime, orderIsInProgress, orderIsPlaced, orderIsCancelled, orderIsReady } = orderSmsMessagesText;

  const dispatch = useDispatch();
  const currentConfig = messageSource === OrderSmsMessageSource.Location ? locationConfig : companyConfig;

  const disabled = messageSource === OrderSmsMessageSource.Company && !isModalOpen;

  const isLocation = messageSource === OrderSmsMessageSource.Location;
  const maxLength = 160;

  const updateOrderSmsFormMessageText = (
    locationAction: ActionCreatorWithPayload<string>,
    companyAction: ActionCreatorWithPayload<string>,
    payload: string
  ) => {
    return isLocation ? dispatch(locationAction(payload)) : dispatch(companyAction(payload));
  };
  const updateOrderSmsFormCheckbox = (
    locationAction: ActionCreatorWithPayload<boolean>,
    companyAction: ActionCreatorWithPayload<boolean>,
    payload: boolean
  ) => {
    return isLocation ? dispatch(locationAction(payload)) : dispatch(companyAction(payload));
  };
  const updateOrderPlacedMessageStatus = (checked: boolean) => {
    updateOrderSmsFormCheckbox(updateLocationOrderPlacedMessageStatus, updateCompanyOrderPlacedMessageStatus, checked);
  };

  const updateOrderPlacedMessage = (message: string) => {
    updateOrderSmsFormMessageText(updateLocationOrderPlacedMessage, updateCompanyOrderPlacedMessage, message);
  };

  const updateOrderInProgressMessageStatus = (checked: boolean) => {
    updateOrderSmsFormCheckbox(
      updateLocationOrderInProgressMessageStatus,
      updateCompanyOrderInProgressMessageStatus,
      checked
    );
  };

  const updateOrderInProgressMessage = (message: string) => {
    updateOrderSmsFormMessageText(updateLocationOrderInProgressMessage, updateCompanyOrderInProgressMessage, message);
  };
  const updateOrderIsReadyMessageStatus = (checked: boolean) => {
    updateOrderSmsFormCheckbox(
      updateLocationOrderIsReadyMessageStatus,
      updateCompanyOrderIsReadyMessageStatus,
      checked
    );
  };

  const updateOrderIsReadyMessage = (message: string) => {
    updateOrderSmsFormMessageText(updateLocationOrderIsReadyMessage, updateCompanyOrderIsReadyMessage, message);
  };
  const updateOrderCancelledMessageStatus = (checked: boolean) => {
    updateOrderSmsFormCheckbox(
      updateLocationOrderCancelledMessageStatus,
      updateCompanyOrderCancelledMessageStatus,
      checked
    );
  };
  const updateOrderCancelledMessage = (message: string) => {
    updateOrderSmsFormMessageText(updateLocationOrderCancelledMessage, updateCompanyOrderCancelledMessage, message);
  };
  const updateOrderPickUpTimeUpdatedMessageStatus = (checked: boolean) => {
    updateOrderSmsFormCheckbox(
      updateLocationOrderPickUpTimeUpdatedMessageStatus,
      updateCompanyOrderPickUpTimeUpdatedMessageStatus,
      checked
    );
  };
  const updateOrderPickUpTimeUpdatedMessage = (message: string) => {
    updateOrderSmsFormMessageText(
      updateLocationOrderPickUpTimeUpdatedMessage,
      updateCompanyOrderPickUpTimeUpdatedMessage,
      message
    );
  };

  return (
    <>
      <OrderSmsMessagesFormControl
        label={orderIsPlaced.normalText}
        boldLabel={orderIsPlaced.boldText}
        checked={currentConfig.orderPlaced?.isActive ?? false}
        messageValue={currentConfig.orderPlaced?.value ?? ''}
        disabled={disabled}
        placeholder='We have received your order'
        maxLength={maxLength}
        updateCheckbox={updateOrderPlacedMessageStatus}
        updateTextArea={updateOrderPlacedMessage}
        withError={orderPlaced.hasError}
        errorMessage={orderPlaced.errorMessage}
        loading={formProcessing}
      />

      <OrderSmsMessagesFormControl
        label={orderIsInProgress.normalText}
        boldLabel={orderIsInProgress.boldText}
        checked={currentConfig.orderInProgress?.isActive ?? false}
        messageValue={currentConfig.orderInProgress?.value ?? ''}
        disabled={disabled}
        placeholder={'Your order is in progress.'}
        maxLength={maxLength}
        updateCheckbox={updateOrderInProgressMessageStatus}
        updateTextArea={updateOrderInProgressMessage}
        withError={orderInProgress.hasError}
        errorMessage={orderInProgress.errorMessage}
        loading={formProcessing}
      />

      <OrderSmsMessagesFormControl
        label={orderIsReady.normalText}
        boldLabel={orderIsReady.boldText}
        checked={currentConfig.orderReady?.isActive ?? false}
        messageValue={currentConfig.orderReady?.value ?? ''}
        disabled={disabled}
        placeholder={'Your order is ready for pick up.'}
        maxLength={maxLength}
        updateCheckbox={updateOrderIsReadyMessageStatus}
        updateTextArea={updateOrderIsReadyMessage}
        withError={orderReady.hasError}
        errorMessage={orderReady.errorMessage}
        loading={formProcessing}
      />

      <OrderSmsMessagesFormControl
        label={orderIsCancelled.normalText}
        boldLabel={orderIsCancelled.boldText}
        checked={currentConfig.orderCancelled?.isActive ?? false}
        messageValue={currentConfig.orderCancelled?.value ?? ''}
        disabled={disabled}
        placeholder={'Your order has been cancelled.'}
        maxLength={maxLength}
        updateCheckbox={updateOrderCancelledMessageStatus}
        updateTextArea={updateOrderCancelledMessage}
        withError={orderCancelled.hasError}
        errorMessage={orderCancelled.errorMessage}
        loading={formProcessing}
      />

      <OrderSmsMessagesFormControl
        label={orderPickupTime.normalText}
        boldLabel={orderPickupTime.boldText}
        checked={currentConfig.pickUpTimeUpdated?.isActive ?? false}
        messageValue={currentConfig.pickUpTimeUpdated?.value ?? ''}
        updateCheckbox={updateOrderPickUpTimeUpdatedMessageStatus}
        updateTextArea={updateOrderPickUpTimeUpdatedMessage}
        disabled={disabled}
        placeholder={'Your pick up time has been updated.'}
        maxLength={maxLength}
        withError={pickUpTimeUpdated.hasError}
        errorMessage={pickUpTimeUpdated.errorMessage}
        loading={formProcessing}
      />
    </>
  );
};
