import { CellProps, getCellClasses } from 'components/TableV2/Cell/Cell';
import React from 'react';
import styles from './cells.module.scss';
import { PaymentStatus } from '@ready/dashboardv2api.contracts';


export const PaymentStatusCell = ({ rowData, columnName, classes }: CellProps) => {
  const paymentStatus = rowData[columnName] as PaymentStatus;
  return <div className={getCellClasses(classes, styles.capitalize)}>{paymentStatus}</div>;
};
