import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form, FormControl, FormActionButtons } from '../../../components/Form';
import Toggle from '../../../components/Toggle/Toggle';
import TextInput from '../../../components/TextInput/TextInput';
import SelectFilter, { Option } from '../../../components/SelectFilter/SelectFilter';
import PaymentOptionShortCodePreview from '../../components/OnAccountPaymentOptions/PaymentOptionShortCodePreview';
import PaymentValidationField from '../../components/OnAccountPaymentOptions/PaymentValidationField';
import { selectOnAccountPaymentOptions } from '../../redux/selectors/onAccountPaymentOptionsSelector';
import { OnAccountPaymentOptionsActions } from '../../redux/actions/onAccountPaymentOptionsActions';
import styles from './OnAccountPaymentOptionsEditForm.module.scss';
import { ContextParams } from '../../../types/ContextParams.interface';
import LinkButton from '../../../components/LinkButton/LinkButton';

export interface IOnAccountPaymentOptionsEditFormProps {
  handleCancel: () => void;
  saveButtonLabel?: string;
  formHasGroups?: boolean;
  isEditOptionForm?: boolean;
}

export const OnAccountPaymentOptionsEditForm = (props: IOnAccountPaymentOptionsEditFormProps) => {
  const { handleCancel, saveButtonLabel = '', formHasGroups = false, isEditOptionForm = false } = props;
  const { contextId: companyId, locationId } = useParams<ContextParams>();
  const dispatch = useDispatch();
  const { form } = useSelector(selectOnAccountPaymentOptions);

  const posTenderTypes = form.tenderTypes.map((tenderType) => ({ value: tenderType.id, label: tenderType.name }));
  const posTenderType = posTenderTypes?.find((tenderType) => tenderType.value === `${form.fields.posTenderType.value}`);

  React.useEffect(() => {
    dispatch(OnAccountPaymentOptionsActions.fetchTenderTypes(companyId, locationId));
    return () => {
      dispatch(OnAccountPaymentOptionsActions.resetForm());
    };
  }, [dispatch, companyId, locationId]);

  return (
    <Form hasGroups={formHasGroups} onSubmit={() => dispatch(OnAccountPaymentOptionsActions.validateForm(form))}>
      <FormControl label='Status'>
        <Toggle
          checked={form.fields.status.value}
          onChange={(checked) => dispatch(OnAccountPaymentOptionsActions.updatePaymentOptionStatus(checked))}
          loading={form.isProcessing}
        />
      </FormControl>
      <FormControl
        label='Payment Option Name *'
        withError={form.fields.paymentOptionName.hasError}
        errorMessage='Field is required.'
      >
        <TextInput
          placeholder='e.g. Room Charge'
          onChange={(event) => dispatch(OnAccountPaymentOptionsActions.updatePaymentOptionName(event.target.value))}
          value={form.fields.paymentOptionName.value}
          loading={form.isLoading}
          disabledLoading={form.isProcessing}
          maxLength={20}
          withError={form.fields.paymentOptionName.hasError}
        />
      </FormControl>
      <FormControl
        label='Description'
        labelTooltip='This description will be visible to guests during checkout when they are shown a list of payment options.'
      >
        <TextInput
          placeholder='e.g. Charge your order to your room and settle your tab later.'
          onChange={(event) =>
            dispatch(OnAccountPaymentOptionsActions.updatePaymentOptionDescription(event.target.value))
          }
          value={form.fields.description.value}
          loading={form.isLoading}
          disabledLoading={form.isProcessing}
          maxLength={100}
        />
      </FormControl>
      <FormControl
        label='Short Code *'
        labelTooltip='This will be displayed as an icon in your payment reports.'
        errorMessage='Field is required.'
        withError={form.fields.shortCode.hasError}
      >
        <div className={styles.shortCodeContainer}>
          <TextInput
            placeholder='e.g. RC'
            onChange={(event) =>
              dispatch(OnAccountPaymentOptionsActions.updatePaymentOptionShortCode(event.target.value))
            }
            value={form.fields.shortCode.value}
            loading={form.isLoading}
            disabledLoading={form.isProcessing}
            maxLength={2}
            withError={form.fields.shortCode.hasError}
          />
          <PaymentOptionShortCodePreview textPreview={form.fields.shortCode.value} disabled={form.isProcessing} />
        </div>
      </FormControl>
      <FormControl
        label='POS Tender Type *'
        withError={form.fields.paymentOptionName.hasError}
        errorMessage='Field is required.'
      >
        <SelectFilter
          options={posTenderTypes ?? []}
          loading={form.isProcessing || (isEditOptionForm && form.isLoadingTenderTypes)}
          populating={form.isLoadingTenderTypes}
          onChange={(option: Option) =>
            dispatch(OnAccountPaymentOptionsActions.updatePaymentOptionTenderType(option.value.toString()))
          }
          placeholder='Select a POS Tender Type'
          withError={form.fields.posTenderType.hasError}
          value={posTenderType}
        />
      </FormControl>
      <FormControl
        label='Payment Validation Fields'
        labelTooltip='Guests will only be asked to fill out these fields if they choose this payment option during checkout.'
      >
        <div className={styles.validationFields}>
          {form.fields.paymentValidationFields.map((paymentValidationField) => (
            <PaymentValidationField
              key={paymentValidationField.internalId}
              internalId={paymentValidationField.internalId}
              isEditOptionForm={isEditOptionForm}
            />
          ))}
        </div>
        <LinkButton
          disabled={form.isProcessing}
          onClick={() => dispatch(OnAccountPaymentOptionsActions.addPaymentOptionValidationField())}
        >
          + Add Field
        </LinkButton>
      </FormControl>

      <FormActionButtons loading={form.isProcessing} handleCancel={handleCancel} saveButtonLabel={saveButtonLabel} />
    </Form>
  );
};
