import React from 'react';
import styles from './TableAssignmentPanel.module.scss';
import { TableAssignmentPanelHeader, TableAssignmentPanelRow } from './index';
import { ITableAssignment } from '@ready/table.core';
import { AppState } from '../../redux/initialStates/AppState';
import { connect, ConnectedProps } from 'react-redux';
import {
  openAssignModal,
  removeTableFromAssignment,
  setRemoveTableAssignmentModalOpen,
} from '../redux/ServerAssignmentActions';
import { useParams } from 'react-router-dom';
import { ContextParams } from '../../types/ContextParams.interface';

interface TableAssignmentPanelProps extends ReduxProps {
  tableAssignment: ITableAssignment;
  disabled: boolean;
  hasAllPermission?: boolean;
}

const TableAssignmentPanel = ({
  tableAssignment,
  openAssignModal,
  removeTableFromAssignment,
  setRemoveTableAssignmentModalOpen,
  disabled,
  hasAllPermission,
}: TableAssignmentPanelProps): JSX.Element => {
  const { contextId, locationId } = useParams<ContextParams>();
  const [showContent, setShowContent] = React.useState(true);

  const handleEditClick = () => {
    openAssignModal('edit', tableAssignment.employee.id);
  };

  const handleRemoveTableClick = (tableId: string) => {
    removeTableFromAssignment(contextId, locationId, tableAssignment, tableId);
  };

  const handleRemoveClick = () => {
    setRemoveTableAssignmentModalOpen(true, tableAssignment.employee.id);
  };

  return (
    <div className={styles.container}>
      <TableAssignmentPanelHeader
        employee={tableAssignment.employee}
        isExpanded={showContent}
        tableCount={tableAssignment.tables.length}
        onEditClick={handleEditClick}
        onRemoveClick={handleRemoveClick}
        onExpandToggleClick={() => setShowContent(!showContent)}
        disabled={disabled}
        hasAllPermission={hasAllPermission}
      />
      {showContent
        ? tableAssignment.tables.map((table) => (
            <TableAssignmentPanelRow
              key={table.id}
              table={table}
              onRemoveClick={() => handleRemoveTableClick(table.id)}
              disabled={disabled}
              hasAllPermission={hasAllPermission}
            />
          ))
        : null}
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    isAssignModalOpen: state.tableServerAssignment.serverAssignments.isAssignModalOpen,
  };
};

const actionCreators = {
  openAssignModal,
  removeTableFromAssignment,
  setRemoveTableAssignmentModalOpen,
};

const connector = connect(mapStateToProps, actionCreators);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(TableAssignmentPanel);
