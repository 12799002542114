import React from 'react';
import _ from 'lodash';
import TextIcon, { Icon } from '../../../../components/Icon/TextIcon';
import { Stars } from '../Stars/Stars';
import { Widget } from '../Widget/Widget';
import classnames from 'classnames';
import styles from './StarRating.module.scss';
import { IFeedbackReportStarRatingData } from '../../redux';

interface RatingItemProps {
  containerClasses?: string;
  fillClasses?: string;
  percentage: number;
  count: number;
  stars: string;
}

interface RatingProps {
  data: IFeedbackReportStarRatingData;
  loading?: boolean;
}

function RatingItem({ containerClasses, fillClasses, percentage, count, stars }: RatingItemProps) {
  return (
    <div className={classnames(styles.ratingItem, containerClasses)}>
      <div className={styles.ratingItem__value}>
        <span className={styles.ratingItem__star}>{stars}</span>
        <TextIcon additionalStyles={styles.ratingItem__starIcon} icon={Icon.StarCircle} />
      </div>
      <div className={styles.ratingItem__rating}>
        <div className={styles.ratingItem__ratingWrapper}>
          <div
            className={classnames(styles.ratingItem__ratingFill, fillClasses)}
            style={{ width: `${Math.ceil(percentage)}%` }}
          />
        </div>
      </div>
      <div className={styles.ratingItem__starsCount}>({count})</div>
    </div>
  );
}

export function StarRating({ data, loading }: RatingProps) {
  const total = _.sum(Object.values(data.counts));
  const average = data.average || 0;
  const isAverageNought = average === 0;
  const starsCount = isAverageNought ? 5 : Math.floor(average) + (average % 1 > 0.5 ? 1 : 0);
  const midRateVal = isAverageNought ? '0' : average.toFixed(average % 1 > 0 ? 1 : 0);

  return (
    <Widget loading={loading} title={`Star Ratings (${total})`} classes={styles.starsRating}>
      <div className={styles.starsRating__midRating}>
        <div className={styles.starsRating__midRatingVal}>{midRateVal}</div>
        <Stars
          starsCount={starsCount}
          starClasses={classnames(styles.starsRating__star, {
            [styles['starsRating__star--disabled']]: isAverageNought,
          })}
        />
      </div>
      <div className={styles.starsRating__counts}>
        {Object.entries(data.counts)
          .reverse()
          .map(([key, value]) => (
            <RatingItem
              key={key}
              fillClasses={styles[`ratingItem__ratingFill--color--${key}`]}
              percentage={isAverageNought ? 0 : value / (total / 100)}
              stars={key}
              count={value}
            />
          ))}
      </div>
    </Widget>
  );
}
