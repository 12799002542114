import { addMonths, format, startOfMonth } from 'date-fns';
import { DatePickerValue } from '../DatePicker/DatePicker';
import React from 'react';

export class DateTimeInputUtil {
  static basicInputRegex = [
    '[0-9]',
    '[0-9]',
    '\\/',
    '[0-9]',
    '[0-9]',
    '\\/',
    '[0-9]',
    '[0-9]',
    '[0-9]',
    '[0-9]',
    ' ',
    '[0-9]',
    '[0-9]',
    '\\:',
    '[0-9]',
    '[0-9]',
    '\\:',
    '[0-9]',
    '[0-9]',
    ' ',
    '[AaPp]',
    '[Mm]',
    ' ',
    '\\-',
    ' ',
    '[0-9]',
    '[0-9]',
    '\\/',
    '[0-9]',
    '[0-9]',
    '\\/',
    '[0-9]',
    '[0-9]',
    '[0-9]',
    '[0-9]',
    ' ',
    '[0-9]',
    '[0-9]',
    '\\:',
    '[0-9]',
    '[0-9]',
    '\\:',
    '[0-9]',
    '[0-9]',
    ' ',
    '[AaPp]',
    '[Mm]',
  ];

  public static initialDateTimeInputValue: DatePickerValue = {
    startMonth: startOfMonth(new Date()),
    endMonth: addMonths(startOfMonth(new Date()), 1),
  };

  public static dateTimeWithSecondsDisplayFormat = 'dd/MM/yyyy hh:mm:ss a';
  public static dateTimeDisplayFormat = 'dd/MM/yyyy hh:mm a';
  public static dateTimeHoursDisplayFormat = 'dd/MM/yyyy hh a';
  public static dateDisplayFormat = 'dd/MM/yyyy';

  public static formatSelectionForInput = (
    mode: 'single' | 'range',
    startDate: Date,
    endDate: Date,
    displayFormat: string
  ): string => {
    return mode === 'single'
      ? format(startDate, displayFormat)
      : `${format(startDate, displayFormat)} - ${format(endDate, displayFormat)}`;
  };

  public static checkBasicMatch = (value: string) => {
    let valueRegex = '';

    for (let i = 0; i < value.length; i++) {
      valueRegex += DateTimeInputUtil.basicInputRegex[i];
    }
    return !!value.match(valueRegex);
  };

  public static getId = () => Math.floor(Math.random() * 1000000);

  public static handleOutsideClick = (
    event: MouseEvent,
    containerRef: React.RefObject<HTMLDivElement>,
    onOutsideClick: () => void
  ) => {
    const eventNode: Node | null = event.target instanceof Node ? event.target : null;

    if (!containerRef.current?.contains(eventNode)) {
      onOutsideClick();
    }
  };
}
