import React from 'react';
import styles from './StatusIndicator.module.scss';

export interface StatusIndicatorProps {
  active: boolean;
  activeLabel?: string;
  inactiveLabel?: string;
  additionalStyles?: string;
}

const StatusIndicator = ({
  active = false,
  activeLabel = '',
  inactiveLabel = '',
  additionalStyles = '',
}: StatusIndicatorProps): JSX.Element => {
  return (
    <div className={styles.statusContainer + ' ' + additionalStyles}>
      <span className={active ? styles.activeIndicator : styles.inactiveIndicator}></span>
      {active ? (
        <span className={styles.activeLabel}>{activeLabel}</span>
      ) : (
        <span className={styles.inactiveLabel}>{inactiveLabel}</span>
      )}
    </div>
  );
};

export default StatusIndicator;
