import React, { FC } from 'react';
import styles from './SimplePanelLayout.module.scss';

/**
 * Simple Panel Layout
 * A simple container that stacks children with a tasteful gap.
 */
export const SimplePanelLayout: FC<{ customStyle?: string }> = ({ children, customStyle }) => {
  return <div className={customStyle ?? styles.container}>{children}</div>;
};
