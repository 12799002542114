import React, { ChangeEvent } from 'react';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';
import Checkbox from '../../../components/Checkbox/Checkbox';
import styles from './AllowMultiplesModifierOptions.module.scss';

export interface AllowMultiplesModifierOptionsProps {
  allowMultiplesOfEachOption: boolean;
  editing: boolean;
  processing: boolean;
  className?: string;
  onMultiplesModifierOptionsChanged: (checked: boolean) => void;
}

const AllowMultiplesModifierOptions = ({
  allowMultiplesOfEachOption,
  editing = false,
  processing,
  className,
  onMultiplesModifierOptionsChanged,
}: AllowMultiplesModifierOptionsProps): JSX.Element => {
  return (
    <span className={className}>
      {editing ? (
        <Checkbox
          label='Allow guests to select multiples of each modifier option'
          checked={allowMultiplesOfEachOption}
          loading={processing}
          onChange={({ target: { checked } }: ChangeEvent<HTMLInputElement>) =>
            onMultiplesModifierOptionsChanged(checked)
          }
        />
      ) : allowMultiplesOfEachOption ? (
        <span>
          <TextIcon icon={Icon.Checkmark} additionalStyles={styles.icon} />
          <span>Allow guests to select multiples of each modifier option</span>
        </span>
      ) : undefined}
    </span>
  );
};

export default AllowMultiplesModifierOptions;
