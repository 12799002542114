import produce from 'immer';
import { Action } from '../../redux/types';
import { LOCATION_SETTINGS_ACTIONS } from './LocationSettingsActions';
import { detailsPanel } from './PanelDetailsState';

export const detailsPanelReducer = (state = detailsPanel, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case LOCATION_SETTINGS_ACTIONS.SET_DETAILS_PANEL:
        draft = { ...action.payload };
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_LOCATION_NAME:
        draft.details.name = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_LOCATION_PUBLIC_NAME:
        draft.details.publicName = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_LOCATION_PHONE_NUMBER:
        draft.details.phoneNumber = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_LOCATION_COUNTRY:
        draft.details.countryCode = action.payload.value;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_LOCATION_STREET:
        draft.details.streetAddress = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_LOCATION_CITY:
        draft.details.city = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_LOCATION_STATE:
        draft.details.stateCode = action.payload.value;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_LOCATION_ZIP:
        draft.details.zip = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_LOCATION_TIMEZONE:
        draft.details.timezone = action.payload.value;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_LOCATION_CURRENCY:
        draft.details.currency = action.payload.value;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_LOCATION_LOCALE:
        draft.details.locale = action.payload.value;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.SET_NAME_ERROR:
        draft.validation = { ...action.payload };
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_LOCATION_ENABLED:
        draft.details.enabled = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_LOCATION_HIDDEN:
        draft.details.hidden = action.payload;
        return draft;
      default:
        return draft;
    }
  });
};
