import produce from 'immer';
import { Action } from '../../redux/types';
import { LOCATION_SETTINGS_ACTIONS } from './LocationSettingsActions';
import { brandingPanel } from './PanelBrandingState';

export const brandingPanelReducer = (state = brandingPanel, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case LOCATION_SETTINGS_ACTIONS.SET_BRANDING_PANEL:
        draft = { ...action.payload };
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_BRANDING_PANEL_PRIMARY_COLOUR:
        draft.primaryColour = action.payload;
        draft.isDirty = true;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_BRANDING_PANEL_SECONDARY_COLOUR:
        draft.secondaryColour = action.payload;
        draft.isDirty = true;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_BRANDING_PANEL_HEADER_FONT:
        draft.headerFont = action.payload;
        draft.isDirty = true;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_BRANDING_PANEL_BODY_FONT:
        draft.bodyFont = action.payload;
        draft.isDirty = true;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_BRANDING_PANEL_HEADER_IMAGE_KEY:
        draft.imageKey = action.payload.fileKey;
        draft.isDirty = true;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_BRANDING_PANEL_MENU_BACKGROUND_COLOUR:
        draft.menuBackgroundColour = action.payload;
        draft.isDirty = true;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_BRANDING_PANEL_MENU_TEXT_COLOUR:
        draft.menuTextColour = action.payload;
        draft.isDirty = true;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_BRANDING_PANEL_ACCENT_BACKGROUND_COLOUR:
        draft.accentBackgroundColour = action.payload;
        draft.isDirty = true;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_BRANDING_PANEL_ACCENT_TEXT_COLOUR:
        draft.accentTextColour = action.payload;
        draft.isDirty = true;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_BRANDING_PANEL_IMAGE_STYLING:
        draft.imageStyling = action.payload;
        draft.isDirty = true;
        return draft;
      default:
        return draft;
    }
  });
};
