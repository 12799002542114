import { ScheduleEffect } from '@ready/menu.core';

export interface Option {
  value: string;
  label: string;
}

export const statusEffects = [
  {
    value: ScheduleEffect.show,
    label: 'Show during',
  },
  {
    value: ScheduleEffect.hide,
    label: 'Hide during',
  },
];
