import React from 'react';
import { useLocation } from 'react-router-dom';
import SquareRedirectCallback, { SquareRedirectState } from '../components/SquareRedirectCallback';
import { SquareIntegration } from '../services/SquareIntegration';

const SquareRedirectPage = () => {
  const [state, setState] = React.useState<SquareRedirectState>(SquareRedirectState.Loading);
  const locationData = useLocation();

  React.useEffect(() => {
    async function connectCompanyToSquare() {
      const search = locationData?.search;
      const searchParams = new URLSearchParams(search);
      const companyId = searchParams.get('state');
      const code = searchParams.get('code');
      if (companyId && code) {
        const response = await SquareIntegration.authorize(companyId, code);
        if (response) {
          setState(SquareRedirectState.Success);
        } else {
          setState(SquareRedirectState.Error);
        }
      }
    }

    connectCompanyToSquare();
  }, [locationData]);

  return <SquareRedirectCallback state={state} />;
};

export default SquareRedirectPage;
