import React from 'react';
import classNames from 'classnames';
import { ITransactionDetailCheck, ITransactionDetailCheckPayment } from '@ready/dashboardv2api.contracts';
import { IPaymentPOSDetail } from '../../../redux/initialStates/transactions/transactionDetail';
import { Panel } from '../../../components/PanelLayout';
import styles from './TransactionCheck.module.scss';
import { DollarValue } from '../../../components/Value';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';
import spinner from '../../../styles/assets/ready-progress-spinner-white.svg';
import { isValidLast4CardNumber } from 'pages/Transactions/utils/isValidLast4CardNumber';

export interface TransactionCheckProps extends ITransactionDetailCheck {
  key: string;
  setShowPaymentDialog: (paymentPOSDetail: IPaymentPOSDetail) => void;
  creditCardBrand: string | undefined;
  transactionId: string;
  manuallyRetryingPayment: boolean;
  contextId: string;
  locationId: string;
  manualRetryPayment: (
    contextId: string,
    locationId: string,
    transactionId: string,
    ticketId: string,
    total: number,
    tip: number
  ) => Promise<void>;
  onAccountPaymentOptionName?: string;
}

const TransactionCheck = (props: TransactionCheckProps) => {
  const {
    key,
    checkNumber,
    checkStatus,
    checkItems = [],
    checkPayments = [],
    setShowPaymentDialog,
    transactionId,
    manuallyRetryingPayment,
    contextId,
    locationId,
    manualRetryPayment,
    onAccountPaymentOptionName,
  } = props;

  return (
    <Panel column={1} key={`check${key}`} additionalStyles={styles.transactionChecksPanel}>
      <p className={styles.checkId}>
        Check: <span className={styles.checkNumber}>#{checkNumber}</span>
      </p>
      <>
        {checkStatus === 'notEntered' && (
          <div className={styles.notEnteredAlert}>
            <TextIcon icon={Icon.Alert} />
            <span>
              Ready has collected payment for this check, but the details have not been sent to your POS. Retry
              submitting the information below, or manually enter the payment amounts into your POS.
            </span>
          </div>
        )}
      </>
      <>
        {checkItems.map((itemInfo: CheckItemProps, index: number) => (
          <CheckItem key={index} {...itemInfo} />
        ))}
      </>
      <div className={styles.checkPaymentSummary}>
        {checkPayments.map((paymentInfo: ITransactionDetailCheckPayment, index: number) => (
          <CheckPayment
            key={index}
            setShowPaymentDialog={setShowPaymentDialog}
            transactionId={transactionId}
            manuallyRetryingPayment={manuallyRetryingPayment}
            contextId={contextId}
            locationId={locationId}
            manualRetryPayment={manualRetryPayment}
            {...paymentInfo}
            onAccountPaymentOptionName={onAccountPaymentOptionName}
          />
        ))}
        {!checkPayments.length && <div>No Payments</div>}
      </div>
    </Panel>
  );
};

export interface CheckItemProps {
  name: string;
  quantity: number;
  priceBeforeDiscount: number;
  priceAfterDiscount: number;
  comment?: string;
  modifiers?: any[];
}

const CheckItem = (props: CheckItemProps) => {
  const { name, quantity, priceBeforeDiscount, priceAfterDiscount, comment, modifiers = [] } = props;

  return (
    <div className={styles.checkItem}>
      <div className={styles.checkInfo}>
        <div className={styles.checkItemNameWrapper}>
          <p>{name}</p>
          {comment && <CheckItemComment comment={comment} />}
        </div>
        <div className={styles.checkItemQuantityWrapper}>
          {quantity > 1 && <p className={styles.checkItemQuantity}>{quantity}</p>}
        </div>
        <div className={styles.checkItemTotalsWrapper}>
          <DollarValue value={priceAfterDiscount} variant='primary' additionalStyles={styles.checkItemTotal} />
          {priceBeforeDiscount > priceAfterDiscount && (
            <DollarValue
              value={priceBeforeDiscount}
              overridden
              variant='secondary'
              additionalStyles={styles.checkItemTotal}
            />
          )}
        </div>
      </div>
      {!!modifiers.length && (
        <div className={styles.checkItemModifiers}>
          {modifiers.map((modifier: CheckItemModifierProps, index: number) => (
            <CheckItemModifier name={modifier.name} value={modifier.value} key={index} />
          ))}
        </div>
      )}
    </div>
  );
};

export interface CheckItemCommentProps {
  comment: string;
}

const CheckItemComment = (props: CheckItemCommentProps) => {
  const { comment } = props;

  return (
    <p className={styles.checkItemComment}>
      <TextIcon icon={Icon.SpeechBubble} additionalStyles={styles.checkItemCommentIcon} />
      <span>{comment}</span>
    </p>
  );
};

export interface CheckItemModifierProps {
  name: string;
  value: number;
}

const CheckItemModifier = (props: CheckItemModifierProps) => {
  const { name, value } = props;

  return (
    <p className={styles.checkModifierDescription}>
      {name}
      <span className={styles.checkModifierValue}>
        (
        <DollarValue value={value} negativeFormat='symbol' showPositiveSymbol />)
      </span>
    </p>
  );
};

export interface CheckPaymentProps extends ITransactionDetailCheckPayment {
  setShowPaymentDialog: (paymentPOSDetail: IPaymentPOSDetail) => void;
  transactionId: string;
  manuallyRetryingPayment: boolean;
  contextId: string;
  locationId: string;
  manualRetryPayment: (
    transactionId: string,
    _id: string,
    contextId: string,
    locationId: string,
    total: number,
    tip: number
  ) => Promise<void>;
  onAccountPaymentOptionName?: string;
}

const CheckPayment = (props: CheckPaymentProps) => {
  const {
    status,
    giftCard,
    total,
    subtotal,
    tip,
    transactionId,
    _id,
    ticketId,
    setShowPaymentDialog,
    manuallyRetryingPayment = false,
    contextId,
    locationId,
    manualRetryPayment,
    cardBrand,
    last4,
    onAccountPaymentOptionName,
  } = props;

  const handleDialog = () =>
    setShowPaymentDialog({
      preTip: subtotal,
      tip,
      total,
      transactionId,
      _id,
    });

  const handleManualRetryPayment = () => {
    manualRetryPayment(contextId, locationId, transactionId, ticketId, subtotal, tip);
  };

  let paymentStatusClassname;
  let statusIcon = '';
  let statusIconClassname;
  switch (status) {
    case 'entered':
      paymentStatusClassname = styles.checkPaymentEntered;
      statusIconClassname = styles.checkPaymentIconEntered;
      statusIcon = Icon.CheckmarkCircle;
      break;
    case 'notEntered':
      paymentStatusClassname = styles.checkPaymentNotEntered;
      statusIconClassname = styles.checkPaymentIconNotEntered;
      statusIcon = Icon.Alert;
      break;
    case 'sending':
      paymentStatusClassname = styles.checkPaymentSending;
      statusIconClassname = styles.checkPaymentIconSending;
      statusIcon = Icon.Clock;
      break;
  }
  const creditCardBrand = cardBrand ? cardBrand.toUpperCase() : '';
  const creditCardLast4 = last4 && isValidLast4CardNumber(last4) ? `(****${last4})` : '';

  const type = giftCard
    ? 'Gift Card'
    : !!onAccountPaymentOptionName
    ? `${onAccountPaymentOptionName}`
    : `${creditCardBrand}${creditCardLast4}`;

  return (
    <div className={classNames(styles.checkPayment, paymentStatusClassname)}>
      <div className={styles.checkPaymentInfo}>
        <TextIcon icon={statusIcon} additionalStyles={classNames(styles.checkPaymentIcon, statusIconClassname)} />
        <strong className={styles.checkPaymentType}>{type}:</strong>
        <DollarValue value={total} />
        <span className={styles.checkPaymentDetail}>
          (<DollarValue value={subtotal} /> +{' '}
          <DollarValue value={tip} additionalStyles={styles.checkPaymentDetailTip} />)
        </span>
      </div>
      <div className={styles.checkPaymentExtra}>
        {status === 'sending' && <span className={styles.checkPaymentExtraText}>Sending to POS</span>}
        {status === 'notEntered' && (
          <>
            <CheckPaymentButton
              variant='orange'
              label='Manual Entry'
              onClick={handleDialog}
              processing={manuallyRetryingPayment}
            />
            <CheckPaymentButton
              variant='orangeSolid'
              label='Retry'
              onClick={handleManualRetryPayment}
              processing={manuallyRetryingPayment}
            />
          </>
        )}
      </div>
    </div>
  );
};

export interface CheckPaymentButtonProps {
  variant: 'orange' | 'orangeSolid';
  label: string;
  processing?: boolean;
  onClick: () => void;
}

const CheckPaymentButton = (props: CheckPaymentButtonProps) => {
  const { variant, label, processing = false, onClick } = props;

  const buttonClassname = variant === 'orange' ? styles.checkPaymentButtonOrange : styles.checkPaymentButtonOrangeSolid;

  const processingClassname = processing ? styles.checkPaymentButtonProcessing : '';

  return (
    <button
      className={classNames(styles.checkPaymentButton, buttonClassname, processingClassname)}
      disabled={processing}
      onClick={onClick}
    >
      <span>{label}</span>
      {processing && variant === 'orangeSolid' && (
        <img src={spinner} alt='loading' className={styles.checkPaymentButtonSpinner} />
      )}
    </button>
  );
};

export default TransactionCheck;
