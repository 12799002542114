import { IMenuItem } from '@ready/menu.core';
import { MenuResourceActions, PrincipalPermissions, ResourceType, SecurityScope, Verifier } from '@ready/security.core';
import classNames from 'classnames';
import React from 'react';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';

import { useHistory, useParams } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import DropDownButton from '../../../components/DropDownButton/DropDownButton';
import { DropDownMenuOptionProps } from '../../../components/DropDownMenu/types';
import EmptyResult from '../../../components/EmptyResult/EmptyResult';
import { Icon } from '../../../components/Icon/TextIcon';
import InfoCard from '../../../components/InfoCard/InfoCard';
import InfoCardsSkeleton from '../../../components/InfoCard/InfoCardColumnSkeleton';
import InfoCardList from '../../../components/InfoCard/InfoCardList';
import OverflowButton from '../../../components/OverflowButton/OverflowButton';
import SearchParamInput from '../../../components/SearchInput/SearchParamInput';
import SelectFilter, { Option } from '../../../components/SelectFilter/SelectFilter';
import ToggleFilter from '../../../components/ToggleFilter/ToggleFilter';
import useResponsiveBreakpoint from '../../../hooks/useResponsiveBreakpoint';
import useS3ImagesMap, { IImageS3Request } from '../../../hooks/useS3Images';
import { useSearchParams } from '../../../hooks/useSearchParams';
import ItemsAndGroupsStockStatus from '../../types/ItemsAndGroupsStockStatus.enum';
import { AppState } from '../../../redux/initialStates/AppState';
import { ContextParams } from '../../../types/ContextParams.interface';
import { LIST_OPTIONS } from '../../../utils/selectListUtils/listOptions';
import { Routes } from '../../MenuBuilderRouter';
import {
  loadMenuItems,
  quickUpdateMenuItemInStock,
  storeMenuItemsFilters,
  syncMenuItems,
} from '../../redux/ItemsAndModsActions';
import { LocationsThunks } from '../../redux/LocationsThunks';
import MenuBuilderTab from '../../types/MenuBuilderTab.enum';
import MenuItemsView from '../../types/MenuItemsView.enum';
import MenuBuilderLayoutContent from '../MenuBuilderLayoutContent';
import MenuItemCard from './MenuItemCard';
import styles from './MenuItemsPage.module.scss';
import { selectLocationsState } from '../../redux/LocationsSelectors';

const mapStateToProps = (state: AppState) => {
  return {
    permissions: state.session.permissions.permissionsList,
    loading: state.menuBuilder.itemsAndMods.loading,
    pagination: state.menuBuilder.itemsAndMods.pagination,
    items: state.menuBuilder.itemsAndMods.items,
    syncing: state.menuBuilder.itemsAndMods.syncing,
  };
};

const actionCreators = {
  loadMenuItems,
  syncMenuItems,
  storeMenuItemsFilters,
  quickUpdateMenuItemInStock,
};

const connector = connect(mapStateToProps, actionCreators);

type MenuItemsPageProps = ConnectedProps<typeof connector>;

const MenuItemsPage = (props: MenuItemsPageProps): JSX.Element | null => {
  const {
    permissions,
    loading,
    pagination,
    items,
    syncing,
    loadMenuItems,
    syncMenuItems,
    storeMenuItemsFilters,
    quickUpdateMenuItemInStock,
  } = props;
  const { location } = useSelector(selectLocationsState);
  const dispatch = useDispatch();

  interface ContextViewParams extends ContextParams {
    view: MenuItemsView;
  }

  const { contextId: companyId, locationId, view } = useParams<ContextViewParams>();
  const { query, stockStatus, page } = useSearchParams();
  const history = useHistory();

  const hasManagePermission = Verifier.check(
    new PrincipalPermissions(permissions),
    SecurityScope.location,
    ResourceType.menu,
    MenuResourceActions.all,
    locationId
  );

  const handleFilterChange = (
    view: MenuItemsView,
    query: string | undefined,
    stockStatus: string | undefined
  ): void => {
    let params = '';
    if (query) {
      params = `?query=${query}`;
    }
    if (stockStatus) {
      if (!params) {
        params = '?';
      } else {
        params += `&`;
      }
      params += `stockStatus=${stockStatus}`;
    }
    history.push(`${Routes.getItemsAndModsPage(companyId, locationId, view)}${params}`);
  };

  const handleItemTypeSelectFilerChange = (option: Option) => {
    switch (option.value) {
      case MenuItemsView.ITEMS:
        handleFilterChange(MenuItemsView.ITEMS, query, stockStatus);
        break;
      case MenuItemsView.MODS:
        handleFilterChange(MenuItemsView.MODS, query, stockStatus);
        break;
      case MenuItemsView.ALL:
        handleFilterChange(MenuItemsView.ALL, query, stockStatus);
        break;
    }
  };

  const onSyncClicked = (): void => {
    syncMenuItems(companyId, locationId, view, stockStatus, query, page);
  };

  const onNewItemClicked = (type: 'item' | 'mod'): void => {
    history.push(
      type === 'item'
        ? Routes.getNewMenuItemPage(companyId, locationId)
        : Routes.getNewModifierPage(companyId, locationId)
    );
  };

  const searchClassName = classNames(`${styles.search}`, 'search-input-full-width', 'white');

  const { isMobile, isTablet } = useResponsiveBreakpoint();
  const loadingInstances = 10;
  const loadingRowsWithinInstances = isMobile ? 3 : 2;
  const loadingColumns = isMobile ? 2 : 5;
  const infoCardColumnWidths = isMobile ? [20, 80] : [5, 35, 35, 5, 20];

  const [imageUrls, setImageUrls] = React.useState<{ [id: string]: string }>({});

  const toggleStockFilter = [
    {
      label: 'All',
      selected: !stockStatus || stockStatus === ItemsAndGroupsStockStatus.All,
      onClick: () => {
        handleFilterChange(view, query, undefined);
      },
    },
    {
      label: 'In Stock',
      selected: stockStatus === ItemsAndGroupsStockStatus.InStock,
      onClick: () => {
        handleFilterChange(view, query, ItemsAndGroupsStockStatus.InStock);
      },
    },
    {
      label: 'Out of Stock',
      selected: stockStatus === ItemsAndGroupsStockStatus.OutOfStock,
      onClick: () => {
        handleFilterChange(view, query, ItemsAndGroupsStockStatus.OutOfStock);
      },
    },
  ];

  const getS3ImagesMap = useS3ImagesMap();

  const syncButton = {
    label: 'Sync',
    hidden: !(isMobile || isTablet),
    disabled: syncing,
    onClick: onSyncClicked,
  };
  const options = hasManagePermission
    ? [
        {
          label: 'New Menu Item',
          disabled: syncing,
          onClick: () => onNewItemClicked('item'),
        },
        {
          label: 'New Modifier',
          disabled: syncing,
          onClick: () => onNewItemClicked('mod'),
        },
        syncButton,
      ]
    : [syncButton];

  const fetchLocation = React.useCallback(
    (companyId: string, locationId: string) => {
      if (!location || location.id !== locationId) {
        dispatch(LocationsThunks.loadSelectedLocation({ companyId, locationId }));
      }
    },
    [location, dispatch]
  );

  const fetchData = React.useCallback(
    async (
      companyId: string,
      locationId: string,
      view: MenuItemsView,
      inStockFilter: ItemsAndGroupsStockStatus,
      query: string,
      page: number
    ) => {
      await loadMenuItems(companyId, locationId, view, inStockFilter, query, page);
    },
    [loadMenuItems]
  );

  const fetchImages = React.useCallback(
    async (items: IMenuItem[]) => {
      const imageRequests: IImageS3Request[] = items
        .filter((item) => item.thumbnailImageId)
        .map((item) => ({
          id: item._id,
          fileKey: item.thumbnailImageId!,
        }));
      const s3ImageUrls = await getS3ImagesMap(imageRequests);
      setImageUrls(s3ImageUrls);
    },
    [getS3ImagesMap]
  );

  React.useEffect(() => {
    fetchLocation(companyId, locationId);
  }, [fetchLocation, companyId, locationId]);

  React.useEffect(() => {
    fetchData(companyId, locationId, view, stockStatus, query, page);
    storeMenuItemsFilters(view, stockStatus, query, page);
  }, [fetchData, storeMenuItemsFilters, companyId, locationId, view, stockStatus, query, page]);

  React.useEffect(() => {
    if (items.length > 0) {
      fetchImages(items);
    }
  }, [fetchImages, items]);

  const handleStockUpdate = (id: string, newStockStatus: boolean) => {
    quickUpdateMenuItemInStock(companyId, locationId, id, newStockStatus);
  };

  const handleStockOption = (id: string, inStock: boolean, loading: boolean): DropDownMenuOptionProps[] => {
    const label = inStock ? 'Set to Out of Stock' : 'Set to In Stock';
    const controlOption: DropDownMenuOptionProps = {
      label,
      onClick: () => handleStockUpdate(id, !inStock),
      loading,
    };
    return [controlOption];
  };

  // configuration from the location
  const canUpdateStock = location?.settings.allowManualStockUpdates;

  const selectedMenuItemType = !view
    ? LIST_OPTIONS.menuItemType[0]
    : LIST_OPTIONS.menuItemType.find((type) => type.value === view);

  const showInfoCardListView = loading || syncing || (items && !!items.length) || query || page || stockStatus;

  return (
    <MenuBuilderLayoutContent
      companyId={companyId}
      locationId={locationId}
      locationName={location && location.id === locationId ? location.name : null}
      stockStatusOnly={!hasManagePermission}
      tab={MenuBuilderTab.ITEMS_AND_MODS}
    >
      <div className={styles.container}>
        <div className={styles.controls}>
          <div className={styles.search}>
            <SearchParamInput
              placeholder='Search menu items and mods'
              disabled={syncing}
              additionalClassName={searchClassName}
              additionalParams={stockStatus ? `stockStatus=${stockStatus}` : undefined}
            />
            <div className={styles.overflow}>
              <OverflowButton options={options} />
            </div>
          </div>
          <div className={styles.filter}>
            <SelectFilter
              options={LIST_OPTIONS.menuItemType}
              value={selectedMenuItemType}
              onChange={handleItemTypeSelectFilerChange}
            />
          </div>
          <div className={styles.toggles}>
            <ToggleFilter buttons={toggleStockFilter} />
          </div>
          <div className={styles.buttons}>
            <Button
              label={syncButton.label}
              icon={Icon.Refresh}
              variant='secondary-gray-bg'
              loading={syncing}
              onClick={syncButton.onClick}
            />
            {hasManagePermission && <DropDownButton label='New Item' options={options} disabled={syncing} />}
          </div>
          <div className={styles.tabletOverflow}>
            <OverflowButton options={options} />
          </div>
        </div>

        {showInfoCardListView ? (
          <InfoCardList paginationProps={pagination}>
            {loading || syncing ? (
              <InfoCardsSkeleton
                instances={loadingInstances}
                rowsWithinInstance={loadingRowsWithinInstances}
                columns={loadingColumns}
                infoCardColumnWidths={infoCardColumnWidths}
              />
            ) : items && !!items.length ? (
              items.map((item: IMenuItem) => (
                <MenuItemCard
                  key={item._id}
                  menuItem={item}
                  linkTo={hasManagePermission ? Routes.getMenuItemPage(companyId, locationId, item._id) : undefined}
                  imageUrl={imageUrls[item._id]}
                  controls={
                    canUpdateStock ? (
                      <OverflowButton options={handleStockOption(item._id, item.inStock, false)} />
                    ) : null
                  }
                  additionalClassName={hasManagePermission ? styles.item : undefined}
                />
              ))
            ) : (
              <InfoCard>
                <div className={styles.noResults}>No Results Found</div>
              </InfoCard>
            )}
          </InfoCardList>
        ) : (
          <EmptyResult
            title='Create Your First Items &amp; Mods'
            paragraph="Use the 'New Item' button to create new menu items and modifiers to be displayed on your Ready menu."
          />
        )}
      </div>
    </MenuBuilderLayoutContent>
  );
};

export default connector(MenuItemsPage);
