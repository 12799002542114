import { chain } from 'lodash';
import { sortOrderPickupTimeAsc } from '.';
import { ISinglePlacedOrder } from '../../services/types/orders.type';

/**
 * Groups orders by table name, then sorts grouped orders by its first order pickup time
 *
 * @param orders ISinglePlacedOrder[]
 * @returns ISinglePlacedOrder[][]
 */
export const groupOrdersByTableName = (orders: ISinglePlacedOrder[]): ISinglePlacedOrder[][] => {
  return (
    chain(orders)
      // gets array out of frozen / strict mode
      .slice()
      // sorts orders to make sure they are pre-sorted before grouping by
      .sort(sortOrderPickupTimeAsc)
      // group orders by table name
      .groupBy((order) => {
        if (order.tableInfo && order.tableInfo.tableName) {
          return order.tableInfo.tableName;
        }

        return 'No Table Assigned';
      })
      // converts object into array of arrays
      .reduce((acc, orders) => {
        return [...acc, orders];
      }, [] as ISinglePlacedOrder[][])
      // sorts array of arrays to make sure older pickup time will be first
      .sort((a, b) => sortOrderPickupTimeAsc(a[0], b[0]))
      .value()
  );
};
