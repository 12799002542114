import { ILocationOrderSettings } from '@ready/dashboardv2api.contracts';
import { OrdersService } from '../../../services/ordersService';
import { Action } from '../../types';
import { toastErrorState } from '../uiActions/responseStateActions';
import { ORDERS_ACTIONS } from './types';

export const setOrderSettingsDialogOpen = (isOpen: boolean): Action => ({
  type: ORDERS_ACTIONS.SET_ORDER_SETTINGS_DIALOG_OPEN,
  payload: isOpen,
});

export const fetchOrderSettingsDialogData = (companyId: string, locationId: string) => async (dispatch: any) => {
  try {
    dispatch(setOrderSettingsDialogLoading(true));
    const orderSettingsDialogData = await OrdersService.getLocationOrderSettingsData(companyId, locationId);
    dispatch(setOrderSettingsDialogData(orderSettingsDialogData));
  } catch (err) {
    dispatch(toastErrorState(err.status, err.message));
  } finally {
    dispatch(setOrderSettingsDialogLoading(false));
  }
};

export const initOrderSettingsDialogData = (): Action => ({
  type: ORDERS_ACTIONS.INIT_ORDER_SETTINGS_DIALOG_DATA,
});

export const toggleOrderThrottlingEnabled = (enabled: boolean): Action => ({
  type: ORDERS_ACTIONS.TOGGLE_ORDER_SETTINGS_ORDER_THROTTLING_ENABLED,
  payload: enabled,
});

export const updateMaxNumberOfOrdersPerMinuteAmount = (amount: number): Action => ({
  type: ORDERS_ACTIONS.UPDATE_ORDER_SETTINGS_MAX_NUMBER_OF_ORDERS_PER_MINUTE_AMOUNT,
  payload: amount,
});

export const updateMaxNumberOfOrdersPerMinuteDurationMinutes = (durationMinutes: number): Action => ({
  type: ORDERS_ACTIONS.UPDATE_ORDER_SETTINGS_MAX_NUMBER_OF_ORDERS_PER_MINUTE_DURATION_MINUTES,
  payload: durationMinutes,
});

export const updateDisruptionMessage = (message: string): Action => ({
  type: ORDERS_ACTIONS.UPDATE_ORDER_SETTINGS_DISRUPTION_MESSAGE,
  payload: message,
});

export const updateAlcoholPolicyMessage = (message: string): Action => ({
  type: ORDERS_ACTIONS.UPDATE_ORDER_SETTINGS_ALCOHOL_POLICY_MESSAGE,
  payload: message,
});

export const updateMaxNumberOfDrinksPerOrder = (maxNumber: number): Action => ({
  type: ORDERS_ACTIONS.UPDATE_ORDER_SETTINGS_MAX_NUMBER_OF_DRINKS_PER_ORDER,
  payload: maxNumber,
});

export const updateLocationOrderSettingsData =
  (companyId: string, locationId: string, payload: ILocationOrderSettings) => async (dispatch: any) => {
    try {
      dispatch(setOrderSettingsDialogLoading(true));
      const result = await OrdersService.saveLocationOrderSettingsData(companyId, locationId, payload);
      if (result) {
        dispatch(setOrderSettingsDialogOpen(false));
      }
    } catch (err) {
      dispatch(toastErrorState(err.status, err.message));
      const originalResults = await OrdersService.getLocationOrderSettingsData(companyId, locationId);
      dispatch(setOrderSettingsDialogData(originalResults));
    } finally {
      dispatch(setOrderSettingsDialogLoading(false));
    }
  };

const setOrderSettingsDialogData = (data: ILocationOrderSettings): Action => ({
  type: ORDERS_ACTIONS.SET_ORDER_SETTINGS_DIALOG_DATA,
  payload: data,
});

const setOrderSettingsDialogLoading = (loading: boolean): Action => ({
  type: ORDERS_ACTIONS.SET_ORDER_SETTINGS_DIALOG_LOADING,
  payload: loading,
});

export const validateOrderSettings = (orderSettings: ILocationOrderSettings): Action => ({
  type: ORDERS_ACTIONS.VALIDATE_ORDER_SETTINGS,
  payload: { orderSettings },
});
