import { itemGroupListSlice } from './list/redux/slice';
import { combineReducers } from 'redux';
import { itemGroupDetailsSlice } from './details/redux/slice';
import { itemGroupAssignedLocationsSlice } from './assignedLocations/redux/slice';

export const itemGroupReducer = combineReducers({
  list: itemGroupListSlice.reducer,
  details: itemGroupDetailsSlice.reducer,
  assignedLocations: itemGroupAssignedLocationsSlice.reducer,
});
