import React, { RefObject } from 'react';
import NamedEntityValidation from '../../types/NamedEntityValidation.interface';
import styles from './DisplayNameInput.module.scss';
import { FormControl } from '../../../components/Form';
import TextInputNestedLabel from '../../../components/TextInputNestedLabel/TextInputNestedLabel';

export interface DisplayNameInputProps {
  value: string;
  validation: NamedEntityValidation;
  processing: boolean;
  label?: string;
  inputRef?: RefObject<HTMLInputElement>;
  fullWidth?: boolean;
  maxLength?: number;
  onChange: (displayName: string) => void;
}

const DisplayNameInput = (props: DisplayNameInputProps): JSX.Element => {
  const {
    value,
    validation,
    processing,
    label = 'Display Name',
    inputRef,
    fullWidth = false,
    maxLength,
    onChange,
  } = props;

  const onDisplayNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const className = `${styles.input} ${fullWidth ? styles.fullWidth : ''}`;
  return (
    <FormControl
      withError={validation.displayName.hasError}
      errorMessage={validation.displayName.errorMessage}
      hideFormLabel
      additionalStyles={className}
    >
      <TextInputNestedLabel
        ref={inputRef}
        value={value}
        label={label}
        loading={processing}
        withError={validation.displayName.hasError}
        maxLength={maxLength}
        onChange={onDisplayNameChange}
      />
    </FormControl>
  );
};

export default DisplayNameInput;
