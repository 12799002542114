import { createSlice } from '@reduxjs/toolkit';
import { fetchPayoutHistoryDetailsReportThunk, fetchPermittedLocationsThunk } from './thunks';
import { fetchPayoutHistoryDetailsReportReducers, fetchPermittedLocationsReducers } from './reducers';
import { IPayoutHistoryDetailsReportState } from './types';

const initialState: IPayoutHistoryDetailsReportState = {
  items: [],
  permittedLocations: {
    totalCount: 0,
    results: [],
  },
  pagination: {
    start: 0,
    length: 0,
    limit: 0,
    total: 0,
  },
  loading: {},
  errors: {},
};

const payoutHistoryDetailsReportSlice = createSlice({
  name: 'payoutHistoryReport',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPayoutHistoryDetailsReportThunk.pending, fetchPayoutHistoryDetailsReportReducers.pending);
    builder.addCase(fetchPayoutHistoryDetailsReportThunk.fulfilled, fetchPayoutHistoryDetailsReportReducers.fulfilled);
    builder.addCase(fetchPayoutHistoryDetailsReportThunk.rejected, fetchPayoutHistoryDetailsReportReducers.rejected);

    builder.addCase(fetchPermittedLocationsThunk.pending, fetchPermittedLocationsReducers.pending);
    builder.addCase(fetchPermittedLocationsThunk.fulfilled, fetchPermittedLocationsReducers.fulfilled);
    builder.addCase(fetchPermittedLocationsThunk.rejected, fetchPermittedLocationsReducers.rejected);
  },
});

export const { actions, reducer } = payoutHistoryDetailsReportSlice;
