import { configureStore } from '@reduxjs/toolkit';
import { createSelectorHook, useDispatch } from 'react-redux';
import monolithicReducer from './reducers';
import { tablesReducer } from '../companyLocations/redux/reducers';
import { menuBuilderReducer } from '../menus/redux';
import { sharedMenuItemReducer } from 'sharedMenuItems/redux/reducer';
import { companiesReducer } from 'admin/companies/reducer';

export const store = configureStore({
  reducer: {
    // pre-modularization reducer
    ...monolithicReducer,
    // modules
    menuBuilder: menuBuilderReducer,
    tableTarget: tablesReducer,
    sharedMenuItems: sharedMenuItemReducer,
    companies: companiesReducer,
  },
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector = createSelectorHook<RootState>();

export default store;
