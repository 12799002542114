import React from 'react';
import NoneValue from 'components/Value/NoneValue';
import { useAppSelector } from 'redux/store';
import { DetailElement } from 'components/DetailLayout';
import { PaymentProcessorProvider } from '@ready/dashboardv2api.contracts';
import { PanelLayout, Panel } from 'components/PanelLayout';

export const ReadyPaymentsPanel = () => {
  const { serialNumbers } = useAppSelector((state) => state.location.serverFlow.readyPayments);
  const { location } = useAppSelector((state) => state.paymentProcessing.paymentProcessing);

  return (
    <PanelLayout>
      <Panel title='Payment Provider'>
        <DetailElement label='Provider'>
          {location?.paymentProcessor === PaymentProcessorProvider.Adyen && <p>Ready Payments</p>}
        </DetailElement>
        <DetailElement label='Payment Terminal Serial Numbers'>
          {serialNumbers.length > 0 ? serialNumbers.map((num) => <div key={num}>{num}</div>) : <NoneValue />}
        </DetailElement>
      </Panel>
    </PanelLayout>
  );
};
