import { IPagination } from '@ready/dashboardv2api.contracts';
import { IBulkEditMenuItemGroup } from '@ready/menu.core';
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import { getTemplateChildMenuItemGroupsThunk } from './thunks';

interface State {
  childMenuItemGroups: IBulkEditMenuItemGroup[];
  pagination?: IPagination;
  loading: boolean;

  totalLocationsCount?: number;
}

const initialState: State = {
  loading: true,
  childMenuItemGroups: [],
};

const itemGroupAssignedLocationsSlice = createSlice({
  name: 'itemGroups/assignedLocations',
  initialState: initialState,
  reducers: {
    storeTotalLocationsCount: (state, action: { payload: number }) => {
      state.totalLocationsCount = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<State>) => {
    builder.addCase(getTemplateChildMenuItemGroupsThunk.pending, (state: State) => {
      state.loading = true;
    });
    builder.addCase(getTemplateChildMenuItemGroupsThunk.fulfilled, (state: State, action) => {
      state.loading = false;
      const { items, ...pagination } = action.payload;
      state.childMenuItemGroups = items;
      state.pagination = pagination;
    });

    builder.addCase(getTemplateChildMenuItemGroupsThunk.rejected, (state: State) => {
      state.loading = false;
    });
  },
});

export { itemGroupAssignedLocationsSlice };
