import React from 'react';
import { FormControl } from '../../../components/Form';
import TextInput from '../../../components/TextInput/TextInput';
import { IPosLoyaltyPanel } from '../../redux/PanelLoyaltyState';
import { PANEL_LOYALTY_ACTIONS } from '../../redux/PanelLoyaltyActions';
import { IUpdateLocationParam } from '../LocationsEditPage';

export interface ByPassProps {
  isProcessing: boolean;
  updateLocationParam: IUpdateLocationParam;
  posLoyaltyPanelInitialState: IPosLoyaltyPanel;
}

const ByPass = (props: ByPassProps) => {
  const { isProcessing, updateLocationParam, posLoyaltyPanelInitialState } = props;

  return (
    <FormControl
      label='Location ID *'
      withError={posLoyaltyPanelInitialState.validations.byPass.locationId.hasError}
      errorMessage={posLoyaltyPanelInitialState.validations.byPass.locationId.errorMessage}
    >
      <TextInput
        placeholder='Enter the ByPass location ID'
        value={posLoyaltyPanelInitialState.byPassOptions?.locationId}
        onChange={(event) => {
          updateLocationParam(event.target.value, PANEL_LOYALTY_ACTIONS.UPDATE_BYPASS_LOCATION_ID);
        }}
        withError={posLoyaltyPanelInitialState.validations.byPass.locationId.hasError}
        loading={isProcessing}
      />
    </FormControl>
  );
};

export default ByPass;
