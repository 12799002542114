import React from 'react';
import styles from './UnassignedPanelHeader.module.scss';
import Button from '../../components/Button/Button';
import classNames from 'classnames';
import IconButton from '../../components/IconButton/IconButton';
import TextIcon, { Icon } from '../../components/Icon/TextIcon';
import useResponsiveBreakpoint from '../../hooks/useResponsiveBreakpoint';

interface UnassignedPanelHeaderProps {
  selectedCount: number;
  onAssignClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onExpandToggleClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isExpanded: boolean;
  additionalStyles?: string;
  loading: boolean;
  hasAllPermission: boolean;
}

const UnassignedPanelHeader = ({
  selectedCount,
  onAssignClick,
  onExpandToggleClick,
  isExpanded,
  additionalStyles,
  loading,
  hasAllPermission,
}: UnassignedPanelHeaderProps) => {
  const { isMobile } = useResponsiveBreakpoint();

  const buttonDisabled = selectedCount < 1 || loading;
  const buttonLabelCount = buttonDisabled ? undefined : ` (${selectedCount})`;

  return (
    <div className={classNames(styles.container, additionalStyles)}>
      <div className={styles.headerContainer}>
        <span className={styles.header}>Tables</span>
        {isMobile ? (
          <IconButton onClick={onExpandToggleClick}>
            <TextIcon icon={isExpanded ? Icon.AngleUp : Icon.AngleDown} additionalStyles={styles.expandButton} />
          </IconButton>
        ) : null}
      </div>
      {(isExpanded || !isMobile) && hasAllPermission ? (
        <div className={styles.assignButtonContainer}>
          <Button disabled={buttonDisabled} label='' additionalStyles={styles.assignButton} onClick={onAssignClick}>
            <div className={styles.assignButtonLabelContainer}>
              <span className={styles.assignButtonLabel}>Assign Selected</span>
              {buttonLabelCount ? <span className={styles.assignButtonCount}>{buttonLabelCount}</span> : null}
            </div>
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default UnassignedPanelHeader;
