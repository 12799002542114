import React from 'react';

interface FormControlStackedProps {
  children: React.ReactNode;
  indented?: boolean;
}

const FormControlStacked = ({ children, indented }: FormControlStackedProps) => (
  <div className={`control--stacked ${indented ? 'control--stacked--indented' : ''}`}>{children}</div>
);

export default FormControlStacked;
