import { PaginationResponse } from '@ready/dashboardv2api.contracts';
import { IBulkEditMenuItemGroupRequest, ILocationAssignments, ITemplateLocation } from '@ready/menu.core';
import { IPagedResponse } from '@ready/table.core';
import config from 'config';
import executeApiAsync from 'services/utils/executeApiAsync';
import toPaginationResponse from 'services/utils/toPaginationResponse';
import { objectToQueryParamsString } from 'utils/urlUtils/objectToQueryParamsString';

export const getLocationAssignmentsForItemGroup = async (
  companyId: string,
  itemGroupId: string,
  locationSearchTerm?: string,
  pageNumber: number = 1,
  pageSize: number = 50
): Promise<PaginationResponse<ITemplateLocation>> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/itemGroups/${itemGroupId}/locations`;
  const queryString = objectToQueryParamsString({
    pageNumber: pageNumber.toString(),
    pageSize: pageSize.toString(),
    searchTerm: locationSearchTerm,
  });

  const result = await executeApiAsync<IPagedResponse<ITemplateLocation>>(url + '?' + queryString, { method: 'GET' });
  return toPaginationResponse({ results: result.results, totalCount: result.totalCount }, pageNumber, pageSize);
};

export const updateLocationAssignmentsForItemGroup = async (
  companyId: string,
  itemId: string,
  locationAssignments: ILocationAssignments
): Promise<void> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/itemGroups/${itemId}/locations`;

  await executeApiAsync<IPagedResponse<ITemplateLocation>>(url, {
    body: JSON.stringify(locationAssignments),
    method: 'POST',
  });
};

export const bulkUpdateLocationItemGroups = async (
  companyId: string,
  itemGroupId: string,
  bulkEditMenuItemGroupRequest: IBulkEditMenuItemGroupRequest
): Promise<void> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/itemGroups/${itemGroupId}/locationItemGroups`;
  // when schedule is set to undefined (clear the schedule) json stringify does not send it by default
  const replacer = (key: string, value: any) => (typeof value === 'undefined' ? null : value);
  return await executeApiAsync<void>(url, {
    method: 'PUT',
    body: JSON.stringify(bulkEditMenuItemGroupRequest, replacer),
  });
};
