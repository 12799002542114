import ActionHeader from 'components/ActionHeader/ActionHeader';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { SharedMenuItemsRoutes } from 'sharedMenuItems/Router';
import { SharedMenuItemsLayout } from 'sharedMenuItems/SharedMenuItemsLayout';
import { SharedMenuItemsTabId } from 'sharedMenuItems/SharedMenuItemsTabs';
import { ContextParams } from 'types/ContextParams.interface';
import { AssignedLocationsTable } from 'sharedMenuItems/components/assignedLocationsTable/AssignedLocationsTable';
import { TableHeaderProps } from 'components/TableV2/TableHeader/TableHeader';
import { HeaderCell, HeaderCellConstants, HeaderCellProps } from 'components/TableV2/Cell/HeaderCell';
import {
  CheckboxCell,
  LocationNameCell,
  LocationNameCellProps,
  MobileToolbarHeader,
  MobileToolbarHeaderProps,
  ScheduleLinkCell,
  UnassignCell,
  UnassignCellProps,
} from 'sharedMenuItems/pages/itemAssignedLocations/components/table/Cells';
import { TableBodyProps } from 'components/TableV2/Body/Body';
import { getWrappedCellsWithMobileLayout } from 'components/TableV2/Cell/Cell';
import {
  bulkUpdateAssignedLocationItemGroupsThunk,
  getAssignedLocationsForItemGroupThunk,
  getTemplateChildMenuItemGroupsThunk,
  updateAssignedLocationsforItemGroupThunk,
} from './redux/thunks';
import { IBulkEditMenuItemGroupRequest, ILocationAssignments } from '@ready/menu.core';
import { ToolbarContent } from './components/ToolbarContent';
import { updateQueryParamsDebounced } from 'utils/updatePath';
import { useSearchParams } from 'hooks';
import { decodeUriString } from 'utils/urlUtils/decodeUriString';
import { DetailsTabs, DetailTabId } from 'sharedMenuItems/pages/createEditItem/components/tabs/DetailsTabs';
import {
  deleteSharedItemGroupThunk,
  duplicateSharedItemGroupThunk,
  getSharedItemGroupThunk,
} from '../details/redux/thunks';
import { initCellCheckedState } from 'sharedMenuItems/redux/table/tableSlice';
import { selectTableState } from 'sharedMenuItems/redux/selectors';
import BulkUnassignLocationModal from 'sharedMenuItems/components/bulkUnassignLocationModal/BulkUnassignLocationModal';
import { BulkEditToolbarContent } from 'sharedMenuItems/components/bulkEditToolbarContent/BulkEditToolbarContent';
import BulkEditItemGroupSettingsForm, { IBulkEditItemSettingsFormState } from './BulkEditItemGroupSettingsForm';
import { UnassignLocationModal } from 'sharedMenuItems/components/unassignLocationModal/UnassignLocationModal';
import EditItemGroupSettingsForm from './EditItemGroupSettingsForm';
import ModalForm from 'components/ModalForm/ModalForm';
import styles from './ItemGroupAssignedLocationsPage.module.scss';
import { resetState } from '../details/redux/slice';
import { DeleteConfirmationModal } from 'components/Modal';
import DuplicateMenuItemModal from 'menus/components/ItemsAndMods/DuplicateMenuItemModal';
import { setFormIsDirty } from 'redux/actions/uiActions/formStateActions';

enum ItemGroupModalType {
  Edit = 'edit',
  BulkEdit = 'bulkEdit',
  Unassign = 'unassign',
  BulkUnassign = 'bulkUnassign',
}

interface TableCellProps {
  unassign: UnassignCellProps;
  locationName: LocationNameCellProps;
  mobileToolbarHeader: MobileToolbarHeaderProps;
}

export const ItemGroupAssignedLocationsPage = () => {
  const { contextId: companyId, id } = useParams<ContextParams>();
  const { childMenuItemGroups, loading, pagination, totalLocationsCount } = useAppSelector(
    (state) => state.sharedMenuItems.itemGroups.assignedLocations
  );
  const { searchTerm, page } = useSearchParams();
  const decodedSearchTerm = decodeUriString(searchTerm ?? '');
  const { rowData, checkCells } = useAppSelector(selectTableState);
  const checkedLocationIds = Object.keys(checkCells).filter((locationId) => checkCells[locationId]);

  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const [updating, setUpdating] = useState<boolean>();
  const [currentModalVisible, setCurrentModalVisible] = useState<ItemGroupModalType | undefined>();
  const [showDuplicateModal, setShowDuplicateModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  useEffect(() => {
    dispatch(
      getTemplateChildMenuItemGroupsThunk({
        companyId,
        itemId: id,
        locationSearchTerm: decodedSearchTerm,
        page: page || 1,
      })
    );
  }, [companyId, dispatch, id, decodedSearchTerm, page]);

  const saveAssignedLocations = async (assignments: ILocationAssignments) => {
    await dispatch(
      updateAssignedLocationsforItemGroupThunk({
        companyId,
        itemId: id,
        assignments,
      })
    );
    dispatch(getTemplateChildMenuItemGroupsThunk({ companyId, itemId: id, locationSearchTerm: decodedSearchTerm }));
  };

  const onSearchTermChanged = (searchTerm: string) => {
    updateQueryParamsDebounced(
      SharedMenuItemsRoutes.getItemGroupAssignedLocationsRoute(companyId, id),
      { searchTerm: searchTerm, page: '' },
      push
    );
  };

  const { itemGroup, itemGroupCache, newId, isItemGroupDeleted, saving } = useAppSelector(
    (state) => state.sharedMenuItems.itemGroups.details
  );
  useEffect(() => {
    dispatch(getSharedItemGroupThunk({ companyId, id }));
    return () => {
      dispatch(resetState());
    };
  }, [companyId, dispatch, id]);

  useEffect(() => {
    dispatch(initCellCheckedState(childMenuItemGroups));
  }, [dispatch, childMenuItemGroups]);

  useEffect(() => {
    if (newId) {
      push(SharedMenuItemsRoutes.getItemGroupDetailsRoute(companyId, newId));
    } else if (isItemGroupDeleted) {
      push(SharedMenuItemsRoutes.getItemGroupListRoute(companyId));
    }
  }, [companyId, isItemGroupDeleted, newId, push]);

  const onUnassign = async (locationIds: string[], toastMessage?: string) => {
    if (!locationIds || locationIds.length === 0) return;

    setUpdating(true);
    await dispatch(
      updateAssignedLocationsforItemGroupThunk({
        companyId,
        itemId: id,
        assignments: {
          assignToLocations: [],
          unassignFromLocations: locationIds,
        },
        toastMessage,
      })
    );
    setUpdating(false);
    dispatch(getTemplateChildMenuItemGroupsThunk({ companyId, itemId: id, locationSearchTerm: decodedSearchTerm }));
  };

  const onEditSave = async (locationIds: string[], formState: IBulkEditItemSettingsFormState): Promise<void> => {
    if (!locationIds || locationIds.length === 0) return;

    const checkedLocationItemGroups = locationIds.reduce<{ _id: string; locationId: string }[]>(
      (accumulator, locationId) => {
        const itemGroup = childMenuItemGroups.find((itemGroup) => itemGroup.locationId === locationId);

        if (itemGroup) {
          accumulator.push({ _id: itemGroup._id, locationId });
        }

        return accumulator;
      },
      []
    );

    const bulkEditMenuItemGroupRequest: IBulkEditMenuItemGroupRequest = {
      templateItemGroupId: id,
      itemGroups: checkedLocationItemGroups,
      itemGroupUpdates: {
        ...formState,
      },
    };

    await dispatch(
      bulkUpdateAssignedLocationItemGroupsThunk({
        companyId,
        itemGroupId: id,
        bulkEditMenuItemGroupRequest,
      })
    );
    dispatch(getTemplateChildMenuItemGroupsThunk({ companyId, itemId: id, locationSearchTerm: searchTerm }));
  };

  const bodyCellProps: TableCellProps = {
    unassign: {
      onUnassign: () => setCurrentModalVisible(ItemGroupModalType.Unassign),
    },
    locationName: {
      onEdit: () => setCurrentModalVisible(ItemGroupModalType.Edit),
    },
    mobileToolbarHeader: {
      onEdit: () => setCurrentModalVisible(ItemGroupModalType.Edit),
      onUnassign: () => setCurrentModalVisible(ItemGroupModalType.Unassign),
    },
  };

  return (
    <>
      <SharedMenuItemsLayout tab={SharedMenuItemsTabId.ItemGroups}>
        <>
          <ActionHeader
            backLinkTo={SharedMenuItemsRoutes.getItemGroupListRoute(companyId)}
            text={itemGroup?.displayName}
            actionButtons={[
              {
                label: 'Duplicate',
                variant: 'secondary-gray-bg',
                disabled: loading,
                onClick: () => {
                  setShowDuplicateModal(true);
                },
              },
              {
                label: 'Delete',
                variant: 'secondary-gray-bg',
                disabled: loading,
                onClick: () => {
                  setShowDeleteModal(true);
                },
              },
            ]}
          />

          <DetailsTabs
            detailsRoute={SharedMenuItemsRoutes.getItemGroupDetailsRoute(companyId, id)}
            assignedLocationsRoute={SharedMenuItemsRoutes.getItemGroupAssignedLocationsRoute(companyId, id)}
            selectedTabId={DetailTabId.AssignedLocations}
          />
          {currentModalVisible === ItemGroupModalType.BulkUnassign && (
            <BulkUnassignLocationModal
              itemType='Item Group'
              itemName={itemGroup?.displayName}
              totalCheckedLocations={checkedLocationIds.length}
              loading={updating}
              setShowModal={(showModal) =>
                setCurrentModalVisible(showModal ? ItemGroupModalType.BulkUnassign : undefined)
              }
              onBulkUnassign={async () => {
                await onUnassign(checkedLocationIds, 'Item group unassigned from locations.');
                setCurrentModalVisible(undefined);
              }}
            />
          )}

          {currentModalVisible === ItemGroupModalType.Unassign && (
            <UnassignLocationModal
              itemType='Item Group'
              itemName={itemGroup?.displayName}
              locationName={rowData?.locationName}
              loading={updating}
              setShowModal={(showModal) => setCurrentModalVisible(showModal ? ItemGroupModalType.Unassign : undefined)}
              onUnassign={async () => {
                await onUnassign(
                  rowData ? [rowData.locationId] : [],
                  `Success! Item group unassigned from ${rowData?.locationName}.`
                );
                setCurrentModalVisible(undefined);
              }}
            />
          )}

          {currentModalVisible === ItemGroupModalType.BulkEdit && (
            <ModalForm
              onSave={async (formState: IBulkEditItemSettingsFormState) => {
                await onEditSave(checkedLocationIds, formState);
                setCurrentModalVisible(undefined);
              }}
              headerLabel='Edit Item Group Settings'
              subLabel={`(${checkedLocationIds.length} location${checkedLocationIds.length > 1 ? 's' : ''})`}
              setShowModal={(showModal) => setCurrentModalVisible(showModal ? ItemGroupModalType.BulkEdit : undefined)}
              defaultValues={{ schedule: undefined }}
            >
              <BulkEditItemGroupSettingsForm />
            </ModalForm>
          )}

          {currentModalVisible === ItemGroupModalType.Edit && rowData && (
            <ModalForm
              onSave={async (formState: IBulkEditItemSettingsFormState) => {
                await onEditSave([rowData.locationId], formState);
                setCurrentModalVisible(undefined);
              }}
              headerLabel='Edit Item Group Settings'
              setShowModal={(showModal) => setCurrentModalVisible(showModal ? ItemGroupModalType.Edit : undefined)}
              defaultValues={{ schedule: rowData.schedule }}
            >
              <EditItemGroupSettingsForm itemGroup={rowData} />
            </ModalForm>
          )}

          <AssignedLocationsTable
            pagination={pagination}
            tableHeaderProps={tableHeaderProps}
            bodyProps={{ ...bodyProps, cellProps: bodyCellProps }}
            items={childMenuItemGroups}
            loading={loading}
            noAssignedLocationsMessage='This item group has not been assigned to any locations yet.'
            toolbarContent={
              checkedLocationIds.length > 0 ? (
                <BulkEditToolbarContent
                  pagination={pagination}
                  setShowBulkEditModal={(showModal) =>
                    setCurrentModalVisible(showModal ? ItemGroupModalType.BulkEdit : undefined)
                  }
                  setShowBulkUnassignModal={(showModal) =>
                    setCurrentModalVisible(showModal ? ItemGroupModalType.BulkUnassign : undefined)
                  }
                />
              ) : (
                <ToolbarContent initialSearch={decodedSearchTerm} onSearchTermChanged={onSearchTermChanged} />
              )
            }
            loadLocationAssignments={({ page, isLoadMore, searchTerm }) =>
              dispatch(
                getAssignedLocationsForItemGroupThunk({
                  companyId,
                  itemId: id,
                  page: page,
                  isLoadMore,
                  locationSearchTerm: searchTerm,
                })
              )
            }
            onSaveLocationAssignments={saveAssignedLocations}
            totalLocationsCount={totalLocationsCount}
          />
        </>

        {showDuplicateModal && (
          <DuplicateMenuItemModal
            processing={saving}
            setVisible={setShowDuplicateModal}
            duplicateMenuItem={async (displayName) => {
              if (itemGroupCache) {
                await dispatch(
                  duplicateSharedItemGroupThunk({
                    companyId,
                    itemGroup: itemGroupCache,
                    displayName,
                  })
                );
                dispatch(setFormIsDirty(false));
                setShowDuplicateModal(false);
              }
            }}
            cancelChanges={(visible) => setShowDuplicateModal(visible)}
            headerLabel='item group'
            placeholder='e.g. Extra Cheese'
          />
        )}

        {showDeleteModal && (
          <DeleteConfirmationModal
            setShowModal={setShowDeleteModal}
            item='Shared Item Group'
            itemName={itemGroupCache?.displayName ?? ''}
            loading={saving}
            handleDelete={() => {
              dispatch(
                deleteSharedItemGroupThunk({
                  companyId,
                  id,
                })
              );
              dispatch(setFormIsDirty(false));
            }}
          />
        )}
      </SharedMenuItemsLayout>
    </>
  );
};

const ItemGroupHeaderCell = (props: HeaderCellProps) => (
  <HeaderCell {...props} maxWidth={HeaderCellConstants.defaultCellMaxWidth} />
);
const ItemGroupScheduleHeaderCell = (props: HeaderCellProps) => (
  <ItemGroupHeaderCell {...props} classes={styles.scheduleHeaderCell} />
);

const tableHeaderProps: TableHeaderProps = {
  cells: {
    checkboxCell: CheckboxCell,
    locationName: ItemGroupHeaderCell,
    schedule: ItemGroupScheduleHeaderCell,
    unassign: ItemGroupHeaderCell,
  },
  data: {
    checkboxCell: '',
    locationName: 'Location Name',
    schedule: 'Schedule',
    unassign: '',
  },
  columns: ['checkboxCell', 'locationName', 'schedule', 'unassign'],
};

const bodyCells = getWrappedCellsWithMobileLayout(
  {
    mobileToolbarHeader: MobileToolbarHeader,
    checkboxCell: CheckboxCell,
    locationName: LocationNameCell,
    schedule: ScheduleLinkCell,
    unassign: UnassignCell,
  },
  tableHeaderProps.data,
  styles.bodyCell
);

const bodyProps: TableBodyProps = {
  cells: bodyCells,
  columns: tableHeaderProps.columns,
  data: [],
  rowKey: '_id',
  hiddenColumns: {},
};
