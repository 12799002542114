import React from 'react';
import classnames from 'classnames';
import styles from './Widget.module.scss';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';

interface WidgetProps {
  title: string;
  children: React.ReactNode;
  classes?: string;
  loading?: boolean;
}

export function Widget({ title, children, classes, loading }: WidgetProps) {
  return (
    <div className={classnames(styles.widget, classes)}>
      {loading ? <LoadingSpinner additionalStyles={styles.widget__loader} /> : null}
      <h4 className={styles.widget__title}>{title}</h4>
      <div className={styles.widget__body}>{children}</div>
    </div>
  );
}
