import React from 'react';
import debounce from 'lodash/debounce';

/**
 * Calculates the actual viewport height unit and injects it into the page's CSS as a variable
 * that can be used directly in styles or in calculations. The CSS variable is "--vh", and is
 * set to the pixel value that represents 1% of the height. So, in order to size an element at
 * 100% of the viewport height, the style would be "calc(var(--vh, 1vh) * 100)".
 *
 * The purpose of this is to get around the behaviour of mobile Safari where the viewport area
 * calculated by standard "100vh" in CSS represents an area that is overlapped by Safari
 * components, and therefore is not completely visible to the user. Using JavaScript, we can
 * calculate the actual visible viewport and use that instead.
 */
const setVh = (): void => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

export const useSetVh = () => {
  React.useEffect(() => {
    setVh();

    const eventListener = () => {
      debounce(setVh, 100)();
    };

    if ('onorientationchange' in window) {
      window.addEventListener('orientationchange', eventListener);
    }

    if ('onresize' in window) {
      window.addEventListener('resize', eventListener);
    }

    return () => {
      if ('onorientationchange' in window) {
        window.removeEventListener('orientationchange', eventListener);
      }

      if ('onresize' in window) {
        window.removeEventListener('resize', eventListener);
      }
    };
  }, []);
};
