import { GuestInformationField, ILocationOrderAhead } from '@ready/dashboardv2api.contracts';
import { AppDispatch } from 'redux/store';
import { LOCATION_SETTINGS_ACTIONS } from '../LocationSettingsActions';

export const setMenuItemGuestInfoError = () => ({
  type: LOCATION_SETTINGS_ACTIONS.SET_MENU_ITEM_GUEST_INFO_ERROR,
  payload: {
    hasError: true,
    error: 'Menu Item is required if sending method is POS Item',
  },
});

export const validateOrderAheadPanel =
  (orderAhead: ILocationOrderAhead) =>
  (dispatch: AppDispatch): boolean => {
    const guestInfoFieldsValid = validateGuestInfoPosItemIdField(
      orderAhead.guestInfoFields,
      orderAhead.guestInfoPosItemId
    );

    if (!guestInfoFieldsValid) {
      dispatch(setMenuItemGuestInfoError());
    }

    return guestInfoFieldsValid;
  };

const validateGuestInfoPosItemIdField = (
  guestInfoFields: GuestInformationField[],
  guestInfoPosItemId?: string
): boolean => {
  const hasFieldWithFakeItemSendingMethod = guestInfoFields.some(
    (guestInfoField) => guestInfoField.methodForSending === 'fakeItem'
  );

  return hasFieldWithFakeItemSendingMethod && !guestInfoPosItemId ? false : true;
};
