import React, { Fragment } from 'react';

import { useClassNames } from '../../utils/cssUtils/useClassNames';
import styles from './PanelLayout.module.scss';

import Panel from './Panel';

export interface IPanelLayoutProps {
  columns?: number;
  stackOnTablet?: boolean;
  children: JSX.Element[] | JSX.Element;
  flexWidths?: number[];
  additionalColumnStyles?: string;
}

const PanelLayout = (props: IPanelLayoutProps) => {
  const { columns = 1, stackOnTablet = false, children, flexWidths = [], additionalColumnStyles = '' } = props;
  const panelColumns: JSX.Element[][] = [];
  for (let column = 0; column < columns; column++) {
    panelColumns.push([]);
  }

  // find Panels as either direct child elements or wrapped in a fragment
  const childElements = Array.isArray(children) ? children : [children];
  const panels = childElements.filter((child) => !!child && child.type === Panel);
  const childFragments = childElements.filter((child) => !!child && child.type === Fragment);
  const panelsInFragments = childFragments.flatMap((fragment) => {
    const childrenOfFragment = Array.isArray(fragment.props.children)
      ? fragment.props.children
      : [fragment.props.children];
    return childrenOfFragment.filter((fragmentChild: JSX.Element) => !!fragmentChild && fragmentChild.type === Panel);
  });
  panelsInFragments.forEach((item) => panels.push(item));

  // put any panels with an explicit column property in the appropriate column
  panels
    .filter((panel) => panel.props.column < columns)
    .forEach((panel) => panelColumns[panel.props.column].push(panel));

  // spread remaining panels evenly across columns
  const remainingPanels = panels.filter((panel) => !(panel.props.column < columns));
  const panelsPerColumn = columns === 0 ? 0 : Math.ceil(remainingPanels.length / columns);
  for (let column = 0; column < columns; column++) {
    const firstPanelIndex = column * panelsPerColumn;
    const lastPanelIndex = firstPanelIndex + panelsPerColumn;
    panelColumns[column] = panelColumns[column].concat(remainingPanels.slice(firstPanelIndex, lastPanelIndex));
  }

  // apply column class names to each column, singling out the main column for and additional class if required
  const columnClassName = useClassNames(
    [
      {
        column: true,
        columnStacked: stackOnTablet,
      },
      additionalColumnStyles,
    ],
    styles
  );

  // the layout will only stack the columns in mobile view, but the stackOnTablet will allow stacking to apply to tablet view as well
  const containerClassName = useClassNames(
    {
      container: true,
      containerStacked: stackOnTablet,
    },
    styles
  );

  return (
    <>
      <div className={containerClassName}>
        {panelColumns.map((columnPanels, index) => {
          const flexWidth = index < flexWidths.length ? { flexBasis: `${flexWidths[index]}%` } : undefined;

          return (
            <div className={columnClassName} key={`column-${index}`} style={flexWidth}>
              {columnPanels}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default PanelLayout;
