import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/store';
import { IPromoCodeSummary } from './initialState';

export const promoCodeSummarySelector = createSelector(
  (state: RootState) => {
    return state;
  },
  ({ session, reports: { promoCodeSummary } }: RootState): IPromoCodeSummary & { contextId: string } => {
    return {
      contextId: session.contextSession.id,
      ...promoCodeSummary,
    };
  }
);

export const promoCodeHeaderColumnsSelector = createSelector(
  (state: RootState) => state,
  (state) => state.reports.promoCodeSummary.headerColumns
);
