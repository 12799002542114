import { toastErrorState } from '../../redux/actions/uiActions/responseStateActions';
import TagsBadgesService from '../../tagsBadges/services/TagsBadgesService';

export const actionTypes = {
  GET_BADGES_LIST_BEGIN: 'GET_BADGES_LIST_BEGIN',
  GET_BADGES_LIST_SUCCESS: 'GET_BADGES_LIST_SUCCESS',
  GET_BADGES_LIST_ERROR: 'GET_BADGES_LIST_ERROR',
};

export const getBadges = (companyId: string, locationId?: string) => async (dispatch: any) => {
  try {
    dispatch({ type: actionTypes.GET_BADGES_LIST_BEGIN });
    const badgesList = await TagsBadgesService.getBadgesList(companyId, locationId);
    dispatch({ type: actionTypes.GET_BADGES_LIST_SUCCESS, payload: badgesList });
  } catch (error) {
    toastErrorState(error.status, error.message);
    dispatch({
      type: actionTypes.GET_BADGES_LIST_ERROR,
    });
  }
};
