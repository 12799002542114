import React from 'react';
import styles from './app-layout.module.scss';
import SidebarUser from './SidebarUser';
import SidebarNavigation from './SidebarNavigation';
import { AppInfo } from './Sidebar/components/AppInfo';
import { checkFeatureToggle } from 'utils/featureToggle/featureToggle';

export interface SidebarProps {
  sidebarVisible: boolean;
  toggleSidebar: (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => void;
}

const Sidebar = (props: SidebarProps) => {
  const { sidebarVisible, toggleSidebar } = props;

  const layoutClassName = sidebarVisible ? styles.sidebarVisible : styles.sidebar;

  return (
    <div className={layoutClassName}>
      <SidebarUser toggleSidebar={toggleSidebar} />
      <SidebarNavigation />
      {checkFeatureToggle('app_info') && <AppInfo />}
    </div>
  );
};

export default Sidebar;
