import { IPagination } from '@ready/dashboardv2api.contracts';
import { ISchedule } from '@ready/menu.core';

export interface ScheduleListState {
  schedules: Array<ISchedule>;
  pagination: IPagination;
  loading: boolean;
}

export const initialScheduleListState: ScheduleListState = {
  schedules: [],
  pagination: {
    length: 0,
    limit: 0,
    start: 0,
    total: 0,
  },
  loading: false,
};
