import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { DigitalMenuLayoutContent } from './DigitalMenuLayoutContent';
import TablesLayoutContent from '../TablesDetails/TablesLayoutContent';

import { TablesTab } from '../../../enums/tables.enum';
import { ContextParams } from '../../../types/ContextParams.interface';
import { getClientDigitalMenuConfig, resetClientDigitalMenuConfig } from '../../redux/actions/digitalMenuActions';
import { selectClientDigitalMenu } from '../../redux/selectors/LocationSelector';
import { pageErrorState } from '../../../redux/actions/uiActions/responseStateActions';

export const DigitalMenuPage = () => {
  const { contextId: companyId, locationId } = useParams<ContextParams>();
  const dispatch = useDispatch();
  const { location, error } = useSelector(selectClientDigitalMenu);
  const locationSettings = location?.settings;

  React.useEffect(() => {
    if (!error && locationId) {
      dispatch(getClientDigitalMenuConfig(companyId, locationId));
    }
    return () => {
      dispatch(resetClientDigitalMenuConfig());
    };
  }, [error, locationId, dispatch, companyId]);

  React.useEffect(() => {
    if (!locationSettings?.digitalMenuFeatureEnabled) {
      dispatch(pageErrorState(401));
    }
  }, [locationSettings, dispatch]);

  const locationName = location && location?.id === locationId ? location.name : '';

  return (
    <TablesLayoutContent
      companyId={companyId}
      locationId={locationId}
      locationName={locationName}
      tab={TablesTab.DigitalMenu}
    >
      <DigitalMenuLayoutContent />
    </TablesLayoutContent>
  );
};
