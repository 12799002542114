import React from 'react';
import styles from './ModifierGroupOption.module.scss';
import MenuItemNameBlock from '../ItemsAndMods/MenuItemNameBlock';
import {
  IEmbeddedModifierItem,
  ITemplateEmbeddedModifierItem,
  MenuItemInactiveStates,
  Status,
  Visibility,
} from '@ready/menu.core';
import MenuItemPosBlock from '../ItemsAndMods/MenuItemPosBlock';
import MenuItemPriceBlock from '../ItemsAndMods/MenuItemPriceBlock';
import MenuItemIconsBlock from '../ItemsAndMods/MenuItemIconsBlock';
import ModifierMaxValue from './ModifierMaxValue';
import MenuItemIsDefaultBlock from '../ItemsAndMods/MenuItemIsDefaultBlock';
import { IModifierOptionsFormValidation } from '../../redux/ModifierGroupsState';
import { compositeStyles } from '../../../utils/cssUtils';
import SimpleExpander from './SimpleExpander';
import NestedModifierGroup from './NestedModifierGroup';
import OptionalLink from '../../../components/OptionalLink/OptionalLink';
import { Routes } from '../../MenuBuilderRouter';
import { SharedMenuItemsRoutes } from 'sharedMenuItems/Router';

interface ModifierGroupOptionProps {
  companyId: string;
  locationId?: string;
  rootModifierGroupId: string;
  option: IEmbeddedModifierItem | ITemplateEmbeddedModifierItem;
  allowMultiplesOfEachOption: boolean;
  defaultMaxValue: number | null;
  processing: boolean;
  editing: boolean;
  menuItemId?: string;
  suppressLinking?: boolean;
  compactView?: boolean;
  nestingLevel?: number;
  allowExpansion?: boolean;
  externalContainer?: boolean;
  modifierOptionsValidation?: IModifierOptionsFormValidation;
  control?: React.ReactNode;
  onChangeMaxValue?: (id: string, constraints: number) => void;
  onDefaultChanged?: (id: string, isDefault: boolean) => void;
  isReadonly?: boolean;
}

const ModifierGroupOption = ({
  companyId,
  locationId,
  rootModifierGroupId,
  option,
  allowMultiplesOfEachOption,
  defaultMaxValue,
  processing,
  editing,
  menuItemId,
  suppressLinking = false,
  compactView = false,
  nestingLevel = 0,
  allowExpansion = false,
  externalContainer = false,
  modifierOptionsValidation,
  control,
  onChangeMaxValue,
  onDefaultChanged,
  isReadonly = false,
}: ModifierGroupOptionProps): JSX.Element => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const onChange = (isExpanded: boolean): void => {
    setIsExpanded(isExpanded);
  };

  const maxValueError =
    !!modifierOptionsValidation &&
    (modifierOptionsValidation.constraints?.options.includes(option.itemId) ||
      modifierOptionsValidation.noNegativeValue?.options.includes(option.itemId));
  let maxValueErrorMsg = modifierOptionsValidation?.constraints?.errorMessage;
  if (!!modifierOptionsValidation && modifierOptionsValidation.noNegativeValue?.errorMessage) {
    const separator = !!maxValueErrorMsg ? ', ' : '';
    maxValueErrorMsg = `${maxValueErrorMsg}${separator}${modifierOptionsValidation.noNegativeValue.errorMessage}`;
  }

  const linkTo =
    editing || suppressLinking
      ? undefined
      : !!menuItemId
      ? locationId
        ? Routes.getMenuItemPage(companyId, locationId, option.itemId)
        : SharedMenuItemsRoutes.getItemAndModsItem(companyId, option.itemId)
      : locationId
      ? Routes.getModifierGroupItemPage(companyId, locationId, rootModifierGroupId, option.itemId)
      : SharedMenuItemsRoutes.getModifierGroupItemPage(companyId, rootModifierGroupId, option.itemId);

  // This indicates whether or not this option is expandable, which is true if it has any nested groups. There is also
  // a separate property called allowExpansion. This option is inside a modifier group, and it's possible that other
  // options within that modifier group have nested groups, even if this one does not. The allowExpansion property
  // indicates that at least one of the options, whether it's this one or some sibling option, has nested groups. We
  // need to know that in here so that we can include an indentation to line up with the other options, even if this
  // option does not have a visible expander.
  const isExpandable = !!option.nestedGroups && !!option.nestedGroups.length;

  const status = 'status' in option ? option.status : undefined;
  const price = 'price' in option ? option.price : undefined;
  const inactiveStates = 'inactiveStates' in option ? option.inactiveStates : [];
  const isPopular = 'isPopular' in option ? option.isPopular : undefined;
  const schedule = 'schedule' in option ? option.schedule : undefined;
  const posItemName = 'posItemName' in option ? option.posItemName : undefined;
  const posItemCategories = 'posItemCategories' in option ? option.posItemCategories : [];

  const nestedGroups = option.nestedGroups ?? [];

  return (
    <>
      <div
        className={compositeStyles(
          [
            { rootOption: nestingLevel === 0 },
            { rootOptionIndented: nestingLevel === 0 && !editing && !suppressLinking },
            { option: (editing || suppressLinking) && nestingLevel === 0 && !externalContainer },
            { externalContainer: externalContainer },
            { optionNested: nestingLevel > 0 && !(isExpandable && isExpanded) },
            { optionNestedWithGroups: nestingLevel > 0 && isExpandable && isExpanded },
            { linkable: !editing && !suppressLinking && !!linkTo && nestingLevel > 0 },
            { compactView: compactView, isReadonly: isReadonly },
          ],
          styles
        )}
      >
        <div
          className={compositeStyles(
            [
              styles.expanderAndName,
              { level1: nestingLevel === 1 },
              { level2: nestingLevel === 2 },
              { level3: nestingLevel === 3 },
              { level4: nestingLevel === 4 },
            ],
            styles
          )}
        >
          <div
            className={compositeStyles(
              [
                { expanderLevel0: allowExpansion && nestingLevel === 0 },
                { expanderLevel1: allowExpansion && nestingLevel === 1 },
                { expanderLevel2: allowExpansion && nestingLevel === 2 },
                { expanderLevel3: allowExpansion && nestingLevel === 3 },
                { expanderLevel4: allowExpansion && nestingLevel === 4 },
                { indenterLevel1: !allowExpansion && nestingLevel === 1 },
                { indenterLevel2: !allowExpansion && nestingLevel === 2 },
                { indenterLevel3: !allowExpansion && nestingLevel === 3 },
                { indenterLevel4: !allowExpansion && nestingLevel === 4 },
                { compactView: compactView },
              ],
              styles
            )}
          >
            {allowExpansion && (
              <div className={styles.expander}>
                {isExpandable && <SimpleExpander isExpanded={isExpanded} onChange={onChange} />}
              </div>
            )}
          </div>

          <OptionalLink to={linkTo} className={styles.name}>
            <MenuItemNameBlock
              displayName={option.displayName}
              description={option.description}
              disabled={status === Status.disabled}
              hidden={option.visibility === Visibility.hidden}
              compactView={compactView}
              className={styles.nameBlock}
            />

            <div className={styles.mobileDetailsContainer}>
              <MenuItemPriceBlock
                price={price}
                deleted={inactiveStates.includes(MenuItemInactiveStates.priceDeletedOnPOS)}
                disabled={status === Status.disabled}
                hidden={option.visibility === Visibility.hidden}
                className={styles.price}
              />

              <MenuItemIconsBlock
                isPopular={isPopular}
                hasSchedule={!!schedule}
                containsAlcohol={option.alcohol?.containsAlcohol}
                alcoholAmount={option.alcohol?.quantity?.value}
                className={styles.icons}
              />

              <div className={styles.maxAndDefault}>
                {allowMultiplesOfEachOption ? (
                  <ModifierMaxValue
                    value={option.constraints?.max}
                    processing={processing}
                    itemId={option.itemId}
                    editing={editing}
                    className={editing ? styles.isMaxValueEditMode : styles.isMaxValue}
                    onChange={onChangeMaxValue}
                    hasError={maxValueError}
                    errorMessage={maxValueErrorMsg}
                  />
                ) : null}

                <MenuItemIsDefaultBlock
                  isDefault={option.isDefault ?? false}
                  itemId={option.itemId}
                  editing={editing}
                  className={editing ? styles.isDefaultEditMode : styles.isDefault}
                  onDefaultChanged={onDefaultChanged}
                  hasError={modifierOptionsValidation?.isDefault.hasError}
                />
              </div>
            </div>
          </OptionalLink>
        </div>

        <OptionalLink
          to={linkTo}
          className={compositeStyles([styles.detailsContainer, { withControlSpace: !compactView }], styles)}
        >
          <MenuItemPosBlock
            id={option.posItemId}
            name={posItemName ?? ''}
            categories={posItemCategories ?? []}
            draft={inactiveStates.includes(MenuItemInactiveStates.draft)}
            deleted={inactiveStates.includes(MenuItemInactiveStates.itemDeletedOnPOS)}
            disabled={status === Status.disabled}
            hidden={option.visibility === Visibility.hidden}
            compactView={compactView}
            className={styles.pos}
          />

          <MenuItemPriceBlock
            price={price}
            deleted={inactiveStates.includes(MenuItemInactiveStates.priceDeletedOnPOS)}
            disabled={status === Status.disabled}
            hidden={option.visibility === Visibility.hidden}
            className={styles.price}
          />

          <MenuItemIconsBlock
            isPopular={isPopular}
            hasSchedule={!!schedule}
            containsAlcohol={option.alcohol?.containsAlcohol}
            alcoholAmount={option.alcohol?.quantity?.value}
            className={styles.icons}
          />
          {allowMultiplesOfEachOption ? (
            <ModifierMaxValue
              value={option.constraints?.max}
              processing={processing}
              itemId={option.itemId}
              editing={editing}
              className={editing ? styles.isMaxValueEditMode : styles.isMaxValue}
              onChange={onChangeMaxValue}
              hasError={maxValueError}
              errorMessage={maxValueErrorMsg}
            />
          ) : (
            <div className={styles.isMaxValue} />
          )}
          <MenuItemIsDefaultBlock
            isDefault={option.isDefault ?? false}
            itemId={option.itemId}
            editing={editing}
            className={editing ? styles.isDefaultEditMode : styles.isDefault}
            onDefaultChanged={onDefaultChanged}
            hasError={modifierOptionsValidation?.isDefault.hasError}
          />
        </OptionalLink>

        {control}
      </div>

      {isExpanded &&
        nestedGroups.map((nestedGroup) => (
          <div
            key={`${option.itemId}-${nestedGroup._id}`}
            className={compositeStyles(
              [{ rootNestedMods: nestingLevel === 0 && !editing && !suppressLinking }, { compactView }],
              styles
            )}
          >
            <NestedModifierGroup
              companyId={companyId}
              locationId={locationId}
              rootModifierGroupId={rootModifierGroupId}
              nestedGroup={nestedGroup}
              nestingLevel={nestingLevel + 1}
              processing={processing}
              editing={editing}
              suppressLinking={suppressLinking}
              compactView={compactView}
            />
          </div>
        ))}
    </>
  );
};

export default ModifierGroupOption;
