import React from 'react';
import styles from './TextBubble.module.scss';
import { useClassNames } from '../../utils/cssUtils';

type Color = 'gray' | 'black';
type Size = 'small' | 'large';
export type Position = 'top' | 'right' | 'bottom' | 'left';

const defaultColor: Color = 'gray';
const defaultSize: Size = 'large';
const defaultLocation: Position = 'bottom';
const defaultAlignment: Position = 'left';

interface TextBubbleProps {
  color?: Color;
  arrowSize?: Size;
  arrowLocation?: Position;
  arrowAlignment?: Position;
  children?: React.ReactNode;
}

const TextBubble = ({
  color = defaultColor,
  arrowSize = defaultSize,
  arrowLocation = defaultLocation,
  arrowAlignment = defaultAlignment,
  children,
}: TextBubbleProps): JSX.Element => {
  const containerClassName = useClassNames(
    [styles.container, { vertical: arrowLocation === 'top' || arrowLocation === 'bottom' }],
    styles
  );

  const topArrowClassName = useClassNames(
    [
      styles.arrow,
      { small: arrowSize === 'small' },
      { large: arrowSize === 'large' },
      { gray: color === 'gray' },
      { black: color === 'black' },
      { top: arrowLocation === 'top' },
      { left: arrowLocation === 'left' },
      { atStart: arrowAlignment === 'left' || arrowAlignment === 'top' },
      { atEnd: arrowAlignment === 'right' || arrowAlignment === 'bottom' },
    ],
    styles
  );

  const bodyClassName = useClassNames([styles.body, { gray: color === 'gray' }, { black: color === 'black' }], styles);

  const bottomArrowClassName = useClassNames(
    [
      styles.arrow,
      { small: arrowSize === 'small' },
      { large: arrowSize === 'large' },
      { gray: color === 'gray' },
      { black: color === 'black' },
      { bottom: arrowLocation === 'bottom' },
      { right: arrowLocation === 'right' },
      { atStart: arrowAlignment === 'left' || arrowAlignment === 'top' },
      { atEnd: arrowAlignment === 'right' || arrowAlignment === 'bottom' },
    ],
    styles
  );

  return (
    <span className={containerClassName}>
      {(arrowLocation === 'top' || arrowLocation === 'left') && <span className={topArrowClassName} />}
      <span className={bodyClassName}>{children}</span>
      {(arrowLocation === 'bottom' || arrowLocation === 'right') && <span className={bottomArrowClassName} />}
    </span>
  );
};

export default TextBubble;
