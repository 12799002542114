import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '../../components/Modal';
import { ModalProps } from '../../components/Modal/Modal';
import { FormControl } from '../../components/Form';
import TextInput from '../../components/TextInput/TextInput';
import ColorPicker from '../../components/ColorPicker/ColorPicker';
import BadgePreview from './BadgePreview';
import {
  updateBadgeName,
  updateBadgeText,
  updateBadgeTextColor,
  updateBackgroundColor,
} from '../redux/TagsBadgesListActions';
import styles from './BadgeFormModal.module.scss';
import { IBadgeForm } from '../redux/TagsBadgesListState';

export interface BadgeFormModalProps extends Pick<ModalProps, 'setShowModal'> {
  setShowModal: (visible: boolean) => void;
  header: string;
  onSave: () => void;
  saveLabel: string;
  badgeForm: IBadgeForm;
}

const BadgeFormModal = (props: BadgeFormModalProps) => {
  const { setShowModal, header, onSave, badgeForm, saveLabel } = props;

  const dispatch = useDispatch();

  return (
    <Modal setShowModal={setShowModal}>
      <ModalHeader headerLabel={header} setShowModal={setShowModal} />
      <ModalBody>
        <FormControl
          label='Badge Name *'
          bold
          labelTooltip='This name is for internal reference only and will not be displayed to your guests.'
          withError={badgeForm.validation.badgeName.hasError}
          errorMessage={badgeForm.validation.badgeName.errorMessage}
        >
          <TextInput
            placeholder='e.g. Popular'
            onChange={(e) => dispatch(updateBadgeName(e.target.value))}
            value={badgeForm.badge.name}
            withError={badgeForm.validation.badgeName.hasError}
          />
        </FormControl>
        <FormControl
          label='Badge Text *'
          bold
          withError={badgeForm.validation.badgeText.hasError}
          errorMessage={badgeForm.validation.badgeText.errorMessage}
        >
          <TextInput
            placeholder='e.g. Popular'
            onChange={(e) => dispatch(updateBadgeText(e.target.value))}
            value={badgeForm.badge.text}
            withError={badgeForm.validation.badgeText.hasError}
          />
        </FormControl>

        <div className={styles.colorControls}>
          <FormControl label='Text Colour' bold>
            <ColorPicker
              colourTextValue={badgeForm.badge.textColor}
              colourWheelValue={badgeForm.badge.textColor}
              onChange={(color) => dispatch(updateBadgeTextColor(color))}
            />
          </FormControl>

          <FormControl label='Background Colour' bold additionalStyles={styles.backgroundColorControl}>
            <ColorPicker
              colourTextValue={badgeForm.badge.backgroundColor}
              colourWheelValue={badgeForm.badge.backgroundColor}
              onChange={(color) => dispatch(updateBackgroundColor(color))}
            />
          </FormControl>
        </div>
        <FormControl label='Preview' bold>
          <div className={styles.preview}>
            <div className={styles.badgePreviewWrapper}>
              <BadgePreview
                backgroundColour={badgeForm.badge.backgroundColor || '#000000'}
                textColour={badgeForm.badge.textColor || '#FFFFFF'}
                badgeText={badgeForm.badge.text || 'Untitled'}
              />
            </div>
          </div>
        </FormControl>
      </ModalBody>
      <ModalFooter
        loading={false}
        primaryLabel={saveLabel}
        primaryActionHandler={onSave}
        secondaryLabel='Cancel'
        secondaryActionHandler={() => setShowModal(false)}
      />
    </Modal>
  );
};

export default BadgeFormModal;
