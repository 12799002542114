import { ITemplateEmbeddedModifierItem, ITemplateModifierGroup } from '@ready/menu.core';
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { getSharedModifierGroupThunk, saveSharedModifierGroupThunk } from './thunks';

interface State {
  modifierGroup: ITemplateModifierGroup;
  modifierGroupCache: ITemplateModifierGroup;
  loading: boolean;
  processing: boolean;
}

const newModifierGroup: ITemplateModifierGroup = {
  _id: '',
  options: [],
  name: '',
  constraints: {},
};

const initialState: State = {
  modifierGroup: newModifierGroup,
  modifierGroupCache: newModifierGroup,
  loading: true,
  processing: false,
};

const modifierGroupDetailsSlice = createSlice({
  name: 'sharedMenuItems/modifierGroups/details',
  initialState: initialState,
  reducers: {
    updateModifierGroup: (state: State, action: { payload: Partial<ITemplateModifierGroup> }) => {
      state.modifierGroup = { ...(state.modifierGroup ?? newModifierGroup), ...action.payload };
    },
    resetModifierGroup: (state: State) => {
      state.modifierGroup = { ...(state.modifierGroupCache ?? newModifierGroup) };
    },
    updateModifierGroupOption: (state: State, action: { payload: Partial<ITemplateEmbeddedModifierItem> }) => {
      const options = state.modifierGroup?.options;

      if (options) {
        const index = options.findIndex((x) => x.itemId === action.payload.itemId);

        if (index !== undefined && index !== -1) {
          options[index] = { ...options[index], ...action.payload };
        }
      }
    },
    addModifierGroupOption: (state: State, action: { payload: ITemplateEmbeddedModifierItem }) => {
      const options = state.modifierGroup?.options;

      if (options && action.payload) {
        options.push({ ...action.payload, sortOrder: options.length });
      }
    },
    removeModifierGroupOption: (state: State, action: { payload: string }) => {
      const index = state.modifierGroup?.options.findIndex((x) => x.itemId === action.payload);

      if (index !== undefined && index !== -1) state.modifierGroup?.options.splice(index, 1);
    },
    moveModifierGroupOption: (state: State, action: { payload: { oldIndex: number; newIndex: number } }) => {
      // same as location mod group, sharable.

      const optionsForMove = state.modifierGroup?.options;

      if (optionsForMove) {
        const movedOption = optionsForMove.splice(action.payload.oldIndex, 1)[0];
        optionsForMove?.splice(action.payload.newIndex, 0, movedOption);
        optionsForMove?.forEach((option, index) => (option.sortOrder = index));
      }
    },
    addNestedModifierGroupToOption: (
      state: State,
      action: { payload: { parentOptionId: string; nestedModGroup: ITemplateModifierGroup } }
    ) => {
      const parentOption = state.modifierGroup?.options.find((x) => x.itemId === action.payload.parentOptionId);

      if (parentOption) {
        parentOption.nestedGroups = parentOption.nestedGroups ?? [];
        parentOption.nestedGroups.push({
          ...action.payload.nestedModGroup,
          sortOrder: parentOption.nestedGroups.length,
        });
      }
    },
    removeNestedModifierGroupToOption: (
      state: State,
      action: { payload: { parentOptionId: string; nestedModGroupId: string } }
    ) => {
      const parentOption = state.modifierGroup?.options.find((x) => x.itemId === action.payload.parentOptionId);

      if (parentOption) {
        if (parentOption.nestedGroups) {
          parentOption.nestedGroups = parentOption.nestedGroups.filter(
            (x) => x._id !== action.payload.nestedModGroupId
          );
        }
      }
    },
    moveNestedModifierGroupToOption: (
      state: State,
      action: { payload: { optionId: string; sourceIndex: number; destinationIndex: number } }
    ) => {
      const optionToReorder = state.modifierGroup.options.find((x) => x.itemId === action.payload.optionId);

      if (optionToReorder && optionToReorder.nestedGroups) {
        const movedModifierOption = optionToReorder.nestedGroups.splice(action.payload.sourceIndex, 1)[0];
        optionToReorder.nestedGroups.splice(action.payload.destinationIndex, 0, movedModifierOption);
        optionToReorder.nestedGroups.forEach((option, index) => (option.sortOrder = index));
      }
    },
    resetState: () => initialState,
  },
  extraReducers: (builder: ActionReducerMapBuilder<State>) => {
    builder.addCase(getSharedModifierGroupThunk.pending, (state: State) => {
      state.loading = true;
    });
    builder.addCase(getSharedModifierGroupThunk.fulfilled, (state: State, action) => {
      state.loading = false;
      state.modifierGroup = state.modifierGroupCache = action.payload;
    });

    builder.addCase(saveSharedModifierGroupThunk.pending, (state: State) => {
      state.processing = true;
    });

    builder.addCase(saveSharedModifierGroupThunk.fulfilled, (state: State, action) => {
      state.processing = false;
      if (action.payload) {
        state.modifierGroup = state.modifierGroupCache = action.payload;
      }
    });
  },
});

export const {
  updateModifierGroup,
  resetModifierGroup,
  updateModifierGroupOption,
  removeModifierGroupOption,
  addModifierGroupOption,
  moveModifierGroupOption,
  addNestedModifierGroupToOption,
  removeNestedModifierGroupToOption,
  moveNestedModifierGroupToOption,
  resetState,
} = modifierGroupDetailsSlice.actions;

export { modifierGroupDetailsSlice };
