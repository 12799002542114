import { IScheduleLink, Status } from '@ready/menu.core';
import { Form, FormControl } from 'components/Form';
import TextIcon, { Icon } from 'components/Icon/TextIcon';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'components/Modal';
import Toggle from 'components/Toggle/Toggle';
import ScheduleFormControl from 'menus/components/shared/ScheduleFormControl';
import { ILink } from 'menus/redux/MenusState';
import React, { useEffect, useState } from 'react';
import { getScheduleList } from 'menus/redux/ScheduleListActions';
import { useParams } from 'react-router-dom';
import { ContextParams } from 'types/ContextParams.interface';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { IBareScheduleReference } from '@ready/menu.core';
import Checkbox from 'components/Checkbox/Checkbox';
import styles from './EditLocationSettingsModal.module.scss';

export interface IEditLocationSettingsModalFields {
  status?: Status;
  schedule?: IScheduleLink<IBareScheduleReference>;
}

interface Props {
  title: string;

  setShowModal: (show: boolean) => void;
  onSave: (updates: IEditLocationSettingsModalFields) => Promise<void>;

  bulkEditCount?: number;
  locationName?: string;
  locationSettings?: IEditLocationSettingsModalFields;
  hiddenFields?: (keyof IEditLocationSettingsModalFields)[];
}

type SelectableFields = { [P in keyof Required<IEditLocationSettingsModalFields>]: boolean };

export const EditLocationSettingsModal = ({
  title,
  bulkEditCount = 0,
  setShowModal,
  locationName,
  locationSettings,
  onSave,
  hiddenFields = [],
}: Props) => {
  const isBulkEdit = bulkEditCount > 0;
  const dispatch = useAppDispatch();
  const { contextId: companyId } = useParams<ContextParams & { menuGroupId: string }>();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [updates, setUpdates] = useState<Partial<IEditLocationSettingsModalFields>>({
    schedule: locationSettings?.schedule,
    status: locationSettings?.status,
  });

  const { schedules } = useAppSelector((state) => state.menuBuilder.scheduleList);
  useEffect(() => {
    dispatch(getScheduleList(companyId));
  }, [companyId, dispatch]);

  const defaultEnabledFieldsValue = !isBulkEdit;
  const [enabledFields, setEnabledFields] = useState<SelectableFields>({
    status: defaultEnabledFieldsValue,
    schedule: defaultEnabledFieldsValue,
  });

  return (
    <Modal setShowModal={setShowModal}>
      <ModalHeader
        headerLabel={title}
        setShowModal={setShowModal}
        subLabel={isBulkEdit ? `(${bulkEditCount} locations)` : undefined}
        additionalStyles={styles.header}
        additionalSubLabelStyles={styles.sublabel}
        subHeader={
          isBulkEdit ? 'Select the fields you would like to edit. Unselected fields will remain unchanged.' : undefined
        }
      />
      <ModalBody>
        <Form hasGroups isModalForm>
          {locationName && (
            <FormControl label='Location'>
              <TextIcon icon={Icon.Location} />
              {locationName}
            </FormControl>
          )}
          <div className={styles.formRow}>
            {isBulkEdit && (
              <Checkbox
                label={''}
                checked={enabledFields.status}
                onChange={() => {
                  setEnabledFields((prev) => ({ ...prev, status: !prev.status }));
                  // if checkbox is checked, set status value to be disabled.
                  setUpdates((prev) => ({
                    ...prev,
                    status: prev.status || Status.disabled,
                  }));
                }}
              />
            )}
            <FormControl label='Status' disabled={!enabledFields.status}>
              <Toggle
                disabled={!enabledFields.status}
                checked={updates.status === Status.enabled ? true : false}
                onChange={(checked: boolean): void => {
                  setUpdates((prev) => ({ ...prev, status: checked ? Status.enabled : Status.disabled }));
                }}
              />
            </FormControl>
          </div>
          {!hiddenFields.includes('schedule') && (
            <div className={styles.formRow}>
              {isBulkEdit && (
                <Checkbox
                  label={''}
                  checked={enabledFields.schedule}
                  onChange={() => setEnabledFields((prev) => ({ ...prev, schedule: !prev.schedule }))}
                />
              )}
              <FormControl label='Schedule' disabled={!enabledFields.schedule}>
                <ScheduleFormControl
                  opened={true}
                  disabled={!enabledFields.schedule}
                  fullWidth
                  onChange={(scheduler: IScheduleLink<ILink> | null): void => {
                    setUpdates((prev) => ({
                      ...prev,
                      schedule: scheduler ?? undefined,
                    }));
                  }}
                  scheduleLink={updates.schedule}
                  schedules={schedules}
                  validation={{ displayName: { hasError: false, errorMessage: '' } }}
                  isInModal
                />
              </FormControl>
            </div>
          )}
        </Form>
      </ModalBody>
      <ModalFooter
        primaryLabel='Save Changes'
        primaryActionHandler={async (): Promise<void> => {
          setIsSaving(true);

          if (isBulkEdit) {
            // if bulk editing, delete the properties that are disabled.
            for (const [key, value] of Object.entries(enabledFields)) {
              if (value === false && updates.hasOwnProperty(key)) {
                delete updates[key as keyof IEditLocationSettingsModalFields];
              }
            }
          }
          await onSave(updates);
          setShowModal(false);
        }}
        loading={isSaving}
        secondaryLabel='Cancel'
        secondaryActionHandler={() => setShowModal(false)}
      />
    </Modal>
  );
};
