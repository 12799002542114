import React from 'react';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';

import {
  loadPromoCode,
  loadPromoCodePosDiscounts,
  updatePromoCodeStatus,
  updatePromoCodeName,
  updatePromoCodeDescription,
  updatePromoCodePosDiscount,
  updatePromoCodeValidation,
  updatePromoCodeAutoAttach,
  updatePromoCodeTimeSlot,
  updatePromoCodeAddTimeSlot,
  updatePromoCodeRemoveTimeSlot,
  validatePromoCode,
  savePromoCode,
  resetPromoCodeForm,
} from '../../redux/actions/promoCodeActions';
import { AppState } from '../../../redux/initialStates/AppState';

import LayoutContent from '../../../components/AppLayout/LayoutContent';
import PromoCodeForm from '../../../locations/components/LocationPromoCodes/PromoCodeForm';
import { useHistory, useParams } from 'react-router-dom';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';

import { selectPermissions } from '../../../redux/selectors/sessionSelectors/sessionSelectors';
import {
  PromoCodesResourceActions,
  PrincipalPermissions,
  ResourceType,
  SecurityScope,
  Verifier,
} from '@ready/security.core';
import { pageErrorState } from '../../../redux/actions/uiActions/responseStateActions';

const mapStateToProps = (state: AppState) => ({
  loading: state.locationPromoCodes.form.loading,
  promoCode: state.locationPromoCodes.form.promoCode,
  processing: state.locationPromoCodes.form.processing,
  discounts: state.locationPromoCodes.form.discounts,
  validated: state.locationPromoCodes.form.validated,
  saved: state.locationPromoCodes.form.saved,
  validation: state.locationPromoCodes.form.validation,
});

const actionCreators = {
  loadPromoCode,
  loadPromoCodePosDiscounts,
  updatePromoCodeStatus,
  updatePromoCodeName,
  updatePromoCodeDescription,
  updatePromoCodePosDiscount,
  updatePromoCodeValidation,
  updatePromoCodeAutoAttach,
  updatePromoCodeTimeSlot,
  updatePromoCodeAddTimeSlot,
  updatePromoCodeRemoveTimeSlot,
  validatePromoCode,
  savePromoCode,
  resetPromoCodeForm,
};

const connector = connect(mapStateToProps, actionCreators);

type NewPromoCodePageProps = ConnectedProps<typeof connector>;

const NewPromoCodePage = (props: NewPromoCodePageProps): JSX.Element => {
  const {
    loading,
    promoCode,
    processing,
    discounts,
    validated,
    saved,
    validation,
    loadPromoCode,
    loadPromoCodePosDiscounts,
    updatePromoCodeStatus,
    updatePromoCodeName,
    updatePromoCodeDescription,
    updatePromoCodePosDiscount,
    updatePromoCodeValidation,
    updatePromoCodeAutoAttach,
    updatePromoCodeTimeSlot,
    updatePromoCodeAddTimeSlot,
    updatePromoCodeRemoveTimeSlot,
    validatePromoCode,
    savePromoCode,
    resetPromoCodeForm,
  } = props;
  const { contextId: companyId, locationId, promoCodeId: id } = useParams<{
    contextId: string;
    locationId: string;
    promoCodeId: string;
  }>();
  const dispatch = useDispatch();
  const { permissionsList } = useSelector(selectPermissions);

  // should only be on this page if user has edit permissions
  const hasPermissionAll = Verifier.check(
    new PrincipalPermissions(permissionsList),
    SecurityScope.location,
    ResourceType.promoCodes,
    PromoCodesResourceActions.all,
    locationId
  );

  if (!hasPermissionAll) {
    dispatch(pageErrorState(401));
  }
  const history = useHistory();

  const onSaveClick = (): void => {
    validatePromoCode(promoCode);
  };

  const onCancelClick = (): void => {
    history.push(`/companies/${companyId}/companyLocations/${locationId}/promo-codes/${id}`);
  };

  React.useEffect(() => {
    if (validated && !validation.hasErrors) {
      savePromoCode(locationId, promoCode, companyId);
    }
  }, [validated, validation, savePromoCode, locationId, promoCode, companyId]);

  React.useEffect(() => {
    if (saved) {
      history.push(`/companies/${companyId}/companyLocations/${locationId}/promo-codes/${id}`);
    }
  }, [saved, companyId, locationId, promoCode, history, id]);

  React.useEffect(() => {
    loadPromoCodePosDiscounts(locationId, companyId);
  }, [loadPromoCodePosDiscounts, locationId, companyId]);

  React.useEffect(() => {
    loadPromoCode(locationId, id, companyId);
    return () => {
      resetPromoCodeForm();
    };
  }, [loadPromoCode, locationId, id, resetPromoCodeForm, companyId]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <LayoutContent title='Edit Promo Code' containerType='within'>
      <PromoCodeForm
        promoCode={promoCode}
        processing={processing}
        discounts={discounts}
        validation={validation}
        onStatusChange={updatePromoCodeStatus}
        onNameChange={updatePromoCodeName}
        onDescriptionChange={updatePromoCodeDescription}
        onPosDiscountChange={updatePromoCodePosDiscount}
        onValidationChange={updatePromoCodeValidation}
        onAutoAttachChange={updatePromoCodeAutoAttach}
        onTimeSlotAdd={updatePromoCodeAddTimeSlot}
        onTimeSlotRemove={updatePromoCodeRemoveTimeSlot}
        onTimeSlotChange={updatePromoCodeTimeSlot}
        onSaveClick={onSaveClick}
        onCancelClick={onCancelClick}
      />
    </LayoutContent>
  );
};

export default connector(NewPromoCodePage);
