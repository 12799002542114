import { combineReducers } from 'redux';
import { cachedLocationReducer } from '../../../locations/redux/CachedLocationReducer';
import { cachedBillingReducer } from '../../../locations/redux/CachedBillingReducer';
import { cachedUserFormReducer } from './cachedUsersFormReducer';
import { cachedMenuItemReducer } from '../../../menus/redux/CachedMenuItemReducer';
import { cachedLoyaltyReducer } from '../../../locations/redux/CachedLoyaltyReducer';
import { cachedPromoCodesReducer } from '../../../locations/redux/CachedPromoCodesReducer';

export const cacheReducer = combineReducers({
  locationCache: cachedLocationReducer,
  billingCache: cachedBillingReducer,
  loyaltyCache: cachedLoyaltyReducer,
  promoCodesCache: cachedPromoCodesReducer,
  usersFormCache: cachedUserFormReducer,
  menuItemCache: cachedMenuItemReducer,
});
