import { ICompanyLoyaltyConfig } from '@ready/dashboardv2api.contracts';
import config from 'config';
import executeApiAsync from 'services/utils/executeApiAsync';

const BASE_URL = `${config.readyDashboardApiEndpoint}`;

export const getCompanyLoyaltyConfig = async (companyId: string): Promise<ICompanyLoyaltyConfig> => {
  const url = `${BASE_URL}/companies/${companyId}/loyaltyConfig`;
  return await executeApiAsync<ICompanyLoyaltyConfig>(url, {
    method: 'GET',
  });
};

export const saveCompanyLoyaltyConfig = async (
  companyId: string,
  config: ICompanyLoyaltyConfig
): Promise<ICompanyLoyaltyConfig> => {
  const url = `${BASE_URL}/companies/${companyId}/loyaltyConfig`;
  return await executeApiAsync<ICompanyLoyaltyConfig>(url, {
    method: 'POST',
    body: JSON.stringify(config),
  });
};
