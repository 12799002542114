import React, { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import SchedulesListPage from './components/Schedules/SchedulesListPage';
import CreateSchedulePage from './components/Schedules/CreateSchedulePage';
import EditSchedulePage from './components/Schedules/EditSchedulePage';
import { usePageTitle } from '../hooks';

const Router: FC<{}> = () => {
  const { path } = useRouteMatch();
  usePageTitle('Schedules');

  return (
    <Switch>
      <Route path={`${path}/new`} component={CreateSchedulePage} />
      <Route path={`${path}/:id/edit`} component={EditSchedulePage} />

      <Route path={path} component={SchedulesListPage} />
    </Switch>
  );
};

class Routes {
  public static getSchedulesListPageRoute(companyId: string) {
    return `/companies/${companyId}/schedules`;
  }

  public static getCreateSchedulesPageRoute(companyId: string) {
    return `/companies/${companyId}/schedules/new`;
  }

  public static getEditSchedulesPageRoute(companyId: string, scheduleId: string) {
    return `/companies/${companyId}/schedules/${scheduleId}/edit`;
  }
}

export default Router;
export { Routes };
