import React, { ChangeEvent } from 'react';
import styles from './LocationPanelDetails.module.scss';
import { FormGroup, FormControl } from '../../components/Form';
import Notification from '../../components/Notification/Notification';
import TextInput from '../../components/TextInput/TextInput';
import SelectFilter, { Option } from '../../components/SelectFilter/SelectFilter';
import Toggle from '../../components/Toggle/Toggle';
// utils
import { LIST_OPTIONS } from '../../utils/selectListUtils/listOptions';
import { getSelectedOption } from '../../utils/selectListUtils/listUtil';
import { LOCATION_SETTINGS_ACTIONS } from '../redux/LocationSettingsActions';
import {
  changeCountryOption,
  changeStateOption,
  changeTimezoneOption,
  changeCurrencyOption,
  changeLocaleOption,
} from '../../redux/actions/uiActions/uiActions';

import { useDispatch } from 'react-redux';
import { IDetailsPanelState, IDetailsPanelValidation } from '../redux/PanelDetailsState';
import { IUpdateLocationParam } from './LocationsEditPage';
import { Icon } from '../../components/Icon/TextIcon';

export interface Props {
  isProcessing: boolean;
  updateLocationParam: IUpdateLocationParam;
  detailsPanel: IDetailsPanelState;
  dropDown: any;
}

const LocationPanelDetails = (props: Props) => {
  const { isProcessing, updateLocationParam, detailsPanel, dropDown } = props;

  const dispatch = useDispatch();

  const handleSelectOptionChange = (option: Option, key: string) => {
    let newSelection;
    switch (key) {
      case 'country':
        newSelection = getSelectedOption(dropDown.country.options, option.value.toString(), key);
        dispatch(changeCountryOption(newSelection));
        break;
      case 'state':
        newSelection = getSelectedOption(dropDown.state.options, option.value.toString(), key);
        dispatch(changeStateOption(newSelection));
        break;
      case 'timezone':
        newSelection = getSelectedOption(LIST_OPTIONS.timezone, option.value.toString(), key);
        dispatch(changeTimezoneOption(newSelection));
        break;
      case 'currency':
        newSelection = getSelectedOption(LIST_OPTIONS.currency, option.value.toString(), key);
        dispatch(changeCurrencyOption(newSelection));
        break;
      case 'locale':
        newSelection = getSelectedOption(LIST_OPTIONS.locale, option.value.toString(), key);
        dispatch(changeLocaleOption(newSelection));
        break;
      default:
        console.log(`Cannot change option`);
        break;
    }
  };

  const validation: IDetailsPanelValidation = detailsPanel.validation;
  const nameValidation = validation.details.name;

  return (
    <>
      <FormGroup groupName='Location Details'>
        {nameValidation.hasError ? (
          <Notification>
            <p>
              <i className={Icon.RemoveCircle} />
              <span>Please complete all required fields</span>
            </p>
          </Notification>
        ) : (
          <div />
        )}
        <FormControl label='Enabled'>
          <Toggle
            checked={detailsPanel.details.enabled}
            onChange={(checked: boolean) => {
              updateLocationParam(checked, LOCATION_SETTINGS_ACTIONS.UPDATE_LOCATION_ENABLED);
            }}
            loading={isProcessing}
          />
        </FormControl>
        <FormControl label='Hidden'>
          <Toggle
            checked={detailsPanel.details.hidden ?? false}
            onChange={(checked: boolean) => {
              updateLocationParam(checked, LOCATION_SETTINGS_ACTIONS.UPDATE_LOCATION_HIDDEN);
            }}
            loading={isProcessing}
          />
        </FormControl>
        <FormControl label='Company'>
          <p>{detailsPanel.companyName}</p>
          <p>
            <strong>Ready ID:</strong> {detailsPanel.companyId}
          </p>
          <p>
            <strong>iQ ID:</strong> {detailsPanel.iqParentEntityId}
          </p>
        </FormControl>
        <FormControl label='Location ID'>
          <p>
            <strong>Ready ID:</strong> {detailsPanel.locationId}
          </p>
          <p>
            <strong>iQ ID:</strong> {detailsPanel.iqEntityId}
          </p>
        </FormControl>
        <FormControl label='Location Name *' errorMessage={nameValidation.error} withError={nameValidation.hasError}>
          <TextInput
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              updateLocationParam(e.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_LOCATION_NAME);
            }}
            placeholder={'Enter a location name'}
            value={detailsPanel.details.name || ''}
            withError={nameValidation.hasError}
            loading={isProcessing}
          />
        </FormControl>
        <FormControl label='Display Name'>
          <TextInput
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              updateLocationParam(e.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_LOCATION_PUBLIC_NAME);
            }}
            placeholder={'Enter a display name of the location'}
            value={detailsPanel.details.publicName}
            loading={isProcessing}
          />
        </FormControl>
        <FormControl label='Phone Number'>
          <TextInput
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              updateLocationParam(e.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_LOCATION_PHONE_NUMBER);
            }}
            placeholder={'Enter a phone number for the location'}
            value={detailsPanel.details.phoneNumber}
            loading={isProcessing}
          />
        </FormControl>
        <FormControl label='Address'>
          <SelectFilter
            options={dropDown.country.options}
            onChange={(option: Option) => {
              updateLocationParam(option, LOCATION_SETTINGS_ACTIONS.UPDATE_LOCATION_COUNTRY);
              handleSelectOptionChange(option, 'country');
            }}
            value={dropDown.country.selectedOption}
            isSearchable={true}
            placeholder='Select country'
            loading={isProcessing}
          />
        </FormControl>
        <FormControl>
          <TextInput
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              updateLocationParam(e.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_LOCATION_STREET);
            }}
            placeholder={'Street Address'}
            value={detailsPanel.details.streetAddress || ''}
            loading={isProcessing}
          />
        </FormControl>
        <FormControl>
          <TextInput
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              updateLocationParam(e.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_LOCATION_CITY);
            }}
            placeholder={'City'}
            value={detailsPanel.details.city || ''}
            loading={isProcessing}
          />
        </FormControl>
        <FormControl>
          <SelectFilter
            options={dropDown.state.options}
            onChange={(option: Option) => {
              updateLocationParam(option, LOCATION_SETTINGS_ACTIONS.UPDATE_LOCATION_STATE);
              handleSelectOptionChange(option, 'state');
            }}
            value={dropDown.state.selectedOption}
            isSearchable={true}
            placeholder='Select state'
            loading={isProcessing}
          />
        </FormControl>
        <FormControl>
          <TextInput
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              updateLocationParam(e.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_LOCATION_ZIP);
            }}
            placeholder={'Zip/Postal Code'}
            value={detailsPanel.details.zip || ''}
            additionalStyles={styles.select}
            loading={isProcessing}
          />
        </FormControl>
        <FormControl label='Time Zone'>
          <SelectFilter
            options={dropDown.timezone.options}
            onChange={(option) => {
              updateLocationParam(option, LOCATION_SETTINGS_ACTIONS.UPDATE_LOCATION_TIMEZONE);
              handleSelectOptionChange(option, 'timezone');
            }}
            value={dropDown.timezone.selectedOption}
            isSearchable={true}
            placeholder='Select timezone'
            loading={isProcessing}
          />
        </FormControl>
        <FormControl label='Currency'>
          <div style={{ width: '35%' }}>
            <SelectFilter
              options={dropDown.currency.options}
              onChange={(option) => {
                updateLocationParam(option, LOCATION_SETTINGS_ACTIONS.UPDATE_LOCATION_CURRENCY);
                handleSelectOptionChange(option, 'currency');
              }}
              value={dropDown.currency.selectedOption}
              isSearchable={true}
              placeholder='Select currency'
              loading={isProcessing}
            />
          </div>
        </FormControl>
        <FormControl label='Locale'>
          <div style={{ width: '35%' }}>
            <SelectFilter
              options={dropDown.locale.options}
              onChange={(option) => {
                updateLocationParam(option, LOCATION_SETTINGS_ACTIONS.UPDATE_LOCATION_LOCALE);
                handleSelectOptionChange(option, 'locale');
              }}
              value={dropDown.locale.selectedOption}
              isSearchable={true}
              placeholder='Select locale'
              loading={isProcessing}
            />
          </div>
        </FormControl>
      </FormGroup>
    </>
  );
};

export default LocationPanelDetails;
