import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../redux/initialStates/AppState';
import Container from '../Container/Container';
import { NavigationConfirmationDialog } from '../NavigationConfirmationDialog';
import { setFormIsDirty } from '../../redux/actions/uiActions/formStateActions';
import classNames from 'classnames';

type FormSubmitData = React.FormEvent | any;

export interface FormProps {
  onSubmit?: (data: FormSubmitData) => void;
  onConfirmNav?: () => void;
  onCancelNav?: () => void;
  children?: React.ReactNode;
  hasGroups?: boolean;
  formTitle?: string;
  dialogTitle?: string;
  dialogMessage?: string;
  confirmCancelChanges?: boolean;
  isModalForm?: boolean;
  additionalClassName?: string;
  useMobileStyling?: boolean;
}

const FormPanel = (props: Omit<FormProps, 'hasGroups' | 'onSubmit'>) => {
  const { formTitle, children, isModalForm = false, additionalClassName } = props;

  return (
    <div className={`form__panel ${isModalForm ? 'modal__form__panel' : ''} ${additionalClassName}`}>
      {formTitle && (
        <Container>
          <h3 className='form__panel__title'>{formTitle}</h3>
        </Container>
      )}
      {children}
    </div>
  );
};

interface SubmitData {
  submit: boolean;
  event?: React.FormEvent<HTMLFormElement>;
}

const Form = (props: FormProps & ReduxProps) => {
  const {
    onSubmit,
    onConfirmNav,
    onCancelNav,
    children,
    hasGroups = false,
    formTitle,
    dialogTitle,
    dialogMessage,
    confirmCancelChanges = true,
    setFormIsDirty,
    formIsDirty,
    isModalForm = false,
    additionalClassName = '',
    useMobileStyling = false,
  } = props;

  const [submitData, setSubmitData] = React.useState<SubmitData>({
    submit: false,
  });

  // Clear the dirty flag and then save the submission state. Submission
  // actually happens after this event handler, since the 'isDirty' flag must
  // be cleared before the submission is done.
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    // Persist is necessary to be able to access this event later in the
    // lifecycle. If React dependency gets updated to version 17+ this should be
    // removed, as event pooling was removed in that version going forward.
    event.persist();
    event.preventDefault();

    if (onSubmit) {
      setFormIsDirty(false);
      setSubmitData({ submit: true, event });
    }
  };

  // Clears the form dirty flag when the component is unmounted
  React.useEffect(() => {
    return () => {
      setFormIsDirty(false);
    };
  }, [setFormIsDirty]);

  // When the 'submit' flag is flipped to true, execute the submit callback and
  // reset the state
  React.useEffect(() => {
    if (submitData.submit && onSubmit) {
      onSubmit(submitData.event);
      setSubmitData({ submit: false });
    }
  }, [submitData, onSubmit]);

  // When data in the form changes, set the isDirty flag to true. This is used
  // to display a confirmation modal when the user clicks 'cancel' on the form
  const handleChange = (_: React.FormEvent<HTMLFormElement>) => {
    confirmCancelChanges && setFormIsDirty(true);
  };

  return (
    <form
      className={classNames('form', { mobileStyling: useMobileStyling })}
      onSubmit={handleSubmit}
      onChange={handleChange}
    >
      <NavigationConfirmationDialog
        title={dialogTitle}
        message={dialogMessage}
        shouldBlockNav={formIsDirty}
        isModalForm={isModalForm}
        onConfirmNav={onConfirmNav}
        onCancelNav={onCancelNav}
      />
      {hasGroups ? (
        children
      ) : (
        <FormPanel formTitle={formTitle} isModalForm={isModalForm} additionalClassName={additionalClassName}>
          {children}
        </FormPanel>
      )}
    </form>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    formIsDirty: state.ui.formState.isDirty,
  };
};

const actionCreators = {
  setFormIsDirty,
};

const connector = connect(mapStateToProps, actionCreators);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(Form);
