import React, { ReactNode } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  exportTransactionsReport,
  getTransactionsList,
  getTransactionsReport,
  updateTransactionsListQuery,
} from '../../redux/actions/transactionActions/transactionActions';
import { getLocations } from '../../redux/actions/transactionActions/permittedLocationsActions';
import { pageErrorState, set200Toast } from '../../redux/actions/uiActions/responseStateActions';
import { useSearchParams } from '../../hooks/useSearchParams';
import LayoutContent from '../../components/AppLayout/LayoutContent';
import HeaderControls, { ControlOption } from '../../components/AppLayout/HeaderControls';
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TableCells,
  TableRowsSkeleton,
} from '../../components/Table';
import { ToolbarComponent } from './components/ToolbarComponent';
import { DateValue, DollarValue, TextValue } from '../../components/Value';
import PaymentMethodIcon from './components/PaymentMethodIcon';
import PaymentTypeIcon from './components/PaymentTypeIcon';
import { initDateFilterUrlQuery } from '../../utils/urlUtils/initDateFilterUrlQuery';
import {
  TransactionResourceActions,
  PrincipalPermissions,
  ResourceType,
  SecurityScope,
  Verifier,
} from '@ready/security.core';
import { usePageTitle } from '../../hooks';
import { OnAccountPaymentBadge } from './components/OnAccountPaymentBadge';
import styles from './TransactionListPage.module.scss';
import { isValidLast4CardNumber } from 'pages/Transactions/utils/isValidLast4CardNumber';

const LOCAL_STORAGE_EXPORT_ID = 'exportId';

const TransactionsListPage = (props: any) => {
  usePageTitle('Transactions', 'Reports');

  const {
    transactionsList,
    getTransactionsList,
    contextId,
    getLocations,
    permittedLocations,
    set200Toast,
    updateTransactionsListQuery,
    pageErrorState,
    permissionsList,
  } = props;
  const { transactions, pagination, loading, exporting, exportId, checkingExport, exportDownloaded } = transactionsList;
  const { query, page } = useSearchParams();
  const emptyResult = !loading && !transactions.length;
  const [isExporting, setIsExporting] = React.useState(false);
  const { push } = useHistory();
  const { pathname } = useLocation();
  React.useEffect(() => {
    if (!query) {
      // if the query parameter is not declared in the url, redirect
      const redirectUrl = `${pathname}?${initDateFilterUrlQuery}`;
      push(redirectUrl);
    }
  }, [pathname, push, query]);

  if (exportId) {
    localStorage.setItem(LOCAL_STORAGE_EXPORT_ID, exportId);
  }

  const hasPermissionId = permissionsList.locations[0];

  const hasPermission = Verifier.checkAny(
    new PrincipalPermissions(permissionsList),
    [
      {
        scope: SecurityScope.location,
        resourceType: ResourceType.transaction,
        action: TransactionResourceActions.view,
      },
      {
        scope: SecurityScope.location,
        resourceType: ResourceType.transaction,
        action: TransactionResourceActions.refund,
      },
    ],
    hasPermissionId
  );
  if (!hasPermission) {
    pageErrorState(401);
  }

  const storedExportId = localStorage.getItem(LOCAL_STORAGE_EXPORT_ID);
  const exportInProgress = exporting || !!storedExportId;
  React.useEffect(() => {
    setIsExporting(exportInProgress);
  }, [exportInProgress]);

  const exportTransactions = async () => {
    props.exportTransactionsReport(contextId, query);
  };

  const headerControlOptions = [
    {
      buttonLabel: 'Export',
      dropdownLabel: 'Export Report',
      onClick: exportTransactions,
      primary: true,
      loading: isExporting,
    } as ControlOption,
  ];

  // table cols width
  const colsWidth: number[] = [10, 10, 20, 10, 10, 5, 5, 10, 20];

  React.useEffect(() => {
    // double-check export ID in local storage to guard against duplicate messaging
    if (exportDownloaded && !!localStorage.getItem(LOCAL_STORAGE_EXPORT_ID)) {
      localStorage.removeItem(LOCAL_STORAGE_EXPORT_ID);
      set200Toast('Transactions exported successfully');
      setIsExporting(false);
    }
  }, [exportDownloaded, set200Toast]);

  // schedule a report download attempt if we're waiting for a report
  React.useEffect(() => {
    if (storedExportId && !checkingExport && !exportDownloaded) {
      (async () => {
        setTimeout(() => {
          props.getTransactionsReport(contextId, storedExportId);
        }, 2000);
      })();
    }
  }, [storedExportId, checkingExport, exportDownloaded, props, contextId]);

  // get transactions list
  React.useEffect(() => {
    (async () => {
      if (!!contextId) {
        getTransactionsList(contextId, query, page);
      }
    })();
    updateTransactionsListQuery(query, page);
  }, [contextId, query, page, updateTransactionsListQuery, getTransactionsList]);

  return (
    <LayoutContent
      title='Transactions'
      containerType='full'
      pageId='transactions-list-page'
      headerControls={<HeaderControls controlOptions={headerControlOptions} />}
      toolbarContent={
        <ToolbarComponent contextId={contextId} getLocations={getLocations} permittedLocations={permittedLocations} />
      }
    >
      <Table paginationProps={pagination}>
        <TableHeader>
          <TableRow>
            <TableCell width={colsWidth[0]}>PAYMENT ID</TableCell>
            <TableCell width={colsWidth[1]} align='right'>
              AMOUNT
            </TableCell>
            <TableCell width={colsWidth[2]}>LOCATION</TableCell>
            <TableCell width={colsWidth[3]}>TICKET #</TableCell>
            <TableCell width={colsWidth[4]}>STATUS</TableCell>
            <TableCell width={colsWidth[5]}>TYPE</TableCell>
            <TableCell width={colsWidth[6]}>METHOD</TableCell>
            <TableCell width={colsWidth[7]}>LAST 4</TableCell>
            <TableCell width={colsWidth[8]}>DATE</TableCell>
          </TableRow>
        </TableHeader>

        <TableBody emptyResult={emptyResult}>
          {!loading ? (
            transactions.map((data: any, index: number) => {
              const lastDigits = isValidLast4CardNumber(data.lastDigits) ? data.lastDigits : '';
              return (
                <TableRow
                  key={index}
                  linkTo={`/companies/${contextId}/locations/${data.locationId}/transactions/${data._id}`}
                >
                  <TableCells visibleOn='desktop'>
                    <TableCell width={colsWidth[0]}>
                      <TextValue value={data._id.slice(-6)} />
                    </TableCell>
                    <TableCell width={colsWidth[1]} align='right'>
                      {/* TODO: uncomment this when we have the possible error status of a transaction */}
                      {/* <TextIcon icon="icon-dashboard-icons_alert" /> */}
                      <DollarValue value={data.amount} />
                    </TableCell>
                    <TableCell width={colsWidth[2]}>
                      <TextValue value={data.locationName} />
                    </TableCell>
                    <TableCell width={colsWidth[3]}>
                      <TextValue value={data.ticketNumber} />
                    </TableCell>
                    <TableCell width={colsWidth[4]}>
                      <TextValue value={data.status.toString()} />
                    </TableCell>
                    <TableCell width={colsWidth[5]}>
                      {data.method !== 'accountpayment' ? (
                        <PaymentTypeIcon code={data.type} />
                      ) : (
                        <CenteredIconCell>
                          <OnAccountPaymentBadge shortCode={data.type} />
                        </CenteredIconCell>
                      )}
                    </TableCell>
                    <TableCell width={colsWidth[6]}>
                      <PaymentMethodIcon code={data.method} />
                    </TableCell>
                    <TableCell width={colsWidth[7]}>
                      <TextValue value={lastDigits} />
                    </TableCell>
                    <TableCell width={colsWidth[8]}>
                      <DateValue value={data.date} timeZone={data.timezone} />
                    </TableCell>
                  </TableCells>

                  <TableCells visibleOn='mobile'>
                    <TableCell align='left'>
                      <TextValue value={data._id.slice(-6)} />
                    </TableCell>
                    <TableCell align='right'>
                      <TextValue value={data.status.toString()} />
                    </TableCell>
                    <TableCell align='left'>
                      <DollarValue value={data.amount} />
                    </TableCell>
                    <TableCell align='right'>
                      <div className={styles.mobileTypeAndPaymentCell}>
                        <CenteredIconCell>
                          {data.method !== 'accountpayment' ? (
                            <PaymentTypeIcon code={data.type} />
                          ) : (
                            <OnAccountPaymentBadge shortCode={data.type} />
                          )}
                        </CenteredIconCell>
                        <CenteredIconCell>
                          <PaymentMethodIcon code={data.method} />
                        </CenteredIconCell>
                      </div>
                    </TableCell>
                    <TableCell align='left'>
                      <TextValue value={data.locationName} />
                    </TableCell>
                    <TableCell align='right'>
                      <TextValue value={lastDigits} />
                    </TableCell>
                    <TableCell align='left'>
                      <TextValue value={data.ticketNumber} />
                    </TableCell>
                    <TableCell align='right'>
                      <DateValue value={data.date} timeZone={data.timezone} />
                    </TableCell>
                  </TableCells>
                </TableRow>
              );
            })
          ) : (
            <TableRowsSkeleton rows={50} colsWidth={colsWidth} />
          )}
        </TableBody>
      </Table>
    </LayoutContent>
  );
};

const CenteredIconCell = ({ children }: { children?: ReactNode }) => (
  <div className={styles.centeredIconCell}>{children}</div>
);

const mapStateToProps = (state: any) => ({
  transactionsList: state.transactions.transactionsList,
  contextId: state.session.contextSession.id,
  permittedLocations: state.transactions.permittedLocations.locations,
  permissionsList: state.session.permissions.permissionsList,
});

const actionCreators = {
  exportTransactionsReport,
  getTransactionsList,
  getTransactionsReport,
  getLocations,
  set200Toast,
  updateTransactionsListQuery,
  pageErrorState,
};

export default connect(mapStateToProps, actionCreators)(TransactionsListPage);
