import React from 'react';
import styles from './ItemGroupImage.module.scss';

export interface ItemGroupImageProps {
  itemGroupName: string;
  imageUrl?: string;
}

const ItemGroupImage = (props: ItemGroupImageProps) => {
  const { itemGroupName, imageUrl } = props;

  return (
    <div className={styles.itemGroupImage}>
      {!!imageUrl ? (
        <img src={imageUrl} alt={itemGroupName} className={styles.imtemGroupImageImgElement} />
      ) : (
        <i className='icon-dashboard-icons_camera'></i>
      )}
    </div>
  );
};

export default ItemGroupImage;
