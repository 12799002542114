import React from 'react';
import Container from '../Container/Container';
import readyProgressSpinner from '../../styles/assets/ready-progress-spinner.svg';
import classNames from 'classnames';

type Variant =
  | 'primary'
  | 'secondary'
  | 'secondary-gray-bg'
  | 'alert'
  | 'alert-secondary'
  | 'toggle-unselected'
  | 'toggle-selected'
  | 'toggle-colour-unselected'
  | 'toggle-colour-selected';

export interface ButtonProps {
  label: string;
  children?: JSX.Element[] | JSX.Element;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type?: 'button' | 'submit';
  variant?: Variant;
  size?: 'small' | 'large';
  loading?: boolean;
  unavailable?: boolean;
  disabled?: boolean;
  icon?: string;
  additionalStyles?: string;
  additionalButtonStyles?: string;
  additionalLabelStyles?: string;
}

const Button = (props: ButtonProps) => {
  const {
    label = '',
    children,
    onClick,
    type = 'button',
    variant = 'primary',
    size = 'small',
    loading = false,
    unavailable = false,
    disabled = false,
    icon,
    additionalStyles,
    additionalButtonStyles,
    additionalLabelStyles,
  } = props;

  // load different button class modifiers depending on its props
  let modifiersClassName = '';
  let additionalContainerClassName = 'button-container';

  if (variant) modifiersClassName += ` button--${variant}`;
  if (size) {
    modifiersClassName += ` button--size-${size}`;
    if (size === 'large') additionalContainerClassName += ' button-large-container';
  }
  if (loading) modifiersClassName += ' button--loading';
  if (unavailable) modifiersClassName += ' button--unavailable';
  if (disabled) modifiersClassName += ' button--disabled';

  return (
    <Container additionalClassName={classNames(additionalStyles, additionalContainerClassName)}>
      <button
        className={classNames('button', modifiersClassName, additionalButtonStyles)}
        onClick={onClick}
        type={type}
        disabled={loading || unavailable || disabled}
      >
        {icon && <i className={icon} />}
        {label ? <span className={`button--label ${additionalLabelStyles}`}>{label}</span> : null}
        {children ? children : null}
        <img src={readyProgressSpinner} alt='loading' />
      </button>
    </Container>
  );
};

export default Button;
