import React from 'react';
// redux
import { connect } from 'react-redux';
// actions
import { getLocationsList, updateLocationsListParameters, exportLocations } from '../redux/LocationsActions';
// hooks
import { useSearchParams } from '../../hooks/useSearchParams';
// components
import LayoutContent from '../../components/AppLayout/LayoutContent';
import SearchParamInput from '../../components/SearchInput/SearchParamInput';
import { Table, TableHeader, TableBody, TableRow, TableCell, TableRowsSkeleton } from '../../components/Table';
import { ILocationToShow } from '@ready/dashboardv2api.contracts';
import StatusIndicator from '../../components/StatusIndicator/StatusIndicator';

import styles from './LocationsListPage.module.scss';
import { useResponsiveBreakpoint } from 'hooks';
import HeaderControls, { ControlOption } from 'components/AppLayout/HeaderControls';
import Toggle from '../../components/Toggle/Toggle';

const LocationsListPage = (props: any) => {
  const { locationsList, getLocationsList, updateLocationsListParameters, exportLocations } = props;
  const { locations, pagination, loading, exporting } = locationsList;
  const { query, page } = useSearchParams();
  const { isDesktop } = useResponsiveBreakpoint();
  const [showHiddenState, setShowHiddenState] = React.useState(false);

  const emptyResult = !loading && !locations.length;

  const headerControlOptions: ControlOption[] = [
    {
      buttonLabel: 'Export',
      dropdownLabel: 'Export Report',
      onClick: exportLocations,
      primary: true,
      loading: exporting,
    },
  ];

  // get locations list
  React.useEffect(() => {
    getLocationsList(query, showHiddenState, page);
    updateLocationsListParameters(query || '', page || 1);
  }, [query, page, getLocationsList, updateLocationsListParameters, showHiddenState]);

  // table cols width
  const colsWidth: number[] = [30, 25, 15, 15, 15, 8, 15];

  return (
    <LayoutContent
      title='Locations'
      containerType='full'
      toolbarContent={
        <div>
          <SearchParamInput placeholder='Search by Location or Company' />
          <div className={styles.showHiddenContainer}>
            <div className={styles.showHiddenText}>Show Hidden</div>
            <Toggle checked={showHiddenState} onChange={() => setShowHiddenState(!showHiddenState)} />
          </div>
        </div>
      }
      headerControls={<HeaderControls controlOptions={headerControlOptions} />}
    >
      <Table paginationProps={pagination}>
        <TableHeader>
          <TableRow>
            <TableCell width={colsWidth[0]}>Location name</TableCell>
            <TableCell width={colsWidth[1]}>Company name</TableCell>
            <TableCell width={colsWidth[2]}>Features</TableCell>
            <TableCell width={colsWidth[3]}>Payment provider</TableCell>
            <TableCell width={colsWidth[4]}>Pos integration</TableCell>
            <TableCell width={colsWidth[5]}>Billing</TableCell>
            <TableCell width={colsWidth[6]}>Status</TableCell>
          </TableRow>
        </TableHeader>

        <TableBody emptyResult={emptyResult}>
          {!loading ? (
            locations.map((data: ILocationToShow, index: number) => {
              return (
                <TableRow key={index} linkTo={`/ready/companies/${data.companyId}/locations/${data._id}/settings`}>
                  <TableCell width={colsWidth[0]}>{data.name}</TableCell>
                  <TableCell width={colsWidth[1]} disabled={!data.companyName}>
                    {data.companyName || 'No name'}
                  </TableCell>
                  <TableCell width={colsWidth[2]} disabled={!data.features.length}>
                    {data.features || 'No features'}
                  </TableCell>
                  <TableCell width={colsWidth[3]} disabled={!data.paymentProvider.length}>
                    {data.paymentProvider || 'No provider'}
                  </TableCell>
                  <TableCell
                    width={colsWidth[4]}
                    disabled={!data.posIntegration.length}
                    additionalStyles={styles.cellPosIntegration}
                    align={isDesktop ? 'left' : 'right'}
                  >
                    {data.posIntegration || 'No integration'}
                  </TableCell>
                  <TableCell width={colsWidth[5]} visibleOn={'desktop'}>
                    {data.billingEnabled ? 'On' : 'Off'}
                  </TableCell>
                  <TableCell width={colsWidth[6]}>
                    <StatusIndicator active={data.enabled} activeLabel='Active' inactiveLabel='Inactive' />
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRowsSkeleton rows={50} colsWidth={colsWidth} />
          )}
        </TableBody>
      </Table>
    </LayoutContent>
  );
};

const mapStateToProps = (state: any) => ({
  locationsList: state.location.locationsList,
});

export default connect(mapStateToProps, {
  getLocationsList,
  updateLocationsListParameters,
  exportLocations,
})(LocationsListPage);
