import EmptyResult from 'components/EmptyResult/EmptyResult';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ContextParams } from 'types/ContextParams.interface';
import { useAppDispatch, useAppSelector } from 'redux/store';
import InfoCardList from 'components/InfoCard/InfoCardList';
import { ListLoadingSkeletons } from '../../components/ListLoadingSkeletons';
import { ItemsAndModsPageControls } from './components/ItemAndModsPageControls';
import { SharedMenuItemsLayout } from 'sharedMenuItems/SharedMenuItemsLayout';
import { SharedMenuItemsTabId } from 'sharedMenuItems/SharedMenuItemsTabs';
import styles from './ItemsAndModsPage.module.scss';
import { ItemInfoCard } from './components/ItemInfoCard';
import { useSearchParams } from 'hooks';
import { getSharedMenuItemListThunk } from './redux/thunks';
import InfoCard from 'components/InfoCard/InfoCard';
import { decodeUriString } from 'utils/urlUtils/decodeUriString';
import { ITemplateMenuItem, WithAssignedLocationsCount } from '@ready/menu.core';

const ItemsAndModsPage = () => {
  const { contextId: companyId } = useParams<ContextParams>();
  const { searchTerm, type, locationName, locationId, page } = useSearchParams(true);
  const dispatch = useAppDispatch();
  const decodedSearchTerm = decodeUriString(searchTerm || '');
  const location = locationId && locationName ? { id: locationId, name: decodeUriString(locationName) } : undefined;

  const { loading, items, pagination } = useAppSelector((state) => state.sharedMenuItems.itemsAndModsList);

  const areFiltersSet = type !== 'all' || searchTerm || location;
  const showCreateFirstMessage = !loading && !areFiltersSet && items.length === 0;

  useEffect(() => {
    dispatch(
      getSharedMenuItemListThunk({
        companyId,
        page,
        type,
        searchTerm: decodedSearchTerm,
        locationId: locationId,
      })
    );
  }, [companyId, decodedSearchTerm, dispatch, locationId, page, type]);

  return (
    <SharedMenuItemsLayout tab={SharedMenuItemsTabId.ItemAndMods}>
      <div className={styles.container}>
        <ItemsAndModsPageControls
          initialSearchFilter={decodedSearchTerm}
          initialTypeFilter={type}
          initialLocationFilter={location}
        />
        {!showCreateFirstMessage ? (
          <InfoCardList paginationProps={pagination}>
            {loading ? (
              <ListLoadingSkeletons />
            ) : (
              items.map((item: WithAssignedLocationsCount<ITemplateMenuItem>) => (
                <ItemInfoCard key={item._id} item={item} />
              ))
            )}
            {!loading && items.length === 0 && (
              <InfoCard>
                <div className={styles.noResults}>No Results Found</div>
              </InfoCard>
            )}
          </InfoCardList>
        ) : (
          <EmptyResult
            title='Create Your First Shared Items & Mods'
            paragraph="Use the 'New Shared Item' button to create new shared menu items and modifiers to be displayed on your Ready menu. Shared items can be assigned to multiple locations as needed."
          />
        )}
      </div>
    </SharedMenuItemsLayout>
  );
};

export { ItemsAndModsPage };
