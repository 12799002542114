import React from 'react';
import classNames from 'classnames';
import styles from './OrderStatusToggle.module.scss';
import { useClassNames } from '../../../utils/cssUtils/useClassNames';
import { OrderFulfillmentStatus } from '@ready/dashboardv2api.contracts';
import readyProgressSpinner from '../../../styles/assets/ready-progress-spinner-white.svg';
import { OrderStatusChanging } from '../../../types/OrderStatusChanging.interface';
import { OrderStatusLabel } from '../../../enums/orders.enum';

export interface OrderStatusToggleProps {
  value: OrderFulfillmentStatus;
  orderStatusChanging: OrderStatusChanging;
  onChange?: (orderStatus: OrderFulfillmentStatus) => void;
  additionalStyles?: string;
}

const OrderStatusToggle = ({ value, orderStatusChanging, onChange, additionalStyles }: OrderStatusToggleProps) => {
  const isProcessing = orderStatusChanging.processing;
  const newOrderStatus = orderStatusChanging.newOrderStatus;

  const isPending = value === OrderFulfillmentStatus.Pending;
  const isPendingDisabled = isProcessing || isPending;
  const isPendingChanging = newOrderStatus === OrderFulfillmentStatus.Pending && isProcessing;

  const isInProgress = value === OrderFulfillmentStatus.InProgress;
  const isInProgressDisabled = isProcessing || isInProgress;
  const isInProgressChanging = newOrderStatus === OrderFulfillmentStatus.InProgress && isProcessing;

  const isReady = value === OrderFulfillmentStatus.Ready;
  const isReadyDisabled = isProcessing || isReady;
  const isReadyChanging = newOrderStatus === OrderFulfillmentStatus.Ready && isProcessing;

  const isComplete = value === OrderFulfillmentStatus.Complete;
  const isCompleteDisabled = isProcessing || isComplete;
  const isCompleteChanging = newOrderStatus === OrderFulfillmentStatus.Complete && isProcessing;

  const pendingClassName = useClassNames(
    [
      {
        active: isPending,
        disabled: isPendingDisabled,
        processing: isProcessing,
      },
      styles.button,
      styles[OrderFulfillmentStatus.Pending],
    ],
    styles
  );

  const inProgressClassName = useClassNames(
    [
      {
        active: isInProgress,
        disabled: isInProgressDisabled,
        processing: isProcessing,
      },
      styles.button,
      styles[OrderFulfillmentStatus.InProgress],
    ],
    styles
  );

  const readyClassName = useClassNames(
    [
      {
        active: isReady,
        disabled: isReadyDisabled,
        processing: isProcessing,
      },
      styles.button,
      styles[OrderFulfillmentStatus.Ready],
    ],
    styles
  );

  const completeClassName = useClassNames(
    [
      {
        active: isComplete,
        disabled: isCompleteDisabled,
        processing: isProcessing,
      },
      styles.button,
      styles[OrderFulfillmentStatus.Complete],
    ],
    styles
  );

  const cancelledClassName = classNames(styles.button, styles[OrderFulfillmentStatus.Cancelled]);

  const handlePendingClick = () => {
    onChange && onChange(OrderFulfillmentStatus.Pending);
  };

  const handleInProgressClick = () => {
    onChange && onChange(OrderFulfillmentStatus.InProgress);
  };

  const handleReadyClick = () => {
    onChange && onChange(OrderFulfillmentStatus.Ready);
  };

  const handleCompleteClick = () => {
    onChange && onChange(OrderFulfillmentStatus.Complete);
  };

  return (
    <div className={classNames(styles.container, additionalStyles)}>
      {value === OrderFulfillmentStatus.Cancelled ? (
        <div className={styles.buttonContainer}>
          <button disabled={true} className={cancelledClassName}>
            {OrderStatusLabel.CANCELLED}
            <Spinner show={isPendingChanging} />
          </button>
        </div>
      ) : (
        <>
          <div className={styles.buttonContainer}>
            <button disabled={isPendingDisabled} className={pendingClassName} onClick={handlePendingClick}>
              {OrderStatusLabel.PENDING}
              <Spinner show={isPendingChanging} />
            </button>
          </div>
          <div className={styles.buttonContainer}>
            <button disabled={isInProgressDisabled} className={inProgressClassName} onClick={handleInProgressClick}>
              {OrderStatusLabel.IN_PROGRESS}
              <Spinner show={isInProgressChanging} />
            </button>
          </div>
          <div className={styles.buttonContainer}>
            <button disabled={isReadyDisabled} className={readyClassName} onClick={handleReadyClick}>
              {OrderStatusLabel.READY}
              <Spinner show={isReadyChanging} />
            </button>
          </div>
          <div className={styles.buttonContainer}>
            <button disabled={isCompleteDisabled} className={completeClassName} onClick={handleCompleteClick}>
              {OrderStatusLabel.COMPLETED}
              <Spinner show={isCompleteChanging} />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

interface SpinnerProps {
  show: boolean;
}

const Spinner = ({ show }: SpinnerProps) =>
  show ? <img src={readyProgressSpinner} alt='loading' className={styles.spinner} /> : null;

export default OrderStatusToggle;
