import { IMenuItemRequest, IPriceLevel } from '@ready/menu.core';
import Checkbox from 'components/Checkbox/Checkbox';
import { Form, FormControl } from 'components/Form';
import TextIcon, { Icon } from 'components/Icon/TextIcon';
import LoadingSpinnerInline from 'components/LoadingSpinnerInline/LoadingSpinnerInline';
import { ModalBody, ModalFooter, ModalHeader } from 'components/Modal';
import Modal from 'components/Modal/Modal';
import Toggle from 'components/Toggle/Toggle';
import { EffectiveDates } from 'menus/components/ItemsAndMods/MenuItemDetails/components/EffectiveDates';
import SelectPriceLevel from 'menus/components/ItemsAndMods/SelectPriceLevel';
import ScheduleFormControl from 'menus/components/shared/ScheduleFormControl';
import { selectLocationsState } from 'menus/redux/LocationsSelectors';
import { selectScheduleListState } from 'menus/redux/SchedulesSelectors';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { IPosMenuItemOption, PosMenuItemSelect } from 'sharedMenuItems/components/PosItemSelect/PosMenuItemSelect';
import { mapIMenuItemToIMenuItemRequest } from 'sharedMenuItems/mappers/mappers';
import { selectTableState } from 'sharedMenuItems/redux/selectors';
import { clearCurrentMenuItem, setEditLocationSettingsModalVisible } from 'sharedMenuItems/redux/table/tableSlice';
import {
  editSharedMenuItemLocationSettings,
  getCurrentItemAtLocation,
} from 'sharedMenuItems/redux/table/tableThunkActions';
import { ContextParams } from 'types/ContextParams.interface';
import styles from './EditLocationItemModal.module.scss';
import { Option } from 'components/SelectFilter/SelectFilter';

const EditLocationSettingsModal = () => {
  const dispatch = useAppDispatch();
  const { location } = useAppSelector(selectLocationsState);
  const timeZone = location?.timezone;
  const [isModifier, setIsModifier] = React.useState<boolean>();

  const { contextId: companyId } = useParams<ContextParams>();
  const { rowData, currentMenuItemAtLocation: menuItem, modalLoading } = useAppSelector(selectTableState);
  const { schedules, loading: schedulesLoading } = useAppSelector(selectScheduleListState);

  const [priceLevels, setPriceLevels] = React.useState<IPriceLevel[]>([]);
  const [activePriceLevel, setActivePriceLevel] = React.useState<IPriceLevel>();
  const [submitState, setSubmitState] = React.useState<IMenuItemRequest>({
    _id: '',
    itemType: 'item',
    status: 0,
    displayName: '',
    modifierGroups: [],
    recommendedItems: [],
    forcedModifiers: [],
  });
  const [initialPosOption, setInitialPosOption] = useState<Option>();

  const handlePOSItemChange = (posItem?: IPosMenuItemOption) => {
    if (posItem) {
      setSubmitState((prev) => ({ ...prev, posItemId: posItem.externalId }));
      const newPriceLevels = posItem.price_levels?.map((price) => {
        return { id: price.id, name: price.name, pricePerUnit: price.price_per_unit };
      });
      setPriceLevels(newPriceLevels || []);
      // set the default price to be the first item in the price level array
      setActivePriceLevel(newPriceLevels && newPriceLevels[0]);
    } else {
      setSubmitState((prev) => ({ ...prev, posItemId: '', status: 0 }));
      setPriceLevels([]);
      setActivePriceLevel(undefined);
    }
  };

  const [saving, setSaving] = useState<boolean>(false);
  const handleSubmit = async () => {
    setSaving(true);
    const request = {
      ...submitState,
      activePriceLevel: submitState.posItemId ? activePriceLevel?.id || 'default' : undefined,
    };
    await dispatch(editSharedMenuItemLocationSettings({ companyId, locationId: rowData?.locationId || '', request }));
    dispatch(clearCurrentMenuItem());
    setSaving(false);
    dispatch(setEditLocationSettingsModalVisible(false));
  };

  useEffect(() => {
    dispatch(
      getCurrentItemAtLocation({
        companyId: companyId,
        locationId: rowData?.locationId || '',
        menuItemId: rowData?._id || '',
      })
    );
  }, [companyId, rowData, dispatch]);

  useEffect(() => {
    if (menuItem) {
      setSubmitState(mapIMenuItemToIMenuItemRequest(menuItem));
      setIsModifier(menuItem.itemType === 'option');
      if (menuItem.posItemId) setInitialPosOption({ value: menuItem.posItemId, label: menuItem.posItemName });
      // these live outside the main object, as we need to maintain more information about them for UI than we will be sending along
      setPriceLevels(menuItem.priceLevels || []);
      setActivePriceLevel(menuItem.priceLevels?.find((level) => level.id === menuItem?.activePriceLevel));
    }
  }, [menuItem]);

  return (
    <Modal setShowModal={setEditLocationSettingsModalVisible}>
      {modalLoading || isModifier === undefined ? (
        <LoadingSpinnerInline />
      ) : (
        <>
          <ModalHeader
            headerLabel={`Edit ${isModifier ? 'Modifier' : 'Item'} Settings`}
            setShowModal={(visible) => dispatch(setEditLocationSettingsModalVisible(visible))}
          />
          <ModalBody>
            <Form hasGroups isModalForm>
              <FormControl label='Location'>
                <TextIcon icon={Icon.Location} additionalStyles={styles.locationIcon} />
                {rowData?.locationName}
              </FormControl>
              <FormControl label='Status'>
                <Toggle
                  checked={!!submitState.status && !!submitState.posItemId}
                  disabled={!submitState.posItemId || modalLoading || saving}
                  onChange={(checked: boolean) => setSubmitState((prev) => ({ ...prev, status: checked ? 1 : 0 }))}
                />
              </FormControl>
              <FormControl label='POS Item'>
                <PosMenuItemSelect
                  initialOption={initialPosOption}
                  selectedPosMenuItemId={submitState.posItemId}
                  companyId={companyId}
                  locationId={rowData?.locationId || ''}
                  isModifier={isModifier}
                  onChange={handlePOSItemChange}
                  isInModal
                  disabled={saving}
                />
              </FormControl>
              <FormControl label='Price' additionalStyles={styles.smallPrice}>
                <SelectPriceLevel
                  placeholder={'—'}
                  isInModal
                  activePriceLevel={activePriceLevel?.id || ''}
                  price={activePriceLevel?.pricePerUnit}
                  priceLevels={priceLevels}
                  disabled={!submitState.posItemId || modalLoading || saving}
                  onChange={(option) =>
                    option.value === 'default'
                      ? setActivePriceLevel(priceLevels[0])
                      : setActivePriceLevel(priceLevels.find((pLevel) => pLevel.id === option.value))
                  }
                />
              </FormControl>
              {!isModifier && (
                <FormControl label='Schedule'>
                  <ScheduleFormControl
                    validation={{ displayName: { hasError: false, errorMessage: '' } }}
                    opened={true}
                    scheduleLink={submitState.schedule}
                    schedules={schedules}
                    areSchedulesLoading={schedulesLoading}
                    loading={modalLoading}
                    onChange={(link) => setSubmitState((prev) => ({ ...prev, schedule: link || undefined }))}
                    // if this undefined is removed the field will not clear ^
                    isInModal
                    fullWidth
                    disabled={saving}
                  />
                </FormControl>
              )}

              <EffectiveDates
                effectiveDates={submitState.effectiveDates}
                timeZone={timeZone}
                disabled={modalLoading || saving}
                onChangeStartDate={(sd) =>
                  setSubmitState((prev) => ({ ...prev, effectiveDates: { start: sd, end: prev.effectiveDates?.end } }))
                }
                onChangeEndDate={(ed) =>
                  setSubmitState((prev) => ({
                    ...prev,
                    effectiveDates: { end: ed, start: prev.effectiveDates?.start },
                  }))
                }
                isInModal
              />
              {!isModifier && (
                <FormControl label='Popular Item'>
                  <Checkbox
                    label='Add to popular item list'
                    checked={submitState.isPopular || false}
                    loading={modalLoading}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setSubmitState((prev) => ({ ...prev, isPopular: e.target.checked }));
                    }}
                    disabled={saving}
                  />
                </FormControl>
              )}
            </Form>
          </ModalBody>
          <ModalFooter
            primaryLabel='Save Changes'
            primaryActionHandler={() => handleSubmit()}
            secondaryLabel='Cancel'
            secondaryActionHandler={() => {
              dispatch(clearCurrentMenuItem());
              dispatch(setEditLocationSettingsModalVisible(false));
            }}
            loading={modalLoading || saving}
          />
        </>
      )}
    </Modal>
  );
};

export default EditLocationSettingsModal;
