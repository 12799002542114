import createAuth0Client, { Auth0Client, Auth0ClientOptions } from '@auth0/auth0-spa-js';

export default class Auth0Service {
  private static client: Auth0Client;

  public static initializeClient = async (options: Auth0ClientOptions): Promise<Auth0Client> => {
    Auth0Service.client = await createAuth0Client(options);
    return Auth0Service.client;
  };

  public static getToken = async (): Promise<string> => {
    return Auth0Service.client.getTokenSilently();
  };
}
