import { IMenuGroupActivationRequest } from '@ready/menu.core';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { saveActivations } from '../locations.service';
import { set200Toast } from 'redux/actions/uiActions/responseStateActions';

interface Args {
  companyId: string;
  guestActivations: Map<string, string>;
  serverFlowActivations: Map<string, string>;
}

export const activateMenuGroupsThunk = createAsyncThunk<void, Args>(
  'sharedMenus/locations/save',
  async ({ companyId, guestActivations, serverFlowActivations }: Args, { dispatch }) => {
    let guestUpdates = [] as IMenuGroupActivationRequest[];
    let serverFlowUpdates = [] as IMenuGroupActivationRequest[];

    if (guestActivations.size > 0) {
      const reqs = Array.from(guestActivations).map<IMenuGroupActivationRequest>(([key, value]) => {
        return {
          locationId: key,
          menuGroupId: value,
        };
      });
      guestUpdates = reqs;
    }
    if (serverFlowActivations.size > 0) {
      const reqs = Array.from(serverFlowActivations).map<IMenuGroupActivationRequest>(([key, value]) => {
        return {
          locationId: key,
          menuGroupId: value,
        };
      });
      serverFlowUpdates = reqs;
    }

    await saveActivations(companyId, guestUpdates, serverFlowUpdates);
    dispatch(set200Toast());
  }
);
