import React, { useState } from 'react';
import { OrderSmsMessagesLocation } from './locationSettings/OrderSMSMessagesLocation';
import { OrderSmsMessagesCompanyModal } from './companySettings/OrderSMSMessagesCompanyModal';
import { Form, FormActionButtons } from '../../../../components/Form';
import { useDispatch, useSelector } from 'react-redux';
import { OrderSmsMessagesThunk } from '../../../redux/reducers/orderSMSMessages/orderSMSMessagesThunk';
import { ContextParams } from '../../../../types/ContextParams.interface';
import { useParams } from 'react-router-dom';
import {
  resetOrderSmsEditLocationForm,
  validateOrderSmsMessages,
} from '../../../redux/reducers/orderSMSMessages/orderSMSMessagesSlice';
import { orderSmsMessagesSelector } from '../../../redux/selectors/orderSmsMessagesSelector';

export const EditOrderSmsMessages = () => {
  const dispatch = useDispatch();
  const { orderSmsMessages, formProcessing, validated } = useSelector(orderSmsMessagesSelector);
  const { locationConfig, messageSource } = orderSmsMessages;
  const { contextId: companyId, locationId } = useParams<ContextParams>();
  const [saveButtonClicked, setSaveButtonClicked] = useState<boolean>(false);

  React.useEffect(() => {
    if (validated && saveButtonClicked) {
      dispatch(
        OrderSmsMessagesThunk.updateOrderSmsMessagesLocationConfig({
          companyId,
          locationId,
          orderSmsMessages,
        })
      );
    }
  }, [validated, dispatch, companyId, locationId, messageSource, orderSmsMessages, saveButtonClicked]);

  const onSubmit = () => {
    setSaveButtonClicked(true);
    dispatch(validateOrderSmsMessages(locationConfig));
  };

  const handleCancelForm = () => {
    dispatch(resetOrderSmsEditLocationForm());
  };

  return (
    <Form hasGroups={true} onSubmit={onSubmit}>
      <OrderSmsMessagesLocation />
      <OrderSmsMessagesCompanyModal />
      <FormActionButtons handleCancel={handleCancelForm} loading={formProcessing} />
    </Form>
  );
};
