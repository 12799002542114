import React from 'react';
import { Status } from '@ready/menu.core';
import styles from './StatusRadioGroup.module.scss';
import RadioGroup from '../../../components/RadioGroup/RadioGroup';
import { useClassNames } from '../../../utils/cssUtils';

export interface StatusRadioGroupProps {
  value: Status;
  processing: boolean;
  label?: string;
  boldLabel?: boolean;
  onChange: (status: boolean) => void;
}

const StatusRadioGroup = (props: StatusRadioGroupProps): JSX.Element => {
  const { value, processing, label = 'Status', boldLabel = false, onChange } = props;

  const onStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value === '1');
  };

  const labelClassName = useClassNames(
    [
      {
        label: true,
        boldLabel: boldLabel,
      },
    ],
    styles
  );

  return (
    <div className={styles.container}>
      <div className={labelClassName}>{label}</div>
      <RadioGroup
        groupName={label}
        options={[
          {
            label: 'Enabled',
            value: Status.enabled,
          },
          {
            label: 'Disabled',
            value: Status.disabled,
          },
        ]}
        layout='horizontal'
        disabled={processing}
        value={value}
        onChange={onStatusChange}
      />
    </div>
  );
};

export default StatusRadioGroup;
