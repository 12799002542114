import React from 'react';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import {
  fetchBrinkRevenueCenters,
  resetBrinkOptions,
  updatePosEmployeeSelection,
  LOCATION_SETTINGS_ACTIONS,
  getPosTenderTypes,
  getPosEmployees,
} from '../../redux/LocationSettingsActions';
import { IPosSettingsPanel } from '../../redux/PanelPosSettingsState';
import styles from './BrinkPOS.module.scss';
// components
import { FormControl } from '../../../components/Form';
import TextInput from '../../../components/TextInput/TextInput';
import Notification from '../../../components/Notification/Notification';
import { Icon } from '../../../components/Icon/TextIcon';
import SelectFilterAsync from '../../../components/SelectFilter/SelectFilterAsync';
import SelectFilter from '../../../components/SelectFilter/SelectFilter';
import { IUpdateLocationParam } from '../LocationsEditPage';

interface Props {
  companyId: string;
  locationId: string;
  isProcessing: boolean;
  updateLocationParam: IUpdateLocationParam;
  posSettingsPanel: IPosSettingsPanel;
}

const BrinkPOS = (props: Props) => {
  const { companyId, locationId, isProcessing, updateLocationParam, posSettingsPanel } = props;
  const dependentFieldsUnavailable =
    !posSettingsPanel.optionsBrink.brinkLocationId || !posSettingsPanel.optionsBrink.apiUrl;
  const dispatch = useDispatch();

  const employees = posSettingsPanel.posOptions.employees.results;
  const revenueCenters = posSettingsPanel.posOptions.revenueCenters.results;
  const tenderTypes = posSettingsPanel.posOptions.tenderTypes.results;

  const selectedEmployee = posSettingsPanel.posOptions.employees.selection || null;
  const selectedRevenueCenter =
    revenueCenters.find((revenueCenter) => revenueCenter.value === posSettingsPanel.optionsBrink.posRevenueCenter) ||
    null;
  const selectedTenderType =
    tenderTypes.find((tenderType) => tenderType.value === posSettingsPanel.optionsBrink.posTenderType) || null;
  const selectedMastercardTenderType =
    tenderTypes.find((tenderType) => tenderType.value === posSettingsPanel.optionsBrink.posMastercard) || null;
  const selectedVisaTenderType =
    tenderTypes.find((tenderType) => tenderType.value === posSettingsPanel.optionsBrink.posVisa) || null;
  const selectedAmexTenderType =
    tenderTypes.find((tenderType) => tenderType.value === posSettingsPanel.optionsBrink.posAmex) || null;
  const selectedDiscoverTenderType =
    tenderTypes.find((tenderType) => tenderType.value === posSettingsPanel.optionsBrink.posDiscover) || null;
  const selectedJcbTenderType =
    tenderTypes.find((tenderType) => tenderType.value === posSettingsPanel.optionsBrink.posJcb) || null;
  const selectedInteracTenderType =
    tenderTypes.find((tenderType) => tenderType.value === posSettingsPanel.optionsBrink.posInterac) || null;

  const handleFetchEmployees = debounce((filter: string) => {
    dispatch(getPosEmployees(companyId, locationId, filter));
  }, 300);
  const handleFetchRevenueCenters = () => {
    if (!posSettingsPanel.posOptions.revenueCenters.loaded && !posSettingsPanel.posOptions.revenueCenters.loading) {
      dispatch(
        fetchBrinkRevenueCenters(
          locationId,
          posSettingsPanel.optionsBrink.brinkLocationId,
          posSettingsPanel.optionsBrink.apiUrl
        )
      );
    }
  };
  const handleFetchTenderTypes = () => {
    if (!posSettingsPanel.posOptions.tenderTypes.loaded && !posSettingsPanel.posOptions.tenderTypes.loading) {
      dispatch(
        getPosTenderTypes(
          locationId,
        )
      );
    }
  };

  React.useEffect(() => {
    if (posSettingsPanel.optionsBrink.posRevenueCenter && !posSettingsPanel.posOptions.revenueCenters.loaded) {
      dispatch(
        fetchBrinkRevenueCenters(
          locationId,
          posSettingsPanel.optionsBrink.brinkLocationId,
          posSettingsPanel.optionsBrink.apiUrl
        )
      );
    }
    if (
      (posSettingsPanel.optionsBrink.posTenderType ||
        posSettingsPanel.optionsBrink.posMastercard ||
        posSettingsPanel.optionsBrink.posVisa ||
        posSettingsPanel.optionsBrink.posAmex ||
        posSettingsPanel.optionsBrink.posDiscover ||
        posSettingsPanel.optionsBrink.posJcb || 
        posSettingsPanel.optionsBrink.posInterac) &&
      !posSettingsPanel.posOptions.tenderTypes.loaded
    ) {
      dispatch(
        getPosTenderTypes(
          locationId,
        )
      );
    }
  }, [
    posSettingsPanel.optionsBrink.posOrderType,
    posSettingsPanel.optionsBrink.posRevenueCenter,
    posSettingsPanel.posOptions.revenueCenters.loaded,
    posSettingsPanel.optionsBrink.posTenderType,
    posSettingsPanel.optionsBrink.posMastercard,
    posSettingsPanel.optionsBrink.posVisa,
    posSettingsPanel.optionsBrink.posAmex,
    posSettingsPanel.optionsBrink.posDiscover,
    posSettingsPanel.optionsBrink.posJcb,
    posSettingsPanel.optionsBrink.posInterac,
    posSettingsPanel.posOptions.tenderTypes.loaded,
    posSettingsPanel.optionsBrink.brinkLocationId,
    posSettingsPanel.optionsBrink.apiUrl,
    locationId,
    dispatch,
  ]);

  return (
    <>
      <FormControl label='Brink Location ID'>
        <TextInput
          placeholder='Enter the Location ID from Brink'
          onChange={(event) => {
            dispatch(resetBrinkOptions());
            updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_BRINK_LOCATION_ID);
          }}
          value={posSettingsPanel.optionsBrink.brinkLocationId}
          loading={isProcessing}
        />
      </FormControl>
      <FormControl label='API Endpoint'>
        <TextInput
          placeholder='Enter the Brink API endpoint'
          onChange={(event) => {
            dispatch(resetBrinkOptions());
            updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_BRINK_API_URL);
          }}
          value={posSettingsPanel.optionsBrink.apiUrl}
          loading={isProcessing}
        />
      </FormControl>

      {dependentFieldsUnavailable && (
        <Notification type='warning' className={styles.notification}>
          <p>
            <i className={Icon.InfoCircle} />
            <span>Brink Location ID and API Endpoint must be entered before completing these fields.</span>
          </p>
        </Notification>
      )}

      <FormControl label='Employee'>
        <SelectFilterAsync
          placeholder='Type to search employees'
          options={employees}
          value={selectedEmployee}
          onChange={(data) => {
            updateLocationParam(!!data ? data.value : '', LOCATION_SETTINGS_ACTIONS.UPDATE_BRINK_POS_EMPLOYEE);
            dispatch(updatePosEmployeeSelection(data));
          }}
          handleFetch={handleFetchEmployees}
          loading={posSettingsPanel.posOptions.employees.loading}
          processing={isProcessing}
          disabled={dependentFieldsUnavailable}
          hideIndicator
          isClearable
          alwaysFetchOnOpen
        />
      </FormControl>
      <FormControl label='Revenue Centre'>
        <SelectFilter
          placeholder='Type to search revenue centres'
          populated={posSettingsPanel.posOptions.revenueCenters.loaded}
          populating={posSettingsPanel.posOptions.revenueCenters.loading}
          disabled={dependentFieldsUnavailable}
          loading={isProcessing}
          options={revenueCenters}
          value={selectedRevenueCenter}
          hideIndicator
          isSearchable
          isClearable
          onMenuOpen={handleFetchRevenueCenters}
          onChange={(data) => {
            updateLocationParam(!!data ? data.value : '', LOCATION_SETTINGS_ACTIONS.UPDATE_BRINK_POS_REVENUE_CENTER);
          }}
        />
      </FormControl>
      <FormControl label='Default Tender Type'>
        <SelectFilter
          placeholder='Type to search tender types'
          populated={posSettingsPanel.posOptions.tenderTypes.loaded}
          populating={posSettingsPanel.posOptions.tenderTypes.loading}
          disabled={dependentFieldsUnavailable}
          loading={isProcessing}
          options={tenderTypes}
          value={selectedTenderType}
          hideIndicator
          isSearchable
          isClearable
          onMenuOpen={handleFetchTenderTypes}
          onChange={(data) => {
            updateLocationParam(!!data ? data.value : '', LOCATION_SETTINGS_ACTIONS.UPDATE_BRINK_POS_TENDER_TYPE);
          }}
        />
      </FormControl>
      <FormControl label='Card Specific Tender Types'>
        <div className='control--stacked'>
          <span className='form__control__sublabel'>Mastercard</span>
          <SelectFilter
            placeholder='Type to search tender types'
            populated={posSettingsPanel.posOptions.tenderTypes.loaded}
            populating={posSettingsPanel.posOptions.tenderTypes.loading}
            disabled={dependentFieldsUnavailable}
            loading={isProcessing}
            options={tenderTypes}
            value={selectedMastercardTenderType}
            hideIndicator
            isSearchable
            isClearable
            onMenuOpen={handleFetchTenderTypes}
            onChange={(data) => {
              updateLocationParam(!!data ? data.value : '', LOCATION_SETTINGS_ACTIONS.UPDATE_BRINK_POS_MASTERCARD);
            }}
          />
        </div>
        <div className='control--stacked'>
          <span className='form__control__sublabel'>Visa</span>
          <SelectFilter
            placeholder='Type to search tender types'
            populated={posSettingsPanel.posOptions.tenderTypes.loaded}
            populating={posSettingsPanel.posOptions.tenderTypes.loading}
            disabled={dependentFieldsUnavailable}
            loading={isProcessing}
            options={tenderTypes}
            value={selectedVisaTenderType}
            hideIndicator
            isSearchable
            isClearable
            onMenuOpen={handleFetchTenderTypes}
            onChange={(data) => {
              updateLocationParam(!!data ? data.value : '', LOCATION_SETTINGS_ACTIONS.UPDATE_BRINK_POS_VISA);
            }}
          />
        </div>
        <div className='control--stacked'>
          <span className='form__control__sublabel'>Amex</span>
          <SelectFilter
            placeholder='Type to search tender types'
            populated={posSettingsPanel.posOptions.tenderTypes.loaded}
            populating={posSettingsPanel.posOptions.tenderTypes.loading}
            disabled={dependentFieldsUnavailable}
            loading={isProcessing}
            options={tenderTypes}
            value={selectedAmexTenderType}
            hideIndicator
            isSearchable
            isClearable
            onMenuOpen={handleFetchTenderTypes}
            onChange={(data) => {
              updateLocationParam(!!data ? data.value : '', LOCATION_SETTINGS_ACTIONS.UPDATE_BRINK_POS_AMEX);
            }}
          />
        </div>
        <div className='control--stacked'>
          <span className='form__control__sublabel'>Discover</span>
          <SelectFilter
            placeholder='Type to search tender types'
            populated={posSettingsPanel.posOptions.tenderTypes.loaded}
            populating={posSettingsPanel.posOptions.tenderTypes.loading}
            disabled={dependentFieldsUnavailable}
            loading={isProcessing}
            options={tenderTypes}
            value={selectedDiscoverTenderType}
            hideIndicator
            isSearchable
            isClearable
            onMenuOpen={handleFetchTenderTypes}
            onChange={(data) => {
              updateLocationParam(!!data ? data.value : '', LOCATION_SETTINGS_ACTIONS.UPDATE_BRINK_POS_DISCOVER);
            }}
          />
        </div>
        <div className='control--stacked'>
          <span className='form__control__sublabel'>JCB</span>
          <SelectFilter
            placeholder='Type to search tender types'
            populated={posSettingsPanel.posOptions.tenderTypes.loaded}
            populating={posSettingsPanel.posOptions.tenderTypes.loading}
            disabled={dependentFieldsUnavailable}
            loading={isProcessing}
            options={tenderTypes}
            value={selectedJcbTenderType}
            hideIndicator
            isSearchable
            isClearable
            onMenuOpen={handleFetchTenderTypes}
            onChange={(data) => {
              updateLocationParam(!!data ? data.value : '', LOCATION_SETTINGS_ACTIONS.UPDATE_BRINK_POS_JCB);
            }}
          />
        </div>
        <div className='control--stacked'>
          <span className='form__control__sublabel'>Interac</span>
          <SelectFilter
            placeholder='Type to search tender types'
            populated={posSettingsPanel.posOptions.tenderTypes.loaded}
            populating={posSettingsPanel.posOptions.tenderTypes.loading}
            disabled={dependentFieldsUnavailable}
            loading={isProcessing}
            options={tenderTypes}
            value={selectedInteracTenderType}
            hideIndicator
            isSearchable
            isClearable
            onMenuOpen={handleFetchTenderTypes}
            onChange={(data) => {
              updateLocationParam(!!data ? data.value : '', LOCATION_SETTINGS_ACTIONS.UPDATE_BRINK_POS_INTERAC);
            }}
          />
        </div>
      </FormControl>
    </>
  );
};

export default BrinkPOS;
