import { ILocationEditModel, ILocationEditModelResponse } from '@ready/dashboardv2api.contracts';
import { setFormIsDirty } from '../../redux/actions/uiActions/formStateActions';
import { pageErrorState, set200Toast, toastErrorState } from '../../redux/actions/uiActions/responseStateActions';
import { getDropDownItems, setDropDownItems } from '../../redux/actions/uiActions/uiActions';
import { IAddressListOptions } from '../../services/types/address.type';
import LocationsService from '../services/LocationsService';
import {
  resetBrinkOptions,
  setBrandingPanel,
  setDetailsPanel,
  setDigitalMenuPanel,
  setEmailReceiptsPanel,
  setGratuityPanel,
  setLastEdit,
  setLocationCache,
  setMobileOrderingPanel,
  setNewsletterPanel,
  setOrderAheadPanel,
  setPayAtTablePanel,
  setPaymentProcessingPanel,
  setPosSettingsPanel,
  setPosSpecificSelections,
  setCompanyIntegrationsPanel,
  setServerFlowPanel,
} from './LocationSettingsActions';
import { locationTab } from './LocationSettingsState';
import { setLoyaltyCache, setLoyaltyPanel } from './PanelLoyaltyActions';

export const LOCATIONS_ACTIONS = {
  GET_LOCATIONS_LIST_BEGIN: 'GET_LOCATIONS_LIST_BEGIN',
  GET_LOCATIONS_LIST_SUCCESS: 'GET_LOCATIONS_LIST_SUCCESS',
  GET_LOCATIONS_LIST_ERROR: 'GET_LOCATIONS_LIST_ERROR',

  UPDATE_LOCATIONS_LIST_PARAMETERS: 'UPDATE_LOCATIONS_LIST_PARAMETERS',

  EXPORT_LOCATIONS_LIST_BEGIN: 'EXPORT_LOCATIONS_LIST_BEGIN',
  EXPORT_LOCATIONS_LIST_SUCCESS: 'EXPORT_LOCATIONS_LIST_SUCCESS',
  EXPORT_LOCATIONS_LIST_ERROR: 'EXPORT_LOCATIONS_LIST_ERROR',
};

export const setLocation = (location: ILocationEditModelResponse) => async (dispatch: any) => {
  dispatch(getDropDownItems(location));
  dispatch(setPosSpecificSelections(location));
  dispatch(setLocationCache(location));
  dispatch(setLastEdit(location));
  dispatch(setDetailsPanel(location));
  dispatch(setPayAtTablePanel(location));
  dispatch(setServerFlowPanel(location));
  dispatch(setMobileOrderingPanel(location));
  dispatch(setDigitalMenuPanel(location));
  dispatch(setOrderAheadPanel(location));
  dispatch(setPayAtTablePanel(location));
  dispatch(setBrandingPanel(location));
  dispatch(setPosSettingsPanel(location));
  dispatch(setLoyaltyPanel(location.loyaltyOptions));
  dispatch(setLoyaltyCache(location.loyaltyOptions));
  dispatch(setPaymentProcessingPanel(location));
  dispatch(setGratuityPanel(location));
  dispatch(setNewsletterPanel(location));
  dispatch(setEmailReceiptsPanel(location));
  dispatch(setCompanyIntegrationsPanel(location));
};

export const saveLocation = (locationId: string, location: ILocationEditModel) => async (dispatch: any) => {
  try {
    const updatedLocation = await LocationsService.updateAdminLocation(locationId, location);

    dispatch(setLocation(updatedLocation));
    dispatch(setFormIsDirty(false));
    dispatch(set200Toast());
  } catch (err) {
    dispatch(toastErrorState(err.status, err.message));
  }
};

export const loadLocation = (id: string) => async (dispatch: any) => {
  try {
    const location = await LocationsService.getAdminLocationById(id);
    dispatch(setLocation(location));
  } catch (err) {
    dispatch(setLocation(locationTab));
    dispatch(pageErrorState(err.status, err.message));
  }
};

export const resetLocation =
  (location: ILocationEditModelResponse, addressListOptions: IAddressListOptions) => async (dispatch: any) => {
    dispatch(setDropDownItems(location, addressListOptions));
    dispatch(resetBrinkOptions());
    dispatch(setPosSpecificSelections(location));
    dispatch(setLastEdit(location));
    dispatch(setDetailsPanel(location));
    dispatch(setPayAtTablePanel(location));
    dispatch(setServerFlowPanel(location));
    dispatch(setMobileOrderingPanel(location));
    dispatch(setDigitalMenuPanel(location));
    dispatch(setOrderAheadPanel(location));
    dispatch(setPayAtTablePanel(location));
    dispatch(setBrandingPanel(location));
    dispatch(setPosSettingsPanel(location));
    dispatch(setPaymentProcessingPanel(location));
    dispatch(setGratuityPanel(location));
    dispatch(setNewsletterPanel(location));
    dispatch(setEmailReceiptsPanel(location));
    dispatch(setFormIsDirty(false));
  };

// locations list
export const getLocationsList =
  (query: string = '', showHidden: boolean = false, page: number) =>
  async (dispatch: any) => {
    dispatch(getLocationsListBegin());
    try {
      const { items, start, length, limit, total } = await LocationsService.getLocations(query, showHidden, page);
      dispatch(getLocationsListSuccess(items, start, length, limit, total));
    } catch (err) {
      dispatch(getLocationsListError());
      dispatch(toastErrorState(err.status, err.message));
    }
  };

export const getLocationsListBegin = () => ({
  type: LOCATIONS_ACTIONS.GET_LOCATIONS_LIST_BEGIN,
});

export const getLocationsListSuccess = (items: any, start: number, length: number, limit: number, total: number) => ({
  type: LOCATIONS_ACTIONS.GET_LOCATIONS_LIST_SUCCESS,
  payload: {
    locations: items,
    pagination: { start, length, limit, total },
  },
});

export const getLocationsListError = () => ({
  type: LOCATIONS_ACTIONS.GET_LOCATIONS_LIST_ERROR,
});

export const updateLocationsListParameters = (query: string, page: string) => ({
  type: LOCATIONS_ACTIONS.UPDATE_LOCATIONS_LIST_PARAMETERS,
  payload: {
    query,
    page,
  },
});

export const exportLocations = () => async (dispatch: any) => {
  dispatch({ type: LOCATIONS_ACTIONS.EXPORT_LOCATIONS_LIST_BEGIN });
  try {
    await LocationsService.getLocationsListExport();
    dispatch({ type: LOCATIONS_ACTIONS.EXPORT_LOCATIONS_LIST_SUCCESS });
  } catch (err) {
    dispatch({ type: LOCATIONS_ACTIONS.EXPORT_LOCATIONS_LIST_ERROR });
    dispatch(toastErrorState(err.status, err.message));
  }
};
