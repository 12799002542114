import React from 'react';
import classNames from 'classnames';

import styles from './InfiniteScrollingList.module.scss';
import { useClassNames } from '../../utils/cssUtils';

import readyProgressSpinner from '../../styles/assets/ready-progress-spinner-gray.svg';
import Button from '../Button/Button';
import EmptyResult from '../EmptyResult/EmptyResult';
import RadioGroup from '../RadioGroup/RadioGroup';

export type InfiniteScrollingListProps = {
  readyToLoad?: boolean;
  loading: boolean;
  error: boolean;
  paginationLoading: boolean;
  paginationAvailable: boolean;
  filtered: boolean;
  items: any[];
  noItemsTitle?: string;
  noItemsMessage?: string;
  noItemsComponent?: React.ReactNode;
  noResultsComponent?: React.ReactNode;
  selectedItemKeys?: string[];
  unavailableItemKeys?: string[];
  addButtonLabel?: string;
  removeButtonLabel?: string;
  listAdditionalClassName?: string;
  loadItems: () => void;
  loadMoreItems: () => void;
  getItemKey: (item: any) => string;
  buildItemComponent: (item: any, controls: JSX.Element | null, unavailable?: boolean) => JSX.Element;
  addItem?: (item: any) => void;
  removeItem?: (itemId: string) => void;
  additionalListItemStyles?: string;
  isInModal?: boolean;
  disabledButtons?: boolean;
};

const InfiniteScrollingList = (props: InfiniteScrollingListProps): JSX.Element => {
  const {
    readyToLoad = true,
    loading,
    error,
    paginationLoading,
    paginationAvailable,
    filtered,
    items,
    noItemsTitle,
    noItemsMessage,
    noItemsComponent,
    noResultsComponent,
    selectedItemKeys,
    unavailableItemKeys,
    addButtonLabel = 'Add',
    removeButtonLabel = 'Remove',
    listAdditionalClassName,
    loadItems,
    loadMoreItems,
    getItemKey,
    buildItemComponent,
    addItem,
    removeItem,
    additionalListItemStyles,
    isInModal = false,
    disabledButtons = false,
  } = props;

  const fetchData = React.useCallback(() => {
    loadItems();
  }, [loadItems]);

  React.useEffect(() => {
    if (readyToLoad) {
      fetchData();
    }
  }, [readyToLoad, fetchData]);

  const controlsClassName = useClassNames(
    [{ controls: true }, { paginationLoading: paginationLoading }, { noMoreResults: !paginationAvailable || error }],
    styles
  );

  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    const element = event.target;
    const { scrollHeight, scrollTop, clientHeight } = element as HTMLInputElement & EventTarget;
    if (scrollTop >= scrollHeight - clientHeight) {
      if (!paginationLoading && paginationAvailable) {
        loadMoreItems();
      }
    }
  };

  const listClassName = useClassNames([styles.list, listAdditionalClassName, { isInModal }], styles);

  const includeAddRemoveControls = !!selectedItemKeys && addItem && removeItem;
  const includeRadioControls = !!selectedItemKeys && selectedItemKeys.length <= 1 && addItem && !removeItem;
  const selectedItemKey =
    includeRadioControls && !!selectedItemKeys && !!selectedItemKeys.length ? selectedItemKeys[0] : '';

  return (
    <>
      {loading ? (
        <div className={styles.loading}>
          <img src={readyProgressSpinner} alt='loading' />
        </div>
      ) : items.length === 0 && filtered ? (
        noResultsComponent || <div className={styles.emptyFilter}>No Results Found</div>
      ) : items.length === 0 && noItemsComponent ? (
        noItemsComponent
      ) : items.length === 0 && noItemsTitle ? (
        <div className={styles.empty}>
          <EmptyResult title={noItemsTitle} paragraph={noItemsMessage} />
        </div>
      ) : (
        <div className={listClassName} onScroll={handleScroll}>
          {items.map((item: any) => (
            <div className={classNames(styles.listItem, additionalListItemStyles)} key={getItemKey(item)}>
              {buildItemComponent(
                item,
                includeAddRemoveControls ? (
                  selectedItemKeys!.includes(getItemKey(item)) ? (
                    <Button
                      label={removeButtonLabel}
                      variant='toggle-selected'
                      disabled={(disabledButtons || unavailableItemKeys?.includes(getItemKey(item))) ?? false}
                      onClick={() => removeItem!(getItemKey(item))}
                    />
                  ) : (
                    <Button
                      label={addButtonLabel}
                      variant='toggle-unselected'
                      disabled={(disabledButtons || unavailableItemKeys?.includes(getItemKey(item))) ?? false}
                      onClick={() => addItem!(item)}
                    />
                  )
                ) : includeRadioControls ? (
                  <RadioGroup
                    groupName='posItems'
                    options={[{ value: getItemKey(item) }]}
                    value={selectedItemKey}
                    onChange={() => addItem!(item)}
                  />
                ) : null,
                !!unavailableItemKeys ? unavailableItemKeys.includes(getItemKey(item)) : false
              )}
            </div>
          ))}
          <div className={controlsClassName}>
            <img className={styles.spinner} src={readyProgressSpinner} alt='loading' />
            <p className={styles.endOfList}>End of Results</p>
          </div>
        </div>
      )}
    </>
  );
};

export default InfiniteScrollingList;
