import { initialEmailMarketingListState } from './initialState';
import { createSlice } from '@reduxjs/toolkit';
import { fetchEmailMarketingListThunk, fetchPermittedLocationsThunk } from './thunks';
import { fetchEmailMarketingListReducers, fetchPermittedLocationsReducers } from './reducers';

const feedbackReducerSlice = createSlice({
  name: 'emailMarketingList',
  initialState: initialEmailMarketingListState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEmailMarketingListThunk.pending, fetchEmailMarketingListReducers.pending);
    builder.addCase(fetchEmailMarketingListThunk.fulfilled, fetchEmailMarketingListReducers.fulfilled);
    builder.addCase(fetchEmailMarketingListThunk.rejected, fetchEmailMarketingListReducers.rejected);

    builder.addCase(fetchPermittedLocationsThunk.pending, fetchPermittedLocationsReducers.pending);
    builder.addCase(fetchPermittedLocationsThunk.fulfilled, fetchPermittedLocationsReducers.fulfilled);
    builder.addCase(fetchPermittedLocationsThunk.rejected, fetchPermittedLocationsReducers.rejected);
  },
});

const { actions: emailMarketingListActions, reducer: emailMarketingListReducer } = feedbackReducerSlice;
export { emailMarketingListActions, emailMarketingListReducer };
