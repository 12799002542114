import produce from 'immer';
import { Action } from '../../../redux/types';
import { RESPONSE_STATE_ACTION_TYPES } from '../../actions/uiActions/responseStateActionTypes';
import { responseState } from '../../initialStates/ui/responseState';

export const responseStateReducer = (state = responseState, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case RESPONSE_STATE_ACTION_TYPES.UPDATE_RESPONSE_STATE:
        draft = action.payload;
        return draft;

      case RESPONSE_STATE_ACTION_TYPES.RESET_RESPONSE_STATE:
        draft = responseState;
        return draft;

      default:
        return draft;
    }
  });
};
