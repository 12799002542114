import React from 'react';
import { compositeStyles } from '../../utils/cssUtils/useClassNames';
import styles from './ToggleFilter.module.scss';

export interface ToggleFilterOptions {
  label: string;
  selected: boolean;
  onClick: () => void;
}

export interface ToggleFilterProps {
  buttons: ToggleFilterOptions[];
}

const ToggleFilter = (props: ToggleFilterProps): JSX.Element => {
  const { buttons } = props;

  return (
    <div className={styles.container}>
      {buttons.map((button: ToggleFilterOptions, index: number) => (
        <button
          className={compositeStyles(
            [
              styles.button,
              {
                selected: button.selected,
              },
            ],
            styles
          )}
          onClick={button.onClick}
          key={index}
        >
          {button.label}
        </button>
      ))}
    </div>
  );
};

export default ToggleFilter;
