import { createAsyncThunk } from '@reduxjs/toolkit';
import { toastErrorState } from 'redux/actions/uiActions/responseStateActions';
import { UserContextService } from 'services/userContextsService';

const getCompanyListForAdminThunk = createAsyncThunk(
  'admin/companies/list/fetch',
  async (args: { query?: ''; pageNumber?: 1 }, thunkAPI) => {
    try {
      const contexts = await UserContextService.getUserContexts(args.query, args.pageNumber);
      // filter out contexts of type 'system' as those aren't actual companies
      return contexts.filter((context) => context.type !== 'system');
    } catch (error) {
      thunkAPI.dispatch(toastErrorState(error.status, error.message));
    }
  }
);

export { getCompanyListForAdminThunk };
