import { IPermissionSetMetadata } from '@ready/security.core';
import produce from 'immer';
import { Feature } from 'utils/featureToggle/features';
import { checkFeatureToggle } from 'utils/featureToggle/featureToggle';
import { Action } from '../../../redux/types';
import { SECURITY_ROLES_METADATA } from '../actions/types';
import { securityMetadata } from '../initialStates/securityMetadata';

/**
 * Map of Feature and list of permission to be filtered in the Security Role Details Page.
 * If the feature toggle (key) is not enabled on the browser,
 * the listed permission labels (value) will not be stored in redux nor displayed.
 * Example:  app_sharedMenuItems: ['Company Level Menu Builder - Full Access'],
 */
const FeatureToggledMetadata: FeatureToggledMetadataMap = {};

export const securityMetadataReducer = (state = securityMetadata, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SECURITY_ROLES_METADATA.LOAD_PERMISSIONS_SET_BEGIN:
        draft.permissions.loading = true;
        draft.permissions.error = false;
        return draft;

      case SECURITY_ROLES_METADATA.LOAD_PERMISSIONS_SET_SUCCESS:
        const filteredPermissions = filterMetadataBasedOnFeatureToggles(action.payload, FeatureToggledMetadata);
        draft.permissions.permissionSet = filteredPermissions;
        draft.permissions.loading = false;
        draft.permissions.error = false;
        return draft;

      case SECURITY_ROLES_METADATA.LOAD_PERMISSIONS_SET_ERROR:
        draft.permissions.error = true;
        draft.permissions.loading = false;
        return draft;

      default:
        return draft;
    }
  });
};

export type FeatureToggledMetadataMap = { [key in Feature | string]?: string[] };

export const filterMetadataBasedOnFeatureToggles = (
  securityMetadata: IPermissionSetMetadata,
  featureToggleMetadataMap: FeatureToggledMetadataMap
): IPermissionSetMetadata => {
  const permissionsToOmit: string[] = [];

  for (const key in featureToggleMetadataMap) {
    const featureKey = key as Feature;
    if (!checkFeatureToggle(featureKey)) {
      const permissions = featureToggleMetadataMap[featureKey];
      if (permissions) {
        permissionsToOmit.push(...permissions);
      }
    }
  }

  if (permissionsToOmit.length === 0) {
    return securityMetadata;
  }

  return {
    companyPermissions: securityMetadata.companyPermissions.filter((x) => !permissionsToOmit.includes(x.label)),
    locationPermissions: securityMetadata.locationPermissions.filter((x) => !permissionsToOmit.includes(x.label)),
  };
};
