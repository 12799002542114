import { OrderIntegrationType } from '@ready/dashboardv2api.contracts';
import Checkbox from 'components/Checkbox/Checkbox';
import { FormControl } from 'components/Form';
import RadioGroup, { RadioOption } from 'components/RadioGroup/RadioGroup';
import TextInput from 'components/TextInput/TextInput';
import { LOCATION_SETTINGS_ACTIONS } from 'locations/redux/LocationSettingsActions';
import React from 'react';
import { setFormIsDirty } from 'redux/actions/uiActions/formStateActions';
import { useAppDispatch, useAppSelector } from 'redux/store';
import styles from './SubmitOrderToPosFormControl.module.scss';

const options: RadioOption<OrderIntegrationType>[] = [
  { label: 'Automatically', value: OrderIntegrationType.Full },
  { label: 'Manually', value: OrderIntegrationType.Semi },
  { label: 'Never', value: OrderIntegrationType.Unintegrated },
];

const SubmitOrderToPosFormControl = () => {
  const mobileOrdering = useAppSelector((state) => state.location.featuresPanel.mobileOrdering);
  const { orderIntegrationType, emailSubmissionAddress, emailSubmissionEnabled } = mobileOrdering.orderIntegration;

  const dispatch = useAppDispatch();

  return (
    <FormControl label='Submit Orders to POS'>
      <RadioGroup
        groupName={'submitOrderToPos'}
        options={options}
        value={orderIntegrationType || OrderIntegrationType.Full}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          dispatch(setFormIsDirty(true));
          dispatch({ type: LOCATION_SETTINGS_ACTIONS.UPDATE_SUBMIT_ORDERS_TO_POS, payload: e.target.value });
        }}
      ></RadioGroup>

      {orderIntegrationType === OrderIntegrationType.Unintegrated && (
        <>
          <div className='control--stacked control--stacked--indented'>
            <Checkbox
              label='Send orders via email'
              checked={emailSubmissionEnabled ?? false}
              onChange={() => {
                dispatch(setFormIsDirty(true));

                dispatch({ type: LOCATION_SETTINGS_ACTIONS.UPDATE_ORDER_BY_EMAIL, payload: !emailSubmissionEnabled });
              }}
            />
          </div>

          <div className={`control--stacked ${styles.emailAddressField}`}>
            <TextInput
              placeholder='Email Address'
              value={emailSubmissionAddress}
              disabled={emailSubmissionEnabled === false}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                dispatch(setFormIsDirty(true));

                dispatch({
                  type: LOCATION_SETTINGS_ACTIONS.UPDATE_ORDER_EMAIL_ADDRESS,
                  payload: e.target.value,
                });
              }}
            />
          </div>
        </>
      )}
    </FormControl>
  );
};

export { SubmitOrderToPosFormControl };
