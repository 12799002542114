import { IPaymentsReportState } from './types';
import { IPaymentDataReportItem, PaginationResponse } from '@ready/dashboardv2api.contracts';
import { IPagedResponse } from '@ready/table.core';
import { ILocation } from '../../../services/types/ILocation.type';

export const fetchPaymentsReportReducers = {
  pending: (state: IPaymentsReportState) => {
    state.loading.list = true;
  },
  fulfilled: (state: IPaymentsReportState, action: { payload: PaginationResponse<IPaymentDataReportItem> }) => {
    const { items, total, limit, start, length } = action.payload;
    state.loading.list = false;
    state.items = items;
    state.pagination = { total, limit, start, length };
  },
  rejected: (state: IPaymentsReportState) => {
    state.loading.list = false;
    state.errors.list = 'Unable to retrieve Promo Code Summary list.';
  },
};

export const fetchPermittedLocationsReducers = {
  pending: (state: IPaymentsReportState) => {
    state.loading.permittedLocations = true;
  },
  fulfilled: (state: IPaymentsReportState, action: { payload: IPagedResponse<ILocation> }) => {
    state.loading.permittedLocations = false;
    state.permittedLocations = action.payload;
  },
  rejected: (state: IPaymentsReportState) => {
    state.loading.permittedLocations = false;
  },
};
