import React from 'react';
import { IMenuSectionItem, IMenuSectionItemGroup, Visibility } from '@ready/menu.core';
import useS3ImagesMap, { IImageS3Request } from '../../../hooks/useS3Images';
import styles from './MenuItemAndItemGroupList.module.scss';
import MenuItemCard from '../ItemsAndMods/MenuItemCard';
import ItemGroupCard from '../ItemGroups/ItemGroupCard';
import InfoCardColumn from '../../../components/InfoCard/InfoCardColumn';
import IconButton from '../../../components/IconButton/IconButton';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';

interface Props {
  disabled: boolean;
  parentDisabled: boolean;
  isSharedMenu?: boolean;
  items: (IMenuSectionItem | IMenuSectionItemGroup)[];
  getMenuItemLink: (menuItemId: string) => string;
  getItemGroupLink: (itemGroupId: string) => string;
  onItemVisibilityClick?: (itemId: string, visible: boolean) => void;
  onGroupItemVisibilityClick?: (groupId: string, itemId: string, visible: boolean) => void;
}

const buildVisibilityButton = (loading: boolean, isVisible: boolean, onClick: () => void): JSX.Element => (
  <InfoCardColumn columnClassName={styles.cardControls}>
    <IconButton
      onClick={onClick}
      loading={loading}
      additionalClassName={isVisible ? styles.cardControls : styles.cardControlsHidden}
    >
      {isVisible ? <TextIcon icon={Icon.EyeOpen} /> : <TextIcon icon={Icon.EyeClosed} />}
    </IconButton>
  </InfoCardColumn>
);

const MenuItemAndItemGroupList: React.FC<Props> = ({
  disabled,
  parentDisabled,
  isSharedMenu,
  items,
  getMenuItemLink,
  getItemGroupLink,
  onItemVisibilityClick = () => {},
  onGroupItemVisibilityClick = () => {},
}) => {
  const [imageUrls, setImageUrls] = React.useState<{ [id: string]: string }>({});
  const getS3ImagesMap = useS3ImagesMap();

  React.useEffect(() => {
    const loadImages = async (items: (IMenuSectionItem | IMenuSectionItemGroup)[]) => {
      let imageRequests: IImageS3Request[] = [];
      // a menu item could be in the itmes and again in an item group in the items,
      // so track requested IDs to prevent duplicates
      let requestedIds: string[] = [];
      items.forEach((item) => {
        if (!!item.thumbnailImageId) {
          // add thumbnail for the menu item or item group if not already added
          if (!requestedIds.includes(item.itemId)) {
            imageRequests.push({
              id: item.itemId,
              fileKey: item.thumbnailImageId,
            });
            requestedIds.push(item.itemId);
          }
        }
        if (item.sectionItemType === 'group') {
          // add thumbnails for menu items in the item group if not already added
          item.items
            .filter((groupItem) => !!groupItem.thumbnailImageId)
            .forEach((groupItem) => {
              if (!requestedIds.includes(groupItem.itemId)) {
                imageRequests.push({
                  id: groupItem.itemId,
                  fileKey: groupItem.thumbnailImageId!,
                });
                requestedIds.push(groupItem.itemId);
              }
            });
        }
      });
      const s3ImageUrls = await getS3ImagesMap(imageRequests);
      setImageUrls(s3ImageUrls);
    };
    if (items.length > 0) {
      loadImages(items);
    }
  }, [items, getS3ImagesMap]);

  return (
    <>
      {items.map((item) =>
        item.sectionItemType === 'item' ? (
          <MenuItemCard
            key={item.itemId}
            menuItem={item}
            imageUrl={imageUrls[item.itemId]}
            linkTo={getMenuItemLink(item.itemId)}
            parentDisabled={parentDisabled}
            hidden={item.visibility === Visibility.hidden}
            controls={buildVisibilityButton(disabled, item.visibility === Visibility.visible, () =>
              onItemVisibilityClick(item.itemId, item.visibility === Visibility.hidden)
            )}
            isSharedMenu={isSharedMenu}
          />
        ) : (
          <React.Fragment key={item.itemId}>
            <ItemGroupCard
              imageUrl={imageUrls[item.itemId]}
              linkTo={getItemGroupLink(item.itemId)}
              name={item.displayName}
              description={item.description}
              quantity={item.items.length}
              parentDisabled={parentDisabled}
              hidden={item.visibility === Visibility.hidden}
              controls={buildVisibilityButton(disabled, item.visibility === Visibility.visible, () =>
                onItemVisibilityClick(item.itemId, item.visibility === Visibility.hidden)
              )}
              isSharedMenu={isSharedMenu}
            />
            {(item as IMenuSectionItemGroup).items.map((groupItem) => (
              <MenuItemCard
                key={`${item.itemId}-${groupItem.itemId}`}
                menuItem={groupItem}
                imageUrl={imageUrls[groupItem.itemId]}
                secondLevel={true}
                linkTo={getMenuItemLink(groupItem.itemId)}
                parentDisabled={parentDisabled}
                hidden={groupItem.visibility === Visibility.hidden}
                controls={buildVisibilityButton(disabled, groupItem.visibility === Visibility.visible, () =>
                  onGroupItemVisibilityClick(item.itemId, groupItem.itemId, groupItem.visibility === Visibility.hidden)
                )}
                isSharedMenu={isSharedMenu}
              />
            ))}
          </React.Fragment>
        )
      )}
    </>
  );
};

export default MenuItemAndItemGroupList;
