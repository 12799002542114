import React from 'react';
import IconButton from '../IconButton/IconButton';
import DropDownMenu, { DropDownMenuProps, DropDownMenuBaseOption } from '../DropDownMenu/DropDownMenu';
import useHideDropdownMenu from '../../hooks/useHideDropdownMenu';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { selectOverflowButtonState } from 'redux/selectors/uiSelectors/uiSelectors';
import { IOverflowButtonState } from 'redux/initialStates/ui/overflowButtonState';
import { updateCurrentlyOpenDropdownOverflowButtonId } from 'redux/actions/uiActions/overflowButtonStateActions';
import { v4 as uuid } from 'uuid';
import classnames from 'classnames';

export interface OverflowButtonProps extends DropDownMenuProps {
  disabled?: boolean;
  className?: string;
}

const OverflowButton = (props: OverflowButtonProps) => {
  const { options, disabled = false, className } = props;
  const dispatch = useAppDispatch();
  const buttonId = React.useRef<string>(uuid());

  const { currentlyOpenOverflowButtonId } = useAppSelector<IOverflowButtonState>(selectOverflowButtonState);
  useHideDropdownMenu();

  const toggleVisible = () => {
    if (buttonId.current === currentlyOpenOverflowButtonId) {
      // second click to close button
      dispatch(updateCurrentlyOpenDropdownOverflowButtonId(undefined));
    } else {
      // first click to open button
      dispatch(updateCurrentlyOpenDropdownOverflowButtonId(buttonId.current));
    }
  };

  const baseOptions: DropDownMenuBaseOption[] = options as DropDownMenuBaseOption[];

  const additionalIconClassName = baseOptions.find((option) => option.loading) ? 'pulse' : '';

  return (
    <div className={classnames('overflow-button', className)}>
      <div className='overflow-button__button-wrapper'>
        <IconButton
          disabled={disabled}
          onClick={(e) => {
            toggleVisible();
            e.stopPropagation();
          }}
          additionalClassName={`icon-button-overflow ${additionalIconClassName}`}
        >
          <i className='icon-dashboard-icons_ellipsis iOScolor'></i>
        </IconButton>
      </div>
      <DropDownMenu
        options={options}
        isVisible={currentlyOpenOverflowButtonId === buttonId.current}
        onOptionClicked={() => {
          dispatch(updateCurrentlyOpenDropdownOverflowButtonId(undefined));
        }}
      />
    </div>
  );
};

export default OverflowButton;
