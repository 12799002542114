import { IAdminUser, PaginationResponse } from '@ready/dashboardv2api.contracts';

export interface IUIAdmin extends IAdminUser {}

export type IPaginatedAdmins = PaginationResponse<IUIAdmin>;

export interface IAdminList {
  admins: IPaginatedAdmins;
  loading: boolean;
  error: boolean;
}

export const adminsList: IAdminList = {
  admins: {
    items: [],
    length: 0,
    limit: 0,
    start: 0,
    total: 0,
  },
  loading: false,
  error: false,
};
