import React from 'react';

import Icon from '../Icon/Icon';
import MissingValue from './MissingValue';

interface IconValueProps {
  value: string;
}

const Value = ({ value }: IconValueProps): JSX.Element => (!!value ? <Icon name={value} /> : <MissingValue />);

export default Value;
