import { ITemplateModifierGroup } from '@ready/menu.core';
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import InfiniteScrollingListState from 'types/InfiniteScrollingListState.interface';
import InfiniteScrollingListStateHelper from 'utils/InfiniteScrollingList/InfiniteScrollingListStateHelper';
import { getModifierGroupsForSelectModalThunk } from './thunks';

interface State extends InfiniteScrollingListState<ITemplateModifierGroup> {}

const initialState: State = {
  ...InfiniteScrollingListStateHelper.initialState(),
};

export const selectModifierGroupModalSlice = createSlice({
  name: 'sharedMenuItems/modifierGroups/modal',
  initialState: initialState,
  reducers: {
    select: (state: State, action: { payload: string }): void => {
      InfiniteScrollingListStateHelper.selectItemState(state, action.payload);
    },
    deselect: (state: State, action: { payload: string }): void => {
      InfiniteScrollingListStateHelper.unselectItemState(state, action.payload);
    },
    prepare: (state: State, action: { payload: string[] }): void => {
      InfiniteScrollingListStateHelper.prepareState(state, true, action.payload);
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<State>) => {
    builder.addCase(getModifierGroupsForSelectModalThunk.pending, (state: State, action) => {
      if (action.meta.arg.isLoadMore === false) {
        InfiniteScrollingListStateHelper.loadingState(state);
      } else {
        InfiniteScrollingListStateHelper.loadingMoreState(state);
      }
    });

    builder.addCase(getModifierGroupsForSelectModalThunk.rejected, (state: State, action) => {
      if (action.meta.arg.isLoadMore === false) {
        InfiniteScrollingListStateHelper.loadFailedState(state);
      } else {
        InfiniteScrollingListStateHelper.loadMoreFailedState(state);
      }
    });

    builder.addCase(getModifierGroupsForSelectModalThunk.fulfilled, (state: State, action) => {
      if (action.meta.arg.isLoadMore === false) {
        InfiniteScrollingListStateHelper.loadedState(state, action.payload.items);
      } else {
        if (action.payload.items.length > 0) {
          InfiniteScrollingListStateHelper.loadedMoreState(state, action.payload.items);
        } else {
          InfiniteScrollingListStateHelper.loadMoreNoItemsState(state);
        }
      }
    });
  },
});

export const { select, deselect, prepare } = selectModifierGroupModalSlice.actions;
