import React from 'react';
import { DayOfWeek, IPromoCode, ValidationType } from '@ready/dashboardv2api.contracts';
import { DayOfWeek as MenuDayOfWeek } from '@ready/menu.core';
import { Discount, IPromoCodeValidation } from '../../redux/PromoCodesState';

import styles from './PromoCodeForm.module.scss';
import { Form, FormActionButtons, FormControl } from '../../../components/Form';
import Toggle from '../../../components/Toggle/Toggle';
import TextInput from '../../../components/TextInput/TextInput';
import Checkbox from '../../../components/Checkbox/Checkbox';
import SelectFilter, { Option } from '../../../components/SelectFilter/SelectFilter';
import TimeSlotSelector from '../../../components/TimeSlotSelector/TimeSlotSelector';
import Button from '../../../components/Button/Button';
import { setFormIsDirty } from 'redux/actions/uiActions/formStateActions';
import { useAppDispatch } from 'redux/store';

interface PromoCodeFormProps {
  promoCode: IPromoCode;
  processing: boolean;
  discounts: Discount[];
  validation: IPromoCodeValidation;
  saveLabel?: string;
  onStatusChange: (enabled: boolean) => void;
  onNameChange: (name: string) => void;
  onDescriptionChange: (description: string) => void;
  onPosDiscountChange: (posDiscount: string) => void;
  onValidationChange: (validation: string) => void;
  onAutoAttachChange: (autoAttach: boolean) => void;
  onTimeSlotAdd: () => void;
  onTimeSlotRemove: (index: number) => void;
  onTimeSlotChange: (
    index: number,
    daysOfWeek: number[],
    startHour?: number,
    startMinute?: number,
    endHour?: number,
    endMinute?: number
  ) => void;
  onSaveClick: () => void;
  onCancelClick: () => void;
}

const PromoCodeForm = (props: PromoCodeFormProps): JSX.Element => {
  const {
    promoCode,
    processing,
    discounts,
    validation,
    saveLabel,
    onStatusChange,
    onNameChange,
    onDescriptionChange,
    onPosDiscountChange,
    onValidationChange,
    onAutoAttachChange,
    onTimeSlotAdd,
    onTimeSlotRemove,
    onTimeSlotChange,
    onSaveClick,
    onCancelClick,
  } = props;

  const posDiscounts: Option[] = discounts.map((discount) => ({
    value: discount.posId,
    label: discount.name,
  }));
  const posDiscount = posDiscounts.find((option) => option.value === promoCode.posDiscountId);

  const validationMethods: Option[] = [
    {
      value: ValidationType.OrderTime,
      label: 'Time the order was placed',
    },
    {
      value: ValidationType.PickUpTime,
      label: 'Scheduled pick up time',
    },
  ];
  const validationMethod = validationMethods.find((option) => option.value === promoCode.validationType);

  const modifiedTimeSlots = promoCode.timeSlots.map((timeSlot) => ({
    daysOfWeek: timeSlot.daysAvailable
      .filter((day) => day.available)
      // time slot component uses 7 for Sunday, while back-end uses 0
      .map((dayAvailable) => (dayAvailable.day === DayOfWeek.Sunday ? MenuDayOfWeek.sunday : dayAvailable.day)),
    startHour: timeSlot.startHour,
    startMinute: timeSlot.startMinute,
    endHour: timeSlot.endHour,
    endMinute: timeSlot.endMinute,
  }));

  const dispatch = useAppDispatch();

  const onStatusChangeInner = (enabled: boolean) => {
    dispatch(setFormIsDirty(true));
    onStatusChange(enabled);
  };

  return (
    <Form onSubmit={onSaveClick}>
      <FormControl label='Status'>
        <Toggle checked={promoCode.enabled} onChange={onStatusChangeInner} loading={processing} />
      </FormControl>

      <FormControl
        label='Promo Code *'
        withError={validation.code.hasError}
        errorMessage={validation.code.errorMessage}
      >
        <TextInput
          value={promoCode.code}
          placeholder='e.g. 15OFFDISC'
          onChange={(event) => onNameChange(event.target.value)}
          withError={validation.code.hasError}
          loading={processing}
        />
      </FormControl>

      <FormControl label='Description'>
        <TextInput
          value={promoCode.description}
          placeholder='eg. Available from 9-5 only'
          onChange={(event) => onDescriptionChange(event.target.value)}
          loading={processing}
        />
      </FormControl>

      <FormControl
        label='Link to POS Discount *'
        withError={validation.posDiscountId.hasError}
        errorMessage={validation.posDiscountId.errorMessage}
      >
        <SelectFilter
          options={posDiscounts}
          onChange={(selection) => onPosDiscountChange(`${selection.value}`)}
          value={posDiscount}
          isSearchable
          placeholder='Type to select POS discount'
          loading={processing}
          withError={validation.posDiscountId.hasError}
        />
      </FormControl>

      <FormControl label='Validate Based On'>
        <SelectFilter
          options={validationMethods}
          onChange={(selection) => onValidationChange(`${selection.value}`)}
          value={validationMethod}
          loading={processing}
        />
      </FormControl>

      <FormControl label='Auto Attach'>
        <Checkbox
          checked={promoCode.autoAttach}
          label='Automatically attach this promo code to every order'
          onChange={(event) => onAutoAttachChange(event.target.checked)}
          loading={processing}
        />
      </FormControl>

      <FormControl
        label='Availability'
        labelTooltip='You can restrict a promo code’s availability so that it can only be used on certain days and time.'
      >
        {!!modifiedTimeSlots.length &&
          modifiedTimeSlots.map((timeSlot, index) => (
            <TimeSlotSelector
              key={index}
              value={timeSlot}
              onChange={(timeSlot) =>
                onTimeSlotChange(
                  index,
                  timeSlot.daysOfWeek,
                  timeSlot.startHour,
                  timeSlot.startMinute,
                  timeSlot.endHour,
                  timeSlot.endMinute
                )
              }
              onClearClick={() => onTimeSlotRemove(index)}
              processing={processing}
              daysOfWeekErrorMessage={validation.timeSlots[index].daysOfWeek.errorMessage}
              startTimeErrorMessage={validation.timeSlots[index].startTime.errorMessage}
              endTimeErrorMessage={validation.timeSlots[index].endTime.errorMessage}
            />
          ))}
        <Button
          label='+ Add Time Slot'
          variant='secondary'
          size='large'
          onClick={onTimeSlotAdd}
          additionalStyles={styles.addTimeSlotButton}
          loading={processing}
        />
      </FormControl>

      <FormActionButtons saveButtonLabel={saveLabel} handleCancel={onCancelClick} loading={processing} />
    </Form>
  );
};

export default PromoCodeForm;
