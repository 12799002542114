import { SECURITY_ROLES_LIST_TYPES } from './types';
import { PaginationResponse } from '@ready/dashboardv2api.contracts';
import { pageErrorState } from '../uiActions/responseStateActions';
import SecurityRolesService from '../../../securityRoles/services/securityRolesService';
import { IRole } from '@ready/security.core';

export const initializeSecurityRoleslist = () => ({
  type: SECURITY_ROLES_LIST_TYPES.INITIALIZE_SECURITY_ROLES_LIST,
});

export const loadSecurityRolesList = (companyId: string, query: string, page: number) => async (dispatch: any) => {
  dispatch(setSecurityRolesListLoading(true));

  try {
    const securityRolesList = await SecurityRolesService.getSecurityRoles(companyId, query, page);

    dispatch(setSecurityRolesList(securityRolesList));
  } catch (error) {
    dispatch(initializeSecurityRoleslist());
    dispatch(pageErrorState(error.status, error.message));
  } finally {
    dispatch(setSecurityRolesListLoading(false));
  }
};

export const setSecurityRolesListLoading = (isLoading: boolean) => ({
  type: SECURITY_ROLES_LIST_TYPES.SET_SECURITY_ROLES_LIST_LOADING,
  payload: isLoading,
});

export const setSecurityRolesList = (securityRolesList: PaginationResponse<IRole>) => ({
  type: SECURITY_ROLES_LIST_TYPES.SET_SECURITY_ROLES_LIST,
  payload: securityRolesList,
});
