import React from 'react';
import IconLink from '../../../components/IconLink/IconLink';
import Button, { ButtonProps } from '../../../components/Button/Button';
import styles from './TablesActionHeader.module.scss';
import OverflowButton from '../../../components/OverflowButton/OverflowButton';
import { sortBy } from 'lodash';
import { useClassNames } from '../../../utils/cssUtils';
import { BackLink } from '../../../types/BackLink.interface';
import getBackLinkUrl from '../../../utils/urlUtils/getBackLinkUrl';

interface SubTextProps {
  text: string | undefined;
}

const SubText = ({ text }: SubTextProps) => (text ? <p className={styles.subText}>{text}</p> : null);

interface Action extends ButtonProps {
  hidden?: boolean;
  overflowOrder?: number;
}

interface ActionProps {
  actions: Array<Action> | undefined;
  loading?: boolean;
}

const sortedActions = (actions: Array<Action>) => sortBy(actions, (action) => action.overflowOrder);

const doNothingCallback = () => {};

const Actions = ({ actions, loading }: ActionProps) =>
  actions ? (
    <div className={styles.actions}>
      <div className={styles.actionMenu}>
        <OverflowButton
          options={sortedActions(actions).map((action) => ({
            label: action.label,
            loading: loading,
            onClick: action.onClick || doNothingCallback,
            hidden: action.hidden,
          }))}
        />
      </div>
      <div className={styles.actionButtons}>
        {actions
          .filter((action) => !action.hidden)
          .map((action) => (
            <div key={`back-link-button-container__${action.label}`} className={styles.actionButton}>
              <Button key={`back-link-button__${action.label}`} {...action} />
            </div>
          ))}
      </div>
    </div>
  ) : null;

export interface TablesActionHeaderProps {
  text: string;
  backLinkTo?: BackLink | string;
  subText?: string;
  actionButtons?: Array<Action>;
  additionalTextStyles?: string;
  textOverflowStyle?: 'none' | 'wrap-all' | 'wrap-word' | 'ellipsis';
  loading?: boolean;
}

const TablesActionHeader = (props: TablesActionHeaderProps) => {
  const { text, backLinkTo, subText, actionButtons, additionalTextStyles, textOverflowStyle = 'none', loading } = props;

  let backLinkUrl: string | undefined = undefined;
  if (backLinkTo) {
    if (typeof backLinkTo === 'string') {
      backLinkUrl = backLinkTo;
    } else {
      backLinkUrl = getBackLinkUrl(backLinkTo);
    }
  }

  const textClass = useClassNames(
    [
      {
        wrapAll: textOverflowStyle === 'wrap-all',
        wrapWord: textOverflowStyle === 'wrap-word',
        ellipsis: textOverflowStyle === 'ellipsis',
      },
      styles.text,
      additionalTextStyles,
    ],
    styles
  );

  return (
    <div className={styles.container}>
      <div className={styles.backLinkSection}>
        {backLinkUrl && (
          <IconLink to={backLinkUrl}>
            <i className={`${styles.leftArrowIcon} icon-dashboard-icons_arrow-left`}></i>
          </IconLink>
        )}
        <h2 className={textClass}>{text}</h2>
      </div>
      <SubText text={subText} />
      <Actions loading={loading} actions={actionButtons} />
    </div>
  );
};

export default TablesActionHeader;
