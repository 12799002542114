import React from 'react';
import { ITemplateEmbeddedMenuItem } from '@ready/menu.core';
import TextIcon, { Icon } from 'components/Icon/TextIcon';
import IconButton from 'components/IconButton/IconButton';
import InfoCardColumn from 'components/InfoCard/InfoCardColumn';
import { InfoCardColumnsWrapper } from 'components/InfoCardColumnsWrapper/InfoCardColumnsWrapper';
import MenuItemImage from 'menus/components/ItemsAndMods/MenuItemImage';
import MenuItemNameBlock from 'menus/components/ItemsAndMods/MenuItemNameBlock';
import styles from './GroupItemsCard.module.scss';
import { useAppDispatch } from 'redux/store';
import { removeItemGroupItem } from '../../redux/slice';
import { SharedMenuItemsRoutes } from 'sharedMenuItems/Router';
import { ContextParams } from 'types/ContextParams.interface';
import { useParams } from 'react-router-dom';
import { setFormIsDirty } from 'redux/actions/uiActions/formStateActions';

interface Props {
  item: ITemplateEmbeddedMenuItem;
  itemImgUrl: string;
  dragHandle?: JSX.Element;
  isPageProcessing: boolean;
}

export const GroupItemsCard = (props: Props) => {
  const { item, itemImgUrl, dragHandle, isPageProcessing } = props;
  const dispatch = useAppDispatch();
  const { contextId: companyId, id } = useParams<ContextParams>();
  return (
    <div className={styles.draggableItemCard}>
      {dragHandle}

      <InfoCardColumnsWrapper linkTo={SharedMenuItemsRoutes.getItemGroupsFromItemPage(companyId, id, item.itemId)}>
        <InfoCardColumn width={100} columnClassName={styles.details}>
          <div className={styles.imageAndDetails}>
            <div className={styles.image}>
              <MenuItemImage type={item.itemType} name={item.displayName} url={itemImgUrl} />
            </div>

            <MenuItemNameBlock displayName={item.displayName} description={item.description} />
          </div>
        </InfoCardColumn>
      </InfoCardColumnsWrapper>

      <IconButton
        additionalClassName={styles.button}
        disabled={isPageProcessing}
        onClick={() => {
          dispatch(removeItemGroupItem(item.itemId));
          dispatch(setFormIsDirty(true));
        }}
      >
        <TextIcon icon={Icon.RemoveCircle} />
      </IconButton>
    </div>
  );
};
