import React from 'react';
import InfoCardColumn from '../../../components/InfoCard/InfoCardColumn';
import InfoCardDataRow from '../../../components/InfoCard/InfoCardDataRow';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';
import styles from './TotalColumn.module.scss';
import classNames from 'classnames';
import { DollarValue } from '../../../components/Value';

interface TotalColumnProps {
  total: number;
  subtotal: number;
  tip: number;
  hidden?: boolean;
  hasRefund?: boolean;
  width: number;
  additionalStyles?: string;
}

const TotalColumn = ({
  total,
  subtotal,
  tip,
  hidden = false,
  hasRefund = false,
  width,
  additionalStyles = '',
}: TotalColumnProps) =>
  hidden ? null : (
    <InfoCardColumn width={width} columnClassName={`totals ${additionalStyles}`}>
      <InfoCardDataRow>
        <div className={styles.row}>
          <HasRefundIcon hidden={!hasRefund} />
          <DollarValue value={total} />
        </div>
        <div className={classNames(styles.row, styles.detail)}>
          <DollarValue value={subtotal} />
          {' + '}
          <DollarValue value={tip} variant='special' />
        </div>
      </InfoCardDataRow>
    </InfoCardColumn>
  );

interface HasRefundIconProps {
  hidden: boolean;
}

const HasRefundIcon = ({ hidden }: HasRefundIconProps) =>
  hidden ? null : <TextIcon icon={Icon.ReverseArrow} additionalStyles={styles.refundIcon} />;

export default TotalColumn;
