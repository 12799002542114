import React from 'react';

import { ICachedPosMenuItem, ICachedPosModifier } from '@ready/menu.core';

import styles from './LinkPosItemListItem.module.scss';
import { DollarValue } from '../../../components/Value';

export interface LinkPosItemListItemProps {
  item: ICachedPosMenuItem | ICachedPosModifier;
  controls: JSX.Element;
}

const LinkPosItemListItem = (props: LinkPosItemListItemProps): JSX.Element => {
  const {
    item: { externalId, name, categories = [], price },
    controls,
  } = props;

  return (
    <div className={styles.container}>
      <div className={styles.controls}>
        <div className={styles.button}>{controls}</div>
      </div>

      <div className={styles.content}>
        <div className={styles.description}>
          <div className={styles.name}>{name}</div>
          <div className={styles.pos}>
            <span className={styles.id}>{externalId}</span>
            <span className={styles.separator}>|</span>
            <span className={styles.categories}>{(categories ?? []).join(', ')}</span>
          </div>
        </div>

        <div className={styles.price}>{price !== undefined && <DollarValue value={price} />}</div>
      </div>
    </div>
  );
};

export default LinkPosItemListItem;
