import { IDropDownList } from '../../../utils/selectListUtils/listOptions.interface';
import { Option } from '../../../components/SelectFilter/SelectFilter';
import { IAddressListOptions } from '../../../services/types/address.type';

const defaultOptions: IDropDownList = {
  options: [] as Option[],
  selectedOption: {} as Option,
};

export interface IUIDropDown {
  country: IDropDownList;
  state: IDropDownList;
  timezone: IDropDownList;
  currency: IDropDownList;
  locale: IDropDownList;
  orderTime: IDropDownList;
  acceptOrders: IDropDownList;
  throttleDuration: IDropDownList;
  addressListOptions: IAddressListOptions;
  posIntegration: IDropDownList;
  posProvider: IDropDownList;
  headerFont: IDropDownList;
  bodyFont: IDropDownList;
  tipCalculation: IDropDownList;
}

export const dropDownState: IUIDropDown = {
  country: { ...defaultOptions },
  state: { ...defaultOptions },
  timezone: { ...defaultOptions },
  currency: { ...defaultOptions },
  locale: { ...defaultOptions },
  orderTime: { ...defaultOptions },
  acceptOrders: { ...defaultOptions },
  throttleDuration: { ...defaultOptions },
  addressListOptions: {} as IAddressListOptions,
  posIntegration: { ...defaultOptions },
  posProvider: { ...defaultOptions },
  headerFont: { ...defaultOptions },
  bodyFont: { ...defaultOptions },
  tipCalculation: { ...defaultOptions },
};
