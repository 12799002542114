import { PaginationResponse } from '@ready/dashboardv2api.contracts';
import { ITemplateModifierGroup } from '@ready/menu.core';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSharedModifierGroups } from '../../modifierGroupService';

interface IGetSharedModifierGroupThunkArgs {
  companyId: string;
  query: string;
  page?: number;
}

const getSharedModifierGroupsThunk = createAsyncThunk<
  PaginationResponse<ITemplateModifierGroup>,
  IGetSharedModifierGroupThunkArgs
>('sharedMenuItems/modifierGroups/list/fetch', async ({ companyId, query, page }: IGetSharedModifierGroupThunkArgs) => {
  return getSharedModifierGroups(companyId, query, page);
});

export { getSharedModifierGroupsThunk };
