import React, { ReactNode } from 'react';
import styles from './app-layout.module.scss';

export interface NavigationButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  label: string | ReactNode;
}

const NavigationButton = ({ onClick, label }: NavigationButtonProps) => (
  <button type='button' className={styles.sidebarNavigationButton} onClick={onClick}>
    {label}
  </button>
);

export default NavigationButton;
