import TextIcon, { Icon } from 'components/Icon/TextIcon';
import LinkButton from 'components/LinkButton/LinkButton';
import React from 'react';

interface Props {
  onClick: () => void;
}
export const LocationSettingsButton = ({ onClick }: Props) => {
  return (
    <LinkButton onClick={onClick}>
      <TextIcon icon={Icon.Cog} />
      <span style={{ paddingLeft: 2 }}>Location Settings</span>
    </LinkButton>
  );
};
