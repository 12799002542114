import { FormControl, FormControlStacked, FormGroup } from '../../components/Form';
import { IAsset } from '@ready/dashboardv2api.contracts';
import { IEmailReceiptsPanel } from '../redux/PanelEmailReceiptsState';
import { IUpdateLocationParam } from './LocationsEditPage';
import { LOCATION_SETTINGS_ACTIONS } from '../redux/LocationSettingsActions';
import Checkbox from 'components/Checkbox/Checkbox';
import ImageUploader from '../../components/ImageUploader/ImageUploader';
import React, { ChangeEvent } from 'react';
import TextInput from '../../components/TextInput/TextInput';
import TextInputNestedLabel from 'components/TextInputNestedLabel/TextInputNestedLabel';

interface Props {
  updateLocationParam: IUpdateLocationParam;
  emailReceiptsPanel: IEmailReceiptsPanel;
  isProcessing: boolean;
}

const titleTextPlaceHolder = 'E.g. Thank you';
const headerTextPlaceHolder = 'E.g. We hope to see you again soon';

const LocationPanelEmailReceipts = (props: Props) => {
  const { updateLocationParam, emailReceiptsPanel, isProcessing } = props;

  const updateLogo = (asset: Partial<IAsset>) => {
    updateLocationParam(asset, LOCATION_SETTINGS_ACTIONS.UPDATE_EMAIL_RECEIPTS_LOGO);
  };

  return (
    <FormGroup groupName='Email Receipts'>
      <FormControl label='Receipt Logo'>
        <ImageUploader
          maxWidth={300}
          aspectRatio='1:2'
          onUpload={updateLogo}
          imageKey={emailReceiptsPanel.receiptLogoAsset.fileKey}
          isProcessing={isProcessing}
          maxFileSizeKb={1024}
          formattedGuideText={
            <>
              <strong>Size:</strong> 300px wide, 150px high <strong>Format:</strong> JPG, PNG
            </>
          }
        />
      </FormControl>
      <FormControl label='Email Receipt Title Text'>
        <TextInput
          placeholder={titleTextPlaceHolder}
          value={emailReceiptsPanel.receiptTitleText}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            updateLocationParam(e.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_EMAIL_RECEIPTS_TITLE_TEXT);
          }}
          loading={isProcessing}
        />
      </FormControl>
      <FormControl label='Email Receipt Header Text'>
        <TextInput
          placeholder={headerTextPlaceHolder}
          value={emailReceiptsPanel.receiptHeaderText}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            updateLocationParam(e.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_EMAIL_RECEIPTS_HEADER_TEXT);
          }}
          loading={isProcessing}
        />
      </FormControl>
      <FormControl label='CFDI Compliance'>
        <Checkbox
          label={'Send receipts to Genesys to generate Folio Fiscal / UUID'}
          checked={emailReceiptsPanel.genesysConfig?.sendToGenesys ?? false}
          onChange={(e) => {
            updateLocationParam({ sendToGenesys: e.target.checked }, LOCATION_SETTINGS_ACTIONS.UPDATE_GENESYS_CONFIGS);
          }}
        />
        {emailReceiptsPanel.genesysConfig?.sendToGenesys && (
          <FormControlStacked indented>
            <TextInputNestedLabel
              withRequiredLabel
              label='Genesys Location ID'
              onChange={(e) => {
                updateLocationParam({ genesysName: e.target.value }, LOCATION_SETTINGS_ACTIONS.UPDATE_GENESYS_CONFIGS);
              }}
              value={emailReceiptsPanel.genesysConfig?.genesysName ?? ''}
            />
          </FormControlStacked>
        )}
      </FormControl>
    </FormGroup>
  );
};

export default LocationPanelEmailReceipts;
