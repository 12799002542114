import React from 'react';
import classNames from 'classnames';
import styles from './NoResults.module.scss';

export interface NoResultsProps {
  additionalStyles?: string;
}

const NoResults = (props: NoResultsProps): JSX.Element => {
  const { additionalStyles = '' } = props;

  return (
    <div className={classNames(styles.wrapperContainer, additionalStyles)}>
      <p className={styles.noResultsText}>No Results Found</p>
    </div>
  );
};

export default NoResults;
