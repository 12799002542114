import React, { forwardRef } from 'react';
import Container from '../Container/Container';
import classNames from 'classnames';

export interface TextInputProps {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  value?: string | number;
  defaultValue?: string | number;
  type?: 'text' | 'email' | 'password' | 'number';
  withError?: boolean;
  placeholder?: string;
  inputRef?: any;
  name?: string;
  disabled?: boolean;
  disabledLoading?: boolean;
  loading?: boolean;
  additionalStyles?: string;
  additionalContainerStyles?: string;
  readOnly?: boolean;
  maxLength?: number;
  autoFocus?: boolean;
}

const TextInput = forwardRef((props: TextInputProps, ref) => {
  const {
    onChange,
    onClick,
    value,
    defaultValue,
    type = 'text',
    withError = false,
    placeholder = '',
    inputRef,
    name,
    disabled = false,
    disabledLoading = false,
    loading = false,
    additionalStyles = '',
    additionalContainerStyles = '',
    readOnly = false,
    maxLength,
    autoFocus = false
  } = props;

  const errorClassName = withError ? 'text-input--state-error' : '';
  const loadingClassName = loading ? 'text-input--state-loading' : '';
  const disabledClassName = disabled ? 'text-input--disabled' : '';
  const disabledLoadingClassName = disabledLoading ? 'text-input--disabledLoading' : '';
  const containerClassNames = classNames(['text-input-container', additionalContainerStyles]);

  return (
    <Container additionalClassName={containerClassNames}>
      <input
        className={`text-input ${errorClassName} ${loadingClassName} ${disabledClassName} ${disabledLoadingClassName} ${additionalStyles}`}
        type={type}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        ref={inputRef || ref}
        name={name}
        disabled={loading || disabled}
        onClick={onClick}
        readOnly={readOnly}
        maxLength={maxLength}
        autoFocus={autoFocus}
      />
    </Container>
  );
});

export default TextInput;
