import { IUIAdmin } from './AdminsListState';

export interface IAdminFormValidation {
  emailError: boolean;
  familyNameError: boolean;
  givenNameError: boolean;
}

export interface IAdminForm {
  loading: boolean;
  error: boolean;
  adminFormData: IUIAdmin;
  formValidation: IAdminFormValidation;
  adminIdTransaction: string;
}

export const adminForm = {
  loading: false,
  error: false,
  success: false,
  adminFormData: { firstName: '', lastName: '', email: '', enabled: false, id: '', uid: '' },
  formValidation: {
    emailError: false,
    givenNameError: false,
    familyNameError: false,
  },
  adminIdTransaction: '',
} as IAdminForm;
