import React from 'react';
import { useToastify } from './useToastify';
import { IResponseState } from '../redux/initialStates/ui/responseState';
import { ToastElementMessage } from '../components/Toast';

export const useResponseToast = (response: IResponseState) => {
  const { showToastifyMessage } = useToastify();

  React.useEffect(() => {
    const { responseStatus, responseType, header = 'Oops! An error has occurred.', message, listItems } = response;

    if (responseType !== 'toast') return;
    let toastMessage = {} as ToastElementMessage;

    if (!responseStatus) {
      toastMessage = {
        type: 'error',
        title: header,
        message,
        listItems,
      };
    } else {
      if (responseStatus === 200) {
        toastMessage = {
          type: 'success',
          title: message ? message : 'Success! Changes saved.',
        };
      } else if (responseStatus && responseStatus >= 400) {
        toastMessage = {
          type: 'error',
          title: header,
          message,
          listItems,
        };
      }
    }

    if (toastMessage.type) {
      showToastifyMessage(toastMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);
};
