import TableLayoutContent from 'companyLocations/components/TableLayoutContent';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './LocationsPage.module.scss';
import { SharedMenuItemsLayout } from 'sharedMenuItems/SharedMenuItemsLayout';
import { SharedMenuItemsTabId } from 'sharedMenuItems/SharedMenuItemsTabs';
import { ToolbarContent } from '../itemGroups/assignedLocations/components/ToolbarContent';
import { PaginationContainer } from 'components/PaginationContainer/PaginationContainer';

import { Body, BodySkeleton, TableBodyProps } from 'components/TableV2/Body/Body';
import { TableV2 } from 'components/TableV2/TableV2';
import { TableHeader, TableHeaderProps } from 'components/TableV2/TableHeader/TableHeader';
import { TextCell } from 'components/TableV2/Cell/SpecialCells';
import { CellProps, createCellsWithMobileLayout } from 'components/TableV2/Cell/Cell';
import { HeaderCell, HeaderCellConstants } from 'components/TableV2/Cell/HeaderCell';
import { SelectMenuConfigCell } from './components/SelectMenuConfigCell';
import { SharedMenuItemsRoutes } from 'sharedMenuItems/Router';
import { useHistory, useParams } from 'react-router-dom';
import { ContextParams } from 'types/ContextParams.interface';
import { updateQueryParamsDebounced } from 'utils/updatePath';
import classnames from 'classnames';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { IMenuGroupAssignments } from '@ready/menu.core';
import { getMenuGroupAssignmentsThunk } from './thunks/getMenuGroupAssignmentsThunk';
import { decodeUriString } from 'utils/urlUtils/decodeUriString';
import { useSearchParams } from 'hooks';
import FormActionBar from 'components/FormActionBar/FormActionBar';
import { activateMenuGroupsThunk } from './thunks/activateMenuGroupsThunk';
import { resetChanges, resetState, updateLocationMenuGroupBooleanField } from './redux/slice';
import { Tooltip } from 'components/Tooltip/Tooltip';
import TextIcon, { Icon } from 'components/Icon/TextIcon';
import { setFormIsDirty } from 'redux/actions/uiActions/formStateActions';

export const LocationsPage = () => {
  const { contextId: companyId } = useParams<ContextParams>();
  const { push } = useHistory();
  const dispatch = useAppDispatch();
  const {
    locations,
    loading,
    pagination = { start: 0, length: 0, limit: 0, total: 0 },
  } = useAppSelector((state) => state.sharedMenuItems.menuConfigLocations);
  const { searchTerm, page } = useSearchParams();
  const decodedSearchTerm = decodeUriString(searchTerm || '');

  const [guestActivations, setGuestActivations] = useState<Map<string, string>>(new Map());
  const [serverFlowActivations, setServerFlowActivations] = useState<Map<string, string>>(new Map());

  const [saving, setSaving] = useState<boolean>(false);

  const selectGuestActiveMenuGroup = useCallback(
    (assignment: IMenuGroupAssignments, selectedMenuGroup: string) => {
      setGuestActivations((prev) => new Map(prev?.set(assignment.locationId, selectedMenuGroup)));

      dispatch(
        updateLocationMenuGroupBooleanField({
          locationId: assignment.locationId,
          update: { key: 'isActive', value: selectedMenuGroup },
        })
      );
      dispatch(setFormIsDirty(true));
    },
    [dispatch]
  );

  const selectServerFlowActiveMenuGroup = useCallback(
    (assignment: IMenuGroupAssignments, selectedMenuGroup: string) => {
      setServerFlowActivations((prev) => new Map(prev?.set(assignment.locationId, selectedMenuGroup)));

      dispatch(
        updateLocationMenuGroupBooleanField({
          locationId: assignment.locationId,
          update: { key: 'isServerFlowActive', value: selectedMenuGroup },
        })
      );
      dispatch(setFormIsDirty(true));
    },
    [dispatch]
  );

  const onSearchTermChanged = (searchTerm: string) => {
    updateQueryParamsDebounced(
      SharedMenuItemsRoutes.getLocationsRoute(companyId),
      { searchTerm: searchTerm, page: '' },
      push
    );
  };

  const handleSubmit = async () => {
    setSaving(true);
    await dispatch(activateMenuGroupsThunk({ companyId, guestActivations, serverFlowActivations }));
    resetForm();
    setSaving(false);
    dispatch(getMenuGroupAssignmentsThunk({ companyId, locationSearchTerm: decodedSearchTerm, page }));
  };

  const resetForm = () => {
    setGuestActivations(new Map());
    setServerFlowActivations(new Map());
    dispatch(resetChanges());
    dispatch(setFormIsDirty(false));
  };

  useEffect(() => {
    dispatch(getMenuGroupAssignmentsThunk({ companyId, locationSearchTerm: decodedSearchTerm, page }));

    return () => {
      dispatch(resetState());
    };
  }, [companyId, decodedSearchTerm, dispatch, page]);

  const bodyProps = useMemo(
    () => getTableProps(selectGuestActiveMenuGroup, selectServerFlowActiveMenuGroup, saving),
    [saving, selectGuestActiveMenuGroup, selectServerFlowActiveMenuGroup]
  );
  const actionBarVisible = guestActivations.size > 0 || serverFlowActivations.size > 0;

  return (
    <>
      <FormActionBar
        actionBarVisible={actionBarVisible}
        handleFormSubmit={handleSubmit}
        resetForm={resetForm}
        isFormProcessing={saving}
      />
      <SharedMenuItemsLayout tab={SharedMenuItemsTabId.Locations}>
        <TableLayoutContent
          title={'Locations'}
          headerDescription={headerDescription}
          toolbarContent={
            <ToolbarContent initialSearch={decodedSearchTerm} onSearchTermChanged={onSearchTermChanged} />
          }
        >
          <PaginationContainer pagination={pagination}>
            {locations.length === 0 && !loading ? (
              <div className={styles.noLocations}>{'No locations found.'}</div>
            ) : (
              <TableV2 classes={styles.table}>
                <>
                  {!loading ? (
                    <>
                      <TableHeader {...tableHeaderProps} />
                      <Body {...bodyProps} data={locations} />
                    </>
                  ) : (
                    <BodySkeleton rowsNumber={50} columns={bodyProps.columns} />
                  )}
                </>
              </TableV2>
            )}
          </PaginationContainer>
        </TableLayoutContent>
      </SharedMenuItemsLayout>
    </>
  );
};

const headerDescription =
  'Select the shared menu configuration you would like displayed at each location. To allow locations to manage their own menu instead of using a shared one, set the active configuration to “Custom”.';

const tableHeaderProps: TableHeaderProps = {
  cells: {
    locationName: (props: any) => <HeaderCell {...props} maxWidth={HeaderCellConstants.defaultCellMaxWidth} />,
    assignedMenuGroups: (props: any) => <HeaderCell {...props} classes={styles.assignedMenuGroupsCell} />,
    assignedServerFlowMenuGroups: (props: any) => <HeaderCell {...props} classes={styles.assignedMenuGroupsCell} />,
  },
  data: {
    locationName: 'Location Name',
    assignedMenuGroups: (
      <>
        GuestFlow Menu Configuration&nbsp;
        <Tooltip
          additionalStyles={styles.activeMenuConfigTooltip}
          text={
            'Menu configurations must be assigned to a location before they can be selected and set as active at that location.'
          }
        >
          <TextIcon icon={Icon.InfoCircle} />
        </Tooltip>
      </>
    ),
    assignedServerFlowMenuGroups: (
      <>
        ServerFlow Menu Configuration&nbsp;
        <Tooltip
          additionalStyles={styles.activeMenuConfigTooltip}
          text={
            'To use custom Server menus, location(s) must be subscribed to the correct Ready tier. Please reach out to support@readytopay.com if you would like to sign up.'
          }
        >
          <TextIcon icon={Icon.InfoCircle} />
        </Tooltip>
      </>
    ),
  },
  columns: ['locationName', 'assignedMenuGroups', 'assignedServerFlowMenuGroups'],
};

const getTableProps = (
  selectGuestActiveMenuGroup: (assignment: IMenuGroupAssignments, selected: string) => void,
  selectServerFlowActiveMenuGroup: (assignment: IMenuGroupAssignments, selected: string) => void,
  loading: boolean
): TableBodyProps => {
  const bodyCells = createCellsWithMobileLayout(
    {
      locationName: (props: CellProps) => <TextCell {...props} classes={classnames(props.classes, styles.nameCell)} />,
      assignedMenuGroups: (props: CellProps<IMenuGroupAssignments>) => (
        <SelectMenuConfigCell
          {...props}
          classes={classnames(props.classes, styles.assignedMenuGroupsCell)}
          onSelect={selectGuestActiveMenuGroup}
          loading={loading}
          activeKey={'isActive'}
        />
      ),
      assignedServerFlowMenuGroups: (props: CellProps<IMenuGroupAssignments>) => (
        <SelectMenuConfigCell
          {...props}
          classes={classnames(props.classes, styles.assignedMenuGroupsCell)}
          onSelect={selectServerFlowActiveMenuGroup}
          loading={loading || !props.rowData.serverFlowFeatureEnabled}
          activeKey={'isServerFlowActive'}
        />
      ),
    },
    tableHeaderProps.data,
    {}
  );

  const bodyProps: TableBodyProps = {
    cells: bodyCells,
    columns: tableHeaderProps.columns,
    data: [],
    rowKey: 'locationId',
    hiddenColumns: {},
  };

  return bodyProps;
};
