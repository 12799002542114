import { ITemplateLocation } from '@ready/menu.core';
import { createSlice } from '@reduxjs/toolkit';
import InfiniteScrollingListState from 'types/InfiniteScrollingListState.interface';
import InfiniteScrollingListStateHelper from 'utils/InfiniteScrollingList/InfiniteScrollingListStateHelper';

export interface IEditAssignedLocationsModal extends InfiniteScrollingListState<ITemplateLocation> {}
export type AllLocations = 'assign' | 'unassign';

export interface IEditAssignedLocationsState {
  locations: ITemplateLocation[];
  loading: boolean;
  editAssignedLocationsModal: IEditAssignedLocationsModal;
  totalMenuItemLocations?: number;

  // working state;
  newAssign: string[];
  newUnassign: string[];

  allLocations?: AllLocations;
}

export const initialState: IEditAssignedLocationsState = {
  editAssignedLocationsModal: { ...InfiniteScrollingListStateHelper.initialState() },
  loading: false,
  locations: [],

  newAssign: [],
  newUnassign: [],
};

const reducers = {
  prepareModal: (state: IEditAssignedLocationsState): void => {
    InfiniteScrollingListStateHelper.prepareState(state.editAssignedLocationsModal, true);

    state.newAssign = [];
    state.newUnassign = [];
    state.allLocations = undefined;
  },

  selectAssignedLocation: (state: IEditAssignedLocationsState, action: { payload: { id: string } }): void => {
    InfiniteScrollingListStateHelper.selectItemState(state.editAssignedLocationsModal, action.payload.id);

    const newRemoveIndex = state.newUnassign.findIndex((x) => x === action.payload.id);
    if (newRemoveIndex !== -1) {
      state.newUnassign.splice(newRemoveIndex, 1);
    } else {
      state.newAssign.push(action.payload.id);
    }
  },

  unselectAssignedLocation: (state: IEditAssignedLocationsState, action: { payload: { id: string } }): void => {
    InfiniteScrollingListStateHelper.unselectItemState(state.editAssignedLocationsModal, action.payload.id);

    const newAssignIndex = state.newAssign.findIndex((x) => x === action.payload.id);
    if (newAssignIndex !== -1) {
      state.newAssign.splice(newAssignIndex, 1);
    } else {
      state.newUnassign.push(action.payload.id);
    }
  },

  assignAllLocations: (state: IEditAssignedLocationsState): void => {
    if (state.allLocations === 'assign') {
      state.allLocations = undefined;
    } else {
      state.allLocations = 'assign';
    }
  },

  unassignAllLocations: (state: IEditAssignedLocationsState): void => {
    if (state.allLocations === 'unassign') {
      state.allLocations = undefined;
    } else {
      state.allLocations = 'unassign';
    }
  },

  updateQuery: (state: IEditAssignedLocationsState, action: { payload: string }): void => {
    InfiniteScrollingListStateHelper.filterChangedState(state.editAssignedLocationsModal, action.payload);
  },
};

const assignLocationsSlice = createSlice({
  name: 'sharedMenuItems/itemsAndMods/item/locations',
  initialState: initialState,
  reducers,
  extraReducers: () => {},
});
export const {
  prepareModal,
  selectAssignedLocation,
  unselectAssignedLocation,
  assignAllLocations,
  unassignAllLocations,
  updateQuery,
} = assignLocationsSlice.actions;

export { assignLocationsSlice };
