import React from 'react';
import styles from '../Page.module.scss';
import { DollarValue } from '../../../../components/Value';
import DateValue from '../../../../components/Value/DateValue';
import { EventTypes } from '../redux';

const getTitle = ({ type, items, value }: Record<string, any>) => {
  switch (type) {
    case EventTypes.checkCreated:
      return 'Check Created';
    case EventTypes.tabOrder:
      return <>Tab Order {items ? <span className={styles.TextBold}>(+{items} items)</span> : null}</>;
    case EventTypes.payment:
      return (
        <>
          Payment{' '}
          {value ? (
            <span className={styles.TextBold}>
              (<DollarValue value={value} />)
            </span>
          ) : null}
        </>
      );
    case EventTypes.order:
      return (
        <>
          Order{' '}
          {items && value ? (
            <span className={styles.TextBold}>
              (<DollarValue value={value} /> - {items} items)
            </span>
          ) : null}
        </>
      );
    case EventTypes.closed:
      return 'Closed';
  }
};

export const HistoryItems = ({
  historyItems,
}: {
  // TODO define format when the data will be ready
  historyItems: Array<any>;
}) => {
  return (
    <div className={styles.HistoryEvents}>
      {historyItems.map((event, i) => {
        return (
          <div className={styles.HistoryEvent} key={i}>
            <span>{getTitle(event)}</span>
            <span className={styles.SecondaryText}>
              <DateValue value={event.date} timeZone={event.timezone} />
            </span>
          </div>
        );
      })}
    </div>
  );
};
