import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '../../../components/Modal';

interface ReenableItemDialogProps {
  processing: boolean;
  completed: boolean;
  setShowModal: (showModal: boolean) => void;
  onReenableItemClick: () => void;
}

const ReenableItemDialog = ({
  processing,
  completed,
  setShowModal,
  onReenableItemClick,
}: ReenableItemDialogProps): JSX.Element => {
  React.useEffect(() => {
    if (completed) {
      setShowModal(false);
    }
  }, [completed, setShowModal]);

  return (
    <Modal loading={processing} setShowModal={setShowModal}>
      <ModalHeader headerLabel='Re-enable this item?' setShowModal={setShowModal} />

      <ModalBody>
        <p>This item will be visible on any live menus as soon as it is re-enabled.</p>
      </ModalBody>

      <ModalFooter
        loading={processing}
        primaryLabel='Re-enable Item'
        primaryActionHandler={onReenableItemClick}
        secondaryLabel='Cancel'
        secondaryActionHandler={() => setShowModal(false)}
      />
    </Modal>
  );
};

export default ReenableItemDialog;
