import produce from 'immer';
import { serverAssignmentLocationsState } from './ServerAssignmentLocationsState';
import { SERVER_ASSIGNMENT_ACTIONS } from './types';
import { Action } from '../../redux/types';

export const serverAssignmentLocationsReducer = (state = serverAssignmentLocationsState, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SERVER_ASSIGNMENT_ACTIONS.SET_SERVER_ASSIGNMENT_LOCATIONS_LOADING:
        draft.locationPicker.loading = action.payload;
        return draft;

      case SERVER_ASSIGNMENT_ACTIONS.SET_SERVER_ASSIGNMENT_LOCATIONS:
        return {
          ...draft,
          locationPicker: {
            ...action.payload.locationPicker,
            loading: draft.locationPicker.loading,
          },
        };

      default:
        return draft;
    }
  });
};
