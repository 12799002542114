import React from 'react';
import { Appearance } from '@ready/menu.core';
import styles from './DefaultAppearanceIcon.module.scss';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';

interface DefaultAppearanceIconProps {
  defaultAppearance: Appearance;
}

const DefaultAppearanceIcon = ({ defaultAppearance }: DefaultAppearanceIconProps): JSX.Element => (
  <TextIcon
    icon={
      defaultAppearance === Appearance.expanded
        ? Icon.ExpandedSection
        : defaultAppearance === Appearance.partiallyExpanded
        ? Icon.PartialCollapsedSection
        : Icon.CollapsedSection
    }
    additionalStyles={styles.icon}
  />
);

export default DefaultAppearanceIcon;
