import config from 'config';
import Auth0Service from '../Auth0Service';
import ServiceError from '../errors/ServiceError';

type Method = 'GET' | 'PUT' | 'POST' | 'DELETE';
export enum ResponseType {
  JSON = 'json',
  Blob = 'blob',
  Text = 'text',
}

interface Options {
  method?: Method;
  throwServiceErrorOnFailStatus?: boolean;
  headers?: { [key: string]: string };
  body?: string;
  responseType?: ResponseType;
}

const executeApiAsync = async <T>(
  url: string,
  options: Options = {},
  isJson: boolean = true,
  abortController?: AbortController
): Promise<T> => {
  // Get options or default options
  const method = options.method || 'GET';
  const throwServiceError =
    options.throwServiceErrorOnFailStatus === undefined ? true : options.throwServiceErrorOnFailStatus;

  const additionalHeaders = options.headers || {};

  // Set/retrieve the auth token
  const _token = await Auth0Service.getToken();

  // Set up request headers
  const headers = {
    Authorization: `Bearer ${_token}`,
    'Content-Type': 'application/json',
    'Ready-Dashboard-Version': config.version,
    ...additionalHeaders,
  };
  if (!isJson) {
    delete (headers as any)['Content-Type'];
  }

  const body = options.body;

  // Execute the API request
  const apiResponse = await fetch(url, { signal: abortController?.signal, method, headers, body });

  // Check the status, and throw an error if required
  if (throwServiceError && !apiResponse.ok) {
    let errorMessage;
    try {
      errorMessage = (await apiResponse.json()).message;
    } catch (_) {
      errorMessage = 'Internal Server Error';
    }
    throw new ServiceError(apiResponse.status, errorMessage);
  }

  // TODO: We may need to implement a custom JSON deserializer, since date
  // values are not converted from strings to Date objects automatically.

  switch (options.responseType) {
    case ResponseType.Blob:
      return (await apiResponse.blob()) as any;

    case ResponseType.Text:
      return (await apiResponse.text()) as any;

    case ResponseType.JSON:
    default:
      // Retrieve the JSON as the required type
      let response;
      try {
        response = (await apiResponse.json()) as T;
      } catch (_) {
        // in case of empty response body
        response = {} as T;
      }

      return response;
  }
};

export default executeApiAsync;
