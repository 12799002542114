import React, { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { usePageTitle } from '../hooks';
import TagsBadgesListPage from './components/TagsBadgesListPage';

const Router: FC<{}> = () => {
  const { path } = useRouteMatch();
  usePageTitle('Tags & Badges');

  return (
    <Switch>
      <Route path={path} component={TagsBadgesListPage} />
    </Switch>
  );
};

export default Router;
