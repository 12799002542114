import React from 'react';
import classNames from 'classnames';
import TextIcon, { Icon } from 'components/Icon/TextIcon';
import styles from './ItemWithAssignedLocations.module.scss';
import { ITemplateMenuItem, WithAssignedLocationsCount } from '@ready/menu.core';

export const ItemWithAssignedLocations = (
  { displayName, assignedLocationsCount }: WithAssignedLocationsCount<ITemplateMenuItem>,
  controls: JSX.Element | null
) => {
  return (
    <div className={styles.container}>
      <div className={styles.displayNameContainer}>
        <div className={styles.displayName}>{displayName}</div>
      </div>
      <div className={styles.rightColumns}>
        <AssignedLocationsContent numberOfLocations={assignedLocationsCount} />
        <div className={styles.controls}>
          <div className={styles.button}>{controls}</div>
        </div>
      </div>
    </div>
  );
};

export const AssignedLocationsContent = ({
  numberOfLocations,
  additionalStyles,
}: {
  numberOfLocations: number;
  additionalStyles?: string;
}) => {
  return (
    <div className={classNames(styles.assignedLocationsContainer, additionalStyles)}>
      <TextIcon icon={Icon.Location} additionalStyles={styles.icon} />
      <span className={styles.numberOfLocations}>{numberOfLocations}</span>
    </div>
  );
};
