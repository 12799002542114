import React from 'react';

import { IMenuItemGroup } from '@ready/menu.core';

import styles from './AddItemGroupsListItem.module.scss';
import IconButton from '../../../components/IconButton/IconButton';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';
import { DollarValue } from '../../../components/Value';
import { useParams } from 'react-router-dom';
import { ContextParams } from 'types/ContextParams.interface';
import { isSharedMenu } from 'sharedMenuItems/sharedMenuItems.utils';

export interface AddItemGroupsListItemProps {
  itemGroup: IMenuItemGroup;
  controls: JSX.Element;
}

const AddItemGroupsListItem = (props: AddItemGroupsListItemProps): JSX.Element => {
  const { itemGroup, controls } = props;
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
  const { contextId: companyId, locationId } = useParams<ContextParams>();

  const onExpanderClicked = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.expander}>
          <IconButton onClick={onExpanderClicked}>
            <TextIcon icon={isExpanded ? Icon.AngleDown : Icon.AngleRight} additionalStyles={styles.icon} />
          </IconButton>
        </div>

        <div className={styles.content}>
          <div className={styles.main}>
            <div className={styles.displayName}>{itemGroup.displayName}</div>
            {!!itemGroup.description ? (
              <div className={styles.description}>{itemGroup.description}</div>
            ) : (
              <div className={styles.noDescription}>No Description</div>
            )}
          </div>

          <div className={styles.itemCount}>{itemGroup.items.length} Items</div>
        </div>

        <div className={styles.controls}>
          <div className={styles.button}>{controls}</div>
        </div>
      </div>

      {isExpanded &&
        itemGroup.items.map((item) => (
          <div key={item.itemId} className={styles.container}>
            {isSharedMenu(companyId, locationId) ? (
              <div className={styles.subContent}>
                <div className={styles.main}>
                  <div className={styles.displayName}>{item.displayName}</div>
                </div>
              </div>
            ) : (
              <div className={styles.subContent}>
                <div className={styles.main}>
                  <div className={styles.displayName}>{item.displayName}</div>
                  <div className={styles.pos}>
                    {item.posItemId}
                    {' | '}
                    {item.posItemCategories.join(', ')}
                  </div>
                </div>

                <div className={styles.price}>{item.price && <DollarValue value={item.price} />}</div>
              </div>
            )}
          </div>
        ))}
    </>
  );
};

export default AddItemGroupsListItem;
