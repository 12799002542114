import React from 'react';
import { Status } from '@ready/menu.core';
import styles from './ReorderDialog.module.scss';

import ListModal from '../../../components/Modal/ListModal';
import DragAndDrop from '../../../components/DragAndDrop';
import Button from '../../../components/Button/Button';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';
import DisabledBadge from '../../components/DisabledBadge';

export interface ReorderDialogProps<T> {
  visible: boolean;
  processing: boolean;
  title: string;
  items: T[];
  setVisible: (visible: boolean) => void;
  keyExtractor: (item: T) => string;
  labelExtractor: (item: T) => string;
  onMove: (oldIndex: number, newIndex: number) => void;
  onSave: (items: T[]) => void;
}

interface Sortable {
  sortOrder: number;
}

const ReorderDialog = <T extends Sortable>(props: ReorderDialogProps<T>): JSX.Element | null => {
  const { visible, processing, title, items, setVisible, keyExtractor, labelExtractor, onMove, onSave } = props;

  if (!visible) {
    return null;
  }

  const onCancelClicked = (): void => {
    setVisible(false);
  };

  const onSaveClicked = (): void => {
    onSave(items);
  };

  return (
    <ListModal setShowModal={setVisible} headerLabel={title} additionalBodyStyles={styles.container}>
      <DragAndDrop
        dropAreaClassName={styles.items}
        dropAreaDraggingClassName={styles.itemsDragging}
        dragItemClassName={styles.item}
        dragItemDraggingClassName={styles.itemDragging}
        dragHandleClassName={styles.dragHandle}
        dragItems={items}
        dragItemKeyExtractor={keyExtractor}
        // TODO: get the proper interface from Ready.Core - on future pass -
        dragItemComponentBuilder={(item: any) => (
          <div className={styles.label}>
            {item.type === 'itemGallery' && <TextIcon icon={Icon.Star} additionalStyles={styles.menuGalleryStarIcon} />}
            <span className={item.status === Status.disabled ? styles.reorderItemDisabled : ''}>
              {labelExtractor(item)}
            </span>

            {item.status === Status.disabled && <DisabledBadge />}
          </div>
        )}
        onDragItemDropped={onMove}
      />

      <div className={styles.controls}>
        <div className={styles.cancel}>
          <Button label='Cancel' variant='secondary' unavailable={processing} onClick={onCancelClicked} />
        </div>
        <div className={styles.save}>
          <Button label='Save Changes' loading={processing} onClick={onSaveClicked} />
        </div>
      </div>
    </ListModal>
  );
};

export default ReorderDialog;
