import produce from 'immer';
import { FORM_STATE_TYPES } from '../../actions/uiActions/formStateActionTypes';
import { formState } from '../../initialStates/ui/formState';
import { Action } from '../../types';

export const formStateReducer = (state = formState, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case FORM_STATE_TYPES.SET_FORM_IS_DIRTY:
        draft.isDirty = action.payload;
        return draft;

      default:
        return draft;
    }
  });
};
