import { PaginationResponse } from '@ready/dashboardv2api.contracts';
import { IMenuGroup } from '@ready/menu.core';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMenuConfigsForLocation } from 'menus/menuConfigs/service';

interface Args {
  companyId: string;
  locationId: string;
}

export const getMenuConfigsForLocationThunk = createAsyncThunk<PaginationResponse<IMenuGroup>, Args>(
  'menuBuilder/menuConfigs/fetch',
  async ({ companyId, locationId }: Args) => getMenuConfigsForLocation(companyId, locationId)
);
