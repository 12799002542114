import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  resetOrderSmsEditCompanyModal,
  toggleOrderSmsEditCompanyModal,
  validateOrderSmsMessages,
} from '../../../../redux/reducers/orderSMSMessages/orderSMSMessagesSlice';
import { OrderSmsMessagesThunk } from '../../../../redux/reducers/orderSMSMessages/orderSMSMessagesThunk';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '../../../../../components/Modal';
import { OrderSmsMessagesFormControls } from '../../components/OrderSMSMessagesFormControls';
import { ContextParams } from '../../../../../types/ContextParams.interface';
import { OrderSmsMessageSource } from '@ready/dashboardv2api.contracts';
import { orderSmsMessagesSelector } from '../../../../redux/selectors/orderSmsMessagesSelector';

export const OrderSmsMessagesCompanyModal = (): JSX.Element | null => {
  const { orderSmsMessages, formProcessing, isModalOpen, validated } = useSelector(orderSmsMessagesSelector);

  const { companyConfig: orderSmsMessagesCompanyConfig, messageSource } = orderSmsMessages;
  const dispatch = useDispatch();
  const { contextId: companyId } = useParams<ContextParams>();

  const handleSetShowModal = (visible: boolean) => {
    dispatch(toggleOrderSmsEditCompanyModal(visible));
  };

  const handleCloseModal = () => {
    dispatch(resetOrderSmsEditCompanyModal());
    dispatch(toggleOrderSmsEditCompanyModal(false));
  };
  const validateOrderSmsMessagesForCompany = () => {
    dispatch(validateOrderSmsMessages(orderSmsMessagesCompanyConfig));
  };

  React.useEffect(() => {
    if (validated && messageSource === OrderSmsMessageSource.Company && isModalOpen) {
      dispatch(
        OrderSmsMessagesThunk.updateOrderSmsMessagesCompanyConfig({
          companyId,
          orderSmsMessagesCompanyConfig,
        })
      );
    }
  }, [companyId, dispatch, messageSource, orderSmsMessagesCompanyConfig, validated, isModalOpen]);

  const subLabelText =
    'These settings will be used by all locations that are configured to use the company defaults message settings. ' +
    'Message settings can be overridden on a per location basis.';

  return isModalOpen ? (
    <Modal setShowModal={handleSetShowModal} loading={formProcessing}>
      <ModalHeader
        headerLabel={'Edit Company Default SMS Settings'}
        subLabel={subLabelText}
        setShowModal={handleSetShowModal}
        displayInline={true}
      />
      <ModalBody>
        <OrderSmsMessagesFormControls />
      </ModalBody>
      <ModalFooter
        primaryLabel='Save Changes'
        primaryActionHandler={validateOrderSmsMessagesForCompany}
        loading={formProcessing}
        secondaryLabel='Cancel'
        secondaryActionHandler={handleCloseModal}
      />
    </Modal>
  ) : null;
};
