import { IScheduleLink, ScheduleEffect } from '@ready/menu.core';
import LinkButton from 'components/LinkButton/LinkButton';
import { ILink } from 'menus/redux/MenusState';
import React, { Fragment } from 'react';
import NoneValue from './NoneValue';

interface Props {
  schedule?: IScheduleLink<ILink>;
  onScheduleLinkClick: (scheduleId: string) => void;
}

export const ScheduleValue = ({ schedule, onScheduleLinkClick }: Props) => {
  if (!schedule || schedule.links.length === 0) {
    return <NoneValue />;
  }

  const verbText = schedule.effect === ScheduleEffect.show ? 'Show' : 'Hide';

  return (
    <span>
      {verbText} during&nbsp;
      {schedule.links.map((x, i) => (
        <Fragment key={x.id}>
          {i > 0 && ', '}
          <LinkButton onClick={() => onScheduleLinkClick(x.id)}>{x.name}</LinkButton>
        </Fragment>
      ))}
    </span>
  );
};
