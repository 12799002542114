import produce from 'immer';
import { Action } from '../../redux/types';
import { ADMIN_DETAILS_ACTION_TYPES } from './AdminDetailsActions';
import { initialAdminDetailsState } from './AdminDetailsState';

export const adminDetailsReducer = (state = initialAdminDetailsState, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ADMIN_DETAILS_ACTION_TYPES.ADMIN_DETAILS_BEGIN:
        draft.loading = true;
        return draft;

      case ADMIN_DETAILS_ACTION_TYPES.ADMIN_DETAILS_SUCCESS:
        draft.admin = action.payload;
        draft.loading = false;
        draft.error = false;
        return draft;

      case ADMIN_DETAILS_ACTION_TYPES.ADMIN_DETAILS_ERROR:
        draft.error = true;
        draft.loading = false;
        return draft;

      case ADMIN_DETAILS_ACTION_TYPES.ADMIN_DETAILS_ACCESS_CHANGE_SUCCESS:
        draft.accessChange = true;
        return draft;

      case ADMIN_DETAILS_ACTION_TYPES.ADMIN_DETAILS_ACCESS_CHANGE_ERROR:
        draft.error = true;
        return draft;

      case ADMIN_DETAILS_ACTION_TYPES.ADMIN_DETAILS_CLEAN_UP:
        draft.error = false;
        draft.accessChange = false;
        return draft;

      default:
        return draft;
    }
  });
};
