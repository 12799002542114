import React from 'react';
import { connect } from 'react-redux';
import styles from './app-layout.module.scss';
import { ISession } from '../../redux/initialStates/session/session';
import Sidebar from './Sidebar';
import LoadingPage from '../../pages/LoadingPage';
import UnderLayer from '../UnderLayer/UnderLayer';
import { updateMobileSidebarVisible } from '../../redux/actions/uiActions/sidebarStateActions';
import { Action } from '../../redux/types';
import { IResponseState } from '../../redux/initialStates/ui/responseState';
import { Toast } from '../Toast';
import { useResponseToast } from '../../hooks/useResponseToast';
// error pages
import Unauthorized from '../../pages/Unauthorized/Unauthorized';
import NotFound from '../../pages/NotFound/NotFound';
import InternalError from '../../pages/InternalError/InternalError';

export interface AppLayoutProps {
  children: JSX.Element | JSX.Element[];
  session: ISession;
  mobileSidebarVisible: boolean;
  responseState: IResponseState;
  invalidContext: boolean;
  updateMobileSidebarVisible: (visible: boolean) => Action;
}

const AppLayout = (props: AppLayoutProps) => {
  const { children, session, mobileSidebarVisible, responseState, invalidContext, updateMobileSidebarVisible } = props;

  // response toast hook
  useResponseToast(responseState);

  const toggleSidebar = () => updateMobileSidebarVisible(!mobileSidebarVisible);

  const { contextSession } = session;

  const layoutClassname = contextSession?.type === 'system' ? styles.layoutUserAdmin : styles.layout;

  if (session.initializeSessionLoading) {
    return <LoadingPage />;
  }

  return (
    <div className={layoutClassname}>
      <Sidebar sidebarVisible={mobileSidebarVisible} toggleSidebar={toggleSidebar} />

      {checkForErrorPages(children, responseState, invalidContext)}

      {mobileSidebarVisible && <UnderLayer onClick={toggleSidebar} />}

      <Toast />
    </div>
  );
};

const checkForErrorPages = (
  children: JSX.Element | JSX.Element[],
  responseState: IResponseState,
  invalidContext: boolean
) => {
  const { responseStatus, responseType } = responseState;

  if (responseType === 'page' || invalidContext) {
    if (responseStatus === 401 || invalidContext) {
      return <Unauthorized />;
    } else if (responseStatus === 404) {
      return <NotFound />;
    }
    if (responseStatus === 500) {
      return <InternalError />;
    }
  }

  return children;
};

const mapStateToProps = (state: any) => ({
  mobileSidebarVisible: state.ui.sidebarState.mobileSidebarVisible,
  responseState: state.ui.responseState,
  invalidContext: state.session.contextSession.invalidContext,
});

export default connect(mapStateToProps, {
  updateMobileSidebarVisible,
})(AppLayout);
