import { PaginationResponse } from '@ready/dashboardv2api.contracts';
import { IBulkEditMenuItem, ILocationAssignments, ITemplateLocation } from '@ready/menu.core';
import { IPagedResponse } from '@ready/table.core';
import config from 'config';
import executeApiAsync from 'services/utils/executeApiAsync';
import toPaginationResponse from 'services/utils/toPaginationResponse';
import { objectToQueryParamsString } from 'utils/urlUtils/objectToQueryParamsString';

export type LinkStatusFilter = 'all' | 'linked' | 'unlinked';

export const fetchTemplateChildMenuItems = async (
  companyId: string,
  itemId: string,
  locationSearchTerm?: string,
  linkStatusFilter: LinkStatusFilter = 'all',
  pageNumber: number = 1,
  pageSize: number = 50
): Promise<PaginationResponse<IBulkEditMenuItem>> => {
  const queryString = objectToQueryParamsString({
    pageNumber: pageNumber.toString(),
    pageSize: pageSize.toString(),
    lq: locationSearchTerm,
    itemLink: linkStatusFilter,
  });

  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/items/${itemId}/locationItems`;
  const result = await executeApiAsync<IPagedResponse<IBulkEditMenuItem>>(`${url}?${queryString}`, {
    method: 'GET',
  });
  return toPaginationResponse({ results: result.results, totalCount: result.totalCount }, pageNumber, pageSize);
};

export const listMenuItemLocations = async (
  companyId: string,
  itemId: string,
  locationSearchTerm?: string,
  pageNumber: number = 1,
  pageSize: number = 50
): Promise<PaginationResponse<ITemplateLocation>> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/items/${itemId}/locations`;
  const queryString = objectToQueryParamsString({
    pageNumber: pageNumber.toString(),
    pageSize: pageSize.toString(),
    searchTerm: locationSearchTerm,
  });

  const result = await executeApiAsync<IPagedResponse<ITemplateLocation>>(url + '?' + queryString, { method: 'GET' });
  return toPaginationResponse({ results: result.results, totalCount: result.totalCount }, pageNumber, pageSize);
};

export const updateMenuItemLocations = async (
  companyId: string,
  itemId: string,
  locationAssignments: ILocationAssignments
): Promise<void> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/items/${itemId}/locations`;

  await executeApiAsync<IPagedResponse<ITemplateLocation>>(url, {
    body: JSON.stringify(locationAssignments),
    method: 'POST',
  });
};
