import { set200Toast, toastErrorState, pageErrorState } from '../../redux/actions/uiActions/responseStateActions';
import AdminsService from '../services/AdminsService';
import { IUIAdmin } from './AdminsListState';

export const ADMIN_DETAILS_ACTION_TYPES = {
  ADMIN_DETAILS_BEGIN: 'ADMIN_DETAILS_BEGIN',
  ADMIN_DETAILS_SUCCESS: 'ADMIN_DETAILS_SUCCESS',
  ADMIN_DETAILS_ERROR: 'ADMIN_DETAILS_ERROR',
  ADMIN_DETAILS_ACCESS_CHANGE_SUCCESS: 'ADMIN_DETAILS_ACCESS_CHANGE_SUCCESS',
  ADMIN_DETAILS_ACCESS_CHANGE_ERROR: 'ADMIN_DETAILS_ACCESS_CHANGE_ERROR',
  ADMIN_DETAILS_CLEAN_UP: 'ADMIN_DETAILS_CLEAN_UP',
};

export const getAdminDetails = (id: string) => async (dispatch: any) => {
  try {
    dispatch(adminDetailsBegin());
    const admin = await AdminsService.getAdmin(id);
    dispatch(adminDetailsSuccess(admin));
  } catch (err) {
    dispatch(adminDetailsError());
    // @ts-ignore
    dispatch(pageErrorState(err.status, err.message));
  }
};

export const handleAdminAccess = (id: string, disabled: boolean) => async (dispatch: any) => {
  try {
    await AdminsService.updateAdminAccess(id, disabled);

    set200Toast();
    dispatch(adminDetailsAccessChangeSuccess());
  } catch (err) {
    dispatch(adminDetailsAccessChangeError());
    // @ts-ignore
    dispatch(toastErrorState(err.status, err.message));
  }
};

export const adminDetailsBegin = () => ({
  type: ADMIN_DETAILS_ACTION_TYPES.ADMIN_DETAILS_BEGIN,
});

export const adminDetailsSuccess = (payload: IUIAdmin) => ({
  type: ADMIN_DETAILS_ACTION_TYPES.ADMIN_DETAILS_SUCCESS,
  payload,
});

export const adminDetailsError = () => ({
  type: ADMIN_DETAILS_ACTION_TYPES.ADMIN_DETAILS_ERROR,
});

export const adminDetailsAccessChangeSuccess = () => ({
  type: ADMIN_DETAILS_ACTION_TYPES.ADMIN_DETAILS_ACCESS_CHANGE_SUCCESS,
});

export const adminDetailsAccessChangeError = () => ({
  type: ADMIN_DETAILS_ACTION_TYPES.ADMIN_DETAILS_ACCESS_CHANGE_ERROR,
});

export const adminDetailsCleanUp = () => ({
  type: ADMIN_DETAILS_ACTION_TYPES.ADMIN_DETAILS_CLEAN_UP,
});
