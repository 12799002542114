import React from 'react';
import NavigationButton from '../../NavigationButton';
import TextIcon, { Icon } from 'components/Icon/TextIcon';

export const HelpAndSupportNavigationButton = () => {
  const HELP_CENTER_URL = 'https://readytopay.com/help-center';
  const openHelpCenter = () => {
    window.open(HELP_CENTER_URL);
  };

  return (
    <NavigationButton
      onClick={openHelpCenter}
      label={
        <>
          <span style={{ paddingRight: 6 }}>Help & Support</span>
          <TextIcon icon={Icon.ExternalLink} />
        </>
      }
    />
  );
};
