import config from '../config';
import { IAsset } from '@ready/dashboardv2api.contracts';
import executeApiAsync, { ResponseType } from './utils/executeApiAsync';

const BASE_URL = `${config.readyDashboardApiEndpoint}/assets`;

export class AssetService {
  public static async uploadImage(image: any): Promise<IAsset> {
    const url = `${config.readyDashboardApiEndpoint}/assets`;
    return executeApiAsync<IAsset>(
      url,
      {
        method: 'POST',
        body: image,
      },
      false
    );
  }

  public static async getAssetUrl(asset: Partial<IAsset>): Promise<string> {
    const url = `${BASE_URL}/${asset.fileKey}`;
    return executeApiAsync<string>(url, { responseType: ResponseType.Text });
  }
}
