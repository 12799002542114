import React from 'react';
import styles from './PaymentOptionShortCodePreview.module.scss';
import { compositeStyles } from '../../../utils/cssUtils';

export interface PaymentOptionShortCodePreviewProps {
  textPreview: string;
  disabled: boolean;
}

const PaymentOptionShortCodePreview = (props: PaymentOptionShortCodePreviewProps) => {
  const { textPreview, disabled } = props;

  return <div className={compositeStyles([styles.preview, { disabled }], styles)}>{textPreview || '-'}</div>;
};

export default PaymentOptionShortCodePreview;
