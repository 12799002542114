import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/store';
import { ILocationPerformance } from './initialState';

export const locationPerformanceSelector = createSelector(
  (state: RootState) => {
    return state;
  },
  ({ session, reports: { locationPerformance } }: RootState): ILocationPerformance & { contextId: string } => {
    return {
      contextId: session.contextSession.id,
      ...locationPerformance,
    };
  }
);

export const locationPerformanceHeaderColumnsSelector = createSelector(
  (state: RootState) => state,
  (state) => state.reports.locationPerformance.headerColumns
);
