import config from '../config';
import { IPrincipalPermissionSet } from '@ready/security.core';
import executeApiAsync from './utils/executeApiAsync';
import { toPairs } from 'lodash';
import { AllPermissionsMap } from '../redux/initialStates/session/session';

export interface IPermissionsAndResources {
  permissions: IPrincipalPermissionSet;
  allPermissionsMap: AllPermissionsMap;
}

const BASE_URL = `${config.readyDashboardApiEndpoint}`;

export class UserPermissionService {
  public static async getUserPermissions(contextId: string): Promise<IPermissionsAndResources> {
    const url = `${BASE_URL}/me/companies/${contextId}/permissions`;
    const permissions = await executeApiAsync<IPrincipalPermissionSet>(url);
    const allPermissionsMap = UserPermissionService.extractAllPermissionsMap(permissions);
    return { permissions, allPermissionsMap };
  }

  /**
   * Collect company permissions and all location permissions into one common map
   * The result can be used, for example, to define should Sidebar entry be displayed
   */
  private static extractAllPermissionsMap(permission: IPrincipalPermissionSet): AllPermissionsMap {
    const { companyPermissions, locationPermissions } = permission;
    const allMap: AllPermissionsMap = {};

    toPairs(companyPermissions).forEach(([resourceType, actions]) => {
      allMap[resourceType] = {};
      actions.forEach((action) => (allMap[resourceType][action] = true));
    });

    Object.values(locationPermissions).forEach((locationEntry) => {
      toPairs(locationEntry).forEach(([resourceType, actions]) => {
        if (!allMap[resourceType]) {
          allMap[resourceType] = {};
        }
        actions.forEach((action) => (allMap[resourceType][action] = true));
      });
    });

    return allMap;
  }
}
