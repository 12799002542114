import React from 'react';
import ListModal from 'components/Modal/ListModal';
import { ModalSearchInput } from '../modalSearchInput/ModalSearchInput';

export interface CreateEditItemModalProps {
  companyId: string;
  query: string;
  handleQueryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setShowModal: (isVisible: boolean) => void;
  searchInputPlaceholder?: string;
  headerLabel?: string;
  modalListComponent?: React.ReactNode;
}

export const CreateEditItemModal = (props: CreateEditItemModalProps): JSX.Element => {
  const { searchInputPlaceholder, handleQueryChange, query, headerLabel, modalListComponent, setShowModal } = props;

  return (
    <ListModal
      setShowModal={setShowModal}
      headerLabel={headerLabel}
      searchControl={
        <ModalSearchInput handleQueryChange={handleQueryChange} placeholder={searchInputPlaceholder} query={query} />
      }
      showDismissButton
    >
      {modalListComponent}
    </ListModal>
  );
};
