import React from 'react';
import { DatePickerValue } from '../../../components/DatePicker/DatePicker';
import { Action } from '../../../redux/types';
import { beginningOfTime, concatenetaDateTime, isBeginningOfTime } from '../../../utils/dateUtils';
import styles from './DialogPickupDateInput.module.scss';
import DateTimeInput from '../../../components/DateTimeInput/DateTimeInput';

export interface DialogPickupDateInputProps {
  value: Date;
  onChange: (value: Date) => Action;
  hasError?: boolean;
  errorMessage?: string;
}

const DialogPickupDateInput = ({
  value: currentDateTime,
  onChange,
  hasError,
  errorMessage,
}: DialogPickupDateInputProps): JSX.Element => {
  const handleChangeDate = (newValue: DatePickerValue) => {
    const newDate = newValue.startDate || beginningOfTime;
    onChange(concatenetaDateTime(newDate, currentDateTime));
  };

  return (
    <div className={styles.container}>
      <h5 className={styles.header}>Pick Up Date</h5>
      <div className={styles.inputContainer}>
        <DateTimeInput
          placeholder='Select a date'
          value={{
            startMonth: isBeginningOfTime(currentDateTime) ? new Date() : currentDateTime,
            endMonth: isBeginningOfTime(currentDateTime) ? new Date() : currentDateTime,
            startDate: isBeginningOfTime(currentDateTime) ? undefined : currentDateTime,
            endDate: isBeginningOfTime(currentDateTime) ? undefined : currentDateTime,
          }}
          type='date'
          clearable
          withError={hasError}
          onChange={handleChangeDate}
        />
        {hasError && <p className={styles.errorMessage}>{errorMessage}</p>}
      </div>
    </div>
  );
};

export default DialogPickupDateInput;
