import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableHeader } from '../../components/TableV2/TableHeader/TableHeader';
import { TableV2 } from '../../components/TableV2/TableV2';
import { Body, BodySkeleton } from '../../components/TableV2/Body/Body';
import LayoutContent from '../../components/AppLayout/LayoutContent';
import { ToolBar } from './components/ToolBar/ToolBar';
import { usePageTitle, useSearchParams } from '../../hooks';
import { PaginationContainer } from '../../components/PaginationContainer/PaginationContainer';
import { useHistory, useLocation } from 'react-router-dom';
import { initDateFilterUrlQuery } from '../../utils/urlUtils/initDateFilterUrlQuery';
import { fetchPaymentsReportThunk, fetchPermittedLocationsThunk } from './redux/thunks';
import { selectPaymentsReport } from './redux/selectors';
import { cells, columns, headerCells, headerColumns, initDateFilter, rowKey } from './settings';
import styles from './PaymentsReportPage.module.scss';

export const PaymentsReportPage = () => {
  usePageTitle('Payments', 'Reports');
  const { query, page, sortColumn, sortDirection } = useSearchParams(true);
  const { pathname } = useLocation();
  const { push } = useHistory();

  const { contextId, permittedLocations, loading, items, pagination } = useSelector(selectPaymentsReport);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!query) {
      const redirectUrl = `${pathname}?${initDateFilterUrlQuery}`;
      push(redirectUrl);
    }
  }, [pathname, push, query, dispatch]);

  useEffect(() => {
    dispatch(fetchPermittedLocationsThunk(contextId));
  }, [contextId, dispatch]);

  useEffect(() => {
    if (query) {
      dispatch(fetchPaymentsReportThunk({ contextId, query, page, sortColumn, sortDirection }));
    }
  }, [contextId, query, page, sortColumn, sortDirection, dispatch]);

  return (
    <LayoutContent
      title='Payments'
      toolbarContent={<ToolBar initDateFilter={initDateFilter} locations={permittedLocations.results} />}
      containerType='within'
      scrollToolbarForMobile
    >
      <div className={styles.table}>
        <PaginationContainer pagination={pagination}>
          <TableV2 loading={loading.list}>
            <>
              <TableHeader cells={headerCells} data={headerColumns} columns={columns} />
              {loading.list ? (
                <BodySkeleton columns={columns} rowsNumber={50} />
              ) : (
                <Body cells={cells} hiddenColumns={{}} rowKey={rowKey} data={items} columns={columns} />
              )}
            </>
          </TableV2>
        </PaginationContainer>
      </div>
    </LayoutContent>
  );
};
