import React from 'react';
import FeatureToggles from '../utils/featureToggle/featureToggle';

/** Loads FeatureToggles utility functions into window. */
const useFeatureToggles = () => {
  React.useEffect(() => {
    // @ts-ignore since FeatureToggle does not belong to the native window object
    if (!window.FeatureToggles) window.FeatureToggles = FeatureToggles;
  }, []);
};

export default useFeatureToggles;
