import { IBadge, ITag } from '@ready/menu.core';
import { pageErrorState, set200Toast, toastErrorState } from '../../redux/actions/uiActions/responseStateActions';
import { Action } from '../../redux/types';
import TagsBadgesService from '../services/TagsBadgesService';
import { newTag, newTagForm, newBadgeForm, newBadge } from './TagsBadgesListState';

export const TAGS_BADGES_LIST_ACTIONS = {
  TAGS_BADGES_LIST_INIT: 'TAGS_BADGES_LIST_INIT',
  TAGS_BADGES_LIST_LOADING: 'TAGS_BADGES_LIST_LOADING',
  TAGS_BADGES_LIST_SET: 'TAGS_BADGES_LIST_SET',
  TAGS_MODAL_EDIT: 'TAGS_MODAL_EDIT',
  UPDATE_TAG_SHORT_NAME: 'UPDATE_TAG_SHORT_NAME',
  UPDATE_TAG_NAME: 'UPDATE_TAG_NAME',
  UPDATE_UNIQUE_TAG_NAME: 'UPDATE_UNIQUE_TAG_NAME',
  UPDATE_UNIQUE_SHORT_CODE: 'UPDATE_UNIQUE_SHORT_CODE',
  SAVE_TAG_NAME_VALIDATED: 'SAVE_TAG_NAME_VALIDATED',
  SAVE_TAG_SHORT_NAME_VALIDATED: 'SAVE_TAG_SHORT_NAME_VALIDATED',
  SAVE_TAG_BEGIN: 'SAVE_TAG_BEGIN',
  SAVE_TAG_SUCCESS: 'SAVE_TAG_SUCCESS',
  SAVE_TAG_ERROR: 'SAVE_TAG_ERROR',
  RESET_TAG_FORMS: 'RESET_TAG_FORMS',
  PREPARE_TAG_EDIT_MODE: 'PREPARE_TAG_EDIT_MODE',
  DELETE_TAG_ITEM_REQUESTE: 'DELETE_TAG_ITEM_REQUESTE',
  DELETE_TAG_BEGIN: 'DELETE_TAG_BEGIN',
  DELETE_TAG_SUCCESS: 'DELETE_TAG_SUCCESS',
  DELETE_TAG_ERROR: 'DELETE_TAG_ERROR',
  CANCEL_REQUEST_DELETE_TAG: 'CANCEL_REQUEST_DELETE_TAG',
  SAVE_BADGE_TEXT_VALIDATED: 'SAVE_BADGE_TEXT_VALIDATED',
  SAVE_BADGE_BEGIN: 'SAVE_BADGE_BEGIN',
  SAVE_BADGE_SUCCESS: 'SAVE_BADGE_SUCCESS',
  SAVE_BADGE_ERROR: 'SAVE_BADGE_ERROR',
  DELETE_BADGE_BEGIN: 'DELETE_BADGE_BEGIN',
  DELETE_BADGE_SUCCESS: 'DELETE_BADGE_SUCCESS',
  DELETE_BADGE_ERROR: 'DELETE_BADGE_ERROR',
  DELETE_BADGE_ITEM_REQUESTE: 'DELETE_BADGE_ITEM_REQUESTE',

  BADGE_MODAL_EDIT: 'BADGE_MODAL_EDIT',
  UPDATE_BADGE_NAME: 'UPDATE_BADGE_NAME',
  UPDATE_BADGE_TEXT: 'UPDATE_BADGE_TEXT',
  UPDATE_BADGE_TEXT_COLOR: 'UPDATE_BADGE_TEXT_COLOR',
  UPDATE_BADGE_BACKGROUND_COLOR: 'UPDATE_BADGE_BACKGROUND_COLOR',
  BADGE_NAME_VALIDATION_ERROR: 'BADGE_NAME_VALIDATION_ERROR',
  BADGE_TEXT_VALIDATION_ERROR: 'BADGE_TEXT_VALIDATION_ERROR',
  REQUEST_DELETE_BADGE: 'REQUEST_DELETE_BADGE',
  CANCEL_REQUEST_DELETE_BADGE: 'CANCEL_REQUEST_DELETE_BADGE',
};

export const initTagsBadgesList = (): Action => ({
  type: TAGS_BADGES_LIST_ACTIONS.TAGS_BADGES_LIST_INIT,
});

export const fetchTagsBadgesList = (companyId: string) => async (dispatch: any) => {
  dispatch(initTagsBadgesList);
  try {
    dispatch(loadingTagsBadgesList(true));
    const tags = await TagsBadgesService.getTagsList(companyId);
    const badges = await TagsBadgesService.getBadgesList(companyId);

    dispatch(setTagsBadgesList(tags, badges));
  } catch (err) {
    dispatch(pageErrorState(err.status, err.message));
  } finally {
    dispatch(loadingTagsBadgesList(false));
  }
};

export const setTagCreateMode = (editing: boolean) => ({
  type: TAGS_BADGES_LIST_ACTIONS.TAGS_MODAL_EDIT,
  payload: newTagForm(editing, newTag()),
});

const loadingTagsBadgesList = (loading: boolean): Action => ({
  type: TAGS_BADGES_LIST_ACTIONS.TAGS_BADGES_LIST_LOADING,
  payload: loading,
});

const setTagsBadgesList = (tags: ITag[], badges: IBadge[]): Action => ({
  type: TAGS_BADGES_LIST_ACTIONS.TAGS_BADGES_LIST_SET,
  payload: { tags, badges },
});

export const updateTagShortName = (tagShortCode: string): Action => ({
  type: TAGS_BADGES_LIST_ACTIONS.UPDATE_TAG_SHORT_NAME,
  payload: tagShortCode,
});

export const updateTagName = (tagName: string): Action => ({
  type: TAGS_BADGES_LIST_ACTIONS.UPDATE_TAG_NAME,
  payload: tagName,
});

export const updateUniqueTagName = (): Action => ({
  type: TAGS_BADGES_LIST_ACTIONS.UPDATE_UNIQUE_TAG_NAME,
  payload: {
    hasError: true,
    notUnique: true,
    errorMessage: 'Name already in use. Please enter a unique name.',
  },
});

export const updateUniqueShortCode = (): Action => ({
  type: TAGS_BADGES_LIST_ACTIONS.UPDATE_UNIQUE_SHORT_CODE,
  payload: {
    hasError: false,
    notUnique: true,
    errorMessage:
      'Short code already in use. A unique code is not required, but using the same short code for different tags may cause confusion for guests.',
  },
});

export const saveTag = (companyId: string, tag: ITag) => async (dispatch: any) => {
  let hasError = false;
  if (!tag.name) {
    hasError = true;
    dispatch({
      type: TAGS_BADGES_LIST_ACTIONS.SAVE_TAG_NAME_VALIDATED,
      payload: {
        hasError: true,
        errorMessage: 'Name is required',
      },
    });
  }
  if (!tag.shortCode) {
    hasError = true;
    dispatch({
      type: TAGS_BADGES_LIST_ACTIONS.SAVE_TAG_SHORT_NAME_VALIDATED,
      payload: {
        hasError: true,
        errorMessage: 'Short Code is required',
      },
    });
  }
  if (hasError) {
    return;
  }
  dispatch({ type: TAGS_BADGES_LIST_ACTIONS.SAVE_TAG_BEGIN });
  try {
    let updatedTag: ITag;
    if (!tag._id) {
      updatedTag = await TagsBadgesService.createTag(companyId, tag);
    } else {
      updatedTag = await TagsBadgesService.updateTag(companyId, tag);
    }
    dispatch({
      type: TAGS_BADGES_LIST_ACTIONS.SAVE_TAG_SUCCESS,
      payload: updatedTag,
    });
    dispatch(fetchTagsBadgesList(companyId));

    if (!tag._id) {
      dispatch(set200Toast('Success! New tag created.'));
    } else {
      dispatch(set200Toast());
    }
  } catch (error) {
    dispatch({ type: TAGS_BADGES_LIST_ACTIONS.SAVE_TAG_ERROR });
    dispatch(toastErrorState(error.status, error.message));
  }
};

export const saveBadge = (companyId: string, badge: IBadge) => async (dispatch: any) => {
  let hasError = false;
  if (!badge.name) {
    hasError = true;
    dispatch({
      type: TAGS_BADGES_LIST_ACTIONS.BADGE_NAME_VALIDATION_ERROR,
      payload: {
        hasError: true,
        errorMessage: 'Name is required',
      },
    });
  }
  if (!badge.text) {
    hasError = true;
    dispatch({
      type: TAGS_BADGES_LIST_ACTIONS.BADGE_TEXT_VALIDATION_ERROR,
      payload: {
        hasError: true,
        errorMessage: 'Text is required',
      },
    });
  }
  if (hasError) {
    return;
  }
  dispatch({ type: TAGS_BADGES_LIST_ACTIONS.SAVE_BADGE_BEGIN });
  try {
    let updatedBadge: IBadge;
    if (!badge._id) {
      updatedBadge = await TagsBadgesService.createBadge(companyId, badge);
    } else {
      updatedBadge = await TagsBadgesService.updateBadge(companyId, badge);
    }
    dispatch({
      type: TAGS_BADGES_LIST_ACTIONS.SAVE_BADGE_SUCCESS,
      payload: updatedBadge,
    });
    dispatch(fetchTagsBadgesList(companyId));

    if (!badge._id) {
      dispatch(set200Toast('Success! New badge created.'));
    } else {
      dispatch(set200Toast());
    }

    dispatch(fetchTagsBadgesList(companyId));
  } catch (error) {
    dispatch({ type: TAGS_BADGES_LIST_ACTIONS.SAVE_BADGE_ERROR });
    dispatch(toastErrorState(error.status, error.message));
  }
};

export const showDeleteBadgeModal = (show: boolean, badge?: IBadge) => ({
  type: TAGS_BADGES_LIST_ACTIONS.DELETE_BADGE_ITEM_REQUESTE,
  payload: { show, badge },
});

export const setTagEditMode = (editing: boolean, tag: ITag) => ({
  type: TAGS_BADGES_LIST_ACTIONS.PREPARE_TAG_EDIT_MODE,
  payload: newTagForm(editing, tag),
});

export const showDeleteTagModal = (show: boolean, tag?: ITag) => ({
  type: TAGS_BADGES_LIST_ACTIONS.DELETE_TAG_ITEM_REQUESTE,
  payload: { show, tag },
});

export const deleteTag = (companyId: string, tag: ITag) => async (dispatch: any) => {
  dispatch({ type: TAGS_BADGES_LIST_ACTIONS.DELETE_TAG_BEGIN });
  try {
    await TagsBadgesService.deleteTagById(companyId, tag._id);
    dispatch(set200Toast('Tag deleted.'));
    dispatch({ type: TAGS_BADGES_LIST_ACTIONS.DELETE_TAG_SUCCESS });
    dispatch(fetchTagsBadgesList(companyId));
  } catch (error) {
    dispatch(toastErrorState(error.status, error.message));
    dispatch({ type: TAGS_BADGES_LIST_ACTIONS.DELETE_TAG_ERROR });
  }
};

export const deleteBadge = (companyId: string, badge: IBadge) => async (dispatch: any) => {
  dispatch({ type: TAGS_BADGES_LIST_ACTIONS.DELETE_BADGE_BEGIN });
  try {
    await TagsBadgesService.deleteBadgeById(companyId, badge._id);
    dispatch(set200Toast('Badge deleted.'));
    dispatch({ type: TAGS_BADGES_LIST_ACTIONS.DELETE_BADGE_SUCCESS });
    dispatch(fetchTagsBadgesList(companyId));
  } catch (error) {
    dispatch(toastErrorState(error.status, error.message));
    dispatch({ type: TAGS_BADGES_LIST_ACTIONS.DELETE_BADGE_ERROR });
  }
};

export const setBadgeCreateMode = (editing: boolean) => ({
  type: TAGS_BADGES_LIST_ACTIONS.BADGE_MODAL_EDIT,
  payload: newBadgeForm(editing, newBadge()),
});

export const updateBadgeName = (name: string) => ({
  type: TAGS_BADGES_LIST_ACTIONS.UPDATE_BADGE_NAME,
  payload: name,
});

export const updateBadgeText = (text: string) => ({
  type: TAGS_BADGES_LIST_ACTIONS.UPDATE_BADGE_TEXT,
  payload: text,
});

export const updateBadgeTextColor = (color: string) => ({
  type: TAGS_BADGES_LIST_ACTIONS.UPDATE_BADGE_TEXT_COLOR,
  payload: color,
});

export const updateBackgroundColor = (color: string) => ({
  type: TAGS_BADGES_LIST_ACTIONS.UPDATE_BADGE_BACKGROUND_COLOR,
  payload: color,
});

export const setBadgeEditMode = (editing: boolean, badge: IBadge) => ({
  type: TAGS_BADGES_LIST_ACTIONS.BADGE_MODAL_EDIT,
  payload: newBadgeForm(editing, badge),
});

export const requestDeleteBadge = (badge: IBadge) => ({
  type: TAGS_BADGES_LIST_ACTIONS.REQUEST_DELETE_BADGE,
  payload: badge,
});

export const cancelRequestDeleteBadge = () => ({
  type: TAGS_BADGES_LIST_ACTIONS.CANCEL_REQUEST_DELETE_BADGE,
});

export const cancelRequestDeleteTag = () => ({
  type: TAGS_BADGES_LIST_ACTIONS.CANCEL_REQUEST_DELETE_TAG,
});
