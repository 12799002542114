import { CONTEXT_SWITCHER_ACTIONS } from './types';
import { UserContextService } from '../../../services/userContextsService';

export const updateModalVisible = (visible: boolean) => ({
  type: CONTEXT_SWITCHER_ACTIONS.UPDATE_MODAL_VISIBLE,
  payload: visible,
});

export const updateQuery = (query: string) => ({
  type: CONTEXT_SWITCHER_ACTIONS.UPDATE_QUERY,
  payload: query,
});

export const getContextsBegin = () => ({
  type: CONTEXT_SWITCHER_ACTIONS.GET_CONTEXTS_BEGIN,
});

export const getContextsSuccess = (contexts: any) => ({
  type: CONTEXT_SWITCHER_ACTIONS.GET_CONTEXTS_SUCCESS,
  payload: contexts,
});

export const getContextsError = () => ({
  type: CONTEXT_SWITCHER_ACTIONS.GET_CONTEXTS_ERROR,
});

export const loadMoreContextsBegin = () => ({
  type: CONTEXT_SWITCHER_ACTIONS.LOAD_MORE_CONTEXTS_BEGIN,
});

export const loadMoreContextsSuccess = (contexts: any) => ({
  type: CONTEXT_SWITCHER_ACTIONS.LOAD_MORE_CONTEXTS_SUCCESS,
  payload: contexts,
});

export const loadMoreContextsError = () => ({
  type: CONTEXT_SWITCHER_ACTIONS.LOAD_MORE_CONTEXTS_ERROR,
});

export const loadMoreContextsNoResults = () => ({
  type: CONTEXT_SWITCHER_ACTIONS.LOAD_MORE_CONTEXTS_NO_RESULTS,
});

export const getContexts =
  (query: string = '') =>
  async (dispatch: any) => {
    dispatch(getContextsBegin());
    try {
      const contexts = await UserContextService.getUserContexts(query);
      dispatch(getContextsSuccess(contexts));
    } catch (err) {
      dispatch(getContextsError());
    }
  };

export const loadMoreContexts =
  (query: string = '', page: number) =>
  async (dispatch: any) => {
    dispatch(loadMoreContextsBegin());
    try {
      const nextPage = page + 1;
      const contexts = await UserContextService.getUserContexts(query, nextPage);
      if (contexts.length > 0) {
        dispatch(loadMoreContextsSuccess(contexts));
      } else {
        dispatch(loadMoreContextsNoResults());
      }
    } catch (err) {
      dispatch(loadMoreContextsError());
    }
  };
