import React from 'react';
import { Link } from 'react-router-dom';
import styles from './InfoCardColumnsWrapper.module.scss';

interface InfoCardColumnsWrapperProps {
  children: React.ReactNode;
  linkTo?: string;
}

export const InfoCardColumnsWrapper = (props: InfoCardColumnsWrapperProps) => {
  const { children, linkTo } = props;

  return linkTo ? (
    <Link to={linkTo} className={styles.container}>
      {children}
    </Link>
  ) : (
    <div className={styles.container}>{children}</div>
  );
};
