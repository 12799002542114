import React from 'react';
import styles from './ConnectWithPayPalButton.module.scss';
import paypalLogo from 'styles/assets/paypal.png';

export interface Props {
  link?: string;
}

const ConnectWithPayPalButton = ({ link }: Props) => {
  const error = !link;

  return (
    <>
      {!error ? (
        <div className={styles.paypalProviderButton}>
          <a
            data-paypal-button='true'
            href={`${link}&displayMode=minibrowser`}
            target='PPFrame'
            className={styles.paypalLink}
            aria-disabled={error}
          >
            <img src={paypalLogo} alt='paypal' className={styles.paypalLogo} />
            <span>Connect with PayPal</span>
          </a>
        </div>
      ) : (
        <p className={styles.errorText}>There was a problem retriving the Connect with PayPal URL</p>
      )}
    </>
  );
};

export default ConnectWithPayPalButton;
