export const CONTEXT_SWITCHER_ACTIONS = {
  UPDATE_MODAL_VISIBLE: 'UPDATE_MODAL_VISIBLE',
  UPDATE_QUERY: 'UPDATE_QUERY',
  GET_CONTEXTS_BEGIN: 'GET_CONTEXTS_BEGIN',
  GET_CONTEXTS_SUCCESS: 'GET_CONTEXTS_SUCCESS',
  GET_CONTEXTS_ERROR: 'GET_CONTEXTS_ERROR',
  LOAD_MORE_CONTEXTS_BEGIN: 'LOAD_MORE_CONTEXTS_BEGIN',
  LOAD_MORE_CONTEXTS_SUCCESS: 'LOAD_MORE_CONTEXTS_SUCCESS',
  LOAD_MORE_CONTEXTS_ERROR: 'LOAD_MORE_CONTEXTS_ERROR',
  LOAD_MORE_CONTEXTS_NO_RESULTS: 'LOAD_MORE_CONTEXTS_NO_RESULTS',
};
