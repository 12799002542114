import {
  ITransactionDetail,
  ITransactionDetailCheckPayment,
  ITransactionToShow,
  PaginationResponse,
} from '@ready/dashboardv2api.contracts';
import { saveAs } from 'file-saver';
import config from '../config';
import { ILocation } from './types/ILocation.type';
import { IPagedResponse } from './types/IPagedResponse.type';
import executeApiAsync, { ResponseType } from './utils/executeApiAsync';

const BASE_URL = `${config.readyDashboardApiEndpoint}/companies`;
const LEGACY_BASE_URL = `${config.readyApiLegacyEndpoint}/companies`;

export const getTransactions = async (
  contextId: string,
  query: string = '',
  page: number = 1
): Promise<PaginationResponse<ITransactionToShow>> => {
  const params = `${decodeURIComponent(query)}&page=${page}`;
  const url = `${BASE_URL}/${contextId}/transactions?${params}`;
  return await executeApiAsync<PaginationResponse<ITransactionToShow>>(url);
};

export const generateTransactionsExportReport = async (contextId: string, query: string): Promise<string> => {
  const params = `${decodeURIComponent(query)}`;
  const url = `${BASE_URL}/${contextId}/transactions/export?${params}`;
  const response = await executeApiAsync<{ id: string }>(url, {
    method: 'POST',
  });
  return response.id;
};

export const getTransactionsExportReport = async (contextId: string, id: string): Promise<boolean> => {
  const url = `${BASE_URL}/${contextId}/transactions/export/${id}`;
  try {
    try {
      await executeApiAsync(url);
    } catch (error) {
      if (error.status === 404) {
        // file is not ready yet
        return false;
      } else {
        throw error;
      }
    }

    // file is ready; download it
    const now = new Date();
    const filename = `TRANSACTIONS-${now.getFullYear()}-${now.getMonth()}-${now.getDate()} ${now.getHours()}-${now.getMinutes()}.zip`;
    const blob = await executeApiAsync<Blob>(`${url}/zip`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/zip',
        'Content-Disposition': `attachment; filename="${filename}"`,
        'Content-Transfer-Encoding': 'binary',
      },
      responseType: ResponseType.Blob,
    });

    // now store it
    saveAs(new Blob([blob], { type: 'application/zip' }), filename);
    return true;
  } catch (e) {
    console.error(`Cannot get transactions report by export id: ${id}`);
    throw e;
  }
};

export const getPermittedTransactionLocations = async (contextId: string): Promise<IPagedResponse<ILocation>> => {
  // security params
  const resourceType = 'transaction';
  const action = 'view';
  const securityParams = `permissions=${resourceType}.${action}`;
  const showHiddenLocations = false;

  const url = `${BASE_URL}/${contextId}/permittedlocations?${securityParams}&isHidden=${showHiddenLocations}`;
  return executeApiAsync<IPagedResponse<ILocation>>(url);
};

export const getTransactionDetail = async (
  contextId: string,
  locationId: string,
  id: string
): Promise<ITransactionDetail> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${contextId}/locations/${locationId}/transactions/${id}`;
  return executeApiAsync<ITransactionDetail>(url);
};

export const manualEnterPosPayment = async (
  contextId: string,
  locationId: string,
  transactionId: string,
  _id: string
): Promise<boolean> => {
  const status = 'success';
  const url = `${BASE_URL}/${contextId}/locations/${locationId}/transactions/${transactionId}/posPayment/${_id}/status`;
  const body = JSON.stringify({ status });
  const result = await executeApiAsync<ITransactionDetailCheckPayment>(url, {
    method: 'PUT',
    body,
    throwServiceErrorOnFailStatus: true,
  });
  return !!result._id;
};

export const manualRetryPosPayment = async (
  contextId: string,
  locationId: string,
  transactionId: string,
  ticketId: string,
  subtotal: number,
  tip: number
): Promise<boolean> => {
  const url = `${LEGACY_BASE_URL}/${contextId}/locations/${locationId}/transactions/${transactionId}/ticket/${ticketId}/retryPosPayment`;
  const result: { retryResult: boolean } = await executeApiAsync(url, {
    method: 'POST',
    throwServiceErrorOnFailStatus: true,
  });
  return result?.retryResult || false;
};

export const refundGiftCardByInvoiceId = async (
  companyId: string,
  locationId: string,
  invoiceId: string,
  amount: number
): Promise<ITransactionDetail> => {
  return await executeApiAsync(
    `${LEGACY_BASE_URL}/${companyId}/locations/${locationId}/transactions/${invoiceId}/refund/giftcard`,
    { method: 'POST', body: JSON.stringify({ amount }) }
  );
};
