import { ICachedPosModifier } from '@ready/menu.core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { components, OptionProps, SingleValueProps } from 'react-select';
import styles from './POSModifierSearch.module.scss';
import { loadPOSModifiers, LOCATION_SETTINGS_ACTIONS } from '../../../../redux/LocationSettingsActions';

import { Option } from '../../../../../components/SelectFilter/SelectFilter';
import SelectFilterAsync from '../../../../../components/SelectFilter/SelectFilterAsync';
import { IUpdateLocationParam } from '../../../LocationsEditPage';

export interface POSModifierSearchProps {
  companyId: string;
  locationId: string;
  loading: boolean;
  selectedModifier?: ICachedPosModifier;
  modifiers: ICachedPosModifier[];
  updateLocationParam: IUpdateLocationParam;
}

const ModifierOption = (props: OptionProps<Option, boolean>) => (
  <components.Option {...props}>
    <div className={styles.details}>
      {props.data.label}
      <div className={props.isDisabled ? styles.secondarySmallDisabled : styles.secondarySmall}>
        {props.data.subLabel}
      </div>
    </div>
  </components.Option>
);

const ModifierSingleValue = (props: SingleValueProps<Option>) => {
  const subLabel = props.data.subLabel ? props.data.subLabel.substring(0, props.data.subLabel.indexOf(' | ')) : '';
  return (
    <components.SingleValue {...props}>
      {props.data.label}
      {subLabel && (
        <>
          {' '}
          <span className={styles.secondary}>{`(${subLabel})`}</span>
        </>
      )}
    </components.SingleValue>
  );
};

const POSModifierSearch = (props: POSModifierSearchProps) => {
  const { companyId, locationId, updateLocationParam, loading, modifiers, selectedModifier } = props;
  const dispatch = useDispatch();

  const fetchPOSModifiers = React.useCallback(
    (filter: string = '', page: number = 1, limit: number = 1) => {
      dispatch(loadPOSModifiers(companyId, locationId, filter, page, limit));
    },
    [dispatch, companyId, locationId]
  );

  const initFetch = (limit: number) => fetchPOSModifiers('', 1, limit);

  const handleFetch = (filter: string, limit: number) => {
    fetchPOSModifiers(filter, 1, limit);
  };

  const modifierOptions = modifiers.map<Option>((modifier) => {
    return {
      value: modifier._id,
      label: modifier.name,
      subLabel: `${modifier.externalId} | ${(modifier.categories || []).join(', ')}`,
      isDisabled: selectedModifier ? selectedModifier._id === modifier._id : false,
    };
  });

  const selectedOption = modifierOptions.find((option) => option.value === selectedModifier?._id);

  const handleSelectFilterChange = (selected: Option) => {
    const updatedModifier = modifiers.find((modifier) => modifier._id === selected?.value);
    updateLocationParam(updatedModifier, LOCATION_SETTINGS_ACTIONS.UPDATE_POS_MODIFIER);
  };

  return (
    <SelectFilterAsync
      placeholder='Search to select a modifier'
      options={modifierOptions}
      value={selectedOption}
      loading={loading}
      limit={50}
      isClearable
      handleInitialFetch={initFetch}
      handleFetch={handleFetch}
      onChange={handleSelectFilterChange}
      customComponents={{
        Option: ModifierOption,
        SingleValue: ModifierSingleValue,
      }}
    />
  );
};

export default POSModifierSearch;
