import React from 'react';
import { TablesTab } from '../../../enums/tables.enum';
import LocationDetailsTabs from './LocationDetailsTabs';
import LayoutContent from '../../../components/AppLayout/LayoutContent';
import ActionHeader from '../../../components/ActionHeader/ActionHeader';
import { NavigationConfirmationDialog } from 'components/NavigationConfirmationDialog';
import { selectFormState } from 'redux/selectors/uiSelectors/uiSelectors';
import { useAppSelector } from 'redux/store';
import { IFormState } from 'redux/initialStates/ui/formState';
export interface TableTargetLayoutContentProps {
  companyId: string;
  locationId: string;
  locationName: string | null;
  tab: TablesTab;
  children?: JSX.Element | JSX.Element[];
}

const TableTargetLayoutContent = (props: TableTargetLayoutContentProps): JSX.Element => {
  const { companyId, locationId, locationName, tab, children = null } = props;
  const { isDirty } = useAppSelector<IFormState>(selectFormState);

  const backLinkTo = `/companies/${companyId}/companyLocations`;

  return (
    <LayoutContent title='Location Details'>
      {locationName ? <ActionHeader text={locationName} backLinkTo={backLinkTo} /> : null}
      <LocationDetailsTabs companyId={companyId} locationId={locationId} tab={tab} />
      <NavigationConfirmationDialog shouldBlockNav={isDirty} />
      {children}
    </LayoutContent>
  );
};

export default TableTargetLayoutContent;
