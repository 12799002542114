import { toastErrorState } from '../../redux/actions/uiActions/responseStateActions';
import TagsBadgesService from '../../tagsBadges/services/TagsBadgesService';

export const actionTypes = {
  SET_TAGS_LIST_DIALOG_VISIBLE: 'SET_TAGS_LIST_DIALOG_VISIBLE',
  GET_TAGS_LIST_BEGIN: 'GET_TAGS_LIST_BEGIN',
  GET_TAGS_LIST_SUCCESS: 'GET_TAGS_LIST_SUCCESS',
  GET_TAGS_LIST_ERROR: 'GET_TAGS_LIST_ERROR',
  UPDATE_TAGS_LIST_QUERY: 'UPDATE_TAGS_LIST_QUERY',
};

export const setTagsListDialogVisible = (isVisible: boolean) => ({
  type: actionTypes.SET_TAGS_LIST_DIALOG_VISIBLE,
  payload: isVisible,
});

export const getTagsList = (companyId: string, locationId?: string) => async (dispatch: any) => {
  try {
    dispatch({ type: actionTypes.GET_TAGS_LIST_BEGIN });

    const tagsList = await TagsBadgesService.getTagsList(companyId, locationId);

    dispatch({ type: actionTypes.GET_TAGS_LIST_SUCCESS, payload: tagsList });
  } catch (error) {
    toastErrorState(error.status, error.message);
    dispatch({ type: actionTypes.GET_TAGS_LIST_ERROR });
  }
};

export const updateTagsListQuery = (query: string) => ({
  type: actionTypes.UPDATE_TAGS_LIST_QUERY,
  payload: query,
});
