import config from '../config';

const isValidEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
};

const { adminEmailDomains } = config;
const adminDomains = adminEmailDomains.split(',');

const isAdminEmailDomain = (email: string): boolean => {
  if (!isValidEmail) return false;
  const emailSegments = email.split('@');
  return adminDomains.includes(emailSegments[1]);
};

export { isValidEmail, isAdminEmailDomain };
