import React from 'react';
import { ChecksCheckStatus, ChecksReadyPayment } from '@ready/dashboardv2api.contracts';
import classnames from 'classnames';
import { DateFilterRestrictable } from '../../../../../components/DateFilter/DateFilter';
import SearchSelectFilter, { Option } from '../../../../../components/SearchSelectFilter/SearchSelectFilter';
import { DatePickerValue } from '../../../../../components/DatePicker/DatePicker';
import { maxDaysToChoose } from '../../reportSettings';
import SearchInputFilter from '../../../../../components/SearchInputFilter/SearchInputFilter';
import Checkbox from '../../../../../components/Checkbox/Checkbox';
import { OptionProps } from 'react-select';
import { components } from 'react-select';
import styles from './ToolBar.module.scss';

const checkStatusOptions: { value: ChecksCheckStatus | ''; label: string }[] = [
  { value: '', label: 'All' },
  { value: ChecksCheckStatus.Open, label: 'Open' },
  { value: ChecksCheckStatus.Closed, label: 'Closed' },
];

const readyPaymentOptions: { value: ChecksReadyPayment; label: string }[] = [
  { value: ChecksReadyPayment.Full, label: 'Full' },
  { value: ChecksReadyPayment.Partial, label: 'Partial' },
  { value: ChecksReadyPayment.Unpaid, label: 'Unpaid' },
  { value: ChecksReadyPayment.None, label: 'None' },
];

interface ToolBarProps {
  locations: Array<{ name: string; _id: string }>;
  initDateFilter: DatePickerValue;
}

const ReadyPaymentOptionComponent = ({ innerProps, data, isSelected }: OptionProps<Option, boolean>) => (
  <span {...innerProps} className={styles.ReadyPaymentOptionComponent}>
    <Checkbox onChange={() => {}} label={data.label} checked={isSelected} />
  </span>
);

const ReadyPaymentValueContainer = (props: any) => {
  const options = props.getValue();
  const input = props.children[1];

  let label: string | React.ReactElement = '';
  const singleOption = 1;
  const noOptions = 0;
  const maxOptions = props.options.length;
  const allSelectedLabel = <span className={styles.ReadyPaymentTextGray}>All</span>;

  switch (options.length) {
    case singleOption:
      label = options[0].label;
      break;
    case maxOptions:
      label = allSelectedLabel;
      break;
    case noOptions:
      label = allSelectedLabel;
      break;
    default:
      label = `${options.length} selected`;
  }

  return (
    <div className={styles.ReadyPaymentValueContainer}>
      {label}
      {input}
    </div>
  );
};

const CheckStatusValueContainer = (props: any) => {
  const { ValueContainer } = components;
  return (
    <ValueContainer
      {...props}
      className={classnames(props.className, { [styles.CheckStatusTextGray]: props.getValue()[0]?.label === 'All' })}
    />
  );
};

export const ToolBar = ({ locations, initDateFilter }: ToolBarProps) => {
  const locationOptions: Option[] = locations?.map(({ name, _id }) => ({ value: _id, label: name })) || [];

  return (
    <>
      <div className={styles.toolBar}>
        <div className={styles.blockWidthBig}>
          <h4>Date Range</h4>
          <DateFilterRestrictable initialValue={initDateFilter} maxDays={maxDaysToChoose} />
        </div>
        <div className={styles.blockWidthBig}>
          <h4>Location</h4>
          <SearchSelectFilter
            placeholder='Select a location'
            options={locationOptions}
            name='locations'
            isSearchable
            isMulti
          />
        </div>
        <div className={styles.filterWidthSmall}>
          <h4>Check Status</h4>
          <SearchSelectFilter
            components={{ ValueContainer: CheckStatusValueContainer }}
            options={checkStatusOptions}
            name='checkStatus'
          />
        </div>
        <div className={styles.filterWidthSmall}>
          <h4>Ready Payment</h4>
          <SearchSelectFilter
            hideSelectedOptions={false}
            defaultValue=''
            name='readyPayment'
            options={readyPaymentOptions}
            isMulti={true}
            components={{ Option: ReadyPaymentOptionComponent, ValueContainer: ReadyPaymentValueContainer }}
          />
        </div>
      </div>

      <div className={styles.toolBar}>
        <div className={styles.blockSearch}>
          <h4>Check #</h4>
          <SearchInputFilter name='check' placeholder='Type to search' />
        </div>
        <div className={styles.blockSearch}>
          <h4>Table</h4>
          <SearchInputFilter name='table' placeholder='Type to search' />
        </div>
      </div>
    </>
  );
};
