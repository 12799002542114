import React, { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MenuResourceActions, PrincipalPermissions, ResourceType, SecurityScope, Verifier } from '@ready/security.core';

import { useHistory, useParams } from 'react-router-dom';
import { MenuContextParams } from '../../types/MenuContextParams.interface';
import { Routes } from '../../MenuBuilderRouter';
import useS3ImagesMap, { IImageS3Request } from '../../../hooks/useS3Images';

import MenuBuilderTab from '../../types/MenuBuilderTab.enum';
import MenuItemsView from '../../types/MenuItemsView.enum';
import { LocationsThunks } from '../../redux/LocationsThunks';
import {
  addReadyForcedModifierToMenuItem,
  cancelNewItem,
  clearMenuItem,
  deleteMenuItem,
  duplicateMenuItem,
  loadMenuItem,
  loadNewMenuItem,
  loadNewModifier,
  moveModifierGroupInMenuItem,
  prepareForcedModiferModal,
  prepareMenuItemsModal,
  preparePosItemsModal,
  reenableMenuItem,
  removeMenuItemFromMenuItemPairing,
  removeModifierGroupFromMenuItem,
  removeReadyForcedModifierToMenuItem,
  resetMenuItem,
  saveMenuItem,
  selectPosItemInPosItemsModal,
  showDuplicateItemModal,
  updateMenuItemActivePriceLevel,
  updateMenuItemAddTag,
  updateMenuItemAlcoholAmount,
  updateMenuItemBadge,
  updateMenuItemContainsAlcohol,
  updateMenuItemDescription,
  updateMenuItemDisplayName,
  updateMenuItemEffectiveEndDate,
  updateMenuItemEffectiveStartDate,
  updateMenuItemImage,
  updateMenuItemInStock,
  updateMenuItemIsPopular,
  updateMenuItemRemoveTag,
  updateMenuItemScheduler,
  updateMenuItemStatus,
  updateMenuItemThumbnailImage,
  updateMenuItemPrice,
  selectTaxes,
  saveSmbMenuItem,
  loadSmbMenuItem,
} from '../../redux/ItemsAndModsActions';
import { prepareModifierGroupsModal } from '../../redux/ModifierGroupsActions';
import { selectPermissions } from '../../../redux/selectors/sessionSelectors/sessionSelectors';
import { selectFormState } from '../../../redux/selectors/uiSelectors/uiSelectors';
import { selectLocationsState } from '../../redux/LocationsSelectors';
import { selectMenuItemCacheState } from '../../../redux/selectors/cacheSelectors/cacheSelectors';
import { selectModifierGroupSelectionState } from '../../redux/ModifierGroupsSelectors';
import { selectScheduleListState } from '../../redux/SchedulesSelectors';
import {
  selectMenuItemForMenuItemPairing,
  deselectMenuItemFromMenuItemPairing,
  selectModifierGroupForMenuItem,
  deselectModifierGroupFromMenuItem,
} from '../../redux/MenuBuilderActions';
import { setFormIsDirty } from '../../../redux/actions/uiActions/formStateActions';
import {
  ForcedModifierStatus,
  IBadge,
  ICachedPosMenuItem,
  ICachedPosModifier,
  IEmbeddedMenuItem,
  IEmbeddedModifierItem,
  IForcedModifier,
  IMenuItem,
  IModifierGroup,
  IScheduleLink,
  ITag,
  MenuItemInactiveStates,
  Status,
} from '@ready/menu.core';
import {
  selectForcedModSelectionState,
  selectItemsAndModsState,
  selectMenuItemFormState,
  selectMenuItemSelectionState,
  selectPosItemSelectionState,
} from '../../redux/ItemsAndModsSelectors';
import { selectBadgesListState } from '../../redux/BadgesListSelector';
import { getBadges } from '../../redux/BadgesListActions';
import { IPermissions } from '../../../redux/initialStates/session/session';
import { IFormState } from '../../../redux/initialStates/ui/formState';
import { IModifierGroupSelection } from '../../redux/ModifierGroupsState';
import { ScheduleListState } from '../../redux/ScheduleListState';
import {
  IItemsAndModsState,
  IMenuItemForm,
  IMenuItemSelection,
  IPosItemSelection,
} from '../../redux/ItemsAndModsState';
import { BadgesListState } from '../../redux/BadgesListState';
import { RootState } from '../../../redux/store';
import { ILocationsState } from '../../redux/LocationsState';

import styles from './MenuItemDetailPage.module.scss';

import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import ActionHeader from '../../../components/ActionHeader/ActionHeader';
import FormActionBar from '../../../components/FormActionBar/FormActionBar';
import { Form, FormControl } from '../../../components/Form';
import { Panel, PanelLayout } from '../../../components/PanelLayout';
import TextInput from '../../../components/TextInput/TextInput';
import TextArea from '../../../components/TextArea/TextArea';
import SelectFilter, { Option } from '../../../components/SelectFilter/SelectFilter';
import FormattedInput from '../../../components/FormattedInput/FormattedInput';
import Checkbox from '../../../components/Checkbox/Checkbox';
import MenuBuilderLayoutContent from '../MenuBuilderLayoutContent';
import Button from '../../../components/Button/Button';
import ModifierGroupCard from './ModifierGroupCard';
import AddModifierGroupDialog from '../ModifierGroups/AddModifierGroupDialog';
import OverflowButton from '../../../components/OverflowButton/OverflowButton';
import DragAndDrop from '../../../components/DragAndDrop';
import EmptyResult from '../../../components/EmptyResult/EmptyResult';
import Toggle from '../../../components/Toggle/Toggle';

import TextIcon, { Icon } from '../../../components/Icon/TextIcon';
import LinkPosItemDialog from './LinkPosItemDialog';
import MenuItemCard from './MenuItemCard';
import InfoCardList from '../../../components/InfoCard/InfoCardList';
import IconButton from '../../../components/IconButton/IconButton';
import AddMenuItemsDialog from './AddMenuItemsDialog';
import ScheduleFormControl from '../shared/ScheduleFormControl';
import { ILink } from '../../redux/MenusState';
import DuplicateMenuItemModal from './DuplicateMenuItemModal';
import { DeleteConfirmationModal } from '../../../components/Modal';
import SelectPriceLevel from './SelectPriceLevel';
import Notification from '../../../components/Notification/Notification';
import ReenableItemDialog from './ReenableItemDialog';
import getBackLinkForMenuItem from '../../utils/getBackLinkForMenuItem';
import MenuItemTagsControl from './MenuItemTagsControl';
import Divider from '../../../components/Divider/Divider';
import { EffectiveDates } from './MenuItemDetails/components/EffectiveDates';
import { LinkedPosItem } from './MenuItemDetails/components/LinkedPosItem';
import { StockStatus } from './MenuItemDetails/components/StockStatus';
import { BannerAndThumbnailImagesFormControls } from './BannerAndThumbnailImage/BannerAndThumbnailImagesFormControls';
import { IAsset } from '@ready/dashboardv2api.contracts';
import { ForcedModifiersControl } from './MenuItemDetails/components/ForcedModifiers/ForcedModifiersControl';
import ModifierGroupOptionsListItem from '../ModifierGroups/ModifierGroupOptionsListItem';
import NoneValue from 'components/Value/NoneValue';
import StatusIndicator from 'components/StatusIndicator/StatusIndicator';
import BadgePreview from 'tagsBadges/components/BadgePreview';
import { ReadonlyValueWrapper } from 'components/Value/ReadonlyValueWrapper';
import { ScheduleValue } from 'components/Value/ScheduleValue';
import { compositeStyles } from 'utils/cssUtils';
import MissingValue from 'components/Value/MissingValue';
import { DollarValue, TextValue } from 'components/Value';
import { isReadySmb } from '../../../utils/smb.utils';
import { getTaxes } from '../../../companySettings/redux/CompanySettingsActions';
import { TaxesState } from '../../../companySettings/redux/CompanySettingsState';
import { selectTaxesState } from '../../../companySettings/redux/CompanySettingsSelectors';
import { ISmbTax } from '../../../companySettings/types/SmbTaxTypes.type';

const getMoneyDisplayValue = (value?: number) => value && Math.abs(value) / 100;

const getTaxDisplayValue = (tax: ISmbTax) => `${tax.rate}%`;

const mapTaxesToOptions = (taxes: ISmbTax[] = []): Option<string>[] =>
  taxes.map((t) => ({
    value: t._id,
    label: `${t.name} - ${getTaxDisplayValue(t)}`,
  }));

const MenuItemDetailPage = (): JSX.Element | null => {
  const { permissionsList: permissions } = useSelector<RootState, IPermissions>(selectPermissions);
  const { isDirty: actionBarVisible } = useSelector<RootState, IFormState>(selectFormState);
  const { modalVisible: isSelectingModifierGroups } = useSelector<RootState, IModifierGroupSelection>(
    selectModifierGroupSelectionState
  );
  const { schedules, loading: areSchedulesLoading } = useSelector<RootState, ScheduleListState>(
    selectScheduleListState
  );
  const { location } = useSelector<RootState, ILocationsState>(selectLocationsState);
  const menuItemCache = useSelector<RootState, IMenuItem>(selectMenuItemCacheState);
  const { modalVisible: isSelectingPosItems, type: posItemSelectionType } = useSelector<RootState, IPosItemSelection>(
    selectPosItemSelectionState
  );
  const { modalVisible: isSelectingMenuItems } = useSelector<RootState, IMenuItemSelection>(
    selectMenuItemSelectionState
  );
  const {
    view: backView,
    stockStatus: backStockStatus,
    query: backQuery,
    page: backPage,
    deleting,
    deleted,
  } = useSelector<RootState, IItemsAndModsState>(selectItemsAndModsState);
  const {
    loading,
    processing,
    cancelled,
    newId,
    menuItem,
    menuItem: {
      _id,
      itemType,
      displayName,
      description,
      imageId,
      thumbnailImageId,
      posItemId,
      posItemName,
      posItemCategories,
      inStock,
      alcohol,
      activePriceLevel = '',
      priceLevels = [],
      price,
      status,
      inactiveStates,
      isPopular = false,
      recommendedItems,
      modifierGroups,
      schedule,
      forcedModifiers,
      tags,
      badge,
      effectiveDates,
      forcedModifierStatus,
      parentTemplateId,
    },
    validation,
    duplicating,
    taxes,
  } = useSelector<RootState, IMenuItemForm>(selectMenuItemFormState);
  const menuItemId = _id;
  const { loading: loadingBadges, badges } = useSelector<RootState, BadgesListState>(selectBadgesListState);
  const { loading: loadingTaxes, taxes: availableTaxes } = useSelector<RootState, TaxesState>(selectTaxesState);

  const dispatch = useDispatch();
  const {
    contextId: companyId,
    locationId,
    menuConfigId,
    menuId,
    itemGroupId,
    modGroupId,
    id,
    forcedModId,
  } = useParams<MenuContextParams>();
  const history = useHistory();

  const forcedModifiersAllowed = itemType === 'item';

  const hasManagePermission = Verifier.check(
    new PrincipalPermissions(permissions),
    SecurityScope.location,
    ResourceType.menu,
    MenuResourceActions.all,
    locationId
  );

  const [imageUrls, setImageUrls] = React.useState<{ [id: string]: string }>({});
  const getS3ImagesMap = useS3ImagesMap();

  const [deleteRequested, setDeleteRequested] = React.useState(false);
  const [reEnableItemRequested, setReEnableItemRequested] = React.useState(false);

  const priceError = inactiveStates.includes(MenuItemInactiveStates.priceDeletedOnPOS);
  const priceErrorMessage = priceError ? 'Select a new price level.' : undefined;

  const backLinkTo = getBackLinkForMenuItem({
    companyId,
    locationId,
    menuConfigId,
    menuId,
    itemGroupId,
    menuItemId: id,
    forcedModId,
    modGroupId,
    view: backView,
    page: backPage,
    query: backQuery,
    stockStatus: backStockStatus,
  });

  const isNewItem = id === 'new-item';
  const isNewMod = id === 'new-mod';
  const title = isNewItem ? 'New Menu Item' : isNewMod ? 'New Modifier' : menuItemCache.displayName;

  const formSaveButtonText = isNewItem ? 'Create Item' : isNewMod ? 'Create Modifier' : undefined;

  const isInDraftState = inactiveStates.includes(MenuItemInactiveStates.draft);
  const isInPosDeletedState = inactiveStates.includes(MenuItemInactiveStates.itemDeletedOnPOS);

  const showForcedModInvalidBanner = forcedModifiersAllowed && forcedModifierStatus === ForcedModifierStatus.invalid;

  const startAddingModifierGroups = (): void => {
    dispatch(prepareModifierGroupsModal(true, modifierGroups));
  };

  const modifiersOverflowOptions = [
    {
      label: '+ Add Modifier Group',
      onClick: startAddingModifierGroups,
    },
  ];

  const pairingsOverflowOptions = [
    {
      label: '+ Add Items',
      onClick: () =>
        dispatch(
          prepareMenuItemsModal(
            true,
            recommendedItems.map((pairing) => pairing.itemId),
            MenuItemsView.ITEMS
          )
        ),
    },
  ];

  // configuration from the location
  const readOnlyStock = !location?.settings.allowManualStockUpdates;
  const timeZone = location?.timezone;

  const handleChangeEffectiveStartDate = (start?: string) => {
    dispatch(updateMenuItemEffectiveStartDate(start));
  };

  const handleChangeEffectiveEndDate = (end?: string) => {
    dispatch(updateMenuItemEffectiveEndDate(end));
  };

  const handleShowDuplicateItemModal = (visible: boolean): void => {
    dispatch(showDuplicateItemModal(visible));
  };

  const handleDuplicate = (displayName: string): void => {
    dispatch(duplicateMenuItem(companyId, location!, menuItem, displayName));
  };

  const handleDelete = (): void => {
    dispatch(deleteMenuItem(companyId, locationId, _id, itemType));
  };

  const handleLinkPosItemClick = (): void => {
    dispatch(preparePosItemsModal(true, itemType === 'item' ? 'item' : 'mod', posItemId));
  };

  const handleUpdateMenuItemInStock = (inStock: boolean): void => {
    dispatch(updateMenuItemInStock(inStock));
  };

  const handleSelectTaxes = (data: any): void => {
    const typedOptions = (data as Option<string>[]) ?? [];
    const selectedTaxes = availableTaxes.filter((t) => typedOptions.some((st) => st.value === t._id));
    dispatch(selectTaxes(selectedTaxes));
  };

  const handleFormSubmit = async () => {
    if (menuItem) {
      if (isReadySmb(location?.posSystemType)) {
        await dispatch(saveSmbMenuItem(companyId, location!, { ...menuItem, taxes }, readOnlyStock));
      } else {
        await dispatch(saveMenuItem(companyId, location!, menuItem, readOnlyStock));
      }
    }
  };

  const handleResetForm = (): void => {
    if (isNewItem || isNewMod) {
      dispatch(cancelNewItem());
    } else {
      dispatch(resetMenuItem(menuItemCache));
    }
  };

  const handleAddModifierGroup = React.useCallback(
    (modifierGroup: IModifierGroup) => {
      dispatch(selectModifierGroupForMenuItem(modifierGroup));
    },
    [dispatch]
  );

  const handleRemoveModifierGroup = React.useCallback(
    (modifierGroupId: string) => {
      dispatch(deselectModifierGroupFromMenuItem(modifierGroupId));
    },
    [dispatch]
  );

  const handleLinkPosItem = React.useCallback(
    (item: ICachedPosMenuItem | ICachedPosModifier) => {
      dispatch(selectPosItemInPosItemsModal(item));
    },
    [dispatch]
  );

  const handleAddMenuItemPairing = React.useCallback(
    (menuItem: IMenuItem) => {
      dispatch(selectMenuItemForMenuItemPairing(menuItem));
    },
    [dispatch]
  );

  const handleRemoveMenuItemPairing = React.useCallback(
    (menuItemId: string) => {
      dispatch(deselectMenuItemFromMenuItemPairing(menuItemId));
    },
    [dispatch]
  );

  const handleUpdateMenuItemPrice = (price: string) => {
    const numericValue = Number.parseFloat(price) * 100;
    dispatch(updateMenuItemPrice(numericValue));
  };

  const fetchLocation = React.useCallback(
    (companyId: string, locationId: string) => {
      if (!location || location.id !== locationId) {
        dispatch(LocationsThunks.loadSelectedLocation({ companyId, locationId }));
      }
    },
    [location, dispatch]
  );

  const fetchData = React.useCallback(() => {
    if (isNewItem) {
      dispatch(loadNewMenuItem(isReadySmb(location?.posSystemType)));
    } else if (isNewMod) {
      dispatch(loadNewModifier(isReadySmb(location?.posSystemType)));
    }
  }, [dispatch, isNewItem, isNewMod, location?.posSystemType]);

  const fetchImages = React.useCallback(
    async (items: IEmbeddedMenuItem[]) => {
      const imageRequests: IImageS3Request[] = items
        .filter((item) => item.thumbnailImageId)
        .map((item) => ({
          id: item.itemId,
          fileKey: item.thumbnailImageId!,
        }));
      const s3ImageUrls = await getS3ImagesMap(imageRequests);
      setImageUrls(s3ImageUrls);
    },
    [getS3ImagesMap]
  );

  React.useEffect(() => {
    fetchLocation(companyId, locationId);
  }, [fetchLocation, companyId, locationId]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  React.useEffect(() => {
    const abortController = new AbortController();
    if (!isNewItem && !isNewMod) {
      if (isReadySmb(location?.posSystemType)) {
        dispatch(loadSmbMenuItem(companyId, locationId, id, abortController));
      } else {
        dispatch(loadMenuItem(companyId, locationId, id, abortController));
      }
    }
    return () => abortController.abort();
  }, [companyId, dispatch, id, isNewItem, isNewMod, location?.posSystemType, locationId]);

  React.useEffect(() => {
    if (newId) {
      history.push(Routes.getMenuItemPage(companyId, locationId, newId));
    }
  }, [history, companyId, locationId, newId]);

  React.useEffect(() => {
    if ((isNewItem || isNewMod) && cancelled) {
      history.push(backLinkTo);
    }
  }, [isNewItem, isNewMod, cancelled, history, backLinkTo]);

  React.useEffect(() => {
    if (recommendedItems.length > 0) {
      fetchImages(recommendedItems);
    }
  }, [fetchImages, recommendedItems]);

  React.useEffect(() => {
    return () => {
      dispatch(clearMenuItem());
    };
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getBadges(companyId, locationId));
  }, [dispatch, companyId, locationId]);

  React.useEffect(() => {
    if (isReadySmb(location?.posSystemType)) {
      dispatch(getTaxes(companyId));
    }
  }, [dispatch, companyId, location?.posSystemType]);

  // this useEffect is to trigger action form buttons on page load
  React.useEffect(() => {
    if (loadingBadges && (isNewItem || isNewMod)) {
      dispatch(setFormIsDirty(true));
    }
  }, [loadingBadges, isNewItem, isNewMod, dispatch]);

  React.useEffect(() => {
    if (deleted) {
      history.push(backLinkTo);
    }
  }, [deleted, history, backLinkTo]);

  React.useEffect(() => {
    if (reEnableItemRequested) {
      // Close modal after re-enabling
      setReEnableItemRequested(false);
    }
  }, [reEnableItemRequested, inactiveStates]);

  const badgesOptions: Option[] =
    badges?.map((b) => ({
      value: b._id,
      label: b.name,
    })) || [];
  badgesOptions.unshift({
    value: '',
    label: 'None',
  });

  const handleUpdateBadge = (badgeId: string) => {
    const selectedBadge = badges.find((b) => b._id === badgeId);
    dispatch(updateMenuItemBadge(selectedBadge));
  };

  const mapBadgeOption = (badge?: IBadge): Option | null => {
    if (badge) {
      return {
        label: badge.name,
        value: badge._id,
      };
    }
    return null;
  };

  const uploadBannerImage = (asset: Partial<IAsset>, imageEl?: HTMLImageElement) => {
    dispatch(updateMenuItemImage(asset, imageEl));
  };
  const uploadThumbnailImage = (asset: Partial<IAsset>, imageEl?: HTMLImageElement) => {
    dispatch(updateMenuItemThumbnailImage(asset, imageEl));
  };

  const { modalVisible: isSelectingForcedMod } = useSelector<RootState, IMenuItemSelection>(
    selectForcedModSelectionState
  );

  const selectedForcedMods = forcedModifiers.map((fm: IForcedModifier) => fm.itemId);

  const handleAddOrEditForcedModClick = (): void => {
    dispatch(prepareForcedModiferModal(true, selectedForcedMods));
  };
  const handleAddReadyForcedModifier = React.useCallback(
    (menuItem: IMenuItem) => {
      dispatch(
        addReadyForcedModifierToMenuItem({
          ...menuItem,
          itemId: menuItem._id,
        })
      );
    },
    [dispatch]
  );

  const handleRemoveReadyForcedModifier = React.useCallback(
    (menuItemId: string) => {
      dispatch(removeReadyForcedModifierToMenuItem(menuItemId));
    },
    [dispatch]
  );

  const hasOptionsWithNestedMods = (modGroupOptions: IEmbeddedModifierItem[]): boolean => {
    return modGroupOptions.filter((option) => !!option.nestedGroups && !!option.nestedGroups.length).length > 0;
  };

  const isReadonly = parentTemplateId !== undefined;

  return (
    <>
      {!(isNewItem || isNewMod) && duplicating && (
        <DuplicateMenuItemModal
          processing={processing}
          setVisible={handleShowDuplicateItemModal}
          duplicateMenuItem={handleDuplicate}
          cancelChanges={handleShowDuplicateItemModal}
          headerLabel={itemType === 'item' ? 'Item' : 'Modifier'}
          placeholder={itemType === 'item' ? 'e.g. Club Sandwich' : 'e.g. Extra Cheese'}
        />
      )}

      {!(isNewItem || isNewMod) && deleteRequested && (
        <DeleteConfirmationModal
          setShowModal={setDeleteRequested}
          item={itemType === 'item' ? 'Menu Item' : 'Modifier'}
          itemName={displayName}
          loading={deleting}
          handleDelete={handleDelete}
        />
      )}

      {!(isNewItem || isNewMod) && reEnableItemRequested && (
        <ReenableItemDialog
          processing={processing}
          completed={false}
          setShowModal={setReEnableItemRequested}
          onReenableItemClick={() => dispatch(reenableMenuItem(companyId, location!, menuItem))}
        />
      )}

      <FormActionBar
        actionBarVisible={actionBarVisible}
        saveLabel={formSaveButtonText}
        handleFormSubmit={handleFormSubmit}
        resetForm={handleResetForm}
      />

      <MenuBuilderLayoutContent
        companyId={companyId}
        locationId={locationId}
        locationName={location && location.id === locationId ? location.name : null}
        stockStatusOnly={!hasManagePermission}
        tab={
          menuId
            ? MenuBuilderTab.MENUS
            : itemGroupId
            ? MenuBuilderTab.ITEM_GROUPS
            : modGroupId
            ? MenuBuilderTab.MOD_GROUPS
            : MenuBuilderTab.ITEMS_AND_MODS
        }
      >
        {loading || loadingBadges ? (
          <LoadingSpinner />
        ) : (
          <>
            <ActionHeader
              text={title}
              afterTextSlot={isReadonly && <TextIcon icon={Icon.Share} additionalStyles={styles.sharedItemIcon} />}
              backLinkTo={!(isNewItem || isNewMod) ? backLinkTo : undefined}
              actionButtons={
                isNewItem || isNewMod || isReadonly
                  ? []
                  : [
                      {
                        label: 'Duplicate',
                        variant: 'secondary-gray-bg',
                        onClick: () => dispatch(showDuplicateItemModal(true)),
                      },
                      {
                        label: 'Delete',
                        variant: 'secondary-gray-bg',
                        onClick: () => setDeleteRequested(true),
                      },
                    ]
              }
            />
            {showForcedModInvalidBanner && (
              <div className={styles.forcedModBannerContainer}>
                <Notification alignment='vertical'>
                  <p>
                    <i className={Icon.Alert} />
                    <span className={styles.text}>
                      We noticed some errors with this item's forced modifiers, so we've hidden the item from your menu.
                      It will be visible again as soon as the errors are resolved.
                    </span>
                  </p>
                </Notification>
              </div>
            )}
            <PanelLayout columns={2} flexWidths={[66, 34]} stackOnTablet>
              <Panel>
                <div>
                  <Form hasGroups onSubmit={() => {}}>
                    <FormControl label='Status'>
                      <div className={styles.statusField}>
                        <ReadonlyValueWrapper
                          readonly={isReadonly && !hasManagePermission}
                          value={
                            <StatusIndicator
                              active={status === Status.enabled}
                              activeLabel='Enabled'
                              inactiveLabel='Disabled'
                            />
                          }
                        >
                          <Toggle
                            checked={status === Status.enabled}
                            disabled={isInDraftState && !isReadySmb(location?.posSystemType)}
                            onChange={(checked: boolean) => {
                              dispatch(updateMenuItemStatus(checked));
                            }}
                          />
                          {isInDraftState && !isReadySmb(location?.posSystemType) && (
                            <span className={styles.statusMessage}>
                              You can update this status once a POS item is linked to this product.
                            </span>
                          )}
                        </ReadonlyValueWrapper>
                      </div>
                    </FormControl>

                    <FormControl
                      label='Display Name *'
                      errorMessage={validation.displayName.errorMessage}
                      withError={validation.displayName.hasError}
                    >
                      <ReadonlyValueWrapper value={displayName} readonly={isReadonly}>
                        <TextInput
                          placeholder='Enter a display name'
                          value={displayName ?? ''}
                          withError={validation.displayName.hasError}
                          loading={processing}
                          maxLength={100}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            dispatch(updateMenuItemDisplayName(e.target.value));
                          }}
                        />
                      </ReadonlyValueWrapper>
                    </FormControl>

                    <FormControl label='Description'>
                      <ReadonlyValueWrapper
                        value={<TextValue value={description || ''} mode='none' />}
                        readonly={isReadonly}
                      >
                        <TextArea
                          placeholder='Enter a description'
                          value={description ?? ''}
                          loading={processing}
                          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                            dispatch(updateMenuItemDescription(e.target.value));
                          }}
                        />
                      </ReadonlyValueWrapper>
                    </FormControl>

                    <EffectiveDates
                      readonly={isReadonly && !hasManagePermission}
                      effectiveDates={effectiveDates}
                      timeZone={timeZone}
                      withError={validation.effectiveStartDate.hasError}
                      errorMessage={validation.effectiveStartDate.errorMessage}
                      onChangeStartDate={handleChangeEffectiveStartDate}
                      onChangeEndDate={handleChangeEffectiveEndDate}
                    />

                    {itemType === 'item' && (
                      <FormControl icon={Icon.HeartOutline} label='Popular Item'>
                        <Checkbox
                          label='Add to popular item list'
                          checked={isPopular}
                          loading={processing}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            dispatch(updateMenuItemIsPopular(e.target.checked));
                          }}
                          readonly={isReadonly && !hasManagePermission}
                        />
                      </FormControl>
                    )}

                    {itemType === 'item' && (
                      <FormControl icon={Icon.Clock} label='Schedule'>
                        <ReadonlyValueWrapper
                          readonly={isReadonly && !hasManagePermission}
                          value={
                            <ScheduleValue
                              schedule={schedule}
                              onScheduleLinkClick={(id) => {
                                history.push(Routes.getScheduleRouteFromMenuItem(companyId, locationId, _id, id));
                              }}
                            />
                          }
                        >
                          <ScheduleFormControl
                            opened={true}
                            schedules={schedules}
                            scheduleLink={schedule}
                            validation={validation}
                            loading={processing}
                            onChange={(scheduler: IScheduleLink<ILink> | null) => {
                              dispatch(updateMenuItemScheduler(scheduler));
                            }}
                            areSchedulesLoading={areSchedulesLoading}
                          />
                        </ReadonlyValueWrapper>
                      </FormControl>
                    )}

                    <FormControl icon={Icon.Martini} label='Alcohol Tracking'>
                      <div className={compositeStyles([styles.alcoholControl, { readonly: isReadonly }], styles)}>
                        <Checkbox
                          label='Contains Alcohol'
                          checked={!!alcohol && alcohol.containsAlcohol}
                          loading={processing}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            dispatch(updateMenuItemContainsAlcohol(e.target.checked));
                          }}
                          readonly={isReadonly}
                        />
                        {isReadonly && alcohol && alcohol.quantity?.value ? (
                          <div className={styles.readonlyAlcohol}>
                            <div className={styles.value}>{alcohol.quantity?.value}</div>
                            <div className='control--stacked'>
                              <span className={styles.note}>Measured in # of standard drinks</span>
                            </div>
                          </div>
                        ) : undefined}
                      </div>
                    </FormControl>

                    {!isReadonly && alcohol && alcohol.containsAlcohol && (
                      <FormControl>
                        <div className='control--stacked'>
                          <div className={styles.small}>
                            <FormattedInput
                              format='number'
                              decimalScale={2}
                              value={alcohol && alcohol.quantity ? alcohol.quantity.value : ''}
                              placeholder='e.g. 1.5'
                              loading={processing}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                dispatch(updateMenuItemAlcoholAmount(e.target.value));
                              }}
                            />
                          </div>
                        </div>
                        <div className='control--stacked'>
                          <span className={styles.note}>Measured in # of standard drinks</span>
                        </div>
                      </FormControl>
                    )}

                    {itemType === 'item' && (
                      <>
                        <FormControl label='Tags'>
                          <MenuItemTagsControl
                            tags={tags}
                            companyId={companyId}
                            locationId={locationId}
                            addTag={(item: ITag) => dispatch(updateMenuItemAddTag(item))}
                            removeTag={(tagId: string) => dispatch(updateMenuItemRemoveTag(tagId))}
                            readonly={isReadonly}
                          />
                        </FormControl>

                        <FormControl
                          label='Badge'
                          labelTooltip='Use badges to draw attention to specific menu items (e.g. New, Popular). You can create and edit badges on the Tags & Badges page.'
                        >
                          <ReadonlyValueWrapper
                            readonly={isReadonly}
                            value={
                              badge ? (
                                <BadgePreview
                                  backgroundColour={badge.backgroundColor}
                                  textColour={badge.textColor}
                                  badgeText={badge.text}
                                />
                              ) : (
                                <NoneValue />
                              )
                            }
                          >
                            <SelectFilter
                              options={badgesOptions}
                              value={mapBadgeOption(badge)}
                              placeholder='None'
                              onChange={(data) => handleUpdateBadge(data.value as string)}
                            />
                          </ReadonlyValueWrapper>
                        </FormControl>
                      </>
                    )}

                    {itemType === 'item' && (
                      <BannerAndThumbnailImagesFormControls
                        bannerImageId={imageId}
                        bannerImageLoading={processing}
                        uploadBannerImage={uploadBannerImage}
                        thumbnailImageId={thumbnailImageId}
                        thumbnailImageLoading={processing}
                        uploadThumbnailImage={uploadThumbnailImage}
                        readonly={isReadonly}
                      />
                    )}
                  </Form>
                </div>
              </Panel>

              <Panel>
                {isReadySmb(location?.posSystemType) ? (
                  <>
                    <span className={styles.posMappingHeader}>Price & Stock</span>
                    <Form hasGroups useMobileStyling>
                      <div className={styles.priceInput}>
                        <FormControl
                          label='Price *'
                          withError={validation.price?.hasError}
                          errorMessage={validation.price?.errorMessage}
                        >
                          <FormattedInput
                            format='money'
                            value={getMoneyDisplayValue(menuItem.price)}
                            onChange={(event) => handleUpdateMenuItemPrice(event.currentTarget.value)}
                            withError={validation.price?.hasError}
                          />
                        </FormControl>
                      </div>

                      <FormControl label='Stock'>
                        <StockStatus
                          inStock={inStock}
                          readOnlyStock={readOnlyStock}
                          isNotApplicable={false}
                          processing={processing}
                          updateMenuItemInStock={handleUpdateMenuItemInStock}
                        />
                      </FormControl>

                      {menuItem.itemType === 'item' && (
                        <FormControl label='Taxes'>
                          <SelectFilter
                            options={mapTaxesToOptions(availableTaxes)}
                            isClearable
                            isMulti
                            isSearchable
                            onChange={handleSelectTaxes}
                            value={mapTaxesToOptions(taxes)}
                            loading={loadingTaxes}
                          />
                        </FormControl>
                      )}
                    </Form>
                  </>
                ) : (
                  <>
                    <span className={styles.posMappingHeader}>POS Mapping</span>
                    <Form hasGroups useMobileStyling>
                      <FormControl label='Linked POS Item' additionalStyles={styles.pos}>
                        <LinkedPosItem
                          isInDraftState={isInDraftState}
                          isInPosDeletedState={isInPosDeletedState}
                          handleLinkPosItemClick={handleLinkPosItemClick}
                          posItemId={posItemId}
                          posItemName={posItemName}
                          posItemCategories={posItemCategories ?? []}
                          readonly={isReadonly && !hasManagePermission}
                        />
                      </FormControl>

                      <FormControl label='Price' errorMessage={priceErrorMessage} withError={priceError}>
                        <div className={styles.small}>
                          <ReadonlyValueWrapper
                            readonly={isReadonly && !hasManagePermission}
                            value={
                              price ? (
                                <div className={styles.readonlyPriceContainer}>
                                  <DollarValue value={price} additionalStyles={styles.readonlyPrice} />
                                </div>
                              ) : (
                                <MissingValue />
                              )
                            }
                          >
                            <SelectPriceLevel
                              placeholder={
                                isInDraftState || isInPosDeletedState || menuItem.posItemId === undefined
                                  ? '—'
                                  : 'Choose'
                              }
                              activePriceLevel={activePriceLevel}
                              priceLevels={priceLevels}
                              price={price}
                              processing={processing}
                              disabled={isInDraftState || isInPosDeletedState || menuItem.posItemId === undefined}
                              withError={priceError}
                              onChange={(option: Option) => {
                                dispatch(updateMenuItemActivePriceLevel(`${option.value}`));
                              }}
                            />
                          </ReadonlyValueWrapper>
                        </div>
                      </FormControl>

                      <FormControl label='Stock Status' hideFormLabel additionalStyles={styles.stockStatus}>
                        <StockStatus
                          inStock={inStock}
                          isNotApplicable={isInDraftState || isInPosDeletedState || menuItem.posItemId === undefined}
                          readOnlyStock={readOnlyStock}
                          processing={processing}
                          updateMenuItemInStock={handleUpdateMenuItemInStock}
                        />
                      </FormControl>

                      {forcedModifiersAllowed && itemType === 'item' && (
                        <>
                          <Divider />
                          <ForcedModifiersControl
                            forcedModifiers={forcedModifiers}
                            onAddOrEditClick={handleAddOrEditForcedModClick}
                            readonly={isReadonly}
                          />
                        </>
                      )}
                    </Form>
                  </>
                )}
              </Panel>
            </PanelLayout>

            {itemType === 'item' ? (
              <>
                <div className={styles.modifiersHeader}>
                  <div className={styles.modifiersHeaderText}>Modifiers</div>
                  {!isReadonly && (
                    <div className={styles.modifiersHeaderControls}>
                      <Button label='+ Add Modifier Group' onClick={startAddingModifierGroups} />
                    </div>
                  )}

                  <div className={styles.modifiersHeaderOverflow}>
                    <OverflowButton options={modifiersOverflowOptions} />
                  </div>
                </div>

                <DragAndDrop
                  dropAreaClassName={styles.modifiers}
                  dropAreaDraggingClassName={styles.modifiersDragging}
                  dragItemClassName={styles.modifier}
                  dragHandleClassName={styles.dragHandle}
                  dragItems={modifierGroups}
                  draggingDisabled={isReadonly}
                  emptyComponent={
                    <EmptyResult
                      title='Add Item Modifiers'
                      paragraph='Add modifier groups to this item so your guests can customize their order.'
                    />
                  }
                  dragHandleProvided
                  dragItemKeyExtractor={(modifierGroup: IModifierGroup) => modifierGroup._id}
                  dragItemComponentBuilder={(
                    modifierGroup: IModifierGroup,
                    dragHandle?: JSX.Element,
                    isDragging?: boolean
                  ) => (
                    <ModifierGroupCard
                      modifierGroup={modifierGroup}
                      linkTo={Routes.getModifierGroupPage(companyId, locationId, modifierGroup._id)}
                      isDragging={isDragging}
                      dragHandle={dragHandle}
                      onRemove={() => dispatch(removeModifierGroupFromMenuItem(modifierGroup._id))}
                      readonly={isReadonly}
                      optionsListElementComponent={modifierGroup.options.map((option) => (
                        <ModifierGroupOptionsListItem
                          key={option.itemId}
                          modifierOption={option}
                          companyId={companyId}
                          locationId={locationId}
                          modifierGroupId={modifierGroup._id}
                          defaultMaxValue={modifierGroup.constraints.max || null}
                          allowMultiplesOfEachOption={!!modifierGroup.allowMultiplesOfEachOption}
                          allowNestedMods={true}
                          allowExpansion={hasOptionsWithNestedMods(modifierGroup.options)}
                          processing={false}
                          menuItemId={menuItemId}
                          compactView={true}
                          isReadonly={isReadonly}
                        />
                      ))}
                    />
                  )}
                  onDragItemDropped={(oldIndex: number, newIndex: number) =>
                    dispatch(moveModifierGroupInMenuItem(oldIndex, newIndex))
                  }
                />

                <div className={styles.pairingsHeader}>
                  <div className={styles.pairingsHeaderText}>Recommended Pairings</div>
                  {!isReadonly && (
                    <div className={styles.pairingsHeaderControls}>
                      <Button label={pairingsOverflowOptions[0].label} onClick={pairingsOverflowOptions[0].onClick} />
                    </div>
                  )}
                  <div className={styles.pairingsHeaderOverflow}>
                    <OverflowButton options={pairingsOverflowOptions} />
                  </div>
                </div>

                <div className={styles.pairings}>
                  {recommendedItems.length === 0 ? (
                    <EmptyResult
                      title='Add Items for Recommendation'
                      paragraph='Recommended pairings let you suggest to your guests other items they might like to try in addition to this menu item.'
                    />
                  ) : (
                    <InfoCardList>
                      {recommendedItems.map((item) => (
                        <MenuItemCard
                          key={item.itemId}
                          menuItem={item}
                          linkTo={Routes.getMenuItemPage(companyId, locationId, item.itemId)}
                          imageUrl={imageUrls[item.itemId]}
                          controls={
                            isReadonly ? undefined : (
                              <IconButton onClick={() => dispatch(removeMenuItemFromMenuItemPairing(item.itemId))}>
                                <TextIcon icon={Icon.RemoveCircle} additionalStyles={styles.button} />
                              </IconButton>
                            )
                          }
                          additionalClassName={styles.item}
                        />
                      ))}
                    </InfoCardList>
                  )}
                </div>
              </>
            ) : null}
          </>
        )}
      </MenuBuilderLayoutContent>

      <AddModifierGroupDialog
        visible={isSelectingModifierGroups}
        companyId={companyId}
        locationId={locationId}
        addModifierGroup={handleAddModifierGroup}
        removeModifierGroup={handleRemoveModifierGroup}
      />

      <LinkPosItemDialog
        visible={isSelectingPosItems}
        companyId={companyId}
        locationId={locationId}
        title={posItemSelectionType === 'mod' ? 'Select a Modifier' : undefined}
        selectPosItem={handleLinkPosItem}
      />

      {forcedModifiersAllowed && (
        <AddMenuItemsDialog
          visible={isSelectingForcedMod}
          companyId={companyId}
          locationId={locationId}
          title='Add Forced Modifiers'
          addMenuItemToSelection={handleAddReadyForcedModifier}
          removeMenuItemFromSelection={handleRemoveReadyForcedModifier}
          prepareModalDispatch={(show: boolean) => dispatch(prepareForcedModiferModal(show))}
          selectedItemIds={selectedForcedMods}
        />
      )}

      <AddMenuItemsDialog
        visible={isSelectingMenuItems}
        companyId={companyId}
        locationId={locationId}
        title='Add Menu Items'
        addMenuItemToSelection={handleAddMenuItemPairing}
        removeMenuItemFromSelection={handleRemoveMenuItemPairing}
      />
    </>
  );
};

export default MenuItemDetailPage;
