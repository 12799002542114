import { IPayoutHistoryDetailsReportState } from './types';
import { IPayoutHistoryDetailItem, PaginationResponse } from '@ready/dashboardv2api.contracts';
import { IPagedResponse } from '@ready/table.core';
import { ILocation } from '../../../services/types/ILocation.type';

export const fetchPayoutHistoryDetailsReportReducers = {
  pending: (state: IPayoutHistoryDetailsReportState) => {
    state.loading.list = true;
  },
  fulfilled: (
    state: IPayoutHistoryDetailsReportState,
    action: { payload: PaginationResponse<IPayoutHistoryDetailItem> }
  ) => {
    const { items, total, limit, start, length } = action.payload;
    state.loading.list = false;

    state.items = items;
    state.pagination = { total, limit, start, length };
  },
  rejected: (state: IPayoutHistoryDetailsReportState) => {
    state.loading.list = false;
    state.errors.list = 'Unable to retrieve Promo Code Summary list.';
    state.items = [];
  },
};

export const fetchPermittedLocationsReducers = {
  pending: (state: IPayoutHistoryDetailsReportState) => {
    state.loading.permittedLocations = true;
  },
  fulfilled: (state: IPayoutHistoryDetailsReportState, action: { payload: IPagedResponse<ILocation> }) => {
    state.loading.permittedLocations = false;
    state.permittedLocations = action.payload;
  },
  rejected: (state: IPayoutHistoryDetailsReportState) => {
    state.loading.permittedLocations = false;
  },
};
