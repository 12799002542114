/** Show `value` if readonly. Otherwise, show child. */

import React, { FC, ReactNode } from 'react';

interface ReadonlyValueWrapperProps {
  readonly: boolean;
  value: ReactNode;
}

export const ReadonlyValueWrapper: FC<ReadonlyValueWrapperProps> = ({ readonly, value, children }) => {
  return <>{readonly ? value : children}</>;
};
