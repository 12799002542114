import { IPagination } from '@ready/dashboardv2api.contracts';
import { ITemplateModifierGroup } from '@ready/menu.core';
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { getSharedModifierGroupsThunk } from './actions';

interface State {
  modifierGroups: ITemplateModifierGroup[];
  pagination?: IPagination;
  loading: boolean;
}

const initialState: State = {
  loading: true,
  modifierGroups: [],
};

const modifierGroupListSlice = createSlice({
  name: 'sharedMenuItems/modifierGroups/list',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<State>) => {
    builder.addCase(getSharedModifierGroupsThunk.pending, (state: State) => {
      state.loading = true;
    });

    builder.addCase(getSharedModifierGroupsThunk.fulfilled, (state: State, action) => {
      state.loading = false;
      const { items, ...pagination } = action.payload;
      state.modifierGroups = items;
      state.pagination = pagination;
    });
  },
});

export { modifierGroupListSlice };
