export const serviceFactorsPanel = {
  maxFactors: 0,
  serviceFactors: {
    mobilePayment: false,
    atmosphere: false,
    food: false,
    music: false,
    service: false,
    cleanliness: false,
    waitTime: false,
  },
};
