import produce from 'immer';
import { ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS } from '../actions/onAccountPaymentOptionsActions';
import {
  onAccountPaymentOptionsState,
  buildPaymentValidationField,
  IPaymentValidationField,
  buildOnAccountPaymentOptionsEmptyForm,
  buildOnAccountPaymentOptionsEmptyView,
} from '../initialStates/onAccountPaymentOptions';
import { Action } from '../../../redux/types';
import { IValidationField } from '@ready/dashboardv2api.contracts';

export const onAccountPaymentOptionsReducer = (state = onAccountPaymentOptionsState, action: Action) => {
  return produce(state, (draft) => {
    const validationFieldIndex = action.payload?.validationFieldInternalId
      ? draft.form.fields.paymentValidationFields.findIndex(
          (f) => f.internalId === action.payload.validationFieldInternalId
        )
      : -1;

    switch (action.type) {
      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.LOAD_ON_ACCOUNT_PAYMENT_OPTIONS_BEGIN:
        draft.list.onAccountPaymentOptions = [];
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.LOAD_ON_ACCOUNT_PAYMENT_OPTIONS_SUCCESS:
        draft.list.initialized = true;
        draft.list.onAccountPaymentOptions = action.payload;
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.LOAD_ON_ACCOUNT_PAYMENT_OPTIONS_ERROR:
        draft.list.initialized = true;
        return draft;
      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.SET_ON_ACCOUNT_PAYMENT_OPTIONS_EDIT_MODE_FORM:
        const { status, description, shortCode, paymentOptionName, posTenderType } = draft.form.fields;
        const paymentValidationFieldsMapper: IPaymentValidationField[] = draft.view.config.validationFields.map(
          (field: IValidationField) => {
            return buildPaymentValidationField(field);
          }
        );

        draft.form.paymentOptionId = draft.view.config._id;
        draft.form.fields.paymentValidationFields = paymentValidationFieldsMapper;
        status.value = draft.view.config.enabled;
        description.value = draft.view.config.description;
        shortCode.value = draft.view.config.code;
        paymentOptionName.value = draft.view.config.name;
        posTenderType.value = draft.view.config.tenderType;

        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.RESET_PAYMENT_OPTION_FORM:
        draft.form = buildOnAccountPaymentOptionsEmptyForm();
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_FORM_IS_LOADING:
        draft.form.isLoading = action.payload;
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_FORM_IS_PROCESSING:
        draft.form.isProcessing = action.payload;
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_FORM_CONTAINS_ERRORS:
        draft.form.containsErrors = action.payload;
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_FORM_STATUS_VALUE:
        draft.form.fields.status.value = action.payload;
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_FORM_PAYMENT_OPTION_NAME_VALUE:
        draft.form.fields.paymentOptionName.value = action.payload;
        draft.form.fields.paymentOptionName.hasError = false;
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_FORM_PAYMENT_OPTION_NAME_HAS_ERROR:
        draft.form.fields.paymentOptionName.hasError = action.payload;
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_FORM_DESCRIPTION_NAME_VALUE:
        draft.form.fields.description.value = action.payload;
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_FORM_SHORT_CODE_VALUE:
        draft.form.fields.shortCode.value = action.payload?.toUpperCase();
        draft.form.fields.shortCode.hasError = false;
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_FORM_SHORT_CODE_HAS_ERROR:
        draft.form.fields.shortCode.hasError = action.payload;
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_FORM_POS_TENDER_TYPE_VALUE:
        draft.form.fields.posTenderType.value = action.payload;
        draft.form.fields.posTenderType.hasError = false;
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_FORM_POS_TENDER_TYPE_HAS_ERROR:
        draft.form.fields.posTenderType.hasError = action.payload;
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.ADD_FORM_PAYMENT_VALIDATION_FIELD:
        const paymentValidationField = buildPaymentValidationField();
        draft.form.fields.paymentValidationFields.push(paymentValidationField);
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.REMOVE_FORM_PAYMENT_VALIDATION_FIELD:
        const internalIdToRemove = action.payload;
        draft.form.fields.paymentValidationFields = draft.form.fields.paymentValidationFields.filter(
          (field: IPaymentValidationField) => field.internalId !== internalIdToRemove
        );
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_VALIDATION_FIELD_REQUIRED_VALUE:
        if (validationFieldIndex !== -1) {
          draft.form.fields.paymentValidationFields[validationFieldIndex].fields.required.value = action.payload.value;
        }
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_VALIDATION_FIELD_LABEL_NAME_VALUE:
        if (validationFieldIndex !== -1) {
          draft.form.fields.paymentValidationFields[validationFieldIndex].fields.fieldLabelName.value =
            action.payload.value;
          draft.form.fields.paymentValidationFields[validationFieldIndex].fields.fieldLabelName.hasError = false;
        }
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_VALIDATION_FIELD_LABEL_NAME_HAS_ERROR:
        if (validationFieldIndex !== -1) {
          draft.form.fields.paymentValidationFields[validationFieldIndex].fields.fieldLabelName.hasError =
            action.payload.hasError;
        }
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_VALIDATION_FIELD_PLACEHOLDER_TEXT_VALUE:
        if (validationFieldIndex !== -1) {
          draft.form.fields.paymentValidationFields[validationFieldIndex].fields.fieldPlaceholderText.value =
            action.payload.value;
          draft.form.fields.paymentValidationFields[validationFieldIndex].fields.fieldPlaceholderText.hasError = false;
        }
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_VALIDATION_FIELD_PLACEHOLDER_TEXT_HAS_ERROR:
        if (validationFieldIndex !== -1) {
          draft.form.fields.paymentValidationFields[validationFieldIndex].fields.fieldPlaceholderText.hasError =
            action.payload.hasError;
        }
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_VALIDATION_FIELD_TYPE_VALUE:
        if (validationFieldIndex !== -1) {
          draft.form.fields.paymentValidationFields[validationFieldIndex].fields.fieldType.value = action.payload.value;
          draft.form.fields.paymentValidationFields[validationFieldIndex].fields.fieldType.hasError = false;
        }
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_VALIDATION_FIELD_TYPE_HAS_ERROR:
        if (validationFieldIndex !== -1) {
          draft.form.fields.paymentValidationFields[validationFieldIndex].fields.fieldType.hasError =
            action.payload.hasError;
        }
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_VALIDATION_FIELD_MAX_CHAR_LENGTH_VALUE:
        if (validationFieldIndex !== -1) {
          draft.form.fields.paymentValidationFields[validationFieldIndex].fields.maxCharacterLength.value =
            action.payload.value;
          draft.form.fields.paymentValidationFields[validationFieldIndex].fields.maxCharacterLength.hasError = false;
        }
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_VALIDATION_FIELD_MAX_CHAR_LENGTH_HAS_ERROR:
        if (validationFieldIndex !== -1) {
          draft.form.fields.paymentValidationFields[validationFieldIndex].fields.maxCharacterLength.hasError =
            action.payload.hasError;
        }
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_VALIDATION_FIELD_MAPPED_POS_ITEM_VALUE:
        if (validationFieldIndex !== -1) {
          draft.form.fields.paymentValidationFields[validationFieldIndex].fields.mappedMenuItem.value =
            action.payload.value;
          draft.form.fields.paymentValidationFields[validationFieldIndex].fields.mappedMenuItem.hasError = false;
        }
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_VALIDATION_FIELD_MAPPED_POS_ITEM_HAS_ERROR:
        if (validationFieldIndex !== -1) {
          draft.form.fields.paymentValidationFields[validationFieldIndex].fields.mappedMenuItem.hasError =
            action.payload.hasError;
        }
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_PAYMENT_OPTION_ID_FIELD:
        draft.form.paymentOptionId = action.payload;
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.GET_PAYMENT_OPTION_BY_ID_LOADING_BEGIN:
        draft.view.isLoading = true;
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.GET_PAYMENT_OPTION_BY_ID_SUCESS:
        draft.view.isLoading = false;
        draft.view.config = action.payload;
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.GET_PAYMENT_OPTION_BY_ID_LOADING_FINISH:
        draft.view.isLoading = false;
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.RESET_PAYMENT_OPTION_VIEW_STATE:
        draft.view = buildOnAccountPaymentOptionsEmptyView();
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.TOGGLE_PAYMENT_OPTION_EDIT_MODE:
        draft.view.isEditMode = action.payload;
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.FETCH_TENDER_TYPES_OPTIONS_VIEW_STATE_BEGIN:
        draft.view.tenderTypesOptions.isLoadingTenderTypes = true;
        draft.view.tenderTypesOptions.tenderTypes = [];
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.FETCH_TENDER_TYPES_OPTIONS_VIEW_STATE_SUCCESS:
        draft.view.tenderTypesOptions.isLoadingTenderTypes = false;
        draft.view.tenderTypesOptions.tenderTypes = action.payload;
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.FETCH_TENDER_TYPES_OPTIONS_VIEW_STATE_ERROR:
        draft.view.tenderTypesOptions.isLoadingTenderTypes = false;
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.GET_PAYMENT_OPTIONS_TENDER_TYPES_BEGIN:
        draft.form.isLoadingTenderTypes = true;
        draft.form.tenderTypes = [];
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.GET_PAYMENT_OPTIONS_TENDER_TYPES_SUCCESS:
        draft.form.isLoadingTenderTypes = false;
        draft.form.tenderTypes = action.payload;
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.GET_PAYMENT_OPTIONS_TENDER_TYPES_ERROR:
        draft.form.isLoadingTenderTypes = false;
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.SET_FORM_IS_VALIDATED:
        draft.form.isValidated = action.payload;
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.SET_ON_ACCOUNT_PAYMENT_OPTION_DELETE_REQUESTED:
        draft.view.deleteRequested = true;
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.SET_ON_ACCOUNT_PAYMENT_OPTION_DELETE_CANCELLED:
        draft.view.deleteRequested = false;
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.SET_ON_ACCOUNT_PAYMENT_OPTION_DELETE_BEGIN:
        draft.view.isDeleting = true;
        draft.view.isDeleted = false;
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.SET_ON_ACCOUNT_PAYMENT_OPTION_DELETE_SUCCESS:
        draft.view.deleteRequested = false;
        draft.view.isDeleting = false;
        draft.view.isDeleted = true;
        return draft;

      case ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.SET_ON_ACCOUNT_PAYMENT_OPTION_DELETE_ERROR:
        draft.view.isDeleting = false;
        return draft;

      default:
        return draft;
    }
  });
};
