import React, { useEffect, useRef, RefObject } from 'react';
import { useResponsiveBreakpoint } from '../../hooks';
import styles from './Tooltip.module.scss';
import TextBubble, { Position } from '../TextBubble/TextBubble';
import classNames from 'classnames';

interface TooltipProps {
  text: string;
  children?: React.ReactNode;
  tooltipOnTheLeft?: boolean;
  additionalStyles?: string;
}

// These values match the width of the tooltip as set in the stylesheet plus 24px for the arrow. If those widths are
// changed, these values should be changed as well. I don't like duplicating that here, but I haven't come up with a
// better approach at the moment.
const TOOLTIP_SAFE_AREA = 274 + 24;
const TOOLTIP_SAFE_AREA_MOBILE = 174 + 24;

const Tooltip = ({ text, children, tooltipOnTheLeft = false, additionalStyles }: TooltipProps): JSX.Element => {
  const [visible, setVisible] = React.useState(false);
  const [isSafeDistanceFromLeft, setIsSafeDistanceFromLeft] = React.useState(true);
  const [isSafeDistanceFromRight, setIsSafeDistanceFromRight] = React.useState(true);

  const elementRef: RefObject<HTMLSpanElement> = useRef(null);

  let arrowLocation: Position = 'left';
  let tooltipClassName = visible ? styles.tooltipShown : styles.tooltip;
  // Switch to showing the tooltip on the left and the arrow on the right if one of these conditions is true:
  // - the props have requested to put the tooltip on the left and there is room to do so
  // - the props have requested to put the tooltip on the right but there is not enough room to do so
  if ((tooltipOnTheLeft && isSafeDistanceFromLeft) || (!tooltipOnTheLeft && !isSafeDistanceFromRight)) {
    arrowLocation = 'right';
    tooltipClassName = visible ? styles.tooltipOnTheLeftShown : styles.tooltipOnTheLeft;
  }

  const { isMobile } = useResponsiveBreakpoint();
  useEffect(() => {
    if (elementRef.current) {
      const domRect = elementRef.current.getBoundingClientRect();
      const tooltipSafeArea = isMobile ? TOOLTIP_SAFE_AREA_MOBILE : TOOLTIP_SAFE_AREA;
      if (domRect.left <= tooltipSafeArea) {
        setIsSafeDistanceFromLeft(false);
      }
      if (domRect.left > window.innerWidth - tooltipSafeArea) {
        setIsSafeDistanceFromRight(false);
      }
    }
  }, [elementRef, isMobile]);

  const containerClassNames = classNames([styles.container, additionalStyles]);

  return (
    <span ref={elementRef} className={containerClassNames} onClick={() => setVisible(!visible)}>
      {children}
      <span className={tooltipClassName}>
        <TextBubble color='black' arrowSize='small' arrowLocation={arrowLocation}>
          {text}
        </TextBubble>
      </span>
    </span>
  );
};

export { Tooltip };
