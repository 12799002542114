import React, { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import NewPromoCodePage from './components/LocationPromoCodes/NewPromoCodePage';
import EditPromoCodePage from './components/LocationPromoCodes/EditPromoCodePage';
import PromoCodePage from './components/LocationPromoCodes/PromoCodePage';
import LocationsEditPage from './components/LocationsEditPage';

const Router: FC<{}> = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:id/promo-codes/new`} component={NewPromoCodePage} />
      <Route path={`${path}/:id/promo-codes/:promoCodeId/edit`} component={EditPromoCodePage} />
      <Route path={`${path}/:id/promo-codes/:promoCodeId`} component={PromoCodePage} />
      <Route path={`${path}/:id/:tab`} component={LocationsEditPage} />
    </Switch>
  );
};

export class Routes {
  public static getPromoCodeTab(companyId: string, id: string): string {
    return `/ready/companies/${companyId}/locations/${id}/promo-codes`;
  }

  public static getNewPromoCodePage(companyId: string, locationId: string): string {
    return `/ready/companies/${companyId}/locations/${locationId}/promo-codes/new`;
  }

  public static getPromoCodePage(companyId: string, locationId: string, id: string): string {
    return `/ready/companies/${companyId}/locations/${locationId}/promo-codes/${id}`;
  }

  public static getEditPromoCodePage(companyId: string, locationId: string, id: string): string {
    return `/ready/companies/${companyId}/locations/${locationId}/promo-codes/${id}/edit`;
  }
}

export default Router;
