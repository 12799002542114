import { IOrderType } from '@ready/table.core/dist/src/models/IOrderType';

export interface IPosOrderTypes {
  loading: boolean;
  error: boolean;
  options: IOrderType[];
}

export const posOrderTypes = {
  loading: false,
  error: false,
  options: [],
} as IPosOrderTypes;
