import debounce from 'lodash/debounce';
import React from 'react';
// redux
import { connect } from 'react-redux';
import { getContexts, loadMoreContexts } from '../../redux/actions/contextSwitcherActions/contextSwitcherActions';
import ListModal from '../Modal/ListModal';
import { ModalProps } from '../Modal/Modal';
import ContextSwitcherList from './ContextSwitcherList';
import ContextSwitcherSearch from './ContextSwitcherSearch';

export interface ContextSwitcherProps extends Pick<ModalProps, 'setShowModal'> {
  getContexts: (query?: string) => any;
  loadMoreContexts: (query: string | undefined, page: number) => any;
}

const ContextSwitcher = (props: ContextSwitcherProps) => {
  const { setShowModal, getContexts, loadMoreContexts } = props;

  const debounceTime = 300;
  const getContextsDebounced = debounce(getContexts, debounceTime);

  const handleContexts = (query?: string): void => {
    getContextsDebounced(query);
  };

  const handleLoadMoreContexts = (query: string | undefined, page: number): void => {
    loadMoreContexts(query, page);
  };

  return (
    <ListModal
      setShowModal={setShowModal}
      headerLabel='Please Select a Company'
      searchControl={<ContextSwitcherSearch />}
      showDismissIcon
    >
      <ContextSwitcherList getContexts={handleContexts} loadMoreContexts={handleLoadMoreContexts} />
    </ListModal>
  );
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {
  getContexts,
  loadMoreContexts,
})(ContextSwitcher);
