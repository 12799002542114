import React, {
  CSSProperties,
  ReactChild,
  ReactChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import styles from './TableV2.module.scss';
import classnames from 'classnames';
import { getIsMobile } from '../../utils/checkMediaQuery';

export interface ITableV2ReducerState {
  columnSizes: Record<string, number>;
  columnSizesUpdated: Record<string, boolean>;
}

export type TDispatch = (action: { payload?: any; type: string }) => void;

export interface ITableV2Context {
  state: ITableV2ReducerState;
  dispatch: TDispatch;
}

const isMobile = getIsMobile();
const scrollXShift = isMobile ? 0 : 50;

export function useXScroll(
  initState: [boolean, boolean],
  shift: number = 50
): [[boolean, boolean], (e: HTMLElement) => void] {
  const [state, setScroll] = useState(initState);
  const handler = useCallback(
    (element: HTMLElement) => {
      const { scrollWidth, offsetWidth, scrollLeft } = element;
      const widthToScroll = scrollWidth - offsetWidth;
      const lScroll = scrollLeft > shift;
      const rScroll = scrollLeft < widthToScroll - shift;
      setScroll([lScroll, rScroll]);
    },
    [shift]
  );

  return [state, handler];
}

const ScrollShadow = ({ side }: { side: string }) => (
  <span className={`${styles.scrollShadow} ${styles[`scrollShadow--${side}`]}`}>
    <span className={styles.scrollShadow__container}>
      <span className={styles.scrollShadow__shadow} />
    </span>
  </span>
);

const tableV2Reducer = (state: ITableV2ReducerState, { payload, type }: { payload?: any; type: string }) => {
  switch (type) {
    case 'SET_COLUMN_SIZE':
      return {
        ...state,
        columnSizes: {
          ...state.columnSizes,
          [payload.columnName]: payload.width,
        },
        columnSizesUpdated: {
          ...state.columnSizesUpdated,
          [payload.columnName]: true,
        },
      };
    case 'UPDATE_COLUMN_SIZES':
      return {
        ...state,
        columnSizesUpdated: Object.keys(state.columnSizes).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
      };
    default:
      return state;
  }
};
interface TableV2Props {
  children: ReactChildren | ReactChild | ReactNode;
  classes?: string;
  loading?: boolean;
}

export const tableV2DefaultContext = {
  state: { columnSizes: {}, columnSizesUpdated: {} },
  dispatch: (action: { payload?: any; type: string }) =>
    console.error('dispatch is not instantiated, action skipped' + JSON.stringify(action)),
};

export const TableV2Context = React.createContext<ITableV2Context>(tableV2DefaultContext);

export function TableV2({ children, classes, loading }: TableV2Props) {
  const [[scrollLeft, scrollRight], handleXScroll] = useXScroll([false, false], scrollXShift);
  const ref = useRef<HTMLDivElement>(null);
  const [state, dispatch] = useReducer(tableV2Reducer, tableV2DefaultContext.state);
  const contextData = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  const allColumnSizesUpdated = Object.values(state.columnSizesUpdated).every((reset) => reset);

  useEffect(() => {
    if (!loading) {
      dispatch({ type: 'UPDATE_COLUMN_SIZES' });
    }
  }, [loading]);

  useEffect(() => {
    if (ref.current && !loading) {
      handleXScroll(ref.current);
    }
  }, [ref, loading, handleXScroll]);

  const fullClasses = classnames(
    {
      [styles['tableV2__tableStyledXScroll--leftScroll']]: scrollLeft,
      [styles['tableV2__tableStyledXScroll--rightScroll']]: scrollRight,
      [styles.tableV2__allColumnSizesUpdated]: allColumnSizesUpdated,
    },
    styles.tableV2_tableStyledXScroll,
    styles.tableV2,
    classes
  );

  return (
    <div
      className={fullClasses}
      style={
        {
          '--cell-white-space': allColumnSizesUpdated && !loading ? 'normal' : 'nowrap',
        } as CSSProperties
      }
      onScroll={(e) => handleXScroll(e.currentTarget)}
      ref={ref}
    >
      <React.StrictMode>
        <ScrollShadow side='left' />
        <ScrollShadow side='right' />
        <TableV2Context.Provider value={contextData}>
          <div className={styles.tableV2__container}>{children}</div>
        </TableV2Context.Provider>
      </React.StrictMode>
    </div>
  );
}
