import React, { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import AdminsListPage from './components/AdminsListPage';
import AdminsCreatePage from './components/AdminsCreatePage';
import AdminsViewPage from './components/AdminsViewPage';
import AdminsEditPage from './components/AdminsEditPage';

const Router: FC<{}> = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/create`} component={AdminsCreatePage} />
      <Route path={`${path}/:id/edit`} component={AdminsEditPage} />
      <Route path={`${path}/:id`} component={AdminsViewPage} />
      <Route exact path={`${path}/`} component={AdminsListPage} />
    </Switch>
  );
};

export default Router;
