import React from 'react';

import classNames from 'classnames';

import TabLabel from './TabLabel';
import Tab from './Tab';

export interface TabsProps {
  children: (JSX.Element | null)[] | (JSX.Element | null);
  color?: 'white' | 'gray';
  selectedTab: string;
}

const Tabs = (props: TabsProps) => {
  const { children, color = 'gray', selectedTab } = props;
  const childTabs = (Array.isArray(children) ? children : [children]).filter((child) => !!child && child.type === Tab);
  const tabsListClassName = classNames('tabs__list', { 'tabs__list--white': color === 'white' });

  return (
    <div className='tabs'>
      <div className={tabsListClassName}>
        {childTabs.map((child) => {
          const { id, route, label } = child!.props;
          return <TabLabel route={route} key={id} label={label} selected={id === selectedTab} />;
        })}
      </div>

      <div className='tab__content'>{childTabs.map((child) => (child!.props.id !== selectedTab ? null : child))}</div>
    </div>
  );
};

export default Tabs;
