import React, { useEffect } from 'react';
import { Link, useLocation, matchPath } from 'react-router-dom';
import styles from './app-layout.module.scss';

export interface NavigationLinkProps {
  to: string;
  label: string;
  isIndented?: boolean;
  isCurrentRoute?: boolean;
  toggleSidebar?: () => void;
}

const stripQueryString = (path: string): string => {
  if (path.includes('?')) {
    return path.slice(0, path.indexOf('?'));
  }
  return path;
};

export const checkIsCurrentRoute = (pathname: string, to: string): boolean => {
  to = stripQueryString(to);
  return !!(
    matchPath(pathname, { path: to }) ||
    matchPath(pathname, { path: '/ready/companies/:companyId/' + to.split('/')[2] }) ||
    matchPath(pathname, { path: '/companies/:companyId/locations/:locationId/' + to.split('/')[3] })
  );
};

const NavigationLink = ({ to, label, isIndented, isCurrentRoute, toggleSidebar }: NavigationLinkProps) => {

  const { pathname } = useLocation();

  const isCurrRoute = isCurrentRoute ?? checkIsCurrentRoute(pathname, to);

  const modifiersClassName = isCurrRoute ? styles.sidebarNavigationLinkActive : styles.sidebarNavigationLink;

  useEffect(() => {
    if (isCurrRoute && toggleSidebar) {
      toggleSidebar();
    }
  }, [isCurrRoute, toggleSidebar]);

  return !isIndented ? (
    <Link to={to} className={modifiersClassName}>
      {label}
    </Link>
  ) : (
    <Link to={to} className={modifiersClassName}>
      <div className={styles.subLinkNav}>{label}</div>
    </Link>
  );
};

export default NavigationLink;
