import produce from 'immer';
import { Action } from '../../redux/types';
import { LOCATION_SETTINGS_ACTIONS } from './LocationSettingsActions';
import { cachedLocation } from './CachedLocationState';

export const cachedLocationReducer = (state = cachedLocation, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case LOCATION_SETTINGS_ACTIONS.SET_LOCATION_CACHE:
        draft = { ...action.payload };
        return draft;
      default:
        return draft;
    }
  });
};
