import ActionHeader from 'components/ActionHeader/ActionHeader';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { TableBodyProps } from 'components/TableV2/Body/Body';
import { CellProps, getWrappedCellsWithMobileLayout } from 'components/TableV2/Cell/Cell';
import { HeaderCell, HeaderCellConstants } from 'components/TableV2/Cell/HeaderCell';
import { TableHeaderProps } from 'components/TableV2/TableHeader/TableHeader';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector, RootState, AppDispatch } from 'redux/store';
import { AssignedLocationsTable } from 'sharedMenuItems/components/assignedLocationsTable/AssignedLocationsTable';
import { CheckboxCell, EnabledStatusCell } from 'sharedMenuItems/pages/itemAssignedLocations/components/table/Cells';
import { SharedMenuItemsRoutes } from 'sharedMenuItems/Router';
import { SharedMenuItemsLayout } from 'sharedMenuItems/SharedMenuItemsLayout';
import { SharedMenuItemsTabId } from 'sharedMenuItems/SharedMenuItemsTabs';
import { ContextParams } from 'types/ContextParams.interface';
import { EditLocationSettingsModal, IEditLocationSettingsModalFields } from '../components/EditLocationSettingsModal';
import { LocationEditCell } from '../components/LocationNameCell';
import styles from '../locationSettings.module.scss';
import { ToolbarContent } from 'sharedMenuItems/pages/itemGroups/assignedLocations/components/ToolbarContent';
import { useSearchParams } from 'hooks/useSearchParams';
import { decodeUriString } from 'utils/urlUtils/decodeUriString';
import { getLocationItemGalleriesThunk, saveLocationItemGalleriesThunk } from './redux/thunks';
import { IBulkEditItemGallery, IBulkEditItemGalleryRequest, IBulkEditMenu } from '@ready/menu.core';
import { getSharedItemGalleryThunk } from 'sharedMenuItems/pages/itemGallery/redux/thunk';
import { updateQueryParamsDebounced } from 'utils/updatePath';
import TextIcon, { Icon } from 'components/Icon/TextIcon';
import { selectTableState } from 'sharedMenuItems/redux/selectors';
import { initCellCheckedState } from 'sharedMenuItems/redux/table/tableSlice';
import { BulkEditToolbarContent } from 'sharedMenuItems/components/bulkEditToolbarContent/BulkEditToolbarContent';

export const ItemGalleryLocationSettingsPage = () => {
  const {
    contextId: companyId,
    menuConfigId,
    menuId,
    itemGalleryId: id,
  } = useParams<ContextParams & { menuConfigId: string; menuId?: string; itemGalleryId: string }>();

  const dispatch: AppDispatch = useAppDispatch();
  const { push } = useHistory();
  const { searchTerm, page } = useSearchParams();
  const decodedSearchTerm = decodeUriString(searchTerm ?? '');
  const { menuGalleryView } = useAppSelector((state: RootState) => state.menuBuilder.menus);
  const itemGalleryLoading = menuGalleryView !== undefined ? menuGalleryView[id]?.loading : true;
  const itemGallery = menuGalleryView !== undefined ? menuGalleryView[id]?.menuGalleryDetails : undefined;

  const { items, loading, pagination } = useAppSelector(
    (state: RootState) => state.sharedMenuItems.locationSettings.itemGallery
  );
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [currentLocationSettings, setCurrentLocationSettings] = useState<IBulkEditItemGallery>();

  const onSearchTermChanged = (searchTerm: string) => {
    updateQueryParamsDebounced(
      SharedMenuItemsRoutes.getItemGalleryLocationSettingsRoute(companyId, menuConfigId, id, menuId),
      { searchTerm: searchTerm, page: '' },
      push
    );
  };

  const saveLocationSettings = async (updates: IEditLocationSettingsModalFields, isBulkEdit: boolean = false) => {
    let itemGalleries: { _id: string; locationId: string }[] = [];
    if (isBulkEdit) {
      itemGalleries = checkedLocationIds.reduce<{ _id: string; locationId: string }[]>((accumulator, locationId) => {
        const menu = items.find((menu) => menu.locationId === locationId);

        if (menu) {
          accumulator.push({ _id: menu._id, locationId });
        }

        return accumulator;
      }, []);
    } else {
      if (currentLocationSettings) {
        itemGalleries = [{ _id: currentLocationSettings._id, locationId: currentLocationSettings.locationId }];
      }
    }
    const request: Omit<IBulkEditItemGalleryRequest, 'templateItemGalleryId'> = {
      itemGalleries,
      itemGalleryUpdates: updates,
    };
    await dispatch(saveLocationItemGalleriesThunk({ companyId, menuId: id, request }));
    dispatch(getLocationItemGalleriesThunk({ companyId, itemGalleryId: id, page, searchTerm }));
  };

  useEffect(() => {
    if (itemGallery?._id !== id) {
      dispatch(getSharedItemGalleryThunk({ companyId, menuOrItemGalleryId: id }));
    }
  }, [companyId, dispatch, menuConfigId, id, itemGallery?._id]);

  useEffect(() => {
    dispatch(getLocationItemGalleriesThunk({ companyId, itemGalleryId: id, searchTerm: decodedSearchTerm, page }));
  }, [companyId, decodedSearchTerm, dispatch, id, page]);

  const bodyProps: TableBodyProps = getTableBodyProps(setCurrentLocationSettings, setShowEditModal);

  const [showBulkEditModal, setShowBulkEditModal] = useState<boolean>(false);
  const { checkCells } = useAppSelector(selectTableState);
  const checkedLocationIds = Object.keys(checkCells).filter((locationId) => checkCells[locationId]);

  useEffect(() => {
    dispatch(initCellCheckedState(items));
  }, [dispatch, items]);

  return (
    <SharedMenuItemsLayout tab={SharedMenuItemsTabId.Menus}>
      {itemGallery && !itemGalleryLoading ? (
        <>
          <div className={styles.container}>
            <ActionHeader
              beforeTextSlot={<TextIcon icon={Icon.Star} additionalStyles={styles.galleryIcon} />}
              text={itemGallery.displayName || 'Item Gallery'}
              additionalTextStyles={styles.displayName}
              fullWidth
              backLinkTo={getBackLink(companyId, menuConfigId, itemGallery._id, menuId)}
              afterTextSlot={<h2 className={styles.title}>Location Settings</h2>}
            />
          </div>

          <AssignedLocationsTable
            title='Locations'
            titleTooltip={
              'This list of locations is based on where the parent menu configuration is assigned. It will update when the configuration is assigned or removed from locations.'
            }
            tableHeaderProps={tableHeaderProps}
            toolbarContent={
              checkedLocationIds.length > 0 ? (
                <BulkEditToolbarContent
                  pagination={pagination}
                  setShowBulkEditModal={setShowBulkEditModal}
                  showBulkUnassignButton={false}
                />
              ) : (
                <ToolbarContent initialSearch={decodedSearchTerm} onSearchTermChanged={onSearchTermChanged} />
              )
            }
            bodyProps={bodyProps}
            items={items}
            loading={loading}
            pagination={pagination}
            noAssignedLocationsMessage={
              'This item gallery cannot be configured at any locations yet. Before you can configure this item gallery at a location,\nyou must assign its parent menu configuration to that location.'
            }
            query={searchTerm}
          />
          {showEditModal && currentLocationSettings && (
            <EditLocationSettingsModal
              title='Edit Item Gallery'
              setShowModal={setShowEditModal}
              locationName={currentLocationSettings.locationName}
              locationSettings={currentLocationSettings}
              onSave={saveLocationSettings}
              hiddenFields={['schedule']}
            />
          )}

          {showBulkEditModal && (
            <EditLocationSettingsModal
              bulkEditCount={checkedLocationIds.length}
              title='Edit Item Gallery Settings'
              setShowModal={setShowBulkEditModal}
              onSave={(updates) => saveLocationSettings(updates, true)}
              hiddenFields={['schedule']}
            />
          )}
        </>
      ) : (
        <LoadingSpinner />
      )}
    </SharedMenuItemsLayout>
  );
};

const tableHeaderProps: TableHeaderProps = {
  cells: {
    checkboxCell: CheckboxCell,
    locationName: (props: any) => <HeaderCell {...props} maxWidth={HeaderCellConstants.defaultCellMaxWidth} />,
    status: (props: any) => <HeaderCell {...props} classes={styles.leftBorder} />,
  },
  data: {
    checkboxCell: '',
    locationName: 'Location Name',
    status: 'Status',
  },
  columns: ['checkboxCell', 'locationName', 'status'],
};

export const getTableBodyProps = (
  setCurrentLocation: React.Dispatch<React.SetStateAction<IBulkEditMenu | undefined>>,
  setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const bodyCells = getWrappedCellsWithMobileLayout(
    {
      checkboxCell: CheckboxCell,
      locationName: (props: CellProps) => (
        <LocationEditCell
          locationNameGetter={(rowData) => rowData.locationName}
          onEditClick={(rowData: IBulkEditMenu) => {
            setCurrentLocation(rowData);
            setShowEditModal(true);
          }}
          {...props}
        />
      ),
      status: EnabledStatusCell,
    },
    tableHeaderProps.data,
    styles.bodyCell
  );

  const bodyProps: TableBodyProps = {
    cells: bodyCells,
    columns: tableHeaderProps.columns,
    data: [],
    rowKey: '_id',
    hiddenColumns: {},
  };
  return bodyProps;
};

const getBackLink = (companyId: string, menuConfigId: string, itemGalleryId: string, menuId?: string) => {
  if (menuId) {
    return SharedMenuItemsRoutes.getSharedMenusMenuRoute(companyId, menuConfigId, menuId);
  } else {
    return SharedMenuItemsRoutes.getSharedMenusItemGalleryRoute(companyId, menuConfigId, itemGalleryId);
  }
};
