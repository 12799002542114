import React from 'react';
import { IMenuSectionItem, IMenuSectionItemGroup, Visibility } from '@ready/menu.core';

import { useClassNames } from '../../../utils/cssUtils';
import styles from './SectionItemGroupCard.module.scss';

import findSectionItem from '../../utils/findSectionItem';
import DisplayNameInput from './DisplayNameInput';
import IconButton from '../../../components/IconButton/IconButton';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';
import DragAndDrop from '../../../components/DragAndDrop';
import SectionItemCard from './SectionItemCard';
import Button from '../../../components/Button/Button';
import { SectionItemChange } from '../../types/SectionItemChange.interface';

export interface SectionItemGroupCardProps {
  item: IMenuSectionItemGroup;
  sectionItemChanges: SectionItemChange[];
  processing: boolean;
  isSharedMenu?: boolean;
  dragHandle?: JSX.Element;
  onRemoveGroup: (id: string) => void;
  onRemoveGroupItem: (groupId: string, itemId: string) => void;
  onChangeGroupName: (id: string, name: string) => void;
  onChangeGroupVisibility: (id: string, visible: boolean) => void;
  onChangeGroupItemVisibility: (groupId: string, itemId: string, visible: boolean) => void;
  onMoveItem: (oldIndex: number, newIndex: number) => void;
  onAddItemsClick: (id: string) => void;
}

const SectionItemGroupCard = ({
  item,
  sectionItemChanges,
  processing,
  isSharedMenu = false,
  dragHandle,
  onRemoveGroup,
  onRemoveGroupItem,
  onChangeGroupName,
  onChangeGroupVisibility,
  onChangeGroupItemVisibility,
  onMoveItem,
  onAddItemsClick,
}: SectionItemGroupCardProps): JSX.Element => (
  <div className={styles.container}>
    <div className={styles.itemGroup}>
      {dragHandle}

      <div className={styles.itemGroupDetails}>
        <div className={styles.itemGroupName}>
          <DisplayNameInput
            value={findSectionItem(sectionItemChanges, item.itemId).displayName}
            validation={findSectionItem(sectionItemChanges, item.itemId).validation}
            processing={processing}
            label='Display Name'
            onChange={(displayName: string) => onChangeGroupName(item.itemId, displayName)}
          />
        </div>

        <div className={styles.itemCount}>
          {item.items.length} Item{item.items.length !== 1 ? 's' : ''}
        </div>
      </div>

      {!isSharedMenu && (
        <IconButton
          onClick={() => onChangeGroupVisibility(item.itemId, item.visibility !== Visibility.visible)}
          loading={processing}
          additionalClassName={item.visibility === Visibility.visible ? styles.hide : styles.show}
        >
          {item.visibility === Visibility.visible ? (
            <TextIcon icon={Icon.EyeOpen} />
          ) : (
            <TextIcon icon={Icon.EyeClosed} />
          )}
        </IconButton>
      )}

      <IconButton onClick={() => onRemoveGroup(item.itemId)} loading={processing} additionalClassName={styles.remove}>
        <TextIcon icon={Icon.RemoveCircle} />
      </IconButton>
    </div>

    <DragAndDrop
      dropAreaClassName={styles.items}
      dropAreaDraggingClassName={styles.itemsDragging}
      dragItemClassName={styles.item}
      dragItemDraggingClassName={styles.itemDragging}
      dragHandleClassName={styles.dragHandle}
      dragHandleProvided
      dragItems={item.items}
      dragItemKeyExtractor={(menuItem: IMenuSectionItem) => menuItem.itemId}
      dragItemComponentBuilder={(menuItem: IMenuSectionItem, dragHandle?: JSX.Element) => (
        <SectionItemCard
          item={menuItem}
          sectionItemChanges={sectionItemChanges}
          processing={processing}
          dragHandle={dragHandle}
          onRemoveItem={(id: string) => onRemoveGroupItem(item.itemId, id)}
          onChangeItemName={onChangeGroupName}
          onChangeItemVisibility={(id: string, visible: boolean) =>
            onChangeGroupItemVisibility(item.itemId, id, visible)
          }
          isSharedMenu={isSharedMenu}
        />
      )}
      onDragItemDropped={onMoveItem}
      emptyComponent={<div className={styles.noItems}>There are no items in this group yet.</div>}
    />

    <div className={useClassNames([{ addItem: !item.items.length }, { addAnotherItem: !!item.items.length }], styles)}>
      <div className={useClassNames([{ addAnotherItemButton: !!item.items.length }], styles)}>
        <Button
          label='+ Add Item to Group'
          variant='secondary'
          onClick={() => onAddItemsClick(item.itemId)}
          loading={processing}
        />
      </div>
    </div>
  </div>
);

export default SectionItemGroupCard;
