import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { AppState } from '../../../redux/initialStates/AppState';
import { setPosItemsModalQuery } from '../../redux/ItemsAndModsActions';

import SearchInput from '../../../components/SearchInput/SearchInput';

export type LinkPosItemSearchProps = ConnectedProps<typeof connector> & {};

const LinkPosItemSearch = (props: LinkPosItemSearchProps) => {
  const { type, query, setPosItemsModalQuery } = props;

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPosItemsModalQuery(event.target.value);
  };

  return (
    <>
      <SearchInput
        value={query}
        placeholder={`Search ${type === 'item' ? 'menu items' : 'modifiers'}`}
        fullWidth
        onChange={handleQueryChange}
        autoFocus={true}
      />
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  type: state.menuBuilder.itemsAndMods.posItemSelection.type,
  query: state.menuBuilder.itemsAndMods.posItemSelection.query,
});

const actionCreators = {
  setPosItemsModalQuery,
};

const connector = connect(mapStateToProps, actionCreators);

export default connector(LinkPosItemSearch);
