import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import debounce from 'lodash/debounce';

import {
  prepareItemsAndGroupsModal,
  loadMenuItemsForInfiniteScroll,
  loadMoreMenuItemsForInfiniteScroll,
  loadItemGroupsForInfiniteScroll,
  loadMoreItemGroupsForInfiniteScroll,
} from '../../redux/MenusActions';
import { prepareNewItemGroupModal } from '../../redux/ItemGroupsActions';
import { IMenuItem, IMenuItemGroup } from '@ready/menu.core';

import ListModal from '../../../components/Modal/ListModal';

import AddItemsAndGroupsSearch from './AddItemsAndGroupsSearch';
import ItemsAndGroupsView from '../../types/ItemsAndGroupsView.enum';
import AddItemsAndGroupsList from './AddItemsAndGroupsList';
import { AppState } from '../../../redux/initialStates/AppState';

export type AddItemsAndGroupsDialogProps = ConnectedProps<typeof connector> & {
  visible: boolean;
  companyId: string;
  locationId?: string;
  selectionType: ItemsAndGroupsView;
  selectedMax?: number;
  onMenuItemAdded: (menuItem: IMenuItem) => void;
  onItemGroupAdded: (itemGroup: IMenuItemGroup) => void;
  onMenuItemRemoved: (menuItemId: string) => void;
  onItemGroupRemoved: (itemGroupId: string) => void;
};

const AddItemsAndGroupsDialog = (props: AddItemsAndGroupsDialogProps): JSX.Element | null => {
  const {
    visible,
    companyId,
    locationId = '',
    selectionType,
    selectedMax,
    selectedCount,
    prepareItemsAndGroupsModal,
    loadMenuItemsForInfiniteScroll,
    loadMoreMenuItemsForInfiniteScroll,
    loadItemGroupsForInfiniteScroll,
    loadMoreItemGroupsForInfiniteScroll,
    prepareNewItemGroupModal,
    onMenuItemAdded,
    onItemGroupAdded,
    onMenuItemRemoved,
    onItemGroupRemoved,
  } = props;

  if (!visible) {
    return null;
  }

  let headerText: string;
  let headerWarning: string | undefined;
  if (selectedCount !== undefined && selectedMax !== undefined) {
    const hasError = selectedCount > selectedMax;
    const selectionDisplay = `(${selectedCount}/${selectedMax})`;
    headerText = hasError ? 'Add Items' : `Add Items ${selectionDisplay}`;
    headerWarning = hasError ? selectionDisplay : undefined;
  } else {
    headerText = 'Add Items';
    headerWarning = undefined;
  }

  const debounceTime = 300;
  const loadMenuItemsDebounced = debounce(loadMenuItemsForInfiniteScroll, debounceTime);
  const loadItemGroupsDebounced = debounce(loadItemGroupsForInfiniteScroll, debounceTime);

  const handleLoadMenuItems = (query: string | undefined): void => {
    loadMenuItemsDebounced(companyId, locationId, query);
  };

  const handleLoadMoreMenuItems = (query: string | undefined, page: number): void => {
    loadMoreMenuItemsForInfiniteScroll(companyId, locationId, query, page);
  };

  const handleLoadItemGroups = (query: string | undefined): void => {
    loadItemGroupsDebounced(companyId, locationId, query);
  };

  const handleLoadMoreItemGroups = (query: string | undefined, page: number): void => {
    loadMoreItemGroupsForInfiniteScroll(companyId, locationId, query, page);
  };

  const loadItems = selectionType === ItemsAndGroupsView.ITEMS ? handleLoadMenuItems : handleLoadItemGroups;
  const loadMoreItems = selectionType === ItemsAndGroupsView.ITEMS ? handleLoadMoreMenuItems : handleLoadMoreItemGroups;
  const addItemToSelection = selectionType === ItemsAndGroupsView.ITEMS ? onMenuItemAdded : onItemGroupAdded;
  const removeItemFromSelection = selectionType === ItemsAndGroupsView.ITEMS ? onMenuItemRemoved : onItemGroupRemoved;

  const handleNewGroupClick = () => {
    prepareNewItemGroupModal(true);
  };

  return (
    <ListModal
      setShowModal={prepareItemsAndGroupsModal}
      headerLabel={headerText}
      headerWarning={headerWarning}
      searchControl={<AddItemsAndGroupsSearch selectionType={selectionType} onNewGroupClick={handleNewGroupClick} />}
      showDismissButton
    >
      <AddItemsAndGroupsList
        loadItems={loadItems}
        loadMoreItems={loadMoreItems}
        addItemToSelection={addItemToSelection}
        removeItemFromSelection={removeItemFromSelection}
      />
    </ListModal>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    selectedCount: state.menuBuilder.menus.itemsAndGroupsSelection.selectedIds.length,
  };
};

const actionCreators = {
  prepareItemsAndGroupsModal,
  loadMenuItemsForInfiniteScroll,
  loadMoreMenuItemsForInfiniteScroll,
  loadItemGroupsForInfiniteScroll,
  loadMoreItemGroupsForInfiniteScroll,
  prepareNewItemGroupModal,
};

const connector = connect(mapStateToProps, actionCreators);

export default connector(AddItemsAndGroupsDialog);
