import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectLocationPromoCode } from '../../redux/selectors/promoCodesSelector';
import { loadPromoCodes } from '../../redux/actions/promoCodeActions';

import styles from './PromoCodesView.module.scss';
import Toggle from '../../../components/Toggle/Toggle';
import { Panel } from '../../../components/PanelLayout';
import Button from '../../../components/Button/Button';
import OverflowButton from '../../../components/OverflowButton/OverflowButton';
import ErrorValue from '../../../components/ErrorValue/ErrorValue';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import StatusIndicator from '../../../components/StatusIndicator/StatusIndicator';

export interface PromoCodesViewProps {
  companyId: string;
  locationId: string;
  promoCodesEnabled: boolean;
  promoCodeStatusProcessing: boolean;
  onPromoCodeStatusChange: (status: boolean) => void;
  hasPermission: boolean;
}

const PromoCodesView = (props: PromoCodesViewProps) => {
  const {
    companyId,
    locationId,
    promoCodesEnabled,
    promoCodeStatusProcessing,
    onPromoCodeStatusChange,
    hasPermission,
  } = props;
  const history = useHistory();

  const { codes, loadingCodes } = useSelector(selectLocationPromoCode);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (locationId) {
      dispatch(loadPromoCodes(locationId, companyId));
    }
  }, [dispatch, locationId, companyId]);

  const onNewCodeClick = (): void => {
    history.push(`/companies/${companyId}/companyLocations/${locationId}/promo-codes/new`);
  };

  if (loadingCodes) {
    return <LoadingSpinner />;
  }

  const headerControls = hasPermission ? (
    <>
      <div className={styles.button}>
        <Button label='+ New Code' onClick={onNewCodeClick} />
      </div>
      <div className={styles.overflow}>
        <OverflowButton
          options={[
            {
              label: '+ New Code',
              onClick: onNewCodeClick,
            },
          ]}
        />
      </div>
    </>
  ) : null;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>Promo Codes Status</div>
        {hasPermission ? (
          <Toggle checked={promoCodesEnabled} onChange={onPromoCodeStatusChange} loading={promoCodeStatusProcessing} />
        ) : (
          <StatusIndicator active={promoCodesEnabled} activeLabel='Active' inactiveLabel='Disabled' />
        )}
      </div>

      <Panel title='Promo Codes' headerControls={headerControls} fullContentArea>
        {!codes || !codes.length ? (
          <div className={styles.noCodes}>Create your first promo code to start offering discounts.</div>
        ) : (
          codes.map((code) => (
            <div key={code._id} className={styles.code}>
              <div className={styles.name}>
                <Link to={`/companies/${companyId}/companyLocations/${locationId}/promo-codes/${code._id}`}>
                  {code.code}
                </Link>
              </div>
              <div className={styles.description}>
                {code.description || <div className={styles.noDescription}>No description</div>}
              </div>
              <div className={styles.posDiscountName}>
                {code.posDiscountName || <ErrorValue text='POS Discount Deleted' />}
              </div>
              <div className={styles.enabled}>
                <StatusIndicator active={code.enabled} activeLabel='Active' inactiveLabel='Disabled' />
              </div>
            </div>
          ))
        )}
      </Panel>
    </div>
  );
};

export default PromoCodesView;
