import { createAsyncThunk } from '@reduxjs/toolkit';
import { IGetPaymentsReportArgs, getPaymentsReport, getPermittedLocations } from '../service';
import { interceptErrorWithToastMessage } from '../../../redux/helpers/interceptErrorWithToastMessage';
import { IPaymentDataReportItem, PaginationResponse } from '@ready/dashboardv2api.contracts';

export const fetchPaymentsReportThunk = createAsyncThunk(
  'paymentsReport/fetch',
  interceptErrorWithToastMessage<IGetPaymentsReportArgs, PaginationResponse<IPaymentDataReportItem>>(getPaymentsReport)
);

export const fetchPermittedLocationsThunk = createAsyncThunk(
  'paymentsReport/permittedLocations',
  interceptErrorWithToastMessage(getPermittedLocations)
);
