import produce from 'immer';
import { DIGITAL_MENU_ACTIONS } from '../actions/digitalMenuActions';
import { clientDigitalMenuDefaultState } from '../initialStates/digitalMenu';
import { DigitalMenuMode } from '@ready/dashboardv2api.contracts';
import { Action } from '../../../redux/types';

export const clientDigitalMenuReducer = (state = clientDigitalMenuDefaultState, action: Action) => {
  return produce(state, (draft: any) => {
    switch (action.type) {
      case DIGITAL_MENU_ACTIONS.LOAD_CLIENT_DIGITAL_MENU_BEGIN:
        draft.loading = true;
        draft.isEditMode = draft.error = false;
        return draft;
      case DIGITAL_MENU_ACTIONS.LOAD_CLIENT_DIGITAL_MENU_SUCCESS:
        draft.view = draft.edit = { ...action.payload };
        draft.loading = draft.error = false;
        return draft;
      case DIGITAL_MENU_ACTIONS.LOAD_CLIENT_DIGITAL_MENU_ERROR:
        draft.loading = false;
        draft.error = true;
        return draft;
      case DIGITAL_MENU_ACTIONS.TOGGLE_CLIENT_DIGITAL_MENU_EDIT_FORM:
        if (action.payload) {
          draft.isEditMode = action.payload;
          draft.edit = draft.view;
        } else {
          draft.isEditMode = action.payload;
          draft.edit = undefined;
          draft.validations.hasError = false;
          draft.validations.errorMessage = '';
        }
        return draft;
      case DIGITAL_MENU_ACTIONS.UPDATE_CLIENT_DIGITAL_MENU_MODE:
        // represent none option
        if (action.payload === '') {
          draft.edit.menuMode = undefined;
          draft.edit.enabled = false;
        } else {
          draft.edit.menuMode = action.payload;
          draft.edit.enabled = true;
          draft.edit.imageKey = draft.view.imageKey;
          draft.edit.iframeUrl = draft.view.iframeUrl;
        }
        draft.validations.hasError = false;
        draft.validations.errorMessage = '';
        return draft;
      case DIGITAL_MENU_ACTIONS.UPDATE_CLIENT_DIGITAL_MENU_IFRAME_URL:
        draft.edit.menuMode = DigitalMenuMode.Iframe;
        draft.edit.iframeUrl = action.payload;
        return draft;
      case DIGITAL_MENU_ACTIONS.UPDATE_CLIENT_DIGITAL_MENU_IMAGE_KEY:
        if (action.payload.fileKey === '') {
          draft.edit.imageUrl = '';
        }
        draft.edit.menuMode = DigitalMenuMode.Image;
        draft.edit.imageKey = action.payload.fileKey;
        return draft;
      case DIGITAL_MENU_ACTIONS.UPDATE_CLIENT_DIGITAL_MENU_IMAGE_URL:
        draft.edit.menuMode = DigitalMenuMode.Image;
        draft.edit.imageUrl = action.payload;
        return draft;
      case DIGITAL_MENU_ACTIONS.SUBMIT_CLIENT_DIGITAL_MENU_FORM_PROCESSING:
        draft.processing = true;
        return draft;
      case DIGITAL_MENU_ACTIONS.SUBMIT_CLIENT_DIGITAL_MENU_FORM_SUCCESS:
        draft.isEditMode = draft.processing = draft.validations.hasError = false;
        draft.validations.errorMessage = '';
        draft.view = action.payload;
        return draft;
      case DIGITAL_MENU_ACTIONS.SUBMIT_CLIENT_DIGITAL_MENU_FORM_ERROR:
        draft.processing = false;
        draft.error = true;
        return draft;
      case DIGITAL_MENU_ACTIONS.SET_CLIENT_DIGITAL_MENU_LOCATION:
        draft.location = action.payload;
        return draft;
      case DIGITAL_MENU_ACTIONS.SUBMIT_CLIENT_DIGITAL_MENU_FORM_VALIDATION_ERROR:
        draft.validations = action.payload;
        return draft;
      case DIGITAL_MENU_ACTIONS.RESET_CLIENT_DIGITAL_MENU_CONFIG:
        draft = { ...clientDigitalMenuDefaultState, location: draft.location };
        return draft;
      default:
        return draft;
    }
  });
};
