import TableLayoutContent from 'companyLocations/components/TableLayoutContent';
import Button from 'components/Button/Button';
import InfoCardList from 'components/InfoCard/InfoCardList';
import { DeleteConfirmationModal } from 'components/Modal';
import OverflowButton from 'components/OverflowButton/OverflowButton';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { ConfigurationCard } from './components/configurationCard/ConfigurationCard';
import { CreateEditConfigModal } from './components/createEditConfigModal/CreateEditConfigModal';
import { resetJetPayConfigOptions, resetJetpayState, selectJetpayConfig } from './redux/slice';
import styles from './JetpaySettings.module.scss';
import { deleteJetpayCompanyConfigThunk, getAllJetpayCompanyConfigsListThunk } from './redux/thunk';
import { useParams } from 'react-router-dom';
import { ContextParams } from 'types/ContextParams.interface';
import { ICompanyPaymentConfig } from '@ready/dashboardv2api.contracts';

const DeleteModalBodyMessage = ({ configName }: { configName: string }) => (
  <p>
    Are you sure you want to delete the configuration "<strong>{configName}</strong>"? Any locations using this
    configuration will no longer be able to process payments until their payment processing settings are updated. This
    action cannot be undone.
  </p>
);

export const JetpaySettings = () => {
  const dispatch = useAppDispatch();

  const { contextId: companyId } = useParams<ContextParams>();

  const {
    companyPaymentConfigsList,
    companyPaymentConfig: {
      options: { name: configName },
    },
    companyPaymentConfig,
    isLoading,
    isSaving,
  } = useAppSelector((state) => state.companies.companySettings.paymentProcessing.jetpay);

  const [activeModal, setActiveModal] = React.useState<'new' | 'edit' | 'delete' | undefined>(undefined);

  const headerControls = (
    <>
      <div className={styles.panelHeaderControlButton}>
        <Button
          label='+ New Configuration'
          onClick={() => {
            dispatch(resetJetPayConfigOptions());
            setActiveModal('new');
          }}
        />
      </div>
      <div className={styles.panelHeaderControlOverflowButton}>
        <OverflowButton
          options={[
            {
              label: '+ New Configuration',
              onClick: () => {
                dispatch(resetJetPayConfigOptions());
                setActiveModal('new');
              },
            },
          ]}
        />
      </div>
    </>
  );

  const onEdit = (configId: string) => {
    dispatch(selectJetpayConfig(configId));
    setActiveModal('edit');
  };

  const onDelete = (configId: string) => {
    dispatch(selectJetpayConfig(configId));
    setActiveModal('delete');
  };

  React.useEffect(() => {
    dispatch(getAllJetpayCompanyConfigsListThunk({ companyId }));
    return () => {
      dispatch(resetJetpayState());
    };
  }, [companyId, dispatch]);

  return (
    <>
      <TableLayoutContent loadingContent={isLoading} title='Jet Pay Settings' headerControls={headerControls}>
        <InfoCardList>
          {companyPaymentConfigsList.length === 0 ? (
            <div className={styles.noConfigMessage}>
              Create a settings configuration to be used when setting up payment processing at your locations.
            </div>
          ) : (
            companyPaymentConfigsList.map(
              ({ id, options: { name, companyNumber, serverPublicKey, clientXmlPublicKey } }: ICompanyPaymentConfig) => {
                return (
                  <ConfigurationCard
                    key={id}
                    configId={id}
                    name={name}
                    companyNumber={companyNumber}
                    clientPublicKey={clientXmlPublicKey}
                    serverPublicKey={serverPublicKey}
                    onDelete={onDelete}
                    onEdit={onEdit}
                  />
                );
              }
            )
          )}
        </InfoCardList>
      </TableLayoutContent>

      {activeModal === 'new' || activeModal === 'edit' ? (
        <CreateEditConfigModal
          onClose={() => {
            setActiveModal(undefined);
          }}
          isNew={activeModal === 'new'}
        />
      ) : null}

      {activeModal === 'delete' ? (
        <DeleteConfirmationModal
          loading={isSaving}
          item='Configuration'
          itemName={configName || ''}
          handleDelete={async () => {
            await dispatch(deleteJetpayCompanyConfigThunk({ companyId, configId: companyPaymentConfig.id }));
            setActiveModal(undefined);
          }}
          setShowModal={() => setActiveModal(undefined)}
          modalBodyComponent={<DeleteModalBodyMessage configName={configName || ''} />}
        />
      ) : null}
    </>
  );
};
