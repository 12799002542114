import { IPickupHours } from '@ready/dashboardv2api.contracts';

export const validateOrderAheadPickUpHours = (hours: IPickupHours): boolean => {
  if (
    !hours ||
    !hours.monday ||
    !hours.tuesday ||
    !hours.wednesday ||
    !hours.thursday ||
    !hours.friday ||
    !hours.saturday ||
    !hours.sunday
  )
    return false;
  return true;
};
