import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import {
  loadPromoCode,
  loadPromoCodePosDiscounts,
  updatePromoCodeStatus,
  updatePromoCodeName,
  updatePromoCodeDescription,
  updatePromoCodePosDiscount,
  updatePromoCodeValidation,
  updatePromoCodeAutoAttach,
  updatePromoCodeTimeSlot,
  updatePromoCodeAddTimeSlot,
  updatePromoCodeRemoveTimeSlot,
  validatePromoCode,
  savePromoCode,
  resetPromoCodeForm,
} from '../../redux/PromoCodesActions';
import { AppState } from '../../../redux/initialStates/AppState';

import LayoutContent from '../../../components/AppLayout/LayoutContent';
import PromoCodeForm from './PromoCodeForm';
import { useHistory, useParams } from 'react-router-dom';
import { Routes } from '../../EditRouter';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';

const mapStateToProps = (state: AppState) => ({
  loading: state.location.promoCodes.form.loading,
  promoCode: state.location.promoCodes.form.promoCode,
  processing: state.location.promoCodes.form.processing,
  discounts: state.location.promoCodes.form.discounts,
  validated: state.location.promoCodes.form.validated,
  saved: state.location.promoCodes.form.saved,
  validation: state.location.promoCodes.form.validation,
});

const actionCreators = {
  loadPromoCode,
  loadPromoCodePosDiscounts,
  updatePromoCodeStatus,
  updatePromoCodeName,
  updatePromoCodeDescription,
  updatePromoCodePosDiscount,
  updatePromoCodeValidation,
  updatePromoCodeAutoAttach,
  updatePromoCodeTimeSlot,
  updatePromoCodeAddTimeSlot,
  updatePromoCodeRemoveTimeSlot,
  validatePromoCode,
  savePromoCode,
  resetPromoCodeForm,
};

const connector = connect(mapStateToProps, actionCreators);

type NewPromoCodePageProps = ConnectedProps<typeof connector>;

const NewPromoCodePage = (props: NewPromoCodePageProps): JSX.Element => {
  const {
    loading,
    promoCode,
    processing,
    discounts,
    validated,
    saved,
    validation,
    loadPromoCode,
    loadPromoCodePosDiscounts,
    updatePromoCodeStatus,
    updatePromoCodeName,
    updatePromoCodeDescription,
    updatePromoCodePosDiscount,
    updatePromoCodeValidation,
    updatePromoCodeAutoAttach,
    updatePromoCodeTimeSlot,
    updatePromoCodeAddTimeSlot,
    updatePromoCodeRemoveTimeSlot,
    validatePromoCode,
    savePromoCode,
    resetPromoCodeForm,
  } = props;
  const {
    companyId,
    id: locationId,
    promoCodeId: id,
  } = useParams<{
    companyId: string;
    id: string;
    promoCodeId: string;
  }>();
  const history = useHistory();

  const onSaveClick = (): void => {
    validatePromoCode(promoCode);
  };

  const onCancelClick = (): void => {
    history.push(Routes.getPromoCodePage(companyId, locationId, promoCode._id));
  };

  React.useEffect(() => {
    if (validated && !validation.hasErrors) {
      savePromoCode(locationId, promoCode);
    }
  }, [validated, validation, savePromoCode, locationId, promoCode]);

  React.useEffect(() => {
    if (saved) {
      history.push(Routes.getPromoCodePage(companyId, locationId, promoCode._id));
    }
  }, [saved, companyId, locationId, promoCode, history]);

  React.useEffect(() => {
    loadPromoCodePosDiscounts(locationId, companyId);
  }, [loadPromoCodePosDiscounts, locationId, companyId]);

  React.useEffect(() => {
    loadPromoCode(locationId, id);
    return () => {
      resetPromoCodeForm();
    };
  }, [loadPromoCode, locationId, id, resetPromoCodeForm]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <LayoutContent title='Edit Promo Code' containerType='within'>
      <PromoCodeForm
        promoCode={promoCode}
        processing={processing}
        discounts={discounts}
        validation={validation}
        onStatusChange={updatePromoCodeStatus}
        onNameChange={updatePromoCodeName}
        onDescriptionChange={updatePromoCodeDescription}
        onPosDiscountChange={updatePromoCodePosDiscount}
        onValidationChange={updatePromoCodeValidation}
        onAutoAttachChange={updatePromoCodeAutoAttach}
        onTimeSlotAdd={updatePromoCodeAddTimeSlot}
        onTimeSlotRemove={updatePromoCodeRemoveTimeSlot}
        onTimeSlotChange={updatePromoCodeTimeSlot}
        onSaveClick={onSaveClick}
        onCancelClick={onCancelClick}
      />
    </LayoutContent>
  );
};

export default connector(NewPromoCodePage);
