import { Option } from '../../components/SelectFilter/SelectFilter';

export enum DropDownListKeyEnum {
  Country = 'country',
  State = 'state',
  TimeZone = 'timezone',
  Currency = 'currency',
  Locale = 'locale',
  PosIntegration = 'posIntegrationOptions',
  PosProvider = 'posProviderOptions',
  PosType = 'posType',
  OrderTime = 'orderTime',
  AcceptOrders = 'acceptOrders',
  FieldType = 'fieldType',
  SendingMethod = 'sendingMethod',
  OrderThrottle = 'orderThrottleDuration',
  PaymentProvider = 'paymentProvider',
  HeaderFont = 'headerFont',
  BodyFont = 'bodyFont',
  TipCalculation = 'tipCalculation',
  PaymentEnvironment = 'paymentEnvironment',
}

export interface IDropDownList {
  options: Option[];
  selectedOption: Option;
}

// static lists (to do -> make the keys conform to DropDownListKeyEnum)
export interface IOptionList {
  timezone: Option[];
  currency: Option[];
  posIntegrationOptions: Option[];
  loyaltyProviderOptions: Option[];
  posType: Option[];
  orderTime: Option[];
  acceptOrders: Option[];
  fieldType: Option[];
  sendingMethod: Option[];
  paymentProvider: Option[];
  throttleDuration: Option[];
  headerFont: Option[];
  bodyFont: Option[];
  tipCalculation: Option[];
  paymentEnvironment: Option[];
  outOfStockManagement: Option[];
  smsMessageSource: Option[];
  guestValidationMethod: Option[];
  singleCheckPos: Option[];
  orderExperienceTypes: Option[];
  orderExperienceTypesFilter: Option[];
  menuItemType: Option[];
  squareGooglePayEnv: Option[];
  brandingImageStyling: Option[];
  itemCommentsSubmission: Option[];
  locale: Option[];
  adyenPriceModel: Option[];
}
