import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  IGetPayoutHistoryReportArgs,
  getPayoutHistoryReport,
  getPermittedLocations,
  exportPayoutHistoryReport,
  exportMultiPayoutHistoryReport
} from '../service';
import { interceptErrorWithToastMessage } from '../../../redux/helpers/interceptErrorWithToastMessage';
import { IPayoutHistoryReportItem, PaginationResponse } from '@ready/dashboardv2api.contracts';

export const fetchPayoutHistoryReportThunk = createAsyncThunk(
  'payoutHistoryReport/fetch',
  interceptErrorWithToastMessage<IGetPayoutHistoryReportArgs, PaginationResponse<IPayoutHistoryReportItem>>(
    getPayoutHistoryReport
  )
);

export const fetchPermittedLocationsThunk = createAsyncThunk(
  'payoutHistoryReport/permittedLocations',
  interceptErrorWithToastMessage(getPermittedLocations)
);

export const exportPayoutHistoryReportThunk = createAsyncThunk(
  'payoutHistoryReport/export',
  interceptErrorWithToastMessage(exportPayoutHistoryReport)
);


export const exportMultiPayoutHistoryReportThunk = createAsyncThunk(
  'multiPayoutHistoryReport/export',
  interceptErrorWithToastMessage(exportMultiPayoutHistoryReport)
);