import { createAsyncThunk } from '@reduxjs/toolkit';
import { interceptErrorWithToastMessage } from '../../../../redux/helpers/interceptErrorWithToastMessage';
import { exportChecksList, getCheckWidgets, getChecksList, getPermittedChecksReportLocations } from '../services/checksService';
import { showToastMessageOnFulfill } from '../../../../redux/helpers/showToastMessageOnFulfill';

export const fetchChecksListThunk = createAsyncThunk(
  'checksReport/fetchList',
  interceptErrorWithToastMessage(getChecksList)
);

export const fetchCheckWidgetsThunk = createAsyncThunk(
  'checksReport/fetchWidgets',
  interceptErrorWithToastMessage(getCheckWidgets)
);

export const exportChecksListThunk = createAsyncThunk(
  'checks/exportList',
  interceptErrorWithToastMessage(showToastMessageOnFulfill(exportChecksList, 'Success! Export complete.'))
);

export const fetchPermittedLocationsThunk = createAsyncThunk(
  'checksReport/permittedLocations',
  interceptErrorWithToastMessage(getPermittedChecksReportLocations)
);
