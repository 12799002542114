import { set200Toast } from '../actions/uiActions/responseStateActions';
import { AppDispatch } from '../store';

export const showToastMessageOnFulfill = (
  apiAsyncMethod: (args: any, config: { dispatch: AppDispatch }) => Promise<any>,
  successMessage: string
) => async (
  args: any,
  { dispatch }: { dispatch: AppDispatch }
) => {
  const data = await apiAsyncMethod(args, { dispatch });
  dispatch(set200Toast(successMessage));
  return data;
}
