import { format, isYesterday, isToday, isTomorrow } from 'date-fns';

export const formatDateTime = (date: Date): string => {
  let dateFormatted = format(date, 'h:mm aa');

  if (isToday(date)) {
    return `${dateFormatted} Today`;
  } else if (isTomorrow(date)) {
    return `${dateFormatted} Tomorrow`;
  } else if (isYesterday(date)) {
    return `${dateFormatted} Yesterday`;
  }

  return `${dateFormatted} ${format(date, 'E MMM d')}`;
};
