import { UI_ACTIONS } from '../../redux/actions/types';
import { toastErrorState } from '../../redux/actions/uiActions/responseStateActions';
import {
  GuestInformationField,
  IAcceptOrdersFor,
  ILocationEditModelResponse,
  ILocationMobileOrdering,
  ILocationOrderAhead,
  IPickupHours,
  OrderTimeViaEnum,
  LOCATION_CONSTANTS,
  ILocationPosSimphony2SettingsEdit,
} from '@ready/dashboardv2api.contracts';
import { LIST_OPTIONS } from '../../utils/selectListUtils/listOptions';
import { DropDownListKeyEnum, IDropDownList } from '../../utils/selectListUtils/listOptions.interface';
import { createDropDownListPayload } from '../../utils/selectListUtils/listUtil';
import { validateOrderAheadPickUpHours } from '../../redux/helpers/validateOrderAheadPickUpHours';
import { getBrandingValidation, IBrandingPanel } from './PanelBrandingState';
import { getDetailsValidation, IDetailsPanelState, IDetailsPanelValidation } from './PanelDetailsState';
import {
  getInitialPickUpHours,
  getOrderAheadValidation,
  IGuestInfoFieldError,
  IPickUpErrors,
  IPickUpHourError,
} from './PanelFeaturesState';
import { IEmailReceiptsPanel } from './PanelEmailReceiptsState';
import { getPOSSettingsValidation, IPosSettingsPanel } from './PanelPosSettingsState';
import { isValidHex } from '../../utils/color';
import { isEmptyString } from '../../utils/stringUtils';
import BrinkService from '../services/BrinkService';
import { Option } from '../../components/SelectFilter/SelectFilter';
import LocationPOSService from '../services/LocationPOSService';
import MenuBuilderService from '../../menus/services/MenuBuilderService';
import { getNewsletterPanelValidation, INewsletterPanel } from './PanelNewsletterState';
import { setFormIsDirty } from 'redux/actions/uiActions/formStateActions';

export const LOCATION_SETTINGS_ACTIONS = {
  LOAD_LOCATION: 'LOAD_LOCATION',
  SET_DETAILS_PANEL: 'SET_DETAILS_PANEL',
  SET_LOCATION_CACHE: 'SET_LOCATION_CACHE',
  LOAD_DROPDOWN_LIST: 'LOAD_DROPDOWN_LIST',
  RESET_LOCATION_UPDATES: 'RESET_LOCATION_UPDATES',
  LOAD_LAST_EDIT: 'LOAD_LAST_EDIT',
  UPDATE_LAST_EDIT: 'UPDATE_LAST_EDIT',
  // details panel
  UPDATE_LOCATION_ENABLED: 'UPDATE_LOCATION_ENABLED',
  UPDATE_LOCATION_HIDDEN: 'UPDATE_LOCATION_HIDDEN',
  UPDATE_LOCATION_NAME: 'UPDATE_LOCATION_NAME',
  UPDATE_LOCATION_PUBLIC_NAME: 'UPDATE_LOCATION_PUBLIC_NAME',
  UPDATE_LOCATION_PHONE_NUMBER: 'UPDATE_LOCATION_PHONE_NUMBER',
  UPDATE_LOCATION_STREET: 'UPDATE_LOCATION_STREET',
  UPDATE_LOCATION_CITY: 'UPDATE_LOCATION_CITY',
  UPDATE_LOCATION_ZIP: 'UPDATE_LOCATION_ZIP',
  UPDATE_LOCATION_COUNTRY: 'UPDATE_LOCATION_COUNTRY',
  UPDATE_LOCATION_STATE: 'UPDATE_LOCATION_STATE',
  UPDATE_LOCATION_TIMEZONE: 'UPDATE_LOCATION_TIMEZONE',
  UPDATE_LOCATION_CURRENCY: 'UPDATE_LOCATION_CURRENCY',
  UPDATE_LOCATION_LOCALE: 'UPDATE_LOCATION_LOCALE',
  SET_NAME_ERROR: 'SET_NAME_ERROR',
  // mobile ordering panel
  SET_MOBILE_ORDERING_PANEL: 'SET_MOBILE_ORDERING_PANEL',
  TOGGLE_MOBILE_ORDERING_PANEL: 'TOGGLE_MOBILE_ORDERING_PANEL',
  TOGGLE_MOBILE_ORDERING_FEATURE: 'TOGGLE_MOBILE_ORDERING_FEATURE',
  TOGGLE_MOBILE_ORDERING: 'TOGGLE_MOBILE_ORDERING',
  TOGGLE_ALCOHOL_ORDERING: 'TOGGLE_ALCOHOL_ORDERING',
  UPDATE_GUEST_VALIDATION_METHOD: 'UPDATE_GUEST_VALIDATION_METHOD',
  UPDATE_GUEST_PRESENCE_DURATION_MINUTES: 'UPDATE_GUEST_PRESENCE_DURATION_MINUTES',
  UPDATE_GUEST_PRESENCE_EXPIRE_MESSAGE: 'UPDATE_GUEST_PRESENCE_EXPIRE_MESSAGE',
  UPDATE_ALCOHOL_POLICY: 'UPDATE_ALCOHOL_POLICY',
  UPDATE_DISRUPTION_MESSAGE: 'UPDATE_DISRUPTION_MESSAGE',
  UPDATE_MENU_ANNOUNCEMENT: 'UPDATE_MENU_ANNOUNCEMENT',
  UPDATE_CHECKOUT_ANNOUNCEMENT: 'UPDATE_CHECKOUT_ANNOUNCEMENT',
  UPDATE_DELIVERY_DETAILS: 'UPDATE_DELIVERY_DETAILS',
  UPDATE_DELIVERY_LOCATION: 'UPDATE_DELIVERY_LOCATION',
  UPDATE_NAVIGATION_TEXT: 'UPDATE_NAVIGATION_TEXT',
  UPDATE_ORDER_BY_EMAIL: 'UPDATE_ORDER_BY_EMAIL',
  UPDATE_ORDER_EMAIL_ADDRESS: 'UPDATE_ORDER_EMAIL_ADDRESS',
  UPDATE_ITEM_COMMENTS_LENGTH: 'UPDATE_ITEM_COMMENTS_LENGTH',
  UPDATE_ALLOW_ITEM_COMMENTS: 'UPDATE_ALLOW_ITEM_COMMENTS',
  UPDATE_POS_MODIFIER: 'UPDATE_POS_MODIFIER',
  UPDATE_COMMENT_SUBMISSION: 'UPDATE_COMMENT_SUBMISSION',
  UPDATE_ORDER_WIDGET: 'UPDATE_ORDER_WIDGET',
  UPDATE_DEFERRED_PAYMENT: 'UPDATE_DEFERRED_PAYMENT',
  UPDATE_SMS_NOTIFICATIONS_ENABLED: 'UPDATE_SMS_NOTIFICATIONS_ENABLED',
  UPDATE_SINGLE_CHECK_POS: 'UPDATE_SINGLE_CHECK_POS',
  UPDATE_START_CHECK_BEFORE_GUESTS_ORDER: 'UPDATE_START_CHECK_BEFORE_GUESTS_ORDER',
  GET_INIT_POS_MODIFIER_START: 'GET_INIT_POS_MODIFIER_START',
  GET_INIT_POS_MODIFIER_SUCCESS: 'GET_INIT_POS_MODIFIER_SUCCESS',
  GET_INIT_POS_MODIFIER_ERROR: 'GET_INIT_POS_MODIFIER_ERROR',
  GET_POS_MODIFIERS_START: 'GET_POS_MODIFIERS_START',
  GET_POS_MODIFIERS_SUCCESS: 'GET_POS_MODIFIERS_SUCCESS',
  GET_POS_MODIFIERS_ERROR: 'GET_POS_MODIFIERS_ERROR',

  // digital menu panel
  SET_DIGITAL_MENU_PANEL: 'SET_DIGITAL_MENU_PANEL',
  TOGGLE_DIGITAL_MENU_PANEL: 'TOGGLE_DIGITAL_MENU_PANEL',
  // order ahead panel
  SET_ORDER_AHEAD_PANEL: 'SET_ORDER_AHEAD_PANEL',
  TOGGLE_ORDER_AHEAD_STATUS: 'TOGGLE_ORDER_AHEAD_STATUS',
  UPDATE_ORDER_TIME_VIA: 'UPDATE_ORDER_TIME_VIA',
  UPDATE_ORDER_TIME_POS_ITEM_ID: 'UPDATE_ORDER_TIME_POS_ITEM_ID',
  UPDATE_ORDER_AHEAD_ACCEPT_FOR: 'UPDATE_ORDER_AHEAD_ACCEPT_FOR',
  UPDATE_ORDER_AHEAD_FUTURE_DAYS_LIMIT: 'UPDATE_ORDER_AHEAD_FUTURE_DAYS_LIMIT',
  SET_PICK_UP_HOURS: 'SET_PICK_UP_HOURS',
  SET_PICK_UP_HOURS_START: 'SET_PICK_UP_HOURS_START',
  SET_PICK_UP_HOURS_END: 'SET_PICK_UP_HOURS_END',
  SET_PICK_UP_HOURS_OFFSET: 'SET_PICK_UP_HOURS_OFFSET',
  ADD_GUEST_INFO: 'ADD_GUEST_INFO',
  REMOVE_GUEST_INFO: 'REMOVE_GUEST_INFO',
  UPDATE_GUEST_INFO_PRIMARY_FIELD: 'UPDATE_GUEST_INFO_PRIMARY_FIELD',
  UPDATE_GUEST_INFO_FIELD_TYPE: 'UPDATE_GUEST_INFO_FIELD_TYPE',
  UPDATE_GUEST_INFO_SENDING_METHOD: 'UPDATE_GUEST_INFO_SENDING_METHOD',
  UPDATE_GUEST_INFO_FIELD_NAME: 'UPDATE_GUEST_INFO_FIELD_NAME',
  UPDATE_GUEST_INFO_PLACEHOLDER: 'UPDATE_GUEST_INFO_PLACEHOLDER',
  UPDATE_GUEST_INFO_MAX_CHAR: 'UPDATE_GUEST_INFO_MAX_CHAR',
  UPDATE_GUEST_INFO_REQUIRED_INPUT: 'UPDATE_GUEST_INFO_REQUIRED_INPUT',
  UPDATE_GUEST_INFO_POS_ITEM_ID: 'UPDATE_GUEST_INFO_POS_ITEM_ID',
  TOGGLE_ORDER_THROTTLING: 'TOGGLE_ORDER_THROTTLING',
  UPDATE_ORDER_THROTTLING_AMOUNT: 'UPDATE_ORDER_THROTTLING_AMOUNT',
  UPDATE_ORDER_THROTTLING_TIME: 'UPDATE_ORDER_THROTTLING_TIME',
  SET_FAKE_MENU_ITEM_ID_ERROR: 'SET_FAKE_MENU_ITEM_ID_ERROR',
  SET_PICK_UP_ERROR: 'SET_PICK_UP_ERROR',
  SET_GUEST_INFO_FIELD_ERROR: 'SET_GUEST_INFO_FIELD_ERROR',
  SET_MENU_ITEM_GUEST_INFO_ERROR: 'SET_MENU_ITEM_GUEST_INFO_ERROR',
  SET_ORDER_THROTTLE_MAX_ORDERS_ERROR: 'SET_ORDER_THROTTLE_MAX_ORDERS_ERROR',
  // pos settings
  SET_POS_SETTINGS_PANEL: 'SET_POS_SETTINGS_PANEL',
  UPDATE_POS_INTEGRATION: 'UPDATE_POS_INTEGRATION',
  UPDATE_OMNIVORE_POS_TYPE: 'UPDATE_OMNIVORE_POS_TYPE',
  UPDATE_OMNIVORE_LOCATION_ID: 'UPDATE_OMNIVORE_LOCATION_ID',
  UPDATE_OMNIVORE_POS_MINIMUM_READY_TIME: 'UPDATE_OMNIVORE_POS_MINIMUM_READY_TIME',
  UPDATE_OMNIVORE_POS_SPECIFIC_SETTINGS: 'UPDATE_OMNIVORE_POS_SPECIFIC_SETTINGS',
  UPDATE_OMNIVORE_POS_SETTINGS_SUPPORT_TABLE: 'UPDATE_OMNIVORE_POS_SETTINGS_SUPPORT_TABLE',
  UPDATE_OMNIVORE_POS_SETTINGS_REQUIRES_COVERS: 'UPDATE_OMNIVORE_POS_SETTINGS_REQUIRES_COVERS',
  UPDATE_OMNIVORE_POS_SETTINGS_MODIFIER_GROUP: 'UPDATE_OMNIVORE_POS_SETTINGS_MODIFIER_GROUP',
  UPDATE_OMNIVORE_POS_SETTINGS_DISABLE_USER_INFO: 'UPDATE_OMNIVORE_POS_SETTINGS_DISABLE_USER_INFO',
  UPDATE_OMNIVORE_POS_SETTINGS_SEND_AND_COMMENT: 'UPDATE_OMNIVORE_POS_SETTINGS_SEND_AND_COMMENT',
  UPDATE_OMNIVORE_POS_MASTERCARD: 'UPDATE_OMNIVORE_POS_MASTERCARD',
  UPDATE_OMNIVORE_POS_VISA: 'UPDATE_OMNIVORE_POS_VISA',
  UPDATE_OMNIVORE_POS_AMEX: 'UPDATE_OMNIVORE_POS_AMEX',
  UPDATE_OMNIVORE_POS_DISCOVER: 'UPDATE_OMNIVORE_POS_DISCOVER',
  UPDATE_OMNIVORE_POS_JCB: 'UPDATE_OMNIVORE_POS_JCB',
  UPDATE_OMNIVORE_POS_INTERAC: 'UPDATE_OMNIVORE_POS_INTERAC',
  UPDATE_OMNIVORE_POS_EMPLOYEE: 'UPDATE_OMNIVORE_POS_EMPLOYEE',
  UPDATE_OMNIVORE_POS_ORDER_TYPE: 'UPDATE_OMNIVORE_POS_ORDER_TYPE',
  UPDATE_OMNIVORE_POS_DEFAULT_REVENUE_CENTER: 'UPDATE_OMNIVORE_POS_DEFAULT_REVENUE_CENTER',
  UPDATE_OMNIVORE_POS_PRICE_CHECK_TABLE: 'UPDATE_OMNIVORE_POS_PRICE_CHECK_TABLE',
  UPDATE_OMNIVORE_POS_PRICE_CHECK_REVENUE_CENTER: 'UPDATE_OMNIVORE_POS_PRICE_CHECK_REVENUE_CENTER',
  UPDATE_OMNIVORE_POS_TENDER_TYPE: 'UPDATE_OMNIVORE_POS_TENDER_TYPE',

  UPDATE_BRINK_POS_ORDER_TYPE: 'UPDATE_BRINK_POS_ORDER_TYPE',
  UPDATE_BRINK_POS_REVENUE_CENTER: 'UPDATE_BRINK_POS_REVENUE_CENTER',
  UPDATE_BRINK_POS_TENDER_TYPE: 'UPDATE_BRINK_POS_TENDER_TYPE',
  UPDATE_BRINK_POS_EMPLOYEE: 'UPDATE_BRINK_POS_EMPLOYEE',
  UPDATE_BRINK_POS_MASTERCARD: 'UPDATE_BRINK_POS_MASTERCARD',
  UPDATE_BRINK_POS_VISA: 'UPDATE_BRINK_POS_VISA',
  UPDATE_BRINK_POS_AMEX: 'UPDATE_BRINK_POS_AMEX',
  UPDATE_BRINK_POS_DISCOVER: 'UPDATE_BRINK_POS_DISCOVER',
  UPDATE_BRINK_POS_JCB: 'UPDATE_BRINK_POS_JCB',
  UPDATE_BRINK_POS_INTERAC: 'UPDATE_BRINK_POS_INTERAC',

  SET_BRINK_EMPLOYEE: 'SET_BRINK_EMPLOYEE',
  GET_BRINK_REVENUE_CENTERS_START: 'GET_BRINK_REVENUE_CENTERS_START',
  GET_BRINK_REVENUE_CENTERS_SUCCESS: 'GET_BRINK_REVENUE_CENTERS_SUCCESS',
  GET_BRINK_REVENUE_CENTERS_ERROR: 'GET_BRINK_REVENUE_CENTERS_ERROR',
  RESET_BRINK_OPTIONS: 'RESET_BRINK_OPTIONS',

  UPDATE_POS_EMPLOYEE: 'UPDATE_POS_EMPLOYEE',
  UPDATE_POS_ORDER_TYPE: 'UPDATE_POS_ORDER_TYPE',
  UPDATE_POS_REVENUE_CENTER: 'UPDATE_POS_REVENUE_CENTER',
  UPDATE_POS_TENDER_TYPE: 'UPDATE_POS_TENDER_TYPE',

  UPDATE_BRINK_LOCATION_ID: 'UPDATE_BRINK_LOCATION_ID',
  UPDATE_BRINK_API_URL: 'UPDATE_BRINK_API_URL',

  UPDATE_POS_BYPASS2_LOCATION_ID: 'UPDATE_POS_BYPASS2_LOCATION_ID',

  UPDATE_POS_SQUARE_LOCATION_ID: 'UPDATE_POS_SQUARE_LOCATION_ID',
  UPDATE_POS_SQUARE_API_KEY: 'UPDATE_POS_SQUARE_API_KEY',
  UPDATE_POS_SQUARE_EMPLOYEE_ID: 'UPDATE_POS_SQUARE_EMPLOYEE_ID',
  UPDATE_POS_SQUARE_REVENUE_CENTER: 'UPDATE_POS_SQUARE_REVENUE_CENTER',
  UPDATE_POS_SQUARE_ORDER_TYPE: 'UPDATE_POS_SQUARE_ORDER_TYPE',
  UPDATE_POS_SQUARE_TENDER_TYPE: 'UPDATE_POS_SQUARE_TENDER_TYPE',
  UPDATE_POS_SQUARE_TABLE: 'UPDATE_POS_SQUARE_TABLE',
  UPDATE_POS_SQUARE_PRICE_CHECK_REVENUE_CENTER: 'UPDATE_POS_SQUARE_PRICE_CHECK_REVENUE_CENTER',
  UPDATE_POS_SQUARE_TABLE_SUPPORT: 'UPDATE_POS_SQUARE_TABLE_SUPPORT',
  UPDATE_POS_SQUARE_REQUIRES_COVERS: 'UPDATE_POS_SQUARE_REQUIRES_COVERS',
  UPDATE_POS_SQUARE_MODIFIER_GROUP_PRICE_CHECKS: 'UPDATE_POS_SQUARE_MODIFIER_GROUP_PRICE_CHECKS',
  UPDATE_POS_SQUARE_DISABLE_USER_INFO_ON_ORDERS: 'UPDATE_POS_SQUARE_DISABLE_USER_INFO_ON_ORDERS',
  UPDATE_POS_SQUARE_SEND_ITEM_AND_COMMENT_SEPARATELY: 'UPDATE_POS_SQUARE_SEND_ITEM_AND_COMMENT_SEPARATELY',

  SET_SELECTED_OPTION_POS_INTEGRATION: 'SET_SELECTED_OPTION_POS_INTEGRATION',
  UPDATE_POS_OUT_OF_STOCK_MANAGEMENT: 'UPDATE_POS_OUT_OF_STOCK_MANAGEMENT',

  UPDATE_FORCED_SERVICE_CHARGE_STATUS: 'UPDATE_FORCED_SERVICE_CHARGE_STATUS',
  UPDATE_FORCED_SERVICE_CHARGE: 'UPDATE_FORCED_SERVICE_CHARGE',
  UPDATE_FORCED_SERVICE_CHARGE_TYPE: 'UPDATE_FORCED_SERVICE_CHARGE_TYPE',
  UPDATE_FORCED_SERVICE_CHARGE_AMOUNT: 'UPDATE_FORCED_SERVICE_CHARGE_AMOUNT',
  UPDATE_FORCED_SERVICE_CHARGE_POS_ITEM: 'UPDATE_FORCED_SERVICE_CHARGE_POS_ITEM',

  UPDATE_SIMPHONY_POS_SETTINGS: 'UPDATE_SIMPHONY_POS_SETTINGS',
  RESET_SIMPHONY_POS_OPTIONS: 'RESET_SIMPHONY_POS_OPTIONS',

  GET_POS_TENDER_TYPES_START: 'GET_POS_TENDER_TYPES_START',
  GET_POS_TENDER_TYPES_SUCCESS: 'GET_POS_TENDER_TYPES_SUCCESS',
  GET_POS_TENDER_TYPES_ERROR: 'GET_POS_TENDER_TYPES_ERROR',

  GET_POS_EMPLOYEES_START: 'GET_POS_EMPLOYEES_START',
  GET_POS_EMPLOYEES_SUCCESS: 'GET_POS_EMPLOYEES_SUCCESS',
  GET_POS_EMPLOYEES_ERROR: 'GET_POS_EMPLOYEES_ERROR',

  // Pos settings validation
  SET_POS_SETTINGS_PANEL_POS_INTEGRATION_ERROR: 'SET_POS_SETTINGS_PANEL_POS_INTEGRATION_ERROR',

  // location feature: pay at table
  TOGGLE_PAY_AT_TABLE_PANEL: 'TOGGLE_PAY_AT_TABLE_PANEL',
  TOGGLE_PAY_AT_TABLE_MULTI_CHECK_CONFIGURATION_OPTION: 'TOGGLE_PAY_AT_TABLE_CONFIGURATION_OPTION',
  UPDATE_PAY_AT_TABLE_CONFIRMATION_MESSAGE: 'UPDATE_PAY_AT_TABLE_CONFIRMATION_MESSAGE',
  SET_PAY_AT_TABLE_PANEL: 'SET_PAY_AT_TABLE_PANEL',
  UPDATE_PAY_BY_ITEM: 'UPDATE_PAY_BY_ITEM',
  UPDATE_PAY_BY_SEAT: 'UPDATE_PAY_BY_SEAT',
  TOGGLE_VERIFY_TICKET: 'TOGGLE_VERIFY_TICKET',
  TOGGLE_PAY_AT_TABLE_REQUIRE_EMAIL_ADDRESS_ON_CHECKOUT: 'TOGGLE_PAY_AT_TABLE_REQUIRE_EMAIL_ON_CHECKOUT',

  // payment processing
  SET_PAYMENT_PROCESSING_PANEL: 'SET_PAYMENT_PROCESSING_PANEL',
  UPDATE_PAYMENT_QUEUES: 'UPDATE_PAYMENT_QUEUES',
  UPDATE_PAYMENT_PROVIDER: 'UPDATE_PAYMENT_PROVIDER',
  UPDATE_STRIPE_ACCOUNT_ID: 'UPDATE_STRIPE_ACCOUNT_ID',
  UPDATE_STRIPE_PERCENTAGE_FEE: 'UPDATE_STRIPE_PERCENTAGE_FEE',
  UPDATE_STRIPE_PER_TRANSACTION_FEE: 'UPDATE_STRIPE_PER_TRANSACTION_FEE',
  UPDATE_STRIPE_PAYMENT_METHOD_CREDIT_CARD: 'UPDATE_STRIPE_PAYMENT_METHOD_CREDIT_CARD',
  UPDATE_STRIPE_PAYMENT_METHOD_APPLE_PAY: 'UPDATE_STRIPE_PAYMENT_METHOD_APPLE_PAY',
  UPDATE_STRIPE_PAYMENT_METHOD_GOOGLE_PAY: 'UPDATE_STRIPE_PAYMENT_METHOD_GOOGLE_PAY',
  // moneris
  UPDATE_MONERIS_API_TOKEN: 'UPDATE_MONERIS_API_TOKEN',
  UPDATE_MONERIS_STORE_ID: 'UPDATE_MONERIS_STORE_ID',
  UPDATE_MONERIS_HPP_TOKEN: 'UPDATE_MONERIS_HPP_TOKEN',
  UPDATE_MONERIS_APPLE_PAY_MERCHANT_ID: 'UPDATE_MONERIS_APPLE_PAY_MERCHANT_ID',
  UPDATE_MONERIS_GOOGLE_PAY_MERCHANT_ID: 'UPDATE_MONERIS_GOOGLE_PAY_MERCHANT_ID',
  UPDATE_MONERIS_PAYMENT_METHOD_CREDIT_CARD: 'UPDATE_MONERIS_PAYMENT_METHOD_CREDIT_CARD',
  UPDATE_MONERIS_PAYMENT_METHOD_APPLE_PAY: 'UPDATE_MONERIS_PAYMENT_METHOD_APPLE_PAY',
  UPDATE_MONERIS_PAYMENT_METHOD_GOOGLE_PAY: 'UPDATE_MONERIS_PAYMENT_METHOD_GOOGLE_PAY',
  UPDATE_MONERIS_APPLE_GOOGLE_NETWORK_MASTERCARD: 'UPDATE_MONERIS_APPLE_GOOGLE_NETWORK_MASTERCARD',
  UPDATE_MONERIS_APPLE_GOOGLE_NETWORK_VISA: 'UPDATE_MONERIS_APPLE_GOOGLE_NETWORK_VISA',
  UPDATE_MONERIS_APPLE_GOOGLE_NETWORK_AMEX: 'UPDATE_MONERIS_APPLE_GOOGLE_NETWORK_AMEX',
  UPDATE_MONERIS_APPLE_GOOGLE_NETWORK_DISCOVER: 'UPDATE_MONERIS_APPLE_GOOGLE_NETWORK_DISCOVER',
  UPDATE_MONERIS_APPLE_GOOGLE_NETWORK_INTERAC: 'UPDATE_MONERIS_APPLE_GOOGLE_NETWORK_INTERAC',
  UPDATE_MONERIS_APPLE_GOOGLE_NETWORK_JCB: 'UPDATE_MONERIS_APPLE_GOOGLE_NETWORK_JCB',
  UPDATE_MONERIS_RATE_LIMITING_TOGGLE: 'UPDATE_MONERIS_RATE_LIMITING_TOGGLE',
  UPDATE_MONERIS_RATE_LIMITING_AMOUNT: 'UPDATE_MONERIS_RATE_LIMITING_AMOUNT',
  UPDATE_MONERIS_RATE_LIMITING_TIME_INTERVAL: 'UPDATE_MONERIS_RATE_LIMITING_TIME_INTERVAL',
  UPDATE_MONERIS_RATE_LIMITING_FOR_GOOGLE_PAY: 'UPDATE_MONERIS_RATE_LIMITING_FOR_GOOGLE_PAY',
  UPDATE_MONERIS_SMALL_TRANSACTIONS: 'UPDATE_MONERIS_SMALL_TRANSACTIONS',
  // iqpay
  UPDATE_IQPAY_PROFILE_ID: 'UPDATE_IQPAY_PROFILE_ID',
  UPDATE_IQPAY_PAYMENT_METHOD_CREDIT_CARD: 'UPDATE_IQPAY_PAYMENT_METHOD_CREDIT_CARD',
  UPDATE_IQPAY_PAYMENT_METHOD_APPLE_PAY: 'UPDATE_IQPAY_PAYMENT_METHOD_APPLE_PAY',
  UPDATE_IQPAY_PAYMENT_METHOD_GOOGLE_PAY: 'UPDATE_IQPAY_PAYMENT_METHOD_GOOGLE_PAY',
  UPDATE_IQPAY_APPLE_GOOGLE_NETWORK_MASTERCARD: 'UPDATE_IQPAY_APPLE_GOOGLE_NETWORK_MASTERCARD',
  UPDATE_IQPAY_APPLE_GOOGLE_NETWORK_VISA: 'UPDATE_IQPAY_APPLE_GOOGLE_NETWORK_VISA',
  UPDATE_IQPAY_APPLE_GOOGLE_NETWORK_AMEX: 'UPDATE_IQPAY_APPLE_GOOGLE_NETWORK_AMEX',
  UPDATE_IQPAY_APPLE_GOOGLE_NETWORK_DISCOVER: 'UPDATE_IQPAY_APPLE_GOOGLE_NETWORK_DISCOVER',
  UPDATE_IQPAY_APPLE_GOOGLE_NETWORK_JCB: 'UPDATE_IQPAY_APPLE_GOOGLE_NETWORK_JCB',
  UPDATE_IQPAY_RATE_LIMITS_TOGGLE: 'UPDATE_IQPAY_RATE_LIMITS_TOGGLE',
  UPDATE_IQPAY_RATE_LIMITS_AMOUNT: 'UPDATE_IQPAY_RATE_LIMITS_AMOUNT',
  UPDATE_IQPAY_RATE_LIMITS_DURATION: 'UPDATE_IQPAY_RATE_LIMITS_DURATION',
  UPDATE_IQPAY_RATE_LIMITS_FOR_GOOGLE_PAY: 'UPDATE_IQPAY_RATE_LIMITS_FOR_GOOGLE_PAY',
  UPDATE_IQPAY_SMALL_TRANSACTIONS: 'UPDATE_IQPAY_SMALL_TRANSACTIONS',
  UPDATE_IQPAY_APPLE_PAY_SETTINGS_MERCHANT_ID: 'UPDATE_IQPAY_APPLE_PAY_SETTINGS_MERCHANT_ID',
  UPDATE_IQPAY_APPLE_PAY_SETTINGS_SUPPORT_3DS: 'UPDATE_IQPAY_APPLE_PAY_SETTINGS_SUPPORT_3DS',
  UPDATE_IQPAY_APPLE_PAY_SETTINGS_SUPPORT_DEBIT: 'UPDATE_IQPAY_APPLE_PAY_SETTINGS_SUPPORT_DEBIT',
  UPDATE_IQPAY_APPLE_PAY_SETTINGS_SUPPORT_CREDIT: 'UPDATE_IQPAY_APPLE_PAY_SETTINGS_SUPPORT_CREDIT',
  UPDATE_IQPAY_GOOGLE_PAY_SETTINGS_GATEWAY_MERCHANT_ID: 'UPDATE_IQPAY_GOOGLE_PAY_SETTINGS_GATEWAY_MERCHANT_ID',
  UPDATE_IQPAY_GOOGLE_PAY_SETTINGS_GATEWAY_ID: 'UPDATE_IQPAY_GOOGLE_PAY_SETTINGS_GATEWAY_ID',
  UPDATE_IQPAY_GOOGLE_PAY_SETTINGS_MERCHANT_ID: 'UPDATE_IQPAY_GOOGLE_PAY_SETTINGS_MERCHANT_ID',
  UPDATE_IQPAY_GOOGLE_PAY_SETTINGS_MERCHANT_NAME: 'UPDATE_IQPAY_GOOGLE_PAY_SETTINGS_MERCHANT_NAME',
  UPDATE_IQPAY_GOOGLE_PAY_SETTINGS_ENVIRONMENT: 'UPDATE_IQPAY_GOOGLE_PAY_SETTINGS_ENVIRONMENT',
  UPDATE_IQPAY_GOOGLE_PAY_SETTINGS_SUPPORT_3DS: 'UPDATE_IQPAY_GOOGLE_PAY_SETTINGS_SUPPORT_3DS',
  UPDATE_IQPAY_GOOGLE_PAY_SETTINGS_SUPPORT_DEBIT: 'UPDATE_IQPAY_GOOGLE_PAY_SETTINGS_SUPPORT_DEBIT',
  UPDATE_IQPAY_GOOGLE_PAY_SETTINGS_SUPPORT_CREDIT: 'UPDATE_IQPAY_GOOGLE_PAY_SETTINGS_SUPPORT_CREDIT',
  // freedom pay
  UPDATE_FREEDOMPAY_STORE_ID: 'UPDATE_FREEDOMPAY_STORE_ID',
  UPDATE_FREEDOMPAY_TERMINAL_ID: 'UPDATE_FREEDOMPAY_TERMINAL_ID',
  UPDATE_FREEDOMPAY_RATE_LIMITING_TOGGLE: 'UPDATE_FREEDOMPAY_RATE_LIMITING_TOGGLE',
  UPDATE_FREEDOMPAY_RATE_LIMITING_AMOUNT: 'UPDATE_FREEDOMPAY_RATE_LIMITING_AMOUNT',
  UPDATE_FREEDOMPAY_RATE_LIMITING_FOR_GOOGLE_PAY: 'UPDATE_FREEDOMPAY_RATE_LIMITING_FOR_GOOGLE_PAY',
  UPDATE_FREEDOMPAY_RATE_LIMITING_TIME_INTERVAL: 'UPDATE_FREEDOMPAY_RATE_LIMITING_TIME_INTERVAL',
  UPDATE_FREEDOMPAY_SMALL_TRANSACTIONS: 'UPDATE_FREEDOMPAY_SMALL_TRANSACTIONS',
  UPDATE_FREEDOMPAY_ESKEY: 'UPDATE_FREEDOMPAY_ESKEY',
  UPDATE_FREEDOMPAY_PAYMENT_METHOD_CREDIT_CARD: 'UPDATE_FREEDOMPAY_PAYMENT_METHOD_CREDIT_CARD',
  UPDATE_FREEDOMPAY_PAYMENT_METHOD_APPLE_PAY: 'UPDATE_FREEDOMPAY_PAYMENT_METHOD_APPLE_PAY',
  UPDATE_FREEDOMPAY_PAYMENT_METHOD_GOOGLE_PAY: 'UPDATE_FREEDOMPAY_PAYMENT_METHOD_GOOGLE_PAY',
  // realex
  UPDATE_REALEX_MERCHANT_ID: 'UPDATE_REALEX_MERCHANT_ID',
  UPDATE_REALEX_ACCOUNT_ID: 'UPDATE_REALEX_ACCOUNT_ID',
  UPDATE_REALEX_SHARED_SECRET: 'UPDATE_REALEX_SHARED_SECRET',
  UPDATE_REALEX_REBATE_PASSWORD: 'UPDATE_REALEX_REBATE_PASSWORD',
  UPDATE_REALEX_REFUND_PASSWORD: 'UPDATE_REALEX_REFUND_PASSWORD',
  UPDATE_REALEX_PAYMENT_METHOD_CREDIT_CARD: 'UPDATE_REALEX_PAYMENT_METHOD_CREDIT_CARD',
  UPDATE_REALEX_PAYMENT_METHOD_APPLE_PAY: 'UPDATE_REALEX_PAYMENT_METHOD_APPLE_PAY',
  UPDATE_REALEX_PAYMENT_METHOD_GOOGLE_PAY: 'UPDATE_REALEX_PAYMENT_METHOD_GOOGLE_PAY',
  UPDATE_REALEX_PAYMENT_METHOD_CREDIT_CARD_SUPPRESS_3DS: 'UPDATE_REALEX_PAYMENT_METHOD_CREDIT_CARD_SUPPRESS_3DS',
  UPDATE_REALEX_APPLE_SETTINGS_MERCHANT_ID: 'UPDATE_REALEX_APPLE_SETTINGS_MERCHANT_ID',
  UPDATE_REALEX_APPLE_SETTINGS_SUPPORTS_3DS: 'UPDATE_REALEX_APPLE_SETTINGS_SUPPORTS_3DS',
  UPDATE_REALEX_APPLE_SETTINGS_SUPPORTS_DEBIT: 'UPDATE_REALEX_APPLE_SETTINGS_SUPPORTS_DEBIT',
  UPDATE_REALEX_APPLE_SETTINGS_SUPPORTS_CREDIT: 'UPDATE_REALEX_APPLE_SETTINGS_SUPPORTS_CREDIT',
  UPDATE_REALEX_GOOGLE_SETTINGS_GATEWAY_MERCHANT_ID: 'UPDATE_REALEX_GOOGLE_SETTINGS_GATEWAY_MERCHANT_ID',
  UPDATE_REALEX_GOOGLE_SETTINGS_GATEWAY_ID: 'UPDATE_REALEX_GOOGLE_SETTINGS_GATEWAY_ID',
  UPDATE_REALEX_GOOGLE_SETTINGS_MERCHANT_ID: 'UPDATE_REALEX_GOOGLE_SETTINGS_MERCHANT_ID',
  UPDATE_REALEX_GOOGLE_SETTINGS_MERCHANT_NAME: 'UPDATE_REALEX_GOOGLE_SETTINGS_MERCHANT_NAME',
  UPDATE_REALEX_GOOGLE_SETTINGS_ENVIRONMENT: 'UPDATE_REALEX_GOOGLE_SETTINGS_ENVIRONMENT',
  UPDATE_REALEX_GOOGLE_SETTINGS_SUPPORTS_3DS: 'UPDATE_REALEX_GOOGLE_SETTINGS_SUPPORTS_3DS',
  UPDATE_REALEX_GOOGLE_SETTINGS_SUPPORTS_CREDIT: 'UPDATE_REALEX_GOOGLE_SETTINGS_SUPPORTS_CREDIT',
  UPDATE_REALEX_APPLE_GOOGLE_NETWORK_MASTERCARD: 'UPDATE_REALEX_APPLE_GOOGLE_NETWORK_MASTERCARD',
  UPDATE_REALEX_APPLE_GOOGLE_NETWORK_VISA: 'UPDATE_REALEX_APPLE_GOOGLE_NETWORK_VISA',
  UPDATE_REALEX_APPLE_GOOGLE_NETWORK_AMEX: 'UPDATE_REALEX_APPLE_GOOGLE_NETWORK_AMEX',
  UPDATE_REALEX_APPLE_GOOGLE_NETWORK_DISCOVER: 'UPDATE_REALEX_APPLE_GOOGLE_NETWORK_DISCOVER',
  UPDATE_REALEX_APPLE_GOOGLE_NETWORK_INTERAC: 'UPDATE_REALEX_APPLE_GOOGLE_NETWORK_INTERAC',
  UPDATE_REALEX_APPLE_GOOGLE_NETWORK_JCB: 'UPDATE_REALEX_APPLE_GOOGLE_NETWORK_JCB',
  UPDATE_REALEX_RATE_LIMITING_TOGGLE: 'UPDATE_REALEX_RATE_LIMITING_TOGGLE',
  UPDATE_REALEX_RATE_LIMITING_AMOUNT: 'UPDATE_REALEX_RATE_LIMITING_AMOUNT',
  UPDATE_REALEX_RATE_LIMITING_TIME_INTERVAL: 'UPDATE_REALEX_RATE_LIMITING_TIME_INTERVAL',
  UPDATE_REALEX_RATE_LIMITING_FOR_GOOGLE_PAY: 'UPDATE_REALEX_RATE_LIMITING_FOR_GOOGLE_PAY',
  UPDATE_REALEX_SMALL_TRANSACTIONS: 'UPDATE_REALEX_SMALL_TRANSACTIONS',
  // jetpay
  UPDATE_JETPAY_STORE_NUMBER: 'UPDATE_JETPAY_STORE_NUMBER',
  UPDATE_JETPAY_COMPANY_PAYMENT_CONFIG_ID: 'UPDATE_JETPAY_COMPANY_PAYMENT_CONFIG_ID',
  UPDATE_JETPAY_PAYMENT_METHOD_CREDIT_CARD: 'UPDATE_JETPAY_PAYMENT_METHOD_CREDIT_CARD',

  UPDATE_JETPAY_PAYMENT_METHOD_APPLE_PAY: 'UPDATE_JETPAY_PAYMENT_METHOD_APPLE_PAY',
  UPDATE_JETPAY_APPLE_PAY_SETTINGS_MERCHANT_ID: 'UPDATE_JETPAY_APPLE_PAY_SETTINGS_MERCHANT_ID',
  UPDATE_JETPAY_APPLE_PAY_SETTINGS_SUPPORT_3DS: 'UPDATE_JETPAY_APPLE_PAY_SETTINGS_SUPPORT_3DS',
  UPDATE_JETPAY_APPLE_PAY_SETTINGS_SUPPORT_DEBIT: 'UPDATE_JETPAY_APPLE_PAY_SETTINGS_SUPPORT_DEBIT',
  UPDATE_JETPAY_APPLE_PAY_SETTINGS_SUPPORT_CREDIT: 'UPDATE_JETPAY_APPLE_PAY_SETTINGS_SUPPORT_CREDIT',
  UPDATE_JETPAY_APPLE_GOOGLE_NETWORK_MASTERCARD: 'UPDATE_JETPAY_APPLE_GOOGLE_NETWORK_MASTERCARD',
  UPDATE_JETPAY_APPLE_GOOGLE_NETWORK_VISA: 'UPDATE_JETPAY_APPLE_GOOGLE_NETWORK_VISA',
  UPDATE_JETPAY_APPLE_GOOGLE_NETWORK_AMEX: 'UPDATE_JETPAY_APPLE_GOOGLE_NETWORK_AMEX',
  UPDATE_JETPAY_APPLE_GOOGLE_NETWORK_DISCOVER: 'UPDATE_JETPAY_APPLE_GOOGLE_NETWORK_DISCOVER',
  UPDATE_JETPAY_APPLE_GOOGLE_NETWORK_JCB: 'UPDATE_JETPAY_APPLE_GOOGLE_NETWORK_JCB',

  UPDATE_JETPAY_RATE_LIMITING_TOGGLE: 'UPDATE_JETPAY_RATE_LIMITING_TOGGLE',
  UPDATE_JETPAY_RATE_LIMITING_AMOUNT: 'UPDATE_JETPAY_RATE_LIMITING_AMOUNT',
  UPDATE_JETPAY_RATE_LIMITING_TIME_INTERVAL: 'UPDATE_JETPAY_RATE_LIMITING_TIME_INTERVAL',
  UPDATE_JETPAY_RATE_LIMITING_FOR_GOOGLE_PAY: 'UPDATE_JETPAY_RATE_LIMITING_FOR_GOOGLE_PAY',
  UPDATE_JETPAY_SMALL_TRANSACTIONS: 'UPDATE_JETPAY_SMALL_TRANSACTIONS',

  // square
  UPDATE_SQUARE_PP_API_TOKEN: 'UPDATE_SQUARE_PP_API_TOKEN',
  UPDATE_SQUARE_PP_STORE_ID: 'UPDATE_SQUARE_PP_STORE_ID',
  UPDATE_SQUARE_PP_CREDIT_CARD: 'UPDATE_SQUARE_PP_CREDIT_CARD',
  UPDATE_SQUARE_PP_APPLE_PAY: 'UPDATE_SQUARE_PP_APPLE_PAY',
  UPDATE_SQUARE_PP_GOOGLE_PAY: 'UPDATE_SQUARE_PP_GOOGLE_PAY',
  UPDATE_SQUARE_PP_MASTERCARD: 'UPDATE_SQUARE_PP_MASTERCARD',
  UPDATE_SQUARE_PP_VISA: 'UPDATE_SQUARE_PP_VISA',
  UPDATE_SQUARE_PP_DISCOVER: 'UPDATE_SQUARE_PP_DISCOVER',
  UPDATE_SQUARE_PP_AMEX: 'UPDATE_SQUARE_PP_AMEX',
  UPDATE_SQUARE_PP_JCB: 'UPDATE_SQUARE_PP_JCB',
  UPDATE_SQUARE_PP_INTERACT: 'UPDATE_SQUARE_PP_INTERACT',
  UPDATE_SQUARE_PP_APPLE_PAY_SUPPORTS_3DS: 'UPDATE_SQUARE_PP_APPLE_PAY_SUPPORTS_3DS',
  UPDATE_SQUARE_PP_APPLE_PAY_SUPPORTS_DEBIT: 'UPDATE_SQUARE_PP_APPLE_PAY_SUPPORTS_DEBIT',
  UPDATE_SQUARE_PP_APPLE_PAY_SUPPORTS_CREDIT: 'UPDATE_SQUARE_PP_APPLE_PAY_SUPPORTS_CREDIT',
  UPDATE_SQUARE_PP_APPLE_PAY_MERCHANT_ID: 'UPDATE_SQUARE_PP_APPLE_PAY_MERCHANT_ID',
  UPDATE_SQUARE_PP_GOOGLE_PAY_SUPPORTS_3DS: 'UPDATE_SQUARE_PP_GOOGLE_PAY_SUPPORTS_3DS',
  UPDATE_SQUARE_PP_GOOGLE_PAY_SUPPORTS_CREDIT: 'UPDATE_SQUARE_PP_GOOGLE_PAY_SUPPORTS_CREDIT',
  UPDATE_SQUARE_PP_GOOGLE_PAY_SUPPORTS_DEBIT: 'UPDATE_SQUARE_PP_GOOGLE_PAY_SUPPORTS_DEBIT',
  UPDATE_SQUARE_PP_GOOGLE_PAY_ENVIRONMENT: 'UPDATE_SQUARE_PP_GOOGLE_PAY_ENVIRONMENT',
  UPDATE_SQUARE_PP_GOOGLE_PAY_GATEWAY_MERCHANT_ID: 'UPDATE_SQUARE_PP_GOOGLE_PAY_GATEWAY_MERCHANT_ID',
  UPDATE_SQUARE_PP_GOOGLE_PAY_WEB_MERCHANT_KEY: 'UPDATE_SQUARE_PP_GOOGLE_PAY_WEB_MERCHANT_KEY',
  UPDATE_SQUARE_PP_GOOGLE_PAY_MERCHANT_ID: 'UPDATE_SQUARE_PP_GOOGLE_PAY_MERCHANT_ID',
  UPDATE_SQUARE_PP_GOOGLE_PAY_MERCHANT_NAME: 'UPDATE_SQUARE_PP_GOOGLE_PAY_MERCHANT_NAME',
  UPDATE_SQUARE_PP_GOOGLE_PAY_GATEWAY_ID: 'UPDATE_SQUARE_PP_GOOGLE_PAY_GATEWAY_ID',
  UPDATE_SQUARE_PP_ALLOW_SMALL_TRANSACTIONS: 'UPDATE_SQUARE_PP_ALLOW_SMALL_TRANSACTIONS',
  UPDATE_SQUARE_PP_RATE_LIMITING_TOGGLE: 'UPDATE_SQUARE_PP_RATE_LIMITING',
  UPDATE_SQUARE_PP_RATE_LIMITING_AMOUNT: 'UPDATE_SQUARE_PP_RATE_LIMITING_AMOUNT',
  UPDATE_SQUARE_PP_RATE_LIMITING_TIME_INTERVAL: 'UPDATE_SQUARE_PP_RATE_LIMITING_TIME_INTERVAL',
  UPDATE_SQUARE_PP_RATE_LIMITING_FOR_GOOGLE_PAY: 'UPDATE_SQUARE_PP_RATE_LIMITING_FOR_GOOGLE_PAY',
  LOAD_SQUARE_LOCATIONS_BEGIN: 'LOAD_SQUARE_LOCATIONS_BEGIN',
  LOAD_SQUARE_LOCATIONS_SUCCESS: 'LOAD_SQUARE_LOCATIONS_SUCCESS',
  LOAD_SQUARE_LOCATIONS_ERROR: 'LOAD_SQUARE_LOCATIONS_ERROR',
  // bambora
  UPDATE_BAMBORA_PP_API_KEY: 'UPDATE_BAMBORA_PP_API_KEY',
  UPDATE_BAMBORA_PP_MERCHANT_ID: 'UPDATE_BAMBORA_PP_MERCHANT_ID',
  UPDATE_BAMBORA_PP_PAYMENT_METHOD_CREDIT_CARD: 'UPDATE_BAMBORA_PP_PAYMENT_METHOD_CREDIT_CARD',
  UPDATE_BAMBORA_PP_PAYMENT_METHOD_APPLE_PAY: 'UPDATE_BAMBORA_PP_PAYMENT_METHOD_APPLE_PAY',
  UPDATE_BAMBORA_PP_APPLE_GOOGLE_NETWORK_MASTERCARD: 'UPDATE_BAMBORA_PP_APPLE_GOOGLE_NETWORK_MASTERCARD',
  UPDATE_BAMBORA_PP_APPLE_GOOGLE_NETWORK_VISA: 'UPDATE_BAMBORA_PP_APPLE_GOOGLE_NETWORK_VISA',
  UPDATE_BAMBORA_PP_APPLE_GOOGLE_NETWORK_AMEX: 'UPDATE_BAMBORA_PP_APPLE_GOOGLE_NETWORK_AMEX',
  UPDATE_BAMBORA_PP_APPLE_GOOGLE_NETWORK_DISCOVER: 'UPDATE_BAMBORA_PP_APPLE_GOOGLE_NETWORK_DISCOVER',
  UPDATE_BAMBORA_PP_APPLE_GOOGLE_NETWORK_JCB: 'UPDATE_BAMBORA_PP_APPLE_GOOGLE_NETWORK_JCB',
  UPDATE_BAMBORA_PP_APPLE_MERCHANT_ID: 'UPDATE_BAMBORA_PP_APPLE_MERCHANT_ID',
  UPDATE_BAMBORA_PP_APPLE_SUPPORTS_3DS: 'UPDATE_BAMBORA_PP_APPLE_SUPPORTS_3DS',
  UPDATE_BAMBORA_PP_APPLE_SUPPORTS_DEBIT: 'UPDATE_BAMBORA_PP_APPLE_SUPPORTS_DEBIT',
  UPDATE_BAMBORA_PP_APPLE_SUPPORTS_CREDIT: 'UPDATE_BAMBORA_PP_APPLE_SUPPORTS_CREDIT',
  UPDATE_BAMBORA_PP_RATE_LIMITING_TOGGLE: 'UPDATE_BAMBORA_PP_RATE_LIMITING_TOGGLE',
  UPDATE_BAMBORA_PP_RATE_LIMITING_AMOUNT: 'UPDATE_BAMBORA_PP_RATE_LIMITING_AMOUNT',
  UPDATE_BAMBORA_PP_RATE_LIMITING_TIME_INTERVAL: 'UPDATE_BAMBORA_PP_RATE_LIMITING_TIME_INTERVAL',
  UPDATE_BAMBORA_PP_RATE_LIMITING_FOR_GOOGLE_PAY: 'UPDATE_BAMBORA_PP_RATE_LIMITING_FOR_GOOGLE_PAY',
  UPDATE_BAMBORA_PP_ALLOW_SMALL_TRANSACTIONS: 'UPDATE_BAMBORA_PP_ALLOW_SMALL_TRANSACTIONS',

  // Adyen
  UPDATE_ADYEN_PP_RATE_LIMITING_TOGGLE: 'UPDATE_ADYEN_PP_RATE_LIMITING',
  UPDATE_ADYEN_PP_RATE_LIMITING_AMOUNT: 'UPDATE_ADYEN_PP_RATE_LIMITING_AMOUNT',
  UPDATE_ADYEN_PP_RATE_LIMITING_TIME_INTERVAL: 'UPDATE_ADYEN_PP_RATE_LIMITING_TIME_INTERVAL',
  UPDATE_ADYEN_PP_RATE_LIMITING_FOR_GOOGLE_PAY: 'UPDATE_ADYEN_PP_RATE_LIMITING_FOR_GOOGLE_PAY',
  UPDATE_ADYEN_PP_ALLOW_SMALL_TRANSACTIONS: 'UPDATE_ADYEN_PP_ALLOW_SMALL_TRANSACTIONS',
  UPDATE_ADYEN_PP_TRANSACTION_FEE_PERCENTAGE: 'UPDATE_ADYEN_PP_TRANSACTION_FEE_PERCENTAGE',
  UPDATE_ADYEN_PP_TRANSACTION_FEE_AMOUNT: 'UPDATE_ADYEN_PP_TRANSACTION_FEE_AMOUNT',
  UPDATE_ADYEN_PP_PAYFAC_REPORT_EMAILS: 'UPDATE_ADYEN_PP_PAYFAC_REPORT_EMAILS',
  UPDATE_ADYEN_PP_PRICING_MODEL: 'UPDATE_ADYEN_PP_PRICING_MODEL',

  // branding
  SET_BRANDING_PANEL: 'SET_BRANDING_PANEL',
  UPDATE_BRANDING_PANEL_PRIMARY_COLOUR: 'UPDATE_BRANDING_PANEL_PRIMARY_COLOUR',
  UPDATE_BRANDING_PANEL_SECONDARY_COLOUR: 'UPDATE_BRANDING_PANEL_SECONDARY_COLOUR',
  UPDATE_BRANDING_PANEL_HEADER_FONT: 'UPDATE_BRANDING_PANEL_HEADER_FONT',
  UPDATE_BRANDING_PANEL_BODY_FONT: 'UPDATE_BRANDING_PANEL_BODY_FONT',
  SET_BRANDING_PANEL_PRIMARY_HEX_ERROR: 'SET_BRANDING_PANEL_PRIMARY_HEX_ERROR',
  SET_BRANDING_PANEL_SECONDARY_HEX_ERROR: 'SET_BRANDING_PANEL_SECONDARY_HEX_ERROR',
  UPDATE_BRANDING_PANEL_HEADER_IMAGE_KEY: 'UPDATE_BRANDING_PANEL_HEADER_IMAGE_KEY',
  SET_BRANDING_PANEL_BANNER_IMAGE_URL: 'SET_BRANDING_PANEL_BANNER_IMAGE_URL',
  UPDATE_BRANDING_PANEL_MENU_BACKGROUND_COLOUR: 'UPDATE_BRANDING_PANEL_MENU_BACKGROUND_COLOUR',
  UPDATE_BRANDING_PANEL_MENU_TEXT_COLOUR: 'UPDATE_BRANDING_PANEL_MENU_TEXT_COLOUR',
  UPDATE_BRANDING_PANEL_ACCENT_BACKGROUND_COLOUR: 'UPDATE_BRANDING_PANEL_ACCENT_BACKGROUND_COLOUR',
  UPDATE_BRANDING_PANEL_ACCENT_TEXT_COLOUR: 'UPDATE_BRANDING_PANEL_ACCENT_TEXT_COLOUR',
  UPDATE_BRANDING_PANEL_IMAGE_STYLING: 'UPDATE_BRANDING_PANEL_IMAGE_STYLING',

  // gratuity
  SET_GRATUITY_PANEL: 'SET_GRATUITY_PANEL',
  TOGGLE_ALLOW_TIPS: 'TOGGLE_ALLOW_TIPS',
  UPDATE_TIP_CALCULATION: 'UPDATE_TIP_CALCULATION',
  UPDATE_TIP_VALUE: 'UPDATE_TIP_VALUE',
  UPDATE_SERVICE_CHARGE_TIP_VALUE: 'UPDATE_SERVICE_CHARGE_TIP_VALUE',
  UPDATE_PRE_DISCOUNT_TIP: 'UPDATE_PRE_DISCOUNT_TIP',
  UPDATE_PRE_ITEM_DISCOUNT_TIP: 'UPDATE_PRE_ITEM_DISCOUNT_TIP',
  UPDATE_PRE_TAX_TIP: 'UPDATE_PRE_TAX_TIP',
  UPDATE_PRE_NON_READY_PAYMENTS_TIP: 'UPDATE_PRE_NON_READY_PAYMENTS_TIP',
  TOGGLE_SHOW_BOTTOM: 'TOGGLE_SHOW_BOTTOM',
  UPDATE_BOTTOM_TEXT: 'UPDATE_BOTTOM_TEXT',
  UPDATE_DEFAULT_CUSTOM_TIP: 'UPDATE_DEFAULT_CUSTOM_TIP',

  // newsletter
  SET_NEWSLETTER_PANEL: 'SET_NEWSLETTER_PANEL',
  TOGGLE_SHOW_NEWSLETTER_SUB_CHECKBOX: 'TOGGLE_SHOW_NEWSLETTER_SUB_CHECKBOX',
  UPDATE_NEWSLETTER_PROMPT_TEXT: 'UPDATE_NEWSLETTER_PROMPT_TEXT',
  UPDATE_NEWSLETTER_SUCCESS_TEXT: 'UPDATE_NEWSLETTER_SUCCESS_TEXT',
  SET_NEWSLETTER_PROMPT_TEXT_ERROR: 'SET_NEWSLETTER_PROMPT_TEXTERROR',
  SET_NEWSLETTER_SUCCESS_TEXT_ERROR: 'SET_NEWSLETTER_SUCCESS_TEXT_ERROR',

  // email receipts
  SET_EMAIL_RECEIPTS_PANEL: 'SET_EMAIL_RECEIPTS_PANEL',
  UPDATE_EMAIL_RECEIPTS_TITLE_TEXT: 'UPDATE_EMAIL_RECEIPTS_TITLE_TEXT',
  UPDATE_EMAIL_RECEIPTS_HEADER_TEXT: 'UPDATE_EMAIL_RECEIPTS_HEADER_TEXT',
  UPDATE_EMAIL_RECEIPTS_LOGO: 'UPDATE_EMAIL_RECEIPTS_LOGO',
  UPDATE_GENESYS_CONFIGS: 'UPDATE_GENESYS_CONFIGS',

  SET_COMPANY_INTEGRATIONS_PANEL: 'SET_COMPANY_INTEGRATIONS_PANEL',

  UPDATE_SUBMIT_ORDERS_TO_POS: 'UPDATE_SUBMIT_ORDERS_TO_POS',

  SET_SERVER_FLOW_PANEL: 'SET_SERVER_FLOW_PANEL',
  TOGGLE_SERVER_FLOW_PANEL: 'TOGGLE_SERVER_FLOW_PANEL',
  UPDATE_SERVER_FLOW_PANEL_CHAT_ASSISTANT_TOGGLE: 'UPDATE_SERVER_FLOW_PANEL_CHAT_ASSISTANT_TOGGLE',
};

export const setLocationCache = (location: ILocationEditModelResponse) => async (dispatch: any) => {
  dispatch({
    type: LOCATION_SETTINGS_ACTIONS.SET_LOCATION_CACHE,
    payload: location,
  });
};

export const setLastEdit = (location: ILocationEditModelResponse) => async (dispatch: any) => {
  dispatch({
    type: LOCATION_SETTINGS_ACTIONS.LOAD_LAST_EDIT,
    payload: {
      lastSaveBy: location.lastSaveBy?.name,
      lastSaveAt: location.lastSaveAt,
      locationName: location.details.name,
    },
  });
};

export const setDetailsPanel = (location: ILocationEditModelResponse) => async (dispatch: any) => {
  dispatch({
    type: LOCATION_SETTINGS_ACTIONS.SET_DETAILS_PANEL,
    payload: {
      details: location.details,
      companyId: location.companyId,
      companyName: location.companyName,
      iqEntityId: location.iqEntityId,
      iqParentEntityId: location.iqParentEntityId,
      locationId: location._id,
      validation: getDetailsValidation(),
    } as IDetailsPanelState,
  });
};

export const setMobileOrderingPanel = (location: ILocationEditModelResponse) => async (dispatch: any) => {
  const mobileOrdering = location.mobileOrdering as ILocationMobileOrdering;
  dispatch({
    type: LOCATION_SETTINGS_ACTIONS.SET_MOBILE_ORDERING_PANEL,
    payload: mobileOrdering,
  });
};

export const setOrderAheadPanel = (location: ILocationEditModelResponse) => async (dispatch: any) => {
  const orderAhead = location.orderAhead as ILocationOrderAhead;

  // drop down lists
  const defaultOrderTime = orderAhead.submitOrderTimeVia
    ? orderAhead.submitOrderTimeVia.type
    : 'Ready Order Time Field';

  const orderTimeDropDownList = createDropDownListPayload(
    LIST_OPTIONS.orderTime,
    defaultOrderTime,
    DropDownListKeyEnum.OrderTime
  ) as IDropDownList;

  const defaultAheadAcceptFor = orderAhead.acceptOrdersFor ? orderAhead.acceptOrdersFor : IAcceptOrdersFor.SameDayOnly;

  const orderAheadAcceptFor = createDropDownListPayload(
    LIST_OPTIONS.acceptOrders,
    defaultAheadAcceptFor,
    DropDownListKeyEnum.AcceptOrders
  ) as IDropDownList;

  let defaultThrottleDuration = '5 min';
  const orderThrottling = orderAhead.orderThrottling;
  if (!!orderThrottling) {
    if (!!orderThrottling && !!orderThrottling.durationMinutes) {
      defaultThrottleDuration = orderThrottling.durationMinutes.toString();
    }
  }

  const throttleDropDownList = createDropDownListPayload(
    LIST_OPTIONS.throttleDuration,
    defaultThrottleDuration,
    DropDownListKeyEnum.OrderThrottle
  ) as IDropDownList;

  dispatch({
    type: UI_ACTIONS.SET_ORDER_AHEAD_PANEL_DROPDOWN,
    payload: {
      orderTime: orderTimeDropDownList,
      acceptOrders: orderAheadAcceptFor,
      throttleDuration: throttleDropDownList,
    },
  });

  // initialize the panel
  dispatch({
    type: LOCATION_SETTINGS_ACTIONS.SET_ORDER_AHEAD_PANEL,
    payload: {
      orderAhead: orderAhead,
      validation: getOrderAheadValidation(),
    },
  });

  // validate pick up hours
  if (!validateOrderAheadPickUpHours(orderAhead.pickUpHours)) {
    dispatch({
      type: LOCATION_SETTINGS_ACTIONS.SET_PICK_UP_HOURS,
      payload: getInitialPickUpHours(),
    });
  }
};

export const setDigitalMenuPanel = (location: ILocationEditModelResponse) => async (dispatch: any) => {
  dispatch({
    type: LOCATION_SETTINGS_ACTIONS.SET_DIGITAL_MENU_PANEL,
    payload: {
      ...location.digitalMenu,
      isDirty: false,
    },
  });
};

export const setPosSettingsPanel = (location: ILocationEditModelResponse) => async (dispatch: any) => {
  const posSettingsPanel = location.posSettings;
  const defaultSelectedPosIntegrationOption = posSettingsPanel.posIntegration;
  const posIntegrationDropDownList = createDropDownListPayload(
    LIST_OPTIONS.posIntegrationOptions,
    defaultSelectedPosIntegrationOption,
    DropDownListKeyEnum.PosIntegration
  );

  dispatch({
    type: LOCATION_SETTINGS_ACTIONS.SET_POS_SETTINGS_PANEL,
    payload: {
      ...location.posSettings,
      validation: getPOSSettingsValidation(),
    },
  });

  dispatch({
    type: UI_ACTIONS.SET_POS_SETTINGS_DROPDOWN,
    payload: {
      posIntegration: posIntegrationDropDownList,
    },
  });
};

export const setPayAtTablePanel = (location: ILocationEditModelResponse) => async (dispatch: any) => {
  dispatch({
    type: LOCATION_SETTINGS_ACTIONS.SET_PAY_AT_TABLE_PANEL,
    payload: location.payAtTable,
  });
};

export const setBrandingPanel = (location: ILocationEditModelResponse) => async (dispatch: any) => {
  const { THEME } = LOCATION_CONSTANTS;
  // set the dropdowns
  const defaultHeaderFont = location.branding.headerFont || THEME.FONT_NAME;
  const headerFontOptions = createDropDownListPayload(
    LIST_OPTIONS.headerFont,
    defaultHeaderFont,
    DropDownListKeyEnum.HeaderFont
  );

  const defaultBodyFont = location.branding.bodyFont || THEME.FONT_NAME;
  const bodyFontOptions = createDropDownListPayload(
    LIST_OPTIONS.bodyFont,
    defaultBodyFont,
    DropDownListKeyEnum.BodyFont
  );
  const bannerImageKey = location.branding.imageKey || THEME.DASHV2_BANNER_UPLOAD_IMAGE_KEY;

  dispatch({
    type: UI_ACTIONS.SET_BRANDING_PANEL_DROPDOWNS,
    payload: {
      headerFont: headerFontOptions,
      bodyFont: bodyFontOptions,
    },
  });

  // set defaults
  const brandingPanel = {
    ...location.branding,
    primaryColour: location.branding.primaryColour || THEME.COLOUR_PRIMARY,
    secondaryColour: location.branding.secondaryColour || THEME.COLOUR_SECONDARY,
    headerFont: defaultHeaderFont,
    bodyFont: defaultBodyFont,
    imageKey: bannerImageKey,
    isDirty: false,
    validation: getBrandingValidation(),
  } as IBrandingPanel;

  // set the panel
  dispatch({
    type: LOCATION_SETTINGS_ACTIONS.SET_BRANDING_PANEL,
    payload: brandingPanel,
  });
};

export const setPaymentProcessingPanel = (location: ILocationEditModelResponse) => async (dispatch: any) => {
  dispatch({
    type: LOCATION_SETTINGS_ACTIONS.SET_PAYMENT_PROCESSING_PANEL,
    payload: location.paymentProcessor,
  });
};

export const setGratuityPanel = (location: ILocationEditModelResponse) => async (dispatch: any) => {
  dispatch({
    type: LOCATION_SETTINGS_ACTIONS.SET_GRATUITY_PANEL,
    payload: { ...location.gratuity },
  });
};

export const setNewsletterPanel = (location: ILocationEditModelResponse) => async (dispatch: any) => {
  dispatch({
    type: LOCATION_SETTINGS_ACTIONS.SET_NEWSLETTER_PANEL,
    payload: {
      ...location.newsletter,
      isDirty: false,
      validation: getNewsletterPanelValidation(),
    },
  });
};

export const setEmailReceiptsPanel = (location: ILocationEditModelResponse) => async (dispatch: any) => {
  // default state
  const emailReceiptsPanel: IEmailReceiptsPanel = {
    ...location.emailReceipts,
    isDirty: false,
    validation: {},
  };

  // set the panel
  dispatch({
    type: LOCATION_SETTINGS_ACTIONS.SET_EMAIL_RECEIPTS_PANEL,
    payload: emailReceiptsPanel,
  });
};

export const validateDetailsPanel =
  (detailsPanel: IDetailsPanelState) =>
  (dispatch: any): boolean => {
    const isNameEmpty = detailsPanel.details.name === '';
    if (isNameEmpty) {
      const validationPayload = {
        details: {
          name: {
            hasError: detailsPanel.details.name === '',
            error: detailsPanel.details.name === '' ? 'Location Name is required' : '',
          },
        },
      } as IDetailsPanelValidation;

      dispatch({
        type: LOCATION_SETTINGS_ACTIONS.SET_NAME_ERROR,
        payload: validationPayload,
      });
    }

    return isNameEmpty;
  };

export const validateOrderAheadPanel =
  (orderAhead: ILocationOrderAhead) =>
  (dispatch: any): boolean => {
    // menu item id
    let emptyFakeMenuItemId = false;
    if (orderAhead.submitOrderTimeVia!.type === OrderTimeViaEnum.PosItem) {
      emptyFakeMenuItemId =
        orderAhead.submitOrderTimeVia!.posItemId === undefined || orderAhead.submitOrderTimeVia!.posItemId === '';

      dispatch({
        type: LOCATION_SETTINGS_ACTIONS.SET_FAKE_MENU_ITEM_ID_ERROR,
        payload: {
          hasError: emptyFakeMenuItemId,
          error: emptyFakeMenuItemId ? 'Menu Name is required' : '',
        },
      });
    }

    // pick up hours
    const pickUpHourErrors: IPickUpErrors = getPickUpErrors(orderAhead.pickUpHours);
    const hasPickUpError = checkForPickUpError(pickUpHourErrors);

    if (hasPickUpError) {
      dispatch({
        type: LOCATION_SETTINGS_ACTIONS.SET_PICK_UP_ERROR,
        payload: {
          hasError: hasPickUpError,
          errors: pickUpHourErrors,
        },
      });
    }

    // guest info fields
    const guestInfoErrors = getGuestInfoFieldErrors(orderAhead.guestInfoFields) as IGuestInfoFieldError[];

    const hasGuestFieldError = checkForGuestFieldError(guestInfoErrors);

    dispatch({
      type: LOCATION_SETTINGS_ACTIONS.SET_GUEST_INFO_FIELD_ERROR,
      payload: {
        hasError: hasGuestFieldError,
        errors: hasGuestFieldError ? guestInfoErrors : [],
      },
    });

    // menu item from guest info
    const hasMenuItemGuestInfoErr = checkForMenuItemGuestInfoError(orderAhead.guestInfoFields);

    dispatch({
      type: LOCATION_SETTINGS_ACTIONS.SET_MENU_ITEM_GUEST_INFO_ERROR,
      payload: {
        hasError: hasMenuItemGuestInfoErr,
        error: hasMenuItemGuestInfoErr ? 'Menu Item is required if sending method is POS Item' : '',
      },
    });
    let orderThrottleMaxOrdersErr = false;
    const orderThrottling = orderAhead.orderThrottling;
    if (!!orderThrottling && orderThrottling.enabled) {
      if (!orderThrottling.amount) {
        orderThrottleMaxOrdersErr = true;
      }
    }

    dispatch({
      type: LOCATION_SETTINGS_ACTIONS.SET_ORDER_THROTTLE_MAX_ORDERS_ERROR,
      payload: {
        hasError: orderThrottleMaxOrdersErr,
        error: orderThrottleMaxOrdersErr ? 'Min value of 1 is required' : '',
      },
    });

    return (
      emptyFakeMenuItemId ||
      hasPickUpError ||
      hasGuestFieldError ||
      hasMenuItemGuestInfoErr ||
      orderThrottleMaxOrdersErr
    );
  };

const checkForMenuItemGuestInfoError = (guestInfoFields: GuestInformationField[]): boolean => {
  return guestInfoFields.some((guestInfoField: GuestInformationField) => {
    return guestInfoField.methodForSending === 'fakeItem';
  });
};

const checkForGuestFieldError = (guestInfoErrors: IGuestInfoFieldError[]): boolean => {
  return guestInfoErrors.some((guestInfoErr: IGuestInfoFieldError) => {
    return (
      guestInfoErr.fieldName || guestInfoErr.placeholder || guestInfoErr.methodForSending || guestInfoErr.charLimit
    );
  });
};

const getGuestInfoFieldErrors = (guestInfoFields: GuestInformationField[]): IGuestInfoFieldError[] => {
  // can have only 1 ticket filed with ticketName
  const hasATicketField = guestInfoFields.some((guestInfoField: GuestInformationField) => {
    return guestInfoField.methodForSending === 'ticketName';
  });

  return guestInfoFields.map((guestInfoField: GuestInformationField) => {
    const error = {} as IGuestInfoFieldError;
    error.fieldName = error.fieldName = guestInfoField.fieldName === '';
    error.placeholder = guestInfoField.placeholder === '';
    error.methodForSending = hasATicketField && guestInfoField.methodForSending === 'ticketName';
    error.charLimit = guestInfoField.charLimit ? guestInfoField.charLimit < 1 : false;
    return error;
  });
};

export const validatePosSettingsPanel =
  (posSettings: IPosSettingsPanel) =>
  (dispatch: any): boolean => {
    const posIntegration = posSettings.posIntegration;

    dispatch({
      type: LOCATION_SETTINGS_ACTIONS.SET_POS_SETTINGS_PANEL_POS_INTEGRATION_ERROR,
      payload: {
        hasError: isEmptyString(posIntegration),
        error: isEmptyString(posIntegration) ? 'Please choose an integration type.' : '',
      },
    });

    return isEmptyString(posIntegration);
  };

export const validateBrandingPanel =
  (branding: IBrandingPanel) =>
  (dispatch: any): boolean => {
    const primaryColour = branding.primaryColour;
    const secondaryColour = branding.secondaryColour;
    const errors = getBrandingPanelHexValueErrors(primaryColour, secondaryColour);

    dispatch({
      type: LOCATION_SETTINGS_ACTIONS.SET_BRANDING_PANEL_PRIMARY_HEX_ERROR,
      payload: {
        hasError: errors.primaryColourHexError,
        error: errors.primaryColourHexError ? 'Invalid hex value. Please try another.' : '',
      },
    });

    dispatch({
      type: LOCATION_SETTINGS_ACTIONS.SET_BRANDING_PANEL_SECONDARY_HEX_ERROR,
      payload: {
        hasError: errors.secondaryColourHexError,
        error: errors.secondaryColourHexError ? 'Invalid hex value. Please try another.' : '',
      },
    });

    return errors.primaryColourHexError || errors.secondaryColourHexError;
  };

export const validateNewsletterPanel =
  (newsletter: INewsletterPanel) =>
  (dispatch: any): boolean => {
    let newsletterPromptTextErr = false;
    let newsletterSuccessTextErr = false;
    if (newsletter.showNewsletterSubCheckbox) {
      newsletterPromptTextErr = isEmptyString(newsletter.newsletterPromptText);
      newsletterSuccessTextErr = isEmptyString(newsletter.newsletterSuccessText);
    }

    dispatch({
      type: LOCATION_SETTINGS_ACTIONS.SET_NEWSLETTER_PROMPT_TEXT_ERROR,
      payload: {
        hasError: newsletterPromptTextErr,
        error: newsletterPromptTextErr ? 'Required Field.' : '',
      },
    });

    dispatch({
      type: LOCATION_SETTINGS_ACTIONS.SET_NEWSLETTER_SUCCESS_TEXT_ERROR,
      payload: {
        hasError: newsletterSuccessTextErr,
        error: newsletterSuccessTextErr ? 'Required Field.' : '',
      },
    });

    return newsletterPromptTextErr || newsletterSuccessTextErr;
  };

const getBrandingPanelHexValueErrors = (
  primaryColour: string,
  secondaryColour: string
): { primaryColourHexError: boolean; secondaryColourHexError: boolean } => {
  let errors = {
    primaryColourHexError: false,
    secondaryColourHexError: false,
  };

  if (!isValidHex(primaryColour) && !isValidHex(secondaryColour)) {
    errors.primaryColourHexError = true;
    errors.secondaryColourHexError = true;
  } else if (!isValidHex(primaryColour)) {
    errors.primaryColourHexError = true;
  } else if (!isValidHex(secondaryColour)) {
    errors.secondaryColourHexError = true;
  }
  return errors;
};

const getPickUpErrors = (pickUpHours: IPickupHours): IPickUpErrors => {
  return Object.keys(pickUpHours).reduce((acc: any, day: string) => {
    acc[day] = {} as IPickUpHourError;
    acc[day].start = pickUpHours[day as keyof IPickupHours].start === '';
    acc[day].end = pickUpHours[day as keyof IPickupHours].end === '';
    acc[day].offset = pickUpHours[day as keyof IPickupHours].offset === '';
    return acc;
  }, {}) as IPickUpErrors;
};

const checkForPickUpError = (pickUpErrors: IPickUpErrors) => {
  return Object.keys(pickUpErrors).some((day: string) => {
    const pickUpTimeError = pickUpErrors[day as keyof IPickUpErrors] as IPickUpHourError;
    return pickUpTimeError.start || pickUpTimeError.end || pickUpTimeError.offset;
  });
};

export const updatePosEmployeeSelection = (employee: Option) => ({
  type: LOCATION_SETTINGS_ACTIONS.SET_BRINK_EMPLOYEE,
  payload: employee,
});

export const setPosSpecificSelections = (location: ILocationEditModelResponse) => async (dispatch: any) => {
  try {
    if (location.posSettings.posIntegration === 'parbrink' && location.posSettings.optionsBrink?.posEmployee) {
      const employee = await BrinkService.getEmployee(
        location.companyId,
        location._id,
        location.posSettings.optionsBrink.posEmployee
      );
      dispatch(updatePosEmployeeSelection(employee));
    }
  } catch (error) {
    dispatch(toastErrorState(error.status, error.message));
  }
};

export const fetchBrinkRevenueCenters =
  (locationId: string, brinkLocationId: string, brinkApiUrl: string) => async (dispatch: any) => {
    dispatch({ type: LOCATION_SETTINGS_ACTIONS.GET_BRINK_REVENUE_CENTERS_START });
    try {
      const revenueCenters = await BrinkService.getRevenueCenters(locationId, brinkLocationId, brinkApiUrl);
      dispatch({
        type: LOCATION_SETTINGS_ACTIONS.GET_BRINK_REVENUE_CENTERS_SUCCESS,
        payload: revenueCenters,
      });
    } catch (error) {
      dispatch({
        type: LOCATION_SETTINGS_ACTIONS.GET_BRINK_REVENUE_CENTERS_ERROR,
      });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const resetBrinkOptions = () => ({
  type: LOCATION_SETTINGS_ACTIONS.RESET_BRINK_OPTIONS,
});

export const loadSquarePOSLocations = (locationId: string) => async (dispatch: any) => {
  dispatch({ type: LOCATION_SETTINGS_ACTIONS.LOAD_SQUARE_LOCATIONS_BEGIN });
  try {
    const locationsPayload = await LocationPOSService.getPOSLocations(locationId, 'square');
    const locations: Option[] = locationsPayload.map((l: any) => ({
      value: l.id,
      label: l.name,
    }));
    dispatch({
      type: LOCATION_SETTINGS_ACTIONS.LOAD_SQUARE_LOCATIONS_SUCCESS,
      payload: locations,
    });
  } catch (err) {
    dispatch(toastErrorState(err.status, err.message));
    dispatch({ type: LOCATION_SETTINGS_ACTIONS.LOAD_SQUARE_LOCATIONS_ERROR });
  }
};

export const setCompanyIntegrationsPanel = (location: ILocationEditModelResponse) => async (dispatch: any) => {
  dispatch({
    type: LOCATION_SETTINGS_ACTIONS.SET_COMPANY_INTEGRATIONS_PANEL,
    payload: location.companyIntegrations,
  });
};

export const getInitPOSModifier = (companyId: string, locationId: string, query: string) => async (dispatch: any) => {
  dispatch({ type: LOCATION_SETTINGS_ACTIONS.GET_INIT_POS_MODIFIER_START });
  try {
    const { items } = await MenuBuilderService.getPosModifiers(companyId, locationId, query, 1, 1);
    dispatch({
      type: LOCATION_SETTINGS_ACTIONS.GET_INIT_POS_MODIFIER_SUCCESS,
      payload: {
        modifiers: items,
        selectedModifier: items.length > 0 ? items[0] : undefined,
      },
    });
  } catch (error) {
    dispatch(toastErrorState(error.status, error.message));
    dispatch({ type: LOCATION_SETTINGS_ACTIONS.GET_INIT_POS_MODIFIER_ERROR });
  }
};

export const loadPOSModifiers =
  (companyId: string, locationId: string, query: string = '', page: number = 1, pageSize: number = 10) =>
  async (dispatch: any) => {
    dispatch({ type: LOCATION_SETTINGS_ACTIONS.GET_POS_MODIFIERS_START });
    try {
      const results = await MenuBuilderService.getPosModifiers(companyId, locationId, query, page, pageSize);
      dispatch({ type: LOCATION_SETTINGS_ACTIONS.GET_POS_MODIFIERS_SUCCESS, payload: results.items });
    } catch (error) {
      dispatch(toastErrorState(error.status, error.message));
      dispatch({ type: LOCATION_SETTINGS_ACTIONS.GET_POS_MODIFIERS_ERROR });
    }
  };

export const updateSimphonyPosSettings =
  (fieldToUpdate: Partial<ILocationPosSimphony2SettingsEdit>) => async (dispatch: any) => {
    dispatch({
      type: LOCATION_SETTINGS_ACTIONS.UPDATE_SIMPHONY_POS_SETTINGS,
      payload: fieldToUpdate,
    });
    dispatch(setFormIsDirty(true));
  };

export const resetSimphonyOptions = () => async (dispatch: any) => {
  dispatch({
    type: LOCATION_SETTINGS_ACTIONS.RESET_SIMPHONY_POS_OPTIONS,
  });
};

export const getPosTenderTypes = (locationId: string) => async (dispatch: any) => {
  dispatch({ type: LOCATION_SETTINGS_ACTIONS.GET_POS_TENDER_TYPES_START });
  try {
    const tenderTypes = await LocationPOSService.getTenderTypes(locationId);
    dispatch({
      type: LOCATION_SETTINGS_ACTIONS.GET_POS_TENDER_TYPES_SUCCESS,
      payload: tenderTypes,
    });
  } catch (error) {
    dispatch({ type: LOCATION_SETTINGS_ACTIONS.GET_POS_TENDER_TYPES_ERROR });
    dispatch(toastErrorState(error.status, error.message));
  }
};

export const getPosEmployees = (companyId: string, locationId: string, filter: string) => async (dispatch: any) => {
  dispatch({ type: LOCATION_SETTINGS_ACTIONS.GET_POS_EMPLOYEES_START });
  try {
    const employees = await LocationPOSService.getEmployees(companyId, locationId, filter);
    dispatch({
      type: LOCATION_SETTINGS_ACTIONS.GET_POS_EMPLOYEES_SUCCESS,
      payload: employees,
    });
  } catch (error) {
    dispatch({ type: LOCATION_SETTINGS_ACTIONS.GET_POS_EMPLOYEES_ERROR });
    dispatch(toastErrorState(error.status, error.message));
  }
};

export const setServerFlowPanel = (location: ILocationEditModelResponse) => async (dispatch: any) => {  
  dispatch({
    type: LOCATION_SETTINGS_ACTIONS.SET_SERVER_FLOW_PANEL,
    payload: location.serverFlow,
  });
};
