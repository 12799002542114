import { IPermissionSetMetadata } from '@ready/security.core';

export interface IPermissionSet {
  loading: boolean;
  error: boolean;
  permissionSet: IPermissionSetMetadata;
}

export interface ISecurityMetadata {
  permissions: IPermissionSet;
}

export const securityMetadata: ISecurityMetadata = {
  permissions: {
    loading: false,
    error: false,
    permissionSet: {
      companyPermissions: [],
      locationPermissions: [],
    },
  },
};
