import React from 'react';
import { IMenuItemGroup } from '@ready/menu.core';
import { useHistory, useParams } from 'react-router-dom';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Routes } from '../../MenuBuilderRouter';
import { MenuResourceActions, PrincipalPermissions, ResourceType, SecurityScope, Verifier } from '@ready/security.core';

import MenuBuilderLayoutContent from '../MenuBuilderLayoutContent';
import SearchParamInput from '../../../components/SearchInput/SearchParamInput';
import Button from '../../../components/Button/Button';
import OverflowButton from '../../../components/OverflowButton/OverflowButton';
import useResponsiveBreakpoint from '../../../hooks/useResponsiveBreakpoint';
import InfoCard from '../../../components/InfoCard/InfoCard';
import InfoCardList from '../../../components/InfoCard/InfoCardList';
import InfoCardsSkeleton from '../../../components/InfoCard/InfoCardColumnSkeleton';
import EmptyResult from '../../../components/EmptyResult/EmptyResult';
import ItemGroupCard from './ItemGroupCard';

import useS3ImagesMap, { IImageS3Request } from '../../../hooks/useS3Images';
import MenuBuilderTab from '../../types/MenuBuilderTab.enum';
import { ContextParams } from '../../../types/ContextParams.interface';
import { AppState } from '../../../redux/initialStates/AppState';
import { LocationsThunks } from '../../redux/LocationsThunks';
import { listItemGroups } from '../../redux/ItemGroupsActions';
import { useSearchParams } from '../../../hooks/useSearchParams';
import styles from './ItemGroupsPage.module.scss';
import { selectLocationsState } from '../../redux/LocationsSelectors';

const mapStateToProps = (state: AppState) => ({
  permissions: state.session.permissions.permissionsList,
  itemGroupsList: state.menuBuilder.itemGroups.itemGroupsListPage,
});

const actionCreators = {
  listItemGroups,
};

const connector = connect(mapStateToProps, actionCreators);

export interface ItemGroupsPageProps extends ConnectedProps<typeof connector> {}

const ItemGroupsPage = (props: ItemGroupsPageProps) => {
  const { permissions, listItemGroups, itemGroupsList } = props;
  const { location } = useSelector(selectLocationsState);
  const dispatch = useDispatch();

  const [itemImgUrls, setItemImgUrls] = React.useState<{ [id: string]: string }>({});
  const { contextId: companyId, locationId } = useParams<ContextParams>();
  const history = useHistory();
  const { query, page } = useSearchParams();
  const { loading, itemGroups } = itemGroupsList;

  const hasManagePermission = Verifier.check(
    new PrincipalPermissions(permissions),
    SecurityScope.location,
    ResourceType.menu,
    MenuResourceActions.all,
    locationId
  );

  // get images from S3
  const items = itemGroups.items;
  const getS3ImagesMap = useS3ImagesMap();

  React.useEffect(() => {
    async function getImages(items: IMenuItemGroup[]) {
      const imagesRequest = items
        .filter((i) => i.thumbnailImageId)
        .map(
          (i) =>
            ({
              id: i._id,
              fileKey: i.thumbnailImageId,
            } as IImageS3Request)
        );
      const images = await getS3ImagesMap(imagesRequest);
      setItemImgUrls(images);
    }

    if (items.length > 0) {
      getImages(items);
    }
  }, [items, getS3ImagesMap]);

  // list item groups
  React.useEffect(() => {
    listItemGroups(companyId, locationId, query, page);
  }, [listItemGroups, companyId, locationId, query, page]);

  const fetchLocation = React.useCallback(
    (companyId: string, locationId: string) => {
      if (!location || location.id !== locationId) {
        dispatch(LocationsThunks.loadSelectedLocation({ companyId, locationId }));
      }
    },
    [location, dispatch]
  );

  React.useEffect(() => {
    fetchLocation(companyId, locationId);
  }, [fetchLocation, companyId, locationId]);

  const searchInputPlaceholder = 'Search Item Groups';
  const searchClassName = classNames(`${styles.search}`, 'search-input-full-width', 'white');

  const newItemGroupUrl = Routes.getNewItemGroupPage(companyId, locationId);
  const addItemGroupButton = {
    label: '+ New Item Group',
    onClick: (): void => history.push(newItemGroupUrl),
  };
  const controlOptions = [addItemGroupButton];

  const { isMobile } = useResponsiveBreakpoint();
  const skeletonInstances = 10;
  const skeletonRowsWithinInstances = isMobile ? 3 : 2;
  const skeletonColumns = isMobile ? 2 : 3;
  const skeletonColumnWidths = isMobile ? [15, 85] : [5, 70, 25];
  const emptyResultTitle = 'Create Your First Item Group';
  const emptyResultParagraph =
    'Make it easy for guests to browse your menu by grouping similar items together. For instance, you might create an item group for a drink that is available in different sizes.';

  return (
    <>
      <MenuBuilderLayoutContent
        companyId={companyId}
        locationId={locationId}
        locationName={location && location.id === locationId ? location.name : null}
        stockStatusOnly={!hasManagePermission}
        tab={MenuBuilderTab.ITEM_GROUPS}
      >
        <div className={styles.container}>
          <div className={styles.controls}>
            <div className={styles.searchContainer}>
              <div className={styles.search}>
                <SearchParamInput placeholder={searchInputPlaceholder} additionalClassName={searchClassName} />
              </div>
            </div>
            <div className={styles.buttons}>
              <Button label={addItemGroupButton.label} onClick={addItemGroupButton.onClick} />
            </div>
            <div className={styles.buttonsOverflow}>
              <OverflowButton options={controlOptions} />
            </div>
          </div>

          {!loading && !itemGroups.items.length && !query && !page ? (
            <EmptyResult title={emptyResultTitle} paragraph={emptyResultParagraph} />
          ) : (
            <InfoCardList paginationProps={itemGroups}>
              <>
                {loading ? (
                  <InfoCardsSkeleton
                    instances={skeletonInstances}
                    rowsWithinInstance={skeletonRowsWithinInstances}
                    columns={skeletonColumns}
                    infoCardColumnWidths={skeletonColumnWidths}
                  />
                ) : (
                  itemGroups.items.map((itemGroup, index) => (
                    <ItemGroupCard
                      linkTo={Routes.getItemGroupPage(companyId, locationId, itemGroup._id)}
                      imageUrl={itemImgUrls[itemGroup._id]}
                      name={itemGroup.displayName}
                      description={itemGroup.description}
                      quantity={itemGroup.items.length}
                      key={`itemGroup${index}`}
                      isSharedItemGroup={!!itemGroup?.parentTemplateId}
                    />
                  ))
                )}
                {!loading && !itemGroups.items.length && (
                  <InfoCard>
                    <p className={styles.noResultsMessage}>No Results Found</p>
                  </InfoCard>
                )}
              </>
            </InfoCardList>
          )}
        </div>
      </MenuBuilderLayoutContent>
    </>
  );
};

export default connector(ItemGroupsPage);
