import produce from 'immer';
import { Action } from '../../redux/types';
import { ITEMS_AND_MODS_ACTIONS } from './ItemsAndModsActions';
import { cachedMenuItem } from './CachedMenuItemState';

export const cachedMenuItemReducer = (state = cachedMenuItem, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ITEMS_AND_MODS_ACTIONS.SET_MENU_ITEM_CACHE:
        draft = { ...action.payload };
        return draft;

      default:
        return draft;
    }
  });
};
