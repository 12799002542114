import produce from 'immer';
import { serverAssignmentInitialState } from './ServerAssignmentState';
import { SERVER_ASSIGNMENT_ACTIONS } from './types';
import { Action } from '../../redux/types';
import { partitionTables, sortServerAssignments } from '../utils';

export const serverAssignmentReducer = (state = serverAssignmentInitialState, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SERVER_ASSIGNMENT_ACTIONS.INIT_SERVER_ASSIGNMENT_LOCATION_DETAILS:
        draft.loadingLocationDetails = serverAssignmentInitialState.loadingLocationDetails;
        draft.locationDetails = serverAssignmentInitialState.locationDetails;
        return draft;

      case SERVER_ASSIGNMENT_ACTIONS.SET_SERVER_ASSIGNMENT_LOCATION_DETAILS_LOADING:
        draft.loadingLocationDetails = action.payload;
        return draft;

      case SERVER_ASSIGNMENT_ACTIONS.SET_SERVER_ASSIGNMENT_LOCATION_DETAILS:
        draft.locationDetails = action.payload;
        return draft;

      case SERVER_ASSIGNMENT_ACTIONS.SET_REMOVE_SERVER_ASSIGNMENT_MODAL_OPEN:
        draft.removeModal = action.payload;
        return draft;

      /* Table assignment list reducers */

      case SERVER_ASSIGNMENT_ACTIONS.INIT_TABLE_LIST:
        draft.assignedTables = serverAssignmentInitialState.assignedTables;
        draft.unassignedTables = serverAssignmentInitialState.unassignedTables;
        return draft;

      case SERVER_ASSIGNMENT_ACTIONS.SET_TABLE_ASSIGNMENT_LIST:
        draft.tableAssignments = sortServerAssignments(action.payload);
        return draft;

      case SERVER_ASSIGNMENT_ACTIONS.SET_TABLE_LIST:
        const { tables, tableAssignments } = action.payload;

        // If tables were not passed, assume the existing list of tables should
        // be used instead
        const tablesToPartition = tables || draft.assignedTables.concat(draft.unassignedTables);

        const { assignedTables, unassignedTables } = partitionTables(tablesToPartition, tableAssignments);
        draft.assignedTables = assignedTables;
        draft.unassignedTables = unassignedTables;
        return draft;

      case SERVER_ASSIGNMENT_ACTIONS.SET_TABLE_LIST_LOADING:
        draft.tablesLoading = action.payload;
        return draft;

      case SERVER_ASSIGNMENT_ACTIONS.TABLE_LIST_SELECT_TABLE:
        let tableToSelect = draft.unassignedTables.find((table) => table.id === action.payload);

        if (!tableToSelect) {
          tableToSelect = draft.assignedTables.find((table) => table.id === action.payload);
        }

        if (tableToSelect) {
          tableToSelect.selected = true;
        }

        return draft;

      case SERVER_ASSIGNMENT_ACTIONS.TABLE_LIST_DESELECT_TABLE:
        let tableToDeselect = draft.unassignedTables.find((table) => table.id === action.payload);

        if (!tableToDeselect) {
          tableToDeselect = draft.assignedTables.find((table) => table.id === action.payload);
        }

        if (tableToDeselect) {
          tableToDeselect.selected = false;
        }

        return draft;

      case SERVER_ASSIGNMENT_ACTIONS.TABLE_LIST_DESELECT_ALL_TABLES:
        draft.unassignedTables = draft.unassignedTables.map((table) => ({
          ...table,
          selected: false,
        }));

        draft.assignedTables = draft.assignedTables.map((table) => ({
          ...table,
          selected: false,
        }));

        return draft;

      case SERVER_ASSIGNMENT_ACTIONS.SET_SERVER_ASSIGNMENT_PAGE_PROCESSING:
        draft.assignmentProcessing = action.payload;
        return draft;

      /* Table assignment modal reducers */

      case SERVER_ASSIGNMENT_ACTIONS.INIT_SERVER_ASSIGNMENT_ASSIGN_MODAL:
        draft.servers = serverAssignmentInitialState.servers;
        draft.selectedServer = serverAssignmentInitialState.selectedServer;
        draft.assignModalQuery = serverAssignmentInitialState.assignModalQuery;
        draft.assignModalMode = 'select';
        return draft;

      case SERVER_ASSIGNMENT_ACTIONS.SERVER_ASSIGNMENT_ASSIGN_MODAL_OPEN:
        draft.isAssignModalOpen = true;
        draft.servers.loading = true;
        draft.assignModalMode = action.payload.mode;
        draft.assignModalServerEdit = action.payload.employeeId;
        return draft;

      case SERVER_ASSIGNMENT_ACTIONS.SERVER_ASSIGNMENT_ASSIGN_MODAL_CLOSE:
        draft.isAssignModalOpen = false;
        return draft;

      case SERVER_ASSIGNMENT_ACTIONS.SET_SERVER_ASSIGNMENT_ASSIGN_MODAL_QUERY:
        draft.assignModalQuery = action.payload;
        return draft;

      case SERVER_ASSIGNMENT_ACTIONS.SET_SERVER_ASSIGNMENT_ASSIGN_MODAL_SELECTED_SERVER:
        draft.selectedServer = action.payload;
        return draft;

      /* Server reducers */

      case SERVER_ASSIGNMENT_ACTIONS.SET_SERVER_LIST:
        draft.servers.filtered = !!draft.assignModalQuery;
        draft.servers.items = action.payload.servers.results;
        draft.servers.page = action.payload.page;
        draft.servers.paginationAvailable = draft.servers.items.length < action.payload.servers.totalCount;
        return draft;

      case SERVER_ASSIGNMENT_ACTIONS.APPEND_SERVER_LIST:
        draft.servers.items.push(...action.payload.servers.results);
        draft.servers.page = action.payload.page;
        draft.servers.paginationAvailable = draft.servers.items.length < action.payload.servers.totalCount;
        return draft;

      case SERVER_ASSIGNMENT_ACTIONS.SET_SERVER_LIST_LOADING:
        draft.servers.loading = action.payload;
        return draft;

      case SERVER_ASSIGNMENT_ACTIONS.SET_SERVER_LIST_PAGINATION_LOADING:
        draft.servers.paginationLoading = action.payload;
        return draft;

      case SERVER_ASSIGNMENT_ACTIONS.SET_SERVER_ASSIGNMENT_MODAL_PROCESSING:
        draft.assignModalProcessing = action.payload;
        return draft;

      default:
        return draft;
    }
  });
};
