import React from 'react';

import styles from './UsersAddLocationsDialogListItem.module.scss';

import { ILocationToShow } from '@ready/dashboardv2api.contracts';

export type UsersAddLocationsDialogListItemProps = {
  location: ILocationToShow;
  controls: JSX.Element;
};

const UsersAddLocationsDialogListItem = (props: UsersAddLocationsDialogListItemProps): JSX.Element => {
  const { location, controls } = props;

  return (
    <div className={styles.listItem} key={location._id}>
      <div className={styles.name}>
        <i className='icon-dashboard-icons_location'></i>
        {location.name}
      </div>
      <div className={styles.control}>{controls}</div>
    </div>
  );
};

export default UsersAddLocationsDialogListItem;
