import { combineReducers } from 'redux';
import { itemGroupReducer } from 'sharedMenuItems/pages/itemGroups/itemGroupReducer';
import { sharedModiferGroupReducer } from 'sharedMenuItems/pages/modifierGroups/modifierGroupReducer';
import { createEditSharedMenuItemSlice } from './createEditItem/createEditItemSlice';
import { assignLocationsSlice } from './editAssignedLocations/assignLocationsSlice';
import { itemsAndModsListSlice } from '../pages/itemsAndMods/redux/slice';
import { tableSlice } from './table/tableSlice';
import { itemAndModsAssignedLocationsSlice } from 'sharedMenuItems/pages/itemAssignedLocations/redux/slice';
import { addMenuItemsDialogSlice } from 'sharedMenuItems/pages/itemsAndMods/components/AddMenuItemsDialog/redux/slice';
import { menuConfigsListSlice } from 'sharedMenuItems/pages/menuConfigs/list/redux/slice';
import { locationSettingsReducer } from 'sharedMenuItems/pages/locationSettings/reducer';
import { menuConfigLocationSlice } from 'sharedMenuItems/pages/locations/redux/slice';

const sharedMenuItemReducer = combineReducers({
  locationSettings: locationSettingsReducer,

  menuConfigs: menuConfigsListSlice.reducer,
  // todo: combine itemAndMods reducers?
  itemsAndModsDialog: addMenuItemsDialogSlice.reducer,
  itemsAndModsList: itemsAndModsListSlice.reducer,
  item: createEditSharedMenuItemSlice.reducer,
  itemAssignedLocationsList: itemAndModsAssignedLocationsSlice.reducer,

  locations: assignLocationsSlice.reducer,
  itemGroups: itemGroupReducer,
  modifierGroups: sharedModiferGroupReducer,
  table: tableSlice.reducer,

  menuConfigLocations: menuConfigLocationSlice.reducer,
});

export { sharedMenuItemReducer };
