import produce from 'immer';
import { Action } from '../../redux/types';
import { ADMIN_FORM_ACTION_TYPES } from './AdminFormActions';
import { adminForm } from './AdminFormState';

export const adminFormReducer = (state = adminForm, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ADMIN_FORM_ACTION_TYPES.UPDATE_FORM_EMAIL:
        draft.adminFormData.email = action.payload;
        return draft;

      case ADMIN_FORM_ACTION_TYPES.UPDATE_FORM_GIVEN_NAME:
        draft.adminFormData.firstName = action.payload;
        return draft;

      case ADMIN_FORM_ACTION_TYPES.UPDATE_FORM_FAMILY_NAME:
        draft.adminFormData.lastName = action.payload;
        return draft;

      case ADMIN_FORM_ACTION_TYPES.SUBMIT_FORM_BEGIN:
        draft.loading = true;
        draft.adminIdTransaction = '';
        return draft;

      case ADMIN_FORM_ACTION_TYPES.SUBMIT_FORM_SUCCESS:
        draft.adminFormData = {
          email: '',
          firstName: '',
          lastName: '',
          enabled: false,
          id: '',
          uid: '',
        };
        draft.adminIdTransaction = action.payload.id;
        draft.loading = false;
        draft.error = false;
        return draft;

      case ADMIN_FORM_ACTION_TYPES.SUBMIT_FORM_ERROR:
        draft.loading = false;
        draft.error = true;
        return draft;

      case ADMIN_FORM_ACTION_TYPES.RESET_ADMIN_FORM:
        draft = adminForm;
        return draft;

      case ADMIN_FORM_ACTION_TYPES.UPDATE_FORM_EMAIL_ERROR:
        draft.formValidation.emailError = action.payload;
        return draft;

      case ADMIN_FORM_ACTION_TYPES.UPDATE_FORM_GIVEN_NAME_ERROR:
        draft.formValidation.givenNameError = action.payload;
        return draft;

      case ADMIN_FORM_ACTION_TYPES.UPDATE_FORM_FAMILY_NAME_ERROR:
        draft.formValidation.familyNameError = action.payload;
        return draft;

      case ADMIN_FORM_ACTION_TYPES.RESET_ADMIN_FORM_ERRORS:
        draft.formValidation.emailError = false;
        draft.formValidation.givenNameError = false;
        draft.formValidation.familyNameError = false;
        return draft;

      case ADMIN_FORM_ACTION_TYPES.INITIALIZE_ADMIN_FORM:
        draft.adminFormData = action.payload;
        return draft;

      case ADMIN_FORM_ACTION_TYPES.RESET_ADMIN_ID_TRANSACTION:
        draft.adminIdTransaction = '';
        return draft;

      default:
        return draft;
    }
  });
};
