import React from 'react';
import { IEmbeddedModifierItem, ITemplateEmbeddedModifierItem, Visibility } from '@ready/menu.core';
import styles from './ModifierGroupOptionsListItem.module.scss';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';
import IconButton from '../../../components/IconButton/IconButton';
import { IModifierOptionsFormValidation } from '../../redux/ModifierGroupsState';
import OverflowButton from '../../../components/OverflowButton/OverflowButton';
import NestedModifierGroups from './NestedModifierGroups';
import ModifierGroupOption from './ModifierGroupOption';
import { compositeStyles } from '../../../utils/cssUtils';

export interface ModifierGroupOptionsListItemProps {
  companyId: string;
  locationId?: string;
  modifierGroupId: string;
  modifierOption: IEmbeddedModifierItem | ITemplateEmbeddedModifierItem;
  modifierOptionsValidation?: IModifierOptionsFormValidation;
  defaultMaxValue: number | null;
  allowMultiplesOfEachOption: boolean;
  allowNestedMods: boolean;
  allowExpansion: boolean;
  processing: boolean;
  menuItemId?: string;
  compactView?: boolean;
  editing?: boolean;
  dragHandle?: JSX.Element;
  isReadonly?: boolean;
  onDelete?: (id: string) => void;
  onVisibilityChanged?: (id: string, visible: boolean) => void;
  onAddNestedModClick?: () => void;
  onRemoveNestedModClick?: (id: string) => void;
  onNestedModMoved?: (sourceIndex: number, destinationIndex: number) => void;
  onChangeMaxValue?: (id: string, constraints: number) => void;
  onDefaultChanged?: (id: string, isDefault: boolean) => void;
}

const ModifierGroupOptionsListItem = (props: ModifierGroupOptionsListItemProps): JSX.Element => {
  const {
    companyId,
    locationId,
    modifierGroupId,
    modifierOption,
    defaultMaxValue,
    allowMultiplesOfEachOption,
    allowNestedMods,
    allowExpansion,
    processing,
    menuItemId,
    compactView = false,
    editing = false,
    dragHandle,
    modifierOptionsValidation,
    onDelete,
    onVisibilityChanged,
    onAddNestedModClick,
    onRemoveNestedModClick,
    onNestedModMoved,
    onChangeMaxValue,
    onDefaultChanged,
    isReadonly = false,
  } = props;

  const emptyMethod = () => {};
  const standardControlOptions = [
    {
      label: modifierOption.visibility === Visibility.visible ? 'Hide Option' : 'Show Option',
      loading: processing,
      onClick: !onVisibilityChanged
        ? emptyMethod
        : () => {
            onVisibilityChanged(modifierOption.itemId, modifierOption.visibility === Visibility.hidden);
          },
    },
    {
      label: 'Remove Option',
      loading: processing,
      onClick: !onDelete
        ? emptyMethod
        : () => {
            onDelete(modifierOption.itemId);
          },
    },
  ];
  const modifierControlOptions = allowNestedMods
    ? [
        {
          label: 'Add Nested Modifier',
          loading: processing,
          onClick: !onAddNestedModClick
            ? emptyMethod
            : () => {
                onAddNestedModClick();
              },
        },
        ...standardControlOptions,
      ]
    : standardControlOptions;

  return (
    <div className={styles.main}>
      <div
        className={compositeStyles(
          [{ container: editing }, { containerLinkable: !editing }, { containerCompact: compactView }],
          styles
        )}
      >
        {editing && dragHandle}

        <div className={styles.mainContent}>
          <ModifierGroupOption
            companyId={companyId}
            locationId={locationId}
            rootModifierGroupId={modifierGroupId}
            option={modifierOption}
            allowMultiplesOfEachOption={allowMultiplesOfEachOption}
            defaultMaxValue={defaultMaxValue}
            processing={processing}
            editing={editing}
            menuItemId={menuItemId}
            compactView={compactView}
            nestingLevel={0}
            allowExpansion={!editing && allowExpansion}
            externalContainer={editing}
            modifierOptionsValidation={modifierOptionsValidation}
            isReadonly={isReadonly}
            control={
              <>
                {!editing && !!onVisibilityChanged && (
                  <IconButton
                    onClick={() => {
                      onVisibilityChanged(modifierOption.itemId, modifierOption.visibility === Visibility.hidden);
                    }}
                    loading={processing}
                    additionalClassName={modifierOption.visibility === Visibility.visible ? styles.hide : styles.show}
                  >
                    {modifierOption.visibility === Visibility.visible ? (
                      <TextIcon icon={Icon.EyeOpen} />
                    ) : (
                      <TextIcon icon={Icon.EyeClosed} />
                    )}
                  </IconButton>
                )}

                {editing && (
                  <div className={styles.dropdown}>
                    <OverflowButton
                      options={modifierOption.itemType === 'option' ? modifierControlOptions : standardControlOptions}
                    />
                  </div>
                )}
              </>
            }
            onChangeMaxValue={onChangeMaxValue}
            onDefaultChanged={onDefaultChanged}
          />
        </div>
      </div>

      {editing && (
        <NestedModifierGroups
          companyId={companyId}
          locationId={locationId}
          nestedGroups={modifierOption.nestedGroups}
          onRemoveNestedModifierGroup={onRemoveNestedModClick}
          onMoveNestedModifierGroup={onNestedModMoved}
        />
      )}
    </div>
  );
};

export default ModifierGroupOptionsListItem;
