import { ICheckPageState } from './initialState';
import { ICheckDetails } from '@ready/dashboardv2api.contracts';

export const fetchCheckThunkReducers = {
  pending: (state: ICheckPageState) => {
    state.loading = true;
  },
  fulfilled: (state: ICheckPageState, action: { payload: ICheckDetails }) => {
    state.loading = false;
    state.data = action.payload;
  },
  rejected: (state: ICheckPageState) => {
    state.loading = false;
    const errorMessage = 'Unable to retrieve check details.';
    if (state.errors && state.errors.length > 0) {
      state.errors.push(errorMessage);
    } else {
      state.errors = [errorMessage];
    }
  },
};

export const getFetchRetryPOSPaymentReducers = (message: string) => ({
  pending: (state: ICheckPageState) => {
    state.retryPOSPaymentLoading = true;
  },
  fulfilled: (state: ICheckPageState) => {
    state.retryPOSPaymentLoading = false;
  },
  rejected: (state: ICheckPageState) => {
    state.retryPOSPaymentLoading = false;
    const errorMessage = message;
    if (state.errors && state.errors.length > 0) {
      state.errors.push(errorMessage);
    } else {
      state.errors = [errorMessage];
    }
  },
});

export const manualEnterPOSPaymentReducer = getFetchRetryPOSPaymentReducers(
  'Unable to retrieve manual payment complete confirmation.'
);

export const retryPOSPaymentReducer = getFetchRetryPOSPaymentReducers('Unable to retrieve retry POS payment');
