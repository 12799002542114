import MenuBuilderService from '../services/MenuBuilderService';
import { IEmbeddedModifierItem, IMenuItem, IModifierGroup, Visibility } from '@ready/menu.core';
import { set200Toast, toastErrorState, pageErrorState } from '../../redux/actions/uiActions/responseStateActions';
import {
  IModifierGroupFormValidation,
  IModifierOptionsFormValidation,
  newModifierGroup,
  newModifierGroupRemoteValidation,
  newModifierGroupValidation,
  newModifierOptionValidation,
} from './ModifierGroupsState';
import { setFormIsDirty } from '../../redux/actions/uiActions/formStateActions';

export const MODIFIER_GROUPS_ACTIONS = {
  LOAD_MENU_BUILDER_MODIFIER_GROUPS_BEGIN: 'LOAD_MENU_BUILDER_MODIFIER_GROUPS_BEGIN',
  LOAD_MENU_BUILDER_MODIFIER_GROUPS_SUCCESS: 'LOAD_MENU_BUILDER_MODIFIER_GROUPS_SUCCESS',
  LOAD_MENU_BUILDER_MODIFIER_GROUPS_ERROR: 'LOAD_MENU_BUILDER_MODIFIER_GROUPS_ERROR',

  LOAD_MENU_BUILDER_MODIFIER_GROUP_BEGIN: 'LOAD_MENU_BUILDER_MODIFIER_GROUP_BEGIN',
  LOAD_MENU_BUILDER_MODIFIER_GROUP_SUCCESS: 'LOAD_MENU_BUILDER_MODIFIER_GROUP_SUCCESS',
  LOAD_MENU_BUILDER_MODIFIER_GROUP_ERROR: 'LOAD_MENU_BUILDER_MODIFIER_GROUP_ERROR',

  STORE_MENU_BUILDER_MODIFIER_GROUPS_FILTERS: 'STORE_MENU_BUILDER_MODIFIER_GROUPS_FILTERS',

  DELETE_MENU_BUILDER_MODIFIER_GROUP_REQUESTED: 'DELETE_MENU_BUILDER_MODIFIER_GROUP_REQUESTED',
  DELETE_MENU_BUILDER_MODIFIER_GROUP_BEGIN: 'DELETE_MENU_BUILDER_MODIFIER_GROUP_BEGIN',
  DELETE_MENU_BUILDER_MODIFIER_GROUP_SUCCESS: 'DELETE_MENU_BUILDER_MODIFIER_GROUP_SUCCESS',
  DELETE_MENU_BUILDER_MODIFIER_GROUP_ERROR: 'DELETE_MENU_BUILDER_MODIFIER_GROUP_ERROR',

  PREPARE_MENU_BUILDER_NEW_MODIFIER_GROUP: 'PREPARE_MENU_BUILDER_NEW_MODIFIER_GROUP',
  CANCEL_MENU_BUILDER_NEW_MODIFIER_GROUP: 'CANCEL_MENU_BUILDER_NEW_MODIFIER_GROUP',

  RESET_MENU_BUILDER_MODIFIER_GROUP_FORM: 'RESET_MENU_BUILDER_MODIFIER_GROUP_FORM',
  UPDATE_MENU_BUILDER_MODIFIER_GROUP_NAME: 'UPDATE_MENU_BUILDER_MODIFIER_GROUP_NAME',
  UPDATE_MENU_BUILDER_MODIFIER_GROUP_DISPLAY_NAME: 'UPDATE_MENU_BUILDER_MODIFIER_GROUP_DISPLAY_NAME',
  UPDATE_MENU_BUILDER_MODIFIER_GROUP_GUEST_INSTRUCTIONS: 'UPDATE_MENU_BUILDER_MODIFIER_GROUP_GUEST_INSTRUCTIONS',
  UPDATE_MENU_BUILDER_MODIFIER_GROUP_MINIMUM: 'UPDATE_MENU_BUILDER_MODIFIER_GROUP_MINIMUM',
  UPDATE_MENU_BUILDER_MODIFIER_GROUP_MAXIMUM: 'UPDATE_MENU_BUILDER_MODIFIER_GROUP_MAXIMUM',
  VALIDATE_MENU_BUILDER_MODIFIER_GROUP: 'VALIDATE_MENU_BUILDER_MODIFIER_GROUP',
  VALIDATE_MENU_BUILDER_MODIFIER_OPTION: 'VALIDATE_MENU_BUILDER_MODIFIER_OPTION',
  SAVE_MENU_BUILDER_MODIFIER_GROUP_BEGIN: 'SAVE_MENU_BUILDER_MODIFIER_GROUP_BEGIN',
  SAVE_MENU_BUILDER_MODIFIER_GROUP_SUCCESS: 'SAVE_MENU_BUILDER_MODIFIER_GROUP_SUCCESS',
  SAVE_MENU_BUILDER_MODIFIER_GROUP_ERROR: 'SAVE_MENU_BUILDER_MODIFIER_GROUP_ERROR',

  EDIT_MENU_BUILDER_MODIFIER_GROUP: 'EDIT_MENU_BUILDER_MODIFIER_GROUP',

  ADD_OPTION_TO_MODIFIER_GROUP: 'ADD_OPTION_TO_MODIFIER_GROUP',
  REMOVE_OPTION_FROM_MODIFIER_GROUP: 'REMOVE_OPTION_FROM_MODIFIER_GROUP',
  MOVE_MODIFIER_OPTION_IN_MODIFIER_GROUP: 'MOVE_MODIFIER_OPTION_IN_MODIFIER_GROUP',
  UPDATE_MODIFIER_OPTION_VISIBILITY_IN_MODIFIER_GROUP: 'UPDATE_MODIFIER_OPTION_VISIBILITY_IN_MODIFIER_GROUP',
  UPDATE_MODIFIER_OPTION_MAX_VALUE_IN_MODIFIER_GROUP: 'UPDATE_MODIFIER_OPTION_MAX_VALUE_IN_MODIFIER_GROUP',
  UPDATE_MODIFIER_OPTION_IS_DEFAULT_IN_MODIFIER_GROUP: 'UPDATE_MODIFIER_OPTION_IS_DEFAULT_IN_MODIFIER_GROUP',
  UPDATE_ALLOW_MULTIPLES_MODIFIER_OPTION_IN_MODIFIER_GROUP: 'UPDATE_ALLOW_MULTIPLES_MODIFIER_OPTION_IN_MODIFIER_GROUP',

  UPDATE_MENU_BUILDER_MODIFIER_GROUP_SUCCESS: 'UPDATE_MENU_BUILDER_MODIFIER_GROUP_SUCCESS',

  PREPARE_MENU_BUILDER_MODIFIER_GROUPS_MODAL: 'PREPARE_MENU_BUILDER_MODIFIER_GROUPS_MODAL',
  SELECT_MODIFIER_GROUP_IN_MODIFIER_GROUPS_MODAL: 'SELECT_MODIFIER_GROUP_IN_MODIFIER_GROUPS_MODAL',
  DESELECT_MODIFIER_GROUP_IN_MODIFIER_GROUPS_MODAL: 'DESELECT_MODIFIER_GROUP_IN_MODIFIER_GROUPS_MODAL',
  ADD_MENU_BUILDER_NESTED_MODIFIER_GROUP_TO_MODIFIER_OPTION:
    'ADD_MENU_BUILDER_NESTED_MODIFIER_GROUP_TO_MODIFIER_OPTION',
  REMOVE_MENU_BUILDER_NESTED_MODIFIER_GROUP_FROM_MODIFIER_OPTION:
    'REMOVE_MENU_BUILDER_NESTED_MODIFIER_GROUP_FROM_MODIFIER_OPTION',
  SET_MENU_BUILDER_MODIFIER_GROUPS_MODAL_QUERY: 'SET_MENU_BUILDER_MODIFIER_GROUPS_MODAL_QUERY',
  MOVE_MODIFIER_GROUP_IN_MODIFIER_OPTION: 'MOVE_MODIFIER_GROUP_IN_MODIFIER_OPTION',

  LOAD_MENU_BUILDER_MODIFIER_GROUPS_FOR_INFINITE_SCROLL_BEGIN:
    'LOAD_MENU_BUILDER_MODIFIER_GROUPS_FOR_INFINITE_SCROLL_BEGIN',
  LOAD_MENU_BUILDER_MODIFIER_GROUPS_FOR_INFINITE_SCROLL_SUCCESS:
    'LOAD_MENU_BUILDER_MODIFIER_GROUPS_FOR_INFINITE_SCROLL_SUCCESS',
  LOAD_MENU_BUILDER_MODIFIER_GROUPS_FOR_INFINITE_SCROLL_ERROR:
    'LOAD_MENU_BUILDER_MODIFIER_GROUPS_FOR_INFINITE_SCROLL_ERROR',
  LOAD_MORE_MENU_BUILDER_MODIFIER_GROUPS_FOR_INFINITE_SCROLL_BEGIN:
    'LOAD_MORE_MENU_BUILDER_MODIFIER_GROUPS_FOR_INFINITE_SCROLL_BEGIN',
  LOAD_MORE_MENU_BUILDER_MODIFIER_GROUPS_FOR_INFINITE_SCROLL_SUCCESS:
    'LOAD_MORE_MENU_BUILDER_MODIFIER_GROUPS_FOR_INFINITE_SCROLL_SUCCESS',
  LOAD_MORE_MENU_BUILDER_MODIFIER_GROUPS_FOR_INFINITE_SCROLL_NO_RESULTS:
    'LOAD_MORE_MENU_BUILDER_MODIFIER_GROUPS_FOR_INFINITE_SCROLL_NO_RESULTS',
  LOAD_MORE_MENU_BUILDER_MODIFIER_GROUPS_FOR_INFINITE_SCROLL_ERROR:
    'LOAD_MORE_MENU_BUILDER_MODIFIER_GROUPS_FOR_INFINITE_SCROLL_ERROR',
};

export const loadModifierGroups =
  (companyId: string, locationId: string, query: string = '', page: number = 1) =>
  async (dispatch: any) => {
    dispatch({
      type: MODIFIER_GROUPS_ACTIONS.LOAD_MENU_BUILDER_MODIFIER_GROUPS_BEGIN,
    });
    try {
      const results = await MenuBuilderService.getModifierGroups(companyId, locationId, query, page);
      dispatch({
        type: MODIFIER_GROUPS_ACTIONS.LOAD_MENU_BUILDER_MODIFIER_GROUPS_SUCCESS,
        payload: results,
      });
    } catch (error) {
      dispatch({
        type: MODIFIER_GROUPS_ACTIONS.LOAD_MENU_BUILDER_MODIFIER_GROUPS_ERROR,
      });
      dispatch(pageErrorState(error.status, error.message));
    }
  };

export const storeModifierGroupsFilters = (query: string = '', page: number = 1) => ({
  type: MODIFIER_GROUPS_ACTIONS.STORE_MENU_BUILDER_MODIFIER_GROUPS_FILTERS,
  payload: { query, page },
});

export const showDeleteModifierGroupModal = (show: boolean, modifierGroup?: IModifierGroup) => ({
  type: MODIFIER_GROUPS_ACTIONS.DELETE_MENU_BUILDER_MODIFIER_GROUP_REQUESTED,
  payload: { show, modifierGroup: modifierGroup },
});

export const deleteModifierGroup =
  (companyId: string, locationId: string, modifierGroup: IModifierGroup) => async (dispatch: any) => {
    dispatch({
      type: MODIFIER_GROUPS_ACTIONS.DELETE_MENU_BUILDER_MODIFIER_GROUP_BEGIN,
    });
    try {
      await MenuBuilderService.deleteModifierGroupById(companyId, locationId, modifierGroup._id);
      dispatch(set200Toast('Modifier group deleted.'));
      dispatch({
        type: MODIFIER_GROUPS_ACTIONS.DELETE_MENU_BUILDER_MODIFIER_GROUP_SUCCESS,
      });
    } catch (error) {
      dispatch(toastErrorState(error.status, error.message));
      dispatch({
        type: MODIFIER_GROUPS_ACTIONS.DELETE_MENU_BUILDER_MODIFIER_GROUP_ERROR,
      });
    }
  };

export const cancelNewModifierGroup = () => async (dispatch: any) => {
  dispatch({
    type: MODIFIER_GROUPS_ACTIONS.CANCEL_MENU_BUILDER_NEW_MODIFIER_GROUP,
  });
  dispatch(setFormIsDirty(false));
};

export const resetModifierGroupForm = (modifierGroup?: IModifierGroup) => async (dispatch: any) => {
  dispatch({
    type: MODIFIER_GROUPS_ACTIONS.RESET_MENU_BUILDER_MODIFIER_GROUP_FORM,
    payload: modifierGroup || newModifierGroup(),
  });
  dispatch(setFormIsDirty(false));
};

export const updateModifierGroupName = (value: string) => ({
  type: MODIFIER_GROUPS_ACTIONS.UPDATE_MENU_BUILDER_MODIFIER_GROUP_NAME,
  payload: value,
});

export const updateModifierGroupDisplayName = (value: string) => ({
  type: MODIFIER_GROUPS_ACTIONS.UPDATE_MENU_BUILDER_MODIFIER_GROUP_DISPLAY_NAME,
  payload: value,
});

export const updateModifierGroupGuestInstructions = (value: string) => ({
  type: MODIFIER_GROUPS_ACTIONS.UPDATE_MENU_BUILDER_MODIFIER_GROUP_GUEST_INSTRUCTIONS,
  payload: value,
});

export const updateModifierGroupMinimum = (value: number) => ({
  type: MODIFIER_GROUPS_ACTIONS.UPDATE_MENU_BUILDER_MODIFIER_GROUP_MINIMUM,
  payload: value,
});

export const updateModifierGroupMaximum = (value: number) => ({
  type: MODIFIER_GROUPS_ACTIONS.UPDATE_MENU_BUILDER_MODIFIER_GROUP_MAXIMUM,
  payload: value,
});

export const validateModifierGroup = (modifierGroup: IModifierGroup) => async (dispatch: any) => {
  const validation: IModifierGroupFormValidation = newModifierGroupValidation();
  if (!modifierGroup.name || modifierGroup.name === '') {
    validation.name = {
      hasError: true,
      errorMessage: 'Group name is required.',
    };
  }
  if (!!modifierGroup.constraints.min && modifierGroup.constraints.min < 0) {
    validation.min = {
      hasError: true,
      errorMessage: 'Minimum cannot be less than 0.',
    };
  }
  if (!!modifierGroup.constraints.max && modifierGroup.constraints.max < 0) {
    validation.max = {
      hasError: true,
      errorMessage: 'Maximum cannot be less than 0.',
    };
  }
  if (
    !validation.min.hasError &&
    !!modifierGroup.constraints.min &&
    !!modifierGroup.constraints.max &&
    modifierGroup.constraints.min > modifierGroup.constraints.max
  ) {
    validation.constraints = {
      hasError: true,
      errorMessage: 'Minimum cannot be greater than maximum.',
    };
  }

  const modifierOptions = applyModifierOptionsValidation(modifierGroup);

  validation.validated =
    !validation.name.hasError &&
    !validation.constraints.hasError &&
    !validation.min.hasError &&
    !validation.max.hasError &&
    modifierOptions.validated;
  dispatch({
    type: MODIFIER_GROUPS_ACTIONS.VALIDATE_MENU_BUILDER_MODIFIER_GROUP,
    payload: validation,
  });

  dispatch({
    type: MODIFIER_GROUPS_ACTIONS.VALIDATE_MENU_BUILDER_MODIFIER_OPTION,
    payload: modifierOptions,
  });
};

export const validateModifierOption = (modifierGroup: IModifierGroup) => async (dispatch: any) => {
  const validation = applyModifierOptionsValidation(modifierGroup);
  dispatch({
    type: MODIFIER_GROUPS_ACTIONS.VALIDATE_MENU_BUILDER_MODIFIER_OPTION,
    payload: validation,
  });
};

const applyModifierOptionsValidation = (modifierGroup: IModifierGroup): IModifierOptionsFormValidation => {
  const validation = newModifierOptionValidation();
  if (
    modifierGroup.constraints.max !== undefined &&
    modifierGroup.constraints.max > 0 &&
    modifierGroup.options.length > 0
  ) {
    if (modifierGroup.options.filter((opt) => !!opt.isDefault).length > modifierGroup.constraints.max) {
      validation.isDefault = {
        hasError: true,
        errorMessage:
          'The number of options set as Default exceeds the Required Selection Maximum for this modifier group. Update your options below to include fewer defaults, or increase your Required Selection Maximum.',
      };
    }

    if (modifierGroup.allowMultiplesOfEachOption) {
      const ids = modifierGroup.options
        .filter(
          (option) =>
            option.constraints &&
            modifierGroup.constraints.max &&
            option.constraints.max > modifierGroup.constraints.max
        )
        .map((x) => x.itemId);

      if (ids.length > 0) {
        validation.constraints = {
          options: ids,
          hasError: true,
          errorMessage: 'Exceeds Group Max',
        };
      }
    }

    const negativeValueOptions =
      modifierGroup.options.filter((o) => !!o.constraints && o.constraints.max < 0).map((o) => o.itemId) || [];

    if (negativeValueOptions.length > 0) {
      validation.noNegativeValue = {
        options: negativeValueOptions,
        hasError: true,
        errorMessage: 'Maximum cannot be less than 0.',
      };
    }
  }

  validation.validated =
    !validation.isDefault.hasError && !validation.constraints.hasError && !validation.noNegativeValue.hasError;

  return validation;
};

export const saveModifierGroup =
  (companyId: string, locationId: string, modifierGroup: IModifierGroup, silentSuccess?: boolean) =>
  async (dispatch: any) => {
    dispatch({
      type: MODIFIER_GROUPS_ACTIONS.SAVE_MENU_BUILDER_MODIFIER_GROUP_BEGIN,
    });
    try {
      if (modifierGroup._id) {
        const updatedModifierGroup = await MenuBuilderService.updateModifierGroup(companyId, locationId, modifierGroup);
        dispatch({
          type: MODIFIER_GROUPS_ACTIONS.UPDATE_MENU_BUILDER_MODIFIER_GROUP_SUCCESS,
          payload: updatedModifierGroup,
        });
        if (!silentSuccess) {
          dispatch(set200Toast());
        }
      } else {
        const newModifierGroup = await MenuBuilderService.createModifierGroup(companyId, locationId, modifierGroup);
        dispatch({
          type: MODIFIER_GROUPS_ACTIONS.SAVE_MENU_BUILDER_MODIFIER_GROUP_SUCCESS,
          payload: newModifierGroup,
        });
        if (!silentSuccess) {
          dispatch(set200Toast('Success! New modifier group created!'));
        }
      }
      dispatch(setFormIsDirty(false));
    } catch (error) {
      if (error.status === 400) {
        dispatch({
          type: MODIFIER_GROUPS_ACTIONS.VALIDATE_MENU_BUILDER_MODIFIER_GROUP,
          payload: newModifierGroupValidation(),
        });
        dispatch({
          type: MODIFIER_GROUPS_ACTIONS.SAVE_MENU_BUILDER_MODIFIER_GROUP_ERROR,
          payload: {
            hasError: true,
            errorMessage: error.message,
          },
        });
      } else {
        dispatch({
          type: MODIFIER_GROUPS_ACTIONS.SAVE_MENU_BUILDER_MODIFIER_GROUP_ERROR,
          payload: newModifierGroupRemoteValidation(),
        });
        dispatch(toastErrorState(error.status, error.message));
      }
    }
  };

export const prepareNewModifierGroup = () => async (dispatch: any) => {
  dispatch({
    type: MODIFIER_GROUPS_ACTIONS.PREPARE_MENU_BUILDER_NEW_MODIFIER_GROUP,
    payload: newModifierGroup(),
  });
  dispatch(setFormIsDirty(true));
};

export const loadModifierGroup =
  (companyId: string, locationId: string, modifierGroupId: string) => async (dispatch: any) => {
    dispatch({
      type: MODIFIER_GROUPS_ACTIONS.LOAD_MENU_BUILDER_MODIFIER_GROUP_BEGIN,
    });
    try {
      const modifierGroup = await MenuBuilderService.getModifierGroup(companyId, locationId, modifierGroupId);
      dispatch({
        type: MODIFIER_GROUPS_ACTIONS.LOAD_MENU_BUILDER_MODIFIER_GROUP_SUCCESS,
        payload: modifierGroup,
      });
    } catch (error) {
      dispatch({
        type: MODIFIER_GROUPS_ACTIONS.LOAD_MENU_BUILDER_MODIFIER_GROUP_ERROR,
      });
      dispatch(pageErrorState(error.status, error.message));
    }
  };

export const startEditingModifierGroup = () => async (dispatch: any) => {
  dispatch({
    type: MODIFIER_GROUPS_ACTIONS.EDIT_MENU_BUILDER_MODIFIER_GROUP,
  });
  dispatch(setFormIsDirty(true));
};

export const addOptionToModifierGroup = (option: IMenuItem) => ({
  type: MODIFIER_GROUPS_ACTIONS.ADD_OPTION_TO_MODIFIER_GROUP,
  payload: MenuBuilderService.convertIMenuItemToIEmbeddedMenuItem(option),
});

export const removeOptionFromModifierGroup = (optionId: string) => ({
  type: MODIFIER_GROUPS_ACTIONS.REMOVE_OPTION_FROM_MODIFIER_GROUP,
  payload: optionId,
});

export const moveModifierOptionInModifierGroup =
  (sourceIndex: number, destinationIndex: number) => async (dispatch: any) => {
    dispatch({
      type: MODIFIER_GROUPS_ACTIONS.MOVE_MODIFIER_OPTION_IN_MODIFIER_GROUP,
      payload: {
        sourceIndex,
        destinationIndex,
      },
    });
    dispatch(setFormIsDirty(true));
  };

export const selectModifierGroupInModifierGroupsModal = (modifierGroupId: string) => ({
  type: MODIFIER_GROUPS_ACTIONS.SELECT_MODIFIER_GROUP_IN_MODIFIER_GROUPS_MODAL,
  payload: modifierGroupId,
});

export const deselectModifierGroupInModifierGroupsModal = (modifierGroupId: string) => ({
  type: MODIFIER_GROUPS_ACTIONS.DESELECT_MODIFIER_GROUP_IN_MODIFIER_GROUPS_MODAL,
  payload: modifierGroupId,
});

export const updateModifierOptionVisibility = (optionId: string, visible: boolean) => async (dispatch: any) => {
  dispatch({
    type: MODIFIER_GROUPS_ACTIONS.UPDATE_MODIFIER_OPTION_VISIBILITY_IN_MODIFIER_GROUP,
    payload: {
      id: optionId,
      visibility: visible ? Visibility.visible : Visibility.hidden,
    },
  });
  dispatch(setFormIsDirty(true));
};

export const updateModifierOptionVisibilityAndSave = (
  companyId: string,
  locationId: string,
  modifierGroup: IModifierGroup,
  optionId: string,
  visible: boolean
) => {
  const modifiedOptions = modifierGroup.options.map((option) => {
    if (option.itemId !== optionId) {
      return option;
    }
    return {
      ...option,
      visibility: visible ? Visibility.visible : Visibility.hidden,
    };
  });
  const updatedModifierGroup = { ...modifierGroup, options: modifiedOptions };
  return saveModifierGroup(companyId, locationId, updatedModifierGroup, true);
};

export const updateModifierOptionIsDefault = (optionId: string, isDefault: boolean) => async (dispatch: any) => {
  dispatch({
    type: MODIFIER_GROUPS_ACTIONS.UPDATE_MODIFIER_OPTION_IS_DEFAULT_IN_MODIFIER_GROUP,
    payload: {
      id: optionId,
      isDefault: isDefault,
    },
  });
  dispatch(setFormIsDirty(true));
};

export const updateModifierConstraintsValue = (optionId: string, max: number) => async (dispatch: any) => {
  dispatch({
    type: MODIFIER_GROUPS_ACTIONS.UPDATE_MODIFIER_OPTION_MAX_VALUE_IN_MODIFIER_GROUP,
    payload: {
      id: optionId,
      max: max,
    },
  });
  dispatch(setFormIsDirty(true));
};

export const updateMultiplesModifierOptions = (allowMultiplesOfEachOption: boolean) => async (dispatch: any) => {
  dispatch({
    type: MODIFIER_GROUPS_ACTIONS.UPDATE_ALLOW_MULTIPLES_MODIFIER_OPTION_IN_MODIFIER_GROUP,
    payload: {
      allowMultiplesOfEachOption: allowMultiplesOfEachOption,
    },
  });
  dispatch(setFormIsDirty(true));
};

export const prepareModifierGroupsModal = (
  visible: boolean,
  selectedModifierGroups?: IModifierGroup[],
  parentModifierOption?: IEmbeddedModifierItem
) => ({
  type: MODIFIER_GROUPS_ACTIONS.PREPARE_MENU_BUILDER_MODIFIER_GROUPS_MODAL,
  payload: {
    visible,
    selectedIds: selectedModifierGroups?.map((modGroup) => modGroup._id) || [],
    parentModifierOptionId: parentModifierOption?.itemId,
  },
});

export const addNestedModifierGroupToModifierOption = (nestedModifierGroup: IModifierGroup) => ({
  type: MODIFIER_GROUPS_ACTIONS.ADD_MENU_BUILDER_NESTED_MODIFIER_GROUP_TO_MODIFIER_OPTION,
  payload: nestedModifierGroup,
});

export const removeNestedModifierGroupFromModifierOption = (
  parentModifierOptionId: string,
  nestedModifierGroupId: string
) => ({
  type: MODIFIER_GROUPS_ACTIONS.REMOVE_MENU_BUILDER_NESTED_MODIFIER_GROUP_FROM_MODIFIER_OPTION,
  payload: {
    parentModifierOptionId,
    nestedModifierGroupId,
  },
});

export const setModifierGroupsModalQuery = (query: string) => ({
  type: MODIFIER_GROUPS_ACTIONS.SET_MENU_BUILDER_MODIFIER_GROUPS_MODAL_QUERY,
  payload: query,
});

export const loadModifierGroupsForInfiniteScroll =
  (companyId: string, locationId: string, query: string = '') =>
  async (dispatch: any) => {
    dispatch({
      type: MODIFIER_GROUPS_ACTIONS.LOAD_MENU_BUILDER_MODIFIER_GROUPS_FOR_INFINITE_SCROLL_BEGIN,
    });
    try {
      const results = await MenuBuilderService.getModifierGroups(companyId, locationId, query);
      dispatch({
        type: MODIFIER_GROUPS_ACTIONS.LOAD_MENU_BUILDER_MODIFIER_GROUPS_FOR_INFINITE_SCROLL_SUCCESS,
        payload: results.items,
      });
    } catch (error) {
      dispatch({
        type: MODIFIER_GROUPS_ACTIONS.LOAD_MENU_BUILDER_MODIFIER_GROUPS_FOR_INFINITE_SCROLL_ERROR,
      });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const loadMoreModifierGroupsForInfiniteScroll =
  (companyId: string, locationId: string, query: string = '', page: number) =>
  async (dispatch: any) => {
    dispatch({
      type: MODIFIER_GROUPS_ACTIONS.LOAD_MORE_MENU_BUILDER_MODIFIER_GROUPS_FOR_INFINITE_SCROLL_BEGIN,
    });
    try {
      const nextPage = page + 1;
      const results = await MenuBuilderService.getModifierGroups(companyId, locationId, query, nextPage);
      if (results.length > 0) {
        dispatch({
          type: MODIFIER_GROUPS_ACTIONS.LOAD_MORE_MENU_BUILDER_MODIFIER_GROUPS_FOR_INFINITE_SCROLL_SUCCESS,
          payload: results.items,
        });
      } else {
        dispatch({
          type: MODIFIER_GROUPS_ACTIONS.LOAD_MORE_MENU_BUILDER_MODIFIER_GROUPS_FOR_INFINITE_SCROLL_NO_RESULTS,
        });
      }
    } catch (error) {
      dispatch({
        type: MODIFIER_GROUPS_ACTIONS.LOAD_MORE_MENU_BUILDER_MODIFIER_GROUPS_FOR_INFINITE_SCROLL_ERROR,
      });
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const moveModifierGroupInModifierOption =
  (optionId: string, sourceIndex: number, destinationIndex: number) => async (dispatch: any) => {
    dispatch({
      type: MODIFIER_GROUPS_ACTIONS.MOVE_MODIFIER_GROUP_IN_MODIFIER_OPTION,
      payload: {
        optionId,
        sourceIndex,
        destinationIndex,
      },
    });
    dispatch(setFormIsDirty(true));
  };
