import React from 'react';
import styles from './AssignTablesDialogNoResults.module.scss';

type MessageType = 'noResults' | 'noServers';

interface AssignTablesDialogNoResultsProps {
  messageType: MessageType;
}

const AssignTablesDialogNoResults = ({ messageType }: AssignTablesDialogNoResultsProps): JSX.Element => {
  return (
    <div className={styles.container}>
      {messageType === 'noResults' ? (
        <p className={styles.noResultsMessage}>{'No Results Found'}</p>
      ) : (
        <p className={styles.noServersMessage}>
          {"We couldn't find a list of servers at this location. Press "}
          <span className={styles.syncText}>{'Sync'}</span>
          {' to get the latest information from your POS, or contact Ready '}
          {'Support for assistance.'}
        </p>
      )}
    </div>
  );
};

export default AssignTablesDialogNoResults;
