import { ICompanyIntegrations } from '@ready/dashboardv2api.contracts';
import { ISmbTax } from '../types/SmbTaxTypes.type';
import { ISmbLocation } from '../types/SmbLocationTypes.type';

export interface CompanyIntegrationsState {
  integrations: ICompanyIntegrations;
  loading: boolean;
}

export interface TaxesState {
  taxes: ISmbTax[];
  loading: boolean;
  selectedTax?: ISmbTax;
  smbConfiguredLocations: ISmbLocation[];
}

export interface CompanySettingsState {
  companyIntegrations: CompanyIntegrationsState;
  smbTaxes: TaxesState;
}

export const companySettingsState: CompanySettingsState = {
  companyIntegrations: {
    loading: false,
    integrations: {
      square: {
        auth: {
          tokenInfo: {
            accessToken: '',
            expiresAt: '',
            refreshToken: '',
          },
        },
      },
    },
  },
  smbTaxes: {
    loading: false,
    taxes: [],
    selectedTax: undefined,
    smbConfiguredLocations: [],
  },
};
