const numericParams = ['page', 'limit'];

export interface Params {
  [propName: string]: any;
}

const getSearchParams = (search: string): Params => {
  const params: Params = {};

  if (search) {
    let searchParams = search;

    // Remove leading question mark if it exists
    if (search.charAt(0) === '?') {
      searchParams = searchParams.slice(1);
    }

    // Split the parameters which should be separated by '&' symbols
    const paramsArr = searchParams.split('&');

    // Get all individual params
    paramsArr.forEach((param) => {
      const [key, value] = param.split('=');
      if (numericParams.includes(key)) {
        // Treat anything that parses to NaN as undefined; use || instead of ?? because NaN is a non-null value
        params[key] = parseInt(value) || undefined;
      } else {
        params[key] = value;
      }
    });
  }

  return params;
};

export default getSearchParams;
