import React, { RefObject } from 'react';
import { ILink, IMenuGalleryForm, IMenuSectionForm } from '../../redux/MenusState';
import { ISchedule, IScheduleLink, Status, Appearance } from '@ready/menu.core';
import styles from './EditSectionPanel.module.scss';
import { Panel, PanelCollapsible } from '../../../components/PanelLayout';
import DisplayNameInput from './DisplayNameInput';
import EditPanelFooter from './EditPanelFooter';
import Button from '../../../components/Button/Button';
import OverflowButton from '../../../components/OverflowButton/OverflowButton';
import { Form } from '../../../components/Form';
import ScheduleFormControl from '../shared/ScheduleFormControl';
import LinkButton from '../../../components/LinkButton/LinkButton';
import DescriptionInput from './DescriptionInput';
import DefaultAppearanceSelector from './DefaultAppearanceSelector';
import Toggle from '../../../components/Toggle/Toggle';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';
import SectionItems from './SectionItems';
import TextInputNestedLabel from '../../../components/TextInputNestedLabel/TextInputNestedLabel';
import TextAreaNestedLabel from '../../../components/TextAreaNestedLabel/TextAreaNestedLabel';
import { SharedMenuLocationSection } from './SharedMenuLocationSection';

export interface EditSectionPanelProps {
  sectionForm: IMenuSectionForm;
  itemGalleryForm: IMenuGalleryForm;
  itemGalleryLimit: number;
  inputRef?: RefObject<HTMLInputElement>;
  schedules?: ISchedule[];
  areSchedulesLoading?: boolean;
  isSharedMenu?: boolean;
  isSharedMenuAssignedToCurrentLocation?: boolean;
  onChangeName: (name: string) => void;
  onChangeDescription: (description: string) => void;
  onChangeStatus?: (enabled: boolean) => void;
  onChangeDefaultAppearance: (defaultAppearance: Appearance) => void;
  onAddGroup: () => void;
  onAddItems: () => void;
  onRemoveItem: (id: string) => void;
  onRemoveGroupItem: (groupId: string, itemId: string) => void;
  onChangeItemName: (id: string, name: string) => void;
  onChangeItemVisibility?: (id: string, visible: boolean) => void;
  onChangeGroupItemVisibility?: (groupId: string, itemId: string, visible: boolean) => void;
  onMoveItem: (oldIndex: number, newIndex: number) => void;
  onMoveItemInGroup: (groupId: string, oldIndex: number, newIndex: number) => void;
  onAddItemsToGroupClick: (id: string) => void;
  onChangeScheduler?: (scheduler: IScheduleLink<ILink> | null) => void;
  onChangeGalleryName: (displayName: string) => void;
  onChangeGalleryDescription: (description: string) => void;
  onChangeGalleryStatus: (enabled: boolean) => void;
  onAddGalleryItems: () => void;
  onRemoveGalleryItem: (id: string) => void;
  onRemoveGalleryGroupItem: (groupId: string, itemId: string) => void;
  onChangeGalleryItemName: (id: string, name: string) => void;
  onChangeGalleryItemVisibility?: (id: string, visible: boolean) => void;
  onChangeGalleryGroupItemVisibility?: (groupId: string, itemId: string, visible: boolean) => void;
  onMoveGalleryItem: (oldIndex: number, newIndex: number) => void;
  onMoveGalleryItemInGroup: (groupId: string, oldIndex: number, newIndex: number) => void;
  onAddGalleryItemsToGroupClick: (id: string) => void;
  onCancel: () => void;
  onSave: () => void;
}

export const itemGalleryCountIndicator = (count: number, limit: number): JSX.Element => (
  <span className={count > limit ? styles.warning : styles.count}>
    ({count}/{limit}){count > limit && <TextIcon icon={Icon.Alert} additionalStyles={styles.warningIcon} />}
  </span>
);

const EditSectionPanel = ({
  sectionForm: { section, sectionItemChanges, processing, validation },
  sectionForm,
  itemGalleryForm,
  itemGalleryLimit,
  inputRef,
  isSharedMenu = false,
  isSharedMenuAssignedToCurrentLocation = false,
  onChangeName,
  onChangeDescription,
  onChangeStatus = () => {},
  onChangeDefaultAppearance,
  onAddGroup,
  onAddItems,
  onRemoveItem,
  onRemoveGroupItem,
  onChangeItemName,
  onChangeItemVisibility = () => {},
  onChangeGroupItemVisibility = () => {},
  onMoveItem,
  onMoveItemInGroup,
  onAddItemsToGroupClick,
  onChangeScheduler = () => {},
  schedules = [],
  onChangeGalleryName,
  onChangeGalleryDescription,
  onChangeGalleryStatus,
  onAddGalleryItems,
  onRemoveGalleryItem,
  onRemoveGalleryGroupItem,
  onChangeGalleryItemName,
  onChangeGalleryItemVisibility = () => {},
  onChangeGalleryGroupItemVisibility = () => {},
  onMoveGalleryItem,
  onMoveGalleryItemInGroup,
  onAddGalleryItemsToGroupClick,
  onCancel,
  onSave,
  areSchedulesLoading = false,
}: EditSectionPanelProps): JSX.Element => {
  const itemGalleryOptions = [
    {
      label: '+ Add Items',
      onClick: onAddGalleryItems,
    },
  ];
  const itemListOptions = [
    {
      label: '+ Add Items',
      onClick: onAddItems,
    },
  ];

  // These functions synchronize changes within an item group in the case where that item group appears in both
  // the section and the section's item gallery at the same time.
  const onMoveItemInGroupSynchronized = (groupId: string, oldIndex: number, newIndex: number): void => {
    onMoveItemInGroup(groupId, oldIndex, newIndex);
    onMoveGalleryItemInGroup(groupId, oldIndex, newIndex);
  };

  const onRemoveGroupItemSynchronized = (groupId: string, itemId: string): void => {
    onRemoveGroupItem(groupId, itemId);
    onRemoveGalleryGroupItem(groupId, itemId);
  };

  const onChangeGroupItemVisibilitySynchronized = (groupId: string, itemId: string, visible: boolean): void => {
    onChangeGroupItemVisibility(groupId, itemId, visible);
    onChangeGalleryGroupItemVisibility(groupId, itemId, visible);
  };

  const onChangeItemNameSynchronized = (id: string, name: string): void => {
    onChangeItemName(id, name);
    onChangeGalleryItemName(id, name);
  };

  return (
    <Panel fullContentArea>
      {isSharedMenuAssignedToCurrentLocation ? (
        <>
          <SharedMenuLocationSection
            sectionForm={sectionForm}
            itemGalleryForm={itemGalleryForm}
            itemGalleryLimit={itemGalleryLimit}
            schedules={schedules}
            onChangeScheduler={onChangeScheduler}
            areSchedulesLoading={areSchedulesLoading}
            onCancel={onCancel}
            onSave={onSave}
            onChangeStatus={onChangeStatus}
            onChangeItemVisibility={onChangeItemVisibility}
            onChangeGroupItemVisibility={onChangeGroupItemVisibility}
            onChangeGalleryItemVisibility={onChangeGalleryItemVisibility}
            onChangeGalleryGroupItemVisibility={onChangeGalleryGroupItemVisibility}
          />
        </>
      ) : (
        <Form hasGroups onSubmit={onSave}>
          <div className={styles.details}>
            <div className={styles.content}>
              <div className={styles.name}>
                <DisplayNameInput
                  value={section.displayName}
                  validation={validation}
                  processing={processing}
                  label='Section Name'
                  inputRef={inputRef}
                  onChange={onChangeName}
                />
              </div>
              <div className={styles.description}>
                <DescriptionInput
                  value={section.description || ''}
                  processing={processing}
                  onChange={onChangeDescription}
                />
              </div>
              <div className={styles.defaultAppearanceContent}>
                <div className={styles.label}>Default Appearance</div>
                <DefaultAppearanceSelector
                  defaultAppearance={section.defaultAppearance}
                  onChange={onChangeDefaultAppearance}
                />
              </div>

              {!isSharedMenu && (
                <ScheduleFormControl
                  schedules={schedules}
                  scheduleLink={section.schedule}
                  validation={validation}
                  onChange={onChangeScheduler}
                  areSchedulesLoading={areSchedulesLoading}
                />
              )}
            </div>

            <div className={styles.controls}>
              <div className={styles.defaultAppearanceControl}>
                <div className={styles.label}>Default Appearance</div>
                <DefaultAppearanceSelector
                  defaultAppearance={section.defaultAppearance}
                  onChange={onChangeDefaultAppearance}
                />
              </div>
            </div>
          </div>

          <PanelCollapsible
            transparent
            initiallyExpanded={itemGalleryForm.menu.itemGallery!.status === Status.enabled}
            fullContentArea
            titleComponent={
              <div className={styles.itemGalleryTitleContainer}>
                <span className={styles.itemGalleryTitle}>
                  Item Gallery
                  {itemGalleryCountIndicator(itemGalleryForm.menu.itemGallery!.items.length, itemGalleryLimit)}
                </span>
                <span className={styles.itemGalleryEnabled}>
                  <Toggle
                    checked={itemGalleryForm.menu.itemGallery!.status === Status.enabled}
                    onChange={(checked) => onChangeGalleryStatus(checked)}
                    loading={processing}
                  />
                </span>
              </div>
            }
            headerControls={
              <>
                <span className={styles.itemGalleryButton}>
                  <Button
                    label={itemGalleryOptions[0].label}
                    variant='secondary'
                    size='large'
                    unavailable={processing}
                    onClick={itemGalleryOptions[0].onClick}
                  />
                </span>
                <span className={styles.overflow}>
                  <OverflowButton options={itemGalleryOptions} />
                </span>
              </>
            }
            additionalStyles={styles.itemGallery}
          >
            <div className={styles.itemGalleryFormHeader}>
              <div className={styles.itemGalleryFormHeaderInputs}>
                <TextInputNestedLabel
                  value={itemGalleryForm.menu.itemGallery!.displayName || ''}
                  label='Item Gallery Name'
                  placeholder='Item Gallery Name (optional)'
                  onChange={(e) => onChangeGalleryName(e.target.value)}
                  loading={processing}
                  maxLength={60}
                />
                <TextAreaNestedLabel
                  value={itemGalleryForm.menu.itemGallery!.description || ''}
                  onChange={(e) => onChangeGalleryDescription(e.target.value)}
                  label='Description'
                  placeholder='Enter a description which will be displayed to your guests.'
                  loading={processing}
                  maxLength={100}
                />
              </div>
            </div>
            {!!itemGalleryForm.menu.itemGallery && itemGalleryForm.menu.itemGallery.items.length > 0 ? (
              <SectionItems
                items={itemGalleryForm.menu.itemGallery.items}
                itemChanges={itemGalleryForm.sectionItemChanges}
                processing={processing}
                onRemoveItem={onRemoveGalleryItem}
                onRemoveGroupItem={onRemoveGroupItemSynchronized}
                onChangeItemName={onChangeItemNameSynchronized}
                onChangeItemVisibility={() => onChangeGalleryItemVisibility}
                onChangeGroupItemVisibility={onChangeGroupItemVisibilitySynchronized}
                onAddItemsToGroupClick={onAddGalleryItemsToGroupClick}
                onMoveItemInGroup={onMoveItemInGroupSynchronized}
                onMoveItem={onMoveGalleryItem}
                isSharedMenu={isSharedMenu}
              />
            ) : (
              <div className={styles.itemGalleryNoItems}>
                <div className={styles.itemGalleryNoItemsText}>
                  <LinkButton disabled={processing} onClick={onAddGalleryItems}>
                    + Add Items
                  </LinkButton>
                  <span>
                    to this gallery to promote them to your guests <span>(max {itemGalleryLimit} items)</span>.
                  </span>
                </div>
              </div>
            )}
          </PanelCollapsible>

          <PanelCollapsible
            transparent
            initiallyExpanded
            fullContentArea
            titleComponent={<div className={styles.itemListTitle}>Item List</div>}
            headerControls={
              <>
                <span className={styles.itemListButton}>
                  <Button
                    label={itemListOptions[0].label}
                    variant='secondary'
                    size='large'
                    unavailable={processing}
                    onClick={itemListOptions[0].onClick}
                  />
                </span>
                <span className={styles.overflow}>
                  <OverflowButton options={itemListOptions} />
                </span>
              </>
            }
            additionalStyles={styles.itemList}
          >
            {section.items && section.items.length ? (
              <SectionItems
                items={section.items}
                itemChanges={sectionItemChanges}
                processing={processing}
                onRemoveItem={onRemoveItem}
                onRemoveGroupItem={onRemoveGroupItemSynchronized}
                onChangeItemName={onChangeItemNameSynchronized}
                onChangeItemVisibility={onChangeItemVisibility}
                onChangeGroupItemVisibility={onChangeGroupItemVisibilitySynchronized}
                onAddItemsToGroupClick={onAddItemsToGroupClick}
                onMoveItemInGroup={onMoveItemInGroupSynchronized}
                onMoveItem={onMoveItem}
                isSharedMenu={isSharedMenu}
              />
            ) : (
              <div className={styles.itemsEmpty}>
                <div className={styles.itemsMessage}>
                  <LinkButton onClick={onAddItems}>+ Add menu items</LinkButton>
                  <span className={styles.itemsMessageText}>to this section, or</span>
                  <LinkButton onClick={onAddGroup}>+ Add a Group</LinkButton>
                  <span className={styles.itemsMessageText}>to combine similar items together</span>
                </div>
              </div>
            )}
          </PanelCollapsible>

          <div className={styles.footer}>
            <EditPanelFooter
              status={section.status}
              statusLabel='Section Status:'
              saveLabel={section.uid ? 'Save Changes' : 'Create Section'}
              processing={processing}
              onChangeStatus={onChangeStatus}
              onCancel={onCancel}
              warningMessage={
                itemGalleryForm.menu.itemGallery!.items.length > itemGalleryLimit
                  ? `Item Gallery limit of ${itemGalleryLimit} exceeded. Please remove some items before saving.`
                  : ''
              }
              isSharedMenu={isSharedMenu}
            />
          </div>
        </Form>
      )}
    </Panel>
  );
};

export default EditSectionPanel;
