import versionJson from './version.json';

const config = {
  /** Used to identfy which environment on which the application is running. */
  environment: process.env.REACT_APP_ENV ?? 'local',

  /** Provides npm package version from version.json that is generated in prebuild script. */
  version: versionJson.version || 'local',

  /** Auth0 */
  auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN ?? 'ready-dev.auth0.com',
  auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID ?? 'O5uMIi4QU05KWSAIfzQFYypuwZpC9rot',
  auth0Audience: process.env.REACT_APP_AUTH0_AUDIENCE ?? 'https://test1.readytopay.co/v2',
  auth0isAdminJwtClaim: process.env.REACT_APP_AUTH0_ISADMIN_JWT_CLAIM ?? 'https://test1.readytopay.co/v2/isAdmin',

  /**
   * Ready.API.Dashboard endpoint.
   * The main API for this application.
   */
  readyDashboardApiEndpoint:
    process.env.REACT_APP_READY_DASHBOARD_API_ENDPOINT ?? 'https://test1.readytopay.co/dashboard',

  /**
   * Ready.API endpoint.
   * Most dashboard specific endpoints have been migrated to Ready.API.Dashboard, so this should rarely be used.
   */
  readyApiLegacyEndpoint: process.env.REACT_APP_READY_API_LEGACY_ENDPOINT ?? 'https://test1.readytopay.co/v2',

  /** Admin email domains (comma, separated) */
  adminEmailDomains: process.env.REACT_APP_ADMIN_EMAIL_DOMAINS ?? 'iqmetrix.com',
  raygunApiKey: process.env.REACT_APP_RAYGUN_KEY ?? 't6Bi4xEHa6ZTuzJBlqA',

  /** Square Configuration */
  squareClientId: process.env.REACT_APP_SQUARE_CLIENT_ID ?? 'sandbox-sq0idb-6XOudFJfrTv4CSEyYnQn5w',
  squareApiDomain: process.env.REACT_APP_SQUARE_API_DOMAIN ?? 'connect.squareupsandbox.com',

  /** Google Analytics */
  googleAnalyticsId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID ?? 'G-DYPVWGJBDC',
};
export default config;
