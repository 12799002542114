import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '../../components/Modal';
import { AppState } from '../../redux/initialStates/AppState';
import { setRemoveTableAssignmentModalOpen, removeAssignment } from '../redux/ServerAssignmentActions';
import { ContextParams } from '../../types/ContextParams.interface';
import { useParams } from 'react-router-dom';

const RemoveServerDialog = ({
  setRemoveTableAssignmentModalOpen,
  removeAssignment,
  employeeId,
  isOpen,
  processing,
}: ReduxProps): JSX.Element | null => {
  const { contextId, locationId } = useParams<ContextParams>();

  const handleRemoveClick = async (_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const success = await removeAssignment(contextId, locationId, employeeId);

    if (success) {
      setRemoveTableAssignmentModalOpen(false);
    }
  };

  const handleCancelClick = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setRemoveTableAssignmentModalOpen(false);
  };

  return isOpen ? (
    <Modal setShowModal={setRemoveTableAssignmentModalOpen}>
      <ModalHeader headerLabel='Remove this Server?' setShowModal={setRemoveTableAssignmentModalOpen} />
      <ModalBody>
        <p>
          {'Are you sure you want to remove this server? All of their ' +
            'tables will move back to the unassigned tables list.'}
        </p>
      </ModalBody>
      <ModalFooter
        primaryLabel='Remove Server'
        primaryActionHandler={handleRemoveClick}
        secondaryLabel='Cancel'
        secondaryActionHandler={handleCancelClick}
        loading={processing}
      />
    </Modal>
  ) : null;
};

const mapStateToProps = (state: AppState) => {
  return {
    isOpen: state.tableServerAssignment.serverAssignments.removeModal.isOpen,
    employeeId: state.tableServerAssignment.serverAssignments.removeModal.employeeId,
    processing: state.tableServerAssignment.serverAssignments.assignmentProcessing,
  };
};

const actionCreators = {
  removeAssignment,
  setRemoveTableAssignmentModalOpen,
};

const connector = connect(mapStateToProps, actionCreators);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(RemoveServerDialog);
