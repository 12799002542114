import { ITransaction } from '@ready/dashboardv2api.contracts';
import {
  IPrincipalPermissionSet,
  PrincipalPermissions,
  ResourceType,
  SecurityScope,
  TransactionResourceActions,
  Verifier,
} from '@ready/security.core';
import { getTransaction, refundTransactionByIdAndAmount, retryPaymentSync } from '../../../services/transactionService';
import { getTransactionDetail } from '../../../services/transactionsService';
import { pageErrorState, set200Toast, toastErrorState } from '../uiActions/responseStateActions';
import { TRANSACTION_DETAIL_ACTIONS } from './transactionDetailActionTypes';

export const getTransactionById = (contextId: string, locationId: string, id: string) => async (dispatch: any) => {
  dispatch(getTransactionBegin());
  try {
    const transaction = await getTransaction(contextId, locationId, id);

    dispatch(getTransactionSuccess(transaction));
  } catch (error) {
    dispatch(getTransactionError(error.message));
    dispatch(pageErrorState(error.status, error.message));
  }
};

const getTransactionBegin = () => ({
  type: TRANSACTION_DETAIL_ACTIONS.GET_TRANSACTION_BEGIN,
});

const getTransactionSuccess = (transaction: ITransaction) => ({
  type: TRANSACTION_DETAIL_ACTIONS.GET_TRANSACTION_SUCCESS,
  payload: transaction,
});

const getTransactionError = (errorMessage: string) => ({
  type: TRANSACTION_DETAIL_ACTIONS.GET_TRANSACTION_ERROR,
  payload: errorMessage,
});

export const checkTransactionRefundAccess = (locationId: string, permissions: IPrincipalPermissionSet) => async (
  dispatch: any
) => {
  try {
    const hasTransactionRefundAccess = Verifier.check(
      new PrincipalPermissions(permissions),
      SecurityScope.location,
      ResourceType.transaction,
      TransactionResourceActions.refund,
      locationId
    );
    dispatch({
      type: TRANSACTION_DETAIL_ACTIONS.CHECK_TRANSACTION_REFUND_ACCESS_SUCCESS,
      payload: hasTransactionRefundAccess,
    });
  } catch (error) {
    dispatch({
      type: TRANSACTION_DETAIL_ACTIONS.CHECK_TRANSACTION_REFUND_ACCESS_ERROR,
      payload: error.message,
    });
    dispatch(toastErrorState(error.status, error.message));
  }
};

export const validateRefund = (amount: string, amountAvailable: number) => (dispatch: any) => {
  const result = {
    refundAmount: parseInt(amount.replace('.', '')),
    validationMessage: '',
  };
  if (!result.refundAmount || result.refundAmount <= 0) {
    result.validationMessage = 'Please enter a refund amount.';
  } else if (result.refundAmount > amountAvailable) {
    result.validationMessage = 'Value exceeds amount available for refund.';
  }
  dispatch({
    type: TRANSACTION_DETAIL_ACTIONS.VALIDATE_REFUND_AMOUNT,
    payload: result,
  });
};

export const refundTransaction = (contextId: string, locationId: string, id: string, amount: number) => async (
  dispatch: any
) => {
  dispatch({
    type: TRANSACTION_DETAIL_ACTIONS.REFUND_TRANSACTION_BEGIN,
  });

  try {
    const transaction = await refundTransactionByIdAndAmount(contextId, locationId, id, amount);

    if (transaction) {
      const transaction = await getTransactionDetail(contextId, locationId, id);
      dispatch({
        type: TRANSACTION_DETAIL_ACTIONS.REFUND_TRANSACTION_SUCCESS,
        payload: transaction,
      });
      dispatch(set200Toast('Success! Refund processed.'));
    }
  } catch (error) {
    dispatch({
      type: TRANSACTION_DETAIL_ACTIONS.REFUND_TRANSACTION_ERROR,
      payload: error.message || 'Error in Transaction Refund',
    });
    dispatch(toastErrorState(error.status, error.message));
    dispatch(resetRefundState());
  }
};

export const resetRefundState = () => async (dispatch: any) => {
  dispatch({
    type: TRANSACTION_DETAIL_ACTIONS.RESET_REFUND_STATE,
  });
};

export const retryPayment = (locationId: string, invoiceId: string) => async (dispatch: any) => {
  dispatch(retryPaymentSyncProcessing(true));

  try {
    await retryPaymentSync(locationId, invoiceId);

    dispatch(retryPaymentSyncSuccess());
    dispatch(set200Toast('Payment applied successfully'));
  } catch (err) {
    dispatch(toastErrorState(err.status, err.message));
  } finally {
    dispatch(retryPaymentSyncProcessing(false));
  }
};

const retryPaymentSyncProcessing = (isProcessing: boolean) => ({
  type: TRANSACTION_DETAIL_ACTIONS.RETRY_PAYMENT_SYNC_PROCESSING,
  payload: isProcessing,
});

const retryPaymentSyncSuccess = () => ({
  type: TRANSACTION_DETAIL_ACTIONS.RETRY_PAYMENT_SYNC_SUCCESS,
});
