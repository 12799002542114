import React from 'react';

export interface CenteredPanelProps {
  children: JSX.Element[] | JSX.Element;
}

const CenteredPanel = (props: CenteredPanelProps) => {
  const { children } = props;
  return (
    <div className='centered-panel'>
      <div className='centered-panel__container'>{children}</div>
    </div>
  );
};

export default CenteredPanel;
