import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import CompanyTableRow from './CompanyTableRow';
import CompanyTableCell from './CompanyTableCell';
import { useSearchParams } from '../../../hooks/useSearchParams';
import { IPagination } from '@ready/dashboardv2api.contracts';
import styles from './CompanyTablePage.module.scss';

export type CompanyTablePaginationProps = IPagination & {
  withOrderType: boolean;
};

const CompanyTablePagination = (props: CompanyTablePaginationProps) => {
  const { start = 0, length = 0, total = 0, withOrderType } = props;

  const { query, page = 1 } = useSearchParams();
  const { pathname } = useLocation();

  const emptyResult = length === 0;

  const pageLimit = !emptyResult ? start + length : 0;
  const isFirstPage = start === 0;
  const isLastPage = pageLimit === total;
  const startShow = emptyResult ? 0 : start + 1;
  const totalShow = emptyResult ? 0 : total;

  // set previous and next page links
  const qs = query ? `query=${query}&` : '';
  const initialPath = `${pathname}?${qs}`;
  const prevLink = `${initialPath}page=${parseInt(page) - 1}`;
  const nextLink = `${initialPath}page=${parseInt(page) + 1}`;

  return (
    <CompanyTableRow withOrderType={withOrderType}>
      <CompanyTableCell>{`${startShow}-${pageLimit} of ${totalShow}`}</CompanyTableCell>
      <CompanyTableCell>
        {isFirstPage || emptyResult ? (
          <span className={styles.linkDisabled}>Prev</span>
        ) : (
          <Link to={prevLink}>Prev</Link>
        )}

        <span className={styles.navigationSeparator}>|</span>

        {isLastPage || emptyResult ? (
          <span className={styles.linkDisabled}>Next</span>
        ) : (
          <Link to={nextLink}>Next</Link>
        )}
      </CompanyTableCell>
    </CompanyTableRow>
  );
};

export default CompanyTablePagination;
