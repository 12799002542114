import { TRANSACTION_ACTIONS } from './transactionsActionsTypes';
import {
  generateTransactionsExportReport,
  getTransactions,
  getTransactionsExportReport,
} from '../../../services/transactionsService';
import { ITransactionToShow } from '@ready/dashboardv2api.contracts';
import { pageErrorState, toastErrorState } from '../uiActions/responseStateActions';

//TRANSACTIONS LIST
export const getTransactionsList =
  (contextId: string, query: string = '', page: number) =>
  async (dispatch: any) => {
    dispatch(getTransactionListBegin());
    try {
      const { items, start, length, limit, total } = await getTransactions(contextId, query, page);
      dispatch(getTransactionsListSuccess(items, start, length, limit, total));
    } catch (err) {
      dispatch(getLocationsListError());
      dispatch(pageErrorState(err.status, err.message));
    }
  };

export const getTransactionListBegin = () => ({
  type: TRANSACTION_ACTIONS.GET_TRANSACTIONS_LIST_BEGIN,
});

export const getTransactionsListSuccess = (
  items: ITransactionToShow[],
  start: number,
  length: number,
  limit: number,
  total: number
) => ({
  type: TRANSACTION_ACTIONS.GET_TRANSACTIONS_LIST_SUCCESS,
  payload: {
    transactions: items,
    pagination: { start, length, limit, total },
  },
});

export const getLocationsListError = () => ({
  type: TRANSACTION_ACTIONS.GET_TRANSACTIONS_LIST_ERROR,
});

export const exportTransactionsReport =
  (contextId: string, query: string = '') =>
  async (dispatch: any) => {
    dispatch({ type: TRANSACTION_ACTIONS.EXPORT_TRANSACTIONS_BEGIN });
    try {
      const exportId = await generateTransactionsExportReport(contextId, query);
      dispatch({
        type: TRANSACTION_ACTIONS.EXPORT_TRANSACTIONS_SUCCESS,
        payload: exportId,
      });
    } catch (err) {
      dispatch({ type: TRANSACTION_ACTIONS.EXPORT_TRANSACTIONS_ERROR });
      dispatch(toastErrorState(err.status, err.message));
    }
  };

export const getTransactionsReport = (contextId: string, id: string) => async (dispatch: any) => {
  dispatch({ type: TRANSACTION_ACTIONS.GET_TRANSACTIONS_REPORT_BEGIN });
  try {
    const reportDownloaded = await getTransactionsExportReport(contextId, id);
    dispatch({
      type: TRANSACTION_ACTIONS.GET_TRANSACTIONS_REPORT_SUCCESS,
      payload: reportDownloaded,
    });
  } catch (err) {
    dispatch({ type: TRANSACTION_ACTIONS.GET_TRANSACTIONS_REPORT_ERROR });
    dispatch(toastErrorState(err.status, err.message));
  }
};

export const updateTransactionsListQuery = (queryString: string, page: string) => ({
  type: TRANSACTION_ACTIONS.UPDATE_TRANSACTIONS_LIST_URL_QUERY,
  payload: {
    queryString,
    page,
  },
});
