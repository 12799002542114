import React from 'react';
import SearchParamInput from '../../../components/SearchInput/SearchParamInput';
import ToggleLinks, { IToggleLinkState } from '../../../components/ToggleLinks/ToggleLinks';
import styles from './ToolbarContent.module.scss';
import truncateNumber, {
  TruncateNumberDisplayType,
  TruncateNumberDecimalPlaces,
  TruncateNumberOperation,
} from '../../../utils/formatUtils/truncateNumber';
import { useSearchParams } from '../../../hooks/useSearchParams';
import DateScroller from '../../../components/DateScroller/DateScroller';
import { startOfDay, isValid, parseISO, formatISO } from 'date-fns';
import { useHistory } from 'react-router-dom';
import useResponsiveBreakpoint from '../../../hooks/useResponsiveBreakpoint';

export enum ToolbarContentTab {
  Upcoming,
  Complete,
  Cancelled,
}

interface ToolbarContentProps {
  upcomingOrders: number;
  completeOrders: number;
  cancelledOrders: number;
  companyId: string;
  locationId: string;
  day: string;
  selectedTab: ToolbarContentTab;
}

const formatNumber = (_number: number) =>
  _number > 1000
    ? truncateNumber(
        _number,
        TruncateNumberDisplayType.Thousands,
        TruncateNumberDecimalPlaces.Zero,
        TruncateNumberOperation.Ceiling
      )
    : _number;

export enum RouteStatus {
  Upcoming = 'upcoming',
  Complete = 'complete',
  Cancelled = 'cancelled',
}

const makeRoute = (companyId: string, locationId: string, day: string, status: RouteStatus) =>
  `/companies/${companyId}/locations/${locationId}/orders/day/${day}/${status}`;

const ToolbarContent = ({
  upcomingOrders,
  completeOrders,
  cancelledOrders,
  companyId,
  locationId,
  day,
  selectedTab,
}: ToolbarContentProps) => {
  const { query } = useSearchParams();
  const searchQuery = query ? `?query=${query}` : undefined;
  const history = useHistory();
  const { isDesktop } = useResponsiveBreakpoint();

  const today = startOfDay(new Date());
  const parsedDay = parseISO(day);
  const dayValue = isValid(parsedDay) ? parsedDay : today;

  const [date, setDate] = React.useState(dayValue);

  const handleDateChange = (value: Date): void => {
    setDate(value);

    const newDay = formatISO(value, { representation: 'date' });

    history.push({
      pathname: makeRoute(companyId, locationId, newDay, RouteStatus.Upcoming),
      search: '',
    });
  };

  const toggleLinksData: IToggleLinkState[] = [
    {
      label: `Upcoming (${formatNumber(upcomingOrders)})`,
      selected: selectedTab === ToolbarContentTab.Upcoming,
      route: makeRoute(companyId, locationId, day, RouteStatus.Upcoming),
      search: searchQuery,
    },
    {
      label: `Complete (${formatNumber(completeOrders)})`,
      selected: selectedTab === ToolbarContentTab.Complete,
      route: makeRoute(companyId, locationId, day, RouteStatus.Complete),
      search: searchQuery,
    },
    {
      label: `Cancelled (${formatNumber(cancelledOrders)})`,
      selected: selectedTab === ToolbarContentTab.Cancelled,
      route: makeRoute(companyId, locationId, day, RouteStatus.Cancelled),
      search: searchQuery,
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.searchAndNavContainer}>
        <div className={styles.searchContainer}>
          <SearchParamInput placeholder='Search Orders' fullWidth additionalClassName={styles.searchInput} />
        </div>
        <div className={styles.toggleLinksContainer}>
          <ToggleLinks data={toggleLinksData} />
        </div>
      </div>

      <div className={styles.datePickerContainer}>
        <div className={styles.datePickerBounds}>
          <DateScroller
            value={date}
            onChange={handleDateChange}
            position={isDesktop ? 'bottomRight' : 'bottomCenter'}
          />
        </div>
      </div>
    </div>
  );
};

export default ToolbarContent;
