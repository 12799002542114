import React from 'react';
import { ISmbTax } from '../types/SmbTaxTypes.type';
import InfoCard from '../../components/InfoCard/InfoCard';
import InfoCardColumn from '../../components/InfoCard/InfoCardColumn';
import { NumberValue } from '../../components/Value';
import TaxItemCardButtons from './TaxItemCardButtons';

import styles from './TaxItemCard.module.scss';

export interface TaxItemCardProps {
  tax: ISmbTax;
  onDelete: (taxId: string) => void;
  onEdit: (taxId: string) => void;
}

const TaxItemCard = ({ tax, onDelete, onEdit }: TaxItemCardProps) => {
  return (
    <InfoCard>
      <InfoCardColumn width={33}>{tax.name}</InfoCardColumn>
      <InfoCardColumn width={33}>
        <NumberValue value={tax.rate} unit='%' />
      </InfoCardColumn>
      <InfoCardColumn columnClassName={styles.actions} width={33}>
        <TaxItemCardButtons
          handleDeleteClicked={() => {
            onDelete(tax._id);
          }}
          handleEditClicked={() => {
            onEdit(tax._id);
          }}
        />
      </InfoCardColumn>
    </InfoCard>
  );
};

export default TaxItemCard;
