import React from 'react';
import { useClassNames } from '../../utils/cssUtils';
import styles from './LocationPickerNoResults.module.scss';

const LocationPickerNoResults = ({ roundBottom = false }): JSX.Element => {
  const containerClassName = useClassNames([{ roundBottom }, styles.container], styles);

  return (
    <div className={containerClassName}>
      <p className={styles.noResultsText}>No Results Found</p>
    </div>
  );
};

export default LocationPickerNoResults;
