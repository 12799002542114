import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '../../../../context/Auth0';
import {
  GiftCardProviderLabels,
  IGiftCardValidation,
  ITenderType,
  noneOption,
} from '../../../redux/initialStates/paymentProcessing';
import { GiftCardProvider, IGiftCardConfig, IGivexConfig } from '@ready/dashboardv2api.contracts';
import {
  saveGiftCardConfig,
  toggleGiftCardEditForm,
  changeGiftCardProvider,
  toggleGiftCardConfig,
  updateGiftCardFormValidation,
} from '../../../redux/actions/paymentProcessingActions';

import GivexForm from './givex/GivexForm';
import { Form, FormActionButtons, FormControl } from '../../../../components/Form';
import SelectFilter, { Option, GroupedOption } from '../../../../components/SelectFilter/SelectFilter';
import { setFormIsDirty } from '../../../../redux/actions/uiActions/formStateActions';
import { selectFormState } from '../../../../redux/selectors/uiSelectors/uiSelectors';
import { FormProvider, useForm } from 'react-hook-form';
import { RootState, useAppSelector } from 'redux/store';

interface GiftCardsEditPanelProps {
  companyId: string;
  locationId: string;
  compatibleProviders: GiftCardProvider[];
  tenderTypes: ITenderType[];
  loading: boolean;
  validated: boolean;
  provider?: GiftCardProvider;
  options?: IGiftCardConfig;
  validations?: IGiftCardValidation;
}

interface IGiftCardFormMap {
  [key: string]: JSX.Element;
}

export const getSelectedTenderTypeOption = (tenderTypes: ITenderType[], value?: string): Option | undefined => {
  const result = tenderTypes.find((tenderType: ITenderType) => tenderType.id === value);

  return result
    ? {
        label: result.name,
        value: result.id,
      }
    : undefined;
};

const GiftCardsEditPanel = (props: GiftCardsEditPanelProps) => {
  const {
    companyId,
    locationId,
    compatibleProviders,
    tenderTypes,
    loading,
    provider,
    options,
    validations,
    validated,
  } = props;

  const { isDirty: formIsDirty } = useSelector(selectFormState);
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const [changedGiftCardProvider, setChangedGiftCardProvider] = React.useState<GiftCardProvider>();
  const givexOptions = useAppSelector((state: RootState) => state.paymentProcessing.givex.options);

  const getSelectedOption = (provider: GiftCardProvider | undefined): Option => {
    return provider && GiftCardProviderLabels[provider]
      ? {
          label: GiftCardProviderLabels[provider],
          value: provider,
        }
      : noneOption;
  };

  const selectedOption = getSelectedOption(
    changedGiftCardProvider === undefined || formIsDirty ? provider : changedGiftCardProvider
  );

  /** The form submission happens out here, so we need to bubble up these form methods */
  const givexFormMethods = useForm<IGivexConfig>({ mode: 'all', values: givexOptions });
  const GiftCardFormMap: IGiftCardFormMap = {
    [GiftCardProvider.Givex]: (
      <FormProvider {...givexFormMethods}>
        <GivexForm loading={loading} options={givexOptions} tenderTypes={tenderTypes} />
      </FormProvider>
    ),
  };

  React.useEffect(() => {
    if (changedGiftCardProvider && provider !== changedGiftCardProvider) {
      dispatch(changeGiftCardProvider(companyId, locationId, changedGiftCardProvider));
    }
  }, [changedGiftCardProvider, dispatch, locationId, companyId, provider]);

  React.useEffect(() => {
    if (validated && provider && options) {
      const updatedOptions = provider === 'givex' ? givexOptions : options;

      dispatch(saveGiftCardConfig(companyId, locationId, user.name ?? '', provider, updatedOptions));
    }
  }, [validated, companyId, locationId, user.name, provider, options, dispatch, givexOptions]);

  const compatibleOptions: GroupedOption = {
    label: 'Compatible',
    options: [],
  };

  const incompatibleOptions: GroupedOption = {
    label: 'Incompatible',
    options: [],
  };

  // generates options for a gift card provider dropdown
  Object.values(GiftCardProvider).forEach((provider: string) => {
    const giftCardProvider = provider as GiftCardProvider;
    if (compatibleProviders.includes(giftCardProvider)) {
      compatibleOptions.options.push({
        label: GiftCardProviderLabels[giftCardProvider],
        value: giftCardProvider,
      });
    } else {
      incompatibleOptions.options.push({
        label: GiftCardProviderLabels[giftCardProvider],
        value: giftCardProvider,
        isDisabled: true,
      });
    }
  });

  const handleSubmit = async () => {
    // when 'none' option is selected, disable giftcard configuration
    if (changedGiftCardProvider === noneOption.value) {
      dispatch(toggleGiftCardConfig(companyId, locationId, false));
      return;
    }

    if (!provider || !options) {
      dispatch(toggleGiftCardEditForm(false));
      return;
    }

    if (provider === 'givex') {
      // for givex, the form handles validation. but we retrigger it here before saving.
      const isGivexFormValid = await givexFormMethods.trigger();
      if (isGivexFormValid) {
        dispatch(updateGiftCardFormValidation(true));
      }
    }
  };

  const handleConfirmationLeave = () => {
    if (changedGiftCardProvider) {
      dispatch(changeGiftCardProvider(companyId, locationId, changedGiftCardProvider));
      dispatch(setFormIsDirty(false));
    }
  };

  const handleConfirmationStay = () => {
    setChangedGiftCardProvider(provider);
  };

  const handleCancel = () => {
    dispatch(toggleGiftCardEditForm(false));
  };

  const handleProviderChange = (data: Option) => {
    const value = data.value as GiftCardProvider;
    if (value === provider) return;

    setChangedGiftCardProvider(value);
    dispatch(setFormIsDirty(true));
  };

  return (
    <Form
      hasGroups={true}
      onSubmit={handleSubmit}
      onConfirmNav={handleConfirmationLeave}
      onCancelNav={handleConfirmationStay}
      confirmCancelChanges={true}
    >
      <FormControl label='Provider'>
        <SelectFilter
          options={[noneOption, compatibleOptions, incompatibleOptions]}
          loading={loading}
          onChange={handleProviderChange}
          value={selectedOption}
        />
      </FormControl>
      {!loading && provider && options && validations && GiftCardFormMap[provider]}
      <FormActionButtons handleCancel={handleCancel} loading={loading} />
    </Form>
  );
};

export default GiftCardsEditPanel;
