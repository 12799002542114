import { ILocationOrderMessagingConfig } from '@ready/dashboardv2api.contracts';
import { updateClientOrderMessagingConfigThunk } from 'companyLocations/redux/reducers/orderMessagingConfig/thunk';
import { Form, FormActionButtons, FormControl } from 'components/Form';
import TextArea from 'components/TextArea/TextArea';
import TextInput from 'components/TextInput/TextInput';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { ContextParams } from 'types/ContextParams.interface';

export const EditPanel = ({ closeForm }: { closeForm: () => void }) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { orderMessagingEdit } = useAppSelector((state) => state.location.orderMessagingConfig);
  const dispatch = useAppDispatch();
  const { contextId: companyId, locationId } = useParams<ContextParams>();

  const { register, handleSubmit, reset } = useForm<ILocationOrderMessagingConfig>({
    values: {
      ...orderMessagingEdit,
    },
  });
  return (
    <Form
      hasGroups={true}
      onSubmit={handleSubmit(async (config: ILocationOrderMessagingConfig) => {
        setIsSaving(true);
        await dispatch(updateClientOrderMessagingConfigThunk({ companyId, locationId, config }));
        setIsSaving(false);
        closeForm();
      })}
    >
      <FormControl label='Alcohol Policy'>
        <TextArea
          {...register('alcoholPolicy')}
          placeholder={'E.g You must be over 21 and provide valid ID.'}
          loading={isSaving}
        />
      </FormControl>

      <FormControl label='Ordering Offline Message'>
        <TextArea
          {...register('disruptionMessage')}
          placeholder={'E.g. Ordering is unavailable at this time.'}
          loading={isSaving}
        />
      </FormControl>

      <FormControl label='Menu Announcements'>
        <TextArea
          {...register('menuAnnouncementsMessage')}
          placeholder={'E.g. Try our new seasonal menu items!'}
          loading={isSaving}
        />
      </FormControl>

      <FormControl label='Pickup Instructions'>
        <TextArea
          {...register('pickupInstructions')}
          placeholder={'E.g. See the host at the front to pick up your order.'}
          loading={isSaving}
        />
      </FormControl>

      <FormControl label='Pickup Location Name'>
        <TextInput {...register('pickupLocationName')} placeholder={'E.g. Lobby Pickup Counter'} loading={isSaving} />
      </FormControl>

      <FormControl label='Checkout Announcements '>
        <TextArea
          {...register('checkoutAnnouncementMessage')}
          placeholder={'E.g. Auto-gratuities are applied to parties of 8 or more.'}
          loading={isSaving}
        />
      </FormControl>

      <FormControl label='Order Confirmation'>
        <TextArea
          {...register('orderConfirmedMessage')}
          placeholder={'E.g. Thank you for your order. Please come again soon.'}
          loading={isSaving}
        />
      </FormControl>

      <FormActionButtons
        handleCancel={() => {
          reset();
          closeForm();
        }}
        loading={isSaving}
      />
    </Form>
  );
};
