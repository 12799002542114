import { startOfMonth, subMonths } from 'date-fns';
import { TextCell, createDateCell } from '../../components/TableV2/Cell/SpecialCells';
import { CellProps, createCellsWithMobileLayout } from '../../components/TableV2/Cell/Cell';
import { createSortableHeaderCell } from '../../components/TableV2/Cell/HeaderCell';
import styles from './PaymentsReportPage.module.scss';
import { CustomTextCell, PaymentStatusCell, PaymentTypeCell, TotalDollarCell } from './components/cells';
import classnames from 'classnames';
import React from 'react';
import { PaymentMethodCell } from 'components/TableV2/Cell/SpecialCells/PaymentMethodCell';
import { CardBrandCell } from 'components/TableV2/Cell/SpecialCells/CardBrandCell';

const rowKey = 'id';

const headerColumns = {
  locationName: 'Location',
  amount: 'Total',
  status: 'Status',
  type: 'Type',
  cardBrand: 'Card Brand',
  last4: 'Last 4',
  method: 'Method',
  checkNumber: 'Checks',
  invoiceId: 'Ready Invoice',
  dateCreated: 'Date Created',
  paymentId: 'Payment ID',
};

const columns = [
  'locationName',
  'amount',
  'status',
  'type',
  'cardBrand',
  'last4',
  'method',
  'checkNumber',
  'invoiceId',
  'dateCreated',
  'paymentId',
];

const cells = createCellsWithMobileLayout(
  {
    locationName: (props: CellProps) => (
      <CustomTextCell {...props} classes={classnames(props.classes, styles.paddingLeftDesktop50)} />
    ),
    amount: TotalDollarCell,
    status: PaymentStatusCell,
    type: PaymentTypeCell,
    cardBrand: CardBrandCell,
    last4: TextCell,
    method: PaymentMethodCell,
    checkNumber: TextCell,
    invoiceId: TextCell,
    dateCreated: createDateCell({
      dateGetter: (rowData) => rowData.dateCreated,
      timezoneGetter: (rowData) => rowData.timezone,
    }),
    paymentId: TextCell,
  },
  headerColumns,
  { titleClasses: styles.MobileHeaderCell }
);

const headerCells = {
  locationName: createSortableHeaderCell('locationName', { classes: styles.HeaderCellShorted50 }),
  amount: createSortableHeaderCell('amount', { classes: styles.HeaderCellShorted50, align: 'right' }),
  type: createSortableHeaderCell('type'),
  status: createSortableHeaderCell('status'),
  cardBrand: createSortableHeaderCell('cardBrand', { classes: styles.HeaderCellShorted50 }),
  last4: createSortableHeaderCell('last4', { classes: styles.HeaderCellShorted50 }),
  method: createSortableHeaderCell('method', { classes: styles.HeaderCellShorted50 }),
  checkNumber: createSortableHeaderCell('checkNumber', { classes: styles.HeaderCellShorted50 }),
  invoiceId: createSortableHeaderCell('invoiceId', { classes: styles.HeaderCellShorted50 }),
  dateCreated: createSortableHeaderCell('dateCreated', { classes: styles.HeaderCellShorted50 }),
  paymentId: createSortableHeaderCell('paymentId', { classes: styles.HeaderCellShorted50 }),
};

const endMonth = startOfMonth(new Date());
const startMonth = subMonths(endMonth, 1);
const initDateFilter = { endMonth, startMonth };

const maxDaysToChoose = 366;

export { rowKey, headerCells, columns, headerColumns, cells, initDateFilter, maxDaysToChoose };
