import { UIClientUser, ICompanyRoleSelector } from '../../../services/types/companyUsers.type';
import { FieldValidation } from '../../../types/FieldValidation.interface';

export interface IUsersForm extends UIClientUser {
  validation: IUsersFormValidation;
  selectedRoleIds: string[];
  processing: boolean;
  loading: boolean;
}

export interface IUsersFormValidation {
  usersForm: {
    firstName: FieldValidation;
    lastName: FieldValidation;
    email: FieldValidation;
    roles: FieldValidation[];
  };
}

export const getUsersCreateFormValidation = (roleCount: number): IUsersFormValidation => {
  const roles = [];
  for (let i = 0; i < roleCount; i++) {
    roles.push({
      hasError: false,
      error: '',
    });
  }
  return {
    usersForm: {
      firstName: {
        hasError: false,
        error: '',
      },
      lastName: {
        hasError: false,
        error: '',
      },
      email: {
        hasError: false,
        error: '',
      },
      roles,
    },
  };
};

export const usersFormCompanyRoleSelectorInitialState: ICompanyRoleSelector = {
  loading: false,
  query: '',
  roles: [],
};

export const usersFormInitialState: IUsersForm = {
  companyId: '',
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  uid: '',
  validation: getUsersCreateFormValidation(0),
  processing: false,
  loading: false,
  selectedRoleIds: [],
  enabled: true,
};
