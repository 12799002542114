import { UserStatusFilter } from '@ready/dashboardv2api.contracts';
import { IPaginatedAdmins } from './AdminsListState';
import { pageErrorState } from '../../redux/actions/uiActions/responseStateActions';
import AdminsService from '../services/AdminsService';

export const ADMINS_LIST_ACTION_TYPES = {
  ADMINS_LIST_BEGIN: 'ADMINS_LIST_BEGIN',
  ADMINS_LIST_SUCCESS: 'ADMINS_LIST_SUCCESS',
  ADMINS_LIST_ERROR: 'ADMINS_LIST_ERROR',
};

export const getAdminsList = (query: string, page: number, status: UserStatusFilter) => async (dispatch: any) => {
  try {
    dispatch(adminsListBegin());
    const paginatedAdmins = await AdminsService.getAdmins(query, page, status);

    dispatch(adminsListSuccess(paginatedAdmins));
  } catch (err) {
    dispatch(adminsListError());
    //@ts-ignore
    dispatch(pageErrorState(err.status, err.message));
  }
};
export const adminsListBegin = () => ({
  type: ADMINS_LIST_ACTION_TYPES.ADMINS_LIST_BEGIN,
});

export const adminsListSuccess = (payload: IPaginatedAdmins) => ({
  type: ADMINS_LIST_ACTION_TYPES.ADMINS_LIST_SUCCESS,
  payload,
});

export const adminsListError = () => ({
  type: ADMINS_LIST_ACTION_TYPES.ADMINS_LIST_ERROR,
});
