import { Visibility, Status } from '@ready/menu.core';
import Button from 'components/Button/Button';
import { Form } from 'components/Form';
import InputError from 'components/InputError/InputError';
import LinkButton from 'components/LinkButton/LinkButton';
import { Panel } from 'components/PanelLayout';
import TextAreaNestedLabel from 'components/TextAreaNestedLabel/TextAreaNestedLabel';
import TextInputNestedLabel from 'components/TextInputNestedLabel/TextInputNestedLabel';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import EditPanelFooter from './EditPanelFooter';
import SectionItems from './SectionItems';
import { deselectMenuItemFromItemGalleryItemGroup } from '../../redux/MenuBuilderActions';
import {
  menuGalleryUpdateGalleryName,
  menuGalleryUpdateDescription,
  removeMenuGallerySubitem,
  updateMenuGallerySubitemName,
  updateItemGalleryItemVisibility,
  updateItemGalleryItemGroupItemVisibility,
  updateItemGalleryItemGroupItemsSortOrder,
  menuGalleryUpdateStatus,
  stopEditingInnerItemGallery,
} from '../../redux/MenusActions';
import MenuBuilderConfig from '../../types/MenuBuilderConfig';
import styles from './EditItemGalleryPanel.module.scss';
import { ContextParams } from 'types/ContextParams.interface';
import { useParams } from 'react-router-dom';
import { isSharedMenu } from 'sharedMenuItems/sharedMenuItems.utils';
import { SharedMenuSectionItemGallery } from './SharedMenuSectionItemGallery';

interface Props {
  onItemGalleryDragItem: (oldIndex: number, newIndex: number) => void;
  onAddItems: () => void;
  onAddItemsToGroupClick: (id: string) => void;
  onSubmit: () => void;
  isSharedMenuAssignedToCurrentLocation?: boolean;
}

export const EditItemGalleryPanel = ({
  onItemGalleryDragItem,
  onAddItems,
  onAddItemsToGroupClick,
  onSubmit,
  isSharedMenuAssignedToCurrentLocation = false,
}: Props) => {
  const {
    menuGalleryForm: {
      processing: itemGalleryProcessing,
      menu: { itemGallery: itemGalleryEdit },
      validation: itemGalleryEditValidation,
      sectionItemChanges: itemGalleryChanges,
    },
  } = useAppSelector((state) => state.menuBuilder.menus);

  const { contextId: companyId, locationId } = useParams<ContextParams>();

  const dispatch = useAppDispatch();

  const showFooterWarningMessage =
    (itemGalleryEdit?.items?.length ?? 0) > MenuBuilderConfig.itemGalleryLimit
      ? `Item Gallery limit of ${MenuBuilderConfig.itemGalleryLimit} exceeded. Please remove some items before saving.`
      : '';

  return (
    <Panel fullContentArea>
      {isSharedMenuAssignedToCurrentLocation ? (
        <SharedMenuSectionItemGallery onSave={onSubmit} />
      ) : (
        <Form hasGroups onSubmit={onSubmit}>
          <div className={styles.itemGalleryFormHeader}>
            <div className={styles.itemGalleryFormHeaderInputs}>
              <TextInputNestedLabel
                value={itemGalleryEdit?.displayName ?? ''}
                label='Item Gallery Name'
                placeholder='Item Gallery Name (optional)'
                onChange={(e) => dispatch(menuGalleryUpdateGalleryName(e.target.value.toString()))}
                loading={itemGalleryProcessing}
                withError={itemGalleryEditValidation.displayName.hasError}
                maxLength={60}
              />
              {itemGalleryEditValidation.displayName.hasError && (
                <InputError message={itemGalleryEditValidation.displayName.errorMessage} />
              )}
              <TextAreaNestedLabel
                value={itemGalleryEdit?.description ?? ''}
                onChange={(e) => dispatch(menuGalleryUpdateDescription(e.target.value.toString()))}
                label='Description'
                placeholder='Enter a description which will be displayed to your guests.'
                loading={itemGalleryProcessing}
                maxLength={100}
              />
            </div>
            <div>
              <Button
                variant='secondary'
                label='+ Add Items'
                onClick={onAddItems}
                unavailable={itemGalleryProcessing}
              />
            </div>
          </div>

          {itemGalleryEdit && itemGalleryEdit.items.length > 0 && (
            <SectionItems
              items={itemGalleryEdit.items}
              itemChanges={itemGalleryChanges}
              processing={itemGalleryProcessing}
              onRemoveItem={(itemId: string) => dispatch(removeMenuGallerySubitem(itemId))}
              onRemoveGroupItem={(itemGroupId: string, menuItemId: string) =>
                dispatch(deselectMenuItemFromItemGalleryItemGroup(itemGroupId, menuItemId))
              }
              onChangeItemName={(itemId: string, displayName: string) =>
                dispatch(updateMenuGallerySubitemName(itemId, displayName))
              }
              onChangeItemVisibility={(itemId, visible) =>
                dispatch(updateItemGalleryItemVisibility(itemId, visible ? Visibility.visible : Visibility.hidden))
              }
              onChangeGroupItemVisibility={(groupId: string, itemId, visible) =>
                dispatch(
                  updateItemGalleryItemGroupItemVisibility(
                    groupId,
                    itemId,
                    visible ? Visibility.visible : Visibility.hidden
                  )
                )
              }
              onAddItemsToGroupClick={onAddItemsToGroupClick}
              onMoveItemInGroup={(groupId: string, sourceIndex: number, destinationIndex: number) =>
                dispatch(updateItemGalleryItemGroupItemsSortOrder(groupId, sourceIndex, destinationIndex))
              }
              onMoveItem={onItemGalleryDragItem}
              isSharedMenu={isSharedMenu(companyId, locationId)}
            />
          )}

          {itemGalleryEdit && !itemGalleryEdit.items.length && (
            <div className={styles.itemGalleryNoItems}>
              <div className={styles.itemGalleryNoItemsText}>
                <LinkButton onClick={onAddItems}>+ Add items</LinkButton>
                <span>to this gallery to promote them to your guests (max 5 items).</span>
              </div>
            </div>
          )}

          <EditPanelFooter
            onCancel={() => dispatch(stopEditingInnerItemGallery())}
            onChangeStatus={(enabled) => dispatch(menuGalleryUpdateStatus(enabled ? Status.enabled : Status.disabled))}
            saveLabel='Save Changes'
            status={itemGalleryEdit!.status}
            statusLabel='Status:'
            processing={itemGalleryProcessing}
            warningMessage={showFooterWarningMessage}
            isSharedMenu={isSharedMenu(companyId, locationId)}
          />
        </Form>
      )}
    </Panel>
  );
};
