import { createAsyncThunk } from '@reduxjs/toolkit';
import { interceptErrorWithToastMessage } from '../../../../redux/helpers/interceptErrorWithToastMessage';
import { getCheckDetail } from '../services';
import { manualEnterPosPayment, manualRetryPosPayment } from '../../../../services/transactionsService';
import { set200Toast, toastErrorState } from '../../../../redux/actions/uiActions/responseStateActions';
import { CheckDetailsFailedPayment } from '../components/PaymentNotAppliedAlert';

export const fetchCheckDetailsThunk = createAsyncThunk(
  'checks/checkDetail/fetchData',
  interceptErrorWithToastMessage(getCheckDetail)
);

export const fetchManualEntryPaymentCompletedThunk = createAsyncThunk(
  'checkDetail/ManualEntryPaymentCompleted',
  async (
    {
      contextId,
      locationId,
      failedPayments,
      checkId,
    }: {
      contextId: string;
      locationId: string;
      checkId: string;
      failedPayments: Array<CheckDetailsFailedPayment>;
    },
    thunkAPI
  ): Promise<void> => {
    const requests: Array<Promise<boolean>> = failedPayments.map(
      async ({ invoiceId: transactionId, pospaymentLastInfo: { _id } }) => {
        try {
          const res = await manualEnterPosPayment(contextId, locationId, transactionId, _id);
          if (res) {
            thunkAPI.dispatch(set200Toast('Manual payment completed successes'));
            return true;
          }
          thunkAPI.dispatch(toastErrorState(undefined, 'Manual payment complete fail'));
        } catch (e) {
          thunkAPI.dispatch(toastErrorState(e.status, e.message));
        }
        return false;
      }
    );
    const res = await Promise.all(requests);

    if (!res.every((result) => !result)) {
      thunkAPI.dispatch(fetchCheckDetailsThunk({ locationId, contextId, checkId }));
    }
  }
);

export const fetchRetryPOSPaymentThunk = createAsyncThunk(
  'checkDetail/RetryPOSPayment',
  async (
    {
      contextId,
      locationId,
      checkId,
      failedPayments,
    }: {
      contextId: string;
      locationId: string;
      checkId: string;
      failedPayments: Array<CheckDetailsFailedPayment>;
    },
    thunkAPI
  ): Promise<void> => {
    const requests: Array<Promise<boolean>> = failedPayments.map(
      async ({ invoiceId: transactionId, pospaymentLastInfo: { subtotal, tip } }) => {
        try {
          const res = await manualRetryPosPayment(contextId, locationId, transactionId, checkId, subtotal, tip);
          if (res) {
            thunkAPI.dispatch(set200Toast('Retry POS payment successes'));
            return true;
          }
          thunkAPI.dispatch(toastErrorState(500, 'It was not possible to retry this payment.'));
        } catch (e) {
          thunkAPI.dispatch(toastErrorState(e.status, e.message));
        }
        return false;
      }
    );

    const res = await Promise.all(requests);
    if (!res.every((result) => !result)) {
      thunkAPI.dispatch(fetchCheckDetailsThunk({ locationId, contextId, checkId }));
    }
  }
);
