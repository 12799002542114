import produce from 'immer';
import { UI_ACTIONS } from 'redux/actions/types';
import { overflowButtonState } from 'redux/initialStates/ui/overflowButtonState';
import { Action } from '../../types';

export const overflowButtonReducer = (state = overflowButtonState, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case UI_ACTIONS.UPDATE_CURRENTLY_OPEN_DROPDOWN_OVERFLOW_BUTTON_ID:
        draft.currentlyOpenOverflowButtonId = action.payload;
        return draft;

      default:
        return draft;
    }
  });
};
