import { combineReducers } from 'redux';
import { feedbackReducer } from '../../../reports/feedback/redux';
import { emailMarketingListReducer } from '../../../reports/emailMarketingList/redux';
import { checksReportReducer } from '../../../reports/checks/reducer';
import { promoCodeSummaryReducer } from '../../../reports/promoCodeSummary/redux';
import { locationPerformanceReducer } from '../../../reports/locationPerformance/redux';
import { reducer as paymentsReportReducer } from 'reports/payments/redux';
import { reducer as dailyTotalsSummaryReducer } from 'reports/dailyTotalsSummary/redux';
import { reducer as payoutHistoryReportReducer } from 'reports/payoutHistory/redux';
import { reducer as payoutHistoryDetailsReportReducer } from 'reports/payoutHistoryDetails/redux';

export const reportsReducer = combineReducers({
  feedback: feedbackReducer,
  emailMarketingList: emailMarketingListReducer,
  checks: checksReportReducer,
  promoCodeSummary: promoCodeSummaryReducer,
  locationPerformance: locationPerformanceReducer,
  payments: paymentsReportReducer,
  dailyTotalsSummary: dailyTotalsSummaryReducer,
  payoutHistory: payoutHistoryReportReducer,
  payoutHistoryDetails: payoutHistoryDetailsReportReducer
});
