import React from 'react';
// components
import { FormControl } from '../../../components/Form';
import TextInput from '../../../components/TextInput/TextInput';
import { LOCATION_SETTINGS_ACTIONS } from '../../redux/LocationSettingsActions';
import { IPosSettingsPanel } from '../../redux/PanelPosSettingsState';
import { IUpdateLocationParam } from '../LocationsEditPage';

interface Props {
  isProcessing: boolean;
  updateLocationParam: IUpdateLocationParam;
  posSettingsPanel: IPosSettingsPanel;
}

const Bypass2POS = (props: Props) => {
  const { isProcessing, updateLocationParam, posSettingsPanel } = props;

  return (
    <FormControl label='Bypass Location ID'>
      <TextInput
        placeholder='Bypass Location ID'
        onChange={(event) => {
          updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_POS_BYPASS2_LOCATION_ID);
        }}
        value={posSettingsPanel.optionsBypass2.locationId}
        loading={isProcessing}
      />
    </FormControl>
  );
};

export default Bypass2POS;
