import { ICheckDetails } from '@ready/dashboardv2api.contracts';
import executeApiAsync from '../../../services/utils/executeApiAsync';
import config from '../../../config';

const BASE_URL = `${config.readyDashboardApiEndpoint}/companies`;

export const getCheckDetail = ({
  checkId,
  contextId,
  locationId,
}: {
  checkId: string;
  contextId: string;
  locationId: string;
}) => {
  return executeApiAsync<ICheckDetails>(
    `${BASE_URL}/${contextId}/locations/${locationId}/reports/checks/${checkId}/details`
  );
};
