import { ILocationLoyaltyOptions } from '@ready/dashboardv2api.contracts';

export interface Option {
  value: string | number;
  label: string;
  subLabel?: string;
}

export interface ISpendgoValidations {
  storeCode: {
    hasError: boolean;
    errorMessage: string;
  };
  accountId: {
    hasError: boolean;
    errorMessage: string;
  };
  loyaltyDiscount: {
    hasError: boolean;
    errorMessage: string;
  };
  apiKey: {
    hasError: boolean;
    errorMessage: string;
  };
  accountDetailsUrl: {
    hasError: boolean;
    errorMessage: string;
  };
  clientSecret: {
    hasError: boolean;
    errorMessage: string;
  };
}

export interface IByPassValidations {
  locationId: {
    hasError: boolean;
    errorMessage: string;
  };
}

export interface IPunchhValidations {
  storeId: {
    hasError: boolean;
    errorMessage: string;
  };
  loyaltyDiscount: {
    hasError: boolean;
    errorMessage: string;
  };
}

export interface ISquareValidations {
  location: {
    hasError: boolean;
    errorMessage: string;
  };
  programId: {
    hasError: boolean;
    errorMessage: string;
  };
  apiKey: {
    hasError: boolean;
    errorMessage: string;
  };
  loyaltyDiscount: {
    hasError: boolean;
    errorMessage: string;
  };
}

export interface IPosLoyaltyPanel extends ILocationLoyaltyOptions {
  discounts: Option[];
  locations: Option[];
  loadingDiscounts: boolean;
  loadingLocations: boolean;
  validations: {
    spendgo: ISpendgoValidations;
    byPass: IByPassValidations;
    square: ISquareValidations;
    punchh: IPunchhValidations;
  };
}

export interface ILoyaltyValidationsResult {
  errorsFound: boolean;
  fields: ISpendgoValidations | IByPassValidations | ISquareValidations | IPunchhValidations;
}

export const newSpendgoValidations = (): ISpendgoValidations => ({
  storeCode: {
    hasError: false,
    errorMessage: '',
  },
  accountId: {
    hasError: false,
    errorMessage: '',
  },
  loyaltyDiscount: {
    hasError: false,
    errorMessage: '',
  },
  apiKey: {
    hasError: false,
    errorMessage: '',
  },
  accountDetailsUrl: {
    hasError: false,
    errorMessage: '',
  },
  clientSecret: {
    hasError: false,
    errorMessage: '',
  },
});

export const newSquareValidations = (): ISquareValidations => ({
  location: {
    hasError: false,
    errorMessage: '',
  },
  programId: {
    hasError: false,
    errorMessage: '',
  },
  apiKey: {
    hasError: false,
    errorMessage: '',
  },
  loyaltyDiscount: {
    hasError: false,
    errorMessage: '',
  },
});

export const newByPassValidations = (): IByPassValidations => ({
  locationId: {
    hasError: false,
    errorMessage: '',
  },
});

export const newPunchhValidations = (): IPunchhValidations => ({
  storeId: {
    hasError: false,
    errorMessage: '',
  },
  loyaltyDiscount: {
    hasError: false,
    errorMessage: '',
  },
});

export const posLoyaltyPanelInitialState: IPosLoyaltyPanel = {
  provider: undefined,
  spendgoOptions: {
    locationId: '',
    brandId: '',
    loyaltyDiscount: '',
    accountDetailsUrl: '',
    clientId: '',
    clientSecret: '',
  },
  byPassOptions: {
    locationId: '',
  },
  squareOptions: {
    locationId: '',
    programId: '',
    apiKey: '',
    loyaltyDiscount: '',
    skipPhoneVerification: false,
  },
  punchhOptions: {
    storeId: '',
    loyaltyDiscount: '',
  },
  discounts: [],
  locations: [],
  loadingDiscounts: false,
  loadingLocations: false,
  validations: {
    spendgo: newSpendgoValidations(),
    byPass: newByPassValidations(),
    square: newSquareValidations(),
    punchh: newPunchhValidations(),
  },
};
