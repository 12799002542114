import styles from './PunchhSettings.module.scss';
import Button from 'components/Button/Button';
import { Panel } from 'components/PanelLayout';
import React, { useState } from 'react';
import { PunchhSettingsEditPanel } from './components/PunchhSettingsEditPanel';
import { PunchhSettingsViewPanel } from './components/PunchhSettingsViewPanel';
import { selectCompanyPunchOptions } from '../redux/selectors';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { saveCompanyLoyaltyConfigThunk } from '../redux/thunks/saveCompanyLoyaltyConfigThunk';
import { useCompanyContextParams } from 'hooks';
import { mapToApiContract, mapToUi } from './mapper';

export type PunchhSettingsProps = {
  clientId?: string;
  clientSecret?: string;
  serverUrl?: string;
  firstNameRequired?: boolean;
  lastNameRequired?: boolean;
  phoneNumberRequired?: boolean;
  postalCodeRequired?: boolean;
  birthdayRequired?: boolean;
  termsUrl?: string;
  termsRequired?: boolean;
};

export const PunchhSettingsPanel = () => {
  const dispatch = useAppDispatch();
  const { contextId: companyId } = useCompanyContextParams();
  const [editModeActive, setEditModeActive] = useState<boolean>(false);

  const headerControls = (
    <div className={styles.panelHeaderControlButton}>
      <Button label={'Edit'} onClick={() => setEditModeActive(true)} />
    </div>
  );

  const isLoading = useAppSelector((state) => state.companies.companySettings.loyalty.isLoading);
  const punchhOptions = useAppSelector(selectCompanyPunchOptions);
  const punchhSettings = mapToUi(punchhOptions);

  return (
    <Panel title='Punchh Settings' headerControls={editModeActive ? undefined : headerControls}>
      {editModeActive ? (
        <PunchhSettingsEditPanel
          punchSettings={punchhSettings}
          onSave={async (updated: PunchhSettingsProps) => {
            await dispatch(saveCompanyLoyaltyConfigThunk({ companyId, options: mapToApiContract(updated) }));
            setEditModeActive(false);
          }}
          onCancel={() => setEditModeActive(false)}
        />
      ) : (
        <PunchhSettingsViewPanel {...punchhSettings} loading={isLoading} />
      )}
    </Panel>
  );
};
