import React, { useEffect } from 'react';
import { PunchhSettingsPanel } from './punchh/PunchhSettings';
import { useAppDispatch } from 'redux/store';

import { getCompanyLoyaltyConfigThunk } from './redux/thunks/getCompanyLoyaltyConfigThunk';
import { useCompanyContextParams } from 'hooks';

import { SimplePanelLayout } from 'components/PanelLayout/SimplePanelLayout';

export const LoyaltyTabContents = () => {
  const dispatch = useAppDispatch();
  const { contextId: companyId } = useCompanyContextParams();

  useEffect(() => {
    dispatch(getCompanyLoyaltyConfigThunk({ companyId }));
  }, [companyId, dispatch]);

  return (
    <SimplePanelLayout>
      <PunchhSettingsPanel />
    </SimplePanelLayout>
  );
};
