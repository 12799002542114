import React from 'react';
import styles from './GiftCardsViewPanel.module.scss';
import classNames from 'classnames';

import { GiftCardProviderLabels, ITenderType } from 'companyLocations/redux/initialStates/paymentProcessing';
import { DetailLayout, DetailElement } from 'components/DetailLayout';
import GivexView from './givex/GivexView';
import { GiftCardProvider, IGiftCardConfig, IGivexConfig } from '@ready/dashboardv2api.contracts';

export interface GiftCardsViewPanelProps {
  giftCardEnabled?: boolean;
  tenderTypes: ITenderType[];
  provider?: GiftCardProvider;
  options?: IGiftCardConfig;
}

interface GiftCardDetailContentProps {
  content?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  className?: string;
  showIcon?: boolean;
}

interface NestedDetailContentProps {
  children: React.ReactNode;
  className?: string;
}

interface IGiftCardViewMap {
  [key: string]: JSX.Element;
}

export const GiftCardDetailContent = ({
  content,
  disabled,
  children,
  className: extraClassName,
  showIcon = false,
}: GiftCardDetailContentProps): JSX.Element => (
  <>
    <p className={classNames(styles.optionContent, disabled ? styles.disabled : styles.enabled, extraClassName)}>
      {showIcon ? <i className={`icon-dashboard-icons_${disabled ? 'remove' : 'checkmark'}`}></i> : null}
      {content}
    </p>
    {children}
  </>
);

export const NestedDetailContent = ({ children, className: extraClassName }: NestedDetailContentProps): JSX.Element => (
  <div className={classNames(styles.nestedDetailContent, extraClassName)}>{children}</div>
);

const GiftCardsViewPanel = (props: GiftCardsViewPanelProps) => {
  const { provider, options, tenderTypes, giftCardEnabled = false } = props;

  const GiftCardViews: IGiftCardViewMap = {
    [GiftCardProvider.Givex]: <GivexView {...(options as IGivexConfig)} tenderTypes={tenderTypes} />,
  };

  return (
    <DetailLayout>
      <DetailElement label='Provider'>
        {provider && giftCardEnabled ? (
          <p>{GiftCardProviderLabels[provider]}</p>
        ) : (
          <p className={styles.noSelectedText}>
            <i>No provider selected</i>
          </p>
        )}
      </DetailElement>
      <>{provider && giftCardEnabled && GiftCardViews[provider]}</>
    </DetailLayout>
  );
};

export default GiftCardsViewPanel;
