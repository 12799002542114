import React from 'react';
import TextIcon, { Icon } from '../../../../components/Icon/TextIcon';
import classnames from 'classnames';
import styles from './Stars.module.scss';

interface StarsProps {
  starsCount: number;
  classes?: string;
  starClasses?: string;
}

export function Stars({ starsCount, classes, starClasses }: StarsProps) {
  return (
    <div className={classnames(styles.stars, classes)}>
      {Array(starsCount)
        .fill(null)
        .map((ignored, index) => (
          <TextIcon
            key={index}
            additionalStyles={classnames(styles.star, styles[`star--${starsCount}`], starClasses)}
            icon={Icon.StarCircle}
          />
        ))}
    </div>
  );
}
