import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TablesTab } from '../../../enums/tables.enum';
import TablesLayoutContent from '../TablesDetails/TablesLayoutContent';
import PromoCodesView from './PromoCodesView';
import { ContextParams } from '../../../types/ContextParams.interface';
import { paymentProcessingSelector } from '../../redux/selectors/PaymentProcessingSelector';
import { selectLocationPromoCode } from '../../redux/selectors/promoCodesSelector';
import { fetchLocation } from '../../redux/actions/paymentProcessingActions';
import { getPromoCodeEnabledStatus, updatePromoCodeEnabledStatus } from '../../redux/actions/promoCodeActions';
import FormActionBar from '../../../components/FormActionBar/FormActionBar';
import { selectPermissions } from '../../../redux/selectors/sessionSelectors/sessionSelectors';
import {
  PromoCodesResourceActions,
  PrincipalPermissions,
  ResourceType,
  SecurityScope,
  Verifier,
} from '@ready/security.core';
import { pageErrorState } from '../../../redux/actions/uiActions/responseStateActions';
import { setFormIsDirty } from 'redux/actions/uiActions/formStateActions';
import { useAppDispatch } from 'redux/store';

export interface PromoCodesPageProps {}

const PromoCodesPage = (props: PromoCodesPageProps) => {
  const { location } = useSelector(paymentProcessingSelector);
  const dispatch = useAppDispatch();
  const { contextId, locationId } = useParams<ContextParams>();

  React.useEffect(() => {
    if (contextId && locationId && (!location || location.id !== locationId)) {
      dispatch(fetchLocation(contextId, locationId));
    }
  }, [contextId, locationId, location, dispatch]);

  // get promo code enabled status
  const [promoCodesEnabled, setPromoCodesEnabled] = React.useState(false);
  const [promoCodesEnabledCache, setPromoCodesEnabledCache] = React.useState(false);
  React.useEffect(() => {
    if (contextId && locationId) {
      dispatch(getPromoCodeEnabledStatus(locationId, contextId));
    }
  }, [contextId, dispatch, locationId]);
  const { promoCodeEnabledStatus } = useSelector(selectLocationPromoCode);
  React.useEffect(() => {
    if (!promoCodeEnabledStatus.updating) {
      setPromoCodesEnabledCache(promoCodeEnabledStatus.enabled);
      setPromoCodesEnabled(promoCodeEnabledStatus.enabled);
    }
  }, [promoCodeEnabledStatus.enabled, promoCodeEnabledStatus.updating]);

  // dismiss "unsaved changes" modal when component unmounts
  React.useEffect(() => {
    return () => {
      dispatch(setFormIsDirty(false));
    };
  }, [dispatch]);

  // update promo code enabled status
  const [actionBarVisible, setActionBarVisible] = React.useState(false);
  const onResetForm = () => {
    setPromoCodesEnabled(promoCodesEnabledCache);
    setActionBarVisible(false);
    dispatch(setFormIsDirty(false));
  };
  const onPromoCodeStatusChange = (status: boolean) => {
    setPromoCodesEnabled(status);
    setActionBarVisible(true);
    dispatch(setFormIsDirty(true));
  };
  const onPromoCodeUpdated = () => {
    setActionBarVisible(false);
  };
  const onFormSubmit = () => {
    dispatch(updatePromoCodeEnabledStatus(promoCodesEnabled, locationId, onPromoCodeUpdated, contextId));
    dispatch(setFormIsDirty(false));
  };

  // permission look up
  const { permissionsList } = useSelector(selectPermissions);
  const hasPermissionAll = Verifier.check(
    new PrincipalPermissions(permissionsList),
    SecurityScope.location,
    ResourceType.promoCodes,
    PromoCodesResourceActions.all,
    locationId
  );
  const hasPermissionView = Verifier.check(
    new PrincipalPermissions(permissionsList),
    SecurityScope.location,
    ResourceType.promoCodes,
    PromoCodesResourceActions.view,
    locationId
  );

  if (!hasPermissionView) {
    dispatch(pageErrorState(401));
  }

  return (
    <>
      <FormActionBar
        actionBarVisible={actionBarVisible && hasPermissionAll}
        handleFormSubmit={onFormSubmit}
        resetForm={onResetForm}
        isFormProcessing={promoCodeEnabledStatus.updating}
      />
      <TablesLayoutContent
        companyId={contextId}
        locationId={locationId}
        locationName={location && location?.id === locationId ? location.name : null}
        tab={TablesTab.PromoCodes}
      >
        <PromoCodesView
          companyId={contextId}
          locationId={locationId}
          promoCodesEnabled={promoCodesEnabled}
          promoCodeStatusProcessing={promoCodeEnabledStatus.updating}
          onPromoCodeStatusChange={onPromoCodeStatusChange}
          hasPermission={hasPermissionAll}
        />
      </TablesLayoutContent>
    </>
  );
};

export default PromoCodesPage;
