import React, { useCallback } from 'react';
import NavigationLink, { checkIsCurrentRoute } from './NavigationLink';
import { ISidebarOption } from '../../redux/initialStates/session/session';
import TextIcon, { Icon } from '../Icon/TextIcon';
import { useLocation } from 'react-router-dom';
import styles from './app-layout.module.scss';

export interface MultiLevelSidebarProps {
  option: ISidebarOption;
  redirectPrefix: string;
}

const MultiLevelSidebar = (props: MultiLevelSidebarProps) => {
  const { option, redirectPrefix } = props;
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [allowAutoExpand, setAllowAutoExpand] = React.useState(false);

  const onExpanderClicked = () => {
    setIsExpanded(!isExpanded);
    setAllowAutoExpand(isExpanded);
  };
  const { pathname } = useLocation();

  const toggleSidebar = useCallback(() => setIsExpanded(!allowAutoExpand), [setIsExpanded, allowAutoExpand]);

  const children = (option.children ?? []).filter(child => child.enabled && child.hasPermission);
  const childrenIsSelected: boolean[] = children.map((child: ISidebarOption) =>
    checkIsCurrentRoute(pathname, redirectPrefix + child.url)
  );
  const thisIsSelected = childrenIsSelected.some((selected: boolean) => selected);

  return (
    <>
      <div className={styles.sidebarNavigationLinkNested}>
        <button type='button' className={styles.multiLevelSidebarButton} onClick={onExpanderClicked}>
          <span className={thisIsSelected ? styles.multiLevelSidebarLabelActive : styles.multiLevelSidebarLabel}>
            {option.label}
          </span>
          <TextIcon icon={isExpanded ? Icon.AngleDown : Icon.AngleRight} additionalStyles={styles.expandIcon} />
        </button>
      </div>
      <div className={!isExpanded ? styles.displayNone : ''}>
        {children.map((child: ISidebarOption, index: number) => (
          <NavigationLink
            key={index}
            to={redirectPrefix + child.url}
            toggleSidebar={toggleSidebar}
            label={child.label}
            isIndented
            isCurrentRoute={childrenIsSelected[index]}
          />
        ))}
      </div>
    </>
  );
};

export default MultiLevelSidebar;
