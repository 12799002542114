import config from '../config';
import { IRole } from '@ready/security.core';
import executeApiAsync from './utils/executeApiAsync';

const BASE_URL = `${config.readyDashboardApiEndpoint}/companies`;

export class CompanyRoleService {
  public static async getCompanyRoles(companyId: string, query: string, limit: number = 50): Promise<IRole[]> {
    const url = `${BASE_URL}/${companyId}/roles?searchTerm=${query}&pageNumber=1&pageSize=${limit}`;
    return executeApiAsync<IRole[]>(url);
  }
}
