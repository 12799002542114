import React from 'react';
import { ITag } from '@ready/menu.core';
import { useDispatch } from 'react-redux';
import Tag from '../../../tagsBadges/components/Tag';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';
import styles from './TagElement.module.scss';
import { setFormIsDirty } from 'redux/actions/uiActions/formStateActions';
import { compositeStyles } from 'utils/cssUtils';

export interface TagElementProps {
  tag: ITag;
  removeTag: (tagId: string) => void;
  readonly?: boolean;
}

const TagElement = (props: TagElementProps) => {
  const { tag, removeTag, readonly = false } = props;

  const dispatch = useDispatch();
  const handleRemoveTag = () => {
    removeTag(tag._id);
    dispatch(setFormIsDirty(true));
  };

  return (
    <div className={compositeStyles([styles.tagElement, { readonly: readonly }], styles)}>
      <div className={styles.tagElementDetails}>
        <Tag item={tag} />
        <span>{tag.name}</span>
        {!readonly && (
          <button type='button' className={styles.removeButton} onClick={handleRemoveTag}>
            <TextIcon icon={Icon.RemoveCircle} additionalStyles={styles.removeIcon} />
          </button>
        )}
      </div>
    </div>
  );
};

export default TagElement;
