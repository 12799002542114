import { ISmsConfig, ISmsNotificationsConfig, ILocationSmsConfigEdit } from '@ready/dashboardv2api.contracts';
import config from '../../config';
import executeApiAsync from '../../services/utils/executeApiAsync';
import { IOrderSmsMessagesValidationResult } from '../redux/initialStates/orderSMSMessagesInitialState';

export class OrderSmsMessagesService {
  public static validateOrderSmsMessages = (config: ISmsConfig): IOrderSmsMessagesValidationResult => {
    const result = {
      errorsFound: false,
      config: {
        orderPlaced: {
          hasError: false,
          errorMessage: '',
        },
        orderInProgress: {
          hasError: false,
          errorMessage: '',
        },
        orderReady: {
          hasError: false,
          errorMessage: '',
        },
        orderCancelled: {
          hasError: false,
          errorMessage: '',
        },
        pickUpTimeUpdated: {
          hasError: false,
          errorMessage: '',
        },
      },
    };
    if (config.orderPlaced?.isActive === true && config.orderPlaced.value === '') {
      result.errorsFound = true;
      result.config.orderPlaced.hasError = true;
      result.config.orderPlaced.errorMessage = 'Message is required';
    }
    if (config.orderInProgress?.isActive === true && config.orderInProgress.value === '') {
      result.errorsFound = true;
      result.config.orderInProgress.hasError = true;
      result.config.orderInProgress.errorMessage = 'Message is required';
    }
    if (config.orderReady?.isActive === true && config.orderReady.value === '') {
      result.errorsFound = true;
      result.config.orderReady.hasError = true;
      result.config.orderReady.errorMessage = 'Message is required';
    }
    if (config.orderCancelled?.isActive === true && config.orderCancelled.value === '') {
      result.errorsFound = true;
      result.config.orderCancelled.hasError = true;
      result.config.orderCancelled.errorMessage = 'Message is required';
    }
    if (config.pickUpTimeUpdated?.isActive === true && config.pickUpTimeUpdated.value === '') {
      result.errorsFound = true;
      result.config.pickUpTimeUpdated.hasError = true;
      result.config.pickUpTimeUpdated.errorMessage = 'Message is required';
    }
    return result;
  };

  static BASE_URL = `${config.readyDashboardApiEndpoint}`;

  public static getOrderSmsMessagesConfig = async (
    companyId: string,
    locationId: string
  ): Promise<ISmsNotificationsConfig> => {
    const url = `${OrderSmsMessagesService.BASE_URL}/companies/${companyId}/locations/${locationId}/orderSms`;
    return executeApiAsync<ISmsNotificationsConfig>(url);
  };

  public static updateOrderSmsMessagesLocationConfig = async (
    companyId: string,
    locationId: string,
    orderSmsLocationNotifications: ILocationSmsConfigEdit
  ): Promise<ILocationSmsConfigEdit> => {
    const url = `${OrderSmsMessagesService.BASE_URL}/companies/${companyId}/locations/${locationId}/orderSms`;
    return executeApiAsync<ILocationSmsConfigEdit>(url, {
      method: 'PUT',
      body: JSON.stringify(orderSmsLocationNotifications),
    });
  };

  public static updateOrderSmsMessagesCompanyConfig = async (
    companyId: string,
    orderSmsCompanyConfig: ISmsConfig
  ): Promise<ISmsConfig> => {
    const url = `${OrderSmsMessagesService.BASE_URL}/companies/${companyId}/orderSms`;
    return executeApiAsync<ISmsConfig>(url, {
      method: 'PUT',
      body: JSON.stringify(orderSmsCompanyConfig),
    });
  };
}
