import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { AppState } from '../../../redux/initialStates/AppState';
import { IMenuItem, IMenuItemGroup } from '@ready/menu.core';
import ItemsAndGroupsView from '../../types/ItemsAndGroupsView.enum';

import styles from './AddItemsAndGroupsList.module.scss';

import InfiniteScrollingList from '../../../components/InfiniteScrollingList/InfiniteScrollingList';
import AddMenuItemsListItem from '../ItemsAndMods/AddMenuItemsListItem';
import AddItemGroupsListItem from '../ItemGroups/AddItemGroupsListItem';

const mapStateToProps = (state: AppState) => {
  const {
    readyToLoad,
    loading,
    error,
    view,
    query,
    page,
    paginationLoading,
    paginationAvailable,
    filtered,
    items,
    selectedIds,
  } = state.menuBuilder.menus.itemsAndGroupsSelection;
  return {
    readyToLoad,
    loading,
    error,
    view,
    query,
    page,
    paginationLoading,
    paginationAvailable,
    filtered,
    items,
    selectedIds,
  };
};

const connector = connect(mapStateToProps);

export type AddItemsAndGroupsListProps = ConnectedProps<typeof connector> & {
  loadItems: (query: string | undefined) => void;
  loadMoreItems: (query: string | undefined, page: number) => void;
  addItemToSelection: ((menuItem: IMenuItem) => void) | ((itemGroup: IMenuItemGroup) => void);
  removeItemFromSelection: (itemId: string) => void;
};

const buildMenuItemComponent = (menuItem: IMenuItem, controls: JSX.Element | null): JSX.Element => (
  <AddMenuItemsListItem menuItem={menuItem} controls={controls!} />
);

const buildItemGroupComponent = (itemGroup: IMenuItemGroup, controls: JSX.Element | null): JSX.Element => (
  <AddItemGroupsListItem itemGroup={itemGroup} controls={controls!} />
);

const AddItemsAndGroupsList = (props: AddItemsAndGroupsListProps): JSX.Element => {
  const {
    readyToLoad,
    loading,
    error,
    view,
    query,
    page,
    paginationLoading,
    paginationAvailable,
    filtered,
    items,
    selectedIds,
    loadItems,
    loadMoreItems,
    addItemToSelection,
    removeItemFromSelection,
  } = props;

  const noItemsTitle = view === ItemsAndGroupsView.ITEMS ? 'No Results Found' : 'Create Your First Item Group';
  const noItemsMessage =
    view === ItemsAndGroupsView.ITEMS
      ? 'Make sure the items you are looking for have been created in your point of sale, or try refining your search.'
      : `Make it easy for guests to browse your menu by grouping similar items
       together. For instance, you might create an item group for a drink that
       is available in different sizes`;

  const handleLoadItems = React.useCallback((): void => {
    loadItems(query);
  }, [loadItems, query]);

  const handleLoadMoreItems = React.useCallback((): void => {
    loadMoreItems(query, page);
  }, [loadMoreItems, query, page]);

  const getItemKey = React.useCallback((item: IMenuItem | IMenuItemGroup): string => item._id, []);

  const buildComponent = view === ItemsAndGroupsView.ITEMS ? buildMenuItemComponent : buildItemGroupComponent;

  return (
    <InfiniteScrollingList
      readyToLoad={readyToLoad}
      loading={loading}
      error={error}
      paginationLoading={paginationLoading}
      paginationAvailable={paginationAvailable}
      items={items}
      filtered={filtered}
      noItemsTitle={noItemsTitle}
      noItemsMessage={noItemsMessage}
      selectedItemKeys={selectedIds}
      loadItems={handleLoadItems}
      loadMoreItems={handleLoadMoreItems}
      getItemKey={getItemKey}
      buildItemComponent={buildComponent}
      addItem={addItemToSelection}
      removeItem={removeItemFromSelection}
      listAdditionalClassName={styles.list}
      isInModal
    />
  );
};

export default connector(AddItemsAndGroupsList);
