import React from 'react';
import MenuItemIsDefaultBlock from 'menus/components/ItemsAndMods/MenuItemIsDefaultBlock';
import MenuItemNameBlock from 'menus/components/ItemsAndMods/MenuItemNameBlock';
import ModifierMaxValue from 'menus/components/ModifierGroups/ModifierMaxValue';
import styles from './ModifierGroupsOption.module.scss';
import { ITemplateEmbeddedModifierItem } from '@ready/menu.core';
import { Link, useParams } from 'react-router-dom';
import { SharedMenuItemsRoutes } from 'sharedMenuItems/Router';
import { ContextParams } from 'types/ContextParams.interface';

export const ModifierGroupsOption = ({ option }: { option: ITemplateEmbeddedModifierItem }) => {
  const { contextId: companyId } = useParams<ContextParams>();

  return (
    <Link to={SharedMenuItemsRoutes.getItemAndModsItem(companyId, option.itemId)}>
      <div className={styles.optionContainer}>
        <div className={styles.nameColumn}>
          <MenuItemNameBlock
            displayName={option.displayName}
            description={option.description}
            showDescription={false}
            className={styles.nameBlock}
          />
        </div>

        <div className={styles.otherDetailsColumn}>
          {/* Todo: uncomment once assigned locations is added to the response */}
          {/* <AssignedLocationsContent numberOfLocations={100} additionalStyles={styles.assignedLocations} /> */}

          <ModifierMaxValue value={option.constraints?.max} itemId={option.itemId} className={styles.isMaxValue} />

          <MenuItemIsDefaultBlock
            isDefault={option.isDefault ?? true}
            itemId={option.itemId}
            className={styles.isDefault}
          />
        </div>
      </div>
    </Link>
  );
};
