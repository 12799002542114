import produce from 'immer';
import { Action } from '../../../redux/types';
import { GiftCardProvider } from '@ready/dashboardv2api.contracts';
import { PAYMENT_PROCESSING_ACTIONS } from '../actions/paymentProcessingActionTypes';
import {
  paymentProcessingSate,
  getGiftCardInitValidationStates,
  newPayPalProvider,
} from '../initialStates/paymentProcessing';
import { combineReducers } from 'redux';
import { givexSlice } from 'companyLocations/pages/paymentProcessing/giftcards/givex/redux/slice';
import { onAccountPaymentSettingsSlice } from './onAccountPaymentSettings/slice';

const paymentProcessingReducer = (state = paymentProcessingSate, action: Action) => {
  return produce(state, (draft) => {
    const { giftCard, paypal } = draft.providers;

    switch (action.type) {
      case PAYMENT_PROCESSING_ACTIONS.UPDATE_LOCATION:
        draft.location = action.payload;
        return draft;

      case PAYMENT_PROCESSING_ACTIONS.INIT_PAYPAL_INFO_BEGIN:
        paypal.loading = true;
        draft.loading = true;
        draft.error = false;
        return draft;

      case PAYMENT_PROCESSING_ACTIONS.INIT_PAYPAL_INFO_SUCCESS:
        paypal.options = action.payload.options;
        paypal.loading = false;
        paypal.link = action.payload.link;
        paypal.initialized = true;

        draft.loading = false;
        draft.error = false;
        return draft;

      case PAYMENT_PROCESSING_ACTIONS.INIT_PAYPAL_INFO_ERROR:
        paypal.initialized = true;
        paypal.loading = false;
        draft.loading = false;
        draft.error = true;
        return draft;

      case PAYMENT_PROCESSING_ACTIONS.UNLINK_PAYPAL_MODAL:
        paypal.unlink.requested = action.payload;
        paypal.initialized = true;
        return draft;

      case PAYMENT_PROCESSING_ACTIONS.UNLINK_PAYPAL_BEGIN:
        paypal.unlink.loading = true;
        return draft;

      case PAYMENT_PROCESSING_ACTIONS.UNLINK_PAYPAL_SUCCESS:
        paypal.unlink.requested = false;
        paypal.unlink.loading = false;
        paypal.options = action.payload.paypalOptions;
        paypal.link = action.payload.link;
        return draft;

      case PAYMENT_PROCESSING_ACTIONS.UPDATE_PAYPAL_CONFIG_BEGIN:
        paypal.loading = true;
        draft.error = false;
        return draft;

      case PAYMENT_PROCESSING_ACTIONS.UPDATE_PAYPAL_CONFIG_SUCCESS:
        paypal.options = action.payload;
        paypal.loading = false;
        return draft;

      case PAYMENT_PROCESSING_ACTIONS.UPDATE_PAYPAL_CONFIG_ERROR:
        paypal.loading = false;
        draft.error = true;
        return draft;

      case PAYMENT_PROCESSING_ACTIONS.RESET_PAYPAL_CONFIG:
        draft.providers.paypal = newPayPalProvider();
        return draft;

      case PAYMENT_PROCESSING_ACTIONS.TOGGLE_GIFT_CARD_CONFIG_BEGIN:
        giftCard.processing = true;
        draft.error = false;
        return draft;

      case PAYMENT_PROCESSING_ACTIONS.TOGGLE_GIFT_CARD_CONFIG_SUCCESS:
        giftCard.editable = giftCard.processing = draft.error = false;
        draft.location = action.payload;

        if (!draft.location?.settings.giftCardsEnabled) {
          giftCard.view = undefined;
        }

        return draft;

      case PAYMENT_PROCESSING_ACTIONS.TOGGLE_GIFT_CARD_CONFIG_ERROR:
        giftCard.processing = false;
        draft.error = true;
        return draft;

      case PAYMENT_PROCESSING_ACTIONS.TOGGLE_GIFT_CARD_EDIT_FORM:
        if (!!action.payload) {
          giftCard.editable = true;
          giftCard.edit = giftCard.view;
          giftCard.validation = getGiftCardInitValidationStates(giftCard.view?.provider as GiftCardProvider);
        } else {
          giftCard.editable = false;
          giftCard.edit = giftCard.validation = undefined;
        }
        return draft;

      case PAYMENT_PROCESSING_ACTIONS.UPDATE_GIFT_CARD_PANEL_EDIT_STATE:
        giftCard.editable = action.payload;
        return draft;

      case PAYMENT_PROCESSING_ACTIONS.GET_GIFT_CARD_TENDER_TYPES_BEGIN:
        draft.loading = true;
        draft.error = false;
        return draft;

      case PAYMENT_PROCESSING_ACTIONS.GET_GIFT_CARD_TENDER_TYPES_SUCCESS:
        giftCard.tenderTypes = action.payload;
        draft.providers.giftCard.initialized = true;
        draft.loading = draft.error = false;
        return draft;

      case PAYMENT_PROCESSING_ACTIONS.GET_GIFT_CARD_TENDER_TYPES_ERROR:
        draft.loading = false;
        draft.providers.giftCard.initialized = true;
        draft.error = true;
        return draft;

      case PAYMENT_PROCESSING_ACTIONS.INIT_GIFT_CARD_CONFIG_BEGIN:
        draft.loading = true;
        giftCard.editable = draft.error = false;
        giftCard.view = giftCard.edit = giftCard.validation = undefined;
        return draft;

      case PAYMENT_PROCESSING_ACTIONS.INIT_GIFT_CARD_CONFIG_SUCCESS:
        draft.loading = draft.error = false;

        giftCard.view = giftCard.edit = action.payload.giftCardConfig;
        giftCard.validation = action.payload.validation;

        return draft;

      case PAYMENT_PROCESSING_ACTIONS.INIT_GIFT_CARD_CONFIG_ERROR:
        draft.loading = false;
        draft.error = true;
        return draft;

      case PAYMENT_PROCESSING_ACTIONS.SAVE_GIFT_CARD_CONFIG_BEGIN:
        giftCard.processing = true;
        draft.error = false;
        return draft;

      case PAYMENT_PROCESSING_ACTIONS.SAVE_GIFT_CARD_CONFIG_SUCCESS:
        giftCard.view = action.payload.view;
        giftCard.edit = giftCard.validation = undefined;
        draft.error = giftCard.processing = giftCard.editable = false;
        draft.location = action.payload.location;

        if (draft.location) {
          draft.location.settings.giftCardsEnabled = true;
        }
        return draft;

      case PAYMENT_PROCESSING_ACTIONS.SAVE_GIFT_CARD_CONFIG_ERROR:
        giftCard.processing = false;
        draft.error = true;
        return draft;

      case PAYMENT_PROCESSING_ACTIONS.CHANGE_GIFT_CARD_CONFIG_BEGIN:
        giftCard.edit = giftCard.validation = undefined;
        giftCard.processing = true;
        draft.error = false;
        return draft;

      case PAYMENT_PROCESSING_ACTIONS.CHANGE_GIFT_CARD_CONFIG_SUCCESS:
        draft.error = giftCard.processing = false;
        giftCard.edit = action.payload.giftCardConfig;
        giftCard.validation = action.payload.validation;

        return draft;

      case PAYMENT_PROCESSING_ACTIONS.CHANGE_GIFT_CARD_CONFIG_ERROR:
        giftCard.processing = false;
        draft.error = true;
        return draft;

      case PAYMENT_PROCESSING_ACTIONS.UPDATE_GIFT_CARD_PROVIDER:
        if (giftCard.edit) {
          giftCard.edit.provider = action.payload;
        }
        return draft;

      case PAYMENT_PROCESSING_ACTIONS.SET_TENDER_TYPES:
        giftCard.tenderTypes = action.payload;
        return draft;

      case PAYMENT_PROCESSING_ACTIONS.SET_POS_INTEGRATION:
        giftCard.posIntegration = action.payload;
        return draft;

      case PAYMENT_PROCESSING_ACTIONS.SET_GIFT_CARD_VIEW_CONFIG:
        giftCard.view = action.payload;
        return draft;

      case PAYMENT_PROCESSING_ACTIONS.SET_GIFT_CARD_EDIT_CONFIG:
        giftCard.edit = action.payload;
        return draft;

      case PAYMENT_PROCESSING_ACTIONS.RESET_GIFT_CARD_CONFIG:
        giftCard.edit = giftCard.view = giftCard.validation = undefined;
        giftCard.processing = giftCard.editable = giftCard.validated = false;
        return draft;

      case PAYMENT_PROCESSING_ACTIONS.UPDATE_GIFT_CARD_FORM_VALIDATION:
        giftCard.validated = action.payload;
        return draft;

      default:
        return draft;
    }
  });
};

export const combinedPaymentProcessorReducer = combineReducers({
  paymentProcessing: paymentProcessingReducer,
  givex: givexSlice.reducer,
  onAccountPaymentSettings: onAccountPaymentSettingsSlice.reducer,
});
