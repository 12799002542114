import { ITransactionDetail, ITransactionStatus } from '@ready/dashboardv2api.contracts';

export interface IPaymentPOSDetail {
  preTip: number;
  tip: number;
  total: number;
  transactionId: string;
  _id: string;
}
export const paymentPOSDetailState = {
  preTip: 0,
  tip: 0,
  total: 0,
  transactionId: '',
  _id: '',
} as IPaymentPOSDetail;

export interface IManuallyEnterPaymentPOS {
  showModal: boolean;
  loading: boolean;
  error: boolean;
  paymentPOSDetail: IPaymentPOSDetail;
}

export interface ITransactionDetailState {
  transactionDetail: ITransactionDetail;
  loading: boolean;
  error: boolean;
  manuallyEnterPaymentPOS: IManuallyEnterPaymentPOS;
  manuallyRetryingPayment: boolean;
  refundingGiftCard: boolean;
  refundGiftCardCompleted: boolean;
  refundGiftCardError: string;
}

export const transactionDetailState = {
  transactionDetail: {
    transactionId: '',
    transactionTotalAmount: 0,
    // We need to set a default value as the type is an enum, not a string.
    // Authorized is the default for a transaction (payment) status.
    timezone: '',
    transactionStatus: ITransactionStatus.Authorized,
    locationName: '',
    transactionCheckNumbers: '',
    serverName: '',
    tableNumber: '',
    totalsSummary: {
      subtotal: 0,
      tax: 0,
      serviceCharge: 0,
      otherCharges: 0,
      discounts: 0,
      checkTotal: 0,
      tip: 0,
      totalPaid: 0,
      totalRefunded: 0,
      tipPreTaxPercentage: 0,
      tipPostTaxPercentage: 0,
    },
    creditCardDetails: {
      amountAvailableForRefund: 0,
      amountPaid: 0,
      amountRefunded: 0,
      date: '',
      currency: '',
      transactionType: '',
      authCode: '',
      paymentId: '',
      last4: '',
      expiryMonth: 0,
      expiryYear: 0,
      cardBrand: '',
      source: '',
      zipPostal: '',
    },
    giftCardDetails: {
      amountAvailableForRefund: 0,
      amountPaid: 0,
      amountRefunded: 0,
      date: '',
      currency: '',
      giftCardNumber: '',
      transactionId: '',
    },
    onAccountPaymentDetails: {
      amountAvailableForRefund: 0,
      amountPaid: 0,
      amountRefunded: 0,
      date: '',
      currency: '',
      paymentType: '',
      validationFields: [],
    },
    guestInformation: {
      ipAddress: '',
      pii: [],
    },
  },
  loading: false,
  error: false,
  manuallyEnterPaymentPOS: {
    showModal: false,
    loading: false,
    error: false,
    paymentPOSDetail: paymentPOSDetailState,
  },
  manuallyRetryingPayment: false,
  refundingGiftCard: false,
  refundGiftCardCompleted: false,
  refundGiftCardError: '',
} as ITransactionDetailState;
