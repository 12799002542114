import { IPagination } from '@ready/dashboardv2api.contracts';
import { IMenuGroupAssignments } from '@ready/menu.core';
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { getMenuGroupAssignmentsThunk } from '../thunks/getMenuGroupAssignmentsThunk';

interface State {
  locations: IMenuGroupAssignments[];
  cache: IMenuGroupAssignments[];
  pagination?: IPagination;
  loading: boolean;
}

const initialState: State = {
  locations: [],
  cache: [],
  loading: false,
};

type LocationMenuGroupField = IMenuGroupAssignments['locationGroups'][number];
type LocationMenuGroupBooleanField = Pick<LocationMenuGroupField, 'isActive' | 'isServerFlowActive'>;

const menuConfigLocationSlice = createSlice({
  name: 'sharedMenus/locations',
  initialState: initialState,
  reducers: {
    updateLocationMenuGroupBooleanField: (
      state,
      action: {
        payload: {
          locationId: string;
          update: { key: keyof LocationMenuGroupBooleanField; value?: string };
        };
      }
    ) => {
      const location = state.locations.find((x) => x.locationId === action.payload.locationId);

      if (location) {
        location.locationGroups.forEach((menuGroup) => {
          menuGroup[action.payload.update.key] = menuGroup._id === action.payload.update.value ? true : false;
        });
      }
    },
    resetChanges: (state) => {
      state.locations = state.cache;
    },
    resetState: () => initialState,
  },
  extraReducers: (builder: ActionReducerMapBuilder<State>) => {
    menuGroupAssignmentsReducers(builder);
  },
});

const menuGroupAssignmentsReducers = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(getMenuGroupAssignmentsThunk.pending, (state: State) => {
    state.loading = true;
  });
  builder.addCase(getMenuGroupAssignmentsThunk.fulfilled, (state: State, action) => {
    state.loading = false;
    const { items, ...pagination } = action.payload;
    state.locations = state.cache = items;
    state.pagination = pagination;
  });
  builder.addCase(getMenuGroupAssignmentsThunk.rejected, (state: State) => {
    state.loading = true;
  });
};

export { menuConfigLocationSlice };
export const { resetChanges, resetState, updateLocationMenuGroupBooleanField } = menuConfigLocationSlice.actions;
