import React from 'react';
import { connect } from 'react-redux';

import { AppState } from '../../redux/initialStates/AppState';

import { setLocationsQuery } from '../../redux/actions/companyLocations/companyLocationsActions';

import SearchInput from '../../components/SearchInput/SearchInput';

export interface UsersAddLocationsDialogSearchProps {
  query: string;
  setLocationsQuery: (query: string) => void;
}

const UsersAddLocationsDialogSearch = (props: UsersAddLocationsDialogSearchProps) => {
  const { query, setLocationsQuery } = props;

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocationsQuery(event.target.value);
  };

  return <SearchInput value={query} placeholder='Search Locations' fullWidth onChange={handleQueryChange} />;
};

const mapStateToProps = (state: AppState) => ({
  query: state.companyLocations.query,
});

const actionCreators = {
  setLocationsQuery,
};

export default connect(mapStateToProps, actionCreators)(UsersAddLocationsDialogSearch);
