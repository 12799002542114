import { IContextPropsForSidebar, IContextSession } from 'redux/initialStates/session/session';
import config from '../../../config';
import { SESSION_ACTIONS } from './types';
import {
  AllPermissionsMap,
  ILastLocation,
  ISidebarOption,
  IUserSessionApiResponse,
} from '../../initialStates/session/session';
import { SIDEBAR_OPTIONS } from '../../initialStates/session/sidebarOptions';
import { UserContextService } from '../../../services/userContextsService';
import { IPermissionsAndResources, UserPermissionService } from '../../../services/userPermissionsService';
import { merge } from 'lodash';
import { CommonActions, IPermission } from '@ready/security.core';
import { getContextsSuccess } from '../contextSwitcherActions/contextSwitcherActions';
import { User } from '@auth0/auth0-spa-js';
import { IContext } from '@ready/dashboardv2api.contracts';

// Initialize Session Actions
export const initializeUserSession = (user: User) => async (dispatch: any) => {
  try {
    const { auth0isAdminJwtClaim } = config;
    const isAdminJWTClaim = user[auth0isAdminJwtClaim];
    const isAdmin = isAdminJWTClaim === true;
    const userSession: IUserSessionApiResponse = {
      isAdmin,
      userId: user.sub ?? '',
      userName: user.name ?? '',
    };
    dispatch(updateUserSessionSuccess(userSession));
  } catch (err) {
    dispatch(initializeSessionError());
  }
};

export const initializeContextSession =
  (contextIdInPath: string = '') =>
    async (dispatch: any) => {
      try {
        // get the first page of user's available contexts
        const userAvailableContexts = await UserContextService.getUserContexts();
        const firstContext = userAvailableContexts[0];
        const { type: contextType } = firstContext;
        const singleContext = userAvailableContexts.length === 1;
        const isReadyAdmin = contextType === 'system';
        // if there's only one context or the first context is Ready Admin then autochoose it
        const autoChooseContext = singleContext || isReadyAdmin;

        // if contextIdInPath is received
        if (!!contextIdInPath) {
          try {
            const context = await UserContextService.getContextById(contextIdInPath);
            dispatch(updateContextSessionSuccess(context));
          } catch (err) {
            dispatch(updateInvalidContext(true));
            dispatch(initializeSessionSuccess());
          }
        } else {
          if (autoChooseContext) {
            try {
              const context = singleContext ? await UserContextService.getContextById(firstContext.id) : firstContext;

              dispatch(updateContextSessionSuccess(context));
            } catch (err) {
              dispatch(updateInvalidContext(true));
              dispatch(initializeSessionSuccess());
            }
          }
        }
        // case when more than one context is available, then activate the context switcher button
        if (userAvailableContexts.length > 1) {
          dispatch(updateEnableContextSwitcher(true));

          if (!isReadyAdmin) {
            dispatch(getContextsSuccess(userAvailableContexts));
            dispatch(updateShowCompanySelectionPage(true));
            dispatch(initializeSessionSuccess());
          }
        }
      } catch (err) {
        dispatch(initializeSessionError());
      }
    };

export const initializePermissions = (contextId: string) => async (dispatch: any) => {
  try {
    const permissionsResponse = await UserPermissionService.getUserPermissions(contextId);
    dispatch(updatePermissionsList(permissionsResponse));
  } catch (err) {
    dispatch(initializeSessionError());
  }
};

export const initializeSidebarOptions =
  (permissions: AllPermissionsMap, contextSession: IContextSession) => async (dispatch: any) => {
    try {
      const sidebarOptions = getSidebarOptions(permissions, contextSession);
      dispatch(updateSidebarOptions(sidebarOptions));
    } catch (err) {
      dispatch(initializeSessionError());
    }
  };
// End of Initialize Session Actions

export const initializeSessionBegin = () => ({
  type: SESSION_ACTIONS.INITIALIZE_SESSION_BEGIN,
});

export const initializeSessionSuccess = () => ({
  type: SESSION_ACTIONS.INITIALIZE_SESSION_SUCCESS,
});

export const initializeSessionError = () => ({
  type: SESSION_ACTIONS.INITIALIZE_SESSION_ERROR,
});

// userSession
export const updateUserSessionBegin = () => ({
  type: SESSION_ACTIONS.UPDATE_USER_SESSION_BEGIN,
});

export const updateUserSessionSuccess = (user: IUserSessionApiResponse) => ({
  type: SESSION_ACTIONS.UPDATE_USER_SESSION_SUCCESS,
  payload: user,
});

export const updateUserSessionError = () => ({
  type: SESSION_ACTIONS.UPDATE_USER_SESSION_ERROR,
});

// contextSession
export const updateContextSessionBegin = () => ({
  type: SESSION_ACTIONS.UPDATE_CONTEXT_BEGIN,
});

export const updateContextSessionSuccess = (context: IContext) => ({
  type: SESSION_ACTIONS.UPDATE_CONTEXT_SUCCESS,
  payload: context,
});

export const updateContextSessionError = () => ({
  type: SESSION_ACTIONS.UPDATE_CONTEXT_ERROR,
});

export const updateEnableContextSwitcher = (payload: boolean) => ({
  type: SESSION_ACTIONS.UPDATE_ENABLE_CONTEXT_SWITCHER,
  payload,
});

export const updateSidebarOptions = (payload: ISidebarOption[]) => ({
  type: SESSION_ACTIONS.UPDATE_SIDEBAR_OPTIONS,
  payload,
});

export const updatePermissionsList = (payload: IPermissionsAndResources) => ({
  type: SESSION_ACTIONS.UPDATE_PERMISSIONS_LIST,
  payload,
});

export const updateApiPermissionsRequested = (payload: boolean) => ({
  type: SESSION_ACTIONS.UPDATE_API_PERMISSIONS_REQUESTED,
  payload,
});

export const updateSidebarOptionsSet = (payload: boolean) => ({
  type: SESSION_ACTIONS.UPDATE_SIDEBAR_OPTIONS_SET,
  payload,
});

export const updateInvalidContext = (payload: boolean) => ({
  type: SESSION_ACTIONS.UPDATE_INVALID_CONTEXT,
  payload,
});

export const updateLastLocation = (payload: ILastLocation) => ({
  type: SESSION_ACTIONS.UPDATE_LAST_LOCATION,
  payload,
});

export const clearLastLocation = () => ({
  type: SESSION_ACTIONS.CLEAR_LAST_LOCATION,
});

export const updateShowCompanySelectionPage = (payload: boolean) => ({
  type: SESSION_ACTIONS.UPDATE_SHOW_COMPANY_SELECTION_PAGE,
  payload,
});

// manually switching to other context
export const updateContextSession = (context: IContext) => async (dispatch: any) => {
  dispatch(updateContextSessionBegin());
  try {
    const permissionsResponse = await UserPermissionService.getUserPermissions(context.id);
    dispatch(updatePermissionsList(permissionsResponse));
    dispatch(updateContextSessionSuccess(context));
  } catch (err) {
    dispatch(updateContextSessionError());
  }
};

const hasPermissionForSidebarOption = (allPermissionsMap: AllPermissionsMap, option: ISidebarOption): boolean => {
  return option.permissions.some((permission: string | IPermission) => {
    // string permission means just resourceType
    if (typeof permission === 'string') {
      return permission in allPermissionsMap;
    }
    if (permission.resourceType && permission.action) {
      return (
        allPermissionsMap[permission.resourceType]?.[permission.action] ||
        allPermissionsMap[permission.resourceType]?.[CommonActions.all]
      );
    }
    console.error('Wrong sidebar permissions format', option);
    return false;
  });
};

/** Compares the contextSession to the requiredContextSessionProps in childOption  */
export const hasContextPropsForSidebarOption = (
  contextSession: IContextSession,
  childOption: ISidebarOption
): boolean => {
  if (!childOption.requiredContextSessionProps) {
    return true;
  }

  for (const p in childOption.requiredContextSessionProps) {
    const prop = p as keyof IContextPropsForSidebar;
    if (childOption.requiredContextSessionProps[prop] !== contextSession[prop]) return false;
  }

  return true;
};

const getSidebarOptions = (
  permissionsFromApi: AllPermissionsMap,
  contextSession: IContextSession
): ISidebarOption[] => {
  // this mockedPermissions array allows us to manually add temporary permissions
  // TODO: this is useful only for dev testing, once the permissons is set, remove it from this array
  const mockedPermissions: AllPermissionsMap = {};
  const permissions = merge({}, permissionsFromApi, mockedPermissions);

  SIDEBAR_OPTIONS.forEach((option: ISidebarOption) => {
    if (option.children) {
      option.children.forEach((childOption: ISidebarOption) => {
        childOption.hasPermission =
          hasPermissionForSidebarOption(permissions, childOption) &&
          hasContextPropsForSidebarOption(contextSession, childOption);
      });
    }
    option.hasPermission =
      option.adminOption ||
      option.children?.some((c) => c.hasPermission) ||
      (hasPermissionForSidebarOption(permissions, option) && hasContextPropsForSidebarOption(contextSession, option));
  });

  return SIDEBAR_OPTIONS;
};
