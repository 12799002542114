import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import IconButton from '../../../components/IconButton/IconButton';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';
import Checkbox from '../../../components/Checkbox/Checkbox';
import TextInput from '../../../components/TextInput/TextInput';
import SelectFilter, { Option } from '../../../components/SelectFilter/SelectFilter';
import InputError from '../../../components/InputError/InputError';
import FormattedInput from '../../../components/FormattedInput/FormattedInput';
import { Tooltip } from '../../../components/Tooltip/Tooltip';
import SelectFilterAsync from '../../../components/SelectFilter/SelectFilterAsync';
import { OnAccountPaymentOptionsActions } from '../../redux/actions/onAccountPaymentOptionsActions';
import styles from './PaymentValidationField.module.scss';
import { selectOnAccountPaymentOptions } from '../../redux/selectors/onAccountPaymentOptionsSelector';
import { useParams } from 'react-router-dom';
import { ContextParams } from '../../../types/ContextParams.interface';
import { components, OptionProps, SingleValueProps } from 'react-select';
import MenuBuilderService from 'menus/services/MenuBuilderService';
import { IMenuItem } from '@ready/menu.core';
import MenuItemsView from 'menus/types/MenuItemsView.enum';
import { toastErrorState } from 'redux/actions/uiActions/responseStateActions';

const PosItemOption = (props: OptionProps<Option, boolean>) => (
  <components.Option {...props}>
    {props.data.label}
    <div className={styles.posDetails}>{props.data.subLabel}</div>
  </components.Option>
);

const PosItemSingleValue = (props: SingleValueProps<Option>) => {
  return (
    <components.SingleValue {...props}>
      {props.data.label}
      {props.data.subLabel && (
        <>
          {' '}
          <span className={styles.posId}>{`(${props.data.subLabel})`}</span>
        </>
      )}
    </components.SingleValue>
  );
};

interface PaymentValidationFieldProps {
  internalId: number;
  isEditOptionForm?: boolean;
}

const fieldTypes: Option[] = [
  {
    label: 'Text',
    value: 'text',
  },
  {
    label: 'Phone',
    value: 'tel',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Number',
    value: 'number',
  },
];

const PaymentValidationField = ({ internalId, isEditOptionForm = false }: PaymentValidationFieldProps) => {
  const { contextId: companyId, locationId } = useParams<ContextParams>();
  const {
    form: {
      fields: { paymentValidationFields },
      isProcessing,
    },
  } = useSelector(selectOnAccountPaymentOptions);
  const paymentValidationFieldForm = paymentValidationFields.find(
    (paymentValidationField) => paymentValidationField.internalId === internalId
  )!;
  const dispatch = useDispatch();

  const [menuItems, setMenuItems] = useState<IMenuItem[]>([]);
  const [areMenuItemsLoading, setMenuItemsLoading] = useState<boolean>(false);
  const menuItemOptions: Option[] = menuItems
    .filter((item) => item.posItemId)
    .map((item) => {
      return {
        label: item.displayName,
        value: item._id!,
        subLabel: item.posItemId,
      };
    });

  const selectedPosItem: Option | undefined = paymentValidationFieldForm.fields.mappedMenuItem.value.id
    ? {
        label: paymentValidationFieldForm.fields.mappedMenuItem.value.name,
        value: paymentValidationFieldForm.fields.mappedMenuItem.value.id,
        subLabel: paymentValidationFieldForm.fields.mappedMenuItem.value.posId,
      }
    : undefined;

  const selectedFieldType = paymentValidationFieldForm.fields.fieldType
    ? fieldTypes.find((fieldType: Option) => fieldType.value === paymentValidationFieldForm.fields.fieldType.value)
    : null;

  const getMenuItems = async (filter?: string) => {
    try {
      return await MenuBuilderService.getMenuItems(companyId, locationId, MenuItemsView.ITEMS, undefined, filter);
    } catch (e) {
      dispatch(toastErrorState(undefined, 'There was a problem getting menu items.'));
    }
  };

  const initialFetchItems = async (): Promise<void> => {
    setMenuItemsLoading(true);
    const result = await getMenuItems();
    if (result) setMenuItems(result.items);
    setMenuItemsLoading(false);
  };

  const fetchItems = debounce(async (filter: string): Promise<void> => {
    setMenuItemsLoading(true);
    const result = await getMenuItems(filter);
    if (result) setMenuItems(result.items);
    setMenuItemsLoading(false);
  }, 300);

  return (
    <div className={styles.field}>
      <div className={styles.closeButton}>
        <IconButton
          disabled={isProcessing}
          onClick={() =>
            dispatch(
              OnAccountPaymentOptionsActions.removePaymentOptionValidationField(paymentValidationFieldForm.internalId)
            )
          }
        >
          <TextIcon icon={Icon.RemoveCircle} />
        </IconButton>
      </div>

      <Checkbox
        checked={paymentValidationFieldForm.fields.required.value}
        disabled={isProcessing}
        onChange={(event) =>
          dispatch(
            OnAccountPaymentOptionsActions.updatePaymentOptionValidationFieldRequiredField(
              paymentValidationFieldForm.internalId,
              event.target.checked
            )
          )
        }
        label='Required field'
      />

      <label className={styles.fieldLabel}>
        <p className={isProcessing ? styles.fieldLabelTextDisabled : styles.fieldLabelText}>Field Label Name *</p>
        <TextInput
          placeholder='e.g. Room Number'
          value={paymentValidationFieldForm.fields.fieldLabelName.value}
          disabledLoading={isProcessing}
          withError={paymentValidationFieldForm.fields.fieldLabelName.hasError}
          onChange={(event) =>
            dispatch(
              OnAccountPaymentOptionsActions.updatePaymentOptionValidationFieldLabelName(
                paymentValidationFieldForm.internalId,
                event.target.value
              )
            )
          }
        />
        {paymentValidationFieldForm.fields.fieldLabelName.hasError && <InputError message='Field is required.' />}
      </label>

      <label className={styles.fieldLabel}>
        <p className={isProcessing ? styles.fieldLabelTextDisabled : styles.fieldLabelText}>Field Placeholder Text *</p>
        <TextInput
          placeholder='e.g. “Enter your room number”'
          value={paymentValidationFieldForm.fields.fieldPlaceholderText.value}
          disabledLoading={isProcessing}
          withError={paymentValidationFieldForm.fields.fieldPlaceholderText.hasError}
          onChange={(event) =>
            dispatch(
              OnAccountPaymentOptionsActions.updatePaymentOptionValidationFieldPlaceholderText(
                paymentValidationFieldForm.internalId,
                event.target.value
              )
            )
          }
        />
        {paymentValidationFieldForm.fields.fieldPlaceholderText.hasError && <InputError message='Field is required.' />}
      </label>

      <label className={styles.fieldLabel}>
        <p className={isProcessing ? styles.fieldLabelTextDisabled : styles.fieldLabelText}>Field Type *</p>
        <SelectFilter
          value={selectedFieldType}
          placeholder='Select a field type'
          options={fieldTypes}
          loading={isProcessing}
          withError={paymentValidationFieldForm.fields.fieldType.hasError}
          onChange={(option) => {
            dispatch(
              OnAccountPaymentOptionsActions.updatePaymentOptionValidationFieldType(
                paymentValidationFieldForm.internalId,
                `${option.value}`
              )
            );
          }}
        />
        {paymentValidationFieldForm.fields.fieldType.hasError && <InputError message='Field is required.' />}
      </label>

      <label className={styles.fieldLabel}>
        <p className={isProcessing ? styles.fieldLabelTextDisabled : styles.fieldLabelText}>Max Character Length *</p>
        <div className={styles.maxLengthInput}>
          <FormattedInput
            placeholder='e.g. 60”'
            format='number'
            value={paymentValidationFieldForm.fields.maxCharacterLength.value}
            loading={isProcessing}
            withError={paymentValidationFieldForm.fields.maxCharacterLength.hasError}
            onChange={(event) =>
              dispatch(
                OnAccountPaymentOptionsActions.updatePaymentOptionValidationFieldMaxCharLength(
                  paymentValidationFieldForm.internalId,
                  event.target.value
                )
              )
            }
          />
        </div>
        {paymentValidationFieldForm.fields.maxCharacterLength.hasError && <InputError message='Field is required.' />}
      </label>

      <label className={styles.fieldLabel}>
        <span className={isProcessing ? styles.fieldLabelTextDisabled : styles.fieldLabelText}>
          Ready Menu Item *&nbsp;
          <Tooltip text='You must select a Ready item that is linked to a POS item. Ready will use this item to pass the data from this field to the POS item.'>
            <TextIcon icon={Icon.InfoCircle} />
          </Tooltip>
        </span>
        <SelectFilterAsync
          placeholder='Search to select a Ready Menu Item'
          options={menuItemOptions}
          value={selectedPosItem}
          loading={areMenuItemsLoading}
          processing={isProcessing}
          limit={50}
          overflowMessage={`Top results shown. Type to refine your search.`}
          showOverflowMessage={() => menuItemOptions.length <= 50 && menuItemOptions.length > 0}
          isClearable
          hideIndicator
          handleFetch={fetchItems}
          handleInitialFetch={initialFetchItems}
          withError={paymentValidationFieldForm.fields.mappedMenuItem.hasError}
          onChange={(option) => {
            dispatch(
              OnAccountPaymentOptionsActions.updatePaymentOptionValidationMappedPosItem(
                paymentValidationFieldForm.internalId,
                option ? { id: `${option.value}`, name: option.label, posId:option.subLabel } : undefined
              )
            );
          }}
          customComponents={{
            Option: PosItemOption,
            SingleValue: PosItemSingleValue,
          }}
        />
        {paymentValidationFieldForm.fields.mappedMenuItem.hasError && <InputError message='Field is required.' />}
      </label>
    </div>
  );
};

export default PaymentValidationField;
