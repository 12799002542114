import InfiniteScrollingListState from '../../types/InfiniteScrollingListState.interface';

export default class InfiniteScrollingListStateHelper {
  public static initialState = <T>(): InfiniteScrollingListState<T> => ({
    modalVisible: false,
    readyToLoad: false,
    loading: false,
    paginationAvailable: false,
    paginationLoading: false,
    filtered: false,
    query: '',
    page: 0,
    error: false,
    items: [],
    selectedIds: [],
  });

  public static prepareState = <T>(
    state: InfiniteScrollingListState<T>,
    visible: boolean,
    selectedIds?: string[]
  ): void => {
    state.modalVisible = visible;
    state.readyToLoad = true;
    state.loading = true;
    state.paginationAvailable = false;
    state.paginationLoading = false;
    state.filtered = false;
    state.query = '';
    state.page = 1;
    state.error = false;
    state.items = [];
    state.selectedIds = selectedIds || [];
  };

  public static loadingState = <T>(state: InfiniteScrollingListState<T>): void => {
    state.readyToLoad = false;
    state.loading = true;
    state.paginationAvailable = true;
    state.page = 1;
  };

  public static loadedState = <T>(state: InfiniteScrollingListState<T>, items: T[]): void => {
    state.filtered = !!state.query;
    state.loading = false;
    state.items = items;
  };

  public static loadFailedState = <T>(state: InfiniteScrollingListState<T>): void => {
    state.loading = false;
    state.paginationAvailable = true;
    state.error = true;
  };

  public static loadingMoreState = <T>(state: InfiniteScrollingListState<T>): void => {
    state.paginationLoading = true;
    state.error = false;
  };

  public static loadedMoreState = <T>(state: InfiniteScrollingListState<T>, items: T[]): void => {
    state.paginationLoading = false;
    state.page = state.page + 1;
    state.items.push(...items);
  };

  public static loadMoreNoItemsState = <T>(state: InfiniteScrollingListState<T>): void => {
    state.paginationLoading = false;
    state.paginationAvailable = false;
  };

  public static loadMoreFailedState = <T>(state: InfiniteScrollingListState<T>): void => {
    state.paginationLoading = false;
    state.paginationAvailable = true;
    state.error = true;
  };

  public static filterChangedState = <T>(state: InfiniteScrollingListState<T>, query: string): void => {
    state.readyToLoad = true;
    state.query = query;
    if (!state.query) {
      state.loading = true;
    }
  };

  public static selectItemState = <T>(state: InfiniteScrollingListState<T>, selectedId: string): void => {
    state.selectedIds.push(selectedId);
  };

  public static unselectItemState = <T>(state: InfiniteScrollingListState<T>, selectedId: string): void => {
    state.selectedIds = state.selectedIds.filter((id: string) => id !== selectedId);
  };

  public static closeState = <T>(state: InfiniteScrollingListState<T>): void => {
    state.modalVisible = false;
  };
}
