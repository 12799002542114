import { USERS_LIST_ACTIONS } from './types';
import { ClientUserService } from '../../../services/usersService';
import { pageErrorState } from '../uiActions/responseStateActions';
import { IClientUser, PaginationResponse, UserStatusFilter } from '@ready/dashboardv2api.contracts';

export interface ILoadUsersList {
  (companyId: string, query?: string, page?: number, status?: UserStatusFilter): void;
}

export const fetchUsersListThunk: ILoadUsersList = (companyId, query, page, status) => async (dispatch: any) => {
  dispatch(setUsersListLoading(true));

  try {
    dispatch(setUsersList(await ClientUserService.getUsers(companyId, query, page, status)));
  } catch (err) {
    dispatch(pageErrorState(err.status, err.message));
  } finally {
    dispatch(setUsersListLoading(false));
  }
};

export const initializeUsersList = (query: string, page: string) => ({
  type: USERS_LIST_ACTIONS.INITIALIZE_USERS_LIST,
  payload: {
    query,
    page,
  },
});

export const setUsersList = (usersList: PaginationResponse<IClientUser>) => ({
  type: USERS_LIST_ACTIONS.SET_USERS_LIST,
  payload: usersList,
});

export const setUsersListLoading = (isLoading: boolean) => ({
  type: USERS_LIST_ACTIONS.SET_USERS_LIST_LOADING,
  payload: isLoading,
});
