import { IOrderDetails } from '@ready/dashboardv2api.contracts';
import { ISinglePlacedOrder } from '../../services/types/orders.type';

const mapOrdersToPlacedOrders = (orderDetailList: IOrderDetails[]): ISinglePlacedOrder[] =>
  orderDetailList.map((order) => ({
    ...order,
    offset: 0,
    newOrder: false,
    processing: false,
    pickupTime: order.pickupTime.toISOString(),
    created: order.created.toISOString(),
  }));

export default mapOrdersToPlacedOrders;
