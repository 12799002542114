import { ResourceType, MenuResourceActions } from '@ready/security.core';
import SelectFilterAsync from 'components/SelectFilter/SelectFilterAsync';
import { Option } from 'components/SelectFilter/SelectFilter';
import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CompanyLocationService } from 'services/companyLocationsService';
import { ILocation } from 'services/types/ILocation.type';
import { ContextParams } from 'types/ContextParams.interface';
import { ILocationFilterParams } from 'hooks/useLocationFilter';

interface Props {
  onLocationSet: (locationFilter?: ILocationFilterParams) => void;
  initial?: ILocationFilterParams;
}

export const LocationFilter = ({ onLocationSet, initial }: Props) => {
  const { contextId: companyId } = useParams<ContextParams>();
  const [locationFilter, setLocationFilter] = useState<ILocationFilterParams | undefined>(initial ?? undefined);
  const [locationOptions, setLocationOptions] = useState<Option[]>([]);
  const [locationsLoading, setLocationsLoading] = useState<boolean>(false);

  const getLocations = useCallback(
    async (filter: string) => {
      setLocationsLoading(true);

      const result = await CompanyLocationService.getPermittedLocations(
        companyId,
        [{ resourceType: ResourceType.templateMenu, action: MenuResourceActions.all }],
        filter
      );
      setLocationOptions(
        result.items.map((location: ILocation) => {
          return { label: location.name, value: location._id };
        })
      );
      setLocationsLoading(false);
    },
    [companyId]
  );

  const debouncedGetLocations = debounce(getLocations, 300);

  return (
    <SelectFilterAsync
      placeholder='Filter by location'
      isClearable
      options={locationOptions}
      loading={locationsLoading}
      value={locationFilter ? { label: locationFilter.name, value: locationFilter.id } : undefined}
      handleFetch={debouncedGetLocations}
      onChange={(option: Option) => {
        const value = option ? { name: option.label, id: option.value.toString() } : undefined;
        setLocationFilter(value);
        onLocationSet(value);
      }}
    />
  );
};
