import { beginningOfTime } from './';

export const isBeginningOfTime = (date: Date | undefined): boolean => {
  if (!date) {
    return false;
  }

  return (
    date.getFullYear() === beginningOfTime.getFullYear() &&
    date.getMonth() === beginningOfTime.getMonth() &&
    date.getDate() === beginningOfTime.getDate()
  );
};
