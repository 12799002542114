import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { PaginationResponse } from '@ready/dashboardv2api.contracts';
import { IMenuCopyState, MenuCopyStatus } from '@ready/menu.core';
import { ILocation } from '../../services/types/ILocation.type';
import { CopyMenuState } from './CopyMenuState';
import { CopyMenuThunks, LaunchMenuCopyModalResult, LaunchMenuCopyResult } from './CopyMenuThunks';

export const reducers = {
  loadMenuCopyStateInit: (state: CopyMenuState): void => {
    state.menuCopyStateLoading = true;
    state.menuCopyState = undefined;
    state.menuCopyStateError = false;
  },
  loadMenuCopyStateSuccess: (state: CopyMenuState, action: PayloadAction<IMenuCopyState>): void => {
    state.menuCopyStateLoading = false;
    state.menuCopyState = action.payload;
  },
  loadMenuCopyStateFailure: (state: CopyMenuState): void => {
    state.menuCopyStateLoading = false;
    state.menuCopyStateError = true;
  },

  acknowledgeFailedMenuCopyInit: (state: CopyMenuState): void => {
    state.acknowledgementProcessing = true;
  },
  acknowledgeFailedMenuCopySuccess: (state: CopyMenuState): void => {
    state.acknowledgementProcessing = false;
    if (state.menuCopyState) {
      state.menuCopyState.acknowledged = true;
    }
  },
  acknowledgeFailedMenuCopyFailure: (state: CopyMenuState): void => {
    state.acknowledgementProcessing = false;
  },

  launchMenuCopyModalInit: (state: CopyMenuState): void => {
    state.menuCopyProcessing = true;
  },
  launchMenuCopyModalSuccess: (state: CopyMenuState, action: PayloadAction<LaunchMenuCopyModalResult>): void => {
    state.menuCopyState = action.payload.menuCopyState;
    state.selectedLocation = action.payload.selectedLocation;
    state.menuCopyProcessing = false;
    if (action.payload.selectedLocation) {
      state.dataDeletionAcknowledged = true;
      state.validated = true;
      state.launchingMenuCopy = action.payload.menuCopyState.status !== MenuCopyStatus.inProgress;
    }
  },
  launchMenuCopyModalFailure: (state: CopyMenuState): void => {
    state.menuCopyProcessing = false;
  },

  acknowledgeDataDeletion: (state: CopyMenuState): void => {
    state.dataDeletionAcknowledged = true;
  },

  loadLocationsInit: (state: CopyMenuState): void => {
    state.locationsLoading = true;
  },
  loadLocationsSuccess: (state: CopyMenuState, action: PayloadAction<PaginationResponse<ILocation>>): void => {
    state.locationsLoading = false;
    state.locations = action.payload.items;
  },
  loadLocationsFailure: (state: CopyMenuState): void => {
    state.locationsLoading = false;
    state.locations = [];
  },

  selectLocation: (state: CopyMenuState, action: PayloadAction<ILocation | undefined>): void => {
    state.selectedLocation = action.payload;
    state.showValidationError = false;
  },

  validate: (state: CopyMenuState, action: PayloadAction<ILocation | undefined>): void => {
    if (action.payload) {
      state.validated = true;
    } else {
      state.showValidationError = true;
    }
  },

  launchMenuCopyInit: (state: CopyMenuState): void => {
    state.launchingMenuCopy = true;
  },
  launchMenuCopySuccess: (state: CopyMenuState, action: PayloadAction<LaunchMenuCopyResult>): void => {
    state.launchingMenuCopy = false;
    state.launchedMenuCopy = action.payload.launched;
    state.showCopyInProgressError = action.payload.copyInProgress;
  },
  launchMenuCopyFailure: (state: CopyMenuState): void => {
    state.launchingMenuCopy = false;
  },

  resetCopyMenuState: (state: CopyMenuState): void => {
    state.menuCopyStateLoading = false;
    state.menuCopyState = undefined;
    state.acknowledgementProcessing = false;
    state.dataDeletionAcknowledged = false;
    state.locationsLoading = false;
    state.locations = [];
    state.selectedLocation = undefined;
    state.validated = false;
    state.showValidationError = false;
    state.showCopyInProgressError = false;
    state.launchingMenuCopy = false;
    state.launchedMenuCopy = false;
  },
};

export const extraReducers = (builder: ActionReducerMapBuilder<CopyMenuState>) => {
  builder.addCase(CopyMenuThunks.loadMenuCopyState.pending, reducers.loadMenuCopyStateInit);
  builder.addCase(CopyMenuThunks.loadMenuCopyState.fulfilled, reducers.loadMenuCopyStateSuccess);
  builder.addCase(CopyMenuThunks.loadMenuCopyState.rejected, reducers.loadMenuCopyStateFailure);

  builder.addCase(CopyMenuThunks.acknowledgeFailedMenuCopy.pending, reducers.acknowledgeFailedMenuCopyInit);
  builder.addCase(CopyMenuThunks.acknowledgeFailedMenuCopy.fulfilled, reducers.acknowledgeFailedMenuCopySuccess);
  builder.addCase(CopyMenuThunks.acknowledgeFailedMenuCopy.rejected, reducers.acknowledgeFailedMenuCopyFailure);

  builder.addCase(CopyMenuThunks.launchMenuCopyModal.pending, reducers.launchMenuCopyModalInit);
  builder.addCase(CopyMenuThunks.launchMenuCopyModal.fulfilled, reducers.launchMenuCopyModalSuccess);
  builder.addCase(CopyMenuThunks.launchMenuCopyModal.rejected, reducers.launchMenuCopyModalFailure);

  builder.addCase(CopyMenuThunks.loadLocations.pending, reducers.loadLocationsInit);
  builder.addCase(CopyMenuThunks.loadLocations.fulfilled, reducers.loadLocationsSuccess);
  builder.addCase(CopyMenuThunks.loadLocations.rejected, reducers.loadLocationsFailure);

  builder.addCase(CopyMenuThunks.launchMenuCopy.pending, reducers.launchMenuCopyInit);
  builder.addCase(CopyMenuThunks.launchMenuCopy.fulfilled, reducers.launchMenuCopySuccess);
  builder.addCase(CopyMenuThunks.launchMenuCopy.rejected, reducers.launchMenuCopyFailure);
};
