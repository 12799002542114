import React from 'react';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import styles from './SquareRedirectCallback.module.scss';

export enum SquareRedirectState {
  Loading = 'loading',
  Success = 'success',
  Error = 'error',
}

interface SquareRedirectCallbackProps {
  state: SquareRedirectState;
}

const SquareRedirectCallback = (props: SquareRedirectCallbackProps) => {
  const { state } = props;

  return (
    <div className={styles.square}>
      {state === 'loading' && <LoadingSpinner />}
      {state === 'success' && (
        <div>
          <h3 className={styles.result}>Successful Connection!</h3>
          <p className={styles.message}>
            You have successfully connected this Company with Square. You can now close this window.
          </p>
        </div>
      )}
      {state === 'error' && (
        <div>
          <h3 className={styles.result}>Whoops!</h3>
          <p className={styles.message}>
            There was an error when trying to connect this Company with Square. Please try again.
          </p>
        </div>
      )}
    </div>
  );
};

export default SquareRedirectCallback;
