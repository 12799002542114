import { ITableAssignment } from '@ready/table.core';
import { compareServerAssignmentEmployeeNames, compareTables } from './index';

const sortServerAssignments = (serverAssignments: ITableAssignment[]): ITableAssignment[] => {
  // Sort the table assignments by employee name
  serverAssignments.sort(compareServerAssignmentEmployeeNames);

  // Sort each table assignment's list of tables
  serverAssignments.forEach((serverAssignment) => serverAssignment.tables.sort(compareTables));

  return serverAssignments;
};

export default sortServerAssignments;
