import React, { useState } from 'react';
import Select, { components } from 'react-select';
import { useHistory, useLocation } from 'react-router-dom';
import { updatePath } from '../../../utils/updatePath';
import { updateURLSearchParams } from '../../../utils/updateURLSearchParams';
import { useSearchParams } from '../../../hooks/useSearchParams';
import getSearchParam from '../../../utils/urlUtils/getSearchParam';
import { find } from 'lodash';
import { NoticeProps } from 'react-select/src/components/Menu';
import styles from './TablesSearchSelectFilter.module.scss';
import DropdownIndicator from '../../../components/DropdownIndicator/DropdownIndicator';

export interface Option {
  value: string | number;
  label: string;
}

export type Value = Option | Option[] | null;

export interface TablesSelectFilterProps {
  options: Option[];
  onChange?: (data: Option) => void;
  value?: Value;
  isSearchable?: boolean;
  isClearable?: boolean;
  placeholder?: string;
  isMulti?: boolean;
  disabled?: boolean;
  loading?: boolean;
  additionalStyles?: string;
  name?: string;
  disabledLoading?: boolean;
  setValue?: (name: string, data: any) => void;
}

const TablesSearchSelectFilter = (props: TablesSelectFilterProps) => {
  const {
    options,
    value,
    isSearchable = false,
    isClearable = false,
    placeholder = '',
    isMulti = false,
    disabled = false,
    loading = false,
    disabledLoading = false,
    name,
  } = props;

  const { query } = useSearchParams();
  const { push } = useHistory();
  const { pathname } = useLocation();

  const [valueState, setValueState] = useState(value);

  // Updates the internal value if controlled from parent component
  React.useEffect(() => setValueState(value), [value]);

  // Populate input value with query URL value
  React.useEffect(() => {
    if (query && name) {
      let param = getSearchParam(decodeURIComponent(query), name);

      if (param) {
        param = param.replace(/\+/g, '%20');
        const decodedParam = decodeURIComponent(param);

        if (isMulti) {
          const selectedOptionValues = decodedParam.split(',');

          // Map the parameters to options in selection order
          const selectedOptions: Option[] = [];
          selectedOptionValues.forEach((value) => {
            const selectedOption = options.find((option) => value === option.label.toString());
            if (selectedOption) {
              selectedOptions.push(selectedOption);
            }
          });

          setValueState(selectedOptions);
        } else {
          const selectedOption = find(options, (option) => option.value === decodedParam);
          setValueState(selectedOption);
        }
      }

      // No parameters selected
      else {
        isMulti ? setValueState([]) : setValueState(null);
      }
    }

    // No parameters selected
    else {
      isMulti ? setValueState([]) : setValueState(null);
    }
  }, [isMulti, name, options, query]);

  const handleChange = (values: Option[]) => {
    let valueString = '';

    if (values !== null && values.length > 0) {
      valueString = values.map((value) => value.label).join(',');
    }

    const searchParams = updateURLSearchParams(query, [
      {
        name: name ? name : '',
        value: valueString,
      },
    ]);

    const sanitizedValue = encodeURIComponent(searchParams.toString());
    updatePath(sanitizedValue, pathname, push);
  };

  const searchableClassName = disabledLoading ? styles.searchSelectFilterDisabledLoading : '';

  const reactSelectProps = {
    className: `search-select-filter ${searchableClassName}`,
    classNamePrefix: 'search-select-filter',
  };

  const NoOptionsMessage = (props: NoticeProps<Option, boolean>): JSX.Element => (
    <components.NoOptionsMessage {...props}>No results found</components.NoOptionsMessage>
  );

  return (
    <div className={styles.targetsSearch}>
      <Select
        options={options}
        isSearchable={isSearchable}
        isClearable={isClearable}
        placeholder={placeholder}
        onChange={(data: any) => handleChange(data)}
        value={valueState}
        isMulti={isMulti}
        isDisabled={loading || disabled}
        components={{
          NoOptionsMessage,
          DropdownIndicator,
        }}
        {...reactSelectProps}
      />
    </div>
  );
};

export default TablesSearchSelectFilter;
