import produce from 'immer';
import { Action } from '../../../redux/types';
import { PERMITTED_LOCATIONS_TYPES } from '../../actions/transactionActions/permittedLocationsTypes';
import { permittedLocations } from '../../initialStates/transactions/permittedLocations';

export const permittedLocationsReducer = (state = permittedLocations, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case PERMITTED_LOCATIONS_TYPES.GET_LOCATIONS_BEGIN:
        draft.loading = true;
        return draft;

      case PERMITTED_LOCATIONS_TYPES.GET_LOCATIONS_SUCCESS:
        draft.locations = action.payload;
        draft.loading = false;
        return draft;

      case PERMITTED_LOCATIONS_TYPES.GET_LOCATIONS_ERROR:
        draft.loading = false;
        draft.error = true;
        return draft;

      default:
        return draft;
    }
  });
};
