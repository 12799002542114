import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { IOnAccountPaymentOptionSummary } from '@ready/dashboardv2api.contracts';
import { ContextParams } from '../../../types/ContextParams.interface';
import styles from './OnAccountPaymentOptionsList.module.scss';
import NoOptions from './NoOptions';
import StatusIndicator from '../../../components/StatusIndicator/StatusIndicator';

interface Props {
  onAccountPaymentOptions: IOnAccountPaymentOptionSummary[];
}

export const OnAccountPaymentOptionsList = ({ onAccountPaymentOptions }: Props): JSX.Element => {
  const { contextId: companyId, locationId } = useParams<ContextParams>();
  const haveOptionsToList = onAccountPaymentOptions.length > 0;

  return (
    <>
      {!haveOptionsToList && <NoOptions />}

      {haveOptionsToList &&
        onAccountPaymentOptions.map((onAccountPaymentOption) => (
          <Link
            key={onAccountPaymentOption._id}
            to={`/companies/${companyId}/companyLocations/${locationId}/paymentProcessing/paymentOptions/${onAccountPaymentOption._id}`}
          >
            <div className={styles.container}>
              <div className={styles.details}>
                <div className={styles.name}>{onAccountPaymentOption.name}</div>
                <div className={styles.description}>{onAccountPaymentOption.description}</div>
              </div>
              <div className={styles.status}>
                <StatusIndicator
                  active={onAccountPaymentOption.enabled}
                  activeLabel='Enabled'
                  inactiveLabel='Disabled'
                />
              </div>
            </div>
          </Link>
        ))}
    </>
  );
};
