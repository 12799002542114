import { ILocationOrderSettings } from '@ready/dashboardv2api.contracts';
import { FieldValidation } from '../../../types/FieldValidation.interface';

export interface ILocationOrderSettingsState {
  loading: boolean;
  orderSettingsDialogOpen: boolean;
  orderSettingsData: ILocationOrderSettings;
  validation: {
    orderThrottling: {
      amount: FieldValidation;
    };
  };
}

export const locationOrderSettingsInitialState: ILocationOrderSettingsState = {
  loading: false,
  orderSettingsDialogOpen: false,
  orderSettingsData: {
    orderThrottling: {
      enabled: false,
      amount: 0,
      durationMinutes: 5,
    },
    disruptionMessage: '',
    alcoholPolicy: '',
    alcoholPurchasingLimit: 0,
  },
  validation: {
    orderThrottling: {
      amount: {
        error: '',
        hasError: false,
      },
    },
  },
};
