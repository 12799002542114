import React from 'react';
import debounce from 'lodash/debounce';
import { components, InputActionMeta, MenuListComponentProps } from 'react-select';
import { NoticeProps } from 'react-select/src/components/Menu';

import SelectFilter, { Option } from './SelectFilter';
import readyProgressSpinner from '../../styles/assets/ready-progress-spinner-gray.svg';
import { SelectComponents } from 'react-select/src/components';

export interface SelectFilterAsyncProps {
  options: Option[];
  value?: Option | Option[] | null;
  placeholder?: string;
  isMulti?: boolean;
  disabled?: boolean;
  isClearable?: boolean;
  loading: boolean;
  processing?: boolean;
  isInModal?: boolean;
  hideIndicator?: boolean;
  withError?: boolean;
  additionalStyles?: string;
  limit?: number;
  overflowMessage?: string;
  showOverflowMessage?: () => boolean;
  handleFetch: (filter: string, limit: number) => void;
  handleInitialFetch?: (limit: number) => void;
  onChange?: (data: Option) => void;
  alwaysFetchOnOpen?: boolean;
  customComponents?: Partial<SelectComponents<Option, boolean>>;
  setValue?: (name: string, data: any) => void;
  name?: string;
  autoFocus?: boolean;
}

const spinner = (): JSX.Element => (
  <div className='select-filter__loading'>
    <img src={readyProgressSpinner} alt='loading' />
    Searching...
  </div>
);

const SelectFilterAsync = (props: SelectFilterAsyncProps) => {
  const {
    options,
    value,
    placeholder = '',
    isMulti = false,
    disabled = false,
    isClearable = false,
    loading,
    processing = false,
    isInModal = false,
    hideIndicator = false,
    withError = false,
    additionalStyles = '',
    limit = 50,
    overflowMessage = 'Top 50 results shown. Type to refine your search.',
    showOverflowMessage,
    handleFetch,
    handleInitialFetch,
    onChange,
    alwaysFetchOnOpen,
    customComponents,
    setValue,
    name,
    autoFocus,
  } = props;
  const debounceTime = 150;

  const [isInitialized, setIsInitialized] = React.useState(false);
  const [filter, setFilter] = React.useState('');
  const isFiltering = loading && isInitialized;

  const onMenuOpen = () => {
    if ((alwaysFetchOnOpen || !isInitialized) && !loading) {
      setIsInitialized(true);
      if (!!handleInitialFetch) {
        handleInitialFetch(limit);
      } else {
        handleFetch(filter, limit);
      }
    }
  }; // this defers loading until the first time the dropdown is selected
  const onInputChange = debounce((newValue: string, actionMeta: InputActionMeta) => {
    setFilter(newValue);
    if (actionMeta.action === 'input-change') {
      handleFetch(newValue, limit);
    } else if (actionMeta.action === 'input-blur') {
      if (!newValue) {
        // this resets if the selection has been cleared
        setIsInitialized(false);
      }
    }
  }, debounceTime);
  // this allows us to make an API call to handle filtering instead of using the default filtering
  const filterOption = () => true; // this overrides the default filtering to let the API do it for us

  const onSelectionChange = (data: Option): void => {
    // we need to reset the filter when the user uses the 'X' button to clear the selection
    if (!data) {
      setFilter('');
      setIsInitialized(false);
    }
    if (onChange) {
      onChange(data);
    }
  };

  const footer =
    isInitialized && ((showOverflowMessage && showOverflowMessage() === true) ?? options.length === limit) ? (
      <div className='select-filter__footer'>{overflowMessage}</div>
    ) : null;

  const MenuList = (props: MenuListComponentProps<Option, boolean>): JSX.Element => (
    <components.MenuList {...props}>
      {isFiltering ? (
        spinner()
      ) : (
        <>
          {props.children}
          {footer}
        </>
      )}
    </components.MenuList>
  );

  const NoOptionsMessage = (props: NoticeProps<Option, boolean>): JSX.Element => (
    <components.NoOptionsMessage {...props}>
      {isInitialized ? 'No results found' : spinner()}
    </components.NoOptionsMessage>
  );

  return (
    <SelectFilter
      options={options}
      placeholder={placeholder}
      isSearchable={true}
      isClearable={isClearable}
      value={value}
      onMenuOpen={onMenuOpen}
      onInputChange={onInputChange}
      onChange={onSelectionChange}
      filterOption={filterOption}
      isMulti={isMulti}
      disabled={disabled}
      loading={processing}
      isInModal={isInModal}
      hideIndicator={hideIndicator}
      withError={withError}
      additionalStyles={additionalStyles}
      customComponents={{ ...customComponents, MenuList, NoOptionsMessage }}
      setValue={setValue}
      name={name}
      autoFocus={autoFocus}
    />
  );
};

export default SelectFilterAsync;
