import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import AppLayout from '../components/AppLayout/AppLayout';
import { ISession } from '../redux/initialStates/session/session';
import ReadyRoutes from './ReadyRoutes';
import CompaniesRoutes from './CompaniesRoutes';
import ContextSwitcher from '../components/ContextSwitcher/ContextSwitcher';
import { updateModalVisible } from '../redux/actions/contextSwitcherActions/contextSwitcherActions';
import { updateMobileSidebarVisible } from '../redux/actions/uiActions/sidebarStateActions';
import { resetResponseState } from '../redux/actions/uiActions/responseStateActions';
import { Action } from '../redux/types';
import Home from '../pages/Home/Home';
import NotFound from '../pages/NotFound/NotFound';
import { useSession } from '../hooks';
import { CompanySelectionPage } from 'pages/CompanySelection/CompanySelectionPage';

export interface ProtectedRoutesProps {
  session: ISession;
  modalVisible: boolean;
  updateModalVisible: (modal: boolean) => Action | void;
  updateMobileSidebarVisible: (modal: boolean) => Action | void;
  resetResponseState: () => void;
}

const ProtectedRoutes = (props: ProtectedRoutesProps) => {
  const { session, modalVisible, updateModalVisible, updateMobileSidebarVisible, resetResponseState } = props;
  const { pathname } = useLocation();
  const contextId = extractContextId(pathname);

  // session
  useSession(session, contextId);

  React.useEffect(() => {
    // close the sidebar on every url change
    updateMobileSidebarVisible(false);
    // reset response state
    resetResponseState();
  });

  return (
    <>
      <AppLayout session={session}>
        <Switch>
          <Route path='/companySelection' component={CompanySelectionPage} />
          {/* ready routes */}
          <Route path='/ready' component={ReadyRoutes} />
          {/* companies routes */}
          <Route path='/companies/:contextId' component={CompaniesRoutes} />
          {/* root route */}
          <Route exact path='/' component={Home} />

          <Route path='*' component={NotFound} />
        </Switch>
      </AppLayout>

      {modalVisible && <ContextSwitcher setShowModal={updateModalVisible} />}
    </>
  );
};

const extractContextId = (path: string) => {
  if (!path.startsWith('/companies')) return '';
  const pathSegments = path.split('/');
  const contextIdIndex = 2;
  return pathSegments[contextIdIndex];
};

const mapStateToProps = (state: any) => ({
  session: state.session,
  modalVisible: state.contextSwitcher.modalVisible,
});

export default connect(mapStateToProps, {
  updateModalVisible,
  updateMobileSidebarVisible,
  resetResponseState,
})(ProtectedRoutes);
