import { IForcedModifier } from '@ready/menu.core';
import Divider from 'components/Divider/Divider';
import { FormControl } from 'components/Form';
import LinkButton from 'components/LinkButton/LinkButton';
import NoneValue from 'components/Value/NoneValue';
import React, { Fragment } from 'react';
import { compositeStyles } from 'utils/cssUtils';
import { ForcedModifier } from './ForcedModifier';
import styles from './ForcedModifiers.module.scss';

interface Props {
  forcedModifiers: IForcedModifier[];
  onAddOrEditClick: () => void;
  readonly?: boolean;
}

export const ForcedModifiersControl = ({ forcedModifiers = [], onAddOrEditClick, readonly = false }: Props) => {
  const hasForcedModifiers = forcedModifiers.length > 0;

  return (
    <FormControl label='Forced Modifiers'>
      <div className={styles.forcedMods}>
        {!forcedModifiers || (forcedModifiers.length === 0 && readonly) ? (
          <NoneValue />
        ) : (
          forcedModifiers.map((forcedModifier: IForcedModifier) => (
            <Fragment key={forcedModifier.itemId}>
              <ForcedModifier forcedModifier={forcedModifier} removable={!readonly}/>
              <Divider size='small' />
            </Fragment>
          ))
        )}

        {!readonly && (
          <div className={compositeStyles([{ editLinkButton: hasForcedModifiers }], styles)}>
            <LinkButton onClick={onAddOrEditClick}>
              {!hasForcedModifiers ? '+ Add Forced Modifier' : 'Edit Forced Modifiers'}
            </LinkButton>
          </div>
        )}
      </div>
    </FormControl>
  );
};
