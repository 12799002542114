import React from 'react';
import { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'components/Modal';
import TextInput from 'components/TextInput/TextInput';
import { FormControl } from 'components/Form';
import { useForm } from 'react-hook-form';

interface Props {
  setShowModal: (show: boolean) => void;
  onAddTerminal: (fields: IAddTerminalFormFields) => Promise<void>;
}

export const AddPaymentTerminalModal = ({ setShowModal, onAddTerminal }: Props) => {
  const [saving, setSaving] = useState<boolean>(false);

  const onAddTerminalsClick = async (fields: IAddTerminalFormFields) => {
    setSaving(true);
    await onAddTerminal(fields);
    setSaving(false);
    setShowModal(false);
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IAddTerminalFormFields>();

  return (
    <Modal setShowModal={setShowModal}>
      <ModalHeader headerLabel='Add Payment Terminal' setShowModal={(visible) => setShowModal(visible)} />
      <ModalBody>
        <FormControl
          label='Terminal Serial Number *'
          bold
          errorMessage={errors.serialNumber?.message}
          withError={errors.serialNumber !== undefined}
        >
          <TextInput
            {...register('serialNumber', { required: 'Serial number required.' })}
            placeholder='000000123456789'
            disabledLoading={saving}
            withError={errors.serialNumber !== undefined}
          />
        </FormControl>
      </ModalBody>
      <ModalFooter
        primaryLabel='Add Terminal(s)'
        primaryActionHandler={handleSubmit((fields) => onAddTerminalsClick(fields))}
        secondaryLabel='Cancel'
        loading={saving}
        secondaryActionHandler={() => setShowModal(false)}
      />
    </Modal>
  );
};

export interface IAddTerminalFormFields {
  serialNumber: string;
}
