import React from 'react';
import styles from './SectionHeader.module.scss';
import Button from '../../../components/Button/Button';
import OverflowButton from '../../../components/OverflowButton/OverflowButton';

export interface SectionHeaderProps {
  disabled: boolean;
  onReorderClick: () => void;
  onCreateClick: () => void;
  isSharedMenuAssignedToCurrentLocation?: boolean;
}

const SectionHeader = ({
  disabled,
  onReorderClick,
  onCreateClick,
  isSharedMenuAssignedToCurrentLocation = false,
}: SectionHeaderProps): JSX.Element => {
  const sectionsButtons = [
    {
      label: 'Reorder Sections',
      unavailable: disabled,
      onClick: onReorderClick,
    },
    {
      label: '+ New Section',
      unavailable: disabled,
      onClick: onCreateClick,
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.text}>Menu Sections</div>
      {isSharedMenuAssignedToCurrentLocation ? null : (
        <>
          <div className={styles.controls}>
            <Button
              label={sectionsButtons[0].label}
              variant='secondary-gray-bg'
              unavailable={sectionsButtons[0].unavailable}
              onClick={sectionsButtons[0].onClick}
            />
            <Button
              label={sectionsButtons[1].label}
              unavailable={sectionsButtons[1].unavailable}
              onClick={sectionsButtons[1].onClick}
            />
          </div>
          <div className={styles.overflow}>
            <OverflowButton options={sectionsButtons} />
          </div>
        </>
      )}
    </div>
  );
};

export default SectionHeader;
