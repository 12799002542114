import React, { useEffect, useCallback } from 'react';
import {
  PrincipalPermissions,
  ResourceType,
  MenuScheduleResourceActions,
  SecurityScope,
  Verifier,
} from '@ready/security.core';
import { connect, ConnectedProps } from 'react-redux';
import styles from './EditSchedulePage.module.scss';
import ActionHeader, { Action } from '../../../components/ActionHeader/ActionHeader';
import LayoutContent from '../../../components/AppLayout/LayoutContent';
import { Form, FormControl } from '../../../components/Form';
import FormActionBar from '../../../components/FormActionBar/FormActionBar';
import TextInput from '../../../components/TextInput/TextInput';
import { AppState } from '../../../redux/initialStates/AppState';
import TimeSlotSelector from '../../../components/TimeSlotSelector/TimeSlotSelector';
import { IScheduleSlot } from '@ready/menu.core';
import Button from '../../../components/Button/Button';
import { Routes } from '../../SchedulesRouter';
import { useHistory, useParams } from 'react-router-dom';
import { ContextParams } from '../../../types/ContextParams.interface';
import {
  initSchedule,
  setScheduleSlot,
  setScheduleName,
  addNewSlot,
  removeSlot,
  setScheduleProcessing,
  validateSchedule,
  submitSchedule,
  fetchSchedule,
  resetForm,
  deleteSchedule,
  showDeleteDialog,
} from '../../redux/ScheduleActions';
import { ScheduleValidationErrors } from '../../redux/ScheduleState';
import { setFormIsDirty } from '../../../redux/actions/uiActions/formStateActions';
import DeleteScheduleDialog from './DeleteScheduleDialog';
import { Routes as MenuBuilderRoutes } from '../../MenuBuilderRouter';

const EditSchedulePage = ({
  schedule,
  scheduleCache,
  processing,
  loading,
  errors,
  isDirty,
  deleteDialogOpen,
  initSchedule,
  setScheduleSlot,
  setScheduleName,
  addNewSlot,
  removeSlot,
  setScheduleProcessing,
  validateSchedule,
  submitSchedule,
  fetchSchedule,
  resetForm,
  setFormIsDirty,
  deleteSchedule,
  showDeleteDialog,
  permissions,
}: ReduxProps): JSX.Element => {
  const { contextId, locationId, menuItemId, id } = useParams<ScheduleContextParams>();
  const { push } = useHistory();

  // permission check
  const canManageAllSchedules = Verifier.check(
    new PrincipalPermissions(permissions),
    SecurityScope.company,
    ResourceType.menuSchedule,
    MenuScheduleResourceActions.all,
    locationId
  );

  useEffect(() => {
    fetchSchedule(contextId, id);

    return () => {
      initSchedule();
    };
  }, [fetchSchedule, initSchedule, contextId, id]);

  const handleFormSubmit = useCallback(async () => {
    const hasErrors = validateSchedule(schedule);

    if (!hasErrors) {
      await submitSchedule(contextId, schedule);
    }

    // Just return success status to the FormActionBar since error handling is
    // already taken care of.
    return { status: 200 };
  }, [contextId, schedule, submitSchedule, validateSchedule]);

  // Go back to list page (no form reset, since this is a create page)
  const handleResetForm = () => {
    resetForm();
  };

  const handleAddTimeSlotClick = () => {
    addNewSlot();
  };

  const handleScheduleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setScheduleName(event.target.value);
  };

  const handleDeleteScheduleClick = (_: React.MouseEvent<HTMLButtonElement>) => {
    showDeleteDialog(true);
  };

  const handleDeleteClick = async (_: React.MouseEvent<HTMLButtonElement>) => {
    const success = await deleteSchedule(contextId, schedule._id);

    // Close the dialog and go to list page
    if (success) {
      showDeleteDialog(false);
      push(Routes.getSchedulesListPageRoute(contextId));
    }
  };

  const handleCloseDeleteDialog = () => {
    showDeleteDialog(false);
  };

  const actionButtons: Action[] = canManageAllSchedules
    ? [
        {
          label: 'Delete',
          variant: 'secondary-gray-bg',
          onClick: handleDeleteScheduleClick,
          disabled: processing,
        },
      ]
    : [];

  const backLink = getBackLink(contextId, locationId, menuItemId);

  return (
    <>
      <DeleteScheduleDialog
        onDelete={handleDeleteClick}
        isOpen={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        processing={processing}
        schedule={schedule}
      />
      <FormActionBar
        actionBarVisible={isDirty}
        handleFormSubmit={handleFormSubmit}
        resetForm={handleResetForm}
        setIsFormProcessing={setScheduleProcessing}
        isFormProcessing={processing}
      />
      <LayoutContent title='Schedule Details' containerType='within' loadingContent={loading}>
        <ActionHeader text={scheduleCache.name} backLinkTo={backLink} actionButtons={actionButtons} />
        <Form additionalClassName={styles.form}>
          <FormControl
            label='Schedule Name *'
            errorMessage={'Schedule Name is required'}
            withError={errors.emptyNameError}
          >
            <div className={styles.formControlContainer}>
              <TextInput
                value={schedule.name}
                onChange={handleScheduleNameChange}
                disabled={processing || !canManageAllSchedules}
              />
            </div>
          </FormControl>
          <FormControl label='Availability *'>
            <div className={styles.formControlContainer}>
              {schedule.slots.map((slot, slotIndex) => {
                const handleClearClick = () => {
                  removeSlot(slotIndex);
                  setFormIsDirty(true);
                };

                const handleSlotChange = (scheduleSlot: IScheduleSlot) => {
                  setScheduleSlot(scheduleSlot, slotIndex);
                };

                const { daysOfWeekErrorMessage, startTimeErrorMessage, endTimeErrorMessage } = getErrorMessages(
                  errors,
                  slotIndex
                );

                return (
                  <TimeSlotSelector
                    // 'slots' is an arbitrary array, so there are no unique
                    // identifiers except for the index
                    key={slotIndex}
                    value={slot}
                    onChange={handleSlotChange}
                    onClearClick={handleClearClick}
                    processing={processing}
                    daysOfWeekErrorMessage={daysOfWeekErrorMessage}
                    startTimeErrorMessage={startTimeErrorMessage}
                    endTimeErrorMessage={endTimeErrorMessage}
                    // Disable the clear button if it's the only slot
                    disableClearClick={schedule.slots.length <= 1}
                    canManageAllSchedules={canManageAllSchedules}
                  />
                );
              })}
              {canManageAllSchedules && (
                <Button label='+ Add Time Slot' size='large' variant='secondary' onClick={handleAddTimeSlotClick} />
              )}
            </div>
          </FormControl>
        </Form>
      </LayoutContent>
    </>
  );
};

interface ScheduleContextParams extends ContextParams {
  menuItemId: string;
}

const getBackLink = (companyId: string, locationId?: string, menuItemId?: string) => {
  if (locationId) {
    if (menuItemId) {
      return MenuBuilderRoutes.getMenuItemPage(companyId, locationId, menuItemId);
    }
  }

  return Routes.getSchedulesListPageRoute(companyId);
};

const getErrorMessages = (errors: ScheduleValidationErrors, slotIndex: number) => {
  const slotErrors = errors.slotErrors.find((error) => error.slotIndex === slotIndex);

  return {
    daysOfWeekErrorMessage: slotErrors?.emptyDaysError ? 'Select at least 1 day' : '',
    startTimeErrorMessage: slotErrors?.emptyStartTimeError
      ? 'Enter a valid Start Time'
      : slotErrors?.timeRangeOrderError
      ? 'Start Time must be before End Time'
      : '',
    endTimeErrorMessage: slotErrors?.emptyEndTimeError ? 'Enter a valid End Time' : '',
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    schedule: state.menuBuilder.schedule.schedule,
    scheduleCache: state.menuBuilder.schedule.scheduleCache,
    processing: state.menuBuilder.schedule.processing,
    loading: state.menuBuilder.schedule.loading,
    errors: state.menuBuilder.schedule.errors,
    isDirty: state.ui.formState.isDirty,
    deleteDialogOpen: state.menuBuilder.schedule.deleteModalOpen,
    permissions: state.session.permissions.permissionsList,
  };
};

const actionCreators = {
  initSchedule,
  setScheduleSlot,
  setScheduleName,
  addNewSlot,
  removeSlot,
  setScheduleProcessing,
  validateSchedule,
  submitSchedule,
  fetchSchedule,
  resetForm,
  setFormIsDirty,
  deleteSchedule,
  showDeleteDialog,
};

const connector = connect(mapStateToProps, actionCreators);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(EditSchedulePage);
