import { createTax, updateTax } from 'companySettings/redux/CompanySettingsActions';
import { ISmbTax, ISmbTaxRequest } from 'companySettings/types/SmbTaxTypes.type';
import { Form, FormControl } from 'components/Form';
import FormattedInput from 'components/FormattedInput/FormattedInput';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'components/Modal';
import TextInput from 'components/TextInput/TextInput';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'redux/store';
import { ContextParams } from 'types/ContextParams.interface';
import styles from './CreateEditTaxModal.module.scss';
import { useSelector } from 'react-redux';
import { selectTaxesState } from '../redux/CompanySettingsSelectors';

const ratePayload = (rate: string): number => {
  return Number(rate);
};

const createTaxRequestPayload = (taxInfo: TaxForm, companyId: string): Omit<ISmbTax, '_id'> => {
  return {
    name: taxInfo.name,
    companyId,
    rate: ratePayload(taxInfo.rate.toString()),
  };
};

const updateTaxRequestPayload = (taxInfo: TaxForm, companyId: string, taxId: string): ISmbTaxRequest => ({
  _id: taxId,
  name: taxInfo.name,
  companyId,
  rate: ratePayload(taxInfo.rate.toString()),
});

type TaxForm = Pick<ISmbTax, 'name' | 'rate'>;

const mapSmbTaxToFormValues = (tax?: ISmbTax): TaxForm | undefined =>
  tax && {
    name: tax.name,
    rate: tax.rate,
  };

interface Props {
  onClose: () => void;
  isNew: boolean;
}

export const CreateEditTaxModal = ({ onClose, isNew }: Props) => {
  const { selectedTax } = useSelector(selectTaxesState);
  const taxForm = useForm<TaxForm>({ values: mapSmbTaxToFormValues(selectedTax) });
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = taxForm;
  const { contextId: companyId } = useParams<ContextParams>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  return (
    <Modal setShowModal={() => {}}>
      <ModalHeader headerLabel='New Tax' setShowModal={() => {}}></ModalHeader>
      <ModalBody>
        <FormProvider {...taxForm}>
          <Form isModalForm useMobileStyling>
            <FormControl label='Tax Name *' errorMessage={errors.name?.message} withError={errors.name !== undefined}>
              <Controller
                name='name'
                control={control}
                defaultValue={''}
                rules={{ required: 'Required field.' }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    autoFocus
                    withError={errors.name !== undefined}
                    disabled={isSaving}
                    placeholder='e.g. Sales Tax'
                  />
                )}
              />
            </FormControl>

            <FormControl
              additionalStyles={styles.rate}
              label='Rate *'
              errorMessage={errors.rate?.message}
              withError={errors.rate !== undefined}
            >
              <Controller
                name='rate'
                defaultValue={''}
                rules={{ required: 'Required field.' }}
                render={({ field }) => (
                  <FormattedInput
                    {...field}
                    format={'percentage'}
                    withError={errors.rate !== undefined}
                    loading={isSaving}
                  />
                )}
              ></Controller>
            </FormControl>
          </Form>
        </FormProvider>
      </ModalBody>
      <ModalFooter
        primaryLabel={isNew ? 'Create Tax' : 'Update Tax'}
        primaryActionHandler={handleSubmit(async (taxInfo) => {
          setIsSaving(true);
          if (isNew) {
            await dispatch(createTax(companyId, createTaxRequestPayload(taxInfo, companyId)));
          } else {
            await dispatch(updateTax(companyId, updateTaxRequestPayload(taxInfo, companyId, selectedTax?._id ?? '')));
          }
          setIsSaving(false);
          onClose();
        })}
        loading={isSaving}
        secondaryActionHandler={() => {
          onClose();
        }}
        secondaryLabel='Cancel'
      />
    </Modal>
  );
};
