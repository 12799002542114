import { IChecksReportState } from './initialState';
import { IChecksReportListItem, IChecksReportWidgetsData, PaginationResponse } from '@ready/dashboardv2api.contracts';
import { IPagedResponse } from '@ready/table.core';
import { ILocation } from '../../../../services/types/ILocation.type';

export const fetchChecksListReducers = {
  pending: (state: IChecksReportState) => {
    state.loading.list = true;
  },
  fulfilled: (state: IChecksReportState, action: { payload: PaginationResponse<IChecksReportListItem> }) => {
    const { items, total, limit, start, length } = action.payload;
    state.loading.list = false;
    state.items = items || [];
    state.pagination = { total, limit, start, length };
  },
  rejected: (state: IChecksReportState) => {
    state.loading.list = false;
    state.errors.list = 'Unable to retrieve checks list.';
  },
};

export const exportChecksListReducers = {
  pending: (state: IChecksReportState) => {
    state.loading.export = true;
  },
  fulfilled: (state: IChecksReportState) => {
    state.loading.export = false;
  },
  rejected: (state: IChecksReportState) => {
    state.loading.export = false;
  },
};

export const fetchPermittedLocationsReducers = {
  pending: (state: IChecksReportState) => {
    state.loading.permittedLocations = true;
  },
  fulfilled: (state: IChecksReportState, action: { payload: IPagedResponse<ILocation> }) => {
    state.loading.permittedLocations = false;
    state.permittedLocations = action.payload;
  },
  rejected: (state: IChecksReportState) => {
    state.loading.permittedLocations = false;
  },
};

export const fetchCheckWidgetsReducers = {
  pending: (state: IChecksReportState) => {
    state.loading.widgets = true;
  },
  fulfilled: (state: IChecksReportState, action: { payload: IChecksReportWidgetsData }) => {
    state.loading.widgets = false;
    state.widgets = action.payload;
  },
  rejected: (state: IChecksReportState) => {
    state.loading.widgets = false;
    state.errors.widgets = 'Unable to retrieve widgets.';
  },
};
