import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { CompaniesListPage } from './CompanyListPage/CompaniesListPage';
import { CompanySettingsPage } from './CompanySettingsPage/CompanySettingsPage';

const BASE_URL = '/ready/companiesSettings';

export const CompaniesSettingsRouter = () => {
  return (
    <Switch>
      <Route exact path={`${BASE_URL}`} component={CompaniesListPage} />
      <Route exact path={`${BASE_URL}/:contextId`} component={CompanySettingsPage} />
      <Route exact path={`${BASE_URL}/:contextId/:tabId`} component={CompanySettingsPage} />
    </Switch>
  );
};
export class CompaniesSettingsRoutes {
  public static getCompaniesListPage(): string {
    return BASE_URL;
  }
  public static getCompanyPaymentSettingsPage(companyId: string): string {
    return `${BASE_URL}/${companyId}/payment`;
  }
  public static getCompanyLoyaltySettingsPage(companyId: string): string {
    return `${BASE_URL}/${companyId}/loyalty`;
  }
}
