import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../redux/initialStates/AppState';
import LayoutContent from '../components/AppLayout/LayoutContent';
import { useParams } from 'react-router-dom';
import { PanelLayout, Panel } from '../components/PanelLayout';
import styles from './ServerAssignmentEditPage.module.scss';
import {
  UnassignedPanelHeader,
  UnassignedPanelData,
  AssignTablesDialog,
  TableAssignmentPanel,
  RemoveServerDialog,
} from './components';
import EmptyResult from '../components/EmptyResult/EmptyResult';
import LocationSelectorLink from '../components/AppLayout/LocationSelectorLink';
import {
  loadTableLists,
  initTableList,
  selectTable,
  deselectTable,
  openAssignModal,
  fetchLocationDetails,
  initLocationDetails,
} from './redux/ServerAssignmentActions';
import useResponsiveBreakpoint from '../hooks/useResponsiveBreakpoint';
import { SelectableTable } from './redux/ServerAssignmentState';
import { ContextParams } from '../types/ContextParams.interface';
import { Verifier, PrincipalPermissions, SecurityScope, ResourceType, CommonActions } from '@ready/security.core';
import { pageErrorState } from '../redux/actions/uiActions/responseStateActions';
import { usePageTitle } from '../hooks';
import ServerAssignmentUnsupportedPOSPage from './ServerAssignmentUnsupportedPOSPage';

const countSelectedTables = (tables: SelectableTable[]): number =>
  tables.reduce((sum, table) => (table.selected ? sum + 1 : sum), 0);

const ServerAssignmentEditPage = ({
  loadTableLists,
  initTableList,
  selectTable,
  deselectTable,
  openAssignModal,
  fetchLocationDetails,
  initLocationDetails,
  unassignedTables,
  assignedTables,
  tableAssignments,
  loading,
  processing,
  locationDetails,
  permissions,
  pageErrorState,
}: ReduxProps): JSX.Element => {
  usePageTitle('Server Assignment');
  const { contextId, locationId } = useParams<ContextParams>();
  const { isMobile } = useResponsiveBreakpoint();

  const [showContent, setShowContent] = React.useState<boolean>(true);

  const principalPermissions = new PrincipalPermissions(permissions);

  const hasAllPermission = Verifier.check(
    principalPermissions,
    SecurityScope.location,
    ResourceType.tableAssignment,
    CommonActions.all,
    locationId
  );

  const hasViewPermission = Verifier.check(
    principalPermissions,
    SecurityScope.location,
    ResourceType.tableAssignment,
    CommonActions.view,
    locationId
  );

  const hasPermission = hasAllPermission || hasViewPermission;

  if (!hasPermission) {
    pageErrorState(401);
  }

  // Loads the table list
  React.useEffect(() => {
    loadTableLists(contextId, locationId);
    return () => {
      initTableList();
    };
  }, [loadTableLists, initTableList, contextId, locationId]);

  React.useEffect(() => {
    fetchLocationDetails(contextId, locationId);
    return () => {
      initLocationDetails();
    };
  }, [fetchLocationDetails, initLocationDetails, contextId, locationId]);

  const showEmptyResult = tableAssignments.length === 0;

  const handleTableCheck = (tableId: string, checked: boolean) =>
    checked ? selectTable(tableId) : deselectTable(tableId);

  const handleAssignClick = () => {
    openAssignModal('select');
  };

  const selectedTableCount = countSelectedTables(unassignedTables) + countSelectedTables(assignedTables);
  const pageTitleControls = (
    <LocationSelectorLink text={locationDetails?.name ?? ''} to={`/companies/${contextId}/serverAssignment`} />
  );
  const isServerAssignmentSupported = locationDetails?.posSystemType !== 'square';

  return (
    <>
      {!isServerAssignmentSupported ? (
        <ServerAssignmentUnsupportedPOSPage titleControls={pageTitleControls} />
      ) : (
        <>
          <AssignTablesDialog />
          <RemoveServerDialog />
          <LayoutContent
            title='Server Assignment'
            containerType='within'
            darkBackground
            loadingContent={loading}
            titleControls={pageTitleControls}
          >
            <PanelLayout columns={2} flexWidths={[34, 66]} additionalColumnStyles={styles.panelColumn}>
              <Panel
                key='unassigned-table'
                fullContentArea
                withDividers
                additionalSectionStyles={styles.sectionContainer}
                additionalContentStyles={styles.unassignedPanel}
              >
                <UnassignedPanelHeader
                  selectedCount={selectedTableCount}
                  onAssignClick={handleAssignClick}
                  isExpanded={showContent}
                  onExpandToggleClick={() => setShowContent(!showContent)}
                  additionalStyles={styles.sectionContents}
                  loading={processing || loading}
                  hasAllPermission={hasAllPermission}
                />
                {showContent || !isMobile ? (
                  <div className={styles.dataPanelContainer}>
                    <UnassignedPanelData
                      additionalContentStyles={styles.sectionContents}
                      assignedTables={assignedTables}
                      unassignedTables={unassignedTables}
                      onChange={handleTableCheck}
                      loading={loading}
                      disabled={loading || processing}
                      hasAllPermission={hasAllPermission}
                    />
                  </div>
                ) : null}
              </Panel>

              <Panel
                key='itemInfo'
                fullContentArea
                transparent
                additionalContentStyles={styles.panelContent}
                additionalSectionStyles={styles.panelSection}
                emptyResult={showEmptyResult}
                emptyResultComponent={
                  <EmptyResult
                    title='No Tables Assigned'
                    paragraph='Select tables from the Unassigned Tables list to assign them to a server.'
                  />
                }
              >
                {tableAssignments.map((tableAssignment) => (
                  <TableAssignmentPanel
                    tableAssignment={tableAssignment}
                    key={tableAssignment.employee.id}
                    disabled={loading || processing}
                    hasAllPermission={hasAllPermission}
                  />
                ))}
              </Panel>
            </PanelLayout>
          </LayoutContent>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    unassignedTables: state.tableServerAssignment.serverAssignments.unassignedTables,
    assignedTables: state.tableServerAssignment.serverAssignments.assignedTables,
    tableAssignments: state.tableServerAssignment.serverAssignments.tableAssignments,
    loading: state.tableServerAssignment.serverAssignments.tablesLoading,
    processing: state.tableServerAssignment.serverAssignments.assignmentProcessing,
    locationDetails: state.tableServerAssignment.serverAssignments.locationDetails,
    permissions: state.session.permissions.permissionsList,
  };
};

const actionCreators = {
  loadTableLists,
  initTableList,
  selectTable,
  deselectTable,
  openAssignModal,
  fetchLocationDetails,
  initLocationDetails,
  pageErrorState,
};

const connector = connect(mapStateToProps, actionCreators);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ServerAssignmentEditPage);
