import { set200Toast, toastErrorState } from '../../redux/actions/uiActions/responseStateActions';
import LoyaltyPOSService from '../services/LocationLoyaltyServices';
import { Option } from '../../components/SelectFilter/SelectFilter';
import { IDiscount, ILocationLoyaltyOptions } from '@ready/dashboardv2api.contracts';
import { Action } from '../../redux/types';
import { posLoyaltyPanelInitialState } from './PanelLoyaltyState';
import { setFormIsDirty } from '../../redux/actions/uiActions/formStateActions';

export const PANEL_LOYALTY_ACTIONS = {
  SET_SELECTED_PROVIDER: 'SET_SELECTED_PROVIDER',

  UPDATE_SPENDGO_ACCOUNT_ID: 'UPDATE_SPENDGO_ACCOUNT_ID',
  UPDATE_SPENDGO_STORE_CODE: 'UPDATE_SPENDGO_STORE_CODE',
  UPDATE_SPENDGO_LOYALTY_DISCOUNT: 'UPDATE_SPENDGO_LOYALTY_DISCOUNT',
  UPDATE_SPENDGO_API_KEY: 'UPDATE_SPENDGO_API_KEY',
  UPDATE_SPENDGO_ACCOUNT_DETAILS_URL: 'UPDATE_SPENDGO_ACCOUNT_DETAILS_URL',
  UPDATE_SPENDGO_CLIENT_SECRET: 'UPDATE_SPENDGO_CLIENT_SECRET',

  UPDATE_BYPASS_LOCATION_ID: 'UPDATE_BYPASS_LOCATION_ID',
  UPDATE_PUNCHH_STORE_ID: 'UPDATE_PUNCHH_STORE_ID',
  UPDATE_PUNCHH_LOYALTY_DISCOUNT: 'UPDATE_PUNCHH_LOYALTY_DISCOUNT',

  UPDATE_SQUARE_LOCATION_ID: 'UPDATE_SQUARE_LOCATION_ID',
  UPDATE_SQUARE_PROGRAM_ID: 'UPDATE_SQUARE_PROGRAM_ID',
  UPDATE_SQUARE_API_KEY: 'UPDATE_SQUARE_API_KEY',
  UPDATE_SQUARE_LOYALTY_DISCOUNT: 'UPDATE_SQUARE_LOYALTY_DISCOUNT',
  UPDATE_SQUARE_SKIP_PHONE_VERIFICATION: 'UPDATE_SQUARE_SKIP_PHONE_VERIFICATION',

  LOAD_AVAILABLE_DISCOUNTS_BEGIN: 'LOAD_AVAILABLE_DISCOUNTS_BEGIN',
  LOAD_AVAILABLE_DISCOUNTS_SUCCESS: 'LOAD_AVAILABLE_DISCOUNTS_SUCCESS',
  SET_SELECTED_OPTION_LOYALTY_DISCOUNT: 'SET_SELECTED_OPTION_LOYALTY_DISCOUNT',
  SET_LOYALTY_PANEL: 'SET_LOYALTY_PANEL',
  SAVE_ITEM_VALIDATION_ERROR: 'SAVE_ITEM_VALIDATION_ERROR',
  SET_LOYALTY_TAB: 'SET_LOYALTY_TAB',
  SET_LOYALTY_CACHE: 'SET_LOYALTY_CACHE',
};

export const loadDiscounts = (locationId: string, companyId: string, query: string) => async (dispatch: any) => {
  dispatch({ type: PANEL_LOYALTY_ACTIONS.LOAD_AVAILABLE_DISCOUNTS_BEGIN });
  try {
    const locationDiscounts = await LoyaltyPOSService.getLocationDiscounts(locationId, companyId, query);
    const discounts: Option[] = locationDiscounts.map((d: IDiscount) => ({
      value: d.id,
      label: d.name,
      subLabel: d.posId,
    }));
    dispatch({
      type: PANEL_LOYALTY_ACTIONS.LOAD_AVAILABLE_DISCOUNTS_SUCCESS,
      payload: discounts,
    });
  } catch (err) {
    dispatch(toastErrorState(err.status, err.message));
  }
};

export const changeLoyaltyDiscountSelection = (newOption: Option): Action => ({
  type: PANEL_LOYALTY_ACTIONS.SET_SELECTED_OPTION_LOYALTY_DISCOUNT,
  payload: newOption,
});

export const setLoyaltyPanel = (loyalty: ILocationLoyaltyOptions | undefined) => async (dispatch: any) => {
  const payload = {
    ...posLoyaltyPanelInitialState,
    provider: loyalty?.provider || posLoyaltyPanelInitialState.provider,
    spendgoOptions: loyalty?.spendgoOptions || posLoyaltyPanelInitialState.spendgoOptions,
    byPassOptions: loyalty?.byPassOptions || posLoyaltyPanelInitialState.byPassOptions,
    squareOptions: loyalty?.squareOptions || posLoyaltyPanelInitialState.squareOptions,
    punchhOptions: loyalty?.punchhOptions || posLoyaltyPanelInitialState.punchhOptions,
  } as ILocationLoyaltyOptions;
  dispatch({
    type: PANEL_LOYALTY_ACTIONS.SET_LOYALTY_PANEL,
    payload,
  });
};

export const resetLoyalty = (loyalty: ILocationLoyaltyOptions) => async (dispatch: any) => {
  dispatch(setLoyalty(loyalty));
  dispatch(setFormIsDirty(false));
};

export const setLoyaltyCache = (loyalty: ILocationLoyaltyOptions | undefined): Action => ({
  type: PANEL_LOYALTY_ACTIONS.SET_LOYALTY_CACHE,
  payload: loyalty,
});

const setLoyalty = (loyalty: ILocationLoyaltyOptions) => async (dispatch: any) => {
  dispatch(setLoyaltyPanel(loyalty));
  dispatch(setLoyaltyCache(loyalty));
};

export const saveLoyaltyConfig = (locationId: string, loyalty: ILocationLoyaltyOptions) => async (dispatch: any) => {
  const validationResult = LoyaltyPOSService.validate(loyalty);
  if (validationResult.errorsFound) {
    const provider = loyalty.provider;
    dispatch({
      type: PANEL_LOYALTY_ACTIONS.SAVE_ITEM_VALIDATION_ERROR,
      payload: { provider, fields: validationResult.fields },
    });
    return;
  }
  try {
    const updatedLoyalty = await LoyaltyPOSService.updateLoyaltyConfig(locationId, loyalty);
    dispatch(setLoyalty(updatedLoyalty.loyaltyOptions as ILocationLoyaltyOptions));
    dispatch(setFormIsDirty(false));
    dispatch(set200Toast());
  } catch (error) {
    dispatch(toastErrorState(error.status, error.message));
  }
};
