import {
  IBaseMenuSectionItem,
  IMenuItem,
  IMenuItemRequest,
  ITemplateEmbeddedMenuItem,
  ITemplateEmbeddedModifierGroup,
  ITemplateForcedModifier,
  ITemplateMenuItem,
  ITemplateMenuItemGroup,
  ITemplateMenuItemGroupRequest,
  ITemplateMenuItemRequest,
  ITemplateModifierGroupInEmbeddedItem,
  Visibility,
  WithAssignedLocationsCount,
} from '@ready/menu.core';

export const mapToIBaseMenuSectionItem = (itemId: string): IBaseMenuSectionItem<string> => ({
  itemId: itemId,
  sectionItemType: 'item',
  sortOrder: 0,
  visibility: Visibility.visible,
});

export const mapToITemplateModifierGroupInEmbeddedItem = (
  mod: ITemplateEmbeddedModifierGroup
): ITemplateModifierGroupInEmbeddedItem => ({
  _id: mod._id,
  sortOrder: mod.sortOrder,
});

export const mapITemplateMenuItemToITemplateMenuItemUpdateRequest = (
  item: ITemplateMenuItem
): ITemplateMenuItemRequest => {
  const tagsIds = item.tags?.map((tag) => tag._id);
  const badgeId = item.badge?._id;
  const recommendedItems = item.recommendedItems.map((i) => mapToIBaseMenuSectionItem(i.itemId));
  const modifierGroups = item.modifierGroups.map((mod) => mapToITemplateModifierGroupInEmbeddedItem(mod));
  const forcedMods = item.forcedModifiers.map((fm) => mapToIBaseMenuSectionItem(fm.itemId));
  const isModifier = item.itemType === 'option';

  return {
    ...item,
    itemType: isModifier ? 'option' : 'item',
    tags: isModifier ? undefined : tagsIds,
    badge: isModifier ? undefined : badgeId,
    recommendedItems: isModifier ? [] : recommendedItems,
    modifierGroups: isModifier ? [] : modifierGroups,
    forcedModifiers: isModifier ? [] : forcedMods,
  };
};

export const mapITemplateMenuItemToITemplateMenuItemCreateRequest = (
  item: ITemplateMenuItem
): Omit<ITemplateMenuItemRequest, '_id'> => {
  const itemRequest = mapITemplateMenuItemToITemplateMenuItemUpdateRequest(item);
  const { _id, ...rest } = itemRequest;
  return rest;
};

export const mapITemplateMenuItemToITemplateEmbeddedMenuItem = (
  item: ITemplateMenuItem | WithAssignedLocationsCount<ITemplateMenuItem>
): ITemplateEmbeddedMenuItem => {
  return {
    sortOrder: 0,
    visibility: Visibility.visible,
    itemId: item._id,
    imageHeight: item.imageHeight,
    imageId: item.imageId,
    imageWidth: item.imageWidth,
    displayName: item.displayName,
    description: item.description,
    thumbnailImageHeight: item.thumbnailImageHeight,
    thumbnailImageId: item.thumbnailImageId,
    thumbnailImageWidth: item.thumbnailImageWidth,
    itemType: item.itemType,
    modifierGroups: item.modifierGroups,
    forcedModifiers: item.forcedModifiers,
    alcohol: item.alcohol,
    tags: item.tags,
    badge: item.badge,
  };
};

export const mapITemplateMenuItemToITemplateForcedModifier = (item: ITemplateMenuItem): ITemplateForcedModifier => {
  return {
    displayName: item.displayName,
    itemId: item._id,
    itemType: item.itemType,
  };
};

export const mapIMenuItemToIMenuItemRequest = (item: IMenuItem): IMenuItemRequest => {
  return {
    _id: item._id,
    parentTemplateId: item.parentTemplateId,
    posItemId: item.posItemId,
    itemType: item.itemType,
    status: item.status,
    imageId: item.imageId,
    imageWidth: item.imageWidth,
    imageHeight: item.imageHeight,
    thumbnailImageId: item.thumbnailImageId,
    thumbnailImageWidth: item.thumbnailImageWidth,
    thumbnailImageHeight: item.thumbnailImageHeight,
    displayName: item.displayName,
    description: item.description,
    alcohol: item.alcohol,
    schedule: item.schedule,
    effectiveDates: item.effectiveDates,
    modifierGroups: item.modifierGroups || [],
    recommendedItems: item.recommendedItems || [],
    forcedModifiers: item.forcedModifiers || [],
    activePriceLevel: item.activePriceLevel,
    isPopular: item.isPopular,
    tags: item.tags?.map((tag) => tag._id),
    badge: item.badge?._id,
  };
};

export const mapITemplateMenuItemGroupToITemplateMenuItemRequest = (
  itemGroup: ITemplateMenuItemGroup
): ITemplateMenuItemGroupRequest => {
  const {
    items,
    thumbnailImageHeight,
    thumbnailImageId,
    thumbnailImageWidth,
    imageHeight,
    imageId,
    imageWidth,
    description,
    displayName,
    _id,
  } = itemGroup;
  return {
    items: items.map(({ itemId, sortOrder, visibility }) => ({
      itemId,
      sortOrder,
      visibility,
    })),
    thumbnailImageHeight,
    thumbnailImageId,
    thumbnailImageWidth,
    imageHeight,
    imageId,
    imageWidth,
    description,
    displayName,
    _id,
  };
};
