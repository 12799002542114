import React, { ChangeEvent, useState } from 'react';
import { Form, FormActionButtons, FormControl, FormControlStacked } from 'components/Form';
import { Controller, useForm, useFieldArray, FormProvider } from 'react-hook-form';
import Checkbox from 'components/Checkbox/Checkbox';
import { pickUpDayLabels } from 'locations/components/LocationPanelFeatures/MobileOrdering/OrderAhead/OrderAhead';
import { orderDaysDefault } from 'companyLocations/redux/initialStates/orderHours';
import { IPickupHours, ISpecialOrderHours } from '@ready/dashboardv2api.contracts';
import { useAppSelector, useAppDispatch } from 'redux/store';
import TimePicker from 'components/TimePicker/TimePicker';
import styles from './EditPanel.module.scss';
import { orderHoursLabelNoteMessage } from '../viewPanel/ViewPanel';
import TextInputNestedLabel from 'components/TextInputNestedLabel/TextInputNestedLabel';
import { Tooltip } from 'components/Tooltip/Tooltip';
import TextIcon, { Icon } from 'components/Icon/TextIcon';
import {
  updateClientOrderHoursThunk,
  saveClientSpecialOrderHoursThunk,
} from 'companyLocations/redux/reducers/orderHours/thunk';
import { useParams } from 'react-router-dom';
import { ContextParams } from 'types/ContextParams.interface';
import { paymentProcessingSelector } from 'companyLocations/redux/selectors/PaymentProcessingSelector';
import { SpecialHours } from './components/specialHours/SpecialHours';
import InputError from 'components/InputError/InputError';
import {
  validateEndTime,
  validateStartTime,
  formatTimeValue,
  formatTimeInput,
  offsetTimeForAllDaysValue,
} from '../utils';

export interface OrderHoursFormState {
  orderHours: IPickupHours;
  specialHours: ISpecialOrderHours[];
}

export const EditPanel = ({ closeForm }: { closeForm: () => void }) => {
  const dispatch = useAppDispatch();
  const { orderHoursEdit: orderHoursInitialState, specialHoursEdit: specialHoursInitialState } = useAppSelector(
    (state) => state.location.orderHours
  );

  const { location } = useAppSelector(paymentProcessingSelector);

  const formMethods = useForm<OrderHoursFormState>({
    defaultValues: {
      orderHours: {
        ...(orderHoursInitialState === undefined || Object.keys(orderHoursInitialState).length === 0
          ? orderDaysDefault
          : orderHoursInitialState),
      },

      specialHours: specialHoursInitialState || [
        {
          date: '',
          start: '',
          end: '',
          offset: '',
          closed: false,
        },
      ],
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    reset,
  } = formMethods;

  const specialHoursFieldArray = useFieldArray<OrderHoursFormState, 'specialHours'>({
    control,
    name: 'specialHours',
  });

  const { contextId: companyId, locationId } = useParams<ContextParams>();

  const formState = watch();

  const hasCustomOffsetTime = offsetTimeForAllDaysValue(formState.orderHours) === undefined;

  // If set, it overrides the offset prop of each day in IPickupTimes.
  const [offsetTimeForAllDays, setOffsetTimeForAllDays] = useState(
    offsetTimeForAllDaysValue(formState.orderHours) ?? ''
  );

  const [showCustomOffsetOptions, setShowCustomOffsetOptions] = useState(hasCustomOffsetTime || false);
  const [isSaving, setIsSaving] = useState(false);

  const updateOffsetTimeForAllDays = (value: string) => {
    pickUpDayLabels.forEach((label: string) =>
      setValue(`orderHours.${label.toLocaleLowerCase() as keyof IPickupHours}.offset`, value)
    );
    specialHoursFieldArray.fields.forEach((hour, index) => {
      setValue(`specialHours[${index}].offset`, value);
    });
  };

  const onSubmit = async () => {
    setIsSaving(true);
    const emptyOffsetEntry = showCustomOffsetOptions === false && offsetTimeForAllDays === '';

    if (offsetTimeForAllDays !== '') {
      updateOffsetTimeForAllDays(offsetTimeForAllDays);
    } else if (emptyOffsetEntry) {
      updateOffsetTimeForAllDays('0');
    }

    await dispatch(updateClientOrderHoursThunk({ companyId, locationId, settings: formState.orderHours }));
    await dispatch(saveClientSpecialOrderHoursThunk({ companyId, locationId, hours: formState.specialHours }));
    closeForm();
    setIsSaving(false);
  };

  return (
    <FormProvider {...formMethods}>
      <div className={styles.componentContainer}>
        <Form hasGroups={true} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.daysList}>
            {pickUpDayLabels.map((label) => {
              const day = label.toLocaleLowerCase() as keyof IPickupHours;
              const checkboxName: any = `orderHours.${day}.disabled`;
              const startTimeName: any = `orderHours.${day}.start`;
              const endTimeName: any = `orderHours.${day}.end`;
              const disabled = watch(checkboxName) as boolean;

              return (
                <div className={styles.dayTimeRow} key={label}>
                  <div className={styles.dayColumn}>
                    <FormControl hideFormLabel>
                      <FormControlStacked key={label}>
                        <Controller
                          control={control}
                          name={checkboxName}
                          render={({ field }) => (
                            <Checkbox
                              checked={field.value === false}
                              boldLabel={label}
                              label=''
                              {...field}
                              onChange={(event: ChangeEvent<HTMLInputElement>) => field.onChange(!event.target.checked)}
                              loading={isSaving}
                            />
                          )}
                        />
                      </FormControlStacked>
                    </FormControl>
                  </div>
                  <div className={styles.timeSlotColumn}>
                    {disabled ? (
                      <div className={styles.isClosedContainer}>
                        <span className={styles.isClosed}>Closed</span>
                      </div>
                    ) : (
                      <div className={styles.timePicker}>
                        <div>
                          <Controller
                            control={control}
                            name={startTimeName}
                            rules={{
                              validate: () =>
                                validateStartTime(formState.orderHours[day].start, formState.orderHours[day].end),
                            }}
                            render={({ field }) => (
                              <FormControl hideFormLabel>
                                <TimePicker
                                  value={formatTimeValue(field?.value)}
                                  onChange={(value: string) => field.onChange(formatTimeInput(value))}
                                  showSeconds={false}
                                  darkBorder
                                  containerPadding='none'
                                  placeholder='12:00:00'
                                  hasError={!!errors.orderHours && !!errors.orderHours[day]?.start}
                                  disabled={isSaving}
                                />
                              </FormControl>
                            )}
                          />
                          <div className={styles.timeError}>
                            {errors.orderHours && errors.orderHours[day]?.start?.message && (
                              <InputError message={errors.orderHours[day]?.start?.message || ''} />
                            )}
                          </div>
                        </div>

                        <span className={styles.timeDivider}>-</span>
                        <div>
                          <Controller
                            control={control}
                            name={endTimeName}
                            rules={{
                              validate: () => validateEndTime(formState.orderHours[day].end),
                            }}
                            render={({ field }) => (
                              <FormControl hideFormLabel>
                                <TimePicker
                                  showSeconds={false}
                                  onChange={(value: string) => field.onChange(formatTimeInput(value))}
                                  darkBorder
                                  containerPadding='none'
                                  placeholder='12:00:00'
                                  value={formatTimeValue(field.value)}
                                  hasError={!!errors.orderHours && !!errors.orderHours[day]?.end}
                                  disabled={isSaving}
                                />
                              </FormControl>
                            )}
                          />
                          <div className={styles.timeError}>
                            {errors.orderHours && errors.orderHours[day]?.end?.message && (
                              <InputError message={errors.orderHours[day]?.end?.message || ''} />
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <SpecialHours
            fieldArray={specialHoursFieldArray}
            locationTimezone={location?.timezone}
            formErrors={errors}
            isSaving={isSaving}
          />

          <div className={styles.customizePrepTimeContainer}>
            <div className={styles.minOffsetTimeColumn}>
              <div className={styles.minOffsetTimeTitle}>
                <div>
                  <span>
                    <b>Minimum Order Prep Time </b>
                  </span>

                  <Tooltip text={orderHoursLabelNoteMessage}>
                    <TextIcon icon={Icon.InfoCircle} />
                  </Tooltip>
                </div>
              </div>
            </div>

            <div className={styles.eachDayOffsetTimeColumn}>
              <div className={styles.divider}>
                <div>
                  <TextInputNestedLabel
                    containerStyles={styles.minOffsetTimeInput}
                    label='Minutes'
                    value={offsetTimeForAllDays}
                    disabled={showCustomOffsetOptions}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setOffsetTimeForAllDays(e.target.value.toString())
                    }
                    loading={isSaving}
                  />

                  <Checkbox
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      if (e.target.checked) {
                        setOffsetTimeForAllDays('');
                      }
                      setShowCustomOffsetOptions(e.target.checked);
                    }}
                    checked={showCustomOffsetOptions}
                    label='Customize prep time per day'
                    loading={isSaving}
                  />
                </div>
                {showCustomOffsetOptions && (
                  <div className={styles.customOffsetWrapper}>
                    {pickUpDayLabels.map((label) => {
                      const day = label.toLocaleLowerCase() as keyof IPickupHours;
                      const offsetName: any = `orderHours.${day}.offset`;

                      return (
                        <div className={styles.offsetRow} key={label}>
                          <strong>{label}</strong>
                          <div className={styles.customOffsetInputWrapper}>
                            <Controller
                              control={control}
                              name={offsetName}
                              render={({ field }) => (
                                <TextInputNestedLabel
                                  containerStyles={styles.customOffsetContainer}
                                  label='Minutes'
                                  value={field.value}
                                  onChange={field.onChange}
                                  loading={isSaving}
                                />
                              )}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
          <FormActionButtons
            additionalClassName={styles.formActionButtons}
            handleCancel={() => {
              reset();
              closeForm();
            }}
            loading={isSaving}
          />
        </Form>
      </div>
    </FormProvider>
  );
};
