import React from 'react';

import { DetailElement, DetailLayout } from '../../../components/DetailLayout';
import TextField from '../../../components/TextField/TextField';

import styles from './DigitalMenuViewPanel.module.scss';
import readyProgressSpinner from '../../../styles/assets/ready-progress-spinner-gray.svg';
import { getAspectRatio } from '../../../components/ImageUploader/ImageUploader';
import { useSelector } from 'react-redux';
import { selectClientDigitalMenu } from '../../redux/selectors/LocationSelector';
import { DigitalMenuMode } from '@ready/dashboardv2api.contracts';

interface ImageUploaderProps {
  imageUrl?: string;
  loading: boolean;
}

interface TextFieldProps {
  iframeUrl?: string;
}

export const NoneComponent = () => (
  <p className={styles.noSelectedText}>
    <i>None</i>
  </p>
);

const TextFieldComponent = (props: TextFieldProps) => {
  const { iframeUrl } = props;
  return (
    <>
      <TextField value={'Link to online menu'} />
      <TextField additionalClassName={styles.truncatedIframeLink} value={iframeUrl ?? ''} />
    </>
  );
};

const ImageDisplayComponent = (props: ImageUploaderProps) => {
  const { loading, imageUrl } = props;
  const placeholderMaxWidth = '100%';
  const backgroundImage = loading ? '' : imageUrl;
  const placeholderStyles = {
    backgroundImage: `url('${backgroundImage}')`,
    maxWidth: placeholderMaxWidth,
  };

  const aspectRatioPercentage = getAspectRatio('1:3');
  const aspectRatioStyles = {
    paddingTop: `${aspectRatioPercentage}%`,
  };
  return (
    <>
      <TextField value={'Image'} />
      <div className={styles.imageUploaderPlaceholder} style={placeholderStyles}>
        {!imageUrl && !loading && <i className={`icon-dashboard-icons_camera ${styles.cameraIcon}`}></i>}
        {loading && <img src={readyProgressSpinner} alt='loading' className={styles.loadingSpinner} />}
        <div style={aspectRatioStyles}></div>
      </div>
    </>
  );
};

export const DigitalMenuViewPanel = () => {
  const {
    view: { imageUrl, iframeUrl, menuMode },
    loading,
  } = useSelector(selectClientDigitalMenu);
  const showImageUploader = menuMode === DigitalMenuMode.Image;
  const showTextInput = menuMode === DigitalMenuMode.Iframe;
  const showNone = menuMode === undefined;
  return (
    <DetailLayout>
      <DetailElement label='Menu Type'>
        {showNone && <NoneComponent />}
        {showImageUploader && <ImageDisplayComponent loading={loading} imageUrl={imageUrl} />}
        {showTextInput && <TextFieldComponent iframeUrl={iframeUrl} />}
      </DetailElement>
    </DetailLayout>
  );
};
