import { startOfMonth, subMonths } from 'date-fns';
import {
  createDateCell,
  createNullableCell,
  DollarCell,
  TextCell,
} from '../../../components/TableV2/Cell/SpecialCells';
import { getWrappedCellsWithMobileLayout } from '../../../components/TableV2/Cell/Cell';
import { createSortableHeaderCell } from '../../../components/TableV2/Cell/HeaderCell';
import styles from './Page.module.scss';
import { IChecksReportListItem } from '@ready/dashboardv2api.contracts';
import { checksHeaderColumnsSelector } from './redux/selectors';
import { CheckReadyPaymentCell, CheckStatusCell } from './components/Cells/Cells';
import MissingValue from '../../../components/Value/MissingValue';

const cells = getWrappedCellsWithMobileLayout(
  {
    posTicketNumber: TextCell,
    tableName: TextCell,
    serverName: TextCell,
    checkTotal: DollarCell,
    tip: DollarCell,
    totalPaid: DollarCell,
    due: DollarCell,
    status: CheckStatusCell,
    readyPayment: CheckReadyPaymentCell,
    checkDuration: createNullableCell(TextCell, MissingValue),
    locationName: TextCell,
    openDate: createDateCell<IChecksReportListItem>({
      dateGetter: (rowData) => rowData.openDate,
      timezoneGetter: (rowData) => rowData.timezone,
    }),
  },
  checksHeaderColumnsSelector,
  styles.cellMobileLayoutWrapper,
  styles.cellMobileLayoutWrapped
);

const headerCells = {
  posTicketNumber: createSortableHeaderCell('posTicketNumber'),
  tableName: createSortableHeaderCell('tableName'),
  serverName: createSortableHeaderCell('serverName'),
  checkTotal: createSortableHeaderCell('checkTotal', { align: 'right' }),
  tip: createSortableHeaderCell('tip', { align: 'right' }),
  totalPaid: createSortableHeaderCell('totalPaid', { align: 'right' }),
  due: createSortableHeaderCell('due', { align: 'right' }),
  status: createSortableHeaderCell('status'),
  readyPayment: createSortableHeaderCell('readyPayment'),
  checkDuration: createSortableHeaderCell('checkDuration'),
  locationName: createSortableHeaderCell('locationName'),
  openDate: createSortableHeaderCell('openDate'),
};

const endMonth = startOfMonth(new Date());
const startMonth = subMonths(endMonth, 1);
const initDateFilter = { endMonth, startMonth };

const maxDaysToChoose = 366;

export { headerCells, cells, initDateFilter, maxDaysToChoose };
