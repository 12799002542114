import { DateFilterRestrictable } from 'components/DateFilter/DateFilter';
import { DatePickerValue } from 'components/DatePicker/DatePicker';
import SearchSelectFilter, { Option } from 'components/SearchSelectFilter/SearchSelectFilter';
import React from 'react';
import { maxDaysToChoose } from 'reports/promoCodeSummary/promoCodeSummarySettings';
import { cardBrandOptions, paymentMethodOptions, paymentTypeOptions } from './selectOptions';
import styles from './ToolBar.module.scss';
import { SearchSelectCheckboxFilter } from '../../../../components/SearchSelectCheckboxFilter/SearchSelectCheckboxFilter';

interface ToolBarProps {
  locations: Array<{ name: string; _id: string }>;
  initDateFilter: DatePickerValue;
}

export const ToolBar = ({ locations, initDateFilter }: ToolBarProps) => {
  const locationOptions: Option[] = locations?.map(({ name, _id }) => ({ value: _id, label: name })) || [];

  return (
    <>
      <div className={styles.toolBar}>
        <div>
          <h4>Date Range</h4>
          <DateFilterRestrictable initialValue={initDateFilter} maxDays={maxDaysToChoose} />
        </div>
        <div>
          <h4>Location</h4>
          <SearchSelectFilter
            placeholder='Select a location'
            options={locationOptions}
            isMulti
            name='locations'
            isSearchable
          />
        </div>
      </div>
      <div className={styles.toolBar}>
        <div className={styles.filterWidthSmall}>
          <h4>Card Brand</h4>
          <SearchSelectCheckboxFilter name='brands' options={cardBrandOptions} />
        </div>
        <div className={styles.filterWidthSmall}>
          <h4>Method</h4>
          <SearchSelectCheckboxFilter name='methods' options={paymentMethodOptions} />
        </div>
        <div className={styles.filterWidthSmall}>
          <h4>Type</h4>
          <SearchSelectCheckboxFilter name='types' options={paymentTypeOptions} />
        </div>
      </div>
    </>
  );
};
