import TextIcon, { Icon } from 'components/Icon/TextIcon';
import IconButton from 'components/IconButton/IconButton';
import { CellProps, getCellClasses } from 'components/TableV2/Cell/Cell';
import { useResponsiveBreakpoint } from 'hooks';
import React from 'react';
import styles from './LocationNameCell.module.scss';

export const LocationEditCell = ({
  rowData,
  classes,
  locationNameGetter,
  onEditClick,
}: CellProps & { locationNameGetter: (rowData: any) => string; onEditClick: (rowData: any) => void }) => {
  const locationName = locationNameGetter(rowData);
  const { isMobile } = useResponsiveBreakpoint();

  return (
    <div className={getCellClasses(classes, styles.locationNameCell)}>
      <span className={styles.locationName}>{locationName}</span>
      {!isMobile && (
        <div>
          <IconButton onClick={() => onEditClick(rowData)}>
            <TextIcon icon={Icon.Pencil} additionalStyles={styles.edit} />
          </IconButton>
        </div>
      )}
    </div>
  );
};
