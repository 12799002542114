import { IPagedResponse } from '@ready/table.core';
import { ILocation } from '../../services/types/ILocation.type';
import executeApiAsync from '../../services/utils/executeApiAsync';
import config from '../../config';
import { IPayoutHistoryDetailItem, PaginationResponse } from '@ready/dashboardv2api.contracts';
import { ReportsResourceActions, ResourceType } from '@ready/security.core';
import toPaginationResponse from 'services/utils/toPaginationResponse';
const BASE_URL = `${config.readyDashboardApiEndpoint}/companies`;

export const getPermittedLocations = async (contextId: string): Promise<IPagedResponse<ILocation>> => {
  const securityParams = `permissions=${ResourceType.reports}.${ReportsResourceActions.viewPaymentDataReport}`;
  const showHiddenLocations = false;
  const url = `${BASE_URL}/${contextId}/permittedlocations?${securityParams}&isHidden=${showHiddenLocations}`;
  return await executeApiAsync<IPagedResponse<ILocation>>(url);
};
export interface IGetPayoutHistoryDetailsReportArgs {
  contextId: string;
  payoutId: string;
  query: string;
  page: number;
  sortColumn: string;
  sortDirection: string;
}

export const getPayoutHistoryDetailsReport = async ({
  contextId,
  payoutId,
  query = '',
  page = 1,
  sortColumn = 'usageCount',
  sortDirection = 'desc',
}: IGetPayoutHistoryDetailsReportArgs): Promise<PaginationResponse<IPayoutHistoryDetailItem>> => {
  const url =
    `${BASE_URL}/${contextId}/reports/payoutHistory/${payoutId}?` +
    decodeURIComponent(query) +
    `&page=${page}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&limit=50`;

  const response = await executeApiAsync<PaginationResponse<IPayoutHistoryDetailItem>>(url);
  return toPaginationResponse({ results: response.items, totalCount: response.total }, page, 50);
};
