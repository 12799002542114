import React from 'react';
import { DropDownMenuToggleProps } from './types';
import Toggle from '../Toggle/Toggle';
import styles from './DropDownMenuToggle.module.scss';
import { useClassNames } from '../../utils/cssUtils';

const DropDownMenuToggle = ({
  checked,
  onChange,
  disabled,
  loading,
  processing,
  label,
  hidden = false,
}: DropDownMenuToggleProps) => {
  const containerClassName = useClassNames([{ hidden }, styles.toggleContainer], styles);

  return (
    <div className={containerClassName}>
      <p className={styles.label}>{label}</p>
      <Toggle checked={checked} onChange={onChange} disabled={disabled} loading={loading} processing={processing} />
    </div>
  );
};

export default DropDownMenuToggle;
