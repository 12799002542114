import { IPagination } from '@ready/dashboardv2api.contracts';
import { IModifierGroup } from '@ready/menu.core';
import InfiniteScrollingListState from '../../types/InfiniteScrollingListState.interface';
import InfiniteScrollingListStateHelper from '../../utils/InfiniteScrollingList/InfiniteScrollingListStateHelper';

export interface IModifierGroupSelection extends InfiniteScrollingListState<IModifierGroup> {
  parentModifierOptionId: string;
}

export interface IModifierGroupFormValidation {
  validated: boolean;
  name: {
    hasError: boolean;
    errorMessage: string;
  };
  constraints: {
    hasError: boolean;
    errorMessage: string;
  };
  min: {
    hasError: boolean;
    errorMessage: string;
  };
  max: {
    hasError: boolean;
    errorMessage: string;
  };
}

export interface IModifierOptionsFormValidation {
  validated: boolean;
  isDefault: {
    hasError: boolean;
    errorMessage: string;
  };
  constraints: {
    options: (string | undefined)[];
    hasError: boolean;
    errorMessage: string;
  };
  noNegativeValue: {
    options: (string | undefined)[];
    hasError: boolean;
    errorMessage: string;
  };
}

export interface IModifierGroupRemoteValidation {
  hasError: boolean;
  errorMessage: string;
}

export interface IModifierGroupForm {
  loading: boolean;
  editing: boolean;
  processing: boolean;
  saved: boolean;
  cancelled: boolean;
  cache: IModifierGroup;
  modifierGroup: IModifierGroup;
  selectedMenuItemIds: string[];
  validation: IModifierGroupFormValidation;
  optionsValidation: IModifierOptionsFormValidation;
  remoteValidation: IModifierGroupRemoteValidation;
}

export interface IModifierGroupsState {
  loading: boolean;
  query?: string;
  page?: number;
  pagination: IPagination;
  items: IModifierGroup[];
  deleteRequested: boolean;
  deleteProcessing: boolean;
  deleteError: boolean;
  modifierGroupToDelete?: IModifierGroup;
  deletedModifierGroup?: IModifierGroup;
  modifierGroupForm: IModifierGroupForm;
  modifierGroupSelection: IModifierGroupSelection;
}

export const newModifierGroup = (): IModifierGroup => ({
  _id: '',
  name: '',
  displayName: '',
  guestInstructions: '',
  constraints: {},
  options: [],
});

export const newModifierGroupValidation = (): IModifierGroupFormValidation => ({
  validated: false,
  name: {
    hasError: false,
    errorMessage: '',
  },
  constraints: {
    hasError: false,
    errorMessage: '',
  },
  min: {
    hasError: false,
    errorMessage: '',
  },
  max: {
    hasError: false,
    errorMessage: '',
  },
});

export const newModifierOptionValidation = (): IModifierOptionsFormValidation => ({
  validated: false,
  isDefault: {
    hasError: false,
    errorMessage: '',
  },
  constraints: {
    options: [],
    hasError: false,
    errorMessage: '',
  },
  noNegativeValue: {
    options: [],
    hasError: false,
    errorMessage: '',
  },
});

export const newModifierGroupRemoteValidation = (): IModifierGroupRemoteValidation => ({
  hasError: false,
  errorMessage: '',
});

export const modifierGroupsState: IModifierGroupsState = {
  loading: false,
  pagination: {
    start: 0,
    length: 0,
    limit: 0,
    total: 0,
  },
  items: [],
  deleteRequested: false,
  deleteProcessing: false,
  deleteError: false,
  modifierGroupForm: {
    loading: false,
    editing: false,
    processing: false,
    saved: false,
    cancelled: false,
    cache: newModifierGroup(),
    modifierGroup: newModifierGroup(),
    selectedMenuItemIds: [],
    validation: newModifierGroupValidation(),
    optionsValidation: newModifierOptionValidation(),
    remoteValidation: newModifierGroupRemoteValidation(),
  },
  modifierGroupSelection: {
    ...InfiniteScrollingListStateHelper.initialState(),
    parentModifierOptionId: '',
  },
};
