import { ITableAssignment } from '@ready/table.core';
import { useSelector } from 'react-redux';
import { AppState } from '../../redux/initialStates/AppState';

/**
 * Returns the table assignment for a specific server from the tableAssignments
 * Redux store
 */
const useServerTables = (employeeId: string): ITableAssignment | null => {
  const tableAssignments = useSelector(
    (state: AppState) => state.tableServerAssignment.serverAssignments.tableAssignments
  );

  const filteredTableAssignments = tableAssignments.filter(
    (tableAssignment) => tableAssignment.employee.id === employeeId
  );

  // As of now, it should always return either zero or one server. If not, there
  // is some logical bug somewhere else.
  return filteredTableAssignments.length === 1 ? filteredTableAssignments[0] : null;
};

export default useServerTables;
