import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { useHistory, useParams } from 'react-router-dom';
import { IOnAccountPaymentOptionsState } from '../../redux/initialStates/onAccountPaymentOptions';
import { OnAccountPaymentOptionsActions } from '../../redux/actions/onAccountPaymentOptionsActions';
import { selectOnAccountPaymentOptions } from '../../redux/selectors/onAccountPaymentOptionsSelector';
import ActionHeader, { Action } from '../../../components/ActionHeader/ActionHeader';
import LayoutContent from '../../../components/AppLayout/LayoutContent';
import { DeleteConfirmationModal } from '../../../components/Modal';
import { OnAccountPaymentOptionDetailsContent } from './OnAccountPaymentOptionDetailsContent';

export const OnAccountPaymentOptionDetails = () => {
  const { contextId: companyId, locationId, paymentOptionId } = useParams<{
    contextId: string;
    locationId: string;
    paymentOptionId: string;
  }>();

  const dispatch = useAppDispatch();
  const {
    view: {
      deleteRequested,
      isDeleting,
      isDeleted,
      isLoading,
      isEditMode,
      config: { name: paymentOptionName },
      tenderTypesOptions: { isLoadingTenderTypes },
    },
  } = useAppSelector<IOnAccountPaymentOptionsState>(selectOnAccountPaymentOptions);

  const history = useHistory();

  React.useEffect(() => {
    dispatch(OnAccountPaymentOptionsActions.getById(companyId, locationId, paymentOptionId));
    return () => {
      dispatch(OnAccountPaymentOptionsActions.resetPaymentOptionViewState());
    };
  }, [companyId, locationId, paymentOptionId, dispatch]);

  const actionButtons: Action[] = [
    {
      label: 'Delete',
      variant: 'secondary-gray-bg',
      onClick: () => dispatch(OnAccountPaymentOptionsActions.initiateDelete()),
      unavailable: isEditMode,
    },
    {
      label: 'Edit',
      variant: 'primary',
      onClick: () => dispatch(OnAccountPaymentOptionsActions.togglePaymentOptionEditMode(true)),
      unavailable: isEditMode,
    },
  ];
  const backLinkTo = `/companies/${companyId}/companyLocations/${locationId}/paymentProcessing`;
  const loading = isLoading || isLoadingTenderTypes;

  React.useEffect(() => {
    if (isDeleted) {
      history.push(backLinkTo);
    }
  }, [isDeleted, history, backLinkTo]);

  return (
    <>
      {deleteRequested && (
        <DeleteConfirmationModal
          item='Payment Option'
          itemName={paymentOptionName}
          loading={isDeleting}
          handleDelete={() => {
            dispatch(OnAccountPaymentOptionsActions.delete(companyId, locationId, paymentOptionId));
          }}
          setShowModal={() => {
            dispatch(OnAccountPaymentOptionsActions.cancelDelete());
          }}
        />
      )}

      <LayoutContent title='Location Settings' containerType='within' loadingPage={loading}>
        <ActionHeader text={paymentOptionName} backLinkTo={backLinkTo} actionButtons={actionButtons} />
        <OnAccountPaymentOptionDetailsContent />
      </LayoutContent>
    </>
  );
};
