import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { CellProps, getCellClasses } from '../../../../components/TableV2/Cell/Cell';
import { ContextParams } from '../../../../types/ContextParams.interface';
import { Stars } from '../Stars/Stars';
import styles from './Cells.module.scss';
import { IFeedbackReportItem } from '@ready/dashboardv2api.contracts';
import { DollarValue } from '../../../../components/Value';

export const AmountPaidCell = ({
  classes,
  rowData,
}: CellProps<IFeedbackReportItem>) => {
  const { contextId } = useParams<ContextParams>();

  const { pathname, search } = useLocation();
  const backUrl = encodeURIComponent(pathname + search);

  return (
    <span className={getCellClasses(classes, styles.cell__amountPaid)}>
      <span>
        <span className={styles.cell__amountPaidSum}>
          <DollarValue value={rowData.amountPaid} />
        </span>{' '}
        <span>({rowData.tipPostTaxPercentage} %)</span>
      </span>
      {
        rowData.invoiceId && <Link
          className={styles.cell__amountPaidLink}
          to={`/companies/${contextId}/locations/${rowData.locationId}/transactions/${rowData.invoiceId}`
            + `?backUrl=${backUrl}`}
        >
          (View)
        </Link>
      }
    </span>
  );
};

export const CellRating = ({
  rowData,
  emptyCellMessage = 'No Service Factors Selected',
  classes,
}: CellProps<IFeedbackReportItem> & { emptyCellMessage?: string; }) => {
  const serviceFactors = rowData.selectedServiceFactors ?? [];

  return (
    <span className={getCellClasses(classes)}>
      <>
        <Stars classes={styles.cell__ratingRatingItem} starsCount={rowData.rating} />
        <span className={styles.cell__rating}>
          {serviceFactors.length > 0 ? (
            serviceFactors.map((item, i) => (
              <span key={i} className={styles.cell__ratingFactorItem}>
                {item}
              </span>
            ))
          ) : (
            <span className={styles.noServiceFactors}>{emptyCellMessage}</span>
          )}
        </span>
      </>
    </span>
  );
};
