import config from '../../config';
import executeApiAsync from '../../services/utils/executeApiAsync';
import { Option } from '../../components/SelectFilter/SelectFilter';
import { IEmployee } from '@ready/employee.core';
import { IPagedResponse } from '@ready/table.core';
import { convertEmployeeToOption, convertPosItemToOption, PosItem } from 'utils/mappers/toOption';

export default class LocationPOSService {
  private static readonly BASE_URL = `${config.readyApiLegacyEndpoint}/admins/locations/`;

  public static getPOSLocations = async (
    locationId: string,
    serviceType?: string
  ): Promise<[{ id: string; name: string }]> => {
    const serviceTypeQuery = serviceType ? `?serviceType=${serviceType}` : '';
    return executeApiAsync<[{ id: string; name: string }]>(
      LocationPOSService.BASE_URL + `${locationId}/posLocations${serviceTypeQuery}`
    );
  };

  public static getTenderTypes = async (locationId: string): Promise<Option[]> => {
    const tenderTypes = await executeApiAsync<PosItem[]>(
      `${config.readyApiLegacyEndpoint}/admins/locations/${locationId}/tenderTypes`
    );

    return tenderTypes.map(convertPosItemToOption);
  };

  static getEmployees = async (companyId: string, locationId: string, filter: string): Promise<Option[]> => {
    const response = await executeApiAsync<IPagedResponse<IEmployee>>(
      `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/employees?q=${filter}&page=1&size=50`
    );

    return response.results.map(convertEmployeeToOption);
  };
}
