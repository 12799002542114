import { IPickupTimes, IPickupHours, ISpecialOrderHours } from '@ready/dashboardv2api.contracts';

export type OrderHoursState = {
  orderHoursEdit?: IPickupHours;
  orderHoursCache?: IPickupHours;
  specialHoursEdit: ISpecialOrderHours[];
  specialHoursCache: ISpecialOrderHours[];
  loading: boolean;
};

const orderTimeDefault: IPickupTimes = {
  offset: '0',
  start: '00:00',
  end: '23:59',
  disabled: false,
};

export const orderDaysDefault: IPickupHours = {
  monday: orderTimeDefault,
  tuesday: orderTimeDefault,
  wednesday: orderTimeDefault,
  thursday: orderTimeDefault,
  friday: orderTimeDefault,
  saturday: orderTimeDefault,
  sunday: orderTimeDefault,
};

export const orderHoursInitialState: OrderHoursState = {
  loading: false,
  specialHoursEdit: [],
  specialHoursCache: [],
};
