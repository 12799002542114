import React from 'react';
import Modal, { ModalProps } from './Modal';
import ModalHeader from './ModalHeader';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';

export interface DeleteConfirmationModalProps extends Pick<ModalProps, 'setShowModal'> {
  item: string;
  itemName: string;
  loading?: boolean;
  handleDelete: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleCancel?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  modalBodyComponent?: React.ReactNode;
}

const DeleteConfirmationModal = (props: DeleteConfirmationModalProps) => {
  const {
    setShowModal,
    item,
    itemName,
    loading = false,
    handleDelete,
    handleCancel = () => setShowModal(false),
    modalBodyComponent,
  } = props;

  const headerLabel = `Delete this ${item}?`;
  const primaryButtonLabel = `Delete ${item}`;

  return (
    <Modal setShowModal={setShowModal} loading={loading}>
      <ModalHeader headerLabel={headerLabel} setShowModal={setShowModal} />
      <ModalBody>
        {modalBodyComponent ? (
          modalBodyComponent
        ) : (
          <p>
            Are you sure you want to delete the {item.toLowerCase()} "<strong>{itemName}</strong>"? This action cannot
            be undone.
          </p>
        )}
      </ModalBody>
      <ModalFooter
        primaryLabel={primaryButtonLabel}
        primaryActionHandler={handleDelete}
        secondaryLabel='Cancel'
        secondaryActionHandler={handleCancel}
        loading={loading}
      />
    </Modal>
  );
};

export default DeleteConfirmationModal;
