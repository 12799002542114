export const SECURITY_ROLE_FORM_TYPES = {
  INIT_SECURITY_ROLE_FORM: 'INIT_SECURITY_ROLE_FORM',
  VALIDATE_SECURITY_ROLE_FORM: 'VALIDATE_SECURITY_ROLE_FORM',
  SET_SECURITY_ROLE_NAME: 'SET_SECURITY_ROLE_NAME',
  SET_SECURITY_ROLE_DESCRIPTION: 'SET_SECURITY_ROLE_DESCRIPTION',
  SET_SECURITY_ROLE_PERMISSION: 'SET_SECURITY_ROLE_PERMISSION',
  SET_SECURITY_ROLE_FORM_LOADING: 'SET_SECURITY_ROLE_FORM_LOADING',
  SET_SECURITY_ROLE_FORM_PROCESSING: 'SET_SECURITY_ROLE_FORM_PROCESSING',
  SET_SECURITY_ROLE_FORM_DATA: 'SET_SECURITY_ROLE_FORM_DATA',
  DELETE_SECURITY_ROLE_BEGIN: 'DELETE_SECURITY_ROLE_BEGIN',
  DELETE_SECURITY_ROLE_SUCCESS: 'DELETE_SECURITY_ROLE_SUCCESS',
  DELETE_SECURITY_ROLE_ERROR: 'DELETE_SECURITY_ROLE_ERROR',
  DELETE_SECURITY_ROLE_REQUESTED: 'DELETE_SECURITY_ROLE_REQUESTED',
};

export const SECURITY_ROLES_METADATA = {
  LOAD_PERMISSIONS_SET_BEGIN: 'LOAD_PERMISSIONS_SET_BEGIN',
  LOAD_PERMISSIONS_SET_SUCCESS: 'LOAD_PERMISSIONS_SET_SUCCESS',
  LOAD_PERMISSIONS_SET_ERROR: 'LOAD_PERMISSIONS_SET_ERROR',
};
