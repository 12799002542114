import React from 'react';
import classNames from 'classnames';
import styles from './ContactInfo.module.scss';
import { IOrderIdentityInfo, IOrderTableInfo } from '@ready/dashboardv2api.contracts';
import { PickupTime } from '.';
import RoundIcon from 'components/RoundIcon/RoundIcon';
import { getOrderContactInformation, getOrderExperienceType } from 'utils/orderUtils';

interface ContactInfoProps {
  orderIdentityInfo: IOrderIdentityInfo | undefined;
  tableInfo?: IOrderTableInfo;
  additionalStyles?: string;
}

const ContactInfo = (props: ContactInfoProps) => {
  const { orderIdentityInfo, additionalStyles, tableInfo } = props;

  const orderExperienceType = getOrderExperienceType(tableInfo?.orderExperienceType);
  const { primaryInfo, secondaryInfo } = getOrderContactInformation(orderIdentityInfo, tableInfo);

  return (
    <div className={classNames(styles.container, additionalStyles)}>
      <div className={styles.iconPickupTimeContainer}>
        <RoundIcon icon={orderExperienceType.icon} />
        <div className={styles.pickupTimeContainer}>
          <span className={styles.pickupTimeLabel}>{orderExperienceType.label}</span>
          <PickupTime />
        </div>
      </div>
      {orderIdentityInfo && (
        <>
          <div className={styles.primaryContactContainer}>
            {primaryInfo.split(' ').map((primaryContact, index) => (
              <span key={`primaryContactPart_${index}`} className={styles.primaryContactPart}>
                {primaryContact}
              </span>
            ))}
          </div>

          {secondaryInfo.map((secondaryField, index) => (
            <span className={styles.secondaryContact} key={index}>
              {secondaryField}
            </span>
          ))}
        </>
      )}
    </div>
  );
};

export default ContactInfo;
