import React, { useMemo, useState } from 'react';
import range from 'lodash/range';
import chunk from 'lodash/chunk';
import {
  format,
  parse,
  minTime,
  maxTime,
  getDay,
  startOfMonth,
  startOfDay,
  endOfDay,
  endOfMonth,
  addDays,
  subDays,
  differenceInDays,
  getDate,
  setDate,
  isBefore,
  isAfter,
  isSameDay,
  isWithinInterval,
} from 'date-fns';
import { isEqualOrWithinInterval } from '../../utils/dateUtils';
import styles from './CalendarDays.module.scss';
import { compositeStyles } from '../../utils/cssUtils';

export interface CalendarValue {
  startMonth: Date;
  startDate?: Date;
  endDate?: Date;
}

export interface CalendarProps {
  value: CalendarValue;
  minValue?: Date;
  maxValue?: Date;
  onChange: CalendarOnChangeCallback;
  updateValue?: 'startDate' | 'endDate';
  isRange?: boolean;
}

export interface CalendarOnChangeCallback {
  (value: CalendarValue): void;
}

interface CalendarDay {
  date: Date;
  displayValue: number;
  relativeMonth: 'previous' | 'current' | 'next';
}

const HEADER_DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const getDateFromMonthString = (monthString: string) => parse(monthString, stateMonthFormat, new Date());

const generateCalendarGrid = (month: string) => {
  const monthDate = getDateFromMonthString(month);

  const firstDayOfMonth = startOfMonth(monthDate);
  const lastDayOfMonth = endOfMonth(monthDate);

  // Get first and last days of the month
  const firstMonthDay = getDay(firstDayOfMonth);
  const lastMonthDay = getDay(lastDayOfMonth);

  // Get first and last days of adjacent months' dates to fill the grid
  const firstGridDate = subDays(firstDayOfMonth, firstMonthDay);
  let lastGridDate = addDays(subDays(lastDayOfMonth, lastMonthDay), 6);
  let daysInMonth = differenceInDays(lastGridDate, firstGridDate) + 1;

  // Count the difference between days in range. It should be 41 to fill a 6x7 grid.
  // Otherwise add a week to fill the end of the grid to display 6 weeks.
  if (daysInMonth < 42) {
    daysInMonth += 7;
  }

  const firstGridDateNumber = getDate(firstGridDate);

  // Generate the date range
  const dateRange = range(firstGridDateNumber, firstGridDateNumber + daysInMonth).map((dateNumber) => {
    const date = startOfDay(setDate(firstGridDate, dateNumber));

    let relativeMonth = 'current';

    if (isBefore(date, firstDayOfMonth)) {
      relativeMonth = 'previous';
    } else if (isAfter(endOfDay(date), lastDayOfMonth)) {
      relativeMonth = 'next';
    }

    return {
      date: date,
      displayValue: getDate(date),
      relativeMonth,
    } as CalendarDay;
  });

  // Break the array of days into one week chunks
  return chunk(dateRange, 7);
};

const stateMonthFormat = 'yyyy-MM';

const CalendarDays = (props: CalendarProps) => {
  // Default false
  const isRange = props.isRange ?? false;

  const minValue = props.minValue ?? new Date(minTime);
  const maxValue = props.maxValue ?? new Date(maxTime);

  const startDate = props.value.startDate;
  const endDate = props.value.endDate;

  const startMonth = format(props.value.startMonth ?? startOfMonth(new Date()), stateMonthFormat);

  /* ****** State ****** */

  const [hoverDate, setHoverDate] = useState<Date>();

  /* ****** Rendering ****** */

  const calendarGrid = useMemo(() => generateCalendarGrid(startMonth), [startMonth]);

  const dayButtonEnabled = (day: CalendarDay) =>
    day.relativeMonth === 'current' && isEqualOrWithinInterval(day.date, { start: minValue, end: maxValue });

  const weekDayClassNames = (day: CalendarDay): string =>
    compositeStyles(
      [
        { weekDay: true },
        { currentMonth: day.relativeMonth === 'current' },
        {
          singleSelectedDay:
            !isRange && ((startDate && isSameDay(day.date, startDate)) || (endDate && isSameDay(day.date, endDate))),
        },
        { firstSelectedDay: isRange && startDate && isSameDay(day.date, startDate) },
        { lastSelectedDay: isRange && endDate && isSameDay(day.date, endDate) },
        {
          betweenSelectedDays:
            isRange &&
            startDate &&
            endDate &&
            startDate < endDate &&
            isWithinInterval(day.date, {
              start: startDate,
              end: endDate,
            }) &&
            day.relativeMonth === 'current',
        },
        { clickableDay: dayButtonEnabled(day) },
        {
          rangeHover:
            isRange &&
            hoverDate &&
            !(startDate && endDate) &&
            ((startDate &&
              isAfter(hoverDate, startDate) &&
              isWithinInterval(day.date, {
                start: startDate,
                end: hoverDate,
              })) ||
              (endDate &&
                isBefore(hoverDate, endDate) &&
                isWithinInterval(day.date, {
                  start: hoverDate,
                  end: endDate,
                })) ||
              (startDate &&
                isBefore(hoverDate, startDate) &&
                isWithinInterval(day.date, {
                  start: hoverDate,
                  end: startDate,
                }))) &&
            day.relativeMonth === 'current',
        },
      ],
      styles
    );

  const onValueChange = (date: Date) => {
    props.onChange({
      startMonth: getDateFromMonthString(startMonth),
      startDate: !isRange || (isRange && props.updateValue === 'startDate') ? date : startDate,
      endDate: !isRange || (isRange && props.updateValue === 'endDate') ? date : endDate,
    });
  };

  const setHover = (date: Date, isHovering: boolean) => (isHovering ? setHoverDate(date) : setHoverDate(undefined));

  return (
    <div className={styles.container}>
      <div className={styles.week}>
        {HEADER_DAYS.map((headerDay) => (
          <div className={styles.weekHeader} key={`calendar-days__week-header_${headerDay}`}>
            {headerDay}
          </div>
        ))}
      </div>
      {calendarGrid.map((week, index) => (
        <div className={styles.week} key={`calendar-days__week_${index}`}>
          {week.map((day) => {
            const enabled = dayButtonEnabled(day);
            return (
              <button
                type='button'
                onClick={() => onValueChange(day.date)}
                className={weekDayClassNames(day)}
                disabled={!enabled}
                key={`calendar-days__week-day_${format(day.date, 'yyyy-MM-dd')}`}
                onMouseOver={() => enabled && setHover(day.date, true)}
                onMouseOut={() => enabled && setHover(day.date, false)}
              >
                {day.displayValue}
              </button>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default CalendarDays;
