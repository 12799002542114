import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TableHeader } from '../../../components/TableV2/TableHeader/TableHeader';
import { TableV2 } from '../../../components/TableV2/TableV2';
import { Body, BodyRowsClickable, BodySkeleton } from '../../../components/TableV2/Body/Body';
import LayoutContent from '../../../components/AppLayout/LayoutContent';
import HeaderControls, { ControlOption } from '../../../components/AppLayout/HeaderControls';
import { ToolBar } from './components/ToolBar/ToolBar';
import { fetchChecksListThunk, fetchPermittedLocationsThunk } from './redux';
import { useSearchParams } from '../../../hooks';
import { cells, headerCells, initDateFilter } from './reportSettings';
import { checksReportSelector } from './redux/selectors';
import { exportChecksListThunk, fetchCheckWidgetsThunk } from './redux/thunks';
import { checkFeatureToggle } from '../../../utils/featureToggle/featureToggle';
import styles from './Page.module.scss';
import { Widgets } from './components/Widgets/Widgets';
import { PaginationContainer } from 'components/PaginationContainer/PaginationContainer';

export const ChecksReportPage = () => {
  const { query, page, sortColumn, sortDirection } = useSearchParams();
  const { loading, pagination, contextId, permittedLocations, items, columns, headerColumns, rowKey, widgets, errors } =
    useSelector(checksReportSelector);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPermittedLocationsThunk(contextId));
  }, [contextId, dispatch]);

  useEffect(() => {
    dispatch(fetchChecksListThunk({ contextId, query, page, sortColumn, sortDirection }));
  }, [contextId, query, page, sortColumn, sortDirection, dispatch]);

  useEffect(() => {
    if (checkFeatureToggle('app_checksReportMetricsCards')) {
      dispatch(fetchCheckWidgetsThunk({ query, contextId }));
    }
  }, [query, contextId, dispatch]);

  const headerControlOptions: Array<ControlOption> = [
    {
      buttonLabel: 'Export',
      loading: loading.export,
      onClick: async () => dispatch(exportChecksListThunk({ contextId, query, sortColumn, sortDirection })),
    },
  ];

  return (
    <LayoutContent
      title='Checks'
      headerControls={<HeaderControls controlOptions={headerControlOptions} />}
      toolbarContent={<ToolBar initDateFilter={initDateFilter} locations={permittedLocations.results} />}
      containerType='within'
      scrollToolbarForMobile
    >
      {checkFeatureToggle('app_checksReportMetricsCards') ? (
        <Widgets widgets={widgets} disabled={Boolean(errors.widgets)} loading={loading.widgets} />
      ) : null}
      <div className={styles.checksListing}>
        <PaginationContainer pagination={pagination}>
          <TableV2 loading={loading.list}>
            <>
              <TableHeader cells={headerCells} data={headerColumns} columns={columns} />
              {loading.list ? (
                <BodySkeleton columns={columns} rowsNumber={50} />
              ) : checkFeatureToggle('app_checkPage') ? (
                <BodyRowsClickable
                  onClick={(rowData) => history.push(`locations/${rowData.locationId}/checks/${rowData._id}`)}
                  cells={cells}
                  hiddenColumns={{}}
                  rowKey={rowKey}
                  data={items}
                  columns={columns}
                />
              ) : (
                <Body cells={cells} hiddenColumns={{}} rowKey={rowKey} data={items} columns={columns} />
              )}
            </>
          </TableV2>
        </PaginationContainer>
      </div>
    </LayoutContent>
  );
};
