import { ITransaction } from '@ready/dashboardv2api.contracts';

export interface ITransactionState {
  loading: boolean;
  error: boolean;
  errorMessage: string;
  transaction: ITransaction;
  hasRefundTransactionAccess: boolean;
  refunding: boolean;
  refundAmount: number;
  refundValidationError: string;
  refundCompleted: boolean;
  refundError: string;
  processingRetry: boolean;
}

export const transactionState = {
  loading: false,
  error: false,
  errorMessage: '',
  hasRefundTransactionAccess: false,
  refunding: false,
  refundAmount: 0,
  refundValidationError: '',
  refundCompleted: false,
  refundError: '',
  processingRetry: false,
} as ITransactionState;
