import produce from 'immer';
import { Action } from '../../redux/types';
import { LOCATION_SETTINGS_ACTIONS } from './LocationSettingsActions';
import { paymentProcessingPanel } from './PanelPaymentProcessingState';

export const paymentProcessingPanelReducer = (state = paymentProcessingPanel, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case LOCATION_SETTINGS_ACTIONS.SET_PAYMENT_PROCESSING_PANEL:
        draft = { ...draft, ...action.payload };
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_PAYMENT_QUEUES:
        draft.paymentQueues = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_PAYMENT_PROVIDER:
        draft.providerName = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_STRIPE_ACCOUNT_ID:
        draft.stripe.accountId = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_STRIPE_PERCENTAGE_FEE:
        draft.stripe.feePercent = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_STRIPE_PER_TRANSACTION_FEE:
        draft.stripe.feePerTxn = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_STRIPE_PAYMENT_METHOD_CREDIT_CARD:
        draft.stripe.paymentMethod.creditCard = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_STRIPE_PAYMENT_METHOD_APPLE_PAY:
        draft.stripe.paymentMethod.applePay = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_STRIPE_PAYMENT_METHOD_GOOGLE_PAY:
        draft.stripe.paymentMethod.googlePay = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_MERCHANT_ID:
        draft.realex.merchantId = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_ACCOUNT_ID:
        draft.realex.accountId = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_SHARED_SECRET:
        draft.realex.sharedSecret = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_REBATE_PASSWORD:
        draft.realex.rebatePassword = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_REFUND_PASSWORD:
        draft.realex.refundPassword = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_PAYMENT_METHOD_CREDIT_CARD:
        draft.realex.paymentMethod.creditCard = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_PAYMENT_METHOD_APPLE_PAY:
        draft.realex.paymentMethod.applePay = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_PAYMENT_METHOD_GOOGLE_PAY:
        draft.realex.paymentMethod.googlePay = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_PAYMENT_METHOD_CREDIT_CARD_SUPPRESS_3DS:
        draft.realex.suppressCreditCard3DSAddress = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_APPLE_SETTINGS_MERCHANT_ID:
        draft.realex.applePay.merchantId = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_APPLE_SETTINGS_SUPPORTS_3DS:
        draft.realex.applePay.supports3DS = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_APPLE_SETTINGS_SUPPORTS_DEBIT:
        draft.realex.applePay.supportsDebit = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_APPLE_SETTINGS_SUPPORTS_CREDIT:
        draft.realex.applePay.supportsCredit = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_GOOGLE_SETTINGS_GATEWAY_MERCHANT_ID:
        draft.realex.googlePay.gatewayMerchantId = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_GOOGLE_SETTINGS_GATEWAY_ID:
        draft.realex.googlePay.gatewayId = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_GOOGLE_SETTINGS_MERCHANT_ID:
        draft.realex.googlePay.merchantId = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_GOOGLE_SETTINGS_MERCHANT_NAME:
        draft.realex.googlePay.merchantName = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_GOOGLE_SETTINGS_ENVIRONMENT:
        draft.realex.googlePay.environment = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_GOOGLE_SETTINGS_SUPPORTS_3DS:
        draft.realex.googlePay.supports3DS = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_GOOGLE_SETTINGS_SUPPORTS_CREDIT:
        draft.realex.googlePay.supportsCredit = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_APPLE_GOOGLE_NETWORK_MASTERCARD:
        draft.realex.appleGoogleNetwork.mastercard = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_APPLE_GOOGLE_NETWORK_VISA:
        draft.realex.appleGoogleNetwork.visa = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_APPLE_GOOGLE_NETWORK_AMEX:
        draft.realex.appleGoogleNetwork.amex = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_APPLE_GOOGLE_NETWORK_DISCOVER:
        draft.realex.appleGoogleNetwork.discover = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_APPLE_GOOGLE_NETWORK_JCB:
        draft.realex.appleGoogleNetwork.jcb = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_RATE_LIMITING_TOGGLE:
        draft.realex.rateLimits.enabled = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_RATE_LIMITING_AMOUNT:
        draft.realex.rateLimits.amount = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_RATE_LIMITING_TIME_INTERVAL:
        draft.realex.rateLimits.duration = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_RATE_LIMITING_FOR_GOOGLE_PAY:
        draft.realex.rateLimits.enforceRateLimitsForGooglePay = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_REALEX_SMALL_TRANSACTIONS:
        draft.realex.allowSmallTransactions = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_API_TOKEN:
        draft.moneris.apiToken = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_STORE_ID:
        draft.moneris.storeId = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_HPP_TOKEN:
        draft.moneris.hppToken = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_APPLE_PAY_MERCHANT_ID:
        draft.moneris.applePayMerchantId = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_GOOGLE_PAY_MERCHANT_ID:
        draft.moneris.googlePayMerchantId = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_PAYMENT_METHOD_CREDIT_CARD:
        draft.moneris.paymentMethod.creditCard = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_PAYMENT_METHOD_APPLE_PAY:
        draft.moneris.paymentMethod.applePay = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_PAYMENT_METHOD_GOOGLE_PAY:
        draft.moneris.paymentMethod.googlePay = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_APPLE_GOOGLE_NETWORK_MASTERCARD:
        draft.moneris.appleGoogleNetwork.mastercard = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_APPLE_GOOGLE_NETWORK_VISA:
        draft.moneris.appleGoogleNetwork.visa = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_APPLE_GOOGLE_NETWORK_AMEX:
        draft.moneris.appleGoogleNetwork.amex = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_APPLE_GOOGLE_NETWORK_DISCOVER:
        draft.moneris.appleGoogleNetwork.discover = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_APPLE_GOOGLE_NETWORK_INTERAC:
        draft.moneris.appleGoogleNetwork.interac = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_APPLE_GOOGLE_NETWORK_JCB:
        draft.moneris.appleGoogleNetwork.jcb = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_RATE_LIMITING_TOGGLE:
        draft.moneris.rateLimits.enabled = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_RATE_LIMITING_AMOUNT:
        draft.moneris.rateLimits.amount = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_RATE_LIMITING_TIME_INTERVAL:
        draft.moneris.rateLimits.duration = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_RATE_LIMITING_FOR_GOOGLE_PAY:
        draft.moneris.rateLimits.enforceRateLimitsForGooglePay = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_MONERIS_SMALL_TRANSACTIONS:
        draft.moneris.allowSmallTransactions = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_PROFILE_ID:
        draft.iqpay.profileId = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_PAYMENT_METHOD_CREDIT_CARD:
        draft.iqpay.paymentMethod.creditCard = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_PAYMENT_METHOD_APPLE_PAY:
        draft.iqpay.paymentMethod.applePay = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_PAYMENT_METHOD_GOOGLE_PAY:
        draft.iqpay.paymentMethod.googlePay = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_APPLE_GOOGLE_NETWORK_MASTERCARD:
        draft.iqpay.appleGoogleNetwork.mastercard = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_APPLE_GOOGLE_NETWORK_VISA:
        draft.iqpay.appleGoogleNetwork.visa = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_APPLE_GOOGLE_NETWORK_AMEX:
        draft.iqpay.appleGoogleNetwork.amex = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_APPLE_GOOGLE_NETWORK_DISCOVER:
        draft.iqpay.appleGoogleNetwork.discover = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_APPLE_GOOGLE_NETWORK_JCB:
        draft.iqpay.appleGoogleNetwork.jcb = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_RATE_LIMITS_TOGGLE:
        draft.iqpay.rateLimits.enabled = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_RATE_LIMITS_AMOUNT:
        draft.iqpay.rateLimits.amount = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_RATE_LIMITS_DURATION:
        draft.iqpay.rateLimits.duration = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_RATE_LIMITS_FOR_GOOGLE_PAY:
        draft.iqpay.rateLimits.enforceRateLimitsForGooglePay = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_SMALL_TRANSACTIONS:
        draft.iqpay.allowSmallTransactions = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_APPLE_PAY_SETTINGS_MERCHANT_ID:
        draft.iqpay.applePay.merchantId = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_APPLE_PAY_SETTINGS_SUPPORT_3DS:
        draft.iqpay.applePay.supports3DS = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_APPLE_PAY_SETTINGS_SUPPORT_CREDIT:
        draft.iqpay.applePay.supportsCredit = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_APPLE_PAY_SETTINGS_SUPPORT_DEBIT:
        draft.iqpay.applePay.supportsDebit = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_GOOGLE_PAY_SETTINGS_GATEWAY_MERCHANT_ID:
        draft.iqpay.googlePay.gatewayMerchantId = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_GOOGLE_PAY_SETTINGS_GATEWAY_ID:
        draft.iqpay.googlePay.gatewayId = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_GOOGLE_PAY_SETTINGS_MERCHANT_ID:
        draft.iqpay.googlePay.merchantId = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_GOOGLE_PAY_SETTINGS_MERCHANT_NAME:
        draft.iqpay.googlePay.merchantName = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_GOOGLE_PAY_SETTINGS_ENVIRONMENT:
        draft.iqpay.googlePay.environment = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_GOOGLE_PAY_SETTINGS_SUPPORT_3DS:
        draft.iqpay.googlePay.supports3DS = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_GOOGLE_PAY_SETTINGS_SUPPORT_CREDIT:
        draft.iqpay.googlePay.supportsCredit = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_IQPAY_GOOGLE_PAY_SETTINGS_SUPPORT_DEBIT:
        draft.iqpay.googlePay.supportsDebit = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_FREEDOMPAY_STORE_ID:
        draft.freedompay.storeId = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_FREEDOMPAY_TERMINAL_ID:
        draft.freedompay.terminalId = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_FREEDOMPAY_ESKEY:
        draft.freedompay.esKey = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_FREEDOMPAY_PAYMENT_METHOD_CREDIT_CARD:
        draft.freedompay.paymentMethod.creditCard = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_FREEDOMPAY_PAYMENT_METHOD_APPLE_PAY:
        draft.freedompay.paymentMethod.applePay = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_FREEDOMPAY_PAYMENT_METHOD_GOOGLE_PAY:
        draft.freedompay.paymentMethod.googlePay = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_FREEDOMPAY_RATE_LIMITING_TOGGLE:
        draft.freedompay.rateLimits.enabled = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_FREEDOMPAY_RATE_LIMITING_AMOUNT:
        draft.freedompay.rateLimits.amount = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_FREEDOMPAY_RATE_LIMITING_TIME_INTERVAL:
        draft.freedompay.rateLimits.duration = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_FREEDOMPAY_RATE_LIMITING_FOR_GOOGLE_PAY:
        draft.freedompay.rateLimits.enforceRateLimitsForGooglePay = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_FREEDOMPAY_SMALL_TRANSACTIONS:
        draft.freedompay.allowSmallTransactions = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_STORE_NUMBER:
        draft.jetpay.storeNumber = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_COMPANY_PAYMENT_CONFIG_ID:
        draft.jetpay.companyPaymentConfigId = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_PAYMENT_METHOD_CREDIT_CARD:
        draft.jetpay.paymentMethod.creditCard = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_PAYMENT_METHOD_APPLE_PAY:
        draft.jetpay.paymentMethod.applePay = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_APPLE_PAY_SETTINGS_MERCHANT_ID:
        draft.jetpay.applePay.merchantId = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_APPLE_PAY_SETTINGS_SUPPORT_3DS:
        draft.jetpay.applePay.supports3DS = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_APPLE_PAY_SETTINGS_SUPPORT_DEBIT:
        draft.jetpay.applePay.supportsDebit = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_APPLE_PAY_SETTINGS_SUPPORT_CREDIT:
        draft.jetpay.applePay.supportsCredit = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_APPLE_GOOGLE_NETWORK_MASTERCARD:
        draft.jetpay.appleGoogleNetwork.mastercard = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_APPLE_GOOGLE_NETWORK_VISA:
        draft.jetpay.appleGoogleNetwork.visa = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_APPLE_GOOGLE_NETWORK_AMEX:
        draft.jetpay.appleGoogleNetwork.amex = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_APPLE_GOOGLE_NETWORK_DISCOVER:
        draft.jetpay.appleGoogleNetwork.discover = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_APPLE_GOOGLE_NETWORK_JCB:
        draft.jetpay.appleGoogleNetwork.jcb = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_RATE_LIMITING_TOGGLE:
        draft.jetpay.rateLimits.enabled = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_RATE_LIMITING_AMOUNT:
        draft.jetpay.rateLimits.amount = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_RATE_LIMITING_TIME_INTERVAL:
        draft.jetpay.rateLimits.duration = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_RATE_LIMITING_FOR_GOOGLE_PAY:
        draft.jetpay.rateLimits.enforceRateLimitsForGooglePay = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_JETPAY_SMALL_TRANSACTIONS:
        draft.jetpay.allowSmallTransactions = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_API_TOKEN:
        draft.square.apiToken = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_STORE_ID:
        draft.square.storeId = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_CREDIT_CARD:
        draft.square.paymentMethod.creditCard = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_APPLE_PAY:
        draft.square.paymentMethod.applePay = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_GOOGLE_PAY:
        draft.square.paymentMethod.googlePay = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_MASTERCARD:
        draft.square.appleGoogleNetwork.mastercard = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_VISA:
        draft.square.appleGoogleNetwork.visa = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_DISCOVER:
        draft.square.appleGoogleNetwork.discover = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_AMEX:
        draft.square.appleGoogleNetwork.amex = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_JCB:
        draft.square.appleGoogleNetwork.jcb = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_INTERACT:
        draft.square.appleGoogleNetwork.interac = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_APPLE_PAY_SUPPORTS_3DS:
        draft.square.applePay.supports3DS = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_APPLE_PAY_SUPPORTS_DEBIT:
        draft.square.applePay.supportsDebit = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_APPLE_PAY_SUPPORTS_CREDIT:
        draft.square.applePay.supportsCredit = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_APPLE_PAY_MERCHANT_ID:
        draft.square.applePay.merchantId = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_GOOGLE_PAY_SUPPORTS_3DS:
        draft.square.googlePay.supports3DS = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_GOOGLE_PAY_SUPPORTS_CREDIT:
        draft.square.googlePay.supportsCredit = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_GOOGLE_PAY_SUPPORTS_DEBIT:
        draft.square.googlePay.supportsDebit = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_GOOGLE_PAY_ENVIRONMENT:
        draft.square.googlePay.environment = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_GOOGLE_PAY_GATEWAY_MERCHANT_ID:
        draft.square.googlePay.gatewayMerchantId = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_GOOGLE_PAY_WEB_MERCHANT_KEY:
        draft.square.googlePay.webMerchantKey = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_GOOGLE_PAY_MERCHANT_ID:
        draft.square.googlePay.merchantId = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_GOOGLE_PAY_MERCHANT_NAME:
        draft.square.googlePay.merchantName = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_GOOGLE_PAY_GATEWAY_ID:
        draft.square.googlePay.gatewayId = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_ALLOW_SMALL_TRANSACTIONS:
        draft.square.allowSmallTransactions = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_RATE_LIMITING_TOGGLE:
        draft.square.rateLimits.enabled = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_RATE_LIMITING_AMOUNT:
        draft.square.rateLimits.amount = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_RATE_LIMITING_TIME_INTERVAL:
        draft.square.rateLimits.duration = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_SQUARE_PP_RATE_LIMITING_FOR_GOOGLE_PAY:
        draft.square.rateLimits.enforceRateLimitsForGooglePay = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_API_KEY:
        draft.bambora.apiKey = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_MERCHANT_ID:
        draft.bambora.merchantId = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_PAYMENT_METHOD_CREDIT_CARD:
        draft.bambora.paymentMethod.creditCard = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_PAYMENT_METHOD_APPLE_PAY:
        draft.bambora.paymentMethod.applePay = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_APPLE_GOOGLE_NETWORK_MASTERCARD:
        draft.bambora.appleGoogleNetwork.mastercard = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_APPLE_GOOGLE_NETWORK_VISA:
        draft.bambora.appleGoogleNetwork.visa = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_APPLE_GOOGLE_NETWORK_AMEX:
        draft.bambora.appleGoogleNetwork.amex = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_APPLE_GOOGLE_NETWORK_DISCOVER:
        draft.bambora.appleGoogleNetwork.discover = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_APPLE_GOOGLE_NETWORK_JCB:
        draft.bambora.appleGoogleNetwork.jcb = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_APPLE_MERCHANT_ID:
        draft.bambora.applePay.merchantId = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_APPLE_SUPPORTS_3DS:
        draft.bambora.applePay.supports3DS = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_APPLE_SUPPORTS_DEBIT:
        draft.bambora.applePay.supportsDebit = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_APPLE_SUPPORTS_CREDIT:
        draft.bambora.applePay.supportsCredit = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_RATE_LIMITING_TOGGLE:
        draft.bambora.rateLimits.enabled = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_RATE_LIMITING_AMOUNT:
        draft.bambora.rateLimits.amount = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_RATE_LIMITING_TIME_INTERVAL:
        draft.bambora.rateLimits.duration = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_RATE_LIMITING_FOR_GOOGLE_PAY:
        draft.bambora.rateLimits.enforceRateLimitsForGooglePay = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_BAMBORA_PP_ALLOW_SMALL_TRANSACTIONS:
        draft.bambora.allowSmallTransactions = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_ADYEN_PP_RATE_LIMITING_TOGGLE:
        draft.adyen.rateLimits.enabled = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_ADYEN_PP_RATE_LIMITING_AMOUNT:
        draft.adyen.rateLimits.amount = action.payload;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_ADYEN_PP_RATE_LIMITING_TIME_INTERVAL:
        draft.adyen.rateLimits.duration = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_ADYEN_PP_RATE_LIMITING_FOR_GOOGLE_PAY:
        draft.adyen.rateLimits.enforceRateLimitsForGooglePay = action.payload;
        return draft;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_ADYEN_PP_ALLOW_SMALL_TRANSACTIONS:
        draft.adyen.allowSmallTransactions = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_ADYEN_PP_TRANSACTION_FEE_AMOUNT:
        draft.adyen.perTransactionFees.fixedAmount = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_ADYEN_PP_PAYFAC_REPORT_EMAILS:
        draft.adyen.payfac.payoutRecipientEmails = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_ADYEN_PP_PRICING_MODEL:
        draft.adyen.pricingModel = action.payload;
        break;

      case LOCATION_SETTINGS_ACTIONS.UPDATE_ADYEN_PP_TRANSACTION_FEE_PERCENTAGE:
        draft.adyen.perTransactionFees.percentage = action.payload;
        break;

      default:
        return draft;
    }
  });
};
