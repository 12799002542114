import { combineReducers } from 'redux';
import { paymentProcessingReducer } from './payment/reducer';

import { companyInfoSlice } from './redux/slice';
import { loyaltySlice } from './loyalty/redux/slice';

export const companySettingsReducer = combineReducers({
  loyalty: loyaltySlice.reducer,
  paymentProcessing: paymentProcessingReducer,
  companyInfo: companyInfoSlice.reducer,
});
