import { ITenderType } from './paymentProcessing';
import { IOnAccountPaymentOption, IValidationField } from '@ready/dashboardv2api.contracts';
import { IOnAccountPaymentOptionSummary } from '@ready/dashboardv2api.contracts';

export interface IOnAccountPaymentOptionsList {
  initialized: boolean;
  onAccountPaymentOptions: IOnAccountPaymentOptionSummary[];
}

export interface IOnAccountPaymentOptionsView {
  deleteRequested: boolean;
  isDeleting: boolean;
  isDeleted: boolean;
  isLoading: boolean;
  isEditMode: boolean;
  tenderTypesOptions: ITenderTypesOptions;
  config: IOnAccountPaymentOption;
}

interface ITenderTypesOptions {
  isLoadingTenderTypes: boolean;
  tenderTypes: ITenderType[];
}

export interface IPaymentValidationField {
  internalId: number;
  fields: {
    required: {
      value: boolean;
    };
    fieldLabelName: {
      value: string;
      hasError: boolean;
    };
    fieldPlaceholderText: {
      value: string;
      hasError: boolean;
    };
    fieldType: {
      value: string;
      hasError: boolean;
    };
    maxCharacterLength: {
      value: number;
      hasError: boolean;
    };
    mappedMenuItem: {
      value: { id: string; name: string; posId: string };
      hasError: boolean;
    };
  };
}

export interface IOnAccountPaymentOptionsForm {
  paymentOptionId: string;
  isLoading: boolean;
  isProcessing: boolean;
  isValidated: boolean;
  containsErrors: boolean;
  isLoadingTenderTypes: boolean;
  tenderTypes: ITenderType[];
  fields: {
    status: {
      value: boolean;
    };
    paymentOptionName: {
      value: string;
      hasError: boolean;
    };
    description: {
      value?: string;
    };
    shortCode: {
      value: string;
      hasError: boolean;
    };
    posTenderType: {
      value: string;
      hasError: boolean;
    };
    paymentValidationFields: IPaymentValidationField[];
  };
}

export interface IOnAccountPaymentOptionsState {
  list: IOnAccountPaymentOptionsList;
  view: IOnAccountPaymentOptionsView;
  form: IOnAccountPaymentOptionsForm;
}

export interface IOnAccountPaymentOptionFormErrors {
  paymentOptionName: boolean;
  shortCode: boolean;
  posTenderType: boolean;
  validationFields: {
    fieldLabelName: boolean;
    fieldPlaceholderText: boolean;
    fieldType: boolean;
    maxCharacterLength: boolean;
    mappedPosItem: boolean;
  }[];
}

export const buildOnAccountPaymentOptionsEmptyForm = (): IOnAccountPaymentOptionsForm => ({
  paymentOptionId: '',
  isLoading: false,
  isProcessing: false,
  isValidated: false,
  containsErrors: false,
  isLoadingTenderTypes: false,
  tenderTypes: [],
  fields: {
    status: {
      value: false,
    },
    paymentOptionName: {
      value: '',
      hasError: false,
    },
    description: {
      value: '',
    },
    shortCode: {
      value: '',
      hasError: false,
    },
    posTenderType: {
      value: '',
      hasError: false,
    },
    paymentValidationFields: [],
  },
});

export const buildPaymentValidationField = (validationField?: IValidationField): IPaymentValidationField => {
  return {
    internalId: Math.floor(Math.random() * 1000000),
    fields: {
      required: {
        value: validationField?.required ?? false,
      },
      fieldLabelName: {
        value: validationField?.label ?? '',
        hasError: false,
      },
      fieldPlaceholderText: {
        value: validationField?.placeholder ?? '',
        hasError: false,
      },
      fieldType: {
        value: validationField?.type ?? '',
        hasError: false,
      },
      maxCharacterLength: {
        value: validationField?.maxLength ?? 0,
        hasError: false,
      },
      mappedMenuItem: {
        value: {
          id: validationField?.menuItemId ?? '',
          name: validationField?.posItemName ?? '',
          posId: validationField?.posItemId ?? '',
        },
        hasError: false,
      },
    },
  };
};

export const buildOnAccountPaymentOptionsEmptyList = (): IOnAccountPaymentOptionsList => ({
  initialized: false,
  onAccountPaymentOptions: [],
});

export const buildOnAccountPaymentOptionsEmptyView = (): IOnAccountPaymentOptionsView => ({
  deleteRequested: false,
  isDeleting: false,
  isDeleted: false,
  isLoading: false,
  isEditMode: false,
  config: {
    _id: '',
    locationId: '',
    enabled: false,
    name: '',
    code: '',
    tenderType: '',
    validationFields: [],
  },
  tenderTypesOptions: {
    tenderTypes: [],
    isLoadingTenderTypes: false,
  },
});

export const onAccountPaymentOptionsState: IOnAccountPaymentOptionsState = {
  list: buildOnAccountPaymentOptionsEmptyList(),
  view: buildOnAccountPaymentOptionsEmptyView(),
  form: buildOnAccountPaymentOptionsEmptyForm(),
};
