import produce from 'immer';
import { Action } from '../../redux/types';
import { LOCATION_BILLING_ACTIONS } from './LocationBillingActions';
import { billingTab } from './LocationBillingState';

export const locationBillingReducer = (state = billingTab, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case LOCATION_BILLING_ACTIONS.SET_BILLING_TAB:
        draft = { ...draft, ...action.payload };
        return draft;

      case LOCATION_BILLING_ACTIONS.UPDATE_BILLING_ENABLED:
        draft.billingEnabled = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.UPDATE_OPT_IN_COMPANY_BILLING:
        draft.optInCompanyTiering = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.UPDATE_PER_TRANSACTION_FEES_ORDER_TRANSACTIONS:
        draft.transactionFee.orderTransactionsEnabled = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.UPDATE_PER_TRANSACTION_FEES_ORDER_TRANSACTIONS_PRODUCT_KEY:
        draft.transactionFee.orderTransactions = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.UPDATE_PER_TRANSACTION_FEES_PAY_TRANSACTIONS:
        draft.transactionFee.payTransactionsEnabled = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.UPDATE_PER_TRANSACTION_FEES_PAY_TRANSACTIONS_PRODUCT_KEY:
        draft.transactionFee.payTransactions = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.UPDATE_BPS_FEES_ORDER_TRANSACTIONS:
        draft.BPSFee.orderTransactionBPSFeesEnabled = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.UPDATE_BPS_FEES_ORDER_TRANSACTIONS_PRODUCT_KEY:
        draft.BPSFee.orderTransactionsBPSFees = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.UPDATE_BPS_FEES_PAY_TRANSACTIONS:
        draft.BPSFee.payTransactionBPSFeesEnabled = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.UPDATE_BPS_FEES_PAY_TRANSACTIONS_PRODUCT_KEY:
        draft.BPSFee.payTransactionsBPSFees = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.UPDATE_MONTHLY_SAAS_FEES_RESTAURANT:
        draft.monthlyFees.restaurantMonthlyFeeEnabled = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.UPDATE_MONTHLY_SAAS_FEES_RESTAURANT_PRODUCT_KEY:
        draft.monthlyFees.restaurantMonthlyFee = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.UPDATE_MONTHLY_SAAS_FEES_SINGLE_MODULE:
        draft.monthlyFees.singleModuleFeeEnabled = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.UPDATE_MONTHLY_SAAS_FEES_SINGLE_MODULE_PRODUCT_KEY:
        draft.monthlyFees.singleModuleFee = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.UPDATE_MONTHLY_SAAS_FEES_MULTI_MODULE:
        draft.monthlyFees.multiModuleFeeEnabled = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.UPDATE_MONTHLY_SAAS_FEES_MULTI_MODULE_PRODUCT_KEY:
        draft.monthlyFees.multiModuleFee = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.UPDATE_MONTHLY_SAAS_FEES_VIRTUAL_KIOSK:
        draft.monthlyFees.virutalKioskEnabled = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.UPDATE_MONTHLY_SAAS_FEES_VIRTUAL_KIOSK_PRODUCT_KEY:
        draft.monthlyFees.virutalKiosk = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.UPDATE_MONTHLY_SAAS_FEES_VIRTUAL_DRIVE_THROUGH:
        draft.monthlyFees.virtualDriveThroughEnabled = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.UPDATE_MONTHLY_SAAS_FEES_VIRTUAL_DRIVE_THROUGH_PRODUCT_KEY:
        draft.monthlyFees.virtualDriveThrough = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.UPDATE_MONTHLY_SAAS_FEES_ORDER_AHEAD:
        draft.monthlyFees.orderAheadEnabled = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.UPDATE_MONTHLY_SAAS_FEES_ORDER_AHEAD_PRODUCT_KEY:
        draft.monthlyFees.orderAhead = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.UPDATE_MONTHLY_SAAS_FEES_PAY_AT_TABLE:
        draft.monthlyFees.payAtTableEnabled = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.UPDATE_MONTHLY_SAAS_FEES_PAY_AT_TABLE_PRODUCT_KEY:
        draft.monthlyFees.payAtTable = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.UPDATE_MONTHLY_SAAS_FEES_ORDER_TO_TABLE:
        draft.monthlyFees.orderToTableEnabled = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.UPDATE_MONTHLY_SAAS_FEES_ORDER_TO_TABLE_PRODUCT_KEY:
        draft.monthlyFees.orderToTable = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.UPDATE_RECURRING_PRODUCTS_READY_WAITLIST:
        draft.recurringProducts.readyWaitlistEnabled = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.UPDATE_RECURRING_PRODUCTS_READY_DIGITAL_SIGNAGE:
        draft.recurringProducts.readyDigitalSignageEnabled = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.UPDATE_RECURRING_PRODUCTS_READY_PILOT_PROGRAM:
        draft.recurringProducts.readyPilotProgramEnabled = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.SET_PER_TRANSACTION_FEES_ORDER_TRANSACTIONS_PRODUCT_KEY_ERROR:
        draft.validation.hasErrors = true;
        draft.validation.transactionFee.orderTransactions = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.SET_PER_TRANSACTION_FEES_PAY_TRANSACTIONS_PRODUCT_KEY_ERROR:
        draft.validation.hasErrors = true;
        draft.validation.transactionFee.payTransactions = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.SET_BPS_FEES_ORDER_TRANSACTIONS_PRODUCT_KEY_ERROR:
        draft.validation.hasErrors = true;
        draft.validation.BPSFee.orderTransactionsBPSFees = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.SET_BPS_FEES_PAY_TRANSACTIONS_PRODUCT_KEY_ERROR:
        draft.validation.hasErrors = true;
        draft.validation.BPSFee.payTransactionsBPSFees = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.SET_MONTHLY_SAAS_FEES_RESTAURANT_PRODUCT_KEY_ERROR:
        draft.validation.hasErrors = true;
        draft.validation.monthlyFees.restaurantMonthlyFee = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.SET_MONTHLY_SAAS_FEES_SINGLE_MODULE_PRODUCT_KEY_ERROR:
        draft.validation.hasErrors = true;
        draft.validation.monthlyFees.singleModuleFee = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.SET_MONTHLY_SAAS_FEES_MULTI_MODULE_PRODUCT_KEY_ERROR:
        draft.validation.hasErrors = true;
        draft.validation.monthlyFees.multiModuleFee = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.SET_MONTHLY_SAAS_FEES_VIRTUAL_KIOSK_PRODUCT_KEY_ERROR:
        draft.validation.hasErrors = true;
        draft.validation.monthlyFees.virutalKiosk = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.SET_MONTHLY_SAAS_FEES_VIRTUAL_DRIVE_THROUGH_PRODUCT_KEY_ERROR:
        draft.validation.hasErrors = true;
        draft.validation.monthlyFees.virtualDriveThrough = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.SET_MONTHLY_SAAS_FEES_ORDER_AHEAD_PRODUCT_KEY_ERROR:
        draft.validation.hasErrors = true;
        draft.validation.monthlyFees.orderAhead = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.SET_MONTHLY_SAAS_FEES_PAY_AT_TABLE_PRODUCT_KEY_ERROR:
        draft.validation.hasErrors = true;
        draft.validation.monthlyFees.payAtTable = action.payload;
        return draft;

      case LOCATION_BILLING_ACTIONS.SET_MONTHLY_SAAS_FEES_ORDER_TO_TABLE_PRODUCT_KEY_ERROR:
        draft.validation.hasErrors = true;
        draft.validation.monthlyFees.orderToTable = action.payload;
        return draft;

      default:
        return draft;
    }
  });
};
