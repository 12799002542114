import { ILocationEmailReceipts } from '@ready/dashboardv2api.contracts';

export interface IEmailReceiptsPanel extends ILocationEmailReceipts {
  isDirty: boolean;
  validation: any;
}

export const emailReceipts: IEmailReceiptsPanel = {
  receiptLogoAsset: {
    _id: '',
    fileName: '',
    fileKey: '',
  },
  receiptTitleText: '',
  receiptHeaderText: '',
  genesysConfig: {
    sendToGenesys: false,
    genesysName: '',
  },
  isDirty: false,
  validation: {},
};
