import produce from 'immer';
import { Action } from '../../redux/types';
import { IMenuSectionItem, IMenuSectionItemGroup, Status, Visibility } from '@ready/menu.core';
import {
  menusState,
  newMenu,
  newMenuForm,
  newMenuGalleryForm,
  newMenuGalleryView,
  sectionItemChange,
  sectionItemChangesForSection,
} from './MenusState';
import { MENUS_ACTIONS } from './MenusActions';
import InfiniteScrollingListStateHelper from '../../utils/InfiniteScrollingList/InfiniteScrollingListStateHelper';
import ItemsAndGroupsView from '../types/ItemsAndGroupsView.enum';

export const menusReducer = (state = menusState, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case MENUS_ACTIONS.LOAD_MENU_BUILDER_MENUS_BEGIN:
        draft.loading = true;
        draft.items = [];
        return draft;

      case MENUS_ACTIONS.LOAD_MENU_BUILDER_MENUS_SUCCESS:
        draft.loading = false;
        draft.items = action.payload;
        return draft;

      case MENUS_ACTIONS.LOAD_MENU_BUILDER_MENUS_ERROR:
        draft.loading = false;
        return draft;

      case MENUS_ACTIONS.PREPARE_MENU_BUILDER_EDIT_MENU:
        draft.menuForm = action.payload;
        return draft;

      case MENUS_ACTIONS.UPDATE_MENU_BUILDER_MENU_STATUS:
        draft.menuForm.menu.status = action.payload ? Status.enabled : Status.disabled;
        return draft;

      case MENUS_ACTIONS.UPDATE_MENU_BUILDER_MENU_DISPLAY_NAME:
        draft.menuForm.menu.displayName = action.payload;
        draft.menuForm.validation.displayName.hasError = false;
        draft.menuForm.validation.displayName.errorMessage = '';
        return draft;

      case MENUS_ACTIONS.UPDATE_MENU_BUILDER_MENU_DESCRIPTION:
        draft.menuForm.menu.description = action.payload;
        return draft;

      case MENUS_ACTIONS.UPDATE_MENU_BUILDER_MENU_SCHEDULER:
        draft.menuForm.menu.schedule = action.payload;
        return draft;

      case MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_VALIDATED:
        draft.menuForm.validation.displayName = action.payload;
        return draft;

      case MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_BEGIN:
        draft.menuForm.processing = true;
        draft.menuForm.saved = false;
        draft.menuForm.sectionForm.processing = true;
        return draft;

      case MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_SUCCESS:
        draft.menuForm.menu = action.payload;
        draft.menuForm.editing = false;
        draft.menuForm.processing = false;
        draft.menuForm.saved = true;
        draft.menuForm.sectionForm.reordering = false;
        draft.menuForm.sectionForm.processing = false;
        draft.menuGalleryForm.menu._id = '';
        draft.menuGalleryForm.editing = false;
        return draft;

      case MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_ERROR:
        draft.menuForm.processing = false;
        draft.menuForm.sectionForm.processing = false;
        return draft;

      case MENUS_ACTIONS.PREPARE_MENU_BUILDER_EDIT_MENU_SECTION:
        draft.menuForm.sectionForm = action.payload;
        return draft;

      case MENUS_ACTIONS.UPDATE_MENU_BUILDER_MENU_SECTION_VIEW_STATE:
        if (action.payload.isExpanded) {
          if (!draft.previousMenuState.expandedSections.includes(action.payload.sectionId)) {
            draft.previousMenuState.expandedSections.push(action.payload.sectionId);
          }
        } else {
          if (draft.previousMenuState.expandedSections.includes(action.payload.sectionId)) {
            draft.previousMenuState.expandedSections = draft.previousMenuState.expandedSections.filter(
              (id) => id !== action.payload.sectionId
            );
          }
        }
        return draft;

      case MENUS_ACTIONS.UPDATE_MENU_BUILDER_MENU_SECTION_STATUS:
        draft.menuForm.sectionForm.section.status = action.payload ? Status.enabled : Status.disabled;
        return draft;

      case MENUS_ACTIONS.UPDATE_MENU_BUILDER_MENU_SECTION_DISPLAY_NAME:
        draft.menuForm.sectionForm.section.displayName = action.payload;
        draft.menuForm.sectionForm.validation.displayName.hasError = false;
        draft.menuForm.sectionForm.validation.displayName.errorMessage = '';
        return draft;

      case MENUS_ACTIONS.UPDATE_MENU_BUILDER_MENU_SECTION_DESCRIPTION:
        draft.menuForm.sectionForm.section.description = action.payload;
        return draft;

      case MENUS_ACTIONS.UPDATE_MENU_BUILDER_MENU_SECTION_DEFAULT_APPEARANCE:
        draft.menuForm.sectionForm.section.defaultAppearance = action.payload;
        return draft;

      case MENUS_ACTIONS.UPDATE_MENU_BUILDER_MENU_SECTION_SCHEDULER:
        draft.menuForm.sectionForm.section.schedule = action.payload;
        return draft;

      case MENUS_ACTIONS.DELETE_MENU_BUILDER_MENU_SECTION_REQUESTED:
        draft.menuForm.sectionForm.deleteRequested = action.payload.show;
        draft.menuForm.sectionForm.section = action.payload.section;
        return draft;

      case MENUS_ACTIONS.DELETE_MENU_BUILDER_MENU_SECTION_BEGIN:
        draft.menuForm.sectionForm.processing = true;
        return draft;

      case MENUS_ACTIONS.DELETE_MENU_BUILDER_MENU_SECTION_SUCCESS:
        draft.menuForm.menu = action.payload;
        draft.menuForm.sectionForm.deleteRequested = false;
        draft.menuForm.sectionForm.processing = false;
        return draft;

      case MENUS_ACTIONS.DELETE_MENU_BUILDER_MENU_SECTION_ERROR:
        draft.menuForm.sectionForm.processing = false;
        return draft;

      case MENUS_ACTIONS.UPDATE_MENU_BUILDER_MENU_SECTION_STATUS_BEGIN:
        draft.menuForm.processing = true;
        draft.menuForm.sectionForm.processing = true;
        return draft;

      case MENUS_ACTIONS.UPDATE_MENU_BUILDER_MENU_SECTION_STATUS_SUCCESS:
        draft.menuForm.processing = false;
        draft.menuForm.menu = action.payload;
        draft.menuForm.sectionForm.processing = false;
        return draft;

      case MENUS_ACTIONS.UPDATE_MENU_BUILDER_MENU_SECTION_STATUS_ERROR:
        draft.menuForm.processing = false;
        draft.menuForm.sectionForm.processing = false;
        return draft;

      case MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_SECTION_VALIDATED:
        draft.menuForm.sectionForm.validation = action.payload.section;
        return draft;

      case MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_SECTION_BEGIN:
        draft.menuForm.sectionForm.processing = true;
        return draft;

      case MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_SECTION_SUCCESS:
        draft.menuForm.menu = action.payload;
        draft.menuForm.sectionForm.editing = false;
        draft.menuForm.sectionForm.processing = false;
        draft.menuForm.sectionForm.saved = true;
        return draft;

      case MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_SECTION_ERROR:
        draft.menuForm.sectionForm.processing = false;
        return draft;

      case MENUS_ACTIONS.PREPARE_MENU_BUILDER_REORDER_MENU_SECTIONS_MODAL:
        draft.menuForm.sectionForm.reordering = action.payload;
        draft.menuForm.sectionForm.itemsForReordering = action.payload ? draft.menuForm.menu.sections : [];
        return draft;

      case MENUS_ACTIONS.ADD_ITEM_GROUP_TO_MENU_SECTION:
        draft.menuForm.sectionForm.section.items.push(action.payload);
        draft.menuForm.sectionForm.sectionItemChanges.push(sectionItemChange(action.payload));
        action.payload.items.forEach((item: IMenuSectionItem) => {
          draft.menuForm.sectionForm.sectionItemChanges.push(sectionItemChange(item));
        });
        return draft;

      case MENUS_ACTIONS.REMOVE_ITEM_GROUP_FROM_MENU_SECTION:
        const itemGroupsForRemove = draft.menuForm.sectionForm.section.items;
        const itemGroupNamesForRemove = draft.menuForm.sectionForm.sectionItemChanges;
        const itemGroupIndex = itemGroupsForRemove.findIndex((itemGroup) => itemGroup.itemId === action.payload);
        const itemGroupNameIndex = itemGroupNamesForRemove.findIndex(
          (itemGroup) => itemGroup.itemId === action.payload
        );
        if (itemGroupIndex > -1) {
          itemGroupsForRemove.splice(itemGroupIndex, 1);
        }
        if (itemGroupNameIndex > -1) {
          itemGroupNamesForRemove.splice(itemGroupNameIndex, 1);
        }
        return draft;

      case MENUS_ACTIONS.ADD_MENU_ITEM_TO_MENU_SECTION:
        draft.menuForm.sectionForm.section.items.push(action.payload);
        draft.menuForm.sectionForm.sectionItemChanges.push(sectionItemChange(action.payload));
        return draft;

      case MENUS_ACTIONS.REMOVE_MENU_ITEM_FROM_MENU_SECTION:
        const menuItemsForRemove = draft.menuForm.sectionForm.section.items;
        const menuItemChangesForRemove = draft.menuForm.sectionForm.sectionItemChanges;
        const menuItemIndex = menuItemsForRemove.findIndex((menuItem) => menuItem.itemId === action.payload);
        const menuItemChangeIndex = menuItemChangesForRemove.findIndex(
          (menuItem) => menuItem.itemId === action.payload
        );
        if (menuItemIndex > -1) {
          menuItemsForRemove.splice(menuItemIndex, 1);
        }
        if (menuItemChangeIndex > -1) {
          menuItemChangesForRemove.splice(menuItemChangeIndex, 1);
        }
        return draft;

      case MENUS_ACTIONS.ADD_MENU_ITEM_TO_ITEM_GROUP_IN_MENU_SECTION:
        const itemGroupForMenuItemAdd = draft.menuForm.sectionForm.section.items
          .filter((item) => item.sectionItemType === 'group')
          .find((item) => item.itemId === action.payload.itemGroupId);
        if (itemGroupForMenuItemAdd) {
          (itemGroupForMenuItemAdd as IMenuSectionItemGroup).items.push(action.payload.menuSectionItem);
          draft.menuForm.sectionForm.sectionItemChanges.push(sectionItemChange(action.payload.menuSectionItem));
          const itemGroupChange = draft.menuForm.sectionForm.sectionItemChanges.find(
            (itemChange) => itemChange.itemId === action.payload.itemGroupId
          );
          if (itemGroupChange) {
            itemGroupChange.modified = true;
          }
        }
        return draft;

      case MENUS_ACTIONS.REMOVE_MENU_ITEM_FROM_ITEM_GROUP_IN_MENU_SECTION:
        const itemGroupForMenuItemRemove = draft.menuForm.sectionForm.section.items
          .filter((item) => item.sectionItemType === 'group')
          .find((item) => item.itemId === action.payload.itemGroupId);
        if (itemGroupForMenuItemRemove) {
          const menuItemInItemGroupForRemoveIndex = (
            itemGroupForMenuItemRemove as IMenuSectionItemGroup
          ).items.findIndex((item) => item.itemId === action.payload.menuItemId);
          if (menuItemInItemGroupForRemoveIndex > -1) {
            (itemGroupForMenuItemRemove as IMenuSectionItemGroup).items.splice(menuItemInItemGroupForRemoveIndex, 1);
          }
          const itemGroupChange = draft.menuForm.sectionForm.sectionItemChanges.find(
            (itemChange) => itemChange.itemId === action.payload.itemGroupId
          );
          if (itemGroupChange) {
            itemGroupChange.modified = true;
          }
        }
        const itemGroupMenuItemNamesForRemove = draft.menuForm.sectionForm.sectionItemChanges;
        const itemGroupMenuItemNameIndex = itemGroupMenuItemNamesForRemove.findIndex(
          (menuItem) => menuItem.itemId === action.payload
        );
        if (itemGroupMenuItemNameIndex > -1) {
          itemGroupMenuItemNamesForRemove.splice(itemGroupMenuItemNameIndex, 1);
        }
        return draft;

      case MENUS_ACTIONS.UPDATE_MENU_BUILDER_MENU_SECTION_ITEM_DISPLAY_NAME:
        const sectionMenuItemsForUpdate = draft.menuForm.sectionForm.sectionItemChanges;
        const sectionMenuItemIndex = sectionMenuItemsForUpdate.findIndex(
          (menuItem) => menuItem.itemId === action.payload.menuItemId
        );
        if (sectionMenuItemIndex > -1) {
          const sectionMenuItemForUpdate = sectionMenuItemsForUpdate[sectionMenuItemIndex];
          sectionMenuItemsForUpdate.splice(sectionMenuItemIndex, 1, {
            ...sectionMenuItemForUpdate,
            displayName: action.payload.displayName,
            validation: {
              displayName: {
                hasError: !action.payload.displayName,
                errorMessage: action.payload.displayName ? '' : 'Required Field',
              },
            },
          });
        }
        return draft;

      case MENUS_ACTIONS.UPDATE_MENU_BUILDER_MENU_SECTION_ITEM_VISIBILITY:
        const menuItemForVisibilityUpdate = draft.menuForm.sectionForm.section.items.find(
          (item) => item.itemId === action.payload.menuItemId
        );
        if (menuItemForVisibilityUpdate) {
          menuItemForVisibilityUpdate.visibility = action.payload.visible ? Visibility.visible : Visibility.hidden;
        }
        return draft;

      case MENUS_ACTIONS.UPDATE_MENU_BUILDER_MENU_SECTION_ITEM_GROUP_ITEM_VISIBILITY:
        const itemGroupForVisibilityUpdate = draft.menuForm.sectionForm.section.items
          .filter((item) => item.sectionItemType === 'group')
          .find((item) => item.itemId === action.payload.itemGroupId);
        if (itemGroupForVisibilityUpdate) {
          const menuItemInItemGroupForVisibilityUpdate = (
            itemGroupForVisibilityUpdate as IMenuSectionItemGroup
          ).items.find((item) => item.itemId === action.payload.menuItemId);
          if (menuItemInItemGroupForVisibilityUpdate) {
            menuItemInItemGroupForVisibilityUpdate.visibility = action.payload.visible
              ? Visibility.visible
              : Visibility.hidden;
          }
          const itemGroupChange = draft.menuForm.sectionForm.sectionItemChanges.find(
            (itemChange) => itemChange.itemId === action.payload.itemGroupId
          );
          if (itemGroupChange) {
            itemGroupChange.modified = true;
          }
        }
        return draft;

      case MENUS_ACTIONS.MOVE_MENU_ITEM_IN_MENU_SECTION:
        const menuItemsForMove = draft.menuForm.sectionForm.section.items;
        const movedMenuItem = menuItemsForMove.splice(action.payload.sourceIndex, 1)[0];
        menuItemsForMove.splice(action.payload.destinationIndex, 0, movedMenuItem);
        return draft;

      case MENUS_ACTIONS.MOVE_MENU_ITEM_IN_MENU_SECTION_GROUP:
        const sectionGroupsForResort = draft.menuForm.sectionForm.sectionItemChanges;
        const sectionGroupForResortIndex = sectionGroupsForResort.findIndex(
          (itemGroup) => itemGroup.itemId === action.payload.groupId
        );
        if (sectionGroupForResortIndex > -1) {
          const sectionGroupForResort = sectionGroupsForResort[sectionGroupForResortIndex];
          sectionGroupsForResort.splice(sectionGroupForResortIndex, 1, {
            ...sectionGroupForResort,
            modified: true,
          });
        }
        const menuGroupForMove = draft.menuForm.sectionForm.section.items
          .filter((item) => item.sectionItemType === 'group')
          .find((item) => item.itemId === action.payload.groupId);
        if (menuGroupForMove) {
          const menuGroupItemsForMove = (menuGroupForMove as IMenuSectionItemGroup).items;
          const movedMenuGroupItem = menuGroupItemsForMove.splice(action.payload.sourceIndex, 1)[0];
          menuGroupItemsForMove.splice(action.payload.destinationIndex, 0, movedMenuGroupItem);
        }
        return draft;

      case MENUS_ACTIONS.MOVE_MENU_SECTION_IN_MENU:
        const menuSectionsForMove = draft.menuForm.sectionForm.itemsForReordering;
        const movedMenuSection = menuSectionsForMove.splice(action.payload.sourceIndex, 1)[0];
        menuSectionsForMove.splice(action.payload.destinationIndex, 0, movedMenuSection);
        return draft;

      case MENUS_ACTIONS.DELETE_MENU_BUILDER_MENU_REQUESTED:
        draft.deleteRequested = action.payload.show;
        draft.menuToDelete = action.payload.menu;
        return draft;

      case MENUS_ACTIONS.DELETE_MENU_BUILDER_MENU_BEGIN:
        draft.deleteProcessing = true;
        draft.deleteError = false;
        return draft;

      case MENUS_ACTIONS.DELETE_MENU_BUILDER_MENU_SUCCESS:
        draft.deleteRequested = false;
        draft.deleteProcessing = false;
        draft.deletedMenu = draft.menuToDelete;
        draft.menuToDelete = undefined;
        return draft;

      case MENUS_ACTIONS.DELETE_MENU_BUILDER_MENU_ERROR:
        draft.deleteProcessing = false;
        draft.deleteError = true;
        return draft;

      case MENUS_ACTIONS.PREPARE_MENU_BUILDER_REORDER_MENUS_MODAL:
        draft.reordering = action.payload;
        draft.itemsForReordering = action.payload ? draft.items : [];
        return draft;

      case MENUS_ACTIONS.MOVE_MENU_IN_MENUS_LIST:
        const menusForMove = draft.itemsForReordering;
        const movedMenu = menusForMove.splice(action.payload.sourceIndex, 1)[0];
        menusForMove.splice(action.payload.destinationIndex, 0, movedMenu);
        return draft;

      case MENUS_ACTIONS.SAVE_MENU_BUILDER_MENUS_BEGIN:
        draft.processing = true;
        return draft;

      case MENUS_ACTIONS.SAVE_MENU_BUILDER_MENUS_SUCCESS:
        draft.reordering = false;
        draft.processing = false;
        draft.items = action.payload;
        return draft;

      case MENUS_ACTIONS.SAVE_MENU_BUILDER_MENUS_ERROR:
        draft.processing = false;
        return draft;

      case MENUS_ACTIONS.LOAD_MENU_BUILDER_MENU_BEGIN:
        draft.menuForm.loading = true;
        draft.menuForm.menu = newMenu();
        draft.menuForm.changed = false;
        return draft;

      case MENUS_ACTIONS.LOAD_MENU_BUILDER_MENU_SUCCESS:
        draft.menuForm.loading = false;
        draft.menuForm.menu = action.payload;
        if (draft.previousMenuState.menuId !== action.payload._id) {
          draft.previousMenuState = {
            menuId: action.payload._id,
            expandedSections: [],
          };
        }
        return draft;

      case MENUS_ACTIONS.LOAD_MENU_BUILDER_MENU_ERROR:
        draft.menuForm.loading = false;
        return draft;

      case MENUS_ACTIONS.RESET_MENU_BUILDER_MENU_FORMS:
        draft.items = [];
        draft.menuForm = newMenuForm();
        draft.menuGalleryForm = newMenuGalleryForm();
        return draft;

      case MENUS_ACTIONS.ADVANCED_SETTINGS_DIALOG_VISIBLE:
        draft.advancedSettings.isDialogVisible = action.payload;
        draft.advancedSettings.sync = !action.payload;
        return draft;

      case MENUS_ACTIONS.ADVANCED_SETTINGS_MENU_SECONDARY_NAVIGATION:
        draft.advancedSettings.settings.menuSectionsSecondaryNavigation = action.payload;
        return draft;

      case MENUS_ACTIONS.ADVANCED_SETTINGS_READ_ONLY_MENUS:
        draft.advancedSettings.settings.readOnlyMenus = action.payload;
        return draft;

      case MENUS_ACTIONS.INITIALIZE_ADVANCED_SETTINGS:
        draft.advancedSettings.settings.menuSectionsSecondaryNavigation =
          action.payload.menuSectionsSecondaryNavigation;
        draft.advancedSettings.settings.readOnlyMenus = action.payload.readOnlyMenus;
        draft.advancedSettings.sync = false;
        return draft;

      case MENUS_ACTIONS.SAVE_ADVANCED_SETTINGS_BEGIN:
        draft.advancedSettings.loading = true;
        return draft;

      case MENUS_ACTIONS.SAVE_ADVANCED_SETTINGS_SUCCESS:
        draft.advancedSettings.settings.menuSectionsSecondaryNavigation =
          action.payload.menuSectionsSecondaryNavigation;
        draft.advancedSettings.settings.readOnlyMenus = action.payload.readOnlyMenus;
        draft.advancedSettings.loading = false;
        draft.advancedSettings.isDialogVisible = false;
        return draft;

      case MENUS_ACTIONS.SAVE_ADVANCED_SETTINGS_ERROR:
        draft.advancedSettings.error = true;
        draft.advancedSettings.loading = false;
        return draft;

      case MENUS_ACTIONS.ADVANCED_SETTINGS_RESET_SETTINGS:
        draft.advancedSettings.settings.menuSectionsSecondaryNavigation =
          draft.advancedSettings.cachedSettings.menuSectionsSecondaryNavigation;
        draft.advancedSettings.settings.readOnlyMenus = draft.advancedSettings.cachedSettings.readOnlyMenus;
        return draft;

      case MENUS_ACTIONS.ADVANCED_SETTINGS_SAVE_SETTINGS:
        draft.advancedSettings.cachedSettings.menuSectionsSecondaryNavigation =
          draft.advancedSettings.settings.menuSectionsSecondaryNavigation;
        draft.advancedSettings.cachedSettings.readOnlyMenus = draft.advancedSettings.settings.readOnlyMenus;
        return draft;

      case MENUS_ACTIONS.PREPARE_MENU_BUILDER_ITEMS_AND_GROUPS_MODAL:
        InfiniteScrollingListStateHelper.prepareState(
          draft.itemsAndGroupsSelection,
          action.payload.modalVisible,
          action.payload.selectedIds
        );
        draft.itemsAndGroupsSelection.view = action.payload.view;
        return draft;

      case MENUS_ACTIONS.SET_MENU_BUILDER_ITEMS_AND_GROUPS_MODAL_VIEW:
        draft.itemsAndGroupsSelection.view = action.payload;
        draft.itemsAndGroupsSelection.readyToLoad = true;
        draft.itemsAndGroupsSelection.loading = true;
        draft.itemsAndGroupsSelection.paginationAvailable = false;
        draft.itemsAndGroupsSelection.paginationLoading = false;
        draft.itemsAndGroupsSelection.filtered = false;
        draft.itemsAndGroupsSelection.query = '';
        draft.itemsAndGroupsSelection.page = 1;
        draft.itemsAndGroupsSelection.error = false;
        draft.itemsAndGroupsSelection.items = [];
        return draft;

      case MENUS_ACTIONS.SET_MENU_BUILDER_ITEMS_AND_GROUPS_MODAL_QUERY:
        InfiniteScrollingListStateHelper.filterChangedState(draft.itemsAndGroupsSelection, action.payload);
        return draft;

      case MENUS_ACTIONS.ADD_MENU_ITEM_TO_ITEMS_AND_GROUPS_SELECTION:
        InfiniteScrollingListStateHelper.selectItemState(draft.itemsAndGroupsSelection, action.payload);
        return draft;

      case MENUS_ACTIONS.REMOVE_MENU_ITEM_FROM_ITEMS_AND_GROUPS_SELECTION:
        InfiniteScrollingListStateHelper.unselectItemState(draft.itemsAndGroupsSelection, action.payload);
        return draft;

      case MENUS_ACTIONS.ADD_ITEM_GROUP_TO_ITEMS_AND_GROUPS_SELECTION:
        InfiniteScrollingListStateHelper.selectItemState(draft.itemsAndGroupsSelection, action.payload);
        return draft;

      case MENUS_ACTIONS.REMOVE_ITEM_GROUP_FROM_ITEMS_AND_GROUPS_SELECTION:
        InfiniteScrollingListStateHelper.unselectItemState(draft.itemsAndGroupsSelection, action.payload);
        return draft;

      case MENUS_ACTIONS.LOAD_MENU_BUILDER_MENU_ITEMS_FOR_INFINITE_SCROLL_BEGIN:
        InfiniteScrollingListStateHelper.loadingState(draft.itemsAndGroupsSelection);
        return draft;

      case MENUS_ACTIONS.LOAD_MENU_BUILDER_MENU_ITEMS_FOR_INFINITE_SCROLL_UPDATE_TOKEN:
        draft.itemsAndGroupsSelection.token = action.payload;
        return draft;

      case MENUS_ACTIONS.LOAD_MENU_BUILDER_MENU_ITEMS_FOR_INFINITE_SCROLL_SUCCESS:
        const lastWantedCall = action.payload.token === state.itemsAndGroupsSelection.token;
        if (lastWantedCall && draft.itemsAndGroupsSelection.view === ItemsAndGroupsView.ITEMS) {
          InfiniteScrollingListStateHelper.loadedState(draft.itemsAndGroupsSelection, action.payload.items);
        }
        return draft;
      case MENUS_ACTIONS.LOAD_MENU_BUILDER_MENU_ITEMS_FOR_INFINITE_SCROLL_ERROR:
        if (draft.itemsAndGroupsSelection.view === ItemsAndGroupsView.ITEMS) {
          InfiniteScrollingListStateHelper.loadFailedState(draft.itemsAndGroupsSelection);
        }
        return draft;

      case MENUS_ACTIONS.LOAD_MORE_MENU_BUILDER_MENU_ITEMS_FOR_INFINITE_SCROLL_BEGIN:
        InfiniteScrollingListStateHelper.loadingMoreState(draft.itemsAndGroupsSelection);
        return draft;

      case MENUS_ACTIONS.LOAD_MORE_MENU_BUILDER_MENU_ITEMS_FOR_INFINITE_SCROLL_SUCCESS:
        if (draft.itemsAndGroupsSelection.view === ItemsAndGroupsView.ITEMS) {
          InfiniteScrollingListStateHelper.loadedMoreState(draft.itemsAndGroupsSelection, action.payload);
        }
        return draft;

      case MENUS_ACTIONS.LOAD_MORE_MENU_BUILDER_MENU_ITEMS_FOR_INFINITE_SCROLL_NO_RESULTS:
        if (draft.itemsAndGroupsSelection.view === ItemsAndGroupsView.ITEMS) {
          InfiniteScrollingListStateHelper.loadMoreNoItemsState(draft.itemsAndGroupsSelection);
        }
        return draft;

      case MENUS_ACTIONS.LOAD_MORE_MENU_BUILDER_MENU_ITEMS_FOR_INFINITE_SCROLL_ERROR:
        if (draft.itemsAndGroupsSelection.view === ItemsAndGroupsView.ITEMS) {
          InfiniteScrollingListStateHelper.loadMoreFailedState(draft.itemsAndGroupsSelection);
        }
        return draft;

      case MENUS_ACTIONS.LOAD_MENU_BUILDER_ITEM_GROUPS_FOR_INFINITE_SCROLL_BEGIN:
        InfiniteScrollingListStateHelper.loadingState(draft.itemsAndGroupsSelection);
        return draft;

      case MENUS_ACTIONS.LOAD_MENU_BUILDER_ITEM_GROUPS_FOR_INFINITE_SCROLL_SUCCESS:
        if (draft.itemsAndGroupsSelection.view === ItemsAndGroupsView.GROUPS) {
          InfiniteScrollingListStateHelper.loadedState(draft.itemsAndGroupsSelection, action.payload);
        }
        return draft;

      case MENUS_ACTIONS.LOAD_MENU_BUILDER_ITEM_GROUPS_FOR_INFINITE_SCROLL_ERROR:
        if (draft.itemsAndGroupsSelection.view === ItemsAndGroupsView.GROUPS) {
          InfiniteScrollingListStateHelper.loadFailedState(draft.itemsAndGroupsSelection);
        }
        return draft;

      case MENUS_ACTIONS.LOAD_MORE_MENU_BUILDER_ITEM_GROUPS_FOR_INFINITE_SCROLL_BEGIN:
        InfiniteScrollingListStateHelper.loadingMoreState(draft.itemsAndGroupsSelection);
        return draft;

      case MENUS_ACTIONS.LOAD_MORE_MENU_BUILDER_ITEM_GROUPS_FOR_INFINITE_SCROLL_SUCCESS:
        if (draft.itemsAndGroupsSelection.view === ItemsAndGroupsView.GROUPS) {
          InfiniteScrollingListStateHelper.loadedMoreState(draft.itemsAndGroupsSelection, action.payload);
        }
        return draft;

      case MENUS_ACTIONS.LOAD_MORE_MENU_BUILDER_ITEM_GROUPS_FOR_INFINITE_SCROLL_NO_RESULTS:
        if (draft.itemsAndGroupsSelection.view === ItemsAndGroupsView.GROUPS) {
          InfiniteScrollingListStateHelper.loadMoreNoItemsState(draft.itemsAndGroupsSelection);
        }
        return draft;

      case MENUS_ACTIONS.LOAD_MORE_MENU_BUILDER_ITEM_GROUPS_FOR_INFINITE_SCROLL_ERROR:
        if (draft.itemsAndGroupsSelection.view === ItemsAndGroupsView.GROUPS) {
          InfiniteScrollingListStateHelper.loadMoreFailedState(draft.itemsAndGroupsSelection);
        }
        return draft;

      case MENUS_ACTIONS.MENU_GALLERY_SET_CREATE_MODE:
        draft.menuGalleryForm.editing = true;
        draft.menuGalleryForm.menu.sortOrder = action.payload;
        draft.menuGalleryForm.sectionItemChanges = sectionItemChangesForSection(draft.menuGalleryForm.menu.itemGallery);
        return draft;

      case MENUS_ACTIONS.MENU_GALLERY_CANCEL_CREATE_MODE:
        draft.menuGalleryForm = action.payload;
        draft.menuGalleryForm.sectionItemChanges = [];
        return draft;

      case MENUS_ACTIONS.MENU_GALLERY_UPDATE_GALLERY_NAME:
        draft.menuGalleryForm.menu.displayName = action.payload;
        if (!!draft.menuGalleryForm.menu.itemGallery) {
          draft.menuGalleryForm.menu.itemGallery.displayName = action.payload;
          draft.menuGalleryForm.validation.displayName.hasError = false;
          draft.menuGalleryForm.validation.displayName.errorMessage = '';
        }
        return draft;

      case MENUS_ACTIONS.MENU_GALLERY_UPDATE_DESCRIPTION:
        draft.menuGalleryForm.menu.description = action.payload;
        if (!!draft.menuGalleryForm.menu.itemGallery) {
          draft.menuGalleryForm.menu.itemGallery.description = action.payload;
        }
        return draft;

      case MENUS_ACTIONS.MENU_GALLERY_UPDATE_STATUS:
        draft.menuGalleryForm.menu.status = action.payload;
        if (!!draft.menuGalleryForm.menu.itemGallery) {
          draft.menuGalleryForm.menu.itemGallery.status = action.payload;
        }
        return draft;

      case MENUS_ACTIONS.MENU_GALLERY_ADD_MENU_ITEM:
        draft.menuGalleryForm.menu.itemGallery?.items.push(action.payload);
        draft.menuGalleryForm.sectionItemChanges.push(sectionItemChange(action.payload));
        return draft;

      case MENUS_ACTIONS.MENU_GALLERY_REMOVE_MENU_ITEM:
        InfiniteScrollingListStateHelper.unselectItemState(draft.itemsAndGroupsSelection, action.payload);
        return draft;

      case MENUS_ACTIONS.REMOVE_MENU_ITEM_FROM_MENU_GALLERY:
        const menuItemsForRemoveInGallery = draft.menuGalleryForm.menu.itemGallery?.items || [];
        const menuItemIndexInGallery = menuItemsForRemoveInGallery.findIndex(
          (menuItem) => menuItem.itemId === action.payload
        );
        if (menuItemIndexInGallery > -1) {
          draft.menuGalleryForm.menu.itemGallery?.items.splice(menuItemIndexInGallery, 1);
        }
        const menuItemChangesForRemoveInGallery = draft.menuGalleryForm.sectionItemChanges;
        const menuItemChangeIndexInGallery = menuItemChangesForRemoveInGallery.findIndex(
          (change) => change.itemId === action.payload
        );
        if (menuItemChangeIndexInGallery > -1) {
          draft.menuGalleryForm.sectionItemChanges.splice(menuItemChangeIndexInGallery, 1);
        }
        return draft;

      case MENUS_ACTIONS.ADD_ITEM_GROUP_TO_MENU_GALLERY:
        InfiniteScrollingListStateHelper.selectItemState(draft.itemsAndGroupsSelection, action.payload);
        return draft;

      case MENUS_ACTIONS.ADD_ITEM_GROUP_TO_MENU_GALLERY_FORM:
        draft.menuGalleryForm.menu.itemGallery?.items.push(action.payload);
        draft.menuGalleryForm.sectionItemChanges.push(sectionItemChange(action.payload));
        action.payload.items.forEach((item: IMenuSectionItem) => {
          draft.menuGalleryForm.sectionItemChanges.push(sectionItemChange(item));
        });
        return draft;

      case MENUS_ACTIONS.REMOVE_ITEM_GROUP_FROM_MENU_GALLERY_FORM:
        const galleryItemGroupsForRemove = draft.menuGalleryForm.menu.itemGallery!.items;
        const galleryItemGroupNamesForRemove = draft.menuGalleryForm.sectionItemChanges;
        const galleryItemGroupIndex = galleryItemGroupsForRemove.findIndex(
          (itemGroup) => itemGroup.itemId === action.payload
        );
        const galleryItemGroupNameIndex = galleryItemGroupNamesForRemove.findIndex(
          (itemGroup) => itemGroup.itemId === action.payload
        );
        if (galleryItemGroupIndex > -1) {
          galleryItemGroupsForRemove.splice(galleryItemGroupIndex, 1);
        }
        if (galleryItemGroupNameIndex > -1) {
          galleryItemGroupNamesForRemove.splice(galleryItemGroupNameIndex, 1);
        }
        return draft;

      case MENUS_ACTIONS.ADD_MENU_ITEM_TO_ITEM_GROUP_IN_ITEM_GALLERY:
        const galleryItemGroupForMenuItemAdd = draft.menuGalleryForm.menu
          .itemGallery!.items.filter((item) => item.sectionItemType === 'group')
          .find((item) => item.itemId === action.payload.itemGroupId);
        if (galleryItemGroupForMenuItemAdd) {
          (galleryItemGroupForMenuItemAdd as IMenuSectionItemGroup).items.push(action.payload.menuItem);
          draft.menuGalleryForm.sectionItemChanges.push(sectionItemChange(action.payload.menuItem));
          const galleryItemGroupChange = draft.menuGalleryForm.sectionItemChanges.find(
            (itemChange) => itemChange.itemId === action.payload.itemGroupId
          );
          if (galleryItemGroupChange) {
            galleryItemGroupChange.modified = true;
          }
        }
        return draft;

      case MENUS_ACTIONS.REMOVE_MENU_ITEM_FROM_ITEM_GROUP_IN_ITEM_GALLERY:
        const galleryItemGroupForMenuItemRemove = draft.menuGalleryForm.menu
          .itemGallery!.items.filter((item) => item.sectionItemType === 'group')
          .find((item) => item.itemId === action.payload.itemGroupId);
        if (galleryItemGroupForMenuItemRemove) {
          const menuItemInItemGroupForRemoveIndex = (
            galleryItemGroupForMenuItemRemove as IMenuSectionItemGroup
          ).items.findIndex((item) => item.itemId === action.payload.menuItemId);
          if (menuItemInItemGroupForRemoveIndex > -1) {
            (galleryItemGroupForMenuItemRemove as IMenuSectionItemGroup).items.splice(
              menuItemInItemGroupForRemoveIndex,
              1
            );
          }
          const itemGroupChange = draft.menuGalleryForm.sectionItemChanges.find(
            (itemChange) => itemChange.itemId === action.payload.itemGroupId
          );
          if (itemGroupChange) {
            itemGroupChange.modified = true;
          }
        }
        const galleryItemGroupMenuItemNamesForRemove = draft.menuGalleryForm.sectionItemChanges;
        const galleryItemGroupMenuItemNameIndex = galleryItemGroupMenuItemNamesForRemove.findIndex(
          (menuItem) => menuItem.itemId === action.payload
        );
        if (galleryItemGroupMenuItemNameIndex > -1) {
          galleryItemGroupMenuItemNamesForRemove.splice(galleryItemGroupMenuItemNameIndex, 1);
        }
        return draft;

      case MENUS_ACTIONS.UPDATE_MENU_GALLERY_SUB_ITEM_NAME:
        const { itemId: itemIdForName, displayName } = action.payload;
        const indexForName = draft.menuGalleryForm.sectionItemChanges.findIndex((i) => i.itemId === itemIdForName);
        if (typeof indexForName === 'number' && indexForName >= 0 && !!draft.menuGalleryForm.menu.itemGallery) {
          draft.menuGalleryForm.sectionItemChanges[indexForName].displayName = displayName;
          draft.menuGalleryForm.sectionItemChanges[indexForName].modified = true;
          draft.menuGalleryForm.sectionItemChanges[indexForName].validation = {
            displayName: {
              hasError: !displayName,
              errorMessage: displayName ? '' : 'Required Field.',
            },
          };
        }
        return draft;

      case MENUS_ACTIONS.UPDATE_MENU_GALLERY_SUB_ITEM_VISIBILITY:
        const { itemId, visibility } = action.payload;
        const index = draft.menuGalleryForm.menu.itemGallery?.items.findIndex((i) => i.itemId === itemId);
        if (typeof index === 'number' && index >= 0 && !!draft.menuGalleryForm.menu.itemGallery) {
          draft.menuGalleryForm.menu.itemGallery.items[index].visibility = visibility;
        }
        return draft;

      case MENUS_ACTIONS.UPDATE_MENU_GALLERY_ITEM_GROUP_SUB_ITEM_VISIBILITY:
        const { groupId, itemId: groupItemId, visibility: groupItemVisibility } = action.payload;
        const groupIndex = draft.menuGalleryForm.menu.itemGallery?.items.findIndex((i) => i.itemId === groupId);
        if (typeof groupIndex === 'number' && groupIndex >= 0 && !!draft.menuGalleryForm.menu.itemGallery) {
          const groupForItemRemoval = draft.menuGalleryForm.menu.itemGallery.items[groupIndex] as IMenuSectionItemGroup;
          const groupItemIndex = groupForItemRemoval.items.findIndex((i) => i.itemId === groupItemId);
          if (groupItemIndex >= 0) {
            groupForItemRemoval.items[groupItemIndex].visibility = groupItemVisibility;
          }
          const itemChange = draft.menuGalleryForm.sectionItemChanges.find(
            (itemChange) => itemChange.itemId === groupId
          );
          if (itemChange) {
            itemChange.modified = true;
          }
        }
        return draft;

      case MENUS_ACTIONS.REMOVE_MENU_GALLERY_SUB_ITEM:
        const itemToRemove = action.payload;
        const itemsFiltered =
          draft.menuGalleryForm.menu.itemGallery?.items.filter((i) => i.itemId !== itemToRemove) || [];
        if (!!draft.menuGalleryForm.menu.itemGallery) {
          draft.menuGalleryForm.menu.itemGallery.items = itemsFiltered;
        }
        return draft;

      case MENUS_ACTIONS.UPDATE_MENU_GALLERY_SUB_ITEM_SORT_ORDER:
        const reorderedItems =
          draft.menuGalleryForm.menu.itemGallery?.items.map((item, index) => ({
            ...item,
            sortOrder: index,
          })) || [];
        if (!!draft.menuGalleryForm.menu.itemGallery) {
          draft.menuGalleryForm.menu.itemGallery.items = reorderedItems;
        }
        return draft;

      case MENUS_ACTIONS.UPDATE_MENU_GALLERY_REORDER_SUB_ITEMS:
        const itemsForMove = draft.menuGalleryForm.menu.itemGallery?.items || [];
        const movedItem = itemsForMove.splice(action.payload.sourceIndex, 1)[0];
        itemsForMove.splice(action.payload.destinationIndex, 0, movedItem);
        return draft;

      case MENUS_ACTIONS.UPDATE_MENU_GALLERY_ITEM_GROUP_ITEM_SORT_ORDER:
        const itemGalleryGroupsForResort = draft.menuGalleryForm.sectionItemChanges;
        const itemGalleryGroupForResortIndex = itemGalleryGroupsForResort.findIndex(
          (itemGroup) => itemGroup.itemId === action.payload.groupId
        );
        if (itemGalleryGroupForResortIndex > -1) {
          const itemGalleryGroupForResort = itemGalleryGroupsForResort[itemGalleryGroupForResortIndex];
          itemGalleryGroupsForResort.splice(itemGalleryGroupForResortIndex, 1, {
            ...itemGalleryGroupForResort,
            modified: true,
          });
        }
        const itemGalleryGroupForMove = draft.menuGalleryForm.menu
          .itemGallery!.items.filter((item) => item.sectionItemType === 'group')
          .find((item) => item.itemId === action.payload.groupId);
        if (itemGalleryGroupForMove) {
          const itemGalleryGroupItemsForMove = (itemGalleryGroupForMove as IMenuSectionItemGroup).items;
          const movedItemGalleryGroupItem = itemGalleryGroupItemsForMove.splice(action.payload.sourceIndex, 1)[0];
          itemGalleryGroupItemsForMove.splice(action.payload.destinationIndex, 0, movedItemGalleryGroupItem);
        }
        return draft;

      case MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_GALLERY_VALIDATED:
        draft.menuGalleryForm.validation.displayName = action.payload;
        return draft;

      case MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_GALLERY_BEGIN:
        draft.menuGalleryForm.processing = true;
        draft.menuGalleryForm.saved = false;
        return draft;

      case MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_GALLERY_SUCCESS:
        draft.menuGalleryForm.menu = newMenu(undefined, true);
        draft.menuGalleryForm.editing = false;
        draft.menuGalleryForm.processing = false;
        draft.menuGalleryForm.saved = true;
        draft.menuGalleryForm.validation.displayName.errorMessage = '';
        draft.menuGalleryForm.validation.displayName.hasError = false;
        return draft;

      case MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_GALLERY_ERROR:
        draft.menuGalleryForm.processing = false;
        return draft;

      case MENUS_ACTIONS.MENU_GALLERY_SET_EDIT_MODE_BEGIN:
        draft.menuGalleryForm.menu._id = action.payload.itemGalleryId;
        draft.menuGalleryForm.editing = action.payload.editing;
        draft.menuGalleryForm.loading = true;
        return draft;

      case MENUS_ACTIONS.MENU_GALLERY_SET_EDIT_MODE_SUCCESS:
        draft.menuGalleryForm.menu.itemGallery = action.payload;
        draft.menuGalleryForm.menu.sortOrder = action.payload.sortOrder;
        draft.menuGalleryForm.loading = false;
        draft.menuGalleryForm.sectionItemChanges = sectionItemChangesForSection(action.payload);
        return draft;

      case MENUS_ACTIONS.MENU_GALLERY_SET_EDIT_MODE_ERROR:
        draft.menuGalleryForm.editing = false;
        draft.menuGalleryForm.menu._id = '';
        draft.menuGalleryForm.loading = false;
        return draft;

      case MENUS_ACTIONS.MENU_GALLERY_UPDATE_STATUS_BEGIN:
        draft.menuGalleryForm.processing = true;
        draft.menuGalleryForm.saved = false;
        return draft;

      case MENUS_ACTIONS.MENU_GALLERY_UPDATE_STATUS_SUCCESS:
        draft.menuGalleryForm.processing = false;
        draft.menuGalleryForm.saved = true;
        return draft;

      case MENUS_ACTIONS.MENU_GALLERY_UPDATE_STATUS_ERROR:
        draft.menuGalleryForm.processing = false;
        return draft;

      case MENUS_ACTIONS.REQUEST_DELETE_MENU_GALLERY:
        draft.menuGalleryDeleteRequested = action.payload.show;
        draft.menuGalleryToDelete.id = action.payload.itemGalleryId || '';
        draft.menuGalleryToDelete.name = action.payload.itemGalleryName || '';
        return draft;

      case MENUS_ACTIONS.DELETE_MENU_GALLERY_BEGIN:
        draft.menuGalleryDeleteProcessing = true;
        draft.menuGalleryDeleted = false;
        return draft;

      case MENUS_ACTIONS.DELETE_MENU_GALLERY_SUCCESS:
        draft.menuGalleryDeleteProcessing = false;
        draft.menuGalleryDeleted = true;
        draft.menuGalleryDeleteRequested = false;
        draft.menuGalleryToDelete = {
          id: '',
          name: '',
        };
        return draft;

      case MENUS_ACTIONS.DELETE_MENU_GALLERY_ERROR:
        draft.menuGalleryDeleteProcessing = false;
        return draft;

      case MENUS_ACTIONS.VIEW_MENU_GALLERY_BEGIN:
        draft.menuGalleryView[action.payload] = newMenuGalleryView();
        draft.menuGalleryView[action.payload].loading = true;
        draft.menuGalleryView[action.payload].visible = true;
        return draft;

      case MENUS_ACTIONS.VIEW_MENU_GALLERY_SUCCESS:
        const success_key = action.payload._id;
        draft.menuGalleryView[success_key].menuGalleryDetails = action.payload;
        draft.menuGalleryView[success_key].loading = false;
        return draft;

      case MENUS_ACTIONS.VIEW_MENU_GALLERY_ERROR:
        if (action.payload) {
          const error_key = action.payload.id;
          if (!error_key) return draft;
          draft.menuGalleryView[error_key].visible = false;
          draft.menuGalleryView[error_key].loading = false;
        }
        return draft;

      case MENUS_ACTIONS.INNER_ITEM_GALLERY_START_EDITING:
        draft.menuGalleryForm.menu.itemGallery = { ...action.payload };
        draft.menuGalleryForm.loading = false;
        draft.menuGalleryForm.editing = true;
        draft.menuGalleryForm.sectionItemChanges = sectionItemChangesForSection(action.payload);
        return draft;

      case MENUS_ACTIONS.INNER_ITEM_GALLERY_STOP_EDITING:
        draft.menuGalleryForm.editing = false;
        draft.menuGalleryForm.sectionItemChanges = [];
        return draft;

      case MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_ITEM_GALLERY_VALIDATED:
        draft.menuGalleryForm.sectionItemChanges = action.payload;
        return draft;

      case MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_ITEM_GALLERY_BEGIN:
        draft.menuGalleryForm.processing = true;
        return draft;

      case MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_ITEM_GALLERY_SUCCESS:
        draft.menuForm.menu = action.payload;
        draft.menuGalleryForm.editing = false;
        draft.menuGalleryForm.processing = false;
        draft.menuGalleryForm.saved = true;
        return draft;

      case MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_ITEM_GALLERY_ERROR:
        draft.menuGalleryForm.processing = false;
        return draft;

      case MENUS_ACTIONS.SAVE_MENU_BUILDER_UPDATED_LOCATION_ITEM_GALLERY_BEGIN:
        draft.menuGalleryForm.processing = true;
        return draft;

      case MENUS_ACTIONS.SAVE_MENU_BUILDER_UPDATED_LOCATION_ITEM_GALLERY_SUCCESS:
        draft.menuGalleryForm.processing = false;
        draft.menuGalleryView[action.payload._id].menuGalleryDetails = action.payload;
        return draft;

      case MENUS_ACTIONS.SAVE_MENU_BUILDER_UPDATED_LOCATION_ITEM_GALLERY_ERROR:
        draft.menuGalleryForm.processing = false;
        return draft;

      default:
        return draft;
    }
  });
};
