import config from '../../config';
import { ILocationPromoCodesConfig } from '@ready/dashboardv2api.contracts';
import executeApiAsync from '../../services/utils/executeApiAsync';

export default class LocationPromoCodeService {
  static BASE_URL = `${config.readyDashboardApiEndpoint}/admins/locations`;

  static loadLocationPromoCodesConfig = async (
    locationId: string,
    companyId?: string
  ): Promise<ILocationPromoCodesConfig> => {
    const url = companyId
      ? `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/promoCodesConfig`
      : `${LocationPromoCodeService.BASE_URL}/${locationId}/promoCodesConfig`;
    return await executeApiAsync<ILocationPromoCodesConfig>(url);
  };

  static saveLocationPromoCodesConfig = async (
    locationId: string,
    promoCodeConfig: ILocationPromoCodesConfig,
    companyId?: string
  ): Promise<ILocationPromoCodesConfig> => {
    const url = companyId
      ? `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/promoCodesConfig`
      : `${LocationPromoCodeService.BASE_URL}/${locationId}/promoCodesConfig`;
    return await executeApiAsync<ILocationPromoCodesConfig>(url, {
      method: 'PUT',
      body: JSON.stringify(promoCodeConfig),
    });
  };
}
