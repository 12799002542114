import produce from 'immer';
import { Action } from '../../../redux/types';
import { TRANSACTION_ACTIONS } from '../../actions/transactionActions/transactionsActionsTypes';
import { transactionsList } from '../../initialStates/transactions/transactionsList';

export const transactionsListReducer = (state = transactionsList, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case TRANSACTION_ACTIONS.GET_TRANSACTIONS_LIST_BEGIN:
        draft.loading = true;
        draft.error = false;
        return draft;

      case TRANSACTION_ACTIONS.GET_TRANSACTIONS_LIST_SUCCESS:
        draft.loading = false;
        draft.transactions = action.payload.transactions;
        draft.pagination = action.payload.pagination;
        return draft;

      case TRANSACTION_ACTIONS.GET_TRANSACTIONS_LIST_ERROR:
        draft.loading = false;
        draft.error = true;
        draft.errorMessage = 'Unable to retrieve transactions.';
        return draft;

      case TRANSACTION_ACTIONS.EXPORT_TRANSACTIONS_BEGIN:
        draft.exporting = true;
        draft.exportId = '';
        draft.checkingExport = false;
        draft.exportDownloaded = false;
        draft.error = false;
        draft.errorMessage = '';
        return draft;

      case TRANSACTION_ACTIONS.EXPORT_TRANSACTIONS_SUCCESS:
        draft.exporting = false;
        draft.exportId = action.payload;
        return draft;

      case TRANSACTION_ACTIONS.EXPORT_TRANSACTIONS_ERROR:
        draft.exporting = false;
        draft.error = true;
        draft.errorMessage = 'Unable to export transactions.';
        return draft;

      case TRANSACTION_ACTIONS.GET_TRANSACTIONS_REPORT_BEGIN:
        draft.exportId = '';
        draft.checkingExport = true;
        draft.error = false;
        return draft;

      case TRANSACTION_ACTIONS.GET_TRANSACTIONS_REPORT_SUCCESS:
        draft.checkingExport = false;
        draft.exportDownloaded = action.payload;
        return draft;

      case TRANSACTION_ACTIONS.GET_TRANSACTIONS_REPORT_ERROR:
        draft.checkingExport = false;
        draft.error = true;
        draft.errorMessage = 'Unable to retrieve exported transactions report.';
        return draft;

      case TRANSACTION_ACTIONS.UPDATE_TRANSACTIONS_LIST_URL_QUERY:
        draft.urlQuery.queryString = action.payload.queryString;
        draft.urlQuery.page = action.payload.page;
        return draft;

      default:
        return draft;
    }
  });
};
