import React from 'react';

import { IModifierGroup } from '@ready/menu.core';

import styles from './ModifierGroupListCard.module.scss';

import { Link } from 'react-router-dom';
import InfoCard from '../../../components/InfoCard/InfoCard';
import InfoCardColumn from '../../../components/InfoCard/InfoCardColumn';
import OverflowButton from '../../../components/OverflowButton/OverflowButton';
import TextIcon, { Icon } from 'components/Icon/TextIcon';

export interface ModifierGroupListCardProps {
  modifierGroup: IModifierGroup;
  linkTo: string;
  onDeleteClick: (modifierGroup: IModifierGroup) => void;
  additionalClassName?: string;
}

const ModifierGroupListCard = (props: ModifierGroupListCardProps): JSX.Element => {
  const {
    modifierGroup,
    modifierGroup: {
      name,
      displayName,
      guestInstructions,
      options,
      constraints: { min, max },
      parentTemplateId,
    },
    linkTo,
    onDeleteClick,
    additionalClassName,
  } = props;

  const displayNameStyle = displayName ? styles.displayName : styles.noDisplayName;
  const noDisplayNameText = 'No Display Name';

  const guestInstructionsStyle = guestInstructions ? styles.guestInstructions : styles.noGuestInstructions;
  const guestInstructionsText = guestInstructions || 'No Instructions';

  const optionsText = `${options.length} options`;

  const constraintsText = `MIN: ${min || 'None'} | MAX: ${max || 'Unlimited'}`;

  const overflowOptions = [
    {
      label: 'Delete Modifier Group',
      primary: true,
      onClick: () => onDeleteClick(modifierGroup),
    },
  ];

  const isSharedModGroup = !!parentTemplateId;

  return (
    <InfoCard additionalStyles={additionalClassName}>
      <Link to={linkTo} className={styles.link}>
        <InfoCardColumn width={100} columnClassName={styles.columns}>
          <div className={styles.column}>
            <div className={styles.name}>{name}</div>
          </div>

          <div className={styles.column}>
            <div className={displayNameStyle}>
              {displayName || (
                <>
                  <span className={styles.noDisplayNameMessage}>{noDisplayNameText}</span>
                  <span className={styles.noDisplayNameIndicator}>&mdash;</span>
                </>
              )}
            </div>

            <div className={guestInstructionsStyle}>{guestInstructionsText}</div>
          </div>

          <div className={styles.column}>
            <div className={styles.options}>{optionsText}</div>

            <div className={styles.constraints}>{constraintsText}</div>
          </div>
        </InfoCardColumn>
      </Link>

      <InfoCardColumn>
        {isSharedModGroup ? (
          <div className={styles.sharedIcon}>{isSharedModGroup && <TextIcon icon={Icon.Share} />}</div>
        ) : (
          <OverflowButton options={overflowOptions} />
        )}
      </InfoCardColumn>
    </InfoCard>
  );
};

export default ModifierGroupListCard;
