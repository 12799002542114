import React from 'react';
import { IMenu } from '@ready/menu.core';
import MenuItemGalleryEntryForm from './MenuItemGalleryEntryForm';
import MenuItemGalleryEntryView from './MenuItemGalleryEntryView';

export interface MenuItemGalleryEntryPanelProps {
  isEditable: boolean;
  disabled: boolean;
  item: IMenu;
  companyId: string;
  locationId: string;
  menuConfigId?:string;
  isSharedConfig?: boolean;
}

const MenuItemGalleryEntryPanel = (props: MenuItemGalleryEntryPanelProps) => {
  const { isEditable, item, disabled, companyId, locationId, menuConfigId, isSharedConfig = false } = props;

  return isEditable ? (
    <MenuItemGalleryEntryForm companyId={companyId} locationId={locationId} />
  ) : (
    <MenuItemGalleryEntryView
      companyId={companyId}
      locationId={locationId}
      item={item}
      disabled={disabled}
      isSharedConfig={isSharedConfig}
      menuConfigId={menuConfigId}

    />
  );
};

export default MenuItemGalleryEntryPanel;
