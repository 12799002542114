import React from 'react';
import styles from './TableAssignmentPanelRow.module.scss';
import { ITable } from '@ready/table.core';
import IconButton from '../../components/IconButton/IconButton';
import TextIcon, { Icon } from '../../components/Icon/TextIcon';

interface TableAssignmentPanelRowProps {
  table: ITable;
  onRemoveClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled: boolean;
  hasAllPermission?: boolean;
}

const TableAssignmentPanelRow = ({ table, onRemoveClick, disabled, hasAllPermission }: TableAssignmentPanelRowProps): JSX.Element => (
  <div className={styles.container}>
    <div className={styles.tableLabelContainer}>
      <p className={styles.tableName}>{table.tableName || table.tableNumber}</p>
      <p className={styles.revenueCenter}>{table.revenueCenter}</p>
    </div>
    {hasAllPermission &&
      <IconButton onClick={onRemoveClick} disabled={disabled}>
        <TextIcon icon={Icon.RemoveCircle} additionalStyles={styles.removeIcon} />
      </IconButton>
    }
  </div>
);

export default TableAssignmentPanelRow;
