import React from 'react';
import { FormControl } from 'components/Form';
import TextIcon, { Icon } from 'components/Icon/TextIcon';
import ImageUploader from 'components/ImageUploader/ImageUploader';
import { Tooltip } from 'components/Tooltip/Tooltip';
import styles from './BannerAndThumbnailImagesFormControls.module.scss';
import { IAsset } from '@ready/dashboardv2api.contracts';

interface BannerAndThumbnailImageProps {
  bannerImageId?: string;
  bannerImageLoading: boolean;
  uploadBannerImage: (asset: Partial<IAsset>, imageEl?: HTMLImageElement | undefined) => void;
  thumbnailImageId?: string;
  thumbnailImageLoading: boolean;
  uploadThumbnailImage: (asset: Partial<IAsset>, imageEl?: HTMLImageElement | undefined) => void;

  readonly?: boolean;
}

export const BannerAndThumbnailImagesFormControls = (props: BannerAndThumbnailImageProps) => {
  const {
    bannerImageId,
    bannerImageLoading,
    uploadBannerImage,
    thumbnailImageId,
    thumbnailImageLoading,
    uploadThumbnailImage,
    readonly = false,
  } = props;
  return (
    <>
      <FormControl label='Banner Image'>
        <div className={styles.image}>
          <ImageUploader
            readonly={readonly}
            aspectRatio='12:25'
            maxWidth={500}
            maxFileSizeKb={500}
            imageKey={bannerImageId}
            isProcessing={bannerImageLoading}
            onUpload={uploadBannerImage}
            formattedGuideText={
              <>
                <div>
                  <span>Images will be automatically scaled and cropped for optimal display</span>{' '}
                  <Tooltip
                    text='Images are displayed in a horizontal format by default. If the ‘read only’ menu setting is enabled, a vertical format will be displayed if available.'
                    tooltipOnTheLeft
                  >
                    <TextIcon icon={Icon.InfoCircle} />
                  </Tooltip>
                </div>
                <div>
                  <strong>Size: </strong>
                  <span>375px wide, 180px high, </span>
                  <strong>Format: </strong>
                  <span>JPG,PNG </span>
                  <strong>Max Size: </strong>
                  <span>500kb</span>
                </div>
              </>
            }
          />
        </div>
      </FormControl>
      <FormControl label='Thumbnail Image'>
        <div className={styles.thumbnailImage}>
          <ImageUploader
            readonly={readonly}
            aspectRatio='1:1'
            maxWidth={100}
            maxFileSizeKb={50}
            imageKey={thumbnailImageId}
            isProcessing={thumbnailImageLoading}
            onUpload={uploadThumbnailImage}
            formattedGuideText={
              <>
                <strong>Size: </strong>
                <span>200px wide, 200px high, </span>
                <strong>Format: </strong>
                <span>JPG, PNG, </span>
                <strong>Max Size: </strong>
                <span>50kb</span>
              </>
            }
          />
        </div>
      </FormControl>
    </>
  );
};
