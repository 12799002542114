import produce from 'immer';
import { Action } from '../../redux/types';
import { LOCATION_PROMO_CODES_ACTIONS } from './LocationPromoCodesActions';
import { cachedPromoCodes } from './CachedPromoCodesState';

export const cachedPromoCodesReducer = (state = cachedPromoCodes, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case LOCATION_PROMO_CODES_ACTIONS.SET_PROMO_CODES_CACHE:
        draft.config = action.payload;
        return draft;

      default:
        return draft;
    }
  });
};
