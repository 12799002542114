import { IEmbeddedMenuItem, IMenuItem, IMenuItemGroup, IModifierGroup } from '@ready/menu.core';
import {
  addMenuItemToMenuSection,
  removeMenuItemFromMenuSection,
  addItemGroupToMenuSection,
  removeItemGroupFromMenuSection,
  addMenuItemToItemGroupInMenuSection,
  removeMenuItemFromItemGroupInMenuSection,
  addMenuItemToItemGroupInItemGallery,
  removeMenuItemFromItemGroupInItemGallery,
  selectMenuItemInItemsAndGroupsModal,
  deselectMenuItemInItemsAndGroupsModal,
  selectItemGroupInItemsAndGroupsModal,
  deselectItemGroupInItemsAndGroupsModal,
} from './MenusActions';
import {
  selectItemInMenuItemsModal,
  deselectItemInMenuItemsModal,
  addMenuItemToMenuItemPairing,
  removeMenuItemFromMenuItemPairing,
  addModifierGroupToMenuItem,
  removeModifierGroupFromMenuItem,
} from './ItemsAndModsActions';
import {
  addOptionToModifierGroup,
  removeOptionFromModifierGroup,
  selectModifierGroupInModifierGroupsModal,
  deselectModifierGroupInModifierGroupsModal,
} from './ModifierGroupsActions';
import { setFormIsDirty } from '../../redux/actions/uiActions/formStateActions';
import { removeItemGroupItem, updateItemGroupAddItem } from './ItemGroupsActions';
import { AppDispatch } from 'redux/store';

export const selectMenuItemForMenuSection = (menuItem: IMenuItem) => async (dispatch: AppDispatch) => {
  dispatch(selectMenuItemInItemsAndGroupsModal(menuItem._id));
  dispatch(addMenuItemToMenuSection(menuItem));
  dispatch(setFormIsDirty(true));
};

export const deselectMenuItemFromMenuSection = (menuItemId: string) => async (dispatch: AppDispatch) => {
  dispatch(deselectMenuItemInItemsAndGroupsModal(menuItemId));
  dispatch(removeMenuItemFromMenuSection(menuItemId));
  dispatch(setFormIsDirty(true));
};

export const selectItemGroupForMenuSection = (itemGroup: IMenuItemGroup) => async (dispatch: AppDispatch) => {
  dispatch(selectItemGroupInItemsAndGroupsModal(itemGroup._id));
  dispatch(addItemGroupToMenuSection(itemGroup));
  dispatch(setFormIsDirty(true));
};

export const deselectItemGroupFromMenuSection = (itemGroupId: string) => async (dispatch: AppDispatch) => {
  dispatch(deselectItemGroupInItemsAndGroupsModal(itemGroupId));
  dispatch(removeItemGroupFromMenuSection(itemGroupId));
  dispatch(setFormIsDirty(true));
};

export const selectMenuItemForMenuSectionItemGroup = (itemGroupId: string, menuItem: IMenuItem) => async (
  dispatch: AppDispatch
) => {
  dispatch(selectItemInMenuItemsModal(menuItem._id));
  dispatch(addMenuItemToItemGroupInMenuSection(itemGroupId, menuItem));
  dispatch(setFormIsDirty(true));
};

export const deselectMenuItemFromMenuSectionItemGroup = (itemGroupId: string, menuItemId: string) => async (
  dispatch: AppDispatch
) => {
  dispatch(deselectItemInMenuItemsModal(menuItemId));
  dispatch(removeMenuItemFromItemGroupInMenuSection(itemGroupId, menuItemId));
  dispatch(setFormIsDirty(true));
};

export const selectMenuItemForItemGalleryItemGroup = (itemGroupId: string, menuItem: IMenuItem) => async (
  dispatch: AppDispatch
) => {
  dispatch(selectItemInMenuItemsModal(menuItem._id));
  dispatch(addMenuItemToItemGroupInItemGallery(itemGroupId, menuItem));
  dispatch(setFormIsDirty(true));
};

export const deselectMenuItemFromItemGalleryItemGroup = (itemGroupId: string, menuItemId: string) => async (
  dispatch: AppDispatch
) => {
  dispatch(deselectItemInMenuItemsModal(menuItemId));
  dispatch(removeMenuItemFromItemGroupInItemGallery(itemGroupId, menuItemId));
  dispatch(setFormIsDirty(true));
};

export const selectMenuItemForMenuItemPairing = (menuItem: IMenuItem) => async (dispatch: AppDispatch) => {
  dispatch(selectItemInMenuItemsModal(menuItem._id));
  dispatch(addMenuItemToMenuItemPairing(menuItem));
  dispatch(setFormIsDirty(true));
};

export const deselectMenuItemFromMenuItemPairing = (menuItemId: string) => async (dispatch: AppDispatch) => {
  dispatch(deselectItemInMenuItemsModal(menuItemId));
  dispatch(removeMenuItemFromMenuItemPairing(menuItemId));
  dispatch(setFormIsDirty(true));
};

export const selectOptionForModifierGroup = (menuItem: IMenuItem) => async (dispatch: AppDispatch) => {
  dispatch(selectItemInMenuItemsModal(menuItem._id));
  dispatch(addOptionToModifierGroup(menuItem));
  dispatch(setFormIsDirty(true));
};

export const deselectOptionFromModifierGroup = (menuItemId: string) => async (dispatch: AppDispatch) => {
  dispatch(deselectItemInMenuItemsModal(menuItemId));
  dispatch(removeOptionFromModifierGroup(menuItemId));
  dispatch(setFormIsDirty(true));
};

export const selectModifierGroupForMenuItem = (modifierGroup: IModifierGroup) => async (dispatch: AppDispatch) => {
  dispatch(selectModifierGroupInModifierGroupsModal(modifierGroup._id));
  dispatch(addModifierGroupToMenuItem(modifierGroup));
  dispatch(setFormIsDirty(true));
};

export const deselectModifierGroupFromMenuItem = (modifierGroupId: string) => async (dispatch: AppDispatch) => {
  dispatch(deselectModifierGroupInModifierGroupsModal(modifierGroupId));
  dispatch(removeModifierGroupFromMenuItem(modifierGroupId));
  dispatch(setFormIsDirty(true));
};

export const addItemToGroupItems = (item: IMenuItem) => async (dispatch: AppDispatch) => {
  const sortOrder = 1;
  const embeddedMenuItem: IEmbeddedMenuItem = {
    ...item,
    itemId: item._id,
    sortOrder,
    visibility: 1,
    posItemCategories: item.posItemCategories,
  };
  dispatch(updateItemGroupAddItem(embeddedMenuItem));
  dispatch(setFormIsDirty(true));
};

export const removeItemFromGroupItems = (itemId: string) => async (dispatch: AppDispatch) => {
  dispatch(removeItemGroupItem(itemId));
  dispatch(deselectItemInMenuItemsModal(itemId));
  dispatch(setFormIsDirty(true));
};
