import React from 'react';

import { DetailElement } from 'components/DetailLayout';
import { ITenderType, getTenderTypeName } from 'companyLocations/redux/initialStates/paymentProcessing';
import { GiftCardDetailContent } from '../GiftCardsViewPanel';
import { IGivexConfig } from '@ready/dashboardv2api.contracts';

export interface GivexViewProps extends IGivexConfig {
  tenderTypes: ITenderType[];
}

const GivexView = (props: GivexViewProps) => {
  const { userId, giftcardTenderId, addAccountDetailsCommentToPayment, tenderTypes, host, requiresPin } = props;

  return (
    <>
      <DetailElement label='Givex Host *'>
        <GiftCardDetailContent content={host} />
      </DetailElement>
      <DetailElement label='User ID *'>
        <GiftCardDetailContent content={userId} />
      </DetailElement>
      <DetailElement label='Password *'>
        <GiftCardDetailContent content='******' />
      </DetailElement>
      <DetailElement label='Requires PIN'>
        <GiftCardDetailContent content='PIN is required on checkout' disabled={!requiresPin} showIcon={true} />
      </DetailElement>
      <DetailElement label='Guest Account Details'>
        <GiftCardDetailContent
          content='Submit guest’s account number to POS along with payment'
          disabled={!addAccountDetailsCommentToPayment}
          showIcon={true}
        />
      </DetailElement>
      <DetailElement label='Gift Card Tender Type *'>
        <GiftCardDetailContent content={getTenderTypeName(tenderTypes, giftcardTenderId as string)} />
      </DetailElement>
    </>
  );
};

export default GivexView;
