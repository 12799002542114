import React from 'react';
import { FormPanelCollapsible, FormControl } from '../../../../components/Form';
import { LOCATION_SETTINGS_ACTIONS } from '../../../redux/LocationSettingsActions';
import { IUpdateLocationParam } from '../../LocationsEditPage';
import { IServerFlowPanel } from 'locations/redux/PanelFeaturesState';
import Toggle from 'components/Toggle/Toggle';

interface Props {
  isProcessing: boolean;
  serverFlowPanel: IServerFlowPanel;
  updateLocationParam: IUpdateLocationParam;
}

export const LocationPanelServerFlow = (props: Props) => {
  const { isProcessing, updateLocationParam, serverFlowPanel } = props;

  return (
    <FormPanelCollapsible
      title='Server Flow'
      initiallyExpanded={serverFlowPanel.serverFlowFeatureEnabled}
      isEnabled={serverFlowPanel.serverFlowFeatureEnabled}
      withToggle={true}
      handlePanelToggle={(toggled: boolean) => {
        updateLocationParam(toggled, LOCATION_SETTINGS_ACTIONS.TOGGLE_SERVER_FLOW_PANEL);
      }}
      loading={isProcessing}
    >
      <FormControl label='Chat Assistant'>
        <Toggle
          checked={serverFlowPanel.chatAssistantFeatureEnabled}
          onChange={(checked: boolean) => {
            updateLocationParam(checked, LOCATION_SETTINGS_ACTIONS.UPDATE_SERVER_FLOW_PANEL_CHAT_ASSISTANT_TOGGLE);
          }}
          loading={isProcessing}
        />
      </FormControl>
    </FormPanelCollapsible>
  );
};
