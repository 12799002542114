import React from 'react';
// components
import { FormControl, FormControlStacked } from '../../../components/Form';
import TextInput from '../../../components/TextInput/TextInput';
import FormattedInput from '../../../components/FormattedInput/FormattedInput';
import { IPaymentProcessingPanel } from '../../redux/PanelPaymentProcessingState';
import { LOCATION_SETTINGS_ACTIONS } from '../../redux/LocationSettingsActions';
// utils
import { toNumber } from '../../../utils/numberUtils/toNumber';
import Checkbox from '../../../components/Checkbox/Checkbox';
import { IUpdateLocationParam } from '../LocationsEditPage';

interface Props {
  isProcessing: boolean;
  updateLocationParam: IUpdateLocationParam;
  paymentProcessingPanel: IPaymentProcessingPanel;
}

const StripePP = (props: Props) => {
  const { isProcessing, updateLocationParam, paymentProcessingPanel } = props;

  return (
    <>
      <FormControl label='Account ID'>
        <TextInput
          placeholder='Enter Stripe Account ID'
          loading={isProcessing}
          value={paymentProcessingPanel.stripe.accountId}
          onChange={(event) => {
            updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_STRIPE_ACCOUNT_ID);
          }}
        />
      </FormControl>
      <FormControl label='Percentage Fee'>
        <FormattedInput
          format='percentage'
          value={paymentProcessingPanel.stripe.feePercent}
          onChange={(event) => {
            updateLocationParam(toNumber(event.target.value), LOCATION_SETTINGS_ACTIONS.UPDATE_STRIPE_PERCENTAGE_FEE);
          }}
          loading={isProcessing}
        />
      </FormControl>
      <FormControl label='Per Transaction Fee'>
        <FormattedInput
          format='number'
          customPrefix='cents'
          value={paymentProcessingPanel.stripe.feePerTxn}
          onChange={(event) => {
            updateLocationParam(
              toNumber(event.target.value),
              LOCATION_SETTINGS_ACTIONS.UPDATE_STRIPE_PER_TRANSACTION_FEE
            );
          }}
          loading={isProcessing}
        />
      </FormControl>
      <FormControl label='Payment Methods'>
        <FormControlStacked>
          <Checkbox
            checked={paymentProcessingPanel.stripe.paymentMethod.creditCard}
            label='Credit Card'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_STRIPE_PAYMENT_METHOD_CREDIT_CARD
              );
            }}
            loading={isProcessing}
          />
        </FormControlStacked>
        <FormControlStacked>
          <Checkbox
            checked={paymentProcessingPanel.stripe.paymentMethod.applePay}
            label='Apple Pay'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_STRIPE_PAYMENT_METHOD_APPLE_PAY
              );
            }}
            loading={isProcessing}
          />
        </FormControlStacked>
        <FormControlStacked>
          <Checkbox
            checked={paymentProcessingPanel.stripe.paymentMethod.googlePay}
            label='Google Pay'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              updateLocationParam(
                event.target.checked,
                LOCATION_SETTINGS_ACTIONS.UPDATE_STRIPE_PAYMENT_METHOD_GOOGLE_PAY
              );
            }}
            loading={isProcessing}
          />
        </FormControlStacked>
      </FormControl>
    </>
  );
};

export default StripePP;
