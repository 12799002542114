import { createAsyncThunk } from '@reduxjs/toolkit';
import { IAsset, IClientLocation, PaginationResponse } from '@ready/dashboardv2api.contracts';
import { ILocation } from '../../services/types/ILocation.type';
import LocationsService from '../../locations/services/LocationsService';
import MenuBuilderService from '../services/MenuBuilderService';
import { AppDispatch } from '../../redux/store';
import { pageErrorState } from '../../redux/actions/uiActions/responseStateActions';

export class LocationsThunks {
  public static loadLocations = createAsyncThunk(
    'menuBuilderLocations/loadLocations',
    async (
      args: { companyId: string; query: string; page: number },
      { dispatch }: { dispatch: AppDispatch }
    ): Promise<PaginationResponse<ILocation>> => {
      try {
        return await MenuBuilderService.getMenuBuilderLocations(args.companyId, args.query ?? '', args.page ?? 1);
      } catch (error) {
        dispatch(pageErrorState(error.status, error.message));
        throw error;
      }
    }
  );

  public static loadSelectedLocation = createAsyncThunk(
    'menuBuilderLocations/loadSelectedLocation',
    async (args: { companyId: string; locationId: string }): Promise<IClientLocation> => {
      return await LocationsService.getClientLocationById(args.companyId, args.locationId);
    }
  );
}

export type PropertyValue = string | number | boolean | Partial<IAsset>;
