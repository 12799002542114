import React, { ChangeEvent } from 'react';

import { FormControl } from '../../components/Form';
import Notification from '../../components/Notification/Notification';
import Toggle from '../../components/Toggle/Toggle';
import Checkbox from '../../components/Checkbox/Checkbox';
import TextInput from '../../components/TextInput/TextInput';
import { checkFeatureToggle } from '../../utils/featureToggle/featureToggle';

import { IBillingTab, IBillingValidation } from '../redux/LocationBillingState';
import { LOCATION_BILLING_ACTIONS } from '../redux/LocationBillingActions';
import { IUpdateLocationParam } from './LocationsEditPage';
import { Icon } from '../../components/Icon/TextIcon';

export interface Props {
  isProcessing: boolean;
  billingTab: IBillingTab;
  updateLocationParam: IUpdateLocationParam;
}

interface BillingSettingControlProps {
  isProcessing: boolean;
  label: string;
  checked: boolean;
  productKey: string;
  checkboxActionType: string;
  productKeyActionType: string;
  withError: boolean;
  updateSetting: (value: any, actionType: string) => void;
}

interface CompanyBillingSettingControlProps {
  isProcessing: boolean;
  label: string;
  checked: boolean;
  checkboxActionType: string;
  updateSetting: (value: any, actionType: string) => void;
}

const BillingSettingControl = (props: BillingSettingControlProps): JSX.Element => {
  const {
    isProcessing,
    label,
    checked,
    productKey,
    checkboxActionType,
    productKeyActionType,
    withError,
    updateSetting,
  } = props;
  return (
    <>
      <div className='control--stacked'>
        <Checkbox
          loading={isProcessing}
          label={label}
          checked={checked}
          onChange={(event: ChangeEvent<HTMLInputElement>): void => {
            updateSetting(event.target.checked, checkboxActionType);
          }}
        />
      </div>
      {checked && (
        <div className='control--stacked control--stacked--indented'>
          <TextInput
            onChange={(e: ChangeEvent<HTMLInputElement>): void => {
              updateSetting(e.target.value, productKeyActionType);
            }}
            placeholder='Enter Zuora Product Key'
            value={productKey}
            loading={isProcessing}
            withError={withError}
          />
        </div>
      )}
    </>
  );
};

const CompanyBillingSettingControl = (props: CompanyBillingSettingControlProps): JSX.Element => {
  const { isProcessing, label, checked, checkboxActionType, updateSetting } = props;
  return (
    <>
      <div className='control--stacked'>
        <Checkbox
          loading={isProcessing}
          label={label}
          checked={checked}
          onChange={(event: ChangeEvent<HTMLInputElement>): void => {
            updateSetting(event.target.checked, checkboxActionType);
          }}
        />
      </div>
    </>
  );
};

const LocationBillingTab = (props: Props) => {
  const { isProcessing, billingTab, updateLocationParam } = props;

  const validation: IBillingValidation = billingTab.validation;
  const zuoraProductKeyErrorMessage = 'Zuora product key is required.';

  const perTransactionFeeError =
    validation.transactionFee.orderTransactions.hasError || validation.transactionFee.payTransactions.hasError;
  const bpsFeeError =
    validation.BPSFee.orderTransactionsBPSFees.hasError || validation.BPSFee.payTransactionsBPSFees.hasError;
  const monthlySeesFeeError =
    validation.monthlyFees.restaurantMonthlyFee.hasError ||
    validation.monthlyFees.singleModuleFee.hasError ||
    validation.monthlyFees.multiModuleFee.hasError ||
    validation.monthlyFees.virutalKiosk.hasError ||
    validation.monthlyFees.virtualDriveThrough.hasError ||
    validation.monthlyFees.orderAhead.hasError ||
    validation.monthlyFees.payAtTable.hasError ||
    validation.monthlyFees.orderToTable.hasError;

  return (
    <div>
      {validation.hasErrors ? (
        <Notification>
          <p>
            <i className={Icon.RemoveCircle} />
            <span>Please complete all required fields</span>
          </p>
        </Notification>
      ) : (
        <div />
      )}
      <FormControl label='Billing Status'>
        <Toggle
          loading={isProcessing}
          checked={billingTab.billingEnabled}
          onChange={(checked: boolean): void => {
            updateLocationParam(checked, LOCATION_BILLING_ACTIONS.UPDATE_BILLING_ENABLED);
          }}
        />
      </FormControl>

      {checkFeatureToggle('app_companyBillingCheck') && (
        <FormControl
          label='Company Level Tiering'
          separated={true}
          showHelpInstructions={true}
          helpInstructions={
            '*Enable for locations that are invoiced separately, but where price breaks apply at the company level.'
          }
        >
          <CompanyBillingSettingControl
            isProcessing={isProcessing}
            label='Include this location in company level tiering'
            checked={billingTab.optInCompanyTiering}
            checkboxActionType={LOCATION_BILLING_ACTIONS.UPDATE_OPT_IN_COMPANY_BILLING}
            updateSetting={updateLocationParam}
          />
        </FormControl>
      )}

      <FormControl
        label='Per Transaction Fee'
        separated={true}
        withError={perTransactionFeeError}
        errorMessage={zuoraProductKeyErrorMessage}
      >
        <BillingSettingControl
          isProcessing={isProcessing}
          label='Order Transactions'
          checked={billingTab.transactionFee.orderTransactionsEnabled}
          productKey={billingTab.transactionFee.orderTransactions}
          checkboxActionType={LOCATION_BILLING_ACTIONS.UPDATE_PER_TRANSACTION_FEES_ORDER_TRANSACTIONS}
          productKeyActionType={LOCATION_BILLING_ACTIONS.UPDATE_PER_TRANSACTION_FEES_ORDER_TRANSACTIONS_PRODUCT_KEY}
          withError={validation.transactionFee.orderTransactions.hasError}
          updateSetting={updateLocationParam}
        />
        <BillingSettingControl
          isProcessing={isProcessing}
          label='Pay Transactions'
          checked={billingTab.transactionFee.payTransactionsEnabled}
          productKey={billingTab.transactionFee.payTransactions}
          checkboxActionType={LOCATION_BILLING_ACTIONS.UPDATE_PER_TRANSACTION_FEES_PAY_TRANSACTIONS}
          productKeyActionType={LOCATION_BILLING_ACTIONS.UPDATE_PER_TRANSACTION_FEES_PAY_TRANSACTIONS_PRODUCT_KEY}
          withError={validation.transactionFee.payTransactions.hasError}
          updateSetting={updateLocationParam}
        />
      </FormControl>

      <FormControl label='BPS Fees' separated={true} withError={bpsFeeError} errorMessage={zuoraProductKeyErrorMessage}>
        <BillingSettingControl
          isProcessing={isProcessing}
          label='Order Transactions BPS Fee'
          checked={billingTab.BPSFee.orderTransactionBPSFeesEnabled}
          productKey={billingTab.BPSFee.orderTransactionsBPSFees}
          checkboxActionType={LOCATION_BILLING_ACTIONS.UPDATE_BPS_FEES_ORDER_TRANSACTIONS}
          productKeyActionType={LOCATION_BILLING_ACTIONS.UPDATE_BPS_FEES_ORDER_TRANSACTIONS_PRODUCT_KEY}
          withError={validation.BPSFee.orderTransactionsBPSFees.hasError}
          updateSetting={updateLocationParam}
        />
        <BillingSettingControl
          isProcessing={isProcessing}
          label='Pay Transactions BPS Fee'
          checked={billingTab.BPSFee.payTransactionBPSFeesEnabled}
          productKey={billingTab.BPSFee.payTransactionsBPSFees}
          checkboxActionType={LOCATION_BILLING_ACTIONS.UPDATE_BPS_FEES_PAY_TRANSACTIONS}
          productKeyActionType={LOCATION_BILLING_ACTIONS.UPDATE_BPS_FEES_PAY_TRANSACTIONS_PRODUCT_KEY}
          withError={validation.BPSFee.payTransactionsBPSFees.hasError}
          updateSetting={updateLocationParam}
        />
      </FormControl>

      <FormControl
        label='Monthly SaaS Fees'
        separated={true}
        withError={monthlySeesFeeError}
        errorMessage={zuoraProductKeyErrorMessage}
      >
        <BillingSettingControl
          isProcessing={isProcessing}
          label='Restaurant Monthly SaaS Fee'
          checked={billingTab.monthlyFees.restaurantMonthlyFeeEnabled}
          productKey={billingTab.monthlyFees.restaurantMonthlyFee}
          checkboxActionType={LOCATION_BILLING_ACTIONS.UPDATE_MONTHLY_SAAS_FEES_RESTAURANT}
          productKeyActionType={LOCATION_BILLING_ACTIONS.UPDATE_MONTHLY_SAAS_FEES_RESTAURANT_PRODUCT_KEY}
          withError={validation.monthlyFees.restaurantMonthlyFee.hasError}
          updateSetting={updateLocationParam}
        />
        <BillingSettingControl
          isProcessing={isProcessing}
          label='Single Module Fee'
          checked={billingTab.monthlyFees.singleModuleFeeEnabled}
          productKey={billingTab.monthlyFees.singleModuleFee}
          checkboxActionType={LOCATION_BILLING_ACTIONS.UPDATE_MONTHLY_SAAS_FEES_SINGLE_MODULE}
          productKeyActionType={LOCATION_BILLING_ACTIONS.UPDATE_MONTHLY_SAAS_FEES_SINGLE_MODULE_PRODUCT_KEY}
          withError={validation.monthlyFees.singleModuleFee.hasError}
          updateSetting={updateLocationParam}
        />
        <BillingSettingControl
          isProcessing={isProcessing}
          label='Multi Module Fee'
          checked={billingTab.monthlyFees.multiModuleFeeEnabled}
          productKey={billingTab.monthlyFees.multiModuleFee}
          checkboxActionType={LOCATION_BILLING_ACTIONS.UPDATE_MONTHLY_SAAS_FEES_MULTI_MODULE}
          productKeyActionType={LOCATION_BILLING_ACTIONS.UPDATE_MONTHLY_SAAS_FEES_MULTI_MODULE_PRODUCT_KEY}
          withError={validation.monthlyFees.multiModuleFee.hasError}
          updateSetting={updateLocationParam}
        />
        <BillingSettingControl
          isProcessing={isProcessing}
          label='Virtual Kiosk ($0.00)'
          checked={billingTab.monthlyFees.virutalKioskEnabled}
          productKey={billingTab.monthlyFees.virutalKiosk}
          checkboxActionType={LOCATION_BILLING_ACTIONS.UPDATE_MONTHLY_SAAS_FEES_VIRTUAL_KIOSK}
          productKeyActionType={LOCATION_BILLING_ACTIONS.UPDATE_MONTHLY_SAAS_FEES_VIRTUAL_KIOSK_PRODUCT_KEY}
          withError={validation.monthlyFees.virutalKiosk.hasError}
          updateSetting={updateLocationParam}
        />
        <BillingSettingControl
          isProcessing={isProcessing}
          label='Virtual Drive Through &amp; Curbside Pickup ($0.00)'
          checked={billingTab.monthlyFees.virtualDriveThroughEnabled}
          productKey={billingTab.monthlyFees.virtualDriveThrough}
          checkboxActionType={LOCATION_BILLING_ACTIONS.UPDATE_MONTHLY_SAAS_FEES_VIRTUAL_DRIVE_THROUGH}
          productKeyActionType={LOCATION_BILLING_ACTIONS.UPDATE_MONTHLY_SAAS_FEES_VIRTUAL_DRIVE_THROUGH_PRODUCT_KEY}
          withError={validation.monthlyFees.virtualDriveThrough.hasError}
          updateSetting={updateLocationParam}
        />
        <BillingSettingControl
          isProcessing={isProcessing}
          label='Order Ahead ($0.00)'
          checked={billingTab.monthlyFees.orderAheadEnabled}
          productKey={billingTab.monthlyFees.orderAhead}
          checkboxActionType={LOCATION_BILLING_ACTIONS.UPDATE_MONTHLY_SAAS_FEES_ORDER_AHEAD}
          productKeyActionType={LOCATION_BILLING_ACTIONS.UPDATE_MONTHLY_SAAS_FEES_ORDER_AHEAD_PRODUCT_KEY}
          withError={validation.monthlyFees.orderAhead.hasError}
          updateSetting={updateLocationParam}
        />
        <BillingSettingControl
          isProcessing={isProcessing}
          label='Pay at Table ($0.00)'
          checked={billingTab.monthlyFees.payAtTableEnabled}
          productKey={billingTab.monthlyFees.payAtTable}
          checkboxActionType={LOCATION_BILLING_ACTIONS.UPDATE_MONTHLY_SAAS_FEES_PAY_AT_TABLE}
          productKeyActionType={LOCATION_BILLING_ACTIONS.UPDATE_MONTHLY_SAAS_FEES_PAY_AT_TABLE_PRODUCT_KEY}
          withError={validation.monthlyFees.payAtTable.hasError}
          updateSetting={updateLocationParam}
        />
        <BillingSettingControl
          isProcessing={isProcessing}
          label='Order to Table ($0.00)'
          checked={billingTab.monthlyFees.orderToTableEnabled}
          productKey={billingTab.monthlyFees.orderToTable}
          checkboxActionType={LOCATION_BILLING_ACTIONS.UPDATE_MONTHLY_SAAS_FEES_ORDER_TO_TABLE}
          productKeyActionType={LOCATION_BILLING_ACTIONS.UPDATE_MONTHLY_SAAS_FEES_ORDER_TO_TABLE_PRODUCT_KEY}
          withError={validation.monthlyFees.orderToTable.hasError}
          updateSetting={updateLocationParam}
        />
      </FormControl>

      <FormControl
        label='Recurring Products'
        description='*For tracking purposes only. Enabling these products will not submit usage to Zuora.'
      >
        <div className='control--stacked'>
          <Checkbox
            loading={isProcessing}
            label='READY - Waitlist'
            checked={billingTab.recurringProducts.readyWaitlistEnabled}
            onChange={(event: ChangeEvent<HTMLInputElement>): void => {
              updateLocationParam(
                event.target.checked,
                LOCATION_BILLING_ACTIONS.UPDATE_RECURRING_PRODUCTS_READY_WAITLIST
              );
            }}
          />
        </div>
        <div className='control--stacked'>
          <Checkbox
            loading={isProcessing}
            label='READY - Digital Signage'
            checked={billingTab.recurringProducts.readyDigitalSignageEnabled}
            onChange={(event: ChangeEvent<HTMLInputElement>): void => {
              updateLocationParam(
                event.target.checked,
                LOCATION_BILLING_ACTIONS.UPDATE_RECURRING_PRODUCTS_READY_DIGITAL_SIGNAGE
              );
            }}
          />
        </div>
        <div className='control--stacked'>
          <Checkbox
            loading={isProcessing}
            label='READY - Pilot Program'
            checked={billingTab.recurringProducts.readyPilotProgramEnabled}
            onChange={(event: ChangeEvent<HTMLInputElement>): void => {
              updateLocationParam(
                event.target.checked,
                LOCATION_BILLING_ACTIONS.UPDATE_RECURRING_PRODUCTS_READY_PILOT_PROGRAM
              );
            }}
          />
        </div>
      </FormControl>
    </div>
  );
};

export default LocationBillingTab;
