import { FormGroup, FormControl } from '../../../components/Form';
import { IGratuityPanel } from '../../redux/GratuityPanel/state';
import { IUIDropDown } from '../../../redux/initialStates/ui/dropDownState';
import { IUpdateLocationParam } from '../LocationsEditPage';
import { LOCATION_SETTINGS_ACTIONS } from '../../redux/LocationSettingsActions';
import { Option } from '../../../components/SelectFilter/SelectFilter';
import { selectGratuityPanel } from '../../redux/GratuityPanel/selectors';
import { toNumber } from '../../../utils/numberUtils/toNumber';
import { useAppSelector } from '../../../redux/store';
import FormattedInput from '../../../components/FormattedInput/FormattedInput';
import React, { ChangeEvent } from 'react';
import SelectFilter from '../../../components/SelectFilter/SelectFilter';
import styles from './LocationPanelGratuity.module.scss';
import TextArea from '../../../components/TextArea/TextArea';
import TextField from '../../../components/TextField/TextField';
import Toggle from '../../../components/Toggle/Toggle';
import { CustomTipType } from '@ready/dashboardv2api.contracts';

interface Props {
  updateLocationParam: IUpdateLocationParam;
  isProcessing: boolean;
  dropDown: IUIDropDown;
}

const tipCalculationListOptions: Option[] = [
  {
    label: 'Before',
    value: 'before',
  },
  {
    label: 'After',
    value: 'after',
  },
];

const defaultCustomTipOptions: Option<CustomTipType.Percentage | CustomTipType.DollarAmount>[] = [
  {
    label: 'Percentage',
    value: CustomTipType.Percentage,
  },
  {
    label: 'Dollar Amount',
    value: CustomTipType.DollarAmount,
  },
];

const getTipCalculationOptionFromValue = (value: boolean): Option =>
  value ? tipCalculationListOptions[0] : tipCalculationListOptions[1];
const getTipCalculationValueFromOption = (option: Option): boolean => (option.value === 'before' ? true : false);

const getDefaultCustomTipOtionFromValue = (value: CustomTipType): Option => {
  if (value === CustomTipType.DollarAmount) {
    return defaultCustomTipOptions[1];
  } else {
    return defaultCustomTipOptions[0];
  }
};

const LocationPanelGratuity = (props: Props) => {
  const { updateLocationParam, isProcessing } = props;
  const gratuityPanel = useAppSelector<IGratuityPanel>(selectGratuityPanel);

  return (
    <FormGroup groupName='Gratuities'>
      <FormControl label='Allow Tips'>
        <Toggle
          checked={gratuityPanel.allowTips}
          onChange={(checked: boolean) => {
            updateLocationParam(checked, LOCATION_SETTINGS_ACTIONS.TOGGLE_ALLOW_TIPS);
          }}
          loading={isProcessing}
        />
      </FormControl>

      <FormControl label='Tip Calculation'>
        <div className='control--stacked'>
          <TextField value='Calculate tips based on the total amount:' />
        </div>
        <div className='control--stacked'>
          <div className={styles.controlWidth}>
            <SelectFilter
              options={tipCalculationListOptions}
              value={getTipCalculationOptionFromValue(gratuityPanel.preDiscountTip)}
              onChange={(option: Option) => {
                updateLocationParam(
                  getTipCalculationValueFromOption(option),
                  LOCATION_SETTINGS_ACTIONS.UPDATE_PRE_DISCOUNT_TIP
                );
              }}
              loading={isProcessing}
            ></SelectFilter>
          </div>
          <TextField value='Check discounts'></TextField>
        </div>
        <div className='control--stacked'>
          <div className={styles.controlWidth}>
            <SelectFilter
              options={tipCalculationListOptions}
              value={getTipCalculationOptionFromValue(gratuityPanel.preItemDiscountTip)}
              onChange={(option: Option) => {
                updateLocationParam(
                  getTipCalculationValueFromOption(option),
                  LOCATION_SETTINGS_ACTIONS.UPDATE_PRE_ITEM_DISCOUNT_TIP
                );
              }}
              loading={isProcessing}
            ></SelectFilter>
          </div>
          <TextField value='Item discounts'></TextField>
        </div>
        <div className='control--stacked'>
          <div className={styles.controlWidth}>
            <SelectFilter
              options={tipCalculationListOptions}
              value={getTipCalculationOptionFromValue(gratuityPanel.preTaxTip)}
              onChange={(option: Option) => {
                updateLocationParam(
                  getTipCalculationValueFromOption(option),
                  LOCATION_SETTINGS_ACTIONS.UPDATE_PRE_TAX_TIP
                );
              }}
              loading={isProcessing}
            ></SelectFilter>
          </div>
          <TextField value='Tax'></TextField>
        </div>
        <div className='control--stacked'>
          <div className={styles.controlWidth}>
            <SelectFilter
              options={tipCalculationListOptions}
              value={getTipCalculationOptionFromValue(gratuityPanel.preNonReadyPaymentsTip)}
              onChange={(option: Option) => {
                updateLocationParam(
                  getTipCalculationValueFromOption(option),
                  LOCATION_SETTINGS_ACTIONS.UPDATE_PRE_NON_READY_PAYMENTS_TIP
                );
              }}
              loading={isProcessing}
            ></SelectFilter>
          </div>
          <TextField value='Non-Ready payments'></TextField>
        </div>
      </FormControl>
      <FormControl label='Default Tip Values'>
        <div className={styles.defaultTipValueContainer}>
          <div className={styles.controlWidth}>
            <FormattedInput
              format='percentage'
              value={gratuityPanel.defaultTips[0]}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const newval = {
                  tip_value: toNumber(e.target.value),
                  tip_index: 0,
                };
                updateLocationParam(newval, LOCATION_SETTINGS_ACTIONS.UPDATE_TIP_VALUE);
              }}
              placeholder='15'
              loading={isProcessing}
            />
          </div>
          <div className={styles.controlWidth}>
            <FormattedInput
              format='percentage'
              value={gratuityPanel.defaultTips[1]}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const newval = {
                  tip_value: toNumber(e.target.value),
                  tip_index: 1,
                };
                updateLocationParam(newval, LOCATION_SETTINGS_ACTIONS.UPDATE_TIP_VALUE);
              }}
              placeholder='18'
              loading={isProcessing}
            />
          </div>
          <div className={styles.controlWidth}>
            <FormattedInput
              format='percentage'
              value={gratuityPanel.defaultTips[2]}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const newval = {
                  tip_value: toNumber(e.target.value),
                  tip_index: 2,
                };
                updateLocationParam(newval, LOCATION_SETTINGS_ACTIONS.UPDATE_TIP_VALUE);
              }}
              placeholder='20'
              loading={isProcessing}
            />
          </div>
        </div>
      </FormControl>

      <FormControl
        label='Default Tip Values for Service Charges'
        labelTooltip='These defaults will be displayed to guests if a service charge is present on the check. Otherwise, the regular Default Tip Values will be displayed.'
      >
        <div className={styles.defaultTipValueContainer}>
          <div className={styles.controlWidth}>
            <FormattedInput
              format='percentage'
              value={gratuityPanel.defaultTipsForServiceCharges[0]}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const newval = {
                  service_tip_value: toNumber(e.target.value),
                  service_tip_index: 0,
                };
                updateLocationParam(newval, LOCATION_SETTINGS_ACTIONS.UPDATE_SERVICE_CHARGE_TIP_VALUE);
              }}
              placeholder='0'
              loading={isProcessing}
            />
          </div>
          <div className={styles.controlWidth}>
            <FormattedInput
              format='percentage'
              value={gratuityPanel.defaultTipsForServiceCharges[1]}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const newval = {
                  service_tip_value: toNumber(e.target.value),
                  service_tip_index: 1,
                };
                updateLocationParam(newval, LOCATION_SETTINGS_ACTIONS.UPDATE_SERVICE_CHARGE_TIP_VALUE);
              }}
              placeholder='2'
              loading={isProcessing}
            />
          </div>
          <div className={styles.controlWidth}>
            <FormattedInput
              format='percentage'
              value={gratuityPanel.defaultTipsForServiceCharges[2]}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const newval = {
                  service_tip_value: toNumber(e.target.value),
                  service_tip_index: 2,
                };
                updateLocationParam(newval, LOCATION_SETTINGS_ACTIONS.UPDATE_SERVICE_CHARGE_TIP_VALUE);
              }}
              placeholder='5'
              loading={isProcessing}
            />
          </div>
        </div>
      </FormControl>

      <FormControl label='Default “Custom Tip” Selection'>
        <div className={styles.defaultCustomTip}>
          <SelectFilter
            options={defaultCustomTipOptions}
            value={getDefaultCustomTipOtionFromValue(gratuityPanel.defaultCustomTipType)}
            onChange={(option) => {
              updateLocationParam(option.value, LOCATION_SETTINGS_ACTIONS.UPDATE_DEFAULT_CUSTOM_TIP);
            }}
            loading={isProcessing}
            additionalStyles={styles.selectContainerStyles}
          />
        </div>
      </FormControl>

      <FormControl label='Display Custom Message'>
        <Toggle
          checked={gratuityPanel.showBottomText}
          onChange={(checked: boolean) => {
            updateLocationParam(checked, LOCATION_SETTINGS_ACTIONS.TOGGLE_SHOW_BOTTOM);
          }}
          loading={isProcessing}
        />
        <TextArea
          disabled={!gratuityPanel.showBottomText}
          value={gratuityPanel.bottomText ?? ''}
          onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
            updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_BOTTOM_TEXT)
          }
          maxLength={200}
        ></TextArea>
      </FormControl>
    </FormGroup>
  );
};

export default LocationPanelGratuity;
