import { initialFeedbackState } from './initialState';
import { createSlice } from '@reduxjs/toolkit';
import {
  exportFeedbacksListThunk,
  fetchFeedbacksListThunk,
  fetchGuestSatisfactionThunk,
  fetchPermittedLocationsThunk,
  fetchStarRatingThunk,
} from './thunks';
import {
  fetchFeedbacksListReducers,
  fetchStarRatingReducers,
  fetchGuestSatisfactionReducers,
  fetchPermittedLocationsReducers,
  exportFeedbacksListReducers,
} from './reducers';

const feedbackReducerSlice = createSlice({
  name: 'feedback',
  initialState: initialFeedbackState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFeedbacksListThunk.pending, fetchFeedbacksListReducers.pending);
    builder.addCase(fetchFeedbacksListThunk.fulfilled, fetchFeedbacksListReducers.fulfilled);
    builder.addCase(fetchFeedbacksListThunk.rejected, fetchFeedbacksListReducers.rejected);

    builder.addCase(exportFeedbacksListThunk.pending, exportFeedbacksListReducers.pending);
    builder.addCase(exportFeedbacksListThunk.fulfilled, exportFeedbacksListReducers.fulfilled);
    builder.addCase(exportFeedbacksListThunk.rejected, exportFeedbacksListReducers.rejected);

    builder.addCase(fetchStarRatingThunk.pending, fetchStarRatingReducers.pending); //
    builder.addCase(fetchStarRatingThunk.fulfilled, fetchStarRatingReducers.fulfilled);
    builder.addCase(fetchStarRatingThunk.rejected, fetchStarRatingReducers.rejected);

    builder.addCase(fetchGuestSatisfactionThunk.pending, fetchGuestSatisfactionReducers.pending);
    builder.addCase(fetchGuestSatisfactionThunk.fulfilled, fetchGuestSatisfactionReducers.fulfilled);
    builder.addCase(fetchGuestSatisfactionThunk.rejected, fetchGuestSatisfactionReducers.rejected);

    builder.addCase(fetchPermittedLocationsThunk.pending, fetchPermittedLocationsReducers.pending);
    builder.addCase(fetchPermittedLocationsThunk.fulfilled, fetchPermittedLocationsReducers.fulfilled);
    builder.addCase(fetchPermittedLocationsThunk.rejected, fetchPermittedLocationsReducers.rejected);
  },
});

const { actions: feedbackActions, reducer: feedbackReducer } = feedbackReducerSlice;
export { feedbackActions, feedbackReducer };
