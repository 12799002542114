import {
  IGiftCardConfig,
  GiftCardProvider,
  IClientLocation,
  IPayPalOptions,
  PayPalStatus,
} from '@ready/dashboardv2api.contracts';
import { givexSlice } from 'companyLocations/pages/paymentProcessing/giftcards/givex/redux/slice';
import { checkFeatureToggle } from 'utils/featureToggle/featureToggle';
import { Option } from '../../../components/SelectFilter/SelectFilter';

// PayPal
export interface IPayPalUnlink {
  requested: boolean;
  loading: boolean;
}

export interface IPayPalInfo {
  initialized: boolean;
  link?: string;
  loading: boolean;
  options: IPayPalOptions;
  unlink: IPayPalUnlink;
}

export const newPayPalProvider = (): IPayPalInfo => ({
  // the dashboard will make an API call to initialize PayPal when initialized value is false
  // this ensures that no API call made to PayPal when the feature toggle is on.

  // TODO: remove the line below the comment and uncomment "initialized: false"
  // once the PayPal feature is ready to release
  // initialized: false,
  initialized: !checkFeatureToggle('app_payPalPanel'),
  link: '',
  loading: false,
  options: {
    status: PayPalStatus.Disconnected,
    paypalMerchantId: '',
  },
  unlink: {
    requested: false,
    loading: false,
  },
});

// GiftCards

export const noneOption: Option = { label: 'None', value: 'none' };

export const GiftCardProviderLabels = {
  [GiftCardProvider.Givex]: 'Givex',
};

export interface IGivexValidation {}
export type IGiftCardValidation = IGivexValidation;

export interface IGiftCardConfigurationPanel {
  initialized: boolean;
  editable: boolean;
  processing: boolean;
  tenderTypes: ITenderType[];
  posIntegration: string;
  validated: boolean;
  view?: IGiftCardConfig;
  edit?: IGiftCardConfig;
  validation?: IGiftCardValidation;
}

export interface ISupportedGiftCardProviderList {
  [key: string]: GiftCardProvider[];
}

export const SupportedGiftCardProviderList: ISupportedGiftCardProviderList = {
  omnivore: [GiftCardProvider.Givex],
};

export const getGiftCardInitConfigStates = (provider: GiftCardProvider): IGiftCardConfig | undefined => {
  const initStates = {
    [GiftCardProvider.Givex]: givexSlice.getInitialState().options,
  };

  return initStates[provider]
    ? {
        ...initStates[provider],
      }
    : undefined;
};

export const getGiftCardInitValidationStates = (provider: GiftCardProvider) => {
  const initStates = {
    [GiftCardProvider.Givex]: {}, // because validation for givex occurs in the form instead
  };

  return initStates[provider];
};

export const getTenderTypeName = (tenderTypes: ITenderType[], id: string): string | undefined => {
  const result = tenderTypes.filter((tenderType) => tenderType.id === id);
  return result.length > 0 ? result[0].name : undefined;
};

// Payment Process
export interface ITenderType {
  name: string;
  id: string;
}

export interface IPaymentProcessingState {
  location?: IClientLocation;
  loading: boolean;
  error: boolean;
  providers: {
    paypal: IPayPalInfo;
    giftCard: IGiftCardConfigurationPanel;
  };
}

export const paymentProcessingSate: IPaymentProcessingState = {
  loading: false,
  error: false,
  providers: {
    paypal: newPayPalProvider(),
    giftCard: {
      initialized: false,
      editable: false,
      processing: false,
      tenderTypes: [],
      posIntegration: '',
      validated: false,
    },
  },
};
