import React, { ChangeEvent } from 'react';
import { FormGroup, FormControl } from '../../components/Form';
import Toggle from '../../components/Toggle/Toggle';
import { INewsletterPanel, INewsletterPanelValidation } from '../redux/PanelNewsletterState';
import { LOCATION_SETTINGS_ACTIONS } from '../redux/LocationSettingsActions';
import TextInput from '../../components/TextInput/TextInput';
import { IUpdateLocationParam } from './LocationsEditPage';

interface Props {
  updateLocationParam: IUpdateLocationParam;
  newsletter: INewsletterPanel;
  isProcessing: boolean;
}

const LocationPanelNewsletter = (props: Props) => {
  const { updateLocationParam, newsletter, isProcessing } = props;
  const validation: INewsletterPanelValidation = newsletter.validation;
  const newsletterPromptTextValidation = validation.newsletter.newsletterPromptText;
  const newsletterSuccessTextValidation = validation.newsletter.newsletterSuccessText;
  const newsLetterFeatureIsOn = newsletter.showNewsletterSubCheckbox;
  return (
    <FormGroup groupName='Newsletter'>
      <FormControl label='Ask Guests to Sign Up'>
        <Toggle
          checked={newsLetterFeatureIsOn}
          onChange={(checked: boolean) => {
            updateLocationParam(checked, LOCATION_SETTINGS_ACTIONS.TOGGLE_SHOW_NEWSLETTER_SUB_CHECKBOX);
          }}
          loading={isProcessing}
        />
      </FormControl>
      <FormControl
        label='Sign Up Prompt Message *'
        errorMessage={newsletterPromptTextValidation.error}
        withError={newsletterPromptTextValidation.hasError}
      >
        <TextInput
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            updateLocationParam(e.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_NEWSLETTER_PROMPT_TEXT);
          }}
          placeholder={'e.g. Sign me up to receive newsletters'}
          value={newsletter.newsletterPromptText ?? ''}
          withError={newsletterPromptTextValidation.hasError}
          loading={isProcessing}
          disabled={!newsLetterFeatureIsOn}
        />
      </FormControl>
      <FormControl
        label='Sign Up Success Message *'
        errorMessage={newsletterSuccessTextValidation.error}
        withError={newsletterSuccessTextValidation.hasError}
      >
        <TextInput
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            updateLocationParam(e.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_NEWSLETTER_SUCCESS_TEXT);
          }}
          placeholder={'e.g. Success! You’re now signed up to receive newsletters'}
          value={newsletter.newsletterSuccessText ?? ''}
          loading={isProcessing}
          withError={newsletterSuccessTextValidation.hasError}
          disabled={!newsLetterFeatureIsOn}
        />
      </FormControl>
    </FormGroup>
  );
};

export default LocationPanelNewsletter;
