import { IRevenueCenter } from '@ready/table.core';
import { REVENUE_CENTERS_ACTIONS } from './types';
import { toastErrorState } from '../../../redux/actions/uiActions/responseStateActions';
import { TableAssignmentService } from '../../../services/tableAssignmentService';

export const getRevenueCenters =
  (companyId: string = '', locationId: string = '') =>
  async (dispatch: any) => {
    dispatch(getRevenueCentersBegin());
    try {
      const revenueCenters = await TableAssignmentService.listRevenueCenters(companyId, locationId);
      dispatch(getRevenueCentersSuccess(revenueCenters));
    } catch (error) {
      dispatch(getRevenueCentersError());
      dispatch(toastErrorState(error.status, error.message));
    }
  };

export const getRevenueCentersBegin = () => ({
  type: REVENUE_CENTERS_ACTIONS.GET_REVENUE_CENTERS_BEGIN,
});

export const getRevenueCentersSuccess = (results: IRevenueCenter[]) => ({
  type: REVENUE_CENTERS_ACTIONS.GET_REVENUE_CENTERS_SUCCESS,
  payload: results,
});

export const getRevenueCentersError = () => ({
  type: REVENUE_CENTERS_ACTIONS.GET_REVENUE_CENTERS_ERROR,
});
