import React from 'react';
import { startOfMonth, subMonths } from 'date-fns';
import classnames from 'classnames';
import { CellProps, createCellsWithMobileLayout } from '../../components/TableV2/Cell/Cell';
import { createSortableHeaderCell } from '../../components/TableV2/Cell/HeaderCell';
import { locationPerformanceHeaderColumnsSelector } from './redux/selectors';
import { createNullableCell, DollarCell, TextCell } from '../../components/TableV2/Cell/SpecialCells';
import MissingValue from '../../components/Value/MissingValue';
import styles from './LocationPerformance.module.scss';

const Cell = (props: CellProps) => <TextCell align='right' {...props} />;

const CellMissingValue = ({ rowData, columnName, ...restProps }: CellProps) => {
  return (
    <Cell
      {...restProps}
      rowData={{
        ...rowData,
        [columnName]: <MissingValue />,
      }}
      columnName={columnName}
    />
  );
};

const PercentCell = ({ rowData, columnName, ...restProps }: CellProps) => {
  return (
    <Cell
      {...restProps}
      rowData={{
        ...rowData,
        [columnName]: `${rowData[columnName]} %`,
      }}
      columnName={columnName}
    />
  );
};

const CellNumberToLocaleString = ({ rowData, columnName, ...restProps }: CellProps) => {
  return (
    <Cell
      {...restProps}
      rowData={{
        ...rowData,
        [columnName]: `${rowData[columnName].toLocaleString()}`,
      }}
      columnName={columnName}
    />
  );
};

const cells = createCellsWithMobileLayout(
  {
    locationName: (props: CellProps) => (
      <Cell {...props} align='left' classes={classnames(props.classes, styles.paddingLeftDesktop50)} />
    ),
    scansCount: (props: CellProps) => (
      <CellNumberToLocaleString {...props} classes={classnames(props.classes, styles.MinWidth)} />
    ),
    conversionRatePercent: createNullableCell(PercentCell, CellMissingValue),
    readyChecksCount: CellNumberToLocaleString,
    readyChecksSum: DollarCell,
    readyPaymentsSum: DollarCell,
    readyCheckAvg: createNullableCell(DollarCell, CellMissingValue),
    readyTableTurnAvg: createNullableCell(Cell, CellMissingValue),
    preTaxTipAvgPercent: createNullableCell(PercentCell, CellMissingValue),
    ratingsCount: CellNumberToLocaleString,
    ratingAvg: createNullableCell(Cell, CellMissingValue),
    surveyRatePercent: createNullableCell(PercentCell, CellMissingValue),
  },
  locationPerformanceHeaderColumnsSelector,
  {
    titleClasses: styles.MobileHeaderCell,
  }
);

const headerCells = {
  locationName: createSortableHeaderCell('locationName'),
  scansCount: createSortableHeaderCell('scansCount', { align: 'right' }),
  conversionRatePercent: createSortableHeaderCell('conversionRatePercent', { align: 'right' }),
  readyChecksCount: createSortableHeaderCell('readyChecksCount', { align: 'right' }),
  readyChecksSum: createSortableHeaderCell('readyChecksSum', { align: 'right' }),
  readyPaymentsSum: createSortableHeaderCell('readyPaymentsSum', { align: 'right' }),
  readyCheckAvg: createSortableHeaderCell('readyCheckAvg', { align: 'right' }),
  readyTableTurnAvg: createSortableHeaderCell('readyTableTurnAvg', { align: 'right' }),
  preTaxTipAvgPercent: createSortableHeaderCell('preTaxTipAvgPercent', { align: 'right' }),
  ratingAvg: createSortableHeaderCell('ratingAvg', { align: 'right' }),
  ratingsCount: createSortableHeaderCell('ratingsCount', { align: 'right' }),
  surveyRatePercent: createSortableHeaderCell('surveyRatePercent', { align: 'right' }),
};

const endMonth = startOfMonth(new Date());
const startMonth = subMonths(endMonth, 1);
const initDateFilter = { endMonth, startMonth };

const maxDaysToChoose = 366;

export { headerCells, cells, initDateFilter, maxDaysToChoose };
