export const SERVER_ASSIGNMENT_ACTIONS = {
  SET_SERVER_ASSIGNMENT_LOCATIONS_LOADING: 'SET_SERVER_ASSIGNMENT_LOCATIONS_LOADING',
  SET_SERVER_ASSIGNMENT_LOCATIONS: 'SET_SERVER_ASSIGNMENT_LOCATIONS',

  SET_SERVER_ASSIGNMENT_LOCATION_DETAILS_LOADING: 'SET_SERVER_ASSIGNMENT_LOCATION_DETAILS_LOADING',
  SET_SERVER_ASSIGNMENT_LOCATION_DETAILS: 'SET_SERVER_ASSIGNMENT_LOCATION_DETAILS',
  INIT_SERVER_ASSIGNMENT_LOCATION_DETAILS: 'INIT_SERVER_ASSIGNMENT_LOCATION_DETAILS',

  SET_REMOVE_SERVER_ASSIGNMENT_MODAL_OPEN: 'SET_REMOVE_SERVER_ASSIGNMENT_MODAL_OPEN',

  // Tables panel actions
  TABLE_LIST_SELECT_TABLE: 'TABLE_LIST_SELECT_TABLE',
  TABLE_LIST_DESELECT_TABLE: 'TABLE_LIST_DESELECT_TABLE',
  TABLE_LIST_DESELECT_ALL_TABLES: 'TABLE_LIST_DESELECT_ALL_TABLES',
  SET_TABLE_LIST_LOADING: 'SET_TABLE_LIST_LOADING',
  SET_TABLE_LIST: 'SET_TABLE_LIST',
  SET_TABLE_ASSIGNMENT_LIST: 'SET_TABLE_ASSIGNMENT_LIST',
  INIT_TABLE_LIST: 'INIT_TABLE_LIST',
  SET_SERVER_ASSIGNMENT_PAGE_PROCESSING: 'SET_SERVER_ASSIGNMENT_PAGE_PROCESSING',

  // Assignment modal actions
  INIT_SERVER_ASSIGNMENT_ASSIGN_MODAL: 'INIT_SERVER_ASSIGNMENT_ASSIGN_MODAL',
  SERVER_ASSIGNMENT_ASSIGN_MODAL_OPEN: 'SERVER_ASSIGNMENT_ASSIGN_MODAL_OPEN',
  SERVER_ASSIGNMENT_ASSIGN_MODAL_CLOSE: 'SERVER_ASSIGNMENT_ASSIGN_MODAL_CLOSE',
  SET_SERVER_ASSIGNMENT_ASSIGN_MODAL_QUERY: 'SET_SERVER_ASSIGNMENT_ASSIGN_MODAL_QUERY',
  SET_SERVER_ASSIGNMENT_ASSIGN_MODAL_SELECTED_SERVER: 'SET_SERVER_ASSIGNMENT_ASSIGN_MODAL_SELECTED_SERVER',
  SET_SERVER_LIST_LOADING: 'SET_SERVER_LIST_LOADING',
  SET_SERVER_LIST_PAGINATION_LOADING: 'SET_SERVER_LIST_PAGINATION_LOADING',
  SET_SERVER_LIST: 'SET_SERVER_LIST',
  APPEND_SERVER_LIST: 'APPEND_SERVER_LIST',
  INIT_SERVER_LIST: 'INIT_SERVER_LIST',
  SET_SERVER_ASSIGNMENT_MODAL_PROCESSING: 'SET_SERVER_ASSIGNMENT_MODAL_PROCESSING',
};
