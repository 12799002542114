import React from 'react';
import CompanyTableRow from './CompanyTableRow';
import CompanyTableCell from './CompanyTableCell';
import Skeleton from '../../../components/Skeleton/Skeleton';

export interface CompanyTableRowsSkeletonProps {
  colsWidth: number[];
  withOrderType: boolean;
  rows?: number;
}

const renderCells = (colsWidth: number[]): JSX.Element[] => {
  return colsWidth.map((width: number, index: number) => {
    if (width) {
      return (
        <CompanyTableCell key={index} width={width}>
          <Skeleton />
        </CompanyTableCell>
      );
    }

    return <></>;
  });
};

const renderRows = (rows: number, colsWidth: number[], withOrderType: boolean): JSX.Element[] => {
  const cells = renderCells(colsWidth);
  const rowsArr = [];
  for (let i = 0; i < rows; i++) {
    rowsArr.push(
      <CompanyTableRow key={i} withOrderType={withOrderType} skeleton>
        {cells}
      </CompanyTableRow>
    );
  }
  return rowsArr;
};

const CompanyTableRowsSkeleton = (props: CompanyTableRowsSkeletonProps): JSX.Element => {
  const { colsWidth, withOrderType, rows = 1 } = props;

  return <>{renderRows(rows, colsWidth, withOrderType)}</>;
};

export default CompanyTableRowsSkeleton;
