import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { ICompanyLoyaltyConfig } from '@ready/dashboardv2api.contracts';
import { IPunchhOptions } from '@ready/dashboardv2api.contracts/dist/src/contracts/api/companyLoyaltyConfig/punchh/IPunchhOptions';

import { getCompanyLoyaltyConfigThunk } from './thunks/getCompanyLoyaltyConfigThunk';
import { saveCompanyLoyaltyConfigThunk } from './thunks/saveCompanyLoyaltyConfigThunk';

interface State {
  loyaltyConfigs: {
    punchh?: IPunchhOptions;
  };
  isLoading: boolean;
  isSaving: boolean;
}

const initialState: State = {
  loyaltyConfigs: {
    punchh: undefined,
  },
  isLoading: false,
  isSaving: false,
};

const loyaltySlice = createSlice({
  name: 'companySettings/loyalty',
  initialState: initialState,
  reducers: {
    resetLoyaltyState: () => initialState,
  },
  extraReducers: (builder: ActionReducerMapBuilder<State>) => {
    builder.addCase(getCompanyLoyaltyConfigThunk.pending, (state: State) => {
      state.isLoading = true;
    });

    builder.addCase(getCompanyLoyaltyConfigThunk.rejected, (state: State) => {
      state.isLoading = false;
    });

    builder.addCase(
      getCompanyLoyaltyConfigThunk.fulfilled,
      (state: State, action: { payload: ICompanyLoyaltyConfig }) => {
        if (action.payload) {
          const companyLoyaltyConfig = action.payload;

          if (companyLoyaltyConfig.options?.provider === 'punchh') {
            state.loyaltyConfigs.punchh = companyLoyaltyConfig.options;
          }
        }
        state.isLoading = false;
      }
    );

    builder.addCase(saveCompanyLoyaltyConfigThunk.pending, (state: State) => {
      state.isSaving = true;
    });

    builder.addCase(saveCompanyLoyaltyConfigThunk.rejected, (state: State) => {
      state.isSaving = false;
    });

    builder.addCase(
      saveCompanyLoyaltyConfigThunk.fulfilled,
      (state: State, action: { payload: ICompanyLoyaltyConfig }) => {
        if (action.payload) {
          const companyLoyaltyConfig = action.payload;

          if (companyLoyaltyConfig.options?.provider === 'punchh') {
            state.loyaltyConfigs.punchh = companyLoyaltyConfig.options;
          }
        }
        state.isSaving = false;
      }
    );
  },
});

export const { resetLoyaltyState } = loyaltySlice.actions;

export { loyaltySlice };
