import { ILocationPicker } from '../../redux/initialStates/companyLocations/companyLocations';

export interface IServerAssignmentLocationsState {
  locationPicker: ILocationPicker;
}

export const serverAssignmentLocationsState: IServerAssignmentLocationsState = {
  locationPicker: {
    loading: false,
    pagination: {
      start: 0,
      length: 0,
      limit: 0,
      total: 0,
    },
    locations: [],
  },
} as IServerAssignmentLocationsState;
