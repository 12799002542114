import React from 'react';
import IconButton from '../../components/IconButton/IconButton';
import TextIcon, { Icon } from '../../components/Icon/TextIcon';

export interface TaxItemCardButtonsProps {
  handleEditClicked: () => void;
  handleDeleteClicked: () => void;
}

const TaxItemCardButtons = ({ handleDeleteClicked, handleEditClicked }: TaxItemCardButtonsProps) => {
  return (
    <>
      <IconButton onClick={handleEditClicked}>
        <TextIcon icon={Icon.Pencil} />
      </IconButton>
      <IconButton onClick={handleDeleteClicked}>
        <TextIcon icon={Icon.TrashCan} />
      </IconButton>
    </>
  );
};

export default TaxItemCardButtons;
