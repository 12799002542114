import React from 'react';
import styles from './SimpleExpander.module.scss';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';

interface SimpleExpanderProps {
  isExpanded: boolean;
  onChange: (isExpanded: boolean) => void;
}

const SimpleExpander = ({ isExpanded, onChange }: SimpleExpanderProps): JSX.Element => {
  const onClick = (): void => {
    onChange(!isExpanded);
  };

  return (
    <div className={styles.container} onClick={onClick}>
      <TextIcon icon={isExpanded ? Icon.CaretDown : Icon.CaretRight} />
    </div>
  );
};

export default SimpleExpander;
