import {
  ILocationPaymentProcessorBamboraConfig,
  ILocationPaymentProcessorFreedomPayConfig,
  ILocationPaymentProcessorIqpayConfig,
  ILocationPaymentProcessorMonerisConfig,
  ILocationPaymentProcessorStripeConfig,
  ILocationPaymentProcessorRealexConfig,
  ILocationPaymentProcessorSquareConfig,
  PaymentProcessorProvider,
  GooglePayEnvironment,
  ILocationPaymentProcessorJetpayConfig,
  ILocationPaymentProcessorAdyenConfig,
  AdyenEnvironment,
  AdyenPricingModel,
} from '@ready/dashboardv2api.contracts';

export interface IPaymentProcessingPanel {
  paymentQueues: boolean;
  providerName: string;
  stripe: ILocationPaymentProcessorStripeConfig;
  moneris: ILocationPaymentProcessorMonerisConfig;
  iqpay: ILocationPaymentProcessorIqpayConfig;
  freedompay: ILocationPaymentProcessorFreedomPayConfig;
  realex: ILocationPaymentProcessorRealexConfig;
  jetpay: ILocationPaymentProcessorJetpayConfig;
  square: ILocationPaymentProcessorSquareConfig;
  bambora: ILocationPaymentProcessorBamboraConfig;
  adyen: ILocationPaymentProcessorAdyenConfig;
}
const environmentCheck =
  process.env.NODE_ENV === 'production' ? GooglePayEnvironment.Production : GooglePayEnvironment.Test;

export const paymentProcessingPanel: IPaymentProcessingPanel = {
  paymentQueues: true,
  providerName: PaymentProcessorProvider.Stripe,
  stripe: {
    accountId: '',
    feePercent: 2.9,
    feePerTxn: 30,
    paymentMethod: {
      applePay: true,
      creditCard: true,
      googlePay: true,
    },
  },
  moneris: {
    apiToken: '',
    storeId: '',
    hppToken: '',
    applePayMerchantId: '',
    googlePayMerchantId: '',
    paymentMethod: {
      applePay: true,
      creditCard: true,
      googlePay: true,
    },
    appleGoogleNetwork: {
      mastercard: true,
      visa: true,
      amex: true,
      discover: true,
      interac: true,
      jcb: true,
    },
    rateLimits: {
      enforceRateLimitsForGooglePay: false,
      enabled: false,
      amount: 100,
      duration: 60,
    },
    allowSmallTransactions: false,
  },
  iqpay: {
    profileId: '',
    paymentMethod: {
      creditCard: true,
      applePay: true,
      googlePay: true,
    },
    appleGoogleNetwork: {
      mastercard: true,
      visa: true,
      amex: true,
      discover: true,
      jcb: true,
    },
    applePay: {
      merchantId: '',
      supports3DS: true,
      supportsDebit: true,
      supportsCredit: true,
    },
    googlePay: {
      gatewayMerchantId: '',
      gatewayId: '',
      merchantId: '',
      merchantName: '',
      environment: environmentCheck,
      supports3DS: true,
      supportsDebit: true,
      supportsCredit: true,
    },
    rateLimits: {
      enforceRateLimitsForGooglePay: false,
      enabled: false,
      amount: 100,
      duration: 60,
    },
    allowSmallTransactions: false,
  },
  freedompay: {
    storeId: '',
    terminalId: '',
    esKey: '',
    paymentMethod: {
      applePay: false,
      creditCard: false,
      googlePay: false,
    },
    rateLimits: {
      enforceRateLimitsForGooglePay: false,
      enabled: false,
      amount: 100,
      duration: 60,
    },
    allowSmallTransactions: false,
  },
  realex: {
    merchantId: '',
    accountId: '',
    sharedSecret: '',
    rebatePassword: '',
    refundPassword: '',
    paymentMethod: {
      applePay: true,
      creditCard: true,
      googlePay: true,
    },
    applePay: {
      merchantId: '',
      supports3DS: false,
      supportsDebit: false,
      supportsCredit: false,
    },
    googlePay: {
      gatewayMerchantId: '',
      gatewayId: '',
      merchantId: '',
      merchantName: '',
      environment: environmentCheck,
      supports3DS: true,
      supportsCredit: true,
    },
    appleGoogleNetwork: {
      mastercard: false,
      visa: false,
      amex: false,
      discover: false,
      jcb: false,
    },
    rateLimits: {
      enforceRateLimitsForGooglePay: false,
      enabled: false,
      amount: 100,
      duration: 60,
    },
    suppressCreditCard3DSAddress: false,
    allowSmallTransactions: false,
  },
  jetpay: {
    storeNumber: 0,
    applePay: {
      merchantId: '',
      supports3DS: false,
      supportsDebit: false,
      supportsCredit: false,
    },
    appleGoogleNetwork: {
      mastercard: false,
      visa: false,
      amex: false,
      discover: false,
      jcb: false,
    },
    companyPaymentConfigId: '',
    paymentMethod: {
      creditCard: false,
      applePay: false,
    },
    rateLimits: {
      enforceRateLimitsForGooglePay: false,
      enabled: false,
      amount: 100,
      duration: 60,
    },
    allowSmallTransactions: false,
  },
  square: {
    apiToken: '',
    storeId: '',
    paymentMethod: {
      applePay: false,
      creditCard: false,
      googlePay: false,
    },
    appleGoogleNetwork: {
      mastercard: false,
      visa: false,
      amex: false,
      discover: false,
      interac: false,
      jcb: false,
    },
    applePay: {
      merchantId: '',
      supports3DS: false,
      supportsDebit: false,
      supportsCredit: false,
    },
    googlePay: {
      gatewayMerchantId: '',
      gatewayId: '',
      merchantId: '',
      merchantName: '',
      environment: environmentCheck,
      supports3DS: false,
      supportsDebit: false,
      supportsCredit: false,
      webMerchantKey: '',
    },
    allowSmallTransactions: false,
    rateLimits: {
      enforceRateLimitsForGooglePay: false,
      enabled: false,
      amount: 0,
      duration: 0,
    },
  },
  bambora: {
    apiKey: '',
    merchantId: '',
    paymentMethod: {
      applePay: false,
      creditCard: false,
    },
    appleGoogleNetwork: {
      mastercard: false,
      visa: false,
      amex: false,
      discover: false,
      jcb: false,
    },
    applePay: {
      merchantId: '',
      supports3DS: false,
      supportsDebit: false,
      supportsCredit: false,
    },
    rateLimits: {
      enforceRateLimitsForGooglePay: false,
      enabled: false,
      amount: 0,
      duration: 0,
    },
    allowSmallTransactions: false,
  },
  adyen: {
    paymentMethods: {
      creditCard: true,
      applePay: true,
      googlePay: true,
    },
    rateLimits: {
      enforceRateLimitsForGooglePay: false,
      enabled: false,
      amount: 0,
      duration: 0,
    },
    balanceAccount: '',
    environment: AdyenEnvironment.Test,
    allowSmallTransactions: false,
    perTransactionFees: {
      fixedAmount: 25,
      percentage: 2.85,
    },
    payfac: {
      payoutRecipientEmails: [],
    },
    pricingModel: AdyenPricingModel.None,
  },
};
