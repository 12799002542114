import classNames from 'classnames';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SelectFilter, { Option } from '../../../components/SelectFilter/SelectFilter';
import { useSearchParams } from '../../../hooks';
import { getSelectedOption } from '../../../utils/selectListUtils/listUtil';
import { updatePath } from '../../../utils/updatePath';
import { updateURLSearchParams } from '../../../utils/updateURLSearchParams';
import { getSearchParam } from '../../../utils/urlUtils';
import styles from './TablesSelectFilter.module.scss';

export interface TablesSelectFilterProps {
  options: Option[];
  name: string;
  disabledLoading?: boolean;
}

const TablesSelectFilter = ({ options, name, disabledLoading = false }: TablesSelectFilterProps) => {
  const { query } = useSearchParams();
  const { push } = useHistory();
  const { pathname } = useLocation();

  const initialValue = options[0];
  const [value, setValue] = React.useState(initialValue);

  // Populate input value with query URL value
  React.useEffect(() => {
    if (query && name) {
      let param = getSearchParam(decodeURIComponent(query), name);

      if (param) {
        const decodedParam = decodeURIComponent(param);
        setValue(getSelectedOption(options, decodedParam, ''));
      } else {
        setValue(initialValue);
      }
    } else {
      setValue(initialValue);
    }
  }, [name, options, query, initialValue]);

  const handleChange = (option: Option) => {
    const searchParams = updateURLSearchParams(query, [
      {
        name: name ? name : '',
        value: option.value.toString(),
      },
    ]);

    const sanitizedValue = encodeURIComponent(searchParams.toString());
    updatePath(sanitizedValue, pathname, push);
  };

  const disabledClass = classNames(disabledLoading && styles.disabled);

  return (
    <SelectFilter
      options={options}
      additionalStyles={styles.filter}
      value={value}
      onChange={handleChange}
      additionalSelectStyles={disabledClass}
    />
  );
};

export default TablesSelectFilter;
