import { Form, FormControl } from 'components/Form';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'components/Modal';
import TextInput from 'components/TextInput/TextInput';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { IMenuGroupUI } from '../../redux/types';

interface Props {
  setShowModal: (show: boolean) => void;
  onSave: (menuGroup: IMenuGroupUI) => Promise<void>;

  menuConfigToEdit?: IMenuGroupUI;
}

export const EditMenuConfigModal = ({ setShowModal, onSave, menuConfigToEdit }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IMenuGroupUI>({ defaultValues: menuConfigToEdit });

  const [saving, setSaving] = useState<boolean>(false);

  return (
    <Modal setShowModal={setShowModal}>
      <ModalHeader
        headerLabel={`${menuConfigToEdit ? 'Edit' : 'New'} Menu Configuration`}
        setShowModal={setShowModal}
      ></ModalHeader>
      <ModalBody>
        <Form isModalForm useMobileStyling>
          <FormControl
            label='Menu Configuration Name *'
            errorMessage={errors.name?.message}
            withError={errors.name !== undefined}
          >
            <TextInput
              {...register('name', { required: 'Required field.' })}
              autoFocus
              withError={errors.name !== undefined}
              disabled={saving}
            />
          </FormControl>
        </Form>
      </ModalBody>
      <ModalFooter
        primaryLabel={menuConfigToEdit ? 'Save Changes' : 'Create Menu Configuration'}
        primaryActionHandler={handleSubmit(async (menuGroup) => {
          setSaving(true);
          await onSave(menuGroup);
          setShowModal(false);
        })}
        loading={saving}
        secondaryActionHandler={() => setShowModal(false)}
        secondaryLabel='Cancel'
      />
    </Modal>
  );
};
