import React from 'react';

import styles from './ModifierGroupCard.module.scss';

import { IModifierGroup, ITemplateModifierGroup } from '@ready/menu.core';

import { PanelCollapsible, PanelTitle } from '../../../components/PanelLayout';
import OverflowButton from '../../../components/OverflowButton/OverflowButton';
import { compositeStyles } from 'utils/cssUtils';

export type ModifierGroupCardProps = {
  modifierGroup: Omit<IModifierGroup | ITemplateModifierGroup, 'options'>;
  linkTo: string;
  initiallyExpanded?: boolean;
  isDragging?: boolean;
  dragHandle?: JSX.Element;
  onRemove: () => void;
  optionsListElementComponent?: React.ReactNode;

  readonly?: boolean;
};

const ModifierGroupCard = (props: ModifierGroupCardProps): JSX.Element => {
  const {
    modifierGroup: {
      name,
      displayName,
      guestInstructions,
      constraints: { min, max },
    },
    linkTo,
    initiallyExpanded = false,
    isDragging = false,
    dragHandle,
    onRemove,
    optionsListElementComponent,
    readonly,
  } = props;

  const minMax = `MIN: ${min ? min : 'None'} | MAX: ${max ? max : 'Unlimited'}`;

  const menuOptions = [
    {
      label: 'Remove Mod Group',
      primary: true,
      onClick: onRemove,
    },
  ];

  return (
    <PanelCollapsible
      titleComponent={
        <div className={styles.title}>
          {dragHandle}
          <PanelTitle title={name!} linkTo={linkTo} />
        </div>
      }
      subTitleComponent={
        <>
          {!!displayName && (
            <div
              className={compositeStyles(
                [styles.subTitle, { withSeparator: !!guestInstructions, isReadonly: !!readonly }],
                styles
              )}
            >
              {displayName}
            </div>
          )}
          <div className={styles.minMaxSubTitle}>{minMax}</div>
          {guestInstructions && (
            <div className={compositeStyles([styles.guestInstructions, { isReadonly: !!readonly }], styles)}>
              {guestInstructions}
            </div>
          )}
        </>
      }
      headerControls={
        <div className={styles.attributes}>
          <div className={styles.minMax}>{minMax}</div>

          <div className={styles.menu}>{!readonly && <OverflowButton options={menuOptions} />}</div>
        </div>
      }
      fullContentArea
      initiallyExpanded={initiallyExpanded}
      shaded={isDragging}
    >
      {optionsListElementComponent}
    </PanelCollapsible>
  );
};

export default ModifierGroupCard;
