import { FormSubHeader, FormControl } from '../../../components/Form';
import { IServiceChargeSettings, PosSystemType, ServiceChargeType } from '@ready/dashboardv2api.contracts';
import { IUpdateLocationParam } from '../LocationsEditPage';
import { LOCATION_SETTINGS_ACTIONS } from '../../redux/LocationSettingsActions';
import { PosItemSelect } from './ForcedServiceCharge/PosItemSelect';
import { useParams } from 'react-router-dom';
import FormattedInput from '../../../components/FormattedInput/FormattedInput';
import React from 'react';
import SelectFilter, { Option } from '../../../components/SelectFilter/SelectFilter';
import TextInput from '../../../components/TextInput/TextInput';
import Toggle from '../../../components/Toggle/Toggle';
import { toNumber } from '../../../utils/numberUtils/toNumber';
import mapSelectOption from 'utils/selectListUtils/mapSelectOption';

export type ServiceChargePosIntegration = PosSystemType.bypass2 | PosSystemType.omnivore | PosSystemType.simphony2;

export interface ForcedServiceChargesProps {
  settings: IServiceChargeSettings;
  posIntegration: ServiceChargePosIntegration;
  updateLocationParam: IUpdateLocationParam;
}

const chargeTypeOptions: Option[] = [
  {
    label: 'Percent',
    value: ServiceChargeType.Percent,
  },
  {
    label: '$ Amount',
    value: ServiceChargeType.Amount,
  },
];

const serviceChargeAmountValue = (settings: IServiceChargeSettings): number | undefined => {
  if (!settings.serviceChargeAmount) {
    return;
  }
  if (settings.serviceChargeType === ServiceChargeType.Percent) {
    return settings.serviceChargeAmount * 100;
  } else {
    return settings.serviceChargeAmount / 100;
  }
};

const showSupportChargesFields = (posSystemType: PosSystemType): boolean => {
  return posSystemType === PosSystemType.omnivore || posSystemType === PosSystemType.simphony2;
};

const ForcedServiceCharges = (props: ForcedServiceChargesProps) => {
  const { settings, updateLocationParam, posIntegration } = props;
  const { companyId, id } = useParams<{ companyId: string; id: string; tab: string }>();

  const isServiceChargeTypeAmount = settings.serviceChargeType === ServiceChargeType.Amount;

  const handleChargeTypeChange = (option: Option) => {
    if (posIntegration !== PosSystemType.omnivore && posIntegration !== PosSystemType.simphony2) {
      updateLocationParam(undefined, LOCATION_SETTINGS_ACTIONS.UPDATE_FORCED_SERVICE_CHARGE_AMOUNT);
    }

    updateLocationParam(option.value, LOCATION_SETTINGS_ACTIONS.UPDATE_FORCED_SERVICE_CHARGE_TYPE);
  };

  const handleChargeAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const amount = event.target.value;
    let parsedAmount: number | undefined;

    if (amount) {
      if (settings.serviceChargeType === ServiceChargeType.Percent) {
        parsedAmount = toNumber(amount) / 100;
      } else {
        parsedAmount = toNumber(amount) * 100;
      }
    }

    updateLocationParam(parsedAmount, LOCATION_SETTINGS_ACTIONS.UPDATE_FORCED_SERVICE_CHARGE_AMOUNT);
  };

  return (
    <>
      <div className='form-panel-separator'></div>
      <FormSubHeader label='Forced Service Charges' />
      <FormControl label='Status'>
        <Toggle
          checked={settings.serviceChargeEnabled ?? false}
          onChange={(checked) => {
            updateLocationParam(checked, LOCATION_SETTINGS_ACTIONS.UPDATE_FORCED_SERVICE_CHARGE_STATUS);
          }}
        />
      </FormControl>

      {showSupportChargesFields(posIntegration) && (
        <>
          <FormControl label='Select Service Charge *'>
            <TextInput
              placeholder='Select Service Charge'
              value={settings.serviceChargeId}
              onChange={(event) => {
                updateLocationParam(event.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_FORCED_SERVICE_CHARGE);
              }}
            />
          </FormControl>
          <FormControl label='Charge Type'>
            <SelectFilter
              options={chargeTypeOptions}
              value={mapSelectOption(chargeTypeOptions, settings.serviceChargeType) ?? chargeTypeOptions[0]}
              onChange={handleChargeTypeChange}
            />
          </FormControl>
          <FormControl label='Charge Amount *'>
            <FormattedInput
              format={isServiceChargeTypeAmount ? 'money' : 'percentage'}
              value={serviceChargeAmountValue(settings) ?? ''}
              onChange={handleChargeAmountChange}
              placeholder={isServiceChargeTypeAmount ? '0.00' : '0'}
            />
          </FormControl>
        </>
      )}

      {posIntegration === PosSystemType.bypass2 && (
        <FormControl label='Select POS Item *'>
          <PosItemSelect
            companyId={companyId}
            locationId={id}
            onChange={(option: Option) => {
              updateLocationParam(option?.value ?? '', LOCATION_SETTINGS_ACTIONS.UPDATE_FORCED_SERVICE_CHARGE_POS_ITEM);
            }}
            selectedPosItemId={settings.serviceChargeId}
          ></PosItemSelect>
        </FormControl>
      )}
    </>
  );
};

export default ForcedServiceCharges;
