import React from 'react';

// Source files must be in the public folder
enum AudioSource {
  ReadyRiff = 'Ready-riff.mp3',
  Test = 'Ready-test.mp3',
}

const getPublicAudioSource = (source: string): string => `${process.env.PUBLIC_URL}/${source}`;

type NoParamCallback = () => void;
type ErrorCallback = (error: any) => void;
type AsyncNoParamCallback = () => Promise<void>;

const noOpFunction = (): void => {};

const createAudio = (source: string, loop: boolean, volume: number): HTMLAudioElement => {
  const audio = new Audio(source);

  audio.loop = loop;
  audio.volume = volume;

  return audio;
};

interface UseAudioOptions {
  autoPlay?: boolean;
  autoTest?: boolean;
  loop?: boolean;
  volume?: number;
  onTestFail?: NoParamCallback;
  onTestPass?: NoParamCallback;
  onPlayError?: ErrorCallback;
}

interface UseAudio {
  play: AsyncNoParamCallback;
  pause: NoParamCallback;
  stop: NoParamCallback;
  test: AsyncNoParamCallback;
  testPassed: boolean;
}

const useAudio = (source: string, options?: UseAudioOptions): UseAudio => {
  // These values are memoized to prevent unnecessary re-renders
  const autoPlay = React.useMemo<boolean>(() => options?.autoPlay || false, [options]);
  const autoTest = React.useMemo<boolean>(() => options?.autoTest || true, [options]);
  const loop = React.useMemo<boolean>(() => options?.loop || false, [options]);
  const volume = React.useMemo<number>(() => options?.volume || 1.0, [options]);
  const onTestFail = React.useMemo<NoParamCallback>(() => options?.onTestFail || noOpFunction, [options]);
  const onTestPass = React.useMemo<NoParamCallback>(() => options?.onTestPass || noOpFunction, [options]);
  const onPlayError = React.useMemo<ErrorCallback>(() => options?.onPlayError || noOpFunction, [options]);

  const [testPassed, setTestPassed] = React.useState(false);

  const [audio] = React.useState<HTMLAudioElement>(createAudio(getPublicAudioSource(source), loop, volume));

  const [testAudio] = React.useState<HTMLAudioElement>(createAudio(getPublicAudioSource(AudioSource.Test), false, 0.0));

  // Audio loop toggle
  React.useEffect(() => {
    if (audio.loop !== loop) {
      audio.loop = loop;
    }
  }, [audio, loop]);

  // Volume adjustment
  React.useEffect(() => {
    if (audio.volume !== volume) {
      audio.volume = volume;
    }
  }, [audio, volume]);

  // Begins playback once the audio player is in a playable state
  const play = React.useCallback(async () => {
    await audio.play().catch((err) => onPlayError(err));
  }, [audio, onPlayError]);

  const handleTestPass = React.useCallback(() => {
    setTestPassed(true);
    onTestPass();
    autoPlay && play();
  }, [onTestPass, autoPlay, play]);

  // A function to test the audio capabilities
  const test = React.useCallback(async () => {
    await testAudio.play().then(handleTestPass, onTestFail);
  }, [testAudio, onTestFail, handleTestPass]);

  // Tests the audio capabilities
  React.useEffect(() => {
    if (autoTest) {
      test();
    }
  }, [autoTest, test]);

  // Pauses playback, if any
  const pause = React.useCallback(() => {
    audio.pause();
  }, [audio]);

  // Pauses playback, if any and scrubs to 0s position
  const stop = React.useCallback(() => {
    audio.pause();
    audio.currentTime = 0;
  }, [audio]);

  return {
    play,
    pause,
    stop,
    test,
    testPassed,
  };
};

export default useAudio;

export { AudioSource };
