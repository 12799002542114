import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { getClientServerFlowSettingsThunk, saveClientServerFlowSettingsThunk } from './thunk';
import { ILocationServerFlow } from '@ready/dashboardv2api.contracts';

type ServerFlowSettingsState = {
  serverFlowEdit?: ILocationServerFlow;
  serverFlowCache?: ILocationServerFlow;
  loading: boolean;
};

export const serverFlowSettingsInitialState: ServerFlowSettingsState = {
  loading: false,
};

export const serverFlowSettingsSlice = createSlice({
  name: 'serverFlow/settings',
  initialState: serverFlowSettingsInitialState,

  reducers: {},

  extraReducers: (builder: ActionReducerMapBuilder<ServerFlowSettingsState>) => {
    builder.addCase(getClientServerFlowSettingsThunk.pending, (state: ServerFlowSettingsState) => {
      state.loading = true;
    });
    builder.addCase(
      getClientServerFlowSettingsThunk.fulfilled,
      (state: ServerFlowSettingsState, action: { payload: ILocationServerFlow }) => {
        state.loading = false;
        state.serverFlowEdit = state.serverFlowCache = action.payload;
      }
    );
    builder.addCase(getClientServerFlowSettingsThunk.rejected, (state: ServerFlowSettingsState) => {
      state.loading = false;
    });

    builder.addCase(
      saveClientServerFlowSettingsThunk.fulfilled,
      (state: ServerFlowSettingsState, action: { payload: ILocationServerFlow }) => {
        state.serverFlowEdit = state.serverFlowCache = action.payload;
      }
    );
  },
});
