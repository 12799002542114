import { ITemplateMenuItem, ITemplateMenuItemRequest } from '@ready/menu.core';
import config from 'config';
import executeApiAsync from 'services/utils/executeApiAsync';
import { mapITemplateMenuItemToITemplateMenuItemCreateRequest } from 'sharedMenuItems/mappers/mappers';

export const getTemplateMenuItem = async (companyId: string, itemId: string): Promise<ITemplateMenuItem> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/items/${itemId}`;
  return await executeApiAsync<ITemplateMenuItem>(url, {
    method: 'GET',
  });
};

export const createTemplateMenuItem = async (
  companyId: string,
  item: ITemplateMenuItem
): Promise<ITemplateMenuItem> => {
  const itemToCreate: Omit<ITemplateMenuItemRequest, '_id'> =
    mapITemplateMenuItemToITemplateMenuItemCreateRequest(item);
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/items`;
  return await executeApiAsync<ITemplateMenuItem>(url, {
    method: 'POST',
    body: JSON.stringify(itemToCreate),
  });
};

export const updateTemplateMenuItem = async (
  companyId: string,
  item: ITemplateMenuItemRequest
): Promise<ITemplateMenuItem> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/items`;
  return await executeApiAsync<ITemplateMenuItem>(url, {
    method: 'PUT',
    body: JSON.stringify(item),
  });
};

export const deleteTemplateMenuItem = async (companyId: string, itemId: string): Promise<void> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/items/${itemId}`;
  return await executeApiAsync<void>(url, {
    method: 'DELETE',
  });
};
