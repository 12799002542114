import { ITable } from '@ready/table.core';

// Used in an array sort; compares two tables by table number
const compareTables = (tableA: ITable, tableB: ITable) => {
  const tableANumber = tableA.tableNumber || -Infinity;
  const tableBNumber = tableB.tableNumber || -Infinity;

  return tableANumber - tableBNumber;
};

export default compareTables;
