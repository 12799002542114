import DropDownButton, { DropDownButtonOption } from 'components/DropDownButton/DropDownButton';
import OverflowButton from 'components/OverflowButton/OverflowButton';
import SelectFilter, { Option } from 'components/SelectFilter/SelectFilter';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { PageControls } from 'sharedMenuItems/components/PageControls';
import styles from './ItemAndModsPageControls.module.scss';
import { SharedMenuItemsRoutes } from 'sharedMenuItems/Router';
import { useHistory, useParams } from 'react-router-dom';
import { ContextParams } from 'types/ContextParams.interface';
import SearchInput from 'components/SearchInput/SearchInput';
import {
  updateQueryParamsDebounced,
  updateQueryParams,
} from 'utils/updatePath';
import { ItemTypeFilter } from '../service';
import { useAppDispatch } from 'redux/store';
import { resetSharedMenuItemState } from 'sharedMenuItems/redux/createEditItem/createEditItemSlice';
import { LocationFilter } from 'sharedMenuItems/components/filters/locationFilter/LocationFilter';
import { ILocationFilterParams, useLocationFilter } from 'hooks/useLocationFilter';

const MENU_ITEM_LIST_OPTIONS: Option<ItemTypeFilter>[] = [
  {
    label: 'All Items & Mods',
    value: 'all',
  },
  {
    label: 'Items',
    value: 'items',
  },
  {
    label: 'Modifiers',
    value: 'options',
  },
];

interface Props {
  initialTypeFilter?: ItemTypeFilter;
  initialSearchFilter?: string;
  initialLocationFilter?: ILocationFilterParams;
}

const ItemsAndModsPageControls = ({
  initialSearchFilter: initialSearch,
  initialTypeFilter: initialType,
  initialLocationFilter,
}: Props) => {
  const [itemTypeFilter, setItemTypeFilter] = useState<ItemTypeFilter>(initialType ?? 'all');
  const [searchInputFilter, setSearchInputFilter] = useState<string>(initialSearch ?? '');
  const [locationFilter, setLocationFilter] = useState<ILocationFilterParams | undefined>(
    initialLocationFilter ?? undefined
  );

  const { contextId: companyId } = useParams<ContextParams>();
  const { push } = useHistory();
  const dispatch = useAppDispatch();

  const newButtonOptions: DropDownButtonOption[] = [
    {
      label: 'New Shared Item',
      disabled: false,
      onClick: () => {
        dispatch(resetSharedMenuItemState());
        push(SharedMenuItemsRoutes.getItemAndModsNewItem(companyId));
      },
    },
    {
      label: 'New Shared Modifier',
      disabled: false,
      onClick: () => {
        dispatch(resetSharedMenuItemState());
        push(SharedMenuItemsRoutes.getItemAndModsNewModifier(companyId));
      },
    },
  ];

  useEffect(() => {
    updateQueryParams(SharedMenuItemsRoutes.getItemAndModsRoute(companyId), { type: itemTypeFilter, page: '' }, push);
  }, [itemTypeFilter, companyId, push]);

  const onSearchTermChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchInputFilter(event.currentTarget.value);
    updateQueryParamsDebounced(
      SharedMenuItemsRoutes.getItemAndModsRoute(companyId),
      { searchTerm: event.currentTarget.value, page: '' },
      push
    );
  };

  useLocationFilter(SharedMenuItemsRoutes.getItemAndModsRoute(companyId), locationFilter);

  return (
    <PageControls className={styles.controls}>
      <div className={styles.searchFilter}>
        <SearchInput
          placeholder='Search menu items and mods'
          additionalClassName='white search-input-full-width'
          value={searchInputFilter}
          onChange={onSearchTermChange}
        />
        <OverflowButton className={styles.mobileOverflowButton} options={newButtonOptions}></OverflowButton>
      </div>
      <SelectFilter
        additionalStyles={styles.itemTypeFilter}
        options={MENU_ITEM_LIST_OPTIONS}
        value={MENU_ITEM_LIST_OPTIONS.find((x: Option<ItemTypeFilter>) => x.value === itemTypeFilter)}
        onChange={(option: Option<ItemTypeFilter>) => setItemTypeFilter(option.value)}
      />

      <LocationFilter initial={locationFilter} onLocationSet={(locationFilter) => setLocationFilter(locationFilter)} />

      <div className={styles.buttons}>
        <DropDownButton label='New Shared Item' options={newButtonOptions} />
      </div>
      <OverflowButton options={newButtonOptions} className={styles.tabletOverflowButton}></OverflowButton>
    </PageControls>
  );
};

export { ItemsAndModsPageControls };
