import { IMenuCopyState } from '@ready/menu.core';
import { ILocation } from '../../services/types/ILocation.type';

export interface CopyMenuState {
  menuCopyStateLoading: boolean;
  menuCopyState?: IMenuCopyState;
  menuCopyStateError: boolean;
  acknowledgementProcessing: boolean;
  menuCopyProcessing: boolean;
  dataDeletionAcknowledged: boolean;
  locationsLoading: boolean;
  locations: ILocation[];
  selectedLocation?: ILocation;
  validated: boolean;
  showValidationError: boolean;
  showCopyInProgressError: boolean;
  launchingMenuCopy: boolean;
  launchedMenuCopy: boolean;
}

export const initialState: CopyMenuState = {
  menuCopyStateLoading: false,
  menuCopyStateError: false,
  acknowledgementProcessing: false,
  menuCopyProcessing: false,
  dataDeletionAcknowledged: false,
  locationsLoading: false,
  locations: [],
  validated: false,
  showValidationError: false,
  showCopyInProgressError: false,
  launchingMenuCopy: false,
  launchedMenuCopy: false,
};
