import { MenuActionHeader, MenuForActionHeader } from 'menus/components/Menus/MenuActionHeader/MenuActionHeader';
import React, { useEffect } from 'react';
import { RootState, useAppDispatch, useAppSelector } from 'redux/store';
import {
  prepareReorderMenuSectionsModal,
  resetForms,
  setMenuEditMode,
  setMenuSectionCreateMode,
  updateMenuDescription,
  updateMenuDisplayName,
} from 'menus/redux/MenusActions';
import { ContextParams } from 'types/ContextParams.interface';
import EditDetailsPanel from 'menus/components/Menus/EditDetailsPanel';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import SectionHeader from 'menus/components/Menus/SectionHeader';
import { SharedMenuItemsLayout } from 'sharedMenuItems/SharedMenuItemsLayout';
import { SharedMenuItemsRoutes } from 'sharedMenuItems/Router';
import { SharedMenuItemsTabId } from 'sharedMenuItems/SharedMenuItemsTabs';
import { ViewEditSectionPanel } from './components/ViewEditSectionPanel';
import { getSharedMenuThunk } from './redux/thunks';
import { saveMenu } from 'menus/redux/MenuActions/SaveMenu';
import styles from './MenuPage.module.scss';
import { useHistory, useParams } from 'react-router-dom';

export const MenuPage = () => {
  const dispatch = useAppDispatch();

  const { push } = useHistory();
  const { contextId: companyId, menuConfigId, id: menuId } = useParams<ContextParams & { menuConfigId: string }>();

  useEffect(() => {
    dispatch(getSharedMenuThunk({ companyId, menuOrItemGalleryId: menuId }));
    return () => {
      dispatch(resetForms());
    };
  }, [companyId, menuId, dispatch]);

  const {
    menuForm: {
      menu,
      cache,
      loading,
      processing,
      validation,
      editing: isEditingMenu,
      sectionForm: { editing: isEditingSection },
    },
    menuGalleryForm: { editing: isEditingMenuItemGallery, menu: formItemGallery },
  } = useAppSelector((state: RootState) => state.menuBuilder.menus);

  const isEditingItemGallery = isEditingMenuItemGallery && formItemGallery!._id === menu.itemGallery!._id;
  const isButtonDisabled = isEditingMenu || isEditingSection || processing || isEditingItemGallery;

  const actionHeaderMenu: MenuForActionHeader = {
    displayName: menu.displayName,
    description: menu.description,
    schedule: menu.schedule,
    status: menu.status,
  };

  return (
    <SharedMenuItemsLayout tab={SharedMenuItemsTabId.Menus}>
      {menu && !loading ? (
        <>
          {!isEditingMenu ? (
            <div className={styles.container}>
              <MenuActionHeader
                menu={actionHeaderMenu}
                backLinkTo={SharedMenuItemsRoutes.getMenuConfigsRoute(companyId)}
                editUnavailable={isButtonDisabled}
                onEditDetailsClick={() => dispatch(setMenuEditMode(true, menu))}
                onLocationSettingsClick={() => {
                  push(SharedMenuItemsRoutes.getMenuLocationSettingsRoute(companyId, menuConfigId, menuId));
                }}
                editItemLabel='Edit Menu Details'
              />
            </div>
          ) : (
            <EditDetailsPanel
              menu={menu}
              processing={processing}
              validation={validation}
              onChangeName={(name) => dispatch(updateMenuDisplayName(name))}
              onChangeDescription={(desc) => dispatch(updateMenuDescription(desc))}
              onCancel={() => dispatch(setMenuEditMode(false, cache))}
              onSave={() => dispatch(saveMenu({ isSharedMenu: true, companyId, menuConfigId, menu }))}
            />
          )}

          <SectionHeader
            disabled={isButtonDisabled}
            onReorderClick={() => dispatch(prepareReorderMenuSectionsModal(true))}
            onCreateClick={() => dispatch(setMenuSectionCreateMode(true))}
          />

          <div className={styles.sections}>
            {/* Using the section panel from menus here, edit and view. Might be a good idea to split this out into a subcomponent even*/}
            <ViewEditSectionPanel />
          </div>
        </>
      ) : (
        <LoadingSpinner />
      )}
    </SharedMenuItemsLayout>
  );
};
