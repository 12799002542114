import { ITransactionDetail } from '@ready/dashboardv2api.contracts';
import {
  getTransactionDetail as getTransactionDetailService,
  manualEnterPosPayment,
  manualRetryPosPayment,
  refundGiftCardByInvoiceId,
} from '../../../services/transactionsService';
import { IPaymentPOSDetail } from '../../initialStates/transactions/transactionDetail';
import { TRANSACTION_DETAIL_ACTIONS } from '../../actions/transactionActions/transactionDetailActionTypesNew';
import { pageErrorState, toastErrorState, set200Toast } from '../uiActions/responseStateActions';

export const getTransactionDetail = (contextId: string, locationId: string, id: string) => async (dispatch: any) => {
  try {
    dispatch(getTransactionDetailBegin());
    const transactionDetailData = await getTransactionDetailService(contextId, locationId, id);
    dispatch(getTransactionDetailSuccess(transactionDetailData));
  } catch (err) {
    dispatch(getTransactionDetailError());
    dispatch(pageErrorState(err.status, err.message));
  }
};

export const getTransactionDetailBegin = () => ({
  type: TRANSACTION_DETAIL_ACTIONS.TRANSACTION_DETAIL_BEGIN,
});

export const getTransactionDetailSuccess = (transactionDetail: ITransactionDetail) => ({
  type: TRANSACTION_DETAIL_ACTIONS.TRANSACTION_DETAIL_SUCCESS,
  payload: transactionDetail,
});

export const getTransactionDetailError = () => ({
  type: TRANSACTION_DETAIL_ACTIONS.TRANSACTION_DETAIL_ERROR,
});

export const showManualEnterPaymentModal = (paymentPOSDetail: IPaymentPOSDetail) => ({
  type: TRANSACTION_DETAIL_ACTIONS.SHOW_MANUAL_ENTER_PAYMENT_MODAL,
  payload: paymentPOSDetail,
});

export const hideManualEnterPaymentModal = () => ({
  type: TRANSACTION_DETAIL_ACTIONS.HIDE_MANUAL_ENTER_PAYMENT_MODAL,
});

export const manualEnterPayment =
  (contextId: string, locationId: string, transactionId: string, _id: string) => async (dispatch: any) => {
    try {
      dispatch(manualEnterPaymentBegin());
      const result = await manualEnterPosPayment(contextId, locationId, transactionId, _id);
      if (result) {
        dispatch(manualEnterPaymentSuccess());
        dispatch(set200Toast());
        // re-fetch transaction detail data
        dispatch(getTransactionDetail(contextId, locationId, transactionId));
      } else {
        dispatch(manualEnterPaymentError());
      }
    } catch (err) {
      dispatch(manualEnterPaymentError());
      dispatch(toastErrorState(err.status, err.message));
    }
  };

export const manualEnterPaymentBegin = () => ({
  type: TRANSACTION_DETAIL_ACTIONS.MANUAL_ENTER_PAYMENT_BEGIN,
});

export const manualEnterPaymentSuccess = () => ({
  type: TRANSACTION_DETAIL_ACTIONS.MANUAL_ENTER_PAYMENT_SUCCESS,
});

export const manualEnterPaymentError = () => ({
  type: TRANSACTION_DETAIL_ACTIONS.MANUAL_ENTER_PAYMENT_ERROR,
});

export const manualRetryPayment =
  (contextId: string, locationId: string, transactionId: string, ticketId: string, subtotal: number, tip: number) =>
  async (dispatch: any) => {
    try {
      dispatch(manualRetryPaymentBegin());
      const result = await manualRetryPosPayment(contextId, locationId, transactionId, ticketId, subtotal, tip);
      if (result) {
        dispatch(manualRetryPaymentSuccess());
        dispatch(set200Toast());
        // re-fetch transaction detail data
        dispatch(getTransactionDetail(contextId, locationId, transactionId));
      } else {
        dispatch(manualRetryPaymentError());
        dispatch(toastErrorState(500, 'It was not possible to retry this payment.'));
      }
    } catch (err) {
      dispatch(manualRetryPaymentError());
      dispatch(toastErrorState(err.status, err.message));
    }
  };

export const manualRetryPaymentBegin = () => ({
  type: TRANSACTION_DETAIL_ACTIONS.MANUAL_RETRY_PAYMENT_BEGIN,
});

export const manualRetryPaymentSuccess = () => ({
  type: TRANSACTION_DETAIL_ACTIONS.MANUAL_RETRY_PAYMENT_SUCCESS,
});

export const manualRetryPaymentError = () => ({
  type: TRANSACTION_DETAIL_ACTIONS.MANUAL_RETRY_PAYMENT_ERROR,
});

export const refundGiftCard =
  (companyId: string, locationId: string, invoiceId: string, amount: number) => async (dispatch: any) => {
    dispatch({
      type: TRANSACTION_DETAIL_ACTIONS.REFUND_GIFT_CARD_BEGIN,
    });

    try {
      const transactionDetailData = await refundGiftCardByInvoiceId(companyId, locationId, invoiceId, amount);
      dispatch(set200Toast('Success! Refund processed.'));
      dispatch({
        type: TRANSACTION_DETAIL_ACTIONS.REFUND_GIFT_CARD_SUCCESS,
        payload: transactionDetailData,
      });
    } catch (err) {
      dispatch({
        type: TRANSACTION_DETAIL_ACTIONS.REFUND_GIFT_CARD_ERROR,
        payload: err.message || 'An error occurred while trying to refund your gift card.',
      });
      dispatch(toastErrorState(err.status, err.message));
    }
  };

export const resetGiftCardRefundState = () => ({
  type: TRANSACTION_DETAIL_ACTIONS.RESET_REFUND_GIFT_CARD_STATE,
});
