import React from 'react';
import { ItemsColumn, TotalColumn } from '.';
import styles from './StackableItemsAndTotalColumns.module.scss';
import { useClassNames } from '../../../utils/cssUtils/useClassNames';
import InfoCardColumn from '../../../components/InfoCard/InfoCardColumn';
import { OrderExperienceType } from '@ready/dashboardv2api.contracts';

interface StackableItemsAndTotalColumnsProps {
  items: number;
  itemsColumnWidth: number;
  total: number;
  tip: number;
  subtotal: number;
  hasRefund?: boolean;
  totalColumnWidth: number;
  stacked?: boolean;
  hidden?: boolean;
  additionalStyles?: string;
  orderExperienceType?: OrderExperienceType;
  hasInvoice?: boolean;
}

const StackableItemsAndTotalColumns = ({
  items,
  itemsColumnWidth,
  total,
  tip,
  subtotal,
  hasRefund = false,
  totalColumnWidth,
  stacked = false,
  hidden = false,
  additionalStyles = '',
  orderExperienceType = OrderExperienceType.DineIn,
  hasInvoice = false,
}: StackableItemsAndTotalColumnsProps) => {
  const stackedContainerClassName = useClassNames([{ stackedContainer: true }, additionalStyles], styles);

  const itemsClassName = useClassNames([{ itemColumn: true }, additionalStyles], styles);

  const totalClassName = useClassNames([{ totalColumn: true }, additionalStyles], styles);

  if (hidden) {
    return null;
  } else if (stacked) {
    return (
      <div className={stackedContainerClassName}>
        <ItemsColumn items={items} width={itemsColumnWidth} additionalStyles={styles.stackedChild} />
        {hasInvoice && (
          <TotalColumn
            total={total}
            tip={tip}
            subtotal={subtotal}
            width={totalColumnWidth}
            additionalStyles={styles.stackedChild}
            hasRefund={hasRefund}
          />
        )}
      </div>
    );
  } else {
    return (
      <>
        <ItemsColumn items={items} width={itemsColumnWidth} additionalStyles={itemsClassName} />
        {hasInvoice ? (
          <TotalColumn
            total={total}
            tip={tip}
            subtotal={subtotal}
            width={totalColumnWidth}
            additionalStyles={styles.stackedChild}
            hasRefund={hasRefund}
          />
        ) : (
          <InfoCardColumn width={totalColumnWidth} columnClassName={totalClassName} />
        )}
      </>
    );
  }
};

export default StackableItemsAndTotalColumns;
