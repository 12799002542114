import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ITag } from '@ready/menu.core';
import AddTagsDialog from './AddTagsDialog';
import TagElement from './TagElement';
import { selectTagsListState } from '../../redux/TagsListSelector';
import { setTagsListDialogVisible } from '../../redux/TagsListActions';
import styles from './MenuItemTagsControl.module.scss';
import NoneValue from 'components/Value/NoneValue';

export interface MenuItemTagsControlProps {
  tags?: ITag[];
  companyId: string;
  locationId?: string;
  addTag: (tag: ITag) => void;
  removeTag: (tagId: string) => void;
  readonly?: boolean;
}

const MenuItemTagsControl = (props: MenuItemTagsControlProps) => {
  const { tags = [], companyId, locationId, addTag, removeTag, readonly = false } = props;

  const { modalVisible } = useSelector(selectTagsListState);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setTagsListDialogVisible(false));
  }, [dispatch]);

  const toggleModalVisibility = (isVisible: boolean) => {
    dispatch(setTagsListDialogVisible(isVisible));
  };

  return (
    <>
      <div className={styles.tagsWrapper}>
        {tags.length === 0 && readonly && <NoneValue />}
        {tags.map((tag: ITag) => (
          <TagElement removeTag={removeTag} key={tag._id} tag={tag} readonly={readonly} />
        ))}
        {!readonly && (
          <button type='button' onClick={() => toggleModalVisibility(true)} className={styles.addTagButton}>
            + Add Tags
          </button>
        )}
      </div>
      {modalVisible && (
        <AddTagsDialog
          setShowModal={toggleModalVisibility}
          companyId={companyId}
          locationId={locationId}
          currentTags={tags}
          addTag={addTag}
          removeTag={removeTag}
        />
      )}
    </>
  );
};

export default MenuItemTagsControl;
