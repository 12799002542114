import { startOfMonth, subMonths } from 'date-fns';
import { DateCell, DollarCell, TextCell } from '../../components/TableV2/Cell/SpecialCells';
import { CellProps, createCellsWithMobileLayout } from '../../components/TableV2/Cell/Cell';
import { createSortableHeaderCell } from '../../components/TableV2/Cell/HeaderCell';
import styles from './PayoutHistoryDetailsPage.module.scss'
import React from 'react';
import classnames from 'classnames';

const rowKey = 'id';

/** Defines header columns and displayed text */
const headerColumns = {
  pspReference: 'PSP Reference',
  createdDate: 'Created Date',
  type: 'Type',
  paymentMethod: 'Payment Method',
  currency: 'Currency',
  amount: "Transaction Amount",
  totalFees: 'Total Fees',
  netAmount: 'Net Amount',

};

const columns = ['pspReference', 'createdDate', 'type', 'paymentMethod', 'currency', 'amount', 'totalFees', 'netAmount'];

export const getCells = () => {
  let cells = {
    pspReference: (props: CellProps) => <TextCell {...props} classes={classnames(props.classes, styles.paddingLeftDesktop50)} />,
    createdDate: (props: CellProps<any>) => (
      <DateCell
        {...props}
        dateGetter={(rowData: any) => rowData.createdDate}
        timezoneGetter={(_: any) => ''}
        classes={classnames(props.classes)}
      />
    ),
    type: TextCell,
    paymentMethod: TextCell,
    currency: TextCell,
    amount: DollarCell,
    totalFees: DollarCell,
    netAmount: DollarCell,

  };

  return createCellsWithMobileLayout(
    cells,
    headerColumns,
    { titleClasses: styles.MobileHeaderCell }
  );
};

export const getHeaderCells = () => {
  return {
    pspReference: createSortableHeaderCell('pspReference', { classes: styles.HeaderCellShorted50 }),
    createdDate: createSortableHeaderCell('createdDate', { classes: styles.HeaderCellShorted50 }),
    type: createSortableHeaderCell('type', { classes: styles.HeaderCellShorted50 }),
    paymentMethod: createSortableHeaderCell('paymentMethod', { classes: styles.HeaderCellShorted50 }),
    currency: createSortableHeaderCell('currency', { classes: styles.HeaderCellShorted50 }),
    amount: createSortableHeaderCell('amount', {
      classes: styles.HeaderCellShorted50,
      align: 'right',
    }),
    totalFees: createSortableHeaderCell('totalFees', {
      classes: styles.HeaderCellShorted50,
      align: 'right',
    }),
    netAmount: createSortableHeaderCell('netAmount', {
      classes: styles.HeaderCellShorted50,
      align: 'right',
    }),
  };

};

const endMonth = startOfMonth(new Date());
const startMonth = subMonths(endMonth, 1);
const initDateFilter = { endMonth, startMonth };

const maxDaysToChoose = 366;

export { rowKey, columns, headerColumns, initDateFilter, maxDaysToChoose };
