import React from 'react';
import InfoCardColumn from '../../../components/InfoCard/InfoCardColumn';
import InfoCardDataRow from '../../../components/InfoCard/InfoCardDataRow';

interface ItemsColumnProps {
  items: number;
  hidden?: boolean;
  width: number;
  additionalStyles?: string;
}

const ItemsColumn = ({ items, hidden = false, width, additionalStyles = '' }: ItemsColumnProps) =>
  hidden ? null : (
    <InfoCardColumn width={width} columnClassName={`items-count ${additionalStyles}`}>
      <InfoCardDataRow>{`${items} item${items === 1 ? '' : 's'}`}</InfoCardDataRow>
    </InfoCardColumn>
  );

export default ItemsColumn;
