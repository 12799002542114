import produce from 'immer';
import { Action } from '../../redux/types';
import { LOCATION_SETTINGS_ACTIONS } from './LocationSettingsActions';
import { emailReceipts } from './PanelEmailReceiptsState';

export const emailReceiptsPanelReducer = (state = emailReceipts, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case LOCATION_SETTINGS_ACTIONS.SET_EMAIL_RECEIPTS_PANEL:
        draft = { ...action.payload };
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_EMAIL_RECEIPTS_LOGO:
        draft.receiptLogoAsset = action.payload;
        draft.isDirty = true;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_EMAIL_RECEIPTS_TITLE_TEXT:
        draft.receiptTitleText = action.payload;
        draft.isDirty = true;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_EMAIL_RECEIPTS_HEADER_TEXT:
        draft.receiptHeaderText = action.payload;
        draft.isDirty = true;
        return draft;
      case LOCATION_SETTINGS_ACTIONS.UPDATE_GENESYS_CONFIGS:
        draft.genesysConfig = { ...(draft.genesysConfig ?? {}), ...action.payload };
        draft.isDirty = true;
        return draft;
      default:
        return draft;
    }
  });
};
