import React from 'react';
import { useParams } from 'react-router-dom';
import { TablesTab } from '../../../enums/tables.enum';
import TablesLayoutContent from '../TablesDetails/TablesLayoutContent';
import { ContextParams } from '../../../types/ContextParams.interface';
import { useDispatch, useSelector } from 'react-redux';
import { paymentProcessingSelector } from '../../redux/selectors/PaymentProcessingSelector';
import { fetchLocation } from '../../redux/actions/paymentProcessingActions';
import {
  OrderSettingsResourceActions,
  PrincipalPermissions,
  ResourceType,
  SecurityScope,
  Verifier,
} from '@ready/security.core';
import { selectPermissions } from '../../../redux/selectors/sessionSelectors/sessionSelectors';
import { OrderHoursPanel } from 'companyLocations/pages/orderHours/OrderHoursPanel';
import Unauthorized from 'pages/Unauthorized/Unauthorized';
import { OrderSmsMessagesContent } from '../orderSMSMessages/orderSMSMessagesContent/OrderSMSMessagesContent';
import { OrderMessagingPanel } from '../orderMessaging/OrderMessagingPanel';

export const OrderSettingsPage = () => {
  const { contextId: companyId, locationId } = useParams<ContextParams>();
  const dispatch = useDispatch();
  const { location } = useSelector(paymentProcessingSelector);
  const { permissionsList } = useSelector(selectPermissions);

  const hasOrderSettingsLocationPermission = Verifier.check(
    new PrincipalPermissions(permissionsList),
    SecurityScope.location,
    ResourceType.orderSettings,
    OrderSettingsResourceActions.all,
    locationId
  );

  React.useEffect(() => {
    dispatch(fetchLocation(companyId, locationId));
  }, [companyId, locationId, dispatch]);

  const locationName = location && location?.id === locationId ? location.name : '';

  return (
    <>
      {hasOrderSettingsLocationPermission ? (
        <TablesLayoutContent
          companyId={companyId}
          locationId={locationId}
          locationName={locationName}
          tab={TablesTab.OrderSettings}
        >
          <OrderHoursPanel />
          <OrderMessagingPanel />
          <OrderSmsMessagesContent />
        </TablesLayoutContent>
      ) : (
        <Unauthorized />
      )}
    </>
  );
};
