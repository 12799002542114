import React, { FC, PropsWithChildren } from 'react';
import styles from './PanelListItem.module.scss';
import { compositeStyles } from 'utils/cssUtils';

/**
 * Component for showing a list of items in a panel.
 * Looks best with `fullContentArea` on parent panel.
 */

interface IPanelListItem {}

export const PanelListItem: FC = (props: PropsWithChildren<IPanelListItem>) => {
  return <div className={styles.container}>{props.children}</div>;
};

interface INoResultsPanelListItemProps {
  message: string;
}
export const NoResultsPanelListItem = ({ message }: INoResultsPanelListItemProps) => {
  return <div className={compositeStyles([styles.container, styles.noResults], styles)}>{message}</div>;
};
