import React, { ReactNode } from 'react';
import { NavigationConfirmationDialog } from 'components/NavigationConfirmationDialog';
import TableHeader from './Table/TableHeader';
import LoadingPage from '../../pages/LoadingPage';
import styles from './TableLayoutContent.module.scss';
import classnames from 'classnames';
import { useAppSelector } from 'redux/store';
import { IFormState } from 'redux/initialStates/ui/formState';
import { selectFormState } from 'redux/selectors/uiSelectors/uiSelectors';

export interface TableLayoutContentProps {
  children: JSX.Element[] | JSX.Element | null;
  pageId?: string;
  title: string;
  titleTooltip?: string;
  headerDescription?: string | ReactNode;
  headerControls?: JSX.Element | JSX.Element[] | null;
  toolbarContent?: ReactNode | JSX.Element | null;
  loadingContent?: boolean;
  className?: string;
}

const TableLayoutContent = (props: TableLayoutContentProps) => {
  const {
    children,
    pageId,
    title,
    headerDescription,
    headerControls,
    toolbarContent,
    loadingContent = false,
    className,
    titleTooltip,
  } = props;

  const { isDirty } = useAppSelector<IFormState>(selectFormState);

  return (
    <div className={classnames(className, styles.layoutContent)} id={pageId}>
      <TableHeader
        title={title}
        controls={headerControls}
        description={headerDescription}
        titleTooltip={titleTooltip}
      />
      <NavigationConfirmationDialog shouldBlockNav={isDirty} />
      <MainContent toolbarContent={toolbarContent} loadingContent={loadingContent}>
        {children}
      </MainContent>
    </div>
  );
};

interface MainContentProps {
  children: JSX.Element[] | JSX.Element | null;
  toolbarContent?: ReactNode | JSX.Element | null;
  loadingContent?: boolean;
  darkBackground?: boolean;
}

const MainContent = (props: MainContentProps) => {
  const { children, toolbarContent, loadingContent = false } = props;

  let mainContentClassName = '';
  let toolbarClassName = styles.toolbar;

  if (loadingContent) {
    return <LoadingPage />;
  }

  return (
    <>
      {toolbarContent ? <div className={toolbarClassName}>{toolbarContent}</div> : null}
      <div className={styles.mainContentWrapper}>
        <div className={mainContentClassName}>{children}</div>
      </div>
    </>
  );
};

export default TableLayoutContent;
