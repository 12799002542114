import produce from 'immer';
import { Action } from '../../redux/types';
import { LOCATION_BILLING_ACTIONS } from './LocationBillingActions';
import { cachedBilling } from './CachedBillingState';

export const cachedBillingReducer = (state = cachedBilling, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case LOCATION_BILLING_ACTIONS.SET_BILLING_CACHE:
        draft = { ...action.payload };
        return draft;

      default:
        return draft;
    }
  });
};
