import { BooleanValue } from 'components/Value/BooleanValue';
import React, { forwardRef } from 'react';
import Container from '../Container/Container';
import TextIcon, { Icon } from '../Icon/TextIcon';
import { Tooltip } from '../Tooltip/Tooltip';

export interface CheckboxProps {
  label: string;
  checked?: boolean;
  onChange?: (event: any) => void;
  disabled?: boolean;
  loading?: boolean;
  additionalHelp?: string;
  withError?: boolean;
  boldLabel?: string;
  labelTooltip?: string;

  readonly?: boolean;
  additionalHelpStyles?: string;
}

const Checkbox = forwardRef((props: CheckboxProps, ref) => {
  const {
    label,
    checked = false,
    onChange,
    disabled = false,
    loading = false,
    additionalHelp = '',
    withError = false,
    boldLabel = '',
    labelTooltip,
    readonly,
    additionalHelpStyles,
  } = props;

  const checkboxBoxRef = React.useRef<HTMLSpanElement>(null);

  const errorClassName = withError && !disabled ? 'checkbox--state-error' : '';
  const checkboxBoxClassName = 'checkbox__box';
  const handleCheckboxFocus = () => {
    if (checkboxBoxRef && checkboxBoxRef.current) {
      checkboxBoxRef.current.className = `${checkboxBoxClassName} ${checkboxBoxClassName}--focus`;
    }
  };
  const handleCheckboxBlur = () => {
    if (checkboxBoxRef && checkboxBoxRef.current) {
      checkboxBoxRef.current.className = checkboxBoxClassName;
    }
  };

  const checkedClassName = checked ? 'checkbox--checked' : '';
  const loadingClassName = loading ? 'checkbox--loading' : '';
  const disabledClassName = disabled ? 'checkbox--disabled' : '';

  if (readonly) {
    return <BooleanValue booleanValue={checked} label={label} />;
  }

  return (
    <Container additionalClassName='checkbox-container'>
      <label className={`checkbox ${errorClassName} ${checkedClassName} ${loadingClassName} ${disabledClassName}`}>
        <span className={checkboxBoxClassName} ref={checkboxBoxRef}>
          <i className='icon-dashboard-icons_checkmark'></i>
        </span>
        <div className={`checkbox__label`}>
          <span>
            {label} <b>{boldLabel}</b>
            {labelTooltip && (
              <span className='checkbox__tooltip'>
                <Tooltip text={labelTooltip}>
                  <TextIcon icon={Icon.InfoCircle} />
                </Tooltip>
              </span>
            )}
          </span>
          {additionalHelp && (
            <div className={`checkbox__label__additional-help ${additionalHelpStyles}`}>{additionalHelp}</div>
          )}
        </div>

        <input
          type='checkbox'
          checked={checked}
          onChange={onChange}
          disabled={disabled || loading}
          onFocus={handleCheckboxFocus}
          onBlur={handleCheckboxBlur}
          className='checkbox_input'
        />
      </label>
    </Container>
  );
});

export default Checkbox;
