import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import {
  loadPromoCodePosDiscounts,
  updatePromoCodeStatus,
  updatePromoCodeName,
  updatePromoCodeDescription,
  updatePromoCodePosDiscount,
  updatePromoCodeValidation,
  updatePromoCodeAutoAttach,
  updatePromoCodeTimeSlot,
  updatePromoCodeAddTimeSlot,
  updatePromoCodeRemoveTimeSlot,
  validatePromoCode,
  savePromoCode,
  resetPromoCodeForm,
} from '../../redux/PromoCodesActions';
import { AppState } from '../../../redux/initialStates/AppState';

import LayoutContent from '../../../components/AppLayout/LayoutContent';
import PromoCodeForm from './PromoCodeForm';
import { useHistory, useParams } from 'react-router-dom';
import { Routes } from '../../EditRouter';

const mapStateToProps = (state: AppState) => ({
  promoCode: state.location.promoCodes.form.promoCode,
  processing: state.location.promoCodes.form.processing,
  discounts: state.location.promoCodes.form.discounts,
  validated: state.location.promoCodes.form.validated,
  saved: state.location.promoCodes.form.saved,
  validation: state.location.promoCodes.form.validation,
});

const actionCreators = {
  loadPromoCodePosDiscounts,
  updatePromoCodeStatus,
  updatePromoCodeName,
  updatePromoCodeDescription,
  updatePromoCodePosDiscount,
  updatePromoCodeValidation,
  updatePromoCodeAutoAttach,
  updatePromoCodeTimeSlot,
  updatePromoCodeAddTimeSlot,
  updatePromoCodeRemoveTimeSlot,
  validatePromoCode,
  savePromoCode,
  resetPromoCodeForm,
};

const connector = connect(mapStateToProps, actionCreators);

type NewPromoCodePageProps = ConnectedProps<typeof connector>;

const NewPromoCodePage = (props: NewPromoCodePageProps): JSX.Element => {
  const {
    promoCode,
    processing,
    discounts,
    validated,
    saved,
    validation,
    loadPromoCodePosDiscounts,
    updatePromoCodeStatus,
    updatePromoCodeName,
    updatePromoCodeDescription,
    updatePromoCodePosDiscount,
    updatePromoCodeValidation,
    updatePromoCodeAutoAttach,
    updatePromoCodeTimeSlot,
    updatePromoCodeAddTimeSlot,
    updatePromoCodeRemoveTimeSlot,
    validatePromoCode,
    savePromoCode,
    resetPromoCodeForm,
  } = props;
  const { companyId, id: locationId } = useParams<{ companyId: string; id: string }>();
  const history = useHistory();

  const onSaveClick = (): void => {
    validatePromoCode(promoCode);
  };

  const onCancelClick = (): void => {
    history.push(Routes.getPromoCodeTab(companyId, locationId));
  };

  React.useEffect(() => {
    if (validated && !validation.hasErrors) {
      savePromoCode(locationId, promoCode);
    }
  }, [validated, validation, savePromoCode, locationId, promoCode]);

  React.useEffect(() => {
    if (saved) {
      history.push(Routes.getPromoCodePage(companyId, locationId, promoCode._id));
    }
  }, [saved, companyId, locationId, promoCode, history]);

  React.useEffect(() => {
    loadPromoCodePosDiscounts(locationId, companyId);
  }, [loadPromoCodePosDiscounts, locationId, companyId]);

  React.useEffect(() => {
    return () => {
      resetPromoCodeForm();
    };
  }, [resetPromoCodeForm]);

  return (
    <LayoutContent title='New Promo Code' containerType='within'>
      <PromoCodeForm
        promoCode={promoCode}
        processing={processing}
        discounts={discounts}
        validation={validation}
        saveLabel='Create Promo Code'
        onStatusChange={updatePromoCodeStatus}
        onNameChange={updatePromoCodeName}
        onDescriptionChange={updatePromoCodeDescription}
        onPosDiscountChange={updatePromoCodePosDiscount}
        onValidationChange={updatePromoCodeValidation}
        onAutoAttachChange={updatePromoCodeAutoAttach}
        onTimeSlotAdd={updatePromoCodeAddTimeSlot}
        onTimeSlotRemove={updatePromoCodeRemoveTimeSlot}
        onTimeSlotChange={updatePromoCodeTimeSlot}
        onSaveClick={onSaveClick}
        onCancelClick={onCancelClick}
      />
    </LayoutContent>
  );
};

export default connector(NewPromoCodePage);
