import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '../../../components/Modal';
import { IPaymentPOSDetail } from '../../../redux/initialStates/transactions/transactionDetail';
import { DollarValue } from '../../../components/Value';
import styles from './ManuallyEnterPayment.module.scss';

interface ManuallyEnterPaymentProps extends IPaymentPOSDetail {
  setShowModal: () => void;
  contextId: string;
  locationId: string;
  posPaymentLoading: boolean;
  manualEnterPayment: (contextId: string, locationId: string, transactionId: string, _id: string) => Promise<void>;
}

const ManuallyEnterPayment = (props: ManuallyEnterPaymentProps) => {
  const {
    setShowModal,
    preTip,
    tip,
    total,
    transactionId,
    _id,
    contextId,
    locationId,
    manualEnterPayment,
    posPaymentLoading,
  } = props;
  const handleManualEntry = () => {
    manualEnterPayment(contextId, locationId, transactionId, _id);
  };

  return (
    <Modal setShowModal={setShowModal} loading={false}>
      <ModalHeader headerLabel='Manually Enter Payment in POS' setShowModal={setShowModal} showDismissIcon />
      <ModalBody>
        <p className={styles.paymentParagraph}>
          Enter the following payment totals into your POS system. Once the payment is applied, confirm that the Manual
          Entry is complete.
        </p>
        <div className={styles.paymentInfo}>
          <div className={styles.paymentInfoAmount}>
            <p className={styles.paymentLabel}>PRE-TIP</p>
            <div className={styles.paymentValue}>
              <DollarValue value={preTip} additionalStyles={styles.paymentDollarValue} />
            </div>
          </div>
          <div className={styles.paymentInfoAmount}>
            <p className={styles.paymentLabel}>TIP</p>
            <div className={styles.paymentValue}>
              <DollarValue value={tip} additionalStyles={styles.paymentDollarValueTip} />
            </div>
          </div>
          <div className={styles.paymentInfoAmount}>
            <p className={styles.paymentLabel}>TOTAL</p>
            <div className={styles.paymentValue}>
              <DollarValue value={total} additionalStyles={styles.paymentDollarValue} />
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter
        primaryLabel='Manual Entry Complete'
        primaryActionHandler={handleManualEntry}
        secondaryLabel='Cancel'
        secondaryActionHandler={setShowModal}
        loading={posPaymentLoading}
      />
    </Modal>
  );
};

export default ManuallyEnterPayment;
