import React from 'react';
import { Alignment } from '../../types/Alignment.type';
import Container from '../Container/Container';

export type DefaultRadioOptionValue = string | number;

export interface RadioOption<T = DefaultRadioOptionValue> {
  label?: string;
  value: T;
}

export interface RadioGroupProps<T = DefaultRadioOptionValue> {
  groupName: string;
  options: RadioOption<T>[];
  value: T;
  onChange: (event: any) => any;
  disabled?: boolean;
  loading?: boolean;
  layout?: Alignment;
}

export interface RadioProps<T = DefaultRadioOptionValue> extends Omit<RadioGroupProps, 'options' | 'value'> {
  option: RadioOption<T>;
  selectedValue?: T;
}

export const Radio = (props: RadioProps) => {
  const { groupName, option, onChange, selectedValue, disabled = false, loading = false } = props;

  const { value, label } = option;
  const radioElementClassName = 'radio__element';
  const selectedClassName = selectedValue === value ? `${radioElementClassName}--selected` : '';
  const radioCombinedClassName = `${radioElementClassName} ${selectedClassName}`;
  const loadingClassName = loading ? 'radio--state-loading' : '';
  const disabledClassName = disabled ? 'radio--disabled' : '';

  const radioElementRef = React.useRef<HTMLSpanElement>(null);
  const handleRadioFocus = () => {
    if (radioElementRef && radioElementRef.current) {
      radioElementRef.current.className = `${radioCombinedClassName} ${radioElementClassName}--focus`;
    }
  };
  const handleRadioBlur = () => {
    if (radioElementRef && radioElementRef.current) {
      radioElementRef.current.className = radioCombinedClassName;
    }
  };

  return (
    <Container additionalClassName='radio__container'>
      <label className={`radio ${loadingClassName} ${disabledClassName}`}>
        <span ref={radioElementRef} className={radioCombinedClassName}>
          <i />
        </span>
        {label && <span className='radio__label'>{label}</span>}
        <input
          type='radio'
          name={groupName}
          value={value}
          onChange={onChange}
          disabled={disabled}
          onFocus={handleRadioFocus}
          onBlur={handleRadioBlur}
        />
      </label>
    </Container>
  );
};

const RadioGroup = (props: RadioGroupProps) => {
  const { groupName, options, value, onChange, disabled = false, loading = false, layout = 'vertical' } = props;

  const radioGroupClassName = `radio-group ${layout === 'horizontal' ? 'radio-group--horizontal' : ''}`;

  return (
    <div className={radioGroupClassName}>
      {options.map((option: RadioOption, index: number) => {
        return (
          <Radio
            key={index}
            groupName={groupName}
            option={option}
            onChange={onChange}
            selectedValue={value}
            disabled={disabled}
            loading={loading}
          />
        );
      })}
    </div>
  );
};

export default RadioGroup;
