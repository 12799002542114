import React, { ChangeEvent } from 'react';
import FormActionBar from 'components/FormActionBar/FormActionBar';
import { useHistory, useParams } from 'react-router-dom';
import { SharedMenuItemsLayout } from 'sharedMenuItems/SharedMenuItemsLayout';
import { SharedMenuItemsTabId } from 'sharedMenuItems/SharedMenuItemsTabs';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { IFormState } from 'redux/initialStates/ui/formState';
import { selectFormState } from 'redux/selectors/uiSelectors/uiSelectors';
import { ContextParams } from 'types/ContextParams.interface';
import { Form, FormControl } from 'components/Form';
import TextInput from 'components/TextInput/TextInput';
import TextArea from 'components/TextArea/TextArea';
import { Panel, PanelLayout } from 'components/PanelLayout';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import ActionHeader from 'components/ActionHeader/ActionHeader';
import { Icon } from 'components/Icon/TextIcon';
import Checkbox from 'components/Checkbox/Checkbox';
import FormattedInput from 'components/FormattedInput/FormattedInput';
import SelectFilter, { Option } from 'components/SelectFilter/SelectFilter';
import { BannerAndThumbnailImagesFormControls } from 'menus/components/ItemsAndMods/BannerAndThumbnailImage/BannerAndThumbnailImagesFormControls';
import { selectCreateEditItemState } from 'sharedMenuItems/redux/selectors';
import LinkButton from 'components/LinkButton/LinkButton';
import Button from 'components/Button/Button';
import OverflowButton from 'components/OverflowButton/OverflowButton';
import EmptyResult from 'components/EmptyResult/EmptyResult';
import {
  addTag,
  dragAndDropModifierGroup,
  prepareModal,
  removeTag,
  revertSharedMenuItemChanges,
  unselectModifierGroup,
  unselectRecommendedItem,
  updateModalSearchQuery,
  updateSharedMenuItemState,
} from 'sharedMenuItems/redux/createEditItem/createEditItemSlice';
import { IAsset } from '@ready/dashboardv2api.contracts';
import {
  IBadge,
  ITag,
  ITemplateEmbeddedMenuItem,
  ITemplateEmbeddedModifierGroup,
  ITemplateForcedModifier,
  ITemplateModifierGroup,
} from '@ready/menu.core';
import mapSelectOption from 'utils/selectListUtils/mapSelectOption';
import { setFormIsDirty } from 'redux/actions/uiActions/formStateActions';
import { SharedMenuItemsRoutes } from 'sharedMenuItems/Router';
import { getBadges } from 'menus/redux/BadgesListActions';
import { selectBadgesListState } from 'menus/redux/BadgesListSelector';
import MenuItemTagsControl from 'menus/components/ItemsAndMods/MenuItemTagsControl';
import { ModifierGroupsModalList } from '../modifierGroupsModalList/ModifierGroupsModalList';
import { CreateEditItemModal } from '../createEditItemModal/CreateEditItemModal';
import { ForcedModifiersModalList } from '../forcedModifiersModalList/ForcedModifiersModalList';
import { RecommendedItemsModalList } from '../recommendedItemsModalList/RecommendedItemsModalList';
import { ForcedModifiersList } from '../forcedModifiersList/ForcedModifiersList';

import DragAndDrop from 'components/DragAndDrop';
import { ModifierGroupsOption } from '../modifierGroupsOption/ModifierGroupsOption';
import ModifierGroupCard from 'menus/components/ItemsAndMods/ModifierGroupCard';
import styles from './CreateEditItemPanels.module.scss';
import InfoCardList from 'components/InfoCard/InfoCardList';
import { RecommendedItemCard } from '../recommendedItemCard/RecommendedItemCard';
import { DeleteConfirmationModal } from 'components/Modal';
import {
  defaultValidationValues,
  DuplicateSharedMenuItemModal,
  IDisplayNameValidation,
} from '../duplicateSharedMenuItemModal/DuplicateSharedMenuItemModal';
import {
  createTemplateMenuItemThunk,
  deleteTemplateMenuItemThunk,
  updateTemplateMenuItemThunk,
} from 'sharedMenuItems/redux/createEditItem/createEditItemThunkActions';
import { DetailsTabs, DetailTabId } from '../tabs/DetailsTabs';
import { useTemplateItemBackLink } from 'hooks/useTemplateItemBackLink';

const noneOption: Option[] = [
  {
    label: 'None',
    value: 'none',
  },
];

export const CreateEditItemPanels = () => {
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const { contextId: companyId, id: sharedMenuItemId } = useParams<ContextParams>();
  const { isDirty: actionBarVisible } = useAppSelector<IFormState>(selectFormState);
  const {
    item,
    itemCache,
    loading,
    processing,
    isItemDeleted,
    newId,
    modals: {
      modifierGroupsModal: { modalVisible: isModGroupsModalVisible, query: modGroupsQuery },
      forcedModifiersModal: { modalVisible: isForcedModifiersModalVisible, query: forcedModsQuery },
      recommendedItemsModal: { modalVisible: isRecommendedItemsModalVisible, query: recommendedItemsQuery },
    },
  } = useAppSelector(selectCreateEditItemState);
  const { badges: badgesList } = useAppSelector(selectBadgesListState);
  const [cancelled, setCancelled] = React.useState<boolean>(false);
  const [isDuplicateModalVisible, setIsDuplicateModalVisible] = React.useState<boolean>(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = React.useState<boolean>(false);
  const [displayNameValidation, setDisplayNameValidation] =
    React.useState<IDisplayNameValidation>(defaultValidationValues);
  const backLink = useTemplateItemBackLink(SharedMenuItemsRoutes.getItemAndModsRoute(companyId));

  const isNewItem = sharedMenuItemId === undefined;

  const saveLabel = isNewItem ? 'Create Item' : 'Save Changes';
  const handleCancelChanges = () => {
    setCancelled(true);
    dispatch(revertSharedMenuItemChanges());
    dispatch(setFormIsDirty(false));
  };

  const hasPassedValidation = (): boolean => {
    if (item?.displayName) {
      setDisplayNameValidation(defaultValidationValues);
      return true;
    } else {
      setDisplayNameValidation({
        hasError: true,
        errorMessage: 'Required field. ',
      });
      return false;
    }
  };

  const handleFormSubmit = async () => {
    if (hasPassedValidation()) {
      if (item) {
        if (isNewItem) {
          dispatch(createTemplateMenuItemThunk({ companyId, item }));
        } else {
          dispatch(updateTemplateMenuItemThunk({ companyId, item }));
        }
      }
    }
  };

  const updateThumbnailImage = (asset: Partial<IAsset>, imageEl?: HTMLImageElement) => {
    dispatch(
      updateSharedMenuItemState({
        thumbnailImageId: asset.fileKey,
        thumbnailImageWidth: imageEl?.naturalWidth ?? 0,
        thumbnailImageHeight: imageEl?.naturalHeight ?? 0,
      })
    );
  };

  const updateBannerImage = (asset: Partial<IAsset>, imageEl?: HTMLImageElement) => {
    dispatch(
      updateSharedMenuItemState({
        imageId: asset.fileKey,
        imageWidth: imageEl?.naturalWidth ?? 0,
        imageHeight: imageEl?.naturalHeight ?? 0,
      })
    );
  };

  const badgesOptions: Option[] | undefined = badgesList?.map((badge: IBadge) => ({
    label: badge.name,
    value: badge._id,
  }));

  const selectedBadgeOption = mapSelectOption(badgesOptions ?? [], item?.badge?._id);

  const handleUpdateBadge = (badgeId: string) => {
    const selectedBadge = badgesList?.find((badge: IBadge) => badge._id === badgeId);
    if (selectedBadge) {
      dispatch(
        updateSharedMenuItemState({
          badge: selectedBadge,
        })
      );
    }
  };

  const openModifierGroupsModal = () => {
    const selectedIds = item?.modifierGroups.map((modGroup: ITemplateEmbeddedModifierGroup) => modGroup._id);
    dispatch(prepareModal({ stateKey: 'modifierGroupsModal', isVisible: true, selectedIds: selectedIds ?? [] }));
  };

  const openForcedModifiersModal = () => {
    const selectedIds = item?.forcedModifiers.map((forcedMod: ITemplateForcedModifier) => forcedMod.itemId);
    dispatch(prepareModal({ stateKey: 'forcedModifiersModal', isVisible: true, selectedIds: selectedIds ?? [] }));
  };

  const openRecommendedItemsModal = () => {
    const selectedIds = item?.recommendedItems.map((item: ITemplateEmbeddedMenuItem) => item.itemId);
    dispatch(prepareModal({ stateKey: 'recommendedItemsModal', isVisible: true, selectedIds: selectedIds ?? [] }));
  };

  React.useEffect(() => {
    dispatch(getBadges(companyId));
  }, [dispatch, companyId]);

  React.useEffect(() => {
    if ((cancelled && isNewItem) || isItemDeleted) {
      push(SharedMenuItemsRoutes.getItemAndModsRoute(companyId));
    }
  }, [cancelled, companyId, isNewItem, isItemDeleted, push]);

  React.useEffect(() => {
    if (newId) {
      push(SharedMenuItemsRoutes.getItemAndModsItem(companyId, newId));
    }
  }, [companyId, newId, push]);

  return (
    <>
      <FormActionBar
        actionBarVisible={actionBarVisible || isNewItem}
        saveLabel={saveLabel}
        handleFormSubmit={handleFormSubmit}
        resetForm={handleCancelChanges}
        isFormProcessing={processing}
      />

      <SharedMenuItemsLayout tab={SharedMenuItemsTabId.ItemAndMods}>
        {loading && !isNewItem ? (
          <LoadingSpinner />
        ) : (
          <>
            {isNewItem ? (
              <ActionHeader text='New Shared Menu Item' />
            ) : (
              <>
                <ActionHeader
                  text={itemCache?.displayName ?? ''}
                  backLinkTo={backLink}
                  actionButtons={[
                    {
                      label: 'Duplicate',
                      variant: 'secondary-gray-bg',
                      disabled: processing,
                      onClick: () => {
                        setIsDuplicateModalVisible(true);
                      },
                    },
                    {
                      label: 'Delete',
                      variant: 'secondary-gray-bg',
                      disabled: processing,
                      onClick: () => {
                        setIsDeleteModalVisible(true);
                      },
                    },
                  ]}
                />
                <DetailsTabs selectedTabId={DetailTabId.Details} />
              </>
            )}

            <Form hasGroups>
              <PanelLayout columns={2} flexWidths={[66, 34]} stackOnTablet>
                <Panel>
                  <FormControl
                    withError={displayNameValidation.hasError}
                    errorMessage={displayNameValidation.errorMessage}
                    label='Display Name *'
                  >
                    <TextInput
                      placeholder='Enter a display name'
                      value={item?.displayName || ''}
                      maxLength={100}
                      loading={processing}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        dispatch(updateSharedMenuItemState({ displayName: e.target.value }));
                      }}
                      withError={displayNameValidation.hasError}
                    />
                  </FormControl>

                  <FormControl label='Description'>
                    <TextArea
                      placeholder='Enter a description'
                      value={item?.description ?? ''}
                      loading={processing}
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        dispatch(
                          updateSharedMenuItemState({
                            description: e.target.value,
                          })
                        );
                      }}
                    />
                  </FormControl>

                  <FormControl icon={Icon.Martini} label='Alcohol Tracking'>
                    <Checkbox
                      label='Contains Alcohol'
                      checked={!!item?.alcohol && item?.alcohol.containsAlcohol}
                      loading={processing}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        dispatch(
                          updateSharedMenuItemState({
                            alcohol: {
                              ...item?.alcohol,
                              containsAlcohol: e.target.checked,
                            },
                          })
                        );
                      }}
                    />
                  </FormControl>

                  {item?.alcohol && item?.alcohol.containsAlcohol ? (
                    <FormControl>
                      <div className='control--stacked'>
                        <div className={styles.small}>
                          <FormattedInput
                            format='number'
                            loading={processing}
                            decimalScale={2}
                            value={item?.alcohol?.quantity?.value!}
                            placeholder='e.g. 1.5'
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              if (e.target.value) {
                                dispatch(
                                  updateSharedMenuItemState({
                                    alcohol: {
                                      containsAlcohol: item.alcohol?.containsAlcohol ?? false,
                                      quantity: {
                                        measure: 'standardDrink',
                                        value: Number(e.target.value),
                                      },
                                    },
                                  })
                                );
                              } else {
                                dispatch(
                                  updateSharedMenuItemState({
                                    alcohol: {
                                      containsAlcohol: item.alcohol?.containsAlcohol ?? false,
                                    },
                                  })
                                );
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className='control--stacked'>
                        <span className={styles.alcoholQuantityNote}>Measured in # of standard drinks</span>
                      </div>
                    </FormControl>
                  ) : null}
                  <FormControl label='Tags'>
                    <MenuItemTagsControl
                      tags={item?.tags ?? []}
                      companyId={companyId}
                      addTag={(tag: ITag) => dispatch(addTag(tag))}
                      removeTag={(tagId: string) => dispatch(removeTag(tagId))}
                      readonly={processing}
                    />
                  </FormControl>

                  <FormControl
                    label='Badge'
                    labelTooltip='Use badges to draw attention to specific menu items (e.g. New, Popular). You can create and edit badges on the Tags & Badges page.'
                  >
                    <SelectFilter
                      options={badgesOptions ?? []}
                      value={selectedBadgeOption ?? noneOption}
                      onChange={(option: Option) => {
                        handleUpdateBadge(option.value.toString());
                        dispatch(setFormIsDirty(true));
                      }}
                      loading={processing}
                    />
                  </FormControl>
                  <BannerAndThumbnailImagesFormControls
                    bannerImageId={item?.imageId}
                    bannerImageLoading={processing}
                    uploadBannerImage={(asset: Partial<IAsset>, imageEl?: HTMLImageElement) => {
                      updateBannerImage(asset, imageEl);
                    }}
                    thumbnailImageId={item?.thumbnailImageId}
                    thumbnailImageLoading={processing}
                    uploadThumbnailImage={(asset: Partial<IAsset>, imageEl?: HTMLImageElement) => {
                      updateThumbnailImage(asset, imageEl);
                    }}
                  />
                </Panel>
                <Panel>
                  <span className={styles.forcedModsHeader}>Forced Modifiers</span>
                  <ForcedModifiersList />

                  <div className={styles.forcedModsLink}>
                    <LinkButton disabled={processing} onClick={openForcedModifiersModal}>
                      + Add Forced Modifier
                    </LinkButton>
                  </div>
                </Panel>
              </PanelLayout>
              <div className={styles.modifiersHeader}>
                <div className={styles.modifiersHeaderText}>Modifiers</div>
                <div className={styles.modifiersHeaderControls}>
                  <Button disabled={processing} label='+ Add Modifier Group' onClick={openModifierGroupsModal} />
                </div>
                <div className={styles.modifiersHeaderOverflow}>
                  <OverflowButton
                    options={[
                      {
                        label: '+ Add Modifier Group',
                        onClick: openModifierGroupsModal,
                        disabled: processing,
                      },
                    ]}
                  />
                </div>
              </div>
              <DragAndDrop
                dropAreaClassName={styles.modifiers}
                dropAreaDraggingClassName={styles.modifiersDragging}
                dragItemClassName={styles.modifier}
                dragHandleClassName={styles.dragHandle}
                dragItems={item?.modifierGroups ?? []}
                emptyComponent={
                  <EmptyResult
                    title='Add Item Modifiers'
                    paragraph='Add modifier groups to this item so your guests can customize their order.'
                  />
                }
                dragHandleProvided
                dragItemKeyExtractor={(modGroup: ITemplateModifierGroup) => modGroup._id}
                onDragItemDropped={(oldIndex: number, newIndex: number) => {
                  dispatch(setFormIsDirty(true));
                  dispatch(dragAndDropModifierGroup({ oldIndex, newIndex }));
                }}
                dragItemComponentBuilder={(
                  modGroup: ITemplateModifierGroup,
                  dragHandle?: JSX.Element,
                  isDragging?: boolean
                ) => (
                  <ModifierGroupCard
                    modifierGroup={modGroup}
                    linkTo={SharedMenuItemsRoutes.geModifierGroupsFromItemPage(
                      companyId,
                      modGroup._id,
                      sharedMenuItemId
                    )}
                    onRemove={() => {
                      dispatch(setFormIsDirty(true));
                      dispatch(unselectModifierGroup(modGroup._id));
                    }}
                    isDragging={isDragging}
                    dragHandle={dragHandle}
                    optionsListElementComponent={modGroup.options.map((option) => (
                      <ModifierGroupsOption key={option.itemId} option={option} />
                    ))}
                  />
                )}
              />
              <div className={styles.pairingsHeader}>
                <div className={styles.pairingsHeaderText}>Recommended Pairings</div>
                <div className={styles.pairingsHeaderControls}>
                  <Button label='+ Add Items' disabled={processing} onClick={openRecommendedItemsModal} />
                </div>
                <div className={styles.pairingsHeaderOverflow}>
                  <OverflowButton
                    options={[
                      {
                        label: '+ Add Items',
                        onClick: openRecommendedItemsModal,
                        disabled: processing,
                      },
                    ]}
                  />
                </div>
              </div>
              <div className={styles.pairings}>
                {!item?.recommendedItems || item?.recommendedItems.length === 0 ? (
                  <EmptyResult
                    title='Add Items for Recommendation'
                    paragraph='Use recommended pairings to suggest to your guests items they might like to order in addition to this menu item.'
                  />
                ) : (
                  <InfoCardList>
                    {item.recommendedItems.map((item) => (
                      <RecommendedItemCard
                        key={item.itemId}
                        item={item}
                        linkTo={SharedMenuItemsRoutes.getItemAndModsItem(companyId, item.itemId)}
                        removeItem={() => {
                          dispatch(setFormIsDirty(true));
                          dispatch(unselectRecommendedItem(item.itemId));
                        }}
                      />
                    ))}
                  </InfoCardList>
                )}
              </div>
            </Form>

            {isModGroupsModalVisible && (
              <CreateEditItemModal
                companyId={companyId}
                headerLabel={'Add Modifier Groups'}
                setShowModal={(isVisible: boolean) =>
                  dispatch(
                    prepareModal({
                      stateKey: 'modifierGroupsModal',
                      isVisible,
                    })
                  )
                }
                query={modGroupsQuery}
                handleQueryChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch(
                    updateModalSearchQuery({
                      stateKey: 'modifierGroupsModal',
                      searchQuery: e.target.value.toString(),
                    })
                  )
                }
                modalListComponent={<ModifierGroupsModalList />}
                searchInputPlaceholder='Search Modifier Groups'
              />
            )}

            {isForcedModifiersModalVisible && (
              <CreateEditItemModal
                companyId={companyId}
                headerLabel={'Add Forced Modifiers'}
                setShowModal={(isVisible: boolean) =>
                  dispatch(
                    prepareModal({
                      stateKey: 'forcedModifiersModal',
                      isVisible,
                    })
                  )
                }
                query={forcedModsQuery}
                handleQueryChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch(
                    updateModalSearchQuery({
                      stateKey: 'forcedModifiersModal',
                      searchQuery: e.target.value.toString(),
                    })
                  )
                }
                modalListComponent={<ForcedModifiersModalList />}
                searchInputPlaceholder='Search Modifiers'
              />
            )}

            {isRecommendedItemsModalVisible && (
              <CreateEditItemModal
                companyId={companyId}
                headerLabel={'Add Menu Items'}
                setShowModal={(isVisible: boolean) =>
                  dispatch(
                    prepareModal({
                      stateKey: 'recommendedItemsModal',
                      isVisible,
                    })
                  )
                }
                query={recommendedItemsQuery}
                handleQueryChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch(
                    updateModalSearchQuery({
                      stateKey: 'recommendedItemsModal',
                      searchQuery: e.target.value.toString(),
                    })
                  )
                }
                modalListComponent={<RecommendedItemsModalList />}
                searchInputPlaceholder='Search Menu Items'
              />
            )}

            {isDeleteModalVisible && (
              <DeleteConfirmationModal
                setShowModal={setIsDeleteModalVisible}
                item='Menu Item'
                itemName={item?.displayName ?? ''}
                loading={processing}
                handleDelete={() => {
                  if (!isNewItem) {
                    dispatch(
                      deleteTemplateMenuItemThunk({
                        companyId,
                        itemId: sharedMenuItemId,
                        itemType: item?.itemType,
                      })
                    );
                    dispatch(setFormIsDirty(false));
                  }
                }}
              />
            )}

            {isDuplicateModalVisible && (
              <DuplicateSharedMenuItemModal companyId={companyId} setVisible={setIsDuplicateModalVisible} />
            )}
          </>
        )}
      </SharedMenuItemsLayout>
    </>
  );
};
