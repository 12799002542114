import produce from 'immer';
import { SECURITY_ROLE_FORM_TYPES } from '../actions/types';
import { Action } from '../../../redux/types';
import { roleFormInitialState } from '../initialStates/securityRoleForm';
import setSecurityRolePermission from '../../../redux/helpers/setSecurityRolePermission';

export const securityRoleFormReducer = (state = roleFormInitialState, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SECURITY_ROLE_FORM_TYPES.INIT_SECURITY_ROLE_FORM:
        draft = roleFormInitialState;
        return draft;

      case SECURITY_ROLE_FORM_TYPES.SET_SECURITY_ROLE_NAME:
        draft.name = action.payload;
        return draft;

      case SECURITY_ROLE_FORM_TYPES.SET_SECURITY_ROLE_DESCRIPTION:
        draft.description = action.payload;
        return draft;

      case SECURITY_ROLE_FORM_TYPES.SET_SECURITY_ROLE_PERMISSION:
        const { permissionType, resourceType, actionName, operation } = action.payload;
        if (permissionType === 'company') {
          draft.companyPermissions = setSecurityRolePermission(
            resourceType,
            actionName,
            operation,
            draft.companyPermissions
          );
        }
        if (permissionType === 'location') {
          draft.locationPermissions = setSecurityRolePermission(
            resourceType,
            actionName,
            operation,
            draft.locationPermissions
          );
        }
        return draft;

      case SECURITY_ROLE_FORM_TYPES.VALIDATE_SECURITY_ROLE_FORM:
        draft.validation = action.payload;
        return draft;

      case SECURITY_ROLE_FORM_TYPES.SET_SECURITY_ROLE_FORM_LOADING:
        draft.loading = action.payload;
        return draft;

      case SECURITY_ROLE_FORM_TYPES.SET_SECURITY_ROLE_FORM_PROCESSING:
        draft.processing = action.payload;
        return draft;

      case SECURITY_ROLE_FORM_TYPES.SET_SECURITY_ROLE_FORM_DATA:
        draft = {
          ...roleFormInitialState,
          ...action.payload,
          loading: draft.loading,
          processing: draft.processing,
        };
        return draft;

      case SECURITY_ROLE_FORM_TYPES.DELETE_SECURITY_ROLE_REQUESTED:
        draft.deleteRequested = action.payload.show;
        return draft;

      case SECURITY_ROLE_FORM_TYPES.DELETE_SECURITY_ROLE_SUCCESS:
        draft.deleteRequested = false;
        draft.processing = false;
        draft.isDeleted = true;
        return draft;

      case SECURITY_ROLE_FORM_TYPES.DELETE_SECURITY_ROLE_ERROR:
        draft.processing = false;
        draft.deleteRequested = false;
        draft.isDeleted = false;
        return draft;

      default:
        return draft;
    }
  });
};
