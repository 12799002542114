import produce from 'immer';
import orderDetailInitialState from '../../initialStates/orders/orderDetailInitialState';
import { Action, OrderDetailForm } from '../../types';
import { ORDERS_ACTIONS } from '../../actions/orders/types';
import { isBefore } from 'date-fns';
import { isBeforeDate, isBeginningOfTime } from '../../../utils/dateUtils';

const orderDetailReducer = (state: OrderDetailForm = orderDetailInitialState, action: Action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ORDERS_ACTIONS.INIT_ORDER_DETAIL:
        return {
          ...orderDetailInitialState,
          loading: draft.loading,
          processing: draft.processing,
        };

      case ORDERS_ACTIONS.SET_ORDER_DETAIL:
        return {
          ...draft,
          ...action.payload,
          loading: draft.loading,
          processing: draft.processing,
        };

      case ORDERS_ACTIONS.SET_ORDER_DETAIL_STATUS:
        draft.status = action.payload;
        return draft;

      case ORDERS_ACTIONS.SET_LOADING_ORDER_DETAIL:
        draft.loading = action.payload;
        return draft;

      case ORDERS_ACTIONS.SET_PROCESSING_ORDER_DETAIL:
        draft.processing = action.payload;
        return draft;

      case ORDERS_ACTIONS.SET_CANCEL_ORDER_DIALOG_OPEN:
        draft.cancelDialogOpen = action.payload;
        return draft;

      case ORDERS_ACTIONS.SET_CANCEL_ORDER_DIALOG_MESSAGE:
        draft.cancelDialogMessage = action.payload;
        return draft;

      case ORDERS_ACTIONS.SET_CANCEL_ORDER_DIALOG_MESSAGE_LOADING:
        draft.cancelDialogMessageLoading = action.payload;
        return draft;

      case ORDERS_ACTIONS.SET_CANCEL_ORDER_PROCESSING:
        draft.cancelProcessing = action.payload;
        return draft;

      case ORDERS_ACTIONS.SET_EDIT_PICKUP_TIME_DIALOG_OPEN:
        draft.editPickupTimeDialogOpen = action.payload;
        return draft;

      case ORDERS_ACTIONS.SET_EDIT_PICKUP_TIME_DIALOG_MESSAGE:
        draft.editPickupTimeDialogMessage = action.payload;
        return draft;

      case ORDERS_ACTIONS.SET_EDIT_PICKUP_TIME_DIALOG_MESSAGE_LOADING:
        draft.editPickupTimeDialogMessageLoading = action.payload;
        return draft;

      case ORDERS_ACTIONS.SET_EDIT_PICKUP_TIME_PROCESSING:
        draft.editPickupTimeProcessing = action.payload;
        return draft;

      case ORDERS_ACTIONS.INIT_EDIT_PICKUP_TIME_DIALOG:
        draft.editPickupTimeDialogMessage = orderDetailInitialState.editPickupTimeDialogMessage;
        draft.newPickupTime = null;
        return draft;

      case ORDERS_ACTIONS.SET_EDIT_PICKUP_TIME_NEW_PICKUP_TIME:
        draft.newPickupTime = action.payload;
        return draft;

      case ORDERS_ACTIONS.RESET_PICKUP_TIME_VALIDATIONS:
        draft.validation.newPickupDate.errorMessage = '';
        draft.validation.newPickupDate.hasError = false;
        draft.validation.newPickupTime.errorMessage = '';
        draft.validation.newPickupDate.hasError = false;
        return draft;

      case ORDERS_ACTIONS.VALIDATE_NEW_PICKUP_DATE_TIME:
        const { pickupDateTime, minPickupDateTime } = action.payload;
        const pickUpDateTimeDate = new Date(pickupDateTime);
        const minPickupDateTimeDate = new Date(minPickupDateTime);

        draft.validation = {
          newPickupDate: {
            errorMessage: '',
            hasError: false,
          },
          newPickupTime: {
            errorMessage: '',
            hasError: false,
          },
        };

        if (isBeginningOfTime(pickUpDateTimeDate)) {
          draft.validation.newPickupDate = {
            errorMessage: 'Date is required',
            hasError: true,
          };
        } else if (isBeforeDate(pickUpDateTimeDate, minPickupDateTimeDate)) {
          draft.validation.newPickupDate = {
            errorMessage: 'Select a future date',
            hasError: true,
          };
        } else if (isBefore(pickUpDateTimeDate, minPickupDateTimeDate)) {
          draft.validation.newPickupTime = {
            errorMessage: 'Select a future time',
            hasError: true,
          };
        }

        return draft;

      default:
        return state;
    }
  });

export default orderDetailReducer;
