import Position from './types/Position.type';

/**
 * To use positional style, import the positionalContainer.scss file into the
 * *.module.scss file used by the component, then include positionalContainer()
 * mixin, passing in the amount of space between the positional component and
 * its parent.
 * @param position Relative position of element compared to parent element
 * @param styles Modular styles imported from *.module.scss file
 */
const getPositionClass = (position: Position, styles: { readonly [key: string]: string }): string => {
  switch (position) {
    case 'center':
      return styles.center;
    case 'topLeft':
      return styles.topLeft;
    case 'topCenter':
      return styles.topCenter;
    case 'topRight':
      return styles.topRight;
    case 'leftTop':
      return styles.leftTop;
    case 'leftCenter':
      return styles.leftCenter;
    case 'leftBottom':
      return styles.leftBottom;
    case 'rightTop':
      return styles.rightTop;
    case 'rightCenter':
      return styles.rightCenter;
    case 'rightBottom':
      return styles.rightBottom;
    case 'bottomLeft':
      return styles.bottomLeft;
    case 'bottomCenter':
      return styles.bottomCenter;
    case 'bottomRight':
      return styles.bottomRight;
  }
};

export default getPositionClass;
