import { CommentSubmissionMethod, GuestValidationMethod } from '@ready/dashboardv2api.contracts';
import { IMobileOrderingPanel } from '../../../redux/PanelFeaturesState';
import React, { ChangeEvent } from 'react';
import { getInitPOSModifier, LOCATION_SETTINGS_ACTIONS } from '../../../redux/LocationSettingsActions';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import styles from './MobileOrdering.module.scss';
import { FormControl } from '../../../../components/Form';
import TextArea from '../../../../components/TextArea/TextArea';
import TextField from '../../../../components/TextField/TextField';
import TextInput from '../../../../components/TextInput/TextInput';
import TextInputNestedLabel from '../../../../components/TextInputNestedLabel/TextInputNestedLabel';
import FormattedInput from '../../../../components/FormattedInput/FormattedInput';
import Toggle from '../../../../components/Toggle/Toggle';
import SelectFilter, { Option } from '../../../../components/SelectFilter/SelectFilter';
import POSModifierSearch from './ItemComments/POSModifierSearch';
import { LIST_OPTIONS } from '../../../../utils/selectListUtils/listOptions';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { IUpdateLocationParam } from '../../LocationsEditPage';
import { findOptionByValue } from '../../../../utils/selectListUtils/listUtil';
import { SubmitOrderToPosFormControl } from './submitOrderToPos/SubmitOrderToPosFormControl';

interface Props {
  isProcessing: boolean;
  updateLocationParam: IUpdateLocationParam;
  mobileOrdering: IMobileOrderingPanel;
  ignoreInStockFromPosItem: boolean;
}

const guestPresenceExpiryMessagePlaceholder =
  'Enter guest facing instructions (e.g. Your ordering session has expired. For verification purposes, please scan the QR code at your table to submit your order.)';
const alcoholPolicyPlaceHolder =
  'Your guests will see this policy and must agree to it before being able to purchase alcohol.';
const serviceOfflinePlaceHolder = 'This is the message your guests will see when mobile ordering is unavailable.';
const menuAnnouncementsPlaceholder =
  'This message will appear on the main menu screen. Use it to highlight promos or special notices for your guests.';
const checkoutAnnouncementsPlaceholder = 'This message will appear on the checkout screen.';
const pickUpInstructionsPlaceholder = 'E.g. Come to the front desk to pick up your order.';
const pickUpLocationPlaceholder = 'E.g. Lobby Pick Up Counter';
const orderConfirmedPlaceholder = 'E.g. Come to the front desk to pick up your order.';

const getSingleCheckPosValue = (status: boolean): Option => {
  const selectedOption = status
    ? LIST_OPTIONS.singleCheckPos.find((o) => o.value)
    : LIST_OPTIONS.singleCheckPos.find((o) => !o.value);
  return selectedOption as Option;
};

const defaultGuestValidationMethodOption: Option = findOptionByValue(
  LIST_OPTIONS.guestValidationMethod,
  GuestValidationMethod.TargetScan
)!;

const resolveGuestValidationMethods = (values: GuestValidationMethod[]): Option => {
  const [value] = values || ([] as GuestValidationMethod[]);
  return findOptionByValue(LIST_OPTIONS.guestValidationMethod, value) || defaultGuestValidationMethodOption;
};

const getOutOfStockManagementValue = (status: boolean): Option => {
  const selectedOption = status
    ? LIST_OPTIONS.outOfStockManagement.find((o) => o.value)
    : LIST_OPTIONS.outOfStockManagement.find((o) => !o.value);
  return selectedOption as Option;
};

const getItemCommentsModOption = (status?: CommentSubmissionMethod): Option => {
  const defaultOption = LIST_OPTIONS.itemCommentsSubmission.find(
    (o) => o.value === CommentSubmissionMethod.UsePOSItemCommentField
  ) as Option;
  const selectedOption = LIST_OPTIONS.itemCommentsSubmission.find((o) => o.value === status);

  return selectedOption ?? defaultOption;
};

const MobileOrdering = (props: Props) => {
  const { isProcessing, updateLocationParam, mobileOrdering, ignoreInStockFromPosItem } = props;
  const dispatch = useDispatch();

  const { companyId, id: locationId } = useParams<{
    companyId: string;
    id: string;
  }>();

  React.useEffect(() => {
    const modifierId = mobileOrdering.itemComments.posModifierId;
    if (modifierId) dispatch(getInitPOSModifier(companyId, locationId, modifierId));
  }, [dispatch, companyId, locationId, mobileOrdering.itemComments.posModifierId]);

  return (
    <div>
      <FormControl label='Availability' additionalStyles={styles.toggle}>
        <TextField value={'Mobile Orders'} />
        <Toggle
          checked={mobileOrdering.orderingEnabled}
          onChange={(checked: boolean) => {
            updateLocationParam(!mobileOrdering.orderingEnabled, LOCATION_SETTINGS_ACTIONS.TOGGLE_MOBILE_ORDERING);
          }}
          loading={isProcessing}
        />
      </FormControl>
      <FormControl label='' additionalStyles={styles.toggle}>
        <TextField value={'Alcohol Sales'} />
        <Toggle
          checked={mobileOrdering.alcoholOrderingEnabled}
          onChange={(checked: boolean) => {
            updateLocationParam(
              !mobileOrdering.alcoholOrderingEnabled,
              LOCATION_SETTINGS_ACTIONS.TOGGLE_ALCOHOL_ORDERING
            );
          }}
          loading={isProcessing}
        />
      </FormControl>
      <FormControl label='SMS Notifications'>
        <Toggle
          checked={mobileOrdering.orderSmsEnabled}
          onChange={(checked: boolean) => {
            updateLocationParam(checked, LOCATION_SETTINGS_ACTIONS.UPDATE_SMS_NOTIFICATIONS_ENABLED);
          }}
          loading={isProcessing}
        />
      </FormControl>
      <FormControl label='Open Tab (Order without payment)'>
        <Toggle
          checked={mobileOrdering.orderDoesNotRequireImmediatePayment}
          onChange={(checked: boolean) => {
            updateLocationParam(
              !mobileOrdering.orderDoesNotRequireImmediatePayment,
              LOCATION_SETTINGS_ACTIONS.UPDATE_DEFERRED_PAYMENT
            );
          }}
          loading={isProcessing}
        />
      </FormControl>
      {mobileOrdering.orderDoesNotRequireImmediatePayment && (
        <>
          <FormControl>
            <SelectFilter
              placeholder='Select an Option'
              options={LIST_OPTIONS.guestValidationMethod}
              loading={isProcessing}
              value={resolveGuestValidationMethods(mobileOrdering.openTabGuestPresenceValidationMethods || [])}
              defaultValue={defaultGuestValidationMethodOption}
              onChange={(option: Option) => {
                updateLocationParam([option.value], LOCATION_SETTINGS_ACTIONS.UPDATE_GUEST_VALIDATION_METHOD);
              }}
            />
          </FormControl>
          <FormControl>
            <FormControl label='Prompt guest to re-scan after' labelColumnSpace={2}>
              <FormattedInput
                format='number'
                customSuffix='Min'
                placeholder='45'
                value={mobileOrdering.openTabGuestPresenceTargetScanDurationMinutes!}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  updateLocationParam(e.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_GUEST_PRESENCE_DURATION_MINUTES);
                }}
                loading={isProcessing}
              />
            </FormControl>
          </FormControl>
          <FormControl>
            <TextArea
              value={mobileOrdering.openTabGuestPresenceTargetScanExpiryMessage || ''}
              placeholder={guestPresenceExpiryMessagePlaceholder}
              maxLength={125}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                updateLocationParam(e.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_GUEST_PRESENCE_EXPIRE_MESSAGE);
              }}
              loading={isProcessing}
            />
          </FormControl>

          <FormControl>
            <SelectFilter
              placeholder='Select an Option'
              options={LIST_OPTIONS.singleCheckPos}
              loading={isProcessing}
              value={getSingleCheckPosValue(mobileOrdering.alwaysAppendOrdersToExistingCheck || false)}
              onChange={(option: Option) => {
                updateLocationParam(option.value === 1, LOCATION_SETTINGS_ACTIONS.UPDATE_SINGLE_CHECK_POS);
              }}
            />
          </FormControl>
        </>
      )}
      {mobileOrdering.alwaysAppendOrdersToExistingCheck && mobileOrdering.orderDoesNotRequireImmediatePayment && (
        <FormControl>
          <Checkbox
            label='Require server to start the check before guests can order'
            checked={!!mobileOrdering.requireServerCreatedCheckBeforeGuestOrder}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              updateLocationParam(
                !mobileOrdering.requireServerCreatedCheckBeforeGuestOrder,
                LOCATION_SETTINGS_ACTIONS.UPDATE_START_CHECK_BEFORE_GUESTS_ORDER
              );
            }}
            loading={isProcessing}
          />
        </FormControl>
      )}
      <FormControl label='Out of Stock Management'>
        <SelectFilter
          placeholder='Select an Option'
          options={LIST_OPTIONS.outOfStockManagement}
          loading={isProcessing}
          value={getOutOfStockManagementValue(ignoreInStockFromPosItem)}
          onChange={(option: Option) => {
            updateLocationParam(option.value === 1, LOCATION_SETTINGS_ACTIONS.UPDATE_POS_OUT_OF_STOCK_MANAGEMENT);
          }}
        />
      </FormControl>
      <FormControl label='Alcohol Policy'>
        <TextArea
          value={mobileOrdering.alcoholPolicy || ''}
          placeholder={alcoholPolicyPlaceHolder}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            updateLocationParam(e.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_ALCOHOL_POLICY);
          }}
          loading={isProcessing}
        />
      </FormControl>
      <FormControl label='Service Offline Message'>
        <TextArea
          value={mobileOrdering.disruptionMessage || ''}
          placeholder={serviceOfflinePlaceHolder}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            updateLocationParam(e.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_DISRUPTION_MESSAGE);
          }}
          loading={isProcessing}
        />
      </FormControl>
      <FormControl label='Menu Announcements Message'>
        <TextArea
          value={mobileOrdering.menuAnnouncementsMessage || ''}
          placeholder={menuAnnouncementsPlaceholder}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            updateLocationParam(e.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_MENU_ANNOUNCEMENT);
          }}
          loading={isProcessing}
        />
      </FormControl>
      <FormControl label='Pickup Instructions'>
        <TextArea
          value={mobileOrdering.pickupInstructions || ''}
          placeholder={pickUpInstructionsPlaceholder}
          onChange={(e) => {
            updateLocationParam(e.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_DELIVERY_DETAILS);
          }}
          loading={isProcessing}
        />
      </FormControl>
      <FormControl label='Pickup Location Name'>
        <TextInput
          value={mobileOrdering.pickupLocationName || ''}
          placeholder={pickUpLocationPlaceholder}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            updateLocationParam(e.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_DELIVERY_LOCATION);
          }}
          loading={isProcessing}
        />
      </FormControl>
      <FormControl label='Checkout Announcements Message'>
        <TextArea
          value={mobileOrdering.checkoutAnnouncementMessage ?? ''}
          placeholder={checkoutAnnouncementsPlaceholder}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            updateLocationParam(e.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_CHECKOUT_ANNOUNCEMENT);
          }}
          loading={isProcessing}
        />
      </FormControl>
      <FormControl label='Order Confirmed Message'>
        <TextArea
          value={mobileOrdering.orderConfirmedMessage || ''}
          placeholder={orderConfirmedPlaceholder}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            updateLocationParam(e.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_NAVIGATION_TEXT);
          }}
          loading={isProcessing}
        />
      </FormControl>
      <FormControl label='Item Comments'>
        <div className='control--stacked'>
          <Toggle
            checked={mobileOrdering.itemComments.enableItemComments}
            onChange={(checked: boolean) => {
              updateLocationParam(
                !mobileOrdering.itemComments.enableItemComments,
                LOCATION_SETTINGS_ACTIONS.UPDATE_ALLOW_ITEM_COMMENTS
              );
            }}
            loading={isProcessing}
          />
        </div>
        {mobileOrdering.itemComments.enableItemComments && (
          <>
            <div className={`control--stacked ${styles.itemCommentsCharLimits}`}>
              <TextInputNestedLabel
                loading={isProcessing}
                label='Char. Limit'
                type='number'
                value={mobileOrdering.itemComments.maxItemCommentLength ?? 0}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  updateLocationParam(Number(e.target.value), LOCATION_SETTINGS_ACTIONS.UPDATE_ITEM_COMMENTS_LENGTH);
                }}
              />
            </div>
            <div className='control--stacked'>
              <SelectFilter
                placeholder='Select an Option'
                options={LIST_OPTIONS.itemCommentsSubmission}
                loading={isProcessing}
                value={getItemCommentsModOption(mobileOrdering.itemComments.commentSubmissionMethod)}
                onChange={(option: Option) => {
                  updateLocationParam(option.value, LOCATION_SETTINGS_ACTIONS.UPDATE_COMMENT_SUBMISSION);
                }}
              />
            </div>
            {mobileOrdering.itemComments.commentSubmissionMethod === CommentSubmissionMethod.AttachCommentModifier && (
              <div className='control--stacked'>
                <POSModifierSearch
                  companyId={companyId}
                  locationId={locationId}
                  updateLocationParam={updateLocationParam}
                  modifiers={mobileOrdering.posModifierSelection.items}
                  selectedModifier={mobileOrdering.posModifierSelection.selectedItem}
                  loading={mobileOrdering.posModifierSelection.loading}
                />
              </div>
            )}
          </>
        )}
      </FormControl>

      <SubmitOrderToPosFormControl />

      <FormControl label='Optional Features'>
        <div className='control--stacked'>
          <Checkbox
            label='Display the name of the location'
            checked={mobileOrdering.enableOrderLocationWidget}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              updateLocationParam(
                !mobileOrdering.enableOrderLocationWidget,
                LOCATION_SETTINGS_ACTIONS.UPDATE_ORDER_WIDGET
              );
            }}
            loading={isProcessing}
          />
        </div>
      </FormControl>
    </div>
  );
};

export default MobileOrdering;
