import { OrderExperienceType } from '@ready/dashboardv2api.contracts';
import { ISinglePlacedOrder } from '../../services/types/orders.type';

const filterOrders = (orders: ISinglePlacedOrder[], searchTerm: string): ISinglePlacedOrder[] => {
  if (searchTerm) {
    return orders.filter((order) => {
      const matchPrimaryField: boolean =
        order.identityInfo?.primary.toLowerCase().includes(searchTerm.toLowerCase()) || false;

      if (matchPrimaryField) return true;

      let matchSecondaryField = false;

      order.identityInfo?.secondary
        .map((value) => value.toLowerCase())
        .forEach((value) => {
          if (value.includes(searchTerm.toLowerCase())) matchSecondaryField = true;
        });

      if (matchSecondaryField) return true;

      // only match table name if orderExperienceType is dineIn
      // since Table is hardcoded it needs to be readded here for comparisson
      const matchTableName: boolean =
        (order.tableInfo?.orderExperienceType === OrderExperienceType.DineIn &&
          `Table ${order.tableInfo?.tableName}`.toLowerCase().includes(searchTerm.toLowerCase())) ||
        false;

      if (matchTableName) return true;

      return false;
    });
  } else {
    return orders;
  }
};

export default filterOrders;
