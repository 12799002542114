import React from 'react';
import { FormControl } from '../../../../../components/Form';
import Button from '../../../../../components/Button/Button';
import GuestInfoField from './GuestInfoField';
// utils
import { LOCATION_SETTINGS_ACTIONS } from '../../../../redux/LocationSettingsActions';
// types
import { GuestInformationField } from '@ready/dashboardv2api.contracts';
import { IOrderAheadValidation, IGuestInfoFieldError } from '../../../../redux/PanelFeaturesState';
import { IUpdateLocationParam } from '../../../LocationsEditPage';

interface Props {
  guestInfoFields: GuestInformationField[];
  updateLocationParam: IUpdateLocationParam;
  validation: IOrderAheadValidation;
  isProcessing?: boolean;
}

const GuestInfoFields = (props: Props) => {
  const { guestInfoFields, updateLocationParam, validation, isProcessing = false } = props;

  const addNewGuestField = () => {
    const isPrimary = guestInfoFields.length === 0;
    const newGuestField: GuestInformationField = {
      isPrimaryOnPaymentConfirmationScreen: isPrimary,
      fieldName: '',
      placeholder: '',
      fieldType: 'text',
      methodForSending: 'fakeItem',
      charLimit: 120, // herb validate this
      inputIsRequired: true,
    };

    const newGuestFieldError: IGuestInfoFieldError = {
      fieldName: false,
      placeholder: false,
      methodForSending: false,
      charLimit: false,
    };

    const newVals = {
      newGuestField,
      newGuestFieldError,
    };

    updateLocationParam(newVals, LOCATION_SETTINGS_ACTIONS.ADD_GUEST_INFO);
  };

  const removeGuestField = (index: number) => {
    updateLocationParam(index, LOCATION_SETTINGS_ACTIONS.REMOVE_GUEST_INFO);
  };

  const controlLabel = guestInfoFields.length === 0 ? 'Guest Information' : '';

  return (
    <div>
      {guestInfoFields.map((guestInfo: GuestInformationField, i: number) => {
        return (
          <GuestInfoField
            guestInfo={guestInfo}
            key={i}
            index={i}
            removeGuestField={removeGuestField}
            updateLocationParam={updateLocationParam}
            validation={validation}
            isProcessing={isProcessing}
          ></GuestInfoField>
        );
      })}
      <FormControl label={controlLabel} additionalStyles='full-width-button'>
        <Button
          variant='secondary'
          label='+ Add Guest Info Field'
          onClick={() => addNewGuestField()}
          loading={isProcessing}
        />
      </FormControl>
    </div>
  );
};

export default GuestInfoFields;
