import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Modal, ModalBody, ModalHeader, ModalFooter } from '../../../components/Modal';
import Checkbox from '../../../components/Checkbox/Checkbox';
import { AppState } from '../../../redux/initialStates/AppState';
import {
  advancedSettingsDialogVisible,
  advancedSettingsMenuSecondaryNavigation,
  advancedSettingsReadOnlyMenus,
  advancedSettingsResetSettings,
  advancedSettingsSaveSettings,
  saveMenuAdvancedSettings,
} from '../../redux/MenusActions';

const mapStateToProps = (state: AppState) => ({
  advancedSettings: state.menuBuilder.menus.advancedSettings,
});

const actionCreators = {
  advancedSettingsDialogVisible,
  advancedSettingsMenuSecondaryNavigation,
  advancedSettingsReadOnlyMenus,
  advancedSettingsResetSettings,
  advancedSettingsSaveSettings,
  saveMenuAdvancedSettings,
};

const connector = connect(mapStateToProps, actionCreators);

export type AdvancedSettingsDialogProps = ConnectedProps<typeof connector> & {
  companyId: string;
  locationId: string;
};

const AdvancedSettingsDialog = (props: AdvancedSettingsDialogProps) => {
  const {
    advancedSettings,
    advancedSettingsDialogVisible,
    advancedSettingsMenuSecondaryNavigation,
    advancedSettingsReadOnlyMenus,
    advancedSettingsResetSettings,
    saveMenuAdvancedSettings,
    companyId,
    locationId,
  } = props;

  const handleCancel = () => {
    advancedSettingsResetSettings();
    advancedSettingsDialogVisible(false);
  };

  const handleSave = () => {
    saveMenuAdvancedSettings(
      companyId,
      locationId,
      advancedSettings.settings.menuSectionsSecondaryNavigation,
      advancedSettings.settings.readOnlyMenus
    );
  };

  return (
    <Modal setShowModal={advancedSettingsDialogVisible}>
      <ModalHeader setShowModal={advancedSettingsDialogVisible} headerLabel='Advanced Settings' />
      <ModalBody>
        <Checkbox
          label='Display menu sections as secondary navigation'
          checked={advancedSettings.settings.menuSectionsSecondaryNavigation}
          onChange={(e) => {
            advancedSettingsMenuSecondaryNavigation(e.target.checked);
          }}
        />
        <Checkbox
          label='Make menus read only'
          checked={advancedSettings.settings.readOnlyMenus}
          onChange={(e) => {
            advancedSettingsReadOnlyMenus(e.target.checked);
          }}
        />
      </ModalBody>
      <ModalFooter
        primaryActionHandler={handleSave}
        primaryLabel='Save Changes'
        secondaryActionHandler={handleCancel}
        secondaryLabel='Cancel'
        loading={advancedSettings.loading}
      />
    </Modal>
  );
};

export default connector(AdvancedSettingsDialog);
