import { DigitalMenuMode, IDigitalMenuConfig } from '@ready/dashboardv2api.contracts';
import config from '../../config';
import executeApiAsync from '../../services/utils/executeApiAsync';
import { IDigitalMenuValidations } from '../redux/initialStates/digitalMenu';

export class DigitalMenuService {
  public static validateDigitalMenu = (digitalMenuConfig: IDigitalMenuConfig): IDigitalMenuValidations => {
    const menuMode = digitalMenuConfig.menuMode;
    let result: IDigitalMenuValidations = {
      hasError: false,
      errorMessage: '',
    };
    if (menuMode === DigitalMenuMode.Iframe) {
      if (!digitalMenuConfig.iframeUrl) {
        result.hasError = true;
        result.errorMessage = 'Field is required.';
      }
    }
    if (menuMode === DigitalMenuMode.Image) {
      if (!digitalMenuConfig?.imageKey) {
        result.hasError = true;
        result.errorMessage = 'Image upload is required.';
      }
    }
    return result;
  };

  public static getClientDigitalMenuConfig = (
    companyId: string,
    locationId: string
  ): Promise<IDigitalMenuConfig | undefined> => {
    const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/digitalMenuConfig`;

    return executeApiAsync<IDigitalMenuConfig | undefined>(url);
  };

  public static updateClientDigitalMenuConfig = (
    companyId: string,
    locationId: string,
    digitalMenuConfig: IDigitalMenuConfig
  ): Promise<IDigitalMenuConfig | undefined> => {
    const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/digitalMenuConfig`;

    return executeApiAsync<IDigitalMenuConfig | undefined>(url, {
      method: 'PUT',
      body: JSON.stringify(digitalMenuConfig),
    });
  };
}
