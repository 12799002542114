import { ITemplateForcedModifier } from '@ready/menu.core';
import Divider from 'components/Divider/Divider';
import TextIcon, { Icon } from 'components/Icon/TextIcon';
import IconButton from 'components/IconButton/IconButton';
import React, { Fragment } from 'react';
import { Link, useParams } from 'react-router-dom';
import { setFormIsDirty } from 'redux/actions/uiActions/formStateActions';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { unselectForcedModifier } from 'sharedMenuItems/redux/createEditItem/createEditItemSlice';
import { selectCreateEditItemState } from 'sharedMenuItems/redux/selectors';
import { SharedMenuItemsRoutes } from 'sharedMenuItems/Router';
import { ContextParams } from 'types/ContextParams.interface';
import styles from './ForcedModifiersList.module.scss';

export const ForcedModifiersList = () => {
  const { item } = useAppSelector(selectCreateEditItemState);
  const dispatch = useAppDispatch();
  const { contextId: companyId, id: sharedMenuItemId } = useParams<ContextParams>();

  const forcedMods = item?.forcedModifiers;

  const removeForcedMod = (id: string) => {
    dispatch(unselectForcedModifier(id));
    dispatch(setFormIsDirty(true));
  };

  return (
    <div className={styles.forcedMods}>
      {forcedMods?.map((forcedModifier: ITemplateForcedModifier) => (
        <Fragment key={forcedModifier.itemId}>
          <div className={styles.forcedMod}>
            <Link
              to={SharedMenuItemsRoutes.getForcedModifierFromItemPage(
                companyId,
                sharedMenuItemId,
                forcedModifier.itemId
              )}
              className={styles.forcedModifierLink}
            >
              <div className={styles.displayNameContainer}>
                <div>{forcedModifier.displayName}</div>
              </div>
            </Link>

            <div className={styles.removeAndNumberOfLocationsContainer}>
              {/* Todo: uncomment once assigned locations is added to the response */}
              {/* <AssignedLocationsContent numberOfLocations={100} additionalStyles={styles.assgnedLocationsWidth} /> */}
              <IconButton onClick={() => removeForcedMod(forcedModifier.itemId)}>
                <TextIcon icon={Icon.RemoveCircle}></TextIcon>
              </IconButton>
            </div>
          </div>
          <Divider size='small' />
        </Fragment>
      ))}
    </div>
  );
};
