export const PAYMENT_PROCESSING_ACTIONS = {
  UPDATE_LOCATION: 'UPDATE_LOCATION',
  INIT_PAYPAL_INFO_BEGIN: 'INIT_PAYPAL_INFO_BEGIN',
  INIT_PAYPAL_INFO_SUCCESS: 'INIT_PAYPAL_INFO_SUCCESS',
  INIT_PAYPAL_INFO_ERROR: 'INIT_PAYPAL_INFO_ERROR',
  UNLINK_PAYPAL_MODAL: 'UNLINK_PAYPAL_MODAL',
  UNLINK_PAYPAL_BEGIN: 'UNLINK_PAYPAL_BEGIN',
  UNLINK_PAYPAL_SUCCESS: 'UNLINK_PAYPAL_SUCCESS',
  UPDATE_PAYPAL_CONFIG_BEGIN: 'UPDATE_PAYPAL_CONFIG_BEGIN',
  UPDATE_PAYPAL_CONFIG_SUCCESS: 'UPDATE_PAYPAL_CONFIG_SUCCESS',
  UPDATE_PAYPAL_CONFIG_ERROR: 'UPDATE_PAYPAL_CONFIG_ERROR',
  RESET_PAYPAL_CONFIG: 'RESET_PAYPAL_CONFIG',

  TOGGLE_GIFT_CARD_CONFIG_BEGIN: 'TOGGLE_GIFT_CARD_CONFIG_BEGIN',
  TOGGLE_GIFT_CARD_CONFIG_SUCCESS: 'TOGGLE_GIFT_CARD_CONFIG_SUCCESS',
  TOGGLE_GIFT_CARD_CONFIG_ERROR: 'TOGGLE_GIFT_CARD_CONFIG_ERROR',
  TOGGLE_GIFT_CARD_EDIT_FORM: 'TOGGLE_GIFT_CARD_EDIT_FORM',

  UPDATE_GIFT_CARD_PANEL_EDIT_STATE: 'UPDATE_GIFT_CARD_PANEL_EDIT_STATE',
  UPDATE_GIFT_CARD_PROVIDER: 'UPDATE_GIFT_CARD_PROVIDER',
  SET_GIFT_CARD_VIEW_CONFIG: 'SET_GIFT_CARD_VIEW_CONFIG',
  SET_GIFT_CARD_EDIT_CONFIG: 'SET_GIFT_CARD_EDIT_CONFIG',

  GET_GIFT_CARD_TENDER_TYPES_BEGIN: 'GET_GIFT_CARD_TENDER_TYPES_BEGIN',
  GET_GIFT_CARD_TENDER_TYPES_SUCCESS: 'GET_GIFT_CARD_TENDER_TYPES_SUCCESS',
  GET_GIFT_CARD_TENDER_TYPES_ERROR: 'GET_GIFT_CARD_TENDER_TYPES_ERROR',

  INIT_GIFT_CARD_CONFIG_BEGIN: 'INIT_GIFT_CARD_CONFIG_BEGIN',
  INIT_GIFT_CARD_CONFIG_SUCCESS: 'INIT_GIFT_CARD_CONFIG_SUCCESS',
  INIT_GIFT_CARD_CONFIG_ERROR: 'INIT_GIFT_CARD_CONFIG_ERROR',

  SAVE_GIFT_CARD_CONFIG_BEGIN: 'SAVE_GIFT_CARD_CONFIG_BEGIN',
  SAVE_GIFT_CARD_CONFIG_SUCCESS: 'SAVE_GIFT_CARD_CONFIG_SUCCESS',
  SAVE_GIFT_CARD_CONFIG_ERROR: 'SAVE_GIFT_CARD_CONFIG_ERROR',

  CHANGE_GIFT_CARD_CONFIG_BEGIN: 'CHANGE_GIFT_CARD_CONFIG_BEGIN',
  CHANGE_GIFT_CARD_CONFIG_SUCCESS: 'CHANGE_GIFT_CARD_CONFIG_SUCCESS',
  CHANGE_GIFT_CARD_CONFIG_ERROR: 'CHANGE_GIFT_CARD_CONFIG_ERROR',

  RESET_GIFT_CARD_CONFIG: 'RESET_GIFT_CARD_CONFIG',
  UPDATE_GIFT_CARD_FORM_VALIDATION: 'UPDATE_GIFT_CARD_FORM_VALIDATION',

  SET_POS_INTEGRATION: 'SET_POS_INTEGRATION',
  SET_TENDER_TYPES: 'SET_TENDER_TYPES',
};
