import moment from 'moment';
import { IPickupHours, IPickupTimes } from '@ready/dashboardv2api.contracts';


export const validateStartTime = (startTime: string, endTime: string) => {
  if (startTime === '') {
    return 'Enter a valid time.';
  }

  const startTimeFormatted = moment(startTime, 'HH:mm');
  const endTimeFormatted = moment(endTime, 'HH:mm');
  if (startTimeFormatted.isSameOrAfter(endTimeFormatted)) {
    return 'Start Time must be before End Time';
  }
};

export const validateEndTime = (endTime: string) => {
  if (endTime === '') {
    return 'Enter a valid time.';
  }
};

export const formatTimeValue = (time: string) => moment(time, ['HH:mm', 'HH:m', 'HH']).format('HH:mm:ss');
export const formatTimeInput = (time: string) => moment(time, ['HH:mm', 'HH:m', 'HH, HH:mm:ss']).format('HH:mm');

export const offsetTimeForAllDaysValue = (orderHours: IPickupHours): string | undefined => {
  // Checking Monday to compare against other values.
  // This can be achieved picking any other day
  const mondayOffset = orderHours.monday.offset;
  let offset: string | undefined = mondayOffset;

  // undefined represents if a custom offset time was set before
  Object.values(orderHours).forEach((value: IPickupTimes) => {
    if (value.offset !== mondayOffset) {
      offset = undefined;
    }
  });
  return offset;
};
