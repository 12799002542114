import React from 'react';
import Select, { components, IndicatorProps, InputActionMeta } from 'react-select';
import { SelectComponents } from 'react-select/src/components';
import { NoticeProps } from 'react-select/src/components/Menu';
import Container from '../Container/Container';
import TextIcon, { Icon } from '../Icon/TextIcon';
import DefaultDropdownIndicator from '../DropdownIndicator/DropdownIndicator';
import readyProgressSpinner from '../../styles/assets/ready-progress-spinner-gray.svg';

export type DefaultOptionValue = string | number | any;

export interface Option<TOptionValue = DefaultOptionValue> {
  value: TOptionValue;
  label: string;
  subLabel?: string;
  icon?: Icon;
  isDisabled?: boolean;
}

export interface GroupedOption {
  label: string;
  options: Option[];
}

export interface SelectFilterProps<TOptionValue = DefaultOptionValue> {
  options: (Option<TOptionValue> | GroupedOption)[];
  onMenuOpen?: () => void;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  onChange?: (data: Option<TOptionValue>) => void;
  filterOption?: ((option: Option, rawInput: string) => boolean) | null;
  value?: Option<TOptionValue> | Option[] | null;
  defaultValue?: Option<TOptionValue> | Option[] | null;
  largeSize?: boolean;
  isSearchable?: boolean;
  isClearable?: boolean;
  placeholder?: string | React.ReactNode;
  isMulti?: boolean;
  disabled?: boolean;
  loading?: boolean;
  populating?: boolean;
  populated?: boolean;
  withError?: boolean;
  isInModal?: boolean;
  hideIndicator?: boolean;
  additionalStyles?: string;
  additionalSelectStyles?: string;
  // useful for 'useForm' hook
  name?: string;
  setValue?: (name: string, data: any) => void;
  customComponents?: Partial<SelectComponents<Option, boolean>>;
  autoFocus?: boolean;
  menuIsOpen?: boolean;
  withoutBorder?: boolean;
}

const SelectFilter = <TOptionValue extends DefaultOptionValue>(props: SelectFilterProps<TOptionValue>) => {
  const {
    options,
    onMenuOpen,
    onInputChange,
    onChange,
    filterOption,
    value,
    defaultValue,
    largeSize = false,
    isSearchable = false,
    isClearable = false,
    placeholder = '',
    isMulti = false,
    disabled = false,
    loading = false,
    populating = false,
    populated = true,
    withError = false,
    isInModal = false,
    hideIndicator = false,
    name,
    setValue,
    additionalStyles = '',
    additionalSelectStyles = '',
    customComponents,
    autoFocus = false,
    menuIsOpen,
    withoutBorder = false,
  } = props;

  const handleChange = (data: any) => {
    // if 'useForm' hook present, then setValue
    if (name && setValue) {
      setValue(name, data);
    }
    // call default onChange prop method
    if (onChange) onChange(data);
  };

  const isSearchableClassName = isSearchable ? 'select-filter--searchable' : '';
  const loadingClassName = loading ? 'select-filter--loading' : '';
  const disabledClassName = disabled ? 'select-filter--disabled' : '';
  const errorClassName = withError ? 'select-filter--with-error' : '';
  const withoutBorderClassName = withoutBorder ? 'select-filter--without-border' : '';

  const reactSelectProps = {
    className: `select-filter ${isSearchableClassName} ${loadingClassName} ${disabledClassName} ${errorClassName} ${withoutBorderClassName} ${additionalSelectStyles}`,
    classNamePrefix: 'select-filter',
  };

  let containerClass = additionalStyles ? 'select-filter-container ' + additionalStyles : 'select-filter-container';
  if (largeSize) {
    containerClass += ' select-filter-container--large';
  }

  const ClearIndicator = (props: IndicatorProps<Option, boolean>) => (
    <components.ClearIndicator {...props}>
      <TextIcon icon={Icon.RemoveCircle} />
    </components.ClearIndicator>
  );

  const NoOptionsMessage = (props: NoticeProps<Option, boolean>): JSX.Element => (
    <components.NoOptionsMessage {...props}>
      {!populated || populating ? (
        <div className='select-filter__loading'>
          <img src={readyProgressSpinner} alt='loading' />
          Searching...
        </div>
      ) : (
        'No results found'
      )}
    </components.NoOptionsMessage>
  );

  const DropdownIndicator = hideIndicator ? () => null : DefaultDropdownIndicator;

  return (
    <Container additionalClassName={containerClass}>
      <Select
        menuIsOpen={menuIsOpen}
        autoFocus={autoFocus}
        options={options as Option[]}
        isSearchable={isSearchable}
        isClearable={isClearable}
        placeholder={placeholder}
        onMenuOpen={onMenuOpen}
        onInputChange={onInputChange}
        onChange={(data: any) => handleChange(data)}
        filterOption={filterOption}
        value={value}
        isMulti={isMulti}
        isDisabled={loading || disabled}
        components={{
          ClearIndicator,
          NoOptionsMessage,
          DropdownIndicator,
          ...customComponents,
        }}
        defaultValue={defaultValue}
        menuPosition={isInModal ? 'fixed' : undefined}
        {...reactSelectProps}
      />
    </Container>
  );
};

export default SelectFilter;
