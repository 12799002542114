import { IInfiniteScrollingListModalState } from 'redux/initialStates/ui/infiniteScrollingListModalState';
import { IOverflowButtonState } from 'redux/initialStates/ui/overflowButtonState';
import { ISearchParamsState } from 'redux/slices/searchParamsSlice';
import { IFormState } from '../../initialStates/ui/formState';
import { RootState } from '../../store';

export const selectFormState = (state: RootState): IFormState => state.ui.formState;
export const selectOverflowButtonState = (state: RootState): IOverflowButtonState => state.ui.overflowButtonState;
export const selectSearchParamsState = (state: RootState): ISearchParamsState => state.ui.searchParamsState;
export const selectInfiniteScrollingListModalState = (state: RootState): IInfiniteScrollingListModalState =>
  state.ui.infiniteScrollingListModalState;
