import { IItemGallery, ITemplateItemGallery } from '@ready/menu.core';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleCoordinatedExecutionResult, MENUS_ACTIONS } from 'menus/redux/MenusActions';
import MenuBuilderService from 'menus/services/MenuBuilderService';
import { SectionItemChange } from 'menus/types/SectionItemChange.interface';
import { toastErrorState } from 'redux/actions/uiActions/responseStateActions';
import { mapITemplateItemGalleryToIItemGallery } from 'sharedMenuItems/pages/menu/menu.mapper';
import { getTemplateMenuGroupItemGallery } from 'sharedMenuItems/pages/menu/menu.service';
import { GetSharedMenuOrGalleryArgs } from 'sharedMenuItems/pages/menu/redux/thunks';
import { CoordinatedExecutionResult } from 'utils/asyncUtils/coordinateExecutions';

export const getSharedItemGalleryThunk = createAsyncThunk<void, GetSharedMenuOrGalleryArgs>(
  'sharedMenus/itemGallery/fetch',
  async ({ companyId, menuOrItemGalleryId }: GetSharedMenuOrGalleryArgs, thunkAPI) => {
    thunkAPI.dispatch({
      type: MENUS_ACTIONS.MENU_GALLERY_SET_EDIT_MODE_BEGIN,
      payload: menuOrItemGalleryId,
    });

    thunkAPI.dispatch({
      type: MENUS_ACTIONS.VIEW_MENU_GALLERY_BEGIN,
      payload: menuOrItemGalleryId,
    });

    try {
      const result = await getTemplateMenuGroupItemGallery(companyId, menuOrItemGalleryId);
      const mappedItemGallery = mapITemplateItemGalleryToIItemGallery(result);

      thunkAPI.dispatch({
        type: MENUS_ACTIONS.VIEW_MENU_GALLERY_SUCCESS,
        payload: mappedItemGallery,
      });

      thunkAPI.dispatch({
        type: MENUS_ACTIONS.MENU_GALLERY_SET_EDIT_MODE_SUCCESS,
        payload: mappedItemGallery,
      });
    } catch (error) {
      thunkAPI.dispatch({
        type: MENUS_ACTIONS.VIEW_MENU_GALLERY_ERROR,
        payload: menuOrItemGalleryId,
      });
      thunkAPI.dispatch({ type: MENUS_ACTIONS.MENU_GALLERY_SET_EDIT_MODE_ERROR });
      thunkAPI.dispatch(toastErrorState(error.status, error.message));
    }
  }
);

interface UpdateSharedItemGalleryArgs {
  companyId: string;
  itemGallery: IItemGallery;
  sectionItemChanges: SectionItemChange[];
}

export const updateSharedItemGalleryThunk = createAsyncThunk<void, UpdateSharedItemGalleryArgs>(
  'sharedMenus/itemGallery/update',
  async ({ companyId, itemGallery, sectionItemChanges }: UpdateSharedItemGalleryArgs, thunkAPI) => {
    if (!itemGallery?.displayName) {
      thunkAPI.dispatch({
        type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_GALLERY_VALIDATED,
        payload: {
          hasError: true,
          errorMessage: 'Item Gallery Name is required',
        },
      });
      return;
    }

    thunkAPI.dispatch({ type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_GALLERY_BEGIN });

    try {
      const result = (await MenuBuilderService.saveMenuGallery(
        companyId,
        '',
        itemGallery,
        sectionItemChanges
      )) as CoordinatedExecutionResult<ITemplateItemGallery>;

      handleCoordinatedExecutionResult(
        thunkAPI.dispatch,
        { ...result, returnValue: mapITemplateItemGalleryToIItemGallery(result.returnValue) },
        MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_GALLERY_SUCCESS,
        MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_GALLERY_ERROR,
        'Success! Changes saved.'
      );
      thunkAPI.dispatch({
        type: MENUS_ACTIONS.VIEW_MENU_GALLERY_SUCCESS,
        payload: mapITemplateItemGalleryToIItemGallery(result.returnValue),
      });
    } catch (error) {
      thunkAPI.dispatch({ type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_GALLERY_ERROR });
      thunkAPI.dispatch(toastErrorState(error.status, error.message));
    }
  }
);
