import React from 'react';

export interface TableFooterProps {
  children: JSX.Element;
}

const TableFooter = (props: TableFooterProps) => {
  const { children } = props;

  return <div className='table__footer'>{children}</div>;
};

export default TableFooter;
