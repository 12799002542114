import { IMenuGroup } from '@ready/menu.core';
import { ActionReducerMapBuilder, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getMenuConfigsForLocationThunk } from './thunks/getMenuConfigs';

export interface IMenuConfigsState {
  menuConfigs: IMenuGroup[];
  loading: boolean;

  userSelectedMenuConfigId?: string;
}

const initialState: IMenuConfigsState = {
  menuConfigs: [],
  loading: true,
};

const reducers = {
  setUserSelectedMenuConfigId: (state: IMenuConfigsState, action: PayloadAction<string | undefined>): void => {
    state.userSelectedMenuConfigId = action.payload;
  },
};

/** Slice for menu configs at the location level */
export const menuConfigSlice = createSlice({
  name: 'menuBuilder/menuConfigs',
  initialState: initialState,
  reducers,
  extraReducers: (builder: ActionReducerMapBuilder<IMenuConfigsState>) => {
    builder.addCase(getMenuConfigsForLocationThunk.pending, (state: IMenuConfigsState) => {
      state.loading = true;
    });
    builder.addCase(getMenuConfigsForLocationThunk.fulfilled, (state: IMenuConfigsState, action) => {
      state.loading = false;
      const { items } = action.payload;
      state.menuConfigs = items;
    });
    builder.addCase(getMenuConfigsForLocationThunk.rejected, (state: IMenuConfigsState) => {
      state.loading = false;
    });
  },
});

export const { setUserSelectedMenuConfigId } = menuConfigSlice.actions;
