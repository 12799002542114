import React, { FC } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import SecurityRoleCreatePage from './pages/SecurityRoleCreatePage/SecurityRoleCreatePage';
import SecurityRolesListPage from './pages/SecurityRoleListPage/SecurityRoleListPage';
import SecurityRoleViewPage from './pages/SecurityRoleViewPage/SecurityRoleViewPage';
import SecurityRoleEditPage from './pages/SecurityRoleEditPage/SecurityRoleEditPage';
import { usePageTitle } from '../hooks';

const SecurityRoleRouter: FC<{}> = () => {
  const { path } = useRouteMatch();
  usePageTitle('Security Roles');

  return (
    <Switch>
      <Route path={`${path}/new`} component={SecurityRoleCreatePage} />
      <Route path={`${path}/:roleId/edit`} component={SecurityRoleEditPage} />
      <Route path={`${path}/:roleId`} component={SecurityRoleViewPage} />
      <Route path={`${path}`} component={SecurityRolesListPage} />
    </Switch>
  );
};

export default SecurityRoleRouter;
