import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import ModalContainer from './ModalContainer';

export interface ModalProps {
  children: (JSX.Element | null)[] | JSX.Element | null;
  setShowModal: (modal: boolean) => void;
  loading?: boolean;
  closeOnOverlayClick?: boolean;
  additionalStyles?: string;
}

const Modal = (props: ModalProps) => {
  const { children, setShowModal, loading = false, closeOnOverlayClick = false, additionalStyles } = props;

  const [divElement] = React.useState(document.createElement('div'));
  const [modalElement] = React.useState(document.querySelector('#modal') as HTMLElement);

  // handle click on modal background
  const clickModalBackground = React.useCallback(
    (e: any) => {
      if (e.target.className === 'modal__background' && !loading) setShowModal(false);
    },
    [loading, setShowModal]
  );

  React.useEffect(() => {
    divElement.setAttribute('class', 'modal__background');
    modalElement.appendChild(divElement);

    return () => {
      modalElement.removeChild(divElement);
    };
  }, [divElement, modalElement]);

  React.useEffect(() => {
    if (closeOnOverlayClick) {
      divElement.addEventListener('mousedown', clickModalBackground);

      return () => {
        divElement.removeEventListener('mousedown', clickModalBackground);
      };
    }
  }, [clickModalBackground, divElement, closeOnOverlayClick]);

  // add mounted state so that we can only render children once the modal is attached to the dom.
  // initially added to use autoFocus in descendants.
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  return ReactDOM.createPortal(
    <ModalContainer additionalStyles={additionalStyles}>{mounted && children}</ModalContainer>,
    divElement
  );
};

export default Modal;
