import React from 'react';
import Badge, { BadgeVariant } from '../../../../components/Badge/Badge';
import { DetailLayout } from '../../../../components/DetailLayout';
import { Icon } from '../../../../components/Icon/TextIcon';
import { DollarValue } from '../../../../components/Value';
import styles from './PaymentStatusSection.module.scss';
import { ITransactionDetail, ITransactionStatus } from '@ready/dashboardv2api.contracts';


interface PaymentStatusSectionProps {
  transactionDetailData: ITransactionDetail;
  refundCompleted: boolean;
}

function getTransactionStatusBadgeVariant(transactionStatus?: ITransactionStatus): BadgeVariant {
  let transactionStatusBadgeVariant: BadgeVariant;
  switch (transactionStatus) {
    case 'paid':
      transactionStatusBadgeVariant = BadgeVariant.GreenSolid;
      break;
    case 'authorized':
      transactionStatusBadgeVariant = BadgeVariant.YellowSolid;
      break;
    case 'refunded':
      transactionStatusBadgeVariant = BadgeVariant.Green;
      break;
    default:
      transactionStatusBadgeVariant = BadgeVariant.Gray;
  }
  return transactionStatusBadgeVariant;
}

export const PaymentStatusSection = (props: PaymentStatusSectionProps) => {
  const {
    refundCompleted,
    transactionDetailData: {
      totalsSummary,
      transactionStatus,
      locationName,
      transactionCheckNumbers,
      serverName,
      tableNumber,
    },
  } = props;

  const transactionStatusBadgeVariant = getTransactionStatusBadgeVariant(transactionStatus);

  return (
    <DetailLayout key='paymentStatus'>
      <div>
        <span className={styles.transactionAmount}>
          <DollarValue value={totalsSummary.totalPaid} />
        </span>
        <Badge
          icon={transactionStatus === 'refunded' || refundCompleted ? Icon.ReverseArrow : ''}
          value={transactionStatus as string}
          variant={transactionStatusBadgeVariant}
        />
      </div>
      <p>{locationName}</p>
      <p className={styles.locationDetails}>
        <strong>Check #: </strong>
        <span>{transactionCheckNumbers}</span>
        <span> | </span>
        <strong>Server: </strong>
        <span>
          {serverName} (Table {tableNumber})
        </span>
      </p>
    </DetailLayout>
  );
};
