import { FieldType } from '@ready/dashboardv2api.contracts/dist/src/contracts/api/locationPaymentOptions/FieldType';
import { AppDispatch } from '../../../redux/store';
import { set200Toast, toastErrorState } from '../../../redux/actions/uiActions/responseStateActions';
import { PaymentProcessingService } from '../../../services/paymentProcessingService';
import { IOnAccountPaymentOptionsForm } from '../initialStates/onAccountPaymentOptions';
import { OnAccountPaymentOptionService } from '../../services/onAccountPaymentOptionService';
import MenuBuilderService from '../../../menus/services/MenuBuilderService';
import { IOnAccountPaymentOption } from '@ready/dashboardv2api.contracts';

export const ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS = {
  LOAD_ON_ACCOUNT_PAYMENT_OPTIONS_BEGIN: 'LOAD_ON_ACCOUNT_PAYMENT_OPTIONS_BEGIN',
  LOAD_ON_ACCOUNT_PAYMENT_OPTIONS_SUCCESS: 'LOAD_ON_ACCOUNT_PAYMENT_OPTIONS_SUCCESS',
  LOAD_ON_ACCOUNT_PAYMENT_OPTIONS_ERROR: 'LOAD_ON_ACCOUNT_PAYMENT_OPTIONS_ERROR',

  GET_PAYMENT_OPTIONS_TENDER_TYPES_BEGIN: 'GET_PAYMENT_OPTIONS_TENDER_TYPES_BEGIN',
  GET_PAYMENT_OPTIONS_TENDER_TYPES_SUCCESS: 'GET_PAYMENT_OPTIONS_TENDER_TYPES_SUCCESS',
  GET_PAYMENT_OPTIONS_TENDER_TYPES_ERROR: 'GET_PAYMENT_OPTIONS_TENDER_TYPES_ERROR',

  GET_PAYMENT_OPTION_BY_ID_LOADING_BEGIN: 'GET_PAYMENT_OPTION_BY_ID_LOADING_BEGIN',
  GET_PAYMENT_OPTION_BY_ID_SUCESS: 'GET_PAYMENT_OPTION_BY_ID_SUCESS',
  GET_PAYMENT_OPTION_BY_ID_LOADING_FINISH: 'GET_PAYMENT_OPTION_BY_ID_LOADING_FINISH',
  RESET_PAYMENT_OPTION_VIEW_STATE: 'RESET_PAYMENT_OPTION_VIEW_STATE',
  TOGGLE_PAYMENT_OPTION_EDIT_MODE: 'TOGGLE_PAYMENT_OPTION_EDIT_MODE',
  SET_PAYMENT_OPTION_TENDER_TYPES_OPTIONS_VIEW_STATE: 'SET_PAYMENT_OPTION_TENDER_TYPES_OPTIONS_VIEW_STATE',
  FETCH_TENDER_TYPES_OPTIONS_VIEW_STATE_BEGIN: 'FETCH_TENDER_TYPES_OPTIONS_VIEW_STATE_BEGIN',
  FETCH_TENDER_TYPES_OPTIONS_VIEW_STATE_SUCCESS: 'FETCH_TENDER_TYPES_OPTIONS_VIEW_STATE_SUCCESS',
  FETCH_TENDER_TYPES_OPTIONS_VIEW_STATE_ERROR: 'FETCH_TENDER_TYPES_OPTIONS_VIEW_STATE_ERROR',

  SET_ON_ACCOUNT_PAYMENT_OPTIONS_EDIT_MODE_FORM: 'SET_ON_ACCOUNT_PAYMENT_OPTIONS_EDIT_MODE_FORM',

  RESET_PAYMENT_OPTION_FORM: 'RESET_PAYMENT_OPTION_FORM',

  LOAD_POS_ITEMS_BEGIN: 'LOAD_POS_ITEMS_BEGIN',
  LOAD_POS_ITEMS_SUCCESS: 'LOAD_POS_ITEMS_SUCCESS',
  LOAD_POS_ITEMS_ERROR: 'LOAD_POS_ITEMS_ERROR',

  UPDATE_FORM_IS_LOADING: 'UPDATE_FORM_IS_LOADING',
  UPDATE_FORM_IS_PROCESSING: 'UPDATE_FORM_IS_PROCESSING',
  UPDATE_FORM_CONTAINS_ERRORS: 'UPDATE_FORM_CONTAINS_ERRORS',
  UPDATE_FORM_STATUS_VALUE: 'UPDATE_FORM_STATUS_VALUE',
  UPDATE_FORM_PAYMENT_OPTION_NAME_VALUE: 'UPDATE_FORM_PAYMENT_OPTION_NAME_VALUE',
  UPDATE_FORM_PAYMENT_OPTION_NAME_HAS_ERROR: 'UPDATE_FORM_PAYMENT_OPTION_NAME_HAS_ERROR',
  UPDATE_FORM_DESCRIPTION_NAME_VALUE: 'UPDATE_FORM_DESCRIPTION_NAME_VALUE',
  UPDATE_FORM_SHORT_CODE_VALUE: 'UPDATE_FORM_SHORT_CODE_VALUE',
  UPDATE_FORM_SHORT_CODE_HAS_ERROR: 'UPDATE_FORM_SHORT_CODE_HAS_ERROR',
  UPDATE_FORM_POS_TENDER_TYPE_VALUE: 'UPDATE_FORM_POS_TENDER_TYPE_VALUE',
  UPDATE_FORM_POS_TENDER_TYPE_HAS_ERROR: 'UPDATE_FORM_POS_TENDER_TYPE_HAS_ERROR',

  ADD_FORM_PAYMENT_VALIDATION_FIELD: 'ADD_FORM_PAYMENT_VALIDATION_FIELD',
  REMOVE_FORM_PAYMENT_VALIDATION_FIELD: 'REMOVE_FORM_PAYMENT_VALIDATION_FIELD',
  UPDATE_VALIDATION_FIELD_REQUIRED_VALUE: 'UPDATE_VALIDATION_FIELD_REQUIRED_VALUE',
  UPDATE_VALIDATION_FIELD_LABEL_NAME_VALUE: 'UPDATE_VALIDATION_FIELD_LABEL_NAME_VALUE',
  UPDATE_VALIDATION_FIELD_LABEL_NAME_HAS_ERROR: 'UPDATE_VALIDATION_FIELD_LABEL_NAME_HAS_ERROR',
  UPDATE_VALIDATION_FIELD_PLACEHOLDER_TEXT_VALUE: 'UPDATE_VALIDATION_FIELD_PLACEHOLDER_TEXT_VALUE',
  UPDATE_VALIDATION_FIELD_PLACEHOLDER_TEXT_HAS_ERROR: 'UPDATE_VALIDATION_FIELD_PLACEHOLDER_TEXT_HAS_ERROR',
  UPDATE_VALIDATION_FIELD_TYPE_VALUE: 'UPDATE_VALIDATION_FIELD_TYPE_VALUE',
  UPDATE_VALIDATION_FIELD_TYPE_HAS_ERROR: 'UPDATE_VALIDATION_FIELD_TYPE_HAS_ERROR',
  UPDATE_VALIDATION_FIELD_MAX_CHAR_LENGTH_VALUE: 'UPDATE_VALIDATION_FIELD_MAX_CHAR_LENGTH_VALUE',
  UPDATE_VALIDATION_FIELD_MAX_CHAR_LENGTH_HAS_ERROR: 'UPDATE_VALIDATION_FIELD_MAX_CHAR_LENGTH_HAS_ERROR',
  UPDATE_VALIDATION_FIELD_MAPPED_POS_ITEM_VALUE: 'UPDATE_VALIDATION_FIELD_MAPPED_POS_ITEM_VALUE',
  UPDATE_VALIDATION_FIELD_MAPPED_POS_ITEM_HAS_ERROR: 'UPDATE_VALIDATION_FIELD_MAPPED_POS_ITEM_HAS_ERROR',
  UPDATE_PAYMENT_OPTION_ID_FIELD: 'UPDATE_PAYMENT_OPTION_ID_FIELD',

  SET_FORM_IS_VALIDATED: 'SET_FORM_IS_VALIDATED',

  SET_ON_ACCOUNT_PAYMENT_OPTION_DELETE_REQUESTED: 'SET_ON_ACCOUNT_PAYMENT_OPTION_DELETE_REQUESTED',
  SET_ON_ACCOUNT_PAYMENT_OPTION_DELETE_CANCELLED: 'SET_ON_ACCOUNT_PAYMENT_OPTION_DELETE_CANCELLED',
  SET_ON_ACCOUNT_PAYMENT_OPTION_DELETE_BEGIN: 'SET_ON_ACCOUNT_PAYMENT_OPTION_DELETE_BEGIN',
  SET_ON_ACCOUNT_PAYMENT_OPTION_DELETE_SUCCESS: 'SET_ON_ACCOUNT_PAYMENT_OPTION_DELETE_SUCCESS',
  SET_ON_ACCOUNT_PAYMENT_OPTION_DELETE_ERROR: 'SET_ON_ACCOUNT_PAYMENT_OPTION_DELETE_ERROR',
};

export class OnAccountPaymentOptionsActions {
  public static loadOnAccountPaymentOptions =
    (companyId: string, locationId: string) => async (dispatch: AppDispatch) => {
      dispatch({
        type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.LOAD_ON_ACCOUNT_PAYMENT_OPTIONS_BEGIN,
      });

      try {
        const onAccountPaymentOptions = await OnAccountPaymentOptionService.list(companyId, locationId);
        dispatch({
          type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.LOAD_ON_ACCOUNT_PAYMENT_OPTIONS_SUCCESS,
          payload: onAccountPaymentOptions,
        });
      } catch (error) {
        dispatch(toastErrorState(error.status, error.message));
        dispatch({
          type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.LOAD_ON_ACCOUNT_PAYMENT_OPTIONS_ERROR,
        });
      }
    };

  public static fetchTenderTypes = (companyId: string, locationId: string) => async (dispatch: AppDispatch) => {
    dispatch({
      type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.GET_PAYMENT_OPTIONS_TENDER_TYPES_BEGIN,
    });
    try {
      const tenderTypes = await PaymentProcessingService.getTenderTypes(companyId, locationId);

      dispatch({
        type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.GET_PAYMENT_OPTIONS_TENDER_TYPES_SUCCESS,
        payload: tenderTypes,
      });
    } catch (error) {
      dispatch(toastErrorState(error.status, error.message));
      dispatch({
        type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.GET_PAYMENT_OPTIONS_TENDER_TYPES_ERROR,
      });
    }
  };

  public static resetForm = () => ({
    type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.RESET_PAYMENT_OPTION_FORM,
  });

  public static updatePaymentOptionStatus = (checked: boolean) => ({
    type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_FORM_STATUS_VALUE,
    payload: checked,
  });

  public static updatePaymentOptionName = (name: string) => ({
    type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_FORM_PAYMENT_OPTION_NAME_VALUE,
    payload: name,
  });

  public static updatePaymentOptionDescription = (description: string) => ({
    type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_FORM_DESCRIPTION_NAME_VALUE,
    payload: description,
  });

  public static updatePaymentOptionShortCode = (shortCode: string) => ({
    type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_FORM_SHORT_CODE_VALUE,
    payload: shortCode,
  });

  public static updatePaymentOptionTenderType = (tenderTypeId: string) => ({
    type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_FORM_POS_TENDER_TYPE_VALUE,
    payload: tenderTypeId,
  });

  public static addPaymentOptionValidationField = () => ({
    type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.ADD_FORM_PAYMENT_VALIDATION_FIELD,
  });

  public static removePaymentOptionValidationField = (internalId: number) => ({
    type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.REMOVE_FORM_PAYMENT_VALIDATION_FIELD,
    payload: internalId,
  });

  public static updatePaymentOptionValidationFieldRequiredField = (internalId: number, required: boolean) => ({
    type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_VALIDATION_FIELD_REQUIRED_VALUE,
    payload: {
      validationFieldInternalId: internalId,
      value: required,
    },
  });

  public static updatePaymentOptionValidationFieldLabelName = (internalId: number, labelName: string) => ({
    type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_VALIDATION_FIELD_LABEL_NAME_VALUE,
    payload: {
      validationFieldInternalId: internalId,
      value: labelName,
    },
  });

  public static updatePaymentOptionValidationFieldPlaceholderText = (internalId: number, placeholderText: string) => ({
    type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_VALIDATION_FIELD_PLACEHOLDER_TEXT_VALUE,
    payload: {
      validationFieldInternalId: internalId,
      value: placeholderText,
    },
  });

  public static updatePaymentOptionValidationFieldType = (internalId: number, fieldType: string) => ({
    type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_VALIDATION_FIELD_TYPE_VALUE,
    payload: {
      validationFieldInternalId: internalId,
      value: fieldType,
    },
  });

  public static updatePaymentOptionValidationFieldMaxCharLength = (internalId: number, maxCharLength: string) => ({
    type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_VALIDATION_FIELD_MAX_CHAR_LENGTH_VALUE,
    payload: {
      validationFieldInternalId: internalId,
      value: maxCharLength,
    },
  });

  public static updatePaymentOptionValidationMappedPosItem = (
    internalId: number,
    value?: { id: string; name: string; posId?: string }
  ) => ({
    type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_VALIDATION_FIELD_MAPPED_POS_ITEM_VALUE,
    payload: {
      validationFieldInternalId: internalId,
      value: { id: value?.id, name: value?.name, posId: value?.posId },
    },
  });

  public static loadPosItems =
    (companyId: string, locationId: string, query: string = '', page: number, limit: number) =>
    async (dispatch: any) => {
      dispatch({
        type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.LOAD_POS_ITEMS_BEGIN,
      });
      try {
        const results = await MenuBuilderService.getPosItems(companyId, locationId, query, page, limit);
        dispatch({
          type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.LOAD_POS_ITEMS_SUCCESS,
          payload: results.items,
        });
      } catch (error) {
        dispatch({
          type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.LOAD_POS_ITEMS_ERROR,
        });
        dispatch(toastErrorState(error.status, error.message));
      }
    };

  public static validateForm = (form: IOnAccountPaymentOptionsForm) => async (dispatch: AppDispatch) => {
    dispatch({
      type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_FORM_IS_PROCESSING,
      payload: true,
    });

    let errorsFound = false;

    if (!form.fields.paymentOptionName.value) {
      errorsFound = true;
      dispatch({
        type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_FORM_PAYMENT_OPTION_NAME_HAS_ERROR,
        payload: true,
      });
    }
    if (!form.fields.shortCode.value) {
      errorsFound = true;
      dispatch({
        type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_FORM_SHORT_CODE_HAS_ERROR,
        payload: true,
      });
    }
    if (!form.fields.posTenderType.value) {
      errorsFound = true;
      dispatch({
        type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_FORM_POS_TENDER_TYPE_HAS_ERROR,
        payload: true,
      });
    }
    for (const paymentValidationField of form.fields.paymentValidationFields) {
      if (!paymentValidationField.fields.fieldLabelName.value) {
        errorsFound = true;
        dispatch({
          type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_VALIDATION_FIELD_LABEL_NAME_HAS_ERROR,
          payload: {
            validationFieldInternalId: paymentValidationField.internalId,
            hasError: true,
          },
        });
      }
      if (!paymentValidationField.fields.fieldPlaceholderText.value) {
        errorsFound = true;
        dispatch({
          type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_VALIDATION_FIELD_PLACEHOLDER_TEXT_HAS_ERROR,
          payload: {
            validationFieldInternalId: paymentValidationField.internalId,
            hasError: true,
          },
        });
      }
      if (!paymentValidationField.fields.fieldType.value) {
        errorsFound = true;
        dispatch({
          type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_VALIDATION_FIELD_TYPE_HAS_ERROR,
          payload: {
            validationFieldInternalId: paymentValidationField.internalId,
            hasError: true,
          },
        });
      }
      if (!paymentValidationField.fields.maxCharacterLength.value) {
        errorsFound = true;
        dispatch({
          type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_VALIDATION_FIELD_MAX_CHAR_LENGTH_HAS_ERROR,
          payload: {
            validationFieldInternalId: paymentValidationField.internalId,
            hasError: true,
          },
        });
      }
      if (!paymentValidationField.fields.mappedMenuItem.value.id) {
        errorsFound = true;
        dispatch({
          type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_VALIDATION_FIELD_MAPPED_POS_ITEM_HAS_ERROR,
          payload: {
            validationFieldInternalId: paymentValidationField.internalId,
            hasError: true,
          },
        });
      }
    }

    if (errorsFound) {
      dispatch({
        type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_FORM_CONTAINS_ERRORS,
        payload: true,
      });
    } else {
      dispatch({
        type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.SET_FORM_IS_VALIDATED,
        payload: true,
      });
    }

    dispatch({
      type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_FORM_IS_PROCESSING,
      payload: false,
    });
  };

  public static save =
    (companyId: string, locationId: string, form: IOnAccountPaymentOptionsForm) => async (dispatch: AppDispatch) => {
      dispatch({
        type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_FORM_IS_PROCESSING,
        payload: true,
      });

      try {
        const newPaymentOption = await OnAccountPaymentOptionService.save(companyId, locationId, {
          _id: '',
          locationId,
          enabled: form.fields.status.value,
          name: form.fields.paymentOptionName.value,
          description: form.fields.description.value,
          code: form.fields.shortCode.value,
          tenderType: form.fields.posTenderType.value,
          validationFields: form.fields.paymentValidationFields.map((validationField) => ({
            required: validationField.fields.required.value,
            label: validationField.fields.fieldLabelName.value,
            placeholder: validationField.fields.fieldPlaceholderText.value,
            type: validationField.fields.fieldType.value as FieldType,
            maxLength: validationField.fields.maxCharacterLength.value,
            menuItemId: validationField.fields.mappedMenuItem.value.id,
          })),
        });
        // This is set here because we want to go to the next page
        // after saving; using this id in the URL
        dispatch({
          type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_PAYMENT_OPTION_ID_FIELD,
          payload: newPaymentOption._id,
        });
        dispatch(set200Toast('Success! Payment option created.'));
      } catch (error) {
        dispatch(toastErrorState(error.status, error.message));
      }

      dispatch({
        type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.SET_FORM_IS_VALIDATED,
        payload: false,
      });
      dispatch({
        type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_FORM_IS_PROCESSING,
        payload: false,
      });
    };

  public static initiateDelete = () => ({
    type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.SET_ON_ACCOUNT_PAYMENT_OPTION_DELETE_REQUESTED,
  });

  public static cancelDelete = () => ({
    type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.SET_ON_ACCOUNT_PAYMENT_OPTION_DELETE_CANCELLED,
  });

  public static delete =
    (companyId: string, locationId: string, onAccountPaymentOptionId: string) => async (dispatch: AppDispatch) => {
      dispatch({
        type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.SET_ON_ACCOUNT_PAYMENT_OPTION_DELETE_BEGIN,
      });
      try {
        await OnAccountPaymentOptionService.delete(companyId, locationId, onAccountPaymentOptionId);
        dispatch(set200Toast('Payment Option deleted.'));
        dispatch({
          type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.SET_ON_ACCOUNT_PAYMENT_OPTION_DELETE_SUCCESS,
        });
      } catch (error) {
        dispatch(toastErrorState(error.status, error.message));
        dispatch({
          type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.SET_ON_ACCOUNT_PAYMENT_OPTION_DELETE_ERROR,
        });
      }
    };

  public static getById =
    (companyId: string, locationId: string, onAccountPaymentOptionId: string) => async (dispatch: AppDispatch) => {
      dispatch({
        type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.GET_PAYMENT_OPTION_BY_ID_LOADING_BEGIN,
        payload: true,
      });
      try {
        const paymentOption = await OnAccountPaymentOptionService.getById(
          companyId,
          locationId,
          onAccountPaymentOptionId
        );
        dispatch({
          type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.GET_PAYMENT_OPTION_BY_ID_SUCESS,
          payload: paymentOption,
        });
        dispatch(OnAccountPaymentOptionsActions.fetchTenderTypesOptionsViewState(companyId, locationId));
      } catch (error) {
        dispatch(toastErrorState(error.status, error.message));
        dispatch({
          type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.GET_PAYMENT_OPTION_BY_ID_LOADING_FINISH,
          payload: false,
        });
      }
    };

  public static togglePaymentOptionEditMode = (toggled: boolean) => ({
    type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.TOGGLE_PAYMENT_OPTION_EDIT_MODE,
    payload: toggled,
  });

  public static resetPaymentOptionViewState = () => ({
    type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.RESET_PAYMENT_OPTION_VIEW_STATE,
  });

  public static fetchTenderTypesOptionsViewState =
    (companyId: string, locationId: string) => async (dispatch: AppDispatch) => {
      dispatch({
        type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.FETCH_TENDER_TYPES_OPTIONS_VIEW_STATE_BEGIN,
      });
      try {
        const tenderTypes = await PaymentProcessingService.getTenderTypes(companyId, locationId);

        dispatch({
          type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.FETCH_TENDER_TYPES_OPTIONS_VIEW_STATE_SUCCESS,
          payload: tenderTypes,
        });
      } catch (err) {
        dispatch(toastErrorState(err));
        dispatch({
          type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.FETCH_TENDER_TYPES_OPTIONS_VIEW_STATE_ERROR,
        });
      }
    };

  public static setOnAccountPayemntOptionsEditModeForm = () => ({
    type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.SET_ON_ACCOUNT_PAYMENT_OPTIONS_EDIT_MODE_FORM,
  });

  public static update =
    (companyId: string, locationId: string, onAccountPaymentOptionId: string, form: IOnAccountPaymentOptionsForm) =>
    async (dispatch: AppDispatch) => {
      dispatch({
        type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_FORM_IS_PROCESSING,
        payload: true,
      });
      dispatch({
        type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.GET_PAYMENT_OPTION_BY_ID_LOADING_BEGIN,
        payload: true,
      });

      try {
        const endpointPayloadMapper: IOnAccountPaymentOption = {
          _id: form.paymentOptionId,
          locationId,
          enabled: form.fields.status.value,
          name: form.fields.paymentOptionName.value,
          description: form.fields.description.value,
          code: form.fields.shortCode.value,
          tenderType: form.fields.posTenderType.value,
          validationFields: form.fields.paymentValidationFields.map((validationField) => ({
            required: validationField.fields.required.value,
            label: validationField.fields.fieldLabelName.value,
            placeholder: validationField.fields.fieldPlaceholderText.value,
            type: validationField.fields.fieldType.value as FieldType,
            maxLength: validationField.fields.maxCharacterLength.value,
            menuItemId: validationField.fields.mappedMenuItem.value.id,
          })),
        };

        const updatedPaymentOption = await OnAccountPaymentOptionService.update(
          companyId,
          locationId,
          onAccountPaymentOptionId,
          endpointPayloadMapper
        );

        dispatch({
          type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.GET_PAYMENT_OPTION_BY_ID_SUCESS,
          payload: updatedPaymentOption,
        });
        dispatch(set200Toast('Success! Payment option updated.'));
      } catch (error) {
        dispatch(toastErrorState(error.status, error.message));
      }

      dispatch({
        type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.SET_FORM_IS_VALIDATED,
        payload: false,
      });
      dispatch({
        type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.UPDATE_FORM_IS_PROCESSING,
        payload: false,
      });

      dispatch({
        type: ON_ACCOUNT_PAYMENT_OPTIONS_ACTIONS.GET_PAYMENT_OPTION_BY_ID_LOADING_FINISH,
        payload: false,
      });
    };
}
