import {
  IBaseMenuOrGalleryInGroup,
  ITemplateMenuGroup,
  ITemplateMenuGroupRequest,
  TemplateMenuSummaryInGroup,
  WithAssignedLocationsCount,
} from '@ready/menu.core';
import { IMenuGroupUI, IMenuSummaryUI } from './list/redux/types';

export type NewTemplateMenuGroupRequest = Omit<ITemplateMenuGroupRequest, '_id'>;

export const mapUIMenuGroupToRequest = (
  uiMenuGroup: IMenuGroupUI
): NewTemplateMenuGroupRequest | ITemplateMenuGroupRequest => {
  return {
    _id: uiMenuGroup._id,
    name: uiMenuGroup.name,
    menusAndGalleries: mapSummariesToRequest(uiMenuGroup.menusAndGalleries),
  };
};

export const mapSummariesToRequest = (summaries: IMenuSummaryUI[]): IBaseMenuOrGalleryInGroup<string>[] => {
  if (!summaries) return [];
  return summaries.map((summary, index) => ({
    _id: summary.id,
    sortOrder: index,
    type: summary.isGallery ? 'itemGallery' : 'menu',
  }));
};

export const mapListTemplateMenuGroupContractToUI = (
  contract: WithAssignedLocationsCount<ITemplateMenuGroup>
): WithAssignedLocationsCount<IMenuGroupUI> => {
  return {
    ...mapTemplateMenuGroupContractToUI(contract),
    assignedLocationsCount: contract.assignedLocationsCount,
  };
};

export const mapTemplateMenuGroupContractToUI = (contract: ITemplateMenuGroup): IMenuGroupUI => {
  return {
    _id: contract._id,
    name: contract.name,
    menusAndGalleries: contract.menusAndGalleries.map(mapMenuSummaryToUI),
  };
};

export const mapMenuSummaryToUI = (contract: TemplateMenuSummaryInGroup): IMenuSummaryUI => {
  return {
    id: contract._id,
    name: contract.displayName,
    isGallery: contract.type === 'itemGallery',
    sortOrder: contract.sortOrder,
    description: contract.description,
  };
};
