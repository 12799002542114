import { IOrderIdentityInfo, OrderIdentificationFieldType } from '@ready/dashboardv2api.contracts';

/**
 *
 * @param identityInfo IOrderIdentityInfo object
 * @returns The first mapped customer response of type "tel" (Telephone) if at
 * least one exists, otherwise returns null.
 */
const getIdentityInfoSmsNumber = (identityInfo: IOrderIdentityInfo): string | null => {
  const smsNumbers = identityInfo.mappedCustomerResponses?.filter(
    (response) => response.type === OrderIdentificationFieldType.Telephone
  );

  if (smsNumbers && smsNumbers.length > 0) {
    return smsNumbers[0].value;
  } else {
    return null;
  }
};

export default getIdentityInfoSmsNumber;
