import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/store';
import { IFeedbackReportState } from './initialState';

export const feedbackReportSelector = createSelector(
  (state: RootState) => {
    return state;
  },
  ({ session, reports: { feedback } }: RootState): IFeedbackReportState & { contextId: string } => {
    return {
      contextId: session.contextSession.id,
      ...feedback,
    };
  }
);
