import { IModifierGroup, ITemplateModifierGroup } from '@ready/menu.core';
import { DetailLayout, DetailElement } from 'components/DetailLayout';
import { Form, FormControl } from 'components/Form';
import FormattedInputNestedLabel from 'components/FormattedInputNestedLabel/FormattedInputNestedLabel';
import TextArea from 'components/TextArea/TextArea';
import TextInput from 'components/TextInput/TextInput';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styles from './DetailsView.module.scss';
import NoneValue from 'components/Value/NoneValue';

export type ModifierGroupDetailsFormFields = Pick<
  ITemplateModifierGroup | IModifierGroup,
  'name' | 'displayName' | 'guestInstructions' | 'constraints'
>;

interface Props {
  modifierGroup?: ITemplateModifierGroup | IModifierGroup;
  editing: boolean;
  processing: boolean;
}

/**
 * Create and edit view for Modifier Groups
 * Intended to be used in both Location Menus and in Menu Builder.
 */
export const DetailsView = ({ modifierGroup, editing, processing }: Props) => {
  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext<ModifierGroupDetailsFormFields>();

  return (
    <>
      {editing ? (
        <Form hasGroups>
          <FormControl label='Group Name *' withError={!!errors.name} errorMessage={errors.name?.message}>
            <Controller
              control={control}
              name='name'
              rules={{ required: 'Group Name is required.' }}
              defaultValue={modifierGroup?.name ?? ''}
              render={({ field }) => {
                return (
                  <TextInput
                    disabled={processing}
                    placeholder='e.g. Sides for burgers'
                    maxLength={100}
                    withError={!!errors.name}
                    {...field}
                  />
                );
              }}
            />
          </FormControl>

          <FormControl label='Display Name' labelNoteComponent={<span className={styles.labelNote}>(optional)</span>}>
            <Controller
              control={control}
              name='displayName'
              defaultValue={modifierGroup?.displayName ?? ''}
              render={({ field }) => {
                return <TextInput disabled={processing} placeholder='e.g. Select sides' {...field} />;
              }}
            />
          </FormControl>

          <FormControl label='Guest instructions'>
            <Controller
              control={control}
              name='guestInstructions'
              defaultValue={modifierGroup?.guestInstructions ?? ''}
              render={({ field }) => {
                return (
                  <TextArea
                    disabled={processing}
                    placeholder='e.g. Includes small fries, or you can upgrade for a fee.'
                    {...field}
                  />
                );
              }}
            />
          </FormControl>

          <FormControl label='Required Selection' additionalStyles={styles.constraints}>
            <Controller
              control={control}
              name='constraints.min'
              rules={{
                validate: (value) => {
                  const max = getValues('constraints.max');
                  if (!value) return true;

                  if (value < 0) {
                    return 'Minimum cannot be less than 0.';
                  }

                  if (max && max < value) {
                    return 'Minimum cannot be greater than maximum.';
                  }
                },
              }}
              defaultValue={modifierGroup?.constraints.min}
              render={({ field }) => {
                return (
                  <FormattedInputNestedLabel
                    format='number'
                    label='Minimum'
                    placeholder='e.g. 1'
                    loading={processing}
                    withError={!!errors.constraints?.min}
                    errorMessage={errors.constraints?.min?.message}
                    {...field}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name='constraints.max'
              defaultValue={modifierGroup?.constraints.max}
              rules={{
                validate: (value) => {
                  if (!value) return true;

                  if (value < 0) {
                    return 'Maxiumum cannot be less than 0.';
                  }
                },
              }}
              render={({ field }) => {
                return (
                  <FormattedInputNestedLabel
                    format='number'
                    label='Maximum'
                    placeholder='e.g. 1'
                    loading={processing}
                    withError={!!errors.constraints?.max}
                    errorMessage={errors.constraints?.max?.message}
                    {...field}
                  />
                );
              }}
            />
          </FormControl>
        </Form>
      ) : (
        <DetailLayout>
          <DetailElement label='Group Name *'>{modifierGroup?.name}</DetailElement>
          <DetailElement label='Display Name' labelNoteComponent={<span className={styles.labelNote}>(optional)</span>}>
            {modifierGroup?.displayName || <NoneValue text='No Display Name' />}
          </DetailElement>
          <DetailElement label='Guest Instructions'>
            {modifierGroup?.guestInstructions || <NoneValue text='No Instructions' />}
          </DetailElement>
          <DetailElement label='Required Selection'>
            <span className={styles.constraint}>Min: </span>
            {modifierGroup?.constraints?.min || 'None'}
            <span className={styles.separator}>|</span>
            <span className={styles.constraint}>Max: </span>
            {modifierGroup?.constraints?.max || 'Unlimited'}
          </DetailElement>
        </DetailLayout>
      )}
    </>
  );
};
