import React from 'react';
import { ITransactionDetailGuestPii } from '@ready/dashboardv2api.contracts';

import { DetailElement } from '../../../components/DetailLayout';
import { TextValue } from '../../../components/Value';

export interface TransactionGuestPiiProps {
  hasGuestPiiPermission: boolean;
  pii: ITransactionDetailGuestPii[];
}

const TransactionGuestPii = (props: TransactionGuestPiiProps) => {
  const { hasGuestPiiPermission, pii = [] } = props;

  if (!hasGuestPiiPermission || !pii.length) return null;

  return (
    <>
      {pii.map(([label, value]: ITransactionDetailGuestPii, index: number) => {
        return (
          <DetailElement condensed label={label} key={`pii${index}`}>
            <TextValue value={value} />
          </DetailElement>
        );
      })}
    </>
  );
};

export default TransactionGuestPii;
