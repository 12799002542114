import { OrderExperienceType } from '@ready/dashboardv2api.contracts';
import { Icon } from '../../components/Icon/TextIcon';

interface ExperienceTypeIconAndLabel {
  icon: Icon;
  label: string;
}

/**
 * Helper function to retrieve Icon and Label for order experience type
 * Default is 'Dine In'
 *
 * @param orderExperienceType  OrderExperienceType | undefined
 * @returns ExperienceTypeIconAndLabel
 */
export const getOrderExperienceType = (
  orderExperienceType: OrderExperienceType | undefined
): ExperienceTypeIconAndLabel => {
  switch (orderExperienceType) {
    case OrderExperienceType.Delivery:
      return {
        icon: Icon.Car,
        label: 'Delivery',
      };
    case OrderExperienceType.Pickup:
      return {
        icon: Icon.TakeoutBag,
        label: 'Pick up',
      };
    default:
      return {
        icon: Icon.Cutlery,
        label: 'Dine In',
      };
  }
};
