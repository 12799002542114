import React from 'react';
import { IPermissionMetadata, IResourcePermission, ResourceType } from '@ready/security.core';
import styles from './PermissionSectionView.module.scss';
import Divider from '../../components/Divider/Divider';
import { FormSubHeader } from '../../components/Form';
import PermissionDescription from '../components/PermissionDescription';
import PermissionItemView from './PermissionItemView';

interface PermissionSectionViewProps {
  permissionType: 'company' | 'location';
  permissions: IPermissionMetadata[];
  showDivider?: boolean;
  handlePermissionChange?: (
    permissionType: 'company' | 'location',
    resourceType: ResourceType,
    actionName: string,
    operation: 'add' | 'remove'
  ) => void;
  permissionsForm?: IResourcePermission[];
}

const PermissionSectionView: React.FC<PermissionSectionViewProps> = ({
  permissionType,
  showDivider = true,
  permissions = [],
  permissionsForm = [],
}: PermissionSectionViewProps) => {
  let headerLabel: string;

  switch (permissionType) {
    case 'company':
      headerLabel = 'Company Level Permissions';
      break;

    case 'location':
      headerLabel = 'Location Level Permissions';
      break;
  }

  return (
    <div className={styles.container}>
      {showDivider ? <Divider additionalContainerStyles={styles.constrainWidth} /> : null}
      <FormSubHeader label={headerLabel} />
      <PermissionDescription permissionType={permissionType} />

      <div className={styles.permissionsViewList}>
        {permissions.map((permission: IPermissionMetadata, index: number) => {
          return (
            <PermissionItemView
              key={`${permissionType}${index}`}
              label={permission.label}
              description={permission.description}
              isActive={existsPermission(permissionsForm, permission.resourceType, permission.action)}
            />
          );
        })}
      </div>
    </div>
  );
};

const existsPermission = (
  permissionsForm: IResourcePermission[],
  resourceType: ResourceType,
  action: string
): boolean => {
  const resource = permissionsForm?.find((r) => r.resourceType === resourceType);
  if (!!resource) {
    return resource.actions.some((a) => a === action);
  }
  return false;
};

export default PermissionSectionView;
