import React from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { ContextParams } from '../../../types/ContextParams.interface';
import { OnAccountPaymentOptionsEditForm } from '../../components/OnAccountPaymentOptions/OnAccountPaymentOptionsEditForm';
import { OnAccountPaymentOptionsActions } from '../../redux/actions/onAccountPaymentOptionsActions';
import { selectOnAccountPaymentOptions } from '../../redux/selectors/onAccountPaymentOptionsSelector';

export const OnAccountPaymentOptionDetailsEditPanel = () => {
  const dispatch = useAppDispatch();
  const { form } = useAppSelector(selectOnAccountPaymentOptions);
  const { contextId: companyId, locationId } = useParams<ContextParams>();

  React.useEffect(() => {
    dispatch(OnAccountPaymentOptionsActions.setOnAccountPayemntOptionsEditModeForm());
  }, [dispatch, companyId, locationId]);

  const isReadyToStartSaving = form.isValidated && !form.isProcessing;
  React.useEffect(() => {
    if (isReadyToStartSaving) {
      dispatch(OnAccountPaymentOptionsActions.update(companyId, locationId, form.paymentOptionId, form));
      dispatch(OnAccountPaymentOptionsActions.togglePaymentOptionEditMode(false));
    }
  }, [isReadyToStartSaving, dispatch, companyId, locationId, form]);

  const handleCancel = () => {
    dispatch(OnAccountPaymentOptionsActions.togglePaymentOptionEditMode(false));
  };
  return (
    <OnAccountPaymentOptionsEditForm
      isEditOptionForm
      formHasGroups
      handleCancel={handleCancel}
      saveButtonLabel='Save Changes'
    />
  );
};
