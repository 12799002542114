import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useParams } from 'react-router-dom';
import { MenuResourceActions, PrincipalPermissions, ResourceType, SecurityScope, Verifier } from '@ready/security.core';

import { ContextParams } from '../../types/ContextParams.interface';
import { LocationsThunks } from '../redux/LocationsThunks';
import { selectLocationsState } from '../redux/LocationsSelectors';
import { selectPermissions } from '../../redux/selectors/sessionSelectors/sessionSelectors';

import LayoutContent from '../../components/AppLayout/LayoutContent';
import LocationPicker from '../../components/LocationPicker/LocationPicker';
import { Routes } from '../MenuBuilderRouter';

const MenuBuilderLocationPage = (): JSX.Element => {
  const { loading, pagination, locations } = useSelector(selectLocationsState);
  const { permissionsList } = useSelector(selectPermissions);
  const dispatch = useDispatch();
  const { contextId: companyId } = useParams<ContextParams>();

  const getPath = (locationId: string): string => {
    if (
      Verifier.check(
        new PrincipalPermissions(permissionsList),
        SecurityScope.location,
        ResourceType.menu,
        MenuResourceActions.all,
        locationId
      )
    ) {
      return Routes.getMenusPage(companyId, locationId);
    }
    return Routes.getItemsAndModsPage(companyId, locationId);
  };

  const handleLoadLocations = React.useCallback(
    (query: string, page: number) => {
      dispatch(LocationsThunks.loadLocations({ companyId, query, page }));
    },
    [dispatch, companyId]
  );

  return (
    <LayoutContent title='Location Menus' containerType='within'>
      <LocationPicker
        loading={loading}
        pagination={pagination}
        locations={locations}
        getPath={getPath}
        handleLoadLocations={handleLoadLocations}
      />
    </LayoutContent>
  );
};

export default MenuBuilderLocationPage;
