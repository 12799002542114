import { PaginationResponse } from '@ready/dashboardv2api.contracts';
import { IBulkEditMenuSection, IBulkEditMenuSectionRequest } from '@ready/menu.core';
import { IPagedResponse } from '@ready/table.core';
import config from 'config';
import executeApiAsync from 'services/utils/executeApiAsync';
import toPaginationResponse from 'services/utils/toPaginationResponse';
import { undefinedWithNullReplacer } from 'utils/objectUtils/replaceUndefinedWithNull';
import { objectToQueryParamsString } from 'utils/urlUtils/objectToQueryParamsString';

export const getLocationMenuSections = async (
  companyId: string,
  menuId: string,
  sectionId: string,
  page: number = 1,
  pageSize: number = 50,
  searchTerm?: string
): Promise<PaginationResponse<IBulkEditMenuSection>> => {
  const queryString = objectToQueryParamsString({
    pageNumber: page.toString(),
    pageSize: pageSize.toString(),
    lq: searchTerm,
  });

  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/${menuId}/locationMenus/sections/${sectionId}?${queryString}`;
  const { results, totalCount } = await executeApiAsync<IPagedResponse<IBulkEditMenuSection>>(`${url}`, {
    method: 'GET',
  });

  return toPaginationResponse({ results, totalCount }, page, pageSize);
};

export const saveLocationMenuSections = async (
  companyId: string,
  menuId: string,
  sectionId: string,
  updates: Omit<IBulkEditMenuSectionRequest, 'templateMenuId' | 'sectionUid'>
): Promise<void> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/${menuId}/locationMenus/sections/${sectionId}`;

  return await executeApiAsync(url, {
    method: 'PUT',
    body: JSON.stringify(updates, undefinedWithNullReplacer),
  });
};
