import React from 'react';

import { IPagination } from '@ready/dashboardv2api.contracts';
import { ILocation } from '../../services/types/ILocation.type';

import { useSearchParams } from '../../hooks/useSearchParams';

import styles from './LocationPicker.module.scss';

import SearchParamInput from '../SearchInput/SearchParamInput';
import InfoCardList from '../InfoCard/InfoCardList';
import InfoCardColumnSkeleton from '../../components/InfoCard/InfoCardColumnSkeleton';
import LocationCard from './LocationCard';
import LocationPickerNoResults from './LocationPickerNoResults';
// import { useHistory, Redirect } from 'react-router-dom'; // TODO: implement
// later, see note below
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../redux/initialStates/AppState';

export interface LocationPickerProps {
  loading: boolean;
  pagination: IPagination;
  showPagination?: boolean;
  locations: ILocation[];
  path?: string;
  getPath?: (locationId: string) => string;
  handleLoadLocations: (query: string, page: number, limit: number) => void;
}

const LocationPicker = (props: LocationPickerProps & ReduxProps): JSX.Element => {
  const {
    loading,
    pagination,
    showPagination = true,
    locations,
    path,
    getPath,
    handleLoadLocations,
    // lastLocationId, // TODO: implement later, see note below
  } = props;

  const { query, page, limit } = useSearchParams();

  React.useEffect(() => {
    handleLoadLocations(query, page, limit);
  }, [handleLoadLocations, query, page, limit]);

  const noResults = !!(query && locations.length === 0);

  return (
    <div className={styles.container}>
      {
        /* If a last location was stored, redirect directly to the location page */
        // TODO: This will be implemented later when there is an opportunity; it
        // is currently only partially working, and the scope is too large to
        // complete for this sprint.
        // lastLocationId ?
        //   <Redirect to={`${props.path.replace(':id', lastLocationId)}`}/>
        //   : null
      }
      <div className={styles.title}>Select a Location</div>

      <div className={styles.controls}>
        <SearchParamInput placeholder='Search Locations' additionalClassName='white' autoFocus={true} />
      </div>

      <div className={styles.content}>
        <InfoCardList paginationProps={showPagination ? pagination : undefined}>
          {loading ? (
            <InfoCardColumnSkeleton instances={10} rowsWithinInstance={2} />
          ) : noResults ? (
            <LocationPickerNoResults roundBottom={!showPagination} />
          ) : (
            locations.map((location: ILocation, index: number) => (
              <LocationCard location={location} path={path || (!!getPath ? getPath(location._id) : '')} key={index} />
            ))
          )}
        </InfoCardList>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    lastLocationId: state.session.lastLocation.locationId,
  };
};

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(LocationPicker);
