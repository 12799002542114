import { PaginationResponse } from '@ready/dashboardv2api.contracts';
import { IMenuGroup } from '@ready/menu.core';
import { IPagedResponse } from '@ready/table.core';
import config from 'config';
import executeApiAsync from 'services/utils/executeApiAsync';
import toPaginationResponse from 'services/utils/toPaginationResponse';

const BASE_URL = `${config.readyDashboardApiEndpoint}`;

export const getMenuConfigsForLocation = async (
  companyId: string,
  locationId: string
): Promise<PaginationResponse<IMenuGroup>> => {
  const url = `${BASE_URL}/companies/${companyId}/locations/${locationId}/menuGroups`;

  const { results, totalCount } = await executeApiAsync<IPagedResponse<IMenuGroup>>(url, {
    method: 'GET',
  });

  return toPaginationResponse({ results, totalCount }, 0, 50);
};
