import React from 'react';

const initiallyShowModal = false;
const initiallyLoadingModal = false;

const useModal = () => {
  const [showModal, setShowModal] = React.useState(initiallyShowModal);
  const [loadingModal, setLoadingModal] = React.useState(initiallyLoadingModal);
  return {
    showModal,
    setShowModal,
    loadingModal,
    setLoadingModal,
  };
};

export default useModal;
