import React from 'react';

export interface InfoCardProps {
  children?: React.ReactNode;
  additionalStyles?: string;
}

const InfoCard = (props: InfoCardProps) => {
  const { children, additionalStyles = '' } = props;

  return (
    <div className={`card-wrapper`}>
      <div className={`card-content ${additionalStyles}`}>{children}</div>
    </div>
  );
};

export default InfoCard;
