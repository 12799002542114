import { createSlice } from '@reduxjs/toolkit';
import { locationsState as initialState } from './LocationsState';
import { extraReducers, reducers } from './LocationsReducer';

export const locationsSlice = createSlice({
  name: 'menuBuilderLocations',
  initialState,
  reducers,
  extraReducers,
});
