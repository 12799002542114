import MenuBuilderService from '../../services/MenuBuilderService';
import { set200Toast, toastErrorState } from '../../../redux/actions/uiActions/responseStateActions';
import { IMenu } from '@ready/menu.core';
import { MENUS_ACTIONS } from '../MenusActions';
import { createTemplateMenu, updateTemplateMenu } from 'sharedMenuItems/pages/menu/menu.service';
import { mapITemplateMenuToIMenu } from 'sharedMenuItems/pages/menu/menu.mapper';
import { AppDispatch } from 'redux/store';

interface Base {
  isSharedMenu?: boolean;
  companyId: string;
  summaryOnly?: boolean;
  menu: IMenu;
}

interface SaveMenuArgs extends Base {
  isSharedMenu?: false;
  locationId: string;
}

interface SaveSharedMenuArgs extends Base {
  isSharedMenu: true;
  menuConfigId: string;
}

export const saveMenu = (args: SaveMenuArgs | SaveSharedMenuArgs) => async (dispatch: AppDispatch) => {
  const { companyId, menu, summaryOnly = false } = args;
  let hasError = false;
  if (!menu.displayName) {
    hasError = true;
    dispatch({
      type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_VALIDATED,
      payload: {
        hasError: hasError,
        errorMessage: 'Menu Name is required',
      },
    });
  }
  if (hasError) {
    return;
  }

  dispatch({ type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_BEGIN });
  try {
    let updatedMenu: IMenu;

    if (!menu._id) {
      updatedMenu = !args.isSharedMenu
        ? await MenuBuilderService.createMenu(companyId, args.locationId, menu)
        : mapITemplateMenuToIMenu(await createTemplateMenu(companyId, args.menuConfigId, menu));
    } else if (summaryOnly) {
      updatedMenu = !args.isSharedMenu
        ? await MenuBuilderService.updateMenuSummary(companyId, args.locationId, menu)
        : // todo: this is for the summary update! (quick edit on the menu list!)
          ({} as IMenu);
    } else {
      updatedMenu = !args.isSharedMenu
        ? await MenuBuilderService.updateMenu(companyId, args.locationId, menu)
        : mapITemplateMenuToIMenu(await updateTemplateMenu(companyId, menu));
    }

    dispatch({
      type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_SUCCESS,
      payload: updatedMenu,
    });
    if (!menu._id) {
      dispatch(set200Toast('Success! New menu created.'));
    } else {
      dispatch(set200Toast());
    }
  } catch (error) {
    dispatch({ type: MENUS_ACTIONS.SAVE_MENU_BUILDER_MENU_ERROR });
    dispatch(toastErrorState(error.status, error.message));
  }
};
