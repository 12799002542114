import React, { useMemo } from 'react';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Routes } from '../../MenuBuilderRouter';
import { MenuResourceActions, PrincipalPermissions, ResourceType, SecurityScope, Verifier } from '@ready/security.core';
import { AppState } from '../../../redux/initialStates/AppState';
import { LocationsThunks } from '../../redux/LocationsThunks';
import {
  prepareNewModifierGroup,
  loadModifierGroup,
  startEditingModifierGroup,
  moveModifierOptionInModifierGroup,
  updateModifierOptionVisibility,
  updateModifierOptionVisibilityAndSave,
  updateModifierConstraintsValue,
  updateMultiplesModifierOptions,
  updateModifierOptionIsDefault,
  updateModifierGroupName,
  updateModifierGroupDisplayName,
  updateModifierGroupGuestInstructions,
  updateModifierGroupMinimum,
  updateModifierGroupMaximum,
  validateModifierGroup,
  validateModifierOption,
  saveModifierGroup,
  showDeleteModifierGroupModal,
  deleteModifierGroup,
  resetModifierGroupForm,
  cancelNewModifierGroup,
  prepareModifierGroupsModal,
  addNestedModifierGroupToModifierOption,
  removeNestedModifierGroupFromModifierOption,
  moveModifierGroupInModifierOption,
} from '../../redux/ModifierGroupsActions';
import { selectOptionForModifierGroup, deselectOptionFromModifierGroup } from '../../redux/MenuBuilderActions';
import { prepareMenuItemsModal } from '../../redux/ItemsAndModsActions';
import { ContextParams } from '../../../types/ContextParams.interface';
import { IEmbeddedModifierItem, ITemplateEmbeddedModifierItem } from '@ready/menu.core';
import getBackLinkUrl from '../../../utils/urlUtils/getBackLinkUrl';
import styles from './ViewModifierGroupPage.module.scss';
import FormActionBar from '../../../components/FormActionBar/FormActionBar';
import MenuBuilderLayoutContent from '../MenuBuilderLayoutContent';
import MenuBuilderTab from '../../types/MenuBuilderTab.enum';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import ActionHeader from '../../../components/ActionHeader/ActionHeader';
import { Panel, PanelLayout } from '../../../components/PanelLayout';
import ModifierGroupDetailsForm from './ModifierGroupDetailsForm';
import { DetailElement, DetailLayout } from '../../../components/DetailLayout';
import Button from '../../../components/Button/Button';
import ModifierGroupOptionsList from './ModifierGroupOptionsList';
import AddMenuItemsDialog from '../ItemsAndMods/AddMenuItemsDialog';
import { DeleteConfirmationModal } from '../../../components/Modal';
import AllowMultiplesModifierOptions from './AllowMultiplesModifierOptions';
import ModifierOptionsValidationNotification from './ModifierOptionsValidationNotification';
import { selectLocationsState } from '../../redux/LocationsSelectors';
import AddModifierGroupDialog from './AddModifierGroupDialog';
import TextIcon, { Icon } from 'components/Icon/TextIcon';
import NoneValue from 'components/Value/NoneValue';

export type ViewModifierGroupPageProps = ConnectedProps<typeof connector>;

interface DefaultModifierSelection {
  itemId: string;
  isDefault: boolean;
}

const ViewModifierGroupPage = (props: ViewModifierGroupPageProps): JSX.Element => {
  const {
    permissions,
    actionBarVisible,
    backQuery,
    backPage,
    modifierGroupForm,
    modifierGroupForm: {
      loading,
      editing,
      processing,
      cancelled,
      saved,
      cache,
      modifierGroup,
      validation: { validated },
    },
    deleteRequested,
    deleteProcessing,
    deleteError,
    deletedModifierGroup,
    isSelectingModifierOptions,
    isSelectingNestedModifierGroups,
    parentModifierOptionId,
    prepareNewModifierGroup,
    loadModifierGroup,
    startEditingModifierGroup,
    moveModifierOptionInModifierGroup,
    updateModifierOptionVisibility,
    updateModifierOptionVisibilityAndSave,
    updateModifierConstraintsValue,
    updateMultiplesModifierOptions,
    updateModifierOptionIsDefault,
    updateModifierGroupName,
    updateModifierGroupDisplayName,
    updateModifierGroupGuestInstructions,
    updateModifierGroupMinimum,
    updateModifierGroupMaximum,
    validateModifierGroup,
    validateModifierOption,
    saveModifierGroup,
    showDeleteModifierGroupModal,
    deleteModifierGroup,
    cancelNewModifierGroup,
    prepareModifierGroupsModal,
    addNestedModifierGroupToModifierOption,
    removeNestedModifierGroupFromModifierOption,
    moveModifierGroupInModifierOption,
    resetModifierGroupForm,
    prepareMenuItemsModal,
    selectOptionForModifierGroup,
    deselectOptionFromModifierGroup,
  } = props;
  const { location } = useSelector(selectLocationsState);
  const dispatch = useDispatch();
  const { contextId: companyId, locationId, id } = useParams<ContextParams>();
  const history = useHistory();

  const [selectedIsDefaultModifier, setSelectedIsDefaultModifier] = React.useState<DefaultModifierSelection>({
    itemId: '',
    isDefault: false,
  });

  const hasManagePermission = Verifier.check(
    new PrincipalPermissions(permissions),
    SecurityScope.location,
    ResourceType.menu,
    MenuResourceActions.all,
    locationId
  );

  const isSharedModGroup = !!modifierGroup?.parentTemplateId;

  const backLinkTo = useMemo(() => {
    return {
      baseUrl: Routes.getModifierGroupsPage(companyId, locationId),
      searchParams: { query: backQuery ?? '', page: String(backPage) },
    };
  }, [backPage, backQuery, companyId, locationId]);

  const min = modifierGroup && modifierGroup.constraints.min ? modifierGroup.constraints.min : 'None';
  const max = modifierGroup && modifierGroup.constraints.max ? modifierGroup.constraints.max : 'Unlimited';

  const isNew = id === 'new';
  const title = isNew ? 'New Modifier Group' : editing ? 'Edit Modifier Group' : cache.name!;

  const formSaveButtonText = isNew ? 'Create Modifier Group' : undefined;

  const allowNestedMods = !!location && !!location.settings.supportsNestedModifiers;

  const onStartAddingNestedMods = (parentOption: IEmbeddedModifierItem | ITemplateEmbeddedModifierItem) => {
    const embeddedModifierItem = parentOption as IEmbeddedModifierItem;
    prepareModifierGroupsModal(true, embeddedModifierItem.nestedGroups ?? [], embeddedModifierItem);
  };

  const onRemoveNestedModifierGroup = React.useCallback(
    (modGroupId: string): void => {
      removeNestedModifierGroupFromModifierOption(parentModifierOptionId, modGroupId);
    },
    [removeNestedModifierGroupFromModifierOption, parentModifierOptionId]
  );

  const onSubmit = (): void => {
    if (modifierGroup) {
      validateModifierGroup(modifierGroup);
    }
  };

  const onCancel = () => {
    if (isNew) {
      cancelNewModifierGroup();
    } else {
      resetModifierGroupForm(cache);
    }
  };

  const onVisibilityChanged = editing
    ? (id: string, visible: boolean): void => {
        updateModifierOptionVisibility(id, visible);
      }
    : (id: string, visible: boolean): void => {
        updateModifierOptionVisibilityAndSave(companyId, locationId, modifierGroup, id, visible);
      };

  const onDefaultChanged = (itemId: string, isDefault: boolean): void => {
    updateModifierOptionIsDefault(itemId, isDefault);
    setSelectedIsDefaultModifier({ itemId, isDefault });
  };

  const onChangeMaxValue = (id: string, constraints: number): void => {
    updateModifierConstraintsValue(id, constraints);
  };

  const onMultiplesModifierOptionsChanged = (allowMultiplesOfEachOption: boolean): void => {
    updateMultiplesModifierOptions(allowMultiplesOfEachOption);
  };

  const onDeleteModifierGroupClick = () => {
    showDeleteModifierGroupModal(true, modifierGroup);
  };

  const onConfirmDeleteModifierGroup = () => {
    deleteModifierGroup(companyId, locationId, modifierGroup);
  };

  const onAddOptionsClick = () => {
    prepareMenuItemsModal(
      true,
      modifierGroupForm.modifierGroup.options.map((option) => option.itemId)
    );
  };

  const performSave = React.useCallback(() => {
    if (modifierGroup) {
      saveModifierGroup(companyId, locationId, modifierGroup);
    }
  }, [saveModifierGroup, companyId, locationId, modifierGroup]);

  const fetchLocation = React.useCallback(
    (companyId: string, locationId: string) => {
      if (!location || location.id !== locationId) {
        dispatch(LocationsThunks.loadSelectedLocation({ companyId, locationId }));
      }
    },
    [location, dispatch]
  );

  const fetchModifierGroup = React.useCallback(() => {
    loadModifierGroup(companyId, locationId, id);
  }, [loadModifierGroup, companyId, locationId, id]);

  React.useEffect(() => {
    fetchLocation(companyId, locationId);
  }, [fetchLocation, companyId, locationId]);

  React.useEffect(() => {
    if (isNew) {
      prepareNewModifierGroup();
    } else {
      fetchModifierGroup();
    }
  }, [isNew, prepareNewModifierGroup, fetchModifierGroup]);

  React.useEffect(() => {
    if (validated) {
      performSave();
    }
  }, [validated, performSave]);

  React.useEffect(() => {
    if (saved && isNew && !actionBarVisible) {
      history.push(Routes.getModifierGroupPage(companyId, locationId, modifierGroup._id));
    }
  }, [saved, isNew, actionBarVisible, history, companyId, locationId, modifierGroup]);

  React.useEffect(() => {
    if (deletedModifierGroup && deletedModifierGroup._id === id && !deleteError) {
      history.push(getBackLinkUrl(backLinkTo));
    }
  }, [id, deletedModifierGroup, deleteError, history, backLinkTo]);

  React.useEffect(() => {
    if (cancelled) {
      history.push(getBackLinkUrl(backLinkTo));
    }
  }, [cancelled, history, backLinkTo]);

  React.useEffect(() => {
    return () => {
      resetModifierGroupForm();
    };
  }, [resetModifierGroupForm]);

  React.useEffect(() => {
    validateModifierOption(modifierGroup);
  }, [selectedIsDefaultModifier, modifierGroup, validateModifierOption]);

  return (
    <>
      {deleteRequested && modifierGroup && (
        <DeleteConfirmationModal
          setShowModal={showDeleteModifierGroupModal}
          item='Modifier Group'
          itemName={modifierGroup.name!}
          loading={deleteProcessing}
          handleDelete={onConfirmDeleteModifierGroup}
        />
      )}

      <AddModifierGroupDialog
        visible={isSelectingNestedModifierGroups}
        companyId={companyId}
        locationId={locationId}
        parentModifierGroupId={modifierGroup._id}
        addModifierGroup={addNestedModifierGroupToModifierOption}
        removeModifierGroup={onRemoveNestedModifierGroup}
      />

      <FormActionBar
        actionBarVisible={actionBarVisible}
        saveLabel={formSaveButtonText}
        handleFormSubmit={onSubmit}
        resetForm={onCancel}
      />

      <MenuBuilderLayoutContent
        companyId={companyId}
        locationId={locationId}
        locationName={location && location.id === locationId ? location.name : null}
        stockStatusOnly={!hasManagePermission}
        tab={MenuBuilderTab.MOD_GROUPS}
      >
        {loading || (!modifierGroup._id && !isNew) ? (
          <LoadingSpinner />
        ) : (
          <>
            <ActionHeader
              text={title}
              backLinkTo={isNew || editing ? undefined : backLinkTo}
              afterTextSlot={isSharedModGroup && <TextIcon icon={Icon.Share} additionalStyles={styles.sharedIcon} />}
              actionButtons={
                isSharedModGroup
                  ? undefined
                  : [
                      {
                        label: 'Delete',
                        variant: 'secondary-gray-bg',
                        unavailable: processing,
                        hidden: isNew || editing,
                        onClick: onDeleteModifierGroupClick,
                      },
                      {
                        label: 'Edit',
                        unavailable: processing,
                        hidden: isNew || editing,
                        onClick: startEditingModifierGroup,
                      },
                    ]
              }
            />

            <PanelLayout additionalColumnStyles={styles.PanelLayout_container}>
              <Panel title='Details'>
                {editing ? (
                  <ModifierGroupDetailsForm
                    modifierGroupForm={modifierGroupForm}
                    onNameChange={updateModifierGroupName}
                    onDisplayNameChange={updateModifierGroupDisplayName}
                    onGuestInstructionsChange={updateModifierGroupGuestInstructions}
                    onMinimumChange={updateModifierGroupMinimum}
                    onMaximumChange={updateModifierGroupMaximum}
                  />
                ) : (
                  <DetailLayout>
                    <DetailElement label='Group Name *'>{modifierGroup.name}</DetailElement>
                    <DetailElement
                      label='Display Name'
                      labelNoteComponent={<span className={styles.labelNote}>(optional)</span>}
                    >
                      {modifierGroup.displayName || <NoneValue text='No Display Name' />}
                    </DetailElement>
                    <DetailElement label='Guest Instructions'>
                      {modifierGroup.guestInstructions || <NoneValue text='No Instructions' />}
                    </DetailElement>
                    <DetailElement label='Required Selection'>
                      <span className={styles.constraint}>Min: </span>
                      {min}
                      <span className={styles.separator}>|</span>
                      <span className={styles.constraint}>Max: </span>
                      {max}
                    </DetailElement>
                  </DetailLayout>
                )}
              </Panel>

              <Panel
                title='Modifier Options'
                headerControls={
                  !editing ? undefined : (
                    <Button
                      label='+ Add Options'
                      variant='primary'
                      unavailable={processing}
                      onClick={onAddOptionsClick}
                    />
                  )
                }
                subTitleComponent={
                  modifierGroup.allowMultiplesOfEachOption === true || editing ? (
                    <AllowMultiplesModifierOptions
                      allowMultiplesOfEachOption={modifierGroup.allowMultiplesOfEachOption ?? false}
                      editing={editing}
                      processing={processing}
                      className={editing ? styles.isAllowMultiplesModifierEditMode : styles.isAllowMultiplesModifier}
                      onMultiplesModifierOptionsChanged={onMultiplesModifierOptionsChanged}
                    />
                  ) : null
                }
                fullContentArea
              >
                <ModifierOptionsValidationNotification
                  showMessage={modifierGroupForm.optionsValidation.isDefault.hasError}
                  message={modifierGroupForm.optionsValidation.isDefault.errorMessage}
                />

                <ModifierOptionsValidationNotification
                  showMessage={modifierGroupForm.remoteValidation.hasError}
                  message={modifierGroupForm.remoteValidation.errorMessage}
                />

                <ModifierGroupOptionsList
                  modifierGroup={modifierGroup}
                  optionsValidation={modifierGroupForm.optionsValidation}
                  processing={processing}
                  editing={editing}
                  companyId={companyId}
                  locationId={locationId}
                  allowNestedMods={allowNestedMods}
                  onOptionMoved={moveModifierOptionInModifierGroup}
                  onOptionDeleted={deselectOptionFromModifierGroup}
                  onVisibilityChanged={onVisibilityChanged}
                  onAddNestedMod={onStartAddingNestedMods}
                  onRemoveNestedMod={removeNestedModifierGroupFromModifierOption}
                  onMoveNestedMod={moveModifierGroupInModifierOption}
                  onChangeMaxValue={onChangeMaxValue}
                  onDefaultChanged={onDefaultChanged}
                />
              </Panel>
            </PanelLayout>
          </>
        )}
      </MenuBuilderLayoutContent>

      <AddMenuItemsDialog
        visible={isSelectingModifierOptions}
        companyId={companyId}
        locationId={locationId}
        title='Add Modifier Options'
        typeSelection={true}
        addMenuItemToSelection={selectOptionForModifierGroup}
        removeMenuItemFromSelection={deselectOptionFromModifierGroup}
      />
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  permissions: state.session.permissions.permissionsList,
  actionBarVisible: state.ui.formState.isDirty,
  location: state.menuBuilder.locationPicker.location,
  backQuery: state.menuBuilder.modifierGroups.query,
  backPage: state.menuBuilder.modifierGroups.page,
  modifierGroupForm: state.menuBuilder.modifierGroups.modifierGroupForm,
  deleteRequested: state.menuBuilder.modifierGroups.deleteRequested,
  deleteProcessing: state.menuBuilder.modifierGroups.deleteProcessing,
  deleteError: state.menuBuilder.modifierGroups.deleteError,
  deletedModifierGroup: state.menuBuilder.modifierGroups.deletedModifierGroup,
  isSelectingModifierOptions: state.menuBuilder.itemsAndMods.menuItemSelection.modalVisible,
  isSelectingNestedModifierGroups: state.menuBuilder.modifierGroups.modifierGroupSelection.modalVisible,
  parentModifierOptionId: state.menuBuilder.modifierGroups.modifierGroupSelection.parentModifierOptionId,
});

const actionCreators = {
  prepareNewModifierGroup,
  loadModifierGroup,
  startEditingModifierGroup,
  moveModifierOptionInModifierGroup,
  updateModifierOptionVisibility,
  updateModifierOptionVisibilityAndSave,
  updateModifierConstraintsValue,
  updateMultiplesModifierOptions,
  updateModifierOptionIsDefault,
  updateModifierGroupName,
  updateModifierGroupDisplayName,
  updateModifierGroupGuestInstructions,
  updateModifierGroupMinimum,
  updateModifierGroupMaximum,
  validateModifierGroup,
  validateModifierOption,
  saveModifierGroup,
  showDeleteModifierGroupModal,
  deleteModifierGroup,
  cancelNewModifierGroup,
  prepareModifierGroupsModal,
  addNestedModifierGroupToModifierOption,
  removeNestedModifierGroupFromModifierOption,
  moveModifierGroupInModifierOption,
  resetModifierGroupForm,
  prepareMenuItemsModal,
  selectOptionForModifierGroup,
  deselectOptionFromModifierGroup,
};

const connector = connect(mapStateToProps, actionCreators);

export default connector(ViewModifierGroupPage);
