import React from 'react';
import { Appearance } from '@ready/menu.core';
import styles from './DefaultAppearanceSelector.module.scss';
import { components, OptionProps, SingleValueProps } from 'react-select';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';
import SelectFilter, { Option } from '../../../components/SelectFilter/SelectFilter';

export const Appearances = {
  expanded: {
    value: Appearance.expanded,
    icon: Icon.ExpandedSection,
    label: 'EXPANDED',
    subLabel: 'Gallery and items visible',
  } as Option,
  partiallyExpanded: {
    value: Appearance.partiallyExpanded,
    icon: Icon.PartialCollapsedSection,
    label: 'PARTIALLY EXPANDED',
    subLabel: 'Gallery visible, items hidden',
  } as Option,
  collapsed: {
    value: Appearance.collapsed,
    icon: Icon.CollapsedSection,
    label: 'COLLAPSED',
    subLabel: 'Gallery and items hidden',
  } as Option,
};

const options: Option[] = [Appearances.expanded, Appearances.partiallyExpanded, Appearances.collapsed];

const findSelectedAppearance = (defaultAppearance: Appearance): Option => {
  return options.find((option) => option.value === defaultAppearance) || options[0];
};

interface DefaultAppearanceSelectorProps {
  defaultAppearance: Appearance;
  onChange?: (data: Appearance) => void;
  hideIndicator?: boolean;
  menuIsOpen?: boolean;
  withoutBorder?: boolean;
}

const DefaultAppearanceSelector = (props: DefaultAppearanceSelectorProps): JSX.Element => {
  const { defaultAppearance, onChange = () => {}, hideIndicator = false, menuIsOpen, withoutBorder } = props;

  const selectedAppearance = findSelectedAppearance(defaultAppearance);

  const Option = (props: OptionProps<Option, boolean>) => (
    <components.Option {...props}>
      <div className={styles.value}>
        <TextIcon icon={props.data.icon} additionalStyles={styles.icon} />
        <div className={styles.textContainer}>
          <div className={styles.label}>{props.data.label}</div>
          <div className={styles.subLabel}>{props.data.subLabel}</div>
        </div>
      </div>
    </components.Option>
  );

  const SingleValue = (props: SingleValueProps<Option>) => (
    <components.SingleValue {...props}>
      <div className={styles.value}>
        <TextIcon icon={props.data.icon!} additionalStyles={styles.icon} />
        <div className={styles.textContainer}>
          <div className={styles.label}>{props.data.label}</div>
          <div className={styles.subLabel}>{props.data.subLabel}</div>
        </div>
      </div>
    </components.SingleValue>
  );

  return (
    <div className={styles.container}>
      <SelectFilter
        value={selectedAppearance}
        options={options}
        onChange={(data: any) => onChange(data.value)}
        customComponents={{
          Option,
          SingleValue,
        }}
        largeSize
        hideIndicator={hideIndicator}
        menuIsOpen={menuIsOpen}
        withoutBorder={withoutBorder}
      />
    </div>
  );
};

export default DefaultAppearanceSelector;
