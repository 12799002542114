import { IPagedResponse } from '../types/IPagedResponse.type';
import { PaginationResponse } from '@ready/dashboardv2api.contracts';

const toPaginationResponse = <T>(
  response: IPagedResponse<T>,
  pageNumber: number,
  pageSize: number
): PaginationResponse<T> => ({
  start: (pageNumber - 1) * pageSize,
  limit: pageSize,
  length: response.results.length,
  total: response.totalCount,
  items: response.results,
});

export default toPaginationResponse;
