import React from 'react';
import { DetailLayout, DetailElement } from '../../../../components/DetailLayout';
import TextField from '../../../../components/TextField/TextField';
import TextBubble from '../../../../components/TextBubble/TextBubble';
import TextIcon, { Icon } from '../../../../components/Icon/TextIcon';
import { useSelector } from 'react-redux';
import { OrderSmsMessageSource } from '@ready/dashboardv2api.contracts';
import styles from './ViewOrderSMSMessages.module.scss';
import { orderSmsMessagesSelector } from '../../../redux/selectors/orderSmsMessagesSelector';

export interface IOrderSmsTextType {
  normalText: string;
  boldText: string;
}
export interface ConfiguredSettingMessageProps extends IOrderSmsTextType {
  bubbleText?: string;
}
export interface IOrderSmsMessagesText {
  orderIsPlaced: IOrderSmsTextType;
  orderIsInProgress: IOrderSmsTextType;
  orderIsReady: IOrderSmsTextType;
  orderIsCancelled: IOrderSmsTextType;
  orderPickupTime: IOrderSmsTextType;
}

export const orderSmsMessagesText: IOrderSmsMessagesText = {
  orderIsPlaced: {
    normalText: 'Send message when',
    boldText: 'order is placed',
  },
  orderIsInProgress: {
    normalText: 'Send message when status set to',
    boldText: 'in progress',
  },
  orderIsReady: {
    normalText: 'Send message when status is set to',
    boldText: 'ready',
  },
  orderIsCancelled: {
    normalText: 'Send message when order is',
    boldText: 'cancelled',
  },
  orderPickupTime: {
    normalText: 'Send message when',
    boldText: 'pick up time is updated',
  },
};

export const NotConfiguredMessage = (props: IOrderSmsTextType) => {
  const { normalText = '', boldText = '' } = props;
  return (
    <div className={styles.notConfiguredSettingContainer}>
      <div className={styles.iconColumn}>
        <TextIcon additionalStyles={styles.notConfiguredSettingIcon} icon={Icon.Remove} />
      </div>
      <div className={styles.textColumn}>
        <p className={styles.notConfiguredSettingText}>
          {normalText} <span className={styles.boldText}>{boldText}</span>
        </p>
      </div>
    </div>
  );
};
export const ConfiguredMessage = (props: ConfiguredSettingMessageProps) => {
  const { normalText = '', boldText = '', bubbleText = '' } = props;

  return (
    <div className={styles.configuredSettingContainer}>
      <div className={styles.iconColumn}>
        <TextIcon additionalStyles={styles.configuredSettingIcon} icon={Icon.Checkmark} />
      </div>
      <div className={styles.textColumn}>
        <p className={styles.configuredSettingText}>
          {normalText}
          <span className={styles.boldText}> {boldText}</span>
        </p>
        <TextBubble arrowLocation='bottom' arrowAlignment='right'>
          <div className={styles.textBubbleChildrenContainer}>
            <span>{bubbleText}</span>
          </div>
        </TextBubble>
      </div>
    </div>
  );
};

export const ViewOrderSmsMessages = () => {
  const { orderPickupTime, orderIsInProgress, orderIsPlaced, orderIsCancelled, orderIsReady } = orderSmsMessagesText;
  const { orderSmsMessages } = useSelector(orderSmsMessagesSelector);

  const { locationConfig, messageSource, companyConfig } = orderSmsMessages;
  const orderSmsConfig = messageSource === OrderSmsMessageSource.Location ? locationConfig : companyConfig;
  const { orderCancelled, orderPlaced, orderInProgress, orderReady, pickUpTimeUpdated } = orderSmsConfig;
  const textFieldValue = messageSource === OrderSmsMessageSource.Location ? 'Custom Messaging' : 'Company Defaults';

  return (
    <DetailLayout>
      <DetailElement label='Message Source'>
        <TextField value={textFieldValue} />
      </DetailElement>

      <DetailElement
        label='Message Settings'
        isLabelNoteColumn
        labelNoteComponent={
          <span className={styles.labelNote}>
            Pick up times can be dynamically inserted into messages by entering the following variable:
            <b> %TIME%</b>
          </span>
        }
      >
        {orderPlaced?.isActive ? (
          <ConfiguredMessage
            normalText={orderIsPlaced.normalText}
            boldText={orderIsPlaced.boldText}
            bubbleText={orderPlaced?.value}
          />
        ) : (
          <NotConfiguredMessage normalText={orderIsPlaced.normalText} boldText={orderIsPlaced.boldText} />
        )}
        {orderInProgress?.isActive ? (
          <ConfiguredMessage
            normalText={orderIsInProgress.normalText}
            boldText={orderIsInProgress.boldText}
            bubbleText={orderInProgress?.value}
          />
        ) : (
          <NotConfiguredMessage normalText={orderIsInProgress.normalText} boldText={orderIsInProgress.boldText} />
        )}
        {orderReady?.isActive ? (
          <ConfiguredMessage
            normalText={orderIsReady.normalText}
            boldText={orderIsReady.boldText}
            bubbleText={orderReady?.value}
          />
        ) : (
          <NotConfiguredMessage normalText={orderIsReady.normalText} boldText={orderIsReady.boldText} />
        )}
        {orderCancelled?.isActive ? (
          <ConfiguredMessage
            normalText={orderIsCancelled.normalText}
            boldText={orderIsCancelled.boldText}
            bubbleText={orderCancelled?.value}
          />
        ) : (
          <NotConfiguredMessage normalText={orderIsCancelled.normalText} boldText={orderIsCancelled.boldText} />
        )}
        {pickUpTimeUpdated?.isActive ? (
          <ConfiguredMessage
            normalText={orderPickupTime.normalText}
            boldText={orderPickupTime.boldText}
            bubbleText={pickUpTimeUpdated?.value}
          />
        ) : (
          <NotConfiguredMessage normalText={orderPickupTime.normalText} boldText={orderPickupTime.boldText} />
        )}
      </DetailElement>
    </DetailLayout>
  );
};
