import React from 'react';
import SearchInput from '../../components/SearchInput/SearchInput';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../redux/initialStates/AppState';
import { setAssignModalQuery } from '../redux/ServerAssignmentActions';

const AssignTableSearch = ({ assignModalQuery, setAssignModalQuery, processing }: ReduxProps): JSX.Element => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAssignModalQuery(event.target.value);
  };

  return (
    <SearchInput
      value={assignModalQuery}
      onChange={handleChange}
      placeholder='Search Servers'
      fullWidth
      disabled={processing}
    />
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    assignModalQuery: state.tableServerAssignment.serverAssignments.assignModalQuery,
    processing: state.tableServerAssignment.serverAssignments.assignModalProcessing,
  };
};

const actionCreators = {
  setAssignModalQuery,
};

const connector = connect(mapStateToProps, actionCreators);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AssignTableSearch);
