import React from 'react';

import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { DragHandle } from '.';

export interface DragItemProps {
  item: any;
  itemId: string;
  itemIndex: number;
  className?: string;
  draggingClassName?: string;
  dragHandleClassName?: string;
  draggingDisabled?: boolean;
  dragHandleProvided?: boolean;
  dragItemComponentBuilder: (item: any, dragHandle?: JSX.Element, isDragging?: boolean) => JSX.Element;
}

const DragItem = ({
  item,
  itemId,
  itemIndex,
  className,
  draggingClassName,
  dragHandleClassName,
  draggingDisabled,
  dragHandleProvided,
  dragItemComponentBuilder,
}: DragItemProps): JSX.Element => {
  if (draggingDisabled) {
    return <div className={className}>{dragItemComponentBuilder(item)}</div>;
  }

  return (
    <Draggable draggableId={itemId} index={itemIndex}>
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={snapshot.isDragging ? draggingClassName : className}
        >
          {!dragHandleProvided && (
            <DragHandle providedDragHandleProps={provided.dragHandleProps} className={dragHandleClassName} />
          )}
          {dragItemComponentBuilder(
            item,
            dragHandleProvided ? (
              <DragHandle providedDragHandleProps={provided.dragHandleProps} className={dragHandleClassName} />
            ) : undefined,
            snapshot.isDragging
          )}
        </div>
      )}
    </Draggable>
  );
};

export default DragItem;
