import { PaginationResponse } from '@ready/dashboardv2api.contracts';
import { ITemplateMenuItem, WithAssignedLocationsCount } from '@ready/menu.core';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSharedMenuItemList, ItemTypeFilter } from 'sharedMenuItems/pages/itemsAndMods/service';

interface IGetSharedMenuItemsForDialogThunkArgs {
  isLoadMore: boolean;
  companyId: string;
  searchTerm?: string;
  page?: number;
  type: ItemTypeFilter;
}

export const getSharedMenuItemsForDialogThunk = createAsyncThunk<
  PaginationResponse<WithAssignedLocationsCount<ITemplateMenuItem>>,
  IGetSharedMenuItemsForDialogThunkArgs
>(
  'sharedMenuItems/itemsAndMods/dialog/fetch',
  ({ companyId, type, searchTerm, page }: IGetSharedMenuItemsForDialogThunkArgs) =>
    getSharedMenuItemList(companyId, searchTerm, type, undefined, page)
);
