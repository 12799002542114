import { ISmsNotificationsConfig, ISmsConfig, ILocationSmsConfigEdit } from '@ready/dashboardv2api.contracts';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { set200Toast, toastErrorState } from '../../../../redux/actions/uiActions/responseStateActions';
import { AppDispatch } from '../../../../redux/store';
import { OrderSmsMessagesService } from '../../../services/orderSMSMessagesService';

export class OrderSmsMessagesThunk {
  public static fetchOrderSmsMessagesConfig = createAsyncThunk(
    'orderSms/fetchOrderSmsMessagesConfig',
    async (
      args: { companyId: string; locationId: string },
      { dispatch }: { dispatch: AppDispatch }
    ): Promise<ISmsNotificationsConfig> => {
      try {
        return await OrderSmsMessagesService.getOrderSmsMessagesConfig(args.companyId, args.locationId);
      } catch (error) {
        dispatch(toastErrorState(error.status, error.message));
        throw error;
      }
    }
  );

  public static updateOrderSmsMessagesLocationConfig = createAsyncThunk(
    'orderSms/updateOrderSmsMessagesLocationConfig',
    async (
      args: {
        companyId: string;
        locationId: string;
        orderSmsMessages: ISmsNotificationsConfig;
      },
      { dispatch }: { dispatch: AppDispatch }
    ): Promise<ILocationSmsConfigEdit> => {
      const updateOrderSmsLocationActionPayload: ILocationSmsConfigEdit = {
        locationConfig: args.orderSmsMessages.locationConfig,
        companyId: args.companyId,
        messageSource: args.orderSmsMessages.messageSource,
      };
      try {
        const updatedConfig = await OrderSmsMessagesService.updateOrderSmsMessagesLocationConfig(
          args.companyId,
          args.locationId,
          updateOrderSmsLocationActionPayload
        );
        dispatch(set200Toast());
        return updatedConfig;
      } catch (error) {
        dispatch(toastErrorState(error.status, error.message));
        throw error;
      }
    }
  );

  public static updateOrderSmsMessagesCompanyConfig = createAsyncThunk(
    'orderSms/updateOrderSmsMessagesCompanyConfig',
    async (
      args: { companyId: string; orderSmsMessagesCompanyConfig: ISmsConfig },
      { dispatch }: { dispatch: AppDispatch }
    ): Promise<ISmsConfig> => {
      try {
        const updatedConfig = await OrderSmsMessagesService.updateOrderSmsMessagesCompanyConfig(
          args.companyId,
          args.orderSmsMessagesCompanyConfig
        );
        return updatedConfig;
      } catch (error) {
        dispatch(toastErrorState(error.status, error.message));
        throw error;
      }
    }
  );
}
