import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { getLocationItemGalleriesThunk } from './thunks';
import { IPagination } from '@ready/dashboardv2api.contracts';
import { IBulkEditItemGallery } from '@ready/menu.core';

interface State {
  items: IBulkEditItemGallery[];
  pagination?: IPagination;
  loading: boolean;
}

const initialState: State = {
  loading: true,
  items: [],
};

export const itemGalleryLocationSettingsSlice = createSlice({
  name: 'sharedMenus/locationSettings/itemGallery',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<State>) => {
    builder.addCase(getLocationItemGalleriesThunk.pending, (state: State) => {
      state.loading = true;
    });
    builder.addCase(getLocationItemGalleriesThunk.fulfilled, (state: State, action) => {
      state.loading = false;
      const { items, ...pagination } = action.payload;
      state.items = items;
      state.pagination = pagination;
    });

    builder.addCase(getLocationItemGalleriesThunk.rejected, (state: State) => {
      state.loading = false;
    });
  },
});
