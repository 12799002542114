import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ModifierGroupListPage } from './pages/modifierGroups/list/ModifierGroupListPage';
import { ModifierGroupDetailsPage } from './pages/modifierGroups/details/ModifierGroupDetailsPage';
import { ItemsAndModsPage } from './pages/itemsAndMods/ItemsAndModsPage';
import { ItemGroupListPage } from './pages/itemGroups/list/ItemGroupListPage';
import { ItemGroupDetailsPage } from './pages/itemGroups/details/ItemGroupDetailsPage';
import { ItemAndModDetailsPageRedirect } from './pages/itemsAndMods/components/ItemAndModDetailsPageRedirect';
import { CreateItemPage } from './pages/createEditItem/CreateItemPage';
import { CreateSharedModifierPage } from './pages/createEditItem/CreateSharedMenuModifierPage';
import { ItemsAndModsAssignedLocationsPage } from './pages/itemAssignedLocations/ItemsAndModsAssignedLocationsPage';
import { ItemGroupAssignedLocationsPage } from './pages/itemGroups/assignedLocations/ItemGroupAssignedLocationsPage';
import { MenuConfigsListPage } from './pages/menuConfigs/list/MenuConfigsListPage';
import { usePageTitle } from 'hooks';
import { MenuPage } from './pages/menu/MenuPage';
import { ItemGalleryPage } from './pages/itemGallery/ItemGalleryPage';
import { MenuLocationSettingsPage } from './pages/locationSettings/menu/MenuLocationSettingsPage';

import { ItemGalleryLocationSettingsPage } from './pages/locationSettings/itemGallery/ItemGalleryLocationSettingsPage';

import { LocationsPage } from './pages/locations/LocationsPage';
import { SectionLocationSettingsPage } from './pages/locationSettings/section/SectionLocationSettingsPage';

export const SharedMenuItemsRouter = () => {
  const { path } = useRouteMatch();
  usePageTitle('Menu Builder');

  return (
    <Switch>
      <Route
        exact
        path={`${path}/menuConfigs/:menuConfigId/menus/:menuId/sections/:sectionId/locationSettings`}
        component={SectionLocationSettingsPage}
      />
      ,
      <Route
        exact
        path={`${path}/menuConfigs/:menuConfigId/menus/:menuId/locationSettings`}
        component={MenuLocationSettingsPage}
      />
      ,
      <Route
        exact
        path={[
          `${path}/menuConfigs/:menuConfigId/itemGalleries/:itemGalleryId/locationSettings`,
          `${path}/menuConfigs/:menuConfigId/menus/:menuId/itemGalleries/:itemGalleryId/locationSettings`,
          `${path}/menuConfigs/:menuConfigId/itemGalleries/:itemGalleryId/locationSettings`,
        ]}
        component={ItemGalleryLocationSettingsPage}
      />
      ,
      <Route
        exact
        path={`${path}/menuConfigs/:menuConfigId/menus/itemGallery/:itemGalleryId`}
        component={ItemGalleryPage}
      />
      ,
      <Route exact path={`${path}/menuConfigs/:menuConfigId/menus/:id`} component={MenuPage} />,
      <Route exact path={`${path}/menuConfigs`} component={MenuConfigsListPage} />,
      <Route exact path={`${path}/locations`} component={LocationsPage} />,
      <Route path={`${path}/items-and-mods/new-item`} component={CreateItemPage} />
      <Route path={`${path}/items-and-mods/new-modifier`} component={CreateSharedModifierPage} />
      <Route path={`${path}/items-and-mods/assignedLocations`} component={ItemsAndModsAssignedLocationsPage} />
      <Route path={`${path}/items-and-mods/:id/assigned-locations`} component={ItemsAndModsAssignedLocationsPage} />
      <Route
        path={[
          `${path}/items-and-mods/:id/forcedModifiers/:nestedForcedModifierId`,
          `${path}/items-and-mods/:id`,
          `${path}/item-groups/:itemGroupId/items/:id`,
          `${path}/menuConfigs/:menuConfigId/menus/:menuId/items-and-mods/:id`,
          `${path}/menuConfigs/:menuConfigId/menus/itemGallery/:itemGalleryId/items-and-mods/:id`,
          `${path}/modifier-groups/:modGroupId/items-and-mods/:id`,
        ]}
        component={ItemAndModDetailsPageRedirect}
      />
      <Route path={`${path}/item-groups/new`} component={ItemGroupDetailsPage} />
      <Route path={`${path}/item-groups/:id/assigned-locations`} component={ItemGroupAssignedLocationsPage} />
      <Route
        path={[
          `${path}/item-groups/:id`,
          `${path}/menuConfigs/:menuConfigId/menus/:menuId/item-groups/:id`,
          `${path}/menuConfigs/:menuConfigId/menus/itemGallery/:itemGalleryId/item-groups/:id`,
        ]}
        component={ItemGroupDetailsPage}
      />
      <Route path={`${path}/item-groups`} component={ItemGroupListPage} />
      <Route path={`${path}/modifier-groups/new`} component={ModifierGroupDetailsPage} />
      <Route
        path={[
          `${path}/modifier-groups/:id`,
          `${path}/modifier-groups/:id/items/:itemId`,
          `${path}/modifier-groups/:id/items-and-mods/:itemId`,
        ]}
        component={ModifierGroupDetailsPage}
      />
      <Route path={`${path}/items-and-mods`} component={ItemsAndModsPage} />
      <Route path={`${path}/modifier-groups`} component={ModifierGroupListPage} />
      {/* The last route is what's going to show first after we click on side bar navigation */}
      <Route path={`${path}`} component={MenuConfigsListPage} />
    </Switch>
  );
};

export class SharedMenuItemsRoutes {
  public static getTemplate(): string {
    return '/shared-menu-items';
  }

  public static getMenuConfigsRoute(companyId: string): string {
    return `/companies/${companyId}/shared-menu-items/menuConfigs`;
  }

  public static getSharedMenusMenuRoute(companyId: string, menuConfigId: string, menuId: string): string {
    return `/companies/${companyId}/shared-menu-items/menuConfigs/${menuConfigId}/menus/${menuId}`;
  }

  public static getSharedMenusItemGalleryRoute(companyId: string, menuConfigId: string, itemGalleryId: string): string {
    return `/companies/${companyId}/shared-menu-items/menuConfigs/${menuConfigId}/menus/itemGallery/${itemGalleryId}`;
  }

  public static getMenuLocationSettingsRoute(companyId: string, menuConfigId: string, menuId: string): string {
    return `/companies/${companyId}/shared-menu-items/menuConfigs/${menuConfigId}/menus/${menuId}/locationSettings`;
  }

  public static getItemGalleryLocationSettingsRoute(
    companyId: string,
    menuConfigId: string,
    galleryId: string,
    menuId?: string
  ): string {
    const base = `/companies/${companyId}/shared-menu-items/menuConfigs/`;

    if (menuId) {
      return `${base + menuConfigId}/menus/${menuId}/itemGalleries/${galleryId}/locationSettings`;
    }
    return `${base + menuConfigId}/itemGalleries/${galleryId}/locationSettings`;
  }

  public static getMenuSectionLocationSettingsRoute(
    companyId: string,
    menuConfigId: string,
    menuId: string,
    sectionId: string
  ): string {
    return `/companies/${companyId}/shared-menu-items/menuConfigs/${menuConfigId}/menus/${menuId}/sections/${sectionId}/locationSettings`;
  }

  public static getItemAndModsRoute(companyId: string): string {
    return `/companies/${companyId}/shared-menu-items/items-and-mods`;
  }

  public static getItemAndModsNewItem(companyId: string): string {
    return `/companies/${companyId}/shared-menu-items/items-and-mods/new-item`;
  }

  public static getItemAndModsNewModifier(companyId: string): string {
    return `/companies/${companyId}/shared-menu-items/items-and-mods/new-modifier`;
  }

  public static getItemAndModsItem(
    companyId: string,
    itemId: string,
    menuConfigId?: string,
    menuId?: string,
    itemGalleryId?: string
  ): string {
    if (!!menuId && !!menuConfigId) {
      return `/companies/${companyId}/shared-menu-items/menuConfigs/${menuConfigId}/menus/${menuId}/items-and-mods/${itemId}`;
    }

    if (!!itemGalleryId && !!menuConfigId) {
      return `/companies/${companyId}/shared-menu-items/menuConfigs/${menuConfigId}/menus/itemGallery/${itemGalleryId}/items-and-mods/${itemId}`;
    }
    return `/companies/${companyId}/shared-menu-items/items-and-mods/${itemId}`;
  }

  public static getItemGroupsFromItemPage(companyId: string, itemGroupId: string, itemId: string): string {
    return `/companies/${companyId}/shared-menu-items/item-groups/${itemGroupId}/items/${itemId}`;
  }

  public static geModifierGroupsFromItemPage(companyId: string, modGroupId: string, itemId: string): string {
    return `/companies/${companyId}/shared-menu-items/modifier-groups/${modGroupId}/items-and-mods/${itemId}`;
  }

  public static getForcedModifierFromItemPage(companyId: string, itemId: string, forcedModifierId: string): string {
    return `/companies/${companyId}/shared-menu-items/items-and-mods/${itemId}/forcedModifiers/${forcedModifierId}`;
  }

  public static getItemGroupListRoute(companyId: string): string {
    return `/companies/${companyId}/shared-menu-items/item-groups`;
  }

  public static getItemGroupDetailsRoute(
    companyId: string,
    itemGroupId?: string,
    menuConfigId?: string,
    menuId?: string,
    itemGalleryId?: string
  ): string {
    if (!!itemGroupId && !!menuConfigId && !!menuId) {
      return `/companies/${companyId}/shared-menu-items/menuConfigs/${menuConfigId}/menus/${menuId}/item-groups/${itemGroupId}`;
    }

    if (!!itemGroupId && !!menuConfigId && !!itemGalleryId) {
      return `/companies/${companyId}/shared-menu-items/menuConfigs/${menuConfigId}/menus/itemGallery/${itemGalleryId}/item-groups/${itemGroupId}`;
    }

    if (!!itemGroupId) {
      return `/companies/${companyId}/shared-menu-items/item-groups/${itemGroupId}`;
    }

    return `/companies/${companyId}/shared-menu-items/item-groups/new`;
  }

  public static getModifierGroupListRoute(companyId: string): string {
    return `/companies/${companyId}/shared-menu-items/modifier-groups`;
  }

  public static getModifierGroupDetailsRoute(companyId: string, id?: string): string {
    if (!id) {
      return `/companies/${companyId}/shared-menu-items/modifier-groups/new`;
    }

    return `/companies/${companyId}/shared-menu-items/modifier-groups/${id}`;
  }

  static getModifierGroupItemPage(companyId: string, modGroupId: string, itemId: string): string {
    return `/companies/${companyId}/shared-menu-items/modifier-groups/${modGroupId}/items-and-mods/${itemId}`;
  }

  public static getItemGroupAssignedLocationsRoute(companyId: string, itemGroupId: string) {
    return `/companies/${companyId}/shared-menu-items/item-groups/${itemGroupId}/assigned-locations`;
  }

  public static getAssignedLocationsRoute(companyId: string, itemId: string) {
    return `/companies/${companyId}/shared-menu-items/items-and-mods/${itemId}/assigned-locations`;
  }

  public static getLocationsRoute(companyId: string) {
    return `/companies/${companyId}/shared-menu-items/locations/`;
  }
}
