import config from '../../config';
import { IPromoCode } from '@ready/dashboardv2api.contracts';
import {
  Discount,
  IPromoCodeValidation,
  newPromoCodeValidation,
  newTimeSlotValidation,
} from '../redux/PromoCodesState';
import executeApiAsync from '../../services/utils/executeApiAsync';

export default class PromoCodeService {
  static BASE_URL = `${config.readyDashboardApiEndpoint}/admins/locations`;

  static loadPromoCodes = async (locationId: string, companyId?: string): Promise<IPromoCode[]> => {
    const url = companyId
      ? `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/promoCodes`
      : `${PromoCodeService.BASE_URL}/${locationId}/promoCodes`;
    return await executeApiAsync<IPromoCode[]>(url);
  };

  static loadPromoCode = async (locationId: string, id: string, companyId?: string): Promise<IPromoCode> => {
    const url = companyId
      ? `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/promoCodes/${id}`
      : `${PromoCodeService.BASE_URL}/${locationId}/promoCodes/${id}`;
    return await executeApiAsync<IPromoCode>(url);
  };

  static loadPosDiscounts = async (locationId: string, companyId: string): Promise<Discount> => {
    const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/discounts`;
    return await executeApiAsync<Discount>(url);
  };

  static validatePromoCode = (promoCode: IPromoCode): IPromoCodeValidation => {
    const validation = newPromoCodeValidation();
    if (!promoCode.code) {
      validation.hasErrors = true;
      validation.code.hasError = true;
      validation.code.errorMessage = 'Promo Code is required';
    }
    if (!promoCode.posDiscountId) {
      validation.hasErrors = true;
      validation.posDiscountId.hasError = true;
      validation.posDiscountId.errorMessage = 'POS Discount is required';
    }
    promoCode.timeSlots.forEach((timeSlot) => {
      const timeSlotValidation = newTimeSlotValidation();
      if (timeSlot.daysAvailable.length === 0) {
        validation.hasErrors = true;
        timeSlotValidation.daysOfWeek.hasError = true;
        timeSlotValidation.daysOfWeek.errorMessage = 'Select at least 1 day';
      }
      if (timeSlot.startHour === undefined || timeSlot.startMinute === undefined) {
        validation.hasErrors = true;
        timeSlotValidation.startTime.hasError = true;
        timeSlotValidation.startTime.errorMessage = 'Enter a valid Start Time';
      }
      if (timeSlot.endHour === undefined || timeSlot.endMinute === undefined) {
        validation.hasErrors = true;
        timeSlotValidation.endTime.hasError = true;
        timeSlotValidation.endTime.errorMessage = 'Enter a valid End Time';
      }
      if (
        timeSlot.startHour !== undefined &&
        timeSlot.startMinute !== undefined &&
        timeSlot.endHour !== undefined &&
        timeSlot.endMinute !== undefined
      ) {
        const startTime = timeSlot.startHour * 100 + timeSlot.startMinute;
        const endTime = timeSlot.endHour * 100 + timeSlot.endMinute;
        if (startTime >= endTime) {
          validation.hasErrors = true;
          timeSlotValidation.startTime.hasError = true;
          timeSlotValidation.startTime.errorMessage = 'Start Time must be before End Time';
        }
      }
      validation.timeSlots.push(timeSlotValidation);
    });
    return validation;
  };

  static savePromoCode = async (locationId: string, promoCode: IPromoCode, companyId?: string): Promise<IPromoCode> => {
    const url = companyId
      ? `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/promoCodes`
      : `${PromoCodeService.BASE_URL}/${locationId}/promoCodes`;
    if (!promoCode._id) {
      return await executeApiAsync<IPromoCode>(url, { method: 'POST', body: JSON.stringify(promoCode) });
    }

    const updateUrl = companyId
      ? `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/promoCodes/${promoCode._id}`
      : `${PromoCodeService.BASE_URL}/${locationId}/promoCodes/${promoCode._id}`;
    return await executeApiAsync<IPromoCode>(updateUrl, { method: 'PUT', body: JSON.stringify(promoCode) });
  };

  static deletePromoCode = async (locationId: string, promoCodeId: string, companyId?: string): Promise<void> => {
    const url = companyId
      ? `${config.readyDashboardApiEndpoint}/companies/${companyId}/locations/${locationId}/promoCodes/${promoCodeId}`
      : `${PromoCodeService.BASE_URL}/${locationId}/promoCodes/${promoCodeId}`;
    await executeApiAsync<IPromoCode>(url, { method: 'DELETE' });
  };
}
