import React from 'react';
import styles from './PaymentProviderPanel.module.scss';
import { DetailLayout, DetailElement } from 'components/DetailLayout';
import { PaymentProcessorProvider } from '@ready/dashboardv2api.contracts';

const providerLabelMap = {
  [PaymentProcessorProvider.Stripe]: 'Stripe',
  [PaymentProcessorProvider.Moneris]: 'Moneris',
  [PaymentProcessorProvider.Iqpay]: 'iQpay',
  [PaymentProcessorProvider.Freedompay]: 'Freedom Pay',
  [PaymentProcessorProvider.Realex]: 'Realex',
  [PaymentProcessorProvider.Jetpay]: 'JetPay',
  [PaymentProcessorProvider.Square]: 'Square Payments',
  [PaymentProcessorProvider.Bambora]: 'Bambora',
  [PaymentProcessorProvider.Adyen]: 'Ready Payments',
};

export interface PaymentProviderPanelProps {
  provider?: PaymentProcessorProvider;
}

export const PaymentProviderPanel = ({ provider }: PaymentProviderPanelProps) => {
  return (
    <>
      <DetailLayout>
        <DetailElement label='Provider'>
          {provider ? (
            <p>{providerLabelMap[provider]}</p>
          ) : (
            <p className={styles.noSelectedText}>
              <i>No provider selected</i>
            </p>
          )}
        </DetailElement>
      </DetailLayout>
    </>
  );
};
