import { IClientLocation, OrderFulfillmentStatus } from '@ready/dashboardv2api.contracts';
import { format } from 'date-fns';
import { RouteStatus } from '../../../pages/Orders/components/ToolbarContent';
import { ISinglePlacedOrder } from '../../../services/types/orders.type';
import { beginningOfTime } from '../../../utils/dateUtils';

export interface IOrderListUrlQuery {
  day: string;
  status: RouteStatus;
  query: string;
}

export interface IOrderList {
  orders: ISinglePlacedOrder[];
  upNextOrders: ISinglePlacedOrder[];
  upNextOrdersCount: number;
  offsetOrders: ISinglePlacedOrder[];
  offsetOrdersCount: number;
  overdueOrders: ISinglePlacedOrder[];
  overdueOrdersCount: number;
  completedOrders: ISinglePlacedOrder[];
  completedOrdersCount: number;
  cancelledOrders: ISinglePlacedOrder[];
  cancelledOrdersCount: number;
  dineInOrders: ISinglePlacedOrder[];
  dineInOrdersCount: number;
  loadingOrders: boolean;
  locationDetails: IClientLocation | null;
  locationDetailsLoading: boolean;
  orderingEnabledProcessing: boolean;
  alcoholOrderingEnabledProcessing: boolean;
  urlQuery: IOrderListUrlQuery;
  orderSettingsDialogOpen: boolean;
  orderSettingsDialogLoading: boolean;
}

export const orderListInitialState: IOrderList = {
  orders: [
    {
      amountPaid: 0,
      invoiceId: '',
      orderId: '',
      itemCount: 0,
      items: [],
      totals: {
        due: 0,
        otherCharges: 0,
        serviceCharge: 0,
        subtotal: 0,
        tax: 0,
        total: 0,
        discounts: 0,
      },
      appliedDiscounts: [],
      priceExcludingTip: 0,
      tip: 0,
      identityInfo: {
        primary: '',
        secondary: [],
        mappedCustomerResponses: [],
      },
      status: OrderFulfillmentStatus.Pending,
      pickupTime: beginningOfTime.toISOString(),
      offset: -1,
      newOrder: false,
      created: beginningOfTime.toISOString(),
      processing: false,
      refundInfo: [],
    },
  ],
  upNextOrders: [],
  upNextOrdersCount: 0,
  offsetOrders: [],
  offsetOrdersCount: 0,
  overdueOrders: [],
  overdueOrdersCount: 0,
  completedOrders: [],
  completedOrdersCount: 0,
  cancelledOrders: [],
  cancelledOrdersCount: 0,
  dineInOrders: [],
  dineInOrdersCount: 0,
  loadingOrders: false,
  locationDetails: null,
  locationDetailsLoading: true,
  orderingEnabledProcessing: false,
  alcoholOrderingEnabledProcessing: false,
  urlQuery: {
    day: format(new Date(), 'yyyy-MM-dd'),
    status: RouteStatus.Upcoming,
    query: '',
  },
  orderSettingsDialogOpen: false,
  orderSettingsDialogLoading: false,
};
