import React from 'react';

export interface InfoCardFooterProps {
  children: JSX.Element;
}

const InfoCardFooter = (props: InfoCardFooterProps) => {
  const { children } = props;

  return <div className='info__card__footer'>{children}</div>;
};

export default InfoCardFooter;
