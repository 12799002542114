import React, { useRef } from 'react';
import handleArrowKeyEvent from '../../utils/keyboardUtils/handleArrowKeyEvent';
import { compositeStyles } from '../../utils/cssUtils';
import styles from './TimePicker.module.scss';

interface TimePickerProps {
  value?: string;
  onChange: TimePickerOnChangeFunction;
  disabled?: boolean;
  showSeconds?: boolean;
  showMinutes?: boolean;
  darkBorder?: boolean;
  fullWidth?: boolean;
  containerPadding?: 'none' | 'small' | 'medium';
  additionalInputStyles?: string;
  hasError?: boolean;
  placeholder?: string;
}

export interface TimePickerOnChangeFunction {
  (value: string): void;
}

const focusRef = (ref: React.RefObject<HTMLInputElement> | undefined): void => {
  if (ref && ref.current) {
    ref.current.focus();
  }
};

// The displayHours/displayMinutes/displaySeconds attributes allow us to store partially entered values that we haven't
// yet "committed" through the onChange method because they're not necessarily valid values yet (for example, the hour
// could be "0" because the user is in the process of typing "01", but "0" is not a valid hour).
interface DisplayValues {
  hours: string;
  displayHours: string;
  minutes: string;
  displayMinutes: string;
  seconds: string;
  displaySeconds: string;
  period: string;
}

const padTimeValue = (value: string): string => {
  return value.padStart(2, '0');
};

const get24hTime = (value: DisplayValues, showSeconds: boolean): string => {
  if (value.period) {
    let hoursInt = parseInt(value.hours, 10);

    if (hoursInt === 12 && value.period === 'AM') {
      hoursInt = 0;
    } else if (hoursInt === 12 && value.period === 'PM') {
      hoursInt = 12;
    } else if (value.period === 'PM' && hoursInt < 12) {
      hoursInt += 12;
    }

    return `${padTimeValue(hoursInt.toString())}:${value.minutes}:${
      showSeconds ? value.seconds : value.seconds || '00'
    }`;
  } else {
    return '';
  }
};

const getTimeValuesFromString = (value: string | undefined): DisplayValues => {
  if (value) {
    if (timeStringIsValid(value)) {
      const hours = value.substring(0, 2);
      const minutes = value.substring(3, 5);
      const seconds = value.substring(6, 8);

      let period = 'AM';
      let hoursInt = parseInt(hours, 10);

      if (hoursInt === 0) {
        hoursInt = 12;
      } else if (hoursInt === 12) {
        period = 'PM';
      } else if (hoursInt > 12) {
        period = 'PM';
        hoursInt -= 12;
      }

      const paddedHours = padTimeValue(hoursInt.toString());
      return {
        hours: paddedHours,
        displayHours: paddedHours,
        minutes,
        displayMinutes: minutes,
        seconds,
        displaySeconds: seconds,
        period,
      };
    }
  }

  return blankDisplayValues;
};

const timeStringIsValid = (value: string): boolean => {
  return !!value.match('^([0-1][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$');
};

const blankDisplayValues: DisplayValues = {
  hours: '',
  displayHours: '',
  minutes: '',
  displayMinutes: '',
  seconds: '',
  displaySeconds: '',
  period: '',
};

const TimePicker = ({
  value,
  onChange,
  disabled = false,
  showSeconds = false,
  showMinutes = true,
  darkBorder = false,
  fullWidth = false,
  containerPadding = 'medium',
  additionalInputStyles,
  hasError = false,
  placeholder,
}: TimePickerProps) => {
  const providedDisplayValues = getTimeValuesFromString(value);
  const [displayValues, setDisplayValues] = React.useState<DisplayValues>(providedDisplayValues || blankDisplayValues);

  const hourInputRef: React.RefObject<HTMLInputElement> = useRef(null);
  const minuteInputRef: React.RefObject<HTMLInputElement> = useRef(null);
  const secondInputRef: React.RefObject<HTMLInputElement> = useRef(null);
  const periodInputRef: React.RefObject<HTMLInputElement> = useRef(null);

  React.useEffect(() => {
    // We initially set displayValues to the initial value provided in props, and after that we primarily manage it
    // internally so that we can retain partially entered values in displayHours/displayMinutes/displaySeconds. However,
    // it is possible that another component may change the value, so we need to check for an external change and update
    // our internal displayValues if one is detected. We do not let an external value override an internal value where
    // we have "cleared" it (e.g. set hours to ""), because then we'd never be able to clear a value, since externally
    // it is usually stored as an actual Date object which cannot have empty hours/minutes/seconds.
    if (
      (providedDisplayValues.hours || providedDisplayValues.minutes || providedDisplayValues.seconds) &&
      ((displayValues.hours && displayValues.hours !== providedDisplayValues.hours) ||
        (displayValues.minutes && displayValues.minutes !== providedDisplayValues.minutes) ||
        (displayValues.seconds && displayValues.seconds !== providedDisplayValues.seconds) ||
        displayValues.period !== providedDisplayValues.period)
    ) {
      setDisplayValues(providedDisplayValues);
    }
  }, [providedDisplayValues, displayValues]);

  const updateDisplayValue = (newDisplayValues: DisplayValues) => {
    setDisplayValues(newDisplayValues);
    if (onChange) {
      const timeString = get24hTime(newDisplayValues, showSeconds);
      if (timeStringIsValid(timeString)) {
        onChange(timeString);
      }
    }
  };

  const updateDisplayHours = (hours: string) => {
    updateDisplayValue({
      ...displayValues,
      hours: hours === '' || hours.length === 2 ? hours : displayValues.hours,
      displayHours: hours,
    });
  };

  const updateDisplayMinutes = (minutes: string) =>
    updateDisplayValue({
      ...displayValues,
      minutes: minutes === '' || minutes.length === 2 ? minutes : displayValues.minutes,
      displayMinutes: minutes,
    });

  const updateDisplaySeconds = (seconds: string) =>
    updateDisplayValue({
      ...displayValues,
      seconds: seconds === '' || seconds.length === 2 ? seconds : displayValues.seconds,
      displaySeconds: seconds,
    });

  const updateDisplayPeriod = (period: string) =>
    updateDisplayValue({
      ...displayValues,
      period,
    });

  const togglePeriod = (period: string) => (period === 'AM' ? 'PM' : 'AM');

  const handleHoursChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;

    if (value.length > 2) {
      focusRef(minuteInputRef);
      return;
    }

    if (value.length === 1) {
      if (!value.match('^[0-9]$')) {
        return;
      }

      if (value.match('^[2-9]$')) {
        focusRef(minuteInputRef);
        updateDisplayHours(`0${value}`);
        return;
      }
    } else if (value.length === 2) {
      if (!value.match('(^0[1-9]$)|(1[0-2]$)')) {
        return;
      } else {
        focusRef(minuteInputRef);
        updateDisplayHours(value);
        return;
      }
    }

    updateDisplayHours(value);
  };

  const handleHoursBlur = () => {
    if (displayValues.displayHours.length < 2) {
      if (displayValues.displayHours.match('^[1-9]$')) {
        updateDisplayHours(`0${displayValues.displayHours}`);
      } else {
        updateDisplayHours('');
      }
    }
  };

  const handleMinutesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;

    if (value.length > 2) {
      focusRef(showSeconds ? secondInputRef : periodInputRef);
      return;
    }

    if (value.length === 1) {
      if (!value.match('^[0-9]$')) {
        return;
      }

      if (value.match('^[6-9]$')) {
        focusRef(showSeconds ? secondInputRef : periodInputRef);
        updateDisplayMinutes(`0${value}`);
        return;
      }
    } else if (value.length === 2) {
      if (!value.match('(^0[1-9]$)|([1-5][0-9]$)|(00$)')) {
        return;
      } else {
        focusRef(showSeconds ? secondInputRef : periodInputRef);
        updateDisplayMinutes(value);
        return;
      }
    }

    updateDisplayMinutes(value);
  };

  const handleMinutesBlur = () => {
    if (displayValues.displayMinutes.length < 2) {
      if (displayValues.displayMinutes.match('^[0-9]$')) {
        updateDisplayMinutes(`0${displayValues.displayMinutes}`);
      } else {
        updateDisplayMinutes('');
      }
    }
  };

  const handleSecondsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;

    if (value.length > 2) {
      focusRef(periodInputRef);
      return;
    }

    if (value.length === 1) {
      if (!value.match('^[0-9]$')) {
        return;
      }

      if (value.match('^[6-9]$')) {
        focusRef(periodInputRef);
        updateDisplaySeconds(`0${value}`);
        return;
      }
    } else if (value.length === 2) {
      if (!value.match('(^0[1-9]$)|([1-5][0-9]$)|(00$)')) {
        return;
      } else {
        focusRef(periodInputRef);
        updateDisplaySeconds(value);
        return;
      }
    }

    updateDisplaySeconds(value);
  };

  const handleSecondsBlur = () => {
    if (displayValues.displaySeconds.length < 2) {
      if (displayValues.displaySeconds.match('^[0-9]$')) {
        updateDisplaySeconds(`0${displayValues.displaySeconds}`);
      } else {
        updateDisplaySeconds('');
      }
    }
  };

  const handlePeriodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;

    if (value.length > 2) {
      return;
    }

    if (value.length === 1) {
      value = `${value}M`;
    }
    if (value.length === 2) {
      if (!value.match('[AaPp][Mm]')) {
        return;
      }
    }

    updateDisplayPeriod(value.toUpperCase());
  };

  const handlePeriodBlur = () => {
    if (displayValues.period.length === 1) {
      if (displayValues.period.match('^[AaPp]$')) {
        updateDisplayPeriod(`${displayValues.period}M`);
      } else {
        updateDisplayPeriod('');
      }
    }
  };

  const increaseTime = (type: 'hours' | 'minutes' | 'seconds') => {
    let newTime = displayValues[type];

    if (!newTime) {
      newTime = type === 'hours' ? '01' : '00';
    } else {
      let newTimeInt = parseInt(newTime, 10);

      if (type !== 'hours' && newTimeInt === 59) {
        newTimeInt = 0;
      } else if (type === 'hours' && newTimeInt === 12) {
        newTimeInt = 1;
      } else {
        newTimeInt++;
      }

      newTime = padTimeValue(newTimeInt.toString());
    }

    if (type === 'hours') {
      updateDisplayHours(newTime);
    } else if (type === 'minutes') {
      updateDisplayMinutes(newTime);
    } else {
      updateDisplaySeconds(newTime);
    }
  };

  const decreaseTime = (type: 'hours' | 'minutes' | 'seconds') => {
    let newTime = displayValues[type];

    if (!newTime) {
      newTime = type === 'hours' ? '12' : '59';
    } else {
      let newTimeInt = parseInt(newTime, 10);

      if (type !== 'hours' && newTimeInt === 0) {
        newTimeInt = 59;
      } else if (type === 'hours' && newTimeInt === 1) {
        newTimeInt = 12;
      } else {
        newTimeInt--;
      }

      newTime = padTimeValue(newTimeInt.toString());
    }

    if (type === 'hours') {
      updateDisplayHours(newTime);
    } else if (type === 'minutes') {
      updateDisplayMinutes(newTime);
    } else {
      updateDisplaySeconds(newTime);
    }
  };

  const handleTimeInputFocus = (event: React.FocusEvent<HTMLInputElement>) => event.target.select();

  const onHourArrowKeyEvents = {
    onArrowKeyUp: () => increaseTime('hours'),
    onArrowKeyDown: () => decreaseTime('hours'),
    onArrowKeyLeft: () => focusRef(periodInputRef),
    onArrowKeyRight: () => focusRef(showMinutes ? minuteInputRef : periodInputRef),
  };

  const onMinuteArrowKeyEvents = {
    onArrowKeyUp: () => increaseTime('minutes'),
    onArrowKeyDown: () => decreaseTime('minutes'),
    onArrowKeyLeft: () => focusRef(hourInputRef),
    onArrowKeyRight: () => focusRef(showSeconds ? secondInputRef : periodInputRef),
  };

  const onSecondArrowKeyEvents = {
    onArrowKeyUp: () => increaseTime('seconds'),
    onArrowKeyDown: () => decreaseTime('seconds'),
    onArrowKeyLeft: () => focusRef(minuteInputRef),
    onArrowKeyRight: () => focusRef(periodInputRef),
  };

  const onPeriodArrowKeyEvents = {
    onArrowKeyUp: () => updateDisplayPeriod(togglePeriod(displayValues.period)),
    onArrowKeyDown: () => updateDisplayPeriod(togglePeriod(displayValues.period)),
    onArrowKeyLeft: () => focusRef(showSeconds ? secondInputRef : showMinutes ? minuteInputRef : hourInputRef),
    onArrowKeyRight: () => focusRef(hourInputRef),
  };

  const placeholders =
    placeholder && timeStringIsValid(placeholder)
      ? getTimeValuesFromString(placeholder)
      : {
          hours: '--',
          minutes: '--',
          seconds: '--',
          period: '--',
        };

  const hoursInput = (
    <input
      className={styles.value}
      type='text'
      value={displayValues.displayHours}
      onChange={handleHoursChange}
      onBlur={handleHoursBlur}
      onKeyDown={(event) => handleArrowKeyEvent(event, onHourArrowKeyEvents, true)}
      onFocus={handleTimeInputFocus}
      placeholder={placeholders.hours}
      ref={hourInputRef}
      disabled={disabled}
    />
  );

  const minutesInput = (
    <input
      className={styles.value}
      type='text'
      value={displayValues.displayMinutes}
      onChange={handleMinutesChange}
      onBlur={handleMinutesBlur}
      onKeyDown={(event) => handleArrowKeyEvent(event, onMinuteArrowKeyEvents, true)}
      onFocus={handleTimeInputFocus}
      placeholder={placeholders.minutes}
      ref={minuteInputRef}
      disabled={disabled}
    />
  );

  const secondsInput = (
    <input
      className={styles.value}
      type='text'
      value={displayValues.displaySeconds}
      onChange={handleSecondsChange}
      onBlur={handleSecondsBlur}
      onKeyDown={(event) => handleArrowKeyEvent(event, onSecondArrowKeyEvents, true)}
      onFocus={handleTimeInputFocus}
      placeholder={placeholders.seconds}
      ref={secondInputRef}
      disabled={disabled}
    />
  );

  const periodInput = (
    <input
      className={compositeStyles(['value', 'period'], styles)}
      type='text'
      value={displayValues.period}
      onChange={handlePeriodChange}
      onBlur={handlePeriodBlur}
      onKeyDown={(event) => handleArrowKeyEvent(event, onPeriodArrowKeyEvents, true)}
      onFocus={handleTimeInputFocus}
      placeholder={placeholders.period}
      ref={periodInputRef}
      disabled={disabled}
    />
  );

  const containerClassName = compositeStyles(
    ['container', { padSmall: containerPadding === 'small' }, { padMedium: containerPadding === 'medium' }],
    styles
  );

  const inputClassName = compositeStyles(
    ['input', { full: fullWidth }, { withError: hasError }, { disabled, darkBorder }, additionalInputStyles],
    styles
  );
  return (
    <div className={containerClassName}>
      <div className={inputClassName}>
        {hoursInput}
        {showMinutes ? <div className={styles.colon}>:</div> : null}
        {showMinutes ? minutesInput : null}
        {showSeconds ? <div className={styles.colon}>:</div> : null}
        {showSeconds ? secondsInput : null}
        <div className={styles.space} />
        {periodInput}
      </div>
    </div>
  );
};

export default TimePicker;
