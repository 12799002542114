// in-bound interfaces
export interface Execution {
  process: () => Promise<any>;
  storeReturnValue?: boolean;
  failureMessage?: string;
}

export interface ExecutionGroup {
  isCritical: boolean;
  failureMessage: string;
  includeIndividualMessages: boolean;
  executions: Execution[];
}

// out-bound interfaces
export interface ExecutionFailure {
  groupMessage: string;
  individualMessages: string[];
}

export interface CoordinatedExecutionResult<T = any> {
  isSuccessful: boolean;
  returnValue: T;
  failures: ExecutionFailure[];
}

// internal interface
export interface ExecutionResult {
  isSuccessful: boolean;
  returnValue: any | null;
}

/**
 * Coordinates the execution of multiple asynchronous functions and returns a single response that
 * summarizes the results.
 *
 * Executions can be lumped into groups for error messaging, so that a single message can be provided
 * for any failures that occur within a given group, while still optionally allowing for individual
 * messages to be provided for each execution within the group.
 *
 * Groups can be defined as either critical or non-critical. A failure within a critical group will
 * result in the overall result being a failure. A failure within a non-critical group will not set
 * the overall result to be a failure.
 *
 * Executions can be marked as storing the return value of the process. Only one return value is
 * stored, so if multiple processes are set to return values, only the last one will be available.
 */
const coordinateExecutions = async (groups: ExecutionGroup[]): Promise<CoordinatedExecutionResult> => {
  // flatten the executions into a simple array to use in the Promise.allSettled call
  const processes: (() => Promise<any>)[] = [];
  for (const group of groups) {
    group.executions
      .map((execution) => execution.process)
      .forEach((process) => {
        processes.push(process);
      });
  }

  // execute all processes and wait for all to be settled one way or another
  const results: ExecutionResult[] = await Promise.all(
    processes
      .map((process) => process())
      .map((promise: Promise<any>) =>
        promise
          .then((result: any) => ({
            isSuccessful: true,
            returnValue: result,
          }))
          .catch(() => ({
            isSuccessful: false,
            returnValue: null,
          }))
      )
  );

  // analyze the results for any failures of critical processes and log failure messages
  const coordinatedExecutionResult: CoordinatedExecutionResult = {
    isSuccessful: true,
    returnValue: null,
    failures: [],
  };
  let index = 0;
  for (const group of groups) {
    // prepare an execution failure for the group in case we need it
    const groupExecutionFailure: ExecutionFailure = {
      groupMessage: group.failureMessage,
      individualMessages: [],
    };
    let hasGroupFailures = false;

    for (const execution of group.executions) {
      const result = results[index];
      if (result.isSuccessful) {
        // all is good; store the return value if necessary and carry on
        if (execution.storeReturnValue) {
          coordinatedExecutionResult.returnValue = result.returnValue;
        }
      } else {
        // we've had a failure...
        if (group.isCritical) {
          // ...and it's a critical one, so the overall result is a failure
          coordinatedExecutionResult.isSuccessful = false;
        }

        if (!hasGroupFailures) {
          // this is the first failure in this group
          coordinatedExecutionResult.failures.push(groupExecutionFailure);
          hasGroupFailures = true;
        }
        // check if we need to log the individual failure message
        if (group.includeIndividualMessages) {
          groupExecutionFailure.individualMessages.push(execution.failureMessage || '');
        }
      }

      index++;
    }
  }

  return coordinatedExecutionResult;
};

export default coordinateExecutions;
