import { USERS_FORM_ACTIONS } from './types';
import {
  IUsersForm,
  getUsersCreateFormValidation,
  usersFormInitialState,
  usersFormCompanyRoleSelectorInitialState,
} from '../../initialStates/users/usersForm';
import { FieldValidation } from '../../../types/FieldValidation.interface';
import { isEmptyString } from '../../../utils/stringUtils';
import { isAdminEmailDomain } from '../../../utils/emailValidation';
import { ClientUserService } from '../../../services/usersService';
import { IClientUser, ILocationToShow } from '@ready/dashboardv2api.contracts';
import { CompanyRoleService } from '../../../services/companyRolesService';
import { UIClientUser, IRole, IUserRole } from '../../../services/types/companyUsers.type';
import { toastErrorState, set200Toast } from '../uiActions/responseStateActions';
import { AppDispatch } from '../../store';
import { IRoleAssignment } from '@ready/security.core';

export const initializeUserFormData = () => ({
  type: USERS_FORM_ACTIONS.INITIALIZE_USERS_FORM,
});

export const loadUserFormData = (companyId: string, userId: string) => async (dispatch: any): Promise<void> => {
  dispatch(setFormLoading(true));

  const user = await ClientUserService.getUser(companyId, userId);
  user && dispatch(setUserFormData(user));

  dispatch(setFormLoading(false));
};

export const setUserFormData = (clientUser: IClientUser) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch({
    type: USERS_FORM_ACTIONS.SET_USERS_FORM,
    payload: {
      ...clientUser,
      roles: clientUser.roles
        ? clientUser.roles.map((userRole: IRoleAssignment) => ({
            role: userRole.role,
            locations: userRole.locations,
            selector: usersFormCompanyRoleSelectorInitialState,
          }))
        : undefined,
      validation: getUsersCreateFormValidation(clientUser.roles ? clientUser.roles.length : 0),
    },
  });

  dispatch(setUsersFormCache(usersFormInitialState));
};

export const setUserEnabledThunk = (user: UIClientUser, enabled: boolean) => async (dispatch: AppDispatch) => {
  dispatch(setFormProcessing(true));

  try {
    await ClientUserService.setUserEnabled(user, enabled);
    dispatch(updateEnabled(enabled));
    dispatch(set200Toast());
  } catch (error) {
    //@ts-ignore
    dispatch(toastErrorState(error.status, error.message));
  } finally {
    dispatch(setFormProcessing(false));
  }
};

export const setUsersFormCache = (usersForm: IUsersForm) => ({
  type: USERS_FORM_ACTIONS.SET_USERS_FORM_CACHE,
  payload: usersForm,
});

export const resetUsersForm = (cachedUsersForm: IUsersForm) => ({
  type: USERS_FORM_ACTIONS.RESET_USERS_FORM,
  payload: cachedUsersForm,
});

export const updateFirstName = (firstName: string) => ({
  type: USERS_FORM_ACTIONS.UPDATE_FIRST_NAME,
  payload: firstName,
});

export const updateLastName = (lastName: string) => ({
  type: USERS_FORM_ACTIONS.UPDATE_LAST_NAME,
  payload: lastName,
});

export const updateEmail = (email: string) => ({
  type: USERS_FORM_ACTIONS.UPDATE_EMAIL_ADDRESS,
  payload: email,
});

export const addUserRole = () => ({
  type: USERS_FORM_ACTIONS.ADD_USER_ROLE,
});

export const removeUserRole = (index: number) => ({
  type: USERS_FORM_ACTIONS.REMOVE_USER_ROLE,
  payload: index,
});

export const updateRole = (index: number, role: IRole) => ({
  type: USERS_FORM_ACTIONS.UPDATE_ROLE,
  payload: { index, role },
});

export const updateEnabled = (enabled: boolean) => ({
  type: USERS_FORM_ACTIONS.UPDATE_ENABLED,
  payload: enabled,
});

export const addUserRoleLocation = (location: ILocationToShow, index: number) => ({
  type: USERS_FORM_ACTIONS.ADD_USER_ROLE_LOCATION,
  payload: { location: { id: location._id, name: location.name }, index },
});

export const removeUserRoleLocation = (locationId: string, index: number) => ({
  type: USERS_FORM_ACTIONS.REMOVE_USER_ROLE_LOCATION,
  payload: { locationId, index },
});

export const validateUserForm = (usersForm: IUsersForm) => (dispatch: any): boolean => {
  const { firstName, lastName, email } = usersForm;

  const isFirstNameEmpty = isEmptyString(firstName);
  const isLastNameEmpty = isEmptyString(lastName);
  const isEmailEmpty = isEmptyString(email);
  const isAdminEmailDomainEmail = isAdminEmailDomain(email);

  // Filter out any user role permissions that were added but not filled out. We will discard these.
  const completedUserRoles = usersForm.roles
    ? usersForm.roles.filter((userRole) => !!userRole.role || (!!userRole.locations && userRole.locations.length > 0))
    : usersForm.roles;

  const rolesValidation: FieldValidation[] = [];
  if (completedUserRoles) {
    completedUserRoles.forEach((role: IUserRole) => {
      const isRoleEmpty = !role.role && !!role.locations && role.locations.length > 0;
      rolesValidation.push({
        hasError: isRoleEmpty,
        error: isRoleEmpty ? 'Role is required.' : '',
      });
    });
  }

  const isValid = !(
    isFirstNameEmpty ||
    isLastNameEmpty ||
    isEmailEmpty ||
    isAdminEmailDomainEmail ||
    rolesValidation.find((role) => role.hasError)
  );

  const validationPayload = {
    roles: completedUserRoles,
    validation: {
      usersForm: {
        firstName: {
          hasError: isFirstNameEmpty,
          error: isFirstNameEmpty ? 'First name is required.' : '',
        },
        lastName: {
          hasError: isLastNameEmpty,
          error: isLastNameEmpty ? 'Last name is required.' : '',
        },
        email: {
          hasError: isEmailEmpty || isAdminEmailDomainEmail,
          error: isEmailEmpty ? 'Email address is required.' : !isAdminEmailDomainEmail ? 'Invalid email address.' : '',
        },
        roles: rolesValidation,
      },
    },
  };

  dispatch({
    type: USERS_FORM_ACTIONS.VALIDATE_USER_FORM,
    payload: validationPayload,
  });

  return isValid;
};

export const submitUserForm = (data: IUsersForm, companyId: string, userId: string) => async (
  dispatch: any
): Promise<UIClientUser | void> => {
  dispatch(setFormProcessing(true));

  let response: UIClientUser;
  try {
    // This is a new user
    if (userId === '') {
      response = await ClientUserService.createUser(data, companyId);
    }

    // This is an existing user
    else {
      response = await ClientUserService.updateUser(data);
    }

    dispatch(setUsersFormCache(data));
    dispatch(setFormProcessing(false));
    dispatch(set200Toast());

    // Returns the created/updated user
    return response;
  } catch (err) {
    dispatch(setFormProcessing(false));
    dispatch(toastErrorState(err.status, err.message));
  }
};

export const setFormProcessing = (isProcessing: boolean) => ({
  type: USERS_FORM_ACTIONS.SET_USERS_FORM_PROCESSING,
  payload: isProcessing,
});

export const setFormLoading = (isLoading: boolean) => ({
  type: USERS_FORM_ACTIONS.SET_USERS_FORM_LOADING,
  payload: isLoading,
});

export interface RoleListValue {
  id: string;
  name: string;
}

export const loadRoles = (companyId: string, instance: number, query: string, limit?: number) => async (
  dispatch: any
) => {
  dispatch({
    type: USERS_FORM_ACTIONS.LOAD_AVAILABLE_ROLES_BEGIN,
    payload: instance,
  });
  try {
    const companyRoles = await CompanyRoleService.getCompanyRoles(companyId, query, limit);
    const roles: RoleListValue[] = companyRoles.map((companyRole) => ({
      id: companyRole._id,
      name: companyRole.name,
    }));
    dispatch({
      type: USERS_FORM_ACTIONS.LOAD_AVAILABLE_ROLES_SUCCESS,
      payload: { instance, roles },
    });
  } catch (err) {
    dispatch(toastErrorState(err.status, err.message));
  }
};
