import React from 'react';
import Button from '../Button/Button';

export interface ModalFooterProps {
  primaryLabel: string;
  primaryActionHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
  secondaryLabel?: string;
  secondaryActionHandler?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  loading?: boolean;
  disablePrimary?: boolean;
  disableSecondary?: boolean;
  additionalStyles?: string;
}

const ModalFooter = (props: ModalFooterProps) => {
  const {
    primaryLabel,
    primaryActionHandler,
    secondaryLabel,
    secondaryActionHandler,
    loading = false,
    disablePrimary = false,
    disableSecondary = false,
    additionalStyles = '',
  } = props;

  return (
    <div className={`modal__footer ${additionalStyles}`}>
      {secondaryLabel && (
        <Button
          variant='secondary'
          size='large'
          label={secondaryLabel}
          onClick={secondaryActionHandler}
          disabled={loading || disableSecondary}
        />
      )}
      <Button
        variant='primary'
        size='large'
        label={primaryLabel}
        onClick={primaryActionHandler}
        loading={loading}
        disabled={disablePrimary}
      />
    </div>
  );
};

export default ModalFooter;
