import React from 'react';

export interface IconProps {
  name: string;
}

const Icon = (props: IconProps) => {
  const { name } = props;
  let icon;

  try {
    icon = require(`../../styles/assets/${name}.svg`);
  } catch {
    try {
      icon = require(`../../styles/assets/${name}.png`);
    } catch {
      icon = require(`../../styles/assets/Transparent.gif`);
    }
  }

  return (
    <>
      <img className={`icon-image`} src={icon} alt={name} />
    </>
  );
};

export default Icon;
