import React from 'react';
import LayoutContent from '../../components/AppLayout/LayoutContent';
import EmptyResult from '../../components/EmptyResult/EmptyResult';

const Unauthorized = () => {
  const notFoundTitle = 'You Shall Not Pass!';
  const notFoundParagraph =
    'We’re sorry, but you do not have permission to view this content. If you think you should have access, please contact your company’s admin and ask them to update your user permissions.';

  return (
    <LayoutContent title='Access Denied' containerType='within'>
      <div className='page-with-empty-result'>
        <EmptyResult title={notFoundTitle} paragraph={notFoundParagraph} />
      </div>
    </LayoutContent>
  );
};

export default Unauthorized;
