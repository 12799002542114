export enum OrderStatusLabel {
  IN_PROGRESS = 'In Progress',
  READY = 'Ready',
  PENDING = 'Pending',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
}

export enum OrderStatusLabelColor {
  MAGENTA = 'magenta',
  GREEN = 'green',
  GRAY = 'gray',
  BLACK = 'black',
}

export enum OrderSection {
  OFFSET = 'offset',
  OVERDUE = 'overdue',
  UPNEXT = 'upnext',
  COMPLETE = 'complete',
  CANCELLED = 'cancelled',
  DINE_IN = 'dineIn',
}

export enum OrderDay {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}
