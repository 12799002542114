import ActionHeader, { Action } from 'components/ActionHeader/ActionHeader';
import EmptyResult from 'components/EmptyResult/EmptyResult';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { SharedMenuItemsLayout } from 'sharedMenuItems/SharedMenuItemsLayout';
import { SharedMenuItemsTabId } from 'sharedMenuItems/SharedMenuItemsTabs';
import { ContextParams } from 'types/ContextParams.interface';
import { MenuConfigPanel } from './components/MenuConfigPanel/MenuConfigPanel';
import { EditMenuConfigModal } from './components/MenuConfigFormModal/MenuConfigFormModal';
import styles from './MenuConfigsListPage.module.scss';
import { IMenuGroupUI, IMenuSummaryUI } from './redux/types';
import {
  createSharedMenuConfigItemGalleryThunk,
  createSharedMenuThunk,
  deleteSharedMenuConfigThunk,
  getAssignedLocationsForMenuConfigThunk,
  getSharedMenuConfigsThunk,
  saveSharedMenuConfigThunk,
  updateAssignedLocationsForMenuGroupThunk,
  updateSharedItemGallerySummaryThunk,
  updateSharedMenuSummaryThunk,
} from './redux/thunks';
import { SharedMenuItemsRoutes } from 'sharedMenuItems/Router';
import { deleteSharedItemGalleryThunk, deleteSharedMenuThunk } from 'sharedMenuItems/pages/menu/redux/thunks';
import { ISaveNameAndDescriptionModalFields } from 'components/nameAndDescriptionModal/NameAndDescriptionModal';
import { LoadAssignedLocations } from 'sharedMenuItems/components/ManageAssignedLocationsModal/ManageAssignedLocationsModal';
import { ILocationAssignments } from '@ready/menu.core';
import { Icon } from 'components/Icon/TextIcon';
import Notification from '../../../../components/Notification/Notification';

export const MenuConfigsListPage = () => {
  const { contextId: companyId } = useParams<ContextParams>();
  const dispatch = useAppDispatch();

  const { loading, menuConfigs, saving, selectedConfigId, totalAssignableLocations } = useAppSelector(
    (state) => state.sharedMenuItems.menuConfigs
  );
  const { push } = useHistory();

  useEffect(() => {
    dispatch(getSharedMenuConfigsThunk(companyId));
  }, [companyId, dispatch]);

  const [showNewConfigModal, setShowNewConfigModal] = useState<boolean>(false);

  const actionButtons: Action[] = [
    {
      label: '+ New Configuration',
      variant: 'primary',
      onClick: () => setShowNewConfigModal(true),
      loading: saving,
    },
  ];

  const saveMenuConfig = async (menuGroup: IMenuGroupUI) => {
    await dispatch(saveSharedMenuConfigThunk({ companyId: companyId, templateMenuGroup: menuGroup }));
    dispatch(getSharedMenuConfigsThunk(companyId));
  };

  const onCreateMenu = async (sharedMenuFormFields: ISaveNameAndDescriptionModalFields) => {
    if (selectedConfigId) {
      await dispatch(
        createSharedMenuThunk({
          companyId,
          groupId: selectedConfigId,
          formFields: sharedMenuFormFields,
        })
      );

      dispatch(getSharedMenuConfigsThunk(companyId));
    }
  };

  const onCreateMenuConfigItemGallery = async (itemGalleryFormFields: ISaveNameAndDescriptionModalFields) => {
    if (selectedConfigId) {
      await dispatch(
        createSharedMenuConfigItemGalleryThunk({
          companyId,
          groupId: selectedConfigId,
          formFields: itemGalleryFormFields,
        })
      );

      dispatch(getSharedMenuConfigsThunk(companyId));
    }
  };

  const onDeleteMenuConfig = async (id: string) => {
    await dispatch(deleteSharedMenuConfigThunk({ companyId, id }));
    dispatch(getSharedMenuConfigsThunk(companyId));
  };

  const onDeleteMenuOrGallery = async (menuOrGalleryId: string, isGallery: boolean) => {
    if (isGallery) {
      await dispatch(deleteSharedItemGalleryThunk({ companyId, id: menuOrGalleryId }));
    } else {
      await dispatch(deleteSharedMenuThunk({ companyId, id: menuOrGalleryId }));
    }

    dispatch(getSharedMenuConfigsThunk(companyId));
  };

  const onUpdateMenuOrItemGallerySummary = async (
    id: string,
    modalFields: ISaveNameAndDescriptionModalFields,
    isGallery?: boolean
  ) => {
    if (!!isGallery) {
      await dispatch(
        updateSharedItemGallerySummaryThunk({
          companyId,
          menuOrGalleryId: id,
          menuFormFields: modalFields,
        })
      );
    } else {
      await dispatch(
        updateSharedMenuSummaryThunk({
          companyId,
          menuOrGalleryId: id,
          menuFormFields: modalFields,
        })
      );
    }

    dispatch(getSharedMenuConfigsThunk(companyId));
  };

  const saveAssignedLocations = async (menuConfigId: string, assignments: ILocationAssignments) => {
    const { allLocations, ...withoutAllLocations } = assignments;
    await dispatch(
      updateAssignedLocationsForMenuGroupThunk({
        companyId,
        menuConfigId: menuConfigId,
        assignments: withoutAllLocations,
      })
    );
    dispatch(getSharedMenuConfigsThunk(companyId));
  };

  return (
    <>
      <SharedMenuItemsLayout tab={SharedMenuItemsTabId.Menus}>
        {/* Todo: remove notification after few months */}
        <NewFeatureNotification />

        <ActionHeader text='Menu Configurations' actionButtons={actionButtons} />
        <div className={styles.container}>
          {loading && <LoadingSpinner />}

          {!loading && menuConfigs.length > 0 ? (
            menuConfigs.map((mc) => (
              <MenuConfigPanel
                key={mc._id}
                menuConfig={mc}
                onRowClick={(menuOrGalleryId: string, isGallery: boolean): void =>
                  isGallery
                    ? push(SharedMenuItemsRoutes.getSharedMenusItemGalleryRoute(companyId, mc._id, menuOrGalleryId))
                    : push(SharedMenuItemsRoutes.getSharedMenusMenuRoute(companyId, mc._id, menuOrGalleryId))
                }
                onDeleteMenuConfig={onDeleteMenuConfig}
                onEditNameSave={saveMenuConfig}
                onDeleteMenuOrGallery={onDeleteMenuOrGallery}
                onReorderMenusSave={(sortedItems: IMenuSummaryUI[]) =>
                  saveMenuConfig({ ...mc, menusAndGalleries: sortedItems })
                }
                onUpdateMenuOrItemGallerySummary={onUpdateMenuOrItemGallerySummary}
                onCreateMenu={onCreateMenu}
                onCreateMenuConfigItemGallery={onCreateMenuConfigItemGallery}
                loadLocationAssignments={({ searchTerm, isLoadMore, page }: LoadAssignedLocations) => {
                  dispatch(
                    getAssignedLocationsForMenuConfigThunk({
                      companyId,
                      isLoadMore,
                      page,
                      menuConfigId: mc._id,
                      locationSearchTerm: searchTerm,
                    })
                  );
                }}
                onSaveAssignedLocations={(assignments: ILocationAssignments) =>
                  saveAssignedLocations(mc._id, assignments)
                }
                totalAssignableLocations={totalAssignableLocations}
              />
            ))
          ) : (
            <EmptyResult
              title='Create Your First Menu Configuration'
              paragraph='If you manage multiple brands, or use two different menus for dine in and take out, you can create different menu configurations for each of them. Configurations can be assigned to one or more locations. Changes made to each configuration will populate to every location they are assigned to.'
            />
          )}
        </div>
      </SharedMenuItemsLayout>

      {showNewConfigModal && <EditMenuConfigModal setShowModal={setShowNewConfigModal} onSave={saveMenuConfig} />}
    </>
  );
};

const NewFeatureNotification = () => {
  return (
    <div className={styles.notification}>
      <Notification type='warning'>
        <p>
          <i className={Icon.Star} />
          Welcome to Ready’s NEW Menu Builder! You can now create, share and centrally manage menus across locations.
          Any previously created menus can be found under Location Menus.{' '}
          <a
            className={styles.notificationLink}
            target='_blank'
            href=' https://help-center.readytopay.com/menu-builder-quick-guide'
          >
            Click Here
          </a>{' '}
          to visit our help files and learn more.
        </p>
      </Notification>
    </div>
  );
};
