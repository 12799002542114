import React, { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import LocationsListPage from './components/LocationsListPage';

const Router: FC<{}> = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/`} component={LocationsListPage} />
    </Switch>
  );
};

export default Router;
