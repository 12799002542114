import React, { useCallback, useState } from 'react';
import LayoutContent from '../../components/AppLayout/LayoutContent';
import InfoCardList from '../../components/InfoCard/InfoCardList';
import InfoCard from '../../components/InfoCard/InfoCard';
import { useParams, Link } from 'react-router-dom';
import { ConnectedProps } from 'react-redux';
import { AppState } from '../../redux/initialStates/AppState';
import { connect } from 'react-redux';
import {
  Section,
  ToolbarContent,
  ToolbarContentTab,
  ContactColumn,
  StackableItemsAndTotalColumns,
  StackableTimeAndStatusColumns,
  EmptySearchResults,
  OrdersOrderToggle,
  OrderSettingsDialog,
  DineInOrders,
} from './components';
import styles from './UpcomingOrdersListPage.module.scss';
import {
  setOrderStatus,
  sortOrdersInPlace,
  updateOrderList,
  setLocationListUrlQuery,
} from '../../redux/actions/orders/orderListActions';
import { OrderSection } from '../../enums/orders.enum';
import useResponsiveBreakpoint, { ResponsiveBreakpoint } from '../../hooks/useResponsiveBreakpoint';
import { useSearchParams } from '../../hooks/useSearchParams';
import EmptyResult from '../../components/EmptyResult/EmptyResult';
import filterOrders from '../../utils/orderUtils/filterOrders';
import LoadingPage from '../LoadingPage';
import { OrderFulfillmentStatus } from '@ready/dashboardv2api.contracts';
import { RouteStatus } from './components/ToolbarContent';
import LocationSelectorLink from '../../components/AppLayout/LocationSelectorLink';
import { ContextParams } from '../../types/ContextParams.interface';
import { useAudio, AudioSource } from '../../hooks';
import TextIcon, { Icon } from '../../components/Icon/TextIcon';
import IconButton from '../../components/IconButton/IconButton';
import { setOrderSettingsDialogOpen } from '../../redux/actions/orders/locationOrderSettingsActions';
import RoundIcon from '../../components/RoundIcon/RoundIcon';
import { getOrderExperienceType } from '../../utils/orderUtils';
import config from "../../config";

interface widths {
  contactColumnWidth: number;
  itemsColumnWidth: number;
  totalColumnWidth: number;
  timeColumnWidth: number;
  statusColumnWidth?: number;
}

const getWidths = (breakpoint: ResponsiveBreakpoint): widths => {
  switch (breakpoint) {
    case ResponsiveBreakpoint.Mobile:
      return {
        contactColumnWidth: 100,
        itemsColumnWidth: 0,
        totalColumnWidth: 0,
        timeColumnWidth: 0,
      };

    case ResponsiveBreakpoint.Tablet:
      return {
        contactColumnWidth: 35,
        itemsColumnWidth: 15,
        totalColumnWidth: 15,
        timeColumnWidth: 0,
      };

    case ResponsiveBreakpoint.Desktop:
      return {
        contactColumnWidth: 30,
        itemsColumnWidth: 12,
        totalColumnWidth: 12,
        timeColumnWidth: 12,
        statusColumnWidth: 24,
      };
  }
};

const getTotal = (priceExcludingTip: number, tip: number) => priceExcludingTip + tip;

interface PageParams extends ContextParams {
  day: string;
}

const UpcomingOrdersListPage = (props: ReduxProps) => {
  const {
    upNextOrders,
    offsetOrders,
    overdueOrders,
    loadingOrders,
    loadingPage,
    setOrderStatus,
    sortOrdersInPlace,
    updateOrderList,
    completedOrdersCount,
    upNextOrdersCount,
    offsetOrdersCount,
    overdueOrdersCount,
    cancelledOrdersCount,
    dineInOrdersCount,
    dineInOrders,
    locationDetails,
    userSession,
    setLocationListUrlQuery,
    setOrderSettingsDialogOpen,
  } = props;

  const { play } = useAudio(AudioSource.ReadyRiff);

  const { contextId, locationId, day } = useParams<PageParams>();
  const { breakpoint, isMobile, isTablet } = useResponsiveBreakpoint();
  const { query } = useSearchParams();
  const [  timestamp, setTimestamp  ] = useState(0);

  const searchTerm = decodeURIComponent(query || '');
  const filteredUpNextOrders = filterOrders(upNextOrders, searchTerm);
  const filteredOffsetOrders = filterOrders(offsetOrders, searchTerm);
  const filteredOverdueOrders = filterOrders(overdueOrders, searchTerm);
  const filteredDineInOrders = filterOrders(dineInOrders, searchTerm);

  const handleNewOrderSignal = useCallback(() => {
      try {
        console.log('New order  signal received');
        play();
      } catch (err) {
        console.error('Could not play audio for new order successfully invoiced event');
      }
      setTimeout(function () {
        updateOrderList(contextId, locationId, day);
      }, 2000);
    }, [contextId, day, locationId, play, updateOrderList]);

  const timestampRef = React.useRef(timestamp);

  // Subscribe to signals on an interval
  React.useEffect(() => {
    const channelName = `orderInvoiceCreated-${locationId}`
    const host = config.readyApiLegacyEndpoint.substring(0, config.readyApiLegacyEndpoint.indexOf('/v2')); // remove /v2 from the end

    const fetchSignals = async () => {
      let url = `${host}/signals?streams=${channelName}`;
      if (timestampRef.current) {
        url += `&since=${timestampRef.current}`;
      }

      console.log('fetching signals from: ', url);
      const response = await fetch(url);
      if(response.ok){
        const data = await response.json();

        if(data.signals.length > 0){
          handleNewOrderSignal();
        }

        timestampRef.current = data.current;
        setTimestamp(data.current);
      }
    };

    fetchSignals();

    const intervalID = setInterval(fetchSignals, 60 * 1000);

    return () => {
      clearInterval(intervalID);
    };
  }, [locationId, handleNewOrderSignal]);

  // Sort orders periodically
  React.useEffect(() => {
    const intervalID = setInterval(() => {
      sortOrdersInPlace();
    }, 30000);

    return () => {
      clearInterval(intervalID);
    };
  }, [sortOrdersInPlace]);

  React.useEffect(() => {
    setLocationListUrlQuery({ day, status: RouteStatus.Upcoming, query: query || '' });
  }, [setLocationListUrlQuery, day, query]);

  const locationName = locationDetails ? locationDetails.name : '';
  const upcomingOrdersCount = upNextOrdersCount + offsetOrdersCount + overdueOrdersCount + dineInOrdersCount;
  const showEmptyResult = upcomingOrdersCount === 0;
  const widths = getWidths(breakpoint);

  const handleOnClickOrderSettings = React.useCallback(() => {
    setOrderSettingsDialogOpen(true);
  }, [setOrderSettingsDialogOpen]);

  return (
    <LayoutContent
      title='Orders'
      containerType='within'
      showSubHeaderDivider={false}
      darkBackground
      loadingPage={loadingPage}
      titleControls={<LocationSelectorLink text={locationName} to={`/companies/${contextId}/orders`} />}
      subHeader={<OrdersOrderToggle />}
      additionalSubHeaderControls={
        <IconButton onClick={handleOnClickOrderSettings}>
          <TextIcon icon={Icon.Cog} additionalStyles={styles.settingsIcon} />
        </IconButton>
      }
    >
      <OrderSettingsDialog />
      {loadingOrders ? (
        <LoadingPage />
      ) : (
        <div className={styles.listContainer}>
          <div className={styles.toolbarContainer}>
            <ToolbarContent
              upcomingOrders={upcomingOrdersCount}
              completeOrders={completedOrdersCount}
              cancelledOrders={cancelledOrdersCount}
              companyId={contextId}
              day={day}
              locationId={locationId}
              selectedTab={ToolbarContentTab.Upcoming}
            />
          </div>
          {filteredDineInOrders && (
            <InfoCardList>
              {filteredDineInOrders.length === 0 && dineInOrdersCount > 0 ? (
                <EmptySearchResults />
              ) : (
                <DineInOrders filteredDineInOrders={filteredDineInOrders} />
              )}
            </InfoCardList>
          )}
          {filteredOverdueOrders && (
            <Section title='Overdue' sectionName='overdue' sectionHidden={overdueOrdersCount === 0}>
              <InfoCardList>
                {filteredOverdueOrders.length === 0 && overdueOrdersCount > 0 ? (
                  <EmptySearchResults />
                ) : (
                  filteredOverdueOrders.map((order) => {
                    const handleOverdueOrderStatusChange = (newStatus: OrderFulfillmentStatus) => {
                      setOrderStatus(
                        contextId,
                        locationId,
                        newStatus,
                        OrderSection.OVERDUE,
                        order.orderId,
                        order.invoiceId
                      );
                    };

                    const orderExperienceType = getOrderExperienceType(order.tableInfo?.orderExperienceType);
                    const orderTip = order.tip ? order.tip : 0;

                    return (
                      <Link
                        to={`/companies/${contextId}/locations/${locationId}/orders/${order.orderId}`}
                        key={order.orderId}
                        onClick={(event) => {
                          if (order.processing) {
                            event.preventDefault();
                          }
                        }}
                      >
                        <InfoCard>
                          <RoundIcon icon={orderExperienceType.icon} additionalStyles={styles.icon} />
                          <ContactColumn
                            customerDetails={order.identityInfo}
                            width={widths.contactColumnWidth}
                            newOrder={order.newOrder}
                            additionalStyles={styles.column}
                          />
                          <StackableItemsAndTotalColumns
                            items={order.itemCount}
                            itemsColumnWidth={widths.itemsColumnWidth}
                            total={getTotal(order.priceExcludingTip, orderTip)}
                            subtotal={order.priceExcludingTip}
                            tip={orderTip}
                            totalColumnWidth={widths.totalColumnWidth}
                            stacked={isTablet}
                            hidden={isMobile}
                            additionalStyles={styles.column}
                            orderExperienceType={order.tableInfo?.orderExperienceType}
                            hasInvoice={!!order.invoiceId}
                          />
                          <StackableTimeAndStatusColumns
                            time={new Date(order.pickupTime)}
                            timeColumnWidth={widths.timeColumnWidth}
                            isOverdue={order.status === OrderFulfillmentStatus.Pending}
                            stacked={isMobile || isTablet}
                            additionalStyles={styles.column}
                            handleOrderStatusChange={handleOverdueOrderStatusChange}
                            orderStatusColumnWidth={widths.statusColumnWidth}
                            isProcessing={order.processing}
                            orderStatus={order.status}
                          />
                        </InfoCard>
                      </Link>
                    );
                  })
                )}
              </InfoCardList>
            </Section>
          )}

          {filteredUpNextOrders && (
            <Section title='Up Next' sectionName='upNext' sectionHidden={upNextOrdersCount === 0}>
              <InfoCardList>
                {filteredUpNextOrders.length === 0 && upNextOrdersCount > 0 ? (
                  <EmptySearchResults />
                ) : (
                  filteredUpNextOrders.map((order) => {
                    const handleUpNextOrderStatusChange = (orderStatus: OrderFulfillmentStatus) => {
                      setOrderStatus(
                        contextId,
                        locationId,
                        orderStatus,
                        OrderSection.UPNEXT,
                        order.orderId,
                        order.invoiceId
                      );
                    };
                    const orderExperienceType = getOrderExperienceType(order.tableInfo?.orderExperienceType);
                    const orderTip = order.tip ? order.tip : 0;

                    return (
                      <Link
                        to={`/companies/${contextId}/locations/${locationId}/orders/${order.orderId}`}
                        key={order.orderId}
                        onClick={(event) => {
                          if (order.processing) {
                            event.preventDefault();
                          }
                        }}
                      >
                        <InfoCard>
                          <RoundIcon icon={orderExperienceType.icon} additionalStyles={styles.icon} />
                          <ContactColumn
                            customerDetails={order.identityInfo}
                            width={widths.contactColumnWidth}
                            newOrder={order.newOrder}
                            additionalStyles={styles.column}
                          />
                          <StackableItemsAndTotalColumns
                            items={order.itemCount}
                            itemsColumnWidth={widths.itemsColumnWidth}
                            total={getTotal(order.priceExcludingTip, orderTip)}
                            subtotal={order.priceExcludingTip}
                            tip={orderTip}
                            totalColumnWidth={widths.totalColumnWidth}
                            stacked={isTablet}
                            hidden={isMobile}
                            additionalStyles={styles.column}
                            orderExperienceType={order.tableInfo?.orderExperienceType}
                            hasInvoice={!!order.invoiceId}
                          />
                          <StackableTimeAndStatusColumns
                            time={new Date(order.pickupTime)}
                            timeColumnWidth={widths.timeColumnWidth}
                            isStartSoon={order.status === OrderFulfillmentStatus.Pending}
                            stacked={isMobile || isTablet}
                            additionalStyles={styles.column}
                            handleOrderStatusChange={handleUpNextOrderStatusChange}
                            orderStatusColumnWidth={widths.statusColumnWidth}
                            isProcessing={order.processing}
                            orderStatus={order.status}
                          />
                        </InfoCard>
                      </Link>
                    );
                  })
                )}
              </InfoCardList>
            </Section>
          )}

          {filteredOffsetOrders && (
            <Section title='30+ minutes' sectionName='offset' sectionHidden={offsetOrdersCount === 0}>
              <InfoCardList>
                {filteredOffsetOrders.length === 0 && offsetOrdersCount > 0 ? (
                  <EmptySearchResults />
                ) : (
                  filteredOffsetOrders.map((order) => {
                    const handleOffsetOrderStatusChange = (orderStatus: OrderFulfillmentStatus) => {
                      setOrderStatus(
                        contextId,
                        locationId,
                        orderStatus,
                        OrderSection.OFFSET,
                        order.orderId,
                        order.invoiceId
                      );
                    };
                    const orderExperienceType = getOrderExperienceType(order.tableInfo?.orderExperienceType);
                    const orderTip = order.tip ? order.tip : 0;

                    return (
                      <Link
                        to={`/companies/${contextId}/locations/${locationId}/orders/${order.orderId}`}
                        key={order.orderId}
                        onClick={(event) => {
                          if (order.processing) {
                            event.preventDefault();
                          }
                        }}
                      >
                        <InfoCard>
                          <RoundIcon icon={orderExperienceType.icon} additionalStyles={styles.icon} />
                          <ContactColumn
                            customerDetails={order.identityInfo}
                            width={widths.contactColumnWidth}
                            newOrder={order.newOrder}
                            additionalStyles={styles.column}
                          />
                          <StackableItemsAndTotalColumns
                            items={order.itemCount}
                            itemsColumnWidth={widths.itemsColumnWidth}
                            total={getTotal(order.priceExcludingTip, orderTip)}
                            subtotal={order.priceExcludingTip}
                            tip={orderTip}
                            totalColumnWidth={widths.totalColumnWidth}
                            stacked={isTablet}
                            hidden={isMobile}
                            additionalStyles={styles.column}
                            orderExperienceType={order.tableInfo?.orderExperienceType}
                            hasInvoice={!!order.invoiceId}
                          />
                          <StackableTimeAndStatusColumns
                            time={new Date(order.pickupTime)}
                            timeColumnWidth={widths.timeColumnWidth}
                            stacked={isMobile || isTablet}
                            additionalStyles={styles.column}
                            handleOrderStatusChange={handleOffsetOrderStatusChange}
                            orderStatusColumnWidth={widths.statusColumnWidth}
                            isProcessing={order.processing}
                            orderStatus={order.status}
                          />
                        </InfoCard>
                      </Link>
                    );
                  })
                )}
              </InfoCardList>
            </Section>
          )}

          {showEmptyResult && (
            <EmptyResult
              title='No Upcoming Orders'
              paragraph="You're all caught up for now. New orders will appear here as they come in."
            />
          )}
        </div>
      )}
    </LayoutContent>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    userSession: state.session.userSession,
    orders: state.orders.orderList.orders,
    upNextOrders: state.orders.orderList.upNextOrders,
    offsetOrders: state.orders.orderList.offsetOrders,
    overdueOrders: state.orders.orderList.overdueOrders,
    dineInOrders: state.orders.orderList.dineInOrders,
    loadingOrders: state.orders.orderList.loadingOrders,
    loadingPage: state.orders.orderList.locationDetailsLoading,
    completedOrdersCount: state.orders.orderList.completedOrdersCount,
    upNextOrdersCount: state.orders.orderList.upNextOrdersCount,
    offsetOrdersCount: state.orders.orderList.offsetOrdersCount,
    overdueOrdersCount: state.orders.orderList.overdueOrdersCount,
    cancelledOrdersCount: state.orders.orderList.cancelledOrdersCount,
    dineInOrdersCount: state.orders.orderList.dineInOrdersCount,
    locationDetails: state.orders.orderList.locationDetails,
  };
};

const actionCreators = {
  setOrderStatus,
  sortOrdersInPlace,
  updateOrderList,
  setLocationListUrlQuery,
  setOrderSettingsDialogOpen,
};

const connector = connect(mapStateToProps, actionCreators);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(UpcomingOrdersListPage);
