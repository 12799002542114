import {
  IClientUser,
  IClientUserCreateRequest,
  PaginationResponse,
  UserStatusFilter,
} from '@ready/dashboardv2api.contracts';
import { IRoleAssignment } from '@ready/security.core';
import { IUsersForm } from 'redux/initialStates/users/usersForm';
import config from '../config';
import { objectToQueryParamsString } from '../utils/urlUtils/objectToQueryParamsString';
import { UIClientUser, IUserRole } from './types/companyUsers.type';
import executeApiAsync from './utils/executeApiAsync';

export class ClientUserService {
  public static async resetUserPassword(email: string): Promise<boolean> {
    const url = `${config.readyDashboardApiEndpoint}/users/reset-password`;
    const body = { email };
    try {
      const response = await fetch(url, {
        method: 'post',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.ok;
    } catch (err) {
      return false;
    }
  }

  public static async getUsers(
    companyId: string,
    query: string = '',
    page: number = 1,
    status: UserStatusFilter = UserStatusFilter.All
  ): Promise<PaginationResponse<IClientUser>> {
    const queryString = objectToQueryParamsString({
      searchTerm: query,
      pageNumber: page.toString(),
      status: status === UserStatusFilter.All ? null : status,
    });

    return await executeApiAsync<PaginationResponse<IClientUser>>(
      `${config.readyDashboardApiEndpoint}/companies/${companyId}/users?${queryString}`
    );
  }

  public static async getUser(companyId: string, userId?: string): Promise<IClientUser | null> {
    const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/users/${userId}`;

    try {
      return await executeApiAsync<IClientUser>(url);
    } catch (err) {
      return null;
    }
  }

  public static async updateUser(data: IUsersForm): Promise<any | void> {
    const user = this.mapFormUserToUpdateUser(data, data.companyId);

    return executeApiAsync(`${config.readyDashboardApiEndpoint}/companies/${data.companyId}/users`, {
      method: 'PUT',
      body: JSON.stringify(user),
    });
  }

  public static async createUser(data: IUsersForm, companyId: string): Promise<any | void> {
    const user = this.mapFormUserToCreateUser(data, companyId);

    return executeApiAsync(`${config.readyDashboardApiEndpoint}/companies/${companyId}/users`, {
      method: 'POST',
      body: JSON.stringify(user),
    });
  }

  public static async setUserEnabled(user: UIClientUser, enabled: boolean) {
    return executeApiAsync(`${config.readyDashboardApiEndpoint}/companies/${user.companyId}/users/enabled`, {
      method: 'PUT',
      body: JSON.stringify({
        principalId: user.uid,
        enabled: enabled,
      }),
    });
  }

  private static mapFormUserToUpdateUser(data: IUsersForm, companyId: string): IClientUser {
    return {
      ...this.mapFormUserToCreateUser(data, companyId),
      id: data.id,
      enabled: data.enabled,
      uid: data.uid,
    };
  }

  private static mapFormUserToCreateUser(data: IUsersForm, companyId: string): IClientUserCreateRequest {
    return {
      companyId: companyId,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      roles: this.mapRoles(data.roles ?? []),
    };
  }

  private static mapRoles(roles: IUserRole[]): IRoleAssignment[] {
    const completedRoles = roles ? roles.filter((userRole) => !!userRole.role) : [];

    const roleAssignments = completedRoles.map<IRoleAssignment>((userRole: IUserRole) => {
      return {
        role: { id: userRole.role!.id, name: userRole.role!.name },
        locations: userRole.locations?.map((x) => {
          return { id: x.id, name: x.name };
        }),
      };
    });

    return roleAssignments;
  }
}
