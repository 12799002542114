import getBackLinkUrl from '../../utils/urlUtils/getBackLinkUrl';
import { Routes } from '../MenuBuilderRouter';
import MenuItemsView from '../types/MenuItemsView.enum';
import ItemsAndGroupsStockStatus from '../types/ItemsAndGroupsStockStatus.enum';

export interface Args {
  companyId: string;
  locationId: string;
  menuConfigId?: string;
  menuId?: string;
  itemGroupId?: string;
  modGroupId?: string;
  menuItemId?: string;
  forcedModId?: string;
  view?: MenuItemsView;
  page?: number;
  query?: string;
  stockStatus?: ItemsAndGroupsStockStatus;
}

const getBackLinkForMenuItem = ({
  companyId,
  locationId,
  menuConfigId,
  menuId,
  itemGroupId,
  modGroupId,
  menuItemId,
  forcedModId,
  view,
  page,
  query,
  stockStatus,
}: Args): string => {
  // if there's a menu ID and an item group ID, we arrived here from a menu section and then an item group...
  if (!!menuId && !!itemGroupId) {
    // ...unless the menu ID is "item-gallery", indicating we arrived here from an item gallery and then an item group
    if (menuId === 'item-gallery') {
      return Routes.getLocationItemGalleryItemGroupPage(companyId, locationId, itemGroupId, menuConfigId);
    }
    return Routes.getMenuSectionItemGroupPage(companyId, locationId, menuId, itemGroupId, menuConfigId);
  }

  // if there's only a menu ID, we arrived here from a menu section...
  if (!!menuId) {
    // ...unless the menu ID is "item-gallery", indicating we arrived here from an item gallery
    if (menuId === 'item-gallery') {
      return Routes.getMenusPage(companyId, locationId, menuConfigId);
    }
    return Routes.getMenuPage(companyId, locationId, menuId, menuConfigId);
  }

  // if there's only an item group ID, we arrived here from an item group
  if (itemGroupId && itemGroupId !== 'new') {
    return Routes.getItemGroupPage(companyId, locationId, itemGroupId);
  } else if (itemGroupId === 'new') {
    return Routes.getItemGroupsPage(companyId, locationId);
  }

  // if there's only a mod group ID, we arrived here from a mod group
  if (!!modGroupId) {
    return Routes.getModifierGroupPage(companyId, locationId, modGroupId);
  }
  // if there's forced mod id, we arrived from forced mod
  if (forcedModId && menuItemId) {
    return Routes.getMenuItemPage(companyId, locationId, menuItemId);
  }
  // if all else fails, we go back to the list page, remembering the filters while we're at it
  return getBackLinkUrl({
    baseUrl: Routes.getItemsAndModsPage(companyId, locationId, view),
    searchParams: { query: query || '', stockStatus: stockStatus || '', page: String(page) },
  });
};

export default getBackLinkForMenuItem;
