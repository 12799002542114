import React from 'react';

import { useClassNames } from '../../../utils/cssUtils/useClassNames';
import styles from './MenuItemImage.module.scss';

export interface MenuItemImageProps {
  type: 'item' | 'option';
  name: string;
  outOfStock?: boolean;
  url?: string;
}

const MenuItemImage = (props: MenuItemImageProps): JSX.Element => {
  const { type, name, outOfStock, url } = props;

  const imageClassNames = useClassNames(
    {
      itemImage: type === 'item',
      modifierImage: type === 'option',
      placeholder: !url,
    },
    styles
  );

  const outOfStockClassNames = useClassNames(
    {
      outOfStock: outOfStock,
      itemOutOfStock: type === 'item',
      modifierOutOfStock: type === 'option',
    },
    styles
  );

  return (
    <div>
      {!!url ? (
        <img src={url} alt={name} className={imageClassNames} />
      ) : (
        <div className={imageClassNames}>
          {type === 'item' ? <i className='icon-dashboard-icons_camera'></i> : <>MOD</>}
        </div>
      )}
      {outOfStock === true && (
        <div className={outOfStockClassNames}>
          <div className={styles.outOfStockIndicator}>Sold Out</div>
        </div>
      )}
    </div>
  );
};

export default MenuItemImage;
