import { IPagination, PaginationResponse } from '@ready/dashboardv2api.contracts';
import { ITransactionToShow } from '@ready/dashboardv2api.contracts';

export interface ITransactionListUrlQuery {
  queryString: string;
  page: string;
}

export interface ITransactionList {
  loading: boolean;
  transactions: PaginationResponse<ITransactionToShow> | [];
  exporting: boolean;
  exportId: string;
  checkingExport: boolean;
  exportDownloaded: boolean;
  error: boolean;
  errorMessage: string;
  pagination: IPagination;
  urlQuery: ITransactionListUrlQuery;
}

const pagination: IPagination = {
  start: 0,
  length: 0,
  limit: 0,
  total: 0,
};

export const transactionsList = {
  loading: false,
  transactions: [],
  exporting: false,
  exportId: '',
  checkingExport: false,
  exportDownloaded: false,
  error: false,
  errorMessage: '',
  pagination,
  urlQuery: {
    queryString: '',
    page: '',
  },
} as ITransactionList;
