import React, { ChangeEvent } from 'react';
import styles from './OrderAhead.module.scss';
// components
import { FormControl } from '../../../../../components/Form';
import FormSubHeader from '../../../../../components/Form/FormSubHeader';
import SelectFilter, { Option } from '../../../../../components/SelectFilter/SelectFilter';
import TextField from '../../../../../components/TextField/TextField';
import TextInput from '../../../../../components/TextInput/TextInput';
import Toggle from '../../../../../components/Toggle/Toggle';
import PickUpHours from './PickUpHours';
import GuestInfoFieldGroup from './GuestInfoFieldGroup';
// utils
import { toNumber } from '../../../../../utils/numberUtils/toNumber';
import { LOCATION_SETTINGS_ACTIONS } from '../../../../redux/LocationSettingsActions';
import { LIST_OPTIONS } from '../../../../../utils/selectListUtils/listOptions';
import {
  changeAcceptOrderAheadForOption,
  changeOrderTimeOption,
  changeThrottleDurationOption,
} from '../../../../../redux/actions/uiActions/uiActions';
// types
import {
  ILocationOrderAhead,
  IAcceptOrdersFor,
  OrderTimeViaEnum,
  GuestInformationField,
  IPickupHours,
} from '@ready/dashboardv2api.contracts';
import { IUIDropDown } from '../../../../../redux/initialStates/ui/dropDownState';
import { DropDownListKeyEnum } from '../../../../../utils/selectListUtils/listOptions.interface';
import { IOrderAheadValidation } from '../../../../redux/PanelFeaturesState';
import { IUpdateLocationParam } from '../../../LocationsEditPage';

// redux
import { useDispatch } from 'react-redux';

export const pickUpDayLabels = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const posItemGuestInfoText =
  'If the method for sending guest information is set to “Send info as POS Item”, a menu item must be specified here.';

const ordersPerMinute = 'Specify the max # of orders per minute you would like to accept.';

const futureDaysLimitText = 'Limit the number of days in advance a guest can order';

export interface Props {
  isProcessing: boolean;
  updateLocationParam: IUpdateLocationParam;
  orderAhead: ILocationOrderAhead;
  dropDown: IUIDropDown;
  validation: IOrderAheadValidation;
}

const LocationPanelOrderAhead = (props: Props) => {
  const dispatch = useDispatch();
  const { isProcessing, updateLocationParam, orderAhead, dropDown, validation } = props;
  const { orderTime, acceptOrders, throttleDuration } = dropDown;

  const showMenuItem = (): boolean => {
    const selectedOption = orderTime.selectedOption?.value ? orderTime.selectedOption.value.toString() : '';
    return selectedOption.toLowerCase() === OrderTimeViaEnum.PosItem.toLowerCase();
  };

  const showFutureDaysLimit = (): boolean => {
    const selectedFutureDaysLimitOption = acceptOrders.selectedOption?.value ? acceptOrders.selectedOption.value : '';
    return selectedFutureDaysLimitOption === IAcceptOrdersFor.FutureDays;
  };

  const handleSelectChange = (newSelection: Option, type: string) => {
    switch (type) {
      case DropDownListKeyEnum.OrderTime:
        updateLocationParam(newSelection.value, LOCATION_SETTINGS_ACTIONS.UPDATE_ORDER_TIME_VIA);
        dispatch(changeOrderTimeOption(newSelection));
        break;
      case DropDownListKeyEnum.OrderThrottle:
        updateLocationParam(
          toNumber(newSelection.value as string),
          LOCATION_SETTINGS_ACTIONS.UPDATE_ORDER_THROTTLING_TIME
        );
        dispatch(changeThrottleDurationOption(newSelection));
        break;
      case DropDownListKeyEnum.AcceptOrders:
        updateLocationParam(newSelection.value, LOCATION_SETTINGS_ACTIONS.UPDATE_ORDER_AHEAD_ACCEPT_FOR);
        if (newSelection.value === IAcceptOrdersFor.SameDayOnly) {
          updateLocationParam(null, LOCATION_SETTINGS_ACTIONS.UPDATE_ORDER_AHEAD_FUTURE_DAYS_LIMIT);
        }
        dispatch(changeAcceptOrderAheadForOption(newSelection));
        break;
      default:
        console.error(`Cannot change selection`);
        break;
    }
  };

  const guestInfoFields = orderAhead.guestInfoFields as GuestInformationField[];
  const guestInfoPosItemIdErr = validation.orderAhead.guestInfoPosItemId;
  const orderThrottleAmountErr = validation.orderAhead.orderThrottling.amount;

  return (
    <div>
      <FormSubHeader label='Order Ahead' />
      <FormControl label='Status'>
        <Toggle
          checked={orderAhead.enableOrderAhead}
          onChange={(checked: boolean) => {
            updateLocationParam(!orderAhead.enableOrderAhead, LOCATION_SETTINGS_ACTIONS.TOGGLE_ORDER_AHEAD_STATUS);
          }}
          loading={isProcessing}
        />
      </FormControl>
      <FormControl
        label='Accept Orders for'
        errorMessage={validation.orderAhead.acceptOrdersFor.error}
        withError={validation.orderAhead.acceptOrdersFor.hasError}
      >
        <div className='control--stacked'>
          <SelectFilter
            isSearchable
            placeholder='Select option'
            options={LIST_OPTIONS.acceptOrders}
            value={acceptOrders.selectedOption}
            onChange={(option: Option) => {
              handleSelectChange(option, DropDownListKeyEnum.AcceptOrders);
            }}
            loading={isProcessing}
          />
        </div>
        {showFutureDaysLimit() && (
          <>
            <div className='control--stacked'>
              <p>
                {futureDaysLimitText} <i>{'(optional).'}</i>
              </p>
            </div>
            <div className={`control--stacked ${styles.futureDaysLimit}`}>
              <TextInput
                type='number'
                placeholder='e.g. 7'
                value={orderAhead.futureDaysLimit || ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  updateLocationParam(
                    parseInt(e.target.value),
                    LOCATION_SETTINGS_ACTIONS.UPDATE_ORDER_AHEAD_FUTURE_DAYS_LIMIT
                  );
                }}
                withError={validation.orderAhead.futureDaysLimit.hasError}
                loading={isProcessing}
              />
            </div>
          </>
        )}
      </FormControl>
      <FormControl
        label='Submit Order Time Via'
        errorMessage={validation.orderAhead.submitOrderTimeVia.error}
        withError={validation.orderAhead.submitOrderTimeVia.hasError}
      >
        <div className='control--stacked'>
          <SelectFilter
            isSearchable
            placeholder='Select option'
            options={LIST_OPTIONS.orderTime}
            value={orderTime.selectedOption}
            onChange={(option: Option) => {
              handleSelectChange(option, DropDownListKeyEnum.OrderTime);
            }}
            loading={isProcessing}
          />
        </div>
        <>
          {showMenuItem() && (
            <div className='control--stacked'>
              <TextInput
                placeholder='Type to select a menu item to use'
                value={orderAhead.submitOrderTimeVia?.posItemId || ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  updateLocationParam(e.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_ORDER_TIME_POS_ITEM_ID);
                }}
                withError={validation.orderAhead.submitOrderTimeVia.hasError}
                loading={isProcessing}
              />
            </div>
          )}
        </>
      </FormControl>
      <FormControl label='Pick Up Hours'>
        <></>
      </FormControl>
      {pickUpDayLabels.map((label: string, i: number) => {
        const day = label.toLowerCase();
        return (
          <PickUpHours
            key={i}
            updateLocationParam={updateLocationParam}
            pickUpTimes={orderAhead.pickUpHours[day as keyof IPickupHours]}
            label={label}
            day={day}
            validation={validation}
            isProcessing={isProcessing}
          ></PickUpHours>
        );
      })}
      <GuestInfoFieldGroup
        guestInfoFields={guestInfoFields}
        updateLocationParam={updateLocationParam}
        validation={validation}
        isProcessing={isProcessing}
      ></GuestInfoFieldGroup>
      <FormControl
        label='POS Item from Guest Information'
        withError={guestInfoPosItemIdErr.hasError}
        errorMessage={guestInfoPosItemIdErr.error}
      >
        <div className='control--stacked'>
          <TextField value={posItemGuestInfoText} />
        </div>
        <div className='control--stacked'>
          <TextInput
            placeholder='Enter External ID of menu item'
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              updateLocationParam(e.target.value, LOCATION_SETTINGS_ACTIONS.UPDATE_GUEST_INFO_POS_ITEM_ID);
            }}
            value={orderAhead.guestInfoPosItemId}
            withError={guestInfoPosItemIdErr.hasError}
            loading={isProcessing}
          />
        </div>
      </FormControl>
      <FormControl
        label='Order Throttling'
        withError={orderThrottleAmountErr.hasError}
        errorMessage={orderThrottleAmountErr.error}
      >
        <div className='control--stacked'>
          <Toggle
            checked={orderAhead.orderThrottling?.enabled || false}
            onChange={(checked: boolean) => {
              updateLocationParam(checked, LOCATION_SETTINGS_ACTIONS.TOGGLE_ORDER_THROTTLING);
            }}
            loading={isProcessing}
          />
        </div>
        <div className='control--stacked'>
          <TextField value={ordersPerMinute} />
        </div>
        <div className='control--stacked'>
          <TextInput
            type='number'
            placeholder='5'
            value={orderAhead.orderThrottling?.amount}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              updateLocationParam(toNumber(e.target.value), LOCATION_SETTINGS_ACTIONS.UPDATE_ORDER_THROTTLING_AMOUNT);
            }}
            additionalStyles={styles.numberInput}
            withError={orderThrottleAmountErr.hasError}
            loading={isProcessing}
          />
          <span className='form__control__separator'>/</span>
          <SelectFilter
            placeholder='Time'
            options={LIST_OPTIONS.throttleDuration}
            value={throttleDuration.selectedOption}
            onChange={(option: Option) => {
              handleSelectChange(option, DropDownListKeyEnum.OrderThrottle);
            }}
            loading={isProcessing}
          />
        </div>
      </FormControl>
    </div>
  );
};

export default LocationPanelOrderAhead;
