import React from 'react';
import TableRow from './TableRow';
import TableCell from './TableCell';
import Skeleton from '../Skeleton/Skeleton';

export interface TableRowsSkeletonProps {
  colsWidth: number[];
  rows?: number;
}

const renderCells = (colsWidth: number[]): JSX.Element[] => {
  return colsWidth.map((value: number, index: number) => {
    return (
      <TableCell key={index} width={value}>
        <Skeleton />
      </TableCell>
    );
  });
};

const renderRows = (rows: number, colsWidth: number[]): JSX.Element[] => {
  const cells = renderCells(colsWidth);
  const rowsArr = [];
  for (let i = 0; i < rows; i++) {
    rowsArr.push(
      <TableRow key={i} skeleton>
        {cells}
      </TableRow>
    );
  }
  return rowsArr;
};

const TableRowsSkeleton = (props: TableRowsSkeletonProps): JSX.Element => {
  const { colsWidth, rows = 1 } = props;

  return <>{renderRows(rows, colsWidth)}</>;
};

export default TableRowsSkeleton;
