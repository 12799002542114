import TextIcon, { Icon } from 'components/Icon/TextIcon';
import React from 'react';
import styles from './BooleanValue.module.scss';
import classNames from 'classnames';

interface BooleanValueProps {
  booleanValue: boolean;
  label?: string;
  className?: string;
}

export const BooleanValue = ({ booleanValue, label = '', className }: BooleanValueProps) => {
  return (
    <span className={classNames([styles.container, className])}>
      {booleanValue ? (
        <TextIcon icon={Icon.Checkmark} additionalStyles={styles.check} />
      ) : (
        <TextIcon icon={Icon.Remove} additionalStyles={styles.remove} />
      )}
      <span className={booleanValue ? '' : styles.uncheckedLabel}>{label}</span>
    </span>
  );
};
