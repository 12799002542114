import { ITemplateItemGallery, ITemplateItemGalleryRequest } from '@ready/menu.core';
import config from 'config';
import executeApiAsync from 'services/utils/executeApiAsync';

export const getTemplateMenuGroupItemGallery = async (
  companyId: string,
  itemGalleryId: string
): Promise<ITemplateItemGallery> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/itemgalleries/${itemGalleryId}`;
  return await executeApiAsync<ITemplateItemGallery>(url, {
    method: 'GET',
  });
};

export const updateTemplateMenuGroupItemGallery = async (
  companyId: string,
  itemGalleryRequest: ITemplateItemGalleryRequest
): Promise<ITemplateItemGallery> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/itemgalleries`;
  return await executeApiAsync<ITemplateItemGallery>(url, {
    method: 'PUT',
    body: JSON.stringify(itemGalleryRequest),
  });
};
