import { DetailElement } from 'components/DetailLayout';
import { TextValue } from 'components/Value';
import React from 'react';
import { useAppSelector } from 'redux/store';

export const ViewPanel = () => {
  const {
    orderMessagingCache: {
      alcoholPolicy,
      menuAnnouncementsMessage,
      checkoutAnnouncementMessage,
      pickupInstructions,
      pickupLocationName,
      orderConfirmedMessage,
      disruptionMessage,
    },
  } = useAppSelector((state) => state.location.orderMessagingConfig);
  return (
    <>
      <DetailElement label='Alcohol Policy'>
        <TextValue mode='none' value={alcoholPolicy ?? ''} />
      </DetailElement>

      <DetailElement label='Ordering Offline Message'>
        <TextValue mode='none' value={disruptionMessage ?? ''} />
      </DetailElement>

      <DetailElement label='Menu Announcements'>
        <TextValue mode='none' value={menuAnnouncementsMessage ?? ''} />
      </DetailElement>

      <DetailElement label='Pick up Instructions'>
        <TextValue mode='none' value={pickupInstructions ?? ''} />
      </DetailElement>

      <DetailElement label='Pick up Location Name'>
        <TextValue mode='none' value={pickupLocationName ?? ''} />
      </DetailElement>

      <DetailElement label='Checkout Announcements'>
        <TextValue mode='none' value={checkoutAnnouncementMessage ?? ''} />
      </DetailElement>

      <DetailElement label='Order Confirmation'>
        <TextValue mode='none' value={orderConfirmedMessage ?? ''} />
      </DetailElement>
    </>
  );
};
