import React from 'react';
import classnames from 'classnames';
import Header from './Header';
import LoadingPage from '../../pages/LoadingPage';
import styles from './app-layout.module.scss';

export interface LayoutContentProps {
  children?: React.ReactNode;
  pageId?: string;
  title: string;
  headerControls?: JSX.Element | JSX.Element[] | null;
  subHeader?: JSX.Element | (JSX.Element | null)[] | null;
  additionalSubHeaderControls?: React.ReactNode;
  showSubHeaderDivider?: boolean;
  toolbarContent?: JSX.Element | null;
  titleControls?: JSX.Element | null;
  containerType?: 'full' | 'panel' | 'within';
  loadingContent?: boolean;
  loadingPage?: boolean;
  darkBackground?: boolean;
  scrollToolbarForMobile?: boolean;
}

const LayoutContent = (props: LayoutContentProps) => {
  const {
    children,
    pageId,
    title,
    headerControls,
    subHeader,
    additionalSubHeaderControls,
    showSubHeaderDivider,
    titleControls,
    toolbarContent,
    containerType,
    scrollToolbarForMobile,
    loadingContent = false,
    loadingPage = false,
    darkBackground = false,
  } = props;

  const className = classnames(styles.layoutContent, {
    [styles.darkBackground]: darkBackground,
    [styles.scrollToolbarForMobile]: scrollToolbarForMobile,
  });

  return (
    <div className={className} id={pageId}>
      {loadingPage ? (
        <LoadingPage />
      ) : (
        <>
          <Header
            title={title}
            controls={headerControls}
            titleControls={titleControls}
            subHeader={subHeader}
            additionalSubHeaderControls={additionalSubHeaderControls}
            showSubHeaderDivider={showSubHeaderDivider}
          />
          <MainContent
            toolbarContent={toolbarContent}
            containerType={containerType}
            loadingContent={loadingContent}
            darkBackground={darkBackground}
          >
            {children}
          </MainContent>
        </>
      )}
    </div>
  );
};

interface MainContentProps {
  children?: React.ReactNode;
  toolbarContent?: JSX.Element | null;
  containerType?: 'full' | 'panel' | 'within';
  loadingContent?: boolean;
  darkBackground?: boolean;
}

const MainContent = (props: MainContentProps) => {
  const { children, toolbarContent, containerType, loadingContent = false, darkBackground = false } = props;

  let mainContentClassName = '';
  let toolbarClassName = styles.toolbar;

  if (containerType === 'full') {
    mainContentClassName = styles.mainContentFull;
  } else if (containerType === 'within') {
    mainContentClassName = styles.mainContentWithin;
  } else if (containerType === 'panel') {
    mainContentClassName = styles.mainContentPanel;
  }

  if (darkBackground) {
    mainContentClassName += ` ${styles.darkBackground}`;
    toolbarClassName += ` ${styles.darkBackground}`;
  }

  if (loadingContent) {
    return <LoadingPage />;
  }

  return (
    <>
      {toolbarContent && <div className={toolbarClassName}>{toolbarContent}</div>}
      <div className={styles.mainContentWrapper}>
        <div className={mainContentClassName}>{children}</div>
      </div>
    </>
  );
};

export default LayoutContent;
