import React from 'react';
import { IBadge, ITag } from '@ready/menu.core';
import { useSelector, useDispatch } from 'react-redux';
import TableLayoutContent from '../../companyLocations/components/TableLayoutContent';
import LayoutContent from '../../components/AppLayout/LayoutContent';
import Button from '../../components/Button/Button';
import EmptyResult, { EmptyResultSize } from '../../components/EmptyResult/EmptyResult';
import InfoCardList from '../../components/InfoCard/InfoCardList';
import InfoCardColumnSkeleton from '../../components/InfoCard/InfoCardColumnSkeleton';
import { useResponsiveBreakpoint } from '../../hooks';
import styles from './TagsBadgesListPage.module.scss';
import TagFormModal from './TagFormModal';
import BadgeFormModal from './BadgeFormModal';
import {
  fetchTagsBadgesList,
  setTagCreateMode,
  updateTagShortName,
  saveTag,
  updateUniqueTagName,
  updateTagName,
  setTagEditMode,
  showDeleteTagModal,
  deleteTag,
  setBadgeCreateMode,
  setBadgeEditMode,
  requestDeleteBadge,
  cancelRequestDeleteBadge,
  cancelRequestDeleteTag,
  updateUniqueShortCode,
  saveBadge,
  deleteBadge,
} from '../redux/TagsBadgesListActions';
import { selectTagsBadgesState } from '../redux/TagBadgeSelectors';
import TagItemLine from './TagItemLine';
import BadgeItem from './BadgeItem';
import { ContextParams } from '../../types/ContextParams.interface';
import { useParams } from 'react-router-dom';
import { DeleteConfirmationModal } from '../../components/Modal';
import { selectPermissions } from '../../redux/selectors/sessionSelectors/sessionSelectors';
import {
  MenuTagsBadgesResourceActions,
  PrincipalPermissions,
  ResourceType,
  SecurityScope,
  Verifier,
} from '@ready/security.core';
import OverflowButton from '../../components/OverflowButton/OverflowButton';

const TagsBadgesListPage = (): JSX.Element => {
  const emptyResultBadgesParagraph = 'Create badges like “Popular” or “New” to highlight specific menu items.';

  const emptyResultTagsParagraph =
    'Create tags like “Gluten Free” or “Vegetarian” to inform guests about their menu choices.';

  const { isMobile } = useResponsiveBreakpoint();

  const dispatch = useDispatch();
  const {
    tags,
    badges,
    tagForm,
    deleteRequested,
    tagToDelete,
    deleteProcessing,
    badgeForm,
    deleteBadgeRequested,
    badgeToDelete,
    loading,
  } = useSelector(selectTagsBadgesState);
  const { permissionsList } = useSelector(selectPermissions);

  // permission check
  const canManageAll = Verifier.check(
    new PrincipalPermissions(permissionsList),
    SecurityScope.company,
    ResourceType.menuTagsBadges,
    MenuTagsBadgesResourceActions.all
  );

  const { contextId: companyId } = useParams<ContextParams>();

  const onSetTagCreateMode = (): void => {
    dispatch(setTagCreateMode(true));
  };

  const onSetBadgeCreateMode = (): void => {
    dispatch(setBadgeCreateMode(true));
  };

  const handleSetShowModal = (showModal: boolean) => {
    dispatch(setTagCreateMode(showModal));
  };

  const handleSetShowModalBadge = (showModal: boolean) => {
    dispatch(setBadgeCreateMode(showModal));
  };

  const handleTagShortNameUpdate = (tagShortCode: string, isUnique: boolean): void => {
    dispatch(updateTagShortName(tagShortCode));
    if (!isUnique) dispatch(updateUniqueShortCode());
  };

  const handleTagNameUpdate = (tagName: string, isUnique: boolean): void => {
    dispatch(updateTagName(tagName));
    if (!isUnique) dispatch(updateUniqueTagName());
  };

  const onSaveTag = (): void => {
    if (tagForm.validation.tagName.hasError) return;
    dispatch(saveTag(companyId, tagForm.tag));
  };

  const onSaveBadge = (): void => {
    if (badgeForm.validation.badgeName.hasError) return;
    dispatch(saveBadge(companyId, badgeForm.badge));
  };

  const onEditTagClick = (tag: ITag) => {
    if (tagForm.validation.tagName.hasError) return;
    dispatch(setTagEditMode(true, tag));
  };

  const onDeleteTagClick = (tag: ITag) => {
    dispatch(showDeleteTagModal(true, tag));
  };

  const onConfirmDeleteTag = () => {
    if (tagToDelete) {
      dispatch(deleteTag(companyId, tagToDelete));
    }
  };

  const onConfirmDeleteBadge = () => {
    if (badgeToDelete) {
      dispatch(deleteBadge(companyId, badgeToDelete));
    }
  };

  const onEditBadge = (badge: IBadge) => {
    dispatch(setBadgeEditMode(true, badge));
  };

  const onDeleteBadge = (badge: IBadge) => {
    dispatch(requestDeleteBadge(badge));
  };

  const onCancelDeleteBadge = () => {
    dispatch(cancelRequestDeleteBadge());
  };

  const onCancelDeleteTag = () => {
    dispatch(cancelRequestDeleteTag());
  };

  React.useEffect(() => {
    dispatch(fetchTagsBadgesList(companyId));
  }, [dispatch, companyId]);

  const createNewControl = () => {
    if (!canManageAll) {
      return null;
    }

    return isMobile ? (
      <OverflowButton
        options={[
          {
            label: 'New Badge',
            onClick: onSetBadgeCreateMode,
            primary: true,
          },
        ]}
      />
    ) : (
      <Button label='+ New Badge' onClick={onSetBadgeCreateMode} variant='primary' />
    );
  };

  const createNewTagControl = () => {
    if (!canManageAll) {
      return null;
    }

    return isMobile ? (
      <OverflowButton
        options={[
          {
            label: 'New Tag',
            onClick: onSetTagCreateMode,
            primary: true,
          },
        ]}
      />
    ) : (
      <Button label='+ New Tag' onClick={onSetTagCreateMode} variant='primary' />
    );
  };

  const viewListPage = () => {
    return (
      <TableLayoutContent title='Tags' headerControls={createNewTagControl()}>
        <InfoCardList>
          {loading ? (
            <InfoCardColumnSkeleton instances={10} rowsWithinInstance={2} />
          ) : tags.length === 0 ? (
            <EmptyResult
              title={''}
              paragraph={emptyResultTagsParagraph}
              size={EmptyResultSize.Small}
              additionalStyles={styles.withNoBorder}
            />
          ) : (
            tags.map((item: ITag, index: number) => (
              <TagItemLine
                key={index}
                item={item}
                onEditClick={onEditTagClick}
                canEdit={canManageAll}
                onDeleteClick={onDeleteTagClick}
              />
            ))
          )}
        </InfoCardList>
      </TableLayoutContent>
    );
  };

  const viewBadgesList = () => {
    return (
      <TableLayoutContent title='Badges' headerControls={createNewControl()}>
        <InfoCardList>
          {loading ? (
            <InfoCardColumnSkeleton instances={10} rowsWithinInstance={2} />
          ) : badges.length === 0 ? (
            <EmptyResult
              title={''}
              paragraph={emptyResultBadgesParagraph}
              size={EmptyResultSize.Small}
              additionalStyles={styles.withNoBorder}
            />
          ) : (
            badges.map((item: IBadge, index: number) => (
              <BadgeItem
                key={index}
                item={item}
                canEdit={canManageAll}
                onEditBadge={onEditBadge}
                onDeleteBadge={onDeleteBadge}
              />
            ))
          )}
        </InfoCardList>
      </TableLayoutContent>
    );
  };

  return (
    <>
      {tagForm.editing && (
        <TagFormModal
          setShowModal={handleSetShowModal}
          onSave={onSaveTag}
          tagForm={tagForm}
          header={tagForm.tag._id ? 'Edit Tag' : 'New Tag'}
          saveLabel={tagForm.tag._id ? 'Save Changes' : 'Create Tag'}
          onChangeShortName={handleTagShortNameUpdate}
          tagList={tags}
          onChangeUniqueTagName={handleTagNameUpdate}
        />
      )}

      {deleteRequested && tagToDelete && (
        <DeleteConfirmationModal
          setShowModal={onCancelDeleteTag}
          item='Tag'
          itemName={tagToDelete.name}
          loading={deleteProcessing}
          handleDelete={onConfirmDeleteTag}
        />
      )}

      {badgeForm.editing && (
        <BadgeFormModal
          badgeForm={badgeForm}
          onSave={onSaveBadge}
          setShowModal={handleSetShowModalBadge}
          header={badgeForm.badge._id ? 'Edit Badge' : 'New Badge'}
          saveLabel={badgeForm.badge._id ? 'Save Changes' : 'Create Badge'}
        />
      )}

      {deleteBadgeRequested && badgeToDelete && (
        <DeleteConfirmationModal
          setShowModal={onCancelDeleteBadge}
          item='Badge'
          itemName={badgeToDelete.name}
          loading={deleteProcessing}
          handleDelete={onConfirmDeleteBadge}
        />
      )}

      <LayoutContent title='Tags & Badges' containerType='within'>
        {viewBadgesList()}
        {viewListPage()}
      </LayoutContent>
    </>
  );
};

export default TagsBadgesListPage;
