import { IPagination, PaginationResponse, ILocationToShow } from '@ready/dashboardv2api.contracts';

export interface ILocationsList {
  loading: boolean;
  locations: PaginationResponse<ILocationToShow> | [];
  error: boolean;
  query: string;
  page: string;
  pagination: IPagination;
  exporting: boolean;
}

const pagination: IPagination = {
  start: 0,
  length: 0,
  limit: 0,
  total: 0,
};

export const locationsList = {
  loading: false,
  locations: [],
  error: false,
  query: '',
  page: '',
  pagination,
  exporting: false,
} as ILocationsList;
