import React from 'react';
import styles from './DuplicateMenuItemModal.module.scss';
import Modal from '../../../components/Modal/Modal';
import ModalHeader from '../../../components/Modal/ModalHeader';
import ModalBody from '../../../components/Modal/ModalBody';
import ModalFooter from '../../../components/Modal/ModalFooter';
import { FormControl } from '../../../components/Form';
import TextInput from '../../../components/TextInput/TextInput';
import { useForm } from 'react-hook-form';

interface DuplicateMenuItemModalProps {
  processing: boolean;
  setVisible: (visible: boolean) => void;
  onDisplayNameChange?: (displayName: string) => void;
  duplicateMenuItem: (displayName: string) => void;
  cancelChanges: (visible: boolean) => void;
  headerLabel: string;
  placeholder: string;
}

const DuplicateMenuItemModal = (props: DuplicateMenuItemModalProps): JSX.Element => {
  const { processing, setVisible, duplicateMenuItem, cancelChanges, headerLabel, placeholder } = props;

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<{ displayName: string }>();

  const lowerCaseTypeLabel = headerLabel.toLowerCase();
  const header = `Duplicate ${headerLabel}`;

  return (
    <Modal setShowModal={setVisible} loading={processing}>
      <ModalHeader headerLabel={header} setShowModal={setVisible} />
      <ModalBody>
        <div className={styles.label}>
          <FormControl
            label={`Enter a Display Name for this new ${lowerCaseTypeLabel} *`}
            withError={errors.displayName !== undefined}
            errorMessage={errors.displayName?.message}
            disabled={processing}
          >
            <TextInput
              {...register('displayName', { required: 'Required field' })}
              placeholder={placeholder}
              loading={processing}
              withError={errors.displayName !== undefined}
            />
          </FormControl>
        </div>
      </ModalBody>
      <ModalFooter
        primaryLabel={header}
        primaryActionHandler={handleSubmit((fields) => {
          duplicateMenuItem(fields.displayName);
        })}
        secondaryLabel='Cancel'
        secondaryActionHandler={() => cancelChanges(false)}
        loading={processing}
      />
    </Modal>
  );
};

export default DuplicateMenuItemModal;
