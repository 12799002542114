import { PaginationResponse } from '@ready/dashboardv2api.contracts';
import { ITemplateModifierGroup, ITemplateModifierGroupRequest } from '@ready/menu.core';
import { IPagedResponse } from '@ready/table.core';
import config from '../../../config';
import executeApiAsync from 'services/utils/executeApiAsync';
import toPaginationResponse from 'services/utils/toPaginationResponse';
import { objectToQueryParamsString } from 'utils/urlUtils/objectToQueryParamsString';
import { mapModifierGroupToRequest } from './modifierGroup.mappers';

export const getSharedModifierGroups = async (
  companyId: string,
  query: string = '',
  pageNumber: number = 1,
  pageSize: number = 50
): Promise<PaginationResponse<ITemplateModifierGroup>> => {
  const queryString = objectToQueryParamsString({
    pageNumber: pageNumber.toString(),
    pageSize: pageSize.toString(),
    searchTerm: query,
  });

  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/modifierGroups`;
  const result = await executeApiAsync<IPagedResponse<ITemplateModifierGroup>>(`${url}?${queryString}`, {
    method: 'GET',
  });

  return toPaginationResponse({ results: result.results, totalCount: result.totalCount }, pageNumber, pageSize);
};

export const getSharedModifierGroup = async (companyId: string, id: string = ''): Promise<ITemplateModifierGroup> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/modifierGroups/${id}`;
  const result = await executeApiAsync<ITemplateModifierGroup>(url, {
    method: 'GET',
  });
  return result;
};

export const updateSharedModifierGroup = async (
  companyId: string,
  templateModifierGroup: ITemplateModifierGroup,
  isNew: boolean
): Promise<ITemplateModifierGroup> => {
  const request: ITemplateModifierGroupRequest = mapModifierGroupToRequest(templateModifierGroup);
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/modifierGroups`;
  return await executeApiAsync<ITemplateModifierGroup>(url, {
    method: isNew ? 'POST' : 'PUT',
    body: JSON.stringify(request),
  });
};

export const deleteSharedModifierGroup = async (companyId: string, id: string = ''): Promise<void> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/menus/modifierGroups/${id}`;
  await executeApiAsync<ITemplateModifierGroup>(url, {
    method: 'DELETE',
  });
};
