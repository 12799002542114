import ActionHeader from 'components/ActionHeader/ActionHeader';
import FormActionBar from 'components/FormActionBar/FormActionBar';
import { PanelLayout, Panel } from 'components/PanelLayout';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { setFormIsDirty } from 'redux/actions/uiActions/formStateActions';
import { IFormState } from 'redux/initialStates/ui/formState';
import { selectFormState, selectSearchParamsState } from 'redux/selectors/uiSelectors/uiSelectors';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { SharedMenuItemsRoutes } from 'sharedMenuItems/Router';
import { SharedMenuItemsLayout } from 'sharedMenuItems/SharedMenuItemsLayout';
import { SharedMenuItemsTabId } from 'sharedMenuItems/SharedMenuItemsTabs';
import { ContextParams } from 'types/ContextParams.interface';
import {
  moveModifierGroupOption,
  removeModifierGroupOption,
  resetModifierGroup,
  resetState,
  updateModifierGroup,
  updateModifierGroupOption,
  addModifierGroupOption,
  addNestedModifierGroupToOption,
  removeNestedModifierGroupToOption,
  moveNestedModifierGroupToOption,
} from './redux/slice';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { getSharedModifierGroupThunk, saveSharedModifierGroupThunk } from './redux/thunks';
import { DetailsView, ModifierGroupDetailsFormFields } from './components/DetailsView/DetailsView';
import ModifierGroupOptionsList from 'menus/components/ModifierGroups/ModifierGroupOptionsList';
import {
  ITemplateEmbeddedModifierItem,
  ITemplateMenuItem,
  ITemplateModifierGroup,
  Visibility,
  WithAssignedLocationsCount,
} from '@ready/menu.core';
import Button from 'components/Button/Button';
import AllowMultiplesModifierOptions from 'menus/components/ModifierGroups/AllowMultiplesModifierOptions';

import styles from './ModifierGroupDetailsPage.module.scss';
import { AddMenuItemsDialog } from 'sharedMenuItems/pages/itemsAndMods/components/AddMenuItemsDialog/AddMenuItemsDialog';
import { mapTemplateItemToEmbeddedModifierItem } from '../modifierGroup.mappers';
import { FormProvider, useForm } from 'react-hook-form';
import { BackLink } from 'types/BackLink.interface';
import getBackLinkUrl from 'utils/urlUtils/getBackLinkUrl';
import { DeleteSharedModifierGroupModal } from '../components/DeleteSharedModifierGroupModal';
import { SelectModifierGroupModal } from '../components/SelectModifierGroupModal/SelectModifierGroupModal';

export const ModifierGroupDetailsPage = () => {
  const { modifierGroup, modifierGroupCache, loading, processing } = useAppSelector(
    (state) => state.sharedMenuItems.modifierGroups.details
  );
  const { isDirty: actionBarVisible } = useAppSelector<IFormState>(selectFormState);
  const { contextId: companyId, id } = useParams<ContextParams>();
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const isNew = id === undefined;

  const [editing, setEditing] = useState<boolean>(isNew ? true : false);
  const [showAddOptionsModal, setShowAddOptionsModal] = useState<boolean>(false);
  const detailsForm = useForm<ModifierGroupDetailsFormFields>({ mode: 'all' });

  useEffect(() => {
    if (!isNew) {
      dispatch(getSharedModifierGroupThunk({ companyId, id }));
    }
  }, [dispatch, companyId, id, isNew]);

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, [dispatch]);

  useEffect(() => {
    // after creating a new mod group, push to the details route.
    if (isNew && modifierGroupCache._id && !actionBarVisible) {
      push(SharedMenuItemsRoutes.getModifierGroupDetailsRoute(companyId, modifierGroupCache._id));
    }
  }, [companyId, isNew, modifierGroupCache, push, actionBarVisible]);

  const handleFormSubmit = async () => {
    const detailsFormValidated = await detailsForm.trigger();

    if (detailsFormValidated) {
      const updatedModGroup: ITemplateModifierGroup = { ...modifierGroup, ...detailsForm.getValues() };
      await dispatch(saveSharedModifierGroupThunk({ companyId, templateModifierGroup: updatedModGroup }));

      setEditing(false);
      dispatch(resetModifierGroup());
      dispatch(setFormIsDirty(false));
    }
  };

  const resetForm = () => {
    setEditing(false);
    dispatch(resetModifierGroup());
    dispatch(setFormIsDirty(false));

    if (isNew) {
      push(SharedMenuItemsRoutes.getModifierGroupListRoute(companyId));
    }
  };

  const { query, page } = useAppSelector(selectSearchParamsState);
  const backToModGroupListLink: BackLink = {
    baseUrl: SharedMenuItemsRoutes.getModifierGroupListRoute(companyId),
    searchParams: {
      query: query || '',
      page: page || '',
    },
  };

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [parentOption, setParentOption] = useState<ITemplateEmbeddedModifierItem>();
  const [showNestedModifierGroupModal, setShowNestedModifierGroupModal] = useState<boolean>(false);

  return (
    <>
      <FormActionBar
        actionBarVisible={actionBarVisible || isNew}
        saveLabel={isNew ? 'Create Shared Modifier Group' : undefined}
        handleFormSubmit={handleFormSubmit}
        resetForm={resetForm}
      />

      <SharedMenuItemsLayout tab={SharedMenuItemsTabId.ModifierGroups}>
        {loading && !isNew ? (
          <LoadingSpinner />
        ) : (
          <>
            <ActionHeader
              backLinkTo={!isNew ? backToModGroupListLink : undefined}
              text={modifierGroupCache?.name || 'New Shared Modifier Group'}
              actionButtons={[
                {
                  label: 'Delete',
                  variant: 'secondary-gray-bg',
                  hidden: isNew || editing,
                  onClick: () => {
                    setShowDeleteModal(true);
                  },
                },
                {
                  label: 'Edit',
                  variant: 'primary',
                  hidden: isNew || editing,
                  onClick: () => {
                    dispatch(setFormIsDirty(true));
                    setEditing(true);
                  },
                },
              ]}
            />

            <PanelLayout stackOnTablet>
              <Panel>
                <FormProvider {...detailsForm}>
                  <DetailsView modifierGroup={modifierGroup} editing={editing} processing={processing} />
                </FormProvider>
              </Panel>
              <Panel
                title='Modifier Options'
                headerControls={
                  !editing ? undefined : (
                    <Button
                      label='+ Add Options'
                      variant='primary'
                      unavailable={processing}
                      onClick={() => setShowAddOptionsModal(true)}
                    />
                  )
                }
                subTitleComponent={
                  modifierGroup?.allowMultiplesOfEachOption === true || editing ? (
                    <AllowMultiplesModifierOptions
                      allowMultiplesOfEachOption={modifierGroup?.allowMultiplesOfEachOption ?? false}
                      editing={editing}
                      processing={processing}
                      className={editing ? styles.isAllowMultiplesModifierEditMode : styles.isAllowMultiplesModifier}
                      onMultiplesModifierOptionsChanged={(checked: boolean) => {
                        dispatch(updateModifierGroup({ allowMultiplesOfEachOption: checked }));
                      }}
                    />
                  ) : null
                }
                fullContentArea
              >
                {/* todo: keeping for future validation
                <ModifierOptionsValidationNotification
                    showMessage={modifierGroupForm.optionsValidation.isDefault.hasError}
                    message={modifierGroupForm.optionsValidation.isDefault.errorMessage}
                  />

                  <ModifierOptionsValidationNotification
                    showMessage={modifierGroupForm.remoteValidation.hasError}
                    message={modifierGroupForm.remoteValidation.errorMessage}
                  /> */}

                <ModifierGroupOptionsList
                  modifierGroup={modifierGroup}
                  // optionsValidation={modifierGroupForm.optionsValidation}
                  processing={processing}
                  editing={editing}
                  companyId={companyId}
                  allowNestedMods={true} // shared modifier groups always supports configuring shared mods.
                  onOptionMoved={(oldIndex, newIndex) => {
                    dispatch(setFormIsDirty(true));
                    dispatch(moveModifierGroupOption({ oldIndex, newIndex }));
                  }}
                  onOptionDeleted={(optionId) => {
                    dispatch(setFormIsDirty(true));
                    dispatch(removeModifierGroupOption(optionId));
                  }}
                  onVisibilityChanged={(optionId, visible) => {
                    dispatch(setFormIsDirty(true));
                    dispatch(
                      updateModifierGroupOption({
                        itemId: optionId,
                        visibility: visible ? Visibility.visible : Visibility.hidden,
                      })
                    );
                  }}
                  onChangeMaxValue={(optionId, value) => {
                    dispatch(updateModifierGroupOption({ itemId: optionId, constraints: { max: value } }));
                  }}
                  onDefaultChanged={(id, isDefault) => {
                    dispatch(updateModifierGroupOption({ itemId: id, isDefault: isDefault }));
                  }}
                  onAddNestedMod={(parentOption) => {
                    setParentOption(parentOption);
                    setShowNestedModifierGroupModal(true);
                  }}
                  onRemoveNestedMod={(parentOptionId, nestedModGroupId) => {
                    dispatch(
                      removeNestedModifierGroupToOption({
                        parentOptionId: parentOptionId,
                        nestedModGroupId: nestedModGroupId,
                      })
                    );
                  }}
                  onMoveNestedMod={(optionId: string, sourceIndex: number, destinationIndex: number) => {
                    dispatch(moveNestedModifierGroupToOption({ optionId, sourceIndex, destinationIndex }));
                  }}
                />
              </Panel>
            </PanelLayout>
          </>
        )}
        {showAddOptionsModal && (
          <AddMenuItemsDialog
            setShowModal={setShowAddOptionsModal}
            headerLabel='Add Options'
            selectedIds={(modifierGroup?.options || []).map((x) => x.itemId)}
            onRemove={(itemId: string): void => {
              dispatch(removeModifierGroupOption(itemId));
            }}
            onAdd={(item: WithAssignedLocationsCount<ITemplateMenuItem>): void => {
              dispatch(addModifierGroupOption(mapTemplateItemToEmbeddedModifierItem(item)));
            }}
          />
        )}

        {showNestedModifierGroupModal && (
          <SelectModifierGroupModal
            parentModifierGroupId={modifierGroup._id}
            headerLabel='Add Nested Modifier'
            setShowModal={setShowNestedModifierGroupModal}
            selectedIds={(parentOption?.nestedGroups || []).map((x) => x._id)}
            onAdd={(modGroup: ITemplateModifierGroup): void => {
              if (parentOption) {
                dispatch(
                  addNestedModifierGroupToOption({ parentOptionId: parentOption?.itemId, nestedModGroup: modGroup })
                );
              }
            }}
            onRemove={(modGroupId: string): void => {
              if (parentOption) {
                dispatch(
                  removeNestedModifierGroupToOption({
                    parentOptionId: parentOption?.itemId,
                    nestedModGroupId: modGroupId,
                  })
                );
              }
            }}
          />
        )}

        {showDeleteModal && (
          <DeleteSharedModifierGroupModal
            companyId={companyId}
            modifierGroup={modifierGroup}
            setShowModal={(show: boolean) => setShowDeleteModal(show)}
            onDelete={() => push(getBackLinkUrl(backToModGroupListLink))}
          />
        )}
      </SharedMenuItemsLayout>
    </>
  );
};
