import React from 'react';
// components
import { FormGroup, FormControl } from '../../components/Form';
import SelectFilter, { Option } from '../../components/SelectFilter/SelectFilter';
import ColorPicker from '../../components/ColorPicker/ColorPicker';
import { IBrandingPanel } from '../redux/PanelBrandingState';
import { LOCATION_SETTINGS_ACTIONS } from '../redux/LocationSettingsActions';
import { Option as SelectFilterOption } from '../../components/SelectFilter/SelectFilter';
import { IUIDropDown } from '../../redux/initialStates/ui/dropDownState';
import { changeHeaderFontOption, changeBodyFontOption } from '../../redux/actions/uiActions/uiActions';
import mapSelectOption from '../../utils/selectListUtils/mapSelectOption';

// redux
import { useDispatch } from 'react-redux';
import ImageUploader from '../../components/ImageUploader/ImageUploader';
import { IAsset } from '@ready/dashboardv2api.contracts';
import { LIST_OPTIONS } from '../../utils/selectListUtils/listOptions';
import { IUpdateLocationParam } from './LocationsEditPage';

interface Props {
  updateLocationParam: IUpdateLocationParam;
  isProcessing: boolean;
  brandingPanel: IBrandingPanel;
  dropDown: IUIDropDown;
}

const LocationPanelBranding = (props: Props) => {
  const dispatch = useDispatch();
  const { updateLocationParam, isProcessing, brandingPanel, dropDown } = props;
  const validation = brandingPanel.validation;

  const onImageUpload = (asset: Partial<IAsset>) => {
    updateLocationParam(asset, LOCATION_SETTINGS_ACTIONS.UPDATE_BRANDING_PANEL_HEADER_IMAGE_KEY);
  };

  const handleSelectChange = (newVal: Option, key: string) => {
    switch (key) {
      case 'headerFont':
        dispatch(changeHeaderFontOption(newVal));
        updateLocationParam(newVal.value, LOCATION_SETTINGS_ACTIONS.UPDATE_BRANDING_PANEL_HEADER_FONT);
        break;
      case 'bodyFont':
        dispatch(changeBodyFontOption(newVal));
        updateLocationParam(newVal.value, LOCATION_SETTINGS_ACTIONS.UPDATE_BRANDING_PANEL_BODY_FONT);
        break;
      default:
        console.error(`Cannot change branding option`);
    }
  };

  return (
    <FormGroup groupName='Branding'>
      <FormControl
        label='Primary Colour'
        withError={validation.primaryColour.hasError}
        errorMessage={validation.primaryColour.error}
      >
        <ColorPicker
          colourWheelValue={brandingPanel.primaryColour}
          colourTextValue={brandingPanel.primaryColour}
          onChange={(colour: string) => {
            updateLocationParam(colour, LOCATION_SETTINGS_ACTIONS.UPDATE_BRANDING_PANEL_PRIMARY_COLOUR);
          }}
          disabled={isProcessing}
        />
      </FormControl>
      <FormControl label='Menu Background Colour'>
        <ColorPicker
          colourWheelValue={brandingPanel.menuBackgroundColour}
          colourTextValue={brandingPanel.menuBackgroundColour}
          onChange={(colour: string) => {
            updateLocationParam(colour, LOCATION_SETTINGS_ACTIONS.UPDATE_BRANDING_PANEL_MENU_BACKGROUND_COLOUR);
          }}
          disabled={isProcessing}
        />
      </FormControl>
      <FormControl label='Menu Text Colour'>
        <ColorPicker
          colourWheelValue={brandingPanel.menuTextColour}
          colourTextValue={brandingPanel.menuTextColour}
          onChange={(colour: string) => {
            updateLocationParam(colour, LOCATION_SETTINGS_ACTIONS.UPDATE_BRANDING_PANEL_MENU_TEXT_COLOUR);
          }}
          disabled={isProcessing}
        />
      </FormControl>
      <FormControl label='Accent Background Colour'>
        <ColorPicker
          colourWheelValue={brandingPanel.accentBackgroundColour}
          colourTextValue={brandingPanel.accentBackgroundColour}
          onChange={(colour: string) => {
            updateLocationParam(colour, LOCATION_SETTINGS_ACTIONS.UPDATE_BRANDING_PANEL_ACCENT_BACKGROUND_COLOUR);
          }}
          disabled={isProcessing}
        />
      </FormControl>
      <FormControl label='Accent Text Colour'>
        <ColorPicker
          colourWheelValue={brandingPanel.accentTextColour}
          colourTextValue={brandingPanel.accentTextColour}
          onChange={(colour: string) => {
            updateLocationParam(colour, LOCATION_SETTINGS_ACTIONS.UPDATE_BRANDING_PANEL_ACCENT_TEXT_COLOUR);
          }}
          disabled={isProcessing}
        />
      </FormControl>
      <FormControl label='Image Styling'>
        <SelectFilter
          placeholder='Select an Image Styling option'
          options={LIST_OPTIONS.brandingImageStyling}
          onChange={(option: SelectFilterOption) => {
            updateLocationParam(option.value, LOCATION_SETTINGS_ACTIONS.UPDATE_BRANDING_PANEL_IMAGE_STYLING);
          }}
          value={mapSelectOption(LIST_OPTIONS.brandingImageStyling, brandingPanel.imageStyling)}
          disabled={isProcessing}
        />
      </FormControl>
      <FormControl label='Header Font'>
        <SelectFilter
          placeholder='Select a header font'
          options={dropDown.headerFont.options}
          onChange={(option: SelectFilterOption) => {
            handleSelectChange(option, 'headerFont');
          }}
          value={dropDown.headerFont.selectedOption}
          disabled={isProcessing}
        />
      </FormControl>
      <FormControl label='Body Font'>
        <SelectFilter
          placeholder='Select a body font'
          options={dropDown.bodyFont.options}
          onChange={(option: SelectFilterOption) => {
            handleSelectChange(option, 'bodyFont');
          }}
          value={dropDown.bodyFont.selectedOption}
          disabled={isProcessing}
        />
      </FormControl>
      <FormControl label='Banner Image'>
        <ImageUploader
          aspectRatio='3:20'
          onUpload={onImageUpload}
          imageKey={brandingPanel.imageKey}
          isProcessing={isProcessing}
          maxFileSizeKb={1000}
          formattedGuideText={
            <>
              <strong>Recommended Size:</strong> 1200px wide, 150px high, 20:3 Aspect Ratio, Max File Size: 1MB{' '}
              <strong>Format:</strong> JPG, PNG
            </>
          }
        />
      </FormControl>
    </FormGroup>
  );
};

export default LocationPanelBranding;
