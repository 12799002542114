import React from 'react';
import LayoutContent from 'components/AppLayout/LayoutContent';
import SearchParamInput from 'components/SearchInput/SearchParamInput';
import { Table, TableBody, TableCell, TableHeader, TableRow, TableRowsSkeleton } from 'components/Table';
import { IContext } from '@ready/dashboardv2api.contracts';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { getCompanyListForAdminThunk } from './redux/thunk';
import { useSearchParams } from 'hooks';

const colsWidth: number[] = [30, 25, 15, 15, 15, 8, 15];

export const CompaniesListPage = () => {
  const dispatch = useAppDispatch();
  const { query } = useSearchParams();
  const { companies, loading } = useAppSelector((state) => state.companies.list);
  const emptyResult = !loading && (companies ?? []).length === 0;

  React.useEffect(() => {
    dispatch(getCompanyListForAdminThunk({ query }));
  }, [dispatch, query]);

  return (
    <LayoutContent
      title='Companies'
      containerType='full'
      toolbarContent={<SearchParamInput placeholder='Search Companies' />}
    >
      {/* Todo: add pagination here once we have pagination data in the response */}
      <Table>
        <TableHeader>
          <TableRow>
            <TableCell width={colsWidth[0]}>COMPANY NAME</TableCell>
          </TableRow>
        </TableHeader>

        <TableBody emptyResult={emptyResult}>
          {!loading && companies?.length ? (
            companies.map((company: IContext) => {
              return (
                <TableRow key={company.id} linkTo={`/ready/companiesSettings/${company.id}/payment`}>
                  <TableCell width={colsWidth[0]}>{company.name}</TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRowsSkeleton rows={50} colsWidth={colsWidth} />
          )}
        </TableBody>
      </Table>
    </LayoutContent>
  );
};
