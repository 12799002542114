import React, { ChangeEvent } from 'react';
import styles from './OrderSettingsDialog.module.scss';
import { useParams } from 'react-router-dom';
import { ContextParams } from '../../../types/ContextParams.interface';
import { AppState } from '../../../redux/initialStates/AppState';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '../../../components/Modal';
import { ConnectedProps, connect } from 'react-redux';
import {
  setOrderSettingsDialogOpen,
  fetchOrderSettingsDialogData,
  initOrderSettingsDialogData,
  toggleOrderThrottlingEnabled,
  updateMaxNumberOfOrdersPerMinuteAmount,
  updateMaxNumberOfOrdersPerMinuteDurationMinutes,
  updateDisruptionMessage,
  updateAlcoholPolicyMessage,
  updateMaxNumberOfDrinksPerOrder,
  updateLocationOrderSettingsData,
  validateOrderSettings,
} from '../../../redux/actions/orders/locationOrderSettingsActions';
import { FormControl, Form } from '../../../components/Form';
import Toggle from '../../../components/Toggle/Toggle';
import TextInput from '../../../components/TextInput/TextInput';
import SelectFilter, { Option } from '../../../components/SelectFilter/SelectFilter';
import { LIST_OPTIONS } from '../../../utils/selectListUtils/listOptions';
import TextArea from '../../../components/TextArea/TextArea';
import { toNumber } from '../../../utils/numberUtils/toNumber';
import { getSelectedOption } from '../../../utils/selectListUtils/listUtil';
import { ILocationOrderSettings } from '@ready/dashboardv2api.contracts';

export interface IOrderSettingsDialogProps {
  loading: boolean;
  open: boolean;
  orderThrottlingEnabled: boolean;
  maxNumberOfOrdersPerMinuteAmount: number;
  maxNumberOfOrdersPerMinuteDurationMinutes: number;
  disruptionMessage: string;
  alcoholPolicy: string;
  maxNumberOfDrinksPerOrder: number;
}

const OrderSettingsDialog = ({
  fetchOrderSettingsDialogData,
  initOrderSettingsDialogData,
  setOrderSettingsDialogOpen,
  toggleOrderThrottlingEnabled,
  updateMaxNumberOfOrdersPerMinuteAmount,
  updateMaxNumberOfOrdersPerMinuteDurationMinutes,
  updateDisruptionMessage,
  updateAlcoholPolicyMessage,
  updateMaxNumberOfDrinksPerOrder,
  updateLocationOrderSettingsData,
  orderSettings,
  validateOrderSettings,
  open,
}: ReduxProps): JSX.Element | null => {
  const { contextId, locationId } = useParams<ContextParams>();

  React.useEffect(() => {
    (async () => {
      await fetchOrderSettingsDialogData(contextId, locationId);
    })();
    return () => {
      initOrderSettingsDialogData();
    };
  }, [fetchOrderSettingsDialogData, contextId, locationId, initOrderSettingsDialogData]);

  const handleSetShowModal = React.useCallback(
    (open: boolean) => {
      setOrderSettingsDialogOpen(open);
    },
    [setOrderSettingsDialogOpen]
  );

  const handleBackOut = React.useCallback(() => {
    setOrderSettingsDialogOpen(false);
  }, [setOrderSettingsDialogOpen]);

  const handleUpdateOrderThrottlingOrdersPerMinuteAmount = React.useCallback(
    (amount: number) => {
      updateMaxNumberOfOrdersPerMinuteAmount(amount);
    },
    [updateMaxNumberOfOrdersPerMinuteAmount]
  );

  const handleUpdateOrderThrottlingDurationMinutes = React.useCallback(
    (durationMinutes: Option) => {
      updateMaxNumberOfOrdersPerMinuteDurationMinutes(toNumber(durationMinutes.value as string));
    },
    [updateMaxNumberOfOrdersPerMinuteDurationMinutes]
  );

  const handleUpdateDisruptionMessage = React.useCallback(
    (message: string) => {
      updateDisruptionMessage(message);
    },
    [updateDisruptionMessage]
  );

  const handleUpdateAlcoholPolicyMessage = React.useCallback(
    (message: string) => {
      updateAlcoholPolicyMessage(message);
    },
    [updateAlcoholPolicyMessage]
  );

  const handleUpdateMaxNumberOfDrinksPerOrder = React.useCallback(
    (maxNumber: number) => {
      updateMaxNumberOfDrinksPerOrder(maxNumber);
    },
    [updateMaxNumberOfDrinksPerOrder]
  );

  const handleOrderSettingsValidation = React.useCallback(
    (orderSettings: ILocationOrderSettings): boolean => {
      validateOrderSettings(orderSettings);

      return !(orderSettings.orderThrottling?.enabled && !orderSettings.orderThrottling.amount);
    },
    [validateOrderSettings]
  );

  const handleSaveOrderSettings = React.useCallback(() => {
    if (handleOrderSettingsValidation(orderSettings.orderSettingsData)) {
      updateLocationOrderSettingsData(contextId, locationId, orderSettings.orderSettingsData);
    }
  }, [
    handleOrderSettingsValidation,
    updateLocationOrderSettingsData,
    contextId,
    locationId,
    orderSettings.orderSettingsData,
  ]);

  return open ? (
    <Modal setShowModal={handleSetShowModal}>
      <ModalHeader headerLabel='Order Settings' setShowModal={handleSetShowModal} />
      <ModalBody>
        {
          <Form isModalForm>
            <FormControl label='Order Throttling'>
              <Toggle
                checked={orderSettings.orderSettingsData.orderThrottling!.enabled}
                onChange={(checked: boolean) => {
                  toggleOrderThrottlingEnabled(checked);
                }}
                loading={orderSettings.loading}
              />
            </FormControl>
            <FormControl
              label='Max # of Orders per Minute'
              additionalStyles={styles.orderThottlingInputs}
              errorMessage={orderSettings.validation.orderThrottling.amount.error}
              withError={orderSettings.validation.orderThrottling.amount.hasError}
            >
              <TextInput
                type='number'
                placeholder='e.g. 7'
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleUpdateOrderThrottlingOrdersPerMinuteAmount(toNumber(event.target.value));
                }}
                value={orderSettings.orderSettingsData.orderThrottling!.amount}
                withError={orderSettings.validation.orderThrottling.amount.hasError}
                loading={orderSettings.loading}
              />
              <span className='form__control__separator'>/</span>
              <SelectFilter
                placeholder='Select duration minutes'
                options={LIST_OPTIONS.throttleDuration}
                value={getSelectedOption(
                  LIST_OPTIONS.throttleDuration,
                  (orderSettings.orderSettingsData.orderThrottling!.durationMinutes! || '').toString(),
                  'duration minutes'
                )}
                onChange={(option: Option) => {
                  handleUpdateOrderThrottlingDurationMinutes(option);
                }}
                loading={orderSettings.loading}
              />
            </FormControl>
            <FormControl label='Ordering Offline Message'>
              <TextArea
                value={orderSettings.orderSettingsData.disruptionMessage!}
                placeholder={'This message will be displayed to guests when ordering is offline.'}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                  handleUpdateDisruptionMessage(event.target.value);
                }}
                loading={orderSettings.loading}
                maxLength={300}
              />
            </FormControl>
            <FormControl label='Alcohol Policy'>
              <TextArea
                value={orderSettings.orderSettingsData.alcoholPolicy!}
                placeholder={'This message will be displayed to guests purchasing alcohol.'}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                  handleUpdateAlcoholPolicyMessage(event.target.value);
                }}
                loading={orderSettings.loading}
                maxLength={1000}
              />
            </FormControl>
            <FormControl label='Max # of Drinks per Order'>
              <TextInput
                type='number'
                placeholder='e.g. 7'
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleUpdateMaxNumberOfDrinksPerOrder(toNumber(event.target.value));
                }}
                value={orderSettings.orderSettingsData.alcoholPurchasingLimit}
                additionalStyles={styles.maxNumberOfDrinks}
                loading={orderSettings.loading}
              />
            </FormControl>
          </Form>
        }
      </ModalBody>
      <ModalFooter
        primaryLabel='Save Changes'
        primaryActionHandler={handleSaveOrderSettings}
        loading={orderSettings.loading}
        secondaryLabel='Cancel'
        secondaryActionHandler={handleBackOut}
      />
    </Modal>
  ) : null;
};

const actionCreators = {
  setOrderSettingsDialogOpen,
  fetchOrderSettingsDialogData,
  initOrderSettingsDialogData,
  toggleOrderThrottlingEnabled,
  updateMaxNumberOfOrdersPerMinuteAmount,
  updateMaxNumberOfOrdersPerMinuteDurationMinutes,
  updateDisruptionMessage,
  updateAlcoholPolicyMessage,
  updateMaxNumberOfDrinksPerOrder,
  updateLocationOrderSettingsData,
  validateOrderSettings,
};

const mapStateToProps = (state: AppState) => {
  return {
    orderSettings: state.orders.orderSettings,
    open: state.orders.orderSettings.orderSettingsDialogOpen,
  };
};

const connector = connect(mapStateToProps, actionCreators);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(OrderSettingsDialog);
