import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Button from '../../../components/Button/Button';
import styles from './PaymentProcessingContent.module.scss';
import {
  IGiftCardConfigurationPanel,
  IPayPalInfo,
  SupportedGiftCardProviderList,
} from '../../redux/initialStates/paymentProcessing';
import {
  initGiftCardConfig,
  toggleGiftCardEditForm,
  getGiftCardTenderTypes,
  resetGiftCardConfig,
  updatePosIntegration,
  unlinkPayPalModal,
  unlinkPayPal,
  updatePayPalConfig,
  initPayPalInfoAfterPayPalSignUp,
  initPayPalInfo,
  resetPayPalConfig,
} from '../../redux/actions/paymentProcessingActions';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import { Option, GroupedOption } from '../../../components/SelectFilter/SelectFilter';
import { Panel, PanelLayout } from '../../../components/PanelLayout';
import GiftCardsViewPanel from './giftcards/GiftCardsViewPanel';
import GiftCardsEditPanel from './giftcards/GiftCardsEditPanel';
import classNames from 'classnames';
import {
  GiftCardProvider,
  IOnAccountPaymentOptionSummary,
  IPayPalOptions,
  PayPalStatus,
  PaymentProcessorProvider,
  PosSystemType,
} from '@ready/dashboardv2api.contracts';
import PayPalPanel from './PayPalPanel';
import { checkFeatureToggle } from '../../../utils/featureToggle/featureToggle';
import { OnAccountPaymentOptionsList } from '../../components/OnAccountPaymentOptions/OnAccountPaymentOptionsList';
import OverflowButton from '../../../components/OverflowButton/OverflowButton';
import { useSearchParams } from '../../../hooks';
import IconButton from 'components/IconButton/IconButton';
import TextIcon, { Icon } from 'components/Icon/TextIcon';
import { OnAccountPaymentOptionSettingsModal } from './OnAccountPaymentOptionSettingsModal';
import { DropDownMenuOptionProps } from 'components/DropDownMenu/types';
import { PaymentProviderPanel } from './paymentProvider/PaymentProviderPanel';

export type GiftCardDropdownList = [Option, GroupedOption, GroupedOption];

export interface PaymentProcessingContentProps {
  paypal: IPayPalInfo;
  giftCard: IGiftCardConfigurationPanel;
  onAccountPaymentOptions: IOnAccountPaymentOptionSummary[];
  giftCardEnabled: boolean;
  companyId: string;
  locationId: string;
  loading: boolean;
  posSystemType?: PosSystemType;
  underlyingPosSystemType?: PosSystemType;
  paymentProcessor?: PaymentProcessorProvider;
}

interface NestedDetailContentProps {
  children: React.ReactNode;
  className?: string;
}

const isOnAccountPaymentOptionsSupported = (
  posSystemType?: PosSystemType,
  underlyingPosSystemType?: PosSystemType
): boolean => {
  const supportedIntegrations: PosSystemType[] = [
    PosSystemType.parbrink,
    PosSystemType.micros3700,
    PosSystemType.ncraloha,
    PosSystemType.positouch,
    PosSystemType.simphony2,
    PosSystemType.microssimphony,
    PosSystemType.basicpos
  ];

  const type = posSystemType === PosSystemType.omnivore ? underlyingPosSystemType : posSystemType;
  return supportedIntegrations.includes(type as PosSystemType);
};

const isOnAccountPaymentSettingsVisible = (posSystemType?: PosSystemType, underlyingPosSystemType?: PosSystemType) => {
  const supportedIntegrations: PosSystemType[] = [
    PosSystemType.micros3700,
    PosSystemType.ncraloha,
    PosSystemType.positouch,
    PosSystemType.simphony2,
    PosSystemType.microssimphony,
    PosSystemType.basicpos
  ];

  const type = posSystemType === PosSystemType.omnivore ? underlyingPosSystemType : posSystemType;
  return supportedIntegrations.includes(type as PosSystemType);
};

export const NestedDetailContent = ({ children, className: extraClassName }: NestedDetailContentProps): JSX.Element => (
  <div className={classNames(styles.nestedDetailContent, extraClassName)}>{children}</div>
);

export const getSupportedGiftCardProviders = (pos: string): GiftCardProvider[] => {
  return SupportedGiftCardProviderList[pos] || [];
};

const PaymentProcessingContent = (props: PaymentProcessingContentProps) => {
  const {
    companyId,
    locationId,
    posSystemType,
    underlyingPosSystemType,
    loading,
    giftCard,
    giftCardEnabled,
    paypal,
    onAccountPaymentOptions,
    paymentProcessor,
  } = props;
  const dispatch = useDispatch();
  const { push, replace } = useHistory();
  const { pathname } = useLocation();
  const { merchantId, merchantIdInPayPal, isEmailConfirmed, permissionsGranted } = useSearchParams();
  const [showModal, setShowModal] = React.useState<boolean>(false);

  const initPayPal = React.useCallback(() => {
    dispatch(initPayPalInfo(companyId, locationId));
  }, [dispatch, companyId, locationId]);

  const initPayPalAfterPayPalSignUp = React.useCallback(
    (verified: boolean) => {
      const config: IPayPalOptions = {
        status: verified ? PayPalStatus.Connected : PayPalStatus.Pending,
        paypalMerchantId: merchantIdInPayPal,
      };
      dispatch(initPayPalInfoAfterPayPalSignUp(companyId, locationId, config));
    },
    [dispatch, companyId, locationId, merchantIdInPayPal]
  );

  React.useEffect(() => {
    if (!paypal.initialized) {
      if (locationId === merchantId && merchantIdInPayPal) {
        initPayPalAfterPayPalSignUp(isEmailConfirmed === 'true' && permissionsGranted === 'true');
      } else {
        initPayPal();
      }
    }
  }, [
    initPayPalAfterPayPalSignUp,
    locationId,
    merchantId,
    merchantIdInPayPal,
    isEmailConfirmed,
    permissionsGranted,
    initPayPal,
    paypal.initialized,
  ]);

  React.useEffect(() => {
    if (paypal.initialized && merchantId) {
      // remove query params after PayPal signed up
      replace(pathname);
    }
  }, [paypal.initialized, merchantId, replace, pathname]);

  React.useEffect(() => {
    if (posSystemType) {
      dispatch(updatePosIntegration(posSystemType));
      dispatch(getGiftCardTenderTypes(companyId, locationId));
    }

    return () => {
      dispatch(resetGiftCardConfig());
      dispatch(resetPayPalConfig());
    };
  }, [dispatch, locationId, companyId, posSystemType]);

  const shouldInitializeGiftCardConfig = giftCardEnabled && !giftCard.view;
  React.useEffect(() => {
    if (shouldInitializeGiftCardConfig) {
      dispatch(initGiftCardConfig(companyId, locationId));
    }
  }, [dispatch, locationId, companyId, shouldInitializeGiftCardConfig]);

  const handleEditButtonClick = () => dispatch(toggleGiftCardEditForm(true));

  const handleUnlinkPayPalModal = (modalVisible: boolean) => {
    dispatch(unlinkPayPalModal(modalVisible));
  };

  const unlinkPayPalAccount = () => {
    dispatch(unlinkPayPal(companyId, locationId));
  };

  const handlePayPalLocationStatusChange = (status: boolean) => {
    dispatch(
      updatePayPalConfig(companyId, locationId, {
        ...paypal.options,
        status: status ? PayPalStatus.Connected : PayPalStatus.Disabled,
      })
    );
  };

  const supportsOnAccountPaymentOptions = isOnAccountPaymentOptionsSupported(posSystemType, underlyingPosSystemType);
  const onAccountPaymentSettingsVisible = isOnAccountPaymentSettingsVisible(posSystemType, underlyingPosSystemType);

  const newAccountPaymentButton: DropDownMenuOptionProps = {
    label: '+ New Payment Option',
    onClick: () => push(`${pathname}/paymentOptions/new`),
    primary: true,
  };

  const onAccountPaymentSettingsButton: DropDownMenuOptionProps = {
    label: 'On Account Payment Settings',
    onClick: () => setShowModal(true),
  };

  const onAccountPaymentOptionsOverflowOptions: DropDownMenuOptionProps[] = [newAccountPaymentButton];

  if (onAccountPaymentSettingsVisible) {
    onAccountPaymentOptionsOverflowOptions.push(onAccountPaymentSettingsButton);
  }

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className={styles.panelContainer}>
          <PanelLayout>
            <Panel title='Payment Provider'>
              <PaymentProviderPanel provider={paymentProcessor} />
            </Panel>
            <>
              {checkFeatureToggle('app_payPalPanel') && (
                <Panel title='PayPal Settings'>
                  <PayPalPanel
                    paypal={paypal}
                    handleUnlinkPayPalModal={handleUnlinkPayPalModal}
                    unlinkPayPalAccount={unlinkPayPalAccount}
                    handlePayPalLocationStatusChange={handlePayPalLocationStatusChange}
                  />
                </Panel>
              )}
            </>

            <Panel
              title='Gift Cards'
              headerControls={
                giftCard.editable ? undefined : (
                  <Button
                    variant='secondary'
                    label='Edit'
                    size='large'
                    loading={giftCard.processing}
                    onClick={handleEditButtonClick}
                  />
                )
              }
            >
              {giftCard.editable ? (
                <GiftCardsEditPanel
                  companyId={companyId}
                  locationId={locationId}
                  compatibleProviders={getSupportedGiftCardProviders(giftCard.posIntegration)}
                  loading={giftCard.processing}
                  tenderTypes={giftCard.tenderTypes}
                  validated={giftCard.validated}
                  provider={giftCard.edit?.provider}
                  options={giftCard.edit}
                  validations={giftCard?.validation}
                />
              ) : (
                <GiftCardsViewPanel
                  giftCardEnabled={giftCardEnabled}
                  tenderTypes={giftCard.tenderTypes}
                  provider={giftCard.view?.provider}
                  options={giftCard.view}
                />
              )}
            </Panel>

            <>
              {supportsOnAccountPaymentOptions && (
                <Panel
                  title='On Account Payment Options'
                  subTitleComponent={
                    <>Give your guests the option to pay on account (ex. hotel room charge, cafeteria card etc).</>
                  }
                  headerControls={
                    <>
                      <div className={styles.buttons}>
                        <Button
                          variant='secondary'
                          label={newAccountPaymentButton.label}
                          size='large'
                          loading={false}
                          onClick={newAccountPaymentButton.onClick}
                        />
                        {onAccountPaymentSettingsVisible && (
                          <IconButton onClick={() => setShowModal(true)}>
                            <TextIcon icon={Icon.Cog} additionalStyles={styles.settingsIcon} />
                          </IconButton>
                        )}
                      </div>
                      <div className={styles.mobileButtons}>
                        <OverflowButton options={onAccountPaymentOptionsOverflowOptions} />
                      </div>
                    </>
                  }
                  fullContentArea
                >
                  <OnAccountPaymentOptionsList onAccountPaymentOptions={onAccountPaymentOptions} />
                </Panel>
              )}
            </>
          </PanelLayout>
          {showModal && (
            <OnAccountPaymentOptionSettingsModal
              setShowModal={setShowModal}
              posSystemType={posSystemType}
              underlyingPosSystemType={underlyingPosSystemType}
            />
          )}
        </div>
      )}
    </>
  );
};

export default PaymentProcessingContent;
