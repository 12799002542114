import React from 'react';
import styles from './NestedModifierGroups.module.scss';
import { IModifierGroup, ITemplateModifierGroup } from '@ready/menu.core';
import DragAndDrop from '../../../components/DragAndDrop';
import IconButton from '../../../components/IconButton/IconButton';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';
import ModifierGroupOption from './ModifierGroupOption';

interface NestedModifierGroupsProps {
  companyId: string;
  locationId?: string;
  nestedGroups?: (IModifierGroup | ITemplateModifierGroup)[];
  processing?: boolean;
  onRemoveNestedModifierGroup?: (id: string) => void;
  onMoveNestedModifierGroup?: (sourceIndex: number, destinationIndex: number) => void;
}

const NestedModifierGroups = ({
  companyId,
  locationId,
  nestedGroups,
  processing = false,
  onRemoveNestedModifierGroup,
  onMoveNestedModifierGroup = () => {},
}: NestedModifierGroupsProps): JSX.Element | null => {
  if (!nestedGroups || !nestedGroups.length) {
    return null;
  }

  return (
    <div className={styles.container}>
      <DragAndDrop
        dropAreaClassName={styles.nestedModGroups}
        dragItemClassName={styles.nestedModGroup}
        dragHandleClassName={styles.dragHandle}
        dragItems={nestedGroups}
        dragHandleProvided
        dragItemKeyExtractor={(modGroup: IModifierGroup) => modGroup._id}
        dragItemComponentBuilder={(modGroup: IModifierGroup, dragHandle?: JSX.Element) => (
          <>
            <div className={styles.dragHandleContainer}>{dragHandle}</div>
            <div className={styles.content}>
              <div className={styles.main}>
                <div className={styles.header}>
                  <div className={styles.nameAndDisplayName}>
                    <div className={styles.name}>{modGroup.name}</div>
                    {!!modGroup.displayName && <div className={styles.displayName}>{modGroup.displayName}</div>}
                    {!modGroup.displayName && <div className={styles.noDisplayName}>No Description</div>}
                  </div>

                  <div className={styles.constraints}>
                    MIN: {modGroup.constraints.min || 'None'} | MAX: {modGroup.constraints.max || 'Unlimited'}
                  </div>
                </div>

                {!!onRemoveNestedModifierGroup && (
                  <div className={styles.controls}>
                    <IconButton
                      onClick={() => onRemoveNestedModifierGroup(modGroup._id)}
                      additionalClassName={styles.remove}
                    >
                      <TextIcon icon={Icon.RemoveCircle} />
                    </IconButton>
                  </div>
                )}
              </div>

              {modGroup.options.map((option) => (
                <ModifierGroupOption
                  key={`${modGroup._id}-${option.itemId}`}
                  companyId={companyId}
                  locationId={locationId}
                  rootModifierGroupId={modGroup._id}
                  option={option}
                  editing={false}
                  suppressLinking={true}
                  nestingLevel={
                    // At this point we are actually already 2 levels into the nesting, but the way the nested groups
                    // are displayed when in edit mode, which is where this component is used, we display the first
                    // nested level differently, with its options displayed as if they are at the root level
                    0
                  }
                  allowExpansion={
                    !!modGroup.options.find((option) => !!option.nestedGroups && !!option.nestedGroups.length)
                  }
                  allowMultiplesOfEachOption={!!modGroup.allowMultiplesOfEachOption}
                  defaultMaxValue={modGroup.constraints.max || null}
                  processing={processing}
                />
              ))}
            </div>
          </>
        )}
        onDragItemDropped={onMoveNestedModifierGroup}
      />
    </div>
  );
};

export default NestedModifierGroups;
