import React from 'react';
import { TimeColumn, StatusColumn } from '.';
import styles from './StackableTimeAndStatusColumns.module.scss';
import { useClassNames } from '../../../utils/cssUtils/useClassNames';
import { OrderFulfillmentStatus } from '@ready/dashboardv2api.contracts';

interface StackableTimeAndStatusColumnsProps {
  time: Date;
  timeColumnWidth: number;
  orderStatusReadOnly?: boolean;
  orderStatusColumnWidth?: number;
  isProcessing: boolean;
  isOverdue?: boolean;
  isStartSoon?: boolean;
  handleOrderStatusChange?: (orderStatus: OrderFulfillmentStatus) => void;
  stacked?: boolean;
  additionalStyles?: string;
  orderStatus: OrderFulfillmentStatus;
}

const StackableTimeAndStatusColumns = ({
  time,
  timeColumnWidth,
  orderStatusReadOnly = false,
  orderStatusColumnWidth = 0,
  isProcessing,
  isOverdue = false,
  isStartSoon = false,
  handleOrderStatusChange = () => {},
  stacked = false,
  additionalStyles = '',
  orderStatus,
}: StackableTimeAndStatusColumnsProps) => {
  const stackedColumnClassNames = useClassNames(
    [
      {
        stackedColumn: true,
      },
      additionalStyles,
    ],
    styles
  );

  if (stacked) {
    return (
      <div className={stackedColumnClassNames}>
        <div className={styles.stackedContainer}>
          <StatusColumn
            additionalStyles={styles.stackedChild}
            readOnly={orderStatusReadOnly}
            handleOrderStatusChange={handleOrderStatusChange}
            isProcessing={isProcessing}
            orderStatus={orderStatus}
          />
          <TimeColumn
            time={time}
            width={timeColumnWidth}
            isOverdue={isOverdue}
            isStartSoon={isStartSoon}
            additionalStyles={styles.stackedChild}
          />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <TimeColumn
          time={time}
          width={timeColumnWidth}
          isOverdue={isOverdue}
          isStartSoon={isStartSoon}
          additionalStyles={additionalStyles}
        />
        <StatusColumn
          additionalStyles={`${additionalStyles} ${styles.statusColumn}`}
          readOnly={orderStatusReadOnly}
          width={orderStatusColumnWidth}
          handleOrderStatusChange={handleOrderStatusChange}
          isProcessing={isProcessing}
          orderStatus={orderStatus}
        />
      </>
    );
  }
};

export default StackableTimeAndStatusColumns;
