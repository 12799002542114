import { isEqual, isWithinInterval } from 'date-fns';

type Interval = {
  start: Date;
  end: Date;
};

const isEqualOrWithinInterval = (date: Date, { start, end }: Interval) =>
  isEqual(date, start) || isEqual(date, end) || isWithinInterval(date, { start, end });

export default isEqualOrWithinInterval;
