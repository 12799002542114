import React from 'react';
import { ILocation } from '../../../services/types/ILocation.type';
import { IPagedResponse } from '../../../services/types/IPagedResponse.type';
import SearchSelectFilter from '../../../components/SearchSelectFilter/SearchSelectFilter';
import DateFilter from '../../../components/DateFilter/DateFilter';
import { Option } from '../../../components/SelectFilter/SelectFilter';
import { startOfMonth, subMonths } from 'date-fns';

export interface ToolbarComponentProps {
  contextId: string;
  getLocations: (contextId: string) => any;
  permittedLocations: IPagedResponse<ILocation>;
}

export const ToolbarComponent = (props: ToolbarComponentProps) => {
  const { contextId, getLocations, permittedLocations } = props;

  const emptyOption: Option[] = [];
  const [locations, setLocations] = React.useState<Option[]>(emptyOption);

  // load permitted locations
  React.useEffect(() => {
    (async () => {
      if (!!contextId) {
        getLocations(contextId);
      }
    })();
  }, [contextId, getLocations]);

  // map permitted locations to Option interface
  React.useEffect(() => {
    if (!!permittedLocations.totalCount) {
      const mappedLocations: Option[] = permittedLocations.results.map((location: ILocation) => {
        return {
          label: location.name,
          value: location._id,
        };
      });
      setLocations(mappedLocations);
    }
  }, [permittedLocations]);

  const endMonth = startOfMonth(new Date());
  const startMonth = subMonths(endMonth, 1);

  return (
    <div className='transaction_toolbar__container'>
      <div className='transaction_toolbar__date-filter'>
        <h4>Date Range</h4>
        <DateFilter
          initialValue={{
            startMonth,
            endMonth,
          }}
        />
      </div>
      <div className='transaction_toolbar__location-filter'>
        <h4>Locations</h4>
        <SearchSelectFilter placeholder='Select locations' options={locations} isMulti name='locations' isSearchable />
      </div>
    </div>
  );
};
