import React from 'react';
import InfiniteScrollingList from 'components/InfiniteScrollingList/InfiniteScrollingList';
import { buildItemComponent } from 'menus/components/ModifierGroups/AddModifierGroupList';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { selectCreateEditItemState } from 'sharedMenuItems/redux/selectors';
import {
  selectItemInModal,
  unselectModifierGroup,
  updateSharedMenuItemState,
} from 'sharedMenuItems/redux/createEditItem/createEditItemSlice';
import {
  getModifierGroupsThunk,
  getMoreModifierGroupsThunk,
} from 'sharedMenuItems/redux/createEditItem/createEditItemThunkActions';
import { ContextParams } from 'types/ContextParams.interface';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { setFormIsDirty } from 'redux/actions/uiActions/formStateActions';
import { ITemplateEmbeddedModifierGroup, ITemplateModifierGroup } from '@ready/menu.core';

const debounceTime = 300;

export const ModifierGroupsModalList = () => {
  const dispatch = useAppDispatch();
  const {
    modals: {
      modifierGroupsModal: {
        paginationAvailable,
        paginationLoading,
        error,
        filtered,
        selectedIds,
        items,
        query,
        page,
        loading,
      },
    },
    item,
  } = useAppSelector(selectCreateEditItemState);
  const { contextId: companyId } = useParams<ContextParams>();

  const noItemsTitle = 'Create Your First Modifier Group';
  const noItemsMessage = `You haven't created any modifier groups yet. You can create and manage your modifier groups on the "Modifier Groups" page.`;

  const selectModGroup = (modifierGroup: ITemplateEmbeddedModifierGroup) => {
    dispatch(setFormIsDirty(true));
    dispatch(
      selectItemInModal({
        stateKey: 'modifierGroupsModal',
        itemId: modifierGroup._id,
      })
    );
    dispatch(
      updateSharedMenuItemState({
        modifierGroups: [...(item?.modifierGroups ?? []), modifierGroup],
      })
    );
  };

  const unselectModGroup = (id: string) => {
    dispatch(setFormIsDirty(true));
    dispatch(unselectModifierGroup(id));
  };

  const loadModifierGroupsDebounced = React.useMemo(() => {
    return debounce((companyId: string, query?: string) => {
      dispatch(getModifierGroupsThunk({ companyId, query }));
    }, debounceTime);
  }, [dispatch]);

  const loadModifierGroups = React.useCallback(() => {
    loadModifierGroupsDebounced(companyId, query);
  }, [loadModifierGroupsDebounced, companyId, query]);

  const nextPage = page + 1;

  const loadMoreModifierGroupsDebounced = React.useMemo(() => {
    return debounce((companyId: string, query?: string, nextPage?: number) => {
      dispatch(getMoreModifierGroupsThunk({ companyId, query, page: nextPage }));
    }, debounceTime);
  }, [dispatch]);

  const loadMoreModifierGroups = React.useCallback(() => {
    loadMoreModifierGroupsDebounced(companyId, query, nextPage);
  }, [loadMoreModifierGroupsDebounced, companyId, query, nextPage]);

  return (
    <InfiniteScrollingList
      loading={loading}
      error={error}
      paginationLoading={paginationLoading}
      paginationAvailable={paginationAvailable}
      items={items}
      filtered={filtered}
      noItemsTitle={noItemsTitle}
      noItemsMessage={noItemsMessage}
      selectedItemKeys={selectedIds}
      loadItems={loadModifierGroups}
      loadMoreItems={loadMoreModifierGroups}
      getItemKey={(modifierGroup: ITemplateModifierGroup) => modifierGroup._id}
      buildItemComponent={buildItemComponent}
      addItem={selectModGroup}
      removeItem={unselectModGroup}
      isInModal
    />
  );
};
