import ActionHeader from 'components/ActionHeader/ActionHeader';
import LayoutContent from 'components/AppLayout/LayoutContent';
import { PaginationContainer } from 'components/PaginationContainer/PaginationContainer';
import { BodySkeleton, Body } from 'components/TableV2/Body/Body';
import { TableV2 } from 'components/TableV2/TableV2';
import { usePageTitle, useSearchParams } from 'hooks';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectPayoutHistoryDetailsReport } from './redux/selectors';
import { fetchPayoutHistoryDetailsReportThunk } from './redux/thunks';
import { getCells, getHeaderCells, headerColumns, columns, rowKey } from './settings';
import { TableHeader } from 'components/TableV2/TableHeader/TableHeader';
import styles from './PayoutHistoryDetailsPage.module.scss';
import { ContextParams } from 'types/ContextParams.interface';
import { useAppSelector } from 'redux/store';
import { selectSearchParamsState } from 'redux/selectors/uiSelectors/uiSelectors';
import HeaderControls, { ControlOption } from 'components/AppLayout/HeaderControls';
import { exportPayoutHistoryReportThunk } from 'reports/payoutHistory/redux/thunks';

export const PayoutHistoryDetailsPage = () => {
  usePageTitle('Payout History Details', 'Reports');
  const { query, page, sortColumn, sortDirection } = useSearchParams();

  const { query: cachedQuery } = useAppSelector(selectSearchParamsState);
  const { contextId, loading, items, pagination } = useSelector(selectPayoutHistoryDetailsReport);
  const { id } = useParams<ContextParams>();
  const dispatch = useDispatch();
  const [isExporting, setIsExporting] = React.useState(false);

  const decodedQuery = decodeURIComponent(query);
  const parsedQueryParams = new URLSearchParams(decodedQuery);
  const locationId = parsedQueryParams.get('location');

  useEffect(() => {
    if (query) {
      dispatch(
        fetchPayoutHistoryDetailsReportThunk({ contextId, query, payoutId: id, page, sortColumn, sortDirection })
      );
    }
  }, [contextId, query, page, sortColumn, sortDirection, dispatch, id]);

  const cells = getCells();
  const headerCells = getHeaderCells();
  const headerControlOptions = [
    {
      buttonLabel: 'Export',
      dropdownLabel: 'Export Report',
      onClick: async () => {
        setIsExporting(true);
        await dispatch(exportPayoutHistoryReportThunk({ contextId, payoutId: id, locationId: locationId || '' }));
        setIsExporting(false);
      },
      primary: true,
      loading: isExporting,
    } as ControlOption,
  ];

  return (
    <LayoutContent
      title={'Payout History Details'}
      headerControls={<HeaderControls controlOptions={headerControlOptions} />}
    >
      <ActionHeader
        text={id}
        backLinkTo={`/companies/${contextId}/payoutHistory?query=${cachedQuery || ''}`}
      ></ActionHeader>
      <div className={styles.table}>
        <PaginationContainer pagination={pagination}>
          <TableV2 loading={loading.list}>
            <>
              <TableHeader cells={headerCells} data={headerColumns} columns={columns} />
              {loading.list ? (
                <BodySkeleton columns={columns} rowsNumber={50} />
              ) : (
                <Body cells={cells} hiddenColumns={{}} rowKey={rowKey} data={items} columns={columns} />
              )}
            </>
          </TableV2>
        </PaginationContainer>
      </div>
    </LayoutContent>
  );
};
