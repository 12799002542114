import { OrderFulfillmentStatus } from '@ready/dashboardv2api.contracts';
import produce from 'immer';
import { Action } from '../../../redux/types';
import { OrderSection } from '../../../enums/orders.enum';
import { ISinglePlacedOrder } from '../../../services/types/orders.type';
import { sortOrdersIntoSections } from '../../../utils/orderUtils';
import { ORDERS_ACTIONS } from '../../actions/orders/types';
import { orderListInitialState } from '../../initialStates/orders/orderList';

export const orderListReducer = (state = orderListInitialState, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ORDERS_ACTIONS.INIT_ORDERS:
        draft = {
          ...orderListInitialState,
          locationDetails: draft.locationDetails,
          loadingOrders: draft.loadingOrders,
          locationDetailsLoading: draft.locationDetailsLoading,
          urlQuery: draft.urlQuery,
        };
        return draft;

      case ORDERS_ACTIONS.INIT_LOCATIONS:
        draft.locationDetails = orderListInitialState.locationDetails;
        return draft;

      case ORDERS_ACTIONS.SET_ORDER_LIST:
        draft.orders = action.payload;
        return draft;

      case ORDERS_ACTIONS.SET_OVERDUE_ORDERS:
        draft.overdueOrders = action.payload;
        draft.overdueOrdersCount = action.payload.length;
        return draft;

      case ORDERS_ACTIONS.SET_OFFSET_ORDERS:
        draft.offsetOrders = action.payload;
        draft.offsetOrdersCount = action.payload.length;
        return draft;

      case ORDERS_ACTIONS.SET_UP_NEXT_ORDERS:
        draft.upNextOrders = action.payload;
        draft.upNextOrdersCount = action.payload.length;
        return draft;

      case ORDERS_ACTIONS.SET_CANCELLED_ORDERS:
        draft.cancelledOrders = action.payload;
        draft.cancelledOrdersCount = action.payload.length;
        return draft;

      case ORDERS_ACTIONS.SET_COMPLETED_ORDERS:
        draft.completedOrders = action.payload;
        draft.completedOrdersCount = action.payload.length;
        return draft;

      case ORDERS_ACTIONS.SET_PROCESSING_ORDER: {
        const currentSection = action.payload.currentSection;
        const isProcessing: boolean = action.payload.val;
        const orderId = action.payload.orderId;

        switch (currentSection) {
          case OrderSection.DINE_IN:
            const dineInOrderToUpdate = draft.dineInOrders.find((order) => order.orderId === orderId);

            if (dineInOrderToUpdate) {
              dineInOrderToUpdate.processing = isProcessing;
            }

            break;

          case OrderSection.OVERDUE:
            const overdueOrderToUpdate = draft.overdueOrders.find((order) => order.orderId === orderId);

            if (overdueOrderToUpdate) {
              overdueOrderToUpdate.processing = isProcessing;
            }

            break;

          case OrderSection.UPNEXT:
            const upNextOrderToUpdate = draft.upNextOrders.find((order) => order.orderId === orderId);

            if (upNextOrderToUpdate) {
              upNextOrderToUpdate.processing = isProcessing;
            }

            break;

          case OrderSection.OFFSET:
            const offsetOrderToUpdate = draft.offsetOrders.find((order) => order.orderId === orderId);

            if (offsetOrderToUpdate) {
              offsetOrderToUpdate.processing = isProcessing;
            }

            break;

          case OrderSection.COMPLETE:
            const completeOrderToUpdate = draft.completedOrders.find((order) => order.orderId === orderId);

            if (completeOrderToUpdate) {
              completeOrderToUpdate.processing = isProcessing;
            }

            break;
        }

        draft.orders = [
          ...draft.dineInOrders,
          ...draft.overdueOrders,
          ...draft.upNextOrders,
          ...draft.offsetOrders,
          ...draft.completedOrders,
          ...draft.cancelledOrders,
        ];

        return draft;
      }

      case ORDERS_ACTIONS.UPDATE_ORDER_STATUS:
        const { updatedStatus, currentSection, orderId } = action.payload;

        let orderToUpdate: ISinglePlacedOrder | undefined;

        switch (currentSection) {
          case OrderSection.DINE_IN:
            orderToUpdate = draft.dineInOrders.find((order) => order.orderId === orderId);

            if (orderToUpdate) {
              orderToUpdate.status = updatedStatus;

              if (updatedStatus === OrderFulfillmentStatus.Complete) {
                draft.dineInOrders = draft.dineInOrders.filter((order) => order.orderId !== orderId);
                draft.completedOrders.push(orderToUpdate);
                draft.dineInOrdersCount = draft.dineInOrders.length;
                draft.completedOrdersCount = draft.completedOrders.length;
              }
            }

            break;

          case OrderSection.OVERDUE:
            orderToUpdate = draft.overdueOrders.find((order) => order.orderId === orderId);

            if (orderToUpdate) {
              orderToUpdate.status = updatedStatus;

              if (updatedStatus === OrderFulfillmentStatus.Complete) {
                draft.overdueOrders = draft.overdueOrders.filter((order) => order.orderId !== orderId);
                draft.completedOrders.push(orderToUpdate);
                draft.overdueOrdersCount = draft.overdueOrders.length;
                draft.completedOrdersCount = draft.completedOrders.length;
              }
            }

            break;

          case OrderSection.UPNEXT:
            orderToUpdate = draft.upNextOrders.find((order) => order.orderId === orderId);

            if (orderToUpdate) {
              orderToUpdate.status = updatedStatus;

              if (updatedStatus === OrderFulfillmentStatus.Complete) {
                draft.upNextOrders = draft.upNextOrders.filter((order) => order.orderId !== orderId);
                draft.completedOrders.push(orderToUpdate);
                draft.upNextOrdersCount = draft.upNextOrders.length;
                draft.completedOrdersCount = draft.completedOrders.length;
              }
            }

            break;

          case OrderSection.OFFSET:
            orderToUpdate = draft.offsetOrders.find((order) => order.orderId === orderId);

            if (orderToUpdate) {
              orderToUpdate.status = updatedStatus;

              if (updatedStatus === OrderFulfillmentStatus.Complete) {
                draft.offsetOrders = draft.offsetOrders.filter((order) => order.orderId !== orderId);
                draft.completedOrders.push(orderToUpdate);
                draft.offsetOrdersCount = draft.offsetOrders.length;
                draft.completedOrdersCount = draft.completedOrders.length;
              }
            }

            break;

          case OrderSection.COMPLETE:
            orderToUpdate = draft.completedOrders.find((order) => order.orderId === orderId);

            if (orderToUpdate) {
              orderToUpdate.status = updatedStatus;

              if (updatedStatus !== OrderFulfillmentStatus.Complete) {
                draft.completedOrders = draft.completedOrders.filter((order) => order.orderId !== orderId);
                draft.offsetOrders.push(orderToUpdate);
                draft.offsetOrdersCount = draft.offsetOrders.length;
                draft.completedOrdersCount = draft.completedOrders.length;
              }
            }
        }

        draft.orders = [
          ...draft.overdueOrders,
          ...draft.upNextOrders,
          ...draft.offsetOrders,
          ...draft.completedOrders,
          ...draft.cancelledOrders,
          ...draft.dineInOrders,
        ];

        return draft;

      case ORDERS_ACTIONS.SORT_ORDERS_IN_PLACE:
        const { overdueOrders, offsetOrders, upNextOrders, completedOrders, cancelledOrders, dineInOrders } =
          sortOrdersIntoSections(draft.orders);

        draft.overdueOrders = overdueOrders;
        draft.overdueOrdersCount = overdueOrders.length;

        draft.offsetOrders = offsetOrders;
        draft.offsetOrdersCount = offsetOrders.length;

        draft.upNextOrders = upNextOrders;
        draft.upNextOrdersCount = upNextOrders.length;

        draft.completedOrders = completedOrders;
        draft.completedOrdersCount = completedOrders.length;

        draft.cancelledOrders = cancelledOrders;
        draft.cancelledOrdersCount = cancelledOrders.length;

        draft.dineInOrders = dineInOrders;
        draft.dineInOrdersCount = dineInOrders.length;

        return draft;

      case ORDERS_ACTIONS.SET_LOADING_ORDERS:
        draft.loadingOrders = action.payload;
        return draft;

      case ORDERS_ACTIONS.SET_LOADING_LOCATION_DETAILS:
        draft.locationDetailsLoading = action.payload;
        return draft;

      case ORDERS_ACTIONS.SET_CLIENT_LOCATION_DETAILS:
        draft.locationDetails = action.payload;
        return draft;

      case ORDERS_ACTIONS.SET_LOCATION_ORDER_TOGGLE_PROCESSING:
        draft.orderingEnabledProcessing = action.payload;
        return draft;

      case ORDERS_ACTIONS.SET_LOCATION_ALCOHOL_ORDER_TOGGLE_PROCESSING:
        draft.alcoholOrderingEnabledProcessing = action.payload;
        return draft;

      case ORDERS_ACTIONS.UPDATE_LOCATION_ORDER_TOGGLE:
        if (draft.locationDetails) {
          draft.locationDetails.settings.orderingEnabled = action.payload;
        }
        return draft;

      case ORDERS_ACTIONS.UPDATE_LOCATION_ALCOHOL_ORDER_TOGGLE:
        if (draft.locationDetails) {
          draft.locationDetails.settings.alcoholOrderingEnabled = action.payload;
        }
        return draft;

      case ORDERS_ACTIONS.SET_ORDER_LIST_URL_QUERY:
        draft.urlQuery = action.payload;
        return draft;

      default:
        return draft;
    }
  });
};
