import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import UsersListPage from '../../pages/Users/UsersListPage';
import UsersCreatePage from '../../pages/Users/UsersCreatePage';
import UsersViewPage from '../../pages/Users/UsersViewPage';
import UsersEditPage from '../../pages/Users/UsersEditPage';
import { usePageTitle } from '../../hooks';

export const UserRoutes = () => {
  const { path } = useRouteMatch();
  usePageTitle('Users');

  return (
    <Switch>
      <Route path={`${path}/:userId/edit`} component={UsersEditPage} />
      <Route path={`${path}/new`} component={UsersCreatePage} />
      <Route path={`${path}/:userId`} component={UsersViewPage} />
      <Route path={`${path}`} component={UsersListPage} />
    </Switch>
  );
};
