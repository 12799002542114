import { Form } from 'components/Form';
import { ModalBody, ModalFooter, ModalHeader } from 'components/Modal';
import Modal from 'components/Modal/Modal';
import React, { useState } from 'react';
import { DeepPartial, FieldValues, FormProvider, useForm } from 'react-hook-form';
import styles from './ModalForm.module.scss';

interface ModalFormProps<T> {
  headerLabel: string;
  onSave: (formState: T) => Promise<void>;
  setShowModal: (showModal: boolean) => void;
  children: React.ReactNode;
  onCancel?: () => void;
  subLabel?: string;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  defaultValues?: DeepPartial<T>;
  additionalBodyStyles?: string;
}

const ModalForm = <T extends FieldValues>({
  headerLabel,
  defaultValues,
  onSave,
  onCancel = () => {},
  setShowModal,
  children,
  subLabel,
  primaryButtonLabel = 'Save Changes',
  secondaryButtonLabel = 'Cancel',
  additionalBodyStyles = '',
}: ModalFormProps<T>) => {
  const formMethods = useForm<T>({ defaultValues });
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Modal setShowModal={setShowModal}>
      <ModalHeader
        headerLabel={headerLabel}
        subLabel={subLabel}
        additionalStyles={styles.header}
        additionalSubLabelStyles={styles.subLabel}
        setShowModal={(visible) => setShowModal(visible)}
      />
      <ModalBody additionalStyles={additionalBodyStyles}>
        <FormProvider {...formMethods}>
          <Form hasGroups isModalForm>
            {children}
          </Form>
        </FormProvider>
      </ModalBody>
      <ModalFooter
        primaryLabel={primaryButtonLabel}
        primaryActionHandler={formMethods.handleSubmit(async (formState: T) => {
          setLoading(true);
          await onSave(formState);
        })}
        disablePrimary={false}
        secondaryLabel={secondaryButtonLabel}
        secondaryActionHandler={() => {
          onCancel();
          setShowModal(false);
        }}
        loading={loading}
      />
    </Modal>
  );
};

export default ModalForm;
