import { isSameDay, format, addDays, subDays } from 'date-fns';
import { now } from '../dateUtils';

const formatDateText = (date: Date, dateFormat: string = 'dd/MM/yyyy'): string => {
  const currentDate = now();

  if (isSameDay(currentDate, date)) {
    return 'Today';
  } else if (isSameDay(addDays(currentDate, 1), date)) {
    return 'Tomorrow';
  } else if (isSameDay(subDays(currentDate, 1), date)) {
    return 'Yesterday';
  } else {
    return format(date, dateFormat);
  }
};

export default formatDateText;
