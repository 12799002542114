import React from 'react';
import styles from './CompanyTablePage.module.scss';

export interface CompanyTableBodyProps {
  children: JSX.Element[] | JSX.Element;
  emptyResult?: boolean;
  hasNoQuery?: boolean;
}

const CompanyTableBodyEmpty = () => {
  return <div className={styles.empty}>No Results Found</div>;
};

const NoTableHasBeenCreated = () => {
  return <div className={styles.hasNoQuery}>No table has been created yet.</div>;
};

const CompanyTableBody = (props: CompanyTableBodyProps) => {
  const { children, emptyResult = false, hasNoQuery = false } = props;
  return (
    <div className={styles.tableBody}>
      {emptyResult && hasNoQuery ? <NoTableHasBeenCreated /> : emptyResult ? <CompanyTableBodyEmpty /> : children}
    </div>
  );
};

export default CompanyTableBody;
