import { createSlice } from '@reduxjs/toolkit';
import { fetchPayoutHistoryReportThunk, fetchPermittedLocationsThunk } from './thunks';
import { fetchPayoutHistoryReportReducers, fetchPermittedLocationsReducers } from './reducers';
import { IPayoutHistoryReportState } from './types';

const initialState: IPayoutHistoryReportState = {
  items: [],
  permittedLocations: {
    totalCount: 0,
    results: [],
  },
  pagination: {
    start: 0,
    length: 0,
    limit: 0,
    total: 0,
  },
  loading: {},
  errors: {},
};

const payoutHistoryReportSlice = createSlice({
  name: 'payoutHistoryReport',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPayoutHistoryReportThunk.pending, fetchPayoutHistoryReportReducers.pending);
    builder.addCase(fetchPayoutHistoryReportThunk.fulfilled, fetchPayoutHistoryReportReducers.fulfilled);
    builder.addCase(fetchPayoutHistoryReportThunk.rejected, fetchPayoutHistoryReportReducers.rejected);

    builder.addCase(fetchPermittedLocationsThunk.pending, fetchPermittedLocationsReducers.pending);
    builder.addCase(fetchPermittedLocationsThunk.fulfilled, fetchPermittedLocationsReducers.fulfilled);
    builder.addCase(fetchPermittedLocationsThunk.rejected, fetchPermittedLocationsReducers.rejected);
  },
});

export const { actions, reducer } = payoutHistoryReportSlice;
