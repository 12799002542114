import { Tabs, Tab } from 'components/Tabs';
import { TabProps } from 'components/Tabs/Tab';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ContextParams } from 'types/ContextParams.interface';
import { JetpaySettings } from './payment/jetpay/JetpaySettings';
import { CompaniesSettingsRoutes } from '../Router';
import { LoyaltyTabContents } from './loyalty/LoyaltyTabContents';
import { checkFeatureToggle } from 'utils/featureToggle/featureToggle';

export enum CompaniesSettingsTabId {
  Payment = 'payment',
  Loyalty = 'loyalty',
}

interface CompanySettingsTabsProps {
  openingTab?: CompaniesSettingsTabId;
}

export const CompanySettingsPageTabs = (props: CompanySettingsTabsProps): JSX.Element => {
  const { openingTab = CompaniesSettingsTabId.Payment } = props;

  const { contextId: companyId } = useParams<ContextParams>();

  const tabs: Readonly<Record<CompaniesSettingsTabId, TabProps>> = {
    payment: {
      label: 'Payment Processing',
      id: CompaniesSettingsTabId.Payment,
      route: CompaniesSettingsRoutes.getCompanyPaymentSettingsPage(companyId),
    },
    loyalty: {
      label: 'Loyalty',
      id: CompaniesSettingsTabId.Loyalty,
      route: CompaniesSettingsRoutes.getCompanyLoyaltySettingsPage(companyId),
    },
  };

  const isCompanyLoyaltyFeatureToggleEnabled = checkFeatureToggle('app_companyLoyalty');

  return (
    <div>
      <Tabs selectedTab={tabs[openingTab].id}>
        <Tab {...tabs.payment}>
          <JetpaySettings />
        </Tab>

        {isCompanyLoyaltyFeatureToggleEnabled ? (
          <Tab {...tabs.loyalty}>
            <LoyaltyTabContents />
          </Tab>
        ) : null}
      </Tabs>
    </div>
  );
};
