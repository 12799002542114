import React from 'react';
import styles from './SelectMenuConfigCell.module.scss';
import { CellProps, getCellClasses } from 'components/TableV2/Cell/Cell';
import SelectFilter from 'components/SelectFilter/SelectFilter';
import { NoMenusAssignedPlaceholder } from './NoMenusAssignedPlaceholder';
import { IMenuGroupAssignments } from '@ready/menu.core';

export const SelectMenuConfigCell = ({
  rowData,
  classes,
  onSelect,
  loading,
  activeKey,
}: CellProps<IMenuGroupAssignments> & {
  onSelect?: (data: IMenuGroupAssignments, selectedValue: string) => void;
  activeKey: 'isActive' | 'isServerFlowActive';
}) => {
  const activeMenuGroup = rowData.locationGroups.find((x) => x[activeKey] || false);
  const options = rowData.locationGroups.map((x) => ({ label: x.name, value: x._id }));

  return (
    <div className={getCellClasses(classes, styles.container)}>
      <SelectFilter
        options={options}
        additionalSelectStyles={styles.select}
        isInModal // required to show dropdown menu above the table cells.
        onChange={(selected) => {
          if (onSelect) onSelect(rowData, selected.value);
        }}
        value={activeMenuGroup && options.find((x) => x.value === activeMenuGroup._id)}
        disabled={loading === true}
        placeholder={<NoMenusAssignedPlaceholder />}
      />
    </div>
  );
};
