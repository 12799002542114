import { IPagination } from '@ready/dashboardv2api.contracts';
import { ITemplateMenuItem, WithAssignedLocationsCount } from '@ready/menu.core';
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { getSharedMenuItemListThunk } from './thunks';

interface State {
  items: WithAssignedLocationsCount<ITemplateMenuItem>[];
  pagination?: IPagination;
  loading: boolean;
}

const initialState: State = {
  loading: true,
  items: [],
};

const itemsAndModsListSlice = createSlice({
  name: 'sharedMenuItems/itemsAndMods/list',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<State>) => {
    builder.addCase(getSharedMenuItemListThunk.pending, (state: State) => {
      state.loading = true;
    });
    builder.addCase(getSharedMenuItemListThunk.fulfilled, (state: State, action) => {
      state.loading = false;
      const { items, ...pagination } = action.payload;
      state.items = items;
      state.pagination = pagination;
    });
  },
});

export { itemsAndModsListSlice };
