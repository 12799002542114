import React from 'react';
import styles from './DateScroller.module.scss';
import IconButton from '../IconButton/IconButton';
import TextIcon, { Icon } from '../Icon/TextIcon';
import DatePicker, { DatePickerValue } from '../DatePicker/DatePicker';
import { addDays, subDays, startOfDay } from 'date-fns';
import classNames from 'classnames';
import { formatDateText } from '../../utils/formatUtils';
import { getPositionClass, Position } from '../../utils/cssUtils';

const handleOutsideClick = (
  event: MouseEvent,
  containerRef: React.RefObject<HTMLDivElement>,
  onOutsideClick: () => void
) => {
  const eventNode: Node | null = event.target instanceof Node ? event.target : null;

  if (!containerRef.current?.contains(eventNode)) {
    onOutsideClick();
  }
};

interface DateScrollerProps {
  value: Date;
  onChange: (value: Date) => void;
  position?: Position;
}

const DateScroller = ({ value, onChange, position = 'center' }: DateScrollerProps) => {
  const today = startOfDay(new Date());

  /* *** Hooks *** */

  const [isOpen, setIsOpen] = React.useState(false);
  const [calendarDisplayValue, setCalendarDisplayValue] = React.useState(today);
  const datePickerRef: React.RefObject<HTMLDivElement> = React.useRef(null);

  /* *** Effects *** */

  React.useEffect(() => {
    const _handleOutsideClick = (event: MouseEvent) =>
      handleOutsideClick(event, datePickerRef, () => {
        setIsOpen(false);
      });

    document.addEventListener('mousedown', _handleOutsideClick);

    return () => document.removeEventListener('mousedown', _handleOutsideClick);
  }, []);

  /* *** Callbacks *** */

  const handleDateClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setCalendarDisplayValue(value);
    !isOpen && setIsOpen(true);
  };

  const handleScrollLeftClick = (): void => {
    const newDate = subDays(value, 1);
    onChange(newDate);
    setCalendarDisplayValue(newDate);
  };

  const handleScrollRightClick = (): void => {
    const newDate = addDays(value, 1);
    onChange(newDate);
    setCalendarDisplayValue(newDate);
  };

  const handleDateChange = (_value: DatePickerValue): void => {
    onChange(_value.startDate || today);

    if (value !== _value.startDate) {
      setIsOpen(false);
    }

    setCalendarDisplayValue(_value.startMonth);
  };

  /* *** Rendering *** */

  const datePickerPositionClass = getPositionClass(position, styles);

  return (
    <div className={styles.container}>
      <IconButton onClick={handleScrollLeftClick}>
        <TextIcon icon={Icon.AngleLeft} additionalStyles={styles.buttonIcon} />
      </IconButton>

      <div className={styles.dateButtonContainer}>
        <button onClick={handleDateClick} className={styles.dateButton}>
          <TextIcon icon={Icon.Calendar} additionalStyles={styles.dateIcon} />
          <span className={styles.dateText}>{formatDateText(value)}</span>
        </button>
        {isOpen ? (
          <div className={classNames(styles.datePicker, datePickerPositionClass)} ref={datePickerRef}>
            <DatePicker
              value={{
                startDate: value,
                endDate: value,
                startMonth: calendarDisplayValue,
                endMonth: calendarDisplayValue,
              }}
              onChange={handleDateChange}
            />
          </div>
        ) : null}
      </div>

      <IconButton onClick={handleScrollRightClick}>
        <TextIcon icon={Icon.AngleRight} additionalStyles={styles.buttonIcon} />
      </IconButton>
    </div>
  );
};

export default DateScroller;
