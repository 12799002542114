import InfoCardsSkeleton from 'components/InfoCard/InfoCardColumnSkeleton';
import { useResponsiveBreakpoint } from 'hooks';
import React from 'react';

const ListLoadingSkeletons = () => {
  const { isMobile } = useResponsiveBreakpoint();
  const loadingRowsWithinInstances = isMobile ? 3 : 2;
  const loadingColumns = isMobile ? 2 : 5;
  const infoCardColumnWidths = isMobile ? [20, 80] : [5, 35, 35, 5, 20];

  return (
    <InfoCardsSkeleton
      instances={10}
      rowsWithinInstance={loadingRowsWithinInstances}
      columns={loadingColumns}
      infoCardColumnWidths={infoCardColumnWidths}
    />
  );
};

export { ListLoadingSkeletons };
