import { useSelector } from 'react-redux';
import { AppState } from '../../redux/initialStates/AppState';

const tableServerAssignmentSelector = (state: AppState) => state.tableServerAssignment;

/**
 * Returns a list of selected table IDs from the lists of assigned and
 * unassigned tables in the tableAssignments Redux store
 */
const useSelectedTableIds = (): string[] => {
  const { assignedTables, unassignedTables } = useSelector(tableServerAssignmentSelector).serverAssignments;

  return assignedTables
    .concat(unassignedTables)
    .filter((table) => table.selected === true)
    .map((table) => table.id);
};

export default useSelectedTableIds;
