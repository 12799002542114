import { ILocation } from '../../../services/types/ILocation.type';
import { IPagedResponse } from '../../../services/types/IPagedResponse.type';

export interface IPermittedLocations {
  locations: IPagedResponse<ILocation>;
  loading: boolean;
  error: boolean;
}

export const permittedLocations = {
  locations: {
    results: [],
    totalCount: 0,
  },
  loading: false,
  error: false,
} as IPermittedLocations;
