import { createSlice } from '@reduxjs/toolkit';
import { Params } from 'utils/urlUtils/getSearchParams';

export interface ISearchParamsState extends Params {}

const searchParamsSlice = createSlice({
  name: 'searchParams',
  initialState: {} as any,

  reducers: {
    storeSearchParams: (state: ISearchParamsState, action: { payload: ISearchParamsState }) => action.payload,
  },
});
export const { storeSearchParams } = searchParamsSlice.actions;

export { searchParamsSlice };
