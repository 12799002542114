import React from 'react';
import TextInputNestedLabel from '../../components/TextInputNestedLabel/TextInputNestedLabel';
import Button from '../../components/Button/Button';

interface RequestEmailProps {
  email: string;
  emptyError: boolean;
  syntaxError: boolean;
  loading: boolean;
  handleResetPassword: (event: React.FormEvent) => void;
  handleEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RequestEmail = (props: RequestEmailProps) => {
  const { email, emptyError, syntaxError, loading = false, handleEmailChange, handleResetPassword } = props;

  let errorMessage = 'Email Address is required';
  if (syntaxError) {
    errorMessage = 'The Email Address is not correct';
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    handleResetPassword(event);
  };

  return (
    <div className='reset-password__request-email'>
      <h3>Forgot Password</h3>
      <p className='reset-password__request-email__paragraph'>
        Enter the email address associated with your Ready account and we’ll send you a link to reset your password.
      </p>
      <form onSubmit={handleSubmit}>
        <TextInputNestedLabel
          onChange={handleEmailChange}
          label='Email'
          value={email}
          type='text'
          withError={emptyError || syntaxError}
          errorMessage={errorMessage}
          loading={loading}
        />
        <Button type='submit' label='Request Password Reset' variant='primary' size='large' loading={loading} />
      </form>
    </div>
  );
};

export default RequestEmail;
