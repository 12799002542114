import { IClientLocation } from '@ready/dashboardv2api.contracts';
import { IEmployee } from '@ready/employee.core';
import { ITable, ITableAssignment } from '@ready/table.core';
import { AssignTablesDialogMode } from '../components';
import InfiniteScrollingListState from '../../types/InfiniteScrollingListState.interface';
import InfiniteScrollingListStateHelper from '../../utils/InfiniteScrollingList/InfiniteScrollingListStateHelper';

export interface SelectableTable extends ITable {
  selected: boolean;
}

export interface ServerList extends InfiniteScrollingListState<IEmployee> {}

export interface ServerAssignmentState {
  unassignedTables: SelectableTable[];
  assignedTables: SelectableTable[];
  tableAssignments: ITableAssignment[];
  tablesLoading: boolean;
  servers: ServerList;
  isAssignModalOpen: boolean;
  assignModalQuery: string;
  assignModalMode: AssignTablesDialogMode;
  assignModalServerEdit: string;
  assignModalProcessing: boolean;
  selectedServer: string;
  assignmentProcessing: boolean;
  locationDetails: IClientLocation | null;
  loadingLocationDetails: boolean;
  removeModal: {
    isOpen: boolean;
    employeeId: string;
  };
}

export const serverAssignmentInitialState: ServerAssignmentState = {
  unassignedTables: [],
  assignedTables: [],
  tableAssignments: [],
  tablesLoading: false,
  servers: {
    ...InfiniteScrollingListStateHelper.initialState(),
  },
  isAssignModalOpen: false,
  assignModalQuery: '',
  assignModalMode: 'select',
  assignModalServerEdit: '',
  assignModalProcessing: false,
  selectedServer: '',
  assignmentProcessing: false,
  locationDetails: null,
  loadingLocationDetails: false,
  removeModal: {
    isOpen: false,
    employeeId: '',
  },
};
