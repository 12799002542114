import EmptyResult from 'components/EmptyResult/EmptyResult';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ContextParams } from 'types/ContextParams.interface';
import { useAppDispatch, useAppSelector } from 'redux/store';
import InfoCardList from 'components/InfoCard/InfoCardList';
import { ItemGroupListPageControls } from './components/ItemGroupListPageControls';
import { SharedMenuItemsLayout } from 'sharedMenuItems/SharedMenuItemsLayout';
import { SharedMenuItemsTabId } from 'sharedMenuItems/SharedMenuItemsTabs';
import styles from './ItemGroupListPage.module.scss';
import { ItemGroupInfoCard } from './components/ItemGroupInfoCard';
import { ListLoadingSkeletons } from 'sharedMenuItems/components/ListLoadingSkeletons';
import { getSharedItemGroupsThunk } from './redux/thunks';
import { useSearchParams } from 'hooks/useSearchParams';
import { decodeUriString } from 'utils/urlUtils/decodeUriString';
import InfoCard from 'components/InfoCard/InfoCard';
import { ITemplateMenuItemGroup, WithAssignedLocationsCount } from '@ready/menu.core';

const ItemGroupListPage = () => {
  const { contextId: companyId } = useParams<ContextParams>();
  const { searchTerm, page, locationName, locationId } = useSearchParams(true);
  const dispatch = useAppDispatch();
  const decodedSearchTerm = decodeUriString(searchTerm || '');
  const location = locationId && locationName ? { id: locationId, name: decodeUriString(locationName) } : undefined;

  const { loading, itemGroups, pagination } = useAppSelector((state) => state.sharedMenuItems.itemGroups.list);
  const isFilterSet: boolean = searchTerm || location;
  const showCreateFirstMessage = !loading && !isFilterSet && itemGroups.length === 0;

  useEffect(() => {
    dispatch(getSharedItemGroupsThunk({ companyId, query: decodedSearchTerm, page, locationId }));
  }, [companyId, dispatch, locationId, page, decodedSearchTerm]);

  return (
    <SharedMenuItemsLayout tab={SharedMenuItemsTabId.ItemGroups}>
      <div className={styles.container}>
        <ItemGroupListPageControls initialSearchFilter={decodedSearchTerm} initialLocationFilter={location} />

        {!showCreateFirstMessage ? (
          <InfoCardList paginationProps={pagination}>
            {loading ? (
              <ListLoadingSkeletons />
            ) : (
              itemGroups.map((itemGroup: WithAssignedLocationsCount<ITemplateMenuItemGroup>) => (
                <ItemGroupInfoCard key={itemGroup._id} itemGroup={itemGroup} />
              ))
            )}
            {!loading && itemGroups.length === 0 && (
              <InfoCard>
                <div className={styles.noResults}>No Results Found</div>
              </InfoCard>
            )}
          </InfoCardList>
        ) : (
          <EmptyResult
            title='Create Your First Shared Item Group'
            paragraph='Make it easy for guests to browse your menu by grouping similar items together. For instance, you might create an item group for a drink that is available in different sizes.'
          />
        )}
      </div>
    </SharedMenuItemsLayout>
  );
};

export { ItemGroupListPage };
