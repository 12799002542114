import React from 'react';
import Button from '../../components/Button/Button';
import styles from './SquareButton.module.scss';
import squareLogo from '../../styles/assets/square.png';
import config from '../../config';

export interface SquareButtonProps {
  companyId: string;
  isSquareConnected: boolean;
}

const SquareButton = (props: SquareButtonProps) => {
  const { companyId, isSquareConnected } = props;

  const squareClientId = config.squareClientId;
  const squareApiDomain = config.squareApiDomain;
  const squareScope =
    'ITEMS_READ+PAYMENTS_WRITE+PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS+ORDERS_WRITE+EMPLOYEES_READ+INVENTORY_READ+MERCHANT_PROFILE_READ+LOYALTY_READ+LOYALTY_WRITE+ORDERS_READ';
  const squareUrl = `https://${squareApiDomain}/oauth2/authorize?client_id=${squareClientId}&scope=${squareScope}&state=${companyId}`;

  const openSquareAuthentication = () => {
    const squareAuthWindow = window.open(squareUrl, 'popup', 'resizable,scrollbars,status,width=500,height=500');
    if (squareAuthWindow) {
      squareAuthWindow.onbeforeunload = () => {
        // refetch company status to verify if the square access token was set correctly
        window.location.reload();
      };
    }
  };

  if (isSquareConnected) {
    return <Button label='Re-Authenticate' variant='secondary' size='large' onClick={openSquareAuthentication} />;
  } else {
    return (
      <button className={styles.squareButton} onClick={openSquareAuthentication}>
        <img src={squareLogo} alt='square' className={styles.squareLogo} />
        <span>Connect Square</span>
      </button>
    );
  }
};

export default SquareButton;
