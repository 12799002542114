import React from 'react';
import styles from './UnassignedPanelNoResults.module.scss';
import classNames from 'classnames';

interface UnassignedPanelNoResultsProps {
  messageType: 'empty' | 'noSearchResults' | 'noTables';
  additionalStyles?: string;
}

const UnassignedPanelNoResults = ({ messageType, additionalStyles }: UnassignedPanelNoResultsProps) => {
  let message: string = '';
  let style: string = '';

  switch (messageType) {
    case 'noTables':
      message = 'You have no Ready enabled tables. To get started, your tables will need to be mapped to a Ready code.';
      style = styles.noTables;
      break;

    case 'noSearchResults':
      message = 'No Results Found';
      style = styles.empty;
      break;

    case 'empty':
      message = 'None';
      style = styles.empty;
      break;
  }

  return (
    <div className={classNames(styles.container, additionalStyles)}>
      <span className={style}>{message}</span>
    </div>
  );
};

export default UnassignedPanelNoResults;
