import React, { ChangeEvent, useState, useEffect } from 'react';
import styles from './GuestInfoField.module.scss';
import { FormControl } from '../../../../../components/Form';
import RadioGroup from '../../../../../components/RadioGroup/RadioGroup';
import TextInputNestedLabel from '../../../../../components/TextInputNestedLabel/TextInputNestedLabel';
import SelectFilter, { Option } from '../../../../../components/SelectFilter/SelectFilter';
import Checkbox from '../../../../../components/Checkbox/Checkbox';
import IconButton from '../../../../../components/IconButton/IconButton';
// utils
import { LOCATION_SETTINGS_ACTIONS } from '../../../../redux/LocationSettingsActions';
import { LIST_OPTIONS } from '../../../../../utils/selectListUtils/listOptions';
import { toNumber } from '../../../../../utils/numberUtils/toNumber';
// types
import { GuestInformationField } from '@ready/dashboardv2api.contracts';
import { IOrderAheadValidation } from '../../../../redux/PanelFeaturesState';
import { IUpdateLocationParam } from '../../../LocationsEditPage';

interface Props {
  index: number;
  removeGuestField: any;
  guestInfo: GuestInformationField;
  updateLocationParam: IUpdateLocationParam;
  validation: IOrderAheadValidation;
  isProcessing?: boolean;
}

const GuestInfoField = (props: Props) => {
  const { guestInfo, index, removeGuestField, updateLocationParam, validation, isProcessing = false } = props;
  const [selectedField, setSelectedField] = useState<Option>({
    label: 'Text',
    value: 'Text',
  });
  const [selectedMethod, setSelectedMethod] = useState<Option>({
    label: 'POS Item',
    value: 'fakeItem',
  });

  // load fieldType from db
  const { fieldType } = guestInfo;
  const mapFieldType = (value: string) => {
    let initialType = {
      label: 'Text',
      value: 'Text',
    };
    switch (value) {
      case 'text':
        initialType = {
          label: 'Text',
          value,
        };
        break;
      case 'tel':
        initialType = {
          label: 'Phone',
          value,
        };
        break;
      case 'email':
        initialType = {
          label: 'Email',
          value,
        };
        break;
      case 'number':
        initialType = {
          label: 'Number',
          value,
        };
        break;
    }
    return initialType;
  };
  // load methodForSending from db
  const { methodForSending } = guestInfo;
  const mapMethodForSending = (method: string) => {
    let initialMethod = {
      label: 'POS Item',
      value: 'fakeItem',
    };
    switch (method) {
      case 'ticketName':
        initialMethod = {
          label: 'Ticket Name',
          value: 'ticketName',
        };
        break;
      case 'fakeItem':
        initialMethod = {
          label: 'POS Item',
          value: 'fakeItem',
        };
        break;
    }
    return initialMethod;
  };
  useEffect(() => {
    setSelectedField(mapFieldType(fieldType));
    setSelectedMethod(mapMethodForSending(methodForSending));
  }, [fieldType, methodForSending]);

  const controlLabel = index === 0 ? 'Guest Information' : '';
  const primaryValue = guestInfo.isPrimaryOnPaymentConfirmationScreen ? 'Primary Field' : 'Secondary Field';

  const handleSelectChange = (option: Option, type: string) => {
    const newVal = {
      index: index,
      value: option.value,
    };

    switch (type) {
      case 'fieldType':
        setSelectedField(option);
        updateLocationParam(newVal, LOCATION_SETTINGS_ACTIONS.UPDATE_GUEST_INFO_FIELD_TYPE);
        break;
      case 'sendingMethod':
        setSelectedMethod(option);
        updateLocationParam(newVal, LOCATION_SETTINGS_ACTIONS.UPDATE_GUEST_INFO_SENDING_METHOD);
        break;
      default:
        console.error(`Cannot update  selection`);
        break;
    }
  };

  const handleInputChange = (val: string | boolean | number, type: string) => {
    const newval = {
      index: index,
      value: val,
    };

    switch (type) {
      case 'fieldName':
        updateLocationParam(newval, LOCATION_SETTINGS_ACTIONS.UPDATE_GUEST_INFO_FIELD_NAME);
        break;
      case 'placeholder':
        updateLocationParam(newval, LOCATION_SETTINGS_ACTIONS.UPDATE_GUEST_INFO_PLACEHOLDER);
        break;
      case 'maxChar':
        updateLocationParam(newval, LOCATION_SETTINGS_ACTIONS.UPDATE_GUEST_INFO_MAX_CHAR);
        break;
      case 'requiredCheckBox':
        updateLocationParam(newval, LOCATION_SETTINGS_ACTIONS.UPDATE_GUEST_INFO_REQUIRED_INPUT);
        break;
      default:
        console.error(`Cannot update text change`);
        break;
    }
  };

  // to-do : add error states to selects affer handling them in SelectFilter
  const hasGuestFieldError = validation.orderAhead.guestInfoFields.hasError;
  const guestFieldError = validation.orderAhead.guestInfoFields.errors[index];

  return (
    <FormControl label={controlLabel} grayBackground>
      <div className={styles.cancelIcon}>
        <IconButton onClick={() => removeGuestField(index)} loading={isProcessing}>
          <i className='icon-dashboard-icons_remove-circle'></i>
        </IconButton>
      </div>
      <RadioGroup
        groupName='guest'
        options={[
          {
            label: 'Primary Field',
            value: 'Primary Field',
          },
        ]}
        value={primaryValue}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          updateLocationParam(index, LOCATION_SETTINGS_ACTIONS.UPDATE_GUEST_INFO_PRIMARY_FIELD);
        }}
        loading={isProcessing}
      />

      <TextInputNestedLabel
        label='Field Name'
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleInputChange(e.target.value, 'fieldName');
        }}
        value={guestInfo.fieldName}
        withError={hasGuestFieldError && guestFieldError.fieldName}
        errorMessage={'Field Name is required'}
        loading={isProcessing}
      />
      <TextInputNestedLabel
        label='Placeholder Text'
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleInputChange(e.target.value, 'placeholder');
        }}
        value={guestInfo.placeholder}
        withError={hasGuestFieldError && guestFieldError.placeholder}
        errorMessage={'Placeholder is required'}
        loading={isProcessing}
      />
      <SelectFilter
        placeholder='Select field type'
        options={LIST_OPTIONS.fieldType}
        value={selectedField}
        onChange={(option: Option) => {
          handleSelectChange(option, 'fieldType');
        }}
        loading={isProcessing}
      />
      <SelectFilter
        placeholder='Select method for sending info'
        options={LIST_OPTIONS.sendingMethod}
        value={selectedMethod}
        onChange={(option: Option) => {
          handleSelectChange(option, 'sendingMethod');
        }}
        loading={isProcessing}
      />
      <TextInputNestedLabel
        label='Max Char'
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleInputChange(toNumber(e.target.value), 'maxChar');
        }}
        value={guestInfo.charLimit ? guestInfo.charLimit.toString() : ''}
        withError={hasGuestFieldError && guestFieldError.charLimit}
        errorMessage={'Limit must be greater than 0'}
        loading={isProcessing}
      />
      <Checkbox
        label='Field is Required'
        checked={guestInfo.inputIsRequired}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleInputChange(e.target.checked, 'requiredCheckBox');
        }}
        loading={isProcessing}
      />
    </FormControl>
  );
};

export default GuestInfoField;
