import { set200Toast, toastErrorState } from '../actions/uiActions/responseStateActions';
import { AppDispatch } from '../store';

export const interceptErrorWithToastMessage =
  <Args, Returns>(
    apiAsyncMethod: (args: Args, config: { dispatch: AppDispatch }) => Promise<Returns>,
    successMessage?: string
  ) =>
  async (args: Args, { dispatch }: any): Promise<Returns> => {
    try {
      const result = await apiAsyncMethod(args, { dispatch });
      if (successMessage) {
        dispatch(set200Toast(successMessage));
      }
      return result;
    } catch (e) {
      dispatch(toastErrorState(e.status, e.message));
      throw e;
    }
  };
