import React from 'react';
import styles from './NoneValue.module.scss';

interface Props {
  text?: string;
}

const NoneValue = ({ text = undefined }: Props): JSX.Element => <span className={styles.value}>{text ?? 'None'}</span>;

export default NoneValue;
