import config from '../../config';
import executeApiAsync from '../../services/utils/executeApiAsync';
import { IRole } from '@ready/security.core';
import { IPermissionSetMetadata } from '@ready/security.core';
import { PaginationResponse } from '@ready/dashboardv2api.contracts';
import { CreateableRole } from '../redux/initialStates/securityRoleForm';

export default class SecurityRolesService {
  static BASE_URL = `${config.readyDashboardApiEndpoint}/companies`;

  static getSecurityRoles = async (
    companyId: string,
    query: string = '',
    page: number = 1
  ): Promise<PaginationResponse<IRole>> => {
    const params = `&q=${query}&pageNumber=${page}`;
    const url = `${SecurityRolesService.BASE_URL}/${companyId}/roles?${params}`;
    const items = await executeApiAsync<IRole[]>(url);
    // TODO: temporary add pagination props until fixed on the backend
    const result = {
      items,
      start: 0,
      length: items.length,
      limit: items.length,
      total: items.length,
    };

    return result as PaginationResponse<IRole>;
  };

  static createSecurityRole = (companyId: string, role: CreateableRole): Promise<IRole> =>
    executeApiAsync<IRole>(`${SecurityRolesService.BASE_URL}/${companyId}/roles`, {
      method: 'POST',
      body: JSON.stringify(role),
    });

  static updateSecurityRole = (roleId: string, companyId: string, role: CreateableRole): Promise<IRole> =>
    executeApiAsync<IRole>(`${SecurityRolesService.BASE_URL}/${companyId}/roles`, {
      method: 'PUT',
      body: JSON.stringify({
        _id: roleId,
        ...role,
      }),
    });

  static fetchSecurityRole = (companyId: string, roleId: string): Promise<IRole> => {
    return executeApiAsync<IRole>(`${SecurityRolesService.BASE_URL}/${companyId}/roles/${roleId}`);
  };

  static getSecurityMetadata = (companyId: string): Promise<IPermissionSetMetadata> => {
    return executeApiAsync<IPermissionSetMetadata>(`${SecurityRolesService.BASE_URL}/${companyId}/roles/metadata`);
  };

  static deleteSecurityRoleById = async (companyId: string, id: string): Promise<void> => {
    const url = `${SecurityRolesService.BASE_URL}/${companyId}/roles/${id}`;
    await executeApiAsync(url, { method: 'DELETE' });
  };
}
