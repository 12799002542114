import { ITag } from '@ready/menu.core';
import React from 'react';
import styles from './Tag.module.scss';

interface TagProps {
  item: ITag;
}

const Tag = (props: TagProps): JSX.Element => {
  const { item } = props;

  return <div className={styles.tagShortCode}>{item.shortCode || '-'}</div>;
};

export default Tag;
