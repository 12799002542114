import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { updatePath } from '../../../utils/updatePath';
import { useSearchParams } from '../../../hooks/useSearchParams';
import TablesSearchInput from './TablesSearchInput';
import { updateURLSearchParams } from '../../../utils/updateURLSearchParams';
import getSearchParam from '../../../utils/urlUtils/getSearchParam';

export interface TablesSearchParamInputProps {
  placeholder?: string;
  additionalClassName?: string;
  additionalContainerClassName?: string;
  name?: string;
  disabledLoading?: boolean;
}

const TablesSearchParamInput = (props: TablesSearchParamInputProps) => {
  const {
    placeholder = '',
    additionalClassName = '',
    additionalContainerClassName,
    name = '',
    disabledLoading = false,
  } = props;

  const { push } = useHistory();
  const { pathname } = useLocation();
  const { query: encodedQuery } = useSearchParams();
  const query = decodeURIComponent(encodedQuery || '');

  const [value, setValue] = useState('');

  // Handles change via query param value
  React.useEffect(() => {
    if (query && name) {
      const param = getSearchParam(decodeURIComponent(query), name);

      if (param) {
        const decodedParam = decodeURIComponent(param);
        setValue(decodedParam);
      }
    } else {
      setValue(query);
    }
  }, [query, name]);

  // Handles change via user input
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    let paramName = name?.toLocaleLowerCase();
    setValue(value);

    const searchParams = updateURLSearchParams(query, [
      {
        name: paramName ? paramName : '',
        value,
      },
    ]);

    const sanitizedValue = encodeURIComponent(searchParams.toString());
    updatePath(sanitizedValue, pathname, push);
  };

  return (
    <TablesSearchInput
      value={value}
      placeholder={placeholder}
      additionalClassName={additionalClassName}
      additionalContainerClassName={additionalContainerClassName}
      onChange={handleChange}
      disabledLoading={disabledLoading}
    />
  );
};

export default TablesSearchParamInput;
