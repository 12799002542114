import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  exportFeedbackListing,
  getFeedbackListing,
  getGuestSatisfaction,
  getPermittedFeedbackLocations,
  getStarRating,
} from '../services/feedbackService';
import { interceptErrorWithToastMessage } from '../../../redux/helpers/interceptErrorWithToastMessage';
import { showToastMessageOnFulfill } from '../../../redux/helpers/showToastMessageOnFulfill';

export const fetchFeedbacksListThunk = createAsyncThunk(
  'feedback/fetchList',
  interceptErrorWithToastMessage(getFeedbackListing)
);

export const exportFeedbacksListThunk = createAsyncThunk(
  'feedback/exportList',
  interceptErrorWithToastMessage(
    showToastMessageOnFulfill(
      exportFeedbackListing,
      'Success! Export complete.'
    )
  )
);

export const fetchGuestSatisfactionThunk = createAsyncThunk(
  'feedback/fetchGuestSatisfactionThunk',
  interceptErrorWithToastMessage(getGuestSatisfaction)
);

export const fetchStarRatingThunk = createAsyncThunk(
  'feedback/fetchStarRatingThunk',
  interceptErrorWithToastMessage(getStarRating)
);

export const fetchPermittedLocationsThunk = createAsyncThunk(
  'feedback/permittedLocations',
  interceptErrorWithToastMessage(getPermittedFeedbackLocations)
);
