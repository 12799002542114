import React from 'react';
import { IEmbeddedMenuItem, MenuItemInactiveStates } from '@ready/menu.core';
import IconButton from '../../../components/IconButton/IconButton';
import TextIcon, { Icon } from '../../../components/Icon/TextIcon';
import MenuItemPosBlock from '../ItemsAndMods/MenuItemPosBlock';
import MenuItemPriceBlock from '../ItemsAndMods/MenuItemPriceBlock';
import Action from '../../../redux/types/Action';
import styles from './DraggableGroupItemCard.module.scss';
import { useClassNames } from 'utils/cssUtils';
import MenuItemImage from '../ItemsAndMods/MenuItemImage';
import MenuItemIconsBlock from '../ItemsAndMods/MenuItemIconsBlock';
import { Link, useParams } from 'react-router-dom';
import { MenuContextParams } from 'menus/types/MenuContextParams.interface';
import { Routes } from 'menus/MenuBuilderRouter';

export interface DraggableGroupItemCardProps {
  item: IEmbeddedMenuItem;
  dragHandle?: JSX.Element;
  isPageProcessing: boolean;
  updateItemGroupItemVisibility: (itemId: string, visibility: number, immediateSync?: boolean) => Action;
  removeItemGroupItem: (itemId: string) => Action;
  updateItemGroupItemsSortOrder: () => Action;
  setFormIsDirty: (isDirty: boolean) => Action;
  itemImgUrl?: string;
  isSharedItemGroup?: boolean;
}

const DraggableGroupItemCard = (props: DraggableGroupItemCardProps) => {
  const {
    item,
    dragHandle,
    isPageProcessing,
    updateItemGroupItemVisibility,
    removeItemGroupItem,
    updateItemGroupItemsSortOrder,
    setFormIsDirty,
    itemImgUrl,
    isSharedItemGroup = false,
  } = props;

  const isItemVisible = item.visibility === 1;

  const handleRemoveItem = () => {
    setFormIsDirty(true);
    removeItemGroupItem(item.itemId);
    updateItemGroupItemsSortOrder();
  };

  const handleChangeVisibility = () => {
    setFormIsDirty(true);
    const visibilityToggled = isItemVisible ? 0 : 1;
    const immediateSync = false;
    updateItemGroupItemVisibility(item.itemId, visibilityToggled, immediateSync);
  };
  const containsAlcohol = item.alcohol && item.alcohol.containsAlcohol;
  const alcoholAmount =
    item.alcohol && containsAlcohol && item.alcohol.quantity ? item.alcohol.quantity.value : undefined;

  const hasSchedule = item.schedule && item.schedule.links.length > 0;

  const { contextId: companyId, locationId, menuId, id: itemGroupId } = useParams<MenuContextParams>();

  const linkTo = (itemId: string) => {
    return menuId
      ? Routes.getMenuSectionItemGroupItemPage(companyId, locationId, menuId, itemGroupId, itemId)
      : Routes.getItemGroupItemPage(companyId, locationId, itemGroupId ?? 'new', itemId);
  };

  return (
    <div className={useClassNames([{ isSharedItemGroup }, styles.draggableItemCard], styles)}>
      {isSharedItemGroup ? null : dragHandle}

      <div className={useClassNames([{ isSharedItemGroup }, styles.draggableItemCardFields], styles)}>
        <Link to={linkTo(item.itemId)} className={styles.draggableItemCardFields}>
          <div className={styles.imageCol}>
            <MenuItemImage
              type={item.itemType}
              name={item.displayName}
              outOfStock={item.posItemId === undefined ? undefined : !item.inStock}
              url={itemImgUrl}
            />
          </div>
          <div className={styles.inputCol}>
            <p>{item.displayName}</p>
            <p className={useClassNames([{ missing: !item.description }, styles.description], styles)}>
              {item.description || 'No Description'}
            </p>
            <div className={styles.mobilePriceAndIconsCol}>
              <MenuItemPriceBlock
                price={item.price}
                deleted={item.inactiveStates.includes(MenuItemInactiveStates.priceDeletedOnPOS)}
                className={styles.priceValue}
              />

              <MenuItemIconsBlock
                isPopular={item.isPopular}
                hasSchedule={hasSchedule}
                containsAlcohol={containsAlcohol}
                alcoholAmount={alcoholAmount}
                className={styles.mobileIcons}
                isShared={isSharedItemGroup}
              />
            </div>
          </div>
          <div className={styles.detailsCol}>
            <MenuItemPosBlock
              id={item.itemId}
              name={item.displayName}
              categories={item.posItemCategories ?? []}
              draft={item.inactiveStates.includes(MenuItemInactiveStates.draft)}
              deleted={item.inactiveStates.includes(MenuItemInactiveStates.itemDeletedOnPOS)}
            />
          </div>
          <div className={styles.priceCol}>
            <MenuItemPriceBlock
              price={item.price}
              deleted={item.inactiveStates.includes(MenuItemInactiveStates.priceDeletedOnPOS)}
              className={styles.priceValue}
            />
          </div>

          <MenuItemIconsBlock
            isPopular={item.isPopular}
            hasSchedule={hasSchedule}
            containsAlcohol={containsAlcohol}
            alcoholAmount={alcoholAmount}
            className={styles.iconsCol}
            isShared={isSharedItemGroup}
          />
        </Link>

        <div className={styles.visibilityRemoveContainer}>
          <div className={useClassNames([{ isSharedItemGroup }, styles.visibilityCol], styles)}>
            <IconButton
              onClick={handleChangeVisibility}
              loading={false}
              additionalClassName={styles.cardControls}
              disabled={isPageProcessing}
            >
              <>{isItemVisible ? <TextIcon icon={Icon.EyeOpen} /> : <TextIcon icon={Icon.EyeClosed} />}</>
            </IconButton>
          </div>

          {isSharedItemGroup ? null : (
            <div className={styles.removeCol}>
              <IconButton
                onClick={handleRemoveItem}
                loading={false}
                additionalClassName={styles.cardControls}
                disabled={isPageProcessing}
              >
                <TextIcon icon={Icon.RemoveCircle} />
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DraggableGroupItemCard;
