import React from 'react';
import Container from '../Container/Container';
import { inputColorMask } from '../../utils/color';

export interface ColorPickerProps {
  onChange: (value: string) => void;
  loading?: boolean;
  disabled?: boolean;
  colourWheelValue: string;
  colourTextValue: string;
}

const ColorPicker = (props: ColorPickerProps) => {
  const { onChange, loading = false, disabled = false, colourWheelValue, colourTextValue } = props;

  const loadingClassName = loading ? 'color-picker--state-loading' : '';
  const disabledClassName = disabled ? 'color-picker--disabled' : '';

  const labelRef = React.useRef<HTMLLabelElement>(null);
  const labelClassName = `color-picker ${loadingClassName} ${disabledClassName}`;
  const handleInputTextFocus = () => {
    labelRef.current!.className = `${labelClassName} ${labelClassName}--focus`;
  };

  // colour wheel input handler
  const handleColorWheelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    // redux global handler
    onChange(value);
  };

  // colour text input handlers
  const handleInputTextBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const valueToInsert = inputColorMask(value);

    if (labelRef && labelRef.current) {
      labelRef.current!.className = labelClassName;
    }

    onChange(valueToInsert);
  };

  const handleInputTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const valueToInsert = inputColorMask(value);

    // redux global handler
    onChange(valueToInsert);
  };

  return (
    <Container additionalClassName='color-picker-container'>
      <label ref={labelRef} className={labelClassName}>
        <div className='color-picker__color-wrapper'>
          <input
            type='color'
            value={colourWheelValue}
            onChange={handleColorWheelChange}
            className='color-picker__input-color'
            disabled={loading || disabled}
          />
          <div className='color-picker__color' style={{ background: colourWheelValue }}></div>
        </div>
        <div className='color-picker__input-wrapper'>
          <input
            type='text'
            value={colourTextValue}
            onChange={handleInputTextChange}
            onFocus={handleInputTextFocus}
            onBlur={handleInputTextBlur}
            className={`color-picker__input-text ${disabledClassName}`}
            maxLength={7}
            disabled={loading || disabled}
          />
        </div>
      </label>
    </Container>
  );
};

export default ColorPicker;
