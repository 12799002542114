import { ILocationDetails } from '@ready/dashboardv2api.contracts';

export interface IDetailsPanelValidation {
  details: {
    name: {
      hasError: boolean;
      error: '';
    };
  };
}
export interface IDetailsPanelState {
  companyName: string;
  companyId: string;
  iqEntityId: number;
  iqParentEntityId: number;
  locationId: string;
  details: ILocationDetails;
  validation: IDetailsPanelValidation;
}

export const detailsPanel = {
  companyName: '',
  companyId: '',
  iqEntityId: 0,
  iqParentEntityId: 0,
  locationId: '',
  details: {
    city: '',
    countryCode: '',
    currency: '',
    stateCode: '',
    streetAddress: '',
    timezone: '',
    zip: '',
    name: '',
    enabled: false,
  },
  validation: {
    details: {
      name: {
        hasError: false,
        error: '',
      },
    },
  },
} as IDetailsPanelState;

export const getDetailsValidation = (): IDetailsPanelValidation => {
  return {
    details: {
      name: {
        hasError: false,
        error: '',
      },
    },
  };
};
