import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/Button/Button';
import Divider from '../../components/Divider/Divider';
import InfoCard from '../../components/InfoCard/InfoCard';
import InfoCardList from '../../components/InfoCard/InfoCardList';
import InfoCardsSkeleton from '../../components/InfoCard/InfoCardColumnSkeleton';
import { Panel } from '../../components/PanelLayout';
import TaxItemCard from './TaxItemCard';
import { ISmbTax } from '../types/SmbTaxTypes.type';
import { CreateEditTaxModal } from './CreateEditTaxModal';
import { deleteTax, selectTax } from '../redux/CompanySettingsActions';
import { DeleteConfirmationModal } from '../../components/Modal';
import { selectTaxesState } from '../redux/CompanySettingsSelectors';

import styles from './TaxList.module.scss';
import { ContextParams } from '../../types/ContextParams.interface';
import { useParams } from 'react-router-dom';

export interface TaxListProps {
  taxes: ISmbTax[];
  loading: boolean;
}

const TaxList = ({ taxes, loading }: TaxListProps) => {
  const [activeModal, setActiveModal] = useState<'new' | 'edit' | 'delete' | undefined>(undefined);
  const dispatch = useDispatch();

  const { selectedTax } = useSelector(selectTaxesState);
  const { contextId: companyId } = useParams<ContextParams>();

  const onEdit = (taxId: string) => {
    dispatch(selectTax(taxes.find((t) => t._id === taxId)));
    setActiveModal('edit');
  };

  const onDelete = (taxId: string) => {
    dispatch(selectTax(taxes.find((t) => t._id === taxId)));
    setActiveModal('delete');
  };

  return (
    <div className={styles.container}>
      <Panel
        title='Taxes'
        fullContentArea
        headerControls={<Button onClick={() => setActiveModal('new')} label='+ New Tax' variant='primary' />}
      >
        <Divider />
        <InfoCardList>
          {loading ? (
            <InfoCardsSkeleton />
          ) : taxes.length > 0 ? (
            taxes.map((t) => <TaxItemCard key={t._id} tax={t} onDelete={onDelete} onEdit={onEdit} />)
          ) : (
            <InfoCard additionalStyles={styles.noResults}>
              Create taxes and apply them to items in the Location Menus.
            </InfoCard>
          )}
        </InfoCardList>
      </Panel>

      {(activeModal === 'new' || activeModal === 'edit') && (
        <CreateEditTaxModal
          isNew={activeModal === 'new'}
          onClose={() => {
            setActiveModal(undefined);
            dispatch(selectTax(undefined));
          }}
        />
      )}

      {activeModal === 'delete' && (
        <DeleteConfirmationModal
          item='Tax'
          itemName={selectedTax?.name ?? ''}
          loading={loading}
          handleCancel={() => {
            setActiveModal(undefined);
            dispatch(selectTax(undefined));
          }}
          handleDelete={() => {
            if (selectedTax) {
              dispatch(deleteTax(companyId, selectedTax));
            }
            setActiveModal(undefined);
            dispatch(selectTax(undefined));
          }}
          setShowModal={() => setActiveModal(undefined)}
        />
      )}
    </div>
  );
};

export default TaxList;
