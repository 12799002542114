import React from 'react';

export interface TableCellProps {
  children: JSX.Element[] | JSX.Element | string;
  width?: number;
  disabled?: boolean;
  firstCell?: boolean;
  align?: 'left' | 'center' | 'right';
  visibleOn?: 'all' | 'desktop' | 'mobile';
  additionalStyles?: string;
}

const TableCell = (props: TableCellProps) => {
  const {
    children,
    width,
    disabled = false,
    firstCell = false,
    align = 'left',
    visibleOn = 'all',
    additionalStyles = ''
  } = props;

  let inlineFlex: any;
  if (width) {
    inlineFlex = {
      flex: `${width}%`,
    };
  }

  const alignmentClassName = `table__row__cell--align-${align}`;
  const visibleOnClassName =
    visibleOn === 'desktop'
      ? 'table__row__cell--hide-on-mobile'
      : visibleOn === 'mobile'
      ? 'table__row__cell--hide-on-desktop'
      : '';
  const manualLayout = visibleOn !== 'all' ? 'table__row__cell--manual-layout' : '';
  const firstCellClassName = firstCell ? 'table__row__cell--manual-layout-first-item' : '';

  const className = `table__row__cell ${additionalStyles} ${
    disabled ? 'table__row__cell--disabled' : ''
  } ${alignmentClassName} ${visibleOnClassName} ${manualLayout} ${firstCellClassName}`;

  return (
    <div className={className} style={inlineFlex}>
      <span>{children}</span>
    </div>
  );
};

export default TableCell;
