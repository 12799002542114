import React from 'react';
import { Link } from 'react-router-dom';
import styles from './IconLink.module.scss';

export interface IconLinkProps {
  to: string;
  children: JSX.Element[] | JSX.Element;
  id?: string;
  disabled?: boolean;
  additionalClassName?: string;
  loading?: boolean;
  onClick?: () => void;
}

const IconLink = (props: IconLinkProps) => {
  const { to, children, id, disabled = false, additionalClassName = '', loading = false, onClick } = props;

  return (
    <Link
      className={`${styles.iconLink} ${additionalClassName}`}
      to={(!disabled && !loading && to) || ''}
      id={id}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};

export default IconLink;
