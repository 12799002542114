import React, { forwardRef } from 'react';
import Container from '../Container/Container';
// todo: currently borrowing this style from the textSMS input module to
// keep DRY, we can create the used styles here for character limit once
// we refactor textarea scss to a module
import textSmsInputStyles from '../TextSMSInput/TextSMSInput.module.scss';

export interface TextAreaProps {
  value?: string | undefined;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  withError?: boolean;
  disabled?: boolean;
  loading?: boolean;
  additionalStyles?: string;
  name?: string;
  maxLength?: number;
  errorMessage?: string;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>((props: TextAreaProps, ref) => {
  const {
    value,
    onChange,
    placeholder = '',
    withError = false,
    disabled = false,
    loading = false,
    additionalStyles = '',
    name,
    maxLength,
    errorMessage,
  } = props;

  const errorClassName = withError ? 'textarea--state-error' : '';
  const loadingClassName = loading ? 'textarea--state-loading' : '';
  const disabledClassName = disabled ? 'textarea--disabled' : '';
  const characterLimitText = `${value?.length || 0}/${maxLength || 0}`;

  return (
    <>
      <Container additionalClassName='textarea-container'>
        <textarea
          ref={ref}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className={`textarea ${errorClassName} ${loadingClassName} ${disabledClassName} ${additionalStyles}`}
          disabled={disabled || loading}
          name={name}
          maxLength={maxLength}
        />
      </Container>
      <span className='textarea-error'>{errorMessage}</span>{' '}
      <div className='max-length-count-container'>
        {maxLength && maxLength > 0 ? (
          <>
            <span className={textSmsInputStyles.characterLimit}>{characterLimitText}</span>
          </>
        ) : null}
      </div>
    </>
  );
});

export default TextArea;
