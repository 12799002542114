import React from 'react';
// redux
import { connect, ConnectedProps, useDispatch } from 'react-redux';
// actions
import { getLocationsList, updateLocationsListParameters } from '../redux/actions/locationActions';
// hooks
import { useSearchParams } from '../../hooks/useSearchParams';
// components
import LayoutContent from '../../components/AppLayout/LayoutContent';
import SearchParamInput from '../../components/SearchInput/SearchParamInput';
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TableRowsSkeleton,
  TableCells,
} from '../../components/Table';
import { ILocation } from '../../services/types/ILocation.type';
import { AppState } from '../../redux/initialStates/AppState';
import { useParams } from 'react-router-dom';
import { ContextParams } from '../../types/ContextParams.interface';
import {
  PromoCodesResourceActions,
  PrincipalPermissions,
  ResourceType,
  SecurityScope,
  Verifier,
  TableResourceActions,
  PaymentProcessingActions,
  DigitalMenuResourceActions,
  OrderSettingsResourceActions,
  ServerFlowResourceActions,
} from '@ready/security.core';
import { selectPermissions } from '../../redux/selectors/sessionSelectors/sessionSelectors';
import { useSelector } from 'react-redux';
import { pageErrorState } from '../../redux/actions/uiActions/responseStateActions';
import styles from './LocationsListPage.module.scss';

const LocationsListPage = (props: ReduxProps) => {
  const { locationsList, pagination, loading, getLocationsList, updateLocationsListParameters } = props;
  const { contextId } = useParams<ContextParams>();
  const { query, page } = useSearchParams();
  const emptyResult = !loading && !locationsList.items.length;
  const dispatch = useDispatch();

  const permissionsList = new PrincipalPermissions(useSelector(selectPermissions).permissionsList);
  const hasPermissions = (type: ResourceType, action: string, locationId: string) => {
    return Verifier.check(permissionsList, SecurityScope.location, type, action, locationId);
  };
  const getTab = (locationId: string) => {
    if (hasPermissions(ResourceType.table, TableResourceActions.all, locationId)) {
      return `companyLocations/${locationId}/tables/`;
    } else if (hasPermissions(ResourceType.paymentProcessing, PaymentProcessingActions.all, locationId)) {
      return `companyLocations/${locationId}/paymentProcessing/`;
    } else if (hasPermissions(ResourceType.promoCodes, PromoCodesResourceActions.view, locationId)) {
      return `companyLocations/${locationId}/promo-codes/`;
    } else if (hasPermissions(ResourceType.digitalMenu, DigitalMenuResourceActions.all, locationId)) {
      return `companyLocations/${locationId}/digital-menu/`;
    } else if (hasPermissions(ResourceType.orderSettings, OrderSettingsResourceActions.all, locationId)) {
      return `companyLocations/${locationId}/order-settings/`;
    } else if (hasPermissions(ResourceType.serverFlow, ServerFlowResourceActions.all, locationId)) {
       return `companyLocations/${locationId}/server-flow/`;
    } else {
      dispatch(pageErrorState(401));
    }
  };

  // get locations list
  React.useEffect(() => {
    (async () => {
      if (contextId) {
        getLocationsList(contextId, query, page);
      }
    })();
    updateLocationsListParameters(query || '', page || 1);
  }, [contextId, getLocationsList, updateLocationsListParameters, query, page]);

  // table cols width
  const colsWidth: number[] = [25, 25, 50];

  return (
    <LayoutContent
      title='Location Settings'
      containerType='full'
      toolbarContent={<SearchParamInput placeholder='Search Locations' />}
    >
      <Table paginationProps={pagination}>
        <TableHeader>
          <TableRow>
            <TableCell width={colsWidth[0]}>Location name</TableCell>
            <TableCell width={colsWidth[1]}>Display name</TableCell>
            <TableCell width={colsWidth[2]}>Address</TableCell>
          </TableRow>
        </TableHeader>

        <TableBody emptyResult={emptyResult}>
          {!loading ? (
            locationsList.items.map((data: ILocation, index: number) => {
              return (
                <TableRow key={index} linkTo={getTab(data._id)}>
                  {/* the API returns empty strings for values in this table, do not switch || to ?? */}
                  <TableCells visibleOn='desktop'>
                    <TableCell width={colsWidth[0]}>
                      {data.name || <div className={styles.placeholder}>No name</div>}
                    </TableCell>
                    <TableCell width={colsWidth[1]}>
                      {data.publicName || <div className={styles.placeholder}>No name</div>}
                    </TableCell>
                    <TableCell width={colsWidth[2]}>
                      {data.fullAddress || <div className={styles.placeholder}>No address</div>}
                    </TableCell>
                  </TableCells>
                  <TableCells visibleOn='mobile'>
                    <TableCell align='left'>
                      {data.publicName || data.name || <div className={styles.placeholder}>No name</div>}
                    </TableCell>
                    <TableCell align='left'>
                      <></>
                    </TableCell>
                    <TableCell align='left'>{data.name || <div className={styles.placeholder}>No name</div>}</TableCell>
                    <TableCell align='left'>
                      <></>
                    </TableCell>
                    <TableCell align='left'>
                      {data.fullAddress || <div className={styles.placeholder}>No address</div>}
                    </TableCell>
                  </TableCells>
                </TableRow>
              );
            })
          ) : (
            <TableRowsSkeleton rows={50} colsWidth={colsWidth} />
          )}
        </TableBody>
      </Table>
    </LayoutContent>
  );
};

const mapStateToProps = (state: AppState) => {
  const locationsList = state.table.locationsList;
  const { pagination, loading } = locationsList;

  return {
    pagination,
    loading,
    locationsList: locationsList,
  };
};

const actionCreators = {
  getLocationsList,
  updateLocationsListParameters,
};

const connector = connect(mapStateToProps, actionCreators);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(LocationsListPage);
