import React from 'react';
import { SkeletonCell } from '../Cell/SpecialCells';
import { TableRow, TableRowClickable } from '../TableRow/TableRow';
import styles from './Body.module.scss';

export type TColumns = Array<string>;

export interface TableBodyProps {
  cells: Record<string, typeof React.Component>;
  columns: TColumns;
  data: Array<Record<string, any>>;
  rowKey: string;
  hiddenColumns: Record<string, Record<string, boolean>>;
  cellProps?: Record<string, any>;
}

export interface TableBodyRowsClickableProps extends TableBodyProps {
  onClick: (rowData: Record<string, any>, e: React.MouseEvent<HTMLDivElement>) => void;
}

const getSkeletonCells = (cells: Array<string>) => cells.reduce((acc, item) => ({ ...acc, [item]: SkeletonCell }), {});

export const Body = ({ data, rowKey, ...rest }: TableBodyProps) => (
  <>
    {data?.length > 0 ? (
      data.map((row) => <TableRow {...rest} key={row[rowKey]} rowData={row} />)
    ) : (
      <div className={styles['tableV2__body--empty']}>
        <span>No Results Found</span>
      </div>
    )}{' '}
  </>
);

export const BodyRowsClickable = ({ data, rowKey, onClick, ...rest }: TableBodyRowsClickableProps) => (
  <>
    {data.length > 0 ? (
      data.map((row) => <TableRowClickable {...rest} onClick={onClick} key={row[rowKey]} rowData={row} />)
    ) : (
      <div className={styles['tableV2__body--empty']}>
        <span>No Results Found</span>
      </div>
    )}{' '}
  </>
);

export const BodySkeleton = ({ rowsNumber, columns }: { rowsNumber: number; columns: TColumns }) => (
  <>
    {Array(rowsNumber)
      .fill(null)
      .map((row, index) => (
        <TableRow
          classes={styles.tableV2__rowSkeleton}
          hiddenColumns={{}}
          columns={columns}
          cells={getSkeletonCells(columns)}
          key={index}
          rowData={row}
        />
      ))}{' '}
  </>
);
