import { CellProps, getCellClasses } from 'components/TableV2/Cell/Cell';
import React from 'react';
import styles from './cells.module.scss';
import { PaymentDataType } from '@ready/dashboardv2api.contracts';

export const PaymentTypeCell = ({ rowData, columnName, classes }: CellProps) => {
  const paymentType = rowData[columnName] as PaymentDataType;
  let displayText = paymentType === PaymentDataType.BankTransfer ? 'Bank Transfer' : paymentType;

  return <div className={getCellClasses(classes, styles.capitalize)}>{displayText}</div>;
};
